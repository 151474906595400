import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AgencyDetails, Reinsurer } from '../../core/model/reinsurer.model'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../core/store/index'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import {
  selectCurrentClientStudies,
  selectCurrentStudyID,
} from '../../core/store/broker/broker.selectors'
import { Study } from '../../core/model/study.model'
import { StudyReinsurersState } from '../store/study-reinsurers.reducer'
import {
  selectAllStudiesReinsurerState,
  selectStudyReinsurerDirty,
  selectStudyReinsurersSaving,
} from '../store/reinsurers.selectors'
import {
  setUpdateOneReinsurer,
  updateOrAddStudiesReinsurerDirty,
  updateReinsurer,
} from '../store/study-reinsurers.actions'
import { AccountOpportunity } from '../../api/model/backend.model'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-premiums-dialog-container',
  templateUrl: './reinsurer-detail.container.html',
})
export class ReinsurerDetailDialogContainerComponent implements OnInit {
  studyID$: Observable<string | null>
  reinsurers$: Observable<StudyReinsurersState[]>
  studies$: Observable<readonly Study[]>
  savingReinsurer$: Observable<boolean>
  dirtyReinsurers$: Observable<Reinsurer[]>
  programs$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  selectedProgramID$: Observable<string | null>

  constructor(
    public dialogRef: MatDialogRef<ReinsurerDetailDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reinsurer: Reinsurer
      agencyDetails: AgencyDetails[]
      relatedReinsurers: Reinsurer[]
      selectedCompanyPapers: AgencyDetails[]
    },
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.studyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.reinsurers$ = this.store.pipe(select(selectAllStudiesReinsurerState))
    this.studies$ = this.store.pipe(select(selectCurrentClientStudies))
    this.savingReinsurer$ = this.store.pipe(select(selectStudyReinsurersSaving))
    this.dirtyReinsurers$ = this.store.pipe(select(selectStudyReinsurerDirty))
    this.programs$ = this.store.pipe(
      select(fromBroker.selectCurrentYearStudies)
    )
    this.selectedProgramID$ = this.store.pipe(
      select(fromBroker.selectCurrentStudyID)
    )
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
  }

  onSetReinsurer(value: { programID: string; reinsurer: Reinsurer }): void {
    this.store.dispatch(setUpdateOneReinsurer(value))
  }

  onUpdateOrAddDirty(reinsurer: Reinsurer): void {
    this.store.dispatch(updateOrAddStudiesReinsurerDirty({ reinsurer }))
  }

  onSaveClick(): void {
    this.store.dispatch(updateReinsurer())
  }
}
