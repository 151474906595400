import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { CreditStructure } from './../../../credit/model/credit-structure.model'
import { CreditStructureGroup } from './../../../credit/model/credit-structure-group.model'

@Component({
  selector: 'app-credit-tier-structures',
  templateUrl: './credit-tier-structures.component.html',
  styleUrls: ['./credit-tier-structures.component.scss'],
})
export class CreditTierStructuresComponent implements OnChanges {
  structuresHeader = 'Structures'
  structureGroupsHeader = 'Structure Groups'
  @Input() creditStructures: CreditStructure[] = []
  @Input() creditStructureGroups: CreditStructureGroup[] = []
  @Output() tierChange = new EventEmitter()
  @Output() creditStructureCheckChange = new EventEmitter<CreditStructure>()
  @Output() creditStructureGroupSelectionChange =
    new EventEmitter<CreditStructureGroup>()

  ngOnChanges(): void {
    this.structuresHeader =
      this.creditStructures.length > 0
        ? 'Structures'
        : 'No Structures Available'
    this.structureGroupsHeader =
      this.creditStructureGroups.length > 0
        ? 'Structure Groups'
        : 'No Structure Groups Available'
  }

  onCreditStructureCheckChange(structure: CreditStructure): void {
    this.creditStructureCheckChange.emit(structure)
  }

  onCreditStructureGroupSelectionChange(
    structureGroup: CreditStructureGroup
  ): void {
    this.creditStructureGroupSelectionChange.emit(structureGroup)
  }
}
