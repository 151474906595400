import { TrackingData, TrackingDetailsData, TrackingDetailsLayerData, TrackingDetailsResponseData, TrackingResponseData } from 'src/app/tracking/tracking.model'

export const convertTrackingsFromResponse = (
  res: TrackingResponseData[]
): TrackingData[] => {
  return res.map(convertTrackingFromResponse)
}
export const convertTrackingFromResponse = (
  res: TrackingResponseData
): TrackingData => ({
  acctName: res.acctName,
  acctid: res.acctid,
  clientName: res.carrierName,
  clientId: res.carrierId,
  year: res.year.toString(),
  programName: res.programName,
  programId: res.programId,
  businessUnit: res.oppBusinessUnit,
  inceptionDate: toShortFormat(res.oppInception),
  opportunityStage: res.oppStage,
  structureCount: res.structureCount,
  reinsurerCount:res.reinsurerCount,
  marketingList: toShortFormat(res.lastExportDate),
  quoteCount: res.quoteCount,
  fotCount: res.fotCount,
  signedLines: res.assignedLinesCount > 0 ? 'Yes' : 'No',
  submittedOT: toShortFormat(res.minOTSubmissionDate),
  complete: res.completeDate ? 'Yes' : 'No'
})

export const convertTrackingDetailsFromResponse = (res: TrackingDetailsResponseData[]) : TrackingDetailsData[] => {
  if (!res || res === null || res.length === 0) {
    return []
  }
  const transformedData = res.reduce((acc, item) => {
    // Check if the structureId already exists in the accumulator
    const existingStructure: TrackingDetailsData = acc.find(
      st => st.structureId === item.structureId
    )

    if (item && existingStructure) {
      existingStructure.programId = item.programId
      existingStructure.structureName = item
        .structureLabel
      existingStructure.layerDetails.push(formatLayerDetails(item))
    } else {
      // If it doesn't exist, create a new object
      acc.push({
        structureId: item.structureId,
        programId: item.programId,
        structureName: item.structureLabel,
        layerDetails: [formatLayerDetails(item)],
      })
    }

    return acc
  }, [])
  return transformedData
}

const formatLayerDetails = (item: TrackingDetailsResponseData): TrackingDetailsLayerData => {
  return {
    layerName: item.layerName,
    quoteCount: item.quoteCount,
    written: item.writtenTotal ? (item.writtenTotal * 100).toFixed(2) + '%' : null,
    order: item.orderTotal ? (item.orderTotal * 100).toFixed(2) + '%' : null,
    fotCount: item.fotCount,
    signed: item.signedTotal ? (item.signedTotal * 100).toFixed(2) + '%' : null,
  }
}

const toShortFormat = (date: Date | null): string | null => {
    if (date === null) {
      return null
    }
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr',
                        'May', 'Jun', 'Jul', 'Aug',
                        'Sep', 'Oct', 'Nov', 'Dec']
    const date1 = new Date(date)
     if (isNaN(date1.getTime())) {
       return null // Handle invalid date
     }
    const day = String(date1.getDate()).padStart(2, '0')

    const monthIndex = date1.getMonth()
    const monthName = monthNames[monthIndex]

    const year = date1.getFullYear()

    return `${day}-${monthName}-${year}`
}
