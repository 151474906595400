<button
  mat-icon-button
  class="menu-icon"
  id="menu"
  (click)="navService.open()"
>
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/" appLogo></a>
<app-header-title class="title" [name]="title" noLink></app-header-title>
<app-user-card
  [name]="
        broker.authenticatingUser ? broker.authenticatingUser : broker.name
      "
  [avatarID]="broker.avatarID"
  [sageLogicalRole]="sageLogicalRole"
></app-user-card>
<app-contact-support-icon-container></app-contact-support-icon-container>
