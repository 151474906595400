import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface MessageDialogData {
  title: string
  message?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-message-dialog',
  template: `
    <h1 mat-dialog-title>
      {{ title }}
    </h1>
    <div mat-dialog-content class="content">{{ message }}</div>
    <div mat-dialog-actions align="end">
      <button appButton accent mat-dialog-close>
        <span>Close</span>
      </button>
    </div>
  `,
  styles: [
    `
           .content {
             overflow: hidden;
           }
         `,
  ],
})
export class MessageDialogComponent {
  title: string
  message: string

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData
  ) {
    this.title = data.title
    this.message = data.message || ''
  }
}
