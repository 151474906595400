// This file will replace `environment.ts` using the command:
//    `ng build --configuration=development`
//
// The list of file replacements are found in `angular.json`,
// and the build commands are found in `package.json`.

import { mergeDeepRight } from 'ramda'
import { commonEnvironment } from './environment-common'

const dev = {
  internalApi: {
    base: 'https://dev.sage.lockton.com/api',
  },
  marketApi: {
    base: 'https://lockton-re-site-uat.azurewebsites.net/api',
  },
  documentApi: {
    base: 'https://lockton-re-docs-uat.azurewebsites.net/api',
  },
  creditMetricTierConfig: {
    client: 106,
    year: 192,
    program: 796,
  },
}

export const environment = mergeDeepRight(commonEnvironment, dev)

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`,
 * `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a
 * negative impact on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error' // Included with Angular CLI.
