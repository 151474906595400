import { Injectable } from '@angular/core'
import * as msal from '@azure/msal-browser'
import { B2CConfig, UserInfo } from './api/model/backend.model'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { AuthenticationResult } from '@azure/msal-browser'
import * as fromAuthActions from './core/store/auth/auth.actions'
import { Store } from '@ngrx/store'
import { AppState } from './core/store'
import { MatDialog } from '@angular/material/dialog'
import { NewUserDisclaimerDialogComponent } from './login/new-user-disclaimer-dialog.component'
import { BackendService } from './api/backend/backend.service'
import { MatSnackBar } from '@angular/material/snack-bar'

// based on this sample: https://learn.microsoft.com/en-us/azure/active-directory-b2c/configure-authentication-sample-spa-app

@Injectable({ providedIn: 'root' })
export class AuthService {
  private msal: msal.PublicClientApplication | null = null
  private b2cConfig: B2CConfig | null = null
  private userInfo: UserInfo | null = null
  private username: string | null = null
  private handledAfterLoginRedirect = false
  private accessToken = ''

  account: msal.AccountInfo | null = null
  accessTokenRefreshed = new Subject<string>()

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private backendService: BackendService,
    private _snackBar: MatSnackBar
  ) {}

  async signOut() {
    this.account = null
    this.username = null
    sessionStorage.removeItem('username')

    if (this.msal) {
      await this.msal.logoutRedirect({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      })
    } else {
      console.log(
        'An attempt was made to log out while MSAL was not initialized!'
      )
    }
  }

  async trySignIn(username: string, force: boolean) {
    this.username = username

    if (this.username && this.username.trim()) {
      const normalizedEmail = this.username.trim().toLowerCase()
      sessionStorage.setItem('username', normalizedEmail)
      await this.initMsalForLogin(normalizedEmail, force)
    } else if (force) {
      alert('username is required')
    }
  }

  async handleAfterLoginRedirect(username: string | null) {
    this.username = username
    this.handledAfterLoginRedirect = true
    await this.initMsalForLogin(this.username, false)
  }

  private async initMsalForLogin(email: string | null, forceLogin: boolean) {
    // tslint:disable-next-line: no-non-null-assertion
    this.b2cConfig = (
      await this.backendService.getAzureB2CConfig(email, false).toPromise()
    ).data!

    // tslint:disable-next-line: no-non-null-assertion
    await this.initMsal(this.b2cConfig!.policyName)
    // tslint:disable-next-line: no-non-null-assertion
    const response = await this.msal!.handleRedirectPromise()

    if (response) {
      this.handledAfterLoginRedirect = true
      // tslint:disable-next-line: no-non-null-assertion
      await this.setAccount(response.account!)
    }

    if (this.account === null) {
      // in case of page refresh
      await this.selectAccount()
    }

    if (this.account) {
      // tslint:disable-next-line: no-non-null-assertion
      this.userInfo = (
        await this.backendService.getUserInfo().toPromise()
      ).data!
      if (this.userInfo.approval_timestamp === null) {
        const result = await this.dialog
          .open(NewUserDisclaimerDialogComponent, {
            data: this.userInfo.eulaContent,
            disableClose: true,
          })
          .afterClosed()
          .toPromise()
        if (result === 'agree') {
          const currentDate = Date().toString().slice(0, 25)
          // tslint:disable-next-line
          const response = <any>(
            await this.backendService
              .putUserTimeStamp(this.userInfo.id, '"' + currentDate + '"')
              .toPromise()
          )
          if (response.error) {
            this._snackBar.open('Error Editing User login timestamp.', 'X', {
              duration: 2000,
            })
          } else {
            this.store.dispatch(
              fromAuthActions.identifySuccess({
                username: this.account.username,
                redirectURL: window.location.origin,
                name:
                  // tslint:disable-next-line
                  this.account.idTokenClaims!['given_name'] +
                  ' ' +
                  // tslint:disable-next-line
                  this.account.idTokenClaims!['family_name'],
                token: this.accessToken,
              })
            )
          }
        }
      } else {
        this.store.dispatch(
          fromAuthActions.identifySuccess({
            username: this.account.username,
            redirectURL: window.location.origin,
            name:
              // tslint:disable-next-line
              this.account.idTokenClaims!['given_name'] +
              ' ' +
              // tslint:disable-next-line
              this.account.idTokenClaims!['family_name'],
            token: this.accessToken,
          })
        )
      }
    } else if (forceLogin) {
      // tslint:disable-next-line: no-non-null-assertion
      await this.msal!.loginRedirect({
        // tslint:disable-next-line
        scopes: [this.b2cConfig!.clientId],
        loginHint: email ?? undefined,
      })
    }
  }

  async redirectToPasswordReset() {
    // tslint:disable-next-line: no-non-null-assertion
    this.b2cConfig = (
      await this.backendService.getAzureB2CConfig(null, true).toPromise()
    ).data!
    // tslint:disable-next-line: no-non-null-assertion
    await this.initMsal(this.b2cConfig!.policyName)
    // tslint:disable-next-line: no-non-null-assertion
    await this.msal!.loginRedirect({ scopes: [this.b2cConfig!.clientId] })
  }

  private async initMsal(policyName: string) {
    this.msal = new msal.PublicClientApplication({
      auth: {
        // tslint:disable-next-line: no-non-null-assertion
        authority: `${this.b2cConfig!.instance}/${
          // tslint:disable-next-line: no-non-null-assertion
          this.b2cConfig!.domain
        }/${policyName}`,
        // tslint:disable-next-line: no-non-null-assertion
        clientId: this.b2cConfig!.clientId,
        // tslint:disable-next-line: no-non-null-assertion
        knownAuthorities: [this.b2cConfig!.knownAuthority],
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    })
    await this.msal.initialize()
  }

  private async selectAccount() {
    // tslint:disable-next-line: no-non-null-assertion
    const currentAccounts = this.msal!.getAllAccounts()

    if (currentAccounts.length < 1) {
      return
    } else if (currentAccounts.length > 1) {
      // Due to the way MSAL caches account objects, the auth response from initiating a user-flow is cached as a new account, which results in more than one account in the cache. Here we make sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow, as this is the default flow the user initially signed-in with.
      const accounts = currentAccounts.filter(
        account =>
          account.homeAccountId
            .toUpperCase()
            // tslint:disable-next-line: no-non-null-assertion
            .includes(this.b2cConfig!.policyName.toUpperCase()) &&
          account.idTokenClaims &&
          account.idTokenClaims.iss &&
          account.idTokenClaims.iss
            .toUpperCase()
            // tslint:disable-next-line: no-non-null-assertion
            .includes(this.b2cConfig!.knownAuthority.toUpperCase()) &&
          // tslint:disable-next-line: no-non-null-assertion
          account.idTokenClaims.aud === this.b2cConfig!.clientId
      )

      if (accounts.length > 1) {
        // localAccountId identifies the entity for which the token asserts information.
        if (
          accounts.every(
            account => account.localAccountId === accounts[0].localAccountId
          )
        ) {
          // All accounts belong to the same user
          await this.setAccount(accounts[0])
        } else {
          // Multiple users detected. Logout all to be safe.
          await this.signOut()
        }
      } else if (accounts.length === 1) {
        await this.setAccount(accounts[0])
      }
    } else if (currentAccounts.length === 1) {
      await this.setAccount(currentAccounts[0])
    }
  }

  private async setAccount(account: msal.AccountInfo) {
    const navigateToHome =
      this.account === null &&
      account !== null &&
      this.handledAfterLoginRedirect
    this.account = account
    this.username = account.username
    sessionStorage.setItem('username', account.username) // in case user came from password reset flow
    await this.getAccessToken()

    if (navigateToHome) {
      await this.router.navigate(['/'])
    }
  }

  async getAccessToken(forceRefresh: boolean = false): Promise<string | null> {
    if (this.account === null) {
      return null
    }

    // tslint:disable-next-line: no-non-null-assertion
    const account = this.msal!.getAccountByHomeId(this.account.homeAccountId)!

    const req = {
      account,
      // tslint:disable-next-line: no-non-null-assertion
      scopes: [this.b2cConfig!.clientId],
      forceRefresh,
    }

    let response: AuthenticationResult | null = null

    try {
      // tslint:disable-next-line: no-non-null-assertion
      response = await this.msal!.acquireTokenSilent(req)
    } catch (error) {
      console.log('Silent access token acquisition failed.')
      console.error(error)
    }

    if (
      response === null ||
      !response.accessToken ||
      response.accessToken === ''
    ) {
      console.log('Acquiring access token using redirect...')
      // tslint:disable-next-line: no-non-null-assertion
      await this.msal!.acquireTokenRedirect(req)
      return ''
    } else {
      const hasChanged = this.accessToken !== response.accessToken

      if (hasChanged) {
        this.accessToken = response.accessToken
        this.accessTokenRefreshed.next(this.accessToken)
      }

      return this.accessToken
    }
  }
}
