<div class="field">
  <mat-label>Chart Grid</mat-label>
  <ul>
    <ng-container *ngFor="let chart of charts">
      <li
        [ngClass]="chart.highlight ? 'selected' : ''"
        (click)="onGridIndexSelection(chart)"
      >
        <app-grid-icon
          [count]="charts.length"
          [selectedIndex]="chart.index"
        >
        </app-grid-icon>
        <mat-select
          panelClass="app-select-height-big"
          [value]="chart.metric.columnName"
          (selectionChange)="onGroupByChange($event, chart.index)"
        >
          <ng-container *ngFor="let groupBy of groupByOptions">
            <mat-option [value]="groupBy.columnName">
              {{ groupBy.name }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-spinner *ngIf="chart.loading" [diameter]="16"></mat-spinner>
      </li>
    </ng-container>
  </ul>
</div>
