import {
  Cedent,
  SignatureContract,
  SignatureReinsurer,
  SignatureReinsurerReferenceType,
} from '../../signature.model'
import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { FotState } from './../fots/fot.reducer'

/* Create, Save, & Modify Signature Data */
export const createSignatureContract = createAction(
  '[Signature] Create Signature Contract',
  props<{ contractToCreate: { pageSetName: string; fots: FotState[] } }>()
)
export const createSignatureContractSuccess = createAction(
  '[Signature] Create Signature Contract Success',
  props<{
    contractToCreate: { pageSetName: string; fots: FotState[] }
    inceptionDate: string
    cedents: Cedent[]
  }>()
)
export const saveAllDirtyContracts = createAction(
  '[Signature] Save All Dirty Contracts'
)
export const savePostSignatureContracts = createAction(
  '[Signature] Save POST Signature Contracts',
  props<{
    contracts: SignatureContract[]
  }>()
)
export const savePostSignatureContractsFailure = createAction(
  '[Signature] Save POST Signature Contracts Failure',
  props<{ error: ApplicationError }>()
)
export const savePostSignatureContractsSuccess = createAction(
  '[Signature] Save POST Signature Contracts Success',
  props<{ contracts: SignatureContract[] }>()
)
export const savePutSignatureContracts = createAction(
  '[Signature] Save PUT Signature Contracts',
  props<{
    contracts: SignatureContract[]
  }>()
)
export const savePutSignatureContractsFailure = createAction(
  '[Signature] Save PUT Signature Contracts Failure',
  props<{ error: ApplicationError }>()
)
export const savePutSignatureContractsSuccess = createAction(
  '[Signature] Save PUT Signature Contracts Success',
  props<{ contracts: SignatureContract[] }>()
)
export const deleteSignatureContract = createAction(
  '[Signature] Delete Signature Contract',
  props<{ id: number }>()
)
export const deleteSignatureContractFailure = createAction(
  '[Signature] Delete Signature Contract Failure',
  props<{ error: ApplicationError }>()
)
export const deleteSignatureContractSuccess = createAction(
  '[Signature] Delete Signature Contract Success',
  props<{ contract: SignatureContract }>()
)
export const deleteSignatureReinsurer = createAction(
  '[Signature] Delete Selected Reinsurer from Selected Contract',
  props<{ reinsurer: SignatureReinsurer }>()
)
export const resetSelectedContract = createAction(
  '[Signature] Reset Selected Contract / Assigned Lines for FOTs'
)
export const resetSelectedContractSuccess = createAction(
  '[Signature] Reset Selected Contract / Assigned Lines for FOTs Success',
  props<{
    fots: FotState[]
  }>()
)
export const updateSignatureContract = createAction(
  '[Signature] Update Signature Contract Field Values',
  props<{
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceType?: SignatureReinsurerReferenceType
  }>()
)
export const updateSignatureContractSuccess = createAction(
  '[Signature] Update Signature Contract Field Values Success',
  props<{
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceTypeAndNum: {
      referenceType?: SignatureReinsurerReferenceType
      aiinReferenceNumber?: string | null
      feinReferenceNumber?: string | null
      naicReferenceNumber?: string | null
    }
  }>()
)
export const addLayerToSelectedContract = createAction(
  '[Signature] Add Layer to Selected Contract',
  props<{ layer: FotState }>()
)
export const removeLayerFromSelectedContract = createAction(
  '[Signature] Remove Layer from Selected Contract',
  props<{ layer: FotState }>()
)

/* Set & Export Currently Displayed Signature Page Data */
export const setSelectedContract = createAction(
  '[Signature] Set Selected Contract',
  props<{ selectedContract: SignatureContract }>()
)
export const setSelectedSigPage = createAction(
  '[Signature] Set Current Signature Page',
  props<{ selectedReinsurer: SignatureReinsurer }>()
)
export const exportSignatureContract = createAction(
  '[Signature] Export Signature Contract',
  props<{ pages: SignatureReinsurer[] }>()
)
export const exportSignatureContractSuccess = createAction(
  '[Signature] Export Signature Contract Success'
)
