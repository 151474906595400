import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from './size.mixin'

class ActiveActionBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof ActiveActionBase = mixinSize(
  ActiveActionBase,
  'big'
)
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-active-action',
  styles: [
    `
           :host {
             display: flex;
             align-items: center;
           }
     
           span {
             margin-left: 5px;
           }
         `,
  ],
  template: `
    <mat-spinner [diameter]="diameter" color="accent"></mat-spinner>
    <span *ngIf="value" appInfoText [size]="size">{{ value }}</span>
  `,
})
export class ActiveActionComponent extends _SizeMixinBase implements CanSize {
  @Input() value?: string | null

  // Size mixin
  @Input() size: Size
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() body: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.big')
  get isSizeBody() {
    return this.size === 'body'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }

  get diameter(): number {
    switch (this.size) {
      case 'gigantic':
        return 28
      case 'big':
        return 20
      case 'small':
        return 14
      case 'tiny':
        return 12
      default:
        return 16
    }
  }
}
