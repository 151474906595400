import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core'

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
  @Input('copy-clipboard')
  payload: string

  @Output() copied: EventEmitter<string> = new EventEmitter<string>()

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault()
    if (!this.payload) {
      return
    }

    const listener = (e: ClipboardEvent) => {
      const clipboard =
        // @ts-ignore
        e.clipboardData || (window.clipboardData as DataTransfer)
      clipboard.setData('text', this.payload.toString())
      e.preventDefault()

      this.copied.emit(this.payload)
    }

    document.addEventListener('copy', listener, false)
    document.execCommand('copy')
    document.removeEventListener('copy', listener, false)
  }
}
