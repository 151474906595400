import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  Stratification,
  StratificationChartType,
} from '../../model/credit-stratification.model'
import * as fromStratificationActions from './stratification.actions'

export const initialState: Stratification = {
  selectedChartType: StratificationChartType.Occupancy,
}
const selectedChartTypeReducer = createReducer(
  initialState.selectedChartType,
  on(
    fromStratificationActions.SetSelectedChartType,
    (_, { selectedType }) => selectedType
  ),
  on(
    fromStratificationActions.InitStratifications,
    () => initialState.selectedChartType
  )
)

const selectedRowFieldReducer = createReducer(
  initialState.rowType,
  on(
    fromStratificationActions.SetRowType,
    (_, { selectedField }) => selectedField
  ),
  on(
    fromStratificationActions.FetchStratificationResultsFailure,
    _ => undefined
  ),
  on(fromStratificationActions.InitStratifications, () => initialState.rowType)
)

const selectedColumnReducer = createReducer(
  initialState.colType,
  on(
    fromStratificationActions.SetColumnType,
    (_, { selectedField }) => selectedField
  ),
  on(
    fromStratificationActions.FetchStratificationResultsFailure,
    _ => undefined
  ),
  on(fromStratificationActions.InitStratifications, () => initialState.colType)
)

const filtersReducer = createReducer(
  initialState.filters,
  on(
    fromStratificationActions.FetchStratificationFiltersSuccess,
    (_, { results }) => results
  ),
  on(
    fromStratificationActions.FetchStratificationFiltersFailure,
    _ => undefined
  ),
)

const resultsReducer = createReducer(
  initialState.results,
  on(fromStratificationActions.FetchStratificationResults, (): null => null),
  on(
    fromStratificationActions.FetchStratificationResultsSuccess,
    (_, { results }) => results
  ),
  on(
    fromStratificationActions.FetchStratificationResultsFailure,
    _ => undefined
  ),
  on(fromStratificationActions.InitStratifications, () => initialState.results)
)

const activeFiltersReducer = createReducer(
  initialState.activeFilters,
  on(fromStratificationActions.SetActiveFilters, (_, { filters }) => filters),
  on(fromStratificationActions.InitStratifications, () => initialState.activeFilters)
)

const statesSelectionReducer = createReducer(
  initialState.statesSelected,
  on(fromStratificationActions.SetStateSelection, (listOfStates, { state }) => {
    if (!listOfStates) {
      listOfStates = []
    }
    const statesList =
      (JSON.parse(JSON.stringify(listOfStates)) as string[]) ?? []
    const index = statesList.findIndex(s => s === state)
    if (index !== -1) {
      statesList.splice(index, 1)
    } else {
      statesList.push(state)
    }
    return statesList
  }),
  on(fromStratificationActions.SetBulkStateSelection, (_, { states }) => states)
)

const stratificationReducer = combineReducers<Stratification>({
  selectedChartType: selectedChartTypeReducer,
  rowType: selectedRowFieldReducer,
  colType: selectedColumnReducer,
  results: resultsReducer,
  filters: filtersReducer,
  activeFilters: activeFiltersReducer,
  statesSelected: statesSelectionReducer,
})

export function reducer(state: Stratification | undefined, action: Action) {
  return stratificationReducer(state, action)
}
