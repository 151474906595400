import { createAction, props } from '@ngrx/store'
import {
  AnalysisProfile,
  CurrencyCode,
} from '../../../api/analyzere/analyzere.model'
import { ApplicationError } from '../../../error/model/error'
import { TierPath } from '../../../tier/tier.model'
import { Program } from '../../model/program.model'
import { ProgramGroup } from '../../../analysis/store/grouper/program-group.model'
import { CompareView } from '../../../analysis/model/compare-view.model'
import { CurrencyRate } from '../../../analysis/tower/mechanics/tower.model'
import { Folder } from '../../model/study.model'
import { StudyResponse } from '../../../api/model/backend.model'

export const setCurrentClient = createAction(
  '[Broker] Set Current Client',
  props<{
    id: string | null
  }>()
)

export const setCurrentClientSuccess = createAction(
  '[Broker] Set Current Client Success',
  props<{
    compareViews?: CompareView[] | null
    programs?: Program[] | null
    programGroups?: ProgramGroup[] | null
  }>()
)

export const setCurrentStudy = createAction(
  '[Broker] Set Current Study',
  props<{ id: string | null }>()
)

export const setCurrentStructure = createAction(
  '[Broker] Set Current Structure',
  props<{ id: string | null }>()
)

export const setCurrentYear = createAction(
  '[Broker] Set Current Year',
  props<{ id: string | null }>()
)

export const setCurrentTierPath = createAction(
  '[Broker] Set Current Tier Path',
  props<TierPath>()
)

export const setCurrentAnalysisProfile = createAction(
  '[Broker] Set Current Analysis Profile',
  props<{ id?: string }>()
)

export const setCurrentAnalysisProfileSuccess = createAction(
  '[Broker] Set Current Analysis Profile Success',
  props<{ analysisProfile: AnalysisProfile }>()
)

export const setCurrentAnalysisProfileFailure = createAction(
  '[Broker] Set Current Analysis Profile Failure',
  props<{ error: ApplicationError }>()
)

export const setStructureFilter = createAction(
  '[Broker] Set Structure Filter',
  props<{ filter: string | null }>()
)

export const setGroupFilterByAssoc = createAction(
  '[Broker] Set Group Filter By Assoc',
  props<{ groupFilterByAssoc: boolean }>()
)

export const setCurrentClient2 = createAction(
  '[Broker] Set Current Client2',
  props<{ id: string | null }>()
)

export const setCurrentTierPath2 = createAction(
  '[Broker] Set Current Tier Path2',
  props<TierPath>()
)

export const setCurrentStudy2 = createAction(
  '[Broker] Set Current Study2',
  props<{ id: string | null }>()
)

export const setCurrentYear2 = createAction(
  '[Broker] Set Current Year2',
  props<{ id: string | null }>()
)

export const updateCheckedPrograms = createAction(
  '[Broker] Update Checked Programs',
  props<{ checkedItemIds: Program[] }>()
)

export const removedCheckedPrograms = createAction(
  '[Broker] Remove Checked Program',
  props<{ programs: Program[] }>()
)
export const clearCheckedPrograms = createAction(
  '[Broker] Clear Checked Programs'
)

export const setCurrencyList = createAction(
  '[Broker] Set Currency List',
  props<{ currencies: CurrencyCode[] }>()
)

export const setCurrencyRates = createAction(
  '[Broker] Set Currency Rates',
  props<{ currencyRates: CurrencyRate[] }>()
)

export const fetchFolders = createAction(
  '[Broker] Fetch Folders',
  props<{ programId: string}>()
)

export const fetchFoldersFailure = createAction(
  '[Broker] Fetch Folders Failure',
  props<{ error: ApplicationError }>()
)

export const fetchFoldersSuccess = createAction(
  '[Broker] Fetch Folders Success',
  props<{ folders: Folder[] }>()
)

export const createFolder = createAction(
  '[Broker] Create Folder',
  props<{ programId: string; name: string; parentId?: string }>()
)

export const createFolderFailure = createAction(
  '[Broker] Create Folder Failure',
  props<{ error: ApplicationError }>()
)

export const createFolderSuccess = createAction(
  '[Broker] Create Folder Success',
  props<{ folder: Folder }>()
)

export const updateFolder = createAction(
  '[Broker] Update Folder',
  props<{
    folderID: string
    name: string
    programId: string
    parentId?: string
  }>()
)

export const updateFolderFailure = createAction(
  '[Broker] Update Folder Failure',
  props<{ error: ApplicationError }>()
)

export const updateFolderSuccess = createAction(
  '[Broker] Update Folder Success',
  props<{ folder: Partial<Folder> }>()
)

export const deleteFolder = createAction(
  '[Broker] Delete Folder',
  props<{ folderID: string }>()
)

export const deleteFolderFailure = createAction(
  '[Broker] Delete Folder Failure',
  props<{ error: ApplicationError }>()
)

export const deleteFolderSuccess = createAction(
  '[Broker] Delete Folder Success'
)

export const fetchStudy = createAction(
  '[Broker] Fetch Study',
  props<{ programId: string }>()
)

export const fetchStudyFailure = createAction(
  '[Broker] Fetch Study',
  props<{ error: ApplicationError }>()
)

export const fetchStudySuccess = createAction(
  '[Broker] Fetch Study',
  props<{ study: Partial<StudyResponse> }>()
)
