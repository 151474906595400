import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  SwiperConfigInterface,
  SwiperModule,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper-v-13'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../../tier/tier.module'
import { TowerModule } from '../tower/tower.module'
import { CompareGroupThumbnailComponent } from './components/compare-group-thumbnail/compare-group-thumbnail.component'
import { CompareMetricCategoryLabelComponent } from './components/compare-metric-category-label/compare-metric-category-label.component'
import { CompareMetricCategoryComponent } from './components/compare-metric-category/compare-metric-category.component'
import { CompareMetricValueComponent } from './components/compare-metric-value/compare-metric-value.component'
import { CompareMetricsReturnPeriodComponent } from './components/compare-metrics-return-period/compare-metrics-return-period.component'
import { CompareMetricsSidebarComponent } from './components/compare-metrics-sidebar/compare-metrics-sidebar.component'
import { CompareMetricsContainerComponent } from './components/compare-metrics.container/compare-metrics.container'
import { CompareTowersComponent } from './compare-towers/compare-towers.component'
import { CompareTowersContainerComponent } from './compare-towers.container/compare-towers.container'
import { CompareContainerComponent } from './compare.container/compare.container'
import { CompareThumbnailContainerComponent } from './components/compare-thumbnail.container/compare-thumbnail.container'
import { DeleteGroupDialogComponent } from './components/delete-group-dialog/delete-group-dialog.component'
import { DeleteGroupDialogService } from './services/delete-group-dialog.service'
import { DuplicateStructureDialogComponent } from './components/duplicate-structure-dialog/duplicate-structure-dialog.component'
import { FHCFWarningDialogComponent } from './components/fhcf-warning-dialog/fhcf-warning-dialog.component'
import { GroupButtonComponent } from './components/group-button/group-button.component'
import { GroupCompareHeaderComponent } from './components/group-compare-header/group-compare-header.component'
import { GroupCompareRouteContainerComponent } from './group-compare-route.container'
import { GroupCompareRoutingModule } from './group-compare-routing.module'
import { GroupCompareComponent } from './group-compare/group-compare.component'
import { GroupInuranceBarComponent } from './components/group-inurance-bar/group-inurance-bar.component'
import { GroupInuranceBarContainerComponent } from './components/group-inurance-bar-container/group-inurance-bar.container'
import { GroupScenariosDialogComponent } from './components/group-scenarios-dialog/group-scenarios-dialog.component'
import { GroupScenariosDialogService } from './services/group-scenarios-dialog.service'
import { GroupTowerBarEdgeComponent } from './components/group-tower-bar-edge/group-tower-bar-edge.component'
import { GroupTowerBarComponent } from './group-tower-bar/group-tower-bar.component'
import { GroupTowerFooterComponent } from './group-tower-footer/group-tower-footer.component'
import { GroupTowersComponent } from './group-towers/group-towers.component'
import { GroupTowersContainerComponent } from './group-towers/group-towers.container'
import { GroupContainerComponent } from './group.container'
import { NewGroupDialogService } from './services/new-group-dialog.service'
import { ProgramMenuItemComponent } from './components/program-menu-item/program-menu-item.component'
import { ReconcileWarningDialogComponent } from './components/reconcile-warning-dialog/reconcile-warning-dialog.component'
import { GroupTowerControlsComponent } from './tower-controls/tower-controls.component'
import { RenameGroupDialogService } from './services/rename-group-dialog.service'
import { GroupLayerDetailsDialogService } from './services/group-layer-details-dialog.service'
import { GroupLayerDetailsDialogComponent } from './components/group-layer-details-dialog/group-layer-details-dialog.component'
import { CompareExportService } from './services/compare-export-service'
import { CompareTabsComponent } from './components/compare-tabs/compare-tabs.component'
import { CompareStructureOptionsContainerComponent } from './compare-structure-options/compare-structure-options.container'
import { CompareStructureOptionsComponent } from './compare-structure-options/compare-structure-options.component'
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
}

const ENTRY_COMPONENTS = [
  DeleteGroupDialogComponent,
  DuplicateStructureDialogComponent,
  FHCFWarningDialogComponent,
  GroupScenariosDialogComponent,
  ReconcileWarningDialogComponent,
  GroupLayerDetailsDialogComponent,
]

@NgModule({
  declarations: [
    CompareContainerComponent,
    CompareMetricCategoryComponent,
    CompareMetricCategoryLabelComponent,
    CompareMetricValueComponent,
    CompareMetricsContainerComponent,
    CompareMetricsReturnPeriodComponent,
    CompareMetricsSidebarComponent,
    CompareTowersComponent,
    CompareGroupThumbnailComponent,
    CompareTowersContainerComponent,
    CompareThumbnailContainerComponent,
    GroupButtonComponent,
    GroupCompareComponent,
    GroupCompareHeaderComponent,
    GroupCompareRouteContainerComponent,
    GroupContainerComponent,
    GroupInuranceBarComponent,
    GroupInuranceBarContainerComponent,
    GroupTowerBarComponent,
    GroupTowerBarEdgeComponent,
    GroupTowerControlsComponent,
    GroupTowerFooterComponent,
    GroupTowersComponent,
    GroupTowersContainerComponent,
    ProgramMenuItemComponent,
    CompareTabsComponent,
    CompareStructureOptionsContainerComponent,
    CompareStructureOptionsComponent,
    ...ENTRY_COMPONENTS,
  ],
  imports: [
    GroupCompareRoutingModule,
    SharedModule,
    TierModule,
    TowerModule,
    FormsModule,
    SwiperModule,
    DragScrollComponent,
    DragScrollItemDirective,
  ],
  providers: [
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    DeleteGroupDialogService,
    GroupScenariosDialogService,
    NewGroupDialogService,
    RenameGroupDialogService,
    GroupLayerDetailsDialogService,
    CompareExportService,
  ],
})
export class GroupCompareModule {}
