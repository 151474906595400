import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from '../../../core/store'
import {
  ProgramRiskCodeMapping,
  RiskCodeSection,
} from '../../models/quote.model'
import { saveSelectedRiskCodesByProgram } from '../../store/quote.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-risk-code-dialog-container',
  templateUrl: 'quote-risk-code-dialog.container.html',
})
export class QuoteRiskCodeDialogContainerComponent {
  constructor(
    public dialogRef: MatDialogRef<QuoteRiskCodeDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      riskCodeMappings: ProgramRiskCodeMapping[]
      riskCodes: RiskCodeSection[]
      currentProgramId: string
      currentLayerId: string
    },
    private store: Store<AppState>
  ) {}

  onSaveRiskCodes($event: ProgramRiskCodeMapping[]): void {
    this.store.dispatch(saveSelectedRiskCodesByProgram({ mappings: $event }))
  }
}
