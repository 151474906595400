import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
} from '../analysis/model/compare-metrics.model'
import { Study } from '../core/model/study.model'
import { LossFilter } from '../api/analyzere/analyzere.model'
import { AccountOpportunity } from '../api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics',
  styles: [
    `
           app-metrics-header {
             flex: 0 0 auto;
             padding: var(--stack) var(--inset-big) var(--stack) 0;
             height: var(--header-height);
           }
           @media screen and (max-width: 1240px) {
             app-metrics-header {
               height: var(--header-height-small);
             }
           }
         `,
  ],
  template: `
    <app-metrics-header
      [name]="name"
      [studySelected]="studySelected"
      [totalWeight]="totalWeight"
      [metricDirty]="metricDirty"
      [loading]="loading"
      (saveClick)="saveClick.emit()"
    ></app-metrics-header>
    <app-metrics-content
      [clientID]="clientID"
      [studyID]="studyID"
      [studies]="studies"
      [metricCategories]="metricCategories"
      [expandedMetricCategories]="expandedMetricCategories"
      [studySelected]="studySelected"
      [totalWeight]="totalWeight"
      [lossFilters]="lossFilters"
      [programs]="programs"
      [selectedProgramID]="selectedProgramID"
      [accountOpportunities]="accountOpportunities"
      (updateMetricElement)="updateMetricElement.emit($event)"
      (collapseToggle)="collapseToggle.emit($event)"
      (removeAllFilters)="removeAllFilters.emit($event)"
      (addCustomMetric)="addCustomMetric.emit($event)"
      (enableAllClick)="enableAllClick.emit($event)"
    ></app-metrics-content>
  `,
})
export class MetricsComponent {
  @Input() name = 'Metrics'
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() studySelected: string | null
  @Input() saving: boolean
  @Input() loading: boolean
  @Input() totalWeight: number
  @Input() metricDirty: boolean
  @Input() lossFilters: LossFilter[]

  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs?: Study[]
  @Input() selectedProgramID: string | null | undefined

  @Output() saveClick = new EventEmitter()
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() collapseToggle = new EventEmitter<string>()
  @Output() removeAllFilters = new EventEmitter()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  @Output() enableAllClick = new EventEmitter()
}
