<app-return-period-toggles
  [metrics]="returnPeriodToggle"
  [lossFilters]="lossFilters"
  (portfolioTypeChange)="onPortfolioTypeChange($event)"
  (perspectiveChange)="onPerspectiveChange($event)"
  (aggregationMethodChange)="onAggregationMethodChange($event)"
  (lossFilterClick)="onLossFilterClick($event)"
></app-return-period-toggles>
<div class="return-period-selector">
      <span class="return-period-label"
      >Return Period:
        <span class="value">{{ returnPeriodToggle.returnPeriod1 }}</span></span
      >
  <app-return-period-selector
    [returnPeriod]="returnPeriodToggle.returnPeriod1"
    (returnPeriodChange)="onPeriodSelected($event)"
    (click)="$event.preventDefault(); $event.stopPropagation()"
  >
  </app-return-period-selector>
</div>
