import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  ClientSearch,
  CreditSubmissionStructure,
  CreditSubmissionUserTranche,
} from '../../model/credit-submission.model'
import { CreditCalculationStructureActions } from '../structure/credit-structure.actions'
import { CreditTransactionActions } from '../transaction/credit-transaction.actions'
import { CreditResultsActions } from '../results/credit-results.actions'
import {
  CreditSubmissionStructureActions,
  CreditSubmissionUserTrancheActions,
} from './credit-submission.actions'

export interface State {
  currentCreditSubmissionStructure?: CreditSubmissionStructure
  currentCreditSubmissionUserTranches?: CreditSubmissionUserTranche[]
  isDirty: boolean
  isSaving: boolean
  searchResults?: CreditSubmissionStructure[]
  searchFilters?: ClientSearch
}

export const initialState: State = {
  isDirty: false,
  isSaving: false,
}

const currentCreditSubmissionStructureReducer = createReducer(
  initialState.currentCreditSubmissionStructure,

  on(
    CreditSubmissionStructureActions.fetchSuccess,
    CreditSubmissionStructureActions.setSelectedSubmission,
    CreditSubmissionStructureActions.updateSelectedSubmission,
    (_, { structure }) => structure
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const currentCreditSubmissionUserTranchesReducer = createReducer(
  initialState.currentCreditSubmissionUserTranches,
  on(
    CreditSubmissionUserTrancheActions.fetchSuccess,
    CreditSubmissionUserTrancheActions.saveSuccess,
    (_, { tranches }) => tranches
  ),
  on(CreditSubmissionUserTrancheActions.update, (state, { tranche }) =>
    state.map(s => (s.tranche_id === tranche.tranche_id ? tranche : s))
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    CreditCalculationStructureActions.createSuccess,
    CreditCalculationStructureActions.update,
    CreditSubmissionStructureActions.updateSelectedSubmission,
    CreditTransactionActions.addSuccess,
    CreditTransactionActions.updateSuccess,
    CreditTransactionActions.delete,
    CreditResultsActions.setSelectedScenario,
    CreditSubmissionUserTrancheActions.update,
    _ => true
  ),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.putSuccess,
    CreditSubmissionUserTrancheActions.saveSuccess,
    CreditSubmissionStructureActions.resetSelectedSubmission,

    _ => false
  )
)

const isSavingReducer = createReducer(
  initialState.isSaving,
  on(CreditCalculationStructureActions.save, _ => true),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.postFailure,
    CreditCalculationStructureActions.putSuccess,
    CreditCalculationStructureActions.putFailure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => false
  )
)

const searchResultsReducer = createReducer(
  initialState.searchResults,
  on(
    CreditSubmissionStructureActions.getSubmissionStructuresSuccess,
    (_, { structures }) => structures
  ),
  on(
    CreditSubmissionStructureActions.getSubmissionStructuresFailure,
    _ => undefined
  ),
  on(CreditSubmissionStructureActions.setSelectedFilters, _ => undefined)
)

const searchFiltersReducer = createReducer(
  initialState.searchFilters,
  on(
    CreditSubmissionStructureActions.setSelectedFilters,
    (_, { filters }) => filters
  )
)

const submissionReducer = combineReducers<State>({
  currentCreditSubmissionStructure: currentCreditSubmissionStructureReducer,
  currentCreditSubmissionUserTranches:
    currentCreditSubmissionUserTranchesReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
  searchResults: searchResultsReducer,
  searchFilters: searchFiltersReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return submissionReducer(state, action)
}
