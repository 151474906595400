<div>
  <h1 mat-dialog-title>Inurance or Shared Limit Found</h1>
  <div mat-dialog-content>
    Cannot add Groups to Groups when Shared Limits or Inurance relationships
    exist.
  </div>
  <div mat-dialog-actions>
    <button appButton link mat-dialog-close (click)="cancel()">
      <span>Cancel</span>
    </button>
  </div>
</div>
