import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { AppState } from '../../../core/store'
import { Store, select } from '@ngrx/store'
import * as fromScenarioEventActions from '../store/scenario-event.actions'
import * as fromAnimatedScenarioActions from '../store/animated-scenarios.actions'
import * as fromAnimatedScenarioSelectors from '../store/animated-scenarios.selectors'
import { Observable, combineLatest, Subject, of } from 'rxjs'
import {
  ScenarioEvent,
  ScenarioEventChange,
  AddScenarioEventPayload,
  ScenarioEventResult,
} from '../animated-scenarios.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import {
  selectLossSetLayers,
  selectCurrentProgram,
  selectSharedIDPortfolios,
  selectCededLayers,
  selectCurrentCurrency,
} from '../../store/analysis.selectors'
import { takeUntil, delay, map, withLatestFrom } from 'rxjs/operators'
import { Program } from '../../../core/model/program.model'
import { SharedIDPortfolio } from '../../model/portfolio-set.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { filterLayers } from '../util/filters'
import { selectCurrentClientStudies } from '../../../core/store/broker/broker.selectors'
import { Study } from '../../../core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-animated-scenarios-container',
  styleUrls: ['./animated-scenarios.container.scss'],
  templateUrl: './animated-scenarios.container.html'
})
export class AnimatedScenariosContainerComponent implements OnInit, OnDestroy {
  scenarioEvents$: Observable<ScenarioEvent[]>
  lossSets$: Observable<LossSetLayer[]>
  scenarioEventsResult$: Observable<ScenarioEventResult[]>
  sharedIDPortfolio$: Observable<SharedIDPortfolio[]>
  delay$: Observable<boolean>
  executing$: Observable<boolean>
  scenarioStructure$: Observable<Program | null>
  originalLayers$: Observable<LayerState[]>
  originalStructure$: Observable<Program | null>
  animating$: Observable<boolean>
  studies$: Observable<Study[]>
  name$: Observable<string>
  currentCurrency$: Observable<string | undefined>

  private destroy$ = new Subject()

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.studies$ = this.store.pipe(select(selectCurrentClientStudies))
    this.originalLayers$ = this.store.pipe(
      select(selectCededLayers),
      map((layers: LayerState[]) => {
        return filterLayers(layers)
      })
    )
    this.originalStructure$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectSelectedStructure)
    )
    this.scenarioStructure$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectScenarioStructure)
    )
    this.executing$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectExecuting)
    )
    this.sharedIDPortfolio$ = this.store.pipe(select(selectSharedIDPortfolios))

    this.scenarioEvents$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectAllScenarioEvents)
    )
    this.lossSets$ = this.store.pipe(select(selectLossSetLayers))
    this.scenarioEventsResult$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectAllScenarioEventResults)
    )

    combineLatest([
      this.store.pipe(select(selectCurrentProgram)),
      this.store.pipe(
        select(fromAnimatedScenarioSelectors.selectSelectedStructure)
      ),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentStructure, selectedStructure]) => {
        if (
          currentStructure &&
          selectedStructure &&
          currentStructure.id !== selectedStructure.id
        ) {
          this.store.dispatch(
            fromAnimatedScenarioActions.resetEventsAndResults()
          )
          this.store.dispatch(
            fromAnimatedScenarioActions.addSelectedStructure({
              structure: currentStructure,
            })
          )
        } else if (currentStructure && !selectedStructure) {
          this.store.dispatch(
            fromAnimatedScenarioActions.addSelectedStructure({
              structure: currentStructure,
            })
          )
        }
      })
    this.animating$ = this.store.pipe(
      select(fromAnimatedScenarioSelectors.selectAnimating)
    )
    this.name$ = this.originalStructure$.pipe(
      withLatestFrom(this.studies$),
      map(([originalStructure, studies]) => {
        if (originalStructure) {
          const study = studies.find(s => s.id === originalStructure.studyID)
          if (study) {
            return `${study.name} - ${originalStructure.label}`
          } else {
            return ''
          }
        } else {
          return ''
        }
      })
    )
    this.delay$ = of(true).pipe(delay(1000))
    this.currentCurrency$ = this.store.pipe(select(selectCurrentCurrency))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onScenarioEventChange(event: ScenarioEventChange): void {
    this.store.dispatch(
      fromScenarioEventActions.updateScenarioEvent({
        id: event.id,
        changes: event.changes,
      })
    )
  }

  onAddScenarioEvent(event: AddScenarioEventPayload): void {
    this.store.dispatch(fromScenarioEventActions.addScenarioEvent({ event }))
  }

  onScenarioEventDelete(eventID: number): void {
    this.store.dispatch(
      fromScenarioEventActions.removeScenarioEvent({ id: eventID })
    )
  }

  onProcessEvents(): void {
    this.store.dispatch(fromAnimatedScenarioActions.execute())
  }

  onAnimationEnd(): void {
    this.store.dispatch(
      fromAnimatedScenarioActions.setAnimating({ value: false })
    )
  }

  onReAnimate(): void {
    this.store.dispatch(
      fromAnimatedScenarioActions.setAnimating({ value: true })
    )
  }
}
