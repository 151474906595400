import { Injectable, Component } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AppState } from '../core/store'
import {
  selectGrouperIsDirty,
  selectCededLayers,
  selectMetricTableSettingsEntities,
  selectLossSetLayersDirty,
  selectTechnicalPremiumCanSave,
} from '../analysis/store/analysis.selectors'
import { selectQuoteDirty } from '../quote/store/quote.selectors'
import { withLatestFrom, mergeMap } from 'rxjs/operators'
import { GroupCompareRouteContainerComponent } from '../analysis/group/group-compare-route.container'
import { MetricsRouteContainerComponent } from '../metrics/metrics-route.container'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { selectRouterState } from '../core/store/router.selectors'
import { TowerContainerComponent } from '../analysis/tower/tower.container'
import { DigitalRouteContainerComponent } from '../digital/digital-route.container'
import { DisplayRouteContainerComponent } from '../display/display-route.container'
import { selectStudyReinsurerDirty } from '../reinsurers/store/reinsurers.selectors'
import { ReinsurerRouteContainerComponent } from '../reinsurers/reinsurer-route.container'
import { QuoteRouteContainerComponent } from '../quote/quote-route.container'
import { SignatureRouteContainerComponent } from '../signature/signature-route.container'
import { selectIsSignatureDirty } from '../signature/store/signature.selectors'
import { TechnicalPremiumContentContainerComponent } from '../analysis/technical-premium/technical-premium-content-container/technical-premium-content.container'

@Injectable()
export class CanDeactivateGuard {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  private message =
    'There are unsaved changes. Are you sure you want to leave the page?'
  private submitLabel = 'Leave'

  canDeactivate(component: Component): boolean | Observable<boolean> {
    if (component instanceof GroupCompareRouteContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectGrouperIsDirty)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, groupDirty, routerState]) => {
          return routerState.state.url.includes('group') || !groupDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof TowerContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectCededLayers)),
          this.store.pipe(select(selectLossSetLayersDirty)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, layers, lossSetDirty, routerState]) => {
          const designDirty =
            lossSetDirty ||
            layers.filter(l => l.deleted || l.dirty || l.new).length > 0

          return routerState.state.url.includes('tower') || !designDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof TechnicalPremiumContentContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectTechnicalPremiumCanSave)),
          this.store.pipe(select(selectRouterState)),
          this.store.pipe(select(selectCededLayers))
        ),
        mergeMap(([_, canSave, routerState, layers]) => {
          const techPremiumDirty =
            canSave ||
            layers.filter(l => l.deleted || l.dirty || l.new).length > 0
          return routerState.state.url.includes('technical-premium') ||
            !techPremiumDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof MetricsRouteContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectMetricTableSettingsEntities)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, metrics, routerState]) => {
          const metricsDirty = metrics.filter(m => m.dirty).length > 0

          return routerState.state.url.includes('metrics') || !metricsDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof ReinsurerRouteContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectStudyReinsurerDirty)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, reinsurers, routerState]) => {
          const reinsurersDirty = reinsurers.length > 0

          return routerState.state.url.includes('reinsurers') ||
            !reinsurersDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof QuoteRouteContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectQuoteDirty)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, quoteDirty, routerState]) => {
          return routerState.state.url.includes('quote') || !quoteDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof SignatureRouteContainerComponent) {
      return of(1).pipe(
        withLatestFrom(
          this.store.pipe(select(selectIsSignatureDirty)),
          this.store.pipe(select(selectRouterState))
        ),
        mergeMap(([_, signatureDirty, routerState]) => {
          return routerState.state.url.includes('signature') || !signatureDirty
            ? of(true)
            : this.confirmationDialog
                .open({
                  message: this.message,
                  submitLabel: this.submitLabel,
                })
                .afterClosed()
        })
      )
    } else if (component instanceof DigitalRouteContainerComponent) {
      return of(true)
    } else if (component instanceof DisplayRouteContainerComponent) {
      return of(true)
    }
    return of(true)
  }
}
