import { inject, Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { throwError } from 'rxjs'
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../index'
import { BackendService } from '../../../api/backend/backend.service'
import { ProgramResponse } from '../../../api/model/backend.model'
import * as fromAuthActions from './auth.actions'
import { selectAuthState } from './auth.selectors'
import { AuthService } from '../../../auth.service'
import { convertPermissionsToEntitiesResponse } from '../../model/client.converter'

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private backendService: BackendService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthActions.identifySuccess),
      switchMap(() => {
        return this.backendService.permissions()
      }),
      withLatestFrom(this.store.pipe(select(selectAuthState))),
      switchMap(([res, authState]) => {
        const actions = []
        if (res.error) {
          actions.push(fromAuthActions.authenticateFailure())
          actions.push(
            fromAuthActions.identifyPermissionsFailure({ error: res.error })
          )
        } else if (res.data !== undefined) {
          this.backendService
            .postEvent({
              eventType: 'User Login',
              eventDetails: {
                user: authState.username,
              },
            })
            .subscribe(err => throwError(err))
          res.data.programs.forEach((program: ProgramResponse) => {
            if (res.data) {
              program.layer_data = res.data.structureLayerData.filter(
                sc => sc.structure_id === program.id
              )
              program.structure_market_layers =
                res.data.structureMarketLayer.filter(
                  ml => ml.structure_id === program.id
                )
              program.tower_preferences =
                res.data.structureTowerPreferences.find(
                  tp => tp.structure_id === program.id
                )
              program.market_content = res.data.structureMarketContent.filter(
                mc => mc.structure_id === program.id
              )
            }
          })
          actions.push(fromAuthActions.authenticateSuccess())
          actions.push(
            fromAuthActions.setAuthenticatingUser({
              authenticatingUser: res.data.authenticatingUser,
            })
          )
          actions.push(
            fromAuthActions.setSageLogicalRole({
              sageLogicalRole: res.data.sageLogicalRole,
            })
          )
          actions.push(
            fromAuthActions.identifyPermissionsSuccess(
              convertPermissionsToEntitiesResponse(res.data)
            )
          )
          actions.push(
            fromAuthActions.setMarketTokenSuccess({
              token: res.data.marketToken ? res.data.marketToken.token : '',
            })
          )
        }
        return actions
      })
    )
  })

  verifyUserFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAuthActions.authenticateFailure),
        tap(_ => {
          this.authService.signOut()
        })
      )
    },
    { dispatch: false }
  )

  authenticateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthActions.authenticateSuccess),
      switchMap(() => {
        // tslint:disable-next-line: no-non-null-assertion
        return this.backendService.getAnalyzereToken()
      }),
      map(res => {
        if (res.error) {
          return fromAuthActions.setAnalyzereToken({
            token: null,
          })
        } else {
          return fromAuthActions.setAnalyzereToken({
            // tslint:disable-next-line: no-non-null-assertion
            token: res.data!.authToken,
          })
        }
      })
    )
  })

  tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    // tslint:disable-next-line: new-parens
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }
}
