<app-theme-container>
  <app-group-animated-scenarios-header
    [structureGroup]="structureGroup"
    [currentClient]="currentClient"
    [executing]="executing"
    [recording]="isRecording"
    [disableReanimate]="scenarioEventsResults.length === 0"
    [disableProcessEvents]="scenarioEvents.length === 0 || loadingGroup"
    (record)="onRecord()"
    (stopRecord)="onStopRecord()"
    (processEvents)="onProcessEvents()"
    (reanimate)="onReAnimate()"
  ></app-group-animated-scenarios-header>
  <main class="main">
    <div class="event-results">
      <app-loading-item
        [item]="lossSets"
        *ngIf="loadingGroup"
        [loading]="loadingGroup"
      >
      </app-loading-item>
      <div class="right" *ngIf="!loadingGroup">
        <button
          [disabled]="executing"
          appButton
          big
          translucent
          (click)="onEventClick()"
        >
          <span>+ Events ({{ scenarioEvents.length }})...</span>
        </button>
      </div>
      <div class="event-result">
        <app-group-animated-event-result
          *ngIf="!loadingGroup"
          [studies]="studies"
          [structureEntities]="structureEntities"
          [eventResults]="scenarioEventsResults"
          [loadingGroup]="loadingGroup"
          [sharedIDGroup]="sharedIDGroup"
          [animating]="animating"
          [eventResultByEntity]="eventResultByEntity"
          [structureNameByEntity]="structureNameByEntity"
          (animationEnd)="animationEnd.emit()"
        ></app-group-animated-event-result>
      </div>
    </div>
  </main>
</app-theme-container>
