import * as d3 from 'd3'
import { select, Selection, pointer } from 'd3-selection'
import { hexGraphingColorPalette } from './utils/graphing-color-palette'

export interface DonutData {
  values: number[]
}

export interface DonutChartOptions {
  chartLabel?: string
  legendValues: string[]
  legendLimit?: number
  legendTextLimit?: number
  min?: boolean
  lightChartMode?: boolean
  margin?: {
    top: number
    right: number
    bottom: number
    left: number
  }
  showDataLabels?: boolean
  pieChart?: boolean
  currencyFormat?: (n: number) => string
  hideToolTipRawData?: boolean
  summary?: boolean
  pdfPie?: boolean
  hideLegend?: boolean
  threeWide?: boolean
  twoWide?: boolean
}

export class DonutChart {
  private container: HTMLElement
  private data: DonutData
  private margin = { top: 50, right: 100, bottom: 50, left: 50 }
  private options: DonutChartOptions

  constructor(container: HTMLElement, options: DonutChartOptions) {
    this.container = container
    this.options = options
    select(container).selectAll('*').remove()
    if (this.options.margin) {
      this.margin = this.options.margin
    }
  }
  draw(data: DonutData) {
    this.data = data
    this.setUpChart()
  }

  private setUpChart(): void {
    const margin = this.options.threeWide
      ? { ...this.margin, left: 25 }
      : this.options.min || this.options.margin
        ? this.margin
        : { top: 150, right: 100, bottom: 50, left: 100 }
    const width = this.container.offsetWidth - (margin.left + margin.right)
    const height = this.container.offsetHeight - (margin.top + margin.bottom)
    const threeWideOffset = this.options.threeWide ? .75 : 1
    const radius = (Math.min(width, height) / 2) * threeWideOffset

    const totalValue = this.data.values.reduce((sum, num) => sum + num, 0)

    const color = d3.scaleOrdinal(
      hexGraphingColorPalette.filter(c => c !== 'body')
    )

    const legendLimit = this.options.legendLimit ?? 5

    const shortString = (s: string, cut?: number) => {
      const max = cut ?? this.options.legendTextLimit ?? 18
      if (s.length > max) {
        return s.slice(0, max) + '...'
      }
      return s
    }

    const tooltip:
      | Selection<HTMLDivElement, unknown, null, undefined>
      | undefined = d3
      .select(this.container)
      .append('div')
      .style('opacity', 0)
      .style('transition', 'all 1s')
      .attr('class', 'custom-tooltip')

    const container = d3
      .select(this.container)
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow', 'display')
      .style('position', 'relative')
      .style('background', 'transparent')

    const additionalOffset =
      this.options.summary && !this.options.pdfPie
        ? 100 - (this.options.threeWide ? margin.right : 0)
        : 0
    const xOffset = margin.left + width / 3 + 75 + additionalOffset
    const yOffset = margin.top + height / 2
    const chartTranslate = `translate(${xOffset},${yOffset})`

    const chart = container
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', chartTranslate)
      .style('background', 'transparent')

    const arc = d3
      .arc<d3.PieArcDatum<number>>()
      .innerRadius(radius * (this.options.pieChart ? 0 : 0.75))
      .outerRadius(radius)

    const pie = d3
      .pie<number>()
      .sort(null)
      .value(d => d)

    const arcs = chart
      .selectAll('.arc')
      .data(pie(this.data.values))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('width', width - margin.right * 2)
      .on('mouseover', () => {
        tooltip?.style('opacity', 1)
      })
      .on('mousemove', (event: any, d: any) => {
        const [x, y] = pointer(event)
        const left = this.options.min
          ? `${width / 8 + x + 100}px`
          : `${x + width / 2}px`
        const top = this.options.min
          ? `${height / 8 + x + 100}px`
          : `${y + height / 2 + 75}px`

        tooltip
          .style('display', 'block')
          .style('opacity', 1)
          .style('left', left)
          .style('top', top)
          .style('text-align', 'center')
          .style('text-transform', 'capitalize').html(`
              <div>
                <strong>${shortString(
                  this.options.legendValues[this.data.values.indexOf(d.data)],
                  50
                )}</strong>
                <div>
                  ${
                    !this.options.hideToolTipRawData
                      ? this.options.currencyFormat
                        ? this.options.currencyFormat(d.data / 100)
                        : d.data
                      : ''
                  } (${((d.data / totalValue) * 100).toFixed(1)}%)
                </div>
              </div>
            `)
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none')
      })

    arcs
      .append('path')
      .attr('d', arc as any)
      .attr('fill', (_, i) => color(i.toString()))

    if (!this.options.min && this.options.chartLabel) {
      const labelXOffset = this.options.threeWide
        ? -(width / 2) - margin.left
        : -(this.options.chartLabel.length * (this.options.pdfPie ? 2 : 8))
      chart
        .append('text')
        .attr('class', 'chart-title')
        .attr('text-anchor', 'top')
        .attr('x', labelXOffset)
        .attr('y', -(height / 2 + 50))
        .style('fill', this.options.lightChartMode ? 'black' : '#FFFFFF')
        .style(
          'font-size',
          this.options.threeWide
            ? '18px'
            : this.options.summary
              ? '24px'
              : '32px'
        )
        .text(this.options.chartLabel)
    }

    if (!this.options.min && this.options.showDataLabels) {
      arcs.each((d, i, nodes) => {
        if (Math.round((d.value / totalValue) * 100) >= 3) {
          const [x1, y1] = arc.centroid(d).map(coord => coord * 1.11)
          const [x2, y2] = arc.centroid(d).map(coord => coord * 1.2)
          const [labelX, labelY] = arc
            .centroid(d)
            .map(coord => coord * (this.options.pieChart ? 1.25 : 1.22))
          const textAnchor = this.options.pieChart
            ? 'center'
            : arc.centroid(d)[0] > 0
              ? 'start'
              : 'end'
          if (!this.options.pieChart) {
            d3.select(nodes[i])
              .append('line')
              .attr('x1', x1)
              .attr('y1', y1)
              .attr('x2', x2)
              .attr('y2', y2)
              .attr('stroke', this.options.lightChartMode ? 'black' : 'white')
              .attr('stroke-width', 1)
              .attr('stroke-dasharray', '2 2')
          }
          const text = this.options.pieChart
            ? `${Math.round((d.value / totalValue) * 100)}%`
            : this.options.threeWide 
            ? `${shortString(this.options.legendValues[i], 13)} (${Math.round((d.value / totalValue) * 100)}%)`
            : `${(this.options.legendValues[i])} (${Math.round((d.value / totalValue) * 100)}%)`

          d3.select(nodes[i])
            .append('text')
            .attr('x', labelX + (this.options.pieChart ? -13 : 0))
            .attr('y', labelY)
            .attr('text-anchor', textAnchor)
            .attr('dy', '0.35em')
            .style('fill', this.options.lightChartMode ? 'black' : '#FFFFFF')
            .style(
              'text-decoration',
              this.options.pieChart ? 'none' : 'underline'
            )
            .style('font-weight', this.options.pieChart ? 'bold' : 'normal')
            .style('font-size', this.options.threeWide ? '10px' : '12px')
            .text(text)
        }
      })
    }

    if (this.options.legendValues && !this.options.hideLegend) {
      const translateWidth = this.options.min
        ? width / 3
        : width / 3 +
          (this.options.pdfPie ? (this.options.threeWide ? 50 : 0) : 200)
      const translateHeight = this.options.pdfPie
        ? height / 3 - 200
        : this.options.min
          ? -(height / 3 + 45)
          : -(height / 3 + 140)
      let translate = `translate(${translateWidth}, ${translateHeight})`
      const legend = chart
        .append('g')
        .attr('class', 'legend')
        .attr('transform', translate)

      const legendItems = legend
        .selectAll('.legend-item')
        .data(this.options.legendValues.slice(0, legendLimit + 1))
        .enter()
        .append('g')
        .attr('class', 'legend-item')
        .attr('transform', (_, i) => `translate(0, ${i * 20})`)
        .style('max-height', height - 100)
        .style('overflow-y', 'auto')
        .on('mouseover', () => {
          tooltip?.style('opacity', 1)
        })
        .on('mousemove', (event: any, d: any) => {
          const [x, y] = pointer(event)
          const index = this.options.legendValues
            .slice(0, legendLimit + 1)
            .indexOf(d)
          const left = this.options.min
            ? `${width / 8 + x + 100}px`
            : `${x + width / 2}px`
          const top = this.options.min
            ? `${height / 8 + x + 100}px`
            : `${y + height / 2 + index * 20 - 190}px`
          tooltip
            .style('display', 'block')
            .style('opacity', 1)
            .style('left', left)
            .style('top', top)
            .style('text-align', 'center')
            .style('text-transform', 'capitalize').html(`
                <div>
                  <strong>${shortString(d, 50)}</strong>
                  <div>${this.data.values[index]} (${(
                    (this.data.values[index] / totalValue) *
                    100
                  ).toFixed(2)}%)</div>
                </div>
              `)
        })
        .on('mouseout', () => {
          tooltip.style('display', 'none')
        })

      legendItems
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', 6)
        .attr('fill', (_, i) => color(i.toString()))

      legendItems
        .append('text')
        .attr('x', 12)
        .attr('y', 0)
        .attr('dy', '0.3em')
        .style('fill', this.options.lightChartMode ? 'black' : '#FFFFFF')
        .style('text-transform', 'capitalize')
        .style('font-size', '14px')
        .text((d, i) => (i < legendLimit ? `${shortString(d)}` : 'Other'))
        .style('font-size', this.options.threeWide ? '10px' : '12px')
    }
  }
}
