<app-reinsurer-header
  [name]="name"
  [activeAction]="activeAction"
  [client]="client"
  [reinsurerDirty]="reinsurerDirty"
  [programs]="programs"
  [selectedProgramID]="selectedProgramID"
  [selectedUserPreferences]= "selectedUserPreferences"
  [userPreferencesColumnsList]="userPreferencesColumnsList"
  [defaultUSAUserPreferences]="defaultUSAUserPreferences"
  [accountOpportunities]="accountOpportunities"
  [opportunityRenewed]="opportunityRenewed"
  (saveClick)="saveClick.emit()"
  (exportClick)="exportClick.emit($event)"
></app-reinsurer-header>
<app-reinsurer-content
  [clientID]="clientID"
  [yearID]="yearID"
  [studyID]="studyID"
  [studies]="studies"
  [metricCategories]="metricCategories"
  [metricDetails]="metricDetails"
  [expandedMetricCategories]="expandedMetricCategories"
  [reinsurers]="filteredReinsurers"
  [reinsurersBlob]="reinsurersBlob"
  [reinsurerFilters]="reinsurerFilters"
  [reinsurerDomicileList]="reinsurerDomicileList"
  [reinsurerSPRatingList]="reinsurerSPRatingList"
  [reinsurerAmBestRatingList]="reinsurerAmBestRatingList"
  [lossFilters]="lossFilters"
  [programs]="programs"
  [selectedProgramID]="selectedProgramID"
  [accountOpportunities]="accountOpportunities"
  [clients]="clients"
  [reinsurerPopulateFrom]="reinsurerPopulateFrom"
  (saveClick)="saveClick.emit($event)"
  (updateMetricElement)="updateMetricElement.emit($event)"
  (collapseToggle)="collapseToggle.emit($event)"
  (setReinsurer)="setReinsurer.emit($event)"
  (showInfo)="showInfo.emit($event)"
  (updateOrAddDirty)="updateOrAddDirty.emit($event)"
  (reinsurerFilterToggle)="reinsurerFilterToggle.emit($event)"
  (removeAllFilters)="removeAllFilters.emit($event)"
  (removeFilter)="removeFilter.emit($event)"
  (addCustomMetric)="addCustomMetric.emit($event)"
  (enableAllClick)="enableAllClick.emit($event)"
  (populateSelectorClick)="populateSelectorClick.emit($event)"
  (setOneReinsurer)="setOneReinsurer.emit($event)"
  (showAgencyModal)="showAgencyModal.emit($event)"
  (showFundManagerModal)="showFundManagerModal.emit($event)"
  (deleteRe)="deleteRe.emit($event)"
></app-reinsurer-content>
