import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { SubjectivityColumnDef } from '../quote-panel/quote-panel-defs'
import {
  QuoteReinsurer,
  ReinsurerSubjectivity,
  Subjectivity,
} from '../models/quote.model'
import { clone } from 'ramda'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-subjectivity',
  styleUrls: ['./quote-subjectivity.component.scss'],
  templateUrl: './quote-subjectivity.component.html',
})
export class QuoteSubjectivityComponent implements OnChanges {
  isDisabled = false
  @Input() subjectivityOptions: string[]
  @Input() subjectivityColumnDef: SubjectivityColumnDef[]
  @Input() rows: Subjectivity[] = []
  @Input() selectedReinsurer: string | null
  @Input() formattedSubjectivityArray: any[] = []
  @Input() sectionID: string | null
  @Input() selectedQuoteReinsurer: QuoteReinsurer | undefined
  @Input() assignedLineReName: string | null
  @Input() assignedLineReId: number | null
  @Input() showAssignedLines = false
  @Input() structureRiskSubjectivityRows: ReinsurerSubjectivity[]
  @Input() selectedReinsurerMarketTpRef: string
  @Input() selectedCededLayerID: string | null | undefined
  tpRef: string
  updatedRows: Subjectivity[] = []
  readOnlyRows = new Map<Subjectivity, Set<keyof Subjectivity>>()

  populateFromLayersList: {
    id: any
    reinsurerName: string
    subjectivity: Subjectivity[]
  }[] = []

  @Output() addNewSubjectivity = new EventEmitter<{
    type: string
    reinsurer: string
  }>()
  @Output() updateSubjectivity = new EventEmitter<{
    reinsurerID: string
    subjectivityRows: Subjectivity[]
    deleteAlSubID?: string
  }>()
  @Output() deleteSubjectivity = new EventEmitter<{
    reinsurerID: string
    id: string
  }>()
  @Output() populateSubjectivityClick = new EventEmitter<{
    from: string
    to: string
  }>()

  ngOnChanges() {
    this.populateFromLayersList = []
    this.formattedSubjectivityArray.forEach(s => {
      if (
        this.selectedQuoteReinsurer?.quoteReinsurerName &&
        s.id !== this.sectionID &&
        s.hasOwnProperty(this.selectedQuoteReinsurer?.quoteReinsurerName) &&
        s[this.selectedQuoteReinsurer?.quoteReinsurerName].length > 0
      ) {
        this.populateFromLayersList.push({
          id: s.id,
          reinsurerName: this.selectedQuoteReinsurer?.quoteReinsurerName,
          subjectivity: s[this.selectedQuoteReinsurer?.quoteReinsurerName],
        })
      }
    })
    if (this.assignedLineReId && this.showAssignedLines) {
      this.rows = [
        ...this.rows.map(row => {
          return {
            ...row,
            ralId:
              row.ralId && row.ralId !== 0
                ? row.ralId
                : this.assignedLineReId ?? 0,
          }
        }),
      ]
    }
    if (this.assignedLineReName && this.showAssignedLines) {
      this.updatedRows = this.getMarketSubjectivity().filter(
        r => r.reinsurer === this.assignedLineReName
      )

    } else {
      this.updatedRows = this.getMarketSubjectivity()
    }
    // In cases of rows with same reinsurer name, this filter ensures that their subjectivities are seperate
    this.updatedRows = this.updatedRows.filter(
      r =>
        r.ralId === this.assignedLineReId ||
        r.applyToStructureForSameMarket === true ||
        r.layerId === this.selectedCededLayerID
    )

    this.updatedRows.forEach(row => {
      const readOnlyRow = new Set<keyof Subjectivity>()
      if (row.vendorName === 'WhiteSpace') {
        readOnlyRow.add('reinsCommentary')
        readOnlyRow.add('deadline')
        this.readOnlyRows.set(row, readOnlyRow)
      }
    })
    this.findMarketTpRef()
  }

  getMarketSubjectivity(): Subjectivity[] {
    if (
      !this.structureRiskSubjectivityRows ||
      this.structureRiskSubjectivityRows.length === 0
      ) {
      return this.rows
    }
    const subjectivityRows = clone(this.rows)
    const subjectivityRowsIds = this.rows.map(sub => sub.id)
    this.structureRiskSubjectivityRows.forEach(mSub => {
      if ((!this.showAssignedLines &&
        mSub.riskSubjectivity.reinsurer === this.selectedQuoteReinsurer?.quoteReinsurerName) ||
        (mSub.riskSubjectivity.reinsurer === this.assignedLineReName && this.showAssignedLines)
      ) {
        if (!subjectivityRowsIds.includes(mSub.riskSubjectivity.id)) {
          const isApplicable = this.showAssignedLines && !!mSub.riskSubjectivity.ralId || !this.showAssignedLines && !mSub.riskSubjectivity.ralId
          subjectivityRowsIds.push(mSub.riskSubjectivity.id)
          if (isApplicable) {
            const riskReinsurerId = mSub.riskSubjectivity.riskReinsurerId ?? Number(mSub.riskReinsurerId)
            subjectivityRows.push({
              ...mSub.riskSubjectivity,
              riskReinsurerId
            })
          }
        }
      }
    })
    return subjectivityRows
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<Subjectivity>): void {
    if (this.selectedReinsurer) {
      const tempRows: Subjectivity[] = clone(this.updatedRows)
      const row: Subjectivity | undefined = tempRows.find(r => r.id === id)
      const rowIdx: number = tempRows.findIndex(r => r.id === id)
      if (!row || rowIdx === -1) {
        return
      }
      const newRowState: Subjectivity = { ...row, [column.id]: value }
      tempRows[rowIdx] = newRowState
      this.updatedRows = tempRows.map(tr => {
        return {
          ...tr,
          marketTpRef: this.tpRef
        }
      })
      const reinsurerID = row.riskReinsurerId ? String(row.riskReinsurerId): this.selectedReinsurer
      this.updateSubjectivity.emit({
        reinsurerID,
        subjectivityRows: this.updatedRows,
      })
    }
  }

  onDeleteSubjectivity($event: { id: string }): void {
    const { id } = $event
    if (this.selectedReinsurer) {
      const tempRows = clone(this.updatedRows)
      const deletedRow = tempRows.find(r => r.id === id)
      const rows = tempRows.filter(r => r.id !== id)
      this.updatedRows = rows.map(r => {
        return {
          ...r,
          marketTpRef: this.selectedReinsurerMarketTpRef
        }
      })
      const reinsurerID = deletedRow?.riskReinsurerId ? String(deletedRow.riskReinsurerId): this.selectedReinsurer
      this.deleteSubjectivity.emit({
        reinsurerID,
        id: id,
      })
      this.updateSubjectivity.emit({
        reinsurerID,
        subjectivityRows: this.updatedRows,
        deleteAlSubID: id
      })
    }
  }

  onAddClick(type: string, reinsurer: string): void {
    this.addNewSubjectivity.emit({ type, reinsurer })
  }

  onPopulateFrom(r: {
    id: any
    reinsurerName: string
    subjectivity: Subjectivity[]
  }): void {
    const ids = this.updatedRows.map(ur => ur.id)
    const populatedSubjectivity: Subjectivity[] = r.subjectivity
      .map(sub => {
        return {
          ...sub,
          applyToStructureForSameMarket: false,
        }
      })
      .filter(ps => !ids.includes(ps.id))
    if (this.selectedReinsurer) {
      this.updateSubjectivity.emit({
        reinsurerID: this.selectedReinsurer,
        subjectivityRows: populatedSubjectivity,
      })
    }
  }

  findMarketTpRef(): void {
    let ref = this.selectedReinsurerMarketTpRef
    if(this.selectedReinsurerMarketTpRef === '' && this.assignedLineReName) {
      ref = this.selectedQuoteReinsurer?.riskAssignedLinesLink?.filter(al => al.reinsurer === this.assignedLineReName)[0].marketTpRef ?? ''
    }
    this.tpRef = ref
  }
}
