import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'emptyValue',
})
export class EmptyValuePipe implements PipeTransform {
  transform(value?: any): string | number {
    if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  }
}
