import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { sortBy } from 'ramda'
import { md5 } from '../../../../shared/util/hash'
import * as fromGrouperActions from '../grouper.actions'
import { ProgramGroupMember } from '../program-group.model'
import * as fromProgramGroupScenarioActions from '../program-group/program-group-scenarios.actions'
import * as fromProgramGroupActions from '../program-group/program-group.actions'
import * as fromProgramActions from '../program/program.actions'

export const DEFAULT_ORDINAL = 128
export const ROOT_ID = 'root'

export interface ProgramGroupMemberEntity {
  programGroupMember: ProgramGroupMember
  hash: string
  dirty: boolean
  new: boolean
  deleted: boolean
  root: boolean
}

export type ProgramGroupMemberEntityState =
  EntityState<ProgramGroupMemberEntity>

interface ProgramGroupMemberIDOption {
  id?: string
  programID?: string
  programGroupID?: string
  parentGroupID?: string
  throwIfEmpty?: boolean
  filterDeleted?: boolean
}

interface ProgramGroupMemberEntityOrIDOption
  extends ProgramGroupMemberIDOption {
  entity?: ProgramGroupMemberEntity
}

export const adapter = createEntityAdapter<ProgramGroupMemberEntity>({
  selectId: entity => entity.programGroupMember.id,
})

export const initialState: ProgramGroupMemberEntityState =
  adapter.getInitialState()

export const defaultEntity = {
  hash: '',
  dirty: false,
  new: false,
  deleted: false,
  root: false,
}

const createEntity = (
  programGroupMember: ProgramGroupMember,
  state: Partial<ProgramGroupMemberEntity> = {}
): ProgramGroupMemberEntity => ({
  ...defaultEntity,
  ...state,
  hash: md5(programGroupMember),
  programGroupMember,
})

export const createMemberID = (id?: string, parentGroupID?: string): string =>
  `${parentGroupID || ROOT_ID}_${id}`

export const createNewProgramEntity = (
  programID: string,
  ordinal?: number,
  parentGroupID?: string
) => {
  const root = parentGroupID == null
  const _parentGroupID = parentGroupID || ROOT_ID
  const id = createMemberID(programID, _parentGroupID)
  const m: ProgramGroupMember = {
    id,
    parentGroupID: _parentGroupID,
    programID,
    type: 'program',
    ordinal: ordinal || DEFAULT_ORDINAL,
  }
  return createEntity(m, { dirty: true, new: true, hash: md5(m), root })
}

export const createNewGroupEntity = (
  programGroupID: string,
  ordinal?: number,
  parentGroupID?: string,
  overrideRoot = false
) => {
  const root = overrideRoot || parentGroupID == null
  const _parentGroupID = parentGroupID || ROOT_ID
  const id = createMemberID(programGroupID, _parentGroupID)
  const m: ProgramGroupMember = {
    id,
    parentGroupID: _parentGroupID,
    programGroupID,
    type: 'programGroup',
    ordinal: ordinal || DEFAULT_ORDINAL,
  }
  return createEntity(m, { dirty: true, new: true, hash: md5(m), root })
}

export const updateReducer = (
  state: ProgramGroupMemberEntityState,
  entityOrIDOption: ProgramGroupMemberEntityOrIDOption,
  changes: Partial<ProgramGroupMember>
) => {
  let entity: ProgramGroupMemberEntity | undefined
  if (entityOrIDOption.entity != null) {
    entity = entityOrIDOption.entity
  } else {
    entity = selectBy(entityOrIDOption, state)
  }
  if (!entity) {
    return state
  }
  const id = entity.programGroupMember.id
  const programGroupMember = {
    ...entity.programGroupMember,
    ...changes,
  }
  return adapter.updateOne(
    {
      id,
      changes: {
        dirty: true,
        hash: md5(programGroupMember),
        programGroupMember,
      },
    },
    state
  )
}

const changeProgramGroupIDsReducer = (
  state: ProgramGroupMemberEntityState,
  idChanges: fromGrouperActions.IDChange[]
): ProgramGroupMemberEntityState => {
  return idChanges.reduce((acc, idChange) => {
    const pgm = selectByProgramGroupID(idChange.prev, acc, false)
    if (!pgm) {
      return acc
    }
    const programGroupID = idChange.next
    const programGroupMember = { ...pgm.programGroupMember, programGroupID }
    const changes = { programGroupMember }
    return adapter.updateOne({ id: pgm.programGroupMember.id, changes }, acc)
  }, state)
}

export const deleteReducer = (
  state: ProgramGroupMemberEntityState,
  entityOrIDOption: ProgramGroupMemberEntityOrIDOption
): ProgramGroupMemberEntityState =>
  _removeOrDeleteReducer(state, entityOrIDOption, false)

export const removeReducer = (
  state: ProgramGroupMemberEntityState,
  entityOrIDOption: ProgramGroupMemberEntityOrIDOption
): ProgramGroupMemberEntityState =>
  _removeOrDeleteReducer(state, entityOrIDOption, true)

const _removeOrDeleteReducer = (
  state: ProgramGroupMemberEntityState,
  entityOrIDOption: ProgramGroupMemberEntityOrIDOption,
  remove: boolean
): ProgramGroupMemberEntityState => {
  let entity: ProgramGroupMemberEntity | undefined
  if (entityOrIDOption.entity != null) {
    entity = entityOrIDOption.entity
  } else {
    entity = selectBy(entityOrIDOption, state)
  }
  if (!entity) {
    return state
  }
  const programMemberID = entity.programGroupMember.id
  if (remove || entity.new || entity.root) {
    return adapter.removeOne(programMemberID, state)
  } else {
    return adapter.updateOne(
      { id: programMemberID, changes: { deleted: true, dirty: true } },
      state
    )
  }
}

const programGroupMemberReducer = createReducer(
  initialState,

  on(
    fromProgramGroupActions.addProgramGroupSuccess,
    (state, { programGroupMembers, id, parentGroupID, ordinal }) => {
      // Add new member to the parent group
      if (!parentGroupID) {
        // Grouper reducer handles special case where no parentGroup specified
        const next1 = adapter.upsertMany(
          programGroupMembers.map(m => createEntity(m)),
          state
        )
        return next1
      }
      const entity = createNewGroupEntity(id, ordinal, parentGroupID)
      const next = adapter.addOne(entity, state)
      return adapter.upsertMany(
        programGroupMembers.map(m => createEntity(m)),
        next
      )
    }
  ),

  on(
    fromProgramActions.addProgramToGroup,
    (state, { program, parentGroupID, ordinal }) => {
      if (!parentGroupID) {
        // Grouper reducer handles special case where no parentGroup specified
        return state
      }
      const entity = createNewProgramEntity(program.id, ordinal, parentGroupID)
      return adapter.upsertOne(entity, state)
    }
  ),

  on(fromProgramActions.removeProgramFromGroup, (state, { program }) =>
    deleteReducer(state, {
      programID: program.id,
      parentGroupID: program.parentGroupID,
      throwIfEmpty: false,
    })
  ),

  on(fromProgramGroupActions.removeProgramGroup, (state, { id }) =>
    deleteReducer(state, { programGroupID: id, throwIfEmpty: false })
  ),

  on(fromProgramGroupActions.deleteProgramGroupSuccess, (state, { id }) =>
    deleteReducer(state, { programGroupID: id, throwIfEmpty: false })
  ),

  on(
    fromProgramActions.moveGrouperProgram,
    (state, { id, toGroupID, ordinal, fromGroupID }) => {
      const newState =
        fromGroupID !== ROOT_ID
          ? deleteReducer(state, {
              programID: id,
              parentGroupID: fromGroupID,
              throwIfEmpty: false,
            })
          : state
      const entity = createNewProgramEntity(id, ordinal, toGroupID)
      return adapter.upsertOne(entity, newState)
    }
  ),

  on(
    fromProgramGroupActions.addNewProgramGroup,
    (state, { label, parentGroupID, programIDs, isUntitled }) => {
      if (isUntitled) {
        // Add new group at root level and update parentGroupId of each program or program group
        const entity = createNewGroupEntity(
          label,
          undefined,
          parentGroupID,
          false
        )
        const next = adapter.addOne(entity, state)
        const updateProgramParentIdsList = selectAllByParentGroupID(
          parentGroupID,
          state
        )
          .filter(
            m =>
              m.programGroupMember.programID &&
              m.programGroupMember.programID !== label
          )
          .map(m => m.programGroupMember.programID)
        const updateProgramGroupParentIdsList = selectAllByParentGroupID(
          parentGroupID,
          state
        )
          .filter(
            m =>
              m.programGroupMember.programGroupID &&
              m.programGroupMember.programGroupID !== label
          )
          .map(m => m.programGroupMember.programGroupID)
        const next2 = updateProgramParentIdsList.reduce((data, programID) => {
          return updateReducer(data, { programID }, { parentGroupID: label })
        }, next)
        const next3 = updateProgramGroupParentIdsList.reduce(
          (data, programGroupID) => {
            return updateReducer(
              data,
              { programGroupID },
              { parentGroupID: label }
            )
          },
          next2
        )
        return next3
      } else {
        // Add new group at normal level and not root level.
        // If a new group is added, assign the ordinal of the structure to it, to prevent automatic rearranging of groups
        let entity: any
        const program = selectByProgramIDAndParentID(
          programIDs[0],
          parentGroupID,
          state,
          false
        )
        if (program) {
          const ordinal = program.programGroupMember.ordinal
          if (ordinal) {
            entity = createNewGroupEntity(label, ordinal, parentGroupID)
          }
        } else {
          entity = createNewGroupEntity(label, undefined, parentGroupID)
        }

        const next = adapter.addOne(entity, state)
        return programIDs.reduce((data, programID) => {
          return updateReducer(
            data,
            { programID, filterDeleted: true },
            { parentGroupID: label }
          )
        }, next)
      }
    }
  ),

  on(
    fromGrouperActions.saveGrouperSuccess,
    fromProgramGroupScenarioActions.saveProgramGroupScenariosSuccess,
    (state, { members, groups }) => {
      const { create, update, remove } = members
      const next = changeProgramGroupIDsReducer(state, groups.idChanges)
      const createEntities = create.map(g => createEntity(g))
      const updateEntities = update.map(u => ({
        ...u,
        changes: createEntity(u.changes as ProgramGroupMember),
      }))
      return adapter.removeMany(
        remove,
        adapter.updateMany(
          updateEntities,
          adapter.addMany(createEntities, next)
        )
      )
    }
  )
)

export function reducer(
  state: ProgramGroupMemberEntityState | undefined,
  action: Action
) {
  return programGroupMemberReducer(state, action)
}

// Helpers

export const { selectAll } = adapter.getSelectors()

export const sortByOrdinal = sortBy(
  (m: ProgramGroupMemberEntity) => m.programGroupMember.ordinal
)

export const selectByID = (
  id: string,
  state: ProgramGroupMemberEntityState,
  filterDelete: boolean
): ProgramGroupMemberEntity | undefined =>
  selectAll(state)
    .filter(pgm => {
      if (filterDelete) {
        return pgm.deleted === false
      } else {
        return true
      }
    })
    .find(pgm => pgm.programGroupMember.id === id)

export const selectByProgramID = (
  id: string,
  state: ProgramGroupMemberEntityState,
  filterDelete: boolean
): ProgramGroupMemberEntity | undefined =>
  selectAll(state)
    .filter(pgm => {
      if (filterDelete) {
        return pgm.deleted === false
      } else {
        return true
      }
    })
    .find(pgm => pgm.programGroupMember.programID === id)

export const selectByProgramIDAndParentID = (
  id: string,
  parentGroupID: string | undefined,
  state: ProgramGroupMemberEntityState,
  filterDelete: boolean
): ProgramGroupMemberEntity | undefined =>
  selectAll(state)
    .filter(pgm => pgm.programGroupMember.parentGroupID === parentGroupID)
    .filter(pgm => {
      if (filterDelete) {
        return pgm.deleted === false
      } else {
        return true
      }
    })
    .find(pm => pm.programGroupMember.programID === id)

export const selectByProgramGroupID = (
  id: string,
  state: ProgramGroupMemberEntityState,
  filterDelete: boolean
): ProgramGroupMemberEntity | undefined =>
  selectAll(state)
    .filter(pgm => {
      if (filterDelete) {
        return pgm.deleted === false
      } else {
        return true
      }
    })
    .find(pgm => pgm.programGroupMember.programGroupID === id)

export const selectAllByParentGroupID = (
  groupID: string | undefined,
  state: ProgramGroupMemberEntityState
): ProgramGroupMemberEntity[] =>
  sortByOrdinal(
    selectAll(state).filter(m => m.programGroupMember.parentGroupID === groupID)
  )

export const selectAllUndeletedByParentGroupID = (
  groupID: string,
  state: ProgramGroupMemberEntityState
): ProgramGroupMemberEntity[] =>
  selectAllByParentGroupID(groupID, state).filter(m => !m.deleted)

export const selectBy = (
  {
    id,
    programID,
    programGroupID,
    parentGroupID,
    throwIfEmpty,
    filterDeleted,
  }: ProgramGroupMemberIDOption,
  state: ProgramGroupMemberEntityState
): ProgramGroupMemberEntity | undefined => {
  const _throwIfEmpty = throwIfEmpty != null ? throwIfEmpty : true
  const _filterDeleted = filterDeleted != null ? filterDeleted : false
  let _entity: ProgramGroupMemberEntity | undefined
  if (id != null) {
    _entity = selectByID(id, state, _filterDeleted)
    if (_throwIfEmpty && !_entity) {
      throw Error(`No program group member found w/ ID ${id}`)
    }
  } else if (programID != null && !parentGroupID) {
    _entity = selectByProgramID(programID, state, _filterDeleted)
    if (_throwIfEmpty && !_entity) {
      throw Error(`No program group member found for program w/ ID ${id}`)
    }
  } else if (programID != null && parentGroupID) {
    _entity = selectByProgramIDAndParentID(
      programID,
      parentGroupID,
      state,
      _filterDeleted
    )
    if (_throwIfEmpty && !_entity) {
      throw Error(
        `No program group member found for program w/ ID ${programID} and ParentID ${parentGroupID}`
      )
    }
  } else if (programGroupID != null) {
    _entity = selectByProgramGroupID(programGroupID, state, _filterDeleted)
    if (_throwIfEmpty && !_entity) {
      throw Error(`No program group member found for program group w/ ID ${id}`)
    }
  }
  if (_throwIfEmpty && !_entity) {
    throw Error(`No ID provided for select member`)
  }
  return _entity
}

export const selectAllByID = (
  ids: string[],
  state: ProgramGroupMemberEntityState
): ProgramGroupMemberEntity[] =>
  sortByOrdinal(
    ids
      .map(pgmID => state.entities[pgmID])
      .filter(pgm => pgm != null && !pgm.deleted) as ProgramGroupMemberEntity[]
  )

export const selectDirtyNewProgramGroupMember = (
  state: ProgramGroupMemberEntityState
) => selectAll(state).filter(pgm => pgm.dirty || pgm.new)
