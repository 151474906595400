import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs'
import * as fromSupportEmailActions from './contact-support.actions'
import { ContactSupportService } from 'src/app/api/contact-support/contact-support.service'
import { AppState } from 'src/app/core/store'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from 'src/app/api/util'
import { BackendService } from 'src/app/api/backend/backend.service'
import { selectUserName } from 'src/app/core/store/auth/auth.selectors'
import {
  fetchAllUsersFailure,
  fetchAllUsersSuccess,
} from './contact-support.actions'

@Injectable()
export class ContactSupportEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: ContactSupportService,
    private backendService: BackendService
  ) {}

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSupportEmailActions.fetchAllUsers),
      withLatestFrom(this.store.pipe(select(selectUserName))),
      filter(([action, sageUserName]) =>
        sageUserName
          .split('@')[1]
          ?.toUpperCase()
          .endsWith('lockton.com'.toUpperCase())
      ),
      mergeMap(() =>
        this.backendService.getAllSalesforceUsers().pipe(
          map(result => fetchAllUsersSuccess({ allUSers: result.data })),
          catchError(error => of(fetchAllUsersFailure({ error })))
        )
      )
    )
  )

  sendSupportEmail = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromSupportEmailActions.sendSupportEmail),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.sendSupportEmail(props.contactSupport)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromSupportEmailActions.sendSupportEmailFailure({
            error,
          })
        )
      ),
      map(([data]) => {
        return fromSupportEmailActions.sendSupportEmailSuccess({
          response: data,
        })
      })
    )
  })
}
