import { Action, createReducer, on } from '@ngrx/store'
import { LayerViewValues } from '../../model/layer-view'
import { LayerModelingDimension } from '../layer-modeling.model'
import { setLayerModelingDimensionProp } from './layer-modeling.actions'

export const LAYER_MODELING_FEATURE_KEY = 'layerModeling'

export type LayerModelingState = Record<
  LayerModelingDimension,
  keyof LayerViewValues
>

const initState: LayerModelingState = {
  x: 'grossVolatilityCeded',
  y: 'expectedCededLossRatio',
  size: 'premium',
}

const layerModelingReducer = createReducer(
  initState,

  on(setLayerModelingDimensionProp, (state, { dimension, prop }) => ({
    ...state,
    [dimension]: prop,
  }))
)

export function reducer(
  state: LayerModelingState | undefined,
  action: Action
): LayerModelingState {
  return layerModelingReducer(state, action)
}
