<app-program-initiation-header
  [name]="name"
  [allCarriers]="allCarriers"
  [accountOpportunities]="accountOpportunities"
  [carrierAccounts]="carrierAccounts"
  (updateSFMapping)="updateSFMapping.emit($event)"
></app-program-initiation-header>
<app-program-initiation-content
  [accountOpportunities]="accountOpportunities"
  [carrierAccounts]="carrierAccounts"
  [isSaving]="isSaving"
  [carriersByID]="carriersByID"
  [allCarriers]="allCarriers"
  [unfilteredAccountOpportunities]="unfilteredAccountOpportunities"
  [currentClientID]="currentClientID"
  (newProgramCreate)="newProgramCreate.emit($event)"
  (newYearProgramCreate)="newYearProgramCreate.emit($event)"
  (newCarrierYearProgramCreate)="newCarrierYearProgramCreate.emit($event)"
  (filteredAccountOpportunities)="filteredAccountOpportunities.emit($event)"
>
</app-program-initiation-content>
