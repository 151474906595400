import { INewBox, IMoneyBounds } from './tower.model'

export class Placement {
  _getHorizontalFromAttachment(
    attachment: number,
    widgetHeight: any,
    highBound: any
  ) {
    return (widgetHeight * attachment) / highBound
  }

  _getHorizontalFromLimit(limit: number, widgetHeight: any, highBound: any) {
    return (widgetHeight * limit) / highBound
  }

  setYAxisValues(item: INewBox, chartHeight: any, bound: IMoneyBounds) {
    let highBound = 0
    let limit = 0
    let attachment = 0
    if (item.id.startsWith('LOcc')) {
      highBound = bound.mostOccurrence
    } else {
      highBound = bound.mostAggregate
    }
    limit = item.convertedLimit > 0 ? item.convertedLimit : item.limit
    attachment =
      item.franchiseDeductible > 0
        ? item.franchiseDeductible
        : item.convertedAttachment > 0
        ? item.convertedAttachment
        : item.attachment
    const newY = this._getHorizontalFromAttachment(
      attachment,
      chartHeight,
      highBound
    )
    const newH = this._getHorizontalFromLimit(limit, chartHeight, highBound)
    item.y = chartHeight - (newY + newH)
    item.boxHeight = newH
  }

  trimLayer(item: INewBox, other: INewBox) {
    if (
      (item.franchiseDeductible > 0
        ? item.franchiseDeductible
        : item.attachment) <
      (other.franchiseDeductible > 0
        ? other.franchiseDeductible
        : other.attachment)
    ) {
      // it's above
      if (
        (item.franchiseDeductible > 0
          ? item.franchiseDeductible
          : item.attachment) +
          item.limit >
        (other.franchiseDeductible > 0
          ? other.franchiseDeductible
          : other.attachment)
      ) {
        // it's above
        item.limit =
          (other.franchiseDeductible > 0
            ? other.franchiseDeductible
            : other.attachment) -
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment)
        return true
      } else if (
        (item.franchiseDeductible > 0
          ? item.franchiseDeductible
          : item.attachment) >
        other.limit +
          (other.franchiseDeductible > 0
            ? other.franchiseDeductible
            : other.attachment)
      ) {
        // it's below
        if (item.franchiseDeductible > 0) {
          item.franchiseDeductible = other.limit + other.franchiseDeductible
        } else {
          item.attachment = other.limit + other.attachment
        }
        return true
      }
    } else if (other.cessionX > item.cessionX) {
      item.cession = Number((other.cessionX - item.cessionX).toFixed(4))
      return true
    } else {
      item.limit = 0
      item.cessionX = 1
    }
    return false
  }

  trim(otherBoxes: INewBox[], item: INewBox) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < otherBoxes.length; i++) {
      const other: any = otherBoxes[i]
      while (this.clash(item, other)) {
        this.trimLayer(item, other)
      }
    }
    if (item.cessionX + item.cession > 1) {
      item.cession = 1 - item.cessionX
    }
  }

  clash(item: INewBox, other: INewBox) {
    return (
      Number(item.cessionX.toFixed(4)) <
        Number((other.cessionX + other.cession).toFixed(4)) &&
      Number((item.cessionX + item.cession).toFixed(4)) >
        Number(other.cessionX.toFixed(4)) &&
      item.limit +
        (item.franchiseDeductible > 0
          ? item.franchiseDeductible
          : item.attachment) >
        (other.franchiseDeductible > 0
          ? other.franchiseDeductible
          : other.attachment) &&
      (item.franchiseDeductible > 0
        ? item.franchiseDeductible
        : item.attachment) <
        other.limit +
          (other.franchiseDeductible > 0
            ? other.franchiseDeductible
            : other.attachment)
    )
  }

  overlap(
    _boxes: INewBox[],
    item: INewBox,
    otherBoxes: INewBox[],
    oldItem: INewBox,
    action: string
  ) {
    let collision = false
    Object.keys(otherBoxes).forEach((k: any, _i) => {
      const other: any = otherBoxes[k]
      if (this.clash(item, other)) {
        collision = true
        if (action === 'new') {
          if (oldItem) {
            item.attachment = oldItem.attachment
            item.limit = oldItem.limit
            item.cessionX = oldItem.cessionX
            item.franchiseDeductible = oldItem.franchiseDeductible
          }
        } else if (action === 'snapRight') {
          item.cession = other.cessionX - item.cessionX
        } else if (action === 'snapLeft') {
          item.cessionX = oldItem.cessionX
          item.cession = oldItem.cession
        } else if (action === 'top') {
          item.limit =
            (other.franchiseDeductible > 0
              ? other.franchiseDeductible
              : other.attachment) -
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment)
          if (item.franchiseDeductible > 0) {
            item.franchiseDeductible = oldItem.franchiseDeductible
          } else {
            item.attachment = oldItem.attachment
          }
        } else if (action === 'bottom') {
          if (item.franchiseDeductible > 0) {
            item.franchiseDeductible =
              (other.franchiseDeductible > 0
                ? other.franchiseDeductible
                : other.attachment) + other.limit
          } else {
            item.attachment = other.attachment + other.limit
          }
          item.limit =
            (oldItem.franchiseDeductible > 0
              ? oldItem.franchiseDeductible
              : oldItem.attachment) +
            oldItem.limit -
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment)
        }
      }
    })
    return collision
  }

  snapLeft(item: INewBox, occupied: INewBox[]) {
    item.cessionX = 0
    occupied = occupied
      .slice()
      .sort((a: { cessionX: number }, b: { cessionX: number }) => {
        return a.cessionX - b.cessionX
      })
    occupied.forEach((_k: any, i: number) => {
      const other = occupied[i]
      if (
        item.limit +
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment) >
          (other.franchiseDeductible > 0
            ? other.franchiseDeductible
            : other.attachment) &&
        (item.franchiseDeductible > 0
          ? item.franchiseDeductible
          : item.attachment) <
          other.limit +
            (other.franchiseDeductible > 0
              ? other.franchiseDeductible
              : other.attachment)
      ) {
        if (
          this.clash(item, other) &&
          Number((other.cessionX + other.cession).toFixed(4)) > item.cessionX
        ) {
          item.cessionX = Number((other.cessionX + other.cession).toFixed(4))
        }
      }
    })
  }

  setInitialX(item: INewBox, _boxes: INewBox[], chartWidth: any) {
    const occupied = _boxes.filter(
      box => box.id !== item.id && !(box.attachment === 0 && box.limit === 1)
    )
    for (const i in occupied) {
      if (
        !(item.attachment === 0 && item.limit === 1) &&
        this.clash(item, occupied[i])
      ) {
        const newCessionX = occupied[i].cessionX + occupied[i].cession
        if (newCessionX + item.cession <= 1) {
          item.cessionX = newCessionX
          item.x = (item.cessionX / 1) * chartWidth
        }
      }
    }
  }

  getOccupied(boxes: INewBox[], item: INewBox) {
    const occupied: any = []
    let counter = 0
    boxes.forEach(xob => {
      if (xob.id !== item.id && !(xob.attachment === 0 && xob.limit === 1)) {
        occupied[counter] = xob
        counter += 1
      }
    })
    return occupied
  }
}
