<div class="row">
  <div class="column submission-data">
    <h1 mat-dialog-title>Structure Details</h1>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [value]="data.currentProgram.label" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput [value]="data.currentProgram.description" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Broker Contact</mat-label>
      <input matInput [value]="data.authState.name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Broker Email</mat-label>
      <input matInput [value]="data.authState.username" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Notes</mat-label>
      <input matInput value="" />
    </mat-form-field>
  </div>
  <div class="column files-container">
    <h1 mat-dialog-title>Submission Files</h1>
    <table class="filesTable">
      <colgroup>
        <col span="1" class="column-main" />
        <col span="1" class="column-remaining" />
        <col span="1" class="column-remaining" />
      </colgroup>
      <thead>
        <tr>
          <th class="file-table-header">Name</th>
          <th class="file-table-header">Class</th>
          <th class="file-table-header">Date</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let file of availableFiles; let i = index">
          <tr>
            <td class="file-table-column">
              <div class="name-column-container">
                <div class="name-column-container-checkbox">
                  <mat-checkbox
                    (click)="setSubmittedFile(file)"
                  ></mat-checkbox>
                </div>
                <div class="name-column-container-icon">
                  <mat-icon
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="file"
                    class="file-icon"
                    [svgIcon]="getIconFromFile(file.fileName)"
                  >
                  </mat-icon>
                  <mat-menu class="primary" #menu="matMenu">
                    <button mat-menu-item (click)="fileClick(file)">
                      View
                    </button>
                  </mat-menu>
                </div>
                <div
                  class="fileName"
                  (mouseenter)="mouseEnter($event, file.name)"
                  (mouseleave)="mouseOut()"
                >
                  {{ file.name }}
                </div>
              </div>
            </td>
            <td class="file-table-column">
              <div
                class="fileName"
                (mouseenter)="mouseEnter($event, file.class)"
                (mouseleave)="mouseOut()"
              >
                {{ file.class }}
              </div>
            </td>
            <td class="file-table-column">
              <div
                class="fileName"
                (mouseenter)="mouseEnter($event, file.date)"
                (mouseleave)="mouseOut()"
              >
                {{ file.date }}
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="column">
    <h1 mat-dialog-title>Tower</h1>
    <app-tower
      [currentStructure]="data.currentProgram"
      [layers]="data.layers"
      [readonly]="true"
      [structureLayerData]="data.startingCession"
      [sharedIDGroup]="data.sharedIDPortfolio"
      [widthOverride]="getWidth()"
      [heightOverride]="getHeight()"
      [widthPercentage]="1"
      [heightPercentage]="1"
      [showAgg]="showAgg()"
      [showOcc]="true"
      [highlight]="highlight"
    >
    </app-tower>
  </div>
</div>
<div class="row">
  <div class="column layer-selection-padding">
    <h1 mat-dialog-title>Layer / Reinsurer Selection</h1>
  </div>
</div>
<div class="row">
  <div class="max-width-input column">
    <mat-form-field>
      <mat-label>Layer(s)</mat-label>
      <mat-select [formControl]="layerListControl" multiple>
        <mat-select-trigger>
          {{ getLayerText() }}
        </mat-select-trigger>
        <mat-option
          [value]="1"
          (click)="selectAll(allSelected)"
          #allSelected
          >Select All</mat-option
        >
        <mat-option
          (mouseleave)="highlightLayers()"
          (mouseover)="highlightLayer(layer.layer)"
          *ngFor="let layer of layerList"
          [value]="layer.layer"
          (click)="layerClicked(ev)"
          #ev
        >
          {{ layer.display }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="max-width-input column">
    <mat-form-field>
      <mat-label>Reinsurer(s)</mat-label>
      <mat-select [formControl]="reinsurerListControl" multiple>
        <mat-select-trigger>
          {{
            reinsurerListControl.value ? reinsurerListControl.value[0] : ''
          }}
          <span *ngIf="reinsurerListControl.value?.length > 1">
            (+{{ reinsurerListControl.value.length - 1 }}
            {{
              reinsurerListControl.value?.length === 2 ? 'other' : 'others'
            }})
          </span>
        </mat-select-trigger>
        <mat-option
          *ngFor="let reinsurer of reinsurerList"
          [value]="reinsurer"
          (click)="reinsurerClicked(ev)"
          #ev
          >{{ reinsurer }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="max-width-input column">
    <mat-form-field>
      <mat-label>Underwriter</mat-label>
      <mat-select [formControl]="underwriterControl">
        <mat-select-trigger>
          {{ underwriterControl.value ? underwriterControl.value : '' }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let reinsurer of underwriterList"
          [value]="reinsurer"
          >{{ reinsurer }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="max-width-input column button-padding">
    <div>
      <button mat-raised-button (click)="add()">Add</button>
      <!--<button
        mat-raised-button
        class="tremor-button"
        (click)="tremor()"
      >
        <img src="https://tremor.co/assets/tremor-logo.svg" />
      </button>-->
    </div>
  </div>
</div>
<div class="row">
  <div class="column">
    <div class="container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="filesTable">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell class="no-wrap" *matCellDef="let element">
            <mat-icon class="status">{{ element.status }}</mat-icon>
            <span class="status-text">{{ getStatus(element.status) }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="layer">
          <th mat-header-cell *matHeaderCellDef>Layer</th>
          <td mat-cell *matCellDef="let element">
            {{ layerDetailsString(element.layer) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="reinsurer">
          <th mat-header-cell *matHeaderCellDef>Reinsurer</th>
          <td mat-cell *matCellDef="let element">
            {{ element.reinsurer }}
          </td>
        </ng-container>
        <ng-container matColumnDef="underwriter">
          <th mat-header-cell *matHeaderCellDef>Underwriter</th>
          <td mat-cell *matCellDef="let element">
            {{ element.underwriter }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rol">
          <th mat-header-cell *matHeaderCellDef>Rate on Line</th>
          <td mat-cell *matCellDef="let element">
            {{ element.rol }}
          </td>
        </ng-container>
        <ng-container matColumnDef="depositpremium">
          <th mat-header-cell *matHeaderCellDef>Deposit Premium</th>
          <td mat-cell *matCellDef="let element">
            {{ element.layer.layer.physicalLayer.premium.value }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef>Rate</th>
          <td mat-cell *matCellDef="let element">
            {{ getROL(element.layer.layer.physicalLayer.meta_data.rol) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="reinstatements">
          <th mat-header-cell *matHeaderCellDef>Reinstatements</th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngFor="
                let reinstatement of formatValueReinstatement(
                  element.layer.layer.physicalLayer.reinstatements
                );
                let i = index
              "
            >
              {{ reinstatement.counter }} @
              {{ reinstatement.formattedValue }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="subjectpremium">
          <th mat-header-cell *matHeaderCellDef>Subject Premium</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.layer.layer.physicalLayer.meta_data.subject_premium
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="occurrencelimit">
          <th mat-header-cell *matHeaderCellDef>Occurrence Limit</th>
          <td mat-cell *matCellDef="let element">
            {{
              getDollarValue(
                element.layer.layer.physicalLayer.franchise.currency,
                element.layer.layer.physicalLayer.limit.value
              )
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lossOnLine">
          <th mat-header-cell *matHeaderCellDef>Loss-on-Line</th>
          <td mat-cell *matCellDef="let element">
            {{ getMetricsLossOnLineValue(element.layer.layer.id) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="purePremium">
          <th mat-header-cell *matHeaderCellDef>
            Expected Ceded Loss (Pure Premium)
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              getMetricsPremiumValue(
                element.layer.layer.id,
                element.layer.layer.physicalLayer.franchise.currency
              )
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="attachmentpoint">
          <th mat-header-cell *matHeaderCellDef>Attachment Point</th>
          <td mat-cell *matCellDef="let element">
            {{
              getDollarValue(
                element.layer.layer.physicalLayer.franchise.currency,
                element.layer.layer.physicalLayer.attachment.value
              )
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="aggregatelimit">
          <th mat-header-cell *matHeaderCellDef>Aggregate Limit</th>
          <td mat-cell *matCellDef="let element">
            {{
              getDollarValue(
                element.layer.layer.physicalLayer.franchise.currency,
                element.layer.layer.physicalLayer.aggregateLimit.value
              )
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="capacity">
          <th mat-header-cell *matHeaderCellDef>Capacity</th>
          <td mat-cell *matCellDef="let element">
            {{ element.capacity }}
          </td>
        </ng-container>
        <ng-container *ngIf="isNew()" matColumnDef="submit">
          <th mat-header-cell *matHeaderCellDef (click)="submitAll()">
            <button mat-stroked-button>Submit</button>
          </th>
          <td mat-cell class="no-wrap" *matCellDef="let element">
            <mat-icon
              class="actions"
              *ngIf="element.status == 'add'"
              matTooltip="Delete"
              (click)="cancel(element)"
              >close</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>