import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { GroupBarChart, GroupBarDatum } from '@graphing/group-bar-chart'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import {
  SummaryChartInfo,
  SummaryChartGridOption,
  SummaryChartGridMetric,
} from '../summary-charts-model'
import html2canvas from 'html2canvas'
import { convertToPercent } from 'src/app/quote/management-information/utils/quote-charts.util'
import { currencySymbol } from 'src/app/analysis/model/layers.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-group-bar-chart',
  template: `
    <app-explore-summary-chart-grid-item-frame
      #grid
      class="chart"
      [chartOption]="chartOption"
      [hasData]="chartOption.data?.data.length > 0"
      [selectableChartsForTab]="selectableChartsForTab"
      [selectableChartsForMetric]="selectableChartsForMetric"
      [maximizedIndex]="maximizedIndex"
      [canHideMetrics]="!chartOption.metric.isPureLayerMetric"
      [hideMetrics]="hideMetrics"
      [pdfView]="pdfView"
      (updateHideMetrics)="onUpdateHideMetrics($event)"
      (chartOptionUpdate)="chartOptionUpdate.emit($event)"
      (maximizeClick)="maximizeClick.emit($event)"
      (onExportClick)="downloadPNG()"
    >
      <div
        id="chart"
        #chart
        [ngClass]="{
          'app-chart': true,
          'app-chart-tiny-annotation': chartOption.index !== maximizedIndex,
          'white-background': lightChartMode,
        }"
      ></div>
    </app-explore-summary-chart-grid-item-frame>
  `,
  styles: [
    `
      .app-chart-tiny-annotation {
        display: flex;
        height: 100%;
        width: 100%;
        flex-shrink: 0;
      }
      svg.app-chart-tiny-annotation {
        height: 100%;
        width: 100%;
      }
      .app-chart {
        background-color: black;
        padding: 25px 10px;
        overflow: visible;
      }
      .white-background {
        color: black;
        background-color: white !important;
      }
    `,
  ],
})
export class ExploreSummaryGroupBarChartComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges
{
  @Input() chartOption: SummaryChartGridOption
  @Input() maximizedIndex: number
  @Input() chartEntityLimit: number
  @Input() selectableChartsForTab: SummaryChartInfo[]
  selectableChartsForMetric: SummaryChartInfo[]
  @Input() drawDebounce = 250
  @Input() hideMetrics: boolean
  @Input() lightChartMode: boolean
  @Input() pdfView: boolean
  @Input() zipLoading: boolean
  @Input() roundedToAbrev: string
  @Input() currentStructureCurrency: string | undefined
  @Input() threeWide: boolean

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  @ViewChild('chart', { static: false }) chartEl: ElementRef<HTMLDivElement>
  @ViewChild('grid', { static: false }) gridEl: ElementRef<HTMLDivElement>

  chart: GroupBarChart
  draw$ = new Subject<void>()
  destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.draw$
      .pipe(takeUntil(this.destroy$), debounceTime(this.drawDebounce))
      .subscribe(() => this.draw())
  }

    ngAfterViewInit(): void {
    this.selectableChartsForMetric = this.chartOption.metric.applicableCharts
    this.draw$.next()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideMetrics || changes.lightChartMode) {
      this.draw$.next()
    }
    if (changes.chartEntityLimit || changes.maximizedIndex) {
      setTimeout(() => this.draw(), 500)
    }

  }

  onUpdateHideMetrics(hideMetrics = false): void {
    this.updateHideMetrics.emit(hideMetrics)
  }

  downloadPNG(): void {
    const pngEl: HTMLElement | null = document.querySelector('#chart')
    if (pngEl) {
      html2canvas(pngEl).then(canvas => {
        const image = canvas.toDataURL('image/png')
        const yLabel = this.chartOption.metric.name
        const chartLabel = yLabel
        const a = document.createElement('a')
        a.setAttribute('download', `${chartLabel}.png`)
        a.setAttribute('href', image)
        a.click()
      })
    }
  }

  draw(): void {
    if (!this.chartEl) {
      return
    }
    const min = !this.pdfView && this.chartOption.index !== this.maximizedIndex
    const metricName = this.chartOption.metric.name
    const chartLabel = metricName
    const format = this.chartOption.metric.format
    this.chart = new GroupBarChart(this.chartEl.nativeElement, {
      chartLabel: !min ? chartLabel : undefined,
      metricPrimary: this.chartOption.metric.metricPrimaryName,
      metricSecondary: this.chartOption.metric.metricSecondaryName,
      metricTertiary: this.chartOption.metric.metricTertiaryName,
      metricQuaternary: this.chartOption.metric.metricQuaternaryName,
      min,
      percent: format === 'percent',
      lightChartMode: this.lightChartMode,
      multiColor: !this.chartOption.metric.name.includes('Ratio'),
      currencyFormat: this.currencyFormat,
      threeWide: this.threeWide

    })
    this.chart.draw(this.getChartData(format ?? ''))
  }

  getChartData(format: string): GroupBarDatum[] {
    if (!this.chartOption.data?.data) {
      return []
    }
    const data = [...this.chartOption.data?.data]
    return data
      .sort((a, b) => b.metricPrimary - a.metricPrimary)
      .slice(0, this.chartEntityLimit)
      .map((d, i) => {
        const values =
          format === 'percent'
            ? [
                convertToPercent(d.metricPrimary),
                convertToPercent(d.metricSecondary ?? 0),
                convertToPercent(d.metricTertiary ?? 0),
                convertToPercent(d.metricQuaternary ?? 0),
              ]
            : [
                d.metricPrimary,
                d.metricSecondary ?? 0,
                d.metricTertiary ?? 0,
                d.metricQuaternary ?? 0,
              ]
        return {
          groupBy: this.chartOption.metric.isPureLayerMetric
            ? `Pure Layer ${i}`
            : this.hideMetrics
              ? `${i + 1}`
              : d.groupBy,
          values,
        }
      })
  }


  currencyFormat(value: number): string {
    const factor = this.roundedToAbrev === 'M' ? 1e6 : 1e3
    const roundedValue = Math.round(value / factor)
    const formattedValue = Number(roundedValue).toLocaleString(undefined, { maximumFractionDigits: 0 })
    return `${currencySymbol(this.currentStructureCurrency)}${formattedValue}`
  }
}
