import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Broker } from '../../core/model/broker.model'
import { NavService } from '../../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-broker-header',
  styleUrls: ['./broker-header.component.scss'],
  templateUrl: './broker-header.component.html',
})
export class BrokerHeaderComponent {
  @Input() broker: Broker
  @Input() sageLogicalRole: string | null
  @Input() title = 'Design Structures'
  constructor(public navService: NavService) {}
}
