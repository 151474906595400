<ng-container *ngIf="open">
  <div class="toggle" *ngIf="isEdit">
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      class="app-mat-theme"
      aria-label="Return Period Type 1"
      value="Layer"
      [(ngModel)]="type"
    >
      <mat-button-toggle value="Layer" disableRipple ngDefaultControl>
        <span class="label">Layer </span>Properties
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="!isLibRE"
        value="Metric"
        disableRipple
        ngDefaultControl
      >
        <span class="label">Layer </span>Results
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="shared-limit" *ngIf="isAddSharedLayer">
    <h2>Add Shared Limit</h2>
    <label appInfoText big class="count">
      <span [ngClass]="layerCountClass">{{ layerCount }}</span>
      <span>&nbsp;</span>
      <span>{{ layerCountText }}</span>
    </label>
    <label appInfoText small italic>
      <span>Click Layers in the tower to select</span>
    </label>
  </div>

  <div class="shared-limit" *ngIf="isDeleteSharedLayer">
    <h2>Edit/ Delete Shared Limit</h2>
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      class="app-mat-theme"
      aria-label="Return Period Type 1"
      value="Layer"
      [(ngModel)]="type"
    >
      <mat-button-toggle value="Layer" disableRipple ngDefaultControl>
        <span class="label">Layer </span>Properties
      </mat-button-toggle>
      <mat-button-toggle
        value="Metric"
        [disabled]="isLibRE"
        disableRipple
        ngDefaultControl
      >
        <span class="label">Layer </span>Results
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <app-properties-container
    class="layer"
    [id]="id"
    [selectedViewID]="selectedViewID"
    [groupValue]="type"
    [isAddSharedLayer]="isAddSharedLayer"
    [isDeleteSharedLayer]="isDeleteSharedLayer"
    [metrics]="metrics"
    [designDirty]="designDirty"
    [isLibRE]="isLibRE"
    (handlePastedLayer)="onHandlePastedLayer($event)"
    (selectedSLCurrency)="selectedSLCurrency.emit($event)"
    (structureCurrency)="onStructureCurrencyEvent($event)"
  ></app-properties-container>

  <section class="actions" *ngIf="isAddSharedLayer">
    <ng-container *ngIf="!activeAction; else actionRunning">
      <button
        appButton
        big
        accent
        border
        [disabled]="isDisabled"
        (click)="saveSharedLimitClick.emit()"
      >
            <span
              matTooltip="Click to Save this Shared Limit arrangement"
              matTooltipPosition="above"
              [matTooltipDisabled]="isDisabled"
            >
              Save
            </span>
      </button>

      <button appButton big link (click)="cancelClick.emit()">
        <span>Cancel</span>
      </button>
    </ng-container>
    <ng-template #actionRunning>
      <app-active-action [value]="activeAction"></app-active-action>
    </ng-template>
  </section>
</ng-container>
