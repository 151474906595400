import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reconcile-warning',
  templateUrl: './reconcile-warning-dialog.component.html',
})
export class ReconcileWarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ReconcileWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReconcileWarningDialogComponent
  ) {}

  cancel() {
    this.dialogRef.close()
  }
}
