import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { OptimizationInputRange } from '../optimization.model'
import * as fromOptimizationRangeActions from './optimization-ranges.actions'
import * as fromOptimizationActions from './optimization.actions'

export interface ExtendedState {
  id: string
  lossSetGroupIDs: string[]
}

export type State = EntityState<OptimizationInputRange> & ExtendedState

export const adapter = createEntityAdapter<OptimizationInputRange>()

export const initialState = adapter.getInitialState<ExtendedState>({
  id: '',
  lossSetGroupIDs: [],
})

const rangeReducer = createReducer(
  initialState,
  on(fromOptimizationRangeActions.setRanges, (state, { ranges }) =>
    adapter.setAll(ranges, state)
  ),
  on(fromOptimizationRangeActions.updateRanges, (state, { id, changes }) =>
    adapter.updateOne({ id, changes }, state)
  ),
  on(fromOptimizationActions.reset, () => ({ ...initialState }))
)

export function reducer(state: State | undefined, action: Action) {
  return rangeReducer(state, action)
}
