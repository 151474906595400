<div>
  <div class="header">
    <h3>Quote Sage Term Selectors Master List</h3>
    <button class ="default-button"
      (click)="onDefaultToggleSelection()"
      appButton
      big
      accent
      tabindex="-1"
    >
      Set Default
    </button>
    <mat-slide-toggle
      class="check-all"
      [checked]="toggleAll()"
      (change)="onToggleAllChange($event)"
    >Select All</mat-slide-toggle
    >
  </div>
  <div class="checkbox-grid">
    <div *ngFor="let def of quotePanelDefs" class="checkbox-item">
      <mat-checkbox
        [checked]="exportSelected(def)"
        (change)="onUpdateExportToggleSelection(def, $event)"
        [matTooltip]="tooltipText(def)"
        matTooltipPosition="after"
      >
        <span class="checkbox-label">{{ def.label }}</span>
      </mat-checkbox>
    </div>
  </div>
</div>
