import { createSelector } from '@ngrx/store'
import { AppState } from '../index'

export const selectThemeState = (state: AppState) => state.theme

export const selectIsThemeDark = createSelector(
  selectThemeState,
  state => state.dark
)

export const selectIsThemeChrome = createSelector(
  selectThemeState,
  state => state.chrome
)

export const selectIsThemeLayerWireframe = createSelector(
  selectThemeState,
  state => state.layerWireframe
)

export const selectIsThemeUserCard = createSelector(
  selectThemeState,
  state => state.userCard
)

export const selectIsThemeLogoColor = createSelector(
  selectThemeState,
  state => state.logoColor
)
