<mat-form-field appearance="outline" (click)="$event.stopPropagation()">
  <mat-label>Client</mat-label>
  <input
    matInput
    [formControl]="clientCtrl"
    [placeholder]="'Select/Type Client'"
    [matAutocomplete]="autoClient"
  />
  <mat-autocomplete
    #autoClient="matAutocomplete"
    (optionSelected)="onClientChange($event.option.value)"
  >
    <mat-option
      *ngFor="let client of filteredClients | async"
      [value]="client.name"
    >
      {{ client.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="outline" (click)="$event.stopPropagation()">
  <mat-label>Year</mat-label>
  <input
    matInput
    [formControl]="yearCtrl"
    [placeholder]="'Select/Type Year'"
    [matAutocomplete]="autoYear"
  />
  <mat-autocomplete
    #autoYear="matAutocomplete"
    (optionSelected)="onYearChange($event.option.value)"
  >
    <mat-option
      *ngFor="let year of filteredYears | async"
      [value]="year.year"
    >
      {{ year.year }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="outline" (click)="$event.stopPropagation()">
  <mat-label>Program</mat-label>
  <input
    matInput
    [formControl]="programCtrl"
    [placeholder]="'Select/Type Program'"
    [matAutocomplete]="autoProgram"
  />
  <mat-autocomplete
    #autoProgram="matAutocomplete"
    (optionSelected)="onProgramChange($event.option.value)"
  >
    <mat-option
      *ngFor="let program of filteredPrograms | async"
      [value]="program.name"
    >
      {{ program.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div class="update-button">
  <button
    class="metric-toggles-button"
    appButton
    big
    accent
    border
    [disabled]="!canPopulateClick"
    (click)="onPopulateClick()"
  >
    Populate
  </button>
</div>
