<span
  *ngIf="tooltip; else noTooltipTemplate"
  [matTooltip]="tooltip"
  [matTooltipPosition]="tooltipPosition"
  [matTooltipShowDelay]="tooltipShowDelay"
>
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
</span>

<ng-template #noTooltipTemplate>
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
</ng-template>

<ng-template #iconTemplate>
  <mat-icon inline>
    <ng-content></ng-content>
  </mat-icon>
</ng-template>
