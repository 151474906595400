<div>
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <div class="metric-buttons">
    <button appButton big [matMenuTriggerFor]="categoryMenu">
      <span>
        Metrics
        <mat-icon inline>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #categoryMenu="matMenu">
      <ng-container *ngFor="let metricCategory of metricCategories">
        <button
          *ngIf="metricCategory.category !== 'Custom Metrics'"
          mat-menu-item
          [matMenuTriggerFor]="metricMenu"
        >
          {{ metricCategory.category }}
        </button>
        <mat-menu #metricMenu="matMenu">
          <ng-container *ngFor="let m of metricCategory.metrics">
            <button
              *ngIf="!isHiddenMetric(metricCategory.category, m[0].label)"
              mat-menu-item
              (click)="onMetricAdd(metricCategory.category, m[0].label)"
            >
              {{ m[0].label }}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-menu>

    <button appButton big (click)="onPlusAdd()">
      <span> + </span>
    </button>
    <button appButton big (click)="onMinusAdd()">
      <span> - </span>
    </button>
    <button appButton big (click)="onMultiAdd()">
      <span> * </span>
    </button>
    <button appButton big (click)="onDivideAdd()">
      <span> / </span>
    </button>
    <button appButton big (click)="onLeftParenAdd()">
      <span> ( </span>
    </button>
    <button appButton big (click)="onRightParenAdd()">
      <span> ) </span>
    </button>

    <mat-form-field class="const-form">
      <input
        matInput
        [(ngModel)]="constant"
        #constantModel="ngModel"
        #input
        [ngModelOptions]="{ standalone: true }"
      />
      <button appButton big matSuffix (click)="onConstAdd()">
        <span>CONST</span>
      </button>
    </mat-form-field>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="formulaForm">
      <mat-form-field class="full-width">
        <textarea
          cdkTextareaAutosize
          required
          matInput
          placeholder="Formula"
          [(ngModel)]="formula"
          readonly
          #input
          [errorStateMatcher]="matcher"
          formControlName="formula"
        ></textarea>
        <button
          mat-button
          *ngIf="formula"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearFormula()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="formulaForm.hasError('invalidFormula')">
          Formula is invalid.
        </mat-error>
      </mat-form-field>
    </form>

    <button
      appButton
      big
      translucent
      class="ranking"
      [matMenuTriggerFor]="ragMenu"
    >
      <span>
        {{ ragButtonLabel }}
        <mat-icon inline>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #ragMenu="matMenu">
      <ng-container *ngFor="let rag of ragOrderList">
        <button mat-menu-item (click)="onSetRagOrder(rag)">
          {{ rag }}
        </button>
      </ng-container>
    </mat-menu>

    <button appButton big translucent [matMenuTriggerFor]="valueTypeMenu">
      <span>
        {{ valueTypeLabel }}
        <mat-icon inline>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #valueTypeMenu="matMenu">
      <ng-container *ngFor="let valueType of valueTypeList">
        <button mat-menu-item (click)="onSetValueType(valueType)">
          {{ valueType }}
        </button>
      </ng-container>
    </mat-menu>

    <mat-form-field class="full-width" hintLabel="Max 50 characters">
      <input
        required
        matInput
        placeholder="Metric Name"
        [(ngModel)]="label"
        #labelModel="ngModel"
        #input
        maxlength="50"
        [ngModelOptions]="{ standalone: true }"
      />
      <mat-hint align="end">{{ input.value?.length || 0 }}/50</mat-hint>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button
      appButton
      accent
      (click)="addSaveMetricClick()"
      [disabled]="isSaveButtonDisabled"
      [matTooltip]="isSaveButtonDisabled ? 'Complete form to add/save custom metric' : ''"
    >
      <span>{{ addOrSaveButtonLabel }}</span>
    </button>
    <button appButton link mat-dialog-close>
      <span>Cancel</span>
    </button>
  </div>
</div>
