import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-user-card',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: row;
             align-items: center;
             /* Display anchors like buttons */
             white-space: nowrap;
             text-decoration: none;
             vertical-align: baseline;
             /* Sizing */
             margin: 0;
             padding: 0;
             line-height: 30px;
             overflow: hidden;
             /* Reset button styles */
             outline: none;
             user-select: none;
             font-family: var(--font-header-family);
             font-weight: var(--font-header-weight);
             font-size: var(--font-size-huge);
           }
     
           .avatar {
             flex: 0;
             display: block;
             height: auto;
             width: auto;
             min-height: 64px;
             min-width: 64px;
             background-size: cover;
             background-position: top center;
             border-radius: 50%;
             margin-right: var(--inset);
             display: flex;
             align-items: center;
             justify-content: center;
           }
     
           .avatar span {
             color: var(--accent);
             padding-bottom: 0.1rem;
           }
     
           .content {
             overflow: hidden;
             white-space: nowrap;
             text-overflow: ellipsis;
           }
     
           .content > span {
             display: block;
           }
     
           .role {
             font-size: var(--font-size-small);
             color: var(--subtle);
             line-height: 18px;
           }
     
           @media screen and (max-width: 1240px) {
             :host {
               font-size: var(--font-size-big);
             }
     
             .avatar {
               min-height: 52px;
               min-width: 52px;
             }
           }
         `,
  ],
  template: `
    <div *ngIf="avatarID" class="avatar" [style.backgroundImage]="bgImg">
      <span>{{ this.initials }}</span>
    </div>
    <div class="content">
      <span>{{ name }}</span>
      <span class="role">{{ sageLogicalRole }}</span>
    </div>
  `,
})
export class UserCardComponent {
  @Input() name: string
  @Input() avatarID: string
  @Input() sageLogicalRole: string | null

  get bgImg(): string {
    return `url(${this.avatarID})`
  }

  get initials(): string {
    return this.name
      .split(' ')
      .map(word => word.substring(0, 1).toUpperCase())
      .join('')
  }
}
