import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  EntryDialogOptions,
  EntryDialogComponent,
} from '../entry-dialog.component'

@Injectable()
export class EntryDialogService {
  constructor(public dialog: MatDialog) {}

  open(opts?: EntryDialogOptions) {
    return this.dialog.open(EntryDialogComponent, {
      width: '30vw',
      data: opts,
    })
  }
}
