<mat-tab-group (selectedTabChange)="tabChange($event)">
  <mat-tab label="Table">
    <ng-template matTabContent>
      <app-compare-towers-container
        id="comparePage1"
        [precision]="precision"
        (programAdd)="programAdd.emit($event)"
        (programRemove)="programRemove.emit($event)"
      ></app-compare-towers-container>
    </ng-template>
  </mat-tab>
  <mat-tab label="Graph">
    <ng-template matTabContent>
      <app-compare-structure-options-container
        [tabIndex]="tabSelected"
        [compareCurrency]="compareCurrency"
      ></app-compare-structure-options-container>
    </ng-template>
  </mat-tab>
</mat-tab-group>
