import { ApplicationError, errorPayload } from '../../error/model/error'
import { SharedLimitValidation } from '../../analysis/store/grouper/program-group.model'

export function sharedLimitValidationToErrorPayload(
  validation: SharedLimitValidation,
  message: string = ''
): ApplicationError | null {
  if (
    validation.hasInuranceSource ||
    validation.hasInuranceSourceDelete ||
    validation.isILW
  ) {
    const details: string[] = []
    if (validation.isILW) {
      details.push('ILW layer type is not supported for Shared Limit.')
    }
    if (validation.hasInuranceSource) {
      details.push(
        'A selected layer is an existing inurance source. To use a Shared Limit layer as an inurance source, create Shared Limit first, then apply Inurance.'
      )
    }
    if (validation.hasInuranceSourceDelete) {
      details.push(
        'A selected layer is an existing inurance source. To delete a Shared Limit inurance source, remove Inurance first, then delete Shared Limit.'
      )
    }
    return errorPayload(message || 'Shared Limit validation failed.', details)
  } else {
    return null
  }
}
