<app-layer-properties
  class="content-grid"
  *ngIf="groupValue === 'Layer'"
  [currentProgram]="currentProgram$ | async"
  [layer]="layer$ | async"
  [layers]="layers$ | async"
  [layerLoading]="layersLoading$ | async"
  [lossSetLayers]="lossSetLayers$ | async"
  [isAddSharedLayer]="isAddSharedLayer"
  [isDeleteSharedLayer]="isDeleteSharedLayer"
  [newSharedLayer]="newSharedLayer$ | async"
  [selectedSharedLayer]="selectedSharedLayer$ | async"
  [studyID]="studyID$ | async"
  [layerViewsMetrics]="layerViewsMetrics$ | async"
  [reinsurersList]="reinsurersList$ | async"
  [reinsurersListSL]="reinsurersListSL$ | async"
  [savedPricingCurves] ="savedPricingCurves$ | async"
  [layerTypeEntries]="layerTypeEntries$ | async"
  [deletingSharedLimit]="sharedLimitDeleting$ | async"
  [updatingSharedLimit]="sharedLimitUpdating$ | async"
  [sharedCurrentCurrency]="sharedCurrentCurrency$ | async"
  [isLibRE]="isLibRE"
  [currencyList]="currencyList$ | async"
  [filteredCurrencyList]="currencyList$ | async"
  [layerFilteredCurrencyList]="currencyList$ | async"
  [selectedViewID]="selectedViewID"
  [cededLayers]="cededLayers$ | async"
  (layerResize)="onLayerResize($event)"
  (colorChange)="onColorChange($event)"
  (sharedLimitChange)="onSharedLimitChange($event)"
  (layerRefResize)="onLayerRefResize($event)"
  (createHiddenLayer)="onCreateHiddenLayer($event)"
  (deleteSharedLayerClick)="onDeleteSharedLayerClick($event)"
  (closeClick)="onCloseClick()"
  (deleteLayerClick)="onDeleteLayer($event)"
  (updateSharedLimitPropertiesClick)="onUpdateSharedLayerPropertiesClick($event)"
  (showTopDropDialog)="onShowTopDropDialog($event)"
  (showMultiSectionDialog)="onShowMultiSectionDialog($event)"
  (showCustomIndexDialog)="onShowCustomIndexDialog($event)"
  (selectedCurrency)="getSelectedCurrency($event)"
  (handlePastedLayer)="onHandlePastedLayer($event)"
  (sharedLimitCurrency)="getSharedLimitCurrency($event)"
  (selectedSLCurrency)="getSelectedSLCurrency($event)"
  (structureCurrency)="onStructureCurrencyEvent($event)"
  ></app-layer-properties>

<app-metric-properties
  class="content-grid"
  *ngIf="groupValue === 'Metric'"
  [layerViewsMetrics]="layerViewsMetrics$ | async"
  [lossSetLayers]="lossSetLayers$ | async"
  [layer]="layer$ | async"
  [layers]="layers$ | async"
  [metricLoading]="layersViewMetricsLoading$ | async"
  [layerLoading]="layersLoading$ | async"
  [currentCededLayer]="currentCededLayer$ | async"
  [selectedViewID]="selectedViewID"
  [newSharedLayer]="newSharedLayer$ | async"
  [selectedSharedLayer]="selectedSharedLayer$ | async"
  [updatingSharedLimit]="sharedLimitUpdating$ | async"
  [portfolioSetID]="id"
  [reinsurersList]="reinsurersList$ | async"
  [currencyList]="currencyList$ | async"
  [filteredCurrencyList]="currencyList$ | async"
  [layerFilteredCurrencyList]="currencyList$ | async"
  [isDeleteSharedLayer]="isDeleteSharedLayer"
  [sharedCurrentCurrency]="sharedCurrentCurrency$ | async"
  [sharedLimitCurrency]="sharedLimitCurrency"
  [designDirty]="designDirty"
  (updateSharedLimitPropertiesClick)="onUpdateSharedLayerPropertiesClick($event)"
  (sharedLimitChange)="onSharedLimitChange($event)"
  (closeClick)="onCloseClick()"
  (setLayerMetrics)="onSetLayerMetrics($event)"
  (contributionClick)="onContributionClick($event)"
  (aalClick)="onAALClick($event)"
></app-metric-properties>

<app-layer-list *ngIf=" !isAddSharedLayer && !isDeleteSharedLayer"
  [layerList]="layerList$ | async"
  [layerListId]="layerListId"
  [groupValue]="groupValue"
  (selectLayer)="doSelectLayer($event)"
></app-layer-list>
