import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject, Observable } from 'rxjs'
import { takeUntil, withLatestFrom, map } from 'rxjs/operators'
import { NavService } from '../../../../nav.service'
import {
  ProgramGroupMember,
  ProgramGroup,
  SharedIDGroup,
} from '../../../store/grouper/program-group.model'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../../core/store'
import {
  selectGrouperSlideProgramEntities,
  selectGrouperProgramGroupMembers,
  selectGrouperProgramGroupEntitiesByID,
  selectGrouperProgramEntities,
} from '../../../store/analysis.selectors'
import {
  selectGroupID,
  selectGroupLossSets,
  selectGroupExecuting,
  selectAllGroupScenarioEvents,
  selectGroupLoading,
  selectAllGroupScenarioEventResults,
  selectGroupAnimating,
} from '../../store/animated-scenarios.selectors'
import { ProgramEntity } from '../../../store/grouper/program/program.reducer'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import {
  ScenarioEvent,
  ScenarioEventResult,
} from '../../animated-scenarios.model'
import * as fromGroupAnimatedScenariosActions from '../../store/group/group-animated-scenarios.actions'
import { Client } from '../../../../core/model/client.model'
import {
  selectCurrentClient,
  selectCurrentClientStudies,
} from '../../../../core/store/broker/broker.selectors'
import { Study } from '../../../../core/model/study.model'
import { filterLayers } from '../../util/filters'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-animated-scenarios-container',
  templateUrl: './group-animated-scenarios.container.html',
})
export class GroupAnimatedScenariosContainerComponent
  implements OnInit, OnDestroy
{
  structureGroup$: Observable<ProgramGroup | null>
  structureEntities$: Observable<ProgramEntity[]>
  lossSetLayers$: Observable<LossSetLayer[]>
  executing$: Observable<boolean>
  scenarioEvents$: Observable<ScenarioEvent[]>
  loadingGroup$: Observable<boolean>
  scenarioEventsResults$: Observable<ScenarioEventResult[]>
  animating$: Observable<boolean>
  currentClient$: Observable<Client | null>
  studies$: Observable<Study[]>
  sharedIDGroup$: Observable<SharedIDGroup[]>
  eventResultByEntity$: Observable<Record<string, ScenarioEventResult[]>>
  structureNameByEntity$: Observable<Record<string, string>>

  private destroy$ = new Subject()

  constructor(
    private route: ActivatedRoute,
    private nav: NavService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.sharedIDGroup$ = this.store.pipe(
      select(selectGrouperProgramEntities),
      map(programEntities => {
        const sharedIDGroup: SharedIDGroup[] = []
        const ids: string[] = []
        let count = 1
        programEntities.forEach(groupEntity => {
          groupEntity.cededLayers.forEach(l => {
            let actualRiskId = ''
            if (l.layer.meta_data.sage_layer_type === 'shared_limits') {
              const group: string[] = []
              l.layer.layerRefs.forEach(id => {
                const layerForSharedLimit = groupEntity.cededLayers.find(
                  l1 => l1.layer.id === id
                )
                if (
                  layerForSharedLimit &&
                  layerForSharedLimit.layer.meta_data.riskVisibleLayerID
                ) {
                  actualRiskId = id
                  group.push(
                    layerForSharedLimit.layer.meta_data.riskVisibleLayerID
                  )
                } else {
                  group.push(id)
                }
              })
              if (!this.isGroupsEquals(sharedIDGroup, group)) {
                if (actualRiskId === '') {
                  sharedIDGroup.push({
                    sharedID: l.layer.id,
                    numberGroup: count,
                    group,
                  })
                  ids.push(l.layer.id)
                  count++
                } else {
                  const oldGroup = sharedIDGroup.find(
                    sg => sg.sharedID === l.layer.id
                  )
                  const newGroup = {
                    sharedID: l.layer.id,
                    numberGroup: count,
                    group,
                  }
                  if (oldGroup) {
                    sharedIDGroup.splice(
                      sharedIDGroup.indexOf(oldGroup),
                      1,
                      newGroup
                    )
                  } else {
                    sharedIDGroup.push({
                      sharedID: l.layer.id,
                      numberGroup: count,
                      group,
                    })
                    ids.push(l.layer.id)
                    count++
                  }
                }
              }
            }
          })
        })
        return sharedIDGroup
      })
    )
    // @ts-ignore
    this.studies$ = this.store.pipe(select(selectCurrentClientStudies))
    this.animating$ = this.store.pipe(select(selectGroupAnimating))
    this.scenarioEventsResults$ = this.store.pipe(
      select(selectAllGroupScenarioEventResults)
    )
    this.structureEntities$ = this.store.pipe(
      select(selectGroupID),
      withLatestFrom(
        this.store.pipe(select(selectGrouperSlideProgramEntities)),
        this.store.pipe(select(selectGrouperProgramGroupMembers))
      ),
      map(([groupID, structureEntities, members]) => {
        return structureEntities.filter(s => {
          const ancestors = this.getAllAncestors(members, s.program.id)
          return ancestors.includes(groupID || '')
        })
      }),
      map(entities => {
        return entities.map(e => ({
          ...e,
          cededLayers: filterLayers(e.cededLayers),
        }))
      })
    )
    this.eventResultByEntity$ = this.store.pipe(
      select(selectAllGroupScenarioEventResults),
      withLatestFrom(this.structureEntities$),
      map(([eventResults, entities]) => {
        const record: Record<string, ScenarioEventResult[]> = {}
        entities.forEach(programEntity => {
          const newEventResult: ScenarioEventResult[] = []
          const entityLayersIDs = programEntity.cededLayers.map(c => c.layer.id)
          eventResults.forEach(result => {
            const layers = result.layers
              .filter(l =>
                entityLayersIDs.includes(l.layer.meta_data.fromLayerID || '')
              )
              .map(l => ({
                ...l,
                layer: {
                  ...l.layer,
                  physicalLayer: {
                    ...l.layer.physicalLayer,
                    id: l.layer.physicalLayer.meta_data.fromLayerID || '',
                  },
                },
              }))
            newEventResult.push({ ...result, layers })
          })
          record[programEntity.program.id] = newEventResult
        })
        return record
      })
    )

    this.structureNameByEntity$ = this.structureEntities$.pipe(
      withLatestFrom(this.studies$),
      map(([entities, studies]) => {
        const record: Record<string, string> = {}
        entities.forEach(entity => {
          const study = studies.find(s => s.id === entity.program.studyID)
          if (study) {
            record[
              entity.program.id
            ] = `${study.name} - ${entity.program.label}`
          }
        })
        return record
      })
    )
    this.loadingGroup$ = this.store.pipe(select(selectGroupLoading))
    this.executing$ = this.store.pipe(select(selectGroupExecuting))
    this.scenarioEvents$ = this.store.pipe(select(selectAllGroupScenarioEvents))
    this.lossSetLayers$ = this.store.pipe(select(selectGroupLossSets))
    this.structureGroup$ = this.store.pipe(
      select(selectGroupID),
      withLatestFrom(
        this.store.pipe(select(selectGrouperProgramGroupEntitiesByID))
      ),
      map(([groupID, groupByID]) => {
        if (groupID) {
          const programGroupEntity = groupByID[groupID]
          if (programGroupEntity) {
            return programGroupEntity.programGroup
          } else {
            return null
          }
        } else {
          return null
        }
      })
    )
    this.currentClient$ = this.store.pipe(select(selectCurrentClient))

    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private getAllAncestors(
    programGroupMembers: ProgramGroupMember[],
    structureID: string
  ): string[] {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'program' && m.programID === structureID && m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestorsFromGroup(
            programGroupMembers,
            member.parentGroupID
          )
        )
      }

      return groupIDs
    } else {
      return groupIDs
    }
  }
  private getAllAncestorsFromGroup(
    programGroupMembers: ProgramGroupMember[],
    groupID: string
  ) {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'programGroup' &&
        m.programGroupID === groupID &&
        m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestorsFromGroup(
            programGroupMembers,
            member.parentGroupID
          )
        )
      }
      return groupIDs
    } else {
      return groupIDs
    }
  }

  private isGroupsEquals(group: SharedIDGroup[], newGroup: string[]): boolean {
    let isEquals = false

    if (group.length === 0) {
      return false
    }

    let valueA = ''
    newGroup.forEach(a => {
      valueA = valueA + a
    })

    group.forEach(g => {
      let valueB = ''
      g.group.forEach(b => {
        valueB = valueB + b
      })

      if (valueA === valueB) {
        isEquals = true
      }
    })

    return isEquals
  }

  onProcessEvents() {
    this.store.dispatch(fromGroupAnimatedScenariosActions.groupExecute())
  }

  onAnimationEnd() {
    this.store.dispatch(
      fromGroupAnimatedScenariosActions.setGroupAnimating({ value: false })
    )
  }

  onReanimate() {
    this.store.dispatch(
      fromGroupAnimatedScenariosActions.setGroupAnimating({ value: true })
    )
  }
}
