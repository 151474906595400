<div class="grid" [style.height]="height">
  <table class="pivot-table" cellspacing="0.25px">
    <tr class="header">
      <th class="sticky row-label-column pivot-cell header-cell"></th>
      <th class="pivot-cell header-cell" *ngFor="let col of pivotTableColumns">
        <span>{{ col.name }}</span>
      </th>
    </tr>
    <tr *ngFor="let row of pivotTableRowDefs">
      <td class="sticky row-label-column row-cell pivot-cell">
        {{ row.name }}
      </td>
      <td
        class="row-cell pivot-cell"
        *ngFor="let col of pivotTableColumns"
        [matTooltip]="valueTransform($any(col)[row.id], row)"
        matTooltipPosition="left"
      >
        <span>{{ valueTransform($any(col)[row.id], row) }}</span>
      </td>
    </tr>
  </table>
</div>
