<h1 mat-dialog-title>Bulk Autobuild</h1>
<div mat-dialog-content>
  <section class="progress-section">
    <div *ngFor="let step of completedSteps; index as i">
      <div class="progress-completed-step">
        <mat-icon
          *ngIf="i < completedSteps.length - 1 || saveComplete"
          class="progress-completed"
        >task_alt</mat-icon>
        <mat-icon
          *ngIf="i === completedSteps.length - 1 && !saveComplete"
          class="progress-uncompleted"
        >radio_button_unchecked</mat-icon>
        <span>{{ step }}</span>
      </div>
    </div>
    <div *ngFor="let step of steps; index as i">
      <div class="progress-step">
        <mat-icon
          *ngIf="i < steps.length - 1 || saveComplete"
          class="progress-check"
        >check_box</mat-icon>
        <mat-icon
          *ngIf="i === steps.length - 1 && !saveComplete"
          class="progress-working"
        >check_box_outline_blank</mat-icon>
        <span>{{ step }}</span>
      </div>
    </div>
    <mat-progress-bar
      class="progress-bar"
      disableClose="true"
      [mode]="mode"
      [value]="value"
    >
    </mat-progress-bar>
  </section>
</div>
