import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { map, take, withLatestFrom } from 'rxjs/operators'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { Layer } from 'src/app/analysis/model/layers.model'
import { findLayerById } from 'src/app/analysis/model/layers.util'
import { toLayerViewMetrics } from 'src/app/analysis/model/metrics.converter'
import {
  AggregationMethodType,
  Perspective,
} from 'src/app/analysis/model/metrics.model'
import { PortfolioType } from 'src/app/analysis/model/portfolio-metrics.model'
import { extractPortfolioSetID } from 'src/app/analysis/model/portfolio-set-id.util'
import {
  selectAllGrouperProgramCededLayerStates,
  selectAllGrouperProgramCededLayerStatesDictionary,
  selectCededDictionary,
  selectCededLayers,
  selectCededPortfolioViewLayersSLViewIDs,
  selectCededPortfolioViewLayersViewIDs,
  selectCurrentCededLayer,
  selectCurrentCurrency,
  selectGrossPortfolioSLViewID,
  selectGrossPortfolioViewID,
  selectGrouperPortfolioSetID,
  selectLayerTypeTechnicalPremiumValues,
  selectSharedLimitSelectedLayer,
} from 'src/app/analysis/store/analysis.selectors'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { fetchLossSetLayerViewsSuccess } from 'src/app/analysis/store/loss-set-layers/loss-set-layers.actions'
import {
  getLayerFromLayerType,
  getLayerTypeLimit,
  reverseRecord,
} from 'src/app/analysis/store/metrics/calculations'
import {
  fetchLayersViewMetrics,
  fetchLayersViewMetricsFailure,
  fetchLayersViewMetricsFailureRP,
  fetchLayersViewMetricsRP,
  fetchLayersViewMetricsSuccess,
  fetchLayersViewMetricsSuccessRP,
} from 'src/app/analysis/store/metrics/layers-metrics.actions'
import { Metrics } from 'src/app/api/analyzere/analyzere.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { MaybeError } from 'src/app/api/model/api.model'
import { TechnicalPremiumService } from 'src/app/api/technical-premium/technical-premium.service'
import {
  concatMapWithInput,
  mergeMapWithInput,
  rejectErrorWithInput,
} from 'src/app/api/util'
import { AppState } from 'src/app/core/store'
import { selectCurrentStudy } from 'src/app/core/store/broker/broker.selectors'
import { fetchLayerTypeValuesFailure } from '../technical-premium/technical-premium.actions'
import { selectSavedCurvesIncludingDefault } from 'src/app/pricingcurve/store/pricing-curve.selectors'

// tslint:disable: no-non-null-assertion
export interface LayerViewMetricsAndAction {
  metrics: Record<string, Metrics[]>
  aggregationMethod: AggregationMethodType
  perspective: Perspective
  portfolioType: PortfolioType
  returnPeriod1: number
  returnPeriod2: number
  returnPeriod3: number
  returnPeriod4: number
  returnPeriod5: number
  cededPortfolioID: string
  grossPortfolioID: string
  netPortfolioID: string
  analysisProfileID: string
  layerViewID: string
  layerID: string
  isSL?: boolean
}

@Injectable()
export class LayersViewMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private technicalPremiumService: TechnicalPremiumService
  ) {}

  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLayersViewMetrics),
      map(({ type, ...props }) => props),
      withLatestFrom(this.store.select(selectCurrentStudy)),
      concatMapWithInput(([_, study]) =>
        this.technicalPremiumService.getLayerTypeEntries({
          programId: study ? study.id : '',
        })
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(fetchLayerTypeValuesFailure({ error }))
      ),
      withLatestFrom(
        this.store.select(selectGrouperPortfolioSetID),
        this.store.select(selectCededPortfolioViewLayersSLViewIDs),
        this.store.select(selectAllGrouperProgramCededLayerStatesDictionary),
        this.store.select(selectAllGrouperProgramCededLayerStates),
        this.store.select(selectGrossPortfolioSLViewID),
        this.store.select(selectSharedLimitSelectedLayer),
        this.store.select(selectCededPortfolioViewLayersViewIDs),
        this.store.select(selectCededDictionary),
        this.store.select(selectCededLayers),
        this.store.select(selectGrossPortfolioViewID),
        this.store.select(selectSavedCurvesIncludingDefault)
      ),
      concatMapWithInput(
        ([
          [layerTypeDefaults, [props]],
          _,
          cededLayersSLViewIDs,
          cededLayersSLByID,
          cededLayersSL,
          grossSLViewID,
          sharedLimitSelectedLayer,
          cededLayersViewIDs,
          cededLayersByID,
          cededLayers,
          grossViewID,
          savedCurves,
        ]) => {
          if (props.isSL) {
            return this.service.getLayersViewViewMetricsAndCalculate(
              props.layerViewID,
              grossSLViewID,
              cededLayersSLViewIDs,
              cededLayersSLByID,
              cededLayersSL,
              true,
              undefined,
              sharedLimitSelectedLayer!,
              layerTypeDefaults,
              undefined,
              undefined,
              savedCurves
            )
          } else {
            return this.service.getLayersViewViewMetricsAndCalculate(
              props.layerViewID,
              grossViewID,
              cededLayersViewIDs,
              cededLayersByID,
              cededLayers,
              false,
              props.lossFilterValue,
              undefined,
              layerTypeDefaults,
              props.yearUpdate,
              props.methodUpdate,
              savedCurves
            )
          }
        }
      ),
      rejectErrorWithInput((error, [[, [props]]]) =>
        this.store.dispatch(
          fetchLayersViewMetricsFailure({
            ...props,
            error,
          })
        )
      ),
      map(([result, [[, props], groupPortfolioId]]) => {
        if (props[0].isSL && groupPortfolioId !== null) {
          return fetchLayersViewMetricsSuccess({
            ...extractPortfolioSetID({
              analysisProfileID: groupPortfolioId.analysisProfileID,
              cededPortfolioID: groupPortfolioId.cededPortfolioID,
              grossPortfolioID: groupPortfolioId.grossPortfolioID,
              netPortfolioID: groupPortfolioId.netPortfolioID,
            })!,
            metrics: result,
            layerViewID: result.id,
          })
        } else {
          return fetchLayersViewMetricsSuccess({
            ...props[0],
            metrics: result,
            layerViewID: result.id,
          })
        }
      })
    )
  )

  fetchAndCalculate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLayersViewMetricsRP),
      mergeMapWithInput(action => {
        let httpObservable
        httpObservable = this.service.getMultiLayerRPViewMetrics(
          [action.layerViewID],
          action.aggregationMethod,
          action.perspective,
          this.transformReturnPeriod(action.returnPeriod1),
          this.transformReturnPeriod(action.returnPeriod2),
          this.transformReturnPeriod(action.returnPeriod3),
          this.transformReturnPeriod(action.returnPeriod4),
          this.transformReturnPeriod(action.returnPeriod5)
        )
        return httpObservable.pipe(
          map(res => {
            if (res.error) {
              return res as LayerViewMetricsAndAction & MaybeError
            } else {
              return {
                data: {
                  metrics: res.data,
                  aggregationMethod: action.aggregationMethod,
                  returnPeriod1: action.returnPeriod1,
                  returnPeriod2: action.returnPeriod2,
                  returnPeriod3: action.returnPeriod3,
                  returnPeriod4: action.returnPeriod4,
                  returnPeriod5: action.returnPeriod5,
                  perspective: action.perspective,
                  cededPortfolioID: action.cededPortfolioID,
                  grossPortfolioID: action.grossPortfolioID,
                  netPortfolioID: action.netPortfolioID,
                  analysisProfileID: action.analysisProfileID,
                  layerViewID: action.layerViewID,
                  layerID: action.layerID,
                  isSL: action.isSL,
                },
              } as { data: LayerViewMetricsAndAction }
            }
          })
        )
      }),
      rejectErrorWithInput((error, props) =>
        this.store.dispatch(
          fetchLayersViewMetricsFailureRP({ ...props, error })
        )
      ),
      withLatestFrom(
        this.store.select(selectCededPortfolioViewLayersViewIDs),
        this.store.select(selectCededDictionary),
        this.store.select(selectCededLayers),
        this.store.select(selectGrossPortfolioViewID)
      ),
      map(data1 => data1),
      withLatestFrom(
        this.store.select(selectCededPortfolioViewLayersSLViewIDs),
        this.store.select(selectAllGrouperProgramCededLayerStatesDictionary),
        this.store.select(selectAllGrouperProgramCededLayerStates),
        this.store.select(selectGrossPortfolioSLViewID),
        this.store.select(selectGrouperPortfolioSetID)
      ),
      concatMapWithInput(
        ([
          [
            [_, props],
            cededLayersViewIDs,
            cededLayersByID,
            cededLayers,
            grossViewID,
          ],
          cededLayersSLViewIDs,
          cededLayersSLByID,
          cededLayersSL,
          grossSLViewID,
        ]) => {
          let layerState: LayerState

          if (props.isSL) {
            const reversedRecord = reverseRecord(cededLayersSLViewIDs)
            // tslint:disable: no-non-null-assertion
            const selectedLayerID = reversedRecord[props.layerViewID]
            if (cededLayersSLByID[selectedLayerID]) {
              layerState = cededLayersSLByID[selectedLayerID]!
            } else {
              layerState = cededLayersSL.find(
                ls => ls.layer.physicalLayer.id === selectedLayerID
              )!
            }

            let layer = getLayerFromLayerType(layerState, cededLayersSLByID)
            if (isSwingLayer(layer, 'combined-layer')) {
              layer = findVisibleLayer(cededLayersSL, layer)
            }

            const limit = getLayerTypeLimit(layer, 'Occ')
            const aggLimit = getLayerTypeLimit(layer, 'Agg')
            let layerId = ''
            let viewID =
              layerState.layer.meta_data.sage_layer_type === 'shared_limits'
                ? cededLayersSLViewIDs[layerState.layer.id]
                : props.layerViewID
            if (
              layer.meta_data.sage_layer_type === 'cat_td' ||
              layer.meta_data.sage_layer_type === 'drop'
            ) {
              const actualLayer = cededLayers.filter(
                e => e.layer.meta_data.sage_layer_subtype === 'actual'
              )
              // Update the view id. Check if list has data
              if (actualLayer.length > 0) {
                layerId = reversedRecord[actualLayer[0].layer.id]
                viewID = layerId
              }
            }
            return this.service.getLayersViewMetrics(
              viewID,
              limit,
              aggLimit,
              grossSLViewID,
              undefined,
              undefined,
              undefined,
              undefined,
              layer.meta_data.sage_layer_type
            )
          } else {
            const reversedRecord = reverseRecord(cededLayersViewIDs)
            // tslint:disable: no-non-null-assertion
            const selectedLayerID = reversedRecord[props.layerViewID]
            if (cededLayersByID[selectedLayerID]) {
              layerState = cededLayersByID[selectedLayerID]!
            } else {
              layerState = cededLayers.find(
                ls => ls.layer.physicalLayer.id === selectedLayerID
              )!
            }

            let layer = getLayerFromLayerType(layerState, cededLayersByID)
            if (isSwingLayer(layer, 'combined-layer')) {
              layer = findVisibleLayer(cededLayers, layer)
            }

            const limit = getLayerTypeLimit(layer, 'Occ')
            const aggLimit = getLayerTypeLimit(layer, 'Agg')

            let viewID = layerState.layer.meta_data.backAllocatedForID
              ? cededLayersViewIDs[layerState.layer.id]
              : props.layerViewID
            if (
              layer.meta_data.sage_layer_type === 'cat_td' ||
              layer.meta_data.sage_layer_type === 'drop'
            ) {
              const actualLayer = cededLayers.filter(
                e => e.layer.meta_data.sage_layer_subtype === 'actual'
              )
              // Update the view id. Check if list has data
              if (actualLayer.length > 0) {
                viewID = cededLayersViewIDs[actualLayer[0].layer.id]
              }
            }

            return this.service.getLayersViewMetrics(
              viewID,
              limit,
              aggLimit,
              grossViewID,
              undefined,
              undefined,
              undefined,
              undefined,
              layer.meta_data.sage_layer_type
            )
          }
        }
      ),
      rejectErrorWithInput((error, [[[_, props]]]) =>
        this.store.dispatch(fetchLayersViewMetricsFailure({ ...props, error }))
      ),
      map(([_, [[[response]], ...groupProps]]) => {
        const t = toLayerViewMetrics(response, response.layerViewID)
        if (response.isSL && groupProps[4] !== null) {
          return fetchLayersViewMetricsSuccessRP({
            ...extractPortfolioSetID({
              analysisProfileID: groupProps[4].analysisProfileID,
              cededPortfolioID: groupProps[4].cededPortfolioID,
              grossPortfolioID: groupProps[4].grossPortfolioID,
              netPortfolioID: groupProps[4].netPortfolioID,
            })!,
            metrics: t,
            layerID: response.layerID,
          })
        } else {
          return fetchLayersViewMetricsSuccessRP({
            ...extractPortfolioSetID({
              analysisProfileID: response.analysisProfileID,
              cededPortfolioID: response.cededPortfolioID,
              grossPortfolioID: response.grossPortfolioID,
              netPortfolioID: response.netPortfolioID,
            })!,
            metrics: t,
            layerID: response.layerID,
          })
        }
      })
    )
  })

  private transformReturnPeriod(value: number) {
    return 1 / value
  }
}

function findVisibleLayer(layerStates: LayerState[], layer: Layer) {
  const layers = layerStates.map(ls => ls.layer)
  const visibleId = layer.meta_data.visible_layer_id

  return findLayerById(layers, visibleId) ?? layer
}
