import { SortTableColumnDef, SortTableColumnView } from './sort-table.model'

export interface SortTableColumnsConfig<T = any> {
  views: SortTableColumnView<T>[]
  names: string[]
  header1Names: string[]
  header2Names: string[]
  footer1Names: string[]
  footer2Names: string[]
}

export function toSortTableColumnsConfig<T = any>(
  columns: SortTableColumnDef<T>[]
): SortTableColumnsConfig<T> {
  const categoryMap: Record<string, string[]> = {}
  let views: SortTableColumnView<T>[] = []
  const names: string[] = []
  const header1Names: string[] = []
  const header2Names: string[] = []
  const footer1Names: string[] = []
  const footer2Names: string[] = []
  const spans = { rowspan: 1, colspan: 1 }
  columns
    .filter(c => !c.hide)
    .forEach(def => {
      const id = String(def.id)
      const category = def.category
      names.push(id)
      if (category) {
        if (!categoryMap[category] || categoryMap[category].length === 0) {
          categoryMap[category] = []
          views.push({
            id: category as keyof T,
            label: category,
            isCategory: true,
            categoryClasses: def.categoryClasses,
            categoryTemplateID: def.categoryTemplateID,
            categoryMinWidth: def.categoryMinWidth,
            pinnableCategory: def.pinnableCategory,
            pinnedCategory: def.pinnedCategory,
            ...spans,
          })
          header1Names.push(category)
        }
        views.push({ ...def, ...spans })
        header2Names.push(id)
        footer1Names.push(id)
        footer2Names.push(id + '-footer2')
        categoryMap[category].push(id)
      } else {
        views.push({ ...def, colspan: 1, rowspan: 2 })
        header1Names.push(id)
        footer1Names.push(id)
        footer2Names.push(id + '-footer2')
      }
    })
  views = views.map(v =>
    v.isCategory ? { ...v, colspan: categoryMap[String(v.id)].length } : v
  )
  return {
    views,
    names,
    header1Names,
    header2Names,
    footer1Names,
    footer2Names,
  }
}
