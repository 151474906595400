<div class="rts-dialog-container">
  <div class="rts-dialog-top">
    <h1 class="mat-dialog-title rts-title">{{reinsurerName}}</h1>
    <button appButton primary (click)="closeDialog.emit()">Close</button>
  </div>
  <div class="rts-filters">
    <div *ngFor="let filter of filters" class="form-field-wrapper">
      <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label class="rts-label">{{filter.label}}</mat-label>
        <mat-select [disabled]="isLoading" multiple (selectionChange)="updateFilter(filter.name, $event.value)" panelClass="app-bar-panel">
          <mat-option *ngFor="let option of filter.options" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="table-menu">
    <div
      *ngFor="let i of menuItems"
      class="menu-item"
      [ngClass]="{ selected: i.selected }"
      (click)="setMenuSelection(i.value)"
    >
      {{i.value}}
    </div>
  </div>
  <div class="table-container">
    <app-sort-table
      class="table"
      spacing="tight"
      [columnDefs]="columnDefs"
      [rows]="filteredSubjectivities"
    ></app-sort-table>
  </div>
</div>
