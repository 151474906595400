import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  ILW_SELECTABLE_LAYERS,
  LAYER_PALETTE,
  AggLayers,
  layerIds
} from 'src/app/analysis/model/layer-palette.model'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-timeout-dialog',
  styleUrls: ['./audience-layer-select-dialog.component.scss'],
  templateUrl: './audience-layer-select-dialog.component.html',
})

export class AudienceLayerSelectDialogComponent {
  isProd = environment.production
  constructor(
    private dialogRef: MatDialogRef<AudienceLayerSelectDialogComponent>,
    private _snackBar: MatSnackBar
  ) {}
  ilwOptions = LAYER_PALETTE.filter(x => ILW_SELECTABLE_LAYERS.includes(x.type) || (!this.isProd && x.id === 'cat_ilw_ag'))
  ilwSelect = ''

  confirm() {
    if (this.ilwSelect) {
      this.dialogRef.close(this.ilwSelect)
    }
  }

  cancel() {
    this._snackBar.open('Placement cancelled, layer not added', 'X', {
      duration: 2000,
    })
  }
}
