import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tracking-header',
  styleUrls: ['./tracking-header.component.scss'],
  templateUrl: './tracking-header.component.html',
})
export class TrackingHeaderComponent {
  @Input() name: string
  @Output() refreshClick =  new EventEmitter()

  get subtitle(): string {
    return ''
  }

  constructor(
    public navService: NavService,
    private router: Router
  ) {}

  onBackClick(): void {
    this.router.navigate(['/home'])
  }
}
