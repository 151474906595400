import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { range } from 'ramda'
import { calcGridDimensions } from './util/math'
import { toArray } from './util/operators'

export type GridThreeItemSpanPosition = 'top' | 'bottom'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-grid-icon',
  styles: [
    `
           :host {
             display: block;
             width: 17px;
             height: 17px;
           }
     
           .grid {
             display: grid;
             row-gap: 1px;
             column-gap: 1px;
             width: 100%;
             height: 100%;
           }
     
           .item {
             border: 1px solid var(--subtle);
             --grid-border-radius: 3px;
           }
     
           .grid.none-selected .item {
             border-color: var(--hint);
           }
     
           .grid:hover .item {
             border-color: var(--subtle);
           }
     
           .selected {
             background: var(--subtle);
             border-color: var(--primary);
           }
     
           .grid:hover .selected {
             background: var(--primary);
             border-color: var(--primary-lit);
           }
     
           .grid.highlight .selected {
             background: var(--accent);
             border-color: var(--accent-lit);
           }
     
           .grid.highlight:hover .selected {
             background: var(--accent-lit);
           }
     
           .top-left {
             border-top-left-radius: var(--grid-border-radius);
           }
     
           .top-right {
             border-top-right-radius: var(--grid-border-radius);
           }
     
           .bottom-left {
             border-bottom-left-radius: var(--grid-border-radius);
           }
     
           .bottom-right {
             border-bottom-right-radius: var(--grid-border-radius);
           }
         `,
  ],
  template: `
    <div
      class="grid"
      [ngClass]="{
        highlight: highlight,
        'none-selected': noneSelected
      }"
      [ngStyle]="gridStyle"
    >
      <div
        *ngFor="let it of range; let i = index"
        class="item"
        [ngStyle]="getItemStyle(i)"
        [ngClass]="getItemClass(i)"
      ></div>
    </div>
  `,
})
export class GridIconComponent {
  @Input() count: number
  @Input() selectedIndex: number | number[] | null
  @Input() highlight: boolean
  @Input() threeItemSpanPosition: GridThreeItemSpanPosition = 'top'

  get range(): number[] {
    return range(0, this.count)
  }

  get gridStyle() {
    if (this.count === 3) {
      return { gridTemplateColumns: 'repeat(2, 1fr)' }
    }

    const [columnCount] = calcGridDimensions(this.count)
    return { gridTemplateColumns: `repeat(${columnCount}, 1fr)` }
  }

  get noneSelected(): boolean {
    if (this.selectedIndex != null) {
      const indices = toArray(this.selectedIndex)
      return indices.every(i => i < 0)
    }
    return true
  }

  getItemClass(index: number) {
    if (this.count === 3) {
      if (this.threeItemSpanPosition === 'bottom') {
        return {
          selected: this.isSelected(index),
          'top-left': index === 0,
          'top-right': index === 1,
          'bottom-left': index === 2,
          'bottom-right': index === 2,
        }
      } else {
        return {
          selected: this.isSelected(index),
          'top-left': index === 0,
          'top-right': index === 0,
          'bottom-left': index === 1,
          'bottom-right': index === 2,
        }
      }
    }
    const [columnCount, rowCount] = calcGridDimensions(this.count)
    return {
      selected: this.isSelected(index),
      'top-left': index === 0,
      'top-right': index === columnCount - 1,
      'bottom-left': index / columnCount === rowCount - 1,
      'bottom-right': index === this.count - 1,
    }
  }

  getItemStyle(index: number) {
    if (this.count === 3) {
      const spanIndex = this.threeItemSpanPosition === 'bottom' ? 2 : 0
      if (spanIndex === index) {
        return { gridColumn: '1 / 3' }
      }
    }
  }

  private isSelected(index: number): boolean {
    if (this.selectedIndex != null) {
      const indices = toArray(this.selectedIndex)
      return indices.includes(index)
    }
    return false
  }
}
