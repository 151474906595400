import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  BenchmarkExportOrientation,
  BenchmarkExportTypeDef,
  benchmarkExportTypeDefs,
} from '../export/benchmark-export.model'
import { BenchmarkAbbreviationsState } from '../model/benchmark-abbreviation'
import * as fromActions from './benchmark-export.actions'

export interface BenchmarkExportState {
  enabled: boolean
  type: BenchmarkExportTypeDef
  showLegend: boolean
  showHeader: boolean
  darkMode: boolean
  orientation: BenchmarkExportOrientation
  exporting: boolean
  includingChart: boolean
  abbreviations?: BenchmarkAbbreviationsState
  firstPageFlag: boolean
}

export const initialState: BenchmarkExportState = {
  enabled: false,
  type: benchmarkExportTypeDefs[0],
  showLegend: true,
  showHeader: true,
  darkMode: true,
  orientation: 'landscape',
  exporting: false,
  includingChart: false,
  firstPageFlag: false,
}

const enabled = createReducer(
  initialState.enabled,
  on(fromActions.ToggleBenchmarkExportMode, state => !state)
)

const type = createReducer(
  initialState.type,
  on(fromActions.SetBenchmarkExportType, (_, { value }) => value)
)

const showLegend = createReducer(
  initialState.showLegend,
  on(fromActions.SetBenchmarkExportShowLegend, (_, { value }) => value)
)

const showHeader = createReducer(
  initialState.showHeader,
  on(fromActions.SetBenchmarkExportShowHeader, (_, { value }) => value)
)

const darkMode = createReducer(
  initialState.darkMode,
  on(fromActions.SetBenchmarkExportDarkMode, (_, { value }) => value)
)

const orientation = createReducer(
  initialState.orientation,
  on(fromActions.SetBenchmarkExportOrientation, (_, { value }) => value)
)

const exporting = createReducer(
  initialState.exporting,
  on(fromActions.SetBenchmarkExporting, (_, { value }) => value)
)

const includingChart = createReducer(
  initialState.includingChart,
  on(fromActions.SetBenchmarkIncludingChart, (_, { value }) => value)
)

const firstPageFlag = createReducer(
  initialState.firstPageFlag,
  on(fromActions.SetBenchmarkExportFirstPageFlag, (_, { value }) => value)
)

const _reducer = combineReducers<BenchmarkExportState>({
  enabled,
  type,
  showLegend,
  showHeader,
  darkMode,
  orientation,
  exporting,
  includingChart,
  firstPageFlag,
})

export function reducer(
  state: BenchmarkExportState | undefined,
  action: Action
): BenchmarkExportState {
  return _reducer(state, action)
}
