<app-compare-metrics-sidebar
  *ngIf="entities.length > 0"
  [entitiesLength]="entities.length"
  [metricCategories]="getMetricCategories(0)"
  [expandedMetricCategories]="expandedMetricCategories"
  [size]="size"
  [offsetHeight]="height + 82"
  [grossSelected]="grossSelected"
  [hiddenMetricRanks]="hiddenMetricRanks"
  [expandedChangeMetrics]="expandedChangeMetrics"
  [firstEntity]="entities[0]"
  (collapseToggle)="collapseToggle.emit($event)"
  (ragToggle)="ragToggle.emit($event)"
  (changeToggle)="changeToggle.emit($event)"
  (scrollChange)="onScrollChange($event)"
  (updateGrossSelection)="updateGrossSelection.emit($event)"
></app-compare-metrics-sidebar>

<div class="swiper-container" [swiper]="config">
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="swiper-wrapper"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngIf="grossSelected && entities.length > 0 && grossMetricsInput"
      class="swiper-slide"
      [ngStyle]="slideStyleGross"
    >
      <app-tower-controls
        hideShowTower
        [showAgg]="false"
        [programType]="false"
        [page]="'Compare'"
        class="hidden-compare"
      ></app-tower-controls>
      <div
        class="hidden-compare"
        [ngStyle]="{ width: 'width' }"
        [style.height.px]="height"
      >
        <img class="img-settings"/>
      </div>

      <app-compare-metrics-container
        [entitiesLength]="entities.length"
        [precision]="precision"
        [portfolioSetID]="grossMetricsInput.grossPortfolioSetID"
        [name]="'Gross Metrics'"
        [metricCategories]="grossMetricsInput.compareMetricsGrossCategory"
        [expandedMetricCategories]="expandedMetricCategories"
        [firstEntity]="entities[0]"
        [expandedChangeMetrics]="expandedChangeMetrics"
        [structureIndex]="0"
        [size]="size"
        (scrollChange)="onScrollChange($event)"
      ></app-compare-metrics-container>
    </div>

    <div
      *ngFor="let layers of entities; let i = index; trackBy: trackByID"
      class="swiper-slide"
      [ngStyle]="slideStyle"
      cdkDragLockAxis="x"
      cdkDrag
    >
      <app-tower-controls
        hideShowTower
        [showAgg]="showAgg(i)"
        [page]="'Compare'"
        [programType]="programTypeEmpty(i)"
        [scenarios]="getScenarios(i)"
        [scenariosSelected]="getScenariosSelected(i)"
        [optimizations]="getOptimizations(i)"
        [optimizationsSelected]="getOptimizationsSelected(i)"
        [title]="getProgramName(i)"
        (showAggChange)="onShowAggChange($event, i)"
        (programRemove)="programRemove.emit(entities[i].program)"
        (scenarioOrOptimizationSelectChange)="
              onScenarioOrOptimizationSelectChange(i, $event)
            "
      ></app-tower-controls>

      <app-compare-thumbnail-container
        *ngIf="!showAgg(i)"
        [width]="{ width: 'width' }"
        [height]="height"
        [thumbnailName]="getImageSrc(i)"
        [lastModified]="getLastModified(i)"
      >
      </app-compare-thumbnail-container>
      <div
        *ngIf="showAgg(i)"
        [ngStyle]="{ width: 'width' }"
        [style.height.px]="height"
      >
        <app-compare-group-thumbnail
          *ngIf="programTypeEmpty(i)"
          [index]="i"
          [height]="height"
          [entities]="entities"
        ></app-compare-group-thumbnail>

        <div *ngIf="!programTypeEmpty(i)" class="img-row">
          <app-compare-thumbnail-container
            [thumbnailName]="getImageSrc(i)"
            [lastModified]="getLastModified(i)"
            [showBothThumbnails]="true"
          >
          </app-compare-thumbnail-container>
          <app-compare-thumbnail-container
            [thumbnailName]="getAggImageSrc(i)"
            [showBothThumbnails]="true"
          >
          </app-compare-thumbnail-container>
        </div>
      </div>

      <app-compare-metrics-container
        [entitiesLength]="entities.length"
        [precision]="precision"
        [portfolioSetID]="getPortfolioSetID(i)"
        [name]="getName(i)"
        [metricCategories]="getMetricCategories(i)"
        [expandedMetricCategories]="expandedMetricCategories"
        [hiddenMetricRanks]="hiddenMetricRanks"
        [expandedChangeMetrics]="expandedChangeMetrics"
        [firstEntity]="entities[0]"
        [size]="size"
        [rankMax]="rankMax"
        [metricsWeight]="getMetricsWeight(i)"
        [structureIndex]="i"
        (returnPeriodChange)="onReturnPeriodChange($event)"
        (scrollChange)="onScrollChange($event)"
      ></app-compare-metrics-container>
    </div>
  </div>

  <div class="swiper-scrollbar"></div>
</div>
