import { Program } from 'src/app/core/model/program.model'
import { ProgramGroup, ProgramGroupMember } from './program-group.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { select, Store } from '@ngrx/store'
import { selectAllAncestorGroupsWithID } from 'src/app/core/store/program-group-member.selectors'
import { first } from 'rxjs/operators'
import {
  fetchCededLayersViews,
  fetchPortfolioView,
} from '../views/portfolio-view.actions'
import { AppState } from 'src/app/core/store'
import { Client } from 'src/app/core/model/client.model'
import { Observable, forkJoin } from 'rxjs'
import { ProgramEntity } from './program/program.reducer'
import { Dictionary } from '@ngrx/entity'
import { LayerState } from '../ceded-layers/layers.reducer'
import { Layer } from '../../model/layers.model'
import { fetchGrouperProgramLayers } from './program/program.actions'
import { fetchStudyReinsurer } from '../../../reinsurers/store/study-reinsurers.actions'
import { DatePipe } from '@angular/common'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { Study } from 'src/app/core/model/study.model'
import { LayerViewValues } from '../../model/layer-view'
import { Cell } from 'exceljs'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export const fetchViewsForStructuresAndGetAncestors = (
  programs: Program[],
  allLayers: LayerState[],
  client: Client | null,
  store: Store<AppState>
): Observable<ProgramGroup[][]> => {
  const myObservableArray: Observable<ProgramGroup[]>[] = []

  programs.forEach(program => {
    const portfolioSetID = extractPortfolioSetID(program)
    if (!client || !portfolioSetID) {
      throw Error(
        'Cannot update metrics without portfolio, yearID and study IDs'
      )
    }
    const clientID = client.id
    const studyID = program.studyID
    const portfolioSetAndStudyIDs = {
      ...portfolioSetID,
      clientID,
      studyID,
      currency: program.structureCurrency,
    }
    const newGroups = store.pipe(
      select(selectAllAncestorGroupsWithID({ programID: program.id })),
      first()
    )
    myObservableArray.push(newGroups)
    store.dispatch(fetchGrouperProgramLayers({ program }))

    store.dispatch(fetchPortfolioView(portfolioSetAndStudyIDs))

    const cededLayers: Layer[] = allLayers
      .filter(l => l.layer.meta_data.structureID === program.id)
      .map(l1 => l1.layer)
    const sharedLimitLayerID = cededLayers
      .filter(l => l.meta_data.sage_layer_type === 'shared_limits')
      .map(l => l.physicalLayer.id)

    const layerIDs = [...cededLayers.map(l => l.id), ...sharedLimitLayerID]
    store.dispatch(
      fetchStudyReinsurer({
        carrierID: portfolioSetAndStudyIDs.clientID,
        studyID: portfolioSetAndStudyIDs.studyID,
      })
    )

    store.dispatch(
      fetchCededLayersViews({
        ...portfolioSetAndStudyIDs,
        layerIDs,
        analysisProfileID: portfolioSetAndStudyIDs.analysisProfileID,
        isDesign: false,
        isGroup: true,
      })
    )
  })

  return forkJoin(myObservableArray)
}

export const getGroupsWithStudyAndClient = (
  groups: ProgramGroup[][],
  allProgramGroups: ProgramGroup[],
  client: Client | null,
  programsByID: Dictionary<ProgramEntity>,
  programGroupMembers: ProgramGroupMember[]
): ProgramGroup[] => {
  const programGroups = allProgramGroups.filter(pg =>
    groups
      .flat()
      .map(g => g.id)
      .includes(pg.id)
  )
  const groupsWithStudyAndClient = programGroups.map(pg => {
    if (!pg.clientID && !pg.studyID) {
      const studyID = getStudyID(pg.id, programsByID, programGroupMembers)
      return {
        ...pg,
        studyID,
        // tslint:disable-next-line: no-non-null-assertion
        clientID: client!.id,
      }
    }
    return pg
  })
  return groupsWithStudyAndClient
}

const getStudyID = (
  pgID: string,
  programsByID: Dictionary<ProgramEntity>,
  programGroupMembers: ProgramGroupMember[]
): string => {
  let studyID = ''

  for (const pgm of programGroupMembers) {
    if (pgID === pgm.parentGroupID) {
      if (pgm.programID) {
        studyID =
          // tslint:disable-next-line: no-non-null-assertion
          programsByID[`${pgm.programID}_${pgm.parentGroupID}`]!.program.studyID
        break
      } else if (pgm.programGroupID) {
        studyID = getStudyID(
          pgm.programGroupID,
          programsByID,
          programGroupMembers
        )
      }
    }
  }
  return studyID
}

export const getEffectiveDate = (accountOpportunities: AccountOpportunity[], currentStudy: Study | undefined, datePipe: DatePipe): string => {
  let effectiveDate = ''
  const accOpp = accountOpportunities?.find(
    opp => opp.id === currentStudy?.opportunity_id
  )
  const accOppOnIncepDate = accOpp?.opportunityInceptionDate
  if (accOppOnIncepDate) {
    const parts = accOppOnIncepDate.split('-')
    const date = new Date(
      parseInt(parts[0], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[2], 10)
    ).toString()
    effectiveDate = datePipe.transform(date, 'longDate') || ''
  }
  return effectiveDate
}

export const getLayerListAsString = (viewValues: LayerViewValues[]): string => {
  return viewValues.map(x => {
    let output = x.name
    if (x.description){
      output += `) ${x.description}`
    }
    return output
  }).join("   ")
}


export const styleHeader = (row: any[] | string, column: SortTableColumnDef<LayerViewValues>, cell: Cell, colIdx: number) => {
  const setWidths = [
    {id: 'name', width: 20},
    {id: 'description', width: 20},
    {id: 'reinstatementsSummary', width: 15},
    {id: 'inuringBenefit', width: 7},
    {id: 'pmpm', width: 7},
  ]

  if (column.id === 'expectedCededLossRatio' && row !== 'headerRow' && row[colIdx] === 0) {
    cell.value = 'na'
  }
  if (row !== "headerRow"){
    return
  }
  if (column.id === 'name'){
    cell.alignment = {
      horizontal: 'left',
      vertical: 'middle'
    }
  }
  const width = setWidths.find(x => x.id === column.id)?.width || 10
  cell.worksheet.getColumn(cell.col).width = width
}



