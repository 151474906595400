import { isLoadedLossSet } from '../../model/layers.util'
import {
  LossSetLayer,
  ScaledLossSetProps,
} from '../../model/loss-set-layers.model'
import { SortTableRow } from '@shared/sort-table/sort-table.model'
import { LoadedLossSet } from 'src/app/api/analyzere/analyzere.model'

export const getRows = (lossSetLayers: LossSetLayer[]): SortTableRow<ScaledLossSetProps>[] => {
  const rows: ScaledLossSetProps[] = []
  lossSetLayers.forEach(ls => {
    const loadedLossSet = isLoadedLossSet(ls.loss_sets[0])
    const lossSet = ls.loss_sets[0] as LoadedLossSet
    const {
      min_loss,
      avg_annual_loss,
      max_loss,
      num_losses,
      non_zero_losses,
    } = lossSet.profile
    const {
      ls_dim1 = '',
        ls_dim2,
      program_name,
      sage_layer_type,
      perspective,
      map1,
      map2,
      map3,
      map4,
      map5,
    } = ls.meta_data
    const name: string = ls_dim1 + (ls_dim2 ? ' - ' + ls_dim2 : '')
    const lossScaleFactor = loadedLossSet ? lossSet.load : 1
    const row = {
      description: ls.description ? ls.description : '',
      lossScaleFactor,
      originalPremium:
        ls.meta_data.originalPremium || ls.meta_data.originalPremium === 0
          ? ls.meta_data.originalPremium
          : ls.premium?.value || 0,
      premiumScaleFactor: 0,
      newPremium: ls.premium?.value || 0,
      newExpenseRatio: ls.fees && ls.fees.length > 0 ? ls.fees[0].rate : 0,
      min_loss: min_loss * lossScaleFactor,
      avg_annual_loss: avg_annual_loss * lossScaleFactor,
      max_loss: max_loss * lossScaleFactor,
      id: ls.id,
      lossSetID: ls.id,
      name,
      ls_dim1,
      ls_dim2,
      program_name,
      sage_layer_type,
      perspective,
      num_losses,
      non_zero_losses,
      map1: map1 ?? '',
      map2: map2 ?? '',
      map3: map3 ?? '',
      map4: map4 ?? '',
      map5: map5 ?? '',
    }
    row.premiumScaleFactor =
      row.originalPremium === 0
        ? 0
        : ls.meta_data.originalPremium
          ? ls.premium?.value || 0 / row.originalPremium
          : 1
          rows.push(row)
  })
  return rows
}

