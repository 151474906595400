import { SalesforceClient } from '../../../api/model/signature.model'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { FotState } from '../../store/fots/fot.reducer'
import {
  SignatureContract,
  SignatureReinsurer,
  SignatureReinsurerReferenceType,
} from '../../signature.model'
import {
  SignatureCreateContractDialogComponent,
  SignatureCreateContractDialogConfig,
} from '../signature-create-contract-dialog.component'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { Subject } from 'rxjs'
import { FeatureFlag } from '../../../api/model/backend.model'
import { takeUntil } from 'rxjs/operators'

type SignatureCreateContractDialogRef = MatDialogRef<
  SignatureCreateContractDialogComponent,
  SignatureCreateContractDialogConfig
>

const PANEL_CLASS = 'app-signature-create-contract-dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signature-content',
  templateUrl: './signature-content.component.html',
  styleUrls: ['./signature-content.component.scss'],
})
export class SignatureContentComponent implements OnChanges, OnDestroy, OnInit {
  private destroy$ = new Subject<void>()

  @Input() contracts: SignatureContract[]
  @Input() selectedContract: SignatureContract
  @Input() reinsurers: SignatureReinsurer[]
  @Input() selectedReinsurer: SignatureReinsurer
  @Input() currentPageIndex: number
  @Input() isDirty: boolean
  @Input() isSaving: boolean
  @Input() isExporting: boolean
  @Input() dirtyContracts: string[]
  @Input() featureFlags: FeatureFlag[]
  @Input() groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  @Input() renewalRiskRefs: string[]
  @Input() clients: SalesforceClient[]
  @Input() isUsaBasedUser: boolean

  @Input() set fots(value: FotState[] | undefined) {
    this._fots = value
    this.updateDialogData()
  }
  getFots(): FotState[] {
    return this._fots ?? []
  }
  private _fots: FotState[] | undefined
  fotsToAdd: FotState[] | undefined
  fotsToRemove: FotState[] | undefined
  dirtyContractString: string | null
  otAllowed: boolean
  paginatorCount = 0
  deleteReinsurerClass = ''
  deleteContractClass = ''

  @Output() createContract = new EventEmitter<{
    pageSetName: string
    fots: FotState[]
  }>()
  @Output() saveClick =
    new EventEmitter() /* Save all dirty contracts in state */
  @Output() resetClick =
    new EventEmitter<SignatureContract>() /* Reset assigned lines for FOTs of selected contract */
  @Output() deleteClick =
    new EventEmitter<SignatureContract>() /* Delete selected contract from database */
  @Output() deleteReinsurerClick =
    new EventEmitter<SignatureReinsurer>() /* Delete selected reinsurer from this contract */
  @Output() generateExportClick = new EventEmitter<
    SignatureReinsurer[]
  >() /* Export all pages for selected contract */
  @Output() backToSelectorClick = new EventEmitter()
  @Output() setSelectedContract = new EventEmitter<SignatureContract>()
  @Output() setSelectedSigPage = new EventEmitter<SignatureReinsurer>()
  @Output() updateSignatureForm = new EventEmitter<{
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceType?: SignatureReinsurerReferenceType
  }>()
  @Output() addLayerToSelectedContract = new EventEmitter<FotState>()
  @Output() removeLayerFromSelectedContract = new EventEmitter<FotState>()
  @Output() submitToOT = new EventEmitter<SignatureContract>()
  @Output() isCoRiskContractChangeEvent = new EventEmitter<boolean>()
  @Output() leadRiskRefChangeEvent = new EventEmitter<string | undefined>()

  selectedContractName: string
  pages: SignatureReinsurer[] = []
  showSignaturePageView: boolean

  constructor(
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.showSignaturePageView = this.isUsaBasedUser
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dirtyContractString = this.getDirtyContractString()
    this.otAllowed = this.getOTAllowed()
    this.paginatorCount = this.getPaginatorLength()
    this.deleteReinsurerClass = this.getDeleteReinsurerClass()
    this.deleteContractClass = this.getDeleteContractClass()
    if (!this.selectedReinsurer) {
      return
    }
    const fotsToFilterOut = this.selectedReinsurer.fotsReinsured.map(fot => {
      const fotId =
        this.groupOrStructureFilterSelection === 'Group'
          ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
          : this.groupOrStructureFilterSelection === 'SL'
          ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
          : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
      return fotId
    })
    const currFots: FotState[] = changes.fots
      ? changes.fots.currentValue
      : this._fots
    this.fotsToAdd =
      currFots.filter(fot => !fotsToFilterOut.find(f => f === fot.id)) ?? []
    this.fotsToRemove =
      currFots.filter(fot => fotsToFilterOut.find(f => f === fot.id)) ?? []
  }

  ngOnDestroy(): void {
    const ref = this.getDialogRef()
    if (ref) {
      ref.close()
    }
    this.destroy$.next()
    this.destroy$.complete()
  }

  getDeleteContractClass(): string {
    return !this.selectedContract?.id ? 'disabled' : 'not-disabled'
  }

  getDeleteReinsurerClass(): string {
    return this.selectedContract?.reinsurers.length <= 1
      ? 'disabled'
      : 'not-disabled'
  }

  getReinsurerCount(): number {
    return this.selectedContract?.reinsurers.length
  }

  getDirtyContractString(): string | null {
    return !this.isDirty
      ? 'No contracts to save'
      : this.dirtyContracts
      ? `Save Changes for: ${this.dirtyContracts.join(', ')}`
      : null
  }

  getOTAllowed(): boolean {
    if (this.featureFlags) {
      const featureFlagFound = this.featureFlags.find(
        element => element.featureName === 'AllowOTSubmissions'
      )
      if (featureFlagFound) {
        return featureFlagFound.isEnabled
      }
    }
    return false
  }

  getPaginatorLength(): number {
    if (!this.selectedContract) {
      return 1
    }
    return this.selectedContract.reinsurers?.length ?? 0
  }

  paginatorChange(event: PageEvent): void {
    this.setSelectedSigPage.emit(
      this.selectedContract.reinsurers[event.pageIndex]
    )
  }

  buildDialogData(): SignatureCreateContractDialogConfig {
    return {
      groupOrStructureFilterSelection: this.groupOrStructureFilterSelection,
      fots: this._fots,
    }
  }

  updateDialogData(): void {
    const ref = this.getDialogRef()
    if (ref && ref.componentInstance) {
      ref.componentInstance.setData(this.buildDialogData())
    }
  }

  onNewContract(): void {
    const ref = this.getDialogRef()
    if (ref) {
      return
    }

    const data: SignatureCreateContractDialogConfig = {
      ...this.buildDialogData(),
    }

    const dialogRef = this.dialog.open(SignatureCreateContractDialogComponent, {
      id: PANEL_CLASS,
      panelClass: PANEL_CLASS,
      data,
      backdropClass: 'backdropBackground',
    })

    dialogRef
      .afterClosed()
      .subscribe(
        (contractToCreate: { pageSetName: string; fots: FotState[] }) => {
          if (contractToCreate) {
            this.createContract.emit(contractToCreate)
          }
        }
      )
  }

  onDeleteContract(): void {
    this.confirmationDialog
      .open({
        message: 'Are you sure you want to delete this contract?',
        submitLabel: 'Delete Contract',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((doDelete: boolean) => {
        if (doDelete) {
          this.deleteClick.emit(this.selectedContract)
        }
      })
  }

  backToSelector(): void {
    if (this.isDirty) {
      this.confirmationDialog
        .open({
          message:
            'There are unsaved changes. Are you sure you want to return to the group or structure selector?',
          submitLabel: 'Return',
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((doClose: boolean) => {
          if (doClose) {
            this.backToSelectorClick.emit()
          }
        })
    } else {
      this.backToSelectorClick.emit()
    }
  }

  onDeleteReinsurer(): void {
    if (this.getReinsurerCount() <= 1) {
      return
    }
    this.confirmationDialog
      .open({
        message: 'Are you sure you want to delete this reinsurer page?',
        submitLabel: 'Delete Reinsurer',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((doDelete: boolean) => {
        if (doDelete) {
          this.deleteReinsurerClick.emit(this.selectedReinsurer)
        }
      })
  }

  updateSelectedContract(): void {
    const contract = this.contracts.find(
      c => c.pageSetName === this.selectedContractName
    )
    if (contract) {
      this.setSelectedContract.emit(contract)
    }
  }

  updateSelectedPage($event: any): void {
    const reinsurerIndex = this.selectedContract.reinsurers.findIndex(
      p => p.reinsurerName === $event.value
    )
    if (reinsurerIndex < 0) {
      return
    }
    this.setSelectedSigPage.emit(
      this.selectedContract.reinsurers[reinsurerIndex]
    )
  }

  saveChanges(): void {
    if (this.isDirty) {
      this.saveClick.emit()
    }
  }

  exportContract(): void {
    if (!this.isExporting) {
      this.generateExportClick.emit(this.selectedContract.reinsurers)
    }
  }

  resetSelectedContractAssignedLines(): void {
    this.resetClick.emit(this.selectedContract)
  }

  private getDialogRef(): SignatureCreateContractDialogRef | undefined {
    return this.dialog.getDialogById(PANEL_CLASS)
  }

  submitToOTButtonClicked(): void {
    if (!this.isDirty) {
      this.submitToOT.emit(this.selectedContract)
    }
  }
}
