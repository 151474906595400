import { Action, createReducer, on } from '@ngrx/store'
import {
  DEFAULT_INCEPTION_DATE,
  DEFAULT_TRACKING_SELECTORS,
  TrackingData,
  TrackingPreferences,
  TrackingSelectors,
} from '../tracking.model'
import * as fromTrackingActions from './tracking.actions'
import { IControl } from 'src/app/management-information/store/management-information.reducer'
import { getSelectors } from '../tracking.utils'
import { BasicControl } from 'src/app/management-information/model/management-information.model'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'

export const TRACKING_KEY = 'tracking'

export interface TrackingState {
  trackingData: TrackingData[]
  selectors: TrackingSelectors
  inceptionTimeInterval: BasicControl
  trackingUserPreferences: SelectedUserPreferences[]
  selectedUserPreference: number
  isLoading: boolean
  isDetailsLoading: boolean
}

const initialState: TrackingState = {
  trackingData: [],
  selectors: DEFAULT_TRACKING_SELECTORS,
  inceptionTimeInterval: DEFAULT_INCEPTION_DATE,
  trackingUserPreferences: [],
  selectedUserPreference: null,
  isLoading: false,
  isDetailsLoading: false
}

const trackingReducer = createReducer(
  initialState,
  on(fromTrackingActions.fetchTracking, state => {
    return {
      ...state,
      isLoading: true,
    }
  }),
  on(
    fromTrackingActions.fetchTrackingSuccess,
    (state: TrackingState, { trackingData }) => {
      return {
        ...state,
        trackingData,
        selectors: getSelectors(state.selectors, trackingData),
        isLoading: false,
      }
    }
  ),
  on(fromTrackingActions.fetchTrackingFailure, state => {
    return {
      ...state,
      isLoading: false,
    }
  }),
  on(
    fromTrackingActions.filtersChanged,
    (state: TrackingState, { filters }) => {
      if (!filters) {
        return state
      }
      const newSelectors: TrackingSelectors = { ...state.selectors }
      const entries: [string, IControl][] = Object.entries(state.selectors)
      for (const [selectorKey, selector] of entries) {
        newSelectors[selectorKey as keyof TrackingSelectors] = {
          ...selector,
          selectedValues: filters[selectorKey] ?? [],
        }
      }
      return { ...state, selectors: newSelectors, selectedUserPreference: null }
    }
  ),
  on(
    fromTrackingActions.inceptionDateChanged,
    (state: TrackingState, { minInceptionDate, maxInceptionDate }) => {
      return {
        ...state,
        inceptionTimeInterval: {
          ...state.inceptionTimeInterval,
          minValue: minInceptionDate,
          maxValue: maxInceptionDate,
        },
        selectedUserPreference: null,
      }
    }
  ),
  on(fromTrackingActions.fetchTrackingDetails, state => {
    return {
      ...state,
      isDetailsLoading: true,
    }
  }),
  on(
    fromTrackingActions.fetchTrackingDetailsSuccess,
    (state: TrackingState, { trackingDetailsData }) => {
      return {
        ...state,
        trackingData: state.trackingData.map(existingData =>
          trackingDetailsData &&
          trackingDetailsData[0] &&
          existingData.programId === trackingDetailsData[0].programId
            ? { ...existingData, details: trackingDetailsData }
            : existingData
        ),
        isDetailsLoading: false,
      }
    }
  ),
  on(fromTrackingActions.fetchTrackingDetailsFailure, state => {
    return {
      ...state,
      isDetailsLoading: false,
    }
  }),

  on(
    fromTrackingActions.updateCompleteProgramSuccess,
    (state: TrackingState, { programEvents }) => {
      return {
        ...state,
        trackingData: state.trackingData.map(existingData =>
          programEvents &&
          existingData.programId.toString() ===
            programEvents.programId.toString()
            ? { ...existingData, complete: 'Yes' }
            : existingData
        ),
      }
    }
  ),

  on(
    fromTrackingActions.saveTrackingPreferencesSuccess,
    (state: TrackingState, { userPreferences }) => {
      let updatedPreference = state.trackingUserPreferences
      return {
        ...state,
        trackingUserPreferences: [...updatedPreference, userPreferences],
      }
    }
  ),

  on(
    fromTrackingActions.getTrackingPreferencesSuccess,
    (state: TrackingState, { userPreferences }) => {
      return {
        ...state,
        trackingUserPreferences: userPreferences,
      }
    }
  ),

  on(
    fromTrackingActions.setSelectedUserPreferences,
    (state: TrackingState, { id }) => {
      let preferenceFound = state.trackingUserPreferences.find(t => t.id === id)

      if (preferenceFound) {
        let updatedSelectors = JSON.parse(JSON.stringify(state.selectors))
        let updatedTime = JSON.parse(
          JSON.stringify(state.inceptionTimeInterval)
        )
        let selectedPreferenceValues = JSON.parse(
          preferenceFound.preferenceValue
        ) as TrackingPreferences
        if (selectedPreferenceValues) {
          updatedSelectors.clientName.selectedValues =
            selectedPreferenceValues.clientName
          updatedSelectors.businessUnit.selectedValues =
            selectedPreferenceValues.businessUnit
          updatedSelectors.year.selectedValues = selectedPreferenceValues.year
          updatedSelectors.complete.selectedValues =
            selectedPreferenceValues.complete
          updatedTime.minValue = selectedPreferenceValues.minDateValue
          updatedTime.maxValue = selectedPreferenceValues.maxDateValue
        }

        return {
          ...state,
          selectedUserPreference: id,
          selectors: updatedSelectors,
          inceptionTimeInterval: updatedTime,
        }
      } else {
        return {
          ...state,
        }
      }
    }
  )
)

export function reducer(
  state: TrackingState | undefined,
  action: Action
): TrackingState {
  return trackingReducer(state, action)
}

