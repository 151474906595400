import { BasicControl } from '../../../management-information/model/management-information.model'
import {
  IControl,
  IIntervalFilter,
} from '../../../management-information/store/management-information.reducer'
import {
  LAYER_PALETTE,
  LayerPaletteProgram,
} from '../../../analysis/model/layer-palette.model'
import { LAYER_TYPE_SELECTOR_NAME, QuoteTabType } from '../model/quote-management-information.model'
import { getLayerIdFromName } from './quote-summary.util'

export const getLayerTypeNameForLayerType = (layerType: string): string => {
  // TODO: check if Agg Feeder can be added in quote sage, since it has same layer id as Aggregate
  const layerName = LAYER_PALETTE.filter(
    lp => lp.name !== 'Aggregate Feeder'
  ).find(lp => lp.id === layerType)?.name
  return layerName ?? layerType
}

export const updateInterval = (
  data: IIntervalFilter,
  controls: BasicControl[],
  isMin: boolean
): BasicControl[] => {
  return (
    controls?.map((control: BasicControl) => {
      if (control.columnName === data.filterId && isMin) {
        control = {
          ...control,
          minValue: data.newMinValue,
        }
      }
      if (control.columnName === data.filterId && !isMin) {
        control = {
          ...control,
          maxValue: data.newMaxValue,
        }
      }
      return control
    }) || []
  )
}

export const getKeyValuePairsForFiltersAndIntervals = (
  audienceView: LayerPaletteProgram,
  filters: IControl[],
  intervals: BasicControl[],
  activeTab?: string
): Record<string, string | string[]> => {
  let kvps: Record<string, string | string[]> = { audienceView }
  if(activeTab && activeTab === QuoteTabType.DETAILS){
    if(filters && filters.length > 0){
      filters = filters.map(item =>
        item.id === 'ultimateSupporter' && item.selectedValues.length <= 0 ? { ...item, selectedValues: ["Yes","No"] } : item
      )
    }
  } else {
    if(filters && filters.length > 0){
      filters = filters.map(item =>
        item.id === 'ultimateSupporter' ? { ...item, selectedValues: [] } : item
      )
    }
  }
  filters?.forEach((control: IControl) => {
    if (!control.selectedValues) {
      return
    }
    kvps = {
      ...kvps,
      [control.columnName]:
        control.name === LAYER_TYPE_SELECTOR_NAME
          ? control.selectedValues.map(val =>
              getLayerIdFromName(val, audienceView)
            )
          : control.selectedValues,
    }
  })
  intervals.forEach((interval: BasicControl) => {
    if (!!interval?.minValue) {
      const intervalMinName = `${interval.columnName}Min`
      kvps = {
        ...kvps,
        [intervalMinName]: interval.minValue,
      }
    }

    if (!!interval?.maxValue) {
      const intervalMaxName = `${interval.columnName}Max`
      kvps = {
        ...kvps,
        [intervalMaxName]: interval.maxValue,
      }
    }
  })
  return kvps
}
