import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { concat, includes, lensPath, reject, set, view, __ } from 'ramda'
import {
  createNewCarrierYearProgramFromOpportunitySuccess,
  createNewProgramFromOpportunitySuccess,
  createNewYearProgramFromOpportunitySuccess,
} from 'src/app/program-initiation/store/program-initation.actions'
import { saveSuccess } from '../../analysis/optimization/store/optimization-candidates-results.actions'
import { saveAsAnalysisSuccess, saveAsCloneAnalysis } from '../../analysis/store/analysis.actions'
import * as GrouperActions from '../../analysis/store/grouper/grouper.actions'
import { ApplicationError } from '../../error/model/error'
import { parseClientsFromEntitiesResponse } from '../model/client.converter'
import { Client, ClientYear } from '../model/client.model'
import { Study } from '../model/study.model'
import * as AuthActions from './auth/auth.actions'
import * as ProgramInitiationActions from '../../program-initiation/store/program-initation.actions'

interface ExtendedState {
  loading: boolean
  error: ApplicationError | null
}
export type State = EntityState<Client> & ExtendedState

export const adapter = createEntityAdapter<Client>()

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
})

const clientsReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(
    AuthActions.identifyPermissionsSuccess,
    ProgramInitiationActions.refreshCarrierYearsSuccess,
    (state, action) => {
      const clients = parseClientsFromEntitiesResponse(action)
      const next = adapter.setAll(clients, state)
      return {
        ...next,
        loading: initialState.loading,
        error: initialState.error,
      }
    }
  ),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(
    GrouperActions.saveGrouperSuccess,
    (state, { clientID, groups, members }) => {
      const client = state.entities[clientID]
      if (!client) {
        return state
      }
      const groupIDs = client.programGroupIDs
      // Remove group IDs that are in the changes.groups.remove list
      const groupIDsFiltered = reject(includes(__, groups.remove), groupIDs)
      // Add group IDs that are in the changes.group.create list
      const groupIDsToAdd = groups.create.map(g => g.id)
      const programGroupIDs = concat(groupIDsFiltered, groupIDsToAdd)

      const memberIDs = client.programGroupMemberIDs
      // Remove member IDs that are in the changes.members.remove list
      const memberIDsFiltered = reject(includes(__, members.remove), memberIDs)
      // Add member IDs that are in the changes.members.create list
      const memberIDsToAdd = members.create.map(m => m.id)
      const programGroupMemberIDs = concat(memberIDsFiltered, memberIDsToAdd)

      // Update the clients groups and members lists
      const changes = { programGroupIDs, programGroupMemberIDs }
      return adapter.updateOne({ id: clientID, changes }, state)
    }
  ),

  on(saveAsAnalysisSuccess, (state, { clientID, studyID, program }) => {
    const client = state.entities[clientID]
    if (!client) {
      return state
    }
    let studyIndex = -1
    const yearIndex = client.clientYears.findIndex(y => {
      const si = y.studies.findIndex(s => s.id === studyID)
      if (si >= 0) {
        studyIndex = si
        return true
      }
      return false
    })
    const lens = lensPath([
      'clientYears',
      yearIndex,
      'studies',
      studyIndex,
      'programIDs',
    ])
    const programIDs = view<Client, Array<string | number>>(lens, client)
    if (studyIndex < 0 || yearIndex < 0 || programIDs == null) {
      console.error(
        `Save As: Cannot find study for client: ` +
          `'${clientID}', study: '${studyID}'`
      )
      return state
    }
    const changes = set(lens, [...programIDs, program.id], client)
    return adapter.updateOne({ id: clientID, changes }, state)
  }),

  on(saveSuccess, (state, { details }) => {
    if (details.length === 0) {
      return state
    }
    const client = state.entities[details[0].clientID]
    if (!client) {
      return state
    }
    let studyIndex = -1
    const yearIndex = client.clientYears.findIndex(y => {
      const si = y.studies.findIndex(s => s.id === details[0].studyID)
      if (si >= 0) {
        studyIndex = si
        return true
      }
      return false
    })
    const lens = lensPath([
      'clientYears',
      yearIndex,
      'studies',
      studyIndex,
      'programIDs',
    ])
    const programIDs = view<Client, Array<string | number>>(lens, client)
    if (studyIndex < 0 || yearIndex < 0 || programIDs == null) {
      console.error(
        `Optimization Save: Cannot find study for client: ` +
          `'${details[0].clientID}', study: '${details[0].studyID}'`
      )
      return state
    }
    const newProgramIDs = details.map(d => d.program.id)
    const changes = set(lens, [...programIDs, ...newProgramIDs], client)
    return adapter.updateOne({ id: details[0].clientID, changes }, state)
  }),

  on(createNewProgramFromOpportunitySuccess, (state, { newProgram }) => {
    // Create new Program (Study) object from response
    const newProgramToAdd: Study = {
      id: newProgram.id.toString(),
      carrierYearID: newProgram.carrier_year_id.toString(),
      name: newProgram.name,
      programIDs: [],
      imageSrc: 'assets/cards/ML.png',
      description: newProgram.description,
      opportunity_id: newProgram.opportunity_id,
      executive_summary: newProgram.executive_summary,
    }

    // Find the right client
    let client: Client | undefined
    for (const [, value] of Object.entries(state.entities)) {
      if (value?.clientYears) {
        // Find the right clientYear
        const matchingClientYear = value.clientYears.find(
          year => year.id === newProgram.carrier_year_id.toString()
        )
        // If found, create updated objects and add new program
        if (matchingClientYear) {
          client = value

          const updatedStudies: Study[] = [
            ...matchingClientYear.studies,
            newProgramToAdd,
          ]

          const updatedClientYear: ClientYear = {
            ...matchingClientYear,
            studies: updatedStudies,
          }

          // Remove old ClientYear and replace with updated ClientYear
          const updatedYears: ClientYear[] = client.clientYears.filter(
            year => year.id !== matchingClientYear.id
          )
          updatedYears.push(updatedClientYear)

          const changes: Client = {
            ...client,
            clientYears: updatedYears,
          }

          // Update correct entity
          return adapter.updateOne({ id: client.id, changes }, state)
        }
      }
    }
    return state
  }),

  on(
    createNewYearProgramFromOpportunitySuccess,
    (state, { newProgram, year, carrierID }) => {
      // Create new Program (Study) object from response
      const newProgramToAdd: Study = {
        id: newProgram.id.toString(),
        carrierYearID: newProgram.carrier_year_id.toString(),
        name: newProgram.name,
        programIDs: [],
        imageSrc: 'assets/cards/ML.png',
        description: newProgram.description,
        opportunity_id: newProgram.opportunity_id,
        executive_summary: newProgram.executive_summary,
      }
      // Find the right client
      const client = state.entities[carrierID]

      if (client) {
        // Create new ClientYear and add new Program
        const newClientYear: ClientYear = {
          id: newProgramToAdd.carrierYearID,
          year,
          studies: [newProgramToAdd],
        }

        // in case clientYear isn't defined on client yet, default to empty list
        const clientYearsInit: Client = client.clientYears
          ? {
              ...client,
            }
          : {
              ...client,
              clientYears: [],
            }

        const changes: Client = {
          ...clientYearsInit,
          clientYears: [...clientYearsInit.clientYears, newClientYear],
        }

        // Update correct entity
        return adapter.updateOne({ id: client.id, changes }, state)
      }
      return state
    }
  ),

  on(
    createNewCarrierYearProgramFromOpportunitySuccess,
    (state, { newCarrier }) => {
      const newClient: Client = {
        ...newCarrier,
        id: newCarrier.id.toString(),
        bannerImageSrc: '',
        clientYears: [],
        programGroupIDs: [],
        programGroupMemberIDs: [],
      }

      return adapter.addOne(newClient, state)
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return clientsReducer(state, action)
}
