import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core'
import { Tower3D } from '../util/tower-3d'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { StructureLayerDataResponse } from '../../../api/model/backend.model'
import { SharedIDGroup } from '../../store/grouper/program-group.model'
import { BehaviorSubject, of, Subject, timer } from 'rxjs'
import { concatMap, delay, delayWhen, filter, takeUntil } from 'rxjs/operators'
import { ScenarioEventResult } from '../animated-scenarios.model'
import { Program } from '../../../core/model/program.model'
import {
  DEFAULT_START_ANGLE,
  toRadians,
  Tower3DAngle,
} from '../util/tower.3d.model'

const LOSS_EVENT_DELAY = 5000
const ROTATION_DELAY = 1000
const INIT_LOSS_EVENT_DELAY = 1500
export const CUBE_TRANSITION = LOSS_EVENT_DELAY - 1000

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tower-3d',
  styleUrls: ['./tower-3d.component.scss'],
  templateUrl: './tower-3d.component.html',
})
export class Tower3DComponent implements AfterViewInit, OnDestroy {
  @Input() name: string
  @Input()
  set layers(val: LayerState[]) {
    this.layersSubject$.next(val)
    this._layers = val
  }
  get layers() {
    return this._layers
  }
  _layers: LayerState[]
  @Input() layersData: StructureLayerDataResponse[]
  @Input()
  set sharedIDGroup(val: SharedIDGroup[]) {
    this._sharedIDGroup = val
    this.sharedIDGroupSubject$.next(val)
  }
  get sharedIDGroup() {
    return this._sharedIDGroup
  }
  _sharedIDGroup: SharedIDGroup[]

  @Input()
  set eventResults(val: ScenarioEventResult[]) {
    this._eventResults = val
    this.eventResultsSubject$.next(val)
  }
  get eventResults() {
    return this._eventResults
  }
  _eventResults: ScenarioEventResult[] = []

  @Input() eventResultStructure: Program
  @Input() animating: boolean
  @Input() baseScale = 30

  @Output() eventAnimationIndex = new EventEmitter<number>()
  @Output() animationEnd = new EventEmitter()

  @ViewChild('chart', { static: false })
  private container: ElementRef
  private tower3D: Tower3D

  private layersSubject$ = new BehaviorSubject<LayerState[]>([])
  private sharedIDGroupSubject$ = new BehaviorSubject<SharedIDGroup[]>([])
  private eventResultsSubject$ = new BehaviorSubject<ScenarioEventResult[]>([])
  private animationSubject$ = new BehaviorSubject<{
    index: number
    eventResult: ScenarioEventResult
  } | null>(null)
  private destroy$ = new Subject()
  private eventCounter = 0

  constructor(private shortNumberPipe: ShortNumberPipe) {}

  private getCubeTransitionDelay(): number {
    if (this.animating) {
      return CUBE_TRANSITION
    } else {
      return 0
    }
  }

  private getStartAngle(): Tower3DAngle {
    if (this.animating) {
      return DEFAULT_START_ANGLE
    } else if (!this.animating && this.eventResults.length === 0) {
      return DEFAULT_START_ANGLE
    } else {
      return {
        ...DEFAULT_START_ANGLE,
        y: DEFAULT_START_ANGLE.y - toRadians(105),
      }
    }
  }

  private getMaxY(): number {
    const element = this.container.nativeElement as HTMLElement
    const height = element.getBoundingClientRect().height
    let maxY = Math.floor(
      (height * 0.82 - this.getMarginBottom()) / this.getScale()
    )
    if (maxY % 2 !== 0) {
      maxY = maxY - 1
    }
    return maxY
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
    if (this.tower3D) {
      this.tower3D.destroy()
    }
  }

  ngAfterViewInit(): void {
    this.tower3D = new Tower3D(this.container.nativeElement, {
      marginLeft: this.getMarginLeft(),
      marginBottom: this.getMarginBottom(),
      cubeTransitionDuration: CUBE_TRANSITION,
      scale: this.getScale(),
      numberFormatter: this.shortNumberPipe,
      startAngle: this.getStartAngle(),
      maxY: this.getMaxY(),
    })
    this.layersSubject$
      .pipe(
        takeUntil(this.destroy$),
        delayWhen(layers => {
          if (layers.find(l => l.layer.sharedLayerID)) {
            return this.sharedIDGroupSubject$.pipe(filter(g => g.length > 0))
          } else {
            return of(1)
          }
        })
      )
      .subscribe(layers => {
        if (layers.length > 0) {
          this.tower3D.setInitLayers(
            layers,
            this.layersData,
            this.sharedIDGroup
          )
          this.tower3D.render(this.getCubeTransitionDelay())
        }
      })
    this.eventResultsSubject$
      .pipe(
        takeUntil(this.destroy$),
        delay(this.animating ? ROTATION_DELAY : 0)
      )
      .subscribe(eventResults => {
        if (eventResults.length > 0) {
          this.tower3D.setInitLayers(
            this.layers,
            this.layersData,
            this.sharedIDGroup
          )
          if (this.animating) {
            this.rotate()
          }
        }
        let index = 1
        for (const eventResult of eventResults) {
          this.animationSubject$.next({ index, eventResult })
          index++
        }
      })
    this.animationSubject$
      .pipe(
        takeUntil(this.destroy$),
        concatMap(val =>
          of(val).pipe(
            delayWhen(result => {
              if (this.animating) {
                if (result === null || result.index === 1) {
                  return timer(INIT_LOSS_EVENT_DELAY)
                } else {
                  return timer(LOSS_EVENT_DELAY)
                }
              } else {
                return of({})
              }
            })
          )
        )
      )
      .subscribe(val => {
        if (val) {
          this.eventCounter++
          this.tower3D.setEventLayers(
            val.eventResult.layers.map(l => ({
              ...l,
              layer: { ...l.layer, id: l.layer.id + val.index },
            })),
            this.eventResultStructure.layerData || [],
            val.index
          )
          this.tower3D.render(this.getCubeTransitionDelay())
          if (this.animating) {
            this.eventAnimationIndex.emit(val.index)
          }
          if (this.eventCounter === this.eventResults.length) {
            this.eventCounter = 0
            this.animationEnd.emit()
          }
        }
      })
  }

  private rotate(): void {
    this.tower3D.setStartAngle(this.getStartAngle())
    this.tower3D.setYRotation(-105, ROTATION_DELAY)
  }

  private getMarginLeft(): number {
    return this.getScale() * 6
  }

  private getMarginBottom(): number {
    return this.getScale() * 3.2
  }

  getScale(): number {
    const baseWidth = 1536
    const baseHeight = 864
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const ratio = Math.min(screenWidth / baseWidth, screenHeight / baseHeight)

    return this.baseScale * ratio
  }

  private resizeTimeout: number

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout)
    }
    this.resizeTimeout = window.setTimeout(
      (() => {
        this.tower3D.update()
      }).bind(this),
      250
    )
  }
}
