import { createAction, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { TrackingData, TrackingDetailsData } from '../tracking.model'
import { ProgramEvents } from 'src/app/program-events/model/program-events.model'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'

export const fetchTracking = createAction('[Tracking] Fetch')

export const fetchTrackingSuccess = createAction(
  '[Tracking] Fetch Success',
  props<{ trackingData: TrackingData[] }>()
)

export const fetchTrackingFailure = createAction(
  '[Tracking] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const filtersChanged = createAction(
  '[Tracking] Filters changed',
  props<{ filters?: Record<string, string[]> }>()
)

export const inceptionDateChanged = createAction(
  '[Tracking] Inception Date changed',
  props<{ minInceptionDate: string; maxInceptionDate: string }>()
)

export const fetchTrackingDetails = createAction(
  '[Tracking Details] Fetch',
  props<{ programId: number }>()
)

export const fetchTrackingDetailsSuccess = createAction(
  '[Tracking Details] Fetch Success',
  props<{ trackingDetailsData: TrackingDetailsData[] }>()
)

export const fetchTrackingDetailsFailure = createAction(
  '[Tracking Details] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const updateCompleteProgram = createAction(
  '[Tracking Details] Update Complete',
  props<{ programId: number }>()
)

export const updateCompleteProgramSuccess = createAction(
  '[Tracking Details] Update Complete Success',
  props<{ programEvents: ProgramEvents }>()
)

export const updateCompleteProgramFailure = createAction(
  '[Tracking Details] Update Complete Failure',
  props<{ error: ApplicationError }>()
)

export const saveTrackingPreferences = createAction(
  '[Tracking Details] Save Tracking Preferences',
  props<{ label: string }>()
)

export const saveTrackingPreferencesFailure = createAction(
  '[Tracking Details] Save Tracking Preferences Failure',
  props<{ error: ApplicationError }>()
)

export const saveTrackingPreferencesSuccess = createAction(
  '[Tracking Details] Save Tracking Preferences Success',
  props<{ userPreferences: SelectedUserPreferences }>()
)

export const getTrackingPreferences = createAction(
  '[Tracking Details] Get Tracking Preferences'
)

export const getTrackingPreferencesFailure = createAction(
  '[Tracking Details] Get Tracking Preferences Failure',
  props<{ error: ApplicationError }>()
)

export const getTrackingPreferencesSuccess = createAction(
  '[Tracking Details] Get Tracking Preferences Success',
  props<{ userPreferences: SelectedUserPreferences[] }>()
)

export const setSelectedUserPreferences = createAction(
  '[Tracking Details] Set Selected User Preferences',
  props<{ id: number }>()
)
