<div
  class="premium-list-entry"
  [ngClass]="{ error: hasError }"
  (mouseover)="hovered = true"
  (mouseout)="hovered = false"
>
  <div class="title-container">
    <span class="index" *ngIf="isLayer">{{ layerIndex }}:&nbsp;</span>
    <h3
      class="title"
      [ngClass]="titleClass"
      [matTooltip]="saveForOnlyNewLayersTooltip"
      [matTooltipDisabled]="!saveForNewLayersOnly"
    >
      {{ title }}
    </h3>
    <button
      class="options-button"
      appButton
      [matMenuTriggerFor]="optionsMenu"
      matTooltip="Options"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu
      #optionsMenu="matMenu"
      panelClass="app-menu-translucent"
      backdropClass="app-theme-dark"
    >
      <button mat-menu-item *ngIf="isLayer" (click)="handleSetEntryToDefault()">
        Set to Default
      </button>
      <button mat-menu-item *ngIf="!isLayer" (click)="onSetSaveMode()">
        {{ newLayersOnlyButtonText }}
      </button>
      <button mat-menu-item [disabled]="!modified" (click)="handleResetEntry()">
        Reset
      </button>
    </mat-menu>
  </div>
  <div class="curve-details">
    <div class="curve-list">
      <div class="curve" *ngFor="let curve of curveEntries; let i = index">
        <mat-icon
          appButtonIcon
          class="close"
          *ngIf="curve.id !==  null && curve.id !== -1"
          (click)="removeCurve(curve)"
          >close</mat-icon
        >
        <div
          class="name"
          (mouseover)="updateTooltipCurve(curve.id, $event)"
          (mouseout)="resetTooltip()"
        >
          {{ curve.pc_name }}
        </div>
        <button
          class="slider"
          appButton
          small
          [matMenuTriggerFor]="sliderMenu"
          [ngClass]="{
            disabled: 'curveEntries.length === 1 && curve.percentage === 1'
          }"
          [disabled]="curveEntries.length === 1 && curve.percentage === 1"
          *ngIf="curve.id !== null"
        >
          <div class="percentage">
            {{ curve.percentage | percent: '1.0-0' }}
          </div>
        </button>
        <mat-menu
          #sliderMenu="matMenu"
          yPosition="above"
          class="app-menu-no-max-width"
        >
          <app-weight-slider
            [weight]="curve.percentage * 100"
            (weightChange)="onWeightChange($event, i)"
          ></app-weight-slider>
        </mat-menu>
        <app-pricing-curve-saved-curve-tooltip
          *ngIf="tooltipSavedCurve && tooltipMouseEvent && curve.id !==  null"
          [savedCurve]="tooltipSavedCurve"
          [mouseEvent]="tooltipMouseEvent"
          parentWidth="50"
        ></app-pricing-curve-saved-curve-tooltip>
      </div>
    </div>
    <div class="premium-container" *ngIf="isLayer">
      <span>{{ premiumText }}:&nbsp;</span>
      <div *ngIf="premium !==  null && premium !==  undefined">
        <span
          class="technical-premium"
          *ngIf="premium !==  undefined && premium !==  null && !isQS"
        >
          <span *ngIf="displayCurrency">{{ currencyString }}</span
          ><span>{{ premium | number: '1.0-0' }}</span>
        </span>
        <span
          class="ceding-commission"
          *ngIf="premium !==  undefined && premium !==  null && isQS"
        >
          {{ premium | percent: '1.1-1' }}
        </span>
      </div>
      <label
        class="empty"
        appInfoText
        *ngIf="premium === null || premium === undefined"
      >
        Loading...
        <span>&nbsp;</span>
        <mat-spinner [diameter]="20" color="accent"></mat-spinner
      ></label>
    </div>
  </div>
  <div
    class="add-curve"
    [ngClass]="{ visible: hovered, disabled: isLayer && premium === undefined }"
    (click)="openCurveDialog()"
  >
    <mat-icon inline>add</mat-icon>
    <span>Pricing Curves</span>
  </div>
</div>
