import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatOptionSelectionChange } from '@angular/material/core'
import { uniq } from 'ramda'
import { Subject, Observable, of } from 'rxjs'
import { debounceTime, map, startWith } from 'rxjs/operators'
import {
  AccountOpportunity,
  CarrierAccount,
} from '../../../api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-initiation-filter',
  templateUrl: `./program-initiation-filter.component.html`,
  styleUrls: [`./program-initiation-filter.component.scss`],
})
export class ProgramInitiationFilterComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() unfilteredAccountOpportunities: AccountOpportunity[]
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() carrierAccounts: CarrierAccount[] | null
  @Input() currentClientID: string | null
  @Output() filteredAccountOpportunities = new EventEmitter<string[]>()

  accountNameControl = new FormControl()
  filteredAccountNames: Observable<string[]>
  accountNames: string[]

  opportunityNameControl = new FormControl()
  filteredOpportunityNames: Observable<string[]>
  opportunityNames: string[]

  opportunityStageControl = new FormControl()
  filteredOpportunityStages: Observable<string[]>
  opportunityStages: string[]

  businessUnitControl = new FormControl()
  filteredBusinessUnits: Observable<string[]>
  opportunityBusinessUnits: string[]

  opportunityClassControl = new FormControl()
  filteredClasses: Observable<string[]>
  opportunityClasses: string[]

  opportunitySubClassControl = new FormControl()
  filteredSubClasses: Observable<string[]>
  opportunitySubClasses: string[]

  riskTypeControl = new FormControl()
  filteredOpportunityRiskTypes: Observable<string[]>
  opportunityRiskTypes: string[]

  filters: Record<string, string> = {}

  destroy$ = new Subject()

  ngOnInit(): void {
    this.accountNames = this.getAccountNames()
    this.opportunityNames = this.getOpportunityNames()
    this.opportunityStages = this.getOpportunityStages()
    this.opportunityBusinessUnits = this.getOpportunityBusinessUnits()
    this.opportunityClasses = this.getOpportunityClasses()
    this.opportunitySubClasses = this.getOpportunitySubClasses()
    this.opportunityRiskTypes = this.getOpportunityRiskTypes()
    const carrierAccount = this.carrierAccounts?.find(
      c => c.carrier_id === Number(this.currentClientID)
    )
    const selectedAccount = this.accountOpportunities.find(
      c => carrierAccount?.sf_acctid === c.accountId
    )
    const accountName = selectedAccount ? selectedAccount.accountName : ''
    this.accountNameControl.setValue(selectedAccount?.accountName)
    this.filteredAccountNames = this.accountNameControl.valueChanges.pipe(
      debounceTime(500),
      startWith(accountName),
      map(value => {
        this.updateFilter('accountName', value)
        return this._filter(value, this.accountNames)
      })
    )
    this.filteredOpportunityNames =
      this.opportunityNameControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.updateFilter('opportunityName', value)
          return this._filter(value, this.opportunityNames)
        })
      )
    this.filteredOpportunityStages =
      this.opportunityStageControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.updateFilter('opportunityStage', value)
          return this._filter(value, this.opportunityNames)
        })
      )
    this.filteredBusinessUnits = this.businessUnitControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.updateFilter('opportunityBU', value)
        return this._filter(value, this.opportunityBusinessUnits)
      })
    )
    this.filteredClasses = this.opportunityClassControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.updateFilter('opportunityClass', value)
        return this._filter(value, this.opportunityClasses)
      })
    )
    this.filteredSubClasses = this.opportunitySubClassControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.updateFilter('opportunitySubClass', value)
        return this._filter(value, this.opportunitySubClasses)
      })
    )
    this.filteredOpportunityRiskTypes = this.riskTypeControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.updateFilter('opportunityRiskType', value)
        return this._filter(value, this.opportunityRiskTypes)
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.accountOpportunities &&
      changes.accountOpportunities?.currentValue !==
        changes.accountOpportunities?.previousValue
    ) {
      this.accountOpportunities = changes.accountOpportunities?.currentValue
      this.fetchUpdatedFilterValues()
    }
  }

  fetchUpdatedFilterValues(): void {
    this.opportunityNames = this.getOpportunityNames()
    this.filteredOpportunityNames = of(this.getOpportunityNames())
    this.filteredOpportunityStages = of(this.getOpportunityStages())
    this.filteredBusinessUnits = of(this.getOpportunityBusinessUnits())
    this.opportunityBusinessUnits = this.getOpportunityBusinessUnits()
    this.filteredClasses = of(this.getOpportunityClasses())
    this.opportunityClasses = this.getOpportunityClasses()
    this.filteredSubClasses = of(this.getOpportunitySubClasses())
    this.opportunitySubClasses = this.getOpportunitySubClasses()
    this.filteredOpportunityRiskTypes = of(this.getOpportunityRiskTypes())
    this.opportunityRiskTypes = this.getOpportunityRiskTypes()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private getAccountNames() {
    return uniq(this.accountOpportunities.map(a => a.accountName))
  }

  private getOpportunityNames() {
    return uniq(this.accountOpportunities.map(a => a.opportunityName)).sort(
      (a, b) => (a > b ? 1 : -1)
    )
  }

  private getOpportunityStages() {
    return uniq(this.accountOpportunities.map(a => a.opportunityStage)).sort(
      (a, b) => (a > b ? 1 : -1)
    )
  }

  private getOpportunityBusinessUnits() {
    return uniq(this.accountOpportunities.map(a => a.opportunityBU)).sort(
      (a, b) => (a > b ? 1 : -1)
    )
  }

  private getOpportunityClasses() {
    return uniq(
      this.accountOpportunities.map(a => a.opportunityClass || '')
    ).sort((a, b) => (a > b ? 1 : -1))
  }

  private getOpportunitySubClasses() {
    return uniq(
      this.accountOpportunities.map(a => a.opportunitySubClass || '')
    ).sort((a, b) => (a > b ? 1 : -1))
  }

  private getOpportunityRiskTypes() {
    return uniq(
      this.accountOpportunities.map(a => a.opportunityRiskType || '')
    ).sort((a, b) => (a > b ? 1 : -1))
  }

  private _filter(value: string, options: string[]): string[] {
    if (value.length >= 3 || value === '') {
      const filterValue = value.toLowerCase()
      return options.filter(option =>
        option.toLowerCase().includes(filterValue)
      )
    }
    return options
  }

  updateFilter(field: string, value: string) {
    if (value.length >= 3 || value === '') {
      this.filters[field] = value
      const oppIds = this.unfilteredAccountOpportunities
        .filter(a => {
          return Object.keys(this.filters).every(k => {
            const filterValue = this.filters[k]

            if (
              filterValue === '' ||
              !this.isValidFilterValue(k, filterValue)
            ) {
              return true
            }
            const _a = a as any
            return _a[k] === filterValue
          })
        })
        .map(a => a.oppId)
      this.filteredAccountOpportunities.emit(oppIds)
    }
  }

  isValidFilterValue(key: string, value: string) {
    return this.accountOpportunities.some(a => {
      const _a = a as any
      return _a[key] === value
    })
  }

  updateSelectionFilter(e: MatOptionSelectionChange, type: string) {
    this.updateFilter(type, e.source.value)
  }

  clearSearchFilters() {
    this.filters = {}
    this.updateFilter('accountName', '')

    this.accountNameControl.setValue('')
    this.opportunityNameControl.setValue('')
    this.opportunityStageControl.setValue('')
    this.businessUnitControl.setValue('')
    this.opportunityClassControl.setValue('')
    this.opportunitySubClassControl.setValue('')
    this.riskTypeControl.setValue('')
  }

  onNameKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunityNames.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredOpportunityNames = of(filtered)
    }
  }

  onStageKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunityStages.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredOpportunityStages = of(filtered)
    }
  }

  onBUKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunityBusinessUnits.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredBusinessUnits = of(filtered)
    }
  }

  onClassKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunityClasses.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredClasses = of(filtered)
    }
  }

  onSubClassKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunitySubClasses.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredSubClasses = of(filtered)
    }
  }

  onRiskTypeKeyUp(searchText: string): void {
    if (searchText !== null) {
      const filtered = this.opportunityRiskTypes.filter(
        b => b.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      this.filteredOpportunityRiskTypes = of(filtered)
    }
  }
}
