import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { PricingCurveService } from '../../api/pricingcurve/pricing-curve.service'
import * as fromActions from './pricing-curve.actions'
import * as fromSelectors from './pricing-curve.selectors'
import {
  switchMap,
  withLatestFrom,
  map,
  filter,
  mergeMap,
} from 'rxjs/operators'
import {
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from 'src/app/api/util'
import {
  BLANK_CURVE,
  CombinedSelectors,
  CreditCurveLayer,
  PricingCurveData,
  PricingCurveLayer,
  PricingCurveWarnings,
  SavedPricingCurveEntry,
} from '../model/pricing-curve.model'
import {
  getCurveTypeFromTechFactors,
  getKeyValuePairs,
  getSavedCurveSelectorsKvpFromSelectors,
  manuallyApplySelectorsToLayers,
  updateLayerStatusForCurve,
} from '../pricing-curve.utils'
import { of } from 'rxjs'
import {
  PricingCurveExportPage,
  PricingCurvePageTypes,
} from '../export/pricing-curve-export.model'
import { PricingCurveExportService } from '../export/pricing-curve-export.service'
import { waitFor } from '@shared/observables'
import { TechnicalPremiumSyncService } from 'src/app/analysis/technical-premium/technical-premium-sync.service'
import { selectPrograms } from 'src/app/core/store/program/program.selectors'
import { selectClients } from 'src/app/core/store/clients.selectors'
import { selectCurrentClientID } from 'src/app/core/store/broker/broker.selectors'
import { clone, equals, flatten, pipe, pluck, uniq } from 'ramda'
import { MatSnackBar } from '@angular/material/snack-bar'
import { PricingCurve } from '../model/pricing-curve'

@Injectable()
export class PricingCurveEffects {
  private actions$ = inject(Actions)

  constructor(
    private service: PricingCurveService,
    private store: Store<AppState>,
    private exportService: PricingCurveExportService,
    private tpSyncService: TechnicalPremiumSyncService,
    private snackBar: MatSnackBar
  ) {}

  addDataDialogOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addDataDialogOpened),
      withLatestFrom(
        this.store.select(fromSelectors.selectNextLocalGraphId),
        this.store.select(fromSelectors.selectNextGraphColor)
      ),
      switchMap(([, id, graphColorClass]) => {
        const actions: Action[] = [
          fromActions.filtersChanged({}),
          fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }),
          fromActions.updateWorkingCurveData({
            curveData: { id, graphColorClass },
          }),
        ]
        return actions
      })
    )
  )

  filtersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.filtersChanged,
        fromActions.cleanFilters,
        fromActions.clearFilter,
        fromActions.clearDateInterval,
        fromActions.updateDateIntervalValues
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(fromSelectors.selectWorkingPricingCurveData)
      ),
      switchMap(([, context, curveData]) => {
        const actions: Action[] = []
        if (!curveData) {
          return actions
        }
        actions.push(
          fromActions.fetchLayers({
            layerSplitView: curveData.layerSplitView,
          })
        )

        // Only fetch selectors for pricing curve, not credit
        if (context === 'pricing-curve') {
          actions.push(
            fromActions.fetchSelectors({
              layerSplitView: curveData.layerSplitView,
            })
          )
        }
        return actions
      })
    )
  )

  fetchSelectorData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSelectors),
      withLatestFrom(
        this.store.select(fromSelectors.selectFilters),
        this.store.select(fromSelectors.selectWorkingDateIntervals),
        this.store.select(fromSelectors.selectWorkingCombinedSelectors)
      ),
      switchMap(
        ([{ layerSplitView }, filters, intervals, combinedSelectors]) => {
          return this.service.getSelectors(
            getKeyValuePairs(intervals, filters, layerSplitView),
            combinedSelectors
          )
        }
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSelectorsFailure({ error }))
      ),
      switchMap(combinedSelectors => {
        const actions: Action[] = [
          fromActions.fetchSelectorsSuccess({ combinedSelectors }),
        ]

        return actions
      })
    )
  )

  fetchLayerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchLayers),
      withLatestFrom(
        this.store.select(fromSelectors.selectFilters),
        this.store.select(fromSelectors.selectWorkingDateIntervals),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([{ layerSplitView }, filters, intervals, context]) => {
        return this.service.getLayers(
          getKeyValuePairs(intervals, filters, layerSplitView),
          context
        )
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchLayersFailure({ error }))
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      map(([data, context]) => {
        const isCredit = context === 'credit'
        return fromActions.fetchLayersSuccess({
          creditLayers: isCredit ? (data as CreditCurveLayer[]) : undefined,
          layers: !isCredit ? (data as PricingCurveLayer[]) : undefined,
        })
      })
    )
  )

  submitCreditCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitCreditCurve),
      filter(({ curve }) => !!curve.creditPredictionColumns),
      withLatestFrom(this.store.select(selectClients)),
      switchMap(([{ curve, saveCurve }, clients]) => {
        return this.service.getCreditPredictions(curve, saveCurve, clients)
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.submitCreditCurveFailure({ error }))
      ),
      map(curveData => fromActions.addCurveToGraph({ curveData }))
    )
  )

  saveCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(selectClients)
      ),
      switchMap(([{ curve }, context, clients]) => {
        if (curve.id >= 0) {
          return this.service.updateSavedPricingCurve(
            curve.id,
            curve,
            context,
            clients
          )
        } else {
          return this.service.postPricingCurve(curve, context, clients)
        }
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      switchMap(curveData => [
        fromActions.addCurveToGraph({ curveData }),
        fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }),
        fromActions.savePricingCurveSuccess(),
      ])
    )
  )

  refitPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refitPricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      filter(([_, context]) => context === 'pricing-curve'),
      switchMap(([{ id, action }, context]) =>
        this.service.getLayers({}, context).pipe(
          rejectError(error =>
            this.store.dispatch(fromActions.fetchLayersFailure({ error }))
          ),
          map(split => ({ id, action, split }))
        )
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([{ id, action, split }, context]) =>
        this.service.getLayers(getKeyValuePairs([], [], false), context).pipe(
          rejectError(error =>
            this.store.dispatch(fromActions.fetchLayersFailure({ error }))
          ),
          map(combined => ({ id, action, split, combined }))
        )
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurves),
        this.store.select(fromSelectors.selectPricingCurveGraphSettings),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(
        ([
          { id, action, split, combined },
          savedCurves,
          graphSettings,
          context,
        ]) => {
          const curveToRefit = savedCurves.find(curve => curve.id === id)
          const layers = (
            curveToRefit.isCombined ? combined : split
          ) as PricingCurveLayer[]
          const filteredLayers = manuallyApplySelectorsToLayers(
            layers,
            curveToRefit.active_filters
          )
          const includedLayerIds =
            action === 'original'
              ? curveToRefit.included_excluded_layers
              : filteredLayers.map(layer => {
                  if (!!layer.props.hist_data_id) {
                    return String(layer.props.hist_data_id)
                  }
                  return `${layer.props.rr_id}-${layer.props.ral_id}`
                })
          const curveData: PricingCurveData = {
            ...BLANK_CURVE,
            id: curveToRefit.id,
            curveType: getCurveTypeFromTechFactors(curveToRefit.techFactors),
            selectors: curveToRefit.active_filters?.selectors ?? null,
            dateIntervals: curveToRefit.active_filters?.dateIntervals ?? null,
            technicalFactors: curveToRefit.techFactors ?? null,
            layerSplitView: !curveToRefit.isCombined,
            includedLayerIds,
            context,
            isEdit: true,
            layers: [],
            label: curveToRefit.pc_name,
            isManual: false,
          }

          const updatedCurve = updateLayerStatusForCurve(
            curveData,
            filteredLayers,
            'pricing-curve'
          )

          const pricingCurve = new PricingCurve(
            updatedCurve,
            graphSettings,
            null
          )
          return this.service
            .updateSavedPricingCurve(
              pricingCurve.id,
              pricingCurve.curveData,
              context,
              []
            )
            .pipe(
              rejectError(error =>
                this.store.dispatch(
                  fromActions.savePricingCurveFailure({ error })
                )
              )
            )
        }
      ),
      map(() => {
        this.snackBar.open('Successfully refit pricing curve', 'X', {
          duration: 2000,
        })
        return fromActions.savePricingCurveSuccess()
      })
    )
  )

  updateIsSavingLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectWorkingPricingCurveData)
      ),
      waitFor(this.actions$, [fromActions.savePricingCurveSuccess]),
      switchMap(([, { id }]) => {
        return this.tpSyncService.doesPricingCurveNeedToSaveLayers(id).pipe(
          rejectError(error =>
            this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
          ),
          map(newValue => fromActions.updateIsSavingLayers({ newValue }))
        )
      })
    )
  )

  updateTechnicalPremiumForLayersUsingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectWorkingPricingCurveData),
        this.store.select(selectPrograms),
        this.store.select(fromSelectors.selectSavedCurves)
      ),
      waitFor(this.actions$, [fromActions.savePricingCurveSuccess]),
      switchMap(([, { id }, programs, savedCurves]) => {
        return this.tpSyncService.updateLayersFromPricingCurve(
          id,
          programs,
          savedCurves
        )
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      map(() => fromActions.updateIsSavingLayers({ newValue: false }))
    )
  )

  savedPricingCurveFiltersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.savedCurveFiltersChanged,
        fromActions.savedCurveIntervalFilterChanged,
        fromActions.clearSavedCurveDateInterval,
        fromActions.clearSavedCurveFilter,
        fromActions.toggleCurveImmutabilitySuccess
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurveSelectors),
        this.store.select(fromSelectors.selectSavedCurveCreatorNameMap),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([_, filters, nameMap, context]) =>
        this.service.getPricingCurves(
          getSavedCurveSelectorsKvpFromSelectors(filters, nameMap),
          context
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSavedCurvesFailure({ error }))
      ),
      switchMap(data => {
        const activeFilters = data
          .map(dataSet => dataSet.active_filters)
          .filter((dataSet): dataSet is CombinedSelectors => !!dataSet)
        const actions: Action[] = [
          fromActions.fetchFilteredSavedCurvesSuccess({ data }),
          fromActions.initSavedCurveSelectors({
            selectors: activeFilters,
            names: data.map(entry => entry.fullName),
          }),
        ]

        return actions
      })
    )
  )

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPricingCurveData),
      map(({ filterByCarrier }) =>
        fromActions.fetchSavedCurves({
          useSavedCurveSelectors: false,
          filterByCarrier,
        })
      )
    )
  )

  initCreditCarriers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPricingCurveData),
      filter(({ context }) => context === 'credit'),
      switchMap(({ context }) => this.service.getLayers({}, context)),
      rejectError(error =>
        this.store.dispatch(fromActions.initCreditCarriersFailure({ error }))
      ),
      map(response => {
        const uniqueOrgs: string[] = pipe(
          pluck('organization'),
          flatten,
          uniq
        )((response as CreditCurveLayer[]) ?? [])
        return fromActions.initCreditCarriersSuccess({ carriers: uniqueOrgs })
      })
    )
  )

  triggerLoadSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.removePricingCurveFromGraph,
        fromActions.resetSavedCurveSelectors,
        fromActions.addDataDialogClosed,
        fromActions.updateSavedCurvesWithLatestLayerCountSuccess,
        fromActions.savePricingCurveSuccess
      ),
      map(() => fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }))
    )
  )

  loadSavedPricingCurves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSavedCurves),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurveSelectors),
        this.store.select(fromSelectors.selectSavedCurveCreatorNameMap),
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(selectCurrentClientID)
      ),
      switchMap(
        ([
          { useSavedCurveSelectors, filterByCarrier },
          filters,
          nameMap,
          context,
          clientId,
        ]) => {
          if (!filterByCarrier) {
            return this.service.getPricingCurves(
              useSavedCurveSelectors
                ? getSavedCurveSelectorsKvpFromSelectors(filters, nameMap)
                : {},
              context
            )
          } else {
            return this.service.getPricingCurvesForCarrier(Number(clientId))
          }
        }
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSavedCurvesFailure({ error }))
      ),
      switchMap(data => {
        const activeFilters = data
          .map(dataSet => dataSet.active_filters)
          .filter((dataSet): dataSet is CombinedSelectors => !!dataSet)
        const actions: Action[] = [
          fromActions.fetchSavedCurvesSuccess({ data }),
          fromActions.initSavedCurveSelectors({
            selectors: activeFilters,
            names: data.map(entry => entry.fullName),
          }),
        ]

        return actions
      })
    )
  )

  calculateIfPricingCurveHasUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSavedCurvesSuccess),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      filter(([_, context]) => context === 'pricing-curve'),
      switchMap(([_, context]) => this.service.getLayers({}, context)),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchLayersFailure({ error }))
      ),
      switchMap(split =>
        this.service
          .getLayers(getKeyValuePairs([], [], false), 'pricing-curve')
          .pipe(
            rejectError(error =>
              this.store.dispatch(fromActions.fetchLayersFailure({ error }))
            ),
            map(combined => ({
              split,
              combined,
            }))
          )
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurves),
        this.store.select(fromSelectors.selectPricingCurveGraphSettings)
      ),
      map(([{ split, combined }, savedCurves, graphSettings]) => {
        // For each curve, calculate a new pricing curve (isEdit: true makes the curve update factors from the layers)
        // and check against the saved technical factors, if they differ, quote data may have changed

        const curvesWithWarnings: SavedPricingCurveEntry[] = []
        const curvesWithValuesToUpdate: SavedPricingCurveEntry[] = []

        clone(savedCurves).forEach(curve => {
          const isManual = !curve.included_excluded_layers?.length
          if (isManual) {
            curvesWithWarnings.push(curve)
          } else {
            const layers = (
              curve.isCombined ? combined : split
            ) as PricingCurveLayer[]
            const curveData: PricingCurveData = {
              ...BLANK_CURVE,
              id: curve.id,
              curveType: getCurveTypeFromTechFactors(curve.techFactors),
              selectors: curve.active_filters?.selectors ?? null,
              dateIntervals: curve.active_filters?.dateIntervals ?? null,
              technicalFactors: curve.techFactors ?? null,
              layerSplitView: !curve.isCombined,
              includedLayerIds: curve.included_excluded_layers,
              context: 'pricing-curve',
              isEdit: true,
              layers: [],
              label: curve.pc_name,
              isManual: false,
            }
            const updatedCurve = updateLayerStatusForCurve(
              curveData,
              layers,
              'pricing-curve'
            )
            const pricingCurve = new PricingCurve(
              updatedCurve,
              graphSettings,
              null
            )

            const origTechFactors: any = curve.techFactors

            const valueChanged = Object.entries(
              pricingCurve.technicalFactors
            ).reduce<boolean>((acc, [key, val]) => {
              if (typeof val !== 'number') {
                return acc
              }
              // The db truncates the values, check if they are extremely close
              return acc || Math.abs(val - origTechFactors[key]) > 0.001
            }, false)

            const warnings: PricingCurveWarnings[] = []
            // If any of the calculated techFactor values have changed, add warning
            if (valueChanged) {
              warnings.push('updated-data')
            }
            const availableLayersForFilters = manuallyApplySelectorsToLayers(
              layers,
              pricingCurve.combinedSelectors
            )

            // If the number of layers for the given filters is different than the count the last time
            // the curve was saved, add warning
            if (availableLayersForFilters.length !== curve.lastFitLayerCount) {
              warnings.push('new-layers')
            }

            curvesWithWarnings.push({
              ...curve,
              warnings,
            })
            if (curve.lastFitLayerCount === null) {
              curvesWithValuesToUpdate.push({
                ...curve,
                lastFitLayerCount: availableLayersForFilters.length,
              })
            }
          }
        })
        return fromActions.updateSavedCurvesWithWarningFlagsSuccess({
          data: curvesWithWarnings,
          curvesToUpdate: curvesWithValuesToUpdate,
        })
      })
    )
  )

  updateSavedCurvesWithLatestLayerCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateSavedCurvesWithWarningFlagsSuccess),
      filter(({ curvesToUpdate }) => !!curvesToUpdate.length),
      mergeMap(({ curvesToUpdate }) =>
        this.service.bulkUpdateSavedPricingCurves(curvesToUpdate)
      ),
      rejectError(error =>
        this.store.dispatch(
          fromActions.updateSavedCurvesWithLatestLayerCountFailure({ error })
        )
      ),
      map(() => fromActions.updateSavedCurvesWithLatestLayerCountSuccess())
    )
  )

  deleteSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSavedPricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([action, context]) =>
        this.service.deletePricingCurve(action.id, context)
      ),
      rejectError(error =>
        this.store.dispatch(
          fromActions.deleteSavedPricingCurveFailure({ error })
        )
      ),
      map(() => fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }))
    )
  )

  loadSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSavedPricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([{ id }, context]) =>
        this.service.getPricingCurve(id, context)
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.loadSavedPricingCurveFailure({ error }))
      ),
      withLatestFrom(this.store.select(fromSelectors.selectNextGraphColor)),
      map(([curveData, graphColorClass]) =>
        fromActions.addCurveToGraph({
          curveData: {
            ...curveData,
            graphColorClass,
          },
        })
      )
    )
  )

  exportPricingCurveState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.exportPricingCurveState),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(
                fromSelectors.selectPricingCurvesByIds(action.includedCurves)
              )
            )
          )
        ),

        map(([{ exportLabel, graphBase64, options }, pricingCurves]) => {
          const pages: PricingCurveExportPage[] = []
          pages.push(
            ...pricingCurves.map((data, index) => ({
              id: index + 1,
              pageType: data.isLayerSet
                ? PricingCurvePageTypes.MANUAL_POINTS
                : PricingCurvePageTypes.CURVE,
              data,
            }))
          )

          this.exportService.export(exportLabel, options, pages, graphBase64)
        })
      ),
    { dispatch: false }
  )

  toggleCurveImmutability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleCurveImmutability),
      withLatestFrom(
        this.store.select(fromSelectors.selectIsUserPricingCurveAdmin),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      filter(([_, isAdmin]) => isAdmin),
      switchMap(([{ id }, _, context]) =>
        this.service.getPricingCurve(id, context)
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.loadSavedPricingCurveFailure({ error }))
      ),
      withLatestFrom(
        this.store.select(selectClients),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([curve, clients, context]) =>
        this.service.updateSavedPricingCurve(
          curve.id,
          {
            ...curve,
            isImmutable: !curve.isImmutable,
          },
          context,
          clients
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      map(({ isImmutable }) => {
        this.snackBar.open(
          `Successfully changed curve immutability to: ${isImmutable}`,
          'X',
          { duration: 2000 }
        )
        return fromActions.toggleCurveImmutabilitySuccess()
      })
    )
  )
}
