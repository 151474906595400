import { createAction, props } from '@ngrx/store'
import { QuoteChartResponse } from '../../model/quote-charts.model'
import { ApplicationError } from '../../../../error/model/error'

export const fetchAllQuoteChartsData = createAction(
  '[Quote Management Information] Fetch All Charts Data'
)
export const fetchAllQuoteChartsDataSuccess = createAction(
  '[Quote Management Information] Fetch All Charts Data - Success',
  props<{ charts: QuoteChartResponse[] }>()
)
export const fetchAllQuoteChartsDataFailure = createAction(
  '[Quote Management Information] Fetch All Charts Data - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchSingleQuoteChartDataSuccess = createAction(
  '[Quote Management Information] Fetch Single Chart Data - Success',
  props<{ chart: QuoteChartResponse }>()
)
export const fetchSingleQuoteChartDataFailure = createAction(
  '[Quote Management Information] Fetch Single Chart Data - Failure',
  props<{ error: ApplicationError }>()
)
