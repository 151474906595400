import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-layer-delete-dialog',
  templateUrl: './layer-delete-dialog.component.html',
  styleUrls: ['./layer-delete-dialog.component.scss']
})
export class LayerDeleteDialogComponent implements OnInit {

  isAcknowledged = false
  constructor(public dialogRef: MatDialogRef<LayerDeleteDialogComponent>) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.dialogRef.close({ event: 'confirm' })
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'cancel' })
  }
}
