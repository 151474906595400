import { inject, Injectable } from '@angular/core'
import { AppState } from '../../core/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from '../../api/util'
import { map } from 'rxjs'
import * as fromUserPreferencesActions from './user-preferences.actions'
import { UserPreferencesService } from '../../api/user-preferences/user-preferences.service'

@Injectable()
export class UserPreferencesEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: UserPreferencesService) {}

  fetchUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserPreferencesActions.fetchUserPreferences),
      concatMapWithInput(({ studyID }) =>
        this.service.getUserPreferences(studyID)
      ),
      rejectError(error =>
        this.store.dispatch(
          fromUserPreferencesActions.fetchUserPreferencesFailure({ error })
        )
      ),
      map(([result]) => {
        return fromUserPreferencesActions.fetchUserPreferencessSuccess({
          selectedUserPreferences: result,
        })
      })
    )
  )

  saveOrUpdateUserPreferences = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserPreferencesActions.saveOrUpdateUserPreferences),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.saveOrUpdateUserPreferences(
          props.selectedUserPreferences
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromUserPreferencesActions.saveOrUpdateUserPreferencesFailure({
            error,
          })
        )
      ),
      map(([data]) => {
        return fromUserPreferencesActions.saveOrUpdateUserPreferencesSuccess({
          selectedUserPreferences: data,
        })
      })
    )
  })
}
