import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[appHoverClass]',
})
export class HoverClassDirective {
  constructor(private el: ElementRef<HTMLElement>) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    this.el.nativeElement.classList.add('hovered')
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.el.nativeElement.classList.remove('hovered')
  }
}
