<div class="dialog-header">
  <h3>Select Carrier Permissions</h3>
  <button appButtonIcon big class="closeX clear" (click)="destroyDialog()">
    close
  </button>
</div>
<div class="input-wrapper">
  <app-multiselect-filter
    [form]="form"
    [filter]="formControl"
    [name]="'Organization'"
    [hideClearFilter]="true"
  ></app-multiselect-filter>
  <div class="button-wrapper">
    <button appButton primary border (click)="submitCarriers()">Confirm</button>
    <button appButton primary border (click)="destroyDialog()">Cancel</button>
  </div>
</div>
