import {
  AnalysisProfile,
  CurrencyCode,
} from '../../../api/analyzere/analyzere.model'
import { Program } from '../../model/program.model'
import { CurrencyRate } from '../../../analysis/tower/mechanics/tower.model'
import { Folder } from '../../model/study.model'

export interface BrokerState {
  currentClientID: string | null
  currentClientID2: string | null
  currentYearID: string | null
  currentYearID2: string | null
  currentStudyID: string | null
  currentStudyID2: string | null
  currentStructureID: string | null
  currentAnalysisProfile: AnalysisProfile | null
  structureFilter: string | null
  groupFilterByAssoc: boolean
  checkedPrograms: Program[]
  currencies: CurrencyCode[]
  currencyRates: CurrencyRate[]
  folders: Folder[]
}

export interface State {
  broker: BrokerState
  checkedProgram: Program[]
}

export const initialState: BrokerState = {
  currentClientID: null,
  currentClientID2: null,
  currentYearID: null,
  currentYearID2: null,
  currentStudyID: null,
  currentStudyID2: null,
  currentStructureID: null,
  currentAnalysisProfile: null,
  structureFilter: null,
  groupFilterByAssoc: true,
  checkedPrograms: [],
  currencies: [],
  currencyRates: [],
  folders: []
}
