import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SummaryChartState } from '../../store/explore.reducer'
import {
  SummaryChartDatum,
  SummaryChartGridOption,
  SummaryChartInfo,
} from '../summary-charts-model'
import { ExploreSummaryDatum, SummaryDataResponse } from '../../explore.model'
import { clone } from 'ramda'
import { currencySymbol } from 'src/app/analysis/model/layers.util'

@Component({
  selector: 'app-explore-gross-summary-charts',
  templateUrl: './explore-gross-summary-charts.component.html',
  styleUrls: ['./explore-gross-summary-charts.component.scss'],
})
export class ExploreGrossSummaryChartsComponent implements OnChanges, OnInit {
  @Input() groupSummaryData: SummaryDataResponse[]
  @Input() chartState: SummaryChartState
  @Input() chartsLoading: boolean
  @Input() currentStructureCurrency: string | undefined
  @Input() summaryRP: number[]
  roundedTo: string = 'Millions'
  get roundedToAbrev(): 'M' | 'K' {
    if (this.roundedTo === 'Millions') {
      return 'M'
    } else {
      return 'K'
    }
  }
  groupRows: ExploreSummaryDatum[]
  chartsEntityLimit: number
  chartOptions: SummaryChartGridOption[]
  selectableChartsForTab: SummaryChartInfo[]
  selectableChartsForMetric: SummaryChartInfo[]
  hideMetrics: boolean
  lightChartMode: boolean
  maximizedIndex: number | null
  method: 'agg' | 'occ' = 'agg'

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  ngOnInit(): void {
    this.maximizedIndex = null
    this.updateChartState()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartState || changes.groupSummaryData) {
      this.updateChartState()
    }
  }

  updateChartState(): void {
    this.chartsEntityLimit = this.chartState.chartsEntityLimit
    this.chartOptions = this.buildChartData(this.chartState.chartOptions)
    this.selectableChartsForTab = this.chartState.selectableChartsForTab
    this.selectableChartsForMetric = this.chartState.selectableChartsForMetric
    this.hideMetrics = this.chartState.hideMetrics
    this.lightChartMode = this.chartState.lightChartMode
  }

  buildChartData(
    chartOptions: SummaryChartGridOption[]
  ): SummaryChartGridOption[] {
    const options = clone(chartOptions)
    const levelCount = Math.max(
      ...this.groupSummaryData.map(d => this.getLevelCount(d.lossName))
    )
    for (const option of options) {
      if (
        option.metric.columnName === 'contributionToModeledGroupVolatility' ||
        option.metric.columnName === 'contributionToOverallVolatility'
      ) {
        const data: SummaryChartDatum[] = []
        for (const row of this.groupSummaryData) {
          if (this.getLevelCount(row.lossName) === levelCount) {
            const lossName = row.lossName.replace(/_/g, ' ')
            data.push({
              groupBy: lossName,
              metricPrimary: row.contributionToGroupVolatility,
            })
          }
          // data.push({
          //   groupBy: `${lossName} Large`,
          //   metricPrimary: row.contributionToGroupVolatilityLarge
          // })
          // data.push({
          //   groupBy: `${lossName} Cat`,
          //   metricPrimary: row.contributionToGroupVolatilityCat
          // })
          // data.push({
          //   groupBy: `${lossName} Attritional`,
          //   metricPrimary: row.contributionToGroupVolatilityAttr
          // })
        }
        option.data = {
          data,
        }
        option.metric.format = 'percent'
      } else if (option.metric.columnName === 'grossCombinedRatioSummary') {
        const data: SummaryChartDatum[] = []
        for (const row of this.groupSummaryData) {
          const lossName = row.lossName.replace(/_/g, ' ')
          const premium = row.subjectPremiumAmt ?? 0
          const lossAttr = row.expectedLossAttr ?? 0
          const lossLarge = row.expectedLossLarge ?? 0
          const lossCat = row.expectedLossCat ?? 0
          const expense = row.expense ?? 0
          const metrics = {
            metricPrimary: this.calculateGrossCombinedRatio(lossAttr, premium),
            metricSecondary: this.calculateGrossCombinedRatio(
              lossLarge,
              premium
            ),
            metricTertiary: this.calculateGrossCombinedRatio(lossCat, premium),
            metricQuaternary: this.calculateGrossCombinedRatio(
              expense,
              premium
            ),
          }
          data.push({
            groupBy: lossName,
            ...metrics,
          })
        }
        const splitNames = {
          metricPrimaryName: 'Attritional Loss',
          metricSecondaryName: 'Large Loss',
          metricTertiaryName: 'Cat Loss',
          metricQuaternaryName: 'Expense',
        }
        option.data = {
          data,
        }
        option.split = {
          ...option.split,
          ...splitNames,
        }
        option.metric = {
          ...option.metric,
          ...splitNames,
        }
      } else if (option.metric.columnName === 'writtenPremiumByProduct') {
        const data: SummaryChartDatum[] = []
        let total = 0
        for (const row of this.groupSummaryData) {
          if (this.getLevelCount(row.lossName) === levelCount) {
            const lossName = row.lossName.replace(/_/g, ' ')
            total += row.subjectPremiumAmt
            data.push({
              groupBy: lossName,
              metricPrimary: row.subjectPremiumAmt,
            })
          }
        }
        const name = `${this.currencyFormat(total)} Written Premium by Product (${this.roundedToAbrev})`
        option.metric = {
          ...option.metric,
          name,
        }
        option.data = {
          data,
        }
      } else if (
        option.metric.columnName === 'compositionofLossCurveByReturnPeriod'
      ) {
        const data: SummaryChartDatum[] = []
        let rawDatum: number[] = []
        const filteredRows = this.groupSummaryData.filter(row => {
          const onLevel = this.getLevelCount(row.lossName) === levelCount
          let rp = row.largeRiskDistributionOEP?.data
            ?.map(d => d?.min || 0)
            .slice(0, this.summaryRP.length)
          if (this.method === 'agg') {
            rp = row.largeRiskDistributionAEP?.data
              ?.map(d => d?.min || 0)
              .slice(0, this.summaryRP.length)
          }
          return onLevel && rp[0] !== 0
        })
        const totalsArray = this.summaryRP.map((_, i) => {
          return filteredRows
            .map(row => {
              if (this.getLevelCount(row.lossName) === levelCount) {
                let rp = row.largeRiskDistributionOEP?.data
                  ?.map(d => d?.min || 0)
                  .slice(0, this.summaryRP.length)
                if (this.method === 'agg') {
                  rp = row.largeRiskDistributionAEP?.data
                    ?.map(d => d?.min || 0)
                    .slice(0, this.summaryRP.length)
                }
                return rp[i]
              }
            })
            .reduce((a, c) => a + c, 0)
        })
        for (const row of filteredRows) {
          if (this.getLevelCount(row.lossName) === levelCount) {
            if (this.method === 'agg') {
              rawDatum = row.largeRiskDistributionAEP?.data
                ?.map(d => d?.min || 0)
                .slice(0, this.summaryRP.length)
            } else {
              rawDatum = row.largeRiskDistributionOEP?.data
                ?.map(d => d?.min || 0)
                .slice(0, this.summaryRP.length)
            }
            const values = this.summaryRP.map((rp, i) => {
              let y = 0
              if (rawDatum[i] !== 0 && totalsArray[i] !== 0) {
                y = (rawDatum[i] / totalsArray[i]) * 100
              }
              return {
                x: rp,
                y,
                raw: this.currencyFormat(rawDatum[i], true),
              }
            })
            const metricPrimary = values.reduce((a, c) => a + c.y, 0)
            const lossName = row.lossName.replace(/_/g, ' ')
            data.push({
              groupBy: lossName,
              metricPrimary,
              values,
            })
          }
        }
        option.data = {
          data,
        }
        option.metric.format = 'percent'
        option.vertSplits = this.summaryRP.map(d => String(d))
      }
    }
    return options
  }

  calculateGrossCombinedRatio(value: number, premium: number): number {
    if (value === 0 || premium === 0) {
      return 0
    } else {
      return value / premium
    }
  }

  getLevelCount(name: string): number {
    return (name.match(/_/g) || []).length
  }

  currencyFormat(value: number, showAbrev?: boolean): string {
    const factor = this.roundedToAbrev === 'M' ? 1e6 : 1e3
    const roundedValue = Math.round(value / factor)
    const formattedValue = Number(roundedValue).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })
    const abrev = showAbrev ? this.roundedToAbrev : ''
    return `${currencySymbol(this.currentStructureCurrency)}${formattedValue}${abrev}`
  }

  onMaximizeClick(chartIndex: number) {
    if (this.maximizedIndex === null) {
      this.maximizedIndex = chartIndex
    } else {
      this.maximizedIndex = null
    }
  }
}
