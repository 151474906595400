<ng-container *ngIf="!error; else errorTemplate">
  <ng-container *ngIf="!saving; else loadingTemplate">
    <ng-container *ngIf="lastCreated > 0; else noSummaryTemplate">
      <h3>Candidates created successfully</h3>
      <div>
        <label>Number of layers:</label>
        <span>{{ totalLayers }}</span>
      </div>
      <div>
        <label>Number of selected candidates:</label>
        <span>{{ totalSelectedCandidates }}</span>
      </div>
      <div>
        <label>Total structures created:</label>
        <span>{{ lastCreated }}</span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #errorTemplate>
  <label appInfoText class="error" warn big>{{ error }}</label>
</ng-template>
<ng-template #loadingTemplate>
  <label appInfoText big class="loading">Loading...</label>
</ng-template>
<ng-template #noSummaryTemplate>
  <label appInfoText big class="loading">No Summary</label>
</ng-template>
