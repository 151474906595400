import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  InuranceSourceTargetView,
  InuranceView,
  Terminal,
} from '../../../model/inurance.model'
import { GrouperInuranceMode } from '../../../store/grouper/inurance/grouper-inurance.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-inurance-bar',
  styleUrls: ['./group-inurance-bar.component.scss'],
  templateUrl: './group-inurance-bar.component.html',
})
export class GroupInuranceBarComponent {
  @Input() dirty = false
  @Input() mode: GrouperInuranceMode
  @Input() nextSymbol: string
  @Input() source: InuranceView | null
  @Input() target: InuranceView | null
  @Input() cursor: Terminal | null
  @Input() error: string | null = null
  @Input() activeAction: string | null = null

  @Output() openNewClick = new EventEmitter<void>()
  @Output() cancelClick = new EventEmitter<void>()
  @Output() terminalClick = new EventEmitter<Terminal>()
  @Output() addClick = new EventEmitter<InuranceSourceTargetView>()
  @Output() deleteClick = new EventEmitter<InuranceSourceTargetView>()

  get open(): boolean {
    return this.mode !== 'none'
  }

  get symbol(): string | number {
    if (this.source) {
      return this.source.symbol
    }
    if (this.target) {
      return this.target.symbol
    }
    return this.nextSymbol
  }

  get actionDisabled(): boolean {
    return !this.source || !this.target
  }

  inuranceDisplayHelper(
    source: InuranceView | null,
    target: InuranceView | null
  ): string | undefined {
    if (source && target) {
      const left = this.findInuranceType(source)
      const right = this.findInuranceType(target)
      return `${left} To ${right}`
    }
  }

  findInuranceType(value: InuranceView): string {
    let response = ''
    if (value.layerID) {
      response = 'Layer'
    } else if (value.programID) {
      response = 'Structure'
    } else if (value.programGroupID) {
      response = 'Structure Group'
    }
    return response
  }

  saveClick(): void {
    if (!this.actionDisabled) {
      this.addClick.emit({ source: this.source, target: this.target })
    }
  }
}
