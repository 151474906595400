import { layerIds } from '../analysis/model/layer-palette.model'
import { AssignedLines, Section } from './models/quote.model'
import { Bureaus } from 'src/app/api/model/quote.model'
import { ReinsurerState } from './store/reinsurer/reinsurer.reducer'
import { Layer } from '../analysis/model/layers.model'

interface DefaultBrokerages {
  brokerageCommission: number
  reinstatementBrokerage: number
}

export const getDefaultBrokerage = (
  isUsaBasedUser: boolean,
  layerId?: string
): DefaultBrokerages => {
  if (isUsaBasedUser) {
    if (layerId === layerIds.catQs) {
      return {
        brokerageCommission: 0.015,
        reinstatementBrokerage: 0,
      }
    } else if (layerId === layerIds.noncatQs) {
      return {
        brokerageCommission: 0.025,
        reinstatementBrokerage: 0,
      }
    }
    return {
      brokerageCommission: 0.1,
      reinstatementBrokerage: 0.05,
    }
  } else {
    return {
      brokerageCommission: 0,
      reinstatementBrokerage: 0,
    }
  }
}

export function isLineSplitLine(
  line: AssignedLines,
  allLines: AssignedLines[]
): boolean {
  return allLines.some(
    l =>
      l !== line &&
      l.whiteSpaceImpressionIdx === line.whiteSpaceImpressionIdx &&
      l.whiteSpaceWSetID === line.whiteSpaceWSetID
  )
}

export function getAssignedLineBureausStamp(
  newAssignedLine: AssignedLines,
  bureausList: Bureaus[]
): string {
  const matchingBureaus = bureausList.filter(
    b => b.tpRef === newAssignedLine.marketTpRef
  )

  let bureau_type = ''
  let bureaus = ''
  if (matchingBureaus.length === 1) {
    bureau_type = matchingBureaus[0].bureau_type
    bureaus = matchingBureaus[0].bureau_stamp
  }

  // only return the stamp for Lloyd's bureaus
  if (newAssignedLine.bureaus && bureau_type === 'LL') {
    return newAssignedLine.bureaus
  }

  return bureaus && bureau_type === 'LL' ? bureaus : 'NA'
}

export function hasOffMarketLines(reinsurer: ReinsurerState): boolean {
  return !!(
    reinsurer.reinsurer.riskAssignedLinesLink &&
    reinsurer.reinsurer.riskAssignedLinesLink?.length > 0 &&
    reinsurer.reinsurer.riskAssignedLinesLink?.some(row => !row.marketTpRef)
  )
}

// Will build a default section from either an existing section or a layer
export function getSectionDataByType(
  layer: Layer,
  layerType: string,
  section?: Section,
  programGroupID?: string | null,
  isGroup?: boolean,
  programID?: string,
  structureID?: string
): Section {
  let newSection: Section = {
    id: section?.id ?? '',
    layerRef: section?.layerRef ?? layer.id,
    structureID: parseInt(
      String(
        structureID ??
          section?.structureID ??
          layer.meta_data.structureID ??
          '0'
      ),
      10
    ),
    layerName:
      section?.layerName ??
      layer.meta_data.layerName ??
      layer.physicalLayer.description ??
      '',
    layerSubType:
      section?.layerSubType ?? layer.meta_data.sage_layer_subtype ?? '',
    layerCurrency:
      section?.layerCurrency ?? layer.physicalLayer.franchise.currency,
    layerType,
    occurrenceLimitToggle: true,
    occurrenceAttachmentToggle: false,
    riskLimitToggle: false,
    riskAttachmentToggle: false,
    franchiseDeductibleToggle: false,
    reinstatementsToggle: false,
    aggregateLimitToggle: false,
    aggregateAttachmentToggle: false,
    cedingCommissionToggle: false,
    reinsurerExpenseProvisionToggle: false,
    profitCommissionToggle: false,
    rolPercentageToggle: false,
    rateOnLineSubjectToggle: false,
    pmpmToggle: false,
    premiumToggle: false,
    effectiveDateToggle: false,
    expirationDateToggle: false,
    expiryDateToggle: false,
    payoutToggle: false,
    triggerToggle: false,
    nthToggle: false,
    underwriterToggle: false,
    maolLimitToggle: false,
    terrorismAggSubLimitToggle: false,
    lossRatioCapToggle: false,
    lossRatioCapPercentageToggle: false,
    lossCapApplicationToggle: false,
    limitApplicationToggle: false,
    laeCapToggle: false,
    laeTreatmentToggle: false,
    adjustmentBasisToggle: false,
    ecoCoveragePctToggle: false,
    xplCoveragePctToggle: false,
    subjectPremiumToggle: true,
    minimumPremiumPercentageToggle: false,
    depositPremiumPercentageToggle: false,
    minimumPremiumToggle: false,
    clashPremiumToggle: false,
    profitShareCommissionToggle: false,
    profitShareMinRateToggle: false,
    profitShareMaxRateToggle: false,
    brokerageCommissionToggle: true,
    brokerageTypeToggle: true,
    brokerageRIPCommissionToggle: false,
    orderPercentToggle: false,
    otherFeaturesToggle: false,
    coverageBasisToggle: false,
    indexationtextToggle: false,
    quoteMinPercentageToggle: false,
    quoteOfferedPercentageToggle: true,
    quoteOfferedLimitToggle: false,
    xplEcoDropdownToggle: false,
    xplEcoConditionsToggle: false,
    quoteDepositPremiumToggle: true,
    quoteMinRateSubjectToggle: false,
    quoteMaxRateSubjectToggle: false,
    quoteMinRatePmpmToggle: false,
    quoteMaxRatePmpmToggle: false,
    quoteSwingRateToggle: false,
    quoteSwingBasisToggle: false,
    quoteIndexationToggle: false,
    quoteFixedIndexValueToggle: false,
    quoteSicOrFranchiseToggle: false,
    quoteExpectedCededLossToggle: true,
    quoteLossOnLineToggle: true,
    quoteExpectedCededPremiumToggle: true,
    quoteDepositPremiumCalcToggle: true,
    slidingCommToggle: false,
    quoteCessionsBasedPremiumToggle: false,
    structureFXToggle: false,
    premiumFXToggle: false,
    territorialScopeToggle: false,
    vendorToggle: false,
    modelVersionToggle: false,
    feeOrBrokerageToggle: false,
    layerCategoryToggle: false,
    layerClassToggle: false,
    excludeFromPricingCurveToggle: false,
    perilsToggle: false,
    lossImpactedFromPreviousYearToggle: false,
    quoteProbabilityOfAttachToggle: false,
    quoteProbabilityOfExhaustToggle: false,
    quoteTopOccurrenceAttachmentToggle: false,
    quoteTopOccurrenceLimitToggle: false,
    quoteDropOccurrenceAttachmentToggle: false,
    quoteDropOccurrenceLimitToggle: false,
    quoteAggregateLimitTopToggle: false,
    quoteAggregateLimitDropToggle: false,
    cedingCommissionBasisToggle: false,
    feeToggle: false,
    rebateToggle: false,
    quoteIndexToggle: false,
  }

  if (
    layerType === layerIds.catXl ||
    layerType === layerIds.noncatXl ||
    layerType === layerIds.ahlXl ||
    layerType === layerIds.catFhcf
  ) {
    newSection = {
      ...newSection,
      occurrenceAttachmentToggle: true,
      reinstatementsToggle: true,
      premiumToggle: true,
      minimumPremiumPercentageToggle: true,
      depositPremiumPercentageToggle: true,
      minimumPremiumToggle: true,
      brokerageRIPCommissionToggle: true,
      slidingCommToggle: false,
    }
  }
  if (
    layerType === layerIds.catQs ||
    layerType === layerIds.noncatQs ||
    layerType === layerIds.ahlQs
  ) {
    newSection = {
      ...newSection,
      riskLimitToggle: true,
      subjectPremiumToggle: true,
      occurrenceLimitToggle: true,
      cedingCommissionToggle: true,
      lossRatioCapToggle: true,
      lossRatioCapPercentageToggle: true,
      lossCapApplicationToggle: true,
      quoteOfferedPercentageToggle: true,
      limitApplicationToggle: true,
      brokerageCommissionToggle: true,
      brokerageTypeToggle: true,
      slidingCommToggle: false,
      premiumToggle: true,
    }
  }

  if (layerType === layerIds.catCa) {
    newSection = {
      ...newSection,
      occurrenceAttachmentToggle: true,
      franchiseDeductibleToggle: true,
      reinstatementsToggle: true,
      premiumToggle: true,
      minimumPremiumPercentageToggle: true,
      depositPremiumPercentageToggle: true,
      minimumPremiumToggle: true,
      brokerageRIPCommissionToggle: true,
      // quoteSignedPercentageToggle: false,
      slidingCommToggle: false,
    }
  }

  if (
    layerType === layerIds.catAg ||
    layerType === layerIds.noncatAg ||
    layerType === layerIds.ahlAg ||
    layerType === layerIds.catTd ||
    layerType === layerIds.drop
  ) {
    newSection = {
      ...newSection,
      occurrenceAttachmentToggle: true,
      aggregateLimitToggle: true,
      aggregateAttachmentToggle: true,
      reinstatementsToggle: true,
      premiumToggle: true,
      minimumPremiumPercentageToggle: true,
      depositPremiumPercentageToggle: true,
      minimumPremiumToggle: true,
      brokerageRIPCommissionToggle: true,
      slidingCommToggle: false,
    }
  }

  if (layerType === layerIds.noncatRisk) {
    newSection = {
      ...newSection,
      riskLimitToggle: true,
      riskAttachmentToggle: true,
      reinstatementsToggle: true,
      premiumToggle: true,
      minimumPremiumPercentageToggle: true,
      depositPremiumPercentageToggle: true,
      minimumPremiumToggle: true,
      brokerageRIPCommissionToggle: true,
      slidingCommToggle: false,
    }
  }

  if (
    layerType === layerIds.noncatXl ||
    layerType === layerIds.noncatIndxl ||
    layerType === layerIds.noncatAg ||
    layerType === layerIds.noncatRisk
  ) {
    newSection = {
      ...newSection,
      rolPercentageToggle: false,
      rateOnLineSubjectToggle: true,
    }
  }

  if (
    layerType === layerIds.ahlAg ||
    layerType === layerIds.ahlXl ||
    layerType === layerIds.ahlQs
  ) {
    newSection = {
      ...newSection,
      rolPercentageToggle: false,
      rateOnLineSubjectToggle: false,
      pmpmToggle: true,
    }
  }

  if (
    layerType === layerIds.catXl ||
    layerType === layerIds.catCa ||
    layerType === layerIds.catFhcf ||
    layerType === layerIds.catTd ||
    layerType === layerIds.catAg
  ) {
    newSection = {
      ...newSection,
      rolPercentageToggle: true,
      rateOnLineSubjectToggle: false,
    }
  }

  if (layerType === layerIds.catTd || layerType === layerIds.drop) {
    newSection = {
      ...newSection,
      quoteTopOccurrenceLimitToggle: true,
      quoteTopOccurrenceAttachmentToggle: true,
      quoteDropOccurrenceLimitToggle: true,
      quoteDropOccurrenceAttachmentToggle: true,
      quoteAggregateLimitTopToggle: true,
      quoteAggregateLimitDropToggle: true,
      quoteOfferedPercentageToggle: true,
      brokerageRIPCommissionToggle: true,
      quoteDepositPremiumToggle: true,
      depositPremiumPercentageToggle: true,
      quoteExpectedCededLossToggle: false,
      quoteLossOnLineToggle: false,
      quoteExpectedCededPremiumToggle: false,
    }
  }

  if (layerType === layerIds.noncatSwing || layerType === layerIds.ahlSwing) {
    newSection = {
      ...newSection,
      subjectPremiumToggle: true,
      occurrenceLimitToggle: true,
      occurrenceAttachmentToggle: true,
      aggregateLimitToggle: true,
      aggregateAttachmentToggle: true,

      quoteSwingRateToggle: true,
      quoteSwingBasisToggle: true,
      quoteOfferedPercentageToggle: true,
      brokerageCommissionToggle: true,
      brokerageRIPCommissionToggle: true,
    }
    if (layerType === layerIds.noncatSwing) {
      newSection = {
        ...newSection,
        quoteMinRateSubjectToggle: true,
        quoteMaxRateSubjectToggle: true,
      }
    } else {
      newSection = {
        ...newSection,
        quoteMinRatePmpmToggle: true,
        quoteMaxRatePmpmToggle: true,
      }
    }
  }

  if (layerType === layerIds.noncatIndxl) {
    newSection = {
      ...newSection,
      subjectPremiumToggle: true,
      occurrenceLimitToggle: true,
      occurrenceAttachmentToggle: true,
      quoteIndexationToggle: true,
      quoteFixedIndexValueToggle: true,
      quoteSicOrFranchiseToggle: true,
      rateOnLineSubjectToggle: true,
      premiumToggle: true,
      reinstatementsToggle: true,
      minimumPremiumPercentageToggle: true,
      depositPremiumPercentageToggle: true,
      minimumPremiumToggle: true,
      quoteOfferedPercentageToggle: true,
      brokerageCommissionToggle: true,
      brokerageRIPCommissionToggle: true,
    }
  }

  if (
    layerType === layerIds.catMultisection ||
    layerType === layerIds.noncatMultisection
  ) {
    if (
      section?.layerSubType === 'section-layer' ||
      layer.meta_data.sage_layer_subtype !== 'section-layer'
    ) {
      newSection = {
        ...newSection,
        occurrenceAttachmentToggle: false,
        rateOnLineSubjectToggle: true,
        premiumToggle: true,
        minimumPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        reinstatementsToggle: true,
        brokerageCommissionToggle: true,
        brokerageRIPCommissionToggle: true,
        aggregateLimitToggle: true,
        aggregateAttachmentToggle: true,
        rolPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        layerClassToggle: true,
        quoteExpectedCededLossToggle: false,
        quoteLossOnLineToggle: false,
        quoteExpectedCededPremiumToggle: false,
        quoteOfferedPercentageToggle: false,
      }
    } else {
      newSection = {
        ...newSection,
        occurrenceAttachmentToggle: true,
        aggregateLimitToggle: true,
        aggregateAttachmentToggle: true,
        subjectPremiumToggle: false,
        quoteDepositPremiumToggle: false,
        quoteExpectedCededLossToggle: false,
        quoteLossOnLineToggle: false,
        brokerageCommissionToggle: false,
        quoteExpectedCededPremiumToggle: false,
        quoteDepositPremiumCalcToggle: false,
        reinstatementsToggle: false,
        brokerageTypeToggle: false,
        layerClassToggle: false,
      }
    }
  }

  if (layerType === layerIds.ilwBin) {
    newSection = {
      ...newSection,
      subjectPremiumToggle: false,
      occurrenceLimitToggle: false,
      payoutToggle: true,
      triggerToggle: true,
      nthToggle: true,
      rolPercentageToggle: true,
      premiumToggle: true,
      quoteOfferedPercentageToggle: false,
      brokerageCommissionToggle: true,
      brokerageRIPCommissionToggle: true,
      effectiveDateToggle: true,
      expirationDateToggle: true,
      territorialScopeToggle: true,
      perilsToggle: true,
      quoteDepositPremiumToggle: false,
    }
  }

  if (layerType === layerIds.ilwProRata) {
    newSection = {
      ...newSection,
      subjectPremiumToggle: false,
      occurrenceLimitToggle: true,
      payoutToggle: true,
      triggerToggle: true,
      rolPercentageToggle: true,
      premiumToggle: true,
      reinstatementsToggle: true,
      aggregateAttachmentToggle: true,
      aggregateLimitToggle: true,
      effectiveDateToggle: true,
      expirationDateToggle: true,
      quoteOfferedPercentageToggle: false,
      brokerageCommissionToggle: true,
      brokerageRIPCommissionToggle: true,
      territorialScopeToggle: true,
      perilsToggle: true,
      quoteDepositPremiumToggle: false,
    }
  }

  if (layerType === layerIds.ilwAg) {
    section = {
      ...section,
      payoutToggle: true,
      effectiveDateToggle: true,
      expirationDateToggle: true,
    }
  }

  if (isGroup && programID) {
    section = {
      ...section,
      programID: parseInt(programID, 10),
    }
  }

  if (isGroup && programGroupID) {
    section = {
      ...section,
      programGroupID: parseInt(programGroupID, 10),
    }
  } else if (!isGroup && programGroupID) {
    section = {
      ...section,
      sharedLimitID: parseInt(programGroupID, 10),
    }
  }

  return newSection
}
