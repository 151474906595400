import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import {
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { rejectError } from '../../../../api/util'
import { AppState } from '../../../../core/store'
import {
  extractPortfolioSetID,
  portfolioSetIDsEqual,
} from '../../../model/portfolio-set-id.util'
import { PortfolioSetID } from '../../../model/portfolio-set.model'
import {
  selectCompareEntities,
  selectCompareMetricSettingsEntities,
  selectCompareMetricSettingsStudyID,
  selectPortfolioSetsWithIDs,
  selectMetricTableSettingsStudyID,
  selectMetricTableSettingsEntities,
  selectCustomCompareMetricSettings,
  selectCompareEntitiesByID,
  selectCompareConversion,
} from '../../analysis.selectors'
import * as CompareActions from '../compare.actions'
import * as SettingsActions from './compare-metric-settings.actions'
import {
  createAllCompareMetricCategoryValues,
  updateRanks,
} from './create-compare-metric-values'
import { MetricSettingsService } from 'src/app/api/metric-settings/metric-settings.service'
import { formatMetricSettingsFetch } from '../../../../api/metric-settings/metric-settings.converter'
import { fetchEfficiencyMetricsSuccess } from '../../metrics/efficiency-metrics.actions'
import {
  CompareMetricCategory,
  CompareMetricValue,
} from 'src/app/analysis/model/compare-metrics.model'
import { selectCompareCurrency } from '../../analysis.selectors'

// tslint:disable: no-non-null-assertion
@Injectable()
export class CompareMetricSettingsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private metricSettingsService: MetricSettingsService
  ) {}

  fetchSettingsOnFirstAddProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompareActions.addProgramToCompareSuccess),
      map(action => action.id),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCompareEntities)),
            this.store.pipe(select(selectCompareMetricSettingsStudyID))
          )
        )
      ),
      map(([id, entities, studyID]) => [id, entities[0], studyID] as const),
      filter(([id, entity]) => entity != null && entity.program.id === id),
      // Get the newly added program entity's study ID
      map(([_, entity, studyID]) => {
        return [entity.program.studyID, studyID] as const
      }),
      // Continue if study ID is null (i.e. metric settings were never fetched)
      // or if the newly added program has a different study ID
      filter(([nextID, prevID]) => !prevID || nextID !== prevID),
      map(([studyID]) =>
        SettingsActions.fetchCompareMetricSettings({ studyID })
      )
    )
  )

  fetchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.fetchCompareMetricSettings),
      map(action => action.studyID),
      switchMap(studyID =>
        this.metricSettingsService.getMetricSettings(studyID)
      ),
      rejectError(error =>
        this.store.dispatch(
          SettingsActions.fetchCompareMetricSettingsFailure({ error })
        )
      ),
      map(res => formatMetricSettingsFetch(res)),
      map(settings =>
        SettingsActions.fetchCompareMetricSettingsSuccess({ settings })
      )
    )
  )

  fetchValuesOnProgramAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchEfficiencyMetricsSuccess),
      map(extractPortfolioSetID),
      filter(id => id != null),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCompareEntities)),
            this.store.pipe(select(selectCompareMetricSettingsEntities))
          )
        )
      ),
      filter(([_, _e, settings]) => settings.length > 0),
      map(([portfolioSetID, entities]) =>
        entities.find(e => portfolioSetIDsEqual(portfolioSetID, e.program))
      ),
      filter(entity => entity != null),
      // tslint:disable-next-line: no-non-null-assertion
      map(entity => parseInt(entity!.program.id, 10)),
      map(programID => SettingsActions.fetchCompareMetricValues({ programID }))
    )
  )

  fetchValuesOnSettingsLoadOrRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SettingsActions.fetchCompareMetricSettingsSuccess,
        CompareActions.removeProgramFromCompare,
        CompareActions.removeCompareProgramGroup
      ),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(this.store.pipe(select(selectCompareEntities)))
        )
      ),
      concatMap(([_, entities]) => entities),
      map(entity => parseInt(entity.program.id, 10)),
      map(programID => SettingsActions.fetchCompareMetricValues({ programID }))
    )
  )

  fetchValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.fetchCompareMetricValues),
      debounceTime(100),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCompareMetricSettingsEntities)),
            this.store.pipe(select(selectCompareEntities))
          )
        )
      ),
      map(([_, settings, entities]) => {
        const ids = entities.map(e => e.program.id)
        const currencies = entities.map(e =>
          e.program.structureCurrency ? e.program.structureCurrency : 'USD'
        )
        const portfolioSetIDs = entities
          .map(e => extractPortfolioSetID(e.program))
          .filter(id => id != null) as PortfolioSetID[]
        return { settings, ids, currencies, portfolioSetIDs }
      }),
      concatMap(res =>
        of(res).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectPortfolioSetsWithIDs, {
                portfolioSetIDs: res.portfolioSetIDs,
              })
            ),
            this.store.pipe(select(selectCompareCurrency)),
            this.store.pipe(select(selectCompareConversion))
          )
        )
      ),
      concatMap(
        ([
          { settings, ids, currencies },
          portfolioSets,
          compareCurrency,
          conversion,
        ]) => {
          let currency = null
          if (compareCurrency && compareCurrency !== 'Default') {
            currency = compareCurrency
          } else {
            currency = currencies
          }

          return createAllCompareMetricCategoryValues(
            ids,
            portfolioSets,
            settings,
            currency,
            conversion
          )
        }
      ),
      map(([programID, categories]) =>
        SettingsActions.fetchCompareMetricValuesSuccess({
          programID,
          categories,
        })
      )
    )
  )

  // On Fetch Values Success, calculate and populate Custom Values
  fetchCustomValues$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.fetchCompareMetricValuesSuccess),
        withLatestFrom(
          this.store.pipe(select(selectCustomCompareMetricSettings)),
          this.store.pipe(select(selectCompareEntitiesByID))
        ),
        map(([action, customSettings, compareEntities]) => {
          const nonCustomCategories = compareEntities[
            action.programID
          ]?.metricCategories.filter(m => m.category !== 'Custom Metrics')!

          const customCategory = compareEntities[
            action.programID
          ]?.metricCategories.find(m => m.category === 'Custom Metrics')!

          const updatedCategories: CompareMetricCategory[] = []
          const updatedMetric2DArray: CompareMetricValue[][] = []

          // Loop through each formula and replace metric name with value from that metric
          customSettings.forEach(setting => {
            let formula = setting.formula as string
            // Loop through categories, then through metrics
            nonCustomCategories?.forEach(category => {
              if (formula.includes(category.category)) {
                category.metrics.forEach(m => {
                  // If we find the metric matching the label in the formula, replace it with the value
                  if (formula.includes(m[0].label)) {
                    formula = formula.replace(
                      `[${category.category}:${m[0].label}]`,
                      `(${String(m[0].value ?? 0)})`
                    )
                  }
                })
              }
            })
            let newValue
            try {
              newValue = Function('"use strict";return (' + formula + ')')()
            } catch (e) {
              newValue = undefined
            }

            // Find metric to update
            const currentCustomMetric = customCategory.metrics.find(
              m => m[0].label === setting.label
            )!
            const updatedMetric = {
              ...currentCustomMetric[0],
              value: Number(newValue),
              validFormula: setting.validFormula,
            } as CompareMetricValue

            const updatedMetricArray: CompareMetricValue[] = []
            updatedMetricArray.push(updatedMetric)
            updatedMetric2DArray.push(updatedMetricArray)
          })

          const updatedCustomCategory = {
            ...customCategory,
            metrics: updatedMetric2DArray,
          }
          updatedCategories.push(updatedCustomCategory, ...nonCustomCategories)

          if (customCategory) {
            this.store.dispatch(
              SettingsActions.fetchCustomCompareMetricValues({
                programID: action.programID,
                categories: updatedCategories,
              })
            )
          }
        })
      ),
    { dispatch: false }
  )

  updateCustomRanks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.fetchCustomCompareMetricValues),
        withLatestFrom(this.store.pipe(select(selectCompareEntities))),
        map(([_, compareEntities]) => {
          const customMetrics = compareEntities.map(e => {
            const customCategory = e.metricCategories.find(
              c => c.category === 'Custom Metrics'
            )
            return customCategory?.metrics.map(m => m[0]) || []
          })

          // Update ranks, passing all entities into argument
          const metricsWithRank = updateRanks(customMetrics)

          // Now pull out the metrics for each entity and update ranks
          compareEntities.forEach((e, i) => {
            const updatedCategories: CompareMetricCategory[] = []
            const updatedMetric2DArray: CompareMetricValue[][] = []
            metricsWithRank.forEach((m, k) => {
              if (k === i) {
                m.map(metric => {
                  const updatedMetricArray: CompareMetricValue[] = []
                  updatedMetricArray.push(metric)
                  updatedMetric2DArray.push(updatedMetricArray)
                })
              }
            })

            const customCategory = e.metricCategories.find(
              m => m.category === 'Custom Metrics'
            )!
            const nonCustomCategories = e.metricCategories.filter(
              m => m.category !== 'Custom Metrics'
            )!

            const updatedCustomCategory = {
              ...customCategory,
              metrics: updatedMetric2DArray,
            }
            updatedCategories.push(
              updatedCustomCategory,
              ...nonCustomCategories
            )

            this.store.dispatch(
              SettingsActions.updateCustomRanks({
                programID: parseInt(e.program.id, 10),
                categories: updatedCategories,
              })
            )
          })
        })
      ),
    { dispatch: false }
  )

  updateCompareMetric$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.upsertCompareMetricSettings),
      concatMap(res =>
        of(res).pipe(
          withLatestFrom(
            this.store.pipe(select(selectMetricTableSettingsStudyID)),
            this.store.pipe(select(selectCompareMetricSettingsStudyID))
          )
        )
      ),
      filter(
        ([_, metricStudyID, compareStudyID]) => metricStudyID === compareStudyID
      ),
      concatMap(res =>
        of(res).pipe(
          withLatestFrom(
            this.store.pipe(select(selectMetricTableSettingsEntities))
          )
        )
      ),
      concatMap(([_, settings]) => [
        SettingsActions.updateCompareMetricSettings({ settings }),
        SettingsActions.fetchCompareMetricSettingsSuccess({ settings }),
      ])
    )
  )
}
