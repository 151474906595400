import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { AssignedLines, ReinsurerPhases } from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import { LLOYDS_SYNDICATE_PREFIX } from 'src/app/core/model/reinsurer.model'
import { getAssignedLineBureausStamp, hasOffMarketLines } from '../quote.util'
import { Bureaus } from 'src/app/api/model/quote.model'

@Component({
  selector: 'app-set-off-market-reinsurer-dialog',
  styleUrls: ['./set-off-market-reinsurer-dialog.component.scss'],
  templateUrl: './set-off-market-reinsurer-dialog.component.html',
})
export class SetOffMarketReinsurerDialogComponent implements OnInit {
  columnDefs: SortTableColumnDef[] = [
    {
      id: 'reinsurer',
      label: 'Reinsurer',
      valueType: 'dropdown',
      editable: true,
      bodyStyle: { width: '35%' },
      references: this.data.reinsurerOptions.map(reinsurer => ({
        value: reinsurer,
        viewValue: reinsurer,
      })),
    },
    {
      id: 'whiteSpaceBusinessUnit',
      label: 'Whitespace Business Unit',
      bodyStyle: { width: '35%' },
      valueType: 'text',
      editable: false,
    },
    {
      id: 'underwriterRef',
      label: 'Underwriter Ref #',
      bodyStyle: { width: '15%' },
      valueType: 'text',
      editable: false,
    },
    {
      id: 'written',
      label: 'Written %',
      bodyStyle: { width: '15%' },
      valueType: 'percentage',
      decimals: 4,
      whenLoading: 'show',
      editable: false,
    }
  ]


  modifiedReinsurers: ReinsurerState[] = []
  layerNames: {[key: string]: string} = {}

  constructor(
    private dialogRef: MatDialogRef<SetOffMarketReinsurerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reinsurerOptions: string[]
      reinsurers: ReinsurerState[]
      sectionList: SectionState[]
      bureaus: Bureaus[]
    }
  ) {}

  ngOnInit(): void {
    this.data.reinsurers.forEach((reinsurer, idx) => {
      if (! reinsurer.reinsurer.cededlayerID){
        return
      }
      const foundLayerName =
        this.data.sectionList.find(
          x => x.section.layerRef === reinsurer.reinsurer.cededlayerID
      )?.section.layerName || `Layer ${idx + 1}` // Gives layer name if no name is found
      this.layerNames[reinsurer.reinsurer.cededlayerID] = foundLayerName
    })
    this.modifiedReinsurers = JSON.parse(JSON.stringify(this.data.reinsurers))
    this.modifiedReinsurers = this.modifiedReinsurers.filter(reinsurer => reinsurer.reinsurer.reinsurerPhase === ReinsurerPhases.FOT)
    this.modifiedReinsurers = this.modifiedReinsurers.filter(hasOffMarketLines)
    this.modifiedReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink = reinsurer.reinsurer.riskAssignedLinesLink?.filter(row => !row.marketTpRef)
    })
  }

  updateLineName(update: SortTableValueChangeEvent<AssignedLines>): void {
    let updateLine: AssignedLines | undefined
    this.modifiedReinsurers.forEach(reinsurerLine => {
      const foundLine = reinsurerLine.reinsurer.riskAssignedLinesLink?.find(row => row.id === update.id)
      if (foundLine) {
        updateLine = foundLine
      }
    })
    if (updateLine) {
      const refLine = updateLine
      this.modifiedReinsurers.forEach(reinsurerLine => {
        reinsurerLine.reinsurer.riskAssignedLinesLink?.forEach(row => {
          if (row.whiteSpaceBusinessUnit === refLine.whiteSpaceBusinessUnit) {
            row.reinsurer = update.value
          }
        })
      })
    }
    this.modifiedReinsurers = JSON.parse(JSON.stringify(this.modifiedReinsurers))
  }

  mergeChangedLines(input: ReinsurerState[]): ReinsurerState[] {
    const tempReinsurers = JSON.parse(JSON.stringify(this.data.reinsurers)) as ReinsurerState[]
    tempReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink = reinsurer.reinsurer.riskAssignedLinesLink?.filter(row => row.marketTpRef)
    })
    input.forEach(reinsurer => {
      const foundReinsurer = tempReinsurers.find(x => x.reinsurer.id === reinsurer.reinsurer.id)
      if (!foundReinsurer || !reinsurer.reinsurer.riskAssignedLinesLink) {
        return
      }
      foundReinsurer.reinsurer.riskAssignedLinesLink =
        foundReinsurer.reinsurer.riskAssignedLinesLink?.concat(
          reinsurer.reinsurer.riskAssignedLinesLink
      )
    })
    return tempReinsurers
  }

  setLloydsSyndicateBureausStamps(): void{
    this.modifiedReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink?.forEach(row => {
        if (row.reinsurer?.startsWith(LLOYDS_SYNDICATE_PREFIX)) {
          row.bureaus = getAssignedLineBureausStamp(row, this.data.bureaus)
        }
      })
    })
  }

  save(): void {
    this.setLloydsSyndicateBureausStamps()
    this.dialogRef.close(this.modifiedReinsurers)
  }
}
