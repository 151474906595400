<div class="content">
  <h1 mat-dialog-title>Credit Scenarios</h1>
  <mat-form-field
    class="app-bar-field app-bar-field-big app-bar-field-button"
    appearance="outline"
  >
    <mat-label>
      <span>Choose a Scenario...</span>
    </mat-label>
    <mat-select
      [formControl]="scenarioControl"
      (selectionChange)="onScenarioChange($event)"
      appearance="outline"
    >
      <mat-optgroup label="Deterministic">
        <mat-option
          *ngFor="let scenario of deterministicScenarios"
          [value]="scenario.value"
        >
          {{ scenario.viewValue }}
        </mat-option>
      </mat-optgroup>
      <mat-optgroup *ngIf="stochasticScenarios.length > 0" label="Simulated">
        <mat-option
          *ngFor="let scenario of stochasticScenarios"
          [value]="scenario.value"
        >
          {{ scenario.viewValue }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <div class="slider-area">
    <app-return-period-selector
      [returnPeriod]="years"
      [disabled]="yearsDisabled"
      [presets]="[1, 5, 10, 20, 100, 250, 1000]"
      (returnPeriodChange)="years = $event"
    ></app-return-period-selector>
  </div>
  <i *ngIf="years <= 0 && !yearsDisabled"
    >Return period must be greater than 0</i
  >
  <div mat-dialog-actions align="end">
    <button appButton border mat-dialog-close>Close</button>
    <button
      appButton
      border
      accent
      [disabled]="years === 0 && !yearsDisabled"
      (click)="onApplyClick()"
    >
      Apply
    </button>
  </div>
</div>
