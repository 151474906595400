<div *ngIf="!error; else errorTemplate" class="wrapper">
  <label *ngIf="loading" appInfoText big class="loading">Loading...</label>
  <div #chart class="app-chart"></div>

  <div class="controls" *ngIf="!loading">
    <mat-form-field appearance="fill">
      <mat-label>X-Axis</mat-label>
      <mat-select
        [value]="metricX"
        (selectionChange)="onDimensionChange('x', $event.value)"
      >
        <mat-optgroup
          *ngFor="let group of metricCategory"
          [label]="group.category"
        >
          <mat-option
            *ngFor="let metrics of group.metrics"
            [value]="group.category + ' ' + metrics[0].label"
            matTooltip="{{ group.category + ' ' + metrics[0].label }}"
          >
            <span>{{ group.category + ' ' + metrics[0].label }}</span>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Y-Axis</mat-label>
      <mat-select
        [value]="metricY"
        (selectionChange)="onDimensionChange('y', $event.value)"
      >
        <mat-optgroup
          *ngFor="let group of metricCategory"
          [label]="group.category"
        >
          <mat-option
            *ngFor="let metrics of group.metrics"
            [value]="group.category + ' ' + metrics[0].label"
            matTooltip="{{ group.category + ' ' + metrics[0].label }}"
          >
            <span>{{ group.category + ' ' + metrics[0].label }}</span>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Size</mat-label>
      <mat-select
        [value]="metricSize"
        (selectionChange)="onDimensionChange('size', $event.value)"
      >
        <mat-option
          *ngFor="let prop of updatedProps"
          [value]="prop.category + ' ' + prop.label"
        >
          <span>{{ prop.category + ' ' + prop.label }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<mat-progress-bar
  *ngIf="loading"
  class="progress"
  color="accent"
  mode="indeterminate"
></mat-progress-bar>

<ng-template #errorTemplate>
  <label appInfoText class="error" warn big>{{ error }}</label>
</ng-template>

<!-- <mat-form-field appearance="fill">
      <mat-label>Y-Axis</mat-label>
      <mat-select
        [value]="state.y"
        (selectionChange)="onDimensionChange('y', $event.value)"
      >
        <mat-option
          *ngFor="let prop of props"
          [value]="prop.category + ' ' + prop.label"
          matTooltip="{{ prop.category + ' ' + prop.label }}"
        >
          <span>{{ prop.category + ' ' + prop.label }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field> -->
