import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { BrokerHeaderComponent } from './broker-header/broker-header.component'
import { BrokerOverviewComponent } from './broker-overview/broker-overview.component'
import { BrokerRouteContainerComponent } from './broker-route.container/broker-route.container'
import { BrokerRoutingModule } from './broker-routing.module'
import { BrokerStructuresContainerComponent } from './broker-structures/broker-structures.container'
import { BrokerComponent } from './broker.component/broker.component'
import { BrokerContainerComponent } from './broker.container/broker.container'
import { BrokerEffects } from '../core/store/broker/broker.effects'
import { EffectsModule } from '@ngrx/effects'

@NgModule({
  declarations: [
    BrokerHeaderComponent,
    BrokerOverviewComponent,
    BrokerRouteContainerComponent,
    BrokerStructuresContainerComponent,
    BrokerComponent,
    BrokerContainerComponent,
  ],
  imports: [
    CommonModule,
    BrokerRoutingModule,
    TierModule,
    SharedModule,
    EffectsModule.forFeature([BrokerEffects]),
  ],
})
export class BrokerModule {}
