import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators'
import { NavService } from '../nav.service'
import { fetchMetricTableSettings } from '../analysis/store/metrics/metrics-cart/metrics-cart.actions'
import { createTierPath } from '../tier/tier.util'
import { AppState } from '../core/store'
import { Store, select } from '@ngrx/store'
import { selectMetricTableSettingsStudyID } from '../analysis/store/analysis.selectors'
import { fetchStudyReinsurer } from './store/study-reinsurers.actions'
import { fetchUserPreferences } from '../user-preferences/store/user-preferences.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-route',
  template: ` <app-reinsurer-container></app-reinsurer-container> `,
})
export class ReinsurerRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  constructor(
    private route: ActivatedRoute,
    private nav: NavService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
    this.route.paramMap
      .pipe(
        map(params => createTierPath(params)),
        map(path => path.program),
        filter<string>(programID => programID != null && programID !== 'none'),
        withLatestFrom(
          this.store.pipe(select(selectMetricTableSettingsStudyID))
        ),
        filter(([next, prev]) => !prev || next !== prev),
        map(([next]) => fetchMetricTableSettings({ studyID: next }))
      )
      .subscribe(this.store)

    this.route.paramMap
      .pipe(
        map(params => createTierPath(params)),
        map(path => [path.program]),
        filter<[string, string]>(
          ([programID]) => programID != null && programID !== 'none'
        ),
        map(([programID]) => fetchUserPreferences({ studyID: programID }))
      )
      .subscribe(this.store)

    this.route.paramMap
      .pipe(
        map(params => createTierPath(params)),
        map(path => [path.program, path.client]),
        filter<[string, string]>(
          ([programID, clientID]) =>
            programID != null &&
            programID !== 'none' &&
            clientID !== null &&
            programID !== 'none'
        ),
        map(([programID, clientID]) =>
          fetchStudyReinsurer({
            carrierID: clientID,
            studyID: programID,
          })
        )
      )
      .subscribe(this.store)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
