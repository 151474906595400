<div class="outer-wrapper">
  <mat-chip-grid #chipList aria-label="Selected values">
    <div class="button-wrapper">
      <button appButton small primary border (click)="onEditClick($event)">
        Edit
      </button>
    </div>
    <div class="chip-container">
      <mat-chip-row class="app-chip-palette" *ngFor="let option of selectedValues">
        <div class="chip-name-wrapper">
          {{ option }}
        </div>
      </mat-chip-row>
    </div>
  </mat-chip-grid>
</div>
