import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { SectionState } from '../store/section/section.reducer'
import {
  QuotePanelDefResolved,
  QUOTE_PANEL_DEFS_TOGGLE_MAP,
} from '../quote-panel/quote-panel-defs'
import {
  InuringLayer,
  QuoteReinsurer,
  Reinstatements,
  Section,
} from '../models/quote.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-toggles',
  styleUrls: ['./quote-toggles.component.scss'],
  templateUrl: './quote-toggles.component.html',
})
export class QuoteTogglesComponent {
  quotePanelDefsToggleMap = QUOTE_PANEL_DEFS_TOGGLE_MAP
  defaultToggle = false
  @Input() selectedSection: Section | undefined
  @Input() quotePanelDefs: QuotePanelDefResolved
  @Input() sectionList: SectionState[]
  @Input() layer: LayerState | null | undefined
  @Input() isGroupSelected = false
  @Input() isSLSelected = false
  @Output() exportToggleSectionClick = new EventEmitter<{
    panelDefToggled: keyof Section | 'all' | 'default'
    value: boolean
  }>()

  @Output() exportToggleDefaultClick = new EventEmitter<{
    panelDefToggled: keyof Section | 'all' | 'default'
  }>()

  exportSelected(
    def: QuotePanelDefResolved
  ):
    | string
    | number
    | boolean
    | QuoteReinsurer[]
    | Reinstatements
    | InuringLayer
    | undefined {
    if (this.quotePanelDefsToggleMap.hasOwnProperty(def.id)) {
      return this.selectedSection?.[this.quotePanelDefsToggleMap[def.id]]
    }
  }

  onUpdateExportToggleSelection(
    def: QuotePanelDefResolved,
    event: MatCheckboxChange
  ): void {
    if (
      this.selectedSection &&
      this.quotePanelDefsToggleMap.hasOwnProperty(def.id)
    ) {
      this.updateToggles(def, event.checked)
    }
  }

  toggleAll(): boolean | undefined {
    if (this.selectedSection && this.quotePanelDefs) {
      let toggleValue = true
      for (const [key, value] of Object.entries(this.selectedSection)) {
        let keyFound = false
        Object.values(this.quotePanelDefs).forEach(element => {
          if (element.exportID === key) {
            keyFound = true
          }
        })
        if (keyFound) {
          if (key.includes('Toggle') && value === true) {
          } else {
            toggleValue = false
          }
        }
      }
      return toggleValue
    }
  }

  onToggleAllChange($event: MatCheckboxChange): void {
    if (this.selectedSection) {
      this.exportToggleSectionClick.emit({
        panelDefToggled: 'all',
        value: $event.checked,
      })
    }
  }

  onDefaultToggleSelection(): void {
    if (this.selectedSection) {
      this.exportToggleDefaultClick.emit({
        panelDefToggled: 'default',
      })
    }
  }

  tooltipText(def: QuotePanelDefResolved): string {
    let response = 'Click to show/hide field'
    if (def.id === 'quoteIndexation') {
      response = 'Full, Severe, Franchise'
    }

    if (def.id === 'quoteFixedIndexValue') {
      response =
        'Rate the Limits and Attachments are expected to inflate at in the agreement'
    }

    if (def.id === 'indexationtext') {
      response =
        'Free text field to track any specifications for the Indexation clause of note'
    }

    if (def.id === 'quoteOccurrenceAttachment') {
      response = 'EELD (each and every loss deductible)'
    }
    return response
  }

  private updateToggles(def: QuotePanelDefResolved, value: boolean): void {
    if (this.quotePanelDefsToggleMap.hasOwnProperty(def.id)) {
      this.exportToggleSectionClick.emit({
        panelDefToggled: this.quotePanelDefsToggleMap[def.id],
        value,
      })
    }
  }
}
