import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { DynamicMenuItem } from './dynamic-menu.model'

@Component({
  selector: 'app-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.scss'],
})
export class DynamicMenuComponent {
  @ViewChild('menu', { static: true }) menu: MatMenu
  @Input() items: DynamicMenuItem[]
  @Output() menuItemClick = new EventEmitter<DynamicMenuItem>()
}
