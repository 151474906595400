<app-portfolio-header
  class="header"
  [tierNames]="tierNames"
  [activeAction]="activeAction"
  [submitMarketPermission]="submitMarketPermission"
  [dirty]="dirty"
  [portfolioSetID]="id"
  [currentStructure]="currentProgram"
  [disableActions]="showingAnimatedScenariosTab || showingExploreGrossTab"
  [disableOptimization]="!enableOptimization"
  [libRETemplate]="isLibRETemplate"
  [isLibRE]="isLibRE"
  [structureDirty]="structureDirtyState"
  [canSaveTechnicalPremium]="canSaveTechnicalPremium"
  (resetClick)="resetClick.emit()"
  (saveClick)="onSaveClick($event)"
  (saveAsClick)="saveAsClick.emit($event)"
  (backClick)="backClick.emit()"
  (parentClick)="onParentClick()"
  (editNameClick)="onEditNameClick()"
  (importLossSetsClick)="onImportLossSetsClick()"
  (optimizeClick)="optimizeClick.emit()"
></app-portfolio-header>

<main>
  <app-layer-panel
    appClickElsewhere
    (clickElsewhere)="resetSelectedGroup()"
    class="layers app-elevation-1"
    [ngClass]="{
      closed: !isLayersPanelOpen,
      hidden: !showingTowerTab,
      hover: layersPanelToggleHover,
      edit: showLossSetGroupEditor
    }"
    [open]="isLayersPanelOpen"
    [currentProgram]="currentProgram"
    [scenarios]="scenarios"
    [optimizations]="optimizations"
    [libRE]="isLibRE"
    [designDirty]="dirty"
    (scenarioOrOptimizationClick)="scenarioOrOptimizationClick.emit($event)"
    [showLossSetGroupEditor]="showLossSetGroupEditor"
    [groupEditSelectedLossSets]="groupEditSelectedLossSets"
    [lossSetGroupEditMode]="lossSetGroupEditMode"
    [programID]="currentProgram!.studyID"
    [lossSetGroups]="lossSetGroups"
    [lossSetGroupsMetrics]="lossSetGroupsMetrics"
    [lossSetSelectedGroup]="lossSetSelectedGroup"
    [lossSetSelectionMode]="lossSetSelectionMode"
    [lossSetGroupsActiveAction]="lossSetGroupsActiveAction"
    [currentCededLayer]="currentCededLayer"
    [allCededLayers]="layers"
    [collapseBySection]="collapseBySection"
    [parentLossSets]="parentLossSets"
    [grossLossSets]="grossLossSets"
    [metrics]="metrics"
    [layerPasteTrigger]="layerPasteTrigger"
    [studies]="studies"
    [selectedProgramID]="selectedProgramID"
    (mouseover)="layersPanelToggleHover = true"
    (mouseout)="layersPanelToggleHover = false"
    (click)="onLayersPanelClick($event)"
    (grossSelectionModeClick)="grossSelectionModeClick.emit()"
    (cededSelectionModeClick)="cededSelectionModeClick.emit()"
    (newLossSetGroupClick)="newLossSetGroupClick.emit()"
    (cancelLossSetGroupClick)="cancelLossSetGroupClick.emit()"
    (saveLossSetGroupClick)="saveLossSetGroupClick.emit($event)"
    (updateLossSetGroupClick)="updateLossSetGroupClick.emit($event)"
    (lossSetGroupEditorClick)="lossSetGroupEditorClick.emit($event)"
    (groupEditLossSetSelection)="groupEditLossSetSelection.emit($event)"
    (lossSetGroupClick)="lossSetGroupClick.emit($event)"
    (deleteLossSetGroupClick)="deleteLossSetGroupClick.emit($event)"
    (lossSetSelection)="lossSetSelection.emit($event)"
    (grossLossSetSelection)="grossLossSetSelection.emit($event)"
    (lossSetGroupSetDirty)="lossSetGroupSetDirty.emit($event)"
    (removeRiskLossSets)="removeRiskLossSets.emit($event)"
    (addRiskLossSets)="addRiskLossSets.emit($event)"
    (collapseChange)="collapseChange.emit($event)"
    (returnPeriod1Change)="returnPeriod1Change.emit($event)"
    (returnPeriod2Change)="returnPeriod2Change.emit($event)"
    (returnPeriod3Change)="returnPeriod3Change.emit($event)"
    (openAddInuranceDialog)="openAddInuranceDialog.emit($event)"
  ></app-layer-panel>

  <div class="content">
    <mat-progress-bar
      mode="indeterminate"
      class="metrics-progress"
      *ngIf="portfolioMetricsLoading"
      color="accent"
    ></mat-progress-bar>

    <app-loading-item
      class="metrics-wrapper"
      [ngClass]="{
        hidden: isLibRE || showingAnimatedScenariosTab || showingExploreGrossTab
      }"
      [item]="metrics"
      [loading]="isEmpty"
    >
      <app-portfolio-metrics
        *ngIf="metrics"
        class="metrics"
        [ngClass]="{
          hidden:
            isLibRE || showingAnimatedScenariosTab || showingExploreGrossTab || showingTechPremiumTab
        }"
        [selectedClientID]="selectedClientID"
        [selectedYearID]="selectedYearID"
        [open]="portfolioMetricsOpen"
        [studies]="studies"
        [selectedProgramID]="selectedProgramID"
        [metrics]="metrics"
        [rss]="rss"
        [layers]="layers"
        [currentProgram]="currentProgram"
        [sharedIDPortfolio]="sharedIDPortfolio"
        [authState]="authState"
        [reinsurers]="reinsurers"
        [layerData]="layerData"
        [marketBlob]="marketBlob"
        [lossFilters]="lossFilters"
        [layerViewIDs]="layerViewIDs"
        [grossPortfolioViewID]="grossPortfolioViewID"
        [currencyList]="currencyList"
        [filteredCurrencyList]="currencyList"
        [currentCurrency]="currentCurrency"
        (structureCurrency)="structureCurrency.emit($event)"
        (structureDirty)="structureDirtyState = $event"
        (toggleClick)="togglePortfolioMetricsClick.emit()"
        (showExchange)="showExchange.emit()"
        (returnPeriod1Change)="returnPeriod1Change.emit($event)"
        (returnPeriod2Change)="returnPeriod2Change.emit($event)"
        (returnPeriod3Change)="returnPeriod3Change.emit($event)"
        (returnPeriod4Change)="returnPeriod4Change.emit($event)"
        (returnPeriod5Change)="returnPeriod5Change.emit($event)"
        (portfolioTypeChange)="portfolioTypeChange.emit($event)"
        (aggregationMethodChange)="aggregationMethodChange.emit($event)"
        (perspectiveChange)="perspectiveChange.emit($event)"
        (lossFilterClick)="lossFilterClick.emit($event)"
        (showDetails)="showDetails.emit(tierNames?.structure)"
        (submitMarketClick)="submitMarketClick.emit($event)"
        (saveClick)="onSaveClick()"
        (updateLayerCurrencyAndSaveClick)="onUpdateLayerCurrencyAndSaveClick($event)"
      ></app-portfolio-metrics
    ></app-loading-item>

    <app-portfolio-tabs
      [showAgg]="showAgg"
      [showOcc]="showOcc"
      [showTowerControls]="showingTowerTab"
      [showZoom]="showZoom"
      [libRE]="isLibRE"
      [hideTechPremiumTab]="hideTechPremiumTab"
      [tabPanel]="tabPanel"
      (showAggChange)="showAggChange.emit($event)"
      (showOccChange)="showOccChange.emit($event)"
      (showProperties)="showProperties.emit($event)"
      (showZoomChange)="showZoomChange.emit($event)"
      (downloadTower)="onTowerDownloadClick()"
      (towerTabSelected)="towerTabSelected.emit()"
    ></app-portfolio-tabs>

    <section class="body">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </section>

    <app-panel-toggle-button
      *ngIf="showingTowerTab && !showLossSetGroupEditor"
      class="toggle-layers"
      [open]="layersPanelOpen"
      [hover]="!layersPanelOpen && layersPanelToggleHover"
      (mouseover)="layersPanelToggleHover = true"
      (mouseout)="layersPanelToggleHover = false"
      (toggleClick)="toggleLayersPanelClick.emit()"
    ></app-panel-toggle-button>
    <app-panel-toggle-button
      *ngIf="showingTowerTab"
      class="toggle-properties"
      right
      [open]="propertiesPanelOpen"
      [hover]="!propertiesPanelOpen && propertiesPanelToggleHover"
      (mouseover)="propertiesPanelToggleHover = true"
      (mouseout)="propertiesPanelToggleHover = false"
      (toggleClick)="togglePropertiesPanelClick.emit()"
    ></app-panel-toggle-button>
  </div>

  <app-properties-panel
    class="props app-elevation-1"
    [ngClass]="{
      closed: !isPropertiesPanelOpen,
      hidden: !showingTowerTab,
      hover: propertiesPanelToggleHover
    }"
    [id]="id"
    [selectedViewID]="selectedViewID"
    [open]="isPropertiesPanelOpen"
    [metrics]="metrics"
    [designDirty]="dirty"
    [libRETemplate]="isLibRETemplate"
    [isLibRE]="isLibRE"
    (mouseover)="propertiesPanelToggleHover = true"
    (mouseout)="propertiesPanelToggleHover = false"
    (click)="onPropertiesPanelClick($event)"
    (handlePastedLayer)="onHandlePastedLayer($event)"
    (structureCurrency)="onStructureCurrencyEvent($event)"
  ></app-properties-panel>
</main>
