import { Observable, Subject } from 'rxjs'
import { HasID } from '../../core/model/general.model'
import { rejectNil } from './operators'

type SelectionItem = HasID | string | number | boolean

export class Selections {
  dictionary: Record<string | number, boolean> = {}
  count = 0
  allSelected = false
  someSelected = false

  private _change$ = new Subject<SelectionsChangeEvent>()

  get changes(): Observable<SelectionsChangeEvent> {
    return this._change$.asObservable()
  }

  get onlySomeSelected(): boolean {
    return !this.allSelected && this.someSelected
  }

  getSelected<T extends SelectionItem = HasID>(items: T[]): T[] {
    return rejectNil(items.map(it => (this.dictionary[getID(it)] ? it : null)))
  }

  some<T extends SelectionItem = HasID>(
    items: T[],
    predicate: (it: T) => unknown
  ): boolean {
    return this.getSelected(items).some(predicate)
  }

  update<T extends SelectionItem = HasID>(
    items: T[],
    skipNotification?: boolean
  ): void {
    if (items.length === 0) {
      this.count = 0
      this.allSelected = false
      this.someSelected = false
      if (!skipNotification) {
        this._change$.next({ selections: this, items })
      }

      return
    }

    let n = items.length
    let all = true
    let some = false
    for (const it of items) {
      if (!this.dictionary[getID(it)]) {
        all = false
        n--
      } else {
        some = true
      }
    }
    this.count = n
    this.allSelected = all
    this.someSelected = some
    if (!skipNotification) {
      this._change$.next({ selections: this, items })
    }
  }

  toggle<T extends SelectionItem = HasID>(
    id: string | number,
    items: T[]
  ): void {
    const prev = this.dictionary[id]
    this.dictionary[id] = !prev
    this.update(items)
  }

  setAll<T extends SelectionItem = HasID>(items: T[], selected: boolean): void {
    if (!selected) {
      return this.clear()
    }
    this.allSelected = true
    this.someSelected = true
    for (const it of items) {
      this.dictionary[getID(it)] = true
    }
    this.count = items.length
    this._change$.next({ selections: this, items })
  }

  clear(): void {
    this.dictionary = {}
    this.count = 0
    this.allSelected = false
    this.someSelected = false
    this._change$.next({ selections: this, items: [] })
  }
}

function getID(item: SelectionItem): string | number {
  switch (typeof item) {
    case 'string':
    case 'number':
      return item
    case 'boolean':
      return String(item)
    default:
      return item.id
  }
}

export interface SelectionsChangeEvent {
  selections: Selections
  items: any[]
}
