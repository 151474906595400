import { createAction, props } from '@ngrx/store'
import {
  LayerMetrics,
  LayerMetricsRP,
  LayerViewMetricsRPPayload,
} from 'src/app/analysis/model/layers-metrics.model'
import { PortfolioSetID } from 'src/app/analysis/model/portfolio-set.model'
import { ApplicationError } from 'src/app/error/model/error'

export const fetchLayersViewMetrics = createAction(
  '[Layers View Metrics] Fetch',
  props<
    PortfolioSetID & {
      layerViewID: string
      isSL?: boolean
      lossFilterValue?: string
      yearUpdate?: number
      methodUpdate?: string
    }
  >()
)

export const fetchLayersViewMetricsFailure = createAction(
  '[Layers View Metrics] Fetch Failure',
  props<
    PortfolioSetID & {
      error: ApplicationError
      layerViewID: string
      lossFilterValue?: string
      yearUpdate?: number
      methodUpdate?: string
    }
  >()
)

export const fetchLayersViewMetricsSuccess = createAction(
  '[Layers View Metrics] Fetch Success',
  props<
    PortfolioSetID & {
      metrics: LayerMetrics
      layerViewID: string
      lossFilterValue?: string
      yearUpdate?: number
      methodUpdate?: string
    }
  >()
)

export const fetchLayersViewMetricsRP = createAction(
  '[Layers View Metrics RP] Fetch',
  props<
    PortfolioSetID &
      LayerViewMetricsRPPayload & {
        layerViewID: string
        layerID: string
        isSL?: boolean
      }
  >()
)

export const fetchLayersViewMetricsFailureRP = createAction(
  '[Layers View Metrics RP] Fetch Failure',
  props<PortfolioSetID & { error: ApplicationError; layerID: string }>()
)

export const fetchLayersViewMetricsSuccessRP = createAction(
  '[Layers View Metrics RP] Fetch Success',
  props<PortfolioSetID & { metrics: LayerMetricsRP; layerID: string }>()
)
