<h1 mat-dialog-title>Choose a Risk Reference</h1>
<mat-dialog-content>
  <div>
    <mat-form-field
      *ngIf="!selectedRiskRef"
      class="select-option"
    >
      <mat-autocomplete
        #riskRefAutoComplete="matAutocomplete"
        panelWidth="auto"
      >
        <mat-optgroup
          *ngFor="let group of filteredRiskRefs | async"
          [label]="group.groupName"
        >
          <mat-option
            *ngFor="let option of group.risks"
            [value]="option.riskRef"
            (onSelectionChange)="onSelectedRiskRefOption(option.riskRef)"
          >
            <span>{{ option.riskRef }}</span
            ><span *ngIf="option.riskNarrative">
              - {{ option.riskNarrative }}</span
            >
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <input
        matInput
        type="text"
        aria-label="RiskRef"
        [matAutocomplete]="riskRefAutoComplete"
        [formControl]="riskRefControl"
      />
    </mat-form-field>
    <div>Contact client services if Risk Ref # is not found</div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="actions">
  <button appButton center link [mat-dialog-close]="false">Cancel</button>
</div>
