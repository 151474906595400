import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import {
  SummaryChartAxis,
  SummaryChartGridOption,
} from '../summary-charts-model'

@Component({
  selector: 'app-explore-summary-grid-select',
  styleUrls: ['./explore-summary-grid-select.component.scss'],
  templateUrl: './explore-summary-grid-select.component.html'
})
export class ExploreSummaryGridSelectComponent {
  @Input() groupByOptions: SummaryChartAxis[]
  @Input() charts: SummaryChartGridOption[]
  @Input() isSelectable: boolean

  @Output() iconToggle = new EventEmitter<number>()
  @Output() chartsGridIndexSelection = new EventEmitter<number>()
  @Output() chartsGroupByChange = new EventEmitter<{
    groupBy: SummaryChartAxis
    chartIndex: number
  }>()

  onGridIndexSelection(chart: SummaryChartGridOption): void {
    if (chart.highlight) {
      return
    }
    this.chartsGridIndexSelection.emit(chart.index)
  }

  onGroupByChange($event: MatSelectChange, index: number): void {
    this.chartsGroupByChange.emit({
      groupBy: {
        name: $event.source.triggerValue,
        columnName: $event.value,
        applicableCharts: [],
      },
      chartIndex: index,
    })
  }

  // This decorator binds a class property to a property of the host element.
  // Class property: this.selectable: boolean < -- > Host element: <li class="selectable:true or false" >
  // if this.isSelectable is true the class .selectable will be applied to the parent component's DOM element
  @HostBinding('class.selectable')
  get selectable(): boolean {
    return this.isSelectable
  }
}
