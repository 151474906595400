import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import {
  StudyMetricSettingsResponse,
  MetricSettingsSaveChanges,
} from './metric-settings-response.model'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'

@Injectable({
  providedIn: 'root',
})
export class MetricSettingsService {
  constructor(private httpClient: HttpClient) {}

  getMetricSettings(id: string, isCredit?: boolean): ApiResponse<StudyMetricSettingsResponse[]> {
    let url = `${environment.internalApi.base}${environment.internalApi.metricSettings}/${id}`
    if (isCredit){
      url = url.concat('?isCredit=true')
    }
    return this.httpClient
      .get<StudyMetricSettingsResponse[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Metric Settings'))
  }

  saveMetricSettings(
    metricRequest: MetricSettingsSaveChanges[], isCredit?: boolean
  ): ApiResponse<StudyMetricSettingsResponse[]> {
    let url = `${environment.internalApi.base}${environment.internalApi.metricSettings}`
    if (isCredit){
      url = url.concat('?isCredit=true')
    }
    return this.httpClient
      .put<StudyMetricSettingsResponse[]>(url, metricRequest)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Metric Settings'))
  }

  deleteMetricSetting(id: string): ApiResponse<StudyMetricSettingsResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.metricSettings}/${id}`
    return this.httpClient
      .delete<StudyMetricSettingsResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Metric Setting'))
  }
}
