import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core'
import { DialogPosition, MatDialogRef } from '@angular/material/dialog'

export interface TriggerPositionDialogOptions {
  triggerRef?: ElementRef
  leftOffset?: string | number
  topOffset?: string | number
  expectedWidth?: number
  expectedHeight?: number
}

const marginX = 12
const marginY = 4

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-trigger-position-dialog',
  template: ` <div></div> `,
})
export class TriggerPositionDialogComponent implements OnInit, AfterViewInit {
  triggerRef?: ElementRef<HTMLElement>
  topOffset?: string | number
  leftOffset?: string | number
  expectedWidth?: number
  expectedHeight?: number
  externalClickIgnoreClasses: string[]
  alignRight = false
  alignBottom = false
  isCloneDialog?: boolean
  ignoreAllExternalClicks?: boolean

  constructor(
    protected dialogRef: MatDialogRef<any>,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const triggerEl: Element | undefined = this.triggerRef?.nativeElement
    if (!triggerEl) {
      return
    }
    let position
    if (this.isCloneDialog) {
      position = {
        ...{ left: '8px' },
        ...{ top: '101px' },
      }
    } else {
      const triggerRect = triggerEl.getBoundingClientRect()
      position = {
        ...this.getXPosition(triggerRect),
        ...this.getYPosition(triggerRect),
      }
      if (position.right) {
        this.alignRight = true
      }
      if (position.bottom) {
        this.alignBottom = true
      }
    }

    this.dialogRef.updatePosition(position)
  }

  ngAfterViewInit(): void {
    let destroy: () => void | undefined

    // tslint:disable: deprecation
    this.dialogRef.afterOpened().subscribe(() => {
      // If the click was outside of the dialog parent container, close
      destroy = this.renderer.listen('document', 'click', $event => {
        const el = this.elementRef.nativeElement
        const parent = el && el.parentElement
        if (
          $event.target &&
          parent &&
          !parent.contains($event.target) &&
          !this.isClickInIgnoredClasses($event.target) &&
          !this.ignoreAllExternalClicks
        ) {
          this.dialogRef.close()
        }
      })
    })
    // Destroy click event listener when closing
    this.dialogRef.beforeClosed().subscribe(() => (destroy ? destroy() : null))
  }

  private getXPosition(trigger: DOMRect): DialogPosition {
    const leftOffset = this.leftOffset || 0
    const windowWidth = window.innerWidth - marginX

    if (this.expectedWidth && this.expectedWidth + trigger.left > windowWidth) {
      // If expected dialog width exceeds window width, position from right
      return {
        right:
          typeof leftOffset === 'string'
            ? `calc(${windowWidth}px - ${trigger.right}px + ${leftOffset})`
            : `${windowWidth - trigger.right + leftOffset}px`,
      }
    } else {
      return {
        left:
          typeof leftOffset === 'string'
            ? `calc(${leftOffset} + ${trigger.left}px)`
            : `${leftOffset + trigger.left}px`,
      }
    }
  }

  private getYPosition(trigger: DOMRect): DialogPosition {
    const topOffset = this.topOffset || 0
    const windowHeight = window.innerHeight - marginY
    if (
      this.expectedHeight &&
      this.expectedHeight + trigger.top > windowHeight
    ) {
      // If expected dialog height exceeds window height, position from bottom
      return {
        bottom:
          typeof topOffset === 'string'
            ? `calc(${windowHeight}px - ${trigger.bottom}px + ${topOffset})`
            : `${windowHeight - trigger.bottom + topOffset}px`,
      }
    } else {
      return {
        top:
          typeof topOffset === 'string'
            ? `calc(${topOffset} + ${trigger.top}px)`
            : `${topOffset + trigger.top}px`,
      }
    }
  }

  private isClickInIgnoredClasses(target: HTMLElement): boolean {
    if (this.externalClickIgnoreClasses) {
      // Check all target's parents if matches ignored classes
      let currentElement: HTMLElement | null = target
      let i = 0
      do {
        const classes = currentElement
          ? Array.from(currentElement.classList)
          : []
        for (const c of classes) {
          for (const ic of this.externalClickIgnoreClasses) {
            if (c.indexOf(ic) >= 0) {
              return true
            }
          }
        }
        currentElement = currentElement.parentElement
        i++
      } while (currentElement && i < 500)
    }
    return false
  }
}
