import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { HttpClientModule } from '@angular/common/http'
import { TrackingRoutingModule } from './tracking-routing.module'
import { TrackingContainerComponent } from './tracking.container'
import { TrackingComponent } from './tracking-component/tracking.component'
import { TrackingRouteContainerComponent } from './tracking-route.container'
import { TrackingHeaderComponent } from './tracking-header/tracking-header.component'
import { TrackingSideBarComponent } from './tracking-sidebar/tracking-sidebar.component'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import * as fromTracking from './store/tracking.reducer'
import { TrackingEffects } from './store/tracking.effects'
import { NewPreferenceDialogService } from './tracking-new-preference-dialog.service'


@NgModule({
  declarations: [
    TrackingContainerComponent,
    TrackingComponent,
    TrackingRouteContainerComponent,
    TrackingHeaderComponent,
    TrackingSideBarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TierModule,
    TrackingRoutingModule,
    HttpClientModule,
    StoreModule.forFeature(fromTracking.TRACKING_KEY, fromTracking.reducer),
    EffectsModule.forFeature([TrackingEffects]),
  ],
  providers: [NewPreferenceDialogService],
})
export class TrackingModule {}
