import { props, createAction } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  LossSetLayer,
  ScaledLossSetProps,
} from '../../model/loss-set-layers.model'
import { Metrics } from 'src/app/api/analyzere/analyzere.model'
import {
  PortfolioMetrics,
  PortfolioViewMetricsPayload,
} from '../../model/portfolio-metrics.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'

export const fetchLossSetLayers = createAction(
  '[LossSetLayers] Fetch',
  props<{ ids: string[] }>()
)

export const fetchLossSetLayersFromGrossPortfolio = createAction(
  '[LossSetLayers] Fetch From Gross Portfolio',
  props<{ id: string }>()
)

export const fetchLossSetLayersFromParentGrossPortfolio = createAction(
  '[LossSetLayers] Fetch From Parent Gross Portfolio',
  props<{ id: string }>()
)

export const fetchLossSetLayersFailure = createAction(
  '[LossSetLayers] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const fetchLossSetLayersSuccess = createAction(
  '[LossSetLayers] Fetch Success',
  props<{ lossSetLayers: LossSetLayer[] }>()
)

export const fetchParentLossSetLayersFailure = createAction(
  '[LossSetLayers] Fetch Parent Failure',
  props<{ error: ApplicationError }>()
)

export const fetchParentLossSetLayersSuccess = createAction(
  '[LossSetLayers] Fetch Parent Success',
  props<{ parentLossSetLayers: LossSetLayer[] }>()
)

export const updateGrossLossSets = createAction(
  '[LossSetLayers] Update Gross Loss Sets',
  props<{ lossSetLayers: LossSetLayer[] }>()
)

export const setDirty = createAction(
  '[LossSetLayers] Set Dirty',
  props<{ dirty: boolean }>()
)

export const setLossSetAAL = createAction(
  '[LossSetLayers] Set Loss Set AAL',
  props<{
    lossSetViews: Record<string, string>
    analysisID: string
    currency?: string
  }>()
)

export const updateLossSetAAL = createAction(
  '[LossSetLayers] Update Loss Set AAL',
  props<{ mean: number; lossSetLayerID: string }>()
)

export const setLossSetAALSuccess = createAction(
  '[Ceded Layers] Set Loss Set AAL Success',
  props<{ success: boolean; data: Record<string, Metrics> | null }>()
)

export const setLossSetSelectionMode = createAction(
  '[Ceded Layers] Set Loss Set Selection Mode',
  props<{ lossSetSelectionMode: string }>()
)

export const newScaledLossSetLayers = createAction(
  '[LossSetLayers] Create New Scaled Loss Sets',
  props<{ scaledProps: ScaledLossSetProps[] }>()
)

export const newScaledLossSetLayersSuccess = createAction(
  '[LossSetLayers] Create New Scaled Loss Sets Success'
)

export const newScaledLossSetLayersFailure = createAction(
  '[LossSetLayers] Create New Scaled Loss Sets Failure',
  props<{ error: ApplicationError }>()
)

export const modifyScaleLossSetLayers = createAction(
  '[LossSetLayers] Modify Loss Set Layers',
  props<{ scaledProps: ScaledLossSetProps[] }>()
)

export const modifyScaleLossSetLayersSuccess = createAction(
  '[LossSetLayers] Modify Loss Set Layers Success'
)

export const modifyScaleLossSetLayersFailure = createAction(
  '[LossSetLayers] Modify Loss Set Layers Failure',
  props<{ error: ApplicationError }>()
)

export const getLossSetGroupMetrics = createAction(
  '[LossSetLayers] Get Portfolio Metrics',
  props<{
    lossSetGroupId: string
    layerIds: string[]
    perspective: Perspective
    returnPeriod1: number
    returnPeriod2: number
    returnPeriod3: number
    aggregationMethod: AggregationMethodType
    lossFilter: string
  }>()
)

export const getLossSetGroupMetricsSuccess = createAction(
  '[LossSetLayers] Get Portfolio Metric Success',
  props<{ data: Record<string, PortfolioMetrics> }>()
)

export const getLossSetGroupMetricsFailure = createAction(
  '[LossSetLayers] Get Portfolio Metric Failure',
  props<{ id: string; error: ApplicationError }>()
)

export const getAllLossSetGroupsMetrics = createAction(
  '[LossSetLayers] Get All Loss Set Groups Metrics',
  props<{
    change: Partial<PortfolioViewMetricsPayload>
  }>()
)

export const fetchLossSetLayerViews = createAction(
  '[LossSetLayers] Fetch Layer Views',
  props<{ layerIDs: string[] }>()
)

export const fetchLossSetLayerViewsSuccess = createAction(
  '[LossSetLayers] Fetch Layer Views Success',
  props<{ layerViewIDs: Record<string, string> }>()
)

export const fetchLossSetLayerViewsFailure = createAction(
  '[LossSetLayers] Fetch Layer Views Failure',
  props<{ error: ApplicationError }>()
)
