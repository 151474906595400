import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-corp-logo, button[appCorpLogo], a[appCorpLogo]',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: column;
           }
     
           .name {
             position: relative;
             top: -25px;
           }
     
           .icon {
             height: 70px;
           }
     
           .tagline {
             width: 100%;
             height: auto;
           }
         `,
  ],
  template: `
    <img
      *ngIf="!tagline"
      class="icon"
      src="assets/logos/corp-logo-icon-white.svg"
      alt="lockton"
    />
    <img
      *ngIf="!tagline"
      class="name"
      src="assets/logos/corp-logo-name-white.svg"
      alt="lockton"
    />
    <img
      *ngIf="tagline"
      class="tagline"
      src="assets/logos/corp-logo.png"
      height="240"
      width="441"
    />
  `,
})
export class CorpLogoComponent {
  @Input() set tagline(value: any) {
    this._tagline = coerceBooleanProperty(value)
  }
  get tagline() {
    return this._tagline
  }
  @HostBinding('class.tagline') _tagline = true
}
