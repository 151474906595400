import { HasName } from '../../core/model/general.model'
import { BenchmarkPeerSetEntity } from '../store/benchmark-peer-set.reducer'
import {
  BenchmarkTable,
  BenchmarkTableRow,
} from '../table/benchmark-table.model'

export const benchmarkExportTypes = [
  'chart_pdf',
  'data_xlsx',
  'data_csv',
  'custom_format_xlsx',
] as const

export type BenchmarkExportType = typeof benchmarkExportTypes[number]

export type BenchmarkExportTypeDef = HasName & {
  id: BenchmarkExportType
  isTabular?: boolean
  isCustomFormat?: boolean
}

export const benchmarkExportTypeDefs: BenchmarkExportTypeDef[] = [
  { id: 'chart_pdf', name: 'Chart as PDF' },
  { id: 'data_xlsx', name: 'Data as Excel', isTabular: true },
  { id: 'data_csv', name: 'Data as CSV', isTabular: true },
  { id: 'custom_format_xlsx', name: 'Custom Excel', isCustomFormat: true },
]

export interface BenchmarkExportPage {
  id: string
  tables: BenchmarkTable<BenchmarkTableRow>[]
  canvas?: HTMLCanvasElement
  peerSets?: BenchmarkPeerSetEntity[]
  chartCanvases?: Record<string, HTMLCanvasElement>
}

export type BenchmarkExportOrientation = 'portrait' | 'landscape'

export const ALPHABET_ARR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
