<div
      class="content"
      *ngFor="
        let rangesType of rangesTypes;
        let i = indexl;
        trackBy: trackByRangesTypes
      "
    >
      <div [ngClass]="getLabelClass(rangesType.id)">
        {{ getLayerDescription(rangesType.id) }}
      </div>
      <div class="loss-set-groups">
        <div>Currency</div>
        <mat-form-field appearance="fill" color="accent">
          <input
            type="text"
            matInput
            [formControl]="currencyControl"
            [matAutocomplete]="currencyCtrl"
            (input)="onCurrencyChange($event.target.value)"
          />
          <mat-autocomplete
            #currencyCtrl="matAutocomplete"
            (optionSelected)="
              currencyChange.emit({
                id: rangesType.id,
                currency: $event.option.value,
                ranges: rangesTypes
              })
            "
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="let currency of filteredCurrencyList"
              [value]="currency"
            >
              {{ currency.code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        *ngFor="
          let range of rangesType.ranges;
          let i = index;
          trackBy: trackByOption
        "
      >
        <ng-container *ngIf="range.id !== 'currencyChange'">
          <app-optimization-range-input
            [range]="range"
            [showExtraLabels]="i === 0"
            (rangeChange)="
              rangeChange.emit({ id: rangesType.id, change: $event })
            "
          ></app-optimization-range-input>
        </ng-container>
      </div>
      <div class="loss-set-groups">
        <div>Loss Set Groups</div>
        <mat-form-field appearance="fill" color="accent">
          <mat-select
            placeholder="Select Loss Set Groups"
            [value]="rangesType.lossSetGroupIDs"
            (valueChange)="
              selectedLossSetGroupIDsChange.emit({
                id: rangesType.id,
                change: $event
              })
            "
            [compareWith]="compareFn"
            multiple
          >
            <mat-option
              *ngFor="let lossSetGroup of lossSetGroups"
              [value]="lossSetGroup.id"
              >{{ lossSetGroup.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="loss-set-groups">
        <div>Pricing Curve</div>
        <div>Default pricing curve selected.</div>
      </div>
    </div>
    <label appInfoText hint small>
      The maximum number of layers is {{ maxUniqueLayers }}. Anything above this
      number will be ignored.
    </label>
    <div class="total-section">
      <label>Number of unique layers:</label>
      <span [ngClass]="{ warning: isOutOfRange }">{{ uniqueLayers }}</span>
    </div>
