<section class="body">
  <div class="results">
    <app-animated-event-result
      [scenarioEventsResult]="scenarioEventsResult"
      [structure]="structure"
      [sharedIDPortfolio]="sharedIDPortfolio"
      [originalLayers]="originalLayers"
      [originalStructure]="originalStructure"
      [studies]="studies"
      [animating]="animating"
      [name]="name"
      (animationEnd)="animationEnd.emit()"
    ></app-animated-event-result>
  </div>
  <div class="table-container">
    <app-animated-scenarios-toolbar
      [executing]="executing"
      [recording]="isRecording"
      [disableReanimate]="scenarioEventsResult.length === 0"
      [disableProcessEvents]="scenarioEvents.length === 0"
      (record)="onRecord()"
      (stopRecord)="onStopRecord()"
      (addEvent)="onScenarioEventAdd()"
      (processEvents)="onProcessEvents()"
      (reanimate)="onReAnimate()"
    ></app-animated-scenarios-toolbar>
    <div class="table">
      <app-scenario-event-table
        [events]="scenarioEvents"
        [lossSetNames]="lossSetNames"
        [disableActions]="executing"
        [currentCurrency]="currentCurrency"
        (eventChange)="scenarioEventChange.emit($event)"
        (delete)="scenarioEventDelete.emit($event)"
      ></app-scenario-event-table>
    </div>
    <label appInfoText hint small>
      Currency values are shown in {{ currencySymbol }} Millions
    </label>
  </div>
</section>
