<div>
  <h1 mat-dialog-title>FHCF Layer Selected Error</h1>
  <div mat-dialog-content>
    Cannot add FHCF layer type for creating Shared Limit.
  </div>
  <div mat-dialog-actions>
    <button appButton link mat-dialog-close (click)="cancel()">
      <span>Cancel</span>
    </button>
  </div>
</div>
