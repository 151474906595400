import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { AppState } from '../../core/store'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import { selectAuthState } from '../../core/store/auth/auth.selectors'
import { State as AuthState } from '../../core/store/auth/auth.state.facade'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-digital-container',
  templateUrl: './digital.container.html',
})
export class DigitalContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  clientId$: Observable<string | null>
  year$: Observable<string | null>
  studyId$: Observable<string | null>
  authState$: Observable<AuthState>

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.clientId$ = this.store.pipe(select(fromBroker.selectCurrentClientID))
    this.year$ = this.store.pipe(select(fromBroker.selectCurrentYearID))
    this.studyId$ = this.store.pipe(select(fromBroker.selectCurrentStudyID))
    this.authState$ = this.store.pipe(select(selectAuthState))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }
}
