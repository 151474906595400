<ng-container *ngIf="minimizeProgram || minimizeGroupName">
  <app-group-tower-bar-edge
    *ngFor="let edge of leftEdges"
    class="edge left"
    [edge]="edge"
    side="left"
    transparent
  ></app-group-tower-bar-edge>
</ng-container>

<div class="bar">
  <div class="left">
        <span
          *ngIf="!hideShowTower && !minimizeGroupName && !selectMode"
          [matTooltip]="towerTooltip"
          matTooltipPosition="after"
        >
          <button appButtonIcon huge opaque (click)="onShowTowerClick()">
            {{ towerIcon }}
          </button>
        </span>

    <app-checkbox-select
      *ngIf="scenarios?.length && (!minimizeProgram || minimizeGroupName)"
      class="scenarios"
      accent
      [allowSelection]="true"
      [items]="scenarios"
      [selected]="scenariosSelected"
      [accent]="true"
      (selectChange)="scenarioOrOptimizationSelectChange.emit($event)"
    ></app-checkbox-select>

    <app-checkbox-select
      *ngIf="
            optimizations?.length && (!minimizeProgram || minimizeGroupName)
          "
      class="scenarios"
      accent
      [allowSelection]="true"
      [items]="optimizations"
      [selected]="optimizationsSelected"
      [accent]="true"
      (selectChange)="scenarioOrOptimizationSelectChange.emit($event)"
    ></app-checkbox-select>
    <div class="name" *ngIf="page === 'Compare'">
      <h4 class="line-clamp">
        {{ title }}
      </h4>
    </div>
  </div>
  <button
    *ngIf="!minimizeProgram && !selectMode"
    matTooltipPosition="before"
    matTooltip="Show/Hide Occurrence or Aggregate"
    appButtonIcon
    huge
    opaque
    [matMenuTriggerFor]="popoverMenu"
    [ngStyle]="{ visibility: programType ? 'hidden' : 'show' }"
  >
    swap_horiz
  </button>
  <mat-menu
    #popoverMenu="matMenu"
    yPosition="below"
    class="app-menu-no-max-width"
  >
    <mat-checkbox
      class="mat-menu-item"
      [checked]="showOcc"
      [disabled]="!showAgg || page === 'Compare'"
      (change)="onShowOccClick()"
    >Show Occurrence</mat-checkbox
    >
    <mat-checkbox
      class="mat-menu-item"
      [checked]="showAgg"
      [disabled]="!showOcc"
      (change)="onShowAggClick()"
    >Show Aggregate</mat-checkbox
    >
  </mat-menu>

  <h3 *ngIf="minimizeProgram || minimizeGroupName">
    {{ minimizeGroupName || name }}
  </h3>
</div>

<div class="right" *ngIf="hideShowTower && page === 'Compare'">
  <button
    appButtonIcon
    huge
    opaque
    class="icon"
    (click)="onRemoveClick($event)"
  >
    close
  </button>
</div>

<ng-container *ngIf="minimizeProgram || minimizeGroupName">
  <app-group-tower-bar-edge
    *ngFor="let edge of rightEdges"
    class="edge right"
    [edge]="edge"
    side="right"
    transparent
  ></app-group-tower-bar-edge>
</ng-container>
