<div class="wrapper">
  <div class="sidebar">
    <div class="back-button">
      <button appButton link accent big (click)="backToSelector()">
        ← Back to Selector
      </button>
    </div>
    <div class="contract-controls">
      <mat-form-field
        appearance="outline"
        matTooltip="{{ selectedContractName }}"
        matTooltipPosition="above"
        subscriptSizing="dynamic"
      >
        <mat-label class="contract-label">Select a Contract</mat-label>
        <mat-select
          [(value)]="selectedContractName"
          (selectionChange)="updateSelectedContract()"
        >
          <mat-option>Select a Contract</mat-option>
          <mat-option
            *ngFor="let contract of contracts"
            [value]="contract.pageSetName"
            >{{ contract.pageSetName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="create-delete-contract">
      <button
        appButton
        big
        accent
        border
        tabindex="-1"
        (click)="onNewContract()"
      >
        <span>+ Create Contract</span>
      </button>
      <i
        matTooltip="Delete Selected Contract"
        matTooltipPosition="after"
        class="material-icons delete-button"
        [ngClass]="deleteContractClass"
        (click)="onDeleteContract()"
      >
        delete
      </i>
    </div>
    <div class="signature-page-view-toggle">
      <mat-slide-toggle
        [checked]="showSignaturePageView"
        (change)="showSignaturePageView = !showSignaturePageView"
        >Signature Page View</mat-slide-toggle
      >
    </div>
  </div>
  <ng-container *ngIf="!selectedContract; then emptyTemplate"></ng-container>
  <ng-container
    *ngIf="
      selectedContract &&
        showSignaturePageView &&
        selectedContract.reinsurers.length > 0;
      then mainContentTemplate
    "
  ></ng-container>
  <ng-container
    *ngIf="
      selectedContract &&
        showSignaturePageView &&
        selectedContract.reinsurers.length <= 0;
      then noPagesTemplate
    "
  ></ng-container>
  <ng-container
    *ngIf="
      selectedContract && !showSignaturePageView;
      then simpleContentTemplate
    "
  ></ng-container>
</div>

<ng-template #mainContentTemplate>
  <div class="main-content">
    <div class="page-controls">
      <mat-form-field appearance="outline">
        <mat-label class="reinsurer-label">Select a Reinsurer</mat-label>
        <mat-select
          [value]="selectedReinsurer.reinsurerName"
          (selectionChange)="updateSelectedPage($event)"
          subscriptSizing="dynamic"
        >
          <mat-option>Select a Reinsurer</mat-option>
          <mat-option
            *ngFor="let page of selectedContract.reinsurers"
            [value]="page.reinsurerName"
            >{{ page.reinsurerName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <i
        matTooltip="Delete Reinsurer"
        matTooltipPosition="above"
        class="material-icons delete-button delete-reinsurer"
        [ngClass]="deleteReinsurerClass"
        (click)="onDeleteReinsurer()"
      >
        delete
      </i>
      <mat-paginator
        hidePageSize="true"
        showFirstLastButtons="true"
        [pageSize]="1"
        [length]="paginatorCount"
        [pageIndex]="currentPageIndex"
        (page)="paginatorChange($event)"
      >
      </mat-paginator>
      <div class="save-export">
        <!-- Temporarily remove update button due to prod issues: LKTN-10686, LKTN-10679, LKTN-10669 -->
        <!-- <button
          [disabled]="isSaving"
          appButton
          big
          accent
          border
          tabindex="-1"
          matTooltip="Reset/Refresh Assigned Lines for FOTs"
          (click)="resetSelectedContractAssignedLines()"
        >
          Update
        </button> -->
        <button
          [disabled]="isSaving || !isDirty"
          appButton
          big
          accent
          border
          tabindex="-1"
          [matTooltip]="dirtyContractString"
          (click)="saveChanges()"
        >
          <span *ngIf="isSaving"> Saving... </span>
          <span *ngIf="!isSaving"> Save </span>
        </button>
        <button
          [disabled]="isSaving || isExporting"
          appButton
          big
          accent
          border
          tabindex="-1"
          (click)="exportContract()"
        >
          <span *ngIf="isExporting"> Exporting... </span>
          <span *ngIf="!isExporting"> Export </span>
        </button>
        <!-- OT Submission Not Ready for testing yet -->
        <button
          *ngIf="otAllowed"
          [disabled]="isDirty"
          appButton
          big
          accent
          border
          tabindex="-1"
          (click)="submitToOTButtonClicked()"
        >
          Submit to OT...
        </button>
      </div>
    </div>
    <!--selectedReinsurer passes cedent data among other things to the signature form-->
    <app-signature-form
      class="form"
      [fotsToAdd]="fotsToAdd"
      [fotsToRemove]="fotsToRemove"
      [selectedReinsurer]="selectedReinsurer"
      [renewalRiskRefs]="renewalRiskRefs"
      [clients]="clients"
      (updateSignatureForm)="updateSignatureForm.emit($event)"
      (addLayerToSelectedContract)="addLayerToSelectedContract.emit($event)"
      (removeLayerFromSelectedContract)="
        removeLayerFromSelectedContract.emit($event)
      "
    >
    </app-signature-form>
  </div>
</ng-template>

<ng-template #simpleContentTemplate>
  <div class="main-content">
    <div class="page-controls">
      <div class="save-export">
        <button
          [disabled]="isSaving || !isDirty"
          appButton
          big
          accent
          border
          tabindex="-1"
          [matTooltip]="dirtyContractString"
          (click)="saveChanges()"
        >
          <span *ngIf="isSaving"> Saving... </span>
          <span *ngIf="!isSaving"> Save </span>
        </button>
        <!-- OT Submission Not Ready for testing yet -->
        <button
          *ngIf="otAllowed"
          [disabled]="isDirty"
          appButton
          big
          accent
          border
          tabindex="-1"
          (click)="submitToOTButtonClicked()"
        >
          Submit to OT...
        </button>
      </div>
    </div>
    <app-simple-contract
      class="form"
      [fotsToAdd]="fotsToAdd"
      [fotsToRemove]="fotsToRemove"
      [selectedReinsurer]="selectedReinsurer"
      [renewalRiskRefs]="renewalRiskRefs"
      [clients]="clients"
      (updateSignatureForm)="updateSignatureForm.emit($event)"
      (isCoRiskContractChangeEvent)="isCoRiskContractChangeEvent.emit($event)"
      (leadRiskRefChangeEvent)="leadRiskRefChangeEvent.emit($event)"
    >
    </app-simple-contract>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <label appInfoText info big>
    Select a Contract to Display a Signature Page
  </label>
</ng-template>

<ng-template #noPagesTemplate>
  <label appInfoText info big>
    This Contract Has No Signature Pages, Confirm Assigned Lines In Quote
  </label>
</ng-template>

<ng-template #loadingTemplate>
  <section>
    <label big appInfoText>Contracts Loading...</label>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </section>
</ng-template>
