import { Component, OnInit, OnDestroy } from '@angular/core'
import { interval, Subscription } from 'rxjs'
import { MatDialogRef } from '@angular/material/dialog'
import { AuthService } from './auth.service'

@Component({
  selector: 'app-timeout-dialog',
  styles: [
    `
           .timeout {
             width: 30vw;
             min-width: 300px;
           }
     
           button {
             background: white !important;
             color: black !important;
             width: 10vw;
             min-width: 140px;
           }
         `,
  ],
  template: `
    <div class="timeout">
      <h1 mat-dialog-title>Are you still there?</h1>
      <div mat-dialog-content>
        Your current SAGE session is about to expire. For your security, SAGE
        sessions automatically end after a period of inactivity. <br /><br />
        Your current session will expire in {{ timeLeft }} seconds.
      </div>
      <div mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close="Reset">
          Continue Session
        </button>
        <button mat-stroked-button mat-dialog-close="Sign Out">Sign Out</button>
      </div>
    </div>
  `,
})
export class TimeoutDialogComponent implements OnInit, OnDestroy {
  timeLeft = 60
  timerSubscription: Subscription
  constructor(
    private dialogRef: MatDialogRef<TimeoutDialogComponent>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timeLeft--
      if (this.timeLeft <= 0) {
        this.authService.signOut()
        this.dialogRef.close('signout')
      }
    })
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe()
  }
}
