import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Size } from '@shared/size.mixin'
import { CompareMetricValue } from '../../../model/compare-metrics.model'
import { NO_CATEGORY_NAME } from '../../../store/compare/compare-metric-settings/create-compare-metric-values'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metric-category-label',
  styleUrls: ['./compare-metric-category-label.component.scss'],
  templateUrl: './compare-metric-category-label.component.html',
})
export class CompareMetricCategoryLabelComponent {
  @Input() entitiesLength: number
  @Input() label: string
  @Input() values: CompareMetricValue[][]
  @Input() hasPortfolioTypes: boolean
  @Input() size: Size
  @Input() valueHeight?: string | number
  @Input() showMoreMenu: boolean
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() expandedChangeMetrics: Record<string, boolean>
  @Input() firstProgramLabel: string

  @Input() set collapsed(value: any) {
    this._collapsed = coerceBooleanProperty(value)
  }
  get collapsed() {
    return this._collapsed
  }
  _collapsed = false

  @Input() set short(value: any) {
    this._short = coerceBooleanProperty(value)
  }
  get short() {
    return this._short
  }
  _short = false

  @Output() collapseToggle = new EventEmitter<string>()
  @Output() ragToggle = new EventEmitter<CompareMetricValue>()
  @Output() changeToggle = new EventEmitter<CompareMetricValue>()

  @HostBinding('class.huge')
  get isSizeHuge(): boolean {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig(): boolean {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall(): boolean {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny(): boolean {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini(): boolean {
    return this.size === 'mini'
  }

  get displayLabel(): string {
    const label = this.label !== NO_CATEGORY_NAME ? this.label : 'General'
    return this.collapsed
      ? `${label} (${this.values.filter(v => v[0].show).length})`
      : label
  }

  get headerIcon(): string {
    return !this.collapsed ? 'expand_less' : 'expand_more'
  }

  get valueStyle(): {height: string } | {} {
    if (this.valueHeight) {
      const height = `${this.valueHeight}px`
      return { height }
    }
    return {}
  }

  getFirstLabel(value: CompareMetricValue[]): string {
    return (value && value[0] && value[0].label) || ''
  }

  onCollapseToggle($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    this.collapseToggle.emit(this.label)
  }

  onRAGToggle($event: MouseEvent | TouchEvent, rowValue: CompareMetricValue): void {
    $event.preventDefault()
    this.ragToggle.emit(rowValue)
  }

  onChangeToggle(
    $event: MouseEvent | TouchEvent,
    rowValue: CompareMetricValue
  ): void {
    $event.preventDefault()
    this.changeToggle.emit(rowValue)
  }

  trackByIDs(index: number, value: CompareMetricValue[] | undefined): string | number {
    return (value && value[0] && value[0].label) || index
  }

  isRankHidden(value: CompareMetricValue): boolean {
    const categoryLabel = value.category + '-' + value.label
    return (
      (this.hiddenMetricRanks && this.hiddenMetricRanks[categoryLabel]) || false
    )
  }

  showRAGToggleLabel(value: CompareMetricValue): string {
    return this.isRankHidden(value) ? 'Show Rank' : 'Hide Rank'
  }

  isChangeExpanded(value: CompareMetricValue): boolean {
    const categoryLabel = value.category + '-' + value.label
    return (
      (this.expandedChangeMetrics &&
        this.expandedChangeMetrics[categoryLabel] &&
        this.entitiesLength > 1) ||
      false
    )
  }

  showChangeToggleLabel(value: CompareMetricValue): string {
    return this.isChangeExpanded(value) ? 'Hide Change' : 'Show Change'
  }

  asTooltip(str: string): string {
    return str && str.length > 28 ? str : ''
  }
}
