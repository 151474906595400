<app-broker-header
  class="header"
  [broker]="broker"
  [title]="title"
  [sageLogicalRole]="sageLogicalRole"
></app-broker-header>
<app-broker-structures-container
  class="content"
  [title]="title"
  [clientSelect]="clientSelect"
  [selectedClientID]="selectedClientID"
></app-broker-structures-container>
<app-broker-overview
  *ngIf="clientSelect && selectedClientID != null"
  class="overview"
></app-broker-overview>
<img *ngIf="clientSelect" class="bg" src="assets/bg/lockton_R.png" alt="lockton" />
