import * as d3 from 'd3'
import { select, Selection } from 'd3-selection'
import {
  hexGraphingColorPalette,
  hexRevenueDashboardGraphingColorPalette,
} from './utils/graphing-color-palette'
import addLegend from './utils/chart-legend'
import addChartLabels from './utils/chart-labels'
import {
  formatCurrencyString,
  formatLongNumberLabel,
} from './utils/charts.util'
import { Tooltip } from '../revenue-dashboard/model/revenue-dashboard.model'
import { getDetailedForecastTooltipTitle } from '../revenue-dashboard/utils/revenue-dashboard.util'

export interface GroupBarChartOptions {
  chartLabel?: string
  xLabel?: string
  yLabel?: string
  metricPrimary?: string
  metricSecondary?: string
  metricTertiary?: string
  metricQuaternary?: string
  min: boolean
  percent: boolean
  revenueDashboard?: boolean
  revenueDashboardGraphType?: string
  isRevenueDashboardForecast?: boolean
  revenueDashboardForecastTooltipData?: Tooltip[]
  revenueDashboardDetailedForecastTooltipData?: Tooltip[]
  lightChartMode?: boolean
  multiColor?: boolean
  currencyFormat?: (n: number) => string
  threeWide?: boolean
}

export interface GroupBarDatum {
  groupBy: string
  values: number[]
}

export class GroupBarChart {
  private container: HTMLElement
  private data: GroupBarDatum[]
  private margin = { top: 50, right: 5, bottom: 75, left: 90 }
  private tooltipStyle = 'style="display:block;text-align:left;"'
  private options: GroupBarChartOptions

  constructor(container: HTMLElement, options: GroupBarChartOptions) {
    this.container = container
    this.options = options
    this.data = []
    select(container).selectAll('*').remove()
  }

  draw(data: GroupBarDatum[]): void {
    this.data = data
    this.setUpChart()
  }

  private setUpChart(): void {
    const lightChartMode = this.options.lightChartMode
    let margin = this.options.threeWide
      ? {
          ...this.margin,
          top: 75,
          bottom: 115,
        }
      : this.options.revenueDashboard
        ? { top: 70, right: 25, bottom: 125, left: 100 }
        : this.options.min
          ? this.margin
          : { top: 75, right: 25, bottom: 125, left: 100 }
    let width = this.container.clientWidth - margin.left * 2
    let height = this.container.clientHeight - margin.bottom * 2
    if (this.options.revenueDashboard) {
      width = this.container.clientWidth - margin.left - margin.right
      height = this.container.clientHeight - margin.top - margin.bottom
    }

    if (this.options.revenueDashboardGraphType === 'Global') {
      margin = { top: 110, right: 25, bottom: 125, left: 230 }
      width = width - 300
      height = height - 10
    } else if (
      this.options.revenueDashboardGraphType === 'International' ||
      this.options.revenueDashboardGraphType === 'US'
    ) {
      margin = { top: 50, right: 25, bottom: 125, left: 80 }
    }

    const data: GroupBarDatum[] = this.data.map(d => {
      if (this.options.revenueDashboard) {
        return {
          groupBy: d.groupBy,
          values: d.values,
        }
      } else {
        return {
          groupBy: d.groupBy,
          values: d.values.filter(v => v !== 0),
        }
      }
    })
    let color = d3.scaleOrdinal(
      hexGraphingColorPalette.filter(c => c !== 'body')
    )
    if (this.options.revenueDashboard) {
      if (
        this.options.revenueDashboardGraphType &&
        this.options.revenueDashboardGraphType !== 'ActualAndBudget' &&
        !this.options.isRevenueDashboardForecast
      ) {
        color = d3.scaleOrdinal(
          hexRevenueDashboardGraphingColorPalette.filter(c => c !== 'body')
        )
      } else {
        color = d3.scaleOrdinal(
          hexRevenueDashboardGraphingColorPalette.filter(
            c => c !== 'body' && c !== '#a9a9a9'
          )
        )
      }
    }
    const container = d3
      .select(this.container)
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow', 'display')
      .style('position', 'relative')
      .style('background', 'transparent')

    const chart = container
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.groupBy))
      .range([0, width])
      .padding(0.1)

    const yMax = Number(d3.max(data, d => d3.max(d.values)))
    const yScale = d3.scaleLinear().domain([0, yMax]).range([height, 0]).nice()

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(d =>
        this.options.revenueDashboard
          ? d.length > 20
            ? `${d.slice(0, 10)}...`
            : d
          : this.options.min
            ? ''
            : d.length > 20
              ? `${d.slice(0, 20)}...`
              : d
      )

    const yAxis = d3.axisLeft(yScale).tickFormat(formatLongNumberLabel)

    const tooltip:
      | Selection<HTMLDivElement, unknown, null, undefined>
      | undefined = select(this.container)
      .append('div')
      .style('opacity', 0)
      .style('transition', 'all 1s')
      .attr('class', 'custom-tooltip')

    chart
      .on('mouseover', event => {
        tooltip?.style('opacity', 1)
        select(event.currentTarget).style('opacity', 1)
      })
      .on('mousemove', event => {
        const [x, y] = d3.pointer(event)
        const lastIndex = data.length - 1
        const dIndex = Math.floor(x / (width / data.length))
        const percent = this.options.percent ? '%' : ''
        if (!data[dIndex]) {
          return
        }
        if (tooltip) {
          tooltip
            .html(
              `
                <div>
                  <div>
                    <strong>${
                      this.options.revenueDashboardForecastTooltipData &&
                      this.options.revenueDashboardForecastTooltipData.length >
                        0
                        ? data[dIndex].groupBy + ' Forecast'
                        : this.options
                              .revenueDashboardDetailedForecastTooltipData &&
                            this.options
                              .revenueDashboardDetailedForecastTooltipData
                              .length > 0
                          ? getDetailedForecastTooltipTitle(
                              data[dIndex].groupBy
                            )
                          : data[dIndex].groupBy
                    }</strong>
                  </div>
                  <div>
                  ${
                    this.options.revenueDashboardForecastTooltipData &&
                    this.options.revenueDashboardForecastTooltipData.length > 0
                      ? `${this.options.revenueDashboardForecastTooltipData[dIndex].forecastTooltipData}`
                      : this.options
                            .revenueDashboardDetailedForecastTooltipData &&
                          this.options
                            .revenueDashboardDetailedForecastTooltipData
                            .length > 0
                        ? `${this.options.revenueDashboardDetailedForecastTooltipData[dIndex].forecastTooltipData}`
                        : `<span ${
                            this.options.revenueDashboardGraphType
                              ? this.tooltipStyle
                              : ''
                          }>${this.options.metricPrimary ? this.options.metricPrimary + ': ' : ''}${
                            this.options.currencyFormat
                              ? this.options.currencyFormat(
                                  data[dIndex].values[0]
                                )
                              : formatCurrencyString(data[dIndex].values[0])
                          }${percent}</span>`
                  }
                  </div>
                  <div>
                  ${
                    this.options.revenueDashboardForecastTooltipData ||
                    this.options.revenueDashboardDetailedForecastTooltipData
                      ? ''
                      : this.options.metricSecondary
                        ? `<span ${
                            this.options.revenueDashboardGraphType
                              ? this.tooltipStyle
                              : ''
                          }>${
                            this.options.metricSecondary
                          }: ${formatCurrencyString(
                            data[dIndex].values[1]
                          )}${percent}</span>`
                        : ''
                  }
                  </div>
                  <div>
                  ${
                    this.options.metricTertiary
                      ? `<span ${
                          this.options.revenueDashboardGraphType
                            ? this.tooltipStyle
                            : ''
                        }>${
                          this.options.metricTertiary
                        }: ${formatCurrencyString(
                          data[dIndex].values[2]
                        )}${percent}</span>`
                      : ''
                  }
                  </div>
                  <div>
                  ${
                    this.options.metricQuaternary
                      ? `<span ${
                          this.options.revenueDashboardGraphType
                            ? this.tooltipStyle
                            : ''
                        }>${
                          this.options.metricQuaternary
                        }: ${formatCurrencyString(
                          data[dIndex].values[3]
                        )}${percent}</span>`
                      : ''
                  }
                  </div>
                </div>
              `
            )
            .style(
              'left',
              this.options.isRevenueDashboardForecast && dIndex === lastIndex
                ? `50px`
                : `${x - 55}px`
            )
            .style(
              'top',
              this.options.isRevenueDashboardForecast
                ? `${y - 150}px`
                : `${y - 15}px`
            )
        }
      })
      .on('mouseleave', () => {
        tooltip?.style('opacity', 0)
      })
    if (
      this.options.revenueDashboardGraphType !== 'Global' &&
      this.options.revenueDashboardGraphType !== 'International' &&
      this.options.revenueDashboardGraphType !== 'US'
    ) {
      chart
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis)
        .selectAll('text')
        .style('text-anchor', 'end')
        .style('text-transform', 'none')
        .style(
          'fill',
          this.options.revenueDashboard
            ? 'var(--body)'
            : lightChartMode
              ? 'black'
              : 'white'
        )
        .style(
          'font-size',
          this.options.threeWide
            ? '10px'
            : this.options.revenueDashboard
              ? '14px'
              : 'none'
        )
        .attr('dx', this.options.revenueDashboard ? '-1em' : '-.2em')
        .attr('dy', this.options.revenueDashboard ? '0.5em' : '.15em')
        .attr('transform', 'rotate(-30)')
    }

    if (!this.options.revenueDashboard) {
      chart.append('g').attr('class', 'y axis').call(yAxis)
    }

    const groupBars = chart
      .selectAll('.group-bar')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'group-bar')
      .attr('transform', d => `translate(${xScale(d.groupBy)}, 0)`)

    let barsLength = data[0].values.filter(v => v !== 0).length
    if (this.options.revenueDashboard) {
      barsLength = data[0].values.length
    }
    // We need to make sure barlength is not less than two as it messes up the group bar chart width.
    if (this.options.revenueDashboard && barsLength <= 1) {
      barsLength = 2
    }

    groupBars
      .selectAll('.bar')
      .data(d => d.values)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (_, i) => (xScale.bandwidth() / barsLength) * i)
      .attr('y', d =>
        this.options.revenueDashboard ? yScale(Math.max(0, d)) : yScale(d)
      )
      .attr('width', xScale.bandwidth() / barsLength)
      .attr('height', d =>
        this.options.revenueDashboard
          ? Math.abs(yScale(d) - yScale(0))
          : height - yScale(d)
      )
      .style('fill', (d, i) => {
        if (this.options.multiColor) {
          let index = 0
          data.forEach((x, j) => {
            if (x.values[0] === d) {
              index = j
            }
          })
          return color(index.toString())
        } else {
          return color(i.toString())
        }
      })

    chart
      .selectAll('text')
      .style(
        'fill',
        this.options.revenueDashboard
          ? 'var(--body)'
          : lightChartMode
            ? 'black'
            : 'white'
      )
    const withinBarLabelDislayLimit = data.length * data[0].values.length <= 32

    if (
      (!this.options.min && withinBarLabelDislayLimit) ||
      (this.options.revenueDashboard && withinBarLabelDislayLimit)
    ) {
      groupBars
        .selectAll('.bar-value')
        .data(d => d.values)
        .enter()
        .append('text')
        .attr('class', 'bar-value')
        .attr(
          'x',
          (_, i) =>
            (xScale.bandwidth() / barsLength) * i +
            xScale.bandwidth() / (barsLength * 2)
        )
        .attr(
          'y',
          d =>
            yScale(d) +
            (height - yScale(d)) / 2 +
            (height - yScale(d) > 14 ? 5 : -20)
        )
        .text(d =>
          this.options.revenueDashboard
            ? d >= 0
              ? d === 0
                ? ''
                : (Math.abs(Number(d)) / 1.0e6).toFixed(1)
              : '-' + (Math.abs(Number(d)) / 1.0e6).toFixed(1)
            : formatLongNumberLabel(d)
        )
        .style('text-anchor', 'middle')
        .style('fill', lightChartMode ? 'black' : 'var(--body)')
        .style(
          'font-size',
          this.options.threeWide
            ? '10px'
            : this.options.revenueDashboard
              ? '13px'
              : '12px'
        )
        .style('font-weight', '600')
    }
    addChartLabels(
      chart,
      this.options.xLabel ?? '',
      this.options.yLabel ?? '',
      this.options.chartLabel ?? '',
      margin,
      width,
      height,
      this.options.min,
      undefined,
      this.options.revenueDashboard,
      lightChartMode
    )
    if (
      (this.options.revenueDashboardGraphType &&
        this.options.revenueDashboardGraphType !== 'ActualAndBudget' &&
        this.options.revenueDashboardGraphType !== 'Global' &&
        this.options.revenueDashboardGraphType !== 'Period') ||
      this.options.yLabel === 'International'
    ) {
      this.options.revenueDashboard = false
    }
    if (!this.options.min || this.options.revenueDashboard) {
      const legendValues = [
        this.options.metricPrimary || '',
        this.options.metricSecondary || '',
        this.options.metricTertiary || '',
        this.options.metricQuaternary || '',
      ].filter(l => l !== '')
      addLegend(
        chart,
        this.container.clientWidth + 50,
        legendValues,
        color,
        this.options.revenueDashboard,
        this.options.revenueDashboardGraphType,
        this.options.isRevenueDashboardForecast,
        lightChartMode
      )
    }
  }
}
