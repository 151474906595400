import { Numeric } from 'd3'

export default function calculateCagr(
  fromValue: number | Numeric,
  fromDate: number | Numeric,
  toValue: number | Numeric,
  toDate: number | Numeric
): number {
  const from = fromValue.valueOf()
  const to = toValue.valueOf()
  if (from === 0 || to < 0) {
    return 0
  }

  // CAGR = ( to / from ) ^ (1 / years) - 1
  let base = to / from
  if (from < 0) {
    // base = (to - from + |from|) / |from|
    base = (to - 2 * Math.abs(from)) / Math.abs(from)
  }

  const toYear = (n: number | Numeric) => new Date(n.valueOf()).getFullYear()

  // NOTE: The number of years for growth is calculated differently than other
  // metrics. For growth metrics, 2019-2022 would count as 3 years (2019-2020,
  // 2020-2021, 2021-2022). Other metrics would count that as 4 years (2019,
  // 2020, 2021, 2022).
  const years = toYear(toDate) - toYear(fromDate)
  if (years === 0) {
    return 0
  }

  const exponent = 1 / years

  const cagr = Math.pow(base, exponent) - 1
  return isNaN(cagr) ? 0 : cagr
}
