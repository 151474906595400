import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { head } from 'ramda'
import { Observable, Subject, Subscription, of } from 'rxjs'
import { Client } from '../../core/model/client.model'
import { selectClients } from '../../core/store/clients.selectors'
import { setExploreProgram } from '../../analysis/explore/store/explore.actions'
import { extractPortfolioSetID } from '../../analysis/model/portfolio-set-id.util'
import { selectGrouperProgramGroupMembers } from '../../analysis/store/analysis.selectors'
import * as CompareActions from '../../analysis/store/compare/compare.actions'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import * as ProgramActions from '../../analysis/store/grouper/program/program.actions'
import * as AuthActions from '../../core/store/auth/auth.actions'
import {
  AccountOpportunity,
  FeatureFlag,
  StudyResponse,
} from '../../api/model/backend.model'
import { ClientYear } from '../../core/model/client.model'
import { Program } from '../../core/model/program.model'
import { Folder, Study } from '../../core/model/study.model'
import { AppState } from '../../core/store'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import {
  selectFeatureFlags,
  selectStudies,
} from '../../core/store/auth/auth.selectors'
import {
  fetchFolders,
  removedCheckedPrograms,
  setCurrentStructure,
  setStructureFilter,
  updateCheckedPrograms,
} from '../../core/store/broker/broker.actions'

import {
  selectCurrentClientID2,
  selectCurrentClientYears,
  selectCurrentClientYears2,
  selectCurrentStudy,
  selectCurrentStudyID,
  selectCurrentStudyID2,
  selectCurrentYearID,
  selectCurrentYearID2,
  selectCurrentYearStudies,
  selectCurrentYearStudies2,
  selectDesignProgramIDS,
  selectStructureFilter,
  selectStudyFolders,
} from '../../core/store/broker/broker.selectors'
import {
  setProgramNameAndDescription,
  StructureIndexEditEvent,
  StructureNameEditEvent,
  updateFolderID,
  updateLocalProgramIndexes,
  updateProgramIndex,
  updateProgramIndexes,
} from '../../core/store/program/program.actions'
import {
  selectCurrentStudyPrograms,
  selectCurrentStudyPrograms2,
} from '../../core/store/program/program.selectors'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { AutoBuildService } from '../../tier/auto-build.service'
import { UpdatePositionIndexesRequest } from '../../tier/tier.model'
import { BackendService } from '../../api/backend/backend.service'
import { takeUntil } from 'rxjs/operators'
import { setMetricTableSageViewChange } from 'src/app/analysis/store/metrics/metrics-cart/metrics-cart.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-broker-structures-container',
  templateUrl: './broker-structures.container.html',
})
export class BrokerStructuresContainerComponent implements OnInit, OnDestroy {
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null
  @Input() title: string
  @Input() analysisProfileID: string | null
  @Input() yearID: string | null
  @Input() selectedYearID: string | null
  @Input() selectedProgramGroupIDs: string[]

  @Output() programGroupAdd = new EventEmitter<ProgramGroup>()
  @Output() programGroupRemove = new EventEmitter<string>()
  @Output() programAdd = new EventEmitter<Program | Program[]>()
  @Output() programRemove = new EventEmitter<Program | Program[]>()
  @Output() groupFilterByAssocChange = new EventEmitter<boolean>()
  @Output() structureFilterChange = new EventEmitter<string | null>()

  _allowScenarioOrOptimizationSelection = false
  @Input() set allowScenarioOrOptimizationSelection(value: any) {
    this._allowScenarioOrOptimizationSelection = coerceBooleanProperty(value)
  }
  get allowScenarioOrOptimizationSelection() {
    return this._allowScenarioOrOptimizationSelection
  }
  clients$: Observable<Client[] | null>
  selectedClientID2$: Observable<string | null>
  selectedYearID$: Observable<string | null>
  selectedYearID2$: Observable<string | null>
  years$: Observable<readonly ClientYear[]>
  years2$: Observable<readonly ClientYear[]>
  selectedProgramID$: Observable<string | null>
  programs$: Observable<readonly Study[]>
  programs2$: Observable<readonly Study[]>
  structures$: Observable<readonly Program[]>
  structures2$: Observable<readonly Program[]>
  structureFilter$: Observable<string | null>
  structureGroupMembers$: Observable<ProgramGroupMember[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  programIDs$: Observable<readonly Program[]>
  folders$: Observable<Folder[] | null>
  studyID$: Observable<string | null>
  studyID2$: Observable<string | null>
  studies$: Observable<StudyResponse[] | null>
  featureFlags$: Observable<FeatureFlag[] | null>

  currentClients: Client[] | null
  executiveSummaryHidden = true
  clientData: string
  clientSubscription: Subscription

  onGroupFilterByAssocChange(value: boolean): void {
    this.groupFilterByAssocChange.emit(value)
  }
  showDialogFlag = false

  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private autobuildService: AutoBuildService,
    private backendService: BackendService
  ) {}

  ngOnInit(): void {
    this.structureGroupMembers$ = this.store.select(
      selectGrouperProgramGroupMembers
    )
    this.structures$ = this.store.select(selectCurrentStudyPrograms)
    this.structures2$ = this.store.select(selectCurrentStudyPrograms2)
    this.selectedClientID2$ = this.store.select(selectCurrentClientID2)
    this.selectedYearID$ = this.store.select(selectCurrentYearID)
    this.selectedYearID2$ = this.store.select(selectCurrentYearID2)
    this.years$ = this.store.select(selectCurrentClientYears)
    this.years2$ = this.store.select(selectCurrentClientYears2)
    this.selectedProgramID$ = this.store.select(selectCurrentStudyID)
    this.programs$ = this.store.select(selectCurrentYearStudies)
    this.programs2$ = this.store.select(selectCurrentYearStudies2)
    this.structureFilter$ = this.store.select(selectStructureFilter)
    this.accountOpportunities$ = this.store.select(selectAccountOpportunities)
    this.programIDs$ = this.store.select(selectDesignProgramIDS)
    this.studyID$ = this.store.select(selectCurrentStudyID)
    this.studyID2$ = this.store.select(selectCurrentStudyID2)
    this.studies$ = this.store.select(selectStudies)
    this.featureFlags$ = this.store.select(selectFeatureFlags)
    this.clients$ = this.store.select(selectClients)
    this.folders$ = this.store.select(selectStudyFolders)
    this.clientSubscription = this.clients$.subscribe(
      clients => (this.currentClients = clients)
    )
    this.store.select(selectCurrentStudy).subscribe(currentStudy => {
      let hideSummary = true
      if (currentStudy?.executive_summary) {
        hideSummary = false
        this.clientData = currentStudy.id
      }
      this.executiveSummaryHidden = hideSummary
    })

    this.selectedProgramID$.subscribe(selectedProgramId =>
      this.fetchFolders(selectedProgramId)
    )

    this.autobuildService.setBroker(this)
  }

  ngOnDestroy(): void {
    this.autobuildService.setBroker(undefined)
    this.clientSubscription.unsubscribe()

    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onButtonClick(_event: any): void {
    if (!this.showDialogFlag) {
      this.showDialogFlag = true
    } else {
      this.showDialogFlag = false
    }
  }

  fetchFolders(selectedProgramID: string | null): void {
    if (selectedProgramID) {
      this.store.dispatch(fetchFolders({ programId: selectedProgramID}))
    }
  }
  onStructureFilterChange2(value: string | null): void {
    this.structureFilterChange.emit(value)
  }

  onSageViewSelectionChange($event: {
    id: number
    updatedStudy: StudyResponse
  }): void {
    this.store.dispatch(
      AuthActions.updateStudySageView({
        study: $event.updatedStudy,
        id: $event.id,
      })
    )
    this.store.dispatch(setMetricTableSageViewChange({isSageViewUpdated : true}))
  }

  onStructureSelectionChange($event: Program): void {
    this.routeToStructure($event)
  }

  onScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<Program>
  ): void {
    const scenarioOrOptimization = head([
      ...($event.add ?? []),
      ...($event.remove ?? []),
    ])
    if (scenarioOrOptimization) {
      this.routeToStructure(scenarioOrOptimization)
    }
  }

  // TODO: remove 500 ms timeout
  onOpenInGroups(program: Program): void {
    this.router
      .navigate(['analysis', 'group'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(
            ProgramActions.addProgramToGroup({
              program: { ...program, checkGroup: false, checkGroupOcc: true },
            })
          )
        }, 500)
      })
  }

  onOpenInCompare(program: Program): void {
    this.router
      .navigate(['analysis', 'compare'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(
            CompareActions.addProgramToCompare({
              program: { ...program, checkCompare: false },
            })
          )
        }, 500)
      })
  }

  onOpenInExplore(program: Program): void {
    this.router
      .navigate(['analysis', 'explore'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(setExploreProgram({ program }))
          this.store.dispatch(setCurrentStructure({ id: program.id }))
        }, 500)
      })
  }

  onSetRoute(event: { page: string; program: Program }): void {
    this.router.navigate([this.router.url + '/analysis/' + event.page])
    switch (event.page) {
      case 'group':
        this.onOpenInGroups(event.program)
        break
      case 'compare':
        this.onOpenInCompare(event.program)
        break
      case 'explore':
        this.onOpenInExplore(event.program)
        break
    }
  }

  onStructureNameDescriptionEdit($event: StructureNameEditEvent): void {
    this.store.dispatch(setProgramNameAndDescription($event))
  }

  onStructureIndexEdit($event: StructureIndexEditEvent): void {
    this.store.dispatch(updateProgramIndex($event))
  }

  onUpdatePositionIndexes($event: UpdatePositionIndexesRequest): void {
    this.store.dispatch(updateProgramIndexes({ req: $event }))
    this.store.dispatch(updateLocalProgramIndexes({ indexes: $event.indexes }))
  }

  onUpdateStructureFolderID(event: {
    structureId: string
    folderID: string | null
  }): void {
    this.store.dispatch(updateFolderID(event))
  }

  onStructureFilterChange(filter: string): void {
    this.store.dispatch(setStructureFilter({ filter }))
  }

  onProgramGroupAdd(checkedItemIds: Program[]): void {
    this.store.dispatch(updateCheckedPrograms({ checkedItemIds }))
  }

  onProgramGroupRemove(programs: Program[]): void {
    this.store.dispatch(removedCheckedPrograms({ programs }))
  }

  private routeToStructure(structure: Program): void {
    const portfolioSetID = extractPortfolioSetID(structure)
    if (!portfolioSetID) {
      throw new Error(
        'Unexpected Error: Selected Structure has no Analysis Profile ID or Portfolio IDs.'
      )
    }
    const { analysisProfileID, ...portfolioIDs } = portfolioSetID
    this.router
      .navigate(
        [
          'structure',
          structure.id,
          'analysis',
          structure.analysisID,
          'portfolios',
          JSON.stringify(portfolioIDs),
        ],
        { relativeTo: this.route }
      )
      .then(() => {
        this.store.dispatch(setCurrentStructure({ id: structure.id }))
      })
  }
}
