import { Directive, DoCheck, Input } from '@angular/core'
import { MatSelect } from '@angular/material/select'

@Directive({
  selector: '[appBackdropClass]',
})
export class BackdropClassDirective implements DoCheck {
  @Input('appBackdropClass') backdropClass: string

  constructor(private _host: MatSelect) {}

  ngDoCheck(): void {
    // @ts-ignore
    if (this._host.overlayDir) {
      // @ts-ignore
      this._host.overlayDir.hasBackdrop = true
      // @ts-ignore
      this._host.overlayDir.backdropClass = this.backdropClass
    }
  }
}
