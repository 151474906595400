import {
  Compiler,
  Injectable,
  Injector,
  NgModuleFactory,
  Type,
} from '@angular/core'
import { PRICING_CURVE_XLSX_SERVICE_TOKEN } from './pricing-curve-xlsx.contract'
import { PricingCurveXlsxService } from './pricing-curve-xlsx.service'
import {
  PricingCurveExportOptions,
  PricingCurveExportPage,
} from './pricing-curve-export.model'

@Injectable({ providedIn: 'root' })
export class PricingCurveExportService {
  constructor(private injector: Injector, private compiler: Compiler) {}

  async export(
    fileName: string,
    options: PricingCurveExportOptions,
    pages: PricingCurveExportPage[],
    graphBase64?: string
  ) {
    const props: ExportProps = {
      fileName,
      options,
    }

    if (options.type === 'xlsx' && graphBase64) {
      return await this.exportXlsx(props, pages, graphBase64)
    }
  }

  private async exportXlsx(
    props: ExportProps,
    pages: PricingCurveExportPage[],
    graphBase64: string
  ) {
    const { PricingCurveXlsxModule } = await import(
      './pricing-curve-xlsx.module'
    )
    const factory = await this.loadModuleFactory(PricingCurveXlsxModule)
    const moduleRef = factory.create(this.injector)
    const injectToken = PRICING_CURVE_XLSX_SERVICE_TOKEN
    const service = moduleRef.injector.get<PricingCurveXlsxService>(injectToken)

    const { fileName, options } = props

    // Last minute changes to data
    service.export(fileName, pages, graphBase64, options)
  }

  private async loadModuleFactory<T>(t: Type<T>): Promise<NgModuleFactory<T>> {
    if (t instanceof NgModuleFactory) {
      return t
    } else {
      return await this.compiler.compileModuleAsync(t)
    }
  }
}

interface ExportProps {
  fileName: string
  options: PricingCurveExportOptions
}
