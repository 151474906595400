import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { map, switchMap, withLatestFrom, concatMap } from 'rxjs/operators'
import {
  concatMapWithInput,
  mergeApiResponses,
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from '../../../../api/util'
import { AppState } from '../../../../core/store'
import * as MetricActions from './metrics-cart.actions'
import { MetricSettingsService } from 'src/app/api/metric-settings/metric-settings.service'
import {
  selectCurrentStudyMetricTableSettings,
  selectMetricTableSettingsEntities,
} from '../../analysis.selectors'
import { MetricSettingsSaveChanges } from '../../../../api/metric-settings/metric-settings-response.model'
import { formatMetricSettingsFetch } from '../../../../api/metric-settings/metric-settings.converter'
import { upsertCompareMetricSettings } from '../../compare/compare-metric-settings/compare-metric-settings.actions'
import {
  buildMetricHash,
  getMetricSettingKey,
} from './create-metric-table-categories'
import { forkJoin, of } from 'rxjs'
import { ApiResponse } from 'src/app/api/model/api.model'
import { StudyMetricSettingsResponse } from 'src/app/api/metric-settings/metric-settings-response.model'

@Injectable()
export class CompareMetricTableEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private metricSettingsService: MetricSettingsService
  ) {}

  fetchTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.fetchMetricTableSettings),
      switchMapWithInput(action =>
        this.metricSettingsService.getMetricSettings(action.studyID, action.isCredit)
      ),
      rejectError(error =>
        this.store.dispatch(
          MetricActions.fetchMetricTableSettingsFailure({ error })
        )
      ),
      map(([settings, action]) => MetricActions.fetchMetricTableSettingsSuccess({
          settings: formatMetricSettingsFetch(settings as StudyMetricSettingsResponse[]),
          isCredit: action.isCredit
        })
      )
    )
  )

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.upsertMetricTableSettings),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStudyMetricTableSettings))
      ),
      map(([_, changes]) => {
        const uniquify = new Map<string, MetricSettingsSaveChanges>()
        changes.forEach(c => {
          uniquify.set(getMetricSettingKey(c), c)
        })
        return MetricActions.saveMetricTableSettings({
          changes: Array.from(uniquify.values()),
          isCredit: _.isCredit
        })
      })
    )
  )

  dirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MetricActions.updateMetricTableSettings),
      map(action => {
        return {
          saveID: action.metrics.saveID,
          metricSettingID: action.metrics.metricSettingID,
        }
      }),
      withLatestFrom(
        this.store.pipe(select(selectMetricTableSettingsEntities))
      ),
      map(([data, settings]) => {
        // If saveID is 0, we're using default metric settings - use metricSettingID instead
        const setting =
          data.saveID === 0
            ? // tslint:disable-next-line: no-non-null-assertion
              settings.find(s => s.metricSettingID === data.metricSettingID)!
            : // tslint:disable-next-line: no-non-null-assertion
              settings.find(s => s.saveID === data.saveID)!
        const newHash = buildMetricHash(setting)
        const dirty = newHash !== setting.hash
        return MetricActions.setDirty({ setting, dirty })
      })
    )
  })

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.saveMetricTableSettings),
      concatMapWithInput(action => {
        const deletedMetrics = action.changes.filter(m => m.deleted)
        const deletedResponses: ApiResponse<StudyMetricSettingsResponse>[] = []
        deletedMetrics.forEach(dm => {
            if(dm && dm.id){
              deletedResponses.push(
                // tslint:disable-next-line: no-non-null-assertion
                this.metricSettingsService.deleteMetricSetting(dm.id!.toString())
              )
            }
          }
        )
        return deletedResponses.length > 0
          ? forkJoin(deletedResponses).pipe(mergeApiResponses())
          : of({ data: [] })
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          MetricActions.saveMetricTableSettingsFailure({ error })
        )
      ),
      switchMapWithInput(([_, action]) => {
        const modifiedMetrics = action.changes.filter(m => !m.deleted)
        return this.metricSettingsService.saveMetricSettings(modifiedMetrics, action.isCredit)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          MetricActions.saveMetricTableSettingsFailure({ error })
        )
      ),
      concatMap(([studyMetricResponse, action]) => {
        const studyID = studyMetricResponse[0].study_id.toString()
        return [
          MetricActions.saveMetricTableSettingsSuccess({ studyID, isCredit: action[1].isCredit }),
          MetricActions.fetchMetricTableSettings({ studyID, isCredit: action[1].isCredit})
        ]
      })
    )
  )

  saveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.saveMetricTableSettingsSuccess),
      map(action => action.studyID),
      switchMap(studyID =>
        this.metricSettingsService.getMetricSettings(studyID)
      ),
      rejectError(error =>
        this.store.dispatch(
          MetricActions.fetchMetricTableSettingsFailure({ error })
        )
      ),
      map(res => formatMetricSettingsFetch(res)),
      map(settings => MetricActions.fetchMetricTableSettingsSuccess({ settings })),
      map(() => upsertCompareMetricSettings())
    )
  )

  enableAllMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.enableAllMetrics),
      withLatestFrom(
        this.store.pipe(select(selectMetricTableSettingsEntities))
      ),
      concatMap(([_, settings]) => {
        const actions: any[] = []
        settings.forEach(s => {
          if (!s.show) {
            actions.push(
              MetricActions.updateMetricTableSettings({
                show: true,
                weight: s.weight,
                year: s.year,
                portfolioType: s.portfolioType,
                vartvar: s.vartvar,
                aggregationMethodType: s.aggregationMethodType,
                perspective: s.perspective,
                lossFilter: s.lossFilter,
                label: s.label,
                metrics: s,
                spPremiumValue: s.spPremiumValue,
                spReserveValue: s.spReserveValue,
                spDivesificationValue: s.spDivesificationValue,
                spCatValue: s.spCatValue,
                deleted: s.deleted,
                formula: s.formula,
                valueType: s.valueType,
                ragOrder: s.ragOrder,
                credit_scenario: s.credit_scenario
              })
            )
          }
        })
        return actions
      })
    )
  )
}
