import { createAction, props } from '@ngrx/store'
import {
  BenchmarkExportOrientation,
  BenchmarkExportTypeDef,
} from '../export/benchmark-export.model'

export const ToggleBenchmarkExportMode = createAction(
  '[Benchmark Export] Toggle Mode'
)

export const SetBenchmarkExportType = createAction(
  '[Benchmark Export] Set Type',
  props<{ value: BenchmarkExportTypeDef }>()
)

export const SetBenchmarkIncludingChart = createAction(
  '[Benchmark Export] Set Including Chart',
  props<{ value: boolean }>()
)

export const SetBenchmarkExporting = createAction(
  '[Benchmark Export] Set Exporting',
  props<{ value: boolean }>()
)

export const SetBenchmarkExportShowLegend = createAction(
  '[Benchmark Export] Set Show Legend',
  props<{ value: boolean }>()
)

export const SetBenchmarkExportShowHeader = createAction(
  '[Benchmark Export] Set Show Header',
  props<{ value: boolean }>()
)

export const SetBenchmarkExportDarkMode = createAction(
  '[Benchmark Export] Set Dark Mode',
  props<{ value: boolean }>()
)

export const SetBenchmarkExportOrientation = createAction(
  '[Benchmark Export] Set Orientation',
  props<{ value: BenchmarkExportOrientation }>()
)

export const SetBenchmarkExportFirstPageFlag = createAction(
  '[Benchmark Export] Set Export First Page Flag',
  props<{ value: boolean }>()
)
