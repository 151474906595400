<h1 mat-dialog-title>Top & Drop Properties</h1>
<mat-dialog-content>
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="multi-sections-list"
  >
    <app-top-drop-section-card
      #sectionCards
      [layer]="topLayer"
      [currentCurrency]="currentCurrency"
      (physicalLayerChange)="this.physicalLayerChange.emit($event)"
      (layerChange)="this.layerChange.emit($event)"
      title="Top"
      cdkDrag
      cdkDragDisabled="true"
    >
    </app-top-drop-section-card>

    <!-- * Drop Layers * -->
    <app-top-drop-section-card
      #sectionCards
      *ngFor="let dropLayer of dropLayers"
      [layer]="dropLayer"
      [currentCurrency]="currentCurrency"
      [disableDelete]="dropLayers.length === 1"
      (physicalLayerChange)="this.physicalLayerChange.emit($event)"
      (layerChange)="this.layerChange.emit($event)"
      (deleteDropLayer)="this.deleteDropLayer.emit($event)"
      title="Drop"
      cdkDrag
      cdkDragDisabled="true"
    >
    </app-top-drop-section-card>

    <app-top-drop-section-card
      #sectionCards
      [layer]="hiddenLayer"
      [currentCurrency]="currentCurrency"
      (physicalLayerChange)="this.physicalLayerChange.emit($event)"
      title="Combined"
      cdkDrag
      cdkDragDisabled="true"
    >
    </app-top-drop-section-card>
  </div>

  <mat-progress-bar
    *ngIf="isNewDropLayerSaving"
    style="margin-top: 8px"
    mode="indeterminate"
    color="accent"
  ></mat-progress-bar>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    #saveAndClose
    appButton
    accent
    cdkFocusInitial
    (click)="onSaveOrCreate()"
    [disabled]="isDisabled"
  >
    Save and close
  </button>
  <button appButton link mat-dialog-close 
   [matTooltip]="!isDialogDisabled ? '' : 'Clicking cancel on new unsaved layer will permanently delete them. Please save the layer first.'">Cancel
  </button>

  <span class="flex-spacer"></span>

  <button
    mat-mini-fab
    accent
    [disabled]="isNewDropLayerSaving"
    (click)="onCreateDropLayer()"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-dialog-actions>
