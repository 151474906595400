export type BasicControl = {
  name: string
  columnName: string
  value?: string
  minValue?: string
  maxValue?: string
  defaultValue?: string
}

export type PredictionsResponse = {
  coefficient_pairs: {
    pAttach: number
    pDetach: number
    mi_attach: number
    mi_detach: number
    expectedLossOnLine: number
    mi_thickness: number
    oltv: number
    fico: number
    dti: number
  }
  intercept: number
  predictions: number[]
  r_squared: number
}
export interface PricingCurveLayerString {
  percentage: number
  value: number
  id: number
  pc_name: string
}

export type PricingCurveStatus = {
  isLoading: boolean
  dialogLoading: boolean
  isExporting: boolean
  isSavingLayers: boolean
}

export type PricingCurveGraphSettings = {
  xAxisDefinition: PricingCurveAxisDefinition
  yAxisDefinition: PricingCurveAxisDefinition
}

export type PricingCurveAxisDefinition = {
  label: string
  path: string
}

export interface DialogData {
  editMode: boolean
  tab: PricingCurveAddDataDialogTabs
  savedCurves: SavedPricingCurveEntry[]
  previouslyClickedId: {
    pricingCurveId?: number
    layerId?: number
  },
  creditCarriers?: string[]
}

export interface SelectedTransaction {
  dealName: string,
  pricingCurveId: number
}

export type PricingCurveAddDataDialogTabs = 'layers' | 'manual' | 'saved'

export type CreditCurvePredictionColumn = {
  key: string
  label: string
  isActive: boolean
  calcColumnName: string
  calcColumnInputName: string
}

export type CreditCurvePredictionColumns = {
  pAttach: CreditCurvePredictionColumn
  pdetach: CreditCurvePredictionColumn
  miAttach: CreditCurvePredictionColumn
  miDetach: CreditCurvePredictionColumn
  expectedLossOnLine: CreditCurvePredictionColumn
  oltv: CreditCurvePredictionColumn
  fico: CreditCurvePredictionColumn
  dti: CreditCurvePredictionColumn
  mi_thickness: CreditCurvePredictionColumn
}

export type PricingCurveData = {
  id: number
  label: string
  curveType: PricingCurveTypes
  layers: PricingCurveLayer[]
  creditLayers: CreditCurveLayer[]
  layerSplitView: boolean
  selectors: Selectors | null
  dateIntervals: BasicControl[] | null
  creditSelectors: CreditSelectors | null
  technicalFactors: TechnicalFactors | null
  graphColorClass: string
  graphColorRgb: string | undefined
  visibilityOptions: PricingCurveVisiblityOptions
  cardIndex: number
  isEdit: boolean
  initialSetup: boolean
  isManual: boolean
  includedLayerIds?: string[]
  context: PricingCurveContextTypes
  scaleFactor: number | null
  minimumPremium: number | null
  creditPredictionColumns?: CreditCurvePredictionColumns
  creditPredictions?: PredictionsResponse
  creditCarriers?: string[]
}

export interface IFilter {
  filterId: string
  newValue: string
}

export interface IIntervalFilter {
  filterId: string
  newMinValue?: string
  newMaxValue?: string
}

export interface IControl {
  id: string
  values: string[]
  allValues: string[]
  selectedValues: string[]
  name: string
  columnName: string
  defaultValues?: string[]
}

export interface PCClass {
  pcClass: string
  pcSubClass: string
}

export interface LayerMapping {
  id: string
  mappings: string[]
}

export interface LayerPaletteView {
  id: string
  name: string
}

export interface LayerPaletteItem {
  id: string
  name: string
}

export interface Selectors {
  layerCategory: IControl
  clientName: IControl
  reinstatement: IControl
  reinsurerName: IControl
  status: IControl
  ignoreForPricingCurve: IControl
  territory: IControl
  placedThrough: IControl
  correspondentBroker: IControl
  lossImpactedFromPrevYear: IControl
  perils: IControl
  pcClass: IControl
  pcSubClass: IControl
}

export interface CreditSelectors {
  organization: IControl
  dealType: IControl
  highLowLTV: IControl
  dealName: IControl
  tranche: IControl
  year: IControl
}

export interface SavedCurveSelectorsDto {
  layerCategory: string[]
  pcClass: string[]
  pcSubClass: string[]
  fullName: string[]
}

export interface SavedCurveSelectors {
  layerCategory: IControl
  fullName: IControl
  pcClass: IControl
  pcSubClass: IControl
  updateDate: BasicControl
}

export type CurveTypeOption = {
  label: string
  value: PricingCurveTypes
}

export type CombinedSelectors = {
  selectors: Selectors
  dateIntervals: BasicControl[]
}

export interface PricingCurveDataPoint {
  id: number
  x: number
  y: number
  isLine: boolean
}

export interface PricingCurveDatum extends PricingCurveDataPoint {
  label: string
  datasetId: number // The id of the dataset that the datum belongs to
  selectedTransactionMember?: boolean
  dealName?: string
  clientName?: string
  layerName?: string
  layerDesc?: string
  pAttach?: string
  pDetach?: string
}

export type PricingCurveExtent = {
  min: number
  max: number
}

export type PricingCurveExtents = {
  xExtents: PricingCurveExtent
  yExtents: PricingCurveExtent
}

export type LayerResponse = {
  aggregateAttachment: number
  aggregateLimit: number
  aggregatedRipPct: string
  cessionPercentage: number
  clientName: string | null
  correspondentBroker: string | null
  el: number
  hist_data_id: number | null
  ignoreForPricingCurve: 'Y' | 'N'
  inceptionDate: string
  include: boolean
  layerCategory: string | null
  layerCurrency: string | null
  layerDesc: string
  layerName: string | null
  layerType: string
  lossImpactedFromPrevYear: 'Y' | 'N'
  occurrenceAttachment: number
  occurrenceLimit: number
  pcClass: string
  pcSubClass: string
  perils: string
  placedThrough: string
  prob_of_attach: number
  prob_of_exhaust: number
  programName: string | null
  quoteExpectedCededLoss: number | null
  quoteExpectedCededPremium: number | null
  ral_id: number | null
  reinsurerName: string | null
  riskAttachment: number | null
  riskLimit: number | null
  rr_id: number | null
  status: string
  structureName: string | null
  territory: string
  trol: number
  vendor: string
  writtenPercent: number | null
  year: number | null
}

export type LayerProperties = Omit<
  LayerResponse,
  'id' | 'el' | 'trol' | 'include'
>

export type CreditCurveLayer = {
  trancheId: number
  organization: string
  carrier: string | null
  dealType: string
  highLowLTV: string
  dealName: string
  tranche: string | null
  year: number
  attach: number
  detach: number
  premium: number | null
  prediction?: number
  pAttach: number | null
  pDetach: number | null
  expectedLossOnLine: number | null
  expectedLoss: number | null
  limit: number
  miThickness: number | null
  miAttach: number
  miDetach: number
  midpoint: number
  loanCount: number
  oltv: number
  cltv: number
  fico: number
  dti: number
  include: boolean
}

export type PricingCurveLayer = {
  id: number
  el: number
  trol: number
  include: boolean
  props: LayerProperties
}

export interface SavedPricingCurveEntry {
  id: number
  pc_name: string
  techFactors: TechnicalFactors | undefined
  included_excluded_layers?: string[]
  active_filters?: CombinedSelectors
  audit_update_dt: string
  audit_insert_dt: string
  audit_created_by: string
  audit_updated_by: string
  fullName: string
}

export interface TechnicalFactors {
  expected_loss_multiplier: number
  expected_loss_power: number
  volatility_multiplier: number
  volatility_metric: string
  fixed_cost: number
  minimum_rate_on_line: number
  maximum_rate_on_line: number
  reinsurer_margin_percentage: number
  max_ceding_commission_percentage: number
}

export type TechnicalFactorsWithWeight = {
  techFactors: TechnicalFactors
  weight: number
}

export type PricingCurveVisiblityOptions = {
  layersVisible: boolean
  lineVisible: boolean
}

export type ZoomProperties = {
  x: number
  y: number
  zoomFactor: number
}

export type PricingCurveDefaultTechFactors = TechnicalFactors & { id?: number }

export type SubmitPricingCurvePayload = {
  data: PricingCurveData
  save: boolean
}

export enum PricingCurveTypes {
  EL_POWER = 'elPower',
  EL_LINEAR = 'elLinear',
  LAYER_SET = 'layerSet',
}

export const CREDIT_CURVE_X_DEFINITIONS: PricingCurveAxisDefinition[] = [
  {
    label: 'Premium',
    path: 'premium',
  },
  {
    label: 'DTI',
    path: 'dti',
  },
  {
    label: 'Expected Loss on Line',
    path: 'expectedLossOnLine',
  },
  {
    label: 'FICO',
    path: 'fico',
  },
  {
    label: 'MI Attach',
    path: 'miAttach',
  },
  {
    label: 'MI Detach',
    path: 'miDetach',
  },
  {
    label: 'OLTV',
    path: 'oltv',
  },
  {
    label: 'Probability of Attach',
    path: 'pAttach',
  },
  {
    label: 'Probability of Detach',
    path: 'pDetach',
  },
  {
    label: 'Midpoint',
    path: 'midpoint',
  },
  {
    label: 'MI Thickness',
    path: 'miThickness',
  },
]

export const CREDIT_CURVE_Y_DEFINITIONS: PricingCurveAxisDefinition[] = [
  {
    label: 'Premium',
    path: 'premium',
  },
  {
    label: 'Predicted Premium',
    path: 'prediction',
  },
]

export const PRICING_CURVE_X_DEFINITION: PricingCurveAxisDefinition = {
  label: 'EL',
  path: 'el',
}

export const PRICING_CURVE_Y_DEFINITION: PricingCurveAxisDefinition = {
  label: 'TROL',
  path: 'trol',
}

export const PRICING_CURVE_TYPE_OPTIONS: CurveTypeOption[] = [
  {
    label: 'EL Power Curve',
    value: PricingCurveTypes.EL_POWER,
  },
  {
    label: 'EL Linear',
    value: PricingCurveTypes.EL_LINEAR,
  },
]

export const CREDIT_PREDICTION_COLUMNS: CreditCurvePredictionColumns = {
  dti: {
    key: 'DTI', // Key matches value API needs
    label: 'DTI',
    isActive: true,
    calcColumnName: 'dtiConst',
    calcColumnInputName: 'inputDTI',
  },
  expectedLossOnLine: {
    key: 'ExpectedLossOnLine',
    label: 'Expected Loss on Line',
    isActive: true,
    calcColumnName: 'eLoLConst',
    calcColumnInputName: 'inputELoL',
  },
  fico: {
    key: 'FICO',
    label: 'FICO',
    isActive: true,
    calcColumnName: 'ficoConst',
    calcColumnInputName: 'inputFICO',
  },
  miAttach: {
    key: 'MI_attach',
    label: 'MI Attach',
    isActive: true,
    calcColumnName: 'miAttachConst',
    calcColumnInputName: 'inputMIAttach',
  },
  miDetach: {
    key: 'MI_detach',
    label: 'MI Detach',
    isActive: true,
    calcColumnName: 'miDetachConst',
    calcColumnInputName: 'inputMIDetach',
  },
  mi_thickness: {
    key: 'MI_Thickness',
    label: 'MI Thickness',
    isActive: true,
    calcColumnName: 'miThicknessConst',
    calcColumnInputName: 'inputMiThickness',
  },
  oltv: {
    key: 'OLTV',
    label: 'OLTV',
    isActive: true,
    calcColumnName: 'oltvConst',
    calcColumnInputName: 'inputOLTV',
  },
  pAttach: {
    key: 'Pattach',
    label: 'Probability of Attach',
    isActive: true,
    calcColumnName: 'pAttachConst',
    calcColumnInputName: 'inputPAttach',
  },
  pdetach: {
    key: 'Pdetach',
    label: 'Probability of Detach',
    isActive: true,
    calcColumnName: 'pDetachConst',
    calcColumnInputName: 'inputPDetach',
  },
}

export const LAYER_ID_MAPPINGS: LayerMapping[] = [
  {
    id: '1',
    mappings: [
      'cat_xl',
      'cat_ca',
      'cat_fhcf',
      'cat_ag',
      'cat_multisection',
      'noncat_xl',
      'noncat_indxl',
      'noncat_ag',
      'noncat_risk',
      'noncat_swing',
    ],
  },
  {
    id: '2',
    mappings: ['noncat_xl', 'noncat_ag', 'ahl_xl', 'ahl_swing'],
  },
  {
    id: '3',
    mappings: ['cat_xl', 'cat_ca', 'cat_fhcf', 'cat_ag', 'cat_multisection'],
  },
  {
    id: '4',
    mappings: [
      'noncat_xl',
      'noncat_indxl',
      'noncat_ag',
      'noncat_risk',
      'noncat_swing',
    ],
  },
]

export const LAYER_PALETTE_VIEWS: LayerPaletteView[] = [
  { id: '1', name: '' },
  { id: '2', name: 'AHL' },
  { id: '3', name: 'Property' },
  { id: '4', name: 'Casualty & Specialty' },
]

export const LAYER_PALETTE: LayerPaletteItem[] = [
  {
    id: 'cat_xl',
    name: 'Excess of Loss',
  },
  {
    id: 'cat_ca',
    name: 'Cascading',
  },
  {
    id: 'drop',
    name: 'Drop',
  },
  {
    id: 'cat_fhcf',
    name: 'FHCF',
  },
  {
    id: 'cat_ag',
    name: 'Aggregate Feeder',
  },
  {
    id: 'cat_ag',
    name: 'Aggregate',
  },
  {
    id: 'cat_multisection',
    name: 'Multi-Section',
  },
  {
    id: 'noncat_xl',
    name: 'Excess of Loss',
  },
  {
    id: 'ahl_xl',
    name: 'Excess of Loss',
  },
  {
    id: 'noncat_indxl',
    name: 'IndeXLoss',
  },
  {
    id: 'noncat_ag',
    name: 'Aggregate Feeder',
  },
  {
    id: 'noncat_ag',
    name: 'Aggregate',
  },
  {
    id: 'noncat_risk',
    name: 'Risk XOL',
  },
  {
    id: 'noncat_swing',
    name: 'Swing-rated',
  },
  {
    id: 'noncat_multisection',
    name: 'Multi-Section',
  },
]

export type PricingCurveContextTypes =
  | 'pricing-curve'
  | 'credit'
  | 'technical-premium'

export const DATE_INTERVALS_INITIAL_STATE: BasicControl[] = [
  {
    name: 'Inception Date',
    columnName: 'inceptionDate',
    minValue: '01-01-2023',
    maxValue: '',
  },
]

export const SELECTORS_INITIAL_STATE: Selectors = {
  layerCategory: {
    columnName: 'layerCategory',
    name: 'layerCategory',
    id: 'layerCategory',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  clientName: {
    columnName: 'clientName',
    name: 'clientName',
    id: 'clientName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  reinstatement: {
    columnName: 'reinstatement',
    name: 'reinstatement',
    id: 'reinstatement',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  reinsurerName: {
    columnName: 'reinsurerName',
    name: 'reinsurerName',
    id: 'reinsurerName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  status: {
    columnName: 'status',
    name: 'status',
    id: 'status',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  ignoreForPricingCurve: {
    columnName: 'ignoreForPricingCurve',
    name: 'ignoreForPricingCurve',
    id: 'ignoreForPricingCurve',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  territory: {
    columnName: 'territory',
    name: 'territory',
    id: 'territory',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  placedThrough: {
    columnName: 'placedThrough',
    name: 'placedThrough',
    id: 'placedThrough',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  perils: {
    columnName: 'perils',
    name: 'perils',
    id: 'perils',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  correspondentBroker: {
    columnName: 'correspondentBroker',
    name: 'correspondentBroker',
    id: 'correspondentBroker',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  lossImpactedFromPrevYear: {
    columnName: 'lossImpactedFromPrevYear',
    name: 'lossImpactedFromPrevYear',
    id: 'lossImpactedFromPrevYear',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  pcClass: {
    columnName: 'pcClass',
    name: 'pcClass',
    id: 'pcClass',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  pcSubClass: {
    columnName: 'pcSubClass',
    name: 'pcSubClass',
    id: 'pcSubClass',
    selectedValues: [],
    allValues: [],
    values: [],
  },
}

export const CREDIT_SELECTORS_INITIAL_STATE: CreditSelectors = {
  dealName: {
    columnName: 'dealName',
    name: 'dealName',
    id: 'dealName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  dealType: {
    columnName: 'dealType',
    name: 'dealType',
    id: 'dealType',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  highLowLTV: {
    columnName: 'highLowLTV',
    name: 'highLowLTV',
    id: 'highLowLTV',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  organization: {
    columnName: 'organization',
    name: 'organization',
    id: 'organization',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  tranche: {
    columnName: 'tranche',
    name: 'tranche',
    id: 'tranche',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  year: {
    columnName: 'year',
    name: 'year',
    id: 'year',
    selectedValues: [],
    allValues: [],
    values: [],
  },
}

export const SAVED_CURVE_SELECTOR_INITIAL_STATE: SavedCurveSelectors = {
  layerCategory: {
    columnName: 'layerCategory',
    name: 'layerCategory',
    id: 'layerCategory',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  fullName: {
    columnName: 'fullName',
    name: 'fullName',
    id: 'fullName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  pcClass: {
    columnName: 'pcClass',
    name: 'pcClass',
    id: 'pcClass',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  pcSubClass: {
    columnName: 'pcSubClass',
    name: 'pcSubClass',
    id: 'pcSubClass',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  updateDate: {
    columnName: 'updateDate',
    name: 'updateDate',
  },
}

export const PRICING_CURVE_STATUS_DEFAULT_STATE: PricingCurveStatus = {
  isLoading: true,
  dialogLoading: true,
  isExporting: false,
  isSavingLayers: false,
}

export const TECHNICAL_FACTORS_DEFAULT_STATE: TechnicalFactors = {
  expected_loss_multiplier: 1.4,
  expected_loss_power: 1.5,
  volatility_multiplier: 0.05,
  volatility_metric: 'Ceded Standard Deviation',
  fixed_cost: 0.01,
  minimum_rate_on_line: 0.04,
  maximum_rate_on_line: 1,
  reinsurer_margin_percentage: 0.1,
  max_ceding_commission_percentage: 0.35,
}

export const BLANK_CURVE: PricingCurveData = {
  id: -Infinity,
  label: '',
  curveType: PricingCurveTypes.EL_LINEAR,
  layers: [],
  creditLayers: [],
  layerSplitView: true,
  selectors: SELECTORS_INITIAL_STATE,
  dateIntervals: DATE_INTERVALS_INITIAL_STATE,
  creditSelectors: CREDIT_SELECTORS_INITIAL_STATE,
  technicalFactors: TECHNICAL_FACTORS_DEFAULT_STATE,
  visibilityOptions: {
    layersVisible: true,
    lineVisible: true,
  },
  cardIndex: -1,
  graphColorClass: '',
  graphColorRgb: undefined,
  initialSetup: false,
  isEdit: false,
  isManual: false,
  context: 'pricing-curve',
  minimumPremium: 0,
  scaleFactor: 1,
  creditPredictionColumns: CREDIT_PREDICTION_COLUMNS,
}

export const DIALOG_DATA_INITIAL_STATE: DialogData = {
  editMode: false,
  tab: 'layers',
  savedCurves: [],
  previouslyClickedId: {
    pricingCurveId: undefined,
    layerId: undefined,
  },
}

export const GRAPH_SETTINGS_INITIAL_STATE: PricingCurveGraphSettings = {
  xAxisDefinition: PRICING_CURVE_X_DEFINITION,
  yAxisDefinition: PRICING_CURVE_Y_DEFINITION,
}
