import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core'
import { Folder } from 'src/app/core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-structure-folder',
  styleUrls: ['./structure-folder.component.scss'],
  templateUrl: './structure-folder.component.html',
})
export class StructureFolderComponent implements OnInit {
  folderName: string
  structureCount: string
  folderID: string

  @Input() folder: Folder
  @Input() editMode: boolean

  @Output() deleteFolder = new EventEmitter<{ folderID: string }>()
  @Output() editFolderName = new EventEmitter<{
    folderID: string
    name: string
  }>()
  @Output() folderClick = new EventEmitter<{ folderID: string }>()

  ngOnInit() {
    this.folderName = this.folder.name
    this.structureCount = `${
      this.folder.programIDs ? String(this.folder.programIDs.length) : '0'
    } structures`
    this.folderID = this.folder.id
  }

  handleDeleteClick(): void {
    this.deleteFolder.emit({ folderID: this.folderID })
  }

  handleEditFolderNameClick(): void {
    this.editFolderName.emit({ folderID: this.folderID, name: this.folderName })
  }

  handleFolderClick(): void {
    this.folderClick.emit({ folderID: this.folderID })
  }
}
