import { Row, Workbook, Worksheet } from 'exceljs'
import { QuoteExcelUtils } from './quote-excel.utils'
import { ExcelExportService } from '@shared/services/excel-export.service'
import { clone } from 'ramda'
import { DatePipe, getCurrencySymbol } from '@angular/common'
import {
  Currency,
  Fills,
  Fonts,
  QuoteExcelParams,
  QuoteExportModes,
  QuoteExportRowValue,
} from './quote-excel.model'

// Class that Defines the "Quotes" Worksheet for Quote Export
export class QuoteExcelWorksheet {
  isDefaultExport: boolean
  private maxRows: number
  private sectionCurrencySymbols: string[]
  private longestExpiring: number

  constructor(
    public workbook: Workbook,
    public quoteParams: QuoteExcelParams,
    public titleName: string | undefined,
    public effectiveDate: string,
    public dateAsOf: string,
    public datePipe: DatePipe,
    public exportService: ExcelExportService,
    public utils: QuoteExcelUtils
  ) {
    this.titleName = this.quoteParams.oppName
      ? this.quoteParams.oppName
      : this.quoteParams.clientName
    let effDate = ''
    if (this.quoteParams.oppDate) {
      const parts = this.quoteParams.oppDate.split('-')
      effDate =
        this.datePipe.transform(
          new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10)
          ).toString(),
          'longDate'
        ) || ''
    }
    this.effectiveDate = 'Effective: ' + effDate
    this.dateAsOf = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    this.sectionCurrencySymbols = this.quoteParams.sectionCurrencies.map(
      currency => getCurrencySymbol(currency ?? 'USD', 'narrow')
    )
    this.isDefaultExport =
      this.quoteParams.exportMode === QuoteExportModes.DEFAULT
    this.longestExpiring = this.utils.findLongestArrayLength(
      this.quoteParams.expiringReinsurerColumns
    )
  }

  public appendTitleRowsToSheet(
    worksheet: Worksheet,
    sheetTitle: string
  ): void {
    this.exportService.appendDataRow(worksheet, [this.titleName], {
      font: Fonts.Segoe_14_Blue,
    })

    const subtitleText = this.quoteParams.programName + ' Placement'
    this.exportService.appendDataRow(worksheet, [subtitleText], {
      font: Fonts.Segoe_8,
    })

    this.exportService.appendDataRow(worksheet, [this.effectiveDate], {
      font: Fonts.Segoe_8_Gray,
    })

    this.exportService.appendBlankRows(worksheet)

    this.exportService.appendDataRow(worksheet, [sheetTitle], {
      font: Fonts.Segoe_14_Blue,
    })

    this.exportService.appendDataRow(worksheet, [this.dateAsOf], {
      font: Fonts.Segoe_8_Gray,
    })
  }

  public addQuoteWorksheet() {
    const worksheet = this.exportService.appendWorksheet(
      this.workbook,
      'Quotes'
    )
    this.appendTitleRowsToSheet(worksheet, 'Market Quote Summary')

    let reinsurerNames: string[] = []
    if (
      this.quoteParams.structureGroupName ||
      this.quoteParams.sharedLimitName
    ) {
      const nonFotAndExpiringReNames =
        this.quoteParams.nonFotAndExpiringColumns[0].slice(
          0,
          this.quoteParams.nonFotAndExpiringColumns[0].length - 2
        ) /* need to remove empty string columns that are used for Total Offered %, etc */
      reinsurerNames = [
        ...this.quoteParams.descriptionColumn,
        ...this.quoteParams.expiringReinsurerColumns[0],
        ...nonFotAndExpiringReNames,
      ]
    }
    // Add Program Group Name
    let programGroupNameRow: Row
    if (this.quoteParams.structureGroupName) {
      programGroupNameRow = worksheet.addRow([
        this.quoteParams.structureGroupName,
      ])
      programGroupNameRow.getCell(1).font = Fonts.Segoe_8_White_Bold
      reinsurerNames.map((reName, index) => {
        programGroupNameRow.getCell(index + 1).fill = Fills.Solid_Gray
        return reName
      })
    }

    // Add Shared Limit Name
    let sharedLimitNameRow: Row
    if (this.quoteParams.sharedLimitName) {
      sharedLimitNameRow = worksheet.addRow([this.quoteParams.sharedLimitName])
      sharedLimitNameRow.getCell(1).font = Fonts.Segoe_8_White_Bold
      reinsurerNames.map((name2, index) => {
        sharedLimitNameRow.getCell(index + 1).fill = Fills.Solid_Gray
        return name2
      })
    }

    // Add Subject Premium and Occurrence Values for XOL Export
    if (this.quoteParams.exportMode === QuoteExportModes.XOL) {
      this.utils.addXOLSubjectPremiumSummaryToWorksheet(
        worksheet,
        this.quoteParams
      )
    }

    /* Add each section to worksheet by layer */
    this.maxRows = 0
    let offeredRow = 0
    let pmpmCurrency = ''
    const membersLayers: number[][] = []
    const occLimitVals: Currency[][] = []
    const subPremiumVals: Currency[][] = []
    const rolVals: number[][] = [] // Rate on Line, Occurrence
    const rosVals: number[][] = [] // Rate % of Subject
    const pmpmVals: number[][] = []
    const priorTotalBlendedRateAll: number[][] = []
    const currTotalBlendedRateAll: number[][] = []
    const modeTotalBlendedOccLimit: Currency[][] = []
    const modeTotalBlendedSubPrem: Currency[][] = []

    const exLength = Math.max(
      ...this.quoteParams.expiringReinsurerColumns.map(e => e.length)
    )
    const curLength = Math.max(
      ...this.quoteParams.nonFotAndExpiringColumns.map(e => e.length)
    )

    // Only used in non-xol export modes
    const blendedColumn = Math.max(exLength + curLength + 2, 8)
    const blendedRow = 8

    if (this.quoteParams.headers.length > 0) {
      let layerNameRow: Row
      let columnNamesRow: Row
      let sectionLayerNameRow: Row
      // for group and SL
      if (
        this.quoteParams.sharedLimitName ||
        this.quoteParams.structureGroupName
      ) {
        // Add column names
        columnNamesRow = worksheet.addRow(reinsurerNames)
        // Add FOT Names
        if (columnNamesRow && this.quoteParams.fotReinsurerColumns.length > 0) {
          const col = columnNamesRow.actualCellCount + 4
          const colNameRowNum = columnNamesRow.number
          const row = colNameRowNum
          this.quoteParams.fotReinsurerColumns[0].forEach((c, i) => {
            const cell = worksheet.getCell(row, col + i)
            cell.value = c
            worksheet.getCell(row - 1, col + i).fill = Fills.Solid_Gray
          })
        }
      }
      let multiSectionCheck = true
      // get blended global blended column

      this.quoteParams.headers.forEach((h, i) => {
        // Add Layer Name
        if (this.quoteParams.sharedLimitName && h.trim() === '') {
          h = 'Shared Limit Layer'
        }
        // Add multisection
        if (
          !this.quoteParams.sharedLimitName &&
          !this.quoteParams.structureGroupName
        ) {
          const splitHeader = h.split(':')
          multiSectionCheck =
            h && splitHeader.length > 1 && splitHeader[0] === splitHeader[1]
          if (multiSectionCheck && h && splitHeader.length > 1) {
            sectionLayerNameRow = worksheet.addRow([splitHeader[1]])
            const nonFotAndExpiringReNames =
              this.quoteParams.nonFotAndExpiringColumns[i].slice(
                0,
                this.quoteParams.nonFotAndExpiringColumns[i].length - 2
              ) /* need to remove empty string columns that are used for Total Offered %, etc */
            const neededSpaces =
              this.longestExpiring -
              (this.quoteParams.expiringReinsurerColumns[i]?.length ?? 0)
            reinsurerNames = [
              ...this.quoteParams.descriptionColumn,
              ...this.quoteParams.expiringReinsurerColumns[i],
              ...this.utils.getSpacesForCurrentRow(
                !!this.longestExpiring ? neededSpaces : 0
              ),
              ...nonFotAndExpiringReNames,
            ]
            multiSectionCheck = false
            columnNamesRow = worksheet.addRow(reinsurerNames)
            if (
              columnNamesRow &&
              this.quoteParams.fotReinsurerColumns.length > 0 &&
              this.isDefaultExport
            ) {
              const col = blendedColumn + 5
              const colNameRowNum = columnNamesRow.number
              const row = colNameRowNum
              this.quoteParams.fotReinsurerColumns[i].forEach((c, index) => {
                const cell = worksheet.getCell(row, col + index)
                cell.value = c
                worksheet.getCell(row - 1, col + index).fill = Fills.Solid_Gray
              })
            }
          }
          if (h && !h.includes(':')) {
            sectionLayerNameRow = worksheet.addRow([h])
            const nonFotNamesCopy =
              clone(this.quoteParams.nonFotAndExpiringColumns[i]) ?? []
            const expiringColumnsCopy =
              clone(this.quoteParams.expiringReinsurerColumns[i]) ?? []

            const nonFotAndExpiringReNames = this.isDefaultExport
              ? nonFotNamesCopy.slice(
                  0,
                  /* need to remove empty string columns that are used for Total Offered %, etc */
                  nonFotNamesCopy.length - 2
                )
              : nonFotNamesCopy
            if (
              !!this.quoteParams.structureGroupName ||
              !!this.quoteParams.sharedLimitName
            ) {
              // Only used for group and SL
              reinsurerNames = [
                ...this.quoteParams.descriptionColumn,
                ...expiringColumnsCopy,
                ...nonFotAndExpiringReNames,
              ]
            } else {
              // Add the gap between the columns, find the longest set of panels and add spaces accordingly
              const neededSpaces =
                this.longestExpiring - (expiringColumnsCopy?.length ?? 0)
              reinsurerNames = [
                ...this.quoteParams.descriptionColumn,
                ...expiringColumnsCopy,
                ...this.utils.getSpacesForCurrentRow(
                  !!this.longestExpiring ? neededSpaces : 0
                ),
                ...nonFotAndExpiringReNames,
              ]
            }

            columnNamesRow = worksheet.addRow(reinsurerNames)
            const row = columnNamesRow.number

            if (
              columnNamesRow &&
              this.quoteParams.fotReinsurerColumns.length > 0 &&
              this.isDefaultExport
            ) {
              /* Add FOT column names next to 'Reinsurer' */
              const col = blendedColumn + 5

              this.quoteParams.fotReinsurerColumns[i].forEach((c, index) => {
                const cell = worksheet.getCell(row, col + index)
                cell.value = c
                worksheet.getCell(row - 1, col + index).fill = Fills.Solid_Gray
              })
            }
          }
          if (h && h.includes(':')) {
            h = h.split(':').shift()
            const neededSpaces = Math.max(this.longestExpiring, 0)
            layerNameRow = worksheet.addRow([
              h,
              ...this.utils.getSpacesForCurrentRow(neededSpaces),
            ])
          }
          sectionLayerNameRow.getCell(1).font = Fonts.Segoe_8_White_Bold
          reinsurerNames.map((_, index) => {
            const cell = sectionLayerNameRow.getCell(index + 1)
            cell.fill = Fills.Solid_Gray
            cell.border = this.utils.getCellBorderByIndex(
              this.longestExpiring,
              index,
              true
            )
          })
        } else {
          const neededSpaces = Math.max(this.longestExpiring, 0)
          layerNameRow = worksheet.addRow([
            h,
            ...this.utils.getSpacesForCurrentRow(neededSpaces),
          ])
        }
        if (layerNameRow) {
          // Set styles for section/group layerName/SL layerName subheaders
          layerNameRow.eachCell((cell, index) => {
            cell.border = this.utils.getCellBorderByIndex(
              this.longestExpiring,
              index - 1,
              true
            )
          })
          layerNameRow.getCell(1).font = Fonts.Segoe_8_Black_Bold
        }

        if (
          this.quoteParams.nonFotAndExpiringColumns[i]?.length > this.maxRows
        ) {
          this.maxRows = this.quoteParams.nonFotAndExpiringColumns[i].length
        }
        columnNamesRow.height = 40
        columnNamesRow.eachCell((cell, index) => {
          cell.alignment = { wrapText: true }
          cell.fill = Fills.Solid_Blue
          cell.font = Fonts.Segoe_8_White_Bold
          cell.border = this.utils.getCellBorderByIndex(
            this.longestExpiring + 1,
            index,
            true
          )
        })

        // display total expected ceded & premium values in the last row
        if (
          this.quoteParams.structureGroupName ||
          (!this.quoteParams.sharedLimitName &&
            !this.quoteParams.structureGroupName &&
            this.quoteParams.headers[i].includes(':')) ||
          this.quoteParams.sharedLimitName
        ) {
          this.quoteParams.descriptionRows[i].forEach((row, ind) => {
            if (
              i !== this.quoteParams.headers.length - 1 &&
              row[0] === 'Total Expected Ceded Premium'
            ) {
              this.quoteParams.nonFotAndExpiringRows[i].splice(ind, 1)
              this.quoteParams.fotReinsurerRows[i].splice(ind, 1)
              if (Object.isFrozen(this.quoteParams.descriptionRows[i])) {
                this.quoteParams.descriptionRows = [
                  ...this.quoteParams.descriptionRows,
                ]
                this.quoteParams.descriptionRows[i] = [
                  ...this.quoteParams.descriptionRows[i],
                ]
              }
              this.quoteParams.expiringReinsurerRows[i].splice(ind, 1)
              this.quoteParams.descriptionRows[i].splice(ind, 1)
            }
          })
          this.quoteParams.descriptionRows[i].forEach((row, ind) => {
            if (
              i !== this.quoteParams.headers.length - 1 &&
              row[0] === 'Total Expected Ceded Loss'
            ) {
              this.quoteParams.nonFotAndExpiringRows[i].splice(ind, 1)
              this.quoteParams.fotReinsurerRows[i].splice(ind, 1)
              this.quoteParams.expiringReinsurerRows[i].splice(ind, 1)
              this.quoteParams.descriptionRows[i].splice(ind, 1)
            }
          })
        }
        const mergedRows = this.mergeDataRows()
        let previousRolRow: number | undefined
        mergedRows[i].forEach(row => {
          const rowVals: QuoteExportRowValue[] = []
          row.forEach(r => {
            if (r) {
              rowVals.push(r[0])
            }
          })
          const DataRow = worksheet.addRow(rowVals)
          if (
            !this.isDefaultExport &&
            row[0][0] === 'Rate-on-Line, Occurrence'
          ) {
            previousRolRow = DataRow.number
          }
          DataRow.eachCell((cell, j) => {
            const offset = this.isDefaultExport ? 2 : 0
            const showBorder =
              j <= DataRow.cellCount - offset || cell.value !== ''
            cell.style = {
              font: Fonts.Segoe_8,
              alignment: { horizontal: 'right', wrapText: true },
              border: {
                ...this.utils.getCellBorderByIndex(this.longestExpiring + 1, j),
                bottom: {
                  style: 'thin',
                  color: { argb: showBorder ? '000000' : 'FFFFFFF' },
                },
              },
            }
            const cellCol = this.exportService.getColLetter(
              Number(cell.col) - 3
            )
            if (
              j > 2 &&
              row[j - 2][0] === 'Total Offered %:' &&
              Number(cell.col) !== 3
            ) {
              const cellValue = (Number(cell.value) * 100).toFixed(2) + '%'
              cell.value = {
                result: cellValue,
                formula: `=SUM(B${cell.row}:${cellCol}${cell.row})`,
                date1904: false,
              }
              offeredRow = Number(cell.row)
            }
            if (
              j > 2 &&
              cell.value !== 0 &&
              (row[j - 2][0] === 'Weighted Avg. Rate On-Line, Occ:' ||
                row[j - 2][0] === 'Weighted Avg. Rate, % of Subject:')
            ) {
              const cellValue = (Number(cell.value) * 100).toFixed(4) + '%'
              cell.value = {
                result: cellValue,
                formula: `=SUMPRODUCT(B${cell.row}:${cellCol}${cell.row},B${offeredRow}:${cellCol}${offeredRow})/SUM(B${offeredRow}:${cellCol}${offeredRow})`,
                date1904: false,
              }
            }
            if (
              !!String(cell.value).trim().length &&
              typeof cell.value === 'number' &&
              row[0][0] === 'Rate %, Change on Expiring' &&
              !!previousRolRow
            ) {
              const hasExpiringColumn =
                !!this.quoteParams.expiringReinsurerColumns[i]?.length
              if (hasExpiringColumn) {
                const numExpiring = this.utils.findLongestArrayLength(
                  this.quoteParams.expiringReinsurerColumns
                )
                const spacesPrior = this.quoteParams.nonFotAndExpiringColumns[i]
                  .slice(numExpiring, j)
                  .filter(col => !!col).length
                const mapping =
                  this.quoteParams.expiringReinsurerMappings[i]?.[
                    j - (numExpiring + spacesPrior + 2)
                  ]
                if (mapping !== undefined) {
                  const expiringCol = this.exportService.getColLetter(
                    mapping + 1
                  )
                  const expiringCellString = `$${expiringCol}$${previousRolRow}`
                  cell.value = {
                    result: cell.value,
                    formula: `=(${this.exportService.getColLetter(Number(cell.col) - 1)}${previousRolRow}-${expiringCellString})/(${expiringCellString})`,
                    date1904: false,
                  }
                }
              }
            }
            if (
              row[j - 1] &&
              row[j - 1][1] === 'currency' &&
              cell.value !== 'Unlimited'
            ) {
              if (
                cell.value &&
                typeof cell.value === 'number' &&
                cell.value % 1 !== 0
              ) {
                cell.numFmt = this.utils.formatCellAsCurrency(
                  this.sectionCurrencySymbols[i],
                  true
                )
              } else {
                cell.numFmt = this.utils.formatCellAsCurrency(
                  this.sectionCurrencySymbols[i],
                  false
                )
              }
              cell.value = this.utils.removeCurrencySymbolFromCell(cell)
            }
            if (row[j - 1] && row[j - 1][1] === 'percentage') {
              if (
                row[0][0] === 'Rate-on-Line, Occurrence' ||
                row[0][0] === 'Rate, % of Subject'
              ) {
                cell.numFmt = '0.0000%'
              } else {
                cell.numFmt = '0.00%'
              }
            }

            if (row[0][0] === 'Cessions Based Premium') {
              cell.alignment = { horizontal: 'center' }
              if (j === 1) {
                cell.alignment = { horizontal: 'left' }
              }
              if (row[j - 1][0] === true && row[j - 1][1] === 'checkbox') {
                cell.value = 'x'
              }
            }

            if (typeof cell.value === 'string' && cell.value.includes('@')) {
              cell.alignment = { horizontal: 'right' }
            }
          })
        })

        // Bleded Rates are only used in default export mode for quotes
        if (
          this.isDefaultExport &&
          !this.quoteParams.sharedLimitName &&
          !this.quoteParams.structureGroupName
        ) {
          const priorBlendedNameCell = worksheet.getCell(
            blendedRow,
            blendedColumn
          )

          priorBlendedNameCell.value = 'Prior Blended Total rate'
          worksheet.getCell(blendedRow - 1, blendedColumn).fill =
            Fills.Solid_Gray
          worksheet.getCell(blendedRow, blendedColumn).fill = Fills.Solid_Blue
          priorBlendedNameCell.alignment = { wrapText: true }

          // Blended Rate Columns - current
          const currentBlendedNameCell = worksheet.getCell(
            blendedRow,
            blendedColumn + 1
          )
          currentBlendedNameCell.value = 'Current Blended Total rate'
          worksheet.getCell(blendedRow - 1, blendedColumn + 1).fill =
            Fills.Solid_Gray
          worksheet.getCell(blendedRow, blendedColumn + 1).fill =
            Fills.Solid_Blue
          currentBlendedNameCell.alignment = { wrapText: true }

          //  Blended Rate Column Rate Change
          const BlendedRateChangeNameCell = worksheet.getCell(
            blendedRow,
            blendedColumn + 2
          )
          BlendedRateChangeNameCell.value = 'Blended YoY Rate Change'
          worksheet.getCell(blendedRow - 1, blendedColumn + 2).fill =
            Fills.Solid_Gray
          worksheet.getCell(blendedRow, blendedColumn + 2).fill =
            Fills.Solid_Blue
          BlendedRateChangeNameCell.alignment = { wrapText: true }
        }

        // Values for FOT Panels - Not needed for XOL export
        if (
          this.isDefaultExport &&
          ((layerNameRow && this.quoteParams.fotReinsurerRows.length > 0) ||
            (sectionLayerNameRow &&
              this.quoteParams.fotReinsurerRows.length > 0))
        ) {
          let col = blendedColumn + 5
          if (
            !!this.quoteParams.sharedLimitName ||
            !!this.quoteParams.structureGroupName
          ) {
            col =
              this.longestExpiring +
              this.utils.findLongestArrayLength(
                this.quoteParams.nonFotAndExpiringColumns
              ) +
              3
          }
          const layerNameRowNum =
            (layerNameRow && layerNameRow.number) ||
            (sectionLayerNameRow && sectionLayerNameRow.number)
          let row = layerNameRowNum

          row++
          const prevCol = col

          if (
            this.quoteParams.fotReinsurerRows &&
            this.quoteParams.fotReinsurerRows[i]
          ) {
            this.quoteParams.fotReinsurerRows[i].forEach((r, idx) => {
              row++
              col = prevCol
              r.forEach((r1: QuoteExportRowValue[], j: number) => {
                if (r1) {
                  let cell
                  if (
                    !this.quoteParams.structureGroupName &&
                    !this.quoteParams.sharedLimitName &&
                    sectionLayerNameRow &&
                    !this.quoteParams.headers[i].includes(':')
                  ) {
                    cell = worksheet.getCell(row, col)
                  } else {
                    cell = worksheet.getCell(row - 1, col)
                  }
                  cell.value = r1[0]
                  cell.alignment = { wrapText: true }
                  cell.style = {
                    font: Fonts.Segoe_8,
                    alignment: { horizontal: 'right', wrapText: true },
                    border: {
                      bottom: { style: 'thin', color: { argb: '000000' } },
                    },
                  }
                  if (r1[1] === 'currency' && cell.value !== 'Unlimited') {
                    const hasDecimals =
                      cell.value &&
                      typeof cell.value === 'number' &&
                      cell.value % 1 !== 0
                    cell.numFmt = this.utils.formatCellAsCurrency(
                      this.sectionCurrencySymbols[i],
                      hasDecimals
                    )
                    cell.value = this.utils.removeCurrencySymbolFromCell(cell)
                  }
                  if (r1[1] === 'percentage') {
                    cell.numFmt = '0.00%'
                  }
                  col++
                }
              })
            })
          }
        }

        // Check for audience types
        const descriptionRow = this.quoteParams.descriptionRows[i]
        const rolIndex = this.utils.findDescRowIndex(
          descriptionRow,
          'Rate-on-Line, Occurrence'
        )
        const rateOfSubjectIndex = this.utils.findDescRowIndex(
          descriptionRow,
          'Rate, % of Subject'
        )
        const occLimitIndex = this.utils.findDescRowIndex(
          descriptionRow,
          '',
          true
        )
        const sharedLimitIndex = this.utils.findDescRowIndex(
          descriptionRow,
          'Shared Limit'
        )
        const subPremIndex = this.utils.findDescRowIndex(
          descriptionRow,
          'Subject Premium'
        )
        const pmpmIndex = this.utils.findDescRowIndex(descriptionRow, 'PMPM')

        if (
          this.isDefaultExport &&
          !this.quoteParams.sharedLimitName &&
          !this.quoteParams.structureGroupName
        ) {
          if (rolIndex >= 0) {
            const rolExpiringBlendedRateTotal = this.calculatePriorBlendedRate(
              i,
              rolIndex
            )
            const rolCurrBlendedRateTotal = this.calculateCurrBlendedRate(
              i,
              rolIndex
            )

            priorTotalBlendedRateAll.push([rolExpiringBlendedRateTotal])
            currTotalBlendedRateAll.push([rolCurrBlendedRateTotal])

            const rolExTotalCell = worksheet.getCell(
              columnNamesRow.number + rolIndex + 1,
              blendedColumn
            )
            rolExTotalCell.value = rolExpiringBlendedRateTotal
            rolExTotalCell.numFmt = '0.00%'

            const rolCurrTotalCell = worksheet.getCell(
              columnNamesRow.number + rolIndex + 1,
              blendedColumn + 1
            )
            rolCurrTotalCell.value = rolCurrBlendedRateTotal
            rolCurrTotalCell.numFmt = '0.00%'

            const rolYoyRateChangeCell = worksheet.getCell(
              columnNamesRow.number + rolIndex + 1,
              blendedColumn + 2
            )
            if (
              rolExpiringBlendedRateTotal !== 0 &&
              rolCurrBlendedRateTotal !== 0
            ) {
              rolYoyRateChangeCell.value = {
                formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                  columnNamesRow.number + rolIndex + 1
                }/${worksheet.getColumn(blendedColumn).letter}${
                  columnNamesRow.number + rolIndex + 1
                }-1`,
                date1904: false,
              }
              rolYoyRateChangeCell.numFmt = '0.00%'
            } else {
              rolYoyRateChangeCell.value = 0
              rolYoyRateChangeCell.numFmt = '0.00%'
            }
          }

          // rate of subject %
          if (rateOfSubjectIndex >= 0) {
            const rateOfSubjectExpiringBlendedRateTotal: number =
              rateOfSubjectIndex >= 0
                ? this.calculatePriorBlendedRate(i, rateOfSubjectIndex)
                : 0
            const rateOfSubjectCurrBlendedRateTotal: number =
              rateOfSubjectIndex >= 0
                ? this.calculateCurrBlendedRate(i, rateOfSubjectIndex)
                : 0

            priorTotalBlendedRateAll.push([
              rateOfSubjectExpiringBlendedRateTotal,
            ])
            currTotalBlendedRateAll.push([rateOfSubjectCurrBlendedRateTotal])

            const roSubExTotalCell = worksheet.getCell(
              columnNamesRow.number + rateOfSubjectIndex + 1,
              blendedColumn
            )
            roSubExTotalCell.value = rateOfSubjectExpiringBlendedRateTotal
            roSubExTotalCell.numFmt = '0.00%'

            const roSubCurrTotalCell = worksheet.getCell(
              columnNamesRow.number + rateOfSubjectIndex + 1,
              blendedColumn + 1
            )
            roSubCurrTotalCell.value = rateOfSubjectCurrBlendedRateTotal
            roSubCurrTotalCell.numFmt = '0.00%'

            const roSubYoyRateChangeCell = worksheet.getCell(
              columnNamesRow.number + rateOfSubjectIndex + 1,
              blendedColumn + 2
            )
            if (
              rateOfSubjectExpiringBlendedRateTotal !== 0 &&
              rateOfSubjectCurrBlendedRateTotal !== 0
            ) {
              roSubYoyRateChangeCell.value = {
                formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                  columnNamesRow.number + rateOfSubjectIndex + 1
                }/${worksheet.getColumn(blendedColumn).letter}${
                  columnNamesRow.number + rateOfSubjectIndex + 1
                }-1`,
                date1904: false,
              }
              roSubYoyRateChangeCell.numFmt = '0.00%'
            } else {
              roSubYoyRateChangeCell.value = 0
              roSubYoyRateChangeCell.numFmt = '0.00%'
            }
          }
        }

        // Rate on Line, Occurrence - Used in both XOL and Default modes (Property Occ Layers)
        if (rolIndex >= 0) {
          rolVals.push(
            this.utils.getValsAtIndex<number>(i, rolIndex, this.quoteParams)
          )
        }

        // Rate, % of Subject - Used in both XOL and Default modes (Casualty Occ Layers)
        if (rateOfSubjectIndex >= 0) {
          rosVals.push(
            this.utils.getValsAtIndex<number>(
              i,
              rateOfSubjectIndex,
              this.quoteParams
            )
          )
        }

        // Occurrence Limit - Used in both XOL and Default modes
        if (occLimitIndex >= 0) {
          const occLimitValsNonNum = this.utils.getValsAtIndex<string>(
            i,
            occLimitIndex,
            this.quoteParams
          )
          const currencyVals = occLimitValsNonNum.map(v =>
            this.parseCurrency(v)
          )

          occLimitVals.push(currencyVals)
          modeTotalBlendedOccLimit.push([this.MODE([...currencyVals])])
        } else if (sharedLimitIndex >= 0) {
          const occLimitValsNonNum = this.utils.getValsAtIndex<string>(
            i,
            sharedLimitIndex,
            this.quoteParams
          )

          occLimitVals.push(occLimitValsNonNum.map(v => this.parseCurrency(v)))
          modeTotalBlendedOccLimit.push([this.MODE([...occLimitVals[i]])])
        }

        // Subject Premium - Used in both XOL and Default modes
        if (subPremIndex >= 0) {
          const subjectPremValsNonNum = this.utils.getValsAtIndex<string>(
            i,
            subPremIndex,
            this.quoteParams
          )

          const subjectPremValsNum = subjectPremValsNonNum.map(v =>
            this.parseCurrency(v)
          )

          subPremiumVals.push(subjectPremValsNum)
          modeTotalBlendedSubPrem.push([this.MODE(subPremiumVals[i])])
        }

        // PMPM for AHL layers
        if (pmpmIndex >= 0) {
          const pmpmExpiringBlendedRateTotal: number =
            pmpmIndex >= 0 ? this.calculatePriorBlendedRate(i, pmpmIndex) : 0
          const pmpmCurrBlendedRateTotal: number =
            pmpmIndex >= 0 ? this.calculateCurrBlendedRate(i, pmpmIndex) : 0
          pmpmCurrency =
            pmpmCurrency === ''
              ? this.parseCurrency(
                  this.quoteParams.nonFotAndExpiringRows[i][pmpmIndex][0][0]
                )[0]
              : pmpmCurrency

          priorTotalBlendedRateAll.push([pmpmExpiringBlendedRateTotal])
          currTotalBlendedRateAll.push([pmpmCurrBlendedRateTotal])

          const pmpmExTotalCell = worksheet.getCell(
            columnNamesRow.number + pmpmIndex + 1,
            blendedColumn
          )
          pmpmExTotalCell.value = pmpmExpiringBlendedRateTotal
          pmpmExTotalCell.numFmt = `[$${pmpmCurrency}]#,##0`

          const pmpmCurrTotalCell = worksheet.getCell(
            columnNamesRow.number + pmpmIndex + 1,
            blendedColumn + 1
          )
          pmpmCurrTotalCell.value = pmpmCurrBlendedRateTotal
          pmpmCurrTotalCell.numFmt = `[$${pmpmCurrency}]#,##0`

          const pmpmYoyRateChangeCell = worksheet.getCell(
            columnNamesRow.number + pmpmIndex + 1,
            blendedColumn + 2
          )
          if (
            pmpmExpiringBlendedRateTotal !== 0 &&
            pmpmCurrBlendedRateTotal !== 0
          ) {
            pmpmYoyRateChangeCell.value = {
              formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                columnNamesRow.number + pmpmIndex + 1
              }/${worksheet.getColumn(blendedColumn).letter}${
                columnNamesRow.number + pmpmIndex + 1
              }-1`,
              date1904: false,
            }
            pmpmYoyRateChangeCell.numFmt = '0.00%'
          } else {
            pmpmYoyRateChangeCell.value = 0
            pmpmYoyRateChangeCell.numFmt = '0.00%'
          }

          // build for total blend rates
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            pmpmIndex
          ]
            ? this.quoteParams.expiringReinsurerRows[i][pmpmIndex]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][pmpmIndex].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][pmpmIndex]
              : []

          const pmpmValsNonNum = [
            ...expiringrows.map((e: string[]) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: string[]) => e[0]),
          ]

          const pmpmValsNum = pmpmValsNonNum.map(v => this.parseCurrency(v)[1])

          pmpmVals.push(pmpmValsNum)
        }
      })
    }

    // Total Blend Rates
    const totalRateRow = ['Total Rate']
    for (let i = 0; i < this.mergeDataRows()[0][0].length; i++) {
      totalRateRow.push(' ')
    }
    const totalRow = worksheet.addRow(totalRateRow)
    totalRow.eachCell(cell => {
      // If there is an empty column, don't set the fill
      const isEmptyColumn = worksheet
        .getColumn(cell.col)
        .values.every(val => !String(val).trim().length)
      cell.fill = !isEmptyColumn
        ? {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '000000' },
          }
        : undefined
      cell.font = Fonts.Segoe_8_White_Bold
    })
    let hasChangeOnExpiringRow = false
    // 3 is the id for the property palette
    const isProperty = this.quoteParams.layerPaletteMappings.every(
      val => val === 3
    )

    if (
      rolVals.length &&
      rolVals.some(e => e.length > 0) &&
      occLimitVals &&
      occLimitVals.some(e => e.length > 0)
    ) {
      const rolTotalBlendValues = this.buildTotalRowBySum(
        rolVals,
        this.currencyToValue(occLimitVals)
      )
      const rolTotalBlendRates = [
        'Rate On Line, Occurrence',
        ...rolTotalBlendValues,
      ]
      const hasExpiringColumn = this.quoteParams.expiringReinsurerColumns.some(
        col => !!col.length
      )

      const rolTotalBlendRatesRow = worksheet.addRow(rolTotalBlendRates)
      rolTotalBlendRatesRow.eachCell((cell, index) => {
        // Empty cells shouldn't be styled
        if (String(cell.value).trim().length) {
          cell.numFmt = '0.00%'
          cell.font = Fonts.Segoe_8
          cell.alignment = { horizontal: 'right', wrapText: true }

          cell.border = this.utils.getCellBorderByIndex(
            this.longestExpiring + 1,
            index
          )
        }
      })

      if (
        this.isDefaultExport &&
        !this.quoteParams.sharedLimitName &&
        !this.quoteParams.structureGroupName
      ) {
        const priorBlendedTotal = this.buildTotalRowBySum(
          priorTotalBlendedRateAll,
          this.currencyToValue(modeTotalBlendedOccLimit)
        )

        const currBlendedTotal = this.buildTotalRowBySum(
          currTotalBlendedRateAll,
          this.currencyToValue(modeTotalBlendedOccLimit)
        )

        const rolPriorYoyRateCell = worksheet.getCell(
          rolTotalBlendRatesRow.number,
          blendedColumn
        )
        const rolCurrYoyRateCell = worksheet.getCell(
          rolTotalBlendRatesRow.number,
          blendedColumn + 1
        )
        const rolYoyRateChangeCell = worksheet.getCell(
          rolTotalBlendRatesRow.number,
          blendedColumn + 2
        )
        rolPriorYoyRateCell.value = priorBlendedTotal[0]
        rolPriorYoyRateCell.numFmt = '0.00%'
        rolCurrYoyRateCell.value = currBlendedTotal[0]
        rolCurrYoyRateCell.numFmt = '0.00%'

        if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
          rolYoyRateChangeCell.value = {
            formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
              rolTotalBlendRatesRow.number
            }/${worksheet.getColumn(blendedColumn).letter}${
              rolTotalBlendRatesRow.number
            }-1`,
            date1904: false,
          }
          rolYoyRateChangeCell.numFmt = '0.00%'
        } else {
          rolYoyRateChangeCell.value = 0
          rolYoyRateChangeCell.numFmt = '0.00%'
        }
      } else if (
        hasExpiringColumn &&
        (isProperty || !rosVals.some(e => e.length > 0)) &&
        !this.isDefaultExport
      ) {
        hasChangeOnExpiringRow = true
        const longestMapping = this.utils.findLongestSubArray(
          this.quoteParams.expiringReinsurerMappings
        )
        const expiringValues = rolTotalBlendValues.slice(
          0,
          this.longestExpiring
        )

        const nonExpiringValues = rolTotalBlendValues.slice(
          this.longestExpiring
        )
        const ratePercentChangeonExp: QuoteExportRowValue[] =
          nonExpiringValues.map((val, i) => {
            const expiringValue = expiringValues[longestMapping[i] ?? 0]
            if (!expiringValue) {
              return ''
            }
            const valString = String(val).trim()
            const expiringValString = String(val).trim()
            return !!valString.length && !!expiringValString.length
              ? (val - expiringValue) / expiringValue
              : ''
          })

        const rowVals = [
          'Rate %, Change on Expiring',
          // Spaces due to expiring panels not having this value calculated
          ...this.utils.getSpacesForCurrentRow(this.longestExpiring),
          ...ratePercentChangeonExp,
        ]
        const ratePercentChangeonExpRow = worksheet.addRow(rowVals)
        ratePercentChangeonExpRow.eachCell((cell, colNumber) => {
          // Empty cells shouldn't be styled
          const cellHasValue = !!String(cell.value).trim().length
          cell.border = this.utils.getCellBorderByIndex(
            this.longestExpiring + 1,
            colNumber
          )
          cell.font = Fonts.Segoe_8
          cell.alignment = { horizontal: 'right', wrapText: true }

          // Only add under non-expiring reinsurers, set up equation using expiring panel
          if (
            cellHasValue &&
            typeof cell.value === 'number' &&
            colNumber !== 1
          ) {
            cell.numFmt = '0.00%'
            const expiringCol = worksheet.getColumn(2)
            const rolRowNumber = rolTotalBlendRatesRow.number
            const expiringRowStatic = `$${expiringCol.letter}$${rolRowNumber}`
            cell.value = {
              result: ratePercentChangeonExp[colNumber],
              formula: `=(${this.exportService.getColLetter(colNumber - 1)}${rolRowNumber}-${expiringRowStatic})/(${expiringRowStatic})`,
              date1904: false,
            }
          }
        })
      }
    }

    if (
      rosVals.length &&
      rosVals.some(e => e.length > 0) &&
      subPremiumVals.length &&
      subPremiumVals.some(e => e.length > 0)
    ) {
      const rosTotalBlendValues = this.buildTotalRowByAverage(
        rosVals,
        this.currencyToValue(subPremiumVals)
      )
      const rosTotalBlendRates = ['Rate, % of Subject', ...rosTotalBlendValues]

      const rosTotalBlendRatesRow = worksheet.addRow(rosTotalBlendRates)
      rosTotalBlendRatesRow.eachCell((cell, colNumber) => {
        cell.numFmt = '0.00%'
        cell.font = Fonts.Segoe_8
        cell.alignment = { horizontal: 'right', wrapText: true }
        cell.border = cell.border = this.utils.getCellBorderByIndex(
          this.longestExpiring + 1,
          colNumber
        )
      })
      const hasExpiringColumn = this.quoteParams.expiringReinsurerColumns.some(
        col => !!col.length
      )

      // XOL Export doesn't use the YoY Blended Rates
      if (this.isDefaultExport) {
        const priorBlendedTotal = this.buildTotalRowByAverage(
          priorTotalBlendedRateAll,
          this.currencyToValue(modeTotalBlendedSubPrem)
        )

        const currBlendedTotal = this.buildTotalRowByAverage(
          currTotalBlendedRateAll,
          this.currencyToValue(modeTotalBlendedSubPrem)
        )

        const rosPriorYoyRateCell = worksheet.getCell(
          rosTotalBlendRatesRow.number,
          blendedColumn
        )
        const rosCurrYoyRateCell = worksheet.getCell(
          rosTotalBlendRatesRow.number,
          blendedColumn + 1
        )
        const rosYoyRateChangeCell = worksheet.getCell(
          rosTotalBlendRatesRow.number,
          blendedColumn + 2
        )
        rosPriorYoyRateCell.value = priorBlendedTotal[0]
        rosPriorYoyRateCell.numFmt = '0.00%'
        rosCurrYoyRateCell.value = currBlendedTotal[0]
        rosCurrYoyRateCell.numFmt = '0.00%'
        if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
          rosYoyRateChangeCell.value = {
            formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
              rosTotalBlendRatesRow.number
            }/${worksheet.getColumn(blendedColumn).letter}${
              rosTotalBlendRatesRow.number
            }-1`,
            date1904: false,
          }
          rosYoyRateChangeCell.numFmt = '0.00%'
        } else {
          rosYoyRateChangeCell.value = 0
          rosYoyRateChangeCell.numFmt = '0.00%'
        }
      } else if (hasExpiringColumn && !hasChangeOnExpiringRow) {
        const longestMapping = this.utils.findLongestSubArray(
          this.quoteParams.expiringReinsurerMappings
        )
        const expiringValues = rosTotalBlendValues.slice(
          0,
          this.longestExpiring
        )

        const nonExpiringValues = rosTotalBlendValues.slice(
          this.longestExpiring
        )
        const ratePercentChangeonExp: QuoteExportRowValue[] =
          nonExpiringValues.map((val, i) => {
            const expiringValue = expiringValues[longestMapping[i]]
            if (!expiringValue) {
              return ''
            }
            const valString = String(val).trim()
            const expiringValString = String(val).trim()
            return !!valString.length && !!expiringValString.length
              ? (val - expiringValue) / expiringValue
              : ''
          })

        const rowVals = [
          'Rate %, Change on Expiring',
          // Spaces due to expiring panels not having this value calculated
          ...this.utils.getSpacesForCurrentRow(this.longestExpiring),
          ...ratePercentChangeonExp,
        ]
        const ratePercentChangeonExpRow = worksheet.addRow(rowVals)
        ratePercentChangeonExpRow.eachCell((cell, colNumber) => {
          // Empty cells shouldn't be styled
          const cellHasValue = !!String(cell.value).trim().length
          cell.border = cell.border = this.utils.getCellBorderByIndex(
            this.longestExpiring + 1,
            colNumber
          )
          cell.font = Fonts.Segoe_8
          cell.alignment = { horizontal: 'right', wrapText: true }

          // Only add under non-expiring reinsurers, set up equation using expiring panel
          if (
            cellHasValue &&
            typeof cell.value === 'number' &&
            colNumber !== 1
          ) {
            cell.numFmt = '0.00%'
            const expiringCol = worksheet.getColumn(2)
            const rolRowNumber = rosTotalBlendRatesRow.number
            const expiringRowStatic = `$${expiringCol.letter}$${rolRowNumber}`
            cell.value = {
              result: ratePercentChangeonExp[colNumber],
              formula: `=(${this.exportService.getColLetter(colNumber - 1)}${rolRowNumber}-${expiringRowStatic})/(${expiringRowStatic})`,
              date1904: false,
            }
          }
        })
      }
    }
    if (pmpmVals.length && pmpmVals.some(e => e.length > 0)) {
      const pmPmTotalBlendRates = [
        'PMPM',
        ...this.buildTotalRowByAverage(
          pmpmVals,
          this.quoteParams.members.map(m => [m])
        ),
      ]
      const pmPmTotalBlendRatesRow = worksheet.addRow(pmPmTotalBlendRates)
      pmPmTotalBlendRatesRow.eachCell(cell => {
        cell.numFmt = `[$${pmpmCurrency}]#,##0`
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' },
          },
        }
      })

      // const priorTotalBlendedRateAllTest = [[0.0394],[0.0694]]
      // const modeTotalBlendedOccLimitTest = [[17500000],[17500000]]
      priorTotalBlendedRateAll.forEach(_ =>
        membersLayers.push(this.quoteParams.members)
      )

      const priorBlendedTotal = this.buildTotalRowByAverage(
        membersLayers,
        priorTotalBlendedRateAll
      )

      const currBlendedTotal = this.buildTotalRowByAverage(
        membersLayers,
        currTotalBlendedRateAll
      )

      const pmPmPriorYoyRateCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn
      )

      const pmPmCurrYoyRateCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn + 1
      )

      const pmPmYoyRateChangeCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn + 2
      )

      pmPmPriorYoyRateCell.value = priorBlendedTotal[0]
      pmPmPriorYoyRateCell.numFmt = `[$${pmpmCurrency}]#,##0`
      pmPmCurrYoyRateCell.value = currBlendedTotal[0]
      pmPmCurrYoyRateCell.numFmt = `[$${pmpmCurrency}]#,##0`

      if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
        pmPmYoyRateChangeCell.value = {
          formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
            pmPmTotalBlendRatesRow.number
          }/${worksheet.getColumn(blendedColumn).letter}${
            pmPmTotalBlendRatesRow.number
          }-1`,
          date1904: false,
        }
        pmPmYoyRateChangeCell.numFmt = '0.00%'
      } else {
        pmPmYoyRateChangeCell.value = 0
        pmPmYoyRateChangeCell.numFmt = '0.00%'
      }
    }

    this.exportService.appendBlankRows(worksheet)

    // Tracking Module
    const trackingRow = worksheet.addRow(['Subjectivities & Notes'])
    trackingRow.getCell(1).fill = Fills.Solid_Gray
    trackingRow.getCell(1).font = Fonts.Segoe_8_White_Bold

    // Add tracking column row
    if (this.quoteParams.structureGroupName) {
      this.quoteParams.trackingColumns[1] = 'Group Name'
    }
    if (this.quoteParams.sharedLimitName) {
      this.quoteParams.trackingColumns[1] = 'Shared Limit Name'
    }
    const trackingColumnRow = worksheet.addRow(this.quoteParams.trackingColumns)

    if (this.quoteParams.trackingColumns.length > this.maxRows) {
      this.maxRows = this.quoteParams.trackingColumns.length
    }
    trackingColumnRow.height = 30
    trackingColumnRow.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
    })
    // Merge Cells
    worksheet.mergeCells(
      `A${trackingRow.number}:${this.exportService.getColLetter(
        trackingColumnRow.actualCellCount - 1
      )}${trackingRow.number}`
    )

    this.quoteParams.trackingRows1.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet.addRow(row)
      DataRow.eachCell(cell => {
        cell.style = {
          alignment: { wrapText: true },
          font: Fonts.Segoe_8,
          border: {
            bottom: {
              style: 'thin',
              color: { argb: '000000' },
            },
          },
        }
      })
    })

    // Set width for any non-empty columns
    worksheet.columns.forEach(column => {
      if (column.values.some(val => !!val)) {
        column.width = 25
      }
    })
    worksheet.getColumn(1).width = 30
    this.exportService.appendLogos(worksheet, this.workbook, 5, 8)
  }

  private MODE(values: Currency[]): Currency {
    if (!values?.length) {
      return ['', 0]
    }

    const count = (array: Currency[], x: Currency) =>
      array.filter(v => v[1] === x[1]).length

    const sorted = values.sort((a, b) => count(values, a) - count(values, b))
    return [...sorted].pop() ?? ['', 0]
  }

  private currencyToValue(currency: Currency[][]): number[][] {
    return currency.map(cc => cc.map(c => c[1]))
  }

  private SUMPRODUCT(
    callback: (ar1: number, ar2: number) => number,
    ar1: number[],
    ar2: number[]
  ) {
    if (ar1.length !== ar2.length) {
      throw new RangeError()
    }
    let sum = 0
    ar1.forEach((_, i) => {
      if (callback(ar1[i], ar2[i])) {
        sum += ar1[i] * ar2[i]
      }
    })
    return sum
  }

  private mergeDataRows() {
    // TODO: Clean this up (variable names, etc.)
    let dRows: QuoteExportRowValue[][][][] = []
    this.quoteParams.descriptionRows.forEach(descRow => {
      const r: string[][][] = []
      descRow.forEach((r1: string[]) => {
        if (r1) {
          r.push([r1])
        }
      })
      dRows.push(r)
    })

    this.quoteParams.expiringReinsurerRows.forEach((expRow, i) => {
      dRows = clone(dRows)
      expRow.forEach((r1, j) => {
        if (r1) {
          dRows[i][j].push(...r1)
        }
      })
    })
    const longestRowLength = this.utils.findLongestArrayLength(
      this.quoteParams.expiringReinsurerColumns
    )
    this.quoteParams.nonFotAndExpiringRows.forEach((nonRow, i) => {
      dRows = clone(dRows)
      nonRow.forEach((r1, j) => {
        if (r1) {
          if (
            !!this.quoteParams.structureGroupName ||
            !!this.quoteParams.sharedLimitName
          ) {
            dRows[i][j].push(...r1)
          } else {
            const spaceDifference =
              longestRowLength -
              this.quoteParams.expiringReinsurerColumns[i].length
            const spacesColumns = this.utils
              .getSpacesForCurrentRow(!!longestRowLength ? spaceDifference : 0)
              .map(space => [space])
            dRows[i][j].push(...spacesColumns, ...r1)
          }
        }
      })
    })

    // Add in Rate %, Change on Expiring
    if (!this.isDefaultExport) {
      dRows.forEach((section, i) => {
        let rateIndex

        // Id of the layer palette view, either 3 for property or 4 for casualty & specialty
        const isProperty = this.quoteParams.layerPaletteMappings[i] === 3
        const rolIndex = section.findIndex(
          cellVal => cellVal[0][0] === 'Rate-on-Line, Occurrence'
        )
        const rosIndex = section.findIndex(
          cellVal => cellVal[0][0] === 'Rate, % of Subject'
        )
        if ((isProperty || rosIndex < 0) && rolIndex >= 0) {
          rateIndex = rolIndex
        } else if (rosIndex >= 0) {
          rateIndex = rosIndex
        }

        if (
          rateIndex >= 0 &&
          !!this.quoteParams.expiringReinsurerColumns[i]?.length &&
          !!this.quoteParams.nonFotAndExpiringColumns[i]?.filter(val => !!val)
            .length
        ) {
          const occurrenceLimitRow = section[rateIndex].slice(1)
          const maxExpiringPanels = this.utils.findLongestArrayLength(
            this.quoteParams.expiringReinsurerColumns
          )
          const newVals = occurrenceLimitRow.map((cell, j) => {
            if (j < maxExpiringPanels) {
              return ['']
            }
            if (cell[0] === '') {
              return cell
            }

            // There is a chance that there are reinsurer entries that don't exist for a section, calculate offset
            const blanksOffset = occurrenceLimitRow
              .slice(0, j)
              .filter(val => val[0] === '' && val.length > 1).length

            const noNonExpiringOrFOTOffset =
              this.quoteParams.nonFotAndExpiringRows
                .slice(0, j)
                .filter(section =>
                  section.every(row => row.every(value => !value[0]))
                ).length

            const expiringMapping =
              this.quoteParams.expiringReinsurerMappings[
                i - noNonExpiringOrFOTOffset
              ]?.[j - (maxExpiringPanels + blanksOffset)]
            const expiringVal = Number(
              occurrenceLimitRow[expiringMapping]?.[0] ?? ''
            )
            if (!expiringVal || expiringVal < 1e-5) {
              return ['', 'text']
            } else {
              const newVal = (Number(cell[0] ?? 0) - expiringVal) / expiringVal
              return [newVal, cell[1]]
            }
          })
          dRows[i].push([['Rate %, Change on Expiring', 'text'], ...newVals])
        }
      })
    }

    return dRows
  }

  private calculatePriorBlendedRate(i: number, metricInd: number): number {
    if (i < 0 || metricInd < 0) {
      return 0
    }

    const metricRow = this.quoteParams.expiringReinsurerRows[i][metricInd]
    if (!metricRow) {
      return 0
    }

    const parsedMetrics = metricRow.map(
      (v: QuoteExportRowValue[]) => this.parseCurrency(v[0])[1]
    )

    const signedRow: number[] = this.quoteParams.quotesSignedPercRow[i]
    // const metricRowTest = [0.03, 0.03, 0.05]
    // const signedRowTest = [0.1, 0.1277, 0.2]

    const sumProductExpiring = this.SUMPRODUCT(
      (x: number) => x,
      parsedMetrics.map(Number),
      signedRow
    )

    const sumExpiringPerc: number = signedRow.reduce((tot, curr) => {
      return tot + curr
    }, 0)

    let expiringBlendedRateTotal = 0

    if (sumProductExpiring !== 0 && sumExpiringPerc !== 0) {
      expiringBlendedRateTotal = sumProductExpiring / sumExpiringPerc
    }

    return expiringBlendedRateTotal
  }

  private calculateCurrBlendedRate(i: number, metricInd: number): number {
    if (i < 0 || metricInd < 0) {
      return 0
    }

    const metricRow = this.quoteParams.nonFotAndExpiringRows[i][metricInd]
    if (!metricRow) {
      return 0
    }

    const parsedMetrics = metricRow
      .slice(0, -2)
      .map((v: QuoteExportRowValue[]) => this.parseCurrency(v[0])[1])

    const offeredInd = this.quoteParams.descriptionRows[i].findIndex(
      e => e[0] === 'Offered Percentage'
    )
    const offeredPercRow: number[] =
      this.quoteParams.nonFotAndExpiringRows[i][offeredInd]
        ?.slice(0, -2)
        ?.map((e: QuoteExportRowValue[]) => Number(e[0])) ?? []
    if (offeredPercRow.length === 0) {
      return 0
    }
    // const metricRowTest = [0.06, 0.045, 0.1]
    // const offeredRowTest = [0, 0.45,1]

    const sumProductCurr = this.SUMPRODUCT(
      (x: number) => x,
      parsedMetrics.map(Number),
      offeredPercRow
    )

    const sumOfferedPerc: number = offeredPercRow.reduce((tot, curr) => {
      return tot + curr
    }, 0)

    let currBlendedRateTotal = 0

    if (sumProductCurr !== 0 && sumOfferedPerc !== 0) {
      currBlendedRateTotal = sumProductCurr / sumOfferedPerc
    }

    return currBlendedRateTotal
  }

  private buildTotalRowBySum(row1: number[][], row2: number[][]) {
    const multiRow: number[][] = []

    row1.forEach((e, idx) => {
      const layerMult: number[] = []

      e.forEach((v, i) => {
        const val = (v ?? 0) * (row2[idx] && row2[idx][i] ? row2[idx][i] : 1)
        layerMult.push(val)
      })

      multiRow.push(layerMult)
    })

    const totalSumRow = row2.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }

      return tot
    })

    const totalMultiRow = multiRow.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }

      return tot
    })

    const totalBlendedRowFinal = totalMultiRow.map((e, idx) => {
      const sumNum = totalSumRow[idx]
      return e && sumNum ? e / sumNum : 0
    })

    return totalBlendedRowFinal
  }

  private buildTotalRowByAverage(row1: number[][], row2: number[][]) {
    const multiRow: number[][] = []

    row1.forEach((e, idx) => {
      const layerMult: number[] = []

      e.forEach((v, i) => {
        const val = (v ?? 1) * (row2[idx] && row2[idx][i] ? row2[idx][i] : 1)
        layerMult.push(val)
      })

      multiRow.push(layerMult)
    })

    const totalSumRow = row2.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }
      return tot
    })

    const totalAverageRow: number[] = totalSumRow.map(e =>
      row2.length > 0 ? e / row2.length : 0
    )

    const totalMultiRow: number[] = multiRow.reduce((tot, curr) => {
      if (curr.length > 0) {
        const sum = curr.map((num, idx) => num + (tot[idx] ?? 0))
        return sum
      }
      return tot
    })

    const totalBlendedRowFinal: number[] = totalMultiRow.map((e, idx) => {
      const avgNum = totalAverageRow[idx]
      return e && avgNum ? e / avgNum : 0
    })

    return totalBlendedRowFinal
  }

  private parseCurrency(value: QuoteExportRowValue): Currency {
    const m = String(value).match(/([A-Z]{3}|\D)?\s*([\d,.]+)/)
    if (m === null) {
      return ['', 0]
    }

    const code = m[1] ?? ''
    const num = parseFloat(m[2].replace(/,/g, ''))

    return [code, num]
  }
}
