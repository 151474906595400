import { Metrics } from '../../../api/analyzere/analyzere.model'
import {
  CombinedLossDistributionRow,
  LossDistributionRow,
} from '../../../core/model/loss-set-table.model'
import { VaRTVaR } from '../../model/metrics.model'

export function convertToTableData(
  oepMetrics: Metrics[],
  arrRP: number[],
  vartvar: VaRTVaR,
  subjectPremiumAmt: number,
  aepMetrics: Metrics[]
): CombinedLossDistributionRow[] {
  return arrRP.map((_, i) => {
    const rpSrc = arrRP
    let oepValue = 0
    let oepVarianceValue = 0
    let oepLossRatioValue = 0
    let oepLossRatioVarianceValue = 0
    let aepValue = 0
    let aepVarianceValue = 0
    let aepLossRatioValue = 0
    let aepLossRatioVarianceValue = 0
    if (oepMetrics) {
      if (vartvar === 'VaR') {
        oepValue = i < 10 ? oepMetrics[i].min : oepMetrics[i].mean
      } else {
        oepValue = oepMetrics[i].mean
      }
      oepVarianceValue = oepMetrics[i].variance
      if (subjectPremiumAmt > 0) {
        oepLossRatioVarianceValue =
          Math.sqrt(oepMetrics[i].variance) / subjectPremiumAmt
      } else {
        oepLossRatioVarianceValue = 0
      }

      if (i <= 10) {
        if (subjectPremiumAmt > 0) {
          oepLossRatioValue = oepValue / subjectPremiumAmt
        } else {
          oepLossRatioValue = 0
        }
      }
    }
    if (aepMetrics) {
      if (vartvar === 'VaR') {
        aepValue = i < 10 ? aepMetrics[i].min : aepMetrics[i].mean
      } else {
        aepValue = aepMetrics[i].mean
      }
      aepVarianceValue = aepMetrics[i].variance
      if (subjectPremiumAmt > 0) {
        aepLossRatioVarianceValue =
          Math.sqrt(aepMetrics[i].variance) / subjectPremiumAmt
      } else {
        aepLossRatioVarianceValue = 0
      }

      if (i <= 10) {
        if (subjectPremiumAmt > 0) {
          aepLossRatioValue = aepValue / subjectPremiumAmt
        } else {
          aepLossRatioValue = 0
        }
      }
    }
    return {
      returnPeriod: rpSrc[i],
      oepValue,
      oepVarianceValue,
      oepLossRatioValue,
      oepLossRatioVarianceValue,
      aepValue,
      aepVarianceValue,
      aepLossRatioValue,
      aepLossRatioVarianceValue,
    }
  })
}
export function convertToStdMeanOnly(
  metrics: Metrics[],
  arrRP: number[],
  vartvar: VaRTVaR,
  subjectPremiumAmt: number
): LossDistributionRow[] {
  return arrRP.map((_, i) => {
    let value = 0
    let varianceValue = 0
    let lossRatioValue = 0
    let lossRatioVarianceValue = 0
    if (metrics) {
      if (vartvar === 'VaR') {
        value = arrRP[i] > 1 ? metrics[i].min : metrics[i].mean
      } else {
        value = arrRP[i] > 1 ? metrics[i].mean : metrics[i].min
      }
      varianceValue = metrics[i].variance
      lossRatioVarianceValue = metrics[i].variance

      if (i < 10) {
        if (subjectPremiumAmt > 0) {
          lossRatioValue = value / subjectPremiumAmt
        } else {
          lossRatioValue = 0
        }
      }
    }
    return {
      returnPeriod: arrRP[i],
      value,
      varianceValue,
      lossRatioValue,
      lossRatioVarianceValue,
    }
  })
}

export function convertToTableDataGroups(
  metrics: Record<string, Metrics[]>,
  arrRP: number[],
  vartvar: VaRTVaR,
  subjectPremiumAmt: number
): LossDistributionRow[] {
  const data = Object.values(metrics)
  return arrRP.map((_, i) => {
    let value = 0
    let varianceValue = 0
    let lossRatioValue = 0
    let lossRatioVarianceValue = 0
    data.forEach(d => {
      if (d) {
        if (vartvar === 'VaR') {
          value += arrRP[i] > 1 ? d[i].min : d[i].mean
        } else {
          value += arrRP[i] > 1 ? d[i].mean : d[i].min
        }
        varianceValue += d[i].variance
        lossRatioVarianceValue += d[i].variance
      }
    })
    if (i < 10) {
      if (subjectPremiumAmt > 0) {
        lossRatioValue = value / subjectPremiumAmt
      } else {
        lossRatioValue = 0
      }
    }
    return {
      returnPeriod: arrRP[i],
      value,
      varianceValue,
      lossRatioValue,
      lossRatioVarianceValue,
    }
  })
}
