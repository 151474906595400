import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  deleteGroupSuccess,
  saveGroupSuccess,
  updateGroupSuccess,
} from 'src/app/analysis/store/loss-set-layers/loss-set-group/loss-set-group.actions'
import { convertLossSetGroupMemberFromResponse } from 'src/app/api/loss-set-group/loss-set-group.converter'
import { LossSetGroupMember } from '../../analysis/model/loss-set-layers.model'
import { ApplicationError } from '../../error/model/error'
import * as AuthActions from './auth/auth.actions'

interface ExtendedState {
  loading: boolean
  error: ApplicationError | null
}

export type State = EntityState<LossSetGroupMember> & ExtendedState

export const adapter = createEntityAdapter<LossSetGroupMember>()

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
})

const lossSetGroupsReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(
    AuthActions.identifyPermissionsSuccess,
    (state, { lossSetGroupMembers }) => {
      const next = adapter.setAll(
        lossSetGroupMembers.map(convertLossSetGroupMemberFromResponse),
        state
      )
      return {
        ...next,
        loading: initialState.loading,
        error: initialState.error,
      }
    }
  ),

  on(saveGroupSuccess, (state, { groupAndMembers }) => {
    return adapter.addMany(groupAndMembers.lossSetGroupMembers, state)
  }),

  on(deleteGroupSuccess, (state, { lossSetGroup }) => {
    const idsToRemove = selectAll(state)
      .filter(m => m.lossSetGroupID === lossSetGroup.id)
      .map(m => m.id)
    return adapter.removeMany(idsToRemove, state)
  }),

  on(updateGroupSuccess, (state, { groupAndMembers }) => {
    const idsToRemove = selectAll(state)
      .filter(m => m.lossSetGroupID === groupAndMembers.lossSetGroup.id)
      .map(m => m.id)
    const intermediateState = adapter.removeMany(idsToRemove, state)
    return adapter.addMany(
      groupAndMembers.lossSetGroupMembers,
      intermediateState
    )
  })
)

export function reducer(state: State | undefined, action: Action) {
  return lossSetGroupsReducer(state, action)
}

export const { selectAll } = adapter.getSelectors()
