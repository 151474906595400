import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store'
import { AppState } from '../index'
import { rejectNil } from '@shared/util/operators'
import { Program } from '../../model/program.model'
import {
  selectCurrentStructureID,
  selectCurrentStudyProgramIDs,
  selectCurrentStudyProgramIDs2,
} from '../broker/broker.selectors'
import * as fromProgram from './program.reducer'
import { State as ProgramState } from './program.state.facade'

export const selectProgramState: MemoizedSelector<AppState, ProgramState> =
  createFeatureSelector('program')

export const {
  selectIds: selectProgramIds,
  selectAll: selectPrograms,
  selectEntities: selectProgramsByID,
} = fromProgram.adapter.getSelectors(selectProgramState)

export const selectLibreTemplate = createSelector(
  selectProgramIds,
  selectProgramsByID,
  (ids: string[], entities) => ids.find(id => entities[id]?.studyID === 'null')
)

export const selectCurrentStudyPrograms = createSelector(
  selectCurrentStudyProgramIDs,
  selectProgramState,
  selectLibreTemplate,
  (programIDs, state, libRETemplateId): Program[] =>
    programIDs &&
    state &&
    // Append 'null' to the programIDs array so that the libre template is included in the structures
    rejectNil(
      programIDs
        .concat(libRETemplateId || [])
        .map(id => state.entities[String(id)])
    )
)
export const selectCurrentStudyPrograms2 = createSelector(
  selectCurrentStudyProgramIDs2,
  selectProgramState,
  selectLibreTemplate,
  (programIDs, state, libRETemplateId): Program[] =>
    programIDs &&
    state &&
    // Append 'null' to the programIDs array so that the libre template is included in the structures
    rejectNil(
      programIDs
        .concat(libRETemplateId || [])
        .map(id => state.entities[String(id)])
    )
)
export const selectCurrentStudyProgramNames = createSelector(
  selectCurrentStudyPrograms,
  programs => programs.map(p => p.label)
)

export const selectDirtyProgram = createSelector(
  selectProgramState,
  state => state.dirtyProgram
)

const getCurrentStructure = (
  structureID: string | null | undefined,
  program: Program[]
): Program | undefined =>
  (program && structureID && program.find(p => p.id === structureID)) ||
  undefined

export const selectCurrentStructure = createSelector(
  selectCurrentStructureID,
  selectPrograms,
  getCurrentStructure
)
