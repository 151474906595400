import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router'
import { curry } from 'ramda'

const TIER_ROUTES = [
  ':clientID',
  ':clientID/years/:yearID',
  ':clientID/years/:yearID/programs/:programID',
  ':clientID/years/:yearID/programs/:programID/structure/:structureID',
  ':clientID/years/:yearID/programs/:programID/structure/:structureID/lob/:lobID',
]

// UrlMatcher doesn't allow null returns despite them requiring null to
// be returned when there is no match.
// tslint:disable: no-non-null-assertion
const tierRouteMatcher = curry(
  (segments: UrlSegment[], path: string): UrlMatchResult => {
    const parts = path ? path.split('/') : []

    if (parts.length > segments.length) {
      // The actual URL is shorter than the config, no match
      return null!
    }

    const posParams: { [key: string]: UrlSegment } = {}

    // Check each config part against the actual URL
    for (let index = 0; index < parts.length; index++) {
      const part = parts[index]
      const segment = segments[index]
      const isParameter = part.startsWith(':')
      if (isParameter) {
        posParams[part.substring(1)] = segment
      } else if (part !== segment.path) {
        // The actual URL part does not match the config, no match
        return null!
      }
    }

    return { consumed: segments.slice(0, parts.length), posParams }
  }
)

function _createTierRouteMatcher(
  contextPath: string | undefined,
  segments: UrlSegment[],
  _segmentGroup: UrlSegmentGroup,
  _route: Route
): UrlMatchResult {
  const matcher = tierRouteMatcher(segments)
  let result: UrlMatchResult | null = null
  for (const path of TIER_ROUTES) {
    const currentResult = matcher(path + (contextPath ? `/${contextPath}` : ''))
    if (currentResult) {
      result = currentResult
    }
  }
  return result!
}
// tslint:enable: no-non-null-assertion

export function createTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher(undefined, segments, segmentGroup, route)
}

export function createSlipTemplatesTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('sliptemplates', segments, segmentGroup, route)
}

export function createAnalysisTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('analysis', segments, segmentGroup, route)
}

export function createCreditTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('credit', segments, segmentGroup, route)
}

export function createMetricsTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('metrics', segments, segmentGroup, route)
}

export function createReinsurersTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('reinsurers', segments, segmentGroup, route)
}

export function createSignatureTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('signature', segments, segmentGroup, route)
}

export function createQuoteTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('quote', segments, segmentGroup, route)
}

export function createDigitalTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('digital', segments, segmentGroup, route)
}

export function createDisplayTierRouteMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route
) {
  return _createTierRouteMatcher('display', segments, segmentGroup, route)
}
