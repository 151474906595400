import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  DependentValue,
  DependentValueLists,
} from '@shared/layer-property/layer-property.component'
import {
  QuoteChildValue,
  QuoteParentValue,
} from '../../../quote/models/quote-class.model'

export interface DependentPropertyDialogData {
  selectedValues: DependentValue
  valueLists: DependentValueLists
  title?: string
  parentTitle: string
  childTitle: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dependent-layer-property-dialog',
  styleUrls: ['dependent-layer-property-dialog.component.scss'],
  templateUrl: 'dependent-layer-property-dialog.component.html',
})
export class DependentLayerPropertyDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DependentLayerPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DependentPropertyDialogData
  ) {}

  parentTitle: string
  childTitle: string
  title: string

  parentValues: QuoteParentValue[]
  childValues: QuoteChildValue[]
  selectedParentId: number | undefined
  selectedChildId: number | undefined

  ngOnInit(): void {
    this.parentTitle = this.data.parentTitle
    this.childTitle = this.data.childTitle
    this.title = this.data.title ?? 'Dependent Properties'

    this.parentValues = this.data.valueLists.parentValues.values
    const parentEntry = this.parentValues.find(
      val => val.value === this.data.selectedValues.parent.value
    )
    this.selectedParentId = parentEntry?.id
    this.floatSelectedValueToTop('parent')
    // tslint:disable-next-line: no-non-null-assertion
    this.childValues = this.getChildrenForSelectedParentEntry(parentEntry!)

    if (this.data.selectedValues.child.value) {
      const childValue = this.data.selectedValues.child.value
      const childEntry = this.childValues.find(val => val.value === childValue)
      this.selectedChildId = childEntry ? childEntry.id : undefined
      this.floatSelectedValueToTop('child')
    }
  }

  get allParentValues(): QuoteParentValue[] {
    return this.data.valueLists.parentValues.values
  }

  get allChildValues(): QuoteChildValue[] {
    return this.data.valueLists.childValues.values
  }

  getClass(item: QuoteParentValue | QuoteChildValue, list: 'parent' | 'child') {
    const compareId =
      list === 'parent' ? this.selectedParentId : this.selectedChildId
    return { selected: item.id === compareId }
  }

  onSelect(
    selected: QuoteParentValue | QuoteChildValue,
    listIndex: number,
    list: 'parent' | 'child'
  ): void {
    const id = selected.id

    if (list === 'parent' && this.parentValues[listIndex]) {
      this.selectedParentId = id
      this.selectedChildId = undefined
      this.childValues = this.getChildrenForSelectedParentEntry(
        this.parentValues[listIndex]
      )
    } else if (list === 'child' && this.childValues[listIndex]) {
      this.selectedChildId = id
    }
  }

  get isSubClassSelectionValid(): boolean {
    // The subclass is valid if there is a selection or the empty selection id is present
    return (
      this.selectedChildId !== undefined ||
      this.childValues.some(val => val.id === -1)
    )
  }

  onOKClick() {
    if (this.selectedParentId === undefined || !this.isSubClassSelectionValid) {
      return
    }

    const parentEntry = this.parentValues.find(
      val => val.id === this.selectedParentId
    )
    const childEntry = this.childValues.find(
      val => val.id === this.selectedChildId
    )

    this.destroyDialog({
      parent: parentEntry,
      child: childEntry,
    })
  }

  floatSelectedValueToTop(list: 'parent' | 'child'): void {
    if (list === 'parent' && this.selectedParentId !== undefined) {
      const index = this.parentValues.findIndex(
        val => val.id === this.selectedParentId
      )
      const parentCopy = [...this.parentValues]
      const parentEntry = parentCopy.splice(index, 1)
      this.parentValues = parentEntry.concat(parentCopy)
    } else if (list === 'child' && this.selectedChildId !== undefined) {
      const index = this.childValues.findIndex(
        val => val.id === this.selectedChildId
      )
      const childCopy = [...this.childValues]
      const childEntry = childCopy.splice(index, 1)
      this.childValues = childEntry.concat(childCopy)
    }
  }

  getChildrenForSelectedParentEntry(
    parentEntry: QuoteParentValue
  ): QuoteChildValue[] {
    const childIds = parentEntry.subClassIds
    return this.allChildValues.filter(val => childIds.includes(val.id))
  }

  destroyDialog(data?: any): void {
    this.dialogRef.close(data)
  }
}
