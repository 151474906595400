import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PricingCurveContainerComponent } from './pricing-curve-container/pricing-curve.container'

const routes: Routes = [
  {
    path: '',
    component: PricingCurveContainerComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PricingCurveRoutingModule {}
