import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import { ProgramEntity } from '../../../store/grouper/program/program.reducer'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import {
  ScenarioEvent,
  ScenarioEventResult,
} from '../../animated-scenarios.model'
import { Subject } from 'rxjs'
import { Recorder } from '../../../../api/animated-scenarios/recorder'
import { takeUntil } from 'rxjs/operators'
import * as d3 from 'd3'
import {
  ProgramGroup,
  SharedIDGroup,
} from '../../../store/grouper/program-group.model'
import { Client } from '../../../../core/model/client.model'
import { Study } from '../../../../core/model/study.model'
import { MatDialog } from '@angular/material/dialog'
import { GroupScenarioEventTableDialogContainerComponent } from '../group-event-table-dialog.container/group-event-table-dialog.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-animated-scenarios',
  styleUrls: ['./group-animated-scenarios.component.scss'],
  templateUrl: './group-animated-scenarios.component.html',
})
export class GroupAnimatedScenariosComponent implements OnInit, OnDestroy {
  @Input() structureEntities: ProgramEntity[]
  @Input() lossSets: LossSetLayer[]
  @Input() executing: boolean
  @Input() scenarioEventsResults: ScenarioEventResult[]
  @Input() loadingGroup: boolean
  @Input() animating: boolean
  @Input() structureGroup: ProgramGroup | null
  @Input() currentClient: Client | null
  @Input() studies: Study[]
  @Input() sharedIDGroup: SharedIDGroup[]
  @Input() eventResultByEntity: Record<string, ScenarioEventResult[]>
  @Input() structureNameByEntity: Record<string, string>
  @Input() scenarioEvents: ScenarioEvent[]

  @Output() processEvents = new EventEmitter()
  @Output() animationEnd = new EventEmitter()
  @Output() reAnimate = new EventEmitter()

  isRecording: boolean
  private destroy$ = new Subject()

  constructor(
    private recorderService: Recorder,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.recorderService.isRecording
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.isRecording = val
        this.ref.detectChanges()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onEventClick(): void {
    if (!this.executing) {
      this.dialog.open(GroupScenarioEventTableDialogContainerComponent)
    }
  }

  onProcessEvents(): void {
    this.processEvents.emit()
  }

  onReAnimate(): void {
    if (this.scenarioEventsResults.length > 0) {
      this.reAnimate.emit()
      this.eventResultByEntity = JSON.parse(
        JSON.stringify(this.eventResultByEntity)
      )
    }
  }

  onRecord(): void {
    this.recorderService.startRecording(this.blobHandler)
  }

  onStopRecord(): void {
    this.recorderService.stopRecording()
  }

  private blobHandler(blob: Blob): void {
    const url = window.URL.createObjectURL(blob)
    const videoLink = d3
      .select('body')
      .append('a')
      .style('display', 'none')
      .attr('href', url)
      .attr('download', `animated-loss-${new Date().getTime()}.webm`)
      .attr('id', 'animated-loss-video')
    const element = videoLink.node()
    if (element) {
      element.click()
      videoLink.remove()
    }
  }
}
