import {
  PortfolioViewID,
  PortfolioSetAndStudyIDs,
} from '../../model/portfolio-set.model'
import { props, createAction } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { VolatilityMetricsResult } from '../../model/volatility-metrics.model'

export const fetchVolatilityMetrics = createAction(
  '[Volatility Metrics] Fetch',
  props<PortfolioSetAndStudyIDs & PortfolioViewID>()
)

export const fetchVolatilityMetricsSuccess = createAction(
  '[Volatility Metrics] Fetch Success',
  props<PortfolioSetAndStudyIDs & VolatilityMetricsResult>()
)

export const fetchVolatilityMetricsFailure = createAction(
  '[Volatility Metrics] Fetch Failure',
  props<PortfolioSetAndStudyIDs & { error: ApplicationError }>()
)
