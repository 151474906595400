import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private spinnerSubject = new Subject()
  public spinnerState = <Observable<boolean>>this.spinnerSubject
  public spinnerCounter: number = 0

  spinnerShow(): void {
    this.spinnerSubject.next(true)
  }

  spinnerHide(): void {
    this.spinnerSubject.next(false)
  }
}
