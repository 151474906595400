import { SortTableColumnDef } from '../sort-table.model'


export enum TableModeMenuOptions {
  SelectRegion = 'selectRegion',
  SelectTable = 'selectTable'
}

export enum SelectionModeMenuOptions {
  Clear = 'clear',
  Copy = 'copy',
  CopyWithHeaders = 'copyWithHeaders',
  Paste = 'paste'
}

export type MenuOptions = TableModeMenuOptions | SelectionModeMenuOptions

export enum UserInputEvents {
  ContextMenuTriggered = 'contextMenuTrigger',
  GlobalContextMenuTriggered = 'globalContextMenuTrigger',
  GlobalLeftClick = 'globalLeftClick',
  GlobalShiftLeftClick = 'globalLeftClick',
  Escape = 'escape',
  FocusGained = 'focusGained',
  FocusLost = 'focusLost',
  RightArrow = 'rightArrow',
  LeftArrow = 'leftArrow',
  UpArrow = 'upArrow',
  DownArrow = 'downArrow',
  Enter = 'enter',
  Tab = 'tab',
  ShiftTab = 'shiftTab',
  ShiftEnter = 'shiftEnter',
}

export type ExcelActions = MenuOptions | UserInputEvents

export enum ExcelStates {
  TableMode = 'tableMode',
  TableContextMenu = 'tableContextMenu',
  PromptRegionSelection = 'promptRegionSelection',
  SelectionIsMade = 'selectionMode',
  SelectionContextMenu = 'selectionContextMenu',
  Copy = 'copy',
  Paste = 'paste',
  RegionOperationSuccess = 'regionOperationSuccess',
  SelectionModeError = 'selectionModeError'
}

export interface CellIdentifier {
  row: number
  col: string | number | symbol
  elementRef: HTMLTableDataCellElement
}

export interface Region{
  c0: CellIdentifier
  c1: CellIdentifier
}

export interface SubsetRowsCols<T> {
  rows: any[][],
  cols: SortTableColumnDef<T>[]
}


export interface ExcelContextMenuOptions {
  id: MenuOptions
  viewText: string
  shortcut: string[]
}


export const excelSelectionModeOptions: ExcelContextMenuOptions[] = [
  {
    id: SelectionModeMenuOptions.Clear,
    viewText: 'Clear Selection',
    shortcut: ['escape']
  },

  {
    id: SelectionModeMenuOptions.Copy,
    viewText: 'Copy Selection',
    shortcut: ['ctrl', 'shift', 'c']
  },

  {
    id: SelectionModeMenuOptions.CopyWithHeaders,
    viewText: 'Copy Selection & Headers',
    shortcut: ['ctrl', 'shift', 'h']
  },

  {
    id: SelectionModeMenuOptions.Paste,
    viewText: 'Paste',
    shortcut: ['ctrl', 'shift', 'v']
  },
]

export const excelTableModeOptions: ExcelContextMenuOptions[] = [
  {
    id: TableModeMenuOptions.SelectTable,
    viewText: 'Select Table',
    shortcut: ['ctrl','shift','a']
  },
  {
    id: TableModeMenuOptions.SelectRegion,
    viewText: 'Select Region',
    shortcut: ['ctrl','shift','s']
  },
]

export interface ClipValues {
  top: number | string,
  right: number | string,
  bottom: number | string,
  left: number | string
}

export interface SortTableDataRef {
  col: number,
  row: number
}


export type LesserDomRect = Omit<DOMRect, 'toJSON'>
