import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Dictionary } from '@ngrx/entity'
import { Action, Store, select } from '@ngrx/store'
import { analyzereConstants } from '@shared/constants/analyzere'
import {
  difference,
  differenceWith,
  equals,
  path,
  pick,
  reverse,
  uniqBy,
} from 'ramda'
import { EMPTY, forkJoin, from, Observable, of, OperatorFunction, throwError } from 'rxjs'
import { getLayerTypeLimit } from 'src/app/analysis/store/metrics/calculations'
import {
  concatMap,
  delay,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  toArray,
  take,
  withLatestFrom,
} from 'rxjs/operators'
import { calculateLayerViewMetrics } from 'src/app/analysis/store/metrics/calculations'
import {
  isIndexedLayer,
  isIndexedMainLayer,
  isIndexedVisibleLayer,
} from 'src/app/analysis/layers/indexed-layer'
import {
  IndexedLayerService,
  matchingLayer,
} from 'src/app/analysis/layers/indexed-layer.service'
import {
  isMultiSectionLayer,
  isMultiSectionMainLayer,
  letterFromDescription,
} from 'src/app/analysis/layers/multi-section-layer'
import { MultiSectionLayerService } from 'src/app/analysis/layers/multi-section-layer.service'
import {
  createSwingCombinedLayer,
  createSwingSubLayer,
  getSwingIds,
  isSwingCombinedLayer,
  isSwingLayer,
  updateSwingLayers,
} from 'src/app/analysis/layers/swing-layer'
import {
  canInure,
  getInuranceSourceAndTargetReference,
} from 'src/app/analysis/model/inurance.util'
import {
  selectCededDictionary,
  selectCededPortfolioViewLayersViewIDs,
} from 'src/app/analysis/store/analysis.selectors'
import {
  convertFromLogicalPortfolioLayers,
  convertToHiddenLayer,
  convertToLogicalFromPhysicalResponse,
} from 'src/app/analysis/model/layers.converter'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import {
  filterValid,
  findActualTopAndDropLayer,
  findLayer,
  findLayerByVisibleId,
  findTopAndDropLayers,
  isLayerActualTopAndDrop,
  isLayerAgg,
  isLayerAggFeeder,
  isLayerDrop,
  isLayerTop,
  isLayerTopOrDrop,
} from 'src/app/analysis/model/layers.util'
import { Section } from 'src/app/analysis/properties/layer/multi-section/multi-section.service'
import * as fromAnalysisSelectors from '../analysis.selectors'
import * as fromLayersActions from '../ceded-layers/layers.actions'
import { LayerState } from './layers.reducer'
import { reconcileAncestorGroups } from '../grouper/grouper.actions'
import * as fromSharedLimitActions from '../grouper/shared-limit/grouper-shared-limit.actions'
import * as fromPortfolioViewActions from '../views/portfolio-view.actions'
import {
  LayerViewResponse,
  LogicalPortfolioLayer,
  LossSet,
  LossSetLayer,
  Metadata,
  Metrics,
  MonetaryUnit,
  PhysicalPortfolioLayer,
  Portfolio,
  Ref,
} from 'src/app/api/analyzere/analyzere.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import { ApiResponse, MaybeError } from 'src/app/api/model/api.model'
import {
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
  mergeMapWithInput,
} from 'src/app/api/util'
import { AppState } from 'src/app/core/store'
import { selectCurrentAnalysisProfile } from '../../../core/store/broker/broker.selectors'
import { selectProgramGroupsByID } from '../../../core/store/program-group/program-group.selectors'
import { selectProgramsByID } from '../../../core/store/program/program.selectors'
import { ApplicationError, errorPayload } from 'src/app/error/model/error'
import { waitFor } from 'src/app/shared/observables'
import { md5 } from '@shared/util/hash'
import { generateUUID } from 'three/src/math/MathUtils'
import { layerIds } from '../../model/layer-palette.model'
import { saveAnalysis, setIsNewDropLayerSaving } from '../analysis.actions'
import { BackAllocatedContribution } from '../../layer-details/layer-details.model'
import { GetBackAllocatedContributionDatum, LossSetContribution } from '../../model/layers-metrics.model'
import { LossSetLayer as AnalysisLossSetLayer } from '../../model/loss-set-layers.model'
import { TypedAction } from '@ngrx/store/src/models'

interface LogicalLayerResponse {
  xcoord: number
  ycoord: number
  data?: LogicalPortfolioLayer
  error?: ApplicationError
}

@Injectable()
export class LayersEffects {

  constructor(
    private service: AnalyzreService,
    private inuranceService: InuranceService,
    private indexedLayerService: IndexedLayerService,
    private mslService: MultiSectionLayerService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.fetchLayers),
      switchMap(action => {
        return this.service.fetchLayers<LogicalPortfolioLayer>(action.ids)
      }),
      rejectError(error =>
        this.store.dispatch(fromLayersActions.fetchLayersFailure({ error }))
      ),
      map(logicalLayers => {
        const layers: Layer[] = convertFromLogicalPortfolioLayers(logicalLayers)
        return fromLayersActions.fetchLayersSuccess({ layers })
      })
    )
  })

  updateMultipleSections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updateMultiplePhysicalLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([changes, layerStateById]) => {
        const actions: Action[] = []
        changes.change.forEach(change => {
          if (change.id) {
            const layer = layerStateById[change.logicalLayerID as string]
            if (!layer) {
              return
            }
            actions.push(
              fromLayersActions.updatePhysicalLayer({
                id: change.logicalLayerID as string,
                change,
                // debounce: true for non hidden layers. top & drop
                // debounce: false for hidden layer. it will run syncTopAndDrop()
                debounce: !isLayerActualTopAndDrop(layer.layer),
              })
            )
          }
        })
        actions.push(saveAnalysis())
        return actions
      })
    )
  })

  fetchSubsectionLayerMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.fetchSubsectionLayerMetrics),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentAnalysisProfileID)
        )
      ),
      mergeMapWithInput(([actions, profileID]) => {
        return this.service.postLayerView(
          actions.layerID,
          profileID || '',
          actions.currency
        )
      }),
      rejectError(error => {
        this.store.dispatch(
          fromLayersActions.fetchSubsectionLayerMetricsFailure({ error })
        )
      }),
      mergeMapWithInput(([view]) => {
        return this.service.getLayersViewMetrics(
          view?.id || '',
          getLayerTypeLimit(view?.layer || '', 'Occ'),
          getLayerTypeLimit(view?.layer || '', 'Agg')
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromLayersActions.fetchSubsectionLayerMetricsFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectCededPortfolioViewLayersViewIDs)),
        this.store.pipe(select(selectCededDictionary)),
        this.store.pipe(
          select(fromAnalysisSelectors.selectSharedLimitSelectedLayer)
        )
      ),
      map(
        ([
          [metrics, [view, [actions]]],
          viewIDs,
          layerStateById,
          sharedLimit,
        ]) => {
          metrics.id = view?.layerID || ''
          const calculatedMetrics = calculateLayerViewMetrics(
            metrics,
            viewIDs,
            layerStateById,
            undefined,
            undefined,
            sharedLimit || undefined,
            actions.layer
          )
          this.store.dispatch(
            fromLayersActions.addSublayerMetrics({
              layerMetrics: calculatedMetrics,
            })
          )
          return fromLayersActions.fetchSubsectionLayerMetricsSuccess({
            layerID: metrics.id,
          })
        }
      )
    )
  })

  syncTopAndDropLayers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.syncTopAndDropLayers),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary)),
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
      ),
      switchMap(([response, layerStateById, cededLayersStates]) => {
        const actions: Action[] = []
        const drivingLayer = layerStateById[response.drivingLayerID]
        if (!drivingLayer) {
          return actions
        }
        const layerMapping = cededLayersStates.map(l => l.layer)
        const sharedLayer = isLayerActualTopAndDrop(drivingLayer.layer)
          ? drivingLayer.layer
          : findActualTopAndDropLayer(
              cededLayersStates.map(l => l.layer),
              drivingLayer.layer.id
            )
        if (!sharedLayer) {
          return actions
        }
        // In theory it should have existing and new drop layers accounted for.
        const topDropGroupLayers = [
          ...findTopAndDropLayers(layerMapping, sharedLayer),
          sharedLayer,
        ]
        // ? The driving layer will be hidden when dialog is saved and closed.
        // ? The driving layer will be top and drop when onLayerResize() properties.containter.ts:507
        // ? keeps rol, premium in sync with all layers
        let premium = drivingLayer.layer.physicalLayer.premium.value
        let rol = drivingLayer.layer.physicalLayer.meta_data.rol || 0
        const sharedLimit = sharedLayer.physicalLayer.limit.value
        if (response.drivingProperty === 'rolPercentage') {
          if (sharedLimit >= analyzereConstants.unlimitedValue && rol !== 0) {
            premium = analyzereConstants.unlimitedValue
            rol = 1
          } else {
            premium = rol * sharedLimit
          }
        } else if (response.drivingProperty === 'premium') {
          rol = premium / sharedLimit
        } else if (response.drivingProperty === 'sharedLimit') {
          if (premium === 0) {
            rol = 0
          } else {
            rol = premium / sharedLimit
          }
        }
        topDropGroupLayers.forEach(layer => {
          // `layer.meta_data.rol` should be in sync with `layer.physicalLayer.meta_data.rol`
          actions.push(
            fromLayersActions.updateLayer({
              id: layer.id,
              change: {
                meta_data: {
                  ...layer.meta_data,
                  rol,
                },
              },
            })
          )

          // debounce: true -> it won't run the syncTopAndDrop()
          actions.push(
            fromLayersActions.updatePhysicalLayer({
              id: layer.id,
              change: {
                ...layer.physicalLayer,
                premium: {
                  ...layer.physicalLayer.premium,
                  value: premium,
                },
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  rol,
                },
              },
              debounce: true,
            })
          )
        })
        return actions
      })
    )
  })

  dirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromLayersActions.updateLayer,
        fromLayersActions.updatePhysicalLayer
      ),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary)),
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
      ),
      switchMap(([response, layerStateById, cededLayersStates]) => {
        const actions: any[] = []

        const layerState = layerStateById[response.id]
        const layer = layerState?.layer
        if (layerState === undefined || layer === undefined) {
          return []
        }
        if (response.type === fromLayersActions.updatePhysicalLayer.type) {
          if (isLayerTopOrDrop(layer) || isLayerActualTopAndDrop(layer)) {
            // ? Runs for hidden layer when dialog is saved and closed.
            // ? Runs the first time hidden layer is created, when top layer's physical layer is updated with first drop layer
            // ? Runs for top or drop after onLayerResize(debounce: false)
            if (!response.debounce) {
              let drivingProperty: string
              const sharedLayer = isLayerActualTopAndDrop(layer)
                ? layer
                : findActualTopAndDropLayer(
                    cededLayersStates.map(l => l.layer),
                    layer.id
                  )
              if (sharedLayer) {
                if (isLayerActualTopAndDrop(layer)) {
                  drivingProperty = 'sharedLimit'
                } else {
                  drivingProperty = response.property || 'sharedLimit'
                }
                actions.push(
                  fromLayersActions.syncTopAndDropLayers({
                    drivingLayerID: layer.id,
                    drivingProperty,
                  })
                )
              }
            }
          }
        }
        if (response.type === fromLayersActions.updateLayer.type) {
          const layerName = response.change.meta_data?.layerName
          if (layerName) {
            if (
              layer.meta_data.isRiskVisible &&
              layer.meta_data.riskActualLayerID
            ) {
              const actualLayer =
                layerStateById[layer.meta_data.riskActualLayerID]?.layer
              if (actualLayer) {
                let metaData = actualLayer.meta_data
                metaData = {
                  ...metaData,
                  layerName,
                }
                actions.push(
                  fromLayersActions.updateLayer({
                    id: actualLayer.id,
                    change: { meta_data: metaData },
                  })
                )
              }
            } else if (
              isIndexedLayer(layer, 'visible-layer') &&
              layer.meta_data.main_layer_id
            ) {
              const mainLayer = layerStateById[layer.meta_data.main_layer_id]
              if (
                mainLayer &&
                mainLayer.layer.meta_data.layerName !== layerName
              ) {
                actions.push(
                  fromLayersActions.updateLayer({
                    id: mainLayer.layer.id,
                    change: {
                      meta_data: {
                        ...mainLayer.layer.meta_data,
                        layerName,
                      },
                    },
                  })
                )
              }
            } else if (isSwingLayer(layer, 'visible-layer')) {
              const combinedLayer = cededLayersStates.find(
                ls => ls.layer.meta_data.visible_layer_id === layer.id
              )

              if (
                combinedLayer &&
                combinedLayer.layer.meta_data.layerName !== layerName
              ) {
                actions.push(
                  fromLayersActions.updateLayer({
                    id: combinedLayer.layer.id,
                    change: {
                      meta_data: {
                        ...combinedLayer.layer.meta_data,
                        layerName,
                      },
                    },
                  })
                )
              }
            } else if (isMultiSectionLayer(layer, 'visible-layer')) {
              const mainLayer = Object.values(layerStateById).find(
                ls => ls?.layer.meta_data.visible_layer_id === layer.id
              )

              if (
                mainLayer &&
                mainLayer.layer.meta_data.layerName !== layerName
              ) {
                actions.push(
                  fromLayersActions.updateLayer({
                    id: mainLayer.layer.id,
                    change: {
                      meta_data: {
                        ...mainLayer.layer.meta_data,
                        layerName,
                      },
                      physicalLayer: {
                        ...mainLayer.layer.physicalLayer,
                        description: layerName,
                      },
                    },
                  })
                )

                actions.push(
                  fromLayersActions.updateLayer({
                    id: layer.id,
                    change: {
                      meta_data: {
                        ...layer.meta_data,
                        layerName,
                      },
                      physicalLayer: {
                        ...layer.physicalLayer,
                        description: layerName,
                      },
                    },
                  })
                )

                const sectionLayers = mainLayer.layer.layerRefs.map(id => {
                  const sectionLayer = findLayer(
                    cededLayersStates.map(l => l.layer),
                    id
                  )
                  if (sectionLayer === undefined) {
                    throw Error('Unable to find section layer.')
                  }
                  return sectionLayer
                })
                sectionLayers.forEach(sectionLayer => {
                  actions.push(
                    fromLayersActions.updateLayer({
                      id: sectionLayer.id,
                      change: {
                        meta_data: {
                          ...sectionLayer.meta_data,
                          layerName,
                        },
                      },
                    })
                  )
                })
              }
            }
          } else if (response.change.lossSetLayers) {
            if (
              isIndexedLayer(layer, 'visible-layer') &&
              layer.meta_data.main_layer_id
            ) {
              const mainLayer = layerStateById[layer.meta_data.main_layer_id]
              if (mainLayer) {
                actions.push(
                  fromLayersActions.updateLayer({
                    id: mainLayer.layer.id,
                    change: {
                      meta_data: {
                        ...mainLayer.layer.meta_data,
                      },
                    },
                  })
                )
              }
            }
          }
        }

        const newHash = md5(layer)
        if (
          newHash === layerState.hash &&
          !layerState.new &&
          !isIndexedMainLayer(layer)
        ) {
          actions.push(
            fromLayersActions.setDirty({ id: response.id, dirty: false })
          )
        } else {
          actions.push(
            fromLayersActions.setDirty({ id: response.id, dirty: true })
          )
        }
        return actions
      })
    )
  })

  save$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromLayersActions.saveLayers),
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
        ),
        switchMap(([_, layerStates]) => {
          let saveLayers: Layer[] = layerStates
            .filter(layerState => !layerState.deleted && layerState.dirty)
            .map(({ layer }) => layer)
          if (saveLayers.length === 0) {
            return of({})
          }
          // when saving the layers isLossSetUpdated field of layer metadata should always be false (New field added for quote sage update on ceded lossset update)
          saveLayers = saveLayers.map(x => ({
            ...x,
            meta_data: {
              ...x.meta_data,
              isLossSetUpdated: false,
            },
          }))

          // Instead receive the visible, load the main and recalc since the meta is stored in the visible
          const updatedIndexed = saveLayers
            .filter(isIndexedVisibleLayer)
            .flatMap(visibleLayer => {
              const mainLayer = layerStates
                .map(ls => ls.layer)
                .find(matchingLayer(visibleLayer.meta_data.main_layer_id))
              if (mainLayer) {
                return this.indexedLayerService.updateIndexedSettlements(
                  mainLayer,
                  visibleLayer
                )
              }
            })
            .filter(l => l !== undefined)

          const updatedLossSetGroup = saveLayers
            .filter(isIndexedMainLayer)
            .flatMap(mainLayer => {
              const visibleLayer = layerStates
                .map(ls => ls.layer)
                .find(matchingLayer(mainLayer.meta_data.visible_layer_id))
              if (visibleLayer) {
                return this.indexedLayerService.updateLossSetGroup(visibleLayer)
              }
            })
            .filter(l => l !== undefined)

          if (updatedIndexed.length > 0) {
            return forkJoin([...updatedIndexed, ...updatedLossSetGroup]).pipe(
              // tslint:disable-next-line: no-shadowed-variable
              switchMap(_ => this.service.saveLayers(saveLayers))
            )
          } else {
            return this.service.saveLayers(saveLayers)
          }
        }),
        rejectError(error =>
          this.store.dispatch(fromLayersActions.saveFailure({ error }))
        ),
        withLatestFrom(
          this.store.pipe(
            select(fromAnalysisSelectors.selectEditorPortfolioSetID)
          ),
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers)),
          this.store.pipe(
            select(fromAnalysisSelectors.selectNetPortfolioLayersIDs)
          ),
          this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayers)),
          this.store.pipe(
            select(fromAnalysisSelectors.selectParentGrossLossSetLayers)
          )
        ),
        map(
          ([
            _,
            portfolioSetID,
            layerStates,
            netLayerIDs,
            lossSetLayers,
            parentGrossLossSets,
          ]) => {
            if (!portfolioSetID) {
              return {
                error: errorPayload('Cannot save layers w/o portfolio IDs '),
              }
            }
            const newLayers = layerStates
              .filter(layerState => layerState.new && !layerState.deleted)
              .map(({ layer }) => layer)
              .filter(filterValid)
            const existingLayers = layerStates
              .filter(layerState => !layerState.new && !layerState.deleted)
              .map(({ layer }) => layer)
              .filter(filterValid)
              .map(layer => layer.id)
            const hiddenFHCFLayers = layerStates
              .filter(
                ({ layer }) =>
                  layer.meta_data.isFHCFHidden1 || layer.meta_data.isFHCFHidden2
              )
              .map(({ layer }) => layer.id)

            const hiddenRiskLayers = layerStates
              .filter(
                ({ layer }) =>
                  layer.meta_data.isRiskLargeHidden ||
                  layer.meta_data.isRiskCatHidden
              )
              .map(({ layer }) => layer.id)

            const hiddenSwingLayers = layerStates
              .filter(({ layer }) =>
                isSwingLayer(
                  layer,
                  'loss-layer',
                  'premium-layer',
                  'adjustment-layer'
                )
              )
              .map(({ layer }) => layer.id)

            const hiddenMultiSectionLayers = layerStates
              .filter(
                ({ layer }) =>
                  isMultiSectionLayer(layer) && !isMultiSectionMainLayer(layer)
              )
              .map(({ layer }) => layer.id)

            const deletedLayers = layerStates
              .filter(layerState => !layerState.new && layerState.deleted)
              .map(({ layer }) => layer.id)

            const deletedNestedBackAllocatedLayers: string[] =
              this.getDeletedBackAllocatedLayers(layerStates).map(
                layer => layer.id
              )

            return {
              data: {
                portfolioSetID,
                newLayers,
                existingLayers,
                hiddenFHCFLayers,
                hiddenRiskLayers,
                hiddenSwingLayers,
                hiddenMultiSectionLayers,
                deletedLayers,
                deletedNestedBackAllocatedLayers,
                netLayerIDs,
                lossSetLayers,
                parentGrossLossSets,
              },
            }
          }
        ),
        rejectError(error =>
          this.store.dispatch(fromLayersActions.saveFailure({ error }))
        )
      )
      .pipe(
        switchMapWithInput(
          ({
            portfolioSetID,
            newLayers,
            existingLayers,
            hiddenFHCFLayers,
            hiddenRiskLayers,
            hiddenSwingLayers,
            hiddenMultiSectionLayers,
            deletedLayers,
            deletedNestedBackAllocatedLayers,
            netLayerIDs,
            lossSetLayers,
            parentGrossLossSets,
          }) => {
            // Resolve netLayerIDs with gross loss sets
            // Remove any parent gross loss sets from net
            const netLayersWithLossSets = netLayerIDs.filter(
              l => !parentGrossLossSets.map(pgl => pgl.id).includes(l)
            )
            // Add all active loss sets back to net
            netLayersWithLossSets.push(...lossSetLayers.map(l => l.id))

            if (newLayers.length === 0 && deletedLayers.length === 0) {
              return this.service
                .fetchPortfolio(portfolioSetID.cededPortfolioID)
                .pipe(
                  switchMap(res => {
                    if (res.error) {
                      return of({ error: res.error })
                    } else {
                      return this.service.patchPortfolio({
                        id: portfolioSetID.cededPortfolioID,
                        change: {
                          meta_data: {
                            // tslint:disable: no-non-null-assertion
                            ...res.data!.meta_data,
                          },
                        },
                      })
                    }
                  }),
                  switchMap(_ => {
                    return this.service.updatePortfolioLayers(
                      portfolioSetID.netPortfolioID,
                      netLayersWithLossSets
                    )
                  })
                )
            } else {
              if (newLayers.length === 0) {
                const updates = []
                updates.push(
                  this.service
                    .updatePortfolioLayers(
                      portfolioSetID.cededPortfolioID,
                      existingLayers.filter(
                        id => !deletedNestedBackAllocatedLayers.includes(id)
                      )
                    )
                    .pipe(
                      switchMap(res => {
                        if (res.error) {
                          return of({ error: res.error })
                        } else {
                          return this.service.patchPortfolio({
                            id: res.data!.id,
                            change: {
                              meta_data: {
                                ...res.data!.meta_data,
                              },
                            },
                          })
                        }
                      })
                    ),
                  this.service.updatePortfolioLayers(
                    portfolioSetID.netPortfolioID,
                    netLayersWithLossSets.filter(
                      l =>
                        ![
                          ...deletedLayers,
                          ...deletedNestedBackAllocatedLayers,
                        ].includes(l)
                    )
                  )
                )
                return forkJoin(updates).pipe(
                  map(responses => {
                    for (const response of responses) {
                      if (response.error) {
                        return { error: response.error }
                      }
                    }
                    return {}
                  })
                )
              } else {
                return this.service
                  .updatePortfolioLayers(
                    portfolioSetID.cededPortfolioID!,
                    [...existingLayers, ...newLayers.map(l => l.id)].filter(
                      l =>
                        ![
                          ...hiddenFHCFLayers,
                          ...hiddenRiskLayers,
                          ...hiddenSwingLayers,
                          ...hiddenMultiSectionLayers,
                          ...deletedLayers,
                          ...deletedNestedBackAllocatedLayers,
                        ].includes(l)
                    )
                  )
                  .pipe(
                    switchMap(res => {
                      if (res.error) {
                        return of({ error: res.error })
                      } else {
                        return this.service.patchPortfolio({
                          id: res.data!.id,
                          change: {
                            meta_data: {
                              ...res.data!.meta_data,
                            },
                          },
                        })
                      }
                    }),
                    switchMap(response => {
                      if (response.error) {
                        return of({ error: response.error })
                      } else {
                        return this.service.updatePortfolioLayers(
                          // tslint:disable no-non-null-assertion
                          portfolioSetID.netPortfolioID!,
                          [
                            ...netLayersWithLossSets,
                            ...newLayers.map(l => l.id),
                          ].filter(
                            l =>
                              ![
                                ...hiddenFHCFLayers,
                                ...hiddenRiskLayers,
                                ...hiddenSwingLayers,
                                ...hiddenMultiSectionLayers,
                                ...deletedLayers,
                                ...deletedNestedBackAllocatedLayers,
                              ].includes(l)
                          )
                        )
                      }
                    })
                  )
              }
            }
          }
        ),
        rejectErrorWithInput(error =>
          this.store.dispatch(fromLayersActions.saveFailure({ error }))
        )
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
        ),
        concatMap(([data, layers]) => {
          const FHCFHidden1 = layers.find(l => l.layer.meta_data.isFHCFHidden1)!
          const FHCFHidden2 = layers.find(l => l.layer.meta_data.isFHCFHidden2)!
          const updates = []
          if (FHCFHidden1 && FHCFHidden2) {
            const lossRank1ID = FHCFHidden1?.layer.lossSetLayers[0].id!
            const lossRank2ID = FHCFHidden2?.layer.lossSetLayers[0].id!
            const lossSetsFHCFArray: Ref[] = []
            const fHCF1LossSets = FHCFHidden1.layer.lossSetLayers[0]
              .loss_sets as LayerRef[]
            if (fHCF1LossSets.length > 0) {
              fHCF1LossSets.forEach((a: LayerRef) => {
                lossSetsFHCFArray.push({
                  ref_id: a.id,
                })
              })
              updates.push(
                this.service.patchLayer<LossSetLayer>({
                  id: lossRank1ID,
                  change: { loss_sets: lossSetsFHCFArray },
                })
              )
              updates.push(
                this.service.patchLayer<LossSetLayer>({
                  id: lossRank2ID,
                  change: { loss_sets: lossSetsFHCFArray },
                })
              )
            }
          }
          if (updates.length > 0) {
            return forkJoin(updates).pipe(
              map(responses => {
                for (const response of responses) {
                  if (response.error) {
                    return { error: response.error }
                  }
                }
                return { data }
              })
            )
          } else {
            return of({})
          }
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(
            select(fromAnalysisSelectors.selectCurrentProgramAllAncestorGroups)
          )
        ),
        tap(([_, ancestorGroups]) => {
          this.store.dispatch(reconcileAncestorGroups({ ancestorGroups }))
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
        ),
        tap(([_, layers]: [unknown, LayerState[]]) => {
          const deletedNestedBackAllocatedLayers: Layer[] =
            this.getDeletedBackAllocatedLayers(layers)
          const modifiedLayers = uniqBy(
            l => l.layer.sharedLayerID,
            layers.filter(
              l =>
                (l.dirty && !l.new && l.layer.sharedLayerID && !l.deleted) ||
                (l.deleted && l.layer.sharedLayerID && !l.new)
            )
          )
          const deletedLayerIDs = modifiedLayers
            .filter(m => m.deleted)
            .map(l => l.layer.id)
          const sharedLayers: Layer[] = []
          modifiedLayers.forEach(modifiedLayer => {
            const sharedLayer = layers.find(
              l => l.layer.id === modifiedLayer.layer.sharedLayerID
            )
            sharedLayers.push(sharedLayer!.layer)
          })
          const sharedLimitMarkedForDeletion: Layer[] = []
          sharedLayers.forEach(sharedLayer => {
            const referencedLayers = sharedLayer.layerRefs.filter(
              ref => !deletedLayerIDs.includes(ref)
            )
            if (referencedLayers.length <= 1) {
              sharedLimitMarkedForDeletion.push(sharedLayer)
            }
          })
          if (sharedLimitMarkedForDeletion.length > 0) {
            sharedLimitMarkedForDeletion.forEach(s => {
              this.store.dispatch(
                fromSharedLimitActions.deleteSharedLimit({
                  sharedLimitLayer: s,
                })
              )
            })
          }
          const sharedLimitMarkedForDeletionIDs =
            sharedLimitMarkedForDeletion.map(s => s.id)
          this.store.dispatch(
            fromSharedLimitActions.updateSharedLimit({
              sharedLimitLayers: sharedLayers.filter(
                s => !sharedLimitMarkedForDeletionIDs.includes(s.id)
              ),
              deletedNestedBackAllocatedLayers,
            })
          )
        }),
        waitFor(this.actions$, [
          [
            fromSharedLimitActions.updateSharedLimitSucccess,
            fromSharedLimitActions.updateSharedLimitFailure,
          ],
        ]),
        delay(100)
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers)),
          this.store.pipe(select(selectProgramsByID)),
          this.store.pipe(select(selectProgramGroupsByID))
        ),
        concatMap(([_, _layerStates, programsByID, programGroupByID]) => {
          const inuringLayerStates = _layerStates.filter(layerState =>
            canInure(layerState.layer)
          )

          const inuranceSourceAndTargetReferences =
            getInuranceSourceAndTargetReference(inuringLayerStates)
          const deletedNestedBackAllocatedLayers =
            this.getDeletedBackAllocatedLayers(inuringLayerStates)

          const deletedLayers = inuringLayerStates
            .filter(layerState => layerState.deleted && !layerState.new)
            .map(layerState => layerState.layer)
          const newLayers = inuringLayerStates
            .filter(layerState => layerState.new && !layerState.deleted)
            .map(layerState => layerState.layer)

          // TODO: Handle structureGroup. For now, only support structures.
          return this.inuranceService.reconcileInuranceOnSave(
            inuranceSourceAndTargetReferences,
            newLayers,
            [...deletedLayers, ...deletedNestedBackAllocatedLayers],
            programsByID,
            programGroupByID
          )
        }),
        rejectError(error =>
          this.store.dispatch(fromLayersActions.saveFailure({ error }))
        ),
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCurrentProgram))
        ),
        switchMap(([_, program]) => {
          return this.service
            .fetchPortfolioWithAggFeederAndRiskVisible(
              program!.cededPortfolioID
            )
            .pipe(
              map(res => {
                if (res.error) {
                  return { error: res.error }
                } else {
                  return { data: { ...res.data!, structureID: program!.id } }
                }
              })
            )
        }),
        rejectError(error =>
          this.store.dispatch(fromLayersActions.saveFailure({ error }))
        ),
        map(portfolioWithStructureID => {
          const layers = convertFromLogicalPortfolioLayers(
            portfolioWithStructureID.layers as LogicalPortfolioLayer[]
          )
          return fromLayersActions.saveSuccess({
            structureID: portfolioWithStructureID.structureID,
            layers,
          })
        })
      )
  })

  addLayer$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromLayersActions.addLayer),
        map(action => action.layer),
        withLatestFrom(
          /* holds the existing onscreen layers */
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers)),
          this.store.pipe(select(selectCurrentAnalysisProfile))
        ),
        // @ts-ignore
        concatMap(([layer, existingLayers, analysisProfile]) => {
          let inuranceTarget
          if (
            layer.physicalLayer.meta_data.sage_layer_type === layerIds.ilwBin ||
            layer.physicalLayer.meta_data.sage_layer_type === layerIds.ilwProRata ||
            layer.physicalLayer.meta_data.sage_layer_type === layerIds.ilwAg
          ) {
            const startDate =
              analysisProfile?.simulation.start_date ?? '1900-01-01'
            const incYear = startDate.substring(0, 4)
            const incDate = incYear + '-01-01T23:59:59'
            const expDate = +incYear + 1 + '-01-01T00:00:00'
            layer = {
              ...layer,
              physicalLayer: {
                ...layer.physicalLayer,
                inception_date: incDate,
                expiry_date: expDate,
              },
            }
          }
          if (canInure(layer)) {
            const inuranceSourceAndTargetReferences =
              getInuranceSourceAndTargetReference(existingLayers)
            inuranceTarget = this.inuranceService.reconcileInuranceOnAdd(
              inuranceSourceAndTargetReferences,
              layer
            )
          } else {
            inuranceTarget = layer
          }

          if (inuranceTarget.meta_data.isRiskLargeHidden) {
            return this.service.createPhysicalLayer(inuranceTarget).pipe(
              map(res => ({
                ...res,
                xcoord: layer.physicalLayer.xcoord,
                ycoord: layer.physicalLayer.ycoord,
              }))
            )
          } else {
            return this.service.createFullLayer(inuranceTarget).pipe(
              map(res => ({
                ...res,
                xcoord: layer.physicalLayer.xcoord,
                ycoord: layer.physicalLayer.ycoord,
              }))
            )
          }
        }),
        withLatestFrom(
          this.store.pipe(
            select(fromAnalysisSelectors.selectCurrentAnalysisProfileID)
          )
        )
      )
      .pipe(
        concatMap(([res, analysisProfileID]) => {
          if (res.error) {
            return of({ error: res.error } as MaybeError & {
              logicalLayerResponse: LogicalLayerResponse
              data?: LayerViewResponse & { layerID: string }
            })
          }
          const layerId = res.data!.id
          return this.service.postLayerView(layerId, analysisProfileID!).pipe(
            map(viewRes => ({
              logicalLayerResponse: res,
              ...viewRes,
            }))
          )
        }),
        withLatestFrom(
          this.store.pipe(
            select(fromAnalysisSelectors.selectEditorPortfolioSetID)
          ),
          this.store.pipe(
            select(fromAnalysisSelectors.selectCededFHCFHiddenLayer)
          ),
          this.store.pipe(select(fromAnalysisSelectors.selectCededRiskLayer)),
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers)),
          this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayers))
        ),
        concatMap(
          ([
            res,
            portfolioSetID,
            FHCFLayerState,
            riskLayerState,
            currentLayers,
            lossSetLayers,
          ]) => {
            const actions = []
            if (res.error || !portfolioSetID) {
              const error =
                res.error ||
                errorPayload('Cannot add layer, no editor portfolio ID')
              actions.push(fromLayersActions.addLayerFailure({ error }))
            } else {
              let logicalLayerResponse = res.logicalLayerResponse
                .data! as LogicalPortfolioLayer
              if (res.logicalLayerResponse.data?.meta_data.isRiskLargeHidden) {
                logicalLayerResponse = convertToLogicalFromPhysicalResponse(
                  res.logicalLayerResponse.data! as PhysicalPortfolioLayer
                )
              }
              const layers: Layer[] = convertFromLogicalPortfolioLayers([
                logicalLayerResponse,
              ])
              let layer: Layer
              if (layers.length === 1) {
                layer = layers[0]
              } else {
                // tslint:disable-next-line: no-non-null-assertion
                layer = layers.find(_layer => {
                  const meta = _layer.meta_data
                  return (
                    (meta.sage_layer_type === 'cat_td' &&
                      meta.sage_layer_subtype === 'actual') ||
                    meta.isFHCFFinal ||
                    meta.isRiskFinal ||
                    isIndexedLayer(_layer, 'main-layer') ||
                    isSwingLayer(_layer, 'combined-layer') ||
                    isMultiSectionLayer(_layer, 'main-layer')
                  )
                })!
              }

              layer.physicalLayer.xcoord = res.logicalLayerResponse.xcoord
              layer.physicalLayer.ycoord = res.logicalLayerResponse.ycoord
              actions.push(fromLayersActions.addLayerSuccess({ layer }))

              actions.push(
                fromPortfolioViewActions.addCededLayerView({
                  ...portfolioSetID,
                  // tslint:disable-next-line: no-non-null-assertion
                  viewID: res.data!.id,
                  // tslint:disable-next-line: no-non-null-assertion
                  layerID: res.data!.layerID,
                })
              )

              if (isLayerAggFeeder(layer)) {
                // check if feeder layer already exists
                const feeder = currentLayers.find(layerState =>
                  isLayerAggFeeder(layerState.layer)
                )
                const aggLayers = currentLayers.filter(
                  x =>
                    isLayerAgg(x.layer) &&
                    x.layer.layerRefs.length <= 0 &&
                    x.layer.physicalLayer.meta_data.isAutoBuild === true
                )
                if (!feeder && aggLayers) {
                  aggLayers.forEach(agLayer => {
                    actions.push(
                      fromLayersActions.updatePhysicalLayer({
                        id: layer.id,
                        change: {
                          ...layer.physicalLayer,
                          attachment: agLayer.layer.physicalLayer.attachment,
                          limit: agLayer.layer.physicalLayer.limit,
                          participation:
                            agLayer.layer.physicalLayer.participation,
                          description: agLayer.layer.meta_data.layerName,
                          meta_data: {
                            ...layer.meta_data,
                            layerName: agLayer.layer.meta_data.layerName,
                          },
                        },
                      }),
                      fromLayersActions.updateLayer({
                        id: agLayer.layer.id,
                        change: {
                          layerRefs: [...agLayer.layer.layerRefs, layer.id],
                        },
                      })
                    )
                  })
                }
              }
              // 1. Top Layer is created
              // 2. Drop layer is created with top layer data.
              // 3. If no drop layers exist, hidden (combined) layer is created with drop layer data.
              // 4. If drop layers exist, update hidden layer with existing drop layers data.

              // Top layer.
              // type = "cat_td" & subtype = 'virtual'
              if (isLayerTop(layer)) {
                // ? How are top and drop related or linked?
                actions.push(
                  fromLayersActions.addDropLayer({ topLayer: layer })
                )
              }

              // Hidden layer.
              // type = "cat_td" & subtype = "actual"
              // is this even needed? Line: 1266 runs updatePhysicalLayer() with debounce: false
              // which runs syncAndDropLayers() too. This runs twice?
              if (isLayerActualTopAndDrop(layer)) {
                // TODO: Sync hidden layer with top and drop.
                actions.push(
                  fromLayersActions.syncTopAndDropLayers({
                    drivingLayerID: layer.id,
                    drivingProperty: 'sharedLimit',
                  })
                )
              }

              // Drop layer.
              // type = "drop", no subtype
              if (layer.meta_data.isDrop) {
                const hiddenLayer = (currentLayers as LayerState[]).find(
                  l =>
                    isLayerActualTopAndDrop(l.layer) &&
                    layer.meta_data.topID &&
                    l.layer.layerRefs.includes(layer.meta_data.topID)
                )
                if (hiddenLayer) {
                  // Update hidden layer layerRefs to link new drop layer.
                  actions.push(
                    fromLayersActions.updateLayer({
                      id: hiddenLayer.layer.id,
                      change: {
                        layerRefs: [...hiddenLayer.layer.layerRefs, layer.id],
                      },
                    })
                  )
                  actions.push(setIsNewDropLayerSaving({ value: false }))
                } else {
                  actions.push(
                    fromLayersActions.addLayer({
                      layer: convertToHiddenLayer(layer),
                    })
                  )
                }
              }

              // Update top layer's physical layer attachment with top and drop layers data.
              if (isLayerActualTopAndDrop(layer)) {
                // * actual layer layerRefs has top and drop layer ids.
                const topLayer = currentLayers.find(
                  (iterator: LayerState) =>
                    isLayerTop(iterator.layer) &&
                    layer.layerRefs.includes(iterator.layer.id)
                )?.layer
                // TODO: Get all drop layers (might no be needed)
                // ? This only happens the first time hidden layer is created. So it would only set this attachment once with the first drop layer data.
                // ? Is attachment modifiable in dialog?
                const dropLayer = currentLayers.find(
                  (iterator: LayerState) =>
                    isLayerDrop(iterator.layer) &&
                    layer.layerRefs.includes(iterator.layer.id)
                )?.layer
                if (topLayer && dropLayer) {
                  // debounce: false -> it will run syncTopAndDrop()
                  // This will run when hidden layer is created.
                  // It will update the top layer phyisical layer attachments with drop layer data attachment + limit
                  actions.push(
                    fromLayersActions.updatePhysicalLayer({
                      id: topLayer.id,
                      change: {
                        ...topLayer.physicalLayer,
                        attachment: {
                          ...topLayer.physicalLayer.attachment,
                          value:
                            dropLayer.physicalLayer.attachment.value +
                            dropLayer.physicalLayer.limit.value,
                        },
                      },
                    })
                  )

                  // Make sure that the hidden layer limit is not unlimited
                  const topLayerLimit = topLayer.physicalLayer.limit.value
                  const dropLayerLimit = dropLayer.physicalLayer.limit.value
                  const hiddenLayerLimit = topLayerLimit > dropLayerLimit ? topLayerLimit : dropLayerLimit
                  actions.push(
                    fromLayersActions.updatePhysicalLayer({
                      id: layer.id,
                      change: {
                        ...layer.physicalLayer,
                        limit: {
                          ...topLayer.physicalLayer.limit,
                          value: hiddenLayerLimit
                        },
                      },
                    })
                  )
                }
              }

              if (layer.meta_data.isFHCFFinal) {
                let FHCFHidden1
                for (const i of layer.lossSetLayers) {
                  FHCFHidden1 = FHCFLayerState.filter(
                    (l: { layer: { id: string } }) => i.id === l.layer.id
                  )
                  if (FHCFHidden1.length) {
                    break
                  }
                }
                if (FHCFHidden1) {
                  actions.push(
                    fromLayersActions.updatePhysicalLayer({
                      id: FHCFHidden1[0].layer.id,
                      change: FHCFHidden1[0].layer.physicalLayer,
                    })
                  )
                }
              }

              if (layer.meta_data.isRiskFinal) {
                let riskVisible
                riskVisible = riskLayerState.find(
                  (l: { layer: { id: string | undefined } }) =>
                    layer.meta_data.riskVisibleLayerID === l.layer.id
                )
                if (riskVisible) {
                  actions.push(
                    fromLayersActions.updatePhysicalLayer({
                      id: riskVisible.layer.id,
                      change: riskVisible.layer.physicalLayer,
                    })
                  )
                }
              }

              if (isIndexedLayer(layer, 'main-layer')) {
                const visible = this.indexedLayerService.createIndexedVisible(
                  layer,
                  lossSetLayers.map(
                    (lossSetLayer: { id: any }) => lossSetLayer.id
                  )
                )
                actions.push(fromLayersActions.addLayer({ layer: visible }))
              }

              if (isSwingLayer(layer, 'combined-layer')) {
                const visible = currentLayers.find(
                  (ls: { layer: { id: string | undefined } }) =>
                    ls.layer.id === layer.meta_data.visible_layer_id
                )
                if (visible) {
                  actions.push(
                    fromLayersActions.updatePhysicalLayer({
                      id: visible.layer.id,
                      change: visible.layer.physicalLayer,
                    })
                  )
                }
              }

              if (layer.meta_data.isFHCFHidden2 && FHCFLayerState.length > 0) {
                const FHCFLayer = {
                  ...layer,
                  lossSetLayers: [
                    {
                      id: FHCFLayerState[FHCFLayerState.length - 1].layer.id,
                      meta_data:
                        FHCFLayerState[FHCFLayerState.length - 1].layer
                          .meta_data,
                      loss_sets:
                        FHCFLayerState[FHCFLayerState.length - 1].layer
                          .lossSetLayers,
                    },
                    {
                      id: layer.id,
                      meta_data: layer.meta_data,
                      loss_sets: layer.lossSetLayers,
                    },
                  ],
                  meta_data: {
                    ...layer.meta_data,
                    sage_layer_subtype: 'actual',
                    isFHCFFinal: true,
                    isFHCFHidden1: false,
                    isFHCFHidden2: false,
                    program_name: 'AggregateLayer',
                    program_type: 'Property',
                  },
                  physicalLayer: {
                    ...layer.physicalLayer,
                    meta_data: {
                      ...layer.physicalLayer.meta_data,
                      sage_layer_subtype: 'actual',
                      isFHCFFinal: true,
                      isFHCFHidden1: false,
                      isFHCFHidden2: false,
                      program_name: 'AggregateLayer',
                      program_type: 'Property',
                    },
                  },
                }
                actions.push(fromLayersActions.addLayer({ layer: FHCFLayer }))
              }

              if (layer.meta_data.isRiskVisible && riskLayerState.length > 0) {
                const riskFinalLossSets: {
                  id: string
                  meta_data: Partial<Metadata>
                  loss_sets: LayerRef[]
                }[] = []
                riskLayerState.forEach((l: LayerState) => {
                  if (
                    l.new &&
                    (l.layer.meta_data.isRiskCatHidden ||
                      l.layer.meta_data.isRiskLargeHidden)
                  ) {
                    const obj = {
                      id: l.layer.id,
                      meta_data: l.layer.meta_data,
                      loss_sets: l.layer.lossSetLayers,
                    }
                    riskFinalLossSets.push(obj)
                  }
                })
                const RiskFinalLayer = {
                  ...layer,
                  lossSetLayers: riskFinalLossSets,
                  meta_data: {
                    ...layer.meta_data,
                    sage_layer_subtype: 'actual',
                    isRiskLargeHidden: false,
                    isRiskVisible: false,
                    isRiskCatHidden: false,
                    isRiskFinal: true,
                    program_name: 'Layer 3 combination',
                    program_type: 'Riskxl',
                    riskVisibleLayerID: layer.id,
                  },
                  physicalLayer: {
                    ...layer.physicalLayer,
                    meta_data: {
                      ...layer.physicalLayer.meta_data,
                      sage_layer_subtype: 'actual',
                      isRiskLargeHidden: false,
                      isRiskVisible: false,
                      isRiskCatHidden: false,
                      isRiskFinal: true,
                      program_name: 'Layer 3 combination',
                      program_type: 'Riskxl',
                    },
                  },
                }
                actions.push(
                  fromLayersActions.addLayer({ layer: RiskFinalLayer })
                )
              }
              if (
                riskLayerState.length > 0 &&
                (layer.meta_data.isRiskCatHidden ||
                  layer.meta_data.isRiskLargeHidden)
              ) {
                const riskFinalLayers = riskLayerState.filter(
                  (r: LayerState) => r.layer.meta_data.isRiskFinal
                )
                const riskFinal = riskFinalLayers.find(
                  (l: { layer: { id: string | undefined } }) =>
                    l.layer.id === layer.meta_data.riskActualLayerID
                )
                if (riskFinal) {
                  const riskFinalLossSets =
                    riskFinal.layer.lossSetLayers.slice()
                  let isAdded = false
                  for (const l1 of riskFinalLossSets) {
                    if (
                      l1.loss_sets &&
                      ((l1.loss_sets[0] as LossSetLayer).id === layer.id ||
                        (l1.loss_sets[0] as LossSetLayer).id ===
                          (layer.lossSetLayers[0] as LossSetLayer).id)
                    ) {
                      isAdded = true
                    }
                  }
                  if (!isAdded) {
                    riskFinalLossSets.push({
                      id: layer.id,
                      meta_data: layer.meta_data,
                      loss_sets: layer.lossSetLayers,
                    })
                    actions.push(
                      fromLayersActions.updateLayer({
                        id: riskFinal.layer.id!,
                        change: { lossSetLayers: riskFinalLossSets },
                      })
                    )
                  } else if (isAdded) {
                    actions.push(
                      fromLayersActions.deleteLayer({ id: layer.id })
                    )
                  }
                }
              }

              if (isIndexedLayer(layer, 'visible-layer')) {
                const mainLayerId = layer.meta_data.main_layer_id
                const mainLayer = currentLayers.find(
                  (ls: { layer: { id: string | undefined } }) =>
                    ls.layer.id === mainLayerId
                )
                if (mainLayerId && mainLayer) {
                  actions.push(
                    fromLayersActions.updateLayer({
                      id: mainLayerId,
                      change: {
                        meta_data: {
                          ...mainLayer.layer.meta_data,
                          visible_layer_id: layer.id,
                        },
                      },
                    })
                  )
                }
              }

              // The three swing rated sublayers are created followed by
              // the visible layer. If this is the visible layer, create
              // the combined layer.
              if (isSwingLayer(layer, 'visible-layer')) {
                const newLayers = currentLayers
                  .filter((ls: { new: any }) => ls.new)
                  .map((ls: { layer: any }) => ls.layer)
                  .concat(layer)

                actions.push(
                  fromLayersActions.addSwingCombinedLayer({
                    layers: newLayers,
                  })
                )
              }
            }
            return actions
          }
        )
      )
  })

  addFHCHLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addFHCFLayer),
      map(action => action.layer),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentGrossPortfolioID)
        )
      ),
      concatMap(([layer, grossPortfolioID]) => {
        const actions: any[] = []
        return this.service.fetchPortfolio(grossPortfolioID!).pipe(
          map(grossPortfolio => {
            if (!grossPortfolio.error) {
              const lossSetLayers = (grossPortfolio.data as Portfolio)
                .layers as LossSetLayer[]
              const savedLossSetLayers = lossSetLayers.map(
                l => (l.loss_sets[0] as LossSet).id
              )
              const lossSetsArray: { ref_id: string }[] = []
              savedLossSetLayers.forEach(a =>
                lossSetsArray.push({
                  ref_id: a,
                })
              )
              const lossSetLayerInputs: Partial<LossSetLayer>[] = []
              lossSetLayerInputs.push({
                _type: 'LossRank',
                meta_data: {},
                loss_sets: lossSetsArray,
                invert: false,
                count: 2,
                criterion: 'LARGEST_LOSS',
              })
              lossSetLayerInputs.push({
                _type: 'LossRank',
                meta_data: {},
                loss_sets: lossSetsArray,
                invert: true,
                count: 2,
                criterion: 'LARGEST_LOSS',
              })
              return lossSetLayerInputs
            }
          }),
          switchMapWithInput(lossSetLayerInputs => {
            return this.service.postLayer(lossSetLayerInputs![0]).pipe(
              map(res => {
                return res
              })
            )
          }),
          rejectErrorWithInput(error =>
            this.store.dispatch(fromLayersActions.addLayerFailure({ error }))
          ),
          switchMapWithInput(([_, lossSetLayerInputs]) => {
            return this.service.postLayer(lossSetLayerInputs![1]).pipe(
              map(res => {
                return res
              })
            )
          }),
          rejectErrorWithInput(error =>
            this.store.dispatch(fromLayersActions.addLayerFailure({ error }))
          ),
          switchMap(([response, [response2]]) => {
            let hiddenLayerResponse1
            let hiddenLayerResponse2
            if (response.invert) {
              hiddenLayerResponse1 = response2
              hiddenLayerResponse2 = response
            } else {
              hiddenLayerResponse1 = response
              hiddenLayerResponse2 = response2
            }
            const hiddenLayer1 = {
              ...layer,
              lossSetLayers: [
                {
                  id: hiddenLayerResponse1.id,
                  meta_data: hiddenLayerResponse1.meta_data,
                  loss_sets: hiddenLayerResponse1.loss_sets,
                },
              ],
              meta_data: {
                ...layer.meta_data,
                sage_layer_subtype: '',
                isFHCFFinal: false,
                isFHCFHidden1: true,
                isFHCFHidden2: false,
                program_name: 'LargestTwoLosses',
                program_type: 'Property',
              },
              physicalLayer: {
                ...layer.physicalLayer,
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  sage_layer_subtype: '',
                  isFHCFFinal: false,
                  isFHCFHidden1: true,
                  isFHCFHidden2: false,
                  program_name: 'LargestTwoLosses',
                  program_type: 'Property',
                },
              },
            }
            const hiddenLayer2 = {
              ...layer,
              lossSetLayers: [
                {
                  id: hiddenLayerResponse2.id,
                  meta_data: hiddenLayerResponse2.meta_data,
                  loss_sets: hiddenLayerResponse2.loss_sets,
                },
              ],
              meta_data: {
                ...layer.meta_data,
                sage_layer_subtype: 'actual',
                isFHCFFinal: false,
                isFHCFHidden1: false,
                isFHCFHidden2: true,
                program_name: 'RemainingLosses',
                program_type: 'Property',
              },
              physicalLayer: {
                ...layer.physicalLayer,
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  sage_layer_subtype: 'actual',
                  isFHCFFinal: false,
                  isFHCFHidden1: false,
                  isFHCFHidden2: true,
                  program_name: 'RemainingLosses',
                  program_type: 'Property',
                },
              },
            }
            actions.push(fromLayersActions.addLayer({ layer: hiddenLayer1 }))
            actions.push(fromLayersActions.addLayer({ layer: hiddenLayer2 }))
            return actions
          })
        )
      })
    )
  })

  updateFHCFHidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updatePhysicalLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([response, layerStateById]) => {
        const actions: any[] = []
        const layer = layerStateById[response.id]?.layer
        if (layer && layer.meta_data.isFHCFHidden1) {
          const correspondingFHCFLayers = this.getCorrespondingFHCFLayers(
            layerStateById,
            layer.id
          )
          correspondingFHCFLayers.map(l => {
            let attachment: MonetaryUnit = response.change.attachment
              ? response.change.attachment
              : layer.physicalLayer.attachment
            let participation = response.change.participation
              ? response.change.participation
              : layer.physicalLayer.participation
            let limit: MonetaryUnit = response.change.limit
              ? response.change.limit
              : layer.physicalLayer.limit
            if (l.layer.meta_data.isFHCFHidden2) {
              attachment = {
                value: attachment.value ? attachment.value / 3 : 0,
                currency: attachment.currency ? attachment.currency : 'USD',
              }
            }
            if (l.layer.meta_data.isFHCFFinal) {
              attachment = {
                value: 0,
                currency: attachment.currency ? attachment.currency : 'USD',
              }
              participation = -1.0
              limit = {
                value: Math.abs(limit.value * response.change.participation!),
                currency: limit.currency ? limit.currency : 'USD',
              }
            }

            // tslint:disable-next-line: no-non-null-assertion
            const layerFHCF = layerStateById[l.layer.id]?.layer
            if (layerFHCF) {
              const updatedChange = {
                ...layerFHCF.physicalLayer,
                attachment,
                limit,
                participation,
                fees: response.change.fees
                  ? response.change.fees
                  : layer.physicalLayer.fees,
                aggregateLimit: response.change.aggregateLimit
                  ? response.change.aggregateLimit
                  : layer.physicalLayer.aggregateLimit,
                aggregateAttachment: response.change.aggregateAttachment
                  ? response.change.aggregateAttachment
                  : layer.physicalLayer.aggregateAttachment,
                premium: response.change.premium
                  ? response.change.premium
                  : layer.physicalLayer.premium,
                franchise: response.change.franchise
                  ? response.change.franchise
                  : layer.physicalLayer.franchise,
                reinstatements: response.change.reinstatements
                  ? response.change.reinstatements
                  : layer.physicalLayer.reinstatements,
              }
              actions.push(
                fromLayersActions.updatePhysicalLayer({
                  id: l.layer.id,
                  change: updatedChange,
                })
              )
            }
          })
        }
        return actions
      })
    )
  })

  deleteFHCForRiskHidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.deleteLayer),
      map(action => action.id),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([layerId, layerStateById]) => {
        const actions: any[] = []
        const layerToDelete = layerStateById[layerId]?.layer
        if (layerToDelete) {
          if (layerToDelete.meta_data.isFHCFHidden1) {
            const correspondingFHCFLayers = this.getCorrespondingFHCFLayers(
              layerStateById,
              layerToDelete.id
            )
            correspondingFHCFLayers.map(l => {
              actions.push(fromLayersActions.deleteLayer({ id: l.layer.id }))
            })
          }

          if (layerToDelete.meta_data.isRiskVisible) {
            const correspondingRiskLayers = this.getCorrespondingRiskLayers(
              layerStateById,
              layerToDelete.id
            )
            correspondingRiskLayers.map(l => {
              if (l && l.layer) {
                actions.push(fromLayersActions.deleteLayer({ id: l.layer.id }))
              }
            })
          }

          if (isIndexedLayer(layerToDelete, 'visible-layer')) {
            const row = Object.entries(layerStateById).find(
              ([_, ls]) =>
                ls?.layer.meta_data.visible_layer_id === layerToDelete.id
            )
            if (row !== undefined) {
              actions.push(fromLayersActions.deleteLayer({ id: row[0] }))
            }
          }

          if (isSwingLayer(layerToDelete, 'visible-layer')) {
            // tslint:disable-next-line: no-non-null-assertion
            const combinedLayer = Object.keys(layerStateById)
              .map(k => layerStateById[k]!.layer)
              .find(
                layer => layer.meta_data.visible_layer_id === layerToDelete.id
              )
            if (combinedLayer !== undefined) {
              const [lossLayerId, premiumLayerId, adjustmentLayerId] =
                getSwingIds(combinedLayer)
              actions.push(
                fromLayersActions.deleteLayer({ id: combinedLayer.id })
              )
              actions.push(fromLayersActions.deleteLayer({ id: lossLayerId }))
              actions.push(
                fromLayersActions.deleteLayer({ id: premiumLayerId })
              )
              actions.push(
                fromLayersActions.deleteLayer({ id: adjustmentLayerId })
              )
            }
          }

          if (isMultiSectionLayer(layerToDelete, 'visible-layer')) {
            const mainLayer = Object.values(layerStateById)
              .map(ls => ls?.layer)
              .find(
                layer => layer?.meta_data.visible_layer_id === layerToDelete.id
              )

            if (mainLayer !== undefined) {
              actions.push(fromLayersActions.deleteLayer({ id: mainLayer.id }))
              actions.push(
                ...mainLayer.layerRefs.map(id =>
                  fromLayersActions.deleteLayer({ id })
                )
              )
            }
          }
        }

        return actions
      })
    )
  })

  addRiskLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addRiskLayer),
      map(action => action.layer),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectCurrentGrossPortfolioID)
      ),
      concatMap(([layer, grossPortfolioID]) => {
        const actions: any[] = []
        return this.service.fetchPortfolio(grossPortfolioID!).pipe(
          map(grossPortfolio => {
            if (!grossPortfolio.error) {
              const lossSetLayers = (grossPortfolio.data as Portfolio)
                .layers as LossSetLayer[]
              return lossSetLayers
            }
          }),
          switchMap(lossSetLayers => {
            const catLossSetLayers = lossSetLayers?.filter(
              l => l.meta_data.loss_type === 'cat'
            )!
            catLossSetLayers.forEach(l => {
              const hiddenCatLayer1 = {
                ...layer,
                lossSetLayers: [
                  {
                    id: l.id,
                    meta_data: l.meta_data,
                    loss_sets: l.loss_sets,
                  },
                ],
                meta_data: {
                  ...layer.meta_data,
                  sage_layer_subtype: 'actual',
                  program_name: 'Layer 1',
                  program_type: 'Riskxl',
                  isRiskLargeHidden: false,
                  isRiskVisible: false,
                  isRiskCatHidden: true,
                  isRiskFinal: false,
                },
                physicalLayer: {
                  ...layer.physicalLayer,
                  meta_data: {
                    ...layer.physicalLayer.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name: 'Layer 1',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: false,
                    isRiskVisible: false,
                    isRiskCatHidden: true,
                    isRiskFinal: false,
                  },
                },
              }
              actions.push(
                fromLayersActions.addLayer({ layer: hiddenCatLayer1 })
              )
            })
            const largeLossSetLayers = lossSetLayers?.filter(
              l => l.meta_data.loss_type === 'large'
            )!
            largeLossSetLayers.forEach(l1 => {
              const hiddenRemainingLargeLayer = {
                ...layer,
                lossSetLayers: [
                  {
                    id: l1.id,
                    meta_data: l1.meta_data,
                    loss_sets: l1.loss_sets,
                  },
                ],
                meta_data: {
                  ...layer.meta_data,
                  sage_layer_subtype: 'actual',
                  program_name: 'Layer 2',
                  program_type: 'Riskxl',
                  isRiskLargeHidden: true,
                  isRiskVisible: false,
                  isRiskCatHidden: false,
                  isRiskFinal: false,
                },
                physicalLayer: {
                  ...layer.physicalLayer,
                  loss_sets: [
                    {
                      id: l1.id,
                      meta_data: l1.meta_data,
                      loss_sets: l1.loss_sets,
                    },
                  ],
                  meta_data: {
                    ...layer.physicalLayer.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name: 'Layer 2',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: true,
                    isRiskVisible: false,
                    isRiskCatHidden: false,
                    isRiskFinal: false,
                  },
                },
              }
              actions.push(
                fromLayersActions.addLayer({ layer: hiddenRemainingLargeLayer })
              )
            })
            if (lossSetLayers) {
              const visibleRiskLayer = {
                ...layer,
                lossSetLayers,
                meta_data: {
                  ...layer.meta_data,
                  sage_layer_subtype: '',
                  program_name: 'Visible Dummy Layer',
                  program_type: 'Riskxl',
                  isRiskLargeHidden: false,
                  isRiskVisible: true,
                  isRiskCatHidden: false,
                  isRiskFinal: false,
                },
                physicalLayer: {
                  ...layer.physicalLayer,
                  meta_data: {
                    ...layer.physicalLayer.meta_data,
                    sage_layer_subtype: '',
                    program_name: 'Visible Dummy Layer',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: false,
                    isRiskVisible: true,
                    isRiskCatHidden: false,
                    isRiskFinal: false,
                  },
                },
              }
              actions.push(
                fromLayersActions.addLayer({ layer: visibleRiskLayer })
              )
            }
            return actions
          })
        )
      })
    )
  })

  // TODO layer: updateRiskHidden$
  updateRiskHidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updatePhysicalLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([response, layerStateById]) => {
        const actions: any[] = []

        const layer = layerStateById[response.id]?.layer
        if (layer && layer.meta_data.isRiskVisible) {
          const correspondingRiskLayers = this.getCorrespondingRiskLayers(
            layerStateById,
            layer.id
          )

          const actualLayer = correspondingRiskLayers.find(
            ls => ls.layer.meta_data.isRiskFinal
          )

          correspondingRiskLayers.map(l => {
            if (l && l.layer) {
              let limit: MonetaryUnit = response.change.limit!
              let attachment: MonetaryUnit = response.change.attachment!
              let aggregateLimit: MonetaryUnit = response.change.aggregateLimit!
              let aggregateAttachment: MonetaryUnit =
                response.change.aggregateAttachment!
              let riskLimit: MonetaryUnit = response.change.riskLimit!
              let riskAttachment: MonetaryUnit = response.change.riskAttachment!
              let premium: MonetaryUnit = response.change.premium!
              let participation = response.change.participation!
              let reinstatements = response.change.reinstatements
              if (l.layer.meta_data.isRiskLargeHidden) {
                aggregateAttachment = {
                  value: 0,
                  currency: response.change.aggregateAttachment?.currency
                    ? response.change.aggregateAttachment?.currency
                    : 'USD',
                }
                aggregateLimit = {
                  value: analyzereConstants.unlimitedValue,
                  currency: response.change.aggregateLimit?.currency
                    ? response.change.aggregateLimit?.currency
                    : 'USD',
                }
                participation = 1
              }
              if (l.layer.meta_data.isRiskCatHidden) {
                attachment = {
                  value: 0,
                  currency: response.change.attachment?.currency
                    ? response.change.attachment?.currency
                    : 'USD',
                }
                aggregateAttachment = {
                  value: 0,
                  currency: response.change.aggregateAttachment?.currency
                    ? response.change.aggregateAttachment?.currency
                    : 'USD',
                }
                limit = {
                  value: analyzereConstants.unlimitedValue,
                  currency: response.change.limit?.currency
                    ? response.change.limit?.currency
                    : 'USD',
                }
                aggregateLimit = {
                  value: analyzereConstants.unlimitedValue,
                  currency: response.change.aggregateLimit?.currency
                    ? response.change.aggregateLimit?.currency
                    : 'USD',
                }
                premium = {
                  value: 0,
                  currency: response.change.premium?.currency
                    ? response.change.premium?.currency
                    : 'USD',
                }
                riskLimit = {
                  value: 0,
                  currency: response.change.riskLimit?.currency
                    ? response.change.riskLimit?.currency
                    : 'USD',
                }
                riskAttachment = {
                  value: 0,
                  currency: response.change.riskAttachment?.currency
                    ? response.change.riskAttachment?.currency
                    : 'USD',
                }
                reinstatements = []
                participation = 1
              }
              if (l.layer.meta_data.isRiskFinal) {
                attachment = {
                  value: 0,
                  currency: response.change.attachment?.currency
                    ? response.change.attachment?.currency
                    : 'USD',
                }
                riskLimit = {
                  value: 0,
                  currency: response.change.riskLimit?.currency
                    ? response.change.riskLimit?.currency
                    : 'USD',
                }
                riskAttachment = {
                  value: 0,
                  currency: response.change.riskAttachment?.currency
                    ? response.change.riskAttachment?.currency
                    : 'USD',
                }
              }

              // tslint:disable-next-line: no-non-null-assertion
              const layerRisk = layerStateById[l.layer.id]!
              const updatedChange = {
                ...layerRisk.layer.physicalLayer,
                attachment: attachment
                  ? attachment
                  : layerRisk.layer.physicalLayer.attachment,
                limit: limit ? limit : layerRisk.layer.physicalLayer.limit,
                participation: participation
                  ? participation
                  : response.change.participation,
                fees: response.change.fees
                  ? response.change.fees
                  : layerRisk.layer.physicalLayer.fees,
                aggregateLimit: aggregateLimit
                  ? aggregateLimit
                  : layerRisk.layer.physicalLayer.aggregateLimit,
                aggregateAttachment: aggregateAttachment
                  ? aggregateAttachment
                  : layerRisk.layer.physicalLayer.aggregateAttachment,
                premium: premium
                  ? premium
                  : layerRisk.layer.physicalLayer.premium,
                franchise: response.change.franchise
                  ? response.change.franchise
                  : layerRisk.layer.physicalLayer.franchise,
                reinstatements: reinstatements
                  ? reinstatements
                  : layerRisk.layer.physicalLayer.reinstatements,
                riskLimit: riskLimit
                  ? riskLimit
                  : layerRisk.layer.physicalLayer.riskLimit,
                riskAttachment: riskAttachment
                  ? riskAttachment
                  : layerRisk.layer.physicalLayer.riskAttachment,
                meta_data: {
                  ...layerRisk.layer.physicalLayer.meta_data,
                  riskActualLayerID: actualLayer ? actualLayer.layer.id : '',
                },
                description: layer.physicalLayer.description,
              }
              actions.push(
                fromLayersActions.updatePhysicalLayer({
                  id: l.layer.id,
                  change: updatedChange,
                })
              )
              let metaData = l.layer.meta_data
              if (actualLayer && !l.layer.meta_data.isRiskFinal) {
                metaData = {
                  ...metaData,
                  riskActualLayerID: actualLayer.layer.id,
                }
                actions.push(
                  fromLayersActions.updateLayer({
                    id: l.layer.id,
                    change: { meta_data: metaData },
                  })
                )
              }
            }
          })
          let metaData1 = layer.meta_data
          if (actualLayer) {
            metaData1 = {
              ...metaData1,
              riskActualLayerID: actualLayer.layer.id,
            }
          }
          actions.push(
            fromLayersActions.updateLayer({
              id: layer.id,
              change: { meta_data: metaData1 },
            })
          )

          actions.push(
            fromLayersActions.updateRiskComplete({
              cId: layer.meta_data.correlationId,
            })
          )
        }
        return actions
      })
    )
  })

  addRiskLayerLossSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addRiskLossSetsToLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([{ id, lossSets }, layerStateById]) => {
        const actions: any[] = []

        const actualLayer = layerStateById[id]?.layer
        if (
          actualLayer === undefined ||
          actualLayer.meta_data.riskVisibleLayerID === undefined
        ) {
          return actions
        }

        const layer =
          layerStateById[actualLayer.meta_data.riskVisibleLayerID]?.layer
        if (layer === undefined) {
          return actions
        }

        lossSets.forEach(l => {
          if (
            l.meta_data.loss_type === 'large' ||
            l.meta_data.loss_type === 'attr'
          ) {
            const hiddenLargeLayer: Layer = {
              ...layer,
              lossSetLayers: [
                {
                  id: l.id,
                  meta_data: l.meta_data,
                  loss_sets: l.loss_sets,
                },
              ],
              meta_data: {
                ...layer.meta_data,
                sage_layer_subtype: 'actual',
                program_name: 'Layer 2',
                program_type: 'Riskxl',
                isRiskLargeHidden: true,
                isRiskVisible: false,
                isRiskCatHidden: false,
                isRiskFinal: false,
                riskActualLayerID: actualLayer.id,
              },
              physicalLayer: {
                ...layer.physicalLayer,
                participation: Math.abs(layer.physicalLayer.participation),
                aggregateAttachment: {
                  value: 0,
                  currency: layer.physicalLayer.aggregateAttachment?.currency
                    ? layer.physicalLayer.aggregateAttachment?.currency
                    : 'USD',
                },
                aggregateLimit: {
                  value: analyzereConstants.unlimitedValue,
                  currency: layer.physicalLayer.aggregateLimit?.currency
                    ? layer.physicalLayer.aggregateLimit?.currency
                    : 'USD',
                },
                loss_sets: [
                  {
                    id: l.id,
                    meta_data: l.meta_data,
                    loss_sets: l.loss_sets,
                  },
                ],
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  sage_layer_subtype: 'actual',
                  program_name: 'Layer 2',
                  program_type: 'Riskxl',
                  isRiskLargeHidden: true,
                  isRiskVisible: false,
                  isRiskCatHidden: false,
                  isRiskFinal: false,
                  riskActualLayerID: actualLayer.id,
                },
              },
            }
            actions.push(
              fromLayersActions.addLayer({ layer: hiddenLargeLayer })
            )
          } else if (l.meta_data.loss_type === 'cat') {
            const hiddenCatLayer: Layer = {
              ...layer,
              lossSetLayers: [
                {
                  id: l.id,
                  meta_data: l.meta_data,
                  loss_sets: l.loss_sets,
                },
              ],
              meta_data: {
                ...layer.meta_data,
                sage_layer_subtype: 'actual',
                program_name: 'Layer 1',
                program_type: 'Riskxl',
                isRiskLargeHidden: false,
                isRiskVisible: false,
                isRiskCatHidden: true,
                isRiskFinal: false,
                riskActualLayerID: actualLayer.id,
              },
              physicalLayer: {
                ...layer.physicalLayer,
                participation: Math.abs(layer.physicalLayer.participation),
                aggregateAttachment: {
                  value: 0,
                  currency: layer.physicalLayer.aggregateAttachment?.currency
                    ? layer.physicalLayer.aggregateAttachment?.currency
                    : 'USD',
                },
                aggregateLimit: {
                  value: analyzereConstants.unlimitedValue,
                  currency: layer.physicalLayer.aggregateLimit?.currency
                    ? layer.physicalLayer.aggregateLimit?.currency
                    : 'USD',
                },
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  sage_layer_subtype: 'actual',
                  program_name: 'Layer 1',
                  program_type: 'Riskxl',
                  isRiskLargeHidden: false,
                  isRiskVisible: false,
                  isRiskCatHidden: true,
                  isRiskFinal: false,
                  riskActualLayerID: actualLayer.id,
                },
              },
            }
            actions.push(fromLayersActions.addLayer({ layer: hiddenCatLayer }))
          }
        })
        return actions
      })
    )
  })

  removeRiskLayerLossSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.removeRiskLossSetsToLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededDictionary))
      ),
      switchMap(([{ id, lossSets }, layerStateById]) => {
        const actions: any[] = []

        const layer = layerStateById[id]?.layer
        if (layer === undefined) {
          return actions
        }

        const allLayers = Object.values(layerStateById)
        lossSets.forEach(l => {
          const removeLayer = allLayers.find(
            l1 =>
              (l1?.layer.meta_data.isRiskLargeHidden ||
                l1?.layer.meta_data.isRiskCatHidden) &&
              (l1.layer.lossSetLayers[0].id === l.id ||
                l1.layer.lossSetLayers[0].id ===
                  (l.loss_sets[0] as LossSetLayer).id ||
                (l1.layer.lossSetLayers[0].loss_sets &&
                  ((l1.layer.lossSetLayers[0].loss_sets[0] as LossSetLayer)
                    .id === l.id ||
                    (l1.layer.lossSetLayers[0].loss_sets[0] as LossSetLayer)
                      .id === (l.loss_sets[0] as LossSetLayer).id))) &&
              !l1.deleted
          )!
          if (
            removeLayer &&
            (removeLayer.layer.meta_data.isRiskLargeHidden ||
              removeLayer.layer.meta_data.isRiskCatHidden)
          ) {
            actions.push(
              fromLayersActions.deleteLayer({ id: removeLayer.layer.id })
            )
            let riskFinalLossSets = layer.lossSetLayers.slice()
            riskFinalLossSets = riskFinalLossSets.filter(
              l1 => !(removeLayer.layer.id === l1.id)
            )
            actions.push(
              fromLayersActions.updateLayer({
                id: layer.id!,
                change: { lossSetLayers: riskFinalLossSets },
              })
            )
          } else if (removeLayer.layer.meta_data.isRiskVisible) {
            actions.push(
              fromLayersActions.deleteLayer({ id: removeLayer.layer.id })
            )
          }
        })

        return actions
      })
    )
  })

  getBackAllocatedContributionForMultiSections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.getBackAllocatedContributionForMultiSections),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCededMultiSectionMainLayers)
        ),
        this.store.pipe(
          select(fromAnalysisSelectors.selectCededMultiSectionLayers)
        ),
        this.store.pipe(
          select(fromAnalysisSelectors.selectParentGrossLossSetLayers)
        )
      ),
      mergeMap(
        ([
          action,
          cededMultiSectionMainLayers,
          cededMultiSectionLayers,
          parentGrossLossSetLayers,
        ]) => {
          const mainLayerFound = cededMultiSectionMainLayers.find(
            layer => layer.meta_data.visible_layer_id === action.id
          )
          if (!mainLayerFound) {
            return []
          }
          return mainLayerFound.layerRefs.map(layerRef => {
            const sectionLossSets = this.getSectionLossSetContribution(
              parentGrossLossSetLayers,
              cededMultiSectionLayers.find(layer => layer.id === layerRef)
            )
            return fromLayersActions.getBackAllocatedContribution({
              id: layerRef,
              lossSets: sectionLossSets,
              forMultiSections: true,
            })
          })
        }
      )
    )
  })

  handleGetBackallocatedContributions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.handleGetBackAllocatedContributions),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededMultiSectionMainLayers)),
        this.store.pipe(select(fromAnalysisSelectors.selectCededMultiSectionLayers)),
        this.store.pipe(select(fromAnalysisSelectors.selectParentGrossLossSetLayers))
      ),
      concatMap(([action, cededMultiSectionMainLayers, cededMultiSectionLayers, parentGrossLossSetLayers]) => {
        this.store.dispatch(fromLayersActions.updateContributionsLoading({ loading: true }))
        const completeData: GetBackAllocatedContributionDatum[] = [...action.data]
  
        action.data.filter(d => d.isMSLayer).forEach(d => {
          const mainLayerFound = cededMultiSectionMainLayers.find(
            layer => layer.meta_data.visible_layer_id === d.id
          )
          if (!mainLayerFound) {
            mainLayerFound.layerRefs.forEach(layerRef => {
              const sectionLossSets = this.getSectionLossSetContribution(
                parentGrossLossSetLayers,
                cededMultiSectionLayers.find(layer => layer.id === layerRef)
              )
              completeData.unshift({
                id: layerRef,
                lossSets: sectionLossSets,
                forMultiSections: true,
              })
            })
          }
        })
        return of(completeData)
      }),
      concatMap((completeData) => 
        from(completeData).pipe(
          concatMap((datum, index) => { 
            const isLast = index === completeData.length - 1
            const { id, lossSets, forMultiSections } = datum
            this.store.dispatch(
              fromLayersActions.getBackAllocatedContribution({
                id,
                lossSets,
                forMultiSections,
                isLast
              })
            )
            return this.actions$.pipe(
              ofType(fromLayersActions.getBackAllocatedContributionSuccess),
              filter(action => action.layerID === id),
              take(1),
              map(() => ({ type: '[Ceded Layers] Get BackAllocated Contribution Intermidiate Processing' }))
            )
          })
        )
      )
    )
  })

  getBackAllocatedContribution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.getBackAllocatedContribution),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentAnalysisProfileID)
        ),
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentCededLayer)),
        this.store.pipe(select(fromAnalysisSelectors.selectActiveCededLayers))
      ),
      mergeMap(
        ([action, analysisID, selectedCededLayer, activeCededLayers]) => {
          const currentCededLayer =
            selectedCededLayer?.layer ??
            activeCededLayers.find(
              layerState => layerState.layer.id === action.id
            )?.layer
          if (!analysisID) {
            return EMPTY
          }
          const currency = currentCededLayer?.currency
          const layerViewIDs = action.lossSets.map(layer =>
            this.service.postLayerView(layer.id, analysisID, currency)
          )
          let calculationLayerId = action.id
          /* for calculation, use layer id from props if section of multisection layer */
          if (!action.forMultiSections && currentCededLayer) {
            calculationLayerId = this.getContributionCalculationLayerId(
              currentCededLayer,
              activeCededLayers.map(ls => ls.layer)
            )
          }
          return forkJoin(layerViewIDs).pipe(
            map(res => ({
              results: res,
              layerId: action.id,
              calculationLayerId,
              lossSets: action.lossSets,
              forMultiSections: action.forMultiSections,
              isLast: action.isLast
            }))
          )
        }
      ),
      mergeMap(action => {
        const backAllocatedLayers = action.results.map(entity => {
          if (!entity.data) {
            return {}
          }
          return this.createBackAllocatedLayer(
            entity.data,
            action.calculationLayerId
          )
        })
        return this.service
          .postLogicalPortfolioLayers(backAllocatedLayers)
          .pipe(
            map(r => ({
              ...r,
              lossSets: action.lossSets,
              layerId: action.layerId,
              forMultiSections: action.forMultiSections,
              isLast: action.isLast
            }))
          )
      }),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentAnalysisProfileID)
        )
      ),
      mergeMap(([data, analysisID]) => {
        if (data.error) {
          return of({ error: data.error })
        } else {
          const observables: Array<ApiResponse<Metrics>> = []
          const lossSetLayerIDs: string[] = []
          data.lossSets.map(l => {
            lossSetLayerIDs.push(l.id)
          })
          data.data!.forEach(backAllocated => {
            observables.push(
              this.service.postLayerView(backAllocated.id, analysisID!).pipe(
                mergeMap(res => {
                  if (res.error) {
                    return of({ error: res.error }) as ApiResponse<Metrics>
                  } else {
                    return this.service.getPortfolioViewMetricsAAL(res.data!.id)
                  }
                })
              )
            )
          })
          return forkJoin(observables).pipe(
            map(results => {
              for (const result of results) {
                if (result.error) {
                  return { error: result.error }
                }
              }
              const record = results.reduce(
                // tslint:disable-next-line: no-non-null-assertion
                (acc, current, index) => ({
                  ...acc,
                  [lossSetLayerIDs[index]]: current.data!,
                }),
                {} as Record<string, Metrics>
              )
              return {
                data: record,
                layerId: data.layerId,
                forMultiSections: data.forMultiSections,
                isLast: data.isLast
              }
            })
          )
        }
      }),
      concatMap(result => {
        if (result.error) {
          return of(fromLayersActions.getBackAllocatedContributionFailure({
            error: result.error,
          }))
        } else {
          // @ts-ignore
          const isLast = result.isLast
          const contributionValues: BackAllocatedContribution[] =
            // @ts-ignore
            Object.entries(result.data).map(l => {
              // @ts-ignore
              return { id: l[0], value: Math.abs(l[1].mean) }
            })
          const actions: Action[] = [
            fromLayersActions.getBackAllocatedContributionSuccess({
              data: contributionValues,
              // @ts-ignore
              layerID: result.layerId,
              // @ts-ignore
              forMultiSections: result.forMultiSections,
            })
          ]
          if (isLast) {
            actions.push(fromLayersActions.handleGetBackAllocatedContributionsSuccess())
          }
          return from(actions)
        }
      })
    )
  })

  getAALValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLayersActions.getAALValues),
      map(action => action.id),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectParentGrossLossSetLayers),
        this.store.select(fromAnalysisSelectors.selectCurrentCededLayer)
      ),
      concatMap(([id, lossSets, currentLayer]) => {
        let actions = []
        if (currentLayer && currentLayer.layer.id === id) {
          let lossSetLayerUpdated: LayerRef[] = []
          currentLayer.layer.lossSetLayers.forEach(ls => {
            if(lossSets.find(l => l.id === ls.id)) {
              let updatedLossSet = {...ls}
              updatedLossSet.mean = lossSets.find(l => l.id === ls.id).mean
              lossSetLayerUpdated.push(updatedLossSet)
            }
          })
          actions.push(
            fromLayersActions.setAALValuesPerLayer({
              id: id,
              lossSetLayers: lossSetLayerUpdated,
            })
          )
        }
        return actions
      })
    )
  )

  private getDeletedBackAllocatedLayers(layers: LayerState[]) {
    return (
      layers
        .filter(l => !l.new && l.deleted && l.layer.sharedLayerID)
        .map(l =>
          layers.find(
            f =>
              f.layer.meta_data.backAllocatedForID === l.layer.id &&
              f.layer.meta_data.sage_layer_subtype === 'backallocated' &&
              f.layer.meta_data.sage_layer_type === 'shared_limit'
          )
        )
        .filter(l => l !== undefined)
        // tslint:disable: no-non-null-assertion
        .map(l => l!.layer)
    )
  }

  private getCorrespondingFHCFLayers(
    layers: Dictionary<LayerState>,
    layerID: string
  ) {
    const correspondingFHCFLayers: LayerState[] = []
    const arr = Object.keys(layers).map(l => layers[l])
    for (const i of arr) {
      if (
        i?.layer.meta_data.isFHCFFinal &&
        i?.layer.lossSetLayers.findIndex(l1 => l1.id === layerID) !== -1
      ) {
        correspondingFHCFLayers.push(i)
        correspondingFHCFLayers.push(
          layers[correspondingFHCFLayers[0].layer.lossSetLayers[1].id]!
        )
        break
      }
    }
    return correspondingFHCFLayers
  }

  private getCorrespondingRiskLayers(
    layers: Dictionary<LayerState>,
    layerID: string
  ) {
    const correspondingRiskLayers: LayerState[] = []
    const arr = Object.keys(layers).map(l => layers[l])
    for (const i of arr) {
      if (
        i?.layer.meta_data.isRiskFinal &&
        i?.layer.meta_data.riskVisibleLayerID === layerID
      ) {
        correspondingRiskLayers.push(i)
        const refArray = i.layer.lossSetLayers
        refArray.forEach(r => correspondingRiskLayers.push(layers[r.id]!))
        break
      }
    }
    return correspondingRiskLayers
  }

  private createBackAllocatedLayer = (
    lossSetLayerViewID: LayerViewResponse & { layerID: string },
    layerID: string
  ): Partial<LogicalPortfolioLayer> => {
    return {
      _type: 'BackAllocatedLayer',
      description: 'Back Allocated Layer',
      meta_data: {
        ...lossSetLayerViewID.layer.meta_data,
        sage_layer_subtype: 'backallocated',
        backAllocatedForID: lossSetLayerViewID.layerID,
      },
      sink: { ref_id: layerID },
      source_id: lossSetLayerViewID.id,
    }
  }

  private getContributionCalculationLayerId(
    currentCededLayer: Layer,
    activeCededLayers: Layer[]
  ): string {
    const swingOrMultiSectionMatch = activeCededLayers.find(
      layer =>
        ((isSwingLayer(currentCededLayer) && isSwingCombinedLayer(layer)) ||
          (isMultiSectionLayer(currentCededLayer) &&
            isMultiSectionMainLayer(layer))) &&
        layer.meta_data.visible_layer_id === currentCededLayer.id
    )
    return swingOrMultiSectionMatch
      ? swingOrMultiSectionMatch.id
      : currentCededLayer.id
  }

  private getSectionLossSetContribution(
    parentGrossLossSetLayers: AnalysisLossSetLayer[],
    layer?: Layer
  ): LossSetContribution[] {
    if (!layer) {
      return []
    }
    return layer.lossSetLayers
      ?.map(lossSet => {
        const lossSetLayer = parentGrossLossSetLayers.find(
          parentLossSet => parentLossSet.id === lossSet.id
        )
        return (
          lossSetLayer &&
          ({
            label: lossSetLayer.description,
            value: lossSetLayer.mean,
            id: lossSetLayer.id,
            meta_data: lossSetLayer.meta_data,
          } as LossSetContribution)
        )
      })
      .filter(Boolean) as LossSetContribution[]
  }

  // * ---------------------------------------------------------------
  // * Indexed Layer
  // * ---------------------------------------------------------------
  addIndexedLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLayersActions.addIndexedLayer),
      map(action => action.layer),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectLossSetLayers)
      ),
      switchMap(([layer, lossSets]) =>
        this.indexedLayerService.createIndexedLayer(layer, lossSets)
      )
    )
  )

  updateIndexed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLayersActions.updatePhysicalLayer),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectCededIndexedLayers)
      ),
      switchMap(([{ id: visibleId, change }, layers]) => {
        const visibleLayer = layers.find(layer => layer.id === visibleId)
        if (!visibleLayer || !isIndexedLayer(visibleLayer, 'visible-layer')) {
          return []
        }

        const mainLayerId = visibleLayer.meta_data.main_layer_id
        if (!mainLayerId) {
          throw new Error(
            `main_layer_id is missing from visible layer ${visibleId}`
          )
        }

        // this.indexedLayerService.write(mainLayerId, visibleLayer)

        return this.indexedLayerService.updateIndexedMain(mainLayerId, change)
      })
    )
  )

  // * ---------------------------------------------------------------
  // * Swing-rated layer
  // * ---------------------------------------------------------------

  addSwingLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addSwingLayer),
      switchMap(({ layer }) => {
        return Array.of(
          fromLayersActions.addLayer({
            layer: createSwingSubLayer(layer, 'loss-layer'),
          }),
          fromLayersActions.addLayer({
            layer: createSwingSubLayer(layer, 'premium-layer'),
          }),
          fromLayersActions.addLayer({
            layer: createSwingSubLayer(layer, 'adjustment-layer'),
          }),
          fromLayersActions.addLayer({
            layer: createSwingSubLayer(layer, 'visible-layer', false),
          })
        )
      })
    )
  })

  addSwingCombinedLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addSwingCombinedLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentCurrency))
      ),
      concatMap(([{ layers }, currency]) => {
        // We need that last sublayers that were created
        const latest = reverse(layers)

        const lossLayer = latest.find(layer =>
          isSwingLayer(layer, 'loss-layer')
        )
        const premiumLayer = latest.find(layer =>
          isSwingLayer(layer, 'premium-layer')
        )
        const adjustmentLayer = latest.find(layer =>
          isSwingLayer(layer, 'adjustment-layer')
        )
        const visibleLayer = latest.find(layer =>
          isSwingLayer(layer, 'visible-layer')
        )
        if (!lossLayer || !premiumLayer || !adjustmentLayer || !visibleLayer) {
          throw new Error(
            'addSwingCombinedLayer: One of the sublayers is missing.'
          )
        }

        // The combined layer with have the three hidden layers as its sources
        const combinedLayer = createSwingCombinedLayer(
          lossLayer,
          lossLayer.id,
          premiumLayer.id,
          adjustmentLayer.id,
          visibleLayer.id,
          currency ?? 'USD'
        )

        return Array.of(fromLayersActions.addLayer({ layer: combinedLayer }))
      })
    )
  })

  updateSwingLayerLossSets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updateLayer),
      filter(action => 'lossSetLayers' in action.change),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectCededSwingLayers)
      ),
      switchMap(([action, layers]) => {
        const visibleLayer = findLayer(layers, action.id)
        if (!visibleLayer || !isSwingLayer(visibleLayer, 'visible-layer')) {
          return []
        }

        const combinedLayer = findLayerByVisibleId(layers, visibleLayer.id)
        if (!combinedLayer) {
          return []
        }

        const [lossId, premiumId, adjustmentId] = getSwingIds(combinedLayer)
        const lossSetLayers = action.change.lossSetLayers

        return [
          fromLayersActions.updateLayer({
            id: lossId,
            change: { lossSetLayers },
          }),
          fromLayersActions.updateLayer({
            id: premiumId,
            change: { lossSetLayers },
          }),
          fromLayersActions.updateLayer({
            id: adjustmentId,
            change: { lossSetLayers },
          }),
          // Update visible layer to trigger updateSwingLayer$ in order to update hidden swing layers
          fromLayersActions.updateLayer({
            id: visibleLayer.id,
            change: {},
          }),
        ]
      })
    )
  })

  updateSwingLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updatePhysicalLayer, fromLayersActions.updateLayer),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededSwingLayers)),
        this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayers))
      ),
      switchMap(([action, layers, lossSetLayers]) => {
        const visibleLayer = findLayer(layers, action.id)
        if (!visibleLayer || !isSwingLayer(visibleLayer, 'visible-layer')) {
          return []
        }

        const combinedLayer = findLayerByVisibleId(layers, visibleLayer.id)
        if (!combinedLayer) {
          return []
        }
        const [lossId, premiumId, adjustmentId] = getSwingIds(combinedLayer)
        const lossLayer = findLayer(layers, lossId)
        const premiumLayer = findLayer(layers, premiumId)
        const adjustmentLayer = findLayer(layers, adjustmentId)

        if (!lossLayer || !premiumLayer || !adjustmentLayer) {
          return []
        }

        // TODO: Can this be combined with _sumOfLossSetPremiums()?
        const premium = lossLayer.lossSetLayers.reduce(
          (sum, { id }) =>
            sum +
            (lossSetLayers.find(lsl => lsl.id === id)?.premium.value ?? 0),
          0
        )

        // TODO Can the be combined with _sumOfLossSetMembers()?
          const membersSum =
          lossLayer.lossSetLayers.reduce(
            (acc, { id }) => {
                const lossSet = lossSetLayers.find(lsl => lsl.id === id)
                if (!lossSet || !lossSet.meta_data || !lossSet.meta_data.members) {
                    return acc
                }
                return acc + lossSet.meta_data.members
            },
            0
          ) * 12

        const [combinedUpdate, lossUpdate, premiumUpdate, adjustmentUpdate] =
          updateSwingLayers(visibleLayer, premium, membersSum)

        // TODO check if update will actually change values before pushing in order to reduce actions sent
        return [
          fromLayersActions.updatePhysicalLayer({
            id: combinedLayer.id,
            change: combinedUpdate,
          }),
          fromLayersActions.updatePhysicalLayer({
            id: lossId,
            change: lossUpdate,
          }),
          fromLayersActions.updatePhysicalLayer({
            id: premiumId,
            change: premiumUpdate,
          }),
          fromLayersActions.updatePhysicalLayer({
            id: adjustmentId,
            change: adjustmentUpdate,
          }),
        ]
      })
    )
  })

  // * ---------------------------------------------------------------
  // * Drop Layer
  // * ---------------------------------------------------------------
  addDropLayer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromLayersActions.addDropLayer),
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
        ),
        tap(([{ topLayer }, _]) => {
          const logicalId = `${new Date().getTime()}${Math.random() * 100000}`
          this.store.dispatch(
            fromLayersActions.addLayer({
              layer: {
                id: logicalId,
                meta_data: {
                  client: topLayer.physicalLayer.meta_data.client,
                  perspective: topLayer.physicalLayer.meta_data.perspective,
                  program_name: topLayer.physicalLayer.meta_data.program_name,
                  program_type: topLayer.physicalLayer.meta_data.program_type,
                  rol: topLayer.physicalLayer.meta_data.rol,
                  rol_type: topLayer.physicalLayer.meta_data.rol_type,
                  sage_layer_type: 'drop',
                  year: topLayer.physicalLayer.meta_data.year,
                  lossfilter_name: '',
                  isDrop: true,
                  topID: topLayer.id,
                  sharedLimitHidden: analyzereConstants.unlimitedValue,
                  layerName: 'New Layer',
                  structureID: topLayer.meta_data.structureID,
                },
                layerRefs: [],
                lossSetFilter: '',
                lossSetLayers: topLayer.lossSetLayers.map(a => ({
                  id: a.id,
                  meta_data: a.meta_data,
                })),
                physicalLayer: {
                  logicalLayerID: logicalId,
                  id: (
                    new Date().getTime() +
                    '' +
                    Math.random() * 100000
                  ).replace('.', ''),
                  type: topLayer.physicalLayer.type,
                  attachment: {
                    value: topLayer.physicalLayer.attachment.value,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  limit: {
                    value: topLayer.physicalLayer.limit.value,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  participation: topLayer.physicalLayer.participation,
                  premium: {
                    value: 0,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  fees: [],
                  aggregateLimit: {
                    value: topLayer.physicalLayer.aggregateLimit.value,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  aggregateAttachment: {
                    value: topLayer.physicalLayer.aggregateAttachment.value,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  description: 'New Layer',
                  meta_data: {
                    client: topLayer.physicalLayer.meta_data.client,
                    perspective: topLayer.physicalLayer.meta_data.perspective,
                    program_name: topLayer.physicalLayer.meta_data.program_name,
                    program_type: topLayer.physicalLayer.meta_data.program_type,
                    rol: topLayer.physicalLayer.meta_data.rol,
                    rol_type: topLayer.physicalLayer.meta_data.rol_type,
                    sage_layer_type: 'drop',
                    year: topLayer.physicalLayer.meta_data.year,
                    isDrop: true,
                  },
                  reinstatements: [],
                  franchise: {
                    value: 0,
                    currency: topLayer.physicalLayer.franchise.currency,
                  },
                  xcoord: 469,
                  ycoord: 110,
                },
                sharedLayerID: '',
                viewMetrics: {
                  error: null,
                  loading: false,
                  metrics: null,
                  rss: null,
                },
              },
            })
          )
        })
      )
    },
    { dispatch: false }
  )

  deleteDropLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromLayersActions.deleteDropLayer),
        withLatestFrom(
          this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
        ),
        tap(([{ layerId }, cededLayers]) => {
          const hiddenLayer = cededLayers.find(
            l =>
              isLayerActualTopAndDrop(l.layer) &&
              l.layer.layerRefs.includes(layerId)
          )
          this.store.dispatch(fromLayersActions.deleteLayer({ id: layerId }))
          if (hiddenLayer) {
            const newLayerRefs = hiddenLayer.layer.layerRefs.filter(
              id => id !== layerId
            )
            this.store.dispatch(
              fromLayersActions.updateLayer({
                id: hiddenLayer.layer.id,
                change: {
                  layerRefs: newLayerRefs,
                },
              })
            )
          }
        })
      ),
    { dispatch: false }
  )

  // * ---------------------------------------------------------------
  // * Multi-Section Layer
  // * ---------------------------------------------------------------

  addMultiSectionLayer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromLayersActions.addLayerSuccess),
        filter(action => isMultiSectionLayer(action.layer, 'visible-layer')),

        // After the visible layer is added, it gets updated to the starting
        // property values, so we need to wait for the update.
        mergeMap(input => {
          this.mslService.setBusy(true)
          if (
            input.layer.physicalLayer.attachment.value === 0 &&
            input.layer.physicalLayer.limit.value === 0
          ) {
            return forkJoin([
              this.actions$.pipe(
                ofType(fromLayersActions.updatePhysicalLayer),
                filter(action => action.id === input.layer.id),
                first()
              ),
            ]).pipe(map(() => input))
          } else {
            return of(input)
          }
        }),

        withLatestFrom(
          this.store.select(fromAnalysisSelectors.selectCededMultiSectionLayers)
        ),

        // Re-get the visible layer so it has the update propery values
        map(([action, layers]) => {
          const visibleLayer = findLayer(layers, action.layer.id)
          if (visibleLayer === undefined) {
            throw Error(`Visible layer ${action.layer.id} not found.`)
          }

          return visibleLayer
        }),

        withLatestFrom(
          this.store.select(fromAnalysisSelectors.selectCededMultiSectionLayers)
        ),

        // Create the two default section layers for a regular multi-section layer
        // If the layer is the result of a paste, copy all sections over
        mergeMap(([visibleLayer, layers]) => {
          // If this layer is the result of pasting, base the new sections on the copied layer
          if (!visibleLayer.meta_data.copiedLayerId) {
            return this.addLayerAndWait(
              ['A', 'B'].map(letter =>
                this.mslService.createSectionLayer(visibleLayer, letter)
              )
            ).pipe(map(sectionResults => ({ visibleLayer, sectionResults })))
          } else {
            const copiedVisibleLayer = findLayer(layers, visibleLayer.meta_data.copiedLayerId)
            const copiedMainLayer = layers.find(layer => layer.meta_data.visible_layer_id === copiedVisibleLayer.id)
            const sections = copiedMainLayer.layerRefs.map(id => findLayer(layers, id)).filter(layer => isMultiSectionLayer(layer, 'section-layer'))
            return this.addLayerAndWait(
              sections.map(section => {
                const sectionLetter =
                  section.physicalLayer.description.split(' ')[1]
                return this.mslService.createSectionLayer(
                  section,
                  sectionLetter,
                  true,
                  visibleLayer.meta_data.layerName
                )
              })
            ).pipe(map(sectionResults => ({ visibleLayer, sectionResults })))
          }
        }),

        withLatestFrom(
          this.store.select(fromAnalysisSelectors.selectCededMultiSectionLayers)
        ),

        // Create the main layer with the section layers as sources
        tap(([{ visibleLayer, sectionResults }, layers]) => {
          const copiedVisibleLayer = layers.find(
            layer => layer.id === visibleLayer.meta_data.copiedLayerId
          )
          const copiedMainLayer = layers.find(
            layer => layer.meta_data.visible_layer_id === copiedVisibleLayer?.id
          )
          this.store.dispatch(
            fromLayersActions.addLayer({
              // @ts-ignore
              layer: this.mslService.createMainLayer(
                visibleLayer,
                sectionResults.map(result => {
                  return result.layer.id
                }),
                copiedMainLayer
              ),
            })
          )
          setTimeout(() => {
            this.mslService.setBusy(false)
          }, 2000)
        })
      )
    },
    { dispatch: false }
  )

  private addLayerAndWait(layerOrLayers: Layer | Layer[]) {
    const layers = Array.isArray(layerOrLayers)
      ? layerOrLayers
      : [layerOrLayers]

    if (layers.length === 0) {
      return of([])
    }

    layers.forEach(layer => {
      layer.meta_data.correlationId = generateUUID()
      this.store.dispatch(fromLayersActions.addLayer({ layer }))
    })

    this.mslService.setBusy(true)
    return forkJoin(
      layers.map(layer =>
        this.actions$.pipe(
          ofType(fromLayersActions.addLayerSuccess),
          filter(
            action =>
              action.layer.meta_data.correlationId ===
              layer.meta_data.correlationId
          ),
          first()
        )
      )
    ).pipe(finalize(() => this.mslService.setBusy(false)))
  }

  /**
   * This effect watches for updatePhysicalLayer changes on the visible
   * layer and propagates those changes to the main and section layers.
   */
  /*
    October 3, 2024:
      This method was commented out to disable properties propogating to section properties per client request 13143
  */
  updateMultiSectionDueToVisibleLayerChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updatePhysicalLayer),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectCededMultiSectionLayers)
      ),

      switchMap(([action, layers]) => {
        // Make sure this is an update for a visible-layer
        const visibleLayer = findLayer(layers, action.id)
        if (
          !visibleLayer ||
          !isMultiSectionLayer(visibleLayer, 'visible-layer')
        ) {
          return []
        }

        const mainLayer = findLayerByVisibleId(layers, visibleLayer.id)
        if (!mainLayer) {
          return []
        }

        this.mslService.setBusy(true)

        try {
          const actions: Action[] = []

          // List of all MonetaryUnit properties on the physical layer
          const monetaryProps = [
            'premium',
            'aggregateLimit',
            'aggregateAttachment',
            'limit',
            'attachment',
            'franchise',
          ]

          // This visible layer has the stale currency, check against new physical props
          const visibleCurrency = visibleLayer.currency ?? 'USD'
          const isCurrencyMismatch = monetaryProps.some(name => {
            const monetaryPropCurrency = path([name], action.change)
            return !equals(visibleCurrency, monetaryPropCurrency)
          })

          // If any MonetaryUnit property has a different currency, it has been updated, push to all MonetaryUnit props
          if (isCurrencyMismatch) {
            const physicalLayer = mainLayer.physicalLayer
            actions.push(
              fromLayersActions.updatePhysicalLayer({
                id: mainLayer.id,
                change: {
                  premium: {
                    ...physicalLayer.premium,
                    currency:
                      action.change.premium?.currency ??
                      physicalLayer.premium.currency,
                  },
                  aggregateLimit: {
                    ...physicalLayer.aggregateLimit,
                    currency:
                      action.change.aggregateLimit?.currency ??
                      physicalLayer.aggregateLimit.currency,
                  },
                  aggregateAttachment: {
                    ...physicalLayer.aggregateAttachment,
                    currency:
                      action.change.aggregateAttachment?.currency ??
                      physicalLayer.aggregateAttachment.currency,
                  },
                  limit: {
                    ...physicalLayer.limit,
                    currency:
                      action.change.limit?.currency ??
                      physicalLayer.limit.currency,
                  },
                  attachment: {
                    ...physicalLayer.attachment,
                    currency:
                      action.change.attachment?.currency ??
                      physicalLayer.attachment.currency,
                  },
                  franchise: {
                    ...physicalLayer.franchise,
                    currency:
                      action.change.franchise?.currency ??
                      physicalLayer.franchise.currency,
                  },
                },
              })
            )
          }

          const mainProps = [
            'premium',
            'aggregateAttachment',
            'aggregateLimit',
            'reinstatements',
            'participation',
          ]

          const isMainChanged = mainProps.some(name => {
            const a = path([name], action.change)
            const b = path([name], mainLayer.physicalLayer)
            return !equals(a, b)
          })

          // Added for 16045 (Make the Multisection[Main-Layer] limit reflect the contractOccuranceLimit)
          const visibleContractOccLimit =
            visibleLayer.physicalLayer.meta_data.contractOccurrenceLimit
          if (visibleContractOccLimit !== mainLayer.physicalLayer.limit.value) {
            actions.push(
              fromLayersActions.updatePhysicalLayer({
                id: mainLayer.id,
                change: {
                  ...mainLayer.physicalLayer,
                  limit: {
                    ...mainLayer.physicalLayer.limit,
                    value: visibleContractOccLimit || 0,
                    currency:
                      visibleLayer.currency ?? mainLayer.currency ?? 'USD',
                  },
                },
              })
            )
          }

          if (isMainChanged) {
            actions.push(
              fromLayersActions.updatePhysicalLayer({
                id: mainLayer.id,
                change: pick(mainProps, action.change),
              })
            )
          }

          // const sectionProps = [
          //   'attachment',
          //   'aggregateAttachment',
          //   'aggregateLimit',
          // ]

          // const sectionLayers = mainLayer.layerRefs.flatMap<Layer>(
          //   id => findLayer(layers, id) ?? []
          // )

          // sectionLayers.forEach(sectionLayer => {
          //   const isSectionChanged = sectionProps.some(name => {
          //     const a = path([name], action.change)
          //     const b = path([name], sectionLayer.physicalLayer)
          //     return !equals(a, b)
          //   })

          //   if (isSectionChanged) {
          //     actions.push(
          //       fromLayersActions.updatePhysicalLayer({
          //         id: sectionLayer.id,
          //         change: pick(sectionProps, action.change),
          //       })
          //     )
          //   }
          // })

          return actions
        } finally {
          this.mslService.setBusy(false)
        }
      })
    )
  })

  /**
   * This action is triggered by the MultiSectionDialogComponent to update the
   * section layers and the main layer.
   */
  updateMultiSectionFromDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.updateMultiSectionFromDialog),
      withLatestFrom(
        this.store.select(fromAnalysisSelectors.selectCededMultiSectionLayers),
        this.store.select(fromAnalysisSelectors.selectLossSetLayers)
      ),

      switchMap(([action, layers, lossSetLayers]) => {
        const { layer: visibleLayer } = action

        const mainLayer = findLayerByVisibleId(layers, visibleLayer.id)
        if (!mainLayer) {
          return throwError(
            `Unable to find the multi-section using the visible id ${visibleLayer.id}`
          )
        }

        const sectionLayers: Layer[] = mainLayer.layerRefs.flatMap(
          id => findLayer(layers, id) ?? []
        )

        const letter = (x: Section | Layer) =>
          'letter' in x
            ? x.letter
            : letterFromDescription(x.physicalLayer.description)

        // Synchronize section layers loss sets in case inurance was done.
        const sections = action.sections.map(section => {
          const sectionLayer = sectionLayers.find(
            s => letter(s) === letter(section)
          )
          const updatedLSL = sectionLayer?.lossSetLayers.map(
            (l: LayerRef) => l.id
          )
          const lossSets = section.lossSets ?? updatedLSL
          return { ...section, lossSets }
        })

        try {
          const sectionForLayer = (layer: Layer): Section => {
            const section = sections.find(s => letter(s) === letter(layer))
            if (section === undefined) {
              throw Error(
                `Unable to find matching section for letter ${letter}`
              )
            }

            return section
          }

          this.store.dispatch(
            fromLayersActions.updatePhysicalLayer({
              id: visibleLayer.id,
              change: {
                ...visibleLayer.physicalLayer,
                attachment: {
                  ...visibleLayer.physicalLayer.attachment,
                  // @ts-ignore
                  value: sectionLayers[0].physicalLayer.attachment.value,
                },
              },
            })
          )

          // Update Visible Layer Loss Sets to Match All Loss Sets from Current Sections
          const lossSets = lossSetLayers.filter(lossSet =>
            sections.some(section => section.lossSets.includes(lossSet.id))
          )
          this.store.dispatch(
            fromLayersActions.updateLayer({
              id: visibleLayer.id,
              change: { ...visibleLayer, lossSetLayers: lossSets },
            })
          )

          // This is necessary because differenceWith tries to pass both
          // types of arguments to both of the arguments.
          const letterEq = (x: Section | Layer, y: Section | Layer) =>
            letter(x) === letter(y)

          // @ts-ignore
          const addedSections: Section[] = differenceWith(
            (x, y) => letterEq(x, y),
            sections,
            sectionLayers
          )

          // @ts-ignore
          const removedSectionLayers: Layer[] = differenceWith(
            (x, y) => letterEq(x, y),
            sectionLayers,
            sections
          )

          // @ts-ignore
          const existingSectionLayers: Layer[] = differenceWith(
            (x, y) => letterEq(x, y),
            sectionLayers,
            removedSectionLayers
          )

          // Added sections are new modal sections not in the current section layers
          const added = addedSections.flatMap(section =>
            this.mslService.addSectionLayers(visibleLayer, section)
          )

          // Removed section layers are section layers without a corresponding modal section
          const removed = removedSectionLayers.flatMap(({ id }) =>
            fromLayersActions.deleteLayer({ id })
          )

          of(added)
            .pipe(
              mergeMap(addLayers => this.addLayerAndWait(addLayers)),
              map(addResults => addResults.map(addResult => addResult.layer.id))
            )
            .subscribe(addedSectionLayerIds => {
              // The new set of sections should be the current set minus the ones that were
              // removed plus the ones that were added
              const layerRefs = difference(
                mainLayer.layerRefs,
                removedSectionLayers.map(layer => layer.id)
              ).concat(addedSectionLayerIds)

              // Update the main layer to point to the new set of sections
              this.store.dispatch(
                fromLayersActions.updateLayer({
                  id: mainLayer.id,
                  change: { layerRefs },
                })
              )
            })

          // Existing section layers correspond to sections in the modal
          const existing = existingSectionLayers.flatMap(layer => {
            const section = sectionForLayer(layer)

            const inuringSectionIds = section.inuringSections.flatMap(
              sectionLetter =>
                existingSectionLayers.find(
                  inuringLayer => letter(inuringLayer) === sectionLetter
                )?.id ?? [] as string[]
            )

            this.store.dispatch(
              fromLayersActions.addMultiSectionInuring({
                visibleLayer,
                sectionLayer: layer,
                section,
                inuringSectionIds,
              })
            )

            return this.mslService.updateSectionLayers(
              lossSetLayers,
              layer,
              section
            )
          })

          return [...removed, ...existing]
        } finally {
          this.mslService.setBusy(false)
        }
      })
    )
  })

  addMultiSectionInuring$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromLayersActions.addMultiSectionInuring),

      mergeMap(({ visibleLayer, sectionLayer, section, inuringSectionIds }) => {
        this.mslService.setBusy(true)

        try {
          // Delete the previous flipper since it is being replaced or removed
          if (sectionLayer.layerRefs.length === 1) {
            this.store.dispatch(
              fromLayersActions.deleteLayer({ id: sectionLayer.layerRefs[0] })
            )
          }

          if (inuringSectionIds.length > 0) {
            // Create a flipper layer if the section has inuring sections
            return this.addLayerAndWait(
              this.mslService.createFlipperLayer(
                visibleLayer,
                section,
                inuringSectionIds
              )
            ).pipe(
              map(flipperLayers => flipperLayers[0].layer.id),
              map(flipperId =>
                fromLayersActions.updateLayer({
                  id: sectionLayer.id,
                  change: {
                    layerRefs: [flipperId],
                  },
                })
              )
            )
          } else {
            return of(
              // If there are no inuring sections then delete any flipper
              // that might have existed
              fromLayersActions.updateLayer({
                id: sectionLayer.id,
                change: {
                  layerRefs: [],
                },
              })
            )
          }
        } finally {
          this.mslService.setBusy(false)
        }
      })
    )
  })
}
