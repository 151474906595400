<ng-container [ngSwitch]="activeView">
  <app-tier-structures
    *ngSwitchCase="viewType.SELECTOR"
    class="content structure"
    small
    isSignature="true"
    [clientSelect]="clientSelect"
    [selectedClientID]="selectedClientID"
    [selectedYearID]="selectedYearID$ | async"
    [years]="years$ | async"
    [selectedProgramID]="selectedProgramID$ | async"
    [programs]="programs$ | async"
    [structures]="structures$ | async"
    [structureFilter]="structureFilter$ | async"
    [structureGroupMembers]="structureGroupMembers$ | async"
    [accountOpportunities]="accountOpportunities$ | async"
    [contextPath]="['signature']"
    (initSignaturePage)="onInitSignaturePage($event)"
  ></app-tier-structures>
  <app-signature-content
    class="content"
    *ngSwitchCase="viewType.CONTENT"
    [fots]="fots$ | async"
    [contracts]="contracts$ | async"
    [selectedContract]="selectedContract$ | async"
    [reinsurers]="reinsurers$ | async"
    [selectedReinsurer]="selectedReinsurer$ | async"
    [currentPageIndex]="currentPageIndex$ | async"
    [isDirty]="isDirty$ | async"
    [isSaving]="isSaving$ | async"
    [isExporting]="isExporting$ | async"
    [dirtyContracts]="dirtyContracts$ | async"
    [featureFlags]="featureFlags$ | async"
    [groupOrStructureFilterSelection]="
      groupOrStructureFilterSelection$ | async
    "
    [renewalRiskRefs]="renewalRiskRefs$ | async"
    [clients]="clients$ | async"
    [isUsaBasedUser]="isUsaBasedUser$ | async"
    (backToSelectorClick)="onBackToSelectorClick()"
    (createContract)="createContract($event)"
    (resetClick)="onResetClick($event)"
    (saveClick)="onSaveClick()"
    (deleteClick)="onDeleteClick($event)"
    (deleteReinsurerClick)="onDeleteReinsurerClick($event)"
    (generateExportClick)="onGenerateExportClick($event)"
    (setSelectedContract)="setSelectedContract($event)"
    (setSelectedSigPage)="setSelectedSigPage($event)"
    (updateSignatureForm)="updateSignatureForm($event)"
    (addLayerToSelectedContract)="addLayerToSelectedContract($event)"
    (removeLayerFromSelectedContract)="
      removeLayerFromSelectedContract($event)
    "
    (submitToOT)="submitToOT($event)"
    (isCoRiskContractChangeEvent)="isCoRiskContractChanged($event)"
    (leadRiskRefChangeEvent)="leadRiskRefChanged($event)"
  ></app-signature-content>
</ng-container>
