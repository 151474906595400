<button
  mat-icon-button
  class="menu-icon"
  id="menu"
  (click)="navService.open()"
>
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="title"
  [subtitle]="getSubtitle()"
  [tierNames]="tierNames"
  (backClick)="onBackClick()"
></app-header-title>
<div class="action" *ngIf="!executing; else actionRunning">
  <button
    appButton
    big
    accent
    border
    (click)="processEvents.emit()"
    [disabled]="disableProcessEvents"
  >
    Process Events
  </button>
  <button
    appButton
    big
    link
    accent
    (click)="reanimate.emit()"
    [disabled]="disableReanimate"
  >
    Re-Animate
  </button>
  <button
    *ngIf="!recording"
    appButton
    big
    link
    accent
    (click)="record.emit()"
  >
    Start Recording
  </button>
  <button
    *ngIf="recording"
    appButton
    big
    link
    accent
    (click)="stopRecord.emit()"
  >
    Stop Recording
  </button>
</div>
<ng-template #actionRunning>
  <app-active-action
    class="action"
    [value]="'Processing'"
  ></app-active-action>
</ng-template>
