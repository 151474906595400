import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import {
  SavedCurveSelectors,
  IIntervalFilter,
  PricingCurveStatus,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import * as fromActions from 'src/app/pricingcurve/store/pricing-curve.actions'
import { MatInput } from '@angular/material/input'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { DatePipe } from '@angular/common'
import { IControl } from 'src/app/management-information/store/management-information.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-saved-curve-filter-bar',
  styleUrls: ['./pricing-curve-saved-curve-filter-bar.component.scss'],
  templateUrl: './pricing-curve-saved-curve-filter-bar.component.html',
})
export class PricingCurveSavedCurveFilterBarComponent
  implements OnInit, OnChanges
{
  @Input() savedCurveSelectors: SavedCurveSelectors
  @Input() status: PricingCurveStatus

  form: FormGroup

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      fullName: new FormControl([]),
      pcClass: new FormControl([]),
      pcSubClass: new FormControl([]),
      layerCategory: new FormControl([]),
      updateDateInputStart: new FormControl(),
      updateDateInputEnd: new FormControl(),
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedCurveSelectors && this.form) {
      this.form.controls['updateDateInputStart'].setValue(
        this.savedCurveSelectors.updateDate.minValue
          ? new Date(this.savedCurveSelectors.updateDate.minValue)
          : ''
      )
      this.form.controls['updateDateInputEnd'].setValue(
        this.savedCurveSelectors.updateDate.maxValue
          ? new Date(this.savedCurveSelectors.updateDate.maxValue)
          : ''
      )
    }
    if (changes.status?.currentValue && this.form) {
      const eitherLoading = this.status.isLoading || this.status.dialogLoading
      if (eitherLoading) {
        this.form.controls['updateDateInputStart'].disable()
        this.form.controls['updateDateInputEnd'].disable()
      } else {
        this.form.controls['updateDateInputStart'].enable()
        this.form.controls['updateDateInputEnd'].enable()
      }
    }
  }

  @ViewChild('updateDateInputStart', {
    read: MatInput,
  })
  inceptionDateInputStart: MatInput

  @ViewChild('updateDateInputEnd', {
    read: MatInput,
  })
  inceptionDateInputEnd: MatInput

  dispatchFilters() {
    const filters = this.getFiltersFromControls()
    this.store.dispatch(fromActions.savedCurveFiltersChanged({ filters }))
  }

  private getFiltersFromControls(): Record<string, string[]> {
    return Object.entries(this.form.controls).reduce(
      (acc, [key, formControl]) => {
        acc = {
          ...acc,
          [key]: formControl.value,
        }
        return acc
      },
      {}
    )
  }

  clearFilter(event: MouseEvent, filter: IControl) {
    event?.stopPropagation()
    const columnName = filter.columnName

    this.form.controls[columnName].setValue([])
    this.store.dispatch(fromActions.clearSavedCurveFilter({ columnName }))
  }

  clearDate(columnName: string, isMin: boolean) {
    if (columnName === 'updateDate') {
      if (isMin) {
        this.inceptionDateInputStart.value = ''
      } else {
        this.inceptionDateInputEnd.value = ''
      }
    }
    this.store.dispatch(
      fromActions.clearSavedCurveDateInterval({ columnName, isMin })
    )
  }

  onStartDateChange(columnName: string, $event: MatDatepickerInputEvent<Date>) {
    const dateValue = $event.target.value
    const date = this.datePipe.transform(dateValue, 'MM/dd/yyyy')
    const intervalToUpdate: IIntervalFilter = {
      filterId: columnName,
      newMinValue: date ?? undefined,
    }

    this.store.dispatch(
      fromActions.savedCurveIntervalFilterChanged({
        data: intervalToUpdate,
        isMin: true,
      })
    )
  }

  onEndDateChange(columnName: string, $event: MatDatepickerInputEvent<Date>) {
    const dateValue = $event.target.value
    const date = this.datePipe.transform(dateValue, 'MM/dd/yyyy')
    const intervalToUpdate: IIntervalFilter = {
      filterId: columnName,
      newMaxValue: date ?? undefined,
    }

    this.store.dispatch(
      fromActions.savedCurveIntervalFilterChanged({
        data: intervalToUpdate,
        isMin: false,
      })
    )
  }
}
