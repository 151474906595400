<div class="program-default-content">
  <div class="header-content">
    <h3 class="header">Default Curves for Layer Types</h3>
    <h3 class="header header-error" *ngIf="entryHasError">
      &nbsp;- Weights Must Equal 100%
    </h3>
  </div>
  <div class="program-default-entries">
    <app-technical-premium-list-entry
      *ngFor="let layerTypeEntry of usedLayerTypeEntries | keyvalue"
      [id]="layerTypeEntry.value.id"
      [title]="layerTypeEntry.value.name"
      [layerType]="layerTypeEntry.value.layerType"
      [curveEntries]="layerTypeEntry.value.pricingCurves"
      [savedCurves]="savedCurves"
      [isLayer]="false"
      [hasError]="layerTypeEntry.value.hasError"
      [saveForNewLayersOnly]="layerTypeEntry.value.saveForOnlyNewLayers"
      [pricingCurveIds]="layerTypeEntry.value.pricingCurveIds"
      [modified]="layerTypeEntry.value.modified"
      (updateCurveEntries)="handleCurveOperation($event)"
      (resetLayerTypeEntry)="resetEntryToInitialValue($event)"
      (dialogClosed)="dialogClosed.emit()"
    ></app-technical-premium-list-entry>
  </div>
</div>
