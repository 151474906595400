import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { ScenarioEventResult } from '../animated-scenarios.model'
import { MatTableDataSource } from '@angular/material/table'
import * as d3 from 'd3'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { CUBE_TRANSITION } from '../tower-3d.component/tower-3d.component'

@Component({
  selector: 'app-scenario-event-result-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./event-result-table.component.scss'],
  templateUrl: './event-result-table.component.html',
})
export class ScenarioEventResultTableComponent {
  @Input()
  set eventResult(val: ScenarioEventResult) {
    this.dataSource.data = [val]
  }

  @Input() animating: boolean

  @Output() cededLossClick = new EventEmitter<ScenarioEventResult>()

  dataSource = new MatTableDataSource<ScenarioEventResult>()
  get displayColumns() {
    return ['gross', 'ceded', 'net']
  }

  get currency(): string {
    if (
      this.dataSource.data &&
      this.dataSource.data.length > 0 &&
      this.dataSource.data[0].layers.length > 0
    ) {
      return this.dataSource.data[0].layers[0].layer.physicalLayer.limit
        .currency
    } else {
      return 'USD'
    }
  }

  constructor(
    private elementRef: ElementRef,
    private shortNumberPipe: ShortNumberPipe
  ) {}

  interpolate(): void {
    const currency = this.currency
    this.interpolateField(
      '.edit.gross',
      this.dataSource.data[0].gross,
      currency
    )
    this.interpolateField(
      '.edit.ceded',
      this.dataSource.data[0].ceded,
      currency
    )
    this.interpolateField('.edit.net', this.dataSource.data[0].net, currency)
  }

  private interpolateField(
    selector: string,
    maxValue: number,
    currency: string
  ): void {
    d3.select(this.elementRef.nativeElement)
      .select(selector)
      .transition()
      .delay(250)
      .duration(CUBE_TRANSITION - 250)
      .tween('net', (_d: any, i: number, g: any) => {
        const iFunction = d3.interpolateNumber(0, maxValue)
        return (n: number) => {
          const value = iFunction(n)
          d3.select(g[i]).text(this.shortNumberPipe.transform(value, currency))
        }
      })
  }
}
