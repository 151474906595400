<h1 mat-dialog-title>Create Submission</h1>
<div mat-dialog-content>
  <app-layer-property
    [type]="'dropdown'"
    [name]="'Transaction'"
    [value]="transactionId"
    [references]="references"
    (inputChange)="transactionId = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Label'"
    [value]="label"
    (inputChange)="label = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Description'"
    [value]="description"
    (inputChange)="description = $event"
  ></app-layer-property>
  <mat-form-field floatLabel="always">
    <mat-label>Reinsurers to Receive Submission</mat-label>
    <input
      matInput
      placeholder="Type to add reinsurer..."
      [formControl]="filterCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="autoReinsurers"
    />
    <mat-autocomplete #autoReinsurers="matAutocomplete">
      <ng-container *ngFor="let reinsurer of filteredReinsurers | async">
        <mat-option [value]="reinsurer.reinsurerId">
          <mat-checkbox
            [checked]="selectedReinsurers.indexOf(reinsurer) > -1"
            (click)="$event.stopPropagation()"
            (change)="toggleReinsurer(reinsurer)"
          >
            {{ reinsurer.reinsurerName }}
          </mat-checkbox>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-chip-grid #chipGrid>
    <mat-chip-row
      class="app-chip-palette app-palette-body"
      *ngFor="let reinsurer of selectedReinsurers"
      (removed)="toggleReinsurer(reinsurer)"
    >
      {{ reinsurer.reinsurerName }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <mat-progress-bar
    *ngIf="creatingSubmissions"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div mat-dialog-actions align="start">
  <button
    appButton
    border
    accent
    [disabled]="selectedReinsurers.length < 1"
    (click)="onCreateClick()"
  >
    Create
  </button>
  <button appButton link mat-dialog-close>Cancel</button>
</div>
