import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  deleteGroupSuccess,
  saveGroupSuccess,
  updateGroupSuccess,
} from '../../analysis/store/loss-set-layers/loss-set-group/loss-set-group.actions'
import { convertLossSetGroupFromResponse } from '../../api/loss-set-group/loss-set-group.converter'
import { LossSetGroup } from '../../analysis/model/loss-set-layers.model'
import { ApplicationError } from '../../error/model/error'
import * as AuthActions from './auth/auth.actions'

interface ExtendedState {
  loading: boolean
  error: ApplicationError | null
}

export type State = EntityState<LossSetGroup> & ExtendedState

export const adapter = createEntityAdapter<LossSetGroup>()

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
})

const lossSetGroupsReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(AuthActions.identifyPermissionsSuccess, (state, { lossSetGroups }) => {
    const next = adapter.setAll(
      lossSetGroups.map(convertLossSetGroupFromResponse),
      state
    )
    return {
      ...next,
      loading: initialState.loading,
      error: initialState.error,
    }
  }),

  on(saveGroupSuccess, (state, { groupAndMembers }) => {
    return adapter.addOne(
      { ...groupAndMembers.lossSetGroup, lossSetLayers: [] },
      state
    )
  }),

  on(deleteGroupSuccess, (state, { lossSetGroup }) => {
    return adapter.removeOne(lossSetGroup.id, state)
  }),

  on(updateGroupSuccess, (state, { groupAndMembers }) => {
    const changes = {
      ...groupAndMembers.lossSetGroup,
    }
    return adapter.updateOne(
      { id: groupAndMembers.lossSetGroup.id, changes },
      state
    )
  })
)

export function reducer(state: State | undefined, action: Action) {
  return lossSetGroupsReducer(state, action)
}
