import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import {
  ProgramRiskCodeMapping,
  RiskCodeSection,
  SelectedRiskCode,
} from '../../models/quote.model'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  selector: 'app-quote-risk-code-dialog-component',
  templateUrl: 'quote-risk-code-dialog.component.html',
  styleUrls: ['quote-risk-code-dialog.component.scss'],
})
export class QuoteRiskCodeDialogComponent implements OnInit {
  @Input() riskCodes: RiskCodeSection[]
  @Input() riskCodeMappings: ProgramRiskCodeMapping[]
  @Input() programId: string
  @Input() layerId: string

  @Output() saveRiskCodesClick = new EventEmitter<ProgramRiskCodeMapping[]>()

  selectedRiskObj: RiskCodeSection
  riskPercentage: number
  riskCodeWithPercentArray: SelectedRiskCode[] = []
  riskCodeControl = new FormControl('')
  filterRiskCodes: RiskCodeSection[]
  thisLayerOnly = false

  constructor(public dialogRef: MatDialogRef<QuoteRiskCodeDialogComponent>) {}

  ngOnInit(): void {
    this.filterRiskCodes = Object.assign([], this.riskCodes)
    this.riskCodeControl.valueChanges.subscribe(riskCode => {
      this.filterRiskCodes = this.filterRiskCodeValues(riskCode)
    })

    if (this.riskCodeMappings && this.riskCodeMappings.length > 0) {
      const forProgram = this.riskCodeMappings.filter(
        mapping => mapping.layerRef === 'DEFAULT'
      )
      const forLayer = this.riskCodeMappings.filter(
        mapping => mapping.layerRef === this.layerId
      )
      const mappings = forLayer.length > 0 ? forLayer : forProgram

      this.thisLayerOnly = forLayer.length > 0

      mappings.map(mapping => {
        const description = this.riskCodes.find(
          code => code.risk_code === mapping.riskCode
        )?.risk_code_description

        this.riskCodeWithPercentArray.push({
          percentage: mapping.percentage,
          risk_code: mapping.riskCode,
          risk_code_description: description ? description : '',
        })
      })
    }

    this.riskPercentage = this.riskPercentage ? this.riskPercentage : 1
  }

  filterRiskCodeValues(searchVal: any): RiskCodeSection[] {
    if (searchVal.code) {
      return this.riskCodes.filter(
        value =>
          value.risk_code
            .toLowerCase()
            .indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      return this.riskCodes.filter(
        value =>
          value.risk_code.toLowerCase().indexOf(searchVal.toLowerCase()) === 0
      )
    }
  }

  displayFn(risk: RiskCodeSection): string | undefined {
    return risk ? risk.risk_code : undefined
  }

  onRiskCodeChange(riskObj: any): void {
    this.selectedRiskObj = riskObj
    this.riskPercentage = this.riskPercentage ? this.riskPercentage : 1
  }

  onToggleThisLayerChange($event: MatCheckboxChange): void {
    this.thisLayerOnly = $event.checked
  }

  onAddClick(): void {
    if (this.selectedRiskObj && this.riskPercentage >= 0) {
      const obj = {} as SelectedRiskCode
      obj.risk_code = this.selectedRiskObj.risk_code
      obj.risk_code_description = this.selectedRiskObj.risk_code_description
      obj.percentage = this.riskPercentage
      this.riskCodeWithPercentArray.push(obj)
    }
  }

  deletedRiskCode(riskId: any): void {
    this.riskCodeWithPercentArray.splice(
      this.riskCodeWithPercentArray.findIndex(
        (obj: SelectedRiskCode) => obj.risk_code === riskId
      ),
      1
    )
  }

  getTotal(): number {
    if (
      this.riskCodeWithPercentArray &&
      this.riskCodeWithPercentArray.length > 0
    ) {
      const sum = this.riskCodeWithPercentArray
        .map(risk => risk.percentage)
        .reduce((total, percentage) => total + percentage)
      return sum
    }
    return 0
  }

  validateOk(): boolean {
    if (
      this.riskCodeWithPercentArray &&
      this.riskCodeWithPercentArray.length > 0
    ) {
      const sum = this.getTotal()
      if (sum !== 1) {
        return true
      }
      return false
    }
    return true
  }

  getPercentage(): number {
    return this.riskPercentage
  }

  onInputChangeSetPercentage($event: any): void {
    this.riskPercentage = $event
  }

  validateAdd(): boolean {
    if (this.selectedRiskObj) {
      if (
        this.riskCodes
          .map(risk => risk.risk_code)
          .includes(this.selectedRiskObj.risk_code)
      ) {
        return false
      }
      return true
    }
    return true
  }

  onOKClick(): void {
    const mappings: ProgramRiskCodeMapping[] = []
    const layerIdOrDefault = this.thisLayerOnly ? this.layerId : 'DEFAULT'

    this.riskCodeWithPercentArray.map(risk => {
      mappings.push({
        programId: parseInt(this.programId, 10),
        layerRef: layerIdOrDefault,
        percentage: risk.percentage,
        riskCode: risk.risk_code,
      })
    })
    this.saveRiskCodesClick.emit(mappings)
  }
}
