import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  CompareMetricValue,
  UpdateCompareMetric,
  UpdateGrossMetric,
} from '../../analysis/model/compare-metrics.model'
import { NO_CATEGORY_NAME } from '../../analysis/store/compare/compare-metric-settings/create-compare-metric-values'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { MetricUpdateData } from '@shared/metrics-toggles.component'
import { MetricBuilderDialogService } from '../metric-builder-dialog/metric-builder-dialog.service'
import { hideMetric } from '../metrics.util'
import { CreditPortfolio } from 'src/app/credit/model/credit-portfolio.model'
import { MatDialog } from '@angular/material/dialog'
import { ScenarioSelectorComponent } from '@shared/scenario-selector/scenario-selector.component'
import { SelectedScenario } from 'src/app/credit/model/credit-results.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-settings-categories-table',
  styles: [
    `
           :host {
             background-color: var(--bg-1);
             border: 1px solid var(--border-1);
             border-radius: var(--border-radius-big);
           }

           :host:not(.collapsed) {
             padding-bottom: var(--stack-small);
           }

           /* Header and metric rows */
           h3,
           .row {
             display: flex;
             flex-wrap: nowrap;
             flex-direction: row;
             justify-content: space-between;
             align-items: center;
             box-sizing: border-box;
             padding: var(--stack-tiny) var(--inset-small) var(--stack-tiny)
               var(--inset);
           }

           h3 > *,
           .row > * {
             flex: 0 0 auto;
           }

           .row > div {
             display: flex;
             flex: 1 1 auto;
             overflow: hidden;
             align-items: baseline;
           }

           h3 > .display-label,
           .row > div > label {
             flex: 1 1 auto;
           }

           /* Header & metric text styling */
           .row,
           h3 {
             font-family: var(--font-header-family);
             font-size: var(--font-size-big);
           }

           label,
           h3 > span {
             white-space: nowrap;
             text-overflow: ellipsis;
             overflow: hidden;
           }

           h3 {
             align-items: baseline;
             width: 100%;
             font-weight: var(--font-link-weight);
             line-height: 24px;
             letter-spacing: 0.3px;
             margin: var(--stack-small) 0;
             border-bottom: 1px solid var(--border-1);
             padding-bottom: var(--stack);
             padding-right: 0.8rem;
             cursor: pointer;
           }

           /* Remove header bottom margin when collapsed */
           :host.collapsed h3 {
             margin-bottom: 0;
             border-bottom-width: 0;
           }

           h3,
           h3 > button {
             color: var(--primary);
           }

           :host.collapsed h3:hover > .info,
           h3:hover > .display-label,
           h3:hover > button {
             color: var(--primary-lit);
           }

           h3 > .info {
             font-size: var(--font-size-tiny);
             color: var(--subtle);
           }

           :host.collapsed h3 > .info,
           h3 > button {
             align-self: center;
           }

           h3 > button {
             font-size: var(--font-size-huge);
           }

           label {
             color: var(--body);
             font-weight: var(--font-weight);
             white-space: initial;
           }

           /* Enable column */
           .info.enable,
           .check {
             flex: 0 0 auto;
             width: 2.75rem;
             margin-right: var(--inset-small);
           }

           /* Weight column */
           .info.weight,
           input {
             width: 3rem;
             text-align: center;
             margin-right: var(--inset);
           }

           /* Threshold column */
           .info.threshold,
           input.threshold {
             width: 6rem;
             margin-right: 0;
             text-align: left;
           }

           .misc {
             display: inline-block;
             flex: 0 0 auto;
           }

           .misc > label {
             padding-left: var(--inset-small);
             padding-right: var(--inset-tiny);
             color: var(--subtle);
           }

           /* Remove browser default up/down buttons in number input field */
           input::-webkit-outer-spin-button,
           input::-webkit-inner-spin-button {
             /* display: none; <- Crashes Chrome on hover */
             -webkit-appearance: none;
             margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
           }

           input[type='number'] {
             -moz-appearance: textfield; /* Firefox */
           }

           .slider {
             flex: 0 0 auto;
             padding: 0;
             margin-right: var(--inset-small);
           }

           span.year {
             padding: 0 var(--inset-tiny);
           }

           .popover-button {
             position: relative;
             top: 0.35em;
             flex: 0 0 auto;
             height: 26px;
             background: transparent;
             margin-left: var(--inset-small);
             padding: 0 var(--inset-tiny);
           }

           .warning-icon {
             position: relative;
             top: 0.25em;
             background: transparent;
             margin-left: var(--inset-small);
             padding: 0 var(--inset-tiny);
             color: var(--caution);
             cursor: pointer;
           }

           app-metrics-toggles {
             margin: var(--stack) var(--inset) 0;
             display: block;
             width: 200px;
             box-sizing: border-box;
           }

           app-capital-metrics-custom {
             margin: var(--stack) var(--inset) 0;
             display: block;
             box-sizing: border-box;
           }

           /* Decrease font sizes on small screen */
           @media screen and (max-width: 1240px) {
             .row,
             h3 {
               font-size: var(--font-size);
             }

             h3 {
               /* Align collapse button w/ ellipses */
               padding-right: 1.1rem;
             }

             h3 > button {
               font-size: var(--font-size-big);
             }
           }
           .scenario-select-button {
             width: fit-content;
           }
         `,
  ],
  template: `
    <h3 (click)="onCollapseToggle($event)">
      <span class="info enable">{{ collapsed ? 'Expand' : 'Enable' }}</span>
      <span class="info weight">{{ collapsed ? '' : 'Weight' }}</span>
      <span class="display-label">{{ displayLabel }}</span>
      <button
        appButtonIcon
        huge
        opaque
        matTooltip="Click the arrow to show or hide for display purposes on this page."
        matTooltipPosition="above"
      >
        {{ headerIcon }}
      </button>
    </h3>

    <ng-container *ngIf="!collapsed">
      <div class="row" *ngFor="let rowValues of values">
        <div *ngIf="!hideMetric(rowValues[0])">
          <mat-slide-toggle
            class="check"
            matTooltip="Enable to include this metric on the Compare page."
            matTooltipPosition="above"
            [matTooltipDisabled]="rowValues[0].show"
            [checked]="rowValues[0].show"
            (change)="
              updateMetric(
                $event,
                rowValues[0].weight,
                rowValues[0].year,
                rowValues[0]
              )
            "
          ></mat-slide-toggle>

          <input
            appInput
            big
            class="weight"
            type="number"
            min="0"
            max="100"
            matTooltip="Enter a value to weight this metric in the heatmap index."
            matTooltipPosition="above"
            [ngModel]="rowValues[0].weight"
            (ngModelChange)="setRowValuesWeight($event, rowValues)"
            (change)="
              onWeightChange(
                rowValues[0].show,
                $event.target.value,
                rowValues[0].year,
                rowValues[0]
              )
            "
          />

          <ng-container
            *ngIf="
              rowValues[0].year &&
              rowValues[0].year !== '' &&
              rowValues[0].category !== 'Misc'
            "
          >
            <button
              *ngIf="!isCredit; else scenarioSelector"
              class="slider"
              appButton
              [matMenuTriggerFor]="sliderMenu"
            >
              <span class="year">{{ rowValues[0].year }}yr</span>
            </button>

            <ng-template #scenarioSelector>
              <button
                class="slider scenario-select-button"
                appButton
                (click)="onScenarioClicked(rowValues[0])"
              >
                <span *ngIf="!isCredit; else scenarioLabel" class="year"
                  >{{ rowValues[0].year }}yr</span
                >
                <ng-template #scenarioLabel>
                  <span class="year">{{
                    rowValues[0].credit_scenario ? 'Deterministic' : 'N/A'
                  }}</span>
                </ng-template>
              </button>
            </ng-template>

            <mat-menu
              #sliderMenu="matMenu"
              yPosition="above"
              class="app-menu-no-max-width"
            >
              <app-return-period-selector
                [returnPeriod]="rowValues[0].year"
                (click)="$event.preventDefault(); $event.stopPropagation()"
                matTooltip="Select a return period to pair with this metric."
                matTooltipPosition="above"
                [presets]="
                  rowValues[0].label.includes('S&P Capital Benefit')
                    ? [200, 250, 333, 500]
                    : [1, 5, 10, 25, 50, 100, 250, 1000]
                "
                (returnPeriodChange)="
                  onYearChange(
                    rowValues[0].show,
                    $event,
                    rowValues[0].weight,
                    rowValues[0]
                  )
                "
              ></app-return-period-selector>
            </mat-menu>
          </ng-container>

          <label>
            {{ getFirstLabel(rowValues) }}
          </label>

          <div
            class="misc"
            *ngIf="rowValues[0].path === 'miscMetrics/probabilityMetric'"
          >
            <label> > </label>
            <input
              appInput
              class="threshold"
              type="number"
              matTooltip="Enter a Threshold value."
              matTooltipPosition="above"
              [ngModel]="rowValues[0].year || 0"
              (change)="
                onYearChange(
                  rowValues[0].show,
                  $event.target.value,
                  rowValues[0].weight,
                  rowValues[0]
                )
              "
            />
          </div>

          <mat-icon
            class="warning-icon"
            *ngIf="rowValues[0].validFormula === false"
            matTooltip="The formula for this metric is no longer valid. One of the component metrics has changed."
            matTooltipPosition="above"
          >
            error
          </mat-icon>

          <button
            appButton
            class="popover-button"
            matTooltip="Select from the options to customize this metric."
            matTooltipPosition="above"
            *ngIf="
              rowValues[0].perspective &&
              rowValues[0].perspective !== '' &&
              !isCredit
            "
            [matMenuTriggerFor]="popoverMenu"
          >
            <mat-icon aria-hidden="false" aria-label="More">
              more_horiz
            </mat-icon>
          </button>

          <mat-menu
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            #popoverMenu="matMenu"
            yPosition="above"
            [hasBackdrop]="true"
          >
            <app-metrics-toggles
              [metrics]="rowValues[0]"
              [lossFilters]="lossFilters"
              (updateMetricClick)="onUpdateMetricClick($event, rowValues[0])"
            ></app-metrics-toggles>
          </mat-menu>

          <button
            appButton
            class="popover-button"
            matTooltip="Select from the options to customize this metric."
            matTooltipPosition="above"
            *ngIf="
              rowValues[0].label &&
              (rowValues[0].label.includes('S&P Capital Benefit') ||
                rowValues[0].label === 'BCAR Capital Benefit')
            "
            [matMenuTriggerFor]="popoverCapitalMenu"
          >
            <mat-icon aria-hidden="false" aria-label="More">
              more_horiz
            </mat-icon>
          </button>

          <mat-menu
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            #popoverCapitalMenu="matMenu"
            yPosition="above"
            class="app-menu-no-max-width"
          >
            <app-capital-metrics-custom
              [metrics]="rowValues[0]"
              [spValues]="createCapitalArray(rowValues[0])"
              (spPremiumValueChange)="
                onSpPremiumValueChange($event, rowValues[0])
              "
              (spReserveValueChange)="
                onSpReserveValueChange($event, rowValues[0])
              "
              (spDivesificationValueChange)="
                onSpDivesificationValueChange($event, rowValues[0])
              "
              (spCatValueChange)="onSpCatValueChange($event, rowValues[0])"
            ></app-capital-metrics-custom>
          </mat-menu>

          <button
            appButton
            class="popover-button"
            matTooltip="Edit or delete custom metric."
            matTooltipPosition="above"
            *ngIf="
              rowValues[0].category &&
              rowValues[0].category === 'Custom Metrics'
            "
            [matMenuTriggerFor]="popoverCustomMenu"
          >
            <mat-icon aria-hidden="false" aria-label="More">
              more_horiz
            </mat-icon>
          </button>

          <mat-menu
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            #popoverCustomMenu="matMenu"
            yPosition="above"
            class="app-menu-no-max-width"
          >
            <button mat-menu-item (click)="onCustomMetricDelete(rowValues[0])">
              Delete
            </button>
            <button mat-menu-item (click)="onCustomMetricEdit(rowValues[0])">
              Edit
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  `,
})
export class MetricSettingsCategoriesTableComponent implements OnInit {
  @Input() isCredit: boolean
  @Input() set creditPortfolio(creditPortfolio: CreditPortfolio) {
    this._creditPortfolio = creditPortfolio
    this.viewScenarioDict = {}
    if (!creditPortfolio) {
      return
    }
    creditPortfolio.gross_loss_scenarios.forEach(portfolio => {
      this.viewScenarioDict[portfolio._id] = portfolio.name
    })
  }

  @Input() label: string
  @Input() values: CompareMetricSetting[][]
  @Input() hasPortfolioTypes: boolean
  @Input() lossFilters: LossFilter[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() updateGrossMetricElement = new EventEmitter<UpdateGrossMetric>()
  valueHeight = '60'
  _creditPortfolio: CreditPortfolio
  viewScenarioDict: { [key: string]: string } = {}

  @Input() set collapsed(value: any) {
    this._collapsed = coerceBooleanProperty(value)
  }
  get collapsed() {
    return this._collapsed
  }
  @HostBinding('class.collapsed') _collapsed = false

  @Output() collapseToggle = new EventEmitter<string>()

  setRowValuesWeight(value: number, rowValues: CompareMetricSetting[]) {
    if (value >= 0 && value <= 100) {
      rowValues[0].weight = value
    }
  }

  get displayLabel(): string {
    let length = this.values.length
    if (
      this.values.findIndex(value => {
        return hideMetric(value[0])
      }) !== -1
    ) {
      length--
    }
    const label = this.label !== NO_CATEGORY_NAME ? this.label : 'General'
    return this.collapsed ? `${label} (${length})` : label
  }

  get headerIcon() {
    return this.collapsed ? 'expand_more' : 'expand_less'
  }

  get hideMetric() {
    return hideMetric
  }

  constructor(
    private metricBuilderDialog: MetricBuilderDialogService,
    public dialog: MatDialog
  ) {}

  getFirstLabel(value: CompareMetricValue[]): string {
    return value && value[0] && value[0].label && value[0].year
      ? value[0].category === 'Misc'
        ? this.getMiscLabel(value[0])
        : this.getTailMetricsLabel(value[0].label)
      : value[0].label || ''
  }

  ngOnInit() {
    this.values.sort((a, b) => a[0].category_order - b[0].category_order) // order the records by category order (New column added to change the order of the metrics if needed)
  }

  getMiscLabel(metricVal: CompareMetricValue) {
    const [, , , aggregateMethod, perspective] = metricVal.label.split(' ')
    const updatedLabel = metricVal.label
      .replace(
        aggregateMethod,
        metricVal.aggregationMethodType === 'AEP' ? 'Aggregate' : 'Occurrence'
      )
      .replace(
        perspective,
        metricVal.perspective === 'LossRp'
          ? 'Loss+RP'
          : metricVal.perspective === 'UW'
          ? 'UW Result'
          : 'Loss'
      )
    return updatedLabel
  }
  getTailMetricsLabel(label: string) {
    return label.substring(label.indexOf(' ') + 1)
  }

  createCapitalArray(value: CompareMetricValue) {
    if (value.label === 'BCAR Capital Benefit') {
      return [
        {
          title: 'BCAR Premium risk factor',
          value: value.spPremiumValue ? value.spPremiumValue : 1,
        },
        {
          title: 'BCAR Reserve risk factor',
          value: value.spReserveValue ? value.spReserveValue : 1,
        },
        {
          title: 'BCAR Diversification factor',
          value: value.spDivesificationValue ? value.spDivesificationValue : 1,
        },
      ]
    } else {
      return [
        {
          title: 'S&P Premium risk factor',
          value: value.spPremiumValue ? value.spPremiumValue : 1,
        },
        {
          title: 'S&P Reserve risk factor',
          value: value.spReserveValue ? value.spReserveValue : 1,
        },
        {
          title: 'S&P Diversification factor',
          value: value.spDivesificationValue ? value.spDivesificationValue : 1,
        },
        {
          title: 'S&P Cat dilution factor',
          value: value.spCatValue ? value.spCatValue : 1,
        },
      ]
    }
  }

  onCollapseToggle($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    this.collapseToggle.emit(this.label)
  }

  updateMetric(
    event: MatCheckboxChange,
    weight: number,
    year: string,
    metricSetting: CompareMetricSetting
  ) {
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        show: event.checked,
        oldLabel: metricSetting.label,
        newLabel: '',
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show: event.checked,
      weight,
      year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      formula: metricSetting.formula,
    })
  }

  onWeightChange(
    show: boolean,
    weight: string,
    year: string,
    metricSetting: CompareMetricSetting
  ) {
    const newWeight =
      Number(weight) <= 100 && Number(weight) >= 0
        ? parseInt(weight, 10)
        : metricSetting.weight

    this.updateMetricElement.emit({
      show,
      weight: newWeight,
      year: year ? year.toString() : year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      formula: metricSetting.formula,
    })
  }

  onSceanrioSelected(
    credit_scenario: string,
    year: string | undefined,
    metricSetting: CompareMetricSetting
  ): void {
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: metricSetting.label,
        show: metricSetting.show,
        year,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
        credit_scenario: credit_scenario,
      })
    }

    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: parseInt(String(metricSetting.weight), 10),
      year: year?.toString() ?? metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      credit_scenario: credit_scenario,
    })
  }

  onYearChange(
    show: boolean,
    year: string,
    weight: string,
    metricSetting: CompareMetricSetting
  ) {
    const updatedLabel =
      metricSetting.category !== 'Misc'
        ? this.updateTailMetricsLabel(
            metricSetting.label,
            0,
            year.toString() + 'yr'
          )
        : metricSetting.label
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: updatedLabel,
        show: metricSetting.show,
        year,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show,
      weight: parseInt(weight, 10),
      year: year.toString(),
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: updatedLabel,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  onSpPremiumValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: value,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  onSpReserveValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: value,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  onSpDivesificationValueChange(
    value: number,
    metricSetting: CompareMetricSetting
  ) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: value,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  onSpCatValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: value,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  onCustomMetricDelete(metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      deleted: true,
    })
  }

  onCustomMetricEdit(metricSetting: CompareMetricSetting) {
    this.metricBuilderDialog
      .open(this.metricCategories, metricSetting)
      .afterClosed()
      .subscribe(newMetric => {
        if (newMetric) {
          this.updateMetricElement.emit({
            show: metricSetting.show,
            weight: metricSetting.weight,
            year: metricSetting.year,
            portfolioType: metricSetting.portfolioType,
            vartvar: metricSetting.vartvar,
            aggregationMethodType: metricSetting.aggregationMethodType,
            perspective: metricSetting.perspective,
            lossFilter: metricSetting.lossFilter,
            label: newMetric.label,
            metrics: metricSetting,
            ragOrder: newMetric.ragOrder,
            valueType: newMetric.valueType,
            formula: newMetric.formula,
          })
        }
      })
  }

  updateTailMetricsLabel(label: string, index: number, value: string) {
    return label.replace(label.split(' ')[index], value)
  }

  updateLossFilterLabel(label: string, value: string) {
    const description = this.lossFilters.find(
      f => f.name === value
    )?.description
    const newLabel = label.split(' ').slice(0, 6).join(' ')
    return `${newLabel} ${description}`
  }

  onUpdateMetricClick(
    data: MetricUpdateData,
    metricSetting: CompareMetricSetting
  ) {
    let updatedLabel =
      metricSetting.category !== 'Misc'
        ? metricSetting.label
            .replace(
              metricSetting.label.split(' ')[1],
              data.portfolioTypeChange
            )
            .replace(metricSetting.label.split(' ')[2], data.vartvarChange)
            .replace(
              metricSetting.label.split(' ')[3],
              data.aggregationMethodChange
            )
            .replace(metricSetting.label.split(' ')[4], data.perspectiveChange)
        : metricSetting.label
            .replace(
              metricSetting.label.split(' ')[2],
              data.portfolioTypeChange
            )
            .replace(
              metricSetting.label.split(' ')[3],
              data.aggregationMethodChange === 'AEP'
                ? 'Aggregate'
                : 'Occurrence'
            )

    if (metricSetting.category === 'Misc') {
      let previousPerspective
      if (metricSetting.perspective === 'UW') {
        previousPerspective =
          metricSetting.label.split(' ')[4] + metricSetting.label.split(' ')[5]
      } else {
        previousPerspective = metricSetting.label.split(' ')[4]
      }
      updatedLabel.replace(
        previousPerspective,
        data.perspectiveChange === 'LossRp'
          ? 'Loss+RP'
          : data.perspectiveChange === 'UW'
          ? 'UW Result'
          : 'Loss'
      )
    }

    const description = this.lossFilters.find(
      f => f.name === data.lossFilterChange
    )?.description
    updatedLabel = updatedLabel.split(' ').slice(0, 6).join(' ')
    updatedLabel = `${updatedLabel} ${description}`

    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: updatedLabel,
        show: metricSetting.show,
        portfolioType: data.portfolioTypeChange,
        vartvar: data.vartvarChange,
        aggregationMethodType: data.aggregationMethodChange,
        perspective: data.perspectiveChange,
        lossFilter: data.lossFilterChange,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: data.portfolioTypeChange,
      vartvar: data.vartvarChange,
      aggregationMethodType: data.aggregationMethodChange,
      perspective: data.perspectiveChange,
      lossFilter: data.lossFilterChange,
      label: updatedLabel,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
    })
  }

  private isGrossMetric(metricSetting: CompareMetricSetting) {
    return (
      metricSetting.grossMetricSettingID &&
      metricSetting.grossMetricSettingID !== 0
    )
  }

  onScenarioClicked(metricSetting: CompareMetricSetting) {
    const creditPortfolio = this._creditPortfolio
    // Temporary fix to uniquify Ids from gross loss
    const currentCededScenarios =
      this._creditPortfolio.gross_loss_scenarios.map(scenario => {
        return { ...scenario, _id: scenario._id.split('').reverse().join('') }
      })
    const currentScenario = metricSetting.credit_scenario
    const years = metricSetting.year
    new Promise<SelectedScenario>((resolve, reject) => {
      const dialogRef = this.dialog.open(ScenarioSelectorComponent, {
        disableClose: true,
        data: {
          creditPortfolio,
          currentCededScenarios,
          currentScenario,
          years,
          disabledCeded: true,
        },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          resolve(result)
        } else {
          reject()
        }
      })
    })
      .then(result => {
        if (result) {
          this.onSceanrioSelected(
            result.name,
            result.years ? result.years.toString() : undefined,
            metricSetting
          )
        }
      })
      .catch(() => {
        this.onSceanrioSelected('', undefined, metricSetting)
      })
  }
}
