import { BackendService } from './api/backend/backend.service'
import { ErrorHandler, Injectable } from '@angular/core'
import { throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService extends ErrorHandler {
  constructor(private backendService: BackendService) {
    super()
  }

  handleError(error: any) {
    if (
      sessionStorage.getItem('id_token') &&
      sessionStorage.getItem('username')
    ) {
      let message = '[' + sessionStorage.getItem('username') + '] '
      if (typeof error === 'string') {
        message += error
      } else if (error.stack) {
        message += error.stack
      }
      this.backendService
        .postEvent({
          eventType: 'Application Error',
          eventDetails: {
            message,
          },
        })
        .subscribe(err => throwError(err))
    }
    super.handleError(error)
  }
}
