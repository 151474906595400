import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { Program } from '../../../../core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-tabs-component',
  styleUrls: ['./compare-tabs.component.scss'],
  templateUrl: './compare-tabs.component.html',
})
export class CompareTabsComponent {
  @Input() tabSelected = 0
  @Input() compareCurrency: string
  @Input() precision: number
  @Output() programAdd = new EventEmitter<Program | Program[]>()
  @Output() programRemove = new EventEmitter<Program | Program[]>()
  @Output() tabIndex = new EventEmitter<number>()

  tabChange($event: MatTabChangeEvent): void {
    this.tabIndex.emit($event.index)
  }
}
