<h1 mat-dialog-title>Update the Carrier for a Salesforce Account</h1>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="app-table app-table-small">
    <ng-container matColumnDef="Account Name">
      <th mat-header-cell *matHeaderCellDef>Salesforce Account Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sfAccountName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Carrier Name">
      <th mat-header-cell *matHeaderCellDef>Carrier Name</th>
      <td mat-cell *matCellDef="let element">{{ element.carrierName }}</td>
    </ng-container>
    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          appButton
          border
          center
          accent
          (click)="onMappingActionClick(element)"
        >
          Update Carrier
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button class ="cancelButton" appButton link [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
