import {
  QuoteChartAxis,
  QuoteChartDatum,
  QuoteChartGridOption,
  QuoteChartGroupedDataBySplit,
  QuoteChartInfo,
} from '../model/quote-charts.model'

/* Remove 'Lloyd's Syndicate' from chart labels */
export const removeLloydsFromLabels = (labels: string[]) => {
  return labels.map(l => l.replace(`Lloyd's Syndicate `, ''))
}

/* Convert metric into a percent value */
export const convertToPercent = (decimal: number): number => {
  return parseFloat((decimal * 100.0).toFixed(2))
}

/* Round metric value down */
export const convertToInt = (decimal: number): number => {
  return Math.floor(decimal)
}

/* Parse data passed with groupBy split values */
export const parseDataWithSplit = (data: QuoteChartDatum[]) => {
  const distinctGroupBys = new Set<string>()
  const distinctSplits = new Set<string>()
  const result: { [groupBy: string]: { [split: string]: QuoteChartDatum } } = {}
  for (const datum of data) {
    if (datum.groupBy) {
      distinctGroupBys.add(datum.groupBy)
    }
    const splitValue = datum.split ?? 'N/A'
    distinctSplits.add(splitValue)
  }
  const distinctSplitsInOrder = Array.from(distinctSplits).sort()
  distinctGroupBys.forEach(groupValue => {
    result[groupValue] = {}
    distinctSplitsInOrder.forEach(splitValue => {
      result[groupValue][splitValue] = {
        groupBy: groupValue,
        metricPrimary: 0,
        split: splitValue,
      }
    })
    const splits = new Set<string>()
    for (const datum of data) {
      if (datum.groupBy === groupValue) {
        const splitValue = datum.split ?? 'N/A'
        splits.add(splitValue)
      }
    }
    splits.forEach(splitValue => {
      const splitChunk: QuoteChartDatum = {
        groupBy: groupValue,
        metricPrimary: 0,
        split: splitValue,
      }
      const dataChunk: QuoteChartDatum[] = []
      for (const datum of data) {
        if (
          datum.groupBy === groupValue &&
          (datum.split ?? 'N/A') === splitValue
        ) {
          dataChunk.push(datum)
        }
      }
      splitChunk.metricPrimary = dataChunk.reduce(
        (sum, datum) => sum + datum.metricPrimary,
        0
      )
      splitChunk.size = dataChunk.reduce(
        (sum, datum) => (datum.size ? sum + datum.size : sum),
        0
      )
      splitChunk.metricSecondary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricSecondary ? sum + datum.metricSecondary : sum,
        0
      )
      splitChunk.metricTertiary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricTertiary ? sum + datum.metricTertiary : sum,
        0
      )
      splitChunk.metricQuaternary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricQuaternary ? sum + datum.metricQuaternary : sum,
        0
      )
      result[groupValue][splitValue] = splitChunk
    })
  })
  const mappedResult: QuoteChartGroupedDataBySplit[] = []
  for (const groupBy in result) {
    if (!result[groupBy]) {
      continue
    }
    const groupData = result[groupBy]
    const metricPrimaryValues: number[] = []
    for (const [_, splitChunk] of Object.entries(groupData)) {
      metricPrimaryValues.push(splitChunk.metricPrimary)
    }
    const metricTotal = metricPrimaryValues.reduce(
      (sum, count) => sum + count,
      0
    )
    mappedResult.push({ groupBy, data: metricPrimaryValues, metricTotal })
  }
  mappedResult.sort((a, b) => b.metricTotal - a.metricTotal)
  return {
    groupedData: mappedResult,
    splits: distinctSplitsInOrder,
  }
}

export const getChartKvps = (chart: QuoteChartGridOption) => {
  const kvps: Record<string, string> = {}
  kvps.groupBy = chart.groupBy.columnName
  kvps.metricPrimary = chart.metric.columnName
  if (chart.size) {
    kvps.size = chart.size.columnName
  }
  if (chart.split) {
    kvps.split = chart.split.columnName
  }
  return kvps
}

export const getChartSplitAndSize = (
  chartInfo: QuoteChartInfo,
  groupBy: QuoteChartAxis
): {
  split: QuoteChartAxis | null
  size: QuoteChartAxis | null
} => {
  const applicableSplitFound = chartInfo.applicableSplits.find(
    split => split.columnName !== groupBy.columnName
  )
  return {
    size:
      chartInfo.applicableSizes.length > 0
        ? chartInfo.applicableSizes[0]
        : null,
    split:
      chartInfo.applicableSplits.length > 0 && applicableSplitFound
        ? applicableSplitFound
        : null,
  }
}
