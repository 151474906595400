import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { SectionState } from '../store/section/section.reducer'
import {
  LossSetLayer,
  Reinstatement,
} from 'src/app/api/analyzere/analyzere.model'
import {
  CASUALTY_PALETTE_IDS,
  layerIds,
  PROPERTY_PALETTE_IDS,
} from 'src/app/analysis/model/layer-palette.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import {
  AssignedLines,
  MultiSectionMapping,
  MultiTotalExpectedForReinsurerVersion,
  QuoteExport,
  QuoteExportAssignedLines,
  QuoteReinsurer,
  ReinsurerPhases,
  Section,
  SubjectivityTracking,
  TotalExpectedForReinsurerVersion,
} from '../models/quote.model'
import getQuotePanelDefs, {
  QuotePanelDefResolved,
  SubjectivityTrackingColumnDef,
} from '../quote-panel/quote-panel-defs'
import { LayerView, LayerViewValues } from 'src/app/analysis/model/layer-view'
import { groupBy, path, prop, sum } from 'ramda'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { Injectable } from '@angular/core'
import {
  fotSummaryRowIds,
  QuoteExportModes,
  QuoteExportRowValue,
  QuoteVersionListEntry,
} from './quote-excel.model'
import { FullDateToYear } from '@shared/pipes/full-date-to-year'
import layerMetricDefs from 'src/app/analysis/model/layer-metric-defs'
import { QuoteExcelUtils } from './quote-excel.utils'
import { QuoteExportAssignedLinesConverter } from './quote-export-assigned-lines.converter'
import QuoteExportRowsConverter from './quote-export-rows.converter'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'

@Injectable({
  providedIn: 'root',
})
export class QuoteExportConverter {
  private sectionList: SectionState[]
  private layerState: LayerState[]
  private lossSetLayers: LossSetLayer[]
  private reinsurerList: ReinsurerState[]
  private reinsurerListFormatted: ReinsurerState[]
  private quotePanelDefs: QuotePanelDefResolved[]
  private expiringOpportunity: AccountOpportunity | undefined
  private currentOpportunity: AccountOpportunity | undefined
  private isSLSelected: boolean
  private isGroupSelected: boolean
  private view: LayerView | undefined
  private allViews: LayerView[] | undefined
  private rowsTracking: SubjectivityTracking[]
  private xolExport: boolean

  constructor(
    private utils: QuoteExcelUtils,
    private alConverter: QuoteExportAssignedLinesConverter
  ) {}

  export(
    sectionList: SectionState[],
    layerState: LayerState[],
    view: LayerView | undefined,
    lossSetLayers: LossSetLayer[],
    reinsurerList: ReinsurerState[],
    reinsurerListFormatted: ReinsurerState[],
    quotePanelDefs: QuotePanelDefResolved[],
    expiringOpportunity: AccountOpportunity | undefined,
    currentOpportunity: AccountOpportunity | undefined,
    isSLSelected: boolean,
    isGroupSelected: boolean,
    rowsTracking: SubjectivityTracking[],
    allViews: LayerView[] | undefined,
    subjectivityTrackingColumnDef: SubjectivityTrackingColumnDef[]
  ): QuoteExport {
    // Set export data members
    this.sectionList = sectionList
    this.layerState = layerState
    this.view = view
    this.lossSetLayers = lossSetLayers
    this.reinsurerList = reinsurerList
    this.reinsurerListFormatted = reinsurerListFormatted
    this.quotePanelDefs = quotePanelDefs
    this.expiringOpportunity = expiringOpportunity
    this.currentOpportunity = currentOpportunity
    this.isSLSelected = isSLSelected
    this.isGroupSelected = isGroupSelected
    this.rowsTracking = rowsTracking
    this.allViews = allViews

    this.xolExport = this.layerState.every(
      ({ layer }) =>
        layer.meta_data.sage_layer_type === layerIds.catXl ||
        layer.meta_data.sage_layer_type === layerIds.noncatXl
    )

    const members: number[] = this.calculateLossSetsMembers()
    /* Set export sections by layer */
    let filteredSections: SectionState[] = []
    if (!this.isSLSelected && !this.isGroupSelected) {
      this.sectionList.map(layer => {
        if (layer.section.layerType === layerIds.catMultisection) {
          this.layerState.map(state => {
            if (
              state.layer.id === layer.section.layerRef &&
              state.layer.meta_data.sage_layer_subtype !== 'visible-layer'
            ) {
              filteredSections.push(layer)
            }
          })
        } else {
          if (layer.section.layerName.trim() !== '') {
            filteredSections.push(layer)
          }
        }
      })
    } else if (this.isGroupSelected) {
      this.reinsurerListFormatted.map(x =>
        this.sectionList.map(ls => {
          if (
            ls.section.layerRef === x.reinsurer.cededlayerID &&
            filteredSections.indexOf(ls) === -1
          ) {
            filteredSections.push(ls)
          }
        })
      )
    } else {
      filteredSections = this.sectionList
    }
    const headers: string[] = this.getExportSectionHeaders(filteredSections) // Names of each layer section i.e. ['Layer 1', 'Layer 2', 'Layer 3']
    const multiSectionMappings = this.getMultiSectionMappings(filteredSections) // Gets all multi section layer ids and main layer ids
    const sharedLimitIds = this.getSharedLimitLayerIds(filteredSections) // Gets all shared limit layer ids
    const expiringOppYear = this.expiringOpportunity?.opportunityInceptionDate
    const currentOppYear = this.currentOpportunity?.opportunityInceptionDate

    /* Split reinsurers by their phase - FOT, Expiring, or neither */
    const reinsurerListForExport = this.getReinsurerListSorted([
      ...this.reinsurerList,
    ])
    const expiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.Expiring
    )
    const nonFotAndExpiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport
    )
    const fotReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.FOT
    )

    /* Gets the total expected premium & loss values for each reinsurer version of a multi section layer, group and shared limit */
    const multiExpectedVals = this.getMultiSectionTotalExpectedValues(
      nonFotAndExpiringReinsurers,
      multiSectionMappings
    )
    const groupExpectedVals = this.getGroupTotalExpectedValues(
      nonFotAndExpiringReinsurers
    )
    const sharedExpectedVals = this.getSharedLimitExpectedValues(
      nonFotAndExpiringReinsurers,
      sharedLimitIds
    )

    /* Iterate through each filtered section and add export columns/rows for each reinsurer (by phase - FOT, Expiring, etc.) */
    const descriptionColumn: string[] = ['Reinsurer']
    const descriptionRows: string[][][] = []
    const finalExpiringColumns: string[][] = []
    const finalExpiringRows: QuoteExportRowValue[][][][] = []
    const finalNonFotAndExpiringColumns: string[][] = []
    const finalNonFotAndExpiringRows: QuoteExportRowValue[][][][] = []
    const finalFotColumns: string[][] = []
    const finalFotRows: QuoteExportRowValue[][][][] = []
    const quotesSignedPercRow: number[][] = []
    /* Iterate through each filtered section and add assigned lines columns/rows  */
    const assignedLinesData: QuoteExportAssignedLines[][][] = []
    const assignedLinesColumns: string[] = !this.xolExport
      ? [' ', ' ', ' ', ' ']
      : [' '] // Assigned lines columns should start after reinsurer and tpref values in tab
    const assignedLinesRe: string[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesRows: QuoteExportRowValue[][][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    const xolSubjectPremiumRows: QuoteExportRowValue[][][] =
      this.getXOLSubjectPremiumRowsForStructure(reinsurerListForExport)

    // reinsurers need to be displayed in a logical order, handling missing versions/reinsurers between layers
    const nonExpiringOrFotReinsurersBySection: ReinsurerState[][] =
      filteredSections.map(({ section }) =>
        nonFotAndExpiringReinsurers.filter(({ reinsurer }) =>
          this.utils.validateReinsurer(reinsurer, section.layerRef)
        )
      )

    // The column order for non fot or expiring versions by tpRef and version
    const overallNonExpiringOrFotReinsurerVersionList: QuoteVersionListEntry[] =
      []

    nonExpiringOrFotReinsurersBySection.forEach((reinsurers, i) => {
      // The first section should always be the base order, any other reinsurers will get added after
      if (i === 0) {
        const sectionEntries = reinsurers.map(({ reinsurer }) => ({
          tpRef: reinsurer.tpRef,
          reinsurerPhaseVersion: Number(reinsurer.reinsurerPhaseVersion),
          quoteReinsurerName: reinsurer.quoteReinsurerName,
        }))
        overallNonExpiringOrFotReinsurerVersionList.push(...sectionEntries)
      } else {
        // Find all reinsurers and versions that don't exist in the order already
        const newReinsurersOrVersions = reinsurers.filter(
          re =>
            !overallNonExpiringOrFotReinsurerVersionList.some(
              version =>
                version.tpRef === re.reinsurer.tpRef &&
                version.reinsurerPhaseVersion ===
                  Number(re.reinsurer.reinsurerPhaseVersion)
            )
        )
        newReinsurersOrVersions.forEach(({ reinsurer }) => {
          const newItem = {
            tpRef: reinsurer.tpRef,
            reinsurerPhaseVersion: Number(reinsurer.reinsurerPhaseVersion),
            quoteReinsurerName: reinsurer.quoteReinsurerName,
          }
          if (
            overallNonExpiringOrFotReinsurerVersionList.some(
              val => val.tpRef === reinsurer.tpRef
            )
          ) {
            // Version of an existing reinsurer that hasn't existed, add after last version in existing list
            const lastVersionForTpRef = [
              ...overallNonExpiringOrFotReinsurerVersionList,
            ]
              .reverse()
              .find(val => val.tpRef === reinsurer.tpRef)
            const lastIndex =
              overallNonExpiringOrFotReinsurerVersionList.indexOf(
                lastVersionForTpRef
              )
            overallNonExpiringOrFotReinsurerVersionList.splice(
              lastIndex + 1,
              0,
              newItem
            )
          } else {
            // New reinsurer, append to end of order
            overallNonExpiringOrFotReinsurerVersionList.push(newItem)
          }
        })
      }
    })

    filteredSections.forEach((s, n) => {
      const exportQuotePanelDefs = this.getQuotePanelDefsForSection(s) // gets all the selected terms for this layer's quote panels
      let sectionRows = this.getSectionRows(s.section, exportQuotePanelDefs)

      // Add total expected ceded loss/premium
      if (!this.xolExport) {
        Object.values(exportQuotePanelDefs).forEach(element => {
          if (
            element.id === 'totalQuoteExpectedCededLoss' ||
            element.id === 'totalQuoteExpectedCededPremium'
          ) {
            sectionRows = [...sectionRows, element]
          }
        })
      }
      const descRows = [...this.getDescriptionRowsForSection(sectionRows)]
      descriptionRows.push(descRows) // gets all the export metrics for this section (i.e. Subject Premium, etc.)

      /* Get non fot or expiring rows & columns for this section and append to arrays */
      const exportRowConverter = new QuoteExportRowsConverter(
        this.isGroupSelected,
        this.isSLSelected,
        this.utils,
        this.view,
        this.xolExport
      )
      const [nonFotOrExpiringRows, nonFotOrExpiringColumns] =
        exportRowConverter.getNonFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          nonFotAndExpiringReinsurers,
          multiExpectedVals,
          groupExpectedVals,
          sharedExpectedVals,
          overallNonExpiringOrFotReinsurerVersionList,
          nonExpiringOrFotReinsurersBySection[n]
        )
      if (nonFotOrExpiringRows.length > 0) {
        finalNonFotAndExpiringColumns.push(nonFotOrExpiringColumns)
        finalNonFotAndExpiringRows.push(nonFotOrExpiringRows)
      }

      /* Get expiring rows & columns for this section and append to finalExpiring arrays */
      const [expiringRows, expiringColumns] =
        this.getFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          expiringReinsurers,
          multiExpectedVals,
          groupExpectedVals,
          sharedExpectedVals
        )
      if (expiringRows.length > 0) {
        finalExpiringColumns.push(expiringColumns)
        finalExpiringRows.push(expiringRows)
      }
      if (finalNonFotAndExpiringRows.length > finalExpiringRows.length) {
        /* Need to add empty value to match section row/column sizes */
        finalExpiringColumns.push([])
        finalExpiringRows.push([])
      }

      /* Get FOT rows & columns for this section and append to finalFot arrays */
      const [fotRows, fotColumns] =
        this.getFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          fotReinsurers
        )
      if (fotRows.length > 0) {
        finalFotColumns.push(fotColumns)
        finalFotRows.push(fotRows)
      }
      if (finalNonFotAndExpiringRows.length > finalFotRows.length) {
        /* Need to add empty value to match section row/column sizes */
        finalFotColumns.push([])
        finalFotRows.push([])
      }

      // Get latest FOTs for assigned lines for export
      const latestFOTTemp = [...fotReinsurers]
      latestFOTTemp.reverse()
      const latestFOTs: ReinsurerState[] = []
      latestFOTTemp.forEach((lf, index) => {
        if (index === 0 || (lf && !latestFOTs.includes(lf))) {
          latestFOTs.push(lf)
        }
      })

      /* Update assigned lines data of export for this section */
      const [sectionAlFOTData, sectionAlRe, sectionAlTpRef, sectionAlSubRows] =
        this.getAssignedLinesColumnsAndRowsForSection(s, latestFOTs.reverse())

      const [
        sectionAlExpiringData,
        sectionAlReExpiring,
        sectionAlTpRefExpiring,
      ] = this.getAssignedLinesColumnsAndRowsForSection(s, expiringReinsurers)
      const fotExpiringLayerSet = [
        ...sectionAlExpiringData,
        ...sectionAlFOTData,
      ]
      const percRow = this.getReducedExpiringForSection(s, expiringReinsurers)
      quotesSignedPercRow.push(percRow)
      assignedLinesData.push(fotExpiringLayerSet)

      // We might need to fix this logic to allow same reinsurer multiple times
      if (n === 0) {
        assignedLinesRe.push(...sectionAlRe)
        assignedLinesTpRef.push(...sectionAlTpRef)
      }
      const deDupSectionRe = [...sectionAlRe, ...sectionAlReExpiring]
      deDupSectionRe.forEach(sectionRe => {
        const [, reinsurerName] = sectionRe
        if (!assignedLinesRe.some(val2 => val2[1] === reinsurerName)) {
          assignedLinesRe.push(sectionRe)
        }
      })
      const deDuptpRef = [...sectionAlTpRef, ...sectionAlTpRefExpiring]
      deDuptpRef.forEach(tpRef => {
        const [agencyTPRef, agencySeqNumber] = tpRef
        if (
          !assignedLinesTpRef.some(
            val2 => val2[0] === agencyTPRef && val2[1] === agencySeqNumber
          )
        ) {
          assignedLinesTpRef.push(tpRef)
        }
      })

      assignedLinesSubRows.push(...sectionAlSubRows)
      /* Get final assigned columns */
      // ntd combine expiring and current fots
      if (this.utils.findLongestArrayLength(fotExpiringLayerSet) > 0) {
        assignedLinesColumns.push(
          ...this.alConverter.getAssignedLineColumns(
            !this.xolExport,
            fotExpiringLayerSet,
            expiringOppYear,
            currentOppYear,
            s,
            expiringReinsurers
          )
        )
      }
    })
    /* Get final assigned lines rows */
    // ! Sort breaks tpRef order, so removed
    assignedLinesData.forEach(ald => {
      if (ald.length > 0) {
        ald.forEach((al: any) => {
          if (al.length > 1) {
            al.sort((a: any, b: any) =>
              a.reinsurer > b.reinsurer ? 1 : b.reinsurer > a.reinsurer ? -1 : 0
            )
          }
        })
      }
    })

    if (assignedLinesColumns.length) {
      assignedLinesRows.push(
        ...this.alConverter.getAssignedLineRows(
          assignedLinesRe,
          reinsurerListForExport,
          expiringReinsurers,
          fotReinsurers,
          nonFotAndExpiringReinsurers,
          !this.xolExport,
          assignedLinesTpRef,
          assignedLinesData,
          assignedLinesColumns,
          filteredSections
        )
      )
    }

    const fotSummaryRows: QuoteExportRowValue[][][] = this.getFotSummaryRows(
      expiringReinsurers,
      fotReinsurers,
      currentOppYear,
      filteredSections
    )

    const expiringReinsurerMappings =
      this.getMultipleVersionMappingsForReinsurers(
        expiringReinsurers,
        nonFotAndExpiringReinsurers
      )

    const layerPaletteMappings = this.layerState.map(({ layer }) => {
      const layerIdLayerType = layer.meta_data.sage_layer_type as layerIds
      if (PROPERTY_PALETTE_IDS.includes(layerIdLayerType)) {
        return 3
      } else if (CASUALTY_PALETTE_IDS.includes(layerIdLayerType)) {
        return 4
      }
    })

    return {
      descriptionColumn,
      descriptionRows,
      headers,
      expiringReinsurerRows: finalExpiringRows,
      expiringReinsurerColumns: finalExpiringColumns,
      nonFotAndExpiringReinsurerRows: finalNonFotAndExpiringRows,
      nonFotAndExpiringReinsurerColumns: finalNonFotAndExpiringColumns,
      fotReinsurerRows: finalFotRows,
      fotReinsurerColumns: finalFotColumns,
      trackingRows: this.rowsTracking,
      trackingColumns: subjectivityTrackingColumnDef,
      assignedLinesRows,
      assignedLinesColumns,
      assignedLinesSubRows,
      assignedLinesTpRef,
      quotesSignedPercRow,
      members,
      xolSubjectPremiumRows,
      exportMode: this.xolExport
        ? QuoteExportModes.XOL
        : QuoteExportModes.DEFAULT,
      fotSummaryRows,
      expiringReinsurerMappings,
      layerPaletteMappings,
    }
  }

  private getExportSectionHeaders(sections: SectionState[]): string[] {
    const headers: string[] = []
    sections.forEach(s => {
      if (this.isGroupSelected || this.isSLSelected) {
        headers.push(s.section.layerName)
      } else {
        if (s.section.layerType === layerIds.catMultisection) {
          headers.push(...this.getSectionHeaderIfMultisection(s))
        } else {
          this.layerState.map(layer => {
            if (s.section.layerRef === layer.layer.id) {
              headers.push(
                layer.layer.meta_data.layerName ??
                  layer.layer.physicalLayer.description
              )
            }
          })
        }
      }
    })
    return headers
  }

  private calculateLossSetsMembers(): number[] {
    const layersMembers: number[] = []
    const layersFromSections = this.sectionList.map((l, idx) => {
      if (this.layerState.some(v => v.layer.id === l.section.layerRef)) {
        return this.layerState[idx]
      }
    })
    layersFromSections.forEach(l => {
      const layerMember = this.lossSetLayers
        .filter(ls => l?.layer.lossSetLayers?.some(_ls => _ls.id === ls.id))
        .reduce(
          (acc, { meta_data }) =>
            meta_data && meta_data.members ? acc + meta_data.members : acc,
          0
        )
      layersMembers.push(layerMember)
    })
    return layersMembers
  }

  private getMultiSectionMappings(
    sections: SectionState[]
  ): MultiSectionMapping[] {
    const mappings: MultiSectionMapping[] = []
    sections.forEach(s => {
      // Check if multisection
      if (s.section.layerType === layerIds.catMultisection) {
        // For each ms layer, iterate through all section layers
        this.layerState.map(data => {
          if (
            data.layer.id === s.section.layerRef &&
            isMultiSectionLayer(data.layer, 'section-layer')
          ) {
            this.layerState
              .filter(state =>
                state.layer.layerRefs.includes(s.section.layerRef)
              )
              .map(mainLayer => {
                if (isMultiSectionLayer(mainLayer.layer, 'main-layer')) {
                  mappings.push({
                    mainLayerId: mainLayer.layer.id,
                    mainLayerName:
                      mainLayer.layer.meta_data.layerName ??
                      `Main Layer ${mainLayer.layer.id}`,
                    sectionLayerId: data.layer.id,
                    sectionLayerName:
                      data.layer.physicalLayer.description ?? '',
                  })
                }
              })
          }
        })
      }
    })
    return mappings
  }

  private getMultiSectionTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiSections: MultiSectionMapping[]
  ): MultiTotalExpectedForReinsurerVersion[] {
    const sectionAVals = multiSections.filter(multi =>
      multi.sectionLayerName.includes('Section A')
    )
    const multiExpectedVals: MultiTotalExpectedForReinsurerVersion[] = []
    sectionAVals.forEach(aVal => {
      const reFound = nonFotAndExpiringReinsurers.filter(
        r => r.reinsurer.cededlayerID === aVal.sectionLayerId
      )
      reFound.forEach(reinsurer => {
        multiExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
          sections: multiSections.filter(
            multi => multi.mainLayerId === aVal.mainLayerId
          ) /* get all sections that have the same mainLayerId */,
        })
      })
    })
    return multiExpectedVals
  }

  getSharedLimitLayerIds(sections: SectionState[]): string[] {
    return sections
      .map(s =>
        s.section.layerType === 'shared_limits' ? s.section.layerRef : undefined
      )
      .filter(id => id !== undefined) as string[]
  }

  private getReinsurerListSorted(
    reinsurerList: ReinsurerState[]
  ): ReinsurerState[] {
    return reinsurerList.sort((a, b) => {
      if (a.reinsurer.quoteReinsurerName && b.reinsurer.quoteReinsurerName) {
        if (a.reinsurer.quoteReinsurerName < b.reinsurer.quoteReinsurerName) {
          return -1
        }
        if (a.reinsurer.quoteReinsurerName > b.reinsurer.quoteReinsurerName) {
          return 1
        }
      }
      return 0
    })
  }

  private getQuotePanelDefsForSection(
    s: SectionState
  ): QuotePanelDefResolved[] {
    let exportQuotePanelDefs = this.quotePanelDefs
    if (this.allViews) {
      const view = this.allViews.find(
        ({ layer }) => layer.id === s.section.layerRef
      )
      if (!view) {
        return []
      }
      exportQuotePanelDefs = getQuotePanelDefs(
        view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
    }
    return exportQuotePanelDefs
  }

  private getSectionRows(
    s: Section,
    defs: QuotePanelDefResolved[]
  ): QuotePanelDefResolved[] {
    const arr: QuotePanelDefResolved[] = []
    Object.values(defs).forEach(element => {
      for (const [key, value] of Object.entries(s)) {
        if (element.exportID === key && value === true) {
          arr.push(element)
        }
      }
    })
    return arr
  }

  private getDescriptionRowsForSection(
    sectionRows: QuotePanelDefResolved[]
  ): string[][] {
    const head: string[][] = []

    sectionRows.forEach(row => {
      if (row.label === 'Sliding Scale Commission') {
        head.push([row.label, 'text'])
        head.push(['Min', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Provisional', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Max', 'text'])
      } else {
        head.push([row.label, 'text'])
      }
    })

    return head
  }

  private getReinsurersByType(
    reinsurerList: ReinsurerState[],
    phase?: ReinsurerPhases
  ): ReinsurerState[] {
    switch (phase) {
      case ReinsurerPhases.Expiring:
      case ReinsurerPhases.FOT:
        return reinsurerList.filter(
          ({ reinsurer }) => reinsurer.reinsurerPhase === phase
        )
      default:
        return reinsurerList.filter(
          ({ reinsurer }) =>
            reinsurer.reinsurerPhase !== ReinsurerPhases.FOT &&
            reinsurer.reinsurerPhase !== ReinsurerPhases.Expiring
        )
    }
  }

  private getSubjectPremiumForStructure(
    reinsurerListForExport: ReinsurerState[]
  ): number {
    // Subject premium should come from any quote panel, creating fallbacks still
    // Per Lockton, all panels should have the same subject premium or there was an input mistake
    const nonFotAndExpiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport
    )
    if (nonFotAndExpiringReinsurers.length) {
      return (
        nonFotAndExpiringReinsurers[0].reinsurer?.quoteFields.subjectPremium
          .value ?? 0
      )
    }
    const fotReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.FOT
    )
    if (fotReinsurers.length) {
      return fotReinsurers[0].reinsurer?.quoteFields.subjectPremium.value ?? 0
    }
    const expiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.Expiring
    )
    if (expiringReinsurers.length) {
      return (
        expiringReinsurers[0].reinsurer?.quoteFields.subjectPremium.value ?? 0
      )
    }
  }

  private getXOLSubjectPremiumRowsForStructure(
    reinsurerListForExport: ReinsurerState[]
  ): QuoteExportRowValue[][][] {
    // Define the static header values
    const subjectPremium = this.getSubjectPremiumForStructure(
      reinsurerListForExport
    )
    const subjectPremiumRows: QuoteExportRowValue[][][] = [
      [
        ['Subject Premium', 'text'],
        [
          this.utils.convertCurrencyToSymbol(
            !!subjectPremium ? subjectPremium : 0,
            this.view.currency
          ),
        ],
      ],
      [[''], ['Occurrence Limit', 'text'], ['Occurrence Attachment', 'text']],
    ]
    this.allViews.forEach(view => {
      const layer = view.layer
      subjectPremiumRows.push([
        [
          layer.meta_data.layerName ?? layer.physicalLayer.description ?? '',
          'text',
        ],
        [
          this.utils.convertCurrencyToSymbol(
            view.occurrenceLimit,
            view.currency
          ),
          'currency',
        ],
        [
          this.utils.convertCurrencyToSymbol(
            view.occurrenceAttachment,
            view.currency
          ),
          'currency',
        ],
      ])
    })
    return subjectPremiumRows
  }

  private getFotOrExpiringColumnsAndRowsForSection(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    fotOrExpiringReinsurers: ReinsurerState[],
    multiExpectedVals?: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals?: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals?: TotalExpectedForReinsurerVersion[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    fotOrExpiringReinsurers.forEach(r => {
      const reRow: QuoteExportRowValue[][] = []
      /* For total expected ceded loss / premium */
      let totalQuoteExpectedCededLoss = 0
      let totalQuoteExpectedCededPremium = 0
      const totalExpectedMultiVals = multiExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedGroupVals = groupExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedSharedVals = sharedExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      if (this.utils.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        reinsurerColumns.push(this.utils.labelReinsurer(r.reinsurer))
        if (sectionRows.length > 0 && r.reinsurer.quoteFields) {
          fotOrExpiringReinsurers.forEach(x => {
            if (
              !this.utils.validateReinsurer(x.reinsurer, s.section.layerRef)
            ) {
              return
            }
            if (
              r.reinsurer.quoteReinsurerName ===
                x.reinsurer.quoteReinsurerName &&
              r.reinsurer.reinsurerPhaseLabel ===
                x.reinsurer.reinsurerPhaseLabel
            ) {
              if (
                s.section.layerType === layerIds.catMultisection ||
                this.isSLSelected
              ) {
                return
              } else {
                if (
                  r.reinsurer.quoteFields &&
                  x.reinsurer.quoteFields?.quoteExpectedCededLoss &&
                  x.reinsurer.quoteFields.quoteExpectedCededPremium
                ) {
                  totalQuoteExpectedCededLoss +=
                    x.reinsurer.quoteFields.quoteExpectedCededLoss.value
                  totalQuoteExpectedCededPremium +=
                    x.reinsurer.quoteFields.quoteExpectedCededPremium.value
                }
              }
            }
          })
          let checkSlidingScale = false
          sectionRows.forEach((sec, ind) => {
            const layerSectionObj = JSON.parse(JSON.stringify(s))
            for (const [key, value] of Object.entries(
              r.reinsurer.quoteFields as any
            )) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                let layerValue = JSON.parse(JSON.stringify(value))

                if (sec.id === 'layerClass') {
                  const subclass = r.reinsurer.quoteFields.layerSubClass
                  if (subclass) {
                    layerValue += `/${subclass}`
                  }
                }

                this.view?.layers?.map(layer => {
                  if (
                    r.reinsurer.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.reinsurer.cededlayerID === layer.layer.id) {
                    layerSectionObj.section.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.utils.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.section.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }
            if (sec.id === 'slidingComm' && r.reinsurer.slidingScale) {
              checkSlidingScale = true
              reRow.push(['', 'text'])
              const min =
                r.reinsurer.slidingScale[0].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[0].lossRatio * 100 +
                '%'
              reRow.push([min, 'text'])
              const slide1 =
                (r.reinsurer.slidingScale[0].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide1, 'text'])
              const prov =
                r.reinsurer.slidingScale[1].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[1].lossRatio * 100 +
                '%'
              reRow.push([prov, 'text'])
              const slide2 =
                (r.reinsurer.slidingScale[2].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide2, 'text'])
              const max =
                r.reinsurer.slidingScale[2].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[2].lossRatio * 100 +
                '%'
              reRow.push([max, 'text'])
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
            }
          })
        }
        reinsurerRows.push(reRow)
      }
    })
    return [reinsurerRows, reinsurerColumns] as const
  }

  private getReducedExpiringForSection(
    s: SectionState,
    fotReinsurers: ReinsurerState[]
  ): number[] {
    const signPerc: number[] = []
    fotReinsurers.forEach(r => {
      if (this.utils.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        if (r.reinsurer.riskAssignedLinesLink) {
          const signedPercTotal = r.reinsurer.riskAssignedLinesLink.reduce(
            (tot, curr) => (curr.signed ? tot + curr.signed : tot),
            0
          )
          signPerc.push(signedPercTotal)
        }
      }
    })
    return signPerc
  }

  private getAssignedLinesColumnsAndRowsForSection(
    s: SectionState,
    fotReinsurers: ReinsurerState[]
  ): readonly [
    AssignedLines[][],
    string[][],
    QuoteExportRowValue[][],
    QuoteExportRowValue[][],
  ] {
    const assignedLinesData: AssignedLines[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    let reinsurerSections: AssignedLines[] = []

    fotReinsurers.forEach(r => {
      if (r && this.utils.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        if (r.reinsurer.riskAssignedLinesLink) {
          let limit = 0
          if (
            s.section.layerType === layerIds.catAg ||
            s.section.layerType === layerIds.noncatAg ||
            s.section.layerType === layerIds.ahlAg
          ) {
            limit = r.reinsurer.quoteFields?.quoteAggregateLimit?.value || 0
          } else if (s.section.layerType === layerIds.noncatRisk) {
            limit = r.reinsurer.quoteFields?.quoteRiskLimit?.value || 0
          } else {
            limit = r.reinsurer.quoteFields?.quoteOccurrenceLimit?.value || 0
          }
          const aLinks = r.reinsurer.riskAssignedLinesLink.map(e => {
            return {
              ...e,
              limit,
              layer: s.section.layerName,
            }
          })

          reinsurerSections = [...reinsurerSections, ...aLinks]
          r.reinsurer.riskAssignedLinesLink.forEach(a => {
            const subjectivityALRow: any[] = []
            assignedLinesTpRef.push([
              a.marketTpRef || '0',
              a.relationSeqNumber || 0,
            ])

            if (a.subjectivity || a.contract) {
              subjectivityALRow.push(
                a.reinsurer,
                s.section.layerName,
                a.contract,
                a.subjectivity,
                ''
              )
              assignedLinesSubRows.push(subjectivityALRow)
            }
          })
        }
      }
    })
    const layerSections = reinsurerSections.map(re => ({
      id: re.id,
      brokerage: re.brokerage,
      brokerageRe: re.brokerageRe,
      recommended: re.recommended,
      signedPercentage: re.signed,
      signedCurrency: re.signed && re.limit ? re.signed * re.limit : 0,
      writtenPercentage: re.written,
      writtenCurrency: re.written && re.limit ? re.written * re.limit : 0,
      placedThrough: re.placedThrough,
      coBroker: re.coBroker,
      leadMarket: re.leadMarket,
      reinsurer: re.reinsurer,
      tpRef: re.marketTpRef,
    }))

    const assignedLinesRe = reinsurerSections.map(re => [re.id, re.reinsurer])
    assignedLinesData.push(layerSections)

    return [
      assignedLinesData,
      assignedLinesRe,
      assignedLinesTpRef,
      assignedLinesSubRows,
    ] as const
  }

  private getGroupTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[]
  ): TotalExpectedForReinsurerVersion[] {
    const groupExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const reFoundIndex = groupExpectedVals.findIndex(
        val =>
          val.reinsurerName === reinsurer.reinsurer.quoteReinsurerName &&
          val.reinsurerVersion === reinsurer.reinsurer.reinsurerPhaseVersion
      )
      if (reFoundIndex < 0) {
        groupExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      } else {
        const newExpectedCdedLoss =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededLoss +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0)
        const newExpectedCdedPremium =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededPremium +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0)
        groupExpectedVals[reFoundIndex] = {
          ...groupExpectedVals[reFoundIndex],
          totalQuoteExpectedCededLoss: newExpectedCdedLoss,
          totalQuoteExpectedCededPremium: newExpectedCdedPremium,
        }
      }
    })
    return groupExpectedVals
  }

  private getSharedLimitExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    sharedLimitIds: string[]
  ): TotalExpectedForReinsurerVersion[] {
    const sharedExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const sharedFound = sharedLimitIds.find(
        sharedId => sharedId === reinsurer.reinsurer.cededlayerID
      )
      if (sharedFound) {
        sharedExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      }
    })
    return sharedExpectedVals
  }

  private getSectionHeaderIfMultisection(s: SectionState): string[] {
    const multiHeaders: string[] = []
    this.layerState.map(data => {
      if (
        data.layer.id === s.section.layerRef &&
        data.layer.meta_data.sage_layer_subtype === 'section-layer'
      ) {
        this.layerState
          .filter(state => state.layer.layerRefs.includes(s.section.layerRef))
          .map(mainLayer => {
            if (mainLayer.layer.meta_data.sage_layer_subtype === 'main-layer') {
              multiHeaders.push(
                data.layer.physicalLayer.description +
                  ':' +
                  mainLayer.layer.meta_data.layerName
              )
            }
          })
      }
    })
    return multiHeaders
  }

  private getFotSummaryRows(
    expiringReinsurers: ReinsurerState[],
    fotReinsurers: ReinsurerState[],
    currentOppYear: string,
    filteredSections: SectionState[]
  ): QuoteExportRowValue[][][] {
    const rows: QuoteExportRowValue[][][] = []
    const getValidity = (reinsurer: QuoteReinsurer) =>
      !reinsurer.decline && !!reinsurer.exportToggle
    const getReFromState = (reinsurers: ReinsurerState[]) =>
      reinsurers.map(({ reinsurer }) => reinsurer).filter(getValidity)

    const groupReinsurersByLayerID = (reinsurerList: QuoteReinsurer[]) =>
      groupBy(prop('cededlayerID'), reinsurerList)

    const longestExpiring = this.utils.findLongestArrayLength(
      Object.values(
        groupReinsurersByLayerID(getReFromState(expiringReinsurers))
      )
    )

    const longestFOT = this.utils.findLongestArrayLength(
      Object.values(groupReinsurersByLayerID(getReFromState(fotReinsurers)))
    )

    const longestLayer = longestExpiring + longestFOT

    const layers = this.layerState.map(({ layer }) => layer)
    layers.forEach(layer => {
      const section = filteredSections.find(
        ({ section }) => section.layerRef === layer.id
      )
      const exportQuotePanelDefs = this.getQuotePanelDefsForSection(section) // gets all the selected terms for this layer's quote panels
      const sectionRows = this.getSectionRows(
        section.section,
        exportQuotePanelDefs
      )
      const metricIds = [...fotSummaryRowIds]
      if (sectionRows.every(({ id }) => id !== 'quoteRolPercentage')) {
        const index = metricIds.indexOf('quoteRolPercentage')
        metricIds.splice(index, 1)
      }
      if (sectionRows.every(({ id }) => id !== 'quoteRateOnLineSubject')) {
        const index = metricIds.indexOf('quoteRateOnLineSubject')
        metricIds.splice(index, 1)
      }

      const expiring = expiringReinsurers.filter(
        ({ reinsurer }) =>
          reinsurer.cededlayerID === layer.id && getValidity(reinsurer)
      )

      const fots = fotReinsurers.filter(
        ({ reinsurer }) =>
          reinsurer.cededlayerID === layer.id && getValidity(reinsurer)
      )

      if (!!expiring.length || !!fots.length) {
        // total length of these rows will be the maximum length of a layer entry plus columns for title, gap, and YoY, add 2 since the layerName col gets added
        rows.push([
          [layer.meta_data.layerName ?? '', 'text'],
          ...this.utils.populateArraysWithValue(longestLayer + 2, [' ']),
        ])

        const expiringTitles = expiring.map(exp => [
          `Expiring ${exp.reinsurer.reinsurerPhaseLabel}`,
          'text',
        ])

        const fotTitles = fots.map(fot => [
          `${(currentOppYear && new FullDateToYear().transform(currentOppYear)) ?? ''} FOT ${fot.reinsurer.reinsurerPhaseLabel}`,
          'text',
        ])

        const expiringSpaces = this.utils.populateArraysWithValue(
          longestExpiring - expiringTitles.length,
          [' ']
        )
        const fotSpaces = this.utils.populateArraysWithValue(
          longestFOT - fotTitles.length,
          [' ']
        )

        // Expiring / FOT Title Row, YoY Rate % Header
        rows.push([
          [' '],
          ...expiringTitles,
          ...expiringSpaces,
          ...fotTitles,
          ...fotSpaces,
          [' '],
          ['YoY Rate % Change', 'text'],
        ])
        let yoyAdded = false

        metricIds.forEach(id => {
          const metricDef = layerMetricDefs[id]
          const valueType = metricDef.valueType
          const label =
            typeof metricDef.label === 'string'
              ? metricDef.label
              : 'Rate-on-Line, Occurrence'
          const defTitleEntry = [label as string, 'text']
          let expiringEntries: QuoteExportRowValue[][] | undefined
          let fotEntries: QuoteExportRowValue[][] | undefined

          if (!!expiring.length) {
            expiringEntries = expiring.map(exp =>
              this.getFotSummaryRowValueForReinsurer(
                exp,
                id,
                valueType,
                layer.currency
              )
            )
          }
          if (!!fots.length) {
            fotEntries = fots.map(fot =>
              this.getFotSummaryRowValueForReinsurer(
                fot,
                id,
                valueType,
                layer.currency
              )
            )
          }

          // Calculate YoY Rate % Change
          const expiringSpaces = this.utils.populateArraysWithValue(
            longestExpiring - (expiringEntries?.length ?? 0),
            [' ']
          )
          const fotSpaces = this.utils.populateArraysWithValue(
            longestFOT - (fotEntries?.length ?? 0),
            [' ']
          )
          const row = [
            defTitleEntry,
            ...(expiringEntries ?? []),
            ...expiringSpaces,
            ...(fotEntries ?? []),
            ...fotSpaces,
          ]
          // Rate on Line and Rate % of Subject are fields that modify one another proportionally, both will have the same YoY change
          if (
            ['quoteRolPercentage', 'quoteRateOnLineSubject'].includes(id) &&
            !yoyAdded
          ) {
            yoyAdded = true
            const expiringSum = !!expiringEntries?.length
              ? sum(expiringEntries.map(entry => Number(entry[0])))
              : 0
            const fotSum = !!fotEntries?.length
              ? sum(fotEntries.map(entry => Number(entry[0])))
              : 0

            const ratePercentageChange = !!expiringSum
              ? (fotSum - expiringSum) / expiringSum
              : ''
            row.push(
              [' '],
              [
                this.utils.calculateValue(
                  ratePercentageChange,
                  valueType,
                  layer.currency
                ),
                'percentage',
              ]
            )
          }
          rows.push(row)
        })
      }
    })

    return rows
  }

  private getMultipleVersionMappingsForReinsurers(
    expiringReinsurers: ReinsurerState[],
    nonFotAndExpiringReinsurers: ReinsurerState[]
  ): number[][] {
    const mappings: number[][] = []
    // Build relation between expiring and reinsurer
    const expiringByLayer: Record<string, ReinsurerState[]> = groupBy(
      path(['reinsurer', 'cededlayerID']),
      expiringReinsurers
    )
    const reByLayer: Record<string, ReinsurerState[]> = groupBy(
      path(['reinsurer', 'cededlayerID']),
      nonFotAndExpiringReinsurers
    )
    Object.values(reByLayer).forEach(reList => {
      const row: number[] = []
      reList.forEach((reinsurer, j) => {
        // Find expiring reinsurer that has this reinsurer as an assigned line
        const expiringForLayer =
          expiringByLayer[reinsurer.reinsurer.cededlayerID] ?? []

        if (!expiringForLayer.length) {
          return
        }

        let reinsurerMatch = expiringForLayer.find(re =>
          re.reinsurer.riskAssignedLinesLink?.some(
            line => line.marketTpRef === reinsurer.reinsurer.tpRef
          )
        )

        if (!reinsurerMatch) {
          // Find the reinsurer with the largest sum of signed value
          const summedSignedPercents = expiringForLayer.map(re =>
            sum(
              re.reinsurer.riskAssignedLinesLink.map(line => line.signed ?? 0)
            )
          )

          if (!summedSignedPercents.length) {
            // If everything is equal, use the largest offered% value
            const offeredPercentVals = expiringForLayer.map(
              re => re.reinsurer.quoteFields.quoteOfferedPercentage ?? 0
            )
            const index = offeredPercentVals.findIndex(
              val => val === Math.max(...offeredPercentVals)
            )
            reinsurerMatch = expiringForLayer[index]
          } else {
            // Use summed signed value
            const index = summedSignedPercents.findIndex(
              val => val === Math.max(...summedSignedPercents)
            )
            reinsurerMatch = expiringForLayer[index]
          }
        }

        if (!reinsurerMatch && !!expiringForLayer.length) {
          // Default to the first expiring if no other condition is met
          reinsurerMatch = expiringForLayer[0]
        }

        const expiringIndex = expiringForLayer.indexOf(reinsurerMatch)

        row.push(expiringIndex)
      })
      mappings.push(row)
    })

    return mappings
  }

  private getFotSummaryRowValueForReinsurer(
    reinsurer: ReinsurerState,
    id: keyof LayerViewValues,
    valueType: MetricValueType,
    currency: string
  ): QuoteExportRowValue[] {
    const quoteFields = reinsurer.reinsurer.quoteFields
    const entryValue = (quoteFields as any)[id] ?? ''
    const calculatedValue = this.utils.calculateValue(
      entryValue,
      valueType,
      currency
    )
    const finalValue = !!calculatedValue
      ? calculatedValue
      : valueType === 'currency'
        ? `${this.utils.convertCurrencyToSymbol(0, currency)}`
        : 0
    return [finalValue, valueType]
  }
}
