import { ApplicationError } from '../../error/model/error'

export interface HasID {
  id: string | number
}

export interface HasName {
  name: string
}

export interface ItemInList<T> {
  item: T
  list: T[]
}

interface HasFetchTime {
  // Last successful fetch time in UNIX epoch milliseconds
  fetchTime?: number
}

export interface CanFetch extends HasFetchTime {
  loading: boolean
  error: ApplicationError | null
}

export const DEFAULT_FETCH_STATE_TIME = 18e5 // 30 minutes

export const isStale = (
  state?: HasFetchTime,
  staleTime = DEFAULT_FETCH_STATE_TIME
) =>
  state == null || !state.fetchTime || Date.now() - state.fetchTime > staleTime
