import { isNil, reject } from 'ramda'
import {
  asyncScheduler,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Operator,
  OperatorFunction,
  pipe,
  SchedulerLike,
  Subscriber,
  Subscription,
  TeardownLogic,
} from 'rxjs'
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators'

export const rejectNil = <T>(list: Array<T | undefined | null>): T[] =>
  reject(isNil, list)

export const rejectNilOperator = <T>(): OperatorFunction<
  T | undefined | null,
  T
> => filter<T>((value: T | undefined | null): value is T => value != null)

export type MaybeArray<T> = T | T[]

export const toArray = <T>(x: T | T[] | null | undefined = []): T[] =>
  Array.isArray(x) ? x : x != null ? [x] : []

export const getManyFromDictionary = <T>(
  keys?: (string | number)[],
  dict?: Record<string | number, T | undefined>
): T[] => {
  return keys && dict ? rejectNil(keys.map(key => dict[key])) : []
}

export const resolveBooleanAccessor = <T extends object | string | number>(
  accessor?: boolean | string | ((o: T) => boolean | undefined),
  object?: T,
  defaultValue = false
): boolean => {
  if (typeof accessor === 'boolean') {
    return accessor
  }
  if (accessor == null || object == null) {
    return defaultValue
  }
  if (typeof accessor === 'string') {
    return (object[accessor as keyof T] as unknown) === true
  }
  return accessor(object) === true
}

export const rejectUnlessStale = <T extends object | string | number>(
  selectStale: Observable<boolean>,
  rejectCallback?: (props: T) => void,
  forceAccessor:
    | boolean
    | string
    | ((props: T) => boolean | undefined) = 'force'
): MonoTypeOperatorFunction<T> =>
  pipe(
    concatMap(props => of(props).pipe(withLatestFrom(selectStale))),
    map(([props, stale]) => {
      const abort = !stale && !resolveBooleanAccessor(forceAccessor, props)
      if (rejectCallback && abort) {
        rejectCallback(props)
      }
      return [props, abort] as const
    }),
    filter(([_, abort]) => !abort),
    map(([props]) => props)
  )

class ConditionalDebounceTimeSubscriber<T> extends Subscriber<T> {
  private debouncedSubscription: Subscription | null = null
  private lastValue: T | null = null
  private hasValue = false

  constructor(
    destination: Subscriber<T>,
    private conditionFunc: (val: T) => boolean,
    private dueTime: number,
    private scheduler: SchedulerLike
  ) {
    super(destination)
  }

  protected _next(value: T) {
    this.clearDebounce()
    this.lastValue = value
    this.hasValue = true
    if (this.conditionFunc(value)) {
      this.add(
        (this.debouncedSubscription = this.scheduler.schedule(
          dispatchNext,
          this.dueTime,
          this
        ))
      )
    } else {
      // tslint:disable-next-line: no-non-null-assertion
      this.destination.next!(this.lastValue)
    }
  }

  protected _complete() {
    this.debouncedNext()
    // tslint:disable-next-line: no-non-null-assertion
    this.destination!.complete!()
  }

  debouncedNext(): void {
    this.clearDebounce()

    if (this.hasValue) {
      const { lastValue } = this
      // This must be done *before* passing the value
      // along to the destination because it's possible for
      // the value to synchronously re-enter this operator
      // recursively when scheduled with things like
      // VirtualScheduler/TestScheduler.
      this.lastValue = null
      this.hasValue = false
      // tslint:disable-next-line: no-non-null-assertion
      this.destination!.next!(lastValue!)
    }
  }

  private clearDebounce(): void {
    const debouncedSubscription = this.debouncedSubscription

    if (debouncedSubscription !== null) {
      this.remove(debouncedSubscription)
      debouncedSubscription.unsubscribe()
      this.debouncedSubscription = null
    }
  }
}

class ConditionalDebounceTime<T> implements Operator<T, T> {
  constructor(
    private conditionFunc: (val: T) => boolean,
    private dueTime: number,
    private scheduler: SchedulerLike
  ) {}

  call(subscriber: Subscriber<T>, source: any): TeardownLogic {
    return source.subscribe(
      new ConditionalDebounceTimeSubscriber(
        subscriber,
        this.conditionFunc,
        this.dueTime,
        this.scheduler
      )
    )
  }
}

export function conditionalDebounceTime<T>(
  conditionFunc: (val: T) => boolean,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    source.lift(new ConditionalDebounceTime(conditionFunc, dueTime, scheduler))
}

function dispatchNext(subscriber: ConditionalDebounceTimeSubscriber<any>) {
  subscriber.debouncedNext()
}
