import { Numeric } from 'd3'
import { curry, head, last, tail, values } from 'ramda'
import calculateCagr from './calculate-cagr'
import { getBenchmarkMetricID } from '../../../benchmark/model/benchmark.util'
import {
  AggregateMetricMethod,
  CompositeMetricMethod,
} from '../../../core/model/metric.model'
import { isArray2D } from '@shared/util/array'
import { divideOrZero, getMedianValue } from '@shared/util/math'
import { rejectNil, toArray } from '@shared/util/operators'

export interface MetricValueFilter {
  lobs?: number[]
  states?: string[]
}

export type DataByMetric = {
  entityID?: string | number
  date: Date
  metrics: Record<string | number, number>
  metricsByState?: Record<string, Record<string | number, number>>
  metricsByLob?: Record<number, Record<string | number, number>>
  metricsByStateLob?: Record<string, Record<string | number, number>>
}

export interface AggregateMetricDef {
  id: string | number
  aggregateMethod?: AggregateMetricMethod
  metricWeightID?: string | number
  metric2ID?: string | number
  metric2WeightID?: string | number
  compositeMethod?: CompositeMetricMethod
  compositeIDs?: (string | number)[]
  compositeDefaultValues?: (number | null)[]
}

const sum = <T>(data: T[], accessor: (d: T) => number): number =>
  data.reduce((acc, d) => acc + accessor(d).valueOf(), 0)

const makeValueOf =
  <T>(accessor: (d: T) => number | Numeric) =>
  (d?: T, defaultValue = 0): number => {
    if (d != null) {
      const value = accessor(d)
      if (value != null) {
        return value.valueOf()
      }
    }
    return defaultValue
  }

export const makeDataAggregator =
  <T>(
    method: AggregateMetricMethod = 'last',
    valueAccessor: (d: T) => number | Numeric,
    dateAccessor?: (d: T) => Date | number | Numeric | undefined
  ) =>
  (data: T[]) =>
    aggregateData(method, data, valueAccessor, dateAccessor)

const aggregateData = <T>(
  method: AggregateMetricMethod = 'last',
  data: T[],
  valueAccessor: (d: T) => number | Numeric,
  dateAccessor?: (d: T) => Date | number | Numeric | undefined
): number => {
  const valueOf = makeValueOf(valueAccessor)
  switch (method) {
    case 'change': {
      const firstDatum = head(data)
      const lastDatum = last(data)
      const firstVal = valueOf(firstDatum)
      const lastVal = valueOf(lastDatum)
      return lastVal - firstVal
    }
    case 'percent-change': {
      const firstDatum = head(data)
      const lastDatum = last(data)
      const firstVal = valueOf(firstDatum)
      const lastVal = valueOf(lastDatum)
      if (firstVal > 0) {
        return (lastVal - firstVal) / firstVal
      }
      return 0
    }
    case 'cagr': {
      const firstDatum = head(data)
      const lastDatum = last(data)
      if (dateAccessor && firstDatum != null && lastDatum != null) {
        const firstDate = dateAccessor(firstDatum)
        const lastDate = dateAccessor(lastDatum)
        if (firstDate != null && lastDate != null) {
          const firstVal = valueOf(firstDatum)
          const lastVal = valueOf(lastDatum)
          if (firstVal > 0) {
            return calculateCagr(firstVal, firstDate, lastVal, lastDate)
          }
        }
      }
      return 0
    }
    case 'mean':
    case 'aggregate-ratio':
    case 'weighted-average': {
      return data.length === 0 ? 0 : sum(data, valueOf) / data.length
    }
    case 'median': {
      return getMedianValue(d => valueAccessor(d).valueOf(), data)
    }
    case 'sum': {
      return sum(data, valueOf)
    }
    case 'last': {
      return valueOf(last(data))
    }
    case 'sd': {
      const n = data.length
      if (n === 0) {
        return 0
      }
      const mean = sum(data, valueOf) / n
      const diffSquared = data.map(d => Math.pow(valueOf(d) - mean, 2))
      const diffSquaredSum = diffSquared.reduce((acc, d) => acc + d)
      const variance = diffSquaredSum / n
      return Math.sqrt(variance)
    }
    default: {
      throw new Error(`Aggregate Data method '${method}' not supported.`)
    }
  }
}

const getMean = makeDataAggregator<number>('mean', d => d)
const getSum = makeDataAggregator<number>('sum', d => d)

export interface MetricValueResult {
  value: number
  issues: string[]
  count?: number
  rawValue?: number
  fromValue?: number
  rawValuePeers?: number | Numeric
  fromValuePeers?: number | Numeric
  date?: Date
  fromDate?: Date
  toValue?: number
}

const calculateCompositeMethod = (
  compositeMethod: CompositeMetricMethod,
  data: number[][]
): number => {
  const n = data.length
  switch (compositeMethod) {
    case 'sum': {
      return data.reduce((acc, d) => acc + getSum(d), 0)
    }
    case 'difference': {
      return data.reduce((acc, d, i) => acc + getSum(d) * (i === 0 ? 1 : -1), 0)
    }
    case 'ratio': {
      if (n < 2) {
        return getMean(data[0] ?? [])
      }
      const numerator = data.reduce((acc, d, i, arr) => {
        if (i === arr.length - 1) {
          return acc
        }
        return acc + getSum(d)
      }, 0)
      const denominator = getSum(data[n - 1])
      return denominator === 0 ? 0 : numerator / denominator
    }
    case 'tailRatio': {
      if (n < 2) {
        return getMean(data[0] ?? [])
      }
      const numerator = getSum(data[0])
      const denominator = data.slice(1).reduce((acc, d) => acc + getSum(d), 0)
      return denominator === 0 ? 0 : numerator / denominator
    }
    case 'ratio2Denoms': {
      if (n < 2) {
        return getMean(data[0] ?? [])
      }
      const numerator = data.reduce((acc, d, i, arr) => {
        if (i >= arr.length - 2) {
          return acc
        }
        return acc + getSum(d)
      }, 0)
      const denominator = getSum(data[n - 1]) + getSum(data[n - 2])
      return denominator === 0 ? 0 : numerator / denominator
    }
    case 'negativeRatio': {
      return calculateCompositeMethod('ratio', data) * -1
    }
    case 'negativeRatio2Denoms': {
      return calculateCompositeMethod('ratio2Denoms', data) * -1
    }
    case 'remainderRatio2Denoms': {
      if (n < 2) {
        return getMean(data[0] ?? [])
      }
      const numerator = data.reduce((acc, d, i, arr) => {
        if (i >= arr.length - 2) {
          return acc
        }
        const value = getSum(d)
        // Calculation is in the form of `(m[0] - m[1..n-1]) / m[n]
        return acc + (i === 0 ? value : -value)
      }, 0)
      const denominator = getSum(data[n - 1]) + getSum(data[n - 2])
      return denominator === 0 ? 0 : numerator / denominator
    }
    case 'remainderRatio': {
      if (n < 2) {
        return getMean(data[0] ?? [])
      }
      const numerator = data.reduce((acc, d, i, arr) => {
        if (i === arr.length - 1) {
          return acc
        }
        const value = getSum(d)
        // Calculation is in the form of `(m[0] - m[1..n-1]) / m[n]
        return acc + (i === 0 ? value : -value)
      }, 0)
      const denominator = getSum(data[n - 1])
      return denominator === 0 ? 0 : numerator / denominator
    }
    /**
     * Sums 2 or 3 ratios where the 1st ratio has 2 numerator terms, the 2nd
     * has 2 terms, and the 3rd is optional with (X) number of terms.
     *
     * @example
     * Expense Ratio [a]: (114110 + 114111) / 114212
     * Loss & LAE Ratio [b]: (114108 + 114109) / 114107
     * Policyholder Dividend Ratio [c]: 114126 / 114107 (Optional)
     *
     * Combined Ratio: <a> + <b> + <c>
     *   = ((114110 + 114111) / 114212)
     *   + ((114108 + 114109) / 114107)
     *   + 114126 / 114107
     */
    case 'sumRatios22X': {
      return getSum([
        calculateCompositeMethod('ratio', data.slice(0, 3)),
        calculateCompositeMethod('ratio', data.slice(3, 6)),
        n > 6 ? calculateCompositeMethod('ratio', data.slice(6, n)) : 0,
      ])
    }
    /**
     * Sums 2 or 3 ratios where the 1st ratio has 4 numerator terms, the 2nd
     * has 3 terms, and the 3rd is optional with (X) number of terms.
     */
    case 'sumRatios43X': {
      return getSum([
        calculateCompositeMethod('ratio', data.slice(0, 5)),
        calculateCompositeMethod('ratio', data.slice(5, 9)),
        n > 9 ? calculateCompositeMethod('ratio', data.slice(9, n)) : 0,
      ])
    }
    /**
     * Same as `sumRatios22X` with an additional 1-term ratio that
     * is subtracted.
     */
    case 'sumRatios22XN1': {
      return getSum([
        calculateCompositeMethod('sumRatios22X', data.slice(0, n - 2)),
        calculateCompositeMethod('negativeRatio', data.slice(n - 2, n)),
      ])
    }
    /**
     * Same as `sumRatios43X` with an additional 1-term ratio that
     * is subtracted.
     */
    case 'sumRatios43XN1': {
      return getSum([
        calculateCompositeMethod('sumRatios43X', data.slice(0, n - 2)),
        calculateCompositeMethod('negativeRatio', data.slice(n - 2, n)),
      ])
    }
    /**
    /**
     * Sums 3 ratios where the 1st ratio has 1 numerator term, the 2nd
     * has 1 term, and the 3rd is optional with (X) number of terms.
     *
     * @example
     * US Health Metrics
     * Expense Ratio [a]: 118474 / 118594 
     * Loss Pure Ratio [b]: 118470 / 221559
     * Loss LAE Both Ratio [c]: (118725 + 118758) / 221559
     * 
     * Combined Ratio: <a> + <b> + <c>
     *   = 118474 / 118594
     *   + ((118470 + 118471) / 221559)
     *   + ((118725 + 118758) / 221559)
     */
    case 'sumRatios12X': {
      return getSum([
        calculateCompositeMethod('ratio', data.slice(0, 2)),
        calculateCompositeMethod('ratio', data.slice(2, 5)),
        n > 5 ? calculateCompositeMethod('ratio', data.slice(5, n)) : 0,
      ])
    }
    /**
     * Same as `sumRatios21X` with an additional 1-term ratio that
     * is subtracted.
     */
    case 'sumRatios12XN1': {
      return getSum([
        calculateCompositeMethod('sumRatios12X', data.slice(0, n - 2)),
        calculateCompositeMethod('negativeRatio', data.slice(n - 2, n)),
      ])
    }
    /**
     * Sums 3 ratios where the 1st ratio has 1 numerator term, the 2nd
     * has 1 term, and the 3rd is optional with (X) number of terms.
     *
     * @example
     * US Health Metrics
     * Expense Ratio [a]: 118474 / (221559 + 118594) 
     * Loss Pure Ratio [b]: (118470 + 118471) / (221559 + 118594) 
     * Loss LAE Both Ratio [c]: (118725 + 118758) / (221559 + 118594) 
     * 
     * Combined Ratio: <a> + <b> + <c>
     *   = 118474 / (221559 + 118594) 
     *   + ((118470 + 118471) / (221559 + 118594 )
     *   + ((118725 + 118758) / (221559 + 118594))
     */
    case 'sumRatios2Denoms12X': {
      return getSum([
        calculateCompositeMethod('ratio2Denoms', data.slice(0, 3)),
        calculateCompositeMethod('ratio2Denoms', data.slice(3, 7)),
        n > 7 ? calculateCompositeMethod('ratio2Denoms', data.slice(7, n)) : 0,
      ])
    }
    /**
     * Same as `sumRatios2Denoms12X` with an additional 1-term ratio that
     * is subtracted.
     */
    case 'sumRatios2Denoms12XN1': {
      return getSum([
        calculateCompositeMethod('sumRatios2Denoms12X', data.slice(0, n - 3)),
        calculateCompositeMethod('negativeRatio2Denoms', data.slice(n - 3, n)),
      ])
    }
    default: { // sum
      return data.reduce((acc, d) => acc + getSum(d), 0)
    }
  }
}

export function getDataByMetricValue(
  d: DataByMetric,
  metricID: string | number,
  filter: MetricValueFilter = {}
): number | undefined {
  const { states, lobs } = filter
  if (states?.length && lobs?.length) {
    const keys = states.flatMap(state => lobs.map(lob => `${state}_${lob}`))
    const vals = rejectNil(keys.map(k => d.metricsByStateLob?.[k]?.[metricID]))
    if (vals.length === 0) {
      return
    }
    return getSum(vals)
  }
  if (states?.length) {
    const vals = rejectNil(states.map(s => d.metricsByState?.[s]?.[metricID]))
    if (vals.length === 0) {
      return
    }
    return getSum(vals)
  }
  if (lobs?.length) {
    const vals = rejectNil(lobs.map(lob => d.metricsByLob?.[lob]?.[metricID]))
    if (vals.length === 0) {
      return
    }
    return getSum(vals)
  }
  return d.metrics[metricID]
}

const calculateComposite = (
  metric: AggregateMetricDef,
  data: DataByMetric[],
  count?: number,
  filter?: MetricValueFilter
): MetricValueResult => {
  const issues: string[] = []
  const issueByIndex = {} as Record<number, boolean>

  const valuesPerMetric = (metric.compositeIDs ?? []).map((id, i) => {
    const metricValues = data.map(d => {
      let val = getDataByMetricValue(d, id, filter)

      if (val == null) {
        const defaultValue = metric.compositeDefaultValues?.[i]
        if (defaultValue != null) {
          val = defaultValue
        } else {
          const suffix = d.entityID ? `: ${d.entityID}` : ''
          issues.push(`No composite metric value (${id})${suffix}`)
          issueByIndex[i] = true
        }
      }

      return val
    })
    return rejectNil(metricValues)
  })

  const method =
    metric.compositeMethod ??
    ((metric.compositeIDs ?? []).length === 1 ? 'sum' : 'ratio')
  const value = calculateCompositeMethod(method, valuesPerMetric)
  const result: MetricValueResult = { value, issues }
  if (count != null) {
    result.count = count - values(issueByIndex).filter(Boolean).length
  }
  return result
}

export const calculateMetricValue = curry(
  (
    metric: AggregateMetricDef,
    dataOrDatum: DataByMetric | DataByMetric[]
  ): MetricValueResult => {
    return _calculateMetricValue(metric, undefined, dataOrDatum)
  }
)

export const calculateMetricValueWith = curry(
  (
    filter: MetricValueFilter,
    metric: AggregateMetricDef,
    dataOrDatum: DataByMetric | DataByMetric[]
  ): MetricValueResult => {
    return _calculateMetricValue(metric, filter, dataOrDatum)
  }
)

const _calculateMetricValue = (
  metric: AggregateMetricDef,
  filter: MetricValueFilter | undefined,
  dataOrDatum: DataByMetric | DataByMetric[]
): MetricValueResult => {
  let count: number | undefined
  let data: DataByMetric[]

  if (Array.isArray(dataOrDatum)) {
    count = dataOrDatum.length
    data = dataOrDatum
  } else {
    data = [dataOrDatum]
  }

  const result: MetricValueResult = { value: 0, issues: [], count }
  const { metricWeightID, metric2ID, metric2WeightID } = metric

  if (metric.compositeIDs?.length) {
    return calculateComposite(metric, data, count, filter)
  }

  const metricKey = getBenchmarkMetricID(metric)
  const issueByIndex = {} as Record<number, boolean>

  const numerator = data.reduce((acc, d, i) => {
    const n1 = getDataByMetricValue(d, metricKey, filter)

    if (n1 == null) {
      const date = d.date?.toISOString().split('T')[0] ?? ''
      acc.issues.push(`No metric value (${date}: ${metricKey})`)
      issueByIndex[i] = true
      return acc
    }

    acc.value += n1

    if (metric2ID) {
      const n2 = getDataByMetricValue(d, metric2ID, filter)

      if (n2 == null) {
        const date = d.date?.toISOString().split('T')[0] ?? ''
        acc.issues.push(`No metric 2 numerator (${date}: ${metric2ID})`)
        issueByIndex[i] = true
      }

      acc.value += n2 ?? 0
    }

    return acc
  }, result)

  if (!metricWeightID) {
    numerator.value /= data.length
    if (numerator.count) {
      numerator.count -= values(issueByIndex).filter(Boolean).length
    }
    return numerator
  }

  const denominator = data.reduce(
    (acc, d, i) => {
      const d1 = getDataByMetricValue(d, metricWeightID, filter)

      if (d1 == null) {
        acc.issues.push(`No metric denominator (${metricWeightID})`)
        issueByIndex[i] = true
        return acc
      }
      if (d1 === 0) {
        acc.issues.push(`Zero metric denominator (${metricWeightID})`)
        issueByIndex[i] = true
        return acc
      }

      acc.value += d1

      if (metric2WeightID) {
        const d2 = getDataByMetricValue(d, metric2WeightID, filter)

        if (d2 == null) {
          acc.issues.push(`No metric denominator (${metric2WeightID})`)
          issueByIndex[i] = true
          return acc
        }
        if (d2 === 0) {
          acc.issues.push(`Zero metric denominator (${metric2WeightID})`)
          issueByIndex[i] = true
          return acc
        }

        acc.value += d2
      }
      return acc
    },
    { value: 0, issues: [] as string[] }
  )

  result.value =
    denominator.value === 0
      ? numerator.value
      : numerator.value / denominator.value

  result.issues = result.issues
    .concat(numerator.issues)
    .concat(denominator.issues)

  if (result.count) {
    result.count -= values(issueByIndex).filter(Boolean).length
  }

  return result
}

function _calculateWeightedAverage(
  data: DataByMetric[],
  factorID: string | number,
  weightID: string | number,
  filter?: MetricValueFilter
): MetricValueResult {
  const issues: string[] = []

  const numerators = data.map((d, i) => {
    const factor = getDataByMetricValue(d, factorID, filter)
    const weight = getDataByMetricValue(d, weightID, filter)

    if (factor == null) {
      issues.push(`No metric factor (${factorID}, ${i})`)
      return null
    }
    if (weight == null) {
      issues.push(`No metric weight (${weightID}, ${i})`)
      return null
    }
    if (weight === 0) {
      issues.push(`Zero metric denominator (${weightID}, ${i})`)
      return null
    }

    return factor * weight
  })

  const denominators = data.map(d => getDataByMetricValue(d, weightID, filter))

  const numerator = rejectNil(numerators).reduce((acc, n) => acc + n, 0)
  const denominator = rejectNil(denominators).reduce((acc, d) => acc + d, 0)
  const weightedMean = numerator / (denominator || 1)

  return { value: weightedMean, issues }
}

const calculateWeightedAverage = (
  metric: AggregateMetricDef,
  filter: MetricValueFilter | undefined,
  dataOrDatum: DataByMetric | DataByMetric[]
): MetricValueResult => {
  const { metricWeightID, metric2ID, metric2WeightID } = metric

  if (!metricWeightID || (metric2WeightID && !metric2WeightID)) {
    return _calculateMetricValue(metric, filter, dataOrDatum)
  }

  const data = toArray(dataOrDatum)

  const metricKey = getBenchmarkMetricID(metric)

  const result = _calculateWeightedAverage(
    data,
    metricKey,
    metricWeightID,
    filter
  )

  if (!metric2ID || !metric2WeightID) {
    return result
  }

  const result2 = _calculateWeightedAverage(
    data,
    metric2ID,
    metric2WeightID,
    filter
  )

  return {
    value: result.value + result2.value,
    issues: result.issues.concat(result2.issues),
  }
}

export const aggregateMetricData = curry(
  (
    metric: AggregateMetricDef,
    rawData: DataByMetric[] | DataByMetric[][]
  ): MetricValueResult => {
    return _aggregateMetricData(metric, undefined, rawData)
  }
)

export const aggregateMetricDataWith = curry(
  (
    metric: AggregateMetricDef,
    filter: MetricValueFilter,
    rawData: DataByMetric[] | DataByMetric[][]
  ): MetricValueResult => {
    return _aggregateMetricData(metric, filter, rawData)
  }
)

const _aggregateMetricData = (
  metric: AggregateMetricDef,
  filter: MetricValueFilter | undefined,
  rawData: DataByMetric[] | DataByMetric[][]
): MetricValueResult => {
  let result: MetricValueResult = { value: 0, issues: [] }

  const metricKey = getBenchmarkMetricID(metric)

  if (rawData.length === 0) {
    result.issues.push(`Data is empty (${metricKey})`)
    return result
  }

  const data = isArray2D(rawData) ? rawData : rawData.map(d => [d])

  const value = (d: DataByMetric | DataByMetric[]): number => {
    const res = _calculateMetricValue(metric, filter, d)
    result.issues = result.issues.concat(res.issues)
    return res.value
  }

  const firstData = head(data) ?? []
  const lastData = last(data) ?? []
  const firstDate = firstData[0]?.date
  const lastDate = lastData[0]?.date
  const firstRes = value(firstData)
  const lastRes = value(lastData)

  result.fromDate = firstDate
  result.date = lastDate
  result.fromValue = firstRes
  result.rawValue = lastRes
  result.toValue = lastRes
  switch (metric.aggregateMethod ?? 'last') {
    case 'change': {
      result.value = lastRes - firstRes
      return result
    }
    case 'percent-change': {
      if (firstRes === 0) {
        result.issues.push(`Zero first value of percent change ${metricKey}`)
        return result
      }
      result.value = (lastRes - firstRes) / firstRes
      return result
    }
    case 'market-share': {
      const i1 = data.reduce((acc, d) => (value(d) ?? 0) + acc, 0)
      const target = data.reduce((acc, d) => (value(d[0]) ?? 0) + acc, 0)
      result.value = target / i1
      result.rawValue = target
      result.rawValuePeers = i1
      delete result.fromValue
      return result
    }
    case 'cagr': {
      if (firstDate == null || lastDate == null) {
        result.issues.push(`No date for CAGR ${metricKey}`)
        return result
      }
      if (firstRes === 0) {
        result.issues.push(`Zero first value of CAGR ${metricKey}`)
        return result
      }

      const peersData = data.map(d => tail(d))
      const peersFirstData = head(peersData) ?? []
      const peersLastData = last(peersData) ?? []
      const peersFirstDate = peersFirstData[0]?.date
      const peersLastDate = peersLastData[0]?.date
      const peersFirstRes = value(peersFirstData)
      const peersLastRes = value(peersLastData)
      if (
        peersFirstDate != null &&
        peersLastDate != null &&
        peersFirstRes !== 0
      ) {
        result.rawValuePeers = calculateCagr(
          peersFirstRes,
          peersFirstDate,
          peersLastRes,
          peersLastDate
        )
      }

      const targetData = data.map(d => head(d)).map(d => rejectNil([d]))
      const targetFirstData = head(targetData) ?? []
      const targetLastData = last(targetData) ?? []
      const targetFirstDate = targetFirstData[0]?.date
      const targetLastDate = targetLastData[0]?.date
      const targetFirstRes = value(targetFirstData)
      const targetLastRes = value(targetLastData)
      if (
        targetFirstDate != null &&
        targetLastDate != null &&
        targetFirstRes !== 0
      ) {
        result.rawValue = calculateCagr(
          targetFirstRes,
          targetFirstDate,
          targetLastRes,
          targetLastDate
        )
      }

      result.value = calculateCagr(firstRes, firstDate, lastRes, lastDate)
      return result
    }
    case 'cagr-vs-peers': {
      if (firstDate == null || lastDate == null) {
        result.issues.push(`No date for CAGR ${metricKey}`)
        return result
      }
      const aggregateMethod = 'cagr'
      const nonPeersMetric: AggregateMetricDef = { ...metric, aggregateMethod }
      result = aggregateTargetVsPeers(result, nonPeersMetric, filter, data)
      return result
    }
    case 'weighted-average': {
      return {
        ...result,
        ...calculateWeightedAverage(metric, filter, data.flat()),
      }
    }
    case 'mean': {
      return {
        ...result,
        ..._calculateMetricValue(metric, filter, data.flat()),
      }
    }
    case 'mean-vs-peers': {
      const aggregateMethod = 'mean'
      const nonPeersMetric: AggregateMetricDef = { ...metric, aggregateMethod }
      result = aggregateTargetVsPeers(result, nonPeersMetric, filter, data)
      return result
    }
    case 'sd': {
      const n = data.length
      const mean = value(data.flat())
      const diffSquared = data.map(d => Math.pow((value(d) ?? 0) - mean, 2))
      const diffSquaredSum = diffSquared.reduce((acc, d) => acc + d)
      const variance = diffSquaredSum / n
      result.value = Math.sqrt(variance)
      return result
    }
    case 'median': {
      const resultValues = rejectNil(
        data.map(d => {
          const res = _calculateMetricValue(metric, filter, d)
          if (res.value === 0 && res.issues.length) {
            result.issues = result.issues.concat(res.issues)
            return null
          }
          return res.value
        })
      )
      result.value = getMedianValue(d => d, resultValues)
      return result
    }
    case 'sum': {
      result.value = data.reduce((acc, d) => (value(d) ?? 0) + acc, 0)
      return result
    }
    case 'last': {
      return {
        ...result,
        ..._calculateMetricValue(metric, filter, last(data) ?? []),
      }
    }
    case 'aggregate-ratio': {
      const ids = metric.compositeIDs ?? [metric.id]
      if (ids.length < 2) {
        throw new Error(
          "Aggregate method 'aggregate-ratio' requires 2 or more metric IDs."
        )
      }

      const targetData = data.map(d => head(d)).map(d => rejectNil([d]))
      result.rawValue = aggregateRatio(targetData, metric, filter)
      const peersData = data.map(d => tail(d))
      const peersHasValues = peersData.some(d => d.length)
      if (peersHasValues) {
        result.rawValuePeers = aggregateRatio(peersData, metric, filter)
      }

      result.value = aggregateRatio(data, metric, filter)
      return result
    }
    case 'aggregate-ratio-difference': {
      const ids = metric.compositeIDs ?? [metric.id]
      const n = ids.length
      if (n % 2 === 1) {
        throw new Error(
          "Aggregate method 'aggregate-ratio-difference' requires an even number of metric IDs."
        )
      }
      const firstMetricIDs = ids.slice(0, n / 2)
      const lastMetricIDs = ids.slice(n / 2)
      const firstRatio = aggregateRatio(
        data,
        { ...metric, compositeIDs: firstMetricIDs },
        filter
      )
      const lastRatio = aggregateRatio(
        data,
        { ...metric, compositeIDs: lastMetricIDs },
        filter
      )
      result.value = firstRatio - lastRatio
      return result
    }
    case 'aggregate-ratio-vs-peers': {
      const ids = metric.compositeIDs ?? [metric.id]
      const n = ids.length
      if (n % 2 === 1) {
        throw new Error(
          "Aggregate method 'aggregate-ratio-vs-peers' requires an even number of metric IDs."
        )
      }
      const aggregateMethod: AggregateMetricMethod = 'aggregate-ratio'
      const nonPeersMetric: AggregateMetricDef = { ...metric, aggregateMethod }
      result = aggregateTargetVsPeers(result, nonPeersMetric, filter, data)
      return result
    }
  }
}

function aggregateTargetVsPeers(
  result: MetricValueResult,
  metric: AggregateMetricDef,
  filter: MetricValueFilter | undefined,
  data: DataByMetric[][]
): MetricValueResult {
  const targetData = data.map(d => head(d)).map(d => rejectNil([d]))
  const peersData = data.map(d => tail(d))

  const targetResult = _aggregateMetricData(metric, filter, targetData)
  if (targetResult.issues.length > 0 && targetResult.value === 0) {
    targetResult.rawValue = 0
    targetResult.rawValuePeers = 0
    return targetResult
  }

  const peersResult = _aggregateMetricData(metric, filter, peersData)

  result.value = targetResult.value - peersResult.value
  result.rawValue = targetResult.value
  result.rawValuePeers = peersResult.value
  delete result.fromValue
  delete result.fromValuePeers

  if (targetResult.issues.length > 0) {
    result.issues.push(...targetResult.issues)
  }
  if (peersResult.issues.length > 0) {
    result.issues.push(...peersResult.issues)
  }
  if (result.count != null) {
    result.count--
  }
  return result
}

function aggregateRatio(
  data: DataByMetric[][],
  metric: AggregateMetricDef,
  filter?: MetricValueFilter
): number {
  const ids = metric.compositeIDs ?? [metric.id]
  const valuePerMetricPerDate = data.map(ds =>
    ids.map(id => {
      const singleMetric = { ...metric, compositeIDs: [id] }
      return _calculateMetricValue(singleMetric, filter, ds).value
    })
  )
  const aggDenominator = getSum(valuePerMetricPerDate.map(ds => last(ds) ?? 0))
  return valuePerMetricPerDate.reduce((outerAcc, ds) => {
    const numerators = ds.slice(0, ds.length - 1)
    const product = numerators.reduce((acc, v) => acc * v, 1)
    return outerAcc + divideOrZero(product, aggDenominator)
  }, 0)
}

export function getDataAggregateTimeframeLabel(
  method: AggregateMetricMethod
): string {
  switch (method) {
    case 'change':
      return 'Change'
    case 'percent-change':
      return 'Percent Change'
    case 'market-share':
      return 'Market Share'
    case 'cagr':
      return 'CAGR'
    case 'cagr-vs-peers':
      return 'CAGR vs Peers'
    case 'mean-vs-peers':
      return 'Average vs Peers'
    case 'mean':
    case 'weighted-average':
      return 'Average'
    case 'sum':
      return 'Sum'
    case 'last':
      return ''
    case 'median':
      return 'Median'
    case 'sd':
      return 'Volatility'
    case 'aggregate-ratio':
      return 'Aggregate Ratio'
    case 'aggregate-ratio-difference':
      return 'Aggregate Ratio Difference'
    case 'aggregate-ratio-vs-peers':
      return 'Aggregate Ratio vs Peers'
  }
}

export function getDataAggregateMetricLabel(
  method: AggregateMetricMethod
): string | undefined {
  switch (method) {
    case 'percent-change':
      return 'Growth'
    case 'sum':
      return 'Sum'
    case 'last':
      return 'Latest'
    case 'median':
      return 'Median'
    case 'sd':
      return 'Volatility'
    default:
    case 'mean':
    case 'weighted-average':
      return undefined
  }
}

export default aggregateData
