<div class="panel">
  <ng-container *ngFor="let def of quotePanelDefs; trackBy: trackByDef">
    <div *ngIf="exportSelected(def)">
      <div *ngIf="def.fotOnly ? isFotMaster : true">
        <div class="modifier">
          <button
            appButtonIcon
            huge
            class="icon"
            disableRipple
            [matMenuTriggerFor]="moreMenu"
            (click)="
              isFotMaster
                ? _selectAllReinsurersForFOT(def)
                : _selectAllReinsurersForExpiring(def)
            "
            *ngIf="
              ((isFotMaster || isExpiring) && def.valueType === 'currency') ||
              ((isFotMaster || isExpiring) &&
                def.id === 'minimumPremiumPercentage') ||
              ((isFotMaster || isExpiring) &&
                def.id === 'quoteRolPercentage') ||
              ((isFotMaster || isExpiring) &&
                def.id === 'quoteRateOnLineSubject') ||
              isGroupSelected ||
              isSLSelected
            "
            matTooltip="Click to open selection panel."
            matTooltipPosition="right"
          >
            more_horiz
          </button>
          <mat-menu #moreMenu="matMenu">
            <button
              *ngIf="
                (!isGroupSelected && !isSLSelected) || isFotMaster || isExpiring
              "
              mat-menu-item
              class="icon"
              [matMenuTriggerFor]="reinsurerMenu"
            >
              Reinsurer
            </button>
            <button
              *ngIf="
                (!isGroupSelected && !isSLSelected) || isFotMaster || isExpiring
              "
              mat-menu-item
              class="icon"
              [matMenuTriggerFor]="actionMenu"
            >
              Action
            </button>
            <button
              *ngIf="isGroupSelected || isSLSelected"
              mat-menu-item
              class="icon"
              [matMenuTriggerFor]="selectMenu"
            >
              Copy Value to Layers
            </button>
          </mat-menu>
          <mat-menu #reinsurerMenu="matMenu">
            <button
              appButton
              stack
              primary
              *ngFor="let reinsurer of getAllQuoteReinsurers"
              [class.selected]="isSelected(reinsurer.id)"
              (click)="
                $event.stopPropagation();
                isFotMaster
                  ? onFOTMasterSelection(
                      this.displayDefValue(reinsurer, def),
                      reinsurer.id
                    )
                  : onExpiringSelection(
                      this.displayDefValue(reinsurer, def),
                      reinsurer.id
                    )
              "
            >
              <div class="parent">
                <mat-icon
                  class="child"
                  *ngIf="isSelected(reinsurer.id)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box
                </mat-icon>
                <mat-icon
                  class="child"
                  *ngIf="!isSelected(reinsurer.id)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box_outline_blank
                </mat-icon>
                <div class="child">
                  <div>
                    {{ reinsurer.quoteReinsurerName }}
                    {{ reinsurer.reinsurerPhase }}
                    {{ reinsurer.reinsurerPhaseVersion }}:
                    {{ this.displayDefValue(reinsurer, def) }}
                  </div>
                </div>
              </div>
            </button>
          </mat-menu>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="onSetClick(finalMin, def)">
              Min - {{ finalMin }}
            </button>
            <button mat-menu-item (click)="onSetClick(finalMax, def)">
              Max - {{ finalMax }}
            </button>
            <button mat-menu-item (click)="onSetClick(finalAvg, def)">
              Average - {{ finalAvg }}
            </button>
            <button
              *ngIf="finalAvgExIsVisible"
              mat-menu-item
              (click)="onSetClick(finalAvgEx, def)"
            >
              Avg (ex Min/Max) - {{ finalAvgEx }}
            </button>
          </mat-menu>
          <mat-menu #selectMenu="matMenu">
            <button
              appButton
              stack
              primary
              [class.selected]="isSameValueForAll(def)"
              (click)="
                $event.stopPropagation();
                setGroupLayerValueForAll(this.getValue(def), def)
              "
            >
              <div class="parent">
                <mat-icon
                  class="child"
                  *ngIf="isSameValueForAll(def)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box
                </mat-icon>
                <mat-icon
                  class="child"
                  *ngIf="!isSameValueForAll(def)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box_outline_blank
                </mat-icon>
                <div class="child">
                  <div>Select All</div>
                </div>
              </div>
            </button>
            <button
              appButton
              stack
              primary
              *ngFor="let reinsurer of getAllGroupReinsurers"
              [class.selected]="isSameValue(reinsurer.id, def)"
              (click)="
                $event.stopPropagation();
                setGroupLayerValue(this.getValue(def), reinsurer, def)
              "
            >
              <div class="parent">
                <mat-icon
                  class="child"
                  *ngIf="isSameValue(reinsurer.id, def)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box
                </mat-icon>
                <mat-icon
                  class="child"
                  *ngIf="!isSameValue(reinsurer.id, def)"
                  aria-hidden="false"
                  aria-label="check"
                >
                  check_box_outline_blank
                </mat-icon>
                <div class="child">
                  <div>
                    {{ getLayerName(reinsurer) }}
                    {{ reinsurer.reinsurerPhaseLabel }}:
                    {{ this.displayDefValue(reinsurer, def) }}
                  </div>
                </div>
              </div>
            </button>
          </mat-menu>
        </div>
        <ng-container *ngIf="!def.unlimitable; else unlimitableTemplate">
          <ng-container *ngTemplateOutlet="layerPropTemplate"></ng-container>
        </ng-container>

        <ng-template #unlimitableTemplate>
          <div class="div-check">
            <div class="check-input">
              <ng-container
                *ngTemplateOutlet="layerPropTemplate"
              ></ng-container>
            </div>
            <mat-checkbox
              matTooltip="Check/ Uncheck for Unlimited"
              matTooltipPosition="above"
              [disabled]="isDisabled || this.isDecline"
              [checked]="getUnlimited(def)"
              (click)="onCheckboxChanged(def)"
            ></mat-checkbox>
          </div>
        </ng-template>

        <ng-template #layerPropTemplate>
          <ng-container [ngSwitch]="def.valueType">
            <ng-container *ngIf="isQuotaShare && def.id === 'premium'">
              <div class="div-check">
                <div class="check-input">
                  <app-layer-property
                    [currentCurrency]="view?.currency"
                    [name]="def.label"
                    [type]="def.valueType || 'text'"
                    [value]="getValue(def)"
                    [withDraggable]="true"
                    [territories]="territories"
                    (inputChange)="setValue(def, $event)"
                    [readonly]="!def.editable || this.isDecline"
                    [whiteLabel]="true"
                  ></app-layer-property>
                </div>
                <mat-checkbox
                  matTooltip="Use Subject Premium"
                  matTooltipPosition="above"
                  [checked]="subjectPremiumChecked"
                  (click)="onSubjectPremiumChanged()"
                ></mat-checkbox>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (!isQuotaShare || def.id !== 'premium') &&
                def.id !== 'slidingComm'
              "
            >
              <ng-container *ngSwitchCase="'percentage'">
                <app-percentage-input
                  *ngIf="
                    !def.showUndefinedUnlessTypes || getValue(def) !== null
                  "
                  [name]="def.label"
                  [value]="getValue(def)"
                  [isCession]="true"
                  [isNegative]="def.label === 'Cession Percentage'"
                  [decimal]="def.decimals || 1"
                  (inputChange)="setValue(def, $event)"
                  [withDraggable]="true"
                  [whiteLabel]="true"
                ></app-percentage-input>
                <app-percentage-input
                  *ngIf="
                    def.showUndefinedUnlessTypes &&
                    !def.showUndefinedUnlessTypes.includes(view?.type) &&
                    getValue(def) === undefined
                  "
                  [name]="def.label"
                  value="N/A"
                  [withDraggable]="true"
                  [whiteLabel]="true"
                  [readonly]="!def.editable || this.isDecline"
                ></app-percentage-input>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <div class="sliding-label">
                  <label>{{ def.label }}</label>
                </div>
                <mat-checkbox
                  [name]="def.label"
                  [checked]="getTypeCheckboxValue(def)"
                  (click)="onTypeCheckboxChange(def)"
                  [ngStyle]="{ 'margin-left': '25px' }"
                ></mat-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <app-layer-property
                  *ngIf="def.id !== 'quoteExpiryDate'"
                  [name]="def.label"
                  [type]="def.valueType || 'text'"
                  [value]="getValue(def)"
                  [withDraggable]="true"
                  [territories]="territories"
                  (inputChange)="setValue(def, $event)"
                  [whiteLabel]="true"
                ></app-layer-property>
                <ng-container *ngIf="!isFotMaster && !isExpiring">
                  <app-layer-property
                    *ngIf="def.id === 'quoteExpiryDate'"
                    [name]="def.label"
                    [type]="def.valueType || 'text'"
                    [value]="getValue(def)"
                    [withDraggable]="true"
                    [territories]="territories"
                    [whiteLabel]="true"
                    (inputChange)="setValue(def, $event)"
                  ></app-layer-property>
                </ng-container>
              </ng-container>
              <app-layer-property
                *ngSwitchDefault
                [currentCurrency]="view?.currency"
                [name]="def.label"
                [type]="getType(def)"
                [value]="getValue(def)"
                [withDraggable]="true"
                [decimal]="def.decimals || '0'"
                [territories]="territories"
                [references]="def.references"
                [whiteLabel]="true"
                (inputChange)="setValue(def, $event)"
                [matTooltip]="
                  def.id === 'limitApplication'
                    ? 'Populate with the limit application as stated in the contract, e.g. each Policy, any one Loss Occurrence.'
                    : def.id === 'quoteCessionsBasedPremium'
                      ? 'Premium reported via periodic bordereau.'
                      : ''
                "
              ></app-layer-property>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <div *ngIf="selectedSection && this.selectedSection!.slidingCommToggle">
    <div class="sliding-label">
      <label>Sliding Scale Commission: </label>
    </div>
    <div class="sliding">
      <app-sort-table
        [columnDefs]="slidingScaleDefCol"
        [rows]="slidingScaleRows"
        (valueChange)="onValueChange($event)"
        [readonly]="isDecline"
      ></app-sort-table>
    </div>
  </div>
  <div class="subjectivity" *ngIf="!isExpiring && isGroupSelectedRe()">
    <button
      [disabled]="isDisabled || isDecline"
      appButton
      big
      border
      (click)="onSubjectivityClick()"
    >
      Subjectivity
    </button>
  </div>
  <div
    class="total-expected-ceded"
    *ngIf="
      this.isGroupSelectedRe() &&
      !isSLSelected &&
      !isMultiSection &&
      !isTopAndDrop
    "
  >
    <ng-container *ngFor="let def of quotePanelDefs; trackBy: trackByDef">
      <ng-container
        *ngIf="
          def.id === 'totalQuoteExpectedCededPremium' ||
          def.id === 'totalQuoteExpectedCededLoss'
        "
      >
        <app-layer-property
          [currentCurrency]="view?.currency"
          [name]="def.label"
          [type]="getType(def)"
          [value]="getValue(def)"
          [territories]="territories"
          [withDraggable]="true"
          [readonly]="!def.editable || this.isDecline"
        >
        </app-layer-property>
      </ng-container>
    </ng-container>
  </div>
</div>
