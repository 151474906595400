import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from '../api/backend/backend.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-file-container',
  styles: [
    `
           .viewer {
             background-color: var(--bg);
             margin: auto;
             width: 100%;
           }
         `,
  ],
  template: `
    <object
      *ngIf="isPDF"
      [data]="safeSrc"
      viewer="pdf"
      [ngStyle]="{ height: getHeight() + 'px' }"
      class="viewer"
    ></object>
    <video *ngIf="!isPDF" autoplay width="100%" [height]="getHeight()" controls>
      <source [src]="safeSrc" type="video/mp4" />
    </video>
  `,
})
export class DisplayFileContainerComponent implements OnInit, OnDestroy {
  @Input() file: string
  @Input() isPDF: boolean

  safeSrc: SafeResourceUrl

  private destroy$ = new Subject()

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.backendService
      .getThumbnailImage(this.file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.createURLFromBlob(data)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createURLFromBlob(blob: any) {
    const url = window.URL.createObjectURL(blob)
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    this.cdRef.markForCheck()
  }

  getHeight() {
    const header = document.getElementsByTagName('app-display-header')[0]
    const selector = document.getElementsByClassName('selector')[0]
    const app = document.getElementsByTagName('app-theme')[0]
    if (app && selector && header) {
      return app.clientHeight - (selector.clientHeight + header.clientHeight)
    } else if (app && header) {
      return app.clientHeight - header.clientHeight
    }
  }
}
