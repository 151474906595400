import { AppState } from '.'
import * as fromLossSetGroup from './loss-set-group.reducer'

export const selectLossSetGroupState = (
  state: AppState
): fromLossSetGroup.State => state.lossSetGroup

export const {
  selectAll: selectLossSetGroups,
  selectEntities: selectLossSetGroupsByID,
} = fromLossSetGroup.adapter.getSelectors(selectLossSetGroupState)
