import { createAction, props } from '@ngrx/store'
import { ScenarioEventResult } from '../animated-scenarios.model'
import { Program } from '../../../core/model/program.model'

export const addAllScenarioEventResults = createAction(
  '[Animated Scenarios] Add All Scenario Event Results',
  props<{
    eventResults: ScenarioEventResult[]
    scenarioStructure: Program | null
  }>()
)

export const removeAllScenarioEventResults = createAction(
  '[Animated Scenarios] Remove All Scenario Event Results'
)
