import * as d3 from 'd3'
import { quadtree as d3Quadtree, Quadtree } from 'd3-quadtree'
import {
  GraphingExtents,
  GraphingExtentsDef,
  withGraphingExtentsDefaults,
} from './utils/graphing-extents'
import { scaleLinear } from 'd3-scale'
import * as fc from 'd3fc'
import {
  PricingCurveContextTypes,
  PricingCurveDatum,
} from '../pricingcurve/model/pricing-curve.model'
import { ScalarScale, WithIndex } from './utils/coord'
import { ZoomProperties } from '../pricingcurve/model/pricing-curve.model'
import { PricingCurve } from '../pricingcurve/model/pricing-curve'

type PricingCurveGraphData = {
  pointSets: PricingCurveDatum[][]
  lineSets: PricingCurveDatum[][]
}

export interface PricingCurveGraphOptions {
  crossValue: (d: PricingCurveDatum) => number
  mainValue: (d: PricingCurveDatum) => number
  colorClass: (d: PricingCurveDatum) => string
  lineColorClass: (d: PricingCurveDatum) => string
  xLabel: string
  yLabel: string
  width: number
  height: number
  xAxisHeight: string
  yAxisWidth: string
  xTicks?: number
  yTicks?: number
  xTickFormat: (n: number) => string
  yTickFormat: (n: number) => string
  margin: { top: number; right: number; bottom: number; left: number }
  extents?: GraphingExtentsDef
  zoomMin: number
  zoomMax: number
  isExport: boolean
  context: PricingCurveContextTypes
  initalZoomProperties: ZoomProperties | null
  onZoom?: (zoomProperties: ZoomProperties) => void
  onLayerPointClick?: (datum: PricingCurveDatum) => void
}

const defaultExtents: GraphingExtentsDef = {
  main: {
    pad: [0, 0],
    include: [0],
    padUnit: 'percent',
    nice: true,
  },
  cross: {
    pad: [0, 0],
    include: [0],
    padUnit: 'percent',
    nice: true,
  },
}

type Options = Omit<PricingCurveGraphOptions, 'extents'> & {
  extents: GraphingExtents
}

const withDefaults = (opts: Partial<PricingCurveGraphOptions>): Options => ({
  crossValue: d => (d as any).x,
  mainValue: d => (d as any).y,
  colorClass: () => '',
  lineColorClass: () => '',
  width: opts.width ?? 800,
  height: opts.height ?? 600,
  xAxisHeight: '2em',
  yAxisWidth: '3em',
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  xTickFormat: n => n.toString(),
  yTickFormat: n => n.toString(),
  zoomMin: 0.75,
  zoomMax: 20,
  isExport: false,
  initalZoomProperties: null,
  xLabel: 'EL',
  yLabel: 'TROL',
  context: 'pricing-curve',
  ...opts,
  extents: withGraphingExtentsDefaults(defaultExtents, opts.extents),
})

export class PricingCurveGraph {
  private options: Options
  private data: PricingCurveDatum[][]
  private compareLineDef: PricingCurve | undefined
  private inputData: PricingCurveGraphData
  private width: number
  private height: number
  private xScale: ScalarScale
  private yScale: ScalarScale
  private xScaleZoom: ScalarScale
  private yScaleZoom: ScalarScale
  private zoom: d3.ZoomBehavior<Element, unknown>
  private quadtree?: Quadtree<WithIndex<PricingCurveDatum>>
  private tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>
  private line: d3.Line<PricingCurveDatum>
  private zoomGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  private xAxis: d3.Axis<number | Date | { valueOf(): number }>
  private yAxis: d3.Axis<number | Date | { valueOf(): number }>
  private xAxisSVG: d3.Selection<SVGElement, unknown, null, undefined>
  private yAxisSVG: d3.Selection<SVGElement, unknown, null, undefined>
  private initialTransform?: d3.ZoomTransform
  private circleRadius: number
  private highlightRadius = 1.25
  private lineWidth = 2
  private hoveredPoint: PricingCurveDatum | null

  constructor(
    private container: HTMLElement,
    options?: Partial<PricingCurveGraphOptions>
  ) {
    this.options = withDefaults(options || {})
    d3.select(container).selectAll('*').remove()

    this.quadtree = undefined
  }

  draw(data: PricingCurveGraphData, compareLineDef?: PricingCurve): void {
    if (data) {
      this.inputData = data
      this.compareLineDef = compareLineDef
      this.data = data.pointSets.concat(data.lineSets)

      this.width =
        this.options.width -
        this.options.margin.left -
        this.options.margin.right
      this.height =
        this.options.height -
        this.options.margin.top -
        this.options.margin.bottom
      this.circleRadius = this.options.isExport && this.width < 1000 ? 2 : 3

      this.setupChart()
      this.updateQuadtree()
    }
  }

  setupChart(): void {
    if (this.options.initalZoomProperties) {
      const zoomProps = this.options.initalZoomProperties
      this.initialTransform = d3.zoomIdentity
        .translate(zoomProps.x, zoomProps.y)
        .scale(zoomProps.zoomFactor)
    }
    this.updateCoordScales()

    // Set total width and height with margins
    const totalWidth =
      this.options.width + this.options.margin.left + this.options.margin.right
    const totalHeight =
      this.options.height + this.options.margin.top + this.options.margin.bottom

    // Create tooltip, hidden until mouseover
    this.tooltip = d3
      .select(this.container)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('opacity', '0')

    // Create chart
    const svg = d3
      .select(this.container)
      .append('svg')
      .attr('width', totalWidth)
      .attr('height', totalHeight)
      .append('g')
      .attr(
        'transform',
        `translate(${this.options.margin.left}, ${this.options.margin.top})`
      )

    // Create x and y axis
    this.xAxis = d3
      .axisBottom(this.xScale)
      .scale(this.xScale)
      .tickSize(-this.height)
      .ticks(this.options.xTicks)
      .tickFormat(this.options.xTickFormat)
    this.yAxis = d3
      .axisLeft(this.yScale)
      .scale(this.yScale)
      .tickSize(-this.width)
      .ticks(this.options.yTicks)
      .tickFormat(this.options.yTickFormat)

    this.xAxisSVG = svg
      .append('g')
      .attr('class', 'x-axis grid-lines')
      .attr('transform', `translate(0, ${this.height})`)
      .call(this.xAxis)
    this.yAxisSVG = svg
      .append('g')
      .attr('class', 'y-axis grid-lines')
      .call(this.yAxis)

    // Add Label for x axis
    this.xAxisSVG
      .append('text')
      .attr('class', 'x label')
      .attr('text-anchor', 'center')
      .attr('x', this.width / 2)
      .attr('y', 35)
      .style('fill', 'white')
      .style('font-size', '16px')
      .text(this.options.xLabel)

    // Add Label for y axis
    const xPos =
      this.options.context === 'pricing-curve'
        ? -(this.height / 2) + 15
        : -((this.height - 100) / 2)
    this.yAxisSVG
      .append('text')
      .attr('class', 'y label')
      .attr('text-anchor', 'center')
      .attr('x', xPos)
      .attr('y', -60)
      .attr('dy', '.75em')
      .attr('transform', 'rotate(-90)')
      .style('fill', 'white')
      .style('font-size', '16px')
      .text(this.options.yLabel)

    // Create clip path that prevents data from showing outside of graph bounds
    svg
      .append('clipPath')
      .attr('id', 'clip-path')
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0)

    const eventRect = svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all')

    // Create group for zoom with clipping
    this.zoomGroup = svg
      .append('g')
      .attr('clip-path', 'url(#clip-path)')
      .append('g')
      .datum(this.data.flat())

    // Create circles for graph
    this.zoomGroup
      .selectAll('circle')
      .data(this.inputData.pointSets.flat())
      .enter()
      .append('circle')
      .attr('cx', d => this.xScale(this.options.crossValue(d)))
      .attr('cy', d => this.yScale(this.options.mainValue(d)))
      .attr('r', this.circleRadius)
      .attr('class', d => this.options.colorClass(d))
      .attr('fill', 'rgb(var(--rgb))')
      .attr('stroke', d =>
        d.selectedTransactionMember ? '#FFFFFF' : undefined
      )
      .attr('stroke-width', d =>
        d.selectedTransactionMember ? this.highlightRadius : undefined
      )
      .style('pointer-events', 'visibleFill')
      .on('click', (_, d) => this.options.onLayerPointClick?.(d))

    // Line generation function
    this.line = d3
      .line<PricingCurveDatum>()
      .x(d => this.xScale(this.options.crossValue(d)))
      .y(d => this.yScale(this.options.mainValue(d)))

    // Create lines for graph
    this.zoomGroup
      .selectAll('path')
      .data(this.inputData.lineSets)
      .enter()
      .append('path')
      .attr('d', this.line)
      .attr('class', d => this.options.colorClass(d[0]))
      .attr('stroke', 'rgb(var(--rgb))')
      .attr('stroke-width', this.lineWidth)
      .attr('fill', 'none')

    // Create zoom behavior
    this.zoom = d3
      .zoom()
      .filter((event: any) => !event.button) // D3 filters out touchpad zoom, overwrite filter
      .scaleExtent([this.options.zoomMin, this.options.zoomMax])
      .extent([
        [0, 0],
        [this.width, this.height],
      ])
      .translateExtent([
        [0, 0],
        [this.width, this.height],
      ])
      .on('zoom', this.zoomed.bind(this))

    if (this.data.flat().length && !this.options.isExport) {
      eventRect.call(this.zoom)
    }

    // Apply initial zoom
    if (
      this.initialTransform &&
      this.data.flat().length &&
      !this.options.isExport
    ) {
      eventRect.call(this.zoom.transform, this.initialTransform)
    }
  }

  // When the mouse moves on the chart element, see if a tooltip is needed
  mouseMove(event: MouseEvent): void {
    const [x, y] = [
      event.offsetX - this.options.margin.left,
      event.offsetY - this.options.margin.top,
    ]
    if (!this.quadtree) {
      return
    }
    // Find closest point to cursor within radius
    const radius = 8
    const closestDatum = this.quadtree.find(x, y, radius)

    if (closestDatum) {
      this.mouseOver(closestDatum, x, y)
    } else {
      this.mouseOut()
    }
  }

  mouseOver(d: PricingCurveDatum, x: number, y: number): void {
    // Check to see if the tooltip will be offscreen, handle offset accordingly
    const xPos =
      x + 300 > this.width + this.options.margin.left ? x - 250 : x + 75
    const yPos = y - 200 < 0 ? y + 10 : y - 60

    this.tooltip
      .style('opacity', '1')
      .html(this.createTooltipHTMLForDatum(d))
      .style('left', `${xPos}px`)
      .style('top', `${yPos}px`)

    this.hoveredPoint = d
  }

  mouseOut(): void {
    this.tooltip.style('opacity', '0')
    this.hoveredPoint = null
  }

  // Create the tooltip html to display on hover
  createTooltipHTMLForDatum(d: PricingCurveDatum) {
    const isComparison =
      !!this.compareLineDef && d.datasetId === this.compareLineDef.id
    const el = (d.x * 100).toFixed(2)
    const trol = (d.y * 100).toFixed(2)

    return `
        <div class="tooltip-body">
            <div class="tooltip-title">
                <span class="tooltip-line main-title">${d.label}${
                  isComparison ? ' (Comparison)' : ''
                }</span>
            </div>
            <div class="tooltip-details">
                <span class="tooltip-line">${this.options.xLabel}: ${el}% ${
                  this.options.yLabel
                }: ${trol}%</span>
                ${this.createDetailsHTML(d, isComparison)}
            </div>
        </div>
    `
  }

  // Create the details section for the tooltip, different for points, lines, and comparison
  createDetailsHTML(d: PricingCurveDatum, isComparison: boolean): string {
    let html = ''
    if (this.options.context === 'credit' && !d.isLine) {
      html += `<span class="tooltip-line">Prob of Attach: ${d.pAttach} Prob of Detach: ${d.pDetach}</span>`
    }
    if (!isComparison) {
      html = `<span class="comparison-diff">${
        this.options.yLabel
      }% Delta (Comparison): ${this.getDifferenceFromComparisonLine(d).toFixed(
        2
      )}%</span>`
    }
    if (!d.isLine) {
      if (d.layerName) {
        html += `
            <span class="tooltip-line layer-name">${
              this.options.context === 'pricing-curve' ? 'Layer' : 'Tranche'
            }: ${d.layerName}</span>`
      }
      if (d.clientName) {
        html += `<span class="tooltip-line client-name">${
          this.options.context === 'pricing-curve' ? 'Client' : 'Deal'
        }: ${d.clientName}</span>`
      }
      if (d.layerDesc && this.options.context !== 'credit') {
        html += `<span class="tooltip-line layer-name">${d.layerDesc}</span>`
      }
      if (!this.options.isExport && this.options.onLayerPointClick) {
        const layersInSelectedTransaction = this.inputData.pointSets
          .flat()
          .filter(
            point =>
              point.datasetId === this.hoveredPoint?.datasetId &&
              point.dealName === this.hoveredPoint?.dealName
          ).length
        const supplimentalText =
          this.options.context === 'pricing-curve'
            ? '(Click to exclude layer from set)'
            : `(Click to Highlight Tranches in Transaction (Total: ${layersInSelectedTransaction}))`
        html += `<span class="tooltip-line click-text">${supplimentalText}</span>`
      }
    }
    return html
  }

  zoomed(event: any): void {
    const zoomScale = event.transform.k

    // Apply transform and rescale scales
    this.zoomGroup.attr('transform', event.transform)
    this.xScaleZoom = event.transform.rescaleX(this.xScale)
    this.yScaleZoom = event.transform.rescaleY(this.yScale)
    this.updateQuadtree()
    this.xAxisSVG.call(this.xAxis.scale(event.transform.rescaleX(this.xScale)))
    this.yAxisSVG.call(this.yAxis.scale(event.transform.rescaleY(this.yScale)))

    // If there was a transform in the x or y direction, apply to points/lines
    if (!event.transform.x || !event.transform.y) {
      // Divide by the scale so points/lines aren't huge after zoom
      this.zoomGroup
        .selectAll('circle')
        .attr('cx', (d: PricingCurveDatum) =>
          this.xScale(this.options.crossValue(d))
        )
        .attr('cy', (d: PricingCurveDatum) =>
          this.yScale(this.options.mainValue(d))
        )
        .attr('r', this.circleRadius / zoomScale)
        .attr('stroke-width', this.highlightRadius / zoomScale)

      this.zoomGroup
        .selectAll('path')
        .attr('d', this.line)
        .attr('stroke-width', this.lineWidth / zoomScale)
    } else {
      // If there was only a zoom, scale based on the new zoom scale
      this.zoomGroup
        .selectAll('circle')
        .attr('r', this.circleRadius / zoomScale)
        .attr('stroke-width', this.highlightRadius / zoomScale)

      this.zoomGroup
        .selectAll('path')
        .attr('stroke-width', this.lineWidth / zoomScale)
    }
    if (this.options.onZoom) {
      this.options.onZoom({
        x: event.transform.x,
        y: event.transform.y,
        zoomFactor: event.transform.k,
      })
    }
  }

  updateCoordScales(): void {
    const baseData = this.data.flat()

    const xPad = this.options.extents.cross.pad
    const xExtents = fc
      .extentLinear()
      .accessors([this.options.crossValue])
      .include(this.options.extents.cross.include)
      .pad(xPad)
      .padUnit(this.options.extents.cross.padUnit)(baseData)

    if (!this.xScale) {
      this.xScale = scaleLinear().domain(xExtents).range([0, this.width]).nice()
    }
    this.xScaleZoom = this.xScale.copy()
    if (this.initialTransform) {
      this.xScaleZoom = this.initialTransform.rescaleX(this.xScaleZoom)
    }

    const yPad = this.options.extents.main.pad
    const yExtents = fc
      .extentLinear()
      .accessors([this.options.mainValue])
      .include(this.options.extents.main.include)
      .pad(yPad)
      .padUnit(this.options.extents.main.padUnit)(baseData)

    if (!this.yScale) {
      this.yScale = scaleLinear()
        .domain(yExtents)
        .range([this.height, 0])
        .nice()
    }
    this.yScaleZoom = this.yScale.copy()
    if (this.initialTransform) {
      this.yScaleZoom = this.initialTransform.rescaleY(this.yScaleZoom)
    }
  }

  private updateQuadtree(): void {
    const data = this.data.flatMap((ds, i) => ds.map(d => ({ ...d, i })))
    this.quadtree = d3Quadtree<WithIndex<PricingCurveDatum>>()
      .x(d => this.xScaleZoom(this.options.crossValue(d)))
      .y(d => this.yScaleZoom(this.options.mainValue(d)))
      .addAll(data)
  }

  private getDifferenceFromComparisonLine(point: PricingCurveDatum): number {
    const comparisonLine = this.compareLineDef

    const x = point.x
    let y = 0

    if (comparisonLine?.technicalFactors) {
      const { slope, intercept, isPowerCurve } = comparisonLine

      if (slope != null && intercept != null) {
        y = isPowerCurve
          ? intercept * Math.pow(x, slope)
          : slope * x + intercept
      }
    }

    return (point.y - y) * 100
  }
}
