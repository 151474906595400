import {
  createDataFilterer,
  DataFilterConfig,
} from '../../data-filter/data-filterer'
import { Selections } from '../../util/selections'
import { SortTableColumnView, SortTableRow } from '../sort-table.model'

export class SortTableColumnFilter {
  valueSet: Set<string | number | boolean>
  hasBlanks: boolean
  selections = new Selections()

  get values(): (string | number | boolean)[] {
    const vs = [...this.valueSet]
    return this.comparator ? vs.sort(this.comparator) : vs.sort()
  }

  get comparator():
    | ((a: string | number | boolean, b: string | number | boolean) => number)
    | undefined {
    return this.column.filter != null && typeof this.column.filter !== 'boolean'
      ? this.column.filter.comparator
      : undefined
  }

  get selectedValues(): (string | number | boolean)[] {
    return this.selections.getSelected(this.values)
  }

  get enabled(): boolean {
    return !this.selections.allSelected
  }

  constructor(public column: SortTableColumnView) {
    this.hasBlanks = false
    this.valueSet = new Set()
  }

  add(value: any): void {
    if (value) {
      this.valueSet.add(value)
    } else {
      this.valueSet.add(SortTableFilter.BLANK)
      this.hasBlanks = true
    }
  }

  update(): void {
    this.selections.update(this.values)
  }

  selectAll(value = true): void {
    this.selections.setAll(this.values, value)
  }

  match(value: any): boolean {
    if (!this.enabled) {
      return true
    }

    for (const v of this.selectedValues) {
      if (v === SortTableFilter.BLANK) {
        if (String(value).trim() === '' || value == null) {
          return true
        }
      } else {
        if (String(value).toLowerCase() === String(v).toLowerCase()) {
          return true
        }
      }
    }
    return false
  }
}

export class SortTableFilter<T> {
  static BLANK = '__blank__'
  static EMPTY_SYMBOL = '◬'

  private filterableColumns: SortTableColumnView<T>[]
  private filterMap: Record<string, SortTableColumnFilter>

  get filters(): SortTableColumnFilter[] {
    return Object.keys(this.filterMap).map(id => this.filterMap[id])
  }

  constructor(
    public filterConfig: DataFilterConfig<SortTableRow<T>>,
    columns?: SortTableColumnView<T>[],
    rows?: SortTableRow<T>[]
  ) {
    if (columns && rows) {
      this.setTableModel(columns, rows)
      return
    }

    this.filterableColumns = []
    this.filterMap = {}
  }

  setTableModel(columns: SortTableColumnView<T>[], rows: SortTableRow<T>[]) {
    this.filterableColumns = columns.filter(col => col.filter != null)

    this.filterMap = rows.reduce((outerMap, row) => {
      return this.filterableColumns.reduce((filters, col) => {
        const colID = col.id as keyof T & string
        if (filters[colID] == null) {
          filters[colID] = new SortTableColumnFilter(col)
        }
        filters[colID].add(row[colID])
        return filters
      }, outerMap)
    }, {} as Record<string, SortTableColumnFilter>)

    this.filters.forEach(f => f.selectAll())
  }

  getColumn(columnID: string): SortTableColumnFilter {
    return this.filterMap[columnID]
  }

  createFilterPredicate(filterText: string): FilterPredicate<SortTableRow<T>> {
    const filterData =
      filterText !== SortTableFilter.EMPTY_SYMBOL
        ? createDataFilterer(filterText, this.filterConfig)
        : null

    return data => {
      // Test the row against the filter text unless it is empty
      if (filterData && !filterData(data).isMatch) {
        return false
      }

      for (const col of this.filterableColumns) {
        const filter = this.filterMap[col.id as string]
        const value = data[col.id]
        if (!filter.match(value)) {
          return false
        }
      }

      return true
    }
  }
}

type FilterPredicate<T> = (data: T, filterText: string) => boolean
