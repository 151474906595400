import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { reduceReducers } from '@shared/util/reduce-reducers'
import {
  BLANK_CURVE,
  BasicControl,
  CREDIT_CURVE_X_DEFINITIONS,
  CREDIT_CURVE_Y_DEFINITIONS,
  DIALOG_DATA_INITIAL_STATE,
  DialogData,
  GRAPH_SETTINGS_INITIAL_STATE,
  PRICING_CURVE_STATUS_DEFAULT_STATE,
  PRICING_CURVE_X_DEFINITION,
  PRICING_CURVE_Y_DEFINITION,
  PricingCurveContextTypes,
  PricingCurveData,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SAVED_CURVE_SELECTOR_INITIAL_STATE,
  SavedCurveSelectors,
  SavedCurveSelectorsDto,
  SavedPricingCurveEntry,
  SelectedTransaction,
} from '../model/pricing-curve.model'
import * as fromActions from './pricing-curve.actions'
import {
  RGBAToHex,
  clearAllFilters,
  onlyUnique,
  resetDateInterval,
  resetSelector,
  updateCurveDataProperties,
  updateDateInterval,
  updateSelectors,
} from '../pricing-curve.utils'
import { IControl } from '../model/pricing-curve.model'
import { recalculateTechnicalPremiumForLayersSuccess } from 'src/app/analysis/store/technical-premium/technical-premium.actions'

export const PRICING_CURVE_FEATURE_KEY = 'pricingcurve'

export type PricingCurveState = {
  savedCurveSelectors: SavedCurveSelectors
  addDataDialog: DialogData
  workingCurve: PricingCurveData
  pricingCurves: PricingCurveData[]
  status: PricingCurveStatus
  savedCurves: SavedPricingCurveEntry[]
  context: PricingCurveContextTypes
  graphSettings: PricingCurveGraphSettings
  selectedTransaction: SelectedTransaction | null
}

export const initialState: PricingCurveState = {
  savedCurveSelectors: SAVED_CURVE_SELECTOR_INITIAL_STATE,
  addDataDialog: DIALOG_DATA_INITIAL_STATE,
  workingCurve: BLANK_CURVE,
  pricingCurves: [],
  status: PRICING_CURVE_STATUS_DEFAULT_STATE,
  savedCurves: [],
  context: 'pricing-curve',
  graphSettings: GRAPH_SETTINGS_INITIAL_STATE,
  selectedTransaction: null,
}

const setPrevReducer = createReducer(initialState)

const savedPricingCurvesReducer = createReducer(
  initialState.savedCurves,
  on(fromActions.fetchSavedCurvesSuccess, (_, { data }) => data)
)

const workingCurveReducer = createReducer(
  initialState.workingCurve,
  on(fromActions.addDataDialogOpened, (_, { context }) => ({
    ...BLANK_CURVE,
    isEdit: true,
    initialSetup: true,
    context,
  })),
  on(fromActions.editDataDialogOpened, (_, { curveData }) => ({
    ...curveData,
    isEdit: true,
  })),
  on(fromActions.fetchSelectorsSuccess, (state, { combinedSelectors }) => ({
    ...state,
    dateIntervals: combinedSelectors.dateIntervals,
    selectors: combinedSelectors.selectors,
  })),
  on(fromActions.fetchLayersSuccess, (state, { layers, creditLayers }) => ({
    ...state,
    layers: layers ?? state.layers,
    creditLayers: creditLayers ?? state.creditLayers,
  })),
  on(fromActions.updateWorkingCurveData, (state, { curveData: newProps }) => ({
    ...state,
    ...newProps,
  })),
  on(fromActions.filtersChanged, (state, { filters }) => ({
    ...state,
    selectors: updateSelectors(state.selectors, filters),
  })),
  on(fromActions.updateDateIntervalValues, (state, { data, isMin }) => ({
    ...state,
    dateIntervals: updateDateInterval(state.dateIntervals, data, isMin),
  })),
  on(fromActions.clearDateInterval, (state, { columnName, isMin }) => ({
    ...state,
    dateIntervals: resetDateInterval(state.dateIntervals, columnName, isMin),
  })),
  on(fromActions.cleanFilters, state => ({
    ...state,
    ...clearAllFilters(state.selectors, state.dateIntervals),
  })),
  on(fromActions.clearFilter, (state, { columnName }) => ({
    ...state,
    selectors: resetSelector(state.selectors, columnName),
  }))
)

const addDataDialogReducer = createReducer(
  initialState.addDataDialog,
  on(fromActions.addDataDialogClosed, state => ({
    ...DIALOG_DATA_INITIAL_STATE,
    creditCarriers: state.creditCarriers,
  })),
  on(fromActions.editDataDialogOpened, state => ({ ...state, editMode: true })),
  on(fromActions.editDataDialogClosed, state => ({
    ...DIALOG_DATA_INITIAL_STATE,
    editMode: false,
    creditCarriers: state.creditCarriers
  })),
  on(fromActions.updatePreviouslyClickedId, (state, { setId, layerId }) => ({
    ...state,
    previouslyClickedId: {
      pricingCurveId: setId,
      layerId,
    },
  })),
  on(
    fromActions.fetchSavedCurvesSuccess,
    fromActions.fetchFilteredSavedCurvesSuccess,
    (state, { data }) => ({
      ...state,
      savedCurves: data,
    })
  ),
  on(fromActions.updateAddDataDialogTab, (state, { tab }) => ({
    ...state,
    tab,
  })),
  on(fromActions.initCreditCarriersSuccess, (state, { carriers }) => {
    const newState = { ...state }

    if (carriers.length) {
      newState.creditCarriers = carriers.concat('Demo')
    }
    return newState
  })
)

const curveReducer = createReducer(
  initialState.pricingCurves,
  on(fromActions.addCurveToGraph, (state, { curveData }) => {
    const newState = [...state]
    // If the curve has a position already, add back to that index
    if (curveData.cardIndex >= 0) {
      newState.splice(curveData.cardIndex, 0, curveData)
    } else {
      newState.push(curveData)
    }
    // Assign index to all curves
    return newState.map((curve, cardIndex) => ({
      ...curve,
      cardIndex,
      isEdit: false,
      initialSetup: false,
    }))
  }),
  on(fromActions.editDataDialogOpened, (state, { curveData }) =>
    updateCurveDataProperties(state, curveData.id, { isEdit: true })
  ),
  on(fromActions.updatePricingCurve, (state, { id, curveData }) =>
    updateCurveDataProperties(state, id, {
      ...curveData,
      isEdit: false,
    })
  ),
  on(
    fromActions.savePricingCurve,
    fromActions.submitCreditCurve,
    (state, { curve }) => {
      const newState = [...state]
      const workingIndex = newState.findIndex(
        stateCurve => stateCurve.id === curve.id
      )
      if (workingIndex >= 0) {
        newState.splice(workingIndex, 1)
      }
      return newState
    }
  ),
  on(fromActions.removePricingCurveFromGraph, (state, { id }) => {
    const newState = [...state]
    const index = newState.findIndex(curve => curve.id === id)
    if (index >= 0) {
      newState.splice(index, 1)
    }
    return newState
  }),
  on(
    fromActions.updateCurveVisibility,
    (state, { id, layersVisible, lineVisible, applyAll }) =>
      updateCurveDataProperties(
        state,
        id,
        {
          visibilityOptions: { lineVisible, layersVisible },
        },
        applyAll
      )
  ),
  on(fromActions.reorderCards, (state, { previousIndex, newIndex }) => {
    const newState = [...state]
    const curve = newState[previousIndex]
    newState.splice(previousIndex, 1)
    newState.splice(newIndex, 0, curve)

    return newState.map((curve, index) => ({ ...curve, index }))
  }),
  on(fromActions.updateDataSetExportRGBValue, (state, { id, rgb }) => {
    const layers = [...state]
    const extractRgb = Array.from(rgb.match(/[0-9]+[.0-9]*/g) ?? [])
    if (extractRgb.length !== 4) {
      return state
    }
    const mappedRgb = extractRgb.map(item => Number.parseFloat(item))
    const graphColorRgb = RGBAToHex(mappedRgb, false)
    return updateCurveDataProperties(layers, id, { graphColorRgb })
  }),
  on(fromActions.resetModuleToDefaultState, () => [] as PricingCurveData[])
)

const savedCurveSelectorReducer = createReducer(
  initialState.savedCurveSelectors,
  on(fromActions.initSavedCurveSelectors, (state, { selectors, names }) => {
    const newState = { ...state }
    const definedSelectors = selectors
      .filter(sel => !!sel)
      .map(sel => sel.selectors)

    const allValuesMap: SavedCurveSelectorsDto = {
      layerCategory: definedSelectors
        .flatMap(value => value.layerCategory.selectedValues ?? [])
        .filter(onlyUnique),
      pcClass: definedSelectors
        .flatMap(value => value.pcClass.selectedValues ?? [])
        .filter(onlyUnique),
      pcSubClass: definedSelectors
        .flatMap(value => value.pcSubClass.selectedValues ?? [])
        .filter(onlyUnique),
      fullName: names.filter(onlyUnique),
    }

    const { updateDate, ...restSelectors } = newState

    for (const selectorKey of Object.keys(restSelectors)) {
      const entry = newState[
        selectorKey as keyof SavedCurveSelectors
      ] as IControl
      const allValues =
        allValuesMap[selectorKey as keyof SavedCurveSelectorsDto]
      newState[selectorKey as keyof SavedCurveSelectors] = {
        ...entry,
        values: allValues,
        allValues,
      }
    }

    return newState
  }),
  on(fromActions.savedCurveFiltersChanged, (state, { filters }) => {
    if (!filters) {
      return state
    }
    const newState = { ...state }

    const { updateDate, ...newSelectors } = newState
    const entries: [string, IControl][] = Object.entries(newSelectors)

    for (const [selectorKey, selector] of entries) {
      const values =
        filters[selectorKey] && filters[selectorKey].length > 0
          ? filters[selectorKey]
          : (selector.values ?? [])
      newState[selectorKey as keyof SavedCurveSelectors] = {
        ...selector,
        selectedValues: filters[selectorKey] ?? [],
        values,
      }
    }
    return newState as SavedCurveSelectors
  }),
  on(fromActions.savedCurveIntervalFilterChanged, (state, { data, isMin }) => {
    const newState = { ...state }
    const { updateDate } = newState

    const newEntry: BasicControl = {
      ...updateDate,
      name: data.filterId ?? updateDate.name,
      value: updateDate.value,
      minValue:
        isMin && data.newMinValue ? data.newMinValue : updateDate.minValue,
      maxValue:
        !isMin && data.newMaxValue ? data.newMaxValue : updateDate.maxValue,
    }

    return {
      ...newState,
      updateDate: newEntry,
    }
  }),
  on(
    fromActions.clearSavedCurveDateInterval,
    (state, { columnName, isMin }) => {
      const newState = { ...state }
      const { updateDate } = newState

      const newEntry: BasicControl = {
        ...updateDate,
        columnName: columnName ?? updateDate.columnName,
        name: columnName ?? updateDate.name,
        value: updateDate.value,
        minValue: isMin ? undefined : updateDate.minValue,
        maxValue: !isMin ? undefined : updateDate.maxValue,
      }

      return {
        ...newState,
        updateDate: newEntry,
      }
    }
  ),
  on(fromActions.clearSavedCurveFilter, (state, { columnName }) => {
    const selectorFromState = (state as any)[columnName] as IControl
    const newState = { ...state }

    newState[columnName as keyof SavedCurveSelectors] = {
      ...selectorFromState,
      selectedValues: [],
      values: selectorFromState.allValues,
    }

    return newState
  }),
  on(fromActions.addDataDialogClosed, (state, { isTechPremium }) => {
    if (isTechPremium) {
      return state
    }
    const newState = { ...state }
    const { updateDate, ...restFilters } = newState

    for (const [key, value] of Object.entries(restFilters)) {
      newState[key as keyof SavedCurveSelectors] = {
        ...(value as any),
        selectedValues: [],
      }
    }

    newState['updateDate'] = {
      ...updateDate,
      minValue: undefined,
      maxValue: undefined,
      value: undefined,
    }

    return newState
  }),
  on(fromActions.resetSavedCurveSelectors, state => {
    const newState = { ...state }
    const { updateDate, ...restFilters } = newState

    for (const [key, value] of Object.entries(restFilters)) {
      newState[key as keyof SavedCurveSelectors] = {
        ...value,
        selectedValues: [],
      }
    }

    newState['updateDate'] = {
      ...updateDate,
      minValue: undefined,
      maxValue: undefined,
      value: undefined,
    }

    return newState
  })
)

const graphSettingsReducer = createReducer(
  initialState.graphSettings,
  on(fromActions.initPricingCurveData, (_, { context }) => {
    if (context === 'credit') {
      return {
        xAxisDefinition: CREDIT_CURVE_X_DEFINITIONS[0],
        yAxisDefinition: CREDIT_CURVE_Y_DEFINITIONS[1],
      }
    } else {
      return {
        xAxisDefinition: PRICING_CURVE_X_DEFINITION,
        yAxisDefinition: PRICING_CURVE_Y_DEFINITION,
      }
    }
  }),
  on(fromActions.updateGraphSettings, (_, { graphSettings }) => graphSettings)
)

const pricingCurveStatusReducer = createReducer(
  initialState.status,
  on(
    fromActions.initPricingCurveData,
    fromActions.loadSavedPricingCurve,
    fromActions.getLayerDetailsAndRecalculateForLayersUsingCurve,
    state => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    fromActions.addCurveToGraph,
    fromActions.loadSavedPricingCurveFailure,
    fromActions.savePricingCurveFailure,
    fromActions.savePricingCurveSuccess,
    fromActions.fetchSavedCurvesSuccess,
    recalculateTechnicalPremiumForLayersSuccess,
    (state: PricingCurveStatus) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    fromActions.addDataDialogOpened,
    fromActions.filtersChanged,
    fromActions.fetchSelectors,
    fromActions.fetchLayers,
    fromActions.fetchSavedCurves,
    state => ({
      ...state,
      dialogLoading: true,
    })
  ),
  on(
    fromActions.fetchLayersSuccess,
    fromActions.fetchLayersFailure,
    fromActions.addDataDialogClosed,
    fromActions.editDataDialogClosed,
    fromActions.fetchSavedCurvesSuccess,
    fromActions.fetchSavedCurvesFailure,
    state => ({
      ...state,
      dialogLoading: false,
    })
  ),
  on(fromActions.updateIsSavingLayers, (state, { newValue }) => ({
    ...state,
    isSavingLayers: newValue,
  })),
  on(fromActions.savePricingCurveFailure, state => ({
    ...state,
    isSavingLayers: false,
  }))
)

const contextReducer = createReducer(
  initialState.context,
  on(
    fromActions.initPricingCurveData,
    fromActions.updatePricingCurveContext,
    (_, { context }) => context
  )
)

const selectedTransactionReducer = createReducer(
  initialState.selectedTransaction,
  on(fromActions.updateSelectedCreditTransaction, (state, { details }) =>
    state?.dealName !== details.dealName ? details : (null as any)
  ),
  on(fromActions.resetModuleToDefaultState, () => null as any)
)

const _pricingCurveReducer = combineReducers<PricingCurveState>({
  addDataDialog: addDataDialogReducer,
  pricingCurves: curveReducer,
  workingCurve: workingCurveReducer,
  savedCurveSelectors: savedCurveSelectorReducer,
  status: pricingCurveStatusReducer,
  savedCurves: savedPricingCurvesReducer,
  context: contextReducer,
  graphSettings: graphSettingsReducer,
  selectedTransaction: selectedTransactionReducer,
})

const pricingCurveReducer = reduceReducers(setPrevReducer, _pricingCurveReducer)

export function reducer(
  state: PricingCurveState | undefined,
  action: Action
): PricingCurveState {
  return pricingCurveReducer(state, action)
}
