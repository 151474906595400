import { Injectable } from '@angular/core'
import Fuse from 'fuse.js'
import { assoc, dissoc, values } from 'ramda'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map, shareReplay, startWith } from 'rxjs/operators'
import { HierarchicalEntity } from './hierarchical-entity-select.component'
import { conditionalDebounceTime } from './util/operators'

export type HierarchicalEntityResult<
  T extends HierarchicalEntity = HierarchicalEntity
> = Fuse.FuseResult<T> & {
  filteredCount?: number
  refIndex?: number
}

interface HierarchicalDictSubGroup<
  T extends HierarchicalEntity = HierarchicalEntity
> {
  subGroup: HierarchicalEntityResult<T>
  results: HierarchicalEntityResult<T>[]
}

interface HierarchicalDictGroup<
  T extends HierarchicalEntity = HierarchicalEntity
> {
  group: HierarchicalEntityResult<T>
  subGroupOrder: string[]
  subGroups: Record<string, HierarchicalDictSubGroup<T>>
}

interface HierarchicalDict<T extends HierarchicalEntity = HierarchicalEntity> {
  groupOrder: string[]
  groups: Record<string, HierarchicalDictGroup<T>>
}

export interface HierarchicalEntitySelectState<
  T extends HierarchicalEntity = HierarchicalEntity
> {
  entities: HierarchicalEntityResult<T>[]
  expandedByID: Record<string, boolean>
  filtered: boolean
}

interface Entities<T> {
  byID: Record<string, T>
  ids: string[]
}

const SEARCH_OPTS: Fuse.IFuseOptions<HierarchicalEntity> = {
  includeMatches: true,
  includeScore: false,
  minMatchCharLength: 2,
  threshold: 0.3,
  keys: ['name'],
}

/**
 * TODO: Add Cursor w/ keyboard support
 */
@Injectable()
export class HierarchicalEntitySelectService<
  T extends HierarchicalEntity = HierarchicalEntity
> {
  private searcher: Fuse<HierarchicalEntity>
  private _entities: T[] = []
  private _entityDict = new Map<string, HierarchicalEntityResult<T>>()
  private _childrenDict = new Map<string, HierarchicalEntityResult<T>[]>()
  private _expandedByID$ = new BehaviorSubject<Record<string, boolean>>({})
  private _selectedByID$ = new BehaviorSubject<Record<string, T>>({})
  private _filterTerm$ = new BehaviorSubject<string>('')

  multiple = true
  filterDebounceMS = 100
  state$: Observable<HierarchicalEntitySelectState<T>>
  selectedByID$: Observable<Record<string, T>>
  selectedEntities$: Observable<T[]>
  relatedIDs$: Observable<Set<string>>
  filterTerm$: Observable<string>
  hasFilter$: Observable<boolean>

  constructor() {
    this.initSearcher()
    this._expandedByID$.next({})
    this.selectedByID$ = this._selectedByID$.asObservable()
    this.selectedEntities$ = this._selectedByID$.asObservable().pipe(
      map(dict => values(dict)),
      shareReplay(1)
    )
    this.filterTerm$ = this._filterTerm$.asObservable().pipe(shareReplay(1))
    this.hasFilter$ = this.filterTerm$.pipe(
      map(term => term != null && term.length > 0),
      shareReplay(1)
    )

    this.state$ = combineLatest([
      this._expandedByID$,
      this.filterTerm$.pipe(
        startWith(''),
        // If filter term is blank, do not debounce, immediately continue
        conditionalDebounceTime(term => term.length > 0, this.filterDebounceMS)
      ),
    ]).pipe(
      map(([expanded, filterTerm]) => this.updateEntities(expanded, filterTerm))
    )

    this.relatedIDs$ = this.selectedEntities$.pipe(
      map(entities => this.getRelatedIDs(entities))
    )
  }

  setEntities(entities: T[]): void {
    this._entities = entities
    this._entityDict = new Map()
    this._childrenDict = new Map()
    for (let refIndex = 0; refIndex < entities.length; refIndex++) {
      const item = entities[refIndex]
      const id = String(item.id)
      // @ts-ignore
      const result = { item, refIndex, matches: [] }
      this._entityDict.set(id, result)

      if (item.level === 2) {
        if (!this._childrenDict.has(item.groupID)) {
          this._childrenDict.set(item.groupID, [])
        }
        this._childrenDict.get(item.groupID)?.push(result)
      } else if (item.level === 3 && item.subGroupID) {
        if (!this._childrenDict.has(item.subGroupID)) {
          this._childrenDict.set(item.subGroupID, [])
        }
        this._childrenDict.get(item.subGroupID)?.push(result)
      }
    }

    this.initSearcher()
  }

  setSelected(selected: T[]): void {
    this._selectedByID$.next(
      selected.reduce((acc, e) => ({ ...acc, [e.id]: e }), {})
    )
  }

  setFilterTerm(filterTerm: string): void {
    this._filterTerm$.next(filterTerm)
  }

  setExpandedByID(expandedByID: Record<string, boolean>) {
    this._expandedByID$.next(expandedByID)
  }

  select(
    entity: T,
    { exclusive }: { exclusive?: boolean } = {}
  ): Record<string, T> {
    let state = this._selectedByID$.value
    const id = String(entity.id)
    if (exclusive) {
      state = { [id]: entity }
      this._selectedByID$.next(state)
      return state
    }
    const isSelected = state[entity.id] != null
    state = isSelected ? dissoc(id, state) : assoc(id, entity, state)

    if (!isSelected) {
      // Deselect any ancestors
      if (entity.level === 2) {
        state = dissoc(entity.groupID, state)
      }
      if (entity.level === 3 && entity.subGroupID != null) {
        state = dissoc(entity.groupID, state)
        state = dissoc(entity.subGroupID, state)
      }
      // Deselect any descendents
      const ids = this.getDescendentIDs(entity)
      state = ids.reduce((acc, childID) => dissoc(childID, acc), state)
    }

    this._selectedByID$.next(state)
    return state
  }

  deselect(entity: T): void {
    const state = this._selectedByID$.value
    const id = String(entity.id)
    this._selectedByID$.next(dissoc(id, state))
  }

  toggleExpand(entity: T): void {
    const state = this._expandedByID$.value
    const id = entity.id
    this._expandedByID$.next({ ...state, [id]: !(state[id] ?? false) })
  }

  private initSearcher() {
    this.searcher = new Fuse(this._entities, SEARCH_OPTS)
  }

  private getRelatedIDs(entities: T[]): Set<string> {
    const relatedIDs = new Set<string>()
    for (const item of entities) {
      this.getDescendentIDs(item).forEach(id => relatedIDs.add(id))
      if (item.level === 2) {
        relatedIDs.add(item.groupID)
      }
      if (item.level === 3 && item.subGroupID != null) {
        relatedIDs.add(item.subGroupID)
      }
    }
    return relatedIDs
  }

  private updateEntities(
    expandedByID: Record<string | number, boolean>,
    filterTerm: string
  ): HierarchicalEntitySelectState<T> {
    const filtered = filterTerm.length > 1
    const result = filtered
      ? this.filterEntities(expandedByID, filterTerm)
      : this.getExpandedEntities(expandedByID)

    const entities = result.ids.map(id => result.byID[id])
    return { entities, expandedByID, filtered }
  }

  private getExpandedEntities(
    expandedByID: Record<string | number, boolean>
  ): Entities<HierarchicalEntityResult<T>> {
    const result: Entities<HierarchicalEntityResult<T>> = { byID: {}, ids: [] }
    const entities: HierarchicalEntityResult<T>[] = []

    function isExpanded(item: T) {
      switch (item.level ?? 1) {
        case 1: {
          return true
        }
        case 2: {
          return expandedByID[item.groupID] === true
        }
        case 3: {
          return (
            expandedByID[item.groupID] === true &&
            expandedByID[item.subGroupID ?? '__na'] === true
          )
        }
      }
    }

    for (let refIndex = 0; refIndex < this._entities.length; refIndex++) {
      const item = this._entities[refIndex]
      if (isExpanded(item)) {
        result.ids.push(String(item.id))
        result.byID[item.id] = { item, refIndex, matches: [] }
        entities.push({ item, refIndex, matches: [] })
      }
    }

    return result
  }

  private filterEntities(
    expandedByID: Record<string | number, boolean>,
    filterTerm: string
  ): Entities<HierarchicalEntityResult<T>> {
    const result: Entities<HierarchicalEntityResult<T>> = { byID: {}, ids: [] }
    const matches = this.searcher.search<T>(filterTerm, {
      limit: 100,
    })

    // tslint:disable: no-non-null-assertion
    const filteredDict = matches.reduce(
      (acc, res) => {
        const id = String(res.item.id)
        switch (res.item.level ?? 1) {
          case 1: {
            if (!acc.groups[id]) {
              acc.groupOrder.push(id)
              acc.groups[id] = {
                group: res,
                subGroupOrder: [],
                subGroups: {},
              }
            }
            acc.groups[id].group = res
            return acc
          }
          case 2: {
            if (!acc.groups[res.item.groupID]) {
              acc.groupOrder.push(String(res.item.groupID))
              acc.groups[res.item.groupID] = {
                group: this._entityDict.get(res.item.groupID)!,
                subGroupOrder: [],
                subGroups: {},
              }
            }
            if (!acc.groups[res.item.groupID].subGroups[id]) {
              acc.groups[res.item.groupID].subGroupOrder.push(id)
              acc.groups[res.item.groupID].subGroups[id] = {
                subGroup: res,
                results: [],
              }
            }
            acc.groups[res.item.groupID].subGroups[id].subGroup = res
            return acc
          }
          case 3: {
            if (!acc.groups[res.item.groupID]) {
              acc.groupOrder.push(String(res.item.groupID))
              acc.groups[res.item.groupID] = {
                group: this._entityDict.get(res.item.groupID)!,
                subGroupOrder: [],
                subGroups: {},
              }
            }
            const subGroupID = res.item.subGroupID ?? '__na'
            if (!acc.groups[res.item.groupID].subGroups[subGroupID]) {
              acc.groups[res.item.groupID].subGroupOrder.push(subGroupID)
              acc.groups[res.item.groupID].subGroups[subGroupID] = {
                subGroup: this._entityDict.get(subGroupID)!,
                results: [],
              }
            }
            acc.groups[res.item.groupID].subGroups[subGroupID].results.push(res)
            return acc
          }
        }
      },
      { groupOrder: [], groups: {} } as HierarchicalDict<T>
    )
    // tslint:enable: no-non-null-assertion

    const filteredEntities: HierarchicalEntityResult<T>[] = []
    for (const groupID of filteredDict.groupOrder) {
      const groupDict = filteredDict.groups[groupID]
      const groupResults: HierarchicalEntityResult<T>[] = []
      const groupIDMap = new Set<string | number>()

      for (const subGroupID of groupDict.subGroupOrder) {
        const subGroupDict = groupDict.subGroups[subGroupID]
        const subGroupResults: HierarchicalEntityResult<T>[] = []
        const subGroupIDMap = new Set<string | number>()

        for (const subGroupCompanies of subGroupDict.results) {
          subGroupResults.push(subGroupCompanies)
          subGroupIDMap.add(subGroupCompanies.item.id)
        }

        if (expandedByID[subGroupID]) {
          const children = this._childrenDict.get(subGroupID)
          for (const child of children ?? []) {
            if (!subGroupIDMap.has(child.item.id)) {
              subGroupResults.push(child)
            }
          }
        }

        groupResults.push({
          ...subGroupDict.subGroup,
          filteredCount: subGroupResults.length,
        })
        groupResults.push(...subGroupResults)
        groupIDMap.add(subGroupDict.subGroup.item.id)
      }

      if (expandedByID[groupID]) {
        for (const child of this._childrenDict.get(groupID) ?? []) {
          const subGroupID = String(child.item.id)
          if (!groupIDMap.has(subGroupID)) {
            groupResults.push(child)
            if (expandedByID[subGroupID]) {
              for (const subchild of this._childrenDict.get(subGroupID) ?? []) {
                groupResults.push(subchild)
              }
            }
          }
        }
      }

      result.ids.push(String(groupDict.group.item.id))
      result.byID[groupDict.group.item.id] = {
        ...groupDict.group,
        filteredCount: groupResults.length,
      }
      filteredEntities.push({
        ...groupDict.group,
        filteredCount: groupResults.length,
      })

      result.ids.push(...groupResults.map(r => String(r.item.id)))
      groupResults.forEach(r => (result.byID[r.item.id] = r))
      filteredEntities.push(...groupResults)
    }

    return result
  }

  private getDescendentIDs(entity: T): string[] {
    const children = this._childrenDict.get(String(entity.id)) ?? []
    switch (entity.level ?? 1) {
      case 1:
        return children.reduce((acc, child) => {
          acc.push(String(child.item.id))
          acc.push(...this.getDescendentIDs(child.item))
          return acc
        }, [] as string[])
      case 2:
        return children.map(child => String(child.item.id))
      case 3:
        return []
    }
  }
}
