<div class="table-wrapper">
  <ng-container>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="clientName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by client name"
        >
          Client Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.clientName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="layerName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by layer name"
        >
          Layer Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.layerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="trol">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by trol"
        >
          TROL %
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.trol | percent: '1.0-3' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="el">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by el"
        >
          EL %
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.el | percent: '1.0-3' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by deal name"
        >
          Deal Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.clientName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="trancheName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by tranche name"
        >
        Tranche Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.layerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="prediction">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by predicted premium"
        >
          Predicted Premium
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.trol | percent: '1.0-3' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="premium">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by premium"
        >
          Premium
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.el | percent: '1.0-3' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="prob_of_attach">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by probability of attach"
        >
          P(att)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.prob_of_attach | percent: '1.0-3' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="prob_of_exhaust">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by probability of exhaust"
        >
          P(exh)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.props.prob_of_exhaust | percent: '1.0-3' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let tableData; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      class="paginator"
      showFirstLastButtons
      pageSize="10"
    ></mat-paginator>
  </ng-container>
</div>
