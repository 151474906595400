import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  AgencyDetails,
  AgencyDetailsSage,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../core/store/index'
import {
  selectCurrentClientStudies,
  selectCurrentStudyID,
} from '../../core/store/broker/broker.selectors'
import { Study } from '../../core/model/study.model'
import {
  selectSelectedStudyReinsurers,
  selectStudyReinsurersSaving,
} from '../store/reinsurers.selectors'
import {
  removeAgencySeqFromReinsurerDirty,
  saveAgencyDetails,
  setUpdateOneReinsurer,
  updateOrAddStudiesReinsurerDirty,
  updateReinsurer,
} from '../store/study-reinsurers.actions'
import { selectAccountOpportunities } from 'src/app/core/store/accountopportunity.selectors'
import { AccountOpportunity } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-agency-detail-dialog-container',
  templateUrl: './agency-detail.container.html',
})
export class AgencyDetailDialogContainerComponent implements OnInit {
  studyID$: Observable<string | null>
  reinsurers$: Observable<Reinsurer[] | null>
  studies$: Observable<readonly Study[]>
  savingReinsurer$: Observable<boolean>
  accountOpportunities$: Observable<AccountOpportunity[]>

  constructor(
    public dialogRef: MatDialogRef<AgencyDetailDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reinsurer: Reinsurer
      select: string
      agencyDetails: AgencyDetails[]
    },
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.studyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.reinsurers$ = this.store.pipe(select(selectSelectedStudyReinsurers))
    this.studies$ = this.store.pipe(select(selectCurrentClientStudies))
    this.savingReinsurer$ = this.store.pipe(select(selectStudyReinsurersSaving))
    this.accountOpportunities$ = this.store.pipe(select(selectAccountOpportunities))
  }

  onSetReinsurer(value: { programID: string; reinsurer: Reinsurer }): void {
    this.store.dispatch(setUpdateOneReinsurer(value))
  }

  onUpdateOrAddDirty(reinsurer: Reinsurer): void {
    this.store.dispatch(updateOrAddStudiesReinsurerDirty({ reinsurer }))
  }

  onDeselectAgencyRelationship(details: AgencyDetails): void {
    this.store.dispatch(removeAgencySeqFromReinsurerDirty({agencyTPRef: details.agencyTPRef, sequenceNumber: details.agencySeqNumber}))
  }

  onSaveClick(): void {
    this.store.dispatch(updateReinsurer())
  }

  onSaveNewOBO(agencyDetails: AgencyDetailsSage[]): void {
    this.store.dispatch(saveAgencyDetails({ agencyDetails }))
  }
}
