import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { Layer } from 'src/app/analysis/model/layers.model'
import { startAnalysis } from 'src/app/analysis/store/analysis.actions'
import * as fromLayersActions from 'src/app/analysis/store/ceded-layers/layers.actions'
import {
  fetchLayersViewMetricsFailureRP,
  fetchLayersViewMetricsRP,
  fetchLayersViewMetricsSuccessRP,
} from 'src/app/analysis/store/metrics/layers-metrics.actions'
import {
  DEFAULT_PREMIUM,
  MonetaryUnit,
} from 'src/app/api/analyzere/analyzere.model'
import { md5 } from 'src/app/shared/util/hash'
import { BackAllocatedContribution } from '../../layer-details/layer-details.model'
import { LayerMetrics } from 'src/app/analysis/model/layers-metrics.model'

export interface LayerState {
  mapped?: boolean
  layer: Layer
  hash: string
  dirty: boolean
  new: boolean
  deleted: boolean
}

export interface CopyLayerState extends LayerState {
  analysisID?: string
  parentGrossPortfolioID?: string
}

export interface State extends EntityState<LayerState> {
  error: string | null
  loading: boolean
  saving: boolean
  selected: string | null
  multiSectionContributions: Record<string, BackAllocatedContribution[]>
  subLayerMetrics?: LayerMetrics[]
  contributionsLoading?: boolean
}

export const adapter = createEntityAdapter<LayerState>({
  selectId: (layerState): string => layerState.layer.id,
})

export const initialLayerState = {
  hash: '',
  dirty: false,
  new: false,
  deleted: false,
  // @ts-ignore
  subLayerMetrics: [],
  contributionsLoading: false,
}

export const initialState: State = adapter.getInitialState({
  error: null,
  loading: false,
  saving: false,
  selected: null,
  multiSectionContributions: {},
})

// @ts-ignore
const LayersReducer = createReducer(
  initialState,
  on(fromLayersActions.fetchLayers, _ => ({
    ...initialState,
    loading: true,
    error: null as string | null,
  })),

  on(startAnalysis, (state, { fromSave }) => ({
    ...initialState,
    selected: fromSave ? state.selected : null,
  })),

  on(fromLayersActions.fetchLayersSuccess, (state, { layers }) => {
    layers = JSON.parse(JSON.stringify(layers)).map((layer: Layer) => {
      let premium = layer.physicalLayer.premium
      if (!premium) {
        premium = DEFAULT_PREMIUM
      }
      return {
        ...layer,
        physicalLayer: {
          ...layer.physicalLayer,
          premium,
        },
      }
    })
    layers.forEach(
      layer => (layer.currency = layer.physicalLayer.premium.currency)
    )

    return adapter.setAll(
      layers.map(layer => ({
        new: false,
        layer,
        dirty: false,
        deleted: false,
        hash: md5(layer),
      })),
      {
        ...state,
        loading: false,
        error: null,
        saving: false,
        selected:
          state.selected && layers.find(layer => layer.id === state.selected)
            ? state.selected
            : null,
      }
    )
  }),
  on(fromLayersActions.fetchLayersFailure, (state: State, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(fromLayersActions.updateLayer, (state: State, { id, change }) => {
    const layer = state.entities[id]
    if (!layer) {
      return state
    }
    if (
      layer.layer.meta_data.sage_layer_type === 'cat_fhcf' &&
      change.physicalLayer
    ) {
      const aggregateLimit: MonetaryUnit = {
        value: change.physicalLayer.limit.value,
        currency: change.physicalLayer.limit.currency
          ? change.physicalLayer.limit.currency
          : 'USD',
      }
      if (
        layer.layer.meta_data.isFHCFHidden1 ||
        layer.layer.meta_data.isFHCFHidden2
      ) {
        if (change.physicalLayer.participation) {
          const participation = change.physicalLayer.participation
          change = {
            ...change,
            physicalLayer: {
              ...change.physicalLayer,
              aggregateLimit,
              participation,
            },
          }
        }
      } else {
        change = {
          ...change,
          physicalLayer: {
            ...change.physicalLayer,
            aggregateLimit,
          },
        }
      }
    }
    let metaDataTemp = layer.layer.meta_data
    if (change.meta_data) {
      metaDataTemp = change.meta_data
    }
    change = {
      ...change,
      meta_data: {
        ...metaDataTemp,
        isChangedInDesign: metaDataTemp.isChangedInDesign ?? true,
      },
    }

    if (change.physicalLayer?.description) {
      change = {
        ...change,
        meta_data: {
          ...change.meta_data,
          layerName: change.physicalLayer.description,
        },
      }
    }
    const modifiedState = adapter.updateOne(
      {
        id,
        changes: { layer: { ...layer.layer, ...change } },
      },
      state
    )
    const layerStates = Object.values(modifiedState.entities).filter(
      (entity): entity is LayerState => entity !== undefined
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),

  on(fromLayersActions.addSublayerMetrics, (state: State, { layerMetrics }) => {
    return {
      ...state,
      subLayerMetrics: [...(state.subLayerMetrics || []), layerMetrics],
    }
  }),

  on(fromLayersActions.updatePhysicalLayer, (state: State, { id, change }) => {
    const layer = state.entities[id]
    if (!layer) {
      return state
    }
    if (layer.layer.meta_data.sage_layer_type === 'cat_fhcf') {
      const aggregateLimit: MonetaryUnit = {
        value: change.limit
          ? change.limit.value
          : layer.layer.physicalLayer.limit.value,
        currency: change.limit?.currency ? change.limit?.currency : 'USD',
      }
      if (
        layer.layer.meta_data.isFHCFHidden1 ||
        layer.layer.meta_data.isFHCFHidden2
      ) {
        if (change.participation) {
          const participation = change.participation
          change = {
            ...change,
            aggregateLimit,
            participation,
          }
        }
      } else {
        change = { ...change, aggregateLimit }
      }
    }

    if (
      layer.layer.meta_data.sage_layer_type === 'noncat_risk' &&
      layer.layer.meta_data.isRiskVisible
    ) {
      if (change.participation) {
        const participation = Math.abs(change.participation) * -1
        change = {
          ...change,
          participation,
        }
      }
    }
    if (layer.layer.meta_data.sage_layer_type === 'cat_ilw_bin') {
      if (change.limit) {
        change = {
          ...change,
          payout: change.limit,
        }
      }
      if (change.payout) {
        change = {
          ...change,
          limit: change.payout,
        }
      }
    }
    if (
      layer.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata' ||
      layer.layer.meta_data.sage_layer_type === 'cat_ilw_bin'
    ) {
      if (change.trigger) {
        change = {
          ...change,
          attachment: change.trigger,
        }
      }
      if (change.attachment) {
        change = {
          ...change,
          trigger: change.attachment,
        }
      }
    }
    let metaDataTemp = layer.layer.physicalLayer.meta_data
    if (change.meta_data) {
      metaDataTemp = change.meta_data
    }
    change = {
      ...change,
      meta_data: {
        ...metaDataTemp,
        isChangedInDesign: metaDataTemp.isChangedInDesign ?? true,
      },
    }
    if (change.description) {
      change = {
        ...change,
        meta_data: {
          ...change.meta_data,
          layerName: change.description,
        },
      }
    }
    if (
      layer.layer.physicalLayer.meta_data.technicalPremiumChecked &&
      change.meta_data?.technicalPremium
    ) {
      change = {
        ...change,
        premium: {
          ...layer.layer.physicalLayer.premium,
          value: change.meta_data.technicalPremium,
        },
      }
    }
    const modifiedState = adapter.updateOne(
      {
        id,

        changes: {
          layer: {
            ...layer.layer,
            physicalLayer: {
              ...layer.layer.physicalLayer,
              ...change,
            },
          },
        },
      },
      state
    )
    const layerStates = Object.values(modifiedState.entities).filter(
      (entity): entity is LayerState => entity !== undefined
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),
  on(fetchLayersViewMetricsRP, (state: State, { layerID }) => {
    if (!state.entities[layerID]) {
      return state
    }
    const modifiedState = adapter.updateOne(
      {
        id: layerID,

        changes: {
          layer: {
            // tslint:disable-next-line: no-non-null-assertion
            ...state.entities[layerID]!.layer,
            viewMetrics: {
              // tslint:disable-next-line: no-non-null-assertion
              ...state.entities[layerID]!.layer.viewMetrics,
              error: null,
              loading: true,
            },
          },
        },
      },
      state
    )
    const layerStates = Object.keys(modifiedState.entities).map(
      // tslint:disable-next-line: no-non-null-assertion
      k => modifiedState.entities[k]!
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),

  on(fetchLayersViewMetricsSuccessRP, (state: State, { layerID, metrics }) => {
    if (!state.entities[layerID]) {
      return state
    }
    const modifiedState = adapter.updateOne(
      {
        id: layerID,

        changes: {
          layer: {
            // tslint:disable-next-line: no-non-null-assertion
            ...state.entities[layerID]!.layer,
            viewMetrics: {
              error: null,
              loading: false,
              rss: null,
              metrics,
            },
          },
        },
      },
      state
    )
    const layerStates = Object.keys(modifiedState.entities).map(
      // tslint:disable-next-line: no-non-null-assertion
      k => modifiedState.entities[k]!
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),

  on(fetchLayersViewMetricsFailureRP, (state: State, { layerID, error }) => {
    if (!state.entities[layerID]) {
      return state
    }
    const modifiedState = adapter.updateOne(
      {
        id: layerID,

        changes: {
          layer: {
            // tslint:disable-next-line: no-non-null-assertion
            ...state.entities[layerID]!.layer,
            viewMetrics: {
              // tslint:disable-next-line: no-non-null-assertion
              ...state.entities[layerID]!.layer.viewMetrics,
              error: error.message,
              loading: false,
            },
          },
        },
      },
      state
    )
    const layerStates = Object.keys(modifiedState.entities).map(
      // tslint:disable-next-line: no-non-null-assertion
      k => modifiedState.entities[k]!
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),

  on(fromLayersActions.deleteLayer, (state: State, { id }) => {
    if (!state.entities[id]) {
      return state
    }
    const modifiedState = adapter.updateOne(
      { id, changes: { deleted: true } },
      { ...state, selected: id === state.selected ? null : state.selected }
    )
    const layerStates = Object.keys(modifiedState.entities).map(
      // tslint:disable-next-line: no-non-null-assertion
      k => modifiedState.entities[k]!
    )
    const layerState = handleCascade(
      layerStates.filter(
        ls =>
          !ls.deleted &&
          !(
            ls.layer.meta_data.sage_layer_type === 'cat_td' &&
            ls.layer.meta_data.sage_layer_subtype === 'actual'
          )
      )
    )
    return adapter.updateMany(
      layerState.map(ls => ({ id: ls.layer.id, changes: ls })),
      modifiedState
    )
  }),

  on(fromLayersActions.setDirty, (state: State, { id, dirty }) => {
    if (!state.entities[id]) {
      return state
    }
    return adapter.updateOne({ id, changes: { dirty } }, state)
  }),

  on(fromLayersActions.saveLayers, (state: State) => ({
    ...state,
    saving: true,
    error: '',
  })),

  on(fromLayersActions.saveSuccess, (state: State) => {
    const ids: string[] = state.ids as string[]
    return adapter.updateMany(
      ids.map((id: string) => ({
        id,
        changes: { new: false, dirty: false },
      })),
      { ...state, saving: false, error: null }
    )
  }),

  on(fromLayersActions.saveFailure, (state: State, { error }) => ({
    ...state,
    saving: false,
    error: error.message,
  })),

  on(fromLayersActions.setSelectedLayer, (state, { id }) => ({
    ...state,
    selected: id,
  })),

  on(fromLayersActions.addLayerSuccess, (state, { layer }) => {
    layer = JSON.parse(JSON.stringify(layer))
    layer.currency = layer.physicalLayer.premium.currency
    return adapter.addOne(
      {
        new: true,
        deleted: false,
        dirty: true,
        layer,
        hash: md5(layer),
      },
      state
    )
  }),

  on(fromLayersActions.getBackAllocatedContribution, (state: State) => ({
    ...state,
    loading: true,
  })),

  on(
    fromLayersActions.getBackAllocatedContributionFailure,
    (state: State, { error }) => ({
      ...state,
      error: error.message,
      loading: false,
    })
  ),

  on(
    fromLayersActions.getBackAllocatedContributionSuccess,
    (state: State, { data, layerID, forMultiSections }) => {
      const layerState = state.entities[layerID]
      if (!layerState) {
        return state
      }
      if (forMultiSections) {
        return {
          ...state,
          multiSectionContributions: {
            ...state.multiSectionContributions,
            [layerID]: data,
          },
        }
      }
      let modifiedState = adapter.updateOne(
        {
          id: layerID,
          changes: {
            layer: {
              ...layerState.layer,
              contributionValues: data,
            },
          },
        },
        state
      )
      const layerStates = Object.keys(modifiedState.entities)
        .map(k => modifiedState.entities[k])
        .filter(
          (entity: LayerState | undefined): entity is LayerState =>
            entity !== undefined
        )
      const newLayerState = handleCascade(
        layerStates.filter(
          ls =>
            !ls.deleted &&
            !(
              ls.layer.meta_data.sage_layer_type === 'cat_td' &&
              ls.layer.meta_data.sage_layer_subtype === 'actual'
            )
        )
      )
      modifiedState = {
        ...modifiedState,
        loading: false,
      }
      return adapter.updateMany(
        newLayerState.map(ls => ({ id: ls.layer.id, changes: ls })),
        modifiedState
      )
    }
  ),
  on(
    fromLayersActions.handleGetBackAllocatedContributionsSuccess,
    (state: State) => ({ ...state, contributionsLoading: false })
  ),
  on(
    fromLayersActions.setAALValuesPerLayer,
    (state: State, { id, lossSetLayers }) => {
      if (!state.entities[id]) {
        return state
      }
      let layer: Layer
      layer = {
        ...state.entities[id].layer,
        lossSetLayers: lossSetLayers,
      }
      return adapter.updateOne(
        {
          id,
          changes: { layer: { ...layer } },
        },
        state
      )
    }
  ),
  on(
    fromLayersActions.updateContributionsLoading,
    (state: State, { loading }) => ({ ...state, contributionsLoading: loading})
  )
)

export function reducer(state: State | undefined, action: Action) {
  return LayersReducer(state, action)
}

const handleCascade = (layerStates: LayerState[]) => {
  const returnCascadeLayers: Record<string, LayerState> = {}
  layerStates.forEach(layerState => {
    returnCascadeLayers[layerState.layer.id] = JSON.parse(
      JSON.stringify(layerState)
    )
  })

  const auxLayerStates = layerStates
    .filter(
      ls =>
        !ls.deleted &&
        ls.layer.meta_data.sage_layer_type !== 'shared_limit' &&
        ls.layer.meta_data.sage_layer_type !== 'shared_limits' &&
        ls.layer.meta_data.sage_layer_subtype !== 'actual' &&
        !isSwingLayer(ls.layer, 'combined-layer')
    )
    .map(ls => {
      return JSON.parse(
        JSON.stringify({ ...ls, layer: { ...ls.layer, layerRefs: [] } })
      )
    })

  let aux
  const n = auxLayerStates.length
  for (let k = 1; k < n; k++) {
    for (let i = 0; i < n - k; i++) {
      let valA = 0
      let valB = 0
      if (auxLayerStates[i].layer.meta_data.sage_layer_type === 'cat_ca') {
        valA =
          auxLayerStates[i].layer.meta_data.cascadeAttachment ||
          auxLayerStates[i].layer.physicalLayer.attachment.value
      } else {
        valA = auxLayerStates[i].layer.physicalLayer.attachment.value
      }

      if (auxLayerStates[i + 1].layer.meta_data.sage_layer_type === 'cat_ca') {
        valB =
          auxLayerStates[i + 1].layer.meta_data.cascadeAttachment ||
          auxLayerStates[i + 1].layer.physicalLayer.attachment.value
      } else {
        valB = auxLayerStates[i + 1].layer.physicalLayer.attachment.value
      }

      valA = valA + auxLayerStates[i].layer.physicalLayer.limit.value
      valB = valB + auxLayerStates[i + 1].layer.physicalLayer.limit.value

      if (valA < valB) {
        aux = auxLayerStates[i]
        auxLayerStates[i] = auxLayerStates[i + 1]
        auxLayerStates[i + 1] = aux
      }
    }
  }

  const cascadeLayers = auxLayerStates.filter(isCascadingLayer)

  if (cascadeLayers.length > 0) {
    cascadeLayers.reverse().forEach(cas => {
      if (
        cas.layer.meta_data.cascadeLowerLayerID &&
        cas.layer.meta_data.cascadeLowerLayerID !== ''
      ) {
        const layersBelow: LayerState[] = []
        let stop = false

        auxLayerStates.forEach(layerState => {
          if (Object.keys(layerState.layer.meta_data).length > 0) {
            if (!stop) {
              const cascadeAttachment =
                (cas.layer.meta_data.cascadeAttachment ||
                  cas.layer.physicalLayer.attachment.value) +
                cas.layer.physicalLayer.limit.value

              let layerAttachment =
                layerState.layer.physicalLayer.attachment.value
              if (isCascadingLayer(layerState)) {
                layerAttachment =
                  layerState.layer.meta_data.cascadeAttachment ||
                  layerState.layer.physicalLayer.attachment.value
              }
              layerAttachment =
                layerAttachment + layerState.layer.physicalLayer.limit.value

              if (
                layerState.layer.id !== cas.layer.id &&
                layerAttachment < cascadeAttachment
              ) {
                layersBelow.push(layerState)
              }

              if (
                layerState.layer.id === cas.layer.meta_data.cascadeLowerLayerID
              ) {
                stop = true
              }
            }
          }
        })

        if (layersBelow.length > 0) {
          let attachment

          if (
            layersBelow[layersBelow.length - 1].layer.meta_data
              .sage_layer_type === 'cat_ca'
          ) {
            attachment =
              layersBelow[layersBelow.length - 1].layer.meta_data
                .cascadeAttachment ||
              layersBelow[layersBelow.length - 1].layer.physicalLayer.attachment
                .value
          } else {
            attachment =
              layersBelow[layersBelow.length - 1].layer.physicalLayer.attachment
                .value
          }

          const cascadeAttachment = returnCascadeLayers[cas.layer.id].layer
            .meta_data.cascadeAttachment
            ? returnCascadeLayers[cas.layer.id].layer.meta_data
                .cascadeAttachment
            : returnCascadeLayers[cas.layer.id].layer.physicalLayer.attachment
                .value

          returnCascadeLayers[cas.layer.id] = {
            ...cas,
            layer: {
              ...cas.layer,
              meta_data: {
                ...cas.layer.meta_data,
                cascadeAttachment,
              },
              physicalLayer: {
                ...cas.layer.physicalLayer,
                attachment: {
                  ...cas.layer.physicalLayer.attachment,
                  value: attachment,
                },
              },
            },
          }

          const index = auxLayerStates.findIndex(ls => {
            return ls.layer.id === cas.layer.id
          })

          if (index >= 0) {
            auxLayerStates[index].layer.meta_data.cascadeAttachment =
              cascadeAttachment
            auxLayerStates[index].layer.physicalLayer.attachment.value =
              attachment
          }

          layersBelow.unshift(returnCascadeLayers[cas.layer.id])

          const newLayerRefs: string[] = layersBelow.map(ls => {
            return ls.layer.id
          })

          layersBelow.forEach(ls => {
            if (ls.layer.meta_data.sage_layer_type === 'cat_ca') {
              newLayerRefs.forEach(layerRef => {
                if (
                  returnCascadeLayers[ls.layer.id].layer.layerRefs.indexOf(
                    layerRef
                  ) === -1 &&
                  ls.layer.id !== layerRef
                ) {
                  returnCascadeLayers[ls.layer.id].layer.layerRefs.push(
                    layerRef
                  )
                }
              })
            }
            newLayerRefs.splice(0, 1)
          })
        }
      }
    })

    if (Object.keys(returnCascadeLayers).length > 0) {
      return layerStates.map(ls => {
        return returnCascadeLayers[ls.layer.id]
      })
    } else {
      return layerStates
    }
  } else {
    return layerStates
  }
}

function isCascadingLayer(layerState: LayerState) {
  return (
    layerState.layer.meta_data.sage_layer_type === 'cat_ca' ||
    layerState.layer.meta_data.sage_layer_type === 'cat_cascading'
  )
}
