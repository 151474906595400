<div *ngIf="!previewOpen" class="multi-select-dialog-container">
  <div class="left">
    <div class="top-titles">
      <h1 class="dialog-title">Export Table and Graph Options</h1>
      <button appButton accent big border (click)="onPreviewClick()">
        <span>Preview</span>
      </button>
    </div>
    <div class="top-controls">
      <mat-slide-toggle
        class="light-chart-toggle"
        matTooltip="Set charts to have light background and dark wording"
        matTooltipPosition="below"
        [checked]="darkMode"
        (change)="toggleDarkMode()"
      >
        <span class="light-chart-toggle-label">Dark Mode</span>
      </mat-slide-toggle>
      <div class="view-select">
        <div class="label">Chart Entity Limit</div>
        <input
          matInput
          type="number"
          [(ngModel)]="chartEntityLimit"
          [min]="1"
          [max]="32"
        />
        <!-- <mat-form-field class="number-input" appearance="outline">
          <button mat-icon-button matPrefix (click)="update()" [disabled]="value <= min">
            <mat-icon>remove</mat-icon>
          </button>


          <button mat-icon-button matSuffix (click)="increment()" [disabled]="value >= max">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field> -->
      </div>
      <div class="view-select">
        <div class="label">Summary View</div>
        <mat-select
          [value]="selectedSummaryView || null"
          (selectionChange)="onSelectedSummaryViewChange($event.value)"
        >
          <ng-container *ngFor="let view of summaryViews">
            <mat-option [value]="view">
              {{ view.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </div>
    <div class="panels">
      <div class="panel" *ngFor="let panel of panels; let index = index">
        <div (click)="toggleVisibility(index, 'Panel')" class="panel-title">
          {{ getIndexedTitle(index, 'Panel')
          }}<span class="panel-subtitle"
            >{{ getPanelSubtitle(panel.type) }}
            <button
              appButton
              small
              primary
              link
              class="option-type panel-options-button"
              [matMenuTriggerFor]="panelOptionMenu"
              aria-label="Options"
              (click)="$event.preventDefault()"
            >
              <mat-icon aria-hidden="false" aria-label="More">
                more_horiz
              </mat-icon>
            </button>
          </span>
          <mat-icon class="caret-icon">
            {{
              isSectionVisible(index, 'Panel') ? 'expand_less' : 'expand_more'
            }}
          </mat-icon>
          <mat-menu
            #panelOptionMenu="matMenu"
            class="app-menu-panel-no-x-scroll app-menu-panel-small"
          >
            <button
              appButton
              small
              primary
              link
              class="option-type"
              (click)="onCopyClick(index)"
            >
              Copy
            </button>
            <button
              appButton
              small
              primary
              link
              class="delete"
              (click)="onDeletePanel(index)"
            >
              Delete
            </button>
          </mat-menu>
        </div>
        <div
          class="panel-options"
          *ngIf="isSectionVisible(index, 'Panel')"
          [@slideInOut]
        >
          <div class="panel-option">
            <mat-label>Panel Type</mat-label>
            <mat-select
              [value]="panel.type || null"
              (selectionChange)="onTypeChange($event.value, index)"
            >
              <ng-container *ngFor="let type of typeOptions">
                <mat-option [value]="type.value">
                  {{ type.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </div>
          <ng-container *ngIf="panel.type === 'titles'">
            <div class="panel-option">
              <mat-label>Top Title</mat-label>
              <div class="input-dropdown-container">
                <input
                  type="text"
                  class="text-input"
                  [value]="panel.topTitle ?? ''"
                  (input)="onTopTitleChange($event.target.value, index)"
                  placeholder="Enter or select a top title"
                />
                <mat-select
                  class="dropdown"
                  (selectionChange)="onTopTitleSelect($event.value, index)"
                >
                  <ng-container *ngFor="let title of titleOptions">
                    <mat-option [value]="title">
                      {{ title.label }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </div>
            </div>
            <ng-container
              *ngFor="let subtitle of panel.subtitles; let i = index"
            >
              <div class="panel-option">
                <mat-label>Subtitle</mat-label>
                <div class="input-dropdown-container">
                  <input
                    type="text"
                    class="text-input"
                    [value]="subtitle"
                    (input)="onSubtitleChange($event.target.value, index, i)"
                    placeholder="Enter or select a subtitle"
                  />
                  <mat-select
                    class="dropdown"
                    (selectionChange)="onSubtitleSelect($event.value, index, i)"
                  >
                    <ng-container *ngFor="let title of titleOptions">
                      <mat-option [value]="title">
                        {{ title.label }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </div>
              </div>
            </ng-container>
            <button appButton accent big border (click)="onAddSubtitle(index)">
              <span>Add Subtitle</span>
            </button>
          </ng-container>
          <ng-container *ngIf="panel.type === 'table'">
            <div class="panel-option">
              <mat-label>Summary View</mat-label>
              <mat-select
                [value]="panel.summaryView || null"
                (selectionChange)="
                  onPanelSummaryViewChange($event.value, index)
                "
              >
                <ng-container *ngFor="let view of summaryViews">
                  <mat-option [value]="view">
                    {{ view.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </div>
            <div class="panel-option">
              <mat-label>Table Title</mat-label>
              <div class="input-dropdown-container">
                <input
                  type="text"
                  class="text-input"
                  [value]="panel.tableTitle ?? ''"
                  (input)="onTableTitleChange($event.target.value, index)"
                  placeholder="Enter Chart Title (Optional)"
                />
              </div>
            </div>
            <div *ngIf="panel.summaryView" class="panel-option">
              <mat-label>Columns</mat-label>
              <mat-select
                [value]="getColumnsValue(panel.columns)"
                (selectionChange)="onColumnSelectionChange($event.value, index)"
                multiple
              >
                <mat-option
                  *ngFor="let option of panel.columns"
                  [value]="option.label"
                  [disabled]="option.label === 'Group By'"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-container>
          <ng-container *ngIf="panel.type === 'charts'">
            <div class="panel-option">
              <mat-label>Summary View</mat-label>
              <mat-select
                [value]="panel.summaryView || null"
                (selectionChange)="
                  onPanelSummaryViewChange($event.value, index)
                "
              >
                <ng-container *ngFor="let view of summaryViews">
                  <mat-option [value]="view">
                    {{ view.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </div>
            <div class="panel-option">
              <mat-label>Chart Grid</mat-label>
              <mat-select
                [value]="panel.chartGrid || null"
                (selectionChange)="onChartGridChange($event.value, index)"
              >
                <ng-container *ngFor="let grid of chartGridDropdownOptions">
                  <mat-option [value]="grid">
                    {{ grid.label }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </div>
            <div *ngIf="panel.chartGridOptions" class="chart-options">
              <ng-container
                *ngFor="
                  let chartGridOption of panel.chartGridOptions;
                  let i = index
                "
              >
                <div (click)="toggleVisibility(i, 'Chart')" class="chart-title">
                  {{ getIndexedTitle(i, 'Chart') }}
                  <mat-icon class="caret-icon">
                    {{
                      isSectionVisible(i, 'Chart')
                        ? 'expand_less'
                        : 'expand_more'
                    }}
                  </mat-icon>
                </div>
                <div
                  *ngIf="isSectionVisible(i, 'Chart')"
                  class="chart"
                  [@slideInOut]
                >
                  <div class="panel-option">
                    <mat-label>Metric</mat-label>
                    <mat-select
                      [value]="chartGridOption.metric || null"
                      (selectionChange)="
                        onChartGridMetricChange($event.value, index, i)
                      "
                    >
                      <ng-container
                        *ngFor="let metric of chartGridMetricsOptions"
                      >
                        <mat-option [value]="metric">
                          {{ metric.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </div>
                  <div *ngIf="chartGridOption.metric" class="panel-option">
                    <mat-label>Chart Type</mat-label>
                    <mat-select
                      [value]="chartGridOption.chart || null"
                      (selectionChange)="
                        onChartGridChartTypeChange($event.value, index, i)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let chart of chartGridOption.metric.applicableCharts
                        "
                      >
                        <mat-option [value]="chart">
                          {{ chart.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <button appButton accent big border (click)="onAddPanel()">
        <span>Add Panel</span>
      </button>
    </div>
  </div>
  <div class="right">
    <div
      class="layout"
      [ngStyle]="{ background: darkMode ? 'black' : 'white' }"
    >
      <ng-container *ngFor="let panel of panels; let index = index">
        <ng-container *ngIf="panel.type === 'titles'">
          <div
            class="layout-box"
            [ngStyle]="getLayoutStyle('titles', 'topTitle')"
          ></div>
          <ng-container *ngIf="panel.subtitles && panel.subtitles.length > 0">
            <ng-container *ngFor="let subtitle of panel.subtitles">
              <div
                class="layout-box"
                [ngStyle]="getLayoutStyle('titles', 'subtitle')"
              ></div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="panel.type === 'table'">
          <ng-container *ngIf="panel.tableTitle && panel.tableTitle !== ''">
            <div
              class="layout-box"
              [ngStyle]="getLayoutStyle('table', 'tableTitle')"
            ></div>
          </ng-container>
          <div
            class="layout-box"
            [ngStyle]="getLayoutStyle('table', 'tableContainer')"
          ></div>
        </ng-container>
        <ng-container
          *ngIf="
            panel.type === 'charts' &&
            panel.chartGridOptions &&
            panel.chartGridOptions.length > 0
          "
        >
          <div class="charts" [ngStyle]="getChartHeight(panel.chartGrid.label)">
            <div
              *ngFor="let chart of panel.chartGridOptions"
              class="layout-box"
              [ngStyle]="getLayoutStyle('charts', panel.chartGrid.value)"
            ></div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-container *ngIf="previewOpen">
  <app-explore-multi-select-export-preview-page
    [panels]="panels"
    [layoutPanels]="layoutPanels"
    [summaryExportGroups]="summaryExportGroups"
    [summaryHeaders]="summaryHeaders"
    [summaryColumnFilterMap]="summaryColumnFilterMap"
    [currentStructureCurrency]="currentStructureCurrency"
    [summaryData]="summaryData"
    [summaryRP]="summaryRP"
    [mappingLabels]="mappingLabels"
    [summaryFilterMap]="summaryFilterMap"
    [chartEntityLimit]="chartEntityLimit"
    [darkMode]="darkMode"
    [client]="client"
    [study]="study"
    [selectedStructure]="program"
    [accountOpportunities]="accountOpportunities"
    (updatePreviewMode)="onUpdatePreviewMode()"
  >
  </app-explore-multi-select-export-preview-page>
</ng-container>
