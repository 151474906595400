// tslint:disable-next-line: max-line-length
import { Action, createReducer, on } from '@ngrx/store'
import { CalculatedPortfolioDetailMetrics } from '../../model/portfolio-metrics.model'
import { fetchPortfolioView } from '../views/portfolio-view.actions'
import {
  fetchPortfolioViewDetailMetrics,
  fetchPortfolioViewDetailMetricsFailure,
  fetchPortfolioViewDetailMetricsSuccess,
} from './portfolio-detail-metrics.actions'

export interface State {
  loading: boolean
  error: string | null
  cededCalculatedMetrics: CalculatedPortfolioDetailMetrics | null
  grossCalculatedMetrics: CalculatedPortfolioDetailMetrics | null
  netCalculatedMetrics: CalculatedPortfolioDetailMetrics | null
}

export const initialState: State = {
  loading: false,
  error: null,
  cededCalculatedMetrics: null,
  grossCalculatedMetrics: null,
  netCalculatedMetrics: null,
}

const portfolioViewDetailMetricsReducer = createReducer(
  initialState,

  on(fetchPortfolioView, _ => initialState),

  on(fetchPortfolioViewDetailMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchPortfolioViewDetailMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(
    fetchPortfolioViewDetailMetricsSuccess,
    (
      state,
      { cededCalculatedMetrics, grossCalculatedMetrics, netCalculatedMetrics }
    ) => ({
      ...state,
      loading: false,
      error: null,
      cededCalculatedMetrics,
      grossCalculatedMetrics,
      netCalculatedMetrics,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return portfolioViewDetailMetricsReducer(state, action)
}
