import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-header',
  styleUrls: ['./metrics-header.component.scss'],
  templateUrl: './metrics-header.component.html',
})
export class MetricsHeaderComponent {
  @Input() name: string
  @Input() activeAction: string
  @Input() metricDirty: boolean
  @Input() studySelected: string
  @Input() totalWeight: number

  @Input() isAddSharedLayer: boolean
  @Input() isDeleteSharedLayer: boolean
  @Input() loading: boolean

  @Output() saveClick = new EventEmitter()
  @Output() addSharedLayerClick = new EventEmitter()
  @Output() deleteSharedLayerClick = new EventEmitter()

  get disableSave(): boolean {
    return (
      !this.metricDirty ||
      !this.studySelected ||
      this.totalWeight !== 100 ||
      this.loading
    )
  }

  get subtitle(): string {
    return 'SAGE offers a variety of analytics-driven insights. Please select from the available programs for your chosen company.\nYou can return to the selection page to choose another company.'
  }

  constructor(public navService: NavService, private router: Router) {}

  onBackClick(): boolean {
    this.router.navigate(['/home']).then(r => r)
    return false
  }

  onSaveClick(): void {
    if (!this.disableSave) {
      this.saveClick.emit()
    }
  }

  onDeleteSharedLimitClick(): void {
    if (this.isAddSharedLayer) {
      this.deleteSharedLayerClick.emit()
    }
    if (this.isDeleteSharedLayer) {
      this.addSharedLayerClick.emit()
    }
  }
}
