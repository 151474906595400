import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
} from '@angular/core'
import * as d3 from 'd3'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-svg-defs',
  styles: [
    `
           .mask {
             width: 1000vw;
             height: 1000vh;
           }
     
           .bg {
             fill: white;
           }
     
           .fg {
             stroke: #ccc;
             stroke-width: 2;
           }
     
           .fg-half {
             stroke-width: 1;
           }
     
           .fg-block {
             fill: #ccc;
           }
     
           .fg-block-strong {
             fill: #888;
           }
         `,
  ],
  template: `
    <svg>
      <defs id="svg-defs">
        <pattern
          id="pattern-diagonal-stripe-red"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect fill="transparent" width="10" height="10" />
          <path
            stroke="var(--warn-strong)"
            stroke-width="2"
            d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2"
          />
        </pattern>

        <pattern
          id="pattern-diagonal-stripe-red-green"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect fill="var(--accent-strong-hint)" width="10" height="10" />
          <path
            stroke="var(--warn-strong)"
            stroke-width="2"
            d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2"
          />
        </pattern>

        <pattern
          id="pattern-diagonal-stripe-red-dim-red"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect fill="var(--accent-strong-hint)" width="10" height="10" />
          <path
            stroke="var(--warn-strong)"
            stroke-width="2"
            d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2"
          />
        </pattern>

        <pattern
          id="pattern-diagonal-stripe"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect class="bg" width="10" height="10" />
          <path class="fg" d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2" />
        </pattern>
        <mask id="mask-diagonal-stripe">
          <rect class="mask" fill="url(#pattern-diagonal-stripe)" />
        </mask>

        <pattern
          id="pattern-blocks"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect class="bg" width="10" height="10" />
          <rect class="fg-block" x="0" y="0" width="6" height="6" />
        </pattern>
        <mask id="mask-blocks">
          <rect class="mask" fill="url(#pattern-blocks)" />
        </mask>

        <pattern
          id="pattern-vertical-stripe"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect class="bg" width="10" height="10" />
          <rect class="fg-block" x="0" y="0" width="2" height="10" />
        </pattern>
        <mask id="mask-vertical-stripe">
          <rect class="mask" fill="url(#pattern-vertical-stripe)" />
        </mask>

        <pattern
          id="pattern-cross-hatch"
          patternUnits="userSpaceOnUse"
          width="8"
          height="8"
        >
          <rect class="bg" width="8" height="8" />
          <path class="fg fg-half" d="M0 0L8 8ZM8 0L0 8Z" />
        </pattern>
        <mask id="mask-cross-hatch">
          <rect class="mask" fill="url(#pattern-cross-hatch)" />
        </mask>

        <pattern
          id="pattern-dot-small"
          patternUnits="userSpaceOnUse"
          width="5"
          height="5"
        >
          <rect class="bg" width="5" height="5" />
          <rect class="fg-block-strong" width="1" height="1" />
        </pattern>
        <mask id="mask-dot-small">
          <rect class="mask" fill="url(#pattern-dot-small)" />
        </mask>

        <pattern
          id="pattern-square"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect class="bg" width="10" height="10" />
          <rect class="fg-block" x="1" width="10" height="10" y="1" />
        </pattern>
        <mask id="mask-square">
          <rect class="mask" fill="url(#pattern-square)" />
        </mask>

        <pattern
          id="pattern-two-triangles"
          patternUnits="userSpaceOnUse"
          width="15"
          height="15"
        >
          <rect class="bg" width="15" height="15" />
          <path class="fg-block" d="M4,4 L16,4 L4,16 L16,16 L4,16 L16,16" />
        </pattern>
        <mask id="mask-two-triangles">
          <rect class="mask" fill="url(#pattern-two-triangles)" />
        </mask>

        <pattern
          id="pattern-triangle"
          patternUnits="userSpaceOnUse"
          width="15"
          height="15"
        >
          <rect class="bg" width="15" height="15" />
          <path class="fg-block" d="M 3 3 L 9 3 L 6 9 z" />
        </pattern>
        <mask id="mask-triangle">
          <rect class="mask" fill="url(#pattern-triangle)" />
        </mask>

        <pattern
          id="pattern-curvy"
          patternUnits="userSpaceOnUse"
          width="15"
          height="15"
        >
          <rect class="bg" width="15" height="15" />
          <path class="fg-block" d="M4,4 Q10,4 10,10 T16,16" />
        </pattern>
        <mask id="mask-curvy">
          <rect class="mask" fill="url(#pattern-curvy)" />
        </mask>

        <pattern
          id="pattern-semi-circle"
          patternUnits="userSpaceOnUse"
          width="15"
          height="15"
        >
          <rect class="bg" width="15" height="15" />
          <path class="fg-block" d="M4,10 C4,16 16,16 16,10" />
        </pattern>
        <mask id="mask-semi-circle">
          <rect class="mask" fill="url(#pattern-semi-circle)" />
        </mask>

        <symbol id="ribbon-tag-source" viewBox="0 0 24 18">
          <g fill="#000">
            <path
              d="M 18.23351097106934 17 L 1.02786386013031 17 C 1.011643171310425 16.85025787353516 1 16.66578102111816 1 16.45161056518555 L 1 1.548390030860901 C 1 1.334218621253967 1.011643171310425 1.149742960929871 1.027864575386047 1 L 18.23807716369629 1 L 22.84583854675293 8.932766914367676 L 18.23351097106934 17 Z"
              stroke="none"
            />
            <path
              d="M 2 2 L 2 16 L 17.65334129333496 16 L 21.69167709350586 8.936713218688965 L 17.6624755859375 2 L 2 2 M 0.3583602905273438 0 L 18.81368064880371 0 L 24 8.92881965637207 L 18.81368064880371 18 L 0.3583602905273438 18 C 0.1604404449462891 18 0 17.30677032470703 0 16.45161056518555 L 0 1.548389434814453 C 0 0.6932392120361328 0.1604404449462891 0 0.3583602905273438 0 Z"
              fill="currentColor"
              stroke="none"
            />
          </g>
        </symbol>

        <symbol id="ribbon-tag-target" viewBox="0 0 24 18">
          <g fill="#000">
            <path
              d="M 1.693692207336426 16.99961853027344 L 5.794804096221924 9.49327564239502 L 6.0561842918396 9.014885902404785 L 5.795704364776611 8.535995483398438 L 1.6966872215271 0.9999957680702209 L 23.014404296875 0.9999957680702209 L 23.014404296875 9.013815879821777 L 23.014404296875 16.98759269714355 C 11.81436729431152 16.98797416687012 5.998332977294922 16.99472236633301 3.161184310913086 16.99801635742188 C 2.564506530761719 16.99870872497559 2.082867860794067 16.99927139282227 1.693692207336426 16.99961853027344 Z"
              stroke="none"
            />
            <path
              d="M 3.378965377807617 1.999996185302734 L 6.674163818359375 8.058185577392578 L 7.195114135742188 9.015945434570312 L 6.672374725341797 9.972735404968262 L 3.380578994750977 15.99777030944824 C 6.2041015625 15.99449920654297 11.74044322967529 15.98836135864258 22.014404296875 15.98764419555664 L 22.014404296875 9.013815879821777 L 22.014404296875 1.999996185302734 L 3.378965377807617 1.999996185302734 M 0.014404296875 -3.814697265625e-06 L 24.014404296875 -3.814697265625e-06 L 24.014404296875 9.013815879821777 L 24.014404296875 17.98757553100586 C 8.143472671508789 17.98757553100586 2.813222885131836 18.00000190734863 0.9924278259277344 18.00000190734863 C 0.08186531066894531 18.00000190734863 0.04923439025878906 17.99689674377441 0.014404296875 17.98757553100586 L 4.917243957519531 9.013815879821777 L 0.014404296875 -3.814697265625e-06 Z"
              stroke="none"
              fill="currentColor"
            />
          </g>
        </symbol>

        <symbol id="ribbon-card-source" viewBox="0 0 25.284 96">
          <g>
            <path
              d="M48,0,96,23.484H0Z"
              transform="translate(23.868 171) rotate(90)"
              fill="#242829"
            />
            <line
              x2="23.484"
              y2="48.535"
              transform="translate(0.9 0.5)"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
            <line
              y1="47.502"
              x2="23.484"
              transform="translate(0.9 48)"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </g>
        </symbol>
      </defs>
    </svg>
  `,
})
export class SvgDefsComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.appendLossGradient()
  }

  private appendLossGradient() {
    const colors = [
      '#668cff',
      '#1a53ff',
      '#0040ff',
      '#0039e6',
      '#0040ff',
      '#1a53ff',
      '#668cff',
    ]
    const defs = d3.select('#svg-defs')
    const linearGradient = defs
      .append('linearGradient')
      .attr('id', 'loss-gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')
      .attr('spreadMethod', 'reflect')
    linearGradient
      .selectAll('.stop')
      .data(colors)
      .enter()
      .append('stop')
      .attr('offset', (_d, i) => {
        return i / (colors.length - 1)
      })
      .attr('stop-color', d => {
        return d
      })
      .attr('stop-opacity', 0.8)
    linearGradient
      .append('animate')
      .attr('attributeName', 'y1')
      .attr('values', '100%;0%')
      .attr('dur', '7s')
      .attr('repeatCount', 'indefinite')
    linearGradient
      .append('animate')
      .attr('attributeName', 'y2')
      .attr('values', '0%;-100%')
      .attr('dur', '7s')
      .attr('repeatCount', 'indefinite')
  }
}
