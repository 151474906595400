import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { CreditTransactionType } from './credit-layer.model'

import { CreditTranche, CreditTransaction } from './credit-structure.model'
import { CreditCompareMetric } from './credit-compare.model'
import {
  CreditPropertyType,
  CreditPropertyReference,
} from './credit-property.model'

export const OPTIMIZATION_CLASS = 'credit-optimization-dialog'
export const DIALOG_HEIGHT = '85vh'
export const DIALOG_MIN_WIDTH = '35vw'
export const DIALOG_MAX_WIDTH = '95vw'
export const DEFAULT_INCREMENT = 0.05

export type CreditOptimizationTab = {
  type: CreditOptimizationTabType
  active: boolean
  disabled: boolean
}

export enum CreditOptimizationTabType {
  Configuration = 'Configuration',
  Results = 'Results',
}

export const DEFAULT_CREDIT_OPTIMIZATION_TABS: CreditOptimizationTab[] = [
  {
    type: CreditOptimizationTabType.Configuration,
    active: true,
    disabled: false,
  },
  {
    type: CreditOptimizationTabType.Results,
    active: false,
    disabled: true,
  },
]

export type CreditOptimizationAttribute = {
  key: keyof CreditTransaction | keyof CreditTranche
  type: CreditPropertyType
  label: string
  lens: CreditOptimizationLens
  references?: CreditPropertyReference[]
  validations?: CreditOptimizationValidation[]
}

export type CreditOptimizationLens = 'transaction' | 'tranche'

export type CreditOptimizationValidation = {
  type: CreditOptimizationValidationType
  definition?: ValidationDefinitionMapping[CreditOptimizationValidation['type']]
}

export type CreditOptimizationValidationType = keyof ValidationDefinitionMapping

type ValidationDefinitionMapping = {
  MinLessThanMax: MinLessThanMaxMapping
  MaxAttachLessThanMinDetach: MinLessThanMaxMapping
  PositiveNumber: undefined
}

export type MinLessThanMaxMapping = {
  min: keyof CreditTranche
  max: keyof CreditTranche
}

const XOL_ILN_OPTIMIZATION_TRANSACTION_ATTRIBUTES: CreditOptimizationAttribute[] =
  [
    {
      key: 'effective_date',
      type: 'date',
      label: 'Effective Date',
      lens: 'transaction',
    },
    {
      key: 'maturity_date',
      type: 'date',
      label: 'Maturity Date',
      lens: 'transaction',
    },
    {
      key: 'call_date',
      type: 'date',
      label: 'Call Date',
      lens: 'transaction',
    },
    {
      key: 'call_type_selector',
      type: 'switch',
      label: 'Call Type',
      lens: 'transaction',
      references: [
        { value: 'earliest', viewValue: 'Earliest' },
        { value: 'economic', viewValue: 'Economic' },
      ],
    },
    {
      key: 'limit_stepdown_type',
      type: 'dropdown',
      label: 'Limit Stepdown Type',
      lens: 'transaction',
      references: [
        { value: 'PMIERS', viewValue: 'PMIERS' },
        { value: 'RIF', viewValue: 'RIF' },
      ],
    },
    {
      key: 'triggers',
      type: 'stepdown-trigger',
      label: 'Step Down Trigger',
      lens: 'transaction',
    },
    {
      key: 'lockout_period',
      type: 'date',
      label: 'Fill Up Period',
      lens: 'transaction',
    },
    {
      key: 'saved_pricing_curve_id',
      type: 'dropdown',
      label: 'Saved Pricing Curve',
      lens: 'transaction',
      references: [], // needs to be passed dynamically based on available pricing curves
    },
  ]

const QS_OPTIMIZATION_TRANSACTION_ATTRIBUTES: CreditOptimizationAttribute[] = [
  {
    key: 'annual_lr_cap',
    type: 'percentage',
    label: 'Annual LR Cap',
    lens: 'transaction',
  },
  {
    key: 'aggregate_lr_cap',
    type: 'percentage',
    label: 'Aggregate LR Cap',
    lens: 'transaction',
  },
  {
    key: 'profit_commission',
    type: 'percentage',
    label: 'Profit Commission',
    lens: 'transaction',
  },
  {
    key: 'ceding_commission',
    type: 'percentage',
    label: 'Ceding Comission',
    lens: 'transaction',
  },
  {
    key: 'effective_date',
    type: 'date',
    label: 'Effective Date',
    lens: 'transaction',
  },
  {
    key: 'maturity_date',
    type: 'date',
    label: 'Maturity Date',
    lens: 'transaction',
  },
  {
    key: 'call_date',
    type: 'date',
    label: 'Call Date',
    lens: 'transaction',
  },
]

export const CREDIT_TRANSACTION_TYPE_TO_OPTIMIZATION_ATTRIBUTES_MAP: Record<
  CreditTransactionType,
  CreditOptimizationAttribute[]
> = {
  pmi_agg_xl: XOL_ILN_OPTIMIZATION_TRANSACTION_ATTRIBUTES,
  pmi_iln: XOL_ILN_OPTIMIZATION_TRANSACTION_ATTRIBUTES,
  pmi_qs: QS_OPTIMIZATION_TRANSACTION_ATTRIBUTES,
  srt_agg_xl: XOL_ILN_OPTIMIZATION_TRANSACTION_ATTRIBUTES,
}

/** Min Attach must be less than Max Attach */
const ATTACH_MIN_MAX_VALIDATION: CreditOptimizationValidation = {
  type: 'MinLessThanMax',
  definition: {
    min: 'min_attach',
    max: 'max_attach',
  },
}

/** Min Detach must be less than Max Detach */
const DETACH_MIN_MAX_VALIDATION: CreditOptimizationValidation = {
  type: 'MinLessThanMax',
  definition: {
    min: 'min_detach',
    max: 'max_detach',
  },
}

/** Max Attach must be less than Min Detach */
const ATTACH_DETACH_MIN_MAX_VALIDATION: CreditOptimizationValidation = {
  type: 'MaxAttachLessThanMinDetach',
  definition: {
    min: 'max_attach',
    max: 'min_detach',
  },
}

/** Min Cession must be less than Max Cession */
const CESSION_MIN_MAX_VALIDATION: CreditOptimizationValidation = {
  type: 'MinLessThanMax',
  definition: {
    min: 'min_cession',
    max: 'max_cession',
  },
}

/** Value must be greater than 0 */
const POSITIVE_NUMBER_VALIDATION: CreditOptimizationValidation = {
  type: 'PositiveNumber',
}

const ATTACH_OPTIMIZATION_INPUTS: CreditOptimizationAttribute[] = [
  {
    key: 'min_attach',
    label: 'Minimum Attach',
    type: 'percentage',
    lens: 'tranche',
    validations: [ATTACH_MIN_MAX_VALIDATION],
  },
  {
    key: 'max_attach',
    label: 'Maxium Attach',
    type: 'percentage',
    lens: 'tranche',
    validations: [ATTACH_MIN_MAX_VALIDATION, ATTACH_DETACH_MIN_MAX_VALIDATION],
  },
  {
    key: 'attach_increment',
    label: 'Attach Increment',
    type: 'percentage',
    lens: 'tranche',
    validations: [POSITIVE_NUMBER_VALIDATION],
  },
]
const DETACH_OPTIMIZATION_INPUTS: CreditOptimizationAttribute[] = [
  {
    key: 'min_detach',
    label: 'Minimum Detach',
    type: 'percentage',
    lens: 'tranche',
    validations: [DETACH_MIN_MAX_VALIDATION, ATTACH_DETACH_MIN_MAX_VALIDATION],
  },
  {
    key: 'max_detach',
    label: 'Maxium Detach',
    type: 'percentage',
    lens: 'tranche',
    validations: [DETACH_MIN_MAX_VALIDATION],
  },
  {
    key: 'detach_increment',
    label: 'Detach Increment',
    type: 'percentage',
    lens: 'tranche',
    validations: [POSITIVE_NUMBER_VALIDATION],
  },
]
const CESSION_OPTIMIZATION_INPUTS: CreditOptimizationAttribute[] = [
  {
    key: 'min_cession',
    label: 'Minimum Cession',
    type: 'percentage',
    lens: 'tranche',
    validations: [CESSION_MIN_MAX_VALIDATION],
  },
  {
    key: 'max_cession',
    label: 'Maxium Cession',
    type: 'percentage',
    lens: 'tranche',
    validations: [CESSION_MIN_MAX_VALIDATION],
  },
  {
    key: 'cession_increment',
    label: 'Cession Increment',
    type: 'percentage',
    lens: 'tranche',
    validations: [POSITIVE_NUMBER_VALIDATION],
  },
]

const XOL_ILN_OPTIMIZATION_TRANCHE_ATTRIBUTES: CreditOptimizationAttribute[] = [
  ...ATTACH_OPTIMIZATION_INPUTS,
  ...DETACH_OPTIMIZATION_INPUTS,
  ...CESSION_OPTIMIZATION_INPUTS,
]

const QS_OPTIMIZATION_TRANCHE_ATTRIBUTES: CreditOptimizationAttribute[] = [
  ...CESSION_OPTIMIZATION_INPUTS,
]

export const CREDIT_TRANSACTION_TYPE_TO_OPTIMIZATION_TRANCHE_ATTRIBUTES_MAP: Record<
  CreditTransactionType,
  CreditOptimizationAttribute[]
> = {
  pmi_agg_xl: XOL_ILN_OPTIMIZATION_TRANCHE_ATTRIBUTES,
  pmi_iln: XOL_ILN_OPTIMIZATION_TRANCHE_ATTRIBUTES,
  pmi_qs: QS_OPTIMIZATION_TRANCHE_ATTRIBUTES,
  srt_agg_xl: XOL_ILN_OPTIMIZATION_TRANCHE_ATTRIBUTES,
}

export interface CreditOptimizationResponse {
  results: CreditOptimizationResult[]
  warning: string
}

export interface CreditOptimizationResult {
  weighted_avg: number
  total_weight: number
  transactionMetrics: CreditOptimizationTransactionMetric[]
  compareMetrics: CreditCompareMetric[]
}

export interface CreditOptimizationTransactionMetric {
  transaction_type: CreditTransactionType
  attach: number
  detach: number
  cession: number
  label?: string
}

export interface CreditOptimizationResultTable {
  header: CreditOptimizationResultTableHeader
  columns: SortTableColumnDef[]
  rows: CreditOptimizationResultTableRow[]
}

export interface CreditOptimizationResultTableHeader {
  transactionMetrics: CreditOptimizationTransactionMetric[] // to show xol & iln attach/detach
  compareMetrics: CreditCompareMetric[] // to show metric weights
}

export interface CreditOptimizationResultTableRow {
  id: number
  weighted_avg: number
  total_weight: number
  [key: string]: number // to allow for dynamic amount of transaction & compare metrics
}

export const WEIGHT_COLUMN_DEFS: SortTableColumnDef[] = [
  {
    id: 'weighted_avg',
    label: 'Weighted Average',
    valueType: 'numeric',
    numberFormat: '1.2-2',
    minWidth: '7.5rem',
  },
  {
    id: 'total_weight',
    label: 'Total Weight',
    valueType: 'numeric',
    minWidth: '6rem',
  },
]
export const XOL_COLUMN_DEF: SortTableColumnDef = {
  id: 'xol_cession',
  label: 'XOL Cession',
  valueType: 'percentage',
  minWidth: '5rem',
}
export const ILN_COLUMN_DEF: SortTableColumnDef = {
  id: 'iln_cession',
  label: 'ILN Cession',
  valueType: 'percentage',
  minWidth: '5rem',
}
export const QS_COLUMN_DEF: SortTableColumnDef = {
  id: 'qs_cession',
  label: 'QS Cession',
  valueType: 'percentage',
  minWidth: '5rem',
}
