import { type } from 'ramda'

export type ValueFn<T, TDatum, TOut = T | undefined> =
  | T
  | ((d: TDatum, i?: number) => TOut)

export function resolveValueFn<TDatum>(
  numberValueOrFunction: ValueFn<number, TDatum>,
  d: TDatum,
  i?: number
): number
export function resolveValueFn<TDatum>(
  stringValueOrFunction: ValueFn<string, TDatum>,
  d: TDatum,
  i?: number
): string
export function resolveValueFn<TDatum>(
  fn: ValueFn<string | number, TDatum>,
  d: TDatum,
  i?: number
): string | number
export function resolveValueFn<T, TDatum>(
  fn: ValueFn<T, TDatum>,
  d: TDatum,
  i?: number
): T
export function resolveValueFn<T, TDatum>(
  fn: ValueFn<T | string | number, TDatum>,
  d: TDatum,
  i?: number
): T | string | number | undefined {
  return type(fn) === 'Function' ? (fn as any)(d, i) : fn
}
