import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer } from '@ngrx/store'
import { CanFetch } from '../../core/model/general.model'
import { mutableOn } from '@shared/util/mutable-on'
import {
  BenchmarkSystemModeID,
  benchmarkSystemModeIDs,
} from '../model/benchmark-mode'
import { BenchmarkCompany } from '../model/benchmark.model'
import * as fromActions from './benchmark-company.actions'

export type BenchmarkCompanyState = Record<
  BenchmarkSystemModeID,
  EntityState<BenchmarkCompany> & CanFetch
>

const adaptersByMode = benchmarkSystemModeIDs.reduce((acc, mode) => {
  acc[mode] = createEntityAdapter<BenchmarkCompany>({
    selectId: entity => String(entity.id),
  })
  return acc
}, {} as Record<BenchmarkSystemModeID, EntityAdapter<BenchmarkCompany>>)

export function getBenchmarkCompanyStateAdapter(
  mode: BenchmarkSystemModeID
): EntityAdapter<BenchmarkCompany> {
  return adaptersByMode[mode]
}

export const initialState: BenchmarkCompanyState =
  benchmarkSystemModeIDs.reduce((acc, mode) => {
    acc[mode] = adaptersByMode[mode].getInitialState({
      error: null,
      loading: false,
    })
    return acc
  }, {} as BenchmarkCompanyState)

// Reducers

const companyReducer = createReducer(
  initialState,

  mutableOn(fromActions.fetchAllBenchmarkCompaniesByMode, (state, { mode }) => {
    // @ts-ignore
    state[mode].loading = true
    // @ts-ignore
    state[mode].error = null
  }),

  mutableOn(
    fromActions.fetchAllBenchmarkCompaniesByModeFailure,
    (state, { mode, error }) => {
      // @ts-ignore
      state[mode].loading = false
      // @ts-ignore
      state[mode].error = error
    }
  ),

  mutableOn(
    fromActions.fetchAllBenchmarkCompaniesByModeSuccess,
    (state, { mode, companies }) => {
      // @ts-ignore
      state[mode] = adaptersByMode[mode].setAll(companies, state[mode])
      // @ts-ignore
      state[mode].loading = false
      // @ts-ignore
      state[mode].error = null
      // @ts-ignore
      state[mode].fetchTime = Date.now()
    }
  )
)

export function reducer(
  state: BenchmarkCompanyState | undefined,
  action: Action
) {
  return companyReducer(state, action)
}
