export interface CreditTrancheQuoteList {
  tranche_id: string
  name: string
  color: string
  reinsurers_count: number
  quote_submitted_count: number
}

export interface CreditQuoteAuditRow {
  id: string
  user_name: string
  user_email: string
  reinsurer_name: string
  reinsurer_id: number
  rol: number
  reinsurer_participation_percent: number
  comments: string
  created_at: Date
  row_type: number
}

export interface CreditQuoteAuditTable {
  fots:  CreditQuoteAuditRow[]
  quotes: CreditQuoteAuditRow[]
}

export interface CreditQuoteOverviewRow {
  id: number
  reinsurer_name: string
  reinsurer_id: number
  rol: number
  reinsurer_participation_percent: number
  comments: string
  created_date: string
}

export interface CreditIssueFot {
  rol: number
  reinsurerParticipation: number
  comments: string
  tranche_id: string
  submission_id: number
}

export interface CreditUserInputFot{
  rol: number
  reinsurerParticipation: number
  comments: string
}

export enum CreditQuoteTab {
  Audit = 'Audit',
  Overview = 'Overview',
}

export const CREDIT_QUOTE_TABS = [
  CreditQuoteTab.Overview,
  CreditQuoteTab.Audit
]
