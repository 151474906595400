<app-client-select
  [clients]="clients"
  [selectedClientID]="selectedClientId"
  (clientChange)="onClientChange($event)"
></app-client-select>

<mat-form-field>
  <mat-label>
    <span>Years</span>
  </mat-label>
  <mat-select
    hideSingleSelectionIndicator
    placeholder="Year..."
    disableRipple
    multiple
    [value]="filters.years"
    (selectionChange)="onYearChange($event.value)"
  >
    <mat-option
      *ngFor="let year of years"
      [value]="year"
      [disabled]="year.id === disabledYearId"
      (click)="$event.stopPropagation()"
    >
      <span>{{ year.year }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-label>
    <span>Programs</span>
  </mat-label>
  <mat-select
    appBackdropClass="app-tier-select-backdrop"
    placeholder="Program..."
    disableRipple
    multiple
    [value]="filters.programs"
    (selectionChange)="onProgramChange($event.value)"
  >
    <mat-option
      *ngFor="let program of programs"
      [value]="program"
      [disabled]="program.id === disabledProgramId"
      (click)="$event.stopPropagation()"
    >
      <span>{{ program.name }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
