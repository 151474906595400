import { createReducer, on, Action } from '@ngrx/store'
import {
  fetchVolatilityMetrics,
  fetchVolatilityMetricsFailure,
  fetchVolatilityMetricsSuccess,
} from './volatility-metrics.actions'
import { VolatilityMetricsResult } from '../../model/volatility-metrics.model'

export interface State extends VolatilityMetricsResult {
  loading: boolean
  error: string | null
}

export const initialState: State = {
  loading: false,
  error: null,
  reductionStdDevPercentage: 0,
  reductionIn10yrAEPPercentage: 0,
  reductionIn250yrAEPPercentage: 0,
  netStandardDeviation: 0,
  cededVolatilityDownsidePercentage: 0,
  cededVolatilityPercentage: 0,
}

const volatilityMetricsReducer = createReducer(
  initialState,
  on(fetchVolatilityMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchVolatilityMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(
    fetchVolatilityMetricsSuccess,
    (
      state,
      {
        reductionStdDevPercentage,
        reductionIn10yrAEPPercentage,
        reductionIn250yrAEPPercentage,
        netStandardDeviation,
        cededVolatilityPercentage,
        cededVolatilityDownsidePercentage,
      }
    ) => ({
      ...state,
      loading: false,
      error: null,
      reductionStdDevPercentage,
      reductionIn10yrAEPPercentage,
      reductionIn250yrAEPPercentage,
      netStandardDeviation,
      cededVolatilityPercentage,
      cededVolatilityDownsidePercentage,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return volatilityMetricsReducer(state, action)
}
