<app-portfolio-details
  [gross]="grossMetrics$ | async"
  [ceded]="cededMetrics$ | async"
  [net]="netMetrics$ | async"
  [loading]="loading$ | async"
  [error]="error$ | async"
  [currency]="currency"
  [name]="name"
  [showResults]="showResults"
  [metrics]="portfolioMetrics$ | async"
  [lossFilters]="currentLossFilters$ | async"
  [rss]="rss$ | async"
  [currentCededPortfolioViewID]="currentCededPortfolioViewID$ | async"
  [currentGrossPortfolioViewID]="currentGrossPortfolioViewID$ | async"
  [currentNetPortfolioViewID]="currentNetPortfolioViewID$ | async"
  (returnPeriod1Change)="onReturnPeriod1Change($event)"
  (returnPeriod2Change)="onReturnPeriod2Change($event)"
  (returnPeriod3Change)="onReturnPeriod3Change($event)"
  (returnPeriod4Change)="onReturnPeriod4Change($event)"
  (returnPeriod5Change)="onReturnPeriod5Change($event)"
  (portfolioTypeChange)="onPortfolioTypeChange($event)"
  (aggregationMethodChange)="onAggregationMethodChange($event)"
  (perspectiveChange)="onPerspectiveChange($event)"
  (lossFilterClick)="onLossFilterClick($event)"
  dialog
></app-portfolio-details>
