import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { analyzereConstants } from '@shared/constants/analyzere'
import { MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { CurrencyPipe, DecimalPipe } from '@angular/common'
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs'
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators'
import { customIndexValuesToString } from '../../layers/indexed-layer'
import {
  LayerMetrics,
  LayerViewMetricsRPPayload,
  LossSetContribution,
} from '../../model/layers-metrics.model'
import { Layer, PhysicalLayer, SimpleLayer } from '../../model/layers.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { CustomIndexedDialogComponent } from '../layer/custom-indexed-dialog/custom-indexed-dialog.component'
import { MultiSectionDialogComponent } from '../layer/multi-section/multi-section-dialog.component'
import { Section } from '../layer/multi-section/multi-section.service'
import { TopDropDialogContainerComponent } from '../layer/top-drop-dialog/top-drop-dialog.container'
import {
  CustomIndexProperties,
  MultiSectionProperties,
  TopDropProperties,
} from '../properties-panel.model'
import {
  selectCededLayers,
  selectCededLayersLoading,
  selectCededPortfolioLoading,
  selectCededSelectedLayer,
  selectCurrentCededLayer,
  selectCurrentProgram,
  selectCurrentSLCededLayer,
  selectLayersSLViewMetricsWithID,
  selectLayersViewMetricsLoading,
  selectLayersViewMetricsWithID,
  selectParentGrossLossSetLayers,
  selectSharedLimitDeleting,
  selectSharedLimitEditLayer,
  selectSharedLimitProperties,
  selectSharedLimitSelectedLayer,
  selectSharedLimitUpdating,
  selectCededLayersState,
  selectLayerTypeTechnicalPremiumValues,
} from '../../store/analysis.selectors'
import {
  addLayer,
  getBackAllocatedContribution,
  setSelectedLayer,
  updateLayer,
  updateMultiSectionFromDialog,
  updatePhysicalLayer,
  updateMultiplePhysicalLayer,
  getAALValues,
} from '../../store/ceded-layers/layers.actions'
import { LayerState, State } from '../../store/ceded-layers/layers.reducer'
import { SharedLimitProperties } from '../../store/grouper/program-group.model'
import {
  cancelSharedLimit,
  deleteSharedLimit,
  setSharedLimitProperties,
  updateSharedLimitProperties,
} from '../../store/grouper/shared-limit/grouper-shared-limit.actions'
import {
  fetchLayersViewMetrics,
  fetchLayersViewMetricsRP,
} from '../../store/metrics/layers-metrics.actions'
import {
  CurrencyCode,
  MonetaryUnit,
} from '../../../api/analyzere/analyzere.model'
import { Program } from '../../../core/model/program.model'
import { Reinsurer } from '../../../core/model/reinsurer.model'
import { AppState } from '../../../core/store'
import {
  selectCurrencyList,
  selectCurrentAnalysisProfile,
  selectCurrentStudyID,
} from '../../../core/store/broker/broker.selectors'
import { setColorChange } from '../../../core/store/program/program.actions'
import { SavedPricingCurveEntry } from '../../../pricingcurve/model/pricing-curve.model'
import { selectSavedCurvesIncludingDefault } from '../../../pricingcurve/store/pricing-curve.selectors'
import {
  selectCurrentStudyReinsurers,
  selectCurrentStudySLReinsurers,
} from '../../../reinsurers/store/reinsurers.selectors'
import { filterTowerLayerList } from '../../model/layers.util'
import { layerIds } from '../../model/layer-palette.model'
import { LayerTypeDefaultEntry } from '../../technical-premium/technical-premium.model'
import { LayerService } from '@shared/services/layer.service'
import { resetAnalysis } from '../../store/analysis.actions'
import { isMultiSectionLayer } from '../../layers/multi-section-layer'
@Component({
  selector: 'app-properties-container',
  templateUrl: './properties.container.html',
  styleUrls: ['./properties.container.scss'],
})
export class PropertiesContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  private selectedViewID$ = new BehaviorSubject<string | null>(null)

  currentProgram$: Observable<Program | undefined>
  layer$: Observable<LayerState | null>
  lossSetLayers$: Observable<LossSetLayer[]>
  layerViewsMetrics$: Observable<LayerMetrics | null>
  layersLoading$: Observable<boolean>
  layersViewMetricsLoading$: Observable<boolean>
  layers$: Observable<LayerState[]>
  layerList$: Observable<SimpleLayer[]>
  newSharedLayer$: Observable<SharedLimitProperties>
  selectedSharedLayer$: Observable<Layer | null>
  studyID$: Observable<string | null>
  sharedLimitDeleting$: Observable<boolean>
  sharedLimitUpdating$: Observable<boolean>
  sharedCurrentCurrency$: Observable<string | null>
  currencyList$: Observable<CurrencyCode[]>
  currentCededLayer$: Observable<LayerState | null>
  reinsurersList$: Observable<Reinsurer[] | null>
  reinsurersListSL$: Observable<Reinsurer[] | null>
  savedPricingCurves$: Observable<SavedPricingCurveEntry[] | null>
  cededLayers$: Observable<State>
  layerTypeEntries$: Observable<LayerTypeDefaultEntry>

  selectedLayerID: string
  checkLayerMetrics = false

  layerListId: string
  layerIds = layerIds
  sharedLimitCurrency: string

  @Input() id: PortfolioSetID | null
  @Input() metrics: PortfolioMetrics
  @Input() groupValue: string
  @Input() isAddSharedLayer = false
  @Input() isDeleteSharedLayer = false
  @Input() designDirty: boolean
  @Input() isLibRE: boolean

  @Input()
  set selectedViewID(value: string | null) {
    this.selectedViewID$.next(value)
    this._selectedViewID = value
  }
  get selectedViewID() {
    return this._selectedViewID
  }
  private _selectedViewID: string | null
  @Output() handlePastedLayer = new EventEmitter<boolean>()
  @Output() selectedSLCurrency = new EventEmitter<string>()
  @Output() structureCurrency = new EventEmitter<string>()

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private layerService: LayerService
  ) {}

  ngOnInit(): void {
    this.savedPricingCurves$ = this.store.pipe(
      select(selectSavedCurvesIncludingDefault)
    )
    this.layerTypeEntries$ = this.store.select(
      selectLayerTypeTechnicalPremiumValues
    )
    this.currentProgram$ = this.store.select(selectCurrentProgram)
    this.sharedLimitDeleting$ = this.store.select(selectSharedLimitDeleting)
    this.sharedLimitUpdating$ = this.store.select(selectSharedLimitUpdating)
    this.reinsurersList$ = this.store.select(selectCurrentStudyReinsurers)
    this.reinsurersListSL$ = this.store.select(selectCurrentStudySLReinsurers)

    this.layers$ = this.store.select(selectCededLayers).pipe(
      map((layers: LayerState[]) => {
        return layers
          .filter(l => !l.deleted)
          .sort((a, b) => {
            // @ts-ignore
            return a.layer?.modified_date > b.layer?.modified_date ? 1 : -1
          })
      })
    )
    this.layerList$ = this.store.select(selectCededLayers).pipe(
      map((layer: LayerState[]) => {
        return layer
          .filter(filterTowerLayerList)
          .map(a => {
            return {
              id: String(a.layer.id),
              description: String(a.layer.physicalLayer.description),
              layerType: String(a.layer.meta_data.sage_layer_type),
              layerInfoText: this.generateLayerInfo(
                String(a.layer.meta_data.sage_layer_type),
                a.layer.physicalLayer
              ),
            }
          })
          .sort((a, b) => {
            // @ts-ignore
            return a.layer?.physicalLayer?.description >
              // @ts-ignore
              b.layer?.physicalLayer?.description
              ? 1
              : -1
          })
      })
    )

    this.currentCededLayer$ = this.store.select(selectCurrentCededLayer)
    this.cededLayers$ = this.store.pipe(select(selectCededLayersState))

    this.lossSetLayers$ = this.store.select(selectParentGrossLossSetLayers)
    this.newSharedLayer$ = this.store.select(selectSharedLimitProperties)
    this.selectedSharedLayer$ = this.store.select(selectSharedLimitEditLayer)
    this.layersLoading$ = combineLatest([
      this.store.select(selectCededLayersLoading),
      this.store.select(selectCededPortfolioLoading),
    ]).pipe(
      map(
        ([layersLoading, portfolioLoading]) => layersLoading || portfolioLoading
      )
    )

    this.layer$ = combineLatest([
      this.store.select(selectCededSelectedLayer),
      this.store.select(selectCurrentCededLayer),
      this.store.select(selectSharedLimitSelectedLayer),
      this.store.select(selectCurrentSLCededLayer),
    ]).pipe(
      map(
        ([
          _selectSelectedLayer,
          _selectCurrentLayer,
          _selectSelectedSLLayer,
          _selectCurrentSLLayer,
        ]) => {
          if (this.isDeleteSharedLayer) {
            if (_selectSelectedSLLayer) {
              this.selectedLayerID = _selectSelectedSLLayer
            }
            if (_selectCurrentSLLayer) {
              this.checkLayerMetrics =
                _selectCurrentSLLayer.layer.viewMetrics.metrics === null
            }
            return _selectCurrentSLLayer
          } else {
            if (_selectSelectedLayer) {
              this.layerListId = String(_selectSelectedLayer)
              this.selectedLayerID = _selectSelectedLayer
            } else {
              this.layerListId = ''
            }
            if (_selectCurrentLayer) {
              this.checkLayerMetrics =
                _selectCurrentLayer.layer.viewMetrics.metrics === null
            }
            return _selectCurrentLayer
          }
        }
      )
    )

    this.studyID$ = this.store.select(selectCurrentStudyID)

    this.selectedViewID$.pipe(takeUntil(this.destroy$)).subscribe(viewID => {
      if (viewID && !this.isDeleteSharedLayer) {
        this.layerViewsMetrics$ = this.store
          .select(selectLayersViewMetricsWithID, {
            layerViewMetricID: viewID,
          })
          .pipe(
            debounceTime(500),
            tap(metrics => {
              if (
                (metrics === null || this.checkLayerMetrics) &&
                this.id !== null &&
                this._selectedViewID !== null
              ) {
                this.store.dispatch(
                  fetchLayersViewMetrics({
                    ...this.id,
                    layerViewID: viewID,
                    isSL: this.isDeleteSharedLayer,
                  })
                )

                const pvp: LayerViewMetricsRPPayload = {
                  aggregationMethod: 'AEP',
                  returnPeriod1: 25,
                  returnPeriod2: 100,
                  returnPeriod3: 250,
                  returnPeriod4: 10,
                  returnPeriod5: 500,
                  perspective: 'Loss',
                }
                this.onSetLayerMetrics({
                  id: this.id,
                  pvp,
                  viewID,
                  layerID: this.selectedLayerID,
                })
              }
            })
          )
        this.layersViewMetricsLoading$ = this.store.select(
          selectLayersViewMetricsLoading,
          { layerViewMetricID: viewID }
        )
      } else if (viewID && this.isDeleteSharedLayer) {
        this.layerViewsMetrics$ = this.store
          .select(selectLayersSLViewMetricsWithID, {
            layerViewMetricID: viewID,
          })
          .pipe(
            debounceTime(500),
            tap(metrics => {
              if (
                (metrics === null || this.checkLayerMetrics) &&
                this.id !== null &&
                this._selectedViewID !== null
              ) {
                this.store.dispatch(
                  fetchLayersViewMetrics({
                    ...this.id,
                    layerViewID: viewID,
                    isSL: this.isDeleteSharedLayer,
                  })
                )

                const pvp: LayerViewMetricsRPPayload = {
                  aggregationMethod: 'AEP',
                  returnPeriod1: 25,
                  returnPeriod2: 100,
                  returnPeriod3: 250,
                  returnPeriod4: 10,
                  returnPeriod5: 500,
                  perspective: 'Loss',
                }
                this.onSetLayerMetrics({
                  id: this.id,
                  pvp,
                  viewID,
                  layerID: this.selectedLayerID,
                })
              }
            })
          )
        this.layersViewMetricsLoading$ = this.store.select(
          selectLayersViewMetricsLoading,
          { layerViewMetricID: viewID }
        )
        this.sharedCurrentCurrency$ = this.store
          .select(selectCurrentAnalysisProfile)
          .pipe(
            map(profile =>
              profile
                ? profile.exchange_rate_profile.exchange_rate_table
                    .base_currency
                : null
            )
          )
      }
    })
    this.currencyList$ = this.store.select(selectCurrencyList)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  generateLayerInfo(layerType: string, pl: PhysicalLayer): string {
    const participationText: string = String(
      this.decimalPipe.transform(Math.abs(pl.participation) * 100, '1.2-2')
    )
    let limitValue: number =
      layerType === this.layerIds.noncatRisk
        ? Number(pl.riskLimit?.value)
        : Number(pl.limit?.value)

    if (layerType === this.layerIds.catMultisection) {
      limitValue = pl.meta_data.contractOccurrenceLimit || limitValue
    }
    const modifiedLimitValue = limitValue / 1000000

    const limitCurrency =
      layerType === this.layerIds.noncatRisk
        ? pl.riskLimit?.currency
        : pl.limit?.currency
    let attachmentValue: number =
      layerType === this.layerIds.noncatRisk
        ? Number(pl.riskAttachment?.value)
        : Number(pl.attachment?.value)
    attachmentValue /= 1000000
    const attachmentCurrency =
      layerType === this.layerIds.noncatRisk
        ? pl.riskAttachment?.currency
        : pl.attachment?.currency

    let attachmentValueText: string = String(
      this.currencyPipe.transform(
        modifiedLimitValue,
        limitCurrency,
        'symbol-narrow',
        '1.0-1'
      ) + 'M'
    )
    if (
      pl.meta_data.isLimitUnlimited ||
      limitValue >= analyzereConstants.unlimitedValue
    ) {
      attachmentValueText = 'Unlimited'
    }
    const currencyText: string = String(
      this.currencyPipe.transform(
        attachmentValue,
        attachmentCurrency,
        'symbol-narrow',
        '1.0-1'
      ) + 'M'
    )

    if (isMultiSectionLayer({ meta_data: { sage_layer_type: layerType } })) {
      const aggAttachText =
        String(
          this.currencyPipe.transform(
            pl.aggregateAttachment.value,
            pl.aggregateAttachment.currency,
            'symbol-narrow',
            '1.0-1'
          )
        ) + 'M'
      if (pl.aggregateAttachment.value > 0) {
        return `${attachmentValueText} xs ${aggAttachText} AAD`
      } else {
        return `${attachmentValueText}`
      }
    }

    return `${participationText}% of ${attachmentValueText} xs ${currencyText}`
  }
  getSharedLimitCurrency(currency: string): void {
    this.sharedLimitCurrency = currency
  }

  onSetLayerMetrics(value: {
    id: PortfolioSetID
    pvp: LayerViewMetricsRPPayload
    viewID: string
    layerID: string
  }): void {
    this.store.dispatch(
      fetchLayersViewMetricsRP({
        ...value.id,
        ...value.pvp,
        layerViewID: value.viewID,
        layerID: value.layerID,
        isSL: this.isDeleteSharedLayer,
      })
    )
  }

  onLayerResize($event: { layer: Partial<PhysicalLayer>; def: string }): void {
    if (!$event.layer.logicalLayerID) {
      return
    }
    this.store.dispatch(
      updatePhysicalLayer({
        id: $event.layer.logicalLayerID,
        change: {
          ...$event.layer,
          meta_data: {
            ...$event.layer.meta_data,
            isChangedInDesign: true,
          },
        },
        // ? onLayerResize() runs when top or drops are updated in layer propeties (right panel)
        // ? this will run syncTopAndDrop() with different property and different layer than hidden
        property: $event.def,
      })
    )
  }

  onLayerRefResize(partialLayer: Partial<Layer>): void {
    if (partialLayer.physicalLayer) {
      this.store.dispatch(
        updateLayer({
          id: partialLayer.physicalLayer.logicalLayerID,
          change: {
            ...partialLayer,
            meta_data: {
              ...partialLayer.meta_data,
              isChangedInDesign: true,
            },
          },
        })
      )
    }
  }

  onColorChange(colorEvent: {
    layer: Partial<PhysicalLayer>
    structure: Program
    color: string
  }) {
    setTimeout(() => {
      this.store.dispatch(
        setColorChange({
          layer: colorEvent.layer,
          structure: colorEvent.structure,
          color: colorEvent.color,
        })
      )
    }, 0)
    setTimeout(() => {
      this.store.dispatch(
        updatePhysicalLayer({
          // tslint:disable-next-line: no-non-null-assertion
          id: colorEvent.layer.logicalLayerID!,
          change: colorEvent.layer,
        })
      )
    }, 0)
  }

  onSharedLimitChange(properties: SharedLimitProperties): void {
    this.store.dispatch(setSharedLimitProperties({ properties }))
  }

  onDeleteSharedLayerClick(layer: Layer): void {
    this.store.dispatch(deleteSharedLimit({ sharedLimitLayer: layer }))
  }

  onUpdateSharedLayerPropertiesClick(layer: Layer): void {
    this.store.dispatch(
      updateSharedLimitProperties({ sharedLimitLayer: layer })
    )
  }

  onCloseClick(): void {
    this.store.dispatch(cancelSharedLimit())
  }

  onDeleteLayer(layerRef: string): void {
    this.layerService.deleteLayerCheck(layerRef)
  }

  onShowTopDropDialog(event: TopDropProperties): void {
    this.dialog
      .open(TopDropDialogContainerComponent, {
        data: event,
      })
      .afterClosed()
      .subscribe((result: Partial<PhysicalLayer>[]) => {
        if (result) {
          this.store.dispatch(
            updateMultiplePhysicalLayer({
              change: result,
            })
          )
        } else {
          this.store.dispatch(resetAnalysis())
        }
      })
  }

  onShowMultiSectionDialog(event: MultiSectionProperties): void {
    this.dialog
      .open(MultiSectionDialogComponent, { data: event, disableClose: true })
      .afterClosed()
      .pipe(filter(result => !!result)) // Nothing to do on cancel
      .subscribe(result => {
        const sections = result as Section[]
        this.store.dispatch(
          updateMultiSectionFromDialog({ layer: event.layer, sections })
        )
      })
  }

  onShowCustomIndexDialog(event: CustomIndexProperties) {
    this.dialog
      .open(CustomIndexedDialogComponent, {
        data: event,
        disableClose: true,
      })
      .beforeClosed()
      .pipe(filter(result => !!result)) // Nothing to do on cancel
      .subscribe(result => {
        const indexValues = result as number[]

        // tslint:disable-next-line: variable-name
        const meta_data = {
          ...event.layer.physicalLayer.meta_data,
          customIndexValues: customIndexValuesToString(indexValues),
          isChangedInDesign: true,
        }
        this.store.dispatch(
          updatePhysicalLayer({
            id: event.layer.id,
            change: {
              meta_data,
            },
          })
        )
      })
  }

  onCreateHiddenLayer(event: any): void {
    const logicalIDTemp = new Date().getTime() + '' + Math.random() * 100000
    let minLimit: MonetaryUnit = {
      value: 0,
      currency: event.layers[0].layer.physicalLayer.franchise.currency,
    }
    if (event.layers.length > 0) {
      event.layers.forEach((layer: any) => {
        if (
          minLimit.value === 0 ||
          layer.layer.physicalLayer.limit.value < minLimit.value
        ) {
          minLimit = layer.layer.physicalLayer.limit
        }
      })
    } else {
      minLimit = {
        value: 300000000,
        currency: event.layers[0].layer.physicalLayer.franchise.currency,
      }
    }

    this.store.dispatch(
      addLayer({
        layer: {
          id: logicalIDTemp,
          meta_data: {
            client: event.layers[0].layer.physicalLayer.meta_data.client,
            perspective:
              event.layers[0].layer.physicalLayer.meta_data.perspective,
            program_name:
              event.layers[0].layer.physicalLayer.meta_data.program_name,
            program_type:
              event.layers[0].layer.physicalLayer.meta_data.program_type,
            rol: event.layers[0].layer.physicalLayer.meta_data.rol,
            rol_type: event.layers[0].layer.physicalLayer.meta_data.rol_type,
            sage_layer_type: event.item.id,
            year: event.layers[0].layer.physicalLayer.meta_data.year,
            lossfilter_name:
              event.layers[0].layer.physicalLayer.lossfilter_name,
            sage_layer_subtype: event.item.subtype,
            oldDropSelected: event.drop,
          },
          layerRefs: [event.top, event.drop],
          lossSetFilter: '',
          lossSetLayers: [],
          physicalLayer: {
            logicalLayerID: logicalIDTemp,
            id: (new Date().getTime() + '' + Math.random() * 100000).replace(
              '.',
              ''
            ),
            type: event.layers[0].layer.physicalLayer.type,
            attachment: {
              value: 0,
              currency: event.layers[0].layer.physicalLayer.franchise.currency,
            },
            limit: minLimit,
            participation: -0.25,
            premium: {
              value: 0,
              currency: event.layers[0].layer.physicalLayer.franchise.currency,
            },
            fees: [],
            aggregateLimit: {
              value: 2 * minLimit.value,
              currency: minLimit.currency,
            },
            aggregateAttachment: {
              value: 0,
              currency: event.layers[0].layer.physicalLayer.franchise.currency,
            },
            description: event.layers[0].layer.physicalLayer.description,
            meta_data: {
              client: event.layers[0].layer.physicalLayer.meta_data.client,
              perspective:
                event.layers[0].layer.physicalLayer.meta_data.perspective,
              program_name:
                event.layers[0].layer.physicalLayer.meta_data.program_name,
              program_type:
                event.layers[0].layer.physicalLayer.meta_data.program_type,
              rol: event.layers[0].layer.physicalLayer.meta_data.rol,
              rol_type: event.layers[0].layer.physicalLayer.meta_data.rol_type,
              sage_layer_type: event.item.id,
              year: event.layers[0].layer.physicalLayer.meta_data.year,
              sage_layer_subtype: event.item.subtype,
            },
            reinstatements: [],
            franchise: {
              value: 0,
              currency: event.layers[0].layer.physicalLayer.franchise.currency,
            },
            xcoord: 469,
            ycoord: 110,
          },
          sharedLayerID: '',
          viewMetrics: {
            error: null,
            loading: false,
            metrics: null,
            rss: null,
          },
        },
      })
    )
  }

  onAALClick(event: { id: string }): void {
    this.store.dispatch(getAALValues({ id: event.id }))
  }

  onContributionClick(event: {
    lossSets: LossSetContribution[]
    id: string
  }): void {
    this.store.dispatch(
      getBackAllocatedContribution({ lossSets: event.lossSets, id: event.id })
    )
  }

  getSelectedCurrency(currency: string): void {
    let currentLayer: any = null
    this.layer$.subscribe(layer => {
      if (layer) {
        currentLayer = JSON.parse(JSON.stringify(layer))
      }
    })
    if (currentLayer) {
      this.store
        .pipe(select(selectCededLayers), take(1))
        .subscribe(layerStates => {
          layerStates.forEach(reslayer => {
            if (
              currentLayer.layer.id === reslayer.layer.id &&
              reslayer.layer.meta_data.sage_layer_subtype === layerIds.feeder
            ) {
              layerStates.map(selLayer => {
                if (selLayer.layer.layerRefs[0] === reslayer.layer.id) {
                  const aggLayer = JSON.parse(JSON.stringify(selLayer))
                  this.updateLayersState(aggLayer, currency)
                }
              })
            }

            if (
              currentLayer.layer.id === reslayer.layer.id &&
              (reslayer.layer.meta_data.sage_layer_type === layerIds.catAg ||
                reslayer.layer.meta_data.sage_layer_type ===
                  layerIds.noncatAg) &&
              reslayer.layer.meta_data.rol_type === 'agg'
            ) {
              layerStates.map(selLayer => {
                if (selLayer.layer.id === currentLayer.layer.layerRefs[0]) {
                  const aggLayer = JSON.parse(JSON.stringify(selLayer))
                  this.updateLayersState(aggLayer, currency)
                }
              })
            }
          })
        })
      this.updateLayersState(currentLayer, currency)
    }
  }

  updateLayersState(layer: LayerState, currency: string): void {
    layer.layer.physicalLayer.aggregateAttachment.currency = currency
    layer.layer.physicalLayer.aggregateLimit.currency = currency
    layer.layer.physicalLayer.attachment.currency = currency
    layer.layer.physicalLayer.franchise.currency = currency
    layer.layer.physicalLayer.limit.currency = currency
    layer.layer.physicalLayer.premium.currency = currency
    if (layer.layer.physicalLayer.riskLimit) {
      layer.layer.physicalLayer.riskLimit.currency = currency
    }
    if (layer.layer.physicalLayer.riskAttachment) {
      layer.layer.physicalLayer.riskAttachment.currency = currency
    }
    if (layer.layer.physicalLayer.event_limit) {
      layer.layer.physicalLayer.event_limit.currency = currency
    }
    if (layer.layer.physicalLayer.payout) {
      layer.layer.physicalLayer.payout.currency = currency
    }
    if (layer.layer.physicalLayer.trigger) {
      layer.layer.physicalLayer.trigger.currency = currency
    }
    this.store.dispatch(
      updatePhysicalLayer({
        id: layer.layer.id,
        change: layer.layer.physicalLayer,
      })
    )
    this.store.dispatch(
      updateLayer({
        id: layer.layer.id,
        change: {
          currency,
          meta_data: {
            ...layer.layer.meta_data,
            isChangedInDesign: true,
          },
        },
      })
    )
  }

  onHandlePastedLayer(e: boolean): void {
    this.handlePastedLayer.emit(e)
  }
  onStructureCurrencyEvent(event: string | undefined): void {
    this.structureCurrency.emit(event)
  }
  getSelectedSLCurrency(currency: string): void {
    this.selectedSLCurrency.emit(currency)
  }

  doSelectLayer(id: string): void {
    this.layerListId = id
    this.store.dispatch(setSelectedLayer({ id }))
  }
}


