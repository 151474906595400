import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-logo, button[appLogo], a[appLogo]',
  styleUrls: ['app-logo.component.scss'],
  templateUrl: 'app-logo.component.html',
})
export class AppLogoComponent {
  @Input() set light(value: boolean) {
    this._light = value
  }
  _light = false

  onPatentClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    window.open('https://global.lockton.com/re/en/sage-patents', '_blank').focus();
  }
}
