import { createAction, props } from '@ngrx/store'
import { Program } from '../../../../core/model/program.model'
import { ApplicationError } from '../../../../error/model/error'
import { Layer } from '../../../model/layers.model'
import { Metadata } from 'src/app/api/analyzere/analyzere.model'

export interface AddProgramProps {
  program: Program
  ordinal?: number
  parentGroupID?: string
  isDuplicate?: boolean
}

export interface MoveProgramProps {
  id: string
  fromGroupID: string
  toGroupID: string
  ordinal: number
  isDuplicate?: boolean
}

/**
 * Add Program to Group
 */
export const addProgramToGroup = createAction(
  '[Grouper Program] Add',
  props<AddProgramProps>()
)

/**
 * Remove Program from Group
 */
export const removeProgramFromGroup = createAction(
  '[Grouper Program] Remove',
  props<{ program: Program }>()
)

/**
 * Move Program
 */
export const moveGrouperProgram = createAction(
  '[Grouper Program] Move',
  props<MoveProgramProps>()
)

interface GrouperSetMinimizedProgramProps {
  /* ID of Program to set minimized value */
  program: Program
  /* Value: true for minimized, false for unminimized, undefined to toggle */
  value?: boolean
}

/**
 * Set Minimized Program
 */
export const setGrouperMinimizedProgram = createAction(
  '[Grouper Program] Set Minimized Metric',
  props<GrouperSetMinimizedProgramProps>()
)

/**
 * Fetch Layers
 */
export const fetchGrouperProgramLayers = createAction(
  '[Grouper Program] Fetch Layers',
  props<{ program: Program }>()
)

export const fetchGrouperProgramLayersSuccess = createAction(
  '[Grouper Program] Fetch Layers Success',
  props<{ program: Program; cededLayers: Layer[]; netLayers: string[] }>()
)

export const fetchGrouperProgramLayersFailure = createAction(
  '[Grouper Program] Fetch Layers Failure',
  props<{ program: Program; error: ApplicationError }>()
)

// Misc.

export const setGrouperProgramShowAgg = createAction(
  '[Grouper Program] Set Show Agg',
  props<{ check: boolean; program: Program }>()
)

export const setGrouperProgramShowOcc = createAction(
  '[Grouper Program] Set Show Occ',
  props<{ check: boolean; program: Program }>()
)

export const setGrouperSelectedProgram = createAction(
  '[Grouper Program] Set Selected',
  props<{ selected: string; program: Program }>()
)

export const updateProgramEntityRecon = createAction(
  '[Grouper Program] Reconcile Update',
  props<{ layerId: string; metaData: Partial<Metadata> }>()
)
