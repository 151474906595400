import {
  PortfolioSetAndStudyIDs,
  PortfolioViewID,
} from '../../model/portfolio-set.model'
import { props, createAction } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { TailMetricsResult } from '../../model/tail-metrics.model'

export const fetchTailMetrics = createAction(
  '[Tail Metrics] Fetch',
  props<PortfolioSetAndStudyIDs & PortfolioViewID>()
)

export const fetchTailMetricsSuccess = createAction(
  '[Tail Metrics] Fetch Success',
  props<PortfolioSetAndStudyIDs & { tailMetrics: TailMetricsResult }>()
)

export const fetchTailMetricsFailure = createAction(
  '[Tail Metrics] Fetch Failure',
  props<PortfolioSetAndStudyIDs & { error: ApplicationError }>()
)
