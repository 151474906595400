import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { GraphingColorScaleOptions } from '@graphing/utils/graphing-color-scale'
import {
  createGraphingValueFormatter,
  createGraphingValueTransformer,
  GraphingValueFormat,
  GraphingValueFormatPreset,
  GraphingValueTransformer,
  isGraphingValueFormatter,
  toGraphingValueFormat,
  ValueFormatter,
} from '@graphing/utils/graphing-value-formatter'

const REGEX_VALID_NUMBER = /^-?\d*\.?\d*$/
const UNITS = ['K', 'M', 'B', 'T']

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-graphing-color-legend-controls',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      .open {
        margin: 1rem 0 1.5rem;
      }

      .controls {
        border-radius: var(--border-radius-big);
        margin-left: 2rem;
        padding: var(--stack-small);
        background: var(--bg-1-translucent-lit);
        backdrop-filter: blur(3px);
        border: 1px solid var(--border-translucent-subtle);
      }

      .control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
      }

      .field {
        font-family: var(--font-header-family);
        width: calc(50% - var(--stack-small) / 2);
      }

      label {
        flex: 0 0 2.4rem;
        text-align: center;
        font-family: var(--font-header-family);
        font-weight: var(--font-header-weight);
        font-size: var(--font-size-tiny);
        color: var(--subtle);
      }

      mat-icon {
        color: var(--primary);
        cursor: pointer;
        top: 3px;
        position: relative;
      }

      mat-icon:hover {
        color: var(--primary-lit);
      }

      button.ok {
        flex: 1 1 0%;
      }

      :host .field ::ng-deep .mat-mdc-text-field-wrapper {
        background-color: hsla(0, 0%, 100%, .1);
      }

      :host .field ::ng-deep .mdc-text-field {
        padding: 0 !important;
      }

      :host .field ::ng-deep .mat-mdc-form-field-flex {
        padding: .25em .75em 0 .75em;
      }

      :host .field ::ng-deep .mat-mdc-form-field-infix {
        padding: .25em 0 .75em;
      }

      .field mat-label {
        font-size: 85%;
      }

      .field input {
        padding-top: 5px;
      }

      :host ::ng-deep .mdc-line-ripple::before {
        display: block;
      }

      :host ::ng-deep .mdc-line-ripple::after {
        display: block;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none; /* Hide up/dow in Chrome/Safari
             /* display: none; // Chrome issue on hover */
        margin: 0; /* // Remove potential margin */
      }

      input[type='number'] {
        -moz-appearance: textfield; /* Hide up/down in Firefox */
      }
    `,
  ],
  template: `
    <div *ngIf="!loading" class="open">
      <mat-icon (click)="onOpen()">tune</mat-icon>
    </div>
    <div *ngIf="!loading && open" class="controls">
      <div class="control">
        <mat-form-field class="field" appearance="fill">
          <mat-label>Min ({{ formatter(extents[0]) }})</mat-label>
          <input
            #minInput
            matInput
            [ngModel]="minDisplay"
            (ngModelChange)="onMinChange($event)"
            (focus)="minFocus = true"
            (focusout)="minFocus = false"
          />
          <mat-error *ngIf="!isMinValid"> Min value is invalid. </mat-error>
          <mat-hint *ngIf="minUnit != '' && minFocus == true"
            >Unit: {{ minUnit }}</mat-hint
          >
        </mat-form-field>

        <mat-form-field class="field" appearance="fill">
          <mat-label>Max ({{ formatter(extents[1]) }})</mat-label>
          <input
            #maxInput
            matInput
            [ngModel]="maxDisplay"
            (ngModelChange)="onMaxChange($event)"
            (focus)="maxFocus = true"
            (focusout)="maxFocus = false"
          />
          <mat-hint *ngIf="maxUnit != '' && maxFocus == true"
            >Unit: {{ maxUnit }}</mat-hint
          >
        </mat-form-field>
      </div>

      <div class="control">
        <button
          appButton
          accent
          center
          class="ok"
          [disabled]="!isValid"
          (click)="onOK()"
        >
          Apply
        </button>
        <button appButton primary (click)="onReset()">Reset</button>
      </div>
    </div>
  `,
})
export class GraphingColorLegendControlsComponent implements OnChanges {
  open = false
  minRaw = '0'
  maxRaw = '0'
  minFormatted = '0'
  maxFormatted = '0'
  minAsUnit = 0
  maxAsUnit = 0
  minFocus = false
  maxFocus = false
  transform: GraphingValueTransformer
  formatter: ValueFormatter
  numberFormat = Intl.NumberFormat('en', { notation: 'compact' } as any)
  minUnit = ''
  maxUnit = ''
  extentsInitialized = false

  @Input() options?: Partial<GraphingColorScaleOptions>

  private _extents: [number, number] = [0, 100]
  @Input() set extents(extents: [number, number]) {
    this._extents = extents
    if (this.extentsInitialized === false) {
      if (!this.isPercent()) {
        this.minUnit = this.unit(extents[0])
        this.maxUnit = this.unit(extents[1])
      }
      this.minAsUnit = extents[0]
      this.maxAsUnit = extents[1]
      this.extentsInitialized = true
    }
  }
  get extents(): [number, number] {
    return this._extents
  }

  @Input() format?: GraphingValueFormat | GraphingValueFormatPreset
  @Input() loading = false

  @Output() optionsChange = new EventEmitter<
    Partial<GraphingColorScaleOptions>
  >()

  @ViewChild('minInput', { static: false })
  minInput: ElementRef<HTMLInputElement>
  @ViewChild('maxInput', { static: false })
  maxInput: ElementRef<HTMLInputElement>

  private unit(num: number): string {
    const formattedNumber = this.numberFormat.format(num)
    const unit = formattedNumber.slice(formattedNumber.length - 1)
    return UNITS.includes(unit) ? unit : ''
  }

  private unitAmount(unit: string): number {
    switch (unit) {
      case 'K':
        return 1e3
      case 'M':
        return 1e6
      case 'B':
        return 1e9
      case 'T':
        return 1e12
      default:
        return 1
    }
  }

  private transformToUnit(n: number, unit: string): number {
    switch (unit) {
      case 'K':
        return n * 1e3
      case 'M':
        return n * 1e6
      case 'B':
        return n * 1e9
      case 'T':
        return n * 1e12
      default:
        return n
    }
  }

  get minDisplay(): string {
    return this.minFocus || !this.isMinValid ? this.minRaw : this.minFormatted
  }

  get maxDisplay(): string {
    return this.maxFocus || !this.isMaxValid ? this.maxRaw : this.maxFormatted
  }

  get isMinValid(): boolean {
    const val = this.minRaw.trim()
    return val.length > 0 && REGEX_VALID_NUMBER.test(val)
  }

  get isMaxValid(): boolean {
    const val = this.maxRaw.trim()
    return val.length > 0 && REGEX_VALID_NUMBER.test(val)
  }

  get isValid(): boolean {
    return this.isMinValid && this.isMaxValid && this.minAsUnit < this.maxAsUnit
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.format) {
      const format = toGraphingValueFormat(this.format)
      this.formatter = createGraphingValueFormatter(format)
      this.transform = createGraphingValueTransformer(format.transform)
    }
  }

  onOpen(): void {
    this.open = !this.open
    if (this.open) {
      this.minAsUnit = this.options?.extentsMin ?? this.extents[0] ?? 0
      this.maxAsUnit = this.options?.extentsMax ?? this.extents[1] ?? 0
      this.minFormatted = this.formatter(this.minAsUnit)
      this.maxFormatted = this.formatter(this.maxAsUnit)
      const minUnitAmount = this.unitAmount(this.minUnit)
      const maxUnitAmount = this.unitAmount(this.maxUnit)
      const minAbbreviated = this.minAsUnit / minUnitAmount
      const maxAbbreviated = this.maxAsUnit / maxUnitAmount
      // Convert percent values to two decimals
      const transformedMinAsUnit = this.isPercent()
        ? Math.round((this.transform(minAbbreviated) + Number.EPSILON) * 1e2) /
          1e2
        : this.transform(minAbbreviated)
      const transformedMaxAsUnit = this.isPercent()
        ? Math.round((this.transform(maxAbbreviated) + Number.EPSILON) * 1e2) /
          1e2
        : this.transform(maxAbbreviated)
      this.minRaw = String(transformedMinAsUnit)
      this.maxRaw = String(transformedMaxAsUnit)
    }
  }

  private isPercent(): boolean {
    const type = isGraphingValueFormatter(this.format)
      ? this.format.transform
      : this.format
    return type === 'percent'
  }

  onMinChange($event: string): void {
    this.minRaw = $event
    if (this.isMinValid) {
      const transformedToUnit = this.transformToUnit(
        Number(this.minRaw),
        this.minUnit
      )
      this.minAsUnit = this.isPercent()
        ? transformedToUnit / 100
        : transformedToUnit
      this.minFormatted = this.formatter(
        this.transform(transformedToUnit, {
          invert: true,
        })
      )
    }
  }

  onMaxChange($event: string): void {
    this.maxRaw = $event
    if (this.isMaxValid) {
      const transformedToUnit = this.transformToUnit(
        Number(this.maxRaw),
        this.maxUnit
      )
      this.maxAsUnit = this.isPercent()
        ? transformedToUnit / 100
        : transformedToUnit
      this.maxFormatted = this.formatter(
        this.transform(transformedToUnit, {
          invert: true,
        })
      )
    }
  }

  onOK(): void {
    if (this.isValid) {
      this.optionsChange.emit({
        extentsMin: this.isPercent()
          ? this.minAsUnit
          : this.transform(this.minAsUnit, { invert: true }),
        extentsMax: this.isPercent()
          ? this.maxAsUnit
          : this.transform(this.maxAsUnit, { invert: true }),
      })
      this.open = false
    }
  }

  onReset(): void {
    this.optionsChange.emit({
      extentsMin: undefined,
      extentsMax: undefined,
    })
    this.open = false
  }
}
