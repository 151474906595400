import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  CheckboxSelectionsChange,
  SortTableColumnDef,
} from '@shared/sort-table/sort-table.model'
import {
  Change,
  extractCandidateID,
  getResultsMetricsTableColumnDef,
  OptimizationCandidateResult,
  OptimizationCandidateResultDenormalized,
  ResultsView,
  denormalize,
  OptimizationCandidateResultEntity,
  OptimizationRangesTypes,
  OptimizationPortfolioTailMetrics,
  OptimizationPortfolioTailMetricsPayload,
  toPortfolioMetrics,
  OptimizationCurrency,
} from '../optimization.model'
import LayerModelingProps from '../../layer-modeling/layer-modeling-defs'
import { LayerModelingState } from '../../layer-modeling/store/layer-modeling.reducer'
import {
  LayerModelingDimensionChangeEvent,
  LayerModelingView,
} from '../../layer-modeling/layer-modeling.model'
import { LossSetGroup } from '../../model/loss-set-layers.model'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-candidates-results-metrics',
  styleUrls: ['./optimization-candidates-results-metrics.component.scss'],
  templateUrl: './optimization-candidates-results-metrics.component.html',
})
export class OptimizationCandidatesResultsMetricsComponent implements OnChanges {
  @Input() candidatesResults: OptimizationCandidateResult[]
  @Input() candidateLayersMetricsLoading: boolean
  @Input() resultsView: ResultsView = 'table'
  @Input() chartState: LayerModelingState
  @Input() chartView: LayerModelingView
  @Input() error: string | null
  @Input() lossSetGroups: LossSetGroup[]
  @Input() rangesTypes: OptimizationRangesTypes[]
  @Input() lossFilters: LossFilter[] | undefined
  @Input() portfolioTailMetrics: OptimizationPortfolioTailMetrics

  @Output() valueChange = new EventEmitter<
    Change<OptimizationCandidateResultEntity>[]
  >()
  @Output() viewChange = new EventEmitter<ResultsView>()
  @Output() dimensionChange =
    new EventEmitter<LayerModelingDimensionChangeEvent>()
  @Output() checkboxChanges = new EventEmitter<CheckboxSelectionsChange>()
  @Output() portfolioReturnPeriodToggleChange = new EventEmitter<
    OptimizationPortfolioTailMetricsPayload[]
  >()

  columnDefs: SortTableColumnDef<OptimizationCandidateResultDenormalized>[]

  props = LayerModelingProps
  rows: OptimizationCandidateResultDenormalized[] = []

  get returnPeriodToggle(): PortfolioMetrics {
    return toPortfolioMetrics(this.portfolioTailMetrics)
  }

  get portfolioTailMetricsTooltip() {
    return `${this.portfolioTailMetrics.portfolioType} ${this.portfolioTailMetrics.returnPeriod1}yr\nAggregation: ${this.portfolioTailMetrics.aggregationMethod}\nPerspective: ${this.portfolioTailMetrics.perspective}\nLoss Filters: ${this.portfolioTailMetrics.lossFilter}`
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.columnDefs) {
      this.columnDefs = getResultsMetricsTableColumnDef(
        this.lossSetGroups,
        this.rangesTypes
      )
    }
    if (changes.candidatesResults) {
      const currencies: OptimizationCurrency[] = []
      for (const range of this.rangesTypes) {
        const rge = range.ranges.find(r => r.id === 'currencyChange')
        if (rge) {
          currencies.push({
            id: range.id,
            currency: rge.currency,
          })
        }
      }
      this.rows = changes.candidatesResults.currentValue.flatMap(denormalize)
      this.rows = this.rows.map(r => ({
        ...r,
        currency: currencies.find(obj => obj.id === r.type)?.currency,
        lossSetGroupID: r.lossSetGroupID || 'From Original',
      }))
    }
  }

  get viewLabel(): string {
    return this.resultsView === 'chart' ? 'Show Table' : 'Show Chart'
  }

  get viewIcon(): string {
    return this.resultsView === 'chart' ? 'table_chart' : 'bar_chart'
  }

  onViewChange() {
    if (this.resultsView === 'chart') {
      this.viewChange.emit('table')
    } else {
      this.dimensionChange.emit({
        dimension: 'y',
        prop: 'portfolioEfficiencyScore',
      })
      this.viewChange.emit('chart')
    }
  }

  onCheckboxChanges(checkboxSelections: CheckboxSelectionsChange) {
    this.checkboxChanges.emit(checkboxSelections)
    if (checkboxSelections.selections.someSelected === false) {
      const changes = this.candidatesResults.map(r => ({
        id: r.id,
        changes: { [checkboxSelections.id]: false },
      }))
      this.valueChange.emit(changes)
    } else {
      const dictionary = checkboxSelections.selections.dictionary
      const changes: Change<OptimizationCandidateResultEntity>[] = []
      Object.keys(dictionary).forEach(key => {
        const candidateID = extractCandidateID(key)
        const candidateResult = this.candidatesResults.find(
          c => c.id === candidateID
        ) as OptimizationCandidateResult
        if (
          (candidateResult as any)[checkboxSelections.id] !== dictionary[key]
        ) {
          changes.push({
            id: candidateID,
            changes: { [checkboxSelections.id]: dictionary[key] },
          })
        }
      })
      this.valueChange.emit(changes)
    }
  }

  onPortfolioReturnPeriodToggleChange($event: PortfolioMetrics) {
    const filteredCandidates = this.candidatesResults.filter(
      c => c.createPortfolio && c.grossPortfolioViewID
    )
    if (filteredCandidates.length > 0) {
      this.portfolioReturnPeriodToggleChange.emit(
        filteredCandidates.map(f => ({ id: f.id, ...$event }))
      )
    } else {
      this.portfolioReturnPeriodToggleChange.emit([{ id: null, ...$event }])
    }
  }
}
