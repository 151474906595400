import { createAction, props } from '@ngrx/store'
import { StudyData } from '../../admin/model/admin.model'
import {
  CarrierResponse,
  StudyDataWithYear,
  StudyResponse,
  CarrierData,
  EntitiesResponse,
} from '../../api/model/backend.model'
import { ApplicationError } from '../../error/model/error'
import { AccountMappingData } from '../model/program-initiation.model'

export const createNewProgramFromOpportunity = createAction(
  '[Program] Create New Program From Opportunity',
  props<{ program: Partial<StudyData> }>()
)

export const createNewProgramFromOpportunitySuccess = createAction(
  '[Program] Create New Program From Opportunity Success',
  props<{ newProgram: StudyResponse }>()
)

export const createNewProgramFromOpportunityFailure = createAction(
  '[Program] Create New Program From Opportunity Failure',
  props<{ error: ApplicationError }>()
)

export const createNewYearProgramFromOpportunity = createAction(
  '[Program] Create New Year and Program From Opportunity',
  props<{ program: Partial<StudyDataWithYear> }>()
)

export const createNewYearProgramFromOpportunitySuccess = createAction(
  '[Program] Create New Year and Program From Opportunity Success',
  props<{ newProgram: StudyResponse; year: string; carrierID: string }>()
)

export const createNewYearProgramFromOpportunityFailure = createAction(
  '[Program] Create New Year and Program From Opportunity Failure',
  props<{ error: ApplicationError }>()
)

export const createNewCarrierYearProgramFromOpportunity = createAction(
  '[Program] Create New Carrier, Year, Program From Opportunity',
  props<{
    carrier: Partial<CarrierData>
    program: Partial<StudyDataWithYear>
    carrierLogoFormData: any
  }>()
)

export const createNewCarrierYearProgramFromOpportunitySuccess = createAction(
  '[Program] Create New Carrier, Year, Program From Opportunity Success',
  props<{ newCarrier: CarrierResponse }>()
)

export const createNewCarrierYearProgramFromOpportunityFailure = createAction(
  '[Program] Create New Carrier, Year, Program From Opportunity Failure',
  props<{ error: ApplicationError }>()
)

export const updateSFAccountCarrierMapping = createAction(
  '[Program] Update SF Account Carrier Mapping',
  props<{ accountMapping: AccountMappingData }>()
)
export const updateSFAccountCarrierMappingFailure = createAction(
  '[Program] Update SF Account Carrier Mapping Failure',
  props<{ error: ApplicationError }>()
)
export const updateSFAccountCarrierMappingSuccess = createAction(
  '[Program] Update SF Account Carrier Mapping Success',
  props<{ accountMapping: AccountMappingData }>()
)

export const refreshCarrierYears = createAction(
  '[Program] Refresh Carrier Years'
)
export const refreshCarrierYearsFailure = createAction(
  '[Program] Refresh Carrier Years Failure',
  props<{ error: ApplicationError }>()
)
export const refreshCarrierYearsSuccess = createAction(
  '[Program] Refresh Carrier Years Success',
  props<EntitiesResponse>()
)
