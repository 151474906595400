import * as d3 from 'd3'
import { select, Selection } from 'd3-selection'
import { hexGraphingColorPalette } from './utils/graphing-color-palette'
import addLegend from './utils/chart-legend'
import addChartLabels from './utils/chart-labels'
import {
  formatCurrencyString,
  formatLongNumberLabel,
} from './utils/charts.util'

export interface StackedBarChartOptions {
  splits: string[]
  chartLabel?: string
  xLabel?: string
  yLabel?: string
  min: boolean
  percent?: boolean
  percentSummary?: boolean
  splitLabels?: string[]
  margin?: { top: number; left: number; bottom: number; right: number }
  lightChartMode?: boolean
  summary?: boolean
  threeWide?: boolean
}

export interface StackedBarDatum {
  groupBy: string
  values: number[]
  totalCount: number
}

export class StackedBarChart {
  private container: HTMLElement
  private data: StackedBarDatum[]
  private margin = { top: 50, right: 5, bottom: 75, left: 90 }
  private options: StackedBarChartOptions

  constructor(container: HTMLElement, options: StackedBarChartOptions) {
    this.container = container
    this.options = options
    this.data = []
    select(container).selectAll('*').remove()
  }

  draw(data: StackedBarDatum[]): void {
    this.data = data
    this.setUpChart()
  }

  private convertToPercentages(values: number[]): number[] {
    const total = values.reduce((sum, num) => sum + num, 0)
    return values.map(v => parseFloat(((v / total) * 100).toFixed(2)))
  }

  private formatPercentSummary(value: number): string {
    return `${value}%`
  }

  private formatBarLabelData(value: number): string | number {
    const outsideDataLength = this.data.length > (this.options.summary ? 8 : 16)
    return isNaN(value) || value === 0
      ? ''
      : this.options.percent || this.options.percentSummary
        ? `${value.toFixed(value === 100 || outsideDataLength || this.options.threeWide ? 0 : 1)}
            ${this.options.percentSummary && !this.options.threeWide ? '%' : ''}`
        : formatLongNumberLabel(value)
  }

  private setUpChart(): void {
    const lightChartMode = this.options.lightChartMode
    const margin = this.options.summary
      ? { ...this.margin, top: 75, bottom: 108 }
      : (this.options.margin ?? this.options.min)
        ? this.margin
        : { top: 75, right: 25, bottom: 125, left: 100 }
    const width = this.container.clientWidth - margin.left * 2
    const height = this.container.clientHeight - margin.bottom * 2

    const data: StackedBarDatum[] = this.data.map(d => {
      return {
        groupBy: d.groupBy,
        values: d.values,
        totalCount: d.totalCount,
      }
    })

    const keys = Object.keys(data[0].values)

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.groupBy))
      .range([0, width])
      .padding(0.1)

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        this.options.percent
          ? 100
          : (d3.max(data, d => d3.sum(d.values.map(val => Math.abs(val)))) ??
            0),
      ])
      .range([height, 0])

    const color = d3.scaleOrdinal(
      hexGraphingColorPalette.filter(c => c !== 'body' && c !== 'yellow')
    )

    const container = d3
      .select(this.container)
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow', 'display')
      .style('position', 'relative')
      .style('background', 'transparent')

    const chart = container
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const stack = d3
      .stack()
      .keys(keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)

    const formattedData: any[] = []

    data.forEach(datum => {
      const formattedDatum: any = { groupBy: datum.groupBy }
      keys.forEach((key, index) => {
        formattedDatum[key] = this.options.percent
          ? this.convertToPercentages(datum.values)[index]
          : datum.values[index]
      })
      formattedData.push(formattedDatum)
    })

    const series = stack(formattedData)

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(d =>
        this.options.min ? '' : d.length > 15 ? `${d.slice(0, 15)}...` : d
      )
    const yAxis = d3
      .axisLeft(yScale)
      .tickFormat(
        this.options.percentSummary
          ? this.formatPercentSummary
          : formatLongNumberLabel
      )

    chart
      .selectAll('.series')
      .data(series)
      .join('g')
      .attr('class', 'series')
      .style('fill', (_, i) => color(i.toString()))
      .selectAll('rect')
      .data(d => d)
      .join('rect')
      .attr('x', d => xScale(String(d.data.groupBy)) || 0)
      .attr('y', d => yScale(d[1]))
      .attr('height', d => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())

    const tooltip:
      | Selection<HTMLDivElement, unknown, null, undefined>
      | undefined = d3
      .select(this.container)
      .append('div')
      .style('opacity', 0)
      .style('transition', 'all 1s')
      .attr('class', 'custom-tooltip')

    chart
      .on('mouseover', event => {
        tooltip?.style('opacity', 1)
        select(event.currentTarget).style('opacity', 1)
      })
      .on('mousemove', (event, d) => {
        const [x, y] = d3.pointer(event)
        const index = Math.floor(x / (width / data.length))
        const dIndex = index < data.length ? index : data.length - 1
        if (!data[dIndex]) {
          return
        }
        if (tooltip) {
          tooltip
            .html(
              `
              <div>
                  <div>
                    <strong>${data[dIndex].groupBy}</strong>
                  </div>
                  <div>
                    ${data[dIndex].values
                      .map((d, val) => {
                        if (d !== 0) {
                          return `
                              <div>
                                ${this.options.splits[val]} : ${
                                  this.options.percent
                                    ? d.toFixed(2)
                                    : formatCurrencyString(d)
                                } ${this.options.percentSummary ? '%' : ''}
                              </div>
                            `
                        }
                      })
                      .join('')}
                  </div>
              </div>
              `
            )
            .style('left', `${x}px`)
            .style('top', `${y - 15}px`)
        }
      })
      .on('mouseleave', () => {
        tooltip?.style('opacity', 0)
      })

    if (!this.options.min) {
      chart
        .selectAll('.bar-label')
        .data(series)
        .join('g')
        .attr('class', 'bar-label')
        .selectAll('text')
        .data(d => d)
        .join('text')
        .attr('x', d => xScale(String(d.data.groupBy)) || 0)
        .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2)
        .attr('dy', '0.35em')
        .attr('dx', xScale.bandwidth() / 2)
        .text(d => this.formatBarLabelData(d[1] - d[0]))
        .style('text-anchor', 'middle')
        .style('fill', '#000000')
        .style(
          'font-size',
          this.options.summary
            ? this.options.threeWide
              ? '10px'
              : '12px'
            : '14px'
        )
        .style('font-weight', '700')
        .style('opacity', d => (height - yScale(d[1] - d[0]) > 14 ? '1' : '0'))
    }

    chart
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-30)')
      .style('font-size', this.options.threeWide ? '10px' : '12px')

    chart
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .style('font-size', this.options.threeWide ? '10px' : '12px')

    chart.selectAll('text').style('fill', lightChartMode ? 'black' : 'white')

    addChartLabels(
      chart,
      this.options.xLabel ?? '',
      this.options.yLabel ?? '',
      this.options.chartLabel ?? '',
      margin,
      width,
      height,
      this.options.min,
      false,
      false,
      lightChartMode
    )
    if (!this.options.min) {
      const offSetX = this.options.summary
        ? this.options.threeWide
          ? -170
          : -180
        : -145
      const fontSize = this.options.summary
        ? this.options.threeWide
          ? '10px'
          : '12px'
        : undefined
      addLegend(
        chart,
        this.container.clientWidth + (this.options.summary ? 150 : 50),
        this.options.splits,
        color,
        false,
        '',
        false,
        lightChartMode,
        fontSize,
        offSetX,
        this.options.threeWide
      )
    }
    if (!this.options.min && this.options.splitLabels) {
      chart
        .append('text')
        .attr('text-anchor', 'center')
        .style('fill', lightChartMode ? 'black' : 'white')
        .style('font-size', '16px')
        .attr('x', width * 0.225)
        .attr('y', height + 90)
        .text(this.options.splitLabels[0])
      chart
        .append('text')
        .attr('text-anchor', 'center')
        .style('fill', lightChartMode ? 'black' : 'white')
        .style('font-size', '16px')
        .attr('x', width * 0.725)
        .attr('y', height + 90)
        .text(this.options.splitLabels[1])
    }
  }
}
