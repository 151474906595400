import { inject, Injectable } from '@angular/core'
import { AppState } from '../../core/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from '../../api/util'
import { map } from 'rxjs'
import * as fromUserPreferencesActions from './program-events.actions'
import { ProgramEventsService } from 'src/app/api/program-events/program-events.service'

@Injectable()
export class ProgramEventsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: ProgramEventsService) {}

  saveOrUpdateUserPreferences = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserPreferencesActions.saveOrUpdateProgramEvents),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.saveOrUpdateProgramEvents(props.programEvents)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromUserPreferencesActions.saveOrUpdateProgramEventsFailure({
            error,
          })
        )
      ),
      map(([data]) => {
        return fromUserPreferencesActions.saveOrUpdateProgramEventsSuccess({
          programEvents: data,
        })
      })
    )
  })
}
