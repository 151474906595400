import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import {
  SharedLimitLayerSelection,
  SharedLimitProperties,
} from '../../store/grouper/program-group.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-properties-panel',
  styleUrls: [`./properties-panel.component.scss`],
  templateUrl: `./properties-panel.component.html`,
})
export class PropertiesPanelComponent {
  type = 'Layer'

  @Input() id: PortfolioSetID | null
  @Input() selectedViewID: string | null
  @Input() open = true
  @Input() isAddSharedLayer = false
  @Input() isDeleteSharedLayer = false
  @Input() sharedLimitAddLayers: SharedLimitLayerSelection[]
  @Input() metrics: PortfolioMetrics
  @Input() activeAction: string
  @Input() sharedLimitProperties: SharedLimitProperties
  @Input() designDirty: boolean
  @Input() libRETemplate: boolean
  @Input() isLibRE: boolean

  @Output() cancelClick = new EventEmitter()
  @Output() saveSharedLimitClick = new EventEmitter()
  @Output() handlePastedLayer = new EventEmitter<boolean>()
  @Output() selectedSLCurrency = new EventEmitter<string>()
  @Output() structureCurrency = new EventEmitter<string>()

  get isEdit(): boolean {
    return !this.isAddSharedLayer && !this.isDeleteSharedLayer
  }

  get groupValue(): string {
    return this.isEdit || this.isDeleteSharedLayer ? this.type : 'Layer'
  }

  get isDisabled(): boolean {
    return (
      !this.sharedLimitProperties.layerName ||
      this.sharedLimitAddLayers.length < 2 ||
      this.sharedLimitProperties.aggLimit === 0 ||
      this.sharedLimitProperties.occLimit === 0
    )
  }

  get layerCount(): string | number {
    const n = this.sharedLimitAddLayers.length
    return n === 0 ? 'No' : n
  }

  get layerCountText(): string {
    const singular = this.sharedLimitAddLayers.length === 1
    return `Layer${singular ? '' : 's'} Selected`
  }

  get layerCountClass() {
    return { numeric: this.layerCount !== 'No' }
  }

  onHandlePastedLayer(e: boolean): void {
    this.handlePastedLayer.emit(e)
  }

  onStructureCurrencyEvent(event: string | undefined): void {
    this.structureCurrency.emit(event)
  }
}
