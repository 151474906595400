import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AppState } from '../store'
import { selectAuthState } from '../store/auth/auth.selectors'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { State as ThemeState } from '../store/theme/theme.state.facade'
import { selectThemeState } from '../store/theme/theme.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-core-auth',
  styles: [
    `
           .wrapper {
             position: relative;
           }
     
           img.bg {
             position: absolute;
             top: 3.8vw;
             /* width: 100vw; */
             /* transform: scaleX(-1.05) scaleY(1.05) rotate(-9deg); */
             opacity: 1;
           }
     
           main {
             display: flex;
             flex: 0 0 43vw;
             margin: 0 0 0 43vw;
             position: relative;
           }
     
           app-logo {
             display: block;
             padding-top: 2vw;
             width: var(--client-panel-width);
           }
     
           mat-spinner {
             margin: 0 0 0 46vw;
             display: block;
             top: 5vw;
           }
         `,
  ],
  template: `
    <app-theme dark class="wrapper" [theme]="theme">
      <img class="bg" src="assets/bg/lockton_R.png" />
      <main>
        <app-logo big></app-logo>
      </main>
      <mat-spinner color="accent"></mat-spinner>
    </app-theme>
  `,
})
export class AuthCallBackComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  theme: ThemeState

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(takeUntil(this.destroy$), select(selectThemeState))
      .subscribe(value => {
        this.theme = value
        this.cdRef.markForCheck()
      })
    this.store
      .pipe(select(selectAuthState), takeUntil(this.destroy$))
      .subscribe(state => {
        if (
          state.authenticated &&
          state.analyzeReToken &&
          state.userPermissions &&
          state.marketToken
        ) {
          if (state.redirectURL && state.redirectURL !== '/auth-callback') {
            this.router.navigateByUrl(state.redirectURL)
          } else {
            this.router.navigateByUrl('/')
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
