import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'
import { Metadata, Metrics } from 'src/app/api/analyzere/analyzere.model'
import { LossSetLayer } from '../model/loss-set-layers.model'
import { ExploreSummaryView } from 'src/app/api/model/backend.model'
import { SummaryChartGridOption } from './explore-gross-summary-charts/summary-charts-model'

export interface MappingOption {
  label: string
  value: keyof Metadata
}

export interface ExploreFilterMap {
  [key: string]: boolean
}

export interface CombinedFilterState {
  summaryColumnFilterMap: ExploreFilterMap
  summaryFilterMap: ExploreFilterMap
  modelingArray: string[]
  showIndividualLossSets: boolean
  selectedStudySummaryFilterView: ExploreSummaryView
  isSavedViewDirty: boolean
}

export interface SummaryArrayDatum {
  data: Metrics[]
  lossType: string
  lossName: string
  rpArray: number[]
  lossFilter: string
  aggregationMethod: string
}

export interface SummaryExportGroup {
  name: string
  groups: GroupSummaryRequest[]
  filteredLossSets: LossSetLayer[]
  rows: ExploreSummaryDatum[]
  viewSummaryFilterMap: ExploreFilterMap
  data?: SummaryDataResponse[]
}

export interface SummaryDataResponse {
  lossName: string
  largeRiskDistributionOEP: SummaryArrayDatum
  largeRiskDistributionAEP: SummaryArrayDatum
  uwResultVaR: SummaryArrayDatum
  expense: number
  subjectPremiumAmt: number
  netLoss: number
  expectedUWResult: number
  lossSetID: string
  contributionToGroupVolatility?: number
  contributionToGroupVolatilityAttr?: number
  contributionToGroupVolatilityLarge?: number
  contributionToGroupVolatilityCat?: number
  expectedLossAttr?: number
  expectedLossLarge?: number
  expectedLossCat?: number
  largeRisk_Frequency?: number
  largeRisk_Severity?: number
  expectedLossVarianceAttr?: number
  expectedLossVarianceLarge?: number
  expectedLossVarianceCat?: number
  covariance?: number
}

export interface GroupSummaryRequest {
  name: string
  ids: string[]
  layers?: LossSetLayer[]
  largeFrequencyLayerViewID?: string
  subjectPremiumAmt?: number
}

export interface ExploreSummaryDatum {
  id: string
  name: string
  groupBy: string
  lossScaleFactor: number | string
  premiumScaleFactor: number | string
  loss_type: string
  ls_dim1: string
  ls_dim2: string
  map1: string
  map2: string
  map3: string
  map4: string
  map5: string
  premium: number | string
  expense: number | string
  lossByType_Attritional: number | string
  lossByType_Large: number | string
  lossByType_Cat: number | string
  expectedLoss: number | string
  expectedUWResult: number | string
  expenseRatio: number | string
  lossRatioByType_Attritional: number | string
  lossRatioByType_Large: number | string
  lossRatioByType_Cat: number | string
  expectedLossRatio: number | string
  expectedCombinedRatio: number | string
  lossRatioCV_Attritional: number | string
  lossRatioCV_Large: number | string
  lossRatioCV_Cat: number | string
  totalLossRatioCV: number | string
  largeRisk_Frequency: number | string
  largeRisk_Severity: number | string
  largeRiskDistributionOEP_10: number | string
  largeRiskDistributionOEP_25: number | string
  largeRiskDistributionOEP_50: number | string
  largeRiskDistributionOEP_100: number | string
  largeRiskDistributionOEP_200: number | string
  largeRiskDistributionOEP_250: number | string
  largeRiskDistributionAEP_10: number | string
  largeRiskDistributionAEP_25: number | string
  largeRiskDistributionAEP_50: number | string
  largeRiskDistributionAEP_100: number | string
  largeRiskDistributionAEP_200: number | string
  largeRiskDistributionAEP_250: number | string
  uwResultVaR_10: number | string
  uwResultVaR_25: number | string
  uwResultVaR_50: number | string
  uwResultVaR_100: number | string
  uwResultVaR_200: number | string
  uwResultVaR_250: number | string
  combinedRatiosVaR_10: number | string
  combinedRatiosVaR_25: number | string
  combinedRatiosVaR_50: number | string
  combinedRatiosVaR_100: number | string
  combinedRatiosVaR_200: number | string
  combinedRatiosVaR_250: number | string
  contributionToGroupVolatility_Attritional?: number | string
  contributionToGroupVolatility_Large?: number | string
  contributionToGroupVolatility_Cat?: number | string
  totalContributionToGroupVolatility?: number | string
  rowLayer?: number | string
}

export interface ExploreSummaryHeader {
  id: string
  label: string
  width: string
  hideBorder?: boolean
  hideLabel?: boolean
  pinned?: boolean
}

export const EXPLORE_SUMMARY_HEADERS: ExploreSummaryHeader[] = [
  {
    id: 'name',
    label: 'Name',
    width: '290',
    hideBorder: true,
    pinned: true,
    hideLabel: true,
  },
  {
    id: 'groupBy',
    label: 'Group By',
    width: '356',
    hideBorder: true,
    hideLabel: true,
    pinned: true,
  },
  {
    id: 'premium',
    label: 'Premium',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expense',
    label: 'Expense',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'lossByType',
    label: 'Expected Loss by Type',
    width: '270',
  },
  {
    id: 'expectedLoss',
    label: 'Total Expected Loss',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expectedUWResult',
    label: 'Expected UW Result',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expenseRatio',
    label: 'Expense Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'lossRatioByType',
    label: 'Expected Loss Ratio by Type',
    width: '270',
  },
  {
    id: 'expectedLossRatio',
    label: 'Expected Total Loss Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expectedCombinedRatio',
    label: 'Expected Combined Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'lossRatioCV',
    label: 'Loss Ratio CV by Type',
    width: '270',
  },
  {
    id: 'totalLossRatioCV',
    label: 'Total Loss Ratio CV',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'largeRisk',
    label: 'Large Risk',
    width: '180',
  },
  {
    id: 'largeRiskDistributionOEP',
    label: 'Large Risk Distribution (OEP)',
    width: '540',
  },
  {
    id: 'largeRiskDistributionAEP',
    label: 'Large Risk Distribution (AEP)',
    width: '540',
  },
  {
    id: 'uwResultVaR',
    label: 'UW Result - VaR',
    width: '540',
  },
  {
    id: 'combinedRatiosVaR',
    label: 'Combined Ratios - VaR',
    width: '540',
  },
  {
    id: 'contributionToGroupVolatility',
    label: 'Contribution To Group Volatility (Downside) by Type',
    width: '270',
  },
  {
    id: 'totalContributionToGroupVolatility',
    label: 'Total Contribution To Group Volatility (Downside)',
    width: '150',
    hideBorder: true,
    hideLabel: true,
  },
]

export const EXPLORE_SUMMARY_LOSS_TYPE_HEADERS: ExploreSummaryHeader[] = [
  {
    id: 'Attritional',
    label: 'Attritional',
    width: '0',
  },
  {
    id: 'Large',
    label: 'Large',
    width: '0',
  },
  {
    id: 'Cat',
    label: 'Cat',
    width: '0',
  },
]

export const DEFAULT_SUMMARY_COLUMN_FILTER_MAP: ExploreFilterMap = {
  Attritional: true,
  Cat: true,
  Large: true,
  combinedRatiosVaR: true,
  contributionToGroupVolatility: true,
  expectedCombinedRatio: true,
  expectedLoss: true,
  expectedLossRatio: true,
  expectedUWResult: true,
  expense: true,
  expenseRatio: true,
  largeRisk: true,
  largeRiskDistributionAEP: true,
  largeRiskDistributionOEP: true,
  lossByType: true,
  lossRatioByType: true,
  lossRatioCV: true,
  name: true,
  premium: true,
  totalContributionToGroupVolatility: true,
  totalLossRatioCV: true,
  uwResultVaR: true,
}

export const exploreSummaryColumnDefs: SortTableColumnDef[] = [
  {
    id: 'name',
    label: 'Loss Set Name',
    valueType: 'text',
    width: '290px',
    minWidth: '290px',
    maxWidth: '290px',
    pinned: true,
    bodyStyle: {
      color: '#FFFFFF',
      paddingLeft: '4px',
      backgroundColor: 'black',
    },
    headerStyle: {
      paddingLeft: '12px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px',
    },
  },
  {
    id: 'groupBy',
    label: 'Group By',
    valueType: 'text',
    width: '340px',
    minWidth: '340px',
    maxWidth: '340px',
    pinned: true,
    bodyStyle: {
      color: '#FFFFFF',
      paddingLeft: '4px',
      backgroundColor: 'black',
    },
    headerStyle: {
      backgroundColor: 'var(--bg-lit)',
      color: '#FFFFFF',
      paddingLeft: '4px',
      paddingRight: '4px',
      borderBottom: '1px solid var(--subtle)',
    },
  },
  {
    id: 'premium',
    label: 'Premium',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expense',
    label: 'Expense',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossByType_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossByType_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossByType_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expectedLoss',
    label: 'Total Expected Loss',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expectedUWResult',
    label: 'Expected UW Result',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expenseRatio',
    label: 'Expense Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioByType_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioByType_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioByType_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expectedLossRatio',
    label: 'Total Expected Loss Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'expectedCombinedRatio',
    label: 'Expected Combined Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioCV_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioCV_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'lossRatioCV_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'totalLossRatioCV',
    label: 'Total Loss Ratio CV',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '8px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRisk_Frequency',
    label: 'Frequency',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'numeric',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRisk_Severity',
    label: 'Severity',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionOEP_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'largeRiskDistributionAEP_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'uwResultVaR_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'combinedRatiosVaR_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'contributionToGroupVolatility_Attritional',
    label: 'Attritional',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'contributionToGroupVolatility_Large',
    label: 'Large',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'contributionToGroupVolatility_Cat',
    label: 'Cat',
    width: '90px',
    maxWidth: '90px',
    alignRight: true,
    valueType: 'percentage',
    bodyStyle: {
      paddingRight: '0px',
      textAlign: 'right',
    },
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
    },
  },
  {
    id: 'totalContributionToGroupVolatility',
    label: 'Total Contribution to Group Volatility (Downside)',
    width: '150px',
    maxWidth: '150px',
    valueType: 'percentage',
    bodyStyle: {
      paddingRight: '0px',
      textAlign: 'right',
    },
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '0px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      alignItems: 'right',
      top: '24px',
      overflow: 'visible',
    },
  },
]

export const ExploreSummaryBorderColumns = {
  left: ['Attritional', '10'],
  right: ['Cat', '250'],
  bottom: [
    'Attritional',
    'Large',
    'Cat',
    '250',
    '200',
    '100',
    '50',
    '25',
    '10',
  ],
}

export const LossTypeColumnFilters = {
  Attritional: [
    'lossByType_Attritional',
    'lossRatioByType_Attritional',
    'lossRatioCV_Attritional',
    'contributionToGroupVolatility_Attritional',
  ],
  Large: [
    'lossByType_Large',
    'lossRatioByType_Large',
    'lossRatioCV_Large',
    'contributionToGroupVolatility_Large',
  ],
  Cat: [
    'lossByType_Cat',
    'lossRatioByType_Cat',
    'lossRatioCV_Cat',
    'contributionToGroupVolatility_Cat',
  ],
}

export const SummaryTableColorPallette = [
  '#0f9ed5', // Darkest Blue
  '#61cbf3', // 2nd Darkest Blue
  '#94dcf8', // Middle Blue
  '#caedfb', // Lightest Blue
  '#ffffff', // White
  '#000000', // Black
]

export interface SummaryViewRequest {
  modeling: string[]
  filters: string[]
  columnFilters: string[]
  showIndividualLossSets: boolean
  grossProfileId?: string
  programId?: number
  name?: string
  isDefault?: boolean
}

export const SUMMARY_GROUPING_FILTER_TERMS = {
  lossSetGroups: '',
  lossSetLayers: '',
  summaryViews: '',
  loss_type: '',
  lossScaleFactor: '',
  premiumScaleFactor: '',
  ls_dim1: '',
  ls_dim2: '',
  map1: '',
  map2: '',
  map3: '',
  map4: '',
  map5: '',
  groupByOne: '',
  groupByTwo: '',
  groupByThree: '',
  groupByFour: '',
  groupByFive: '',
  columns: '',
}

export interface GroupCovariance {
  layerName: string
  covariance: number
}

export interface ExploreMultiSelectExportPanelOption {
  value: string
  label: string
  defaults?: any
}

export const ExploreMultiSelectExportPanelTypeOptions: ExploreMultiSelectExportPanelOption[] =
  [
    {
      value: 'titles',
      label: 'Titles',
    },
    {
      value: 'table',
      label: 'Table',
    },
    {
      value: 'charts',
      label: 'Charts',
    },
  ]

export const ExploreMultiSelectExportPanelTitleOptions: ExploreMultiSelectExportPanelOption[] =
  [
    {
      value: 'date',
      label: 'Date',
    },
    {
      value: 'analysisProfileID',
      label: 'Analysis Profile ID',
    },
    {
      value: 'grossPortfolioID',
      label: 'Gross Portfolio ID',
    },
    {
      value: 'structure',
      label: 'Structure',
    },
  ]

export type ExploreMultiSelectExportPanelType = 'titles' | 'table' | 'charts'

export type ExploreMultiSelectExportPanelChartGrid =
  | 'oneByOne'
  | 'oneByTwo'
  | 'oneByThree'
  | 'twoByOne'
  | 'twoByTwo'
  | 'twoByThree'

export interface ExploreMultiSelectExportPanelChartOption {
  value: ExploreMultiSelectExportPanelChartGrid
  label: string
  total: number
}

export const ExploreMultiSelectExportPanelChartOptions: ExploreMultiSelectExportPanelChartOption[] =
  [
    { value: 'oneByOne', label: '1 x 1', total: 1 },
    { value: 'oneByTwo', label: '1 x 2', total: 2 },
    { value: 'oneByThree', label: '1 x 3', total: 3 },
    { value: 'twoByOne', label: '2 x 1', total: 2 },
    { value: 'twoByTwo', label: '2 x 2', total: 4 },
    { value: 'twoByThree', label: '2 x 3', total: 6 },
  ]

export interface ExploreExportColumnArrayValue {
  value: string
  label: string
  selected: boolean
}

export interface ExploreMultiSelectExportPanel {
  type: ExploreMultiSelectExportPanelType | null
  topTitle?: string
  subtitles?: string[]
  chartGrid?: ExploreMultiSelectExportPanelChartOption
  chartGridOptions?: SummaryChartGridOption[]
  columns?: ExploreExportColumnArrayValue[]
  tableTitle?: string
  summaryView?: ExploreSummaryView
}

export interface ExploreMultiSelectExportLayoutPanel {
  group?: SummaryExportGroup
  panel: ExploreMultiSelectExportPanel
  view?: ExploreSummaryView
  groupRows?: ExploreSummaryDatum[]
  defs?: SortTableColumnDef[][]
  headers?: ExploreSummaryHeader[][]
  subheaders?: string[]
}

export const EMPTY_EXPLORE_MULTI_SELECT_EXPORT_PANEL: ExploreMultiSelectExportPanel =
  {
    type: null,
    topTitle: undefined,
    subtitles: undefined,
    chartGrid: undefined,
    chartGridOptions: undefined,
    columns: undefined,
    tableTitle: undefined,
    summaryView: undefined
  }

export interface ExploreMultiSelectExportStyles {
  titles: SectionStyles<TitleStyles>
  table: SectionStyles<TableStyles>
  charts: ChartStyles
}

export interface SectionStyles<T> {
  display: string
  flexDirection: string
  width: string
  height: string
  [key: string]: T | string
}

export interface TitleStyles {
  fontSize?: string
  color?: string
  marginBottom?: string
  marginTop?: string
  width?: string
  height?: string
  textWrap?: string
}

export interface TableStyles extends TitleStyles {}

export interface ChartStyles extends SectionStyles<ChartLayoutStyles> {
  gap: string
  oneByOne: ChartLayoutStyles
  oneByTwo: ChartLayoutStyles
  oneByThree: ChartLayoutStyles
  oneByFour: ChartLayoutStyles
  twoByOne: ChartLayoutStyles
  twoByTwo: ChartLayoutStyles
  twoByThree: ChartLayoutStyles
  twoByFour: ChartLayoutStyles
}

export interface ChartLayoutStyles {
  height: string
  width: string
  marginBottom?: string
  marginTop?: string
}

export const EXPLORE_MULTI_SELECT_EXPORT_DEFAULT_STYLES: ExploreMultiSelectExportStyles =
  {
    titles: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '25%',
      topTitle: {
        fontSize: '24px',
        color: 'white',
        marginBottom: '16px',
        width: '50%',
        height: '30px',
        textWrap: 'nowrap'
      },
      subtitle: {
        fontSize: '18px',
        color: 'white',
        marginBottom: '12px',
        width: '40%',
        height: '24px',
        textWrap: 'nowrap'
      },
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '50%',
      tableTitle: {
        fontSize: '18px',
        color: 'black',
        marginBottom: '12px',
        marginTop: '24px',
        width: '40%',
        height: '24px',
      },
      tableContainer: {
        width: 'calc(100% - 12px)',
        height: 'calc(50% - 36px)',
        marginBottom: '12px',
        marginTop: '12px',
      },
    },
    charts: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      height: '50%',
      gap: '12px',
      oneByOne: {
        height: '100%',
        width: '100%',
        marginBottom: '20px',
      },
      oneByTwo: {
        height: '100%',
        width: 'calc(45%)',
        marginBottom: '20px',
      },
      oneByThree: {
        height: '100%',
        width: 'calc(30%)',
        marginBottom: '20px',
      },
      oneByFour: {
        height: '100%',
        width: 'calc(20%)',
        marginBottom: '20px',
      },
      twoByOne: {
        height: 'calc(50%)',
        width: '100%',
        marginBottom: '20px',
      },
      twoByTwo: {
        height: 'calc(50%)',
        width: 'calc(45%)',
        marginBottom: '20px',
      },
      twoByThree: {
        height: 'calc(50%)',
        width: 'calc(30%)',
        marginBottom: '20px',
      },
      twoByFour: {
        height: 'calc(50%)',
        width: 'calc(20%)',
        marginBottom: '20px',
      },
    },
  }
