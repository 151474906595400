<h3 (click)="onCollapseToggle($event)">
  <span class="info enable">{{ collapsed ? 'Expand' : 'Enable' }}</span>
  <span class="info weight">{{ collapsed ? '' : 'Weight' }}</span>
  <span class="display-label">{{ displayLabel }}</span>
  <button
    appButtonIcon
    huge
    opaque
    matTooltip="Click the arrow to show or hide for display purposes on this page."
    matTooltipPosition="above"
  >
    {{ headerIcon }}
  </button>
</h3>

<ng-container *ngIf="!collapsed">
  <div class="row" *ngFor="let rowValues of values; let  i = index">
    <div *ngIf="!hideMetric(rowValues[0])">
      <mat-slide-toggle
        class="check"
        matTooltip="Enable to include this metric on the Compare page."
        matTooltipPosition="above"
        [matTooltipDisabled]="rowValues[0].show"
        [checked]="rowValues[0].show"
        (change)="
          updateMetric(
            $event,
            rowValues[0].weight,
            rowValues[0].year,
            rowValues[0]
          )
        "
      ></mat-slide-toggle>

      <input
        appInput
        big
        class="weight"
        type="number"
        min="0"
        max="100"
        matTooltip="Enter a value to weight this metric in the heatmap index."
        matTooltipPosition="above"
        [ngModel]="rowValues[0].weight"
        (ngModelChange)="setRowValuesWeight($event, rowValues)"
        (change)="
          onWeightChange(
            rowValues[0].show,
            $event.target.value,
            rowValues[0].year,
            rowValues[0]
          )
        "
      />

      <ng-container
        *ngIf="
          rowValues[0].year &&
          rowValues[0].year !== '' &&
          rowValues[0].category !== 'Misc'
        "
      >
        <button
          *ngIf="!isCredit; else scenarioSelector"
          class="slider"
          appButton
          [matMenuTriggerFor]="sliderMenu"
        >
          <span class="year">{{ rowValues[0].year }}yr</span>
        </button>

        <ng-template #scenarioSelector>
          <button
            class="slider scenario-select-button"
            appButton
            (click)="onScenarioClicked(rowValues[0])"
          >
            <span *ngIf="!isCredit; else scenarioLabel" class="year"
              >{{ rowValues[0].year }}yr</span
            >
            <ng-template #scenarioLabel>
              <span class="year">{{
                rowValues[0].credit_scenario ? 'Deterministic' : 'N/A'
              }}</span>
            </ng-template>
          </button>
        </ng-template>

        <mat-menu
          #sliderMenu="matMenu"
          yPosition="above"
          class="app-menu-no-max-width"
        >
          <app-return-period-selector
            [returnPeriod]="rowValues[0].year"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            matTooltip="Select a return period to pair with this metric."
            matTooltipPosition="above"
            [presets]="
              rowValues[0].label.includes('S&P Capital Benefit')
                ? [200, 250, 333, 500]
                : [1, 5, 10, 25, 50, 100, 250, 1000]
            "
            (returnPeriodChange)="
              onYearChange(
                rowValues[0].show,
                $event,
                rowValues[0].weight,
                rowValues[0]
              )
            "
          ></app-return-period-selector>
        </mat-menu>
      </ng-container>

      <label>
        {{ getFirstLabel(rowValues) }}
      </label>

      <div
        class="misc"
        *ngIf="rowValues[0].path === 'miscMetrics/probabilityMetric'"
      >
        <label> > </label>
        <input
          appInput
          class="threshold"
          type="number"
          matTooltip="Enter a Threshold value."
          matTooltipPosition="above"
          [ngModel]="rowValues[0].year || 0"
          (change)="
            onYearChange(
              rowValues[0].show,
              $event.target.value,
              rowValues[0].weight,
              rowValues[0]
            )
          "
        />
      </div>

      <mat-icon
        class="warning-icon"
        *ngIf="rowValues[0].validFormula === false"
        matTooltip="The formula for this metric is no longer valid. One of the component metrics has changed."
        matTooltipPosition="above"
      >
        error
      </mat-icon>

      <button
        appButton
        class="popover-button"
        matTooltip="Select from the options to customize this metric."
        matTooltipPosition="above"
        *ngIf="
          rowValues[0].perspective &&
          rowValues[0].perspective !== '' &&
          !isCredit
        "
        [matMenuTriggerFor]="popoverMenu"
      >
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>

      <mat-menu
        panelClass="app-menu-translucent"
        backdropClass="app-theme-dark"
        #popoverMenu="matMenu"
        yPosition="above"
        [hasBackdrop]="true"
      >
        <app-metrics-toggles
          [metrics]="rowValues[0]"
          [lossFilters]="lossFilters"
          [isNewTailMetric]="false"
          (updateMetricClick)="onUpdateMetricClick($event, rowValues[0])"
          (deleteMetricClick)="onCustomMetricDelete($event)"
        ></app-metrics-toggles>
      </mat-menu>

      <button
        appButton
        class="popover-button"
        matTooltip="Select from the options to customize this metric."
        matTooltipPosition="above"
        *ngIf="
          rowValues[0].label &&
          (rowValues[0].label.includes('S&P Capital Benefit') ||
            rowValues[0].label === 'BCAR Capital Benefit')
        "
        [matMenuTriggerFor]="popoverCapitalMenu"
      >
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>

      <mat-menu
        panelClass="app-menu-translucent"
        backdropClass="app-theme-dark"
        #popoverCapitalMenu="matMenu"
        yPosition="above"
        class="app-menu-no-max-width"
      >
        <app-capital-metrics-custom
          [metrics]="rowValues[0]"
          [spValues]="createCapitalArray(rowValues[0])"
          (spPremiumValueChange)="
            onSpPremiumValueChange($event, rowValues[0])
          "
          (spReserveValueChange)="
            onSpReserveValueChange($event, rowValues[0])
          "
          (spDivesificationValueChange)="
            onSpDivesificationValueChange($event, rowValues[0])
          "
          (spCatValueChange)="onSpCatValueChange($event, rowValues[0])"
        ></app-capital-metrics-custom>
      </mat-menu>

      <button
        appButton
        class="popover-button"
        matTooltip="Edit or delete custom metric."
        matTooltipPosition="above"
        *ngIf="
          rowValues[0].category &&
          rowValues[0].category === 'Custom Metrics'
        "
        [matMenuTriggerFor]="popoverCustomMenu"
      >
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>

      <mat-menu
        panelClass="app-menu-translucent"
        backdropClass="app-theme-dark"
        #popoverCustomMenu="matMenu"
        yPosition="above"
        class="app-menu-no-max-width"
      >
        <button mat-menu-item (click)="onCustomMetricDelete(rowValues[0])">
          Delete
        </button>
        <button mat-menu-item (click)="onCustomMetricEdit(rowValues[0])">
          Edit
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="new-metric">
    <button
      *ngIf="isTailMetric"
      class="metric-builder-button"
      [matMenuTriggerFor]="newTailMetricMenu"
      appButton
      accent
      border> 
      Add New Tail Metric
    </button>
    
    <mat-menu #newTailMetricMenu="matMenu"> 
      <app-metrics-toggles
        [metrics]="newTailMetric"
        [isNewTailMetric]="true"
        [lossFilters]="lossFilters"
        [metricCategories]="metricCategories"
        [allMetrics]="values"
        (addCustomMetric)="addCustomMetric.emit($event)"
    ></app-metrics-toggles>

    </mat-menu>
  </div>
</ng-container>