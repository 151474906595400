import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { map, Observable } from 'rxjs'
import { AppState } from 'src/app/core/store'
import {
  selectQuoteCustomCompareViews,
  selectReinsurers,
  selectSections,
} from '../../store/quote.selectors'
import { ReinsurerState } from '../../store/reinsurer/reinsurer.reducer'
import { QuoteCustomCompareView } from '../../models/quote.model'
import { SectionState } from '../../store/section/section.reducer'
import { MatDialogRef } from '@angular/material/dialog'
import { selectCededLayers } from 'src/app/analysis/store/analysis.selectors'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { QuoteExportDialogPayload } from '../../export/quote-excel.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-export-dialog-container',
  styleUrls: ['./quote-export-dialog.container.scss'],
  templateUrl: './quote-export-dialog.container.html',
})
export class QuoteExportDialogContainerComponent implements OnInit {
  reinsurers$: Observable<ReinsurerState[]>
  compareViews$: Observable<QuoteCustomCompareView[]>
  sections$: Observable<SectionState[]>
  layers$: Observable<LayerState[]>

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<QuoteExportDialogContainerComponent>
  ) {}

  ngOnInit(): void {
    this.reinsurers$ = this.store.select(selectReinsurers)
    this.compareViews$ = this.store.select(selectQuoteCustomCompareViews)
    this.sections$ = this.store.select(selectSections)
    this.layers$ = this.store
      .select(selectCededLayers)
      .pipe(map((layers: LayerState[]) => layers.filter(l => !l.deleted)))
  }

  destroyDialog(payload?: QuoteExportDialogPayload): void {
    this.dialogRef.close(payload)
  }
}
