import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Client } from '../../core/model/client.model'
import { State as AuthState } from '../../core/store/auth/auth.state.facade'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display',
  styleUrls: ['./display.component.scss'],
  templateUrl: './display.component.html',
})
export class DisplayComponent {
  @Input() name = 'Executive Summary'
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() year: string | null
  @Input() authState: AuthState
  @Input() carriers: Client[]
}
