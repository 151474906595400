<div class="score">
  <h3>
    <button appButtonIcon huge (click)="toggleClick.emit()">
      {{ openIcon }}
    </button>
    <span>Portfolio Results</span>
  </h3>
  <div *ngIf="open" class="currency-selector">
    <label class="structure-label">
      Structure Metric Currency
    </label>
    <button
      class="info-button"
      transparent
      center
      (click)="showExchange.emit()"
    >
      <mat-icon
        inline
        class="info-icon"
      >info</mat-icon>
    </button>
    <button class="currency-button" appButton [matMenuTriggerFor]="currencyChangeMenu"
      matTooltip="Update structure/ structure and layers currency" matTooltipPosition="below">
      {{ this.currentCurrency }}
    </button>
    <mat-menu #currencyChangeMenu="matMenu" yPosition="below"
           [hasBackdrop]="false">
      <div class="currency-popup" (click)="$event.stopPropagation()">
        <mat-form-field appearance="fill" class="seclector-width">
          <mat-label>Change Currency</mat-label>
          <mat-select [value]="currencyOption" (click)="$event.stopPropagation()" (selectionChange)="onCurrencyOptionChange($event.value)">
            <mat-option *ngFor="let prop of currencySelect" [value]="prop">
              <span>{{ prop }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="currency-input">
          <input title="currency" type="text" matInput [formControl]="currencyControl" [matAutocomplete]="currencyCtrl" (click)="$event.stopPropagation()" />
          <mat-autocomplete #currencyCtrl="matAutocomplete" (optionSelected)="
                        onCurrencyOptionUpdate($event.option.value.code)
                      " [displayWith]="displayFn"
                      (click)="$event.stopPropagation()">
            <mat-option *ngFor="let currency of filteredCurrencyList" [value]="currency">
              {{ currency.code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button class="currency-update-button" appButton big accent border (click)="onUpdateCurrencyClick()">
          Update and Save
        </button>
      </div>
    </mat-menu>

  </div>
  <div *ngIf="open" class="buttons">
    <button
      appButton
      border
      center
      (click)="showDetails.emit()"
      matTooltip="Click to display financial results for the active structure."
      matTooltipPosition="below"
      class="action-btn"
    >
      Details
    </button>

    <button
      appButton
      border
      center
      (click)="submitMarket()"
      matTooltip="Click to submit layer(s) to market."
      matTooltipPosition="below"
      [disabled]="!hasMarketPermission()"
      class="action-btn"
    >
      Markets
    </button>
  </div>
</div>
<div *ngIf="open" class="score-toggles">
  <div class="subtitle">
    <h5>Perspective</h5>
  </div>
  <app-return-period-toggles
    [metrics]="metrics"
    [viewId]="layerViewId"
    [lossFilters]="lossFilters"
    (portfolioTypeChange)="portfolioTypeChange.emit($event)"
    (perspectiveChange)="perspectiveChange.emit($event)"
    (aggregationMethodChange)="aggregationMethodChange.emit($event)"
    (lossFilterClick)="lossFilterClick.emit($event)"
  ></app-return-period-toggles>
</div>

<div *ngIf="open" class="score-table">
  <div class="currency-title-wrapper">
    <div *ngIf="layerViewId !== '2'" class="subtitle table-title">
      <h5>Exceedance Probabilities</h5>
    </div>
  </div>
  <app-return-period-table
    [isPortfolioMetrics]="true"
    [viewId]="layerViewId"
    [data]="returnPeriodData"
    [rp1]="returnPeriod1"
    [rp2]="returnPeriod2"
    [rp3]="returnPeriod3"
    [rp4]="returnPeriod4"
    [rp5]="returnPeriod5"
    [displayFooter]="true"
    (rp1Change)="returnPeriod1Change.emit($event)"
    (rp2Change)="returnPeriod2Change.emit($event)"
    (rp3Change)="returnPeriod3Change.emit($event)"
    (rp4Change)="returnPeriod4Change.emit($event)"
    (rp5Change)="returnPeriod5Change.emit($event)"
  ></app-return-period-table>
</div>
