import { createAction } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { BenchmarkSystemModeID } from '../model/benchmark-mode'
import { BenchmarkMetric } from '../model/benchmark.model'

export const fetchAllBenchmarkMetricsForCurrentMode = createAction(
  '[Benchmark Metric] Fetch All For Current Mode'
)

export const fetchAllBenchmarkMetricsByMode = createAction(
  '[Benchmark Metric] Fetch All By Mode',
  (mode: BenchmarkSystemModeID) => ({ mode })
)
export const fetchAllBenchmarkMetricsByModeSuccess = createAction(
  '[Benchmark Metric] Fetch All By Mode Success',
  (mode: BenchmarkSystemModeID, metrics: BenchmarkMetric[]) => ({
    mode,
    metrics,
  })
)
export const fetchAllBenchmarkMetricsByModeFailure = createAction(
  '[Benchmark Metric] Fetch All By Mode Failure',
  (mode: BenchmarkSystemModeID, error: ApplicationError) => ({ mode, error })
)
