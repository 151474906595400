import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RoleGuard } from '../guards/role-guard.service'
import { RevenueContainerComponent } from './revenue.container'

const routes: Routes = [
  {
    path: '',
    component: RevenueContainerComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class revenueRoutingModule {}
