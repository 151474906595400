import { Injectable } from '@angular/core'
import { forkJoin, map, Observable, switchMap } from 'rxjs'
import { CreditService } from './../../api/credit/credit.service'
import { CreditThumbnailService } from './../services/credit-thumbnail.service'
import { BackendService } from '../../api/backend/backend.service'
import { UserReinsurer } from './../../api/model/backend.model'
import {
  CreateSubmissionDraftProps,
  CreditSubmissionStatus,
  CreditSubmissionStructure,
  CreditSubmissionUserTranche,
  CreditSubmissionUserTrancheInputs,
  CreditSubmissionUserTrancheStatus,
} from '../model/credit-submission.model'
import { CreditCalculationStructure } from '../model/credit-structure.model'
import { CreditTransactionType } from '../model/credit-layer.model'

@Injectable({
  providedIn: 'root',
})
export class CreditSubmissionService {
  constructor(
    private creditService: CreditService,
    private creditThumbnailService: CreditThumbnailService,
    private backendService: BackendService
  ) {}

  createSubmissionDraft(
    props: CreateSubmissionDraftProps
  ): Observable<CreditSubmissionStructure> {
    return this.createCalculationStructure(props.calculationStructure).pipe(
      switchMap(calculationStructure =>
        this.createSubmissionStructure(calculationStructure, props)
      )
    )
  }

  createSubmissionUserTranchesForReinsurers(
    calculationStructure: CreditCalculationStructure,
    submissionStructure: CreditSubmissionStructure
  ): Observable<CreditSubmissionUserTranche[]> {
    const requests = submissionStructure.reinsurer_ids.flatMap(reinsurerId => {
      const usersToAdd =
        submissionStructure.confirmedReinsurerUsers[reinsurerId]
      const usersToRemove = submissionStructure.removedReinsurerUsers
        ? submissionStructure.removedReinsurerUsers[reinsurerId]
        : []
      const trancheInputs = this.getSubmissionUserTrancheInputs(
        calculationStructure
      ).filter(t => t.transactionId === submissionStructure.transaction_id)
      if (!usersToAdd || !trancheInputs) {
        return
      }
      // for each user associated with the reinsurer that was selected, for each tranche on the transaction
      const usersToAddRequests = usersToAdd.flatMap(user =>
        trancheInputs.map(trancheInput =>
          this.creditService.saveCreditSubmissionUserTranche({
            submission_id: submissionStructure.id,
            user_id: user.userId,
            tranche_id: trancheInput.trancheId,
            rol: trancheInput.defaultRol,
            reinsurer_participation_percent:
              trancheInput.defaulReinsurerParticipationPercent,
            collateral_percent: trancheInput.defaultCollateralPercent,
            ceding_commission: trancheInput.defaultCedingComission,
            profit_commission: trancheInput.defaultProfitComission,
            status: CreditSubmissionUserTrancheStatus.Granted,
          })
        )
      )
      // for each user associated with the reinsurer that was renmoved, for each tranche on the transaction
      const usersToRemoveRequests = usersToRemove.flatMap(user =>
        trancheInputs.map(trancheInput =>
          this.creditService.saveCreditSubmissionUserTranche({
            submission_id: submissionStructure.id,
            user_id: user.userId,
            tranche_id: trancheInput.trancheId,
            rol: trancheInput.defaultRol,
            reinsurer_participation_percent:
              trancheInput.defaulReinsurerParticipationPercent,
            collateral_percent: trancheInput.defaultCollateralPercent,
            ceding_commission: trancheInput.defaultCedingComission,
            profit_commission: trancheInput.defaultProfitComission,
            status: CreditSubmissionUserTrancheStatus.Cancelled,
          })
        )
      )
      return usersToAddRequests.concat(usersToRemoveRequests)
    })
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data))
    )
  }

  private createCalculationStructure(
    calculationStructure: CreditCalculationStructure
  ): Observable<CreditCalculationStructure> {
    return this.creditService
      .createSubmissionStructure(calculationStructure)
      .pipe(map(res => res.data))
  }

  private createSubmissionStructure(
    calculationStructure: CreditCalculationStructure,
    props: CreateSubmissionDraftProps
  ): Observable<CreditSubmissionStructure> {
    this.saveThumbnailForStructure(props, calculationStructure)
    return this.creditService
      .postCreditSubmissionStructure({
        program_id: props.creditStructure.program_id,
        label: calculationStructure.name,
        description: props.description,
        credit_submission_structure_id: calculationStructure._id,
        credit_structure_id: props.creditStructure.id,
        transaction_id: props.transactionId,
        status: CreditSubmissionStatus.Draft,
        selected_gls: props.portfolio.gross_loss_scenarios
          .map(gls => gls._id)
          .join(','),
        portfolio_id: props.portfolio._id,
        doc_link: '', // initially empty
      })
      .pipe(map(res => res.data))
  }

  private saveThumbnailForStructure(
    props: CreateSubmissionDraftProps,
    calculationStructure: CreditCalculationStructure
  ): void {
    const timestamp = new Date().toISOString() // used to bust cache
    if (!props.creditStructure.imageSrc) {
      return
    }
    this.backendService
      .getThumbnailImage(props.creditStructure.imageSrc, timestamp)
      .subscribe((data: Blob) => {
        this.creditThumbnailService
          .saveCreditStructureThumbnail(
            props.portfolio._id,
            calculationStructure._id,
            data
          )
          .subscribe()
      })
  }

  private getSubmissionUserTrancheInputs(
    calculationStructure: CreditCalculationStructure
  ): CreditSubmissionUserTrancheInputs[] {
    return calculationStructure.transactions.flatMap(transaction =>
      transaction.tranches.map(tranche => {
        return {
          transactionId: transaction._id,
          trancheId:
            transaction.transaction_type !== CreditTransactionType.pmiQs
              ? tranche._id
              : transaction._id,
          defaultRol:
            transaction.transaction_type !== CreditTransactionType.pmiQs
              ? tranche.rol
              : 0,
          defaulReinsurerParticipationPercent:
            tranche.reinsurer_participation_percent,
          defaultCollateralPercent: calculationStructure.collateral_percent,
          defaultCedingComission:
            transaction.transaction_type === CreditTransactionType.pmiQs
              ? transaction.ceding_commission
              : 0,
          defaultProfitComission:
            transaction.transaction_type === CreditTransactionType.pmiQs
              ? transaction.profit_commission
              : 0,
        }
      })
    )
  }
}
