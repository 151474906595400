<app-theme-container>
  <app-metrics
    [clientID]="clientID$ | async"
    [studyID]="studyId$ | async"
    [studies]="studies$ | async"
    [studySelected]="studySelected$ | async"
    [metricCategories]="categories$ | async"
    [expandedMetricCategories]="expandedMetricCategories$ | async"
    [saving]="saving$ | async"
    [loading]="loading$ | async"
    [totalWeight]="totalWeight$ | async"
    [metricDirty]="metricDirty$ | async"
    [lossFilters]="currentLossFilters$ | async"
    [selectedProgramID]="selectedProgramID$ | async"
    [programs]="programs$ | async"
    [accountOpportunities]="accountOpportunities$ | async"
    (collapseToggle)="onCollapseToggle($event)"
    (saveClick)="onSaveClick()"
    (updateMetricElement)="onUpdateMetricElement($event)"
    (addCustomMetric)="onAddCustomMetric($event)"
    (enableAllClick)="onEnableAllClick()"
  ></app-metrics>
</app-theme-container>
