  <div class="tracking-side-element" #controlsRef>
    <form *ngIf="selectors" class="filters" [formGroup]="form">
      <app-multiselect-autocomplete [form]="form" [filter]="selectors.clientName" [name]="'Client Name'"
        (clearFilter)="clearFilter($event, selectors.clientName)" (setFilter)="dispatchFilters()"
        [loading]="isLoading" ></app-multiselect-autocomplete>
      <app-multiselect-autocomplete [form]="form" [filter]="selectors.year" [name]="'Year'"
        (clearFilter)="clearFilter($event, selectors.year)" (setFilter)="dispatchFilters()"
        [loading]="isLoading"></app-multiselect-autocomplete>
      <app-multiselect-autocomplete [form]="form" [filter]="selectors.businessUnit" [name]="'Business Unit'"
        (clearFilter)="clearFilter($event, selectors.businessUnit)"(setFilter)="dispatchFilters()"
        [loading]="isLoading" ></app-multiselect-autocomplete>
      <app-multiselect-autocomplete [form]="form" [filter]="selectors.complete" [name]="'Complete'"
        (clearFilter)="clearFilter($event, selectors.complete)" (setFilter)="dispatchFilters()"
        [loading]="isLoading"></app-multiselect-autocomplete>

      <section class="more-options_ranges">
        <div class="range_inputs">
          <mat-label class="range_label"> {{ inceptionInterval.name }}</mat-label>
          <mat-form-field subscriptSizing="dynamic"
            class="field interval app-bar-field app-bar-field-button app-field-bold-label">
            <input matInput placeholder="Start" [matDatepicker]="startDate" [value]="minInceptionDate"
            (dateChange)="intervalChanged(
                      'min',
                      $event.target?.value
                    )" />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field subscriptSizing="dynamic"
            class="field interval app-bar-field app-bar-field-button app-field-bold-label">
            <input matInput placeholder="End" [matDatepicker]="endDate" [value]="maxInceptionDate"
            (dateChange)="intervalChanged(
                      'max',
                      $event.target?.value
                    )" />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </div>
      </section>

    </form>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <button class="save" appButton accent (click)="savePreferences()">
      <span>Save Selected Preferences </span>
    </button>

    <mat-form-field class="selector" appearance="fill" *ngIf="userPreferences.length > 0">
      <mat-label>Saved Preferences:</mat-label>
      <mat-select [(value)]="selectedPreferenceValue" (selectionChange)="onPreferenceChange($event)">
        <mat-option *ngFor="let preference of userPreferences" [value]="preference.id">
          {{ getName(preference.id) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
