import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
// tslint:disable-next-line: max-line-length
import { CalculatedPortfolioDetailMetrics } from '../../model/portfolio-metrics.model'
import {
  PortfolioSetAndStudyIDs,
  PortfolioViewID,
} from '../../model/portfolio-set.model'

export const fetchPortfolioViewDetailMetrics = createAction(
  '[Portfolio View Detail Metrics] Fetch',
  props<PortfolioSetAndStudyIDs & PortfolioViewID>()
)

export const fetchPortfolioViewDetailMetricsSuccess = createAction(
  '[Portfolio View Detail Metrics] Fetch Success',
  props<
    PortfolioSetAndStudyIDs &
      PortfolioViewID & {
        cededCalculatedMetrics: CalculatedPortfolioDetailMetrics
        grossCalculatedMetrics: CalculatedPortfolioDetailMetrics
        netCalculatedMetrics: CalculatedPortfolioDetailMetrics
      }
  >()
)

export const fetchPortfolioViewDetailMetricsFailure = createAction(
  '[Portfolio View Detail Metrics] Fetch Failure',
  props<
    PortfolioSetAndStudyIDs & PortfolioViewID & { error: ApplicationError }
  >()
)
