import {
  LossSetGroup,
  LossSetGroupMember,
} from '../../analysis/model/loss-set-layers.model'
import {
  LossSetGroupMemberResponse,
  LossSetGroupResponse,
  OmitID,
} from '../model/backend.model'

export const convertLossSetGroupToRequest = (
  lossSetGroup: Partial<LossSetGroup>
): OmitID<Partial<LossSetGroupResponse>> => ({
  name: lossSetGroup.name,
  program_id: lossSetGroup.programID,
})

export const convertLossSetGroupFromResponse = (
  res: LossSetGroupResponse
): LossSetGroup => ({
  id: String(res.id),
  name: res.name,
  programID: String(res.program_id),
  lossSetLayers: [],
})

export const convertLossSetGroupMemberToRequest = (
  lossSetGroupMember: Partial<LossSetGroupMember>
): OmitID<Partial<LossSetGroupMemberResponse>> => ({
  name: lossSetGroupMember.name,
  loss_set_group_id: lossSetGroupMember.lossSetGroupID,
  loss_set_layer_id: lossSetGroupMember.lossSetLayerID,
})

export const convertLossSetGroupMemberFromResponse = (
  res: LossSetGroupMemberResponse
): LossSetGroupMember => ({
  id: String(res.id),
  name: res.name,
  lossSetGroupID: String(res.loss_set_group_id),
  lossSetLayerID: res.loss_set_layer_id,
})
