<ng-container *ngIf="!row.loading; else loadingTemplate">
  <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <ng-container [ngSwitch]="column.whenLoading">
    <ng-container *ngSwitchCase="'show'">
      <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'message'">
      <span class="loading">Loading...</span>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span> </span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #valueTemplate>
  <ng-container *ngIf="isApplicable; else blacklistTemplate">
    <ng-container *ngIf="!column.editable || readonly; else editTemplate">
      <div
        [ngClass]="{
          'indicator-class': row['indicator']
        }"
        *ngIf="!isHtml"
      >
        <app-metric-text
          class="text"
          [ngClass]="{ clamp: column.clamp || clamp }"
          [ngStyle]="getTextStyle(column)"
          [ngStyle]="getRankColorChangeByValue()"
          [ngStyle]="getRowColor(row)"
          [isLayerDetailsTab]="isLayerDetailsTab"
          [value]="
            column.id === 'currencyChange' ? row['currency'] : row[column.id]
          "
          [valueType]="getValueType(column, row)"
          [id]="getId(column)"
          [removeSuffixCurrencyTemplateOption]="
            removeSuffixCurrencyTemplateOption
          "
          [numberTransform]="
            column.numberTransform ? column.numberTransform : toThousands
          "
          [numberFormat]="column.numberFormat ? column.numberFormat : '1.0-0'"
          [percentFormat]="percentFormat"
          [isAssignedLines]="isAssignedLines"
          [type]="row.type"
          [subtype]="row.subtype"
          [whitelist]="column.whitelist"
          [blacklist]="column.blacklist"
          [references]="column.references"
          [tooltip]="getTooltip(column, row)"
          [currency]="row.currency"
          [useZeroText]="useZeroText"
          [customClick]="customClick"
          (click)="onCustomClick()"
        ></app-metric-text>
      </div>
      <div
        [ngClass]="{
          'indicator-class': row['indicator']
        }"
        *ngIf="isHtml"
      >
        <ejs-richtexteditor
          id="rich-text-editor"
          #richtext
          [value]="value"
          readonly="true"
        >
        </ejs-richtexteditor>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #editTemplate>
  <div class="edit">
    <ng-container [ngSwitch]="column.valueType">
      <app-percentage-input
        *ngSwitchCase="'percentage'"
        cell
        alignRight
        [name]="column.label"
        [columnName]="column.id"
        [value]="value"
        [decimal]="decimals"
        [isAssignedLines]="isAssignedLines"
        [isCession]="true"
        [readonly]="!column.editable || !editable"
        [submitting]="submitting"
        [spacing]="spacing"
        [selectOnFocus]="column.selectOnFocus"
        [noLoadingSpinnerOnSubmit]="noLoadingSpinnerOnSubmit"
        [isQQ]="isQQ"
        [ngStyle]="getRowColor(row)"
        (inputChange)="value = $event"
        (inputSubmit)="onSubmit()"
      ></app-percentage-input>
      <app-layer-property
        *ngSwitchDefault
        cell
        [alignRight]="column.alignRight || column.valueType && column.valueType !== 'text'"
        [name]="column.label"
        [type]="column.valueType || 'text'"
        [references]="column.references"
        [value]="value"
        [currentCurrency]="currentCurrency"
        [numberTransform]="
          column.numberTransform ? column.numberTransform : toThousands
        "
        [numberReverseTransform]="
          column.numberReverseTransform
            ? column.numberReverseTransform
            : fromThousands
        "
        [dontFilterList]="true"
        [decimal]="column.id === 'pmpm' ? '4' : '0'"
        [readonly]="!column.editable"
        [submitting]="submitting"
        [inputChangeDebounceTime]="
          column.changeDebounceTime === undefined
            ? undefined
            : column.changeDebounceTime
        "
        [ngStyle]="getRowColor(row)"
        (inputChange)="value = $event"
        (inputSubmit)="onSubmit()"
      ></app-layer-property>
    </ng-container>
  </div>
</ng-template>

<ng-template #blacklistTemplate>
  <span>{{ notApplicableText }}</span>
</ng-template>
