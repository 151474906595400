import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { selectCurrentCurrency } from '../../store/analysis.selectors'
import { Observable } from 'rxjs'
import { AnalysisProfile } from 'src/app/api/analyzere/analyzere.model'
import {
  selectCurrencyRates,
  selectCurrentAnalysisProfile,
} from '../../../core/store/broker/broker.selectors'
import { CurrencyRate } from '../../tower/mechanics/tower.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-exchange-dialog-container',
  templateUrl: './portfolio-exchange-dialog.container.html',
})
export class PortfolioExchangeDialogContainerComponent implements OnInit {
  currentCurrency$: Observable<string | undefined>
  analysisProfile$: Observable<AnalysisProfile | null>
  exchangeRateData$: Observable<CurrencyRate[]>
  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.exchangeRateData$ = this.store.pipe(select(selectCurrencyRates))
    this.currentCurrency$ = this.store.pipe(select(selectCurrentCurrency))
    this.analysisProfile$ = this.store.pipe(
      select(selectCurrentAnalysisProfile)
    )
  }
}
