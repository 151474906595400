import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { AGENCY_MARKET_USE, Reinsurer } from '../../core/model/reinsurer.model'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  AssignedLines,
  EXPIRING_REINSURER_NAME,
  FOT_MASTER_REINSURER_NAME,
  QuoteReinsurer,
  ReinsurerPhase,
  ReinsurerSubjectivity,
  QuoteFields,
  QUOTE_TEMP_PREFIX,
  ReinsurerPhases,
  QuoteCustomCompareView,
  QuoteCompareViewUpdatePayload,
} from '../models/quote.model'
import { layerIds } from '../../analysis/model/layer-palette.model'
import { MessageDialogService } from '@shared/message-dialog.service'
import { MatDialog } from '@angular/material/dialog'
import { updateQuoteFields } from '../store/reinsurer/reinsurer.actions'
import { AppState } from 'src/app/core/store'
import { Store } from '@ngrx/store'
import { QuoteConfirmationDialogComponent } from '../quote-confirmation-dialog/quote-confirmation-dialog.component'
import { selectCurrentProgram } from 'src/app/analysis/store/analysis.selectors'
import { QuoteDeleteReinsurerDialogComponent } from '../quote-delete-reinsurer-dialog/quote-delete-reinsurer-dialog.component'

const LOCKTON_RE_PLACED_THROUGH = 'Lockton Re'
const DEFAULT_VERSION_NAME = 'Unlabeled'

enum AddNewVersionType {
  QUOTE = 'Add New Quote',
  FOT = 'Add New FOT',
  EXPIRING = 'Add Custom Expiring',
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-actions',
  templateUrl: './quote-actions.component.html',
  styleUrls: ['./quote-actions.component.scss'],
})
export class QuoteActionsComponent implements OnInit, OnChanges {
  reinsuranceList: string[] = []
  selectedRe = ''
  reinsurerPhases = ReinsurerPhases
  selectedPhase: ReinsurerPhase = ReinsurerPhases.Quote
  selectedPhaseVersion = '1'
  selectedQuoteVersion = '0'
  selectedFOTVersion = '0'
  selectedExpiringVersion = '0'
  quoteReinsurers: QuoteReinsurer[] = []
  fotReinsurers: QuoteReinsurer[] = []
  expiringReinsurers: QuoteReinsurer[] = []
  isFotMaster = false
  isExpiring = false
  isDeclined = false
  declineConfirm = false
  reason = ''
  exportSelected = true
  currentLabel = ''
  isLabelDirty = false
  reinsurerPlacedThrough = ''
  buttonLabel = 'Assign Lines'
  populateFromList: QuoteReinsurer[] = []

  @Input() reinsurerByName: QuoteReinsurer[]
  @Input() currentReinsurer: QuoteReinsurer | undefined
  @Input() selectedReinsurer: string | null
  @Input() isExpanded: boolean
  @Input() expandedName: string | null
  @Input() isFOTMasterCreated: boolean
  @Input() isSaving: boolean
  @Input() reinsurerList: ReinsurerState[]
  @Input() allReinsurers: QuoteReinsurer[]
  @Input() reinsurersNameListUpdated: Reinsurer[]
  @Input() isGroupSelected = false
  @Input() isSLSelected = false
  @Input() layer: LayerState | null | undefined
  @Input() layerState: LayerState[] = []
  @Input() showCustomCompare: boolean
  @Input() customCompareViews: QuoteCustomCompareView[]

  @Output() saveClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() deleteClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() declineClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() exportToggleClick = new EventEmitter<{
    reinsurer: QuoteReinsurer
  }>()
  @Output() nameSelection = new EventEmitter<{
    reinsurerName: string
    id: string
    tpRef: string
    brokerageChanges: Partial<QuoteFields>
  }>()
  @Output() addOrUpdateVersionReinsurer = new EventEmitter<{
    reinsurerId?: string
    version: string
    label?: string
    reinsurerName: string
    phase: ReinsurerPhase
    subjectivity: ReinsurerSubjectivity[]
    assignedLines: AssignedLines[]
    isPreferred?: boolean
    brokerageRIPCommission?: number
    brokerageCommission?: number
  }>()
  @Output() isUKReinsurerActions = new EventEmitter<boolean>()
  @Output() selectedID = new EventEmitter<string>()
  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()
  @Output() populateClick = new EventEmitter<{ from: string; to: string }>()
  @Output() expandedReinsurer = new EventEmitter<QuoteReinsurer | undefined>()
  @Output() assignedLinesClick = new EventEmitter<{ id: string }>()
  @Output() updateLabel = new EventEmitter<{
    reinsurerId: string
    label: string
  }>()
  @Output() preferredClick = new EventEmitter<{
    reinsurer: QuoteReinsurer
    isChange: boolean
  }>()
  @Output() updateCompareViews =
    new EventEmitter<QuoteCompareViewUpdatePayload>()

  constructor(
    private snackBar: MatSnackBar,
    private messageService: MessageDialogService,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  get isDisabled() {
    return !(this.selectedReinsurer === this.currentReinsurer?.id)
  }

  get isNameSelectDisable() {
    const currentState = this.reinsurerList.find(
      r => r.reinsurer.id === this.selectedReinsurer
    )
    const currentNew =
      currentState && currentState.new ? currentState.new : false
    return !currentNew
  }

  get isSaveDisabled() {
    const currentState = this.reinsurerList.find(
      r => r.reinsurer.id === this.selectedReinsurer
    )
    const currentDirty =
      currentState && currentState.dirty ? currentState.dirty : false
    return (currentDirty || this.isLabelDirty) && this.selectedRe !== ''
  }

  get comparisonsForReinsurer(): QuoteCustomCompareView[] {
    return this.customCompareViews.filter(view =>
      view.members.some(member => member === Number(this.selectedReinsurer))
    )
  }

  ngOnInit(): void {
    if (this.currentReinsurer) {
      this.selectedPhase = this.currentReinsurer.reinsurerPhase
      this.selectedPhaseVersion = this.currentReinsurer.reinsurerPhaseVersion
      if (this.selectedPhase === ReinsurerPhases.Quote) {
        this.selectedQuoteVersion = this.selectedPhaseVersion
        this.currentLabel =
          this.currentReinsurer.reinsurerPhaseLabel ??
          `Unlabeled ${this.selectedQuoteVersion}`
      } else if (this.selectedPhase === ReinsurerPhases.FOT) {
        this.selectedFOTVersion = this.selectedPhaseVersion
        this.currentLabel =
          this.currentReinsurer.reinsurerPhaseLabel ??
          `Unlabeled ${this.selectedFOTVersion}`
      } else if (this.selectedPhase === ReinsurerPhases.Expiring) {
        this.selectedExpiringVersion = this.selectedPhaseVersion
        this.currentLabel =
          this.currentReinsurer.reinsurerPhaseLabel ??
          `Unlabeled ${this.selectedExpiringVersion}`
      }
      this.isDeclined = this.currentReinsurer.decline
        ? this.currentReinsurer.decline
        : false
      this.exportSelected = this.isDeclined
        ? false
        : !(
            this.currentReinsurer.exportToggle !== undefined &&
            !this.currentReinsurer.exportToggle
          )
      this.reinsuranceList =
        this.reinsurersNameListUpdated?.map(r => {
          if (
            this.currentReinsurer?.reType == AGENCY_MARKET_USE
              ? r.tpRef === this.currentReinsurer?.tpRef &&
                r.reinsurerProgramFactor[0].relation_seq_number ===
                  this.currentReinsurer?.sequenceNumber
              : r.tpRef === this.currentReinsurer?.tpRef
          ) {
            return r.reinsurerProgramFactor[0].obo_name ?? r.name
          }
        }) ?? []

      if (this.reinsuranceList.length === 0) {
        this.reinsuranceList.push('DEFAULT- (Add reinsurer from selection)')
      }

      const placedReinsurer = this.reinsurersNameListUpdated.find(l => {
        return this.currentReinsurer?.reType == AGENCY_MARKET_USE
          ? l.tpRef === this.currentReinsurer?.tpRef &&
              l.reinsurerProgramFactor[0].relation_seq_number ===
                this.currentReinsurer?.sequenceNumber
          : l.tpRef === this.currentReinsurer?.tpRef
      })
      this.selectedRe = placedReinsurer
        ? (placedReinsurer.reinsurerProgramFactor[0].obo_name ??
          placedReinsurer.name)
        : this.currentReinsurer.quoteReinsurerName
      const placedThrough =
        placedReinsurer?.reinsurerProgramFactor[0]?.placed_through ?? ''
      if (placedThrough !== LOCKTON_RE_PLACED_THROUGH) {
        this.reinsurerPlacedThrough = placedThrough
      }
    }
    this.updateReNameList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allReinsurers) {
      this.populateFromList = this.getPopulateFromList()
    }
    if (changes.reinsurersNameListUpdated) {
      this.reinsuranceList =
        this.reinsurersNameListUpdated?.map(
          r => r.reinsurerProgramFactor[0].obo_name ?? r.name
        ) ?? []
      if (this.reinsuranceList.length === 0) {
        this.reinsuranceList.push('DEFAULT- (Add reinsurer from selection)')
      }

      const placedReinsurer = this.reinsurersNameListUpdated.find(l => {
        return this.currentReinsurer?.reType == AGENCY_MARKET_USE
          ? (l.tpRef === this.currentReinsurer?.tpRef &&
              l.reinsurerProgramFactor[0].relation_seq_number) ===
              this.currentReinsurer?.sequenceNumber
          : l.tpRef === this.currentReinsurer?.tpRef
      })

      const placedThrough =
        placedReinsurer?.reinsurerProgramFactor[0]?.placed_through ?? ''
      if (placedThrough !== LOCKTON_RE_PLACED_THROUGH) {
        this.reinsurerPlacedThrough = placedThrough
      }
      this.updateReNameList()
    }
  }

  updateReNameList(): void {
    this.quoteReinsurers = this.reinsurerByName.filter(
      l => l.reinsurerPhase === ReinsurerPhases.Quote
    )
    this.fotReinsurers = this.reinsurerByName.filter(
      l => l.reinsurerPhase === ReinsurerPhases.FOT
    )
    this.expiringReinsurers = this.reinsurerByName.filter(
      l => l.reinsurerPhase === ReinsurerPhases.Expiring
    )
    this.reinsuranceList.sort()
    if (!this.reinsuranceList.find(r => r === FOT_MASTER_REINSURER_NAME)) {
      this.reinsuranceList.push(FOT_MASTER_REINSURER_NAME)
    }
    if (!this.reinsuranceList.find(r => r === EXPIRING_REINSURER_NAME)) {
      this.reinsuranceList.push(EXPIRING_REINSURER_NAME)
    }
    if (
      this.currentReinsurer?.quoteReinsurerName === FOT_MASTER_REINSURER_NAME
    ) {
      this.isFotMaster = true
    }
    if (this.currentReinsurer?.quoteReinsurerName === EXPIRING_REINSURER_NAME) {
      this.isExpiring = true
    }
  }

  onReinsurerSelection(reSelected: string) {
    if (
      this.reinsurerList.find(
        l => l.reinsurer.quoteReinsurerName === reSelected
      )
    ) {
      // Already selected Reinsurer
      this.snackBar.open(
        'A quote with this reinsurer already exists, please add a quote to the existing panel with this reinsurer',
        'X',
        { duration: 10000, panelClass: 'app-error' }
      )
      this.delete()
    } else {
      const re = this.reinsurersNameListUpdated.find(
        r =>
          r.name === reSelected ||
          r.reinsurerProgramFactor[0].obo_name === reSelected
      )
      const { brokerageRIPCommission, brokerageCommission } =
        this.currentReinsurer.quoteFields
      if (re) {
        if (re.country_code === 'GB') {
          this.isUKReinsurerActions.emit(true)
        }
        const domicileSpecificBrokerages: Partial<QuoteFields> = {}
        if (re) {
          if (re.domicile === 'United Kingdom') {
            domicileSpecificBrokerages.brokerageCommission = 0
            domicileSpecificBrokerages.brokerageRIPCommission = 0
          }
        }
        if (domicileSpecificBrokerages.brokerageRIPCommission !== 0) {
          domicileSpecificBrokerages.brokerageCommission = 0.15
          domicileSpecificBrokerages.brokerageRIPCommission = 0
        }
        this.selectedRe = reSelected
        if (this.currentReinsurer?.id) {
          this.nameSelection.emit({
            reinsurerName: reSelected,
            id: this.currentReinsurer?.id,
            tpRef: re.tpRef || '0',
            brokerageChanges: domicileSpecificBrokerages,
          })
          this.addOrUpdateVersionAndLabel(
            ReinsurerPhases.Quote,
            '1',
            `Unlabeled 1`,
            this.currentReinsurer.id,
            true,
            brokerageRIPCommission,
            brokerageCommission
          )
        }
      }
      if (
        this.selectedRe === FOT_MASTER_REINSURER_NAME &&
        this.currentReinsurer
      ) {
        this.isFotMaster = true
        this.addOrUpdateVersionAndLabel(
          ReinsurerPhases.FOT,
          '1',
          `Unlabeled 1`,
          this.currentReinsurer.id,
          true,
          brokerageRIPCommission,
          brokerageCommission
        )
      }
      if (
        this.selectedRe === EXPIRING_REINSURER_NAME &&
        this.currentReinsurer
      ) {
        this.addOrUpdateVersionAndLabel(
          ReinsurerPhases.Expiring,
          '1',
          `Unlabeled 1`,
          this.currentReinsurer.id,
          true,
          brokerageRIPCommission,
          brokerageCommission
        )
      }
      if (this.selectedRe === EXPIRING_REINSURER_NAME) {
        this.addOrUpdateVersionAndLabel(
          EXPIRING_REINSURER_NAME,
          '1',
          `Unlabeled 1`,
          // tslint:disable-next-line: no-non-null-assertion
          this.currentReinsurer!.id,
          true,
          brokerageRIPCommission,
          brokerageCommission
        )
      }
    }
  }

  get getFOTList(): string[] {
    const fotList1: string[] = []
    this.fotReinsurers
      .filter(q => {
        const layer = this.layerState.find(
          l => l.layer.id === q.cededlayerID
        )?.layer
        return (
          layer?.meta_data.sage_layer_subtype !== 'section-layer' &&
          layer?.meta_data.sage_layer_type !== 'drop'
        )
      })
      .forEach(q => {
        const label =
          q.reinsurerPhaseLabel ?? `Unlabeled ${q.reinsurerPhaseVersion}`
        const quoteFound = fotList1.find(l => l === label)
        if (!quoteFound) {
          fotList1.push(label)
        }
      })
    fotList1.push(AddNewVersionType.FOT)
    return fotList1
  }

  get getExpiringList(): string[] {
    const expiringList1: string[] = []
    this.expiringReinsurers
      .filter(q => {
        const layer = this.layerState.find(
          l => l.layer.id === q.cededlayerID
        )?.layer
        return (
          layer?.meta_data.sage_layer_subtype !== 'section-layer' &&
          layer?.meta_data.sage_layer_type !== 'drop'
        )
      })
      .forEach(q => {
        const label =
          q.reinsurerPhaseLabel ?? `Unlabeled ${q.reinsurerPhaseVersion}`
        const quoteFound = expiringList1.find(l => l === label)
        if (!quoteFound) {
          expiringList1.push(label)
        }
      })
    this.buttonLabel = 'View Lines'
    expiringList1.push(AddNewVersionType.EXPIRING)
    return expiringList1
  }

  get getQuoteList(): string[] {
    const quoteList1: string[] = []
    this.quoteReinsurers
      .filter(q => {
        const layer = this.layerState.find(
          l => l.layer.id === q.cededlayerID
        )?.layer
        return (
          layer?.meta_data.sage_layer_subtype !== 'section-layer' &&
          layer?.meta_data.sage_layer_type !== 'drop'
        )
      })
      .forEach(q => {
        const label =
          q.reinsurerPhaseLabel ?? `Unlabeled ${q.reinsurerPhaseVersion}`
        const quoteFound = quoteList1.find(l => l === label)
        if (!quoteFound) {
          quoteList1.push(label)
        }
      })
    quoteList1.push(AddNewVersionType.QUOTE)
    return quoteList1
  }

  getPopulateFromList() {
    let populateOptionsList: QuoteReinsurer[]
    populateOptionsList = this.allReinsurers
      .filter(l => l.id !== this.currentReinsurer?.id)
      .filter(l => !l.decline)
    if (
      this.isGroupSelected ||
      this.isSLSelected ||
      (this.layer &&
        this.layer.layer.meta_data.sage_layer_type ===
          layerIds.catMultisection) ||
      (this.layer &&
        this.layer.layer.meta_data.sage_layer_type === layerIds.catTd)
    ) {
      populateOptionsList = populateOptionsList.filter(
        al =>
          !(
            al.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
            al.reinsurerPhaseVersion ===
              this.currentReinsurer?.reinsurerPhaseVersion &&
            (al.reType == AGENCY_MARKET_USE
              ? al.tpRef === this.currentReinsurer?.tpRef &&
                al.sequenceNumber === this.currentReinsurer?.sequenceNumber
              : al.tpRef === this.currentReinsurer?.tpRef)
          )
      )
    }

    if (this.currentReinsurer?.reinsurerPhase === ReinsurerPhases.Quote) {
      populateOptionsList = populateOptionsList.filter(
        p =>
          p.reinsurerPhase === ReinsurerPhases.Quote ||
          p.reinsurerPhase === ReinsurerPhases.Expiring
      )
    } else if (this.currentReinsurer?.reinsurerPhase === ReinsurerPhases.FOT) {
      populateOptionsList = populateOptionsList.filter(
        p =>
          p.reinsurerPhase === ReinsurerPhases.Quote ||
          p.reinsurerPhase === ReinsurerPhases.FOT ||
          p.reinsurerPhase === ReinsurerPhases.Expiring
      )
    } else if (
      this.currentReinsurer?.reinsurerPhase === ReinsurerPhases.Expiring
    ) {
      populateOptionsList = populateOptionsList.filter(
        p =>
          p.reinsurerPhase === ReinsurerPhases.Quote ||
          p.reinsurerPhase === ReinsurerPhases.Expiring
      )
    }
    if (
      this.isGroupSelected ||
      this.isSLSelected ||
      (this.layer &&
        (this.layer.layer.meta_data.sage_layer_type === 'cat_multisection' ||
          this.layer.layer.meta_data.sage_layer_type ===
            'noncat_multisection')) ||
      (this.layer && this.layer.layer.meta_data.sage_layer_type === 'cat_td')
    ) {
      populateOptionsList = populateOptionsList.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.reinsurerPhase === value.reinsurerPhase &&
              t.reinsurerPhaseVersion === value.reinsurerPhaseVersion &&
              (t.reType == AGENCY_MARKET_USE
                ? t.tpRef === value.tpRef &&
                  t.sequenceNumber === value.sequenceNumber
                : t.tpRef === value.tpRef)
          )
      )
    }
    return populateOptionsList
  }

  get getToolTip() {
    return (
      'Populate ' +
      this.selectedPhase +
      ' ' +
      this.selectedPhaseVersion +
      ' From:'
    )
  }

  get allowFOT() {
    return (
      this.quoteReinsurers.length > 1 ||
      (this.quoteReinsurers[0] &&
        !this.quoteReinsurers[0].id?.includes(QUOTE_TEMP_PREFIX) &&
        this.isFOTMasterCreated) ||
      this.fotReinsurers.length > 0 ||
      this.isFotMaster
    )
  }

  get allowExpiring() {
    return (
      this.quoteReinsurers.length > 1 ||
      (this.quoteReinsurers[0] &&
        !this.quoteReinsurers[0].id?.includes(QUOTE_TEMP_PREFIX)) ||
      this.fotReinsurers.length > 0 ||
      this.isExpiring
    )
  }

  addOrUpdateVersionAndLabel(
    phase: ReinsurerPhase,
    phaseNumber: string,
    phaseLabel: string,
    reinsurerId?: string,
    isPreferred?: boolean,
    brokerageRIPCommission?: number,
    brokerageCommission?: number
  ) {
    this.addOrUpdateVersionReinsurer.emit({
      reinsurerId,
      version: phaseNumber,
      label: phaseLabel,
      reinsurerName: this.selectedRe,
      phase,
      subjectivity:
        [] /* common subjectivites will be populated automatically */,
      assignedLines: this.currentReinsurer?.riskAssignedLinesLink
        ? this.currentReinsurer?.riskAssignedLinesLink.map(
            ({ id, ...rest }) => rest
          ) /* new fot version needs new assigned lines ids */
        : [],
      isPreferred,
      brokerageRIPCommission,
      brokerageCommission,
    })
  }

  isUnsavedQuote(): boolean {
    return this.reinsurerList.some(a =>
      a.reinsurer.id?.startsWith(QUOTE_TEMP_PREFIX)
    )
  }

  onQuoteChange(quoteLabel: string) {
    if (!this.isUnsavedQuote()) {
      this.selectedPhaseVersion = ''
      this.currentLabel = quoteLabel
      let quote: QuoteReinsurer | undefined

      if (quoteLabel === AddNewVersionType.QUOTE) {
        const nextQuoteVersionNum = this.getNextAvailableSeq().toString()
        const { brokerageRIPCommission, brokerageCommission } =
          this.currentReinsurer.quoteFields
        this.addOrUpdateVersionAndLabel(
          ReinsurerPhases.Quote,
          nextQuoteVersionNum,
          `Unlabeled ${nextQuoteVersionNum}`,
          undefined,
          false,
          brokerageRIPCommission,
          brokerageCommission
        )
      } else {
        quote = this.quoteReinsurers
          .slice()
          .find(q => q.reinsurerPhaseLabel === quoteLabel)
      }
      if (quote) {
        this.selectedID.emit(quote.id)
      }
    }
  }

  onFOTChange(fotLabel: string) {
    if (!this.isUnsavedQuote()) {
      this.selectedPhaseVersion = ''
      this.currentLabel = fotLabel
      let fot: QuoteReinsurer | undefined

      if (fotLabel === AddNewVersionType.FOT) {
        const nextFotVersionNum = this.getNextAvailableSeq().toString()
        this.addOrUpdateVersionAndLabel(
          ReinsurerPhases.FOT,
          nextFotVersionNum,
          `Unlabeled ${nextFotVersionNum}`,
          undefined,
          false
        )
      } else {
        fot = this.fotReinsurers
          .slice()
          .find(q => q.reinsurerPhaseLabel === fotLabel)
      }
      if (fot) {
        this.selectedID.emit(fot?.id)
      }
    }
  }

  onExpiringChange(expiringLabel: string) {
    this.selectedPhaseVersion = ''
    this.currentLabel = expiringLabel
    if (expiringLabel === AddNewVersionType.EXPIRING) {
      const nextExpiringVersionNum = this.getNextAvailableSeq().toString()
      this.addOrUpdateVersionAndLabel(
        ReinsurerPhases.Expiring,
        nextExpiringVersionNum,
        `Unlabeled ${nextExpiringVersionNum}`,
        undefined,
        false
      )
      return
    }
    const expiring = this.expiringReinsurers.find(
      q => q.reinsurerPhaseLabel === expiringLabel
    )
    if (expiring) {
      this.selectedID.emit(expiring?.id)
    }
  }

  onExpandClick() {
    if (this.currentReinsurer && this.currentReinsurer.quoteReinsurerName) {
      this.expandClick.emit({
        isOpen: true,
        name: this.currentReinsurer.quoteReinsurerName,
      })
      this.expandedReinsurer.emit(this.currentReinsurer)
    }
  }

  onPopulateFrom(reinsurer: QuoteReinsurer) {
    if (this.currentReinsurer && reinsurer.id && this.currentReinsurer.id) {
      this.populateClick.emit({
        from: reinsurer.id,
        to: this.currentReinsurer.id,
      })
    }
  }

  save(): void {
    if (
      this.currentReinsurer &&
      !this.isDisabled &&
      this.isSaveDisabled &&
      !this.isDeclined
    ) {
      // Check for same label
      const sameName = this.reinsurerByName.filter(
        re => re.reinsurerPhaseLabel === this.currentLabel
      )
      if (
        (this.isUpdateSingleLayer() && sameName.length > 1) ||
        (!this.isUpdateSingleLayer() && !this.isSameVersion(sameName))
      ) {
        const panelName =
          this.reinsurerByName && this.reinsurerByName.length > 0
            ? `${this.reinsurerByName[0].quoteReinsurerName}`
            : ''
        this.messageService.showMessage(
          panelName,
          `Multiple versions cannot have same label. Please update the label and save again.`
        )
      } else {
        let re: QuoteReinsurer = {
          ...this.currentReinsurer,
          reinsurerPhaseLabel: this.currentLabel,
          riskSubjectivityLink:
            this.currentReinsurer.riskSubjectivityLink?.filter(
              sub => !!sub.riskReinsurerId
            ),
        }
        let current: string
        this.store.select(selectCurrentProgram).subscribe(a => {
          current = a?.libRE
        })

        if (current === 'Y') {
          this.saveClick.emit({ reinsurer: re })
        } else {
          let updatedQuoteReinsurer = { ...re }
          let quoteFields = { ...re.quoteFields }
          let title: string

          if (
            (re.quoteFields.manualExpectedCededLoss &&
              re.quoteFields.automaticExpectedCededLoss) ||
            (re.quoteFields.manualExpectedCededPremium &&
              re.quoteFields.automaticExpectedCededPremium)
          ) {
            if (
              re.quoteFields.manualExpectedCededLoss &&
              re.quoteFields.automaticExpectedCededLoss &&
              re.quoteFields.manualExpectedCededPremium &&
              re.quoteFields.automaticExpectedCededPremium
            ) {
              title = 'Expected Ceded Loss and Expected Ceded Premium'
            } else if (re.quoteFields.manualExpectedCededLoss) {
              title = 'Expected Ceded Loss'
            } else if (re.quoteFields.manualExpectedCededPremium) {
              title = 'Expected Ceded Premium'
            }
            this.dialog
              .open(QuoteConfirmationDialogComponent, {
                data: {
                  titleData: title,
                },
              })
              .afterClosed()
              .subscribe(result => {
                if (result === 'useAnalyze') {
                  quoteFields.manualExpectedCededLoss = false
                  quoteFields.manualExpectedCededPremium = false
                  quoteFields.manualLossOnLine = false
                } else if (result === 'useManual') {
                  quoteFields.automaticExpectedCededLoss = false
                  quoteFields.automaticExpectedCededPremium = false
                }

                if (result === 'useAnalyze' || result === 'useManual') {
                  updatedQuoteReinsurer.quoteFields = quoteFields
                  this.store.dispatch(
                    updateQuoteFields({
                      id: updatedQuoteReinsurer.id,
                      change: updatedQuoteReinsurer.quoteFields,
                    })
                  )
                  this.saveClick.emit({
                    reinsurer: updatedQuoteReinsurer,
                  })
                }
              })
          } else {
            this.saveClick.emit({ reinsurer: re })
          }
        }
        this.isLabelDirty = false
      }
    }
  }

  isSameVersion(re: QuoteReinsurer[]): boolean {
    let isSame = true
    re.forEach(r => {
      if (
        r.reinsurerPhase !== re[0].reinsurerPhase ||
        r.reinsurerPhaseVersion !== re[0].reinsurerPhaseVersion
      ) {
        isSame = false
      }
    })
    return isSame
  }

  isUpdateSingleLayer(): boolean {
    return (
      !this.isGroupSelected &&
      !this.isSLSelected &&
      this.layer?.layer.meta_data.sage_layer_type !==
        layerIds.catMultisection &&
      this.layer?.layer.meta_data.sage_layer_type !== layerIds.catTd
    )
  }

  getExpiringSectionLabel(value: string): string {
    const section = !value.toLocaleLowerCase().includes('section')
      ? 'Section '
      : ''
    return `${section}${value}`
  }

  delete() {
    if (this.currentReinsurer && !this.isDisabled) {
      if (this.comparisonsForReinsurer.length && this.showCustomCompare) {
        // Delete and remove from all comparisons without opening dialog
        this.updateCompareViews.emit({
          reinsurerId: Number(this.selectedReinsurer),
          delete: [],
          remove: this.comparisonsForReinsurer,
        })
      } else if (!this.comparisonsForReinsurer.length) {
        // If there are no custom comparisons tied to the reinsurer, delete as usual
        this.deleteClick.emit({ reinsurer: this.currentReinsurer })
      } else {
        // Else, open dialog for user to decide how to proceed
        const dialogRef = this.dialog.open(
          QuoteDeleteReinsurerDialogComponent,
          {
            data: {
              comparisons: this.comparisonsForReinsurer,
            },
            width: '600px',
            height: '400px',
          }
        )
        dialogRef
          .afterClosed()
          .subscribe(
            (
              comparisonStatus:
                | Record<number, { status: 'remove' | 'delete' }>
                | undefined
            ) => {
              if (comparisonStatus) {
                const entries = Object.entries(comparisonStatus)
                const toRemoveIds = entries
                  .filter(([, { status }]) => status === 'remove')
                  .map(([id]) => Number(id))
                const toDeleteIds = entries
                  .filter(([, { status }]) => status === 'delete')
                  .map(([id]) => Number(id))
                const toRemove = this.comparisonsForReinsurer.filter(({ id }) =>
                  toRemoveIds.includes(id)
                )
                const toDelete = this.comparisonsForReinsurer.filter(({ id }) =>
                  toDeleteIds.includes(id)
                )
                this.updateCompareViews.emit({
                  reinsurerId: Number(this.selectedReinsurer),
                  delete: toDelete,
                  remove: toRemove,
                })
              }
            }
          )
      }
    }
  }

  decline() {
    if (this.currentReinsurer && !this.isDisabled) {
      const re1 = {
        ...this.currentReinsurer,
        decline: true,
        declineReason: this.reason,
      }
      this.declineClick.emit({ reinsurer: re1 })
    }
  }

  onUpdateExportToggleSelection(event: MatCheckboxChange) {
    if (this.currentReinsurer) {
      const re1 = {
        ...this.currentReinsurer,
        exportToggle: event.checked,
      }
      this.exportToggleClick.emit({ reinsurer: re1 })
    }
  }

  labelChange() {
    var alreadyFound = this.reinsurerByName.find(
      r => r.reinsurerPhaseLabel === this.currentLabel
    )

    if (alreadyFound) {
      const panelName =
        this.reinsurerByName && this.reinsurerByName.length > 0
          ? `${this.reinsurerByName[0].quoteReinsurerName}`
          : ''
      this.messageService.showMessage(
        panelName,
        `Duplicate version name found for this panel (${this.currentLabel}). Renaming to default version name.`
      )
      const nextSeqNum = this.getNextAvailableSeq()
      this.currentLabel = `${DEFAULT_VERSION_NAME} ${nextSeqNum}`
    }
    if (this.currentReinsurer?.id) {
      this.updateLabel.emit({
        reinsurerId: this.currentReinsurer?.id,
        label: this.currentLabel,
      })
    }
    this.isLabelDirty = true
  }

  getNextAvailableSeq(): number {
    const unlabeledVersions = this.reinsurerByName
      .filter(r =>
        r.reinsurerPhaseLabel?.startsWith(`${DEFAULT_VERSION_NAME} `)
      )
      .map(r => {
        let numStr = r.reinsurerPhaseLabel
          .slice(`${DEFAULT_VERSION_NAME} `.length)
          .trim()
        return isNaN(Number(numStr)) ? 0 : Number(numStr)
      })

    return unlabeledVersions && unlabeledVersions.length > 0
      ? Math.max(...unlabeledVersions) + 1
      : 1
  }

  onAssignedLinesClick() {
    if (!this.isDisabled && !this.isDeclined) {
      // tslint:disable-next-line: no-non-null-assertion
      this.assignedLinesClick.emit({ id: this.currentReinsurer?.id! })
    }
  }

  getPreferredChecked() {
    if (this.currentReinsurer) {
      return this.currentReinsurer.isPreferred
    }
    return false
  }

  onPreferredCheckboxChanged() {
    if (this.currentReinsurer) {
      const re1: QuoteReinsurer = {
        ...this.currentReinsurer,
        isPreferred: true,
      }
      this.preferredClick.emit({
        reinsurer: re1,
        isChange: !this.currentReinsurer.isPreferred,
      })
    }
  }

  checkPremiumAndRateRules(): void {
    const re = this.currentReinsurer
    let passed = true
    const {
      layerClass,
      layerCategory,
      quoteRateOnLineSubject,
      quoteCedingCommission,
      slidingComm,
      quotePmpm,
      quoteRolPercentage,
      quotePremium,
    } = re.quoteFields
    const { sage_layer_type } = this.layer.layer.meta_data
    const hasLayerClass = !!layerClass
    const isQuotaShare = sage_layer_type.toLocaleLowerCase().includes('qs')
    const isProperty = hasLayerClass ? layerClass.includes('Property') : false
    const isCasualty = hasLayerClass ? layerClass.includes('Casualty') : false
    const isSpecialty = hasLayerClass ? layerClass.includes('Specialty') : false
    const isAHL = layerCategory === 'AHL'
    const missingPMPM = !quotePmpm || quotePmpm === 0
    const missingQuoteCedingCommission =
      !quoteCedingCommission || quoteCedingCommission === 0
    const missingROL = !quoteRolPercentage || quoteRolPercentage === 0
    const missingRateOnLineSubject =
      !quoteRateOnLineSubject || quoteRateOnLineSubject === 0
    if (!quotePremium || quotePremium.value === 0) {
      passed = false
    }
    if (isQuotaShare && missingQuoteCedingCommission && !slidingComm) {
      passed = false
    }
    if (isAHL && missingPMPM) {
      passed = false
    }
    if (isProperty && missingROL) {
      passed = false
    }
    if ((isCasualty || isSpecialty) && missingRateOnLineSubject) {
      passed = false
    }
    if (passed) {
      this.save()
    } else {
      this.dialog
        .open(QuoteConfirmationDialogComponent, {
          data: {
            checkPremiumAndRateRules: true,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result === 'save') {
            this.save()
          }
        })
    }
  }
}
