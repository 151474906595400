import { createAction, props } from '@ngrx/store'
import { Section } from '../../models/quote.model'
import { ApplicationError } from 'src/app/error/model/error'

export const exportToggleSection = createAction(
  '[Quote Section] Export Toggle',
  props<{ sections: Section[] }>()
)

export const saveQuoteSection = createAction(
  '[Quote Section] Save',
  props<{ section: Section }>()
)

export const saveQuoteSectionSuccess = createAction(
  '[Quote Section] Save Success',
  props<{ section: Section }>()
)

export const saveQuoteSectionFailure = createAction(
  '[Quote Section] Save Failure',
  props<{ error: ApplicationError }>()
)
