<div class="name">
  <h3
    matTooltip="{{ name }}"
    [matTooltipDisabled]="name.length < 40"
    matTooltipPosition="above"
    class="line-clamp"
  >
    {{ name }}
  </h3>
</div>
<app-loading-item
  [appWheelScrollSpeed]="45"
  cdkScrollable
  class="loading"
  [item]="viewMetrics$ | async"
  [loading]="viewDetailMetricsLoading$ | async"
  [error]="viewDetailMetricsError$ | async"
>
  <div class="weight" [ngClass]="metricsWeight ? '' : 'gross-weight'">
    <h3 *ngIf="metricsWeight == 0">{{ metricsWeight }}</h3>
    <h3 *ngIf="isNumber(metricsWeight) && metricsWeight != 0">
      {{ metricsWeight | number: '1.1-1' }}
    </h3>
    <h3 *ngIf="!metricsWeight">0</h3>
  </div>
  <app-compare-metric-category
    *ngFor="let c of metricCategories; let i = index; trackBy: trackByID"
    class="value"
    [entitiesLength]="entitiesLength"
    [label]="c.category"
    [precision]="precision"
    [values]="getMetrics(c.metrics)"
    [collapsed]="!isExpanded(c.category)"
    [hiddenMetricRanks]="hiddenMetricRanks"
    [expandedChangeMetrics]="expandedChangeMetrics"
    [firstEntity]="firstEntity"
    [hasPortfolioTypes]="c.hasPortfolioTypes"
    [rankMax]="rankMax"
    [size]="size"
    [structureIndex]="structureIndex"
  ></app-compare-metric-category>
  <app-loading-item
    class="rp-loading"
    [item]="viewMetrics$ | async"
    [loading]="viewMetricsLoading$ | async"
    [error]="viewMetricsError$ | async"
  >
  </app-loading-item>

  <app-compare-metric-category
    class="rss"
    collapsed
    [precision]="precision"
    size="gigantic"
  ></app-compare-metric-category>
</app-loading-item>
