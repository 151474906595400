import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { clone } from 'ramda'
import { CompareMetricTableCategory } from '../../analysis/model/compare-metrics.model'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import {
  AGENCY_MARKET_USE,
  FUND_MANAGER_MARKET_USE,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { containsObo } from '@shared/util/string'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-banner',
  styleUrls: ['./reinsurer-banner.component.scss'],
  templateUrl: './reinsurer-banner.component.html',
})
export class ReinsurerBannerComponent {
  fundManagerMarketUse = FUND_MANAGER_MARKET_USE
  agencyMarketUse = AGENCY_MARKET_USE
  @Input() reinsurer: Reinsurer
  @Input() reinsurersBlob: BlobResponse | null
  @Input() programs: Study[]
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() metricDetails: CompareMetricTableCategory[]
  // @Input() imageURI: string
  @Input() programID: string
  @Input() duplicateReinsurers: string[]
  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() showFundManagerModal = new EventEmitter<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()

  get financialInfo(): Reinsurer {
    
    if (
      !this.reinsurer.reinsurerProgramFactor[0].co_broker
        ?.toString()
        .replace(/\s/g, '') &&
      !this.reinsurer.reinsurerProgramFactor[0].co_broker_ref
        ?.toString()
        .replace(/\s/g, '')
    ) {
      let correspondentBroker = ''
      const co_broker_ref =
        this.reinsurer.reinsurerProgramFactor[0].co_broker_ref
      let opportunityData: AccountOpportunity[] | null = []
      const selectedProgram = this.getSelectedProgram()
    
      if (selectedProgram){
        opportunityData = this.accountOpportunities.filter(
          x =>
            JSON.parse(JSON.stringify(x.oppId)) ===
          selectedProgram.opportunity_id
        )
      }

      if (
        typeof opportunityData !== 'undefined' &&
        opportunityData.length > 0
      ) {
        if (
          opportunityData[0].parentBU === 'US' &&
          this.reinsurer.domicile === 'United Kingdom'
        ) {
          correspondentBroker = 'Lockton Re LLP'
        }
        let newReinsurer = this.reinsurer
        const factor = clone(newReinsurer.reinsurerProgramFactor)
        factor[0].co_broker = correspondentBroker
        factor[0].co_broker_ref = co_broker_ref
        newReinsurer = {
          ...newReinsurer,
          reinsurerProgramFactor: factor,
        }
        return newReinsurer
      }
    }
    return this.reinsurer
  }

  get name(): string {
    return (
      this.reinsurer.reinsurerProgramFactor[0].obo_name ?? this.reinsurer.name
    )
  }

  get subHeader(): string | null | undefined {
    if(this.duplicateReinsurers.includes(this.name)) {
      return this.reinsurer?.internalNarr || this.reinsurer?.domicile
    }
  }

  getSelectedProgram(): Study | undefined{
    return this.programs.find(p => p.id === this.programID)
  }

  onFundManagerModalMenuClick(): void {
    let select = this.reinsurer.reinsurerProgramFactor[0].incumbent ? 'inc' : ''
    select = this.reinsurer.reinsurerProgramFactor[0].target_market
      ? 'tm'
      : select

    this.showFundManagerModal.emit({
      reinsurer: this.reinsurer,
      programID: this.programID,
      select,
    })
  }

  // LKTN-12949 May need, currently waiting on user input
  getReinsurerName(reinsurer: Reinsurer): string {
    if (reinsurer.market_use === FUND_MANAGER_MARKET_USE) {
      if (
        reinsurer.selectedSegregatedAccounts &&
        reinsurer.selectedSegregatedAccounts.length > 0
      ) {
        const oboDetected =
          containsObo(reinsurer.name) ||
          containsObo(reinsurer.selectedSegregatedAccounts[0].agencyName)
        return `${reinsurer.selectedSegregatedAccounts[0].agencyName}${
          !oboDetected ? ` obo ${reinsurer.name}` : ''
        }`
      } else {
        return reinsurer.name + ' in Respect of Segregated Account'
      }
    } else {
      return (
        this.reinsurer.reinsurerProgramFactor[0].obo_name ?? this.reinsurer.name
      )
    }
  }

  checkCompanyPapers(): boolean {
    if (
      (this.reinsurer.reinsurerProgramFactor[0].incumbent ||
        this.reinsurer.reinsurerProgramFactor[0].target_market) &&
      this.reinsurer.market_use === FUND_MANAGER_MARKET_USE
    ) {
      return true
    } else {
      return false
    }
  }
}
