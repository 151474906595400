import { selectCurrentCreditGroupMembers } from './../../credit/store/group/credit-group.selectors'
import {
  CreditStructureGroup,
  CreditStructureGroupEntity,
  CreditStructureGroupMemberEntity,
} from './../../credit/model/credit-structure-group.model'
import { CreditRoutes } from '../../credit/model/credit-routes.model'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map, shareReplay, skip, take } from 'rxjs/operators'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import { ClientYear } from '../../core/model/client.model'
import { Program } from '../../core/model/program.model'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import {
  TriggerPositionDialogComponent,
  TriggerPositionDialogOptions,
} from '@shared/trigger-position-dialog.component'
import { TierPath } from '../tier.model'
import { NavService } from '../../nav.service'
import { toArray } from '@shared/util/operators'
import { Study } from '../../core/model/study.model'
import { CompareView } from '../../analysis/model/compare-view.model'
import { FeatureFlag, StudyResponse } from '../../api/model/backend.model'
import { ImportLossSetsClickEvent } from '../../core/store/program/program.actions'
import { CreditStructure } from '../../credit/model/credit-structure.model'
import { AppState } from '../../core/store'
import * as fromCreditActions from '../../credit/store/credit.actions'
import * as fromCreditGroupActions from '../../credit/store/group/credit-group.actions'
import * as fromCreditCompareActions from '../../credit/store/compare/credit-compare.actions'
import { updateSelectedCompareView } from 'src/app/analysis/store/compare/compare-view/compare-view.actions'
export interface TierStructuresDialogConfig
  extends TriggerPositionDialogOptions {
  panelClass?: string
  analysisProfileID: string | null
  yearID: string | null
  years?: ClientYear[]
  years2?: ClientYear[]
  structureGroups?: ProgramGroup[]
  structures?: Program[]
  structures2?: Program[]
  selectedYearID?: string | null
  selectedYearID2?: string | null
  selectedClientID?: string | null
  selectedClientID2?: string | null
  selectedStructureGroupIDs?: string[]
  selectedStructureIDs?: string[]
  selectedStructures?: Program[]
  allowScenarioOrOptimizationSelection?: boolean
  contextPath?: string[]
  structureFilter: string | null
  structureGroupMembers: ProgramGroupMember[]
  groupFilterByAssoc: boolean
  studyID: string | null | undefined
  studyID2: string | null | undefined
  showDialogClone: boolean
  programs: Study[]
  programs2: Study[]
  compareViews?: CompareView[]
  selectedCompareView?: CompareView
  studies: StudyResponse[]
  featureFlags: FeatureFlag[]
  routerUrl: string
  selectedCreditGroup?: CreditStructureGroupEntity
  selectedCreditGroupMembers?: CreditStructureGroupMemberEntity[]
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tier-structures-dialog',
  templateUrl: './tier-structures-dialog.component.html',
  styles: [
    `
      app-tier-structures {
        background: var(--bg-1);
      }
    `,
  ],
})
export class TierStructuresDialogComponent
  extends TriggerPositionDialogComponent
  implements OnDestroy
{
  panelClass: string | undefined
  analysisProfileID: string | null
  yearID: string | null
  years?: ClientYear[]
  years2?: ClientYear[]
  structureGroups?: ProgramGroup[]
  structures?: Program[]
  structures2?: Program[]
  selectedYearID?: string | null
  selectedYearID2?: string | null
  selectedClientID?: string | null
  selectedClientID2?: string | null
  selectedStructureGroupIDs?: string[]
  selectedStructureIDs?: string[]
  selectedStructures?: Program[]
  allowScenarioOrOptimizationSelection?: boolean
  contextPath?: string[]
  structureFilter: string | null
  structureGroupMembers: ProgramGroupMember[]
  groupFilterByAssoc: boolean
  studyID: string | null | undefined
  studyID2: string | null | undefined
  showDialogClone: boolean
  programs: Study[]
  programs2: Study[]
  compareViews?: CompareView[]
  selectedCompareView?: CompareView
  studies: StudyResponse[]
  featureFlags: FeatureFlag[]
  routerUrl: string
  selectedCreditGroup?: CreditStructureGroupEntity
  selectedCreditGroupMembers?: CreditStructureGroupMemberEntity[]

  dialog = 'dialog'
  private _importLossSetsClick$: BehaviorSubject<ImportLossSetsClickEvent | null>
  importLossSetsClick$: Observable<ImportLossSetsClickEvent>

  private _structureGroupSelectionChange$: BehaviorSubject<ProgramGroup | null>
  structureGroupSelectionChange$: Observable<ProgramGroup>

  private _structureSelectionChange$: BehaviorSubject<Program | null>
  structureSelectionChange$: Observable<Program>

  private _compareViewSelectionChange$: BehaviorSubject<CompareView | null>
  compareViewSelectionChange$: Observable<CompareView>

  private _scenarioOrOptimizationSelectChange$: BehaviorSubject<CheckboxSelectChangeEvent<Program> | null>
  scenarioOrOptimizationSelectChange$: Observable<
    CheckboxSelectChangeEvent<Program>
  >

  private _structureFilterChange$: BehaviorSubject<string | null>
  structureFilterChange$: Observable<string | null>

  private _groupFilterByAssocChange$: BehaviorSubject<boolean | null>
  groupFilterByAssocChange$: Observable<boolean>
  // isCloneDialog: boolean

  get contextPathList(): string[] {
    return !this.contextPath ? [] : toArray(this.contextPath)
  }

  constructor(
    protected dialogRef: MatDialogRef<TierStructuresDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TierStructuresDialogConfig,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    private nav: NavService,
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {
    super(dialogRef, elementRef, renderer)

    this.studyID = data.studyID
    this.showDialogClone = data.showDialogClone
    this.isCloneDialog = data.showDialogClone
    this.studyID2 = data.studyID2
    this.programs = data.programs
    this.programs2 = data.programs2
    this.triggerRef = data.triggerRef
    this.topOffset = data.topOffset
    this.leftOffset = data.leftOffset
    this.externalClickIgnoreClasses = [
      'app-bar-panel',
      'app-tier-select-backdrop',
      'app-dialog-invisible',
      'app-checkbox-select-backdrop',
      'clone-dialog',
      'swap-loss-sets-dialog',
      'client',
    ]
    this._importLossSetsClick$ = new BehaviorSubject(null)
    this.importLossSetsClick$ = this._importLossSetsClick$.asObservable().pipe(
      skip(1),
      filter(value => value != null),
      // tslint:disable-next-line: no-non-null-assertion
      map(value => value!),
      shareReplay(1)
    )

    this._structureGroupSelectionChange$ = new BehaviorSubject(null)
    this.structureGroupSelectionChange$ = this._structureGroupSelectionChange$
      .asObservable()
      .pipe(
        skip(1),
        filter(value => value != null),
        // tslint:disable-next-line: no-non-null-assertion
        map(value => value!),
        shareReplay(1)
      )

    this._structureSelectionChange$ = new BehaviorSubject(null)
    this.structureSelectionChange$ = this._structureSelectionChange$
      .asObservable()
      .pipe(
        skip(1),
        filter(value => value != null),
        // tslint:disable-next-line: no-non-null-assertion
        map(value => value!),
        shareReplay(1)
      )

    this._compareViewSelectionChange$ = new BehaviorSubject(null)
    this.compareViewSelectionChange$ = this._compareViewSelectionChange$
      .asObservable()
      .pipe(
        skip(1),
        filter(value => value != null),
        // tslint:disable-next-line: no-non-null-assertion
        map(value => value!),
        shareReplay(1)
      )

    this._scenarioOrOptimizationSelectChange$ = new BehaviorSubject(null)
    this.scenarioOrOptimizationSelectChange$ =
      this._scenarioOrOptimizationSelectChange$.asObservable().pipe(
        skip(1),
        filter(value => value != null),
        // tslint:disable-next-line: no-non-null-assertion
        map(value => value!),
        shareReplay(1)
      )

    this._structureFilterChange$ = new BehaviorSubject(null)
    this.structureFilterChange$ = this._structureFilterChange$
      .asObservable()
      .pipe(
        skip(1),
        filter(value => value != null)
      )

    this._groupFilterByAssocChange$ = new BehaviorSubject(null)
    this.groupFilterByAssocChange$ = this._groupFilterByAssocChange$
      .asObservable()
      .pipe(
        skip(1),
        filter(value => value !== null),
        // tslint:disable-next-line: no-non-null-assertion
        map(value => value!)
      )
    this.setData(data)
  }

  ngOnDestroy() {
    this._structureGroupSelectionChange$.complete()
    this._structureSelectionChange$.complete()
    this._compareViewSelectionChange$.complete()
    this._scenarioOrOptimizationSelectChange$.complete()
    this._structureFilterChange$.complete()
    this._groupFilterByAssocChange$.complete()
    if (this.routerUrl?.includes(CreditRoutes.Credit)) {
      return
    }
    if (this.selectedClientID && this.selectedYearID) {
      const path: TierPath = {
        client: this.selectedClientID,
        year: this.selectedYearID,
        program: null,
        structure: null,
      }
      this.nav.navigateWithTierPath(path, ...this.contextPathList)
    }
  }

  setData(data: TierStructuresDialogConfig): void {
    this.panelClass = data.panelClass
      ? `.${data.panelClass}`
      : '.mat-mdc-dialog-container'

    this.analysisProfileID = data.analysisProfileID
    this.yearID = data.yearID
    this.years = data.years
    this.years2 = data.years2
    this.structureGroups = data.structureGroups
    this.structures = data.structures
    this.structures2 = data.structures2
    this.selectedYearID = data.selectedYearID
    this.selectedClientID = data.selectedClientID
    this.selectedClientID2 = data.selectedClientID2
    this.selectedStructureGroupIDs = data.selectedStructureGroupIDs
    this.selectedStructureIDs = data.selectedStructureIDs
    this.selectedStructures = data.selectedStructures
    this.allowScenarioOrOptimizationSelection =
      data.allowScenarioOrOptimizationSelection
    this.contextPath = data.contextPath
    this.structureFilter = data.structureFilter
    this.structureGroupMembers = data.structureGroupMembers
    this.groupFilterByAssoc = data.groupFilterByAssoc
    this.studyID = data.studyID
    this.studyID2 = data.studyID2
    this.showDialogClone = data.showDialogClone
    this.compareViews = data.compareViews
    this.selectedCompareView = data.selectedCompareView
    this.studies = data.studies
    this.featureFlags = data.featureFlags
    this.routerUrl = data.routerUrl
    this.selectedCreditGroup = data.selectedCreditGroup
    this.selectedCreditGroupMembers = data.selectedCreditGroupMembers
    this.cdRef.markForCheck()
  }
  onImportLossSetsClick($event: ImportLossSetsClickEvent) {
    this._importLossSetsClick$.next($event)
  }
  onStructureGroupSelectionChange($event: ProgramGroup): void {
    this._structureGroupSelectionChange$.next($event)
  }

  onStructureSelectionChange($event: Program): void {
    this._structureSelectionChange$.next($event)
  }

  onCompareViewSelectionChange($event: CompareView): void {
    const compareView =
      this.selectedCompareView?.id === $event?.id ? null : $event
    this.store.dispatch(updateSelectedCompareView({ compareView }))
    this.selectedCompareView = compareView
    this.cdRef.markForCheck()
  }

  onScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<Program>
  ): void {
    this._scenarioOrOptimizationSelectChange$.next($event)
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onStructureFilterChange(value: string | null): void {
    this._structureFilterChange$.next(value)
  }

  onGroupFilterByAssoc(value: boolean) {
    this._groupFilterByAssocChange$.next(value)
  }
}
