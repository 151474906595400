<div class="wrapper">
  <div class="title-container">
    <div class="title-left">
      <mat-form-field class="export-label-input">
        <mat-label>Export Label</mat-label>
        <input
          matInput
          type="text"
          class="export-name-input"
          [(ngModel)]="exportName"
        />
      </mat-form-field>
    </div>
    <div class="title-right">
      <div class="button-container" [matTooltip]="disabledTooltip">
        <button
          appButton
          big
          accent
          [disabled]="!activeIds || !activeIds.length || !exportName"
          (click)="initExport()"
        >
          <span>Export</span>
        </button>
      </div>
      <button appButtonIcon class="closing" (click)="destroyDialog()">
        close
      </button>
    </div>
  </div>
  <div class="content">
    <div class="data-selection">
      <app-pricing-curve-export-form
        #details
        [pricingCurves]="pricingCurves$ | async"
        (selectionChanged)="updateExportGraph($event)"
      ></app-pricing-curve-export-form>
    </div>
    <div class="graph-preview">
      <div class="graph-container">
        <app-pricing-curve-graph
          #graph
          [pricingCurves]="pricingCurves$ | async"
          [graphSettings]="graphSettings$ | async"
          [status]="status$ | async"
          [graphSettings]="graphSettings$ | async"
          [activeIds]="activeIds"
          [isExport]="true"
        ></app-pricing-curve-graph>
      </div>
    </div>
  </div>
</div>
