import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { concatMapWithInput, rejectErrorWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import * as fromPortfolioViewActions from '../views/portfolio-view.actions'
import { fetchVolatilityMetrics } from '../metrics/volatility-metrics.actions'
import { selectCurrentAnalysisProfile } from '../../../core/store/broker/broker.selectors'
import {
  selectCompareCurrency,
  selectCurrentCurrency,
  selectGrouperProgramEntities,
} from '../analysis.selectors'
import {
  LogicalPortfolioLayer,
  PhysicalPortfolioLayer,
} from 'src/app/api/analyzere/analyzere.model'
import { of } from 'rxjs'

@Injectable()
export class PortfolioViewEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioViewActions.fetchPortfolioView),
      map(({ type, ...props }) => props),
      concatMapWithInput(props =>
        this.service.fetchPortfolio(props.cededPortfolioID)
      ),
      rejectErrorWithInput((error, props) =>
        fromPortfolioViewActions.fetchPortfolioViewFailure({
          ...props,
          error,
        })
      ),
      withLatestFrom(
        this.store.pipe(select(selectCurrentAnalysisProfile)),
        this.store.pipe(select(selectCurrentCurrency)),
        this.store.pipe(select(selectGrouperProgramEntities)),
        this.store.pipe(select(selectCompareCurrency))
      ),
      concatMapWithInput(
        ([
          [cededPortfolio, props],
          analysisProfile,
          currentCurrency,
          programEntities,
          compareCurrency,
        ]) => {
          // target_currency for POST portfolio_views call rules:
          // 1. Design Page, use editor state current currency (taken from ceded portfolio)
          // 2. Group Page -> Groups, use analysis profile base_currency
          // 3. Group Page -> Structures, use ceded portfolio currency
          // 4. Compare Page -> use analysis profile base_currency

          // TODO: Clean up this effect to remove duplicate code

          let cededLayer: LogicalPortfolioLayer
          let cededCurrency
          let currency

          // If Shared Limit layer, we need to get the original Ceded Layer
          // to determine the original Ceded Currency
          if (
            (cededPortfolio.layers[0] as LogicalPortfolioLayer).meta_data
              .backAllocatedForID
          ) {
            // tslint:disable-next-line: no-non-null-assertion
            const originalCededLayerID = (
              cededPortfolio.layers[0] as LogicalPortfolioLayer
            ).meta_data.backAllocatedForID!
            return this.service
              .fetchLayer<LogicalPortfolioLayer>(originalCededLayerID)
              .pipe(
                concatMap(res => {
                  if (res.error) {
                    return of({ error: res.error })
                  } else {
                    // tslint:disable-next-line: no-non-null-assertion
                    cededLayer = res.data!

                    cededCurrency = (cededLayer.sink as PhysicalPortfolioLayer)
                      .franchise.currency

                    currency = props.isGroupCompare
                      ? // tslint:disable-next-line: no-non-null-assertion
                        analysisProfile!.exchange_rate_profile
                          .exchange_rate_table.base_currency
                      : props.isDesign
                      ? // tslint:disable-next-line: no-non-null-assertion
                        currentCurrency!
                      : cededCurrency
                    if (!props.isDesign && !props.isGroupCompare) {
                      if (props.currency) {
                        currency = props.currency
                      } else {
                        if (
                          programEntities.length > 0 &&
                          programEntities[programEntities.length - 1].program
                            .structureCurrency
                        ) {
                          currency =
                            programEntities[programEntities.length - 1].program
                              .structureCurrency
                        }
                      }
                    }
                    if (props.isGroupCompare) {
                      if (compareCurrency && compareCurrency !== 'Default') {
                        currency = compareCurrency
                      } else {
                        currency = props.currency ? props.currency : currency
                      }
                    }
                    return this.service.postPortfolioView(
                      props.cededPortfolioID,
                      props.grossPortfolioID,
                      props.netPortfolioID,
                      props.analysisProfileID,
                      currency
                    )
                  }
                })
              )
          } else {
            cededLayer = cededPortfolio.layers[0] as LogicalPortfolioLayer
            const cededSink = cededLayer.sink as PhysicalPortfolioLayer

            // A Generic layer will have a franchise, but a QuotaShare will have an event_limit and premuin is common for ILW.
            cededCurrency = (
              cededSink.event_limit ??
              cededSink.franchise ??
              cededSink.premium
            ).currency

            currency = props.isGroupCompare
              ? // tslint:disable-next-line: no-non-null-assertion
                analysisProfile!.exchange_rate_profile.exchange_rate_table
                  .base_currency
              : props.isDesign
              ? // tslint:disable-next-line: no-non-null-assertion
                currentCurrency!
              : cededCurrency
            if (!props.isDesign && !props.isGroupCompare) {
              if (props.currency) {
                currency = props.currency
              } else {
                if (
                  programEntities.length > 0 &&
                  programEntities[programEntities.length - 1].program
                    .structureCurrency
                ) {
                  currency =
                    programEntities[programEntities.length - 1].program
                      .structureCurrency
                }
              }
            }
            if (props.isGroupCompare) {
              if (compareCurrency && compareCurrency !== 'Default') {
                currency = compareCurrency
              } else {
                currency = props.currency ? props.currency : currency
              }
            }
            return this.service.postPortfolioView(
              props.cededPortfolioID,
              props.grossPortfolioID,
              props.netPortfolioID,
              props.analysisProfileID,
              currency
            )
          }
        }
      ),
      rejectErrorWithInput((error, [[_, props]]) =>
        this.store.dispatch(
          fromPortfolioViewActions.fetchPortfolioViewFailure({
            ...props,
            error,
          })
        )
      ),
      concatMap(([result, [[_, props]]]) => {
        const actions = []

        actions.push(
          fromPortfolioViewActions.fetchPortfolioViewSuccess({
            ...props,
            cededPortfolioViewID: result.cededPortfolioView.id,
            grossPortfolioViewID: result.grossPortfolioView.id,
            netPortfolioViewID: result.netPortfolioView.id,
          }),
          fetchVolatilityMetrics({
            ...props,
            cededPortfolioViewID: result.cededPortfolioView.id,
            grossPortfolioViewID: result.grossPortfolioView.id,
            netPortfolioViewID: result.netPortfolioView.id,
          })
        )
        return actions
      })
    )
  })

  fetchLayersViews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioViewActions.fetchCededLayersViews),
      map(({ type, ...props }) => props),
      withLatestFrom(
        this.store.pipe(select(selectCurrentCurrency)),
        this.store.pipe(select(selectCurrentAnalysisProfile))
      ),
      concatMapWithInput(([props, designCurrency, analysisProfile]) => {
        // In Design, always use the editor state currentCurrency
        // Instead of using the Analysis Profile base currency (for SL) we are now sending the layercurriences selected for that layer
        let currency: string[] | undefined = []
        if (props.isDesign && props.layerCurrencies) {
          currency = props.layerCurrencies
        } else if (designCurrency) {
          currency = [designCurrency]
        } else if (props.isGroup && analysisProfile) {
          if (props.layerCurrencies) {
            currency = props.layerCurrencies.map(l =>
              l
                ? l
                : analysisProfile.exchange_rate_profile.exchange_rate_table
                    .base_currency
            )
          } else {
            currency = [
              analysisProfile.exchange_rate_profile.exchange_rate_table
                .base_currency,
            ]
          }
        } else {
          currency = undefined
        }
        return this.service.postLayersViews(
          props.layerIDs,
          props.analysisProfileID,
          currency
        )
      }),
      rejectErrorWithInput((error, [props]) =>
        this.store.dispatch(
          fromPortfolioViewActions.fetchCededLayersViewsFailure({
            ...props,
            error,
          })
        )
      ),
      map(([views, [props]]) => {
        const viewRecord = views.reduce((acc, current) => {
          acc[current.layerID] = current.id
          return acc
        }, {} as Record<string, string>)
        return fromPortfolioViewActions.fetchCededLayersViewsSuccess({
          ...props,
          views: viewRecord,
        })
      })
    )
  })
}
