<button
  *ngIf="!open; else openTemplate"
  appButton
  big
  translucent
  [disabled]="dirty"
  (click)="!dirty && openNewClick.emit()"
>
  <mat-icon inline>add</mat-icon>
  <span>Add Inurance...</span>
</button>
<ng-template #openTemplate>
  <div>
    <div class="title-wrapper">
      <h2 *ngIf="mode === 'new'">Define Inurance</h2>
      <h2 *ngIf="mode === 'delete'">Inurance</h2>
      <div class="subtitle">
        <span>{{ inuranceDisplayHelper(source, target) }}</span>
      </div>
    </div>
    <app-ribbon-card
      class="card source"
      source
      [symbol]="symbol"
      [buttonText]="!source ? 'Select Source' : undefined"
      [title]="source?.title"
      [subtitle]="source?.subtitle"
      [active]="source !== null"
      [selected]="cursor === 'source'"
      (click)="terminalClick.emit('source')"
    ></app-ribbon-card>
    <app-ribbon-card
      class="card target"
      target
      [symbol]="symbol"
      [buttonText]="!target ? 'Select Target' : undefined"
      [title]="target?.title"
      [subtitle]="target?.subtitle"
      [active]="target !== null"
      [selected]="cursor === 'target'"
      (click)="terminalClick.emit('target')"
    ></app-ribbon-card>
    <ng-container *ngIf="!activeAction; else actionRunning">
      <button
        *ngIf="mode === 'new'"
        appButton
        accent
        big
        border
        [disabled]="actionDisabled"
        (click)="saveClick()"
      >
        <span>Save</span>
      </button>
      <button
        *ngIf="mode === 'delete'"
        appButton
        warn
        big
        border
        [disabled]="actionDisabled"
        (click)="deleteClick.emit({ source: source, target: target })"
      >
        <span>Delete</span>
      </button>
      <button appButton link big (click)="cancelClick.emit()">
        <span>Cancel</span>
      </button>
    </ng-container>
    <ng-template #actionRunning>
      <app-active-action [value]="activeAction"></app-active-action>
    </ng-template>
  </div>
  <div>
    <label *ngIf="error" appInfoText warn>{{ error }}</label>
  </div>
  <label class="inurance-info" appInfoText small
  >Inurance Relationships are defined through a source and target of your
    choosing. Layers, Structures and Custom Groups are all options.</label
  >
</ng-template>
