export interface State {
  dark: boolean
  chrome: boolean
  layerWireframe: boolean
  userCard: boolean
  logoColor: boolean
}

export const initialState: State = {
  dark: true,
  chrome: true,
  layerWireframe: true,
  userCard: true,
  logoColor: true,
}
