import { createEntityAdapter, EntityState, Update } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { Section } from '../../models/quote.model'
import { startAnalysis } from '../../../analysis/store/analysis.actions'
import {
  fetchOrSaveQuoteData,
  fetchQuoteSectionByLayerRefAndProgramGroupIDSuccess,
  fetchQuoteSectionByLayerRefAndSharedLimitIDSuccess,
  fetchQuoteSectionByLayerRefSuccess,
  saveQuoteSectionByLayerRefAndProgramGroupIDSuccess,
  saveQuoteSectionByLayerRefAndSharedLimitIDSuccess,
  saveQuoteSectionByLayerRefSuccess,
  startGroupQuote,
  startSLQuote,
} from '../quote.actions'
import {
  exportToggleSection,
  saveQuoteSectionSuccess,
} from '../section/section.actions'

export interface SectionState {
  section: Section
  dirty: boolean
  new: boolean
}

export interface State extends EntityState<SectionState> {
  error: string | null
  loading: boolean
  saving: boolean
}

export const adapter = createEntityAdapter<SectionState>({
  selectId: (sectionState): string => sectionState.section.id,
})

export const initialSectionState = {
  hash: '',
  dirty: false,
  new: false,
}

export const initialState: State = adapter.getInitialState({
  error: null,
  loading: false,
  saving: false,
})

const SectionReducer = createReducer(
  initialState,

  on(fetchOrSaveQuoteData, startGroupQuote, startSLQuote, () => ({
    ...initialState,
  })),

  on(
    fetchQuoteSectionByLayerRefSuccess,
    saveQuoteSectionByLayerRefSuccess,
    fetchQuoteSectionByLayerRefAndProgramGroupIDSuccess,
    saveQuoteSectionByLayerRefAndProgramGroupIDSuccess,
    fetchQuoteSectionByLayerRefAndSharedLimitIDSuccess,
    saveQuoteSectionByLayerRefAndSharedLimitIDSuccess,
    (state, { section }) => {
      const modifiedState = adapter.addOne(
        {
          new: true,
          dirty: true,
          section,
        },
        {
          ...state,
        }
      )
      return modifiedState
    }
  ),

  on(exportToggleSection, (state, { sections }) => {
    const updates: Update<SectionState>[] = sections.map(section => {
      return {
        id: section.id,
        changes: {
          section,
          dirty: true,
        },
      }
    })
    return adapter.updateMany(updates, state)
  }),

  on(saveQuoteSectionSuccess, (state, { section }) => {
    const modifiedState = adapter.updateOne(
      {
        id: section.id,
        changes: {
          section,
          dirty: false,
        },
      },
      {
        ...state,
      }
    )
    return modifiedState
  }),

  on(startAnalysis, state => {
    return adapter.removeAll(state)
  })
)

export function reducer(state: State | undefined, action: Action) {
  return SectionReducer(state, action)
}
