<div #componentRoot class="preview">
  <div class="toolbar">
    <button appButton accent big border (click)="updatePreviewMode.emit()">
      <span>Close Preview</span>
    </button>
    <button appButton accent big border (click)="exportPanels()">
      {{ zipButtonText }}
    </button>
  </div>
  <ng-container *ngIf="!layoutPanels">
    <div class="loading">
      <div class="group-data">
        <h3>Loading group data for:</h3>
        <div *ngFor="let group of summaryExportGroups">
          <div class="group">
            <span class="name">{{ group.name }}:</span>
            <span *ngIf="!group.data" class="loading-text">Loading</span>
            <span *ngIf="group.data" class="loaded-text">Loaded</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="layoutPanels">
    <div
      class="panels"
      [ngStyle]="{
        background: darkMode ? 'black' : 'white',
        color: darkMode ? 'white' : 'black',
      }"
    >
      <div
        *ngFor="let panel of layoutPanels"
        class="panel"
        [ngStyle]="{
          background: darkMode ? 'black' : 'white',
          color: darkMode ? 'white' : 'black',
        }"
      >
        <ng-container *ngIf="panel.panel.type === 'titles'">
          <div
            class="layout-box"
            [ngStyle]="getLayoutStyle('titles', 'topTitle')"
          >
            {{ panel.panel.topTitle }}
          </div>
          <ng-container
            *ngIf="panel.panel.subtitles && panel.panel.subtitles.length > 0"
          >
            <ng-container *ngFor="let subtitle of panel.panel.subtitles">
              <div
                class="layout-box"
                [ngStyle]="getLayoutStyle('titles', 'subtitle')"
              >
                {{ subtitle }}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="panel.panel.type === 'table'">
          <ng-container *ngIf="panel.tableTitle && panel.tableTitle !== ''">
            <div
              class="layout-box"
              [ngStyle]="getLayoutStyle('table', 'tableTitle')"
            >
              {{ panel.panel.chartTitle }}
            </div>
          </ng-container>
          <div class="subheader">
            <span>Explore Gross Results</span>
            <span *ngIf="panel.subheaders && panel.subheaders.length > 0">
              {{ ' - ' }}
              <span
                *ngFor="
                  let value of panel.subheaders;
                  let i = index;
                  let isLast = last
                "
                class="sublist"
              >
                <span
                  [style.color]="getSubheaderColor(i, panel.view.modeling)"
                  >{{ value }}</span
                >
                <span *ngIf="!isLast">{{ '&' }}</span>
              </span>
            </span>
            <!-- <span> in </span>
            <mat-select
              class="header-select"
              [value]="roundedTo"
              (selectionChange)="changeRoundedTo($event.value)"
            >
              <mat-option [value]="'Millions'">
                <span
                  class="header-select-rounded"
                  matTooltip="Show values to the nearest Million"
                  matTooltipPosition="right"
                  >Millions</span
                >
              </mat-option>
              <mat-option [value]="'Thousands'">
                <span
                  class="header-select-rounded"
                  matTooltip="Show values to the nearest Thousand"
                  matTooltipPosition="right"
                  >Thousands</span
                >
              </mat-option>
            </mat-select> -->
          </div>
          <div
            class="table"
            *ngFor="let headers of panel.headers; let tableIndex = index"
          >
            <div class="headers">
              <div
                *ngFor="let header of headers; let i = index"
                [ngStyle]="{
                  'min-width.px': header.width - (header.pinned ? 0 : 16),
                  'max-width.px': header.width - (header.pinned ? 0 : 16),
                }"
                [ngClass]="{
                  'hide-border': header.hideBorder,
                  pinned: header.pinned,
                  groupBy: header.id === 'groupBy',
                  'groupBy-left-0': header.id === 'groupBy' && i === 0,
                }"
                class="header-label"
              >
                <span *ngIf="!header.hideLabel">
                  {{ header.label }}
                </span>
              </div>
            </div>
            <div class="sort-table-container">
              <app-sort-table
                [rows]="panel.groupRows"
                [columnDefs]="panel.defs[tableIndex]"
                [useZeroText]="true"
                [disableSort]="true"
              >
              </app-sort-table>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            panel.panel.type === 'charts' &&
            panel.panel.chartGridOptions &&
            panel.panel.chartGridOptions.length > 0
          "
        >
          <div
            class="charts"
            [ngStyle]="getChartHeight(panel.panel.chartGrid.label)"
          >
            <div
              *ngFor="let chartOption of panel.panel.chartGridOptions"
              [ngStyle]="getLayoutStyle('charts', panel.panel.chartGrid.value)"
              class="chart-option"
            >
              <ng-container [ngSwitch]="chartOption.chart.type">
                <ng-container class="chart-container" *ngSwitchCase="'bubble'">
                  <app-explore-summary-bubble-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                  >
                  </app-explore-summary-bubble-chart>
                </ng-container>
                <ng-container class="chart-container" *ngSwitchCase="'donut'">
                  <app-explore-summary-donut-pie-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [roundedToAbrev]="roundedToAbrev"
                    [currentStructureCurrency]="currentStructureCurrency"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                    [twoWide]="panel.panel.chartGrid.label.includes('2')"
                  ></app-explore-summary-donut-pie-chart>
                </ng-container>
                <ng-container class="chart-container" *ngSwitchCase="'pie'">
                  <app-explore-summary-donut-pie-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [pieChart]="true"
                    [roundedToAbrev]="roundedToAbrev"
                    [currentStructureCurrency]="currentStructureCurrency"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                    [twoWide]="panel.panel.chartGrid.label.includes('2')"
                  ></app-explore-summary-donut-pie-chart>
                </ng-container>
                <ng-container
                  class="chart-container"
                  *ngSwitchCase="'unstackedBar'"
                >
                  <app-explore-summary-group-bar-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [roundedToAbrev]="roundedToAbrev"
                    [currentStructureCurrency]="currentStructureCurrency"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                  ></app-explore-summary-group-bar-chart>
                </ng-container>
                <ng-container
                  class="chart-container"
                  *ngSwitchCase="'stackedBar'"
                >
                  <app-explore-summary-stacked-bar-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                  ></app-explore-summary-stacked-bar-chart>
                </ng-container>
                <ng-container
                  class="chart-container"
                  *ngSwitchCase="'percentStackedBar'"
                >
                  <app-explore-summary-percent-stacked-bar-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                    [twoWide]="panel.panel.chartGrid.label.includes('2')"
                  ></app-explore-summary-percent-stacked-bar-chart>
                </ng-container>
                <ng-container
                  class="chart-container"
                  *ngSwitchCase="'verticalArea'"
                >
                  <app-explore-summary-percent-stacked-area-chart
                    [chartOption]="chartOption"
                    [pdfView]="true"
                    [chartEntityLimit]="chartEntityLimit"
                    [selectableChartsForTab]="selectableChartsForTab"
                    [selectableChartsForMetric]="selectableChartsForMetric"
                    [hideMetrics]="hideMetrics"
                    [lightChartMode]="!darkMode"
                    [zipLoading]="zipLoading"
                    [threeWide]="panel.panel.chartGrid.label.includes('3')"
                    [twoWide]="panel.panel.chartGrid.label.includes('2')"
                  ></app-explore-summary-percent-stacked-area-chart>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
