<label
  *ngIf="lossSetSelectionMode === 'Gross' && !showLossSetGroupEditor"
  appInfoText
  small
  italic
  class="info-text"
>
  Add/Remove Loss Sets from Gross Portfolio
</label>

<label
  *ngIf="lossSetSelectionMode === 'Ceded' && !showLossSetGroupEditor"
  appInfoText
  small
  italic
  class="info-text"
>
  Add/Remove Loss Sets from Selected Ceded Layer
</label>

<div class="parent">
  <button
    appButton
    stack
    primary
    class="child select"
    *ngIf="!showLossSetGroupEditor"
    matTooltip="Select / Deselect All"
    matTooltipPosition="above"
    [class.selected]="isAllSelected(lossSetsList)"
    (click)="updateAllLossSets(lossSetsList, $event)"
  >
    <div class="parent">
      <mat-icon
        class="child"
        *ngIf="isAllSelected(lossSetsList)"
        aria-hidden="false"
        aria-label="check"
      >
        check_box
      </mat-icon>
      <mat-icon
        class="child"
        *ngIf="!isAllSelected(lossSetsList)"
        aria-hidden="false"
        aria-label="check"
      >
        check_box_outline_blank
      </mat-icon>
    </div>
  </button>
  <app-filter-input class="child" transparent (filterChange)="createFilter($event)"></app-filter-input>
</div>
<section class="loss-sets">
  <!-- <mat-slide-toggle
    stack
    *ngFor="let l of layerChoices"
    [checked]="isSelected(l)"
    [matTooltip]="l.description"
  >
    {{ lookupPerilCode(l.meta_data.peril) }}
  </mat-slide-toggle> -->
  <button
    *ngFor="let ls of lossSetsList"
    appButton
    stack
    primary
    [class.selected]="isSelected(ls)"
    (click)="toggleSelected(ls, $event)"
  >
    <div class="parent">
      <mat-icon
        class="child"
        *ngIf="isSelected(ls)"
        aria-hidden="false"
        aria-label="check"
      >
        check_box
      </mat-icon>
      <mat-icon
        class="child"
        *ngIf="!isSelected(ls)"
        aria-hidden="false"
        aria-label="check"
      >
        check_box_outline_blank
      </mat-icon>
      <div class="child">
        <div [matTooltip]="getLabel(ls)" matTooltipPosition="above">
          {{ getLabel(ls) }}
        </div>
      </div>
    </div>
  </button>

  <ng-container *ngIf="selectedLayer">
    {{ selectedLayer.layer.id }}
  </ng-container>
</section>
