import { Action, createReducer, on } from '@ngrx/store'
import { LayerMetrics } from '../../model/layers-metrics.model'
import { fetchCededLayersViews } from '../views/portfolio-view.actions'
import {
  fetchLayersViewMetrics,
  fetchLayersViewMetricsFailure,
  fetchLayersViewMetricsSuccess,
} from './layers-metrics.actions'

export interface LayerMetricsState {
  loading: boolean
  error: string | null
  metrics: LayerMetrics | null
}

export interface State {
  byID: Record<string, LayerMetricsState>
}

export const initialState: State = {
  byID: {},
}

const layersMetricsReducer = createReducer(
  initialState,

  on(fetchCededLayersViews, _ => initialState),

  on(fetchLayersViewMetrics, (state, { layerViewID, lossFilterValue }) => ({
    ...state,
    byID: {
      ...state.byID,
      [updateLayerViewID(layerViewID, lossFilterValue)]: {
        loading: true,
        error: null,
        metrics: null,
      },
    },
  })),

  on(
    fetchLayersViewMetricsFailure,
    (state, { error, layerViewID, lossFilterValue }) => ({
      ...state,
      byID: {
        ...state.byID,
        [updateLayerViewID(layerViewID, lossFilterValue)]: {
          loading: false,
          error: error.message,
          metrics: null,
        },
      },
    })
  ),

  on(
    fetchLayersViewMetricsSuccess,
    (state, { metrics, layerViewID, lossFilterValue }) => ({
      ...state,
      byID: {
        ...state.byID,
        [updateLayerViewID(layerViewID, lossFilterValue)]: {
          loading: false,
          error: null,
          metrics: {
            ...metrics,
            id: updateLayerViewID(layerViewID, lossFilterValue),
          },
        },
      },
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return layersMetricsReducer(state, action)
}

function updateLayerViewID(
  layerViewID: string,
  lossFilter: string | undefined
) {
  let Id = layerViewID
  if (lossFilter) {
    Id = Id + '-filter=' + lossFilter
  }
  return Id
}
