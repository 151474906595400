import { Dictionary } from '@ngrx/entity'
import { createSelector } from '@ngrx/store'
import { Program } from '../../../../core/model/program.model'
import {
  findAllProgramGroupMembersWithID,
  selectProgramGroupSetState,
} from '../../../../core/store/program-group-member.selectors'
import { rejectNil } from '../../../../shared/util/operators'
import { selectGrouperProgramGroupEntities } from '../../analysis.selectors'
import {
  CanHaveScenarios,
  GroupScenariosModel,
  ProgramGroupMember,
  ScenarioSelectModel,
} from '../program-group.model'

const selectEligibleGroups = createSelector(
  selectGrouperProgramGroupEntities,
  groups =>
    groups
      .filter(entity => !entity.deleted && !entity.untitled)
      .map(entity => entity.programGroup)
)

export const selectGrouperScenariosByID = createSelector(
  selectEligibleGroups,
  selectProgramGroupSetState,
  (groups, { groupsByID, structuresByID, allMembers }) =>
    groups.reduce((acc, group) => {
      const { scenarios, index } = getScenarioSelectModel(groupsByID, group)

      const groupScenarios = scenarios.map(scenario => {
        const parentGroupID = scenario.id
        const members =
          findAllProgramGroupMembersWithID({ parentGroupID }, allMembers) ?? []
        const structureScenarios = getStructureScenarios(
          structuresByID,
          members
        )
        return { group: scenario, members, structureScenarios }
      })

      const hasStructureScenarios = groupScenarios[0]?.structureScenarios?.some(
        sm => sm.scenarios.length > 1
      )
      const hasOnlyStructures = groupScenarios[0]?.members?.every(
        m => m.type === 'program'
      )
      const canEditScenarios =
        groupScenarios.length > 1 ||
        (hasStructureScenarios && hasOnlyStructures)

      acc[group.id] = {
        group,
        groupScenarios,
        index,
        canEditScenarios,
        hasStructureScenarios,
        hasOnlyStructures,
      }

      return acc
    }, {} as Record<string, GroupScenariosModel>)
)

const getStructureScenarios = (
  structuresByID: Dictionary<Program>,
  members: ProgramGroupMember[] = []
): ScenarioSelectModel<Program>[] =>
  rejectNil(
    members
      .filter(m => m.type === 'program')
      .map(m => m.programID)
      .map(id => structuresByID[id ?? ''])
  ).map(getScenarioSelectModel(structuresByID))

function getScenarioSelectModel<T extends CanHaveScenarios>(
  structuresByID: Dictionary<T>,
  item: T
): ScenarioSelectModel<T>

function getScenarioSelectModel<T extends CanHaveScenarios>(
  structuresByID: Dictionary<T>
): (item: T) => ScenarioSelectModel<T>

function getScenarioSelectModel<T extends CanHaveScenarios>(
  structuresByID: Dictionary<T>,
  _item?: T | undefined
): ScenarioSelectModel<T> | ((item: T) => ScenarioSelectModel<T>) {
  // Curried function
  const fn = (item: T) => {
    if (item.isScenario && item.parentScenarioID) {
      // This item is a scenario
      const parent = structuresByID[item.parentScenarioID]
      if (parent && parent.scenarioIDs) {
        const children = getScenarios(structuresByID, parent)
        // Find the position of this scenario in its parent structure
        // scenario list and add one, since the original is always
        // returned as the first item in the scenarios list
        const index = parent.scenarioIDs.indexOf(item.id) + 1
        return { item: parent, scenarios: [parent, ...children], index }
      }
    } else if (item.scenarioIDs) {
      // This item has children scenarios
      const children = getScenarios(structuresByID, item)
      return { item, scenarios: [item, ...children], index: 0 }
    }

    // This item is not a scenario and has no children scenarios
    return { item, scenarios: [item], index: 0 }
  }

  return _item ? fn(_item) : fn
}

function getScenarios<T extends CanHaveScenarios>(
  itemsByID: Dictionary<T>,
  item: T
) {
  return rejectNil(item.scenarioIDs?.map(id => itemsByID[id]) ?? [])
}
