import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { Folder } from '../../core/model/study.model'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class FolderService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}
  base = `${environment.internalApi.base}${environment.internalApi.folder}`

  getFolder(folderID: string): ApiResponse<Folder> {
    const url = `${this.base}/${folderID}`
    return this.httpClient
      .get<Folder>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Folder'))
  }

  postFolder(
    name: string,
    programId: string,
    parentId?: string
  ): ApiResponse<Folder> {
    const body = { name, programId, parentId }
    return this.httpClient
      .post<Folder>(this.base, body, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Folder'))
  }

  putFolder(
    folderID: string,
    name: string,
    programId: string,
    parentId?: string
  ): ApiResponse<Folder> {
    const url = `${this.base}/${folderID}`
    const body = { name, programId, parentId }
    return this.httpClient
      .put<Folder>(url, body, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Put Folder'))
  }

  deleteFolder(folderID: string): ApiResponse<string> {
    const url = `${this.base}/${folderID}`
    return this.httpClient
      .delete<string>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Delete Folder'))
  }
}
