import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-placeholder-image',
  styles: [
    `
           div {
             width: 100%;
             height: 100%;
           }
     
           .placeholder-1 {
             background: radial-gradient(
               circle at 30% 107%,
               #fdf09720 0%,
               #fdf59720 5%,
               #49f1fd20 45%,
               #24bdd620 60%,
               #282eeb20 90%
             );
           }
     
           .placeholder-2 {
             background: radial-gradient(
               circle at 30% 107%,
               #fdf49720 0%,
               #fdf49720 5%,
               #fd594920 45%,
               #d6249f20 60%,
               #285aeb20 90%
             );
           }
     
           .placeholder-3 {
             background: radial-gradient(
               circle at 70% 107%,
               #97d7fd20 0%,
               #97d7fd20 5%,
               #49fd8d20 45%,
               #71d62420 60%,
               #ebca2820 90%
             );
           }
     
           .placeholder-4 {
             background: radial-gradient(
               circle at 30% -5%,
               #fde79720 0%,
               #be97fd20 5%,
               #fd7f4920 45%,
               #b2d62420 60%,
               #28eba320 90%
             );
           }
         `,
  ],
  template: `
    <div [ngClass]="placeholderClass"></div>
  `,
})
export class PlaceholderImageComponent {
  @Input() index = 0

  get placeholderClass() {
    return {
      [`placeholder-${(this.index % 4) + 1}`]: true,
    }
  }
}
