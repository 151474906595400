import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { ClientYear } from '../../../core/model/client.model'
import { getPaletteClassByIndex } from '../../../core/model/palette'
import { Program } from '../../../core/model/program.model'
import {
  InuranceTag,
  InuranceTagsByID,
  Terminal,
} from '../../model/inurance.model'
import {
  GroupBar,
  GroupBarEdge,
  GrouperSlide,
  GroupScenariosDialogData,
  GroupScenariosModel,
  ProgramGroup,
  UNTITLED_GROUP_LABEL,
} from '../../store/grouper/program-group.model'
import { AddProgramGroupProps } from '../../store/grouper/program-group/program-group.actions'
import { ProgramGroupEntity } from '../../store/grouper/program-group/program-group.reducer'
import { AddProgramProps } from '../../store/grouper/program/program.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-tower-bar',
  styleUrls: ['./group-tower-bar.component.scss'],
  templateUrl: './group-tower-bar.component.html',
})
export class GroupTowerBarComponent {
  @Input() dirty: boolean
  @Input() groupBar?: GroupBar | null
  @Input() analysisProfileID: string | null
  @Input() slide: GrouperSlide
  @Input() inuranceTagsByProgramGroupID: InuranceTagsByID = {}
  @Input() years: readonly ClientYear[]
  @Input() programsByID: Dictionary<Program>
  @Input() selectedProgramIDs?: readonly string[]
  @Input() programGroups: readonly ProgramGroup[]
  @Input() groups: ProgramGroup[]
  @Input() groupsByID: Dictionary<ProgramGroupEntity>
  @Input() groupScenariosByID: Record<string, GroupScenariosModel>
  @Input() ifLibreGroup: []

  @Input() set selectMode(value: boolean) {
    this._selectMode = coerceBooleanProperty(value)
  }
  get selectMode() {
    return this._selectMode
  }
  @HostBinding('class.select-mode') _selectMode = false

  @Input() set bottomMinimized(value: boolean) {
    this._bottomMinimized = coerceBooleanProperty(value)
  }
  get bottomMinimized() {
    return this._bottomMinimized
  }
  @HostBinding('class.bottom-minimized') _bottomMinimized = false

  @Input() set clickable(value: boolean) {
    this._clickable = coerceBooleanProperty(value)
  }
  get clickable() {
    return this._clickable
  }
  @HostBinding('class.clickable') _clickable = false

  @Input() set allowSelection(value: any) {
    this._allowSelection = coerceBooleanProperty(value)
  }
  get allowSelection() {
    return this._allowSelection
  }
  private _allowSelection: boolean

  @Output() barClick = new EventEmitter<string | undefined>()
  @Output() barMouseover = new EventEmitter<string | undefined>()
  @Output() barMouseout = new EventEmitter<string | undefined>()
  @Output() programGroupAdd = new EventEmitter<AddProgramGroupProps>()
  @Output() programGroupRemove = new EventEmitter<string>()
  @Output() programGroupMinimizeToggle = new EventEmitter<string>()
  @Output() programAdd = new EventEmitter<AddProgramProps>()
  @Output() saveUntitled = new EventEmitter<AddProgramGroupProps>()
  @Output() inuranceTagClick = new EventEmitter<InuranceTag>()
  @Output() animatedScenarios = new EventEmitter<{ groupID: string }>()
  @Output() groupLayerDetails = new EventEmitter<{
    groupID: string
    groupName: string
  }>()
  @Output() groupScenariosClick = new EventEmitter<GroupScenariosDialogData>()
  @Output() programGroupRename = new EventEmitter<string>()

  @HostBinding('class.minimize-bar') get isMinimizeBar(): boolean {
    return (
      this.groupBar != null && this.groupBar.minimized && this.groupBar.first
    )
  }
  @Output() programGroupDelete = new EventEmitter<GroupBar>()

  @HostBinding('class.untitled') get isGroupUntitled(): boolean {
    return (
      this.groupBar != null &&
      this.groupBar.label === UNTITLED_GROUP_LABEL &&
      this.groupBar.untitled
    )
  }

  get label(): string {
    return this.isGroupUntitled
      ? '+ New Group'
      : this.groupBar
      ? this.groupBar.label
      : ''
  }

  get leftEdges(): GroupBarEdge[] {
    return (
      (this.groupBar &&
        this.groupBar.leftEdges.filter(le => le.paletteIndex !== -1)) ||
      []
    )
  }

  get rightEdges(): GroupBarEdge[] {
    return (
      (this.groupBar &&
        this.groupBar.rightEdges.filter(re => re.paletteIndex !== -1)) ||
      []
    )
  }

  get inuranceTags(): InuranceTag[] {
    return (
      (this.groupBar && this.inuranceTagsByProgramGroupID[this.groupBar.id]) ||
      []
    )
  }

  get showLeftSpan(): boolean {
    if (!this.showLabelCenter && !this.showLabelLeft && !this.showMinimize) {
      return false
    }
    return !this.isGroupUntitled || this.showLabelLeft
  }

  get showRightSpan(): boolean {
    if (!this.showRemove && !this.showLabelCenter) {
      return false
    }
    return !this.bottomMinimized && !this.isGroupUntitled
  }

  get showLabelLeft(): boolean {
    return (
      this.groupBar != null &&
      this.groupBar.labelPos === 'left' &&
      !this.groupBar.minimized
    )
  }

  get showLabelCenter(): boolean {
    return (
      this.groupBar != null &&
      this.groupBar.labelPos === 'center' &&
      !this.groupBar.minimized
    )
  }

  get barClasses() {
    if (this.groupBar) {
      return {
        [this.groupBar.labelPos]: true,
        [getPaletteClassByIndex(this.groupBar.paletteIndex)]: true,
        untitled: this.groupBar.paletteIndex === -1,
        'untitled-disabled':
          this.selectMode && this.groupBar.paletteIndex === -1,
        hide:
          this.groupBar.minimized && this.groupBar.last && !this.groupBar.first,
      }
    }
    return { hide: true }
  }

  get showMinimize(): boolean {
    if (this.groupBar == null || this.isGroupUntitled) {
      return false
    }
    return (
      this.groupBar.first && (this.bottomMinimized || !this.groupBar.minimized)
    )
  }

  get showProgramMenu(): boolean {
    return !this.selectMode && !this.isGroupUntitled
  }

  get showMoreMenu(): boolean {
    return !this.selectMode && !this.isGroupUntitled
  }

  get showRemove(): boolean {
    return (
      !this.selectMode &&
      this.groupBar != null &&
      this.groupBar.last &&
      !this.groupBar.minimized
    )
  }

  get minimizeIcon(): string {
    return this.groupBar != null && this.groupBar.minimized
      ? 'crop_square'
      : 'minimize'
  }

  get isPersisted(): boolean {
    return (
      this.groupBar != null &&
      this.groupsByID[this.groupBar.id] != null &&
      !this.groupsByID[this.groupBar.id]?.new
    )
  }

  get groupScenariosModel(): GroupScenariosModel | undefined {
    return this.groupBar ? this.groupScenariosByID[this.groupBar.id] : undefined
  }

  get hasGroupScenarios(): boolean {
    return (this.groupScenariosModel?.groupScenarios.length ?? 0) > 1
  }

  get canEditScenarios(): boolean {
    return this.groupScenariosModel?.canEditScenarios === true
  }

  get showGroupScenarios(): boolean {
    return this.isPersisted && !this.dirty && this.canEditScenarios
  }

  get showGroupBar(): boolean {
    const id = 'id'
    const libre = 'libre'
    const group = this.ifLibreGroup.findIndex(
      grp => grp[id] === this.groupBar?.id
    )
    return !(
      this.ifLibreGroup[group][libre] !== 'Y' ||
      !this.ifLibreGroup[group][libre]
    )
  }
  getProgram(id: string | number): Program {
    const program = this.programsByID[id]
    if (!program) {
      throw Error(`Cannot show program menu, none found for id '${id}'`)
    }
    return program
  }

  isSelected(id: string): boolean {
    if (!this.allowSelection || !this.selectedProgramIDs) {
      return false
    }
    return this.selectedProgramIDs.includes(id)
  }

  onProgramClick($event: MouseEvent | TouchEvent, id: string): void {
    $event.preventDefault()
    const program = this.getProgram(id)
    this.onProgramAdd(program)
  }

  calculateWidth(groupBar: GroupBar): number {
    if (groupBar && this.slide !== undefined && this.slide.groupBars !== null) {
      const len = this.slide.groupBars.length - groupBar.paletteIndex - 1
      if (len === 0) {
        return len + 30
      } else {
        return len * 10 + 30
      }
    }
    return 30
  }

  getInuranceTags(terminal: Terminal): InuranceTag[] {
    return this.inuranceTags.filter(v => v.terminal === terminal)
  }

  getEdgeClasses(edge: GroupBarEdge) {
    return {
      [getPaletteClassByIndex(edge.paletteIndex)]: true,
      untitled: edge.paletteIndex === -1,
      minimized: edge.minimized,
    }
  }

  onBarClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    if (this.isGroupUntitled) {
      if (!this.selectMode) {
        return this.saveUntitled.emit({
          id: 'root',
          parentGroupID: this.groupBar ? this.groupBar.id : undefined,
        })
      }
    } else {
      this.barClick.emit(this.groupBar ? this.groupBar.id : undefined)
    }
  }

  onInuranceTagClick($event: MouseEvent | TouchEvent, tag: InuranceTag): void {
    if (!this.selectMode) {
      $event.preventDefault()
      $event.stopPropagation()
      this.inuranceTagClick.emit(tag)
    }
  }

  onProgramGroupMinimizeToggle($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.programGroupMinimizeToggle.emit(
      this.groupBar ? this.groupBar.id : undefined
    )
  }

  onProgramGroupRemove($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.programGroupRemove.emit(this.groupBar ? this.groupBar.id : undefined)
  }

  onProgramAdd(program: Program): void {
    if (!this.groupBar) {
      console.error('Unexpected error, program add on empty bar.')
      return
    }
    // Set the new program's position as to the right of last item in group
    const ordinal = this.groupBar.addRightOrdinal
    const parentGroupID = this.groupBar.id
    this.programAdd.emit({ program, ordinal, parentGroupID })
  }

  onProgramGroupAdd(programGroup: ProgramGroup): void {
    if (!this.groupBar) {
      console.error('Unexpected error, program add on empty bar.')
      return
    }
    // Set the new group's position as to the right of last item in group
    const ordinal = this.groupBar.addRightOrdinal
    const parentGroupID = this.groupBar.id
    this.programGroupAdd.emit({ id: programGroup.id, ordinal, parentGroupID })
  }

  onAnimatedScenarios(): void {
    this.animatedScenarios.emit({ groupID: this.groupBar?.id || '' })
  }

  onLayerDetails(): void {
    this.groupLayerDetails.emit({
      groupID: this.groupBar?.id || '',
      groupName: this.groupBar?.label || '',
    })
  }

  onGroupScenariosClick(): void {
    const model = this.groupBar && this.groupScenariosByID[this.groupBar.id]
    if (model) {
      this.groupScenariosClick.emit({
        ...model,
        groupClass: getPaletteClassByIndex(this.groupBar?.paletteIndex),
      })
    }
  }

  trackByTag(index: number, view: InuranceTag): string | number {
    return (view && `${view.symbol}_${view.terminal}`) || index
  }
}
