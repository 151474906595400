import {inject, Injectable} from '@angular/core'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppState } from 'src/app/core/store'
import { Store, select } from '@ngrx/store'
import * as fromEfficiencyMetricsActions from './efficiency-metrics.actions'
import { withLatestFrom, map, concatMap } from 'rxjs/operators'
import { concatMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import {
  selectViewMetricsState,
  selectCompareMetricSettingsEntities,
} from '../analysis.selectors'
import { calculateEfficiencyMetrics } from './calculations'
import { of, forkJoin } from 'rxjs'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { ApiResponse } from 'src/app/api/model/api.model'
import { Metrics } from 'src/app/api/analyzere/analyzere.model'

@Injectable()
export class EfficiencyMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEfficiencyMetricsActions.fetchEfficiencyMetrics),
      withLatestFrom(
        this.store.pipe(select(selectCurrentClient)),
        this.store.pipe(select(selectCompareMetricSettingsEntities))
      ),
      concatMapWithInput(
        ([
          {
            cededPortfolioID,
            netPortfolioID,
            grossPortfolioID,
            analysisProfileID,
            cededPortfolioViewID,
            netPortfolioViewID,
            grossPortfolioViewID,
          },
          client,
          settings,
        ]) => {
          // @ts-ignore
          const taxRate = client.tax_rate
          // tslint:disable: no-non-null-assertion
          return of(taxRate).pipe(
            withLatestFrom(
              this.store.pipe(
                select(selectViewMetricsState, {
                  portfolioSetID: {
                    cededPortfolioID,
                    netPortfolioID,
                    grossPortfolioID,
                    analysisProfileID,
                  },
                })
              )
            ),
            concatMap(([_prop, metricsState]) => {
              const actions: Array<ApiResponse<Metrics>> = []
              const reinsuranceSetting = settings.filter(
                setting =>
                  setting.category === 'Efficiency Metrics' && setting.year
              )
              if (reinsuranceSetting.length > 0) {
                actions.push(
                  this.service.getTailMetrics(
                    netPortfolioViewID,
                    'AEP',
                    'UW',
                    // tslint:disable-next-line: no-non-null-assertion
                    parseInt(reinsuranceSetting[0].year!, 10),
                    'all'
                  ) as ApiResponse<Metrics>
                )
                actions.push(
                  this.service.getTailMetrics(
                    grossPortfolioViewID,
                    'AEP',
                    'UW',
                    // tslint:disable-next-line: no-non-null-assertion
                    parseInt(reinsuranceSetting[0].year!, 10),
                    'all'
                  ) as ApiResponse<Metrics>
                )
              }
              return forkJoin(actions).pipe(
                map(
                  ([reinsuranceEfficiencyNet, reinsuranceEfficiencyGross]) => {
                    return {
                      taxRate,
                      expectedCededMargin:
                        metricsState.portfolioViewDetailMetrics
                          .cededCalculatedMetrics!.expectedUnderwritingResult,
                      capitalMetrics: metricsState.capitalMetrics,
                      reinsuranceEfficiencyNet: reinsuranceEfficiencyNet.data!,
                      reinsuranceEfficiencyGross:
                        reinsuranceEfficiencyGross.data!,
                      netUW:
                        metricsState.portfolioViewDetailMetrics
                          .netCalculatedMetrics!.expectedUnderwritingResult,
                      grossUW:
                        metricsState.portfolioViewDetailMetrics
                          .grossCalculatedMetrics!.expectedUnderwritingResult,
                    }
                  }
                )
              )
            }),
            concatMapWithInput(_data => {
              return this.service.getEfficiencyVolatility(cededPortfolioViewID)
            }),
            map(response => {
              if (response.error) {
                return { error: response.error }
              } else {
                const [metric, data] = response.data!
                return {
                  data: {
                    taxRate: data.taxRate,
                    expectedCededMargin: data.expectedCededMargin,
                    capitalMetrics: data.capitalMetrics,
                    volatility: Math.sqrt(metric!.variance),
                    reinsuranceEfficiencyNet: data.reinsuranceEfficiencyNet,
                    reinsuranceEfficiencyGross: data.reinsuranceEfficiencyGross,
                    netUW: data.netUW,
                    grossUW: data.grossUW,
                  },
                }
              }
            })
          )
        }
      ),
      rejectErrorWithInput((error, [props]) =>
        this.store.dispatch(
          fromEfficiencyMetricsActions.fetchEfficiencyMetricsFailure({
            error,
            ...props,
          })
        )
      ),
      map(([results, [props]]) => {
        return fromEfficiencyMetricsActions.fetchEfficiencyMetricsSuccess({
          ...calculateEfficiencyMetrics(
            results.taxRate,
            results.capitalMetrics,
            results.volatility,
            results.expectedCededMargin,
            results.reinsuranceEfficiencyNet,
            results.reinsuranceEfficiencyGross,
            results.netUW,
            results.grossUW
          ),
          ...props,
        })
      })
    )
  })
}
