<app-group-tower-bar-edge
  *ngFor="let edge of leftEdges"
  [edge]="edge"
  side="left"
></app-group-tower-bar-edge>

<button
  class="bar"
  [ngClass]="barClasses"
  [ngStyle]="{ 'min-width': calculateWidth(groupBar!) + 'px' }"
  (click)="onBarClick($event)"
  (mouseover)="barMouseover.emit(groupBar?.id)"
  (mouseout)="barMouseout.emit(groupBar?.id)"
>
      <span class="left" *ngIf="showLeftSpan">
        <button
          *ngIf="showMinimize"
          appButtonIcon
          class="icon more"
          (click)="onProgramGroupMinimizeToggle($event)"
        >
          {{ minimizeIcon }}
        </button>

        <span *ngIf="showLabelLeft">
          <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
        </span>
      </span>

  <span *ngIf="showLabelCenter" class="center">
        <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      </span>

  <span class="right" *ngIf="showRightSpan">
        <button
          *ngIf="showRemove"
          appButtonIcon
          class="icon"
          (click)="onProgramGroupRemove($event)"
        >
          close
        </button>
      </span>
</button>

<app-group-tower-bar-edge
  *ngFor="let edge of rightEdges"
  [edge]="edge"
  side="right"
></app-group-tower-bar-edge>

<ng-template #nameTemplate>
  <app-ribbon-tag
    *ngFor="let tag of getInuranceTags('target'); trackBy: trackByTag"
    class="tag"
    target
    [height]="17"
    (click)="onInuranceTagClick($event, tag)"
  >
    {{ tag.symbol }}
  </app-ribbon-tag>
  <label [matTooltip]="label" matTooltipPosition="above">{{ label }}</label>
  <app-ribbon-tag
    *ngFor="let tag of getInuranceTags('source'); trackBy: trackByTag"
    class="tag"
    source
    [height]="17"
    (click)="onInuranceTagClick($event, tag)"
  >
    {{ tag.symbol }}
  </app-ribbon-tag>

  <button
    appButtonIcon
    *ngIf="showMoreMenu"
    class="icon"
    [matMenuTriggerFor]="deleteMenu"
    (click)="$event.stopPropagation()"
  >
    more_horiz
  </button>

  <mat-menu #deleteMenu="matMenu">
    <button
      class="delete-button"
      mat-menu-item
      (click)="programGroupDelete.emit(groupBar!)"
    >
      Delete
    </button>

    <button mat-menu-item (click)="onProgramGroupMinimizeToggle($event)">
      Snap Up
    </button>

    <button mat-menu-item (click)="onProgramGroupRemove($event)">
      Hide
    </button>

    <button
      mat-menu-item
      [disabled]="!showGroupScenarios"
      (click)="onGroupScenariosClick()"
    >
      Group Scenarios
    </button>

    <button
      mat-menu-item
      *ngIf="isPersisted"
      (click)="onAnimatedScenarios()"
    >
      Animated Scenarios
    </button>
    <button
      mat-menu-item
      [disabled]="showGroupBar"
      (click)="onLayerDetails()"
    >
      Layer Details
    </button>

    <button
      mat-menu-item
      [matMenuTriggerFor]="yearMenu"
      [disabled]="hasGroupScenarios"
    >
      Add Structure
    </button>

    <mat-menu #yearMenu="matMenu">
      <ng-container *ngFor="let y of years">
        <button
          mat-menu-item
          class="item"
          [matMenuTriggerFor]="programMenu"
        >
          {{ y.year }}
        </button>

        <mat-menu #programMenu="matMenu">
          <ng-container *ngFor="let s of y.studies">
            <button
              mat-menu-item
              class="item"
              [matMenuTriggerFor]="subMenu"
            >
              {{ s.name }}
            </button>

            <mat-menu #subMenu="matMenu">
              <app-program-menu-item
                *ngFor="let id of s.programIDs; let i = index"
                [program]="getProgram(id)"
                [allowSelection]="allowSelection"
                [selected]="isSelected(id)"
                [index]="i"
                (itemClick)="onProgramClick($event, id)"
              ></app-program-menu-item>
            </mat-menu>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-menu>

    <button
      mat-menu-item
      [matMenuTriggerFor]="groupMenu"
      [disabled]="hasGroupScenarios"
    >
      Add Structure Group
    </button>

    <mat-menu #groupMenu="matMenu">
      <ng-container *ngFor="let pg of programGroups">
        <button mat-menu-item (click)="onProgramGroupAdd(pg)">
          {{ pg.label }}
        </button>
      </ng-container>
    </mat-menu>

    <button mat-menu-item (click)="programGroupRename.emit(groupBar!.id)">
      Rename Group
    </button>
  </mat-menu>
</ng-template>
