import { Fill, FillPattern, FillPatterns, Font } from 'exceljs'
import { LayerViewValues } from 'src/app/analysis/model/layer-view'
import { QuoteCustomCompareView, Section } from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import {
  layerIds,
  LayerPaletteItem,
} from 'src/app/analysis/model/layer-palette.model'
import layerMetricDefs from 'src/app/analysis/model/layer-metric-defs'

export const Fonts: Record<string, Partial<Font>> = {
  Segoe_14_Blue: createFont('Segoe Pro', 14, '00AEEF'),
  Segoe_8: createFont('Segoe Pro', 8),
  Segoe_8_Gray: createFont('Segoe Pro', 8, '5b6670'),
  Segoe_8_White_Bold: createFont('Segoe Pro', 8, 'FFFFFF', true),
  Segoe_8_Black_Bold: createFont('Segoe Pro', 8, '000000', true),
}

export const Fills: Record<string, Fill> = {
  Solid_Gray: createFillPattern('solid', '737F8B'),
  Solid_Blue: createFillPattern('solid', '00AEEF'),
  Solid_Black: createFillPattern('solid', '000000'),
}

export type QuoteExportRowValue = boolean | string | number

export type QuoteVersionListEntry = {
  tpRef: string
  reinsurerPhaseVersion: number
  quoteReinsurerName: string // Not needed, for debug
}

export enum QuoteExportModes {
  DEFAULT = 'default',
  XOL = 'xol',
}

export enum AssignedLinesKeys {
  SIGNED_PERCENT = 'signed%',
  DOLLARS_SIGNED = 'signed$',
  UNDERWRITER_REF = 'underwriterRef',
  DOLLARS_WRITTEN = 'written$',
  PERCENT_WRITTEN = 'written%',
  PERCENT_RECOMMENDED = 'recommended%',
  PLACED_THROUGH = 'placedThrough',
  CO_BROKER = 'coBroker',
  LEAD_MARKET = 'leadMarket',
  BROKERAGE = 'brokerage',
  BROKERAGE_RE = 'brokerageRe',
  NAME = 'name',
}

export const AssignedLinesTextMappings: Record<AssignedLinesKeys, string> = {
  underwriterRef: 'Underwriter Ref #',
  written$: '$ Written',
  'written%': 'Written %',
  signed$: '$ Signed',
  'signed%': 'Signed %',
  'recommended%': 'Recommended %',
  placedThrough: 'Placed Through',
  coBroker: 'Correspondent Broker',
  leadMarket: 'Lead Market',
  brokerage: 'Brokerage',
  brokerageRe: 'Brokerage RIP %',
  name: 'Reinsurer',
}

export interface QuoteExcelParams {
  clientName: string
  programName: string
  headers: string[]
  descriptionColumn: string[] // describes each column (i.e. Reinsurer)
  descriptionRows: string[][][] // describes what each value of the row measures (i.e. Subject Premium, Risk Limit, etc.)
  nonFotAndExpiringRows: QuoteExportRowValue[][][][]
  nonFotAndExpiringColumns: string[][]
  fotReinsurerRows: QuoteExportRowValue[][][][]
  fotReinsurerColumns: string[][]
  expiringReinsurerRows: QuoteExportRowValue[][][][]
  expiringReinsurerColumns: string[][]
  trackingRows1: QuoteExportRowValue[][]
  trackingRows2: QuoteExportRowValue[][]
  trackingColumns: string[]
  oppDate: string | undefined
  oppName: string | undefined
  assignedLinesRows: QuoteExportRowValue[][][]
  assignedLinesColumns: string[]
  trackingRowsAL1: QuoteExportRowValue[][]
  trackingRowsAL2: QuoteExportRowValue[][]
  structureGroupName: string | null | undefined
  sharedLimitName: string | null | undefined
  quotesSignedPercRow: number[][]
  members: number[]
  sectionCurrencies: string[]
  xolSubjectPremiumRows: QuoteExportRowValue[][][]
  exportMode: QuoteExportModes
  fotSummaryRows: QuoteExportRowValue[][][]
  expiringReinsurerMappings: number[][]
  layerPaletteMappings: number[]
  fotSummaryXOLLayerIndexMappings: number[]
}

export type QuoteExportSectionView = {
  id: string
  section: Section | null
  childSections: Section[]
  reinsurers: ReinsurerState[]
  compareViews: QuoteCustomCompareView[]
  sectionSelected: boolean
  selectedCompareViewIds: number[]
}

export type QuoteExportTermView = {
  layerTypes: string[]
  paletteItem: LayerPaletteItem
  terms: QuoteExportTermItem[]
}

export type QuoteExportTermItem = {
  quoteExportId: string
  id: string
  label: string
  active: boolean
}

export type QuoteExportDialogPayload = {
  sectionViews: QuoteExportSectionView[]
  termViews: QuoteExportTermView[]
}

export const QuoteExportDefaultSelections: Partial<
  Record<layerIds, (keyof LayerViewValues)[]>
> = {
  cat_xl: [
    'quoteOfferedPercentage',
    'quoteRolPercentage',
    'premium',
    'minimumPremiumPercentage',
    'minimumPremium',
    'depositPremiumPercentage',
    'quoteDepositPremium',
    'quoteReinstatements',
  ],
  noncat_xl: [
    'quoteOfferedPercentage',
    'quoteRateOnLineSubject',
    'premium',
    'minimumPremiumPercentage',
    'minimumPremium',
    'depositPremiumPercentage',
    'quoteDepositPremium',
    'quoteReinstatements',
  ],
}

export type Currency = readonly [string, number]

export const fotSummaryRowIds: (keyof LayerViewValues)[] = [
  'quoteRolPercentage',
  'quoteRateOnLineSubject',
  'quotePremium',
  'minimumPremiumPercentage',
  'minimumPremium',
  'depositPremiumPercentage',
  'quoteDepositPremium',
  'quoteReinstatements',
]

function createFont(
  name: string,
  size: number,
  argb?: string,
  bold?: boolean
): Partial<Font> {
  return {
    name,
    size,
    color: argb ? { argb } : undefined,
    bold,
  }
}

function createFillPattern(pattern: FillPatterns, argb: string): FillPattern {
  return {
    type: 'pattern',
    pattern,
    fgColor: { argb },
  }
}
