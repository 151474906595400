import { BaseDimension, BASE_DIMENSIONS, Coord } from '@graphing/utils/coord'
import { LayerViewValues } from '../model/layer-view'

export interface LayerModelingDatum
  extends Record<LayerModelingDimension, number> {
  description: string
  layerType?: string
  currency?: any
  lossSetGroup?: string
}

export const LAYER_MODELING_DIMENSIONS = [...BASE_DIMENSIONS, 'size'] as const

export type LayerModelingDimension = typeof LAYER_MODELING_DIMENSIONS[number]

export interface LayerModelingView {
  loading: boolean
  error?: string
  data: LayerModelingDatum[]
  medians?: Record<BaseDimension, number>
  secondaryLines?: Coord[][]
  colors?: string[]
}

export interface LayerModelingDimensionChangeEvent {
  dimension: LayerModelingDimension
  prop: keyof LayerViewValues
}
