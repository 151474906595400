<div (click)="reinsurerClick()">
  <app-quote-actions
    [reinsurerByName]="reinsurers"
    [currentReinsurer]="currentReinsurer"
    [selectedReinsurer]="selectedReinsurer"
    [isExpanded]="isExpanded"
    [expandedName]="expandedName"
    [isFOTMasterCreated]="isFOTMasterCreated"
    [isSaving]="isSaving"
    [reinsurerList]="reinsurerList"
    [allReinsurers]="allReinsurers"
    [reinsurersNameListUpdated]="reinsurersNameListUpdated"
    [isGroupSelected]="isGroupSelected"
    [isSLSelected]="isSLSelected"
    [layer]="layer"
    [layerState]="layerState"
    [showCustomCompare]="showCustomCompare"
    [customCompareViews]="customCompareViews"
    (saveClick)="saveClick.emit($event)"
    (deleteClick)="deleteClick.emit($event)"
    (declineClick)="declineClick.emit($event)"
    (preferredClick)="preferredClick.emit($event)"
    (exportToggleClick)="exportToggleClick.emit($event)"
    (nameSelection)="nameSelection.emit($event)"
    (addOrUpdateVersionReinsurer)="addOrUpdateVersionReinsurer.emit($event)"
    (selectedID)="selectedID.emit($event)"
    (expandClick)="expandClick.emit($event)"
    (populateClick)="populateClick.emit($event)"
    (expandedReinsurer)="expandedReinsurer.emit($event)"
    (assignedLinesClick)="assignedLinesClick.emit()"
    (updateLabel)="updateLabel.emit($event)"
    (isUKReinsurerActions)="isUKReinsurerEvent.emit($event)"
    (updateCompareViews)="updateCompareViews.emit($event)"
  ></app-quote-actions>
  <app-quote-panel
    *ngIf="!isGroupSelected && !isSLSelected"
    [layer]="layer"
    [layerState]="layerState"
    [layerGroup]="layerGroup"
    [layerSL]="getLayerForSL()"
    [view]="view"
    [selectedReinsurer]="selectedReinsurer"
    [currentReinsurer]="currentReinsurer"
    [quotePanelDefs]="quotePanelDefs"
    [isExpanded]="isExpanded"
    [expandedName]="expandedName"
    [allReinsurers]="allReinsurers"
    [selectedSection]="selectedSection"
    [sectionList]="sectionList"
    [isGroupSelected]="isGroupSelected"
    [isSLSelected]="isSLSelected"
    [firstReinsurerName]="firstReinsurerName"
    [territories]="territories"
    [accountOpportunity]="accountOpportunity"
    (subjectivityClick)="subjectivityClick.emit($event)"
    (reinsurerResize)="reinsurerResize.emit($event)"
    (slidingValueChange)="slidingValueChange.emit($event)"
    (deleteReinstatement)="deleteReinstatement.emit($event)"
    (expandClick)="expandClick.emit($event)"
  ></app-quote-panel>

  <mat-accordion
    [multi]="true"
    *ngIf="
      isGroupSelected ||
      isSLSelected ||
      (layer &&
        layer.layer.meta_data.sage_layer_type === layerIds.catMultisection) ||
      (layer && layer.layer.meta_data.sage_layer_type === layerIds.catTd)
    "
  >
    <mat-expansion-panel
      *ngFor="let reinsurer of groupReinsurers; let i = index"
      [expanded]="isLayerExpanded(i)"
      (opened)="panelExpandClick.emit({ arg: i })"
      (closed)="panelCollapseClick.emit({ arg: i })"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ getLayerName(reinsurer) }}
        </mat-panel-title>
      </mat-expansion-panel-header>
     <app-quote-panel
        [layer]="layer"
        [layerState]="layerState"
        [layerStateGroup]="layerStateGroup"
        [layerGroup]="getLayerGroup(reinsurer)"
        [layersSL]="layersSL"
        [layerSL]="getLayerSL(reinsurer)"
        [view]="getView(reinsurer)"
        [selectedReinsurer]="selectedReinsurer"
        [currentReinsurer]="reinsurer"
        [quotePanelDefs]="getQuotePanelDefValues(reinsurer)"
        [isExpanded]="isExpanded"
        [expandedName]="expandedName"
        [allReinsurers]="allReinsurers"
        [selectedSection]="getSelectedSection(reinsurer)"
        [isGroupSelected]="isGroupSelected"
        [isSLSelected]="isSLSelected"
        [firstReinsurerName]="firstReinsurerName"
        [territories]="territories"
        [isSection]="isSection"
        (subjectivityClick)="subjectivityClick.emit()"
        (reinsurerResize)="reinsurerResize.emit($event)"
        (slidingValueChange)="slidingValueChange.emit($event)"
        (deleteReinstatement)="deleteReinstatement.emit($event)"
        (expandClick)="expandClick.emit($event)"
      ></app-quote-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>
