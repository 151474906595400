export type SummaryChartGridOption = {
  index: number /* chart grid index i.e. 0 = top left chart */
  chart?: SummaryChartInfo
  metric?: SummaryChartAxis /* y axis of chart */
  split?: SummaryChartAxis | null /* column used for additional data grouping */
  size?: SummaryChartAxis | null /* column used for bubble size */
  loading: boolean /* chart grid data loading */
  highlight: boolean /* highlight chart grid icon & selector */
  data?: SummaryChartData
  vertSplits?: string[]
}
export interface SummaryChartInfo {
  name: string
  type: SummaryChartType
  icon: string
  class?: string
  applicableSizes: SummaryChartAxis[]
  applicableSplits: SummaryChartAxis[]
}
export interface SummaryChartAxis {
  name: string
  columnName:
    | SummaryChartGridColumn
    | SummaryChartGridMetric
    | SummaryChartDataSplitColumn
    | SummaryChartSizeColumn
  applicableCharts: SummaryChartInfo[]
  format?: 'decimal' | 'int' | 'percent'
  metricPrimaryName?: string
  metricSecondaryName?: string
  metricTertiaryName?: string
  metricQuaternaryName?: string
  isPureLayerMetric?: boolean
  columnIDs?: string[]
  headerIDs?: string[]
  shortName?: string
}
export type SummaryChartGridColumn =
  | 'contributionToModeledGroupVolatility'
  | 'compositionofLossCurveByReturnPeriod'
  | 'grossCombinedRatioSummary'
  | 'contributionToOverallVolatility'
  | 'writtenPremiumByProduct'
export type SummaryChartGridMetric =
  | 'contributionToModeledGroupVolatility'
  | 'compositionofLossCurveByReturnPeriod'
  | 'grossCombinedRatioSummary'
  | 'contributionToOverallVolatility'
  | 'writtenPremiumByProduct'
export type SummaryChartDataSplitColumn =
  | 'groupByOne'
  | 'groupByTwo'
  | 'groupByThree'
  | 'groupByFour'
  | 'groupByFive'
export type SummaryChartSizeColumn =
  | 'signedLimit'
  | 'writtenLimit'
  | 'expiringLimit'
  | 'cessionPercentage'

export type SummaryChartResponse = {
  data: SummaryChartData
  index: number
  groupBy: SummaryChartAxis
}
export type SummaryChartData = {
  data: SummaryChartDatum[]
}
export interface SummaryChartDatum {
  groupBy: string
  metricPrimary: number
  metricSecondary?: number
  metricTertiary?: number
  metricQuaternary?: number
  split?: string
  size?: number
  values?: {
    x: number
    y: number
    raw?: number | string
  }[]
}

export interface SummaryChartGroupedDataBySplit {
  groupBy: string
  data: number[]
  metricTotal: number
}

export interface SummaryChartMenuOption {
  name: string
  icon?: string
}

export const SummaryMIChartTypes = [
  'bubble',
  'scatter',
  'donut',
  'pie',
  'unstackedArea',
  'stackedArea',
  'unstackedBar',
  'stackedBar',
  'percentStackedBar',
  'verticalArea'
] as const

export type SummaryChartType = typeof SummaryMIChartTypes[number]

export type SummaryChartsInfo = {
  bubbleInfo: SummaryChartInfo
  scatterInfo: SummaryChartInfo
  donutInfo: SummaryChartInfo
  pieInfo: SummaryChartInfo
  unstackedAreaInfo: SummaryChartInfo
  unstackedBarInfo: SummaryChartInfo
  stackedBarInfo: SummaryChartInfo
  percentStackedBarInfo: SummaryChartInfo
  verticalAreaInfo: SummaryChartInfo
}
export type SummaryChartMetricsInfo = {
  contributionToModeledGroupVolatility: SummaryChartAxis
  compositionofLossCurveByReturnPeriod: SummaryChartAxis
  grossCombinedRatioSummary: SummaryChartAxis
  contributionToOverallVolatility: SummaryChartAxis
  writtenPremiumByProduct: SummaryChartAxis
}
export type SummaryChartGroupBysInfo = {
  contributionToModeledGroupVolatility: SummaryChartAxis
  compositionofLossCurveByReturnPeriod: SummaryChartAxis
  grossCombinedRatioSummary: SummaryChartAxis
  contributionToOverallVolatility: SummaryChartAxis
  writtenPremiumByProduct: SummaryChartAxis
}
export const SUMMARY_CHARTS_INFO: SummaryChartsInfo = {
  bubbleInfo: {
    name: 'Bubble',
    type: 'bubble',
    icon: 'bubble_chart',
    applicableSizes: [
      { name: 'Signed Limit', columnName: 'signedLimit', applicableCharts: [] },
      {
        name: 'Written Limit',
        columnName: 'writtenLimit',
        applicableCharts: [],
      },
      {
        name: 'Expiring Limit',
        columnName: 'expiringLimit',
        applicableCharts: [],
      },
      {
        name: 'Cession Percentage',
        columnName: 'cessionPercentage',
        applicableCharts: [],
      },
    ],
    applicableSplits: [],
  },
  scatterInfo: {
    name: 'Scatter',
    type: 'scatter',
    icon: 'scatter_plot',
    applicableSizes: [],
    applicableSplits: [],
  },
  donutInfo: {
    name: 'Donut',
    type: 'donut',
    icon: 'donut_large',
    applicableSizes: [],
    applicableSplits: [],
  },
  pieInfo: {
    name: 'Pie',
    type: 'pie',
    icon: 'pie_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
  unstackedAreaInfo: {
    name: 'Unstacked Area',
    type: 'unstackedArea',
    icon: 'area_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
  unstackedBarInfo: {
    name: 'Unstacked Bar',
    type: 'unstackedBar',
    icon: 'bar_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
  stackedBarInfo: {
    name: 'Stacked Bar',
    type: 'stackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Loss Type',
        columnName: 'grossCombinedRatioSummary',
        applicableCharts: [],
      },
    ],
  },
  percentStackedBarInfo: {
    name: 'Percent Stacked Bar',
    type: 'percentStackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Loss Type',
        columnName: 'grossCombinedRatioSummary',
        applicableCharts: [],
      },
    ],
  },
  verticalAreaInfo: {
    name: 'Vertical Stacked Area',
    type: 'verticalArea',
    icon: 'area_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
}
export const SUMMARY_CHART_METRICS_INFO: SummaryChartMetricsInfo = {
  contributionToModeledGroupVolatility: {
    name: 'Contribution to Overall Group Modeled Volatility',
    shortName: 'volatility-contribution',
    columnName: 'contributionToModeledGroupVolatility',
    format: 'percent',
    applicableCharts: [
      SUMMARY_CHARTS_INFO.donutInfo,
      SUMMARY_CHARTS_INFO.pieInfo,
      SUMMARY_CHARTS_INFO.unstackedBarInfo,
    ],
    columnIDs: [
      'contributionToGroupVolatility_Attritional',
      'contributionToGroupVolatility_Large',
      'contributionToGroupVolatility_Cat',
      'totalContributionToGroupVolatility',
    ],
    headerIDs: [
      'contributionToGroupVolatility',
      'totalContributionToGroupVolatility',
    ]
  },
  compositionofLossCurveByReturnPeriod: {
    name: 'Composition of Loss Curve by Return Period',
    shortName: 'loss-curve',
    columnName: 'compositionofLossCurveByReturnPeriod',
    format: 'percent',
    applicableCharts: [
      SUMMARY_CHARTS_INFO.verticalAreaInfo
    ],
    columnIDs: [
      'largeRiskDistributionAEP_10',
      'largeRiskDistributionAEP_25',
      'largeRiskDistributionAEP_50',
      'largeRiskDistributionAEP_100',
      'largeRiskDistributionAEP_200',
      'largeRiskDistributionAEP_250',
    ],
    headerIDs: [
      'largeRiskDistributionAEP'
    ]
  },
  grossCombinedRatioSummary: {
    name: 'Gross Combined Ratio Summary',
    shortName: 'combined-ratio',
    columnName: 'grossCombinedRatioSummary',
    format: 'percent',
    applicableCharts: [
      SUMMARY_CHARTS_INFO.stackedBarInfo,
    ],
    columnIDs: [
      'premium',
      'expense',
      'lossByType_Attritional',
      'lossByType_Large',
      'lossByType_Cat',
      'expectedLoss',
      'expenseRatio'
    ],
    headerIDs: [
      'premium',
      'expense',
      'lossByType',
      'expectedLoss',
      'expenseRatio'
    ]
  },
  contributionToOverallVolatility: {
    name: 'Contribution To Overall Volatility',
    shortName: 'overall-volatility-contribution',
    columnName: 'contributionToOverallVolatility',
    format: 'decimal',
    applicableCharts: [
      SUMMARY_CHARTS_INFO.unstackedBarInfo,
      SUMMARY_CHARTS_INFO.donutInfo,
      SUMMARY_CHARTS_INFO.pieInfo,
    ],
    columnIDs: [
      'contributionToGroupVolatility_Attritional',
      'contributionToGroupVolatility_Large',
      'contributionToGroupVolatility_Cat',
      'totalContributionToGroupVolatility',
    ],
    headerIDs: [
      'contributionToGroupVolatility',
      'totalContributionToGroupVolatility',
    ]
  },
  writtenPremiumByProduct: {
    name: 'Written Premium By Product',
    shortName: 'written-premium',
    columnName: 'writtenPremiumByProduct',
    format: 'decimal',
    applicableCharts: [
      SUMMARY_CHARTS_INFO.donutInfo,
      SUMMARY_CHARTS_INFO.pieInfo,
      SUMMARY_CHARTS_INFO.unstackedBarInfo,
    ],
    columnIDs: [
      'premium'
    ],
    headerIDs: [
      'premium'
    ]
  }
}
export const DEFAULT_SUMMARY_CHARTS: SummaryChartGridOption[] = [
  {
    index: 0,
    loading: false,
    highlight: false,
    chart: SUMMARY_CHARTS_INFO.donutInfo,
    metric: SUMMARY_CHART_METRICS_INFO.contributionToModeledGroupVolatility,
  },
  {
    index: 1,
    loading: false,
    highlight: false,
    chart: SUMMARY_CHARTS_INFO.pieInfo,
    metric: SUMMARY_CHART_METRICS_INFO.writtenPremiumByProduct,
  },
  {
    index: 2,
    loading: false,
    highlight: false,
    chart: SUMMARY_CHARTS_INFO.stackedBarInfo,
    metric: SUMMARY_CHART_METRICS_INFO.grossCombinedRatioSummary,
  },
  {
    index: 3,
    loading: false,
    highlight: false,
    chart: SUMMARY_CHARTS_INFO.verticalAreaInfo,
    metric: SUMMARY_CHART_METRICS_INFO.compositionofLossCurveByReturnPeriod,
  },
]
