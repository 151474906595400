import { Component } from '@angular/core'
import { ProgressBarMode } from '@angular/material/progress-bar'

@Component({
  selector: 'app-auto-build-progress-dialog',
  styleUrls: ['./auto-build-progress-dialog.component.scss'],
  templateUrl: './auto-build-progress-dialog.component.html',
})
export class AutoBuildProgressDialogComponent {
  mode: ProgressBarMode = 'query'
  completedSteps: string[] = []
  steps: string[] = []
  range = 100
  value = 0
  saveComplete = false

  setCloningStep(label: string, step: string): void {
    this.saveComplete = false

    this.mode = 'query'
    this.value = 0

    this.completedSteps.push(label)
    this.steps = [step]
  }

  addStep(step: string): void {
    this.steps.push(step)
  }

  setRange(range: number): void {
    this.mode = 'determinate'
    this.range = range
  }

  setProgress(progress: number): void {
    this.value = (100 * progress) / this.range
  }

  setSavingStep(step: string): void {
    this.mode = 'indeterminate'
    this.steps.push(step)
  }

  setSaveComplete(): void {
    this.saveComplete = true

    this.mode = 'determinate'
    this.value = 100
  }
}
