import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
} from '../../analysis/model/compare-metrics.model'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import { Reinsurer, ReinsurerFilter } from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { LossFilter } from '../../api/analyzere/analyzere.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-content',
  styleUrls: ['./metrics-content.component.scss'],
  templateUrl: './metrics-content.component.html',
})
export class MetricsContentComponent {
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() studySelected: string | null
  @Input() totalWeight: number
  @Input() reinsurers: Reinsurer[] | null
  @Input() reinsurersBlob: BlobResponse | null
  @Input() reinsurerFilters: ReinsurerFilter[]
  @Input() reinsurerDomicileList: string[]
  @Input() reinsurerSPRatingList: string[]
  @Input() reinsurerAmBestRatingList: string[]
  @Input() lossFilters: LossFilter[]

  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs: Study[]
  @Input() selectedProgramID: string | null | undefined

  @Output() saveClick = new EventEmitter()
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() collapseToggle = new EventEmitter<string>()
  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurers: Reinsurer[]
  }>()
  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() reinsurerFilterToggle = new EventEmitter<ReinsurerFilter>()
  @Output() removeAllFilters = new EventEmitter()
  @Output() removeFilter = new EventEmitter<ReinsurerFilter>()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  @Output() enableAllClick = new EventEmitter()
}
