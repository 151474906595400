export enum layerIds {
  catQs = 'cat_qs',
  catFhcf = 'cat_fhcf',
  catAg = 'cat_ag',
  catCa = 'cat_ca',
  catAggXl = 'cat_agg_xl',
  catCascade = 'cat_cascade',
  catCascading = 'cat_cascading',
  catTd = 'cat_td',
  catXl = 'cat_xl',
  catIlw = 'cat_ilw',
  ilwBin = 'cat_ilw_bin',
  ilwProRata = 'cat_ilw_pro_rata',
  ilwAg ='cat_ilw_ag',
  ahlAg = 'ahl_ag',
  ahlQs = 'ahl_qs',
  ahlXl = 'ahl_xl',
  ahlSwing = 'ahl_swing',
  otherAg = 'other_ag',
  otherQs = 'other_qs',
  otherXl = 'other_xl',
  noncatAg = 'noncat_ag',
  noncatAggXl = 'noncat_agg_xl',
  noncatIndxl = 'noncat_indxl',
  noncatQs = 'noncat_qs',
  noncatRisk = 'noncat_risk',
  noncatSwing = 'noncat_swing',
  noncatXl = 'noncat_xl',
  catMultisection = 'cat_multisection',
  noncatMultisection = 'noncat_multisection',
  feeder = 'feeder',
  drop = 'drop',
  topAndDrop = 'top_and_drop',
}

export const COMPLEX_LAYERS = [
  layerIds.noncatIndxl,
  layerIds.noncatSwing,
  layerIds.ahlSwing,
  layerIds.catMultisection,
  layerIds.noncatMultisection,
]

export type LayerPaletteType =
  | 'xl' // Excess of loss
  | 'ag' // Aggregate
  | 'qs' // Quota Share
  | 'td' // Top & Drop
  | 'ca' // Cascading
  | 'd' // ?
  | 'fhcf' // FHCF
  | 'risk' // Risk XOL(Risk Excess of Loss)
  | 'swing' // Swing-rated
  | 'multisection' // Multi-Section
  | 'ilw' // ILW layers
  | 'pro-rata' // ILW pro-rata layer
  | 'bin' // ILW bin layer

export type LayerPaletteProgram = 'cat' | 'noncat' | 'ahl' | 'standard'

export type BasicRoleTypes = 'Occ' | 'Agg' | 'QS' | 'Risk' | 'ILW'

export const QsLayers: string[] = [
  layerIds.catQs,
  layerIds.noncatQs,
  layerIds.ahlQs,
]
export const OccLayers: string[] = [
  layerIds.catXl,
  layerIds.catCa,
  layerIds.catTd,
  layerIds.catFhcf,
  layerIds.catMultisection,
  layerIds.catIlw,
  layerIds.ilwBin,
  layerIds.ilwProRata,
  layerIds.noncatXl,
  layerIds.noncatIndxl,
  layerIds.noncatRisk,
  layerIds.noncatSwing,
  layerIds.ahlXl,
  layerIds.ahlSwing,
]
export const AggLayers: string[] = [
  layerIds.catAg,
  layerIds.noncatAg,
  layerIds.ahlAg,
  layerIds.ilwAg,
]

export const ILW_SELECTABLE_LAYERS: LayerPaletteType[] = ['pro-rata', 'bin']

export const STANDARD_PALETTE_IDS: layerIds[] = [
  layerIds.catAg,
  layerIds.catCa,
  layerIds.catFhcf,
  layerIds.catQs,
  layerIds.catTd,
  layerIds.catXl,
  layerIds.catIlw,
  layerIds.catMultisection,
  layerIds.noncatAg,
  layerIds.noncatIndxl,
  layerIds.noncatQs,
  layerIds.noncatRisk,
  layerIds.noncatSwing,
  layerIds.noncatXl,
  layerIds.ahlAg,
  layerIds.ahlQs,
  layerIds.ahlXl,
  layerIds.ahlSwing,
]

export const AHL_PALETTE_IDS: layerIds[] = [
  layerIds.ahlAg,
  layerIds.ahlQs,
  layerIds.ahlXl,
  layerIds.ahlSwing,
]

export const PROPERTY_PALETTE_IDS: layerIds[] = [
  layerIds.catAg,
  layerIds.catCa,
  layerIds.catFhcf,
  layerIds.catQs,
  layerIds.catTd,
  layerIds.catXl,
  layerIds.catMultisection,
  layerIds.catIlw,
]

export const CASUALTY_PALETTE_IDS: layerIds[] = [
  layerIds.noncatAg,
  layerIds.noncatIndxl,
  layerIds.noncatQs,
  layerIds.noncatRisk,
  layerIds.noncatSwing,
  layerIds.noncatXl,
]

export interface LayerPaletteProgramGroup {
  name: string
  items: readonly LayerPaletteItem[]
}

export interface LayerPaletteItem {
  id: string
  name: string
  type: LayerPaletteType
  program: LayerPaletteProgram
  special?: boolean
  striped?: boolean
  subtype?: string
  hidden?: boolean
  role_type?: BasicRoleTypes
}

export interface LayerPaletteView {
  id: string
  name: string
}

export const LAYER_PALETTE_VIEWS: LayerPaletteView[] = [
  { id: '1', name: 'Standard' },
  { id: '2', name: 'AHL' },
  { id: '3', name: 'Property' },
  { id: '4', name: 'Casualty & Specialty' },
]

export const LAYER_PALETTE_PROGRAMS: Record<LayerPaletteProgram, string> = {
  cat: 'Property Catastrophe',
  noncat: 'Casualty & Specialty',
  ahl: 'AHL',
  standard: 'Standard',
}

export const LAYER_PALETTE_CATEGORY_MAPPINGS: Record<
  LayerPaletteType,
  { name: string; condition?: 'EQUAL' | 'GREATER' }[]
> = {
  xl: [
    { name: 'Excess of Loss', condition: 'EQUAL' },
    { name: 'Excess of Loss Subsequent Event', condition: 'GREATER' },
  ],
  ag: [{ name: 'Aggregate' }],
  qs: [{ name: 'Quota Share' }],
  td: [{ name: 'Top & Drop' }],
  ca: [
    { name: 'Cascading', condition: 'EQUAL' },
    { name: 'Cascading Subsequent Event', condition: 'GREATER' },
  ],
  d: [],
  fhcf: [{ name: 'Ignore' }],
  risk: [
    { name: 'Risk XOL', condition: 'EQUAL' },
    { name: 'Risk XOL Subsequent Event', condition: 'GREATER' },
  ],
  swing: [
    { name: 'Swing Rated', condition: 'EQUAL' },
    { name: 'Swing Rated Subsequent Event', condition: 'GREATER' },
  ],
  multisection: [
    { name: 'Multi-section', condition: 'EQUAL' },
    { name: 'Multi-section Subsequent Event', condition: 'GREATER' },
  ],
  ilw: [],
  'pro-rata': [
    { name: 'Pro Rata', condition: 'EQUAL' },
    { name: 'Pro Rata Subsequent Event', condition: 'GREATER' },
  ],
  bin: [
    { name: 'Binary', condition: 'EQUAL' },
    { name: 'Binary Subsequent Event', condition: 'GREATER' },
  ],
}

// TODO: Match `id`s with API
export const LAYER_PALETTE: LayerPaletteItem[] = [
  {
    id: layerIds.catQs,
    name: 'Quota Share',
    type: 'qs',
    program: 'cat',
    role_type: 'QS',
  },
  {
    id: layerIds.catXl,
    name: 'Excess of Loss',
    type: 'xl',
    program: 'cat',
    role_type: 'Occ',
  },
  {
    id: layerIds.catCa,
    name: 'Cascading',
    type: 'ca',
    program: 'cat',
    special: true,
    role_type: 'Occ',
  },
  {
    id: layerIds.catTd,
    name: 'Top & Drop',
    type: 'td',
    program: 'cat',
    special: true,
    subtype: 'virtual',
    role_type: 'Occ',
  },
  {
    id: layerIds.drop,
    name: 'Drop',
    type: 'd',
    program: 'cat',
    special: true,
    hidden: true,
    role_type: 'Occ',
  },
  {
    id: layerIds.catFhcf,
    name: 'FHCF',
    type: 'fhcf',
    program: 'cat',
    role_type: 'Occ',
  },
  {
    id: layerIds.catAg,
    name: 'Aggregate Feeder',
    type: 'ag',
    program: 'cat',
    subtype: 'feeder',
    role_type: 'Agg',
  },
  {
    id: layerIds.catAg,
    name: 'Aggregate',
    type: 'ag',
    program: 'cat',
    role_type: 'Agg',
  },
  {
    id: layerIds.catMultisection,
    name: 'Multi-Section',
    type: 'multisection',
    program: 'cat',
    subtype: 'visible-layer',
    striped: true,
    role_type: 'Occ',
  },
  {
    id: layerIds.catIlw,
    name: 'ILW Layers',
    type: 'ilw',
    program: 'cat',
    role_type: 'ILW',
  },
  {
    id: layerIds.ilwBin,
    name: 'ILW Binary',
    type: 'bin',
    program: 'cat',
    role_type: 'ILW',
  },
  {
    id: layerIds.ilwProRata,
    name: 'ILW Pro-Rata',
    type: 'pro-rata',
    program: 'cat',
    role_type: 'ILW',
  },
  {
    id: layerIds.ilwAg,
    name: 'ILW Aggregate Feeder',
    type: 'ag',
    program: 'cat',
    subtype: 'feeder',
    role_type: 'ILW',
  },
  {
    id: layerIds.ilwAg,
    name: 'ILW Aggregate',
    type: 'ag',
    program: 'cat',
    role_type: 'ILW',
  },
  {
    id: layerIds.noncatQs,
    name: 'Quota Share',
    type: 'qs',
    program: 'noncat',
    role_type: 'QS',
  },
  {
    id: layerIds.noncatXl,
    name: 'Excess of Loss',
    type: 'xl',
    program: 'noncat',
    role_type: 'Occ',
  },
  {
    id: layerIds.noncatIndxl,
    name: 'Indexed XL',
    type: 'xl',
    program: 'noncat',
    subtype: 'visible-layer',
    role_type: 'Occ',
  },
  {
    id: layerIds.noncatAg,
    name: 'Aggregate Feeder',
    type: 'ag',
    program: 'noncat',
    subtype: 'feeder',
    role_type: 'Agg',
  },
  {
    id: layerIds.noncatAg,
    name: 'Aggregate',
    type: 'ag',
    program: 'noncat',
    role_type: 'Agg',
  },
  {
    id: layerIds.noncatRisk,
    name: 'Risk XOL',
    type: 'risk',
    program: 'noncat',
    role_type: 'Risk',
  },
  {
    id: layerIds.noncatSwing,
    name: 'Swing-rated',
    type: 'swing',
    program: 'noncat',
    subtype: 'visible-layer',
    role_type: 'Occ',
  },
  {
    id: layerIds.noncatMultisection,
    name: 'Multi-Section',
    type: 'multisection',
    program: 'noncat',
    subtype: 'visible-layer',
    striped: true,
    hidden: true,
    role_type: 'Occ',
  },
  {
    id: layerIds.ahlQs,
    name: 'Quota Share',
    type: 'qs',
    program: 'ahl',
    role_type: 'QS',
  },
  {
    id: layerIds.ahlXl,
    name: 'Excess of Loss',
    type: 'xl',
    program: 'ahl',
    role_type: 'Occ',
  },
  {
    id: layerIds.ahlAg,
    name: 'Aggregate',
    type: 'ag',
    program: 'ahl',
    role_type: 'Agg',
  },
  {
    id: layerIds.ahlAg,
    name: 'Aggregate Feeder',
    type: 'ag',
    program: 'ahl',
    subtype: 'feeder',
    role_type: 'Agg',
  },
  {
    id: layerIds.ahlSwing,
    name: 'Swing-rated',
    type: 'swing',
    program: 'ahl',
    subtype: 'visible-layer',
    role_type: 'Occ',
  },
]

export interface LayerDialogData {
  renewedFromOpportunityId: string | null
  selectedClientID: string | null
  selectedYearId: string | null
  selectedProgramID: string | null
}
