import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AudienceLayerSelectDialogComponent } from './audience-layer-select-dialog/audience-layer-select-dialog.component'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LossSet, Metadata } from '../api/analyzere/analyzere.model'
import {
  layerIds,
  LayerPaletteProgramGroup,
  LayerPaletteProgram,
  LayerPaletteView,
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
  LAYER_PALETTE_VIEWS,
} from '../analysis/model/layer-palette.model'
import { Layer, LayerRef, PhysicalLayer } from '../analysis/model/layers.model'
import { isLayerAggFeeder } from '../analysis/model/layers.util'
import { LossSetLayer } from '../analysis/model/loss-set-layers.model'
import * as fromSelectors from '../analysis/store/analysis.selectors'
import { selectEditorState } from '../analysis/store/analysis.selectors'
import {
  addFHCFLayer,
  addIndexedLayer,
  addLayer,
  addRiskLayer,
  addSwingLayer,
} from '../analysis/store/ceded-layers/layers.actions'
import {
  CopyLayerState,
  LayerState,
} from '../analysis/store/ceded-layers/layers.reducer'
import { EditorState } from '../analysis/store/analysis.reducer'
import * as fromPortfolio from '../analysis/store/ceded-portfolio/portfolio.reducer'
import { AppState } from '../core/store'
import { analyzereConstants } from './constants/analyzere'
import { LayerDropEvent } from './layer-palette-item.component'
import { LayerService } from '@shared/services/layer.service'
import { StudyResponse } from '../api/model/backend.model'

export interface DialogResult {
  parentGrossPortfolioID?: string
  analysisProfileID?: string
  index?: number
}

export function generateTemporaryId() {
  const time = new Date().getTime()
  const rnd = Math.random() * 100000
  return `${time}${rnd}`
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-palette-item-container',
  styles: [
    `
      app-layer-palette-item {
        display: block;
        margin-bottom: var(--stack);
      }

      :host-context(.app-theme-layer-wireframe) app-layer-palette-item {
        padding-right: 6px;
      }

      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media screen and (max-width: 1240px) {
        h4 {
          font-size: var(--font-size-small);
        }
      }
    `,
  ],
  template: `
    <ng-container *ngFor="let group of groups; index as i; trackBy: trackByFn">
      <h4 *ngIf="group.items.length > 0">
        {{ group.name }}
      </h4>
      <app-layer-palette-item
        *ngFor="let item of group.items; index as j; trackBy: trackByFn"
        [item]="item"
        [layers]="layers$ | async"
        [portfolioMetadata]="portfolioMetadata$ | async"
        [layerChoices]="layerChoices$ | async"
        [currentStructureID]="structureId"
        [log]="log$ | async"
        (newLayer)="onLayerAdded($event)"
      ></app-layer-palette-item>
    </ng-container>
  `,
})
export class LayerPaletteItemContainerComponent implements OnInit, OnChanges {
  @Input() layerPasteTrigger: boolean
  @Input() structureId: string | null
  @Input() studies: StudyResponse[]
  @Input() selectedProgramID: string
  views: LayerPaletteView[] = LAYER_PALETTE_VIEWS

  editorState: EditorState
  layers$: Observable<LayerState[]>
  layerChoices$: Observable<LossSetLayer[]>
  groups: LayerPaletteProgramGroup[]
  portfolioMetadata$: Observable<fromPortfolio.State | null>
  log$: Observable<boolean | null>
  currentCurrency: string
  layerPaletteView: LayerPaletteView
  copyLayer: CopyLayerState

  constructor(
    private store: Store<AppState>,
    private layerService: LayerService,
    public dialog: MatDialog
  ) {
    this.layerService.getLayerCopyObs().subscribe(layer => {
      this.copyLayer = layer
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.layerPasteTrigger &&
      changes.layerPasteTrigger.currentValue
    ) {
      this.onLayerPaste(this.copyLayer)
    }
  }

  ngOnInit(): void {
    this.portfolioMetadata$ = this.store.pipe(
      select(fromSelectors.selectCededPortfolioState)
    )
    this.store.pipe(select(selectEditorState)).subscribe(editor => {
      this.editorState = editor
    })
    this.layers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => {
        return layers.filter(
          l =>
            !l.deleted && l.layer.meta_data.sage_layer_type !== 'shared_limits'
        )
      })
    )

    this.log$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesLog)
    )

    this.layerChoices$ = this.store.pipe(
      select(fromSelectors.selectParentGrossLossSetLayers)
    )

    this.store
      .pipe(
        select(fromSelectors.selectCurrentCurrency),
        map(currency => (currency ? currency : 'USD'))
      )
      .subscribe((currency: string) => {
        this.currentCurrency = currency
      })

    this.layerPaletteView = this.getLayerPaletteView()
    this.groups = this.getGroups()
  }

  getLayerPaletteView(): LayerPaletteView {
    let id = '1'
    const selectedStudyView = this.studies?.find(
      s => s.id.toString() === this.selectedProgramID
    )?.sage_view
    id = this.views.find(v => v.name === selectedStudyView)?.id!
    const layerPaletteView: LayerPaletteView | undefined =
      LAYER_PALETTE_VIEWS.find(view => view.id === id)

    return layerPaletteView ? layerPaletteView : { id: '1', name: 'Standard' }
  }

  getGroups(): LayerPaletteProgramGroup[] {
    const ids = this.layerService.getLayerIds(this.layerPaletteView?.id)
    return Object.keys(LAYER_PALETTE_PROGRAMS).map(
      (key: LayerPaletteProgram) => {
        return {
          name: LAYER_PALETTE_PROGRAMS[key],
          items: LAYER_PALETTE.filter(
            item =>
              item.program === key && !item.hidden && ids.includes(item.id)
          ),
        }
      }
    )
  }

  onLayerPaste(layerToCopy: CopyLayerState): void {
    const layerType = layerToCopy.layer.meta_data.sage_layer_type
    const lossSetLayers: LossSet[] | LayerRef[] =
      layerToCopy.parentGrossPortfolioID ===
      this.editorState.parentGrossPortfolioID
        ? layerToCopy.layer.lossSetLayers
        : this.editorState.lossSetLayers.layers
    const layer = this.layerService.buildCopiedLayer(
      layerToCopy.layer,
      lossSetLayers,
      this.currentCurrency,
      this.structureId
    )
    if (layerType === layerIds.catFhcf) {
      this.store.dispatch(addFHCFLayer({ layer }))
    } else if (layerType === layerIds.noncatRisk) {
      this.store.dispatch(addRiskLayer({ layer }))
    } else if (layerType === layerIds.noncatIndxl) {
      this.store.dispatch(addIndexedLayer({ layer }))
    } else if (
      layerType === layerIds.noncatSwing ||
      layerType === layerIds.ahlSwing
    ) {
      this.store.dispatch(addSwingLayer({ layer }))
    } else if (
      layerType === layerIds.catMultisection ||
      layerType === layerIds.noncatMultisection
    ) {
      const {contractOccurrenceLimit, rol} = layerToCopy.layer.physicalLayer.meta_data
      layer.physicalLayer.meta_data.contractOccurrenceLimit =
        contractOccurrenceLimit
      layer.physicalLayer.meta_data.rol = rol
      this.store.dispatch(addLayer({ layer }))
    } else {
      this.store.dispatch(addLayer({ layer }))
    }
  }

  onLayerAdded(event: LayerDropEvent): void {
    new Promise<void>((resolve, reject) => {
      if (event.item.id === 'cat_ilw') {
        const dialogRef = this.dialog.open(AudienceLayerSelectDialogComponent, {
          disableClose: true,
        })
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            const layerItem = LAYER_PALETTE.find(x => x.id === result)
            if (layerItem) {
              event.item = layerItem
              resolve()
              return
            }
          }
          reject()
          return
        })
      } else {
        resolve()
      }
    })
      .then(() => this.addLayer(event))
      .catch(error => {
        if (error) {
          console.log(error)
        }
      })
  }

  getLayerDefaultParticipation(
    event: LayerDropEvent,
    paletteLayerAggFeeder: boolean,
    paletteLayerFHCF: boolean,
    paletteLayerRisk: boolean
  ): number {
    let participation = -0.25
    if (paletteLayerAggFeeder) {
      participation = 0.25
    } else if (paletteLayerFHCF) {
      participation = -0.9
    } else if (paletteLayerRisk) {
      participation = 1.0
    } else {
      participation = -0.25
    }
    if (event.item.type === 'td') {
      participation = Math.abs(participation)
    }
    return participation
  }

  addLayer(event: LayerDropEvent): void {
    const logicalIDTemp = generateTemporaryId()
    const currency = this.currentCurrency
    const lossSetLayers: LayerRef[] = this.layerService.getLossLayers(
      event.lossSetLayers,
      event.item.id,
      event.item.subtype
    )
    const paletteLayerAggFeeder =
      this.layerService.isPaletteLayerAggFeeder(event)
    const paletteLayerRisk = this.layerService.isPaletteLayerRisk(event.item.id)
    const paletteLayerFHCF = this.layerService.isPaletteLayerFHCF(event.item.id)
    let layer: Layer = {
      id: logicalIDTemp,
      meta_data: {
        client: event.portfolioMetadata.client || '',
        perspective: event.portfolioMetadata.perspective || '',
        rol_type:
          event.item.subtype !== 'feeder' &&
          (event.item.id === layerIds.catAg ||
            event.item.id === layerIds.noncatAg ||
            event.item.id === layerIds.ahlAg)
            ? 'agg'
            : 'occ',
        sage_layer_type: event.item.id,
        year: event.portfolioMetadata.year || '',
        sage_layer_subtype: event.item.subtype,
        structureID: event.currentStructureID,
        layerName: 'New Layer',
        isFHCFFinal: event.item.id === layerIds.catFhcf,
      },
      layerRefs:
        event.item.subtype !== 'feeder' &&
        (event.item.id === layerIds.catAg ||
          event.item.id === layerIds.noncatAg ||
          event.item.id === layerIds.ahlAg)
          ? [
              // tslint:disable-next-line: no-non-null-assertion
              event.layers.find(l => isLayerAggFeeder(l.layer))!.layer.id,
            ]
          : [],
      lossSetFilter: '',
      lossSetLayers,
      physicalLayer: {
        logicalLayerID: logicalIDTemp,
        id: generateTemporaryId().replace('.', ''),
        type: 'Generic',
        attachment: {
          value: 0,
          currency,
        },
        limit: {
          value:
            event.item.subtype !== 'feeder' &&
            (event.item.id === layerIds.catAg ||
              event.item.id === layerIds.noncatAg ||
              event.item.id === layerIds.ahlAg)
              ? analyzereConstants.unlimitedValue
              : 0,
          currency,
        },
        participation: this.getLayerDefaultParticipation(
          event,
          paletteLayerAggFeeder,
          paletteLayerFHCF,
          paletteLayerRisk
        ),
        premium: {
          value: 1,
          currency,
        },
        // @ts-ignore
        fees: [],
        aggregateLimit: {
          value: 0,
          currency,
        },
        aggregateAttachment: {
          value: 0,
          currency,
        },
        description: 'New Layer',
        meta_data: {
          client: event.portfolioMetadata.client || '',
          perspective: event.portfolioMetadata.perspective || '',
          rol_type:
            event.item.subtype !== 'feeder' &&
            (event.item.id === layerIds.catAg ||
              event.item.id === layerIds.noncatAg ||
              event.item.id === layerIds.ahlAg)
              ? 'agg'
              : 'occ',
          rol: 0.0,
          sage_layer_type: event.item.id,
          year: event.portfolioMetadata.year || '',
          sage_layer_subtype: event.item.subtype,
          isFHCFFinal: event.item.id === layerIds.catFhcf,
          payout: 0,
          pricingcurve_is_default: true,
        },
        // @ts-ignore
        reinstatements: [],
        franchise: {
          value: 0,
          currency,
        },
        trigger: {
          value: 0,
          currency,
        },
        payout: {
          value: 10000000,
          currency,
        },
        inception_date: null,
        expiry_date: null,
        xcoord:
          event.event.distance.x +
          // @ts-ignore
          event.event.item._dragRef._pickupPositionOnPage.x -
          // @ts-ignore
          event.event.item._dragRef._pickupPositionInElement.x,
        ycoord:
          event.event.distance.y +
          // @ts-ignore
          event.event.item._dragRef._pickupPositionOnPage.y +
          // @ts-ignore
          event.event.item.element.nativeElement.offsetHeight -
          // @ts-ignore
          event.event.item._dragRef._pickupPositionInElement.y,
      },
      sharedLayerID: '',
      viewMetrics: {
        // @ts-ignore
        error: null,
        loading: false,
        // @ts-ignore
        metrics: null,
        // @ts-ignore
        rss: null,
      },
    }
    if (event.item.id === layerIds.ilwBin) {
      const ilwPhysicalLayer: PhysicalLayer = {
        logicalLayerID: logicalIDTemp,
        id: generateTemporaryId().replace('.', ''),
        type: 'IndustryLossWarranty',
        attachment: {
          value: 0,
          currency,
        },
        limit: {
          value: 0,
          currency,
        },
        participation: -1,
        premium: {
          value: 1,
          currency,
        },
        fees: [],
        aggregateLimit: {
          value: 0,
          currency,
        },
        aggregateAttachment: {
          value: 0,
          currency,
        },
        description: 'New Layer',
        meta_data: {
          client: event.portfolioMetadata.client || '',
          perspective: event.portfolioMetadata.perspective || '',
          rol_type: 'occ',
          rol: 0.0,
          sage_layer_type: event.item.id,
          year: event.portfolioMetadata.year || '',
          sage_layer_subtype: event.item.subtype,
          isFHCFFinal: false,
          pricingcurve_is_default: true,
        },
        reinstatements: [],
        franchise: {
          value: 0,
          currency,
        },
        payout: {
          value: 0,
          currency,
        },
        trigger: {
          value: 0,
          currency,
        },
        nth: 1,
        inception_date: null,
        expiry_date: null,
        xcoord:
          event.event.distance.x +
          // @ts-ignore
          event.event.item._dragRef._pickupPositionOnPage.x -
          // @ts-ignore
          event.event.item._dragRef._pickupPositionInElement.x,
        ycoord:
          event.event.distance.y +
          // @ts-ignore
          event.event.item._dragRef._pickupPositionOnPage.y +
          // @ts-ignore
          event.event.item.element.nativeElement.offsetHeight -
          // @ts-ignore
          event.event.item._dragRef._pickupPositionInElement.y,
      }
      layer = {
        ...layer,
        physicalLayer: ilwPhysicalLayer,
      }
    }
    if (event.item.id === layerIds.catFhcf) {
      this.store.dispatch(addFHCFLayer({ layer }))
    } else if (event.item.id === layerIds.noncatRisk) {
      this.store.dispatch(addRiskLayer({ layer }))
    } else if (event.item.id === layerIds.noncatIndxl) {
      this.store.dispatch(addIndexedLayer({ layer }))
    } else if (
      event.item.id === layerIds.noncatSwing ||
      event.item.id === layerIds.ahlSwing
    ) {
      this.store.dispatch(addSwingLayer({ layer }))
    } else if (
      event.item.id === layerIds.catMultisection ||
      event.item.id === layerIds.noncatMultisection
    ) {
      const meta = layer.physicalLayer.meta_data as Metadata
      meta.contractOccurrenceLimit = analyzereConstants.unlimitedValue
      this.store.dispatch(addLayer({ layer }))
    } else {
      this.store.dispatch(addLayer({ layer }))
    }
  }

  trackByFn(index: number, item: any): number {
    return item ? item.id : index
  }
}
