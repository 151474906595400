import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { map, Observable } from 'rxjs'
import { AppState } from './../../../core/store/index'
import { CreditService } from './../../../api/credit/credit.service'
import { BackendService } from '../../../api/backend/backend.service'
import {
  CreditCalculationStructure,
  CreditStructure,
} from '../../model/credit-structure.model'
import { UserReinsurer } from './../../../api/model/backend.model'
import { CreditPortfolio } from '../../model/credit-portfolio.model'
import { selectCurrentPortfolio } from '../../store/credit.selectors'

@Component({
  selector: 'app-credit-create-submission-dialog-container',
  templateUrl: './credit-create-submission-dialog.container.html',
})
export class CreditCreateSubmissionDialogContainerComponent implements OnInit {
  calculationStructure$: Observable<CreditCalculationStructure | undefined>
  userReinsurers$: Observable<UserReinsurer[]>
  currentPortfolio$: Observable<CreditPortfolio | undefined>
  constructor(
    private store: Store<AppState>,
    private creditService: CreditService,
    private backendService: BackendService,
    private dialogRef: MatDialogRef<CreditCreateSubmissionDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CreditStructure
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return
    }
    this.calculationStructure$ = this.creditService
      .getCalculationStructureById(this.data.credit_calculation_structure_id)
      .pipe(map(res => res.data))
    this.userReinsurers$ = this.backendService
      .getAllReinsurerUsers()
      .pipe(map(res => res.data))
    this.currentPortfolio$ = this.store.pipe(select(selectCurrentPortfolio))
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
