<app-overview-card
  title="Metrics"
  imagePath="assets/overview-cards/metrics.png"
  caption="Calibrate Reinsurance Markets & Key Risk Transfer Financial Metrics"
>
  There are dozens of key metrics to consider when deciding which structure
  optimizes risk transfer. SAGE allows users to select those metrics most
  important to them and features them throughout the decision-making
  process.
</app-overview-card>
<app-overview-card
  title="Design"
  imagePath="assets/overview-cards/edit.png"
  caption="Build Unique Reinsurance Structures"
>
  SAGE creates a unique, interactive & dynamic interface. Users can
  intuitively change complicated structures by straightforward mouse
  (computer interface) or finger (tablet-based) interaction. Users can
  create structuring options and receive real time financial output.
</app-overview-card>
<app-overview-card
  title="Group"
  imagePath="assets/overview-cards/group.png"
  caption="Use Higher-order Structuring Capabilities"
>
  Reinsurance has many valuable qualities that are harder to visualize and
  access. SAGE allows users to consider more sophisticated features using
  its unique visual interface. SAGE “democratizes” analytics by allowing any
  user to interact with any form of risk transfer structure.
</app-overview-card>
<app-overview-card
  title="Compare"
  imagePath="assets/overview-cards/compare.png"
  caption="Compare & Evaluate Risk Transfer Options"
>
  Why was I presented with these 4 options? What are the distinguishing
  features that I should be aware of? SAGE’s Compare capability allows users
  to analyze structuring options in an easy-to-understand, transparent
  format accessing user-selected, customized financial metrics.
</app-overview-card>
