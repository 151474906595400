<app-sort-table
  *ngIf="rows"
  [columnDefs]="subjectivityTrackingColumnDef"
  [rows]="rows"
  [subjectivityOptions]="subjectivityOptions"
  (editedSubjectivity)="editedSubjectivity($event)"
  [isTracking]="true"
  [isSubjectivity]="true"
  (deleteSubjectivity)="onDeleteSubjectivity($event)"
  (handleCustomClickFunction)="onReinsurerClick($event.tpRef, $event.reinsurerName)"
>
</app-sort-table>
