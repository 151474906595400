import { EfficiencyMetricsResult } from '../../model/efficiency-metrics.mode'
import { createReducer, on, Action } from '@ngrx/store'
import {
  fetchEfficiencyMetrics,
  fetchEfficiencyMetricsFailure,
  fetchEfficiencyMetricsSuccess,
} from './efficiency-metrics.actions'

export interface State extends EfficiencyMetricsResult {
  loading: boolean
  error: string | null
}

export const initialState: State = {
  loading: false,
  error: null,
  bcarCostOfCapital: 0,
  spCostOfCapital: 0,
  ecmCostOfCapital: 0,
  custom: 0,
  reinsuranceEfficiency: 0,
}

const efficiencyMetricsReducer = createReducer(
  initialState,
  on(fetchEfficiencyMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchEfficiencyMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(
    fetchEfficiencyMetricsSuccess,
    (
      state,
      {
        bcarCostOfCapital,
        spCostOfCapital,
        ecmCostOfCapital,
        custom,
        reinsuranceEfficiency,
      }
    ) => ({
      ...state,
      loading: false,
      error: null,
      bcarCostOfCapital,
      spCostOfCapital,
      ecmCostOfCapital,
      custom,
      reinsuranceEfficiency,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return efficiencyMetricsReducer(state, action)
}
