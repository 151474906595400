import { MatDialogRef } from '@angular/material/dialog'
import { TriggerPositionDialogOptions } from '@shared/trigger-position-dialog.component'
import { ClientYear } from './../../core/model/client.model'
import { Study } from './../../core/model/study.model'
import { CreditTierStructuresDialogComponent } from './credit-tier-structures-dialog/credit-tier-structures-dialog.component'

export interface ProgramsYearsFilter {
  years: ClientYear[]
  programs: Study[]
}

export interface CreditTierStructuresDialogConfig
  extends TriggerPositionDialogOptions {
  panelClass?: string
  ignoreAllExternalClicks?: boolean
}

export type CreditTierStructuresDialogRef = MatDialogRef<
  CreditTierStructuresDialogComponent,
  CreditTierStructuresDialogConfig
>

export const DEFAULT_FILTER: ProgramsYearsFilter = {
  years: [],
  programs: [],
}
export const CREDIT_TIER_DIALOG = 'app-credit-tier-structures-dialog'
export const CREDIT_TIER_DIALOG_HEIGHT = '70vh'
export const CREDIT_TIER_DIALOG_WIDTH = 'calc(100vw - 2 * var(--inset-big))'
