import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { OptimizationContainerComponent } from './optimization.container/optimization.container'

const OPTIMIZATION_CLASS = 'optimization-dialog'
const DEFAULT_HEIGHT = '100vh'
const DEFAULT_WIDTH = '80vw'

@Injectable()
export class OptimizationService {
  constructor(private dialog: MatDialog) {}

  open() {
    const existing = this.getDialogRef()
    if (existing) {
      return
    } else {
      this.dialog.open(OptimizationContainerComponent, {
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
        disableClose: true,
        id: OPTIMIZATION_CLASS,
        panelClass: OPTIMIZATION_CLASS,
      })
    }
  }

  resize(width: string, height: string) {
    const existing = this.getDialogRef()
    if (existing) {
      existing.updateSize(width, height)
    }
  }

  resizeDefault() {
    const existing = this.getDialogRef()
    if (existing) {
      existing.updateSize(DEFAULT_WIDTH, DEFAULT_HEIGHT)
    }
  }

  private getDialogRef():
    | MatDialogRef<OptimizationContainerComponent>
    | undefined {
    return this.dialog.getDialogById(OPTIMIZATION_CLASS)
  }
}
