<h1 mat-dialog-title>{{ reinsurer.name }}</h1>
<div mat-dialog-content class="wrapper">
  <ng-container *ngIf="showExistingOBO()">
    <h3>Select Existing Relationship</h3>
    <table class="tidy-auto-table">
      <thead>
        <tr>
          <th class="th-generic"></th>
          <th class="th-generic">Sequence<br>Number</th>
          <th class="th-generic">Reinsurer Name</th>
          <th class="th-generic">Reinsurer<br>Percentage</th>
          <th class="th-generic">Dates</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let relation of agencyDetails; let i = index">
          <td>
            <div class="div-cb" *ngIf="
                    i === 0 ||
                    (i > 0 &&
                      relation.agencySeqNumber !==
                        agencyDetails[i - 1].agencySeqNumber)
                  ">
              <mat-checkbox class="custom-frame" [checked]="relationshipSelected(relation)"
                (change)="relationshipChange(relation, $event)"></mat-checkbox>
            </div>
          </td>
          <td>
            <span>{{ relation.agencySeqNumber }}</span>
          </td>
          <td>
            <span>{{ relation.memberName }}</span>
          </td>
          <td>
            <span class="float-r">{{ relation.memberPct }}%</span>
          </td>
          <td>
            <span class="float-r">{{getFormattedDate(relation.effectiveDate)}}-{{getFormattedDate(relation.expiryDate)}}</span> 
          </td>          
        </tr>
      </tbody>
    </table>
    <br />
  </ng-container>
  <ng-container *ngIf="!allowCreateNew">
    <h4>(To create a new Lloyd's relationship, contact client services)</h4>
  </ng-container>
  <ng-container *ngIf="allowCreateNew">
    <br />
    <div class="divider">-----OR-----</div>
    <br />

    <h3>Create New Relationship</h3>
    <table class="tidy-auto-table">
      <thead>
        <tr>
          <th class="th-generic"></th>
          <th class="th-generic col-span-2">
            <span>Reinsurer Name</span>
            <span>Reinsurer Percentage</span>
          </th>
          <th class="th-generic"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of newAgencyDetail; let i = index">
          <td>
            <div class="div-cb" *ngIf="i === 0">
              <mat-checkbox class="custom-frame" [checked]="relationshipOBOSelected()"
                (change)="relationshipOBOChange()"></mat-checkbox>
            </div>
          </td>
          <td class="new-relation">
            <div class="div-content">
              <input matInput class="value1" [(ngModel)]="item.memberName" [matAutocomplete]="auto"
                (input)="onAutocompleteChange($event.target.value)" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredReinsurers" [value]="option.name">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <span>@</span>
              <input class="value2" type="number" [(ngModel)]="item.memberPct" [disabled]="isDisabled" />
            </div>
          </td>
          <td>
            <i *ngIf="!isDisabled || i !== 0" class="material-icons" matTooltip="Click to remove relation."
              matTooltipPosition="above" [ngStyle]="{ 'font-size': '18px', cursor: 'pointer' }"
              (click)="removeRelation(i)">
              remove_circle_outline
            </i>
            <i *ngIf="!isDisabled && isLast(i)" class="material-icons" matTooltip="Click to add relation."
              matTooltipPosition="above" [ngStyle]="{ 'font-size': '18px', cursor: 'pointer' }" (click)="addRelation()">
              add_circle_outline
            </i>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <br />
</div>
<div class="error-msg">
  <label>{{ errorMsg }}</label>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="!activeAction; else actionRunning">
    <button appButton accent mat-dialog-close="2" cdkFocusInitial [disabled]="isSaveDisable()" (click)="onSaveClick()">
      Save
    </button>
  </ng-container>
  <ng-template #actionRunning>
    <app-active-action [value]="activeAction"></app-active-action>
  </ng-template>
  <button appButton primary mat-dialog-close="2" cdkFocusInitial>
    Cancel
  </button>
  <div *ngIf="invalidDatesSelected" class="outside-approval-date-message">Inception date is outside effective dates. Client services will be notified to extend/approve. </div>
</div>