import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { PricingCurveGraphSettingsDialogPayload } from '../../dialog/pricing-curve-dialog.model'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { updateGraphSettings } from 'src/app/pricingcurve/store/pricing-curve.actions'
import { CREDIT_CURVE_X_DEFINITIONS, CREDIT_CURVE_Y_DEFINITIONS, PricingCurveAxisDefinition } from 'src/app/pricingcurve/model/pricing-curve.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-graph-settings-dialog',
  styleUrls: ['pricing-curve-graph-settings-dialog.component.scss'],
  templateUrl: 'pricing-curve-graph-settings-dialog.component.html',
})
export class PricingCurveGraphSettingsDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<PricingCurveGraphSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PricingCurveGraphSettingsDialogPayload,
    private store: Store<AppState>
  ) {}

  selectedXDef: PricingCurveAxisDefinition
  selectedYDef: PricingCurveAxisDefinition

  xAxisOptions = CREDIT_CURVE_X_DEFINITIONS
  yAxisOptions = CREDIT_CURVE_Y_DEFINITIONS

  ngOnInit(): void {
    this.selectedXDef = this.data.currentGraphSettings.xAxisDefinition
    this.selectedYDef = this.data.currentGraphSettings.yAxisDefinition
  }

  destroyDialog(): void {
    this.matDialogRef.close()
  }

  confirmSettings(): void {
    this.store.dispatch(
      updateGraphSettings({
        graphSettings: {
          xAxisDefinition: this.selectedXDef,
          yAxisDefinition: this.selectedYDef,
        },
      })
    )
    this.destroyDialog()
  }
}
