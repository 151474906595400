import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lensPath, partition, set, uniq, values } from 'ramda'
import {
  forkJoin,
  Observable,
  of,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs'
import { map, mergeMap, switchMap } from 'rxjs/operators'
import { Layer } from 'src/app/analysis/model/layers.model'
import { LossSetLayer } from 'src/app/analysis/model/loss-set-layers.model'
import { environment } from '../../../environments/environment'
import {
  convertLayersToRef,
  getLayerOrRefID,
} from '../../analysis/model/layers.util'
import { extractPortfolioSetID } from '../../analysis/model/portfolio-set-id.util'
import {
  PortfolioSet,
  PortfolioSetID,
  PortfolioSetIDAndName,
} from '../../analysis/model/portfolio-set.model'
import {
  GrouperSavedChanges,
  SavedChanges,
} from '../../analysis/store/grouper/grouper.actions'
import { ProgramGroupMemberEntity } from '../../analysis/store/grouper/program-group-member/program-group-member.reducer'
import {
  ProgramGroup,
  ProgramGroupEntityWithProgramPortfolioIDs,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import { ApplicationError, errorPayload } from '../../error/model/error'
import { rejectNil } from '@shared/util/operators'
import {
  LogicalPortfolioLayer,
  Portfolio,
  Ref,
} from '../analyzere/analyzere.model'
import { AnalyzreService } from '../analyzere/analyzre.service'
import { ApiResponse, MaybeData, MaybeError } from '../model/api.model'
import {
  ProgramGroupMemberResponse,
  ProgramGroupResponse,
} from '../model/backend.model'
import {
  catchAndHandleError,
  mapResponse,
  mapToMaybeData,
  mergeApiResponses,
  mergeMapResponse,
} from '../util'
import {
  convertProgramGroupMemberToRequest,
  convertProgramGroupToRequest,
} from './program-group.converter'

export interface SavedUpdate<T extends { id: string }> {
  prevID?: string
  data: T
  removed?: boolean
  error?: ApplicationError
}

export interface SavedUpdates {
  groupUpdates: SavedUpdate<ProgramGroup>[]
  memberUpdates: SavedUpdate<ProgramGroupMember>[]
}

@Injectable({
  providedIn: 'root',
})
export class ProgramGroupService {
  constructor(private http: HttpClient, private analyzere: AnalyzreService) {}

  save(
    clientID: string | null,
    groups: ProgramGroupEntityWithProgramPortfolioIDs[],
    members: ProgramGroupMemberEntity[]
  ): ApiResponse<GrouperSavedChanges> {
    if (!clientID) {
      return of({
        error: errorPayload('Cannot save: No client selected'),
      })
    }

    return this.addOrUpdateManyGroups(clientID, groups).pipe(
      // Update the members to save that reference a newly create group
      // with the groups' new IDs
      map(groupUpdates => {
        const membersWithNewGroupIDs = this.updateMembersWithNewGroupIDs(
          groupUpdates,
          members
        )
        return [groupUpdates, membersWithNewGroupIDs] as const
      }),
      // Next, update or create all members
      switchMap(([groupUpdates, membersWithNewGroupIDs]) =>
        this.changeManyMembers(clientID, membersWithNewGroupIDs).pipe(
          mergeMap(memberUpdates =>
            // Finally, remove any deleted groups
            this.removeManyGroups(groups).pipe(
              // Append the removed groups to the group updates
              map(groupRemovals => ({
                groupUpdates: [...groupUpdates, ...groupRemovals],
                memberUpdates,
              }))
            )
          )
        )
      ),
      this.mapToSavedChanges(clientID),
      mapToMaybeData(),
      catchAndHandleError('Save Grouper')
    )
  }

  addAndRemovePortfolioLayers(
    groups: ProgramGroup[],
    addLayers: (Layer | LossSetLayer)[],
    removeIDs: string[]
  ): ApiResponse<PortfolioSet<Portfolio>[]> {
    if (groups.length === 0) {
      return of({ data: [] })
    }
    return forkJoin(
      groups.map(g =>
        this.getPortfolioSet(g).pipe(
          mergeMapResponse(portfolioSet =>
            this.updatePortfolioSetLayers(portfolioSet, addLayers, removeIDs)
          )
        )
      )
    ).pipe(mergeApiResponses())
  }

  updateGroups(
    clientID: string,
    groups: ProgramGroupEntityWithProgramPortfolioIDs[]
  ): Observable<SavedUpdate<ProgramGroup>[]> {
    return forkJoin(groups.map(g => this.updateGroup(clientID, g)))
  }

  private addOrUpdateManyGroups(
    clientID: string,
    groups: ProgramGroupEntityWithProgramPortfolioIDs[]
  ): Observable<SavedUpdate<ProgramGroup>[]> {
    const groupsToAddOrUpdate = groups.filter(g => !g.deleted)
    return groupsToAddOrUpdate.length === 0
      ? of([])
      : forkJoin(
          groupsToAddOrUpdate.map(g =>
            g.new
              ? this.createGroup(clientID, g)
              : this.updateGroup(clientID, g)
          )
        )
  }

  private removeManyGroups(
    groups: ProgramGroupEntityWithProgramPortfolioIDs[]
  ): Observable<SavedUpdate<ProgramGroup>[]> {
    const groupsToRemove = groups.filter(g => g.deleted)
    return groupsToRemove.length === 0
      ? of([])
      : forkJoin(groupsToRemove.map(g => this.removeGroup(g)))
  }

  private changeManyMembers(
    clientID: string,
    members: ProgramGroupMemberEntity[]
  ): Observable<SavedUpdate<ProgramGroupMember>[]> {
    return members.length === 0
      ? of([])
      : forkJoin(
          members.map(m =>
            m.deleted
              ? this.removeMember(m)
              : m.new
              ? this.createMember(clientID, m)
              : this.updateMember(clientID, m)
          )
        )
  }

  private createGroup(
    clientID: string,
    group: ProgramGroupEntityWithProgramPortfolioIDs
  ): Observable<SavedUpdate<ProgramGroup>> {
    const url = `${environment.internalApi.base}/programgroups`
    return this.upsertGroupPortfolios(group).pipe(
      mergeMap(({ error, data }) => {
        if (error) {
          return of({ data: group.programGroup, error })
        }
        // tslint:disable-next-line: no-non-null-assertion
        const ids = data!
        const body = convertProgramGroupToRequest(
          clientID,
          group.programGroup,
          ids
        )
        return this.http.post<ProgramGroupResponse>(url, body).pipe(
          map(res => res.id),
          map(nextID => {
            const updatedGroup = {
              ...group,
              programGroup: { ...group.programGroup, ...ids },
            }
            return [updatedGroup, nextID] as const
          }),
          map(([updatedGroup, nextID]) =>
            this.createGroupUpdate(updatedGroup, nextID)
          )
        )
      })
    )
  }

  private updateGroup(
    clientID: string,
    group: ProgramGroupEntityWithProgramPortfolioIDs
  ): Observable<SavedUpdate<ProgramGroup>> {
    const id = group.programGroup.id
    // Groups' analysis profile ID should all be the same since the group
    // page does not allow added items w/ different analysis profile IDs
    const url = `${environment.internalApi.base}/programgroups/${id}`

    return this.upsertGroupPortfolios(group).pipe(
      mergeMap(({ error, data }) => {
        if (error || !group.dirty) {
          // Don't update backend if error or group's not dirty (i.e. the
          // group's portfolio layers needed updates, but nothing in backend)
          return of({ data: group.programGroup, error })
        }
        // tslint:disable-next-line: no-non-null-assertion
        const ids = data!
        const body = convertProgramGroupToRequest(
          clientID,
          group.programGroup,
          ids
        )
        // Update the group's portfolio IDs
        return this.http.put(url, body).pipe(
          map(() => ({
            ...group,
            programGroup: { ...group.programGroup, ...ids },
          })),
          map(updatedGroup => this.createGroupUpdate(updatedGroup))
        )
      })
    )
  }

  private removeGroup(
    group: ProgramGroupEntityWithProgramPortfolioIDs
  ): Observable<SavedUpdate<ProgramGroup>> {
    const id = group.programGroup.id
    const url = `${environment.internalApi.base}/programgroups/${id}`
    return this.http
      .delete(url)
      .pipe(map(() => ({ data: group.programGroup, removed: true })))
  }

  private createMember(
    clientID: string,
    member: ProgramGroupMemberEntity
  ): Observable<SavedUpdate<ProgramGroupMember>> {
    const url = `${environment.internalApi.base}/programgroupmembers`
    const body = convertProgramGroupMemberToRequest(
      clientID,
      member.programGroupMember
    )
    return this.http.post<ProgramGroupMemberResponse>(url, body).pipe(
      map(res => res.id),
      map(nextID => this.createMemberUpdate(member, nextID))
    )
  }

  private updateMember(
    clientID: string,
    member: ProgramGroupMemberEntity
  ): Observable<SavedUpdate<ProgramGroupMember>> {
    const id = member.programGroupMember.id
    const url = `${environment.internalApi.base}/programgroupmembers/${id}`
    const body = convertProgramGroupMemberToRequest(
      clientID,
      member.programGroupMember
    )
    return this.http
      .put(url, body)
      .pipe(map(() => this.createMemberUpdate(member)))
  }

  private removeMember(
    member: ProgramGroupMemberEntity
  ): Observable<SavedUpdate<ProgramGroupMember>> {
    const id = member.programGroupMember.id
    const url = `${environment.internalApi.base}/programgroupmembers/${id}`
    return this.http
      .delete(url)
      .pipe(map(() => ({ data: member.programGroupMember, removed: true })))
  }

  private combinePortfolioSetLayers(
    portfolioSets: Array<PortfolioSet<Portfolio>>
  ): PortfolioSet<Portfolio> {
    const portfolios = portfolioSets.reduce((acc, it, i) => {
      if (i === 0) {
        return acc
      }
      acc.ceded.layers = [
        ...(acc.ceded.layers as LogicalPortfolioLayer[]),
        ...(it.ceded.layers as LogicalPortfolioLayer[]),
      ]
      acc.gross.layers = [
        ...(acc.gross.layers as LogicalPortfolioLayer[]),
        ...(it.gross.layers as LogicalPortfolioLayer[]),
      ]
      acc.net.layers = [
        ...(acc.net.layers as LogicalPortfolioLayer[]),
        ...(it.net.layers as LogicalPortfolioLayer[]),
      ]
      return acc
    }, portfolioSets[0])
    portfolios.ceded.layers = convertLayersToRef(portfolios.ceded.layers)
    portfolios.gross.layers = convertLayersToRef(portfolios.gross.layers)
    portfolios.net.layers = convertLayersToRef(portfolios.net.layers)
    return portfolios
  }

  private upsertGroupPortfolios(
    group: ProgramGroupEntityWithProgramPortfolioIDs
  ): ApiResponse<PortfolioSetIDAndName> {
    const uniqueAnalysisProfileIDs = uniq(
      group.programPortfolioIDs.map(it => it.analysisProfileID)
    )
    if (uniqueAnalysisProfileIDs.length !== 1) {
      return of({
        error: errorPayload(
          'Cannot group portfolios with different analysis IDs.'
        ),
      })
    }
    if (group.programPortfolioIDs.length === 0) {
      return of({
        error: errorPayload('Cannot group portfolios with no portfolios.'),
      })
    }

    return forkJoin(
      group.programPortfolioIDs.map(it => this.getPortfolioSet(it))
    ).pipe(
      mergeApiResponses(),
      mapResponse(portfolioSets =>
        this.combinePortfolioSetLayers(portfolioSets)
      ),
      mergeMapResponse(portfolioSet =>
        (group.new
          ? this.addPortfolioSet(portfolioSet)
          : this.updateGroupPortfolioSet(group, portfolioSet)
        ).pipe(
          mapResponse(({ ceded, gross, net }) => ({
            analysisProfileID: uniqueAnalysisProfileIDs[0],
            cededPortfolioID: ceded.id,
            grossPortfolioID: gross.id,
            netPortfolioID: net.id,
            name: net.name,
          }))
        )
      )
    )
  }

  private getPortfolioSet(
    portfolioSetID: Partial<PortfolioSetID>
  ): ApiResponse<PortfolioSet<Portfolio>> {
    const portfolioIDs = rejectNil([
      portfolioSetID.cededPortfolioID,
      portfolioSetID.grossPortfolioID,
      portfolioSetID.netPortfolioID,
    ])
    return forkJoin(
      portfolioIDs.map(id => this.analyzere.fetchPortfolio(id))
    ).pipe(this.handlePortfoliosResponse())
  }

  private addPortfolioSet(
    portfolioSet: PortfolioSet<Portfolio>
  ): ApiResponse<PortfolioSet<Portfolio>> {
    return forkJoin(
      values(portfolioSet).map(p => this.analyzere.postPortfolio(p))
    ).pipe(this.handlePortfoliosResponse())
  }

  private updatePortfolioSetLayers(
    portfolioSet: PortfolioSet<Portfolio>,
    addLayers: (Layer | LossSetLayer)[] = [],
    removeIDs: string[] = []
  ): ApiResponse<PortfolioSet<Portfolio>> {
    return forkJoin(
      values(portfolioSet).map(p =>
        this.analyzere.updatePortfolioLayers(
          p.id,
          this.getUpdatedPortfolioLayerIDs(p, addLayers, removeIDs)
        )
      )
    ).pipe(this.handlePortfoliosResponse())
  }

  private updateGroupPortfolioSet(
    group: ProgramGroupEntityWithProgramPortfolioIDs,
    portfolioSet: PortfolioSet<Portfolio>
  ): ApiResponse<PortfolioSet<Portfolio>> {
    const ids = extractPortfolioSetID(group.programGroup)
    if (!ids) {
      return of({
        error: errorPayload('Cannot update, group has no portfolio IDs.'),
      })
    }
    return forkJoin([
      this.analyzere.updatePortfolioLayers(
        ids.cededPortfolioID,
        this.getUpdatedPortfolioLayerIDs(portfolioSet.ceded)
      ),
      this.analyzere.updatePortfolioLayers(
        ids.grossPortfolioID,
        this.getUpdatedPortfolioLayerIDs(portfolioSet.gross)
      ),
      this.analyzere.updatePortfolioLayers(
        ids.netPortfolioID,
        this.getUpdatedPortfolioLayerIDs(portfolioSet.net)
      ),
    ]).pipe(this.handlePortfoliosResponse())
  }

  private getUpdatedPortfolioLayerIDs(
    portfolio: Portfolio,
    addLayers: (Layer | LossSetLayer)[] = [],
    removeIDs: string[] = []
  ): string[] {
    let addIDs: string[] = []
    // If gross portfolio, do not add ceded layers
    if (portfolio.meta_data.perspective === 'gross') {
      addIDs = addLayers
        .filter(l => l.meta_data.perspective !== 'ceded')
        .map(l => l.id)
      // If ceded portfolio, do not add gross layers
    } else if (portfolio.meta_data.perspective === 'ceded') {
      addIDs = addLayers
        .filter(l => l.meta_data.perspective !== 'gross')
        .map(l => l.id)
    } else {
      addIDs = addLayers.map(l => l.id)
    }

    const prevLayers = portfolio.layers as Array<LogicalPortfolioLayer | Ref>
    const layerIDs = [...prevLayers.map(getLayerOrRefID), ...addIDs]
    return uniq(layerIDs).filter(id => !removeIDs.includes(id))
  }

  private handlePortfoliosResponse(): UnaryFunction<
    Observable<Array<MaybeData<Portfolio> & MaybeError>>,
    ApiResponse<PortfolioSet<Portfolio>>
  > {
    return pipe(
      mergeApiResponses(),
      mapResponse(([ceded, gross, net]) => ({ ceded, gross, net }))
    )
  }

  private createGroupUpdate(
    groupEntity: { programGroup: ProgramGroup },
    nextID?: number
  ): SavedUpdate<ProgramGroup> {
    const group = { ...groupEntity.programGroup }
    let prevID: string | undefined
    if (nextID) {
      prevID = group.id
      group.id = String(nextID)
    }
    return { data: group, prevID }
  }

  private createMemberUpdate(
    memberEntity: ProgramGroupMemberEntity,
    nextID?: number
  ): SavedUpdate<ProgramGroupMember> {
    const member = { ...memberEntity.programGroupMember }
    let prevID: string | undefined
    if (nextID) {
      prevID = member.id
      member.id = String(nextID)
    }
    return { data: member, prevID }
  }

  private updateMembersWithNewGroupIDs(
    groupUpdates: SavedUpdate<ProgramGroup>[],
    members: ProgramGroupMemberEntity[]
  ): ProgramGroupMemberEntity[] {
    // Convert from an array to a dictionary
    const memberMap = members.reduce(
      (acc, m) => ({ ...acc, [m.programGroupMember.id]: m }),
      {} as Record<string, ProgramGroupMemberEntity>
    )

    const updatedMemberMap = groupUpdates.reduce((outer, groupUpdate) => {
      if (!groupUpdate.prevID) {
        return outer
      }
      return members.reduce((acc, m) => {
        // tslint:disable-next-line: no-non-null-assertion
        const prevID = groupUpdate.prevID!
        const memberID = m.programGroupMember.id
        const nextID = groupUpdate.data.id || ''

        const path = [memberID, 'programGroupMember']
        if (m.programGroupMember.parentGroupID === prevID) {
          return set(lensPath([...path, 'parentGroupID']), nextID, acc)
        } else if (m.programGroupMember.programGroupID === prevID) {
          return set(lensPath([...path, 'programGroupID']), nextID, acc)
        }
        return acc
      }, outer)
    }, memberMap)

    // Convert back to an array from a dictionary
    return Object.keys(updatedMemberMap).map(key => updatedMemberMap[key])
  }

  private getSavedChanges<T extends { id: string }>(
    memberUpdates: SavedUpdate<T>[]
  ): SavedChanges<T> {
    const [created, rest] = partition(u => u.prevID != null, memberUpdates)
    const [removed, updated] = partition(u => u.removed === true, rest)

    const idChanges = created.map(u => ({
      prev: u.prevID as string,
      next: u.data.id,
    }))
    const createdRemoveIDs = created.map(u => u.prevID as string)
    const removeIDs = removed.map(u => u.data.id)

    return {
      create: created.map(u => u.data),
      update: updated.map(u => ({ id: u.data.id, changes: u.data })),
      remove: [...createdRemoveIDs, ...removeIDs],
      idChanges,
    }
  }

  private mapToSavedChanges(
    clientID: string
  ): OperatorFunction<SavedUpdates, GrouperSavedChanges> {
    return map(({ groupUpdates, memberUpdates }) => ({
      clientID,
      groups: this.getSavedChanges(groupUpdates),
      members: this.getSavedChanges(memberUpdates),
    }))
  }
}
