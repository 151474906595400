import { GeoDatum } from './graphing-geo.model'

export const US_STATE_POSTAL_CODES: readonly (readonly [string, string])[] = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'District of Columbia'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
  ['MP', 'Northern Mariana Islands'],
  ['PR', 'Puerto Rico'],
  ['AS', 'American Samoa'],
  ['GU', 'Guam'],
  ['VI', 'U.S. Virgin Islands'],
  ['da', 'Canada'],
  ['en', 'Alien'],
]

const date = new Date(2021, 0, 1)

export const MOCK_US_STATE_VOTE_DATA: GeoDatum[] = [
  { state: 'AK', value: 72220000, date },
  { state: 'AL', value: 314258000, date },
  { state: 'AR', value: 211802000, date },
  { state: 'AZ', value: 868224000, date },
  { state: 'CA', value: 1234042000, date },
  { state: 'CO', value: 507158800, date },
  { state: 'CT', value: 331280000, date },
  { state: 'DC', value: 141540000, date },
  { state: 'DE', value: 86984000, date },
  { state: 'FL', value: -749898000, date },
  { state: 'GA', value: 922233000, date },
  { state: 'GU', value: 4870000, date },
  { state: 'HI', value: 129149000, date },
  { state: 'IA', value: 292421000, date },
  { state: 'ID', value: 220109000, date },
  { state: 'IL', value: 867832000, date },
  { state: 'IN', value: 630253000, date },
  { state: 'KS', value: 312599000, date },
  { state: 'KY', value: 275940000, date },
  { state: 'LA', value: 302133000, date },
  { state: 'MA', value: 801250000, date },
  { state: 'MD', value: 750952000, date },
  { state: 'ME', value: 98691000, date },
  { state: 'MI', value: 907417000, date },
  { state: 'MN', value: 952273000, date },
  { state: 'MO', value: 624366000, date },
  { state: 'MS', value: 90088000, date },
  { state: 'MT', value: 114727000, date },
  { state: 'NC', value: 956320000, date },
  { state: 'ND', value: -145967000, date },
  { state: 'NE', value: 136045000, date },
  { state: 'NH', value: 177657000, date },
  { state: 'NJ', value: 775880000, date },
  { state: 'NM', value: 105550000, date },
  { state: 'NV', value: 320952000, date },
  { state: 'NY', value: 737888000, date },
  { state: 'OH', value: 936934000, date },
  { state: 'OK', value: 202984000, date },
  { state: 'OR', value: 677971000, date },
  { state: 'PA', value: 917228000, date },
  { state: 'PR', value: 483000, date },
  { state: 'RI', value: -66272000, date },
  { state: 'SC', value: 459072000, date },
  { state: 'SD', value: -55967000, date },
  { state: 'TN', value: 564685000, date },
  { state: 'TX', value: 702823000, date },
  { state: 'UT', value: 671866000, date },
  { state: 'VA', value: 809829000, date },
  { state: 'VT', value: 74949000, date },
  { state: 'WA', value: 793574000, date },
  { state: 'WI', value: 605015000, date },
  { state: 'WV', value: 67009000, date },
  { state: 'WY', value: 52301000, date },
]

export const MOCK_US_STATE_WAGE_DATA: GeoDatum[] = [
  { state: 'Washington', value: 1.12, date },
  { state: 'Oregon', value: 1.19, date },
  { state: 'California', value: 1.22, date },
  { state: 'Alaska', value: 1.1, date },
  { state: 'Guam', value: 1.26, date },
  { state: 'Nevada', value: 1.14, date },
  { state: 'Idaho', value: 1.04, date },
  { state: 'Utah', value: 1.13, date },
  { state: 'Arizona', value: 0.79, date },
  { state: 'Hawaii', value: 1.03, date },
  { state: 'Montana', value: 1.07, date },
  { state: 'Wyoming', value: 1.13, date },
  { state: 'Colorado', value: 0.93, date },
  { state: 'New Mexico', value: 1.09, date },
  { state: 'North Dakota', value: 1.04, date },
  { state: 'South Dakota', value: 1, date },
  { state: 'Nebraska', value: 1.19, date },
  { state: 'Kansas', value: 1.14, date },
  { state: 'Oklahoma', value: 1.09, date },
  { state: 'Texas', value: 1.07, date },
  { state: 'Minnesota', value: 1.02, date },
  { state: 'Iowa', value: 1.06, date },
  { state: 'Missouri', value: 1, date },
  { state: 'Arkansas', value: 1.07, date },
  { state: 'Louisiana', value: 1.09, date },
  { state: 'Wisconsin', value: 1.06, date },
  { state: 'Illinois', value: 1.1, date },
  { state: 'Mississippi', value: 1.07, date },
  { state: 'Michigan', value: 1.12, date },
  { state: 'Indiana', value: 1.04, date },
  { state: 'Ohio', value: 1.24, date },
  { state: 'Kentucky', value: 1.14, date },
  { state: 'Tennessee', value: 1.08, date },
  { state: 'Alabama', value: 1.06, date },
  { state: 'Maine', value: 1.05, date },
  { state: 'New York', value: 1.23, date },
  { state: 'Pennsylvania', value: 1.21, date },
  { state: 'West Virginia', value: 1.06, date },
  { state: 'Virginia', value: 1.2, date },
  { state: 'North Carolina', value: 0.91, date },
  { state: 'South Carolina', value: 1.12, date },
  { state: 'Georgia', value: 1.18, date },
  { state: 'Florida', value: 1.13, date },
  { state: 'Vermont', value: 1.08, date },
  { state: 'New Hampshire', value: 1, date },
  { state: 'Massachusetts', value: 1.09, date },
  { state: 'Rhode Island', value: 1.11, date },
  { state: 'Connecticut', value: 1.15, date },
  { state: 'New Jersey', value: 1.07, date },
  { state: 'Maryland', value: 1.06, date },
  { state: 'Delaware', value: 1.05, date },
  { state: 'District of Columbia', value: 0.75, date },
  { state: 'Puerto Rico', value: 1.06, date },
  { state: 'Virgin Islands', value: 1.03, date },
]

export const MOCK_US_STATE_UNEMPLOYMENT_DATA: GeoDatum[] = [
  { state: 'Vermont', value: 2.1, date },
  { state: 'North Dakota', value: 2.4, date },
  { state: 'Iowa', value: 2.5, date },
  { state: 'New Hampshire', value: 2.5, date },
  { state: 'Hawaii', value: 2.8, date },
  { state: 'Utah', value: 2.8, date },
  // { state: 'Colorado', data: 2.9, date },
  { state: 'Idaho', value: 2.9, date },
  { state: 'Massachusetts', value: 2.9, date },
  { state: 'South Dakota', value: 2.9, date },
  { state: 'Virginia', value: 2.9, date },
  { state: 'Maine', value: 3, date },
  { state: 'Wisconsin', value: 3, date },
  { state: 'Nebraska', value: 3.1, date },
  { state: 'Oklahoma', value: 3.2, date },
  { state: 'Alabama', value: 3.3, date },
  { state: 'Delaware', value: 3.3, date },
  { state: 'Florida', value: 3.3, date },
  { state: 'Kansas', value: 3.3, date },
  { state: 'Missouri', value: 3.3, date },
  { state: 'New Jersey', value: 3.3, date },
  { state: 'Arkansas', value: 3.4, date },
  { state: 'Indiana', value: 3.4, date },
  { state: 'Minnesota', value: 3.4, date },
  { state: 'Montana', value: 3.4, date },
  { state: 'South Carolina', value: 3.4, date },
  { state: 'Texas', value: 3.4, date },
  { state: 'Rhode Island', value: 3.5, date },
  { state: 'Tennessee', value: 3.5, date },
  { state: 'Connecticut', value: 3.6, date },
  { state: 'Georgia', value: 3.6, date },
  // { state: 'Wyoming', data: 3.6, date },
  { state: 'Maryland', value: 3.8, date },
  { state: 'Pennsylvania', value: 3.9, date },
  { state: 'Ohio', value: 4, date },
  { state: 'Oregon', value: 4, date },
  { state: 'California', value: 4.1, date },
  { state: 'Nevada', value: 4.1, date },
  { state: 'Illinois', value: 4.2, date },
  { state: 'North Carolina', value: 4.2, date },
  { state: 'Kentucky', value: 4.3, date },
  { state: 'Louisiana', value: 4.3, date },
  { state: 'Michigan', value: 4.3, date },
  { state: 'Washington', value: 4.6, date },
  { state: 'West Virginia', value: 4.7, date },
  { state: 'Arizona', value: 4.9, date },
  { state: 'New Mexico', value: 4.9, date },
  { state: 'Mississippi', value: 5.1, date },
  { state: 'District of Columbia', value: 5.6, date },
  { state: 'New York', value: 6.1, date },
  { state: 'Alaska', value: 6.3, date },
]

export const MOCK_US_STATE_EMISSIONS: GeoDatum[] = [
  { state: 'Alaska', value: 911.985, date },
  { state: 'Alabama', value: 868.298, date },
  { state: 'Arkansas', value: 1219.144, date },
  { state: 'Arizona', value: 972.245, date },
  { state: 'California', value: 422.029, date },
  { state: 'Colorado', value: 1371.37, date },
  { state: 'Connecticut', value: 509.483, date },
  { state: 'District of Columbia', value: 440.07, date },
  { state: 'Delaware', value: 900.386, date },
  { state: 'Florida', value: 947.724, date },
  { state: 'Georgia', value: 931.858, date },
  { state: 'Hawaii', value: 1524.783, date },
  { state: 'Iowa', value: 1077.362, date },
  { state: 'Idaho', value: 160.695, date },
  { state: 'Illinois', value: 818.543, date },
  { state: 'Indiana', value: 1748.524, date },
  { state: 'Kansas', value: 996.6, date },
  { state: 'Kentucky', value: 1835.661, date },
  { state: 'Louisiana', value: 839.429, date },
  { state: 'Massachusetts', value: 733.843, date },
  { state: 'Maryland', value: 841.164, date },
  { state: 'Maine', value: 268.221, date },
  { state: 'Michigan', value: 1115.764, date },
  { state: 'Minnesota', value: 1003.107, date },
  { state: 'Missouri', value: 1712.893, date },
  { state: 'Mississippi', value: 919.517, date },
  { state: 'Montana', value: 1165.931, date },
  { state: 'North Carolina', value: 804.074, date },
  { state: 'North Dakota', value: 1516.85, date },
  { state: 'Nebraska', value: 1417.525, date },
  { state: 'New Hampshire', value: 305.815, date },
  { state: 'New Jersey', value: 502.141, date },
  { state: 'New Mexico', value: 1340.42, date },
  { state: 'Nevada', value: 746.62, date },
  { state: 'New York', value: 418.683, date },
  { state: 'Ohio', value: 1329.846, date },
  { state: 'Oklahoma', value: 893.383, date },
  { state: 'Oregon', value: 314.16, date },
  { state: 'Pennsylvania', value: 788.844, date },
  { state: 'Rhode Island', value: 868.776, date },
  { state: 'South Carolina', value: 634.563, date },
  { state: 'South Dakota', value: 519.987, date },
  { state: 'Tennessee', value: 748.355, date },
  { state: 'Texas', value: 983.662, date },
  { state: 'Utah', value: 1609.35, date },
  { state: 'Virginia', value: 742.844, date },
  { state: 'Vermont', value: 57.507, date },
  { state: 'Washington', value: 200.042, date },
  { state: 'Wisconsin', value: 1396.254, date },
  { state: 'West Virginia', value: 1961.459, date },
  { state: 'Wyoming', value: 2063.758, date },
]

export function unabbreviateState(stateAbbreviation: string): string {
  const stateMap = new Map(US_STATE_POSTAL_CODES)
  if (stateMap.has(stateAbbreviation)) {
    return stateMap.get(stateAbbreviation) ?? ''
  } else {
    return 'United States'
  }
}

export function abbreviateState(stateName: string): string {
  const reverseKeyMap = new Map()
  const stateMap = new Map(US_STATE_POSTAL_CODES)
  for (const [key, value] of stateMap) {
    reverseKeyMap.set(value, key)
  }
  if (reverseKeyMap.has(stateName)) {
    return reverseKeyMap.get(stateName) ?? ''
  } else {
    return 'US'
  }
}
