import { Injectable, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ActionsSubject, Store } from '@ngrx/store'
import { bind, groupBy, keys, mapObjIndexed, toString } from 'ramda'
import { ReplaySubject } from 'rxjs'
import {
  RiskRefWithSections,
  RiskSectionWithMarkets,
} from 'src/app/api/model/backend.model'
import { BrokerStructuresContainerComponent } from '../broker/broker-structures/broker-structures.container'
import { Program } from 'src/app/core/model/program.model'
import { AppState } from 'src/app/core/store'
import {
  AutoBuildStructureCreator,
  LayerSection,
  StructureSection,
  StructuresSection,
} from 'src/app/tier/auto-build-structure-creator'

@Injectable({
  providedIn: 'root',
})
export class AutoBuildService implements OnDestroy {
  // the broker will be set before autobuild can be selected. This wlil hold
  // the current value until the creator is ready for it.
  private broker$ = new ReplaySubject<
    BrokerStructuresContainerComponent | undefined
  >()

  private defaultCessionPct: number

  constructor(
    private actionsSubject$: ActionsSubject,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.broker$.complete()
  }

  // The broker component will be destroyed and recreated every time the UI
  // returns to the structure select page. So the broker instance needs to be
  // updated each time so the creator object can select each new structure and
  // move to the design page.
  setBroker(broker: BrokerStructuresContainerComponent | undefined) {
    this.broker$.next(broker)
  }

  // ----------------------------------------------------------------

  createStructuresFromOTSections(
    sections: RiskSectionWithMarkets[],
    defaultCessionPct: number
  ): StructuresSection {
    // Gather the sections by structure. There may be one or more structures and
    // each structure may have one or more sections.
    const sectionsByStructure = groupBy(
      rs => toString(rs.selectedStructure).padStart(3, '0'),
      sections
    )

    this.defaultCessionPct = defaultCessionPct

    return mapObjIndexed(
      bind(this.createStructureFromOTSections, this),
      sectionsByStructure
    )
  }

  createStructureFromOTSections(
    sections: RiskSectionWithMarkets[]
  ): StructureSection {
    const key = (rs: RiskSectionWithMarkets) =>
      `${rs.riskRef}_${rs.occurrenceLimit}x${rs.occurrenceAttachment}`

    // Gather the sections by layer. There may be one or more layers and each
    // layer may have one or more sections.
    const sectionsByLayer = groupBy(key, sections)

    return mapObjIndexed(
      //    ^?
      bind(this.createLayersFromOTSections, this),
      sectionsByLayer
    )
  }

  createLayersFromOTSections(sections: RiskSectionWithMarkets[]): LayerSection {
    let totalPct = 0.0

    if (this.defaultCessionPct && this.defaultCessionPct > 0) {
      totalPct = this.defaultCessionPct
    } else {
      // Add up the total signed percent from the layer's sections.
      totalPct = sections.reduce(
        (total, rs) => total + (rs.totalSignedPct ?? 0),
        0
      )
    }

    const sectionLargestSignedPercent =
      this.getSectionWithLargestSignedPercentage(sections)

    return { section: sectionLargestSignedPercent, totalPct }
  }

  getSectionWithLargestSignedPercentage(sections: RiskSectionWithMarkets[]) {
    let sectionWithLargestSignedPercentage
    let largestSignedPct = 0
    for (const section of sections) {
      let sumOfSignedPercentages = 0
      for (const market of section.markets) {
        sumOfSignedPercentages += market.signedPct ?? 0
      }
      if (sumOfSignedPercentages > largestSignedPct) {
        largestSignedPct = sumOfSignedPercentages
        sectionWithLargestSignedPercentage = section
      }
    }
    return sectionWithLargestSignedPercentage
  }

  groupSectionsPerRiskRef(
    sections: RiskSectionWithMarkets[]
  ): RiskRefWithSections[] {
    return Object.values(
      sections.reduce((acc, section) => {
        const { riskRef, sectionNarrative, sectionNum } = section
        const key = `${riskRef}-${sectionNarrative}`
        if (!acc[key]) {
          acc[key] = { riskRef, sectionNarrative, sectionNums: [] }
        }
        acc[key].sectionNums.push(sectionNum)
        return acc
      }, {} as { [key: string]: RiskRefWithSections })
    )
  }

  // ----------------------------------------------------------------

  createStructures(
    client: string,
    year: string,
    program: string,
    libreTemplate: Program,
    structuresSection: StructuresSection,
    autobuildID: string,
    riskRefSections: RiskRefWithSections[]
  ) {
    const creator = new AutoBuildStructureCreator(
      this.actionsSubject$,
      this.store,
      this.dialog,
      this.router,
      client,
      year,
      program,
      libreTemplate,
      keys(structuresSection).map(k => structuresSection[k]),
      this.broker$,
      autobuildID,
      riskRefSections
    )

    // Create the first structure. The method will keep checking for more
    // structures to create as each one finishes, until all the structures
    // are complete.
    creator.createNextStructure()
  }
}
