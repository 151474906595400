import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../core/store'
import { LossSetLayer } from '../model/loss-set-layers.model'
import * as fromAnalysisSelectors from '../store/analysis.selectors'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { selectProgramsByID } from '../../core/store/program/program.selectors'
import { Program } from 'src/app/core/model/program.model'
import { selectStudies } from 'src/app/core/store/clients.selectors'
import { StudyResponse } from 'src/app/api/model/backend.model'
import {
  DeleteInuranceFromDesign,
  InuranceRelationship,
} from '../model/inurance.model'
import { deleteInuranceFromDesign } from '../store/grouper/inurance/grouper-inurance.actions'
import { ProgramGroup } from '../store/grouper/program-group.model'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import { openAddInuranceDialogForEdit } from '../store/analysis.actions'
import { Layer } from '../model/layers.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-inurance-layers-container',
  template: `
    <app-inurance-layers
      [selectedLayer]="selectedLayer$ | async"
      [layerChoices]="layerChoices$ | async"
      [programsByID]="programsByID$ | async"
      [studies]="studies$ | async"
      [layers]="layers$ | async"
      [groups]="groups$ | async"
      [inuranceLoading]="inuranceLoading$ | async"
      (deleteInuranceClick)="onDeleteClick($event)"
      (editInuranceClick)="onEditClick($event)"
    ></app-inurance-layers>
  `,
})
export class InuranceLayersContainerComponent implements OnInit {
  layerChoices$: Observable<LossSetLayer[]>
  loading$: Observable<boolean>
  error$: Observable<string | null>
  selectedLayer$: Observable<LayerState | null>
  programsByID$: Observable<Dictionary<Program>>
  studies$: Observable<StudyResponse[] | null>
  layers$: Observable<LayerState[]>
  groups$: Observable<Dictionary<ProgramGroup>>
  inuranceLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.layerChoices$ = this.store.pipe(
      select(fromAnalysisSelectors.selectParentGrossLossSetLayers)
    )
    this.loading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayersLoading)
    )

    this.error$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayersError)
    )

    this.selectedLayer$ = this.store.pipe(
      select(fromAnalysisSelectors.selectCurrentCededLayer)
    )

    this.layers$ = this.store.pipe(
      select(fromAnalysisSelectors.selectCededLayers)
    )

    this.studies$ = this.store.pipe(select(selectStudies))

    this.programsByID$ = this.store.pipe(select(selectProgramsByID))

    this.groups$ = this.store.pipe(select(selectProgramGroupsByID))

    this.inuranceLoading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectInuranceLoadingInDesign)
    )
  }

  onDeleteClick($event: DeleteInuranceFromDesign): void {
    this.store.dispatch(deleteInuranceFromDesign($event))
  }

  onEditClick($event: {
    currentLayer: Layer
    currentProgram: Program
    layer: LossSetLayer | Layer | undefined
    structure: Program | undefined
    group: ProgramGroup | undefined
    relationship: InuranceRelationship
  }): void {
    this.store.dispatch(
      openAddInuranceDialogForEdit({
        currentProgram: $event.currentProgram,
        currentLayer: $event.currentLayer,
        relationship: $event.relationship,
        layer: $event.layer,
        structure: $event.structure,
        group: $event.group,
        isAdd: false,
        isEdit: true,
      })
    )
  }
}
