import { createAction, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import {
  StratificationAxisField,
  StratificationChartType,
  StratificationFilters,
  StratificationResults,
} from '../../model/credit-stratification.model'

export enum StratificationActions {
  InitStratification = '[Credit Stratifications] Initialization',
  SetSelectedChartType = '[Credit Stratifications] Set selected chart type',
  SetColumnType = '[Credit Stratifications] Set column axis type',
  SetSelectedState = '[Credit Stratifications] Set selected state',
  SetStateSelectionBulk = '[Credit Stratifications] Set state selection bulk',
  SetRowType = '[Credit Stratifications] Set row axis type',
  FetchStratificationResults = '[Credit Stratifications] Fetch stratification results',
  FetchStratificationResultsSuccess = '[Credit Stratifications] Fetch stratification results success',
  FetchStratificationResultsFailure = '[Credit Stratifications] Fetch stratification results failure',
  FetchStratificationFilters = '[Credit Stratifications] Fetch stratification filters',
  FetchStratificationFiltersSuccess = '[Credit Stratifications] Fetch stratification filters success',
  FetchStratificationFiltersFailure = '[Credit Stratifications] Fetch stratification filters failure',
  SetActiveFilters= '[Credit Stratifications] Set active filters',
}

export const SetSelectedChartType = createAction(
  StratificationActions.SetSelectedChartType,
  props<{ selectedType: StratificationChartType }>()
)

export const SetColumnType = createAction(
  StratificationActions.SetColumnType,
  props<{ selectedField: StratificationAxisField }>()
)

export const SetRowType = createAction(
  StratificationActions.SetRowType,
  props<{ selectedField: StratificationAxisField }>()
)

export const InitStratifications = createAction(
  StratificationActions.InitStratification
)

export const FetchStratificationResults = createAction(
  StratificationActions.FetchStratificationResults,
)

export const FetchStratificationResultsSuccess = createAction(
  StratificationActions.FetchStratificationResultsSuccess,
  props<{ results: StratificationResults }>()
)

export const FetchStratificationResultsFailure = createAction(
  StratificationActions.FetchStratificationResultsFailure,
  props<{ error: ApplicationError }>()
)

export const SetStateSelection = createAction(
  StratificationActions.SetSelectedState,
  props<{ state: string }>()
)

export const SetBulkStateSelection = createAction(
  StratificationActions.SetStateSelectionBulk,
  props<{ states: string[] }>()
)

export const SetActiveFilters = createAction(
  StratificationActions.SetActiveFilters,
  props<{ filters: StratificationFilters }>()
)

export const FetchStratificationFilters = createAction(
  StratificationActions.FetchStratificationFilters
)

export const FetchStratificationFiltersSuccess = createAction(
  StratificationActions.FetchStratificationFiltersSuccess,
  props<{ results: StratificationFilters }>()
)

export const FetchStratificationFiltersFailure = createAction(
  StratificationActions.FetchStratificationFiltersFailure,
  props<{ error: ApplicationError }>()
)
