import { select, Selection } from 'd3-selection'
import { zoom as d3Zoom, zoomIdentity, zoomTransform } from 'd3-zoom'
import { GraphingScale, ScalarScale } from './coord'

export interface ZoomMultiAxisDecoratorOptions {
  min: number
  max: number
  xScale: GraphingScale
  yScale: GraphingScale
  xScaleOriginal: GraphingScale
  yScaleOriginal: GraphingScale
  y2Scale?: GraphingScale
  y2ScaleOriginal?: GraphingScale
  disableMainZoom?: boolean
  disableXZoom?: boolean
  disableYZoom?: boolean
  mainZoomYOnly?: boolean
  onZoom?: () => void
}

// Setting zoom to just '1' initially does not actually update
// the underlying D3 zoom values.
const ONE = 1.0000000001

/**
 * Applying the decorator causes the d3fc left axis to be treated
 * as a right axis because the axis datums are changed from a
 * simple 'left' value (which the d3fc code uses to apply a SVG viewBox)
 * with the chart data. This fix corrects the viewBox of a 'left
 * positioned axis given the selected chart container element.
 */
const fixLeftAxis = (
  sel: Selection<HTMLElement, unknown, null, undefined>
): void => {
  sel.select('d3fc-group .y-axis').on('measure.y-axis', event => {
    const { width, height } = event.detail
    select(event.target)
      .select('svg')
      .attr('viewBox', `${-width} 0 ${width} ${height}`)
  })
}

function createZoomMultiAxisDecorator(options: ZoomMultiAxisDecoratorOptions) {
  const { min, max, disableMainZoom, mainZoomYOnly } = options
  const { xScale, xScaleOriginal, disableXZoom } = options
  const { yScale, yScaleOriginal, disableYZoom } = options
  const { y2Scale, y2ScaleOriginal } = options

  const extents: [number, number] = [min, max]
  let xExtents = extents
  if (mainZoomYOnly) {
    xExtents = [1, extents[1]]
  }

  const zoom = d3Zoom().scaleExtent(extents)
  const zoomX = d3Zoom().scaleExtent(xExtents)
  const zoomY = d3Zoom().scaleExtent(extents)
  const zoomY2 = d3Zoom().scaleExtent(extents)

  function decorate(chart: any, measureCallback?: (state: any) => void) {
    const plotAreaNode = chart.select('.svg-plot-area').node()
    const xAxisNode = chart.select('.x-axis').node()
    const yAxisNode = chart.select('.y-axis').node()
    const y2AxisNode = chart.select('.y2-axis').node()

    const getTransforms = () => {
      return {
        plot: plotAreaNode.__zoom,
        xAxis: xAxisNode.__zoom,
        yAxis: yAxisNode.__zoom,
        y2Axis: y2AxisNode?.__zoom,
      }
    }

    const applyTransform = (_yOnly = false) => {
      // Update the scales based on current zoom
      if (!_yOnly) {
        xScale.domain(
          zoomTransform(xAxisNode)
            .rescaleX(xScaleOriginal as ScalarScale)
            .domain()
        )
      }

      yScale.domain(
        zoomTransform(yAxisNode)
          .rescaleY(yScaleOriginal as ScalarScale)
          .domain()
      )

      if (y2Scale && y2ScaleOriginal && y2AxisNode) {
        y2Scale.domain(
          zoomTransform(y2AxisNode)
            .rescaleY(y2ScaleOriginal as ScalarScale)
            .domain()
        )
      }

      options.onZoom?.()
      chart.node().requestRedraw()
    }

    zoom.on('zoom', () => {
      if (disableMainZoom) {
        return
      }

      // Compute how much the user has zoomed since the last event
      const factor =
        (plotAreaNode.__zoom.k - plotAreaNode.__zoomOld.k) /
        plotAreaNode.__zoomOld.k
      plotAreaNode.__zoomOld = plotAreaNode.__zoom

      // Apply scale to the x & y axis, maintaining their aspect ratio
      if (!mainZoomYOnly) {
        xAxisNode.__zoom.k = xAxisNode.__zoom.k * (1 + factor)
      }

      yAxisNode.__zoom.k = yAxisNode.__zoom.k * (1 + factor)
      if (y2Scale && y2ScaleOriginal) {
        y2AxisNode.__zoom.k = y2AxisNode.__zoom.k * (1 + factor)
      }

      const transform = zoomTransform(plotAreaNode)

      // Apply transform
      if (!mainZoomYOnly) {
        xAxisNode.__zoom.x = transform.x
      }

      yAxisNode.__zoom.y = transform.y
      if (y2Scale && y2ScaleOriginal) {
        y2AxisNode.__zoom.y = transform.y
      }

      applyTransform(mainZoomYOnly)
    })

    zoomX.on('zoom', () => {
      if (!disableXZoom) {
        plotAreaNode.__zoom.x = zoomTransform(xAxisNode).x
        applyTransform()
      }
    })

    zoomY.on('zoom', () => {
      if (!disableYZoom) {
        const transform = zoomTransform(yAxisNode)
        plotAreaNode.__zoom.y = transform.y

        if (y2Scale && y2ScaleOriginal && y2AxisNode?.__zoom) {
          y2AxisNode.__zoom = transform
        }

        applyTransform()
      }
    })

    zoomY2.on('zoom', () => {
      if (!disableYZoom) {
        const transform = zoomTransform(y2AxisNode)
        plotAreaNode.__zoom.y = transform.y

        if (yAxisNode) {
          yAxisNode.__zoom = transform
        }

        applyTransform()
      }
    })

    chart
      .enter()
      .select('d3fc-svg.plot-area')
      .on('measure.range', (event: any) => {
        // {TO: JB} Upon research using any IS ACTUALLY the only thing to use here based on existing documentation....
        xScaleOriginal.range([0, event.detail.width])
        yScaleOriginal.range([event.detail.height, 0])
        if (y2Scale && y2ScaleOriginal) {
          y2ScaleOriginal?.range([event.detail.height, 0])
        }
        if (measureCallback) {
          const transforms = getTransforms()
          measureCallback(transforms)
        }
      })
      .call(zoom)

    plotAreaNode.__zoom = zoomIdentity.scale(ONE)
    plotAreaNode.__zoomOld = plotAreaNode.__zoom

    xAxisNode.__zoom = zoomIdentity.scale(ONE)
    yAxisNode.__zoom = zoomIdentity.scale(ONE)
    if (y2AxisNode && y2Scale && y2ScaleOriginal) {
      y2AxisNode.__zoom = zoomIdentity.scale(ONE)
    }

    // Cannot use enter selection as this pulls data through
    const yAxis = chart.selectAll('.y-axis')
    yAxis.call(zoomY)
    // Explicitly scale the axes to start which solves the issue where the
    // main zoom does not follow the mouse position initially.
    // Also solves issue where the zoom jumps when a chart is refreshed.
    // TODO: fix zoom scaleTo issue
    zoomY.scaleTo(yAxis, ONE)

    if (y2Scale && y2ScaleOriginal) {
      const y2Axis = chart.selectAll('.y2-axis')
      y2Axis.call(zoomY2)
      // TODO: fix zoom scaleTo issue
      zoomY2.scaleTo(y2Axis, ONE)
    }

    const xAxis = chart.selectAll('.x-axis')
    xAxis.call(zoomX)
    // TODO: fix zoom scaleTo issue
    zoomX.scaleTo(xAxis, ONE)
  }

  return { decorate, fixLeftAxis, zoom }
}

export type ZoomMultiAxisDecorator = ReturnType<
  typeof createZoomMultiAxisDecorator
>

export default createZoomMultiAxisDecorator
