import { Reinstatement } from 'src/app/api/analyzere/analyzere.model'
import {
  SavedPricingCurveEntry,
  TechnicalFactors,
} from '../../pricingcurve/model/pricing-curve.model'
import {
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
  layerIds,
} from '../model/layer-palette.model'
import { LayerViewValues } from '../model/layer-view'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import {
  DefaultSavedCurveEntry,
  GLOBAL_DEFAULT_CURVE_ENTRY,
  LayerEntry,
  PRICING_CURVE_LAYER_TYPE_DEFAULT,
  SavedLayerTypeEntry,
} from './technical-premium.model'
import { Layer } from '../model/layers.model'
import { OptimizationCandidateLayer } from '../optimization/optimization.model'

export function isCurveWeightTotalValid(
  curves: DefaultSavedCurveEntry[]
): boolean {
  // Multiply by 100 and check ceiling to avoid floating point errors
  return (
    Math.ceil(
      curves.reduce((acc, curve) => acc + curve.percentage, 0) * 100
    ) === 100
  )
}

export function createDefaultCurveObjectForCurve(
  curve: SavedPricingCurveEntry,
  weight: number = 1
): DefaultSavedCurveEntry {
  return {
    id: curve.id,
    pc_name: curve.pc_name,
    percentage: weight,
    value: 0,
  }
}

export function getPricingCurvesForLayer(
  layer: Layer
): DefaultSavedCurveEntry[] {
  const curveString = layer.physicalLayer.meta_data.pricingCurves
  if (curveString) {
    return curveString.split(';').map(string => {
      const values = string.split(',')
      return {
        id: Number(values[0]),
        percentage: Number(values[1]),
        pc_name: values[2],
        value: Number(values[3]),
      }
    })
  } else {
    return [PRICING_CURVE_LAYER_TYPE_DEFAULT]
  }
}

export function layerEntryToString(
  layerEntry: LayerEntry,
  premiumValue?: number
): string | undefined {
  if (
    layerEntry.pricingCurves.length === 1 &&
    layerEntry.pricingCurves[0].id === GLOBAL_DEFAULT_CURVE_ENTRY.id
  ) {
    return
  }
  let pricingCurvesString = ''
  layerEntry.pricingCurves.forEach(pc => {
    const premiumString = !!premiumValue ? premiumValue : pc.value
    pricingCurvesString =
      pricingCurvesString +
      pc.id +
      ',' +
      pc.percentage +
      ',' +
      pc.pc_name +
      ',' +
      premiumString +
      ';'
  })
  pricingCurvesString = pricingCurvesString.substring(
    0,
    pricingCurvesString.length - 1
  )
  return pricingCurvesString
}

export function initDefaults(
  entries: Record<string, LayerEntry> = {}
): Record<string, LayerEntry> {
  const newEntries = { ...entries }
  const keys = Object.keys(newEntries)
  const missingKeys = LAYER_PALETTE.filter(item => !item.hidden).filter(
    item => !keys.includes(item.id)
  )
  missingKeys.map((item, index) => {
    let layerType = item.id
    newEntries[layerType] = {
      id: index,
      name: `${LAYER_PALETTE_PROGRAMS[item.program]} - ${item.name}`,
      layerType,
      pricingCurves: [
        createDefaultCurveObjectForCurve(GLOBAL_DEFAULT_CURVE_ENTRY),
      ],
      hasError: false,
      pricingCurveIds: [-1],
      modified: false,
      saveForOnlyNewLayers: false,
    }
  })

  return newEntries
}

export function getSavedLayerTypeEntriesForLayerEntry(
  entry: LayerEntry,
  studyId: number
): SavedLayerTypeEntry[] {
  return entry.pricingCurves.map(curve => ({
    program_id: studyId,
    // tslint:disable-next-line: no-non-null-assertion
    pricing_curve_id: curve.id!,
    layer_type: entry.layerType,
    weight_percent: curve.percentage,
  }))
}

export function doesLayerUseLayerTypeDefault(layer: Layer): boolean {
  return (
    layer.physicalLayer.meta_data.pricingcurve_is_default ||
    !layer.physicalLayer.meta_data.pricingCurves
  )
}

// we need to revisit this method. We are updating the method for resolving the tech premium loading issue
export function areFieldsPresentForTechnicalPremium(
  layer: Layer,
  values: LayerViewValues,
  reinstatements: Reinstatement[]
): boolean {
  const layerType = layer.meta_data.sage_layer_type
  // QS layers require expected ceded loss to calculate technical premium
  if (
    layerType === layerIds.noncatQs ||
    layerType === layerIds.catQs ||
    layerType === layerIds.ahlQs
  ) {
    return values.expectedCededLossRatio >= 0
    // Ag layers require purePremiumForTP
  } else if (
    layerType === layerIds.noncatAg ||
    layerType === layerIds.catAg ||
    layerType === layerIds.ahlAg
  ) {
    return values.purePremiumForTP >= 0
  } else {
    // 0 Rp relativity will cause a divide by 0 if technical premium is calculated
    return getRprelativityValue(values, reinstatements) !== 0
  }
}

export function calculateTechnicalPremium(
  values: LayerViewValues | OptimizationCandidateLayer,
  reinstatements: Reinstatement[],
  curveEntries: DefaultSavedCurveEntry[],
  savedCurves: SavedPricingCurveEntry[],
  layer: Layer
): number {
  return curveEntries.reduce((acc, curve) => {
    const savedCurve = savedCurves.find(l => l.id === curve.id)
    if (!savedCurve || !savedCurve.techFactors) {
      return acc
    }
    return (
      acc +
      calculateTechnicalPremiumForCurve(
        savedCurve.techFactors,
        values,
        reinstatements,
        layer
      ) *
        curve.percentage
    )
  }, 0)
}

function calculateTechnicalPremiumForCurve(
  techFactors: TechnicalFactors,
  values: LayerViewValues | OptimizationCandidateLayer,
  reinstatements: Reinstatement[],
  layer: Layer
): number {
  const volatilityMetricValue = getVolatilityMetricValue(techFactors, values)
  const rpRelativity = getRprelativityValue(values, reinstatements)
  const layerType = layer.meta_data.sage_layer_type
  if (
    layerType === layerIds.noncatAg ||
    layerType === layerIds.catAg ||
    layerType === layerIds.ahlAg
  ) {
    if (values.aggregateLimit >= 1e21) {
      return (
        (Math.pow(
          values.expectedCededLossRatio / values.aggregateLimit,
          techFactors.expected_loss_power
        ) *
          techFactors.expected_loss_multiplier +
          (volatilityMetricValue / values.aggregateLimit) *
            techFactors.volatility_multiplier +
          techFactors.fixed_cost / values.aggregateLimit) *
        values.aggregateLimit
      )
    } else {
      return (
        Math.max(
          Math.pow(
            values.purePremiumForTP / values.aggregateLimit,
            techFactors.expected_loss_power
          ) *
            techFactors.expected_loss_multiplier +
            (volatilityMetricValue / values.aggregateLimit) *
              techFactors.volatility_multiplier +
            techFactors.fixed_cost / values.aggregateLimit,
          techFactors.minimum_rate_on_line
        ) * values.aggregateLimit
      )
    }
  } else if (
    layerType === layerIds.noncatQs ||
    layerType === layerIds.catQs ||
    layerType === layerIds.ahlQs
  ) {
    const expectedCededLossRatio = Math.abs(values.expectedCededLossRatio)
    return Math.max(
      Math.min(
        1 - techFactors.reinsurer_margin_percentage - expectedCededLossRatio,
        techFactors.max_ceding_commission_percentage
      ),
      0
    )
  } else {
    if (values.occurrenceLimit >= 1e21) {
      return (
        (Math.pow(
          values.expectedCededLossRatio /
            (values.occurrenceLimit * rpRelativity),
          techFactors.expected_loss_power
        ) *
          techFactors.expected_loss_multiplier +
          (volatilityMetricValue / (values.occurrenceLimit * rpRelativity)) *
            techFactors.volatility_multiplier +
          techFactors.fixed_cost / values.occurrenceLimit) *
        values.occurrenceLimit
      )
    } else {
      return (
        Math.max(
          Math.pow(
            values.purePremiumForTP / (values.occurrenceLimit * rpRelativity),
            techFactors.expected_loss_power
          ) *
            techFactors.expected_loss_multiplier +
            (volatilityMetricValue / (values.occurrenceLimit * rpRelativity)) *
              techFactors.volatility_multiplier +
            techFactors.fixed_cost / values.occurrenceLimit,
          techFactors.minimum_rate_on_line
        ) * values.occurrenceLimit
      )
    }
  }
}

function getVolatilityMetricValue(
  { volatility_metric }: TechnicalFactors,
  values: LayerViewValues
): number {
  switch (volatility_metric) {
    case 'Ceded Standard Deviation': {
      return Math.abs(values.standardDeviationExpectedLossForTP)
    }
    case 'Ceded Loss CV': {
      return Math.abs(values.cededLossCVForTP)
    }
    case 'Probability of Attachment': {
      return Math.abs(values.entryProbability)
    }
    default: {
      if (volatility_metric.includes('year TVar')) {
        return Math.abs(values.cededYearTVar)
      } else if (volatility_metric.includes('year Var')) {
        return Math.abs(values.cededYearVar)
      } else {
        return Math.abs(values.standardDeviationExpectedLossForTP)
      }
    }
  }
}

function getRprelativityValue(
  values: LayerViewValues | OptimizationCandidateLayer,
  reinstatements: Reinstatement[]
): number {
  const absDepositPremium = Math.abs(values.depositPremium)
  let rpRelativity = values.expectedCededPremium / values.depositPremium
  if (reinstatements.length === 0 || absDepositPremium === 0) {
    rpRelativity = 1.0
  } else if (absDepositPremium === 1) {
    const sumProductValue = reinstatements.reduce((acc, val) => {
      return val.premium !== undefined ? acc + val.premium : acc
    }, 0)
    rpRelativity = 1 + Number(sumProductValue.toFixed(5))
  }
  return rpRelativity
}
