import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import * as fromClients from '../../core/store/clients.reducer'
import { NavService } from '../../nav.service'

export const {} = fromClients.adapter.getSelectors()

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-broker-route',
  templateUrl: './broker-route.container.html',
})
export class BrokerRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  clientSelect = false

  constructor(
    private route: ActivatedRoute,
    private nav: NavService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.clientSelect = data != null && data.clientSelect === true
      this.cd.markForCheck()
    })
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
