<div class="layer-default-content">
  <div class="header-content">
    <h3 class="header">Curve Selection for Layers</h3>
    <h4 class="header header-error" *ngIf="entryHasError">
      &nbsp;- Weights Must Equal 100%
    </h4>
  </div>
  <div class="layer-default-entries">
    <app-technical-premium-list-entry
      *ngFor="let layer of layerEntries; let i = index"
      [id]="layer.id"
      [title]="layer.name"
      [layerType]="layer.layerType"
      [layerIndex]="i + 1"
      [curveEntries]="layer.pricingCurves"
      [savedCurves]="savedCurves"
      [hasError]="layer.hasError"
      [premium]="premiumMapping[layer.layerId]"
      [isQS]="layer.isQS"
      [layerId]="layer.layerId"
      [physicalLayerId]="layer.physicalLayerId"
      [currencyString]="layer.currency"
      [modified]="layer.modified"
      isLayer="true"
      (updateCurveEntries)="handleCurveOperation($event)"
      (resetEntry)="resetEntryToInitialValue($event)"
      (setEntryToDefault)="setEntryToDefault($event)"
      (dialogClosed)="dialogClosed.emit()"
    ></app-technical-premium-list-entry>
  </div>
</div>
