import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { ColumnsSettings } from '@shared/columns-settings/columns-settings.model'
import {
  DEFAULT_QUOTE_DETAILS_COLUMNS,
  QuoteDetailsData,
} from '../model/quote-details.model'
import {
  DEFAULT_QUOTE_RANKING_COLUMNS,
  QuoteRankingData,
} from '../model/quote-ranking.model'
import { LayerPaletteProgram } from '../../../analysis/model/layer-palette.model'
import { QuoteSummaryContent } from '../model/quote-summary.model'
import {
  QUOTE_TABS,
  DEFAULT_QUOTE_SELECTORS,
  QuoteSelectors,
  QuoteTab,
  QuoteSummaryData,
  DEFAULT_AUDIENCE,
  DEFAULT_LAYER_INTERVALS,
  DEFAULT_TIME_INTERVALS,
  DataCounts,
} from '../model/quote-management-information.model'
import { IControl } from '../../../management-information/store/management-information.reducer'
import { BasicControl } from '../../../management-information/model/management-information.model'
import { QuoteChartAxis, QuoteChartInfo } from '../model/quote-charts.model'
import { getReinstatementsString } from '../utils/quote-details.util'
import {
  updateInterval,
  getLayerTypeNameForLayerType,
} from '../utils/quote-management-information.util'
import {
  getDefaultAudienceLayerNames,
  getAudienceLayerNames,
  getQuoteSelectors,
  getQuoteSummaryContent,
  filterDistinctLayerTypes,
} from '../utils/quote-summary.util'
import * as fromQuoteChartsReducer from './charts/quote-management-information-charts.reducer'
import * as fromQuoteTrendsReducer from './trends/quote-management-information-trends.reducer'
import * as fromQuoteMIActions from './quote-management-information.actions'
import * as fromQuoteMIChartActions from './charts/quote-management-information-charts.actions'
import * as fromQuoteMITrendActions from './trends/quote-management-information-trends.actions'

export interface QuoteManagementChartsTrendsBase {
  groupBys: QuoteChartAxis[]
  metrics: QuoteChartAxis[]
  selectedMetric: QuoteChartAxis
  selectableChartsForTab: QuoteChartInfo[]
  selectableChartsForMetric: QuoteChartInfo[]
  maximizedIndex: number | null
  chartEntityLimit: number | null
  hideMetrics: boolean
  lightChartMode: boolean
}
export interface QuoteManagementState {
  tabs: QuoteTab[]
  activeTab: QuoteTab
  audienceView: LayerPaletteProgram /* cat, noncat, ahl */
  selectors: QuoteSelectors
  layerIntervals: BasicControl[]
  timeIntervals: BasicControl[]
  summaryData?: QuoteSummaryData
  summaryContent: QuoteSummaryContent
  detailsData?: QuoteDetailsData
  detailsColumnsSettings: ColumnsSettings
  rankingData?: QuoteRankingData
  dataCounts?: DataCounts
  rankingColumnsSettings: ColumnsSettings
  charts: fromQuoteChartsReducer.State
  trends: fromQuoteTrendsReducer.State
  lastRefreshDate: string | undefined
}

export const initialState: QuoteManagementState = {
  tabs: QUOTE_TABS,
  activeTab: QUOTE_TABS[0],
  audienceView: DEFAULT_AUDIENCE,
  selectors: DEFAULT_QUOTE_SELECTORS,
  layerIntervals: DEFAULT_LAYER_INTERVALS,
  timeIntervals: DEFAULT_TIME_INTERVALS,
  summaryContent: getQuoteSummaryContent(DEFAULT_AUDIENCE),
  detailsColumnsSettings: {
    visibleColumns: [...DEFAULT_QUOTE_DETAILS_COLUMNS],
    hiddenColumns: [],
    defaultColumns: [...DEFAULT_QUOTE_DETAILS_COLUMNS],
  },
  rankingColumnsSettings: {
    visibleColumns: [...DEFAULT_QUOTE_RANKING_COLUMNS],
    hiddenColumns: [],
    defaultColumns: [...DEFAULT_QUOTE_RANKING_COLUMNS],
  },
  charts: fromQuoteChartsReducer.initialCharts,
  trends: fromQuoteTrendsReducer.initialTrends,
  lastRefreshDate: '',
}

const tabsReducer = createReducer(initialState.tabs)

const activeTabReducer = createReducer(
  initialState.activeTab,
  on(
    fromQuoteMIActions.updateQuoteMIActiveTab,
    (_, { tabClicked }) => tabClicked
  ),
  on(fromQuoteMIActions.fetchQuoteSelectors, state => {
    return { ...state, filtersLoading: true }
  }),
  on(fromQuoteMIActions.fetchQuoteSelectorsSuccess, state => {
    return { ...state, filtersLoading: false }
  }),
  on(
    fromQuoteMIActions.loadInitialData,
    fromQuoteMIActions.fetchQuoteSummaryData,
    fromQuoteMIActions.fetchQuoteDetailsData,
    fromQuoteMIActions.fetchQuoteRankingData,
    fromQuoteMIActions.fetchQuoteDataCounts,
    fromQuoteMIChartActions.fetchAllQuoteChartsData,
    fromQuoteMITrendActions.fetchAllQuoteTrendsData,
    state => {
      return { ...state, dataLoading: true }
    }
  ),
  on(
    fromQuoteMIActions.fetchQuoteSummaryDataSuccess,
    fromQuoteMIActions.fetchQuoteDetailsDataSuccess,
    fromQuoteMIActions.fetchQuoteRankingDataSuccess,
    fromQuoteMIChartActions.fetchAllQuoteChartsDataSuccess,
    fromQuoteMITrendActions.fetchAllQuoteTrendsDataSuccess,
    fromQuoteMIActions.fetchQuoteDataCountsSuccess,
    state => {
      return { ...state, dataLoading: false }
    }
  )
)

const audienceViewReducer = createReducer(
  initialState.audienceView,
  on(
    fromQuoteMIActions.updateQuoteMIAudienceView,
    (_, { audienceView }) => audienceView
  )
)

const selectorsReducer = createReducer(
  initialState.selectors,
  on(
    fromQuoteMIActions.fetchQuoteSelectorsSuccess,
    (state, { selectorsDto, audienceView }) =>
      getQuoteSelectors(state, selectorsDto, audienceView) // update fetched data for selectors under this audience
  ),
  on(fromQuoteMIActions.filtersChanged, (state, { filters }) => {
    if (!filters) {
      return state
    }
    const newState: QuoteSelectors = { ...state }
    const entries: [string, IControl][] = Object.entries(state)
    for (const [selectorKey, selector] of entries) {
      newState[selectorKey as keyof QuoteSelectors] = {
        ...selector,
        selectedValues: filters[selectorKey] ?? [],
      }
    }
    return newState
  }) /* update selectors under this audience */,
  on(fromQuoteMIActions.updateQuoteMIAudienceView, (_, { audienceView }) => {
    return {
      ...DEFAULT_QUOTE_SELECTORS,
      layerType: {
        ...DEFAULT_QUOTE_SELECTORS.layerType,
        selectedValues: getDefaultAudienceLayerNames(audienceView),
        allValues: getAudienceLayerNames(audienceView),
        values: getDefaultAudienceLayerNames(audienceView),
      },
    }
  }) /* all filter values need to be reset on audience change, and layer type values defaulted for new audience */
)

const layerIntervalsReducer = createReducer(
  initialState.layerIntervals,
  on(fromQuoteMIActions.layerIntervalsChanged, (state, { data, isMin }) => {
    return updateInterval(data, state || [], isMin)
  })
)
const timeIntvervalsReducer = createReducer(
  initialState.timeIntervals,
  on(fromQuoteMIActions.timeIntervalsChanged, (state, { data, isMin }) => {
    return updateInterval(data, state || [], isMin)
  })
)

const summaryDataReducer = createReducer(
  initialState.summaryData,
  on(
    fromQuoteMIActions.fetchQuoteSummaryDataSuccess,
    (_, { quoteSummaryData }) => quoteSummaryData
  )
)
const summaryContentReducer = createReducer(
  initialState.summaryContent,
  on(
    fromQuoteMIActions.updateQuoteSummaryContentSuccess,
    (_, { audienceView, selectors, summaryData }) => {
      return getQuoteSummaryContent(
        audienceView,
        summaryData,
        filterDistinctLayerTypes(selectors.layerType.selectedValues) // the layer names of each type that are selected
      )
    }
  )
)

const detailsDataReducer = createReducer(
  initialState.detailsData,
  on(
    fromQuoteMIActions.fetchQuoteDetailsDataSuccess,
    (_, { quoteDetailsData }) => {
      return {
        ...quoteDetailsData,
        rows: [
          ...quoteDetailsData.rows.map(row => {
            return {
              ...row,
              reinstatements: getReinstatementsString(row.reinstatements), // convert reinstatement values to display string (i.e. '1.00' => `1 @ 100%`)
              layerType: getLayerTypeNameForLayerType(row.layerType), // convert layer type to corresponding name (i.e. 'cat_xl' => 'Excess of Loss')
            }
          }),
        ],
      }
    }
  )
)

const detailsColumnsSettingsReducer = createReducer(
  initialState.detailsColumnsSettings,
  on(fromQuoteMIActions.pinColumnChangeDetails, (state, { column }) => {
    const newVisibleColumns = state.visibleColumns.map(col => {
      if (col.id === column.id) {
        return { ...col, pinned: !col.pinned }
      }
      if (col.category === column.id) {
        return {
          ...col,
          pinnedCategory: !col.pinnedCategory,
        }
      }
      return col
    })
    return {
      ...state,
      visibleColumns: newVisibleColumns,
    }
  }),
  on(
    fromQuoteMIActions.setDetailsColumnsSettings,
    (_, { columnsSettings }) => columnsSettings
  )
)
const rankingDataReducer = createReducer(
  initialState.rankingData,
  on(
    fromQuoteMIActions.fetchQuoteRankingDataSuccess,
    (_, { quoteRankingData }) => quoteRankingData
  )
)
const dataCountsReducer = createReducer(
  initialState.dataCounts,
  on(
    fromQuoteMIActions.fetchQuoteDataCountsSuccess,
    (_, { quoteDataCounts }) => quoteDataCounts
  )
)
const rankingColumnsSettingsReducer = createReducer(
  initialState.rankingColumnsSettings,
  on(fromQuoteMIActions.pinColumnChangeRanking, (state, { column }) => {
    const newVisibleColumns = state.visibleColumns.map(col => {
      if (col.id === column.id) {
        return { ...col, pinned: !col.pinned }
      }
      return col
    })
    return {
      ...state,
      visibleColumns: newVisibleColumns,
    }
  }),
  on(
    fromQuoteMIActions.setRankingColumnsSettings,
    (_, { columnsSettings }) => columnsSettings
  )
)
const lastRefreshDateReducer = createReducer(
  initialState.lastRefreshDate,
  on(
    fromQuoteMIActions.fetchLastRefreshDateSuccess,
    (_, { lastRefreshDate }) => lastRefreshDate
  )
)

const quoteManagementReducer = combineReducers<QuoteManagementState>({
  tabs: tabsReducer,
  activeTab: activeTabReducer,
  audienceView: audienceViewReducer,
  selectors: selectorsReducer,
  layerIntervals: layerIntervalsReducer,
  timeIntervals: timeIntvervalsReducer,
  summaryData: summaryDataReducer,
  summaryContent: summaryContentReducer,
  detailsData: detailsDataReducer,
  detailsColumnsSettings: detailsColumnsSettingsReducer,
  rankingData: rankingDataReducer,
  rankingColumnsSettings: rankingColumnsSettingsReducer,
  dataCounts: dataCountsReducer,
  charts: fromQuoteChartsReducer.quoteManagementChartsReducer,
  trends: fromQuoteTrendsReducer.quoteManagementTrendsReducer,
  lastRefreshDate: lastRefreshDateReducer,
})

export function reducer(
  state: QuoteManagementState | undefined,
  action: Action
) {
  return quoteManagementReducer(state, action)
}
