import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { values } from 'ramda'
import { Observable, Subject, combineLatest } from 'rxjs'
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators'
import LayerModelingProps from '../layer-modeling-defs'
import { LayerModelingComponent } from '../layer-modeling.component/layer-modeling.component'
import {
  LayerModelingDimensionChangeEvent,
  LayerModelingView,
} from '../layer-modeling.model'
import {
  restoreLayerModelingSettings,
  setLayerModelingDimensionProp,
} from '../store/layer-modeling.actions'
import { LayerModelingState } from '../store/layer-modeling.reducer'
import {
  selectLayerModelingState,
  selectLayerModelingView,
} from '../store/layer-modeling.selectors'
import { selectAnalysisPortfolioMetricsOpen } from '../../store/analysis-panels.selectors'
import {
  selectCededPortfolioViewLayersViewIDs,
  selectCurrentCurrency,
  selectEditorPortfolioSetID,
  selectGrossPortfolioViewID,
} from '../../store/analysis.selectors'
import { fetchLayersViewMetrics } from '../../store/metrics/layers-metrics.actions'
import { AppState } from 'src/app/core/store'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-modeling-container',
  styleUrls: ['./layer-modeling.container.scss'],
  templateUrl: './layer-modeling.container.html',
})
export class LayerModelingContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  props = LayerModelingProps
  view$: Observable<LayerModelingView>
  state$: Observable<LayerModelingState>

  @ViewChild(LayerModelingComponent)
  component: LayerModelingComponent
  currentCurrency$: Observable<string | undefined>

  constructor(public store: Store<AppState>) {}

  ngOnInit() {
    const grossPortfolioViewID$ = this.store
      .select(selectGrossPortfolioViewID)
      .pipe(
        filter<string>(id => id != null),
        first()
      )

    const layerViewMetricIDs$ = this.store
      .select(selectCededPortfolioViewLayersViewIDs)
      .pipe(map(values))

    // Once gross portfolio view ID is available, fetch all layers' view metrics
    combineLatest([grossPortfolioViewID$, layerViewMetricIDs$])
      .pipe(
        takeUntil(this.destroy$),
        map(([_, layerViewMetricIDs]) => layerViewMetricIDs),
        withLatestFrom(this.store.select(selectEditorPortfolioSetID))
      )
      .subscribe(([layerViewMetricIDs, portfolioSetID]) => {
        if (portfolioSetID !== null) {
          layerViewMetricIDs.forEach(layerViewID =>
            this.store.dispatch(
              fetchLayersViewMetrics({ ...portfolioSetID, layerViewID })
            )
          )
        }
      })

    this.store.dispatch(restoreLayerModelingSettings())

    this.view$ = this.store.select(selectLayerModelingView)
    this.state$ = this.store.select(selectLayerModelingState)

    // Re-render chart when portfolio metrics toggled to resize
    this.store
      .select(selectAnalysisPortfolioMetricsOpen)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => setTimeout(() => this.component.render()))

    this.currentCurrency$ = this.store.select(selectCurrentCurrency)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onDimensionChange($event: LayerModelingDimensionChangeEvent): void {
    this.store.dispatch(setLayerModelingDimensionProp($event))
  }
}
