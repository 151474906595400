import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  ClientSearch,
  CreditSubmissionStructure,
  CreditSubmissionReinsurer,
  CreditSubmissionUserTranche,
  CreditSubmissionReinsurerStatus,
} from '../../model/credit-submission.model'
import { CreditCalculationStructureActions } from '../structure/credit-structure.actions'
import { CreditTransactionActions } from '../transaction/credit-transaction.actions'
import {
  CreditSubmissionReinsurerActions,
  CreditSubmissionStatusActions,
  CreditSubmissionStructureActions,
  CreditSubmissionUserTrancheActions,
} from './credit-submission.actions'
import { SetModuleContext } from '../credit.actions'

export interface State {
  currentCreditSubmissionStructure?: CreditSubmissionStructure
  currentCreditSubmissionReinsurers?: CreditSubmissionReinsurer[]
  currentCreditSubmissionUserTranches?: CreditSubmissionUserTranche[]
  currentCreditSubmissionUserIds?: number[]
  isDirty: boolean
  isSaving: boolean
  sessionModifiedDate?: string
  searchResults?: CreditSubmissionStructure[]
  searchFilters?: ClientSearch
}

export const initialState: State = {
  isDirty: false,
  isSaving: false,
}

const currentCreditSubmissionStructureReducer = createReducer(
  initialState.currentCreditSubmissionStructure,
  on(
    CreditSubmissionStructureActions.fetchSuccess,
    CreditSubmissionStructureActions.setSelectedSubmission,
    CreditSubmissionStructureActions.updateSelectedSubmission,
    CreditSubmissionStructureActions.updateSelectedSubmissionReinsurers,
    CreditSubmissionStatusActions.sendForApprovalSuccess,
    CreditSubmissionStatusActions.sendToMarketSuccess,
    (_, { structure }) => structure
  ),
  on(
    CreditSubmissionReinsurerActions.getSubmissionReinsurersSuccess,
    (state, { reinsurers }) => {
      return {
        ...state,
        reinsurer_ids: reinsurers
          .filter(r => r.status !== CreditSubmissionReinsurerStatus.Cancelled)
          .map(r => r.reinsurer_id),
      }
    }
  ),
  on(
    CreditSubmissionStructureActions.getUserIdsBySubmissionSuccess,
    (state, { userIds }) => {
      return {
        ...state,
        userIds,
      }
    }
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const currentCreditSubmissionReinsurersReducer = createReducer(
  initialState.currentCreditSubmissionReinsurers,
  on(
    CreditSubmissionReinsurerActions.getSubmissionReinsurersSuccess,
    (_, { reinsurers }) => reinsurers
  ),
  on(
    CreditSubmissionReinsurerActions.updateViewedStatusSuccess,
    (reinsurers, { reinsurer }) =>
      reinsurers.map(r => (r.id === reinsurer.id ? reinsurer : r))
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const currentCreditSubmissionUserTranchesReducer = createReducer(
  initialState.currentCreditSubmissionUserTranches,
  on(
    CreditSubmissionUserTrancheActions.fetchSuccess,
    CreditSubmissionUserTrancheActions.saveSuccess,
    (_, { tranches }) => tranches
  ),
  on(CreditSubmissionUserTrancheActions.update, (state, { tranche }) =>
    state.map(s => (s.tranche_id === tranche.tranche_id ? tranche : s))
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const currentCreditSubmissionUserIdsReducer = createReducer(
  initialState.currentCreditSubmissionUserIds,
  on(
    CreditSubmissionStructureActions.getUserIdsBySubmissionSuccess,
    (_, { userIds }) => userIds
  ),
  on(CreditSubmissionStructureActions.resetSelectedSubmission, _ => undefined)
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    CreditCalculationStructureActions.createSuccess,
    CreditCalculationStructureActions.update,
    CreditSubmissionStructureActions.updateSelectedSubmission,
    CreditTransactionActions.addSuccess,
    CreditTransactionActions.updateSuccess,
    CreditTransactionActions.delete,
    CreditSubmissionUserTrancheActions.update,
    _ => true
  ),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.putSuccess,
    CreditSubmissionStructureActions.putSuccess,
    CreditSubmissionUserTrancheActions.saveSuccess,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => false
  )
)

const isSavingReducer = createReducer(
  initialState.isSaving,
  on(CreditCalculationStructureActions.save, _ => true),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.postFailure,
    CreditCalculationStructureActions.putSuccess,
    CreditCalculationStructureActions.putFailure,
    CreditSubmissionStructureActions.putSuccess,
    CreditSubmissionStructureActions.putFailure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => false
  )
)

const sessionModifiedDateReducer = createReducer(
  initialState.sessionModifiedDate,
  on(CreditSubmissionUserTrancheActions.saveSuccess, _ =>
    new Date().toISOString()
  )
)

const searchResultsReducer = createReducer(
  initialState.searchResults,
  on(
    CreditSubmissionStructureActions.getSubmissionStructuresSuccess,
    (_, { structures }) => structures
  ),
  on(
    CreditSubmissionStructureActions.getSubmissionStructuresFailure,
    _ => undefined
  ),
  on(CreditSubmissionStructureActions.setSelectedFilters, _ => undefined),
  on(SetModuleContext, _ => undefined)
)

const searchFiltersReducer = createReducer(
  initialState.searchFilters,
  on(
    CreditSubmissionStructureActions.setSelectedFilters,
    (_, { filters }) => filters
  )
)

const submissionReducer = combineReducers<State>({
  currentCreditSubmissionStructure: currentCreditSubmissionStructureReducer,
  currentCreditSubmissionReinsurers: currentCreditSubmissionReinsurersReducer,
  currentCreditSubmissionUserTranches:
    currentCreditSubmissionUserTranchesReducer,
  currentCreditSubmissionUserIds: currentCreditSubmissionUserIdsReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
  sessionModifiedDate: sessionModifiedDateReducer,
  searchResults: searchResultsReducer,
  searchFilters: searchFiltersReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return submissionReducer(state, action)
}
