import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import {
  ScatterBubbleChart,
  ScatterBubbleDatum,
} from '@graphing/quote-scatter-bubble-chart'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import {
  SummaryChartInfo,
  SummaryChartGridOption,
  SummaryChartGridMetric,
} from '../summary-charts-model'
import html2canvas from 'html2canvas'
import {
  convertToInt,
  convertToPercent,
} from 'src/app/quote/management-information/utils/quote-charts.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-bubble-chart',
  styles: [
    `
      .app-chart ::ng-deep d3fc-group.cartesian-chart {
        grid-template-columns: 1.5em auto 1fr;
        grid-template-rows: minmax(2em, max-content) auto 1fr 1.5em;
      }
      .app-chart {
        background-color: black;
        padding: 25px 10px;
        overflow: visible;
      }
      .white-background {
        color: black;
        background-color: white !important;
      }
      .bubble-chart-frame {
        padding: 35px 10px 10px 10px;
      }
      #chart {
        width: 100%;
        height: auto;
      }
    `,
  ],
  template: `
    <app-explore-summary-chart-grid-item-frame
      [chartOption]="chartOption"
      [hasData]="chartOption.data?.data.length > 0"
      [selectableChartsForTab]="selectableChartsForTab"
      [selectableChartsForMetric]="selectableChartsForMetric"
      [maximizedIndex]="maximizedIndex"
      [canHideMetrics]="false"
      [hideMetrics]="hideMetrics"
      [pdfView]="pdfView"
      [hasComparisonValue]="true"
      [pdfView]="pdfView"
      (updateHideMetrics)="onUpdateHideMetrics($event)"
      (chartOptionUpdate)="chartOptionUpdate.emit($event)"
      (maximizeClick)="maximizeClick.emit($event)"
      (updateComparisonValue)="onUpdateComparisonValue($event)"
      (onExportClick)="downloadPNG()"
    >
      <div
        id="chart"
        #chart
        [ngClass]="{
          'app-chart': true,
          'app-chart-tiny-annotation': chartOption.index !== maximizedIndex,
          'white-background': lightChartMode,
        }"
      ></div>
    </app-explore-summary-chart-grid-item-frame>
  `,
})
export class ExploreSummaryBubbleChartComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() chartOption: SummaryChartGridOption
  @Input() maximizedIndex: number
  @Input() chartEntityLimit: number
  @Input() selectableChartsForTab: SummaryChartInfo[]
  selectableChartsForMetric: SummaryChartInfo[]
  @Input() drawDebounce = 250
  @Input() hideMetrics: boolean
  @Input() lightChartMode: boolean
  @Input() pdfView: boolean
  @Input() zipLoading: boolean
  isCountOfReinsurers: boolean
  isGroupByReinsurer: boolean
  comparisonValue: number | null = null

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  @ViewChild('chart', { static: false }) chartEl: ElementRef<HTMLDivElement>

  private chart: ScatterBubbleChart
  private draw$ = new Subject<void>()
  private destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.draw$
      .pipe(takeUntil(this.destroy$), debounceTime(this.drawDebounce))
      .subscribe(() => this.draw())
  }

  ngAfterViewInit(): void {
    this.selectableChartsForMetric = this.chartOption.metric.applicableCharts
    this.draw$.next()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isCountOfReinsurers =
      this.chartOption.metric.name === 'Count of Reinsurers'
    if (changes.hideMetrics || changes.lightChartMode) {
      this.draw$.next()
    }
    if (changes.chartEntityLimit || changes.maximizedIndex) {
      setTimeout(() => this.draw$.next(), 500)
    }

  }

  onUpdateHideMetrics(hideMetrics = false): void {
    this.updateHideMetrics.emit(hideMetrics)
  }

  onUpdateComparisonValue(val: number | null) {
    this.comparisonValue = val
    this.draw$.next()
  }

  downloadPNG(): void {
    const pngEl: HTMLElement | null = document.querySelector('#chart')
    if (pngEl) {
      html2canvas(pngEl).then(canvas => {
        const image = canvas.toDataURL('image/png')
        const yLabel = this.chartOption.metric.name
        const xLabel = ''
        const chartLabel = `${yLabel} ${!this.isCountOfReinsurers ? `by ${xLabel}` : ''}`
        const a = document.createElement('a')
        a.setAttribute('download', `${chartLabel}.png`)
        a.setAttribute('href', image)
        a.click()
      })
    }
  }

  private draw(): void {
    if (!this.chartEl) {
      return
    }
    const min = !this.pdfView && this.chartOption.index !== this.maximizedIndex
    const yLabel = this.chartOption.metric.name
    const xLabel = ''
    const chartLabel = `${yLabel} ${!this.isCountOfReinsurers ? `by ${xLabel}` : ''}`
    const sizeCol = this.chartOption.size?.name
    this.chart = new ScatterBubbleChart(this.chartEl.nativeElement, {
      xLabel,
      yLabel,
      chartLabel,
      xMetricValues: this.getXMetricValues(),
      metricPrimary: this.chartOption.metric.metricPrimaryName ?? yLabel,
      metricSecondary: this.chartOption.metric.metricSecondaryName,
      min,
      percent: this.chartOption.metric.format === 'percent',
      sizeCol,
      comparisonValue: this.comparisonValue ?? undefined,
      lightChartMode: this.lightChartMode,
    })
    this.chart.draw(this.getChartData())
  }

  private getXMetricValues(): string[] {
    if (!this.chartOption.data?.data) {
      return []
    }
    const data = [...this.chartOption.data?.data]
    return (
      data
        .sort((a, b) => b.metricPrimary - a.metricPrimary)
        .slice(0, this.chartEntityLimit)
        .map((d, i) => (this.hideMetrics ? `${i + 1}` : d.groupBy)) ?? []
    )
  }

  private getChartData(): ScatterBubbleDatum[] {
    const format = this.chartOption.metric.format
    if (!this.chartOption.data?.data) {
      return []
    }
    const data = [...this.chartOption.data?.data]
    return data
      .sort((a, b) => b.metricPrimary - a.metricPrimary)
      .slice(0, this.chartEntityLimit)
      .map((d, i) => {
        const size = d.size ?? 0
        return {
          x: i,
          y: [
            format === 'int'
              ? convertToInt(d.metricPrimary)
              : format === 'percent'
                ? convertToPercent(d.metricPrimary)
                : d.metricPrimary,
          ],
          size,
        }
      })
  }
}
