<app-theme-container>
  <app-quote
    [clientSelect]="clientSelect"
    [selectedClientID]="selectedClientID$ | async"
    [isExpanded]="isExpanded$ | async"
    [selectedCededLayerID]="selectedCededLayerID$ | async"
    [isGroupSelected]="isGroupSelected$ | async"
    [isSLSelected]="isSLSelected$ | async"
    [renewedFromRisks]="renewedFromRisks$ | async"
    [doesCurrentLayerHaveExpiring]="doesCurrentLayerHaveExpiring$ | async"
    [isLoading]="isLoading$ | async"
    [lossSetLayers]="lossSetLayers$ | async"
    [accountOpportunities]="accountOpportunities$ | async"
    [currentProgramId]="currentProgramId$ | async"
    [programs]="programs$ | async"
    [layer]="layer$ | async"
    [reinsurers]="reinsurers$ | async"
    [compareView]="compareView$ | async"
    (expandClick)="onExpandClick($event)"
    (fetchQuoteFieldsForSelectedRiskAndSection)="
          onFetchQuoteFieldsForSelectedRiskAndSection($event)
        "
    (riskCodeClick)="onRiskCodeClick($event)"
    (backQuickQuoteClick)="onBackQuickQuoteClick()"
    (saveCustomComparison)="onSaveCustomComparison()"
    (resetSelectedComparison)="resetSelectedComparison()"
  ></app-quote>
</app-theme-container>
