import { Action, createReducer, on } from '@ngrx/store'
import * as fromReinsurersActions from '../../quote/store/reinsurer/reinsurer.actions'
import { ReinsurerTrackingSubjectivity } from '../../quote/models/quote.model'
import { ApplicationError } from '../../error/model/error'


export const REINSURER_TRACKING_SUBJECTIVITIES_FEATURE_KEY = 'reinsurerTrackingSubjectivities'

export interface ReinsurerTrackingSubjectivityState {
  subjectivities: ReinsurerTrackingSubjectivity[]
  error: ApplicationError | null
  loading: boolean
}

export const initialState: ReinsurerTrackingSubjectivityState = {
  subjectivities: [],
  error: null,
  loading: false,
}

export const reinsurersReducer = createReducer(
  initialState,
  on(fromReinsurersActions.fetchSubjectivitiesByReinsurer, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromReinsurersActions.fetchSubjectivitiesByReinsurerSuccess, (state, { subjectivities }) => ({
    ...state,
    subjectivities,
    loading: false
  })),
  on(fromReinsurersActions.fetchSubjectivitiesByReinsurerFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  }))
)

export function reducer(state: ReinsurerTrackingSubjectivityState | undefined, action: Action) {
  return reinsurersReducer(state, action)
}
