export type QuoteChildValue = {
  id: number
  value: string
  viewValue: string
}

export type QuoteParentValue = QuoteChildValue & {
  subClassIds: number[]
}

export const QuoteOpportunityClassMap: QuoteParentValue[] = [
  {
    id: 0,
    value: 'Accident & Health',
    viewValue: 'Accident & Health',
    subClassIds: [48],
  },
  {
    id: 1,
    value: 'Accident & Health (Intl P&C)',
    viewValue: 'Accident & Health (Intl P&C)',
    subClassIds: [1],
  },
  {
    id: 2,
    value: 'Accident & Health (Turkey)',
    viewValue: 'Accident & Health (Turkey)',
    subClassIds: [1],
  },
  { id: 3, value: 'Aerospace', viewValue: 'Aerospace', subClassIds: [-1] },
  {
    id: 4,
    value: 'Aerospace (Aviation)',
    viewValue: 'Aerospace (Aviation)',
    subClassIds: [42],
  },
  {
    id: 5,
    value: 'Aerospace (Marine & Energy)',
    viewValue: 'Aerospace (Marine & Energy)',
    subClassIds: [2],
  },
  {
    id: 6,
    value: 'Affinity (Korea)',
    viewValue: 'Affinity (Korea)',
    subClassIds: [52, 53, 54, 55, 58],
  },
  { id: 7, value: 'Agriculture', viewValue: 'Agriculture', subClassIds: [-1] },
  {
    id: 8,
    value: 'Agriculture, Livestock & Crop',
    viewValue: 'Agriculture, Livestock & Crop',
    subClassIds: [3],
  },
  {
    id: 9,
    value: 'Casualty International (Korea)',
    viewValue: 'Casualty International (Korea)',
    subClassIds: [7, 42],
  },
  {
    id: 10,
    value: 'Casualty International (London North America)',
    viewValue: 'Casualty International (London North America)',
    subClassIds: [29, 30],
  },
  {
    id: 11,
    value: "Casualty Int'l",
    viewValue: "Casualty Int'l",
    subClassIds: [8, 11, 27, 30, 59],
  },
  {
    id: 12,
    value: 'Casualty Retro',
    viewValue: 'Casualty Retro',
    subClassIds: [9, 25, 30],
  },
  {
    id: 13,
    value: 'Casualty Retro (London North America)',
    viewValue: 'Casualty Retro (London North America)',
    subClassIds: [9],
  },
  { id: 14, value: 'Composite', viewValue: 'Composite', subClassIds: [] },
  {
    id: 15,
    value: 'Composite (Specialty)',
    viewValue: 'Composite (Specialty)',
    subClassIds: [86],
  },
  { id: 16, value: 'Contingency', viewValue: 'Contingency', subClassIds: [28] },
  {
    id: 17,
    value: 'Contingency (Specialty)',
    viewValue: 'Contingency (Specialty)',
    subClassIds: [14],
  },
  {
    id: 18,
    value: 'Coverholder & U/W Agency Services',
    viewValue: 'Coverholder & U/W Agency Services',
    subClassIds: [15],
  },
  {
    id: 19,
    value: 'Crisis Management',
    viewValue: 'Crisis Management',
    subClassIds: [-1],
  },
  {
    id: 20,
    value: 'Crisis Management (Specialty)',
    viewValue: 'Crisis Management (Specialty)',
    subClassIds: [73],
  },
  { id: 21, value: 'Cyber', viewValue: 'Cyber', subClassIds: [17] },
  {
    id: 22,
    value: 'Cyber (Healthcare)',
    viewValue: 'Cyber (Healthcare)',
    subClassIds: [17],
  },
  {
    id: 23,
    value: 'Cyber (Intl P&C)',
    viewValue: 'Cyber (Intl P&C)',
    subClassIds: [17],
  },
  {
    id: 24,
    value: 'Cyber (London North America)',
    viewValue: 'Cyber (London North America)',
    subClassIds: [17],
  },
  {
    id: 25,
    value: 'Cyber (Specialty)',
    viewValue: 'Cyber (Specialty)',
    subClassIds: [17],
  },
  {
    id: 26,
    value: 'D&F and Binders (Retrocession)',
    viewValue: 'D&F and Binders (Retrocession)',
    subClassIds: [10, 81, 85],
  },
  {
    id: 27,
    value: 'Energy (Marine & Energy)',
    viewValue: 'Energy (Marine & Energy)',
    subClassIds: [21, 63, 64, 83],
  },
  {
    id: 28,
    value: 'Engineering, Construction & Machinery (Intl P&C)',
    viewValue: 'Engineering, Construction & Machinery (Intl P&C)',
    subClassIds: [22],
  },
  {
    id: 29,
    value: 'Engineering, Construction & Machinery (Turkey)',
    viewValue: 'Engineering, Construction & Machinery (Turkey)',
    subClassIds: [22],
  },
  {
    id: 30,
    value: 'Engineering, Construction & Machinery Breakdown',
    viewValue: 'Engineering, Construction & Machinery Breakdown',
    subClassIds: [6],
  },
  {
    id: 31,
    value: 'Environmental',
    viewValue: 'Environmental',
    subClassIds: [23],
  },
  {
    id: 32,
    value: 'Environmental (EIL)',
    viewValue: 'Environmental (EIL)',
    subClassIds: [24],
  },
  {
    id: 33,
    value: 'Financial Lines',
    viewValue: 'Financial Lines',
    subClassIds: [18, 19, 26],
  },
  {
    id: 34,
    value: 'Financial Lines (Specialty)',
    viewValue: 'Financial Lines (Specialty)',
    subClassIds: [16, 68, 69],
  },
  {
    id: 35,
    value: 'General Aviation (Aviation)',
    viewValue: 'General Aviation (Aviation)',
    subClassIds: [36, 37, 42],
  },
  {
    id: 36,
    value: 'General Liability',
    viewValue: 'General Liability',
    subClassIds: [-1],
  },
  {
    id: 37,
    value: 'General Liability (Intl P&C)',
    viewValue: 'General Liability (Intl P&C)',
    subClassIds: [20, 31, 72],
  },
  {
    id: 38,
    value: 'General Liability (Turkey)',
    viewValue: 'General Liability (Turkey)',
    subClassIds: [31],
  },
  {
    id: 39,
    value: 'Healthcare',
    viewValue: 'Healthcare',
    subClassIds: [4, 34, 35, 44, 67],
  },
  {
    id: 40,
    value: 'Healthcare (Healthcare)',
    viewValue: 'Healthcare (Healthcare)',
    subClassIds: [34, 49],
  },
  {
    id: 41,
    value: 'Healthcare (London North America)',
    viewValue: 'Healthcare (London North America)',
    subClassIds: [34],
  },
  {
    id: 42,
    value: 'ILWs (Retrocession)',
    viewValue: 'ILWs (Retrocession)',
    subClassIds: [10],
  },
  {
    id: 43,
    value: 'Latent Defects',
    viewValue: 'Latent Defects',
    subClassIds: [39],
  },
  {
    id: 44,
    value: 'Latent Defects (Intl P&C)',
    viewValue: 'Latent Defects (Intl P&C)',
    subClassIds: [39],
  },
  {
    id: 45,
    value: 'Legal Expenses',
    viewValue: 'Legal Expenses',
    subClassIds: [40],
  },
  {
    id: 46,
    value: 'Legal Expenses (Intl P&C)',
    viewValue: 'Legal Expenses (Intl P&C)',
    subClassIds: [40],
  },
  { id: 47, value: 'Life', viewValue: 'Life', subClassIds: [43] },
  {
    id: 48,
    value: 'Life (Turkey)',
    viewValue: 'Life (Turkey)',
    subClassIds: [43],
  },
  {
    id: 49,
    value: 'Life Accident and Health',
    viewValue: 'Life Accident and Health',
    subClassIds: [0, 5, 33, 82],
  },
  {
    id: 50,
    value: 'Major Risk (Aviation)',
    viewValue: 'Major Risk (Aviation)',
    subClassIds: [37],
  },
  { id: 51, value: 'Marine', viewValue: 'Marine', subClassIds: [-1, 46] },
  {
    id: 52,
    value: 'Marine & Energy',
    viewValue: 'Marine & Energy',
    subClassIds: [-1],
  },
  {
    id: 53,
    value: 'Marine (Marine & Energy)',
    viewValue: 'Marine (Marine & Energy)',
    subClassIds: [13, 41, 46, 47, 65, 70, 84],
  },
  {
    id: 54,
    value: 'Marine (Turkey)',
    viewValue: 'Marine (Turkey)',
    subClassIds: [46],
  },
  {
    id: 55,
    value: 'Mergers & Acquisitions (Intl P&C)',
    viewValue: 'Mergers & Acquisitions (Intl P&C)',
    subClassIds: [95],
  },
  {
    id: 56,
    value: 'Miscellaneous Accident (Intl P&C)',
    viewValue: 'Miscellaneous Accident (Intl P&C)',
    subClassIds: [51],
  },
  { id: 57, value: 'Motor', viewValue: 'Motor', subClassIds: [12, 71] },
  {
    id: 58,
    value: 'Motor (Ex USA)',
    viewValue: 'Motor (Ex USA)',
    subClassIds: [57],
  },
  {
    id: 59,
    value: 'Motor (Intl P&C)',
    viewValue: 'Motor (Intl P&C)',
    subClassIds: [12, 71],
  },
  {
    id: 60,
    value: 'Motor (Turkey)',
    viewValue: 'Motor (Turkey)',
    subClassIds: [56],
  },
  {
    id: 61,
    value: 'Offshore energy',
    viewValue: 'Offshore energy',
    subClassIds: [-1, 63],
  },
  { id: 62, value: 'Pet', viewValue: 'Pet', subClassIds: [66] },
  {
    id: 63,
    value: 'Pet (Intl P&C)',
    viewValue: 'Pet (Intl P&C)',
    subClassIds: [66],
  },
  {
    id: 64,
    value: 'Political Risk and Credit',
    viewValue: 'Political Risk and Credit',
    subClassIds: [-1],
  },
  {
    id: 65,
    value: 'Political Violence & Terror',
    viewValue: 'Political Violence & Terror',
    subClassIds: [-1],
  },
  {
    id: 66,
    value: 'Professional & Financial Lines (Intl P&C)',
    viewValue: 'Professional & Financial Lines (Intl P&C)',
    subClassIds: [74],
  },
  {
    id: 67,
    value: 'Professional & Financial Lines (Turkey)',
    viewValue: 'Professional & Financial Lines (Turkey)',
    subClassIds: [74],
  },
  {
    id: 68,
    value: 'Professional Lines',
    viewValue: 'Professional Lines',
    subClassIds: [50, 60],
  },
  {
    id: 69,
    value: 'Property',
    viewValue: 'Property',
    subClassIds: [63, 77, 80],
  },
  {
    id: 70,
    value: 'Property (Intl P&C)',
    viewValue: 'Property (Intl P&C)',
    subClassIds: [76],
  },
  {
    id: 71,
    value: 'Property Reinsurance',
    viewValue: 'Property Reinsurance',
    subClassIds: [32, 38, 61, 78, 88, 92],
  },
  {
    id: 72,
    value: 'Property Reinsurance (Korea)',
    viewValue: 'Property Reinsurance (Korea)',
    subClassIds: [76],
  },
  {
    id: 73,
    value: 'Property Reinsurance (London North America)',
    viewValue: 'Property Reinsurance (London North America)',
    subClassIds: [61, 88, 92],
  },
  {
    id: 74,
    value: 'Property Reinsurance (Turkey)',
    viewValue: 'Property Reinsurance (Turkey)',
    subClassIds: [76],
  },
  {
    id: 75,
    value: 'Property Retro',
    viewValue: 'Property Retro',
    subClassIds: [79],
  },
  {
    id: 76,
    value: 'Property Retro (Retrocession)',
    viewValue: 'Property Retro (Retrocession)',
    subClassIds: [10, 81, 85],
  },
  {
    id: 77,
    value: 'Retro & D&F Combined (Retrocession)',
    viewValue: 'Retro & D&F Combined (Retrocession)',
    subClassIds: [10, 81],
  },
  {
    id: 78,
    value: 'Retro (Aviation)',
    viewValue: 'Retro (Aviation)',
    subClassIds: [37],
  },
  { id: 79, value: 'Specialty', viewValue: 'Specialty', subClassIds: [-1] },
  {
    id: 80,
    value: 'Structured Solutions',
    viewValue: 'Structured Solutions',
    subClassIds: [87],
  },
  { id: 81, value: 'Surety', viewValue: 'Surety', subClassIds: [-1] },
  {
    id: 82,
    value: 'Surety & Bonds',
    viewValue: 'Surety & Bonds',
    subClassIds: [89],
  },
  {
    id: 83,
    value: 'US Casualty (Healthcare)',
    viewValue: 'US Casualty (Healthcare)',
    subClassIds: [90],
  },
  {
    id: 84,
    value: 'US Casualty (London North America)',
    viewValue: 'US Casualty (London North America)',
    subClassIds: [11, 29, 30, 75, 90],
  },
  {
    id: 85,
    value: 'US Casualty US',
    viewValue: 'US Casualty US',
    subClassIds: [11, 20, 25, 27, 30, 59, 90],
  },
  {
    id: 86,
    value: 'US Workers Compensation',
    viewValue: 'US Workers Compensation',
    subClassIds: [-1, 93],
  },
  {
    id: 87,
    value: 'US Workers Compensation (Healthcare)',
    viewValue: 'US Workers Compensation (Healthcare)',
    subClassIds: [93],
  },
  {
    id: 88,
    value: 'US Workers Compensation (London North America)',
    viewValue: 'US Workers Compensation (London North America)',
    subClassIds: [93],
  },
  {
    id: 89,
    value: 'War, Terrorism and Political Violence',
    viewValue: 'War, Terrorism and Political Violence',
    subClassIds: [-1],
  },
  {
    id: 90,
    value: 'War, Terrorism and Political Violence (Specialty)',
    viewValue: 'War, Terrorism and Political Violence (Specialty)',
    subClassIds: [62, 94],
  },
  {
    id: 90,
    value: 'Warranty & Affinity',
    viewValue: 'Warranty & Affinity',
    subClassIds: [-1],
  },
  {
    id: 91,
    value: 'Warranty & Indemnity',
    viewValue: 'Warranty & Indemnity',
    subClassIds: [45],
  },
  {
    id: 92,
    value: 'Whole Account',
    viewValue: 'Whole Account',
    subClassIds: [96],
  },
  {
    id: 93,
    value: 'Whole Account (Intl P&C)',
    viewValue: 'Whole Account (Intl P&C)',
    subClassIds: [96],
  },
  {
    id: 94,
    value: 'Whole Account (Turkey)',
    viewValue: 'Whole Account (Turkey)',
    subClassIds: [96],
  },
]

export const QuoteOpportunitySubClasses: QuoteChildValue[] = [
  { id: -1, value: '', viewValue: '' },
  { id: 0, value: 'Accident', viewValue: 'Accident' },
  { id: 1, value: 'Accident & Health', viewValue: 'Accident & Health' },
  { id: 2, value: 'Aerospace', viewValue: 'Aerospace' },
  {
    id: 3,
    value: 'Agriculture, Livestock & Crop',
    viewValue: 'Agriculture, Livestock & Crop',
  },
  { id: 4, value: 'Allied Health', viewValue: 'Allied Health' },
  { id: 5, value: 'Annuity', viewValue: 'Annuity' },
  { id: 6, value: 'CAR', viewValue: 'CAR' },
  { id: 7, value: 'Casualty', viewValue: 'Casualty' },
  { id: 8, value: "Casualty Int'l", viewValue: "Casualty Int'l" },
  { id: 9, value: 'Casualty Retro', viewValue: 'Casualty Retro' },
  { id: 10, value: 'Cat', viewValue: 'Cat' },
  { id: 11, value: 'Commercial Auto', viewValue: 'Commercial Auto' },
  { id: 12, value: 'Commercial Vehicle', viewValue: 'Commercial Vehicle' },
  { id: 13, value: 'Composite', viewValue: 'Composite' },
  { id: 14, value: 'Contingency', viewValue: 'Contingency' },
  {
    id: 15,
    value: 'Coverholder & U/W Agency Services',
    viewValue: 'Coverholder & U/W Agency Services',
  },
  { id: 16, value: 'Credit', viewValue: 'Credit' },
  { id: 17, value: 'Cyber', viewValue: 'Cyber' },
  { id: 18, value: 'D&O', viewValue: 'D&O' },
  { id: 19, value: 'E&O', viewValue: 'E&O' },
  { id: 20, value: 'Employers Liability', viewValue: 'Employers Liability' },
  { id: 21, value: 'Energy', viewValue: 'Energy' },
  {
    id: 22,
    value: 'Engineering, Construction & Machinery',
    viewValue: 'Engineering, Construction & Machinery',
  },
  { id: 23, value: 'Environmental', viewValue: 'Environmental' },
  { id: 24, value: 'Environmental (EIL)', viewValue: 'Environmental (EIL)' },
  {
    id: 25,
    value: 'Excess Casualty Umbrella',
    viewValue: 'Excess Casualty Umbrella',
  },
  {
    id: 26,
    value: 'Financial Institutions',
    viewValue: 'Financial Institutions',
  },
  { id: 27, value: 'Financial Lines', viewValue: 'Financial Lines' },
  { id: 28, value: 'GAP', viewValue: 'GAP' },
  { id: 29, value: 'General Casualty', viewValue: 'General Casualty' },
  { id: 30, value: 'General Liability', viewValue: 'General Liability' },
  { id: 31, value: 'General Third Party', viewValue: 'General Third Party' },
  { id: 32, value: 'Global', viewValue: 'Global' },
  { id: 33, value: 'Group Disability', viewValue: 'Group Disability' },
  { id: 34, value: 'Healthcare', viewValue: 'Healthcare' },
  {
    id: 35,
    value: 'Hospital Professional Liability',
    viewValue: 'Hospital Professional Liability',
  },
  { id: 36, value: 'Hull', viewValue: 'Hull' },
  { id: 37, value: 'Hull & Liability', viewValue: 'Hull & Liability' },
  { id: 38, value: 'International', viewValue: 'International' },
  { id: 39, value: 'Latent Defects', viewValue: 'Latent Defects' },
  { id: 40, value: 'Legal Expenses', viewValue: 'Legal Expenses' },
  { id: 41, value: 'Liabilities', viewValue: 'Liabilities' },
  { id: 42, value: 'Liability', viewValue: 'Liability' },
  { id: 43, value: 'Life', viewValue: 'Life' },
  { id: 44, value: 'Long Term Care', viewValue: 'Long Term Care' },
  { id: 45, value: 'M&A', viewValue: 'M&A' },
  { id: 46, value: 'Marine', viewValue: 'Marine' },
  { id: 47, value: 'Marine & Energy', viewValue: 'Marine & Energy' },
  { id: 48, value: 'Medical Expenses', viewValue: 'Medical Expenses' },
  {
    id: 49,
    value: 'Medical Professional Liability',
    viewValue: 'Medical Professional Liability',
  },
  { id: 50, value: 'Miscellaneous', viewValue: 'Miscellaneous' },
  {
    id: 51,
    value: 'Miscellaneous Accident',
    viewValue: 'Miscellaneous Accident',
  },
  { id: 52, value: 'Mobile Fee', viewValue: 'Mobile Fee' },
  { id: 53, value: 'Mobile MPI', viewValue: 'Mobile MPI' },
  { id: 54, value: 'Mobile RVI', viewValue: 'Mobile RVI' },
  { id: 55, value: 'Mortgage RVI', viewValue: 'Mortgage RVI' },
  { id: 56, value: 'Motor', viewValue: 'Motor' },
  { id: 57, value: 'Motor (Ex USA)', viewValue: 'Motor (Ex USA)' },
  { id: 58, value: 'Motor EW', viewValue: 'Motor EW' },
  { id: 59, value: 'Multi-line Casualty', viewValue: 'Multi-line Casualty' },
  { id: 60, value: 'Multi-Lines', viewValue: 'Multi-Lines' },
  { id: 61, value: 'Nationwide', viewValue: 'Nationwide' },
  {
    id: 62,
    value: 'Nuclear, Chemical, Biological and Radiological',
    viewValue: 'Nuclear, Chemical, Biological and Radiological',
  },
  { id: 63, value: 'Offshore Energy', viewValue: 'Offshore Energy' },
  { id: 64, value: 'Onshore Energy', viewValue: 'Onshore Energy' },
  { id: 65, value: 'P&I', viewValue: 'P&I' },
  { id: 66, value: 'Pet', viewValue: 'Pet' },
  {
    id: 67,
    value: 'Physicians Professional Liability',
    viewValue: 'Physicians Professional Liability',
  },
  {
    id: 68,
    value: 'Political Risk and Credit',
    viewValue: 'Political Risk and Credit',
  },
  {
    id: 69,
    value: 'Political Risks and Contract Frustration',
    viewValue: 'Political Risks and Contract Frustration',
  },
  {
    id: 70,
    value: 'Political Violence & Terror',
    viewValue: 'Political Violence & Terror',
  },
  { id: 71, value: 'Private Car', viewValue: 'Private Car' },
  { id: 72, value: 'Product Liability', viewValue: 'Product Liability' },
  {
    id: 73,
    value: 'Product Recall & Contamination',
    viewValue: 'Product Recall & Contamination',
  },
  {
    id: 74,
    value: 'Professional & Financial Lines',
    viewValue: 'Professional & Financial Lines',
  },
  {
    id: 75,
    value: 'Professional Liability',
    viewValue: 'Professional Liability',
  },
  { id: 76, value: 'Property', viewValue: 'Property' },
  { id: 77, value: 'Property Cat', viewValue: 'Property Cat' },
  { id: 78, value: 'Property Reinsurance', viewValue: 'Property Reinsurance' },
  { id: 79, value: 'Property Retro', viewValue: 'Property Retro' },
  { id: 80, value: 'Property Risk', viewValue: 'Property Risk' },
  { id: 81, value: 'Quota Share', viewValue: 'Quota Share' },
  { id: 82, value: 'Regional Health Plan', viewValue: 'Regional Health Plan' },
  { id: 83, value: 'Renewables', viewValue: 'Renewables' },
  { id: 84, value: 'Retro', viewValue: 'Retro' },
  { id: 85, value: 'Risk', viewValue: 'Risk' },
  { id: 86, value: 'Specialty Composite', viewValue: 'Specialty Composite' },
  { id: 87, value: 'Structured Solutions', viewValue: 'Structured Solutions' },
  { id: 88, value: 'Super Regional', viewValue: 'Super Regional' },
  { id: 89, value: 'Surety & Bonds', viewValue: 'Surety & Bonds' },
  { id: 90, value: 'US Casualty', viewValue: 'US Casualty' },
  { id: 91, value: 'US Casualty US', viewValue: 'US Casualty US' },
  { id: 92, value: 'US Regional', viewValue: 'US Regional' },
  {
    id: 93,
    value: 'US Workers Compensation',
    viewValue: 'US Workers Compensation',
  },
  {
    id: 94,
    value: 'War, Terrorism & Political Violence',
    viewValue: 'War, Terrorism & Political Violence',
  },
  { id: 95, value: 'Warranty & Indemnity', viewValue: 'Warranty & Indemnity' },
  { id: 96, value: 'Whole Account', viewValue: 'Whole Account' },
]
