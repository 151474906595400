import { PipeTransform } from '@angular/core'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { EventLayer } from '../animated-scenarios.model'
import { SharedIDGroup } from '../../store/grouper/program-group.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { Layer } from '../../model/layers.model'
import { isLayerAgg, isLayerVisibleRisk } from '../../model/layers.util'
import { MonetaryUnit } from '../../../api/analyzere/analyzere.model'

export const DEFAULT_HEIGHT = 100000000
export const MIN_Z = -16
export const MAX_Z = 3
export const MIN_X = 0
export const MAX_X = 11
export const MIN_Y = 0
export const MAX_Y = 10
export const DEFAULT_SCALE = 30
export const EVENT_DISTANCE = 3
export const DEFAULT_START_ANGLE: Tower3DAngle = {
  y: toRadians(20),
  x: toRadians(10),
}
const ANNOTATION_DX = 1.2
const ANNOTATION_DY = 1.8

export interface Point {
  x: number
  y: number
  z: number
}

// Array order: FRONT TOP LEFT, FRONT BOTTOM LEFT, FRONT BOTTOM RIGHT,
// FRONT TOP RIGHT, BACK  TOP LEFT, BACK  BOTTOM LEFT,
// BACK  BOTTOM RIGHT,BACK  TOP RIGHT
export interface Cube {
  id: string
  points: Point[]
  layerState: LayerState | EventLayer
  sharedIDGroup: SharedIDGroup[]
}

export interface Tower3DOptions {
  marginLeft: number
  marginBottom: number
  cubeTransitionDuration: number
  numberFormatter: PipeTransform
  scale?: number
  startAngle?: Tower3DAngle
  defaultHeight?: number
  minZ?: number
  maxZ?: number
  minY?: number
  maxY?: number
  minX?: number
  maxX?: number
  eventDistance?: number
}

export interface Annotation {
  note: AnnotationNote
  className?: string
  color: string[]
  data: Omit<Point, 'z'>
  dx: number
  dy: number
}

export interface AnnotationData {
  x: (d: any) => number
  y: (d: any) => number
}

export interface AnnotationNote {
  label: string
  title: string
}

export function translateCube(cube: Cube) {
  const val = [...cube.points] as any
  val.id = cube.id
  val.layerState = cube.layerState
  val.height = cube.points[0].y - cube.points[1].y
  val.sharedIDGroup = cube.sharedIDGroup
  return val
}

export function translatePoint(point: Point) {
  return [point.x, point.y, point.z]
}

export function toRadians(degrees: number) {
  return (Math.PI / 180) * degrees
}

export interface Tower3DAngle {
  x: number
  y: number
}

export function getLimit(layer: Layer) {
  if (isLayerAgg(layer)) {
    return layer.physicalLayer.aggregateLimit
  } else if (isLayerVisibleRisk(layer)) {
    return layer.physicalLayer.riskLimit as MonetaryUnit
  } else {
    return layer.physicalLayer.limit
  }
}

export function getAttachment(layer: Layer) {
  if (isLayerAgg(layer)) {
    return layer.physicalLayer.aggregateAttachment
  } else if (isLayerVisibleRisk(layer)) {
    return layer.physicalLayer.riskAttachment as MonetaryUnit
  } else {
    return layer.physicalLayer.attachment
  }
}

export function createAnnotation(
  d: any,
  origin: number[],
  scale: number,
  shortNumberPipe: PipeTransform,
  container: HTMLElement
) {
  const layerState = d.layerState as LayerState
  const limit = getLimit(layerState.layer)
  const attachment = getAttachment(layerState.layer)
  let label =
    (Math.abs(layerState.layer.physicalLayer.participation) * 100).toFixed(1) +
    '% of ' +
    shortNumberPipe.transform(limit.value, limit.currency) +
    ' xs ' +
    shortNumberPipe.transform(attachment.value, attachment.currency)

  const eventLayer = layerState as EventLayer
  if (eventLayer.loss !== undefined) {
    label +=
      '\n' +
      shortNumberPipe.transform(
        eventLayer.loss *
          Math.abs(eventLayer.layer.physicalLayer.participation),
        layerState.layer.physicalLayer.attachment.currency
      ) +
      ' Event Loss'
  }
  const y = origin[1] + d.centroid.y * scale - scale * ANNOTATION_DY
  let dy = -scale * ANNOTATION_DY
  if (y < 80) {
    dy = dy * -1
  }
  const x = origin[0] + d.centroid.x * scale + scale * ANNOTATION_DX
  const bounds = container.getBoundingClientRect()
  const annotationXSpace = bounds.width - x
  let dx = scale * ANNOTATION_DX
  if (annotationXSpace < 150) {
    dx = dx * -1
  }
  return {
    note: {
      label,
      title: layerState.layer.physicalLayer.description || '',
      bgPadding: { top: 10, left: 10, right: 10, bottom: 10 },
      wrapSplitter: /\n/,
    },
    data: {
      x: origin[0] + d.centroid.x * scale,
      y: origin[1] + d.centroid.y * scale,
    },
    className: 'show-bg',
    dx,
    dy,
    color: ['white'],
  }
}

export function getLossSetNames(lossSets: LossSetLayer[]) {
  return lossSets.map(l => `${l.meta_data.ls_dim1} - ${l.meta_data.ls_dim2}`)
}
