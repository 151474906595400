import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface QuoteConfirmationDialogComponentData  {
  titleData?: string,
  checkPremiumAndRateRules?: boolean
}

@Component({
  selector: 'app-quote-confirmation-dialog',
  templateUrl: './quote-confirmation-dialog.component.html',
  styleUrls: ['./quote-confirmation-dialog.component.scss'],
})
export class QuoteConfirmationDialogComponent {
  title: string
  checkPremiumAndRateRules: boolean
  constructor(
    public dialogRef: MatDialogRef<QuoteConfirmationDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: QuoteConfirmationDialogComponentData
  ) {
    this.title = data.titleData 
      ? 'Do you want to overwrite ' +
        data.titleData +
        ' with value from Analyze Re or keep the manually overridden value?'
      : ''
    this.checkPremiumAndRateRules = !!this.data.checkPremiumAndRateRules && !this.title
  }

  useAnalyze(): void {
    this.dialogRef.close('useAnalyze')
  }

  useManual(): void {
    this.dialogRef.close('useManual')
  }

  save(): void {
    this.dialogRef.close('save')
  }

  close(): void {
    this.dialogRef.close('close')
  }
}
