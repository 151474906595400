import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  RenameGroupDialogComponentData,
  RenameGroupDialogComponent,
} from '@shared/rename-group-dialog.component'

@Injectable()
export class RenameGroupDialogService {
  constructor(public dialog: MatDialog) {}

  open(portfolioName: string) {
    const data: RenameGroupDialogComponentData = {
      groupName: portfolioName,
    }
    return this.dialog.open(RenameGroupDialogComponent, {
      width: '30vw',
      data,
    })
  }
}
