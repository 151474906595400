import { Params, RouterStateSnapshot } from '@angular/router'
import { RouterStateSerializer } from '@ngrx/router-store'
import { Injectable } from '@angular/core'

export interface RouterStateUrl {
  url: string
  params: Params
  queryParams: Params
}

// The CustomSerializer improves @ngrx performance and allows storeFreeze
// to co-exist with the router-store.
// See: https://ngrx.io/guide/router-store/configuration#custom-router-state-serializer
@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root
    while (route.firstChild) {
      route = route.firstChild
    }
    const { url } = routerState
    const params = routerState.root.params
    const queryParams = routerState.root.queryParams

    // Only return an object including the URL and query params
    // instead of the entire snapshot
    return { url, params, queryParams }
  }
}
