import { createAction, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { Program } from 'src/app/core/model/program.model'

export const execute = createAction('[Animated Scenarios] Execute')
export const executeSuccess = createAction(
  '[Animated Scenarios] Execute Success'
)
export const executeFailure = createAction(
  '[Animated Scenarios] Execute Failure',
  props<{ error: ApplicationError }>()
)

export const addSelectedStructure = createAction(
  '[Animated Scenarios] Add Selected Structure',
  props<{ structure: Program }>()
)

export const resetEventsAndResults = createAction(
  '[Animated Scenarios] Reset Events And Results'
)

export const setAnimating = createAction(
  '[Animated Scenarios] Set Animating',
  props<{ value: boolean }>()
)
