import { MatTableDataSource } from '@angular/material/table'
import { SortTableColumnsConfig } from '../sort-table-column-view'
import { CellIdentifier, LesserDomRect, Region, SubsetRowsCols } from './sort-table-excel.model'
import { SortTableColumnDef, SortTableRow }  from '../sort-table.model'
const pasteWarningTag = '**Red columns are immutable'
const zeroWidthCharacter = String.fromCodePoint(0x200B)

export const setFloatingMenuPosition = (element: HTMLDivElement, mouseEvent: MouseEvent): void => {
  const docWidth = document.documentElement.clientWidth
  const docHeight = document.documentElement.clientHeight

  const elementWidth = element.offsetWidth
  const elementHeight = element.offsetHeight

  if (elementWidth + mouseEvent.clientX > docWidth){
    element.style.setProperty('left', `${mouseEvent.clientX - elementWidth}px`)
  }
  else {
    element.style.setProperty('left', `${mouseEvent.clientX}px`)
  }

  if (elementHeight + mouseEvent.clientY > docHeight){
    element.style.setProperty('top', `${mouseEvent.clientY - elementHeight}px`)
  }
  else {
    element.style.setProperty('top', `${mouseEvent.clientY}px`)
  }
}

export const getFirstParentOfType = (element: HTMLElement, parentType: string): HTMLTableCellElement | undefined => {
  let currentElement: HTMLElement | null = element;

  while (currentElement) {
    if (currentElement.tagName === parentType) {
      return currentElement as HTMLTableCellElement;
    }
    currentElement = currentElement.parentElement;
  }

  return undefined;
}

export const getClosestElement = <T>(
  point: { x: number; y: number },
  nodes: HTMLTableCellElement[]
): HTMLTableCellElement | undefined => {
  let closestElement: HTMLTableCellElement | undefined = undefined
  let closestDistance: number = Infinity

  nodes.forEach(element => {
    const rect = element.getBoundingClientRect()
    const elementCenter = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    }

    const distance = Math.sqrt(
      Math.pow(elementCenter.x - point.x, 2) +
        Math.pow(elementCenter.y - point.y, 2)
    )

    if (distance < closestDistance) {
      closestDistance = distance
      closestElement = element
    }
  })

  return closestElement
}

export const getArrayFromRegionSelection = <T>(cellSelection: Region, dataSource: MatTableDataSource<SortTableRow<T>>, columns: SortTableColumnsConfig<T>): SubsetRowsCols<T> => {
    const c0 = cellSelection.c0
    const c1 = cellSelection.c1
    const colsList = columns.views.map(x => x.id)

    const c0ColIndex = colsList.indexOf(c0.col as keyof T)
    const c1ColIndex = colsList.indexOf(c1.col as keyof T)

    const leftMost = c0ColIndex < c1ColIndex ? c0ColIndex : c1ColIndex
    const rightMost = leftMost === c0ColIndex ? c1ColIndex : c0ColIndex

    const topMost = c0.row < c1.row ? c0.row : c1.row
    const bottomMost = topMost === c0.row ? c1.row : c0.row

    const output: any[][] = []
    const columnsOutput: SortTableColumnDef<T>[] = []

    for (let row = topMost; row <= bottomMost; row++){
      let outputRow: any[] = []
      for (let col = leftMost; col <= rightMost; col++){
        const colID = colsList[col]
        if (row === topMost){
          columnsOutput.push(columns.views[col])
        }
        outputRow.push(dataSource.data[row][colID])
      }
      output.push(outputRow)
    }
    return {rows: output, cols: columnsOutput}
}

export const selectionIncludesCell = <T>(cellSelection: Region, cell: CellIdentifier, columns: SortTableColumnsConfig<T>): boolean => {
    const c0 = cellSelection.c0
    const c1 = cellSelection.c1

    const colsList = columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(c0.col as keyof T)
    const c1ColIndex = colsList.indexOf(c1.col as keyof T)

    const leftMost = c0ColIndex < c1ColIndex ? c0ColIndex : c1ColIndex
    const rightMost = leftMost === c0ColIndex ? c1ColIndex : c0ColIndex

    const topMost = c0.row < c1.row ? c0.row : c1.row
    const bottomMost = topMost === c0.row ? c1.row : c0.row

    const cellColIndex = colsList.indexOf(cell.col as keyof T)

    if (cellColIndex < leftMost || cellColIndex > rightMost){
      return false
    }

    if (cell.row < topMost || cell.row > bottomMost){
      return false
    }

    return true
}

export const generateHtmlTableFromSelection = <T>(data: SubsetRowsCols<T>, includeHeader: boolean): string => {
  let output = `<table>`
  if (includeHeader){
    output += `<tr>`
    data.cols.forEach(col => {
      output += `<th id=${String(col.id)} style='border-bottom: 1.0pt solid #44B3E1'>${col.label}${zeroWidthCharacter}</th>`
    })
  }
  data.rows.forEach(row => {
    output += '<tr>'
    row.forEach((value, valueIdx) => {
      if (data.cols[valueIdx].editable){
        output += `<td>${value}</td>`
      }
      else {
        output += `<td id=${String(data.cols[valueIdx].id)} style="color:#9C0006; background:#FFC7CE; mso-pattern:black none" >` + value + '</td>'
      }

    })
    output += '</tr>'
  })
  output += '</table>'
  output += `<caption>${pasteWarningTag}</caption>`
  return output
}

export const topRowIsColumnHeaders = (row: string[]): boolean => {
  return !!row.find(header => header.indexOf(zeroWidthCharacter) !== -1)
}

export const parseCSVStringToArray = (data: string): string[][] => {
  const warningIdx = data.indexOf(pasteWarningTag)
  if (warningIdx !== -1) {
    data = data.substr(0, warningIdx)
  }
  let rows = data.split('\x0d\x0a') // Carriage feed then line feed
  if (rows.length === 1 && rows[0].includes('\x0a')) { // MacOS only uses new line char for line feed
    rows = data.split('\x0a')
  }
  if (rows[rows.length - 1] === '') {
    rows.length -= 1
  }
  return rows.map(row => row.split('\t'))
}

export const cleanCSVStringArray = (data: string[][]): void => {
  if (data[data.length - 1][0] === pasteWarningTag) {
    data.length -= 1
  }
  const topRow = data[0]
  if (topRowIsColumnHeaders(topRow)) {
    data.splice(0,1)
  }
  const bottomRow = data[data.length-1]
  if (bottomRow.length !== topRow.length){
    data.splice(data.length-1,1)
  }
}

export const generatePlainText = <T>(data: SubsetRowsCols<T>, includeHeader: boolean): string => {
  let output = ""
  if (includeHeader) {
    output += data.cols.map(col => col.label + zeroWidthCharacter).join('\t')
    output += '\n'
  }
  output += data.rows.map( row => row.join('\t')).join('\n')
  return output
}

export const checkIfDomRectChanged = (r1: DOMRect, r2: DOMRect): boolean => {
  const keys: (keyof DOMRect)[] = ['x','y','height','width']
  for (let key of keys) {
    const typedKey = key
    if (r1[typedKey] !== r2[typedKey]){
      return true
    }
  }
  return false
}

export const getRegionClipCssString = (parentRect: DOMRect, childRect: LesserDomRect): string  => {
    const verticalScrollBarSize = parentRect.height < childRect.height ? 16 : 0 //Scrollbar height in index.scss
    const horizontalScrollBarSize = parentRect.width < childRect.width ? 14 : 0 //Scrollbar height in index.scss

    const top = Math.max(childRect.top, parentRect.top)
    const right = Math.min(childRect.right, parentRect.right - verticalScrollBarSize)
    const bottom = Math.min(childRect.bottom, parentRect.bottom - horizontalScrollBarSize)
    const left = Math.max(childRect.left, parentRect.left)

    // Ensure that the child is inside the parent bounds
    if (top >= bottom || left >= right) {
        return "rect(0, 0, 0, 0)" // No visible area
    }

    // Calculate the clip values relative to the child element's own rectangle
    const clipTop = top - childRect.top
    const clipRight = (childRect.width + 4) - (childRect.right - right)
    const clipBottom = (childRect.height + 4) - (childRect.bottom - bottom)
    const clipLeft = left - childRect.left

    return `rect(${clipTop}px, ${clipRight}px, ${clipBottom}px, ${clipLeft}px)`
}

export const rectContains = (rect: DOMRect, x: number, y: number): boolean => {
  return (
    x >= rect.x &&
    x <= rect.width + rect.x &&
    y >= rect.y &&
    y <= rect.width + rect.y
  )
}

export const countElementsIn2DArray = (array: any[][]): number => {
  let count = 0
  array.forEach(row => {
    count += row.length
  })
  return count
}

export const findScrollableParent = (element: HTMLElement): HTMLElement => {
    let parent: HTMLElement | null = element.parentElement;

    while (parent) {
        const hasVerticalScrollbar = parent.scrollHeight > parent.clientHeight;
        const hasHorizontalScrollbar = parent.scrollWidth > parent.clientWidth;

        if (hasVerticalScrollbar || hasHorizontalScrollbar) {
            return parent;
        }
        parent = parent.parentElement;
    }
    return element;
}
