import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { range, uniqBy } from 'ramda'
import { Layer, LayerRef } from '../../model/layers.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { findRiskActualLayer, isLayerAgg } from '../../model/layers.util'
import { LossSetGroupEntity } from '../../store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import { LossSet } from '../../../api/analyzere/analyzere.model'

export interface RiskLossSetLayerModel {
  id: string
  lossSets: LossSetLayer[]
}
@Component({
  selector: 'app-loss-set-layer-selector',
  templateUrl: './loss-set-layer-selector.component.html',
  styleUrls: ['./loss-set-layer-selector.component.scss'],
})
export class LossSetLayerSelectorComponent {
  private lastIndex: number | null = null

  _selectedLayer: LayerState

  @Input()
  set selectedLayer(l: LayerState) {
    this._selectedLayer = l
    if (
      this._selectedLayer &&
      l &&
      l.layer.id !== this._selectedLayer.layer.id
    ) {
      this.lastIndex = null
    }
  }
  @Input() layerChoices: LossSetLayer[]
  @Input() grossLossSets: LossSetLayer[]
  @Input() groupEditSelectedLossSets: LossSetLayer[]
  @Input() set lossSetSelectionMode(val: string) {
    this._lossSetSelectionMode = val
    this.lastIndex = null
  }

  get lossSetSelectionMode() {
    return this._lossSetSelectionMode
  }

  _lossSetSelectionMode: string
  @Input() lossSetsList: LossSetLayer[]
  @Input() allCededLayers: LayerState[]

  @Input() showLossSetGroupEditor: boolean
  @Input() lossSetGroupEditMode: string
  @Input() lossSetSelectedGroup: string
  @Input() lossSetGroups: LossSetGroupEntity[]

  @Output() lossSetSelection = new EventEmitter<Partial<Layer>>()
  @Output() grossLossSetSelection = new EventEmitter<LossSetLayer[]>()
  @Output() removeRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() addRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() groupEditLossSetSelection = new EventEmitter<LossSetLayer[]>()

  @Output() lossSetGroupSetDirty = new EventEmitter<string>()

  @HostBinding('class.show-editor') get isShowEditor(): boolean {
    return this.showLossSetGroupEditor
  }

  get currentSelectedGroup() {
    return this.lossSetGroups.find(
      g => g.lossSetGroup.id === this.lossSetSelectedGroup
    )
  }

  toggleSelected(ls: LossSetLayer, $event: MouseEvent) {
    $event.stopPropagation()
    // Emit different actions depending on mode - below is Edit Groups Mode
    if (this.showLossSetGroupEditor) {
      if (this.lossSetGroupEditMode === '') {
        // Do nothing - don't allow user to select loss sets
        return
      }
      let selLossSetLayers = this.groupEditSelectedLossSets.slice()
      const inRange = this.shiftClick(ls, $event)
      if (this.isSelected(ls)) {
        const inRangeIDs = inRange.map(r => r.id)

        selLossSetLayers = selLossSetLayers.filter(
          l => !inRangeIDs.includes(l.id)
        )
      } else {
        selLossSetLayers.push(...inRange)
      }
      this.groupEditLossSetSelection.emit(uniqBy(l => l.id, selLossSetLayers))

      // If existing group is being edited, update dirty flag
      if (
        this.lossSetGroupEditMode === 'edit' &&
        !this.currentSelectedGroup?.dirty
      ) {
        this.lossSetGroupSetDirty.emit(this.lossSetSelectedGroup)
      }
    } else {
      // Else If not in Edit Groups Mode
      // If Ceded, update ceded layer's loss sets
      if (this.lossSetSelectionMode === 'Ceded') {
        if (
          !this._selectedLayer ||
          (this._selectedLayer && isLayerAgg(this._selectedLayer.layer))
        ) {
          return
        }
        const curSel = this.isSelected(ls)
        const inRange = this.shiftClick(ls, $event)
        // Handle FHCF
        if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'cat_fhcf'
        ) {
          // tslint:disable-next-line: no-non-null-assertion
          let selFHCFLossSetLayers = (
            this._selectedLayer.layer.lossSetLayers[0].loss_sets as LossSet[]
          ).slice()
          if (curSel) {
            const inRangeIDs = inRange.map(r => (r.loss_sets[0] as LayerRef).id)

            selFHCFLossSetLayers = selFHCFLossSetLayers.filter(
              // tslint:disable-next-line: no-non-null-assertion
              l => !inRangeIDs.includes(l.id)
            )
          } else {
            // if it's not selected add it
            selFHCFLossSetLayers.push(
              ...inRange.map(l => l.loss_sets[0] as LossSet)
            )
            selFHCFLossSetLayers = selFHCFLossSetLayers.filter(
              l => l.meta_data.loss_type === 'cat'
            )
          }
          const innerFHCFLossSetLayer = [
            {
              id: this._selectedLayer.layer.lossSetLayers[0].id,
              meta_data: this._selectedLayer.layer.lossSetLayers[0].meta_data,
              loss_sets: selFHCFLossSetLayers,
            },
          ]
          this.lossSetSelection.emit({
            id: this._selectedLayer.layer.id,
            lossSetLayers: innerFHCFLossSetLayer,
            meta_data: this._selectedLayer.layer.meta_data,
          })
          // Handle Risk XOL
        } else if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'noncat_risk'
        ) {
          const allLayers = this.allCededLayers.map(l => l.layer)
          // tslint:disable-next-line: no-non-null-assertion
          const actualRiskLayer = findRiskActualLayer(
            allLayers,
            this._selectedLayer.layer.id
          )!
          let selRiskLossSetLayers: LayerRef[] = []
          actualRiskLayer.lossSetLayers.forEach(l => {
            if (l.loss_sets) {
              if (
                l.loss_sets[0].hasOwnProperty('loss_sets') &&
                (l.loss_sets[0] as LayerRef).loss_sets
              ) {
                const catLoss = l.loss_sets[0] as LayerRef
                if (catLoss.loss_sets && catLoss.loss_sets[0]) {
                  selRiskLossSetLayers.push(
                    // tslint:disable-next-line: no-non-null-assertion
                    (l.loss_sets[0] as LayerRef).loss_sets![0] as LossSetLayer
                  )
                }
              } else {
                selRiskLossSetLayers.push(l.loss_sets[0] as LayerRef)
              }
            }
          })
          selRiskLossSetLayers = selRiskLossSetLayers.slice()
          const inRangeIDs = inRange.map(
            r => (r.loss_sets[0] as LossSetLayer).id
          )
          if (curSel) {
            const removeRiskLSIds = inRangeIDs.filter(l =>
              selRiskLossSetLayers.map(l1 => l1.id).includes(l)
            )
            const removeRiskLossSets = removeRiskLSIds.map(
              // tslint:disable-next-line: no-non-null-assertion
              r => inRange.find(i => (i.loss_sets[0] as LossSetLayer).id === r)!
            )
            removeRiskLossSets.map(r => {
              if (this.isSelected(ls)) {
                this.removeRiskLossSets.emit({
                  id: actualRiskLayer.id,
                  lossSets: [r],
                })
              }
            })
          } else {
            // if it's not selected add it
            const addRiskLSIds = inRangeIDs.filter(
              l => !selRiskLossSetLayers.map(l1 => l1.id).includes(l)
            )
            const addRiskLossSets = addRiskLSIds.map(
              // tslint:disable-next-line: no-non-null-assertion
              r => inRange.find(i => (i.loss_sets[0] as LossSetLayer).id === r)!
            )
            this.addRiskLossSets.emit({
              id: actualRiskLayer.id,
              lossSets: uniqBy(l => l.id, addRiskLossSets),
            })
          }
          // Handle normal layers
        } else {
          let selLossSetLayers = this._selectedLayer.layer.lossSetLayers.slice()
          if (curSel) {
            const inRangeIDs = inRange.map(r => r.id)

            selLossSetLayers = selLossSetLayers.filter(
              l => !inRangeIDs.includes(l.id)
            )
          } else {
            // if it's not selected add it
            selLossSetLayers.push(
              ...inRange.map(l => ({
                id: l.id,
                meta_data: l.meta_data,
                loss_sets: l.loss_sets,
              }))
            )
          }
          // Added code to apply same loss sets to top and drop layers
          if (
            this._selectedLayer.layer.meta_data.sage_layer_type === 'cat_td' ||
            this._selectedLayer.layer.meta_data.sage_layer_type === 'drop'
          ) {
            const allLayers = this.allCededLayers.map(l => l.layer)
            allLayers.forEach(x => {
              if (
                (x.meta_data.sage_layer_type === 'cat_td' &&
                  x.meta_data.sage_layer_subtype === 'virtual') ||
                x.meta_data.sage_layer_type === 'drop'
              ) {
                this.lossSetSelection.emit({
                  id: x.id,
                  lossSetLayers: uniqBy(l => l.id, selLossSetLayers),
                  meta_data: x.meta_data,
                })
              }
            })
          } else {
            this.lossSetSelection.emit({
              id: this._selectedLayer.layer.id,
              lossSetLayers: uniqBy(l => l.id, selLossSetLayers),
              meta_data: this._selectedLayer.layer.meta_data,
            })
          }
        }
        // If Gross, update gross and net portfolios
      } else {
        let selLossSetLayers = this.grossLossSets.slice()
        const inRange = this.shiftClick(ls, $event)
        if (this.isSelected(ls)) {
          const inRangeIDs = inRange.map(r => r.id)

          selLossSetLayers = selLossSetLayers.filter(
            l => !inRangeIDs.includes(l.id)
          )
        } else {
          selLossSetLayers.push(...inRange)
        }
        this.grossLossSetSelection.emit(uniqBy(l => l.id, selLossSetLayers))
      }
    }
  }

  isSelected(ls: LossSetLayer) {
    // If editing Loss Set Groups, don't use gross/ceded logic
    if (this.showLossSetGroupEditor) {
      return this.groupEditSelectedLossSets.find(l => l.id === ls.id)
    } else {
      // Below is for Ceded and Gross
      // Show active loss sets for a given selected ceded layer
      if (this.lossSetSelectionMode === 'Ceded') {
        if (!this._selectedLayer) {
          return false
        }
        // Handle FHCF
        if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'cat_fhcf'
        ) {
          const fHCFLossSetList = (
            this._selectedLayer.layer.lossSetLayers[0].loss_sets as LayerRef[]
          ).map(l => l.id)
          if (fHCFLossSetList.includes((ls.loss_sets[0] as LayerRef).id)) {
            return true
          }
          // Handle Risk XOL
        } else if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'noncat_risk'
        ) {
          const allLayers = this.allCededLayers.map(l => l.layer)
          const riskFinalLayer = findRiskActualLayer(
            allLayers,
            this._selectedLayer.layer.id
          )
          const riskLossSetList: string[] = []
          riskFinalLayer?.lossSetLayers.forEach(l => {
            if (l.loss_sets && l.meta_data.sage_layer_type === 'noncat_risk') {
              if (
                l.loss_sets[0].hasOwnProperty('loss_sets') &&
                (l.loss_sets[0] as LayerRef).loss_sets
              ) {
                const catLoss = l.loss_sets[0] as LayerRef
                if (catLoss.loss_sets && catLoss.loss_sets[0]) {
                  riskLossSetList.push(
                    // tslint:disable-next-line: no-non-null-assertion
                    ((l.loss_sets[0] as LayerRef).loss_sets![0] as LossSetLayer)
                      .id
                  )
                }
              } else {
                riskLossSetList.push((l.loss_sets[0] as LossSetLayer).id)
              }
            }
          })
          if (riskLossSetList?.includes((ls.loss_sets[0] as LossSetLayer).id)) {
            return true
          }
          // Handle normal layer types
        } else {
          const allLossSetList = this._selectedLayer.layer.lossSetLayers
          for (const l of allLossSetList) {
            if (l.id === ls.id) {
              return true
            }
          }
        }
        return false
        // Show active loss sets for gross/net portfolio
      } else {
        return this.grossLossSets.find(l => l.id === ls.id)
      }
    }
  }

  isAllSelected(lossSetsList: LossSetLayer[]) {
    for (const l in lossSetsList) {
      if (this.isSelected(lossSetsList[l])) {
        continue
      } else {
        return false
      }
    }
    return true
  }

  updateAllLossSets(lossSetsList: LossSetLayer[], $event: MouseEvent) {
    $event.preventDefault()
    const check = this.isAllSelected(lossSetsList)
    if (this.showLossSetGroupEditor) {
      return
    } else {
      if (this.lossSetSelectionMode === 'Ceded') {
        if (
          !this._selectedLayer ||
          (this._selectedLayer && isLayerAgg(this._selectedLayer.layer))
        ) {
          return
        }
        const curSel = check
        const inRange = lossSetsList
        // Handle FHCF
        if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'cat_fhcf'
        ) {
          // tslint:disable-next-line: no-non-null-assertion
          let selFHCFLossSetLayers = (
            this._selectedLayer.layer.lossSetLayers[0].loss_sets as LayerRef[]
          ).slice()
          if (curSel) {
            const inRangeIDs = inRange.map(r => (r.loss_sets[0] as LayerRef).id)

            selFHCFLossSetLayers = selFHCFLossSetLayers.filter(
              // tslint:disable-next-line: no-non-null-assertion
              l => !inRangeIDs.includes(l.id)
            )
          } else {
            selFHCFLossSetLayers.push(
              ...inRange.map(l => l.loss_sets[0] as LayerRef)
            )
            selFHCFLossSetLayers = selFHCFLossSetLayers.filter(
              l => l.meta_data.loss_type === 'cat'
            )
          }

          const innerFHCFLossSetLayer = [
            {
              id: this._selectedLayer.layer.lossSetLayers[0].id,
              meta_data: this._selectedLayer.layer.lossSetLayers[0].meta_data,
              loss_sets: selFHCFLossSetLayers,
            },
          ]
          this.lossSetSelection.emit({
            id: this._selectedLayer.layer.id,
            lossSetLayers: innerFHCFLossSetLayer,
            meta_data: this._selectedLayer.layer.meta_data,
          })
          // Handle Risk XOL
        } else if (
          this._selectedLayer.layer.meta_data.sage_layer_type === 'noncat_risk'
        ) {
          const allLayers = this.allCededLayers.map(l => l.layer)
          // tslint:disable-next-line: no-non-null-assertion
          const actualRiskLayer = findRiskActualLayer(
            allLayers,
            this._selectedLayer.layer.id
          )!
          let selRiskLossSetLayers: LayerRef[] = []
          actualRiskLayer.lossSetLayers.forEach(l => {
            if (l.loss_sets) {
              if (
                l.loss_sets[0].hasOwnProperty('loss_sets') &&
                (l.loss_sets[0] as LayerRef).loss_sets
              ) {
                const catLoss = l.loss_sets[0] as LayerRef
                if (catLoss.loss_sets && catLoss.loss_sets[0]) {
                  selRiskLossSetLayers.push(
                    // tslint:disable-next-line: no-non-null-assertion
                    (l.loss_sets[0] as LayerRef).loss_sets![0] as LossSetLayer
                  )
                }
              } else {
                selRiskLossSetLayers.push(l.loss_sets[0] as LayerRef)
              }
            }
          })
          selRiskLossSetLayers = selRiskLossSetLayers.slice()
          const inRangeIDs = inRange.map(
            r => (r.loss_sets[0] as LossSetLayer).id
          )
          if (curSel) {
            const removeRiskLSIds = inRangeIDs.filter(l =>
              selRiskLossSetLayers.map(l1 => l1.id).includes(l)
            )
            const removeRiskLossSets = removeRiskLSIds.map(
              // tslint:disable-next-line: no-non-null-assertion
              r => inRange.find(i => (i.loss_sets[0] as LossSetLayer).id === r)!
            )
            removeRiskLossSets.map(r => {
              if (check) {
                this.removeRiskLossSets.emit({
                  id: actualRiskLayer.id,
                  lossSets: [r],
                })
              }
            })
          } else {
            const addRiskLSIds = inRangeIDs.filter(
              l => !selRiskLossSetLayers.map(l1 => l1.id).includes(l)
            )
            const addRiskLossSets = addRiskLSIds.map(
              // tslint:disable-next-line: no-non-null-assertion
              r => inRange.find(i => (i.loss_sets[0] as LossSetLayer).id === r)!
            )
            this.addRiskLossSets.emit({
              id: actualRiskLayer.id,
              lossSets: uniqBy(l => l.id, addRiskLossSets),
            })
          }
          // Handle normal layers
        } else {
          let selLossSetLayers = this._selectedLayer.layer.lossSetLayers.slice()
          if (curSel) {
            selLossSetLayers = []
          } else {
            selLossSetLayers = lossSetsList
          }
          this.lossSetSelection.emit({
            id: this._selectedLayer.layer.id,
            lossSetLayers: uniqBy(l => l.id, selLossSetLayers),
            meta_data: this._selectedLayer.layer.meta_data,
          })
        }
        // If Gross, update gross and net portfolios
      } else {
        let selLossSetLayers = lossSetsList
        if (check) {
          selLossSetLayers = []
        }
        this.grossLossSetSelection.emit(uniqBy(l => l.id, selLossSetLayers))
      }
    }
  }

  getLabel(ls: LossSetLayer): string {
    // TODO: Add `ls_dim{1,2}` to model
    return `${(ls.meta_data as any).ls_dim1} - ${(ls.meta_data as any).ls_dim2}`
  }

  lookupPerilCode(code: string): string {
    switch (code) {
      case 'EQ': {
        return 'Earthquake'
      }
      case 'HU': {
        return 'Hurricane'
      }
      case 'ST': {
        return 'Storm'
      }
      case 'WF': {
        return 'Wild Fire'
      }
      default: {
        return '?'
      }
    }
  }

  createFilter(value: string) {
    if (value) {
      this.lossSetsList = this.layerChoices.filter(e => {
        return (
          this.getLabel(e).toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
      })
    } else {
      this.lossSetsList = this.layerChoices
    }
  }

  private shiftClick(ls: LossSetLayer, $event: MouseEvent) {
    let inRange: LossSetLayer[] = []
    if ($event.shiftKey) {
      const index = this.lossSetsList.map(l => l.id).indexOf(ls.id)
      if (index >= 0) {
        inRange = range(
          Math.min(index, this.lastIndex || 0),
          Math.max(index, this.lastIndex || 0) + 1
        ).map(i => this.lossSetsList[i])
      }
      this.lastIndex = this.lossSetsList.map(l => l.id).indexOf(ls.id)
    } else {
      this.lastIndex = this.lossSetsList.map(l => l.id).indexOf(ls.id)
    }
    if (inRange.length === 0) {
      inRange.push(ls)
    }
    return inRange
  }

  clearFilter() {}
}
