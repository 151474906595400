import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from './size.mixin'

class InfoTextBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof InfoTextBase = mixinSize(
  InfoTextBase
)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-info-text, [appInfoText]',
  styles: [
    `
           :host {
             color: var(--subtle);
             font-size: var(--font-size);
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
           }
     
           div {
             display: inline-block;
           }
     
           :host.glass {
             position: relative;
             display: block;
             backdrop-filter: blur(12px);
             border-radius: var(--border-radius-big);
           }
     
           :host.glass .content {
             position: relative;
             display: block;
             padding: var(--stack-tiny) var(--inset-small) var(--stack-small);
             color: rgba(255, 255, 255, 0.75);
             background: radial-gradient(
               circle at 46% 107%,
               #fdf0974d 0%,
               #fff08605 1%,
               #00b8ff0d 45%,
               #24bdd60f 60%,
               #282eeb0a 90%
             );
             border: 1px solid var(--border-translucent-subtle);
             border-radius: var(--border-radius-big);
             box-shadow: var(--shadow);
             z-index: 2;
           }
     
           :host.deemphasize .content {
             opacity: 0.33;
           }
     
           :host.glass .white-noise {
             position: absolute;
             top: 0;
             left: 0;
             right: 0;
             bottom: 0;
             margin: 0;
             /*TODO: FIX*/
             /*background-image: url('assets/bg/white-noise.png');*/
             border-radius: var(--border-radius-big);
             opacity: 0.67;
             overflow: hidden;
             z-index: 1;
           }
     
           :host.glass .gradient {
             position: absolute;
             top: 0;
             left: 0;
             right: 0;
             bottom: 0;
             margin: 0;
             background: linear-gradient(0deg, var(--bg-1), transparent);
             border-radius: var(--border-radius-big);
             opacity: 0.67;
             overflow: hidden;
             z-index: 1;
           }
     
           :host.italic {
             font-style: italic;
           }
     
           :host.strong {
             color: var(--body);
           }
     
           :host.hint {
             color: var(--hint);
           }
     
           :host.warn {
             color: var(--warn);
           }
     
           :host.tiny {
             font-size: var(--font-size-tiny);
           }
     
           :host.small {
             font-size: var(--font-size-small);
           }
     
           :host.big {
             font-size: var(--font-size-big);
           }
     
           :host.huge {
             font-size: var(--font-size-huge);
           }
     
           :host.gigantic {
             font-size: var(--font-size-gigantic);
           }
     
           @media screen and (max-width: 1240px) {
             :host {
               font-size: var(--font-size-small);
             }
     
             :host.small {
               font-size: var(--font-size-tiny);
             }
     
             :host.big {
               font-size: var(--font-size);
             }
     
             :host.huge {
               font-size: var(--font-size-big);
             }
     
             :host.gigantic {
               font-size: var(--font-size-big);
             }
           }
         `,
  ],
  template: `
    <div class="content">
      <span><ng-content></ng-content></span>
    </div>
    <div *ngIf="glass" class="white-noise"></div>
    <div *ngIf="glass" class="gradient"></div>
  `,
})
export class InfoTextComponent extends _SizeMixinBase implements CanSize {
  // Style mode: Italics
  @Input() set italic(value: any) {
    this._italic = coerceBooleanProperty(value)
  }
  get italic() {
    return this._italic
  }
  @HostBinding('class.italic') _italic = false

  // Style mode: Strong
  @Input() set strong(value: any) {
    this._strong = coerceBooleanProperty(value)
  }
  get strong() {
    return this._strong
  }
  @HostBinding('class.strong') _strong = false

  // Style mode: Hint
  @Input() set hint(value: any) {
    this._hint = coerceBooleanProperty(value)
  }
  get hint() {
    return this._hint
  }
  @HostBinding('class.hint') _hint = false

  // Style mode: Error
  @Input() set warn(value: any) {
    this._warn = coerceBooleanProperty(value)
  }
  get warn() {
    return this._warn
  }
  @HostBinding('class.warn') _warn = false

  // Style mode: Glass
  @Input() set glass(value: any) {
    this._glass = coerceBooleanProperty(value)
  }
  get glass() {
    return this._glass
  }
  @HostBinding('class.glass') _glass = false

  // Style mode: Deemphasize
  @Input() set deemphasize(value: any) {
    this._deemphasize = coerceBooleanProperty(value)
  }
  get deemphasize() {
    return this._deemphasize
  }
  @HostBinding('class.deemphasize') _deemphasize = false

  // Size mixin
  @Input() size: Size
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }

  constructor(public elementRef: ElementRef) {
    super(elementRef)
  }
}
