import { Component, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import {
  RenewedFromGroup,
  RenewedFromRiskAndSection,
} from '../models/quote.model'

@Component({
  selector: 'app-quote-expiring-details-dialog',
  templateUrl: './quote-expiring-details-dialog.component.html',
  styleUrls: ['./quote-expiring-details-dialog.component.scss'],
})
export class QuoteExpiringDetailsDialogComponent implements AfterViewInit {
  @ViewChild('sectionInput') sectionInput: ElementRef<HTMLInputElement>
  renewedFromRisks: RenewedFromRiskAndSection[]
  sectionControl = new FormControl()
  filteredRiskGroups: Observable<RenewedFromGroup[]>
  selectedRiskAndSection: RenewedFromRiskAndSection

  constructor(
    public dialogRef: MatDialogRef<QuoteExpiringDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      renewedFromRisks: RenewedFromRiskAndSection[]
    }
  ) {
    this.renewedFromRisks = data.renewedFromRisks
    this.filteredRiskGroups = this.sectionControl.valueChanges.pipe(
      startWith(''),
      map(
        filterBy =>
          typeof filterBy === 'string' && filterBy.length > 0
            ? this.filterRiskGroups(filterBy)
            : this.groupRisks([
                ...this.renewedFromRisks,
              ]) /* default to all risks if no filter typed */
      )
    )
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sectionInput.nativeElement.focus()
    }, 500)
  }

  getSectionValue(risk: RenewedFromRiskAndSection): string {
    return `Section ${risk.sectionNum} : ${risk.sectionNarrative}`
  }

  groupRisks(risks: RenewedFromRiskAndSection[]): RenewedFromGroup[] {
    const riskGroups: RenewedFromGroup[] = []
    risks.forEach(risk => {
      const group = riskGroups.find(
        g => g.groupName === `Risk Ref : ${risk.riskRef}`
      )
      if (group) {
        group.risks.push(risk)
      } else {
        riskGroups.push({
          groupName: `Risk Ref : ${risk.riskRef}`,
          risks: [risk],
        })
      }
    })
    return riskGroups
  }

  filterRiskGroups(filter: string): RenewedFromGroup[] {
    const risks = this.renewedFromRisks.filter(
      risk =>
        risk.riskRef.toLowerCase().includes(filter.toLowerCase()) ||
        risk.sectionNarrative?.toLowerCase().includes(filter.toLowerCase()) ||
        risk.sectionNum.toString().toLowerCase().includes(filter.toLowerCase())
    )
    return this.groupRisks(risks)
  }

  onSelectedRisk(riskAndSection: RenewedFromRiskAndSection) {
    this.selectedRiskAndSection = riskAndSection
  }

  onOKClick() {
    if (this.selectedRiskAndSection) {
      this.dialogRef.close(this.selectedRiskAndSection)
    }
  }
}
