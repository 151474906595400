import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { MultiSectionLayerService } from 'src/app/analysis/layers/multi-section-layer.service'
import { MultiSectionProperties } from 'src/app/analysis/properties/properties-panel.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
@Component({
  selector: 'app-multi-section-button',
  styles: [
    `
           .multi-section-button {
             display: flex;
             justify-content: center;
             padding-left: var(--inset);
             width: 100%;
     
             margin-top: var(--stack);
             margin-bottom: var(--stack);
           }
     
           .multi-section-button button {
             border-color: rgba(255, 255, 255, 0.3);
           }
         `,
  ],
  template: `
    <div
      class="multi-section-button"
      *ngIf="mslService.busy$ | async as status; else loading"
    >
      <button
        appButton
        color="accent"
        [disabled]="status.busy"
        (click)="onShowMultiSectionDialog()"
      >
        Sections
      </button>
    </div>
    <ng-template #loading
      ><mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar
    ></ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSectionButtonComponent {
  @Input() layerState: LayerState
  @Input() isLibRE: boolean
  private get layer() {
    return this.layerState.layer
  }

  @Output() showMultiSectionDialog = new EventEmitter<MultiSectionProperties>()

  constructor(readonly mslService: MultiSectionLayerService) {}

  onShowMultiSectionDialog(): void {
    this.showMultiSectionDialog.emit({
      layer: this.layer,
      isLibRE: this.isLibRE,
    })
  }
}
