import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { ApplicationError } from './model/error'
import { ErrorDialogComponent } from './dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-error',
  styles: [
    `
           :host {
             display: flex;
             align-items: baseline;
           }
     
           mat-icon {
             font-size: 20px;
             position: relative;
             top: 4px;
             color: var(--warn);
           }
     
           :host.info mat-icon {
             color: var(--accent);
           }
     
           .message {
             flex: 1 auto;
             font-family: var(--font-header-family);
             margin-left: 5px;
             user-select: text;
           }
     
           .actions {
             flex: 0 auto;
             margin-left: 15px;
             display: inline-block;
           }
     
           .actions button {
             margin-left: 5px;
             color: var(--warn);
           }
     
           :host.info .actions button {
             color: var(--accent);
           }
     
           @media screen and (max-width: 1240px) {
             mat-icon {
               font-size: 18px;
               width: 18px;
             }
     
             .message {
               font-size: var(--font-size-small);
             }
           }
         `,
  ],
  template: `
    <mat-icon aria-hidden="false" aria-label="Example home icon">
      {{ data.info ? 'info' : 'warning' }}
    </mat-icon>
    <span class="message">{{ data.message }}</span>
    <div class="actions">
      <button *ngIf="hasDetails" appButton link (click)="details()">
        <span>Details</span>
      </button>
      <button appButton link (click)="dismiss()">
        <span>Dismiss</span>
      </button>
    </div>
  `,
})
export class ErrorComponent {
  @HostBinding('class.info') get isInfo(): boolean {
    return this.data.info === true
  }

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ApplicationError,
    private ref: MatSnackBarRef<ErrorComponent>,
    private dialog: MatDialog
  ) {}

  get hasDetails(): boolean {
    return this.data && this.data.details && this.data.details.length > 0
  }

  dismiss() {
    this.ref.dismiss()
  }

  details() {
    if (this.hasDetails) {
      this.dialog.open(ErrorDialogComponent, {
        width: '70vw',
        height: '60vh',
        data: this.data.details,
      })
    }
  }
}
