<h1 class="title">Select Fronting Entity for Fund Manager's Assigned Lines</h1>
<p class="seg-text">
  Please select the specific segregated cell or fronting reinsurer applicable to
  this FOT. If the segregated cell or fronting reinsurer does not appear here,
  please open a new account in Salesforce as a Reinsurer ILS:
</p>
<div mat-dialog-content>
  <app-sort-table
    class="table"
    spacing="tight"
    [columnDefs]="fundManagerColumns"
    [rows]="fundManagerRows"
    (valueChange)="onValueChange($event)"
  ></app-sort-table>
</div>
<div mat-dialog-actions>
  <button
    appButton
    accent
    mat-dialog-close
    cdkFocusInitial
    (click)="onAddPlaceholderClick()"
  >
    Add Placeholder Line
  </button>
  <button
    appButton
    accent
    mat-dialog-close
    cdkFocusInitial
    (click)="onAddClick()"
  >
    Add Lines
  </button>
  <button appButton primary mat-dialog-close cdkFocusInitial>Cancel</button>
</div>
