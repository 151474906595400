import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import {
  PricingCurveContextTypes,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SavedPricingCurveEntry,
} from '../../../model/pricing-curve.model'
import { PricingCurve } from '../../../model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-content',
  styleUrls: ['pricing-curve-content.component.scss'],
  templateUrl: 'pricing-curve-content.component.html',
})
export class PricingCurveContentComponent {
  @Input() pricingCurves: PricingCurve[]
  @Input() status: PricingCurveStatus
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() context: PricingCurveContextTypes
  @Input() graphSettings: PricingCurveGraphSettings
  @Input() readonly: boolean
}
