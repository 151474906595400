import { createEntityAdapter, EntityState, Update } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  LossSetGroup,
  LossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { startAnalysis } from '../../analysis.actions'
import {
  clearAllDirtyGroups,
  deleteGroup,
  deleteGroupSuccess,
  fetchLossSetGroups,
  saveGroup,
  saveGroupSuccess,
  setDirtyLossSetGroup,
  setSelectedLossSetGroup,
  updateGroup,
  updateGroupEditLossSets,
  updateGroupSuccess,
  updateLossSetGroupMode,
} from './loss-set-group.actions'

interface ExtendedState {
  mode: string
  selectedLayers: LossSetLayer[]
  selectedGroup: string | null
  saving: boolean
}

export interface LossSetGroupEntity {
  lossSetGroup: LossSetGroup
  dirty: boolean
}

export type LossSetGroupEntityState = EntityState<LossSetGroupEntity> &
  ExtendedState

export const adapter = createEntityAdapter<LossSetGroupEntity>({
  selectId: entity => entity.lossSetGroup.id,
})

export const initialState: LossSetGroupEntityState = adapter.getInitialState<
  ExtendedState
>({
  mode: '',
  selectedLayers: [],
  selectedGroup: null,
  saving: false,
})

export const defaultEntity = {
  dirty: false,
}

const createEntity = (lossSetGroup: LossSetGroup): LossSetGroupEntity => ({
  ...defaultEntity,
  lossSetGroup,
})

const lossSetGroupReducer = createReducer(
  initialState,

  on(updateLossSetGroupMode, (state, { mode }) => {
    let updatedSelectedLayers = state.selectedLayers
    if (mode === '') {
      updatedSelectedLayers = []
    }
    return {
      ...state,
      mode,
      selectedLayers: updatedSelectedLayers,
      selectedGroup: '',
    }
  }),

  on(saveGroup, state => {
    return {
      ...state,
      saving: true,
    }
  }),

  on(saveGroupSuccess, (state, { groupAndMembers }) => {
    const modifiedState = {
      ...state,
      mode: '',
      selectedLayers: <LossSetLayer[]>[],
      selectedGroup: '',
      saving: false,
    }

    return adapter.addOne(
      createEntity(groupAndMembers.lossSetGroup),
      modifiedState
    )
  }),

  on(updateGroup, state => {
    return {
      ...state,
      saving: true,
    }
  }),

  on(updateGroupSuccess, (state, { groupAndMembers }) => {
    const entity = createEntity(
      groupAndMembers.lossSetGroup
    ) as LossSetGroupEntity
    const changes = {
      ...entity,
    }
    const modifiedState = {
      ...state,
      mode: '',
      selectedLayers: <LossSetLayer[]>[],
      selectedGroup: '',
      saving: false,
    }
    return adapter.updateOne(
      { id: groupAndMembers.lossSetGroup.id, changes },
      modifiedState
    )
  }),

  on(updateGroupEditLossSets, (state, { lossSetLayers }) => {
    return {
      ...state,
      selectedLayers: lossSetLayers,
    }
  }),

  on(setSelectedLossSetGroup, (state, { lossSetGroup }) => {
    if (lossSetGroup) {
      return {
        ...state,
        selectedGroup: lossSetGroup.id,
        selectedLayers: lossSetGroup.lossSetLayers,
      }
    } else {
      return {
        ...state,
        selectedGroup: null,
        selectedLayers: <LossSetLayer[]>[],
      }
    }
  }),

  on(deleteGroup, state => {
    return {
      ...state,
      saving: true,
    }
  }),

  on(deleteGroupSuccess, (state, { lossSetGroup }) => {
    const modifiedState = {
      ...state,
      mode: '',
      selectedLayers: <LossSetLayer[]>[],
      selectedGroup: '',
      saving: false,
    }

    return adapter.removeOne(lossSetGroup.id, modifiedState)
  }),

  on(fetchLossSetGroups, (state, { lossSetGroups }) => {
    const createEntities = lossSetGroups.map(g => createEntity(g))
    return adapter.setAll(createEntities, state)
  }),

  on(setDirtyLossSetGroup, (state, { lossSetGroupID, dirty }) => {
    const entity = state.entities[lossSetGroupID] as LossSetGroupEntity
    const changes = { ...entity, dirty }
    return adapter.updateOne({ id: lossSetGroupID, changes }, state)
  }),

  on(clearAllDirtyGroups, state => {
    const allGroups = selectAll(state)
    const updates: Update<LossSetGroupEntity>[] = []
    allGroups.forEach(g => {
      updates.push({
        id: g.lossSetGroup.id,
        changes: {
          dirty: false,
        },
      })
    })
    return adapter.updateMany(updates, state)
  }),

  on(startAnalysis, state => {
    return adapter.removeAll(state)
  })
)

export function reducer(
  state: LossSetGroupEntityState | undefined,
  action: Action
) {
  return lossSetGroupReducer(state, action)
}

export const { selectAll } = adapter.getSelectors()
