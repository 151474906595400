import { createAction, props } from '@ngrx/store'
import { Change, OptimizationInputRange } from '../optimization.model'

export const setRangesTypes = createAction(
  '[Optimization] Set Ranges Types',
  props<{
    rangesTypes: Array<{ id: string; ranges: OptimizationInputRange[] }>
  }>()
)

export const updateRangesTypes = createAction(
  '[Optimization] Update Ranges Types',
  props<{ id: string; rangeChange: Change<OptimizationInputRange> }>()
)

export const setLossSetGroupIDs = createAction(
  '[Optimization] Set Loss Set Group IDs',
  props<{ id: string; values: string[] }>()
)

export const updateRangesCurrency = createAction(
  '[Optimization] Update Ranges Currency',
  props<{ id: string; rangeChange: Change<OptimizationInputRange>[] }>()
)
