<app-layer-modeling
  *ngIf="view$ | async as view"
  [loading]="view.loading"
  [error]="view.error"
  [props]="props"
  [data]="view.data"
  [medians]="view.medians"
  [state]="state$ | async"
  [currentCurrency]="currentCurrency$ | async"
  (dimensionChange)="onDimensionChange($event)"
></app-layer-modeling>
