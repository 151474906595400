import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { CurrencyPipe, PercentPipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Observable, of, race, Subject, timer } from 'rxjs'
import { debounce, debounceTime, takeUntil } from 'rxjs/operators'
import {
  CurrencyCode,
  Reinstatement,
} from '../../api/analyzere/analyzere.model'
import { AppState } from '../../core/store'
import { selectCurrencyList } from '../../core/store/broker/broker.selectors'
import { analyzereConstants } from '../constants/analyzere'
import { Territories } from '../../api/territory/territory.service'
import {
  QuoteChildValue,
  QuoteOpportunityClassMap,
  QuoteOpportunitySubClasses,
  QuoteParentValue,
} from '../../quote/models/quote-class.model'

interface EditReinstatements {
  id?: number
  counter: number
  premium?: number
  formattedValue?: string | number | number[]
}

export interface Reference {
  value: string
  viewValue: string | null
}

export interface DependentValue {
  parent: { id: string; value: string }
  child: { id: string; value: string }
}

export interface DependentValueLists {
  parentValues: { id: string; values: QuoteParentValue[] }
  childValues: { id: string; values: QuoteChildValue[] }
}

type inputs = string
    | number
    | Reinstatement[]
    | boolean
    | number[]
    | string[]
    | DependentValue

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-property',
  styleUrls: ['./layer-property.component.scss'],
  templateUrl: './layer-property.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LayerPropertyComponent),
      multi: true,
    },
  ]
})
// tslint:disable: no-non-null-assertion
export class LayerPropertyComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  private destroy$ = new Subject()
  @Input() type:
    | 'text'
    | 'currency'
    | 'number-list'
    | 'dropdown'
    | 'dependent'
    | 'multiselect'
    | 'numeric'
    | 'checkbox'
    | 'unformatted-numeric'
    | 'textarea'
    | 'autocomplete'
    | 'date'
    | 'nondecimal-numeric'
    | 'empty'
  @Input() readonly = false
  @Input() whiteLabel = false
  @Input() withDraggable = false
  @Input() isCession = false
  @Input() isReinstatements = false
  @Input() dontFilterList = false
  @Input() name: string
  @Input() value:
    | string
    | string[]
    | number
    | number[]
    | Reinstatement[]
    | boolean
    | DependentValue
  @Input() references?: Reference[]
  @Input() isNewLayer: boolean
  @Input() currentCurrency?: string
  @Input() numberTransform = (val: number) =>
    this.isValueUnlimited(val) ? analyzereConstants.unlimitedValue : val
  @Input() numberReverseTransform = (val: number) => val
  @Input() submitting = false
  @Input() inputChangeDebounceTime = 50000
  @Input() isQuotaShare = false
  @Input() cedingCommissionChecked = false
  @Input() subjectPremiumChecked = false
  @Input() decimal = '0'
  @Input() territories: Territories

  @Input() set hideLabel(value: any) {
    this._hideLabel = coerceBooleanProperty(value)
  }
  get hideLabel() {
    return this._hideLabel
  }
  _hideLabel = false

  @Input() set alignRight(value: any) {
    this._alignRight = coerceBooleanProperty(value)
  }
  get alignRight() {
    return this._alignRight
  }
  @HostBinding('class.align-right') _alignRight = false

  @Input() set cell(value: any) {
    this._cell = coerceBooleanProperty(value)
  }
  get cell() {
    return this._cell
  }
  @HostBinding('class.cell') _cell = false

  @Input() set checkboxRow(value: any) {
    this._checkboxRow = coerceBooleanProperty(value)
  }
  get checkbox_row() {
    return this._checkboxRow
  }

  @HostBinding('class.checkbox-row') _checkboxRow = false

  @Output() inputChange = new EventEmitter<
    | string
    | number
    | Reinstatement[]
    | boolean
    | number[]
    | string[]
    | DependentValue
  >()
  @Output() inputKeyup = new EventEmitter<void>()
  @Output() inputSubmit = new EventEmitter<void>()
  @Output() premiumValueChange = new EventEmitter<number>()
  @Output() cedingCommissionValueChange = new EventEmitter<number>()

  @HostBinding('class.submitting') get isSubmitting() {
    return this.submitting
  }

  @ViewChild('textInput') textInput: ElementRef
  @ViewChild('textareaInput') textareaInput: ElementRef
  @ViewChild('percentageInput') percentageInput: ElementRef
  @ViewChild('currencyInput') currencyInput: ElementRef
  @ViewChild('numericInput') numericInput: ElementRef
  @ViewChild('unformattedNumericInput') unformattedNumericInput: ElementRef
  @ViewChild('dateInput') dateInput: ElementRef
  @ViewChild('nonDecimalNumericInput') nonDecimalNumericInput: ElementRef

  autoControl = new FormControl()
  currencyList$: Observable<CurrencyCode[]>
  formattedDate: Date
  items: Reference[]
  formattedValue:
    | string
    | string[]
    | number
    | number[]
    | Reinstatement[]
    | boolean
    | DependentValue
  transformedValue:
    | string
    | string[]
    | number
    | number[]
    | Reinstatement[]
    | boolean
    | DependentValue
  editReinstatements: EditReinstatements[]
  isReinstatementInput = false
  inputChangeDebounce$ = new Subject<
    string | string[] | number | Reinstatement[]
  >()
  inputKeyupDebounce$ = new Subject<void>()
  focusOutSubject = new Subject<void>()
  isRemoveReinstatement = false
  dependentValueLists?: DependentValueLists

  get valueDropdown(): string {
    const value = this.items.find(i => i.value === this.value)
    if (value && value.viewValue) {
      return value.viewValue
    }
    return 'Select'
  }
  get isDropdown(): boolean {
    return this.type === 'dropdown'
  }
  get isDependent(): boolean {
    return this.type === 'dependent'
  }
  get isMultiselect(): boolean {
    return this.type === 'multiselect'
  }
  get isAutocomplete(): boolean {
    return this.type === 'autocomplete'
  }
  get isCurrency(): boolean {
    return this.type === 'currency'
  }
  get isText(): boolean {
    return this.type === 'text'
  }
  get isEmpty(): boolean {
    return this.type === 'empty'
  }
  get isNumeric(): boolean {
    return this.type === 'numeric'
  }
  get isUnformattedNumeric(): boolean {
    return this.type === 'unformatted-numeric'
  }
  get isNonDecimalNumeric(): boolean {
    return this.type === 'nondecimal-numeric'
  }
  get isNumberList(): boolean {
    return this.type === 'number-list'
  }
  get isTextArea(): boolean {
    return this.type === 'textarea'
  }
  get isCheckbox(): boolean {
    return this.type === 'checkbox'
  }
  get isDate(): boolean {
    return this.type === 'date'
  }

  get tooltip(): string | number | number[] | Reinstatement[] | undefined {
    return this.formattedValue && this.formatValue.length > 18
      ? String(this.formattedValue)
      : ''
  }
  get spinnerSize(): number {
    return this.cell ? 16 : 32
  }

  get additionalMultiselectValues(): any {
    if (this.name === 'Territories') {
      return this.territories
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private readonly currencyPipe: CurrencyPipe,
    private store: Store<AppState>
  ) {
  }

  isNumberOrReinstatementArray = (
    value: number[] | string[] | Reinstatement[]
  ): value is number[] | Reinstatement[] => {
    if (value && value.length > 0) {
      // @ts-ignore
      return !value.every(
        (item: number | string | Reinstatement) => typeof item === 'string'
      )
    } else {
      return true
    }
  }

  ngOnInit(): void {
    if (this.isCheckbox) {
      this.value = !!this.value
    } else if (this.isText && this.readonly) {
      this.formattedValue = this.value
    } else if (!this.isMultiselect && !this.isDependent) {
      if (
        this.isNumberList &&
        Array.isArray(this.value) &&
        typeof this.value === 'object' &&
        this.isNumberOrReinstatementArray(this.value)
      ) {
        this.editReinstatements = this.formatValueReinstatement(this.value)
      } else {
        this.setNumericValues()
      }
    }
    if (this.isDropdown || this.isMultiselect || this.isAutocomplete) {
      if (this.value === 'Unknown') {
        this.items = [{ value: 'Unknown', viewValue: 'Unknown' }]
      } else {
        this.items = this.references as Reference[]
      }
      if (this.name === 'Structure FX' || this.name === 'Premium FX') {
        this.currencyDataUpdate()
      }
    }
    if (this.isDependent) {
      if (
        typeof this.value === 'object' &&
        !Array.isArray(this.value) &&
        this.value.parent.id === 'layerClass'
      ) {
        this.dependentValueLists = {
          parentValues: {
            id: this.value.parent.id,
            values: QuoteOpportunityClassMap,
          },
          childValues: {
            id: this.value.child.id,
            values: QuoteOpportunitySubClasses,
          },
        }
      }
    }
    this.autoControl.valueChanges.subscribe(searchVal => {
      if (this.items) {
        if (!this.dontFilterList) {
          this.items = this.filterItemsValues(searchVal) as Reference[]
        }
      }
    })

    this.inputChangeDebounce$
      .pipe(
        debounce(() => {
          if (this.isRemoveReinstatement) {
            return of(500)
          } else {
            return race(
              timer(this.inputChangeDebounceTime),
              this.focusOutSubject.asObservable()
            )
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        const value =
          typeof this.value === 'number' && typeof val === 'number'
            ? this.numberReverseTransform(val)
            : val
        this.inputChange.emit(value)
        this.value = value
        this.onChange(value)
      })
    this.inputChangeDebounceTime = 50000
    this.inputKeyupDebounce$
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(val => this.inputKeyup.emit(val))
    this.isRemoveReinstatement = false
  }

  currencyDataUpdate(): void {
    this.currencyList$ = this.store.pipe(select(selectCurrencyList))
    this.currencyList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        const data: Reference[] = []
        result.forEach((element: any) => {
          data.push({
            value: element.code,
            viewValue: element.code,
          })
        })
        this.items = data
        this.references = data
        this.cdRef.detectChanges()
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isDropdown) {
      if (
        changes.references &&
        JSON.stringify(changes.references.currentValue) !==
          JSON.stringify(changes.references.previousValue)
      ) {
        this.getList()
      }
      if (this.items.length <= 0) {
        this.getList()
      }
      return
    }
    if (changes.value) {
      this.autoControl.setValue({
        viewValue: this.value,
      })
      if (this.isDate && this.value && typeof this.value === 'string') {
        this.formattedDate = new Date(this.value)
      }
    }
    if (
      this.isNumberList &&
      typeof this.value === 'object' &&
      Array.isArray(this.value) &&
      this.isNumberOrReinstatementArray(this.value)
    ) {
      this.editReinstatements = this.formatValueReinstatement(this.value)
    } else if (!this.isCheckbox && !this.isMultiselect && !this.isDependent) {
      this.setNumericValues()
    }
    if (
      this.isQuotaShare && this.subjectPremiumChecked &&
      this.name === 'Premium' &&
      typeof this.value === 'number' &&
      changes.value &&
      changes.value.currentValue !== changes.value.previousValue
    ) {
      this.premiumValueChange.emit(this.value)
    }
    if (
      this.isQuotaShare && this.cedingCommissionChecked &&
      this.name === 'Ceding Commission' &&
      typeof this.value === 'number' &&
      changes.value &&
      changes.value.currentValue !== changes.value.previousValue
    ) {
      this.cedingCommissionValueChange.emit(this.value)
    }
    if (this.name === 'Final Signature Page Changes') {
      if (this.formattedValue === '0') {
        this.formattedValue = ''
        this.value = ''
      }
    }
    this.isRemoveReinstatement = false
  }

  trackByDropdownItem(_index: number, item: Reference): any {
    return item.value
  }

  multiselectInputChange(selectedValues: string[]): void {
    this.value = selectedValues
    this.inputChange.emit(selectedValues)
  }

  isValueUnlimited(num: number) {
    return num.toString().includes('e' || 'E')
  }

  numberAction($event: any): void {
    let modifiedValue = $event
    switch (this.name) {
      case 'Premium FX to USD':
        const val = Number($event)
        modifiedValue = val.toFixed(6)
        break
      case 'Loss Scale Factor': // Warning: Loss Scale Factor shares same switch condition as New Expense Ratio... Leave it to together
      case 'New Expense Ratio':
        const currentValue = Number(modifiedValue)
        let value = parseFloat(currentValue.toString())
        value = value * 10 ** 5
        value = Math.round(value)
        value = value / 10 ** 5
        modifiedValue = value
        break
      case 'Premium Scale Factor':
        const factorValue = Number($event)
        modifiedValue = factorValue.toFixed(1)
        break
      default:
        if (modifiedValue) {
          const modValue = '' + modifiedValue
          const arr = modValue.split('.')
          if (arr[1] && arr[1].length > 1) {
            modifiedValue = modifiedValue.toFixed(1)
          }
        }
        break
    }
    this.inputChangeDebounce$.next(modifiedValue)
  }

  onFocusOut(): void {
    this.focusOutSubject.next()
    this.inputSubmit.emit()
  }

  onMouseDown(event: Event): void {
    if (this.withDraggable) {
      // stop mousedown event from bubbling up to parent elements; allows user to drag/highlight input without moving a draggable panel
      event.stopPropagation()
    }
  }

  blur(): void {
    const els = [
      this.textInput,
      this.textareaInput,
      this.percentageInput,
      this.currencyInput,
      this.numericInput,
      this.unformattedNumericInput,
      this.dateInput,
      this.nonDecimalNumericInput,
    ]
    els
      .filter(el => el != null)
      .map(el => el.nativeElement as HTMLInputElement)
      .forEach(el => el.blur())
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  getList(): void {
    this.items = this.references as Reference[]
  }

  editedReinstatement(): void {
    const newReinstatement: Array<Reinstatement> = []
    // Will have to delete this code once confirmed.
    // this.editReinstatements = this.collapseReinstatements(
    //   this.editReinstatements
    // )
    this.editReinstatements.forEach(er => {
      er.formattedValue = this.setPipePercentage(er.premium)
      if (er.counter!.toString() === '') {
        newReinstatement.push({ premium: Number(''), brokerage: 0 })
      } else {
        for (let x = 0; x < er.counter!; x++) {
          newReinstatement.push({
            // id: er.id,
            premium: er.premium === undefined ? undefined : Number(er.premium),
            brokerage: 0,
          })
        }
      }
    })
    this.inputChangeDebounce$.next(newReinstatement)
    this.inputKeyupDebounce$.next()
  }

  addReinstatement(): void {
    this.editReinstatements.push({
      premium: undefined,
      counter: 0,
      formattedValue: this.setPipePercentage(undefined),
    })
    const newReinstatement: Array<Reinstatement> = []
    this.editReinstatements.forEach(er => {
      for (let x = 0; x < er.counter!; x++) {
        newReinstatement.push({
          // id: er.id,
          premium: er.premium === undefined ? undefined : Number(er.premium),
          brokerage: 0,
        })
      }
    })
    this.inputChangeDebounce$.next(newReinstatement)
    this.inputKeyupDebounce$.next()
  }

  removeReinstatement(index: number): void {
    this.editReinstatements.splice(index, 1)
    const newReinstatement: Array<Reinstatement> = []
    this.editReinstatements.forEach(er => {
      for (let x = 0; x < er.counter!; x++) {
        newReinstatement.push({
          // id: er.id,
          premium: er.premium === undefined ? undefined : Number(er.premium),
          brokerage: 0,
        })
      }
    })
    this.isRemoveReinstatement = true
    this.inputChangeDebounce$.next(newReinstatement)
    this.inputKeyupDebounce$.next()
  }

  private formatValueReinstatement(
    value: number[] | Reinstatement[]
  ): EditReinstatements[] {
    const newArray: Array<EditReinstatements> = []
    value.forEach((r: Reinstatement | number) => {
      if (typeof r === 'number') {
        throw new Error(
          'Layer property number-list value must be reinstatements'
        )
      }
      if (
        newArray.length > 0 &&
        newArray[newArray.length - 1].premium === r.premium
      ) {
        newArray[newArray.length - 1].counter! += 1
      } else {
        newArray.push({
          // id: r.id,
          counter: 1,
          premium: r.premium,
          formattedValue: this.setPipePercentage(r.premium),
        })
      }
    })
    return newArray
  }

  onMenuItemSelected(value: Reference): void {
    this.value = value.value
    this.inputChange.emit(value.value)
    this.onChange(value.value)
    this.inputSubmit.next()
  }

  onDateChange(date: Date): void {
    this.value = date.toISOString() ?? ''
    this.inputChange.emit(this.value)
    this.onChange(this.value)
    this.inputSubmit.next()
  }

  setPipeCurrency(num: string | number): string {
    if (num !== '0') {
      if (
        num.toString().indexOf('e+') > -1 ||
        num.toString() === 'Unlimited' ||
        (!isNaN(Number(num)) &&
          Number(num) >=
            this.numberTransform(analyzereConstants.unlimitedValue))
      ) {
        return 'Unlimited'
      } else {
        try {
          const curr = this.currencyPipe.transform(
            num,
            this.currentCurrency || '',
            'symbol-narrow',
            `1.${this.decimal}-${this.decimal}`
          )
          return curr || ''
        } catch (e) {
          this.inputChangeDebounce$.next(0)
          this.inputKeyupDebounce$.next()
          return (
            this.currencyPipe.transform(
              0,
              this.currentCurrency || '',
              'symbol-narrow',
              `1.${this.decimal}-${this.decimal}`
            ) || ''
          )
        }
      }
    } else {
      const curr = this.currencyPipe.transform(
        num,
        this.currentCurrency || '',
        'symbol-narrow',
        `1.${this.decimal}-${this.decimal}`
      )
      return curr || ''
    }
  }

  setPipePercentage(num: string | number | undefined): string {
    if (typeof num === undefined) {
      return ''
    }
    const pipe = new PercentPipe('en-US')
    let perc
    if (this.isCession) {
      perc = pipe.transform(num, '1.1-1')
    } else {
      perc = pipe.transform(num)
    }
    return perc || ''
  }

  currencyInputChanged(rawValue: number | string): number | undefined {
    if (rawValue) {
      if (typeof rawValue === 'string') {
        if (rawValue.match(/unlimited/i)) {
          this.value = analyzereConstants.unlimitedValue
          return this.value
        }
      }
      // Remove the currency symbol and grouping separators such as commas
      const digits = String(rawValue).replace(/[^.\d]/g, '')

      this.value = Number(digits)

      return this.value
    }
  }

  percentInputChanged(value: any): number {
    if (value) {
      const num = value.replace(/[%,]/g, '')
      const converted = num ? Number(num / 100) : 1
      if (this.isCession || this.isNumberList) {
        return isNaN(converted) ? 0 : converted
      } else {
        if (converted > 1) {
          return 1
        } else {
          return isNaN(converted) ? 0 : converted
        }
      }
    } else {
      return 0
    }
  }

  nonDecimalNumericChanged(value: number) {
    if (value) {
      return Number(value).toFixed(0)
    } else {
      return 0
    }
  }

  private setNumericValues(): void {
    if (this.isCheckbox) {
      return
    }
    if (typeof this.value !== 'number' && typeof this.value !== 'string') {
      this.value = 0.0
    }
    const transformedValue =
      typeof this.value === 'number'
        ? this.numberTransform(this.value)
        : this.value

    this.formattedValue = this.formatValue(String(transformedValue))
    this.transformedValue = transformedValue
  }

  private formatValue(value: string | number): string {
    switch (this.type) {
      case 'currency':
        return this.setPipeCurrency(value)
      case 'text':
        return value.toString()
      case 'dropdown':
        return value.toString()
      case 'numeric':
        if (this.name === 'Model Version') {
          return Number(value).toFixed(1)
        } else {
          return value.toString()
        }
      case 'checkbox':
        return value.toString()
      case 'textarea':
        return value.toString()
      case 'nondecimal-numeric':
        return Number(value).toFixed(0)
      default:
        return ''
    }
  }

  filterItemsValues(searchVal: any): Reference[] | undefined {
    if (this.references) {
      if (searchVal.viewValue) {
        return this.references.filter(value => {
          if (value.viewValue) {
            return (
              value.viewValue
                .toLowerCase()
                .indexOf(searchVal.viewValue.toLowerCase()) === 0
            )
          }
        })
      } else {
        return this.references.filter(value => {
          if (value.viewValue) {
            return (
              value.viewValue.toLowerCase().indexOf(searchVal.toLowerCase()) ===
              0
            )
          }
        })
      }
    }
  }

  displayFn(item: any): string | undefined {
    return item ? item.viewValue : undefined
  }

  getType(): string {
    if (this.name && this.name === 'New Expense Ratio') {
      return 'text'
    }
    return 'number'
  }

    // These are the callbacks that Angular will register
  private onChange: (value: inputs) => void = (value) => {};
  private onTouched: () => void = () => {};

  // Implement required methods from ControlValueAccessor
  writeValue(value: inputs): void {
    this.value = value; // Update the component's value when Angular updates it
    this.setNumericValues()
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: (value: inputs) => void): void {
    this.onChange = fn; // Register Angular's onChange callback
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn; // Register Angular's onTouched callback
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled
    this.cdRef.detectChanges();
  }
}
