import { LayerMetricsRP } from './layers-metrics.model'
import {
  asUSD,
  Fee,
  LoadedLossSet,
  LossSet,
  Metadata,
  MonetaryUnit,
  PhysicalPortfolioLayer,
  Ref,
  Reinstatement,
} from '../../api/analyzere/analyzere.model'

export interface Layer {
  modified_date?: string
  id: string
  lossSetFilter?: string
  lossSetLayers: LayerRef[]
  layerRefs: string[]
  physicalLayer: PhysicalLayer
  meta_data: Partial<Metadata>
  sharedLayerID: string
  viewMetrics: {
    loading: boolean
    error: string | null
    metrics: LayerMetricsRP | null
    rss: number | null
  }
  contributionValues?: { id: string; value: number }[]
  currency?: string
  layerResultsMetricCurrency?: string // Add layer results currency to layer model
}

export interface LayerRef {
  id: string
  meta_data: Partial<Metadata>
  loss_sets?: Ref[] | LossSet[] | LayerRef[] | LoadedLossSet[]
  mean?: number
}

export interface PhysicalLayer {
  aggregateLimit: MonetaryUnit
  aggregateAttachment: MonetaryUnit
  attachment: MonetaryUnit
  color?: string
  description: string | null
  event_limit?: MonetaryUnit
  fees: Fee[]
  franchise: MonetaryUnit
  id: string
  limit: MonetaryUnit
  logicalLayerID: string
  loss_sets?: Ref[] | LossSet[] | LayerRef[]
  meta_data: Partial<Metadata>
  modified_date?: string
  participation: number
  premium: MonetaryUnit
  riskAttachment?: MonetaryUnit
  riskLimit?: MonetaryUnit
  type: string
  reinstatements: Reinstatement[]
  xcoord: number
  ycoord: number
  inception_date?: string | null
  expiry_date?: string | null
  payout?: MonetaryUnit
  trigger?: MonetaryUnit
  nth?: number
}

export interface WithLayerId {
  id: string
}

export type PostResponse<T> = T & WithLayerId

export type GenericLayer = Partial<PhysicalPortfolioLayer>

export interface NestedLayer {
  _type: string // "NestedLayer"
  description: string | null
  sink: Ref | GenericLayer
  sources: Array<Ref>
  meta_data: Partial<Metadata>
}

export interface SimpleLayer {
  id: string
  description: string
  layerType: string
  layerInfoText?: string
}

export interface DropDownOptions {
  value: string | number
  viewValue: string
}

export const UNLIMITED_AMOUNT = 1e21
export const NESTED_AGG_LIMIT_AMOUNT = 1e12
export const ZERO_DOLLARS = asUSD(0)
export const UNLIMITED_DOLLARS = asUSD(UNLIMITED_AMOUNT)
export const ONE_DOLLAR = asUSD(1)
export const NESTED_AGG_LIMIT_DOLLARS = asUSD(NESTED_AGG_LIMIT_AMOUNT)

export const DEFAULT_TEMPLATE_LAYER_ID = '13bf605c-1ea0-4e0e-a50d-183eb3f7f89b'

export const LARGE_FREQ_SINK_LAYER: Partial<PhysicalPortfolioLayer> = {
  _type: 'Generic',
  description: 'sink',
  meta_data: {},
  loss_sets: [],
  participation: 1.00,
  attachment: ZERO_DOLLARS,
  limit: ONE_DOLLAR,
  aggregate_attachment: ZERO_DOLLARS,
  aggregate_limit: NESTED_AGG_LIMIT_DOLLARS,
  franchise: ZERO_DOLLARS
}
