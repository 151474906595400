import { Injectable } from '@angular/core'
import {
  Metrics,
  Portfolio,
  LogicalPortfolioLayer,
  LayerViewResponse,
  LossSetLayer,
  PhysicalPortfolioLayer,
  Update,
} from '../analyzere/analyzere.model'
import { ApiResponse, MaybeData, MaybeError } from '../model/api.model'
import { AnalyzreService } from '../analyzere/analyzre.service'
import { Layer } from 'src/app/analysis/model/layers.model'
import { switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { of, forkJoin, Observable } from 'rxjs'
import { uniq, uniqBy, isNil, clone } from 'ramda'
import {
  SharedLimitLayerSelection,
  ProgramGroup,
  ProgramGroupMember,
  SharedLimitValidation,
  SharedLimit,
  SharedLimitMember,
} from 'src/app/analysis/store/grouper/program-group.model'
import { filterValid } from 'src/app/analysis/model/layers.util'
import { Dictionary } from '@ngrx/entity'
import { Program } from 'src/app/core/model/program.model'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../core/store'
import { selectPrograms } from '../../core/store/program/program.selectors'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import { selectProgramGroupMembers } from '../../core/store/program-group-member.selectors'
import { convertFromLogicalPortfolioLayers } from '../../analysis/model/layers.converter'
import { catchAndHandleError, executeSequentially } from '../util'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { sharedLimitValidationToErrorPayload } from './shared-limit.util'
import { OmitID } from '../model/backend.model'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import {
  selectCurrentClientID,
  selectCurrentYearID,
} from '../../core/store/broker/broker.selectors'
import * as SharedLimitActions from '../../analysis/store/grouper/shared-limit/grouper-shared-limit.actions'
import {
  selectSharedLimitMembers,
  selectSharedLimits,
} from '../../core/store/auth/auth.selectors'

interface GroupChange {
  groups: ProgramGroup[]
  nestedLayerIDs: string[]
}

@Injectable({
  providedIn: 'root',
})
// tslint:disable: no-non-null-assertion
export class SharedLimitService {
  constructor(
    private service: AnalyzreService,
    private store: Store<AppState>,
    private http: HttpClient
  ) {}

  private getAllAncestors(
    programGroupMembers: ProgramGroupMember[],
    structureID: string
  ) {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'program' && m.programID === structureID && m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestorsFromGroup(
            programGroupMembers,
            member.parentGroupID
          )
        )
      }

      return groupIDs
    } else {
      return groupIDs
    }
  }
  private getAllAncestorsFromGroup(
    programGroupMembers: ProgramGroupMember[],
    groupID: string
  ) {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'programGroup' &&
        m.programGroupID === groupID &&
        m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestorsFromGroup(
            programGroupMembers,
            member.parentGroupID
          )
        )
      }
      return groupIDs
    } else {
      return groupIDs
    }
  }

  reconcileSharedLimitWithGroup(
    cededNestedLayerRecord: Record<string, string[]>,
    nestedLayers: LogicalPortfolioLayer[],
    structures: Program[],
    groupStructuresByID: Dictionary<ProgramGroup>,
    members: ProgramGroupMember[],
    create: boolean
  ): Observable<MaybeError> {
    const groupChanges: GroupChange[] = []
    Object.keys(cededNestedLayerRecord).forEach(cededPortfolioID => {
      const structure = structures.find(
        s => s.cededPortfolioID === cededPortfolioID
      )
      if (structure) {
        const groups = this.getAllAncestors(members, structure.id)
        if (groups.length > 0) {
          const structureGroups = groups
            .map(groupID => groupStructuresByID[groupID])
            .filter(s => !isNil(s)) as ProgramGroup[]
          if (structureGroups.length > 0) {
            const changes = {
              groups: structureGroups,
              nestedLayerIDs: cededNestedLayerRecord[cededPortfolioID],
            }
            groupChanges.push(changes)
          }
        }
      }
    })
    if (groupChanges.length > 0) {
      const updates: Observable<MaybeError>[] = []
      groupChanges.forEach(groupChange => {
        const groups = groupChange.groups
        groups.forEach(g => {
          const cededPortfolioID = g.cededPortfolioID as string
          const netPortfolioID = g.netPortfolioID as string
          const nestedLayersForGroup = nestedLayers.filter(n =>
            groupChange.nestedLayerIDs.includes(n.id)
          )
          const backAllocatedForGroupIDs = nestedLayersForGroup.map(
            n => n.meta_data.backAllocatedForID as string
          )
          const nestedLayersForGroupIDs = nestedLayersForGroup.map(n => n.id)
          if (create) {
            updates.push(
              this.replaceLayersInPortfolio(
                cededPortfolioID,
                nestedLayersForGroupIDs,
                backAllocatedForGroupIDs
              )
            )
            updates.push(
              this.replaceLayersInPortfolio(
                netPortfolioID,
                nestedLayersForGroupIDs,
                backAllocatedForGroupIDs
              )
            )
          } else {
            updates.push(
              this.replaceLayersInPortfolio(
                cededPortfolioID,
                backAllocatedForGroupIDs,
                nestedLayersForGroupIDs
              )
            )
            updates.push(
              this.replaceLayersInPortfolio(
                netPortfolioID,
                backAllocatedForGroupIDs,
                nestedLayersForGroupIDs
              )
            )
          }
        })
      })
      return executeSequentially(updates).pipe(
        map(result => {
          if (result.error) {
            return { error: result.error }
          }
          return {}
        })
      )
    } else {
      return of({})
    }
  }

  private replaceLayersInPortfolio(
    portfolioID: string,
    addLayers: string[],
    removeLayers: string[]
  ): ApiResponse<Portfolio> {
    return this.service.fetchPortfolio(portfolioID).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const layers = (response.data!.layers as LogicalPortfolioLayer[]).map(
            l => l.id
          )
          const newLayers = layers
            .filter(l => !removeLayers.includes(l))
            .map(l => ({ ref_id: l }))
          newLayers.push(...addLayers.map(n => ({ ref_id: n })))
          return this.service.patchPortfolio({
            id: portfolioID,
            change: { layers: newLayers },
          })
        }
      })
    )
  }

  getPremium(
    sharedLayerMetrics: Metrics,
    backAllocatedMetrics: Metrics,
    sharedLayerPremium: number,
    allLayersTotalPremium: number,
    currentLayerPremium: number
  ) {
    let premium = 0
    if (sharedLayerMetrics.mean > 0) {
      premium =
        (backAllocatedMetrics.mean / sharedLayerMetrics.mean) *
        sharedLayerPremium
    } else {
      premium =
        (currentLayerPremium / allLayersTotalPremium) * sharedLayerPremium
    }
    return Math.abs(premium)
  }

  updateNestedLayer(
    nestedLayer: LogicalPortfolioLayer,
    analysisProfileID: string,
    sharedLayerMetrics: Metrics,
    sharedLayer: Layer
  ) {
    const sharedCurrency = sharedLayer.physicalLayer.franchise.currency
    return this.service
      .postLayerView(
        nestedLayer.meta_data.backAllocatedForID!,
        analysisProfileID,
        sharedCurrency
      )
      .pipe(
        switchMap(res => {
          if (res.error) {
            return of({
              error: res.error,
            }) as ApiResponse<LogicalPortfolioLayer>
          } else {
            const layerViewID = res.data!.id
            const backAllocatedLayer = (
              nestedLayer.sources as LogicalPortfolioLayer[]
            ).find(s => s._type === 'BackAllocatedLayer')!
            backAllocatedLayer.source_id = layerViewID
            return this.service.patchLogicalPortfolioLayer({
              id: backAllocatedLayer.id,
              change: { source_id: layerViewID },
            })
          }
        }),
        switchMap(res => {
          if (res.error) {
            return of({ error: res.error }) as ApiResponse<
              LayerViewResponse & { layerID: string }
            >
          } else {
            return this.service.postLayerView(
              res.data!.id,
              analysisProfileID,
              sharedCurrency
            )
          }
        }),
        switchMap(res => {
          if (res.error) {
            return of({ error: res.error }) as ApiResponse<Metrics>
          } else {
            return this.service.getMeanLossMetrics(res.data!.id)
          }
        }),
        switchMap(res => {
          return this.service
            .fetchLayers<LogicalPortfolioLayer>(sharedLayer.layerRefs)
            .pipe(
              map(layersResponse => {
                if (layersResponse.error) {
                  return { error: layersResponse.error }
                } else {
                  return { ...res, allLayers: layersResponse.data! }
                }
              })
            )
        }),
        switchMap(
          (
            res: MaybeData<Metrics> &
              MaybeError & { allLayers: LogicalPortfolioLayer[] }
          ) => {
            if (res.error) {
              return of({ error: res.error }) as ApiResponse<LossSetLayer>
            } else {
              const allPremiumTotal = res.allLayers.reduce(
                (acc, next) =>
                  acc + (next.sink as PhysicalPortfolioLayer).premium.value,
                0
              )
              const currentLayer = res.allLayers.find(
                l => l.id === nestedLayer.meta_data.backAllocatedForID
              )!
              const currentLayerPremium = (
                currentLayer.sink as PhysicalPortfolioLayer
              ).premium.value
              const premium = this.getPremium(
                sharedLayerMetrics,
                res.data!,
                sharedLayer.physicalLayer.premium.value,
                allPremiumTotal,
                currentLayerPremium
              )
              const quotaShare = nestedLayer.sink as LossSetLayer
              return this.service.patchLayer<LossSetLayer>({
                id: quotaShare.id,
                change: { premium: { ...quotaShare.premium, value: premium } },
              })
            }
          }
        )
      )
  }

  removeNestedLayersAndRestore(
    portfolioID: string,
    nestedLayerRecord: Record<string, string[]>,
    cededPortfolio: boolean
  ): ApiResponse<Portfolio> {
    return this.service.fetchPortfolio(portfolioID).pipe(
      switchMap(res => {
        const nestedLayersIDs = nestedLayerRecord[portfolioID]
        if (res.error) {
          return of({ error: res.error })
        } else {
          const portfolio = res.data!
          const portfolioLayers = portfolio.layers as LogicalPortfolioLayer[]
          const nestedLayers = portfolioLayers.filter(l =>
            nestedLayersIDs.includes(l.id)
          )

          // Validate deletion order
          const validationPayload = sharedLimitValidationToErrorPayload(
            this.validateSharedLimitDelete(nestedLayers)
          )
          if (validationPayload) {
            return of({ error: validationPayload })
          }

          const layers = nestedLayers.map(n => n.meta_data.backAllocatedForID!)
          const newLayers = [
            ...portfolioLayers
              .map(l => l.id)
              .filter(id => !nestedLayersIDs.includes(id)),
            ...layers,
          ]
          let participationUpdates: ApiResponse<PhysicalPortfolioLayer[]>
          if (cededPortfolio) {
            participationUpdates = this.service
              .fetchLayers<LogicalPortfolioLayer>(layers)
              .pipe(
                switchMap(fetchResponse => {
                  if (fetchResponse.error) {
                    return of({ error: fetchResponse.error })
                  } else {
                    const physicalUpdates: Update<PhysicalPortfolioLayer>[] =
                      fetchResponse.data!.map(l => {
                        const physicalLayer = l.sink as PhysicalPortfolioLayer
                        if (physicalLayer) {
                          return {
                            id: physicalLayer.id,
                            change: {
                              participation: physicalLayer.participation * -1,
                            },
                          }
                        } else {
                          return {
                            id: '',
                            change: {},
                          }
                        }
                      })
                    return this.service.patchPhysicalPortfolioLayers(
                      physicalUpdates
                    )
                  }
                })
              )
          } else {
            participationUpdates = of({})
          }
          return forkJoin([
            participationUpdates,
            this.service.updatePortfolioLayers(portfolioID, uniq(newLayers)),
          ]).pipe(
            map(([phyUpdateResponse, portfolioResponse]) => {
              if (phyUpdateResponse.error || portfolioResponse.error) {
                return {
                  error: phyUpdateResponse.error || portfolioResponse.error,
                }
              } else {
                return portfolioResponse
              }
            })
          )
        }
      })
    )
  }

  buildNestedLayerRecords = (
    nestedLayers: LogicalPortfolioLayer[],
    cededPortfolioRecord: Record<string, string[]>,
    netPortfolioRecord: Record<string, string[]>
  ) => {
    const allNestedLayers = nestedLayers.map(n => n.id)
    const cededPortfolioNestedLayerRecord = Object.keys(
      cededPortfolioRecord
    ).reduce((acc, curr) => {
      const portfolioNestedLayers = cededPortfolioRecord[curr].filter(l =>
        allNestedLayers.includes(l)
      )
      acc[curr] = portfolioNestedLayers
      return acc
    }, {} as Record<string, string[]>)
    const netPortfolioNestedLayerRecord = Object.keys(
      netPortfolioRecord
    ).reduce((acc, curr) => {
      const portfolioNestedLayers = netPortfolioRecord[curr].filter(l =>
        allNestedLayers.includes(l)
      )
      acc[curr] = portfolioNestedLayers
      return acc
    }, {} as Record<string, string[]>)
    return { cededPortfolioNestedLayerRecord, netPortfolioNestedLayerRecord }
  }

  buildLayerRecords = (
    selectedLayerEntities: SharedLimitLayerSelection[],
    nestedLayers: LogicalPortfolioLayer[]
  ) => {
    const uniqueSelectedLayerEntities = uniqBy(
      e => e.cededPortfolioID,
      selectedLayerEntities
    )
    const cededPortfolioRecord: Record<string, string[]> = {}
    const netPortfolioRecord: Record<string, string[]> = {}
    uniqueSelectedLayerEntities.forEach(u => {
      cededPortfolioRecord[u.cededPortfolioID] = u.cededLayers
        .map(l => l.layer)
        .filter(filterValid)
        .map(l => l.id)
      netPortfolioRecord[u.netPortfolioID] = u.netPortfolioLayersIDs
    })
    nestedLayers.forEach(n => {
      const entity = selectedLayerEntities.find(
        e => e.layerID === n.meta_data.backAllocatedForID
      )!
      const cededPortfolioID = entity.cededPortfolioID
      const netPortfolioID = entity.netPortfolioID
      const nestedLayerID = n.id
      const removeID = entity.layerID
      cededPortfolioRecord[cededPortfolioID] = [
        ...cededPortfolioRecord[cededPortfolioID],
        nestedLayerID,
      ].filter(id => id !== removeID)
      netPortfolioRecord[netPortfolioID] = [
        ...netPortfolioRecord[netPortfolioID],
        nestedLayerID,
      ].filter(id => id !== removeID)
    })

    return {
      cededPortfolioRecord,
      netPortfolioRecord,
    }
  }

  createBackAllocatedLayer = (
    layer: Layer,
    sharedLimitLayer: LogicalPortfolioLayer,
    layerViewID: string
  ): Partial<LogicalPortfolioLayer> => {
    const {
      cascadeAttachment,
      cascadeLowerLayerID,
      oldDropSelected,
      prevID,
      inuranceSource,
      inuranceSourceFor,
      inuranceTarget,
      inuranceTargetFor,
      reinsurers,
      reinsurer_is_default,
      structureID,
      isDrop,
      topID,
      sharedLimitHidden,
      ...rest
    } = layer.meta_data
    return {
      _type: 'BackAllocatedLayer',
      description: 'Back Allocated Layer',
      meta_data: {
        ...rest,
        sage_layer_type: 'shared_limit',
        sage_layer_subtype: 'backallocated',
        backAllocatedForID: layer.id,
        layerName: layer.physicalLayer.description || '',
      },
      sink: { ref_id: sharedLimitLayer.id },
      source_id: layerViewID,
    }
  }

  createQuotaShare = (
    premium: number,
    layerID: string,
    sharedCurrency: string
  ): Partial<LossSetLayer> => {
    return {
      _type: 'QuotaShare',
      loss_sets: [],
      policy: null,
      premium: {
        value:
          isNaN(premium) || premium === null || premium === undefined
            ? 0
            : premium,
        currency: sharedCurrency,
      },
      fees: [],
      event_limit: {
        value: 1.7976931348623157e308,
        currency: sharedCurrency,
      },
      participation: -1,
      meta_data: {
        backAllocatedForID: layerID,
      },
      description: null,
    }
  }

  createNestedLayer = (
    quotaShareLayerID: string,
    backallocatedLayer: LogicalPortfolioLayer,
    entityId: string
  ): Partial<LogicalPortfolioLayer> => {
    const sharedLayer = backallocatedLayer.sink as LogicalPortfolioLayer
    const layer = (sharedLayer.sources as LogicalPortfolioLayer[]).find(
      l => l.id === backallocatedLayer.meta_data.backAllocatedForID
    ) as LogicalPortfolioLayer
    const {
      cascadeAttachment,
      cascadeLowerLayerID,
      oldDropSelected,
      prevID,
      reinsurers,
      reinsurer_is_default,
      isDrop,
      topID,
      sharedLimitHidden,
      ...rest
    } = layer.meta_data
    return {
      _type: 'NestedLayer',
      description: 'Allocated Virtual',
      meta_data: {
        ...rest,
        inuranceSource: false,
        inuranceTarget: false,
        inuranceSourceFor: undefined,
        inuranceTargetFor: undefined,
        sage_layer_type: 'shared_limit',
        sage_layer_subtype: 'backallocated',
        backAllocatedForID: backallocatedLayer.meta_data.backAllocatedForID!,
        layerName: backallocatedLayer.meta_data.layerName || '',
        structureID: backallocatedLayer.meta_data.structureID || entityId,
      },
      sink: { ref_id: quotaShareLayerID },
      sources: [{ ref_id: backallocatedLayer.id }],
    }
  }

  deleteSharedLimit(
    sharedLayer: Layer,
    sharedLimits: SharedLimit[] | null,
    sharedLimitMembers: SharedLimitMember[] | null
  ): any {
    if (
      sharedLayer &&
      sharedLimits &&
      sharedLimits.length > 0 &&
      sharedLimitMembers &&
      sharedLimitMembers.length > 0
    ) {
      const slID = sharedLimits.find(
        sl => sl.sl_layer_id === sharedLayer.id
      )?.id
      const slmIDs: number[] = []
      sharedLimitMembers.forEach(slm => {
        if (slm.sl_id === slID) {
          slmIDs.push(parseInt(slm.id, 10))
        }
      })
      if (slID && slmIDs) {
        return this.deleteSLMembers(slmIDs)
          .pipe(
            map(response => ({
              ...response,
            }))
          )
          .pipe(
            mergeMap(() => {
              return this.deleteSL(parseInt(slID, 10)).pipe(
                map(response => ({
                  ...response,
                }))
              )
            }),
            map(() => {
              const slFinal: SharedLimit[] | null = clone(sharedLimits).filter(
                sl => sl.id !== slID
              )
              const slmFinal: SharedLimitMember[] | null = clone(
                sharedLimitMembers
              ).filter(slm => !slmIDs.includes(parseInt(slm.id, 10)))
              this.store.dispatch(
                SharedLimitActions.updateSLState({
                  sharedLimits: slFinal,
                  sharedLimitMembers: slmFinal,
                })
              )
            })
          )
      }
    }
  }

  addSharedLimit(
    sharedLayer: Layer,
    selectedLayerEntities: SharedLimitLayerSelection[]
  ): ApiResponse<{ cededPortfolios: Portfolio[]; netPortfolios: Portfolio[] }> {
    const sharedCurrency = sharedLayer.physicalLayer.franchise.currency

    const selectedLayers: LayerState[] = selectedLayerEntities.map(
      s => s.cededLayers.find(l => l.layer.id === s.layerID)!
    )

    // Validate order of SL/Inurance
    const validationPayload = sharedLimitValidationToErrorPayload(
      this.validateSharedLimit(selectedLayers)
    )
    if (validationPayload) {
      return of({ error: validationPayload })
    }
    const phyUpdates: Update<PhysicalPortfolioLayer>[] = selectedLayers.map(
      l => ({
        id: l.layer.physicalLayer.id,
        change: {
          participation: Math.abs(l.layer.physicalLayer.participation),
        },
      })
    )
    return this.service.patchPhysicalPortfolioLayers(phyUpdates).pipe(
      map(physicalUpdateResults => {
        if (physicalUpdateResults.error) {
          return {
            error: physicalUpdateResults.error,
          }
        } else {
          return {}
        }
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error }) as ApiResponse<LogicalPortfolioLayer>
        }
        return this.service.createFullLayer({
          ...sharedLayer,
          physicalLayer: {
            ...sharedLayer.physicalLayer,
            participation: Math.abs(sharedLayer.physicalLayer.participation),
          },
          meta_data: {
            ...sharedLayer.meta_data,
            analysisProfileID: selectedLayerEntities[0].analysisID,
          },
        })
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error }) as Observable<
            MaybeError &
              MaybeData<LayerViewResponse & { layerID: string }> & {
                layer: LogicalPortfolioLayer
              }
          >
        }
        return this.service
          .postLayerView(
            res.data!.id,
            selectedLayerEntities[0].analysisID,
            sharedCurrency
          )
          .pipe(map(r => ({ ...r, layer: res.data! })))
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error }) as Observable<
            MaybeError &
              MaybeData<Metrics> & {
                layer: LogicalPortfolioLayer
              }
          >
        }
        return this.service
          .getMeanLossMetrics(res.data!.id)
          .pipe(
            map(metricResponse => ({ ...metricResponse, layer: res.layer }))
          )
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error })
        }
        return this.addBackAllocatedLayer(
          res.layer,
          res.data!,
          selectedLayerEntities,
          sharedCurrency
        )
      })
    )
  }

  private addBackAllocatedLayer(
    sharedLayer: LogicalPortfolioLayer,
    metrics: Metrics,
    selectedLayerEntities: SharedLimitLayerSelection[],
    sharedCurrency: string
  ) {
    const observables: Array<
      ApiResponse<
        LayerViewResponse & {
          layerID: string
        }
      >
    > = []

    // For each selected Layer, get the LayerView.
    // The LayerViewID is later set to the source_id
    // property of the BackAllocated Layers that are created.
    selectedLayerEntities.forEach(entity => {
      observables.push(
        this.service
          .postLayerView(entity.layerID, entity.analysisID, sharedCurrency)
          .pipe(map(res => ({ ...res, layerID: entity.layerID })))
      )
    })
    return forkJoin(observables).pipe(
      map(res => ({ results: res })),
      mergeMap(res => {
        for (const result of res.results) {
          if (result.error) {
            return of({ error: result.error }) as ApiResponse<
              LogicalPortfolioLayer[]
            >
          }
        }
        const layerViewResponses: Array<
          LayerViewResponse & {
            layerID: string
          }
        > = []
        for (const result of res.results) {
          layerViewResponses.push(result.data!)
        }

        const backAllocatedLayers = selectedLayerEntities.map(entity => {
          const layer = entity.cededLayers.find(
            l => l.layer.id === entity.layerID
          )!.layer
          const layerViewResponse = layerViewResponses.find(
            l => l.layerID === layer.id
          )!
          return this.createBackAllocatedLayer(
            layer,
            sharedLayer,
            layerViewResponse.id
          )
        })
        return this.service.postLogicalPortfolioLayers(backAllocatedLayers)
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error })
        }
        return this.addQuotaShare(
          res.data!,
          metrics,
          sharedLayer,
          selectedLayerEntities,
          sharedCurrency
        )
      })
    )
  }

  private addQuotaShare(
    backAllocatedLayers: LogicalPortfolioLayer[],
    hiddenLayerMetrics: Metrics,
    hiddenLayer: LogicalPortfolioLayer,
    selectedLayerEntities: SharedLimitLayerSelection[],
    sharedCurrency: string
  ) {
    const observables: Array<ApiResponse<LossSetLayer>> = []
    backAllocatedLayers.forEach(backAllocated => {
      const analysisID = selectedLayerEntities.find(
        l => l.layerID === backAllocated.meta_data.backAllocatedForID
      )!.analysisID
      observables.push(
        this.service
          .postLayerView(backAllocated.id, analysisID, sharedCurrency)
          .pipe(
            mergeMap(res => {
              if (res.error) {
                return of({ error: res.error }) as ApiResponse<Metrics>
              } else {
                return this.service.getMeanLossMetrics(res.data!.id)
              }
            }),
            mergeMap(res => {
              if (res.error) {
                return of({ error: res.error }) as ApiResponse<LossSetLayer>
              } else {
                const allSelectedLayers = selectedLayerEntities.map(
                  e => e.cededLayers.find(c => c.layer.id === e.layerID)!
                )
                const selectedLayerEntity = selectedLayerEntities.find(
                  e => e.layerID === backAllocated.meta_data.backAllocatedForID
                )!
                const selectedLayer = selectedLayerEntity.cededLayers.find(
                  c => c.layer.id === selectedLayerEntity.layerID
                )!
                const allPremiumTotal = allSelectedLayers
                  .map(l => l.layer)
                  .reduce(
                    (acc, next) => acc + next.physicalLayer.premium.value,
                    0
                  )
                const premium = this.getPremium(
                  hiddenLayerMetrics,
                  res.data!,
                  (hiddenLayer.sink as PhysicalPortfolioLayer).premium.value,
                  allPremiumTotal,
                  selectedLayer.layer.physicalLayer.premium.value
                )
                const quotaShareLayer = this.createQuotaShare(
                  premium,
                  backAllocated.meta_data.backAllocatedForID!,
                  sharedCurrency
                )
                return this.service.postLossSetLayer(quotaShareLayer)
              }
            })
          )
      )
    })
    return forkJoin(observables).pipe(
      map(res => ({
        results: res,
      })),
      mergeMap(res => {
        for (const result of res.results) {
          if (result.error) {
            return of({ error: result.error })
          }
        }
        return this.addNestedLayer(
          backAllocatedLayers,
          hiddenLayer,
          selectedLayerEntities,
          res.results.map(r => r.data!)
        )
      })
    )
  }

  private addNestedLayer(
    backAllocatedLayers: LogicalPortfolioLayer[],
    hiddenLayer: LogicalPortfolioLayer,
    selectedLayerEntities: SharedLimitLayerSelection[],
    quotaShareLayers: LossSetLayer[]
  ) {
    const nestedLayers = backAllocatedLayers.map(backAllocated => {
      const backAllocatedId = backAllocated.meta_data.backAllocatedForID
      const selection = selectedLayerEntities.find(
        s => s.layerID === backAllocatedId
      )
      const quotaShare = quotaShareLayers.find(
        q => q.meta_data.backAllocatedForID === backAllocatedId
      )
      return this.createNestedLayer(
        quotaShare!.id,
        backAllocated,
        selection?.entityID ?? ''
      )
    })
    return this.service.postLogicalPortfolioLayers(nestedLayers).pipe(
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error })
        }
        const actions: ApiResponse<SharedLimitLayerSelection>[] = []
        selectedLayerEntities.forEach(entity => {
          actions.push(
            this.service
              .fetchPortfolios([entity.cededPortfolioID, entity.netPortfolioID])
              .pipe(
                map(portfolios => {
                  if (portfolios.error) {
                    return { error: portfolios.error }
                  }
                  const cededLayers = convertFromLogicalPortfolioLayers(
                    portfolios.data![0].layers as LogicalPortfolioLayer[]
                  ).map(l => ({
                    layer: l,
                    dirty: false,
                    new: false,
                    deleted: false,
                    hash: '',
                  }))
                  const netLayers = portfolios.data![1]
                    .layers as LogicalPortfolioLayer[]
                  return {
                    data: {
                      ...entity,
                      cededLayers,
                      netPortfolioLayersIDs: netLayers.map(n => n.id),
                    },
                  }
                })
              ) as ApiResponse<SharedLimitLayerSelection>
          )
        })
        return forkJoin(actions).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClientID)),
            this.store.pipe(select(selectCurrentYearID)),
            this.store.pipe(select(selectSharedLimits)),
            this.store.pipe(select(selectSharedLimitMembers))
          ),
          mergeMap(([actionRes, clientID, yearID, limits, limitMembers]) => {
            for (const a of actionRes) {
              if (a.error) {
                return of({ error: a.error })
              }
            }
            const body: OmitID<SharedLimit> = {
              sl_layer_id: hiddenLayer.id,
              sl_name:
                (hiddenLayer.sink as PhysicalPortfolioLayer).description || '',
              carrier_id: parseInt(clientID!, 10) || 0,
              carrier_year_id: parseInt(yearID!, 10) || 0,
              analysis_profile_id: hiddenLayer.meta_data.analysisProfileID,
            }
            return this.createSL(body)
              .pipe(
                map(response => ({
                  ...response,
                }))
              )
              .pipe(
                mergeMap(data => {
                  const slBody: OmitID<SharedLimitMember>[] = []
                  if (res.data && res.data.length > 0) {
                    res.data.forEach(slm => {
                      const slmBody: OmitID<SharedLimitMember> = {
                        sl_id: data.id,
                        layer_id: slm.meta_data.backAllocatedForID,
                        layer_name: slm.meta_data.layerName,
                        backallocated_layer_id: slm.id,
                        carrier_id: parseInt(clientID!, 10) || 0,
                        structure_id: slm.meta_data.structureID || '',
                      }
                      slBody.push(slmBody)
                    })
                  }
                  return this.createSLMembers(slBody)
                    .pipe(
                      map(response => ({
                        ...response,
                      }))
                    )
                    .pipe(
                      mergeMap(slmData => {
                        const refreshedSelectedLayerEntities: SharedLimitLayerSelection[] =
                          actionRes.map(a => a.data!)
                        const slFinal: SharedLimit[] | null = clone(limits)
                        slFinal?.push(data)
                        const slmFinal: SharedLimitMember[] | null =
                          clone(limitMembers)
                        if (
                          Object.values(slmData) &&
                          Object.values(slmData).length > 0
                        ) {
                          Object.values(slmData).forEach(slm =>
                            slmFinal?.push(slm as SharedLimitMember)
                          )
                        }
                        return this.saveSharedLayer(
                          hiddenLayer,
                          refreshedSelectedLayerEntities,
                          res.data!,
                          slFinal,
                          slmFinal
                        )
                      })
                    )
                })
              )
          })
        )
      })
    )
  }

  private saveSharedLayer(
    hiddenLayer: LogicalPortfolioLayer,
    selectedLayerEntities: SharedLimitLayerSelection[],
    nestedLayers: LogicalPortfolioLayer[],
    slData: SharedLimit[] | null,
    slmData: SharedLimitMember[] | null
  ) {
    const { cededPortfolioRecord, netPortfolioRecord } = this.buildLayerRecords(
      selectedLayerEntities,
      nestedLayers
    )
    const { cededPortfolioNestedLayerRecord, netPortfolioNestedLayerRecord } =
      this.buildNestedLayerRecords(
        nestedLayers,
        cededPortfolioRecord,
        netPortfolioRecord
      )
    const nestedLayersCededPortfolioRecord = JSON.stringify(
      cededPortfolioNestedLayerRecord
    )
    const nestedLayersNetPortfolioRecord = JSON.stringify(
      netPortfolioNestedLayerRecord
    )
    this.store.dispatch(
      SharedLimitActions.updateSLState({
        sharedLimits: slData,
        sharedLimitMembers: slmData,
      })
    )
    const update: Update<LogicalPortfolioLayer> = {
      id: hiddenLayer.id,
      change: {
        meta_data: {
          ...hiddenLayer.meta_data,
          nestedLayersCededPortfolioRecord,
          nestedLayersNetPortfolioRecord,
        },
      },
    }
    return this.service
      .patchLogicalPortfolioLayer(update)
      .pipe(
        map(res => ({
          ...res,
          data: {
            results: res.data,
            cededPortfolioRecord,
            netPortfolioRecord,
            cededPortfolioNestedLayerRecord,
            netPortfolioNestedLayerRecord,
            nestedLayers,
          },
        }))
      )
      .pipe(
        mergeMap(res => {
          if (res.error) {
            return of({ error: res.error }) as ApiResponse<{
              cededPortfolios: Portfolio[]
              netPortfolios: Portfolio[]
            }>
          }
          const cededUpdates: Array<ApiResponse<Portfolio>> = []
          const netUpdates: Array<ApiResponse<Portfolio>> = []
          Object.keys(cededPortfolioRecord).forEach(portfolioID => {
            cededUpdates.push(
              this.service
                .updatePortfolioLayers(
                  portfolioID,
                  uniq(cededPortfolioRecord[portfolioID])
                )
                .pipe(this.service.appendAggFeederAndRiskVisible())
            )
          })
          Object.keys(netPortfolioRecord).forEach(portfolioID => {
            netUpdates.push(
              this.service.updatePortfolioLayers(
                portfolioID,
                uniq(netPortfolioRecord[portfolioID])
              )
            )
          })

          return forkJoin([forkJoin(cededUpdates), forkJoin(netUpdates)]).pipe(
            map(([cededResults, netResults]) => {
              for (const result of [...cededResults, ...netResults]) {
                if (result.error) {
                  return { error: result.error } as MaybeError &
                    MaybeData<{
                      cededPortfolios: Portfolio[]
                      netPortfolios: Portfolio[]
                    }>
                }
              }
              return {
                data: {
                  cededPortfolios: cededResults.map(r => r.data!),
                  netPortfolios: netResults.map(r => r.data!),
                },
              }
            })
          ) as ApiResponse<{
            cededPortfolios: Portfolio[]
            netPortfolios: Portfolio[]
          }>
        }),
        withLatestFrom(
          this.store.pipe(select(selectPrograms)),
          this.store.pipe(select(selectProgramGroupsByID)),
          this.store.pipe(select(selectProgramGroupMembers))
        ),
        mergeMap(
          ([res, structures, structureGroupsByID, structureGroupMembers]) => {
            if (res.error) {
              return of({ error: res.error })
            }
            return this.reconcileSharedLimitWithGroup(
              cededPortfolioNestedLayerRecord,
              nestedLayers,
              structures,
              structureGroupsByID,
              structureGroupMembers,
              true
            ).pipe(
              map(result => {
                if (result.error) {
                  return { error: result.error }
                } else {
                  return {
                    data: {
                      cededPortfolios: res.data!.cededPortfolios,
                      netPortfolios: res.data!.netPortfolios,
                    },
                  }
                }
              })
            )
          }
        )
      )
  }

  private validateSharedLimit(layers: LayerState[]): SharedLimitValidation {
    const validation: SharedLimitValidation = {
      isILW: false,
      hasInuranceSource: false,
      hasInuranceSourceDelete: false,
    }
    for (const layer of layers) {
      if (
        layer.layer.meta_data.sage_layer_type &&
        ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
          layer.layer.meta_data.sage_layer_type
        )
      ) {
        validation.isILW = true
        break
      }
      if (layer.layer.meta_data.inuranceSource) {
        validation.hasInuranceSource = true
        break
      }
    }
    return validation
  }

  private validateSharedLimitDelete(
    layers: LogicalPortfolioLayer[]
  ): SharedLimitValidation {
    const validation: SharedLimitValidation = {
      isILW: false,
      hasInuranceSource: false,
      hasInuranceSourceDelete: false,
    }
    for (const layer of layers) {
      if (
        layer.meta_data.sage_layer_type &&
        ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
          layer.meta_data.sage_layer_type
        )
      ) {
        validation.isILW = true
        break
      }
      if (layer.meta_data.inuranceSource) {
        validation.hasInuranceSourceDelete = true
        break
      }
    }
    return validation
  }

  private createSL(body: OmitID<SharedLimit>): Observable<SharedLimit> {
    const url = `${environment.internalApi.base}${environment.internalApi.sharedLimit}`
    return this.http
      .post<SharedLimit>(url, body)
      .pipe(catchAndHandleError('Create New Shared Limits'))
  }

  private createSLMembers(
    slMembers: OmitID<SharedLimitMember>[]
  ): Observable<any> {
    return forkJoin(slMembers.map(sl => this.createSLMember(sl)))
  }

  private createSLMember(
    body: OmitID<SharedLimitMember>
  ): Observable<SharedLimitMember> {
    const url = `${environment.internalApi.base}${environment.internalApi.sharedLimitMembers}`
    return this.http
      .post<SharedLimitMember>(url, body)
      .pipe(catchAndHandleError('Create New Shared Limit Member'))
  }

  private deleteSL(id: number): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.sharedLimit}/${id}`
    return this.http
      .delete(url)
      .pipe(catchAndHandleError('Delete New Shared Limits'))
  }

  private deleteSLMembers(ids: number[]): Observable<any> {
    return forkJoin(ids.map(sl => this.deleteSLMember(sl)))
  }

  private deleteSLMember(id: number): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.sharedLimitMembers}/${id}`
    return this.http
      .delete(url)
      .pipe(catchAndHandleError('Delete Shared Limit Member'))
  }

  updateSL(body: OmitID<SharedLimit>, id: string): Observable<SharedLimit> {
    const url = `${environment.internalApi.base}${environment.internalApi.sharedLimit}/${id}`
    return this.http
      .put<SharedLimit>(url, body)
      .pipe(catchAndHandleError('Update Shared Limits'))
  }
}
