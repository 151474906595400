import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'

@Component({
  selector: 'app-quote-push-ws-confirmation-dialog',
  templateUrl: './quote-push-ws-confirmation-dialog.component.html',
  styleUrls: ['./quote-push-ws-confirmation-dialog.component.scss'],
})
export class QuotePushWsConfirmationDialogComponent implements OnInit {
  rows: SortTableRow[]
  emptyTpRefWarning = false

  columnDefs: SortTableColumnDef[] = []
  constructor(
    private dialogRef: MatDialogRef<QuotePushWsConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      signedLines: Map<string, Map<string, number>> // Map<marketTPRef, Map<LayerName, SignedValue>>
      tpRefLookup: Map<string, string> // Map<marketTPRef, reinsurerName>
    }
  ) {}

  ngOnInit(): void {
    this.structureSignedLines()
    this.emptyTpRefWarning = this.data.tpRefLookup.has('')
  }

  structureSignedLines(): void {
    const data = this.data.signedLines
    this.columnDefs.push({
      id: 'reinsurer',
      label: 'Reinsurer',
      valueType: 'text',
    })
    const layers: SortTableRow[] = []
    data.forEach((layerMapping, reinsurer) => {
      layerMapping.forEach((signed, layer) => {
        if (!this.columnDefs.find(x => x.id === layer)) {
          this.columnDefs.push({
            id: layer,
            label: layer,
            valueType: 'percentage',
          })
        }

        const foundLayer = layers.find(x => x.tpRef === reinsurer)
        if (foundLayer) {
          foundLayer[layer] = signed
        } else {
          layers.push({
            tpRef: reinsurer,
            reinsurer: this.data.tpRefLookup.get(reinsurer) || reinsurer,
            [layer]: signed,
          })
        }
      })
    })
    this.rows = layers
  }

  confirm(): void {
    this.dialogRef.close('Confirmed')
  }
}
