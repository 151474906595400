import { Component, Input, OnInit } from '@angular/core'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { CurrencyRate } from 'src/app/analysis/tower/mechanics/tower.model'
import { AnalysisProfile } from 'src/app/api/analyzere/analyzere.model'

@Component({
  selector: 'app-portfolio-exchange-dialog',
  templateUrl: './portfolio-exchange-dialog.component.html',
  styleUrls: ['./portfolio-exchange-dialog.component.scss'],
})
export class PortfolioExchangeDialogComponent implements OnInit {
  @Input() currentCurrency: string
  @Input() analysisProfile: AnalysisProfile
  @Input() exchangeRateData: CurrencyRate[]
  description: string
  trialCount: number
  rows: CurrencyRate[]
  date: Date | string
  columnDefs: SortTableColumnDef[] = [
    {
      id: 'Currency',
      label: 'Currency',
      editable: false,
      minWidth: '4rem',
    },
    {
      id: 'Rate',
      label: 'Rate',
      editable: false,
      minWidth: '4rem',
    },
  ]

  ngOnInit(): void {
    this.date = this.exchangeRateData[0].Date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    this.rows = this.exchangeRateData
    this.description = this.analysisProfile.description
    this.trialCount = this.analysisProfile.simulation.trial_count
  }

  createFilter(value: string) {
    if (value) {
      this.rows = this.exchangeRateData.filter(rate => {
        const currencyFound = rate.Currency.toLocaleLowerCase().includes(
          value.toLocaleLowerCase()
        )
        const rateFound = String(rate.Rate).includes(value)
        return currencyFound || rateFound
      })
    } else {
      this.rows = this.exchangeRateData
    }
  }
}
