import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { DonutChart } from '@graphing/donut-chart'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import {
  SummaryChartInfo,
  SummaryChartGridOption,
  SummaryChartGridMetric,
} from '../summary-charts-model'
import html2canvas from 'html2canvas'
import { currencySymbol } from 'src/app/analysis/model/layers.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-donut-pie-chart',
  styles: [
    `
      .app-chart ::ng-deep d3fc-group.cartesian-chart {
        grid-template-columns: 0 auto 1fr;
        grid-template-rows: minmax(1em, max-content) auto 1fr 1em 0.5em;
      }

      :host.has-y-label .app-chart ::ng-deep d3fc-group.cartesian-chart {
        grid-template-columns: 1.5em auto 1fr;
      }

      :host.show-bar-series .app-chart ::ng-deep d3fc-group.cartesian-chart {
        grid-template-columns: 0 auto 1fr auto minmax(1em, max-content);
      }

      :host.has-y-label.show-bar-series
        .app-chart
        ::ng-deep
        d3fc-group.cartesian-chart {
        grid-template-columns: minmax(1em, max-content) auto 1fr auto minmax(
            1em,
            max-content
          );
      }
      .app-chart {
        background-color: black;
        padding: 25px 10px;
      }
      .white-background {
        color: black;
        background-color: white !important;
      }
    `,
  ],
  template: `
    <app-explore-summary-chart-grid-item-frame
      [chartOption]="chartOption"
      [hasData]="chartOption.data?.data.length > 0"
      [selectableChartsForTab]="selectableChartsForTab"
      [selectableChartsForMetric]="selectableChartsForMetric"
      [maximizedIndex]="maximizedIndex"
      [canHideMetrics]="false"
      [hideMetrics]="hideMetrics"
      (updateHideMetrics)="onUpdateHideMetrics($event)"
      (chartOptionUpdate)="chartOptionUpdate.emit($event)"
      (maximizeClick)="maximizeClick.emit($event)"
      (onExportClick)="downloadPNG()"
    >
      <div
        id="chart"
        #chart
        [ngClass]="{
          'app-chart': true,
          'app-chart-tiny-annotation': chartOption.index !== maximizedIndex,
          'white-background': lightChartMode,
        }"
      ></div>
    </app-explore-summary-chart-grid-item-frame>
  `,
})
export class ExploreSummaryDonutPieChartComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges
{
  @Input() chartOption: SummaryChartGridOption
  @Input() maximizedIndex: number
  @Input() chartEntityLimit: number
  @Input() selectableChartsForTab: SummaryChartInfo[]
  @Input() selectableChartsForMetric: SummaryChartInfo[]
  @Input() drawDebounce = 250
  @Input() hideMetrics: boolean
  @Input() lightChartMode: boolean
  @Input() pieChart: boolean
  @Input() roundedToAbrev: string
  @Input() currentStructureCurrency: string | undefined
  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  @ViewChild('chart', { static: false }) chartEl: ElementRef<HTMLDivElement>

  chart: DonutChart
  draw$ = new Subject<void>()
  destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.draw$
      .pipe(takeUntil(this.destroy$), debounceTime(this.drawDebounce))
      .subscribe(() => this.draw())
  }

    ngAfterViewInit(): void {
    this.selectableChartsForMetric = this.chartOption.metric.applicableCharts
    this.draw$.next()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideMetrics || changes.lightChartMode) {
      this.draw$.next()
    }
    if (changes.chartEntityLimit || changes.maximizedIndex) {
      setTimeout(() => this.draw(), 500)
    }
  }

  onUpdateHideMetrics(hideMetrics = false): void {
    this.updateHideMetrics.emit(hideMetrics)
  }

  downloadPNG(): void {
    const pngEl: HTMLElement | null = document.querySelector('#chart')
    if (pngEl) {
      html2canvas(pngEl).then(canvas => {
        const image = canvas.toDataURL('image/png')
        const yLabel = this.chartOption.metric.name
        const xLabel = ''
        const chartLabel = `${yLabel}`
        const a = document.createElement('a')
        a.setAttribute('download', `${chartLabel}.png`)
        a.setAttribute('href', image)
        a.click()
      })
    }
  }

  draw(): void {
    if (!this.chartEl) {
      return
    }
    const min = this.chartOption.index !== this.maximizedIndex
    const container = this.chartEl.nativeElement
    const mapped = this.getHighestValuesForLegend()
    const mappedKeys = Object.keys(mapped)
    const mappedData = Object.values(mapped)
    const metricName = this.chartOption.metric.name
    const chartLabel = `${metricName}`
    this.chart = new DonutChart(container, {
      chartLabel: !min ? chartLabel : undefined,
      legendValues: this.getLegendValues(mappedKeys),
      legendLimit: this.chartEntityLimit,
      legendTextLimit: min ? 16 : 100,
      min,
      lightChartMode: this.lightChartMode,
      margin: { top: min ? 50 : 100, right: 100, bottom: 50, left: 50 },
      showDataLabels: true,
      pieChart: this.pieChart,
      currencyFormat: this.currencyFormat,
      hideToolTipRawData: this.chartOption.metric.columnName.includes('Volatility')
    })

    this.chart.draw({
      values: mappedData,
    })
  }

  getLegendValues(values: string[]): string[] {
    return values.map((v, i) => {
      return this.hideMetrics
        ? `${i + 1}`
        : v
    })
  }

  getHighestValuesForLegend(): Record<string, number> {
    const data = this.chartOption.data?.data ?? []
    const sorted = [...data].sort((a, b) => b.metricPrimary - a.metricPrimary)
    const topValues = sorted.slice(0, this.chartEntityLimit - 1)
    const otherTotal = sorted
      .slice(this.chartEntityLimit)
      .reduce((sum, obj) => sum + obj.metricPrimary, 0)
    const result: Record<string, number> = {}
    topValues.forEach(({ groupBy, metricPrimary }) => {
      result[groupBy] = metricPrimary
    })
    result.Other = otherTotal
    return result
  }

  currencyFormat(value: number): string {
    const factor = this.roundedToAbrev === 'M' ? 1e6 : 1e3
    const roundedValue = Math.round(value / factor)
    const formattedValue = Number(roundedValue).toLocaleString(undefined, { maximumFractionDigits: 0 })
    return `${currencySymbol(this.currentStructureCurrency)}${formattedValue}`
  }
}
