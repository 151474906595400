<div class="wrapper">
  <div class="data-export-table-container">
    <app-sort-table
      [columnDefs]="exportColumnDefs"
      [rows]="exportRows"
      [paginator]="paginator"
      [selectAllColumnID]="'include'"
      (selectedChange)="onSelectedChange($event)"
    ></app-sort-table>
    <mat-paginator
      class="paginator"
      showFirstLastButtons
      pageSize="20"
      [pageSizeOptions]="[10, 20, 50]"
    ></mat-paginator>
  </div>
</div>
