import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  DependentValue,
  DependentValueLists,
} from '@shared/layer-property/layer-property.component'
import {
  QuoteChildValue,
  QuoteParentValue,
} from '../../quote/models/quote-class.model'
import {
  DependentLayerPropertyDialogComponent,
  DependentPropertyDialogData,
} from './dialog/dependent-layer-property-dialog.component'
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dependent-layer-property',
  styleUrls: ['dependent-layer-property.component.scss'],
  templateUrl: 'dependent-layer-property.component.html',
})
export class DependentLayerPropertyComponent implements OnInit {
  @Input() value: DependentValue
  @Input() name: string
  @Input() valueLists: DependentValueLists

  @Output() valueChanged = new EventEmitter<DependentValue>()

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  get parentValues(): QuoteParentValue[] {
    return this.valueLists.parentValues.values
  }

  get childValues(): QuoteChildValue[] {
    return this.valueLists.childValues.values
  }

  get parentTitle(): string {
    switch (this.value.parent.id) {
      case 'layerClass': {
        return 'Layer Class'
      }
      default: {
        return 'Parent Property'
      }
    }
  }

  get childTitle(): string {
    switch (this.value.child.id) {
      case 'layerSubClass': {
        return 'Layer Subclass'
      }
      default: {
        return 'Child Property'
      }
    }
  }

  onEditClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    $event.stopPropagation()

    if (!this.parentValues) {
      return
    }

    const data: DependentPropertyDialogData = {
      selectedValues: this.value,
      valueLists: this.valueLists,
      title: this.name,
      parentTitle: this.parentTitle,
      childTitle: this.childTitle,
    }

    this.dialog
      .open(DependentLayerPropertyDialogComponent, {
        data,
        panelClass: 'app-dialog-translucent',
      })
      .afterClosed()
      .subscribe(
        (dialogResult: {
          parent: QuoteParentValue
          child?: QuoteChildValue
        }) => {
          const isNewParent =
            dialogResult.parent.value !== this.value.parent.value
          const isNewChild =
            (this.value.child.value && !dialogResult.child) ||
            (dialogResult.child &&
              dialogResult.child.value !== this.value.child.value)
          if (!isNewParent && !isNewChild) {
            return
          }

          this.valueChanged.emit({
            parent: {
              id: this.value.parent.id,
              value: dialogResult.parent.value,
            },
            child: {
              id: this.value.child.id,
              value: dialogResult.child?.value ?? '',
            },
          })
        }
      )
  }
}
