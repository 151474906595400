import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from '../../../../api/backend/backend.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-thumbnail-container',
  styleUrls: ['./compare-thumbnail.container.scss'],
  templateUrl: './compare-thumbnail.container.html',
})
export class CompareThumbnailContainerComponent implements OnInit, OnDestroy {
  @Input() thumbnailName?: string
  @Input() lastModified: string
  @Input() height: number
  @Input() width: number
  @Input() showBothThumbnails?: boolean
  imageURI: string

  private destroy$ = new Subject()

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.backendService
      // tslint:disable-next-line: no-non-null-assertion
      .getThumbnailImage(this.thumbnailName, this.lastModified)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.createImageFromBlob(data)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }
}
