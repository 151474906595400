import { Action, createReducer, on } from '@ngrx/store'
import { Layer } from '../../../model/layers.model'
import {
  SharedLimitLayerSelection,
  SharedLimitProperties,
} from '../program-group.model'
import * as fromProgramActions from '../program/program.actions'
import * as fromActions from './grouper-shared-limit.actions'

export type SharedLimitMode = 'none' | 'new' | 'delete'

export interface SharedLimitState {
  mode: SharedLimitMode
  editLayer: Layer | null
  addLayers: SharedLimitLayerSelection[]
  properties: SharedLimitProperties
  saving: boolean
  deleting: boolean
  updating: boolean
  layerResultsupdating: boolean
  layerResultsMetricCurrency: string | undefined
  error: string | null
  selectedLayer: string | null
  selectedProgram: string | null
}

const initialProps: SharedLimitProperties = {
  layerName: '',
  aggLimit: 0,
  occLimit: 0,
  aggDed: 0,
  ceded: 0,
  cession: 1,
  reinstatements: [],
  currency: '',
  layerResultsMetricCurrency: '',
}

export const initialState: SharedLimitState = {
  mode: 'none',
  editLayer: null,
  addLayers: [],
  properties: initialProps,
  saving: false,
  deleting: false,
  updating: false,
  layerResultsupdating: false,
  layerResultsMetricCurrency: '',
  error: null,
  selectedLayer: null,
  selectedProgram: null,
}

const grouperSharedLimitReducer = createReducer(
  initialState,

  // Mode

  on(fromActions.openNewSharedLimit, () => ({
    ...initialState,
    mode: 'new' as SharedLimitMode,
  })),

  on(
    fromActions.openEditSharedLimit,
    (_, { layer, selectedLayer, selectedProgram }) => ({
      ...initialState,
      mode: 'delete' as SharedLimitMode,
      editLayer: layer,
      properties: {
        layerName: layer.physicalLayer.description,
        aggLimit: layer.physicalLayer.aggregateLimit.value,
        occLimit: layer.physicalLayer.limit.value,
        aggDed: layer.physicalLayer.aggregateAttachment.value,
        ceded: layer.physicalLayer.premium.value,
        cession: Math.abs(layer.physicalLayer.participation),
        reinstatements: layer.physicalLayer.reinstatements,
        currency: layer.currency,
        layerResultsMetricCurrency: layer.layerResultsMetricCurrency,
      },
      selectedLayer,
      selectedProgram,
    })
  ),

  on(
    fromActions.cancelSharedLimit,
    fromActions.deleteSharedLimitSuccess,
    fromActions.updateSharedLimitSucccess,
    fromActions.addSharedLimitSuccess,
    () => ({ ...initialState })
  ),

  // Layer Selection

  on(
    fromActions.toggleSharedLimitLayerSelection,
    (state, { type, ...props }) => {
      const layer = state.addLayers.find(l => {
        return l.layerID === props.layerID
      })

      if (layer) {
        const selectedLayerIDs = [...state.addLayers]
        const index = state.addLayers.indexOf(layer)
        selectedLayerIDs.splice(index, 1)
        return { ...state, addLayers: selectedLayerIDs }
      } else {
        const selectedLayerIDs: SharedLimitLayerSelection[] = [
          ...state.addLayers,
          props,
        ]
        return { ...state, addLayers: selectedLayerIDs }
      }
    }
  ),

  on(fromProgramActions.removeProgramFromGroup, (state, { program }) => {
    // When a Program is removed, selected layers within must be removed
    const selectedLayerIDs = state.addLayers.filter(
      l => l.entityID !== program.id
    )
    return { ...state, addLayers: selectedLayerIDs }
  }),

  on(fromActions.clearSharedLimitLayerSelection, state => {
    return { ...state, addLayers: [] }
  }),

  // Set Properties

  on(fromActions.setSharedLimitProperties, (state, { properties }) => {
    return { ...state, properties }
  }),

  // Add

  on(fromActions.addSharedLimit, state => {
    return {
      ...state,
      saving: true,
      error: null,
    }
  }),

  on(fromActions.addSharedLimitFailure, (state, { error }) => {
    return {
      ...state,
      saving: false,
      error: error.message,
    }
  }),

  // Delete

  on(fromActions.deleteSharedLimit, state => {
    return {
      ...state,
      deleting: true,
      error: null,
    }
  }),

  on(fromActions.deleteSharedLimitFailure, (state, { error }) => {
    return {
      ...state,
      selectedLayerID: null,
      deleting: false,
      error: error.message,
    }
  }),

  // Update Properties

  on(fromActions.updateSharedLimitProperties, state => {
    return {
      ...state,
      updating: true,
      error: null,
    }
  }),

  on(fromActions.updateSharedLimitFailure, (state, { error }) => {
    return {
      ...state,
      updating: false,
      error: error.message,
    }
  })
)

export function reducer(
  state: SharedLimitState | undefined,
  action: Action
): SharedLimitState {
  return grouperSharedLimitReducer(state, action)
}
