import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { reduceReducers } from '@shared/util/reduce-reducers'
import {
  LayerEntry,
  LayerTypeDefaultEntry,
} from '../../technical-premium/technical-premium.model'
import * as fromActions from './technical-premium.actions'

export const TECHNICAL_PREMIUM_FEATURE_KEY = 'technicalPremium'

export type TechnicalPremiumState = {
  layerTypeDefaults: LayerTypeDefaultEntry
  defaultsInitialState: LayerTypeDefaultEntry
  layerEntries: LayerEntry[]
  loading: boolean
}

export const initialState: TechnicalPremiumState = {
  layerTypeDefaults: {},
  defaultsInitialState: {},
  layerEntries: [],
  loading: false,
}

const layerTypeDefaultsReducer = createReducer(
  initialState.layerTypeDefaults,
  on(
    fromActions.addLayerTypeValues,
    (_, { layerTypeEntries }) => layerTypeEntries
  ),
  on(fromActions.updateLayerTypeValue, (state, { layerType, newValue }) => {
    const newState = { ...state }
    newState[layerType] = newValue
    return newState
  }),
  on(fromActions.saveLayerTypeDefaultsSuccess, state => {
    const newEntries = { ...state }
    Object.entries(state).map(([key, value]) => {
      newEntries[key] = {
        ...value,
        modified: false,
      }
    })
    return newEntries
  }),
  on(fromActions.saveLayerTypeValuesGoingForwardSuccess, state => {
    const newEntries = { ...state }
    Object.entries(state).map(([key, value]) => {
      newEntries[key] = {
        ...value,
        modified: false,
        saveForOnlyNewLayers: false,
      }
    })
    return newEntries
  })
)

const defaultsInitialStateReducer = createReducer(
  initialState.defaultsInitialState,
  on(
    fromActions.addLayerTypeValues,
    (_, { layerTypeEntries }) => layerTypeEntries
  )
)

const layerEntryReducer = createReducer(
  initialState.layerEntries,
  on(fromActions.initLayerEntries, (_, { entries }) => entries),
  on(fromActions.updateLayerEntry, (state, { entry }) => {
    const newState = [...state]
    const index = state.findIndex(stateEntry => stateEntry.id === entry.id)
    if (index < 0) {
      return state
    }
    newState[index] = entry
    return newState
  })
)

const loadingReducer = createReducer(
  initialState.loading,
  on(fromActions.getLayerDetailsAndRecalculateTechnicalPremium, () => true),
  on(
    fromActions.recalculateTechnicalPremiumForLayersSuccess,
    fromActions.recalculateTechnicalPremiumForLayersFailure,
    fromActions.getLayerDetailsAndRecalculateTechnicalPremiumFailure,
    () => false
  )
)

const setPrevReducer = createReducer(initialState)

const _technicalPremiumReducer = combineReducers<TechnicalPremiumState>({
  layerTypeDefaults: layerTypeDefaultsReducer,
  defaultsInitialState: defaultsInitialStateReducer,
  layerEntries: layerEntryReducer,
  loading: loadingReducer,
})

const technicalPremiumReducer = reduceReducers(
  setPrevReducer,
  _technicalPremiumReducer
)

export function reducer(
  state: TechnicalPremiumState | undefined,
  action: Action
): TechnicalPremiumState {
  return technicalPremiumReducer(state, action)
}
