import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { range } from 'ramda'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'

export interface MultiselectDialogData {
  title?: string
  values: MultiselectDataItem[]
}

export interface MultiselectDataItem {
  value: string
  id: number
  isSelected: boolean
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-multiselect-layer-property-dialog',
  styleUrls: ['multiselect-layer-property-dialog.component.scss'],
  templateUrl: 'multiselect-layer-property-dialog.component.html',
})
export class MultiselectLayerPropertyDialogComponent implements OnInit {
  title: string
  values: MultiselectDataItem[] = []
  allValues: MultiselectDataItem[] = []
  searchCtrl = new FormControl()
  filteredValues: Observable<MultiselectDataItem[]>
  prevUserInput: string

  startIndex = 0 // Index where the data will start
  cursor = 0 // Most recent item a user clicked on
  rangeCursor = 0 // Where the cursor for selecting a range lies in the list

  onlySelectedToggled = false

  get hasSelection(): boolean {
    return this.values.some(value => value.isSelected)
  }

  @ViewChild('list', { read: ElementRef })
  listElement?: ElementRef<HTMLUListElement>
  @ViewChildren('item', { read: ElementRef })
  itemElements?: QueryList<ElementRef<HTMLLIElement>>

  @HostListener('document:keydown', ['$event'])
  onKeyUp($event: KeyboardEvent): void {
    const isRange = $event.shiftKey
    switch ($event.key) {
      case 'Enter': {
        return this.onOKClick()
      }
      case ' ': {
        this.trapKey($event)
        return this.onSelect(this.values[this.rangeCursor], this.rangeCursor, {
          isRange,
        })
      }
      case 'ArrowDown': {
        this.trapKey($event)
        return isRange ? this.moveRangeCursor(1) : this.moveCursor(1)
      }
      case 'ArrowUp': {
        this.trapKey($event)
        return isRange ? this.moveRangeCursor(-1) : this.moveCursor(-1)
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<MultiselectLayerPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MultiselectDialogData
  ) {
    this.title = data.title ?? 'Quote SAGE Multiselect'
    this.prevUserInput = ''
    this.values = this.sortBySelectedThenName(data.values)
    this.allValues = this.values
  }

  ngOnInit(): void {
    this.filteredValues = this.searchCtrl.valueChanges.pipe(
      startWith<string>(''),
      map((userInput: string) =>
        typeof userInput === 'string' ? userInput : this.prevUserInput
      ),
      map((userInput: string) => this.getValues(userInput))
    )
    this.searchCtrl.setValue('')
  }

  getClass(item: MultiselectDataItem) {
    return {
      selected: item.isSelected,
    }
  }

  getValues(userInput: string) {
    if (!userInput && this.prevUserInput.length) {
      // Check to see the previous input, if the user is backspacing, update the list order
      if (this.onlySelectedToggled) {
        this.values = this.sortBySelectedThenName(this.values)
      } else {
        this.values = this.sortBySelectedThenName(this.allValues)
      }
    } else {
      if (userInput.length < this.prevUserInput.length) {
        // User backspaced, need to update values with allValues
        this.values = this.allValues.filter(
          option =>
            option.value.toLowerCase().indexOf(userInput.toLowerCase()) >= 0
        )
      } else {
        this.values = this.values.filter(
          option =>
            option.value.toLowerCase().indexOf(userInput.toLowerCase()) >= 0
        )
      }
      if (this.onlySelectedToggled) {
        this.values = this.values.filter(value => value.isSelected)
      }
    }
    this.prevUserInput = userInput
    return this.values
  }

  toggleExclusiveSelected($event: MatCheckboxChange) {
    this.onlySelectedToggled = $event.checked
    if (!$event.checked) {
      this.values = this.sortBySelectedThenName(this.allValues)
    }
    // Update the value to trigger the valueChanges event, no need to actually change input
    this.searchCtrl.setValue(this.searchCtrl.value)
  }

  onSelect(
    selected: MultiselectDataItem,
    listIndex: number,
    { isRange, force }: { isRange?: boolean; force?: boolean } = {}
  ): void {
    const id = selected.id
    const index = this.values.findIndex(item => item.id === id)
    const copiedIndex = this.allValues.findIndex(item => item.id === id)
    // Need to check for the select all id

    if (isRange) {
      // Case where user is shift clicking values
      let begin = this.cursor
      if (begin < 0) {
        begin = this.startIndex
      }
      let end = listIndex
      if (begin > end) {
        const temp = begin
        begin = end
        end = temp
      }

      range(begin, end + 1).forEach(i => {
        const item = this.values[i]
        this.values[i] = {
          ...item,
          isSelected: i === begin ? item.isSelected : !item.isSelected,
        }
        const copyIndex = this.allValues.findIndex(
          value => value.id === item.id
        )
        this.allValues[copyIndex] = { ...this.values[i] }
      })
    } else {
      if (force === false || (force == null && selected.isSelected)) {
        this.values[index] = {
          ...selected,
          isSelected: false,
        }
      } else {
        this.values[index] = {
          ...selected,
          isSelected: true,
        }
      }
      this.allValues[copiedIndex] = { ...this.values[index] }

      if (force == null) {
        this.cursor = index
        if (index < 0) {
          this.cursor = this.startIndex
        }
        this.rangeCursor = this.cursor
      }
    }
    this.searchCtrl.setValue(this.searchCtrl.value)
  }

  onOKClick(): void {
    if (this.hasSelection) {
      this.dialogRef.close([
        ...this.values.filter(item => item.isSelected).map(item => item.value),
      ])
    }
  }

  trapKey($event: KeyboardEvent) {
    $event.preventDefault()
    $event.stopPropagation()
  }

  private moveCursor(n: number) {
    const prevIndex = this.rangeCursor
    const nextIndex = prevIndex + n
    const values = this.values
    let index: number
    if (nextIndex < 0) {
      index = nextIndex + values.length
    } else if (nextIndex > values.length - 1) {
      index = nextIndex - values.length
    } else {
      index = nextIndex
    }
    this.cursor = index
    this.rangeCursor = index

    this.scrollToCursor(this.cursor)
  }

  private moveRangeCursor(n: number) {
    const cursorItem = this.values[this.cursor]
    if (cursorItem && !cursorItem.isSelected) {
      this.onSelect(cursorItem, this.cursor)
    }
    if (
      (n < 0 && this.rangeCursor <= this.cursor) ||
      (n > 0 && this.rangeCursor >= this.cursor)
    ) {
      this.rangeCursor += n
      if (this.rangeCursor > this.values.length - 1) {
        this.rangeCursor = this.values.length - 1
        return
      }
      if (this.rangeCursor < this.startIndex) {
        this.rangeCursor = this.startIndex
        return
      }
      this.onSelect(this.values[this.rangeCursor], this.cursor, { force: true })
    } else {
      this.onSelect(this.values[this.rangeCursor], this.cursor, {
        force: false,
      })
      this.rangeCursor += n
    }

    this.scrollToCursor(this.rangeCursor)
  }

  private scrollToCursor(cursor: number) {
    const list = this.listElement?.nativeElement
    const itemEl = this.itemElements?.find((_, i) => i === cursor)
    const item = itemEl?.nativeElement
    if (!list || !item) {
      return
    }
    const listBox = list.getBoundingClientRect()
    const itemBox = item.getBoundingClientRect()
    const isVisible =
      itemBox.top >= listBox.top &&
      itemBox.bottom <= listBox.top + list.clientHeight

    if (!isVisible) {
      const deltaTop = itemBox.top - listBox.top
      const deltaBottom = itemBox.bottom - listBox.bottom
      list.scrollTop +=
        Math.abs(deltaTop) < Math.abs(deltaBottom) ? deltaTop : deltaBottom
    }
  }

  private sortBySelectedThenName(
    values: MultiselectDataItem[]
  ): MultiselectDataItem[] {
    const selectedValues = values.filter(value => value.isSelected)
    const nonSelectedValues = values.filter(value => !value.isSelected)

    return this.sortArray(selectedValues).concat(
      this.sortArray(nonSelectedValues)
    )
  }

  sortArray = (values: MultiselectDataItem[]) =>
    values.sort((a, b) => a.value.localeCompare(b.value))
}
