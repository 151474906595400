import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnInit,
  EventEmitter,
} from '@angular/core'
import { Reference } from '../layer-property/layer-property.component'
import {
  MultiselectDataItem,
  MultiselectDialogData,
  MultiselectLayerPropertyDialogComponent,
} from './dialog/multiselect-layer-property-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-multiselect-layer-property',
  styleUrls: ['multiselect-layer-property.component.scss'],
  templateUrl: 'multiselect-layer-property.component.html',
})
export class MultiselectLayerPropertyComponent implements OnInit {
  allValues: string[] = []
  selectedValues: string[] = []
  prevSentValues: string[] | undefined
  @Input() inputSelectedValues: string[]
  @Input() references: Reference[]
  @Input() additionalValues?: any
  @Input() name: string

  @Output() clearValues = new EventEmitter()
  @Output() setValues = new EventEmitter()

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    const displayRefs = this.references
      .map(item => item.viewValue)
      .filter((item): item is string => !!item)
    if (this.inputSelectedValues && this.inputSelectedValues[0]) {
      this.selectedValues = [...this.inputSelectedValues]
    }
    this.prevSentValues = [...this.selectedValues]
    this.allValues = this.removeDupes(this.selectedValues.concat(displayRefs))
    this.initAdditionalValues()
  }

  initAdditionalValues(): void {
    if (this.additionalValues && this.name) {
      switch (this.name) {
        case 'Territories':
          this.allValues = this.removeDupes(
            this.allValues.concat(
              (Object.values(this.additionalValues) as string[]).flat()
            )
          )
          break
      }
    }
  }

  onEditClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()

    if (!this.allValues.length) {
      return
    }

    const data: MultiselectDialogData = {
      title: this.name,
      values: this.getParsedValuesForDialog(),
    }

    this.dialog
      .open(MultiselectLayerPropertyDialogComponent, {
        data,
        panelClass: 'app-dialog-translucent',
        minHeight: '67vh',
        height: '67vh',
      })
      .afterClosed()
      .subscribe(selectedItems => {
        if (selectedItems) {
          this.setValues.emit(selectedItems)
        }
      })
  }

  getParsedValuesForDialog(): MultiselectDataItem[] {
    return this.allValues.map((value, index) => ({
      id: index,
      value,
      isSelected: this.inputSelectedValues?.includes(value) ?? false,
    }))
  }

  isSelected(option: string): boolean {
    return this.selectedValues.indexOf(option) > -1
  }

  removeDupes = (values: string[]) =>
    values.filter((item, index) => values.indexOf(item) === index)
}
