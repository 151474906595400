import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { clone, of } from 'ramda'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map, shareReplay, skip, switchMap, take } from 'rxjs/operators'
import { Program } from '../../../../core/model/program.model'
import {
  SetNameDialogComponent,
  SetNameDialogData,
  SetNameDialogResult,
} from '@shared/set-name-dialog.component'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import {
  ChangeManyEvent,
  GroupScenario,
  GroupScenariosDialogData,
  ProgramGroupMember,
  ScenarioSelectModel,
} from '../../../store/grouper/program-group.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-scenarios-dialog',
  styleUrls: ['./group-scenarios-dialog.component.scss'],
  templateUrl: './group-scenarios-dialog.component.html',
})

export class GroupScenariosDialogComponent implements OnDestroy {
  private _onSave$: BehaviorSubject<ChangeManyEvent<GroupScenario> | null>
  onSave$: Observable<ChangeManyEvent<GroupScenario>>

  selectedIndex = 0
  selectedList: 'existing' | 'adding' = 'existing'
  selectedStructureModels: ScenarioSelectModel<Program>[] = []

  existing: GroupScenario[] = []
  adding: GroupScenario[] = []

  get isNewGroupSelected(): boolean {
    return this.selectedList === 'adding'
  }

  get disableSave(): boolean {
    return (
      this.adding.length === 0 &&
      this.existing.length === this.data.groupScenarios.length
    )
  }

  showDesc(item: any): boolean {
    return item.description != null
  }

  constructor(
    private dialogRef: MatDialogRef<
      GroupScenariosDialogComponent,
      ChangeManyEvent<GroupScenario>
    >,
    @Inject(MAT_DIALOG_DATA) public data: GroupScenariosDialogData,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private cdRef: ChangeDetectorRef
  ) {
    this.existing = clone(data.groupScenarios)
    this.setSelected('existing', data.selectedGroupIndex ?? data.index)

    this._onSave$ = new BehaviorSubject(null)
    this.onSave$ = this._onSave$.asObservable().pipe(
      skip(1),
      filter(value => value != null),
      // tslint:disable-next-line: no-non-null-assertion
      map(value => value!),
      shareReplay(1)
    )

    dialogRef
      .backdropClick()
      .pipe(
        switchMap(() => this.data.saving$?.pipe(take(1)) ?? of(false)),
        filter(saving => !saving)
      )
      .subscribe(() => this.onCancel())
  }

  ngOnDestroy(): void {
    this._onSave$.complete()
  }

  isSelected(list: ListType, index: number) {
    return this.selectedList === list && this.selectedIndex === index
  }

  asTooltip(str: string): string {
    return str && str.length > 32 ? str : ''
  }

  setSelected(list: ListType, index: number) {
    this.selectedList = list
    this.selectedIndex = index
    this.selectedStructureModels = this.getStructureScenarios(list, index)
  }

  onStructureScenarioClick(
    structureIndex: number,
    scenarioIndex: number
  ): void {
    if (this.selectedList === 'adding') {
      const i = this.selectedIndex
      const j = structureIndex
      const k = scenarioIndex
      // Update selected structure scenario model's index
      this.adding[i].structureScenarios[j].index = k
      // Update selected structure scenario model's item
      const item = this.adding[i].structureScenarios[j].scenarios[k]
      this.adding[i].structureScenarios[j].item = item
      // Update selected group scenario's corresponding member IDs
      const member = this.adding[i].members[j]
      this.adding[i].members[j].programID = item.id
      this.adding[i].members[j].id = `${member.parentGroupID}_${item.id}`
    }
  }

  onAdd(): void {
    const data: SetNameDialogData = {
      actionName: `Add Group Scenario`,
      otherNames: this.data.groupScenarios.map(gs => gs.group.label),
      showDescription: true,
    }
    this.dialog
      .open(SetNameDialogComponent, { width: '30vw', data })
      .afterClosed()
      .pipe(filter<SetNameDialogResult>(res => !!res))
      .subscribe(value => this.handleAdd(value))
  }

  onDelete(list: ListType, index: number): void {
    switch (list) {
      case 'existing':
        this.existing = this.existing.filter((_, i) => i !== index)
        break
      case 'adding':
        this.adding = this.adding.filter((_, i) => i !== index)
        break
    }

    if (list === this.selectedList) {
      if (index <= this.selectedIndex) {
        this.setSelected(this.selectedList, this.selectedIndex - 1)
      }
    }
  }

  handleSave(): void {
    if (!this.disableSave) {
      const add = this.adding

      const remove = this.data.groupScenarios.reduce(
        (acc, gs) =>
          this.existing.find(e => e.group.id === gs.group.id)
            ? acc
            : [...acc, gs],
        [] as GroupScenario[]
      )

      this._onSave$.next({ add, remove, update: [] })
    }
  }

  onCancel(saving = false): void {
    if (saving) {
      return
    }

    if (this.disableSave) {
      this.dialogRef.close()
      return
    }

    this.confirmationDialog
      .open({
        message: 'There are unsaved changes. Are you sure want to discard?',
        submitLabel: 'Discard',
      })
      .afterClosed()
      .subscribe((doClose: boolean) => {
        if (doClose) {
          this.dialogRef.close()
        }
      })
  }

  private getGroupScenario(list: ListType, index: number): GroupScenario {
    switch (list) {
      case 'existing':
        return this.existing[index]
      case 'adding':
        return this.adding[index]
    }
  }

  private getStructureScenarios(
    list: ListType,
    index: number
  ): ScenarioSelectModel<Program>[] {
    return this.getGroupScenario(list, index).structureScenarios
  }

  private handleAdd({ name, description }: SetNameDialogResult) {
    const original = this.data.groupScenarios[0]
    const group = {
      ...original.group,
      id: name,
      label: name,
      description,
      isScenario: true,
      parentScenarioID: original.group.id,
      scenarioIDs: undefined as string[],
    }

    // Increment currently selected structure scenario indices
    const structureScenarios = clone(
      this.getStructureScenarios(this.selectedList, this.selectedIndex)
    ).map(m => ({
      ...m,
      index: Math.min(m.index + 1, m.scenarios.length - 1),
    }))

    const members: ProgramGroupMember[] = original.members.map((m, i) => {
      const parentGroupID = group.id
      const { scenarios, index } = structureScenarios[i]
      const programID = scenarios[index].id
      return {
        ...m,
        id: `${parentGroupID}_${programID}`,
        parentGroupID,
        programID,
      }
    })

    const addLength = this.adding.push({ group, members, structureScenarios })

    this.setSelected('adding', addLength - 1)
    this.cdRef.markForCheck()
  }
}

type ListType = 'existing' | 'adding'
