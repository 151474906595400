<h1 mat-dialog-title>{{ options.message }}</h1>
<mat-dialog-content>
  <table
    class="table-content"
    *ngIf="options.fields[0].name === 'Vehicle Type'"
  >
    <thead>
      <tr>
        <th class="table-column">Vehicle Type</th>
        <th class="table-column">Local</th>
        <th class="table-column">Intermediate</th>
        <th class="table-column">Long Distance</th>
        <th class="table-column">Total</th>
      </tr>
    </thead>
    <tbody
      *ngIf="
        options &&
        options.tableValue &&
        options.tableValue.columnData &&
        options.tableValue.columnData.columns
      "
    >
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td
          *ngIf="
            options && options.tableValue && options.tableValue.data && row
          "
          class="table-column"
        >
          {{ row['Vehicle Type'] }}
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] !== 'Total'
          "
          class="table-column"
        >
          <input
            [(ngModel)]="row.Local"
            class="form-control input-width"
            type="text"
            name="{{ row.Local }}"
            (ngModelChange)="updateTotal(i, false)"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] !== 'Total'
          "
          class="table-column"
        >
          <input
            [(ngModel)]="row.Intermediate"
            class="form-control input-width"
            type="text"
            name="{{ row.Intermediate }}"
            (ngModelChange)="updateTotal(i, false)"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] !== 'Total'
          "
          class="table-column"
        >
          <input
            [(ngModel)]="row['Long Distance']"
            class="form-control input-width"
            type="text"
            name="{{ row['Long Distance'] }}"
            (ngModelChange)="updateTotal(i, false)"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] !== 'Total'
          "
          class="table-column"
        >
          <input
            [(ngModel)]="row.Total"
            class="form-control input-width"
            type="text"
            name="{{ row.Total }}"
            (focus)="parseStringToNumber(i, 'Total', row.Total)"
            (blur)="parseStringToNumber(i, 'Total', row.Total)"
            (ngModelChange)="updateTotal(i, true)"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] === 'Total'
          "
          class="table-column"
        >
          <input
            value="{{ getColumnTotal('Local') }}"
            matInput
            type="text"
            readonly="true"
            tabindex="-1"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] === 'Total'
          "
          class="table-column"
        >
          <input
            value="{{ getColumnTotal('Intermediate') }}"
            matInput
            type="text"
            readonly="true"
            tabindex="-1"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] === 'Total'
          "
          class="table-column"
        >
          <input
            value="{{ getColumnTotal('Long Distance') }}"
            matInput
            type="text"
            readonly="true"
            tabindex="-1"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['Vehicle Type'] === 'Total'
          "
          class="table-column"
        >
          <input
            value="{{ getColumnTotal('Total') }}"
            matInput
            type="text"
            readonly="true"
            tabindex="-1"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content"
    *ngIf="options.fields[0].name === 'LOB' && options.fields.length === 5"
  >
    <thead>
      <tr>
        <th *ngFor="let field of options.fields">{{ field.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td>
          <input
            [(ngModel)]="row.LOB"
            class="form-control input-width"
            type="text"
            name="LOB"
            list="LOB"
            onmousedown="value=''"
          />
          <datalist id="LOB">
            <option>Auto Liability</option>
            <option>General Liability</option>
            <option>Employer's Liability</option>
            <option>Employee Benefits Liability</option>
            <option>Liquor Liability</option>
            <option>Assault & Battery</option>
            <option>SML</option>
            <option>Lead Excess Liability</option>
            <option>Follow form Excess Liability</option>
          </datalist>
        </td>
        <td
          *ngIf="
            row.LOB !== 'Auto Liability' &&
            row.LOB !== 'General Liability' &&
            row.LOB !== employer &&
            row.LOB !== 'Employee Benefits Liability' &&
            row.LOB !== 'Liquor Liability' &&
            row.LOB !== 'Assault & Battery' &&
            row.LOB !== 'SML' &&
            row.LOB !== 'Lead Excess Liability' &&
            row.LOB !== 'Follow form Excess Liability' &&
            row.LOB !== 'Excess Liability'
          "
        >
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          />
        </td>
        <td
          *ngIf="
            row.LOB === 'Liquor Liability' ||
            row.LOB === 'Assault & Battery' ||
            row.LOB === 'SML' ||
            row.LOB === 'Lead Excess Liability' ||
            row.LOB === 'Follow form Excess Liability'
          "
        >
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
            [attr.list]="'limits' + i"
            onmousedown="value=''"
          />
          <datalist id="{{ 'limits' + i }}">
            <option value="$1M/$1M/$1M">$1M/$1M/$1M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
            <option value="$3M/$3M/$3M">$3M/$3M/$3M</option>
            <option value="$4M/$4M/$4M">$4M/$4M/$4M</option>
            <option value="$5M/$5M/$5M">$5M/$5M/$5M</option>
            <option value="$6M/$6M/$6M">$6M/$6M/$6M</option>
            <option value="$7M/$7M/$7M">$7M/$7M/$7M</option>
            <option value="$8M/$8M/$8M">$8M/$8M/$8M</option>
            <option value="$9M/$9M/$9M">$9M/$9M/$9M</option>
            <option value="$10M/$10M/$10M">$10M/$10M/$10M</option>
            <option value="$15M/$15M/$15M">$15M/$15M/$15M</option>
            <option value="$20M/$20M/$20M">$20M/$20M/$20M</option>
            <option value="$25M/$25M/$25M">$25M/$25M/$25M</option>
          </datalist>
        </td>
        <td *ngIf="row.LOB === 'Auto Liability'">
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
            [attr.list]="'limits' + i"
            onmousedown="value=''"
          />
          <datalist id="{{ 'limits' + i }}">
            <option value="$1M CSL">$1M CSL</option>
            <option value="$2M CSL">$2M CSL</option>
            <option value="$3M CSL">$3M CSL</option>
            <option value="$5M CSL">$5M CSL</option>
          </datalist>
        </td>
        <td *ngIf="row.LOB === 'General Liability'">
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
            [attr.list]="'limits' + i"
            onmousedown="value=''"
          />
          <datalist id="{{ 'limits' + i }}">
            <option value="$1M/$2M/$2M">$1M/$2M/$2M</option>
            <option value="$1M/$2M/$1M">$1M/$2M/$1M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
            <option value="$2M/$4M/$2M">$2M/$4M/$2M</option>
            <option value="$2M/$4M/$4M">$2M/$4M/$4M</option>
            <option value="$3M/$6M/$3M">$3M/$6M/$3M</option>
            <option value="$3M/$6M/$6M">$3M/$6M/$6M</option>
            <option value="$5M/$5M/$5M">$5M/$5M/$5M</option>
          </datalist>
        </td>
        <td *ngIf="row.LOB === employer">
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
            [attr.list]="'limits' + i"
            onmousedown="value=''"
          />
          <datalist id="{{ 'limits' + i }}">
            <option value="$500K/$500K/$500K">$500K/$500K/$500K</option>
            <option value="$1M/$1M/$1M">$1M/$1M/$1M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
          </datalist>
        </td>
        <td *ngIf="row.LOB === 'Employee Benefits Liability'">
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
            [attr.list]="'limits' + i"
            onmousedown="value=''"
          />
          <datalist id="{{ 'limits' + i }}">
            <option value="$1M/$2M">$1M/$2M</option>
          </datalist>
        </td>
        <td>
          <input
            [(ngModel)]="row.Premium"
            matinput
            class="form-control input-width"
            type="currency"
            name="Premium"
            (focus)="removeCommas(i, 'Premium', row.Premium)"
            (blur)="addCommasCurrency(i, 'Premium', row.Premium)"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row.Carrier"
            class="form-control input-width"
            type="text"
            name="Carrier"
          />
        </td>
        <td>
          <input
            type="checkbox"
            [(ngModel)]="row['See Attached U/L Schedule']"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <div class="add-row-column">
            <button class="btn btn-default" type="button" (click)="addRow()">
              Add New Row
            </button>
            <span>or</span>
          </div>
        </td>
        <td [attr.colspan]="4">
          <div class="upload-container">
            <div class="upload-dropzone">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                (change)="onFileChange($event)"
              />
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content"
    *ngIf="options.fields[0].name === 'LOB' && options.fields.length !== 5"
  >
    <thead>
      <tr>
        <th *ngFor="let field of options.fields">{{ field.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td>
          <input
            [(ngModel)]="row.LOB"
            class="form-control input-width"
            type="text"
            name="LOB"
            list="LOB"
            onmousedown="value=''"
          />
          <datalist id="LOB">
            <option>Auto Liability</option>
            <option>General Liability</option>
            <option>Employer's Liability</option>
            <option>Employee Benefits Liability</option>
            <option>Excess Liability</option>
          </datalist>
        </td>
        <td
          *ngIf="
            row.LOB !== 'Auto Liability' &&
            row.LOB !== 'General Liability' &&
            row.LOB !== employer &&
            row.LOB !== 'Employee Benefits Liability' &&
            row.LOB !== 'Excess Liability'
          "
        >
          <input
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          />
        </td>
        <td *ngIf="row.LOB === 'Auto Liability'">
          <select
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          >
            <option value="$1M">$1M</option>
            <option value="$2M">$2M</option>
            <option value="$3M">$3M</option>
            <option value="$4M">$4M</option>
            <option value="$5M">$5M</option>
          </select>
        </td>
        <td *ngIf="row.LOB === 'General Liability'">
          <select
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          >
            <option value="$1M/$2M/$1M">$1M/$2M/$1M</option>
            <option value="$1M/$2M/$2M">$1M/$2M/$2M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
            <option value="$2M/$4M/$2M">$2M/$4M/$2M</option>
            <option value="$2M/$4M/$4M">$2M/$4M/$4M</option>
            <option value="$5M/$5M/$5M">$5M/$5M/$5M</option>
          </select>
        </td>
        <td *ngIf="row.LOB === employer">
          <select
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          >
            <option value="$500K/$500K/$500K">$500K/$500K/$500K</option>
            <option value="$1M/$1M/$1M">$1M/$1M/$1M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
          </select>
        </td>
        <td *ngIf="row.LOB === 'Employee Benefits Liability'">
          <select
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          >
            <option value="$1M/$2M">$1M/$2M</option>
          </select>
        </td>
        <td *ngIf="row.LOB === 'Excess Liability'">
          <select
            [(ngModel)]="row.Limits"
            class="form-control input-width"
            type="text"
            name="Limits"
          >
            <option value="$1M/$1M/$1M">$1M/$1M/$1M</option>
            <option value="$2M/$2M/$2M">$2M/$2M/$2M</option>
            <option value="$3M/$3M/$3M">$3M/$3M/$3M</option>
            <option value="$4M/$4M/$4M">$4M/$4M/$4M</option>
            <option value="$5M/$5M/$5M">$5M/$5M/$5M</option>
            <option value="$6M/$6M/$6M">$6M/$6M/$6M</option>
            <option value="$7M/$7M/$7M">$7M/$7M/$7M</option>
            <option value="$8M/$8M/$8M">$8M/$8M/$8M</option>
            <option value="$9M/$9M/$9M">$9M/$9M/$9M</option>
            <option value="$10M/$10M/$10M">$10M/$10M/$10M</option>
            <option value="$15M/$15M/$15M">$15M/$15M/$15M</option>
            <option value="$20M/$20M/$20M">$20M/$20M/$20M</option>
            <option value="$25M/$25M/$25M">$25M/$25M/$25M</option>
          </select>
        </td>
        <td>
          <input
            [(ngModel)]="row.Carrier"
            class="form-control input-width"
            type="text"
            name="Carrier"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <div class="add-row-column">
            <button class="btn btn-default" type="button" (click)="addRow()">
              Add New Row
            </button>
            <span>or</span>
          </div>
        </td>
        <td [attr.colspan]="1">
          <div class="upload-container">
            <div class="upload-dropzone">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                (change)="onFileChange($event)"
              />
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content"
    *ngIf="options.fields[1] && options.fields[1]?.name === 'Claims'"
  >
    <thead>
      <tr>
        <th *ngFor="let field of options.fields">{{ field.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td *ngIf="options.fields[0].name === 'Year'">
          <input
            [(ngModel)]="row.Year"
            class="form-control input-width"
            type="text"
            name="Year"
            tabindex="-1"
          />
        </td>
        <td *ngIf="options.fields[0].name !== 'Year'">
          <input
            [(ngModel)]="row.Years"
            class="form-control input-width"
            type="text"
            name="Years"
            tabindex="-1"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row.Claims"
            class="form-control input-width"
            type="text"
            name="Claims"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row['Total Incurred']"
            matinput
            class="form-control input-width"
            type="currency"
            name="Total Incurred"
            (focus)="removeCommas(i, 'Total Incurred', row['Total Incurred'])"
            (blur)="
              addCommasCurrency(i, 'Total Incurred', row['Total Incurred'])
            "
          />
        </td>
        <td>
          <input
            [(ngModel)]="row['Total Paid']"
            matinput
            class="form-control input-width"
            type="currency"
            name="Total Paid"
            (focus)="removeCommas(i, 'Total Paid', row['Total Paid'])"
            (blur)="addCommasCurrency(i, 'Total Paid', row['Total Paid'])"
          />
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Val Date: {{ row['Val Date'] ? row['Val Date'] : options.date }}"
              [(ngModel)]="row['Val Date']"
              (ngModelChange)="updateDate(row['Val Date'], i)"
              />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <div class="add-row-column">
            <button class="btn btn-default" type="button" (click)="addRow()">
              Add New Row
            </button>
            <span>or</span>
          </div>
        </td>
        <td [attr.colspan]="4">
          <div class="upload-container">
            <div class="upload-dropzone">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                (change)="onFileChange($event)"
              />
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content table-layout-fixed"
    *ngIf="options.fields[0].name === 'DOL'"
  >
    <thead>
      <tr>
        <th *ngIf="options.fields.length > 4" width="10%">LOB</th>
        <th width="20%">DOL</th>
        <th width="10%">Total Incurred</th>
        <th width="10%">Status</th>
        <th width="50%">Description</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td *ngIf="options.fields.length > 4">
          <select
            [(ngModel)]="row.LOB"
            class="form-control input-width input-height"
            type="text"
            name="LOB"
          >
            <option value="Auto">Auto</option>
            <option value="GL">GL</option>
            <option value="Umb/Excess">Umb/Excess</option>
          </select>
        </td>
        <td>
          <mat-form-field>
            <div class="date-data">
              <input
                placeholder="DOL: {{ options.tableValue.data[i].DOL }}"
                [(ngModel)]="row.DOL"
                matInput
                [matDatepicker]="picker"
              />
            </div>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint></mat-hint>
          </mat-form-field>
        </td>
        <td>
          <input
            [(ngModel)]="row['Total Incurred']"
            matinput
            class="form-control input-width"
            type="currency"
            name="Total Incurred"
            (focus)="removeCommas(i, 'Total Incurred', row['Total Incurred'])"
            (blur)="
              addCommasCurrency(i, 'Total Incurred', row['Total Incurred'])
            "
          />
        </td>
        <td>
          <select
            [(ngModel)]="row.Status"
            class="form-control input-width input-height"
            type="text"
            name="Status"
          >
            <option value="Open">Open</option>
            <option value="Pending">Pending</option>
            <option value="Closed">Closed</option>
          </select>
        </td>
        <td>
          <textarea
            [ngModel]="row.Description"
            (ngModelChange)="row.Description = $event"
            class="form-control input-width response-textarea"
          ></textarea>
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <div class="add-row-column">
            <button class="btn btn-default" type="button" (click)="addRow()">
              Add New Row
            </button>
            <span>or</span>
          </div>
        </td>
        <td [attr.colspan]="4">
          <div class="upload-container">
            <div class="upload-dropzone">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                (change)="onFileChange($event)"
              />
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table-content" *ngIf="options.fields[0].name === 'Category'">
    <thead>
      <tr>
        <th *ngFor="let field of options.fields">{{ field.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td>
          <input
            [(ngModel)]="row.Category"
            class="form-control input-width"
            type="text"
            name="firstExposure"
            list="firstExposure"
            onmousedown="value=''"
          />
          <datalist id="firstExposure">
            <option *ngFor="let option of exposureOptions; let i = index" [value]="option">{{option}}</option>
          </datalist>
        </td>
        <td
          *ngIf="
            row.Category === 'Gross Receipts' ||
            row.Category === 'Payroll' ||
            row.Category === 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row['Exposure']"
            matinput
            class="form-control input-width"
            type="currency"
            name="Exposure"
            (focus)="removeCommas(i, 'Exposure', row['Exposure'])"
            (blur)="addCommasCurrency(i, 'Exposure', row['Exposure'])"
          />
        </td>
        <td
          *ngIf="
            row.Category !== 'Gross Receipts' &&
            row.Category !== 'Payroll' &&
            row.Category !== 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row['Exposure']"
            matinput
            class="form-control input-width"
            type="currency"
            name="Exposure"
            (focus)="removeCommas(i, 'Exposure', row['Exposure'])"
            (blur)="addCommas(i, 'Exposure', row['Exposure'])"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <button class="btn btn-default" type="button" (click)="addRow()">
            Add New Row
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content"
    *ngIf="options.fields[0].name === 'Year' && options.type === 'select'"
  >
    <thead>
      <tr>
        <th>Year</th>
        <th>
          <input
            [ngModel]="firstExposure"
            class="form-control input-width"
            type="text"
            name="firstExposure"
            list="firstExposure"
            onmousedown="value=''"
            (ngModelChange)="firstExposureChanged($event)"
            tabindex="-1"
          />
          <datalist id="firstExposure">
            <option *ngFor="let option of filteredExposureOptions; let i = index" [value]="option">{{option}}</option>
          </datalist>
        </th>
        <th>
          <input
            [ngModel]="secondExposure"
            class="form-control input-width"
            type="text"
            name="secondExposure"
            list="secondExposure"
            onmousedown="value=''"
            (ngModelChange)="secondExposureChanged($event)"
            tabindex="-1"
          />
          <datalist id="secondExposure">
            <option *ngFor="let option of filteredExposureOptions; let i = index" [value]="option">{{option}}</option>
          </datalist>
        </th>
        <th *ngIf="thirdExposure !== ''">
          <input
            [ngModel]="thirdExposure"
            class="form-control input-width"
            type="text"
            name="thirdExposure"
            list="thirdExposure"
            onmousedown="value=''"
            (ngModelChange)="thirdExposureChanged($event)"
            tabindex="-1"
          />
          <datalist id="thirdExposure">
            <option *ngFor="let option of filteredExposureOptions; let i = index" [value]="option">{{option}}</option>
          </datalist>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td>
          <input
            [(ngModel)]="row['Year']"
            matinput
            class="form-control input-width"
            type="text"
            name="Year"
            tabindex="-1"
          />
        </td>
        <td
          *ngIf="
            firstExposure === 'Gross Receipts' ||
            firstExposure === 'Payroll' ||
            firstExposure === 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[firstExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="firstExposureVal"
            (focus)="removeCommas(i, firstExposure, row[firstExposure])"
            (blur)="addCommasCurrency(i, firstExposure, row[firstExposure])"
          />
        </td>
        <td
          *ngIf="
            firstExposure !== 'Gross Receipts' &&
            firstExposure !== 'Payroll' &&
            firstExposure !== 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[firstExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="firstExposureVal"
            (focus)="removeCommas(i, firstExposure, row[firstExposure])"
            (blur)="addCommas(i, firstExposure, row[firstExposure])"
          />
        </td>
        <td
          *ngIf="
            secondExposure === 'Gross Receipts' ||
            secondExposure === 'Payroll' ||
            secondExposure === 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[secondExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="secondExposureVal"
            (focus)="removeCommas(i, secondExposure, row[secondExposure])"
            (blur)="addCommasCurrency(i, secondExposure, row[secondExposure])"
          />
        </td>
        <td
          *ngIf="
            secondExposure !== 'Gross Receipts' &&
            secondExposure !== 'Payroll' &&
            secondExposure !== 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[secondExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="secondExposureVal"
            (focus)="removeCommas(i, secondExposure, row[secondExposure])"
            (blur)="addCommas(i, secondExposure, row[secondExposure])"
          />
        </td>
        <td
          *ngIf="
            thirdExposure === 'Gross Receipts' ||
            thirdExposure === 'Payroll' ||
            thirdExposure === 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[thirdExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="thirdExposureVal"
            (focus)="removeCommas(i, thirdExposure, row[thirdExposure])"
            (blur)="addCommasCurrency(i, thirdExposure, row[thirdExposure])"
          />
        </td>
        <td
          *ngIf="
            thirdExposure !== '' &&
            thirdExposure !== 'Gross Receipts' &&
            thirdExposure !== 'Payroll' &&
            thirdExposure !== 'Gross Sales'
          "
        >
          <input
            [(ngModel)]="row[thirdExposure]"
            matinput
            class="form-control input-width"
            type="currency"
            name="thirdExposureVal"
            (focus)="removeCommas(i, thirdExposure, row[thirdExposure])"
            (blur)="addCommas(i, thirdExposure, row[thirdExposure])"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <button class="btn btn-default" type="button" (click)="addRow()">
            Add New Row
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    class="table-content"
    *ngIf="
      options.fields[0] &&
      options.fields[0]?.name === 'Year' &&
      options.fields[1] &&
      options.fields[1]?.name === 'Units'
    "
  >
    <thead>
      <tr>
        <th>Year</th>
        <th>Units</th>
        <th>Mileage</th>
        <th>
          <input
            [ngModel]="thirdExposure"
            class="form-control input-width"
            style="margin-left: -6px;"
            type="text"
            name="thirdExposure"
            onmousedown="value=''"
            (ngModelChange)="thirdExposureChanged($event)"
            tabindex="-1"
          />
        </th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td>
          <input
            [(ngModel)]="row['Year']"
            matinput
            class="form-control input-width"
            type="text"
            name="Year"
            tabindex="-1"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row['Units']"
            matinput
            class="form-control input-width"
            type="text"
            name="Units"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row['Mileage']"
            matinput
            class="form-control input-width"
            type="text"
            name="Mileage"
            (focus)="removeCommas(i, 'Mileage', row['Vehicles'])"
            (blur)="addCommas(i, 'Mileage', row['Mileage'])"
          />
        </td>
        <td>
          <input
            [(ngModel)]="row[thirdExposure]"
            matinput
            class="form-control input-width"
            type="text"
            name="thirdExposureVal"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <button class="btn btn-default" type="button" (click)="addRow()">
            Add New Row
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table-content" *ngIf="options.fields[0].name === 'State'">
    <thead>
      <tr>
        <th *ngFor="let field of options.fields">{{ field.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="options.tableValue && options.tableValue.data">
      <tr *ngFor="let row of options.tableValue.data; let i = index">
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['State'] !== 'Total'
          "
        >
          <input
            [(ngModel)]="row['State']"
            matinput
            class="form-control input-width"
            type="text"
            list="state"
            name="State"
          />
          <datalist id="state">
            <option *ngFor="let state of getStateList()">
              {{ state }}
            </option>
          </datalist>
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['State'] !== 'Total'
          "
        >
          <input
            [(ngModel)]="row['Vehicles']"
            matinput
            class="form-control input-width"
            type="text"
            name="Vehicles"
            (focus)="removeCommas(i, 'Vehicles', row['Vehicles'])"
            (blur)="addCommas(i, 'Vehicles', row['Vehicles'])"
          />
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['State'] === 'Total'
          "
          class="table-column"
        >
          {{ row['State'] }}
        </td>
        <td
          *ngIf="
            options &&
            options.tableValue &&
            options.tableValue.data &&
            row &&
            row['State'] === 'Total'
          "
          class="table-column"
        >
          <input
            value="{{ getColumnTotal('Vehicles') }}"
            matInput
            type="text"
            readonly="true"
            tabindex="-1"
          />
        </td>
      </tr>
      <tr>
        <td class="table-column">
          <div class="add-row-column">
            <button class="btn btn-default" type="button" (click)="addRow()">
              Add New Row
            </button>
            <span>or</span>
          </div>
        </td>
        <td>
          <div class="upload-container">
            <div class="upload-dropzone">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                (change)="onFileChange($event)"
              />
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div mat-dialog-actions>
    <button appButton accent (click)="confirm(true)">
      <span>{{ options.submitLabel }}</span>
    </button>
    <button appButton link (click)="confirm(false)">
      <span>Cancel</span>
    </button>
  </div>
</mat-dialog-content>
