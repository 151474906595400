import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from './size.mixin'

/**
 * Get a color mixin that InputComponent can extend to handle color inputs
 */
class InputBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof InputBase = mixinSize(InputBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: component-selector
  selector: 'input[appInput]',
  styles: [
    `
           :host {
             --app-input-size: var(--font-size);
     
             font-family: var(--font-family);
             font-weight: var(--font-header-weight);
             font-size: var(--app-input-size);
             line-height: calc(var(--app-input-size) + 0.5rem);
             background-color: var(--bg-1);
             outline: none;
             color: var(--body);
             caret-color: var(--accent);
             border: 2px solid var(--border-1);
             border-radius: 5px;
             padding: var(--stack-tiny) var(--inset-tiny);
             transition: border-color 250ms;
             box-sizing: border-box;
           }
     
           :host:hover {
             border-color: var(--border-1-lit);
           }
     
           :host:focus,
           :host:active {
             border-color: var(--primary);
           }
     
           /* Size mixin */
     
           :host.mini {
             --app-input-size: var(--font-size-mini);
           }
     
           :host.tiny {
             --app-input-size: var(--font-size-tiny);
           }
     
           :host.small {
             --app-input-size: var(--font-size-small);
           }
     
           :host.big {
             --app-input-size: var(--font-size-big);
           }
     
           :host.huge {
             --app-input-size: var(--font-size-huge);
           }
     
           :host.gigantic {
             --app-input-size: var(--font-size-gigantic);
           }
     
           :host.massive {
             --app-input-size: var(--font-size-massive);
           }
     
           @media screen and (max-width: 1240px) {
             :host {
               --app-input-size: var(--font-size-small);
             }
     
             :host.mini {
               --app-input-size: var(--font-size-mini);
             }
     
             :host.tiny {
               --app-input-size: var(--font-size-mini);
             }
     
             :host.small {
               --app-input-size: var(--font-size-tiny);
             }
     
             :host.big {
               --app-input-size: var(--font-size);
             }
     
             :host.huge {
               --app-input-size: var(--font-size-big);
             }
     
             :host.gigantic {
               --app-input-size: var(--font-size-huge);
             }
     
             :host.massive {
               --app-input-size: var(--font-size-gigantic);
             }
           }
         `,
  ],
  template: `
    <ng-content></ng-content>
  `,
})
export class InputComponent extends _SizeMixinBase implements CanSize {
  // Size mixin
  @Input() size: Size
  @Input() massive: boolean
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @Input() mini: boolean
  @HostBinding('class.massive')
  get isSizeMassive() {
    return this.size === 'massive'
  }
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  constructor(public elementRef: ElementRef) {
    super(elementRef)
  }
}
