import { Directive, Input, ElementRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[formatNumber]',
})
// tslint:disable-next-line: directive-class-suffix
export class FormatNumber {
  @Input() formatNumber: string
  @Input() input: any

  constructor(public el: ElementRef) {
    this.el.nativeElement.onkeypress = (evt: any) => {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    }
  }
}
