import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  QuoteCustomCompareView,
  QuoteReinsurer,
  RenewedFromRiskAndSection,
} from '../models/quote.model'
import { LossSetLayer } from '../../analysis/model/loss-set-layers.model'
import { Study } from 'src/app/core/model/study.model'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { UtilService } from 'src/app/util.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote',
  styleUrls: ['./quote.component.scss'],
  templateUrl: './quote.component.html',
})
export class QuoteComponent {
  @Input() currentProgramId: string | null | undefined
  @Input() programs: Study[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null
  @Input() isExpanded: boolean
  @Input() selectedCededLayerID: string | null | undefined
  @Input() isGroupSelected = false
  @Input() isSLSelected = false
  @Input() renewedFromRisks: RenewedFromRiskAndSection[]
  @Input() doesCurrentLayerHaveExpiring: boolean
  @Input() isLoading: boolean
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() layer: LayerState
  @Input() reinsurers: ReinsurerState[]
  @Input() compareView: QuoteCustomCompareView | null
  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()
  @Output() fetchQuoteFieldsForSelectedRiskAndSection =
    new EventEmitter<RenewedFromRiskAndSection>()
  @Output() riskCodeClick = new EventEmitter<string>()
  @Output() backQuickQuoteClick = new EventEmitter()
  @Output() saveCustomComparison = new EventEmitter()
  @Output() resetSelectedComparison = new EventEmitter()

  showCreateNewRiskRef = false
  showSubjectivity = false
  showTrackingModule = false
  exportClicked = false
  exportCustomCompareClicked = false
  saveSubClicked = false
  showToggle = false
  showAssignedLines = false
  saveAssignedLinesClicked = false
  showQuickQuote = false
  showCustomCompareBuilder = false
  showCustomCompare = false
  saveQuickQuoteClicked = false
  expandedReinsurer: QuoteReinsurer | undefined

  constructor(private utilService: UtilService) {}

  backClick(): void {
    this.showSubjectivity = false
    this.showTrackingModule = false
    this.showToggle = false
    this.showAssignedLines = false
    this.showQuickQuote = false

    if (this.showCustomCompare) {
      this.showCustomCompare = false
      this.showCustomCompareBuilder = true
    } else if (this.showCustomCompareBuilder) {
      this.showCustomCompareBuilder = false
      this.resetSelectedComparison.emit()
    }
  }

  trackingClick(): void {
    this.showTrackingModule = true
  }

  onExportClick(): void {
    this.exportClicked = true
  }

  onExportCustomCompareClicked(): void {
    this.exportCustomCompareClicked = true
  }

  onExportDone(): void {
    this.exportClicked = false
    this.exportCustomCompareClicked = false
  }

  onSaveSubjectivityClick(): void {
    this.saveSubClicked = true
  }

  onSaveAssignedLinesClick(): void {
    this.saveAssignedLinesClicked = true
  }

  onSaveSubjectivityDone(): void {
    this.saveSubClicked = false
    this.showSubjectivity = false
  }

  onSaveAssignedLinesDone(): void {
    this.saveAssignedLinesClicked = false
    this.showAssignedLines = false
  }

  onShowSubjectivityClick(): void {
    this.showSubjectivity = true
  }

  onShowAssignedLinesClick(): void {
    this.showAssignedLines = true
  }

  onShowToggleClick(): void {
    this.showToggle = true
  }

  onShowQuickQuoteClick(): void {
    this.showQuickQuote = true
  }

  onSaveQuickQuoteClick(): void {
    this.utilService.spinnerShow()
    this.saveQuickQuoteClicked = true
  }

  onSaveQuickQuoteDone(): void {
    this.saveQuickQuoteClicked = false
    this.showQuickQuote = false
  }

  onShowCustomCompareClick(): void {
    this.showCustomCompareBuilder = true
  }

  onBackCustomCompareClick(): void {
    this.showCustomCompareBuilder = false
    this.showCustomCompare = false
  }

  setExpandedReinsurer(event: QuoteReinsurer | undefined): void {
    this.expandedReinsurer = event
  }

  onBackAssignedSubjectivitiesClick(): void {
    this.showSubjectivity = false
  }

  onBackQuickQuoteClick(): void {
    this.showQuickQuote = false
    this.backQuickQuoteClick.emit()
  }

  onViewCustomCompareClick(): void {
    this.showCustomCompareBuilder = false
    this.showCustomCompare = true
  }

  onResetDisplayOnLayerGroupSLChange(): void {
    this.showSubjectivity = false
    this.showTrackingModule = false
    this.showToggle = false
    this.showAssignedLines = false
    this.showCustomCompare = false
    this.showCustomCompareBuilder = false
  }
}
