<app-auto-group-loss-sets
  [parentLossSets]="parentLossSets$ | async"
  [portfolioSetID]="portfolioSetID$ | async"
  [selectedClientID]="selectedClientID$ | async"
  [selectedYearID]="selectedYearID$ | async"
  [selectedStudyID]="selectedStudyID$ | async"
  [selectedStructureID]="selectedStructureID$ | async"
  [currentProgram]="currentProgram$ | async"
  [studies]="studies$ | async"
  (closeScaleDialog)="onCloseClick($event)"
  (saveLossSetGroupClick)="onSaveLossSetGroupClick($event)"
></app-auto-group-loss-sets>
