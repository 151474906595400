import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { Observable } from 'rxjs'
import { FormControl, FormGroup } from '@angular/forms'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { IControl } from '../management-information/store/management-information.reducer'
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-multiselect-autocomplete',
  styles: [
    `
      .field {
        width: 100%;
        box-sizing: border-box;
        padding: 0 var(--inset-small) 0 var(--inset-big);
        border-bottom: 1px solid var(--border);
        margin-bottom: var(--stack);
        font-weight: bold;
        font-size: var(--font-size-small);
      }

      .field-less-width {
        width: 88% !important;
      }

      mat-form-field {
        color: var(--body);
        margin-bottom: 10px;
      }

      mat-form-field mat-label {
        font-size: var(--font-size-small);
      }

      mat-form-field ::ng-deep .mat-mdc-form-field-suffix {
        margin: auto 0;
        align-self: flex-end;
      }

      .suffix-icon {
        font-size: 125%;
        height: 22px;
        width: 18px;
        color: var(--primary);
        transition: 250ms color;
        cursor: pointer;
      }

      .suffix-icon:hover,
      .suffix-icon:focus {
        color: var(--primary-lit);
      }

      :host.has-control .control .suffix-icon {
        font-size: 18px;
        right: 0;
      }

      :host:not(.has-control) .control .suffix-icon {
        top: -6px;
      }

      .child-filter-1 {
        padding-left: 35px;
      }

      .child-filter-2 {
        padding-left: 50px;
      }

      .child-filter-3 {
        padding-left: 65px;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
      ::ng-deep .mat-autocomplete-panel {
        max-height: 50vh !important;
      }

      ::ng-deep
        .mat-mdc-autocomplete-panel
        .mdc-menu-surface
        .mdc-menu-surface--open {
        min-width: 200px !important;
      }

      .input-field {
        min-width: 80%;
      }

      .mat-mdc-option {
        padding: 8px 16px;
      }

      .no-select-limit {
        padding: 0;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      .mat-mdc-checkbox,
      ::ng-deep .mat-checkbox-layout,
      ::ng-deep .mat-checkbox-label,
      ::ng-deep .mat-mdc-option-text {
        width: 100%;
      }

      .mat-mdc-checkbox,
      ::ng-deep .mat-mdc-option-text {
        height: 100%;
      }

      .mat-mdc-checkbox {
        padding: 0 16px;
        display: flex;
        align-items: center;
      }

      label.loading {
        margin: var(--stack-big) var(--inset-big);
        display: block;
      }

      .loading {
        cursor: wait;
      }
      .loading > * {
        pointer-events: none;
      }

      .mat-divider {
        border-top-color: var(--accent);
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }

      h2 {
        padding: 8px 0 5px 8px;
        margin-bottom: -1px;
        color: var(--accent);
        font-size: var(--font-size-small);
      }

      h2.other {
        margin-top: -1px;
      }

      ::ng-deep .mat-mdc-tooltip.metric-tooltip {
        zoom: 1.5;
      }

      @media only screen and (max-width: 1180px) {
        input.mat-mdc-chip-input {
          flex: 1 0 135px;
        }

        .chip-name-wrapper {
          width: calc(100% - 26px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .mat-mdc-chip {
          overflow: hidden;
        }
      }
    `,
  ],
  template: `
    <mat-form-field
      [ngClass]="getIndentationClass(childNumber)"
      [class.loading]="loading"
      class="field control app-bar-field app-bar-field-button app-field-bold-label"
      [class.field-less-width]="useLessWidth"
      [formGroup]="form"
      [id]="filter?.columnName"
      subscriptSizing="dynamic"
    >
      <mat-label
        [class.disabled]="!filter?.values?.length || disabled"
        [class.loading]="loading"
      >
        {{ name }}
      </mat-label>

      <mat-chip-grid #chipList aria-label="Selected values">
        <mat-chip-row
          [class.hidden]="triggerPanel?.panelOpen"
          [class.app-chip-palette]="includedInFilterValues(option)"
          [class.app-palette-orange]="includedInFilterValues(option)"
          *ngFor="let option of selectedValues"
          (removed)="remove(option, filter)"
          matTooltip="{{ option }}"
        >
          <div class="chip-name-wrapper">
            {{ option }}
          </div>
          <mat-icon matChipRemove [class.hidden]="hideChipRemove"
            >cancel</mat-icon
          >
        </mat-chip-row>

        <input
          matInput
          class="input-field"
          #filterInput
          [disabled]="!filter?.values?.length || loading || disabled"
          [formControl]="filterCtrl"
          [placeholder]="isExportEnabled ? '' : 'Click & type to add...'"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
          [class.loading]="loading"
        />

        <mat-icon
          *ngIf="!isExportEnabled && !disabled"
          class="suffix-icon"
          matSuffix
          (click)="openOptions()"
        >
          arrow_drop_down
        </mat-icon>
        <mat-icon
          *ngIf="
            (form?.controls)[filter?.columnName]?.value?.length &&
            !isExportEnabled
          "
          class="suffix-icon"
          [class.hidden]="hideClearFilter"
          matSuffix
          (click)="clearFilter.emit($event)"
          >cancel</mat-icon
        >
      </mat-chip-grid>

      <mat-autocomplete
        #auto="matAutocomplete"
        (closed)="panelClosed(filter)"
        (optionSelected)="onOptionSelected($event, filter)"
        panelClass="app-bar-panel large"
        hideSingleSelectionIndicator
      >
        <h2>Applicable</h2>
        <ng-container *ngFor="let option of filtered | async">
          <mat-option
            *ngIf="option.length > 0"
            [value]="option"
            [class.no-select-limit]="!limitToOneSelection"
          >
            <span
              *ngIf="limitToOneSelection"
              [matTooltip]="option"
              matTooltipClass="metric-tooltip"
              matTooltipShowDelay="250"
              matTooltipPosition="after"
              >{{ option }}
            </span>
            <mat-checkbox
              *ngIf="!limitToOneSelection"
              [checked]="isSelected(option)"
              [matTooltip]="option"
              matTooltipClass="metric-tooltip"
              matTooltipShowDelay="250"
              (click)="
                $event.preventDefault();
                $event.stopImmediatePropagation();
                toggleOption(option, filter)
              "
              matTooltipPosition="after"
            >
              {{ option }}
            </mat-checkbox>
          </mat-option>
          <mat-divider *ngIf="option.length <= 0"></mat-divider>
          <h2 class="other" *ngIf="option.length <= 0">Other</h2>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  `,
})
export class MultiselectAutocompleteComponent {
  _filter: IControl
  selectedValues: string[] = new Array<string>()
  filtered: Observable<string[]>
  lastFilter: string
  separatorKeysCodes: number[] = [ENTER, COMMA]
  filterCtrl = new FormControl()
  @Input() form: FormGroup
  @Input() childNumber: number
  @Input() disabled = false
  @Input() sectionEnd: boolean
  @Input() useLessWidth: boolean
  @Input() loading: boolean
  @Input() name: string
  @Input() isExportEnabled: boolean
  @Input() hideClearFilter: boolean
  @Input() hideChipRemove: boolean
  @Input() limitToOneSelection = false

  @Input() set filter(value: IControl) {
    this._filter = value
    this.setDataOnReload(value.columnName, value.selectedValues)
    this.filtered = this.filterCtrl.valueChanges.pipe(
      startWith<string>(''),
      map((filterBy: string) =>
        typeof filterBy === 'string' ? filterBy : this.lastFilter
      ),
      map((filterBy: string) => this.filterData(filterBy))
    )
  }
  get filter(): IControl {
    return this._filter
  }
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>
  @ViewChild('filterInput', { read: MatAutocompleteTrigger, static: false })
  triggerPanel: MatAutocompleteTrigger

  @Output() clearFilter = new EventEmitter()
  @Output() setFilter = new EventEmitter()

  setDataOnReload(filterId: string, values: string[]) {
    this.form.controls[filterId].setValue(values, { emitEvent: false })
    this.selectedValues = [...values]
  }

  filterData(filter: string): string[] {
    this.lastFilter = filter

    return this.getFiltered(this.filter.values, filter).concat(
      '', // concatenate empty string to divide applicable and other options
      this.getFilteredWithoutDupes(
        this.getFiltered(this.filter.allValues, filter)
      )
    )
  }

  getFiltered(vals: string[], filter: string) {
    if (!filter) {
      return vals
    }

    return vals.filter(option => {
      return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    })
  }

  getFilteredWithoutDupes(vals: string[]) {
    return vals.filter(option => {
      return this.filter.values.indexOf(option) < 0
    })
  }

  getIndentationClass(childNumber?: number): string {
    if (childNumber) {
      return `child-filter-${childNumber}`
    }
    return ''
  }

  isSelected(option: string): boolean {
    return this.selectedValues.indexOf(option) > -1
  }

  remove(option: string, filter: IControl): void {
    const index = this.selectedValues.indexOf(option)

    if (index >= 0) {
      this.selectedValues.splice(index, 1)
      this.form.controls[filter.columnName].setValue(this.selectedValues)
      this.setFilter.emit()
    }
  }

  panelClosed(filter: IControl): void {
    this.filterInput.nativeElement.value = ''
    this.filterCtrl.setValue('')
    const control = this.form.controls[filter.columnName]

    if (control.value?.join() !== this.selectedValues.join()) {
      control.setValue(this.selectedValues)
      this.setFilter.emit()
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent, filter: IControl) {
    const option = event.option.value
    this.toggleOption(option, filter)
    this.panelClosed(filter)
  }

  toggleOption(option: string, filter?: IControl) {
    const indexOf = this.selectedValues.indexOf(option)
    if (indexOf < 0 && this.limitToOneSelection) {
      this.selectedValues = [option]
    }

    if (indexOf < 0 && !this.limitToOneSelection) {
      this.selectedValues.push(option)
    }

    if (indexOf >= 0 && !this.limitToOneSelection) {
      this.selectedValues.splice(indexOf, 1)
    }

    if (this.limitToOneSelection) {
      this.filterInput.nativeElement.value = ''
      this.filterCtrl.setValue('')
      const control = this.form.controls[filter?.columnName ?? '']
      if (control.value?.join() !== this.selectedValues.join()) {
        control.setValue(this.selectedValues)
        this.setFilter.emit()
      }
    }
  }

  openOptions() {
    this.triggerPanel.openPanel()
  }

  includedInFilterValues = (selectedOption: string) =>
    this.filter.values.includes(selectedOption)
}
