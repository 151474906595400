import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from 'src/app/api/backend/backend.service'
import {
  AccountOpportunity,
  OTCreateNewQuoteRequest,
  ProcessLog,
} from 'src/app/api/model/backend.model'
import { SalesforceClient } from 'src/app/api/model/signature.model'
import { SignatureReinsurerService } from 'src/app/api/signature/signature-reinsurer.service'
import { SlipTemplatesService } from 'src/app/api/slip-templates/slip-templates.service'
import { Study } from 'src/app/core/model/study.model'

@Component({
  selector: 'app-open-twin-new-risk-dialog',
  templateUrl: './open-twin-new-risk-dialog.component.html',
  styleUrls: ['./open-twin-new-risk-dialog.component.scss'],
})
export class OpenTwinNewRiskDialogComponent
  implements OnInit, OnDestroy, OnChanges
{
  constructor(
    public dialogRef: MatDialogRef<OpenTwinNewRiskDialogComponent>,
    private signatureService: SignatureReinsurerService,
    private slipTemplateService: SlipTemplatesService,
    private backendService: BackendService
  ) {}

  @Input() accountOpportunities: AccountOpportunity[]
  @Input() programs: Study[]
  @Input() selectedClientID: string
  @Input() selectedStudyID: string
  @Input() hasCoBroker: boolean
  @Input() isUsaBasedUser: boolean

  selectedSFOpportunity: AccountOpportunity | undefined

  private destroy$ = new Subject()

  submitting: boolean
  errorMessage: string
  processId: string | undefined
  processLog: ProcessLog | undefined
  clients: SalesforceClient[] | undefined
  filteredOptions: SalesforceClient[] | undefined
  clientInput = new FormControl()
  riskTypeInput = new FormControl()
  riskDescInput = new FormControl()
  baseCurrencyInput = new FormControl()
  createCoBrokedRisk = false
  currencyTypes = ['USD', 'GBP', 'EUR']
  coBrokedRiskRefTypes = [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ]
  currentSFOpportunityId: string
  polling: boolean
  isComplete: boolean
  logInterval: any
  logCount = 0
  dialogSubtitle = ''


  ngOnInit(): void {
    this.filteredOptions = this._filter('')
  }

  ngOnChanges(): void {

    if (
      this.selectedStudyID &&
      this.selectedClientID &&
      this.programs &&
      this.accountOpportunities
    ) {
      const selectedProgram = this.programs.find(
        x => x.id === this.selectedStudyID
      )

      if (selectedProgram?.opportunity_id) {
        this.selectedSFOpportunity = this.accountOpportunities.find(
          x => x.oppId === selectedProgram?.opportunity_id
        )
        if (
          this.selectedSFOpportunity &&
          this.selectedSFOpportunity.parentBU === 'UK'
        ) {
          const keyValuePairs = {
            carrierID: this.selectedClientID,
            studyID: this.selectedStudyID,
            coBrokerRef: '20001295',
          }
          this.slipTemplateService
            .getCoBrokerDetails(keyValuePairs)
            .subscribe(res => {
              if (res.error !== undefined) {
                this.errorMessage = res.error.message
              } else {
                this.hasCoBroker = res.data ? res.data : false
                this.setSubtitleAndCoBrokedRiskFlag()
              }
            })
        } else if (
          this.selectedSFOpportunity &&
          this.selectedSFOpportunity.parentBU === 'US'
        ) {
          const keyValuePairs = {
            carrierID: this.selectedClientID,
            studyID: this.selectedStudyID,
            coBrokerRef: '20001293',
          }
          this.slipTemplateService
            .getCoBrokerDetails(keyValuePairs)
            .subscribe(res => {
              if (res.error !== undefined) {
                this.errorMessage = res.error.message
              } else {
                this.hasCoBroker = res.data ? res.data : false
                this.setSubtitleAndCoBrokedRiskFlag()
              }
            })
        } else if (
          this.selectedSFOpportunity &&
          (this.selectedSFOpportunity.parentBU === 'LC' || this.selectedSFOpportunity.parentBU === 'DE') // LAC or German Involvement
        ) {
          // hold off on checking for co-broker for now - need reqs
          this.hasCoBroker = false
          this.setSubtitleAndCoBrokedRiskFlag()
        }

        // set defaults and fill client dropdown values
        if (this.selectedSFOpportunity) {
          this.currentSFOpportunityId = this.selectedSFOpportunity.oppId
          this.signatureService
            .getAllClientsFromSalesforce()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
              if (res.error !== undefined) {
                this.errorMessage = res.error.message
              } else {
                this.clients = res.data
              
                // use transactional Account OT Id if available
                const clientOpenTwinsId = this.selectedSFOpportunity?.transactionalAcctOpenTWINSId ?? this.selectedSFOpportunity?.accountOpenTwinsID
                const foundClient = this.clients?.find(
                  s =>
                    s.tpRef === clientOpenTwinsId
                )
                if (foundClient) {
                  this.clientInput.setValue(foundClient)
                  this.baseCurrencyInput.setValue(
                    this.selectedSFOpportunity?.opportunityCurrency
                  )
                  this.riskTypeInput.setValue(this.convertRiskType(this.selectedSFOpportunity?.opportunityRiskType))
                  this.riskDescInput.setValue(
                    this.selectedSFOpportunity?.opportunityName
                  )
                }
              }
            })
        }
      }
    }
  }

  setSubtitleAndCoBrokedRiskFlag(): void {
    this.createCoBrokedRisk = false

    if (this.isUsaBasedUser){

      this.dialogSubtitle = 'This will create a new US/NA risk in OpenTWINS, generally meant for new business (not renewals)'

      if (this.selectedSFOpportunity?.parentBU !== 'US'){

        // non-US opp and US user
        if (this.hasCoBroker){
          this.createCoBrokedRisk = true
        } 
        else{
          this.dialogSubtitle = `This is a ${this.selectedSFOpportunity?.parentBU} opportunity with no correspondent brokers. Please confirm before creating a ${this.selectedSFOpportunity?.parentBU} risk in OpenTWINS`
        }
      }
    }
    else{
      // non-US based user
      this.dialogSubtitle = `This will create a new ${this.selectedSFOpportunity?.parentBU} risk in OpenTWINS, generally meant for new business (not renewals)`

      if (this.selectedSFOpportunity?.parentBU == 'US'){
         
          if (this.hasCoBroker){
            // will create a UK risk since it's US but has UK cobrokers 
            this.createCoBrokedRisk = true
            this.dialogSubtitle = `This will create a new UK risk in OpenTWINS since there are correspondent brokers. New risks are generally meant for new business (not renewals)`
          }
          else{
            this.dialogSubtitle = `This is a US/NA opportunity with no correspondent brokers. Please confirm before creating a US risk in OpenTWINS`
          }
      }
    }
  }

  convertRiskType(input: string): string {
    if (input === 'Pro Rata' || input === 'PROP') {
      return 'PROP'
    }
    return input
  }

  submit(): void {
    if (
      !this.clientInput.value ||
      !this.baseCurrencyInput.value ||
      !this.riskTypeInput.value
    ) {
      this.errorMessage = 'You must enter all fields before submitting'
    } else {
      const req: OTCreateNewQuoteRequest = {
        sfOpportunityId: this.currentSFOpportunityId,
        clientTpRef: (this.clientInput.value as SalesforceClient).tpRef,
        riskDesc: this.riskDescInput.value,
        riskType: this.riskTypeInput.value,
        baseCurrency: this.baseCurrencyInput.value,
        coBrokedRiskRef: this.createCoBrokedRisk,
      }
      this.submitting = true
      this.backendService.postOpenTWINSCreateQuote(req).subscribe(res => {
        if (res.error !== undefined) {
          this.errorMessage = res.error.message
        }
        this.processId = res.data?.processId
        this.logCount = 0
        this.polling = true
        this.logInterval = setInterval(() => {
          this.logCount += 1
          this.getProcessLog(this.processId)
        }, 3000)
      })
    }
  }

  onAutocompleteChange(inputValue: any): void {
    this.filteredOptions = this._filter(inputValue)
  }

  displayFn(client: SalesforceClient): string | undefined {
    return client ? `${client.acctName} (${client.tpRef})` : undefined
  }

  private _filter(display: string): SalesforceClient[] {
    if (this.clients) {
      return this.clients?.filter(
        (option: SalesforceClient) =>
          option.acctName?.toLowerCase().startsWith(display.toLowerCase()) ||
          option.tpRef?.toLowerCase().startsWith(display.toLowerCase())
      )
    }
    return []
  }

  getProcessLog(pId: string | undefined): void {
    if (this.logCount > 30) {
      // 3 seconds per log check so total polling = 90 secons
      this.polling = false
      this.submitting = false
      this.isComplete = true
      clearInterval(this.logInterval)
    }

    if (pId) {
      this.backendService.getProcessLog(pId).subscribe(res => {
        this.processLog = res.data
        if (
          this.processLog?.status === 'COMPLETE' ||
          this.processLog?.status === 'ERROR'
        ) {
          this.polling = false
          this.submitting = false
          this.isComplete = true
          clearInterval(this.logInterval)
        }
      })
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'cancel' })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
