<ng-container>
  <div class="ld-container">
    <h1 mat-dialog-title>Layer Details for {{ this.name }}</h1>
    <div class="options">
      <button
        matTooltipPosition="before"
        matTooltip="Click to export CSV of displayed table."
        appButtonIcon
        huge
        opaque
        (click)="onExportClick()"
      >
        cloud_download
      </button>
    </div>
    <div class="table-container">
      <app-sort-table
        [columnDefs]="updatedColumnDefs"
        [rows]="rows"
      ></app-sort-table>
    </div>

    <label appInfoText hint small>
      Currency values are shown in thousands
    </label>
  </div>
</ng-container>
