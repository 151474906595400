<div>
  <h1 *ngIf="!data.isGroup" mat-dialog-title>Duplicate Structures Found</h1>
  <h1 *ngIf="data.isGroup" mat-dialog-title>
    Duplicate Structures Groups Found
  </h1>
  <div *ngIf="!data.isGroup" mat-dialog-content>
    A group cannot have same structure more than once.
  </div>
  <div *ngIf="data.isGroup" mat-dialog-content>
    A group cannot have same structure group more than once.
  </div>
  <div mat-dialog-actions>
    <button appButton link mat-dialog-close (click)="cancel()">
      <span>Cancel</span>
    </button>
  </div>
</div>
