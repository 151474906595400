import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { AnimatedScenariosContainerComponent } from './animated-scenarios.container/animated-scenarios.container'
import { AnimatedScenariosRoutingModule } from './animated-scenarios-routing.module'
import { AnimatedScenariosComponent } from './animated-scenarios/animated-scenarios.component'
import { StoreModule } from '@ngrx/store'
import * as fromAnimatedScenariosReducer from './store/animated-scenarios.reducer'
import { AnimatedScenariosEffects } from './store/animated-scenarios.effects'
import { ScenarioEventTableComponent } from './event-table/event-table.component'
import {
  SwiperConfigInterface,
  SwiperModule,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper-v-13'
import { ScenarioEventResultComponent } from './event-result/event-result.component'
import { ScenarioEventResultTableComponent } from './event-result-table/event-result-table.component'
import { EffectsModule } from '@ngrx/effects'
import { Tower3DComponent } from './tower-3d.component/tower-3d.component'
import { GroupAnimatedScenariosContainerComponent } from './group/group-animated-scenarios.container/group-animated-scenarios.container'
import { GroupAnimatedScenariosComponent } from './group/group-animated-scenarios/group-animated-scenarios.component'
import { GroupAnimatedScenariosHeaderComponent } from './group/group-animated-header/group-animated-header.component'
import { GroupAnimatedScenariosEffects } from './store/group/group-animated-scenarios.effects'
import { CededLossDetailsDialogComponent } from './ceded-loss-details-dialog/ceded-loss-details-dialog.component'
import { AnimatedScenariosToolbarComponent } from './animated-scenarios-toolbar/animated-scenarios-toolbar.component'
import { GroupEventResultComponent } from './group/group-event-result/group-event-result.components'
import { GroupScenarioEventTableDialogContainerComponent } from './group/group-event-table-dialog.container/group-event-table-dialog.container'

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
}
@NgModule({
  declarations: [
    AnimatedScenariosContainerComponent,
    AnimatedScenariosComponent,
    ScenarioEventTableComponent,
    ScenarioEventResultComponent,
    ScenarioEventResultTableComponent,
    Tower3DComponent,
    GroupAnimatedScenariosContainerComponent,
    GroupAnimatedScenariosComponent,
    GroupAnimatedScenariosHeaderComponent,
    CededLossDetailsDialogComponent,
    AnimatedScenariosToolbarComponent,
    GroupEventResultComponent,
    GroupScenarioEventTableDialogContainerComponent,
  ],
  imports: [
    AnimatedScenariosRoutingModule,
    SharedModule,
    StoreModule.forFeature(
      fromAnimatedScenariosReducer.ANIMATED_SCENARIOS_FEATURE_KEY,
      fromAnimatedScenariosReducer.reducer
    ),
    EffectsModule.forFeature([
      AnimatedScenariosEffects,
      GroupAnimatedScenariosEffects,
    ]),
    SwiperModule,
  ],
  providers: [{ provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG }],
})
export class AnimatedScenariosModule {}
