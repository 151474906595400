<mat-card appearance="outlined" [ngClass]="getClass()">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <button
      mat-icon-button
      class="remove-button"
      [disabled]="!isDrop && disableDelete"
      (click)="this.deleteDropLayer.emit(layer.id)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <app-layer-property
      name="Layer Name"
      type="text"
      [readonly]="title === 'Combined'"
      [value]="layerName"
      (inputChange)="layerName = $event"
    >

    </app-layer-property>

    <app-layer-property
      name="Per Occ. Limit"
      [type]="!isHiddenLayer ? 'currency' : 'empty'"
      [readonly]="isHiddenLayer"
      [value]="perOccLimit"
      [currentCurrency]="currentCurrency"
      (inputChange)="perOccLimit = $event"
    >
    </app-layer-property>

    <app-layer-property
      name="Per Occ. Attachment"
      [type]="!isHiddenLayer ? 'currency' : 'empty'"
      [readonly]="isHiddenLayer"
      [value]="perOccAttachment"
      [currentCurrency]="currentCurrency"
      (inputChange)="perOccAttachment = $event"
    >
    </app-layer-property>

    <app-layer-property
      name="Aggregate Limit"
      [type]="!isHiddenLayer ? 'currency' : 'empty'"
      [readonly]="isHiddenLayer"
      [value]="aggregateLimit"
      [currentCurrency]="currentCurrency"
      (inputChange)="aggregateLimit = $event"
    >
    </app-layer-property>

    <app-layer-property
      name="Aggregate Attachment"
      [type]="!isHiddenLayer ? 'currency' : 'empty'"
      [readonly]="isHiddenLayer"
      [value]="aggregateAttachment"
      [currentCurrency]="currentCurrency"
      (inputChange)="aggregateAttachment = $event"
    >
    </app-layer-property>

    <app-percentage-input
      name="Cession Percentage"
      [value]="cessionPercentage"
      [decimal]="2"
      [isCession]="true"
      [isNegative]="true"
      (inputChange)="cessionPercentage = $event"
    >
    </app-percentage-input>
  </mat-card-content>
</mat-card>
