import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromTracking from './tracking.reducer'

export const selectTrackingState =
  createFeatureSelector<fromTracking.TrackingState>(fromTracking.TRACKING_KEY)

export const selectTrackingData = createSelector(
  selectTrackingState,
  state => state.trackingData
)

export const selectTrackingSelectors = createSelector(
  selectTrackingState,
  state => state.selectors
)

export const selectInceptionTimeInterval = createSelector(
  selectTrackingState,
  state => state.inceptionTimeInterval
)

export const selectIsLoading = createSelector(
  selectTrackingState,
  state => state.isLoading
)

export const selectIsDetailsLoading = createSelector(
  selectTrackingState,
  state => state.isDetailsLoading
)

export const selectTrackingUserPreferences = createSelector(
  selectTrackingState,
  state => state.trackingUserPreferences
)
