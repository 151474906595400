import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core'
import { Numeric } from 'd3'
import { format } from 'd3-format'
import { BubbleChart } from '@graphing/bubble-chart'
import { BaseDimension, Coord } from '@graphing/utils/coord'
import { GraphingLineData } from '@graphing/models/graphing.model'
import { D3SeriesAnnotation } from '@graphing/models/series-annotation.model'
import { LayerViewValues } from '../../model/layer-view'
import { LayerModelingProp } from '../layer-modeling-defs'
import {
  LayerModelingDatum,
  LayerModelingDimension,
  LayerModelingDimensionChangeEvent,
} from '../layer-modeling.model'
import { LayerModelingState } from '../store/layer-modeling.reducer'
import { currencySymbol } from '../../model/layers.util'
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-modeling',
  styleUrls: ['./layer-modeling.component.scss'],
  templateUrl: './layer-modeling.component.html',
})
export class LayerModelingComponent implements OnChanges, AfterViewInit {
  private chart: BubbleChart<LayerModelingDatum>

  @Input() loading: boolean
  @Input() error?: string
  @Input() data: LayerModelingDatum[]
  @Input() medians?: Record<BaseDimension, number>
  @Input() secondaryLines?: Coord[][]
  @Input() colors?: string[]
  @Input() state: LayerModelingState
  @Input() props: LayerModelingProp[]
  @Input() currentCurrency: string

  @Output() dimensionChange =
    new EventEmitter<LayerModelingDimensionChangeEvent>()

  @ViewChild('chart') chartEl: ElementRef | undefined

  ngAfterViewInit(): void {
    this.init()
  }

  ngOnChanges(): void {
    this.init()
  }

  init() {
    if (
      !this.chartEl ||
      !this.chartEl.nativeElement ||
      this.loading ||
      this.error ||
      this.data.length === 0
    ) {
      return
    }

    const xProp = this.props.find(p => p.id === this.state.x)
    const yProp = this.props.find(p => p.id === this.state.y)
    const sizeProp = this.props.find(p => p.id === this.state.size)

    if (!xProp || !yProp || !sizeProp) {
      const s = JSON.stringify(this.state, null, 2)
      throw Error(`Layer Modeling dimension not found (${s}).`)
    }

    const formatters: Record<LayerModelingDimension, ValueFormatter> = {
      x: getFormatter(xProp),
      y: getFormatter(yProp),
      size: getFormatter(sizeProp),
    }

    this.chart = new BubbleChart(this.chartEl.nativeElement, {
      crossValue: (d: LayerModelingDatum) => d.x,
      mainValue: (d: LayerModelingDatum) => d.y,
      size: (d: LayerModelingDatum) => d.size,
      label: (d: LayerModelingDatum) => d.description,
      xLabel: this.currencyFormatter(xProp.valueType, xProp.label),
      yLabel: this.currencyFormatter(yProp.valueType, yProp.label),
      colorClass: colorClassFn,
      annotationBuilder: makeAnnotationBuilder(
        formatters,
        xProp.valueType,
        yProp.valueType
      ),
      xTickFormat: formatters.x,
      yTickFormat: formatters.y,
      defaultColor: 'var(--accent-strong)',
      lineColorClass: 'app-palette-4',
      optimization: !!this.secondaryLines,
      colors: this.colors,
      lineStrokeDashArray: '0',
    })

    const opts: GraphingLineData = {}
    if (this.medians) {
      opts.xLines = [this.medians.x]
      opts.yLines = [this.medians.y]
    }
    if (this.secondaryLines) {
      opts.secondaryLines = this.secondaryLines
    }
    this.chart.draw(this.data, opts)
  }

  render(): void {
    if (this.chart) {
      this.chart.render()
    }
  }

  onDimensionChange(
    dimension: LayerModelingDimension,
    prop: keyof LayerViewValues
  ) {
    this.dimensionChange.emit({ dimension, prop })
  }

  currencyFormatter(valueType: string | undefined, axisLabel: string) {
    if (valueType === 'currency') {
      return axisLabel + ' (' + currencySymbol(this.currentCurrency) + 'm)'
    } else {
      return axisLabel
    }
  }
}

/** `,`: thousands comma separator
 * `.3`: 3 significant digits
 * `~`:  trim trailing zeroes
 * `p`:  multiply by 100 and round to the specified sig. digits
 * `s`:  abbreviate w/ SI unit (e.g. `k` for 1000s, `M` for million, etc.)
 */
const formatPercentage = format(',.3~p')
const formatNumber = format(',.3~s')
const formatDecimal = format('.3~')

const colorClassFn = (d: LayerModelingDatum): string | undefined => {
  return d.layerType && `app-palette-${d.layerType}`
}

const makeAnnotationBuilder =
  (
    formatters: Record<LayerModelingDimension, ValueFormatter>,
    xPropType: string | undefined,
    yPropType: string | undefined
  ) =>
  (d: LayerModelingDatum): D3SeriesAnnotation => ({
    note: {
      title: `X: ${
        xPropType === 'currency' ? currencySymbol(d.currency) : ''
      }${formatters.x(d.x)},\nY: ${
        yPropType === 'currency' ? currencySymbol(d.currency) : ''
      }${formatters.y(d.y)},`,
      label: `Size: ${formatters.size(d.size)}`,
      wrapSplitter: /\n/,
      bgPadding: 5,
    },
    x: d.x,
    y: d.y,
    dx: 20,
    dy: -20,
  })

type ValueFormatter = (n: number | Numeric) => string

const getFormatter = (prop: LayerModelingProp) => {
  switch (prop.valueType) {
    case 'percentage':
    case 'ratio':
      return formatPercentage
  }
  switch (prop.id) {
    case 'portfolioEfficiencyScore':
      return formatDecimal
  }
  return formatNumber
}
