import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { Program } from 'src/app/core/model/program.model'
import { Reinsurer } from 'src/app/core/model/reinsurer.model'
import { AppState } from 'src/app/core/store'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import { addDataDialogClosed } from 'src/app/pricingcurve/store/pricing-curve.actions'
import { PhysicalLayer } from '../../model/layers.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { updatePhysicalLayer } from '../../store/ceded-layers/layers.actions'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { fetchLayersViewMetrics } from '../../store/metrics/layers-metrics.actions'
import { LayerMetricsState } from '../../store/metrics/layers-metrics.reducer'
import {
  initLayerEntries,
  updateLayerEntry,
} from '../../store/technical-premium/technical-premium.actions'
import { LayerEntry, LayerTypeDefaultEntry } from '../technical-premium.model'
import { StudyResponse } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-technical-premium-content',
  styleUrls: ['./technical-premium-content.component.scss'],
  templateUrl: './technical-premium-content.component.html',
})
export class TechnicalPremiumContentComponent implements OnChanges {
  @Input() currentProgram: Program | undefined
  @Input() towerLayers: LayerState[]
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() layerMetrics: Record<string, LayerMetricsState>
  @Input() layersViewIds: Record<string, string>
  @Input() reinsurersList: Reinsurer[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() layerTypeEntries: LayerTypeDefaultEntry
  @Input() studies: StudyResponse[]
  @Input() selectedProgramID: string

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layersViewIds) {
      if (this.currentProgram && this.layersViewIds) {
        const portfolioSetId = extractPortfolioSetID(this.currentProgram)
        if (!portfolioSetId) {
          return
        }
        const ids = Object.values(this.layersViewIds)
        ids.forEach(layerViewID => {
          this.store.dispatch(
            fetchLayersViewMetrics({
              ...portfolioSetId,
              layerViewID,
            })
          )
        })
      }
    }
  }

  onLayerEntryInit(entries: LayerEntry[]): void {
    this.store.dispatch(initLayerEntries({ entries }))
  }

  onLayerEntryUpdate(entry: LayerEntry): void {
    this.store.dispatch(updateLayerEntry({ entry }))
  }

  onLayerResize(partialPhysicalLayer: Partial<PhysicalLayer>): void {
    this.store.dispatch(
      updatePhysicalLayer({
        // tslint:disable-next-line:no-non-null-assertion
        id: partialPhysicalLayer.logicalLayerID!,
        change: partialPhysicalLayer,
      })
    )
  }

  onDialogClosed(): void {
    this.store.dispatch(addDataDialogClosed({ isTechPremium: true }))
  }
}
