import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

interface TremorIframe {
  tremor: string
}

@Component({
  selector: 'app-tremor-dialog',
  styles: [
    `
           .frame {
             height: 80vh;
             overflow: hidden;
           }
         `,
  ],
  template: `
    <div class="frame">
      <iframe
        frameBorder="0"
        [src]="safeSrc"
        width="100%"
        height="100%"
        min-height="100% !important"
      ></iframe>
    </div>
  `,
})
export class TremorDialogComponent {
  url = 'https://api.app.tremor.co/embed/v1/program?placementid='
  safeSrc: SafeResourceUrl
  constructor(
    public dialogRef: MatDialogRef<TremorDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: TremorIframe
  ) {
    if (data.tremor) {
      this.url += data.tremor
    } else {
      this.url += 'LOCKTON_1'
    }
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }
}
