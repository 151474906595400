import { Component, Input, ElementRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CREDIT_TIER_DIALOG,
  CREDIT_TIER_DIALOG_HEIGHT,
  CREDIT_TIER_DIALOG_WIDTH,
  CreditTierStructuresDialogConfig,
  CreditTierStructuresDialogRef,
} from '../credit-tier.model'
import { CreditTierStructuresDialogComponent } from '../credit-tier-structures-dialog/credit-tier-structures-dialog.component'

@Component({
  selector: 'app-credit-tier-structures-menu',
  templateUrl: './credit-tier-structures-menu.component.html',
  styleUrls: ['./credit-tier-structures-menu.component.scss'],
})
export class CreditTierStructuresMenuComponent {
  @Input() disabled = false

  constructor(
    private dialog: MatDialog,
    private elementRef: ElementRef
  ) {}

  onOverlayClick(): void {
    if (this.disabled) {
      return
    }
    this.openDialog()
  }

  private openDialog(): void {
    let ref = this.getDialogRef()
    if (ref) {
      return
    }
    const data: CreditTierStructuresDialogConfig = {
      triggerRef: this.elementRef,
      panelClass: CREDIT_TIER_DIALOG,
      ignoreAllExternalClicks: true,
    }
    this.dialog.open(CreditTierStructuresDialogComponent, {
      id: CREDIT_TIER_DIALOG,
      panelClass: CREDIT_TIER_DIALOG,
      height: CREDIT_TIER_DIALOG_HEIGHT,
      width: CREDIT_TIER_DIALOG_WIDTH,
      data,
    })
  }

  private getDialogRef(): CreditTierStructuresDialogRef | undefined {
    return this.dialog.getDialogById(CREDIT_TIER_DIALOG)
  }
}
