import { KeyValue } from '@angular/common'
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-saved-curve-tooltip',
  styleUrls: ['pricing-curve-saved-curve-tooltip.component.scss'],
  templateUrl: 'pricing-curve-saved-curve-tooltip.component.html',
})
export class PricingCurveSavedCurveTooltipComponent implements OnInit {
  @Input() visible = false
  @Input() savedCurve: SavedPricingCurveEntry
  @Input() mouseEvent: MouseEvent
  @Input() parentWidth = 400

  mouseHeight = 0
  leftPadding = 25

  displayValues: Map<string, string> = new Map()

  // Comparer function to preserve displayValues order in keyvalue pipe
  originalOrder = (
    _a: KeyValue<string, string>,
    _b: KeyValue<string, string>
  ): number => {
    return 0
  }

  ngOnInit(): void {
    this.parentWidth = Number(this.parentWidth) + this.mouseEvent.clientX
    this.mouseHeight = this.mouseEvent.clientY
    this.displayValues.set('Curve Name', this.savedCurve.pc_name)
    const techFactors = this.savedCurve.techFactors
    if (techFactors) {
      this.displayValues.set(
        'Expected Loss Multiplier',
        `${techFactors.expected_loss_multiplier}`
      )
      this.displayValues.set(
        'Expected Loss Power',
        `${techFactors.expected_loss_power}`
      )
      this.displayValues.set('Volatility Metric', techFactors.volatility_metric)
      this.displayValues.set(
        'Fixed Cost',
        `${(techFactors.fixed_cost * 100).toFixed(2)}%`
      )
      this.displayValues.set(
        'Minimum Rate on Line',
        `${(techFactors.minimum_rate_on_line * 100).toFixed(2)}%`
      )
      this.displayValues.set(
        'Maximum Rate on Line',
        `${(techFactors.maximum_rate_on_line * 100).toFixed(2)}%`
      )
      this.displayValues.set(
        'Reinsurer Margin',
        `${(techFactors.reinsurer_margin_percentage * 100).toFixed(2)}%`
      )
      this.displayValues.set(
        'Max Ceding Commission',
        `${(techFactors.max_ceding_commission_percentage * 100).toFixed(2)}%`
      )
    }

    if (this.savedCurve.fullName) {
      this.displayValues.set('Last Updated By', this.savedCurve.fullName)
    }
    if (this.savedCurve.audit_update_dt) {
      this.displayValues.set(
        'Last Updated Date',
        `${new Date(this.savedCurve.audit_update_dt).toDateString()}`
      )
    }
  }
}
