<app-quote-header
  [isExpanded]="isExpanded"
  [showSubjectivity]="showSubjectivity"
  [showAssignedLines]="showAssignedLines"
  [showTrackingModule]="showTrackingModule"
  [showQuickQuote]="showQuickQuote"
  [selectedCededLayerID]="selectedCededLayerID"
  [showToggle]="showToggle"
  [isGroupSelected]="isGroupSelected"
  [isSLSelected]="isSLSelected"
  [renewedFromRisks]="renewedFromRisks"
  [doesCurrentLayerHaveExpiring]="doesCurrentLayerHaveExpiring"
  [isLoading]="isLoading"
  [accountOpportunities]="accountOpportunities"
  [currentProgramId]="currentProgramId"
  [programs]="programs"
  [reinsurers]="reinsurers"
  [showCustomCompareBuilder]="showCustomCompareBuilder"
  [showCustomCompare]="showCustomCompare"
  [expandedReinsurer]="expandedReinsurer"
  [compareView]="compareView"
  (expandClick)="expandClick.emit($event)"
  (backClick)="backClick()"
  (trackingClick)="trackingClick()"
  (exportClick)="onExportClick()"
  (exportCustomCompareClick)="onExportCustomCompareClicked()"
  (saveSubjectivityClick)="onSaveSubjectivityClick()"
  (saveAssignedLinesClick)="onSaveAssignedLinesClick()"
  (toggleClick)="onShowToggleClick()"
  (showAssignedLinesClick)="onShowAssignedLinesClick()"
  (showQuickQuoteClick)="onShowQuickQuoteClick()"
  (saveQuickQuoteClick)="onSaveQuickQuoteClick()"
  (backAssignedSubjectivitiesClick)="onBackAssignedSubjectivitiesClick()"
  (backQuickQuoteClick)="onBackQuickQuoteClick()"
  (fetchQuoteFieldsForSelectedRiskAndSection)="
        fetchQuoteFieldsForSelectedRiskAndSection.emit($event)
      "
  (riskCodeClick)="riskCodeClick.emit($event)"
  (showCustomCompareClick)="onShowCustomCompareClick()"
  (saveCustomCompareClick)="saveCustomComparison.emit()"
  (viewCustomCompareClick)="onViewCustomCompareClick()"
></app-quote-header>

<app-quote-content-container
  [clientSelect]="clientSelect"
  [selectedClientID]="selectedClientID"
  [showSubjectivity]="showSubjectivity"
  [showAssignedLines]="showAssignedLines"
  [showTrackingModule]="showTrackingModule"
  [exportClicked]="exportClicked"
  [exportCustomCompareClicked]="exportCustomCompareClicked"
  [saveSubClicked]="saveSubClicked"
  [saveAssignedLinesClicked]="saveAssignedLinesClicked"
  [showToggle]="showToggle"
  [showQuickQuote]="showQuickQuote"
  [saveQuickQuoteClicked]="saveQuickQuoteClicked"
  [lossSetLayers]="lossSetLayers"
  [showCustomCompareBuilder]="showCustomCompareBuilder"
  [showCustomCompare]="showCustomCompare"
  [currentCompareView]="compareView"
  [isGroupSelected]="isGroupSelected"
  [isSLSelected]="isSLSelected"
  (exportDone)="onExportDone()"
  (saveSubjectivityDone)="onSaveSubjectivityDone()"
  (saveQuickQuoteDone)="onSaveQuickQuoteDone()"
  (showSubjectivityClick)="onShowSubjectivityClick()"
  (expandedReinsurer)="setExpandedReinsurer($event)"
  (showAssignedLinesClick)="onShowAssignedLinesClick()"
  (saveAssignedLinesDone)="onSaveAssignedLinesDone()"
  (backClick)="backClick()"
  (resetDisplayOnLayerGroupSLChange)="onResetDisplayOnLayerGroupSLChange()"
></app-quote-content-container>
