import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { addColumnDefValues } from '../utils/quote-details.util'

export interface QuoteDetailsData {
  rows: QuoteDetailsDatum[]
  count: number
}

export interface QuoteDetailsDatum {
  id: number
  layerType: string
  layerName: string
  clientName: string
  programName: string
  reinsurer: string
  fotQuoteIndicator: string
  expiringIndicator: string
  quoteVersionName: string
  fotVersionName: string
  expiringVersionName: string
  rateBasisType: string
  expiringRateBasis: number
  quoteRateBasis: number
  fotRateBasis: number
  expiringRate: number
  quoteRate: number
  fotRate: number
  expiringExpCedLossByDepositPremium: number
  quoteExpCedLossByDepositPremium: number
  fotExpCedLossByDepositPremium: number
  expiringExpCedLossByExpCedPremium: number
  quoteExpCedLossByExpCedPremium: number
  fotExpCedLossByExpCedPremium: number
  expiringCapacity: number
  quoteCapacity: number
  fotCapacity: number
  quotedVsExpiringRate: number
  quotedVsExpiringCapacity: number
  fotVsExpiringRate: number
  fotVsExpiringCapacity: number
  fotVsQuotedRate: number
  fotVsQuotedCapacity: number
  reinstatements: string
  totalBoundLimitVsTotalQuotedLimit: number
  programId: number
  riskSectionId: number
  marketTpRef: string
  occurrenceAttachment: number
  occurrenceLimit: number
  aggregateLimit: number
  aggregateAttachment: number
  inceptionDate: Date
  businessUnit: string
  lineOfBusiness: string
  primaryClassOfBusiness: string
  territory: string
  perils: string
  lastRefreshDate: string
  layerCurrency: string
  fotCapacityWritten: number
  ultimateSupporter: string
  quoteVsFotRate: number
  quoteVsFotCapacity: number
  quoteChangeYoyInRate: number
  fotChangeYoyInRate: number
}

const enum QuoteDetailsCategory {
  RATE = 'Rate',
  EXP_CED_LOSS_DEPOSIT_PREMIUM = 'Expected Ceded Loss / Deposit Premium',
  EXP_CED_LOSS_EXP_CED_PREMIUM = 'Expected Ceded Loss Ratio',
  CAPACITY = 'Capacity',
  EXP_CEDED_LOSS_RATIO = "Expected Ceded Loss Ratio",
  CHANGE_YOY_IN_RATE = "Change YoY in rate"
}

export const DEFAULT_QUOTE_DETAILS_COLUMNS: SortTableColumnDef[] = [
  {
    label: 'Layer Type',
    id: 'layerType',
    pinnable: true,
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Layer Name',
    id: 'layerName',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Client Name',
    id: 'clientName',
    ...addColumnDefValues('text', '20rem', true),
  },
  {
    label: 'Program Name',
    id: 'programName',
    ...addColumnDefValues('text', '20rem', true),
  },
  {
    label: 'Reinsurer',
    id: 'reinsurer',
    ...addColumnDefValues('text', '20rem', true),
  },
  {
    label: 'Indicator',
    id: 'fotQuoteIndicator',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Expiring Indicator',
    id: 'expiringIndicator',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Quote Version Name',
    id: 'quoteVersionName',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'FOT Version Name',
    id: 'fotVersionName',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Expiring Version Name',
    id: 'expiringVersionName',
    ...addColumnDefValues('text', '12.5rem', true),
  },
  {
    label: 'Rate Basis',
    id: 'rateBasisType',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Expiring Rate Basis',
    id: 'expiringRateBasis',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Quote Rate Basis',
    id: 'quoteRateBasis',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'FOT Rate Basis',
    id: 'fotRateBasis',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Expiring',
    id: 'expiringRate',
    category: QuoteDetailsCategory.RATE,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Quote',
    id: 'quoteRate',
    category: QuoteDetailsCategory.RATE,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'FOT',
    id: 'fotRate',
    category: QuoteDetailsCategory.RATE,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Expiring',
    id: 'expiringExpCedLossByDepositPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_DEPOSIT_PREMIUM,
    categoryMinWidth: '20rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Quote',
    id: 'quoteExpCedLossByDepositPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_DEPOSIT_PREMIUM,
    categoryMinWidth: '20rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'FOT',
    id: 'fotExpCedLossByDepositPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_DEPOSIT_PREMIUM,
    categoryMinWidth: '20rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Expiring',
    id: 'expiringExpCedLossByExpCedPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_EXP_CED_PREMIUM,
    categoryMinWidth: '22.5rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Quote',
    id: 'quoteExpCedLossByExpCedPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_EXP_CED_PREMIUM,
    categoryMinWidth: '22.5rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'FOT',
    id: 'fotExpCedLossByExpCedPremium',
    category: QuoteDetailsCategory.EXP_CED_LOSS_EXP_CED_PREMIUM,
    categoryMinWidth: '22.5rem',
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '2rem', false),
  },
  {
    label: 'Expiring (Offered)',
    id: 'expiringCapacity',
    category: QuoteDetailsCategory.CAPACITY,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '10rem', false),
  },
  {
    label: 'Quote (Offered)',
    id: 'quoteCapacity',
    category: QuoteDetailsCategory.CAPACITY,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '6rem', false),
  },
  {
    label: 'FOT (Signed)',
    id: 'fotCapacity',
    category: QuoteDetailsCategory.CAPACITY,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '7rem', false),
  },
  {
    label: 'FOT(Written)',
    id: 'fotCapacityWritten',
    category: QuoteDetailsCategory.CAPACITY,
    ...addColumnDefValues('percentage', '7rem', true),
  },
  {
    label: 'Quoted vs Expiring Rate',
    id: 'quotedVsExpiringRate',
    ...addColumnDefValues('percentage', '11rem', true),
  },
  {
    label: 'Quoted vs Expiring Capacity',
    id: 'quotedVsExpiringCapacity',
    ...addColumnDefValues('percentage', '12.5rem', true),
  },
  {
    label: 'FOT vs Expiring Rate',
    id: 'fotVsExpiringRate',
    ...addColumnDefValues('percentage', '9.5rem', true),
  },
  {
    label: 'FOT vs Expiring Capacity',
    id: 'fotVsExpiringCapacity',
    ...addColumnDefValues('percentage', '11rem', true),
  },
  {
    label: 'FOT vs Quoted Rate',
    id: 'fotVsQuotedRate',
    ...addColumnDefValues('percentage', '9rem', true),
  },
  {
    label: 'FOT vs Quoted Capacity',
    id: 'fotVsQuotedCapacity',
    ...addColumnDefValues('percentage', '11rem', true),
  },
  {
    label: 'Reinstatements',
    id: 'reinstatements',
    ...addColumnDefValues('text', '12.5rem', true),
  },
  {
    label: 'Occ Limit',
    id: 'occurrenceLimit',
    ...addColumnDefValues('short-numeric', '10rem', true),
  },
  {
    label: 'Occ Att',
    id: 'occurrenceAttachment',
    ...addColumnDefValues('short-numeric', '10rem', true),
  },
  {
    label: 'Agg Limit',
    id: 'aggregateLimit',
    ...addColumnDefValues('short-numeric', '10rem', true),
  },
  {
    label: 'Agg Att',
    id: 'aggregateAttachment',
    ...addColumnDefValues('short-numeric', '10rem', true),
  },
  {
    label: 'Bound Limit vs Quoted Limit',
    id: 'totalBoundLimitVsTotalQuotedLimit',
    ...addColumnDefValues('short-numeric', '12.5rem', true),
  },
  {
    label: 'Policy Effective Date',
    id: 'inceptionDate',
    ...addColumnDefValues('text', '10rem', true),
    valueType: 'date'
  },
  {
    label: 'Business Unit',
    id: 'businessUnit',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Line of Business',
    id: 'lineOfBusiness',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Primary Class of Business',
    id: 'primaryClassOfBusiness',
    ...addColumnDefValues('text', '12.5rem', true),
  },
  {
    label: 'Territory',
    id: 'territory',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Perils',
    id: 'perils',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Ultimate Supporter',
    id: 'ultimateSupporter',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    label: 'Quote vs FOT Rate',
    id: 'quoteVsFotRate',
    ...addColumnDefValues('percentage', '8rem', true),
  },
  {
    label: 'Quote vs FOT Capacity',
    id: 'quoteVsFotCapacity',
    ...addColumnDefValues('percentage', '12rem', true),
  },
  {
    label: 'Quote',
    id: 'quoteChangeYoyInRate',
    category: QuoteDetailsCategory.CHANGE_YOY_IN_RATE,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '4rem', false),
  },
  {
   label: 'FOT',
    id: 'fotChangeYoyInRate',
     category: QuoteDetailsCategory.CHANGE_YOY_IN_RATE,
    pinnableCategory: true,
    ...addColumnDefValues('percentage', '4rem', false),
  },
]
