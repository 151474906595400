import { LossSetTableState } from './explore.reducer'

export interface ResponseGraph {
  x_axis: number[]
  LossData: LossItem[]
}
export interface LossItem {
  label: string
  value: string[]
}
export interface ItemSelect {
  label: string
  value: string
}

export type LossSetToAdd = {
  lossID: string
  lossName: string
  filterValue: string
  isLossRatioView?: boolean
  subjectPremiumAmt?: number
}

export const colors = [
  '#e41a1c',
  '#377eb8',
  '#4daf4a',
  '#984ea3',
  '#ff7f00',
  '#ffff33',
  '#a65628',
  '#f781bf',
  '#999999',
]
export interface GrossLossTableExportXlsx {
  lossSetDataTable: LossSetTableState[]
  aggregationMethodType: string
  vartvarType: string
  perspectiveType: string
  occurrenceRows: any
  aggregateRows: any
  excelLossRatioRows: any
  grossOccurrenceResultsOtherValues: any
  grossAggregateResultsOtherValues: any
  grossRatiosOtherValues: any
  view: string
}
