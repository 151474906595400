import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { LossFilter } from '../api/analyzere/analyzere.model'
import { MatMenuTrigger } from '@angular/material/menu'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-loss-filter',
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group {
        width: 100%;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group:not(:last-of-type) {
        margin-bottom: var(--stack-tiny);
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle {
        width: 50%;
      }

      .loss-filter {
        height: 1.3rem;
        max-width: 160px;
        margin-top: 5px;
        margin-left: auto;
      }

      .loss-filters-button {
        width: 100%;
        line-height: normal;
        font-size: small;
        padding-left: 2rem;
      }

      .loss-filters-button-reset {
        width: 100%;
        line-height: normal;
        font-size: small;
        color: var(--primary) !important;
      }

      .remove-filter-button {
        font-size: large;
        padding-top: 0.1rem;
        cursor: pointer;
      }

      .selected {
        background-color: var(--primary);
        color: var(--body-inverse);
      }

      .reset-selected {
        color: var(--primary);
      }
    `,
  ],
  template: `
    <mat-button-toggle-group
      class="app-mat-theme app-button-toggle-group-small loss-filter"
      name="filters"
      aria-label="Loss Filters"
      matTooltip="Set perspective to Loss to select Loss Filter"
      matTooltipShowDelay="500"
      [ngClass]="resetLossFilter ? 'reset-selected' : 'selected'"
    >
      <button
        [ngClass]="
          resetLossFilter ? 'loss-filters-button-reset' : 'loss-filters-button'
        "
        mat-button
        [matMenuTriggerFor]="lossFiltersMenu"
        #lossFilterMenuTrigger="matMenuTrigger"
      >
        {{ lossFilterButtonLabel }}
      </button>
      <i
        *ngIf="!resetLossFilter"
        class="material-icons remove-filter-button"
        (click)="onRemoveFilterClick()"
      >
        cancel
      </i>
      <mat-menu #lossFiltersMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let f of lossFilters"
          (click)="
            $event.stopPropagation();
            $event.preventDefault();
            onLossFilterClick($event.target.innerText)
          "
        >
          {{ f.description }}
        </button>
      </mat-menu>
    </mat-button-toggle-group>
  `,
})
export class LossFilterComponent {
  @Input() lossFilters: LossFilter[]
  @Input() lossID: string
  @Input() lossName: string
  @Input() lossFilter: string
  @Input() lossType: string

  @Output() lossFilterClick = new EventEmitter<{
    lossID?: string
    lossName?: string
    filterValue?: string
    lossType?: string
  }>()

  @ViewChild('lossFilterMenuTrigger') lossSetFilterMenuTrigger: MatMenuTrigger

  get lossFilterButtonLabel() {
    const description = this.lossFilters.find(f => f.name === this.lossFilter)
      ?.description as string
    return this.lossFilter === 'all' ? 'Loss Filters' : description
  }

  get resetLossFilter(): boolean {
    return this.lossFilter === 'all'
  }

  onLossFilterClick(description: string) {
    const name = this.lossFilters.find(f => f.description === description)?.name
    this.lossFilterClick.emit({
      lossID: this.lossID,
      lossName: this.lossName,
      filterValue: name,
      lossType: this.lossType,
    })
    this.lossSetFilterMenuTrigger.closeMenu()
  }

  onRemoveFilterClick(): void {
    this.lossFilterClick.emit({
      lossID: this.lossID,
      lossName: this.lossName,
      filterValue: 'all',
      lossType: this.lossType,
    })
  }
}
