import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-loss-set-contribution-error-dialog',
  templateUrl: './loss-set-contribution-error-dialog.component.html',
  styleUrls: ['./loss-set-contribution-error-dialog.component.scss']
})
export class LossSetContributionErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LossSetContributionErrorDialogComponent>,
  ) {
   }
   onClose() {
    this.dialogRef.close({})
  }
}
