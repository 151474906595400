import { AppState } from '.'
import * as fromLossSetGroupMember from './loss-set-group-member.reducer'

export const selectLossSetGroupMemberState = (
  state: AppState
): fromLossSetGroupMember.State => state.lossSetGroupMember

export const {
  selectAll: selectLossSetGroupMembers,
  selectEntities: selectLossSetGroupMembersByID,
} = fromLossSetGroupMember.adapter.getSelectors(selectLossSetGroupMemberState)
