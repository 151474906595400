import { Action, createReducer, on } from '@ngrx/store'
import {
  fetchPortfolio,
  fetchPortfolioFailure,
  fetchPortfolioSuccess,
} from './portfolio.actions'

export interface State {
  error: string | null
  loading: boolean
  id: string | null
  name: string | null
  description: string | null
  client: string | null
  programName: string | null
  year: string | null
  perspective: string | null
}

export const initialState: State = {
  error: null,
  loading: false,
  id: null,
  name: null,
  description: null,
  client: null,
  programName: null,
  year: null,
  perspective: null,
}

const portfolioReducer = createReducer(
  initialState,
  on(fetchPortfolio, _ => ({
    ...initialState,
    error: null,
    loading: true,
  })),

  on(
    fetchPortfolioSuccess,
    (
      state,
      { id, name, description, client, programName, year, perspective }
    ) => ({
      ...state,
      error: null,
      loading: false,
      id,
      name,
      description,
      client,
      programName,
      year,
      perspective,
    })
  ),

  on(fetchPortfolioFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return portfolioReducer(state, action)
}
