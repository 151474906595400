import { Injectable } from '@angular/core'
import { AnalyzreService } from '../analyzere/analyzre.service'
import { AppState } from 'src/app/core/store'
import { Store, select } from '@ngrx/store'
import {
  SourceAndTargetStructureAndGroupReferences,
  InuranceMetadataRef,
  InuranceRelationship,
  InuranceReference,
  MemberData,
  InuranceValidation,
  SourceAndTargetStructureGroupReferences,
} from 'src/app/analysis/model/inurance.model'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import { Dictionary } from '@ngrx/entity'
import {
  ProgramGroup,
  ProgramGroupMember,
} from 'src/app/analysis/store/grouper/program-group.model'
import { Program } from 'src/app/core/model/program.model'
import { Observable, of, forkJoin } from 'rxjs'
import { MaybeError, MaybeData, ApiResponse } from '../model/api.model'
import {
  LogicalPortfolioLayer,
  Portfolio,
  Update,
  LossSetLayer,
  Ref,
} from '../analyzere/analyzere.model'
import {
  toInuranceRefs,
  isTargetLayer,
  fromInuranceRefs,
  isSourceLayer,
  isSourceStructure,
  isTargetStructure,
  isSourceStructureGroup,
  getInuranceSourceAndTargerReferenceByGroup,
  isTargetStructureGroup,
  canInure,
  inuranceValidationToErrorPayload,
} from 'src/app/analysis/model/inurance.util'
import {
  switchMap,
  map,
  withLatestFrom,
  concatMap,
  mergeMap,
} from 'rxjs/operators'
import { selectProgramsByID } from '../../core/store/program/program.selectors'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import { errorPayload } from 'src/app/error/model/error'
import { uniqBy, chain, partition, uniq } from 'ramda'
import {
  getSources,
  convertFromLogicalPortfolioLayers,
} from 'src/app/analysis/model/layers.converter'
import { GrouperSavedChanges } from 'src/app/analysis/store/grouper/grouper.actions'
import intersection from 'ramda/es/intersection'
import { ProgramGroupMemberEntity } from 'src/app/analysis/store/grouper/program-group-member/program-group-member.reducer'
import { isLayerBackAllocated } from 'src/app/analysis/model/layers.util'
import { executeSequentially } from '../util'
import { ProgramGroupSetState } from '../../core/store/program-group-member.selectors'
import { isSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
// tslint:disable: no-non-null-assertion
@Injectable({
  providedIn: 'root',
})
export class InuranceService {
  constructor(
    private service: AnalyzreService,
    private store: Store<AppState>
  ) {}

  private reconcileSaveAsLayerWithlayer(
    layerSourceID: string,
    layerTargetID: string
  ) {
    return this.service
      .fetchLayers<LogicalPortfolioLayer>([layerSourceID])
      .pipe(
        switchMap(response => {
          if (response.error) {
            return of({ error: response.error })
          } else {
            const layer = response.data![0]
            const inuranceSourceFor = toInuranceRefs(
              layer.meta_data.inuranceSourceFor
            )
            inuranceSourceFor.push({ type: 'layerToLayer', id: layerTargetID })
            return this.service.patchLogicalPortfolioLayer({
              id: layer.id,
              change: {
                meta_data: {
                  ...layer.meta_data,
                  inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
                },
              },
            })
          }
        })
      )
  }

  private reconcileSaveAsLayerWithStructure(
    layerSourceID: string,
    structureID: string
  ) {
    return this.service
      .fetchLayers<LogicalPortfolioLayer>([layerSourceID])
      .pipe(
        switchMap(response => {
          if (response.error) {
            return of({ error: response.error })
          } else {
            const layer = response.data![0]
            const inuranceSourceFor = toInuranceRefs(
              layer.meta_data.inuranceSourceFor
            )
            inuranceSourceFor.push({
              type: 'layerToStructure',
              id: structureID,
            })
            return this.service.patchLogicalPortfolioLayer({
              id: layer.id,
              change: {
                meta_data: {
                  ...layer.meta_data,
                  inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
                },
              },
            })
          }
        })
      )
  }
  private reconcileSaveAsPortfolioWithLayer(
    portfolioID: string,
    layerID: string,
    group?: boolean
  ) {
    return this.fetchLayersFromPortfolios([portfolioID]).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const layers = convertFromLogicalPortfolioLayers(
            response.data!
          ).filter(l => l.meta_data.inuranceSource)
          const updates: Update<LogicalPortfolioLayer>[] = layers.map(l => {
            const inuranceSourceFor = toInuranceRefs(
              l.meta_data.inuranceSourceFor
            )
            inuranceSourceFor.push({
              id: layerID,
              type: group ? 'structureGroupToLayer' : 'structureToLayer',
            })
            return {
              id: l.id,
              change: {
                meta_data: {
                  ...l.meta_data,
                  inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
                },
              },
            }
          })
          return this.service.patchLogicalPortfolioLayers(updates)
        }
      })
    )
  }

  private reconcileSaveAsPortfolioWithStructure(
    portfolioID: string,
    structureID: string,
    group?: boolean
  ) {
    return this.fetchLayersFromPortfolios([portfolioID]).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const layers = convertFromLogicalPortfolioLayers(
            response.data!
          ).filter(l => l.meta_data.inuranceSource)
          const updates: Update<LogicalPortfolioLayer>[] = layers.map(l => {
            const inuranceSourceFor = toInuranceRefs(
              l.meta_data.inuranceSourceFor
            )
            inuranceSourceFor.push({
              id: structureID,
              type: group
                ? 'structureGroupToStructure'
                : 'structureToStructure',
            })
            return {
              id: l.id,
              change: {
                meta_data: {
                  ...l.meta_data,
                  inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
                },
              },
            }
          })
          return this.service.patchLogicalPortfolioLayers(updates)
        }
      })
    )
  }

  private reconcileTargetForSaveAs(layers: Layer[]) {
    const update: Update<LogicalPortfolioLayer>[] = layers.map(l => {
      const inuranceRefs = toInuranceRefs(l.meta_data.inuranceTargetFor)
      inuranceRefs.forEach(i => {
        if (isTargetStructureGroup(i.type)) {
          i.type = (i.type.substring(0, i.type.indexOf('To') + 2) +
            'Structure') as InuranceRelationship
        }
      })
      return {
        id: l.id,
        change: {
          meta_data: {
            ...l.meta_data,
            inuranceTargetFor: fromInuranceRefs(inuranceRefs),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(update)
  }

  reconcileAnimatedLoss(
    cededLayers: LogicalPortfolioLayer[]
  ): ApiResponse<LogicalPortfolioLayer[]> {
    const updates: Array<Update<LogicalPortfolioLayer>> = []
    cededLayers.forEach(layer => {
      if (layer.meta_data.inuranceTarget) {
        const sources = layer.sources as Array<
          LogicalPortfolioLayer | LossSetLayer
        >
        const newSources: Ref[] = []
        for (const source of sources) {
          const newInuredLayer = cededLayers.find(
            l => l.meta_data.fromLayerID === source.id
          )
          if (newInuredLayer) {
            newSources.push({ ref_id: newInuredLayer.id })
          } else {
            newSources.push({ ref_id: source.id })
          }
        }
        updates.push({ id: layer.id, change: { sources: newSources } })
      }
    })
    if (updates.length > 0) {
      return this.service.patchLogicalPortfolioLayers(updates)
    } else {
      return of({})
    }
  }

  reconcileSaveAs(
    structure: Program,
    cededLayers: LogicalPortfolioLayer[],
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>
  ): Observable<MaybeError> {
    const layers = convertFromLogicalPortfolioLayers(cededLayers)
    const layersWithInurance = layers.filter(f => f.meta_data.inuranceTarget)
    const groupSources: Set<string> = new Set()
    const structureSources: Set<string> = new Set()
    const update: Observable<MaybeError>[] = []
    if (layersWithInurance.length > 0) {
      layersWithInurance.forEach(l => {
        const inuranceRefs = toInuranceRefs(l.meta_data.inuranceTargetFor)
        inuranceRefs.forEach(i => {
          // Layer Target
          if (isSourceStructure(i.type) && isTargetLayer(i.type)) {
            const s = structuresByID[i.id]
            if (s) {
              update.push(
                this.reconcileSaveAsPortfolioWithLayer(s.cededPortfolioID, l.id)
              )
            }
          } else if (isSourceStructureGroup(i.type) && isTargetLayer(i.type)) {
            const g = structureGroupsByID[i.id]
            if (g) {
              update.push(
                this.reconcileSaveAsPortfolioWithLayer(
                  g.cededPortfolioID || '',
                  l.id,
                  true
                )
              )
            }
          } else if (isSourceLayer(i.type) && isTargetLayer(i.type)) {
            update.push(this.reconcileSaveAsLayerWithlayer(i.id, l.id))
            // Structure Target
          } else if (isSourceStructure(i.type) && isTargetStructure(i.type)) {
            if (!structureSources.has(i.id)) {
              structureSources.add(i.id)
              const s = structuresByID[i.id]
              if (s) {
                update.push(
                  this.reconcileSaveAsPortfolioWithStructure(
                    s.cededPortfolioID,
                    structure.id
                  )
                )
              }
            }
          } else if (
            isSourceStructureGroup(i.type) &&
            isTargetStructure(i.type)
          ) {
            if (!groupSources.has(i.id)) {
              groupSources.add(i.id)
              const g = structureGroupsByID[i.id]
              if (g) {
                update.push(
                  this.reconcileSaveAsPortfolioWithStructure(
                    g.cededPortfolioID || '',
                    structure.id,
                    true
                  )
                )
              }
            }
          } else if (isSourceLayer(i.type) && isTargetStructure(i.type)) {
            update.push(
              this.reconcileSaveAsLayerWithStructure(i.id, structure.id)
            )
            // Structure Group Target, gets downgraded to Structure Target
          } else if (
            isSourceStructure(i.type) &&
            isTargetStructureGroup(i.type)
          ) {
            if (!structureSources.has(i.id)) {
              structureSources.add(i.id)
              const s = structuresByID[i.id]
              if (s) {
                update.push(
                  this.reconcileSaveAsPortfolioWithStructure(
                    s.cededPortfolioID,
                    structure.id
                  )
                )
              }
            }
          } else if (
            isSourceStructureGroup(i.type) &&
            isTargetStructureGroup(i.type)
          ) {
            if (!groupSources.has(i.id)) {
              groupSources.add(i.id)
              const g = structureGroupsByID[i.id]
              if (g) {
                update.push(
                  this.reconcileSaveAsPortfolioWithStructure(
                    g.cededPortfolioID || '',
                    structure.id,
                    true
                  )
                )
              }
            }
          } else if (isSourceLayer(i.type) && isTargetStructureGroup(i.type)) {
            update.push(
              this.reconcileSaveAsLayerWithStructure(i.id, structure.id)
            )
          }
        })
      })
      if (update.length > 0) {
        update.push(this.reconcileTargetForSaveAs(layersWithInurance))
        return executeSequentially(update)
      } else {
        return of({})
      }
    } else {
      return of({})
    }
  }

  reconcileOnGroupAdd(
    grouperSavedChanges: GrouperSavedChanges,
    { structuresByID, groupsByID, allMembers }: ProgramGroupSetState
  ): Observable<MaybeError> {
    const groupsCreated = grouperSavedChanges.groups.create.map(g => g.id)
    const added = grouperSavedChanges.members.create.filter(
      c => c.type === 'program' && !groupsCreated.includes(c.parentGroupID)
    )
    if (added.length > 0) {
      const reconcileActions: Observable<MaybeError>[] = []
      added.forEach(newMember => {
        const parentGroupID = newMember.parentGroupID
        const ancestors = this.getAllAncestors(allMembers, parentGroupID)
        const structureID = newMember.programID!
        const parentCededPortfolioID =
          groupsByID[parentGroupID]!.cededPortfolioID!
        const memberCededPortfolioID =
          structuresByID[structureID]!.cededPortfolioID
        reconcileActions.push(
          forkJoin([
            this.fetchLayersFromPortfolios([memberCededPortfolioID]),
            this.fetchLayersFromPortfolios([parentCededPortfolioID]),
          ]).pipe(
            concatMap(([memberResponse, parentResponse]) => {
              if (memberResponse.error || parentResponse.error) {
                return of({
                  error: memberResponse.error || parentResponse.error,
                })
              }
              const memberLayers = convertFromLogicalPortfolioLayers(
                memberResponse.data!
              ).filter(canInure)
              const parentLayers = convertFromLogicalPortfolioLayers(
                parentResponse.data!
              ).filter(canInure)
              const allAncestors = [parentGroupID, ...ancestors]
              const parentTargetLayer = parentLayers.find(
                p => p.meta_data.inuranceTarget && p.meta_data.inuranceTargetFor
              )
              const actions: Observable<MaybeError>[] = []
              if (parentTargetLayer) {
                const parentTargetFor = toInuranceRefs(
                  parentTargetLayer.meta_data.inuranceTargetFor
                ).filter(
                  r =>
                    isTargetStructureGroup(r.type) &&
                    r.groupID &&
                    allAncestors.includes(r.groupID)
                )
                const parentSources = parentTargetLayer.lossSetLayers.filter(
                  l => {
                    if (
                      l.meta_data.inuranceSource &&
                      l.meta_data.inuranceSourceFor
                    ) {
                      const inuranceSourceFor = toInuranceRefs(
                        l.meta_data.inuranceSourceFor
                      ).filter(
                        r =>
                          isTargetStructureGroup(r.type) &&
                          allAncestors.includes(r.id)
                      )
                      return inuranceSourceFor.length > 0
                    } else {
                      return false
                    }
                  }
                )
                if (parentTargetFor.length > 0 && parentSources.length > 0) {
                  actions.push(
                    this.reconcileGroupAddAsTarget(
                      parentSources,
                      parentTargetFor,
                      memberLayers,
                      structureID
                    )
                  )
                }
              }
              const parentSourceLayer = parentLayers.find(
                p => p.meta_data.inuranceSource && p.meta_data.inuranceSourceFor
              )
              if (parentSourceLayer) {
                const parentSourceFor = toInuranceRefs(
                  parentSourceLayer.meta_data.inuranceSourceFor
                ).filter(
                  r =>
                    isSourceStructureGroup(r.type) &&
                    r.groupID &&
                    allAncestors.includes(r.groupID)
                )
                if (parentSourceFor.length > 0) {
                  actions.push(
                    this.reconcileGroupAddAsSource(
                      parentSourceFor,
                      memberLayers,
                      structuresByID,
                      groupsByID,
                      structureID
                    )
                  )
                }
              }
              if (actions.length > 0) {
                return executeSequentially(actions)
              } else {
                return of({})
              }
            })
          )
        )
      })
      return executeSequentially(reconcileActions)
    } else {
      return of({})
    }
  }

  reconcileOnGroupDelete(
    grouperSavedChanges: GrouperSavedChanges,
    { structuresByID, groupsByID, allMembers }: ProgramGroupSetState
  ): Observable<MaybeError> {
    const groupsRemoved = grouperSavedChanges.groups.remove.filter(
      removeID =>
        !grouperSavedChanges.groups.create.find(c => c.label === removeID)
    )
    const removedMemberIDs = grouperSavedChanges.members.remove.filter(
      memberID => {
        return !!!grouperSavedChanges.members.idChanges.find(
          c => c.prev === memberID
        )
      }
    )
    if (groupsRemoved.length === 0 && removedMemberIDs.length === 0) {
      return of({})
    } else {
      const removedMemberDataTuple = this.getMemberData(
        removedMemberIDs,
        allMembers
      )
      const actions: Observable<MaybeError>[] = []
      if (groupsRemoved.length > 0) {
        groupsRemoved.forEach(gID => {
          const portfolioID = groupsByID[gID]!.cededPortfolioID!
          actions.push(
            this.reconcilePortfolioOnGroupDelete(portfolioID, [gID], false)
          )
        })
      }
      if (removedMemberIDs.length > 0) {
        removedMemberDataTuple[0].forEach(structureMember => {
          const portfolioID =
            structuresByID[structureMember.structureID!]!.cededPortfolioID
          actions.push(
            this.reconcilePortfolioOnGroupDelete(
              portfolioID,
              structureMember.ancestorGroupIDs,
              true
            )
          )
        })
      }
      return executeSequentially(actions)
    }
  }

  deleteInurance(
    sourceLayers: Layer[],
    targetLayers: Layer[],
    source: InuranceReference[],
    target: InuranceReference[],
    relationship: InuranceRelationship
  ): ApiResponse<LogicalPortfolioLayer[]> {
    const validationPayload = inuranceValidationToErrorPayload(
      this.validateInuranceDelete(sourceLayers, targetLayers)
    )
    if (validationPayload) {
      return of({ error: validationPayload })
    }

    let sourceIDs: InuranceMetadataRef[] = []
    let targetIDs: InuranceMetadataRef[] = []
    const sourceLayerIDs = sourceLayers.map(s => s.id)
    if (isSourceLayer(relationship)) {
      sourceIDs = sourceLayers.map(s => ({ id: s.id, type: relationship }))
    } else if (isSourceStructure(relationship)) {
      sourceIDs = source.map(s => ({ id: s.structureID, type: relationship }))
    } else {
      sourceIDs = source.map(s => ({
        id: s.structureGroupID as string,
        type: relationship,
      }))
    }
    if (isTargetLayer(relationship)) {
      targetIDs = targetLayers.map(s => ({ id: s.id, type: relationship }))
    } else if (isTargetStructure(relationship)) {
      targetIDs = target.map(s => ({ id: s.structureID, type: relationship }))
    } else {
      targetIDs = target.map(s => ({
        id: s.structureGroupID as string,
        type: relationship,
      }))
    }

    const sourceUpdateRequest: Partial<LogicalPortfolioLayer>[] =
      sourceLayers.map(layer => {
        let inuranceSourceFor = layer.meta_data.inuranceSourceFor
        let inuranceSource = true
        if (inuranceSourceFor) {
          const inuranceSourceRefs = toInuranceRefs(inuranceSourceFor).filter(
            r => !targetIDs.find(t => t.id === r.id && t.type === r.type)
          )
          inuranceSource = inuranceSourceRefs.length > 0
          inuranceSourceFor = fromInuranceRefs(inuranceSourceRefs)
        } else {
          inuranceSource = false
        }
        return {
          id: layer.id,
          meta_data: { ...layer.meta_data, inuranceSource, inuranceSourceFor },
        }
      })
    const targetUpdateRequest: Partial<LogicalPortfolioLayer>[] =
      targetLayers.map(layer => {
        let inuranceTargetFor = layer.meta_data.inuranceTargetFor
        let inuranceTarget = true
        if (inuranceTargetFor) {
          const inuranceTargetRefs = toInuranceRefs(inuranceTargetFor).filter(
            r => !sourceIDs.find(s => s.id === r.id && s.type === r.type)
          )
          inuranceTarget = inuranceTargetRefs.length > 0
          inuranceTargetFor = fromInuranceRefs(inuranceTargetRefs)
        } else {
          inuranceTarget = false
        }
        return {
          id: layer.id,
          sources: uniqBy(
            l => l.ref_id,
            getSources(layer).filter(l => !sourceLayerIDs.includes(l.ref_id))
          ),
          meta_data: { ...layer.meta_data, inuranceTarget, inuranceTargetFor },
        }
      })
    const updates = [...sourceUpdateRequest, ...targetUpdateRequest].map(
      request => ({ id: request.id!, change: request })
    )
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  validateInurance(sources: Layer[], targets: Layer[]): InuranceValidation {
    const validation: InuranceValidation = {
      duplicateReference: false,
      selfReference: false,
      circularReference: false,
      targetHasSharedLimit: false,
      hasSharedLimitDelete: false,
      isILW: false,
      noLayerFoundReference: false,
    }
    const targetIDs = targets.map(t => t.id)
    const sourceIDs = sources.map(t => t.id)
    if (!(sources.length > 0) || !(targets.length > 0)) {
      validation.noLayerFoundReference = true
    } else {
      for (const layer of sources) {
        if (
          layer.meta_data.sage_layer_type &&
          ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
            layer.meta_data.sage_layer_type
          )
        ) {
          validation.isILW = true
          break
        }
        if (layer.lossSetLayers.find(l => targetIDs.includes(l.id))) {
          validation.circularReference = true
          break
        }
      }
      for (const layer of targets) {
        if (
          layer.meta_data.sage_layer_type &&
          ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
            layer.meta_data.sage_layer_type
          )
        ) {
          validation.isILW = true
          break
        }
        if (layer.lossSetLayers.find(l => sourceIDs.includes(l.id))) {
          validation.duplicateReference = true
          break
        }
        if (layer.meta_data.sage_layer_type === 'shared_limit') {
          validation.targetHasSharedLimit = true
          break
        }
      }
      if (intersection(sourceIDs, targetIDs).length > 0) {
        validation.selfReference = true
      }
    }
    return validation
  }

  validateInuranceDelete(
    sources: Layer[],
    targets: Layer[]
  ): InuranceValidation {
    const validation: InuranceValidation = {
      duplicateReference: false,
      selfReference: false,
      circularReference: false,
      targetHasSharedLimit: false,
      hasSharedLimitDelete: false,
      isILW: false,
      noLayerFoundReference: false,
    }
    if (!(sources.length > 0) || !(targets.length > 0)) {
      validation.noLayerFoundReference = true
    } else {
      for (const layer of sources) {
        if (
          layer.meta_data.sage_layer_type &&
          ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
            layer.meta_data.sage_layer_type
          )
        ) {
          validation.isILW = true
          break
        }
        if (layer.sharedLayerID) {
          validation.hasSharedLimitDelete = true
          break
        }
      }
      for (const layer of targets) {
        if (
          layer.meta_data.sage_layer_type &&
          ['cat_ilw_pro_rata', 'cat_ilw_bin'].includes(
            layer.meta_data.sage_layer_type
          )
        ) {
          validation.isILW = true
          break
        }
        if (layer.sharedLayerID) {
          validation.hasSharedLimitDelete = true
          break
        }
      }
    }
    return validation
  }

  validateOnGroupSave(
    members: ProgramGroupMemberEntity[],
    { structuresByID, groupsByID, allMembers }: ProgramGroupSetState
  ): Observable<MaybeError> {
    const newMembers = members.filter(
      m => m.new && !m.deleted && m.programGroupMember.type === 'program'
    )
    if (newMembers.length > 0) {
      const actions: Observable<MaybeError>[] = []
      newMembers.forEach(m => {
        const programID = m.programGroupMember.programID as string
        const parentID = m.programGroupMember.parentGroupID as string
        if (groupsByID[parentID] && groupsByID[parentID]!.cededPortfolioID) {
          const parentPortfolioID = groupsByID[parentID]!.cededPortfolioID!
          const structurePortfolioID =
            structuresByID[programID]!.cededPortfolioID
          actions.push(
            forkJoin([
              this.fetchLayersFromPortfolios([structurePortfolioID]),
              this.fetchLayersFromPortfolios([parentPortfolioID]),
            ]).pipe(
              mergeMap(([memberLayersResponse, parentLayersResponse]) => {
                if (memberLayersResponse.error || parentLayersResponse.error) {
                  return of({
                    error:
                      memberLayersResponse.error || parentLayersResponse.error,
                  })
                } else {
                  const memberLayers = convertFromLogicalPortfolioLayers(
                    memberLayersResponse.data!
                  )
                  const parentLayers = convertFromLogicalPortfolioLayers(
                    parentLayersResponse.data!
                  )
                  const allGroupIDs = [
                    parentID,
                    ...this.getAllAncestors(allMembers, parentID),
                  ]
                  const groupRelationships =
                    getInuranceSourceAndTargerReferenceByGroup(
                      parentLayers,
                      allGroupIDs
                    )
                  return this.validate(
                    groupRelationships,
                    memberLayers,
                    structuresByID,
                    groupsByID
                  )
                }
              })
            )
          )
        } else {
          // new group, so there is no validation
          actions.push(of({}))
        }
      })
      return forkJoin(actions).pipe(
        map(responses => {
          return this.uniqInuranceValidationErrors(responses)
        })
      )
    } else {
      return of({})
    }
  }

  reconcileWithSharedLimitDelete(
    nestedLayerIDs: string[]
  ): Observable<MaybeError & MaybeData<LogicalPortfolioLayer[]>> {
    return this.service.fetchLayers<LogicalPortfolioLayer>(nestedLayerIDs).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        }
        const nestedLayers = response.data!
        const layerIDs = nestedLayers.map(
          n => n.meta_data.backAllocatedForID as string
        )
        return this.service
          .fetchLayers<LogicalPortfolioLayer>(layerIDs)
          .pipe(map(r => ({ ...r, nestedLayers })))
      }),
      withLatestFrom(
        this.store.pipe(select(selectProgramsByID)),
        this.store.pipe(select(selectProgramGroupsByID))
      ),
      switchMap(
        ([response, structuresByID, structureGroupsByID]: [
          MaybeError &
            MaybeData<LogicalPortfolioLayer[]> & {
              nestedLayers: LogicalPortfolioLayer[]
            },
          Dictionary<Program>,
          Dictionary<ProgramGroup>
        ]) => {
          if (response.error) {
            return of({ error: response.error })
          }
          return this.reconcileWithDeletedNestedLayers(
            response.nestedLayers,
            response.data!,
            structuresByID,
            structureGroupsByID
          )
        }
      )
    )
  }

  private reconcileWithDeletedNestedLayers(
    nestedLayers: LogicalPortfolioLayer[],
    layers: LogicalPortfolioLayer[],
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>
  ): Observable<MaybeError & MaybeData<LogicalPortfolioLayer[]>> {
    const actions: Observable<
      MaybeError &
        (MaybeData<LogicalPortfolioLayer[]> | MaybeData<LogicalPortfolioLayer>)
    >[] = []
    nestedLayers.forEach(nestedLayer => {
      if (
        nestedLayer.meta_data.inuranceSource ||
        nestedLayer.meta_data.inuranceTarget
      ) {
        let layer = layers.find(
          l => l.id === (nestedLayer.meta_data.backAllocatedForID as string)
        )!
        if (nestedLayer.meta_data.inuranceTarget) {
          const inuranceTargetFor = toInuranceRefs(
            nestedLayer.meta_data.inuranceTargetFor
          )
          let inuranceSources: Ref[] = []
          inuranceSources.push(
            ...(nestedLayer.sources as LogicalPortfolioLayer[])
              .filter(l => l.meta_data.inuranceSource)
              .map(l => ({ ref_id: l.id }))
          )

          inuranceSources = inuranceSources.map(i => {
            const nestedL = nestedLayers.find(n => n.id === i.ref_id)
            if (nestedL) {
              const newLayer = layers.find(
                l => l.id === (nestedL.meta_data.backAllocatedForID as string)
              )!
              const targetFor = inuranceTargetFor.find(t => t.id === i.ref_id)
              if (targetFor) {
                targetFor.id = newLayer.id
              }
              return { ref_id: newLayer.id }
            } else {
              return i
            }
          })
          const newSources: Ref[] = (
            layer.sources as LogicalPortfolioLayer[]
          ).map(s => ({ ref_id: s.id }))
          newSources.push(...inuranceSources)
          const inuranceSource =
            nestedLayer.meta_data.inuranceSource ||
            layer.meta_data.inuranceSource
          const inuranceSourceFor =
            nestedLayer.meta_data.inuranceSourceFor ||
            layer.meta_data.inuranceSourceFor
          layer = {
            ...layer,
            sources: uniqBy(x => x.ref_id, newSources),
            meta_data: {
              ...layer.meta_data,
              inuranceSource,
              inuranceSourceFor,
              inuranceTarget: inuranceTargetFor.length > 0,
              inuranceTargetFor: JSON.stringify(inuranceTargetFor),
              tempSources: '',
              tempInuranceSource: false,
              tempInuranceTarget: false,
            },
          }
          actions.push(
            this.service.patchLogicalPortfolioLayer({
              id: layer.id,
              change: {
                sources: layer.sources,
                meta_data: layer.meta_data,
              },
            })
          )
          const filteredTargetForRefs = inuranceTargetFor.filter(t =>
            isTargetLayer(t.type)
          )
          if (filteredTargetForRefs.length > 0) {
            const sourceLayersIDs = filteredTargetForRefs
              .filter(f => isSourceLayer(f.type))
              .map(f => f.id)
            if (sourceLayersIDs.length > 0) {
              actions.push(
                this.service
                  .fetchLayers<LogicalPortfolioLayer>(sourceLayersIDs)
                  .pipe(
                    switchMap(response => {
                      if (response.error) {
                        return of({ error: response.error })
                      } else {
                        return this.reconcileSourceLayersWithNestedLayer(
                          response.data!,
                          nestedLayer,
                          layer
                        )
                      }
                    })
                  )
              )
            }
            const portfolios: string[] = []
            const sourceStructuresIDs = filteredTargetForRefs
              .filter(f => isSourceStructure(f.type))
              .map(f => f.id)
            const sourceStructureGroupIDs = filteredTargetForRefs
              .filter(f => isSourceStructureGroup(f.type))
              .map(f => f.id)
            portfolios.push(
              ...sourceStructuresIDs.map(
                structureID => structuresByID[structureID]!.cededPortfolioID
              )
            )
            portfolios.push(
              ...sourceStructureGroupIDs.map(
                structureGroupID =>
                  structureGroupsByID[structureGroupID]!.cededPortfolioID!
              )
            )
            if (portfolios.length > 0) {
              actions.push(
                this.fetchLayersFromPortfolios(portfolios).pipe(
                  switchMap(response => {
                    if (response.error) {
                      return of({ error: response.error }) as ApiResponse<
                        LogicalPortfolioLayer[]
                      >
                    } else {
                      const layerIDs = convertFromLogicalPortfolioLayers(
                        response.data!
                      )
                        .filter(canInure)
                        .map(l => l.id)
                        .map(
                          this.mapWithDeletedNestedLayer(nestedLayers, layers)
                        )
                      return this.service.fetchLayers<LogicalPortfolioLayer>(
                        layerIDs
                      )
                    }
                  }),
                  switchMap(response => {
                    if (response.error) {
                      return of({ error: response.error })
                    } else {
                      return this.reconcileSourceLayersWithNestedLayer(
                        response.data!,
                        nestedLayer,
                        layer
                      )
                    }
                  })
                )
              )
            }
          }
        }
        if (nestedLayer.meta_data.inuranceSource) {
          let sourceRefs = toInuranceRefs(
            nestedLayer.meta_data.inuranceSourceFor
          )
          sourceRefs = sourceRefs.map(ref => {
            const nestedL = nestedLayers.find(
              n => ref.id === n.id && isTargetLayer(ref.type)
            )
            if (nestedL) {
              const newLayer = layers.find(
                l => l.id === nestedL.meta_data.backAllocatedForID
              )!
              return { ...ref, id: newLayer.id }
            } else {
              return ref
            }
          })
          const inuranceTarget = nestedLayer.meta_data.inuranceTarget
            ? layer.meta_data.inuranceTarget ||
              nestedLayer.meta_data.inuranceTarget
            : nestedLayer.meta_data.inuranceTarget ||
              layer.meta_data.inuranceTarget
          const inuranceTargetFor = nestedLayer.meta_data.inuranceTarget
            ? layer.meta_data.inuranceTargetFor ||
              nestedLayer.meta_data.inuranceTargetFor
            : nestedLayer.meta_data.inuranceTargetFor ||
              layer.meta_data.inuranceTargetFor
          layer = {
            ...layer,
            meta_data: {
              ...layer.meta_data,
              inuranceTarget,
              inuranceTargetFor,
              inuranceSourceFor: JSON.stringify(sourceRefs),
              inuranceSource: sourceRefs.length > 0,
            },
          }
          actions.push(
            this.service.patchLogicalPortfolioLayer({
              id: layer.id,
              change: {
                meta_data: layer.meta_data,
              },
            })
          )
          const targetLayersIDs = sourceRefs
            .filter(f => isTargetLayer(f.type))
            .map(f => f.id)
          if (targetLayersIDs.length > 0) {
            const newTargetLayersIDs = targetLayersIDs.map(
              this.mapWithDeletedNestedLayer(nestedLayers, layers)
            )
            actions.push(
              this.service
                .fetchLayers<LogicalPortfolioLayer>(newTargetLayersIDs)
                .pipe(
                  switchMap(response => {
                    if (response.error) {
                      return of({ error: response.error })
                    }
                    return this.reconcileTargetLayersWithNestedLayer(
                      response.data!,
                      nestedLayer,
                      layer
                    )
                  })
                )
            )
          }
          const portfolios: string[] = []
          const targetStructuresIDs = sourceRefs
            .filter(f => isTargetStructure(f.type))
            .map(f => f.id)
          const targetStructureGroupIDs = sourceRefs
            .filter(f => isTargetStructureGroup(f.type))
            .map(f => f.id)
          portfolios.push(
            ...targetStructuresIDs.map(
              structureID => structuresByID[structureID]!.cededPortfolioID
            )
          )
          portfolios.push(
            ...targetStructureGroupIDs.map(
              structureGroupID =>
                structureGroupsByID[structureGroupID]!.cededPortfolioID!
            )
          )
          if (portfolios.length > 0) {
            actions.push(
              this.fetchLayersFromPortfolios(portfolios).pipe(
                switchMap(response => {
                  if (response.error) {
                    return of({ error: response.error }) as ApiResponse<
                      LogicalPortfolioLayer[]
                    >
                  } else {
                    const layerIDs = convertFromLogicalPortfolioLayers(
                      response.data!
                    )
                      .filter(canInure)
                      .map(l => l.id)
                      .map(this.mapWithDeletedNestedLayer(nestedLayers, layers))
                    return this.service.fetchLayers<LogicalPortfolioLayer>(
                      layerIDs
                    )
                  }
                }),
                switchMap(response => {
                  if (response.error) {
                    return of({ error: response.error })
                  }
                  return this.reconcileTargetLayersWithNestedLayer(
                    response.data!,
                    nestedLayer,
                    layer
                  )
                })
              )
            )
          }
        }
      }
    })
    if (actions.length === 0) {
      return of({})
    } else {
      return forkJoin(actions).pipe(
        map(responses => {
          for (const response of responses) {
            if (response.error) {
              return { error: response.error }
            } else if (response.data && Array.isArray(response.data)) {
              return { data: response.data }
            }
          }
          return {}
        })
      )
    }
  }

  reconcileWithSharedLimit(
    nestedLayers: LogicalPortfolioLayer[],
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>
  ): Observable<MaybeError & MaybeData<LogicalPortfolioLayer[]>> {
    const reconActions: Observable<
      MaybeError & MaybeData<LogicalPortfolioLayer[]>
    >[] = []
    nestedLayers.forEach(nestedLayer => {
      const backAllocated = (
        nestedLayer.sources as LogicalPortfolioLayer[]
      ).find(
        l =>
          l.meta_data.backAllocatedForID ===
            nestedLayer.meta_data.backAllocatedForID &&
          l._type === 'BackAllocatedLayer'
      ) as LogicalPortfolioLayer
      const sharedLayer = backAllocated.sink as LogicalPortfolioLayer
      const logicalPortfolioLayer = (
        sharedLayer.sources as LogicalPortfolioLayer[]
      ).find(
        l => l.id === backAllocated.meta_data.backAllocatedForID
      ) as LogicalPortfolioLayer
      reconActions.push(
        this.reconcileWithNestedLayer(
          logicalPortfolioLayer,
          nestedLayer,
          structuresByID,
          structureGroupsByID,
          nestedLayers
        )
      )
    })

    if (reconActions.length === 0) {
      return of({})
    } else {
      return forkJoin(reconActions).pipe(
        map(responses => {
          for (const response of responses) {
            if (response.error) {
              return { error: response.error }
            } else if (response.data) {
              return { data: response.data }
            }
          }
          return {}
        })
      )
    }
  }

  private reconcileWithNestedLayer(
    removedLayer: LogicalPortfolioLayer,
    newLayer: LogicalPortfolioLayer,
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>,
    nestedLayers: LogicalPortfolioLayer[]
  ): Observable<MaybeError & MaybeData<LogicalPortfolioLayer[]>> {
    const actions: Observable<
      MaybeError &
        (MaybeData<LogicalPortfolioLayer[]> | MaybeData<LogicalPortfolioLayer>)
    >[] = []
    removedLayer = {
      ...removedLayer,
      meta_data: {
        ...removedLayer.meta_data,
        inuranceSource: removedLayer.meta_data.tempInuranceSource,
        inuranceTarget: removedLayer.meta_data.tempInuranceTarget,
      },
    }
    if (
      removedLayer.meta_data.inuranceSource ||
      removedLayer.meta_data.inuranceTarget
    ) {
      const removedLayerInuranceSources = removedLayer.meta_data
        .tempSources!.split(',')
        .map(id => ({ ref_id: id }))
      if (removedLayer.meta_data.inuranceTarget) {
        let inuranceSources: Ref[] = []
        inuranceSources.push(...removedLayerInuranceSources)
        const inuranceTargetFor = toInuranceRefs(
          removedLayer.meta_data.inuranceTargetFor
        )
        inuranceSources = inuranceSources.map(i => {
          const nestedLayer = nestedLayers.find(
            n => n.meta_data.backAllocatedForID === i.ref_id
          )
          if (nestedLayer) {
            const targetFor = inuranceTargetFor.find(t => t.id === i.ref_id)
            if (targetFor) {
              targetFor.id = nestedLayer.id
            }
            return { ref_id: nestedLayer.id }
          } else {
            return i
          }
        })
        const newSources: Ref[] = (
          newLayer.sources as LogicalPortfolioLayer[]
        ).map(s => ({ ref_id: s.id }))

        newSources.push(...inuranceSources)
        newLayer = {
          ...newLayer,
          sources: uniqBy(x => x.ref_id, newSources),
          meta_data: {
            ...newLayer.meta_data,
            inuranceTargetFor: JSON.stringify(inuranceTargetFor),
          },
        }
        actions.push(
          this.service.patchLogicalPortfolioLayer({
            id: newLayer.id,
            change: {
              sources: newLayer.sources,
              meta_data: newLayer.meta_data,
            },
          })
        )

        const filteredTargetForRefs = inuranceTargetFor.filter(t =>
          isTargetLayer(t.type)
        )
        if (filteredTargetForRefs.length > 0) {
          const sourceLayersIDs = filteredTargetForRefs
            .filter(f => isSourceLayer(f.type))
            .map(f => f.id)
          if (sourceLayersIDs.length > 0) {
            actions.push(
              this.service
                .fetchLayers<LogicalPortfolioLayer>(sourceLayersIDs)
                .pipe(
                  switchMap(response => {
                    if (response.error) {
                      return of({ error: response.error })
                    } else {
                      return this.reconcileSourceLayersWithNestedLayer(
                        response.data!,
                        removedLayer,
                        newLayer
                      )
                    }
                  })
                )
            )
          }

          const portfolios: string[] = []
          const sourceStructuresIDs = filteredTargetForRefs
            .filter(f => isSourceStructure(f.type))
            .map(f => f.id)
          const sourceStructureGroupIDs = filteredTargetForRefs
            .filter(f => isSourceStructureGroup(f.type))
            .map(f => f.id)
          portfolios.push(
            ...sourceStructuresIDs.map(
              structureID => structuresByID[structureID]!.cededPortfolioID
            )
          )
          portfolios.push(
            ...sourceStructureGroupIDs.map(
              structureGroupID =>
                structureGroupsByID[structureGroupID]!.cededPortfolioID!
            )
          )
          if (portfolios.length > 0) {
            actions.push(
              this.fetchLayersFromPortfolios(portfolios).pipe(
                switchMap(response => {
                  if (response.error) {
                    return of({ error: response.error }) as ApiResponse<
                      LogicalPortfolioLayer[]
                    >
                  } else {
                    const layerIDs = convertFromLogicalPortfolioLayers(
                      response.data!
                    )
                      .filter(canInure)
                      .map(l => l.id)
                      .map(this.mapWithNestedLayer(nestedLayers))
                    return this.service.fetchLayers<LogicalPortfolioLayer>(
                      layerIDs
                    )
                  }
                }),
                switchMap(response => {
                  if (response.error) {
                    return of({ error: response.error })
                  } else {
                    return this.reconcileSourceLayersWithNestedLayer(
                      response.data!,
                      removedLayer,
                      newLayer
                    )
                  }
                })
              )
            )
          }
        }
      }
      if (removedLayer.meta_data.inuranceSource) {
        let sourceRefs = toInuranceRefs(
          removedLayer.meta_data.inuranceSourceFor
        )
        sourceRefs = sourceRefs.map(ref => {
          const nestedLayer = nestedLayers.find(
            n =>
              n.meta_data.backAllocatedForID === ref.id &&
              isTargetLayer(ref.type)
          )
          if (nestedLayer) {
            return { ...ref, id: nestedLayer.id }
          } else {
            return ref
          }
        })
        newLayer = {
          ...newLayer,
          meta_data: {
            ...newLayer.meta_data,
            inuranceSourceFor: JSON.stringify(sourceRefs),
            inuranceSource: sourceRefs.length > 0,
          },
        }
        actions.push(
          this.service.patchLogicalPortfolioLayer({
            id: newLayer.id,
            change: {
              meta_data: newLayer.meta_data,
            },
          })
        )
        const targetLayersIDs = sourceRefs
          .filter(f => isTargetLayer(f.type))
          .map(f => f.id)
        if (targetLayersIDs.length > 0) {
          const newTargetLayersIDs = targetLayersIDs.map(
            this.mapWithNestedLayer(nestedLayers)
          )
          actions.push(
            this.service
              .fetchLayers<LogicalPortfolioLayer>(newTargetLayersIDs)
              .pipe(
                switchMap(response => {
                  if (response.error) {
                    return of({ error: response.error })
                  }
                  return this.reconcileTargetLayersWithNestedLayer(
                    response.data!,
                    removedLayer,
                    newLayer
                  )
                })
              )
          )
        }
        const portfolios: string[] = []
        const targetStructuresIDs = sourceRefs
          .filter(f => isTargetStructure(f.type))
          .map(f => f.id)
        const targetStructureGroupIDs = sourceRefs
          .filter(f => isTargetStructureGroup(f.type))
          .map(f => f.id)
        portfolios.push(
          ...targetStructuresIDs.map(
            structureID => structuresByID[structureID]!.cededPortfolioID
          )
        )
        portfolios.push(
          ...targetStructureGroupIDs.map(
            structureGroupID =>
              structureGroupsByID[structureGroupID]!.cededPortfolioID!
          )
        )
        if (portfolios.length > 0) {
          actions.push(
            this.fetchLayersFromPortfolios(portfolios).pipe(
              switchMap(response => {
                if (response.error) {
                  return of({ error: response.error }) as ApiResponse<
                    LogicalPortfolioLayer[]
                  >
                } else {
                  const layerIDs = convertFromLogicalPortfolioLayers(
                    response.data!
                  )
                    .filter(canInure)
                    .map(l => l.id)
                    .map(this.mapWithNestedLayer(nestedLayers))
                  return this.service.fetchLayers<LogicalPortfolioLayer>(
                    layerIDs
                  )
                }
              }),
              switchMap(response => {
                if (response.error) {
                  return of({ error: response.error })
                }
                return this.reconcileTargetLayersWithNestedLayer(
                  response.data!,
                  removedLayer,
                  newLayer
                )
              })
            )
          )
        }
      }
    }
    if (actions.length === 0) {
      return of({})
    } else {
      return forkJoin(actions).pipe(
        map(responses => {
          for (const response of responses) {
            if (response.error) {
              return { error: response.error }
            } else if (response.data && Array.isArray(response.data)) {
              return { data: response.data }
            }
          }
          return {}
        })
      )
    }
  }

  private mapWithDeletedNestedLayer(
    nestedLayers: LogicalPortfolioLayer[],
    layers: LogicalPortfolioLayer[]
  ) {
    return (layerID: string) => {
      const nestedL = nestedLayers.find(n => n.id === layerID)
      if (nestedL) {
        const newLayer = layers.find(
          l => l.id === (nestedL.meta_data.backAllocatedForID as string)
        )!
        return newLayer.id
      } else {
        return layerID
      }
    }
  }

  private mapWithNestedLayer(nestedLayers: LogicalPortfolioLayer[]) {
    return (layerID: string) => {
      const nestedLayer = nestedLayers.find(
        n => n.meta_data.backAllocatedForID === layerID
      )
      if (nestedLayer) {
        return nestedLayer.id
      } else {
        return layerID
      }
    }
  }

  private reconcileTargetLayersWithNestedLayer(
    targetLayers: LogicalPortfolioLayer[],
    removedLayer: LogicalPortfolioLayer,
    newLayer: LogicalPortfolioLayer
  ) {
    const updates = targetLayers.map(l => {
      const inuranceTargetFor = toInuranceRefs(l.meta_data.inuranceTargetFor)
      const modifiedInuranceTargetFor = inuranceTargetFor.find(
        i => i.id === removedLayer.id
      )
      if (modifiedInuranceTargetFor) {
        modifiedInuranceTargetFor.id = newLayer.id
      }
      const sources: Ref[] = (l.sources as LogicalPortfolioLayer[])
        .filter(s => s.id !== removedLayer.id)
        .map(s => ({ ref_id: s.id }))
      sources.push({ ref_id: newLayer.id })
      return {
        id: l.id,
        change: {
          sources: uniqBy(s => s.ref_id, sources),
          meta_data: {
            ...l.meta_data,
            inuranceTarget: inuranceTargetFor.length > 0,
            inuranceTargetFor: JSON.stringify(inuranceTargetFor),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  private reconcileSourceLayersWithNestedLayer(
    sourceLayers: LogicalPortfolioLayer[],
    removedLayer: LogicalPortfolioLayer,
    newLayer: LogicalPortfolioLayer
  ) {
    const updates = sourceLayers.map(l => {
      const inuranceSourceFor = toInuranceRefs(l.meta_data.inuranceSourceFor)
      const modifiedInuranceSourceFor = inuranceSourceFor.find(
        i => i.id === removedLayer.id
      )
      if (modifiedInuranceSourceFor) {
        modifiedInuranceSourceFor!.id = newLayer.id
      }
      return {
        id: l.id,
        change: {
          meta_data: {
            ...l.meta_data,
            inuranceSource: inuranceSourceFor.length > 0,
            inuranceSourceFor: JSON.stringify(inuranceSourceFor),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  private validate(
    groupRelationships: SourceAndTargetStructureGroupReferences,
    newLayers: Layer[],
    structuresByID: Dictionary<Program>,
    groupsByID: Dictionary<ProgramGroup>
  ): Observable<MaybeError> {
    const validationActions: Observable<MaybeError>[] = []
    const sourceLayersActions: ApiResponse<LogicalPortfolioLayer[]>[] = []
    const targetLayersActions: ApiResponse<LogicalPortfolioLayer[]>[] = []
    // Check Sources
    if (groupRelationships.layerSources.length > 0) {
      sourceLayersActions.push(
        this.service.fetchLayers<LogicalPortfolioLayer>(
          groupRelationships.layerSources
        )
      )
    }
    if (
      groupRelationships.structureSources.length > 0 ||
      groupRelationships.structureGroupSources.length > 0
    ) {
      const structurePortfolios = groupRelationships.structureSources.map(
        id => structuresByID[id]!.cededPortfolioID
      )
      const groupPortfolios = groupRelationships.structureGroupSources.map(
        id => groupsByID[id]!.cededPortfolioID!
      )
      sourceLayersActions.push(
        this.fetchLayersFromPortfolios([
          ...structurePortfolios,
          ...groupPortfolios,
        ])
      )
    }
    if (sourceLayersActions.length > 0) {
      validationActions.push(
        forkJoin(sourceLayersActions).pipe(
          map(responses => {
            for (const response of responses) {
              if (response.error) {
                return { error: response.error }
              }
            }
            const sourceLayers = convertFromLogicalPortfolioLayers(
              responses.flatMap(r => r.data!)
            )
            const payload = inuranceValidationToErrorPayload(
              this.validateInurance(sourceLayers, newLayers)
            )
            if (payload) {
              return { error: payload }
            } else {
              return {}
            }
          })
        )
      )
    } else {
      validationActions.push(of({}))
    }

    // Check Targets

    if (groupRelationships.layerTargets.length > 0) {
      targetLayersActions.push(
        this.service.fetchLayers<LogicalPortfolioLayer>(
          groupRelationships.layerTargets
        )
      )
    }
    if (
      groupRelationships.structureTargets.length > 0 ||
      groupRelationships.structureGroupTargets.length > 0
    ) {
      const structurePortfolios = groupRelationships.structureTargets.map(
        id => structuresByID[id]!.cededPortfolioID
      )
      const groupPortfolios = groupRelationships.structureGroupTargets.map(
        id => groupsByID[id]!.cededPortfolioID!
      )
      targetLayersActions.push(
        this.fetchLayersFromPortfolios([
          ...structurePortfolios,
          ...groupPortfolios,
        ])
      )
    }

    if (targetLayersActions.length > 0) {
      validationActions.push(
        forkJoin(targetLayersActions).pipe(
          map(responses => {
            for (const response of responses) {
              if (response.error) {
                return { error: response.error }
              }
            }
            const targetLayers = convertFromLogicalPortfolioLayers(
              responses.flatMap(r => r.data!)
            )
            const payload = inuranceValidationToErrorPayload(
              this.validateInurance(newLayers, targetLayers)
            )
            if (payload) {
              return { error: payload }
            } else {
              return {}
            }
          })
        )
      )
    } else {
      validationActions.push(of({}))
    }
    return forkJoin(validationActions).pipe(
      map(responses => {
        return this.uniqInuranceValidationErrors(responses)
      })
    )
  }

  private uniqInuranceValidationErrors(responses: MaybeError[]) {
    const errorDetails = []
    let error = false
    for (const response of responses) {
      if (response.error) {
        error = true
        if (response.error.details && response.error.details.length > 0) {
          errorDetails.push(...response.error.details)
        }
      }
    }
    if (error) {
      return {
        error: errorPayload('Inurance validation failed.', uniq(errorDetails)),
      }
    } else {
      return {}
    }
  }

  inure(
    sourceLayers: Layer[],
    targetLayers: Layer[],
    source: InuranceReference[],
    target: InuranceReference[],
    relationship: InuranceRelationship
  ): ApiResponse<LogicalPortfolioLayer[]> {
    const validation = this.validateInurance(sourceLayers, targetLayers)
    const validationPayload = inuranceValidationToErrorPayload(validation)
    if (validationPayload) {
      return of({ error: validationPayload })
    }
    const references = sourceLayers.map(this.toLayerRef)
    const sourceUpdateRequest: Partial<LogicalPortfolioLayer>[] =
      sourceLayers.map(layer => {
        const inuranceSourceFor = this.getInuranceRefs(
          layer.meta_data.inuranceSourceFor,
          targetLayers,
          target,
          relationship,
          source[0].structureGroupID,
          true
        )
        const inuranceSourceReference = source.find(
          s => s.layerID === layer.id
        ) as InuranceReference
        const layerName =
          isLayerBackAllocated(layer) || isSectionLayer(layer)
            ? layer.meta_data.layerName || ''
            : layer.physicalLayer.description || ''
        return {
          id: layer.id,
          meta_data: {
            ...layer.meta_data,
            inuranceSource: true,
            inuranceSourceFor,
            structureID: inuranceSourceReference.structureID,
            layerName,
          },
        }
      })
    const targetUpdateRequest: Partial<LogicalPortfolioLayer>[] =
      targetLayers.map(layer => {
        const inuranceTargetFor = this.getInuranceRefs(
          layer.meta_data.inuranceTargetFor,
          sourceLayers,
          source,
          relationship,
          target[0].structureGroupID,
          false
        )
        const inuranceTargetReference = target.find(
          s => s.layerID === layer.id
        ) as InuranceReference
        return {
          id: layer.id,
          sources: uniqBy(l => l.ref_id, [...getSources(layer), ...references]),
          meta_data: {
            ...layer.meta_data,
            inuranceTarget: true,
            inuranceTargetFor,
            structureID: inuranceTargetReference.structureID,
          },
        }
      })
    const updates = [...sourceUpdateRequest, ...targetUpdateRequest].map(
      request => ({ id: request.id!, change: request })
    )
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  reconcileInuranceOnAdd(
    sourceAndTargetReference: SourceAndTargetStructureAndGroupReferences,
    layer: Layer
  ) {
    const mutableLayer = JSON.parse(JSON.stringify(layer)) as Layer
    if (
      (sourceAndTargetReference.layerSources.length > 0 ||
        sourceAndTargetReference.structureSources.length > 0 ||
        sourceAndTargetReference.structureGroupSources) &&
      sourceAndTargetReference.targetForSample
    ) {
      const targetForLayerSample = sourceAndTargetReference.targetForSample!
      mutableLayer.meta_data.inuranceTarget = true

      const targetForInuranceRefs = toInuranceRefs(
        targetForLayerSample.meta_data.inuranceTargetFor
      ).filter(r => !isTargetLayer(r.type))
      mutableLayer.meta_data.inuranceTargetFor = fromInuranceRefs(
        targetForInuranceRefs
      )
      const sampleLossSetLayerRefs = targetForLayerSample.lossSetLayers.filter(
        l => {
          const sourceFor = toInuranceRefs(l.meta_data.inuranceSourceFor)
          const groupIDs = sourceFor
            .filter(s => s.groupID)
            .map(s => s.groupID) as string[]
          return (
            l.meta_data.inuranceSource &&
            targetForInuranceRefs.find(t => {
              return (
                (isSourceLayer(t.type) && t.id === l.id) ||
                (isSourceStructure(t.type) &&
                  t.id === l.meta_data.structureID!) ||
                (isSourceStructureGroup(t.type) && groupIDs.includes(t.id))
              )
            })
          )
        }
      )
      mutableLayer.lossSetLayers.push(...sampleLossSetLayerRefs)
      mutableLayer.lossSetLayers = uniqBy(l => l.id, mutableLayer.lossSetLayers)
    }
    if (
      (sourceAndTargetReference.layerTargets.length > 0 ||
        sourceAndTargetReference.structureTargets.length > 0 ||
        sourceAndTargetReference.structureGroupTargets.length > 0) &&
      sourceAndTargetReference.sourceForSample
    ) {
      const sourceForSampleLayer = sourceAndTargetReference.sourceForSample
      const inuranceSourceFor = toInuranceRefs(
        sourceForSampleLayer.meta_data.inuranceSourceFor
      ).filter(r => !isSourceLayer(r.type))
      mutableLayer.meta_data.inuranceSource = true
      mutableLayer.meta_data.inuranceSourceFor =
        fromInuranceRefs(inuranceSourceFor)
    }
    return mutableLayer
  }

  reconcileInuranceOnSave(
    sourceAndTargetReference: SourceAndTargetStructureAndGroupReferences,
    newLayers: Layer[],
    deletedLayers: Layer[],
    structureByID: Dictionary<Program>,
    structureGroupByID: Dictionary<ProgramGroup>
  ): Observable<MaybeError> {
    const filteredDeleted = deletedLayers.filter(
      d => d.meta_data.inuranceTarget || d.meta_data.inuranceSource
    )

    // Order matters
    const deleteSourceActions: Observable<
      (MaybeData<LogicalPortfolioLayer> & MaybeError)[]
    >[] = []
    const deleteTargetActions: Observable<
      (MaybeData<LogicalPortfolioLayer> & MaybeError)[]
    >[] = []
    const reconcileLayerTargetActions: ApiResponse<LogicalPortfolioLayer>[] = []
    const reconcileLayerStructureAndGroupActions: ApiResponse<
      LogicalPortfolioLayer[]
    >[] = []

    filteredDeleted.forEach(deleted => {
      if (deleted.meta_data.inuranceSourceFor) {
        const inuranceTargets = toInuranceRefs(
          deleted.meta_data.inuranceSourceFor
        )
        inuranceTargets.forEach(target => {
          deleteSourceActions.push(
            this.reconcileInuranceDeleteSourceFor(
              deleted,
              target.id,
              target.type,
              structureByID,
              structureGroupByID
            )
          )
        })
      }
      if (deleted.meta_data.inuranceTargetFor) {
        const inuranceSources = toInuranceRefs(
          deleted.meta_data.inuranceTargetFor
        ).filter(m => isTargetLayer(m.type))
        inuranceSources.forEach(source => {
          deleteTargetActions.push(
            this.reconcileInuranceDeleteTargetFor(
              deleted,
              source,
              structureByID,
              structureGroupByID
            )
          )
        })
      }
    })
    if (
      (sourceAndTargetReference.layerTargets.length > 0 ||
        sourceAndTargetReference.structureTargets.length > 0 ||
        sourceAndTargetReference.structureGroupTargets.length > 0) &&
      sourceAndTargetReference.sourceForSample &&
      newLayers.length > 0
    ) {
      sourceAndTargetReference.layerTargets.forEach(layerTarget => {
        reconcileLayerTargetActions.push(
          this.reconcileInuranceLayerTarget(layerTarget, newLayers)
        )
      })
      sourceAndTargetReference.structureTargets.forEach(structureTarget => {
        reconcileLayerStructureAndGroupActions.push(
          this.reconcileInuranceStructureAndGroupTarget(
            structureTarget,
            newLayers,
            false
          )
        )
      })
      sourceAndTargetReference.structureGroupTargets.forEach(
        strutureGroupTarget => {
          reconcileLayerStructureAndGroupActions.push(
            this.reconcileInuranceStructureAndGroupTarget(
              strutureGroupTarget,
              newLayers,
              true
            )
          )
        }
      )
    }
    if (deleteSourceActions.length === 0) {
      deleteSourceActions.push(of([{}]))
    }
    if (deleteTargetActions.length === 0) {
      deleteTargetActions.push(of([{}]))
    }
    if (reconcileLayerTargetActions.length === 0) {
      reconcileLayerTargetActions.push(of({}))
    }
    if (reconcileLayerStructureAndGroupActions.length === 0) {
      reconcileLayerStructureAndGroupActions.push(of({}))
    }
    return forkJoin(deleteSourceActions).pipe(
      switchMap(responses => {
        for (const response of responses) {
          for (const innerResponse of response) {
            if (innerResponse.error) {
              return of([{ error: innerResponse.error }] as MaybeError[])
            }
          }
        }
        return forkJoin(deleteTargetActions)
      }),
      switchMap(
        (responses: (MaybeData<LogicalPortfolioLayer> & MaybeError)[][]) => {
          for (const response of responses) {
            for (const innerResponse of response) {
              if (innerResponse.error) {
                return of([{ error: innerResponse.error }] as MaybeError[])
              }
            }
          }
          return forkJoin(reconcileLayerTargetActions)
        }
      ),
      switchMap(responses => {
        for (const response of responses) {
          if (response.error) {
            return of([{ error: response.error }] as MaybeError[])
          }
        }
        return forkJoin(reconcileLayerStructureAndGroupActions)
      }),
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return of([{ error: response.error }] as MaybeError[])
          }
        }
        return {}
      })
    )
  }

  private reconcileGroupAddAsSource(
    parentSourceFor: InuranceMetadataRef[],
    sources: Layer[],
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>,
    sourcesStructureID: string
  ): Observable<MaybeError> {
    const actions: Observable<MaybeError>[] = []
    const sourceUpdates: Update<LogicalPortfolioLayer>[] = sources.map(s => {
      const inuranceSourceFor = toInuranceRefs(s.meta_data.inuranceSourceFor)
      inuranceSourceFor.push(...parentSourceFor)
      return {
        id: s.id,
        change: {
          meta_data: {
            ...s.meta_data,
            inuranceSource: inuranceSourceFor.length > 0,
            inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
            structureID: sourcesStructureID,
          },
        },
      }
    })
    actions.push(this.service.patchLogicalPortfolioLayers(sourceUpdates))
    const refTuple = partition(p => isTargetLayer(p.type), parentSourceFor)
    const layerIDs = refTuple[0].flatMap(r => r.id)
    if (layerIDs.length > 0) {
      actions.push(
        this.service.fetchLayers(layerIDs).pipe(
          concatMap(response => {
            if (response.error) {
              return of({ error: response.error })
            }
            const layers = convertFromLogicalPortfolioLayers(
              response.data! as LogicalPortfolioLayer[]
            ).filter(canInure)
            return this.reconcileGroupAddLayersAsSource(sources, layers)
          })
        )
      )
    }
    if (refTuple[1].length > 0) {
      const portfolioIDs = refTuple[1].map(ref => {
        if (isTargetStructure(ref.type)) {
          return structuresByID[ref.id]!.cededPortfolioID
        } else {
          return structureGroupsByID[ref.id]!.cededPortfolioID!
        }
      })
      actions.push(
        this.fetchLayersFromPortfolios(portfolioIDs).pipe(
          concatMap(response => {
            if (response.error) {
              return of({ error: response.error })
            }
            const layers = convertFromLogicalPortfolioLayers(
              response.data! as LogicalPortfolioLayer[]
            ).filter(canInure)
            return this.reconcileGroupAddLayersAsSource(sources, layers)
          })
        )
      )
    }
    if (actions.length > 0) {
      return forkJoin(actions).pipe(
        map(responses => {
          for (const response of responses) {
            if (response.error) {
              return { error: response.error }
            }
          }
          return {}
        })
      )
    } else {
      return of({})
    }
  }

  private reconcileGroupAddLayersAsSource(sources: Layer[], targets: Layer[]) {
    const layerUpdates: Update<LogicalPortfolioLayer>[] = targets.map(layer => {
      const lossSets: Ref[] = getSources(layer)
      lossSets.push(...sources.map(s => ({ ref_id: s.id })))
      return {
        id: layer.id,
        change: {
          sources: uniqBy(s => s.ref_id, lossSets),
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(layerUpdates)
  }

  private reconcileGroupAddAsTarget(
    parentSourceLayers: LayerRef[],
    parentTargetFor: InuranceMetadataRef[],
    targets: Layer[],
    targestStructureID: string
  ): Observable<MaybeError> {
    const updates: Update<LogicalPortfolioLayer>[] = targets.map(t => {
      const sources: Ref[] = getSources(t)
      sources.push(...parentSourceLayers.map(s => ({ ref_id: s.id })))
      const inuranceTargetFor = toInuranceRefs(t.meta_data.inuranceTargetFor)
      inuranceTargetFor.push(...parentTargetFor)
      return {
        id: t.id,
        change: {
          sources: uniqBy(s => s.ref_id, sources),
          meta_data: {
            ...t.meta_data,
            inuranceTargetFor: fromInuranceRefs(inuranceTargetFor),
            inuranceTarget: inuranceTargetFor.length > 0,
            structureID: targestStructureID,
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  private getMemberDataFromStructureGroupMember(
    allProgramGroupMembers: ProgramGroupMember[],
    members: ProgramGroupMember[]
  ): MemberData[] {
    return members.map(programMember => {
      const structureID = programMember.programID
      const groupID = programMember.programGroupID
      const parentGroupID = programMember.parentGroupID!
      const ancestors = this.getAllAncestors(
        allProgramGroupMembers,
        parentGroupID
      )
      const allGroupIDs = [parentGroupID, ...ancestors]
      return {
        structureID,
        groupID,
        ancestorGroupIDs: allGroupIDs,
        type: programMember.type === 'program' ? 'structure' : 'structureGroup',
        memberID: programMember.id,
      }
    })
  }

  private reconcilePortfolioOnGroupDelete(
    portfolioID: string,
    groupRemoved: string[],
    isMember: boolean
  ): Observable<MaybeError> {
    return this.fetchLayersFromPortfolios([portfolioID]).pipe(
      concatMap(response => {
        if (response.error) {
          return of({ error: response.error })
        }
        const layers = convertFromLogicalPortfolioLayers(response.data!).filter(
          canInure
        )
        const references = getInuranceSourceAndTargerReferenceByGroup(
          layers,
          groupRemoved
        )
        const actions: Observable<MaybeError>[] = []
        if (
          references.layerTargets.length > 0 ||
          references.structureTargets.length > 0 ||
          references.structureGroupTargets.length > 0
        ) {
          actions.push(
            this.reconcileTargetsOnGroupSourceDelete(
              groupRemoved,
              layers,
              references.layerTargets,
              references.structureTargets,
              references.structureGroupTargets,
              isMember
            )
          )
        }
        if (
          references.layerSources.length > 0 ||
          references.structureSources.length > 0 ||
          references.structureGroupSources.length > 0
        ) {
          actions.push(
            this.reconcileSourcesOnGroupTargetDelete(
              groupRemoved,
              references.layerSources,
              references.structureSources,
              references.structureGroupSources,
              isMember
            )
          )
        }
        if (actions.length > 0) {
          actions.push(
            this.removeDeletedGroupInuranceInfo(layers, groupRemoved)
          )
          return executeSequentially(actions)
        } else {
          return of({})
        }
      })
    )
  }

  private getMemberData(
    memberIDs: string[],
    members: ProgramGroupMember[]
  ): readonly [MemberData[], MemberData[]] {
    const structureGroupMembers = memberIDs.map(
      memberID => members.find(m => m.id === memberID)!
    )
    const memberData = this.getMemberDataFromStructureGroupMember(
      members,
      structureGroupMembers
    )
    return partition(m => m.type === 'structure', memberData)
  }

  private getAllAncestors(
    programGroupMembers: ProgramGroupMember[],
    groupID: string
  ) {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'programGroup' &&
        m.programGroupID === groupID &&
        m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestors(programGroupMembers, member.parentGroupID)
        )
      }
      return groupIDs
    } else {
      return groupIDs
    }
  }

  private removeDeletedGroupInuranceInfo(
    layers: Layer[],
    removedGroupIDs: string[]
  ) {
    const updates: Update<LogicalPortfolioLayer>[] = layers.map(l => {
      const sources = l.lossSetLayers
        .filter(lossSet => {
          const lossSetInuranceSourceFor = toInuranceRefs(
            lossSet.meta_data.inuranceSourceFor
          )
          return !!!lossSetInuranceSourceFor.find(
            s =>
              isTargetStructureGroup(s.type) && removedGroupIDs.includes(s.id)
          )
        })
        .map(s => ({ ref_id: s.id }))
      sources.push(...l.layerRefs.map(refs => ({ ref_id: refs })))
      const inuranceSourceFor = toInuranceRefs(
        l.meta_data.inuranceSourceFor
      ).filter(
        r =>
          !(
            isSourceStructureGroup(r.type) &&
            removedGroupIDs.includes(r.groupID as string)
          )
      )
      const inuranceSource = inuranceSourceFor.length > 0
      const inuranceTargetFor = toInuranceRefs(
        l.meta_data.inuranceTargetFor
      ).filter(
        r =>
          !(
            isTargetStructureGroup(r.type) &&
            removedGroupIDs.includes(r.groupID as string)
          )
      )
      const inuranceTarget = inuranceTargetFor.length > 0
      return {
        id: l.id,
        change: {
          sources,
          meta_data: {
            ...l.meta_data,
            inuranceSource,
            inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
            inuranceTarget,
            inuranceTargetFor: fromInuranceRefs(inuranceTargetFor),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(updates)
  }

  private reconcileSourcesOnGroupTargetDelete(
    removedGroupIDs: string[],
    sourceLayerIDs: string[],
    sourceStructureIDs: string[],
    sourceStructureGroupIDs: string[],
    memberRemove: boolean
  ): Observable<MaybeError> {
    const actions: ApiResponse<LogicalPortfolioLayer[]>[] = []
    if (sourceLayerIDs.length > 0) {
      actions.push(
        this.service.fetchLayers<LogicalPortfolioLayer>(sourceLayerIDs).pipe(
          mergeMap(response => {
            if (response.error) {
              return of({ error: response.error })
            }
            const layers = convertFromLogicalPortfolioLayers(
              response.data!
            ).filter(canInure)
            return this.reconcileLayerSourcesOnGroupTargetDelete(
              removedGroupIDs,
              layers,
              memberRemove
            )
          })
        )
      )
    }
    if (sourceStructureIDs.length > 0 || sourceStructureGroupIDs.length > 0) {
      actions.push(
        of(1).pipe(
          withLatestFrom(
            this.store.pipe(select(selectProgramsByID)),
            this.store.pipe(select(selectProgramGroupsByID))
          ),
          mergeMap(([_, structuresByID, structureGroupsByID]) => {
            const portfolioIDs: string[] = this.getCededPortfolioIDs(
              sourceStructureIDs,
              sourceStructureGroupIDs,
              structuresByID,
              structureGroupsByID
            )
            return this.fetchLayersFromPortfolios(portfolioIDs)
          }),
          mergeMap(response => {
            if (response.error) {
              return of({ error: response.error })
            } else {
              const layers = convertFromLogicalPortfolioLayers(
                response.data!
              ).filter(canInure)
              return this.reconcileLayerSourcesOnGroupTargetDelete(
                removedGroupIDs,
                layers,
                memberRemove
              )
            }
          })
        )
      )
    }
    return forkJoin(actions).pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error }
          }
        }
        return {}
      })
    )
  }
  private getCededPortfolioIDs(
    structureIDs: string[],
    structureGroupIDs: string[],
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>
  ) {
    const portfolioIDs: string[] = []
    structureIDs.forEach(structureID => {
      const structure = structuresByID[structureID]
      if (structure) {
        portfolioIDs.push(structure.cededPortfolioID)
      }
    })
    structureGroupIDs.forEach(structureGroupID => {
      const group = structureGroupsByID[structureGroupID]
      if (group && group.cededPortfolioID) {
        portfolioIDs.push(group.cededPortfolioID)
      }
    })
    return portfolioIDs
  }

  private reconcileTargetsOnGroupSourceDelete(
    removedGroupIDs: string[],
    removedLayers: Layer[],
    targetLayerIDs: string[],
    targetStructureIDs: string[],
    targetStructureGroupIDs: string[],
    memberRemove: boolean
  ): Observable<MaybeError> {
    const actions: ApiResponse<LogicalPortfolioLayer[]>[] = []
    if (targetLayerIDs.length > 0) {
      actions.push(
        this.service.fetchLayers<LogicalPortfolioLayer>(targetLayerIDs).pipe(
          mergeMap(response => {
            if (response.error) {
              return of({ error: response.error })
            }
            const layers = convertFromLogicalPortfolioLayers(
              response.data!
            ).filter(canInure)
            return this.reconcileLayerTargetsOnGroupSourceDelete(
              removedGroupIDs,
              removedLayers,
              layers,
              memberRemove
            )
          })
        )
      )
    }
    if (targetStructureIDs.length > 0 || targetStructureGroupIDs.length > 0) {
      actions.push(
        of(1).pipe(
          withLatestFrom(
            this.store.pipe(select(selectProgramsByID)),
            this.store.pipe(select(selectProgramGroupsByID))
          ),
          mergeMap(([_, structuresByID, structureGroupsByID]) => {
            const portfolioIDs: string[] = this.getCededPortfolioIDs(
              targetStructureIDs,
              targetStructureGroupIDs,
              structuresByID,
              structureGroupsByID
            )
            return this.fetchLayersFromPortfolios(portfolioIDs)
          }),
          mergeMap(response => {
            if (response.error) {
              return of({ error: response.error })
            } else {
              const layers = convertFromLogicalPortfolioLayers(
                response.data!
              ).filter(canInure)
              return this.reconcileLayerTargetsOnGroupSourceDelete(
                removedGroupIDs,
                removedLayers,
                layers,
                memberRemove
              )
            }
          })
        )
      )
    }
    return forkJoin(actions).pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error }
          }
        }
        return {}
      })
    )
  }

  private reconcileLayerTargetsOnGroupSourceDelete(
    removedGroupIDs: string[],
    removedLayers: Layer[],
    targetLayers: Layer[],
    memberRemove: boolean
  ) {
    const removedLayersIDs = removedLayers.map(r => r.id)
    const changes: Update<LogicalPortfolioLayer>[] = targetLayers.map(layer => {
      const sources = getSources(layer).filter(
        lossSet => !removedLayersIDs.includes(lossSet.ref_id)
      )
      const inuranceTargetFor = toInuranceRefs(
        layer.meta_data.inuranceTargetFor
      ).filter(
        r =>
          memberRemove ||
          !(removedGroupIDs.includes(r.id) && isSourceStructureGroup(r.type))
      )
      const inuranceTarget = inuranceTargetFor.length > 0
      return {
        id: layer.id,
        change: {
          sources,
          meta_data: {
            ...layer.meta_data,
            inuranceTarget,
            inuranceTargetFor: fromInuranceRefs(inuranceTargetFor),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(changes)
  }

  private reconcileLayerSourcesOnGroupTargetDelete(
    removedGroupIDs: string[],
    sourceLayers: Layer[],
    memberRemove: boolean
  ) {
    const changes: Update<LogicalPortfolioLayer>[] = sourceLayers.map(layer => {
      const inuranceSourceFor = toInuranceRefs(
        layer.meta_data.inuranceSourceFor
      ).filter(
        r =>
          memberRemove ||
          !(removedGroupIDs.includes(r.id) && isTargetStructureGroup(r.type))
      )
      const inuranceSource = inuranceSourceFor.length > 0
      return {
        id: layer.id,
        change: {
          meta_data: {
            ...layer.meta_data,
            inuranceSource,
            inuranceSourceFor: fromInuranceRefs(inuranceSourceFor),
          },
        },
      }
    })
    return this.service.patchLogicalPortfolioLayers(changes)
  }

  private reconcileInuranceStructureAndGroupTarget(
    target: string,
    sources: Layer[],
    group: boolean
  ): ApiResponse<LogicalPortfolioLayer[]> {
    return of(1).pipe(
      withLatestFrom(
        this.store.pipe(select(selectProgramsByID)),
        this.store.pipe(select(selectProgramGroupsByID))
      ),
      switchMap(([_, structureEntities, structureGroupEntities]) => {
        if (group) {
          if (
            structureGroupEntities[target] &&
            structureGroupEntities[target]!.cededPortfolioID
          ) {
            return this.service.fetchPortfolio(
              structureGroupEntities[target]!.cededPortfolioID!
            )
          } else {
            return of({
              error: errorPayload(`Missing Structure Group ID ${target}.`),
            })
          }
        } else {
          if (structureEntities[target]) {
            return this.service.fetchPortfolio(
              structureEntities[target]!.cededPortfolioID
            )
          } else {
            return of({
              error: errorPayload(`Missing Structure ID ${target}.`),
            })
          }
        }
      }),
      switchMap((response: MaybeData<Portfolio> & MaybeError) => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const targetLayers = convertFromLogicalPortfolioLayers(
            response.data!.layers as LogicalPortfolioLayer[]
          ).filter(canInure)
          const updates: Update<LogicalPortfolioLayer>[] = targetLayers.map(
            targetLayer => {
              const lossSetLayers = getSources(targetLayer)
              lossSetLayers.push(...sources.map(s => ({ ref_id: s.id })))
              return {
                id: targetLayer.id,
                change: {
                  sources: uniqBy(l => l.ref_id, lossSetLayers),
                },
              }
            }
          )
          return this.service.patchLogicalPortfolioLayers(updates)
        }
      })
    )
  }

  private reconcileInuranceLayerTarget(
    target: string,
    sources: Layer[]
  ): ApiResponse<LogicalPortfolioLayer> {
    return this.service.fetchLayers<LogicalPortfolioLayer>([target]).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const targetLayer = response.data![0]
          const lossSetLayers = (targetLayer.sources as LossSetLayer[]).map(
            l => ({ ref_id: l.id })
          )
          lossSetLayers.push(...sources.map(s => ({ ref_id: s.id })))
          const update: Update<LogicalPortfolioLayer> = {
            id: target,
            change: { sources: uniqBy(l => l.ref_id, lossSetLayers) },
          }
          return this.service.patchLayer(update)
        }
      })
    )
  }

  private patchSourceOnTargetDelete(
    target: Layer,
    source: string
  ): ApiResponse<LogicalPortfolioLayer> {
    return this.service.fetchLayers<LogicalPortfolioLayer>([source]).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const sourceLayer = response.data![0]
          const sourceLayerInuranceSourceFor = toInuranceRefs(
            sourceLayer.meta_data.inuranceSourceFor
          )
          const filteredInuranceRefs = sourceLayerInuranceSourceFor.filter(
            t => t.id !== target.id
          )

          const update: Update<LogicalPortfolioLayer> = {
            id: sourceLayer.id,
            change: {
              meta_data: {
                ...sourceLayer.meta_data,
                inuranceSourceFor: fromInuranceRefs(filteredInuranceRefs),
                inuranceSource: filteredInuranceRefs.length > 0,
              },
            },
          }
          return this.service.patchLayer<LogicalPortfolioLayer>(update)
        }
      })
    )
  }

  private reconcileInuranceDeleteTargetFor(
    target: Layer,
    source: InuranceMetadataRef,
    structuresByID: Dictionary<Program>,
    structureGroupsByID: Dictionary<ProgramGroup>
  ): Observable<(MaybeData<LogicalPortfolioLayer> & MaybeError)[]> {
    if (isSourceLayer(source.type)) {
      return this.patchSourceOnTargetDelete(target, source.id).pipe(
        map(res => {
          return [res]
        })
      )
    } else {
      let cededPortfolioID = ''
      if (isSourceStructure(source.type)) {
        if (structuresByID[source.id]) {
          cededPortfolioID = structuresByID[source.id]!.cededPortfolioID
        } else {
          return of([
            { error: errorPayload(`Missing Structure ID ${source.id}.`) },
          ])
        }
      } else {
        if (
          structureGroupsByID[source.id] &&
          structureGroupsByID[source.id]!.cededPortfolioID
        ) {
          cededPortfolioID = structureGroupsByID[source.id]!.cededPortfolioID!
        } else {
          return of([
            { error: errorPayload(`Missing Structure Group ID ${source.id}.`) },
          ])
        }
      }
      return this.service.fetchPortfolio(cededPortfolioID).pipe(
        switchMap(response => {
          if (response.error) {
            return of([{ error: response.error }])
          } else {
            const updates: ApiResponse<LogicalPortfolioLayer>[] = []
            const cededLayers = convertFromLogicalPortfolioLayers(
              response.data!.layers as LogicalPortfolioLayer[]
            ).filter(canInure)
            cededLayers.forEach(l => {
              updates.push(this.patchSourceOnTargetDelete(target, l.id))
            })
            return forkJoin(updates)
          }
        })
      )
    }
  }

  private patchInuranceTargetsOnDelete(
    source: Layer,
    target: string,
    relationship: InuranceRelationship
  ) {
    return this.service.fetchLayers<LogicalPortfolioLayer>([target]).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const targetLayer = response.data![0]
          const targetLayerInuranceTargetFor = toInuranceRefs(
            targetLayer.meta_data.inuranceTargetFor
          )
          let filteredInuranceRefs = targetLayerInuranceTargetFor
          if (relationship === 'layerToLayer') {
            filteredInuranceRefs = targetLayerInuranceTargetFor.filter(
              t => t.id !== source.id
            )
          }
          const targetLossSetLayers = (
            targetLayer.sources as LossSetLayer[]
          ).map(s => ({
            ref_id: s.id,
          }))
          const update: Update<LogicalPortfolioLayer> = {
            id: targetLayer.id,
            change: {
              meta_data: {
                ...targetLayer.meta_data,
                inuranceTargetFor: fromInuranceRefs(filteredInuranceRefs),
                inuranceTarget: filteredInuranceRefs.length > 0,
              },
              sources: targetLossSetLayers.filter(l => l.ref_id !== source.id),
            },
          }
          return this.service.patchLayer<LogicalPortfolioLayer>(update)
        }
      })
    )
  }

  private reconcileInuranceDeleteSourceFor(
    source: Layer,
    target: string,
    relationship: InuranceRelationship,
    strutureByID: Dictionary<Program>,
    structureGroupByID: Dictionary<ProgramGroup>
  ): Observable<(MaybeData<LogicalPortfolioLayer> & MaybeError)[]> {
    if (isTargetLayer(relationship)) {
      return this.patchInuranceTargetsOnDelete(
        source,
        target,
        relationship
      ).pipe(
        map(res => {
          return [res]
        })
      )
    } else if (isTargetStructure(relationship)) {
      if (strutureByID[target]) {
        return this.updateStructureAndStructureGroupTargetOnDelete(
          strutureByID[target]!.cededPortfolioID,
          source,
          relationship
        )
      } else {
        return of([
          { error: errorPayload(`Structure ID ${target} not found.`) },
        ])
      }
    } else {
      if (
        structureGroupByID[target] &&
        structureGroupByID[target]!.cededPortfolioID
      ) {
        return this.updateStructureAndStructureGroupTargetOnDelete(
          structureGroupByID[target]!.cededPortfolioID!,
          source,
          relationship
        )
      } else {
        return of([
          { error: errorPayload(`Structure Group ID ${target} not found.`) },
        ])
      }
    }
  }

  private updateStructureAndStructureGroupTargetOnDelete(
    id: string,
    source: Layer,
    relationship: InuranceRelationship
  ): Observable<(MaybeData<LogicalPortfolioLayer> & MaybeError)[]> {
    return this.service.fetchPortfolio(id).pipe(
      switchMap(portfolio => {
        if (portfolio.error) {
          return of([{ error: portfolio.error }])
        }
        const updateLayers: ApiResponse<LogicalPortfolioLayer>[] = []
        const cededLayers = convertFromLogicalPortfolioLayers(
          portfolio.data!.layers as LogicalPortfolioLayer[]
        ).filter(canInure)
        cededLayers.forEach(l => {
          updateLayers.push(
            this.patchInuranceTargetsOnDelete(source, l.id, relationship)
          )
        })
        return forkJoin(updateLayers)
      })
    )
  }

  private getInuranceRefs(
    inuranceFor: string | undefined,
    layer: Layer[],
    reference: InuranceReference[],
    relationship: InuranceRelationship,
    selfGroupID: string | undefined,
    inuranceSourceFor: boolean
  ) {
    const newInuranceRefs: InuranceMetadataRef[] = []
    if (
      ((relationship === 'structureToLayer' ||
        relationship === 'structureToStructureGroup') &&
        !inuranceSourceFor) ||
      ((relationship === 'layerToStructure' ||
        relationship === 'structureGroupToStructure') &&
        inuranceSourceFor) ||
      relationship === 'structureToStructure'
    ) {
      newInuranceRefs.push({
        type: relationship,
        id: reference[0].structureID,
        groupID: selfGroupID,
      })
    } else if (
      ((relationship === 'structureGroupToLayer' ||
        relationship === 'structureGroupToStructure') &&
        !inuranceSourceFor) ||
      ((relationship === 'layerToStructureGroup' ||
        relationship === 'structureToStructureGroup') &&
        inuranceSourceFor) ||
      relationship === 'structureGroupToStructureGroup'
    ) {
      newInuranceRefs.push({
        type: relationship,
        id: reference[0].structureGroupID as string,
        groupID: selfGroupID,
      })
    } else {
      layer.forEach(l => {
        newInuranceRefs.push({
          type: relationship,
          id: l.id,
          groupID: selfGroupID,
        })
      })
    }
    let newInuranceFor = inuranceFor
    if (newInuranceFor) {
      const inuranceRefs = toInuranceRefs(newInuranceFor)
      inuranceRefs.push(...newInuranceRefs)
      newInuranceFor = fromInuranceRefs(inuranceRefs)
    } else {
      newInuranceFor = fromInuranceRefs(newInuranceRefs)
    }
    return newInuranceFor
  }

  private toLayerRef(layer: Layer): Ref {
    return {
      ref_id: layer.id,
    }
  }

  fetchLayersFromPortfolios(
    ids: string[]
  ): ApiResponse<LogicalPortfolioLayer[]> {
    const portfolioAction: ApiResponse<Portfolio>[] = []
    if (ids.length === 0) {
      return of({ data: [] as LogicalPortfolioLayer[] })
    }
    for (const id of ids) {
      portfolioAction.push(this.service.fetchPortfolio(id))
    }
    return forkJoin(portfolioAction).pipe(
      map(portfolioResponses => {
        for (const portfolioResponse of portfolioResponses) {
          if (portfolioResponse.error) {
            return { error: portfolioResponse.error }
          }
        }
        return {
          data: uniqBy(
            l => l.id,
            chain(
              p => p.data!.layers as LogicalPortfolioLayer[],
              portfolioResponses
            )
          ),
        }
      })
    )
  }
}
