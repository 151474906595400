import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromAnimatedScenariosActions from './animated-scenarios.actions'
import * as fromAnimatedSceneariosSelectors from './animated-scenarios.selectors'
import * as fromScenarioEventResultActions from './scenario-event-result.actions'
import { withLatestFrom, map, concatMap, mergeMap } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../core/store'
import { AnimatedScenariosService } from '../../../api/animated-scenarios/animated-scenarios.service'
import { rejectErrorWithInput, mergeMapWithInput } from '../../../api/util'
import { selectCurrentAnalysisProfile } from '../../../core/store/broker/broker.selectors'
import { LogicalPortfolioLayer } from '../../../api/analyzere/analyzere.model'
import { ApiResponse } from '../../../api/model/api.model'
import { Program } from '../../../core/model/program.model'
import { PortfoliosIDAndName } from '../../model/portfolio-set.model'
import { of } from 'rxjs'
import { isLayerActualRisk } from '../../model/layers.util'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { selectLossSetLayers } from '../../store/analysis.selectors'

// tslint:disable: no-non-null-assertion
@Injectable()
export class AnimatedScenariosEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private animatedScenarioService: AnimatedScenariosService,
    private analyzereService: AnalyzreService
  ) {}

  execute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnimatedScenariosActions.execute),
      withLatestFrom(
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectAllScenarioEvents)
        ),
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectSelectedStructure)
        ),
        this.store.pipe(select(selectCurrentAnalysisProfile)),
        this.store.pipe(select(selectLossSetLayers))
      ),
      mergeMapWithInput(([_, events, structure, __, lossSetLayers]) => {
        const eventCatalogDataID =
          this.animatedScenarioService.getLossSetDataIdWithMinSize(
            lossSetLayers
          )
        return this.animatedScenarioService
          .getEventIds(events.length, eventCatalogDataID)
          .pipe(
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error })
              } else {
                const eventIDs = response.data!
                return this.animatedScenarioService
                  .cloneStructureWithSharedLimit(
                    structure!.id,
                    `${
                      structure!.label
                    } - Loss Scenario - ${new Date().getTime()}`,
                    structure!.description ? structure!.description : '',
                    {
                      scenarioEvents: events,
                      eventIDs,
                    }
                  )
                  .pipe(
                    map(r => {
                      if (!r.error && r.data) {
                        return {
                          ...r,
                          data: {
                            ...r.data,
                            eventIDs,
                          },
                        }
                      } else {
                        return { ...r }
                      }
                    })
                  ) as ApiResponse<{
                  program: Program
                  cededLayers: LogicalPortfolioLayer[]
                  portfolioIDs: PortfoliosIDAndName
                  eventIDs: number[]
                }>
              }
            })
          )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      mergeMapWithInput(
        ([{ cededLayers, portfolioIDs, eventIDs }, [_, events, structure]]) => {
          const riskXOL = cededLayers.filter(isLayerActualRisk)
          if (riskXOL.length > 0) {
            const visibleRiskXOL = riskXOL.map(
              l => l.meta_data.riskVisibleLayerID as string
            )
            return this.analyzereService
              .fetchLayers<LogicalPortfolioLayer>(visibleRiskXOL)
              .pipe(
                mergeMap(r => {
                  if (r.error) {
                    return of({ error: r.error })
                  } else {
                    return this.animatedScenarioService.processScenarioEvents(
                      [...cededLayers, ...r.data!],
                      portfolioIDs,
                      structure!.analysisID,
                      eventIDs,
                      events
                    )
                  }
                })
              )
          } else {
            return this.animatedScenarioService.processScenarioEvents(
              cededLayers,
              portfolioIDs,
              structure!.analysisID,
              eventIDs,
              events
            )
          }
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      concatMap(([eventResults, [{ program }]]) => {
        return [
          fromAnimatedScenariosActions.executeSuccess(),
          fromScenarioEventResultActions.addAllScenarioEventResults({
            eventResults,
            scenarioStructure: program,
          }),
        ]
      })
    )
  )
}
