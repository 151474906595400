import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector:
    // tslint:disable-next-line
    'app-header-collapse, h1[appHeaderCollapse], h2[appHeaderCollapse], h3[appHeaderCollapse], h4[appHeaderCollapse], h5[appHeaderCollapse]',
  styles: [
    `
           :host a {
             font-family: inherit;
             font-size: inherit;
             font-weight: inherit;
             color: inherit;
             background: inherit;
     
             width: 100%;
           }
     
           mat-icon {
             color: var(--primary);
             transition: color 250ms;
           }
     
           :host a:hover mat-icon,
           :host a:focus mat-icon {
             color: var(--primary-lit);
           }
         `,
  ],
  template: `
    <a appButton link (click)="collapseChange.emit(!collapse)">
      <ng-content></ng-content>

      <ng-template #rightTemplate>
        <ng-container
          *ngIf="
            rightButtonTemplate && !useDefaultRightButton;
            else defaultRightTemplate
          "
          [ngTemplateOutlet]="rightButtonTemplate"
        ></ng-container>

        <ng-template #defaultRightTemplate>
          <mat-icon>{{ icon }}</mat-icon>
        </ng-template>
      </ng-template>
    </a>
  `,
})
export class HeaderCollapseComponent {
  @Input() set collapse(value: any) {
    this._collapse = coerceBooleanProperty(value)
  }
  get collapse() {
    return this._collapse
  }
  @HostBinding('class.collapse') _collapse = false

  @Input() set useDefaultRightButton(value: any) {
    this._useDefaultRightButton = coerceBooleanProperty(value)
  }
  get useDefaultRightButton() {
    return this._useDefaultRightButton
  }
  _useDefaultRightButton = false

  @Output() collapseChange = new EventEmitter<boolean>()

  @ContentChild('rightButtonTemplate') rightButtonTemplate: TemplateRef<any>

  get icon() {
    return this.collapse ? 'expand_more' : 'expand_less'
  }
}
