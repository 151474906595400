import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  ViewChild,
  Input,
} from '@angular/core'
import { MatInput } from '@angular/material/input'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-card-curve-table',
  styleUrls: ['./pricing-curve-card-curve-table.component.scss'],
  templateUrl: './pricing-curve-card-curve-table.component.html',
})
export class PricingCurveCardCurveTableComponent implements OnChanges {
  @Input() pricingCurve: PricingCurve
  @Input() yIntercept: number
  @Input() slope: number

  tableData: {
    elPercent: number
    trolPercent: number
    elm: number
  }[]
  numberOfRows = 6
  inputElPercent: number
  displayedColumns = ['elPercent', 'trolPercent', 'elm']
  currentUserInput: number

  private userInput: MatInput

  @ViewChild(MatInput, { static: false }) set userInputComponent(
    userInput: MatInput
  ) {
    if (userInput) {
      this.userInput = userInput
    }
  }

  ngOnChanges(): void {
    this.updateTable()
  }

  updateTable(newUserValue?: number) {
    this.tableData = []

    if (!this.pricingCurve) {
      return
    }

    let startingValue = 0.025
    if (newUserValue) {
      startingValue = newUserValue - 0.025 < 0 ? 0 : newUserValue - 0.025
    }

    let elPercent = startingValue
    for (let i = 0; i < this.numberOfRows; i++) {
      if (i === 1) {
        if (!newUserValue) {
          this.currentUserInput = elPercent
        } else {
          elPercent = newUserValue
        }
      }
      const a = this.yIntercept
      const b = this.slope
      const trolPercent = a * Math.pow(elPercent, b)
      this.tableData.push({
        elPercent,
        trolPercent,
        elm: trolPercent / elPercent,
      })
      elPercent += 0.025
    }
  }

  updateUserInput(newValue: number) {
    this.currentUserInput = newValue
    this.tableData[1].elPercent = this.currentUserInput
  }

  userInputHandleBlur(event?: KeyboardEvent): void {
    if (event && event.key !== 'Enter') {
      return
    }

    const inputValue = this.userInput.value
    const parsedInputValue = Number(inputValue.replace('%', ''))
    if (isNaN(parsedInputValue) || inputValue.length === 0) {
      this.userInput.value = `${(this.currentUserInput * 100).toFixed(2)}%`
      return
    }

    this.updateUserInput(Number(inputValue.replace('%', '')) / 100)
    this.updateTable(this.currentUserInput)
  }

  togglePercentOnFocus(): void {
    const inputValue = this.userInput.value
    if (inputValue && inputValue.includes('%')) {
      this.userInput.value = inputValue.replace('%', '')
    }
  }

  formatDecimal(value: number): string {
    return `${(value * 100).toFixed(2)}%`
  }

  get inputValue() {
    return this.tableData[1].elPercent
  }
}
