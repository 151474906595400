<ng-container *ngIf="!error; else errorTemplate">
    <ng-container *ngIf="!candidateLayersMetricsLoading; else loadingTemplate">
        <div class="container">
            <div [ngClass]="{
              top: resultsView === 'table',
              'top-end': resultsView === 'chart'
            }">
                <label appInfoText hint small [hidden]="resultsView === 'chart'">
                    Currency values are shown in $ thousands
                </label>
                <div class="right">
                    <button appButton class="popover-button margin-rt" (click)="onViewChange()">
                        <span><mat-icon inline>{{ viewIcon }}</mat-icon>
                            {{ viewLabel }}</span>
                    </button>
                    <div [hidden]="resultsView === 'chart'">
                        <mat-paginator class="paginator" #paginator showFirstLastButtons pageSize="15"></mat-paginator>
                    </div>
                </div>
            </div>
            <div class="table" *ngIf="resultsView === 'table'">
                <app-sort-table [columnDefs]="columnDefs" [rows]="rows" [emptyMessage]="'No Metrics Found'"
                    [paginator]="paginator" (checkboxChanges)="onCheckboxChanges($event)">
                    <ng-template name="portfolioTailMetrics" let-col>
                        <button appButton class="popover-button" [matTooltip]="portfolioTailMetricsTooltip"
                            matTooltipClass="optimization-tail-metrics" matTooltipPosition="above"
                            [matMenuTriggerFor]="popoverMenu">
                            <span>{{ col.label }}</span>
                        </button>
                        <mat-menu panelClass="app-menu-translucent" backdropClass="app-theme-dark" class="rp-class"
                            #popoverMenu="matMenu" yPosition="above" xPosition="before"
                            class="optimization-tail-metrics-menu">
                            <app-optimization-portfolio-tail-metrics [returnPeriodToggle]="returnPeriodToggle"
                                [lossFilters]="lossFilters" (click)="$event.preventDefault(); $event.stopPropagation()"
                                (returnPeriodToggleChange)="
                      onPortfolioReturnPeriodToggleChange($event)
                    "></app-optimization-portfolio-tail-metrics>
                        </mat-menu>
                    </ng-template>
                </app-sort-table>
            </div>
            <div class="chart" *ngIf="resultsView === 'chart'">
                <app-layer-modeling *ngIf="resultsView === 'chart' && chartView"
                    [loading]="candidateLayersMetricsLoading" [props]="props" [data]="chartView.data"
                    [secondaryLines]="chartView.secondaryLines" [colors]="chartView.colors" [state]="chartState"
                    (dimensionChange)="dimensionChange.emit($event)"></app-layer-modeling>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #loadingTemplate>
    <label appInfoText big class="loading">Loading...</label>
</ng-template>
<ng-template #errorTemplate>
    <label appInfoText class="error" warn big>{{ error }}</label>
</ng-template>
