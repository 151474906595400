import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { NavService } from '../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-route',
  template: ` <app-quote-container></app-quote-container> `,
})
export class QuoteRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  constructor(private route: ActivatedRoute, private nav: NavService) {}

  ngOnInit(): void {
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
