<app-theme-container>
  <app-portfolio
    [id]="portfolioSetID$ | async"
    [tierNames]="tierNames$ | async"
    [metrics]="portfolioMetrics$ | async"
    [activeAction]="activeAction$ | async"
    [dirty]="dirty$ | async"
    [layers]="layers$ | async"
    [portfolioMetricsLoading]="portfolioMetricsLoading$ | async"
    [selectedViewID]="selectedViewID$ | async"
    [rss]="rss$ | async"
    [portfolioMetricsOpen]="portfolioMetricsOpen$ | async"
    [layersPanelOpen]="layersPanelOpen$ | async"
    [propertiesPanelOpen]="propertiesPanelOpen$ | async"
    [showAgg]="showAgg$ | async"
    [showOcc]="showOcc$ | async"
    [showZoom]="showZoom$ | async"
    [currentProgram]="currentProgram$ | async"
    [currentProgramStructureNames]="currentProgramStructureNames$ | async"
    [authState]="authState$ | async"
    [reinsurers]="reinsurers$ | async"
    [showingTowerTab]="showingTowerTab$ | async"
    [submitMarketPermission]="submitMarketPermission$ | async"
    [layerData]="layerData$ | async"
    [selectedClientID]="selectedClientID$ | async"
    [selectedYearID]="selectedYearID$ | async"
    [marketBlob]="marketBlob$ | async"
    [sharedIDPortfolio]="sharedIDPortfolio$ | async"
    [showingAnimatedScenariosTab]="showingAnimatedScenariosTab$ | async"
    [showingExploreGrossTab]="showingExploreGrossTab$ | async"
    [showLossSetGroupEditor]="showLossSetGroupEditor$ | async"
    [lossFilters]="currentLossFilters$ | async"
    [scenarios]="scenarios$ | async"
    [optimizations]="optimizations$ | async"
    [groupEditSelectedLossSets]="groupEditSelectedLossSets$ | async"
    [lossSetGroupEditMode]="lossSetGroupEditMode$ | async"
    [lossSetGroups]="lossSetGroups$ | async"
    [lossSetGroupsMetrics]="lossSetGroupsMetrics$ | async"
    [lossSetSelectedGroup]="lossSetSelectedGroup$ | async"
    [lossSetSelectionMode]="lossSetSelectionMode$ | async"
    [lossSetGroupsActiveAction]="lossSetGroupsActiveAction$ | async"
    [currentCededLayer]="currentCededLayer$ | async"
    [collapseBySection]="collapseBySection$ | async"
    [parentLossSets]="parentLossSets$ | async"
    [grossLossSets]="grossLossSets$ | async"
    [layerViewIDs]="layerViewIDs$ | async"
    [grossPortfolioViewID]="grossPortfolioViewID$ | async"
    [currencyList]="currencyList$ | async"
    [filteredCurrencyList]="currencyList$ | async"
    [hideTechPremiumTab]="hideTechPremiumTab$ | async"
    [showingTechPremiumTab]="showingTechPremiumTab$ | async"
    [currentCurrency]="currentCurrency"
    [canSaveTechnicalPremium]="canSaveTechnicalPremium$ | async"
    [selectedProgramID]="selectedProgramID$ | async"
    [studies]="studies$ | async"
    (returnPeriod1Change)="onReturnPeriod1Change($event)"
    (returnPeriod2Change)="onReturnPeriod2Change($event)"
    (returnPeriod3Change)="onReturnPeriod3Change($event)"
    (returnPeriod4Change)="onReturnPeriod4Change($event)"
    (returnPeriod5Change)="onReturnPeriod5Change($event)"
    (portfolioTypeChange)="onPortfolioTypeChange($event)"
    (aggregationMethodChange)="onAggregationMethodChange($event)"
    (perspectiveChange)="onPerspectiveChange($event)"
    (lossFilterClick)="onLossFilterClick($event)"
    (resetClick)="onResetClick()"
    (saveClick)="onSaveClick($event)"
    (structureNameEdit)="onStructureNameEdit($event)"
    (showDetails)="onShowDetails($event)"
    (showExchange)="onShowExchange()"
    (submitMarketClick)="onSubmitMarketClick($event)"
    (showProperties)="onShowProperties()"
    (togglePortfolioMetricsClick)="onTogglePortfolioMetricsClick()"
    (toggleLayersPanelClick)="onToggleLayersPanelClick()"
    (togglePropertiesPanelClick)="onTogglePropertiesPanelClick()"
    (saveAsClick)="onSaveAsClick($event)"
    (showAggChange)="onShowAggChange($event)"
    (showOccChange)="onShowOccChange($event)"
    (showZoomChange)="onShowZoomChange($event)"
    (towerTabSelected)="onTowerTabSelected()"
    (backClick)="onBackClick()"
    (scenarioOrOptimizationClick)="onScenarioOrOptimizationClick($event)"
    (grossSelectionModeClick)="onGrossSelectionModeClick()"
    (cededSelectionModeClick)="onCededSelectionModeClick()"
    (newLossSetGroupClick)="onNewLossSetGroupClick()"
    (cancelLossSetGroupClick)="onCancelLossSetGroupClick()"
    (saveLossSetGroupClick)="onSaveLossSetGroupClick($event)"
    (updateLossSetGroupClick)="onUpdateLossSetGroupClick($event)"
    (lossSetGroupEditorClick)="onLossSetGroupEditorClick($event)"
    (groupEditLossSetSelection)="onGroupEditLossSetSelection($event)"
    (lossSetGroupClick)="onLossSetGroupClick($event)"
    (deleteLossSetGroupClick)="onDeleteLossSetGroupClick($event)"
    (lossSetSelection)="onLossSetSelection($event)"
    (grossLossSetSelection)="onGrossLossSetSelection($event)"
    (lossSetGroupSetDirty)="onLossSetGroupSetDirty($event)"
    (removeRiskLossSets)="onRemoveRiskLossSets($event)"
    (addRiskLossSets)="onAddRiskLossSets($event)"
    (collapseChange)="onCollapseChange($event)"
    (optimizeClick)="onOptimizeClick()"
    (structureCurrency)="structureCurrency = $event.trim()"
    (openAddInuranceDialog)="onOpenAddInuranceDialogClick($event)"
    (updatePhysicalLayer)="onUpdatePhysicalLayer($event)"
    (updateLayer)="onUpdateLayer($event)"
  ></app-portfolio>
</app-theme-container>
