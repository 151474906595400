import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { OptimizationPortfolioTailMetricsPayload } from '../optimization.model'

export const updateAndFetchPortfolioTailMetrics = createAction(
  '[Optimization] Update and Fetch Portfolio Tail Metrics',
  props<OptimizationPortfolioTailMetricsPayload>()
)

export const updateAndFetchPortfolioTailMetricsSuccess = createAction(
  '[Optimization] Update and Fetch Portfolio Tail Metrics Success',
  props<{
    id: string
    metrics: { var: number; tvar: number; efficiencyScore: number }
  }>()
)

export const updateAndFetchPortfolioTailMetricsFailure = createAction(
  '[Optimization] Update and Fetch Portfolio Tail Metrics Failure',
  props<{ id: string; error: ApplicationError }>()
)
