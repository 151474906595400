import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromReinsurersActions from './reinsurer.actions'
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { QuoteReinsurerService } from '../../../api/quote-reinsurer/quote-reinsurer.service'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from '../../../api/util'
import { Action, select, Store } from '@ngrx/store'
import { AppState } from '../../../core/store'
import {
  selectedReinsurer,
  selecthasAutoBuildLayerDataChanged,
  selectQuoteSectionID,
  selectQuoteSharedLimitID,
  selectQuoteSharedLimitlayers,
  selectQuoteSharedLimitPortfolioID,
  selectQuoteStructureGroupID,
  selectReinsurers,
  selectSections,
  selectSectionState,
} from '../quote.selectors'
import { forkJoin, of } from 'rxjs'
import {
  QUOTE_TEMP_PREFIX,
  QuoteReinsurer,
  ReinsurerPhases,
  ReinsurerSubjectivity,
  SUBJECTIVITY_TEMP_PREFIX,
  SaveQuoteReinsurerResponse,
  Section,
} from '../../models/quote.model'
import {
  selectAllGrouperProgramCededLayerStates,
  selectCededDictionary,
  selectCededLayers,
  selectCededPortfolioViewLayersViewIDs,
  selectCurrentAnalysisProfileID,
  selectCurrentProgram,
  selectEditorPortfolioSetID,
  selectGrouperAnalysisProfileID,
  selectGrouperPortfolioSetID,
} from '../../../analysis/store/analysis.selectors'
import {
  asMonetaryUnit,
  LogicalPortfolioLayer,
  Metadata,
  MonetaryUnit,
  Reinstatement,
} from '../../../api/analyzere/analyzere.model'
import { LayerState } from '../../../analysis/store/ceded-layers/layers.reducer'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { addCededLayerView } from '../../../analysis/store/views/portfolio-view.actions'
import { Layer } from '../../../analysis/model/layers.model'
import {
  calculateLayerViewMetrics,
  getLayerTypeLimit,
} from '../../../analysis/store/metrics/calculations'
import {
  convertFromLogicalPortfolioLayers,
  mapLayerTypeToLayerCategory,
} from '../../../analysis/model/layers.converter'
import {
  AggLayers,
  layerIds,
} from '../../../analysis/model/layer-palette.model'
import {
  loadingSuccess,
  openLayerDataChangedDialog,
  setFotAndQuoteCount,
} from '../quote.actions'
import {
  exportToggleSection,
  saveQuoteSection,
} from '../section/section.actions'
import { selectCurrentStructure } from 'src/app/core/store/program/program.selectors'
import { selectProgramGroups } from 'src/app/core/store/program-group/program-group.selectors'
import { updateProgramGroup } from 'src/app/analysis/store/grouper/program-group/program-group.actions'
import { selectSharedLimits } from 'src/app/core/store/auth/auth.selectors'
import { updateSL } from 'src/app/analysis/store/grouper/shared-limit/grouper-shared-limit.actions'
import { ReinsurerState } from './reinsurer.reducer'
import { SectionState } from '../section/section.reducer'
import { Program } from 'src/app/core/model/program.model'
import {
  ProgramGroup,
  SharedLimit,
} from 'src/app/analysis/store/grouper/program-group.model'
import { saveQuickQuote } from '../quick-quote/quick-quote.action'
import { UtilService } from 'src/app/util.service'

export interface PatchLayer {
  id: string
  xcoord: number
  ycoord: number
  data: { id: string }
  error: string
}

export interface PatchLayer {
  id: string
  xcoord: number
  ycoord: number
  data: { id: string }
  error: string
}

@Injectable()
export class ReinsurersEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: QuoteReinsurerService,
    private analyzReService: AnalyzreService,
    private utilService: UtilService
  ) {}

  addNewReinsurer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.addOrUpdateQuoteReinsurer),
      withLatestFrom(
        this.store.pipe(select(selecthasAutoBuildLayerDataChanged))
      ),
      concatMap(([props, hasAutoBuildLayerDataChanged]) => {
        const actions: Action[] = []
        actions.push(
          fromReinsurersActions.addOrUpdateQuoteReinsurerSuccess({
            reinsurer: props.reinsurer,
            isSection: props.isSection,
            isQQSave: props.isQQSave,
          })
        )
        if (props.isLinkExpiring || props.isLastAutoBuildSection) {
          actions.push(loadingSuccess())
        }
        if (props.isLastAutoBuildSection && hasAutoBuildLayerDataChanged) {
          actions.push(openLayerDataChangedDialog())
        }
        return actions
      })
    )
  })

  addOrUpdateQuoteReinsurerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.addOrUpdateQuoteReinsurerSuccess),
      map(props => {
        if (props.isQQSave) {
          return saveQuickQuote()
        } else {
          return { type: 'No Action' }
        }
      })
    )
  })

  saveLayerClassPerSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.saveLayerClassPerSection),
      withLatestFrom(this.store.pipe(select(selectSectionState))),
      concatMap(([_, sections]) => {
        const actions: Action[] = []
        const activeSections = Object.entries(sections.entities)
          .map(
            // tslint:disable-next-line: no-non-null-assertion
            ([, value]) => value?.section!
          )
          .filter(
            sec => !sec.layerClassToggle && !sec.layerName.startsWith('Section')
          )
        if (activeSections.length) {
          const newSectionsState = activeSections.map(section => ({
            ...section,
            layerClassToggle: !section.layerName.startsWith('Section'),
          }))
          actions.push(
            exportToggleSection({ sections: newSectionsState }),
            ...newSectionsState.map(section => saveQuoteSection({ section }))
          )
        }
        return actions
      })
    )
  })

  saveReinsurer$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromReinsurersActions.saveQuoteReinsurer),
        concatMapWithInput(action => {
          if (action.reinsurer.id?.startsWith(QUOTE_TEMP_PREFIX)) {
            return this.service.postRiskReinsurers(action.reinsurer)
          } else {
            return this.service.putRiskReinsurers(action.reinsurer)
          }
        }),
        rejectErrorWithInput(error => {
          this.utilService.spinnerCounter = this.utilService.spinnerCounter - 1
          if (this.utilService.spinnerCounter == 0) {
            this.utilService.spinnerHide()
          }
          return this.store.dispatch(
            fromReinsurersActions.saveQuoteReinsurerFailure({ error })
          )
        }),
        concatMap(([reinsurer, props]) => {
          return this.analyzReService.fetchLayer(reinsurer.cededlayerID!).pipe(
            map(res => {
              const currentLayer = convertFromLogicalPortfolioLayers([
                res.data as LogicalPortfolioLayer,
              ])[0]
              return {
                currentLayer,
                reinsurer,
                props,
              }
            })
          )
        }),
        withLatestFrom(
          this.store.pipe(select(selectCededLayers)),
          this.store.pipe(select(selectAllGrouperProgramCededLayerStates)),
          this.store.pipe(select(selectQuoteSharedLimitlayers))
        ),
        concatMap(
          ([
            { props, reinsurer, currentLayer },
            cededLayers,
            groupCededLayers,
            slLayers,
          ]) => {
            const slLayerState: LayerState[] = []
            if (slLayers && slLayers.length > 0) {
              slLayers?.forEach(sl => {
                const state = {
                  layer: sl,
                  hash: '',
                  dirty: false,
                  new: false,
                  deleted: false,
                }
                slLayerState.push(state)
              })
            }
            const mergeLayers = [
              ...cededLayers,
              ...groupCededLayers,
              ...slLayerState,
            ]
            const reinsurerLayer: LayerState[] = mergeLayers.filter(
              c => c.layer.id === reinsurer.cededlayerID
            )
            const rein: Reinstatement[] = []
            reinsurer.quoteFields?.quoteReinstatements?.forEach(re => {
              const r: Reinstatement = {
                id: re.id,
                premium: re.premium ? re.premium : 0,
                brokerage: re.brokerage ? re.brokerage : 0,
              }
              rein.push(r)
            })

            const defaultMonetary: MonetaryUnit = {
              value: 0,
              currency:
                reinsurerLayer[0].layer.physicalLayer.franchise.currency ||
                'USD',
              value_date: null,
              rate: null,
              rate_currency: null,
            }

            if (reinsurer.newCededLayerForViewID) {
              return this.analyzReService
                .fetchLayer(reinsurer.newCededLayerForViewID)
                .pipe(
                  concatMap(res => {
                    const fetchLayer = convertFromLogicalPortfolioLayers([
                      res.data as LogicalPortfolioLayer,
                    ])[0]
                    const toSaveLayer: Layer = {
                      ...fetchLayer,
                      lossSetLayers:
                        currentLayer &&
                        currentLayer.lossSetLayers.length > 0 &&
                        fetchLayer.id === currentLayer.id
                          ? currentLayer.lossSetLayers
                          : fetchLayer.lossSetLayers,
                      physicalLayer: {
                        ...fetchLayer.physicalLayer,
                        attachment: reinsurer.quoteFields
                          ?.quoteOccurrenceAttachment
                          ? reinsurer.quoteFields?.quoteOccurrenceAttachment
                          : defaultMonetary,
                        limit: reinsurer.quoteFields?.quoteOccurrenceLimit
                          ? reinsurer.quoteFields?.quoteOccurrenceLimit
                          : defaultMonetary,
                        premium: reinsurer.quoteFields?.quotePremium
                          ? reinsurer.quoteFields?.quotePremium
                          : defaultMonetary,
                        aggregateLimit: reinsurer.quoteFields
                          ?.quoteAggregateLimit
                          ? reinsurer.quoteFields?.quoteAggregateLimit
                          : defaultMonetary,
                        aggregateAttachment: reinsurer.quoteFields
                          ?.quoteAggregateAttachment
                          ? reinsurer.quoteFields?.quoteAggregateAttachment
                          : defaultMonetary,
                        reinstatements: rein,
                        participation: -1, // Cession Percentage should be 100% always when saving quotes (AB#16647)
                      },
                    }

                    return this.analyzReService.saveLayers([toSaveLayer]).pipe(
                      map(response => {
                        const newLayerResponse = {
                          xcoord: null,
                          ycoord: null,
                          data: null,
                          error: null,
                        } as PatchLayer
                        const data = {
                          newLayerResponse,
                          patchLayerResponse: response,
                          reinsurer,
                          props,
                        }

                        return data
                      })
                    )
                  })
                )
            } else {
              // tslint:disable
              let newLayer: Layer = {
                id: reinsurerLayer[0]?.layer.id!,
                meta_data: reinsurerLayer[0]?.layer.meta_data!,
                layerRefs: reinsurerLayer[0]?.layer.layerRefs!,
                lossSetFilter: reinsurerLayer[0]?.layer.lossSetFilter!,
                lossSetLayers:
                  currentLayer &&
                  currentLayer.lossSetLayers.length > 0 &&
                  reinsurerLayer[0]?.layer.id === currentLayer.id
                    ? currentLayer.lossSetLayers
                    : reinsurerLayer[0]?.layer.lossSetLayers!,
                sharedLayerID: reinsurerLayer[0]?.layer.sharedLayerID!,
                viewMetrics: reinsurerLayer[0]?.layer.viewMetrics!,
                physicalLayer: {
                  logicalLayerID: reinsurerLayer[0]?.layer.id!,
                  id: reinsurerLayer[0]?.layer.id!,

                  type: reinsurerLayer[0].layer.physicalLayer.type!,
                  attachment: {
                    value: reinsurer.quoteFields?.quoteOccurrenceAttachment
                      ?.value
                      ? reinsurer.quoteFields?.quoteOccurrenceAttachment.value
                      : 0,
                    currency:
                      reinsurerLayer[0]?.layer.physicalLayer.attachment
                        .currency!,
                  },
                  limit: reinsurer.quoteFields?.quoteOccurrenceLimit
                    ? reinsurer.quoteFields?.quoteOccurrenceLimit
                    : defaultMonetary,
                  participation: -1, // Cession Percentage should be 100% always when saving quotes (AB#16647)
                  premium: {
                    value: reinsurer.quoteFields?.quotePremium?.value
                      ? reinsurer.quoteFields?.quotePremium.value
                      : 0,
                    currency:
                      reinsurerLayer[0]?.layer.physicalLayer.premium.currency!,
                  },
                  fees: reinsurerLayer[0].layer.physicalLayer.fees!,
                  aggregateLimit: {
                    value: reinsurer.quoteFields?.quoteAggregateLimit?.value
                      ? reinsurer.quoteFields?.quoteAggregateLimit.value
                      : 0,
                    currency:
                      reinsurerLayer[0]?.layer.physicalLayer.aggregateLimit
                        .currency!,
                  },
                  aggregateAttachment: {
                    value: reinsurer.quoteFields?.quoteAggregateAttachment
                      ?.value
                      ? reinsurer.quoteFields?.quoteAggregateAttachment.value
                      : 0,
                    currency:
                      reinsurerLayer[0]?.layer.physicalLayer.aggregateAttachment
                        .currency!,
                  },
                  description:
                    reinsurerLayer[0].layer.physicalLayer.description,
                  meta_data: {
                    client: reinsurerLayer[0].layer.meta_data.client,
                    perspective:
                      reinsurerLayer[0].layer.physicalLayer.meta_data
                        .perspective,
                    program_name:
                      reinsurerLayer[0].layer.physicalLayer.meta_data
                        .program_name,
                    program_type:
                      reinsurerLayer[0].layer.physicalLayer.meta_data
                        .program_type,
                    rol: reinsurerLayer[0].layer.meta_data.rol,
                    rol_type: reinsurerLayer[0].layer.meta_data.rol_type,
                    sage_layer_type:
                      reinsurerLayer[0].layer.meta_data.sage_layer_type,
                    year: reinsurerLayer[0].layer.meta_data.year,
                    sage_layer_subtype:
                      reinsurerLayer[0].layer.meta_data.sage_layer_subtype,
                  },
                  reinstatements: rein,
                  franchise: {
                    value:
                      reinsurerLayer[0].layer.physicalLayer.franchise.value!,
                    currency:
                      reinsurerLayer[0].layer.physicalLayer.franchise.currency!,
                  },
                  inception_date:
                    reinsurerLayer[0].layer.physicalLayer.inception_date,
                  expiry_date:
                    reinsurerLayer[0].layer.physicalLayer.expiry_date,
                  xcoord: reinsurerLayer[0].layer.physicalLayer.xcoord,
                  ycoord: reinsurerLayer[0].layer.physicalLayer.ycoord,
                },
              }
              if (
                reinsurerLayer[0].layer.meta_data.sage_layer_type ===
                layerIds.ilwBin
              ) {
                newLayer = {
                  ...newLayer,
                  physicalLayer: {
                    ...newLayer.physicalLayer,
                    payout: {
                      value:
                        reinsurerLayer[0].layer.physicalLayer.payout?.value! ||
                        0,
                      currency:
                        reinsurerLayer[0].layer.physicalLayer.payout
                          ?.currency! || 'USD',
                    },
                    trigger: {
                      value:
                        reinsurerLayer[0].layer.physicalLayer.trigger?.value! ||
                        0,
                      currency:
                        reinsurerLayer[0].layer.physicalLayer.trigger
                          ?.currency! || 'USD',
                    },
                    nth: reinsurerLayer[0].layer.physicalLayer.nth,
                  },
                }
              }
              // tslint:enable
              if (reinsurerLayer[0].layer.meta_data.isRiskLargeHidden) {
                return this.analyzReService.createPhysicalLayer(newLayer).pipe(
                  map(res => {
                    const patchLayerResponse = {
                      // @ts-ignore
                      id: null,
                      // @ts-ignore
                      xcoord: null,
                      // @ts-ignore
                      ycoord: null,
                      // @ts-ignore
                      data: null,
                      // @ts-ignore
                      error: null,
                    }
                    const data = {
                      newLayerResponse: {
                        ...res,
                        xcoord: reinsurerLayer[0].layer.physicalLayer.xcoord,
                        ycoord: reinsurerLayer[0].layer.physicalLayer.ycoord,
                      },
                      patchLayerResponse,
                      reinsurer,
                      props,
                    }

                    return data
                  })
                )
              } else {
                return this.analyzReService.createFullLayer(newLayer).pipe(
                  map(res => {
                    const patchLayerResponse = {
                      id: null,
                      xcoord: null,
                      ycoord: null,
                      data: null,
                      error: null,
                    } as PatchLayer
                    const data = {
                      newLayerResponse: {
                        ...res,
                        xcoord: reinsurerLayer[0].layer.physicalLayer.xcoord,
                        ycoord: reinsurerLayer[0].layer.physicalLayer.ycoord,
                      },
                      patchLayerResponse,
                      reinsurer,
                      props,
                    }

                    return data
                  })
                )
              }
            }
            // tslint:disable
          }
        ),
        withLatestFrom(
          this.store.pipe(select(selectCurrentAnalysisProfileID)),
          this.store.pipe(select(selectGrouperAnalysisProfileID)),
          this.store.pipe(select(selectEditorPortfolioSetID)),
          this.store.pipe(select(selectGrouperPortfolioSetID)),
          this.store.pipe(select(selectQuoteSharedLimitPortfolioID))
        )
      )
      .pipe(
        concatMap(
          ([
            { newLayerResponse, patchLayerResponse, reinsurer, props },
            analysisID,
            groupAnalysisID,
            portfolioSetID,
            groupPortfolioID,
            slPortfolioID,
          ]) => {
            if (!reinsurer.newCededLayerForViewID) {
              return (
                this.analyzReService
                  // tslint:disable-next-line: no-non-null-assertion
                  .postLayerView(
                    newLayerResponse.data?.id!,
                    analysisID
                      ? analysisID
                      : groupAnalysisID
                        ? groupAnalysisID
                        : slPortfolioID?.analysisProfileID
                          ? slPortfolioID.analysisProfileID
                          : ''
                  )
                  .pipe(
                    map(res => {
                      const portfolioID = portfolioSetID
                        ? portfolioSetID
                        : groupPortfolioID
                          ? groupPortfolioID
                          : slPortfolioID
                      if (portfolioID && res.data?.id) {
                        this.store.dispatch(
                          addCededLayerView({
                            ...portfolioID,
                            viewID: res.data.id,
                            layerID: res.data.layerID,
                          })
                        )
                      }

                      const data = {
                        layerViewResponse: {
                          ...res,
                        },
                        newLayerResponse,
                        patchLayerResponse,
                        reinsurer,
                        props,
                      }
                      return data
                    })
                  )
              )
            } else {
              return (
                this.analyzReService
                  // tslint:disable-next-line: no-non-null-assertion
                  .postLayerView(
                    reinsurer.newCededLayerForViewID,
                    analysisID
                      ? analysisID
                      : groupAnalysisID
                        ? groupAnalysisID
                        : slPortfolioID?.analysisProfileID
                          ? slPortfolioID.analysisProfileID
                          : ''
                  )
                  .pipe(
                    map(res => {
                      const portfolioID = portfolioSetID
                        ? portfolioSetID
                        : groupPortfolioID
                          ? groupPortfolioID
                          : slPortfolioID
                      if (portfolioID && res.data?.id) {
                        this.store.dispatch(
                          addCededLayerView({
                            ...portfolioID,
                            viewID: res.data.id,
                            layerID: res.data.layerID,
                          })
                        )
                      }

                      const data = {
                        layerViewResponse: {
                          ...res,
                        },
                        newLayerResponse,
                        patchLayerResponse,
                        reinsurer,
                        props,
                      }

                      return data
                    })
                  )
              )
            }
          }
        ),
        concatMap(data => {
          return this.analyzReService
            .fetchLayer(data.layerViewResponse.data?.layerID!)
            .pipe(
              map(response => {
                const layer = convertFromLogicalPortfolioLayers([
                  response.data! as LogicalPortfolioLayer,
                ])[0]

                const limit = getLayerTypeLimit(layer, 'Occ')
                const aggLimit = getLayerTypeLimit(layer, 'Agg')

                return { ...data, layer, limit, aggLimit }
              })
            )
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(selectCededPortfolioViewLayersViewIDs)),
          this.store.pipe(select(selectCededDictionary))
        ),
        concatMap(([data, viewIDs, cededLayersByID]) => {
          return this.analyzReService
            .getLayersViewMetrics(
              data.layerViewResponse.data?.id!,
              data.limit,
              data.aggLimit,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              data.layer.meta_data.sage_layer_type
            )
            .pipe(
              map(res => {
                const viewMetrics = calculateLayerViewMetrics(
                  res.data!,
                  viewIDs,
                  cededLayersByID,
                  data.layer?.physicalLayer,
                  false,
                  undefined,
                  data.layer
                )

                const newData = {
                  ...data,
                  viewMetrics,
                }

                return newData
              })
            )
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(selectCededLayers)),
          this.store.pipe(select(selectAllGrouperProgramCededLayerStates)),
          this.store.pipe(select(selectQuoteSharedLimitlayers)),
          this.store.pipe(select(selectCurrentProgram))
        ),
        concatMap(
          ([data, cededLayers, groupCededLayers, slLayers, currentProgram]) => {
            const slLayerState: LayerState[] = []
            if (slLayers && slLayers.length > 0) {
              slLayers?.forEach(sl => {
                const state = {
                  layer: sl,
                  hash: '',
                  dirty: false,
                  new: false,
                  deleted: false,
                }
                slLayerState.push(state)
              })
            }
            const layerViewMetrics = data.viewMetrics
            const mergeLayers = [
              ...cededLayers,
              ...groupCededLayers,
              ...slLayerState,
            ]
            const reinsurerLayer = mergeLayers.filter(
              layer => layer.layer.id === data.reinsurer.cededlayerID
            )[0].layer

            const isSection =
              data.layer.meta_data.sage_layer_subtype === 'section-layer' ||
              data.layer.meta_data.sage_layer_type === 'drop' ||
              (data.layer.meta_data.sage_layer_type === 'cat_td' &&
                data.layer.meta_data.sage_layer_subtype === 'virtual')

            let newData = {
              ...data,
              change: { ...data.reinsurer.quoteFields },
              isSection,
            }
            let fieldChange = { ...data.reinsurer.quoteFields }

            // If no layer category was selected, default using layer type and agg attach
            if (
              data.layer.meta_data.sage_layer_type &&
              !data.reinsurer.quoteFields?.layerCategory
            ) {
              const layerCategory = mapLayerTypeToLayerCategory(
                data.layer.meta_data.sage_layer_type,
                data.reinsurer.quoteFields
              )
              fieldChange = {
                ...fieldChange,
                layerCategory,
              }

              if (data.props.reinsurer.id) {
                this.store.dispatch(
                  fromReinsurersActions.updateQuoteFields({
                    id: data.props.reinsurer.id,
                    change: fieldChange,
                    isSection,
                  })
                )
              }
              newData = { ...newData, change: fieldChange }
            }

            if (layerViewMetrics) {
              /* if it is a non modeling layer we need to update the Expected ceded loss and premium value only if the user
             updates it manually it otherwise it will be stored as null in database (Shown as N/A in the UI) */

              const isLibre = currentProgram?.libRE === 'Y'
              const currency =
                reinsurerLayer.physicalLayer.franchise.currency || 'USD'
              const quoteFields = data.reinsurer.quoteFields

              let quoteLossOnLine = layerViewMetrics.lossOnLine
              if (
                quoteFields.manualLossOnLine &&
                typeof quoteFields.quoteLossOnLine !== 'undefined'
              ) {
                quoteLossOnLine = quoteFields.quoteLossOnLine
              }

              let quoteExpectedCededPremium: MonetaryUnit
              if (quoteFields.manualExpectedCededPremium) {
                quoteExpectedCededPremium = asMonetaryUnit(
                  quoteFields.quoteExpectedCededPremium.value,
                  currency
                )
              } else {
                quoteExpectedCededPremium = asMonetaryUnit(
                  currentProgram && isLibre
                    ? 0
                    : Math.abs(layerViewMetrics.expectedCededPremium),
                  currency
                )
              }

              let quoteExpectedCededLoss: MonetaryUnit

              if (quoteFields.manualExpectedCededLoss) {
                quoteExpectedCededLoss = asMonetaryUnit(
                  quoteFields.quoteExpectedCededLoss.value,
                  currency
                )
              } else {
                quoteExpectedCededLoss = asMonetaryUnit(
                  currentProgram && isLibre
                    ? 0
                    : Math.abs(layerViewMetrics.purePremium),
                  currency
                )
                quoteLossOnLine =
                  currentProgram && isLibre ? 0 : layerViewMetrics.lossOnLine
              }

              let quoteProbabilityOfAttach = layerViewMetrics.entryProbability
              let quoteProbabilityOfExhaust = AggLayers.includes(
                data.layer.meta_data.sage_layer_type as string
              )
                ? layerViewMetrics.exitAggProbability
                : layerViewMetrics.exitProbability

              if (
                quoteFields.manualProbabilityOfAttach &&
                typeof quoteFields.quoteProbabilityOfAttach !== 'undefined'
              ) {
                quoteProbabilityOfAttach = quoteFields.quoteProbabilityOfAttach
              }

              if (
                quoteFields.manualProbabilityOfExhaust &&
                typeof quoteFields.quoteProbabilityOfExhaust !== 'undefined'
              ) {
                quoteProbabilityOfExhaust =
                  quoteFields.quoteProbabilityOfExhaust
              }

              fieldChange = {
                ...fieldChange,
                quoteExpectedCededPremium,
                quoteExpectedCededLoss,
                quoteProbabilityOfAttach,
                quoteProbabilityOfExhaust,
                quoteLossOnLine,
              }
              if (data.props.reinsurer.id) {
                this.store.dispatch(
                  fromReinsurersActions.updateQuoteFields({
                    id: data.props.reinsurer.id,
                    change: fieldChange,
                    isSection,
                  })
                )
              }
              newData = { ...newData, change: fieldChange }
            }

            return of(newData)
          }
        )
      )
      .pipe(
        concatMap(data => {
          const updatedReinsurer: QuoteReinsurer = {
            ...data.reinsurer,
            quoteFields: {
              ...data.reinsurer.quoteFields,
              ...data.change,
            },
          }
          return this.service.putRiskReinsurers(updatedReinsurer).pipe(
            map(res => {
              return {
                ...data,
                res,
                isSection: data.isSection,
              }
            })
          )
        }),
        concatMap(({ newLayerResponse, reinsurer, props, isSection }) => {
          const actions = []
          let newCededLayerForViewID
          if (!reinsurer.newCededLayerForViewID) {
            newCededLayerForViewID = newLayerResponse.data?.id
          } else {
            newCededLayerForViewID = reinsurer.newCededLayerForViewID
          }
          // @ts-ignore
          const metaData = newLayerResponse.data?.meta_data as
            | Metadata
            | undefined
          if (reinsurer.id) {
            actions.push(
              fromReinsurersActions.saveQuoteReinsurerSuccess({
                id: reinsurer.id,
                // tslint:disable-next-line: no-non-null-assertion
                tempID: props.reinsurer.id!,
                subjectivity: reinsurer.riskSubjectivityLink
                  ? reinsurer.riskSubjectivityLink
                  : [],
                reinstatement: reinsurer.quoteFields?.quoteReinstatements
                  ? reinsurer.quoteFields?.quoteReinstatements
                  : [],
                assignedLines: reinsurer.riskAssignedLinesLink
                  ? reinsurer.riskAssignedLinesLink
                  : [],
                // tslint:disable-next-line: no-non-null-assertion
                newCededLayerForViewID: newCededLayerForViewID!,
                isSection:
                  metaData?.sage_layer_subtype === 'section-layer' || isSection,
              })
            )
          }
          this.utilService.spinnerCounter = this.utilService.spinnerCounter - 1
          if (this.utilService.spinnerCounter == 0) {
            this.utilService.spinnerHide()
          }
          return actions
        })
      )
  })

  saveQuoteReinsurerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.saveQuoteReinsurerSuccess),
      withLatestFrom(
        this.store.select(selectReinsurers),
        this.store.select(selectSections),
        this.store.select(selectCurrentStructure),
        this.store.select(selectProgramGroups),
        this.store.select(selectSharedLimits),
        this.store.select(selectCededLayers)
      ),
      concatMap(
        ([
          action,
          reinsurers,
          sections,
          structure,
          groups,
          sharedLimits,
          cededLayers,
        ]: [
          SaveQuoteReinsurerResponse,
          ReinsurerState[],
          SectionState[],
          Program,
          ProgramGroup[],
          SharedLimit[],
          LayerState[],
        ]) => {
          const nonSectionReinsurers = reinsurers.filter(r => {
            const layer = cededLayers.find(
              l =>
                l.layer.meta_data.visible_layer_id ===
                  r.reinsurer.cededlayerID ||
                l.layer.id === r.reinsurer.cededlayerID
            )?.layer
            if (layer) {
              const metaData = layer.meta_data
              const isSectionLayer =
                (metaData.sage_layer_type === 'cat_multisection' ||
                metaData.sage_layer_type === 'noncat_multisection') &&
                metaData.sage_layer_subtype === 'section-layer'
              const isTopLayer =
                layer.meta_data?.sage_layer_type === layerIds.catTd &&
                layer.meta_data?.sage_layer_subtype === 'virtual'
              const isLayerDrop = layer.meta_data?.sage_layer_type === 'drop'
              if (!isSectionLayer && !isTopLayer && !isLayerDrop) {
                return r
              }
            }
          })
          let reinsurerFound = reinsurers.find(
            r =>
              r.reinsurer.id === action.id || r.reinsurer.id === action.tempID
          )
          let actions = []
          if (reinsurerFound) {
            let fCount =
              nonSectionReinsurers.filter(
                re => re.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
              ).length || 0
            let qCount =
              nonSectionReinsurers.filter(
                re => re.reinsurer.reinsurerPhase === ReinsurerPhases.Quote
              ).length || 0
            if (reinsurerFound.reinsurer.programGroupID) {
              // Update Structure Group
              let groupFound = groups.find(
                gr =>
                  gr.id === reinsurerFound?.reinsurer.programGroupID?.toString()
              )
              if (groupFound) {
                actions.push(
                  updateProgramGroup({
                    group: groupFound,
                    fotCount: fCount,
                    quoteCount: qCount,
                  })
                )
              }
            } else if (reinsurerFound.reinsurer.sharedLimitID) {
              // Update SL
              let slFound = sharedLimits.find(
                sl =>
                  sl.id.toString() ===
                  reinsurerFound?.reinsurer.sharedLimitID?.toString()
              )
              if (slFound) {
                actions.push(
                  updateSL({
                    sharedLimit: {
                      ...slFound,
                      fot_count: fCount,
                      quote_count: qCount,
                    },
                  })
                )
              }
            } else if (reinsurerFound.reinsurer.reinsurerSectionId) {
              // Update Section and Structure
              let section: Section | undefined
              section = sections.find(
                s =>
                  s.section.id ===
                  reinsurerFound?.reinsurer.reinsurerSectionId?.toString()
              )?.section
              if (section) {
                let fSectionCount =
                  reinsurers.filter(
                    re =>
                      re.reinsurer.reinsurerSectionId?.toString() ===
                        section?.id &&
                      re.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
                  ).length || 0
                let qSectionCount =
                  reinsurers.filter(
                    re =>
                      re.reinsurer.reinsurerSectionId?.toString() ===
                        section?.id &&
                      re.reinsurer.reinsurerPhase === ReinsurerPhases.Quote
                  ).length || 0
                actions.push(
                  saveQuoteSection({
                    section: {
                      ...section,
                      fotCount: fSectionCount,
                      quoteCount: qSectionCount,
                    },
                  })
                )
              }
              if (structure) {
                actions.push(
                  setFotAndQuoteCount({
                    structure,
                    fotCount: fCount,
                    quoteCount: qCount,
                  })
                )
              }
            }
          }
          return actions
        }
      )
    )
  })

  deleteReinsurer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.deleteQuoteReinsurer),
      concatMap(action => {
        return this.service.deleteRiskReinsurers(action.id)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurersActions.deleteQuoteReinsurerFailure({ error })
        )
      ),
      map(reinsurer => {
        return fromReinsurersActions.deleteQuoteReinsurerSuccess({
          // tslint:disable-next-line: no-non-null-assertion
          id: reinsurer.id!,
        })
      })
    )
  })

  deleteQuoteReinsurerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.deleteQuoteReinsurerSuccess),
      withLatestFrom(
        this.store.select(selectReinsurers),
        this.store.select(selectSections),
        this.store.select(selectCurrentStructure),
        this.store.select(selectProgramGroups),
        this.store.select(selectSharedLimits)
      ),
      map(data => data),
      withLatestFrom(
        this.store.select(selectQuoteSectionID),
        this.store.select(selectQuoteStructureGroupID),
        this.store.select(selectQuoteSharedLimitID),
        this.store.select(selectCededLayers)
      ),
      concatMap(
        ([
          [_, reinsurers, sections, structure, groups, sharedLimits],
          sectionID,
          groupID,
          slID,
          cededLayers,
        ]) => {
          const nonSectionReinsurers = reinsurers.filter(r => {
            const layer = cededLayers.find(
              l =>
                l.layer.meta_data.visible_layer_id ===
                  r.reinsurer.cededlayerID ||
                l.layer.id === r.reinsurer.cededlayerID
            )?.layer
            if (layer) {
              const metaData = layer.meta_data
              const isSectionLayer =
                (metaData.sage_layer_type === 'cat_multisection' ||
                metaData.sage_layer_type === 'noncat_multisection') &&
                metaData.sage_layer_subtype === 'section-layer'
              const isTopLayer =
                layer.meta_data?.sage_layer_type === layerIds.catTd &&
                layer.meta_data?.sage_layer_subtype === 'virtual'
              const isLayerDrop = layer.meta_data?.sage_layer_type === 'drop'
              if (!isSectionLayer && !isTopLayer && !isLayerDrop) {
                return r
              }
            }
          })
          let actions = []
          let fCount =
            nonSectionReinsurers.filter(
              re => re.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
            ).length || 0
          let qCount =
            nonSectionReinsurers.filter(
              re => re.reinsurer.reinsurerPhase === ReinsurerPhases.Quote
            ).length || 0
          if (groupID) {
            // Update Structure Group
            let groupFound = groups.find(gr => gr.id === groupID)
            if (groupFound) {
              actions.push(
                updateProgramGroup({
                  group: groupFound,
                  fotCount: fCount,
                  quoteCount: qCount,
                })
              )
            }
          } else if (slID) {
            let slFound = sharedLimits.find(
              sl => sl.id.toString() === slID.toString()
            )
            if (slFound) {
              actions.push(
                updateSL({
                  sharedLimit: {
                    ...slFound,
                    fot_count: fCount,
                    quote_count: qCount,
                  },
                })
              )
            }
          } else if (sectionID && structure) {
            let section: Section | undefined
            section = sections.find(
              s => s.section.id === sectionID.toString()
            )?.section
            if (section) {
              let fSectionCount =
                reinsurers.filter(
                  re =>
                    re.reinsurer.reinsurerSectionId?.toString() ===
                      section?.id &&
                    re.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
                ).length || 0
              let qSectionCount =
                reinsurers.filter(
                  re =>
                    re.reinsurer.reinsurerSectionId?.toString() ===
                      section?.id &&
                    re.reinsurer.reinsurerPhase === ReinsurerPhases.Quote
                ).length || 0
              actions.push(
                saveQuoteSection({
                  section: {
                    ...section,
                    fotCount: fSectionCount,
                    quoteCount: qSectionCount,
                  },
                })
              )
            }
            if (structure) {
              actions.push(
                setFotAndQuoteCount({
                  structure,
                  fotCount: fCount,
                  quoteCount: qCount,
                })
              )
            }
          }
          return actions
        }
      )
    )
  })

  deleteReinstatement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.deleteReinstatement),
      switchMap(action => {
        return this.service.deleteRiskReinstatement(action.id)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurersActions.deleteQuoteReinsurerFailure({ error })
        )
      ),
      map(() => {
        return { type: 'No Action' }
      })
    )
  })

  deleteSubjectivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.deleteSubjectivity),
      concatMapWithInput(action => {
        return this.service.deleteLinkReinsurerSubjectivity(
          parseInt(action.reinsurerID, 10),
          parseInt(action.id, 10)
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromReinsurersActions.deleteQuoteReinsurerFailure({ error })
        )
      ),
      switchMapWithInput(data => {
        return this.service.deleteSubjectivity(parseInt(data[1].id, 10))
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromReinsurersActions.deleteQuoteReinsurerFailure({ error })
        )
      ),
      map(data => {
        const ids = data[1][1]
        return fromReinsurersActions.deleteSubjectivitySuccess({
          id: ids.id,
          reinsurerID: ids.reinsurerID,
        })
      })
    )
  })

  saveSubjectivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.saveSubjectivitiesArray),
      withLatestFrom(this.store.pipe(select(selectedReinsurer))),
      concatMap(([action, selectedID]) => {
        const observables = []
        const selectedReID = action.id ? action.id : selectedID
        const subArray: ReinsurerSubjectivity[] = []
        for (const a in action.subjectivity) {
          if (a && action.subjectivity[a].id) {
            const id1 = action.subjectivity[a].id?.toString()
            if (id1?.startsWith(SUBJECTIVITY_TEMP_PREFIX)) {
              observables.push(
                this.service.postSubjectivity(action.subjectivity[a])
              )
            } else {
              observables.push(
                this.service.putSubjectivity(action.subjectivity[a])
              )
            }
          }
        }
        return forkJoin(observables).pipe(
          map(results => {
            for (const result of results) {
              if (result.error) {
                return { error: result.error }
              }
            }
            results.forEach(r => {
              if (r.data) {
                if (!selectedReID) {
                  return
                }
                subArray.push({
                  riskReinsurerId: selectedReID,
                  riskSubjectivityId: r.data.id,
                  riskSubjectivity: r.data,
                })
              }
            })

            return {
              id: action.id,
              subjectivity: subArray,
            }
          })
        )
      }),
      map(data => {
        return fromReinsurersActions.saveSubjectivitiesArraySuccess({
          // tslint:disable-next-line: no-non-null-assertion
          id: data.id!,
          // tslint:disable-next-line: no-non-null-assertion
          subjectivity: data.subjectivity!,
          bypassFOT: true,
        })
      })
    )
  })

  linkReinsurerSubjectivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.saveSubjectivitiesArraySuccess),
      withLatestFrom(this.store.pipe(select(selectedReinsurer))),
      switchMap(([action, selectedID]) => {
        const observables = []
        for (const a in action.subjectivity) {
          if (a) {
            if (
              action.subjectivity[a].riskSubjectivityId &&
              action.subjectivity[a].riskSubjectivityId !== null
            ) {
              let reID = selectedID
              if (selectedID !== action.id && action.id) {
                reID = action.id
              }
              if (!reID) {
                continue
              }
              observables.push(
                this.service.postLinkReinsurerSubjectivity({
                  riskReinsurerId: reID,
                  riskSubjectivityId: action.subjectivity[a].riskSubjectivityId,
                })
              )
            }
          }
        }
        return forkJoin(observables).pipe(
          map(results => {
            for (const result of results) {
              if (result.error) {
                return { error: result.error }
              }
            }
          })
        )
      }),
      map(() => {
        return { type: 'No Action' }
      })
    )
  })

  deleteAssignedLines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.deleteAssignedLines),
      switchMap(action => {
        return this.service.deleteAssignedLines(parseInt(action.id, 10))
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurersActions.deleteQuoteReinsurerFailure({ error })
        )
      ),
      map(() => {
        return { type: 'No Action' }
      })
    )
  })

  fetchSubjectivitiesByReinsurer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.fetchSubjectivitiesByReinsurer),
      switchMap(action => {
        return this.service.fetchSubjectivitiesByReinsurer(String(action.tpRef))
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurersActions.fetchSubjectivitiesByReinsurerFailure({ error })
        )
      ),
      map(subjectivities => {
        return fromReinsurersActions.fetchSubjectivitiesByReinsurerSuccess({
          subjectivities,
        })
      })
    )
  })
}
