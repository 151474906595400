import {
  CompareMetricSetting,
  CompareMetricValue,
} from '../analysis/model/compare-metrics.model'
import { buildMetricHash } from '../analysis/store/metrics/metrics-cart/create-metric-table-categories'

// Update this function to hide specific metrics from UI
export const hideMetric = (
  metric: CompareMetricValue | CompareMetricSetting | any
): boolean => {
  return (
    (metric.category === 'Net Results' && metric.label === 'Deposit Premium') ||
    (metric.category === 'Gross Metrics' &&
      metric.label === 'Deposit Premium') ||
    ((metric.category === 'Custom Metrics' || metric.category === 'Tail Metrics')  && metric.deleted)
  )
}

export const validateFormula = (
  settings: CompareMetricSetting[],
  isCompare: boolean
): CompareMetricSetting[] => {
  const customSettings = settings.filter(s => s.category === 'Custom Metrics')
  const nonCustomSettings = settings.filter(
    s => s.category !== 'Custom Metrics'
  )
  const updatedCustomSettings = customSettings.map(cs => {
    // tslint:disable-next-line: no-non-null-assertion
    let formula = cs.formula!
    nonCustomSettings.forEach(ncs => {
      if (formula.includes(ncs.label)) {
        formula = formula.replace(`[${ncs.category}:${ncs.label}]`, '')
      }
    })
    const isFormulaValid = !(formula.includes('[') || formula.includes(']'))
    return isCompare
      ? {
          ...cs,
          validFormula: isFormulaValid,
        }
      : {
          ...cs,
          dirty: false,
          hash: buildMetricHash(cs),
          validFormula: isFormulaValid,
        }
  })
  const updatedNonCustomSettings = nonCustomSettings.map(s => {
    return isCompare
      ? {
          ...s,
        }
      : {
          ...s,
          dirty: false,
          hash: buildMetricHash(s),
        }
  })

  return [...updatedCustomSettings, ...updatedNonCustomSettings]
}
