import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  CompareMetricValue,
  UpdateCompareMetric,
  UpdateGrossMetric,
} from '../../../analysis/model/compare-metrics.model'
import { NO_CATEGORY_NAME } from '../../../analysis/store/compare/compare-metric-settings/create-compare-metric-values'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { MetricUpdateData } from '@shared/metrics-toggles.component'
import { MetricBuilderDialogService } from '../../metric-builder-dialog/metric-builder-dialog.service'
import { hideMetric } from '../../metrics.util'
import { CreditPortfolio } from 'src/app/credit/model/credit-portfolio.model'
import { MatDialog } from '@angular/material/dialog'
import { ScenarioSelectorComponent } from '@shared/scenario-selector/scenario-selector.component'
import { SelectedScenario } from 'src/app/credit/model/credit-results.model'
import { MatMenuTrigger } from '@angular/material/menu'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-settings-categories-table',
  styleUrls: ['./metric-settings-categories-table.component.scss'],
  templateUrl: './metric-settings-categories-table.component.html',
})
export class MetricSettingsCategoriesTableComponent implements OnInit {
  @ViewChild('returnPeriodMenuTrigger', { static: true }) returnPeriodMenuTrigger: MatMenuTrigger
  @Input() isCredit: boolean
  @Input() set creditPortfolio(creditPortfolio: CreditPortfolio) {
    this._creditPortfolio = creditPortfolio
    this.viewScenarioDict = {}
    if (!creditPortfolio) {
      return
    }
    creditPortfolio.gross_loss_scenarios.forEach(portfolio => {
      this.viewScenarioDict[portfolio._id] = portfolio.name
    })
  }
  newTailMetric: CompareMetricSetting
  @Input() label: string
  @Input() values: CompareMetricSetting[][]
  @Input() hasPortfolioTypes: boolean
  @Input() lossFilters: LossFilter[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() updateGrossMetricElement = new EventEmitter<UpdateGrossMetric>()
  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  isTailMetric = false
  valueHeight = '60'
  _creditPortfolio: CreditPortfolio
  viewScenarioDict: { [key: string]: string } = {}

  @Input() set collapsed(value: any) {
    this._collapsed = coerceBooleanProperty(value)
  }
  get collapsed() {
    return this._collapsed
  }
  @HostBinding('class.collapsed') _collapsed = false

  @Output() collapseToggle = new EventEmitter<string>()

  setRowValuesWeight(value: number, rowValues: CompareMetricSetting[]) {
    if (value >= 0 && value <= 100) {
      rowValues[0].weight = value
    }
  }

  get displayLabel(): string {
    let length = this.values.length
    if (
      this.values.findIndex(value => {
        return hideMetric(value[0])
      }) !== -1
    ) {
      length--
    }
    const label = this.label !== NO_CATEGORY_NAME ? this.label : 'General'
    return this.collapsed ? `${label} (${length})` : label
  }

  get headerIcon() {
    return this.collapsed ? 'expand_more' : 'expand_less'
  }

  get hideMetric() {
    return hideMetric
  }

  constructor(
    private metricBuilderDialog: MetricBuilderDialogService,
    public dialog: MatDialog
  ) {
    this.newTailMetric = {
      label: `Tail Metric`,
      category: 'Tail Metrics',
      valueType: 'currency',
      portfolioType: 'Net',
      ragOrder: 'Lower',
      path: `tailMetrics/`,
      vartvar: 'VaR',
      aggregationMethodType: 'AEP',
      perspective: 'Loss',
      lossFilter: 'all',
      saveID: 0,
      metricSettingID: 0,
      ordinal: 0,
      show: false,
      weight: 0,
      dirty: true,
      year: "1",
    }
  }

  getFirstLabel(value: CompareMetricValue[]): string {
    return value && value[0] && value[0].label && value[0].year
      ? value[0].category === 'Misc'
        ? this.getMiscLabel(value[0])
        : this.getTailMetricsLabel(value[0].label)
      : value[0].label || ''
  }

  ngOnInit() {
    this.isTailMetric = this.values.some(subArray => subArray.some(item => item.category === 'Tail Metrics'))
    this.values.sort((a, b) => a[0].category_order - b[0].category_order) // order the records by category order (New column added to change the order of the metrics if needed)
  }

  getMiscLabel(metricVal: CompareMetricValue) {
    const [, , , aggregateMethod, perspective] = metricVal.label.split(' ')
    const updatedLabel = metricVal.label
      .replace(
        aggregateMethod,
        metricVal.aggregationMethodType === 'AEP' ? 'Aggregate' : 'Occurrence'
      )
      .replace(
        perspective,
        metricVal.perspective === 'LossRp'
          ? 'Loss+RP'
          : metricVal.perspective === 'UW'
          ? 'UW Result'
          : 'Loss'
      )
    return updatedLabel
  }
  getTailMetricsLabel(label: string) {
    return label.substring(label.indexOf(' ') + 1)
  }

  createCapitalArray(value: CompareMetricValue) {
    if (value.label === 'BCAR Capital Benefit') {
      return [
        {
          title: 'BCAR Premium risk factor',
          value: value.spPremiumValue ? value.spPremiumValue : 1,
        },
        {
          title: 'BCAR Reserve risk factor',
          value: value.spReserveValue ? value.spReserveValue : 1,
        },
        {
          title: 'BCAR Diversification factor',
          value: value.spDivesificationValue ? value.spDivesificationValue : 1,
        },
      ]
    } else {
      return [
        {
          title: 'S&P Premium risk factor',
          value: value.spPremiumValue ? value.spPremiumValue : 1,
        },
        {
          title: 'S&P Reserve risk factor',
          value: value.spReserveValue ? value.spReserveValue : 1,
        },
        {
          title: 'S&P Diversification factor',
          value: value.spDivesificationValue ? value.spDivesificationValue : 1,
        },
        {
          title: 'S&P Cat dilution factor',
          value: value.spCatValue ? value.spCatValue : 1,
        },
      ]
    }
  }

  onCollapseToggle($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    this.collapseToggle.emit(this.label)
  }

  updateMetric(
    event: MatCheckboxChange,
    weight: number,
    year: string,
    metricSetting: CompareMetricSetting
  ) {
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        show: event.checked,
        oldLabel: metricSetting.label,
        newLabel: '',
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show: event.checked,
      weight,
      year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      formula: metricSetting.formula,
      category: metricSetting.category
    })
  }

  onWeightChange(
    show: boolean,
    weight: string,
    year: string,
    metricSetting: CompareMetricSetting
  ) {
    const newWeight =
      Number(weight) <= 100 && Number(weight) >= 0
        ? parseInt(weight, 10)
        : metricSetting.weight

    this.updateMetricElement.emit({
      show,
      weight: newWeight,
      year: year ? year.toString() : year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      formula: metricSetting.formula,
      category: metricSetting.category
    })
  }

  onSceanrioSelected(
    credit_scenario: string,
    year: string | undefined,
    metricSetting: CompareMetricSetting
  ): void {
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: metricSetting.label,
        show: metricSetting.show,
        year,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
        credit_scenario: credit_scenario,
      })
    }

    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: parseInt(String(metricSetting.weight), 10),
      year: year?.toString() ?? metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      credit_scenario: credit_scenario,
      category: metricSetting.category
    })
  }

  onYearChange(
    show: boolean,
    year: string,
    weight: string,
    metricSetting: CompareMetricSetting
  ) {
    const updatedLabel =
      metricSetting.category !== 'Misc'
        ? this.updateTailMetricsLabel(
            metricSetting.label,
            0,
            year.toString() + 'yr'
          )
        : metricSetting.label
    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: updatedLabel,
        show: metricSetting.show,
        year,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show,
      weight: parseInt(weight, 10),
      year: year.toString(),
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: updatedLabel,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  onSpPremiumValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: value,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  onSpReserveValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: value,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  onSpDivesificationValueChange(
    value: number,
    metricSetting: CompareMetricSetting
  ) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: value,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  onSpCatValueChange(value: number, metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: value,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  onCustomMetricDelete(metricSetting: CompareMetricSetting) {
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: metricSetting.portfolioType,
      vartvar: metricSetting.vartvar,
      aggregationMethodType: metricSetting.aggregationMethodType,
      perspective: metricSetting.perspective,
      lossFilter: metricSetting.lossFilter,
      label: metricSetting.label,
      metrics: metricSetting,
      deleted: true,
      category: metricSetting.category
    })
  }

  onCustomMetricEdit(metricSetting: CompareMetricSetting) {
    this.metricBuilderDialog
      .open(this.metricCategories, metricSetting)
      .afterClosed()
      .subscribe(newMetric => {
        if (newMetric) {
          this.updateMetricElement.emit({
            show: metricSetting.show,
            weight: metricSetting.weight,
            year: metricSetting.year,
            portfolioType: metricSetting.portfolioType,
            vartvar: metricSetting.vartvar,
            aggregationMethodType: metricSetting.aggregationMethodType,
            perspective: metricSetting.perspective,
            lossFilter: metricSetting.lossFilter,
            label: newMetric.label,
            metrics: metricSetting,
            ragOrder: newMetric.ragOrder,
            valueType: newMetric.valueType,
            formula: newMetric.formula,
            category: metricSetting.category
          })
        }
      })
  }

  updateTailMetricsLabel(label: string, index: number, value: string) {
    return label.replace(label.split(' ')[index], value)
  }

  updateLossFilterLabel(label: string, value: string) {
    const description = this.lossFilters.find(
      f => f.name === value
    )?.description
    const newLabel = label.split(' ').slice(0, 6).join(' ')
    return `${newLabel} ${description}`
  }

  openReturnPeriodMenu(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing of parent menu
    this.returnPeriodMenuTrigger.openMenu(); // Open the nested menu
  }

  onUpdateMetricClick(
    data: MetricUpdateData,
    metricSetting: CompareMetricSetting
  ) {
    let updatedLabel =
      metricSetting.category !== 'Misc'
        ? metricSetting.label
            .replace(
              metricSetting.label.split(' ')[1],
              data.portfolioTypeChange
            )
            .replace(metricSetting.label.split(' ')[2], data.vartvarChange)
            .replace(
              metricSetting.label.split(' ')[3],
              data.aggregationMethodChange
            )
            .replace(metricSetting.label.split(' ')[4], data.perspectiveChange)
        : metricSetting.label
            .replace(
              metricSetting.label.split(' ')[2],
              data.portfolioTypeChange
            )
            .replace(
              metricSetting.label.split(' ')[3],
              data.aggregationMethodChange === 'AEP'
                ? 'Aggregate'
                : 'Occurrence'
            )

    if (metricSetting.category === 'Misc') {
      let previousPerspective
      if (metricSetting.perspective === 'UW') {
        previousPerspective =
          metricSetting.label.split(' ')[4] + metricSetting.label.split(' ')[5]
      } else {
        previousPerspective = metricSetting.label.split(' ')[4]
      }
      updatedLabel.replace(
        previousPerspective,
        data.perspectiveChange === 'LossRp'
          ? 'Loss+RP'
          : data.perspectiveChange === 'UW'
          ? 'UW Result'
          : 'Loss'
      )
    }

    const description = this.lossFilters.find(
      f => f.name === data.lossFilterChange
    )?.description
    updatedLabel = updatedLabel.split(' ').slice(0, 6).join(' ')
    updatedLabel = `${updatedLabel} ${description}`

    if (this.isGrossMetric(metricSetting)) {
      this.updateGrossMetricElement.emit({
        oldLabel: metricSetting.label,
        newLabel: updatedLabel,
        show: metricSetting.show,
        portfolioType: data.portfolioTypeChange,
        vartvar: data.vartvarChange,
        aggregationMethodType: data.aggregationMethodChange,
        perspective: data.perspectiveChange,
        lossFilter: data.lossFilterChange,
        grossMetricSettingID: metricSetting.grossMetricSettingID,
        ragOrder: metricSetting.ragOrder,
        valueType: metricSetting.valueType,
      })
    }
    this.updateMetricElement.emit({
      show: metricSetting.show,
      weight: metricSetting.weight,
      year: metricSetting.year,
      portfolioType: data.portfolioTypeChange,
      vartvar: data.vartvarChange,
      aggregationMethodType: data.aggregationMethodChange,
      perspective: data.perspectiveChange,
      lossFilter: data.lossFilterChange,
      label: updatedLabel,
      metrics: metricSetting,
      spPremiumValue: metricSetting.spPremiumValue,
      spReserveValue: metricSetting.spReserveValue,
      spDivesificationValue: metricSetting.spDivesificationValue,
      spCatValue: metricSetting.spCatValue,
      ragOrder: metricSetting.ragOrder,
      valueType: metricSetting.valueType,
      category: metricSetting.category
    })
  }

  private isGrossMetric(metricSetting: CompareMetricSetting) {
    return (
      metricSetting.grossMetricSettingID &&
      metricSetting.grossMetricSettingID !== 0
    )
  }

  onScenarioClicked(metricSetting: CompareMetricSetting) {
    const creditPortfolio = this._creditPortfolio
    // Temporary fix to uniquify Ids from gross loss
    const currentCededScenarios =
      this._creditPortfolio.gross_loss_scenarios.map(scenario => {
        return { ...scenario, _id: scenario._id.split('').reverse().join('') }
      })
    const currentScenario = metricSetting.credit_scenario
    const years = metricSetting.year
    new Promise<SelectedScenario>((resolve, reject) => {
      const dialogRef = this.dialog.open(ScenarioSelectorComponent, {
        disableClose: true,
        data: {
          creditPortfolio,
          currentCededScenarios,
          currentScenario,
          years,
          disabledCeded: true,
        },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          resolve(result)
        } else {
          reject()
        }
      })
    })
      .then(result => {
        if (result) {
          this.onSceanrioSelected(
            result.name,
            result.years ? result.years.toString() : undefined,
            metricSetting
          )
        }
      })
      .catch(() => {
        this.onSceanrioSelected('', undefined, metricSetting)
      })
  }
}
