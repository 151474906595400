import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { LayerModelingState } from '../../layer-modeling/store/layer-modeling.reducer'
import { OptimizationCandidateResultEntity } from '../optimization.model'
import * as fromOptimizationCandidatesResultsActions from './optimization-candidates-results.actions'
import * as fromOptimizationActions from './optimization.actions'
import * as fromOptimizationLayersActions from './optimization-layers.actions'
import * as fromOptimizationTailMetricsReducer from './optimization-tail-metrics.reducer'
import * as fromOptimizationTailMetricsActions from './optimization-tail-metrics.actions'

const initState: LayerModelingState = {
  x: 'occurrenceAttachment',
  y: 'expectedCededPremium',
  size: 'premium',
}

interface ExtendedState {
  loading: boolean
  error: string | null
  view: 'chart' | 'table'
  chartState: LayerModelingState
  saving: boolean
  savingError: string | null
  lastCreated: number
  tailMetrics: fromOptimizationTailMetricsReducer.State
}

export type State = EntityState<OptimizationCandidateResultEntity> &
  ExtendedState

export const adapter = createEntityAdapter<OptimizationCandidateResultEntity>()

const initialState = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
  view: 'table',
  chartState: initState,
  saving: false,
  savingError: null,
  lastCreated: 0,
  tailMetrics: fromOptimizationTailMetricsReducer.initialState,
})

const candidateResultsReducer = createReducer(
  initialState,
  on(fromOptimizationCandidatesResultsActions.generateCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromOptimizationCandidatesResultsActions.generateCandidatesSuccess,
    (state, { values }) =>
      adapter.setAll(values, {
        ...state,
        view: 'table' as 'chart' | 'table',
        loading: false,
        error: null,
      })
  ),
  on(
    fromOptimizationCandidatesResultsActions.generateCandidatesFailure,
    (state, { error }) => ({
      ...state,
      error: error.message,
      loading: false,
    })
  ),
  on(
    fromOptimizationActions.reset,
    fromOptimizationLayersActions.generateLayers,
    () => ({ ...initialState })
  ),
  on(
    fromOptimizationCandidatesResultsActions.updateCandidates,
    (state, { changes }) => {
      return adapter.updateMany(changes, state)
    }
  ),
  on(
    fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetricsFailure,
    (state, { id, error }) =>
      adapter.updateOne(
        {
          id,
          changes: { loading: false, portfolioMetricsError: error.message },
        },
        state
      )
  ),
  on(
    fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetrics,
    (state, { id }) =>
      adapter.updateOne(
        { id, changes: { loading: true, portfolioMetricsError: null } },
        state
      )
  ),
  on(
    fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetricsSuccess,
    (state, { changes }) =>
      adapter.updateOne(
        {
          id: changes[0].id,
          changes: {
            ...changes[0].changes,
            loading: false,
            portfolioMetricsError: null,
          },
        },
        state
      )
  ),
  on(fromOptimizationCandidatesResultsActions.setView, (state, { view }) => ({
    ...state,
    view,
  })),

  on(
    fromOptimizationCandidatesResultsActions.setChartDimensionProp,
    (state, { dimension, prop }) => ({
      ...state,
      chartState: { ...state.chartState, [dimension]: prop },
    })
  ),
  on(fromOptimizationCandidatesResultsActions.save, state => ({
    ...state,
    saving: true,
    savingError: null,
  })),
  on(
    fromOptimizationCandidatesResultsActions.saveSuccess,
    (state, { lastCreated }) => ({
      ...state,
      saving: false,
      savingError: null,
      lastCreated,
    })
  ),
  on(
    fromOptimizationCandidatesResultsActions.saveFailure,
    (state, { error }) => ({
      ...state,
      saving: false,
      savingError: error.message,
    })
  ),
  on(
    fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetrics,
    (state, action) => {
      if (action.id) {
        const _state = adapter.updateOne(
          {
            id: action.id,
            changes: {
              portfolioTailError: null,
            },
          },
          state
        )
        return {
          ..._state,
          tailMetrics: fromOptimizationTailMetricsReducer.reducer(
            state.tailMetrics,
            action
          ),
        }
      } else {
        return {
          ...state,
          tailMetrics: fromOptimizationTailMetricsReducer.reducer(
            state.tailMetrics,
            action
          ),
        }
      }
    }
  ),
  on(
    fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetricsFailure,
    (state, action) => {
      return adapter.updateOne(
        {
          id: action.id,
          changes: {
            portfolioTailError: action.error.message,
          },
        },
        state
      )
    }
  ),
  on(
    fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetricsSuccess,
    (state, action) => {
      return adapter.updateOne(
        {
          id: action.id,
          changes: {
            portfolioTailError: null,
            portfolioTvar: action.metrics.tvar,
            portfolioVar: action.metrics.var,
            portfolioEfficiencyScore: action.metrics.efficiencyScore,
          },
        },
        state
      )
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return candidateResultsReducer(state, action)
}
