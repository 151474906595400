<app-explore-multi-select-export-dialog
  [analysisProfile]="analysisProfile$ | async"
  [summaryExportGroups]="summaryExportGroups$ | async"
  [grossPortfolioID]="grossPortfolioID$ | async"
  [program]="program"
  [currentStructureCurrency]="currentStructureCurrency"
  [summaryLossSetRows]="summaryLossSetRows"
  [summaryColumnFilterMap]="summaryColumnFilterMap"
  [summaryViews]="summaryViews"
  [selectedSummaryView]="selectedSummaryView"
  [summaryData]="summaryData"
  [lossSetLayers]="lossSetLayers"
  [summaryFilterMap]="summaryFilterMap"
  [summaryRP]="summaryRP"
  [mappingLabels]="mappingLabels"
  [client]="client"
  [study]="study"
  [accountOpportunities]="accountOpportunities"
  (getSummaryExportGroups)="OnGetSummaryExportGroups($event)"
>
</app-explore-multi-select-export-dialog>
