<h1 mat-dialog-title>Multi-Sections</h1>
<mat-dialog-content>
  <app-multi-section
    #multiSections
    [mainLayer]="layer"
    [layers]="msLayers$ | async"
    [currencyList]="currencyList$ | async"
    [parentGrossLossSetLayers]="pgLossSetLayers$ | async"
    [currentProgram]="currentProgram$ | async"
    [isLibRE]="isLibRE"
    (sectionInvalid)="sectionInvalid = $event"
    (currencyChangeLayer)="getCurrencyChangeLayer($event)"
  ></app-multi-section>
</mat-dialog-content>

<div *ngIf="loadingInMultiSection$ | async" class="loading-inurance">
  Loading inurance...
  <mat-spinner color="accent" [diameter]="15"></mat-spinner>
</div>

<mat-dialog-actions *ngIf="mslService.busy$ | async as status; else loading">
  <button
    #saveAndClose
    appButton
    accent
    cdkFocusInitial
    [disabled]="sectionInvalid || status.busy"
    (click)="onSaveAndClose()"
  >
    Save and Close
  </button>
  <button
    #save
    appButton
    [disabled]="sectionInvalid || status.busy"
    (click)="onSaveClick()"
  >
    Save
  </button>
  <button appButton link mat-dialog-close>Cancel</button>

  <span class="flex-spacer"></span>

  <button mat-mini-fab [disabled]="status.busy" (click)="onAddClick()">
    <mat-icon>add</mat-icon>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
