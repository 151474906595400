import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { MatTabNavPanel } from '@angular/material/tabs'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-tabs',
  styleUrls: ['./portfolio-tabs.component.scss'],
  templateUrl: './portfolio-tabs.component.html',
})
export class PortfolioTabsComponent {
  @Input() showAgg: boolean
  @Input() showOcc: boolean
  @Input() showZoom: number
  @Input() showTowerControls: boolean
  @Input() libRE: boolean
  @Input() hideTechPremiumTab: boolean
  @Input() tabPanel!: MatTabNavPanel

  @Output() showProperties = new EventEmitter<void>()
  @Output() showAggChange = new EventEmitter<boolean>()
  @Output() showOccChange = new EventEmitter<boolean>()
  @Output() showZoomChange = new EventEmitter<number>()
  @Output() downloadTower = new EventEmitter<void>()
  @Output() towerTabSelected = new EventEmitter<void>()

  onShowAggClick(): void {
    return this.showAggChange.emit(!this.showAgg)
  }

  onShowOccClick(): void {
    return this.showOccChange.emit(!this.showOcc)
  }
}
