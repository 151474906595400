import { SalesforceClient } from '../../api/model/signature.model'
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { FormControl } from '@angular/forms'
import { map, startWith } from 'rxjs/operators'
import { Cedent } from '../signature.model'

@Component({
  selector: 'app-signature-select-clients-dialog',
  template: `
    <div class="sidebar">
      <h1 class="sidebar-title">Select All Clients for Contract</h1>
      <mat-chip-grid #chipList class="chips">
        <mat-chip-row
          class="app-chip-palette app-palette-teal"
          *ngFor="let client of selectedClients"
          (removed)="removeSelectedClient(client)"
          >{{ client.acctName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
      </mat-chip-grid>
      <div mat-dialog-actions class="actions">
        <button
          appButton
          border
          center
          accent
          [disabled]="!hasSelection"
          (click)="onOKClick()"
        >
          OK
        </button>
        <button appButton link [mat-dialog-close]="false">Cancel</button>
      </div>
    </div>
    <mat-form-field class="filter">
      <mat-label>Filter Clients</mat-label>
      <input
        matInput
        [formControl]="filterCtrl"
        [matAutocomplete]="autoClients"
      />
      <mat-autocomplete #autoClients="matAutocomplete">
        <ng-container *ngFor="let client of filteredClients | async">
          <mat-option
            [value]="client.acctName"
            matTooltip="TPRef : {{ client.tpRef }}"
            matTooltipPosition="after"
          >
            <mat-checkbox
              [checked]="isSelected(client)"
              (click)="$event.stopPropagation()"
              (change)="toggleClient(client)"
            >
              {{ client.acctName }}
            </mat-checkbox>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        min-height: 40vh;
        font-family: var(--font-header-family);
        border-radius: var(--border-radius-huge);
        box-shadow: var(--shadow-big);
      }

      :host > .sidebar {
        width: 40%;
        padding: 20px;
        background: var(--bg-2);
        border: 1px solid var(--border-2);
        border-right-width: 0;
        border-top-left-radius: var(--border-radius-huge);
        border-bottom-left-radius: var(--border-radius-huge);
        backdrop-filter: blur(25px);
      }

      :host > .filter {
        display: block !important;
        width: 60%;
        padding: 15px;
        background: var(--bg-accent-hint);
        border: 1px solid var(--border);
        border-top-right-radius: var(--border-radius-huge);
        border-bottom-right-radius: var(--border-radius-huge);
        backdrop-filter: blur(25px);
        align-items: flex-start !important;
      }

      .sidebar-title {
        margin-bottom: var(--stack);
        font-size: 20px !important;
        font-family: var(--font-family) !important;
        font-weight: bold !important;
        letter-spacing: normal !important;
        color: white !important;
        height: 24px;
      }

      .chips {
        display: flex;
        align-items: flex-start;
        min-height: calc(
          40vh - 64px - 84px
        ); /* subtract header and action heights */
      }

      .actions {
        padding: var(--stack-big) 0 var(--stack);
      }

      ::ng-deep .backdropBackground {
        backdrop-filter: blur(5px);
      }

      input {
        color: white !important;
        padding-bottom: 6px;
        border-bottom: 1px solid #FFFFFF80 !important;
      }
    `,
  ],
})
export class SignatureSelectClientsDialogComponent {
  selectedClients: SalesforceClient[] = []
  filteredClients: Observable<SalesforceClient[]>
  filterCtrl = new FormControl('')
  clients: SalesforceClient[]

  constructor(
    public dialogRef: MatDialogRef<SignatureSelectClientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clients: SalesforceClient[]
      selected: Cedent[]
    }
  ) {
    this.clients = data.clients
    this.selectedClients = data.selected
      .map(cedent => {
        const clientFound = data.clients.find(
          client => client.acctName === cedent.cedentName
        )
        return clientFound
      })
      .filter(
        isSalesforceClient
      ) /* filter out clients that don't exist in salesforce */

    this.filteredClients = this.filterCtrl.valueChanges.pipe(
      startWith(''),
      map(
        filterBy =>
          typeof filterBy === 'string' && filterBy.length > 0
            ? this.filterClients(filterBy)
            : this.clients /* default to all clients if no filter typed */
      )
    )
  }

  get hasSelection() {
    return this.selectedClients.length > 0
  }

  filterClients(filter: string): SalesforceClient[] {
    return this.clients.filter(client => {
      return client.acctName.toLowerCase().includes(filter.toLowerCase())
    })
  }

  isSelected(client: SalesforceClient) {
    return this.selectedClients.indexOf(client) > -1
  }

  toggleClient(client: SalesforceClient) {
    const indexOf = this.selectedClients.indexOf(client)
    if (indexOf < 0) {
      this.selectedClients.push(client)
    } else {
      this.selectedClients.splice(indexOf, 1)
    }
  }

  removeSelectedClient(client: SalesforceClient) {
    const index = this.selectedClients.indexOf(client)
    if (index >= 0) {
      this.selectedClients.splice(index, 1)
    }
  }

  onOKClick() {
    if (this.hasSelection) {
      this.dialogRef.close(this.selectedClients)
    }
  }
}

const isSalesforceClient = (
  client: SalesforceClient | undefined
): client is SalesforceClient => {
  return !!client
}
