import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ChangeDetectorRef,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ScenarioEventResult, EventLayer } from '../animated-scenarios.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-animated-scenarios-ceded-details',
  styleUrls: ['./ceded-loss-details-dialog.component.scss'],
  templateUrl: './ceded-loss-details-dialog.component.html',
})
export class CededLossDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CededLossDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScenarioEventResult,
    private ref: ChangeDetectorRef
  ) {}

  get eventLayers(): EventLayer[] {
    if (this.data) {
      this.ref.markForCheck()
      return this.data.layers.filter(l => l.loss && l.layer.physicalLayer.limit)
    } else {
      this.ref.markForCheck()
      return []
    }
  }

  getValue(eventLayer: EventLayer): number {
    return (
      eventLayer.loss * Math.abs(eventLayer.layer.physicalLayer.participation)
    )
  }
}
