<div class="title-container">
  <div>
    <h1>{{ title }}</h1>
    <div class="subtitle">Shift-click to select a range.</div>
  </div>
  <div class="action-toggles">
    <mat-checkbox
      class="exclusive-selected-toggle"
      labelPosition="before"
      (change)="toggleExclusiveSelected($event)"
      >View only selected</mat-checkbox
    >
  </div>
</div>
<div class="search-container">
  <span class="search-prefix">Search:</span>
  <input
    matInput
    class="input-field"
    #searchInput
    [formControl]="searchCtrl"
    [placeholder]="'Click & type to filter options...'"
  />
</div>
<div class="content">
  <div class="header">
    <span class="name">Name</span>
  </div>
  <ul #list>
    <ng-container *ngFor="let value of filteredValues | async; let i = index">
      <li
        #item
        (click)="onSelect(value, i, { isRange: $event.shiftKey })"
        [ngClass]="getClass(value)"
      >
        <span class="name">{{ value.value }}</span>
        <svg
          *ngIf="value.id === values[rangeCursor]?.id"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M15 18V6l-6 6z" />
        </svg>
      </li>
      <!--<hr *ngIf="i === startIndex - 1" />-->
    </ng-container>
  </ul>
</div>
<div mat-dialog-actions class="actions">
  <button
    appButton
    border
    center
    accent
    tabindex="1"
    [disabled]="!hasSelection"
    (click)="onOKClick()"
  >
    OK
  </button>
  <button appButton link tabindex="2" [mat-dialog-close]="false">Cancel</button>
</div>
