import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Reinsurer } from '../../../core/model/reinsurer.model'
import { isLayerMetricApplicable } from '../../model/layer-metric-applicability'
import { LayerMetricDefAndID } from '../../model/layer-metric-defs'
import { LayerView } from '../../model/layer-view'
import {
  LayerMetrics,
  LayerMetricsRP,
  LayerViewMetricsRPPayload,
  LossSetContribution,
} from '../../model/layers-metrics.model'
import { Layer, LayerRef } from '../../model/layers.model'
import { findRiskActualLayer } from '../../model/layers.util'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import {
  AggregationMethodType,
  Perspective,
  ReturnPeriodRow,
} from '../../model/metrics.model'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import MetricPropertyDefs from './metric-property-defs'
import { CurrencyCode } from 'src/app/api/analyzere/analyzere.model'
import { FormControl } from '@angular/forms'
import { SharedLimitProperties } from '../../store/grouper/program-group.model'
import { clone } from 'ramda'
import { layerIds } from '../../model/layer-palette.model'

@Component({
  selector: 'app-metric-properties',
  templateUrl: './metric-properties.component.html',
  styleUrls: ['./metric-properties.component.scss'],
})
export class MetricPropertiesComponent implements OnChanges, OnInit {
  title = 'Layer Selected'
  layerResultsMetricCurrency: string
  metricPropertyDefs: LayerMetricDefAndID[] = []
  view: LayerView
  layerMetrics: LayerMetricsRP
  filterLossSets: LossSetContribution[] = []
  isFilter = false
  contributionValues: { id: string; value: number }[] = []
  layerResultsCurrencyControl = new FormControl()
  currentCurrency: string | null

  @Input() set layer(value: LayerState | null) {
    if (value) {
      this.view = new LayerView(null, value.layer, {
        metrics: this._layerMetrics,
        reinsurers: this._reinsurersList,
        lossSets: this._lossSetLayers,
      })
      if (this.view.layer.viewMetrics.metrics) {
        this.layerMetrics = { ...this.view.layer.viewMetrics.metrics }
      }

      if (this.view.layer.contributionValues) {
        this.contributionValues = this.view.layer.contributionValues
      } else {
        this.contributionValues = []
      }

      this.metricPropertyDefs = MetricPropertyDefs.filter(def =>
        isLayerMetricApplicable(this.view, def)
      )
    }
  }

  private _layerMetrics?: LayerMetrics
  @Input() set layerViewsMetrics(value: LayerMetrics | null) {
    this._layerMetrics = value as LayerMetrics
    if (this.view) {
      this.view.metrics = this._layerMetrics
    }
  }

  private _reinsurersList: Reinsurer[]
  @Input() set reinsurersList(value: Reinsurer[] | null) {
    this._reinsurersList = value as Reinsurer[]
    if (this.view) {
      this.view.reinsurers = this._reinsurersList
    }
  }

  private _lossSetLayers: LossSetLayer[]
  @Input() set lossSetLayers(value: LossSetLayer[] | null) {
    this._lossSetLayers = value as LossSetLayer[]
    if (this.view) {
      this.view.lossSets = value as LossSetLayer[]
    }
  }
  get lossSetLayers() {
    return this._lossSetLayers
  }
  private newSharedLayerEdit: SharedLimitProperties

  @Input() metricLoading: boolean | null
  @Input() layerLoading: boolean | null
  @Input() currentCededLayer: LayerState | null
  @Input() layers: LayerState[] | null
  @Input() selectedViewID: string | null
  @Input() portfolioSetID: PortfolioSetID | null
  @Input() isDeleteSharedLayer = false
  @Input() sharedCurrentCurrency: string | null
  @Input() updatingSharedLimit: boolean | null = false
  @Input() designDirty: boolean
  @Input() currencyList: CurrencyCode[] | null
  @Input() filteredCurrencyList: CurrencyCode[] | null
  @Input() layerFilteredCurrencyList: CurrencyCode[] | null
  @Input() sharedLimitCurrency: string
  @Input() selectedSharedLayer: Layer | null

  @Input()
  set newSharedLayer(value: SharedLimitProperties | null) {
    this.newSharedLayerEdit = clone(value) as SharedLimitProperties
  }
  @Output() closeClick = new EventEmitter<void>()
  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()
  @Output() contributionClick = new EventEmitter<{
    lossSets: LossSetContribution[]
    id: string
  }>()

  @Output() aalClick = new EventEmitter<{
    id: string
  }>()

  @Output() setLayerMetrics = new EventEmitter<{
    id: PortfolioSetID
    pvp: LayerViewMetricsRPPayload
    viewID: string
    layerID: string
  }>()
  @Output() updateSharedLimitPropertiesClick = new EventEmitter()
  @Output() sharedLimitChange = new EventEmitter<SharedLimitProperties>()

  get layerPresent(): boolean {
    return !!this.selectedViewID
  }

  get isRiskXOL(): boolean {
    return (
      this.view &&
      this.view.layer.meta_data.sage_layer_type === layerIds.noncatRisk
    )
  }

  ngOnInit(): void {
    this.layerResultsCurrencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layer) {
      if (changes.layer.currentValue) {
        this.currentCurrency = changes.layer.currentValue.layer.currency
      }
      if (this.selectedSharedLayer) {
        if (this.selectedSharedLayer.layerResultsMetricCurrency) {
          this.layerResultsMetricCurrency =
            this.selectedSharedLayer.layerResultsMetricCurrency
        } else if (this.selectedSharedLayer.currency) {
          this.layerResultsMetricCurrency = this.selectedSharedLayer.currency
        }
      } else {
        this.layerResultsMetricCurrency = 'USD'
      }
      this.layerResultsCurrencyControl.setValue({
        code: this.layerResultsMetricCurrency,
      })
    }
  }

  filterCurrencyValues(searchVal: any): CurrencyCode[] {
    const currencyList = this.currencyList as CurrencyCode[]
    if (searchVal.code) {
      return currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      return currencyList.filter(
        value => value.code.toLowerCase().indexOf(searchVal.toLowerCase()) === 0
      )
    }
  }

  get lossSets(): LossSetContribution[] {
    let actualLayer = this.view.layer.lossSetLayers.map(l1 => l1.id)
    let lossSet: LossSetContribution[] = []
    if (this.view.layer.meta_data.isRiskVisible === true) {
      const layers = this.layers as LayerState[]
      // tslint:disable-next-line:no-non-null-assertion
      const actualRiskLayer = findRiskActualLayer(
        layers?.map(l => l.layer),
        this.view.layer.id
      )!
      const loss: string[] = []
      if (actualRiskLayer) {
        actualRiskLayer.lossSetLayers.forEach(l => {
          if (l.loss_sets && l.meta_data.sage_layer_type === 'noncat_risk') {
            if (
              l.loss_sets[0].hasOwnProperty('loss_sets') &&
              (l.loss_sets[0] as LayerRef).loss_sets
            ) {
              const catLoss = l.loss_sets[0] as LayerRef
              if (catLoss.loss_sets && catLoss.loss_sets[0]) {
                loss.push(
                  // tslint:disable-next-line: no-non-null-assertion
                  ((l.loss_sets[0] as LayerRef).loss_sets![0] as LossSetLayer)
                    .id
                )
              }
            } else {
              loss.push((l.loss_sets[0] as LossSetLayer).id)
            }
          }
        })
        actualLayer = loss
        lossSet = (this.lossSetLayers || [])
          .filter(ls =>
            actualLayer.some(
              vls => vls === (ls.loss_sets[0] as LossSetLayer).id
            )
          )
          .map(ls => ({
            label: ls.description,
            value: ls.mean,
            id: ls.id,
            meta_data: ls.meta_data,
          }))
      }
    } else {
      lossSet = (this.lossSetLayers || [])
        .filter(ls => actualLayer.some(vls => vls === ls.id))
        .map(ls => ({
          label: ls.description,
          value: ls.mean,
          id: ls.id,
          meta_data: ls.meta_data,
        }))
    }
    if (this.contributionValues && this.contributionValues.length > 0) {
      this.contributionValues.forEach(c => {
        const index = lossSet.findIndex(l => l.id === c.id)
        if (index > -1) {
          lossSet[index].expectedLoss = c.value
        }
      })
    }
    if (!this.isFilter) {
      const currentCededLayer = this.currentCededLayer
      if (currentCededLayer !== null) {
        lossSet.forEach(lSet => {
          currentCededLayer.layer.lossSetLayers.forEach((cLayer: any) => {
            if (cLayer.id === lSet.id) {
              lSet.value = cLayer.mean
            }
          })
        })
      }
      this.filterLossSets = lossSet
    }
    return lossSet
  }

  get showMetrics(): boolean {
    return this.view.layer.viewMetrics.metrics != null
  }

  get returnPeriodData(): ReturnPeriodRow[] {
    return this.layerMetrics.returnPeriodData
  }

  get returnPeriod1(): number {
    return this.layerMetrics.returnPeriod1
  }

  get returnPeriod2(): number {
    return this.layerMetrics.returnPeriod2
  }

  get returnPeriod3(): number {
    return this.layerMetrics.returnPeriod3
  }

  trackByDef(index?: number, def?: LayerMetricDefAndID) {
    return def ? def.id : index
  }

  trackByLossSet(index?: number, lossSet?: { label: string; value: number }) {
    return lossSet ? lossSet.label : index
  }

  getValue(def: LayerMetricDefAndID) {
    if (this.view) {
      return this.view.values[def.id]
    }
  }

  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }

  onLayerResultsCurrencyChange(val: CurrencyCode): void {
    this.layerResultsMetricCurrency = val.code
    this.newSharedLayerEdit.layerResultsMetricCurrency = val.code
    this.onSharedLimitPropChange()
  }

  onReturnPeriod1Change($event: number): void {
    this.layerMetrics.returnPeriod1 = $event
    this.dispatchLayerMetrics({ ...this.layerMetrics, returnPeriod1: $event })
  }

  onReturnPeriod2Change($event: number): void {
    this.layerMetrics.returnPeriod2 = $event
    this.dispatchLayerMetrics({ ...this.layerMetrics, returnPeriod2: $event })
  }

  onReturnPeriod3Change($event: number): void {
    this.layerMetrics.returnPeriod3 = $event
    this.dispatchLayerMetrics({ ...this.layerMetrics, returnPeriod3: $event })
  }

  onAggregationMethodChange($event: AggregationMethodType): void {
    this.layerMetrics.aggregationMethod = $event
    this.dispatchLayerMetrics({
      ...this.layerMetrics,
      aggregationMethod: $event,
    })
  }

  onPerspectiveChange($event: Perspective): void {
    this.layerMetrics.perspective = $event
    this.dispatchLayerMetrics({ ...this.layerMetrics, perspective: $event })
  }

  createFilter(value: string): void {
    if (this.lossSets) {
      this.filterLossSets = this.lossSets.filter(e => {
        return e.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
      })
      this.isFilter = true
    }
  }

  onAALClick(): void {
    this.aalClick.emit({
      id: this.view.layer.id,
    })
  }

  onContributionClick(): void {
    if (!this.designDirty) {
      this.contributionClick.emit({
        lossSets: this.filterLossSets,
        id: this.view.layer.id,
      })
    }
  }

  onUpdateSharedLimitPropertiesClick(): void {
    if (this.isDeleteSharedLayer) {
      if (this.selectedSharedLayer) {
        const layer: Layer = {
          ...this.selectedSharedLayer,
          layerResultsMetricCurrency: this.layerResultsMetricCurrency,
          physicalLayer: {
            ...this.selectedSharedLayer.physicalLayer,
            meta_data: {
              ...this.selectedSharedLayer.meta_data,
              layerResultsMetricCurrency: this.layerResultsMetricCurrency,
            },
          },
          meta_data: {
            ...this.selectedSharedLayer.meta_data,
          },
        }
        this.updateSharedLimitPropertiesClick.emit(layer)
      }
    }
  }

  onSharedLimitPropChange(): void {
    this.sharedLimitChange.emit(this.newSharedLayerEdit)
  }

  get showEditButton(): boolean {
    return this.isDeleteSharedLayer && this.selectedSharedLayer != null
  }

  get isDisabled(): boolean {
    if (this.isDeleteSharedLayer && this.selectedSharedLayer) {
      return (
        this.newSharedLayerEdit.layerResultsMetricCurrency ===
        this.selectedSharedLayer.layerResultsMetricCurrency
      )
    } else {
      return true
    }
  }

  private dispatchLayerMetrics(pvp: LayerViewMetricsRPPayload) {
    this.setLayerMetrics.emit({
      // tslint:disable-next-line: no-non-null-assertion
      id: this.portfolioSetID!,
      layerID: this.view.layer.id,
      // tslint:disable-next-line: no-non-null-assertion
      viewID: this.selectedViewID!,
      pvp,
    })
  }
}
