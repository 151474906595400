<h1 mat-dialog-title>Graph Properties</h1>
<div mat-dialog-content class="wrapper">
  <div class="layer-title">
    <div class="name" ngClass="property-title">General Preferences</div>
  </div>
  <mat-slide-toggle [checked]="log" (change)="onLogToggle($event)">
    Logarithmic Y Axis
  </mat-slide-toggle>
  <div class="properties">
    <app-layer-property
      *ngIf="log"
      name="Logarithmic Axis Minimum"
      type="currency"
      [value]="getLogMin"
      (inputChange)="setLogMin = $event"
      [currentCurrency]="currentCurrency"
    ></app-layer-property>
  </div>
  <mat-slide-toggle [checked]="snapping" (change)="onSnappingToggle($event)">
    Snapping
  </mat-slide-toggle>
  <div class="layer-title">
    <div class="name" ngClass="property-title">Occurrence Tower</div>
  </div>

  <div class="properties">
    <app-layer-property
      name="Y Increments"
      type="currency"
      [value]="getIncrementsYOcc"
      (inputChange)="setIncrementsYOcc = $event"
      [currentCurrency]="currentCurrency"
    ></app-layer-property>

    <app-layer-property
      name="Max Y"
      type="currency"
      [value]="getMaxYOcc"
      (inputChange)="setMaxYOcc = $event"
      [currentCurrency]="currentCurrency"
    ></app-layer-property>
  </div>

  <div *ngIf="aggLayers.length > 0">
    <div class="layer-title">
      <div class="name" ngClass="property-title">Aggregate Tower</div>
    </div>

    <div class="properties">
      <app-layer-property
        name="Y Increments"
        type="currency"
        [value]="getIncrementsYAgg"
        (inputChange)="setIncrementsYAgg = $event"
        [currentCurrency]="currentCurrency"
      ></app-layer-property>

      <app-layer-property
        name="Max Y"
        type="currency"
        [value]="getMaxYAgg"
        (inputChange)="setMaxYAgg = $event"
        [currentCurrency]="currentCurrency"
      ></app-layer-property>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button
    appButton
    big
    border
    center
    accent
    [disabled]="isDisabled"
    (click)="onSaveProperties()"
  >
    <span>Save</span>
  </button>
  <button appButton big link mat-dialog-close="2">
    <span>Close</span>
  </button>
</div>
