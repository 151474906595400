import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { SortTableColumnView } from './sort-table/sort-table.model'

export interface LayerDetailsTitleData {
  title: string
  message?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-details-title',
  template: `
    <div class="container">
      <ng-container>
        <h3 class="subtle">RP Year:</h3>
        <button class="slider" appButton [matMenuTriggerFor]="sliderMenu">
          <span class="year">{{ rpYearValue }}yr</span>
        </button>
        <mat-menu
          #sliderMenu="matMenu"
          yPosition="above"
          class="app-menu-no-max-width"
        >
          <app-return-period-selector
            [returnPeriod]="rpYear"
            matTooltip="Select a return period to pair with this metric."
            matTooltipPosition="above"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            (returnPeriodChange)="rpYearValue = $event"
          >
          </app-return-period-selector>
        </mat-menu>
      </ng-container>
      <h3 class="subtle">Aggregation Method:</h3>
      <mat-button-toggle-group
        class="app-mat-theme"
        name="aggregation"
        aria-label="Aggregation"
        (click)="$event.stopPropagation()"
        [value]="aggregationMethod"
        (change)="aggregationMethodValue = $event.value"
      >
        <mat-button-toggle value="OEP" disableRipple>OEP</mat-button-toggle>
        <mat-button-toggle value="AEP" disableRipple>AEP</mat-button-toggle>
      </mat-button-toggle-group>
      <div class="update-button">
        <button
          class="metric-toggles-button"
          appButton
          accent
          border
          (click)="onUpdateMetricClick()"
        >
          Update
        </button>
      </div>
    </div>
  `,
  styles: [
    `
           .container {
             padding: 10px;
           }
     
           .content {
             overflow: hidden;
           }
     
           h3 {
             margin-bottom: var(--stack-small);
           }
     
           h3.subtle {
             color: var(--subtle);
             margin-top: 0;
             margin-bottom: var(--stack);
             display: inline-block;
           }
     
           .slider {
             display: inline-block;
             left: 10px;
           }
     
           .update-button {
             margin-top: 20px;
           }
         `,
  ],
})
export class LayerDetailsTitleComponent<T extends { id: string }>
  implements OnInit
{
  rpYearValue: number
  aggregationMethodValue: string

  @Input() col: SortTableColumnView<T>
  @Output() updateLayerTitleClick = new EventEmitter<{
    year: number
    method: string
  }>()

  get rpYear(): number {
    const label = this.isString(this.col.label)
    return parseInt(label.split(' ')[0].replace('yr', ''), 10)
  }

  get aggregationMethod(): string {
    const label = this.isString(this.col.label)
    return label.split(' ')[1]
  }

  ngOnInit() {
    const label = this.isString(this.col.label)
    this.rpYearValue = parseInt(label.split(' ')[0].replace('yr', ''), 10)
    this.aggregationMethodValue = label.split(' ')[1]
  }

  isString(input: any) {
    return typeof input === 'string' ? input : ''
  }

  onUpdateMetricClick() {
    this.updateLayerTitleClick.emit({
      year: this.rpYearValue,
      method: this.aggregationMethodValue,
    })
  }
}
