import { clone } from 'ramda'
import {
  QuoteChartAxis,
  QuoteChartDatum,
  QuoteChartGridOption,
  QuoteChartGroupedDataBySplit,
  QuoteChartInfo,
} from '../model/quote-charts.model'
import { B } from '@angular/cdk/keycodes'

/* Remove 'Lloyd's Syndicate' from chart labels */
export const removeLloydsFromLabels = (labels: string[]) => {
  return labels.map(l => l.replace(`Lloyd's Syndicate `, ''))
}

/* Convert metric into a percent value */
export const convertToPercent = (decimal: number): number => {
  return parseFloat((decimal * 100.0).toFixed(2))
}

/* Round metric value down */
export const convertToInt = (decimal: number): number => {
  return Math.floor(decimal)
}

/* Parse data passed with groupBy split values */
export const parseDataWithSplit = (
  data: QuoteChartDatum[]
) => {
  const distinctGroupBys = new Set<string>()
  const distinctSplits = new Set<string>()
  const result: { [groupBy: string]: { [split: string]: QuoteChartDatum } } = {}
  for (const datum of data) {
    if (datum.groupBy) {
      distinctGroupBys.add(datum.groupBy)
    }
    const splitValue = datum.split ?? 'N/A'
    distinctSplits.add(splitValue)
  }
  const distinctSplitsInOrder = Array.from(distinctSplits).sort()
  distinctGroupBys.forEach(groupValue => {
    result[groupValue] = {}
    distinctSplitsInOrder.forEach(splitValue => {
      result[groupValue][splitValue] = {
        groupBy: groupValue,
        metricPrimary: 0,
        split: splitValue,
      }
    })
    const splits = new Set<string>()
    for (const datum of data) {
      if (datum.groupBy === groupValue) {
        const splitValue = datum.split ?? 'N/A'
        splits.add(splitValue)
      }
    }
    splits.forEach(splitValue => {
      const splitChunk: QuoteChartDatum = {
        groupBy: groupValue,
        metricPrimary: 0,
        split: splitValue,
      }
      const dataChunk: QuoteChartDatum[] = []
      for (const datum of data) {
        if (
          datum.groupBy === groupValue &&
          (datum.split ?? 'N/A') === splitValue
        ) {
          dataChunk.push(datum)
        }
      }
      splitChunk.metricPrimary = dataChunk.reduce(
        (sum, datum) => sum + datum.metricPrimary,
        0
      )
      splitChunk.size = dataChunk.reduce(
        (sum, datum) => (datum.size ? sum + datum.size : sum),
        0
      )
      splitChunk.metricSecondary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricSecondary ? sum + datum.metricSecondary : sum,
        0
      )
      splitChunk.metricTertiary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricTertiary ? sum + datum.metricTertiary : sum,
        0
      )
      splitChunk.metricQuaternary = dataChunk.reduce(
        (sum, datum) =>
          datum.metricQuaternary ? sum + datum.metricQuaternary : sum,
        0
      )
      result[groupValue][splitValue] = splitChunk
    })
  })
  const mappedResult: QuoteChartGroupedDataBySplit[] = []
  for (const groupBy in result) {
    if (!result[groupBy]) {
      continue
    }
    const groupData = result[groupBy]
    const metricPrimaryValues: number[] = []
    for (const [_, splitChunk] of Object.entries(groupData)) {
      metricPrimaryValues.push(splitChunk.metricPrimary)
    }
    const metricTotal = metricPrimaryValues.reduce(
      (sum, count) => sum + count,
      0
    )
    mappedResult.push({ groupBy, data: metricPrimaryValues, metricTotal })
  }
  let groupedData = mappedResult.sort((a, b) => b.metricTotal - a.metricTotal)
  return {
    groupedData,
    splits: distinctSplitsInOrder,
  }
}

export const getChartKvps = (chart: QuoteChartGridOption) => {
  const kvps: Record<string, string> = {}
  kvps.groupBy = chart.groupBy.columnName
  kvps.metricPrimary = chart.metric.columnName
  if (chart.size) {
    kvps.size = chart.size.columnName
  }
  if (chart.split) {
    kvps.split = chart.split.columnName
  }
  return kvps
}

export const getChartSplitAndSize = (
  chartInfo: QuoteChartInfo,
  groupBy: QuoteChartAxis
): {
  split: QuoteChartAxis | null
  size: QuoteChartAxis | null
} => {
  const applicableSplitFound = chartInfo.applicableSplits.find(
    split => split.columnName !== groupBy.columnName
  )
  return {
    size:
      chartInfo.applicableSizes.length > 0
        ? chartInfo.applicableSizes[0]
        : null,
    split:
      chartInfo.applicableSplits.length > 0 && applicableSplitFound
        ? applicableSplitFound
        : null,
  }
}

export const sortChartData = (
  chartOption: QuoteChartGridOption,
  ignoreBlackList?: boolean
): QuoteChartDatum[] => {
  if (!chartOption || !chartOption.data || !chartOption.data.data) {
    return []
  }
  let data: QuoteChartDatum[] = clone(chartOption.data.data)
  if (chartOption.consolidateLloyds) {
    data = consolidateLloydsData(data)
  }
  if (
    chartOption.blackList &&
    chartOption.blackList.length > 0 &&
    !ignoreBlackList
  ) {
    data = data.filter(d => !chartOption.blackList.includes(d.groupBy))
  }
  const sortBy = chartOption.sortBy?.value ?? 'none'
  if (chartOption.groupBy.columnName === 'inceptionDate') {
    data = data
  } else if (sortBy === 'none' || sortBy === 'highToLow') {
    data = data.sort((a, b) => b.metricPrimary - a.metricPrimary)
  } else if (sortBy === 'lowToHigh') {
    data = data.sort((a, b) => a.metricPrimary - b.metricPrimary)
  } else if (sortBy === 'lowToHighSize') {
    data = data.sort((a, b) => a.size - b.size)
  } else if (sortBy === 'highToLowSize') {
    data = data.sort((a, b) => b.size - a.size)
  }
  return data
}

export const sortGroupedChartData = (
  groupedData: QuoteChartGroupedDataBySplit[],
  chartOption: QuoteChartGridOption,
  ignoreBlackList?: boolean
): QuoteChartGroupedDataBySplit[] => {
  let data: QuoteChartGroupedDataBySplit[] = clone(groupedData)
  const sortBy = chartOption.sortBy?.value ?? 'none'
  if (chartOption.consolidateLloyds) {
    data = consolidateLloydsGroupedData(data)
  }
  if (chartOption.blackList && chartOption.blackList.length > 0 && !ignoreBlackList) {
    data = data.filter(d => !chartOption.blackList.includes(d.groupBy))
  }
  if (chartOption.groupBy.columnName === 'inceptionDate') {
    data = data
  } else if (sortBy === 'none' || sortBy === 'highToLow') {
    data = data.sort((a, b) => b.metricTotal - a.metricTotal)
  } else if (sortBy === 'lowToHigh') {
    data = data.sort((a, b) => a.metricTotal - b.metricTotal)
  }
  return data
}

export const getSlicedGroupedData = (
  data: QuoteChartGroupedDataBySplit[],
  chartEntityLimit: number,
  getAllOther: boolean,
  consolidateLloyds: boolean
): QuoteChartGroupedDataBySplit[] => {
  let limit = chartEntityLimit
  let finalData = clone(data)
  if (getAllOther) {
    const allOther = getAllOtherGroupedData(finalData, limit)
    if (allOther) {
      finalData = finalData.slice(0, limit)
      finalData.push(allOther)
      limit += 1
    }
  }
  return finalData.slice(0, limit)
}

export const getAllOtherGroupedData = (
  data: QuoteChartGroupedDataBySplit[],
  chartEntityLimit: number
): QuoteChartGroupedDataBySplit => {
  if (
    !data ||
    data.length === 0 ||
    chartEntityLimit < 0 ||
    chartEntityLimit >= data.length
  ) {
    return undefined
  }
  const initialIndex = chartEntityLimit + 1
  if (initialIndex >= data.length) {
    return undefined
  }
  let allOther: QuoteChartGroupedDataBySplit = {
    ...data[initialIndex],
    groupBy: 'All Other',
  }
  const otherData = data.slice(initialIndex + 1)
  if (otherData.length === 0) {
    return allOther
  }
  const metricTotal = otherData.reduce(
    (a, b) => a + b.metricTotal,
    allOther.metricTotal
  )
  const allOtherData = allOther.data.map((d, i) => {
    let final = d
    otherData.forEach(od => {
      if (od.data[i]) {
        final += od.data[i]
      }
    })
    return final
  })
  return {
    ...allOther,
    metricTotal,
    data: allOtherData,
  }
}

export const getSlicedData = (
  data: QuoteChartDatum[],
  chartEntityLimit: number,
  getAllOther: boolean,
  consolidateLloyds: boolean
): QuoteChartDatum[] => {
  let limit = chartEntityLimit
  let finalData = clone(data)
  if (getAllOther) {
    const allOther = getAllOtherData(finalData, limit)
    finalData = finalData.slice(0, limit)
    if (allOther) {
      finalData.push(allOther)
    }
    limit += 1
  }
  return finalData.slice(0, limit)
}

export const getAllOtherData = (
  data: QuoteChartDatum[],
  chartEntityLimit: number
): QuoteChartDatum | undefined => {
  if (
    !data ||
    data.length === 0 ||
    chartEntityLimit < 0 ||
    chartEntityLimit >= data.length
  ) {
    return undefined
  }
  const initialIndex = chartEntityLimit + 1
  if (initialIndex >= data.length) {
    return undefined
  }
  let allOther: QuoteChartDatum = {
    ...data[initialIndex],
    groupBy: 'All Other',
  }
  const otherData = data.slice(initialIndex + 1)
  if (otherData.length === 0) {
    return allOther
  }
  allOther = otherData.reduce((a, b) => {
    return {
      ...allOther,
      metricPrimary: (a.metricPrimary || 0) + (b.metricPrimary || 0),
      metricSecondary: (a.metricSecondary || 0) + (b.metricSecondary || 0),
      metricTertiary: (a.metricTertiary || 0) + (b.metricTertiary || 0),
      metricQuaternary: (a.metricQuaternary || 0) + (b.metricQuaternary || 0),
      size: (a.size || 0) + (b.size || 0),
    }
  }, allOther)
  return allOther
}

export const consolidateLloydsData = (
  data: QuoteChartDatum[]
): QuoteChartDatum[] => {
  let consolidatedData = clone(data)
  const newData = consolidatedData.filter(d => !d.groupBy.includes('Lloyd'))
  const lloydsData = consolidatedData.filter(d => d.groupBy.includes('Lloyd'))
  if (lloydsData && lloydsData.length > 0) {
    const ogLloyd = {
      ...lloydsData[0],
      groupBy: `Consolidated Lloyd's`,
      metricPrimary: 0,
      metricSecondary: 0,
      metricTertiary: 0,
      metricQuarternary: 0,
      size: 0,
    }
    const consolidatedLloydsDatum = lloydsData.reduce((a, b) => {
      return {
        ...a,
        metricPrimary: (a.metricPrimary || 0) + (b.metricPrimary || 0),
        metricSecondary: (a.metricSecondary || 0) + (b.metricSecondary || 0),
        metricTertiary: (a.metricTertiary || 0) + (b.metricTertiary || 0),
        metricQuaternary: (a.metricQuaternary || 0) + (b.metricQuaternary || 0),
        size: (a.size || 0) + (b.size || 0),
      }
    }, ogLloyd)
    newData.push(consolidatedLloydsDatum)
  }
  return newData
}

export const consolidateLloydsGroupedData = (
  data: QuoteChartGroupedDataBySplit[]
): QuoteChartGroupedDataBySplit[] => {
  let consolidatedData = clone(data)
  const newData = consolidatedData.filter(d => !d.groupBy.includes('Lloyd'))
  const lloydsData = consolidatedData.filter(d => d.groupBy.includes('Lloyd'))
  if (lloydsData && lloydsData.length > 0) {
    const groupBy = `Consolidated Lloyd's`
    const metricTotal = lloydsData.reduce((a, b) => a + b.metricTotal, 0)
    const lloydsValues = lloydsData[0].data.map((d, i) => {
      let final = d
      lloydsData.forEach((ld, j) => {
        if (j !== 0 && ld.data[i]) {
          final += ld.data[i]
        }
      })
      return final
    })
    newData.push({
      groupBy,
      metricTotal,
      data: lloydsValues,
    })
  }
  return newData
}
