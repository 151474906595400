import { ActionCreator, ActionType, on } from '@ngrx/store'
import { FunctionWithParametersType } from '@ngrx/store/src/models'
import produce, { Draft } from 'immer'

/**
 * Replacement for `@ngrx/store/on` function, using Immer's `produce`
 * function to allow reducers to be written ina mutable manner while
 * preserving state immutability.
 *
 * See: `https://stackoverflow.com/questions/56963536/looking-for-examples-to-immer-produce-with-the-ngrx-8`
 */
export function mutableOn<
  T extends string,
  C extends FunctionWithParametersType<any, object>,
  State
>(
  actionType: ActionCreator<T, C>,
  callback: (
    draft: Draft<State>,
    action: ActionType<ActionCreator<T, C>>
  ) => any
) {
  return on(
    actionType,
    (state: State, action): State =>
      produce(state, draft => callback(draft, action))
  )
}
