import { props, createAction } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { AnalysisStartProps } from '../analysis.actions'

export const fetchPortfolio = createAction(
  '[Net Portfolio] Fetch',
  props<{ id: string, analysisStartProps: AnalysisStartProps }>()
)

export const fetchPortfolioSuccess = createAction(
  '[Net Portfolio] Fetch Success',
  props<{
    id: string
    layersIDs: string[]
  }>()
)

export const fetchPortfolioFailure = createAction(
  '[Net Portfolio] Fetch Failure',
  props<{ error: ApplicationError }>()
)
