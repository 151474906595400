<div class="flex-container-column">
  <div class="selector" *ngIf="multipleCarriers">
    <app-tier-bar-container contextPath="display"></app-tier-bar-container>
  </div>
  <div class="flex-container-row" *ngIf="clientID">
    <div *ngIf="files.length > 1" [@.disabled]="true" class="container">
      <mat-tab-group>
        <mat-tab
          *ngFor="let file of files; let index = index"
          [label]="file"
        >
          <app-display-file-container
            *ngIf="isFilePDF(file)"
            [isPDF]="isFilePDF(files[0])"
            [file]="file"
            [ngStyle]="{ height: getHeight() + 'px' }"
          ></app-display-file-container>
          <app-display-file-container
            *ngIf="isFileMP4(file)"
            [isPDF]="isFilePDF(files[0])"
            [file]="file"
            [ngStyle]="{ height: getHeight() + 'px' }"
          ></app-display-file-container>
        </mat-tab>
      </mat-tab-group>
    </div>
    <app-display-file-container
      *ngIf="files.length === 1"
      [isPDF]="isFilePDF(files[0])"
      [file]="files[0]"
      [ngStyle]="{ height: getHeight() + 'px' }"
    ></app-display-file-container>
  </div>
</div>
