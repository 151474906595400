import { Workbook, WorksheetView } from 'exceljs'
import { DatePipe } from '@angular/common'
import { clone, sum } from 'ramda'
import { ReinsurerExportService } from 'src/app/reinsurers/export-service/reinsurer-export.service'
import { QuoteExcelWorksheet } from './quote-excel-worksheet'
import { ExcelExportService } from '@shared/services/excel-export.service'
import { QuoteExcelUtils } from './quote-excel.utils'
import {
  Fills,
  Fonts,
  fotSummaryRowIds,
  QuoteExcelParams,
  QuoteExportRowValue,
} from './quote-excel.model'

export default class QuoteExcel extends QuoteExcelWorksheet {
  private fileName = 'export'
  private exportFileName = 'export'

  constructor(
    workbook: Workbook,
    datePipe: DatePipe,
    quoteParams: QuoteExcelParams,
    private reinsurerExportService: ReinsurerExportService,
    exportService: ExcelExportService,
    utils: QuoteExcelUtils
  ) {
    const copiedQuoteParams = clone(quoteParams)
    const titleName = copiedQuoteParams.oppName
      ? copiedQuoteParams.oppName
      : copiedQuoteParams.clientName

    let effDate = ''
    if (copiedQuoteParams.oppDate) {
      const parts = copiedQuoteParams.oppDate.split('-')
      effDate =
        datePipe.transform(
          new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10)
          ).toString(),
          'longDate'
        ) || ''
    }
    const effectiveDate = 'Effective: ' + effDate
    const dateAsOf = 'As of ' + datePipe.transform(new Date(), 'longDate')

    super(
      workbook,
      copiedQuoteParams,
      titleName,
      effectiveDate,
      dateAsOf,
      datePipe,
      exportService,
      utils
    )
    this.quoteParams = copiedQuoteParams
    this.titleName = titleName

    this.effectiveDate = effectiveDate
    this.dateAsOf = dateAsOf
    if (this.quoteParams.clientName && this.quoteParams.programName) {
      const { clientName, programName } = this.quoteParams
      const date = this.datePipe.transform(new Date(), 'MM.dd.yyyy')
      this.fileName = `${clientName} ${programName} Quotes ${date}.xlsx`
      this.exportFileName = `${clientName} ${programName} Quotes .xlsx`
    }
  }

  export() {
    this.addQuoteWorksheet()
    this.addQuotedContractRequestsWorksheet()
    if (!this.isDefaultExport) {
      this.addFOTSummaryWorksheet()
    }
    this.addAuthAndSignedLinesWorksheet()
    this.addFotContractRequestsWorksheet()
    this.saveFile()
  }

  private addQuotedContractRequestsWorksheet() {
    const worksheet = this.exportService.appendWorksheet(
      this.workbook,
      'Quoted Contract Requests'
    )

    this.appendTitleRowsToSheet(worksheet, 'Quoted Contract Requests')
    this.exportService.appendBlankRows(worksheet)
    // Tracking Module
    const trackingRow2 = this.exportService.appendDataRow(
      worksheet,
      ['Contract requests/exclusions and document requests'],
      {
        fill: Fills.Solid_Gray,
        font: Fonts.Segoe_8_White_Bold,
      }
    )
    let maxRows = 0
    // Add tracking column row
    const trackingColumnRow2 = worksheet.addRow(
      this.quoteParams.trackingColumns
    )

    if (this.quoteParams.trackingColumns.length > maxRows) {
      maxRows = this.quoteParams.trackingColumns.length
    }
    trackingColumnRow2.height = 30
    trackingColumnRow2.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
    })
    // Merge Cells
    worksheet.mergeCells(
      `A${trackingRow2.number}:${this.exportService.getColLetter(
        trackingColumnRow2.actualCellCount - 1
      )}${trackingRow2.number}`
    )

    this.quoteParams.trackingRows2.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet.addRow(row)
      DataRow.eachCell(cell => {
        cell.alignment = { wrapText: true }
        cell.font = Fonts.Segoe_8
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' },
          },
        }
      })
    })
    if (maxRows > 0) {
      let count = maxRows
      while (count > 0) {
        worksheet.getColumn(count).width = 20
        count--
      }
    }
    // Blank Row
    this.exportService.appendBlankRows(worksheet)
    this.exportService.appendLogos(worksheet, this.workbook, 3, 5)
  }

  private addAuthAndSignedLinesWorksheet() {
    const extraViews: Partial<WorksheetView> = !this.isDefaultExport
      ? { state: 'frozen', ySplit: 9 }
      : {}
    const worksheet = this.exportService.appendWorksheet(
      this.workbook,
      'Auth & Signed Lines',
      extraViews
    )
    this.appendTitleRowsToSheet(worksheet, 'Authorizations and Signed Lines')
    this.exportService.appendBlankRows(worksheet)

    let maxRows3 = 0
    // Add Assigned Lined Logic
    const assignedLinesNameRow = worksheet.addRow(
      this.quoteParams.assignedLinesColumns
    )
    if (this.quoteParams.assignedLinesColumns.length > maxRows3) {
      maxRows3 = this.quoteParams.assignedLinesColumns.length
    }
    const arr: string[] = []
    assignedLinesNameRow.eachCell((cell, i) => {
      const startCol = `${this.exportService.getColLetter(i - 1)}`
      const expiringLength = this.isDefaultExport ? 4 : 2
      const fotLength = this.isDefaultExport ? 11 : 5
      const exLengthCol = `${this.exportService.getColLetter(i - 1 + expiringLength)}`
      const fotLengthCol = `${this.exportService.getColLetter(i - 1 + fotLength)}`

      if (cell.value !== ' ' && cell.value !== 'fill') {
        if (this.quoteParams.structureGroupName) {
          cell.value = this.quoteParams.structureGroupName + ' ' + cell.value
        }
        if (this.quoteParams.sharedLimitName) {
          cell.value = this.quoteParams.sharedLimitName + ' ' + cell.value
        }
        cell.font = Fonts.Segoe_8_White_Bold
        if (this.isDefaultExport) {
          cell.alignment = { wrapText: true }
          cell.fill = Fills.Solid_Gray
        } else {
          if (String(cell.value).includes('Expiring')) {
            // Expiring Styling
            cell.fill = Fills.Solid_Gray
          } else {
            // FOT Styling
            cell.fill = Fills.Solid_Black
          }
        }
        if (
          worksheet.getCell(exLengthCol + assignedLinesNameRow.number).value !==
          'fill'
        ) {
          arr.push(
            `${startCol}${assignedLinesNameRow.number}:${this.exportService.getColLetter(
              i - 2 + expiringLength
            )}${assignedLinesNameRow.number}`
          )
        } else if (
          worksheet.getCell(fotLengthCol + assignedLinesNameRow.number)
            .value !== 'fill'
        ) {
          arr.push(
            `${startCol}${assignedLinesNameRow.number}:${this.exportService.getColLetter(
              i - 2 + fotLength
            )}${assignedLinesNameRow.number}`
          )
        }
      }
    })
    arr.forEach(n => {
      worksheet.mergeCells(n)
    })

    const rowAL: string[] = []
    if (this.isDefaultExport) {
      // Push non-XOL Export fields
      rowAL.push('Reinsurer', 'Bureau Stamp', 'TP Ref')
    }
    if (
      this.quoteParams.assignedLinesRows &&
      this.quoteParams.assignedLinesRows.length > 0
    ) {
      this.quoteParams.assignedLinesRows[0].forEach(a => {
        // Removes spacing lines by filtering out ' '
        if (a.length > 1 || a[0].toString().trim() !== '') {
          const headerText = this.utils.convertTempAssignedLinesValsToText(
            String(a[2]),
            this.isDefaultExport
          )
          if (headerText) {
            rowAL.push(headerText)
          }
        } else {
          rowAL.push(' ')
        }
      })
    }
    const columnNamesALRow = worksheet.addRow(rowAL)
    columnNamesALRow.eachCell(cell => {
      if (cell.value !== ' ') {
        cell.alignment = { horizontal: 'center', wrapText: true }
        cell.fill = Fills.Solid_Blue
        cell.font = Fonts.Segoe_8_White_Bold
        if (
          cell.value === 'Reinsurer' ||
          cell.value === 'Bureau Stamp' ||
          cell.value === 'TP Ref'
        ) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      }
    })
    // Add table data
    const total: QuoteExportRowValue[] = []
    this.quoteParams.assignedLinesRows = [...this.quoteParams.assignedLinesRows]
    this.quoteParams.assignedLinesRows.sort((a, b) => {
      const i = a.findIndex(e => e[0] === 'Lockton Re')
      const j = b.findIndex(e => e[0] === 'Lockton Re')

      const stringifyAtIndex = (
        val: QuoteExportRowValue[][],
        index: number,
        secIndex: number
      ): string | undefined =>
        val[index] ? String(val[index][secIndex]) : undefined

      const a0 = stringifyAtIndex(a, i, 0)
      const b0 = stringifyAtIndex(b, i, 0)
      const a1 = stringifyAtIndex(a, i + 1, 0)
      const b1 = stringifyAtIndex(b, i + 1, 0)
      if (
        (i !== -1 && j !== -1 && i < j) ||
        (a0 && b0 && a0.localeCompare(b0) === -1) ||
        (a1 && b1 && a1.localeCompare(b1) === -1)
      ) {
        return -1
      } else {
        return 1
      }
    })
    if (
      this.quoteParams.assignedLinesRows &&
      this.quoteParams.assignedLinesRows.length > 0
    ) {
      this.quoteParams.assignedLinesRows[0].forEach(a1 => {
        if (a1[2] === 'name') {
          total.push('Grand Total')
        } else if (
          a1[2] === 'written%' ||
          a1[2] === 'written$' ||
          a1[2] === 'recommended%' ||
          a1[2] === 'signed%' ||
          a1[2] === 'signed$'
        ) {
          total.push(0)
        } else {
          total.push(' ')
        }
      })
    }

    this.quoteParams.assignedLinesRows.forEach(row => {
      const r: QuoteExportRowValue[] = []
      row?.forEach((r1: QuoteExportRowValue[]) => {
        if (r1.length > 1 && r1[0] !== ' ') {
          r.push(r1[0])
        } else {
          r.push(' ')
        }
      })
      const DataRow = worksheet.addRow(r)

      DataRow.eachCell((cell, j) => {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = Fonts.Segoe_8
        cell.border = this.utils.getAssignedLinesBorderByIndex(
          this.isDefaultExport,
          worksheet,
          cell
        )
        if (row[j - 1] && row[j - 1][1] === 'percentage') {
          {
            cell.numFmt = '0.0000%'
            if (
              row[j - 1][2] === 'written%' ||
              row[j - 1][2] === 'recommended%' ||
              row[j - 1][2] === 'signed%'
            ) {
              total[j - 1] = Number(total[j - 1]) + Number(row[j - 1][0])
            }
          }
        } else if (row[j - 1][1] === 'currency') {
          if (
            cell.value && typeof cell.value === 'number'
              ? cell.value % 1 !== 0
              : false
          ) {
            cell.numFmt = `#,##0.0000`
          } else {
            cell.numFmt = `#,##0`
          }
          if (row[j - 1][2] === 'written$' || row[j - 1][2] === 'signed$') {
            total[j - 1] = Number(total[j - 1]) + Number(row[j - 1][0])
          }
        }
        if (j === 1 || (j === 2 && this.isDefaultExport)) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      })
    })

    const DataTotalRow = worksheet.addRow(total)
    DataTotalRow.eachCell((cell, l) => {
      if (rowAL[l - 1] !== ' ') {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = Fonts.Segoe_8_White_Bold
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        }
        if (
          this.quoteParams.assignedLinesRows[0][l - 1] &&
          this.quoteParams.assignedLinesRows[0][l - 1][1] === 'percentage'
        ) {
          {
            // cell.alignment = { horizontal: 'right' }
            cell.numFmt = '0.0000%'
          }
        } else if (
          this.quoteParams.assignedLinesRows[0][l - 1][1] === 'currency'
        ) {
          if (
            cell.value && typeof cell.value === 'number'
              ? cell.value % 1 !== 0
              : false
          ) {
            cell.numFmt = `#,##0.0000`
          } else {
            cell.numFmt = `#,##0`
          }
        }
        if (l === 1) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }

        const colVals: string[] = []
        worksheet.getColumn(l).eachCell(cell => {
          colVals.push(cell.value?.toString() ?? undefined)
        })
        const noNumericCellsInColumn = colVals
          .slice(0, -1)
          .every(cellVal => isNaN(Number(cellVal)))
        if (noNumericCellsInColumn) {
          cell.value = '-'
          cell.numFmt = undefined
        }
      }
    })

    this.exportService.appendBlankRows(worksheet, 2)

    // Tracking Module
    const trackingRowAL = worksheet.addRow(['Subjectivities & Notes'])
    trackingRowAL.getCell(1).fill = Fills.Solid_Gray
    trackingRowAL.getCell(1).font = Fonts.Segoe_8_White_Bold
    const aLColumns = clone(this.quoteParams.trackingColumns)
    aLColumns.splice(1, 0, 'TP Ref')
    aLColumns.splice(1, 0, 'Bureau Stamp')
    // Add tracking column row
    const trackingColumnRowAL = worksheet.addRow(aLColumns)

    if (aLColumns.length > maxRows3) {
      maxRows3 = aLColumns.length
    }
    trackingColumnRowAL.height = 30
    trackingColumnRowAL.eachCell((cell, m) => {
      cell.alignment = { wrapText: true, horizontal: 'center' }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
      if (m === 1 || m === 2) {
        cell.alignment = { horizontal: 'left', wrapText: true }
      }
    })
    // Merge Cells
    worksheet.mergeCells(
      `A${trackingRowAL.number}:${this.exportService.getColLetter(
        trackingColumnRowAL.actualCellCount - 1
      )}${trackingRowAL.number}`
    )

    this.quoteParams.trackingRowsAL1.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const updatedRow = clone(row)
      let found = false
      this.quoteParams.assignedLinesRows.forEach(al => {
        if (al[0] && al[0][0] && String(row[0]).includes(String(al[0][0]))) {
          updatedRow.splice(1, 0, al[2][0])
          updatedRow.splice(1, 0, al[1][0])
          found = true
        }
      })
      if (!found) {
        updatedRow.splice(1, 0, ' ')
        updatedRow.splice(1, 0, ' ')
      }
      const DataRow = worksheet.addRow(updatedRow)
      DataRow.eachCell((cell, n) => {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = Fonts.Segoe_8
        if (cell.value !== '') {
          cell.border = {
            bottom: {
              style: 'thin',
              color: { argb: '000000' },
            },
          }
        }
        if (n === 1 || n === 2) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      })
    })

    this.exportService.appendBlankRows(worksheet, 2)

    if (maxRows3 > 0) {
      let count = maxRows3
      while (count > 0) {
        worksheet.getColumn(count).width = 20
        count--
      }
    }
    worksheet.getColumn(1).width = 30
    if (maxRows3 > 0) {
      let count = maxRows3
      while (count > 1) {
        worksheet.getColumn(count).width = 12.2
        count--
      }
    }

    this.exportService.appendLogos(worksheet, this.workbook, 5, 8)
  }

  private addFotContractRequestsWorksheet() {
    const worksheet = this.exportService.appendWorksheet(
      this.workbook,
      'FOT Contract Requests'
    )
    this.appendTitleRowsToSheet(worksheet, 'FOT Contract Requests')
    this.exportService.appendBlankRows(worksheet)

    // Tracking Module
    const trackingRow4 = worksheet.addRow([
      'Contract requests/exclusions and document requests',
    ])
    trackingRow4.getCell(1).fill = Fills.Solid_Gray
    trackingRow4.getCell(1).font = Fonts.Segoe_8_White_Bold
    let maxRows4 = 0
    // Add tracking column row
    const trackingColALRow4 = [
      ...this.quoteParams.trackingColumns,
      'Final Contract Changes',
      'Final Signature Page Changes',
    ]

    const trackingColumnRow4 = worksheet.addRow(trackingColALRow4)

    if (trackingColALRow4.length > maxRows4) {
      maxRows4 = trackingColALRow4.length
    }
    trackingColumnRow4.height = 30
    trackingColumnRow4.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
    })
    // Merge Cells
    worksheet.mergeCells(
      `A${trackingRow4.number}:${this.exportService.getColLetter(
        trackingColumnRow4.actualCellCount - 1
      )}${trackingRow4.number}`
    )
    this.quoteParams.trackingRowsAL2.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet.addRow(row)
      DataRow.eachCell(cell => {
        cell.alignment = { wrapText: true }
        cell.font = Fonts.Segoe_8
        cell.border = {
          bottom: {
            style: 'thin',
          },
        }
      })
    })
    if (maxRows4 > 0) {
      let count = maxRows4
      while (count > 0) {
        worksheet.getColumn(count).width = 20
        count--
      }
    }
    // Blank Row
    this.exportService.appendLogos(worksheet, this.workbook, 5, 8)
  }

  private addFOTSummaryWorksheet() {
    const worksheet = this.exportService.appendWorksheet(this.workbook, 'FOT')
    this.appendTitleRowsToSheet(worksheet, 'FOT Summary')
    this.exportService.appendBlankRows(worksheet)

    this.utils.addXOLSubjectPremiumSummaryToWorksheet(
      worksheet,
      this.quoteParams
    )

    if (this.quoteParams.fotSummaryRows.length) {
      const headerSize = 2
      const layerRowSize = fotSummaryRowIds.length + headerSize

      const headerLength =
        this.utils.findLongestArrayLength(this.quoteParams.fotSummaryRows) - 2

      const premiumRowNumbers: number[] = []
      this.quoteParams.fotSummaryRows.forEach((row, index) => {
        const worksheetRow = worksheet.addRow(row.map(val => val[0]))
        if (index % layerRowSize === 0 || index % layerRowSize === 1) {
          // Header row
          worksheetRow.eachCell((cell, colNumber) => {
            cell.font = Fonts.Segoe_8_White_Bold
            if (colNumber <= headerLength || colNumber === row.length) {
              cell.fill =
                index % layerRowSize === 0 ? Fills.Solid_Gray : Fills.Solid_Blue
            }
          })
        } else {
          if (index % layerRowSize === 3) {
            premiumRowNumbers.push(worksheetRow.number)
          }
          worksheetRow.eachCell((cell, colNum) => {
            const val = row[colNum - 1]

            cell.style = {
              font: Fonts.Segoe_8,
              alignment: { horizontal: 'right', wrapText: true },
            }

            if (
              !this.utils.isCellInEmptyColumn(worksheet, cell, undefined, true)
            ) {
              cell.border = {
                bottom: {
                  style: 'thin',
                  color: { argb: '000000' },
                },
              }
            }

            if (val[1] === 'percentage') {
              cell.numFmt = '0.00%'
            } else if (val[1] === 'currency') {
              cell.numFmt = this.utils.formatCellAsCurrency(
                String(val[0]).charAt(0),
                false
              )
              cell.value = this.utils.removeCurrencySymbolFromCell(cell)
            }
          })
        }
      })

      // Get Layer Occurrence Limit
      const xolSubjectPremiumRows = this.quoteParams.xolSubjectPremiumRows
      const subjectPremiumRows = xolSubjectPremiumRows.slice(2)
      const layerOccLimitCells = subjectPremiumRows.map((_, i) => `B${i + 11}`)
      const layerOccLimitCellVals = layerOccLimitCells.map(cell =>
        Number(worksheet.getCell(cell).value)
      )

      let totalExpiring = 0
      let totalFot = 0

      const totalLayerLimit = sum(layerOccLimitCellVals)
      const layerEquation = '(' + layerOccLimitCells.join('+') + ')'
      const longestExpiring = this.utils.findLongestArrayLength(
        this.quoteParams.expiringReinsurerColumns
      )

      const indicesPerSection =
        this.quoteParams.fotSummaryRows.length / this.quoteParams.headers.length

      // Grab the first row from each section for the version headers
      const sectionHeaders = this.quoteParams.fotSummaryRows
        .filter((_, i) => i % indicesPerSection === 1)
        .map(val => val.slice(1))

      const rolOccValsAndEquations = sectionHeaders[0].map((_, i) => {
        if (i !== sectionHeaders[0].length - 2) {
          const col = this.exportService.getColLetter(i + 1)
          const cells = premiumRowNumbers
            .map(val => `${col}${val}`)
            .filter(
              cellPos => String(worksheet.getCell(cellPos).value).trim().length
            )
          const equation = '(' + cells.join('+') + ')'

          const cellVals = cells.map(cell =>
            Number(worksheet.getCell(cell).value)
          )
          const cellTotal = sum(cellVals)
          const result = cellTotal / totalLayerLimit

          if (i < longestExpiring) {
            totalExpiring += result
          } else {
            totalFot += result
          }

          return [result, `=${equation}/${layerEquation}`]
        } else {
          return []
        }
      })

      // Subtract 1 for the label
      const neededSpaces =
        this.utils.findLongestArrayLength(this.quoteParams.fotSummaryRows) - 1
      const totalRateTitleRow = worksheet.addRow([
        'Total Rate',
        ...this.utils.getSpacesForCurrentRow(neededSpaces),
      ])
      totalRateTitleRow.eachCell(cell => {
        if (!this.utils.isCellInEmptyColumn(worksheet, cell)) {
          cell.fill = Fills.Solid_Black
          cell.font = Fonts.Segoe_8_White_Bold
        }
      })

      const rolTotalRowValues = [
        'Rate On Line, Occurrence',
        ...this.utils.getSpacesForCurrentRow(neededSpaces),
      ]
      const rolTotalRow = worksheet.addRow(rolTotalRowValues)
      rolTotalRow.eachCell((cell, colNum) => {
        cell.style = {
          font: Fonts.Segoe_8,
          alignment: { horizontal: 'right', wrapText: true },
        }

        if (!this.utils.isCellInEmptyColumn(worksheet, cell)) {
          cell.style = {
            ...cell.style,
            border: {
              bottom: {
                style: 'thin',
                color: { argb: '000000' },
              },
            },
          }
        }
        cell.numFmt = '0.00%'

        // Skip the first cell as it is just the label text
        if (colNum !== 1) {
          const cellDetails = rolOccValsAndEquations[colNum - 2]
          if (!!cellDetails?.length) {
            if (colNum === rolTotalRowValues.length) {
              // YoY Rate % Change
              cell.value = !!totalExpiring
                ? (totalFot - totalExpiring) / totalExpiring
                : 'Infinity'
            } else {
              cell.value = {
                result: cellDetails[0],
                formula: String(cellDetails[1]),
                date1904: false,
              }
            }
          }
        }
      })
    }

    this.exportService.autoSizeColumns(worksheet, undefined, 30)
  }

  private saveFile() {
    const captureExportCallback = (blob: Blob) => {
      const base64FromBlob = (blob1: Blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob1)
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      }
      base64FromBlob(blob).then(base64Response => {
        this.reinsurerExportService
          .captureExportDetails(
            base64Response,
            this.exportFileName,
            'QuoteSelection'
          )
          .pipe()
          .subscribe()
      })
    }
    this.exportService.exportAsXlsx(
      this.workbook,
      this.fileName,
      captureExportCallback.bind(this)
    )
  }
}
