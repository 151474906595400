import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  MessageDialogComponent,
  MessageDialogData,
} from './message-dialog-component'

@Injectable()
export class MessageDialogService {
  constructor(private dialog: MatDialog) {}

  showMessage(title: string, message?: string, settings?: {}) {
    const data: MessageDialogData = {
      title,
      message,
    }
    this.dialog.open(MessageDialogComponent, { ...settings, data })
  }
}
