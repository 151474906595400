import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { append, assoc, dissoc, lensPath, set, view } from 'ramda'
import { Program } from '../../../core/model/program.model'
import { reduceReducers } from '../../../shared/util/reduce-reducers'
import { CompareMetricCategory } from '../../model/compare-metrics.model'
import { Layer } from '../../model/layers.model'
import * as fromAnalysisActions from '../../store/analysis.actions'
import * as fromLayers from '../ceded-layers/layers.reducer'
import * as fromMetricSettingsActions from './compare-metric-settings/compare-metric-settings.actions'
import * as fromMetricSettings from './compare-metric-settings/compare-metric-settings.reducer'
import * as fromView from './compare-view/compare-view.reducer'
import * as compareViewActions from './compare-view/compare-view.actions'
import * as fromCompareActions from './compare.actions'
import * as fromCompareProgramGroupActions from './program-group-compare/program-group-compare.actions'
import * as OptimizationActions from '../../optimization/store/optimization-candidates-results.actions'

interface ExtendedState {
  view: fromView.CompareViewState
  metricSettings: fromMetricSettings.State
  expandedCategories: Record<string, boolean>
  hiddenMetricRanks: Record<string, boolean>
  expandedChangeMetrics: Record<string, boolean>
  programGroups: string[]
  grossSelected: boolean
  compareCurrency: string | null
  compareConversion: string | null
  tabIndex: number
  structureOptions: StructureOptions
}

export interface CompareEntity {
  program: Program
  cededLayers: Layer[]
  metricCategories: CompareMetricCategory[]
  probAttachment: number
  probExhaust: number
  loading: boolean
  error: string | null
}

export interface StructureOptions {
  x: string
  y: string
  size: string
}

export const COMPARE_KEY = 'compare'

export type State = EntityState<CompareEntity> & ExtendedState

export const adapter = createEntityAdapter<CompareEntity>({
  selectId: entity => entity.program.id,
})

export const initialState: State = adapter.getInitialState<ExtendedState>({
  view: fromView.initialState,
  metricSettings: fromMetricSettings.initialState,
  expandedCategories: {},
  hiddenMetricRanks: {},
  expandedChangeMetrics: {},
  programGroups: [],
  grossSelected: false,
  compareCurrency: null,
  compareConversion: null,
  tabIndex: 0,
  structureOptions: {
    x: 'Ceded Cost Metrics Expected Ceded Margin',
    y: '',
    size: 'None ',
  },
})

const initialEntity: Partial<CompareEntity> = {
  cededLayers: [],
  metricCategories: [],
  loading: false,
  error: null,
}

const createNewEntity = (program: Program): CompareEntity =>
  ({
    ...initialEntity,
    program,
  } as CompareEntity)

const compareEntitiesReducer = createReducer(
  initialState,

  on(fromCompareActions.addProgramToCompare, (state, { program }) => {
    return adapter.addOne({ ...createNewEntity(program), loading: true }, state)
  }),

  on(fromCompareActions.addProgramToCompareFailure, (state, { id, error }) => {
    return adapter.updateOne(
      { id, changes: { error: error.message, loading: initialEntity.loading } },
      state
    )
  }),

  on(
    fromCompareActions.addProgramToCompareSuccess,
    (state, { id, cededLayers }) => {
      return adapter.updateOne(
        {
          id,
          changes: {
            error: initialEntity.error,
            loading: initialEntity.loading,
            cededLayers,
          },
        },
        state
      )
    }
  ),

  on(fromCompareActions.removeProgramFromCompare, (state, { id }) =>
    adapter.removeOne(id, state)
  ),

  on(
    fromCompareProgramGroupActions.removeCompareProgramGroup,
    (state, { id }) => adapter.removeOne(id, state)
  ),

  on(
    fromCompareActions.setProgramFromCompare,
    (state, { id, check, program }) => {
      return adapter.updateOne(
        { id, changes: { program: { ...program, checkCompare: check } } },
        state
      )
    }
  ),

  on(
    fromCompareActions.setSelectedProgramFromCompare,
    (state, { id, selected, program }) => {
      return adapter.updateOne(
        { id, changes: { program: { ...program, groupSelected: selected } } },
        state
      )
    }
  ),

  on(
    fromMetricSettingsActions.fetchCompareMetricValuesSuccess,
    (state, { programID, categories }) => {
      const entity = state.entities[programID] as CompareEntity
      const modifiedCategories = JSON.parse(JSON.stringify(categories))
      const miscIndex = categories.findIndex((category: any) => {
        return category.category === 'Misc'
      })
      if (miscIndex !== -1) {
        modifiedCategories[miscIndex].metrics.forEach((category: any) => {
          if (category[0].label === 'Probability of Attach (Agg)') {
            category[0].value = entity.probAttachment
          } else if (category[0].label === 'Probability of Exhaust (Agg)') {
            category[0].value = entity.probExhaust
          }
        })
      }
      const changes = { ...entity, metricCategories: modifiedCategories }
      return adapter.updateOne({ id: programID, changes }, state)
    }
  ),

  on(
    fromMetricSettingsActions.fetchCustomCompareMetricValues,
    fromMetricSettingsActions.updateCustomRanks,
    (state, { programID, categories }) => {
      const entity = state.entities[programID] as CompareEntity
      const changes = { ...entity, metricCategories: categories }
      return adapter.updateOne({ id: programID, changes }, state)
    }
  ),

  on(compareViewActions.deleteCompareViewSuccess, () => {
    return initialState
  }),

  on(
    fromMetricSettingsActions.setCompareMetricProbabilityValues,
    (state, { programID, probabilityAttachment, probabilityExhaust }) => {
      const entity = state.entities[programID] as CompareEntity
      let changes = {
        ...entity,
        probAttachment: probabilityAttachment,
        probExhaust: probabilityExhaust,
      }
      if (entity) {
        const metricCategories = JSON.parse(
          JSON.stringify(
            (state.entities[programID] as CompareEntity).metricCategories
          )
        )
        if (metricCategories) {
          const miscIndex = metricCategories.findIndex((category: any) => {
            return category.category === 'Misc'
          })
          if (miscIndex !== -1) {
            metricCategories[miscIndex].metrics.forEach((metric: any) => {
              if (metric[0].label === 'Probability of Attach (Agg)') {
                metric[0].value = probabilityAttachment
              } else if (metric[0].label === 'Probability of Exhaust (Agg)') {
                metric[0].value = probabilityExhaust
              }
            })
          }
        }
        changes = {
          ...changes,
          metricCategories,
        }
      }
      return adapter.updateOne({ id: programID, changes }, state)
    }
  ),

  on(fromCompareActions.setCompareProgramGroup, (state, { id }) => {
    return { ...state, programGroups: [...state.programGroups, id] }
  }),

  on(
    fromCompareActions.setCompareProgramGroupMembersSuccess,
    (state, { imageSrcGroup, id }) => {
      const entity = state.entities[id]
      if (!entity) {
        return state
      }
      return adapter.upsertOne(
        {
          ...entity,
          program: {
            ...entity.program,
            imageSrcGroup,
          },
        },
        state
      )
    }
  ),

  on(fromCompareActions.removeCompareProgramGroup, (state, { id }) => {
    const newProgramGroups = state.programGroups.filter(pId => {
      return pId !== id
    })
    return { ...state, programGroups: newProgramGroups }
  }),

  on(
    fromCompareActions.updateCompareGrossSelected,
    (state, { grossSelected }) => {
      return { ...state, grossSelected }
    }
  ),

  on(fromCompareActions.compareCurrency, (state, { compareCurrency }) => {
    return { ...state, compareCurrency }
  }),

  on(fromCompareActions.compareConversion, (state, { compareConversion }) => {
    return { ...state, compareConversion }
  }),

  on(fromCompareActions.updateCompareEntityIndex, (state, { indexArray }) => {
    return { ...state, ids: indexArray }
  }),

  on(fromAnalysisActions.saveAsAnalysisSuccess, (state, { program }) => {
    if (program.isScenario && program.parentScenarioID) {
      // Scenario is being added, add its ID to its parent's scenario IDs
      const id = program.parentScenarioID
      const parent = state.entities[id]
      if (parent) {
        const prev = view(scenarioIDsLens, parent) as string[] | undefined
        const next = append(program.id, prev ?? [])
        const changes = set(scenarioIDsLens, next, parent)
        return adapter.updateOne({ id, changes }, state)
      }
    }
    return state
  }),

  on(OptimizationActions.saveSuccess, (state, { details }) => {
    if (details.length === 0) {
      return state
    }
    const parentOptimizationID = details[0].program
      .parentOptimizationID as string
    const parent = state.entities[parentOptimizationID]
    const optimizationIDs = details.map(d => d.program.id)
    if (parent) {
      const prev = view(optimizationIDsLens, parent) as string[] | undefined
      const next = prev ? [...prev, ...optimizationIDs] : optimizationIDs
      const changes = set(optimizationIDsLens, next, parent)
      return adapter.updateOne({ id: parentOptimizationID, changes }, state)
    } else {
      return state
    }
  })
)

const expandedCategoriesReducer = createReducer(
  initialState.expandedCategories,
  on(
    fromCompareActions.setCompareExpandedMetricCategory,
    (state, { category, value }) => {
      const expand = value != null ? value : !state[category]
      return expand ? assoc(category, true, state) : dissoc(category, state)
    }
  )
)

const hiddenMetricRanksReducer = createReducer(
  initialState.hiddenMetricRanks,
  on(
    fromCompareActions.setCompareHiddenMetricRank,
    (state, { categoryLabel, value }) => {
      const expand = value != null ? value : !state[categoryLabel]
      return expand
        ? assoc(categoryLabel, true, state)
        : dissoc(categoryLabel, state)
    }
  )
)

const expandedChangeMetricsReducer = createReducer(
  initialState.expandedChangeMetrics,
  on(
    fromCompareActions.setCompareExpandedChangeMetric,
    (state, { categoryLabel, value }) => {
      const expand = value != null ? value : !state[categoryLabel]
      return expand
        ? assoc(categoryLabel, true, state)
        : dissoc(categoryLabel, state)
    }
  )
)

const tabIndexReducer = createReducer(
  initialState.tabIndex,
  on(fromCompareActions.setTabIndex, (_, { tabIndex }) => {
    return tabIndex
  })
)

const structureOptionsReducer = createReducer(
  initialState.structureOptions,
  on(
    fromCompareActions.setCompareStructureOptionsDimensionProp,
    (state, { dimension, prop }) => ({
      ...state,
      [dimension]: prop,
    })
  )
)

function compareReducer(state: State | undefined, action: Action): State {
  return {
    ...compareEntitiesReducer(state, action),
    view: fromView.reducer(state && state.view, action),
    metricSettings: fromMetricSettings.reducer(
      state && state.metricSettings,
      action
    ),
    expandedCategories: expandedCategoriesReducer(
      state && state.expandedCategories,
      action
    ),
    hiddenMetricRanks: hiddenMetricRanksReducer(
      state && state.hiddenMetricRanks,
      action
    ),
    expandedChangeMetrics: expandedChangeMetricsReducer(
      state && state.expandedChangeMetrics,
      action
    ),
    structureOptions: structureOptionsReducer(
      state && state.structureOptions,
      action
    ),
    tabIndex: tabIndexReducer(state && state.tabIndex, action),
  }
}

const updateSettingsStudyIDReducer = createReducer(
  initialState,
  // Remove the set study ID if all programs are removed from compare page
  on(
    fromCompareActions.removeProgramFromCompare,
    fromCompareProgramGroupActions.removeCompareProgramGroup,
    state => {
      if (state.ids.length !== 0) {
        return state
      }
      return {
        ...state,
        metricSettings: {
          ...state.metricSettings,
          studyID: null,
        },
      }
    }
  )
)

const reducedReducer = reduceReducers(
  compareReducer,
  updateSettingsStudyIDReducer
)

export function reducer(state: State | undefined, action: Action) {
  return reducedReducer(state, action)
}

// Selectors

const scenarioIDsLens = lensPath(['program', 'scenarioIDs'])

export const selectLayersList = (
  clientID: string | null,
  entities: CompareEntity[]
): fromLayers.LayerState[][] =>
  clientID != null
    ? entities.map(entity =>
        entity.cededLayers.map(layer => ({
          ...fromLayers.initialLayerState,
          layer,
        }))
      )
    : []

const optimizationIDsLens = lensPath(['program', 'optimizationIDs'])
