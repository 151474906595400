import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { LossSetLayer } from '../model/loss-set-layers.model'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { Dictionary } from '@ngrx/entity'
import { Program } from 'src/app/core/model/program.model'
import { StudyResponse } from 'src/app/api/model/backend.model'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import {
  DeleteInuranceFromDesign,
  InuranceMetadataRef,
  InuranceReference,
  InuranceRelationship,
  InuranceView,
} from '../model/inurance.model'
import {
  createLayerInuranceView,
  createStructureInuranceView,
  toInuranceRefs,
} from '../model/inurance.util'
import { ProgramGroup } from '../store/grouper/program-group.model'
import { isMultiSectionLayer, isSectionLayer } from './multi-section-layer'
import { COMPLEX_LAYERS, layerIds } from '../model/layer-palette.model'
import { uniqBy } from 'ramda'
import { isSwingLayer } from './swing-layer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-inurance-layers',
  styles: [
    `
      section {
        display: flex;
        flex-direction: column;
        background: var(--bg-2);
        border-radius: 6px;
      }

      .layers {
        overflow: auto;
        height: 100%;
      }

      .button-clone {
        margin-right: 0;
        border-bottom: 1px solid var(--border-2);
        cursor: pointer;
      }

      .button-clone:last-of-type {
        border: none;
      }

      .parent {
        display: flex;
        align-items: center;
      }

      .child {
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        color: var(--accent);
        text-overflow: clip;
        font-family: var(--font-header-family);
        font-weight: var(--font-link-weight);
        font-size: var(--font-size);
        padding: 6px;
      }

      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      ::ng-deep .add-menu {
        max-width: none; /* So that the menu is not too narrow */
      }
    `,
  ],
  template: `
    <section *ngIf="_selectedLayer && !inuranceLoading" class="inurance-layers">
      <ng-container *ngFor="let label of inuranceLabels">
        <div class="button-clone">
          <div class="parent">
            <div
              matTooltip="{{ label.name }}"
              matTooltipPosition="above"
              class="child"
              [matMenuTriggerFor]="editMenu"
            >
              {{ label.name }}
            </div>
          </div>
        </div>
        <div>
          <mat-menu #editMenu="matMenu">
            <button
              mat-menu-item
              (click)="
                onDeleteOrEditClick(
                  label.id,
                  label.relationship,
                  'delete',
                  label.targetLayer,
                  label.targetStructure,
                  label.targetGroup
                )
              "
            >
              Delete
            </button>
            <button
              mat-menu-item
              (click)="
                onDeleteOrEditClick(
                  label.id,
                  label.relationship,
                  'edit',
                  label.targetLayer,
                  label.targetStructure,
                  label.targetGroup
                )
              "
            >
              Edit
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </section>
    <section *ngIf="_selectedLayer && inuranceLoading">
      <div class="loading">
        <mat-spinner color="accent" [diameter]="40"></mat-spinner>
      </div>
    </section>
  `,
})
export class InuranceLayersComponent {
  _selectedLayer: LayerState
  _inuranceLabels: InuranceLabel[] = []
  refs: InuranceMetadataRef[]

  @Input()
  set selectedLayer(l: LayerState) {
    this._selectedLayer = l
    if (this._selectedLayer) {
      this._inuranceLabels = this.parseAndBuildLabels(this._selectedLayer.layer)
    }
  }
  @Input() layerChoices: LossSetLayer[]
  @Input() programsByID: Dictionary<Program>
  @Input() studies: StudyResponse[]
  @Input() layers: LayerState[]
  @Input() groups: Dictionary<ProgramGroup>
  @Input() inuranceLoading: boolean

  @Output() editInuranceClick = new EventEmitter<{
    currentLayer: Layer
    currentProgram: Program
    layer: LossSetLayer | Layer | undefined
    structure: Program | undefined
    group: ProgramGroup | undefined
    relationship: InuranceRelationship
  }>()
  @Output() deleteInuranceClick = new EventEmitter<DeleteInuranceFromDesign>()

  get lossSetLayers(): LayerRef[] {
    const layerType = this._selectedLayer.layer.meta_data
      .sage_layer_type as layerIds
    const layerSubtype = this._selectedLayer.layer.meta_data.sage_layer_subtype

    if (!layerType) {
      return []
    }

    if (
      COMPLEX_LAYERS.includes(layerType) &&
      layerSubtype === 'visible-layer'
    ) {
      return this.getMainLayerForComplex()
    }

    return this._selectedLayer.layer.lossSetLayers.slice()
  }

  get inuranceLabels(): InuranceLabel[] {
    return this._inuranceLabels
  }

  getMainLayerForComplex(): LayerRef[] {
    const {
      sage_layer_type: selectedLayerType,
      main_layer_id: selectedLayerMainLayerId,
    } = this._selectedLayer.layer.meta_data

    for (const l of this.layers) {
      const { meta_data, id, lossSetLayers } = l.layer

      switch (selectedLayerType) {
        case layerIds.noncatIndxl:
          if (
            meta_data.sage_layer_type === layerIds.noncatIndxl &&
            meta_data.sage_layer_subtype === 'main-layer' &&
            id === selectedLayerMainLayerId
          ) {
            return lossSetLayers.slice()
          }
          break

        case layerIds.noncatSwing:
        case layerIds.ahlSwing:
          if (isSwingLayer({ meta_data }, 'combined-layer')) {
            return lossSetLayers.slice()
          }
          break

        case layerIds.catMultisection:
        case layerIds.noncatMultisection:
          if (
            (meta_data.sage_layer_type === layerIds.catMultisection ||
              meta_data.sage_layer_type === layerIds.noncatMultisection) &&
            meta_data.sage_layer_subtype === 'main-layer' &&
            meta_data.visible_layer_id === this._selectedLayer.layer.id
          ) {
            return lossSetLayers.slice()
          }
          break
      }
    }

    return []
  }

  isInuranceSource(ls: LossSetLayer) {
    if (!this._selectedLayer) {
      return false
    }
    for (const l of this.layerChoices) {
      if (l.id === ls.id) {
        return false
      }
    }
    if (ls.meta_data.inuranceSource) {
      return true
    }
    return false
  }

  getProgram(programID: string | undefined): Program | undefined {
    // tslint:disable-next-line: no-non-null-assertion
    const program = this.programsByID[programID!]
    if (!program) {
      console.error(`No structure found w/ ID ${programID}`)
      return undefined
    }
    return program
  }

  getStudyID(program: Program): number {
    return parseInt(program.studyID, 10)
  }

  getStudy(studyID: number): StudyResponse {
    const study = this.studies.find(element => element.id === studyID)
    if (!study) {
      throw Error(`Cannot get study, none found for id '${studyID}'`)
    }
    return study
  }

  buildLabel(ls: LossSetLayer): string {
    const curProgram = this.getProgram(ls.meta_data.structureID)
    if (!curProgram) {
      return 'Error building inurance source label'
    }
    const curStudy = this.getStudy(this.getStudyID(curProgram))
    const layerName = ls.meta_data.layerName

    return `${curStudy.name}/${curProgram.label}/${layerName}`
  }

  findHidden(id: string): LayerRef[] {
    let lsl: LayerRef[] = []
    for (const l of this.layers) {
      if (l.layer.layerRefs.includes(id)) {
        if (
          l.layer.meta_data.sage_layer_type === 'cat_td' &&
          l.layer.meta_data.sage_layer_subtype === 'actual' &&
          l.layer.lossSetLayers.length > 0
        ) {
          return l.layer.lossSetLayers
        } else {
          if (l.layer.meta_data.nestedLayersCededPortfolioRecord) {
            const nestedLayersCededPortfolioRecord: Record<string, string[]> =
              JSON.parse(l.layer.meta_data.nestedLayersCededPortfolioRecord)
            const findLayers = Object.values(nestedLayersCededPortfolioRecord)

            findLayers.forEach(f => {
              f.forEach(x => {
                const layer = this.layers.find(l2 => l2.layer.id === x)
                if (layer && layer.layer.meta_data.backAllocatedForID === id) {
                  lsl = layer.layer.lossSetLayers.filter(
                    l2 => l2.meta_data.inuranceSource
                  )
                }
              })
            })
          }
        }
      } else if (
        l.layer.meta_data.riskVisibleLayerID === id &&
        l.layer.meta_data.sage_layer_type === 'noncat_risk'
      ) {
        lsl = l.layer.lossSetLayers.filter(l2 => l2.meta_data.inuranceSource)
      }
    }
    return lsl
  }

  onDeleteOrEditClick(
    sourceId: string,
    relationship: InuranceRelationship,
    type: string,
    targetLayer: Layer,
    targetStructure?: Program,
    targetGroup?: ProgramGroup
  ): void {
    let targetStructureId =
      targetLayer.meta_data.structureID ?? targetStructure?.id ?? ''
    let actualTargetLayer = targetLayer
    const targetLayerSubtype = targetLayer.meta_data.sage_layer_subtype
    let targetView: InuranceView | null = null
    if (targetLayer && relationship.endsWith('Layer')) {
      // Some layers do not have structureID assigned, this is because of a `Save as new structure` prev. bug.
      // For future reference -> layers.util.ts:190, animated-scenarios.service.ts:1734
      if (targetLayerSubtype === 'visible-layer') {
        const mainLayerState = this.layers.find(
          l => l.layer.meta_data.visible_layer_id === targetLayer.id
        )
        if (mainLayerState) {
          actualTargetLayer = mainLayerState.layer
          targetStructureId = mainLayerState.layer.meta_data.structureID ?? ''
        }
      }
      if (targetLayerSubtype === 'section-layer') {
        const mainLayer = this.layers.find(
          l =>
            l.layer.meta_data.sage_layer_subtype === 'main-layer' &&
            l.layer.layerRefs.includes(targetLayer.id)
        )
        targetStructureId = mainLayer?.layer.meta_data.structureID ?? ''
      }

      if (!targetStructureId) {
        throw new Error('Structure Id not found')
      }

      targetView = this.createInuranceViewForLayer(
        actualTargetLayer,
        targetStructureId
      )
    }

    if (targetGroup) {
      targetView = this.createInuranceViewForGroup(targetGroup)
    }

    if (targetStructure) {
      targetView = this.createInuranceViewForStructure(targetStructure)
    }

    const structureOfTarget = this.getProgram(targetStructureId) as Program
    let sourceLayer: LossSetLayer | Layer | undefined
    let sourceStructure: Program | undefined
    let sourceGroup: ProgramGroup | undefined

    if (
      relationship === 'layerToLayer' ||
      relationship === 'layerToStructure' ||
      relationship === 'layerToStructureGroup'
    ) {
      const sectionLossSetLayers =
        targetLayerSubtype === 'section-layer'
          ? actualTargetLayer.lossSetLayers
          : []

      sourceLayer = [
        ...this.lossSetLayers,
        ...this.findHidden(targetLayer.id),
        ...sectionLossSetLayers,
      ].find(lsl => lsl.id === sourceId) as LossSetLayer

      if (sourceLayer) {
        sourceStructure = this.getProgram(sourceLayer.meta_data.structureID)
      }
    } else if (
      relationship === 'structureToLayer' ||
      relationship === 'structureToStructure' ||
      relationship === 'structureToStructureGroup'
    ) {
      sourceStructure = this.getProgram(sourceId)
    } else {
      sourceGroup = this.groups[sourceId]
    }
    if (type === 'delete' && targetView) {
      const toDelete: DeleteInuranceFromDesign = {
        sourceLayer,
        sourceStructure,
        sourceGroup,
        targetLayer: actualTargetLayer ?? targetLayer,
        structureOfTarget,
        targetView,
        relationship,
      }
      this.deleteInuranceClick.emit(toDelete)
    } else {
      this.editInuranceClick.emit({
        layer: sourceLayer,
        structure: sourceStructure,
        group: sourceGroup,
        currentLayer: actualTargetLayer,
        currentProgram: structureOfTarget,
        relationship,
      })
    }
  }

  createInuranceViewForGroup(group: ProgramGroup): InuranceView {
    const type = 'source'
    const fromDesign = true
    const levelFromDesign = 'programGroup'
    // All layers of program group are not loaded. This is done later in `grouper-inurance.effects.ts`
    const referencesFromDesign: InuranceReference[] = []
    return createStructureInuranceView(
      type,
      group,
      undefined,
      fromDesign,
      levelFromDesign,
      referencesFromDesign
    )
  }

  createInuranceViewForStructure(structure: Program): InuranceView {
    const type = 'source'
    const fromDesign = true
    const levelFromDesign = 'program'
    const referencesFromDesign = this.layers.map(l =>
      this.createLayerInuranceReference(l.layer, structure)
    )
    return createStructureInuranceView(
      type,
      structure,
      undefined,
      fromDesign,
      levelFromDesign,
      referencesFromDesign
    )
  }

  createInuranceViewForLayer(
    lsl: LossSetLayer | Layer,
    structureId: string
  ): InuranceView {
    const type = 'source'
    const program = this.getProgram(structureId) as Program
    const layer = this.layers.find(
      state =>
        state.layer.id === lsl.id ||
        state.layer.id === lsl.meta_data.main_layer_id
    )?.layer

    if (!layer) {
      throw new Error('Loss Set Layer not found in layers')
    }

    const fromDesign = true
    const levelFromDesign = 'layer'
    const referencesFromDesign = this.createLayerInuranceReferences(
      layer,
      program
    )

    return createLayerInuranceView(
      type,
      program,
      layer,
      undefined,
      fromDesign,
      levelFromDesign,
      referencesFromDesign
    )
  }

  createLayerInuranceReference(
    layer: Layer,
    structure: Program
  ): InuranceReference {
    return { layerID: layer.id, structureID: structure.id }
  }

  createLayerInuranceReferences(
    layer: Layer,
    structure: Program
  ): InuranceReference[] {
    return [{ layerID: layer.id, structureID: structure.id }]
  }

  parseAndBuildLabels(layer: Layer): InuranceLabel[] {
    const { sage_layer_type, sage_layer_subtype } = layer.meta_data
    const layers = this.layers.map(l => l.layer)
    const refs: InuranceMetadataRef[] = []
    const isComplexVisibleLayer =
      (isMultiSectionLayer(layer) ||
        sage_layer_type === layerIds.noncatIndxl) &&
      sage_layer_subtype === 'visible-layer'

    let inuranceLayer: Layer = layer
    if (isComplexVisibleLayer) {
      // Main layer has the inurance information
      const mainLayer = layers.find(
        ({ meta_data }) =>
          meta_data.sage_layer_subtype === 'main-layer' &&
          meta_data.visible_layer_id === layer.id
      )

      if (mainLayer) {
        inuranceLayer = mainLayer
        if (isMultiSectionLayer(layer)) {
          // Check inurance in child sections.
          mainLayer.layerRefs.forEach(ref => {
            const section = layers.find(l => l.id === ref && isSectionLayer(l))
            const sectionInuranceTargetFor =
              section?.meta_data.inuranceTargetFor
            if (sectionInuranceTargetFor) {
              refs.push(
                ...toInuranceRefs(sectionInuranceTargetFor).map(sectionRef => ({
                  ...sectionRef,
                  sectionLayer: section,
                }))
              )
            }
          })
        }
      }
    }

    this.refs = toInuranceRefs(
      inuranceLayer.meta_data.inuranceTargetFor
    ).concat(refs)

    const sourceLabels: InuranceLabel[] = []

    this.refs.forEach(({ id, type, sectionLayer }) => {
      const targetLayer = sectionLayer ?? this._selectedLayer.layer
      const targetStructure = this.getProgram(targetLayer.meta_data.structureID)
      const targetRef = toInuranceRefs(
        targetLayer.meta_data.inuranceTargetFor
      ).filter(l => l.id === id && l.type === type)
      const targetGroup = this.groups[targetRef[0]?.id] ?? undefined

      if (
        type === 'layerToLayer' ||
        type === 'layerToStructure' ||
        type === 'layerToStructureGroup'
      ) {
        const lossSetLayer = [
          ...this.lossSetLayers,
          ...this.findHidden(layer.id),
          ...(sectionLayer?.lossSetLayers ?? []),
        ].find(l => l.id === id)

        if (lossSetLayer) {
          const label = this.buildLabel(lossSetLayer as LossSetLayer)
          sourceLabels.push({
            relationship: type,
            name: `${label}${sectionLayer ? ' (Section Target)' : ''}`,
            id,
            targetLayer,
            targetGroup,
          })
        }
      }

      if (
        type === 'structureToLayer' ||
        type === 'structureToStructure' ||
        type === 'structureToStructureGroup'
      ) {
        const sourceStructure = this.getProgram(id)
        if (sourceStructure) {
          const sourceStudy = this.getStudy(this.getStudyID(sourceStructure))
          sourceLabels.push({
            relationship: type,
            name: `${sourceStudy.name}/${sourceStructure.label}`,
            id,
            targetLayer,
            targetStructure,
            targetGroup,
          })
        }
      }

      if (
        type === 'structureGroupToLayer' ||
        type === 'structureGroupToStructure' ||
        type === 'structureGroupToStructureGroup'
      ) {
        const sourceGroup = this.groups[id] ?? undefined
        if (sourceGroup) {
          sourceLabels.push({
            relationship: type,
            name: sourceGroup.label,
            id,
            targetLayer,
            targetGroup,
          })
        }
      }
    })

    // Deduplicate because 'section-layer's have same inurance information for structure / groups targets.
    return uniqBy(l => l.name, sourceLabels)
  }
}

type InuranceLabel = {
  relationship: InuranceRelationship
  name: string
  id: string
  targetLayer: Layer
  targetStructure?: Program
  targetGroup?: ProgramGroup
}
