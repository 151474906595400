import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { NavService } from '../nav.service'
import { Store } from '@ngrx/store'
import { AppState } from '../core/store'
import { fetchTracking } from './store/tracking.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tracking-route',
  template: ` <app-tracking-container></app-tracking-container> `,
})
export class TrackingRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
      this.route.paramMap.subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
