<section class="outer-wrapper">
  <div class="close-button">
    <button appButtonIcon big class="close" (click)="destroyDialog()">
      close
    </button>
  </div>
  <div class="wrapper">
    <div class="checkbox-wrapper">
      <mat-checkbox
        class="hide-data-points"
        (change)="updateVisibility('layer', $event)"
        [checked]="layersVisible"
        *ngIf="displayLayerCheckbox"
        >Show Data Points</mat-checkbox
      >
      <mat-checkbox
        class="hide-curve"
        (change)="updateVisibility('line', $event)"
        [checked]="lineVisible"
        *ngIf="displayLineCheckbox"
        >Show Pricing Curve</mat-checkbox
      >
    </div>
    <div class="button-wrapper">
      <div class="modal-action-buttons">
        <button
          appButton
          primary
          accent
          border
          (click)="updateCurveSettings(true)"
        >
          <span>Apply to All</span>
        </button>
        <button
          appButton
          primary
          accent
          border
          (click)="updateCurveSettings(false)"
        >
          <span>Apply</span>
        </button>
        <button appButton primary accent border (click)="destroyDialog()">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</section>
