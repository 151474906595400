import { createAction, props } from '@ngrx/store'
import { Bureaus, ExternalVendorMapping } from 'src/app/api/model/quote.model'
import { Territories } from 'src/app/api/territory/territory.service'
import { ApplicationError } from 'src/app/error/model/error'
import { Layer } from '../../analysis/model/layers.model'
import { PortfolioSetID } from '../../analysis/model/portfolio-set.model'
import {
  Section,
  Risk,
  QuoteExport,
  RenewedFromRiskAndSection,
  RenewedFromOpportunity,
  QuoteReinsurer,
  RenewedFromRiskAndSectionAndAB,
  ProgramRiskCodeMapping,
  QuoteCustomCompareView,
  QuoteCompareViewUpdatePayload,
} from '../models/quote.model'
import { RiskRefWithSections } from 'src/app/api/model/backend.model'
import { Metadata } from 'src/app/api/analyzere/analyzere.model'
import { Program } from 'src/app/core/model/program.model'

export interface QuoteStartProps {
  clientID?: string | null
  studyID?: string | null
  yearID?: string | null
  structureID?: string | null
  cededLayerID?: string | null
  cededLayerName?: string | null
  cededLayerType?: string | null
  structureGroupID?: string | null
  structureGroupName?: string | null
  sharedLimitID?: string | null
  sharedLimitName?: string | null
  isLayerAutoBuild?: boolean
  isLayerChangedInDesign?: boolean
  autoBuildSectionsForSelectedLayer?: RiskRefWithSections
  sectionID?: string
}

export interface QuoteSetAnalysis {
  portfolioSetID: PortfolioSetID | null
  structureID: string | null
}

export interface StructureCountEditEvent {
  structure: Program
  fotCount: number
  quoteCount: number
}

export const fetchOrSaveQuoteData = createAction(
  '[Quote Sage] Fetch Or Save Quote Data',
  props<{ structureID: string }>()
)

export const startQuote = createAction(
  '[Quote Sage] Start',
  props<QuoteStartProps>()
)

export const setAnalysisOrReinitialize = createAction(
  '[Quote Sage] Set Analysis or Reinitialize',
  props<QuoteSetAnalysis>()
)

export const setSectionID = createAction(
  '[Quote Sage] Set SectionID',
  props<{ sectionID: string }>()
)

export const fetchTerritories = createAction(
  '[Quote Section] Fetch territories'
)

export const fetchTerritoriesSuccess = createAction(
  '[Quote Section] Fetch territories success',
  props<{ territories: Territories }>()
)

export const fetchTerritoriesFailure = createAction(
  '[Quote Section] Fetch territories',
  props<{ error: ApplicationError }>()
)

export const fetchQuoteSectionByLayerRef = createAction(
  '[Quote Section] Fetch',
  props<{ layerRef: string }>()
)

export const fetchQuoteSectionByLayerRefSuccess = createAction(
  '[Quote Section] Fetch Success',
  props<{ section: Section }>()
)

export const fetchQuoteSectionByLayerRefFailure = createAction(
  '[Quote Section] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const saveQuoteSectionByLayerRef = createAction(
  '[Quote Section] Save By LayerRef',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefSuccess = createAction(
  '[Quote Section] Save Success By LayerRef',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefFailure = createAction(
  '[Quote Section] Save Failure By LayerRef',
  props<{ error: ApplicationError }>()
)

export const fetchQuoteRiskBystructureID = createAction(
  '[Quote Risk] Fetch',
  props<{ structureID: number }>()
)

export const fetchQuoteRiskBystructureIDSuccess = createAction(
  '[Quote Risk] Fetch Success',
  props<{ risk: Risk }>()
)

export const fetchQuoteRiskBystructureIDFailure = createAction(
  '[Quote Risk] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const saveQuoteRiskBystructureID = createAction(
  '[Quote Risk] Save',
  props<{ risk: Risk }>()
)

export const saveQuoteRiskBystructureIDSuccess = createAction(
  '[Quote Risk] Save Success',
  props<{ risk: Risk }>()
)

export const saveQuoteRiskBystructureIDFailure = createAction(
  '[Quote Risk] Save Failure',
  props<{ error: ApplicationError }>()
)

export const exportAsExcel = createAction(
  '[Quote Sage] Export As Excel',
  props<QuoteExport>()
)

export const exportQuoteSuccess = createAction(
  '[Quote Sage] Export As Excel Success'
)

export const fetchBureaus = createAction('[Quote Sage] Fetch Bureaus')

export const fetchBureausFailure = createAction(
  '[Quote Sage] Fetch Bureaus Failure',
  props<{ error: ApplicationError }>()
)

export const fetchBureausSuccess = createAction(
  '[Quote Sage] Fetch Bureaus Success',
  props<{ bureaus: Bureaus[] }>()
)

export const startGroupQuote = createAction(
  '[Quote Sage] Start Group',
  props<QuoteStartProps>()
)

export const fetchQuoteRiskByprogramID = createAction(
  '[Quote Risk] Fetch for Group / Shared Limit',
  props<{ programID: number }>()
)

export const fetchQuoteRiskByprogramIDSuccess = createAction(
  '[Quote Risk] Fetch Success for Group / Shared Limit',
  props<{ risk: Risk }>()
)

export const fetchQuoteRiskByprogramIDFailure = createAction(
  '[Quote Risk] Fetch Failure for Group / Shared Limit',
  props<{ error: ApplicationError }>()
)

export const saveQuoteRiskByprogramID = createAction(
  '[Quote Risk] Save for Group / Shared Limit',
  props<{ risk: Risk }>()
)

export const saveQuoteRiskByprogramIDSuccess = createAction(
  '[Quote Risk] Save Success for Group / Shared Limit',
  props<{ risk: Risk }>()
)

export const saveQuoteRiskByprogramIDFailure = createAction(
  '[Quote Risk] Save Failure for Group / Shared Limit',
  props<{ error: ApplicationError }>()
)

export const fetchQuoteSectionByLayerRefAndProgramGroupID = createAction(
  '[Quote Section] Fetch for Group',
  props<{ layerRef: string; programGroupID: string }>()
)

export const fetchQuoteSectionByLayerRefAndProgramGroupIDSuccess = createAction(
  '[Quote Section] Fetch Success for Group',
  props<{ section: Section }>()
)

export const fetchQuoteSectionByLayerRefAndProgramGroupIDFailure = createAction(
  '[Quote Section] Fetch Failure for Group',
  props<{ error: ApplicationError }>()
)

export const saveQuoteSectionByLayerRefAndProgramGroupID = createAction(
  '[Quote Section] Save By LayerRef for Group',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefAndProgramGroupIDSuccess = createAction(
  '[Quote Section] Save Success By LayerRef for Group',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefAndProgramGroupIDFailure = createAction(
  '[Quote Section] Save Failure By LayerRef for Group',
  props<{ error: ApplicationError }>()
)

export const loadingSuccess = createAction('[Quote Sage] Loading Success')

export const startSLQuote = createAction(
  '[Quote Sage] Start Shared Limit',
  props<QuoteStartProps>()
)

export const fetchLayersForSharedLimit = createAction(
  '[Quote Sage] Fetch Layers for Shared Limit',
  props<{ slID: string }>()
)

export const fetchLayersForSharedLimitSuccess = createAction(
  '[Quote Sage] Fetch Layers for Shared Limit Success',
  props<{
    sharedLimitLayers: Layer[]
    sharedLimitPortfolioID: PortfolioSetID | null
  }>()
)

export const fetchLayersForSharedLimitFailure = createAction(
  '[Quote Sage] Fetch Layers for Shared Limit Failure',
  props<{ error: ApplicationError }>()
)

export const fetchQuoteSectionByLayerRefAndSharedLimitID = createAction(
  '[Quote Section] Fetch Section for SharedLimit',
  props<{ layerRef: string; sharedLimitID: string }>()
)

export const fetchQuoteSectionByLayerRefAndSharedLimitIDSuccess = createAction(
  '[Quote Section] Fetch Section Success for SharedLimit',
  props<{ section: Section }>()
)

export const fetchQuoteSectionByLayerRefAndSharedLimitIDFailure = createAction(
  '[Quote Section] Fetch Section Failure for SharedLimit',
  props<{ error: ApplicationError }>()
)

export const saveQuoteSectionByLayerRefAndSharedLimitID = createAction(
  '[Quote Section] Save Section By LayerRef for SharedLimit',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefAndSharedLimitIDSuccess = createAction(
  '[Quote Section] Save Section Success By LayerRef for SharedLimit',
  props<{ section: Section }>()
)

export const saveQuoteSectionByLayerRefAndSharedLimitIDFailure = createAction(
  '[Quote Section] Save Section Failure By LayerRef for SharedLimit',
  props<{ error: ApplicationError }>()
)

export const checkIfProgramHasRenewedFrom = createAction(
  '[Quote Sage] Check If Program Has Renewed From',
  props<{ programId: string }>()
)
export const fetchDetailsForRenewedOpportunity = createAction(
  '[Quote Sage] Fetch Details for Renewed Opportunity',
  props<{ opportunityRenewedFrom: RenewedFromOpportunity }>()
)
export const fetchDetailsForRenewedOpportunitySuccess = createAction(
  '[Quote Sage] Fetch Expiring Details for Renewed Opportunity Success',
  props<{
    renewedFromRisks: RenewedFromRiskAndSection[]
  }>()
)
export const fetchDetailsForRenewedOpportunityFailure = createAction(
  '[Quote Sage] Fetch Expiring Details for Renewed Opportunity Failure',
  props<{
    error: ApplicationError
  }>()
)

export const fetchQuoteFieldsForSelectedRiskAndSection = createAction(
  '[Quote Sage] Fetch Quote Fields for Selected Risk & Section',
  props<{ selectedRiskAndSectionAndAB: RenewedFromRiskAndSectionAndAB }>()
)

export const fetchQuoteFieldsForSelectedRiskAndSectionSuccess = createAction(
  '[Quote Sage] Fetch Quote Fields for Selected Risk & Section Success',
  props<{ quoteReinsurer: QuoteReinsurer }>()
)

export const fetchQuoteFieldsForSelectedRiskAndSectionFailure = createAction(
  '[Quote Sage] Fetch Quote Fields for Selected Risk & Section Failure',
  props<{ error: ApplicationError }>()
)

export const fetchSignedLinesFromWS = createAction(
  '[Quote Sage] Fetch Signed Lines From Whitespace',
  props<{ reinsurerId: string }>()
)

export const fetchSignedLinesFromWSSuccess = createAction(
  '[Quote Sage] Fetch Signed Lines From Whitespace Success'
)

export const fetchSignedLinesFromWSFailure = createAction(
  '[Quote Sage] Fetch Signed Lines From Whitespace Failure',
  props<{ error: ApplicationError }>()
)

export const checkIfShouldFetchAutoBuildExpiring = createAction(
  '[Quote Sage] Check If Auto Build Expiring Details Should Be Fetched',
  props<{
    layerName: string | null | undefined
  }>()
)

export const fetchAutoBuildExpiringDetails = createAction(
  '[Quote Sage] Fetch Auto Build Expiring Details',
  props<{ layerName: string | null | undefined }>()
)

export const noMoreAutoBuiltSections = createAction(
  '[Quote Sage] Auto Built Sections isLoading False'
)

export const moreAutoBuiltSections = createAction(
  '[Quote Sage] More Auto Built Sections'
)

export const fetchExternalVendorByStructureID = createAction(
  '[Quote Sage] Fetch External Vendor',
  props<{ structureID: string }>()
)

export const fetchExternalVendorFailure = createAction(
  '[Quote Sage] Fetch External Vendor Failure',
  props<{ error: ApplicationError }>()
)

export const fetchExternalVendorSuccess = createAction(
  '[Quote Sage] Fetch External Vendor Success',
  props<{ vendor: ExternalVendorMapping }>()
)

export const openRiskCodeDialog = createAction(
  '[Quote Sage] Open Risk Code Dialog',
  props<{ programId: string }>()
)

export const openRiskCodeDialogSuccess = createAction(
  '[Quote Sage] Open Risk Code Dialog Success'
)

export const fetchRiskCodes = createAction('[Quote Sage] Fetch Risk Codes')

export const fetchRiskCodesFailure = createAction(
  '[Quote Sage] Fetch Risk Codes Failure',
  props<{ error: ApplicationError }>()
)

export const fetchSavedRiskCodesByProgram = createAction(
  '[Quote Sage] Fetch Saved Risk Codes By Program'
)
export const fetchSavedRiskCodesByProgramFailure = createAction(
  '[Quote Sage] Fetch Saved Risk Codes By Program Failure',
  props<{ error: ApplicationError }>()
)

export const saveSelectedRiskCodesByProgram = createAction(
  '[Quote Sage] Save Selected Risk Codes By Program',
  props<{ mappings: ProgramRiskCodeMapping[] }>()
)

export const saveSelectedRiskCodesByProgramSuccess = createAction(
  '[Quote Sage] Save Selected Risk Codes By Program Success'
)

export const saveSelectedRiskCodesByProgramFailure = createAction(
  '[Quote Sage] Save Selected Risk Codes By Program Failure',
  props<{ error: ApplicationError }>()
)

export const openLayerDataChangedDialog = createAction(
  '[Quote Sage] Open Layer Data Changed Dialog'
)
export const patchLayerMetadataFromQuote = createAction(
  '[Quote Sage] Patch Layer Metadata From Quote',
  props<{ layerId: string; change: Partial<Metadata> }>()
)
export const patchLayerDataFromQuoteSuccess = createAction(
  '[Quote Sage] Patch Layer Data From Quote Success'
)

export const fetchAutoBuildExpiring = createAction(
  '[Quote Sage] Fetch AutoBuild Expiring',
  props<{ layerRef?: string | undefined }>()
)

export const setFotAndQuoteCount = createAction(
  '[Quote Sage] Set FOT and Quote Count',
  props<StructureCountEditEvent>()
)

export const setFotAndQuoteCountSuccess = createAction(
  '[Quote Sage] Set FOT and Quote Count Success',
  props<{ id: string; fotCount: number; quoteCount: number }>()
)

export const setFotAndQuoteCountFailure = createAction(
  '[Quote Sage] Set FOT and Quote Count Failure',
  props<{ error: ApplicationError }>()
)

export const fetchQuoteComparisonsBySectionIdSuccess = createAction(
  '[Quote Sage] Fetch Quote Comparisons by Section Id Success',
  props<{ quoteComparisons: QuoteCustomCompareView[] }>()
)

export const fetchQuoteComparisonsBySectionIdFailure = createAction(
  '[Quote Sage] Fetch Quote Comparisons by Section Id Failure',
  props<{ error: ApplicationError }>()
)

export const saveQuoteComparison = createAction(
  '[Quote Sage] Save Quote Comparison'
)

export const updateQuoteComparisonsFromRemoveDialog = createAction(
  '[Quote Sage] Update Quote Comparison From Remove Dialog',
  props<{ updates: QuoteCompareViewUpdatePayload }>()
)

export const updateQuoteComparisonsFromRemoveDialogSuccess = createAction(
  '[Quote Sage] Update Quote Comparison From Remove Dialog Success'
)

export const updateQuoteComparisonsFromRemoveDialogFailure = createAction(
  '[Quote Sage] Update Quote Comparison From Remove Dialog Failure',
  props<{ error: ApplicationError }>()
)

export const saveQuoteComparisonSuccess = createAction(
  '[Quote Sage] Save Quote Comparison Success'
)

export const saveQuoteComparisonFailure = createAction(
  '[Quote Sage] Save Quote Comparison Failure',
  props<{ error: ApplicationError }>()
)

export const deleteQuoteComparison = createAction(
  '[Quote Sage] Delete Quote Comparison',
  props<{ id: number }>()
)

export const deleteQuoteComparisonSuccess = createAction(
  '[Quote Sage] Delete Quote Comparison Success'
)

export const deleteQuoteComparisonFailure = createAction(
  '[Quote Sage] Delete Quote Comparison Failure',
  props<{ error: ApplicationError }>()
)

export const updateSelectedCompareView = createAction(
  '[Quote Sage] Update Selected Quote Compare View',
  props<{ view: QuoteCustomCompareView | null }>()
)
