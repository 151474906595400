<div class="add">
  <button appButton big border [matMenuTriggerFor]="addMenu">
    Add New Row
  </button>
  <mat-menu #addMenu="matMenu" class="add-menu">
    <button
      mat-menu-item
      *ngFor="let s of subjectivityOptions"
      [matTooltip]="s"
      (click)="
            onAddClick(
              $event.target.innerText,
              selectedQuoteReinsurer?.quoteReinsurerName
            )
          "
    >
      {{ s }}
    </button>
  </mat-menu>

  <div class="right-button">
    <button
      [disabled]="
            this.isDisabled ||
            this.populateFromLayersList.length === 0 ||
            this.showAssignedLines
          "
      appButton
      big
      border
      tabindex="-1"
      class="populate"
      matTooltip="Populate Subjectivities from: "
      matTooltipPosition="above"
      [matMenuTriggerFor]="popMenu"
    >
      Populate From
    </button>
    <mat-menu #popMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let s of this.populateFromLayersList"
        (click)="onPopulateFrom(s)"
      >
        {{ s.id }} {{ s.reinsurerName }}
      </button>
    </mat-menu>
  </div>
</div>
<app-sort-table
  *ngIf="rows"
  [excelEnabled]="false"
  [columnDefs]="subjectivityColumnDef"
  [rows]="updatedRows"
  [isSubjectivity]="true"
  [readOnlyRows]="readOnlyRows"
  (valueChange)="onValueChange($event)"
  (deleteSubjectivity)="onDeleteSubjectivity($event)"
>
</app-sort-table>
