<table
  class="app-table app-table-small mat-table"
  mat-table
  [dataSource]="dataSource"
  [trackBy]="trackByEvents"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Event</th>
    <td class="event-id" mat-cell *matCellDef="let i = index">
      {{ i + 1 }}
    </td>
  </ng-container>
  <ng-container
    *ngFor="let lossSetName of lossSetNames"
    [matColumnDef]="lossSetName"
  >
    <th mat-header-cell class="edit" *matHeaderCellDef>
      {{ lossSetName }}
    </th>
    <td mat-cell *matCellDef="let event" class="edit">
      <app-layer-property
        cell
        [name]="lossSetName"
        [alignRight]="true"
        [type]="'currency'"
        [readonly]="disableActions"
        [value]="getValue(lossSetName, event)"
        [numberTransform]="toMillions"
        [numberReverseTransform]="fromMillions"
        [currentCurrency]="currentCurrency"
        (inputChange)="inputChange(event.id, lossSetName, $event)"
      ></app-layer-property>
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let event; let i = index" class="action">
      <mat-icon
        matTooltip="Delete"
        class="delete"
        [ngClass]="{ disabled: disableActions }"
        (click)="onDelete(event.id)"
      >delete</mat-icon
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
</table>
