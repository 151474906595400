import { Component, Inject, OnInit } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { CarrierData } from '../../../api/model/backend.model'
import { ProgramInitiationMappingActionDialogComponent } from '../../components/program-initiation-mapping-action-dialog/program-initiation-mapping-action-dialog.component'
import { AccountMappingData } from '../../model/program-initiation.model'

@Component({
  selector: 'app-program-initiation-mapping-dialog',
  templateUrl: './program-initiation-mapping-dialog.component.html',
  styleUrls: ['./program-initiation-mapping-dialog.component.scss'],
})
export class ProgramInitiationMappingDialogComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProgramInitiationMappingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      carriers: CarrierData[]
      accountMappingData: AccountMappingData[]
    }
  ) {}

  dataSource: MatTableDataSource<AccountMappingData>
  displayedColumns: string[] = ['Account Name', 'Carrier Name', 'deleteAction']

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.accountMappingData)
  }

  onMappingActionClick(accountMappingData: AccountMappingData): void {
    const dialogRef = this.dialog.open(
      ProgramInitiationMappingActionDialogComponent,
      {
        data: {
          carriers: this.data.carriers,
          accountMappingData,
        },
      }
    )
    dialogRef.afterClosed().subscribe((updatedMapping: AccountMappingData) => {
      if (!updatedMapping) {
        return
      }
      this.dialogRef.close(updatedMapping)
    })
  }
}
