import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'
import { CanSize, CanSizeCtor, mixinSize, Size } from '../size.mixin'

class ButtonBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof ButtonBase = mixinSize(ButtonBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'button[appButtonIcon], a[appButtonIcon]',
  styleUrls: ['./button-icon.component.scss'],
  templateUrl: './button-icon.component.html',
})
export class ButtonIconComponent extends _SizeMixinBase implements CanSize {
  @Input() tooltip: string
  @Input() tooltipPosition: TooltipPosition = 'below'

  @Input() set tooltipShowDelay(value: number) {
    this._tooltipShowDelay = coerceNumberProperty(value)
  }
  get tooltipShowDelay(): number {
    return this._tooltipShowDelay
  }
  private _tooltipShowDelay = 200

  @Input() set opaque(value: any) {
    this._opaque = coerceBooleanProperty(value)
  }
  get opaque() {
    return this._opaque
  }
  @HostBinding('class.opaque') _opaque = false

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @Input() huge: boolean
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @Input() gigantic: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }

  // Style mode: Accent
  @Input() set accent(value: any) {
    this._accent = coerceBooleanProperty(value)
  }
  get accent() {
    return this._accent
  }
  @HostBinding('class.accent') _accent = false
}
