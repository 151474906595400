import { inject, Injectable } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { AppState } from '../../../core/store'
import { Action, select, Store } from '@ngrx/store'
import {
  fetchLossSetLayers,
  fetchLossSetLayersFailure,
  fetchLossSetLayersSuccess,
  fetchLossSetLayersFromGrossPortfolio,
  // updateLossSetAAL,
  setLossSetAAL,
  setLossSetAALSuccess,
  fetchLossSetLayersFromParentGrossPortfolio,
  fetchParentLossSetLayersFailure,
  fetchParentLossSetLayersSuccess,
  setDirty,
  newScaledLossSetLayers,
  newScaledLossSetLayersFailure,
  newScaledLossSetLayersSuccess,
  modifyScaleLossSetLayers,
  modifyScaleLossSetLayersFailure,
  modifyScaleLossSetLayersSuccess,
  getLossSetGroupMetrics,
  getLossSetGroupMetricsSuccess,
  getLossSetGroupMetricsFailure,
  getAllLossSetGroupsMetrics,
  fetchLossSetLayerViews,
  fetchLossSetLayerViewsFailure,
  fetchLossSetLayerViewsSuccess,
} from './loss-set-layers.actions'
import {
  switchMap,
  map,
  withLatestFrom,
  mergeMap,
  filter,
  concatMap,
  take,
} from 'rxjs/operators'
import {
  DEFAULT_PREMIUM,
  LoadedLossSet,
  LogicalPortfolioLayer,
  LossSet,
  LossSetLayer,
} from '../../../api/analyzere/analyzere.model'
import {
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from '../../../api/util'
import { forkJoin, Observable, of, OperatorFunction } from 'rxjs'
import * as fromLossSetLayerModel from '../../model/loss-set-layers.model'
import {
  selectCededLayers,
  selectCurrentAnalysisProfileID,
  selectCurrentCededLayer,
  selectCurrentCurrency,
  selectCurrentParentGrossPortfolioID,
  selectEditorPortfolioSetID,
  selectLossSetGroups,
  selectLossSetLayerViewIDs,
  selectParentGrossLossSetLayers,
  selectPortfolioViewMetrics,
} from '../analysis.selectors'
import { ApiResponse, MaybeError } from 'src/app/api/model/api.model'
import {
  createProportionalExpense,
  isLoadedLossSet,
} from '../../model/layers.util'
import {
  PortfolioMetrics,
  PortfolioViewMetricsPayload,
} from '../../model/portfolio-metrics.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import { updateGroupSuccess } from './loss-set-group/loss-set-group.actions'
import { keys } from 'ramda'
import {
  fetchLayersSuccess,
  updateLayer,
  setDirty as setDirtyLayer,
} from '../ceded-layers/layers.actions'
import { LayerState } from '../ceded-layers/layers.reducer'
import { selectRouterState } from 'src/app/core/store/router.selectors'
import { isSwingLayer } from '../../layers/swing-layer'

// tslint:disable: no-non-null-assertion
@Injectable()
export class LossSetLayersEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: AnalyzreService) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLossSetLayers),
      switchMap(({ ids }) => {
        return this.service.fetchLayers<LossSetLayer>(ids)
      }),
      rejectError(error =>
        this.store.dispatch(fetchLossSetLayersFailure({ error }))
      ),
      mapToLossSetLayers(),
      map(layers => {
        const lossSetLayers = layers.map(l => {
          let premium = l.premium
          if (!premium) {
            premium = DEFAULT_PREMIUM
          }
          return {
            ...l,
            premium
          }
        })
        return fetchLossSetLayersSuccess({ lossSetLayers })
      })
    )
  })

  fetchByGrossPortfolio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLossSetLayersFromGrossPortfolio),
      switchMap(({ id }) => {
        return this.service.fetchPortfolio(id)
      }),
      rejectError(error =>
        this.store.dispatch(fetchLossSetLayersFailure({ error }))
      ),
      map(portfolioResponse => {
        const lossSetFilteredLayers: LossSetLayer[] = (
          portfolioResponse.layers as LossSetLayer[]
        ).filter((l: LossSetLayer) => l.loss_sets)
        return lossSetFilteredLayers
      }),
      mapToLossSetLayers(),
      map(layers => {
        this.store.dispatch(setDirty({ dirty: false }))
        const lossSetLayers = layers.map(l => {
          let premium = l.premium
          if (!premium) {
            premium = DEFAULT_PREMIUM
          }
          return {
            ...l,
            premium
          }
        })
        return fetchLossSetLayersSuccess({ lossSetLayers })
      })
    )
  })

  fetchByParentGrossPortfolio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLossSetLayersFromParentGrossPortfolio),
      switchMap(({ id }) => {
        return this.service.fetchPortfolio(id)
      }),
      rejectError(error =>
        this.store.dispatch(fetchParentLossSetLayersFailure({ error }))
      ),
      map(portfolioResponse => {
        const lossSetParentFilteredLayers: LossSetLayer[] = (
          portfolioResponse.layers as LossSetLayer[]
        ).filter((l: LossSetLayer) => l.loss_sets)
        return lossSetParentFilteredLayers
      }),
      mapToLossSetLayers(),
      mergeMap(layers => {
        const actions: Action[] = []
        actions.push(
          fetchParentLossSetLayersSuccess({ parentLossSetLayers: layers })
        )
        actions.push(
          fetchLossSetLayerViews({ layerIDs: layers.map(l => l.id) })
        )
        return actions
      })
    )
  })

  fetchLossSetLayerViews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLossSetLayerViews),
      withLatestFrom(
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(select(selectCurrentCurrency))
      ),
      mergeMap(([{ layerIDs }, analysisProfileID, currentCurrency]) => {
        return this.service.postLayersViews(
          layerIDs,
          analysisProfileID as string,
          currentCurrency ? [currentCurrency] : undefined
        )
      }),
      rejectError(error =>
        this.store.dispatch(fetchLossSetLayerViewsFailure({ error }))
      ),
      map(res => {
        const record = res.reduce(
          (acc, next) => {
            acc[next.layerID] = next.id
            return acc
          },
          {} as Record<string, string>
        )
        return fetchLossSetLayerViewsSuccess({ layerViewIDs: record })
      })
    )
  })

  fetchCededLayersSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLossSetLayerViewsSuccess, fetchLayersSuccess),
      withLatestFrom(
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(select(selectCurrentCurrency)),
        this.store.pipe(select(selectLossSetLayerViewIDs)),
        this.store.pipe(select(selectRouterState))
      ),
      map(([_, analysisID, currency, lossSetViews, routerState]) => {
        if (
          analysisID &&
          lossSetViews &&
          !routerState.state.url.includes('quote')
        ) {
          return setLossSetAAL({
            lossSetViews,
            analysisID,
            currency,
          })
        }
        return setLossSetAALSuccess({
          success: true,
          data: null,
        })
      })
    )
  })

  setLossSetsAAL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setLossSetAAL),
      map(action => action),
      switchMap(res => {
        const layerViewIDs: string[] = []
        const lossSetLayerIDs: string[] = []

        keys(res.lossSetViews).forEach(lossSetLayerID => {
          lossSetLayerIDs.push(lossSetLayerID)
          layerViewIDs.push(res.lossSetViews[lossSetLayerID])
        })
        return this.service
          .getMultiPortfolioViewMetricsAAL(layerViewIDs, lossSetLayerIDs)
          .pipe(
            map(newRes => ({
              ...newRes,
            }))
          )
      }),
      switchMap(res => {
        const actions = []
        if (res.data) {
          actions.push(
            setLossSetAALSuccess({
              success: true,
              data: res.data,
            })
          )
        }
        return actions
      })
    )
  })

  newScaledLossSets$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(newScaledLossSetLayers),
        withLatestFrom(this.store.pipe(select(selectParentGrossLossSetLayers))),
        switchMapWithInput(([{ scaledProps }, lossSetLayers]) => {
          return this.createLoadedLossSets(scaledProps, lossSetLayers)
        }),
        rejectErrorWithInput(error =>
          this.store.dispatch(newScaledLossSetLayersFailure({ error }))
        ),
        switchMap(([loadedLossSets, [{ scaledProps }, lossSetLayers]]) => {
          const quotaShareResponses: ApiResponse<LossSetLayer>[] = []

          // Loop through scaledProps and POST new QS Layer wrapper for each
          scaledProps.forEach(sp => {
            const qsProps = this.getQuotaShareWrapperProps(
              sp,
              lossSetLayers,
              loadedLossSets
            )
            quotaShareResponses.push(
              this.service.postLossSetLayer({
                ...qsProps.lossSetLayer,
                id: undefined,
                _type: 'QuotaShare',
                loss_sets: [
                  {
                    ref_id: qsProps.lossSetID,
                  },
                ],
                description: `${qsProps.lossSetLayer.description} - ${sp.description}`,
                premium: {
                  ...qsProps.lossSetLayer.premium,
                  value: sp.newPremium,
                },
                fees: [
                  {
                    ...qsProps.fee,
                    rate: sp.newExpenseRatio,
                  },
                ],
                meta_data: {
                  ...qsProps.lossSetLayer.meta_data,
                  ls_dim2: `${qsProps.lossSetLayer.meta_data.ls_dim2} - ${sp.description}`,
                  // Keep original premium if it has one
                  // Otherwise, set original premium to current premium
                  originalPremium: qsProps.lossSetLayer.meta_data
                    .originalPremium
                    ? qsProps.lossSetLayer.meta_data.originalPremium
                    : qsProps.lossSetLayer.premium.value,
                  scaledIdentifier: sp.description,
                  map1: sp.map1,
                  map2: sp.map2,
                  map3: sp.map3,
                  map4: sp.map4,
                  map5: sp.map5,
                },
              })
            )
          })

          return forkJoin(quotaShareResponses).pipe(
            map(responses => {
              const data: LossSetLayer[] = []
              for (const response of responses) {
                if (response.error) {
                  return { error: response.error }
                } else {
                  data.push(response.data!)
                }
              }
              return { data }
            })
          )
        }),
        rejectError(error =>
          this.store.dispatch(newScaledLossSetLayersFailure({ error }))
        ),
        withLatestFrom(
          this.store.pipe(select(selectCurrentParentGrossPortfolioID)),
          this.store.pipe(select(selectParentGrossLossSetLayers))
        ),
        switchMap(
          ([newLossSetLayers, parentGrossPortfolioID, parentGrossLayers]) => {
            // Get existing Parent Gross layers
            let updatedParentGrossLayers = parentGrossLayers.map(l => {
              return {
                ref_id: l.id,
              }
            })
            // Add new layers
            const newLayers = newLossSetLayers.map(l => {
              return {
                ref_id: l.id,
              }
            })
            updatedParentGrossLayers =
              updatedParentGrossLayers.concat(newLayers)
            // PATCH Parent Gross Portfolio
            return this.service.patchPortfolio({
              id: parentGrossPortfolioID!,
              change: {
                layers: updatedParentGrossLayers,
              },
            })
          }
        )
      )
      .pipe(
        rejectError(error =>
          this.store.dispatch(newScaledLossSetLayersFailure({ error }))
        ),
        map(portfolioResponse => {
          return portfolioResponse.layers as LossSetLayer[]
        }),
        mapToLossSetLayers(),
        map(layers => {
          this.store.dispatch(
            fetchParentLossSetLayersSuccess({ parentLossSetLayers: layers })
          )
          return newScaledLossSetLayersSuccess()
        })
      )
  })

  modifyScaledLossSets$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(modifyScaleLossSetLayers),
        withLatestFrom(this.store.pipe(select(selectParentGrossLossSetLayers))),
        switchMapWithInput(([{ scaledProps }, lossSetLayers]) => {
          return this.createLoadedLossSets(scaledProps, lossSetLayers)
        }),
        rejectErrorWithInput(error =>
          this.store.dispatch(modifyScaleLossSetLayersFailure({ error }))
        ),
        switchMap(([loadedLossSets, [{ scaledProps }, lossSetLayers]]) => {
          const quotaShareResponses: ApiResponse<LossSetLayer>[] = []

          // Loop through scaledProps and PATCH QS Layer wrapper for each
          scaledProps.forEach(sp => {
            const qsProps = this.getQuotaShareWrapperProps(
              sp,
              lossSetLayers,
              loadedLossSets
            )
            const existingScaledIdentifier =
              ' ' + qsProps.lossSetLayer.meta_data.scaledIdentifier + ' '
            const {
              ls_dim1,
              ls_dim2,
              description,
              newPremium,
              newExpenseRatio,
              map1,
              map2,
              map3,
              map4,
              map5,
            } = sp

            // PATCH QuotaShare to wrap LoadedLossSet using props from original
            // LossSetLayer (QS) and user entered values (sp)
            const update = {
              id: qsProps.lossSetLayer.id,
              change: {
                loss_sets: [
                  {
                    ref_id: qsProps.lossSetID,
                  },
                ],
                description,
                premium: {
                  ...qsProps.lossSetLayer.premium,
                  value: newPremium,
                },
                fees: [
                  {
                    ...qsProps.fee,
                    rate: newExpenseRatio,
                  },
                ],
                meta_data: {
                  ...qsProps.lossSetLayer.meta_data,
                  ls_dim1,
                  ls_dim2,
                  // Keep original premium if it has one
                  // Otherwise, set original premium to current premium
                  originalPremium: qsProps.lossSetLayer.meta_data
                    .originalPremium
                    ? qsProps.lossSetLayer.meta_data.originalPremium
                    : qsProps.lossSetLayer.premium.value,
                  scaledIdentifier: existingScaledIdentifier
                    ? existingScaledIdentifier
                    : description,
                  map1,
                  map2,
                  map3,
                  map4,
                  map5,
                },
              },
            }
            quotaShareResponses.push(this.service.patchLossSetLayer(update))
          })
          return forkJoin(quotaShareResponses).pipe(
            map(responses => {
              const data: LossSetLayer[] = []
              for (const response of responses) {
                if (response.error) {
                  return { error: response.error }
                } else {
                  data.push(response.data!)
                }
              }
              return { data }
            })
          )
        })
      )
      .pipe(
        rejectError(error =>
          this.store.dispatch(modifyScaleLossSetLayersFailure({ error }))
        ),
        // Update back allocated layers `source_id`
        withLatestFrom(this.store.pipe(select(selectCededLayers))),
        map(([_, cededLayers]) => {
          // Find shared limit layer to get its layerRefs
          const sharedLimitLayerState: LayerState | undefined =
            cededLayers.find(layer => {
              const { sage_layer_type, sage_layer_subtype } =
                layer.layer.meta_data

              return (
                sage_layer_type === 'shared_limits' &&
                sage_layer_subtype === 'virtual'
              )
            })

          return {
            sharedLimitLayerState,
          }
        }),
        filter(x => x.sharedLimitLayerState !== undefined),
        switchMap(({ sharedLimitLayerState }) => {
          const sharedLayer = (sharedLimitLayerState as LayerState).layer
          const sharedCurrency = sharedLayer.physicalLayer.franchise.currency
          const analysisProfileId = sharedLayer.meta_data.analysisProfileID!

          const nestedLayerCededRecord: Record<string, string[]> = JSON.parse(
            sharedLayer.meta_data.nestedLayersCededPortfolioRecord!
          )

          const updates: ApiResponse<LogicalPortfolioLayer[]>[] = []

          Object.keys(nestedLayerCededRecord).forEach(cededPortfolioId => {
            const updateObservable = this.service
              .fetchPortfolio(cededPortfolioId)
              .pipe(
                concatMap(res => {
                  if (res.error) {
                    return of({ error: res.error }) as ApiResponse<
                      LogicalPortfolioLayer[]
                    >
                  }

                  const portfolio = res.data!
                  const nestedLayerIds =
                    nestedLayerCededRecord[cededPortfolioId]

                  const portfolioLayers =
                    portfolio.layers as LogicalPortfolioLayer[]

                  const nestedLayers = portfolioLayers.filter(l =>
                    nestedLayerIds.includes(l.id)
                  )

                  const observables: ApiResponse<LogicalPortfolioLayer>[] = []

                  nestedLayers.forEach(nestedLayer => {
                    const postLayerViewObservable = this.service
                      .postLayerView(
                        nestedLayer.meta_data.backAllocatedForID!,
                        analysisProfileId,
                        sharedCurrency
                      )
                      .pipe(
                        switchMap(({ error, data }) => {
                          if (error) {
                            return of({
                              error,
                            }) as ApiResponse<LogicalPortfolioLayer>
                          }

                          const layerViewId = data!.id

                          const sources =
                            nestedLayer.sources as LogicalPortfolioLayer[]

                          const backAllocatedLayer = sources.find(
                            s => s._type === 'BackAllocatedLayer'
                          )!

                          return this.service.patchLogicalPortfolioLayer({
                            id: backAllocatedLayer.id,
                            change: {
                              source_id: layerViewId,
                            },
                          })
                        })
                      )

                    observables.push(postLayerViewObservable)
                  })

                  if (observables.length === 0) {
                    return of({ data: [] }) as ApiResponse<
                      LogicalPortfolioLayer[]
                    >
                  } else {
                    return forkJoin(observables).pipe(
                      map(responses => {
                        for (const r of responses) {
                          if (r.error) {
                            return { error: r.error }
                          }
                        }

                        return { data: responses.map(r => r.data!) }
                      })
                    ) as ApiResponse<LogicalPortfolioLayer[]>
                  }
                })
              )

            updates.push(updateObservable)
          })

          return forkJoin(updates).pipe(
            map(res => {
              for (const r of res) {
                if (r.error) {
                  return of({ error: r.error }) as MaybeError
                }
              }

              return {
                data: res.map(r => r.data!),
              }
            })
          )
        })
      )
      .pipe(
        rejectError(error =>
          this.store.dispatch(modifyScaleLossSetLayersFailure({ error }))
        ),
        withLatestFrom(
          this.store.pipe(select(selectCurrentParentGrossPortfolioID))
        ),
        switchMap(([_, parentGrossPortfolioID]) => {
          // GET Parent Gross Portfolio
          return this.service.fetchPortfolio(parentGrossPortfolioID!)
        })
      )
      .pipe(
        rejectError(error =>
          this.store.dispatch(modifyScaleLossSetLayersFailure({ error }))
        ),
        map(portfolioResponse => {
          return portfolioResponse.layers as LossSetLayer[]
        }),
        mapToLossSetLayers(),
        map(layers => {
          this.store.dispatch(
            fetchParentLossSetLayersSuccess({ parentLossSetLayers: layers })
          )
          return modifyScaleLossSetLayersSuccess()
        })
      )
  })

  losssetGroupMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLossSetGroupMetrics),
      withLatestFrom(
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(select(selectLossSetLayerViewIDs))
      ),
      mergeMap(
        ([
          {
            lossSetGroupId,
            layerIds,
            perspective,
            returnPeriod1,
            returnPeriod2,
            returnPeriod3,
            aggregationMethod,
            lossFilter,
          },
          analysisProfileID,
          lossSetLayerViewIDs,
        ]) => {
          const layerViewIDs = layerIds.map(l => lossSetLayerViewIDs[l])
          return this.service
            .getLossSetGroupMetrics(
              lossSetGroupId,
              layerViewIDs,
              analysisProfileID || '',
              aggregationMethod,
              perspective,
              lossFilter,
              returnPeriod1,
              returnPeriod2,
              returnPeriod3
            )
            .pipe(map(r => ({ ...r })))
        }
      ),
      map(response => {
        if (response.error) {
          return getLossSetGroupMetricsFailure({
            id: '',
            error: response.error,
          })
        } else {
          return getLossSetGroupMetricsSuccess({
            data: response.data as Record<string, PortfolioMetrics>,
          })
        }
      })
    )
  })

  allLossSetGroupsMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAllLossSetGroupsMetrics, updateGroupSuccess),
      map(
        ({ type, ...props }) =>
          props as { change: Partial<PortfolioViewMetricsPayload> }
      ),
      withLatestFrom(this.store.pipe(select(selectEditorPortfolioSetID))),
      mergeMap(([props, portfolioSetID]) => {
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectLossSetGroups)),
            this.store.pipe(
              select(selectPortfolioViewMetrics, { portfolioSetID })
            )
          )
        )
      }),
      switchMap(([props, groups, metrics]) => {
        const actions: any[] = []

        const perspective =
          props?.change?.perspective ||
          metrics?.perspective ||
          ('Loss' as Perspective)
        const returnPeriod1 =
          props?.change?.returnPeriod1 || metrics?.returnPeriod1 || 10
        const returnPeriod2 =
          props?.change?.returnPeriod2 || metrics?.returnPeriod2 || 25
        const returnPeriod3 =
          props?.change?.returnPeriod3 || metrics?.returnPeriod3 || 100
        const aggregationMethod =
          props?.change?.aggregationMethod ||
          metrics?.aggregationMethod ||
          ('AEP' as AggregationMethodType)
        const lossFilter =
          props?.change?.lossFilter || metrics?.lossFilter || 'all'

        groups.forEach(g => {
          const ids = g.lossSetLayers.map(l => l.id)

          actions.push(
            getLossSetGroupMetrics({
              lossSetGroupId: g.id,
              layerIds: ids,
              perspective,
              returnPeriod1,
              returnPeriod2,
              returnPeriod3,
              aggregationMethod,
              lossFilter,
            })
          )
        })

        return actions
      })
    )
  })

  private createLoadedLossSets(
    scaledProps: fromLossSetLayerModel.ScaledLossSetProps[],
    lossSetLayers: fromLossSetLayerModel.LossSetLayer[]
  ) {
    const loadedLossSetResponses: ApiResponse<LoadedLossSet>[] = []
    scaledProps.forEach(p => {
      // POST LoadedLossSet if user updated Loss Scale Factor
      if (p.lossScaleFactor !== 1) {
        // Get loss set id and use to POST LoadedLossSet
        let lossSet = lossSetLayers.find(l => l.id === p.id)?.loss_sets[0] as
          | LossSet
          | LoadedLossSet

        // If it's already loaded, grab the original loss set instead
        if (lossSet._type === 'LoadedLossSet') {
          lossSet = (lossSet as LoadedLossSet).source as LossSet
        }
        loadedLossSetResponses.push(
          this.service.postLoadedLossSet({
            _type: 'LoadedLossSet',
            description: `${p.description}`,
            load: p.lossScaleFactor,
            source: {
              ref_id: lossSet.id,
            },
            meta_data: {
              loadedForLayerID: p.id,
              map1: p.map1,
              map2: p.map2,
              map3: p.map3,
              map4: p.map4,
              map5: p.map5,
            },
          })
        )
      }
    })
    if (loadedLossSetResponses.length > 0) {
      return forkJoin(loadedLossSetResponses).pipe(
        map(responses => {
          const data: LoadedLossSet[] = []
          for (const response of responses) {
            if (response.error) {
              return { error: response.error }
            } else {
              data.push(response.data!)
            }
          }
          return { data }
        })
      )
    } else {
      return of({ data: undefined })
    }
  }

  private getQuotaShareWrapperProps(
    sp: fromLossSetLayerModel.ScaledLossSetProps,
    lossSetLayers: fromLossSetLayerModel.LossSetLayer[],
    loadedLossSets: LoadedLossSet[]
  ) {
    const lossSetLayer = lossSetLayers.find(l => l.id === sp.id)!

    // Get original LossSet - could be direct reference in layer, or source of
    // LoadedLossSet if LoadedLossSet exists
    const lossSetProp = lossSetLayer.loss_sets[0]
    const originalLossSet = isLoadedLossSet(lossSetProp)
      ? (lossSetProp.source as LossSet)
      : (lossSetProp as LossSet)

    // Find LoadedLossSet for current layer if it exists
    const newLoadedLossSet = loadedLossSets
      ? loadedLossSets.find(lls => lls.meta_data.loadedForLayerID === sp.id)
      : undefined

    // Use LoadedLossSet ID if it exists, otherwise reference original loss set
    const lossSetID = newLoadedLossSet
      ? newLoadedLossSet.id
      : originalLossSet.id
    const fee =
      lossSetLayer.fees!.length > 0
        ? lossSetLayer.fees![0]
        : createProportionalExpense('Fee', 0)

    return {
      lossSetLayer,
      lossSetID,
      fee,
    }
  }
}

const mapToLossSetLayers =
  (): OperatorFunction<LossSetLayer[], fromLossSetLayerModel.LossSetLayer[]> =>
  (layers: Observable<LossSetLayer[]>) =>
    layers.pipe(
      map(layersResponse => {
        return layersResponse.map(l => ({
          id: l.id,
          meta_data: l.meta_data,
          description: l.description || '',
          premium: l.premium,
          mean: 0,
          loss_sets: l.loss_sets,
          fees: l.fees,
          event_limit: l.event_limit,
          participation: l.participation,
        }))
      })
    )
