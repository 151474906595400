import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getMedianValue } from '../../../shared/util/math'
import { isLayerMetricApplicable } from '../../model/layer-metric-applicability'
import { LayerView, LayerViewValues } from '../../model/layer-view'
import { CalculatedPortfolioDetailMetrics } from '../../model/portfolio-metrics.model'
import { selectPortfolioViewDetailCededMetrics } from '../../store/analysis.selectors'
import { selectLayerMetricsViews } from '../../store/metrics/layer-metrics.selectors'
import LayerModelingProps from '../layer-modeling-defs'
import {
  LayerModelingDatum,
  LayerModelingDimension,
  LayerModelingView,
} from '../layer-modeling.model'
import * as fromReducer from './layer-modeling.reducer'

export const selectLayerModelingState =
  createFeatureSelector<fromReducer.LayerModelingState>(
    fromReducer.LAYER_MODELING_FEATURE_KEY
  )

export const selectLayerModelingView = createSelector(
  selectLayerModelingState,
  selectLayerMetricsViews,
  selectPortfolioViewDetailCededMetrics,
  (state, views, cededMetrics): LayerModelingView => {
    if (cededMetrics == null || views.length === 0) {
      return { loading: true, data: [] }
    }

    const data = views.map(createDatum(state))
    return {
      loading: false,
      data,
      medians: {
        x:
          getPortfolioMedian(state.x, cededMetrics) ||
          getMedianValue(d => d.x, data),
        y:
          getPortfolioMedian(state.y, cededMetrics) ||
          getMedianValue(d => d.y, data),
      },
    }
  }
)

const getPortfolioMedian = (
  prop: keyof LayerViewValues,
  cededMetrics: CalculatedPortfolioDetailMetrics
): number | null => {
  switch (prop) {
    case 'premium':
      return Math.abs(cededMetrics.premium)

    case 'purePremium':
      return Math.abs(cededMetrics.expectedLoss)

    case 'expectedCededMargin':
      return cededMetrics.margin

    case 'expectedCededLossRatio':
      const { premium, expectedLoss } = cededMetrics
      return premium ? Math.min(expectedLoss / premium, 1) : 0
  }
  return null
}

const makeGetValue =
  (state: fromReducer.LayerModelingState, view: LayerView) =>
  (dimension: LayerModelingDimension): number => {
    const propID = state[dimension]
    const prop = LayerModelingProps.find(p => p.id === propID)

    if (!prop) {
      throw Error(`Layer Modeling property '${propID} not found.`)
    }

    if (!isLayerMetricApplicable(view, prop)) {
      return 0
    }

    const value = view.values[propID]

    if (value == null || typeof value !== 'number') {
      throw Error(
        `Layer Modeling ${dimension} '${propID}' value is not a number (${value}).`
      )
    }
    if (value >= 1e21) {
      return 0
    }
    return value
  }

const createDatum =
  (state: fromReducer.LayerModelingState) =>
  (view: LayerView): LayerModelingDatum => {
    const getValue = makeGetValue(state, view)
    const x = getValue('x')
    const y = getValue('y')
    const size = getValue('size')
    const description = view.values.name
    const layerType = view.values.type
    const currency = view.values.currency
    return { description, layerType, x, y, size, currency }
  }
