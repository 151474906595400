import { NgModule } from '@angular/core'
import { OptimizationRoutingModule } from './optimization-routing.module'
import { SharedModule } from '@shared/shared.module'
import { OptimizationComponent } from './optimization.component/optimization.component'
import { OptimizationContainerComponent } from './optimization.container/optimization.container'
import { OptimizationService } from './optimization.service'
import { OptimizationHeaderComponent } from './optimization-header/optimization-header.component'
import { OptimizationInputRangeComponent } from './optimization-range-input/optimization-range-input.component'
import { StoreModule } from '@ngrx/store'
import * as fromOptimizationReducer from './store/optimization.reducer'
import { EffectsModule } from '@ngrx/effects'
import { OptimizationRangesComponent } from './optimization-ranges/optimization-ranges.component'
import { OptimizationCandidateResultsEffects } from './store/optimization-candidates-results.effects'
import { OptimizationCandidatesResultsComponent } from './optimization-candidates-results/optimization-candidates-results.component'
import { OptimizationCandidatesResultsMetricsComponent } from './optimization-candidates-results-metrics/optimization-candidates-results-metrics.component'
import { LayerModelingModule } from '../layer-modeling/layer-modeling.module'
import { OptimizationSummaryComponent } from './optimization-summary/optimization-summary.component'
import { OptimizationLayersEffects } from './store/optimization-layers.effects'
import { OptimizationTailMetricsEffects } from './store/optimization-tail-metrics.effects'
import { OptimizationPortfolioTailMetricsComponent } from './optimization-portfolio-tail-metrics/optimization-portfolio-tail-metrics.component'

@NgModule({
  declarations: [
    OptimizationComponent,
    OptimizationContainerComponent,
    OptimizationHeaderComponent,
    OptimizationInputRangeComponent,
    OptimizationRangesComponent,
    OptimizationCandidatesResultsComponent,
    OptimizationCandidatesResultsMetricsComponent,
    OptimizationSummaryComponent,
    OptimizationPortfolioTailMetricsComponent,
  ],
  imports: [
    SharedModule,
    OptimizationRoutingModule,
    StoreModule.forFeature(
      fromOptimizationReducer.OPTIMIZATION_FEATURE_KEY,
      fromOptimizationReducer.reducer
    ),
    EffectsModule.forFeature([
      OptimizationCandidateResultsEffects,
      OptimizationLayersEffects,
      OptimizationTailMetricsEffects,
    ]),
    LayerModelingModule,
  ],
  providers: [OptimizationService],
})
export class OptimizationModule {}
