<mat-menu #menu class="app-mat-theme" (click)="$event.stopPropagation()">
  <ng-container *ngFor="let item of items">
    <button
      *ngIf="!item.children; else branch"
      mat-menu-item
      (click)="menuItemClick.emit(item)"
    >
      {{ item.name }}
    </button>
    <ng-template #branch>
      <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu">
        {{ item.name }}
      </button>
      <app-dynamic-menu
        #innerPanel
        [items]="item.children"
        (menuItemClick)="menuItemClick.emit($event)"
      ></app-dynamic-menu>
    </ng-template>
  </ng-container>
</mat-menu>
