<div class="dialog-wrapper">
  <div class="dialog-header">
    <h3>Data Has Associated Custom Comparisons</h3>
    <button appButtonIcon big class="close icon" (click)="destroyDialog()">
      close
    </button>
  </div>
  <div class="dialog-content">
    <div class="dialog-data">
      <table>
        <tr>
          <th class="name-column">Comparison Name</th>
          <th>Remove?</th>
          <th>Delete?</th>
        </tr>
        <tr *ngFor="let comparison of comparisons">
          <td class="name-column">{{ comparison.name }}</td>
          <td>
            <mat-checkbox
              [checked]="comparisonStatus[comparison.id].status === 'remove'"
              [disabled]="comparisonStatus[comparison.id].status === 'remove'"
              (change)="updateCheckbox(comparison.id, 'remove')"
              matTooltip="Remove this Reinsurer Version from the Comparison"
            ></mat-checkbox>
          </td>
          <td>
            <mat-checkbox
              [checked]="comparisonStatus[comparison.id].status === 'delete'"
              [disabled]="comparisonStatus[comparison.id].status === 'delete'"
              (change)="updateCheckbox(comparison.id, 'delete')"
              matTooltip="Delete this Custom Comparison"
            ></mat-checkbox>
          </td>
        </tr>
      </table>
    </div>
    <div class="dialog-actions">
      <button appButton (click)="confirmForm()">
        Confirm
      </button>
      <button appButton (click)="destroyDialog()">Cancel</button>
    </div>
  </div>
</div>
