import { createAction, props } from '@ngrx/store'
import { LayerView } from '../../model/layer-view'
import { Change, OptimizationCandidateLayer } from '../optimization.model'
import { ApplicationError } from '../../../error/model/error'

export const generateLayers = createAction(
  '[Optimization] Generate Layers',
  props<{ templates: LayerView[] }>()
)

export const generateLayersSuccess = createAction(
  '[Optimization] Generate Layers Success',
  props<{ values: OptimizationCandidateLayer[] }>()
)

export const generateLayersFailure = createAction(
  '[Optimization] Generate Layers Failure',
  props<{ error: ApplicationError }>()
)

export const updateLayers = createAction(
  '[Optimization] Update Layers',
  props<{ changes: Change<OptimizationCandidateLayer>[] }>()
)

export const getLayersMetrics = createAction(
  '[Optimization] Get Layers Metrics',
  props<{ ids: string[] }>()
)

export const getLayersMetricsSuccess = createAction(
  '[Optimization] Get Layers Metrics Success',
  props<{ changes: Change<OptimizationCandidateLayer>[] }>()
)

export const getLayersMetricsFailure = createAction(
  '[Optimization] Get Layers Metrics Failure',
  props<{ error: ApplicationError }>()
)
