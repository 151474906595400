import { createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { State } from './program-group.state.facade'
import { append, lensProp, set, view } from 'ramda'
import * as fromGrouperActions from '../../../analysis/store/grouper/grouper.actions'
import { ProgramGroup } from '../../../analysis/store/grouper/program-group.model'
import * as fromGroupScenarioActions from '../../../analysis/store/grouper/program-group/program-group-scenarios.actions'
import { convertAllProgramGroupsFromResponse } from '../../../api/program-group/program-group.converter'
import * as AuthActions from '../auth/auth.actions'
import { ExtendedState } from './program-group.state.facade'
import { updateProgramGroupSuccess } from 'src/app/analysis/store/grouper/program-group/program-group.actions'

export const adapter = createEntityAdapter<ProgramGroup>({
  selectId: (pg): string => `${pg.id}`,
})

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
})

const programGroupsReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(AuthActions.identifyPermissionsSuccess, (state, { programGroups }) => {
    const pgs = convertAllProgramGroupsFromResponse(programGroups)
    const next = adapter.setAll(pgs, state)
    return {
      ...next,
      loading: initialState.loading,
      error: initialState.error,
    }
  }),

  on(updateProgramGroupSuccess, (state, { group }) => {
    const modifiedState = adapter.updateOne(
      {
        id: group.id,
        changes: {
          ...group,
        },
      },
      {
        ...state,
      }
    )
    return modifiedState
  }),

  on(
    fromGrouperActions.saveGrouperSuccess,
    fromGroupScenarioActions.saveProgramGroupScenariosSuccess,
    (state, { groups }) => {
      const { create, update, remove } = groups

      let nextState = create.reduce((acc, g) => {
        if (g.isScenario && g.parentScenarioID) {
          // Group scenario being added: add its ID to its parent's scenarios
          const id = g.parentScenarioID
          const parent = acc.entities[id]
          if (parent) {
            const prev = view(scenarioIDsLens, parent) as string[] | undefined
            const next = append(g.id, prev ?? [])
            const changes = set(scenarioIDsLens, next, parent)
            return adapter.updateOne({ id, changes }, acc)
          }
        }
        return acc
      }, state)

      nextState = remove.reduce((acc, idToRemove) => {
        const g = acc.entities[idToRemove]
        if (g && g.isScenario && g.parentScenarioID) {
          // Group scenario being removed: remove its ID from parent's scenarios
          const id = g.parentScenarioID
          const parent = acc.entities[id]
          if (parent) {
            const prev = view(scenarioIDsLens, parent) as string[] | undefined
            const next = (prev ?? []).filter(it => it !== g.id)
            const changes = set(scenarioIDsLens, next, parent)
            return adapter.updateOne({ id, changes }, acc)
          }
        }
        return acc
      }, nextState)

      return adapter.removeMany(
        remove,
        adapter.updateMany(update, adapter.addMany(create, nextState))
      )
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return programGroupsReducer(state, action)
}

// @ts-ignore
const scenarioIDsLens = lensProp('scenarioIDs')
