import { Action, createReducer, on } from '@ngrx/store'
import { QuickQuoteUpdates } from '../../models/quote.model'
import {
  addOrUpdateQuickQuote,
  backQuickQuote,
  saveQuickQuoteSuccess,
  updateQuickQuoteAutoFill,
  updateQuickQuoteField,
} from './quick-quote.action'

export interface State {
  selectedField: string
  addOrUpdateData: QuickQuoteUpdates[]
  autoFill: boolean
}

export const initialState: State = {
  selectedField: 'Rate',
  addOrUpdateData: [],
  autoFill: true
}

const QuickQuoteReducer = createReducer(
  initialState,

  on(updateQuickQuoteField, (state, { field }) => ({
    ...state,
    selectedField: field,
  })),

  on(addOrUpdateQuickQuote, (state, { data }) => ({
    ...state,
    addOrUpdateData: [
      ...state.addOrUpdateData.filter((update) =>
        !(update.cededLayerId === data.cededLayerId &&
        update.reinsurerName === data.reinsurerName &&
        update.reinsurerId === data.reinsurerId)
      ),
      data
    ]
  })),

  on(backQuickQuote, saveQuickQuoteSuccess, state => ({
    ...state,
    addOrUpdateData: [],
  })),

  on(updateQuickQuoteAutoFill, (state, { autoFill }) => ({
    ...state,
    autoFill: autoFill,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return QuickQuoteReducer(state, action)
}
