import { createAction } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { BenchmarkData } from '../model/benchmark-chart'
import { BenchmarkModeID, BenchmarkSystemModeID } from '../model/benchmark-mode'
import { BenchmarkSubmode } from '../model/benchmark-submode'
import { BenchmarkDataRefreshStatus, BenchmarkLob, BenchmarkMetric } from '../model/benchmark.model'

export const fetchBenchmarkSubmodes = createAction('[Benchmark] Fetch Submodes')
export const fetchBenchmarkUiSettings = createAction('[Benchmark] Fetch UI Settings')

export const fetchBenchmarkSubmodesSuccess = createAction(
  '[Benchmark] Fetch Submodes Success',
  (submodes: Record<string, BenchmarkSubmode>) => ({
    submodes,
  })
)
export const fetchBenchmarkSubmodesFailure = createAction(
  '[Benchmark] Fetch Submodes Failure',
  (error: ApplicationError) => ({ error })
)

export const fetchBenchmarkMetrics = createAction(
  '[Benchmark] Fetch Metrics',
  (force: boolean = false) => ({ force })
)
export const fetchBenchmarkMetricsSuccess = createAction(
  '[Benchmark] Fetch Metrics Success',
  (data: BenchmarkMetric[]) => ({ data })
)
export const fetchBenchmarkMetricsFailure = createAction(
  '[Benchmark] Fetch Metrics Failure',
  (error: ApplicationError) => ({ error })
)
export const fetchBenchmarkMetricsAbort = createAction(
  '[Benchmark] Fetch Metrics Abort'
)
export const invalidateBenchmarkMetrics = createAction(
  '[Benchmark] Invalidate Metrics'
)

export const fetchBenchmarkLobs = createAction(
  '[Benchmark] Fetch LOBs',
  (force: boolean = false) => ({ force })
)
export const fetchBenchmarkLobsSuccess = createAction(
  '[Benchmark] Fetch LOBs Success',
  (data: BenchmarkLob[]) => ({ data })
)
export const fetchBenchmarkLobsFailure = createAction(
  '[Benchmark] Fetch LOBs Failure',
  (error: ApplicationError) => ({ error })
)
export const fetchBenchmarkLobsAbort = createAction(
  '[Benchmark] Fetch LOBs Abort'
)
export const invalidateBenchmarkLobs = createAction(
  '[Benchmark] Invalidate LOBs'
)

export const setBenchmarkMode = createAction(
  '[Benchmark] Set Mode',
  (mode: BenchmarkModeID | null = null) => ({ mode })
)

export const setBenchmarkSubmode = createAction(
  '[Benchmark] Set Submode',
  (submode: string | null = null, subheader?: string | null) => ({
    submode,
    subheader,
  })
)

export const setBenchmarkManagePeerSetsMode = createAction(
  '[Benchmark] Set Manage Peer Sets Mode',
  (mode: BenchmarkSystemModeID) => ({ mode })
)

export const changeBenchmarkMode = createAction(
  '[Benchmark] Change Mode',
  (mode: BenchmarkModeID | null = null, submode: string | null = null) => ({
    mode,
    submode,
  })
)

export const moveBenchmarkMode = createAction(
  '[Benchmark] Move Mode',
  (step: number) => ({ step })
)

export const fetchBenchmarkCharts = createAction(
  '[Benchmark] Fetch Charts',
  (force: boolean = false) => ({ force })
)
export const fetchBenchmarkChartsSuccess = createAction(
  '[Benchmark] Fetch Charts Success',
  (data: BenchmarkData) => ({ data })
)
export const fetchBenchmarkChartsFailure = createAction(
  '[Benchmark] Fetch Charts Failure',
  (error: ApplicationError) => ({ error })
)
export const fetchBenchmarkChartsAbort = createAction(
  '[Benchmark] Fetch Charts Abort'
)
export const invalidateBenchmarkData = createAction(
  '[Benchmark] Invalidate Charts'
)

export const clearBenchmarkCharts = createAction('[Benchmark] Clear Charts')

export const exportFullBenchmarkData = createAction(
  '[Benchmark] Export Full Data'
)

export const exportCompositeMembers = createAction(
  '[Benchmark] Export Composite Members'
)

export const exportCompositeMembersFailure = createAction(
  '[Benchmark] Export Composite Members Failure',
  (error: ApplicationError) => ({ error })
)

export const maximizeBenchmarkChart = createAction(
  '[Benchmark] Maximize',
  (index: number) => ({ index })
)

export const setBenchmarkExtents = createAction(
  '[Benchmark] Set Extents',
  (extents: [number, number, number]) => ({ extents })
)

export const fetchBenchmarkDataRefreshStatus = createAction(
  '[Benchmark] Fetch Data Refresh Status'
)

export const fetchBenchmarkDataRefreshStatusSuccess = createAction(
  '[Benchmark] Fetch Data Refresh Status Success',
  (data: BenchmarkDataRefreshStatus) => ({ data })
)

export const fetchBenchmarkDataRefreshStatusFailure = createAction(
  '[Benchmark] Fetch Data Refresh Status Failure',
  (error: ApplicationError) => ({ error })
)
