import { BenchmarkDimensionMetricConfig } from '../../../benchmark/model/benchmark-metric-dimension'
import {
  BenchmarkSubheader,
  BenchmarkSubmode,
} from '../../../benchmark/model/benchmark-submode'
import { BenchmarkTimeframe } from '../../../benchmark/model/benchmark-timeframe'
import {
  BenchmarkLob,
  BENCHMARK_US_STATE_POPULATION_METRIC_ID,
} from '../../../benchmark/model/benchmark.model'
import { GraphingValueFormat } from '@graphing/utils/graphing-value-formatter'
import {
  BENCHMARK_INDUSTRY_DPW_BY_POPULATION,
  BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
} from '../benchmark-combined-metric-ids'

const FORMAT_PERCENT: GraphingValueFormat = {
  type: 'short-fixed',
  precision: 1,
  suffix: '%',
  transform: 'percent',
}

const FORMAT_VOLATILITY: GraphingValueFormat = {
  type: 'short-fixed',
  precision: 1,
  suffix: '%',
  transform: 'percent',
}

const FORMAT_PERCENT_ALT_PERCENT: GraphingValueFormat = {
  ...FORMAT_PERCENT,
  alt: FORMAT_PERCENT,
}

const FORMAT_VOLATILITY_ALT_PERCENT: GraphingValueFormat = {
  ...FORMAT_VOLATILITY,
  alt: FORMAT_PERCENT,
}

// Data Item IDs
export const DPW = 126780
const DPE = 126781
const DIRECT_LOSSES_INCURRED = 126784

export const BENCHMARK_MPL_METRIC_IDS = [DPW, DPE, DIRECT_LOSSES_INCURRED]

export const DPW_METRIC: BenchmarkDimensionMetricConfig = {
  id: 'MPL_DPW',
  name: 'DPW',
  compositeIDs: [DPW],
  aggregateMethod: 'sum',
  entityFilter: 'target',
}
const DPW_GROWTH_PERCENT_METRIC: BenchmarkDimensionMetricConfig = {
  id: 'MPL_DPW_GROWTH_PERCENT',
  name: 'DPW Growth',
  compositeIDs: [DPW],
  aggregateMethod: 'cagr',
  format: 'percent',
}
const DPW_GROWTH_METRIC: BenchmarkDimensionMetricConfig = {
  id: 'MPL_DPW_GROWTH',
  name: 'DPW Growth $',
  compositeIDs: [DPW],
  aggregateMethod: 'change',
}
const DPW_GROWTH_VS_PEERS_METRIC: BenchmarkDimensionMetricConfig = {
  ...DPW_GROWTH_PERCENT_METRIC,
  id: 'MPL_DPW_GROWTH_VS_PEERS',
  entityFilter: 'target-and-peers',
  aggregateMethod: 'cagr-vs-peers',
}
const LOSS_RATIO_METRIC: BenchmarkDimensionMetricConfig = {
  id: 'MPL_LOSS_RATIO',
  name: 'Loss Ratio',
  compositeIDs: [DIRECT_LOSSES_INCURRED, DPE],
  compositeDefaultValues: [0, 1],
  aggregateMethod: 'aggregate-ratio',
  format: FORMAT_PERCENT_ALT_PERCENT,
  colorScale: {
    reverse: true,
    extentsMin: 0,
    extentsMax: 1,
    divergeOrigin: 0.5,
  },
}
const LOSS_RATIO_VOLATILITY: BenchmarkDimensionMetricConfig = {
  id: 'MPL_LOSS_RATIO_VOLATILITY',
  name: 'Loss Ratio Volatility',
  compositeIDs: [DIRECT_LOSSES_INCURRED, DPE],
  aggregateMethod: 'sd',
  format: FORMAT_VOLATILITY_ALT_PERCENT,
  colorScale: {
    reverse: true,
    extentsMin: 0,
    extentsMax: 0.5,
    divergeOrigin: 0.25,
  },
}
const LOSS_RATIO_VS_PEERS_METRIC: BenchmarkDimensionMetricConfig = {
  ...LOSS_RATIO_METRIC,
  id: 'MPL_LOSS_RATIO_VS_PEERS',
  entityFilter: 'target-and-peers',
  aggregateMethod: 'aggregate-ratio-vs-peers',
  colorScale: { reverse: true },
}
const MARKET_SHARE_PERCENT_METRIC: BenchmarkDimensionMetricConfig = {
  id: 'MPL_MARKET_SHARE',
  name: 'Market Share %',
  compositeIDs: [DPW],
  aggregateMethod: 'market-share',
  format: 'percent',
}

// TODO: show/hide based on entityFilter
const STATE_METRICS: BenchmarkDimensionMetricConfig[] = [
  {
    ...DPW_METRIC,
    entityFilter: 'target',
    colorScale: { skipClampOrigin: true, colors: 'positive' },
  },
  {
    ...DPW_METRIC,
    id: 'MPL_DPW_PEERS',
    entityFilter: 'all-peers',
    colorScale: { colors: 'positive' },
  },
  { ...DPW_GROWTH_PERCENT_METRIC, entityFilter: 'target' },
  {
    ...DPW_GROWTH_PERCENT_METRIC,
    id: 'MPL_DPW_GROWTH_PERCENT_PEERS',
    entityFilter: 'all-peers',
  },
  { ...DPW_GROWTH_METRIC, entityFilter: 'target' },
  {
    ...DPW_GROWTH_METRIC,
    id: 'MPL_DPW_GROWTH_PEERS',
    entityFilter: 'all-peers',
  },
  DPW_GROWTH_VS_PEERS_METRIC,
  {
    id: BENCHMARK_INDUSTRY_DPW_BY_POPULATION,
    name: 'DPW per 1,000 people',
    aggregateMethod: 'last',
    compositeIDs: [DPW],
    weightUSStatePopulations: true,
    colorScale: { colors: 'positive' },
  },
  {
    id: 'MPL_POP_SIZE',
    name: 'Population Size',
    aggregateMethod: 'last',
    compositeIDs: [BENCHMARK_US_STATE_POPULATION_METRIC_ID],
    weightUSStatePopulations: true,
    colorScale: { colors: 'positive' },
  },
  {
    id: 'MPL_POP_GROWTH',
    name: 'Population Growth %',
    aggregateMethod: 'cagr',
    compositeIDs: [BENCHMARK_US_STATE_POPULATION_METRIC_ID],
    weightUSStatePopulations: true,
    format: 'percent',
  },
  {
    id: BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
    name: '5yr Projected Population Growth %',
    aggregateMethod: 'percent-change',
    compositeIDs: [BENCHMARK_US_STATE_POPULATION_METRIC_ID],
    weightUSStatePopulations: true,
    format: 'percent',
  },
  { ...LOSS_RATIO_METRIC, entityFilter: 'target' },
  {
    ...LOSS_RATIO_METRIC,
    id: 'MPL_LOSS_RATIO_PEERS',
    entityFilter: 'all-peers',
  },
  { ...LOSS_RATIO_VOLATILITY, entityFilter: 'target' },
  {
    ...LOSS_RATIO_VOLATILITY,
    id: 'MPL_LOSS_RATIO_VOLATILITY_PEERS',
    entityFilter: 'all-peers',
  },
  {
    ...LOSS_RATIO_METRIC,
    id: 'MPL_LOSS_RATIO_VS_PEERS',
    entityFilter: 'target-and-peers',
    aggregateMethod: 'aggregate-ratio-vs-peers',
    colorScale: { reverse: true },
  },
  {
    ...MARKET_SHARE_PERCENT_METRIC,
    id: 'MPL_MARKET_SHARE',
    entityFilter: 'target-industry',
    colorScale: {
      colors: 'positive',
    },
  },
  // TODO: Market Share %
  // TODO: Market Share Concentration (HHI)
]

const TIMEFRAME_LAST_2_YEARS: (string | number)[] = [
  BENCHMARK_INDUSTRY_DPW_BY_POPULATION,
  'MPL_POP_SIZE',
]

const TIMEFRAME_YEARS: BenchmarkTimeframe[] = [
  { value: 10, showRange: true },
  { value: 5, showRange: true },
  { value: 3, showRange: true },
  { value: 1, showRange: true },
]

const SUBHEADERS: BenchmarkSubheader[] = [
  { id: 'mpl', name: 'Maps' },
  { id: 'mpl-growth', name: 'DPW Growth' },
  { id: 'mpl-lr', name: 'Loss Ratio' },
  { id: 'mpl-diversification', name: 'By Line Diversification' },
  { id: 'mpl-diversification-states', name: 'By State Diversification' },
  { id: 'mpl-performance', name: 'Performance' },
  { id: 'mpl-market-share', name: 'Market Share' },
]

export const BENCHMARK_MPL_LOBS: BenchmarkLob[] = [
  { id: 78, name: 'Med Prof Liab', abbreviation: 'MPL' },
  { id: 223, name: 'Physicians', abbreviation: 'Phys' },
  { id: 224, name: 'Hospital', abbreviation: 'Hosp' },
  { id: 225, name: 'Other HC Professionals', abbreviation: 'OP' },
  { id: 226, name: 'Other HC Facilities', abbreviation: 'OF' },
]

let gridIndex = -1

const BENCHMARK_STAT_MPL_CONFIGS: Record<string, BenchmarkSubmode> = {
  // Maps
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: 'MPL Supp A',
    description:
      'Supplement A data. Assesses the four medical professional liability ' +
      'lines of business.',
    dataType: 'geo',
    endpoint: 'metrics/geo',
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    subheaders: SUBHEADERS,
    labels: {
      alignTitle: 'right',
      title: [
        { type: 'metricEntity' },
        { type: 'timeframe' },
        { type: 'rawPrimaryMetric' },
        {
          type: 'lob',
          unlessMetricIDs: [
            'MPL_POP_SIZE',
            'MPL_POP_GROWTH',
            BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
          ],
          prefixSeparator: ' – ',
        },
      ],
      grid: [
        { type: 'metricEntity' },
        { type: 'timeframe' },
        { type: 'rawPrimaryMetric' },
        {
          type: 'lob',
          unlessMetricIDs: [
            'MPL_POP_SIZE',
            'MPL_POP_GROWTH',
            BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
          ],
          prefixSeparator: ' – ',
          abbreviate: true,
        },
      ],
      export: { type: 'timeframe' },
    },
    showPeerSetQuantileSelect: false,
    enableMultipleLob: true,
    targetValuesTableColumns: [
      {
        label: 'DPW',
        format: 'shortNumber',
      },
      {
        label: '% Total DPW',
        format: 'ratio',
        width: '6rem',
        weight: '600',
      },
    ],
    disablePeerSetColors: true,
    format: 'short-fixed',
    targetValueMetricID: DPW,
    dimensions: {
      primary: {
        ids: STATE_METRICS,
        defaultID: DPW_METRIC.id,
      },
    },
    timeframes: [
      {
        value: 10,
        showRange: true,
        unlessMetric: [
          ...TIMEFRAME_LAST_2_YEARS,
          BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
        ],
      },
      {
        value: 5,
        showRange: true,
        unlessMetric: [
          ...TIMEFRAME_LAST_2_YEARS,
          BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
        ],
      },
      {
        value: 3,
        showRange: true,
        unlessMetric: [
          ...TIMEFRAME_LAST_2_YEARS,
          BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
        ],
      },
      {
        value: 1,
        showRange: true,
        unlessMetric: [
          ...TIMEFRAME_LAST_2_YEARS,
          'MPL_LOSS_RATIO_VOLATILITY',
          BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
        ],
      },
      {
        transform: 'most-recent-year',
        value: 10,
        whenMetric: TIMEFRAME_LAST_2_YEARS,
      },
    ],
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [{ id: 'excludeIncompletePeers' }],
    hideTable: true,
    gridName: { useTitle: true },
    gridControls: ['metrics', 'timeframe'],
    grid: [
      { dimensions: { primary: { defaultID: 'MPL_DPW_PEERS' } } },
      { dimensions: { primary: { defaultID: 'MPL_LOSS_RATIO' } } },
      { dimensions: { primary: { defaultID: 'MPL_LOSS_RATIO_PEERS' } } },
    ],
    metricsDefinitions: [
      {
        metric: 'Direct Premiums Written',
        definition:
          'The premiums booked on policies where the company is the primary carrier.',
      },
      {
        metric: 'DPW Growth',
        definition: `The compounded percentage change in a company's direct premiums written. `,
        calculation:
          '(Current Direct Premiums Written / Original Direct Premiums Written) ^ (1 / n) - 1',
      },
      {
        metric: 'DPW Per 1,000 People',
        definition:
          'The amount of premium written directly by the company per 1,000 people.',
        calculation: 'Direct Premiums Written / 1,000',
      },
      {
        metric: 'Population Size',
        definition: 'The number of people living in a particular state.',
      },
      {
        metric: 'Population Growth %',
        definition:
          'The percentage change in population size in a given period of time.',
        calculation: '(Current Population Size / Original Population Size) - 1',
      },
      {
        metric: '5YR Projected Population Growth %',
        definition:
          'An estimation of the percentage change in the future population size.',
        calculation: '(Future Population Size / Current Population Size) - 1',
      },
      {
        metric: 'Direct Pure Loss Ratio',
        definition:
          'Direct incurred losses as a percentage of direct premiums written. This is an indicator of the underlying profitability of policies written by the company before reinsurance premiums.',
        calculation: 'Direct Incurred Losses / Direct Premiums Earned',
      },
      {
        metric: 'Direct Loss Ratio Volatility',
        definition:
          'Standard deviation of Direct Loss Ratio over a period of time.',
      },
      {
        metric: 'Market Share %',
        definition:
          'The amount of business a company writes in a given area relative to the total amount of business written for the industry in that same area.',
      },
    ],
  },
  // Growth
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'time-series',
    endpoint: 'metrics/geo',
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    includeTerritories: true,
    extents: {
      main: {
        include: [0],
        pad: [0.001, 0.02],
        nice: false,
      },
    },
    labels: {
      title: [
        { type: 'timeframe' },
        { type: 'state', prefixSeparator: ' – ' },
        { type: 'lob', prefixSeparator: ' – ' },
      ],
      grid: [
        { type: 'timeframe' },
        { type: 'state', prefixSeparator: ' – ' },
        { type: 'lob', prefixSeparator: ' – ', abbreviate: true },
      ],
      y2: 'Target (Bar Chart)',
      export: { type: 'timeframe' },
    },
    showPeerSetQuantileSelect: true,
    disablePeerSetColors: false,
    enableMultipleLob: false,
    // LOB: Physicians
    defaultLob: 223,
    showStates: true,
    enableMultipleStates: true,
    targetValueMetricID: DPW,
    dimensions: {
      primary: {
        ids: [DPW_METRIC],
        defaultID: DPW_METRIC.id,
        format: 'percent',
      },
    },
    timeframes: [
      {
        value: 10,
        transform: 'percent-growth-from-start',
      },
      {
        value: 5,
        transform: 'percent-growth-from-start',
      },
      {
        value: 10,
        transform: 'percent-growth-rolling',
        transformValue: 3,
      },
      {
        value: 10,
        transform: 'percent-growth-rolling',
        transformValue: 1,
      },
    ],
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [
      {
        id: 'percentile25',
        controlType: 'hidden',
        color: 'gray',
        style: 'thin',
      },
      { id: 'quantileArea' },
      { id: 'median' },
      {
        id: 'percentile75',
        controlType: 'hidden',
        color: 'gray',
        style: 'thin',
      },
      { id: 'secondaryAxis' },
      { id: 'smoothLines', value: false },
      { id: 'excludeIncompletePeers' },
    ],
    tableOptions: {
      valueType: 'percentage',
      includeRawValues: true,
      numberFormat: '1.1-1',
    },
    gridName: { useTitle: true },
    gridControls: ['lob'],
    grid: [
      // LOB: Hospital
      { defaultLob: 224 },
      // LOB: Other HC Professionals
      { defaultLob: 225 },
      // LOB: Other HC Facilities
      { defaultLob: 226 },
    ],
  },
  // Loss Ratio
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'time-series',
    endpoint: 'metrics/geo',
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    extents: {
      main: {
        include: [0],
        pad: [0.001, 0.02],
        nice: false,
      },
    },
    labels: {
      title: [
        { type: 'primaryMetric', template: '{} Trend vs. Peers' },
        { type: 'state', prefixSeparator: ' – ' },
        { type: 'lob', prefixSeparator: ' – ' },
      ],
      grid: [
        { type: 'state' },
        { type: 'lob', prefixSeparator: ' – ', abbreviate: true },
      ],
      export: { type: 'timeframe' },
    },
    showPeerSetQuantileSelect: true,
    disablePeerSetColors: false,
    enableMultipleLob: true,
    showStates: true,
    enableMultipleStates: true,
    // LOB: MPL
    defaultLob: 78,
    includeTerritories: true,
    targetValuesTableColumns: [{ label: '% Total DPW', format: 'ratio' }],
    targetValueMetricID: DPW,
    dimensions: {
      primary: {
        ids: [LOSS_RATIO_METRIC],
        defaultID: LOSS_RATIO_METRIC.id,
        format: 'percent',
        lobTargetValueMetricID: DPW,
        stateTargetValueMetricID: DPW,
      },
    },
    timeframes: [10],
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [
      {
        id: 'percentile25',
        controlType: 'hidden',
        color: 'gray',
        style: 'thin',
      },
      { id: 'median' },
      {
        id: 'percentile75',
        controlType: 'hidden',
        color: 'gray',
        style: 'thin',
      },
      { id: 'quantileArea' },
      { id: 'smoothLines', value: false },
      { id: 'excludeIncompletePeers' },
      { id: 'sortByValue' },
      { id: 'sortByValueTimeframe' },
      { id: 'dataLabelFormat' },
    ],
    tableOptions: { valueType: 'percentage', numberFormat: '1.1-1' },
    gridName: { useTitle: true },
    gridControls: ['lob', 'states'],
    gridLink: { to: 'child', index: 2 },
    grid: [
      {
        gridDeselect: true,
        gridLink: { to: 'child', index: 3 },
        dataType: 'time-series',
        extents: {
          main: {
            include: [0],
            pad: [0.001, 0.02],
            nice: false,
          },
        },
        labels: {
          title: [
            { type: 'primaryMetric', template: '{} Trend vs. Peers' },
            { type: 'state', prefixSeparator: ' – ' },
            { type: 'lob', prefixSeparator: ' – ' },
          ],
          grid: [
            { type: 'state' },
            { type: 'lob', prefixSeparator: ' – ', abbreviate: true },
          ],
          export: { type: 'timeframe' },
        },
        // LOB: Physicians
        defaultLob: 223,
        timeframes: [10],
      },
      {
        gridLink: { to: 'parent', index: 0 },
        dataType: 'aggregate-timeframes',
        dataTypeOptions: { groupBy: 'timeframe-peerSet' },
        extents: {
          main: { pad: [0.001, 0.02], nice: false },
          cross: { pad: 0.2 },
        },
        labels: {
          title: [
            { type: 'primaryMetric', template: '{} Avgs. vs. Peers' },
            { type: 'state', prefixSeparator: ' – ' },
            { type: 'lob', prefixSeparator: ' – ' },
          ],
        },
        // LOB: Physicians
        defaultLob: 78,
        entityFilter: 'all-peers',
        useAllTimeframeOptions: true,
        timeframes: [{ transform: 'most-recent-year' }, 5, 10],
      },
      {
        gridLink: { to: 'parent', index: 1 },
        dataType: 'aggregate-timeframes',
        dataTypeOptions: { groupBy: 'timeframe-peerSet' },
        extents: {
          main: { pad: [0.001, 0.02], nice: false },
          cross: { pad: 0.2 },
        },
        labels: {
          title: [
            { type: 'primaryMetric', template: '{} Avgs. vs. Peers' },
            { type: 'state', prefixSeparator: ' – ' },
            { type: 'lob', prefixSeparator: ' – ' },
          ],
        },
        // LOB: Physicians
        defaultLob: 223,
        entityFilter: 'all-peers',
        useAllTimeframeOptions: true,
        timeframes: [{ transform: 'most-recent-year' }, 5, 10],
      },
    ],
  },
  // By Line Diversification
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'aggregate-lobs',
    endpoint: 'metrics/geo',
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    includeTerritories: true,
    extents: {
      main: { pad: [0.001, 0.02], nice: false },
      cross: { pad: 0.1 },
    },
    labels: {
      title: [
        { type: 'dateRange' },
        { type: 'primaryMetric', template: '{} by Line', prefixSeparator: ' ' },
        { type: 'state', prefixSeparator: ' – ' },
      ],
      export: 'MPL By Line Utilization',
    },
    enableMultipleLob: true,
    enableMultipleStates: true,
    targetValueMetricID: DPW,
    targetValuesTableColumns: [
      { label: 'DPW', format: 'shortNumber' },
      {
        label: '% Total DPW',
        format: 'ratio',
        width: '6rem',
        weight: '600',
      },
    ],
    targetValueLegendColors: {
      78: 'gray',
      223: 'mint',
      224: 'salmon',
      225: 'indigo',
      226: 'magenta',
    },
    dimensions: {
      primary: {
        ids: [{ ...DPW_METRIC, aggregateMethod: 'sum' }],
        defaultID: DPW_METRIC.id,
        format: 'percent',
      },
    },
    timeframes: TIMEFRAME_YEARS,
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [
      { id: 'pointLabel', value: true },
      { id: 'excludeIncompletePeers' },
      { id: 'combinePeerSets' },
      { id: 'dataLabelFormat' },
    ],
    tableOptions: { valueType: 'percentage', numberFormat: '1.1-1' },
    showAbbreviationsTable: true,
    showPeerSetQuantileSelect: false,
    disablePeerSetColors: true,
  },
  // By State Diversification
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'aggregate-states',
    endpoint: 'metrics/geo',
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    includeTerritories: true,
    enableMultipleLob: true,
    showStates: true,
    enableMultipleStates: true,
    extents: {
      main: { pad: [0.001, 0.02], nice: false },
      cross: { pad: 0.1 },
    },
    labels: {
      title: [
        { type: 'dateRange' },
        {
          type: 'primaryMetric',
          template: '{} by State',
          prefixSeparator: ' ',
        },
        { type: 'lob', prefixSeparator: ' – ' },
      ],
      export: 'MPL By State Utilization',
    },
    targetValueMetricID: DPW,
    targetValuesTableColumns: [
      { label: 'DPW', format: 'shortNumber' },
      {
        label: '% Total DPW',
        format: 'ratio',
        width: '6rem',
        weight: '600',
      },
    ],
    dimensions: {
      primary: {
        ids: [{ ...DPW_METRIC, aggregateMethod: 'sum' }],
        defaultID: DPW_METRIC.id,
        format: 'percent',
      },
    },
    timeframes: TIMEFRAME_YEARS,
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [
      { id: 'pointLabel', value: true },
      { id: 'excludeIncompletePeers' },
      { id: 'combinePeerSets' },
      { id: 'dataLabelFormat' },
    ],
    tableOptions: { valueType: 'percentage', numberFormat: '1.1-1' },
    showAbbreviationsTable: true,
    showPeerSetQuantileSelect: false,
    disablePeerSetColors: true,
  },
  // Performance
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'aggregate',
    dataTypeOptions: {
      groupBy: 'state-lob',
      defaultTopStatesCount: 6,
      defaultTopLobsCount: 6,
      disableLegendOther: true,
      disableLegendReverse: true,
    },
    endpoint: 'metrics/geo',
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    // Show all LOBs except MPL by default
    defaultLob: BENCHMARK_MPL_LOBS.slice(1).map(def => def.id),
    useSubmodeLOBs: 'mpl',
    includeTerritories: true,
    enableMultipleLob: true,
    showStates: true,
    enableMultipleStates: true,
    targetValueMetricID: DPW,
    allowTargetValuesAllLinesSelection: true,
    targetValuesTableColumns: [
      {
        label: 'DPW',
        format: 'shortNumber',
      },
      {
        label: '% Total DPW',
        format: 'ratio',
        width: '6rem',
        weight: '600',
      },
    ],
    targetValueLegendColors: {
      78: 'gray',
      223: 'mint',
      224: 'salmon',
      225: 'indigo',
      226: 'magenta',
    },
    disablePeerSetColors: true,
    dimensions: {
      primary: {
        ids: [
          DPW_GROWTH_VS_PEERS_METRIC,
          { ...DPW_GROWTH_PERCENT_METRIC, entityFilter: 'target' },
        ],
      },
      secondary: {
        ids: [
          LOSS_RATIO_VS_PEERS_METRIC,
          { ...LOSS_RATIO_METRIC, entityFilter: 'target' },
        ],
        defaultID: LOSS_RATIO_VS_PEERS_METRIC.id,
      },
      tertiary: {
        ids: [DPW_METRIC],
        defaultID: [DPW_METRIC.id],
        isOptional: true,
      },
    },
    timeframes: [
      { value: 10, showRange: true },
      { value: 5, showRange: true },
      { value: 3, showRange: true },
    ],
    options: [
      { id: 'allStateAggregate', value: true },
      { id: 'pointLabel', value: true },
      { id: 'colorizeBy' },
    ],
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    hideTimeframeEndOffset: false,
    showMoreOptionsAtTopLevel: true,
    showPeerSetQuantileSelect: false,
    hideTable: true,
  },
  // Market Share
  [String(SUBHEADERS[++gridIndex].id)]: {
    id: String(SUBHEADERS[gridIndex].id),
    name: String(SUBHEADERS[gridIndex].name),
    dataType: 'table',
    endpoint: 'metrics/geo-market-share',
    tableOptions: {
      columnDefs: [
        { id: 'rank', label: 'Rank', valueType: 'numeric', minWidth: '1rem' },
        { id: 'entityName', label: 'Company' },
        { id: 'dpw', label: 'DPW', valueType: 'currency', minWidth: '1rem' },
        {
          id: 'marketShare',
          label: 'Market Share',
          valueType: 'percentage',
          decimals: 1,
          minWidth: '4.75rem',
        },
        {
          id: 'lr',
          label: '{most-recent-year}',
          valueType: 'percentage',
          category: 'Loss Ratio',
          decimals: 1,
          minWidth: '1rem',
        },
        {
          id: 'lr5yr',
          label: '5-Year',
          valueType: 'percentage',
          category: 'Loss Ratio',
          decimals: 1,
          minWidth: '3rem',
        },
      ],
    },
    subheaders: SUBHEADERS,
    lobAllLinesID: 78,
    useSubmodeLOBs: 'mpl',
    includeTerritories: true,
    targetValueMetricID: DPW,
    labels: {
      title: [
        { type: 'primaryMetric', prefixSeparator: ' ' },
        { type: 'state', prefixSeparator: ' – ' },
        { type: 'lob', prefixSeparator: ' – ' },
      ],
      grid: [
        { type: 'state' },
        { type: 'lob', prefixSeparator: ' – ', abbreviate: true },
      ],
      export: { type: 'timeframe' },
    },
    showPeerSetQuantileSelect: false,
    disablePeerSetColors: false,
    enableMultipleLob: false,
    // LOB: Physicians
    defaultLob: 223,
    showStates: true,
    enableMultipleStates: true,
    useManyMetrics: true,
    targetValuesTableColumns: [{ label: '% Total DPW', format: 'percent' }],
    dimensions: {
      primary: {
        ids: [
          {
            id: 'MPL_MARKET_SHARE_TABLE',
            name: 'Market Share',
            compositeIDs: [DPW, DIRECT_LOSSES_INCURRED, DPE],
            format: FORMAT_PERCENT_ALT_PERCENT,
          },
        ],
        format: 'percent',
      },
    },
    timeframes: [
      { transform: 'most-recent-year', value: 10 },
      { transform: 'most-recent-year', value: 10, transformValue: -1 },
    ],
    hideTimeframeEndOffset: true,
    hideTable: true,
    periods: [{ id: 1, name: 'Annual', periodicity: 'annual' }],
    options: [],
    // tableOptions: { valueType: 'percentage', includeRawValues: true },
    gridName: { useTitle: true },
    gridControls: ['lob'],
    grid: [
      // LOB: Hospital
      { defaultLob: 224 },
      // LOB: Other HC Professionals
      { defaultLob: 225 },
      // LOB: Other HC Facilities
      { defaultLob: 226 },
    ],
  },
}

export default BENCHMARK_STAT_MPL_CONFIGS
