import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
  UpdateGrossMetric,
} from '../../analysis/model/compare-metrics.model'
import { Study } from '../../core/model/study.model'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { MetricBuilderDialogService } from '../metric-builder-dialog/metric-builder-dialog.service'
import { CreditPortfolio } from 'src/app/credit/model/credit-portfolio.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-settings',
  styleUrls: ['./metric-settings.component.scss'],
  templateUrl: './metric-settings.component.html',
})
export class MetricSettingsComponent {
  @Input() name: 'Metric'
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() studySelected: string | null
  @Input() totalWeight: number
  @Input() lossFilters: LossFilter[]

  @Input() creditPortfolio: CreditPortfolio
  @Input() isCredit: boolean = false

  @Output() programSelected = new EventEmitter<string>()
  @Output() saveClick = new EventEmitter()
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() collapseToggle = new EventEmitter<string>()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  @Output() enableAllClick = new EventEmitter()

  get hasClient(): boolean {
    return this.clientID != null
  }

  get hasStudies(): boolean {
    return this.studies && this.studies.length > 0
  }

  get hasPrograms(): boolean {
    return this.studyID !== '' && this.studyID !== null
  }

  get showAddButton(): boolean {
    return this.hasClient && this.hasStudies
  }

  get addMenuLabel(): string {
    return 'Select Program'
  }

  get showEmptySelectedLabel(): boolean {
    return this.showAddButton && !this.hasPrograms
  }

  get showMetrics(): boolean {
    return (
      this.hasClient &&
      this.hasStudies &&
      this.hasPrograms &&
      this.isStudyOnList
    )
  }

  get isStudyOnList(): boolean {
    return this.studies.find(s => s.id === this.studyID) != null
  }

  constructor(private metricBuilderDialog: MetricBuilderDialogService) {}

  onProgramClick($event: MouseEvent | TouchEvent, s: Study): void {
    $event.preventDefault()
    this.studySelected = s.name
    this.programSelected.emit(s.id)
  }

  isExpanded(category: string): boolean {
    return (
      (this.expandedMetricCategories &&
        this.expandedMetricCategories[category]) ||
      false
    )
  }

  onUpdateGrossMetricElement(metric: UpdateGrossMetric): void {
    let grossMetric: CompareMetricSetting[] | undefined
    const grossCat = this.metricCategories.filter(
      a => a.category === 'Gross Metrics'
    )[0]
    // For all gross metrics
    grossMetric = grossCat.metrics.find(
      b => b[0].metricSettingID === metric.grossMetricSettingID
    )

    if (typeof grossMetric !== 'undefined') {
      this.updateMetricElement.emit({
        show: metric.show,
        weight: grossMetric[0].weight,
        year: metric.year ? metric.year : grossMetric[0].year,
        portfolioType: metric.portfolioType
          ? metric.portfolioType
          : grossMetric[0].portfolioType,
        vartvar: metric.vartvar ? metric.vartvar : grossMetric[0].vartvar,
        aggregationMethodType: metric.aggregationMethodType
          ? metric.aggregationMethodType
          : grossMetric[0].aggregationMethodType,
        perspective: metric.perspective
          ? metric.perspective
          : grossMetric[0].perspective,
        lossFilter: metric.lossFilter
          ? metric.lossFilter
          : grossMetric[0].lossFilter,
        label: metric.newLabel === '' ? grossMetric[0].label : metric.newLabel,
        metrics: grossMetric[0],
        ragOrder: metric.ragOrder ? metric.ragOrder : grossMetric[0].ragOrder,
        valueType: metric.valueType
          ? metric.valueType
          : grossMetric[0].valueType,
        credit_scenario: metric.credit_scenario ?? grossMetric[0].credit_scenario,
        category: grossMetric[0].category!
      })
    }
  }

  onMetricBuilderClick(): void {
    this.metricBuilderDialog
      .open(this.metricCategories)
      .afterClosed()
      .subscribe(newMetric => {
        if (newMetric) {
          // Create new custom metric setting
          const newCustomMetric: CompareMetricSetting = {
            label: newMetric.label,
            category: 'Custom Metrics',
            valueType: newMetric.valueType,
            ragOrder: newMetric.ragOrder,
            path: `customMetrics/${newMetric.label.replace(/\s+/g, '')}`,
            saveID: 0,
            metricSettingID: 0,
            ordinal: 0,
            show: true,
            weight: 0,
            dirty: true,
            formula: newMetric.formula,
          }
          // Emit event - add metric to state
          this.addCustomMetric.emit(newCustomMetric)
        }
      })
  }
}
