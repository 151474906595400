import { ParamMap } from '@angular/router'
import { PortfolioSetAndStudyIDs } from '../analysis/model/portfolio-set.model'
import { TierPath } from './tier.model'
import { isPortfolioSetAndStudyIDs } from '../analysis/model/portfolio-set.util'

function _createTierPath(
  client?: string | null,
  year?: string | null,
  program?: string | null,
  structure?: string | null
): TierPath {
  return {
    client: client != null ? client : null,
    year: year != null ? year : null,
    program: program != null ? program : null,
    structure: structure != null ? structure : null,
  }
}

function _createTierPathFromPortfolioSetAndStudyIDs(
  portfolioIDs: PortfolioSetAndStudyIDs
): TierPath {
  const client = portfolioIDs.clientID
  const year = portfolioIDs.yearID || null
  const program = year ? portfolioIDs.studyID : null
  const structure = portfolioIDs.structureID || null
  return { client, year, program, structure }
}

export function createTierPath(
  pathOrPortfolioIDs?:
    | TierPath
    | PortfolioSetAndStudyIDs
    | ParamMap
    | null
    | string
): TierPath
export function createTierPath(
  client?: string | null,
  year?: string | null,
  program?: string | null,
  structure?: string | null
): TierPath
export function createTierPath(
  multi?:
    | string
    | null
    | undefined
    | PortfolioSetAndStudyIDs
    | TierPath
    | ParamMap,
  year?: string | null,
  program?: string | null,
  structure?: string | null
): TierPath {
  if (multi == null || typeof multi === 'string') {
    // @ts-ignore
    return _createTierPath(multi, year, program, structure)
  }
  if (isTierPath(multi)) {
    return multi
  }
  if (isPortfolioSetAndStudyIDs(multi)) {
    return _createTierPathFromPortfolioSetAndStudyIDs(multi)
  }
  return _createTierPath(
    multi.get('clientID'),
    multi.get('yearID'),
    multi.get('programID'),
    multi.get('structureID')
  )
}

export function isTierPathEmpty(path: TierPath): boolean {
  return path.client == null && path.year == null && path.program == null
}

export function areTierPathsEqual(
  a: TierPath | null | undefined,
  b: TierPath | null | undefined,
  contextPath: string[]
): boolean {
  if (!a && !b) {
    return true
  }
  if (!a || !b) {
    return false
  }

  if (contextPath.includes('explore')) {
    return (
      a.client === b.client &&
      a.year === b.year &&
      a.program === b.program &&
      a.structure === b.structure
    )
  } else {
    return a.client === b.client && a.year === b.year && a.program === b.program
  }
}

export function isTierPath(path: any): path is TierPath {
  return (
    path != null &&
    (path as TierPath).client !== undefined &&
    (path as TierPath).year !== undefined &&
    (path as TierPath).program !== undefined &&
    (path as TierPath).structure !== undefined
  )
}

export function buildTierRouteCommands(
  pathOrPortfolioIDs: TierPath | PortfolioSetAndStudyIDs,
  ...contextPath: string[]
): string[] {
  const path = createTierPath(pathOrPortfolioIDs)
  const cmds = ['/clients']
  if (path.client) {
    cmds.push(path.client)
    if (path.year) {
      cmds.push('years', path.year)
      if (path.program) {
        cmds.push('programs', path.program)
        if (contextPath.includes('explore') && path.structure) {
          cmds.push('structure', path.structure)
        }
      }
    }
  }
  cmds.push(...contextPath)
  return cmds
}


export function createImageFromBlob(image: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    if (image){
      reader.readAsDataURL(image)
    }
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}
