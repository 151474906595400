<app-compare-towers
  [entities]="entities$ | async"
  [structureGroupIDs]="structureGroupIDs$ | async"
  [structuresByID]="structuresByID$ | async"
  [structureGroupsByID]="structureGroupsByID$ | async"
  [client]="client$ | async"
  [towerSizePercentage]="towerSizePercentage$ | async"
  [windowSize]="size$ | async"
  [precision]="precision"
  [expandedMetricCategories]="expandedMetricCategories$ | async"
  [hiddenMetricRanks]="hiddenMetricRanks$ | async"
  [expandedChangeMetrics]="expandedChangeMetrics$ | async"
  [grossSelected]="grossSelected$ | async"
  [grossMetricsInput]="grossMetricsInput$ | async"
  (collapseToggle)="onCollapseToggle($event)"
  (ragToggle)="onRAGToggle($event)"
  (changeToggle)="onChangeToggle($event)"
  (programRemove)="programRemove.emit($event)"
  (updateGrossSelection)="onUpdateGrossSelection($event)"
  (updateEntityIndex)="onUpdateEntityIndex($event)"
  (scenarioOrOptimizationSelectChange)="
        onScenarioOrOptimizationSelectChange($event)
      "
  (groupScenarioOrOptimizationSelectChange)="
        onGroupScenarioOrOptimizationSelectChange($event)
      "
></app-compare-towers>
