import { Pipe, PipeTransform } from '@angular/core'
import { formatDistance } from 'date-fns'

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  /* Return the distance between the given dates in words.  */
  transform(date: string, prefix?: string): string {
    if (prefix) {
      prefix = prefix + ' '
    } else {
      prefix = ''
    }
    return `${prefix}${formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    })}`
  }
}
