import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { sortArrayByKeys } from 'src/app/pricingcurve/pricing-curve.utils'
import { PricingCurveExcludeLayerDialogPayload } from '../../dialog/pricing-curve-dialog.model'
import { PricingCurveData, PricingCurveTypes } from 'src/app/pricingcurve/model/pricing-curve.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-exclude-layer-dialog',
  styleUrls: ['./pricing-curve-exclude-layer-dialog.component.scss'],
  templateUrl: './pricing-curve-exclude-layer-dialog.component.html',
})
export class PricingCurveExcludeLayerDialogComponent {
  datum: (string | number)[]
  headerText: string
  pricingCurve: PricingCurveData

  hasLine: boolean
  dataNameTaken: boolean
  saveCurve = false
  newCurveLabel = ''
  techFactorsDialogTooltip =
    'Open the tech factors form.\n' +
    'If not selected, the curve will have all non-fitted factors carried over.'
  saveCurveTooltip = 'Save curve to database after refit'

  columnHeaders = [
    'Dataset Name',
    'EL %',
    'TROL %',
    'Client Name',
    'Layer Name',
    'Layer Desc',
  ]

  columnOrder = ['label', 'el', 'trol', 'clientName', 'layerName', 'layerDesc']

  constructor(
    public matDialogRef: MatDialogRef<PricingCurveExcludeLayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PricingCurveExcludeLayerDialogPayload
  ) {
    const { id, datasetId, isLine, ...datum } = data.datum

    // Sort the datum details in the same order as the columnHeaders
    this.datum = sortArrayByKeys(Object.entries(datum), this.columnOrder).map(
      item => item[1]
    )
    this.pricingCurve = data.curve
    this.hasLine = this.pricingCurve.curveType !== PricingCurveTypes.LAYER_SET
    this.headerText = this.hasLine
      ? 'Exclude Layer from Fitted Curve?'
      : 'Exclude Layer from Layer Set?'
      this.newCurveLabel = this.pricingCurve.label
    this.dataNameTaken = this.isDataNameConflicting
  }

  get isDataNameConflicting(): boolean {
    const matchingLabelEntry = this.data.currentDataSetNames.find(
      entry => entry.name === this.newCurveLabel
    )
    // Check to see if the name exists, if it does, check if the id is different
    return !matchingLabelEntry
      ? false
      : matchingLabelEntry.id !== this.pricingCurve.id
  }

  get buttonTooltipText(): string | undefined {
    if (this.isDataNameConflicting) {
      return 'Current label already exists in the database, please change before continuing.'
    }
  }

  destroyDialog(): void {
    this.matDialogRef.close()
  }

  checkIfPercentageField(value: string | number): boolean {
    return typeof value === 'number'
  }

  formatColumnByType(value: string | number | undefined): string {
    if (typeof value === 'number') {
      return value.toString()
    } else {
      return value ?? 'N/A'
    }
  }

  submitDialog(): void {
    if (!this.isDataNameConflicting) {
      this.matDialogRef.close({
        save: this.hasLine ? this.saveCurve : false,
        newLabel:
          this.newCurveLabel !== this.pricingCurve.label
            ? this.newCurveLabel
            : undefined,
      })
    }
  }
}
