import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Layer } from '../analysis/model/layers.model'
import { setSelectedLayer } from '../analysis/store/ceded-layers/layers.actions'
import { addProgramToCompare } from '../analysis/store/compare/compare.actions'
import { addProgramGroup } from '../analysis/store/grouper/program-group/program-group.actions'
import { addProgramToGroup } from '../analysis/store/grouper/program/program.actions'
import { deleteSharedLimit } from '../analysis/store/grouper/shared-limit/grouper-shared-limit.actions'
import { BenchmarkCompany } from '../benchmark/model/benchmark.model'
import { AddBenchmarkControlsPeers } from '../benchmark/store/benchmark-controls.actions'
import { AppState } from './store'
import { setCurrentClient } from './store/broker/broker.actions'
import {
  toggleDarkTheme,
  toggleThemeChrome,
  toggleThemeLayerWireframe,
  toggleThemeLogoColor,
  toggleThemeUserCard,
} from './store/theme/theme.actions'

const BASE_COMPANY = {
  groupCount: 0,
  subGroupCount: 0,
  groupID: '',
  groupName: '',
  level: 1,
  term: '',
} as BenchmarkCompany

/**
 * App programatically interacting with the app through the browser development
 * tools.
 *
 * For instance, in the console, type `appDebug.toggleThemeDark()` to
 * dispatch the action that toggles the theme's dark mode.
 */
@Component({
  selector: 'app-debug',
  template: ``,
})
export class DebugContainerComponent implements OnInit {
  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    const _window = window as any
    _window.appDebug = {
      getStore: () =>
        this.store.subscribe(s => {
          _window.appDebug.store = s
          return s
        }),
      toggleThemeDark: () => this.store.dispatch(toggleDarkTheme()),
      toggleThemeChrome: () => this.store.dispatch(toggleThemeChrome()),
      toggleThemeLayerWireframe: () =>
        this.store.dispatch(toggleThemeLayerWireframe()),
      toggleThemeUserCard: () => this.store.dispatch(toggleThemeUserCard()),
      toggleThemeLogoColor: () => this.store.dispatch(toggleThemeLogoColor()),
      selectLayer: (id: string) =>
        this.store.dispatch(setSelectedLayer({ id })),
      testGroups: (id?: number) => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'group'])
        if (id) {
          this.store.dispatch(addProgramGroup({ id: `${id}` }))
        }
      },
      testGroupPrograms: () => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'group'])
        this.store.dispatch(
          addProgramToGroup({
            program: {
              id: '24',
              studyID: '24',
              label: 'Marine - Option 2',
              programType: 'property_catastrophe',
              grossPortfolioID: '39f953ea-54b1-4e1c-b199-178472ba60ab',
              cededPortfolioID: '74d13f2e-4c5b-4c81-adb9-ad02b9266665',
              netPortfolioID: 'e3fe7bee-1975-40be-93be-b716825a1eb5',
              analysisID: '726a4ad6-a6b0-4f5e-9fb2-4dd9afa1a582',
              imageSrc:
                'https://locktonblobstorage.blob.core.windows.net/thumbnails/726a4ad6-a6b0-4f5e-9fb2-4dd9afa1a582-74d13f2e-4c5b-4c81-adb9-ad02b9266665-e3fe7bee-1975-40be-93be-b716825a1eb5-39f953ea-54b1-4e1c-b199-178472ba60ab.png?sv=2018-03-28&sr=c&sig=lSse9fuQzY83RDqksS5CH7ASCLsbiQii%2B5PTQaimuBg%3D&se=2021-02-12T15%3A46%3A01Z&sp=rcwl',
              checkGroup: false,
              yearID: '',
            },
          })
        )
        this.store.dispatch(
          addProgramToGroup({
            program: {
              id: '277',
              studyID: '24',
              label: 'Marine - Option 2 - Adam 1',
              programType: 'Save As Program',
              grossPortfolioID: '8a0a0b24-7e03-469a-9f7e-a725c743f848',
              cededPortfolioID: 'b8f643f2-fa31-4dff-8c40-6dfeee4089d9',
              netPortfolioID: '5b0acdda-6d92-4ad3-955b-4dac07452003',
              analysisID: '726a4ad6-a6b0-4f5e-9fb2-4dd9afa1a582',
              imageSrc:
                'https://locktonblobstorage.blob.core.windows.net/thumbnails/726a4ad6-a6b0-4f5e-9fb2-4dd9afa1a582-b8f643f2-fa31-4dff-8c40-6dfeee4089d9-5b0acdda-6d92-4ad3-955b-4dac07452003-8a0a0b24-7e03-469a-9f7e-a725c743f848.png?sv=2018-03-28&sr=c&sig=lSse9fuQzY83RDqksS5CH7ASCLsbiQii%2B5PTQaimuBg%3D&se=2021-02-12T15%3A46%3A01Z&sp=rcwl',
              checkGroup: false,
              yearID: '',
            },
          })
        )
      },
      testCompare: () => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'compare'])
      },
      testCompare1: () => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'compare'])
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '3',
              studyID: '3',
              label: 'Current - All',
              programType: 'property_catastrophe',
              grossPortfolioID: '07f3c1bf-4c64-47de-9556-4fc0c41bb54d',
              cededPortfolioID: '381e05b5-88c0-4c41-b135-5a48aca9651d',
              netPortfolioID: '6b0d9481-1ab6-4a61-97ce-4578faef2534',
              analysisID: 'e69607aa-41cc-4fdd-b26c-a531fb29ec02',
              imageSrc: 'assets/cards/ML.png',
              checkCompare: false,
              yearID: '',
            },
          })
        )
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '218',
              studyID: '3',
              label: 'Current - All 2',
              programType: 'Save As Program',
              grossPortfolioID: 'f0c1bcc1-b026-40e9-9fe1-c88b8c626e06',
              cededPortfolioID: 'e9552c81-3760-458d-a180-6c1325efd7f3',
              netPortfolioID: '039e74f8-2a74-4fa5-a950-4dcb748cb921',
              analysisID: 'e69607aa-41cc-4fdd-b26c-a531fb29ec02',
              imageSrc: 'assets/cards/ML.png',
              checkCompare: false,
              yearID: '',
            },
          })
        )
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '4',
              studyID: '6',
              label: 'Current - All',
              programType: 'property_catastrophe',
              grossPortfolioID: '07f3c1bf-4c64-47de-9556-4fc0c41bb54d',
              cededPortfolioID: '381e05b5-88c0-4c41-b135-5a48aca9651d',
              netPortfolioID: '6b0d9481-1ab6-4a61-97ce-4578faef2534',
              analysisID: 'e69607aa-41cc-4fdd-b26c-a531fb29ec02',
              imageSrc: 'assets/cards/ML.png',
              checkCompare: false,
              yearID: '',
            },
          })
        )
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '6',
              studyID: '5',
              label: 'Current - Property',
              programType: 'property_catastrophe',
              grossPortfolioID: 'c18624d5-4547-4d7f-8c1f-3e29f45d0e16',
              cededPortfolioID: '9d5d1822-5b8a-4229-965c-ae5009d28af7',
              netPortfolioID: '4b4e607d-82ba-40f0-8c31-2bddbed23253',
              analysisID: 'e69607aa-41cc-4fdd-b26c-a531fb29ec02',
              imageSrc: 'assets/cards/ML.png',
              checkCompare: false,
              yearID: '',
            },
          })
        )
      },
      testCompare2: () => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'compare'])
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '4',
              studyID: '6',
              label: 'Current - All',
              programType: 'property_catastrophe',
              grossPortfolioID: '07f3c1bf-4c64-47de-9556-4fc0c41bb54d',
              cededPortfolioID: '381e05b5-88c0-4c41-b135-5a48aca9651d',
              netPortfolioID: '6b0d9481-1ab6-4a61-97ce-4578faef2534',
              analysisID: 'e69607aa-41cc-4fdd-b26c-a531fb29ec02',
              imageSrc: 'assets/cards/ML.png',
              checkCompare: false,
              yearID: '',
            },
          })
        )
      },

      testDeleteSharedLimit: (layer: Layer) => {
        this.store.dispatch(deleteSharedLimit({ sharedLimitLayer: layer }))
      },

      testCompare3: () => {
        this.store.dispatch(setCurrentClient({ id: '3' }))
        this.router.navigate(['/analysis', 'compare'])
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '251',
              studyID: '232',
              label: 'Prop Cat Quota Share Layer',
              programType: 'property_catastrophe',
              grossPortfolioID: '5ccb3e1e-4bb9-4801-a957-101e03634d1d',
              cededPortfolioID: 'ad6e3de4-5d71-4add-9c97-f90441e404d8',
              netPortfolioID: '443d5341-2008-4ed7-b0dd-4f0e7a19e1c9',
              analysisID: '4a574315-3fd1-4d36-8366-6843c62e0bfe',
              imageSrc:
                'https://locktonblobstorage.blob.core.windows.net/thumbnails/4a574315-3fd1-4d36-8366-6843c62e0bfe-ad6e3de4-5d71-4add-9c97-f90441e404d8-443d5341-2008-4ed7-b0dd-4f0e7a19e1c9-5ccb3e1e-4bb9-4801-a957-101e03634d1d.png?sv=2018-03-28&sr=c&sig=lSse9fuQzY83RDqksS5CH7ASCLsbiQii%2B5PTQaimuBg%3D&se=2021-02-12T15%3A46%3A01Z&sp=rcwl',
              checkCompare: false,
              yearID: '',
            },
          })
        )
        this.store.dispatch(
          addProgramToCompare({
            program: {
              id: '250',
              studyID: '231',
              label: 'Prop Cat Net Agg Layer',
              programType: 'property_catastrophe',
              grossPortfolioID: 'd3df73c0-16ce-47db-a6b9-edcbf936fefe',
              cededPortfolioID: 'c02589fc-3b61-4506-863b-710715cb7a92',
              netPortfolioID: 'd37ce248-eab4-4673-930f-fa68b23cf66a',
              analysisID: '4a574315-3fd1-4d36-8366-6843c62e0bfe',
              imageSrc:
                'https://locktonblobstorage.blob.core.windows.net/thumbnails/4a574315-3fd1-4d36-8366-6843c62e0bfe-c02589fc-3b61-4506-863b-710715cb7a92-d37ce248-eab4-4673-930f-fa68b23cf66a-d3df73c0-16ce-47db-a6b9-edcbf936fefe.png?sv=2018-03-28&sr=c&sig=lSse9fuQzY83RDqksS5CH7ASCLsbiQii%2B5PTQaimuBg%3D&se=2021-02-12T15%3A46%3A01Z&sp=rcwl',
              checkCompare: false,
              yearID: '',
            },
          })
        )
      },

      testBenchmarkAddPeers: () => {
        this.store.dispatch(
          AddBenchmarkControlsPeers([
            {
              ...BASE_COMPANY,
              id: 'GP13012',
              name: 'Coverys (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP13591',
              name: 'Physicians Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103330',
              name: 'AIG (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP12932',
              name: 'LAMMICO (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'C4756',
              name: 'AEGIS Healthcare Risk Retention Group, Inc.',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4188178',
              name: 'Alfa Mutual Group (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103247',
              name: 'Allstate Corp (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4224361',
              name: 'Allegany Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103410',
              name: 'Alleghany (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP13045',
              name: 'A.I.M. Mutual Insurance Companies (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4023135',
              name: 'AF Group (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4023192',
              name: 'AMERCO (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4174043',
              name: 'Allianz (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103462',
              name: 'Berkshire Hathaway Inc. (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4343766',
              name: 'GeoVera Holdings (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP11863',
              name: 'California Casualty (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP11847',
              name: 'Buckeye Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4170827',
              name: 'Celina Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP12244',
              name: 'Donegal (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP12396',
              name: 'Federated Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103619',
              name: 'Erie Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103343',
              name: 'CNA (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP11803',
              name: 'Bar Plan (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP103412',
              name: 'First American (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP4310994',
              name: 'Farmers Insurance (SNL P&C Group)',
              mode: 'us',
            },
            {
              ...BASE_COMPANY,
              id: 'GP28041197',
              name: 'Harford Mutual (SNL P&C Group)',
              mode: 'us',
            },
          ])
        )
      },
    }
  }
}
