<div #swiper class="swiper-container" [swiper]="configGroup">
  <div class="swiper-wrapper">
    <div
      class="swiper-slide"
      *ngFor="let p of getPrograms(index); let subI = index"
      [ngStyle]="getSlideStyle()"
      [style.height.px]="height"
    >
      <app-compare-thumbnail-container
        [thumbnailName]="getGroupImageSrc(index, subI)"
        [height]="height"
      >
      </app-compare-thumbnail-container>
      <div class="number">{{ subI + 1 }} /  {{ getPrograms(index).length }}</div>
    </div>
  </div>

  <!-- If we need navigation buttons -->
  <div class="swiper-pagination"></div>
  <div class="swiper-button-prev" (click)="onPrev()"></div>
  <div class="swiper-button-next" (click)="onNext()"></div>
</div>
