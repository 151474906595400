export enum StratificationAxisField {
  FICO = "FICO",
  LTV = "LTV",
  DTI = "DTI"
}

export enum StratificationChartType {
  Occupancy='occupancy',
  PropertyType='propertyType',
  LoanPurpose='purpose',
  FirstTimeHomeBuyer='firstTimeHomeBuyer'
}

export interface StratificationResults {
  dataTable: number[][]
  dataTableTotal: number
  xAxis: string[]
  yAxis: string[]
  pieCharts: {
    occupancy : Record<string, number>,
    propertyType : Record<string, number>,
    loanPurpose : Record<string, number>,
    firstTimeHomeBuyer : Record<string, number>
  }
}

export interface Stratification {
  rowType?: StratificationAxisField
  colType?: StratificationAxisField
  selectedChartType: StratificationChartType
  results?: StratificationResults | null
  statesSelected?: string[]
}

export enum LoadingStates{
  empty,
  loading,
  loaded
}

export enum StratificationMapType{
  CreditEventUPB="creditEventUPB",
  RiskInForce="riskInForce",
  LossRatio="lossRatio",
}

export const stratificationSelectableChartType : readonly {value: StratificationChartType, viewValue: string}[] = [
  {value: StratificationChartType.Occupancy, viewValue: 'Occupancy'},
  {value: StratificationChartType.PropertyType, viewValue: 'Property Type'},
  {value: StratificationChartType.LoanPurpose, viewValue: 'Loan Purpose'},
  {value: StratificationChartType.FirstTimeHomeBuyer, viewValue: 'First Time Home Buyer'},
] as const

export const stratificationSelectableFields: readonly {value: StratificationAxisField, viewValue: string}[] = [
  {value: StratificationAxisField.FICO, viewValue: 'FICO Score'},
  {value: StratificationAxisField.LTV, viewValue: 'LTV'},
  {value: StratificationAxisField.DTI, viewValue: 'DTI'}
] as const

export const stratificationSelectableMapTypes: readonly {value: StratificationMapType, viewValue: string}[] = [
  {value: StratificationMapType.CreditEventUPB, viewValue: 'Credit Event UPB'},
  {value: StratificationMapType.RiskInForce, viewValue: 'Risk In Force'},
]

export const monitoringSelectableMapTypes: readonly {value: StratificationMapType, viewValue: string}[] = [
  {value: StratificationMapType.LossRatio, viewValue: 'Loss Ratio'},
  {value: StratificationMapType.LossRatio, viewValue: 'Loss'},
]

