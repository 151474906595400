import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { AnimatedScenariosResults } from '../../model/credit-animated-scenarios.model'
import { createReducer, on, Action } from '@ngrx/store'
import { fetchAnimatedScenariosDataSuccess } from './credit-animated-scenarios.actions'


export type ScenarioEventResultState = EntityState<AnimatedScenariosResults>

export const adapter = createEntityAdapter<AnimatedScenariosResults>()

export const initialState = adapter.getInitialState()

const scenarioEventResultReduer = createReducer(
  initialState,

  on(fetchAnimatedScenariosDataSuccess, (state, { data }) =>
    adapter.setAll(data, state)
  )
)

export function reducer(
  state: ScenarioEventResultState | undefined,
  action: Action
) {
  return scenarioEventResultReduer(state, action)
}
