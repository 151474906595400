import { Injectable } from '@angular/core'
import { AnalyzreService } from '../analyzere/analyzre.service'
import { Layer } from '../../analysis/model/layers.model'
import { Program } from '../../core/model/program.model'
import { SharedLimitRef } from './model'
import { LogicalPortfolioLayer } from '../analyzere/analyzere.model'
import { map } from 'rxjs/operators'
import { ApiResponse, MaybeData, MaybeError } from '../model/api.model'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private analyzereService: AnalyzreService) {}

  getSharedLimitRelationships(
    cededLayers: Layer[],
    structures: Program[]
  ): ApiResponse<SharedLimitRef[]> {
    const sharedHiddenLayers = cededLayers.filter(
      layer =>
        layer.meta_data.sage_layer_type === 'shared_limits' &&
        layer.meta_data.sage_layer_subtype === 'virtual'
    )
    if (sharedHiddenLayers.length > 0) {
      const sharedLimitRefs: SharedLimitRef[] = []
      sharedHiddenLayers.forEach(sharedHiddenLayer => {
        sharedLimitRefs.push(
          this.fromSharedLimitHiddenLayer(
            sharedHiddenLayer,
            structures,
            cededLayers
          )
        )
      })
      const nestedLayers: string[] = sharedLimitRefs
        .flatMap(sl => sl.layersWithStructure)
        .map(l => l.layerID)
      return this.analyzereService
        .fetchLayers<LogicalPortfolioLayer>(nestedLayers)
        .pipe(
          map(response => {
            if (response.error) {
              return { error: response.error } as MaybeError &
                MaybeData<{ record: Record<string, string> }>
            }
            const record: Record<string, string> = {}
            nestedLayers.forEach(n => {
              // tslint:disable-next-line: no-non-null-assertion
              record[n] = response.data!.find(d => d.id === n)!.meta_data
                .backAllocatedForID as string
            })
            return { data: { record } } as MaybeError &
              MaybeData<{ record: Record<string, string> }>
          }),
          map(response => {
            if (response.error) {
              return { error: response.error }
            } else {
              sharedLimitRefs.forEach(sl => {
                sl.layersWithStructure.forEach(l => {
                  // tslint:disable-next-line: no-non-null-assertion
                  l.layerID = response.data!.record[l.layerID]
                })
              })
              return { data: sharedLimitRefs }
            }
          })
        )
    } else {
      return of({ data: [] })
    }
  }

  fromSharedLimitHiddenLayer(
    sharedLimitHiddenLayer: Layer,
    structures: Program[],
    cededLayers: Layer[]
  ): SharedLimitRef {
    const record: Record<string, string[]> = JSON.parse(
      sharedLimitHiddenLayer.meta_data
        .nestedLayersCededPortfolioRecord as string
    )
    const sharedLimitRef: SharedLimitRef = {
      sharedLayer: sharedLimitHiddenLayer,
      layersWithStructure: [],
      analysisProfileID: '',
    }
    Object.keys(record).forEach(cededPortfolioID => {
      const layerIDs = record[cededPortfolioID]
      const structure = structures.find(
        s => s.cededPortfolioID === cededPortfolioID
      )
      if (!structure) {
        throw Error(
          `CededPortfolioID ${cededPortfolioID} not found in any Structure`
        )
      }
      sharedLimitRef.analysisProfileID = structure.analysisID
      if (cededLayers.find(l => layerIDs.includes(l.id))) {
        sharedLimitRef.layersWithStructure.push(
          ...layerIDs.map(id => ({
            layerID: id,
            fromSaveAsStructure: true,
            structureID: structure.id,
            label: structure.label,
            description: structure.description,
          }))
        )
      } else {
        sharedLimitRef.layersWithStructure.push(
          ...layerIDs.map(id => ({
            layerID: id,
            fromSaveAsStructure: false,
            structureID: structure.id,
            label: structure.label,
            description: structure.description,
          }))
        )
      }
    })
    return sharedLimitRef
  }
}
