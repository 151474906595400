import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
} from '../../analysis/model/compare-metrics.model'
import {
  selectMetricTableCalculatedWeight,
  selectMetricTableExpandedCategories,
  selectMetricTableSelectedStudyName,
  selectMetricTableSettingsCategories,
  selectMetricTableSettingsSaving,
  selectMetricTableSettingsLoading,
  selectMetricTableSettingsStudyID,
  selectMetricTableSettingsEntities,
} from '../../analysis/store/analysis.selectors'
import {
  addNewCustomMetric,
  enableAllMetrics,
  setExpandedMetricTableCategory,
  updateMetricTableSettings,
  upsertMetricTableSettings,
} from '../../analysis/store/metrics/metrics-cart/metrics-cart.actions'
import { Client } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import { AppState } from '../../core/store'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import { selectClients } from '../../core/store/clients.selectors'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { selectCurrentLossFilters } from '../../core/store/broker/broker.selectors'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { AccountOpportunity } from '../../api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-container',
  templateUrl: './metrics.container.html',
})
export class MetricsContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  clientID$: Observable<string | null>
  studyId$: Observable<string | null>
  studies$: Observable<readonly Study[]>
  studySelected$: Observable<string | null>
  saving$: Observable<boolean>
  loading$: Observable<boolean>
  totalWeight$: Observable<number>

  categories$: Observable<CompareMetricTableCategory[]>
  expandedMetricCategories$: Observable<Record<string, boolean>>

  clients$: Observable<readonly Client[]>
  clientID: string | null
  studiesID: Study[]

  metricDirty$: Observable<boolean>
  currentLossFilters$: Observable<LossFilter[] | undefined>

  selectedProgramID$: Observable<string | null>
  programs$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.clientID$ = this.store.pipe(select(fromBroker.selectCurrentClientID))
    this.studyId$ = this.store.pipe(select(selectMetricTableSettingsStudyID))
    this.studies$ = this.store.pipe(
      select(fromBroker.selectCurrentClientStudies)
    )
    this.expandedMetricCategories$ = this.store.pipe(
      select(selectMetricTableExpandedCategories)
    )

    this.saving$ = this.store.pipe(select(selectMetricTableSettingsSaving))
    this.loading$ = this.store.pipe(select(selectMetricTableSettingsLoading))

    this.metricDirty$ = this.store.pipe(
      select(selectMetricTableSettingsEntities),
      map(metrics => {
        return metrics.filter(m => m.dirty).length > 0
      })
    )

    this.studySelected$ = this.store.pipe(
      select(selectMetricTableSelectedStudyName)
    )

    this.categories$ = this.store.pipe(
      select(selectMetricTableSettingsCategories)
    )

    this.totalWeight$ = this.store.pipe(
      select(selectMetricTableCalculatedWeight)
    )

    this.clients$ = this.store.pipe(select(selectClients))

    this.currentLossFilters$ = this.store.pipe(select(selectCurrentLossFilters))

    this.selectedProgramID$ = this.store.pipe(
      select(fromBroker.selectCurrentStudyID)
    )
    this.programs$ = this.store.pipe(
      select(fromBroker.selectCurrentYearStudies)
    )
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onBackClick(): boolean {
    this.router.navigate(['/home']).then(r => r)
    return false
  }

  onCollapseToggle(category: string): void {
    this.store.dispatch(setExpandedMetricTableCategory({ category }))
  }

  onUpdateMetricElement(data: UpdateCompareMetric): void {
    this.store.dispatch(
      updateMetricTableSettings({
        show: data.show,
        weight: data.weight,
        year: data.year,
        portfolioType: data.portfolioType,
        vartvar: data.vartvar,
        aggregationMethodType: data.aggregationMethodType,
        perspective: data.perspective,
        lossFilter: data.lossFilter,
        label: data.label,
        metrics: data.metrics,
        spPremiumValue: data.spPremiumValue,
        spReserveValue: data.spReserveValue,
        spDivesificationValue: data.spDivesificationValue,
        spCatValue: data.spCatValue,
        deleted: data.deleted,
        formula: data.formula,
        valueType: data.valueType,
        ragOrder: data.ragOrder,
        category: data.category
      })
    )
  }

  onSaveClick(): void {
    this.store.dispatch(upsertMetricTableSettings({}))
  }

  onAddCustomMetric(newCustomMetric: CompareMetricSetting): void {
    this.store.dispatch(addNewCustomMetric({ newCustomMetric }))
  }

  onEnableAllClick(): void {
    this.store.dispatch(enableAllMetrics())
  }
}
