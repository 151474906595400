import * as d3 from 'd3'
import { select, Selection } from 'd3-selection'
import addLegend from './utils/chart-legend'
import addChartLabels from './utils/chart-labels'
import {
  formatCurrencyString,
  formatLongNumberLabel,
} from './utils/charts.util'
import { QuoteChartGridMetric } from '../quote/management-information/model/quote-charts.model'

export interface ScatterBubbleChartOptions {
  chartLabel?: string
  xMetricValues: string[]
  xLabel?: string
  yLabel?: string
  metricPrimary?: string
  metricPrimaryType?: QuoteChartGridMetric
  metricSecondary?: string
  metricTertiary?: string
  sizeCol?: string
  splitValues?: string[]
  comparisonValue?: number
  min: boolean
  percent: boolean
  lightChartMode?: boolean
}

export interface ScatterBubbleDatum {
  x: number
  y: number[]
  size?: number
  sizeCol?: string
}

export class ScatterBubbleChart {
  private container: HTMLElement
  private data: ScatterBubbleDatum[]
  private margin = { top: 50, right: 5, bottom: 50, left: 90 }
  private options: ScatterBubbleChartOptions

  constructor(container: HTMLElement, options: ScatterBubbleChartOptions) {
    this.container = container
    this.options = options
    this.data = []
    select(container).selectAll('*').remove()
  }

  draw(data: ScatterBubbleDatum[]): void {
    this.data = data
    this.setUpScatterBubbleChart()
  }

  // Color scheme for scatter/bubble
  graphingColorPalette = ['#00aeef', '#f68a33', '#01c96d']

  color = d3.scaleOrdinal(this.graphingColorPalette)

  private setUpScatterBubbleChart(): void {
    const lightChartMode = this.options.lightChartMode;
    const data = this.data
    const margin = this.options.min
      ? this.margin
      : { top: 75, right: 25, bottom: 125, left: 100 }
    const width = this.container.clientWidth - (margin.left * 2)
    const height = this.container.clientHeight - (margin.bottom * 2)

    const metrics: string[] = [
      this.options.metricPrimary ? this.options.metricPrimary : '',
      this.options.metricSecondary ? this.options.metricSecondary : '',
      this.options.metricTertiary ? this.options.metricTertiary : '',
    ].filter(metric => metric !== '')

    const sizeScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.size || 0) || 1])
      .range([this.options.min ? 5 : 10, this.options.min ? 25 : 50])

    const xScale = d3.scaleLinear().domain([-1, data.length]).range([0, width])

    const maxY = d3.max(data, d => Math.max(...d.y)) ?? 0
    const minY = d3.min(data, d => Math.min(...d.y)) ?? 0
    const yDomainMin = minY >= 0 ? 0 - maxY * 0.1 : minY - Math.abs(minY * 0.25)

    const yScale = d3
      .scaleLinear()
      .domain([yDomainMin, maxY + maxY * 0.1])
      .range([height, 0])

    const tooltip:
      | Selection<HTMLDivElement, unknown, null, undefined>
      | undefined = select(this.container)
      .append('div')
      .style('opacity', 0)
      .style('transition', 'all 1s')
      .attr('class', 'custom-tooltip')

    const container = d3.select(this.container)
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow', 'display')
      .style('position', 'relative')
      .style('background', 'transparent')

    const chart = container
      .append('svg')
      .attr('width', width )
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .style('background', 'transparent')

    chart
      .selectAll('.circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'bubble')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y[0]))
      .attr('r', d => (d.size ? sizeScale(d.size) : this.options.min ? 5 : 10))
      .style('fill', this.color('0'))

    if (!!this.options.comparisonValue) {
      const comparisonLineY = yScale(this.options.comparisonValue)
      chart.append('line')
        .attr('x1', 0)
        .attr('y1', comparisonLineY)
        .attr('x2', width)
        .attr('y2', comparisonLineY)
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
    }

    if (data[0].y.length > 1) {
      chart
        .selectAll('.rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'rect')
        .attr('width', this.options.min ? 10 : 20)
        .attr('height', this.options.min ? 4 : 8)
        .attr('x', d => xScale(d.x) - (this.options.min ? 5 : 10))
        .attr('y', d => yScale(d.y[1]))
        .style('fill', this.color('1'))
    }
    if (data[0].y.length > 2) {
      const triangle = d3.symbol().type(d3.symbolTriangle)
      chart
        .selectAll('.triangle')
        .data(data)
        .enter()
        .append('path')
        .attr('class', 'triangle')
        .attr('d', triangle)
        .attr('width', this.options.min ? 10 : 20)
        .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y[2])})`)
        .style('fill', this.color('2'))
    }

    chart
      .on(
        'mouseover', (event: any) => {
          tooltip?.style('opacity', 1)
          select(event.currentTarget).style('opacity', 1)
        }
      )
      .on(
        'mousemove', (event: any) => {
          const [x, y] = d3.pointer(event)
          const dIndex = Math.floor(x / (width / data.length - 1))
          const d = data[dIndex]
          if (!data[dIndex]) {
            return
          }
          if (tooltip) {
            tooltip
              .html(
                `
                  <div>
                    <div>
                      <strong>${
                        this.options.xMetricValues
                          ? this.options.xMetricValues[dIndex]
                          : ''
                      }</strong>
                    </div>
                   ${this.getMetricTooltip(d.y)}
                   ${
                     this.options.sizeCol
                       ? `
                        <div>
                          ${this.options.sizeCol}: ${
                           d.size ? this.options.sizeCol === 'Cession Percentage' ? `${(d.size * 100).toFixed(2)}%` : formatCurrencyString(d.size) : '0'
                         }
                        </div>
                      `
                       : ``
                   }
                  </div>
                `
              )
              .style('left', `${x + 15}px`)
              .style('top', `${height / 8 + y}px`)
          }
        }
      )
      .on('mouseleave', () => {
        tooltip?.style('opacity', 0)
      })

    const xAxis = chart
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(data.map(d => d.x))
          .tickFormat(d =>
            this.options.min || !this.options.xMetricValues
              ? ''
              : this.options.xMetricValues[Number(d)].slice(0, 20)
          )
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-30)')

    xAxis
      .selectAll('line')
      .style('stroke', lightChartMode ? 'black' : 'white')

    const yAxis = chart.append('g').call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickFormat((n: number | { valueOf(): number }) => {
            if (typeof n === 'number') {
              return formatLongNumberLabel(n)
            } else {
              return formatLongNumberLabel(n.valueOf())
            }
          })
          .tickSizeInner(-width)
      )
      .selectAll('line')
      .style('stroke', lightChartMode ? 'black' : 'white')

    yAxis.selectAll('.tick line').style('opacity', '0.3')

    chart.selectAll('text').style('fill', lightChartMode ? 'black' : 'white')

    addChartLabels(
      chart,
      this.options.xLabel ?? '',
      this.options.yLabel ?? '',
      this.options.chartLabel ?? '',
      margin,
      width,
      height,
      this.options.min,
      false,
      false,
      lightChartMode
    )
    if (!this.options.min) {
      addLegend(
        chart,
        this.container.clientWidth,
        this.options.splitValues ?? metrics,
        this.color,
        false,
        '',
        false,
        lightChartMode
      )
    }
  }

  private getMetricTooltip(yData: number[]): string {
    const percent = this.options.percent ? '%' : ''
    const isFotQuoteRate = this.options.metricPrimaryType === 'fotVsQuoteRate'
    return `${yData
      .map(
        (val, index) =>
          `
        <div>
          ${
            this.options.splitValues
              ? this.options.splitValues[index]
              : isFotQuoteRate
              ? `The variance of the FOT ${
                  val >= 0 ? 'above' : 'below'
                } a given quote`
              : this.options.yLabel
          }: ${
            this.options.percent ? val.toFixed(2) : formatCurrencyString(val)
          }${percent}
        </div>
      `
      )
      .join('')}`
  }
}
