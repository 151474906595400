import { NgModule } from '@angular/core'
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { ReinsurerRoutingModule } from './reinsurer-routing.module'
import { ReinsurerHeaderComponent } from './header/reinsurer-header.component'
import { ReinsurerRouteContainerComponent } from './reinsurer-route.container'
import { ReinsurerContainerComponent } from './reinsurer-container/reinsurer.container'
import { ReinsurerContentComponent } from './reinsurer-content/reinsurer-content.component'
import { ReinsurerComponent } from './reinsurer-component/reinsurer.component'
import { ReinsurerBannerContainerComponent } from './reinsurer-banner-container/reinsurer-banner.container'
import { ReinsurerBannerComponent } from './reinsurer-banner-component/reinsurer-banner.component'
import { ReinsurerDetailDialogComponent } from './reinsurer-detail-component/reinsurer-detail.component'
import { ReinsurerDetailDialogContainerComponent } from './reinsurer-detail-container/reinsurer-detail.container'
import { ReinsurerListComponent } from './reinsurer-list/reinsurer-list.component'
import { ReinsurerNotesComponent } from './reinsurer-detail-component/reinsurer-notes/reinsurer-notes.component'
import { EffectsModule } from '@ngrx/effects'
import { StudyReinsurersEffects } from './store/study-reinsurers.effects'
import { StoreModule } from '@ngrx/store'
import * as fromReinsurers from './store/study-reinsurers.reducer'
import { CanDeactivateGuard } from '../guards/can-deactivate.guard'
import { ReinsurerSelectorComponent } from './reinsurer-selector/reinsurer-selector.component'
import { ReinsurerExportService } from './export-service/reinsurer-export.service'
import { AgencyDetailDialogContainerComponent } from './agency-detail-container/agency-detail.container'
import { AgencyDetailDialogComponent } from './agency-detail-component/agency-detail.component'
import { FundManagerDetailDialogContainerComponent } from './fund-manager-detail-container/fund-manager-detail.container'
import { FundManagerDetailDialogComponent } from './fund-manager-detail/fund-manager-detail.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { AnalysisModule } from '../analysis/analysis.module'
import { ReinsurerCustomBreakoutDialogComponent } from './reinsurer-custom-breakouts-dialog/reinsurer-custom-breakouts-dialog.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserPreferencesModule } from '../user-preferences/user-preferences.module'
import { UserPreferencesEffects } from '../user-preferences/store/user-preferences.effects'
import { ProgramEventsEffects } from '../program-events/store/program-events.effects'

@NgModule({
  declarations: [
    ReinsurerRouteContainerComponent,
    ReinsurerContainerComponent,
    ReinsurerHeaderComponent,
    ReinsurerContentComponent,
    ReinsurerComponent,
    ReinsurerBannerContainerComponent,
    ReinsurerBannerComponent,
    ReinsurerDetailDialogComponent,
    ReinsurerDetailDialogContainerComponent,
    ReinsurerListComponent,
    ReinsurerNotesComponent,
    ReinsurerSelectorComponent,
    AgencyDetailDialogContainerComponent,
    AgencyDetailDialogComponent,
    FundManagerDetailDialogContainerComponent,
    FundManagerDetailDialogComponent,
    ReinsurerCustomBreakoutDialogComponent,
  ],
  entryComponents: [
    ReinsurerRouteContainerComponent,
    ReinsurerDetailDialogComponent,
    ReinsurerDetailDialogContainerComponent,
    AgencyDetailDialogContainerComponent,
    AgencyDetailDialogComponent,
    FundManagerDetailDialogContainerComponent,
    FundManagerDetailDialogComponent,
    ReinsurerCustomBreakoutDialogComponent,
  ],
  imports: [
    CommonModule,
    ReinsurerRoutingModule,
    SharedModule,
    AnalysisModule,
    ReactiveFormsModule,
    FormsModule,
    TierModule,
    MatAutocompleteModule,
    UserPreferencesModule,
    StoreModule.forFeature(fromReinsurers.FEATURE_KEY, fromReinsurers.reducer),
    EffectsModule.forFeature([
      StudyReinsurersEffects,
      UserPreferencesEffects,
      ProgramEventsEffects,
    ]),
  ],
  providers: [
    CanDeactivateGuard,
    ReinsurerExportService,
    DatePipe,
    CurrencyPipe,
  ],
})
export class ReinsurerModule {}
