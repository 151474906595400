<div class="header"></div>
<ng-container *ngIf="!collapsed">
    <ng-container
            *ngFor="let rowValues of values; let i = index; trackBy: trackByIDs"
    >
        <div
                *ngIf="rowValues[0].show"
                class="row"
                [ngClass]="{
            'single-item-row': rowValues.length === 1,
            'row-show-change':
              isChangeExpanded(rowValues[0]) && rowValues.length === 1
          }"
        >
            <div *ngIf="!hideMetric(rowValues[0])">
                <div class="compare-metric-value-width">
                    <app-compare-metric-value
                            *ngFor="let value of rowValues; trackBy: trackByID"
                            class="value"
                            [metricsLabel]="label"
                            [precision]="value.label === 'CV of Net Losses' ? 2 : precision"
                            [entitiesLength]="entitiesLength"
                            [compareMetricValue]="value"
                            [rankMax]="rankMax"
                            [size]="size"
                            [hiddenMetricRanks]="hiddenMetricRanks"
                    ></app-compare-metric-value>
                    <div
                            *ngIf="isChangeExpanded(rowValues[0])"
                            [ngClass]="{ invisible: structureIndex === 0 }"
                            class="change-from-first"
                    >
                        <ng-container [ngSwitch]="rowValues[0].valueType">
                            <app-action-text
                                    class="small-font"
                                    *ngSwitchCase="'percentage'"
                            >
                                {{
                                computeChangeFromFirst(rowValues[0]) | percent: '1.1-1'
                                }}
                                ({{
                                computeChangeFromFirstPercent(rowValues[0])
                                    | percent: '1.1-1'
                                }})
                            </app-action-text>
                            <app-action-text class="small-font" *ngSwitchDefault>
                                {{
                                computeChangeFromFirst(rowValues[0])
                                    | appShortNumber: rowValues[0].currencyCode
                                }}
                                ({{
                                computeChangeFromFirstPercent(rowValues[0])
                                    | percent: '1.1-1'
                                }})
                            </app-action-text>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
