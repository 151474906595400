import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../core/store'
import { Dictionary } from '@ngrx/entity'
import { Observable } from 'rxjs'
import { Program } from '../../../core/model/program.model'
import {
  selectCurrentStudyPrograms,
  selectPrograms,
} from '../../../core/store/program/program.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import * as fromAnalysisSelectors from '../../store/analysis.selectors'
import * as fromAnalysisActions from '../../store/analysis.actions'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../store/grouper/program-group.model'
import { selectCurrentClientProgramGroups } from '../../../core/store/program-group/program-group.selectors'
import { ProgramEntity } from '../../store/grouper/program/program.reducer'
import {
  DeleteInuranceFromDesign,
  InuranceRelationship,
  InuranceView,
  Terminal,
} from '../../model/inurance.model'
import {
  refreshOnChangedInurance,
  saveGrouperInurance,
  setGrouperInuranceCard,
  setGrouperInuranceCursor,
  swapInuranceFromDesign,
} from '../../store/grouper/inurance/grouper-inurance.actions'
import * as SharedLimitActions from '../../store/grouper/shared-limit/grouper-shared-limit.actions'
import { Layer } from '../../model/layers.model'
import { SharedLimitMode } from '../../store/grouper/shared-limit/grouper-shared-limit.reducer'
import { selectProgramGroupMembers } from '../../../core/store/program-group-member.selectors'
import { setCurrentStructure } from '../../../core/store/broker/broker.actions'
import { setSelectedLayer } from '../../store/ceded-layers/layers.actions'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { Client } from 'src/app/core/model/client.model'
import { resetGrouper } from '../../store/grouper/grouper.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-inurance-container',
  styles: [``],
  template: `
    <app-add-inurance-component
      [currentProgram]="data.currentProgram"
      [currentLayer]="data.currentLayer"
      [isAdd]="data.isAdd"
      [isEdit]="data.isEdit"
      [editLayer]="data.sourceLayer"
      [editStructure]="data.sourceStructure"
      [editGroup]="data.sourceGroup"
      [editRelationship]="data.relationship"
      [fromMultiSection]="data.fromMultiSection"
      [allStructures]="allPrograms$ | async"
      [members]="members$ | async"
      [groups]="groups$ | async"
      [structures]="programs$ | async"
      [sourceLayersByStructure]="sourceLayersByStructure$ | async"
      [swapLayersByStructure]="swapLayersByStructure$ | async"
      [targetLayersByStructure]="targetLayersByStructure$ | async"
      [sourceLayersByStructureIsLoading]="
        sourceLayersByStructureIsLoading$ | async
      "
      [swapLayersByStructureIsLoading]="swapLayersByStructureIsLoading$ | async"
      [targetLayersByStructureIsLoading]="
        targetLayersByStructureIsLoading$ | async
      "
      [cededLayers]="cededLayers$ | async"
      [dirty]="dirty$ | async"
      [error]="error$ | async"
      [programEntities]="programEntities$ | async"
      [saving]="saving$ | async"
      [saved]="saved$ | async"
      [sharedLimitLayers]="sharedLimitLayers$ | async"
      [sharedLimitMode]="sharedLimitMode$ | async"
      [client]="client$ | async"
      (getLayersByStructure)="getLayersByStructure($event)"
      (newInurance)="onNewInurance($event)"
      (addProgram)="onAddProgram($event)"
      (addGroup)="onAddGroup($event)"
      (saveClick)="onSaveClick()"
      (hasSaved)="onSaved($event)"
      (resetGrouper)="resetGrouper()"
      (swapInurance)="onSwapInurance($event)"
      (openEditSharedLimit)="onOpenEditSharedLimit($event)"
      (sharedLimitToggle)="onSharedLimitToggle($event)"
    ></app-add-inurance-component>
  `,
})
export class AddInuranceDialogContainerComponent implements OnInit {
  programs$: Observable<Program[]>
  allPrograms$: Observable<Program[]>
  groups$: Observable<ProgramGroup[]>
  members$: Observable<ProgramGroupMember[]>

  sourceLayersByStructure$: Observable<Dictionary<Layer[]> | null>
  swapLayersByStructure$: Observable<Dictionary<Layer[]> | null>
  targetLayersByStructure$: Observable<Dictionary<Layer[]> | null>
  sourceLayersByStructureIsLoading$: Observable<boolean>
  swapLayersByStructureIsLoading$: Observable<boolean>
  targetLayersByStructureIsLoading$: Observable<boolean>

  cededLayers$: Observable<LayerState[]>
  currentProgram$: Observable<Program | undefined>
  dirty$: Observable<boolean>
  error$: Observable<string | null>
  loading$: Observable<boolean>
  programEntities$: Observable<ProgramEntity[]>
  saving$: Observable<boolean>
  saved$: Observable<InuranceView[]>
  sharedLayers: string[]
  sharedLimitLayers$: Observable<Layer[]>
  sharedLimitMode$: Observable<SharedLimitMode>
  client$: Observable<Client | null>

  constructor(
    public dialogRef: MatDialogRef<AddInuranceDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isAdd: boolean
      isEdit: boolean
      currentProgram: Program | undefined
      currentLayer: Layer | undefined
      sourceLayer: Layer | undefined
      sourceStructure: Program | undefined
      sourceGroup: ProgramGroup | undefined
      relationship: InuranceRelationship
      fromMultiSection?: boolean
    },
    private store: Store<AppState> // private router: Router
  ) {}

  ngOnInit(): void {
    this.groups$ = this.store.pipe(select(selectCurrentClientProgramGroups))
    this.programs$ = this.store.pipe(select(selectCurrentStudyPrograms))
    this.allPrograms$ = this.store.pipe(select(selectPrograms))
    this.programEntities$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGrouperProgramEntities)
    )
    this.members$ = this.store.pipe(select(selectProgramGroupMembers))

    this.sourceLayersByStructure$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSourceLayersByStructure)
    )
    this.swapLayersByStructure$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSwapLayersByStructure)
    )
    this.targetLayersByStructure$ = this.store.pipe(
      select(fromAnalysisSelectors.selectTargetLayersByStructure)
    )
    this.sourceLayersByStructureIsLoading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSourceLayersByStructureLoading)
    )
    this.swapLayersByStructureIsLoading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSwapLayersByStructureLoading)
    )
    this.targetLayersByStructureIsLoading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectTargetLayersByStructureLoading)
    )

    this.cededLayers$ = this.store.pipe(
      select(fromAnalysisSelectors.selectCededLayers)
    )
    this.dirty$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGrouperIsDirty)
    )
    this.error$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGrouperInuranceError)
    )
    this.loading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayersLoading)
    )
    this.saving$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGrouperSaving)
    )
    this.saved$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGrouperInuranceFromDesignSaved)
    )
    this.sharedLimitMode$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSharedLimitMode)
    )
    this.sharedLimitLayers$ = this.store.pipe(
      select(fromAnalysisSelectors.selectSharedLimitLayers)
    )
    this.client$ = this.store.pipe(select(selectCurrentClient))
  }

  getLayersByStructure($event: { structure: Program; type: string }): void {
    this.store.dispatch(
      fromAnalysisActions.fetchLayersByStructure({
        structure: $event.structure,
        inurance: $event.type,
      })
    )
  }

  onNewInurance($event: { view: InuranceView; layers?: Layer[] }): void {
    this.store.dispatch(
      setGrouperInuranceCursor({ cursor: $event.view.symbol as Terminal })
    )
    if ($event.layers) {
      this.store.dispatch(
        setGrouperInuranceCard({ view: $event.view, layers: $event.layers })
      )
    } else {
      this.store.dispatch(setGrouperInuranceCard({ view: $event.view }))
    }
  }

  onAddProgram($event: { structure: Program; layers: Layer[] }): void {
    this.store.dispatch(
      fromAnalysisActions.addProgramForInurance({
        structure: $event.structure,
        layers: $event.layers,
      })
    )
  }

  onAddGroup($event: ProgramGroup): void {
    this.store.dispatch(
      fromAnalysisActions.addGroupForInurance({ group: $event })
    )
  }

  onSwapInurance($event: DeleteInuranceFromDesign): void {
    this.store.dispatch(swapInuranceFromDesign({ toDelete: $event }))
    this.store.dispatch(saveGrouperInurance())
    this.dialogRef.close()
  }

  onSaveClick(): void {
    this.store.dispatch(saveGrouperInurance())
  }

  onSaved($event: { structure: Program; layer: Layer }): void {
    this.refreshToStructure($event.structure, $event.layer)
  }

  onOpenEditSharedLimit($event: {
    layer: Layer
    selectedLayer: string
    selectedProgram: string
  }): void {
    this.store.dispatch(
      SharedLimitActions.openEditSharedLimit({
        layer: $event.layer,
        selectedLayer: $event.selectedLayer,
        selectedProgram: $event.selectedProgram,
      })
    )
  }

  onSharedLimitToggle($event: {
    layerID: string
    entityID: string
    analysisID: string
    netPortfolioID: string
    cededPortfolioID: string
    netPortfolioLayersIDs: string[]
    cededLayers: LayerState[]
  }): void {
    this.store.dispatch(
      SharedLimitActions.toggleSharedLimitLayerSelection({
        layerID: $event.layerID,
        entityID: $event.entityID,
        analysisID: $event.analysisID,
        cededLayers: $event.cededLayers,
        cededPortfolioID: $event.cededPortfolioID,
        netPortfolioLayersIDs: $event.netPortfolioLayersIDs,
        netPortfolioID: $event.netPortfolioID,
      })
    )
  }

  private refreshToStructure(structure: Program, layer: Layer) {
    this.store.dispatch(refreshOnChangedInurance({ structure, layer }))
    this.store.dispatch(setCurrentStructure({ id: structure.id }))
    this.store.dispatch(setSelectedLayer({ id: layer.id }))
  }

  // Reset grouper state since inurance dialog uses this for program ceded layers
  // If it is not reset it will cause issues in Group
  resetGrouper() {
    this.store.dispatch(resetGrouper())
  }
}
