import {
  LayerDetails,
  QuoteWithLayerDetails,
  SharedLimitProps,
  SignatureContract,
} from '../signature.model'
import { createAction, props } from '@ngrx/store'
import { Program } from '../../core/model/program.model'
import { ApplicationError } from '../../error/model/error'
import { Section } from '../../quote/models/quote.model'
import { Reinsurer } from 'src/app/core/model/reinsurer.model'
import {
  RiskRefDetail,
  SalesforceClient,
} from 'src/app/api/model/signature.model'

export const initSignatureData = createAction(
  '[Signature] Init Signature Data',
  props<{ groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' }>()
)

/* For Initial Data Load */
export const fetchReinsurersFromSelectionPage = createAction(
  '[Signature] Fetch Reinsurers From Selection Page'
)
export const fetchAllRiskRefs = createAction(
  '[Signature] Fetch All Saved RiskRefs Under This Program'
)
export const fetchAllRiskRefsSuccess = createAction(
  '[Signature] Fetch All Saved RiskRefs Under This Program - Success',
  props<{ riskRefs: RiskRefDetail[] }>()
)
export const fetchAllRiskRefsFailure = createAction(
  '[Signature] Fetch All Saved RiskRefs Under This Program - Failure',
  props<{ error: ApplicationError }>()
)

export const fetchAllClientsFromSalesforce = createAction(
  '[Signature] Fetch All Clients From Salesforce'
)
export const fetchAllClientsFromSalesforceSuccess = createAction(
  '[Signature] Fetch All Clients From Salesforce - Success',
  props<{ clients: SalesforceClient[] }>()
)
export const fetchAllClientsFromSalesforceFailure = createAction(
  '[Signature] Fetch All Clients From Salesforce - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchAllSavedContracts = createAction(
  '[Signature] Fetch All Saved Contracts Under This Program'
)
export const fetchAllSavedContractsSuccess = createAction(
  '[Signature] Fetch All Saved Contracts Under This Program - Success',
  props<{ contracts: SignatureContract[] }>()
)
export const fetchAllSavedContractsFailure = createAction(
  '[Signature] Fetch All Saved Contracts Under This Program - Failure',
  props<{ error: ApplicationError }>()
)

/* For Groups */
export const fetchSignatureDataForGroups = createAction(
  '[Signature] Fetch Signature Data For Groups'
)
export const fetchRiskSectionsByProgramId = createAction(
  '[Signature] Fetch Risk Sections By ProgramID',
  props<{ programId: string }>()
)
export const fetchRiskSectionsByProgramIdSuccess = createAction(
  '[Signature] Fetch Risk Sections By ProgramID Success',
  props<{ sections: Section[] }>()
)
export const fetchRiskSectionsByProgramIdFailure = createAction(
  '[Signature] Fetch Risk Sections By ProgramID Failure',
  props<{ error: ApplicationError }>()
)
export const fetchReinsurersByProgramGroupId = createAction(
  '[Signature] Fetch Reinsurers By ProgramGroupID',
  props<{
    programGroupId: number
    programGroupName: string
    programGroupLayerDetails: LayerDetails[]
  }>()
)
export const fetchReinsurersByProgramGroupIdSuccess = createAction(
  '[Signature] Fetch Reinsurers By ProgramGroupID Success',
  props<{
    quoteReinsurers: QuoteWithLayerDetails[]
    programId: string | null
    programGroupId: number
    programGroupName: string
    selectedReinsurers: Reinsurer[] | null
  }>()
)
export const fetchReinsurersByProgramGroupIdFailure = createAction(
  '[Signature] Fetch Reinsurers By ProgramGroup ID Failure',
  props<{ error: ApplicationError }>()
)

/* For Structures */
export const fetchSignatureDataForStructures = createAction(
  '[Signature] Fetch Signature Data For Structures'
)
export const fetchAllRiskSections = createAction(
  '[Signature] Fetch All Risk Sections For Program'
)

export const fetchRiskSectionsByStructureId = createAction(
  '[Signature] Fetch Risk Sections By StructureID',
  props<{ structure: Program }>()
)
export const fetchRiskSectionsByStructureIdSuccess = createAction(
  '[Signature] Fetch Risk Sections By StructureID Success',
  props<{ sections: Section[] }>()
)
export const fetchRiskSectionsByStructureIdFailure = createAction(
  '[Signature] Fetch Risk Sections By StructureID Failure',
  props<{ error: ApplicationError }>()
)
export const fetchReinsurersByLayerRef = createAction(
  '[Signature] Fetch Reinsurers By LayerRef',
  props<{
    structureId: number
    structureName: string
    structureLayerDetails: LayerDetails
  }>()
)
export const fetchReinsurersByLayerRefSuccess = createAction(
  '[Signature] Fetch Reinsurers By LayerRef Success',
  props<{
    quoteReinsurers: QuoteWithLayerDetails[]
    programId: string | null
    structureId: number
    structureName: string
    selectedReinsurers: Reinsurer[] | null
  }>()
)
export const fetchReinsurersByLayerRefFailure = createAction(
  '[Signature] Fetch Reinsurers By LayerRef Failure',
  props<{ error: ApplicationError }>()
)

/* For Shared Limits */
export const fetchSignatureDataForSharedLimits = createAction(
  '[Signature] Fetch Signature Data For Shared Limits'
)

export const fetchRiskSectionsBySharedLimitId = createAction(
  '[Signature] Fetch Risk Sections By Shared Limit ID',
  props<{ sharedLimitProps: SharedLimitProps }>()
)
export const fetchRiskSectionsBySharedLimitIdSuccess = createAction(
  '[Signature] Fetch Risk Sections By Shared Limit ID Success',
  props<{ sections: Section[] }>()
)
export const fetchRiskSectionsBySharedLimitIdFailure = createAction(
  '[Signature] Fetch Risk Sections By Shared Limit ID Failure',
  props<{ error: ApplicationError }>()
)
export const fetchReinsurersBySharedLimitId = createAction(
  '[Signature] Fetch Reinsurers By Shared Limit ID',
  props<{
    sharedLimitProps: SharedLimitProps
  }>()
)
export const fetchReinsurersBySharedLimitIdSuccess = createAction(
  '[Signature] Fetch Reinsurers By Shared Limit ID Success',
  props<{
    quoteReinsurers: QuoteWithLayerDetails[]
    sharedLimitProps: SharedLimitProps
    programId: string | null
    selectedReinsurers: Reinsurer[] | null
  }>()
)
export const fetchReinsurersBySharedLimitIdFailure = createAction(
  '[Signature] Fetch Reinsurers By Shared Limit ID Failure',
  props<{ error: ApplicationError }>()
)

/* Reset Signature State */
export const resetSignatureData = createAction('[Signature] Reset Data')
export const resetSignatureProperties = createAction(
  '[Signature] Reset Properties'
)
