import { uniq } from 'ramda'
import { ApplicationError, errorPayload } from 'src/app/error/model/error'
import { isProgram, Program } from '../../core/model/program.model'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { ProgramGroup } from '../store/grouper/program-group.model'
import { ProgramGroupEntity } from '../store/grouper/program-group/program-group.reducer'
import { ProgramEntity } from '../store/grouper/program/program.reducer'
import {
  InuranceLevel,
  InuranceMetadataRef,
  InurancePayload,
  InuranceReference,
  InuranceRelationship,
  InuranceTagsByLevel,
  InuranceValidation,
  InuranceView,
  SourceAndTargetStructureAndGroupReferences,
  SourceAndTargetStructureGroupReferences,
  Terminal,
} from './inurance.model'
import { Layer } from './layers.model'
import {
  findActualTopAndDropLayer,
  findBackAllocatedLayerFor,
  filterValid,
  findFHCFActualLayer,
  findRiskActualLayer,
  findLayerByVisibleId,
} from './layers.util'
import { Dictionary } from '@ngrx/entity/src'
import { isSectionLayer } from '../layers/multi-section-layer'

export const inuranceCardsEquals = (
  a: InuranceView | null | undefined,
  b: InuranceView | null | undefined
): boolean => {
  if (!a && !b) {
    return true
  }
  if (!a || !b) {
    return false
  }
  return (
    a.layerID === b.layerID &&
    a.programID === b.programID &&
    a.programGroupID === b.programGroupID
  )
}

// Action helpers

function createInuranceLevelFromView(view: InuranceView): InuranceLevel {
  if (view.layerID != null) {
    if (!view.programID) {
      throw Error('Unexpected Error: Inurance Layer payload needs Program ID')
    }
    return 'layer'
  }

  if (view.programGroupID != null) {
    return 'programGroup'
  }

  if (view.programID != null) {
    return 'program'
  }

  throw Error('Unexpected Error: Inurance payload has no IDs')
}

export function getStructureCededLayers(
  structureID: string,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): Layer[] {
  const structure = Array.from(structureMap.keys()).find(
    s => s.program.id === structureID
  )
  if (!structure) {
    throw Error('Unexpected Error: Structure Inurance entity not found')
  }
  return structure.cededLayers.map(le => le.layer)
}

function getActualLayerID(
  visibleLayerID: string,
  structureID: string,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): string {
  let id = visibleLayerID
  const cededLayers = getStructureCededLayers(structureID, structureMap)

  // If this layer has an actual, hidden top & drop layer referencing it,
  // use its layer ID instead for the inurance
  const actualTopAndDropLayer = findActualTopAndDropLayer(cededLayers, id)
  if (actualTopAndDropLayer) {
    id = actualTopAndDropLayer.id
  }

  // If this layer has a back allocated layer for shared limits, we should
  // use its layer ID instead
  const backAllocatedLayer = findBackAllocatedLayerFor(cededLayers, id)
  if (backAllocatedLayer) {
    id = backAllocatedLayer.id
  }

  // If the FHCF layer has actual FHCF layer referencing it,
  // use its layer ID instead for the inurance
  const actualFHCFLayer = findFHCFActualLayer(cededLayers, id)
  if (actualFHCFLayer) {
    id = actualFHCFLayer.id
  }

  // If the Risk layer has actual Risk layer referencing it,
  // use its layer ID instead for the inurance
  const actualRiskLayer = findRiskActualLayer(cededLayers, id)
  if (actualRiskLayer) {
    id = actualRiskLayer.id
  }

  const mainLayer = findLayerByVisibleId(cededLayers, id)
  if (mainLayer) {
    id = mainLayer.id
  }

  return id
}

function getInuranceReferences(
  level: InuranceLevel,
  view: InuranceView,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): InuranceReference[] {
  switch (level) {
    case 'layer': {
      // `createInuranceLevelFromView` ensures `programID`
      // tslint:disable-next-line: no-non-null-assertion
      const structureID = view.programID!
      // `createInuranceLevelFromView` ensures `layerID`
      // tslint:disable-next-line: no-non-null-assertion
      const layerID = getActualLayerID(view.layerID!, structureID, structureMap)

      return [{ layerID, structureID }]
    }
    case 'program': {
      // `createInuranceLevelFromView` ensures `programID`
      // tslint:disable-next-line: no-non-null-assertion
      const structureID = view.programID!
      const cededLayers = getStructureCededLayers(structureID, structureMap)
      return cededLayers.map(layer => ({ layerID: layer.id, structureID }))
    }
    case 'programGroup': {
      // `createInuranceLevelFromView` ensures `programGroupID`
      // tslint:disable-next-line: no-non-null-assertion
      const structureGroupID = view.programGroupID
      const isGroup = (g: ProgramGroupEntity) =>
        g.programGroup.id === structureGroupID
      const structuresInGroup = Array.from(structureMap.entries())
        .filter(([_, groups]) => groups.find(isGroup))
        .map(([structure]) => structure)
      return structuresInGroup.flatMap(s => {
        const structureID = s.program.id
        return s.cededLayers
          .map(ls => ls.layer.id)
          .map(layerID => ({ layerID, structureID, structureGroupID }))
      })
    }
  }
}

export const initializeTagsByLevel = (): InuranceTagsByLevel => ({
  layer: {},
  program: {},
  programGroup: {},
})

export function createInurancePayload(
  sourceView: InuranceView | null,
  targetView: InuranceView | null,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>,
  programEntityDictionary: Dictionary<ProgramEntity>
): InurancePayload {
  if (!sourceView || !targetView) {
    throw Error('Unexpected Error: Inurance payload needs both source & target')
  }

  if (sourceView.fromDesign && targetView.fromDesign) {
    let inurancePayload = createInurancePayloadFromDesign(
      sourceView,
      targetView
    )
    const programEntity =
      programEntityDictionary[targetView.programID as string]
    const cededLayers = programEntity?.cededLayers.map(c => c.layer) ?? []
    const _target: InuranceReference[] = []
    for (const target of inurancePayload.target) {
      const targetLayer = cededLayers.find(c => c.id === target.layerID)

      if (targetLayer?.meta_data.sage_layer_subtype === 'visible-layer') {
        const mainLayer = cededLayers.find(
          c => c.meta_data.visible_layer_id === target.layerID
        )
        if (mainLayer) {
          _target.push({ ...target, layerID: mainLayer.id })
          continue
        }
      }

      _target.push(target)
    }
    inurancePayload = {
      ...inurancePayload,
      target: _target,
    }
    return inurancePayload
  } else {
    const sourceLevel = createInuranceLevelFromView(sourceView)
    const source = getInuranceReferences(sourceLevel, sourceView, structureMap)

    const targetLevel = createInuranceLevelFromView(targetView)
    const target = getInuranceReferences(targetLevel, targetView, structureMap)

    const relationship = createInuranceRelationship(sourceLevel, targetLevel)
    return { relationship, source, target }
  }
}

export function createInurancePayloadFromDesign(
  sourceView: InuranceView,
  targetView: InuranceView,
  sourceLayers?: Layer[],
  targetLayers?: Layer[]
): InurancePayload {
  const relationship: InuranceRelationship = createInuranceRelationship(
    sourceView.levelFromDesign as InuranceLevel,
    targetView.levelFromDesign as InuranceLevel
  )
  const source: InuranceReference[] =
    sourceView.referencesFromDesign as InuranceReference[]
  const target: InuranceReference[] =
    targetView.referencesFromDesign as InuranceReference[]
  const fromDesign: boolean =
    (sourceView.fromDesign as boolean) && (targetView.fromDesign as boolean)
  const layerForRefresh =
    sourceView.layerForRefresh && targetView.layerForRefresh
      ? targetView.layerForRefresh
      : undefined
  const structureForRefresh =
    sourceView.structureForRefresh && targetView.structureForRefresh
      ? targetView.structureForRefresh
      : undefined

  return {
    relationship,
    source,
    target,
    fromDesign,
    fromDesignSourceLayers: sourceLayers,
    fromDesignTargetLayers: targetLayers,
    structureForRefresh,
    layerForRefresh,
  }
}

export function createLayerInuranceView(
  symbol: string,
  structure: Program,
  layer: Layer,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): InuranceView
export function createLayerInuranceView(
  symbol: string,
  structure: Program,
  layer: Layer | undefined,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): undefined
export function createLayerInuranceView(
  symbol: string,
  structure: Program | undefined,
  layer: Layer,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): undefined
export function createLayerInuranceView(
  symbol: string,
  structure: Program | undefined,
  layer: Layer | undefined,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): InuranceView | undefined {
  if (structure && layer) {
    return {
      layerID: layer.id,
      programID: structure.id,
      symbol,
      title: layer.meta_data.layerName || layer.physicalLayer.description || '',
      subtitle: structure.label,
      payload,
      fromDesign,
      levelFromDesign,
      referencesFromDesign,
      layerForRefresh,
      structureForRefresh,
      isSection: isSectionLayer(layer),
    }
  }
}

export function createStructureInuranceView(
  symbol: string,
  structure: Program | ProgramGroup,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): InuranceView
export function createStructureInuranceView(
  symbol: string,
  structure: undefined,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): undefined
export function createStructureInuranceView(
  symbol: string,
  structure?: Program | ProgramGroup,
  payload?: InurancePayload,
  fromDesign?: boolean,
  levelFromDesign?: InuranceLevel,
  referencesFromDesign?: InuranceReference[],
  layerForRefresh?: Layer,
  structureForRefresh?: Program
): InuranceView | undefined {
  if (structure) {
    const programID = isProgram(structure) ? structure.id : undefined
    const programGroupID = !isProgram(structure) ? structure.id : undefined
    return {
      programID,
      programGroupID,
      symbol,
      title: structure.label,
      payload,
      fromDesign,
      levelFromDesign,
      referencesFromDesign,
      layerForRefresh,
      structureForRefresh,
    }
  }
}

export function toInuranceRefs(
  value: string | undefined
): InuranceMetadataRef[] {
  if (!value) {
    return []
  }
  return JSON.parse(value)
}

export function fromInuranceRefs(value: InuranceMetadataRef[]): string {
  if (!value || (value && value.length === 0)) {
    return ''
  }
  return JSON.stringify(value)
}

export function getInuranceLevelOfRelationship(
  terminal: Terminal,
  relationship: InuranceRelationship
): InuranceLevel {
  const isSource = terminal === 'source'
  switch (relationship) {
    case 'layerToLayer':
      return 'layer'
    case 'layerToStructure':
      return isSource ? 'layer' : 'program'
    case 'layerToStructureGroup':
      return isSource ? 'layer' : 'programGroup'
    case 'structureToLayer':
      return isSource ? 'program' : 'layer'
    case 'structureToStructure':
      return 'program'
    case 'structureToStructureGroup':
      return isSource ? 'program' : 'programGroup'
    case 'structureGroupToLayer':
      return isSource ? 'programGroup' : 'layer'
    case 'structureGroupToStructure':
      return isSource ? 'programGroup' : 'program'
    case 'structureGroupToStructureGroup':
      return 'programGroup'
    default:
      throw Error('Unexpected relationship in "getInuranceLevelOfRelationship"')
  }
}

function createInuranceRelationship(
  source: InuranceLevel,
  target: InuranceLevel
): InuranceRelationship {
  switch (source) {
    case 'layer':
      switch (target) {
        case 'layer':
          return 'layerToLayer'
        case 'program':
          return 'layerToStructure'
        default:
        case 'programGroup':
          return 'layerToStructureGroup'
      }
    case 'program':
      switch (target) {
        case 'layer':
          return 'structureToLayer'
        case 'program':
          return 'structureToStructure'
        default:
        case 'programGroup':
          return 'structureToStructureGroup'
      }
    case 'programGroup':
      switch (target) {
        case 'layer':
          return 'structureGroupToLayer'
        case 'program':
          return 'structureGroupToStructure'
        default:
        case 'programGroup':
          return 'structureGroupToStructureGroup'
      }
    default:
      throw Error('Unexpected relationship in "createInuranceRelationship"')
  }
}

export function getInuranceSourceAndTargerReferenceByGroup(
  layers: Layer[],
  groupIDs: string[]
) {
  const references = layers
    .filter(l => l.meta_data.inuranceTarget || l.meta_data.inuranceSource)
    .reduce(reduceToGroupReference(groupIDs), {
      layerSources: [],
      layerTargets: [],
      structureSources: [],
      structureTargets: [],
      structureGroupSources: [],
      structureGroupTargets: [],
    } as SourceAndTargetStructureGroupReferences)
  references.layerSources = uniq(references.layerSources)
  references.structureSources = uniq(references.structureSources)
  references.layerTargets = uniq(references.layerTargets)
  references.structureTargets = uniq(references.structureTargets)
  references.structureGroupSources = uniq(references.structureGroupSources)
  references.structureGroupTargets = uniq(references.structureGroupTargets)
  return references
}

export function getInuranceSourceAndTargetReference(
  layers: LayerState[]
): SourceAndTargetStructureAndGroupReferences {
  const references = layers
    .filter(
      l => l.layer.meta_data.inuranceTarget || l.layer.meta_data.inuranceSource
    )
    .map(l => l.layer)
    .reduce(reduceToStructureReference, {
      layerSources: [],
      layerTargets: [],
      structureSources: [],
      structureTargets: [],
      structureGroupSources: [],
      structureGroupTargets: [],
      targetForSample: null,
      sourceForSample: null,
    } as SourceAndTargetStructureAndGroupReferences)
  references.layerSources = uniq(references.layerSources)
  references.structureSources = uniq(references.structureSources)
  references.layerTargets = uniq(references.layerTargets)
  references.structureTargets = uniq(references.structureTargets)
  references.structureGroupSources = uniq(references.structureGroupSources)
  references.structureGroupTargets = uniq(references.structureGroupTargets)
  return references
}

const reduceToGroupReference =
  (groupIDs: string[]) =>
  (acc: SourceAndTargetStructureGroupReferences, layer: Layer) => {
    if (layer.meta_data.inuranceSource) {
      const sourceForInuranceRefs = toInuranceRefs(
        layer.meta_data.inuranceSourceFor
      ).filter(
        r =>
          !isSourceLayer(r.type) &&
          !isSourceStructure(r.type) &&
          groupIDs.includes(r.groupID || '')
      )
      for (const sourceInuranceRef of sourceForInuranceRefs) {
        if (isTargetLayer(sourceInuranceRef.type)) {
          acc.layerTargets.push(sourceInuranceRef.id)
        } else if (isTargetStructure(sourceInuranceRef.type)) {
          acc.structureTargets.push(sourceInuranceRef.id)
        } else {
          acc.structureGroupTargets.push(sourceInuranceRef.id)
        }
      }
    }
    if (layer.meta_data.inuranceTarget) {
      const targetForInuranceRefs = toInuranceRefs(
        layer.meta_data.inuranceTargetFor
      ).filter(
        r =>
          !isTargetLayer(r.type) &&
          !isTargetStructure(r.type) &&
          groupIDs.includes(r.groupID || '')
      )
      for (const targetInuranceRef of targetForInuranceRefs) {
        if (isSourceLayer(targetInuranceRef.type)) {
          acc.layerSources.push(targetInuranceRef.id)
        } else if (isSourceStructure(targetInuranceRef.type)) {
          acc.structureSources.push(targetInuranceRef.id)
        } else {
          acc.structureGroupSources.push(targetInuranceRef.id)
        }
      }
    }
    return acc
  }

// Ignore layer sources or layer targets
function reduceToStructureReference(
  acc: SourceAndTargetStructureAndGroupReferences,
  layer: Layer
): SourceAndTargetStructureAndGroupReferences {
  if (layer.meta_data.inuranceSource) {
    const sourceForInuranceRefs = toInuranceRefs(
      layer.meta_data.inuranceSourceFor
    ).filter(r => !isSourceLayer(r.type))
    if (sourceForInuranceRefs.length > 0) {
      acc.sourceForSample = layer
    }
    for (const sourceInuranceRef of sourceForInuranceRefs) {
      if (isTargetLayer(sourceInuranceRef.type)) {
        acc.layerTargets.push(sourceInuranceRef.id)
      } else if (isTargetStructure(sourceInuranceRef.type)) {
        acc.structureTargets.push(sourceInuranceRef.id)
      } else {
        acc.structureGroupTargets.push(sourceInuranceRef.id)
      }
    }
  }
  if (layer.meta_data.inuranceTarget) {
    const targetForInuranceRefs = toInuranceRefs(
      layer.meta_data.inuranceTargetFor
    ).filter(r => !isTargetLayer(r.type))
    if (targetForInuranceRefs.length > 0) {
      acc.targetForSample = layer
    }
    for (const targetInuranceRef of targetForInuranceRefs) {
      if (isSourceLayer(targetInuranceRef.type)) {
        acc.layerSources.push(targetInuranceRef.id)
      } else if (isSourceStructure(targetInuranceRef.type)) {
        acc.structureSources.push(targetInuranceRef.id)
      } else {
        acc.structureGroupSources.push(targetInuranceRef.id)
      }
    }
  }
  return acc
}

export function isSourceLayer(ref: InuranceRelationship) {
  return ref.startsWith('layer')
}

export function isSourceStructure(ref: InuranceRelationship) {
  return ref.startsWith('structureTo')
}

export function isSourceStructureGroup(ref: InuranceRelationship) {
  return ref.startsWith('structureGroup')
}

export function isTargetLayer(ref: InuranceRelationship) {
  return ref.endsWith('Layer')
}

export function isTargetStructure(ref: InuranceRelationship) {
  return ref.endsWith('Structure')
}

export function isTargetStructureGroup(ref: InuranceRelationship) {
  return ref.endsWith('StructureGroup')
}

export function canInure(layer: Layer) {
  return filterValid(layer)
}

export function canInureDelete(layer: Layer) {
  const meta = layer.meta_data
  const type = meta.sage_layer_type
  const subtype = meta.sage_layer_subtype

  // Just like filterValid but allows shared limit detection
  return (
    !(type === 'cat_td' && subtype === 'virtual') &&
    !(type === 'drop' && meta.isDrop) &&
    !(type === 'shared_limits' && subtype === 'virtual') &&
    !(
      (type === 'cat_ag' || type === 'noncat_ag' || type === 'ahl_ag') &&
      subtype === 'feeder'
    ) &&
    !(type === 'cat_fhcf' && (meta.isFHCFHidden1 || meta.isFHCFHidden2)) &&
    !(
      type === 'noncat_risk' &&
      (meta.isRiskLargeHidden || meta.isRiskCatHidden || meta.isRiskVisible)
    )
  )
}

export function filterReferences(
  source: InuranceReference[],
  target: InuranceReference[],
  sourceLayers: Layer[],
  targetLayers: Layer[]
): readonly [InuranceReference[], InuranceReference[]] {
  const sourceLayersID = sourceLayers.map(s => s.id)
  const targetLayersID = targetLayers.map(t => t.id)
  const filteredSource = source.filter(s => sourceLayersID.includes(s.layerID))
  const filteredTarget = target.filter(t => targetLayersID.includes(t.layerID))
  return [filteredSource, filteredTarget]
}

export function inuranceValidationToErrorPayload(
  validation: InuranceValidation,
  message: string = ''
): ApplicationError | null {
  if (
    validation.circularReference ||
    validation.selfReference ||
    validation.duplicateReference ||
    validation.targetHasSharedLimit ||
    validation.hasSharedLimitDelete ||
    validation.isILW ||
    validation.noLayerFoundReference
  ) {
    const details: string[] = []
    if (validation.noLayerFoundReference) {
      details.push(
        'Source or Target Layer not found for Inurance. Please save the structure and try again.'
      )
    }
    if (validation.isILW) {
      details.push('ILW layer type is not supported for Inurance.')
    }
    if (validation.circularReference) {
      details.push('Circular reference detected.')
    }
    if (validation.duplicateReference) {
      details.push('Duplicate reference detected.')
    }
    if (validation.selfReference) {
      details.push('Self reference detected.')
    }
    if (validation.targetHasSharedLimit) {
      details.push(
        'Shared Limit detected on one or more target layers. Please create Inurance first, then add Shared Limit to target layers.'
      )
    }
    if (validation.hasSharedLimitDelete) {
      details.push(
        'Shared Limit detected on one or more target layers. Please delete Shared Limit from target layers first, then delete Inurance.'
      )
    }
    return errorPayload(message || 'Inurance validation failed.', details)
  } else {
    return null
  }
}
