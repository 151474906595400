<div
  class="tooltip-wrapper"
  [style.top]="mouseHeight + 'px'"
  [style.left]="parentWidth + leftPadding + 'px'"
>
  <div
    class="metric-wrapper"
    *ngFor="let keyPair of displayValues | keyvalue: originalOrder"
  >
    <div
      class="metric-row"
      [ngClass]="{ 'name-row': keyPair.key === 'Curve Name' }"
    >
      <div class="metric">
        <span>{{ keyPair.key + ': ' }}</span
        ><span>{{ keyPair.value }}</span>
      </div>

      <mat-icon
        class="immutable-icon"
        *ngIf="!!savedCurve.isImmutable && keyPair.key === 'Curve Name'"
        >edit_off</mat-icon
      >
    </div>
  </div>
</div>
