import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { intersection, uniq, uniqBy } from 'ramda'
import { RiskLossSetLayerModel } from 'src/app/analysis/layers/loss-set-layer-selector/loss-set-layer-selector.component'
import { ScaleLossSetDialogService } from 'src/app/analysis/layers/scale-loss-set-dialog.service'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import {
  findRiskActualLayer,
  isLayerAgg,
} from 'src/app/analysis/model/layers.util'
import {
  LossSetGroup,
  LossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { PortfolioMetrics } from 'src/app/analysis/model/portfolio-metrics.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { LossSetGroupEntity } from 'src/app/analysis/store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import { OmitID } from 'src/app/api/model/backend.model'
import { AutoGroupLossSetsDialogService } from './auto-group-loss-sets/auto-group-loss-sets-dialog.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state(
        'expand',
        style({
          width: '*',
          opacity: 1,
          visibility: 'visible',
        })
      ),
      state(
        'collapse',
        style({
          width: '0px',
          opacity: 0,
          visibility: 'hidden',
        })
      ),
      transition('expand <=> collapse', animate('150ms ease-out')),
    ]),
  ],
  selector: 'app-layer-panel-loss-set-groups',
  styles: [
    `
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: var(--stack) 0 var(--stack-tiny);
      }

      section {
        margin: var(--stack-small) 0;
        max-height: 20vh;
        padding: 24px 0;
        overflow: auto;
      }

      .loss-set-groups button {
        width: 100%;
      }

      .loss-set-groups-button-list-edit {
        margin-top: 30px;
        margin-right: 15px;
        width: 40%;
      }

      .loss-set-groups-button-list-view {
        width: 100%;
      }

      .return-period-table-layer {
        width: 55%;
      }

      button.icon {
        color: var(--body);
        align-self: flex-end;
      }

      button.icon:hover {
        color: var(--subtle);
      }

      :host button.edit {
        padding: 0px 10px 3px;
        width: 100%;
      }

      :host.has-groups button.edit {
        background: transparent;
        margin-top: var(--stack-tiny);
      }

      .loss-sets-header {
        display: flex;
        justify-content: space-between;
      }

      .auto-group {
        margin-top: var(--inset-small);
        margin-bottom: var(--inset-small);
      }

      .loss-set-groups {
        padding-top: var(--stack-small);
        display: flex;
        flex-direction: row;
        max-height: 25vh;
        overflow: scroll;
      }

      .loss-set-groups-button-list {
        display: flex;
        flex-direction: column;
      }

      .loss-set-groups-button-edit {
        margin-top: 30px;
      }

      form {
        display: flex;
        align-items: flex-end;
        height: 32px;
      }

      form > div {
        display: flex;
        flex: 1 1 auto;
        align-items: baseline;
        width: 100%;
        overflow-y: hidden;
      }

      form > div.add {
        flex: 0 1 auto;
        width: auto;
      }

      form > div > mat-form-field {
        flex: 1 1 auto;
        margin-right: var(--inset);
      }

      form > div > button.cancel {
        margin-right: var(--inset-small);
      }

      mat-icon {
        width: 16px;
        height: 16px;
        font-size: 18px;
        margin-right: 0;
      }

      @media screen and (max-width: 1240px) {
        h4 {
          font-size: var(--font-size-small);
        }
      }
    `,
  ],
  template: `
    <div *ngIf="lossSetGroups.length" class="loss-set-groups">
      <div
        [ngClass]="
          showLossSetGroupEditor
            ? 'loss-set-groups-button-list-edit'
            : 'loss-set-groups-button-list-view'
        "
      >
        <button
          *ngFor="let g of sortLossGroups()"
          appButton
          stack
          [primary]="!isGroupSelected(g.lossSetGroup.id)"
          [accent]="isGroupSelected(g.lossSetGroup.id)"
          (click)="setSelectedGroup(g.lossSetGroup)"
          [matTooltip]="g.lossSetGroup.name"
          matTooltipPosition="above"
        >
          <span>{{ g.lossSetGroup.name }}</span>
          <ng-template #rightTemplate>
            <mat-icon *ngIf="showDelete" (click)="deleteGroup(g.lossSetGroup)">
              cancel
            </mat-icon>
          </ng-template>
        </button>
      </div>

      <app-loading-item
        *ngIf="showLossSetGroupEditor"
        [item]="lossSetGroupsMetrics"
        [loading]="isEmpty"
      >
        <app-return-period-table-group
          class="return-period-table-layer"
          *ngIf="showLossSetGroupEditor"
          [isPortfolioMetrics]="true"
          [data]="lossSetReturnPeriodData"
          [rp1]="returnPeriod1"
          [rp2]="returnPeriod2"
          [rp3]="returnPeriod3"
          (rp1Change)="returnPeriod1Change.emit($event)"
          (rp2Change)="returnPeriod2Change.emit($event)"
          (rp3Change)="returnPeriod3Change.emit($event)"
          size="tiny"
          noPadding
        ></app-return-period-table-group>
      </app-loading-item>
    </div>

    <section *ngIf="showLossSetGroupEditor; else editTemplate">
      <ng-container *ngIf="!lossSetGroupsActiveAction; else actionRunning">
        <form>
          <div class="add" [@collapse]="!isSaveHidden ? 'collapse' : 'expand'">
            <button
              [disabled]="isNewDisabled"
              appButton
              accent
              border
              (click)="onNewClick()"
            >
              Add Group
            </button>
          </div>

          <div [@collapse]="isSaveHidden ? 'collapse' : 'expand'">
            <mat-form-field
              class="app-mat-form-field-no-padding"
              appearance="fill"
            >
              <input
                matInput
                #groupName
                placeholder="Group Name"
                name="groupName"
                value="{{ selectedGroupName }}"
                (input)="onGroupNameEdit()"
              />
            </mat-form-field>

            <button
              appButton
              accent
              border
              matTooltip="{{ saveButtonTooltip }} "
              matTooltipPosition="right"
              [disabled]="isSaveDisabled || groupName.value.length < 1"
              (click)="onSaveClick(groupName.value); groupName.value = ''"
            >
              Save
            </button>

            <button
              appButton
              link
              class="cancel"
              [disabled]="isCancelDisabled"
              (click)="onCancelClick()"
            >
              Cancel
            </button>
          </div>
        </form>
        <div class="auto-group">
          <button
            [disabled]="isNewDisabled"
            appButton
            accent
            border
            (click)="onAutoGroupClick()"
          >
            Auto Group
          </button>
        </div>
      </ng-container>
    </section>

    <ng-template #editTemplate>
      <button
        appButton
        small
        center
        [border]="!hasGroups"
        class="edit"
        (click)="onEditClick()"
      >
        {{ editLabel }}
      </button>
      <button
        appButton
        small
        center
        [border]="!hasGroups"
        class="edit"
        matTooltip="{{ scaleTooltip }}"
        (click)="onScaleClick()"
        [disabled]="designDirty"
      >
        Edit/Scale Loss Sets
      </button>
    </ng-template>

    <ng-template #actionRunning>
      <app-active-action
        [value]="lossSetGroupsActiveAction"
      ></app-active-action>
    </ng-template>
  `,
})
export class LayerPanelLossSetGroupsComponent {
  @Input() lossSetGroupsActiveAction: string
  @Input() showLossSetGroupEditor = false
  @Input() groupEditSelectedLossSets: LossSetLayer[]
  @Input() lossSetGroupEditMode: string
  @Input() programID: string
  @Input() lossSetGroups: LossSetGroupEntity[]
  @Input() lossSetGroupsMetrics: Record<string, PortfolioMetrics>[]
  @Input() lossSetSelectedGroup: string
  @Input() lossSetSelectionMode: string
  @Input() currentCededLayer: LayerState | null
  @Input() allCededLayers: LayerState[]
  @Input() parentLossSets: LossSetLayer[]
  @Input() grossLossSets: LossSetLayer[]
  @Input() designDirty: boolean
  @Input() metrics: PortfolioMetrics

  @Output() lossSetSelection = new EventEmitter<Partial<Layer>>()
  @Output() grossLossSetSelection = new EventEmitter<LossSetLayer[]>()

  @Output() lossSetGroupEditorClick = new EventEmitter<boolean>()
  @Output() groupEditLossSetSelection = new EventEmitter<LossSetLayer[]>()
  @Output() lossSetGroupClick = new EventEmitter<any>()
  @Output() deleteLossSetGroupClick = new EventEmitter<LossSetGroup>()

  @Output() newLossSetGroupClick = new EventEmitter<void>()
  @Output() cancelLossSetGroupClick = new EventEmitter<void>()
  @Output() saveLossSetGroupClick = new EventEmitter<any>()
  @Output() updateLossSetGroupClick = new EventEmitter<LossSetGroup>()
  @Output() lossSetGroupSetDirty = new EventEmitter<string>()
  @Output() grossSelectionModeClick = new EventEmitter<void>()

  @Output() removeRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() addRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()

  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()

  @HostBinding('class.has-groups')
  get hasGroups(): boolean {
    return this.lossSetGroups.length > 0
  }

  get isEmpty() {
    return (
      this.lossSetGroupsMetrics === null ||
      this.lossSetGroupsMetrics === undefined ||
      this.lossSetGroupsMetrics.length <= 0
    )
  }

  get editLabel(): string {
    return this.hasGroups ? 'Edit Groups' : 'Add Groups'
  }

  get selectedGroupName(): string {
    if (!this.lossSetSelectedGroup) {
      return ''
    } else {
      // tslint:disable-next-line: no-non-null-assertion
      return this.lossSetGroups.find(
        g => g.lossSetGroup.id === this.lossSetSelectedGroup
      )!.lossSetGroup.name
    }
  }

  get currentSelectedGroup() {
    return this.lossSetGroups.find(
      g => g.lossSetGroup.id === this.lossSetSelectedGroup
    )
  }

  get groupEditorTooltip() {
    return this.showLossSetGroupEditor
      ? 'Close Loss Set Group Editor'
      : 'Open Loss Set Group Editor'
  }

  get groupEditorInfoText() {
    return this.showLossSetGroupEditor
      ? 'Create or Delete Loss Set Groups'
      : 'Open Group Editor to edit Loss Set Groups'
  }

  get saveButtonTooltip() {
    return 'Enter a name and choose Loss Sets to Save Group'
  }

  get scaleTooltip() {
    return this.designDirty
      ? 'Please Save changes in Design before scaling loss sets'
      : 'Edit / Scale Loss Sets'
  }

  get isNewDisabled(): boolean {
    return this.lossSetGroupEditMode === 'new'
  }
  get isCancelDisabled(): boolean {
    return this.lossSetGroupEditMode === ''
  }
  get isSaveHidden(): boolean {
    return this.lossSetGroupEditMode === ''
  }

  get isSaveDisabled(): boolean {
    const anyGroupSelected = this.currentSelectedGroup
    const currentGroupDirty =
      anyGroupSelected && this.currentSelectedGroup?.dirty

    return (
      this.groupEditSelectedLossSets.length < 1 ||
      (this.lossSetGroupEditMode === 'edit' && !currentGroupDirty)
    )
  }

  get showDelete(): boolean {
    return this.showLossSetGroupEditor && this.lossSetGroupEditMode !== 'new'
  }

  get lossSetReturnPeriodData() {
    return this.sortLossGroups().map(l => {
      const group = l.lossSetGroup

      const metric = this.lossSetGroupsMetrics.find(m => m[l.lossSetGroup.id])

      if (metric) {
        const res = metric[l.lossSetGroup.id].returnPeriodData.find(
          r => r.header === 'Expected'
        )

        if (res) {
          return {
            ...group,
            header: 'Expected',
            period1: res.period1,
            period2: res.period2,
            period3: res.period3,
            period4: res.period4,
            period5: res.period5,
            term: res.term,
            value: res.value,
          }
        }
      }

      return {
        ...group,
        header: '',
        term: '',
        value: null,
      }
    })
  }

  get returnPeriod1(): number {
    return this.metrics.returnPeriod1
  }

  get returnPeriod2(): number {
    return this.metrics.returnPeriod2
  }

  get returnPeriod3(): number {
    return this.metrics.returnPeriod3
  }

  constructor(
    private scaleLossSetDialog: ScaleLossSetDialogService,
    private autoGroupLossSetsDialog: AutoGroupLossSetsDialogService
  ) {}

  getSelected(groupID: string, lossSets: string[]): boolean {
    const lossSetGroupMap = toRecord<string>(
      this.lossSetGroups,
      lossSetTransform
    )
    const lossSetLayers = lossSetGroupMap[groupID]
    if (lossSetLayers) {
      return validateGroupSelection(lossSetLayers, lossSets, lossSetGroupMap)
    } else {
      return false
    }
  }

  isGroupSelected(groupID: string) {
    if (this.showLossSetGroupEditor) {
      const groupSelected = groupID === this.lossSetSelectedGroup
      return groupSelected
    } else if (this.lossSetSelectionMode === 'Gross') {
      const grossLossSets = this.grossLossSets.map(l => l.id)
      return this.getSelected(groupID, grossLossSets)
    } else if (this.lossSetSelectionMode === 'Ceded') {
      if (this.currentCededLayer) {
        if (
          this.currentCededLayer.layer.meta_data.sage_layer_type === 'cat_fhcf'
        ) {
          if (
            this.currentCededLayer.layer.lossSetLayers.length > 0 &&
            this.currentCededLayer.layer.lossSetLayers[0].loss_sets
          ) {
            const lossSets = this.currentCededLayer.layer.lossSetLayers[0]
              .loss_sets as LayerRef[]
            const cededLossSets = lossSets.map(l => l.id)

            const lossSetGroupMap = toRecord<string>(
              this.lossSetGroups,
              lossSetSpecialTransform
            )
            const lossSetLayers = lossSetGroupMap[groupID]
            if (lossSetLayers) {
              return validateGroupSelection(
                lossSetLayers,
                cededLossSets,
                lossSetGroupMap
              )
            } else {
              return false
            }
          } else {
            return false
          }
        } else if (
          this.currentCededLayer.layer.meta_data.sage_layer_type ===
          'noncat_risk'
        ) {
          const allLayers = this.allCededLayers.map(l => l.layer)
          // tslint:disable-next-line: no-non-null-assertion
          const actualRiskLayer = findRiskActualLayer(
            allLayers,
            this.currentCededLayer.layer.id
          )!
          const cededLossSets = actualRiskLayer.lossSetLayers.map(l => {
            if (l.meta_data.isRiskCatHidden) {
              return (
                (l.loss_sets as LossSetLayer[])[0].loss_sets[0] as LayerRef
              ).id
            } else {
              return (l.loss_sets as LayerRef[])[0].id
            }
          })

          const lossSetGroupMap = toRecord<string>(
            this.lossSetGroups,
            lossSetSpecialTransform
          )
          const lossSetLayers = lossSetGroupMap[groupID]
          if (lossSetLayers) {
            return validateGroupSelection(
              lossSetLayers,
              cededLossSets,
              lossSetGroupMap
            )
          } else {
            return false
          }
        } else {
          const cededLossSets = this.currentCededLayer.layer.lossSetLayers.map(
            l => l.id
          )
          return this.getSelected(groupID, cededLossSets)
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  onEditClick() {
    // Close
    if (this.showLossSetGroupEditor) {
      this.lossSetGroupEditorClick.emit(false)
      this.grossSelectionModeClick.emit()
      // Open
    } else {
      this.lossSetGroupEditorClick.emit(true)
    }
  }

  setSelectedGroup(lossSetGroup: LossSetGroup) {
    // If in editor, selecting a group should update mode to 'edit'
    if (this.showLossSetGroupEditor) {
      this.lossSetGroupClick.emit({ lossSetGroup, mode: 'edit' })
    }

    // If not in editor, selecting loss set group should apply
    // loss sets to either Gross portfolio or selected ceded layer
    if (!this.showLossSetGroupEditor) {
      this.lossSetGroupClick.emit({ lossSetGroup, mode: '' })
      let anyGroupSelected = false
      for (const g of this.lossSetGroups) {
        anyGroupSelected = this.isGroupSelected(g.lossSetGroup.id)
        if (anyGroupSelected) {
          break
        }
      }
      const isGroupSelected = this.isGroupSelected(lossSetGroup.id)
      if (this.lossSetSelectionMode === 'Ceded') {
        if (
          this.currentCededLayer &&
          isLayerAgg(this.currentCededLayer.layer)
        ) {
          return
        }
        if (this.currentCededLayer?.layer.id) {
          // Handle special FHCF layer type
          // TODO: Handle Risk XOL layer type when implemented
          if (
            this.currentCededLayer.layer.meta_data.sage_layer_type ===
            'cat_fhcf'
          ) {
            const FHCFLossSets: LayerRef[] = []
            if (
              this.currentCededLayer.layer.lossSetLayers.length > 0 &&
              this.currentCededLayer.layer.lossSetLayers[0].loss_sets
            ) {
            }
            if (isGroupSelected) {
              const lossSetGroupMap = toRecord<string>(
                this.lossSetGroups,
                lossSetSpecialTransform
              )
              const lossSetsToRemove = this.getSpecialLossSetsToRemove(
                lossSetGroupMap,
                lossSetGroup
              )
              FHCFLossSets.push(
                ...(
                  this.currentCededLayer.layer.lossSetLayers[0]
                    .loss_sets as LayerRef[]
                ).filter(l => !lossSetsToRemove.includes(l.id))
              )
            } else {
              if (anyGroupSelected) {
                FHCFLossSets.push(
                  ...(this.currentCededLayer.layer.lossSetLayers[0]
                    .loss_sets as LayerRef[])
                )
              }
              lossSetGroup.lossSetLayers.forEach(l => {
                FHCFLossSets.push(l.loss_sets[0] as LayerRef)
              })
            }

            const innerFHCFLossSetLayer = [
              {
                id: this.currentCededLayer.layer.lossSetLayers[0].id,
                meta_data:
                  this.currentCededLayer.layer.lossSetLayers[0].meta_data,
                loss_sets: uniqBy(l => l.id, FHCFLossSets),
              },
            ]
            this.lossSetSelection.emit({
              id: this.currentCededLayer.layer.id,
              lossSetLayers: innerFHCFLossSetLayer,
            })
          } else if (
            this.currentCededLayer.layer.meta_data.sage_layer_type ===
            'noncat_risk'
          ) {
            const allLayers = this.allCededLayers.map(l => l.layer)
            // tslint:disable-next-line: no-non-null-assertion
            const actualRiskLayer = findRiskActualLayer(
              allLayers,
              this.currentCededLayer.layer.id
            )!
            let selRiskLossSetLayers: LayerRef[] = []
            actualRiskLayer.lossSetLayers.forEach(l => {
              if (l.loss_sets) {
                if (
                  l.loss_sets[0].hasOwnProperty('loss_sets') &&
                  (l.loss_sets[0] as LayerRef).loss_sets
                ) {
                  const catLoss = l.loss_sets[0] as LayerRef
                  if (catLoss.loss_sets && catLoss.loss_sets[0]) {
                    selRiskLossSetLayers.push(
                      // tslint:disable-next-line: no-non-null-assertion
                      (l.loss_sets[0] as LayerRef).loss_sets![0] as LossSetLayer
                    )
                  }
                } else {
                  selRiskLossSetLayers.push(l.loss_sets[0] as LayerRef)
                }
              }
            })
            selRiskLossSetLayers = selRiskLossSetLayers.slice()

            const inRangeIDs = lossSetGroup.lossSetLayers.map(
              r => (r.loss_sets[0] as LossSetLayer).id
            )
            if (isGroupSelected) {
              const lossSetGroupMap = toRecord<string>(
                this.lossSetGroups,
                lossSetSpecialTransform
              )
              const lossSetsToRemove = this.getSpecialLossSetsToRemove(
                lossSetGroupMap,
                lossSetGroup
              )

              const removeRiskLossSets = lossSetsToRemove.map(
                id =>
                  // tslint:disable-next-line: no-non-null-assertion
                  this.parentLossSets.find(
                    i => (i.loss_sets[0] as LossSetLayer).id === id
                  )!
              )
              removeRiskLossSets.map(r => {
                this.removeRiskLossSets.emit({
                  id: actualRiskLayer.id,
                  lossSets: [r],
                })
              })
            } else {
              if (!anyGroupSelected) {
                // // Remove loss sets that are existing on actual risk layers and are not in group
                const removeRiskLSIds = selRiskLossSetLayers.filter(
                  l => !inRangeIDs.map(l1 => l1).includes(l.id)
                )
                const removeRiskLossSets = removeRiskLSIds.map(
                  r =>
                    // tslint:disable-next-line: no-non-null-assertion
                    this.parentLossSets.find(
                      i => (i.loss_sets[0] as LossSetLayer).id === r.id
                    )!
                )

                removeRiskLossSets.map(r => {
                  this.removeRiskLossSets.emit({
                    id: actualRiskLayer.id,
                    lossSets: [r],
                  })
                })
              }

              // Add loss sets that do not exist on actual risk layers and ARE in group
              // if it's not selected add it
              const addRiskLSIds = inRangeIDs.filter(
                l => !selRiskLossSetLayers.map(l1 => l1.id).includes(l)
              )
              const addRiskLossSets = addRiskLSIds.map(
                r =>
                  // tslint:disable-next-line: no-non-null-assertion
                  lossSetGroup.lossSetLayers.find(
                    i => (i.loss_sets[0] as LossSetLayer).id === r
                  )!
              )
              this.addRiskLossSets.emit({
                id: actualRiskLayer.id,
                lossSets: uniqBy(l => l.id, addRiskLossSets),
              })
            }
          } else {
            if (isGroupSelected) {
              const lossSetGroupMap = toRecord<string>(
                this.lossSetGroups,
                lossSetTransform
              )

              const lossSetsToRemove = this.getLossSetsToRemove(
                lossSetGroupMap,
                lossSetGroup
              )

              this.lossSetSelection.emit({
                ...this.currentCededLayer.layer,
                lossSetLayers: uniqBy(
                  l => l.id,
                  [
                    ...this.currentCededLayer.layer.lossSetLayers.filter(
                      l => !lossSetsToRemove.includes(l.id)
                    ),
                  ]
                ),
              })
            } else {
              this.lossSetSelection.emit({
                ...this.currentCededLayer.layer,
                lossSetLayers: uniqBy(
                  l => l.id,
                  [
                    ...lossSetGroup.lossSetLayers,
                    ...(anyGroupSelected
                      ? this.currentCededLayer.layer.lossSetLayers
                      : []),
                  ]
                ),
              })
            }
          }
        }
      } else {
        // For Gross Loss Sets
        if (isGroupSelected) {
          const lossSetGroupMap = toRecord<string>(
            this.lossSetGroups,
            lossSetTransform
          )

          const lossSetsToRemove = this.getLossSetsToRemove(
            lossSetGroupMap,
            lossSetGroup
          )
          const lossData: LossSetLayer[] = uniqBy(
            l => l.id,
            [
              ...this.grossLossSets.filter(
                l => !lossSetsToRemove.includes(l.id)
              ),
            ]
          )
          this.grossLossSetSelection.emit(lossData)
        } else {
          const lossData: LossSetLayer[] = uniqBy(
            l => l.id,
            [
              ...lossSetGroup.lossSetLayers,
              ...(anyGroupSelected ? this.grossLossSets : []),
            ]
          )
          this.grossLossSetSelection.emit(lossData)
        }
      }
    }
  }

  private getLossSetsToRemove(
    lossSetGroupMap: Record<string, string[]>,
    lossSetGroup: LossSetGroup
  ) {
    const otherLossSetsFromGroups: string[] = []
    Object.keys(lossSetGroupMap).forEach(k => {
      if (k === lossSetGroup.id) {
        return
      }
      if (this.isGroupSelected(k)) {
        otherLossSetsFromGroups.push(...lossSetGroupMap[k])
      }
    })
    const lossSetsToRemove = lossSetGroup.lossSetLayers
      .filter(l => !otherLossSetsFromGroups.includes(l.id))
      .map(l => l.id)
    return lossSetsToRemove
  }

  private getSpecialLossSetsToRemove(
    lossSetGroupMap: Record<string, string[]>,
    lossSetGroup: LossSetGroup
  ) {
    const otherLossSetsFromGroups: string[] = []
    Object.keys(lossSetGroupMap).forEach(k => {
      if (k === lossSetGroup.id) {
        return
      }
      if (this.isGroupSelected(k)) {
        otherLossSetsFromGroups.push(...lossSetGroupMap[k])
      }
    })
    const lossSetsToRemove = lossSetGroup.lossSetLayers
      .filter(
        l => !otherLossSetsFromGroups.includes((l.loss_sets[0] as LayerRef).id)
      )
      .map(l => (l.loss_sets[0] as LayerRef).id)
    return lossSetsToRemove
  }

  deleteGroup(lossSetGroup: LossSetGroup) {
    this.deleteLossSetGroupClick.emit(lossSetGroup)
  }

  onNewClick() {
    this.newLossSetGroupClick.emit()
  }

  onCancelClick() {
    this.cancelLossSetGroupClick.emit()
  }

  onGroupNameEdit() {
    if (
      this.lossSetGroupEditMode === 'edit' &&
      !this.currentSelectedGroup?.dirty
    ) {
      this.lossSetGroupSetDirty.emit(this.lossSetSelectedGroup)
    }
  }

  onSaveClick(groupName: string) {
    if (!this.isSaveDisabled) {
      // Create New Group
      if (this.lossSetGroupEditMode === 'new') {
        const lossSetGroup: OmitID<LossSetGroup> = {
          name: groupName,
          programID: this.programID,
          lossSetLayers: this.groupEditSelectedLossSets,
        }
        this.saveLossSetGroupClick.emit(lossSetGroup)
      }
      if (this.lossSetGroupEditMode === 'edit') {
        const lossSetGroup: LossSetGroup = {
          // tslint:disable-next-line: no-non-null-assertion
          id: this.currentSelectedGroup!.lossSetGroup.id,
          name: groupName,
          programID: this.programID,
          lossSetLayers: this.groupEditSelectedLossSets,
        }
        this.updateLossSetGroupClick.emit(lossSetGroup)
      }
    }
  }

  onScaleClick() {
    if (this.designDirty === false) {
      this.scaleLossSetDialog.open()
    }
  }

  onAutoGroupClick() {
    if (this.designDirty === false) {
      this.autoGroupLossSetsDialog.open()
    }
  }
  sortLossGroups(): LossSetGroupEntity[] {
    return this.lossSetGroups.sort((a, b) => {
      return a.lossSetGroup.name.localeCompare(b.lossSetGroup.name)
    })
  }
}

const toRecord = <T>(
  lossSetGroupEntities: LossSetGroupEntity[],
  transform: (
    p: Record<string, T[]>,
    e: LossSetGroupEntity
  ) => Record<string, T[]>
): Record<string, T[]> => {
  return lossSetGroupEntities.reduce(transform, {} as Record<string, T[]>)
}

const lossSetTransform = (
  prev: Record<string, string[]>,
  current: LossSetGroupEntity
) => {
  prev[current.lossSetGroup.id] = current.lossSetGroup.lossSetLayers.map(
    l => l.id
  )
  return prev
}

const lossSetSpecialTransform = (
  prev: Record<string, string[]>,
  current: LossSetGroupEntity
) => {
  prev[current.lossSetGroup.id] = current.lossSetGroup.lossSetLayers.map(
    l => (l.loss_sets[0] as LayerRef).id
  )
  return prev
}

const validateGroupSelection = (
  lossSetLayers: string[],
  cededLossSets: string[],
  lossSetGroupMap: Record<string, string[]>
) => {
  const currentGroupSelected =
    intersection(lossSetLayers, cededLossSets).length === lossSetLayers.length
  if (currentGroupSelected) {
    const partOfGroup: string[] = []
    Object.keys(lossSetGroupMap).forEach(k => {
      const l = lossSetGroupMap[k]
      const intersect = intersection(l, cededLossSets)
      if (intersect.length === l.length) {
        partOfGroup.push(...intersect)
      }
    })
    if (uniq(partOfGroup).length === cededLossSets.length) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
