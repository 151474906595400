import { TrackingData, TrackingSelectors } from "./tracking.model"

// Function to parse date from "dd-mmm-yyyy" format
export function parseDate(dateString: string | null): Date | null {
  if (!dateString) {
    return null
  }
  const parts = dateString.split('-')
  const day = parseInt(parts[0], 10)
  const month = getMonthIndex(parts[1]) // Convert month name to index
  const year = parseInt(parts[2], 10)
  return new Date(year, month, day)
}

// Helper function to get month index from string
function getMonthIndex(monthName: string): number {
  const monthMap: { [key: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  }
  return monthMap[monthName] !== undefined ? monthMap[monthName] : -1 // Return -1 for invalid month
}

export function getSelectors(
  selector: TrackingSelectors,
  trackingData: TrackingData[]
): TrackingSelectors {
  let updatedselector = JSON.parse(JSON.stringify(selector))
  const clientName = new Set<string>()
  const year = new Set<string>()
  const businessUnit = new Set<string>()
  const complete = new Set<string>()
  trackingData.forEach(item => {
    clientName.add(item.clientName)
    year.add(item.year)
    businessUnit.add(item.businessUnit)
    complete.add(item.complete)
  })
  const sortedClientName = Array.from(clientName).sort()
  const sortedYear = Array.from(year).sort((a, b) => {
    if (a < b) {
      return 1
    } else if (a > b) {
      return -1
    } else {
      return 0
    }
  })
  const sortedBusinessUnit = Array.from(businessUnit).sort()
  updatedselector.clientName.values = Array.from(sortedClientName)
  updatedselector.clientName.allValues = Array.from(sortedClientName)
  updatedselector.year.values = Array.from(sortedYear)
  updatedselector.year.allValues = Array.from(sortedYear)
  updatedselector.businessUnit.values = Array.from(sortedBusinessUnit)
  updatedselector.businessUnit.allValues = Array.from(sortedBusinessUnit)
  updatedselector.complete.values = Array.from(complete)
  updatedselector.complete.allValues = Array.from(complete)

  return updatedselector
}

export const sortByInceptionDate = (array: TrackingData[]) => {
  return array.sort((a, b) => {
    const aSubstring = parseDate(a.inceptionDate).getTime()
    const bSubstring = parseDate(b.inceptionDate).getTime()

    if (aSubstring < bSubstring) return 1
    if (aSubstring > bSubstring) return -1
    return 0
  })
}
