import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ContactSupportButtonDialogComponent } from '../contact-support-button-support-dialog/contact-support-button-dialog.component'
import { Store } from '@ngrx/store'
import { ContactSupport } from 'src/app/contact-support/model/contact-support.model'
import { sendSupportEmail } from 'src/app/contact-support/store/contact-support.actions'
import { Client, ClientYear } from 'src/app/core/model/client.model'
import { Study } from 'src/app/core/model/study.model'
import { SalesforceUser, User } from 'src/app/api/model/backend.model'
import { IControl } from 'src/app/pricingcurve/model/pricing-curve.model'

@Component({
  selector: 'app-contact-support-icon',
  templateUrl: './contact-support-icon.component.html',
  styleUrls: ['./contact-support-icon.component.scss'],
})
export class ContactSupportIconComponent {
  constructor(
    public dialog: MatDialog,
    public store: Store
  ) {}
  @Input() sageUserName: string | null
  @Input() currentClient: Client | null
  @Input() currentYear: ClientYear | null
  @Input() currentProgram: Study | undefined
  @Input() allUsers: SalesforceUser[]
  @Input() userEmailListSelector: IControl
  @Output() supportEmailClick = new EventEmitter<{
    contactSupport: ContactSupport
  }>()

  onSupportIconClick() {
    const data = {
      client: this.currentClient,
      currentYear: this.currentYear,
      currentProgram: this.currentProgram,
      usersList: this.allUsers,
      userEmailListSelector: this.userEmailListSelector,
    }
    const dialogRef = this.dialog.open(ContactSupportButtonDialogComponent, {
      minWidth: '40vw',
      width: '40vw',
      maxWidth: '40vw',
      data,
    })
    dialogRef.componentInstance.supportEmailClick.subscribe($event => {
      this.store.dispatch(
        sendSupportEmail({ contactSupport: $event.contactSupport })
      )
    })
  }

  isLocktonUser() {
    let domainName = this.sageUserName.split('@')[1]
    const isLocktonUser = domainName
      ?.toUpperCase()
      .endsWith('lockton.com'.toUpperCase())
    return isLocktonUser
  }
}
