import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core'
import { ReinsurerTrackingSubjectivity, reinsurerTrackingSubjectivityColumns } from '../models/quote.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { LAYER_PALETTE } from 'src/app/analysis/model/layer-palette.model'
import { formatDate } from 'src/app/reinsurers/utils/reinsurer.util'
import { clone } from 'ramda'

interface Filter {
  name: string
  label: string
  options: string[]
  selectedValues: string[]
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-reinsurer-subjectivity-dialog',
  templateUrl: 'reinsurer-subjectivity-dialog.component.html',
  styleUrls: ['reinsurer-subjectivity-dialog.component.scss'],
})
export class QuoteReinsurerSubjectivityComponent implements OnInit, OnChanges{
  @Input() subjectivities: ReinsurerTrackingSubjectivity[]
  @Input() isLoading: boolean
  @Input() reinsurerName: string
  filteredSubjectivities: ReinsurerTrackingSubjectivity[]
  columnDefs: SortTableColumnDef[] = reinsurerTrackingSubjectivityColumns
  formattedSubjectivities: ReinsurerTrackingSubjectivity[]
  menuSelection: string = 'Quote'

  filters: Filter[] = [
    { name: 'clients', label: 'Clients', options: [], selectedValues: [] },
    { name: 'years', label: 'Years', options: [], selectedValues: [] },
    { name: 'programs', label: 'Programs', options: [], selectedValues: [] },
    { name: 'structures', label: 'Structures', options: [], selectedValues: [] },
  ]
  menuItems = [
    { value: 'Quote', selected: true },
    { value: 'FOT', selected: false },
    { value: 'Signature', selected: false },
  ]

  @Output() closeDialog = new EventEmitter()

  ngOnInit(): void {
    this.populateFilters()
    this.applyFilters()
  }

  ngOnChanges(): void {
    this.populateFilters()
    this.applyFilters()
  }


  setMenuSelection(selection: string): void {
    this.menuSelection = selection
    let updatedMenuItems = clone(this.menuItems)
    updatedMenuItems.forEach((item) => {
        if(item.value === this.menuSelection) {
          item.selected = true
        } else {
          item.selected = false
        }
      })
    this.menuItems = updatedMenuItems
    this.applyFilters()
  }

  formatSubjectivities(subjectivities: ReinsurerTrackingSubjectivity[]): ReinsurerTrackingSubjectivity[] {
    const formatted = subjectivities.map(sub => {
      const layerType = LAYER_PALETTE.filter(l => l.id === sub.layerType)[0]?.name ?? sub.layerType
      const deadline = sub.deadline ? formatDate(String(sub.deadline)) : undefined
      return {
        ...sub,
        layerType,
        deadline
      }
    })
    const filtered = formatted.filter(sub => {
      const matchesFilter =
        (this.menuSelection === 'FOT' && !!sub.ralId) ||
        (this.menuSelection === 'Signature' && !!sub.pageSetName) ||
        (this.menuSelection === 'Quote' && !sub.ralId && !sub.pageSetName)
      return matchesFilter
    })
    return filtered
  }

  populateFilters(): void {
    const yearSet = new Set<string>()
    const clientSet = new Set<string>()
    const programSet = new Set<string>()
    const structureSet = new Set<string>()

    this.subjectivities.forEach(s => {
      yearSet.add(String(s.year))
      clientSet.add(s.carrierName)
      programSet.add(s.programName)
      structureSet.add(s.structureName)
    })

    this.getFilterByName('years').options = Array.from(yearSet).sort()
    this.getFilterByName('clients').options = Array.from(clientSet).sort()
    this.getFilterByName('programs').options = Array.from(programSet).sort()
    this.getFilterByName('structures').options = Array.from(structureSet).sort()
  }

  getFilterByName(name: string): Filter {
    return this.filters.filter(filter => filter.name === name)[0]
  }

  applyFilters(): void {
    let result = this.formatSubjectivities(this.subjectivities)
    this.filteredSubjectivities = result
    const years = this.getFilterByName('years').selectedValues
    const clients = this.getFilterByName('clients').selectedValues
    const programs = this.getFilterByName('programs').selectedValues
    const structures = this.getFilterByName('structures').selectedValues
    if (years.length) {
      result = result.filter(s => years.includes(String(s.year)))
    }
    if (clients.length) {
      result = result.filter(s => clients.includes(s.carrierName))
    }
    if (programs.length) {
      result = result.filter(s => programs.includes(s.programName))
    }
    if (structures.length) {
      result = result.filter(s => structures.includes(s.structureName))
    }
    if (years.length || clients.length || programs.length || structures.length) {
      this.filteredSubjectivities = result
    }
  }

  updateFilter(filterName: string, selectedValues: any[]): void {
    this.getFilterByName(filterName).selectedValues = selectedValues
    this.applyFilters()
  }
}
