import { createAction, props } from '@ngrx/store'
import { LayerModelingDimensionChangeEvent } from '../layer-modeling.model'

export const restoreLayerModelingSettings = createAction(
  '[Layer Modeling] Restore Settings'
)

export const setLayerModelingDimensionProp = createAction(
  '[Layer Modeling] Set Dimension Prop',
  props<LayerModelingDimensionChangeEvent>()
)
