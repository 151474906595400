import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  HostListener,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from './size.mixin'

class ActionTextBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof ActionTextBase = mixinSize(
  ActionTextBase
)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-action-text',
  styles: [
    `
           :host {
             --action-text-size: var(--font-size);
     
             display: inline-flex;
             justify-content: center;
             font-size: var(--action-text-size);
             font-weight: var(--font-weight);
             /** Min width is 2x the inset padding subtracted from the height */
             min-width: calc(
               var(--action-text-size) + 0.5rem - 2 *
                 (var(--action-text-size) / 5 + 0.21rem)
             );
             border-radius: 5px;
             cursor: auto;
             height: calc(var(--action-text-size) + 0.5rem);
             line-height: calc(var(--action-text-size) + 0.5rem);
             padding-left: calc(var(--action-text-size) / 5 + 0.21rem);
             padding-right: calc(var(--action-text-size) / 5 + 0.21rem);
             outline: none;
           }
     
           :host.clickable {
             cursor: pointer;
           }
     
           :host.clickable:hover {
             background: var(--bg-lit);
           }
     
           label {
             color: var(--subtle);
             margin-right: var(--inset-small);
           }
     
           /* Size mixin */
     
           :host.mini {
             --action-text-size: var(--font-size-mini);
           }
     
           :host.tiny {
             --action-text-size: var(--font-size-tiny);
           }
     
           :host.small {
             --action-text-size: var(--font-size-small);
           }
     
           :host.big {
             --action-text-size: var(--font-size-big);
           }
     
           :host.huge {
             --action-text-size: var(--font-size-huge);
           }
     
           :host.gigantic {
             --action-text-size: var(--font-size-gigantic);
           }
     
           :host.massive {
             --action-text-size: var(--font-size-massive);
           }
         `,
  ],
  template: `
    <label *ngIf="label">{{ label }}</label
    ><ng-content></ng-content>
  `,
})
export class ActionTextComponent extends _SizeMixinBase
  implements CanSize, OnInit {
  @Input() label?: string

  @Output() actionClick = new EventEmitter()

  @HostBinding('class.clickable') clickable: boolean

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    this.actionClick.emit()
  }

  // Size mixin
  @Input() size: Size
  @Input() massive: boolean
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @Input() mini: boolean
  @HostBinding('class.massive')
  get isSizeMassive() {
    return this.size === 'massive'
  }
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  constructor(public elementRef: ElementRef) {
    super(elementRef)
  }

  ngOnInit() {
    this.clickable = this.actionClick.observers.length > 0
  }
}
