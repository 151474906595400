import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResponse } from '../model/api.model'
import { environment } from 'src/environments/environment'
import { catchAndHandleError, mapToMaybeData, mergeApiResponses } from '../util'
import { QuoteCustomCompareView } from 'src/app/quote/models/quote.model'
import { forkJoin, map, of } from 'rxjs'

const URL = `${environment.internalApi.base}${environment.internalApi.quoteCustomComparison}`

@Injectable({
  providedIn: 'root',
})
export class QuoteCustomComparisonService {
  constructor(private http: HttpClient) {}

  postCustomComparison(
    comparison: QuoteCustomCompareView
  ): ApiResponse<QuoteCustomCompareView> {
    return this.http.post<QuoteCustomCompareView>(URL, comparison).pipe(
      map(val => ({ ...val, dirty: false })),
      mapToMaybeData(),
      catchAndHandleError('POST Quote Custom Comparisons')
    )
  }

  putManyCustomComparisons(
    comparisons: QuoteCustomCompareView[]
  ): ApiResponse<QuoteCustomCompareView[]> {
    if (!comparisons.length) {
      return of({data: []})
    }
    const observables = comparisons.map(comparison =>
      this.putCustomComparison(comparison)
    )
    return forkJoin(observables).pipe(mergeApiResponses())
  }

  putCustomComparison(
    comparison: QuoteCustomCompareView
  ): ApiResponse<QuoteCustomCompareView> {
    return this.http.put<QuoteCustomCompareView>(URL, comparison).pipe(
      map(val => ({ ...val, dirty: false })),
      mapToMaybeData(),
      catchAndHandleError('PUT Quote Custom Comparisons')
    )
  }

  getCustomComparisonsBySectionId(
    id: number
  ): ApiResponse<QuoteCustomCompareView[]> {
    return this.http
      .get<QuoteCustomCompareView[]>(`${URL}/byRiskSectionId/${id}`)
      .pipe(
        map(response =>
          response.map(val => ({
            ...val,
            dirty: false,
          }))
        ),
        mapToMaybeData(),
        catchAndHandleError('GET Quote Custom Comparisons')
      )
  }

  bulkDeleteComparisons(ids: number[]): ApiResponse<any> {
    if (!ids.length) {
      return of({data: []})
    }
    const observables = ids.map(id => this.deleteCustomComparison(id))
    return forkJoin(observables).pipe(mergeApiResponses())
  }

  deleteCustomComparison(id: number): ApiResponse<any> {
    return this.http
      .delete(`${URL}/${id}`)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Quote Custom Comparison')
      )
  }
}
