import { ElementRef } from '@angular/core'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-notes-component',
  styleUrls: ['../reinsurer-detail.component.scss'],
  templateUrl: './reinsurer-notes.component.html',
})
export class ReinsurerNotesComponent implements AfterViewInit {
  isInput = true
  @Input() notesModel: string
  @Input() studyID: string

  @Output() setNotesModel = new EventEmitter<string>()
  @Output() setReinsurer = new EventEmitter<void>()

  @ViewChildren('textNote', {}) private textNote: QueryList<ElementRef>

  constructor() {}

  ngAfterViewInit(): void {
    this.textNote.changes.subscribe((txtNote: QueryList<ElementRef>) => {
      if (txtNote.length > 0) {
        txtNote.first.nativeElement.focus()
      }
    })
  }

  inputFocus(): void {
    this.isInput = false
  }

  textareaFocusOut(): void {
    this.isInput = true
    this.setReinsurer.emit()
  }
}
