import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core'
import {
  ScenarioEvent,
  ScenarioEventChange,
  AddScenarioEventPayload,
  ScenarioEventResult,
} from '../animated-scenarios.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { Recorder } from '../../../api/animated-scenarios/recorder'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { Program } from 'src/app/core/model/program.model'
import { SharedIDPortfolio } from '../../model/portfolio-set.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import * as d3 from 'd3'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { getLossSetNames } from '../util/tower.3d.model'
import { Study } from '../../../core/model/study.model'
import { currencySymbol } from '../../model/layers.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-animated-scenarios',
  styleUrls: ['./animated-scenarios.component.scss'],
  templateUrl: './animated-scenarios.component.html',
})
export class AnimatedScenariosComponent implements OnInit, OnDestroy {
  @Input() scenarioEvents: ScenarioEvent[]
  @Input() lossSets: LossSetLayer[]
  @Input() scenarioEventsResult: ScenarioEventResult[]
  @Input() structure: Program | null
  @Input() sharedIDPortfolio: SharedIDPortfolio[]
  @Input() executing: boolean
  @Input() originalLayers: LayerState | null
  @Input() originalStructure: Program
  @Input() animating: boolean
  @Input() studies: Study[]
  @Input() name: string
  @Input() currentCurrency: string

  @Output() scenarioEventChange = new EventEmitter<ScenarioEventChange>()
  @Output() addScenarioEvent = new EventEmitter<AddScenarioEventPayload>()
  @Output() scenarioEventDelete = new EventEmitter<number>()
  @Output() processEvents = new EventEmitter()
  @Output() animationEnd = new EventEmitter()
  @Output() reAnimate = new EventEmitter()

  private destroy$ = new Subject()
  configGroup: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    watchOverflow: true,
    touchStartPreventDefault: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  isRecording: boolean
  constructor(
    private recorderService: Recorder,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.recorderService.isRecording
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.isRecording = val
        this.ref.detectChanges()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  get lossSetNames() {
    return getLossSetNames(this.lossSets)
  }

  get currencySymbol(): string {
    return currencySymbol(this.currentCurrency)
  }

  onScenarioEventAdd(): void {
    const newEvent = this.lossSetNames.reduce((acc, next) => {
      acc[next] = 0
      return acc
    }, {} as AddScenarioEventPayload)
    this.addScenarioEvent.emit(newEvent)
  }

  onProcessEvents(): void {
    if (this.scenarioEvents.length > 0) {
      this.processEvents.emit()
    }
  }

  onReAnimate(): void {
    if (this.scenarioEventsResult.length > 0) {
      this.reAnimate.emit()
      this.scenarioEventsResult = [...this.scenarioEventsResult]
    }
  }

  onRecord(): void {
    this.recorderService.startRecording(this.blobHandler)
  }

  onStopRecord(): void {
    this.recorderService.stopRecording()
  }

  private blobHandler(blob: Blob): void {
    const url = window.URL.createObjectURL(blob)
    const videoLink = d3
      .select('body')
      .append('a')
      .style('display', 'none')
      .attr('href', url)
      .attr('download', `animated-loss-${new Date().getTime()}.webm`)
      .attr('id', 'animated-loss-video')
    const element = videoLink.node()
    if (element) {
      element.click()
      videoLink.remove()
    }
  }
}
