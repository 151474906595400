<app-agency-detail-dialog-component
  [reinsurer]="data.reinsurer"
  [reinsurers]="reinsurers$ | async"
  [studyID]="studyID$ | async"
  [studies]="studies$ | async"
  [reinsurerID]="data.reinsurer.id"
  [agencyDetails]="data.agencyDetails"
  [savingReinsurer]="savingReinsurer$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [select]="data.select"
  (setReinsurer)="onSetReinsurer($event)"
  (updateOrAddDirty)="onUpdateOrAddDirty($event)"
  (deselectAgencyRelationship)="onDeselectAgencyRelationship($event)"
  (saveClick)="onSaveClick()"
  (saveNewOBO)="onSaveNewOBO($event)"
>
</app-agency-detail-dialog-component>
