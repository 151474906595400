import { Action, createReducer, on } from '@ngrx/store'
import * as fromContactSupportActions from './contact-support.actions'
import { SalesforceUser, User } from 'src/app/api/model/backend.model'
import { IControl } from '../model/contact-support.model'
import { getUserSelector } from '@shared/contact-support/contact-support.util'

export const FEATURE_KEY = 'contactSupport'

export interface ContactSupportState {
  emailResponse: string
  allUsersContactsList: SalesforceUser[]
  userEmailList: IControl
}

export type State = ContactSupportState

export const initialState: State = {
  emailResponse: '',
  allUsersContactsList: [],
  userEmailList: {
    columnName: 'userEmailList',
    name: 'userEmailList',
    id: 'userEmailList',
    selectedValues: [],
    values: [],
    allValues: [],
  },
}

const contactSupportReducer = createReducer(
  initialState,
  on(
    fromContactSupportActions.sendSupportEmailSuccess,
    (state: ContactSupportState, { response }) => {
      return { ...state, emailResponse: response }
    }
  ),
  on(
    fromContactSupportActions.fetchAllUsersSuccess,
    (state: ContactSupportState, { allUSers }) => {
      return {
        ...state,
        allUsersContactsList: allUSers,
        userEmailList: getUserSelector(state.userEmailList, allUSers),
      }
    }
  )
)

export function reducer(
  state: ContactSupportState | undefined,
  action: Action
) {
  return contactSupportReducer(state, action)
}
