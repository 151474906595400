import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { Subject } from 'rxjs'
import { CompareMetricTableCategory } from '../../analysis/model/compare-metrics.model'
import { AccountOpportunity } from '../../api/model/backend.model'
import {
  AGENCY_MARKET_USE,
  FUND_MANAGER_MARKET_USE,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { internationalDateApprovalNeeded, isReinsurerOutsideOpportunityDate, parseDate } from '../utils/reinsurer.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-banner-container',
  styleUrls: ['./reinsurer-banner.container.scss'],
  templateUrl: './reinsurer-banner.container.html',
})
export class ReinsurerBannerContainerComponent implements OnDestroy {
  @Input() reinsurer: Reinsurer
  @Input() programs: Study[]
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() metricDetails: CompareMetricTableCategory[]
  @Input() selectedProgramID: string | null
  @Input() programID: string
  @Input() duplicateReinsurers: string[]
  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() showFundManagerModal = new EventEmitter<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()

  // imageURI: string
  readonly invalidDate = parseDate(
    '1900-01-01T00:00:00',
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  private destroy$ = new Subject()

  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  getCardTypeStyle(reinsurer: Reinsurer): string {
    if (reinsurer.doNotUseInd) {
      return 'do_not_use_card'
    } else if (reinsurer.market_use === FUND_MANAGER_MARKET_USE) {
      return 'fund_manager_card'
    } else if (this.isActionRequired(reinsurer)) {
      return 'action_required'
    } else if (reinsurer.market_use === AGENCY_MARKET_USE) {
      return 'agency_card'
    } else {
      return 'reinsurer-card'
    }
  }

  isActionRequired(reinsurer: Reinsurer): boolean {
    const selectedProgramOpportunityId = this.programs.find(
      f => f.id === this.selectedProgramID
    )?.opportunity_id

    // for any programs that aren't connected to a Salesforce opportunity, never show that action is required per ADO #13794
    if (!selectedProgramOpportunityId) {
      return false
    }

    const intlApprovalNeeded = internationalDateApprovalNeeded(reinsurer)

    const approvalNeeded = intlApprovalNeeded ? !reinsurer.internationalReinsuranceApprovalStatus || reinsurer.internationalReinsuranceApprovalStatus !== 'ACT' : !reinsurer.reinsuranceApprovalStatus || reinsurer.reinsuranceApprovalStatus !== 'ACT'

    const invalidApprovalDates = isReinsurerOutsideOpportunityDate(reinsurer, this.programs, this.accountOpportunities, this.selectedProgramID, intlApprovalNeeded)

    if (approvalNeeded || invalidApprovalDates) {
      return true
    }

    return false
  }
}


