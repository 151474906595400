import { createSelector } from '@ngrx/store'
import { chain } from 'ramda'
import { AppState } from '.'
import { Client } from '../model/client.model'
import { Study } from '../model/study.model'
import * as fromClients from './clients.reducer'

export const selectClientsState = (state: AppState) => state.clients

export const { selectAll: selectClients } =
  fromClients.adapter.getSelectors(selectClientsState)

export const selectClientsByID = createSelector(
  selectClientsState,
  state => state.entities as Record<string, Client>
)

export const getClientStudyWithID = (
  studyID: string,
  client: Client
): Study | undefined =>
  chain(y => y.studies as Study[], client.clientYears).find(
    s => s.id === studyID
  )

export const selectSecurity = (state: AppState) => state.auth.security

export const selectCarriers = (state: AppState) => state.auth.carriers

export const selectStudies = (state: AppState) => state.auth.studies
