import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { NavService } from '../../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-compare-route',
  template: `
    <app-group-container *ngIf="name === 'group'"></app-group-container>
    <app-compare-container *ngIf="name === 'compare'"></app-compare-container>
  `,
})
export class GroupCompareRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  name: 'group' | 'compare'

  constructor(
    private route: ActivatedRoute,
    private nav: NavService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      // Expects data.contextPath: ['analysis', 'group'/'compare']
      this.name =
        (data &&
          data.contextPath &&
          data.contextPath.length > 1 &&
          data.contextPath[1]) ||
        'group'
      this.cd.markForCheck()
    })
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
