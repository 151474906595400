import { createAction, props } from '@ngrx/store'
import { Layer } from 'src/app/analysis/model/layers.model'
import { Portfolio } from '../../../../api/analyzere/analyzere.model'
import { ApplicationError } from '../../../../error/model/error'
import {
  SharedLimit,
  SharedLimitMember,
  SharedLimitLayerSelection,
  SharedLimitProperties,
} from '../program-group.model'

export const openNewSharedLimit = createAction(
  '[Grouper Shared Limit] Open New'
)

export const openEditSharedLimit = createAction(
  '[Grouper Shared Limit] Open Edit',
  props<{ layer: Layer; selectedLayer: string; selectedProgram: string }>()
)

export const cancelSharedLimit = createAction('[Grouper Shared Limit] Cancel')

// Layer Selection
export const toggleSharedLimitLayerSelection = createAction(
  '[Grouper Shared Limit] Toggle Layer Selection',
  props<SharedLimitLayerSelection>()
)

export const clearSharedLimitLayerSelection = createAction(
  '[Grouper Shared Limit] Clear Layer Selection'
)

// Set Properties
export const setSharedLimitProperties = createAction(
  '[Grouper Shared Limit] Set Properties',
  props<{ properties: SharedLimitProperties }>()
)

// Add
export const addSharedLimit = createAction(
  '[Grouper Shared Limit] Add',
  props<{ layer: Layer; selectedLayerEntities: SharedLimitLayerSelection[] }>()
)

export const addSharedLimitSuccess = createAction(
  '[Grouper Shared Limit] Add Success',
  props<{
    cededPortfolios: Portfolio[]
    netPortfolios: Portfolio[]
  }>()
)

export const addSharedLimitFailure = createAction(
  '[Grouper Shared Limit] Add Failure',
  props<{ error: ApplicationError }>()
)

// Delete
export const deleteSharedLimit = createAction(
  '[Grouper Shared Limit] Delete',
  props<{ sharedLimitLayer: Layer }>()
)

export const deleteSharedLimitFailure = createAction(
  '[Grouper Shared Limit] Delete Failure',
  props<{ error: ApplicationError }>()
)

export const deleteSharedLimitSuccess = createAction(
  '[Grouper Shared Limit] Delete Success',
  props<{ cededPortfolios: Portfolio[]; netPortfolios: Portfolio[] }>()
)

// Update
export const updateSharedLimit = createAction(
  '[Grouper Shared Limit] Update',
  props<{
    sharedLimitLayers: Layer[]
    deletedNestedBackAllocatedLayers: Layer[]
  }>()
)

export const updateSharedLimitFailure = createAction(
  '[Grouper Shared Limit] Update Failure',
  props<{ error: ApplicationError }>()
)

export const updateSharedLimitSucccess = createAction(
  '[Grouper Shared Limit] Update Success',
  props<{ cededPortfolios: Portfolio[]; netPortfolios: Portfolio[] }>()
)

export const updateSharedLimitProperties = createAction(
  '[Grouper Shared Limit] Update Properties',
  props<{ sharedLimitLayer: Layer }>()
)

export const updateSLState = createAction(
  '[Grouper Shared Limit] Update SL State',
  props<{
    sharedLimits: SharedLimit[] | null
    sharedLimitMembers: SharedLimitMember[] | null
  }>()
)

// Update Shared limit layer in auth object
export const updateSL = createAction(
  '[Grouper Shared Limit] Update SL',
  props<{
    sharedLimit: SharedLimit
  }>()
)

export const updateSLSuccess = createAction(
  '[Grouper Shared Limit] Update SL Success',
  props<{
    sharedLimit: SharedLimit
  }>()
)

export const updateSLFailure = createAction(
  '[Grouper Shared Limit] Update SL Failure',
  props<{
    error: ApplicationError
  }>()
)
