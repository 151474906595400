import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from '../../api/backend/backend.service'
import { Program } from '../../core/model/program.model'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { CheckboxSelectComponent } from '@shared/checkbox-select.component'
import { Size } from '@shared/size.mixin'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-structure-card-container',
  styleUrls: ['./structure-card.container.scss'],
  templateUrl: './structure-card.container.html',
})
export class StructureCardContainerComponent implements OnInit, OnDestroy {
  @Input() index: number
  @Input() structure: Program
  @Input() scenarios: Program[]
  @Input() scenariosSelected: Record<string, boolean>
  @Input() optimizations: Program[]
  @Input() optimizationsSelected: Record<string, boolean>
  @Input() isExplore = false
  @Input() allowScenarioOrOptimizationSelection: boolean
  @Input() selectMultiple: boolean
  @Input() checked: boolean
  @Input() indicateFocus: boolean
  @Input() elevation: any
  @Input() isEditMode = false
  @Input() selectedStructureIDs: string[]
  @Input() editFolderMode = false
  @Input() canCheck = false
  @Input() checkboxTooltip: string
  imageURI: string

  private destroy$ = new Subject()

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() structureClick = new EventEmitter<void>()
  @Output() editClick = new EventEmitter<void>()
  @Output() scenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<Program>
  >()
  @Output() structureCheckChange = new EventEmitter<{
    structureId: string
  }>()

  @Output() addStructure = new EventEmitter<string>()

  @Output() removeFromFolder = new EventEmitter<{
    structureId: string
  }>()

  @ViewChild(CheckboxSelectComponent)
  checkboxSelect?: CheckboxSelectComponent<Program>

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.backendService
      // tslint:disable-next-line: no-non-null-assertion
      .getThumbnailImage(this.structure.imageSrc!, this.structure.lastModified)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.createImageFromBlob(data)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createImageFromBlob(image: any): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }

  onRemoveFromFolder(): void {
    this.removeFromFolder.emit({ structureId: this.structure.id })
  }
}
