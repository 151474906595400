import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as ENV } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import {
  catchAndHandleError,
  encodeParams,
  mapResponse,
  mapToMaybeData,
} from '../util'
import {
  DataCounts,
  QuoteSelectorsDto,
  QuoteSummaryData,
} from './../../quote/management-information/model/quote-management-information.model'
import {
  QuoteChartAxis,
  QuoteChartData,
  QuoteChartResponse,
} from '../../quote/management-information/model/quote-charts.model'
import { QuoteDetailsData } from '../../quote/management-information/model/quote-details.model'
import { QuoteRankingData } from '../../quote/management-information/model/quote-ranking.model'

const API = ENV.internalApi

const enum QuoteMIRoutes {
  FILTERS = '/filters',
  SUMMARIES = '/summaries',
  DETAILS = '/details',
  CHART = '/chart',
  TREND = '/trend',
  RANKING = '/ranking',
  COUNTS = '/data-counts',
  REFRESHDATE = '/lastRefreshDate',
}

@Injectable({
  providedIn: 'root',
})
export class QuoteManagementInformationService {
  constructor(private http: HttpClient) {}

  getQuoteSelectors(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<QuoteSelectorsDto> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.FILTERS}`
    return this.http
      .get<QuoteSelectorsDto>(url, { params: encodeParams(params) })
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quote Selectors'))
  }

  getLastRefreshDate(): ApiResponse<string> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.REFRESHDATE}`
    return this.http
      .get<string>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Last Refresh date'))
  }

  getQuoteSummaryData(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<QuoteSummaryData> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.SUMMARIES}`
    return this.http
      .get<QuoteSummaryData>(url, { params: encodeParams(params) })
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quote Summaries'))
  }

  getQuoteDetailsData(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<QuoteDetailsData> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.DETAILS}`
    return this.http
      .get<QuoteDetailsData>(url, { params: encodeParams(params) })
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quote Details'))
  }

  getQuoteRankingData(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<QuoteRankingData> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.RANKING}`
    return this.http
      .get<QuoteRankingData>(url, { params: encodeParams(params) })
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quote Ranking'))
  }

  getQuoteDataCounts(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<DataCounts> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.COUNTS}`
    return this.http
      .get<DataCounts>(url, { params: encodeParams(params) })
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quote Counts'))
  }

  getQuoteChartData(
    params: Record<string, string | string[]>,
    index: number,
    groupBy: QuoteChartAxis
  ): ApiResponse<QuoteChartResponse> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.CHART}`
    return this.http
      .get<QuoteChartData>(url, { params: encodeParams(params) })
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Get Quote Chart'),
        mapResponse(data => ({ data, index, groupBy }))
      )
  }

  getQuoteTrendData(
    params: Record<string, string | string[]>,
    index: number,
    groupBy: QuoteChartAxis
  ): ApiResponse<QuoteChartResponse> {
    const url = `${API.base}${API.quoteManagementInformation}${QuoteMIRoutes.TREND}`
    return this.http
      .get<QuoteChartData>(url, { params: encodeParams(params) })
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Get Quote Trend'),
        mapResponse(data => ({ data, index, groupBy }))
      )
  }
}
