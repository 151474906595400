import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import {
  TopDropProperties,
  CreateTopHiddenLayers,
} from '../../properties-panel.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../../../core/store'
import {
  addLayer,
  setSelectedLayer,
  addDropLayer,
  updatePhysicalLayer,
  deleteDropLayer,
  updateLayer,
} from 'src/app/analysis/store/ceded-layers/layers.actions'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import { Observable } from 'rxjs'
import {
  selectParentGrossLossSetLayers,
  selectCededLayers,
  selectCurrentStructureID,
  selectIsNewDropLayerSaving,
} from 'src/app/analysis/store/analysis.selectors'
import { Layer, PhysicalLayer } from 'src/app/analysis/model/layers.model'
import { tap } from 'rxjs/operators'
import { setIsNewDropLayerSaving } from 'src/app/analysis/store/analysis.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-top-drop-dialog-container',
  template: `
    <app-top-drop-dialog-component
      [topLayerSet]="topLayer"
      [dropLayerSets]="dropLayers"
      [hiddenLayerSet]="hiddenLayer"
      [lossSetLayers]="lossSetLayers$ | async"
      [currentStructureID]="currentStructureID$ | async"
      [isNewDropLayerSaving]="isNewDropLayerSaving$ | async"
      (createTopHiddenLayers)="onCreateTopHiddenLayers($event)"
      (physicalLayerChange)="physicalLayerChange($event)"
      (layerChange)="onLayerChange($event)"
      (createDropLayer)="onCreateDropLayer()"
      (deleteDropLayer)="onDeleteDropLayer($event)"
    >
    </app-top-drop-dialog-component>
  `,
})
export class TopDropDialogContainerComponent implements OnInit, OnDestroy {
  lossSetLayers$: Observable<LossSetLayer[]>
  currentStructureID$: Observable<string | null>
  isNewDropLayerSaving$: Observable<boolean>

  private destroy$ = new Subject()

  topLayer: LayerState
  dropLayers: LayerState[] = []
  hiddenLayer: LayerState | null = null

  constructor(
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: TopDropProperties,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.topLayer = this.data.top
    this.lossSetLayers$ = this.store.pipe(
      select(selectParentGrossLossSetLayers)
    )

    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectCededLayers),
        tap((layers: LayerState[]) => {
          if (this.topLayer.layer.meta_data.isDrop) {
            const topLayer = layers.find(
              ({ layer }) => this.topLayer.layer.meta_data.topID === layer.id
            )
            this.topLayer = topLayer ?? this.topLayer
          }
          const hiddenLayer = layers.find(layer =>
            layer.layer.layerRefs.includes(this.data.top.layer.id)
          )
          this.hiddenLayer = hiddenLayer ?? null
          this.dropLayers = layers.filter(
            ({ layer, deleted }) =>
              layer.meta_data.isDrop &&
              layer.meta_data.topID === this.topLayer.layer.id &&
              !deleted
          )
          this.cdRef.markForCheck()
        })
      )
      .subscribe()

    this.isNewDropLayerSaving$ = this.store.pipe(
      select(selectIsNewDropLayerSaving)
    )
    this.currentStructureID$ = this.store.pipe(select(selectCurrentStructureID))
  }

  physicalLayerChange($event: {
    layer: Layer
    physicalLayerChange: Partial<PhysicalLayer>
  }): void {
    this.store.dispatch(
      updatePhysicalLayer({
        id: $event.layer.id.toString(),
        change: $event.physicalLayerChange,
        debounce: true,
      })
    )
  }

  onLayerChange($event: { layer: Layer; layerChange: Partial<Layer> }): void {
    this.store.dispatch(
      updateLayer({
        id: $event.layer.id.toString(),
        change: $event.layerChange,
        debounce: true,
      })
    )
  }

  onCreateDropLayer(): void {
    this.store.dispatch(setIsNewDropLayerSaving({ value: true }))
    this.store.dispatch(addDropLayer({ topLayer: this.topLayer.layer }))
  }

  onDeleteDropLayer(layerId: string): void {
    this.store.dispatch(deleteDropLayer({ layerId }))
  }

  onCreateTopHiddenLayers(event: CreateTopHiddenLayers) {
    const logicalIDTemp = new Date().getTime() + '' + Math.random() * 100000
    this.store.dispatch(
      addLayer({
        layer: {
          id: logicalIDTemp,
          meta_data: {
            client: event.topLayer.physicalLayer.meta_data.client,
            perspective: event.topLayer.physicalLayer.meta_data.perspective,
            program_name: event.topLayer.physicalLayer.meta_data.program_name,
            program_type: event.topLayer.physicalLayer.meta_data.program_type,
            rol: event.topLayer.physicalLayer.meta_data.rol,
            rol_type: event.topLayer.physicalLayer.meta_data.rol_type,
            sage_layer_type: 'drop',
            year: event.topLayer.physicalLayer.meta_data.year,
            lossfilter_name: '',
            isDrop: true, // ? Why
            topID: event.topLayer.id,
            sharedLimitHidden: event.sharedLimitHidden,
            layerName: 'New Layer',
            structureID: event.structureID,
          },
          layerRefs: [],
          lossSetFilter: '',
          lossSetLayers: event.lossSetLayers.map(a => ({
            id: a.id,
            meta_data: a.meta_data,
          })),
          physicalLayer: {
            logicalLayerID: logicalIDTemp,
            id: (new Date().getTime() + '' + Math.random() * 100000).replace(
              '.',
              ''
            ),
            type: event.topLayer.physicalLayer.type,
            attachment: {
              value: event.perOccAttDrop,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            limit: {
              value: event.perOccLimitDrop,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            participation: event.cessionDropLayer,
            premium: {
              value: event.premiumHidden,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            fees: [],
            aggregateLimit: {
              value: event.aggLimitDrop,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            aggregateAttachment: {
              value: event.aggAttDrop,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            description: 'New Layer',
            meta_data: {
              client: event.topLayer.physicalLayer.meta_data.client,
              perspective: event.topLayer.physicalLayer.meta_data.perspective,
              program_name: event.topLayer.physicalLayer.meta_data.program_name,
              program_type: event.topLayer.physicalLayer.meta_data.program_type,
              rol: event.topLayer.physicalLayer.meta_data.rol,
              rol_type: event.topLayer.physicalLayer.meta_data.rol_type,
              sage_layer_type: 'drop',
              year: event.topLayer.physicalLayer.meta_data.year,
              isDrop: true,
            },
            reinstatements: [],
            franchise: {
              value: 0,
              currency: event.topLayer.physicalLayer.franchise.currency,
            },
            xcoord: 469,
            ycoord: 110,
          },
          sharedLayerID: '',
          viewMetrics: {
            error: null,
            loading: false,
            metrics: null,
            rss: null,
          },
        },
      })
    )
    this.store.dispatch(
      setSelectedLayer({
        id: null,
      })
    )
  }
}
