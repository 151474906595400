import LayerMetricDefs, {
  LayerMetricDef,
  LayerMetricDefMap,
  LayerMetricDefResolved,
  resolveLayerMetricDef,
} from '../model/layer-metric-defs'
import { LayerViewValues } from '../model/layer-view'

export type LayerModelingProp = LayerMetricDefResolved & {
  id: keyof LayerViewValues
}

// const columnDefs = Object.keys(LayerMetricDefs)
interface PropDef extends Partial<LayerMetricDef> {
  id: keyof LayerViewValues
}

/* Configure the fields available on the Layer Modeling tab */
const columnDefs: PropDef[] = [
  { id: 'cessionPercentage' },
  { id: 'occurrenceLimit' },
  { id: 'occurrenceAttachment' },
  { id: 'aggregateLimit' },
  { id: 'aggregateAttachment' },
  { id: 'premium' },

  { id: 'expectedReinstatementPremium' },
  { id: 'expectedCededPremium' },
  { id: 'purePremium', label: 'Expected Ceded Loss' },
  { id: 'expectedCededMargin' },
  { id: 'expectedCededLossRatio' },

  { id: 'entryProbability' },
  { id: 'exitProbability' },
  { id: 'exitAggProbability' },

  { id: 'aeptVar100' },
  { id: 'aepVar100' },

  { id: 'grossVolatilityCeded', label: '% Gross Volatility Ceded' },
  { id: 'portfolioEfficiencyScore', label: 'Efficiency Score' },
]

const mergeMetricDef =
  (defs: LayerMetricDefMap) =>
  (prop: PropDef): LayerModelingProp => {
    const def = defs[prop.id]
    if (!def) {
      throw new Error(`Cannot find layer metric definition for '${prop.id}`)
    }
    const merged = { ...def, ...prop }
    return { ...resolveLayerMetricDef(merged) }
  }

export default columnDefs.map(mergeMetricDef(LayerMetricDefs))
