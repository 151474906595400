import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { values } from 'ramda'

export interface CheckboxSelectChangeEvent<T extends { id: string }> {
  add?: T[]
  remove?: T[]
  parent?: T
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-checkbox-select-button',
  styles: [
    `
      button {
        padding: 0 10px 2px;
      }

      :host.dropdown button,
      :host.dropdown-open button {
        padding-right: var(--inset-tiny);
      }

      button .wrapper {
        display: flex;
        align-items: center;
        height: 24px;
      }

      :host mat-checkbox {
        position: relative;
        top: -1px;
        margin-right: var(--inset-small);
      }

      :host.accent mat-checkbox ::ng-deep .mat-mdc-checkbox-frame {
        border-color: var(--border-2);
        opacity: 0.6;
      }

      :host ::ng-deep .mat-mdc-checkbox .mdc-checkbox__background {
        border-color: var(--border-2) !important;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      :host.accent
        button.scenarios
        ::ng-deep
        .mat-checkbox-indeterminate.mat-accent
        .mat-checkbox-background,
      :host.accent
        button.scenarios
        ::ng-deep
        .mat-checkbox-checked.mat-accent
        .mat-checkbox-background {
        background-color: var(--accent-lit);
        border: 1px solid var(--accent);
      }

      mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-right: 0;
      }
    `,
  ],
  template: `
    <button
      appButton
      class="scenarios"
      [accent]="accent"
      (click)="onScenariosOrOptimizationsClick($event)"
    >
      <div class="wrapper">
        <mat-checkbox
          *ngIf="allowSelection"
          [checked]="checked"
          [indeterminate]="indeterminate"
          (click)="onScenariosOrOptimizationsCheckboxClick($event)"
        ></mat-checkbox>
        <span>{{ label }}</span>
        <mat-icon *ngIf="dropdown || dropdownOpen">{{ dropdownIcon }}</mat-icon>
      </div>
    </button>
  `,
})
export class CheckboxSelectButtonComponent<T extends { id: string }> {
  @Input() items: T[]
  @Input() selected: Record<string, boolean>
  @Input() parent?: T
  @Input() label: string

  // Allow Scenario/Optimization Selection - Shows the select all checkbox
  @Input() set allowSelection(value: any) {
    this._allowSelection = coerceBooleanProperty(value)
  }
  get allowSelection() {
    return this._allowSelection
  }
  _allowSelection = false

  // Use Accent color
  @Input() set accent(value: any) {
    this._accent = coerceBooleanProperty(value)
  }
  get accent() {
    return this._accent
  }
  @HostBinding('class.accent') _accent = false

  // Dropdown indicator
  @Input() set dropdown(value: any) {
    this._dropdown = coerceBooleanProperty(value)
  }
  get dropdown() {
    return this._dropdown
  }
  @HostBinding('class.dropdown') _dropdown = false

  // Dropdown is open indicator
  @Input() set dropdownOpen(value: any) {
    this._dropdownOpen = coerceBooleanProperty(value)
  }
  get dropdownOpen() {
    return this._dropdownOpen
  }
  @HostBinding('class.dropdown-open') _dropdownOpen = false

  @Output() selectChange = new EventEmitter<CheckboxSelectChangeEvent<T>>()
  @Output() buttonClick = new EventEmitter<void>()

  get selectCount(): number {
    return values(this.selected).filter(s => s === true).length
  }

  get checked(): boolean {
    return this.selectCount > 0
  }

  get indeterminate(): boolean {
    return this.checked && this.selectCount !== this.items.length
  }

  get dropdownIcon(): string {
    return this.dropdownOpen ? 'arrow_drop_up' : 'arrow_drop_down'
  }

  onScenariosOrOptimizationsClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    $event.stopPropagation()
    this.buttonClick.emit()
  }

  onScenariosOrOptimizationsCheckboxClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    $event.stopPropagation()

    const parent = this.parent

    if (this.checked && !this.indeterminate) {
      // Select All: All items selected, so deselect all
      this.selectChange.emit({ remove: this.items, parent })
    } else {
      // Deselect All: Select all items not currently selected
      const deselectedItems = this.items.filter(it => !this.selected[it.id])
      this.selectChange.emit({ add: deselectedItems, parent })
    }
  }
}
