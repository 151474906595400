import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { QuoteRouteContainerComponent } from './quote-route.container'
import { RoleGuard } from '../guards/role-guard.service'
import { CanDeactivateGuard } from '../guards/can-deactivate.guard'

const routes: Routes = [
  {
    path: 'main',
    component: QuoteRouteContainerComponent,
    data: { contextPath: ['quote', 'main'] },
    canActivate: [RoleGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'management-information',
    loadChildren: () =>
      import(
        './management-information/quote-management-information.module'
      ).then(m => m.QuoteManagementInformationModule),
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuoteRoutingModule {}
