export interface GraphingAxisExtents {
  /**
   * Outer pad amount (in terms of percent of range or domain value
   * depending on the `padUnit`). For grouped charts (e.g. `GroupBandBarChart`),
   * is used by the band scale axis's `paddingOuter` attribute
   * and only supports percent unit.
   */
  pad: [number, number]
  /**
   * Used only by grouped charts (e.g. `GroupBandBarChart`) band scale axes
   * as its `paddingOuterMember` attribute determining the ratio of the
   * range that is reserved for blank space between bands within a group.
   */
  padMember: number
  /**
   * Used only by grouped charts (e.g. `GroupBandBarChart`) band scale axes
   * as its `paddingInner` attribute determining the ratio of the range
   * that is reserved for blank space between band groups.
   */
  padInner: number
  padUnit: 'percent' | 'domain'
  /**
   * Forces the axis's scale to include the given array of domain values
   * in its extents. Primarily used with value `[0]` on the y-axis to
   * ensure that the origin (0 value) is shown on the chart regardless.
   */
  include: number[]
  /**
   * Causes the axis's extent min and max to be rounded to the nearest
   * 'nice' value using D3 scales' `nice()` function.
   */
  nice: boolean
}

export type GraphingAxisExtentsDef = Omit<
  Partial<GraphingAxisExtents>,
  'pad'
> & {
  pad?: number | [number, number]
}

export interface GraphingExtents {
  main: GraphingAxisExtents
  cross: GraphingAxisExtents
}

export interface GraphingExtentsDef {
  main?: GraphingAxisExtentsDef
  cross?: GraphingAxisExtentsDef
}

const DEFAULT_GRAPHING_EXTENT_AXIS_OPTIONS: GraphingAxisExtents = {
  pad: [0, 0],
  padMember: 0,
  padInner: 0,
  padUnit: 'percent',
  include: [],
  nice: false,
}

function mergeAxisDefaults(
  defaults?: GraphingAxisExtentsDef,
  config?: GraphingAxisExtentsDef
): GraphingAxisExtents {
  const pad =
    config?.pad ?? defaults?.pad ?? DEFAULT_GRAPHING_EXTENT_AXIS_OPTIONS.pad
  return {
    ...DEFAULT_GRAPHING_EXTENT_AXIS_OPTIONS,
    ...(defaults ?? {}),
    ...config,
    pad: Array.isArray(pad) ? pad : [pad, pad],
  }
}

export const withGraphingExtentsDefaults = (
  defaultDefs: GraphingExtentsDef,
  config?: GraphingExtentsDef
): GraphingExtents => ({
  main: mergeAxisDefaults(defaultDefs.main, config?.main),
  cross: mergeAxisDefaults(defaultDefs.cross, config?.cross),
})
