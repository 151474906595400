import { Injectable } from '@angular/core'
import {
  Fill,
  FillPattern,
  FillPatterns,
  Font,
  Row,
  Workbook,
  Worksheet,
  WorksheetView,
} from 'exceljs'
import { clamp } from 'three/src/math/MathUtils'
import {
  SortTableColumnDef,
  SortTableRow,
} from '../sort-table/sort-table.model'
import { saveAs } from 'file-saver'
import { TableOptions } from './services.model/excel-export.model'
import { formatCell, transformValue } from './services.utils/excel-export.utils'
import { locktonLogoBase64, sageLogoBase64 } from '@shared/util/export'

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  /** Returns a new Workbook [exceljs] object with Lockton defaults
   * @returns {Workbook} a new workbook with standerdized settings
   */
  public createWorkbook(): Workbook {
    const workbook = new Workbook()

    workbook.creator = 'Lockton'
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    return workbook
  }

  /** Returns a worksheet with standardized formatting, appended onto the @param workbook
   * @param workbook Workbook to append the sheet onto
   * @param sheetName Name of the new sheet to be created (bottom row/tabs in excel)
   * @param viewOptions Extra view options to add to worksheet definition
   * @returns Newly created worksheet with standardized formatting
   */
  public appendWorksheet(workbook: Workbook, sheetName: string, viewOptions: Partial<WorksheetView> = {}): Worksheet {
    const worksheet = workbook.addWorksheet(sheetName, {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false, ...viewOptions }],
    })

    worksheet.pageSetup.margins = {
      left: 0.2,
      right: 0.2,
      top: 0.5,
      bottom: 0.5,
      header: 0.3,
      footer: 0.3,
    }

    return worksheet
  }

  /** Adds a title row onto the worksheet [in place operation: meaning the worksheet passed in has its data manipulated]
   * @param worksheet Worksheet to add the title row onto
   * @param title text to printed in title font
   */
  public appendTitleRow(worksheet: Worksheet, title: string): void {
    const titleRow = worksheet.addRow([title])
    titleRow.font = {
      name: 'Segoe Pro',
      size: 16,
      color: { argb: '00AEEF' },
    }
  }

  /** Adds a subheader row onto the worksheet [in place operation: meaning the worksheet passed in has its data manipulated]
   * @param worksheet Worksheet to add the subheader row onto
   * @param subHeader text to printed in subheader font
   */
  public appendSubheaderRow(worksheet: Worksheet, subHeader: string): void {
    const titleRow = worksheet.addRow([subHeader])
    titleRow.font = {
      name: 'Segoe Pro',
      size: 8,
    }
  }

  /** Adds a hint row onto the worksheet [in place operation: meaning the worksheet passed in has its data manipulated]
   * @param worksheet Worksheet to add the hint row onto
   * @param hint text to printed in hint font
   */
  public appendHintRow(worksheet: Worksheet, hint: string): void {
    const titleRow = worksheet.addRow([hint])
    titleRow.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '636363' },
      italic: true,
    }
  }

  /** Add Logos to bottom of worksheet
   * @param worksheet Worksheet to add the hint row onto
   * @param hint text to printed in hint font
   */
  public appendLogos(
    worksheet: Worksheet,
    workbook: Workbook,
    locktonCol: number,
    sageCol: number
  ): void {
    const logoRow = worksheet.rowCount + 3
    const logo = workbook.addImage({
      base64: locktonLogoBase64,
      extension: 'png',
    })
    worksheet.addImage(logo, {
      tl: { col: locktonCol, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })
    const sageLogo = workbook.addImage({
      base64: sageLogoBase64,
      extension: 'png',
    })
    worksheet.addImage(sageLogo, {
      tl: { col: sageCol, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet.getCell(patentLinkRow, sageCol + 1)
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents',
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      },
    }
  }

  /** Adds a blank row(s) [in place operation: meaning the worksheet passed in has its data manipulated]
   * @param worksheet Worksheet to add the blank row onto
   * @param count Number of blank rows to add by default 1
   */
  public appendBlankRows(worksheet: Worksheet, count: number = 1): void {
    for (let i = 0; i < count; i++) {
      const row = worksheet.addRow([])
      row.font = {
        name: 'Segoe Pro',
        size: 8,
      }
    }
  }

  /** Adds a row of data to the end of the worksheet [in place operation: meaning the worksheet passed in has its data manipulated]
   * @param worksheet Worksheet to append the data row to
   * @param data The array of data to populate the data row
   * @param options Any styling options to apply to the data row
   * @returns The newly created row in case it is needed
   */
  public appendDataRow(
    worksheet: Worksheet,
    data: any[],
    options: Partial<{
      font: Partial<Font>
      fill: Fill
    }>
  ): Row {
    const row = worksheet.addRow(data)
    if (options.font) {
      row.font = options.font
    }
    if (options.fill) {
      row.fill = options.fill
    }
    return row
  }

  /** Formats each Columns in the sheet to either fit the widest cell, or the provided maxCellWidth
   * @param worksheet Worksheet to autosize
   * @param minCellWidth Minimum cell size. Defaults to 10
   * @param maxCellWidth Maximum size. Defaults to 100
   */
  public autoSizeColumns(
    worksheet: Worksheet,
    minCellWidth: number = 10,
    maxCellWidth: number = 100
  ): void {
    worksheet.columns.forEach(column => {
      let maxLength = 0
      if (column['eachCell']) {
        column.eachCell({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10
          if (columnLength > maxLength) {
            maxLength = columnLength
          }
        })
      }
      column.width = clamp(maxLength, minCellWidth, maxCellWidth)
    })
  }

  /** Appends an app-sort-table to the excel sheet, handles formatting and styling. See TableOptions to read more custom styling and custom number transformations on this table
   * @param worksheet Worksheet to append table to.
   * @param columns Columns from the app-sort-table.
   * @param rows Rows from the app-sort-table.
   * @param options Styling and formatting options.
   */
  public appendSortTable(
    worksheet: Worksheet,
    columns: SortTableColumnDef[],
    rows: SortTableRow[],
    options?: TableOptions
  ): void {
    this.appendHeaderRow(worksheet, columns, options)
    this.appendBodyRows(worksheet, columns, rows, options)
    this.appendFooterRow(worksheet, columns, options)
  }

  /** Saves the workbook to users filesystem.
   * @param workbook The workbook to save
   * @param fileName The name to save the workbook as
   */
  public exportAsXlsx(
    workbook: Workbook,
    fileName: string,
    callback?: (blob: Blob) => void
  ): void {
    workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName)
      if (callback) {
        callback(blob)
      }
    })
  }

  /**
   * Converts the column number into a column letter. i.e. 1 --> A, 2 --> B, etc
   * @param n The number of the column
   * @returns The letter of the column
   */
  public getColLetter(n: number): string {
    const ordA = 'A'.charCodeAt(0)
    const ordZ = 'Z'.charCodeAt(0)
    const len = ordZ - ordA + 1

    let s = ''
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s
      n = Math.floor(n / len) - 1
    }
    return s
  }

  private appendBodyRows(
    worksheet: Worksheet,
    columns: SortTableColumnDef[],
    rows: SortTableRow[],
    options?: TableOptions
  ): void {
    rows.forEach(row => {
      const rowData: any[] = []
      columns.forEach(col => {
        let value = row[col.id]
        if (!options?.transformFunc) {
          value = transformValue(value, col.valueType)
        } else {
          value = options.transformFunc(row, col)
        }
        rowData.push(value)
      })
      const excelRow = worksheet.addRow(rowData)

      this.applyStylingToSortTableBody(excelRow, row, columns, options)

      excelRow.eachCell((cell, cellIdx) => {
        formatCell(
          columns[cellIdx - 1],
          options?.currency || 'USD',
          cell,
          options
        )
      })
    })
  }

  private appendHeaderRow(
    worksheet: Worksheet,
    columns: SortTableColumnDef[],
    options?: TableOptions
  ): void {
    const headerRow = columns.map(x => x.label)

    const rowTableHeader = worksheet.addRow(headerRow)

    this.applyStylingToSortTableHeader(rowTableHeader, columns, options)
  }

  private appendFooterRow(
    worksheet: Worksheet,
    columns: SortTableColumnDef[],
    options?: TableOptions
  ): void {
    const footerRow = columns.map(_ => '')
    const rowTableFooter = worksheet.addRow(footerRow)
    if (options && options.footerStyle) {
      rowTableFooter.eachCell(cell => {
        cell.style = {
          ...cell.style,
          ...options.footerStyle,
        }
      })
    } else {
      // Default cell styling
      rowTableFooter.eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: '00BFFF' } },
        }
      })
    }
  }

  private applyStylingToSortTableBody(
    excelRow: Row,
    row: SortTableRow,
    columns: SortTableColumnDef[],
    options?: TableOptions
  ): void {
    if (!options || (options && !options.bodyStyle)) {
      this.applyDefaultSortTableBodyStylings(excelRow)
    }
    if (options) {
      if (options.bodyStyle) {
        const bodyStyle = options.bodyStyle
        excelRow.eachCell(cell => {
          cell.style = {
            ...cell.style,
            ...bodyStyle,
          }
        })
        return
      }
      if (options.styleFunc) {
        const styleFunc = options.styleFunc
        excelRow.eachCell((cell, colNum) => {
          styleFunc(row, columns[colNum - 1], cell, colNum - 1)
        })
      }
    }
  }

  private applyStylingToSortTableHeader(
    row: Row,
    columns: SortTableColumnDef[],
    options?: TableOptions
  ): void {
    if (!options || (options && !options.headerStyle)) {
      this.applyDefaultSortTableHeaderStylings(row)
    }
    if (options) {
      const headerStyle = options.headerStyle
      if (headerStyle) {
        row.eachCell(cell => {
          cell.style = headerStyle
        })
      }
      if (options.styleFunc) {
        const styleFunc = options.styleFunc
        row.eachCell((cell, colNum) => {
          styleFunc('headerRow', columns[colNum - 1], cell, colNum - 1)
        })
      }
    }
  }

  private applyDefaultSortTableBodyStylings(row: Row): void {
    row.eachCell(cell => {
      cell.style = {
        font: {
          name: 'Segoe Pro',
          size: 8,
        },
        border: {
          bottom: {
            style: 'thin',
            color: { argb: '000000' },
          },
        },
      }
    })
  }

  private applyDefaultSortTableHeaderStylings(row: Row): void {
    row.eachCell(cell => {
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
        wrapText: true,
      }
    })
  }
}
