import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from 'src/app/core/store'
import { selectGrossPortfolioViewID } from '../../store/analysis.selectors'
import { saveSummaryFilterView } from '../store/explore.actions'
import { SummaryViewRequest } from '../explore.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-summary-filter-view-container',
  templateUrl: './explore-gross-summary-filter-view-container.component.html',
  styleUrls: ['./explore-gross-summary-filter-view-container.component.scss']
})

export class ExploreGrossSummaryFilterViewContainerComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public viewData: SummaryViewRequest
  ) {}
  exploreGrossPortfolioView$: Observable<string | null>

  ngOnInit(): void {
    this.exploreGrossPortfolioView$ = this.store.select(selectGrossPortfolioViewID)
  }

  submitSummaryView($event: {
    name: string,
    grossProfileId: string
  }): void {
    this.store.dispatch(saveSummaryFilterView({
      data: {
        name: $event.name,
        grossProfileId: $event.grossProfileId,
        modeling: this.viewData.modeling,
        filters: this.viewData.filters,
        columnFilters: this.viewData.columnFilters,
        showIndividualLossSets: this.viewData.showIndividualLossSets,
        programId: this.viewData.programId,
        isDefault: false
      }
    }))
    this.dialog.closeAll()
  }
}
