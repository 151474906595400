import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../../api/model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../../api/util'
import { ProgramRiskCodeMapping, RiskCodeSection } from '../models/quote.model'

@Injectable({
  providedIn: 'root',
})
export class QuoteRiskCodeService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  getRiskCodes(): ApiResponse<RiskCodeSection[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskCodes}`
    return this.httpClient
      .get<RiskCodeSection[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET All Risk Codes'))
  }

  getProgramRiskCodeMappings(
    programId: number
  ): ApiResponse<ProgramRiskCodeMapping[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.programRiskCode}/${programId}`
    return this.httpClient
      .get<ProgramRiskCodeMapping[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Program Risk Code Mapping')
      )
  }

  postProgramRiskCodeMappings(
    mappings: ProgramRiskCodeMapping[]
  ): ApiResponse<ProgramRiskCodeMapping[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.programRiskCode}`
    return this.httpClient
      .post<ProgramRiskCodeMapping[]>(url, mappings, this.httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Program Risk Code Mapping')
      )
  }
}
