import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Broker } from '../core/model/broker.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signature',
  template: `
    <app-signature-header></app-signature-header>

    <app-signature-content-container
      [clientSelect]="clientSelect"
      [selectedClientID]="selectedClientID"
    ></app-signature-content-container>
  `,
  styles: [
    `
           app-signature-header {
             flex: 0 0 auto;
             padding: var(--stack) var(--inset-big) var(--stack) 0;
             height: var(--header-height);
           }
         `,
  ],
})
export class SignatureComponent {
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null
  @Input() broker: Broker
}
