import {
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding,
} from '@angular/core'
import { Broker } from '../../core/model/broker.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-broker',
  styleUrls: ['./broker.component.scss'],
  templateUrl: './broker.component.html',
})
export class BrokerComponent {
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null
  @Input() broker: Broker
  @Input() sageLogicalRole?: string | null

  @HostBinding('class.client-select') get isClientSelect() {
    return this.clientSelect
  }

  get title(): string {
    return this.clientSelect ? 'Overview' : 'Design Structures'
  }
}
