import { createSelector, createFeatureSelector } from '@ngrx/store'
import { REINSURER_TRACKING_SUBJECTIVITIES_FEATURE_KEY, ReinsurerTrackingSubjectivityState } from './reinsurerSubjectivities.reducer'

export const selectReinsurerTrackingSubjectivityState = createFeatureSelector<ReinsurerTrackingSubjectivityState>(REINSURER_TRACKING_SUBJECTIVITIES_FEATURE_KEY)

export const selectReinsurerTrackingSubjectivities = createSelector(
  selectReinsurerTrackingSubjectivityState,
  (state: ReinsurerTrackingSubjectivityState) => state.subjectivities
)

export const selectLoading = createSelector(
  selectReinsurerTrackingSubjectivityState,
  (state: ReinsurerTrackingSubjectivityState) => state.loading
)
