<h1 mat-dialog-title>Submit to OpenTWINS</h1>
<mat-dialog-content>
  <div>
    <div class="subtitle">{{dialogSubtitle}}</div>

    <div class="error-alert" *ngIf="errorMessage">{{ errorMessage }}</div>

    <mat-form-field
      class="app-bar-field app-bar-field-big app-bar-field-button clientDropdown"
    >
    <mat-label>
      <span>Client</span>
    </mat-label>
      <input
        placeholder="Client"
        matInput
        [formControl]="clientInput"
        [matAutocomplete]="clientAutocomplete"
        (input)="onAutocompleteChange($event.target.value)"
        required
      />
      <mat-autocomplete
        #clientAutocomplete="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option *ngFor="let client of filteredOptions" [value]="client">
          {{ client.acctName }} ({{ client.tpRef }})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <br />
    <mat-form-field
      class="app-bar-field app-bar-field-big app-bar-field-button riskDescInput"
    >
      <mat-label>
        <span>Risk Description</span>
      </mat-label>
      <input
        placeholder="Description"
        matInput
        [formControl]="riskDescInput"
        required
      />
    </mat-form-field>
    <br />
    <mat-form-field
      class="app-bar-field app-bar-field-big app-bar-field-button"
    >
      <mat-label>
        <span>Risk type</span>
      </mat-label>
      <mat-select
        appBackdropClass="app-tier-select-backdrop"
        panelClass="app-bar-panel"
        placeholder=""
        disableRipple
        required
        [formControl]="riskTypeInput"
        [value]=""
      >
        <mat-option value="XOL">
          <span>XOL</span>
        </mat-option>
        <mat-option value="PROP">
          <span>QS/Proportional</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field
      class="app-bar-field app-bar-field-big app-bar-field-button"
    >
      <mat-label>
        <span>Base currency</span>
      </mat-label>
      <mat-select
        appBackdropClass="app-tier-select-backdrop"
        panelClass="app-bar-panel"
        placeholder=""
        disableRipple
        [value]=""
        required
        [formControl]="baseCurrencyInput"
      >
        <mat-option
          *ngFor="let currencyType of currencyTypes"
          [value]="currencyType"
        >
          {{ currencyType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  <br/>
    <span *ngIf="processLog">Status: {{ processLog.status }}</span>
    <br /><br />
    <div *ngIf="processLog">
      <span>Details:</span>
      <ul>
        <li *ngFor="let detail of processLog.processLogDetails">
          {{ detail.step }}
          <ul>
            <li>{{ detail.message }}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="close" (click)="closeDialog()">Close</button>
  <button
    *ngIf="!submitting && !isComplete"
    mat-raised-button
    (click)="submit()"
    class="submit"
  >
    Submit
  </button>
  <button *ngIf="submitting" mat-raised-button disabled="true" class="submit">
    Submitting</button
  ><mat-spinner *ngIf="submitting" class="submit" [diameter]="20"></mat-spinner>
</mat-dialog-actions>
