import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { LayerView } from '../../model/layer-view'
import {
  GroupLayerDetailsDialogComponent,
  GroupLayerDetailsDialogComponentData,
} from '../components/group-layer-details-dialog/group-layer-details-dialog.component'
import { StudyResponse } from 'src/app/api/model/backend.model'

@Injectable()
export class GroupLayerDetailsDialogService {
  constructor(public dialog: MatDialog) {}

  open(
    views: LayerView[],
    groupName: string,
    studyIDs: string[],
    studies: StudyResponse[],
    groupID: string
  ) {
    const data: GroupLayerDetailsDialogComponentData = {
      views,
      groupName,
      studyIDs,
      studies,
      groupID,
    }
    return this.dialog.open(GroupLayerDetailsDialogComponent, {
      width: '80vw',
      data,
      panelClass: 'custom-dialog-box',
      autoFocus: false,
    })
  }
}
