import { Reinsurer } from '../../core/model/reinsurer.model'

export function createDomicileFilterList(reinsurers: Reinsurer[]) {
  let domicileList
  if (reinsurers) {
    domicileList = [
      ...new Set(
        reinsurers
          .map(r => {
            if (r.domicile) {
              return r.domicile
            }
          })
          .filter(d => d !== undefined)
          .sort()
      ),
    ]
  }
  return domicileList
}

export function createSPRatingFilterList(reinsurers: Reinsurer[]) {
  let spRatingList
  if (reinsurers) {
    spRatingList = [
      ...new Set(
        reinsurers
          .map(r => {
            if (r.sp_rating) {
              return r.sp_rating
            }
          })
          .filter(s => s !== undefined)
          .sort()
      ),
    ]
  }
  return spRatingList
}

export function createAmBestRatingFilterList(reinsurers: Reinsurer[]) {
  let amBestRatingList
  if (reinsurers) {
    amBestRatingList = [
      ...new Set(
        reinsurers
          .map(r => {
            if (r.am_best_rating) {
              return r.am_best_rating
            }
          })
          .filter(a => a !== undefined)
          .sort()
      ),
    ]
  }
  return amBestRatingList
}
