import {
  MIValueType,
  SummaryValue,
} from '../../../management-information/model/management-information.model'
import {
  QuoteStats,
  QuoteSummaryData,
} from './quote-management-information.model'

export const LAYER_NAME_AGG = 'Aggregate'
export const LAYER_NAME_XOL = 'Excess of Loss'

export interface QuoteSummaryContent {
  expiring: QuoteSummarySection
  quote: QuoteSummarySection
  fot: QuoteSummarySection
  fotVsQuoteVsExpiring: QuoteSummarySection
  occurrence: QuoteSummarySection
  totalBoundLimitVsTotalQuotedLimit: QuoteSummarySection
}
interface QuoteSummarySection {
  isMultiSection: boolean
  columnSections: QuoteSummaryColumnSection[]
}
interface QuoteSummaryColumnSection {
  name: keyof QuoteSummaryData
  columns: QuoteSummaryColumn[]
}
export interface QuoteSummaryColumn {
  layerType: string
  tiles: QuoteSummaryTile[]
}
export interface QuoteSummaryTile {
  type: MIValueType
  metric?: keyof QuoteStats /* min, avg, max */
  value?: SummaryValue
  textToolTip?: string
  class: string
}
interface QuoteSummaryMultiSectionTile {
  sections: QuoteSummaryTile[][]
}

interface QuoteSummaryHeaders {
  expiringRateHeader: QuoteSummaryTile[]
  expiringExpectedCededLossDepositPremiumHeader: QuoteSummaryTile[]
  expiringExpectedCededLossExpectedCededPremiumHeader: QuoteSummaryTile[]
  quoteRateHeader: QuoteSummaryTile[]
  quoteExpectedCededLossDepositPremiumHeader: QuoteSummaryTile[]
  quoteExpectedCededLossExpectedCededPremiumHeader: QuoteSummaryTile[]
  fotRateHeader: QuoteSummaryTile[]
  fotExpectedCededLossDepositPremiumHeader: QuoteSummaryTile[]
  fotExpectedCededLossExpectedCededPremiumHeader: QuoteSummaryTile[]
  quoteVsExpiringRateHeader: QuoteSummaryTile[]
  quoteVsExpiringCapacityHeader: QuoteSummaryTile[]
  fotVsExpiringRateHeader: QuoteSummaryTile[]
  fotVsExpiringCapacityHeader: QuoteSummaryTile[]
  fotVsQuoteRateHeader: QuoteSummaryTile[]
  fotVsQuoteCapacityHeader: QuoteSummaryTile[]
  occurrenceLimitHeader: QuoteSummaryTile[]
  occurrenceAttachmentHeader: QuoteSummaryTile[]
  totalBoundLimitVsTotalQuotedLimitHeader: QuoteSummaryTile[]
}

export const emptyTile: QuoteSummaryTile = {
  class: '',
  value: '',
  type: 'text',
}
const minAvgMaxHeaders: QuoteSummaryTile[] = [
  { class: 'header', value: 'Min', type: 'text' },
  { class: 'header', value: 'Average', type: 'text' },
  { class: 'header', value: 'Max', type: 'text' },
]

/* Main Headers */
const expectedHeaders: QuoteSummaryTile[][] = [
  [
    {
      class: 'color-header',
      value: 'Exp. Ced. Loss / Deposit Premium',
      textToolTip: 'Expected Ceded Loss / Deposit Premium',
      type: 'text',
    },
    emptyTile,
    ...minAvgMaxHeaders,
  ],
  [
    {
      class: 'color-header',
      value: 'Exp. Ced. Loss / Exp. Ced. Premium',
      textToolTip: 'Expected Ceded Loss / Expected Ceded Premium',
      type: 'text',
    },
    emptyTile,
    ...minAvgMaxHeaders,
  ],
]
const expiringHeaders: QuoteSummaryMultiSectionTile = {
  sections: [
    [
      { class: 'main-title', value: 'Expiring', type: 'text' },
      {
        class: 'color-header',
        value: 'Rate-on-Line, Occurrence' || 'Rate, % of Subject' || 'PMPM',
        textToolTip: 'Expiring Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    ...expectedHeaders,
  ],
}
const quoteHeaders: QuoteSummaryMultiSectionTile = {
  sections: [
    [
      { class: 'main-title', value: 'Quote', type: 'text' },
      {
        class: 'color-header',
        value: 'Rate-on-Line, Occurrence' || 'Rate, % of Subject' || 'PMPM',
        textToolTip: 'Quote Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    ...expectedHeaders,
  ],
}
const fotHeaders: QuoteSummaryMultiSectionTile = {
  sections: [
    [
      { class: 'main-title', value: 'FOT', type: 'text' },
      {
        class: 'color-header',
        value: 'Rate-on-Line, Occurrence' || 'Rate, % of Subject' || 'PMPM',
        textToolTip: 'FOT Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    ...expectedHeaders,
  ],
}
const fotVsQuoteVsExpiringHeaders: QuoteSummaryMultiSectionTile = {
  sections: [
    [
      { class: 'main-title', value: 'FOT vs Quote vs Expiring', type: 'text' },
      {
        class: 'color-header',
        value: 'Quote vs Expiring Rate',
        textToolTip: 'Quote vs Expiring Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'Quote vs Expiring Capacity',
        textToolTip: 'Quote vs Expiring Capacity',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'FOT vs Expiring Rate',
        textToolTip: 'FOT vs Expiring Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'FOT vs Expiring Capacity',
        textToolTip: 'FOT vs Expiring Capacity',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'FOT vs Quote Rate',
        textToolTip: 'FOT vs Quote Rate',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'FOT vs Quote Capacity',
        textToolTip: 'FOT vs Quote Capacity',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
  ],
}
const occurrenceHeader: QuoteSummaryMultiSectionTile = {
  sections: [
    [
      { class: 'main-title', value: 'Occurrence', type: 'text' },
      {
        class: 'color-header',
        value: 'Limit',
        textToolTip: 'Occurrence Limit',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
    [
      {
        class: 'color-header',
        value: 'Attachment',
        textToolTip: 'Occurrence Attachment',
        type: 'text',
      },
      emptyTile,
      ...minAvgMaxHeaders,
    ],
  ],
}
const totalBoundLimitVsTotalQuotedLimitHeader: QuoteSummaryTile[] = [
  {
    class: 'main-title',
    value: 'Total Limit Bound vs Quoted',
    textToolTip: 'Total Limit Bound vs Total Limit Quoted',
    type: 'text',
  },
  emptyTile,
  ...minAvgMaxHeaders,
]

const [
  expiringRateHeader,
  expiringExpectedCededLossDepositPremiumHeader,
  expiringExpectedCededLossExpectedCededPremiumHeader,
] = expiringHeaders.sections
const [
  quoteRateHeader,
  quoteExpectedCededLossDepositPremiumHeader,
  quoteExpectedCededLossExpectedCededPremiumHeader,
] = quoteHeaders.sections
const [
  fotRateHeader,
  fotExpectedCededLossDepositPremiumHeader,
  fotExpectedCededLossExpectedCededPremiumHeader,
] = fotHeaders.sections
const [
  quoteVsExpiringRateHeader,
  quoteVsExpiringCapacityHeader,
  fotVsExpiringRateHeader,
  fotVsExpiringCapacityHeader,
  fotVsQuoteRateHeader,
  fotVsQuoteCapacityHeader,
] = fotVsQuoteVsExpiringHeaders.sections
const [occurrenceLimitHeader, occurrenceAttachmentHeader] =
  occurrenceHeader.sections

export const QUOTE_SUMMARY_HEADERS: QuoteSummaryHeaders = {
  expiringRateHeader,
  expiringExpectedCededLossDepositPremiumHeader,
  expiringExpectedCededLossExpectedCededPremiumHeader,
  quoteRateHeader,
  quoteExpectedCededLossDepositPremiumHeader,
  quoteExpectedCededLossExpectedCededPremiumHeader,
  fotRateHeader,
  fotExpectedCededLossDepositPremiumHeader,
  fotExpectedCededLossExpectedCededPremiumHeader,
  quoteVsExpiringRateHeader,
  quoteVsExpiringCapacityHeader,
  fotVsExpiringRateHeader,
  fotVsExpiringCapacityHeader,
  fotVsQuoteRateHeader,
  fotVsQuoteCapacityHeader,
  occurrenceLimitHeader,
  occurrenceAttachmentHeader,
  totalBoundLimitVsTotalQuotedLimitHeader,
}

export const QUOTE_SUMMARY_EXPORT_LABELS = [
  {
    key: 'expiringRates',
    header: 'Expiring - ',
    valueType: 'percentage',
  },
  {
    key: 'expiringExpCedLossByDepositPremium',
    header: 'Expiring - Exp. Ced. Loss / Deposit Premium',
    valueType: 'percentage',
  },
  {
    key: 'expiringExpCedLossByExpCedPremium',
    header: 'Expiring - Exp. Ced. Loss / Exp. Ced. Premium',
    valueType: 'percentage',
  },
  {
    key: 'quoteRates',
    header: 'Quote - ',
    valueType: 'percentage',
  },
  {
    key: 'quoteExpCedLossByDepositPremium',
    header: 'Quote - Expected Ceded Loss / Deposit Premium',
    valueType: 'percentage',
  },
  {
    key: 'quoteExpCedLossByExpCedPremium',
    header: 'Quote - Exp. Ced. Loss / Exp. Ced. Premium',
    valueType: 'percentage',
  },
  {
    key: 'fotRates',
    header: 'FOT - ',
    valueType: 'percentage',
  },
  {
    key: 'fotExpCedLossByDepositPremium',
    header: 'FOT - Exp. Ced. Loss / Deposit Premium',
    valueType: 'percentage',
  },
  {
    key: 'fotExpCedLossByExpCedPremium',
    header: 'FOT - Exp. Ced. Loss / Exp. Ced. Premium',
    valueType: 'percentage',
  },
  {
    key: 'quotedVsExpiringRates',
    header: 'Quoted Vs Expiring Rates',
    valueType: 'percentage',
  },
  {
    key: 'quotedVsExpiringCapacity',
    header: 'Quoted Vs Expiring Capacity',
    valueType: 'percentage',
  },
  {
    key: 'fotVsExpiringRates',
    header: 'FOT vs Expiring Rates',
    valueType: 'percentage',
  },
  {
    key: 'fotVsExpiringCapacity',
    header: 'FOT vs Expiring Capacity',
    valueType: 'percentage',
  },
  {
    key: 'fotVsQuotedRates',
    header: 'FOT vs Quoted Rates',
    valueType: 'percentage',
  },
  {
    key: 'fotVsQuotedCapacity',
    header: 'FOT vs Quoted Capacity',
    valueType: 'percentage',
  },
  {
    key: 'occurrenceLimit',
    header: 'Occurrence Limit',
    valueType: 'currency',
  },
  {
    key: 'occurrenceAttachment',
    header: 'Occurrence Attachment',
    valueType: 'currency',
  },
  {
    key: 'totalBoundLimitVsTotalQuotedLimit',
    header: 'Total Limit Bound vs Quoted',
    valueType: 'numeric-decimal',
  },
]
