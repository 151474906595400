import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import {
  EXPIRING_REINSURER_NAME,
  FOT_MASTER_REINSURER_NAME,
  QuickQuoteUpdates,
  QuoteReinsurer,
  quickQuoteOptions,
} from '../models/quote.model'
import {
  isAHLLayer,
  isQSLayer,
  isRateSubjectLayer,
} from '../../analysis/model/layers.util'
import { DynamicMenuItem } from '@shared/dynamic-menu/dynamic-menu.model'
import { groupBy } from 'ramda'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { layerIds } from '../../analysis/model/layer-palette.model'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quick-quote',
  styleUrls: ['./quick-quote.component.scss'],
  templateUrl: './quick-quote.component.html',
})
export class QuickQuoteComponent implements OnChanges, OnInit {
  reinsuranceList: string[] = []
  quickQuoteColumnDefs: SortTableColumnDef[] = []
  quickQuoteRows: any[] = []
  qqOptions: string[] = quickQuoteOptions
  quoteSelectedArray: string[] = []
  @Input() autoFill: boolean
  @Input() selectedField: string
  @Input() reinsurerList: ReinsurerState[]
  @Input() reinsurersNameListUpdated: Reinsurer[]
  @Input() sectionList: SectionState[]
  @Input() addOrUpdateData: QuickQuoteUpdates[]
  @Input() layerState: LayerState[] = []
  @Output() updateQuickQuoteField = new EventEmitter<{ field: string }>()
  @Output() addOrUpdateQuickQuote = new EventEmitter<{
    data: QuickQuoteUpdates
  }>()
  @Output() saveQuickQuoteOnChange = new EventEmitter()
  @Output() updateAutoFillSelection = new EventEmitter<boolean>()

  constructor(private confirmationDialog: ConfirmationDialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !(
        Object.keys(changes).length === 1 &&
        changes.hasOwnProperty('reinsurersNameListUpdated')
      )
    ) {
      this.getRowsAndColumns()
    }
  }

  ngOnInit(): void {
    this.quoteSelectedArray = this.getAllPreferred()
    this.getRowsAndColumns()
  }

  getAllPreferred(): string[] {
    const arr: string[] = []
    this.sectionList.forEach(sec => {
      const reFoundArr = this.reinsurerList
        .map(r => r.reinsurer)
        .filter(r => r.cededlayerID === sec.section.layerRef)
      const groupReinsurer = Object.values(
        // tslint:disable-next-line: no-non-null-assertion
        groupBy(r => r.quoteReinsurerName!, reFoundArr)
      )
      groupReinsurer.forEach(gr => {
        let reFound = gr[0].id
        gr.forEach(grc => {
          if (grc.isPreferred) {
            reFound = grc.id
          }
        })
        arr.push(reFound)
      })
    })
    return arr
  }

  getRowsAndColumns(): void {
    this.quickQuoteRows = []
    this.quickQuoteColumnDefs = []
    this.reinsuranceList = this.reinsurersNameListUpdated.map(
      r => r.reinsurerProgramFactor[0].obo_name ?? r.name
    )
    this.quickQuoteColumnDefs = [
      {
        id: 'reinsurer',
        label: 'Reinsurer Name',
        valueType: 'text',
        whenLoading: 'show',
        editable: false,
        selectQQLayer: true,
      },
    ]

    // Add Defs per section
    this.sectionList.forEach(sec => {
      let isAddName = true
      if (sec.section.layerType === layerIds.catMultisection) {
        isAddName = this.layerState.some(
          state =>
            state.layer.id === sec.section.layerRef &&
            state.layer.meta_data.sage_layer_subtype === 'visible-layer'
        )
      }
      if (isAddName) {
        let name = sec.section.layerName
        if (sec.section.layerType === layerIds.catMultisection) {
          const multiLayer = this.layerState.find(
            l1 =>
              l1.layer.layerRefs &&
              l1.layer.layerRefs.includes(sec.section.layerRef)
          )
          if (multiLayer) {
            name = multiLayer.layer.meta_data.layerName || sec.section.layerName
          }
        }
        let decimalValue: number
        if (this.selectedField === 'Rate') {
          decimalValue = 4
        } else if (this.selectedField === 'Offered %') {
          decimalValue = 2
        } else {
          decimalValue = 0
        }
        const obj: SortTableColumnDef = {
          id: sec.section.layerRef,
          label: name,
          valueType:
            this.selectedField === 'Rate' || this.selectedField === 'Offered %'
              ? 'percentage'
              : 'currency',
          decimals: decimalValue,
          whenLoading: 'show',
          editable: true,
          sortDisabled: true,
          subHeader: this.getSubheader(sec.section.layerType, true),
          numberTransform: (n: number) => n,
          numberReverseTransform: (n: number) => n,
        }
        this.quickQuoteColumnDefs.push(obj)
      }
    })

    const updatedReList = [
      EXPIRING_REINSURER_NAME,
      ...this.reinsuranceList.sort(),
      FOT_MASTER_REINSURER_NAME,
    ]
    updatedReList.forEach((reList, i) => {
      const obj: any = {}
      obj.isPreferred = false
      this.quickQuoteColumnDefs.forEach(cd => {
        const key = 'id'
        obj[key] = i + 1
        if (cd.id === 'reinsurer') {
          obj[cd.id] = reList
        } else {
          obj[cd.id] = this.getReValue(cd.id as string, reList)[0]
          obj.isPreferred =
            obj.isPreferred || this.getReValue(cd.id as string, reList)[1]
        }
      })
      obj.allLayers = this.buildPopulateFromMenuItems(reList)
      this.addOrUpdateData.forEach(quickQuote => {
        if (quickQuote.reinsurerName === obj.reinsurer) {
          obj[quickQuote.cededLayerId] = quickQuote.value
        }
      })
      this.quickQuoteRows.push(obj)
    })
  }

  buildPopulateFromMenuItems(reNameSelect: string): DynamicMenuItem[] {
    const obj: DynamicMenuItem[] = []
    this.sectionList.forEach((section, i) => {
      let isAddCol = true
      let name = section.section.layerName ?? `Layer${i}`
      if (section.section.layerType === layerIds.catMultisection) {
        const lState = this.layerState.find(
          ls => ls.layer.id === section.section.layerRef
        )
        if (lState?.layer.meta_data.sage_layer_subtype !== 'visible-layer') {
          isAddCol = false
        }
      }
      if (isAddCol) {
        const res = this.reinsurerList.map(r => r.reinsurer)
        const childArr: DynamicMenuItem[] = []
        res.forEach(r => {
          if (
            r.quoteReinsurerName === reNameSelect &&
            r.cededlayerID === section.section.layerRef
          ) {
            childArr.push({
              name: r.reinsurerPhaseLabel || '',
              metadata: {
                quoteReinsurerId: r.id,
                cededLayerId: r.cededlayerID,
                quoteReinsurerName: r.quoteReinsurerName,
                isPreferred: r.isPreferred,
              },
            })
          }
        })
        if (childArr.length > 0) {
          obj.push({
            name,
            children: childArr,
          })
        }
      }
    })
    return obj
  }

  getReValue(id: string, re: string): [number, boolean] {
    let isPreferred = false
    const reFoundArr = this.reinsurerList
      .map(r => r.reinsurer)
      .filter(r => r.cededlayerID === id && r.quoteReinsurerName === re)
    let reFound: QuoteReinsurer =
      reFoundArr.find(re1 => re1.reinsurerPhaseVersion === '1') || reFoundArr[0]
    reFoundArr.forEach(reF => {
      // Add a logic to check sort table selected quote version
      if (this.quoteSelectedArray.includes(reF.id)) {
        reFound = reF
        if (!reF.isPreferred) {
          isPreferred = true
        }
      }
    })
    if (reFound && reFound.cededlayerID && reFound.quoteReinsurerName === re) {
      const layerType = this.getLayerType(reFound.cededlayerID)
      // Get correct rate value based on Layer Type
      return [this.getLayerSpecificRate(layerType, reFound), isPreferred]
    } else {
      return [0, isPreferred]
    }
  }

  getLayerSpecificRate(type: string, re: QuoteReinsurer): number {
    if (type && re && re.quoteFields) {
      if (this.selectedField === 'Rate') {
        if (isAHLLayer(type)) {
          return re.quoteFields.quotePmpm || 0
        } else if (isQSLayer(type)) {
          return re.quoteFields.quoteCedingCommission || 0
        } else if (isRateSubjectLayer(type)) {
          return re.quoteFields.quoteRateOnLineSubject || 0
        } else {
          return re.quoteFields.quoteRolPercentage || 0
        }
      } else if (this.selectedField === 'Aggregate Attachment / AAD') {
        return re.quoteFields.quoteAggregateAttachment?.value || 0
      } else if (this.selectedField === 'Aggregate Limit') {
        return re.quoteFields.quoteAggregateLimit?.value || 0
      } else if (this.selectedField === 'Offered %') {
        return re.quoteFields.quoteOfferedPercentage || 0
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  getLayerType(id: string): string {
    return (
      this.sectionList.find(s => s.section.layerRef === id)?.section
        .layerType || ''
    )
  }

  getSubheader(type: string, isHeader: boolean): string {
    if (this.selectedField === 'Rate') {
      if (type) {
        if (isAHLLayer(type)) {
          return isHeader ? 'PMPM' : 'quotePmpm'
        } else if (isQSLayer(type)) {
          return isHeader ? 'Ceding Commission' : 'quoteCedingCommission'
        } else if (isRateSubjectLayer(type)) {
          return isHeader ? 'Rate % of Subject' : 'quoteRateOnLineSubject'
        } else {
          return isHeader ? 'Rate on Line' : 'quoteRolPercentage'
        }
      } else {
        return ''
      }
    } else if (this.selectedField === 'Aggregate Attachment / AAD') {
      return isHeader
        ? 'Aggregate Attachment / AAD'
        : 'quoteAggregateAttachment'
    } else if (this.selectedField === 'Aggregate Limit') {
      return isHeader ? 'Aggregate Limit' : 'quoteAggregateLimit'
    } else if (this.selectedField === 'Offered %') {
      return isHeader ? 'Offered %' : 'quoteOfferedPercentage'
    } else {
      return ''
    }
  }

  async onChangeField(event: Event): Promise<void> {
    const type = (event.target as HTMLInputElement).innerText
    if (this.addOrUpdateData && this.addOrUpdateData.length > 0) {
      await new Promise<void>((resolve, reject) => {
        const dialogRef = this.confirmationDialog.open({
          message: 'Changes must be saved before updating this field.',
          submitLabel: 'Save and Continue',
        })
        dialogRef.afterClosed().subscribe((confirm: any) => {
          if (confirm) {
            this.saveQuickQuoteOnChange.emit()
            resolve()
          } else {
            reject()
          }
        })
      }).then(() => this.updateQuickQuoteField.emit({ field: type }))
    } else {
      this.updateQuickQuoteField.emit({ field: type })
    }
  }

  onValueChange(value: SortTableValueChangeEvent<any>): void {
    const section = this.sectionList.find(
      s => s.section.layerRef === value.column.id
    )?.section
    // Check if this is existing quote or create new
    const reFoundArr = this.reinsurerList
      .map(r => r.reinsurer)
      .filter(
        r =>
          r.cededlayerID === value.column.id &&
          r.quoteReinsurerName === value.row.reinsurer
      )
    let reFound: QuoteReinsurer =
      reFoundArr.find(re => re.reinsurerPhaseVersion === '1') || reFoundArr[0]
    reFoundArr.forEach(reF => {
      if (this.quoteSelectedArray.includes(reF.id)) {
        reFound = reF
      }
    })
    if (section) {
      if (reFound) {
        const obj: QuickQuoteUpdates = {
          action: 'Update',
          value: value.value,
          cededLayerId: section.layerRef,
          reinsurerName: value.row.reinsurer,
          reinsurerId: reFound.id,
          fieldSelected: this.getSubheader(section.layerType, false),
        }
        this.addOrUpdateQuickQuote.emit({ data: obj })
      } else {
        const obj: QuickQuoteUpdates = {
          action: 'Add',
          value: value.value,
          cededLayerId: section.layerRef,
          reinsurerName: value.row.reinsurer,
          fieldSelected: this.getSubheader(section.layerType, false),
        }
        this.addOrUpdateQuickQuote.emit({ data: obj })
      }
    }
  }

  onVersionClick(value: DynamicMenuItem): void {
    // Update quoteUpdatedArray
    if (value.metadata && value.metadata.quoteReinsurerId) {
      // If same reinsurer version is selected
      if (this.quoteSelectedArray.includes(value.metadata?.quoteReinsurerId)) {
        return
      }
      // if another reinsurer version is selected then replace
      let index = 0
      if (value.metadata.cededLayerId && value.metadata.quoteReinsurerName) {
        const reList = this.reinsurerList
          .map(r => r.reinsurer)
          .filter(
            r =>
              r.cededlayerID === value.metadata?.cededLayerId &&
              r.quoteReinsurerName === value.metadata?.quoteReinsurerName
          )
          .map(re => re.id)
        if (reList.length > 0) {
          this.quoteSelectedArray.forEach((qs, i) => {
            const res = reList.find(reL => reL === qs)
            if (res) {
              index = i
            }
          })
        }
        this.quoteSelectedArray.splice(
          index,
          1,
          value.metadata?.quoteReinsurerId
        )
        this.getRowsAndColumns()
      }
    }
  }

  onUpdateAutoFillSelection(event: MatCheckboxChange): void {
    this.updateAutoFillSelection.emit(event.checked)
  }
}
