import {
  SavedPricingCurveEntry,
  TECHNICAL_FACTORS_DEFAULT_STATE,
} from 'src/app/pricingcurve/model/pricing-curve.model'

export type DefaultSavedCurveEntry = {
  id: number | null
  pc_name: string
  percentage: number
  value: number
}

export type LayerEntry = {
  id: number
  layerId?: string
  physicalLayerId?: string
  isQS?: boolean
  currency?: string
  name: string
  layerType: string
  pricingCurves: DefaultSavedCurveEntry[]
  pricingCurveIds: number[]
  hasError: boolean
  modified: boolean
  saveForOnlyNewLayers: boolean | null
}

export interface SavedLayerTypeEntry {
  program_id: number
  pricing_curve_id: number
  layer_type: string
  weight_percent: number
}

export type CurveEntryUpdatePayload = {
  entryId: number
  layerType: string
  newEntry: LayerEntry
}

export type PricingCurveLayerEntryDTO = {
  pricing_curve_id: number
  layerRef: string
}

export type LayerTypeDefaultEntry = Record<string, LayerEntry>

export const PRICING_CURVE_LAYER_TYPE_DEFAULT: DefaultSavedCurveEntry = {
  id: null,
  pc_name: 'Layer Type Default',
  percentage: 1,
  value: 0,
}

export const GLOBAL_DEFAULT_CURVE_ENTRY: SavedPricingCurveEntry = {
  techFactors: TECHNICAL_FACTORS_DEFAULT_STATE,
  id: -1,
  pc_name: 'Global Default',
  audit_update_dt: '',
  audit_insert_dt: '',
  audit_created_by: '',
  audit_updated_by: '',
  fullName: '',
  included_excluded_layers: [],
  active_filters: undefined,
  isCombined: true,
  isImmutable: false,
  lastFitLayerCount: null
}
