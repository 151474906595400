import { range } from 'ramda'

export function shiftClick(
  obj: any,
  $event: MouseEvent,
  array: any[],
  lastIndex: number
): {
  inRange: any[]
  lastIndex: number
} {
  let inRange: any[] = []
  if ($event.shiftKey) {
    const index = array.map(l => l.id).indexOf(obj.id)
    if (index >= 0) {
      inRange = range(
        Math.min(index, lastIndex || 0),
        Math.max(index, lastIndex || 0) + 1
      ).map(i => array[i])
    }
    lastIndex = array.map(l => l.id).indexOf(obj.id)
  } else {
    lastIndex = array.map(l => l.id).indexOf(obj.id)
  }
  if (inRange.length === 0) {
    inRange.push(obj)
  }
  return {
    inRange,
    lastIndex,
  }
}
