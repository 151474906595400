import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/core/store';
import { select, Store } from '@ngrx/store'
import { selectFacSageUrl } from '../../core/store/auth/auth.selectors'

@Component({
  selector: 'app-fac-redirect',
  templateUrl: './fac-redirect.component.html',
  styleUrls: ['./fac-redirect.component.scss']
})
export class FacRedirectComponent implements OnInit, OnDestroy {

  countDown = 6
  intervalId: any
  facSageUrl: string
  private readonly destroyed$ = new Subject()

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {

    this.store
      .pipe(takeUntil(this.destroyed$), select(selectFacSageUrl))
      .subscribe(value => {
        this.facSageUrl = value ?? ''
      })

    this.intervalId = setInterval(() => {
      this.countDown--
      if (this.countDown <= 0){
        window.location.href = this.facSageUrl
      }      
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.destroyed$.next(true)
  }

}
