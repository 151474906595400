import { Component, ElementRef, Inject, Renderer2 } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TriggerPositionDialogComponent } from '@shared/trigger-position-dialog.component'
import { CreditTierStructuresDialogConfig } from '../credit-tier.model'

@Component({
  selector: 'app-credit-tier-structures-dialog',
  templateUrl: './credit-tier-structures-dialog.component.html',
  styleUrls: ['./credit-tier-structures-dialog.component.scss'],
})
export class CreditTierStructuresDialogComponent extends TriggerPositionDialogComponent {
  panelClass?: string
  constructor(
    protected dialogRef: MatDialogRef<CreditTierStructuresDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreditTierStructuresDialogConfig,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(dialogRef, elementRef, renderer)
    this.triggerRef = data.triggerRef
    this.panelClass = data.panelClass
    this.ignoreAllExternalClicks = data.ignoreAllExternalClicks
  }

  onTierChange() {
    this.dialogRef.close() // on client change, close dialog
  }
}
