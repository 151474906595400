import { Program } from '../../../../core/model/program.model'
import { PortfolioSetIDAndName } from '../../../model/portfolio-set.model'

export const selectProgramPortfolioSetIDAndName = (
  program: Program
): PortfolioSetIDAndName => ({
  cededPortfolioID: program.cededPortfolioID,
  grossPortfolioID: program.grossPortfolioID,
  netPortfolioID: program.netPortfolioID,
  name: program.label,
  analysisProfileID: program.analysisID,
})
