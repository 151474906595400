import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Program } from '../../../../core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-menu-item',
  styleUrls: ['./program-menu-item.component.scss'],
  templateUrl: './program-menu-item.component.html',
})
export class ProgramMenuItemComponent {
  @Input() program: Program
  @Input() allowSelection = true
  @Input() selected = false
  @Input() index = 0

  @Output() itemClick = new EventEmitter<MouseEvent | TouchEvent>()
}
