import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { tail } from 'ramda'
import { Program } from '../../core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-panel-scenarios-optimizations',
  styles: [
    `
           :host {
             margin: var(--stack-small) 0 0;
             max-height: 20vh;
             overflow: auto;
             display: block;
           }
     
           button {
             width: 100%;
           }
     
           button.original {
             background: var(--bg-3);
           }
     
           button.original:hover {
             background: var(--bg-3-lit);
           }
         `,
  ],
  template: `
    <button
      *ngFor="let it of list"
      appButton
      stack
      [ngClass]="getClass(it)"
      [primary]="!isCurrent(it)"
      [accent]="isCurrent(it)"
      [border]="isCurrent(it)"
      [unclickable]="isCurrent(it)"
      (click)="onClick(it)"
    >
      <span [matTooltip]="getTooltip(it)" matTooltipPosition="above">
        {{ getLabel(it) }}
      </span>
    </button>
  `,
})
export class LayerPanelScenariosOptimizationsComponent implements OnChanges {
  list: Program[]

  @Input() currentProgram?: Program
  @Input() scenarios?: Program[]
  @Input() optimizations?: Program[]

  @Output() scenarioOrOptimizationClick = new EventEmitter<Program>()

  ngOnChanges(): void {
    if (this.scenarios) {
      this.list = this.currentProgram?.isScenario
        ? this.scenarios
        : // If current structure is the original (i.e. non-scenario), don't show
          // it in the list of scenarios (original is the first scenario)
          tail(this.scenarios)
    }
    if (this.optimizations) {
      this.list = this.currentProgram?.isOptimization
        ? this.optimizations
        : tail(this.optimizations)
    }
  }

  getClass(program: Program) {
    return {
      original:
        !program.isScenario &&
        !program.isOptimization &&
        !this.isCurrent(program),
    }
  }

  getLabel(program: Program): string {
    return program.isScenario || program.isOptimization
      ? program.label
      : 'Original'
  }

  getTooltip(program: Program): string {
    const label = this.getLabel(program)
    return label.length <= 16 ? '' : label
  }

  isCurrent(program: Program): boolean {
    return program.id === this.currentProgram?.id
  }

  onClick(scenarioOrOptimization: Program): void {
    if (scenarioOrOptimization.id !== this.currentProgram?.id) {
      this.scenarioOrOptimizationClick.emit(scenarioOrOptimization)
    }
  }
}
