<!-- <section class="main-field">
  <mat-form-field
    class="field metrics app-bar-field app-bar-field-button app-field-bold-label"
  >
    <mat-label>Metric</mat-label>
    <mat-select
      panelClass="app-select-height-big"
      [(value)]="selectedMetric"
      (selectionChange)="onMetricChange()"
    >
      <mat-select-trigger class="select-placeholder-container">
        {{ selectedMetric?.name }}
      </mat-select-trigger>
      <ng-container *ngFor="let metric of metricOptions">
        <mat-option [value]="metric">
          {{ metric.name }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</section> -->
<app-explore-summary-grid-select
  [groupByOptions]="groupByOptions"
  [charts]="charts"
  [isSelectable]="true"
  (chartsGroupByChange)="chartsGroupByChange.emit($event)"
  (chartsGridIndexSelection)="chartsGridIndexSelection.emit($event)"
></app-explore-summary-grid-select>
<div class="field non-form slider">
  <mat-label>Chart Entity Limit</mat-label>

  <app-slider-input
    [min]="0"
    [max]="32"
    [step]="1"
    [(value)]="chartsEntityLimit"
    (valueChange)="chartEntityLimitChange.emit($event)"
    [showInput]="true"
  ></app-slider-input>
</div>
