import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Program } from '../../../core/model/program.model'
import { Client } from '../../../core/model/client.model'
import { AppState, selectWindowSize } from '../../../core/store'
import { WindowSize } from '../../../core/store/app.actions'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { selectProgramGroupsByID } from '../../../core/store/program-group/program-group.selectors'
import { selectProgramsByID } from '../../../core/store/program/program.selectors'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import {
  CompareMetricValue,
  GrossMetricsInput,
} from '../../model/compare-metrics.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import {
  selectCompareEntities,
  selectCompareExpandedCategories,
  selectCompareExpandedChangeMetrics,
  selectCompareGrossSelected,
  selectCompareHiddenMetricRanks,
  selectCompareProgramGroups,
  selectCompareTowerSizePercentage,
} from '../../store/analysis.selectors'
import { createGrossCompareMetricCategory } from '../../store/compare/compare-metric-settings/create-compare-metric-values'
import {
  setCompareExpandedChangeMetric,
  setCompareExpandedMetricCategory,
  setCompareHiddenMetricRank,
  updateCompareEntityIndex,
  updateCompareGrossSelected,
} from '../../store/compare/compare.actions'
import { CompareEntity } from '../../store/compare/compare.reducer'
import {
  addCompareProgramGroup,
  removeCompareProgramGroup,
} from '../../store/compare/program-group-compare/program-group-compare.actions'
import { ProgramGroup } from '../../store/grouper/program-group.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-towers-container',
  templateUrl: './compare-towers.container.html',
})

export class CompareTowersContainerComponent implements OnInit {
  entities$: Observable<CompareEntity[]>
  structureGroupIDs$: Observable<string[]>
  structuresByID$: Observable<Dictionary<Program>>
  structureGroupsByID$: Observable<Dictionary<ProgramGroup>>
  client$: Observable<Client | null>
  towerSizePercentage$: Observable<number>
  size$: Observable<WindowSize>
  expandedMetricCategories$: Observable<Record<string, boolean>>
  hiddenMetricRanks$: Observable<Record<string, boolean>>
  expandedChangeMetrics$: Observable<Record<string, boolean>>
  grossSelected$: Observable<boolean>
  grossMetricsInput$: Observable<GrossMetricsInput | null>

  @Input() precision: number

  @Output() programAdd = new EventEmitter<Program | Program[]>()
  @Output() programRemove = new EventEmitter<Program | Program[]>()
  @Output() programGroupAdd = new EventEmitter<ProgramGroup | ProgramGroup[]>()
  @Output() programGroupRemove = new EventEmitter<
    ProgramGroup | ProgramGroup[]
  >()

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.entities$ = this.store.pipe(select(selectCompareEntities))
    this.structureGroupIDs$ = this.store.pipe(
      select(selectCompareProgramGroups)
    )
    this.structuresByID$ = this.store.pipe(select(selectProgramsByID))
    this.structureGroupsByID$ = this.store.pipe(select(selectProgramGroupsByID))
    this.client$ = this.store.pipe(select(selectCurrentClient))

    this.towerSizePercentage$ = this.store.pipe(
      select(selectCompareTowerSizePercentage)
    )
    this.size$ = this.store.pipe(select(selectWindowSize))
    this.expandedMetricCategories$ = this.store.pipe(
      select(selectCompareExpandedCategories)
    )
    this.grossSelected$ = this.store.pipe(select(selectCompareGrossSelected))
    this.hiddenMetricRanks$ = this.store.pipe(
      select(selectCompareHiddenMetricRanks)
    )
    this.expandedChangeMetrics$ = this.store.pipe(
      select(selectCompareExpandedChangeMetrics)
    )
    this.grossMetricsInput$ = combineLatest([
      this.store.pipe(select(selectCompareGrossSelected)),
      this.store.pipe(select(selectCompareEntities)),
    ]).pipe(
      map(([grossSelected, entities]) => {
        if (grossSelected && entities.length > 0) {
          const program = entities[0].program
          // tslint:disable-next-line: no-non-null-assertion
          const grossPortfolioSetID = extractPortfolioSetID({
            ...program,
            analysisProfileID: program.analysisID,
          })!
          const grossCategories =
            (entities[0] &&
              entities[0].metricCategories &&
              entities[0].metricCategories.filter(
                m => m.category === 'Gross Metrics'
              )) ||
            []
          if (grossCategories.length === 0) {
            return null
          }
          const compareMetricsGrossCategory = createGrossCompareMetricCategory(
            grossCategories,
            (entities[0] &&
              entities[0].metricCategories &&
              entities[0].metricCategories.filter(
                m => m.category !== 'Gross Metrics'
              )) ||
              []
          )
          return { grossPortfolioSetID, compareMetricsGrossCategory }
        } else {
          return null
        }
      })
    )
  }

  onCollapseToggle(category: string) {
    this.store.dispatch(setCompareExpandedMetricCategory({ category }))
  }

  onUpdateGrossSelection(grossSelected: boolean) {
    this.store.dispatch(updateCompareGrossSelected({ grossSelected }))
  }

  onRAGToggle(rowValue: CompareMetricValue) {
    let categoryLabel: string
    categoryLabel = rowValue.category + '-' + rowValue.label
    this.store.dispatch(setCompareHiddenMetricRank({ categoryLabel }))
  }

  onChangeToggle(rowValue: CompareMetricValue) {
    let categoryLabel: string
    categoryLabel = rowValue.category + '-' + rowValue.label
    this.store.dispatch(setCompareExpandedChangeMetric({ categoryLabel }))
  }

  onUpdateEntityIndex(indexArray: string[]) {
    this.store.dispatch(updateCompareEntityIndex({ indexArray }))
  }

  onScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<Program>
  ) {
    const { add, remove } = $event

    if (remove && remove.length > 0) {
      this.programRemove.emit(remove)
    }
    if (add && add.length > 0) {
      this.programAdd.emit(add)
    }
  }

  onGroupScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<ProgramGroup>
  ) {
    const { add, remove } = $event

    if (remove && remove.length > 0) {
      remove
        .map(e => e.id)
        .forEach(id => this.store.dispatch(removeCompareProgramGroup({ id })))
    }
    if (add && add.length > 0) {
      add
        .map(e => e.id)
        .forEach(id => this.store.dispatch(addCompareProgramGroup({ id })))
    }
  }
}
