import { layerLossSetColDefs } from './layer-loss-sets-defs'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import {
  LossSetLayer,
  ScaledLossDialogOutput,
  ScaledLossSetProps,
} from '../model/loss-set-layers.model'
import { PortfolioSetID } from '../model/portfolio-set.model'
import { AnalysisInitProps } from '../store/analysis.actions'
import { SortTableRow, SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { clone, lensPath, set } from 'ramda'
import { MessageDialogService } from '@shared/message-dialog.service'
import { getRows } from './lossset-utils/loss-sets.util'
import { StudyResponse } from 'src/app/api/model/backend.model'
import { DEFAULT_MAPPING_LABELS, LossSetMappingLabelsResponse, MappingLabels } from 'src/app/core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-scale-loss-set',
  styles: [
    `
      .container {
        display: flex;
        flex-direction: column;
        max-width: calc(75vw - 48px);
      }

      .controls {
        display: flex;
        padding: 1.5rem;
        width: 100%;
      }

      .table-container {
        max-height: 400px;
        overflow-y: auto;
        width: 100%;
        max-width: 100%;
        overflow-x: scroll;
        padding-bottom: 16px;
      }

      .identifier {
        width: 60%;
        margin-left: -2rem;
      }

      .select-action {
        width: 40%;
        align-items: center;
        label {
          font-size: var(--font-size-small);
        }
      }

      .select-action > mat-form-field {
        width: 50%;
      }

      .warning-icon {
        top: 8px;
        position: relative;
        background: transparent;
        margin-left: var(--inset-small);
        padding: 0 var(--inset-tiny);
        color: var(--caution);
        margin-right: -1rem;
        cursor: pointer;
      }

      .data {
        padding: 1.5rem;
      }

      :host ::ng-deep .underline .mat-mdc-text-field-wrapper {
        border-bottom: 1px solid var(--bg-3-lit) !important;
        padding-left: 1rem !important;
      }

      :host
        ::ng-deep
        .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        font-size: var(--font-size-mini);
        display: block;
      }
    `,
  ],
  template: `
    <div class="container">
      <h1 mat-dialog-title>Scale Loss Sets</h1>
      <div class="controls">
        <div class="select-action">
          <label>Select Action: </label>
          <mat-form-field
            appearance="fill"
            color="accent"
            class="underline"
            hintLabel="{{ actionLabel }}"
          >
            <mat-select
              placeholder="Select Loss Set Groups"
              default="'Adjust Existing Layers'"
              [value]="'modify'"
              (valueChange)="onSelectActionChange($event)"
            >
              <mat-option [value]="'modify'">Adjust Existing Layers</mat-option>
              <mat-option [value]="'new'">Add New Layers</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-icon
            class="warning-icon"
            *ngIf="dialogOutput.action === 'modify'"
            matTooltip="Changes will affect every Portfolio and Ceded Layer that contains adjusted Loss Sets"
            matTooltipPosition="above"
          >
            error
          </mat-icon>
        </div>
        <div class="identifier">
          <label>
            {{ identifierLabel }}
          </label>
          <mat-form-field class="app-mat-form-field-no-padding underline">
            <input
              matInput
              [required]="dialogOutput.action === 'new'"
              value="{{ description }}"
              (input)="onIdentifierEdit($event.target.value)"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="data">
        <div>
          <app-filter-input
            class="child"
            transparent
            (filterChange)="createFilter($event)"
          ></app-filter-input>
        </div>
        <div class="table-container">
          <app-sort-table
            [excelEnabled]="true"
            [columnDefs]="columnDefs"
            [rows]="rows"
            [currentCurrency]="currentCurrency"
            [accentHeaderRow]="true"
            (valueChange)="onValueChange($event)"
            (headerValueChange)="onHeaderValueChange($event)"
          ></app-sort-table>
        </div>
        <div mat-dialog-actions>
          <button
            appButton
            accent
            [disabled]="!enableSave"
            (click)="onSaveClick()"
          >
            <span>Save</span>
          </button>
          <button appButton link (click)="onCloseClick()">
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  `,
})
export class ScaleLossSetDialogComponent implements OnInit {
  @Input() parentLossSets: LossSetLayer[]
  @Input() portfolioSetID: PortfolioSetID
  @Input() selectedClientID: string
  @Input() selectedYearID: string
  @Input() selectedStudyID: string
  @Input() selectedStructureID: string
  @Input() studies: StudyResponse[]
  filteredParentLossSets: LossSetLayer[]
  columnDefs = layerLossSetColDefs
  rows: SortTableRow<ScaledLossSetProps>[]
  currentCurrency = ''
  mappingLabels: MappingLabels
  noMappingLabelsDetected: boolean
  mappingLabelsUpdated = false

  description: string
  dialogOutput: ScaledLossDialogOutput = {
    props: [],
    action: 'modify',
  }

  @Output() scaleLossSets = new EventEmitter<ScaledLossDialogOutput>()
  @Output() closeScaleDialog = new EventEmitter<AnalysisInitProps>()
  @Output() postMappingLabels = new EventEmitter<LossSetMappingLabelsResponse>()
  @Output() updateMappingLabels = new EventEmitter<LossSetMappingLabelsResponse>()

  get enableSave(): boolean {
    return this.dialogOutput.props.length > 0
      ? this.dialogOutput.props.find(p => p.dirty) &&
        (this.dialogOutput.action === 'modify' ||
          (this.description && this.dialogOutput.action === 'new'))
        ? true
        : false
      : this.mappingLabelsUpdated
  }

  get actionLabel(): string {
    return this.dialogOutput.action === 'modify'
      ? 'Modify Loss Set Layers for all Gross Portfolios that contain these layers'
      : 'Create new Loss Set Layers based on existing Loss Set Layer properties'
  }

  get identifierLabel(): string {
    return this.dialogOutput.action === 'modify'
      ? 'Identifier:'
      : 'Identifier*:'
  }

  constructor(private messageService: MessageDialogService) {}

  ngOnInit(): void {
    this.filteredParentLossSets = this.parentLossSets
    this.rows = getRows(this.filteredParentLossSets)
    this.filteredParentLossSets.forEach(ls => {
      if (!!this.currentCurrency) {
        return
      }
      this.currentCurrency = ls.premium.currency
    })
    const study = this.studies.filter(
      s => String(s.id) === this.selectedStudyID
    )[0]
    if (study && study.mapping_labels) {
      this.mappingLabels = study.mapping_labels
      const updatedColumnDefs = clone(this.columnDefs)
      const labelKeys = Object.keys(
        this.mappingLabels
      ) as (keyof MappingLabels)[]
      updatedColumnDefs.forEach(c => {
        const label = this.mappingLabels[c.id as keyof MappingLabels]
        if (labelKeys.includes(c.id as keyof MappingLabels) && label !== null) {
          c.label = label as string
        }
      })
      this.columnDefs = updatedColumnDefs
      this.noMappingLabelsDetected = false
    } else {
      this.mappingLabels = DEFAULT_MAPPING_LABELS
      this.noMappingLabelsDetected = true
    }
  }

  onSelectActionChange(action: string) {
    this.dialogOutput.action = action
  }

  onIdentifierEdit(description: string) {
    this.description = description
    this.dialogOutput.props.forEach(p => (p.description = description))
  }

  onScaleChange(updatedProps: ScaledLossSetProps) {
    if (this.dialogOutput.props.length > 0) {
      const foundIndex = this.dialogOutput.props.findIndex(
        p => p.id === updatedProps.id
      )
      if (foundIndex === -1) {
        this.dialogOutput.props.push(updatedProps)
      } else {
        this.dialogOutput.props[foundIndex] = updatedProps
      }
    } else {
      this.dialogOutput.props.push(updatedProps)
    }
  }

  onSaveClick() {
    if (this.dialogOutput && this.dialogOutput.props.length > 0) {
      const output = clone(this.dialogOutput)
      output.props = output.props.filter(p => p.dirty)
      let lossSetScaleWarning = false
      for (const prop of output.props) {
        if (prop.dirty) {
          prop.originalPremium = prop.originalPremium
          prop.newPremium = prop.newPremium
          prop.description =
            output.action === 'new' && this.description
              ? this.description
              : prop.description
          prop.map1 = this.checkForEmptyMap(prop.map1)
          prop.map2 = this.checkForEmptyMap(prop.map2)
          prop.map3 = this.checkForEmptyMap(prop.map3)
          prop.map4 = this.checkForEmptyMap(prop.map4)
          prop.map5 = this.checkForEmptyMap(prop.map5)
          const relatedLossSet = this.parentLossSets.find(
            ls => ls.id === prop.lossSetID
          )
          if (
            prop.lossScaleFactor >= 1.1 &&
            relatedLossSet &&
            relatedLossSet.meta_data.loss_type !== 'cat'
          ) {
            lossSetScaleWarning = true
          }
        }
      }

      if (lossSetScaleWarning) {
        this.messageService.showMessage(
          'Warning',
          'Scaling Losses changes the size of each occurrence, but not the number of occurrences. Scaling more than 10% may be more accurate by re-simulating.',
          { maxWidth: '40%' }
        )
      }
      this.scaleLossSets.emit(output)
      this.dialogOutput.props = []
    }
    if (this.mappingLabelsUpdated) {
      if (this.noMappingLabelsDetected) {
        this.postMappingLabels.emit({
          id: this.selectedStudyID,
          mappingLabels: this.mappingLabels,
        })
      } else {
        this.updateMappingLabels.emit({
          id: this.selectedStudyID,
          mappingLabels: this.mappingLabels,
        })
      }
      this.mappingLabelsUpdated = false
    }
  }

  checkForEmptyMap(val: string | undefined) {
    if (!val || val === '') {
      return undefined
    }
    return val
  }

  onCloseClick() {
    const initProps = {
      cededPortfolioID: this.portfolioSetID.cededPortfolioID,
      grossPortfolioID: this.portfolioSetID.grossPortfolioID,
      netPortfolioID: this.portfolioSetID.netPortfolioID,
      parentGrossPortfolioID: this.portfolioSetID.parentGrossPortfolioID,
      analysisProfileID: this.portfolioSetID.analysisProfileID,
      clientID: this.selectedClientID,
      studyID: this.selectedStudyID,
      yearID: this.selectedYearID,
      structureID: this.selectedStructureID,
    } as AnalysisInitProps
    this.closeScaleDialog.emit(initProps)
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<ScaledLossSetProps>): void {
    const tempRows = clone(this.rows)
    const tempRow = tempRows.find(r => r.id === id)
    if (!tempRow) {
      return
    }
    const index = tempRows.indexOf(tempRow)
    const row = set(lensPath([column.id]), value, tempRow)
    row.dirty = true
    switch (column.id) {
      case 'lossScaleFactor':
        if (row.avg_annual_loss) {
          row.avg_annual_loss *= value
        }
        if (row.max_loss) {
          row.max_loss *= value
        }
        if (row.min_loss) {
          row.min_loss *= value
        }
        break
      case 'premiumScaleFactor':
        row.newPremium = row.originalPremium * value
        break
      case 'newPremium':
        if (row.originalPremium !== 0) {
          row.premiumScaleFactor = value / row.originalPremium
        }
        break
      case 'ls_dim1':
        row.name = value + ' - ' + row.ls_dim2
        break
      case 'ls_dim2':
        row.name = row.ls_dim1 + ' - ' + value
        break
    }
    row.description = row.description ?? row.name
    tempRows[index] = row
    this.rows = tempRows
    this.onScaleChange(row)
  }

  createFilter(value: string) {
    if (value) {
      this.filteredParentLossSets = this.parentLossSets.filter(e => {
        return (
          this.getFilterValue(e).toLowerCase().indexOf(value.toLowerCase()) !==
          -1
        )
      })
      this.rows = getRows(this.filteredParentLossSets)
    } else {
      this.filteredParentLossSets = this.parentLossSets
      this.rows = getRows(this.parentLossSets)
    }
  }

  getFilterValue(ls: LossSetLayer): string {
    return Object.values(getRows([ls])[0]).join(',')
  }

  onHeaderValueChange($event: { id: string; value: string }): void {
    const { id, value } = $event
    const updatedMappingLabels = clone(this.mappingLabels)
    updatedMappingLabels[id as keyof MappingLabels] = value
    this.mappingLabels = updatedMappingLabels
    this.mappingLabelsUpdated = true
  }
}
