<div class="table-wrapper">
  <ng-container>
    <table mat-table [dataSource]="tableData" *ngIf="tableData.length > 0">
      <ng-container matColumnDef="elPercent">
        <th mat-header-cell *matHeaderCellDef>EL%</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div *ngIf="i !== 1">
            {{ formatDecimal(element.elPercent) }}
          </div>
          <div class="el-user-input" *ngIf="i == 1">
            <input
              matInput
              value="{{ formatDecimal(inputValue) }}"
              (focus)="togglePercentOnFocus()"
              (blur)="userInputHandleBlur()"
              (keydown)="userInputHandleBlur($event)"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="trolPercent">
        <th mat-header-cell *matHeaderCellDef>TROL%</th>
        <td mat-cell *matCellDef="let element">
          {{ formatDecimal(element.trolPercent) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="elm">
        <th mat-header-cell *matHeaderCellDef>ELM</th>
        <td mat-cell *matCellDef="let element">
          {{ element.elm | number: '1.1-4' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let tableData; columns: displayedColumns"></tr>
    </table>
  </ng-container>
</div>
