import {
  EXPLORE_SUMMARY_HEADERS,
  ExploreSummaryHeader,
  GroupSummaryRequest,
  LossTypeColumnFilters,
  SummaryViewRequest,
  SummaryDataResponse,
  SummaryTableColorPallette,
} from './../explore.model'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import {
  ExploreFilterMap,
  exploreSummaryColumnDefs,
  ExploreSummaryDatum,
  MappingOption,
} from '../explore.model'
import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'
import {
  extractFilterMapKeys,
  filterLossSets,
  formatSummaryRows,
  getGroupedSummaryData,
  getRowsFromLossSets,
  updateRPColumnDefLabel,
} from '../explore.util'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { Program } from '../../../core/model/program.model'
import { GrossSummaryExportXlsxService } from '../export/gross-summary-table-export-xlsx.service'
import { Client } from 'src/app/core/model/client.model'
import {
  AccountOpportunity,
  ExploreSummaryView,
  StudyResponse,
} from 'src/app/api/model/backend.model'
import { DatePipe } from '@angular/common'
import { MatDialog } from '@angular/material/dialog'
import { ExploreGrossSummaryFilterViewContainerComponent } from '../explore-gross-summary-filter-view-container/explore-gross-summary-filter-view-container.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-summary-table',
  templateUrl: './explore-gross-summary-table.component.html',
  styleUrls: ['./explore-gross-summary-table.component.scss'],
})
export class ExploreGrossSummaryTableComponent implements OnInit, OnChanges {
  @Input() lossSetGroups: LossSetGroup[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() mappingLabels: MappingLabels
  @Input() summaryFilterOptions: MappingOption[]
  @Input() summaryFilterMap: { [key: string]: boolean }
  @Input() summaryColumnFilterMap: { [key: string]: boolean }
  @Input() mappingOptions: MappingOption[]
  @Input() showIndividualLossSets: boolean
  @Input() modelingArray: string[]
  @Input() summaryData: SummaryDataResponse[]
  @Input() groupSummaryData: SummaryDataResponse[]
  @Input() currentStructureCurrency: string | undefined
  @Input() analysisProfileCurrency: string
  @Input() groups: GroupSummaryRequest[]
  @Input() client: Client
  @Input() study: StudyResponse
  @Input() selectedStructure: Program
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() summaryViews: ExploreSummaryView[]
  @Input() selectedStudySummaryFilterView: ExploreSummaryView | null
  @Input() isSavedViewDirty: boolean
  @Input() summaryLoading: boolean
  @Input() summaryRP: number[]

  roundedTo: string = 'Millions'
  get roundedToAbrev(): 'M' | 'K' {
    if (this.roundedTo === 'Millions') {
      return 'M'
    } else {
      return 'K'
    }
  }
  isGroupBy: boolean
  columnDefs: SortTableColumnDef[] = exploreSummaryColumnDefs
  updatedColumnDefs: SortTableColumnDef[] = exploreSummaryColumnDefs
  rows: SortTableRow<ExploreSummaryDatum>[]
  updatedDataRows: SortTableRow<ExploreSummaryDatum>[]
  exportRows: SortTableRow<ExploreSummaryDatum>[]
  headers: ExploreSummaryHeader[]
  subheader: string[] | null = []

  @Output() saveAsSummaryView = new EventEmitter<ExploreSummaryView>()
  @Output() deleteSummaryView = new EventEmitter<{ id: number }>()

  get saveDisabled(): boolean {
    return this.lossSetLayers.length === 0
  }

  get saveAsDisabled(): boolean {
    return !this.isSavedViewDirty
  }

  get isLoading(): boolean {
    return (
      (this.lossSetLayers && !this.updatedDataRows) ||
      (this.isGroupBy && !this.updatedDataRows && !this.groupSummaryData)
    )
  }

  shortNumberPipe = new ShortNumberPipe()
  colorPalette = SummaryTableColorPallette

  constructor(
    private exportService: GrossSummaryExportXlsxService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.headers = EXPLORE_SUMMARY_HEADERS.filter(h => h.id !== 'groupBy')
    this.updatedColumnDefs = exploreSummaryColumnDefs.filter(
      c => c.id !== 'groupBy'
    )
    this.updateTableData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateTableData()
    }
  }

  changeRoundedTo(value: string): void {
    this.roundedTo = value
    this.updateTableData()
  }

  updateTableData(): void {
    const model = this.modelingArray.filter(x => x !== '')
    if (this.lossSetLayers) {
      this.rows = getRowsFromLossSets(
        filterLossSets(this.lossSetLayers, this.summaryFilterMap),
        this.summaryData,
        model
      )
      if (model.length > 0) {
        this.updatedDataRows = getGroupedSummaryData(
          this.groupSummaryData,
          this.summaryData,
          model,
          this.groups,
          this.showIndividualLossSets,
          this.rows,
          this.currentStructureCurrency,
          this.lossSetLayers,
          this.roundedToAbrev
        )
        this.exportRows = getGroupedSummaryData(
          this.groupSummaryData,
          this.summaryData,
          model,
          this.groups,
          this.showIndividualLossSets,
          this.rows,
          this.currentStructureCurrency,
          this.lossSetLayers,
          this.roundedToAbrev,
          true
        )
      } else {
        this.exportRows = this.rows
        this.updatedDataRows = formatSummaryRows(
          this.rows,
          this.currentStructureCurrency,
          model,
          this.roundedToAbrev
        )
      }
    }
    this.updatedColumnDefs = this.filterColumnDefs()
  }

  filterColumnDefs(): SortTableColumnDef[] {
    let defs = exploreSummaryColumnDefs.map(def => {
      let label = def.label
      if (!isNaN(Number(def.label))) {
        label = updateRPColumnDefLabel(Number(def.label), this.summaryRP)
      }
      return {
        ...def,
        label,
      }
    })
    const model = this.modelingArray.filter(x => x !== '')
    const map = extractFilterMapKeys(this.summaryColumnFilterMap, '_')
    this.isGroupBy = model.length > 0
    this.subheader = []
    const removeLarge =
      !this.summaryColumnFilterMap['Large'] ||
      !this.summaryFilterMap['loss_type~large']
    if (this.isGroupBy) {
      defs = defs.map(d => {
        this.subheader = model.map(key => {
          const label = this.summaryFilterOptions.find(
            o => o.value === key
          ).label
          return label ?? key
        })
        let label = ''
        if (d.id === 'groupBy') {
          label = this.subheader.filter(x => x !== '').join('/')
        } else if (d.id === 'totalContributionToGroupVolatility') {
          label = `Total Contribution to ${this.subheader.filter(x => x !== '')[0]} Volatility (Downside)`
        } else {
          label = String(d.label)
        }
        return {
          ...d,
          label,
        }
      })
    }
    if (map) {
      defs = defs.filter(def => {
        const keyValue = String(def.id).split('_')[0]
        const typeKeyValue = String(def.id).split('_')[1]
        return Object.keys(map).every(key => {
          const allowedValues = map[key]
          if (
            keyValue === 'groupBy' ||
            keyValue === 'totalContributionToGroupVolatility' ||
            keyValue === 'contributionToGroupVolatility' ||
            (keyValue === 'largeRiskFrequency' &&
              this.isGroupBy &&
              !removeLarge) ||
            (keyValue === 'largeRiskSeverity' &&
              this.isGroupBy &&
              !removeLarge)
          ) {
            if (
              keyValue === 'contributionToGroupVolatility' &&
              (String(def.id).includes('Attritional') ||
                String(def.id).includes('Large') ||
                String(def.id).includes('Cat'))
            ) {
              return (
                allowedValues.includes(typeKeyValue) &&
                allowedValues.includes(keyValue) &&
                this.isGroupBy
              )
            }
            return this.isGroupBy
          }
          if (
            String(def.id).toLocaleLowerCase().includes('large') &&
            removeLarge
          ) {
            return false
          } else if (
            String(def.id).includes('Attritional') ||
            String(def.id).includes('Large') ||
            String(def.id).includes('Cat')
          ) {
            return (
              allowedValues.includes(typeKeyValue) &&
              allowedValues.includes(keyValue)
            )
          }
          return allowedValues.includes(keyValue)
        })
      })
    }
    let removeTypeHeaders = false
    this.headers = EXPLORE_SUMMARY_HEADERS.map(header => {
      let label = header.label
      let width = header.width
      if (this.isGroupBy) {
        if (header.id === 'contributionToGroupVolatility') {
          label = `Contribution to ${this.subheader.filter(x => x !== '')[0]} Volatility by Type (Downside)`
        }
      }
      if (header.label.includes('by Type')) {
        let w = 0
        if (this.summaryColumnFilterMap['Attritional']) {
          w += 90
        }
        if (
          this.summaryColumnFilterMap['Large'] &&
          this.summaryFilterMap['loss_type~large']
        ) {
          w += 90
        }
        if (this.summaryColumnFilterMap['Cat']) {
          w += 90
        }
        if (w === 0) {
          removeTypeHeaders = true
        }
        width = String(w)
      }
      return {
        ...header,
        label,
        width,
      }
    }).filter(header => {
      const id = header.id
      if (header.label.includes('By Type') && removeTypeHeaders) {
        return false
      }
      if (
        id === 'groupBy' ||
        id === 'totalContributionToGroupVolatility' ||
        id === 'contributionToGroupVolatility' ||
        (id === 'largeRisk' && this.isGroupBy && !removeLarge)
      ) {
        return this.isGroupBy
      }
      return Object.keys(map).every(key => {
        const allowedValues = map[key]
        if (id.toLocaleLowerCase().includes('large') && removeLarge) {
          return false
        }
        return allowedValues.includes(id)
      })
    })
    return defs
  }

  exportSummary(): void {
    const isGroupBy = this.isGroupBy
    const clientName = this.client.name
    const year = this.client.clientYears.find(
      y => Number(y.id) === this.study.carrier_year_id
    ).year
    const program = this.study.name
    const structure = this.selectedStructure.label
    const currency = this.currentStructureCurrency
    const topHeaders = this.headers
    const columnDefs = this.updatedColumnDefs
    const dataRows = this.exportRows
    const indLossSets = !!this.showIndividualLossSets
    const accOpp = this.accountOpportunities?.find(
      opp => opp.id === this.study?.opportunity_id
    )
    const accOppOnIncepDate = accOpp?.opportunityInceptionDate
    let effectiveDate = ''
    if (accOppOnIncepDate) {
      const parts = accOppOnIncepDate.split('-')
      const date = new Date(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10)
      ).toString()
      effectiveDate = this.datePipe.transform(date, 'longDate') || ''
    }
    const roundedTo = this.roundedToAbrev
    this.exportService.exportSummaryXlsx(
      clientName,
      year,
      program,
      structure,
      effectiveDate,
      currency,
      topHeaders,
      columnDefs,
      dataRows,
      isGroupBy,
      indLossSets,
      roundedTo
    )
  }

  getSubheaderColor(index: number): string {
    const model = this.modelingArray.filter(x => x !== '')
    let i = index
    const lastLevel = model.length - 1
    if (index > 0 && index === lastLevel) {
      i = 4
    }
    return this.colorPalette[i]
  }

  onSaveClick(): void {
    const filters: string[] = []
    const columnFilters: string[] = []
    Object.keys(this.summaryFilterMap).forEach(key => {
      if (this.summaryFilterMap[key]) {
        filters.push(key)
      }
    })
    Object.keys(this.summaryColumnFilterMap).forEach(key => {
      if (this.summaryColumnFilterMap[key]) {
        columnFilters.push(key)
      }
    })
    this.dialog.open(ExploreGrossSummaryFilterViewContainerComponent, {
      data: {
        modeling: this.modelingArray,
        filters: filters,
        columnFilters: columnFilters,
        showIndividualLossSets: this.showIndividualLossSets,
        programId: this.study.id,
      },
      minWidth: '25vw',
      width: '25vw',
      maxWidth: '25vw',
    })
  }

  onSaveAsClick(): void {
    const filters: string[] = []
    const columnFilters: string[] = []
    Object.keys(this.summaryFilterMap).forEach(key => {
      if (this.summaryFilterMap[key]) {
        filters.push(key)
      }
    })
    Object.keys(this.summaryColumnFilterMap).forEach(key => {
      if (this.summaryColumnFilterMap[key]) {
        columnFilters.push(key)
      }
    })
    const modeling = this.modelingArray
    const showIndividualLossSets = this.showIndividualLossSets
    this.saveAsSummaryView.emit({
      ...this.selectedStudySummaryFilterView,
      filters,
      columnFilters,
      modeling,
      showIndividualLossSets,
    })
  }

  onDeleteViewClick(): void {
    this.deleteSummaryView.emit({ id: this.selectedStudySummaryFilterView.id })
  }
}
