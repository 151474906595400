import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { concatMap, withLatestFrom } from 'rxjs/operators'
import { saveQuickQuote, saveQuickQuoteSuccess } from './quick-quote.action'
import { AppState } from 'src/app/core/store'
import { Store, select } from '@ngrx/store'
import { selectReinsurers } from '../quote.selectors'
import { saveQuoteReinsurer } from '../reinsurer/reinsurer.actions'

@Injectable()
export class QuickQuoteEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  saveQuickQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveQuickQuote),
      withLatestFrom(this.store.pipe(select(selectReinsurers))),
      concatMap(([_, reinsurers]) => {
        const action = []
        reinsurers.forEach(re => {
          if (
            re.dirty &&
            ((re.reinsurer.tpRef && re.reinsurer.tpRef !== '') ||
              re.reinsurer.reinsurerPhase === 'Expiring' ||
              re.reinsurer.reinsurerPhase === 'FOT')
          ) {
            action.push(saveQuoteReinsurer(re))
          }
        })
        action.push(saveQuickQuoteSuccess())
        return action
      })
    )
  })
}
