import {
  Directive,
  Input,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core'
import { coerceNumberProperty } from '@angular/cdk/coercion'

@Directive({
  selector: '[appWheelScrollSpeed]',
})
export class WheelScrollDirective implements OnInit {
  @Input() set appWheelScrollSpeed(value: any) {
    this._speed = coerceNumberProperty(value)
  }

  get speed() {
    return this._speed
  }

  private _speed: number

  constructor(
    private element: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.renderer.listen(
        this.element.nativeElement,
        'wheel',
        (event: WheelEvent) => {
          event.preventDefault()
          const sign = event.deltaY < 0 ? -1 : 1
          this.element.nativeElement.scrollTo({
            top: sign * this.speed + this.element.nativeElement.scrollTop,
          })
        }
      )
    })
  }
}
