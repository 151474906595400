import {
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core'
import {
  EXPLORE_MULTI_SELECT_EXPORT_DEFAULT_STYLES,
  EXPLORE_SUMMARY_HEADERS,
  ExploreFilterMap,
  ExploreMultiSelectExportLayoutPanel,
  ExploreMultiSelectExportPanel,
  ExploreMultiSelectExportStyles,
  exploreSummaryColumnDefs,
  ExploreSummaryHeader,
  SummaryDataResponse,
  SummaryExportGroup,
  SummaryTableColorPallette,
} from '../../../explore.model'
import {
  extractFilterMapKeys,
  getGroupedSummaryData,
  updateRPColumnDefLabel,
} from '../../../explore.util'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import {
  AccountOpportunity,
  ExploreSummaryView,
  StudyResponse,
} from 'src/app/api/model/backend.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import { buildChartData } from '../../../explore-gross-summary-charts/summary-charts-util'
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import {
  GrossSummaryExportMultiSheet,
  GrossSummaryExportXlsxService,
} from '../../gross-summary-table-export-xlsx.service'
import { Client } from 'src/app/core/model/client.model'
import { Program } from 'src/app/core/model/program.model'
import { DatePipe } from '@angular/common'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-multi-select-export-preview-page',
  templateUrl: './explore-multi-select-export-preview-page.component.html',
  styleUrls: ['./explore-multi-select-export-preview-page.component.scss'],
})
export class ExploreMultiSelectExportPreviewPageComponent
  implements OnChanges, AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('componentRoot', { static: true })
  componentRoot: ElementRef<HTMLDivElement>

  constructor(
    private cdr: ChangeDetectorRef,
    private exportService: GrossSummaryExportXlsxService,
    private datePipe: DatePipe
  ) {}

  @Input() panels: ExploreMultiSelectExportPanel[]
  @Input() layoutPanels: ExploreMultiSelectExportLayoutPanel[] | null
  @Input() summaryExportGroups: SummaryExportGroup[]
  @Input() summaryHeaders: ExploreSummaryHeader[]
  @Input() summaryColumnFilterMap: ExploreFilterMap
  @Input() currentStructureCurrency: string | undefined
  @Input() summaryData: SummaryDataResponse[]
  @Input() summaryFilterMap: ExploreFilterMap
  @Input() summaryRP: number[]
  @Input() mappingLabels: MappingLabels
  @Input() chartEntityLimit: number
  @Input() darkMode: boolean
  @Input() client: Client
  @Input() study: StudyResponse
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() selectedStructure: Program
  zipLoading: boolean = false
  width: number = 1500
  height: number = 750
  defaultStyles: ExploreMultiSelectExportStyles
  colorPalette = SummaryTableColorPallette

  get zipButtonText(): string {
    if (this.zipLoading) {
      return 'Zipping...'
    } else {
      return 'Export Zip'
    }
  }

  @Output() updatePreviewMode = new EventEmitter()

  ngOnInit(): void {
    this.initializeComponent()
  }

  ngAfterViewInit(): void {
    this.initializeComponent()
  }

  initializeComponent(): void {
    if (this.componentRoot?.nativeElement) {
      this.width = this.componentRoot.nativeElement.offsetWidth - 32
      this.height = this.componentRoot.nativeElement.offsetHeight - 32
    }
    this.defaultStyles = EXPLORE_MULTI_SELECT_EXPORT_DEFAULT_STYLES
    if (this.layoutPanels) {
      this.generatePanelStructures()
    }
  }

  ngOnChanges(): void {
    if (this.layoutPanels) {
      this.generatePanelStructures()
    }
  }

  ngOnDestroy(): void {
    this.componentRoot = null
  }

  generatePanelStructures(): void {
    const panels: ExploreMultiSelectExportLayoutPanel[] = []
    for (const panel of this.layoutPanels) {
      if (panel.panel.type === 'table') {
        const groupRows = getGroupedSummaryData(
          panel.group.data,
          this.summaryData,
          panel.view.modeling.filter(x => x !== ''),
          panel.group.groups,
          false,
          panel.group.rows,
          this.currentStructureCurrency,
          panel.group.filteredLossSets,
          'M'
        )
        const { defs, headers, subheaders } = this.filterColumnDefs(panel, true)
        const finalDefsAndHeaders = this.getFinalDefsAndHeaders(defs, headers)
        panels.push({
          ...panel,
          groupRows,
          defs: finalDefsAndHeaders.defs,
          headers: finalDefsAndHeaders.headers,
          subheaders,
        })
      }
      if (panel.panel.type === 'charts') {
        const groupRows = getGroupedSummaryData(
          panel.group.data,
          this.summaryData,
          panel.view.modeling.filter(x => x !== ''),
          panel.group.groups,
          false,
          panel.group.rows,
          this.currentStructureCurrency,
          panel.group.filteredLossSets,
          'M'
        )
        const chartGridOptions = buildChartData(
          panel.panel.chartGridOptions,
          panel.group.data,
          this.summaryRP,
          this.currentStructureCurrency
        )
        panels.push({
          ...panel,
          groupRows,
          panel: {
            ...panel.panel,
            chartGridOptions,
          },
        })
      }
      if (panel.panel.type === 'titles') {
        panels.push({
          ...panel,
        })
      }
    }
    this.layoutPanels = panels
  }

  filterColumnDefs(
    panel: ExploreMultiSelectExportLayoutPanel,
    scale: boolean
  ): {
    defs: SortTableColumnDef[]
    headers: ExploreSummaryHeader[]
    subheaders: string[]
  } {
    const summaryFilterOptions = [
      { label: 'Loss Type', value: 'loss_type' },
      { label: 'Loss Scale Factor', value: 'lossScaleFactor' },
      { label: 'Premium Scale Factor', value: 'premiumScaleFactor' },
      { label: 'LS Dim 1', value: 'ls_dim1' },
      { label: 'LS Dim 2', value: 'ls_dim2' },
      { label: this.mappingLabels.map1, value: 'map1' },
      { label: this.mappingLabels.map2, value: 'map2' },
      { label: this.mappingLabels.map3, value: 'map3' },
      { label: this.mappingLabels.map4, value: 'map4' },
      { label: this.mappingLabels.map5, value: 'map5' },
    ]
    let defs = exploreSummaryColumnDefs.map(def => {
      let label = def.label
      if (!isNaN(Number(def.label))) {
        label = updateRPColumnDefLabel(Number(def.label), this.summaryRP)
      }
      let width = def.width
      let maxWidth = def.maxWidth
      let minWidth = def.minWidth
      const colorScheme = {
        color: this.darkMode ? 'white' : 'black',
        backgroundColor: this.darkMode ? 'black' : 'white',
      }
      if (width) {
        width = scale ? this.scaleTableWidth(width, 0.5) : width
      }
      if (maxWidth) {
        maxWidth = scale ? this.scaleTableWidth(maxWidth, 0.5) : width
      }
      if (minWidth) {
        minWidth = scale ? this.scaleTableWidth(minWidth, 0.5) : width
      }
      const bodyStyle = {
        ...def.bodyStyle,
        fontSize: '10px',
        maxWidth,
        minWidth: width,
      }
      const headerStyle = {
        ...def.bodyStyle,
        fontSize: '10px',
        maxWidth,
        minWidth: width,
        ...colorScheme,
      }
      return {
        ...def,
        label,
        width,
        maxWidth,
        minWidth: width,
        bodyStyle,
        headerStyle,
      }
    })
    const model = panel.view.modeling.filter(x => x !== '')
    const panelFilterMap: ExploreFilterMap = {}
    panel.panel.columns.forEach(c => {
      panelFilterMap[c.value] = c.selected
    })
    const map = extractFilterMapKeys(panelFilterMap, '_')
    const isGroupBy = model.length > 0
    let subheaders: string[] | null = []
    const removeLarge =
      !panelFilterMap['Large'] ||
      !panel.group.viewSummaryFilterMap['loss_type~large']
    if (isGroupBy) {
      defs = defs.map(d => {
        subheaders = model.map(key => {
          const label = summaryFilterOptions.find(o => o.value === key).label
          return label ?? key
        })
        let label = String(d.label)
        if (d.id === 'groupBy') {
          label = subheaders.filter(x => x !== '').join('/')
        } else if (d.id === 'totalContributionToGroupVolatility') {
          label = `Total Contribution to ${subheaders.filter(x => x !== '')[0]} Volatility (Downside)`
        } else if (d.label === 'Attritional') {
          label = 'Attr'
        }
        return {
          ...d,
          label,
        }
      })
    }
    if (map) {
      defs = defs.filter(def => {
        const keyValue = String(def.id).split('_')[0]
        const typeKeyValue = String(def.id).split('_')[1]
        return Object.keys(map).every(key => {
          const allowedValues = map[key]
          if (
            keyValue === 'groupBy' ||
            keyValue === 'totalContributionToGroupVolatility' ||
            keyValue === 'contributionToGroupVolatility' ||
            (keyValue === 'largeRiskFrequency' && isGroupBy && !removeLarge) ||
            (keyValue === 'largeRiskSeverity' && isGroupBy && !removeLarge)
          ) {
            if (
              keyValue === 'contributionToGroupVolatility' &&
              (String(def.id).includes('Attritional') ||
                String(def.id).includes('Large') ||
                String(def.id).includes('Cat'))
            ) {
              return (
                allowedValues.includes(typeKeyValue) &&
                allowedValues.includes(keyValue) &&
                isGroupBy
              )
            }
            return isGroupBy
          }
          if (
            String(def.id).toLocaleLowerCase().includes('large') &&
            removeLarge
          ) {
            return false
          } else if (
            String(def.id).includes('Attritional') ||
            String(def.id).includes('Large') ||
            String(def.id).includes('Cat')
          ) {
            return (
              allowedValues.includes(typeKeyValue) &&
              allowedValues.includes(keyValue)
            )
          }
          return allowedValues.includes(keyValue)
        })
      })
    }
    let removeTypeHeaders = false
    let headers = EXPLORE_SUMMARY_HEADERS.map(header => {
      let label = header.label
      let width = header.width
      if (isGroupBy) {
        if (header.id === 'contributionToGroupVolatility') {
          label = `Contribution to ${subheaders.filter(x => x !== '')[0]} Volatility by Type (Downside)`
        }
      }
      if (header.label.includes('by Type')) {
        let w = 0
        if (panelFilterMap['Attritional']) {
          w += 90
        }
        if (
          panelFilterMap['Large'] &&
          panel.group.viewSummaryFilterMap['loss_type~large']
        ) {
          w += 90
        }
        if (panelFilterMap['Cat']) {
          w += 90
        }
        if (w === 0) {
          removeTypeHeaders = true
        }
        width = String(w)
      }
      return {
        ...header,
        label,
        width: scale ? this.scaleTableWidth(width, 0.5) : width,
      }
    }).filter(header => {
      const id = header.id
      if (header.label.includes('By Type') && removeTypeHeaders) {
        return false
      }
      if (
        id === 'groupBy' ||
        id === 'totalContributionToGroupVolatility' ||
        id === 'contributionToGroupVolatility' ||
        (id === 'largeRisk' && isGroupBy && !removeLarge)
      ) {
        return isGroupBy
      }
      return Object.keys(map).every(key => {
        const allowedValues = map[key]
        if (id.toLocaleLowerCase().includes('large') && removeLarge) {
          return false
        }
        return allowedValues.includes(id)
      })
    })
    return {
      defs,
      headers,
      subheaders,
    }
  }

  getFinalDefsAndHeaders(
    originalDefs: SortTableColumnDef[],
    originalHeaders: ExploreSummaryHeader[]
  ): {
    defs: SortTableColumnDef[][]
    headers: ExploreSummaryHeader[][]
  } {
    let total = 0
    const primaryDefs: SortTableColumnDef[] = []
    const secondaryDefs: SortTableColumnDef[] = []
    const baseHeaders = [originalHeaders[0], originalHeaders[1]]
    const primaryHeaders = [...baseHeaders]
    const secondaryHeaders = [...baseHeaders]
    originalHeaders.forEach((h, i) => {
      total += Number(h.width)
      if (i > 1) {
        if (total < this.width) {
          primaryHeaders.push(h)
        } else {
          secondaryHeaders.push(h)
        }
      }
    })
    originalDefs.forEach(d => {
      const primaryHeaderIDs = primaryHeaders.map(h => h.id)
      const secondaryHeaderIDs = secondaryHeaders.map(h => h.id)
      const headerID = String(d.id).split('_')[0]
      if (headerID === 'largeRisk') {
      }
      if (primaryHeaderIDs.includes(headerID)) {
        primaryDefs.push(d)
      }
      if (secondaryHeaderIDs.includes(headerID)) {
        secondaryDefs.push(d)
      }
    })
    const defs = [primaryDefs]
    const headers = [primaryHeaders]
    if (secondaryHeaders.length > 2) {
      defs.push(secondaryDefs)
      headers.push(secondaryHeaders)
    }
    return {
      defs,
      headers,
    }
  }

  getLayoutStyle(
    parent: keyof ExploreMultiSelectExportStyles,
    item: string
  ): { [key: string]: string } {
    const section = this.defaultStyles[parent]
    if (section && typeof section === 'object' && item in section) {
      const style = section[item as keyof typeof section]
      if (typeof style === 'object' && style !== null) {
        const adjustedStyle = { ...style } as { [key: string]: string }
        if (!this.darkMode && adjustedStyle.color === 'white') {
          adjustedStyle.color = 'black'
        }
        return adjustedStyle
      }
    }
    return {}
  }

  getSubheaderColor(index: number, modelingArray: string[]): string {
    const model = modelingArray.filter(x => x !== '')
    let i = index
    const lastLevel = model.length - 1
    if (index > 0 && index === lastLevel) {
      i = 4
    }
    return this.colorPalette[i]
  }

  scaleTableWidth(originalWidth: string, scale: number): string {
    if (originalWidth.includes('px')) {
      const w = Number(originalWidth.replace('px', ''))
      return `${w * scale}px`
    } else {
      return String(Number(originalWidth) * scale)
    }
  }

  getChartHeight(grid: string): { [key: string]: string } {
    return { height: `${this.height * 0.6}px` }
  }

  async exportPanels(): Promise<void> {
    this.zipLoading = true
    const zip = new JSZip()
    const clientName = this.client.name
    const year = this.client.clientYears.find(
      y => Number(y.id) === this.study.carrier_year_id
    ).year
    const program = this.study.name
    const structure = this.selectedStructure.label
    const currency = this.currentStructureCurrency
    const accOpp = this.accountOpportunities?.find(
      opp => opp.id === this.study?.opportunity_id
    )
    const accOppOnIncepDate = accOpp?.opportunityInceptionDate
    let effectiveDate = ''
    if (accOppOnIncepDate) {
      const parts = accOppOnIncepDate.split('-')
      const date = new Date(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10)
      ).toString()
      effectiveDate = this.datePipe.transform(date, 'longDate') || ''
    }
    const excelExport: GrossSummaryExportMultiSheet = {
      worksheetsData: [],
      fileName: '',
      options: {
        isGroupBy: true,
        currency,
        roundedValue: 'M',
        indLossSets: false,
      },
      titleOptions: {
        clientName,
        program,
        structure,
        effectiveDate,
      },
    }
    this.summaryExportGroups.forEach(group => {
      const matchingPanels = this.layoutPanels.filter(
        p => p.group && p.group.name === group.name
      )
      let defs = exploreSummaryColumnDefs.map(def => {
        let label = def.label
        if (!isNaN(Number(def.label))) {
          label = updateRPColumnDefLabel(Number(def.label), this.summaryRP)
        }
        if (def.id === 'groupBy') {
          const summaryFilterOptions = [
            { label: 'Loss Type', value: 'loss_type' },
            { label: 'Loss Scale Factor', value: 'lossScaleFactor' },
            { label: 'Premium Scale Factor', value: 'premiumScaleFactor' },
            { label: 'LS Dim 1', value: 'ls_dim1' },
            { label: 'LS Dim 2', value: 'ls_dim2' },
            { label: this.mappingLabels.map1, value: 'map1' },
            { label: this.mappingLabels.map2, value: 'map2' },
            { label: this.mappingLabels.map3, value: 'map3' },
            { label: this.mappingLabels.map4, value: 'map4' },
            { label: this.mappingLabels.map5, value: 'map5' },
          ]
          const subheaders = matchingPanels[0].view.modeling
            .filter(x => x !== '')
            .map(key => {
              const label = summaryFilterOptions.find(
                o => o.value === key
              )?.label
              return label ?? key
            })
          label = subheaders.filter(x => x !== '').join('/')
        }
        return {
          ...def,
          label,
        }
      })
      let headers = EXPLORE_SUMMARY_HEADERS
      const rows = getGroupedSummaryData(
        matchingPanels[0].group.data,
        this.summaryData,
        matchingPanels[0].view.modeling.filter(x => x !== ''),
        matchingPanels[0].group.groups,
        false,
        matchingPanels[0].group.rows,
        this.currentStructureCurrency,
        matchingPanels[0].group.filteredLossSets,
        'M',
        true
      )
      const tablePanel = matchingPanels.find(p => p.panel.type === 'table')
      if (tablePanel) {
        const panelComps = this.filterColumnDefs(tablePanel, false)
        defs = panelComps.defs
        headers = panelComps.headers
      }
      excelExport.worksheetsData.push({
        name: `${group.name}-summary-table`,
        data: rows,
        columnDefs: defs,
        topHeaders: headers,
      })
    })
    const panelsElement = this.componentRoot.nativeElement.querySelector(
      '.panels'
    ) as HTMLElement

    const panelElements = panelsElement.querySelectorAll('.panel')

    const originalPanelStyles = {
      width: panelsElement.style.width,
      height: panelsElement.style.height,
      overflow: panelsElement.style.overflow,
    }

    panelsElement.style.width = `${panelsElement.scrollWidth}px`
    panelsElement.style.height = `${panelsElement.scrollHeight}px`
    panelsElement.style.overflow = 'visible'

    const entireImage = await html2canvas(panelsElement, { scale: 2 })
    const entireImageData = entireImage.toDataURL('image/png')
    const groupNames = this.summaryExportGroups.map(group => group.name)
    const prefix: string[] = []
    const titles = this.panels.some(p => p.type === 'titles')
    const table = this.panels.some(p => p.type === 'table')
    const charts = this.panels.some(p => p.type === 'charts')
    if (titles) {
      prefix.push('titles')
    }
    if (table) {
      prefix.push('tables')
    }
    if (charts) {
      prefix.push('charts')
    }
    const fileName = `${prefix.join('-')}-for-${groupNames.join('-')}`
    zip.file(`${fileName}.png`, entireImageData.split(',')[1], { base64: true })

    panelsElement.style.width = originalPanelStyles.width
    panelsElement.style.height = originalPanelStyles.height
    panelsElement.style.overflow = originalPanelStyles.overflow

    for (let i = 0; i < panelElements.length; i++) {
      const panelElement = panelElements[i] as HTMLElement
      const panel = this.layoutPanels[i]
      const originalPanelElementStyles = {
        width: panelElement.style.width,
        height: panelElement.style.height,
        overflow: panelElement.style.overflow,
      }
      panelElement.style.width = `${panelElement.scrollWidth}px`
      panelElement.style.height = `${panelElement.scrollHeight}px`
      panelElement.style.overflow = 'visible'

      const panelImage = await html2canvas(panelElement, { scale: 2 })
      const panelImageData = panelImage.toDataURL('image/png')
      const panelNameParts = []
      if (panel.view && panel.panel.type !== 'titles') {
        panelNameParts.push(`${panel.view.name}-`)
      }
      panelNameParts.push(panel.panel.type)
      if (panel.panel.type === 'charts') {
        panelNameParts.push(`-${panel.panel.chartGrid.value}`)
      }
      const panelName = panelNameParts.join('')
      zip.file(`${panelName}.png`, panelImageData.split(',')[1], {
        base64: true,
      })
      panelElement.style.width = originalPanelElementStyles.width
      panelElement.style.height = originalPanelElementStyles.height
      panelElement.style.overflow = originalPanelElementStyles.overflow
      const chartsContainer = panelElement.querySelector(
        '.charts'
      ) as HTMLElement
      if (chartsContainer) {
        chartsContainer.style.flexWrap = 'nowrap'
      }
      const chartContainers = panelElement.querySelectorAll('.chart-option')
      const chartNames: string[] = []
      for (let j = 0; j < chartContainers.length; j++) {
        const chart = panel.panel.chartGridOptions[j]
        let chartName = `${panel.view.name}-${chart.metric.shortName}-${chart.chart.type}`
        if (!chartNames.includes(chartName)) {
          chartNames.push(chartName)
        } else {
          const chartNameCounts: Record<string, number> = {}
          for (const name of chartNames) {
            chartNameCounts[name] = (chartNameCounts[name] || 0) + 1
          }
          chartName = `${chartName}(${chartNameCounts[chartName]})`
        }

        const data = getGroupedSummaryData(
          panel.group.data,
          this.summaryData,
          panel.view.modeling.filter(x => x !== ''),
          panel.group.groups,
          false,
          panel.group.rows,
          this.currentStructureCurrency,
          panel.group.filteredLossSets,
          'M',
          true
        )
        const columnDefs = exploreSummaryColumnDefs
          .filter((def, i) => i < 2 || chart.metric.columnIDs.includes(String(def.id)))
          .map(def => {
            let label = def.label
            if (!isNaN(Number(def.label))) {
              label = updateRPColumnDefLabel(Number(def.label), this.summaryRP)
            }
            if (def.id === 'groupBy') {
              const summaryFilterOptions = [
                { label: 'Loss Type', value: 'loss_type' },
                { label: 'Loss Scale Factor', value: 'lossScaleFactor' },
                { label: 'Premium Scale Factor', value: 'premiumScaleFactor' },
                { label: 'LS Dim 1', value: 'ls_dim1' },
                { label: 'LS Dim 2', value: 'ls_dim2' },
                { label: this.mappingLabels.map1, value: 'map1' },
                { label: this.mappingLabels.map2, value: 'map2' },
                { label: this.mappingLabels.map3, value: 'map3' },
                { label: this.mappingLabels.map4, value: 'map4' },
                { label: this.mappingLabels.map5, value: 'map5' },
              ]
              const subheaders = panel.view.modeling
                .filter(x => x !== '')
                .map(key => {
                  const label = summaryFilterOptions.find(
                    o => o.value === key
                  )?.label
                  return label ?? key
                })
              label = subheaders.filter(x => x !== '').join('/')
            }
            return {
              ...def,
              label,
            }
          })
        
        const topHeaders = EXPLORE_SUMMARY_HEADERS
          .filter((header, i) => i < 2 || chart.metric.headerIDs.includes(String(header.id)))

        const chartElement = chartContainers[j] as HTMLElement
        const backgroundColor = this.darkMode ? 'black' : 'white'
        if (chart.chart.type === 'donut' || chart.chart.type === 'pie') {
          const originalChartStyles = {
            width: chartElement.style.width,
            height: chartElement.style.height,
            overflow: chartElement.style.overflow,
            margin: chartElement.style.margin,
            backgroundColor: chartElement.style.backgroundColor,
            display: chartElement.style.display,
            flexDirection: chartElement.style.flexDirection,
            justifyContent: chartElement.style.justifyContent,
            alignItems: chartElement.style.alignItems,
          }

          const childElements = chartElement.querySelectorAll('*')
          const originalChildStyles = Array.from(childElements).map(child => ({
            element: child as HTMLElement,
            overflow: (child as HTMLElement).style.overflow,
          }))

          chartElement.style.width = `${chartElement.scrollWidth + 48}px`
          chartElement.style.height = `${chartElement.scrollHeight + 48}px`
          chartElement.style.overflow = 'visible'
          chartElement.style.backgroundColor = backgroundColor
          chartElement.style.display = 'flex'
          ;(chartElement.style.flexDirection = 'column'),
            (chartElement.style.justifyContent = 'center'),
            (chartElement.style.alignItems = 'center'),
            // Update child elements' overflow
            childElements.forEach(child => {
              ;((child as HTMLElement).style.overflow = 'visible'),
                ((child as HTMLElement).style.width =
                  `${chartElement.scrollWidth}px`),
                ((child as HTMLElement).style.height =
                  `${chartElement.scrollHeight}px`)
            })

          const chartImage = await html2canvas(chartElement, { scale: 2 })
          const chartImageData = chartImage.toDataURL('image/png')
          excelExport.worksheetsData.push({
            name: chartName,
            data,
            columnDefs,
            topHeaders,
            chartMetric: chart.metric.name,
            base64Image: chartImageData
          })
          zip.file(`${chartName}.png`, chartImageData.split(',')[1], {
            base64: true,
          })

          chartElement.style.width = originalChartStyles.width
          chartElement.style.height = originalChartStyles.height
          chartElement.style.overflow = originalChartStyles.overflow
          chartElement.style.margin = originalChartStyles.margin
          chartElement.style.backgroundColor =
            originalChartStyles.backgroundColor
          chartElement.style.display = originalChartStyles.display
          chartElement.style.flexDirection = originalChartStyles.flexDirection
          chartElement.style.justifyContent = originalChartStyles.justifyContent
          chartElement.style.alignItems = originalChartStyles.alignItems

          originalChildStyles.forEach(({ element, overflow }) => {
            element.style.overflow = overflow
          })
        } else if (chart.chart.type === 'verticalArea') {
          const originalChartStyles = {
            height: chartElement.style.height,
            overflow: chartElement.style.overflow,
            backgroundColor: chartElement.style.backgroundColor,
          }
          chartElement.style.height = `${chartElement.scrollHeight + 48}px`
          chartElement.style.overflow = 'visible'
          chartElement.style.backgroundColor = backgroundColor

          const chartImage = await html2canvas(chartElement, { scale: 2 })
          const chartImageData = chartImage.toDataURL('image/png')
          zip.file(`${chartName}.png`, chartImageData.split(',')[1], {
            base64: true,
          })
          excelExport.worksheetsData.push({
            name: chartName,
            data,
            columnDefs,
            topHeaders,
            chartMetric: chart.metric.name,
            base64Image: chartImageData
          })
          chartElement.style.height = originalChartStyles.height
          chartElement.style.overflow = originalChartStyles.overflow
          chartElement.style.backgroundColor =
            originalChartStyles.backgroundColor
        } else {
          const chartImage = await html2canvas(chartElement, { scale: 2 })
          const chartImageData = chartImage.toDataURL('image/png')
          zip.file(`${chartName}.png`, chartImageData.split(',')[1], {
            base64: true,
          })
          excelExport.worksheetsData.push({
            name: chartName,
            data,
            columnDefs,
            topHeaders,
            chartMetric: chart.metric.name,
            base64Image: chartImageData
          })
        }
      }
      if (chartsContainer) {
        chartsContainer.style.flexWrap = 'wrap'
      }
    }
    try {
      const { worksheetsData, fileName, options, titleOptions } = excelExport
      const workbook = await this.exportService.exportMultiSheetSummaryXlsx(
        worksheetsData,
        fileName,
        options,
        titleOptions
      )
      const base64 = workbook.base64.split(',')[1]
      zip.file(`${fileName}-summary-tables.xlsx`, base64, { base64: true })
    } catch (error) {
      console.error('Error generating zip:', error)
    }
    zip
      .generateAsync({ type: 'blob' })
      .then(blob => {
        saveAs(blob, `${fileName}.zip`)
        this.zipLoading = false
        this.cdr.detectChanges()
      })
      .catch(error => {
        console.error('Error generating zip file:', error)
        this.zipLoading = false
        this.cdr.detectChanges()
      })
  }
}
