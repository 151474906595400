import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core'
import {
  PricingCurveStatus,
} from '../../../model/pricing-curve.model'
import {
  EXPORT_COLUMNS,
  ExportRowDef,
} from '../../../model/pricing-curve-table.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { MatPaginator } from '@angular/material/paginator'
import { SelectionsChangeEvent } from '@shared/util/selections'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-export-form',
  styleUrls: ['./pricing-curve-export-form.component.scss'],
  templateUrl: './pricing-curve-export-form.component.html',
})
export class PricingCurveExportFormComponent implements OnInit {
  @Input() pricingCurves: PricingCurve[]
  @Input() status: PricingCurveStatus
  exportColumnDefs: SortTableColumnDef[] = EXPORT_COLUMNS
  exportRows: ExportRowDef[] = []

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  @Output() selectionChanged = new EventEmitter()
  @Output() exportNameChanged = new EventEmitter()

  ngOnInit(): void {
    this.exportRows = this.pricingCurves.map(curve => ({
      id: curve.id,
      label: curve.label,
      include: curve.layersVisible || curve.lineVisible,
    }))
    this.selectionChanged.emit(
      this.exportRows.filter(row => row.include).map(row => row.id)
    )
  }

  onSelectedChange($event: SelectionsChangeEvent) {
    const keys = Object.entries($event.selections.dictionary)
      .filter(([_, value]) => !!value)
      .map(value => Number(value[0]))
    this.selectionChanged.emit(keys)
  }
}
