import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { TechnicalPremiumRoutingModule } from './technical-premium-routing.module'
import { TechnicalPremiumContentContainerComponent } from './technical-premium-content-container/technical-premium-content.container'
import { TechnicalPremiumContentComponent } from './technical-premium-content/technical-premium-content.component'
import { TechnicalPremiumListEntryComponent } from './technical-premium-list-entry/technical-premium-list-entry.component'
import { ProgramDefaultListComponent } from './program-default-list/program-default-list.component'
import { LayerDefaultListComponent } from './layer-default-list/layer-default-list.component'
import { AddCurveDialogComponent } from './add-curve-dialog/add-curve-dialog.component'

@NgModule({
  declarations: [
    TechnicalPremiumContentContainerComponent,
    TechnicalPremiumContentComponent,
    TechnicalPremiumListEntryComponent,
    ProgramDefaultListComponent,
    LayerDefaultListComponent,
    AddCurveDialogComponent,
  ],
  imports: [
    SharedModule,
    TechnicalPremiumRoutingModule,
  ],
})
export class TechnicalPremiumModule {}
