import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { debounceTime, filter, Subject, tap } from 'rxjs'
import { Client, ClientYear } from './../../../core/model/client.model'
import { Study } from './../../../core/model/study.model'
import { TierPath } from '../../tier.model'
import { ProgramsYearsFilter } from '../credit-tier.model'

@Component({
  selector: 'app-credit-tier-bar-multiselect',
  templateUrl: './credit-tier-bar-multiselect.component.html',
  styleUrls: ['./credit-tier-bar-multiselect.component.scss'],
})
export class CreditTierBarMultiselectComponent implements OnInit, OnChanges {
  private updateFilters$ = new Subject()
  private selectedYears: ClientYear[] = []
  private selectedPrograms: Study[] = []

  disabledYearId: string
  disabledProgramId: string

  @Input() clients: Client[]
  @Input() years: ClientYear[]
  @Input() programs: Study[]
  @Input() selectedClientId: string

  private _filters: ProgramsYearsFilter
  @Input() set filters(value: ProgramsYearsFilter) {
    this._filters = value
    this.selectedYears = value.years
    this.selectedPrograms = value.programs
  }
  get filters() {
    return this._filters
  }

  @Output() tierChange = new EventEmitter<TierPath>()
  @Output() updateFilters = new EventEmitter<ProgramsYearsFilter>()

  ngOnInit(): void {
    this.updateFilters$
      .pipe(
        filter(() => this.validFilters()),
        debounceTime(1500),
        tap(() => this.updateFilters.emit(this.getFilters()))
      )
      .subscribe()
  }

  ngOnChanges(): void {
    this.disabledProgramId =
      this.filters.programs.length === 1 ? this.filters.programs[0].id : ''
    this.disabledYearId =
      this.filters.years.length === 1 ? this.filters.years[0].id : ''
  }

  onClientChange(client: string): void {
    if (client !== this.selectedClientId) {
      this.tierChange.emit({
        client,
        year: null,
        program: null,
        structure: null,
      })
    }
  }

  onYearChange(years: ClientYear[]): void {
    if (years.length < 1) {
      return
    }
    this.selectedYears = years
    this.updateFilters$.next('')
  }

  onProgramChange(programs: Study[]): void {
    if (programs.length < 1) {
      return
    }
    this.selectedPrograms = programs
    this.updateFilters$.next('')
  }

  private validFilters(): boolean {
    return this.selectedYears.length > 0 && this.selectedPrograms.length > 0
  }

  private getFilters(): ProgramsYearsFilter {
    return {
      years: this.selectedYears,
      programs: this.selectedPrograms,
    }
  }
}
