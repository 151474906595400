import { StudyData } from '../../admin/model/admin.model'
import {
  SharedLimit,
  SharedLimitMember,
} from 'src/app/analysis/store/grouper/program-group.model'
import { UserPermissions } from '../../core/model/auth.model'
import { CompareView } from '../../analysis/model/compare-view.model'
import { Folder, MappingLabels } from 'src/app/core/model/study.model'

export interface AnalyzeReAuthResponse {
  authToken: string
}

export interface PermissionsResponse {
  carrierYears: CarrierYearResponse[]
  permissions: UserPermissions
  specialPermissions: string[]
  marketToken: MarketTokenResponse
  blobSAS: BlobResponse[]
  carriers: CarrierResponse[]
  studies: StudyResponse[]
  programs: ProgramResponse[]
  programGroups: ProgramGroupResponse[]
  programGroupMembers: ProgramGroupMemberResponse[]
  structureLayerData: StructureLayerDataResponse[]
  structureMarketLayer: StructureMarketLayersResponse[]
  structureTowerPreferences: StructureTowerPreferencesResponse[]
  structureMarketContent: StructureMarketContentResponse[]
  programLossSetGroups: LossSetGroupResponse[]
  programLossSetGroupMembers: LossSetGroupMemberResponse[]
  authenticatingUser: string
  accountOpportunities: AccountOpportunity[]
  carrierAccountMap: CarrierAccount[]
  sageLogicalRole: string
  featureFlags: FeatureFlag[]
  sharedLimits: SharedLimit[]
  sharedLimitMembers: SharedLimitMember[]
  compareViews: CompareView[]
  hasWhitespaceAccess: boolean | null
  usaBasedUser: boolean | null
  facSageUrl: string | null
  salesforceUserRole: string | null
}

export interface CarrierYearResponse {
  id: number
  year: number
  carrier_id: number
}

export interface MarketTokenResponse {
  id: string
  username: string
  token: string
}

export interface BlobResponse {
  url: string
  container_name: string
  token: string | null
}

export interface CarrierResponse {
  id: number
  name: string
  tax_rate: number
  roe_sector: number
  executive_summary: string
  eula: string
  esp_volatility_sector: number
  intercept: number
  book_value: number
  prospective_roe: number
  eps_volatility: number
  surplus: number
  roe_target: number
  sf_acctid?: string
  isDemo: boolean
}

export interface StudyResponse {
  id: number
  carrier_year_id: number
  name: string
  description: string
  folders: Folder[]
  opportunity_id?: string
  sage_view?: string
  credit_sage_portfolio_id?: string
  executive_summary: string
  mapping_labels?: MappingLabels
  explore_gross_views?: ExploreSummaryView[]
}

export interface ExploreSummaryView {
  id: number
  name: string
  modeling: string[]
  filters: string[]
  columnFilters: string[]
  showIndividualLossSets: boolean
  isDefault: boolean
  grossProfileId: string
  programId: number
}

export interface StudyDetailsResponse {
  study: StudyResponse
  year?: number
  carrierName: string
}

export interface ProgramRequest {
  study_id?: number
  label?: string
  program_type?: string
  gross_portfolio_id?: string
  ceded_portfolio_id?: string
  net_portfolio_id?: string
  analysis_profile_id?: string
}

export interface CarrierRequest {
  name: string
  tax_rate?: number
  roe_sector?: number
  executive_summary: string
  eula?: string
  esp_volatility_sector?: number
  intercept?: number
  book_value?: number
  prospective_roe?: number
  eps_volatility?: number
  surplus?: number
  roe_target?: number
}

export interface StudyRequest {
  carrier_year_id: number
  name: string
  description: string
}

export interface SageViewRequest {
  sageViewName: string
}

export interface ProgramResponse {
  id: number
  study_id: number
  label: string
  program_type: string
  gross_portfolio_id: string
  ceded_portfolio_id: string
  net_portfolio_id: string
  parent_gross_portfolio_id?: string
  analysis_profile_id: string
  layer_data?: StructureLayerDataResponse[]
  structure_market_layers?: StructureMarketLayersResponse[]
  tower_preferences?: StructureTowerPreferencesResponse
  market_content?: StructureMarketContentResponse[]
  description: string
  is_scenario?: boolean
  parent_scenario_id?: number
  tremor?: string
  carrier_year_id: number
  position_index?: number
  is_optimization?: boolean
  parent_optimization_id?: number
  tail_metrics_options?: string
  last_modified?: string
  libRE?: string
  structure_currency?: string
  autobuild_id?: string
  folder_id?: string | null | undefined
  fot_count?: number
  quote_count?: number
}

export interface ProgramGroupResponse {
  id: number
  carrier_id: number
  label: string
  carrier_year_id: number
  description?: string
  gross_portfolio_id?: string
  ceded_portfolio_id?: string
  net_portfolio_id?: string
  analysis_profile_id?: string
  is_scenario?: boolean
  parent_scenario_id?: number
  fot_count?: number
  quote_count?: number
}

export type OmitID<T> = Omit<T, 'id'>

export interface ProgramGroupMemberResponse {
  id: number
  parent_group_id: number
  carrier_id: number
  program_id: number | null
  program_group_id: number | null
  ordinal: number
  type: string
}

export interface StructureLayerDataResponse {
  id: number
  structure_id: number
  layer_id: string
  starting_cession: number
  color: string
  lossSetGroupIDs?: string[]
}

export interface User {
  id: number
  email_id: string
  first_name: string
  last_name: string
  sage_logical_role: string
}

export interface SalesforceUser {
  id: string
  username: string
  lastName: string
  firstName: string
  email: string
  profileId: string
  userRoleId: string
  federationIdentifier: string
  isActive: boolean
}

export interface UserReinsurer {
  userId: number
  emailId: string
  firstName: string
  lastName: string
  reinsurerId: number
  reinsurerName: string
}

export interface NewUser {
  email_id: string
  first_name: string
  last_name: string
  approval_timestamp?: string
  sage_logical_role: string
}

export interface NewUserRole {
  user_id: number
  carrier_id: string | null
  sage_functionality_id: number
  security_id: number
  create: boolean
}

export interface UserRole {
  Id: number
  user_id: number
  role_id: number
}

export interface Role {
  Id: number
  carrier_id: string
  sage_functionality_id: number
  security_id: number
}

export interface SageFunctionality {
  Id: number
  name: string
  route: string
  editable: boolean
}

export interface LogEvent {
  eventType: string
  eventDetails: any
}

export interface StructureMarketLayersResponse {
  id: number
  structure_id: number
  layer_id: string
  market_reference: string
  underwriter: string
}

export interface StructureMarketContentResponse {
  id: number
  structure_id: number
  name: string
}

export interface StructureTowerPreferencesResponse {
  id: number
  increment_y_agg: number
  increment_y_occ: number
  structure_id: number
  y_max_agg: number
  y_max_occ: number
}

export interface StructureTowerPreferencesRequest {
  increment_y_agg: number
  increment_y_occ: number
  y_max_agg: number
  y_max_occ: number
}

export interface EntitiesResponse {
  permissions: UserPermissions
  marketToken: string
  security: string[]
  blobSAS: BlobResponse[]
  carriers: CarrierResponse[]
  years: CarrierYearResponse[]
  studies: StudyResponse[]
  programs: ProgramResponse[]
  programGroups: ProgramGroupResponse[]
  programGroupMembers: ProgramGroupMemberResponse[]
  lossSetGroups: LossSetGroupResponse[]
  lossSetGroupMembers: LossSetGroupMemberResponse[]
  accountOpportunities: AccountOpportunity[]
  carrierAccountMap: CarrierAccount[]
  featureFlags: FeatureFlag[]
  sharedLimits: SharedLimit[]
  sharedLimitMembers: SharedLimitMember[]
  compareViews: CompareView[]
  hasWhitespaceAccess: boolean | null
  usaBasedUser: boolean | null
  facSageUrl: string | null
}

export interface LossSetGroupResponse {
  id: number
  program_id: string
  name: string
}

export interface LossSetGroupMemberResponse {
  id: number
  name: string
  loss_set_group_id: string
  loss_set_layer_id: string
}

export interface AccountOpportunity {
  id: string
  oppId: string
  accountName: string
  accountId: string
  accountOpenTwinsID?: string
  transactionalAcctId?: string
  transactionalAcctName?: string
  transactionalAcctOpenTWINSId?: string
  opportunityName: string
  opportunityStage: string
  opportunityNextStep?: string
  opportunityCurrency: string
  opportunityBU: string
  opportunityClass?: string
  opportunitySubClass?: string
  opportunityRiskType?: string
  opportunityContractStructure?: string
  opportunityInceptionDate?: string
  opportunityTerminationDate?: string
  opportunityForecastedRevenue?: number
  opportunityRenewedFrom?: string | null
  parentBU?: string | null
  germanInvolvement?: boolean
}

export interface CarrierAccount {
  sf_acctid: string
  carrier_id: number
}

export interface YearData {
  inceptionYear: string
  carrier_id?: number
}

export type StudyDataWithYear = Partial<StudyData> & YearData

export interface PostFacDataElement {
  modifiedDate: string
  name: string
  username: string
  formName: string
  fieldValues: string
  hiddenFields: string
  category: string
  final: boolean
  renewal?: boolean
  type: string
  createdBy: string
}

export interface CreateFacDataReturn {
  username: string
  dealName: string
  dealID: number
  versionID: number
  fieldValues: string
  hiddenFields: string
  category: string
  final: boolean
  modifiedDate: string
  versionName: string
  type: string
  createdBy: string
  salesforceId: string
}

export interface FacPropertySubmission {
  id: number
  named_insured: string
  market_bound: string
  assigned_broker: string
  cedent_company: string
  email_groups: string
}

export interface FacReinsurer {
  id: number
  reinsurerName: string
}

export interface FacBasisOfAcceptance {
  id: number
  basisOfAcceptance: string
}

export interface FacCurrency {
  id: number
  currency: string
}

export interface FacNamedInsured {
  id: number
  namedInsured: string
}

export interface FacReinsuranceConditions {
  id: number
  reinsuranceConditions: string
}

export interface FacTermsAndConditions {
  id: number
  termsAndConditions: string
}

export interface FacTypeOfInsurance {
  id: number
  insuranceType: string
}

export interface FacBinder {
  id: number
  fac_property_id: string
  basis_of_acceptance: string
  cedent_company_full_legal_name: string
  currency: string
  reinsurance_conditions: string
  terms_and_conditions: string
  perils_reinsured: string
  type_of_insurance: string
}

export interface FacMailingGroups {
  id: number
  email_id: string
  first_name: string
  last_name: string
}
export interface FacUsersListResponse {
  data: FacUsersList[]
}

export interface FacUsersList {
  Id: number
  email: string
  name: string
}
export interface FacType {
  id: number
  user_id: number
  fac_type: string
}
export interface FacUserType {
  facUserTypes: FacType
  userDetails: User
}

export interface FacFromSalesForceResponse {
  data: FacFromSalesForce[]
}

export interface FacFromSalesForce {
  id: number
  emailAddress: string
  name: string
  first_name: string
  last_name: string
}

export interface PostEmailElement {
  emailAddress: string
  bcc: string
  subject: string
  bodyHtml: string
  content: string
  pdfName: string
  files: string[]
}

export interface ProcessLog {
  id: string
  type: string
  status: string
  startTime: string
  endTime: string
  createdBy: string
  processLogDetails: ProcessLogDetail[]
}

export interface ProcessLogDetail {
  processLogId: string
  step: string
  message: string
  dateInserted: string
}
export interface CarrierData {
  id: number
  name: string
  roe_sector: number
  esp_volatility_sector: number
  intercept: number
  book_value: number
  prospective_roe: number
  eps_volatility: number
  tax_rate: number
  surplus: number
  roe_target: number
  executive_summary: string
  eula: string
  sf_acctid?: string
}
export interface OTRiskRequest {
  policyFileRef: string
  brokerSupportingPersonRef: string
  placingBrokerRef: string
  producingBrokerRef: string
  contractId: number | undefined
  contractSplits: ContractSplit[]
  isCoRisk: boolean
  leadRiskRef: string | undefined
}

export interface OTRiskResponse {
  processId: string
}

export interface OTCreateNewQuoteRequest {
  sfOpportunityId: string
  riskDesc: string
  clientTpRef: string
  riskType: string
  baseCurrency: string
  coBrokedRiskRef: boolean
}

export interface FeatureFlag {
  featureName: string
  isEnabled: boolean
}

export interface OTRiskConfirmRequest {
  contractId: number | undefined
}

export interface OTRiskConfirmResponse {
  reinsuredTpRef: string
  reinsuredName: string
  reinsuredCity: string
  reinsuredCountry: string
  reinsuredSubCountry: string
  isRenewal: boolean
  isConfirmed: boolean
  confirmErrorMessages: []
  acknowledgements: OTSubmissionAcknowledgement[]
  renewalRisk: OTRiskSearchResult
  contractSplits: ContractSplit[]
}

export interface OTSubmissionAcknowledgement {
  description: string
  isAcknowledged: boolean
  details: string
}

export interface OTRiskSearchResult {
  riskRef: string
  clientName: string
  clientTpRef: string
  descriptionNarr: string
  riskTypeShort: string
  riskDescription: string
  riskVersionStatusEnum: string
  inceptionDateAndTime: string
  expiryDateAndTime: string
}

export interface ContractSplit {
  cobrokerTpRef: string
  cobrokerName: string
  splitPercent: number
  reinstatementSplitPercent: number
}

export interface RiskRefWithSections {
  riskRef: string
  sectionNarrative: string
  sectionNums: number[]
}

export interface RiskSectionWithMarkets {
  riskRef: string
  extendedRiskTypeValue: string
  revenueCode: string
  sectionNarrative: string
  interestNarrative: string
  baseCurrencyCode: string
  sectionNum: number
  selectedLayer: string
  selectedStructure?: number
  occurrenceAttachment?: number
  occurrenceLimit?: number
  maximumLiability?: number
  unlimitedLiabilityIndicator?: boolean
  totalSignedPct?: number
  minimumPremiumAmount?: number
  initialPremiumAmount?: number
  annualAggregateDeductible?: number
  premiumAdjRate?: number
  defaultSageView: string
  markets: RiskMarket[]
  reinstatements: RiskReinstatement[]
}

export interface DisplayRiskSectionWithMarkets extends RiskSectionWithMarkets {
  largestSectionSignedPct?: number
  largestSectionSignedPctReinsurer?: string
}

export interface LayerRow {
  riskRef: string
  occurrenceLimit?: number
  occurrenceAttachment?: number
  selectedLayer: string
  selectedStructure?: number
  sections?: DisplayRiskSectionWithMarkets[]
  largestLayerSignedPct?: number
  largestLayerSignedPctReinsurer?: string
}

export interface RiskReinstatement {
  ripPct?: number
}

export interface RiskMarket {
  marketTpRef: string
  signedPct?: number
  reinsurerName: string
}

export interface B2CConfig {
  policyName: string
  instance: string
  knownAuthority: string
  domain: string
  clientId: string
}

export interface UserInfo {
  approval_timestamp?: string
  id: number
  eulaContent: string
}

export interface BlobWithHeaders {
  blob: Blob
  filename: string
}

export const CONTENT_DISPOSITION = 'Content-Disposition'
