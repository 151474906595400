// const SIDE_WIDTH = 56
// const SCROLL_WIDTH = 12

// const TOWER_WIDTH = 560
// const TOWER_MARGIN = 76 + 35 + 26

// const getAvailableWidth = (totalWidth: number): number =>
//   totalWidth - SIDE_WIDTH

export const calculateTowerSize = (slidesPerView: number): number => {
  // const pageWidth = getAvailableWidth(totalWidth)
  // const slideWidth = Math.floor(pageWidth / slidesPerView)
  // const x = (slideWidth - TOWER_MARGIN - SCROLL_WIDTH) / TOWER_WIDTH
  // const scrollerPercentage = (SCROLL_WIDTH * 2) / slideWidth
  return 1 / slidesPerView
}
