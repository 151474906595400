import { BasicControl } from '../../../management-information/model/management-information.model'
import { IControl } from '../../../management-information/store/management-information.reducer'
import { LayerPaletteProgram } from '../../../analysis/model/layer-palette.model'
import {
  getAudienceLayerNames,
  getDefaultAudienceLayerNames,
} from '../utils/quote-summary.util'

export enum QuoteTabType {
  SUMMARY = 'Summary',
  DETAILS = 'Details',
  CHARTS = 'Charts',
  TRENDS = 'Trends',
  RANKING = 'Ranking',
}
export interface QuoteTab {
  tabType: QuoteTabType
  isDisabled: boolean
  isHidden: boolean
  dataLoading: boolean
  filtersLoading: boolean
  hasCharts: boolean
}

export interface QuoteStats {
  min: number
  avg: number
  max: number
}
export interface LayerQuoteStats extends QuoteStats {
  layerType: string
}

export interface QuoteSelectors {
  clientName: IControl
  programName: IControl
  reinsurer: IControl
  layerName: IControl
  layerType: IControl
  fotQuoteIndicator: IControl
  lineOfBusiness: IControl
  primaryClassOfBusiness: IControl
  perils: IControl
  territory: IControl
  businessUnit: IControl
}

export interface QuoteSelectorsDto {
  clientName: string[]
  programName: string[]
  layerType: string[]
  layerName: string[]
  reinsurer: string[]
  fotQuoteIndicator: string[]
  lineOfBusiness: string[]
  primaryClassOfBusiness: string[]
  territory: string[]
  perils: string[]
}

export interface QuoteSummaryData {
  expiringRates: LayerQuoteStats[]
  expiringExpCedLossByDepositPremium: LayerQuoteStats[]
  expiringExpCedLossByExpCedPremium: LayerQuoteStats[]
  quoteRates: LayerQuoteStats[]
  quoteExpCedLossByDepositPremium: LayerQuoteStats[]
  quoteExpCedLossByExpCedPremium: LayerQuoteStats[]
  fotRates: LayerQuoteStats[]
  fotExpCedLossByDepositPremium: LayerQuoteStats[]
  fotExpCedLossByExpCedPremium: LayerQuoteStats[]
  quotedVsExpiringRates: LayerQuoteStats[]
  quotedVsExpiringCapacity: LayerQuoteStats[]
  fotVsExpiringRates: LayerQuoteStats[]
  fotVsExpiringCapacity: LayerQuoteStats[]
  fotVsQuotedRates: LayerQuoteStats[]
  fotVsQuotedCapacity: LayerQuoteStats[]
  occurrenceLimit: LayerQuoteStats[]
  occurrenceAttachment: LayerQuoteStats[]
  totalBoundLimitVsTotalQuotedLimit: LayerQuoteStats[]
}

export interface DataCounts {
  distinctClients: number
  distinctPrograms: number
  distincLayers: number
  distinctQuotes: number
  distinctFots: number
}

export interface DataCountValues {
  label: string
  id: string
  tooltip: string
  count?: number
}

export const DEFAULT_AUDIENCE: LayerPaletteProgram = 'standard' // overwrites from selected opportunity if default view exists
export const QUOTE_TABS: QuoteTab[] = [
  {
    tabType: QuoteTabType.SUMMARY,
    isDisabled: false,
    isHidden: false,
    dataLoading: false,
    filtersLoading: false,
    hasCharts: false,
  },
  {
    tabType: QuoteTabType.DETAILS,
    isDisabled: false,
    isHidden: false,
    dataLoading: false,
    filtersLoading: false,
    hasCharts: false,
  },
  {
    tabType: QuoteTabType.RANKING,
    isDisabled: false,
    isHidden: false,
    dataLoading: false,
    filtersLoading: false,
    hasCharts: false,
  },
  {
    tabType: QuoteTabType.CHARTS,
    isDisabled: false,
    isHidden: false,
    dataLoading: false,
    filtersLoading: false,
    hasCharts: true,
  },
  {
    tabType: QuoteTabType.TRENDS,
    isDisabled: false,
    isHidden: false,
    dataLoading: false,
    filtersLoading: false,
    hasCharts: true,
  },
]
export const LAYER_TYPE_SELECTOR_NAME = 'layerType'
export const DEFAULT_QUOTE_SELECTORS: QuoteSelectors = {
  clientName: {
    columnName: 'clientName',
    name: 'clientName',
    id: 'clientName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  programName: {
    columnName: 'programName',
    name: 'programName',
    id: 'programName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  reinsurer: {
    columnName: 'reinsurer',
    name: 'reinsurer',
    id: 'reinsurer',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  layerName: {
    columnName: 'layerName',
    name: 'layerName',
    id: 'layerName',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  layerType: {
    columnName: 'layerType',
    name: 'layerType',
    id: 'layerType',
    selectedValues: getDefaultAudienceLayerNames(DEFAULT_AUDIENCE),
    allValues: getAudienceLayerNames(DEFAULT_AUDIENCE),
    values: getDefaultAudienceLayerNames(DEFAULT_AUDIENCE),
  },
  fotQuoteIndicator: {
    columnName: 'fotQuoteIndicator',
    name: 'fotQuoteIndicator',
    id: 'fotQuoteIndicator',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  lineOfBusiness: {
    columnName: 'lineOfBusiness',
    name: 'lineOfBusiness',
    id: 'lineOfBusiness',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  primaryClassOfBusiness: {
    columnName: 'primaryClassOfBusiness',
    name: 'primaryClassOfBusiness',
    id: 'primaryClassOfBusiness',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  territory: {
    columnName: 'territory',
    name: 'territory',
    id: 'territory',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  perils: {
    columnName: 'perils',
    name: 'perils',
    id: 'perils',
    selectedValues: [],
    allValues: [],
    values: [],
  },
  businessUnit: {
    columnName: 'businessUnit',
    name: 'businessUnit',
    id: 'businessUnit',
    selectedValues: [],
    allValues: [],
    values: [],
  },
}
export const DEFAULT_LAYER_INTERVALS: BasicControl[] = [
  {
    name: 'Premium',
    columnName: 'premium',
    minValue: '',
    maxValue: '',
  },
  {
    name: 'Occurrence Limit',
    columnName: 'occurrenceLimit',
    minValue: '',
    maxValue: '',
  },
  {
    name: 'Occurrence Attachment',
    columnName: 'occurrenceAttachment',
    minValue: '',
    maxValue: '',
  },
  {
    name: 'Aggregate Limit',
    columnName: 'aggregateLimit',
    minValue: '',
    maxValue: '',
  },
  {
    name: 'Aggregate Attachment',
    columnName: 'aggregateAttachment',
    minValue: '',
    maxValue: '',
  },
  {
    name: 'Total Signed Limit',
    columnName: 'totalSignedLimit',
    minValue: '',
    maxValue: '',
  },
]

const rollBack12Months = (): string => {
  const currentDate = new Date()
  const rolledBackDate = new Date()
  rolledBackDate.setFullYear(currentDate.getFullYear() - 1)
  rolledBackDate.setMonth(currentDate.getMonth())
  rolledBackDate.setDate(currentDate.getDate() + 1)
  const month = rolledBackDate.getMonth() + 1
  const formattedMonth = month < 10 ? `0${month}` : `${month}`
  const date = rolledBackDate.getDate()
  const formattedDate = date < 10 ? `0${date}` : `${date}`
  const year = rolledBackDate.getFullYear()
  return `${formattedMonth}/${formattedDate}/${year}`
}

export const DEFAULT_TIME_INTERVALS: BasicControl[] = [
  {
    name: 'Policy Effective Date',
    columnName: 'policyEffectiveDate',
    minValue: rollBack12Months(),
    maxValue: '',
  },
]

export const DATA_COUNT: DataCountValues[] = [
  {
    label: 'Clients',
    id: 'distinctClients',
    tooltip: 'Count of Distinct Clients',
  },
  {
    label: 'Programs',
    id: 'distinctPrograms',
    tooltip: 'Count of Distinct Programs',
  },
  {
    label: 'Layers',
    id: 'distinctLayers',
    tooltip: 'Count of Distinct Layers by Structures, Programs and Clients',
  },
  {
    label: 'Quotes',
    id: 'distinctQuotes',
    tooltip:
      'Count of Distinct Layers by Layers, Structures, Programs and Clients',
  },
  {
    label: 'FOTs',
    id: 'distinctFots',
    tooltip:
      'Count of Distinct FOTs by Version, Structures, Programs and Clients',
  },
]
