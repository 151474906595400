import { Component, ChangeDetectionStrategy } from '@angular/core'
import { Observable } from 'rxjs'
import { BenchmarkDataRefreshStatus } from '../../model/benchmark.model'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { selectBenchmarkDataRefreshStatus } from '../../store/benchmark.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-benchmark-data-availability-dialog-container',
  template: `
    <app-benchmark-data-availability
      [dataRefreshStatus]="dataRefreshStatus$ | async"
    >
    </app-benchmark-data-availability>
  `,
})
export class BenchmarkDataAvailabilityDialogContainerComponent {
  dataRefreshStatus$: Observable<BenchmarkDataRefreshStatus | undefined>

  constructor(private store: Store<AppState>) {
    this.dataRefreshStatus$ = this.store.pipe(
      select(selectBenchmarkDataRefreshStatus)
    )
  }
}
