import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { CreditStructureEntity } from '../../model/credit-structure.model'
import {
  CreditOptimizationTab,
  CreditOptimizationResultTableRow,
  CreditOptimizationResultTable,
  DEFAULT_CREDIT_OPTIMIZATION_TABS,
} from '../../model/credit-optimization.model'
import * as fromCreditOptimizationActions from './credit-optimization.actions'

export interface State {
  tabs: CreditOptimizationTab[]
  configurationValidity: boolean
  isOptimizing: boolean
  optimizationStructureEntity?: CreditStructureEntity
  optimizationProcessId?: string
  optimizationResultTable?: CreditOptimizationResultTable
  optimizationResultSelected?: CreditOptimizationResultTableRow
}

export const initialState: State = {
  tabs: DEFAULT_CREDIT_OPTIMIZATION_TABS,
  configurationValidity: true,
  isOptimizing: false,
}

const tabsReducer = createReducer(
  initialState.tabs,
  on(fromCreditOptimizationActions.EnableTabType, (tabs, { tabType }) =>
    tabs.map(tab => (tab.type === tabType ? { ...tab, disabled: false } : tab))
  ),
  on(fromCreditOptimizationActions.SetActiveTabType, (tabs, { tabType }) =>
    tabs.map(tab =>
      tab.type === tabType
        ? { ...tab, active: true }
        : { ...tab, active: false }
    )
  ),
  on(
    fromCreditOptimizationActions.ResetOptimization,
    () => DEFAULT_CREDIT_OPTIMIZATION_TABS
  )
)

const configurationValidityReducer = createReducer(
  initialState.configurationValidity,
  on(
    fromCreditOptimizationActions.UpdateConfigurationValidity,
    (_, { validity }) => validity
  ),
  on(fromCreditOptimizationActions.ResetOptimization, () => true)
)

const isOptimizingReducer = createReducer(
  initialState.isOptimizing,
  on(fromCreditOptimizationActions.RunOptimization, () => true),
  on(
    fromCreditOptimizationActions.RunOptimizationSuccess,
    fromCreditOptimizationActions.ResetOptimization,
    () => false
  )
)

const optimizationStructureEntityReducer = createReducer(
  initialState.optimizationStructureEntity,
  on(
    fromCreditOptimizationActions.InitOptimizationDataSuccess,
    (_, { structureEntity }) => structureEntity
  ),
  on(
    fromCreditOptimizationActions.UpdateConfigurationTransaction,
    (structureEntity, { transaction }) => {
      return {
        ...structureEntity,
        creditCalculationStructure: {
          ...structureEntity.creditCalculationStructure,
          transactions:
            structureEntity.creditCalculationStructure.transactions.map(t =>
              t._id === transaction._id ? transaction : t
            ),
        },
      }
    }
  ),
  on(fromCreditOptimizationActions.ResetOptimization, _ => undefined)
)

const optimizationProcessIdReducer = createReducer(
  initialState.optimizationProcessId,
  on(
    fromCreditOptimizationActions.RunOptimizationInitiated,
    (_, { processId }) => processId
  ),
  on(fromCreditOptimizationActions.ResetOptimization, _ => undefined)
)

const optimizationResultTableReducer = createReducer(
  initialState.optimizationResultTable,
  on(
    fromCreditOptimizationActions.RunOptimizationSuccess,
    (_, { results }) => results
  ),
  on(fromCreditOptimizationActions.ResetOptimization, _ => undefined)
)

const optimizationResultSelectedReducer = createReducer(
  initialState.optimizationResultSelected,
  on(
    fromCreditOptimizationActions.SelectOptimization,
    (_, { optimizationRow }) => optimizationRow
  ),
  on(fromCreditOptimizationActions.ResetOptimization, _ => undefined)
)

const optimizationReducer = combineReducers<State>({
  tabs: tabsReducer,
  configurationValidity: configurationValidityReducer,
  isOptimizing: isOptimizingReducer,
  optimizationStructureEntity: optimizationStructureEntityReducer,
  optimizationProcessId: optimizationProcessIdReducer,
  optimizationResultTable: optimizationResultTableReducer,
  optimizationResultSelected: optimizationResultSelectedReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return optimizationReducer(state, action)
}
