const ORDERS = [
  { suffix: '∞', magnitude: Math.pow(10, 15) },
  { suffix: 'T', magnitude: Math.pow(10, 12) },
  { suffix: 'B', magnitude: Math.pow(10, 9) },
  { suffix: 'M', magnitude: Math.pow(10, 6) },
  { suffix: 'K', magnitude: Math.pow(10, 3) },
]

function toFixed(num: number, precision = 1): number {
  const pow = 10 ** precision
  return Math.round(num * pow) / pow
}

export interface FormatShortFixedNumberOptions {
  prefix?: string
  precision?: number
  precisionOverThousand?: number
  noMajorRoundUp?: boolean
}

export function formatShortFixedNumber(
  value: number,
  options: FormatShortFixedNumberOptions = {}
): string {
  if (isNaN(value)) {
    return String(value)
  }

  const init = { short: Math.abs(value), suffix: '' }

  let { short, suffix } = ORDERS.reduce((acc, order) => {
    if (!acc.suffix) {
      const div = toFixed(acc.short / order.magnitude)
      let isMajorRoundUp = Math.abs(value).toString()[0] !== div.toString()[0]
      if (options.noMajorRoundUp !== true) {
        isMajorRoundUp = false
      }
      return div >= 1 && !isMajorRoundUp
        ? { short: div, suffix: order.suffix }
        : acc
    }
    return acc
  }, init)

  if (!suffix) {
    short = toFixed(short)
    suffix = ''
  } else if (suffix === '∞') {
    return 'Unlimited'
  }

  const { prefix = '', precision = 1, precisionOverThousand } = options

  // If value < 1000 and no fixed decimal needed, remove decimal. Otherwise show 1 decimal place
  const decimalPlaces =
    Math.abs(value) < 1000 ? precision : precisionOverThousand ?? precision

  const negation = value < 0 ? '-' : ''
  return `${negation}${prefix}${short.toFixed(decimalPlaces)}${suffix}`
}
