import { Action, on, createReducer } from '@ngrx/store'
import { CreditStructure } from '../../model/credit-structure.model'
import { CreditGrossLossScenario } from '../../model/credit-portfolio.model'
import { CreditStructureActions } from '../structure/credit-structure.actions'
import { CreditSubmissionStructureActions } from '../submission/credit-submission.actions'
import * as fromCreditEventResultsReducer from './credit-event-results.reducer'
import * as fromCreditAnimatedScenariosActions from './credit-animated-scenarios.actions'
import * as fromCreditActions from '../credit.actions'

export interface State {
  selectedScenarios: CreditGrossLossScenario[]
  eventResultState: fromCreditEventResultsReducer.ScenarioEventResultState
  executing: boolean
  error: string | null
  selectedStructure: CreditStructure | null
  animating: boolean
  showSlider: boolean
  sliderValue: number
}

export const initialState: State = {
  selectedScenarios: [],
  eventResultState: fromCreditEventResultsReducer.initialState,
  executing: false,
  error: null,
  selectedStructure: null,
  animating: false,
  showSlider: false,
  sliderValue: 0,
}

const creditAnimatedScenariosReducer = createReducer(
  initialState,
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    () => {
      return {
        ...initialState,
      }
    }
  ),
  on(fromCreditAnimatedScenariosActions.execute, state => ({
    ...state,
    executing: true,
    animating: true,
  })),
  on(fromCreditAnimatedScenariosActions.setAnimating, (state, { value }) => ({
    ...state,
    animating: value,
  })),
  on(fromCreditAnimatedScenariosActions.executeFailure, (state, { error }) => ({
    ...state,
    executing: false,
    error: error.message,
  })),
  on(fromCreditAnimatedScenariosActions.executeSuccess, state => ({
    ...state,
    executing: false,
    showSlider: true,
  })),
  on(
    fromCreditAnimatedScenariosActions.updateScenarios,
    (state, { grossLossScenarios }) => ({
      ...state,
      selectedScenarios: grossLossScenarios,
    })
  ),
  on(
    fromCreditAnimatedScenariosActions.fetchAnimatedScenariosDataSuccess,
    (state, action) => ({
      ...state,
      eventResultState: fromCreditEventResultsReducer.reducer(
        state.eventResultState,
        action
      ),
    })
  ),
  on(fromCreditAnimatedScenariosActions.setSliderValue, (state, { value }) => ({
    ...state,
    sliderValue: value,
  })),
  on(
    fromCreditAnimatedScenariosActions.addSelectedStructure,
    (state, { structure }) => ({ ...state, selectedStructure: structure })
  ),

  on(fromCreditAnimatedScenariosActions.resetEventsAndResults, state => {
    return { ...initialState, selectedStructure: state.selectedStructure }
  })
)

export function reducer(state: State | undefined, action: Action) {
  return creditAnimatedScenariosReducer(state, action)
}
