import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Layer, PhysicalLayer } from 'src/app/analysis/model/layers.model'

@Component({
  selector: 'app-top-drop-section-card',
  styleUrls: ['./top-drop-section-card.component.scss'],
  templateUrl: './top-drop-section-card.component.html',
})
export class TopAndDropSectionCardComponent {
  @Input() layer: Layer
  @Input() currentCurrency = 'USD'
  @Input() title = ''
  @Input() isHiddenLayer = false
  /* Deleting should be disabled if we only have one drop layer. */
  @Input() disableDelete = false

  @Output() physicalLayerChange = new EventEmitter<{
    layer: Layer
    physicalLayerChange: Partial<PhysicalLayer>
  }>()

  @Output() layerChange = new EventEmitter<{
    layer: Layer
    layerChange: Partial<Layer>
  }>()

  @Output() deleteDropLayer = new EventEmitter<string>()

  get isDrop(): boolean {
    return this.layer.meta_data.isDrop === true
  }

  get layerName(): string {
    return (
      this.layer.meta_data.layerName ||
      this.layer.physicalLayer.description ||
      ''
    )
  }

  set layerName(value: string) {
    const physicalLayerChange: Partial<PhysicalLayer> = {
      description: value,
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    const layerChange: Partial<Layer> = {
      meta_data: {
        ...this.layer.meta_data,
        layerName: value,
      },
    }
    this.layerChange.emit({
      layer: this.layer,
      layerChange,
    })
    this.layer.meta_data.layerName = value
    this.layer.physicalLayer.description = value
  }

  getClass(): string | undefined {
    if (this.title === 'Combined') {
      return 'combined-card'
    }
  }
  get perOccLimit(): number {
    return this.layer.physicalLayer.limit.value
  }

  set perOccLimit(value: number) {
    let physicalLayerChange: Partial<PhysicalLayer> = {
      limit: { ...this.layer.physicalLayer.limit, value },
    }
    if(this.layer.meta_data.sage_layer_type === 'cat_td' && this.layer.meta_data.sage_layer_subtype === 'actual'){
      physicalLayerChange = {
        limit: { ...this.layer.physicalLayer.limit, value },
        premium: {
          ...this.layer.physicalLayer.premium,
          value: this.layer.physicalLayer.meta_data.rol * value,
        }
      }
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.limit.value = value
  }

  get perOccAttachment(): number {
    return this.layer.physicalLayer.attachment.value
  }

  set perOccAttachment(value: number) {
    const physicalLayerChange: Partial<PhysicalLayer> = {
      attachment: { ...this.layer.physicalLayer.attachment, value },
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.attachment.value = value
  }

  get aggregateAttachment(): number {
    return this.layer.physicalLayer.aggregateAttachment.value
  }

  set aggregateAttachment(value: number) {
    const physicalLayerChange: Partial<PhysicalLayer> = {
      aggregateAttachment: {
        ...this.layer.physicalLayer.aggregateAttachment,
        value,
      },
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.aggregateAttachment.value = value
  }

  get aggregateLimit(): number {
    return this.layer.physicalLayer.aggregateLimit.value
  }

  set aggregateLimit(value: number) {
    const physicalLayerChange: Partial<PhysicalLayer> = {
      aggregateLimit: { ...this.layer.physicalLayer.aggregateLimit, value },
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.aggregateLimit.value = value
  }

  get cessionPercentage(): number {
    return this.layer.physicalLayer.participation * -1
  }

  set cessionPercentage(value: number) {
    const newValue = Math.abs(value) * -1
    const physicalLayerChange: Partial<PhysicalLayer> = {
      participation: newValue,
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.participation = newValue
  }

  /* HIDDEN LAYER */
  get sharedLimit(): number {
    return this.layer.physicalLayer.limit.value
  }

  set sharedLimit(value: number) {
    const physicalLayerChange: Partial<PhysicalLayer> = {
      limit: { ...this.layer.physicalLayer.limit, value },
      aggregateLimit: { ...this.layer.physicalLayer.aggregateLimit, value },
    }
    this.physicalLayerChange.emit({
      layer: this.layer,
      physicalLayerChange,
    })
    this.layer.physicalLayer.limit.value = value
    this.layer.physicalLayer.aggregateLimit.value = value
  }
}
