import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-summary-filter-view-dialog',
  templateUrl: './explore-gross-summary-filter-view-dialog.component.html',
  styleUrls: ['./explore-gross-summary-filter-view-dialog.component.scss']
})
export class ExploreGrossSummaryFilterViewDialogComponent implements OnInit, OnDestroy {
  @Input() exploreGrossPortfolioView: string
  actionText = 'Create Summary View'
  form: FormGroup
  private destroy$ = new Subject<void>()

  get saveDisabled(): boolean {
    return this.form.valid
  }

  @Output() createsummaryView = new EventEmitter<{
    name: string,
    grossProfileId: string,
  }>()

  constructor(
    public dialogRef: MatDialogRef<ExploreGrossSummaryFilterViewDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      summaryViewName: ['', [Validators.required, Validators.maxLength(50)]],
    })
  }

  handleClick(): void {
    if (this.form.valid) {
      this.createsummaryView.emit({
        name: this.form.value.summaryViewName,
        grossProfileId: this.exploreGrossPortfolioView
      })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
