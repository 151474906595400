import {
  BasicControl,
  CombinedSelectors,
  CreditCurveLayer,
  CreditCurvePredictionColumn,
  CreditCurvePredictionColumns,
  CreditSelectors,
  PredictionsResponse,
  PricingCurveAxisDefinition,
  PricingCurveContextTypes,
  PricingCurveData,
  PricingCurveDataPoint,
  PricingCurveDatum,
  PricingCurveGraphSettings,
  PricingCurveLayer,
  PricingCurveTypes,
  PricingCurveVisiblityOptions,
  SelectedTransaction,
  Selectors,
  TECHNICAL_FACTORS_DEFAULT_STATE,
  TechnicalFactors,
} from './pricing-curve.model'
import {
  buildCreditSelectorsOnCurveInit,
  filterCreditLayers,
  fitLineByCurveType,
  fitMinAndMaxXsAtGivenYs,
  getCurveTypeFromTechFactors,
  getExtentsForDataSet,
  leastSquares,
  rSquared,
  truncToDecimal,
  updateTechFactorsByCurveType,
} from '../pricing-curve.utils'
import {
  CreditAddLayersRowDef,
  DefaultAddLayersRowDef,
} from './pricing-curve-table.model'
import { lensProp, view } from 'ramda'

const maxDecimalValue = 9.99999

export class PricingCurve {
  private _id: number
  private _label: string
  private _visibilityOptions: PricingCurveVisiblityOptions
  private _graphColorClass: string
  private _curveType: PricingCurveTypes
  private _technicalFactors: TechnicalFactors | null
  private _lineData: PricingCurveDataPoint[]
  private _layers: PricingCurveLayer[]
  private _creditLayers: CreditCurveLayer[]
  private _layerSplitView: boolean
  private _selectors: Selectors | null
  private _dateIntervals: BasicControl[] | null
  private _creditSelectors: CreditSelectors | null
  private _graphColorRgb: string | undefined
  private _rSquared: number | undefined
  private _cardIndex: number
  private _isEdit: boolean
  private _initialSetup: boolean
  private _isManual: boolean
  private _context: PricingCurveContextTypes
  private _creditPredictionColumns: CreditCurvePredictionColumns | undefined
  private _creditPredictions: PredictionsResponse | undefined
  private _scaleFactor: number | null
  private _minimumPremium: number | null

  private _intercept: number | undefined
  private _slope: number | undefined

  includedLayerIds?: string[]
  isQuickEdit: boolean
  xValueDefinition: PricingCurveAxisDefinition
  yValueDefinition: PricingCurveAxisDefinition

  constructor(
    data: PricingCurveData,
    graphSettings: PricingCurveGraphSettings,
    private selectedTransaction: SelectedTransaction | null
  ) {
    this.setDefaultValuesForProperties()
    this.id = data.id
    this.label = data.label
    this.context = data.context
    this.xValueDefinition = graphSettings.xAxisDefinition
    this.yValueDefinition = graphSettings.yAxisDefinition
    this.isManual = data.isManual
    this.curveType = data.curveType
    this.layerSplitView = data.layerSplitView ?? true
    this.visibilityOptions = data.visibilityOptions
    this.graphColorClass = data.graphColorClass
    this.graphColorRgb = data.graphColorRgb
    this.cardIndex = data.cardIndex
    this.isEdit = data.isEdit
    this.initialSetup = data.initialSetup
    this.creditPredictionColumns = data.creditPredictionColumns
    this.creditPredictions = data.creditPredictions
    this.scaleFactor = data.scaleFactor
    this.minimumPremium = data.minimumPremium
    this.technicalFactors = data.technicalFactors
    this.selectors = data.selectors
    this.dateIntervals = data.dateIntervals
    this.includedLayerIds = data.includedLayerIds
    this.creditSelectors = data.creditSelectors
    this.layers = data.layers
    this.creditLayers = data.creditLayers

    this.creditSelectors = buildCreditSelectorsOnCurveInit(
      this.filteredCreditLayers,
      this.creditLayers,
      this.creditSelectors
    )
    if (this.isManual || !this.initialSetup) {
      this.updatePropertiesFromTechFactors()
    } else {
      this.updatePropertiesFromLayers()
    }
    this.calculateCurveFit()
  }

  get id(): number {
    return this._id
  }
  set id(value: number) {
    this._id = value
  }

  get label(): string {
    return this._label
  }
  set label(value: string) {
    this._label = value
  }

  get visibilityOptions(): PricingCurveVisiblityOptions {
    return this._visibilityOptions
  }
  set visibilityOptions(value: PricingCurveVisiblityOptions) {
    this._visibilityOptions = value
  }

  get layersVisible(): boolean {
    return this._visibilityOptions.layersVisible
  }
  set layersVisible(value: boolean) {
    this._visibilityOptions = {
      ...this._visibilityOptions,
      layersVisible: value,
    }
  }

  get lineVisible(): boolean {
    return this._visibilityOptions.lineVisible
  }
  set lineVisible(value: boolean) {
    this._visibilityOptions = {
      ...this._visibilityOptions,
      lineVisible: value,
    }
  }

  get graphColorClass(): string {
    return this._graphColorClass
  }
  set graphColorClass(value: string) {
    this._graphColorClass = value
  }

  get curveType(): PricingCurveTypes {
    return this._curveType
  }
  set curveType(value: PricingCurveTypes) {
    this._curveType = value
  }

  get technicalFactors(): TechnicalFactors | null {
    return this._technicalFactors
  }
  set technicalFactors(value: TechnicalFactors | null) {
    if (this.context !== 'credit') {
      this._technicalFactors = value
      if (this.isManual) {
        this.updatePropertiesFromTechFactors()
        this.calculateCurveFit()
      }
    } else {
      this._technicalFactors = null
    }
  }

  get lineData(): PricingCurveDatum[] {
    return this._lineData.map(d => ({
      ...d,
      label: this.label,
      datasetId: this.id,
    }))
  }

  get pointData(): PricingCurveDatum[] {
    if (this.context === 'pricing-curve') {
      return (this.includedLayers as PricingCurveLayer[]).map(layer => ({
        id: layer.id,
        datasetId: this.id,
        label: this.label,
        x: this.getValueFromAxisDefinition<PricingCurveLayer>(layer, 'x'),
        y: this.getValueFromAxisDefinition<PricingCurveLayer>(layer, 'y'),
        isLine: false,
        clientName: layer.props.clientName ?? '',
        layerDesc: layer.props.layerDesc,
        layerName: layer.props.layerName ?? '',
      }))
    } else {
      return (this.includedLayers as CreditCurveLayer[]).map(layer => ({
        id: layer.trancheId,
        datasetId: this.id,
        isLine: false,
        x: this.getValueFromAxisDefinition<CreditCurveLayer>(layer, 'x'),
        y: this.getValueFromAxisDefinition<CreditCurveLayer>(layer, 'y'),
        label: `${layer.dealName} - ${layer.tranche}`,
        pAttach: `${(layer.pAttach * 100).toFixed(2)}%`,
        pDetach: `${(layer.pDetach * 100).toFixed(2)}%`,
        dealName: layer.dealName,
        selectedTransactionMember:
          layer.dealName === this.selectedTransaction?.dealName &&
          this.id === this.selectedTransaction?.pricingCurveId,
      }))
    }
  }

  get includedLayers(): PricingCurveLayer[] | CreditCurveLayer[] {
    if (this.context === 'pricing-curve') {
      return this.layers.filter(layer => layer.include)
    } else {
      return this.filteredCreditLayers.filter(layer => layer.include)
    }
  }
  get layers(): PricingCurveLayer[] {
    return this._layers
  }
  set layers(value: PricingCurveLayer[]) {
    if (!this.isManual) {
      this._layers = value

      if (this.hasLine && !!value.length) {
        this.updatePropertiesFromLayers()
        this.calculateCurveFit()
      }
    }
  }

  get creditLayers(): CreditCurveLayer[] {
    return this._creditLayers
  }
  set creditLayers(value: CreditCurveLayer[]) {
    this._creditLayers = value
    if (this.creditPredictions && this.creditLayers.length) {
      const idValueMap = this.creditLayerPredictionIdMap
      this._creditLayers = this.creditLayers.map(layer => ({
        ...layer,
        premium: Math.max(
          layer.premium * this.scaleFactor,
          this.minimumPremium
        ),
        prediction: idValueMap[layer.trancheId] ?? undefined,
      }))
      if (this.includedLayers.length) {
        this.updatePropertiesFromCreditLayers()
        this.calculateCurveFit()
      }
      this.includedLayerIds = (this.includedLayers as CreditCurveLayer[]).map(
        layer => layer.trancheId.toString()
      )
    }
  }
  get filteredCreditLayers(): CreditCurveLayer[] {
    return filterCreditLayers(this.creditLayers, this.creditSelectors)
  }

  get creditLayerPredictionIdMap(): Record<number, number> {
    const predictionIdMap: Record<number, number> = {}
    if (this.creditPredictions && this.includedLayers.length) {
      const includedLayers = this.includedLayers as CreditCurveLayer[]
      includedLayers.forEach((val, index) => {
        predictionIdMap[val.trancheId] =
          // tslint:disable-next-line: no-non-null-assertion
          this.creditPredictions!.predictions[index]
      })
    }
    return predictionIdMap
  }

  get layerSplitView(): boolean {
    return this._layerSplitView
  }
  set layerSplitView(value: boolean) {
    this._layerSplitView = value
  }

  get selectors(): Selectors | null {
    return this._selectors
  }
  set selectors(value: Selectors | null) {
    if (!this.isManual && this.context !== 'credit') {
      this._selectors = value
    } else {
      this._selectors = null
    }
  }

  get dateIntervals(): BasicControl[] | null {
    return this._dateIntervals
  }
  set dateIntervals(value: BasicControl[] | null) {
    if (!this.isManual && this.context !== 'credit') {
      this._dateIntervals = value
    } else {
      this._dateIntervals = null
    }
  }

  get creditSelectors(): CreditSelectors | null {
    return this._creditSelectors
  }
  set creditSelectors(value: CreditSelectors | null) {
    if (!this.isManual && this.context === 'credit') {
      this._creditSelectors = value
    }
  }

  set combinedSelectors(value: CombinedSelectors) {
    this.selectors = value.selectors
    this.dateIntervals = value.dateIntervals
  }

  get intercept(): number | undefined {
    return this._intercept
  }
  set intercept(value: number | undefined) {
    if (value !== undefined) {
      this._intercept = truncToDecimal(value, 8, maxDecimalValue)
    }
  }

  get slope(): number | undefined {
    return this._slope
  }
  set slope(value: number | undefined) {
    if (value !== undefined) {
      this._slope = truncToDecimal(value, 8, maxDecimalValue)
    }
  }

  get cardIndex(): number {
    return this._cardIndex
  }
  set cardIndex(value: number) {
    this._cardIndex = value
  }

  get isEdit(): boolean {
    return this._isEdit
  }
  set isEdit(value: boolean) {
    this._isEdit = value
  }

  get initialSetup(): boolean {
    return this._initialSetup
  }
  set initialSetup(value: boolean) {
    this._initialSetup = value
  }

  get context(): PricingCurveContextTypes {
    return this._context
  }
  set context(value: PricingCurveContextTypes) {
    this._context = value
  }

  get creditPredictionColumns(): CreditCurvePredictionColumns | undefined {
    return this._creditPredictionColumns
  }
  set creditPredictionColumns(value: CreditCurvePredictionColumns | undefined) {
    if (this.context === 'credit') {
      this._creditPredictionColumns = value
    }
  }
  get activeCreditPredictionColumns():
    | CreditCurvePredictionColumn[]
    | undefined {
    if (this.creditPredictionColumns) {
      return Object.values(this.creditPredictionColumns).filter(
        column => column.isActive
      )
    }
  }

  get creditPredictions(): PredictionsResponse | undefined {
    return this._creditPredictions
  }
  set creditPredictions(value: PredictionsResponse | undefined) {
    this._creditPredictions = value
    this.rSquared = value?.r_squared
    if (value?.predictions && this.creditLayers.length) {
      const idValueMap = this.creditLayerPredictionIdMap
      this._creditLayers = this.creditLayers.map(layer => ({
        ...layer,
        prediction: idValueMap[layer.trancheId] ?? undefined,
      }))
      this.updatePropertiesFromCreditLayers()
      this.calculateCurveFit()
    }
  }

  get scaleFactor(): number | null {
    return this._scaleFactor
  }
  set scaleFactor(value: number | null) {
    if (this.context === 'credit') {
      this._scaleFactor = value
    } else {
      this._scaleFactor = null
    }
  }

  get minimumPremium(): number | null {
    return this._minimumPremium
  }
  set minimumPremium(value: number | null) {
    if (this.context === 'credit') {
      this._minimumPremium = value
    } else {
      this._minimumPremium = null
    }
  }

  get rSquared(): number | undefined {
    return this._rSquared
  }
  set rSquared(value: number | undefined) {
    this._rSquared = value
  }

  get graphColorRgb(): string | undefined {
    return this._graphColorRgb
  }
  set graphColorRgb(value: string | undefined) {
    this._graphColorRgb = value
  }

  get isSaved(): boolean {
    return this.id >= 0
  }

  get hasLine(): boolean {
    return (
      !!this.lineData.length ||
      this.curveType === PricingCurveTypes.EL_LINEAR ||
      this.isPowerCurve
    )
  }

  get isPowerCurve(): boolean {
    return this.curveType === PricingCurveTypes.EL_POWER
  }

  get isManual(): boolean {
    return this._isManual
  }
  set isManual(value: boolean) {
    this._isManual = value
    if (this.initialSetup) {
      this.technicalFactors = TECHNICAL_FACTORS_DEFAULT_STATE
    }
  }

  get isLayerSet(): boolean {
    return this.curveType === PricingCurveTypes.LAYER_SET
  }

  get curveData(): PricingCurveData {
    return {
      curveType: this.curveType,
      dateIntervals: this.dateIntervals,
      graphColorClass: this.graphColorClass,
      graphColorRgb: this.graphColorRgb,
      id: this.id,
      label: this.label,
      layers: this.layers,
      creditLayers: this.creditLayers,
      layerSplitView: this.layerSplitView,
      selectors: this.selectors,
      creditSelectors: this.creditSelectors,
      technicalFactors: this.technicalFactors,
      visibilityOptions: this.visibilityOptions,
      includedLayerIds: this.includedLayerIds,
      cardIndex: this.cardIndex,
      isEdit: this.isEdit,
      initialSetup: this.initialSetup,
      isManual: this.isManual,
      context: this.context,
      creditPredictionColumns: this.creditPredictionColumns,
      creditPredictions: this.creditPredictions,
      minimumPremium: this.minimumPremium,
      scaleFactor: this.scaleFactor,
    }
  }

  get tableRows(): DefaultAddLayersRowDef[] | CreditAddLayersRowDef[] {
    if (this.context === 'pricing-curve') {
      return this.layers.map(layer => ({
        id: layer.id,
        clientName: layer.props.clientName,
        layerName: layer.props.layerName,
        programName: layer.props.programName,
        reinsurerName: layer.props.reinsurerName,
        writtenPercent: layer.props.writtenPercent,
        layerDesc: layer.props.layerDesc,
        el: layer.el,
        trol: layer.trol,
        prob_of_attach: layer.props.prob_of_attach,
        prob_of_exhaust: layer.props.prob_of_exhaust,
        include: layer.include,
      }))
    } else {
      return this.filteredCreditLayers.map(layer => ({
        id: layer.trancheId,
        include: layer.include,
        dealName: layer.dealName,
        dealType: layer.dealType,
        premium: layer.premium,
        pAttach: layer.pAttach,
        pDetach: layer.pDetach,
        miThickness: layer.miThickness,
        expectedLossOnLine: layer.expectedLossOnLine,
        dti: layer.dti,
        oltv: layer.oltv,
        miAttach: layer.miAttach,
        miDetach: layer.miDetach,
        midpoint: layer.midpoint,
        fico: layer.fico,
      }))
    }
  }

  getValueFromAxisDefinition<T>(layer: T, axis: 'x' | 'y'): number {
    const path = (
      axis === 'x' ? this.xValueDefinition.path : this.yValueDefinition.path
    ) as keyof T
    const value = view(lensProp(path), layer)
    return typeof value === 'number' ? value : 0
  }

  updatePredictionColumn(key: string, isActive: boolean): void {
    if (!this.creditPredictionColumns) {
      return
    }
    const anyColumns = { ...this.creditPredictionColumns } as any
    anyColumns[key] = {
      ...anyColumns[key],
      isActive,
    }
    this.creditPredictionColumns = anyColumns as CreditCurvePredictionColumns
  }

  removeLayerFromSetAndRecalculate(layerId: number): void {
    this.isQuickEdit = true
    const layers = [...this.layers]
    const layerIndex = layers.findIndex(l => l.id === layerId)
    if (layerIndex >= 0) {
      const layer = layers[layerIndex]
      layers.splice(layerIndex, 1, {
        ...layer,
        include: false,
      })
      this.layers = layers
    }
    this.isQuickEdit = false
  }

  setDefaultValuesForProperties(): void {
    this._lineData = []
    this._layers = []
    this._creditLayers = []
    this.isEdit = false
    this.isQuickEdit = false
  }

  updatePropertiesFromCreditLayers(): void {
    if (!this.includedLayers.length) {
      return
    }

    const { slope, intercept } = leastSquares(
      this.includedLayers,
      this.isPowerCurve,
      this.xValueDefinition.path,
      this.yValueDefinition.path
    )
    this.slope = slope
    this.intercept = intercept
  }

  updatePropertiesFromLayers(): void {
    if (!this.includedLayers.length) {
      return
    }
    const layers = this.includedLayers as PricingCurveLayer[]

    const { slope, intercept } = leastSquares(
      layers,
      this.isPowerCurve,
      this.xValueDefinition.path,
      this.yValueDefinition.path
    )
    this.slope = slope
    this.intercept = this.isPowerCurve ? Math.pow(10, intercept) : intercept

    if (!this.technicalFactors) {
      return
    }

    const { yExtents } = getExtentsForDataSet(
      layers,
      this.xValueDefinition.path,
      this.yValueDefinition.path
    )
    const newMinVal =
      this.isQuickEdit || this.isSaved
        ? this.technicalFactors.minimum_rate_on_line
        : yExtents.min
    const newMin = truncToDecimal(newMinVal, 3, maxDecimalValue)

    const newMaxVal =
      this.isQuickEdit || this.isSaved
        ? this.technicalFactors.maximum_rate_on_line
        : yExtents.max
    const newMax = truncToDecimal(newMaxVal, 3, maxDecimalValue)
    this._technicalFactors = updateTechFactorsByCurveType(
      this.slope,
      this.intercept,
      this.isPowerCurve,
      this.technicalFactors,
      newMin,
      newMax,
      this.initialSetup
    )
  }

  updatePropertiesFromTechFactors(): void {
    if (!this.technicalFactors) {
      return
    }
    // Set slope and intercept by tech factors, may be overwritten by layer update
    const { expected_loss_multiplier, fixed_cost, expected_loss_power } =
      this.technicalFactors
    this.slope = this.isPowerCurve
      ? expected_loss_power
      : expected_loss_multiplier
    this.intercept = this.isPowerCurve ? expected_loss_multiplier : fixed_cost

    // Update curve type based on technical factors
    this.curveType = getCurveTypeFromTechFactors(this.technicalFactors)
  }

  calculateCurveFit(): void {
    if (
      (!this.technicalFactors && this.context === 'pricing-curve') ||
      this.intercept == undefined ||
      this.slope == undefined
    ) {
      return
    }
    let minX
    let maxX
    if (this.technicalFactors && this.context === 'pricing-curve') {
      const xExtents = fitMinAndMaxXsAtGivenYs(
        this.technicalFactors.minimum_rate_on_line,
        this.technicalFactors.maximum_rate_on_line,
        this.intercept,
        this.slope,
        this.isPowerCurve
      )
      minX = xExtents[0]
      maxX = xExtents[1]
    } else if (this.creditPredictions && this.context === 'credit') {
      this.rSquared = rSquared(
        this.includedLayers,
        this.xValueDefinition.path,
        this.yValueDefinition.path
      )
      const { xExtents } = getExtentsForDataSet(
        this.includedLayers,
        this.xValueDefinition.path,
        this.yValueDefinition.path
      )
      minX = xExtents.min
      maxX = xExtents.max
    }
    if (minX != undefined && maxX != undefined) {
      const minXOrZero = Math.max(minX, 0)
      this._lineData = fitLineByCurveType(
        this.intercept,
        this.slope,
        minXOrZero,
        maxX,
        this.curveType
      )
    }
  }
}
