import { sortBy } from 'ramda'

export const sortByDate = sortBy((d?: { date: number }) => d?.date ?? 0)

export const convertYearToDateString = (
  yyyy: number,
  m = 12,
  d = 31
): string => {
  const mm = String(m).padStart(2, '0')
  const dd = String(d).padStart(2, '0')
  return `${yyyy}-${mm}-${dd} 00:00:00Z`
}

export const formatMMDDYYYY = (date: string): string => {
  if (date) {
    const d = new Date(date)
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
  }
  return ''
}

export function isDate(value?: unknown): value is Date {
  if (value && typeof (value as any).getMonth === 'function') {
    return true
  }
  return false
}
