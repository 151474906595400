import { DatePipe, getCurrencySymbol } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface EntryDialogOptions {
  message?: string
  submitLabel?: string
  date?: string
  year?: string
  fields: { name: string; value: string; type: string }[]
  selectedCurrency: string | undefined
}

const defaults: EntryDialogOptions = {
  message: 'Are you sure you want to do this?',
  submitLabel: 'Confirm',
  fields: [],
  selectedCurrency: undefined,
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-entry',
  styles: [
    `
           .date-data {
             display: flex;
           }
         `,
  ],
  template: `
    <div>
      <h1 mat-dialog-title>
        {{ options.message }}
      </h1>
      <div mat-dialog-content>
        <div *ngFor="let item of options.fields">
          <div *ngIf="item.type === 'total'">
            <mat-form-field>
              <input
                placeholder="{{ item.name }}"
                value="{{ getTotal() }}"
                matInput
                type="text"
                readonly="true"
              />
            </mat-form-field>
          </div>
          <div *ngIf="item.type === 'Date' && !options.date">
            <mat-form-field>
              <div class="date-data">
                <input
                  placeholder="{{ item.name }}: {{ item.value }}"
                  [(ngModel)]="item.value"
                  [type]="getType(item.type)"
                  matInput
                  [matDatepicker]="picker"
                />
              </div>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="item.type === 'Date' && options.date">
            <mat-form-field>
              <div class="date-data">
                <input
                  placeholder="{{ item.name }}: {{ options.date }}"
                  [(ngModel)]="item.value"
                  [type]="getType(item.type)"
                  matInput
                  [matDatepicker]="picker"
                />
              </div>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
          <div
            *ngIf="
              item.type !== 'total' &&
              item.type !== 'select' &&
              item.type !== 'Date' &&
              (item.name === 'Year' || item.name === 'Years') &&
              options.year
            "
          >
            <mat-form-field>
              <input
                placeholder="{{ item.name }}: {{ options.year }}"
                [(ngModel)]="item.value"
                [type]="getType(item.type)"
                matInput
                (focus)="removeCommas(item)"
                (blur)="addCommas(item)"
                (keypress)="validateInput($event, item)"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              item.type !== 'total' &&
              item.type !== 'select' &&
              item.type !== 'Date' &&
              ((item.name !== 'Year' && item.name !== 'Years') || !options.year)
            "
          >
            <mat-form-field>
              <input
                placeholder="{{ item.name }}"
                [(ngModel)]="item.value"
                [type]="getType(item.type)"
                matInput
                (focus)="removeCommas(item)"
                (blur)="addCommas(item)"
                (keypress)="validateInput($event, item)"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button appButton accent (click)="confirm(true)">
          <span>{{ options.submitLabel }}</span>
        </button>
        <button appButton link (click)="confirm(false)">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  `,
})
export class EntryDialogComponent {
  options: EntryDialogOptions
  constructor(
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<
      EntryDialogComponent,
      {
        choice: boolean
        fields: { name: string; value: string; type: string }[]
      }
    >,
    @Inject(MAT_DIALOG_DATA) public data: EntryDialogOptions
  ) {
    if (data.date) {
      data.fields.forEach(
        field =>
          (field.value =
            field.type === 'Date' && data.date ? data.date : field.value)
      )
    }
    if (data.year) {
      data.fields.forEach(
        field =>
          (field.value =
            (field.name === 'Year' || field.name === 'Years') && data.year
              ? data.year
              : field.value)
      )
    }
    this.options = { ...defaults, ...data }
  }

  getType(type: string) {
    if (type === 'currency' || type === 'number') {
      return 'text'
    } else {
      return type
    }
  }

  getCurrencySign() {
    if (this.options.selectedCurrency) {
      return getCurrencySymbol(this.options.selectedCurrency, 'wide')
    } else {
      return '$'
    }
  }

  validateInput(
    event: KeyboardEvent,
    item: { name: string; value: string; type: string }
  ) {
    const match = item.value.match(/\./g)
    if (item.type === 'currency' || item.type === 'number') {
      // tslint:disable-next-line: deprecation
      if (event.charCode === 46) {
        if (match && match.length > 0) {
          return false
        } else {
          return true
        }
      } else {
        return (
          // for numbers and B, M and T
          // tslint:disable-next-line: deprecation
          (event.charCode >= 48 && event.charCode <= 57) ||
          event.charCode === 66 ||
          event.charCode === 77 ||
          event.charCode === 84 ||
          event.charCode === 98 ||
          event.charCode === 109 ||
          event.charCode === 116
        )
      }
    }
  }

  confirm(choice: boolean) {
    for (const i of this.options.fields) {
      if (i.type === 'Date') {
        i.value = this.datePipe.transform(i.value, 'MM/dd/yyyy') ?? ''
      }
    }
    this.dialogRef.close({ choice, fields: this.options.fields })
  }

  removeCommas(item: { name: string; value: string; type: string }) {
    if ((item.type === 'number' || item.type === 'currency') && item.value) {
      const currentField = this.options.fields.filter(field => {
        return field.name === item.name
      })
      currentField[0].value = item.value.toString().replace(/[^0-9.]/g, '')
    }
  }

  addCommas(item: { name: string; value: string; type: string }) {
    if ((item.type === 'number' || item.type === 'currency') && item.value) {
      const currentField = this.options.fields.filter(field => {
        return field.name === item.name
      })
      const value = parseFloat(this.convertSmartNumber(item.value))
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      currentField[0].value =
        item.type === 'currency' ? this.getCurrencySign() + value : value
    }
  }

  getTotal() {
    let total = 0
    this.data.fields.forEach(field => {
      if (field.type !== 'total') {
        total +=
          field.value !== ''
            ? parseFloat(field.value.replace(/[^0-9.]/g, ''))
            : 0
      }
    })
    const totalField = this.data.fields.find(field => {
      return field.type === 'total'
    })
    if (totalField) {
      totalField.value = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return total
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  convertSmartNumber(input: string) {
    if (input.endsWith('M') || input.endsWith('m')) {
      return (parseFloat(input.slice(0, -1)) * 1000000).toString()
    } else if (input.endsWith('B') || input.endsWith('b')) {
      return (parseFloat(input.slice(0, -1)) * 1000000000).toString()
    } else if (input.endsWith('T') || input.endsWith('t')) {
      return (parseFloat(input.slice(0, -1)) * 1000000000000).toString()
    } else {
      return input
    }
  }
}
