import { props, createAction } from '@ngrx/store'
import {
  LossSetGroup,
  LossSetGroupAndMembers,
  LossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { OmitID } from 'src/app/api/model/backend.model'
import { ApplicationError } from 'src/app/error/model/error'

export const updateLossSetGroupMode = createAction(
  '[LossSet Group] Update Loss Set Group Edit Mode',
  props<{ mode: string }>()
)

export const saveGroup = createAction(
  '[LossSet Group] Save Loss Set Group',
  props<{ lossSetGroup: OmitID<LossSetGroup> }>()
)

export const saveGroupSuccess = createAction(
  '[LossSet Group] Save Loss Set Group Success',
  props<{ groupAndMembers: LossSetGroupAndMembers }>()
)

export const saveGroupFailure = createAction(
  '[LossSet Group] Save Loss Set Group Failure',
  props<{ error: ApplicationError }>()
)

export const updateGroup = createAction(
  '[LossSet Group] Update Loss Set Group',
  props<{ lossSetGroup: LossSetGroup }>()
)

export const updateGroupSuccess = createAction(
  '[LossSet Group] Update Loss Set Group Success',
  props<{ groupAndMembers: LossSetGroupAndMembers }>()
)

export const updateGroupFailure = createAction(
  '[LossSet Group] Update Loss Set Group Failure',
  props<{ error: ApplicationError }>()
)

export const updateGroupEditLossSets = createAction(
  '[LossSet Group] Update Group Edit Loss Sets',
  props<{ lossSetLayers: LossSetLayer[] }>()
)

export const setSelectedLossSetGroup = createAction(
  '[LossSet Group] Set Selected Group',
  props<{ lossSetGroup: LossSetGroup | null }>()
)

export const deleteGroup = createAction(
  '[LossSet Group] Delete Loss Set Group',
  props<{ lossSetGroup: LossSetGroup }>()
)

export const deleteGroupSuccess = createAction(
  '[LossSet Group] Delete Loss Set Group Success',
  props<{ lossSetGroup: LossSetGroup }>()
)

export const deleteGroupFailure = createAction(
  '[LossSet Group] Delete Loss Set Group Failure',
  props<{ error: ApplicationError }>()
)

export const fetchLossSetGroups = createAction(
  '[LossSet Group] Fetch Groups',
  props<{ lossSetGroups: LossSetGroup[] }>()
)

export const setDirtyLossSetGroup = createAction(
  '[LossSet Group] Set Dirty Group',
  props<{ lossSetGroupID: string; dirty: boolean }>()
)

export const clearAllDirtyGroups = createAction(
  '[LossSet Group] Clear All Dirty Groups'
)
