import { BenchmarkMetricResponse } from 'src/app/api/benchmark/benchmark.converter'

export const BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID =
  'POP_GROWTH_PROJECTED'
export const BENCHMARK_INDUSTRY_DPW_BY_POPULATION =
  'MPL_INDUSTRY_DPW_GROWTH_PERCENT_PER_1000'

const BENCHMARK_COMBINED_METRIC_IDS = [
  // U.S. > Summary
  'SUMMARY_DIRECT_LOSS_RATIO',
  'SUMMARY_NET_LOSS_RATIO',
  'SUMMARY_DIRECT_EXPENSE_RATIO',
  'SUMMARY_NET_EXPENSE_RATIO',
  'SUMMARY_DIRECT_COMBINED_RATIO',
  'SUMMARY_NET_COMBINED_RATIO',
  'SUMMARY_INVESTMENT_INCOME_RATIO',
  'SUMMARY_OPERATING_RATIO',
  'SUMMARY_DPW_PHS',
  'SUMMARY_NPW_PHS',
  'SUMMARY_INVESTED_ASSETS_PHS',
  'SUMMARY_COMMON_STOCKS_PHS',
  // U.S. > Leverage
  'LEVERAGE_RESERVE',
  'LEVERAGE_NET',
  // U.S. > Underwriting
  'LOSS_PURE',
  'LOSS_LAE',
  'LOSS_ALAE',
  'LOSS_ULAE',
  'LOSS_BOTH',
  'LOSS_LAE_BOTH',
  'LOSS_PURE_2',
  'LOSS_LAE_2',
  'LOSS_ALAE_2',
  'LOSS_ULAE_2',
  'LOSS_BOTH_2',
  'LOSS_LAE_BOTH_2',
  'LOSS_PURE_3',
  'LOSS_LAE_3',
  'LOSS_ALAE_3',
  'LOSS_ULAE_3',
  'LOSS_BOTH_3',
  'LOSS_LAE_BOTH_3',
  'EXPENSE_RATIO',
  'DIVIDEND_RATIO',
  'INVEST_INCOME_RATIO',
  'COMBINED_RATIO',
  'COMBINED_RATIO_EX_DIV',
  'OPERATING_RATIO',
  'OPERATING_RATIO_EX_DIV',
  // U.S. > Performance
  'PERFORMANCE_DPW',
  'PERFORMANCE_DPW_GROWTH_PERCENT',
  'PERFORMANCE_DPW_GROWTH_VS_PEERS',
  'PERFORMANCE_LOSS_RATIO',
  'PERFORMANCE_LOSS_RATIO_VS_PEERS',
  'PERFORMANCE_DCC_RATIO',
  'PERFORMANCE_DCC_RATIO_VS_PEERS',
  'PERFORMANCE_DIRECT_LOSS_DCC_RATIO',
  'PERFORMANCE_DIRECT_LOSS_DCC_RATIO_VS_PEERS',
  // U.S. > State
  120034,
  120035,
  120038,
  120040,
  126780,
  126781,
  126784,
  'STATE_DPW',
  'STATE_DPW_PEERS',
  'STATE_DPW_GROWTH',
  'STATE_DPW_GROWTH_CAGR_PEERS',
  'STATE_DPW_GROWTH_CAGR_VS_PEERS',
  'STATE_DPW_GROWTH_PERCENT_PEERS',
  'STATE_DPW_GROWTH_PERCENT_VS_PEERS',
  'STATE_DPW_GROWTH_PEERS',
  'STATE_DPW_GROWTH_CAGR',
  'STATE_POP_SIZE',
  'STATE_POP_GROWTH',
  'STATE_LOSS_RATIO',
  'STATE_LOSS_RATIO_PEERS',
  'STATE_LOSS_RATIO_VOLATILITY',
  'STATE_LOSS_RATIO_VOLATILITY_PEERS',
  'STATE_LOSS_RATIO_CHANGE',
  'STATE_LOSS_RATIO_CHANGE_PEERS',
  'STATE_LOSS_RATIO_VS_PEERS',
  'STATE_DCC_RATIO',
  'STATE_DCC_RATIO_PEERS',
  'STATE_DCC_RATIO_VOLATILITY',
  'STATE_DCC_RATIO_VOLATILITY_PEERS',
  'STATE_DCC_RATIO_CHANGE',
  'STATE_DCC_RATIO_CHANGE_PEERS',
  'STATE_DCC_RATIO_VS_PEERS',
  'STATE_DIRECT_LOSS_DCC_RATIO',
  'STATE_DIRECT_LOSS_DCC_RATIO_PEERS',
  'STATE_DIRECT_LOSS_DCC_RATIO_VOLATILITY',
  'STATE_DIRECT_LOSS_DCC_RATIO_VOLATILITY_PEERS',
  'STATE_DIRECT_LOSS_DCC_RATIO_CHANGE',
  'STATE_DIRECT_LOSS_DCC_RATIO_CHANGE_PEERS',
  'STATE_DIRECT_LOSS_DCC_RATIO_VS_PEERS',
  'STATE_PRE_EXPENSE_MARGIN',
  'STATE_PRE_EXPENSE_MARGIN_PEERS',
  'STATE_MARKET_SHARE',
  // Market Share
  'MARKET_SHARE_TABLE',
  // U.S. > MPL
  126780,
  126781,
  126784,
  'MPL_DPW',
  'MPL_DPW_PEERS',
  'MPL_DPW_GROWTH_PERCENT',
  'MPL_DPW_GROWTH_PERCENT_PEERS',
  'MPL_DPW_GROWTH',
  'MPL_DPW_GROWTH_PEERS',
  'MPL_DPW_GROWTH_VS_PEERS',
  BENCHMARK_INDUSTRY_DPW_BY_POPULATION,
  'MPL_POP_SIZE',
  'MPL_POP_GROWTH',
  BENCHMARK_PROJECTED_POPULATION_GROWTH_METRIC_ID,
  'MPL_LOSS_RATIO',
  'MPL_LOSS_RATIO_PEERS',
  'MPL_LOSS_RATIO_VOLATILITY',
  'MPL_LOSS_RATIO_VOLATILITY_PEERS',
  'MPL_LOSS_RATIO_VS_PEERS',
  'MPL_MARKET_SHARE',
  'MPL_MARKET_CONCENTRATION',
  'MPL_MARKET_SHARE_TABLE',
] as const

export type BenchmarkCombinedMetricID =
  typeof BENCHMARK_COMBINED_METRIC_IDS[number]

/**
 * Many BenchmarkMetric's are composite metrics where multiple metric IDs'
 * data are gathered and them combined using a compositeMethod formula. Their
 * combined metrics still need a unique ID for example to save a metrics
 * visibility status in state, which is usually set to one of the composite IDs.
 * However, for instance, for the stat underwriting config, many of the
 * composite metrics have identical composite IDs, not enough of them to provide
 * a unique ID for each composite metric. Instead we use the dummy metric IDs
 * in this file to distinguish between them.
 */
export const BENCHMARK_COMBINED_METRICS_DUMMY_RESPONSE =
  BENCHMARK_COMBINED_METRIC_IDS.map(
    (id): BenchmarkMetricResponse => ({
      id,
      itemId: id,
      definition: '',
      name: String(id),
    })
  )
