import * as d3 from 'd3'
import { select } from 'd3-selection'
import { hexGraphingColorPalette } from './utils/graphing-color-palette'

export interface VerticalStackedAreaOptions {
  chartLabel?: string
  xLabel?: string
  yLabel?: string
  min?: boolean
  lightChartMode?: boolean
}

export interface VerticalStackedAreaDatum {
  groupBy: string
  values: { x: number; y: number; raw: number }[]
}

export class VerticalStackedAreaChart {
  private container: HTMLElement
  private margin = { top: 100, right: 100, bottom: 100, left: 100 }
  private options: VerticalStackedAreaOptions

  constructor(container: HTMLElement, options: VerticalStackedAreaOptions) {
    this.container = container
    this.options = options
    select(container).selectAll('*').remove()
  }

  graphingColorAreaPalette = hexGraphingColorPalette

  draw(data: VerticalStackedAreaDatum[]) {
    if (this.options.min) {
      this.margin.top = 50
    }
    const color = d3.scaleOrdinal([
      ...this.graphingColorAreaPalette.slice(0, data.length + 1).reverse(),
    ])
    const margin = this.margin
    const width = this.container.clientWidth - margin.left - margin.right
    const height = this.container.clientHeight - margin.top - margin.bottom
    const xScaleDomain: number[] = [data[0].values[data[0].values.length - 1].x, data[0].values[0].x]
    const xScale = d3.scaleLinear().domain(xScaleDomain).range([0, height])
    const yScale = d3.scaleLinear().domain([0, 100]).range([0, width])

    const svg = select(this.container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)

    const chartGroup = svg
      .append('g')
      .attr('height', width + margin.left + margin.right)
      .attr('width', height + margin.top + margin.bottom)
      .attr(
        'transform',
        `translate(${width + margin.bottom}, ${height + margin.top}) rotate(90) scale(-1,1)`
      )

    const xKeys = data[0].values.map(d => d.x)
    const stackData = xKeys.map((x, i) => {
      const entry: { x: number; [key: string]: number } = { x }
      data.forEach(d => {
        entry[d.groupBy] = d.values[i].y ?? 0
      })
      return entry
    })


    const stack = d3.stack<{ x: number }>().keys(data.map(d => d.groupBy))
    const series = stack(stackData)

    const area = d3
      .area<any>()
      .x(d => xScale(d.data.x))
      .y0(d => yScale(d[0]))
      .y1(d => yScale(d[1]))
      .curve(d3.curveBasis)

    const tooltip = d3
      .select(this.container)
      .append('div')
      .style('opacity', 0)
      .style('transition', 'all 1s')
      .attr('class', 'custom-tooltip')

    chartGroup
      .selectAll('.layer')
      .data(series)
      .join('path')
      .attr('class', 'layer')
      .attr('d', area)
      .style('fill', (_, i) => color(String(i)))
      .on('mouseover', event => {
        tooltip?.style('opacity', 1)
        select(event.currentTarget).style('opacity', 1)
      })
      .on('mousemove', (event: any, d: any) => {
        const coord = d3.pointer(event)
        const key = d.key
        const datum = data.find(datum => datum.groupBy === key)
        tooltip
          .style('display', 'block')
          .style('opacity', 1)
          .style('z-index', '9999')
          .style('text-align', 'center')
          .style('text-transform', 'capitalize')
          .html(
            `
                <div>
                  <strong>${d.key}</strong>
                  <div>
                    ${[...datum.values]
                      .reverse()
                      .map(v => {
                        return `
                        <div>
                          <span style="width: 50px; display: inline-block;">${v.x}:</span> ${v.raw}
                        </div>
                        `
                      })
                      .join('')}
                  </div>
                </div>
              `
          )
          .style('top', `${height - coord[0]}px`)
          .style('left', `${width - coord[1]}px`)
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none')
      })

    const xAxis = d3.axisTop(yScale).tickFormat(d => `${d}%`)
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')

    const tickValues = data[0].values.map(v => v.x)
    const yAxis = d3
      .axisLeft(xScale)
      .tickValues(tickValues)
      .tickFormat(d3.format('d'))
    svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(yAxis)

    svg
      .selectAll('text')
      .style('fill', this.options.lightChartMode ? 'black' : 'white')

    if (!this.options.min) {
      svg
        .append('text')
        .attr('class', 'chart-title')
        .style('fill', this.options.lightChartMode ? 'black' : 'var(--body)')
        .style('font-size', '20px')
        .style('width', 'fit-content')
        .style('height', '25px')
        .attr('transform', `translate(${height},50)`)
        .text(this.options.chartLabel)

      const legend = svg
        .append('g')
        .attr('class', 'legend')
        .attr(
          'transform',
          `translate(${margin.left}, ${height + margin.top + 20})`
        )

      data.forEach((_, i) => {
        const index = data.length - 1 - i
        const itemWidth = width / 5
        const xOffset = (i % 5) * itemWidth
        const yOffset = Math.floor(i / 5) * 20

        const legendRow = legend
          .append('g')
          .attr('transform', `translate(${xOffset}, ${yOffset})`)
          .attr('width', width - 50)

        legendRow
          .append('rect')
          .attr('width', 10)
          .attr('height', 10)
          .style('fill', () => color(String(index)))
          .on('mouseover', function (event) {
            tooltip.style('visibility', 'visible').text(data[index].groupBy)
          })
          .on('mousemove', function (event) {
            tooltip
              .style('top', `${event.pageY + 5}px`)
              .style('left', `${event.pageX + 5}px`)
          })
          .on('mouseout', () => tooltip.style('visibility', 'hidden'))

        legendRow
          .append('text')
          .attr('x', 20)
          .attr('y', 10)
          .text(data[index].groupBy)
          .style('text-anchor', 'start')
          .style('font-family', 'sans-serif')
          .style('font-size', '14px')
          .style('fill', this.options.lightChartMode ? 'black' : 'var(--body)')
      })
    }
  }
}
