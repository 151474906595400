import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AgencyDetails, Reinsurer } from '../../core/model/reinsurer.model'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../core/store/index'
import { selectStudyReinsurersSaving } from '../store/reinsurers.selectors'
import {
  updateOrAddStudiesReinsurerDirty,
  saveCompanyPapers,
  updateReinsurer,
  setUpdateOneReinsurer,
} from '../store/study-reinsurers.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fund-manager-detail-dialog-container',
  template: `
    <app-fund-manager-detail-dialog-component
      [reinsurer]="data.reinsurer"
      [companyPapers]="data.companyPapers"
      [selectedCompanyPapers]="data.selectedCompanyPapers"
      [incumbentOrTargetMarket]="data.incumbentOrTargetMarket"
      [savingReinsurer]="savingReinsurer$ | async"
      (updateOrAddDirty)="onUpdateOrAddDirty($event)"
      (setReinsurer)="onSetReinsurer($event)"
      (saveClick)="onSaveClick($event)"
    ></app-fund-manager-detail-dialog-component>
  `,
})
export class FundManagerDetailDialogContainerComponent implements OnInit {
  programId: string
  savingReinsurer$: Observable<boolean>

  constructor(
    public dialogRef: MatDialogRef<FundManagerDetailDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      programId: string
      reinsurer: Reinsurer
      companyPapers: AgencyDetails[]
      selectedCompanyPapers: AgencyDetails[]
      incumbentOrTargetMarket: 'inc' | 'tm'
    },
    private store: Store<AppState>
  ) {
    this.programId = data.programId
  }

  ngOnInit(): void {
    this.savingReinsurer$ = this.store.pipe(select(selectStudyReinsurersSaving))
  }

  onSetReinsurer(reinsurer: Reinsurer): void {
    this.store.dispatch(
      setUpdateOneReinsurer({
        programID: this.programId,
        reinsurer,
      })
    )
  }

  onUpdateOrAddDirty(reinsurer: Reinsurer): void {
    this.store.dispatch(updateOrAddStudiesReinsurerDirty({ reinsurer }))
  }

  onSaveClick($event: {
    reinsurer: Reinsurer
    selectedCompanyPapers: AgencyDetails[]
  }): void {
    this.store.dispatch(
      saveCompanyPapers({
        programID: this.programId,
        reinsurer: $event.reinsurer,
        selectedCompanyPapers: $event.selectedCompanyPapers,
      })
    )
    this.store.dispatch(updateReinsurer())
  }
}
