import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { lensPath, set } from 'ramda'
import { analyzereConstants } from '@shared/constants/analyzere'
import { Change, OptimizationInputRange } from '../optimization.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-range-input',
  styleUrls: ['./optimization-range-input.component.scss'],
  templateUrl: './optimization-range-input.component.html',
})

export class OptimizationInputRangeComponent {
  @Input() showExtraLabels: boolean
  @Input() range: OptimizationInputRange

  @Output() rangeChange = new EventEmitter<Change<OptimizationInputRange>>()

  get isCurrency(): boolean {
    return this.range.type === 'currency'
  }
  get isText(): boolean {
    return this.range.type === 'text'
  }
  get isPercentage(): boolean {
    return this.range.type === 'percentage'
  }

  get name() {
    return this.range.label
  }

  setValue(property: string, value: any): void {
    const range = set(lensPath([property]), value, this.range)

    if (property !== 'numberOfOptions') {
      if (
        (this.isUnlimited(range.to) && this.isUnlimited(range.from)) ||
        range.to === range.from
      ) {
        range.numberOfOptions = 1
        range.incrementsOf = 0
      } else if (this.isUnlimited(range.to)) {
        // range.numberOfOptions = 1
        // range.incrementsOf = 0
        // do nothing
      } else {
        const numberOfOptions = Math.floor(
          (range.to - range.from) / range.incrementsOf
        )
        range.numberOfOptions = this.isValid(numberOfOptions)
          ? numberOfOptions + 1
          : 0
      }
    } else {
      if (!this.isUnlimited(range.to) || this.isUnlimited(range.from)) {
        const incrementsOf =
          (range.to - range.from) / (range.numberOfOptions - 1)
        range.incrementsOf = this.isValid(incrementsOf) ? incrementsOf : 0
      }
    }

    this.rangeChange.emit({ id: range.id, changes: range })
  }

  isUnlimited(value: number) {
    return value >= analyzereConstants.unlimitedValue
  }

  isValid(value: number) {
    return !isNaN(value) && isFinite(value) && value >= 0
  }

  get disableIncrementsAndOptions() {
    return (
      (this.isUnlimited(this.range.from) && this.isUnlimited(this.range.to)) ||
      this.range.from === this.range.to
    )
  }
}
