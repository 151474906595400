<div class="main">
  <h1 mat-dialog-title>Errors and Warnings</h1>
  <div mat-dialog-content>
    <div class="content">
      <div class="key">
        <div>❌ Error</div>
        <div>⚠️ Warning</div>
      </div>
      <div class="error-table"
        *ngIf="data.orderErrors.length > 0"
        >
        <h3>Order % Errors</h3>
        <app-sort-table
          class="table"
          spacing="tight"
          [columnDefs]="orderErrorColumnDefs"
          [rows]="orderMessages"
          [disableSort]="true"
          ></app-sort-table>
      </div>
      <div
        *ngIf="messages.length > 0"
        class="error-table"
        >
        <h3>Line Errors</h3>
        <app-sort-table
          class="table"
          spacing="tight"
          [columnDefs]="columnDefs"
          [rows]="messages"
          [disableSort]="true"
          ></app-sort-table>
      </div>
      <i class="hint">Cannot submit to whitespace until all ❌errors❌ are cleared</i>
    </div>
  </div>
  <div mat-dialog-actions>
    <button appButton border mat-dialog-close>
      Cancel
    </button>
    <button appButton border accent [disabled]="data.errors.length > 0 || orderMessages.length > 0"
            (click)="confirm()" class="confirm-button">
      Confirm
    </button>
  </div>
</div>
