<div class="main">
  <h1 mat-dialog-title>
    Confirm with underwriters
  </h1>
  <div mat-dialog-content>
    <div class="content">
      <i class="hint">
        Please ensure the following signed lines have been communicated to underwriters
      </i>
      <app-sort-table
        class="table"
        spacing="tight"
        [columnDefs]="columnDefs"
        [rows]="rows"
        [disableSort]="true"
        ></app-sort-table>
        <div class="market-tp" *ngIf="emptyTpRefWarning">
          <i class="warning">
            *All unconfigured lines have collapsed onto a blank single line above.
          </i>
          <i class="hint">
            **Unconfigured lines refers to lines that do not have a reinsurer name.<br>&nbsp;&nbsp;Using the "Set Line Reinsurer" button, you can set the reinsurer name for these lines.
          </i>
        </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button appButton border mat-dialog-close>
      Cancel
    </button>
    <button appButton border accent
            (click)="confirm()"
            class="confirm-button">
      Submit to Whitespace
    </button>
  </div>
</div>
