import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { ScenarioEventResult } from '../../animated-scenarios.model'
import { createReducer, on, Action } from '@ngrx/store'
import * as fromGroupScenarioEventResultsActions from './group-scenario-event-result.actions'
import { saveSuccess } from '../../../store/ceded-layers/layers.actions'

export type ScenarioEventResultState = EntityState<ScenarioEventResult>

function sortComparer(a: ScenarioEventResult, b: ScenarioEventResult) {
  return a.id - b.id
}

export const adapter = createEntityAdapter<ScenarioEventResult>({
  sortComparer,
})

export const initialState = adapter.getInitialState()

const scenarioEventResultReduer = createReducer(
  initialState,
  on(
    fromGroupScenarioEventResultsActions.addAllGroupScenarioEventResults,
    (state, { eventResults }) => {
      return adapter.setAll(eventResults, state)
    }
  ),

  on(
    fromGroupScenarioEventResultsActions.removeAllGroupScenarioEventResults,
    saveSuccess,
    state => {
      return adapter.removeAll({ ...state, scenarioStructure: null })
    }
  )
)

export function reducer(
  state: ScenarioEventResultState | undefined,
  action: Action
) {
  return scenarioEventResultReduer(state, action)
}
