import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { Reinsurer, ReinsurerFilter } from '../../core/model/reinsurer.model'
import * as fromStudyReinsurerActions from './study-reinsurers.actions'

export const FEATURE_KEY = 'reinsurers'

export interface StudyReinsurersState {
  programID: string
  reinsurers: Reinsurer[]
}

export interface State extends EntityState<StudyReinsurersState> {
  saving: boolean
  dirty: Reinsurer[]
  populateFrom: Reinsurer[]
  reinsurerFilters: ReinsurerFilter[]
}

export const adapter = createEntityAdapter<StudyReinsurersState>({
  selectId: (studyReinsurersState): string => studyReinsurersState.programID,
})

export const initialState: State = adapter.getInitialState({
  saving: false,
  dirty: [],
  populateFrom: [],
  reinsurerFilters: [],
})

const studyReinsurersReducer = createReducer(
  initialState,

  on(
    fromStudyReinsurerActions.fetchStudyReinsurerSuccess,
    (state: State, { programID, reinsurers }) => {
      return adapter.upsertOne(
        { reinsurers, programID },
        { ...state, dirty: [] }
      )
    }
  ),
  on(
    fromStudyReinsurerActions.setReinsurer,
    (state: State, { programID, reinsurers }) => {
      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(fromStudyReinsurerActions.saveReinsurer, (state: State, {}) => {
    return { ...state, saving: true }
  }),

  on(fromStudyReinsurerActions.updateReinsurerSuccess, (state: State, {}) => {
    return { ...state, saving: false, populateFrom: [] }
  }),

  on(fromStudyReinsurerActions.updateReinsurerFailure, (state: State, {}) => {
    return { ...state, saving: false }
  }),

  on(
    fromStudyReinsurerActions.setUpdateOneReinsurer,
    (state, { programID, reinsurer }) => {
      const entities = state.entities[programID]
      if (entities) {
        const reinsurers = entities.reinsurers.map(r =>
          r.id === reinsurer.id &&
          (reinsurer.reinsurerProgramFactor[0].obo_name ===
            r.reinsurerProgramFactor[0].obo_name ||
            reinsurer.reinsurerProgramFactor[0].id ===
              r.reinsurerProgramFactor[0].id)
            ? reinsurer
            : r
        )
        return adapter.upsertOne({ reinsurers, programID }, state)
      }
      return state
    }
  ),

  on(
    fromStudyReinsurerActions.fetchStudiesReinsurerSuccess,
    (state: State, { reinsurersByStudy }) => {
      return adapter.upsertMany(reinsurersByStudy, { ...state, dirty: [] })
    }
  ),

  on(
    fromStudyReinsurerActions.updateOrAddStudiesReinsurerDirty,
    (state: State, { reinsurer }) => {

        return {
          ...state,
          dirty: [...state.dirty, reinsurer],
        }
    }
  ),

  on(
    fromStudyReinsurerActions.removeAgencySeqFromReinsurerDirty,
    (state: State, { agencyTPRef, sequenceNumber }) => {
      const newDirty = state.dirty.filter(d => !agencyMatches(d, agencyTPRef, sequenceNumber))

      return {
        ...state,
        dirty: newDirty,
      }
    }
  ),

  on(
    fromStudyReinsurerActions.removeAgencyFromReinsurerDirty,
    (state: State, { agencyTPRef }) => {
      const newDirty = state.dirty.filter(d => !(d.tpRef === agencyTPRef))

      return {
        ...state,
        dirty: newDirty,
      }
    }
  ),

  on(
    fromStudyReinsurerActions.toggleReinsurerFilter,
    (state: State, { reinsurerFilter }) => {
      const newFilters = state.reinsurerFilters.filter(
        rf =>
          rf.column !== reinsurerFilter.column ||
          rf.value !== reinsurerFilter.value
      )

      // If nothing was filtered out, then must be a new one
      if (state.reinsurerFilters.length === newFilters.length) {
        newFilters.push(reinsurerFilter)
      }

      return {
        ...state,
        reinsurerFilters: newFilters,
      }
    }
  ),

  on(
    fromStudyReinsurerActions.removeReinsurerFilter,
    (state: State, { reinsurerFilter }) => {
      const newFilters = state.reinsurerFilters.filter(
        rf =>
          rf.column !== reinsurerFilter.column ||
          rf.value !== reinsurerFilter.value
      )
      return {
        ...state,
        reinsurerFilters: newFilters,
      }
    }
  ),

  on(
    fromStudyReinsurerActions.removeAllReinsurerFilters,
    (state: State, {}) => {
      return { ...state, reinsurerFilters: [] }
    }
  ),

  on(
    fromStudyReinsurerActions.fetchPopulateFromReinsurerSuccess,
    (state: State, { reinsurers }) => {
      return { ...state, populateFrom: reinsurers }
    }
  ),

  on(
    fromStudyReinsurerActions.fetchCompanyPapersSuccess,
    (
      state: State,
      { programID, reinsurer, companyPapers, selectedCompanyPapers }
    ) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }

      let reinsurers: Reinsurer[] = entities.reinsurers.map(r =>
        r.id === reinsurer.id &&
        r.reinsurerProgramFactor[0].relation_seq_number === reinsurer.reinsurerProgramFactor[0].relation_seq_number &&
        r.reinsurerProgramFactor[0].obo_name === reinsurer.reinsurerProgramFactor[0].obo_name ?
        { ...reinsurer, companyPapers } :
        r
      )

      if (selectedCompanyPapers) {
        reinsurers = reinsurers.map(r =>
          r.id === reinsurer.id &&
          r.reinsurerProgramFactor[0].relation_seq_number === reinsurer.reinsurerProgramFactor[0].relation_seq_number &&
          r.reinsurerProgramFactor[0].obo_name === reinsurer.reinsurerProgramFactor[0].obo_name ?
          { ...reinsurer, selectedCompanyPapers } :
          r
        )
      }

      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(
    fromStudyReinsurerActions.fetchMultipleCompanyPapersSuccess,
    (state: State, { programID, fundManagers, companyPaperPairs }) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }
      const reinsurers = entities.reinsurers.map(reinsurer =>
        fundManagers.includes(reinsurer)
          ? {
              ...reinsurer,
              // tslint:disable-next-line: no-non-null-assertion
              selectedCompanyPapers: companyPaperPairs[reinsurer.tpRef!],
            }
          : reinsurer
      )

      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(
    fromStudyReinsurerActions.saveCompanyPapersSuccess,
    (state: State, { programID, reinsurer, selectedCompanyPapers }) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }

      const reinsurers = entities.reinsurers.map(r =>
        r.id === reinsurer.id ? { ...reinsurer, selectedCompanyPapers } : r
      )

      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(
    fromStudyReinsurerActions.fetchSegregatedAccountDetailsSuccess,
    (
      state: State,
      { programID, fundManager, segregatedAccounts, selectedSegregatedAccounts }
    ) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }

      let reinsurers: Reinsurer[] = entities.reinsurers.map(r =>
        r.id === fundManager.id ? { ...fundManager, segregatedAccounts } : r
      )

      if (selectedSegregatedAccounts) {
        reinsurers = reinsurers.map(r =>
          r.id === fundManager.id
            ? { ...fundManager, selectedSegregatedAccounts }
            : r
        )
      }
      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(
    fromStudyReinsurerActions.saveSegregatedAccountDetailsSuccess,
    (state: State, { programID, reinsurer, selectedSegregatedAccounts }) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }

      const reinsurers = entities.reinsurers.map(r =>
        r.id === reinsurer.id ? { ...reinsurer, selectedSegregatedAccounts } : r
      )

      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  ),

  on(
    fromStudyReinsurerActions.fetchMultipleSegregatedAccountDetailsSuccess,
    (state: State, { programID, fundManagers, segregatedAccountPairs }) => {
      const entities = state.entities[programID]
      if (!entities) {
        return state
      }
      const reinsurers = entities.reinsurers.map(reinsurer =>
        fundManagers.includes(reinsurer)
          ? {
              ...reinsurer,
              selectedSegregatedAccounts:
                // tslint:disable-next-line: no-non-null-assertion
                segregatedAccountPairs[reinsurer.tpRef!],
            }
          : reinsurer
      )

      return adapter.upsertOne({ reinsurers, programID }, state)
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return studyReinsurersReducer(state, action)
}

export function agencyMatches(reinsurer: Reinsurer, agencyTPRef: string, sequenceNumber: number): boolean {
  if (reinsurer.tpRef === agencyTPRef){
    return reinsurer.reinsurerProgramFactor && reinsurer.reinsurerProgramFactor.some(rpf => rpf.relation_seq_number === sequenceNumber)
  }
  return false
}
