import { setMarketToken } from '../../core/store/auth/auth.actions'
import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { mergeMap, withLatestFrom, switchMap } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { selectAuthState } from '../../core/store/auth/auth.selectors'
import { BackendService } from 'src/app/api/backend/backend.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private backendService: BackendService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.includes(environment.internalApi.base) &&
      !(
        req.method === 'GET' &&
        req.url.includes(environment.internalApi.azureb2c)
      )
    ) {
      return of(1).pipe(
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        mergeMap(([_, authState]) => {
          if (authState.token) {
            if (this.tokenExpired(authState.token)) {
              throwError('User unauthorized')
              window.location.reload()
              return next.handle(req.clone())
            } else {
              return next.handle(
                req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + authState.token
                  ),
                })
              )
            }
          } else {
            throwError('User unauthorized')
            window.location.reload()
            return next.handle(req.clone())
          }
        })
      )
    } else if (req.url.includes(environment.api.base)) {
      return of(1).pipe(
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        mergeMap(([_, authState]) => {
          return next.handle(
            req.clone({
              headers: req.headers.set(
                'Authorization',
                `Basic ${authState.analyzeReToken}`
              ),
            })
          )
        })
      )
    } else if (
      (req.url.includes(environment.marketApi.base) &&
        !req.url.includes(environment.marketApi.authenticate)) ||
      req.url.includes(environment.documentApi.base)
    ) {
      return of(1).pipe(
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        mergeMap(([_, authState]) => {
          if (
            authState.marketToken &&
            !this.tokenExpired(authState.marketToken)
          ) {
            return next.handle(
              req.clone({
                headers: req.headers.set(
                  'Authorization',
                  `Bearer ${authState.marketToken}`
                ),
              })
            )
          } else {
            return this.backendService.getMarketToken().pipe(
              switchMap((res: any) => {
                if (res.error) {
                  return throwError(res.error)
                } else {
                  this.store.dispatch(
                    setMarketToken({ marketToken: res.data.token })
                  )
                  return next.handle(
                    req.clone({
                      headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + res.data.token
                      ),
                    })
                  )
                }
              })
            )
          }
        })
      )
    } else {
      return next.handle(req)
    }
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    // tslint:disable-next-line: new-parens
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }
}
