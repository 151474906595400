<input
  *ngIf="isInput"
  type="text"
  [ngModel]="notesModel"
  (focus)="inputFocus()"
  />
<textarea
  #textNote
  *ngIf="!isInput"
  [ngModel]="notesModel"
  (ngModelChange)="setNotesModel.emit($event)"
  (focusout)="textareaFocusOut()"
  autofocus
></textarea>
