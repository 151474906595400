import {
  CreditStructureGroup,
  CreditStructureGroupEntity,
  CreditStructureGroupMemberEntity,
} from './../../credit/model/credit-structure-group.model'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import {
  AccountOpportunity,
  FeatureFlag,
  StudyResponse,
} from '../../api/model/backend.model'
import { ClientYear } from '../../core/model/client.model'
import { Program } from '../../core/model/program.model'
import { Study } from '../../core/model/study.model'
import { ErrorComponent } from '../../error/error.component'
import { errorPayload } from '../../error/model/error'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import {
  TierStructuresDialogComponent,
  TierStructuresDialogConfig,
} from '../tier-structures-dialog.component/tier-structures-dialog.component'
import { CompareView } from '../../analysis/model/compare-view.model'
import { ImportLossSetsClickEvent } from '../../core/store/program/program.actions'
import { TierService } from '../tier.service'
import { CreditStructure } from '../../credit/model/credit-structure.model'

type TierStructuresDialogRef = MatDialogRef<
  TierStructuresDialogComponent,
  TierStructuresDialogConfig
>

const PANEL_CLASS = 'app-tier-structures-dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tier-structures-menu',
  styleUrls: ['./tier-structures-menu.component.scss'],
  templateUrl: './tier-structures-menu.component.html',
})
export class TierStructuresMenuComponent implements OnDestroy {
  private destroy$ = new Subject<void>()

  hover = false
  autoBuildSelected = true
  delayDialogTrigger: boolean

  @Input() showDialogClone: boolean
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs: Study[]
  @Input() programs2: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() title: string
  @Input() routerUrl: string

  @Input() set analysisProfileID(value: string | null) {
    this._analysisProfileID = value
    this.updateDialogData()
  }
  private _analysisProfileID: string | null

  @Input() set yearID(value: string | null) {
    this._yearID = value
    this.updateDialogData()
  }
  private _yearID: string | null

  @Input() set years(value: ClientYear[] | undefined) {
    this._years = value
    this.updateDialogData()
  }
  private _years: ClientYear[] | undefined

  @Input() set years2(value: ClientYear[] | undefined) {
    this._years2 = value
    this.updateDialogData()
  }
  private _years2: ClientYear[] | undefined

  @Input() set structureGroups(value: ProgramGroup[] | undefined) {
    this._structureGroups = value
    this.updateDialogData()
  }
  private _structureGroups: ProgramGroup[] | undefined

  @Input() set structures(value: Program[] | undefined) {
    this._structures = value
    this.updateDialogData()
  }
  private _structures: Program[] | undefined

  @Input() set structures2(value: Program[] | undefined) {
    this._structures2 = value
    this.updateDialogData()
  }
  private _structures2: Program[] | undefined
  @Input() set compareViews(value: CompareView[] | undefined) {
    this._compareViews = value
    this.updateDialogData()
  }
  private _compareViews: CompareView[] | undefined

  @Input() set selectedCompareView(value: CompareView | null) {
    this._selectedCompareView = value
    this.updateDialogData()
  }
  private _selectedCompareView: CompareView | undefined

  @Input() set selectedClientID(value: string | null) {
    this._selectedClientID = value
    this.updateDialogData()
  }
  private _selectedClientID: string | null

  @Input() set selectedClientID2(value: string | null) {
    this._selectedClientID2 = value
    this.updateDialogData()
  }
  private _selectedClientID2: string | null

  @Input() set selectedYearID(value: string | null) {
    this._selectedYearID = value
    this.updateDialogData()
  }
  private _selectedYearID: string | null
  @Input() set selectedYearID2(value: string | null) {
    this._selectedYearID2 = value
    this.updateDialogData()
  }
  private _selectedYearID2: string | null

  @Input() set selectedStructureGroupIDs(value: string[] | undefined) {
    this._selectedStructureGroupIDs = value
    this.updateDialogData()
  }
  private _selectedStructureGroupIDs: string[] | undefined

  @Input() set selectedStructureIDs(value: any[] | undefined) {
    if (this.showDialogClone && value) {
      const ids = value.map((e: { id: any }) => e.id)
      this._selectedStructureIDs = ids
    } else {
      this._selectedStructureIDs = value
    }
    this.updateDialogData()
  }
  private _selectedStructureIDs: string[] | undefined

  @Input() set selectedStructures(value: any[] | undefined) {
    this._selectedStructures = value
    this.updateDialogData()
  }
  private _selectedStructures: Program[] | undefined

  @Input() set allowScenarioOrOptimizationSelection(value: any) {
    this._allowScenarioOrOptimizationSelection = coerceBooleanProperty(value)
    this.updateDialogData()
  }
  private _allowScenarioOrOptimizationSelection: boolean

  @Input() contextPath?: string[]

  @Input() set isButton(value: any) {
    this._isButton = coerceBooleanProperty(value)
  }
  get isButton() {
    return this._isButton
  }
  _isButton = false

  @Input() set structureFilter(value: string | null) {
    this._structureFilter = value
    this.updateDialogData()
  }
  _structureFilter: string | null

  _structureGroupMembers: ProgramGroupMember[]
  @Input() set structureGroupMembers(value: ProgramGroupMember[]) {
    this._structureGroupMembers = value
    this.updateDialogData()
  }

  _groupFilterByAssoc: boolean
  @Input() set groupFilterByAssoc(value: boolean) {
    this._groupFilterByAssoc = value
    this.updateDialogData()
  }

  _studyID: string | null
  @Input() set studyID(value: string | null) {
    this._studyID = value
    this.updateDialogData()
  }
  _studyID2: string | null
  @Input() set studyID2(value: string | null) {
    this._studyID2 = value
    this.updateDialogData()
  }

  private _studies: StudyResponse[]

  @Input() set studies(value: StudyResponse[]) {
    this._studies = value
    this.updateDialogData()
  }

  private _featureFlags: FeatureFlag[]

  @Input() set featureFlags(value: FeatureFlag[]) {
    this._featureFlags = value
    this.updateDialogData()
  }

  private _selectedCreditGroup: CreditStructureGroupEntity
  @Input() set selectedCreditGroup(value: CreditStructureGroupEntity) {
    this._selectedCreditGroup = value
    this.updateDialogData()
  }

  private _selectedCreditGroupMembers: CreditStructureGroupMemberEntity[]
  @Input() set selectedCreditGroupMembers(
    value: CreditStructureGroupMemberEntity[]
  ) {
    this._selectedCreditGroupMembers = value
    this.updateDialogData()
  }

  @Output() structureGroupAdd = new EventEmitter<ProgramGroup>()
  @Output() structureGroupRemove = new EventEmitter<string>()
  @Output() structureAdd = new EventEmitter<Program | Program[]>()
  @Output() structureRemove = new EventEmitter<Program | Program[]>()
  @Output() updateSelectedCompareView = new EventEmitter<CompareView>()
  @Output() structureFilterChange = new EventEmitter<string | null>()
  @Output() groupFilterByAssocChange = new EventEmitter<boolean>()
  @Output() importLossSetsClick = new EventEmitter<ImportLossSetsClickEvent>()

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private ts: TierService
  ) {
    this.ts
      .getDelayDialogPopup()
      .pipe()
      .subscribe(a => (this.delayDialogTrigger = a))
    this.checkDialog()
  }
  @Output() showDialogFlag = new EventEmitter<string>()
  showDialog() {
    this.showDialogFlag.emit('clicked')
  }
  ngOnDestroy() {
    const ref = this.getDialogRef()
    if (ref) {
      ref.close()
    }
    this.destroy$.next()
    this.destroy$.complete()
  }

  buildDialogData(): TierStructuresDialogConfig {
    return {
      analysisProfileID: this._analysisProfileID,
      yearID: this._yearID,
      years: this._years,
      years2: this._years2,
      structureGroups: this._structureGroups,
      structures: this._structures,
      structures2: this._structures2,
      selectedYearID: this._selectedYearID,
      selectedYearID2: this._selectedYearID2,
      selectedClientID: this._selectedClientID,
      selectedClientID2: this._selectedClientID2,
      selectedStructureGroupIDs: this._selectedStructureGroupIDs,
      selectedStructureIDs: this._selectedStructureIDs,
      selectedStructures: this._selectedStructures,
      allowScenarioOrOptimizationSelection:
        this._allowScenarioOrOptimizationSelection,
      contextPath: this.contextPath,
      structureFilter: this._structureFilter,
      structureGroupMembers: this._structureGroupMembers,
      groupFilterByAssoc: this._groupFilterByAssoc,
      studyID: this._studyID,
      studyID2: this._studyID2,
      showDialogClone: this.showDialogClone,
      programs: this.programs,
      programs2: this.programs2,
      compareViews: this._compareViews,
      selectedCompareView: this._selectedCompareView,
      studies: this._studies,
      featureFlags: this._featureFlags,
      routerUrl: this.routerUrl,
      selectedCreditGroup: this._selectedCreditGroup,
      selectedCreditGroupMembers: this._selectedCreditGroupMembers,
    }
  }

  updateDialogData() {
    const ref = this.getDialogRef()
    if (ref && ref.componentInstance) {
      ref.componentInstance.setData(this.buildDialogData())
    }
  }

  checkDialog(): void {
    if (!this.delayDialogTrigger) {
      this.openDialog()
      this.showDialog()
    } else {
      const dialogDelayReset = timer(1000).subscribe(() => {
        dialogDelayReset.unsubscribe()
        this.ts.setDelayDialogPopup(false)
      })
    }
  }

  openDialog(): void {
    let ref = this.getDialogRef()
    if (ref) {
      return
    }

    const data: TierStructuresDialogConfig = {
      triggerRef: this.elementRef,
      leftOffset: -16,
      topOffset: -12,
      panelClass: PANEL_CLASS,
      ...this.buildDialogData(),
    }

    ref = this.dialog.open(TierStructuresDialogComponent, {
      id: PANEL_CLASS,
      panelClass: PANEL_CLASS,
      data,
      height: '70vh',
      maxWidth: 'calc(100vw - 2 * var(--inset-big))',
      width: 'calc(100vw - 2 * var(--inset-big)) !important',
    })

    ref.componentInstance.importLossSetsClick$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        this.onImportLossSetsClick(value)
      })

    ref.componentInstance.structureGroupSelectionChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        this.onStructureGroupSelectionChange(value)
      })

    ref.componentInstance.structureSelectionChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        // tslint:disable-next-line: no-non-null-assertion
        this.onStructureSelectionChange(value!)
      })

    ref.componentInstance.compareViewSelectionChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        // tslint:disable-next-line: no-non-null-assertion
        this.onCompareViewSelectionChange(value!)
      })

    ref.componentInstance.scenarioOrOptimizationSelectChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        // tslint:disable-next-line: no-non-null-assertion
        this.onScenarioOrOptimizationSelectChange(value!)
      })

    ref.componentInstance.structureFilterChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => this.onStructureFilterChange(value))

    ref.componentInstance.groupFilterByAssocChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => this.onGroupFilterByAssocChange(value))
  }

  onImportLossSetsClick($event: ImportLossSetsClickEvent) {
    this.importLossSetsClick.emit($event)
  }

  onStructureGroupSelectionChange($event: ProgramGroup) {
    if (
      this._selectedStructureGroupIDs &&
      this._selectedStructureGroupIDs.includes($event.id)
    ) {
      return this.structureGroupRemove.emit($event.id)
    }
    if (
      this.validateGroupAnalysisProfileID($event) &&
      this.validateGroupYearID($event)
    ) {
      return this.structureGroupAdd.emit($event)
    }
  }

  onStructureSelectionChange($event: Program) {
    if (this._selectedStructureIDs?.includes($event.id)) {
      const remove = [$event]
      // If the structure has any scenarios selected, remove them too
      if ($event.scenarioIDs?.length) {
        $event.scenarioIDs.forEach(id => {
          if (this._selectedStructureIDs?.includes(id)) {
            const scenario = this._structures?.find(s => s.id === id)
            if (scenario) {
              remove.push(scenario)
            }
          }
        })
      }
      // If the structure has any optimizations selected, remove them too
      if ($event.optimizationIDs?.length) {
        $event.optimizationIDs.forEach(id => {
          if (this._selectedStructureIDs?.includes(id)) {
            const optimization = this._structures?.find(s => s.id === id)
            if (optimization) {
              remove.push(optimization)
            }
          }
        })
      }
      return this.structureRemove.emit(remove)
    }
    if (
      this.validateProgramAnalysisProfileID($event) &&
      this.validateProgramYearID($event)
    ) {
      return this.structureAdd.emit($event)
    }
  }

  onCompareViewSelectionChange($event: CompareView) {
    this.updateSelectedCompareView.emit($event)
  }

  onScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<Program>
  ) {
    const { parent, add, remove } = $event
    let _add = [...(add ?? [])]

    // If scenarios' parent not yet added, add it if valid
    let valid = true
    if (parent && !this._selectedStructureIDs?.includes(parent.id)) {
      if (
        this.validateProgramAnalysisProfileID(parent) &&
        this.validateProgramYearID(parent)
      ) {
        _add = [parent, ..._add]
      } else {
        valid = false
      }
    }

    if (valid) {
      if (remove) {
        const filteredRemove = remove.filter(p =>
          this._selectedStructureIDs?.includes(p.id)
        )
        if (filteredRemove.length > 0) {
          this.structureRemove.emit(filteredRemove)
        }
      }
      if (_add.length > 0) {
        this.structureAdd.emit(_add)
      }
    }
  }

  onStructureFilterChange(value: string | null) {
    this.structureFilterChange.emit(value)
  }

  onGroupFilterByAssocChange(value: boolean) {
    this.groupFilterByAssocChange.emit(value)
  }

  private getDialogRef(): TierStructuresDialogRef | undefined {
    return this.dialog.getDialogById(PANEL_CLASS)
  }

  private validateProgramAnalysisProfileID(program?: Program): boolean {
    return this.validateAnalysisProfileID(program, undefined)
  }

  private validateGroupAnalysisProfileID(group?: ProgramGroup): boolean {
    return this.validateAnalysisProfileID(undefined, group)
  }

  private validateAnalysisProfileID(
    program?: Program,
    group?: ProgramGroup
  ): boolean {
    const analysisProfileID = program
      ? program.analysisID
      : group
        ? group.analysisProfileID
        : null
    const label = program ? 'Structure' : 'Group'
    if (
      !this._analysisProfileID ||
      !analysisProfileID ||
      this._analysisProfileID === analysisProfileID
    ) {
      return true
    }
    this.snackBar.openFromComponent(ErrorComponent, {
      data: errorPayload(`${label} has different Analysis Profile ID.`, [
        `Currently, structures with the following Analysis Profile ID`,
        `are loaded:`,
        '',
        `>   '${this._analysisProfileID}'`,
        '',
        `The ${label} you are attempting to add has Analysis Profile ID:`,
        '',
        `>   '${analysisProfileID}'`,
      ]),
    })
    return false
  }

  private validateProgramYearID(program?: Program): boolean {
    return this.validateYearID(program, undefined)
  }

  private validateGroupYearID(group?: ProgramGroup): boolean {
    return this.validateYearID(undefined, group)
  }

  private validateYearID(program?: Program, group?: ProgramGroup): boolean {
    const yearID = program ? program.yearID : group ? group.yearID : null
    const label = program ? 'Structure' : 'Group'
    if (!this._yearID || !yearID || this._yearID === yearID) {
      return true
    }
    this.snackBar.openFromComponent(ErrorComponent, {
      data: errorPayload(`${label} has different Year ID.`, [
        `Currently, structures with the following Year ID`,
        `are loaded:`,
        '',
        `>   '${this._yearID}'`,
        '',
        `The ${label} you are attempting to add has Year ID:`,
        '',
        `>   '${yearID}'`,
      ]),
    })
    return false
  }
}
