import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromActions from './technical-premium.actions'
import { LayerEntry } from '../../technical-premium/technical-premium.model'
import {
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { TechnicalPremiumService } from 'src/app/api/technical-premium/technical-premium.service'
import {
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from 'src/app/api/util'
import { AppState } from 'src/app/core/store'
import { Action, select, Store } from '@ngrx/store'
import {
  selectCededLayers,
  selectEditorPortfolioSetAndStudyIDs,
  selectInitialLayerTypeTechnicalPremiumValues,
  selectLayerTypeTechnicalPremiumValues,
} from '../analysis.selectors'
import { selectSavedCurves } from 'src/app/pricingcurve/store/pricing-curve.selectors'
import {
  doesLayerUseLayerTypeDefault,
  getSavedLayerTypeEntriesForLayerEntry,
  initDefaults,
} from '../../technical-premium/technical-premium.utils'
import { selectCurrentStudy } from 'src/app/core/store/broker/broker.selectors'
import { waitFor } from '@shared/observables'
import { fetchSavedCurvesSuccess } from 'src/app/pricingcurve/store/pricing-curve.actions'
import {
  selectCurrentStudyPrograms,
  selectPrograms,
} from 'src/app/core/store/program/program.selectors'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { convertFromLogicalPortfolioLayers } from '../../model/layers.converter'
import { LogicalPortfolioLayer } from 'src/app/api/analyzere/analyzere.model'

import { layerEntryToString } from 'src/app/analysis/technical-premium/technical-premium.utils'
import { TechnicalPremiumSyncService } from 'src/app/analysis/technical-premium/technical-premium-sync.service'
import {
  resetAnalysis,
  saveAnalysis,
  saveAsCloneAnalysisSuccess,
  startAnalysis,
} from '../analysis.actions'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import { saveFailure, saveSuccess } from '../ceded-layers/layers.actions'
import { of } from 'rxjs'

@Injectable()
export class TechnicalPremiumEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private service: TechnicalPremiumService,
    private syncService: TechnicalPremiumSyncService,
    private analyzeReService: AnalyzreService
  ) {}

  initLayerTypeValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initLayerTypeValues),
      waitFor(this.actions$, [fetchSavedCurvesSuccess]),
      map(() => fromActions.fetchLayerTypeValues())
    )
  )

  resetLayerTypeValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetLayerTypeValues),
      map(() => fromActions.fetchLayerTypeValues())
    )
  )

  fetchLayerTypeValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchLayerTypeValues),
      withLatestFrom(this.store.pipe(select(selectCurrentStudy))),
      filter(([_, study]) => study !== undefined),
      switchMap(([_, study]) =>
        // tslint:disable-next-line: no-non-null-assertion
        this.service.getLayerTypeEntries({ programId: study!.id })
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchLayerTypeValuesFailure({ error }))
      ),
      withLatestFrom(this.store.pipe(select(selectSavedCurves))),
      map(([response, curves]) =>
        fromActions.addLayerTypeValues({
          layerTypeEntries: this.mapMissingLayerTypesFromLayerTypeResponse(
            response,
            curves
          ),
        })
      )
    )
  )

  applyOnlyNewLayersToOtherProgramLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveLayerTypeValuesGoingForward),
      withLatestFrom(
        this.store.pipe(select(selectLayerTypeTechnicalPremiumValues)),
        this.store.pipe(select(selectCurrentStudyPrograms))
      ),
      filter(([_, layerTypeValues]) =>
        Object.values(layerTypeValues).some(val => val.saveForOnlyNewLayers)
      ),
      switchMap(([_, _layerTypeValues, programs]) =>
        this.analyzeReService.fetchPortfolios(
          programs.map(program => program.cededPortfolioID)
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.saveLayerTypeDefaultsFailure({ error }))
      ),
      withLatestFrom(
        this.store.pipe(select(selectInitialLayerTypeTechnicalPremiumValues)),
        this.store.pipe(select(selectLayerTypeTechnicalPremiumValues))
      ),
      switchMapWithInput(
        ([portfolios, _initialLayerTypeValues, layerTypeValues]) =>
          this.analyzeReService.fetchLayers<LogicalPortfolioLayer>(
            this.syncService.createLayerRequestFromLayerTypeEntries(
              portfolios,
              layerTypeValues,
              true
            )
          )
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromActions.saveLayerTypeValuesGoingForwardFailure({ error })
        )
      ),
      switchMap(([layers, [_portfolios, initialLayerTypeValues]]) => {
        const actions: Action[] = []
        const convertedLayers = convertFromLogicalPortfolioLayers(
          layers
        ).filter(layer => doesLayerUseLayerTypeDefault(layer))
        const updatedLayers = convertedLayers.map(layer => {
          const layerType = layer.meta_data.sage_layer_type
          const layerTypeEntry = layerType
            ? initialLayerTypeValues[layerType]
            : undefined
          if (!layerTypeEntry) {
            return layer
          } else {
            const layerEntryString = layerEntryToString(layerTypeEntry)
            return {
              ...layer,
              physicalLayer: {
                ...layer.physicalLayer,
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  pricingCurves: layerEntryString,
                  reinsurers: layerEntryString,
                  pricingcurve_is_default: false,
                },
              },
            }
          }
        })
        if (updatedLayers.length) {
          actions.push(
            fromActions.updateProgramLayers({ layers: updatedLayers })
          )
        } else {
          actions.push(fromActions.saveLayerTypeValuesGoingForwardSuccess())
        }
        return actions
      })
    )
  )

  updateProgramLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProgramLayers),
      switchMap(({ layers }) => {
        return this.analyzeReService.saveLayers(layers)
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.saveLayerTypeDefaultsFailure({ error }))
      ),
      switchMap(() => {
        // Resetting analysis will update the layers that are present on the current structure
        return [
          fromActions.saveLayerTypeValuesGoingForwardSuccess(),
          resetAnalysis({ keepPanelOpen: false }),
        ]
      })
    )
  )

  saveLayerTypeValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveLayerTypeDefaults),
      withLatestFrom(
        this.store.pipe(select(selectLayerTypeTechnicalPremiumValues)),
        this.store.pipe(select(selectCurrentStudy))
      ),
      filter(([_, _layerTypeValues, study]) => !!study),
      switchMap(([_, layerTypeValues, study]) => {
        // Get any layer type value that has a non-default curve added
        // tslint:disable-next-line: no-non-null-assertion
        const studyId = Number(study!.id)
        const layerValues = Object.values(layerTypeValues)
        const values = layerValues
          .filter(value =>
            value.pricingCurves.some(curve => curve.id != null && curve.id >= 0)
          )
          .map(value => getSavedLayerTypeEntriesForLayerEntry(value, studyId))
          .flat()
        if (values.length === 0 && layerValues.some(val => val.modified)) {
          return this.service.deleteLayerTypeEntriesByProgramId(studyId)
        } else {
          return this.service.saveLayerTypeEntries(values)
        }
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.saveLayerTypeDefaultsFailure({ error }))
      ),
      switchMap(() => [
        fromActions.saveLayerTypeDefaultsSuccess(),
        fromActions.initLayerTypeValues(),
      ])
    )
  )

  // After recalculating and saving, start analysis
  triggerStartAnalysisAfterTechnicalPremiumRecalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.recalculateTechnicalPremiumForLayersSuccess),
      // Pricing curve also will trigger this function if not filtered
      filter(({ skipStartAnalysis }) => !skipStartAnalysis),
      withLatestFrom(
        this.store.pipe(select(selectEditorPortfolioSetAndStudyIDs))
      ),
      map(([, portfolioSetAndStudyIDs]) => {
        return startAnalysis({
          ...portfolioSetAndStudyIDs!,
          fromSave: true,
          yearID: portfolioSetAndStudyIDs!.yearID!,
          structureID: portfolioSetAndStudyIDs!.structureID!,
        })
      })
    )
  )

  // LayerViewValues for a layer aren't created until after it is saved for the first time
  // If the user is setting premium/ceded commission to technical premium, resave, else start analysis
  resaveNewLayerForSetValueToTechnicalPremium$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAnalysis),
      withLatestFrom(this.store.pipe(select(selectCededLayers))),
      waitFor(this.actions$, [[saveSuccess, saveFailure]]),
      switchMap(([, layers]) =>
        of(layers).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCededLayers)),
            this.store.pipe(select(selectPrograms)),
            this.store.pipe(select(selectSavedCurves))
          )
        )
      ),
      switchMap(([beforeLayers, afterLayers, programs, savedCurves]) => {
        const layersToUpdate = this.syncService.getFreshVersionOfDirtiedLayers(
          beforeLayers,
          afterLayers
        )
        return this.syncService.getLayerDetailsAndRecalculateTechnicalPremium(
          layersToUpdate,
          programs,
          savedCurves
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          fromActions.recalculateTechnicalPremiumForLayersFailure({
            error,
          })
        )
      ),
      map(response =>
        fromActions.recalculateTechnicalPremiumForLayersSuccess({
          skipStartAnalysis: !!response.length,
        })
      )
    )
  )

  createLayerMappingEntriesForProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        saveAsCloneAnalysisSuccess,
        fromActions.addMappingsForNewStructure
      ),
      mergeMap(({ program }) => {
        return this.syncService.createLayerMappingEntriesForPrograms([program])
      }),
      rejectError(error =>
        this.store.dispatch(
          fromActions.createLayerMappingEntriesForProgramFailure({ error })
        )
      ),
      map(() => fromActions.updateMappingsOnSaveSuccess())
    )
  )

  removeLayerRefEntriesForDeletedLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAnalysis),
      withLatestFrom(this.store.pipe(select(selectCededLayers))),
      filter(([, cededLayers]) => cededLayers.some(layer => layer.deleted)),
      switchMap(([, layers]) =>
        this.syncService.removeLayerMappingEntriesOnSave(layers)
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.saveLayerTypeDefaultsFailure({ error }))
      ),
      switchMap(() => [] as Action[])
    )
  )

  updateLayerRefEntriesOnSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAnalysis, fromActions.saveLayerTypeDefaults),
      withLatestFrom(
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectLayerTypeTechnicalPremiumValues))
      ),
      filter(
        ([, cededLayers, layerTypeEntries]) =>
          cededLayers.some(layer => layer.dirty) ||
          Object.values(layerTypeEntries).some(entry => entry.modified)
      ),
      mergeMap(([, cededLayers, layerTypeEntries]) =>
        this.syncService.updateLayerMappingEntriesOnSave(
          cededLayers.filter(layer => layer.dirty).map(l => l.layer),
          layerTypeEntries
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.updateMappingsOnSaveFailure({ error }))
      ),
      map(() => fromActions.updateMappingsOnSaveSuccess())
    )
  )

  // When a layer type default is updated, update all program layers that use that default
  updateCurveMappingsForOtherProgramLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveLayerTypeDefaults),
      withLatestFrom(
        this.store.pipe(select(selectLayerTypeTechnicalPremiumValues)),
        this.store.pipe(select(selectCurrentStudyPrograms)),
        this.store.pipe(select(selectSavedCurves))
      ),
      filter(([_, layerTypeValues]) =>
        Object.values(layerTypeValues).some(val => !val.saveForOnlyNewLayers)
      ),
      mergeMap(([_, layerTypeValues, programs, savedCurves]) =>
        this.syncService.updateOtherProgramLayersUsingLayerTypeDefault(
          programs,
          layerTypeValues,
          savedCurves
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.saveLayerTypeDefaultsFailure({ error }))
      ),
      map(() => fromActions.recalculateTechnicalPremiumForLayersSuccess({}))
    )
  )

  private mapMissingLayerTypesFromLayerTypeResponse(
    response: Record<string, LayerEntry> | undefined,
    savedCurves: SavedPricingCurveEntry[]
  ): Record<string, LayerEntry> {
    let entries: Record<string, LayerEntry>
    if (response) {
      const responseCopy = { ...response }
      Object.entries(response).map(([key, value]) => {
        if (responseCopy[key].pricingCurveIds) {
          responseCopy[key] = {
            ...value,
            pricingCurves: responseCopy[key].pricingCurves.map(curveEntry => {
              const savedCurve = savedCurves.find(
                curve => curve.id === curveEntry.id
              )
              return {
                ...curveEntry,
                pc_name: savedCurve?.pc_name ?? 'Global Default',
              }
            }),
          }
        } else {
          return responseCopy[key]
        }
      })
      entries = responseCopy
    } else {
      entries = initDefaults()
    }

    return entries
  }
}
