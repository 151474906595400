import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
} from '../../analysis/model/compare-metrics.model'
import {
  MetricBuilderDialogComponentData,
  MetricBuilderDialogComponent,
} from './metric-builder-dialog.component'

@Injectable()
export class MetricBuilderDialogService {
  constructor(public dialog: MatDialog) {}

  open(
    metricCategories: CompareMetricTableCategory[],
    metricSetting?: CompareMetricSetting
  ) {
    const data: MetricBuilderDialogComponentData = {
      metricCategories,
      metricSetting,
    }
    return this.dialog.open(MetricBuilderDialogComponent, {
      width: '55vw',
      data,
    })
  }
}
