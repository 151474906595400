import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
} from '../../analysis/model/compare-metrics.model'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import { Reinsurer, ReinsurerFilter } from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { Client } from '../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-content',
  styleUrls: ['./reinsurer-content.component.scss'],
  templateUrl: './reinsurer-content.component.html',
})
export class ReinsurerContentComponent {
  @Input() clientID: string | null
  @Input() yearID: string | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() metricDetails: CompareMetricTableCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() studySelected: string | null
  @Input() reinsurers: Reinsurer[] | null
  @Input() reinsurersBlob: BlobResponse | null
  @Input() reinsurerFilters: ReinsurerFilter[]
  @Input() reinsurerDomicileList: string[]
  @Input() reinsurerSPRatingList: string[]
  @Input() reinsurerAmBestRatingList: string[]
  @Input() lossFilters: LossFilter[]

  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() clients: Client[]
  @Input() reinsurerPopulateFrom: Reinsurer[] | null

  @Output() saveClick = new EventEmitter()
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() collapseToggle = new EventEmitter<string>()
  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurers: Reinsurer[]
  }>()
  @Output() setOneReinsurer = new EventEmitter<{
    programID: string
    reinsurer: Reinsurer
  }>()
  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() reinsurerFilterToggle = new EventEmitter<ReinsurerFilter>()
  @Output() removeAllFilters = new EventEmitter()
  @Output() removeFilter = new EventEmitter<ReinsurerFilter>()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  @Output() enableAllClick = new EventEmitter()
  @Output() populateSelectorClick = new EventEmitter<{
    client: string
    program: string
  }>()
  @Output() showAgencyModal = new EventEmitter<{
    re: Reinsurer
    type: string
  }>()
  @Output() showFundManagerModal = new EventEmitter<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()
  @Output() deleteRe = new EventEmitter<Reinsurer>()

  @ViewChild('tierBar') tierBarSection: ElementRef

  get tierBarHeight(): number {
    if (this.tierBarSection) {
      return this.tierBarSection.nativeElement.offsetHeight
    } else {
      return 0
    }
  }

  get containerHeight(): { height: string } {
    const height = `calc(100vh - ${this.tierBarHeight}px - var(--header-height))`
    return { height }
  }
}
