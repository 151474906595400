import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import {
  LogicalPortfolioLayer,
  Portfolio,
} from 'src/app/api/analyzere/analyzere.model'
import { AnimatedScenariosService } from 'src/app/api/animated-scenarios/animated-scenarios.service'
import { ApiResponse } from 'src/app/api/model/api.model'
import { ProgramService } from 'src/app/api/program/program.service'
import { Program } from 'src/app/core/model/program.model'
import { errorPayload } from 'src/app/error/model/error'
import {
  isMultiSectionLayer,
  isMultiSectionMainLayer,
} from '../layers/multi-section-layer'
import { switchMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class RepairPortfoliosService {
  constructor(
    private animatedScenariosService: AnimatedScenariosService,
    private programService: ProgramService
  ) {}

  removeSectionLayersToRepairPortfolio(
    portfolio: Portfolio,
    structureId: string,
    structures: Program[],
    propertyName: 'netPortfolioID' | 'cededPortfolioID'
  ): ApiResponse<{
    portfolio: Portfolio
    structureId: string
  }> {
    const currentStructure = structures.find(s => s.id === structureId)
    if (!currentStructure) {
      return of({
        error: errorPayload(
          'Cannot repair portfolio, no structure found for provided id'
        ),
      })
    }
    const logicalPortfolioLayers = portfolio.layers as LogicalPortfolioLayer[]
    const hasInvalidMultisectionLayers =
      propertyName === 'netPortfolioID'
        ? logicalPortfolioLayers.some(
            layer =>
              isMultiSectionLayer(layer) && !isMultiSectionMainLayer(layer)
          )
        : logicalPortfolioLayers.some(layer =>
            isMultiSectionLayer(layer, 'section-layer')
          )
    if (hasInvalidMultisectionLayers) {
      const validLayers = logicalPortfolioLayers.filter(
        layer => !isMultiSectionLayer(layer) || isMultiSectionMainLayer(layer)
      )
      return this.animatedScenariosService
        .updatePortfolio(portfolio.id, portfolio.name, validLayers)
        .pipe(
          switchMap(res => {
            if (res.error || !res.data) {
              return of({ error: res.error })
            }
            return this.programService
              .update(currentStructure.id, {
                ...currentStructure,
                [propertyName]: res.data.id,
              })
              .pipe(
                switchMap(() => {
                  if (res.error || !res.data) {
                    return of({ error: res.error })
                  }
                  return of({
                    data: {
                      portfolio: res.data,
                      structureId: currentStructure.id,
                    },
                  })
                })
              )
          })
        )
    } else {
      return of({ data: { portfolio, structureId: currentStructure.id } })
    }
  }
}
