<app-technical-premium-content
  [currentProgram]="currentProgram$ | async"
  [towerLayers]="towerLayers$ | async"
  [savedCurves]="savedCurves$ | async"
  [layerMetrics]="layerMetrics$ | async"
  [layersViewIds]="layersViewIds$ | async"
  [reinsurersList]="reinsurersList$ | async"
  [lossSetLayers]="lossSetLayers$ | async"
  [layerTypeEntries]="layerTypeEntries$ | async"
  [studies]="studies$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
></app-technical-premium-content>
