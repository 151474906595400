import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core'
import { NgModel } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface RenameGroupDialogComponentData {
  groupName: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-rename-group',
  template: `
    <div class="rename-group">
      <h1 mat-dialog-title>Rename Group Name</h1>
      <div mat-dialog-content>
        <mat-form-field class="full-width">
          <input
            required
            matInput
            placeholder="Group Name"
            [(ngModel)]="groupName"
            #groupNameModel="ngModel"
            #input
            maxlength="50"
          />
          <mat-error *ngIf="groupNameModel.invalid"
            >Value is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button appButton accent (click)="renameGroupClick()">
          <span>Add</span>
        </button>
        <button appButton link mat-dialog-close>
          <span>Cancel</span>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .full-width {
        width: 100%;
      }

      .rename-group {
        width: 30vw;
        padding: 10px;
      }

      .mat-mdc-dialog-content {
        padding-top: 1.5rem;
      }

      :host
        ::ng-deep
        .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: block;
        font-size: var(--font-size-tiny);
        color: var(--warn);
      }

      :host ::ng-deep .mat-mdc-text-field-wrapper {
        border-bottom: 1px solid var(--bg-3-lit) !important;
      }
    `,
  ],
})
export class RenameGroupDialogComponent {
  groupName: string
  @ViewChild('groupNameModel')
  groupNameModel: NgModel

  constructor(
    public dialogRef: MatDialogRef<RenameGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RenameGroupDialogComponentData
  ) {
    this.groupName = data.groupName
  }

  renameGroupClick() {
    if (!this.groupNameModel.invalid) {
      this.dialogRef.close(this.groupName)
    }
  }
}
