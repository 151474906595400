<button appButton small primary link class="option-type" [matMenuTriggerFor]="optionMenu" aria-label="Options">
  <div class="option-type-label">
    <i class="material-icons">{{ currentOption.icon }}</i>
    {{ currentOption.name}}
    <div class="select-arrow-wrapper">
      <div class="select-arrow"></div>
    </div>
  </div>
</button>
<mat-menu #optionMenu="matMenu" class="app-menu-panel-no-x-scroll app-menu-panel-small">
  <button class="chart-option" *ngFor="let option of menuOptions" mat-menu-item (click)="onOptionSelect($event, option)">
    <div class="option-type-label">
      <i class="material-icons">{{ option.icon }}</i>
      {{ option.name }}
    </div>
  </button>
</mat-menu>
