import { ActionCreator, Store } from '@ngrx/store'
import { AppState } from '../store'
import createEntityStore, {
  CreateEntityStoreOptions,
  EntityStore,
  EntityStoreActionType,
  EntityStoreReducer,
  EntityStoreSelectors,
  EntityStoreState,
} from './create-entity-store'

//#region Types

type CreateEntityStore<T> = (
  defaultName: string,
  opts: CreateEntityStoreOptions
) => EntityStore<T>

export type CreateEntityStoreSetOptions<T> = {
  [P in keyof T]: CreateEntityStore<T[P]>
}

type EntityStoreSetRecords<T> = {
  [P in keyof T]: EntityStore<T[P]>
}

type EntityStoreSetState<T> = {
  [P in keyof T]: EntityStoreState<T[P]>
}

type EntityStoreSetRecordSelectors<T> = {
  [P in keyof T]: EntityStoreSelectors<T[P]>
}

type EntityStoreSetSelectors<T> = EntityStoreSetRecordSelectors<T> & {
  selectAnyLoading: (state: AppState) => boolean
}

export type EntityStoreSet<T> = EntityStoreSetRecords<T> & {
  fetchAll: (store: Store<AppState>) => void
  initialStates: EntityStoreSetState<T>
  reducers: { [P in keyof T]: EntityStoreReducer<T[P]> }
  getActionTypeTuples: (
    ...types: EntityStoreActionType<T>[]
  ) => ActionCreator[][]
  getActionTypes: (...types: EntityStoreActionType<T>[]) => ActionCreator[]
  getSelectors: (
    getState: (state: AppState) => EntityStoreSetState<T>
  ) => EntityStoreSetSelectors<T>
}

//#endregion

function buildDefaultName(key: string) {
  return key.charAt(0).toUpperCase() + key.slice(1)
}

const makeGetSelectors = <T, K extends keyof T>(
  keys: K[],
  entityStoresByKey: EntityStoreSetRecords<T>
) => (
  getState: (state: AppState) => EntityStoreSetState<T>
): EntityStoreSetSelectors<T> => {
  const makeEntityStoreStateSelector = (key: K) => (state: AppState) =>
    getState(state)[key]

  const selectors = keys.reduce((acc, k) => {
    const selectEntityStoreState = makeEntityStoreStateSelector(k)
    acc[k] = entityStoresByKey[k].getSelectors(selectEntityStoreState)
    return acc
  }, {} as EntityStoreSetRecordSelectors<T>)

  const selectAnyLoading = (state: AppState) =>
    keys.some(k => selectors[k].selectLoading(state))

  return {
    ...selectors,
    selectAnyLoading,
  }
}

const makeEntityStoreCreator = <T>(name?: string): CreateEntityStore<T> => (
  defaultName,
  opts
) => createEntityStore<T>(name ?? defaultName, opts)

const createEntityStoreSet = <T, K extends keyof T>(
  moduleName: string,
  defs: CreateEntityStoreSetOptions<T>
): EntityStoreSet<T> => {
  const keys = Object.keys(defs) as K[]
  const [entityStoresByKey, entityStores] = keys.reduce(
    (acc, k) => {
      const defaultName = buildDefaultName(String(k))
      const store = defs[k](defaultName, { moduleName })
      acc[0][k] = store
      acc[1].push(store)
      return acc
    },
    [{} as EntityStoreSetRecords<T>, [] as EntityStore<T[K]>[]] as const
  )

  const getActionTypeTuples = (...types: EntityStoreActionType<T>[]) =>
    entityStores.map(s => types.map(t => s.actions[t]))

  const getSelectors = makeGetSelectors(keys, entityStoresByKey)

  const set: EntityStoreSet<T> = {
    ...entityStoresByKey,

    fetchAll(store) {
      entityStores.forEach(s => store.dispatch(s.actions.fetch()))
    },

    initialStates: keys.reduce((acc, k) => {
      acc[k] = entityStoresByKey[k].initialState
      return acc
    }, {} as { [P in keyof T]: EntityStoreState<T[P]> }),

    reducers: keys.reduce((acc, k) => {
      acc[k] = entityStoresByKey[k].reducer
      return acc
    }, {} as { [P in keyof T]: EntityStoreReducer<T[P]> }),

    getActionTypeTuples,

    getActionTypes: types => getActionTypeTuples(types).flat(),

    getSelectors,
  }
  return set
}

export const as = makeEntityStoreCreator
export default createEntityStoreSet
