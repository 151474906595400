import { createAction, props } from '@ngrx/store'
import { LayerViewResponse } from 'src/app/api/analyzere/analyzere.model'
import { Program } from 'src/app/core/model/program.model'
import { ApplicationError } from 'src/app/error/model/error'
import { LayerMetrics } from '../../model/layers-metrics.model'
import { Layer } from '../../model/layers.model'
import {
  LayerEntry,
  LayerTypeDefaultEntry,
} from '../../technical-premium/technical-premium.model'

export const resetLayerTypeValues = createAction(
  '[Technical Premium] Reset Layer Type Values'
)

export const fetchLayerTypeValues = createAction(
  '[Technical Premium] Fetch Layer Type Values'
)

export const fetchLayerTypeValuesFailure = createAction(
  '[Technical Premium] Fetch Layer Type Values Failure',
  props<{ error: ApplicationError }>()
)

export const initLayerTypeValues = createAction(
  '[Technical Premium] Init Layer Type Values'
)

export const addLayerTypeValues = createAction(
  '[Technical Premium] Add Layer Type Values',
  props<{ layerTypeEntries: LayerTypeDefaultEntry }>()
)

export const initDefaultEntries = createAction(
  '[Technical Premium] Init Default Entries'
)

export const updateLayerTypeValue = createAction(
  '[Technical Premium] Update Layer Type Value',
  props<{ layerType: string; newValue: LayerEntry }>()
)

export const saveLayerTypeDefaults = createAction(
  '[Technical Premium] Save Layer Type Defaults'
)

export const saveLayerTypeDefaultsSuccess = createAction(
  '[Technical Premium] Save Layer Type Defaults Success'
)

export const saveLayerTypeDefaultsFailure = createAction(
  '[Technical Premium] Save Layer Type Defaults Failure',
  props<{ error: ApplicationError }>()
)

export const createLayerMappingEntriesForProgramFailure = createAction(
  '[Technical Premium] Create Layer Mapping Entries Program Failure',
  props<{ error: ApplicationError }>()
)

export const updateProgramLayers = createAction(
  '[Technical Premium] Update Program Layers',
  props<{ layers: Layer[] }>()
)

export const saveLayerTypeValuesGoingForward = createAction(
  '[Technical Premium] Save Layer Type Values Going Forward'
)

export const saveLayerTypeValuesGoingForwardSuccess = createAction(
  '[Technical Premium] Save Layer Type Values Going Forward Success'
)

export const saveLayerTypeValuesGoingForwardFailure = createAction(
  '[Technical Premium] Save Layer Type Values Going Forward Failure',
  props<{ error: ApplicationError }>()
)

export const initLayerEntries = createAction(
  '[Technical Premium] Init Layer Entries',
  props<{ entries: LayerEntry[] }>()
)

export const updateLayerEntry = createAction(
  '[Technical Premium] Update Layer Entries',
  props<{ entry: LayerEntry }>()
)

export const updateMappingsOnSaveFailure = createAction(
  '[Technical Premium] Update Mappings on Save Failure',
  props<{ error: ApplicationError }>()
)

export const updateMappingsOnSaveSuccess = createAction(
  '[Technical Premium] Update Mappings on Save Success'
)

export const getLayerDetailsAndRecalculateTechnicalPremium = createAction(
  '[Technical Premium] Get Layer Details and Recalculate Technical Premium',
  props<{ layers: Layer[]; skipStartAnalysis?: boolean }>()
)

export const getLayerDetailsAndRecalculateTechnicalPremiumFailure =
  createAction(
    '[Technical Premium] Get Layer Details and Recalculate Technical Premium Failure',
    props<{ error: ApplicationError }>()
  )

export const recalculateTechnicalPremiumForLayers = createAction(
  '[Technical Premium] Recalculate Technical Premium',
  props<{
    layers: Layer[]
    metrics: LayerMetrics[]
    layersViewsResponse: (LayerViewResponse & {
      layerID: string
    })[][]
    layerTypeMappings: Record<string, Record<string, LayerEntry>>
    programs: Program[]
    skipStartAnalysis?: boolean
  }>()
)

export const recalculateTechnicalPremiumForLayersSuccess = createAction(
  '[Technical Premium] Recalculate Technical Premium Success',
  props<{ skipStartAnalysis?: boolean }>()
)

export const recalculateTechnicalPremiumForLayersFailure = createAction(
  '[Technical Premium] Recalculate Technical Premium Failure',
  props<{ error: ApplicationError }>()
)

export const addMappingsForNewStructure = createAction(
  '[Technical Premium] Add Mappings For New Structure',
  props<{ program: Program }>()
)
