export interface ApplicationError {
  message: string
  details: string[]
  type: PayloadDiscriminant.ERROR
  text: string
  /**
   * Length of time in milliseconds the message should remain on screen
   * until automatically dismissed.
   */
  duration?: number
  /** Style the message as an info message instead of an error. */
  info?: boolean
}

export enum PayloadDiscriminant {
  ERROR,
}

type ErrorOptions = Partial<Omit<ApplicationError, 'message' | 'type'>>

export function errorPayload(
  message: string,
  options?: ErrorOptions
): ApplicationError
export function errorPayload(
  message: string,
  details?: string | string[],
  options?: ErrorOptions
): ApplicationError
export function errorPayload(
  message: string,
  detailsOrOptions?: string | string[] | ErrorOptions,
  rawOptions?: ErrorOptions
): ApplicationError {
  let detailsProp: string[] = []
  let options: ErrorOptions | undefined = rawOptions
  if (typeof detailsOrOptions === 'string') {
    detailsProp = [detailsOrOptions]
  } else if (Array.isArray(detailsOrOptions)) {
    detailsProp = detailsOrOptions
  } else {
    options = detailsOrOptions
  }

  const { details, text = '', duration, info } = options ?? {}
  return {
    message,
    details: detailsProp ?? details ?? [],
    type: PayloadDiscriminant.ERROR,
    text,
    duration,
    info,
  }
}
