import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResponse } from '../model/api.model'
import { environment } from '../../../environments/environment'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { TrackingData, TrackingDetailsData, TrackingDetailsResponseData, TrackingResponseData } from 'src/app/tracking/tracking.model'
import { map } from 'rxjs'
import { convertTrackingDetailsFromResponse, convertTrackingsFromResponse } from './tracking.convertor'

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private httpClient: HttpClient) {}

  getTracking(): ApiResponse<TrackingData[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.placementTracking}`
    return this.httpClient
      .get<TrackingResponseData[]>(url)
      .pipe(
        map(convertTrackingsFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Fetch Tracking Data')
      )
  }

  getTrackingDetails(programId: number): ApiResponse<TrackingDetailsData[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.placementTracking}/details/${programId}`
    return this.httpClient
      .get<TrackingDetailsResponseData[]>(url)
      .pipe(
        map(convertTrackingDetailsFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Fetch Tracking Details Data')
      )
  }
}
