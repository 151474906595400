import { uniq } from 'ramda'
import { SortTableColumnDef } from '../sort-table/sort-table.model'

export interface ColumnsSettings {
  visibleColumns: SortTableColumnDef[]
  hiddenColumns: SortTableColumnDef[]
  defaultColumns: SortTableColumnDef[]
}

export const mapCategoriesToOneItem = (
  columns: SortTableColumnDef[]
): SortTableColumnDef[] => {
  const filtered: Set<SortTableColumnDef> = new Set(
    columns.map(col =>
      col.category ? { ...col, label: col.category, id: col.category } : col
    )
  )
  return uniq(Array.from(filtered))
}

export const mapBackAllCategories = (
  defaultColumns: SortTableColumnDef[],
  columns: SortTableColumnDef[]
): SortTableColumnDef[] => {
  const mapped: SortTableColumnDef[] = []
  for (const col of columns) {
    if (col.category) {
      const columnsInCategory = defaultColumns.filter(
        def => def.category === col.category
      )
      mapped.push(...columnsInCategory)
    } else {
      mapped.push(col)
    }
  }
  return mapped
}
