import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { ApplicationError } from '../../../../error/model/error'
import * as Actions from './compare-metric-settings.actions'
import { CompareMetricSetting } from '../../../model/compare-metrics.model'
import { validateFormula } from '../../../../metrics/metrics.util'

interface ExtendedState {
  studyID: string | null
  loading: boolean
  error: ApplicationError | null
}

export type State = EntityState<CompareMetricSetting> & ExtendedState

const addIDPart = <T>(obj: T, prop: keyof T, suffix = false): string => {
  const _prefix = !suffix ? '_' : ''
  const _suffix = suffix ? '_' : ''
  return obj[prop] ? _prefix + `${obj[prop]}` + _suffix : ''
}

const buildID = (setting: CompareMetricSetting): string =>
  addIDPart(setting, 'category', true) +
  setting.label +
  addIDPart(setting, 'portfolioType') +
  addIDPart(setting, 'aggregationMethodType') +
  addIDPart(setting, 'path') +
  addIDPart(setting, 'returnPeriodNumber')

export const adapter = createEntityAdapter<CompareMetricSetting>({
  selectId: setting => buildID(setting),
  sortComparer: (a, b) => a.ordinal - b.ordinal,
})

export const initialState: State = adapter.getInitialState<ExtendedState>({
  studyID: null,
  loading: false,
  error: null,
})

export const replaceUpdatedMetricSettingsReducer = (
  state: State,
  settings: CompareMetricSetting[]
) => {
  return adapter.updateMany(
    settings.map(m => ({
      id: buildID(m),
      changes: { show: m.show, weight: m.weight, label: m.label },
    })),
    state
  )
}

const compareMetricSettingsReducer = createReducer(
  initialState,

  on(Actions.fetchCompareMetricSettings, (state, { studyID }) => ({
    ...state,
    studyID,
    loading: true,
    error: initialState.error,
  })),

  on(Actions.fetchCompareMetricSettingsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(Actions.fetchCompareMetricSettingsSuccess, (state, { settings }) => {
    const updatedSettings = validateFormula(settings, true)
    return adapter.setAll(updatedSettings, {
      ...state,
      loading: false,
      error: initialState.error,
    })
  }),

  on(Actions.updateCompareMetricSettings, (state, { settings }) => {
    return replaceUpdatedMetricSettingsReducer(state, settings)
  })
)

export function reducer(state: State | undefined, action: Action) {
  return compareMetricSettingsReducer(state, action)
}
