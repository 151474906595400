import { Injectable } from '@angular/core'
import * as pbi from 'powerbi-client'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { PBITokenResponse } from '../../api/model/pbi.model'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {
  reportCode: string = null
  accessTokenData = new BehaviorSubject<PBITokenResponse>(null)
  private tokenRefreshInterval = 55 * 60 * 1000 // 55 minutes in milliseconds

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) {
  }

  initiate(reportCode: string){
    this.reportCode = reportCode
    // Initial fetch of the access token
    this.fetchAccessToken().subscribe(tokenResponse => {
        this.accessTokenData.next(tokenResponse)
    
        // Set up periodic refresh
        interval(this.tokenRefreshInterval)
            .pipe(switchMap(() => this.fetchAccessToken()))
            .subscribe(newTokenResponse => this.accessTokenData.next(newTokenResponse))
        })
  }

  private fetchAccessToken(): Observable<PBITokenResponse> {    
    // Replace with your API endpoint to get the access token
    const url = `${environment.internalApi.base}${environment.internalApi.pbiToken}?reportCode=${this.reportCode}`
    return this.httpClient.get<PBITokenResponse>(url)
  }

  embedReport(elementId: string, overlayId: string, report: pbi.Embed): void {
    this.accessTokenData.subscribe(tokenResponse => {
      const embedConfig: pbi.IEmbedConfiguration = {
        type: 'report',
        id: tokenResponse.reportId,
        embedUrl: `${tokenResponse.embedUrl}&w=2&language=en&formatlocale=es`,
        accessToken: tokenResponse.tokenData.token,
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.All,
        viewMode: pbi.models.ViewMode.View,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          localeSettings: {
            language: 'en',
            formatLocale: 'es',
          },
        },
      }

      const reportContainer = document.getElementById(elementId) as HTMLElement
      if (reportContainer) {
        // Hide the embed container initially
        reportContainer.style.visibility = 'hidden'

        let powerbiService = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        )

        report = powerbiService.embed(reportContainer, embedConfig) as pbi.Embed

        report.on('rendered', () => {
          const overlay = document.getElementById(overlayId) as HTMLElement
          if (overlay) {
            overlay.style.display = 'none'
          }
          reportContainer.style.visibility = 'visible'
          // Remove the event handler to avoid multiple triggers
          report?.off('rendered')
        })

        report.on('error', (event: Event) => {
          const overlay = document.getElementById(overlayId) as HTMLElement
          if (overlay) {
            overlay.style.display = 'none'
          }
          this.snackBar.open('Error loading the report data', 'X', {
            panelClass: 'app-error',
            duration: 10000,
          })
          reportContainer.style.visibility = 'hidden'
        })
      } else {
        this.snackBar.open('Error loading the report data', 'X', {
          panelClass: 'app-error',
          duration: 10000,
        })
      }
    })
  }
}
