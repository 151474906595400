import { Action, combineReducers } from '@ngrx/store'
import * as fromOptimizationCandidateResults from './optimization-candidates-results.reducer'
import * as fromOptimizationLayers from './optimization-layers.reducer'
import * as fromOptimizationRangeType from './optimization-ranges-types.reducer'

export const OPTIMIZATION_FEATURE_KEY = 'optimization'

export interface State {
  candidateResults: fromOptimizationCandidateResults.State
  candidateLayers: fromOptimizationLayers.State
  rangesTypes: fromOptimizationRangeType.State
}

const optimizationReducer = combineReducers<State>({
  candidateResults: fromOptimizationCandidateResults.reducer,
  candidateLayers: fromOptimizationLayers.reducer,
  rangesTypes: fromOptimizationRangeType.reducer,
})

export function reducer(state: State | undefined, action: Action) {
  return optimizationReducer(state, action)
}
