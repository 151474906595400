import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { PortfoliosID } from '../../analysis/model/portfolio-set.model'
import { Program } from '../../core/model/program.model'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { CheckboxSelectComponent } from '@shared/checkbox-select.component'
import { CanSize, CanSizeCtor, mixinSize, Size } from '@shared/size.mixin'
import { Store } from '@ngrx/store'
import { AppState } from '../../core/store'
import {
  updateCheckedPrograms,
  removedCheckedPrograms,
} from '../../core/store/broker/broker.actions'

/*
 * Get a color mixin that TierBarComponent can extend to handle color inputs
 */
class StructureCardBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof StructureCardBase =
  mixinSize(StructureCardBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-structure-card',
  styleUrls: ['./structure-card.component.scss'],
  templateUrl: './structure-card.component.html',
})
export class StructureCardComponent extends _SizeMixinBase implements CanSize {
  @Input() index: number
  @Input() structure: Program
  @Input() scenarios: Program[]
  @Input() scenariosSelected: Record<string, boolean>
  @Input() optimizations: Program[]
  @Input() optimizationsSelected: Record<string, boolean>
  @Input() isExplore = false
  @Input() imageURI: string
  @Input() selectedStructureIDs: string[]
  @Input() editFolderMode = false
  @Input() structureSelected: boolean
  @Input() canCheck: boolean
  @Input() checkboxTooltip: string
  // Allow Scenario or Optimization Selection - Shows the scenarios/optimization select all checkbox
  @Input() set allowScenarioOrOptimizationSelection(value: any) {
    this._allowScenarioOrOptimizationSelection = coerceBooleanProperty(value)
  }
  get allowScenarioOrOptimizationSelection() {
    return this._allowScenarioOrOptimizationSelection
  }
  _allowScenarioOrOptimizationSelection = false

  // Selected
  @Input() set checked(value: any) {
    this._checked = coerceBooleanProperty(value)
  }
  get checked() {
    return this._checked
  }
  _checked = false

  // Multiple selection
  @Input() set selectMultiple(value: any) {
    this._selectMultiple = coerceBooleanProperty(value)
  }
  get selectMultiple() {
    return this._selectMultiple
  }
  _selectMultiple = false

  // Indicate Focus
  @Input() set indicateFocus(value: any) {
    this._indicateFocus = coerceBooleanProperty(value)
  }
  get indicateFocus() {
    return this._indicateFocus
  }
  _indicateFocus = false

  // Elevation
  @Input() set elevation(value: any) {
    this._elevation = coerceNumberProperty(value)
  }
  get elevation() {
    return this._elevation
  }
  _elevation = 0

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() structureClick = new EventEmitter<void>()
  @Output() editClick = new EventEmitter<void>()
  @Output() scenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<Program>
  >()
  @Output() structureCheckChange = new EventEmitter<{
    structureId: string
  }>()

  @Output() addStructure = new EventEmitter<string>()
  @Output() removeFromFolderClick = new EventEmitter<void>()

  @ViewChild(CheckboxSelectComponent)
  checkboxSelect?: CheckboxSelectComponent<Program>

  @ViewChild('moreMenuButton', { read: ElementRef })
  moreMenuButton?: ElementRef<HTMLButtonElement>

  @HostListener('document:keypress', ['$event']) onKeyup(
    $event: KeyboardEvent
  ) {
    if (
      // Ignore unless this is the item w/ indicated focus
      !this.indicateFocus ||
      // Ignore keystrokes in inputs unless <ctrl> key is held
      (($event.target as any)?.type === 'text' && !$event.ctrlKey)
    ) {
      return
    }
    switch ($event.key.toUpperCase()) {
      case 'M':
        this.moreMenuButton?.nativeElement?.click()
        break
      case 'S':
        this.checkboxSelect?.onButtonClick()
    }
  }

  @HostBinding('class.show-more-menu')
  get showMoreMenu(): boolean {
    return (!this.selectMultiple && !this.isExplore) || this.editFolderMode
  }

  get portfolios(): PortfoliosID {
    return {
      cededPortfolioID: this.structure.cededPortfolioID,
      grossPortfolioID: this.structure.grossPortfolioID,
      netPortfolioID: this.structure.netPortfolioID,
    }
  }

  get isLibRE(): boolean {
    return this.structure.libRE === 'Y' || this.structure.libRE === 'T'
  }

  get isLibRETemplate(): boolean {
    return this.structure.libRE === 'T'
  }

  constructor(public elementRef: ElementRef, public store: Store<AppState>) {
    super(elementRef)
  }

  onStructureCheckChange(): void {
    const structureId = this.structure.id
    this.structureCheckChange.emit({ structureId })
  }

  onStructureClick($event: MouseEvent | TouchEvent): void {
    if (!this.isExplore) {
      $event.preventDefault()
      $event.stopPropagation()
      this.structureClick.emit()
      if (
        this.selectedStructureIDs &&
        this.selectedStructureIDs.includes(this.structure.id)
      ) {
        this.store.dispatch(
          removedCheckedPrograms({ programs: [this.structure] })
        )
      } else {
        this.store.dispatch(
          updateCheckedPrograms({ checkedItemIds: [this.structure] })
        )
      }
    }
  }

  setRouter(page: string): void {
    this.addStructure.emit(page)
  }
}
