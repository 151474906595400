import { createAction, props } from '@ngrx/store'
import { ContactSupport } from '../model/contact-support.model'
import { ApplicationError } from 'src/app/error/model/error'
import { SalesforceUser } from 'src/app/api/model/backend.model'

export const sendSupportEmail = createAction(
  '[Contact Support] Send Support Email',
  props<{ contactSupport: ContactSupport }>()
)

export const sendSupportEmailSuccess = createAction(
  '[Contact Support] Send Support Email Success',
  props<{ response: string }>()
)

export const sendSupportEmailFailure = createAction(
  '[Contact Support] Send Support Email Failure',
  props<{ error: ApplicationError }>()
)

export const fetchAllUsers = createAction('[Contact Support] Fetch All Users')

export const fetchAllUsersSuccess = createAction(
  '[Contact Support] Fetch All Users Success',
  props<{ allUSers: SalesforceUser[] }>()
)

export const fetchAllUsersFailure = createAction(
  '[Contact Support] Fetch All Users Failure',
  props<{ error: ApplicationError }>()
)
