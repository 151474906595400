import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import {
  MultiTotalExpectedForReinsurerVersion,
  QuoteReinsurer,
  TotalExpectedForReinsurerVersion,
} from '../models/quote.model'
import { QuotePanelDefResolved } from '../quote-panel/quote-panel-defs'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import { QuoteExportRowValue, QuoteVersionListEntry } from './quote-excel.model'
import { clone } from 'ramda'
import { QuoteExcelUtils } from './quote-excel.utils'
import { LayerView } from 'src/app/analysis/model/layer-view'

export default class QuoteExportRowsConverter {
  constructor(
    private isGroupSelected: boolean,
    private isSLSelected: boolean,
    private utils: QuoteExcelUtils,
    private view: LayerView | undefined,
    private xolExport: boolean
  ) {}

  public getNonFotOrExpiringColumnsAndRowsForSection(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiExpectedVals: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals: TotalExpectedForReinsurerVersion[],
    overallNonExpiringOrFotReinsurerVersionList: QuoteVersionListEntry[],
    sectionReinsurers: ReinsurerState[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    if (this.isGroupSelected || this.isSLSelected || this.xolExport) {
      // Use old method of generating values for group/SL
      return this.getNonFotOrExpiringColumnsAndRowsForGroupOrSLSection(
        s,
        sectionRows,
        nonFotAndExpiringReinsurers,
        multiExpectedVals,
        groupExpectedVals,
        sharedExpectedVals
      )
    } else {
      return this.getOrderedNonFotOrExpiringRowsForLayers(
        s,
        sectionRows,
        multiExpectedVals,
        groupExpectedVals,
        sharedExpectedVals,
        overallNonExpiringOrFotReinsurerVersionList,
        sectionReinsurers
      )
    }
  }

  private getOrderedNonFotOrExpiringRowsForLayers(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    multiExpectedVals: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals: TotalExpectedForReinsurerVersion[],
    overallNonExpiringOrFotReinsurerVersionList: QuoteVersionListEntry[],
    sectionReinsurers: ReinsurerState[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    let weightedRol = 0
    let weightedRateOnLineSubject = 0
    let offeredPercentageTotal = 0

    overallNonExpiringOrFotReinsurerVersionList.forEach(
      ({ tpRef, reinsurerPhaseVersion }) => {
        const validateVersion = (
          tpRef: string,
          reinsurerPhaseVersion: number
        ): boolean =>
          sectionReinsurers.some(
            ({ reinsurer }) =>
              reinsurer.tpRef === tpRef &&
              Number(reinsurer.reinsurerPhaseVersion) === reinsurerPhaseVersion
          )

        const reRow: QuoteExportRowValue[][] = []
        let totalQuoteExpectedCededLoss = 0
        let totalQuoteExpectedCededPremium = 0
        if (validateVersion(tpRef, reinsurerPhaseVersion)) {
          const r = sectionReinsurers.find(
            ({ reinsurer }) =>
              reinsurer.tpRef === tpRef &&
              Number(reinsurer.reinsurerPhaseVersion) === reinsurerPhaseVersion
          )?.reinsurer

          const totalExpectedMultiVals = multiExpectedVals.find(
            expected =>
              expected.reinsurerName === r.quoteReinsurerName &&
              expected.reinsurerVersion === r.reinsurerPhaseVersion
          )
          const totalExpectedGroupVals = groupExpectedVals.find(
            expected =>
              expected.reinsurerName === r.quoteReinsurerName &&
              expected.reinsurerVersion === r.reinsurerPhaseVersion
          )
          const totalExpectedSharedVals = sharedExpectedVals.find(
            expected =>
              expected.reinsurerName === r.quoteReinsurerName &&
              expected.reinsurerVersion === r.reinsurerPhaseVersion
          )

          const num = sectionReinsurers.filter(
            ({ reinsurer }) => reinsurer.tpRef === tpRef
          ).length
          reinsurerColumns.push(this.utils.labelReinsurer(r, 'quote', num))

          sectionReinsurers.forEach(reForTotal => {
            if (s.section.layerType === layerIds.catMultisection) {
              return
            } else if (
              reForTotal.reinsurer.quoteFields &&
              reForTotal.reinsurer.quoteFields?.quoteExpectedCededLoss &&
              reForTotal.reinsurer.quoteFields.quoteExpectedCededPremium
            ) {
              totalQuoteExpectedCededLoss +=
                reForTotal.reinsurer.quoteFields.quoteExpectedCededLoss.value
              totalQuoteExpectedCededPremium +=
                reForTotal.reinsurer.quoteFields.quoteExpectedCededPremium.value
            }
          })
          let checkSlidingScale = false
          const offeredPctg = r.quoteFields.quoteOfferedPercentage ?? 0
          const rol = r.quoteFields.quoteRolPercentage ?? 0
          const rateOnLineSubject = r.quoteFields.quoteRateOnLineSubject ?? 0

          weightedRol += rol * offeredPctg
          weightedRateOnLineSubject += rateOnLineSubject * offeredPctg
          offeredPercentageTotal += offeredPctg

          sectionRows.forEach((sec, ind) => {
            const layerSectionObj = clone(s)

            for (const [key, value] of Object.entries(r.quoteFields)) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                let layerValue = JSON.parse(JSON.stringify(value))

                if (sec.id === 'layerClass') {
                  const subclass = r.quoteFields.layerSubClass
                  if (subclass) {
                    layerValue += `/${subclass}`
                  }
                }

                this.view?.layers?.map(layer => {
                  if (
                    r.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.cededlayerID === layer.layer.id) {
                    layerSectionObj.section.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.utils.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.section.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }

            if (sec.id === 'slidingComm' && r.slidingScale) {
              checkSlidingScale = true
              reRow.push(['', 'text'])
              reRow.push(this.generateSlidingScaleVal(r, 0, false))
              reRow.push(this.generateSlidingScaleVal(r, 0, true))

              reRow.push(this.generateSlidingScaleVal(r, 1, false))
              reRow.push(this.generateSlidingScaleVal(r, 2, true))

              reRow.push(this.generateSlidingScaleVal(r, 2, false))
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
            }
          })
        } else {
          reinsurerColumns.push('')
          sectionRows.forEach(() => reRow.push(['', 'text']))
        }
        reinsurerRows.push(reRow)
      }
    )

    if (!this.xolExport) {
      const denom = offeredPercentageTotal !== 0 ? offeredPercentageTotal : 1
      const weightedAvgRateOnLine = weightedRol / denom
      const weightedAvgRatePercentageOfSubject =
        weightedRateOnLineSubject / denom
      const summariesValuesRow: any[] = []
      const summariesHeadersRow: any[] = []
      const fields: Record<string, { value: number; label: string }> = {
        quoteOfferedPercentage: {
          value: offeredPercentageTotal,
          label: 'Total Offered %:',
        },
        quoteRolPercentage: {
          value: weightedAvgRateOnLine,
          label: 'Weighted Avg. Rate On-Line, Occ:',
        },
        quoteRateOnLineSubject: {
          value: weightedAvgRatePercentageOfSubject,
          label: 'Weighted Avg. Rate, % of Subject:',
        },
      }
      const keys = Object.keys(fields)
      sectionRows.forEach(section => {
        const included = keys.includes(section.id)
        summariesHeadersRow.push([
          included ? fields[section.id].label : '',
          'text',
        ])
        summariesValuesRow.push([
          included ? fields[section.id].value : '',
          'percentage',
        ])
      })

      reinsurerRows.push(summariesHeadersRow)
      reinsurerRows.push(summariesValuesRow)
      reinsurerColumns.push('', '')
    }
    return [reinsurerRows, reinsurerColumns] as const
  }

  private getNonFotOrExpiringColumnsAndRowsForGroupOrSLSection(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiExpectedVals: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals: TotalExpectedForReinsurerVersion[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    let weightedRol = 0
    let weightedRateOnLineSubject = 0
    let offeredPercentageTotal = 0
    nonFotAndExpiringReinsurers.forEach(r => {
      /* For total expected ceded loss / premium */
      let totalQuoteExpectedCededLoss = 0
      let totalQuoteExpectedCededPremium = 0
      const totalExpectedMultiVals = multiExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedGroupVals = groupExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedSharedVals = sharedExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const reRow: QuoteExportRowValue[][] = []
      if (this.utils.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        let num = 0
        nonFotAndExpiringReinsurers.forEach(q => {
          if (
            this.utils.validateReinsurer(q.reinsurer, s.section.layerRef) &&
            q.reinsurer.quoteReinsurerName === r.reinsurer.quoteReinsurerName
          ) {
            num += 1
          }
        })
        reinsurerColumns.push(
          this.utils.labelReinsurer(r.reinsurer, 'quote', num)
        )
        if (
          sectionRows.length > 0 &&
          r.reinsurer.quoteFields &&
          !r.reinsurer.decline &&
          this.utils.validateReinsurer(r.reinsurer, s.section.layerRef)
        ) {
          nonFotAndExpiringReinsurers.forEach(x => {
            if (
              !this.utils.validateReinsurer(x.reinsurer, s.section.layerRef)
            ) {
              return
            }
            if (
              r.reinsurer.quoteReinsurerName ===
                x.reinsurer.quoteReinsurerName &&
              r.reinsurer.reinsurerPhaseLabel ===
                x.reinsurer.reinsurerPhaseLabel
            ) {
              if (
                s.section.layerType === layerIds.catMultisection ||
                this.isSLSelected
              ) {
                return
              } else {
                if (
                  r.reinsurer.quoteFields &&
                  x.reinsurer.quoteFields?.quoteExpectedCededLoss &&
                  x.reinsurer.quoteFields.quoteExpectedCededPremium
                ) {
                  totalQuoteExpectedCededLoss +=
                    x.reinsurer.quoteFields.quoteExpectedCededLoss.value
                  totalQuoteExpectedCededPremium +=
                    x.reinsurer.quoteFields.quoteExpectedCededPremium.value
                }
              }
            }
          })
          let checkSlidingScale = false

          const offeredPctg =
            r.reinsurer.quoteFields.quoteOfferedPercentage ?? 0
          const rol = r.reinsurer.quoteFields.quoteRolPercentage ?? 0
          const rateOnLineSubject =
            r.reinsurer.quoteFields.quoteRateOnLineSubject ?? 0

          weightedRol += rol * offeredPctg
          weightedRateOnLineSubject += rateOnLineSubject * offeredPctg
          offeredPercentageTotal += offeredPctg
          sectionRows.forEach((sec, ind) => {
            const layerSectionObj = clone(s)

            for (const [key, value] of Object.entries(
              r.reinsurer.quoteFields
            )) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                const layerValue = JSON.parse(JSON.stringify(value))

                this.view?.layers?.map(layer => {
                  if (
                    r.reinsurer.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.reinsurer.cededlayerID === layer.layer.id) {
                    layerSectionObj.section.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.utils.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.section.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }
            if (sec.id === 'slidingComm' && r.reinsurer.slidingScale) {
              checkSlidingScale = true
              const re = r.reinsurer
              reRow.push(['', 'text'])
              reRow.push(this.generateSlidingScaleVal(re, 0, false))
              reRow.push(this.generateSlidingScaleVal(re, 0, true))

              reRow.push(this.generateSlidingScaleVal(re, 1, false))
              reRow.push(this.generateSlidingScaleVal(re, 2, true))

              reRow.push(this.generateSlidingScaleVal(re, 2, false))
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
            }
          })
        }
        reinsurerRows.push(reRow)
      }
    })

    // Adds the summary rows to the left of the quote panels, not needed for XOL
    if (!this.xolExport) {
      const denom = offeredPercentageTotal !== 0 ? offeredPercentageTotal : 1
      const weightedAvgRateOnLine = weightedRol / denom
      const weightedAvgRatePercentageOfSubject =
        weightedRateOnLineSubject / denom
      const summariesValuesRow: any[] = []
      const summariesHeadersRow: any[] = []
      const fields: Record<string, { value: number; label: string }> = {
        quoteOfferedPercentage: {
          value: offeredPercentageTotal,
          label: 'Total Offered %:',
        },
        quoteRolPercentage: {
          value: weightedAvgRateOnLine,
          label: 'Weighted Avg. Rate On-Line, Occ:',
        },
        quoteRateOnLineSubject: {
          value: weightedAvgRatePercentageOfSubject,
          label: 'Weighted Avg. Rate, % of Subject:',
        },
      }
      const keys = Object.keys(fields)
      sectionRows.forEach(section => {
        const included = keys.includes(section.id)
        summariesHeadersRow.push([
          included ? fields[section.id].label : '',
          'text',
        ])
        summariesValuesRow.push([
          included ? fields[section.id].value : '',
          'percentage',
        ])
      })

      reinsurerRows.push(summariesHeadersRow)
      reinsurerRows.push(summariesValuesRow)
      reinsurerColumns.push('', '')
    }

    return [reinsurerRows, reinsurerColumns] as const
  }

  private generateSlidingScaleVal(
    r: QuoteReinsurer,
    index: number,
    isSlideRate: boolean
  ): QuoteExportRowValue[] {
    let text
    if (isSlideRate) {
      text = `${(r.slidingScale[index].slideRate || 0) * 100} : 1`
    } else {
      text = `${r.slidingScale[index].commission * 100}% @ ${r.slidingScale[index].lossRatio * 100}%`
    }
    return [text, 'text']
  }
}
