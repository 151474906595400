import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input
} from '@angular/core'
import {
  checkIfDomRectChanged,
  generatePlainText,
  generateHtmlTableFromSelection,
  getRegionClipCssString,
  getClosestElement,
  getArrayFromRegionSelection,
  getFirstParentOfType,
  setFloatingMenuPosition,
  rectContains,
  parseCSVStringToArray,
  cleanCSVStringArray,
  selectionIncludesCell,
} from './sort-table-excel.util'
import {
  excelTableModeOptions,
  excelSelectionModeOptions,
  Region,
  CellIdentifier,
  SortTableDataRef,
  LesserDomRect,
  ExcelStates,
  ExcelActions,
  UserInputEvents,
  TableModeMenuOptions,
  SelectionModeMenuOptions,
  MenuOptions
} from './sort-table-excel.model'
import {
  SortTableRow,
  SortTableValueChangeEvent
} from '../sort-table.model'
import Finity, { StateMachine } from 'finity'
import { MatTableDataSource } from '@angular/material/table'
import { SortTableColumnsConfig } from '../sort-table-column-view'
import { interval, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import hotkeys from 'hotkeys-js';

/** Noninstantiable class intended to add a several excel like features onto the app-sort-table
  * */
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class SortTableExcel<T> implements AfterContentInit {
  @Input() excelEnabled = false
  abstract excelContextMenu: ElementRef<HTMLDivElement>
  excelMenuOptions = excelTableModeOptions

  abstract excelSelectionBox: ElementRef<HTMLDivElement>
  private fsm: StateMachine<ExcelStates, ExcelActions>

  protected abstract _destroy$: Subject<void>
  private currentHoveredElement: CellIdentifier
  private menuCurrentHoveredElement: CellIdentifier

  protected abstract dataSource: MatTableDataSource<SortTableRow<T>>
  protected abstract columns: SortTableColumnsConfig<T>
  protected abstract rows: SortTableRow<T>[]
  protected abstract valueChange: EventEmitter<SortTableValueChangeEvent<T>>
  protected tableDomCells: HTMLTableDataCellElement[]

  protected tableParentElement: HTMLElement
  protected abstract refresh(): void
  protected abstract showUserMessage(message: string, error: boolean): void

  private set cellSelection(region: Region){
    this._cellSelection = region
    this.updateSelectionBox()
  }
  private get cellSelection(): Region{
    return this._cellSelection
  }
  _cellSelection: Region

  @HostListener('mousemove', ['$event'])
  handleMouseMoveEvent(event: MouseEvent): void {
    if (!this.excelEnabled){
      return
    }
    const element = getClosestElement<HTMLTableCellElement>(
      { x: event.x, y: event.y },
      this.tableDomCells
    )
    if (!element){
      return
    }
    this.currentHoveredElement = {
      elementRef: element,
      row: Number(element.getAttribute("row")),
      col: String(element.getAttribute("col"))
    }
    if (this.fsm.getCurrentState() === ExcelStates.PromptRegionSelection){
      this.updateC1CellSelection(this.currentHoveredElement)
    }
  }

  // If users right clicks inside of app-sort-table
  @HostListener('contextmenu', ['$event'])
  handleContextMouseEvent(event: MouseEvent): void {
    if (!this.excelEnabled){
      return
    }
    this.fsm.handle(UserInputEvents.ContextMenuTriggered, event)
    event.preventDefault()
  }

  // If users right clicks outside of app-sort-table
  @HostListener('document:contextmenu', ['$event'])
  handleDocumentContextMouseEvent(event: MouseEvent): void {
    if (!this.excelEnabled){
      return
    }
    this.fsm.handle(UserInputEvents.GlobalContextMenuTriggered, event)
  }

  // If users selects a element within the app-sort-table
  @HostListener('focusin', ['$event'])
  boxElementFocused(event: FocusEvent): void {
    if (!this.excelEnabled){
      return
    }
    // If selection is a header cell
    if ((event.target as HTMLElement).closest('th')){
      return
    }
    this.fsm.handle(UserInputEvents.FocusGained, event)
  }

  // If users deselect a element within the app-sort-table
  @HostListener('focusout', ['$event'])
  boxElementUnfocused(event: FocusEvent): void {
    if (!this.excelEnabled){
      return
    }
    this.fsm.handle(UserInputEvents.FocusLost, event)
  }

  @HostListener('document:click', ['$event'])
  handleMouseEvent(event: MouseEvent): void {
    if (!this.excelEnabled){
      return
    }
    if ((event.target as HTMLElement).closest('th')){
      this.fsm.handle(UserInputEvents.Escape)
      return
    }
    const excelContextMenu = this.excelContextMenu.nativeElement
    if (event.button === 0){
      if (excelContextMenu.style.getPropertyValue('display') !== 'none') {
        if (rectContains(excelContextMenu.getBoundingClientRect(), event.x, event.y)) {
          return
        }
      }
      // Shift click to be added in the future
      //
      // if (this.fsm.canHandle(UserInputEvents.GlobalShiftLeftClick) && event.shiftKey) {
      //   this.fsm.handle(UserInputEvents.GlobalShiftLeftClick, event)
      // }
      // else {
      //   this.fsm.handle(UserInputEvents.GlobalLeftClick, event)
      // }
      this.fsm.handle(UserInputEvents.GlobalLeftClick, event)
    }
  }

  private isDecimalBuffer = false
  @HostListener('document:keydown', ['$event'])
  handleKeyDownEvent(event: KeyboardEvent): void {
    if (!this.excelEnabled){
      return
    }
    if (this.fsm.getCurrentState() === ExcelStates.SelectionIsMade && this.isSingleCellSelection()){
      const focusedElement = this.tableParentElement.querySelector(':focus') as HTMLElement
      const inputElement = this.cellSelection.c1.elementRef.querySelector('input')
      if (event.ctrlKey){
        return
      }

      if (event.key === 'Escape' && focusedElement){ // Unfocus selected cell
        focusedElement.blur()
        return
      }
      else if (event.key === 'Enter') {
        if (focusedElement){
          inputElement.blur()
        }
        if (!event.shiftKey) {
          this.fsm.handle(UserInputEvents.Enter)
        }
        else {
          this.fsm.handle(UserInputEvents.ShiftEnter)
        }
        return
      }
      else if (event.key === 'Tab') {
        if (focusedElement){
          inputElement.blur()
        }
        if (!event.shiftKey) {
          this.fsm.handle(UserInputEvents.Tab)
        }
        else {
          this.fsm.handle(UserInputEvents.ShiftTab)
        }
        return
      }
      const colsList = this.columns.views.map(x => x.id)
      const c0ColIndex = colsList.indexOf(this.cellSelection.c0.col as keyof T)
      if (!this.columns.views[c0ColIndex].editable){
        return
      }
      if (event.key.length !== 1){ // If key is not a letter don't add it to the buffer
        return
      }

      if (focusedElement === inputElement) { // If the input is already focused don't need to overwrite current text
        return
      }
      if (event.key === '.'){ // Input object with number limits cannot process decimal point alone without a number
        this.isDecimalBuffer = true
        return
      }
      else {
        if (this.isDecimalBuffer){
          inputElement.value = "."
          this.isDecimalBuffer = false
        }
        else {
          inputElement.value = ""
        }
        inputElement.focus()
      }
    }
  }

  constructor(){
    this.fsm = this.generateFiniteStateMachine()
  }

  ngAfterContentInit(): void{
    this.pollRegionSelectionPosition()

    hotkeys('escape', () => {
      if (!this.excelEnabled){
        return true
      }
      if (this.fsm.canHandle(UserInputEvents.Escape)){
        this.fsm.handle(UserInputEvents.Escape)
        return false
      }
    })

    hotkeys('right', (event) => {
      if (!this.excelEnabled){
        return
      }
      if (this.fsm.canHandle(UserInputEvents.RightArrow)){
        this.fsm.handle(UserInputEvents.RightArrow)
        event.preventDefault()
      }
    })

    hotkeys('up', (event) => {
      if (!this.excelEnabled){
        return
      }
      if (this.fsm.canHandle(UserInputEvents.UpArrow)){
        this.fsm.handle(UserInputEvents.UpArrow)
        event.preventDefault()
      }
    })

    hotkeys('down', (event) => {
      if (!this.excelEnabled){
        return
      }
      if (this.fsm.canHandle(UserInputEvents.DownArrow)){
        this.fsm.handle(UserInputEvents.DownArrow)
        event.preventDefault()
      }
    })

    hotkeys('left', (event) => {
      if (!this.excelEnabled){
        return
      }
      if (this.fsm.canHandle(UserInputEvents.LeftArrow)){
        this.fsm.handle(UserInputEvents.LeftArrow)
        event.preventDefault()
      }
    })

    const tableOptions = [...excelTableModeOptions, ...excelSelectionModeOptions]
    tableOptions.forEach(option => {
      if (!this.excelEnabled){
        return
      }
      if (option.shortcut.includes('escape')){
        return
      }
      hotkeys(option.shortcut.join('+'), () => {
        if (this.fsm.canHandle(option.id)) {
          this.fsm.handle(option.id)
          return false
        }
      })
    })
  }

  private pollRegionSelectionPosition(): void{
    const pollingInterval = interval(20)
    let lastPollingC0Position: DOMRect
    pollingInterval.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (this.excelSelectionBox.nativeElement.style.getPropertyValue('display') === 'none') {
        return
      }
      if (this.cellSelection && this.cellSelection.c0) {
        const currentRect = this.cellSelection.c0.elementRef.getBoundingClientRect()
        if (!lastPollingC0Position){
          lastPollingC0Position = currentRect
          return
        }
        if (checkIfDomRectChanged(lastPollingC0Position, currentRect)){
          this.cellSelection = this.cellSelection // Trigger set event which recalculates Region/Selection box position
          lastPollingC0Position = currentRect
        }
      }
    })

  }

  private generateFiniteStateMachine(): StateMachine<ExcelStates, ExcelActions> {
    return Finity
      .configure<ExcelStates, ExcelActions>()

      .initialState(ExcelStates.TableMode)
        .onEnter(() => this.excelMenuOptions = excelTableModeOptions)
        .onExit(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.TableContextMenu).withAction(() => this.menuCurrentHoveredElement = this.currentHoveredElement)
        .on(UserInputEvents.GlobalContextMenuTriggered).ignore()
        .on(UserInputEvents.GlobalLeftClick).ignore()
        .on(UserInputEvents.FocusLost).ignore()
        .on(UserInputEvents.Escape).ignore()
        .on(UserInputEvents.FocusGained).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.selectSingleCell(this.currentHoveredElement))
        .on(TableModeMenuOptions.SelectRegion).transitionTo(ExcelStates.PromptRegionSelection).withAction(() => this.selectSingleRegion(true))
        .on(TableModeMenuOptions.SelectTable).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.selectWholeTableRegion())

      .state(ExcelStates.TableContextMenu)
        .onEnter((_state, payload) => this.toggleExcelMenu(payload.eventPayload, true))
        .onExit((_state, payload) => this.toggleExcelMenu(payload.eventPayload, false))
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.TableMode)
        .on(UserInputEvents.FocusGained).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.selectSingleCell(this.currentHoveredElement))
        .on(UserInputEvents.GlobalContextMenuTriggered).transitionTo(ExcelStates.TableMode)
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.TableMode)
        .on(UserInputEvents.Escape).transitionTo(ExcelStates.TableMode)
        .on(TableModeMenuOptions.SelectRegion).transitionTo(ExcelStates.PromptRegionSelection).withAction(() => this.selectSingleRegion())
        .on(TableModeMenuOptions.SelectTable).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.selectWholeTableRegion())

      .state(ExcelStates.PromptRegionSelection)
        .onEnter(() => this.enterCellSelection())
        .onExit(() => this.exitCellSelection())
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.GlobalContextMenuTriggered).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.Escape).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.FocusGained).selfTransition().withAction(() => this.unfocusCell())
        .on(UserInputEvents.FocusLost).ignore()

      .state(ExcelStates.SelectionIsMade)
        .onEnter(() => this.enterSelectionMode())
        .on(UserInputEvents.GlobalLeftClick).ignore()
        .on(UserInputEvents.Escape).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.TableMode).withAction(()=> this.exitSelectionMode())
        .on(UserInputEvents.GlobalContextMenuTriggered).ignore()
        .on(UserInputEvents.FocusLost).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false)).withCondition(this.isSingleCellSelection).selfTransition()
        .on(UserInputEvents.FocusGained).selfTransition().withAction(() => this.focusGainedSelectionEstablished())
        .on(UserInputEvents.RightArrow).selfTransition().withAction(() => this.moveSelectionRight())
        .on(UserInputEvents.LeftArrow).selfTransition().withAction(() => this.moveSelectionLeft())
        .on(UserInputEvents.UpArrow).selfTransition().withAction(() => this.moveSelectionUp())
        .on(UserInputEvents.DownArrow).selfTransition().withAction(() => this.moveSelectionDown())
        .on(UserInputEvents.Enter).selfTransition().withAction(() => this.moveSelectionDown())
        .on(UserInputEvents.Tab).selfTransition().withAction(() => this.moveSelectionRight())
        .on(UserInputEvents.ShiftEnter).selfTransition().withAction(() => this.moveSelectionUp())
        .on(UserInputEvents.ShiftTab).selfTransition().withAction(() => this.moveSelectionLeft())
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.SelectionContextMenu).withAction(() => this.contextMenuTriggered())
        .on(SelectionModeMenuOptions.Clear).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(SelectionModeMenuOptions.Copy).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.CopyWithHeaders).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.Paste).transitionTo(ExcelStates.Paste)

      .state(ExcelStates.SelectionContextMenu)
        .onEnter((_state, payload) => this.enterSelectionContextMenu(payload.eventPayload, true))
        .onExit((_state, payload) => this.toggleExcelMenu(payload.eventPayload, false))
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.GlobalContextMenuTriggered).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.SelectionContextMenu).withAction(() => this.contextMenuTriggered())
        .on(SelectionModeMenuOptions.Clear).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(SelectionModeMenuOptions.Copy).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.CopyWithHeaders).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.Paste).transitionTo(ExcelStates.Paste)

      .state(ExcelStates.SelectionContextMenu)
        .onEnter((_state, payload) => this.enterSelectionContextMenu(payload.eventPayload, true))
        .onExit((_state, payload) => this.toggleExcelMenu(payload.eventPayload, false))
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.GlobalContextMenuTriggered).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.ContextMenuTriggered).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.Escape).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.FocusGained).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.unfocusCell())
        .on(UserInputEvents.GlobalLeftClick).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.Escape).transitionTo(ExcelStates.SelectionIsMade)
        .on(UserInputEvents.FocusGained).transitionTo(ExcelStates.SelectionIsMade).withAction(() => this.unfocusCell())
        .on(UserInputEvents.FocusLost).ignore()
        .on(SelectionModeMenuOptions.Clear).transitionTo(ExcelStates.TableMode).withAction(() => this.toggleSelectRegion(false))
        .on(SelectionModeMenuOptions.Copy).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.CopyWithHeaders).transitionTo(ExcelStates.Copy)
        .on(SelectionModeMenuOptions.Paste).transitionTo(ExcelStates.Paste)

      .state(ExcelStates.Copy)
        .do((_state, context) => this.copySelection(context.event === SelectionModeMenuOptions.CopyWithHeaders))
          .onSuccess().transitionTo(ExcelStates.RegionOperationSuccess).withAction(_=> this.showUserMessage("Selection Copied", false))
          .onFailure().transitionTo(ExcelStates.SelectionModeError).withAction((_from, _to, context) => this.showUserMessage((context.error as unknown) as string, true))


      .state(ExcelStates.Paste)
        .do(_ => this.pasteSelection())
          .onSuccess().transitionTo(ExcelStates.RegionOperationSuccess).withAction(_=> this.showUserMessage("Paste successful", false))
          .onFailure().transitionTo(ExcelStates.SelectionModeError).withAction((_from, _to, context) => this.showUserMessage((context.error as unknown) as string, true))

      .state(ExcelStates.SelectionModeError)
        .onEnter(_ => this.enterErrorState())
        .onAny().ignore()
        .onTimeout(400).transitionTo(ExcelStates.SelectionIsMade)

      .state(ExcelStates.RegionOperationSuccess)
        .onEnter(_ => this.enterSuccessState())
        .onAny().ignore()
        .onTimeout(400).transitionTo(ExcelStates.SelectionIsMade)

      .global()
        // Uncomment for debugging
        // .onStateEnter((state, payload) => console.log(`Entering state '${state}' on ${payload.event}`))
      .start()
  }

  private enterSelectionContextMenu(event: MouseEvent, open: boolean): void {
    this.excelMenuOptions = excelSelectionModeOptions
    this.toggleExcelMenu(event, open)
  }

  private enterErrorState(): void{
    const element = this.excelSelectionBox.nativeElement
    element.style.setProperty('--selected-color', 'var(--red)')
  }

  private enterSuccessState(): void{
    const element = this.excelSelectionBox.nativeElement
    element.style.setProperty('--selected-color', 'var(--green)')
  }

  private updateC1CellSelection(value: CellIdentifier) : void {
    this.cellSelection = {
      ...this.cellSelection,
      c1: value
    }
  }

  private toggleExcelMenu(event: MouseEvent, open: boolean): void {
    const element = this.excelContextMenu.nativeElement
    if (!open){
      element.style.setProperty('Display', 'none')
    }
    else {
      element.style.setProperty('Display', 'block')
      setFloatingMenuPosition(element, event)
      event.stopImmediatePropagation()
      event.preventDefault()
    }
  }

  private contextMenuTriggered(): void {
    if (!this.selectionIncludesCell(this.currentHoveredElement)){
      this.selectSingleCell(this.currentHoveredElement)
    }
  }

  private selectionIncludesCell(cell: CellIdentifier): boolean {
    return selectionIncludesCell(this.cellSelection, cell, this.columns)
  }

  private toggleSelectRegion(visible: boolean): void {
    const element = this.excelSelectionBox.nativeElement
    if (!visible){
      element.style.setProperty('opacity', '0')
      element.style.setProperty('--selected-color', 'var(--orange)')
    }
    else {
      element.style.setProperty('opacity', '1')
      element.style.setProperty('clip', 'auto')
      // this.cellSelection = {c0: this.menuCurrentHoveredElement, c1: this.currentHoveredElement}
    }
  }

  private enterSelectionMode(): void {
    this.excelSelectionBox.nativeElement.style.setProperty('--selected-color', 'var(--blue)')
  }

  private exitSelectionMode(): void {
    this.excelSelectionBox.nativeElement.style.setProperty('--selected-color', 'var(--orange)')
    this.toggleSelectRegion(false)
  }

  private updateSelectionBox(): void{
    const element = this.excelSelectionBox.nativeElement
    const c0ElementRef = this.cellSelection.c0.elementRef
    const c1ElementRef = this.cellSelection.c1.elementRef

    const c0TD = getFirstParentOfType(c0ElementRef, "TD")
    const c1TD = getFirstParentOfType(c1ElementRef, "TD")

    if (!c0TD || !c1TD){
      return
    }

    const c0Box = c0TD.getBoundingClientRect()
    const c1Box = c1TD.getBoundingClientRect()

    // Single cell selection
    const topMostBox = c0Box.y < c1Box.y ? c0Box : c1Box
    const bottomMostBox = c0Box === topMostBox ? c1Box : c0Box

    const leftMostBox = c0Box.left < c1Box.left ? c0Box : c1Box
    const rightMostBox = c0Box === leftMostBox ? c1Box : c0Box

    const extraBorderHeight = 4

    const height = bottomMostBox.bottom - topMostBox.top - extraBorderHeight
    const width = rightMostBox.right - leftMostBox.left
    const top = topMostBox.top
    const left = leftMostBox.left
    let child: LesserDomRect = {
      height,
      width,
      top,
      right: left + width,
      bottom: top + height,
      left,
      x: left,
      y: top,
    }

    child = this.setBoxPosition(
      element,
      child
    )

    const clippingParent = this.tableParentElement.getBoundingClientRect()
    element.style.setProperty('clip', getRegionClipCssString(clippingParent,child))
  }

  private updateScrollPosition(): void{
    if (!this.isSingleCellSelection()){
      return
    }
    const child = this.excelSelectionBox.nativeElement.getBoundingClientRect()
    const clippingParent = this.tableParentElement.getBoundingClientRect()

    if (child.right > clippingParent.right){
      this.tableParentElement.scrollLeft += child.right - clippingParent.right + 15
    }
    else if (child.left < clippingParent.left){
      this.tableParentElement.scrollLeft += clippingParent.left - (Math.abs(child.left))
    }

    if (child.bottom > clippingParent.bottom){
      this.tableParentElement.scrollTop += child.bottom - clippingParent.bottom + 15
    }
    else if ( child.top < clippingParent.top + 100){
      this.tableParentElement.scrollTop += clippingParent.top - (Math.abs(child.top) + 100)
    }
  }

  private setBoxPosition(element: HTMLDivElement, size: LesserDomRect): DOMRect{
    const clone = JSON.parse(JSON.stringify(size))
    if (this.cellSelection.c0.row === this.rows.length - 1 ||
        this.cellSelection.c1.row === this.rows.length - 1){
      clone.height -= 2
      clone.bottom -= 2

    }
    const colsList = this.columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(this.cellSelection.c0.col as keyof T)
    const c1ColIndex = colsList.indexOf(this.cellSelection.c1.col as keyof T)
    if (c0ColIndex === this.columns.views.length - 1 ||
        c1ColIndex === this.columns.views.length - 1){
      clone.width -= 4
      clone.right -= 4
    }
    element.style.setProperty("height", `${clone.height}px`) // 5 pixels puts bottom of div above sort-table <hr>
    element.style.setProperty("width", `${clone.width}px`)
    element.style.setProperty("top", `${clone.top}px`)
    element.style.setProperty("left", `${clone.left}px`)
    return clone as DOMRect
  }

  private enterCellSelection(): void{
    this.toggleSelectRegion(true)
    const cursorStyle = document.createElement('style');
    cursorStyle.innerHTML = '*{cursor: cell!important;}';
    cursorStyle.id = 'cursor-style';
    document.head.appendChild(cursorStyle);
  }

  private exitCellSelection(){
    document.getElementById('cursor-style')?.remove();
  }

  public excelContextMenuItemSelected(eventID: MenuOptions, clickEvent: MouseEvent): void{
    clickEvent.preventDefault()
    clickEvent.stopImmediatePropagation()
    if (this.fsm.canHandle(eventID)){
      this.fsm.handle(eventID)
    }
  }

  private selectSingleRegion(keyboardTrigger: boolean = false): void {
    if (!keyboardTrigger) {
      this.cellSelection = {
        c0: this.menuCurrentHoveredElement,
        c1: this.menuCurrentHoveredElement
      }
    }
    else {
      this.cellSelection = {
        c0: this.currentHoveredElement,
        c1: this.currentHoveredElement
      }
    }
  }

  //Warning/Error types:
  //  1. Bad/No input | error
  //  2. Paste when input size is > region size | warning
  //  3. Paste when input columns don't match table columns | warning
  private pasteSelection(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      navigator.clipboard.readText().then(clipboardData => {
        if (!clipboardData){
          reject('No data to paste')
        }
        try {
          const cleaned = parseCSVStringToArray(clipboardData)
          cleanCSVStringArray(cleaned)

          const rowClone = JSON.parse(JSON.stringify(this.rows))
          const topLeft = this.getTopLeftDataRef()

          const changedEvents: SortTableValueChangeEvent<T>[] = []

          for (let rowIdx = 0; rowIdx < cleaned.length; rowIdx++) {
            const row = cleaned[rowIdx]
            const globalRowIdx = rowIdx + topLeft.row
            if (globalRowIdx >= rowClone.length){
              return reject("Paste overflow: Move the selection box higher to fit pasted element")
            }
            for (let colIdx = 0; colIdx < row.length; colIdx++) {
              const value = row[colIdx]
              const globalColIdx = colIdx + topLeft.col
              if (globalColIdx >= this.columns.views.length){
                return reject("Paste overflow: Move the selection box leftward to fit pasted element")
              }
              if (this.columns.views[globalColIdx].editable){
                //@ts-ignore ---- Poorly implement type for SortTableValueChangeEvent.
                const id = this.rows[globalRowIdx].id || undefined
                changedEvents.push({
                  id: id,
                  row: this.rows[globalRowIdx],
                  column: this.columns.views[globalColIdx],
                  value
                })
                this.setValueAtPosition(value, rowClone, globalColIdx, globalRowIdx)
              }
            }
          }

          const nodeList = this.tableDomCells

          const topLeftColId = this.columns.views[topLeft.col].id
          const lastColIndex = Math.min(cleaned[0].length - 1 + topLeft.col, this.columns.views.length - 1)

          const bottomRightColId = this.columns.views[lastColIndex].id
          const bottomRowIdx = Math.min(topLeft.row + cleaned.length - 1, this.rows.length - 1)

          const newBottomRight: CellIdentifier = {
            row: bottomRowIdx,
            col: bottomRightColId,
            elementRef: nodeList.find(node =>
                node.getAttribute('row') === String(bottomRowIdx) &&
                node.getAttribute('col') === bottomRightColId
            ) || this.cellSelection.c1.elementRef
          }

          this.cellSelection = { // Resize selection box to new paste size
            c0: {
              row: topLeft.row,
              col: topLeftColId,
              elementRef:  nodeList.find(node =>
                node.getAttribute('row') === String(topLeft.row) &&
                node.getAttribute('col') === topLeftColId
              ) || this.cellSelection.c0.elementRef
            },
            c1: newBottomRight
          }

          this.dataSource.data = rowClone
          this.rows = rowClone

          changedEvents.forEach(event => this.valueChange.emit(event))

          this.refresh()
          return resolve('')
        }
        catch {
          reject("Error on paste")
        }
      }).catch(_ => reject())
    })
  }

  // Future feature to be added
  // private shiftClick(): void {
  //   if (this.isSingleCellSelection()){
  //     this.cellSelection =  {
  //       c0: this.cellSelection.c0,
  //       c1: this.currentHoveredElement
  //     }
  //   }
  // }

  private focusGainedSelectionEstablished(): void {
    const focusedElement = this.tableParentElement.querySelector(':focus') as HTMLElement
    if (this.currentHoveredElement.elementRef.contains(focusedElement)) {
      this.cellSelection = {
        c0: this.currentHoveredElement,
        c1: this.currentHoveredElement
      }
    }
  }

  private canMoveUsingArrowKeys(): boolean {
    if (!this.isSingleCellSelection()){
      return false
    }
    const focusedElement = this.tableParentElement.querySelector(':focus') as HTMLElement
    if (focusedElement) {
      return false
    }
    return true
  }

  private moveSelectionUp(): void {
    if (!this.canMoveUsingArrowKeys()){
      return
    }
    const rowIdx = this.cellSelection.c0.row

    if (rowIdx === 0){
      return
    }

    const nodeList = Array.from(this.tableDomCells)

    const row = rowIdx - 1
    const col = this.cellSelection.c0.col

    const elementRef = nodeList.find(node =>
      node.getAttribute('row') === String(row) &&
      node.getAttribute('col') === col
    )

    const nextElement: CellIdentifier = {
      row,
      col,
      elementRef
    }

    this.moveCellSelection({c0: nextElement, c1: nextElement})
  }

  private moveSelectionDown(): void {
    if (!this.canMoveUsingArrowKeys()){
      return
    }
    const rowIdx = this.cellSelection.c0.row

    if (rowIdx === this.rows.length - 1){
      return
    }

    const nodeList = Array.from(this.tableDomCells)

    const row = rowIdx + 1
    const col = this.cellSelection.c0.col

    const elementRef = nodeList.find(node =>
      node.getAttribute('row') === String(row) &&
      node.getAttribute('col') === col
    )

    const nextElement: CellIdentifier = {
      row,
      col,
      elementRef
    }

    this.moveCellSelection({c0: nextElement, c1: nextElement})
  }


  private moveSelectionLeft(): void {
    if (!this.canMoveUsingArrowKeys()){
      return
    }
    const colsList = this.columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(this.cellSelection.c0.col as keyof T)

    if (c0ColIndex === 0){
      return
    }

    const nodeList = Array.from(this.tableDomCells)

    const row = this.cellSelection.c0.row
    const col = colsList[c0ColIndex - 1]
    const elementRef = nodeList.find(node =>
      node.getAttribute('row') === String(row) &&
      node.getAttribute('col') === col
    )

    const nextElement: CellIdentifier = {
      row,
      col,
      elementRef
    }

    this.moveCellSelection({c0: nextElement, c1: nextElement})
  }

  private moveSelectionRight(): void {
    if (!this.canMoveUsingArrowKeys()){
      return
    }
    const colsList = this.columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(this.cellSelection.c0.col as keyof T)

    if (c0ColIndex === this.columns.views.length - 1){
      return
    }

    const nodeList = Array.from(this.tableDomCells)

    const row = this.cellSelection.c0.row
    const col = colsList[c0ColIndex + 1]
    const elementRef = nodeList.find(node =>
      node.getAttribute('row') === String(row) &&
      node.getAttribute('col') === col
    )

    const nextElement: CellIdentifier = {
      row,
      col,
      elementRef
    }

    this.moveCellSelection({c0: nextElement, c1: nextElement})
  }

  // Update box position... update scroll position.... update box position again to account for scroll
  private moveCellSelection(position: Region): void {
    this.cellSelection = position
    this.updateScrollPosition()
    this.cellSelection = position
  }

  private setValueAtPosition(value: any, dataSet: SortTableRow<T>[], col: number, row:number): void {
    dataSet[row][this.columns.views[col].id] = value
  }

  private copySelection(includeHeader: boolean): Promise<boolean>{
    return new Promise<boolean>(async (resolve, reject) => {
      const data = getArrayFromRegionSelection(
        this.cellSelection,
        this.dataSource,
        this.columns
      )

      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([generateHtmlTableFromSelection(data, includeHeader)], { type: 'text/html' }),
        'text/plain': new Blob([generatePlainText(data, includeHeader)], { type: 'text/plain' })
      });

      navigator.clipboard.write([clipboardItem]).then(() => resolve(true)).catch(()=> reject("Copy Error: Something went wrong while attempting to copy selection"))

    })
  }

  private unfocusCell(): void {
    const focusedElement = this.tableParentElement.querySelector(':focus') as HTMLElement
    if (focusedElement) {
      focusedElement.blur()
    }
  }

  private selectSingleCell(cell: CellIdentifier): void {
    this.toggleSelectRegion(true)
    this.cellSelection = {
      c0: cell,
      c1: cell
    }
  }

  private isSingleCellSelection(){
    if (!this.cellSelection || !this.cellSelection.c0 || !this.cellSelection.c1){
      return false
    }
    return this.cellSelection.c0.elementRef === this.cellSelection.c1.elementRef
  }

  private selectWholeTableRegion(): void{
    this.toggleSelectRegion(true)
    const nodeList = Array.from(this.tableDomCells)

    const c1Row = this.dataSource.data.length - 1
    const c1Col = this.columns.views[this.columns.views.length - 1].id
    this.cellSelection = {
      c0: {
        row: 0,
        col: this.columns.views[0].id,
        elementRef:
          nodeList.find(node =>
            node.getAttribute('row') === '0' &&
            node.getAttribute('col') === this.columns.views[0].id
          )
          || this.currentHoveredElement.elementRef
      },
      c1: {
        row: c1Row,
        col: c1Col,
        elementRef: nodeList.find(node =>
          node.getAttribute('row') === String(c1Row) &&
          node.getAttribute('col') === c1Col
        ) || this.currentHoveredElement.elementRef
      }
    }
  }

  private getTopLeftDataRef(): SortTableDataRef {
    const c0 = this.cellSelection.c0
    const c1 = this.cellSelection.c1

    const colsList = this.columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(c0.col as keyof T)
    const c1ColIndex = colsList.indexOf(c1.col as keyof T)
    return {
      row: c0.row < c1.row ? c0.row : c1.row,
      col: c0ColIndex < c1ColIndex ? c0ColIndex : c1ColIndex,
    }
  }

  public getBottomRightDataRef(): SortTableDataRef {
    const c0 = this.cellSelection.c0
    const c1 = this.cellSelection.c1

    const colsList = this.columns.views.map(x => x.id)
    const c0ColIndex = colsList.indexOf(c0.col as keyof T)
    const c1ColIndex = colsList.indexOf(c1.col as keyof T)
    return {
      row: c0.row > c1.row ? c0.row : c1.row,
      col: c0ColIndex > c1ColIndex ? c0ColIndex : c1ColIndex,
    }
  }

  public cleanup(): void {
    hotkeys.unbind()
    this.exitCellSelection()
  }
}
