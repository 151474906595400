const abbreviations: { [key: string]: number } = {
  K: 3,
  M: 6,
  B: 9,
  T: 12,
  QD: 15,
  QT: 18,
  SXT: 21,
  SPT: 24,
  OCT: 27,
}
export function formatCurrencyString(amount: string | number): string {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
  if (!numericAmount) {
    return ''
  }
  const formattedAmount = numericAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formattedAmount.replace('$', '')
}

export function formatLongNumberLabel(n: number | { valueOf(): number }): string {
  const value = typeof n === 'number' ? n : n.valueOf()
  const sortedKeys = Object.keys(abbreviations).sort(
    (a, b) => abbreviations[b] - abbreviations[a]
  )
  for (const key of sortedKeys) {
    if (value >= 10 ** abbreviations[key]) {
      const newVal = value / 10 ** abbreviations[key]
      const formattedNumber = newVal.toFixed(1)
      return `${formattedNumber}${key}`
    }
  }
  return value.toFixed(value % 1 !== 0 ? 2 : 0).toString()
}

export function getNumberLabel(longNumberLabel: string): string | null {
  const label = longNumberLabel.match(/([KMBTQDSXO]{1,3})$/);
  return label ? label[0] : null;
}

export function getNumberFromAbbreviations(numberStr: string): number {
  const regexMatch  = numberStr.match(/^(\d+(\.\d+)?)([KMBTQDQT]{1,3})$/)
  if (regexMatch) {
    const base = parseFloat(regexMatch[1])
    const label = regexMatch[3] // the number label
    const exponent = abbreviations[label]
    return base * Math.pow(10, exponent)
  }
}
