import { Action, createReducer, on } from '@ngrx/store'
import {
  createNewCarrierYearProgramFromOpportunity,
  createNewCarrierYearProgramFromOpportunitySuccess,
  createNewCarrierYearProgramFromOpportunityFailure,
  createNewProgramFromOpportunity,
  createNewProgramFromOpportunitySuccess,
  createNewProgramFromOpportunityFailure,
  createNewYearProgramFromOpportunity,
  createNewYearProgramFromOpportunitySuccess,
  createNewYearProgramFromOpportunityFailure,
  updateSFAccountCarrierMappingSuccess,
} from '../../program-initiation/store/program-initation.actions'
import {
  AccountOpportunity,
  CarrierAccount,
} from '../../api/model/backend.model'
import { ApplicationError } from '../../error/model/error'
import * as AuthActions from './auth/auth.actions'

export const FEATURE_KEY = 'accountopportunity'

interface ExtendedState {
  loading: boolean
  error: ApplicationError | null
  saving: boolean
}

export interface AccountOpportunityState {
  accountOpportunities: AccountOpportunity[]
  carrierAccountMap: CarrierAccount[]
}

export type State = AccountOpportunityState & ExtendedState

export const initialState: State = {
  accountOpportunities: [],
  carrierAccountMap: [],
  loading: false,
  error: null,
  saving: false,
}

const accountOpportunityReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(
    AuthActions.identifyPermissionsSuccess,
    (state, { accountOpportunities, carrierAccountMap }) => {
      // Truncate timestamp from all accountopportunities
      const updatedOpportunities = accountOpportunities.map(opp => {
        return {
          ...opp,
          opportunityInceptionDate: opp.opportunityInceptionDate?.substring(
            0,
            10
          ),
          opportunityTerminationDate: opp.opportunityTerminationDate?.substring(
            0,
            10
          ),
        }
      })
      return {
        ...state,
        accountOpportunities: updatedOpportunities,
        carrierAccountMap,
        loading: initialState.loading,
        error: initialState.error,
      }
    }
  ),

  on(
    createNewProgramFromOpportunity,
    createNewYearProgramFromOpportunity,
    createNewCarrierYearProgramFromOpportunity,
    (state: State) => ({
      ...state,
      saving: true,
    })
  ),

  on(
    createNewCarrierYearProgramFromOpportunityFailure,
    createNewYearProgramFromOpportunitySuccess,
    createNewYearProgramFromOpportunityFailure,
    createNewProgramFromOpportunitySuccess,
    createNewProgramFromOpportunityFailure,
    (state: State) => ({
      ...state,
      saving: false,
    })
  ),

  // Add new carrier/sfaccount entry to map
  on(
    createNewCarrierYearProgramFromOpportunitySuccess,
    (state, { newCarrier }) => ({
      ...state,
      carrierAccountMap: [
        ...state.carrierAccountMap,
        {
          // tslint:disable-next-line: no-non-null-assertion
          sf_acctid: newCarrier.sf_acctid!,
          carrier_id: newCarrier.id,
        },
      ],
      saving: false,
    })
  ),

  on(updateSFAccountCarrierMappingSuccess, (state, { accountMapping }) => {
    return {
      ...state,
      carrierAccountMap: [
        ...state.carrierAccountMap.filter(
          mapping => mapping.sf_acctid !== accountMapping.sfAccountId
        ),
        {
          sf_acctid: accountMapping.sfAccountId,
          carrier_id: accountMapping.carrierId,
        },
      ],
    }
  })
)

export function reducer(state: State | undefined, action: Action) {
  return accountOpportunityReducer(state, action)
}
