<div>
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <div mat-dialog-content></div>
  <div mat-dialog-actions class="confirm-button">
    <button appButton mat-dialog-close type="submit" (click)="useAnalyze()">
      Use Analyze Re
    </button>
    <button appButton mat-dialog-close type="submit" (click)="useManual()">
      Use Manually
    </button>
  </div>
</div>
