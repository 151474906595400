import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { CreditGrossLossScenario } from './credit-portfolio.model'
import { CreditTowerDimensions, CreditTowerBounds } from './credit-tower.model'

export enum CashFlowColors {
  pmiers = 'rgb(1, 201, 109)', // green
  loss = 'rgb(169, 75, 0)', // orange
  retention = 'rgb(16, 19, 20)', // dark grey
  impliedLimit = 'rgb(175, 29, 97)', // sage pink
  impliedRetention = 'rgb(52, 133, 162)', // sage blue
}

export const DEFAULT_RETENTION_AREA: AreaData = {
  id: 'RETENTION',
  name: 'Retention',
  color: CashFlowColors.retention,
  data: [],
}

/** Per Tranche - key is tranche _id */
export type CashFlowsTrancheDataDict = Record<string, CashFlowsDatum[]>

export interface CashFlowsData {
  impliedRetention: number
  impliedLimit: number // top value of qs
  lossData: MetricDatum[]
  pmiersData: MetricDatum[]
  trancheData: CashFlowsTrancheDataDict
  tickNums: number
  actualDate: string | null
  rawGrossData: RawGrossCashFlowDatum[]
  rawCededData: RawCededCashFlowDatum[]
}

export interface RawGrossCashFlowDatum {
  forecast_month: number
  mi_loss: number
  earned_premium: number
  performing_capital_required: number
  nonperforming_capital_required: number
  day_30_delq_upb: number
  day_60_delq_upb: number
  day_90_delq_upb: number
  day_120_delq_upb: number
  day_150_delq_upb: number
  day_180_delq_upb: number
  credit_event_upb: number
  starting_iif: number
  starting_upb: number
  conditional_disposition_rate: number
  conditional_prepay_rate: number
  unscheduled_principal: number
  starting_rif: number
}
export interface CashFlowsDatum {
  forecastMonth: string
  attach: number
  limit: number
  pmiersCapital: number
}

export interface MetricDatum {
  forecastMonth: string
  value: number
}

export interface CashFlowsGraphData {
  impliedRetention: number
  impliedLimit: number
  lossData: MetricDatum[]
  pmiersData: MetricDatum[]
  trancheAreaData: AreaData[]
  trancheStackedBarData: StackedBarData
  tickNums: number
  actualDate: string | null
  rawGrossData: RawGrossCashFlowDatum[]
  rawCededData: RawCededCashFlowDatum[]
}

export interface RawCededCashFlowDatum {
  forecastMonth: number
  premium: number
  loss: number
  pmiersRelief: number
  costOfPmiers: number
  limitRemaining: number
  annualPremiumRate: number
  cedingCommission: number
  profitCommission: number
  grossMargin: number
  netMargin: number
  lossRatio: number
  cedingCommissionRate: number
  profitCommissionRate: number
  grossMarginRate: number
  netMarginRate: number
}

export interface AreaData {
  id: string // tranche id
  name: string // tranche name
  color: string // color of area chart
  data: AreaDatum[]
  focused?: AreaDatum
}

export interface AreaDatum {
  x: string // forecastMonth
  y0: number // attach
  y1: number // attach + limit
}

export interface StackedBarData {
  keys: string[] // tranche ids
  colors: string[] // tranche colors
  data: StackedBarDatum[]
  focused?: StackedBarDatum
}

export interface StackedBarDatum {
  x: string // forecastMonth
  [key: string]: number | string // [_id] : limit
}

export interface FocusedDatum {
  loss: MetricDatum
  pmiers: MetricDatum
  areas: AreaData[]
  bar: StackedBarDatum
}

export interface CashFlowsGraphProperties {
  svgDimensions: CreditTowerDimensions
  graphDimensions: CreditTowerDimensions
  bounds: CreditTowerBounds
}

export enum CashFlowsChartType {
  Area = 'Area',
  Bar = 'Bar',
}

export const CASH_FLOWS_CHART_TYPES: CashFlowsChartType[] = [
  CashFlowsChartType.Area,
  CashFlowsChartType.Bar,
]

export interface CreditGrossActual extends CreditGrossLossScenario {
  actual: boolean
}

const DEFAULT_SVG_DIMENSIONS: CreditTowerDimensions = {
  height: 0,
  width: 0,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  offset: {
    height: 1,
    width: 1,
  },
}

const DEFAULT_GRAPH_DIMENSIONS: CreditTowerDimensions = {
  height: 0,
  width: 0,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  offset: {
    height: 0.85,
    width: 0.9,
  },
}

export const DEFAULT_CASH_FLOWS_GRAPH_PROPERTIES: CashFlowsGraphProperties = {
  svgDimensions: DEFAULT_SVG_DIMENSIONS,
  graphDimensions: DEFAULT_GRAPH_DIMENSIONS,
  bounds: {
    most: 0,
  },
}

export const DESIGN_CASH_FLOW_TABLE_COLUMNS: readonly SortTableColumnDef[] = [
  {
    id: 'name',
    label: 'Tranche/Transaction',
    valueType: 'text',
  },
  {
    id: 'expected_premium',
    label: 'Expected Premium',
    valueType: 'short-numeric',
  },
  {
    id: 'expected_loss',
    label: 'Expected Loss',
    valueType: 'short-numeric',
  },
  {
    id: 'ceded_margin',
    label: 'Ceded Margin',
    valueType: 'short-numeric',
  },
  {
    id: 'loss_ratio',
    label: 'Loss Ratio',
    valueType: 'percentage',
  },
  {
    id: 'pmiers_capital',
    label: 'PMIERS Capital',
    valueType: 'short-numeric',
  },
  {
    id: 'annual_return_pmiers_capital',
    label: 'Annual Return On PMIERS Capital',
    valueType: 'percentage',
  },
  {
    id: 'annual_premium',
    label: 'Annual Premium',
    valueType: 'percentage',
  },
] as const

export const SUBMISSION_CASH_FLOW_TABLE_COLUMNS: readonly SortTableColumnDef[] =
  [
    {
      id: 'name',
      label: 'Tranche/Transaction',
      valueType: 'text',
    },
    {
      id: 'expected_premium',
      label: 'Expected Premium',
      valueType: 'short-numeric',
    },
    {
      id: 'expected_loss',
      label: 'Expected Loss',
      valueType: 'short-numeric',
    },
    {
      id: 'ceded_margin',
      label: 'Ceded Margin',
      valueType: 'short-numeric',
    },
    {
      id: 'loss_ratio',
      label: 'Loss Ratio',
      valueType: 'percentage',
    },
    {
      id: 'pmiers_capital',
      label: 'PMIERS Capital',
      valueType: 'short-numeric',
    },
    {
      id: 'annual_premium',
      label: 'Annual Premium',
      valueType: 'percentage',
    },
    {
      id: 'collateral',
      label: 'Collateral',
      valueType: 'short-numeric',
    },
    {
      id: 'return_on_collateral',
      label: 'Return on Collateral',
      valueType: 'percentage',
    },
  ] as const

export enum CreditRawCashFlowTab {
  Gross = 'Gross',
  Ceded = 'Ceded',
}

export const CREDIT_RAW_CASH_FLOW_TABS: CreditRawCashFlowTab[] = [
  CreditRawCashFlowTab.Gross,
  CreditRawCashFlowTab.Ceded,
]

export const RAW_XOL_ILN_CEDED_CASH_FLOW_TABLE_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'premium',
    label: 'Premium',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'loss',
    label: 'Loss',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'limitRemaining',
    label: 'Limit Remaining',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'pmiersRelief',
    label: 'PMIERS Relief',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'annualPremiumRate',
    label: 'Annual Premium Rate',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'costOfPmiers',
    label: 'Cost of PMIERS',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
]

export const RAW_QS_CEDED_CASH_FLOW_TABLE_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'premium',
    label: 'Premium',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'loss',
    label: 'Loss',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'cedingCommission',
    label: 'Ceding Commission',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '10rem',
  },
  {
    id: 'profitCommission',
    label: 'Profit Commission',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'grossMargin',
    label: 'Gross Margin',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'netMargin',
    label: 'Net Margin',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'pmiersRelief',
    label: 'PMIERS Relief',
    valueType: 'short-numeric',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'lossRatio',
    label: 'Loss Ratio',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'cedingCommissionRate',
    label: 'Ceding Commission Rate',
    valueType: 'percentage',
    editable: false,
    minWidth: '12.5rem',
  },
  {
    id: 'profitCommissionRate',
    label: 'Profit Commission Rate',
    valueType: 'percentage',
    editable: false,
    minWidth: '12.5rem',
  },
  {
    id: 'grossMarginRate',
    label: 'Gross Margin Rate',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'netMarginRate',
    label: 'Net Margin Rate',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
  {
    id: 'costOfPmiers',
    label: 'Cost of PMIERS',
    valueType: 'percentage',
    editable: false,
    minWidth: '7.5rem',
  },
]
