import {
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding,
  OnInit,
  OnChanges,
} from '@angular/core'
import { CompareMetricValue } from '../../../model/compare-metrics.model'
import { Size } from '@shared/size.mixin'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metric-value',
  styleUrls: ['./compare-metric-value.component.scss'],
  templateUrl: './compare-metric-value.component.html',
})

export class CompareMetricValueComponent implements OnInit {
  @Input() entitiesLength: number
  @Input() compareMetricValue: CompareMetricValue
  @Input() rankMax: number
  @Input() size: Size
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() metricsLabel: string
  @Input() precision: number

  isEfficiencyMetric = false
  isMiscMetric = false
  formatter: string

  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  ngOnInit(): void {
    if (this.metricsLabel === 'Efficiency Metrics') {
      this.isEfficiencyMetric = true
    }
    if (
      this.metricsLabel === 'Misc' &&
      this.compareMetricValue.path !== 'miscMetrics/probabilityMetric'
    ) {
      this.isMiscMetric = true
    }
    if (!this.compareMetricValue.conversion) {
      this.compareMetricValue.conversion = 'Millions'
    }
  }

  ngOnChanges() {
    this.formatter = `1.${this.precision}-${this.precision}`
  }

  get label(): string {
    const suffix = this.compareMetricValue.portfolioType
      ? `, ${this.compareMetricValue.portfolioType}`
      : ''
    return `${this.compareMetricValue.label}${suffix}`
  }

  get rankSize(): Size {
    switch (this.size) {
      case 'tiny':
        return 'small'
      case 'small':
        return 'body'
      default:
        return 'big'
    }
  }

  isRankHidden(value: CompareMetricValue) {
    const categoryLabel = `${value.category}-${value.label}`
    if (this.entitiesLength === 1) {
      return true
    } else {
      return (
        (this.hiddenMetricRanks && this.hiddenMetricRanks[categoryLabel]) ||
        false
      )
    }
  }
}
