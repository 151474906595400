import { Component, Input, OnInit } from '@angular/core'
import { NavService } from '../nav.service'

@Component({
  selector: 'app-signature-header',
  template: `
    <button
      mat-icon-button
      class="menu-icon"
      id="menu"
      (click)="navService.open()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <a class="logo" routerLink="/home" appLogo></a>
    <app-header-title
      class="title"
      [name]="name"
      [subtitle]="subtitle"
    ></app-header-title>
    <app-contact-support-icon-container></app-contact-support-icon-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 auto;
        box-sizing: border-box;
      }
      .menu-icon {
        background-color: var(--bg);
        border-color: var(--bg);
        margin: var(--inset);
      }
      .mat-icon {
        color: white;
      }
      a {
        flex: 0 0 auto;
      }
      a.logo {
        width: calc(
          var(--layer-panel-width) - var(--inset-big) - var(--inset-tiny)
        );
        margin-right: var(--inset-huge);
      }
      a.sidebar-toggle {
        color: var(--body);
        padding: 0.75rem 1rem;
        margin-right: var(--inset-big);
      }
      .title {
        flex: 1 1 auto;
        overflow: hidden;
      }
      app-corp-logo {
        width: 120px;
      }
      @media screen and (max-width: 1240px) {
        a.logo {
          width: calc(
            var(--layer-panel-width-small) - var(--inset-big) - var(
                --inset-tiny
              )
          );
        }
        app-corp-logo {
          width: 100px;
        }
      }
    `,
  ],
})
export class SignatureHeaderComponent implements OnInit {
  @Input() name = 'Signature'

  constructor(public navService: NavService) {}

  ngOnInit(): void {}

  get subtitle(): string {
    return 'SAGE offers a variety of analytics-driven insights. Please select from the available programs for your chosen company.\nYou can return to the selection page to choose another company.'
  }
}
