import {
  Actions,
  createEffect,
  CreateEffectMetadata,
  ofType,
} from '@ngrx/effects'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { EntitySelectors } from '@ngrx/entity/src/models'
import {
  Action,
  ActionCreator,
  ActionReducer,
  createAction,
  createReducer,
  createSelector,
  on,
  select,
  Store,
} from '@ngrx/store'
import { FunctionWithParametersType, TypedAction } from '@ngrx/store/src/models'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { ApiResponse } from '../../api/model/api.model'
import { rejectError } from '../../api/util'
import { ApplicationError } from '../../error/model/error'
import { rejectUnlessStale } from '@shared/util/operators'
import { AppState } from '../store'
import { CanFetch, isStale } from './general.model'

//#region Types

interface ICreateEntityStoreOptions {
  moduleName?: string
}

export type CreateEntityStoreOptions = Partial<ICreateEntityStoreOptions>

export type EntityStoreState<T> = EntityState<T> & CanFetch

type EntityStoreAction<TIn extends any[], TOut> = FunctionWithParametersType<
  TIn,
  TOut & TypedAction<string>
> &
  TypedAction<string>

export interface EntityStoreActions<T> {
  fetch: EntityStoreAction<[boolean?], { force: boolean }>
  fetchSuccess: EntityStoreAction<[T[]], { data: T[] }>
  fetchFailure: EntityStoreAction<
    [ApplicationError],
    { error: ApplicationError }
  >
  fetchAbort: ActionCreator<string, () => TypedAction<string>>
  invalidate: ActionCreator<string, () => TypedAction<string>>
}

export type EntityStoreActionType<T> = keyof EntityStoreActions<T>

export type EntityStoreReducer<T> = ActionReducer<EntityStoreState<T>, Action>

type EntityStoreStateSelector<T> = (state: AppState) => EntityStoreState<T>

export type EntityStoreSelectors<T> = EntitySelectors<T, AppState> & {
  selectIsStale: (state: AppState) => boolean
  selectLoading: (state: AppState) => boolean
}

type EntityStoreSelectorsCreator<T> = (
  selectState: EntityStoreStateSelector<T>
) => EntityStoreSelectors<T>

type EntityStoreFetchEffectCreator<T> = (
  actions$: Actions,
  store: Store<AppState>,
  selectStale: (state: AppState) => boolean,
  fetchFn: () => ApiResponse<T[]>
) => Observable<
  {
    data: T[]
  } & TypedAction<string>
> &
  CreateEffectMetadata

export interface EntityStore<T> {
  initialState: EntityStoreState<T>
  actions: EntityStoreActions<T>
  reducer: EntityStoreReducer<T>
  getSelectors: EntityStoreSelectorsCreator<T>
  createFetchEffect: EntityStoreFetchEffectCreator<T>
}

//#endregion

const defaultOptions: ICreateEntityStoreOptions = {}

const createEntityStoreActions = <T>(
  name: string,
  moduleName?: string
): EntityStoreActions<T> => {
  const actionPrefix = moduleName ? `[${moduleName}] ` : ''
  const fetch = createAction(
    `${actionPrefix}Fetch ${name}`,
    (force: boolean = false) => ({ force })
  )
  const fetchSuccess = createAction(
    `${actionPrefix}Fetch ${name} Success`,
    (data: T[]) => ({ data })
  )
  const fetchFailure = createAction(
    `${actionPrefix}Fetch ${name} Failure`,
    (error: ApplicationError) => ({ error })
  )
  const fetchAbort = createAction(`${actionPrefix}Fetch ${name} Abort`)
  const invalidate = createAction(`${actionPrefix}Invalidate ${name}`)
  return { fetch, fetchSuccess, fetchFailure, fetchAbort, invalidate }
}

const createEntityStoreReducer = <T>(
  initialState: EntityStoreState<T>,
  adapter: EntityAdapter<T>,
  actions: EntityStoreActions<T>
): EntityStoreReducer<T> => {
  return createReducer(
    initialState,

    on(actions.fetch, state => ({
      ...state,
      loading: true,
      error: null,
    })),

    on(actions.fetchSuccess, (state, { data }) => ({
      ...adapter.setAll(data, state),
      loading: false,
      error: null,
      fetchTime: Date.now(),
    })),

    on(actions.fetchFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })),

    on(actions.fetchAbort, state => ({
      ...state,
      loading: false,
      error: null,
    })),

    on(actions.invalidate, state => ({
      ...state,
      fetchTime: undefined,
    }))
  )
}

const createEntityStoreSelectors =
  <T>(adapter: EntityAdapter<T>): EntityStoreSelectorsCreator<T> =>
  (selectState: EntityStoreStateSelector<T>) => ({
    ...adapter.getSelectors(selectState),
    selectIsStale: createSelector(selectState, isStale),
    selectLoading: createSelector(selectState, state => state.loading),
  })

const makeEntityStoreFetchEffect =
  <T>(actions: EntityStoreActions<T>): EntityStoreFetchEffectCreator<T> =>
  (
    actions$: Actions,
    store: Store<AppState>,
    selectStale: (state: AppState) => boolean,
    fetchFn: () => ApiResponse<T[]>
  ) =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.fetch),
        rejectUnlessStale(store.pipe(select(selectStale)), () =>
          store.dispatch(actions.fetchAbort())
        ),
        switchMap(fetchFn),
        rejectError(error => store.dispatch(actions.fetchFailure(error))),
        map(companies => actions.fetchSuccess(companies))
      )
    )

const createEntityStore = <T>(
  name: string,
  opts?: CreateEntityStoreOptions
): EntityStore<T> => {
  const o = { ...defaultOptions, ...opts }
  const adapter = createEntityAdapter<T>()
  const initialState = adapter.getInitialState<CanFetch>({
    loading: false,
    error: null,
  })

  const actions = createEntityStoreActions<T>(name, o.moduleName)
  const reducer = createEntityStoreReducer(initialState, adapter, actions)
  const getSelectors = createEntityStoreSelectors(adapter)
  const createFetchEffect = makeEntityStoreFetchEffect(actions)

  return { initialState, actions, reducer, getSelectors, createFetchEffect }
}

export default createEntityStore
