import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Broker } from '../../core/model/broker.model'
import { AppState } from '../../core/store'
import {
  selectAuthState,
  selectSageLogicalRole,
} from '../../core/store/auth/auth.selectors'
import {
  selectCurrentClientID,
} from '../../core/store/broker/broker.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-broker-container',
  templateUrl: './broker.container.html',
})
export class BrokerContainerComponent implements OnInit {
  @Input() clientSelect = false

  selectedClientID$: Observable<string | null>
  broker$: Observable<Broker>
  sageLogicalRole$: Observable<string | null>

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    // Note: TODO: Currently no user image in blobstorage
    this.broker$ = this.store.pipe(
      select(selectAuthState),
      map(authState => ({
        username: (authState.username as string).split('@')[0],
        name: authState.name as string,
        avatarID: 'assets/avatars/initials-placeholder-square.png',
      }))
    )

    this.sageLogicalRole$ = this.store.pipe(select(selectSageLogicalRole))
  }
}
