import { createAction, props } from '@ngrx/store'
import { ApplicationError } from './../../../error/model/error'
import { CreditStructureCompareEntity } from './../../model/credit-compare.model'
import { CreditStructure } from '../../model/credit-structure.model'
import { CreditStructureGroup } from '../../model/credit-structure-group.model'

enum CreditCompareActions {
  GetCompareMetricsForStructure = '[Credit Compare] Get Metrics for Selected Structure',
  GetCompareMetricsForStructureSuccess = '[Credit Compare] Get Metrics for Selected Structure - Success',
  GetCompareMetricsForStructureFailure = '[Credit Compare] Get Metrics for Selected Structure - Failure',
  GetCompareThumbnailsForStructureGroup = '[Credit Compare] Get Thumbnails for Selected Structure Group',
  GetCompareThumbnailsForStructureGroupFailure = '[Credit Compare] Get Thumbnails for Selected Structure Group - Failure',
  GetCompareMetricsForStructureGroup = '[Credit Compare] Get Metrics for Selected Structure Group',
  GetCompareMetricsForStructureGroupSuccess = '[Credit Compare] Get Metrics for Selected Structure Group - Success',
  GetCompareMetricsForStructureGroupFailure = '[Credit Compare] Get Metrics for Selected Structure Group - Failure',
  RemoveCreditStructureCompareEntity = '[Credit Compare] Remove Compare Structure Entity',
  RemoveAllCreditStructureCompareEntities = '[Credit Compare] Remove All Compare Structure Entities',
  GetCompareMetricCategorySettings = '[Credit Compare] Get Metric Category Settings',
}

export const GetCreditCompareMetricsForStructure = createAction(
  CreditCompareActions.GetCompareMetricsForStructure,
  props<{ creditStructure: CreditStructure }>()
)
export const GetCreditCompareMetricsForStructureFailure = createAction(
  CreditCompareActions.GetCompareMetricsForStructureFailure,
  props<{ error: ApplicationError }>()
)
export const GetCreditCompareMetricsForStructureSuccess = createAction(
  CreditCompareActions.GetCompareMetricsForStructureSuccess,
  props<{ creditStructureCompareEntity: CreditStructureCompareEntity }>()
)

export const GetCreditCompareThumbnailsForStructureGroup = createAction(
  CreditCompareActions.GetCompareThumbnailsForStructureGroup,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)
export const GetCreditCompareThumbnailsForStructureGroupFailure = createAction(
  CreditCompareActions.GetCompareThumbnailsForStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const GetCreditCompareMetricsForStructureGroup = createAction(
  CreditCompareActions.GetCompareMetricsForStructureGroup,
  props<{ creditStructureGroup: CreditStructureGroup; thumbnails: string[] }>()
)
export const GetCreditCompareMetricsForStructureGroupFailure = createAction(
  CreditCompareActions.GetCompareMetricsForStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const GetCreditCompareMetricsForStructureGroupSuccess = createAction(
  CreditCompareActions.GetCompareMetricsForStructureGroupSuccess,
  props<{ creditStructureCompareEntity: CreditStructureCompareEntity }>()
)

export const RemoveCreditStructureCompareEntity = createAction(
  CreditCompareActions.RemoveCreditStructureCompareEntity,
  props<{ structureId: string }>()
)
export const RemoveAllCreditStructureCompareEntities = createAction(
  CreditCompareActions.RemoveAllCreditStructureCompareEntities
)

export const GetCreditCompareMetricCategorySettings = createAction(
  CreditCompareActions.GetCompareMetricCategorySettings,
  props<{ creditStructureCompareEntity: CreditStructureCompareEntity }>()
)
