import { BenchmarkPeerSetType } from '../../api/benchmark/benchmark-peer-set.converter'
import { HasID, HasName } from '../../core/model/general.model'
import {
  AggregateMetricMethod,
  CompositeMetricMethod,
} from '../../core/model/metric.model'
import { GraphingColorPalette } from '@graphing/utils/graphing-color-palette'
import { GraphingColorScaleOptions } from '@graphing/utils/graphing-color-scale'
import {
  GraphingValueFormat,
  GraphingValueFormatPreset,
} from '@graphing/utils/graphing-value-formatter'
import { GraphingMetricExtents } from '@graphing/models/graphing.model'
import { BenchmarkModeID, BenchmarkSystemModeID } from './benchmark-mode'
import { BenchmarkOptionKey } from './benchmark-options'

interface HasMode {
  mode: BenchmarkModeID
}

interface BenchmarkCompanyMetadata {
  rating: string
  entityType: string
  lob: string
}

export interface BenchmarkCompany
  extends HasID,
    HasName,
    HasMode,
    Partial<BenchmarkCompanyMetadata> {
  description?: string
  abbreviation?: string
  hide?: boolean
  groupID: string
  groupName: string
  subGroupID?: string
  subGroupName?: string
  level: 1 | 2 | 3 | 4
  term: string
  groupCount: number
  subGroupCount: number
  amBestNumber?: number
  isCompanyNameOverriden?: boolean
  filerType?: number
  isAmBestGroup?: boolean
  csGroupName?: string
  csGroupStatEntityId?: string
  crossSectorOverride?: boolean
}

export interface BenchmarkCategory
  extends HasID,
    HasName,
    HasMode,
    Partial<BenchmarkCompanyMetadata> {
  description?: string
  abbreviation?: string
  hide?: boolean
}

export interface WithIndexMap<T> {
  value?: T
  byIndex?: Record<number, T>
}

export type BenchmarkSubmodeGroupIndexState<T> = Record<string, WithIndexMap<T>>

export interface WithIndices<T> {
  value: T
  indices?: number[]
}

export const benchmarkUsHealthEntityGroups: BenchmarkSystemModeID[] = [
  'us-cross-sector',
  'us',
  'us-health',
  'us-life',
]

export const benchmarkEntityFilters = [
  'target',
  'entity-select',
  'all-peers',
  'target-industry',
  'target-and-peers',
] as const
export type BenchmarkEntityFilterType = (typeof benchmarkEntityFilters)[number]

export type BenchmarkVisibility = 'show' | 'hide' | 'disable'

export interface BenchmarkMetricVisibilityActionChange {
  metricID: string | number
  visibility: BenchmarkVisibility
  selectMetricIDIfSelected?: string | number
}

export interface BenchmarkMetricVisibilityAction {
  label: string
  checked: boolean
  rowSpan?: number
  changes: BenchmarkMetricVisibilityActionChange[]
}

export type BenchmarkDefBase = HasID &
  HasName & {
    abbreviation?: string
    color?: GraphingColorPalette
    submodes?: string[]
  }

export interface BenchmarkItemsWithOption {
  checked: boolean
  selectedItems: BenchmarkDefBase[]
}

export type UiSettingsDefBase = HasID &
  HasName & {
    settings: any
  }

export type BenchmarkSelectionType =
  | 'user'
  | 'submode'
  | 'override'
  | 'top'
  | 'default'
  | 'none'

export interface BenchmarkLobSelection {
  lobs: BenchmarkLob[]
  selectType: BenchmarkSelectionType
}

export interface BenchmarkStateSelection {
  states: BenchmarkDefBase[]
  selectType: BenchmarkSelectionType
}

export interface BenchmarkMetricBase extends BenchmarkDefBase {
  estimateYear?: number
  aggregateMethod?: AggregateMetricMethod
  format?: GraphingValueFormat | GraphingValueFormatPreset
  colorScale?: Partial<GraphingColorScaleOptions>
  metricWeightID?: string | number
  metric2ID?: string | number
  metric2WeightID?: string | number
  compositeMethod?: CompositeMetricMethod
  compositeIDs?: (string | number)[]
  compositeDefaultValues?: (number | null)[]
  visibility?: BenchmarkVisibility
  visibilityAction?: BenchmarkMetricVisibilityAction
  extents?: GraphingMetricExtents
  unlessLOB?: string | number | (string | number)[]
  whenLOB?: string | number | (string | number)[]
  entityFilter?: BenchmarkEntityFilterType
  weightUSStatePopulations?: boolean
  /** Metric name before formatting w/ `entityFilter` information. */
  rawName?: string
  metricGroupSortDefinition?: (string | number)[]
}

export interface BenchmarkMetric extends BenchmarkMetricBase {
  id: string | number
  name: string
  definition: string
  bands?: string[]
  // TODO: Visibility action config (add to submode dimensions, and controls reducer too)
  // TODO: Remove once all mock data is in backend
  useMockData?: boolean
}

export type BenchmarkLob = BenchmarkDefBase

export type BenchmarkUiSettings = UiSettingsDefBase

export type BenchmarkDataRefreshStatusSummary = {
  name: string
  approxCompaniesFiledCount: number
  approxTotalCompaniesCount: number
  approxCompaniesFiledPercentage: number
  approxNpeCompaniesFiledPercentage: number
}

export type BenchmarkDataRefreshStatus = {
  year: number
  summary: BenchmarkDataRefreshStatusSummary[]
}

export type BenchmarkEntityPeersComparison = {
  hasChanges: boolean
  peersAdded: string[]
  peersRemoved: string[]
}

export interface BenchmarkPeer extends BenchmarkCompany {
  years?: number[]
  exclude?: boolean
}
export interface BenchmarkPeerSet extends HasID, HasName, HasMode {
  entityID?: string | number
  description?: string
  type: BenchmarkPeerSetType
  // @ts-ignore
  peers: BenchmarkPeer[]
  global?: boolean
  isComposite: boolean
  hidden?: boolean
  amBestNumber?: number
  filerType?: number
  isAmBestGroup?: boolean
}

export interface ExcludePeerset {
  id: string | number
  value?: boolean
}

export interface BenchmarkTargetValuesByColumn {
  label: string
  format: 'ratio' | 'shortNumber' | 'percent'
  width?: string
  weight?: string
  whenMetricID?: string | number
  unlessMetricID?: string | number
}

export interface BenchmarkTargetValuesTable {
  cols: BenchmarkTargetValuesByColumn[]
  rows: Record<string | number, number[]>
}

export type BenchmarkChartOptionsDict = Record<
  string | number,
  Record<string | number, Partial<GraphingColorScaleOptions>>
>

export const BENCHMARK_US_STATE_POPULATION_METRIC_ID = 99999901
export const BENCHMARK_US_STATE_POPULATION_ENTITY_ID = 'us_state_populations'
export const BENCHMARK_US_COUNTRYWIDE_DPW_METRIC_ID = 124055
export const BENCHMARK_US_MPL_DPW_METRIC_ID = 126780
export const BENCHMARK_INDUSTRY_ENTITY_ID = 'I1'
export const BENCHMARK_INDUSTRY_ENTITY_NAME = 'P&C Industry'

export const BENCHMARK_COMPOSITE_TARGET_KEY = '__target_composite'

export function initBenchmarkPeer(): BenchmarkPeer {
  return {
    id: '',
    mode: 'us',
    name: '',
    groupCount: 0,
    groupID: '',
    groupName: '',
    level: 1,
    subGroupCount: 0,
    term: '',
  }
}

export interface ReFetchModel {
  submode: string
  id: BenchmarkOptionKey | string
  value: string | boolean
  refetchOnChange?: boolean
}

export interface BenchmarkColorScaleByTargetMetricChange {
  target: string | number
  metric: string | number
  colorScale: Partial<GraphingColorScaleOptions>
}

export interface BenchmarkDisplayWarningSettings {
  show: boolean
  currDataYear?: number
  prevDataYear?: number
  warningMessage?: string
}

export interface CompositeMember {
  compositeId: string
  compositeName: string
  memberEntityId: string
  memberName: string
  year: number
}

export type SpendAndEfficiencyRow = {
  lobName: string
  currency?: string

  // Premiums Earned
  grossLatestDataYearPremiumsEarned?: number
  grossPrevDataYearPremiumsEarned?: number
  grossGrowth?: number

  cededLatestDataYearPremiumsEarned?: number
  cededPrevDataYearPremiumsEarned?: number
  cededGrowth?: number

  netLatestDataYearPremiumsEarned?: number
  netPrevDataYearPremiumsEarned?: number
  netGrowth?: number

  cpwToUnaffiliatesLatestDataYear?: number
  cpwToUnaffiliatesPrevDataYear?: number
  cpwToUnaffiliatesGrowth?: number

  // Loss and Lae
  grossLatestDataYearLossAndLae?: number
  grossPrevDataYearLossAndLae?: number
  grossWeightedAvg?: number
  grossStdDev?: number

  cededLatestDataYearLossAndLae?: number
  cededPrevDataYearLossAndLae?: number
  cededWeightedAvg?: number
  cededStdDev?: number

  netLatestDataYearLossAndLae?: number
  netPrevDataYearLossAndLae?: number
  netWeightedAvg?: number
  netStdDev?: number

  // Volatility Reduction = (Net Std Dev / Gross Std Dev) - 1
  volatilityReduction?: number
}

export const TOTAL_ALL_LINES = 'Total All Lines'
