import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-portfolio-tail-metrics',
  styleUrls: ['./optimization-portfolio-tail-metrics.component.scss'],
  templateUrl: './optimization-portfolio-tail-metrics.component.html',
})
export class OptimizationPortfolioTailMetricsComponent {
  @Input() lossFilters: LossFilter[] | undefined
  @Input() returnPeriodToggle: PortfolioMetrics

  @Output() returnPeriodToggleChange = new EventEmitter<PortfolioMetrics>()

  onPeriodSelected(returnPeriod: number) {
    this.returnPeriodToggleChange.emit({
      ...this.returnPeriodToggle,
      returnPeriod1: returnPeriod,
    })
  }

  onPortfolioTypeChange(portfolioType: PortfolioType) {
    this.returnPeriodToggleChange.emit({
      ...this.returnPeriodToggle,
      portfolioType,
    })
  }

  onPerspectiveChange(perspective: Perspective) {
    this.returnPeriodToggleChange.emit({
      ...this.returnPeriodToggle,
      perspective,
    })
  }

  onAggregationMethodChange(aggregationMethod: AggregationMethodType) {
    this.returnPeriodToggleChange.emit({
      ...this.returnPeriodToggle,
      aggregationMethod,
    })
  }

  onLossFilterClick(lossFilter: string) {
    this.returnPeriodToggleChange.emit({
      ...this.returnPeriodToggle,
      lossFilter,
    })
  }
}
