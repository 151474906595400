import { LoadedLossSet, Metadata, Metrics } from 'src/app/api/analyzere/analyzere.model'
import { LossSetLayer } from '../model/loss-set-layers.model'
import {
  LossDistributionRow,
  CombinedLossDistributionRow,
} from './../../core/model/loss-set-table.model'
import { AggregationMethodType, VaRTVaR} from './../model/metrics.model'
import { ExploreFilterMap, ExploreSummaryDatum, GroupCovariance, GroupSummaryRequest, MappingOption, SummaryArrayDatum, SummaryDataResponse, SummaryTableColorPallette } from './explore.model'
import { currencySymbol, isLoadedLossSet } from '../model/layers.util'
import { ModifierState } from './store/explore.reducer'
import { ApiResponse } from 'src/app/api/model/api.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { SortTableRow } from '@shared/sort-table/sort-table.model'

export function convertCombinedLossDistributionTable(
  combinedTable: CombinedLossDistributionRow[],
  aggregationMethod: AggregationMethodType
): LossDistributionRow[] {
  return combinedTable.map(row => {
    if (aggregationMethod === 'OEP') {
      return {
        returnPeriod: row.returnPeriod,
        value: row.oepValue,
        varianceValue: row.oepVarianceValue,
        lossRatioValue: row.oepLossRatioValue,
        lossRatioVarianceValue: row.oepLossRatioVarianceValue,
      }
    } else {
      return {
        returnPeriod: row.returnPeriod,
        value: row.aepValue,
        varianceValue: row.aepVarianceValue,
        lossRatioValue: row.aepLossRatioValue,
        lossRatioVarianceValue: row.aepLossRatioVarianceValue,
      }
    }
  })
}

export function buildSummaryFilterMap(
  lossSetLayers: LossSetLayer[],
  summaryFilterOptions: MappingOption[]
): ExploreFilterMap {
  const summaryFilterMap: ExploreFilterMap = {}
  const layers = lossSetLayers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
      ? layer.meta_data.originalPremium
      : layer.premium?.value || 0
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
  summaryFilterOptions.forEach(filterOption => {
    const value = filterOption.value
    const optionValue = value as keyof Metadata
    layers.forEach(layer => {
      const option = String(layer.meta_data[optionValue])
        const filterKey = `${value}~${option}`
        if (!(filterKey in summaryFilterMap)) {
          summaryFilterMap[filterKey] = true
        }
    })
  })
  return summaryFilterMap
}

export function getMetaValuesForGroupedSummaryData(metaData: Partial<Metadata>, modelingArray: string[]) {
  const meta_data:
  {
    loss_type: string,
    ls_dim1: string,
    ls_dim2: string,
    map1: string,
    map2: string,
    map3: string,
    map4: string,
    map5: string,
    premiumScaleFactor: string,
    lossScaleFactor: string
  } = {
    loss_type: '',
    ls_dim1: '',
    ls_dim2: '',
    map1: '',
    map2: '',
    map3: '',
    map4: '',
    map5: '',
    premiumScaleFactor: '',
    lossScaleFactor: ''
  }
  const groupByValues: string[] = []
  modelingArray.forEach((key) => {
    if (key && key in metaData) {
      meta_data[key as keyof typeof meta_data] = String(metaData[key as keyof Metadata]).replace(/_/g, '*')
      groupByValues.push(String(metaData[key as keyof Metadata]))
    }
  })
  const groupBy = groupByValues.join('/')
  return {
    meta_data,
    groupBy
  }
}

export function getLossRatio(loss: number, premium: number): number {
  return loss / premium
}

export function getRowsFromGroupSummaryData(
  groupSummaryData: SummaryDataResponse[],
  summaryData:SummaryDataResponse[],
  modelingArray: string[],
  groups: GroupSummaryRequest[],

): ExploreSummaryDatum[] {
  const lslRows: ExploreSummaryDatum[] = []
  groupSummaryData.forEach((data, i) => {
    const group = groups[i]
    const premium = data.subjectPremiumAmt
    const expense = data.expense ?? 0
    const expenseRatio = expense / premium
    const lossByType_Attritional = data.expectedLossAttr
    const lossByType_Large = data.expectedLossLarge
    const lossByType_Cat = data.expectedLossCat
    const expectedLoss = [
      lossByType_Attritional,
      lossByType_Large,
      lossByType_Cat,
    ].reduce((sum, loss) => sum + loss, 0)
    const expectedUWResult = premium - expense - expectedLoss
    let expectedCombinedRatio = 0
    let totalLossRatioCV = Math.sqrt(data.covariance) / expectedLoss
    const nonZeroPremium = premium > 0
    if (nonZeroPremium) {
      expectedCombinedRatio = getCombinedRatio(expectedUWResult, premium)
    }
    const lossRatioCV_Attritional = Math.sqrt(data.expectedLossVarianceAttr) / data.expectedLossAttr
    const lossRatioCV_Large = Math.sqrt(data.expectedLossVarianceLarge) / data.expectedLossLarge
    const lossRatioCV_Cat = Math.sqrt(data.expectedLossVarianceCat) / data.expectedLossCat
    const layers = getLayerScaleFactors(group.layers)

    const layerValues = getMetaValuesForGroupedSummaryData(layers[0].meta_data, modelingArray)

    const {
      loss_type,
      ls_dim1,
      ls_dim2,
      map1,
      map2,
      map3,
      map4,
      map5,
      premiumScaleFactor,
      lossScaleFactor
    } = layerValues.meta_data

    const lossTypeData = getTypeTotals(layers, summaryData)
    const nameSplit = group.name.split('_')
    const tabLength = nameSplit.length - 1
    const tabs = '    '.repeat(tabLength)
    const hasLarge = data.largeRiskFrequency > 0
    lslRows.push({
      id: group.ids.join(','),
      name: group.name,
      groupBy: tabs + nameSplit[tabLength],
      loss_type: loss_type,
      ls_dim1: ls_dim1,
      ls_dim2: ls_dim2,
      map1: map1,
      map2: map2,
      map3: map3,
      map4: map4,
      map5: map5,
      lossScaleFactor,
      premiumScaleFactor,
      premium,
      expense,
      lossByType_Attritional: data.expectedLossAttr,
      lossByType_Large: data.expectedLossLarge,
      lossByType_Cat: data.expectedLossCat,
      expectedLoss,
      expectedUWResult,
      expenseRatio,
      lossRatioByType_Attritional: nonZeroPremium ? getLossRatio(data.expectedLossAttr, premium) : 0,
      lossRatioByType_Large: nonZeroPremium ? getLossRatio(data.expectedLossLarge, premium) : 0,
      lossRatioByType_Cat: nonZeroPremium ? getLossRatio(data.expectedLossCat, premium) : 0,
      expectedLossRatio: [
        data.expectedLossAttr,
        data.expectedLossLarge,
        data.expectedLossCat
      ].reduce((sum, loss) => sum + getLossRatio(loss, premium), 0),
      expectedCombinedRatio,
      lossRatioCV_Attritional,
      lossRatioCV_Large,
      lossRatioCV_Cat,
      totalLossRatioCV,
      largeRiskFrequency: data.largeRiskFrequency,
      largeRiskSeverity: hasLarge ? lossTypeData.lossByType_Large / data.largeRiskFrequency : 0,
      largeRiskDistributionOEP_10: hasLarge ? data.largeRiskDistributionOEP?.data[0]?.min : 0,
      largeRiskDistributionOEP_25: hasLarge ? data.largeRiskDistributionOEP?.data[1]?.min : 0,
      largeRiskDistributionOEP_50: hasLarge ? data.largeRiskDistributionOEP?.data[2]?.min : 0,
      largeRiskDistributionOEP_100: hasLarge ? data.largeRiskDistributionOEP?.data[3]?.min : 0,
      largeRiskDistributionOEP_200: hasLarge ? data.largeRiskDistributionOEP?.data[4]?.min : 0,
      largeRiskDistributionOEP_250: hasLarge ? data.largeRiskDistributionOEP?.data[5]?.min : 0,
      largeRiskDistributionAEP_10: hasLarge ? data.largeRiskDistributionAEP?.data[0]?.min : 0,
      largeRiskDistributionAEP_25: hasLarge ? data.largeRiskDistributionAEP?.data[1]?.min : 0,
      largeRiskDistributionAEP_50: hasLarge ? data.largeRiskDistributionAEP?.data[2]?.min : 0,
      largeRiskDistributionAEP_100: hasLarge ? data.largeRiskDistributionAEP?.data[3]?.min : 0,
      largeRiskDistributionAEP_200: hasLarge ? data.largeRiskDistributionAEP?.data[4]?.min : 0,
      largeRiskDistributionAEP_250: hasLarge ? data.largeRiskDistributionAEP?.data[5]?.min : 0,
      uwResultVaR_10: data.uwResultVaR?.data[0]?.min ? data?.uwResultVaR?.data[0].min * -1 : 0,
      uwResultVaR_25: data.uwResultVaR?.data[1]?.min ? data?.uwResultVaR?.data[1].min * -1 : 0,
      uwResultVaR_50: data.uwResultVaR?.data[2]?.min ? data?.uwResultVaR?.data[2].min * -1 : 0,
      uwResultVaR_100: data.uwResultVaR?.data[3]?.min ? data?.uwResultVaR?.data[3].min * -1 : 0,
      uwResultVaR_200: data.uwResultVaR?.data[4]?.min ? data?.uwResultVaR?.data[4].min * -1 : 0,
      uwResultVaR_250: data.uwResultVaR?.data[5]?.min ? data?.uwResultVaR?.data[5].min * -1 : 0,
      combinedRatiosVaR_10: getCombinedRatio(data.uwResultVaR?.data[0]?.min ? data?.uwResultVaR?.data[0].min * -1 : 0, premium),
      combinedRatiosVaR_25: getCombinedRatio(data.uwResultVaR?.data[1]?.min ? data?.uwResultVaR?.data[1].min * -1 : 0, premium),
      combinedRatiosVaR_50: getCombinedRatio(data.uwResultVaR?.data[2]?.min ? data?.uwResultVaR?.data[2].min * -1 : 0, premium),
      combinedRatiosVaR_100: getCombinedRatio(data.uwResultVaR?.data[3]?.min ? data?.uwResultVaR?.data[3].min * -1 : 0, premium),
      combinedRatiosVaR_200: getCombinedRatio(data.uwResultVaR?.data[4]?.min ? data?.uwResultVaR?.data[4].min * -1 : 0, premium),
      combinedRatiosVaR_250: getCombinedRatio(data.uwResultVaR?.data[5]?.min ? data?.uwResultVaR?.data[5].min * -1 : 0, premium),
      contributionToGroupVolatility_Attritional: data.contributionToGroupVolatilityAttr,
      contributionToGroupVolatility_Large: data.contributionToGroupVolatilityLarge,
      contributionToGroupVolatility_Cat: data.contributionToGroupVolatilityCat,
      totalContributionToGroupVolatility: data.contributionToGroupVolatility,
      rowLayer: `Level ${tabLength}`
    })
  })
  return lslRows
}

export function getRowsFromLossSets(
  lossSetLayers: LossSetLayer[],
  summaryData: SummaryDataResponse[],
  modelingArray: string[]
): ExploreSummaryDatum[] {
  const lslRows: ExploreSummaryDatum[] = []
  lossSetLayers.forEach(layer => {
    const summary = summaryData.filter(s => s.lossSetID === layer.id)[0]
    const {
      loss_type,
      ls_dim1,
      ls_dim2,
      map1,
      map2,
      map3,
      map4,
      map5,
      lossScaleFactor,
      premiumScaleFactor
    } = layer.meta_data
    const premium = layer.premium?.value ?? 0
    const expense = summary?.expense ?? 0
    const expenseRatio = expense / premium
    const expectedLoss = summary?.netLoss ?? 0
    const expectedUWResult = premium - expense - expectedLoss
    let expectedLossRatio = 0
    let expectedCombinedRatio = 0
    const totalLossRatioCV = Math.sqrt(summary?.covariance) / expectedLoss
    if (premium > 0) {
      expectedLossRatio = expectedLoss / premium
      expectedCombinedRatio = getCombinedRatio(expectedUWResult, premium)
    }

    const lossTypeData = getLossByLayerType(layer, summary, expectedLossRatio, totalLossRatioCV)
    const isLarge = layer.meta_data.loss_type === 'large'
    lslRows.push({
      id: layer.id,
      name: layer.description,
      groupBy: '',
      loss_type: loss_type,
      ls_dim1: ls_dim1,
      ls_dim2: ls_dim2,
      map1: map1,
      map2: map2,
      map3: map3,
      map4: map4,
      map5: map5,
      lossScaleFactor,
      premiumScaleFactor,
      premium,
      expense,
      lossByType_Attritional: lossTypeData.lossByType_Attritional,
      lossByType_Large: lossTypeData.lossByType_Large,
      lossByType_Cat: lossTypeData.lossByType_Cat,
      expectedLoss,
      expectedUWResult,
      expenseRatio,
      lossRatioByType_Attritional: lossTypeData.lossRatioByType_Attritional,
      lossRatioByType_Large: lossTypeData.lossRatioByType_Large,
      lossRatioByType_Cat: lossTypeData.lossRatioByType_Cat,
      expectedLossRatio,
      expectedCombinedRatio,
      lossRatioCV_Attritional: lossTypeData.lossRatioCV_Attritional,
      lossRatioCV_Large: lossTypeData.lossRatioCV_Large,
      lossRatioCV_Cat: lossTypeData.lossRatioCV_Cat,
      totalLossRatioCV,
      largeRiskFrequency: 0,
      largeRiskSeverity: 0,
      largeRiskDistributionOEP_10: isLarge ? summary?.largeRiskDistributionOEP?.data[0]?.min ?? 0 : 0,
      largeRiskDistributionOEP_25: isLarge ? summary?.largeRiskDistributionOEP?.data[1]?.min ?? 0 : 0,
      largeRiskDistributionOEP_50: isLarge ? summary?.largeRiskDistributionOEP?.data[2]?.min ?? 0 : 0,
      largeRiskDistributionOEP_100: isLarge ? summary?.largeRiskDistributionOEP?.data[3]?.min ?? 0 : 0,
      largeRiskDistributionOEP_200: isLarge ? summary?.largeRiskDistributionOEP?.data[4]?.min ?? 0 : 0,
      largeRiskDistributionOEP_250: isLarge ? summary?.largeRiskDistributionOEP?.data[5]?.min ?? 0 : 0,
      largeRiskDistributionAEP_10: isLarge ? summary?.largeRiskDistributionAEP?.data[0]?.min ?? 0 : 0,
      largeRiskDistributionAEP_25: isLarge ? summary?.largeRiskDistributionAEP?.data[1]?.min ?? 0 : 0,
      largeRiskDistributionAEP_50: isLarge ? summary?.largeRiskDistributionAEP?.data[2]?.min ?? 0 : 0,
      largeRiskDistributionAEP_100: isLarge ? summary?.largeRiskDistributionAEP?.data[3]?.min ?? 0 : 0,
      largeRiskDistributionAEP_200: isLarge ? summary?.largeRiskDistributionAEP?.data[4]?.min ?? 0 : 0,
      largeRiskDistributionAEP_250: isLarge ? summary?.largeRiskDistributionAEP?.data[5]?.min ?? 0 : 0,
      uwResultVaR_10: summary.uwResultVaR?.data[0]?.min ? summary?.uwResultVaR?.data[0].min * -1 : 0,
      uwResultVaR_25: summary.uwResultVaR?.data[1]?.min ? summary?.uwResultVaR?.data[1].min * -1 : 0,
      uwResultVaR_50: summary.uwResultVaR?.data[2]?.min ? summary?.uwResultVaR?.data[2].min * -1 : 0,
      uwResultVaR_100: summary.uwResultVaR?.data[3]?.min ? summary?.uwResultVaR?.data[3].min * -1 : 0,
      uwResultVaR_200: summary.uwResultVaR?.data[4]?.min ? summary?.uwResultVaR?.data[4].min * -1 : 0,
      uwResultVaR_250: summary.uwResultVaR?.data[5]?.min ? summary?.uwResultVaR?.data[5].min * -1 : 0,
      combinedRatiosVaR_10: getCombinedRatio(summary.uwResultVaR?.data[0]?.min ? summary?.uwResultVaR?.data[0].min * -1 : 0, premium),
      combinedRatiosVaR_25: getCombinedRatio(summary.uwResultVaR?.data[1]?.min ? summary?.uwResultVaR?.data[1].min * -1 : 0, premium),
      combinedRatiosVaR_50: getCombinedRatio(summary.uwResultVaR?.data[2]?.min ? summary?.uwResultVaR?.data[2].min * -1 : 0, premium),
      combinedRatiosVaR_100: getCombinedRatio(summary.uwResultVaR?.data[3]?.min ? summary?.uwResultVaR?.data[3].min * -1 : 0, premium),
      combinedRatiosVaR_200: getCombinedRatio(summary.uwResultVaR?.data[4]?.min ? summary?.uwResultVaR?.data[4].min * -1 : 0, premium),
      combinedRatiosVaR_250: getCombinedRatio(summary.uwResultVaR?.data[5]?.min ? summary?.uwResultVaR?.data[5].min * -1 : 0, premium),
    })
  })
  return styleRows(lslRows, modelingArray)
}

export function formatSummaryRows(rows: ExploreSummaryDatum[], currency: string, modelingArray: string[], abrev: 'M' | 'K'): ExploreSummaryDatum[] {
  return styleRows(rows.map(row => {
    const groupOne = !row.name.includes('_') && row.groupBy && row.groupBy !== ''
    return {
      ...row,
      name: row.name.replace(/\*/g, '_'),
      groupBy: row.groupBy.replace(/\*/g, '_'),
      premium: getDisplayValue(row.premium, "currency", abrev, currency, groupOne),
      expense: getDisplayValue(row.expense, "currency", abrev, currency, groupOne),
      lossByType_Attritional: getDisplayValue(row.lossByType_Attritional, "currency", abrev, currency, groupOne),
      lossByType_Large: getDisplayValue(row.lossByType_Large, "currency", abrev, currency, groupOne),
      lossByType_Cat: getDisplayValue(row.lossByType_Cat, "currency", abrev, currency, groupOne),
      expectedLoss: getDisplayValue(row.expectedLoss, "currency", abrev, currency, groupOne),
      expectedUWResult: getDisplayValue(row.expectedUWResult, "currency", abrev, currency, groupOne),
      lossRatioCV_Attritional: getDisplayValue(Number(row.lossRatioCV_Attritional)),
      lossRatioCV_Large: getDisplayValue(Number(row.lossRatioCV_Large)),
      lossRatioCV_Cat: getDisplayValue(Number(row.lossRatioCV_Cat)),
      totalLossRatioCV: getDisplayValue(Number(row.totalLossRatioCV)),
      largeRiskFrequency: Number(row.largeRiskFrequency) > 0 ? Number(row.largeRiskFrequency).toFixed(1) : '-',
      largeRiskSeverity: getDisplayValue(Number(row.largeRiskSeverity), "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_10: getDisplayValue(row.largeRiskDistributionOEP_10, "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_25: getDisplayValue(row.largeRiskDistributionOEP_25, "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_50: getDisplayValue(row.largeRiskDistributionOEP_50, "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_100: getDisplayValue(row.largeRiskDistributionOEP_100, "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_200: getDisplayValue(row.largeRiskDistributionOEP_200, "currency", abrev, currency, groupOne),
      largeRiskDistributionOEP_250: getDisplayValue(row.largeRiskDistributionOEP_250, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_10: getDisplayValue(row.largeRiskDistributionAEP_10, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_25: getDisplayValue(row.largeRiskDistributionAEP_25, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_50: getDisplayValue(row.largeRiskDistributionAEP_50, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_100: getDisplayValue(row.largeRiskDistributionAEP_100, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_200: getDisplayValue(row.largeRiskDistributionAEP_200, "currency", abrev, currency, groupOne),
      largeRiskDistributionAEP_250: getDisplayValue(row.largeRiskDistributionAEP_250, "currency", abrev, currency, groupOne),
      uwResultVaR_10: getDisplayValue(row.uwResultVaR_10, "currency", abrev, currency, groupOne),
      uwResultVaR_25: getDisplayValue(row.uwResultVaR_25, "currency", abrev, currency, groupOne),
      uwResultVaR_50: getDisplayValue(row.uwResultVaR_50, "currency", abrev, currency, groupOne),
      uwResultVaR_100: getDisplayValue(row.uwResultVaR_100, "currency", abrev, currency, groupOne),
      uwResultVaR_200: getDisplayValue(row.uwResultVaR_200, "currency", abrev, currency, groupOne),
      uwResultVaR_250: getDisplayValue(row.uwResultVaR_250, "currency", abrev, currency, groupOne),
    }
  }), modelingArray)
}

export function styleRows(rows: SortTableRow<ExploreSummaryDatum>[], modelingArray: string[]): SortTableRow<ExploreSummaryDatum>[] {
  const lastLevel = modelingArray.length - 1
  return rows.map(r => {
    let style: Record<string, string> = {
      backgroundColor: 'black',
      color: '#FFFFFF',
      borderBottomColor: 'var(--subtle)',
      paddingRight: '0px'
    }
    let index = 10
    if (r.rowLayer) {
      index = Number(String(r.rowLayer).replace('Level ', ''))
      if (index > 0 && index === lastLevel) {
        index = 4
      }
      if (index < 5) {
        const color = index === 0 ? '#FFFFFF' : '#000000'
        const fontWeight = index === 0 ? 'bold' : 'normal'
        style = {
          ...style,
          backgroundColor: SummaryTableColorPallette[index],
          borderBottomColor: 'var(--subtle)',
          color,
          fontWeight
        }
      }
    }
    return {
      ...r,
      style
    }
  })
}

export function getGroupedSummaryData(
  groupSummaryData: SummaryDataResponse[],
  summaryData:SummaryDataResponse[],
  modelingArray: string[],
  groups: GroupSummaryRequest[],
  showIndividualLossSets: boolean,
  data: ExploreSummaryDatum[],
  currency: string,
  layers: LossSetLayer[],
  abrev: 'K' | 'M',
  isExport?: boolean
): ExploreSummaryDatum[] {
  const model = modelingArray.filter(x => x !== '')
  let result = getRowsFromGroupSummaryData(groupSummaryData, summaryData, modelingArray, groups).sort((a, b) => a.name.localeCompare(b.name))
  if (showIndividualLossSets) {
    result = insertIndividualLossSets(data, model, result)
  }
  if (isExport) {
    return result
  }
  return formatSummaryRows(result, currency, modelingArray, abrev)
}

function insertIndividualLossSets(
  data: ExploreSummaryDatum[],
  modelingArray: string[],
  result: ExploreSummaryDatum[],
): ExploreSummaryDatum[] {
  const lastLevel = modelingArray.length
  let finalResult: ExploreSummaryDatum[] = []
  result.forEach((groupedRow) => {
    const groupParts = groupedRow.name.split('_')
    finalResult.push(groupedRow)
    if (groupParts.length === lastLevel) {
      const matchingRows = data.filter(d => groupedRow.id.includes(d.id)).sort((a, b) => a.name.localeCompare(b.name))
      matchingRows.forEach(row => {
        const tab = '    '.repeat(lastLevel)
        finalResult.push({
          ...row,
          name: row.name,
          groupBy: tab + groupParts[lastLevel - 1].replace(/\*/g, '_'),
          rowLayer: 'Level 5'
        })
      })
    }
  })
  return finalResult
}

export function extractFilterMapKeys(filterMap: Record<string, boolean>, split: string): Record<string, string[]> {
  const result: Record<string, string[]> = {}

  Object.keys(filterMap).forEach(key => {
    const parts = key.split(split)
    const mapKey = parts.slice(0, parts.length - 1).join(split)
    const mapValue = parts[parts.length - 1]
    if (filterMap[key]) {
      if (!result[mapKey]) {
        result[mapKey] = []
      }
      result[mapKey].push(mapValue)
    }
  })
  return result
}

export function getTypeTotals(
  layers: LossSetLayer[],
  summaryData: SummaryDataResponse[]
): {
  lossByType_Attritional: number,
  lossByType_Large: number,
  lossByType_Cat: number,
  lossRatioByType_Attritional: number,
  lossRatioByType_Large: number,
  lossRatioByType_Cat: number,
  lossRatioCV_Attritional: number,
  lossRatioCV_Large: number,
  lossRatioCV_Cat: number,
}  {
  let totals = {
    lossByType_Attritional: 0,
    lossByType_Large: 0,
    lossByType_Cat: 0,
    lossRatioByType_Attritional: 0,
    lossRatioByType_Large: 0,
    lossRatioByType_Cat: 0,
    lossRatioCV_Attritional: 0,
    lossRatioCV_Large: 0,
    lossRatioCV_Cat: 0,
  }
  layers.forEach(layer => {
    const summary = summaryData.filter(s => s.lossSetID === layer.id)[0]
    const premium = layer.premium?.value ?? 0
    const expectedLoss = summary?.netLoss ?? 0
    const aep100 = summary?.largeRiskDistributionAEP.data[6]
    let expectedLossRatio = 0
    let totalLossRatioCV = 0
    totalLossRatioCV = (Math.sqrt(aep100.variance) / aep100.mean)
    if (premium > 0) {
      expectedLossRatio = expectedLoss / premium
    }
    const typeData = getLossByLayerType(layer, summary, expectedLossRatio, totalLossRatioCV)
    totals = {
      lossByType_Attritional: totals.lossByType_Attritional += typeData.lossByType_Attritional,
      lossByType_Large: totals.lossByType_Large += typeData.lossByType_Large,
      lossByType_Cat: totals.lossByType_Cat += typeData.lossByType_Cat,
      lossRatioByType_Attritional: totals.lossRatioByType_Attritional += typeData.lossRatioByType_Attritional,
      lossRatioByType_Large: totals.lossRatioByType_Large += typeData.lossRatioByType_Large,
      lossRatioByType_Cat: totals.lossRatioByType_Cat += typeData.lossRatioByType_Cat,
      lossRatioCV_Attritional: totals.lossRatioCV_Attritional += typeData.lossRatioCV_Attritional,
      lossRatioCV_Large: totals.lossRatioCV_Large += typeData.lossRatioCV_Large,
      lossRatioCV_Cat: totals.lossRatioCV_Cat += typeData.lossRatioCV_Cat,
    }
  })

  return totals
}

export function getLossByLayerType(
  layer: LossSetLayer,
  summary: SummaryDataResponse,
  expectedLossRatio: number,
  totalLossRatioCV: number
): {
  lossByType_Attritional: number,
  lossByType_Large: number,
  lossByType_Cat: number,
  lossRatioByType_Attritional: number,
  lossRatioByType_Large: number,
  lossRatioByType_Cat: number,
  lossRatioCV_Attritional: number,
  lossRatioCV_Large: number,
  lossRatioCV_Cat: number,
}  {
  let data = {
    lossByType_Attritional: 0,
    lossByType_Large: 0,
    lossByType_Cat: 0,
    lossRatioByType_Attritional: 0,
    lossRatioByType_Large: 0,
    lossRatioByType_Cat: 0,
    lossRatioCV_Attritional: 0,
    lossRatioCV_Large: 0,
    lossRatioCV_Cat: 0,
  }
  const { loss_type } = layer.meta_data
  switch (loss_type) {
    case 'large':
      data = {
        ...data,
        lossByType_Large: summary?.netLoss ?? 0,
        lossRatioByType_Large: expectedLossRatio,
        lossRatioCV_Large: totalLossRatioCV,
      }
      break;
    case 'cat':
      data = {
        ...data,
        lossByType_Cat: summary?.netLoss ?? 0,
        lossRatioByType_Cat: expectedLossRatio,
        lossRatioCV_Cat: totalLossRatioCV,
      }
      break;
    case 'attr':
      data = {
        ...data,
        lossByType_Attritional: summary?.netLoss ?? 0,
        lossRatioByType_Attritional: expectedLossRatio,
        lossRatioCV_Attritional: totalLossRatioCV,
      }
      break;
    default:
      break;
  }

  return data
}

export function getSummaryResponse(
  layers: {
    lossID: string
    viewID: string
    lossName: string
    lossType: string
    filterValue: string
    isLossRatioView?: boolean
    subjectPremiumAmt?: number
    largeFrequencyLayerViewID?: string
  }[],
  response: any,
  isGroup?: boolean
): SummaryDataResponse[] {
  const summaryData: SummaryDataResponse[] = []
  const mainLayers = layers?.filter(layer => {
    const isGroupVariance = isGroup && layer.lossName.includes('variance')
    return !isGroupVariance
  })
  const dataLength = 5 + (isGroup ? 1 : 0)
  const mainDataLength = dataLength * mainLayers.length
  const varianceResponses = response.filter((r: any) => !r.data.largeFrequency).slice(mainDataLength)
  const groupCVs: GroupCovariance[] = []
  let totalGroup1CV = 0
  response.filter((r: any) => !r.data.largeFrequency).forEach((res: any, i: number) => {
    if (i < mainDataLength) {
      const compCV = res.data.groupComponentCovariance ?? null
      const layerName = response[i - 2]?.data?.lossName ?? null
      if (compCV && layerName) {
        groupCVs.push({
          layerName: String(layerName),
          covariance: compCV.component_metrics.covariance,
        })
      }
    }
  })
  let frequencyResponses: any[] = []
  if (isGroup) {
    if (groupCVs.length > 0) {
      groupCVs.filter(cv => !cv.layerName.includes('_')).forEach(cv => {
        totalGroup1CV += cv.covariance
      })
    }
    frequencyResponses = response.filter((res: any) => !!res.data.largeFrequency)
  }
  mainLayers.forEach((layer, i) => {
    const start = i * dataLength
    const end = (i + 1) * dataLength
    const res = response.filter((r: any) => !r.data.largeFrequency).slice(start, end)
    const lossSetID = layer.lossID
    const lossName = String(res[0].data.expense.lossName)
    const expense = res[0].data.expense.mean ?? 0
    const netLoss = res[1].data.mean ?? 0
    const oep = res[2].data
    const aep = res[3].data
    const uw = res[4].data
    const subjectPremiumAmt = layer.subjectPremiumAmt
    const covariance = res[0].data.cv.variance ?? 0
    let largeRiskFrequency = 0
    let largeRiskSeverity = 0
    let contributionToGroupVolatility = 0
    let contributionToGroupVolatilityAttr = 0
    let contributionToGroupVolatilityLarge = 0
    let contributionToGroupVolatilityCat = 0
    let expectedLossAttr = 0
    let expectedLossLarge = 0
    let expectedLossCat = 0
    let expectedLossVarianceAttr = 0
    let expectedLossVarianceLarge = 0
    let expectedLossVarianceCat = 0
    if (isGroup) {
      const isTopGroup = !lossName.includes('_')
      const componentCV = res[5].data.groupComponentCovariance.component_metrics.covariance
      const baseCV = isTopGroup ? totalGroup1CV : groupCVs.find(cv => {
        const nameSplit = lossName.split('_')
        const baseCVLossName = nameSplit.slice(0, nameSplit.length - 1).join('_')
        return baseCVLossName === cv.layerName
      })?.covariance
      contributionToGroupVolatility = componentCV / baseCV
      const attrGroupID = layers.find(l => {
        return l.lossName.includes('attr_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (attrGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === attrGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          contributionToGroupVolatilityAttr = cCV / baseCV
          expectedLossAttr = res.data.typeExpectedLoss.mean
          expectedLossVarianceAttr = res.data.typeExpectedLoss.variance
        }
      }
      const largeGroupID = layers.find(l => {
        return l.lossName.includes('large_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (largeGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === largeGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          contributionToGroupVolatilityLarge = cCV / baseCV
          expectedLossLarge = res.data.typeExpectedLoss.mean
          expectedLossVarianceLarge = res.data.typeExpectedLoss.variance
        }
      }
      const catGroupID = layers.find(l => {
        return l.lossName.includes('cat_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (catGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === catGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          contributionToGroupVolatilityCat = cCV / baseCV
          expectedLossCat = res.data.typeExpectedLoss.mean
          expectedLossVarianceCat = res.data.typeExpectedLoss.variance
        }
      }
      const freqRes = frequencyResponses.find(res => res.viewID === layer.largeFrequencyLayerViewID)
      if (freqRes) {
        largeRiskFrequency = freqRes.data.largeFrequency.mean
        largeRiskSeverity = freqRes.data.expectedLoss.mean / largeRiskFrequency
      }
    }
    const largeRiskDistributionOEP: SummaryArrayDatum = {
      data: [
        oep[0],
        oep[1],
        oep[2],
        oep[3],
        oep[4],
        oep[5],
        oep[6]
      ],
      lossName: oep.lossName,
      lossType: oep.lossType,
      rpArray: oep.rpArray,
      lossFilter: oep.lossFilter,
      aggregationMethod: oep.aggregationMethod
    }
    const largeRiskDistributionAEP: SummaryArrayDatum = {
      data: [
        aep[0],
        aep[1],
        aep[2],
        aep[3],
        aep[4],
        aep[5],
        aep[6]
      ],
      lossName: aep.lossName,
      lossType: aep.lossType,
      rpArray: aep.rpArray,
      lossFilter: aep.lossFilter,
      aggregationMethod: aep.aggregationMethod
    }
    const uwResultVaR: SummaryArrayDatum = {
      data: [
        uw[0],
        uw[1],
        uw[2],
        uw[3],
        uw[4],
        uw[5],
        uw[6]
      ],
      lossName: uw.lossName,
      lossType: uw.lossType,
      rpArray: uw.rpArray,
      lossFilter: uw.lossFilter,
      aggregationMethod: uw.aggregationMethod
    }
    const expectedUWResult = uwResultVaR.data[3].mean
    summaryData.push({
      lossName: uw.lossName,
      lossSetID,
      subjectPremiumAmt,
      expense,
      netLoss,
      largeRiskDistributionOEP,
      largeRiskDistributionAEP,
      uwResultVaR,
      expectedUWResult,
      contributionToGroupVolatility,
      contributionToGroupVolatilityAttr,
      contributionToGroupVolatilityLarge,
      contributionToGroupVolatilityCat,
      expectedLossAttr,
      expectedLossLarge,
      expectedLossCat,
      largeRiskFrequency,
      largeRiskSeverity,
      expectedLossVarianceAttr,
      expectedLossVarianceLarge,
      expectedLossVarianceCat,
      covariance
    })
  })
  return summaryData
}

export function getCombinedRatio(uwResult: number, premium: number): number {
  const value = premium !== 0 ? 1 - (uwResult / premium) : 0
  return value
}

export function getDisplayValue(value: number | string, type?: string, abrev?: 'M' | 'K', currency?: string, groupOne?: boolean): number | string {
  let displayValue: number | string = value
  if (Number(value) !== 0) {
    if (type === 'currency') {
      displayValue = summaryNumberFormat(Number(value), currency, abrev, groupOne)
    } else if (type === 'fixed') {
      displayValue = Number(value).toFixed(1)
    }
  } else {
    return '-'
  }
  return displayValue
}

export function getGroupedSummaryRequest(
  data: ExploreSummaryDatum[],
  modelingArray: string[],
  lossSetLayers: LossSetLayer[]
): GroupSummaryRequest[] {
  const model = modelingArray.filter(x => x !== '')
  const result = organizeGroupRequests(data, 0, model, '')
  return result.map(r => {
    const layers = lossSetLayers.filter(l => r.ids.includes(l.id)) ?? []
    return {
      ...r,
      layers,
      largeFrequencyLayerViewID: ''
    }
  })
}

export function organizeGroupRequests(
  items: ExploreSummaryDatum[],
  groupIndex: number,
  modelingArray: string[],
  previousGroupName: string = ''
): GroupSummaryRequest[] {
  if (groupIndex >= modelingArray.length) return []
  const groupByField = modelingArray[groupIndex]
  const grouped = items.reduce((acc, item) => {
    const groupValue = item[groupByField as keyof ExploreSummaryDatum]
    if (groupValue === undefined) {
      return acc
    }
    const key = String(groupValue).replace(/_/g, '*')
    if (!acc[key]) acc[key] = []
    acc[key].push(item)
    return acc
  }, {} as { [key: string]: ExploreSummaryDatum[] })

  let result: GroupSummaryRequest[] = []

  Object.keys(grouped).forEach(groupKey => {
    const groupItems = grouped[groupKey]
    const groupName = previousGroupName ? `${previousGroupName}_${groupKey}` : groupKey
    const GroupSummaryRequest = getLossSetAggregateRequests(groupItems)
    result.push({
      name: groupName,
      ids: GroupSummaryRequest.ids
    })
    result = result.concat(organizeGroupRequests(groupItems, groupIndex + 1, modelingArray, groupName))
  })
  return result
}

export function getLossSetAggregateRequests(items: ExploreSummaryDatum[]): GroupSummaryRequest {
  const ids = items.map(item => item.id)
  return {
    name: '',
    ids: ids
  }
}

export function getExploreSummaryAPIs(
  lossSetLayers: {
    lossID: string
    viewID: string
    lossName: string
    lossType: string
    filterValue: string
    isLossRatioView?: boolean
    subjectPremiumAmt?: number
    groupType?: string,
    largeFrequencyLayerViewID?: string
  }[],
  service: AnalyzreService,
  portfolioViewID: string,
  arrRP: number[]
): ApiResponse<
    Metrics[] & {
      lossSetID: string
      lossType: string
      lossName: string
      rpArray: number[]
      vartvar: VaRTVaR
      lossFilter: string
      isLossRatioView: boolean
      subjectPremiumAmt: number
      aggregationMethod: string
      mean?: number
    }
>[] {
  const exploreApis: ApiResponse<
  Metrics[] & {
      lossSetID: string
      lossType: string
      lossName: string
      rpArray: number[]
      vartvar: VaRTVaR
      lossFilter: string
      isLossRatioView: boolean
      subjectPremiumAmt: number
      aggregationMethod: string
      mean?: number
    }
  >[] = []
  lossSetLayers?.forEach(l => {
    let largeViewID = l.viewID
    if (l.groupType && l.groupType === 'View') {
      lossSetLayers.forEach(loss => {
        const name = loss.lossName.split('~')[0]
        if (
          loss.groupType &&
          loss.groupType === 'Variance' &&
          name === l.lossName &&
          loss.lossName.includes('large')
        ) {
          largeViewID = loss.viewID
        }
      })
    }
    if (l.viewID) {
      const oepMetrics = service.getExploreDataValues(
        largeViewID,
        arrRP,
        l.lossType,
        'Loss',
        'OEP',
        'VaR',
        l.filterValue ?? 'all',
        l.lossID,
        l.lossName,
        false,
        l.subjectPremiumAmt || 0,
        true
      )
      const aepMetrics = service.getExploreDataValues(
        largeViewID,
        arrRP.concat([1]),
        l.lossType,
        'Loss',
        'AEP',
        'VaR',
        l.filterValue ?? 'all',
        l.lossID,
        l.lossName,
        false,
        l.subjectPremiumAmt || 0,
        true
      )
      const UWMetrics = service.getExploreDataValues(
        l.viewID,
        arrRP.concat([1]),
        l.lossType,
        'UWSummary',
        'AEP',
        'VaR',
        l.filterValue ?? 'all',
        l.lossID,
        l.lossName,
        false,
        l.subjectPremiumAmt || 0,
        true
      )
      const expensePremium = service.getExpensePremium(l.viewID, l.lossType)
      const netLoss = service.getExpectedNetLoss(l.viewID, l.lossType)
      if(l.groupType !== 'Variance') {
        exploreApis.push(expensePremium)
        exploreApis.push(netLoss)
        exploreApis.push(oepMetrics)
        exploreApis.push(aepMetrics)
        exploreApis.push(UWMetrics)
      }
      if (l.lossType === 'Group') {
        exploreApis.push(service.getGroupAndComponentVolatility(l.viewID, portfolioViewID))
        if (l.largeFrequencyLayerViewID !== '') {
          exploreApis.push(service.getGroupLargeFrequency(portfolioViewID, l.largeFrequencyLayerViewID))
        }
      }
    }
  })
  return exploreApis
}

function getLayerScaleFactors(layers: LossSetLayer[]): LossSetLayer[] {
  return layers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
      ? layer.meta_data.originalPremium
      : layer.premium?.value || 0
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
}

export function filterLossSets(lossSetLayers: LossSetLayer[], summaryFilterMap: ExploreFilterMap): LossSetLayer[] {
  const map = extractFilterMapKeys(summaryFilterMap, '~')
  const layers = lossSetLayers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data?.originalPremium || layer.meta_data?.originalPremium === 0
      ? layer.meta_data?.originalPremium
      : layer.premium?.value ?? 0
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
  return layers.filter(layer => {
    return Object.keys(map).every(key => {
      const metaValue = String(layer.meta_data[key as keyof typeof layer.meta_data])
      const allowedValues = map[key]
      if (!allowedValues || allowedValues.includes("undefined") || allowedValues.length === 0) {
        return true
      }
      return allowedValues.includes(metaValue)
    })
  })
}

export function updateSelectedFilterMap(
  filterMap: ExploreFilterMap,
  filters: string[],
  reset?: boolean
): ExploreFilterMap {
  return Object.keys(filterMap).reduce((updatedMap, key) => {
    updatedMap[key] = reset || filters.includes(key)
    return updatedMap
  }, {} as { [key: string]: boolean })
}

export function summaryNumberFormat(value: number, currency: string, abrev: 'M' | 'K', groupOne: boolean): string {
  const factor = abrev === 'M' ? 1e6 : 1e3
  const roundedValue = Math.abs(value / factor).toFixed(1)
  const formattedValue = Number(roundedValue).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
  const symbol = groupOne ? currencySymbol(currency): ''
  const positive = `${symbol}${formattedValue}`
  const negative = `-${positive}`
  return value >= 0 ? positive : negative
}

export function updateRPColumnDefLabel(label: number, rp: number[]): string {
  switch (label) {
    case 10:
      return String(rp[0])
    case 25:
      return String(rp[1])
    case 50:
      return String(rp[2])
    case 100:
      return String(rp[3])
    case 200:
      return String(rp[4])
    case 250:
      return String(rp[5])
    default:
      return String(label)
      break;
  }
}
