import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { UserPreferencesEffects } from './store/user-preferences.effects'
import { EffectsModule } from '@ngrx/effects'
import * as fromUserPreferences from './store/user-preferences.reducer'
import { UserPreferencesService } from '../api/user-preferences/user-preferences.service'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromUserPreferences.FEATURE_KEY,
      fromUserPreferences.reducer
    ),
    EffectsModule.forFeature([UserPreferencesEffects]),
  ],
  providers: [UserPreferencesService],
})
export class UserPreferencesModule {}
