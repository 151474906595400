<div class="outer-wrapper">
  <div class="close-button">
    <button appButtonIcon big class="close" (click)="destroyDialog()">
      close
    </button>
  </div>
  <div class="wrapper">
    <mat-form-field class="settings-field" appearance="outline">
      <mat-label>X-Axis</mat-label>
      <mat-select
        [value]="selectedXDef"
        (selectionChange)="selectedXDef = $event.value"
        ><mat-option *ngFor="let option of xAxisOptions" [value]="option">{{
          option.label
        }}</mat-option></mat-select
      >
    </mat-form-field>
    <mat-form-field class="settings-field" appearance="outline">
      <mat-label>Y-Axis</mat-label>
      <mat-select
        [value]="selectedYDef"
        (selectionChange)="selectedYDef = $event.value"
        ><mat-option *ngFor="let option of yAxisOptions" [value]="option">{{
          option.label
        }}</mat-option></mat-select
      >
    </mat-form-field>
  </div>
  <div class="button-wrapper">
    <div class="modal-action-buttons">
      <button appButton primary accent border (click)="confirmSettings()">
        <span>Confirm</span>
      </button>
      <button appButton primary accent border (click)="destroyDialog()">
        <span>Cancel</span>
      </button>
    </div>
  </div>
</div>
