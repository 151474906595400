import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import {
  CombinedSelectors,
  PricingCurveLayer,
  SavedPricingCurveEntry,
  TechnicalFactors,
  IIntervalFilter,
  PricingCurveContextTypes,
  PricingCurveData,
  PricingCurveAddDataDialogTabs,
  CreditCurveLayer,
  PricingCurveGraphSettings,
  SelectedTransaction,
} from '../model/pricing-curve.model'
import { PricingCurveExportOptions } from '../export/pricing-curve-export.model'
import { Layer } from 'src/app/analysis/model/layers.model'

export const initPricingCurveData = createAction(
  '[Pricing Curve] Init pricing curve data',
  props<{ context: PricingCurveContextTypes }>()
)

export const updateGraphSettings = createAction(
  '[Pricing Curve] Update graph settings',
  props<{ graphSettings: PricingCurveGraphSettings }>()
)

export const initDefaultTechFactorsSuccess = createAction(
  '[Pricing Curve] Init default tech factors success',
  props<{ techFactors?: TechnicalFactors }>()
)

export const initDefaultTechFactorsFailure = createAction(
  '[Pricing Curve] Init default tech factors failure',
  props<{ error?: ApplicationError }>()
)

export const fetchSelectors = createAction(
  '[Pricing Curve] Fetch selectors',
  props<{ layerSplitView: boolean }>()
)

export const fetchSelectorsSuccess = createAction(
  '[Pricing Curve] Fetch selectors success',
  props<{ combinedSelectors: CombinedSelectors }>()
)

export const fetchSelectorsFailure = createAction(
  '[Pricing Curve] Fetch selectors failure',
  props<{ error: ApplicationError }>()
)

export const initSavedCurveSelectors = createAction(
  '[Pricing Curve] Init saved curve selectors',
  props<{ selectors: CombinedSelectors[]; names: string[] }>()
)

export const resetSavedCurveSelectors = createAction(
  '[Pricing Curve] Reset saved curve selectors'
)

export const updatePricingCurveContext = createAction(
  '[Pricing Curve] Update Pricing Curve Context',
  props<{ context: PricingCurveContextTypes }>()
)

export const editCurveFromGraphInteraction = createAction(
  '[Pricing Curve] Edit dataSet from graph interaction',
  props<{
    curveData: PricingCurveData
  }>()
)

export const updatePreviouslyClickedId = createAction(
  '[Pricing Curve] Update previously clicked id',
  props<{ setId: number; layerId: number }>()
)

export const addDataDialogOpened = createAction(
  '[Pricing Curve] Open add data dialog',
  props<{ context: PricingCurveContextTypes }>()
)

export const addDataDialogClosed = createAction(
  '[Pricing Curve] Close add data dialog',
  props<{ isTechPremium?: boolean }>()
)

export const updatePricingCurve = createAction(
  '[Pricing Curve] Update pricing curve',
  props<{
    id: number
    curveData: Partial<PricingCurveData>
  }>()
)

export const updateAddDataDialogTab = createAction(
  '[Pricing Curve] Update add data dialog tab',
  props<{ tab: PricingCurveAddDataDialogTabs }>()
)

export const updateWorkingCurveData = createAction(
  '[Pricing Curve] Update Working Curve Data',
  props<{
    curveData: Partial<PricingCurveData>
  }>()
)

export const updateDialogSelectors = createAction(
  '[Pricing Curve] Update dialog selectors',
  props<CombinedSelectors>()
)

export const addCurveToGraph = createAction(
  '[Pricing Curve] Add curve to graph',
  props<{ curveData: PricingCurveData }>()
)

export const removePricingCurveFromGraph = createAction(
  '[Pricing Curve] Remove pricing curve from graph',
  props<{ id: number }>()
)

export const submitCreditCurve = createAction(
  '[Pricing Curve] Submit credit curve',
  props<{ curve: PricingCurveData; saveCurve: boolean }>()
)

export const submitCreditCurveFailure = createAction(
  '[Pricing Curve] Submit credit curve failure',
  props<{ error: ApplicationError }>()
)

export const savePricingCurve = createAction(
  '[Pricing Curve] Save pricing curve',
  props<{ curve: PricingCurveData }>()
)

export const savePricingCurveSuccess = createAction(
  '[Pricing Curve] Save pricing curve success'
)

export const savePricingCurveFailure = createAction(
  '[Pricing Curve] Save pricing curve failure',
  props<{ error: ApplicationError }>()
)

export const reorderCards = createAction(
  '[Pricing Curve] Reorder curve cards',
  props<{ previousIndex: number; newIndex: number }>()
)

export const fetchLayers = createAction(
  '[Pricing Curve] Fetch layers',
  props<{ layerSplitView: boolean }>()
)

export const fetchLayersFailure = createAction(
  '[Pricing Curve] Fetch layers failure',
  props<{ error: ApplicationError }>()
)

export const fetchLayersSuccess = createAction(
  '[Pricing Curve] Fetch layers success',
  props<{ layers?: PricingCurveLayer[]; creditLayers?: CreditCurveLayer[] }>()
)

export const filtersChanged = createAction(
  '[Pricing Curve] Filters changed',
  props<{ filters?: Record<string, string[]> }>()
)

export const updateDateIntervalValues = createAction(
  '[Pricing Curve] Update date interval',
  props<{ data: IIntervalFilter; isMin: boolean }>()
)

export const clearDateInterval = createAction(
  '[Pricing Curve] Clear date interval',
  props<{ columnName: string; isMin: boolean }>()
)

export const cleanFilters = createAction('[Pricing Curve] Clean filters')

export const clearFilter = createAction(
  '[Pricing Curve] Clear filter',
  props<{ columnName: string }>()
)

export const savedCurveFiltersChanged = createAction(
  '[Pricing Curve] Saved curve filters changed',
  props<{ filters?: Record<string, string[]> }>()
)

export const savedCurveIntervalFilterChanged = createAction(
  '[Pricing Curve] Saved curve interval filter changed',
  props<{ data: IIntervalFilter; isMin: boolean }>()
)

export const clearSavedCurveDateInterval = createAction(
  '[Pricing Curve] Clear saved curve date interval',
  props<{ columnName: string; isMin: boolean }>()
)

export const clearSavedCurveFilter = createAction(
  '[Pricing Curve] Clear saved curve filter',
  props<{ columnName: string }>()
)

export const fetchFilteredSavedCurvesSuccess = createAction(
  '[Pricing Curve] Fetch filtered saved curves success',
  props<{ data: SavedPricingCurveEntry[] }>()
)

export const setWorkingCurve = createAction(
  '[Pricing Curve] Set working curve',
  props<{ curveData: PricingCurveData }>()
)

export const resetCurveInDialog = createAction(
  '[Pricing Curve] Reset curve for dialog',
  props<{ id: number }>()
)

export const editDataDialogOpened = createAction(
  '[Pricing Curve] Edit curve data dialog opened',
  props<{ curveData: PricingCurveData }>()
)

export const editDataDialogClosed = createAction(
  '[Pricing Curve] Edit curve data dialog closed'
)

export const updateCurveVisibility = createAction(
  '[Pricing Curve] Update curve visibility',
  props<{
    id: number
    layersVisible: boolean
    lineVisible: boolean
    applyAll: boolean
  }>()
)

export const fetchSavedCurves = createAction(
  '[Pricing Curve] Fetch saved curves',
  props<{ useSavedCurveSelectors: boolean }>()
)

export const fetchSavedCurvesFailure = createAction(
  '[Pricing Curve] Fetch saved curves failure',
  props<{ error: ApplicationError }>()
)

export const fetchSavedCurvesSuccess = createAction(
  '[Pricing Curve] Fetch saved curves success',
  props<{ data: SavedPricingCurveEntry[] }>()
)

export const loadSavedPricingCurve = createAction(
  '[Pricing Curve] Load saved pricing curve',
  props<{ id: number }>()
)

export const loadSavedPricingCurveSuccess = createAction(
  '[Pricing Curve] Load saved curve success'
)

export const loadSavedPricingCurveFailure = createAction(
  '[Pricing Curve] Load saved curve failure',
  props<{ error: ApplicationError }>()
)

export const resetModuleToDefaultState = createAction(
  '[Pricing Curve] Reset module to default state'
)

export const deleteSavedPricingCurve = createAction(
  '[Pricing Curve] Delete saved pricing curve',
  props<{ name: string; id: number }>()
)

export const deleteSavedPricingCurveFailure = createAction(
  '[Pricing Curve] Delete saved pricing curve failure',
  props<{ error: ApplicationError }>()
)

export const exportPricingCurveState = createAction(
  '[Pricing Curve] Export state',
  props<{
    exportLabel: string
    includedCurves: number[]
    options: PricingCurveExportOptions
    graphBase64: string
  }>()
)

export const updateDataSetExportRGBValue = createAction(
  '[Pricing Curve] Update dataset export RGB value',
  props<{ id: number; rgb: string }>()
)

export const getLayerDetailsAndRecalculateForLayersUsingCurve = createAction(
  '[Pricing Curve] Get layer details and recalculate for layers using curve',
  props<{ layers: Layer[]; skipStartAnalysis?: boolean }>()
)

export const updateIsSavingLayers = createAction(
  '[Pricing Curve] Update Is Saving Layers',
  props<{ newValue: boolean }>()
)

export const updateSelectedCreditTransaction = createAction(
  '[Pricing Curve] Update Selected Credit Transaction',
  props<{details: SelectedTransaction}>()
)
