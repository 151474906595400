import { InjectionToken } from '@angular/core'
import { PricingCurveExportPage } from './pricing-curve-export.model'

export interface PricingCurveXlsxServiceOptions {
  type?: 'xlsx'
}

export interface PricingCurveXlsxService {
  export(
    fileName: string,
    pages: PricingCurveExportPage[],
    graphBase64: string,
    options?: PricingCurveXlsxServiceOptions
  ): void
}

export const PRICING_CURVE_XLSX_SERVICE_TOKEN =
  new InjectionToken<PricingCurveXlsxService>('pricing-curve-xlsx-service')
