<section class="filter-bar" *ngIf="form">
  <form
    *ngIf="selectors && context !== 'credit'"
    class="filters"
    [formGroup]="form"
  >
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.clientName"
      [name]="'Client Name'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.clientName)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.layerCategory"
      [name]="'Layer Category'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.layerCategory)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.reinstatement"
      [name]="'Reinstatement'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.reinstatement)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.reinsurerName"
      [name]="'Reinsurer Name'"
      [loading]="status.isLoading || status.dialogLoading"
      [canSelectAll]="true"
      (clearFilter)="clearFilter($event, selectors.reinsurerName)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.status"
      [name]="'Status'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.status)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.ignoreForPricingCurve"
      [name]="'Ignore for Pricing Curve'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.ignoreForPricingCurve)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.territory"
      [name]="'Territory'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.territory)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.perils"
      [name]="'Perils'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.perils)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.placedThrough"
      [name]="'Placed Through'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.placedThrough)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.correspondentBroker"
      [name]="'Correspondent Broker'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.correspondentBroker)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.lossImpactedFromPrevYear"
      [name]="'Loss Impacted from Previous Year'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.lossImpactedFromPrevYear)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.pcClass"
      [name]="'Class'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.pcClass)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="selectors.pcSubClass"
      [name]="'SubClass'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, selectors.pcSubClass)"
      (setFilter)="dispatchFilters()"
      [childNumber]="1"
    ></app-multiselect-autocomplete>
    <section class="more-options_ranges">
      <div class="range_inputs">
        <mat-label class="range_label">Inception Date</mat-label>
        <mat-form-field
          class="field interval app-bar-field app-bar-field-button app-field-bold-label"
        >
          <input
            matInput
            #inceptionDateInputStart
            formControlName="inceptionDateInputStart"
            [matDatepicker]="startDate"
            placeholder="Start"
            (dateChange)="onStartDateChange('inceptionDate', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            matSuffix
            *ngIf="inceptionDateInputStart.value"
            class="clear"
            [for]="startDate"
            (click)="clearDate('inceptionDate', true)"
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          class="field interval app-bar-field app-bar-field-button app-field-bold-label"
        >
          <input
            matInput
            #inceptionDateInputEnd
            formControlName="inceptionDateInputEnd"
            placeholder="End"
            [matDatepicker]="endDate"
            (dateChange)="onEndDateChange('inceptionDate', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            matSuffix
            *ngIf="inceptionDateInputEnd.value"
            class="clear"
            (click)="clearDate('inceptionDate', false)"
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </section>
  </form>
  <form
    *ngIf="creditSelectors && context === 'credit'"
    class="filters"
    [formGroup]="form"
  >
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.organization"
      [name]="'Organization'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.organization)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.dealType"
      [name]="'Deal Type'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.dealType)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.highLowLTV"
      [name]="'High/Low'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.highLowLTV)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.dealName"
      [name]="'Deal Name'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.dealName)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.tranche"
      [name]="'Tranche Name'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.tranche)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
    <app-multiselect-autocomplete
      [form]="form"
      [filter]="creditSelectors.year"
      [name]="'Year'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, creditSelectors.year)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-autocomplete>
  </form>
</section>
