import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  SummaryChartGridOption,
  SummaryChartInfo,
} from '../summary-charts-model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-chart-grid-item-frame',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        overflow: visible;
      }

      .wrapper {
        flex: 1;
        position: relative;
        margin: 0;
        max-height: 100%;
        max-width: 100%;
        overflow: visible;
      }

      .wrapper > .app-chart {
        position: absolute;
        display: inline-block;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: middle;
        vertical-align: middle;
        overflow: visible;
      }

      label {
        margin: var(--stack-big) var(--inset-big);
        display: block;
      }

      .empty {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      .empty label {
        flex: 1 1 0%;
        text-align: center;
      }
    `,
  ],
  template: `
    <div class="wrapper">
      <label *ngIf="!hasData" appInfoText big> No data available </label>
      <ng-container *ngIf="hasData">
        <ng-content></ng-content>
        <ng-container *ngIf="!pdfView">
          <app-explore-summary-chart-controls
            [chartOption]="chartOption"
            [selectableChartsForTab]="selectableChartsForTab"
            [selectableChartsForMetric]="selectableChartsForMetric"
            [isMaximized]="isFrameMaximized"
            [canHideMetrics]="canHideMetrics"
            [hideMetrics]="hideMetrics"
            [hasComparisonValue]="hasComparisonValue"
            [lightChartMode]="lightChartMode"
            (updateHideMetrics)="onHideMetricsChange($event)"
            (chartOptionUpdate)="chartOptionUpdate.emit($event)"
            (maximizeClick)="maximizeClick.emit($event)"
            (updateComparisonValue)="updateComparisonValue.emit($event)"
            (onExportClick)="onExportClick.emit()"
          >
          </app-explore-summary-chart-controls>
        </ng-container>
      </ng-container>
    </div>
  `,
})
export class ExploreSummaryChartGridItemFrameComponent implements OnChanges {
  @Input() chartOption: SummaryChartGridOption
  @Input() hasData: boolean
  @Input() selectableChartsForTab: SummaryChartInfo[]
  @Input() selectableChartsForMetric: SummaryChartInfo[]
  @Input() maximize: boolean
  @Input() buttonMarginTop = '0'
  @Input() maximizedIndex: number
  @Input() showMaximize: boolean
  @Input() canHideMetrics: boolean
  @Input() hideMetrics: boolean
  @Input() hasComparisonValue: boolean
  @Input() lightChartMode: boolean
  @Input() pdfView: boolean

  @Output() chartTypeChange = new EventEmitter<SummaryChartInfo>()
  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()
  @Output() updateComparisonValue = new EventEmitter<number | null>()
  @Output() onExportClick = new EventEmitter()

  isFrameMaximized: boolean
  showMaximizedButton: boolean

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maximizedIndex) {
      this.isFrameMaximized =
        (!!this.chartOption.index &&
          !!this.maximizedIndex &&
          this.chartOption.index === this.maximizedIndex) ||
        (this.chartOption.index === 0 && this.maximizedIndex === 0)
    }
  }

  onHideMetricsChange(hideMetrics: boolean): void {
    this.updateHideMetrics.emit(hideMetrics)
  }
}
