<ng-container *ngIf="!error; else errorTemplate">
    <ng-container *ngIf="!candidateLayersLoading; else loadingTemplate">
      <div class="container">
        <div class="top">
          <label appInfoText hint small>
            Currency values are shown in $ thousands
          </label>
          <mat-paginator
            class="paginator"
            #paginator
            showFirstLastButtons
            pageSize="15"
          ></mat-paginator>
        </div>
        <div class="table">
          <app-sort-table
            #sortTable
            [columnDefs]="columnDefs"
            [rows]="candidateLayers"
            [emptyMessage]="'No Candidates Found'"
            [paginator]="paginator"
            [selectAllColumnID]="'include'"
            (selectedChange)="onSelectedChange($event)"
            (valueChange)="onValueChange($event)"
          ></app-sort-table>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loadingTemplate>
    <label appInfoText big class="loading">Loading...</label>
  </ng-template>
  <ng-template #errorTemplate>
    <label appInfoText class="error" warn big>{{ error }}</label>
  </ng-template>
