<h1 mat-dialog-title>Scenario Events</h1>
<ng-container *ngIf="lossSetNames$ | async as lossSetNames">
  <div mat-dialog-content>
    <button appButton border (click)="onAddScenarioEvent(lossSetNames)">
      Add Event
    </button>
  </div>
  <div class="table-container">
    <app-scenario-event-table
      [events]="scenarioEvents$ | async"
      [lossSetNames]="lossSetNames"
      [disableActions]="executing$ | async"
      (eventChange)="onScenarioEventChange($event)"
      (delete)="onScenarioEventDelete($event)"
    ></app-scenario-event-table>
  </div>
  <label appInfoText hint small>
    Currency values are shown in $ Millions
  </label>
</ng-container>
<div mat-dialog-actions>
  <button appButton accent mat-dialog-close="2" cdkFocusInitial>
    Close
  </button>
</div>
