import { MarketService } from '../../api/market/market.service'
import {
  Input,
  Component,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core'
import { Upload } from 'tus-js-client'
import { State as AuthState } from '../../core/store/auth/auth.state.facade'
import { environment } from '../../../environments/environment'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MarketDocument } from '../../api/model/market.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-digital-content',
  styleUrls: ['./digital-content.component.scss'],
  templateUrl: './digital-content.component.html',
})
export class DigitalContentComponent implements OnInit {
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() year: string | null
  @Input() authState: AuthState

  files: any[] = []
  name: string
  docClass: string
  comments: string
  documentClassList = [
    {
      id: 1,
      name: 'Policy Listing',
      documents: ([] = [] as any),
    },
    {
      id: 2,
      name: 'Exposure Listing',
      documents: ([] = [] as any),
    },
    {
      id: 3,
      name: 'Loss Listing',
      documents: ([] = [] as any),
    },
    {
      id: 4,
      name: 'Development Triangles',
      documents: ([] = [] as any),
    },
    {
      id: 5,
      name: 'Rate Changes',
      documents: ([] = [] as any),
    },
    {
      id: 6,
      name: 'Adjustment Factors',
      documents: ([] = [] as any),
    },
    {
      id: 7,
      name: 'Structure Details',
      documents: ([] = [] as any),
    },
    {
      id: 8,
      name: 'RDM',
      documents: ([] = [] as any),
    },
    {
      id: 9,
      name: 'EDM',
      documents: ([] = [] as any),
    },
    {
      id: 10,
      name: 'SOV',
      documents: ([] = [] as any),
    },
    {
      id: 11,
      name: 'Other',
      documents: ([] = [] as any),
    },
  ]

  constructor(
    private _snackBar: MatSnackBar,
    private marketService: MarketService
  ) {}

  ngOnInit(): void {
    this.getMarketContent()
  }

  onFileDropped($event: any): void {
    this.prepareFilesList($event)
  }

  fileBrowseHandler(files: any): void {
    this.prepareFilesList(files)
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1)
  }

  prepareFilesList(files: Array<any>): void {
    for (const item of files) {
      item.progress = 0
      this.files.push(item)
    }
  }

  disableBtn(): boolean {
    if (
      this.name &&
      this.name !== '' &&
      this.docClass &&
      this.docClass !== '' &&
      this.comments &&
      this.comments !== '' &&
      this.files.length > 0
    ) {
      return false
    } else {
      return true
    }
  }

  getMarketContent(): void {
    if (this.clientID) {
      this.marketService
        .getDocuments(this.clientID ? this.clientID : '')
        .subscribe(res => {
          if (!res.error) {
            if (res.data && res.data.documents) {
              res.data.documents.forEach((doc: MarketDocument) => {
                const index = this.documentClassList.findIndex(
                  (entryDoc: any) => {
                    return entryDoc.name === doc.documentClass
                  }
                )
                if (index) {
                  this.documentClassList[index].documents.push(doc)
                }
              })
            }
          }
        })
    }
  }

  add(): void {
    this.files.forEach((file: File) => {
      let fileType = ''
      if (file.type === '') {
        // get it from the name
        const splitFileName = file.name.split('.')
        fileType = splitFileName[splitFileName.length - 1]
      } else {
        fileType = file.type
      }
      const upload = new Upload(file, {
        endpoint: environment.documentApi.base + environment.documentApi.files,
        headers: { Authorization: 'Bearer ' + this.authState.marketToken },
        retryDelays: [0, 3000, 6000, 12000, 24000],
        chunkSize: 4194304,
        metadata: {
          filename: file.name,
          filetype: fileType,
          name: file.name,
          contentType: fileType,
          'document-name': this.name,
          'document-class-id': this.docClass,
          'custom-metadata-comments': this.comments,
          'party-reference': this.clientID ? this.clientID : '',
        },
        onError: async error => {
          this._snackBar.open('Uploaded File(s) Failed.', 'X', {
            duration: 2000,
          })
          console.log(error)
          return false
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + '%')
        },
        onSuccess: async () => {
          this._snackBar.open('Successfully Uploaded File(s).', 'X', {
            duration: 2000,
          })
          this.files = []
          this.getMarketContent()
          return true
        },
      })
      upload.start()
      this._snackBar.open('Uploading File(s)..', 'X', {
        duration: 2000,
      })
    })
  }
}
