<div class="div-content">
  <ng-container
    *ngIf="!isQuotaShare; then techPremium; else techCedingCommission"
  >
  </ng-container>

  <ng-template #techPremium>
    <span *ngIf="value == null || isNew">
      <label>Technical Premium</label>
      <span class="label-content">
        <label class="empty" appInfoText big *ngIf="!isNew">
          Loading...
          <mat-spinner
            *ngIf="value == null"
            [diameter]="20"
            color="accent"
          ></mat-spinner
        ></label>
        <label class="empty" appInfoText *ngIf="isNew">
          Value will calculate after save
        </label>
      </span>
    </span>

    <app-layer-property
      *ngIf="value != null && !isNew"
      [currentCurrency]="currentCurrency"
      name="Technical Premium"
      type="currency"
      [readonly]="true"
      [value]="value"
      (inputChange)="inputChange.emit($event)"
      (inputKeyup)="inputKeyup.emit()"
      (inputSubmit)="inputSubmit.emit()"
    ></app-layer-property>
  </ng-template>

  <ng-template #techCedingCommission>
    <span *ngIf="value == null || isNew">
      <label>{{ techCedingCommissionLabel }}</label>
      <span class="label-content">
        <label class="empty" appInfoText big *ngIf="!isNew">
          Loading...
          <mat-spinner
            *ngIf="value == null"
            [diameter]="20"
            color="accent"
          ></mat-spinner
        ></label>
        <label class="empty" appInfoText *ngIf="isNew">
          Value will calculate after save</label
        >
      </span>
    </span>
    <app-percentage-input
      *ngIf="value != null && !isNew"
      [name]="techCedingCommissionLabel"
      type="percentage"
      [readonly]="true"
      [value]="value"
      [isCession]="true"
      [decimal]="'1'"
      (inputChange)="inputChange.emit($event)"
      (inputKeyup)="inputKeyup.emit()"
      (inputSubmit)="inputSubmit.emit()"
    ></app-percentage-input>
  </ng-template>
</div>
