import { OTRenewalDetails } from '../../slip-templates/slip-templates.model'
import { OTRenewalDetailsResponse } from '../model/sliptemplates.model'

export const convertRenewalDetailsResponse = (
  res: OTRenewalDetailsResponse
): OTRenewalDetails => ({
  umr: res.umr,
  inceptionDate: res.inceptionDateAndTime,
  expirationDate: res.expiryDateAndTime,
  riskRef: res.riskRef,
  reinsured: res.reinsuredName
    ? res.reinsuredName
    : res.quotes && res.quotes.length > 0 // in case of quotes from OT, the reinsured name will be on the quote structure
    ? res.quotes[0].reinsuredName
    : '',
})

// For Post
export const convertRenewalDetailsPostWS = (
  renewalDetails: OTRenewalDetails,
  structureId: string | null,
  layerIds: string[],
  layerNames: string[],
  currentTemplate: string | null,
  currentVendorId: string | undefined
) => ({
  reinsuredName: renewalDetails.reinsured,
  structureId: structureId && parseInt(structureId, 10),
  sectionIds: layerIds,
  sectionNames: layerNames,
  slipTemplate: currentTemplate,
  vendorId: currentVendorId,
})
