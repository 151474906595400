import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { BenchmarkMetricView } from '../model/benchmark-metric-view'
import { BenchmarkCompany } from '../model/benchmark.model'
import { BenchmarkMetricViewState } from './benchmark-metric-view.reducer'

export const setBenchmarkSelectedMetricView = createAction(
  '[Benchmark Metric View] Set Selected',
  (view: BenchmarkMetricView) => ({ view, submode: view.submode })
)

export const refreshBenchmarkMetricViews = createAction(
  '[Benchmark Metric View] Refresh'
)

export const restoreBenchmarkMetricViewSettings = createAction(
  '[Benchmark Metric View] Restore Settings'
)

export const restoreBenchmarkMetricViewSettingsSuccess = createAction(
  '[Benchmark Metric View] Restore Settings Success',
  (updates: Partial<BenchmarkMetricViewState>) => ({ updates })
)

// Show Metrics

export const toggleBenchmarkMetricViewShowMetrics = createAction(
  '[Benchmark Metric View] Toggle Show Metrics'
)

export const setBenchmarkMetricViewShowMetrics = createAction(
  '[Benchmark Metric View] Set Show Metrics',
  (value: boolean) => ({ value })
)

// Prepare New

export const prepareNewBenchmarkMetricView = createAction(
  '[Benchmark Metric View] Prepare New'
)

export const prepareNewBenchmarkMetricViewDone = createAction(
  '[Benchmark Metric View] Prepare New Done',
  (view: BenchmarkMetricView) => ({ view })
)

// Fetch, Update, Add, Remove

interface FetchProps {
  entity: BenchmarkCompany
  submode: string
}

export const fetchBenchmarkCompanyMetricViews = createAction(
  '[Benchmark Metric View] Fetch All By Company',
  props<FetchProps>()
)
export const fetchBenchmarkCompanyMetricViewsSuccess = createAction(
  '[Benchmark Metric View] Fetch All By Company Success',
  (views: BenchmarkMetricView[], { entity, submode }: FetchProps) => ({
    views,
    entity,
    submode,
  })
)
export const fetchBenchmarkCompanyMetricViewsFailure = createAction(
  '[Benchmark Metric View] Fetch All By Company Failure',
  (error: ApplicationError, { entity, submode }: FetchProps) => ({
    error,
    entity,
    submode,
  })
)

export const updateBenchmarkMetricView = createAction(
  '[Benchmark Metric View] Update',
  (view: BenchmarkMetricView) => ({ view })
)
export const updateBenchmarkMetricViewSuccess = createAction(
  '[Benchmark Metric View] Update Success',
  (view: BenchmarkMetricView) => ({ view })
)
export const updateBenchmarkMetricViewFailure = createAction(
  '[Benchmark Metric View] Update Failure',
  (error: ApplicationError, view: BenchmarkMetricView) => ({ view, error })
)

export const addBenchmarkMetricView = createAction(
  '[Benchmark Metric View] Add',
  (view: BenchmarkMetricView) => ({ view })
)
export const addBenchmarkMetricViewSuccess = createAction(
  '[Benchmark Metric View] Add Success',
  (view: BenchmarkMetricView) => ({ view })
)
export const addBenchmarkMetricViewFailure = createAction(
  '[Benchmark Metric View] Add Failure',
  (error: ApplicationError, view: BenchmarkMetricView) => ({ view, error })
)

export const removeBenchmarkMetricView = createAction(
  '[Benchmark Metric View] Remove',
  (view: BenchmarkMetricView) => ({ view })
)
export const removeBenchmarkMetricViewSuccess = createAction(
  '[Benchmark Metric View] Remove Success',
  (view: BenchmarkMetricView) => ({ view })
)
export const removeBenchmarkMetricViewFailure = createAction(
  '[Benchmark Metric View] Remove Failure',
  (error: ApplicationError, view: BenchmarkMetricView) => ({ view, error })
)
