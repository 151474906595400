<div class="exchange">
    <div class="title">
      Exchange Rate Table
    </div>
    <div class="description">
      {{description}}
    </div>
    <div class="current-currency">
      <span class="label">
        Currency:
      </span>
      {{currentCurrency}}
    </div>
    <div class="current-currency">
      <span class="label">
        Date of Exchange Rate:
      </span>
      {{date}}
    </div>
    <div class="current-currency">
      <span class="label">
        Simulation Trial Count:
      </span>
      {{trialCount}}
    </div>
    <div class="search">
      <app-filter-input
        class="child"
        transparent
        (filterChange)="createFilter($event)"
      ></app-filter-input>
    </div>
    <div class="rate-table">
      <app-sort-table
        [rows]="rows"
        [columnDefs]="columnDefs"
      ></app-sort-table>
    </div>
</div>
