import { Observable } from 'rxjs'
import { Reinstatement } from 'src/app/api/analyzere/analyzere.model'
import { Program } from '../../../core/model/program.model'
import {
  PortfolioSetID,
  PortfolioSetIDAndName,
} from '../../model/portfolio-set.model'
import * as fromLayers from '../ceded-layers/layers.reducer'
import { GrouperViewState } from './grouper-view/grouper-view.reducer'
import * as fromInurance from './inurance/grouper-inurance.reducer'
import { ProgramGroupMemberEntityState } from './program-group-member/program-group-member.reducer'
import {
  ProgramGroupEntity,
  ProgramGroupEntityState,
} from './program-group/program-group.reducer'
import { ProgramEntityState } from './program/program.reducer'
import * as fromSharedLimit from './shared-limit/grouper-shared-limit.reducer'
import { Dictionary } from '@ngrx/entity'
import { Layer } from '../../model/layers.model'

export const UNTITLED_GROUP_LABEL = 'Untitled'

export interface ProgramGroup extends Partial<PortfolioSetID> {
  id: string
  label: string
  description?: string
  clientID?: string
  studyID?: string
  isScenario?: boolean
  parentScenarioID?: string
  scenarioIDs?: string[]
  yearID: string
  fotCount?: number
  quoteCount?: number
}

export interface ProgramGroupEntityWithProgramPortfolioIDs
  extends Partial<Omit<ProgramGroupEntity, 'programGroup'>> {
  programGroup: ProgramGroup
  programPortfolioIDs: PortfolioSetIDAndName[]
}

export interface ProgramGroupMember {
  id: string
  parentGroupID: string
  programID?: string
  programGroupID?: string
  ordinal: number
  type: 'program' | 'programGroup'
}

export interface ProgramGroupSet {
  programs: Program[]
  programGroups: ProgramGroup[]
  programGroupMembers: ProgramGroupMember[]
}

export interface LayersByStructure {
  layers: Dictionary<Layer[]>
  loading: boolean
}

export interface LayersByStructureState {
  source: LayersByStructure
  swap: LayersByStructure
  target: LayersByStructure
}

export interface GrouperState {
  programs: ProgramEntityState
  programGroups: ProgramGroupEntityState
  programGroupMembers: ProgramGroupMemberEntityState
  layersByStructure: LayersByStructureState
  analysisProfileID: string | null
  yearID: string | null
  rootIDs: string[]
  slides: GrouperSlide[]
  view: GrouperViewState
  error: string | null
  loading: boolean
  saving: boolean
  inurance: fromInurance.GrouperInuranceState
  sharedLimit: fromSharedLimit.SharedLimitState
  groupCurrency?: string | null
}

// Scenarios

export interface ScenarioSelectModel<T> {
  item: T
  scenarios: T[]
  index: number
}

export interface CanHaveScenarios {
  id: string
  isScenario?: boolean
  parentScenarioID?: string
  scenarioIDs?: string[]
}

export interface GroupScenario {
  group: ProgramGroup
  members: ProgramGroupMember[]
  structureScenarios: ScenarioSelectModel<Program>[]
}

export interface GroupScenariosModel {
  group: ProgramGroup
  groupScenarios: GroupScenario[]
  index: number
  canEditScenarios: boolean
  hasStructureScenarios: boolean
  hasOnlyStructures: boolean
}

export interface GroupScenariosDialogData extends GroupScenariosModel {
  selectedGroupIndex?: number
  groupClass?: string
  saving$?: Observable<boolean>
}

export interface ChangeManyEvent<T> {
  add: T[]
  remove: T[]
  update: T[]
}

// Shared Limit

export interface SharedLimitLayerSelection {
  layerID: string
  entityID: string
  analysisID: string
  cededLayers: fromLayers.LayerState[]
  cededPortfolioID: string
  netPortfolioLayersIDs: string[]
  netPortfolioID: string
}

export interface SharedLimitProperties {
  layerName: string | null
  aggLimit: number
  occLimit: number
  aggDed: number
  ceded: number
  cession: number
  currency: string | undefined
  layerResultsMetricCurrency: string | undefined
  reinstatements: Reinstatement[]
}

export interface SharedIDGroup {
  sharedID: string
  numberGroup: number
  group: string[]
}

export interface SharedLimitValidation {
  isILW: boolean
  hasInuranceSource: boolean
  hasInuranceSourceDelete: boolean
}

export interface SharedLimit {
  id: string
  sl_layer_id?: string
  sl_name?: string
  carrier_id: number
  carrier_year_id?: number
  analysis_profile_id?: string
  fot_count?: number
  quote_count?: number
}

export interface SharedLimitMember {
  id: string
  sl_id: string
  layer_id?: string
  layer_name?: string
  backallocated_layer_id?: string
  carrier_id: number
  structure_id: string
}

// Program View models

export type LabelPos = 'left' | 'center' | 'none'

export interface GroupBarEdge {
  paletteIndex: number
  dropOrdinal?: number
  dropGroupID?: string
  minimized: boolean
}

export interface GroupBar {
  id: string
  ordinal: number
  paletteIndex: number
  label: string
  labelPos: LabelPos
  labelIndex: number
  untitled: boolean
  first: boolean
  firstIndex: number
  last: boolean
  lastIndex: number
  leftEdges: GroupBarEdge[]
  rightEdges: GroupBarEdge[]
  addLeftOrdinal: number
  addRightOrdinal: number
  minimized: boolean
}

export interface GrouperSlide {
  id: string
  groupID: string
  index: number
  ordinal: number
  dropLeftOrdinal: number
  dropRightOrdinal: number
  moveLeftOrdinal?: number
  moveLeftGroupID?: string
  moveRightOrdinal?: number
  moveRightGroupID?: string
  isGroupAgg: boolean
  minimized: boolean
  groupBars: Array<GroupBar | null>
  groupMinimizedIndex?: number
  programID?: string
}

export interface DeleteGroupParams {
  groupBar: GroupBar
  isChild: boolean
}
