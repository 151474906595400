import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromAnimatedScenariosReducer from './animated-scenarios.reducer'
import * as fromScenarioEventReducer from './scenario-event.reducer'
import * as fromScenarioEventResultReducer from './scenario-event-result.reducer'
import * as fromGroupScenarioEventReducer from './group/group-scenario-event.reducer'
import * as fromGroupScenarioEventResultsReducer from './group/group-scenario-event-result.reducer'

export const selectAnimatedScenariosState = createFeatureSelector<
  fromAnimatedScenariosReducer.AnimatedScenariosState
>(fromAnimatedScenariosReducer.ANIMATED_SCENARIOS_FEATURE_KEY)

export const selectScenarioEventState = createSelector(
  selectAnimatedScenariosState,
  s => s.eventState
)

export const selectScenarioEventResultState = createSelector(
  selectAnimatedScenariosState,
  s => s.eventResultState
)

export const {
  selectAll: selectAllScenarioEvents,
} = fromScenarioEventReducer.adapter.getSelectors(selectScenarioEventState)

export const {
  selectAll: selectAllScenarioEventResults,
} = fromScenarioEventResultReducer.adapter.getSelectors(
  selectScenarioEventResultState
)

export const selectScenarioStructure = createSelector(
  selectScenarioEventResultState,
  s => s.scenarioStructure
)
export const selectSelectedStructure = createSelector(
  selectAnimatedScenariosState,
  s => s.selectedStructure
)

export const selectExecuting = createSelector(
  selectAnimatedScenariosState,
  s => s.executing
)

export const selectAnimating = createSelector(
  selectAnimatedScenariosState,
  s => s.animating
)

// Group Selectors

export const selectGroupAnimatedScenariosState = createSelector(
  selectAnimatedScenariosState,
  s => s.groupScenarios
)

export const selectGroupID = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.groupID
)

export const selectGroupLoading = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.loadingGroup
)

export const selectGroupLoadingError = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.loadingGroupError
)

export const selectGroupLossSets = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.lossSetLayers
)

export const selectGroupExecuting = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.executing
)

export const selectGroupScenarioEventState = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.eventState
)

export const {
  selectAll: selectAllGroupScenarioEvents,
} = fromGroupScenarioEventReducer.adapter.getSelectors(
  selectGroupScenarioEventState
)

export const selectGroupScenarioEventResultState = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.eventResultState
)

export const {
  selectAll: selectAllGroupScenarioEventResults,
} = fromGroupScenarioEventResultsReducer.adapter.getSelectors(
  selectGroupScenarioEventResultState
)

export const selectGroupAnimating = createSelector(
  selectGroupAnimatedScenariosState,
  s => s.animating
)
