import { createAction, props } from '@ngrx/store'
import { SelectedUserPreferences } from '../../core/model/reinsurer.model'

import { ApplicationError } from '../../error/model/error'

export const saveOrUpdateUserPreferences = createAction(
  '[Study Reinsurers] Save Or Update User Preferences',
  props<{ selectedUserPreferences: SelectedUserPreferences }>()
)

export const saveOrUpdateUserPreferencesSuccess = createAction(
  '[Study Reinsurers] Save Or Update User Preferences Success',
  props<{ selectedUserPreferences: SelectedUserPreferences }>()
)

export const saveOrUpdateUserPreferencesFailure = createAction(
  '[Study Reinsurers] Save Or Update User Preferences Failure',
  props<{ error: ApplicationError }>()
)

export const fetchUserPreferences = createAction(
  '[Study Reinsurers] Fetch User Preferences',
  props<{ studyID: string }>()
)

export const fetchUserPreferencessSuccess = createAction(
  '[Study Reinsurers] Fetch User Preferences Success',
  props<{ selectedUserPreferences: SelectedUserPreferences }>()
)

export const fetchUserPreferencesFailure = createAction(
  '[Study Reinsurers] Fetch User Preferences Failure',
  props<{ error: ApplicationError }>()
)
