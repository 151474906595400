import { scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import { annotation as d3SvgAnnotation } from 'd3-svg-annotation'
import * as fc from 'd3fc'
import { Coord } from './coord'

// Wraps the d3-annotation component (https://d3-annotation.susielu.com/)
// so that it can be rendered as a series
export const seriesSvgAnnotation = <T extends Coord>(): any => {
  // the underlying component that we are wrapping
  const d3Annotation = d3SvgAnnotation()

  let xScale = scaleLinear()
  let yScale = scaleLinear()

  const join = fc.dataJoin('g', 'annotation')

  const series = (selection: any) => {
    selection.each((data: T[], index: number, group: any) => {
      const projectedData = data.map((d: T) => ({
        ...d,
        x: xScale(d.x),
        y: yScale(d.y),
      }))

      d3Annotation.annotations(projectedData)

      join(select(group[index]), projectedData).call(d3Annotation)
    })
  }

  series.xScale = (...args: any[]) => {
    if (!args.length) {
      return xScale
    }
    xScale = args[0]
    return series
  }

  series.yScale = (...args: any[]) => {
    if (!args.length) {
      return yScale
    }
    yScale = args[0]
    return series
  }

  fc.rebindAll(series, d3Annotation)

  return series
}
