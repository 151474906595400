import { Injectable } from '@angular/core'
import { BehaviorSubject, interval, Observable, of } from 'rxjs'
import { delayWhen } from 'rxjs/operators'
import {
  Layer,
  UNLIMITED_DOLLARS,
  ZERO_DOLLARS,
} from 'src/app/analysis/model/layers.model'
import { Section } from 'src/app/analysis/properties/layer/multi-section/multi-section.service'
import * as fromLayersActions from 'src/app/analysis/store/ceded-layers/layers.actions'
import {
  asMonetaryUnit,
  asUSD,
  Metadata,
  MonetaryUnit,
} from 'src/app/api/analyzere/analyzere.model'
import { LossSetLayer } from '../model/loss-set-layers.model'

type BusyStatus = {
  busy: boolean
}

@Injectable({
  providedIn: 'root',
})
export class MultiSectionLayerService {
  busyDebounceTime = 500

  /**
   * The busy counter pairs busy and notbusy calls. This is necessary because
   * of a sequence like:
   *   action --> busy"true"
   *     sub-action --> busy="true"
   *     sub-action <-- busy="false"
   *   action <-- busy="false"
   * We don't want the sub action to make the overall busy false when the outer
   * action still is not complete.
   */
  private busyCounter = 0

  private busySubject$ = new BehaviorSubject<BusyStatus>({ busy: false })
  readonly busy$: Observable<BusyStatus> = this.busySubject$.pipe(
    delayWhen((state, count) =>
      count > 1 && !state.busy ? interval(this.busyDebounceTime) : of(0)
    )
  )

  setBusy(busy: boolean) {
    console.assert(
      busy || this.busyCounter > 0,
      'The busy counter must not be negative.'
    )

    this.busyCounter += busy ? 1 : -1
    this.busyCounter = Math.max(this.busyCounter, 0)

    this.busySubject$.next({ busy: this.busyCounter > 0 })
  }

  createMainLayer(
    visibleLayer: Layer,
    sectionIds: string[],
    copiedMainLayer?: Layer
  ) {
    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      ...visibleLayer.meta_data,
      sage_layer_subtype: 'main-layer',
      hidden: true,
      visible_layer_id: visibleLayer.id,
    }

    const updatedCurrencyZeroDollars: MonetaryUnit = {
      ...ZERO_DOLLARS,
      currency: visibleLayer.currency ?? ZERO_DOLLARS.currency,
    }

    const updatedCurrencyUnlimitedDollars: MonetaryUnit = {
      ...UNLIMITED_DOLLARS,
      currency: visibleLayer.currency ?? UNLIMITED_DOLLARS.currency,
    }

    const mainLayer = {
      ...visibleLayer,
      id: '',
      lossSetLayers: [] as unknown as LossSetLayer,
      layerRefs: sectionIds,
      meta_data,
      physicalLayer: {
        ...visibleLayer.physicalLayer,
        aggregateAttachment: updatedCurrencyZeroDollars,
        attachment: updatedCurrencyZeroDollars,
        franchise: updatedCurrencyZeroDollars,
        limit:
          copiedMainLayer?.physicalLayer.limit ??
          updatedCurrencyUnlimitedDollars,
        participation: -1.0,
        meta_data,
      },
    }

    return mainLayer
  }

  createFlipperLayer(
    layerDefaults: Layer,
    section: Section,
    inuringSectionIds: string[]
  ): Layer {
    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      ...layerDefaults.meta_data,
      sage_layer_subtype: 'flipper-layer',
      hidden: true,
    }

    return {
      ...layerDefaults,
      id: '',
      lossSetLayers: [] as unknown as LossSetLayer,
      layerRefs: [...inuringSectionIds],
      description: `Flipper ${section.letter}`,
      meta_data,
      physicalLayer: {
        ...layerDefaults.physicalLayer,
        description: `Flipper ${section.letter}`,
        attachment: ZERO_DOLLARS,
        limit: UNLIMITED_DOLLARS,
        aggregateAttachment: ZERO_DOLLARS,
        aggregateLimit: UNLIMITED_DOLLARS,
        franchise: ZERO_DOLLARS,
        // Flip the sign from the section participation
        participation: section.participation > 0 ? -1.0 : 1.0,
        meta_data,
      },
    } as unknown as Layer
  }

  createSectionLayer(
    layerDefaults: Layer,
    letter: string,
    isPaste?: boolean,
    layerName?: string
  ) {
    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      ...layerDefaults.meta_data,
      sage_layer_subtype: 'section-layer',
      hidden: true,
      // In case it is a copied section layer, remove inurance info
      inuranceSource: isPaste
        ? undefined
        : layerDefaults.meta_data.inuranceSource,
      inuranceSourceFor: isPaste
        ? undefined
        : layerDefaults.meta_data.inuranceSourceFor,
      inuranceTarget: isPaste
        ? undefined
        : layerDefaults.meta_data.inuranceTarget,
      inuranceTargetFor: isPaste
        ? undefined
        : layerDefaults.meta_data.inuranceTargetFor,
      layerName: layerName ?? layerDefaults.meta_data.layerName,
    }

    return {
      ...layerDefaults,
      id: '',
      description: `Section ${letter}`,
      meta_data,
      physicalLayer: {
        ...layerDefaults.physicalLayer,
        description: `Section ${letter}`,
        franchise: ZERO_DOLLARS,
        participation: !isPaste
          ? 1.0
          : layerDefaults.physicalLayer.participation,
        meta_data,
      },
    }
  }

  addSectionLayers(
    visibleLayer: Layer,
    section: Section,
    flipperLayerId?: string
  ): Layer {
    // Pick out the loss sets by id from the section selections
    const lossSets = section.lossSets.flatMap(
      id => visibleLayer.lossSetLayers.find(ls => ls.id === id) ?? []
    )

    const layer = this.createSectionLayer(visibleLayer, section.letter)

    layer.lossSetLayers = lossSets
    layer.layerRefs = flipperLayerId ? [flipperLayerId] : []
    layer.physicalLayer.attachment = asUSD(section.occAttach)
    layer.physicalLayer.limit = section.occUnlimited
      ? UNLIMITED_DOLLARS
      : asUSD(section.occLimit)
    layer.physicalLayer.aggregateAttachment = asUSD(section.aggAttach)
    layer.physicalLayer.aggregateLimit = section.aggUnlimited
      ? UNLIMITED_DOLLARS
      : asUSD(section.aggLimit)
    layer.physicalLayer.participation = section.participation

    return layer
  }

  updateSectionLayers(
    lossSetLayers: LossSetLayer[],
    layer: Layer,
    section: Section
  ) {
    // Pick out the loss sets by id from the section selections
    const lossSets = section.lossSets.flatMap(
      id => lossSetLayers.find(ls => ls.id === id) ?? []
    )

    return [
      fromLayersActions.updateLayer({
        id: layer.id,
        change: {
          meta_data: {
            ...layer.meta_data,
            layer_narrative: section.narrative,
          },
          lossSetLayers: lossSets,
        },
      }),
      fromLayersActions.updatePhysicalLayer({
        id: layer.id,
        change: {
          limit: section.occUnlimited
            ? UNLIMITED_DOLLARS
            : asMonetaryUnit(section.occLimit, section.currency),
          attachment: asMonetaryUnit(section.occAttach, section.currency),
          aggregateLimit: section.aggUnlimited
            ? UNLIMITED_DOLLARS
            : asMonetaryUnit(section.aggLimit, section.currency),
          aggregateAttachment: asMonetaryUnit(
            section.aggAttach,
            section.currency
          ),
          participation: section.participation,
          meta_data: {
            ...layer.meta_data,
            isChangedInDesign: true,
            lossSetGroupIds: JSON.stringify(section.lossSetGroupIds)
          },
        },
      }),
    ]
  }
}
