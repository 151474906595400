import { OTRenewalDetails } from '../../../slip-templates/slip-templates.model'
import { RiskRefDetail } from '../../../api/model/signature.model'
import { ExternalVendorMapping } from 'src/app/api/model/sliptemplates.model'

export const SLIP_TEMPLATES_FEATURE_KEY = 'sliptemplates'

export interface State {
  templates: string[]
  riskRefs: RiskRefDetail[]
  renewalDetails: OTRenewalDetails
  currentTemplate: string
  currentRiskRef: string
  loading: boolean
  coBrokerLoading: boolean
  hasCoBroker?: boolean
  saving: boolean
  externalVendorMapping: ExternalVendorMapping | null
  currentVendorID?: string
}

export const initialState: State = {
  templates: [],
  renewalDetails: {
    riskRef: '',
    umr: '',
    reinsured: '',
    inceptionDate: '',
    expirationDate: '',
  },
  currentTemplate: '',
  currentRiskRef: '',
  riskRefs: [],
  loading: false,
  coBrokerLoading: false,
  hasCoBroker: false,
  saving: false,
  externalVendorMapping: null,
}
