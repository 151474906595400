<div class="wrapper">
  <h1 mat-dialog-title>Auto Group Loss Sets</h1>
  <div class="selection">
    <mat-button-toggle-group
    class="app-mat-theme app-button-toggle-group"
    name="groupMethod"
    aria-label="Auto Group Method"
    [value]="groupMethod"
    (click)="$event.stopPropagation()"
    (change)="updateGroupMethod($event.value)"
  >
    <mat-button-toggle value="dim" disableRipple>Group by dim</mat-button-toggle>
    <mat-button-toggle value="map" disableRipple>Auto Mapping</mat-button-toggle>
    <mat-button-toggle value="summary" disableRipple>Summary Mapping</mat-button-toggle>
  </mat-button-toggle-group>
  </div>
  <div
    *ngIf="groupMethod === 'dim'"
    class="dialog-container auto-group-container"
    >
    <div class="group-criteria">
      <h3>Group By:</h3>
      <div class="list">
        <h4>ls_dim1</h4>
        <div class="list-value" *ngFor="let lsdim of lsDim1List">
          <app-layer-property
            checkboxRow
            [name]="lsdim"
            [value]="isSelected(lsdim, lsDim1SelectedList)"
            [type]="'checkbox'"
            (inputChange)="toggleSelected(lsdim, 'lsDim1')"
          ></app-layer-property>
        </div>
        <h4>ls_dim2</h4>
        <div class="list-value" *ngFor="let lsdim of lsDim2List">
          <app-layer-property
            checkboxRow
            [name]="lsdim"
            [value]="isSelected(lsdim, lsDim2SelectedList)"
            [type]="'checkbox'"
            (inputChange)="toggleSelected(lsdim, 'lsDim2')"
          ></app-layer-property>
        </div>
      </div>
    </div>
    <div class="new-group">
      <h3>New Loss Set Group</h3>
      <input
        matInput
        class="name-input"
        placeholder="...Group Name"
        (change)="updateGroupName($event.target.value)"
      />
      <h4>Included Loss Sets</h4>
      <div class="loss-sets">
        <div class="loss-set" *ngFor="let ls of selectedLossSets">
          {{ layerLabels[ls.id] }}
        </div>
      </div>
    </div>
  </div>
  <div
  *ngIf="groupMethod === 'map'"
  class="dialog-container auto-group-container"
  >
    <div class="auto-map">
      <div class="maps">
        <h3>Mapping Columns</h3>
        <div
          class="map"
          *ngFor="let column of lossSetMappingColumns"
          >
          <app-layer-property
            checkboxRow
            [name]="column.viewValue"
            [value]="selectedColumns[column.id]"
            [type]="'checkbox'"
            (inputChange)="toggleSelectedColumn(column.id)"
          ></app-layer-property>
        </div>
      </div>
      <div class="groups">
        <h3>New Groups</h3>
        <div
          *ngFor="let group of selectedLossSetGroups"
          class="group"
        >
          <h4>{{group.name}}</h4>
          <div class="loss-sets">
            <div class="loss-set" *ngFor="let ls of group.lossSetLayers">{{ layerLabels[ls.id] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="groupMethod === 'attribute'"
    class="dialog-container auto-group-container"
    >
    <div class="auto-map">
      <div class="maps">
        <h3>Mapping Columns</h3>
        <div>
          <mat-select
            (selectionChange)="onAttributeSelected($event)"
            placeholder="Choose an Attribute"
            >
            <mat-option *ngFor="let attribute of attributes" [value]="attribute.value">
              {{attribute.viewValue}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="groups">
        <h3>New Groups</h3>
        <div
          *ngFor="let group of selectedLossSetGroups"
          class="group"
        >
          <h4>{{group.name}}</h4>
          <div class="loss-sets">
            <div class="loss-set" *ngFor="let ls of group.lossSetLayers">{{layerLabels[ls.id]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="groupMethod === 'summary'"
    class="dialog-container auto-group-container"
  >
    <div class="auto-map">
      <div class="maps">
        <h3
          class="sub-header"
        >
          Group By
        </h3>
        <div>
          <h4 (click)="toggleVisibility('l1')">
            Group By One
            <mat-icon class="caret-icon">
              {{ isSectionVisible('l1') ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </h4>
          <div *ngIf="isSectionVisible('l1')" [@slideInOut] class="level-container">
            <div
              *ngFor="let option
              of createFilter(lossSetMappingColumns, 'groupByOne', 'label')"
              class="parent"
              (click)="!checkDisabledLevels(option.id, 0) && updateModelingArray(option.id, 0)"
              [class.selected]="checkSelectedLevels(option.id, 0)"
              [class.disabled]="checkDisabledLevels(option.id, 0)"
              [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.id, 0) ? 'none' : 'auto' }"
            >
              <mat-icon
                class="child"
                *ngIf="checkSelectedLevels(option.id, 0)"
                aria-hidden="false"
                aria-label="check"
                [class.selected]="checkSelectedLevels(option.id, 0)"
                [class.disabled]="checkDisabledLevels(option.id, 0)"
              >
                check_box
              </mat-icon>
              <mat-icon
                class="child"
                *ngIf="!checkSelectedLevels(option.id, 0)"
                aria-hidden="false"
                aria-label="check"
              >
                check_box_outline_blank
              </mat-icon>
              <div class="child">
                <span
                  class="unselectable"
                  [matTooltip]="option.viewValue"
                  matTooltipPosition="above"
                >
                  {{ option.viewValue }}
                </span>
              </div>
            </div>
          </div>
          <h4 *ngIf="showGroupByLevel(0)" (click)="toggleVisibility('l2')">
            Group By Two
            <mat-icon class="caret-icon">
              {{ isSectionVisible('l2') ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </h4>
          <div *ngIf="isSectionVisible('l2')" [@slideInOut] class="level-container">
            <div
              *ngFor="let option
              of createFilter(lossSetMappingColumns, 'groupByTwo', 'label')"
              class="parent"
              (click)="!checkDisabledLevels(option.id, 1) && updateModelingArray(option.id, 1)"
              [class.selected]="checkSelectedLevels(option.id, 1)"
              [class.disabled]="checkDisabledLevels(option.id, 1)"
              [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.id, 1) ? 'none' : 'auto' }"
            >
              <mat-icon
                class="child"
                *ngIf="checkSelectedLevels(option.id, 1)"
                aria-hidden="false"
                aria-label="check"
                [class.selected]="checkSelectedLevels(option.id, 1)"
                [class.disabled]="checkDisabledLevels(option.id, 1)"
              >
                check_box
              </mat-icon>
              <mat-icon
                class="child"
                *ngIf="!checkSelectedLevels(option.id, 1)"
                aria-hidden="false"
                aria-label="check"
              >
                check_box_outline_blank
              </mat-icon>
              <div class="child">
                <span
                  class="unselectable"
                  [matTooltip]="option.viewValue"
                  matTooltipPosition="above"
                >
                  {{ option.viewValue }}
                </span>
              </div>
            </div>
          </div>
          <h4 *ngIf="showGroupByLevel(1)" (click)="toggleVisibility('l3')">
            Group By Three
            <mat-icon class="caret-icon">
              {{ isSectionVisible('l3') ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </h4>
          <div *ngIf="isSectionVisible('l3')" [@slideInOut] class="level-container">

            <div
              *ngFor="let option
              of createFilter(lossSetMappingColumns, 'groupByThree', 'label')"
              class="parent"
              (click)="!checkDisabledLevels(option.id, 2) && updateModelingArray(option.id, 2)"
              [class.selected]="checkSelectedLevels(option.id, 2)"
              [class.disabled]="checkDisabledLevels(option.id, 2)"
              [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.id, 2) ? 'none' : 'auto' }"
            >
              <mat-icon
                class="child"
                *ngIf="checkSelectedLevels(option.id, 2)"
                aria-hidden="false"
                aria-label="check"
                [class.selected]="checkSelectedLevels(option.id, 2)"
                [class.disabled]="checkDisabledLevels(option.id, 2)"
              >
                check_box
              </mat-icon>
              <mat-icon
                class="child"
                *ngIf="!checkSelectedLevels(option.id, 2)"
                aria-hidden="false"
                aria-label="check"
              >
                check_box_outline_blank
              </mat-icon>
              <div class="child">
                <span
                  class="unselectable"
                  [matTooltip]="option.viewValue"
                  matTooltipPosition="above"
                >
                  {{ option.viewValue }}
                </span>
              </div>
            </div>
          </div>
          <h4 *ngIf="showGroupByLevel(2)" (click)="toggleVisibility('l4')">
            Group By Four
            <mat-icon class="caret-icon">
              {{ isSectionVisible('l4') ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </h4>
          <div *ngIf="isSectionVisible('l4')" [@slideInOut] class="level-container">

            <div
              *ngFor="let option
              of createFilter(lossSetMappingColumns, 'groupByFour', 'label')"
              class="parent"
              (click)="!checkDisabledLevels(option.id, 3) && updateModelingArray(option.id, 3)"
              [class.selected]="checkSelectedLevels(option.id, 3)"
              [class.disabled]="checkDisabledLevels(option.id, 3)"
              [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.id, 3) ? 'none' : 'auto' }"
            >
              <mat-icon
                class="child"
                *ngIf="checkSelectedLevels(option.id, 3)"
                aria-hidden="false"
                aria-label="check"
                [class.selected]="checkSelectedLevels(option.id, 3)"
                [class.disabled]="checkDisabledLevels(option.id, 3)"
              >
                check_box
              </mat-icon>
              <mat-icon
                class="child"
                *ngIf="!checkSelectedLevels(option.id, 3)"
                aria-hidden="false"
                aria-label="check"
              >
                check_box_outline_blank
              </mat-icon>
              <div class="child">
                <span
                  class="unselectable"
                  [matTooltip]="option.viewValue"
                  matTooltipPosition="above"
                >
                  {{ option.viewValue }}
                </span>
              </div>
            </div>
          </div>
          <h4 *ngIf="showGroupByLevel(3)" (click)="toggleVisibility('l5')">
            Group By Five
            <mat-icon class="caret-icon">
              {{ isSectionVisible('l5') ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </h4>
          <div *ngIf="isSectionVisible('l5')" [@slideInOut] class="level-container">

            <div
              *ngFor="let option
              of createFilter(lossSetMappingColumns, 'groupByFive', 'label')"
              class="parent"
              (click)="!checkDisabledLevels(option.id, 4) && updateModelingArray(option.id, 4)"
              [class.selected]="checkSelectedLevels(option.id, 4)"
              [class.disabled]="checkDisabledLevels(option.id, 4)"
              [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.id, 4) ? 'none' : 'auto' }"
            >
              <mat-icon
                class="child"
                *ngIf="checkSelectedLevels(option.id, 4)"
                aria-hidden="false"
                aria-label="check"
                [class.selected]="checkSelectedLevels(option.id, 4)"
                [class.disabled]="checkDisabledLevels(option.id, 4)"
              >
                check_box
              </mat-icon>
              <mat-icon
                class="child"
                *ngIf="!checkSelectedLevels(option.id, 4)"
                aria-hidden="false"
                aria-label="check"
              >
                check_box_outline_blank
              </mat-icon>
              <div class="child">
                <span
                  class="unselectable"
                  [matTooltip]="option.viewValue"
                  matTooltipPosition="above"
                >
                  {{ option.viewValue }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="groups">
          <h3>New Groups</h3>
          <div
            *ngFor="let group of selectedLossSetGroups"
            class="group"
          >
            <h4>{{group.name}}</h4>
            <div class="loss-sets">
              <div class="loss-set" *ngFor="let ls of group.lossSetLayers">{{layerLabels[ls.id]}}</div>
            </div>
          </div>

      </div>
    </div>
  </div>
  <div class="button-group">
    <button
      appButton
      link
      (click)="onCloseClick()"
    >Close</button>
    <button
      appButton
      border
      (click)="handleClear()"
    >Clear</button>
    <button
      [disabled]="savingDisabled"
      appButton
      accent
      border
      (click)="onSaveClick()"
    >Save Group(s)</button>
  </div>
</div>
