import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { MatSliderChange } from '@angular/material/slider'
import { chain } from 'ramda'
import { Study } from '../../../core/model/study.model'
import {
  CurrencyCode,
  MonetaryUnit,
} from '../../../api/analyzere/analyzere.model'
import { AccountOpportunity } from '../../../api/model/backend.model'
import { ClientYear } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { Layer } from '../../model/layers.model'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { GrouperInuranceMode } from '../../store/grouper/inurance/grouper-inurance.reducer'
import {
  ProgramGroup,
  ProgramGroupMember,
  SharedLimitLayerSelection,
  SharedLimitProperties,
} from '../../store/grouper/program-group.model'
import { ProgramEntity } from '../../store/grouper/program/program.reducer'
import { SharedLimitMode } from '../../store/grouper/shared-limit/grouper-shared-limit.reducer'
import { CompareView } from '../../model/compare-view.model'
import { FormControl } from '@angular/forms'
import { MatTooltip } from '@angular/material/tooltip'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-compare',
  styleUrls: ['./group-compare.component.scss'],
  templateUrl: './group-compare.component.html',
})
export class GroupCompareComponent implements OnChanges, OnInit {
  @ViewChild('myTooltip') myTooltip: MatTooltip

  propertiesPanelToggleHover = false
  sharedLayers: string[] = []
  compareView: CompareView | undefined
  tooltipDisabled = true
  saveButtonDisabled = true
  precision = 3
  defaultConversion = 'Millions'
  compareViewName = 'New Compare View'
  @Input() id: PortfolioSetID | null
  @Input() entities: ProgramEntity[]
  @Input() name: 'Group' | 'Compare'
  @Input() analysisProfileID: string | null
  @Input() yearID: string | null
  @Input() years: readonly ClientYear[]
  @Input() programGroups: readonly ProgramGroup[]
  @Input() programs: readonly Program[]
  @Input() programIDs: readonly string[]
  @Input() compareViews: readonly CompareView[]
  @Input() selectedYearID: string | null
  @Input() selectedClientID: string | null
  @Input() selectedProgramGroupIDs: string[]
  @Input() saving: boolean
  @Input() dirty = false

  @Input() slidesPerView: number
  @Input() slidesPerViewMax = 6
  @Input() sharedLimitMode: SharedLimitMode
  @Input() sharedLimitSaving: boolean
  @Input() sharedLimitProperties: SharedLimitProperties
  @Input() sharedLimitAddLayers: SharedLimitLayerSelection[]
  @Input() sharedLimitLayers: Layer[]
  @Input() sharedCurrentCurrency: string
  @Input() inuranceMode: GrouperInuranceMode
  @Input() nextInuranceSymbol: string
  @Input() cededPortfolioViewID: string | null
  @Input() grossPortfolioViewID: string | null
  @Input() netPortfolioViewID: string | null
  @Input() programGroupMembers: ProgramGroupMember[]
  @Input() clientProgramGroupMembers: readonly ProgramGroupMember[]
  @Input() currentClientID: string | null
  @Input() structureFilter: string | null
  @Input() groupFilterByAssoc: boolean
  @Input() studyID: string | null
  @Input() selectedViewID: string | null
  @Input() selectedCompareView: CompareView | null

  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() studies?: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  @Input() groupCurrency: string
  @Input() compareCurrency: string
  @Input() tabSelected = 0
  currencyControl = new FormControl()
  SLCurrency: string

  // Allow Scenario or Optimization Selection - Shows the scenarios/optimization select all checkbox
  @Input() set allowScenarioOrOptimizationSelection(value: any) {
    this._allowScenarioOrOptimizationSelection = coerceBooleanProperty(value)
  }
  get allowScenarioOrOptimizationSelection() {
    return this._allowScenarioOrOptimizationSelection
  }
  _allowScenarioOrOptimizationSelection = false

  @Output() structureFilterChange = new EventEmitter<string | null>()
  @Output() programGroupAdd = new EventEmitter<ProgramGroup>()
  @Output() programGroupRemove = new EventEmitter<string>()
  @Output() programAdd = new EventEmitter<Program | Program[]>()
  @Output() programRemove = new EventEmitter<Program | Program[]>()
  @Output() cancelClick = new EventEmitter()
  @Output() saveSharedLimitClick = new EventEmitter<{
    hiddenLayer: Layer
    selectedLayerIDs: SharedLimitLayerSelection[]
  }>()
  @Output() slidesPerViewChange = new EventEmitter<number>()
  @Output() saveClick = new EventEmitter()
  @Output() addSharedLayerClick = new EventEmitter()
  @Output() groupFilterByAssocChange = new EventEmitter<boolean>()
  @Output() export = new EventEmitter<'pdf' | 'xlsx'>()

  @Output() saveAsCompareViewClick = new EventEmitter<CompareView>()
  @Output() saveCompareViewClick = new EventEmitter<CompareView>()
  @Output() deleteCompareViewClick = new EventEmitter<CompareView>()
  @Output() updateSelectedCompareView = new EventEmitter<CompareView>()
  @Output() selectedCurrency = new EventEmitter<string>()
  @Output() selectedConversion = new EventEmitter<string>()
  @Output() tabIndex = new EventEmitter<number>()

  currencyValues: string[] = ['Thousands', 'Millions', 'Billions']

  ngOnInit(): void {
    this.currencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharedLimitMode) {
      if (changes.sharedLimitMode.currentValue === 'None') {
        this.sharedLayers = []
      }
    }

    if (this.groupCurrency === null || this.compareCurrency === null) {
      this.currencyControl.setValue({
        code: 'Default',
      })
    }

    if (changes.groupCurrency && changes.groupCurrency.currentValue) {
      this.currencyControl.setValue({
        code: this.groupCurrency,
      })
    }

    if (changes.compareCurrency && changes.compareCurrency.currentValue) {
      this.currencyControl.setValue({
        code: this.compareCurrency,
      })
    }

    if (
      (changes.entities && changes.entities.currentValue.length === 0) ||
      (changes.programIDs && changes.programIDs.currentValue.length === 0)
    ) {
      this.currencyControl.reset()
    }
    if (changes.selectedCompareView) {
      this.onUpdateSelectedCompareView(this.selectedCompareView)
    }
  }

  get isLibRE(): boolean {
    const program = this.entities.findIndex(
      e =>
        e.program.cededPortfolioID === this.id?.cededPortfolioID &&
        e.program.grossPortfolioID === this.id?.grossPortfolioID &&
        e.program.netPortfolioID === this.id?.netPortfolioID
    )
    return !!(this.id && this.entities[program].program.libRE === 'Y')
  }

  get isGroup(): boolean {
    return this.name === 'Group'
  }

  get hasPrograms(): boolean {
    return this.programIDs.length > 0
  }

  get showInuranceBar(): boolean {
    return this.isGroup && this.hasPrograms && !this.showSharedLimitPanel
  }

  get isInuranceBarOpen(): boolean {
    return this.showInuranceBar && this.inuranceMode !== 'none'
  }

  get showSharedLimitPanel(): boolean {
    return this.isGroup && this.sharedLimitMode !== 'none'
  }

  get showAddMenu(): boolean {
    return (
      !this.isGroup || (!this.isInuranceBarOpen && !this.showSharedLimitPanel)
    )
  }

  get showEmptySelectedLabel(): boolean {
    return this.showAddMenu && !this.hasPrograms
  }

  get showSharedLimitAddButton(): boolean {
    return (
      this.hasPrograms &&
      this.isGroup &&
      !this.isInuranceBarOpen &&
      this.sharedLimitMode !== 'new'
    )
  }

  get hideSave(): boolean {
    return (
      this.isInuranceBarOpen ||
      this.showSharedLimitPanel ||
      (this.programIDs.length < 2 && !this.dirty)
    )
  }

  get allowDelete(): boolean {
    return this.isInuranceBarOpen || this.showSharedLimitPanel || !this.dirty
  }

  get activeAction(): string {
    return this.saving ? 'Saving' : ''
  }

  get sharedLimitActiveAction(): string {
    return this.sharedLimitSaving ? 'Saving' : ''
  }

  onProgramGroupRemove(id: string): void {
    this.programGroupRemove.emit(id)
  }

  onSlidesPerViewChange($event: MatSliderChange): void {
    if (!$event.value) {
      console.error('No value on slides per view change')
      return
    }
    this.slidesPerViewChange.emit($event.value)
  }

  onPropertiesPanelClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
  }

  onSaveSharedLimitClick(): void {
    const selectedCededPortfolioIDs = this.sharedLimitAddLayers.map(
      l => l.cededPortfolioID
    )
    const entities = this.entities.filter(entity =>
      selectedCededPortfolioIDs.includes(entity.program.cededPortfolioID)
    )
    if (entities.length > 0) {
      const allCededLayers = chain(e => e.cededLayers, entities)
      const sharedLayer = this.getNewSharedLayer(
        allCededLayers,
        this.sharedLimitAddLayers.map(l => l.layerID)
      )
      this.saveSharedLimitClick.emit({
        hiddenLayer: sharedLayer,
        selectedLayerIDs: this.sharedLimitAddLayers,
      })
    }
  }

  onPrecisionButtonClick(sign: string): void {
    if (sign === '+') {
      // No max limit on precision
      this.precision++
    } else if (sign === '-') {
      this.precision = Math.max(this.precision - 1, 0)
    }
  }

  onProgramRemoveGroup(event: Program): void {
    if (this.programIDs.length === 1 && this.slidesPerView !== 3) {
      this.slidesPerViewChange.emit(3)
    }
    this.programRemove.emit(event)
  }

  onProgramRemoveCompare(event: Program): void {
    this.saveButtonDisabled = false
    if (this.programIDs.length === 1 && this.slidesPerView !== 4) {
      this.slidesPerViewChange.emit(4)
    }
    this.programRemove.emit(event)
  }
  onProgramAddCompare(event: Program): void {
    this.saveButtonDisabled = false
    this.programAdd.emit(event)
  }

  onCancelClick(): void {
    this.sharedLayers = []
    this.cancelClick.emit()
  }

  onStructureFilterChange(value: string | null): void {
    this.structureFilterChange.emit(value)
  }

  onGroupFilterByAssocChange(value: boolean): void {
    this.groupFilterByAssocChange.emit(value)
  }

  onDeleteCompareViewClick(): void {
    this.deleteCompareViewClick.emit(this.compareView)
    this.compareView = undefined
  }

  onSaveCompareViewClick(): void {
    this.saveButtonDisabled = true
    const updatedCompareView: CompareView = {
      // tslint:disable-next-line: no-non-null-assertion
      ...this.compareView!,
      view_name: this.compareViewName,
      client_id: this.selectedClientID || '',
    }
    this.saveCompareViewClick.emit(updatedCompareView)
  }

  onSaveAsCompareViewClick(): void {
    this.myTooltip.disabled = false
    this.myTooltip.show()
    setTimeout(() => {
      this.myTooltip.disabled = true
    }, 1500)
    const structureIDs: string[] = []
    this.programIDs.map(id => structureIDs.push(id))
    const newCompareView: CompareView = {
      id: this.createCompareViewId().toString(),
      view_name: this.compareViewName,
      structure_ids: structureIDs,
      group_ids: this.selectedProgramGroupIDs,
      client_id: this.selectedClientID || '',
    }
    this.tooltipDisabled = false
    this.saveAsCompareViewClick.emit(newCompareView)
  }

  onUpdateSelectedCompareView($event: CompareView | null): void {
    this.saveButtonDisabled = false
    this.compareView = $event
    this.compareViewName = this.compareView?.view_name ?? 'New Compare View'
  }

  createCompareViewId(): number {
    return Math.round(new Date().getTime() + Math.random())
  }

  onCompareViewNameChange(): void {
    this.saveButtonDisabled = false
  }

  private getNewSharedLayer(
    layers: LayerState[],
    allLayerIDs: string[]
  ): Layer {
    const logicalIDTemp = new Date().getTime() + '' + Math.random() * 100000
    const sharedCurrency = this.SLCurrency
      ? this.SLCurrency
      : this.sharedCurrentCurrency
    const minLimit: MonetaryUnit = {
      value: this.sharedLimitProperties.occLimit,
      currency: sharedCurrency,
    }

    const layer: Layer = {
      id: logicalIDTemp,
      meta_data: {
        client: layers[0].layer.physicalLayer.meta_data.client,
        perspective: layers[0].layer.physicalLayer.meta_data.perspective,
        program_name: layers[0].layer.physicalLayer.meta_data.program_name,
        program_type: layers[0].layer.physicalLayer.meta_data.program_type,
        rol: layers[0].layer.physicalLayer.meta_data.rol,
        rol_type: layers[0].layer.physicalLayer.meta_data.rol_type,
        sage_layer_type: 'shared_limits',
        year: layers[0].layer.physicalLayer.meta_data.year,
        lossfilter_name: layers[0].layer.meta_data.lossfilter_name,
        sage_layer_subtype: 'virtual',
      },
      layerRefs: layers
        .filter(l => allLayerIDs.includes(l.layer.id))
        .map(l => l.layer.id),
      lossSetFilter: '',

      lossSetLayers: [],
      physicalLayer: {
        logicalLayerID: logicalIDTemp,
        id: (new Date().getTime() + '' + Math.random() * 100000).replace(
          '.',
          ''
        ),
        type: layers[0].layer.physicalLayer.type,
        attachment: {
          value: 0,
          currency: sharedCurrency,
        },
        limit: minLimit,
        participation: this.sharedLimitProperties.cession * -1,
        premium: {
          value: this.sharedLimitProperties.ceded,
          currency: sharedCurrency,
        },
        fees: [],
        aggregateLimit: {
          value: this.sharedLimitProperties.aggLimit,
          currency: minLimit.currency,
        },
        aggregateAttachment: {
          value: this.sharedLimitProperties.aggDed,
          currency: sharedCurrency,
        },
        description: this.sharedLimitProperties.layerName,
        meta_data: {
          client: layers[0].layer.physicalLayer.meta_data.client,
          perspective: layers[0].layer.physicalLayer.meta_data.perspective,
          program_name: layers[0].layer.physicalLayer.meta_data.program_name,
          program_type: layers[0].layer.physicalLayer.meta_data.program_type,
          rol: layers[0].layer.physicalLayer.meta_data.rol,
          rol_type: layers[0].layer.physicalLayer.meta_data.rol_type,
          sage_layer_type: 'shared_limits',
          year: layers[0].layer.physicalLayer.meta_data.year,
          sage_layer_subtype: 'virtual',
        },
        reinstatements: this.sharedLimitProperties.reinstatements,
        franchise: {
          value: 0,
          currency: sharedCurrency,
        },
        xcoord: 469,
        ycoord: 110,
      },
      sharedLayerID: '',
      viewMetrics: {
        error: null,
        loading: false,
        metrics: null,
        rss: null,
      },
    }

    return layer
  }

  filterCurrencyValues(searchVal: CurrencyCode): CurrencyCode[] {
    if (searchVal?.code) {
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      const currencyCode = searchVal as unknown as string
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(currencyCode?.toLowerCase()) === 0
      )
    }
  }

  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }

  getSelectedCurrency(currency: string): void {
    this.selectedCurrency.emit(currency)
  }

  getSelectedConversion(Conversion: string): void {
    this.selectedConversion.emit(Conversion)
  }

  getSelectedSLCurrency(currency: string): void {
    this.SLCurrency = currency
  }
}
