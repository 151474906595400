import { createAction, props } from '@ngrx/store'
import { QuickQuoteUpdates } from '../../models/quote.model'

export const updateQuickQuoteField = createAction(
  '[Quick Quote] Update Field',
  props<{ field: string }>()
)

export const addOrUpdateQuickQuote = createAction(
  '[Quick Quote] Add Or Update Quote',
  props<{ data: QuickQuoteUpdates }>()
)

export const updateQuickQuoteAutoFill = createAction(
  '[Quick Quote] Update Auto Fill',
  props<{ autoFill: boolean }>()
)

export const backQuickQuote = createAction('[Quick Quote] Back')

export const saveQuickQuote = createAction('[Quick Quote] Save')

export const saveQuickQuoteSuccess = createAction('[Quick Quote] Save Success')
