import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  CurveTypeOption,
  PRICING_CURVE_TYPE_OPTIONS,
  TechnicalFactors,
  DialogData,
  Selectors,
  BasicControl,
  PricingCurveLayer,
  PricingCurveStatus,
  PricingCurveContextTypes,
  SubmitPricingCurvePayload,
  CreditCurveLayer,
  CreditSelectors,
} from '../../../model/pricing-curve.model'
import { FormControl, FormGroup } from '@angular/forms'
import {
  PC_ADD_LAYERS_COLUMNS,
  DefaultAddLayersRowDef,
  CREDIT_ADD_LAYERS_COLUMNS,
  CreditAddLayersRowDef,
} from '../../../model/pricing-curve-table.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { MatPaginator } from '@angular/material/paginator'
import { SelectionsChangeEvent } from '@shared/util/selections'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-pricing-curve-layers',
  styleUrls: ['pricing-curve-add-layers.component.scss'],
  templateUrl: 'pricing-curve-add-layers.component.html',
})
export class PricingCurveAddLayersComponent implements OnInit {
  curveLabel: string
  curveType: CurveTypeOption | undefined
  selectors: Selectors | null
  dateIntervals: BasicControl[] | null
  creditSelectors: CreditSelectors | null
  isManual: boolean
  isLayerSet: boolean
  addLayerColumnDefs: SortTableColumnDef[]
  layerRows: DefaultAddLayersRowDef[] | CreditAddLayersRowDef[] = []
  dropdownOptions = PRICING_CURVE_TYPE_OPTIONS
  isEdit = false
  inputCurveLabel = ''
  fitCurveButtonText = 'Fit Curve'
  saveCurve = true

  defaultPageSize = 30
  pageSizeOptions = [15, 30, 50]

  @Input() set workingCurve(value: PricingCurve) {
    this._workingCurve = value
    this.selectors = value.selectors
    this.dateIntervals = value.dateIntervals
    this.creditSelectors = value.creditSelectors
    this.isManual = value.isManual
    this.isLayerSet = value.isLayerSet
    this.layerRows = value.tableRows
  }
  get workingCurve(): PricingCurve {
    return this._workingCurve
  }
  _workingCurve: PricingCurve
  @Input() form: FormGroup
  @Input() set dialogData(value: DialogData) {
    this._dialogData = value
    this.isEdit = value.editMode

    this.toggleColumnsBetweenViews()
  }
  get dialogData(): DialogData {
    return this._dialogData
  }
  _dialogData: DialogData
  @Input() inputTechFactors: TechnicalFactors | undefined
  @Input() currentDataSetNames: string[]
  @Input() status: PricingCurveStatus
  @Input() set context(val: PricingCurveContextTypes) {
    this._context = val
    if (val === 'credit') {
      this.addLayerColumnDefs = CREDIT_ADD_LAYERS_COLUMNS
      this.defaultPageSize = 100
      this.pageSizeOptions = [15, 30, 50, 100]
    } else {
      this.addLayerColumnDefs = PC_ADD_LAYERS_COLUMNS
      this.toggleColumnsBetweenViews()
    }
  }
  _context: PricingCurveContextTypes | undefined
  get context(): PricingCurveContextTypes {
    return this._context ?? 'pricing-curve'
  }

  @Output() selectionChanged = new EventEmitter<
    PricingCurveLayer[] | CreditCurveLayer[]
  >()
  @Output() predictionColumnsChanged = new EventEmitter<{
    key: string
    isActive: boolean
  }>()
  @Output() layerSplitViewChanged = new EventEmitter<boolean>()
  @Output() submitWorkingCurveAsLayerSet = new EventEmitter()
  @Output() submitCurve = new EventEmitter<SubmitPricingCurvePayload>()
  @Output() navigateToCurveFactors = new EventEmitter()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  toggleColumnsBetweenViews(): void {
    if (this.context === 'pricing-curve' && this.addLayerColumnDefs) {
      const cols = [...this.addLayerColumnDefs]
      const index = cols.findIndex(col => col.id === 'reinsurerName')
      if (index >= 0) {
        const column = {
          ...cols[index],
          label: this.workingCurve.layerSplitView
            ? 'Reinsurer Name'
            : 'FOT Version Name',
        }
        cols.splice(index, 1, column)
        this.addLayerColumnDefs = cols
      }
    }
  }

  ngOnInit(): void {
    this.addContextSpecificFormFields()
    // Set up subscription for label
    this.form
      .get('label')
      ?.valueChanges.subscribe(value => (this.curveLabel = value))

    // See if the curve type dropdown needs to be set
    const curveValue = this.workingCurve.curveType
    const curveType = this.dropdownOptions.find(
      option => option.value === curveValue
    )
    this.curveType = curveType
    this.form.get('curveType')?.setValue(curveType?.value)
    if (this.isEdit) {
      this.fitCurveButtonText = 'Confirm'
      this.inputCurveLabel = this.workingCurve.label
      this.form.get('label')?.setValue(this.inputCurveLabel)

      if (this.workingCurve.isSaved) {
        this.form.get('label')?.disable()
      }
    }
    if (this.isEdit || this.context === 'credit') {
      this.form.get('curveType')?.disable()
    } else {
      this.form.get('curveType')?.enable()
    }
  }

  addContextSpecificFormFields(): void {
    switch (this.context) {
      case 'credit':
        this.form.addControl(
          'minPremium',
          new FormControl(`${this.workingCurve.minimumPremium * 100}%`)
        )
        this.form.addControl(
          'scaleFactor',
          new FormControl(this.workingCurve.scaleFactor)
        )
        break
    }
  }

  onPercentageFieldBlur(fieldName: string): void {
    if (this.form.get(fieldName)) {
      const currentValue: number | string = this.form.get(fieldName).value
      const currentValueOrZero = !!currentValue ? currentValue : 0
      const transformedValue =
        typeof currentValueOrZero === 'number' || !currentValueOrZero.includes('%')
          ? `${currentValueOrZero}%`
          : currentValueOrZero
      this.form.get(fieldName).setValue(transformedValue)
    }
  }

  onPercentageFieldFocus(fieldName: string): void {
    if (this.form.get(fieldName)) {
      const currentValue: string = this.form
        .get(fieldName)
        .value.replace('%', '')
      this.form.get(fieldName).setValue(currentValue)
    }
  }

  // Fit curve must have at least 2 layer points as least squares can not be performed on a single point
  get fitCurveDisabled(): boolean {
    if (this.context === 'pricing-curve') {
      return (
        this.addLayerPointsDisabled ||
        !(this.workingCurve.includedLayers.length > 1) ||
        (!this.curveType && !this.isManual && !this.isLayerSet)
      )
    } else {
      return !(
        !!this.workingCurve.creditPredictionColumns &&
        Object.values(this.workingCurve.creditPredictionColumns).some(
          val => val.isActive
        ) &&
        !!this.workingCurve.includedLayers.length &&
        !!this.curveLabel &&
        !!this.curveLabel.length
      )
    }
  }

  // Add layer points needs only a single point, that condition is included in the template
  get addLayerPointsDisabled(): boolean {
    return (
      !this.curveLabel ||
      this.curveLabel.length === 0 ||
      (this.currentDataSetNames.includes(this.curveLabel) &&
        this.curveLabel !== this.inputCurveLabel)
    )
  }

  get disabledTooltip(): string | undefined {
    if (!this.curveLabel || !this.curveLabel.length) {
      return 'A label must be entered'
    }
    if (
      this.currentDataSetNames.includes(this.curveLabel) &&
      this.curveLabel !== this.inputCurveLabel
    ) {
      return 'The entered label already exists on the graph'
    }
    if (!this.curveType && !this.isManual && !this.isLayerSet) {
      return 'A curve type must be selected'
    }
  }

  updatePredictionColumn(key: string, $event: MatCheckboxChange) {
    this.predictionColumnsChanged.emit({ key, isActive: $event.checked })
  }

  onSelectedChange($event: SelectionsChangeEvent): void {
    // Row selection event, map new selections and update curve
    const { selections } = $event
    const dictionary = selections.dictionary
    let layers: PricingCurveLayer[] | CreditCurveLayer[]
    if (this.context === 'pricing-curve') {
      layers = this.workingCurve.layers.map(layer => ({
        ...layer,
        include: selections.someSelected ? dictionary[layer.id] : false,
      }))
    } else {
      layers = this.workingCurve.creditLayers.map(layer => ({
        ...layer,
        include: selections.someSelected ? dictionary[layer.trancheId] : false,
      }))
    }
    this.selectionChanged.emit(layers)
  }

  initLayerPoints(): void {
    if (
      this.addLayerPointsDisabled ||
      !this.workingCurve.includedLayers.length ||
      this.isEdit
    ) {
      return
    }
    this.submitWorkingCurveAsLayerSet.emit()
  }

  initFitOrSaveCurve(): void {
    if (
      this.fitCurveDisabled ||
      this.workingCurve.id === undefined ||
      !(this.workingCurve.includedLayers.length > 1)
    ) {
      return
    }

    if (!this.isLayerSet) {
      // Change tab to technical factors form for all non-layer sets
      if (this.context === 'pricing-curve') {
        this.navigateToCurveFactors.emit()
      } else if (this.context === 'credit') {
        this.submitCurve.emit({
          data: this.workingCurve.curveData,
          save: this.saveCurve,
        })
      }
    } else {
      // Update layer set in state
      this.submitCurve.emit({ data: this.workingCurve.curveData, save: false })
    }
  }

  onDropdownChange(): void {
    this.curveType = this.getDropdownType(this.form.controls.curveType.value)
  }

  getDropdownType(type: string): CurveTypeOption | undefined {
    return this.dropdownOptions.find(option => option.value === type)
  }
}
