import {
  COMPARE_METRIC_DEFINITION_CELL_TYPE,
  CreditCompareMetric,
  CreditCompareMetricCategorySetting,
  CreditCompareTableColumn,
  DEFAULT_COMPARE_METRIC_DEFINITION_COLUMN,
} from '../model/credit-compare.model'
import { CreditMetricCategory } from '../model/credit-metric.model'

export const getCompareMetricCategorySettings = (
  metrics: CreditCompareMetric[]
): CreditCompareMetricCategorySetting[] => {
  return Object.values(
    metrics.reduce(
      (
        accumulator: {
          [category in CreditMetricCategory]: CreditCompareMetricCategorySetting
        },
        setting
      ) => {
        const { category } = setting
        if (!(category in accumulator)) {
          accumulator[category] = {
            category,
            metricSettings: [],
          }
        }
        accumulator[category].metricSettings.push({ ...setting, value: 0 })
        return accumulator
      },
      {} as {
        [category in CreditMetricCategory]: CreditCompareMetricCategorySetting
      }
    )
  )
}

export const sortByCategoryAndMetricValue = (
  category: CreditMetricCategory,
  notExpectedLossPmiersOrLossRatio: boolean
): ((a: CreditCompareMetric, b: CreditCompareMetric) => number) => {
  return (a: CreditCompareMetric, b: CreditCompareMetric) => {
    if (category === 'Net') {
      if (notExpectedLossPmiersOrLossRatio) {
        return b.value - a.value
      } else {
        return a.value - b.value
      }
    } else if (category === 'Ceded') {
      if (notExpectedLossPmiersOrLossRatio) {
        return a.value - b.value
      } else {
        return b.value - a.value
      }
    }
    return 0
  }
}

export const buildMetricDefinitionColumn = (
  metricCategorySettings: CreditCompareMetricCategorySetting[]
): CreditCompareTableColumn => {
  return {
    ...DEFAULT_COMPARE_METRIC_DEFINITION_COLUMN,
    categories: metricCategorySettings.map(category => {
      return {
        label: category.category,
        metrics: category.metricSettings.map(m => {
          return {
            value: m.label,
            type: COMPARE_METRIC_DEFINITION_CELL_TYPE,
          }
        }),
      }
    }),
  }
}
