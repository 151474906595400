<div class="pricing-curve-side-element" [class.hidden]="!open" #controlsRef>
  <div class="actions" *ngIf="!readonly">
    <button appButton primary (click)="openAddDataDialog('layers')">
      New Layer Curve
    </button>
    <button
      appButton
      primary
      (click)="openAddDataDialog('manual')"
      *ngIf="context === 'pricing-curve'"
    >
      New Manual Curve
    </button>
  </div>
  <div class="pricing-curve-scroll-element">
    <div class="wrapper">
      <div class="title-row">
        <h2>Saved Pricing Curves</h2>
        <label appInfoText hint small>Drag panel to add to graph.</label>
        <div class="legend">
          <span class="legend-title">Legend:</span>
          <span
            class="legend-color app-palette-cat_ca"
            *ngIf="context === 'pricing-curve'"
            >Manual Curve</span
          >
          <span class="legend-color app-palette-cat_xl">Layer Curve</span>
        </div>
      </div>
      <div class="swiper-container">
        <div
          *ngFor="
            let curve of displayedCurves;
            let i = index;
            trackBy: trackByID
          "
          class="swiper-wrapper"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          <div class="curve-container" id="{{ curve.id }}" cdkDrag>
            <div
              class="curve-details"
              [ngClass]="{
                'app-palette-cat_ca': !curve.included_excluded_layers.length,
                'app-palette-cat_xl': !!curve.included_excluded_layers.length,
              }"
              (mouseover)="
                tooltipSavedCurve = curve; tooltipMouseEvent = $event
              "
              (mouseout)="
                tooltipSavedCurve = undefined; tooltipMouseEvent = undefined
              "
            >
              <span class="curve-name"
                >{{ curve.pc_name }}
                <mat-icon
                  class="immutable-icon"
                  *ngIf="!!curve.isImmutable"
                  matTooltip="Curve is immutable, editing can only be performed by admins."
                  matTooltipPosition="above"
                  >edit_off</mat-icon
                ></span
              >
              <mat-icon
                class="warning-icon"
                *ngIf="!!curve.warnings?.length"
                [matTooltip]="getWarningLabel(curve)"
                matTooltipClass="multiline-tooltip"
                matTooltipPosition="above"
                [matMenuTriggerFor]="menu"
                (click)="checkMenuPermission($event, curve)"
                >error</mat-icon
              >
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="refitCurve(curve.id, 'original')"
                >
                  Refit with Original Layer Selection
                </button>
                <button mat-menu-item (click)="refitCurve(curve.id, 'all')">
                  Refit with All Layers for Filters
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <app-pricing-curve-saved-curve-tooltip
          *ngIf="tooltipSavedCurve && tooltipMouseEvent"
          [savedCurve]="tooltipSavedCurve"
          [mouseEvent]="tooltipMouseEvent"
          parentWidth="50"
        ></app-pricing-curve-saved-curve-tooltip>
      </div>
    </div>
  </div>
  <div class="quick-actions">
    <div class="quick-sort">
      <h4>Quick Sort</h4>
      <div class="sort-options">
        <mat-button-toggle-group (change)="applyQuickSort($event)">
          <mat-button-toggle
            value="date-desc"
            [checked]="activeSortId === 'date-desc'"
            >Date <mat-icon>arrow_downward</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="date-asc"
            [checked]="activeSortId === 'date-asc'"
            >Date <mat-icon>arrow_upward</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="name-asc"
            [checked]="activeSortId === 'name-asc'"
            >Name <mat-icon>arrow_upward</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="name-desc"
            [checked]="activeSortId === 'name-desc'"
            >Name <mat-icon>arrow_downward</mat-icon></mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="quick-filters">
      <h4>Quick Filter</h4>
      <label appInfoText hint small>Click to toggle.</label>
      <div class="filter-options">
        <mat-button-toggle-group
          [multiple]="true"
          (change)="applySortEvent($event)"
        >
          <mat-button-toggle
            [checked]="activeFilters.includes('user')"
            [disabled]="!username"
            value="user"
            >My Curves</mat-button-toggle
          >
          <mat-button-toggle
            [checked]="activeFilters.includes('manual')"
            [disabled]="activeFilters.includes('layer')"
            value="manual"
            *ngIf="context === 'pricing-curve'"
            >Only Manual</mat-button-toggle
          >
          <mat-button-toggle
            [checked]="activeFilters.includes('layer')"
            [disabled]="activeFilters.includes('manual')"
            value="layer"
            >Only Layer</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</div>
