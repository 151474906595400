import { createReducer, on, combineReducers } from '@ngrx/store'
import {
  DEFAULT_QUOTE_CHARTS,
  QUOTE_CHART_METRICS_INFO,
  QUOTE_CHART_GROUP_BYS_INFO,
  QuoteChartGridOption,
  QUOTE_CHARTS_INFO,
} from '../../model/quote-charts.model'
import { QuoteManagementChartsTrendsBase } from '../quote-management-information.reducer'
import * as fromQuoteMIActions from '../quote-management-information.actions'
import * as fromQuoteMIChartActions from './quote-management-information-charts.actions'
import { getChartSplitAndSize } from '../../utils/quote-charts.util'
import { QuoteTabType } from '../../model/quote-management-information.model'

export interface State extends QuoteManagementChartsTrendsBase {
  charts: QuoteChartGridOption[]
}

export const initialCharts: State = {
  charts: DEFAULT_QUOTE_CHARTS,
  metrics: Object.values(QUOTE_CHART_METRICS_INFO),
  selectedMetric: Object.values(QUOTE_CHART_METRICS_INFO)[0],
  groupBys: Object.values(QUOTE_CHART_GROUP_BYS_INFO),
  selectableChartsForTab: Object.values(QUOTE_CHARTS_INFO),
  selectableChartsForMetric: Object.values(QUOTE_CHART_METRICS_INFO)[0]
    .applicableCharts,
  maximizedIndex: null,
  chartEntityLimit: null,
  hideMetrics: false,
  lightChartMode: false
}

const chartsReducer = createReducer(
  initialCharts.charts,
  on(
    fromQuoteMIChartActions.fetchAllQuoteChartsDataSuccess,
    (state, { charts }) => {
      const newState: QuoteChartGridOption[] = []
      for (const chart of charts) {
        const chartInState = state.find(s => s.index === chart.index)
        if (chartInState) {
          newState.push({
            ...chartInState,
            loading: false,
            data: chart.data,
          })
        }
      }
      return newState
    }
  ),
  on(
    fromQuoteMIChartActions.fetchSingleQuoteChartDataSuccess,
    (state, { chart }) => {
      const updatedCharts = [...state]
      updatedCharts.splice(chart.index, 1, {
        ...updatedCharts[chart.index],
        data: chart.data,
        loading: false,
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) => {
      if (tab !== QuoteTabType.CHARTS) {
        return state
      }
      const updatedCharts = [...state]
      const newSelectableCharts = selectedChartsMetric.applicableCharts
      return updatedCharts.map(chart => {
        const updateChartInfo = !newSelectableCharts
          .map(c => c.type)
          .includes(chart.chart.type)
        return {
          ...chart,
          metric: selectedChartsMetric,
          chart: updateChartInfo ? newSelectableCharts[0] : chart.chart,
          ...getChartSplitAndSize(
            updateChartInfo ? newSelectableCharts[0] : chart.chart,
            chart.groupBy
          ),
        }
      })
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGridGroupBy,
    (state, { selectedGroupBy, chartIndex, tab }) => {
      if (tab !== QuoteTabType.CHARTS) {
        return state
      }
      const updatedCharts = [...state]
      updatedCharts.splice(chartIndex, 1, {
        ...updatedCharts[chartIndex],
        groupBy: selectedGroupBy,
        loading: true, // switch to chart loading template on UI
        data: undefined, // reset data on group by switch
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGrid,
    (state, { chartIndex, tab }) => {
      if (tab !== QuoteTabType.CHARTS) {
        return state
      }
      const updatedCharts = [...state]
      const previousSelectedIndex = updatedCharts.findIndex(c => c.highlight)
      if (previousSelectedIndex >= 0) {
        updatedCharts.splice(previousSelectedIndex, 1, {
          ...updatedCharts[previousSelectedIndex],
          highlight: false,
        })
      }
      updatedCharts.splice(chartIndex, 1, {
        ...updatedCharts[chartIndex],
        highlight: true,
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGridOption,
    (state, { chartIndex, chartOption, tab }) => {
      if (tab !== QuoteTabType.CHARTS) {
        return state
      }
      const updatedCharts = [...state]
      if (chartOption.chart.type === updatedCharts[chartIndex].chart.type) {
        updatedCharts.splice(chartIndex, 1, chartOption)
      } else {
        updatedCharts.splice(chartIndex, 1, {
          ...updatedCharts[chartIndex],
          chart: chartOption.chart,
          ...getChartSplitAndSize(chartOption.chart, chartOption.groupBy),
        })
      }
      return updatedCharts
    }
  )
)

const chartsMetricsReducer = createReducer(initialCharts.metrics)

const selectedChartsMetricReducer = createReducer(
  initialCharts.selectedMetric,
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) =>
      tab === QuoteTabType.CHARTS ? selectedChartsMetric : state
  )
)

const chartsGroupBysReducer = createReducer(initialCharts.groupBys)

const selectableChartsForTabReducer = createReducer(
  initialCharts.selectableChartsForTab
)

const selectableChartsForMetricReducer = createReducer(
  initialCharts.selectableChartsForMetric,
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) =>
      tab === QuoteTabType.CHARTS
        ? selectedChartsMetric.applicableCharts
        : state
  )
)

const maximizedIndexReducer = createReducer(
  initialCharts.maximizedIndex,
  on(fromQuoteMIActions.maximizeMIChart, (state, { chartIndex, tab }) => {
    if (tab !== QuoteTabType.CHARTS) {
      return state
    }
    return state !== chartIndex ? chartIndex : null
  })
)

const chartEntityLimitReducer = createReducer(
  initialCharts.chartEntityLimit,
  on(fromQuoteMIActions.setChartEntityLimit, (state, { limit, tab }) =>
    tab === QuoteTabType.CHARTS ? limit : state
  )
)

const hideMetricsReducer = createReducer(
  initialCharts.hideMetrics,
  on(fromQuoteMIActions.updateHideMetrics, (_, { hideMetrics }) => hideMetrics)
)

const lightChartModeReducer = createReducer(
  initialCharts.lightChartMode,
  on(fromQuoteMIActions.updateLightChartMode, (_, { lightChartMode }) => lightChartMode)
)

export const quoteManagementChartsReducer = combineReducers<State>({
  charts: chartsReducer,
  metrics: chartsMetricsReducer,
  selectedMetric: selectedChartsMetricReducer,
  groupBys: chartsGroupBysReducer,
  selectableChartsForTab: selectableChartsForTabReducer,
  selectableChartsForMetric: selectableChartsForMetricReducer,
  maximizedIndex: maximizedIndexReducer,
  chartEntityLimit: chartEntityLimitReducer,
  hideMetrics: hideMetricsReducer,
  lightChartMode: lightChartModeReducer
})
