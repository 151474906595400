<table
  class="app-table app-table-small mat-table"
  mat-table
  [dataSource]="dataSource"
>
  <ng-container matColumnDef="gross">
    <th mat-header-cell *matHeaderCellDef>Gross</th>
    <ng-container *ngIf="animating">
      <td class="edit gross" mat-cell *matCellDef="let result">
        {{ 0 | appShortNumber: currency }}
      </td>
    </ng-container>
    <ng-container *ngIf="!animating">
      <td class="edit gross" mat-cell *matCellDef="let result">
        {{ result.gross | appShortNumber: currency }}
      </td>
    </ng-container>
  </ng-container>
  <ng-container matColumnDef="ceded">
    <th mat-header-cell *matHeaderCellDef>Ceded</th>
    <ng-container *ngIf="animating">
      <td
        class="edit ceded"
        mat-cell
        *matCellDef="let result"
        (click)="cededLossClick.emit(result)"
      >
        {{ 0 | appShortNumber: currency }}
      </td>
    </ng-container>
    <ng-container *ngIf="!animating">
      <td
        class="edit ceded"
        mat-cell
        *matCellDef="let result"
        (click)="cededLossClick.emit(result)"
      >
        {{ result.ceded | appShortNumber: currency }}
      </td>
    </ng-container>
  </ng-container>
  <ng-container matColumnDef="net">
    <th mat-header-cell *matHeaderCellDef>Net</th>
    <ng-container *ngIf="animating">
      <td class="edit net" mat-cell *matCellDef="let result">
        {{ 0 | appShortNumber: currency }}
      </td>
    </ng-container>
    <ng-container *ngIf="!animating">
      <td class="edit net" mat-cell *matCellDef="let result">
        {{ result.net | appShortNumber: currency }}
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
</table>
