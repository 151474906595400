import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'
import { CanSize, CanSizeCtor, mixinSize, Size } from '../size.mixin'

class ButtonBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof ButtonBase = mixinSize(ButtonBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: component-selector
  selector: 'button[appButtonIcon], a[appButtonIcon]',
  styles: [
    `
           :host {
             position: relative;
             display: inline-block;
             white-space: nowrap;
             text-decoration: none;
             text-align: center;
     
             margin: 0;
             padding: 0;
             height: 18px;
             width: 17px;
             vertical-align: -1px;
             font-size: var(--font-size);
             line-height: 18px;
             border-radius: 2px;
     
             cursor: pointer;
             outline: none;
             border: none;
             user-select: none;
     
             background: rgba(0, 0, 0, 0);
             opacity: 0.6;
           }
     
           :host:focus,
           :host:hover {
             background: rgba(0, 0, 0, 0.3);
           }
     
           :host.opaque {
             opacity: 1;
           }
     
           :host.big {
             font-size: var(--font-size-big);
             height: 20px;
             width: 19.5px;
           }
     
           :host.huge {
             font-size: var(--font-size-huge);
             height: 24px;
             width: 24.5px;
             padding: 2px 0;
           }
     
           :host.gigantic {
             font-size: var(--font-size-gigantic);
             height: 32px;
             width: 32.5px;
             padding: 7px 0;
             border-radius: var(--border-radius);
           }
     
           @media screen and (max-width: 1240px) {
             :host.huge {
               font-size: var(--font-size);
               height: 18px;
               width: 17px;
               padding: 0;
             }
     
             :host.gigantic {
               font-size: var(--font-size-huge);
               height: 24px;
               width: 24.5px;
               padding: 2px 0;
             }
           }
         `,
  ],
  template: `
    <span
      *ngIf="tooltip; else noTooltipTemplate"
      [matTooltip]="tooltip"
      [matTooltipPosition]="tooltipPosition"
      [matTooltipShowDelay]="tooltipShowDelay"
    >
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    </span>

    <ng-template #noTooltipTemplate>
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    </ng-template>

    <ng-template #iconTemplate>
      <mat-icon inline>
        <ng-content></ng-content>
      </mat-icon>
    </ng-template>
  `,
})
export class ButtonIconComponent extends _SizeMixinBase implements CanSize {
  @Input() tooltip: string
  @Input() tooltipPosition: TooltipPosition = 'below'

  @Input() set tooltipShowDelay(value: number) {
    this._tooltipShowDelay = coerceNumberProperty(value)
  }
  get tooltipShowDelay(): number {
    return this._tooltipShowDelay
  }
  private _tooltipShowDelay = 200

  @Input() set opaque(value: any) {
    this._opaque = coerceBooleanProperty(value)
  }
  get opaque() {
    return this._opaque
  }
  @HostBinding('class.opaque') _opaque = false

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @Input() huge: boolean
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @Input() gigantic: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
}
