<div class="wrapper">
  <div class="toolbar" *ngIf="!showTable">
    <div class="left">
      <label *ngIf="!hasClient" big appInfoText>
        <span>Select Company</span>
      </label>
      <label *ngIf="hasClient && !hasStudies" big appInfoText>
        <span>Select Program</span>
      </label>
      <label
        *ngIf="showEmptySelectedLabel || isStudyOnList"
        class="empty"
        appInfoText
        big
      >
        <span>Select Program</span>
      </label>
    </div>
  </div>
  <div class="filter-bar" *ngIf="showTable">
    <div class="filter-button">
      <ng-container *ngIf="filterReinsurersEdit">
        <button appButton big translucent [matMenuTriggerFor]="filterMenu">
          <span>
            Filter
            <mat-icon inline>arrow_drop_down</mat-icon>
          </span>
        </button>
        <mat-menu #filterMenu="matMenu">
          <button
            mat-menu-item
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            [matMenuTriggerFor]="domicileFilterMenu"
          >
            Domicile
          </button>
          <mat-menu #domicileFilterMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let d of reinsurerDomicileList"
              (click)="onFilterValueClick($event, DOMICILE_COL, d)"
            >
              <mat-checkbox
                *ngIf="isFilterValueSelected(DOMICILE_COL, d)"
                [checked]="true"
              ></mat-checkbox>
              <mat-checkbox
                *ngIf="!isFilterValueSelected(DOMICILE_COL, d)"
                [checked]="false"
              ></mat-checkbox>
              {{ d }}
            </button>
          </mat-menu>
          <button
            mat-menu-item
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            [matMenuTriggerFor]="spFilterMenu"
          >
            S&P Rating
          </button>
          <mat-menu #spFilterMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let s of reinsurerSPRatingList"
              (click)="onFilterValueClick($event, SP_RATING_COL, s)"
            >
              <mat-checkbox
                *ngIf="isFilterValueSelected(SP_RATING_COL, s)"
                [checked]="true"
              ></mat-checkbox>
              <mat-checkbox
                *ngIf="!isFilterValueSelected(SP_RATING_COL, s)"
                [checked]="false"
              ></mat-checkbox>
              {{ s }}
            </button>
          </mat-menu>
          <button
            mat-menu-item
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            [matMenuTriggerFor]="amBestRatingFilterMenu"
          >
            AM Best Rating
          </button>
          <mat-menu #amBestRatingFilterMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let a of reinsurerAmBestRatingList"
              (click)="onFilterValueClick($event, AM_BEST_RATING_COL, a)"
            >
              <mat-checkbox
                *ngIf="isFilterValueSelected(AM_BEST_RATING_COL, a)"
                [checked]="true"
              ></mat-checkbox>
              <mat-checkbox
                *ngIf="!isFilterValueSelected(AM_BEST_RATING_COL, a)"
                [checked]="false"
              ></mat-checkbox>
              {{ a }}
            </button>
          </mat-menu>
          <!-- Disable appetite filter -->
          <!-- <button
            mat-menu-item
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            [matMenuTriggerFor]="appetiteFilterMenu"
          >
            Appetite
          </button>
          <mat-menu #appetiteFilterMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let ap of appetiteList"
              (click)="onFilterValueClick($event, APPETITE_COL, ap)"
            >
              <mat-checkbox
                *ngIf="isFilterValueSelected(APPETITE_COL, ap)"
                [checked]="true"
              ></mat-checkbox>
              <mat-checkbox
                *ngIf="!isFilterValueSelected(APPETITE_COL, ap)"
                [checked]="false"
              ></mat-checkbox>
              {{ ap }}
            </button>
          </mat-menu> -->
          <button
            mat-menu-item
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
            [matMenuTriggerFor]="surplusFilterMenu"
          >
            Surplus
          </button>
          <mat-menu #surplusFilterMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let su of surplusList"
              (click)="onFilterValueClick($event, SURPLUS_COL, su.value)"
            >
              <mat-checkbox
                *ngIf="isFilterValueSelected(SURPLUS_COL, su.value)"
                [checked]="true"
              ></mat-checkbox>
              <mat-checkbox
                *ngIf="!isFilterValueSelected(SURPLUS_COL, su.value)"
                [checked]="false"
              ></mat-checkbox>
              {{ su.label }}
            </button>
          </mat-menu>
        </mat-menu>
      </ng-container>
    </div>
    <div *ngIf="hasFilters" class="active-filters">
      <div *ngFor="let f of reinsurerFilters" class="single-filter">
        {{ displayFilter(f) }}
        <button
          *ngIf="hasFilters"
          class="clear-filter"
          appButton
          small
          (click)="onRemoveFilter($event, f)"
        >
          <mat-icon inline>clear</mat-icon>
        </button>
      </div>
    </div>
    <div class="active-filters populate">
      <div class="tier-bar-opportunity">
        <button
          [disabled]="!showTable"
          appButton
          big
          accent
          border
          tabindex="-1"
          class="button-tab"
          [matMenuTriggerFor]="moreMenu"
        >
          Populate From
        </button>
        <mat-menu
          #moreMenu="matMenu"
          panelClass="app-menu-translucent"
          backdropClass="app-theme-dark"
          yPosition="below"
          [hasBackdrop]="true"
        >
          <app-reinsurer-selector
            [clients]="clients"
            [selectedClientID]="clientID"
            [selectedYearID]="yearID"
            [selectedProgramID]="studyID"
            (populateSelectorClick)="
              this.populateSelectorClick.emit($event)
            "
          ></app-reinsurer-selector>
        </mat-menu>
      </div>
    </div>
  </div>
  <div
    class="display-flex justify-content-space-between align-items-center"
  >
    <app-filter-input
      class="child"
      transparent
      (filterChange)="createFilter($event)"
    ></app-filter-input>
    <div class="legend-section" *ngIf="reinsurers?.length">
      <span class="legend-box reinsurer-bg-color margin-left-15"></span>
      Reinsurer
      <span class="legend-box agency-card-bg-color margin-left-15"></span>
      Agency
      <span
        class="legend-box outside-approval-date-bg-color margin-left-15"
      ></span>
      Action Required
      <span class="legend-box fund-manager-bg-color margin-left-15"></span>
      Fund Manager
      <span class="legend-box do-not-use-bg-color margin-left-15"></span>
      Do Not Use
    </div>
  </div>
  <section class="reinsurer-selections" *ngIf="showTable" cdkDropListGroup>
    <div class="headers">
      <div class="selection-header">Reinsurer / Agency Selection</div>
      <div class="incumbent-header">Incumbent</div>
      <div class="target-header">Target Market</div>
    </div>
    <div class="buckets-container">
      <div class="main-bucket">
        <div
          class="item"
          cdkDropList
          [cdkDropListData]="{ index: i, bucket: 'selection' }"
          *ngFor="
            let reinsurer of filterReinsurersEdit;
            let i = index;
            trackBy: byID
          "
          [ngClass]="{ 'hide-reinsurer': !showReinsurer(reinsurer) }"
        >
          <app-reinsurer-banner-container
            [reinsurer]="reinsurer"
            [metricDetails]="metricDetails"
            [cdkDragData]="i"
            (showInfo)="showInfo.emit($event)"
            [programs]="programs"
            [selectedProgramID]="studyID"
            [duplicateReinsurers]="duplicateReinsurers"
            [accountOpportunities]="accountOpportunities"
            (cdkDragDropped)="drop($event)"
            [programID]="studyID"
            (showFundManagerModal)="showFundManagerModal.emit($event)"
            cdkDrag
          >
            <div *cdkDragPlaceholder></div>
          </app-reinsurer-banner-container>
        </div>
      </div>
      <mat-icon class="arrow">arrow_right_alt</mat-icon>
      <div
        [cdkDropListData]="{ index: 0, bucket: 'incumbent' }"
        class="bucket"
        cdkDropList
      >
        <app-reinsurer-banner-container
          *ngFor="
            let reinsurer of filterReinsurersIncumbent;
            let i = index;
            trackBy: byID
          "
          [reinsurer]="reinsurer"
          [duplicateReinsurers]="duplicateReinsurers"
          [metricDetails]="metricDetails"
          [cdkDragData]="i"
          class="item"
          (showInfo)="showInfo.emit($event)"
          [programs]="programs"
          [accountOpportunities]="accountOpportunities"
          [selectedProgramID]="studyID"
          (cdkDragDropped)="drop($event)"
          [programID]="studyID"
          (showFundManagerModal)="showFundManagerModal.emit($event)"
          cdkDrag
        >
          <div *cdkDragPlaceholder></div>
        </app-reinsurer-banner-container>
      </div>
      <div
        class="bucket"
        [cdkDropListData]="{ index: 0, bucket: 'marketTarget' }"
        cdkDropList
      >
        <app-reinsurer-banner-container
          *ngFor="
            let reinsurer of filterReinsurersTargetMarket;
            let i = index;
            trackBy: byID
          "
          [reinsurer]="reinsurer"
          [duplicateReinsurers]="duplicateReinsurers"
          [cdkDragData]="i"
          class="item"
          (showInfo)="showInfo.emit($event)"
          [programs]="programs"
          [accountOpportunities]="accountOpportunities"
          [metricDetails]="metricDetails"
          [selectedProgramID]="studyID"
          (cdkDragDropped)="drop($event)"
          [programID]="studyID"
          (showFundManagerModal)="showFundManagerModal.emit($event)"
          cdkDrag
        >
          <div *cdkDragPlaceholder></div>
        </app-reinsurer-banner-container>
      </div>
    </div>
  </section>
</div>
