import { createSelector } from '@ngrx/store'
import { AppState } from '../index'
import { ProgramGroup } from '../../../analysis/store/grouper/program-group.model'
import { rejectNil } from '@shared/util/operators'
import { selectCurrentClient } from '../broker/broker.selectors'
import * as fromProgramGroup from './program-group.reducer'
import { State as ProgramGroupState } from './program-group.state.facade'
import { Dictionary } from '@ngrx/entity'

export const selectProgramGroupState = (state: AppState): ProgramGroupState =>
  state.programGroup

export const {
  selectAll: selectProgramGroups,
  selectEntities: selectProgramGroupsByID,
} = fromProgramGroup.adapter.getSelectors(selectProgramGroupState)

export const findAllProgramGroups = (
  programGroupIDs: Array<string | number> | undefined | null,
  byID?: Dictionary<ProgramGroup>
): ProgramGroup[] =>
  byID ? rejectNil((programGroupIDs || []).map(id => byID[id])) : []

export const selectCurrentClientProgramGroups = createSelector(
  selectCurrentClient,
  selectProgramGroupsByID,
  (client, byID): ProgramGroup[] =>
    findAllProgramGroups(client && client.programGroupIDs, byID)
)
