import { Row, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { Injectable } from '@angular/core'
import { Client } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import {
  AgencyDetails,
  LLOYDS_SYNDICATE_PREFIX,
  Reinsurer,
  ReinsurerPriorYearView,
} from '../../core/model/reinsurer.model'
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { catchAndHandleError, mapToMaybeData } from '../../api/util'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import {
  createInterestNarrativeMap,
  findUniqueRefSections,
  getDataForReinsurerType,
  merge,
  populateRefArray,
} from '../utils/reinsurer.util'
import { locktonLogoBase64, sageLogoBase64 } from '@shared/util/export'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class ReinsurerExportService {
  totalsArr: number[]
  includeWrittenPct: boolean

  constructor(
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe
  ) {}

  exportXLSX(
    client: Client | null,
    program: Study | undefined,
    year: string | undefined,
    reinsurers: Reinsurer[] | null,
    oppDate: string | undefined,
    oppName: string | undefined,
    oppBU: string | undefined,
    oppGI: boolean,
    agencyDetails: AgencyDetails[][],
    priorData: ReinsurerPriorYearView[],
    companyPapers: Record<string, AgencyDetails[]> | null,
    segregatedAccounts: Record<string, AgencyDetails[]> | null,
    customBreakOutSelections: string[],
    customDisplayFieldSelections: string[]
  ) {
    const updatedReinsurers = reinsurers?.filter(r => !r.del_ind) || []
    const workbook = new Workbook()
    const customBreakoutsCond: boolean =
      priorData.length > 0 &&
      customBreakOutSelections &&
      customBreakOutSelections.filter(cbs => cbs !== 'includePct').length > 0

    const breakoutRows =
      customBreakOutSelections.filter(cbs => cbs !== 'includePct').length ?? 0
    const ySplit = priorData.length > 0 ? 9 + breakoutRows : undefined
    const worksheet = workbook.addWorksheet('Marketing List', {
      pageSetup: { fitToPage: true },
      views: [
        { state: 'frozen', xSplit: 1, ySplit: ySplit, showGridLines: false },
      ],
    })
    worksheet.pageSetup.margins = {
      left: 0.2,
      right: 0.2,
      top: 0.5,
      bottom: 0.5,
      header: 0.3,
      footer: 0.3,
    }
    this.totalsArr = []

    let fileName = 'export'
    let exportFileName = 'export'
    const name = oppName ? oppName : client?.name
    if (client?.name && program?.name) {
      fileName = `${name} ${program?.name.replace(` ${year}`, '')} ${year} Marketing List ${this.datePipe.transform(new Date(), 'MMM d, y')}.xlsx`
      exportFileName = `${name} ${program?.name.replace(` ${year}`, '')} ${year} Marketing List.xlsx`
    }
    const title1Row = worksheet.addRow([name])
    title1Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' },
    }
    const title2Row = worksheet.addRow([program?.name + ' Placement'])
    title2Row.font = {
      name: 'Segoe Pro',
      size: 8,
    }
    let effDate = ''
    if (oppDate) {
      const parts = oppDate.split('-')
      effDate =
        this.datePipe.transform(
          new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10)
          ).toString(),
          'longDate'
        ) || ''
    }
    const dateString = 'Effective: ' + effDate
    const title3Row = worksheet.addRow([dateString])
    title3Row.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '5B6670' },
    }
    const title4Row = worksheet.addRow([
      'Proposed Reinsurance Placement Marketing List',
    ])
    title4Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' },
    }
    const date = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    const dateRow = worksheet.addRow([date])
    dateRow.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5B6670' },
      }
    })
    const uniqueRefSections: ReinsurerPriorYearView[] = customBreakoutsCond
      ? findUniqueRefSections(priorData, customBreakOutSelections)
      : priorData
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                t =>
                  t.interestNarrative === value.interestNarrative &&
                  t.sectionNum === value.sectionNum &&
                  t.layerName === value.layerName
              )
          )
          .sort((a, b) => {
            const aNarr = a.interestNarrative
            const bNarr = b.interestNarrative
            return aNarr < bNarr ? -1 : aNarr > bNarr ? 1 : 0
          })

    if (customBreakoutsCond) {
      uniqueRefSections.sort((a, b) => (a.sectionNum < b.sectionNum ? -1 : 1))
    }
    const totalCols =
      uniqueRefSections.length * (this.includeWrittenPct ? 2 : 1) +
      (customBreakoutsCond ? 1 : 0)
    const refsHeaderArray: string[] = []
    const refsSubHeaderArray: string[] = ['']

    const refsTitleArray: string[] = []
    let refsIncYearArray: string[] = []
    let refsExpYearArray: string[] = []
    let refsAdjRateArray: (string | number)[] = []
    let refsCurrencyArray: (string | number)[] = []
    let refsRiskLimitArray: (string | number)[] = []
    let refsOccLimitArray: (string | number)[] = []
    let refsOccAttArray: (string | number)[] = []
    let refsInitialPremAmtArray: (string | number)[] = []
    let refsMinimumPremiumAmountArray: (string | number)[] = []
    let refsAggLimitArray: (string | number)[] = []
    let refsAggAttArray: (string | number)[] = []
    let refsReinstatementsArray: string[] = []
    let refsNotesArray: string[] = []
    this.includeWrittenPct = customBreakOutSelections.some(
      a => a === 'includePct'
    )

    // Target Market Data
    const tmData = this.getData(
      'tm',
      updatedReinsurers,
      agencyDetails,
      uniqueRefSections,
      priorData,
      reinsurers,
      companyPapers,
      segregatedAccounts,
      oppBU,
      oppGI,
      customBreakOutSelections.filter(s => s !== 'includePct')
    )

    // Incumbent Data
    const incData = this.getData(
      'inc',
      updatedReinsurers,
      agencyDetails,
      uniqueRefSections,
      priorData,
      reinsurers,
      companyPapers,
      segregatedAccounts,
      oppBU,
      oppGI,
      customBreakOutSelections.filter(s => s !== 'includePct')
    )
    let containsLlyodsSyndicates = false
    if(priorData.length > 0) {
      containsLlyodsSyndicates = priorData.some(pd => {
        return pd.reinsurerName.startsWith(LLOYDS_SYNDICATE_PREFIX)
      })
    } else {
      containsLlyodsSyndicates = incData.some(innerDataArray => {
        const name = innerDataArray[0]
        return typeof name === 'string' && name.startsWith(LLOYDS_SYNDICATE_PREFIX);
      })
    }
    const uniqueRefColCountMap = createInterestNarrativeMap(uniqueRefSections)

    const priorPctLabel = this.includeWrittenPct
      ? 'Prior Percentage Written and Signed'
      : 'Prior Percentage Signed'
    if (customBreakoutsCond) {
      refsHeaderArray.push(' ')
      uniqueRefSections.forEach((_, ind) => {
        if (ind === 0) {
          refsTitleArray.push(priorPctLabel)
          // refsHeaderArray.push(rf.interestNarrative || ' ') -- commenting out until decision is made on custom breakouts header labeling
          refsHeaderArray.push(' ')
        } else {
          refsHeaderArray.push(' ')
          refsTitleArray.push(' ')
        }
        if (this.includeWrittenPct) {
          refsTitleArray.push(' ')
          refsHeaderArray.push(' ')
        }
      })
      refsIncYearArray = populateRefArray(
        'inceptionDate',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsExpYearArray = populateRefArray(
        'expireDate',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsAdjRateArray = populateRefArray(
        'adjustRate',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsCurrencyArray = populateRefArray(
        'currency',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsRiskLimitArray = populateRefArray(
        'riskLimit',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsOccLimitArray = populateRefArray(
        'occLimit',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsOccAttArray = populateRefArray(
        'excess',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsInitialPremAmtArray = populateRefArray(
        'deposit',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsMinimumPremiumAmountArray = populateRefArray(
        'minimum',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsAggLimitArray = populateRefArray(
        'aggLimit',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsAggAttArray = populateRefArray(
        'aggAttachment',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsReinstatementsArray = populateRefArray(
        'reinstatements',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
      refsNotesArray = populateRefArray(
        'notes',
        uniqueRefSections,
        customBreakOutSelections,
        this.datePipe,
        this.currencyPipe,
        this.includeWrittenPct
      )
    } else {
      uniqueRefSections.forEach((rf, i) => {
        if (i === 0) {
          refsHeaderArray.push(rf.interestNarrative || ' ')
          refsTitleArray.push(priorPctLabel)
        } else if (
          uniqueRefSections[i - 1].interestNarrative === rf.interestNarrative
        ) {
          refsHeaderArray.push(' ')
          refsTitleArray.push(' ')
        } else {
          refsHeaderArray.push(rf.interestNarrative || ' ')
          refsTitleArray.push(' ')
        }
        refsSubHeaderArray.push(rf.layerName ?? 'N/A')
        if (this.includeWrittenPct) {
          refsHeaderArray.push(' ')
          refsSubHeaderArray.push(' ')
        }
      })
    }

    // Blank Row
    const blankArray = [
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
    ]
    let refsHeaderArrayStartCell =
      refsHeaderArray.indexOf(priorPctLabel) - (customBreakoutsCond ? 1 : 2)

    const blankWithRefTitle = merge(
      blankArray,
      refsTitleArray,
      refsHeaderArrayStartCell
    )
    uniqueRefSections.forEach(_ => {
      blankWithRefTitle.push(' ')
      if (this.includeWrittenPct) {
        blankWithRefTitle.push(' ')
      }
    })
    const titleRefRow = worksheet.addRow(blankWithRefTitle)
    let titleRefRowStartCell = 0
    titleRefRow.eachCell((c, i) => {
      if (i === 1 && containsLlyodsSyndicates) {
        c.value =
          '** Note: Lloyd’s syndicates are backed by Lloyd’s central assets so individual equity / surplus figures are not shown.'
        c.font = {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: 'A0AAAB' },
          bold: true,
          italic: true,
        }
        c.alignment = {
          horizontal: 'left',
          wrapText: true,
        }
      }
      if (c.value === priorPctLabel) {
        titleRefRowStartCell = i
      }
      c.alignment = { horizontal: 'left', wrapText: false }
    })

    /* Set width of columns*/
    for (let i = 1; i <= 100; i++) {
      if (worksheet.getColumn(i)) {
        worksheet.getColumn(i).width = 16
      }
    }
    worksheet.getColumn(1).width = 90
    worksheet.getColumn(2).width = 16
    worksheet.getColumn(14 + totalCols).width = 16

    /* Update column constants when new column added */
    const TOTAL_COLUMNS = (customBreakoutsCond ? 21 : 20) + totalCols
    const INITIAL_COLUMN = 'A'
    const END_COLUMN = this.getExcelColName(19 + totalCols)
    const header1Row = [
      'Reinsurance Company',
      'NAIC / AIIN',
      'FEIN',
      'LORS',
      'Domicile',
      'A.M. Best Rating',
      'A.M. Best Rating Date',
      'A.M. Best Outlook',
      'S&P Rating',
      'S&P Rating Date',
      'S&P Outlook',
      'S&P Outlook Date',
      'Last Review Date',
      'Policyholders Surplus/ Total Equity (000s)',
      'Policyholders Surplus/ Total Equity Type',
      'Policyholders Surplus/ Total Equity As of Date',
      'Placed Through',
      'Correspondent Broker',
      client?.name + ' Approval',
      'Lockton Approval Status',
      'Lockton Market Security Grade',
      'Lockton Market Security Restrictions',
    ]

    const updatedHeaderRow = merge(header1Row, refsHeaderArray, 19)
    const tableLength = updatedHeaderRow.length
    const headerRow = worksheet.addRow(updatedHeaderRow)
    headerRow.height = 48
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, i) => {
      cell.alignment = { wrapText: true }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '737F8B' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
      if (
        !customBreakoutsCond &&
        typeof cell.value === 'string' &&
        uniqueRefColCountMap.has(cell.value)
      ) {
        try {
          const mergeCount = uniqueRefColCountMap.get(cell.value)
          worksheet.mergeCells(
            headerRow.number,
            i,
            headerRow.number,
            i + mergeCount * (this.includeWrittenPct ? 2 : 1) - 1
          )
        } catch (e) {
          console.warn(e)
        }
      }
      cell.alignment = { horizontal: 'center', wrapText: true }
      if (
        priorData.length > 0 &&
        !customBreakoutsCond &&
        i >= titleRefRowStartCell &&
        i < tableLength - 2
      ) {
        cell.border = {
          top: {
            style: 'medium',
            color: { argb: '000000' },
          },
          left: {
            style: 'medium',
            color: { argb: '000000' },
          },
          right: {
            style: 'medium',
            color: { argb: '000000' },
          },
        }
      }
    })
    titleRefRow.eachCell((c, i) => {
      if (
        i >= titleRefRowStartCell &&
        i < tableLength - 2 &&
        priorData.length > 0
      ) {
        c.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F2F2F2' },
        }
        c.font = {
          name: 'Segoe Pro',
          size: 8,
          bold: true,
          color: { argb: '000000' },
        }
      }
      c.alignment = {
        ...c.alignment,
        horizontal: 'left',
        wrapText: true,
      }
    })
    worksheet.mergeCells(
      titleRefRow.number,
      titleRefRowStartCell,
      titleRefRow.number,
      titleRefRowStartCell + 1
    )
    // This is the blue row
    const incRowSubHeader = [
      'Incumbent Markets',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]
    let updatedIncRow
    if (customBreakoutsCond) {
      refsTitleArray.forEach(_ => {
        incRowSubHeader.push(' ')
      })
      incRowSubHeader.push(' ')
      updatedIncRow = merge(incRowSubHeader, refsSubHeaderArray, 20)
    } else {
      updatedIncRow = merge(incRowSubHeader, refsSubHeaderArray, 18)
    }
    if (refsIncYearArray.length) {
      const refsIncYearRow = merge(blankArray, refsIncYearArray, 19)
      const incYearRow = worksheet.addRow(refsIncYearRow)
      if (this.includeWrittenPct) {
        for (let i = 19; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      incYearRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (ind > 20 && ind <= 20 + refsTitleArray.length) {
          c.border = {
            top: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
          if (ind % 2 !== 1 || !this.includeWrittenPct) {
            c.border = {
              ...c.border,
              right: {
                style: 'medium',
                color: { argb: '000000' },
              },
              top: {
                style: 'medium',
                color: { argb: '000000' },
              },
            }
          }
        }
      })
    }
    if (refsExpYearArray.length) {
      const refsExpYearRow = merge(blankArray, refsExpYearArray, 19)
      const expYear = worksheet.addRow(refsExpYearRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      expYear.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      })
    }
    if (refsOccLimitArray.length) {
      const refsOccLimitRow = merge(blankArray, refsOccLimitArray, 19)
      const occLimitRow = worksheet.addRow(refsOccLimitRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      occLimitRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsCurrencyArray.length) {
      const refsCurrencyRow = merge(blankArray, refsCurrencyArray, 19)
      const currencyRow = worksheet.addRow(refsCurrencyRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      currencyRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      })
    }
    if (refsRiskLimitArray.length) {
      const refsRiskLimitRow = merge(blankArray, refsRiskLimitArray, 19)
      const riskLimitRow = worksheet.addRow(refsRiskLimitRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      riskLimitRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsOccAttArray.length) {
      const refsOccAttRow = merge(blankArray, refsOccAttArray, 19)
      const occAttRow = worksheet.addRow(refsOccAttRow)
      if (this.includeWrittenPct) {
        for (let i = 19; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      occAttRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsAggLimitArray.length) {
      const refsAggLimitRow = merge(blankArray, refsAggLimitArray, 19)
      const aggLimitRow = worksheet.addRow(refsAggLimitRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      aggLimitRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsAggAttArray.length) {
      const refsAggAttRow = merge(blankArray, refsAggAttArray, 19)
      const aggAttRow = worksheet.addRow(refsAggAttRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      aggAttRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsReinstatementsArray.length) {
      const refsReinstatementsRow = merge(
        blankArray,
        refsReinstatementsArray,
        19
      )
      const reinstatementsRow = worksheet.addRow(refsReinstatementsRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      reinstatementsRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      })
    }
    if (refsInitialPremAmtArray.length) {
      const refsPremAmtRow = merge(blankArray, refsInitialPremAmtArray, 19)
      const premAmt = worksheet.addRow(refsPremAmtRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      premAmt.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsMinimumPremiumAmountArray.length) {
      const refsMinPremAmtRow = merge(
        blankArray,
        refsMinimumPremiumAmountArray,
        19
      )
      const minPremAmtRow = worksheet.addRow(refsMinPremAmtRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      minPremAmtRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,##0'
      })
    }
    if (refsAdjRateArray.length) {
      const refsAdjRateRow = merge(blankArray, refsAdjRateArray, 19)
      const adjRateRow = worksheet.addRow(refsAdjRateRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      adjRateRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        c.numFmt = '#,#0.000"%"'
      })
    }
    if (refsNotesArray.length) {
      const refsNotesRow = merge(blankArray, refsNotesArray, 19)
      const notesRow = worksheet.addRow(refsNotesRow)
      if (this.includeWrittenPct) {
        for (let i = 21; i <= tableLength; i += 2) {
          worksheet.mergeCells(worksheet.rowCount, i, worksheet.rowCount, i + 1)
        }
      }
      notesRow.eachCell((c, ind) => {
        c.alignment = { horizontal: 'center', wrapText: true }
        if (ind === 20) {
          c.alignment = { horizontal: 'center', wrapText: true }
          c.font = {
            name: 'Segoe Pro',
            bold: true,
            color: { argb: '000000' },
          }
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (
          (!this.includeWrittenPct || ind % 2 !== 1) &&
          ind > 20 &&
          ind <= 20 + refsTitleArray.length
        ) {
          c.border = {
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      })
    }
    const incRow = worksheet.addRow(updatedIncRow)
    incRow.height = 16
    incRow.eachCell((c, i) => {
      const leftIndex = customBreakoutsCond ? 20 : 19
      c.style = {
        alignment: { horizontal: 'center', wrapText: true },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00AEEF' },
        },
        font: {
          name: 'Segoe Pro',
          size: 8,
          bold: true,
          color: { argb: 'FFFFFF' },
        },
        border: {
          top: {
            style: 'medium',
            color: { argb: '00AEEF' },
          },
        },
      }
      if (
        i > leftIndex &&
        i <= leftIndex + refsTitleArray.length * (!customBreakoutsCond ? 2 : 1)
      ) {
        c.border = {
          ...c.border,
          top: {
            style: 'medium',
            color: { argb: '000000' },
          },
          right: {
            style: 'medium',
            color: { argb: '000000' },
          },
          left: {
            style: 'medium',
            color: { argb: '000000' },
          },
        }
      }
    })
    if (this.includeWrittenPct) {
      for (
        let i = customBreakoutsCond ? 21 : 20;
        i <= tableLength - 4;
        i += 2
      ) {
        worksheet.mergeCells(incRow.number, i, incRow.number, i + 1)
      }
    }
    const measurementArray =
      customBreakoutsCond || !this.includeWrittenPct
        ? refsTitleArray
        : refsSubHeaderArray
    const labelFixup: string[] = measurementArray.map((_, i) => {
      const showBlank =
        !customBreakoutsCond &&
        i === measurementArray.length + (this.includeWrittenPct ? -1 : 1)
      return showBlank
        ? ' '
        : this.includeWrittenPct
          ? i % 2 === 0
            ? 'Written %'
            : 'Signed %'
          : 'Signed %'
    })
    const updatedLabelsRow = merge(
      [...Array(customBreakoutsCond ? 20 : 19)],
      labelFixup,
      customBreakoutsCond ? 21 : 20
    ).slice(0, tableLength)
    if (priorData.length > 0) {
      const labelsRow = worksheet.addRow(updatedLabelsRow.slice(0, tableLength))
      labelsRow.eachCell((cell, i) => {
        const leftIndex = customBreakoutsCond ? 21 : 20
        const isEven = customBreakoutsCond ? i % 2 === 1 : i % 2 !== 1
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: '000000' },
        }
        if (
          !isEven &&
          i >= leftIndex &&
          i <= leftIndex + measurementArray.length &&
          this.includeWrittenPct
        ) {
          cell.font = {
            name: 'Segoe Pro',
            size: 8,
            color: { argb: '00AEEF' },
          }
        }
        cell.alignment = { horizontal: 'center' }
        if (
          (!this.includeWrittenPct || !isEven) &&
          i >= leftIndex &&
          i <= leftIndex + measurementArray.length
        ) {
          if (uniqueRefSections && uniqueRefSections.length > 0) {
            cell.border = {
              ...cell.border,
              right: {
                style: 'medium',
                color: { argb: '000000' },
              },
            }
          }
        }
        if (i === leftIndex) {
          cell.border = {
            ...cell.border,
            left: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      })
    }

    incData.forEach(d => {
      if (customBreakoutsCond) {
        d.splice(19, 0, '')
      }
      const dataRow = worksheet.addRow(d)
      dataRow.eachCell((c, i) => {
        c.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        c.border = {
          ...c.border,
          bottom: {
            style: 'thin',
            color: { argb: '000000' },
          },
        }
        const isEven = customBreakoutsCond ? i % 2 === 1 : i % 2 !== 1
        const leftIndex = customBreakoutsCond ? 21 : 20
        if (
          !isEven &&
          i >= leftIndex &&
          i <=
            leftIndex +
              measurementArray.length -
              (customBreakoutsCond ? 1 : 2) &&
          this.includeWrittenPct
        ) {
          c.font = {
            name: 'Segoe Pro',
            size: 8,
            color: { argb: '00AEEF' },
          }
        }
        c.alignment = { wrapText: true }
        c.alignment = { horizontal: 'center', wrapText: true }

        if (customBreakoutsCond) {
          if (i >= leftIndex && i <= leftIndex + measurementArray.length) {
            c.numFmt = '#0.000"%"'
            if (uniqueRefSections && uniqueRefSections.length > 0) {
              if (
                (isEven && customBreakoutsCond) ||
                (!isEven && !customBreakoutsCond) ||
                !this.includeWrittenPct
              ) {
                c.border = {
                  ...c.border,
                  left: {
                    style: 'medium',
                    color: { argb: '000000' },
                  },
                }
              }
              if (i === leftIndex + refsTitleArray.length - 1) {
                c.border = {
                  ...c.border,
                  right: {
                    style: 'medium',
                    color: { argb: '000000' },
                  },
                }
              }
            }
          }
        } else {
          if (i >= leftIndex) {
            c.numFmt = '#0.000"%"'
            if (
              (isEven || !this.includeWrittenPct) &&
              i <= leftIndex + measurementArray.length &&
              uniqueRefSections &&
              uniqueRefSections.length > 0
            ) {
              c.border = {
                ...c.border,
                left: {
                  style: 'medium',
                  color: { argb: '000000' },
                },
              }
            }
            // Added condition to add the border to the right if the columns after prior percentage signed are not selected
            if (
              this.includeWrittenPct &&
              i === leftIndex + measurementArray.length - 2
            ) {
              c.border = {
                ...c.border,
                right: {
                  style: 'medium',
                  color: { argb: '000000' },
                },
              }
            } else if (
              !this.includeWrittenPct &&
              i === leftIndex + measurementArray.length - 1
            ) {
              c.border = {
                ...c.border,
                right: {
                  style: 'medium',
                  color: { argb: '000000' },
                },
              }
            }
          }
        }
        if (i === tableLength - 2 || i === tableLength) {
          c.alignment = {
            ...c.alignment,
            horizontal: 'left',
            wrapText: false,
          }
        }
      })
      if (
        d[16] === 'Restricted' ||
        d[16] === 'Unauthorized' ||
        d[16] === 'AUTHRQ'
      ) {
        dataRow.eachCell((cell, i) => {
          if (i >= 1 && i <= TOTAL_COLUMNS) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'A2AAAD' },
            }
          }
        })
      }
      if (d[16] === 'Collateralized' || d[17] === 'Collateralized') {
        dataRow.eachCell((cell, i) => {
          if (i >= 1 && i <= TOTAL_COLUMNS) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'A2AAAD' },
            }
          }
        })
      }
    })
    uniqueRefSections.forEach(() => {
      blankArray.push(' ')
    })
    let emptyRow: string[] = []
    const tmRowSubHeader = ['Target Markets']
    updatedIncRow.forEach((_, i) => {
      if (i !== 0) {
        tmRowSubHeader.push(' ')
      }
    })
    const tmRow = worksheet.addRow(tmRowSubHeader)
    tmRow.eachCell((c, i) => {
      const leftIndex = customBreakoutsCond ? 21 : 20
      const isEven = customBreakoutsCond ? i % 2 === 1 : i % 2 !== 1
      c.alignment = { horizontal: 'center' }
      c.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
      c.border = {
        top: {
          style: 'medium',
          color: { argb: '00AEEF' },
        },
      }
      if (
        i >= leftIndex &&
        i <= leftIndex + measurementArray.length &&
        uniqueRefSections &&
        uniqueRefSections.length > 0
      ) {
        if (
          !this.includeWrittenPct ||
          (isEven && customBreakoutsCond) ||
          (isEven && !customBreakoutsCond)
        ) {
          c.border = {
            ...c.border,
            left: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        /*  this condition is added to add the border to the right if the columns after prior percentage signed are not selected.
         */
        if (
          !this.includeWrittenPct &&
          i === leftIndex + measurementArray.length - 1
        ) {
          c.border = {
            ...c.border,
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
      }
    })

    tmData.forEach((d, j) => {
      if (customBreakoutsCond) {
        d.splice(19, 0, '')
      }
      const dataRow = worksheet.addRow(d)
      dataRow.eachCell((c, i) => {
        c.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        c.border = {
          ...c.border,
          bottom: {
            style: 'thin',
            color: { argb: j !== tmData.length - 1 ? '000000' : '00AEEF' },
          },
        }
        const isEven = customBreakoutsCond ? i % 2 === 1 : i % 2 !== 1
        const leftIndex = customBreakoutsCond ? 21 : 20

        c.alignment = { horizontal: 'center', wrapText: true }

        if (
          !isEven &&
          i >= leftIndex &&
          i <=
            leftIndex +
              measurementArray.length -
              (customBreakoutsCond ? 1 : 2) &&
          this.includeWrittenPct
        ) {
          c.font = {
            name: 'Segoe Pro',
            size: 8,
            color: { argb: '00AEEF' },
          }
        }
        if (i >= leftIndex && i <= leftIndex + measurementArray.length) {
          c.numFmt = '#0.000"%"'
          if (uniqueRefSections && uniqueRefSections.length > 0) {
            if (!this.includeWrittenPct || isEven) {
              c.border = {
                ...c.border,
                left: {
                  style: 'medium',
                  color: { argb: '000000' },
                },
              }
            }
            if (
              i ===
              leftIndex +
                measurementArray.length -
                (customBreakoutsCond ? 1 : 2)
            ) {
              c.border = {
                ...c.border,
                right: {
                  style: 'medium',
                  color: { argb: '000000' },
                },
              }
            }
          }
        }
        if (
           priorData.length > 0 && !this.includeWrittenPct &&
          i === leftIndex + measurementArray.length - 1
        ) {
          c.border = {
            ...c.border,
            right: {
              style: 'medium',
              color: { argb: '000000' },
            },
          }
        }
        if (i === tableLength - 2 || i === tableLength) {
          c.alignment = {
            ...c.alignment,
            horizontal: 'left',
            wrapText: false,
          }
        }
      })
      if (
        // TO DO UPDATE THIS WITH NEW COLUMN ONCE CALCULATED
        d[14 + totalCols] === 'Restricted' ||
        d[14 + totalCols] === 'Unauthorized'
      ) {
        dataRow.eachCell((cell, i) => {
          if (i >= 1 && i <= TOTAL_COLUMNS) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'A2AAAD' },
            }
          }
        })
      }
      if (
        d[14 + totalCols] === 'Collateralized' ||
        d[15 + totalCols] === 'Collateralized'
      ) {
        dataRow.eachCell((cell, i) => {
          if (i >= 1 && i <= TOTAL_COLUMNS) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'A2AAAD' },
            }
          }
        })
      }
      if (
        d[0]?.toString().includes('obo segregated account') ||
        d[0]?.toString().includes('o.b.o. segregated account') ||
        d[16 + totalCols]?.toString().includes('segregated account')
      ) {
        dataRow.eachCell((cell, i) => {
          if (i >= 2 && i <= TOTAL_COLUMNS) {
            cell.value = ''
          }
        })
      }
    })

    const totalsFixup: number[] = this.totalsArr
      .slice(0, uniqueRefSections.length * (this.includeWrittenPct ? 2 : 1))
      .map(a => (a ? parseFloat((a / 100).toFixed(3)) : 0))

    const updatedTotalsRow = merge(
      blankArray,
      totalsFixup,
      customBreakoutsCond ? 20 : 19
    )
    let totalsRow: Row
    if (uniqueRefSections && uniqueRefSections.length > 0) {
      totalsRow = worksheet.addRow(updatedTotalsRow.slice(0, tableLength))
      totalsRow.eachCell((cell, i) => {
        const isEven = customBreakoutsCond ? i % 2 === 1 : i % 2 !== 1
        if (i === 1) {
          cell.value = 'Totals'
          cell.numFmt = null
        } else {
          cell.numFmt = '0.000%'
        }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          bold: true,
          color: { argb: '000000' },
        }
        cell.border = {
          top: {
            style: 'thin',
            color: { argb: '00AEEF' },
          },
        }
        cell.alignment = { horizontal: 'center' }
        if (this.includeWrittenPct && !isEven && i !== 1) {
          cell.font.color = {
            argb: '00AEEF',
          }
        }
      })
    } else {
      totalsRow = worksheet.addRow(updatedTotalsRow.slice(0, tableLength))
    }

    if (customBreakoutsCond) {
      updatedHeaderRow.forEach((h, i) => {
        if (
          h.toLowerCase().includes('date') &&
          !h.toLowerCase().includes('policy')
        ) {
          worksheet.getColumn(i + 1).hidden = true
        }
      })
      worksheet.getColumn('R').width = 28
    }

    if (customDisplayFieldSelections.length > 0) {
      const startIndex = updatedHeaderRow.indexOf('Correspondent Broker')
      const endIndex = updatedHeaderRow.indexOf('Lockton Approval Status')
      const priorPercentageSignedIndices: number[] = []
      if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        for (let i = startIndex + 2; i < endIndex; i++) {
          priorPercentageSignedIndices.push(i)
        }
      }
      updatedHeaderRow.forEach((h, i) => {
        if (
          h !== 'Reinsurance Company' &&
          !priorPercentageSignedIndices.includes(i)
        ) {
          if (!customDisplayFieldSelections.includes(h)) {
            worksheet.getColumn(i + 1).hidden = true
          }
        }
      })
    }

    worksheet.getColumn(tableLength).width = 100
    worksheet.getColumn(tableLength - 2).width = 40
    updatedHeaderRow.forEach((_, i) => {
      if (
        i >= titleRefRowStartCell &&
        i < tableLength - 2 &&
        priorData.length > 0
      ) {
        if (!!worksheet.getColumn(i)) worksheet.getColumn(i).width = 20
      }
    })

    const logoRow = totalsRow.number + 5
    const logo = workbook.addImage({
      base64: locktonLogoBase64,
      extension: 'png',
    })
    worksheet.addImage(logo, {
      tl: { col: 5, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })
    const sageLogo = workbook.addImage({
      base64: sageLogoBase64,
      extension: 'png',
    })
    const sageLogoTopLeftCol = 10 + (customBreakoutsCond ? 4 : 0)

    worksheet.addImage(sageLogo, {
      tl: { col: sageLogoTopLeftCol, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet.getCell(
      patentLinkRow,
      sageLogoTopLeftCol + 1
    )
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents',
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      },
    }

    worksheet.pageSetup.printArea = `${INITIAL_COLUMN}1:${END_COLUMN}${totalsRow.number}`

    // workbook.xlsx.writeBuffer().then((data: BlobPart) => {
    //   const blob = new Blob([data], {
    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   })
    //   saveAs(blob, fileName)
    // })

    workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName)
      const base64FromBlob = (blobII: Blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blobII)
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      }
      base64FromBlob(blob).then(base64Response => {
        this.captureExportDetails(
          base64Response,
          exportFileName,
          'ReinsurerSelection'
        )
          .pipe()
          .subscribe()
      })
    })
  }

  captureExportDetails(
    encodedData: any,
    filename: string,
    exportSelection: string
  ) {
    const cr = {
      blobData: encodedData,
      fileName: filename,
      selection: exportSelection,
    }
    // Upload attached fac files
    const url = `${environment.internalApi.base}${environment.internalApi.captureExportDetails}`
    return this.httpClient
      .post(url, JSON.stringify(cr), httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Capture Export Details')
      )
  }

  private getData(
    type: 'tm' | 'inc',
    updatedReinsurers: Reinsurer[] | null,
    agencyDetails: AgencyDetails[][],
    uniqueRefSections: ReinsurerPriorYearView[],
    priorData: ReinsurerPriorYearView[],
    reinsurers: Reinsurer[] | null,
    allCompanyPapers: Record<string, AgencyDetails[]> | null,
    allSegregatedAccounts: Record<string, AgencyDetails[]> | null,
    oppBU: string,
    oppGI: boolean,
    customBreakoutSelections?: string[]
  ) {
    const spliceInd = 19
    if (!updatedReinsurers) {
      return []
    }
    const result: (string | number | null)[][] = getDataForReinsurerType(
      type,
      updatedReinsurers,
      agencyDetails,
      uniqueRefSections,
      priorData,
      reinsurers,
      allCompanyPapers,
      allSegregatedAccounts,
      spliceInd,
      this.datePipe,
      this.totalsArr,
      this.includeWrittenPct,
      this.currencyPipe,
      oppBU,
      oppGI,
      customBreakoutSelections
    )
    result.sort((a, b) => {
      if (a[16] === 'Lockton Re' && a[17] === '') {
        return b[16] === 'Lockton Re' && b[17] === ''
          ? (a[0] as string).localeCompare(b[0] as string)
          : -1
      } else if (a[16] === 'Lockton Re' && a[17] !== '') {
        return b[16] === 'Lockton Re'
          ? b[17] !== ''
            ? 1
            : (a[0] as string).localeCompare(b[0] as string)
          : -1
      } else {
        return b[16] !== 'Lockton Re'
          ? (a[0] as string).localeCompare(b[0] as string)
          : 1
      }
    })
    return result
  }

  private getExcelColName(n: number) {
    const ordA = 'a'.charCodeAt(0)
    const ordZ = 'z'.charCodeAt(0)
    const len = ordZ - ordA + 1

    let s = ''
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s
      n = Math.floor(n / len) - 1
    }
    return s.toLocaleUpperCase()
  }
}
