import { QuoteManagementInformationEffects } from './management-information/store/quote-management-information.effects'
import { NgModule } from '@angular/core'
import { SharedModule } from '../shared/shared.module'
import { StoreModule } from '@ngrx/store'
import * as fromQuoteReducer from './store/quote.reducer'
import { EffectsModule } from '@ngrx/effects'
import { MaterialModule } from '@shared/material.module'
import { QuoteContainerComponent } from './quote.container/quote.container'
import { QuoteComponent } from './quote.component/quote.component'
import { QuoteRoutingModule } from './quote-routing.module'
import { QuoteEffects } from './store/quote.effects'
import { QuotePanelComponent } from './quote-panel/quote-panel.component'
import { QuoteActionsComponent } from './quote-actions/quote-actions.component'
import { QuoteSubjectivityComponent } from './quote-subjectivity/quote-subjectivity.component'
import { QuoteReinsurerComponent } from './quote-reinsurer/quote-reinsurer.component'
import { QuoteTrackingComponent } from './quote-tracking/quote-tracking.component'
import {
  SwiperConfigInterface,
  SwiperModule,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper-v-13'
import { QuoteRouteContainerComponent } from './quote-route.container'
import { TierModule } from 'src/app/tier/tier.module'
import { BrokerModule } from 'src/app/broker/broker.module'
import { QuoteHeaderComponent } from './quote-header/quote-header.component'
import { QuoteContentContainerComponent } from './quote-content.container/quote-content.container'
import { QuoteContentComponent } from './quote-content/quote-content.component'
import { CommonModule } from '@angular/common'
import { QuoteTogglesComponent } from './quote-toggles/quote-toggles.component'
import { QuoteExportService } from './export/quote-export.service'
import { QuoteAssignedLinesComponent } from './quote-assigned-lines/quote-assigned-lines.component'
import { QuoteExpiringDetailsDialogComponent } from './expiring-details-dialog/quote-expiring-details-dialog.component'
import { QuoteRiskCodeDialogComponent } from './risk-codes/risk-code-dialog/quote-risk-code-dialog.component'
import { QuoteRiskCodeDialogContainerComponent } from './risk-codes/risk-code-dialog/quote-risk-code-dialog.container'
import { QuoteSegregatedAccountDetailsDialogContainerComponent } from './quote-seg-account-detail/quote-seg-account-detail.container'
import { QuoteSegregatedAccountDetailsDialogComponent } from './quote-seg-account-detail/quote-seg-account-detail.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { SectionsEffects } from './store/section/section.effects'
import { ReinsurersEffects } from './store/reinsurer/reinsurer.effects'
import { QuoteManagementInformationChartEffects } from './management-information/store/charts/quote-management-information-charts.effects'
import { QuoteManagementInformationTrendsEffects } from './management-information/store/trends/quote-management-information-trends.effects'
import { QuotePushWsWarningsDialogComponent } from './quote-push-ws-warnings-dialog/quote-push-ws-warnings-dialog.component'
import { QuickQuoteComponent } from './quick-quote/quick-quote.component'
import { QuickQuoteEffects } from './store/quick-quote/quick-quote.effects'
import { QuoteAutoBuildDataChangedDialogComponent } from './quote-auto-build-data-changed-dialog/quote-auto-build-data-changed-dialog.component'
import { QuotePushWsConfirmationDialogComponent } from './quote-push-ws-confirmation-dialog/quote-push-ws-confirmation-dialog.component'
import { WhitespaceSyncWarningsComponent } from './whitespace-sync-warnings/whitespace-sync-warnings.component'
import { SetOffMarketReinsurerDialogComponent } from './set-off-market-reinsurer-dialog/set-off-market-reinsurer-dialog.component'
import { QuoteExpiringPanelConfirmationDialogComponent } from './quote-expiring-panel-confirmation-dialog/quote-expiring-panel-confirmation-dialog.component'
import { QuoteReinsurerSubjectivityContainerComponent } from './reinsurer-subjectivity-dialog/reinsurer-subjectivity-dialog.container'
import { QuoteReinsurerSubjectivityComponent } from './reinsurer-subjectivity-dialog/reinsurer-subjectivity-dialog.component'
import { QuoteCustomComparisonComponent } from './quote-custom-comparison/quote-custom-comparison.component'
import { QuoteConfirmationDialogComponent } from './quote-confirmation-dialog/quote-confirmation-dialog.component'
import { QuoteDeleteReinsurerDialogComponent } from './quote-delete-reinsurer-dialog/quote-delete-reinsurer-dialog.component'
import { QuoteExportDialogContainerComponent } from './quote-export-dialog/quote-export-dialog-container/quote-export-dialog.container';
import { QuoteExportDialogComponent } from './quote-export-dialog/quote-export-dialog-component/quote-export-dialog.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
}

@NgModule({
  declarations: [
    QuoteComponent,
    QuoteContainerComponent,
    QuotePanelComponent,
    QuoteActionsComponent,
    QuoteSubjectivityComponent,
    QuoteHeaderComponent,
    QuoteReinsurerComponent,
    QuoteTrackingComponent,
    QuoteRouteContainerComponent,
    QuoteContentContainerComponent,
    QuoteContentComponent,
    QuoteTogglesComponent,
    QuoteAssignedLinesComponent,
    QuoteExpiringDetailsDialogComponent,
    QuoteSegregatedAccountDetailsDialogContainerComponent,
    QuoteSegregatedAccountDetailsDialogComponent,
    QuotePushWsWarningsDialogComponent,
    QuoteRiskCodeDialogContainerComponent,
    QuoteRiskCodeDialogComponent,
    QuickQuoteComponent,
    QuoteAutoBuildDataChangedDialogComponent,
    QuotePushWsConfirmationDialogComponent,
    WhitespaceSyncWarningsComponent,
    SetOffMarketReinsurerDialogComponent,
    QuoteExpiringPanelConfirmationDialogComponent,
    QuoteReinsurerSubjectivityContainerComponent,
    QuoteReinsurerSubjectivityComponent,
    QuoteCustomComparisonComponent,
    QuoteConfirmationDialogComponent,
    QuoteDeleteReinsurerDialogComponent,
    QuoteExportDialogContainerComponent,
    QuoteExportDialogComponent
  ],
  imports: [
    QuoteRoutingModule,
    SharedModule,
    SwiperModule,
    TierModule,
    BrokerModule,
    CommonModule,
    NgxMatSelectSearchModule,
    MaterialModule,
    StoreModule.forFeature(
      fromQuoteReducer.QUOTE_FEATURE_KEY,
      fromQuoteReducer.reducer
    ),
    EffectsModule.forFeature([
      QuoteEffects,
      SectionsEffects,
      ReinsurersEffects,
      QuoteManagementInformationEffects,
      QuoteManagementInformationChartEffects,
      QuoteManagementInformationTrendsEffects,
      QuickQuoteEffects,
    ]),
  ],
  providers: [
    QuoteExportService,
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
  ],
})
export class QuoteModule {}
