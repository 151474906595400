import { PricingCurve } from '../model/pricing-curve'
import {
  PricingCurveLayer,
  LayerProperties,
  TechnicalFactors,
} from '../model/pricing-curve.model'

export type PricingCurveExportInformation = {}

export type PricingCurveExportOptions = {
  type: 'xlsx'
}

export enum PricingCurvePageTypes {
  SUMMARY = 'summary',
  MANUAL_POINTS = 'manualPoints',
  CURVE = 'curve',
}

export interface PricingCurveExportPage {
  id: number
  pageType: PricingCurvePageTypes
  data: PricingCurve
}

export const TECHNICAL_FACTORS_LABELS: LabelType<TechnicalFactors> = {
  expected_loss_multiplier: 'Expected Loss Multiplier',
  expected_loss_power: 'Expected Loss Power',
  volatility_multiplier: 'Volatility Multiplier',
  volatility_metric: 'Volatility Metric',
  fixed_cost: 'Fixed Cost',
  minimum_rate_on_line: 'Minimum Rate on Line',
  maximum_rate_on_line: 'Maximum Rate on Line',
  reinsurer_margin_percentage: 'Reinsurer Margin Percentage',
  max_ceding_commission_percentage: 'Max Ceding Commission Percentage',
}

export const TECHNICAL_FACTORS_COLUMN_TYPES: ColumnType<TechnicalFactors> = {
  expected_loss_multiplier: 'number',
  expected_loss_power: 'number',
  volatility_multiplier: 'number',
  volatility_metric: 'string',
  fixed_cost: 'percentage',
  minimum_rate_on_line: 'percentage',
  maximum_rate_on_line: 'percentage',
  reinsurer_margin_percentage: 'percentage',
  max_ceding_commission_percentage: 'percentage',
}

export type LayerExportColumnType = Omit<
  PricingCurveLayer & LayerProperties,
  'props' | 'hist_data_id' | 'rr_id' | 'ral_id' | 'id'
>

export const LAYER_LABELS: LabelType<LayerExportColumnType> = {
  clientName: 'Client Name',
  layerType: 'Layer Type',
  year: 'Year',
  programName: 'Program Name',
  structureName: 'Structure Name',
  layerName: 'Layer Name',
  layerDesc: 'Layer Description',
  el: 'EL',
  trol: 'TROL',
  layerCurrency: 'Layer Currency',
  inceptionDate: 'Inception Date',
  aggregateAttachment: 'Agg Attachment',
  aggregatedRipPct: 'Agg Rip Percentage',
  aggregateLimit: 'Agg Limit',
  occurrenceAttachment: 'Occ Attachment',
  occurrenceLimit: 'Occ Limit',
  riskAttachment: 'Risk Attachment',
  riskLimit: 'Risk Limit',
  cessionPercentage: 'Cession Percentage',
  correspondentBroker: 'Correspondent Broker',
  ignoreForPricingCurve: 'Ignore for Pricing Curve',
  lossImpactedFromPrevYear: 'Loss Impacted from Previous Year',
  pcClass: 'Class',
  pcSubClass: 'SubClass',
  placedThrough: 'Placed Through',
  prob_of_attach: 'Probability of Attachment',
  prob_of_exhaust: 'Probability of Exhaustion',
  quoteExpectedCededLoss: 'Expected Ceded Loss',
  quoteExpectedCededPremium: 'Expected Ceded Premium',
  status: 'Status',
  territory: 'Territory',
  vendor: 'Vendor',
  include: 'Include',
  layerCategory: 'Layer Category',
  perils: 'Perils',
  reinsurerName: 'Reinsurer Name',
  writtenPercent: 'Written Percent',
}

export const LAYER_COLUMN_TYPES: ColumnType<LayerExportColumnType> = {
  clientName: 'string',
  layerType: 'string',
  year: 'year-no-zero',
  programName: 'string',
  structureName: 'string',
  layerName: 'string',
  layerDesc: 'string',
  el: 'percentage',
  trol: 'percentage',
  inceptionDate: 'date',
  layerCurrency: 'string',
  aggregateAttachment: 'number',
  aggregatedRipPct: 'string',
  aggregateLimit: 'number',
  occurrenceAttachment: 'number',
  occurrenceLimit: 'number',
  riskAttachment: 'number',
  riskLimit: 'number',
  cessionPercentage: 'percentage',
  correspondentBroker: 'string',
  ignoreForPricingCurve: 'boolean',
  lossImpactedFromPrevYear: 'boolean',
  pcClass: 'string',
  pcSubClass: 'string',
  placedThrough: 'string',
  prob_of_attach: 'percentage',
  prob_of_exhaust: 'percentage',
  quoteExpectedCededLoss: 'number',
  quoteExpectedCededPremium: 'number',
  status: 'string',
  territory: 'string',
  vendor: 'string',
  include: 'boolean',
  layerCategory: 'string',
  perils: 'string',
  reinsurerName: 'string',
  writtenPercent: 'percentage',
}

export type LabelType<Type> = {
  [Property in keyof Type]: string
}

export type ColumnType<Type> = {
  [Property in keyof Type]:
    | 'string'
    | 'number'
    | 'percentage'
    | 'date'
    | 'boolean'
    | 'year-no-zero'
}
