import { Component, Inject } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelectChange } from '@angular/material/select'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreditModelScenarioMapping,
  ScenarioType,
  STOCHASTIC_SCENARIO,
} from '../../credit/model/credit-portfolio.model'
import { SelectedScenario } from '../..//credit/model/credit-results.model'

@Component({
  selector: 'app-scenario-selector',
  templateUrl: './scenario-selector.component.html',
  styleUrls: ['./scenario-selector.component.scss'],
})
export class ScenarioSelectorComponent {
  scenarioControl = new FormControl('')
  deterministicScenarios: { value: string; viewValue: string }[] = []
  stochasticScenarios: { value: string; viewValue: string }[] = []
  years: number = 0
  yearsDisabled: boolean = true

  constructor(
    public dialogRef: MatDialogRef<ScenarioSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mappingScenarioId: number
      returnPeriod?: string
      modelScenarioMappings?: CreditModelScenarioMapping[]
    }
  ) {
    if (data.modelScenarioMappings) {
      this.getDataFromMappings()
    }
  }

  onScenarioChange(scenario: MatSelectChange): void {
    this.yearsDisabled = !!!this.stochasticScenarios.find(
      s => s.value === scenario.value
    )
  }

  onApplyClick(): void {
    if (!this.yearsDisabled && this.years <= 0) {
      return
    }
    if (this.data.modelScenarioMappings) {
      this.applyForMapping()
    }
  }

  private getDataFromMappings(): void {
    const stochastic_data_result = this.data.modelScenarioMappings.filter(
      s => s.scenario_name === STOCHASTIC_SCENARIO && s.return_period === 1
    )
    this.stochasticScenarios = stochastic_data_result.map(s => ({
      value: String(s.id),
      viewValue: `${s.model_name} ${s.scenario_name}`,
    }))

    this.years = this.data.returnPeriod ? Number(this.data.returnPeriod) : 0
    const deteministic_data_result = this.data.modelScenarioMappings.filter(
      s => s.scenario_name !== STOCHASTIC_SCENARIO
    )
    this.deterministicScenarios = deteministic_data_result.map(s => ({
      value: String(s.id),
      viewValue: `${s.model_name} ${s.scenario_name}`,
    }))
    if (this.data.mappingScenarioId) {
      this.scenarioControl.setValue(String(this.data.mappingScenarioId))
    }
    this.yearsDisabled = !!!this.stochasticScenarios.find(
      s => s.value === String(this.data.mappingScenarioId)
    )
  }

  private applyForMapping(): void {
    let mapping: CreditModelScenarioMapping | undefined =
      this.data.modelScenarioMappings.find(
        s => String(s.id) === this.scenarioControl.value
      )
    let stochastic = mapping?.scenario_name === STOCHASTIC_SCENARIO
    if (!mapping) {
      this.dialogRef.close()
      return
    }
    const mappingScenarioId =
      mapping.scenario_name !== STOCHASTIC_SCENARIO
        ? mapping.id
        : this.getStochasticMappingIdFromMapping(mapping)
    const output: SelectedScenario = {
      id: '',
      model: mapping.model_name,
      name: mapping.scenario_name,
      scenario: mapping.scenario_name,
      scenario_type: stochastic
        ? ScenarioType.Stochastic
        : ScenarioType.Deterministic,
      years: stochastic ? this.years : undefined,
      mappingScenarioId,
    }
    this.dialogRef.close(output)
  }

  private getStochasticMappingIdFromMapping(
    mapping: CreditModelScenarioMapping
  ): number {
    return (
      this.data.modelScenarioMappings.find(
        s =>
          Number(s.return_period) === this.years &&
          s.model_name === mapping.model_name
      ).id ??
      this.data.modelScenarioMappings.find(
        s =>
          Number(s.return_period) === 1 && s.model_name === mapping.model_name
      ).id // default to average if invalid return period
    )
  }
}
