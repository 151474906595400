import {inject, Injectable} from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { Actions, createEffect } from '@ngrx/effects'

import { tap } from 'rxjs/operators'
import { ErrorComponent } from '../error.component'
import { ApplicationError, PayloadDiscriminant } from '../model/error'
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store";

@Injectable()
export class ErrorEffects {
  private actions$ = inject(Actions)

  constructor(private snackBar: MatSnackBar) {}

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        tap((action: any) => {
          const data = action.error
          if (this.isApplicationError(data)) {
            const cfg: MatSnackBarConfig = { data, panelClass: 'app-error' }
            if (data.duration) {
              cfg.duration = data.duration
            }
            if (data.duration !== 0) {
              this.snackBar.openFromComponent(ErrorComponent, cfg)
            }
          }
        })
      )
    },
    { dispatch: false }
  )

  private isApplicationError(error?: any): error is ApplicationError {
    return error != null && error.type === PayloadDiscriminant.ERROR
  }
}
