import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { RiskRefDetail } from '../../../api/model/signature.model'
import { OTRenewalDetails } from '../../../slip-templates/slip-templates.model'
import { PortfolioSetID } from '../../../analysis/model/portfolio-set.model'
import { ExternalVendorMapping, WhiteSpaceCloneResponse } from 'src/app/api/model/sliptemplates.model'

export interface QuoteSetAnalysis {
  portfolioSetID: PortfolioSetID | null
  structureID: string | null
}

export const exportToWhiteSpace = createAction(
  '[SlipTemplates] export to Whitespace'
)
export const exportToWhiteSpaceSuccess = createAction(
  '[SlipTemplates] export to Whitespace Success',
  props<{ data: WhiteSpaceCloneResponse[] }>()
)

export const exportToWhiteSpaceFailure = createAction(
  '[SlipTemplates] export to Whitespace Failure',
  props<{ error: ApplicationError }>()
)

export const getRenewalDetails = createAction(
  '[SlipTemplates] Get Renewal Details',
  props<{ currentRiskRef: string }>()
)
export const getRenewalDetailsSuccess = createAction(
  '[SlipTemplates] Get Renewal Details Success',
  props<{ renewalDetails: OTRenewalDetails }>()
)
export const getRenewalDetailsFailure = createAction(
  '[SlipTemplates] get Renewal Details - Failure',
  props<{ error: ApplicationError }>()
)

export const setSelectedTemplate = createAction(
  '[SlipTemplates] Set Selected Template',
  props<{ currentTemplate: string }>()
)
export const setLoading = createAction(
  '[SlipTemplates] Set Loading',
  props<{ loading: boolean }>()
)

export const setRiskRef = createAction(
  '[SlipTemplates] Set Risk Ref',
  props<{ currentRiskRef: string }>()
)

export const fetchTemplates = createAction('[SlipTemplates] Fetch templates')
export const fetchTemplatesSuccess = createAction(
  '[SlipTemplates] Fetch templates Success',
  props<{ templates: string[] }>()
)
export const fetchTemplatesFailure = createAction(
  '[Signature] fetch all templates - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchRiskRefsByProgramId = createAction(
  '[Signature] Fetch Risk Refs By Program ID'
)
export const fetchRiskRefsByProgramIdSuccess = createAction(
  '[Signature] [Signature] Fetch Risk Refs By Program ID Success',
  props<{ riskRefs: RiskRefDetail[] }>()
)
export const fetchRiskRefsByProgramIdFailure = createAction(
  '[Signature] Fetch Risk Sections By Shared Limit ID Failure',
  props<{ error: ApplicationError }>()
)
export const setAnalysisOrReinitialize = createAction(
  '[Quote Sage] Set Analysis or Reinitialize',
  props<QuoteSetAnalysis>()
)
export const fetchCoBrokedDetails = createAction(
  '[Reinsurer] Fetch Reinsurer Program Factor to Check if Co-Broker is US/UK',
  props<{ coBrokedKeyValuePairs: Record<string, string | string[]> }>()
)
export const fetchCoBrokedDetailsSuccess = createAction(
  '[Reinsurer] Fetch Reinsurer Program Factor to Check if Co-Broker is US/UK Success',
  props<{ hasCoBroker: boolean }>()
)
export const fetchCoBrokedDetailsFailure = createAction(
  '[Reinsurer] Fetch Reinsurer Program Factor to Check if Co-Broker is US/UK Failure',
  props<{ error: ApplicationError }>()
)
export const fetchExternalVendorMappingsByStructure = createAction(
  '[Reinsurer] Fetch WS External Vendor Mappings By StructureId'
)
export const fetchExternalVendorMappingsByStructureSuccess = createAction(
  '[Reinsurer] Fetch WS External Vendor Mappings By StructureId Success',
  props<{ externalVendorMapping: ExternalVendorMapping }>()
)
export const fetchExternalVendorMappingsByStructureFailure = createAction(
  '[Reinsurer] Fetch WS External Vendor Mappings By StructureId Failure',
  props<{ error: ApplicationError }>()
)
export const setCurrentVendorID = createAction(
  '[Reinsurer] Set Current Vendor ID',
  props<{ ID: string | undefined }>()
)
