import {
  SignatureContractResponse,
  SignatureReinsurerResponse,
  SignatureFotResponse,
  SignatureCedentResponse,
} from './../model/signature.model'
import {
  Cedent,
  ReinsuredFot,
  SignatureContract,
  SignatureReinsurer,
} from './../../signature/signature.model'
import { OmitID } from '../model/backend.model'

// Convert To Response Type
const convertToReinsurerResponses = (
  reinsurers?: SignatureReinsurer[]
): SignatureReinsurerResponse[] => {
  return (reinsurers ?? []).map(reinsurer => ({
    contractName: reinsurer.contractName,
    reinsurer: reinsurer.reinsurerName,
    tpRef: reinsurer.tpRef,
    sequenceNumber: reinsurer.sequenceNumber,    
    referenceType: reinsurer.referenceType,
    aiinReferenceNumber: reinsurer.aiinReferenceNumber,
    feinReferenceNumber: reinsurer.feinReferenceNumber,
    naicReferenceNumber: reinsurer.naicReferenceNumber,
    effectiveDate: reinsurer.effectiveDate,
    riskRef: reinsurer.riskRef,
    isFinalVersion: reinsurer.isFinalVersion,
    programId: reinsurer.programId,
    signatureContractFots: convertToFotResponses(reinsurer.fotsReinsured),
    signatureContractCedents: convertToCedentResponses(reinsurer.cedents),
    companyAlias: reinsurer.companyAlias ?? '',
  }))
}

const convertToReinsurerResponsesPut = (
  reinsurers?: SignatureReinsurer[]
): SignatureReinsurerResponse[] => {
  return (reinsurers ?? []).map(reinsurer => ({
    id: reinsurer.id,
    contractName: reinsurer.contractName,
    reinsurer: reinsurer.reinsurerName,
    tpRef: reinsurer.tpRef,
    sequenceNumber: reinsurer.sequenceNumber, 
    referenceType: reinsurer.referenceType,
    aiinReferenceNumber: reinsurer.aiinReferenceNumber,
    feinReferenceNumber: reinsurer.feinReferenceNumber,
    naicReferenceNumber: reinsurer.naicReferenceNumber,
    effectiveDate: reinsurer.effectiveDate,
    riskRef: reinsurer.riskRef,
    isFinalVersion: reinsurer.isFinalVersion,
    programId: reinsurer.programId,
    signatureContractFots: convertToFotResponsesPut(reinsurer.fotsReinsured),
    signatureContractCedents: convertToCedentResponsesPut(reinsurer.cedents),
    companyAlias: reinsurer.companyAlias ?? '',
  }))
}

const convertToFotResponses = (
  fotsReinsured?: ReinsuredFot[]
): SignatureFotResponse[] => {
  return (fotsReinsured ?? []).map(fot => ({
    programGroupId: fot.programGroupId,
    programGroupName: fot.programGroupName,
    structureId: fot.structureId,
    structureName: fot.structureName,
    sharedLimitId: fot.sharedLimitId,
    sharedLimitName: fot.sharedLimitName,
    layerRef: fot.layerRef,
    layerName: fot.layerName,
    layerType: fot.layerType,
    layerCurrency: fot.layerCurrency,
    reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
    reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
    subjectivities: fot.subjectivities,
    occurrence: fot.occurrence,
    percentage: fot.percentage,
    reinsuranceBrokeragePayable: fot.reinsuranceBrokeragePayable,
    brokerageType: fot.brokerageType,
    brokeragePercent: fot.brokeragePercent,
    reinstatementPercent: fot.reinstatementPercent,
    riskReinsurerId: fot.riskReinsurerId,
    fotPositionInContract: fot.fotPositionInContract,
  }))
}

const convertToFotResponsesPut = (
  fotsReinsured?: ReinsuredFot[]
): SignatureFotResponse[] => {
  return (fotsReinsured ?? []).map(fot => ({
    id: fot.id,
    programGroupId: fot.programGroupId,
    programGroupName: fot.programGroupName,
    structureId: fot.structureId,
    structureName: fot.structureName,
    sharedLimitId: fot.sharedLimitId,
    sharedLimitName: fot.sharedLimitName,
    layerRef: fot.layerRef,
    layerName: fot.layerName,
    layerType: fot.layerType,
    layerCurrency: fot.layerCurrency,
    reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
    reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
    subjectivities: fot.subjectivities,
    occurrence: fot.occurrence,
    percentage: fot.percentage,
    reinsuranceBrokeragePayable: fot.reinsuranceBrokeragePayable,
    brokerageType: fot.brokerageType,
    brokeragePercent: fot.brokeragePercent,
    reinstatementPercent: fot.reinstatementPercent,
    riskReinsurerId: fot.riskReinsurerId,
    fotPositionInContract: fot.fotPositionInContract,
  }))
}

const convertToCedentResponses = (
  cedents?: Cedent[]
): SignatureCedentResponse[] => {
  return (cedents ?? []).map(cedent => ({
    cedentName: cedent.cedentName,
    cedentLocation: cedent.cedentLocation,
    tpRef: cedent.tpRef,
  }))
}

const convertToCedentResponsesPut = (
  cedents?: Cedent[]
): SignatureCedentResponse[] => {
  return (cedents ?? []).map(cedent => ({
    id: cedent.id,
    cedentName: cedent.cedentName,
    cedentLocation: cedent.cedentLocation,
    tpRef: cedent.tpRef,
  }))
}

// Convert From Response Type
const convertFromReinsurerResponses = (
  reinsurers?: SignatureReinsurerResponse[]
): SignatureReinsurer[] => {
  return (reinsurers ?? []).map(reinsurer => ({
    id: reinsurer.id,
    contractName: reinsurer.contractName,
    tpRef: reinsurer.tpRef,
    sequenceNumber: reinsurer.sequenceNumber, 
    reinsurerName: reinsurer.reinsurer,
    referenceType: reinsurer.referenceType,
    aiinReferenceNumber: reinsurer.aiinReferenceNumber,
    feinReferenceNumber: reinsurer.feinReferenceNumber,
    naicReferenceNumber: reinsurer.naicReferenceNumber,
    effectiveDate: reinsurer.effectiveDate,
    riskRef: reinsurer.riskRef,
    isFinalVersion: reinsurer.isFinalVersion,
    programId: reinsurer.programId,
    fotsReinsured: convertFromFotResponses(reinsurer.signatureContractFots),
    cedents: convertFromCedentResponses(reinsurer.signatureContractCedents),
    companyAlias: reinsurer.companyAlias,
  }))
}

const convertFromFotResponses = (
  fotsReinsured: SignatureFotResponse[]
): ReinsuredFot[] => {
  return (fotsReinsured ?? []).map(fot => ({
    id: fot.id,
    programGroupId: fot.programGroupId,
    programGroupName: fot.programGroupName,
    structureId: fot.structureId,
    structureName: fot.structureName,
    sharedLimitId: fot.sharedLimitId,
    sharedLimitName: fot.sharedLimitName,
    layerRef: fot.layerRef,
    layerName: fot.layerName,
    layerType: fot.layerType,
    layerCurrency: fot.layerCurrency,
    reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
    reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
    subjectivities: fot.subjectivities,
    occurrence: fot.occurrence,
    percentage: fot.percentage ? parseFloat(fot.percentage.toFixed(10)) : 0,
    reinsuranceBrokeragePayable: fot.reinsuranceBrokeragePayable,
    brokerageType: fot.brokerageType,
    brokeragePercent: fot.brokeragePercent,
    reinstatementPercent: fot.reinstatementPercent,
    riskReinsurerId: fot.riskReinsurerId,
    fotPositionInContract: fot.fotPositionInContract,
  }))
}

const convertFromCedentResponses = (
  cedents?: SignatureCedentResponse[]
): Cedent[] => {
  return (cedents ?? []).map(cedent => ({
    id: cedent.id,
    cedentName: cedent.cedentName,
    cedentLocation: cedent.cedentLocation,
    tpRef: cedent.tpRef,
  }))
}

// For POST APIs
export const convertSignatureContractsToRequestPost = (
  contracts: Partial<SignatureContract>[]
): OmitID<Partial<SignatureContractResponse>>[] =>
  contracts.map(contract => {
    return {
      contractName: contract.contractName,
      pageSetName: contract.pageSetName,
      programId: contract.programId,
      signatureContractReinsurers: convertToReinsurerResponses(
        contract.reinsurers
      ),
    }
  })

export const convertSignatureContractFromResponse = (
  res: SignatureContractResponse
): SignatureContract => ({
  id: res.id,
  contractName: res.contractName,
  pageSetName: res.pageSetName,
  programId: res.programId,
  reinsurers: convertFromReinsurerResponses(res.signatureContractReinsurers),
  companyAlias: res.companyAlias,
})

// For GET APIs
export const convertSignatureContractsFromResponse = (
  res: SignatureContractResponse[]
) => res.map(convertSignatureContractFromResponse)

// For PUT APIs
export const convertSignatureContractsToRequestPut = (
  contracts: Partial<SignatureContract>[]
): Partial<SignatureContractResponse>[] =>
  contracts.map(contract => {
    return {
      id: contract.id,
      contractName: contract.contractName,
      pageSetName: contract.pageSetName,
      programId: contract.programId,
      signatureContractReinsurers: convertToReinsurerResponsesPut(
        contract.reinsurers
      ),
    }
  })

export const convertRenewalRiskRefResponse = (res: string[]) => res
