import { getChartSplitAndSize } from '../utils/quote-charts.util'

export type QuoteChartGridOption = {
  index: number /* chart grid index i.e. 0 = top left chart */
  chart: QuoteChartInfo
  groupBy: QuoteChartAxis /* x axis of chart */
  metric: QuoteChartAxis /* y axis of chart */
  split: QuoteChartAxis | null /* column used for additional data grouping */
  size: QuoteChartAxis | null /* column used for bubble size */
  loading: boolean /* chart grid data loading */
  highlight: boolean /* highlight chart grid icon & selector */
  data?: QuoteChartData
  sortBy?: QuoteSortBy,
  consolidateLloyds?: boolean
  blackList?: string[]
  showAllOtherData?: boolean
}
export interface QuoteChartInfo {
  name: string
  type: QuoteChartType
  icon: string
  class?: string
  applicableSizes: QuoteChartAxis[]
  applicableSplits: QuoteChartAxis[]
}
export interface QuoteChartAxis {
  name: string
  columnName:
    | QuoteChartGridColumn
    | QuoteChartGridMetric
    | QuoteChartDataSplitColumn
    | QuoteChartSizeColumn
  applicableCharts: QuoteChartInfo[]
  format?: 'decimal' | 'int' | 'percent'
  metricPrimaryName?: string
  metricSecondaryName?: string
  metricTertiaryName?: string
  metricQuaternaryName?: string
  isPureLayerMetric?: boolean
  columnIDs?: string[]
}
export interface QuoteSortBy {
  name: string
  value: QuoteSortByOption
}
export type QuoteChartGridColumn =
  | 'reinsurer'
  | 'programName'
  | 'layerName'
  | 'fotQuoteIndicator'
  | 'clientName'
  | 'inceptionDate'
  | 'account'
export type QuoteChartGridMetric =
  | 'quoteVsfotRate'
  | 'riskAdjustedRateChange'
  | 'signedLimit'
  | 'signedWrittenLimit'
  | 'signedWrittenExpiringLimit'
  | 'signedWrittenExpiringPremium'
  | 'countOfReinsurers'
  | 'countOfAssignedLloyds'
  | 'sumSignedWrittenLines'
  | 'sumSignedWrittenExpiringLines'
  | 'sumSignedOverplacementLines'
  | 'quoteRate'
export type QuoteChartDataSplitColumn =
  | 'layerStatus'
  | 'inceptionDate'
  | 'securityType'
export type QuoteChartSizeColumn =
  | 'signedLimit'
  | 'writtenLimit'
  | 'expiringLimit'
  | 'cessionPercentage'
export type QuoteSortByOption =
  | 'lowToHigh'
  | 'highToLow'
  | 'lowToHighSize'
  | 'highToLowSize'
export type QuoteChartResponse = {
  data: QuoteChartData
  index: number
  groupBy: QuoteChartAxis
}
export type QuoteChartData = {
  data: QuoteChartDatum[]
}
export interface QuoteChartDatum {
  groupBy: string
  metricPrimary: number
  metricSecondary?: number
  metricTertiary?: number
  metricQuaternary?: number
  split?: string
  size?: number
}

export interface QuoteChartGroupedDataBySplit {
  groupBy: string
  data: number[]
  metricTotal: number
}

export interface QuoteChartMenuOption {
  name: string
  icon?: string
}

export const QuoteChartSortByOptions: QuoteSortBy[] = [
  {
    name: 'High to Low',
    value: 'highToLow',
  },
  {
    name: 'Low to High',
    value: 'lowToHigh',
  },
]

export const QuoteMIChartTypes = [
  'bubble',
  'scatter',
  'donut',
  'gauge',
  'unstackedArea',
  'stackedArea',
  'unstackedBar',
  'stackedBar',
  'divergingBar',
  'percentStackedBar',
] as const
export type QuoteChartType = (typeof QuoteMIChartTypes)[number]

export type QuoteChartsInfo = {
  bubbleInfo: QuoteChartInfo
  scatterInfo: QuoteChartInfo
  donutInfo: QuoteChartInfo
  gaugeInfo: QuoteChartInfo
  unstackedAreaInfo: QuoteChartInfo
  unstackedBarInfo: QuoteChartInfo
  stackedBarInfo: QuoteChartInfo
  percentStackedBarInfo: QuoteChartInfo
  divergingBarInfo: QuoteChartInfo
}
export type QuoteChartMetricsInfo = {
  quoteVsfotRate: QuoteChartAxis
  riskAdjustedRateChange: QuoteChartAxis
  quoteRate: QuoteChartAxis
  signedLimit: QuoteChartAxis
  signedWrittenLimit: QuoteChartAxis
  signedWrittenExpiringLimit: QuoteChartAxis
  signedWrittenExpiringPremium: QuoteChartAxis
  countOfReinsurers: QuoteChartAxis
  countOfAssignedLloyds: QuoteChartAxis
  sumSignedWrittenLines: QuoteChartAxis
  sumSignedWrittenExpiringLines: QuoteChartAxis
}
export type QuoteChartGroupBysInfo = {
  reinsurer: QuoteChartAxis
  program: QuoteChartAxis
  layerName: QuoteChartAxis
  fotQuoteIndicator: QuoteChartAxis
  account: QuoteChartAxis
}
export const QUOTE_CHARTS_INFO: QuoteChartsInfo = {
  bubbleInfo: {
    name: 'Bubble',
    type: 'bubble',
    icon: 'bubble_chart',
    applicableSizes: [
      { name: 'Signed Limit', columnName: 'signedLimit', applicableCharts: [] },
      {
        name: 'Written Limit',
        columnName: 'writtenLimit',
        applicableCharts: [],
        format: 'decimal'
      },
      {
        name: 'Expiring Limit',
        columnName: 'expiringLimit',
        applicableCharts: [],
        format: 'decimal'
      },
      {
        name: 'Cession Percentage',
        columnName: 'cessionPercentage',
        applicableCharts: [],
        format: 'percent'
      },
    ],
    applicableSplits: [],
  },
  scatterInfo: {
    name: 'Scatter',
    type: 'scatter',
    icon: 'scatter_plot',
    applicableSizes: [],
    applicableSplits: [],
  },
  donutInfo: {
    name: 'Donut',
    type: 'donut',
    icon: 'donut_large',
    applicableSizes: [],
    applicableSplits: [],
  },
  gaugeInfo: {
    name: 'Gauge',
    type: 'gauge',
    icon: 'looks',
    applicableSizes: [],
    applicableSplits: [],
  },
  unstackedAreaInfo: {
    name: 'Unstacked Area',
    type: 'unstackedArea',
    icon: 'area_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
  unstackedBarInfo: {
    name: 'Unstacked Bar',
    type: 'unstackedBar',
    icon: 'bar_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
  stackedBarInfo: {
    name: 'Stacked Bar',
    type: 'stackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Status',
        columnName: 'layerStatus',
        applicableCharts: [],
      },
    ],
  },
  percentStackedBarInfo: {
    name: 'Percent Stacked Bar',
    type: 'percentStackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Status',
        columnName: 'layerStatus',
        applicableCharts: [],
      },
    ],
  },
  divergingBarInfo: {
    name: 'Diverging Bar',
    type: 'divergingBar',
    icon: 'bar_chart',
    applicableSizes: [],
    applicableSplits: [],
  },
}
export const QUOTE_CHART_METRICS_INFO: QuoteChartMetricsInfo = {
  quoteVsfotRate: {
    name: 'Quote Vs FOT Rate',
    columnName: 'quoteVsfotRate',
    format: 'percent',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  quoteRate: {
    name: 'Quote Rate',
    columnName: 'quoteRate',
    format: 'percent',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  riskAdjustedRateChange: {
    name: 'Risk Adjusted Rate Change',
    columnName: 'riskAdjustedRateChange',
    format: 'percent',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  signedLimit: {
    name: 'Signed Limit',
    columnName: 'signedLimit',
    format: 'decimal',
    metricPrimaryName: 'Signed Limit',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  signedWrittenLimit: {
    name: 'Signed & Written Limit',
    columnName: 'signedWrittenLimit',
    format: 'decimal',
    metricPrimaryName: 'Signed Limit',
    metricSecondaryName: 'Written Limit',
    applicableCharts: [
      QUOTE_CHARTS_INFO.gaugeInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
    ],
  },
  signedWrittenExpiringLimit: {
    name: 'Signed, Written & Expiring Limit',
    columnName: 'signedWrittenExpiringLimit',
    format: 'decimal',
    metricPrimaryName: 'Signed Limit',
    metricSecondaryName: 'Written Limit',
    metricTertiaryName: 'Expiring Signed Limit',
    applicableCharts: [
      QUOTE_CHARTS_INFO.gaugeInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
    ],
  },
  signedWrittenExpiringPremium: {
    name: 'Signed, Written & Expiring Premium',
    columnName: 'signedWrittenExpiringPremium',
    format: 'decimal',
    metricPrimaryName: 'Signed Premium',
    metricSecondaryName: 'Written Premium',
    metricTertiaryName: 'Expiring Signed Premium',
    applicableCharts: [
      QUOTE_CHARTS_INFO.gaugeInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
    ],
  },
  countOfReinsurers: {
    name: 'Count of Reinsurers',
    columnName: 'countOfReinsurers',
    format: 'int',
    metricPrimaryName: 'Count of Reinsurers',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.donutInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
      QUOTE_CHARTS_INFO.stackedBarInfo,
      QUOTE_CHARTS_INFO.percentStackedBarInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  countOfAssignedLloyds: {
    name: 'Count of Assigned LLoyds',
    columnName: 'countOfAssignedLloyds',
    format: 'int',
    metricPrimaryName: 'Count of Assigned LLoyds',
    applicableCharts: [
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHARTS_INFO.scatterInfo,
      QUOTE_CHARTS_INFO.donutInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.unstackedBarInfo,
      QUOTE_CHARTS_INFO.stackedBarInfo,
      QUOTE_CHARTS_INFO.percentStackedBarInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
    ],
  },
  sumSignedWrittenLines: {
    name: 'Sum Signed & Written Lines',
    columnName: 'sumSignedWrittenLines',
    format: 'percent',
    metricPrimaryName: 'Sum Signed Lines',
    metricSecondaryName: 'Sum Written Lines',
    isPureLayerMetric: true,
    applicableCharts: [
      QUOTE_CHARTS_INFO.unstackedBarInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.gaugeInfo,
    ],
  },
  sumSignedWrittenExpiringLines: {
    name: 'Sum Signed, Written & Expiring Lines',
    columnName: 'sumSignedWrittenExpiringLines',
    format: 'percent',
    metricPrimaryName: 'Sum Signed Lines',
    metricSecondaryName: 'Sum Written Lines',
    metricTertiaryName: 'Sum Expiring Signed Lines',
    metricQuaternaryName: 'Sum Expiring Written Lines',
    isPureLayerMetric: true,
    applicableCharts: [
      QUOTE_CHARTS_INFO.unstackedBarInfo,
      QUOTE_CHARTS_INFO.divergingBarInfo,
      QUOTE_CHARTS_INFO.unstackedAreaInfo,
      QUOTE_CHARTS_INFO.gaugeInfo,
    ],
  },
}
export const QUOTE_CHART_GROUP_BYS_INFO: QuoteChartGroupBysInfo = {
  reinsurer: {
    name: 'Reinsurer',
    columnName: 'reinsurer',
    applicableCharts: [],
  },
  program: {
    name: 'Program',
    columnName: 'programName',
    applicableCharts: [],
  },
  layerName: {
    name: 'Layer Name',
    columnName: 'layerName',
    applicableCharts: [],
  },
  fotQuoteIndicator: {
    name: 'Quote/FOT Indicator',
    columnName: 'fotQuoteIndicator',
    applicableCharts: [],
  },
  account: {
    name: 'Account',
    columnName: 'clientName',
    applicableCharts: [],
  },
}
export const DEFAULT_QUOTE_CHARTS: QuoteChartGridOption[] = [
  {
    index: 0,
    loading: false,
    highlight: true,
    chart: QUOTE_CHARTS_INFO.bubbleInfo,
    metric: QUOTE_CHART_METRICS_INFO.quoteVsfotRate,
    groupBy: QUOTE_CHART_GROUP_BYS_INFO.reinsurer,
    ...getChartSplitAndSize(
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHART_GROUP_BYS_INFO.reinsurer
    ),
    sortBy: QuoteChartSortByOptions[0],
  },
  {
    index: 1,
    loading: false,
    highlight: false,
    chart: QUOTE_CHARTS_INFO.bubbleInfo,
    metric: QUOTE_CHART_METRICS_INFO.quoteVsfotRate,
    groupBy: QUOTE_CHART_GROUP_BYS_INFO.program,
    ...getChartSplitAndSize(
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHART_GROUP_BYS_INFO.program
    ),
    sortBy: QuoteChartSortByOptions[0],
  },
  {
    index: 2,
    loading: false,
    highlight: false,
    chart: QUOTE_CHARTS_INFO.bubbleInfo,
    metric: QUOTE_CHART_METRICS_INFO.quoteVsfotRate,
    groupBy: QUOTE_CHART_GROUP_BYS_INFO.layerName,
    ...getChartSplitAndSize(
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHART_GROUP_BYS_INFO.layerName
    ),
    sortBy: QuoteChartSortByOptions[0],
  },
  {
    index: 3,
    loading: false,
    highlight: false,
    chart: QUOTE_CHARTS_INFO.bubbleInfo,
    metric: QUOTE_CHART_METRICS_INFO.quoteVsfotRate,
    groupBy: QUOTE_CHART_GROUP_BYS_INFO.fotQuoteIndicator,
    ...getChartSplitAndSize(
      QUOTE_CHARTS_INFO.bubbleInfo,
      QUOTE_CHART_GROUP_BYS_INFO.fotQuoteIndicator
    ),
    sortBy: QuoteChartSortByOptions[0],
  },
]
