<div class="new-group">
  <h1 mat-dialog-title>New {{pageName}} Name</h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <input
        title="name"
        required
        matInput
        [placeholder]="pageName + ' Name'"
        ngDefaultControl
        [(ngModel)]="groupName"
        #groupNameModel="ngModel"
        #input
        maxlength="50"
      />
      <mat-error *ngIf="groupNameModel.invalid"
      >Value is required.</mat-error
      >
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button appButton accent (click)="newGroupClick()">
      <span>Add</span>
    </button>
    <button appButton link mat-dialog-close>
      <span>Cancel</span>
    </button>
  </div>
</div>
