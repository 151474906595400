import { OperatorFunction } from 'rxjs'
import { MaybeData, MaybeError } from '../model/api.model'
import { map } from 'rxjs/operators'
import * as Papa from 'papaparse'
import { errorPayload } from '../../error/model/error'

export const parseCsvResponse = <T>(): OperatorFunction<
  MaybeData<string> & MaybeError,
  MaybeData<T[]> & MaybeError
> =>
  map(response => {
    if (response.error) {
      return { error: response.error }
    } else {
      const parsedResponse = Papa.parse<T>(response.data as string, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
      })
      if (parsedResponse.errors.length === 0) {
        return { data: parsedResponse.data }
      } else {
        return {
          error: errorPayload(
            'Error parsing csv',
            parsedResponse.errors.map(e => e.message)
          ),
        }
      }
    }
  })
