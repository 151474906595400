import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { AppState } from '../core/store'
import { map, withLatestFrom, delayWhen, filter } from 'rxjs/operators'
import { UserPermissions } from '../core/model/auth.model'
import { selectAuthState } from '../core/store/auth/auth.selectors'

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return of(1).pipe(
      withLatestFrom(this.store.pipe(select(selectAuthState))),
      delayWhen(([_, authState]) => {
        if (!authState.userPermissions) {
          return this.store.pipe(
            select(selectAuthState),
            filter(
              state =>
                (state.userPermissions !== null &&
                  state.analyzeReToken !== null &&
                  state.marketToken !== null) ||
                state.userPermissionsError !== null
            )
          )
        } else {
          return of(1)
        }
      }),
      withLatestFrom(this.store.pipe(select(selectAuthState))),
      map(([[_, _oldAuthState], authState]) => {
        if (!authState.authenticated) {
          return false
        }
        if (authState.userPermissions && authState.security) {
          const allowedGuards = this.checkPermissions(
            authState.userPermissions,
            authState.security
          )
          if (_state.url.includes('/signature')) {
            if (allowedGuards.has('/signature')) {
              return true
            }
          } else if (_state.url.startsWith('/sliptemplates')) {
            if (allowedGuards.has('/sliptemplates')) {
              return true
            }
          } else if (
            _state.url.includes('/quote') &&
            _state.url.includes('/main')
          ) {
            if (allowedGuards.has('/quote/main')) {
              return true
            }
          } else if (
            _state.url.includes('/quote') &&
            _state.url.includes('/management-information')
          ) {
            if (allowedGuards.has('/quote/management-information')) {
              return true
            }
          } else if (_state.url.includes('/digital')) {
            if (allowedGuards.has('/digital')) {
              return true
            }
          } else if (_state.url.startsWith('/display')) {
            if (allowedGuards.has('/display')) {
              return true
            }
          } else if (_state.url.startsWith('/clients')) {
            // both Design ("home) and Credit-* ("credit/*") modules use the /clients root url
            if (
              allowedGuards.has('/home') ||
              allowedGuards.has('/credit/design') ||
              allowedGuards.has('/credit/group') ||
              allowedGuards.has('/credit/compare') ||
              allowedGuards.has('/credit/metrics') ||
              allowedGuards.has('/credit/pricingcurve') ||
              allowedGuards.has('/credit/submission') ||
              allowedGuards.has('/credit/monitoring')
            ) {
              return true
            }
          } else if (
            _state.url.includes('/analysis') &&
            _state.url.includes('/group')
          ) {
            if (allowedGuards.has('/analysis/group')) {
              return true
            }
          } else if (
            _state.url.includes('/analysis') &&
            _state.url.includes('/compare')
          ) {
            if (allowedGuards.has('/analysis/compare')) {
              return true
            }
          } else if (
            _state.url.includes('/analysis') &&
            _state.url.includes('/portfolios')
          ) {
            if (allowedGuards.has('/analysis')) {
              return true
            }
          } else if (_state.url.startsWith('/admin')) {
            if (_state.url.includes('/main')) {
              if (allowedGuards.has('/admin/main')) {
                return true
              }
            }
            if (_state.url.includes('/credit')) {
              if (allowedGuards.has('/admin/credit')) {
                return true
              }
            }
          } else if (
            // run before general /metrics check to capture credit/metrics permissions, first
            _state.url.includes('/credit') &&
            _state.url.includes('/metrics')
          ) {
            if (allowedGuards.has('/credit/metrics')) {
              return true
            }
          } else if (_state.url.includes('/metrics')) {
            if (allowedGuards.has('/metrics')) {
              return true
            }
          } else if (
            _state.url.includes('/analysis') &&
            _state.url.includes('/explore')
          ) {
            if (allowedGuards.has('/analysis')) {
              return true
            }
          } else if (_state.url.includes('/benchmark')) {
            if (allowedGuards.has('/benchmark')) {
              return true
            }
          } else if (_state.url.startsWith('/facultative')) {
            if (allowedGuards.has('/facultative')) {
              return true
            }
          } else if (_state.url.startsWith('/facultative-admin')) {
            return true
          } else if (_state.url.includes('/reinsurers')) {
            if (allowedGuards.has('/reinsurers')) {
              return true
            }
          } else if (_state.url.startsWith('/tracking')) {
            if (allowedGuards.has('/quote/main')) {
              return true
            }
          } else if (_state.url.startsWith('/fac-dashboard')) {
            if (allowedGuards.has('/fac-dashboard')) {
              return true
            }
          } else if (_state.url.startsWith('/kpi-dashboard')) {
            if (allowedGuards.has('/kpi-dashboard')) {
              return true
            }
          } else if (_state.url.includes('/management-information')) {
            if (allowedGuards.has('/management-information')) {
              return true
            }
          } else if (_state.url.includes('/revenue-dashboard')) {
            if (allowedGuards.has('/revenue-dashboard')) {
              return true
            }
          } else if (_state.url.includes('/program-initiation')) {
            if (allowedGuards.has('/program-initiation')) {
              return true
            }
          } else if (_state.url.includes('/prove')) {
            if (allowedGuards.has('/prove')) {
              return true
            }
          } else if (
            // run before general /pricingcurve check to capture credit/pricingcurve permissions, first
            _state.url.includes('/credit') &&
            _state.url.includes('/pricingcurve')
          ) {
            if (allowedGuards.has('/credit/pricingcurve')) {
              return true
            }
          } else if (_state.url.includes('/pricingcurve')) {
            if (allowedGuards.has('/pricingcurve')) {
              return true
            }
          } else if (_state.url.includes('/technical-premium')) {
            if (allowedGuards.has('/pricingcurve')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/design')
          ) {
            if (allowedGuards.has('/credit/design')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/group')
          ) {
            if (allowedGuards.has('/credit/group')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/compare')
          ) {
            if (allowedGuards.has('/credit/compare')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/submission')
          ) {
            if (allowedGuards.has('/credit/submission')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/monitoring')
          ) {
            if (allowedGuards.has('/credit/monitoring')) {
              return true
            }
          } else if (
            _state.url.includes('/credit') &&
            _state.url.includes('/quote')
          ) {
            if (allowedGuards.has('/credit/quote')) {
              return true
            }
          } else if (_state.url.includes('/maintenance')) {
              return true
          } else {
            this.router.navigate(['/auth-callback'])
            return false
          }
        }
        return false
      })
    )
  }

  checkPermissions(
    userPermissions: UserPermissions,
    securityPermissions: string[]
  ) {
    const allowedGuards = new Set<string>()
    allowedGuards.add('/maintenance')
    Object.keys(userPermissions).forEach(carrier => {
      userPermissions[carrier].forEach(permission => {
        if (permission.route !== null) {
          allowedGuards.add(permission.route)
        }
        // Allow Tracking for all users who has Quote Sage Permission
        if (permission.route === '/quote/main') {
            allowedGuards.add('/tracking')
        }
      })
    })

    securityPermissions.forEach((entry: string) => {
      if (entry === 'Security') {
        allowedGuards.add('/admin/main')
      } else if (entry === 'Credit Security') {
        allowedGuards.add('/admin/credit')
      } else if (entry === 'Display') {
        allowedGuards.add('/display')
      } else if (entry === 'Digital Client') {
        allowedGuards.add('/digital')
      } else if (entry === 'Slip Templates') {
        allowedGuards.add('/sliptemplates')
      } else if (entry === 'Facultative') {
        allowedGuards.add('/facultative')
      } else if (entry === 'Signature Page') {
        allowedGuards.add('/signature')
      } else if (entry === 'Benchmarking') {
        allowedGuards.add('/benchmark')
      } else if (entry === 'Management Information') {
        allowedGuards.add('/management-information')
      } else if (entry === 'Revenue Dashboard') {
        allowedGuards.add('/revenue-dashboard')
      } else if (entry === 'Fac Dashboard') {
        allowedGuards.add('/fac-dashboard')
      } else if (entry === 'KPI Dashboard') {
        allowedGuards.add('/kpi-dashboard')
      } else if (entry === 'Program Initiation') {
        allowedGuards.add('/program-initiation')
      } else if (entry === 'PROVE') {
        allowedGuards.add('/prove')
      } else if (entry === 'Quote Sage - Quotes') {
        allowedGuards.add('/quote/main')
      } else if (entry === 'Quote Sage - Dashboards') {
        allowedGuards.add('/quote/management-information')
      } else if (entry === 'Pricing Curve') {
        allowedGuards.add('/pricingcurve')
      } else if (entry === 'Credit SAGE - Pricing Curve') {
        allowedGuards.add('/credit/pricingcurve')
      } else if (entry === 'Credit SAGE - Design') {
        allowedGuards.add('/credit/design')
      } else if (entry === 'Credit SAGE - Group') {
        allowedGuards.add('/credit/group')
      } else if (entry === 'Credit SAGE - Compare') {
        allowedGuards.add('/credit/compare')
      } else if (entry === 'Credit SAGE - Metrics') {
        allowedGuards.add('/credit/metrics')
      } else if (entry === 'Credit SAGE - Submission') {
        allowedGuards.add('/credit/submission')
      } else if (entry === 'Credit SAGE - Monitoring') {
        allowedGuards.add('/credit/monitoring')
      }
    })
    return allowedGuards
  }
}
