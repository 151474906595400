import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { ElementRef } from '@angular/core'
import { clamp } from 'ramda'

type Constructor<T> = new (...args: any[]) => T

interface HasElementRef {
  _elementRef: ElementRef
}

export type Size =
  | 'massive'
  | 'gigantic'
  | 'huge'
  | 'big'
  | 'body'
  | 'small'
  | 'tiny'
  | 'mini'
  | undefined

export type CanSizeCtor = Constructor<CanSize>

export interface CanSize {
  size: Size
  massive: boolean
  gigantic: boolean
  huge: boolean
  big: boolean
  small: boolean
  tiny: boolean
  mini: boolean
}

export const mixinSize = <T extends Constructor<HasElementRef>>(
  base: T,
  defaultValue?: Size
): CanSizeCtor & T =>
  class extends base {
    get massive(): boolean {
      return this._massive
    }
    set massive(value: boolean) {
      this._massive = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _massive: boolean

    get gigantic(): boolean {
      return this._gigantic
    }
    set gigantic(value: boolean) {
      this._gigantic = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _gigantic: boolean

    get huge(): boolean {
      return this._huge
    }
    set huge(value: boolean) {
      this._huge = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _huge: boolean

    get big(): boolean {
      return this._big
    }
    set big(value: boolean) {
      this._big = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    _big: boolean

    get body(): boolean {
      return this._body
    }
    set body(value: boolean) {
      this._body = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    _body: boolean

    get small(): boolean {
      return this._small
    }
    set small(value: boolean) {
      this._small = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _small: boolean

    get tiny(): boolean {
      return this._tiny
    }
    set tiny(value: boolean) {
      this._tiny = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _tiny: boolean

    get mini(): boolean {
      return this._mini
    }
    set mini(value: boolean) {
      this._mini = coerceBooleanProperty(value)
      this._size = this._determineSize(this._size)
    }
    private _mini: boolean

    get size(): Size {
      return this._size
    }
    set size(value: Size) {
      this._size = this._determineSize(value)
    }
    private _size: Size

    constructor(...args: any[]) {
      super(...args)
      this.size = defaultValue
    }

    private _determineSize(value?: Size): Size {
      if (this._mini) {
        return 'mini'
      }
      if (this._tiny) {
        return 'tiny'
      }
      if (this._small) {
        return 'small'
      }
      if (this._body) {
        return 'body'
      }
      if (this._big) {
        return 'big'
      }
      if (this._huge) {
        return 'huge'
      }
      if (this._gigantic) {
        return 'gigantic'
      }
      if (this._massive) {
        return 'massive'
      }
      return value || defaultValue
    }
  }

export const sizeMap: Size[] = [
  'mini',
  'tiny',
  'small',
  'body',
  'big',
  'huge',
  'gigantic',
  'massive',
]

export const adjustSize = (size: Size, adjustment: number): Size => {
  const i = sizeMap.findIndex(s => s === size)
  return sizeMap[clamp(0, sizeMap.length - 1, i + adjustment)]
}
