import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import { Study } from '../../../../core/model/study.model'
import { ProgramEntity } from '../../../store/grouper/program/program.reducer'
import { ScenarioEventResult } from '../../animated-scenarios.model'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { SharedIDGroup } from '../../../store/grouper/program-group.model'
import { MatDialog } from '@angular/material/dialog'
import { CededLossDetailsDialogComponent } from '../../ceded-loss-details-dialog/ceded-loss-details-dialog.component'
import { ScenarioEventResultTableComponent } from '../../event-result-table/event-result-table.component'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-animated-event-result',
  styleUrls: ['./group-event-result.components.scss'],
  templateUrl: './group-event-result.components.html',
})
export class GroupEventResultComponent implements OnInit, OnDestroy {
  @Input() studies: Study[]
  @Input() structureEntities: ProgramEntity[]
  @Input() eventResults: ScenarioEventResult[]
  @Input() loadingGroup: boolean
  @Input() sharedIDGroup: SharedIDGroup[]
  @Input() animating: boolean
  @Input() eventResultByEntity: Record<string, ScenarioEventResult[]>
  @Input() structureNameByEntity: Record<string, string>

  @Output() animationEnd = new EventEmitter()

  tower3DSwiperConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    navigation: false,
    observer: true,
    observeParents: true,
    touchEventsTarget: 'container',
    preventClicks: false,
    simulateTouch: false,
    pagination: false,
    watchOverflow: true,
    touchStartPreventDefault: false,
  }

  configGroup: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    watchOverflow: true,
    touchStartPreventDefault: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
  }
  @ViewChildren(ScenarioEventResultTableComponent)
  eventResultTableComponents: QueryList<ScenarioEventResultTableComponent>

  @ViewChild('tableResultsSwiper', { static: false })
  set swiperRef(val: ElementRef) {
    this._swiperRef = val
  }
  get swiperRef() {
    return this._swiperRef
  }
  _swiperRef: ElementRef

  animationIndexSubject$ = new BehaviorSubject<number | null>(null)
  animationEndSubject$ = new BehaviorSubject<boolean | null>(null)
  private destroy$ = new Subject()

  constructor(
    private dialog: MatDialog,
    private changeRef: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.animationIndexSubject$
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(val => {
        if (val !== null) {
          this.onEventAnimationIndex(val)
          this.changeRef.markForCheck()
        }
      })
    this.animationEndSubject$
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(val => {
        if (val !== null) {
          this.animationEnd.emit()
          this.changeRef.markForCheck()
        }
      })
  }

  trackByID(entity: ProgramEntity, index: number): string | number {
    return (entity && entity.program && entity.program.id) || index
  }

  onCededLossClick(data: ScenarioEventResult): void {
    this.dialog.open(CededLossDetailsDialogComponent, { data })
  }

  onEventAnimationIndex(index: number): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slideTo(index - 1)
      this.eventResultTableComponents.toArray()[index - 1].interpolate()
    }
  }

  onPrev(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slidePrev()
    }
  }

  onNext(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slideNext()
    }
  }

  get towerClass() {
    return {
      'swiper-slide-half-width':
        this.structureEntities.length === 1 ||
        this.structureEntities.length === 2,
      'swiper-slide-third-width': this.structureEntities.length === 3,
      'swiper-slide': true,
    }
  }

  get scale() {
    return (
      58 /
      Math.min(
        this.structureEntities.length === 1 ? 2 : this.structureEntities.length,
        4
      )
    )
  }
}
