import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core'
import { resolveLayerMetricDef } from '../../../analysis/model/layer-metric-defs'
import { PercentageInputComponent } from '../../../analysis/properties/layer/type-inputs/percentage-input.component'
import { MetricValueType } from '../../../core/model/metric-value-type.model'
import { LayerPropertyComponent } from '../../layer-property/layer-property.component'
import { SortTableColumnView, SortTableRow } from '../sort-table.model'
import { isLayerMetricApplicable } from '../../../analysis/model/layer-metric-applicability'
import {
  HtmlEditorService,
  LinkService,
  PasteCleanupService,
} from '@syncfusion/ej2-angular-richtexteditor'
import { CREDIT_ANIMATED_SCENARIOS } from 'src/app/credit/model/credit-animated-scenarios.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LinkService, HtmlEditorService, PasteCleanupService],
  selector: 'app-sort-table-value',
  styleUrls: ['./sort-table-value.component.scss'],
  templateUrl: './sort-table-value.component.html',
})
export class SortTableValueComponent<T extends { id: string }>
  implements OnChanges
{
  value: SortTableRow<T>[keyof T]
  editable = false
  submitting = false
  percentFormat: string
  decimals = 0
  isHtml = false

  @Input() excelEnabled = false
  @Input() column: SortTableColumnView<T>
  @Input() row: SortTableRow<T>
  @Input() currentCurrency?: string
  @Input() removeSuffixCurrencyTemplateOption = false
  @Input() isAssignedLines: boolean
  @Input() noLoadingSpinnerOnSubmit: boolean
  @Input() isQQ: boolean
  @Input() useZeroText: boolean
  @Input() customClick: boolean
  @Input() isLayerDetailsTab: boolean
  notApplicableText = 'na'

  private _clamp: number
  @Input() set clamp(value: number) {
    this._clamp = coerceNumberProperty(value)
  }
  get clamp() {
    return this._clamp
  }

  private _readonly = false
  @Input() set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value)
  }
  get readonly() {
    return this._readonly
  }

  private _spacing: 'tight' | 'loose' = 'loose'
  @Input() set spacing(value: 'tight' | 'loose') {
    this._spacing = value
  }
  get spacing() {
    return this._spacing
  }

  @HostBinding('class.tight') get isSpacingTight() {
    return this._spacing === 'tight'
  }

  @HostListener('mousedown', ['$event'])
  handleMouseDown(event: MouseEvent): void {
    if (this.excelEnabled) {
      if (event.button === 2) {
        event.preventDefault()
      }
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyDownEvent(event: KeyboardEvent): void {
    if (this.excelEnabled) {
      if (event.key === 'Tab' || event.key === 'Enter') {
        event.preventDefault()
      }
    }
  }

  @Output() valueChange = new EventEmitter<any>()
  @Output() customClickFunction = new EventEmitter<SortTableRow<T>>()

  @ViewChild(PercentageInputComponent)
  percentageInput: PercentageInputComponent
  @ViewChild(LayerPropertyComponent)
  input: LayerPropertyComponent

  ngOnChanges(): void {
    this.value = this.row[this.column.id]
    if (this.column.id.toString() === 'pmpm') {
      this.column.numberTransform = (val: number) => val
      this.column.numberReverseTransform = (val: number) => val
    }
    this.isHtml = this.checkIsHtml()
    if (
      this.column &&
      (this.value || (typeof this.value === 'number' && this.value === 0))
    ) {
      this.decimals = this.column.decimals ?? 1
      this.percentFormat = `1.${this.decimals}-${this.decimals}`
      if (
        (this.isAssignedLines && this.column.id.toString() === 'written') ||
        this.column.id.toString() === 'recommended' ||
        this.column.id.toString() === 'signed'
      ) {
        const currentValue = Number(this.value)
        const decimalValue = currentValue * 100
        const convertedString = parseFloat(decimalValue.toString())
        const result = Number(convertedString.toPrecision(15)) * 1.0
        const count = this.countDecimals(result)
        if (count === 0) {
          this.percentFormat = `1.${this.column.decimals}-${this.column.decimals}`
          this.decimals = this.column.decimals ?? 1
        }
        if (count > 0) {
          this.percentFormat = count <= 8 ? `1.${count}-${count}` : `1.8-8`
          this.decimals = count <= 8 ? count : 8
        }
      }
      if (this.column.numberFormat != null) {
        this.percentFormat = this.column.numberFormat
      }
    }
    this.editable = resolveLayerMetricDef(this.column, this.row as any).editable

    if (this.column.isEditableFunction) {
      this.editable = this.column.isEditableFunction(this.row)
    }
    this.submitting = false
  }

  getTextStyle(column: SortTableColumnView<T>) {
    if (this.clamp || column.clamp) {
      return {
        '-webkit-line-clamp': column.clamp || this.clamp,
      }
    }
  }

  getRowColor(row: SortTableRow<T>) {
    if (row.style && row.type === CREDIT_ANIMATED_SCENARIOS) {
      return { color: row.style }
    }
  }

  getRankColorChangeByValue() {
    if (this.column.id !== 'rankChange') {
      return
    }
    const value = String(this.value)
    let style = {}
    const containsSymbol =
      value === 'Λ' || value === 'V' || value === '―' || value === '✖'
    if (containsSymbol) {
      switch (value) {
        case 'Λ':
          style = { color: 'green' }
          break
        case 'V':
          style = { color: 'red' }
          break
        case '―':
          style = { color: 'yellow', fontWeight: '800' }
          break
        case '✖':
          style = {
            color: 'white',
            backgroundColor: 'red',
            borderRadius: '50%',
            padding: '0 2px',
          }
          break
        default:
          break
      }
    }
    return style
  }

  checkIsHtml(): boolean {
    if (typeof this.value === 'string') {
      const htmlRegex = /<("[^"]*"|'[^']*'|[^'">])*>/
      return htmlRegex.test(this.value)
    }
    return false
  }

  countDecimals(value: number): number {
    if (Math.floor(value) === value) {
      return 0
    }
    return value.toString().split('.')[1].length || 0
  }

  getValueType(
    column: SortTableColumnView<T>,
    row: SortTableRow<T>
  ): MetricValueType | undefined {
    return row.valueTypeByColumn?.[column.id] ?? column.valueType
  }

  getId(column: SortTableColumnView<T>): string | number | symbol {
    return column.id
  }

  getTooltip(
    column: SortTableColumnView<T>,
    row: SortTableRow<T>
  ): string | undefined {
    return row.tooltipByColumn?.[column.id]
  }
  toThousands = (val: number) => val / 1000
  fromThousands = (val: number) => val * 1000

  onSubmit(): void {
    if (this.value !== this.row[this.column.id]) {
      this.submitting = true
      this.valueChange.emit(this.value)
    }
    if (this.input) {
      this.input.blur()
    }
    if (this.percentageInput) {
      this.percentageInput.blur()
    }
  }

  onCustomClick(): void {
    this.customClickFunction.emit(this.row)
  }

  get isApplicable(): boolean {
    if (
      this.row.type &&
      this.row.type.includes('multisection') &&
      (this.column.id === 'occurrenceLimit' ||
        (this.column.id === 'occurrenceAttachment' &&
          this.row.subtype !== 'main-layer'))
    ) {
      return true
    }
    return isLayerMetricApplicable(
      this.row.type,
      this.row.subtype,
      this.column.whitelist,
      this.column.blacklist
    )
  }
}
