import { createAction, props } from '@ngrx/store'
import { ScenarioEventResult } from '../../animated-scenarios.model'

export const addAllGroupScenarioEventResults = createAction(
  '[Animated Scenarios] Add All Group Scenario Event Results',
  props<{
    eventResults: ScenarioEventResult[]
  }>()
)

export const removeAllGroupScenarioEventResults = createAction(
  '[Animated Scenarios] Remove All Group Scenario Event Results'
)
