// tslint:disable-next-line: max-line-length
import { PortfolioViewMetricsAndAction } from '../store/metrics/portfolio-metrics.effects'
import {
  PortfolioMetrics,
  PortfolioViewMetricsPayload,
} from './portfolio-metrics.model'
import { PortfolioSetID } from './portfolio-set.model'
import { LayerViewMetricsAndAction } from '../store/metrics/layers-metrics.effects'
import { LayerMetricsRP } from './layers-metrics.model'
import { Perspective } from './metrics.model'

function isLossPerspective(value: { perspective: Perspective }) {
  return value.perspective === 'Loss' || value.perspective === 'LossRp'
}

function _toPortfolioViewMetrics(
  value: PortfolioViewMetricsAndAction,
  id: string
) {
  const isLoss = isLossPerspective(value)
  const portfolioMetrics: PortfolioMetrics = {
    portfolioType: value.portfolioType,
    aggregationMethod: value.aggregationMethod,
    perspective: value.perspective,
    lossFilter: value.lossFilter,
    returnPeriod1: value.returnPeriod1,
    returnPeriod2: value.returnPeriod2,
    returnPeriod3: value.returnPeriod3,
    returnPeriod4: value.returnPeriod4,
    returnPeriod5: value.returnPeriod5,
    returnPeriodData: [
      {
        header: 'Expected',
        value: null,
        term: 'VaR',
        period1: isLoss
          ? value.metrics[id][0].min
          : value.metrics[id][0].min * -1,
        period2: isLoss
          ? value.metrics[id][1].min
          : value.metrics[id][1].min * -1,
        period3: isLoss
          ? value.metrics[id][2].min
          : value.metrics[id][2].min * -1,
        period4: isLoss
          ? value.metrics[id][3].min
          : value.metrics[id][3].min * -1,
        period5: isLoss
          ? value.metrics[id][4].min
          : value.metrics[id][4].min * -1,
        mean: value.metrics[id][4].mean,
      },
      {
        header: 'Std Dev',
        value: null,
        term: 'tVaR',
        period1: isLoss
          ? value.metrics[id][0].mean
          : value.metrics[id][0].mean * -1,
        period2: isLoss
          ? value.metrics[id][1].mean
          : value.metrics[id][1].mean * -1,
        period3: isLoss
          ? value.metrics[id][2].mean
          : value.metrics[id][2].mean * -1,
        period4: isLoss
          ? value.metrics[id][3].mean
          : value.metrics[id][3].mean * -1,
        period5: isLoss
          ? value.metrics[id][4].mean
          : value.metrics[id][4].mean * -1,
        mean: value.metrics[id][4].mean,
      },
      {
        header: null,
        value: null,
        term: 'Std Dev',
        period1: Math.sqrt(value.metrics[id][0].variance),
        period2: Math.sqrt(value.metrics[id][1].variance),
        period3: Math.sqrt(value.metrics[id][2].variance),
        period4: Math.sqrt(value.metrics[id][3].variance),
        period5: Math.sqrt(value.metrics[id][4].variance),
        mean: value.metrics[id][4].mean,
      },
    ],
  }
  return portfolioMetrics
}

export function toPortfolioViewMetrics(
  value: PortfolioViewMetricsAndAction,
  grossViewID: string,
  _cededViewID: string,
  netViewID: string
): PortfolioMetrics {
  if (value.portfolioType === 'Ceded') {
    const grossMetrics = _toPortfolioViewMetrics(value, grossViewID)
    const netMetrics = _toPortfolioViewMetrics(value, netViewID)
    const cededMetrics: PortfolioMetrics = {
      ...grossMetrics,
      returnPeriodData: [
        {
          header: 'Expected',
          value: null,
          term: 'VaR',
          period1:
            netMetrics.returnPeriodData[0].period1 -
            grossMetrics.returnPeriodData[0].period1,
          period2:
            netMetrics.returnPeriodData[0].period2 -
            grossMetrics.returnPeriodData[0].period2,
          period3:
            netMetrics.returnPeriodData[0].period3 -
            grossMetrics.returnPeriodData[0].period3,
          period4:
            netMetrics.returnPeriodData[0].period4 -
            grossMetrics.returnPeriodData[0].period4,
          period5:
            netMetrics.returnPeriodData[0].period5 -
            grossMetrics.returnPeriodData[0].period5,
          mean: value.metrics[grossViewID][4].mean,
        },
        {
          header: 'Std Dev',
          value: null,
          term: 'tVaR',
          period1:
            netMetrics.returnPeriodData[1].period1 -
            grossMetrics.returnPeriodData[1].period1,
          period2:
            netMetrics.returnPeriodData[1].period2 -
            grossMetrics.returnPeriodData[1].period2,
          period3:
            netMetrics.returnPeriodData[1].period3 -
            grossMetrics.returnPeriodData[1].period3,
          period4:
            netMetrics.returnPeriodData[1].period4 -
            grossMetrics.returnPeriodData[1].period4,
          period5:
            netMetrics.returnPeriodData[1].period5 -
            grossMetrics.returnPeriodData[1].period5,
          mean: value.metrics[grossViewID][4].mean,
        },
        {
          header: null,
          value: null,
          term: 'Std Dev',
          period1:
            netMetrics.returnPeriodData[2].period1 -
            grossMetrics.returnPeriodData[2].period1,
          period2:
            netMetrics.returnPeriodData[2].period2 -
            grossMetrics.returnPeriodData[2].period2,
          period3:
            netMetrics.returnPeriodData[2].period3 -
            grossMetrics.returnPeriodData[2].period3,
          period4:
            netMetrics.returnPeriodData[2].period4 -
            grossMetrics.returnPeriodData[2].period4,
          period5:
            netMetrics.returnPeriodData[2].period5 -
            grossMetrics.returnPeriodData[2].period5,
          mean: value.metrics[grossViewID][4].mean,
        },
      ],
    }
    return cededMetrics
  } else {
    let id
    if (value.portfolioType === 'Gross') {
      id = grossViewID
    } else {
      id = netViewID
    }
    return _toPortfolioViewMetrics(value, id)
  }
}

export function defaultPortfolioViewMetricsPayload(
  portfolioSetID: PortfolioSetID,
  id: string
): PortfolioViewMetricsPayload {
  return {
    ...portfolioSetID,
    id,
    perspective: 'Loss',
    portfolioType: 'Net',
    returnPeriod1: 10,
    returnPeriod2: 25,
    returnPeriod3: 100,
    returnPeriod4: 250,
    returnPeriod5: 500,
    aggregationMethod: 'AEP',
    lossFilter: 'all',
  }
}

export function constructPortfolioViewMetricsPayload(
  portfolioSetID: PortfolioSetID,
  id: string,
  metrics: PortfolioMetrics
): PortfolioViewMetricsPayload {
  return {
    ...portfolioSetID,
    id,
    portfolioType: metrics.portfolioType,
    returnPeriod1: metrics.returnPeriod1,
    returnPeriod2: metrics.returnPeriod2,
    returnPeriod3: metrics.returnPeriod3,
    returnPeriod4: metrics.returnPeriod4,
    returnPeriod5: metrics.returnPeriod5,
    aggregationMethod: metrics.aggregationMethod,
    perspective: metrics.perspective,
    lossFilter: metrics.lossFilter,
  }
}

export function updatePortfolioViewMetricsPayload(
  portfolioSetID: PortfolioSetID,
  id: string,
  metrics: PortfolioMetrics,
  changes: Partial<PortfolioViewMetricsPayload>
) {
  return {
    ...portfolioSetID,
    id,
    aggregationMethod: metrics.aggregationMethod,
    perspective: metrics.perspective,
    portfolioType: metrics.portfolioType,
    lossFilter: metrics.lossFilter,
    returnPeriod1: metrics.returnPeriod1,
    returnPeriod2: metrics.returnPeriod2,
    returnPeriod3: metrics.returnPeriod3,
    returnPeriod4: metrics.returnPeriod4,
    returnPeriod5: metrics.returnPeriod5,
    ...changes,
  }
}

function _toLayerViewMetrics(value: LayerViewMetricsAndAction, id: string) {
  const isLoss = isLossPerspective(value)
  const layerMetrics: LayerMetricsRP = {
    aggregationMethod: value.aggregationMethod,
    perspective: value.perspective,
    returnPeriod1: value.returnPeriod1,
    returnPeriod2: value.returnPeriod2,
    returnPeriod3: value.returnPeriod3,
    returnPeriod4: value.returnPeriod4,
    returnPeriod5: value.returnPeriod5,
    returnPeriodData: [
      {
        header: 'Expected',
        value: null,
        term: 'VaR',
        period1: isLoss
          ? toLayerRPValues(value.metrics[id][0].min)
          : toLayerRPValues(value.metrics[id][0].min) * -1,
        period2: isLoss
          ? toLayerRPValues(value.metrics[id][1].min)
          : toLayerRPValues(value.metrics[id][1].min) * -1,
        period3: isLoss
          ? toLayerRPValues(value.metrics[id][2].min)
          : toLayerRPValues(value.metrics[id][2].min) * -1,
        period4: isLoss
          ? toLayerRPValues(value.metrics[id][3].min)
          : toLayerRPValues(value.metrics[id][3].min) * -1,
        period5: isLoss
          ? toLayerRPValues(value.metrics[id][4].min)
          : toLayerRPValues(value.metrics[id][4].min) * -1,
        mean: value.metrics[id][4].mean,
      },
      {
        header: 'Std Dev',
        value: null,
        term: 'tVaR',
        period1: isLoss
          ? toLayerRPValues(value.metrics[id][0].mean)
          : toLayerRPValues(value.metrics[id][0].mean) * -1,
        period2: isLoss
          ? toLayerRPValues(value.metrics[id][1].mean)
          : toLayerRPValues(value.metrics[id][1].mean) * -1,
        period3: isLoss
          ? toLayerRPValues(value.metrics[id][2].mean)
          : toLayerRPValues(value.metrics[id][2].mean) * -1,
        period4: isLoss
          ? toLayerRPValues(value.metrics[id][3].mean)
          : toLayerRPValues(value.metrics[id][3].mean) * -1,
        period5: isLoss
          ? toLayerRPValues(value.metrics[id][4].mean)
          : toLayerRPValues(value.metrics[id][4].mean) * -1,
        mean: value.metrics[id][4].mean,
      },
      {
        header: null,
        value: null,
        term: 'Std Dev',
        period1: isLoss
          ? toLayerRPValues(Math.sqrt(value.metrics[id][0].variance))
          : toLayerRPValues(Math.sqrt(value.metrics[id][0].variance)),
        period2: isLoss
          ? toLayerRPValues(Math.sqrt(value.metrics[id][1].variance))
          : toLayerRPValues(Math.sqrt(value.metrics[id][1].variance)),
        period3: isLoss
          ? toLayerRPValues(Math.sqrt(value.metrics[id][2].variance))
          : toLayerRPValues(Math.sqrt(value.metrics[id][2].variance)),
        period4: isLoss
          ? toLayerRPValues(Math.sqrt(value.metrics[id][3].variance))
          : toLayerRPValues(Math.sqrt(value.metrics[id][3].variance)),
        period5: isLoss
          ? toLayerRPValues(Math.sqrt(value.metrics[id][4].variance))
          : toLayerRPValues(Math.sqrt(value.metrics[id][4].variance)),
        mean: value.metrics[id][4].mean,
      },
    ],
  }
  return layerMetrics
}

export function toLayerViewMetrics(
  value: LayerViewMetricsAndAction,
  id: string
): LayerMetricsRP {
  return _toLayerViewMetrics(value, id)
}

function toLayerRPValues(value: number) {
  return value
}
