<div class="selector">
  <div class="tier-bar-opportunity">
    <app-tier-bar-container contextPath="metrics"></app-tier-bar-container>

    <app-program-opportunity-info
      [programs]="programs"
      [selectedProgramID]="selectedProgramID"
      [accountOpportunities]="accountOpportunities"
    ></app-program-opportunity-info>
  </div>
</div>
<div class="metrics-container">
  <app-metric-settings
    class="metric-settings"
    [clientID]="clientID"
    [studyID]="studyID"
    [studies]="studies"
    [metricCategories]="metricCategories"
    [expandedMetricCategories]="expandedMetricCategories"
    [studySelected]="studySelected"
    [totalWeight]="totalWeight"
    [lossFilters]="lossFilters"
    (saveClick)="saveClick.emit($event)"
    (updateMetricElement)="updateMetricElement.emit($event)"
    (collapseToggle)="collapseToggle.emit($event)"
    (addCustomMetric)="addCustomMetric.emit($event)"
    (enableAllClick)="enableAllClick.emit($event)"
  ></app-metric-settings>
</div>
