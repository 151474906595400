import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { chain, uniq } from 'ramda'
import { forkJoin, of } from 'rxjs'
import {
  concatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import {
  selectProgramGroupMemberState,
  selectProgramGroupSetState,
} from 'src/app/core/store/program-group-member.selectors'
import { selectProgramGroupState } from '../../../core/store/program-group/program-group.selectors'
import { selectProgramState } from '../../../core/store/program/program.selectors'
import { ProgramGroupService } from '../../../api/program-group/program-group.service'
import {
  concatMapWithInput,
  mergeApiResponses,
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
  rejectErrorWithOnlyInput,
} from '../../../api/util'
import { AppState } from '../../../core/store'
import { selectCurrentClientID } from '../../../core/store/broker/broker.selectors'
import { PortfolioSetIDAndName } from '../../model/portfolio-set.model'
import {
  selectGrouperProgramGroupMembersToSave,
  selectGrouperProgramGroupsToSave,
} from '../analysis.selectors'
import {
  reconcileAncestorGroups,
  reconcileAncestorGroupsFailure,
  reconcileAncestorGroupsSuccess,
  saveGrouper,
  saveGrouperFailure,
  saveGrouperSuccess,
} from './grouper.actions'
import {
  loadProgramGroupEntities,
  loadProgramGroupEntitiesResponse,
} from './load-program-group-entities'
import { ProgramGroupEntityWithProgramPortfolioIDs } from './program-group.model'
import { createFetchPortfolioViewActionsFromGroups } from './program-group/create-portfolio-view-action'

@Injectable()
export class GrouperEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private programGroupService: ProgramGroupService,
    private inuranceService: InuranceService
  ) {}

  save$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(saveGrouper),
        concatMap(id =>
          of(id).pipe(
            withLatestFrom(
              this.store.pipe(select(selectProgramGroupSetState)),
              this.store.pipe(select(selectGrouperProgramGroupMembersToSave))
            ),
            map(([_, state, members]) => ({ ...state, members }))
          )
        ),
        mergeMapWithInput(({ members, ...state }) => {
          return this.inuranceService.validateOnGroupSave(members, state)
        }),
        rejectErrorWithOnlyInput(error =>
          this.store.dispatch(saveGrouperFailure({ error }))
        ),
        concatMap(id =>
          of(id).pipe(
            withLatestFrom(
              this.store.pipe(select(selectCurrentClientID)),
              this.store.pipe(select(selectGrouperProgramGroupsToSave))
            ),
            map(([state, clientID, groups]) => ({ ...state, clientID, groups }))
          )
        ),
        mergeMapWithInput(({ clientID, groups, members }) =>
          this.programGroupService.save(clientID, groups, members)
        ),
        rejectErrorWithInput(error =>
          this.store.dispatch(saveGrouperFailure({ error }))
        )
      )
      .pipe(
        concatMapWithInput(([changes, state]) =>
          this.inuranceService.reconcileOnGroupDelete(changes, state)
        ),
        rejectErrorWithOnlyInput(error =>
          this.store.dispatch(saveGrouperFailure({ error }))
        ),
        concatMapWithInput(([changes, state]) =>
          this.inuranceService.reconcileOnGroupAdd(changes, state)
        ),
        rejectErrorWithOnlyInput(error =>
          this.store.dispatch(saveGrouperFailure({ error }))
        ),
        map(([changes]) => saveGrouperSuccess(changes))
      )
  )

  fetchPortfolioViewsOnSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveGrouperSuccess),
      map(action => action.groups),
      map(groups =>
        uniq([
          ...groups.create.map(c => c.id),
          ...groups.update.map(c => String(c.id)),
        ])
      ),
      mergeMap(ids => {
        return forkJoin(
          ids.map(id => loadProgramGroupEntitiesResponse(this.store, id, ''))
        )
      }),
      mergeApiResponses(),
      rejectError(error => this.store.dispatch(saveGrouperFailure({ error }))),
      map(programGroupSet => chain(s => s.programGroups, programGroupSet)),
      mergeMap(createFetchPortfolioViewActionsFromGroups)
    )
  )

  reconcileAncestorGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reconcileAncestorGroups),
      map(action => action.ancestorGroups),
      withLatestFrom(
        this.store.pipe(select(selectCurrentClientID)),
        this.store.pipe(select(selectProgramState)),
        this.store.pipe(select(selectProgramGroupState)),
        this.store.pipe(select(selectProgramGroupMemberState))
      ),
      switchMap(
        ([
          groups,
          clientID,
          structureState,
          structureGroupState,
          structureGroupMemberState,
        ]) => {
          if (groups.length > 0) {
            const groupsToUpdate: ProgramGroupEntityWithProgramPortfolioIDs[] =
              []

            groups.forEach(g => {
              // Using programGroupID, get all descendant structures
              const pgID = g.id
              const pgSet = loadProgramGroupEntities(
                pgID,
                clientID,
                structureState,
                structureGroupState,
                structureGroupMemberState
              )
              const structurePortfolioIDsAndNames: PortfolioSetIDAndName[] = []

              pgSet.programs.forEach(structure => {
                structurePortfolioIDsAndNames.push({
                  cededPortfolioID: structure.cededPortfolioID,
                  grossPortfolioID: structure.grossPortfolioID,
                  netPortfolioID: structure.netPortfolioID,
                  analysisProfileID: structure.analysisID,
                  name: structure.label,
                })
              })

              const groupEntity = {
                programGroup: g,
                hash: '',
                dirty: false,
                new: false,
                deleted: false,
                untitled: false,
                programPortfolioIDs: structurePortfolioIDsAndNames,
              }

              groupsToUpdate.push(groupEntity)
            })

            return this.programGroupService.updateGroups(
              // tslint:disable-next-line: no-non-null-assertion
              clientID!,
              groupsToUpdate
            )
          } else {
            return of([])
          }
        }
      ),
      map(res => {
        res.forEach(r => {
          if (r.error) {
            return reconcileAncestorGroupsFailure({ error: r.error })
          }
        })
        return reconcileAncestorGroupsSuccess({ savedGroups: res })
      })
    )
  })
}
