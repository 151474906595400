export function toTruthMap<T extends string | number>(
  arr: T[]
): Record<T, boolean> {
  return arr.reduce((acc, it) => {
    acc[it] = true
    return acc
  }, {} as Record<T, boolean>)
}

export function getTruthMapList(truthMap: Record<string | number, boolean>) {
  return Object.keys(truthMap).filter(id => truthMap[id] === true)
}

export function isObjEmpty<T>(obj: T): boolean {
  return Object.keys(obj ?? {}).length === 0
}

export function createDictFromKvps<
  TKey extends string | number | symbol,
  TValue
>(kvps: readonly (readonly [TKey, TValue])[]) {
  const dict = kvps.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {} as Record<TKey, TValue>)
  return dict
}

export function getKeyByValue(v: { [k: string]: any }, value: string): any {
  return (Object.keys(v) as Array<keyof typeof v>).find(key => v[key] === value)
}
