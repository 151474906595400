import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Terminal } from '../analysis/model/inurance.model'
import { getRibbonTagTextTransform } from './ribbon-tag.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ribbon-tag',
  styles: [
    `
           svg {
             color: var(--body);
           }
     
           text {
             font-family: var(--font-family);
             font-weight: var(--font-header-weight);
             fill: var(--body);
           }
         `,
  ],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="width"
      [attr.height]="height"
    >
      <use *ngIf="_terminal === 'source'" xlink:href="#ribbon-tag-source" />
      <use *ngIf="_terminal === 'target'" xlink:href="#ribbon-tag-target" />
      <text [attr.transform]="textTransform" [attr.font-size]="fontSize">
        <tspan>
          <ng-content></ng-content>
        </tspan>
      </text>
    </svg>
  `,
})
export class RibbonTagComponent {
  @Input() width: string | number = 24
  @Input() height: string | number = 18
  @Input() fontSize: string | number = 12

  @Input() terminal?: Terminal

  @Input() set source(value: any) {
    this._source = coerceBooleanProperty(value)
    if (value) {
      this._target = undefined
    }
  }
  _source: boolean | undefined

  @Input() set target(value: any) {
    this._target = coerceBooleanProperty(value)
    if (value) {
      this._source = undefined
    }
  }
  _target: boolean | undefined

  get _terminal(): Terminal {
    return this.terminal ? this.terminal : this._source ? 'source' : 'target'
  }

  get textTransform(): string {
    return getRibbonTagTextTransform(this._terminal)
  }
}
