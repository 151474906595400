import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { AppState } from '../../../core/store'
import * as fromSelectors from '../../store/analysis.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { map } from 'rxjs/operators'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { SavePortfolioPropertiesEvent } from '../../model/tower-properties.model'
import {
  saveTowerPreferences,
  setAllProperties,
  setProgramLogAndSnapping,
} from '../../../core/store/program/program.actions'
import { StructureTowerPreferencesRequest } from '../../../api/model/backend.model'
import { Program } from '../../../core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-properties-dialog-container',
  templateUrl: './portfolio-properties-dialog.container.html',
})
export class PortfolioPropertiesDialogContainerComponent
  implements OnInit, OnDestroy
{
  incrementsYOcc$: Observable<number | null>
  incrementsYDirtyOcc$: Observable<boolean | null>
  maxYOcc$: Observable<number | null>
  maxYDirtyOcc$: Observable<boolean | null>
  mostOcc$: Observable<number | null>
  logMin$: Observable<number | null>
  log$: Observable<boolean | null>
  snapping$: Observable<boolean | null>
  incrementsYAgg$: Observable<number | null>
  incrementsYDirtyAgg$: Observable<boolean | null>
  maxYAgg$: Observable<number | null>
  maxYDirtyAgg$: Observable<boolean | null>
  mostAgg$: Observable<number | null>

  layers$: Observable<LayerState[]>
  portfolioSetID$: Observable<PortfolioSetID | null>
  aggLayers$: Observable<LayerState[]>

  programID$: Observable<Program | undefined>
  currentCurrency$: Observable<string | undefined>
  private destroy$ = new Subject()

  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.programID$ = this.store.pipe(
      select(fromSelectors.selectCurrentProgram)
    )

    this.incrementsYOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccIncrementsY)
    )
    this.incrementsYDirtyOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccIncrementsYDirty)
    )
    this.maxYOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMaxY)
    )
    this.maxYDirtyOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMaxYDirty)
    )
    this.mostOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMost)
    )
    this.logMin$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesLogMin)
    )
    this.log$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesLog)
    )
    this.snapping$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesSnapping)
    )
    this.incrementsYAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggIncrementsY)
    )
    this.incrementsYDirtyAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggIncrementsYDirty)
    )
    this.maxYAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMaxY)
    )
    this.maxYDirtyAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMaxYDirty)
    )
    this.mostAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMost)
    )

    this.layers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => {
        return layers.filter(
          l => !l.deleted && l.layer.meta_data.sage_layer_subtype !== 'actual'
        )
      })
    )

    this.aggLayers$ = this.store.pipe(
      select(fromSelectors.selectCededAggLayers)
    )

    this.portfolioSetID$ = this.store.pipe(
      select(fromSelectors.selectEditorPortfolioSetID)
    )

    this.currentCurrency$ = this.store.pipe(
      select(fromSelectors.selectCurrentCurrency)
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onPropertiesSave(
    value: SavePortfolioPropertiesEvent & {
      id: string
      preferences: StructureTowerPreferencesRequest[]
    }
  ): void {
    if (value.portfolioSetID) {
      this.store.dispatch(
        setAllProperties({
          programID: value.id,
          occ: value.occ,
          agg: value.agg,
        })
      )
    }

    this.store.dispatch(
      saveTowerPreferences({
        id: value.id,
        preferences: value.preferences,
      })
    )

    this.store.dispatch(
      setProgramLogAndSnapping({
        id: value.id,
        log: value.log,
        logMin: value.logMin,
        snapping: value.snapping,
      })
    )
  }
}
