<button mat-icon-button class="menu-icon" id="menu" (click)="navService.open()">
  <mat-icon class="menu">menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="name"
  [subtitle]="subtitle"
  (backClick)="onBackClick()"
></app-header-title>
<div class="header">
  <div class="sub-header" *ngIf="showQuickQuote">
    <h2>Quick Quote Panel</h2>
  </div>
  <div class="sub-header" *ngIf="showSubjectivity">
    <h2>Subjectivity</h2>
  </div>
  <div class="sub-header" *ngIf="showTrackingModule">
    <h2>Tracking Module</h2>
  </div>
  <div class="sub-header" *ngIf="showToggle">
    <h2>Term Selection Module</h2>
  </div>
  <div class="sub-header" *ngIf="showCustomCompareBuilder || showCustomCompare">
    <h2>Custom Comparison</h2>
  </div>
  <div
    class="sub-header textElement"
    *ngIf="showAssignedLines && !showSubjectivity"
  >
    <h2>Assigned Lines</h2>
  </div>
  <section class="actions">
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare &&
        isLayerSelected
      "
      appButton
      accent
      (click)="showCustomCompareClick.emit()"
      [disabled]="isLoading"
      tabindex="-1"
    >
      Custom Comparison
    </button>
    <button
      *ngIf="
        canCreateRiskRef &&
        hasFOT &&
        !showSubjectivity &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare
      "
      appButton
      accent
      tabindex="-1"
      (click)="createNewRiskRef()"
      [disabled]="isLoading"
    >
      + Risk Ref
    </button>
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare &&
        !isGroupSelected &&
        !isSLSelected
      "
      (click)="showQuickQuoteClick.emit()"
      appButton
      accent
      [disabled]="
        isLoading || (!isLayerSelected && !isGroupSelected && !isSLSelected)
      "
      tabindex="-1"
    >
      Quick Quote
    </button>
    <button
      *ngIf="
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare
      "
      (click)="onRiskCodeClick()"
      appButton
      accent
      [disabled]="
        isLoading ||
        (!isLayerSelected && !isGroupSelected && !isSLSelected) ||
        !currentProgramId
      "
      tabindex="-1"
    >
      Lloyd's Risk Codes
    </button>
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare &&
        renewedFromRisks?.length > 0
      "
      (click)="onLinkExpiringClick()"
      appButton
      accent
      [disabled]="
        isLoading || (!isLayerSelected && !isGroupSelected && !isSLSelected)
      "
      tabindex="-1"
      matTooltip="{{ expiringTooltip }}"
    >
      Link Expiring
    </button>
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare
      "
      (click)="toggleClick.emit()"
      appButton
      accent
      [disabled]="
        isLoading || (!isLayerSelected && !isGroupSelected && !isSLSelected)
      "
      tabindex="-1"
    >
      Term Selection
    </button>
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder
      "
      (click)="onExportClick()"
      appButton
      accent
      [disabled]="
        isLoading || (!isLayerSelected && !isGroupSelected && !isSLSelected)
      "
      tabindex="-1"
    >
      Export
    </button>
    <button
      *ngIf="
        !showSubjectivity &&
        !isExpanded &&
        !showTrackingModule &&
        !showToggle &&
        !showAssignedLines &&
        !showQuickQuote &&
        !showCustomCompareBuilder &&
        !showCustomCompare
      "
      (click)="trackingClick.emit()"
      appButton
      accent
      [disabled]="
        isLoading || (!isLayerSelected && !isGroupSelected && !isSLSelected)
      "
      tabindex="-1"
    >
      Tracking Module
    </button>
    <button
      *ngIf="
        (showSubjectivity ||
          showTrackingModule ||
          showToggle ||
          showAssignedLines ||
          showQuickQuote ||
          showCustomCompareBuilder ||
          showCustomCompare) &&
        !isExpanded
      "
      (click)="onBackButtonClick()"
      appButton
      accent
      tabindex="-1"
    >
      Back
    </button>
    <button
      *ngIf="
        showSubjectivity ||
        showAssignedLines ||
        showQuickQuote ||
        showCustomCompareBuilder
      "
      (click)="onSaveButtonClick()"
      [disabled]="saveButtonDisabled"
      appButton
      accent
      border
      tabindex="-1"
    >
      {{ saveButtonText }}
    </button>
    <button
      appButton
      accent
      border
      tabindex="-1"
      [disabled]="compareView.dirty"
      matTooltip="Save Comparison Before Viewing"
      [matTooltipDisabled]="!compareView.dirty"
      *ngIf="compareView && showCustomCompareBuilder"
      (click)="viewCustomCompareClick.emit()"
    >
      View
    </button>
    <div class="sub-header" *ngIf="isExpanded">
      <h2 class="expanded-text">You are in the expanded view.</h2>
    </div>
    <button
      *ngIf="isExpanded && showSubjectivity"
      (click)="onBackExpandSubjClick()"
      appButton
      accent
      tabindex="-1"
    >
      Back to expanded view
    </button>
    <button
      *ngIf="isExpanded && !showSubjectivity"
      (click)="onBackExpand()"
      appButton
      accent
      tabindex="-1"
    >
      Back to quote page
    </button>
  </section>
</div>
<app-contact-support-icon-container></app-contact-support-icon-container>
