import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromContactSupport from './store/contact-support.reducer'
import { ContactSupportEffects } from './store/contact-support.effects'
import { ContactSupportService } from '../api/contact-support/contact-support.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MaterialModule } from '@shared/material.module'
import { SharedModule } from '@shared/shared.module'

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MaterialModule,
    SharedModule,
    StoreModule.forFeature(
      fromContactSupport.FEATURE_KEY,
      fromContactSupport.reducer
    ),
    EffectsModule.forFeature([ContactSupportEffects]),
  ],
  providers: [ContactSupportService],
})
export class ContactSupportModule {}
