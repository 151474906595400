import { Selection } from 'd3-selection'
import { resolveValueFn, ValueFn } from '@shared/util/value-fn'

export interface CircleSegmentOptions {
  index: number
  count: number
  radius: number
  offset?: number
  x?: number
  y?: number
  width?: number
}

export const decoratePointCircleSegment = <T>(
  sizeFn: ValueFn<number, T>,
  segmentIndexFn?: ValueFn<number, T>,
  segmentCountFn?: ValueFn<number, T>,
  segmentOffsetFn?: ValueFn<number, T>,
  sel?: Selection<SVGGElement, T, SVGGElement, any>
): void => {
  sel
    ?.select('path')
    .attr('fill', (d, i) => {
      const count = resolveValueFn(segmentCountFn, d, i) ?? 1
      return count >= 2 ? 'transparent' : 'inherit'
    })
    .attr('stroke', (d, i) => {
      const count = resolveValueFn(segmentCountFn, d, i) ?? 1
      return count >= 2 ? 'transparent' : 'inherit'
    })

  sel
    ?.enter()
    .append('path')
    .attr('d', (d, i) => {
      const index = resolveValueFn(segmentIndexFn, d, i) ?? 0
      const count = resolveValueFn(segmentCountFn, d, i) ?? 1
      const offset = resolveValueFn(segmentOffsetFn, d, i) ?? -90
      const size = resolveValueFn(sizeFn, d, i)
      const radius = Math.sqrt(size / Math.PI)

      if (count < 2) {
        // No segmentation needed, use default as it was
        return sel.select('path').attr('d')
      }

      return circleSegmentPath({ index, count, radius, offset })
    })
    .attr('fill', (d, i) => {
      const count = resolveValueFn(segmentCountFn, d, i) ?? 1
      return count < 2 ? 'transparent' : 'rgba(var(--rgb), var(--alpha))'
    })
    .attr('stroke', (d, i) => {
      const count = resolveValueFn(segmentCountFn, d, i) ?? 1
      return count < 2 ? 'transparent' : 'var(--bg)'
    })
}

export const circleSegmentPath = (options: CircleSegmentOptions): string => {
  const { index, count, radius } = options
  const { offset = 0, x = 0, y = 0, width = radius } = options

  const degrees = 360 / count
  const start = degrees * index + offset
  const end = degrees * (index + 1) + offset
  return _circleSegmentPath(x, y, radius, radius - width, start, end)
}

const _circleSegmentPath = (
  x: number,
  y: number,
  r0: number,
  r1: number,
  d0: number,
  d1: number
): string => {
  // https://svgwg.org/specs/paths/#PathDataEllipticalArcCommands
  const arc = Math.abs(d0 - d1) > 180 ? 1 : 0
  const point = (radius: number, degree: number) =>
    _polarToCartesian(x, y, radius, degree)
      .map(n => n.toPrecision(5))
      .join(',')
  return [
    `M${point(r0, d0)}`,
    `A${r0},${r0},0,${arc},1,${point(r0, d1)}`,
    `L${point(r1, d1)}`,
    `A${r1},${r1},0,${arc},0,${point(r1, d0)}`,
    'Z',
  ].join('')
}

const _polarToCartesian = (
  x: number,
  y: number,
  r: number,
  degrees: number
): readonly [number, number] => {
  const radians = (degrees * Math.PI) / 180.0
  return [x + r * Math.cos(radians), y + r * Math.sin(radians)]
}
