import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { append, assoc, dissoc, lensPath, set, view } from 'ramda'
import { md5 } from '../../../../shared/util/hash'
import * as fromGrouperActions from '../grouper.actions'
import { ProgramGroup, UNTITLED_GROUP_LABEL } from '../program-group.model'
import * as fromProgramGroupScenarioActions from './program-group-scenarios.actions'
import * as fromActions from './program-group.actions'

interface ExtendedState {
  minimized: Record<string, boolean>
}

export interface ProgramGroupEntity {
  programGroup: ProgramGroup
  hash: string
  dirty: boolean
  new: boolean
  deleted: boolean
  untitled: boolean
}

export type ProgramGroupEntityState = EntityState<ProgramGroupEntity> &
  ExtendedState

export const adapter = createEntityAdapter<ProgramGroupEntity>({
  selectId: entity => entity.programGroup.id,
})

export const initialState: ProgramGroupEntityState =
  adapter.getInitialState<ExtendedState>({
    minimized: {},
  })

export const defaultEntity = {
  hash: '',
  dirty: false,
  new: false,
  deleted: false,
  untitled: false,
}

const createEntity = (
  programGroup: ProgramGroup,
  entity: Partial<ProgramGroupEntity> = {}
): ProgramGroupEntity => ({
  ...defaultEntity,
  ...entity,
  hash: md5(programGroup),
  programGroup,
})

export const { selectAll } = adapter.getSelectors()

export const createUntitledEntity = (partialID: string): ProgramGroupEntity =>
  createEntity(
    { id: `untitled_${partialID}`, label: UNTITLED_GROUP_LABEL, yearID: '' },
    {
      dirty: true,
      new: true,
      untitled: true,
    }
  )

export const deleteReducer = (
  state: ProgramGroupEntityState,
  { id }: { id: string }
): ProgramGroupEntityState => {
  const programGroup = selectByID(id, state)
  if (!programGroup) {
    console.error(`No program group found w/ ID ${id}`)
    return state
  }
  if (programGroup.new || programGroup.untitled) {
    return adapter.removeOne(id, state)
  }
  return adapter.updateOne(
    { id, changes: { deleted: true, dirty: true } },
    state
  )
}

export const updateReducer = (
  state: ProgramGroupEntityState,
  { id }: { id: string },
  changes: Partial<ProgramGroup>
): ProgramGroupEntityState => {
  let entity: ProgramGroupEntity | undefined
  entity = selectByID(id, state)
  if (!entity) {
    return state
  }
  const programGroup = {
    ...entity.programGroup,
    ...changes,
  }
  return adapter.updateOne(
    {
      id,
      changes: {
        dirty: true,
        hash: md5(programGroup),
        untitled: false,
        programGroup,
      },
    },
    state
  )
}

const programGroupReducer = createReducer(
  initialState,

  on(fromActions.addProgramGroupSuccess, (state, { programGroups }) =>
    adapter.addMany(
      programGroups.map(g => createEntity(g)),
      state
    )
  ),

  on(fromActions.removeProgramGroup, (state, { id }) =>
    adapter.removeOne(id, state)
  ),

  on(fromActions.addNewProgramGroup, (state, { label, yearID }) => {
    const programGroup = { id: label, label, yearID }
    return adapter.addOne(
      createEntity(programGroup, { new: true, dirty: true }),
      state
    )
  }),

  on(fromActions.setGrouperMinimizedProgramGroup, (state, { id, value }) => {
    const minimize = value != null ? value : !state.minimized[id]
    const minimized = minimize
      ? assoc(id, true, state.minimized)
      // @ts-ignore
      : dissoc<Record<string, boolean>>(id, state.minimized)
    return { ...state, minimized }
  }),

  on(fromActions.deleteProgramGroupSuccess, (state, { id }) =>
    deleteReducer(state, { id })
  ),

  on(fromActions.renameProgramGroup, (state, { id, newName }) => {
    return updateReducer(state, { id }, { label: newName })
  }),

  on(
    fromGrouperActions.saveGrouperSuccess,
    fromProgramGroupScenarioActions.saveProgramGroupScenariosSuccess,
    (state, { groups }) => {
      const { create, update, remove } = groups

      let nextState = create.reduce((acc, g) => {
        if (g.isScenario && g.parentScenarioID) {
          // Group scenario is added: add its ID to its parent's scenario IDs
          const id = g.parentScenarioID
          const parent = acc.entities[id]
          if (parent) {
            const prev = view(scenarioIDsLens, parent) as string[] | undefined
            const next = append(g.id, prev ?? [])
            const changes = set(scenarioIDsLens, next, parent)
            return adapter.updateOne({ id, changes }, acc)
          }
        }
        return acc
      }, state)

      nextState = remove.reduce((acc, idToRemove) => {
        const g = acc.entities[idToRemove]
        if (g && g.programGroup.isScenario && g.programGroup.parentScenarioID) {
          // Group scenario being removed: remove its ID from parent's scenarios
          const id = g.programGroup.parentScenarioID
          const parent = acc.entities[id]
          if (parent) {
            const prev = view(scenarioIDsLens, parent) as string[] | undefined
            const next = (prev ?? []).filter(it => it !== g.programGroup.id)
            const changes = set(scenarioIDsLens, next, parent)
            return adapter.updateOne({ id, changes }, acc)
          }
        }
        return acc
      }, nextState)

      const createEntities = create.map(g => createEntity(g))
      const updateEntities = update.map(u => ({
        ...u,
        changes: createEntity(u.changes as ProgramGroup),
      }))
      return adapter.removeMany(
        remove,
        adapter.updateMany(
          updateEntities,
          adapter.addMany(createEntities, nextState)
        )
      )
    }
  )
)

export function reducer(
  state: ProgramGroupEntityState | undefined,
  action: Action
) {
  return programGroupReducer(state, action)
}

// Selectors

const scenarioIDsLens = lensPath(['programGroup', 'scenarioIDs'])

export const selectByID = (
  id: string,
  state: ProgramGroupEntityState
): ProgramGroupEntity | undefined => state.entities[id]

export const selectDirtyNewProgramGroup = (state: ProgramGroupEntityState) =>
  selectAll(state).filter(pgm => pgm.dirty || pgm.new)
