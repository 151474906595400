import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { MetricValueType } from '../../../core/model/metric-value-type.model'

export const addColumnDefValues = (
  valueType: MetricValueType,
  minWidth: string,
  pinnable: boolean
): Partial<SortTableColumnDef> => {
  const numberFormat = valueType === 'percentage' ? '1.2-2' : undefined
  return {
    minWidth,
    valueType,
    numberFormat,
    pinnable,
  }
}

export const getReinstatementsString = (
  reinstatements: string | null
): string => {
  if (!reinstatements) {
    return ''
  }

  const reinstatementsSplit = reinstatements
    .split(',')
    .map(split => split.trim())

  const reinstatementOccurrences = reinstatementsSplit.reduce((map, value) => {
    const count = map.get(value) || 0
    map.set(value, count + 1)
    return map
  }, new Map<string, number>())

  const mappedReinstatements = Array.from(reinstatementOccurrences.entries())
    .map(([value, count]) => {
      const percentage = (parseFloat(value) * 100).toFixed(2)
      return `${count} @ ${percentage}%`
    })
    .filter(occurrence => !occurrence.endsWith('@ 0%'))

  return mappedReinstatements.join(', ')
}
