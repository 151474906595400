import { createAction, props } from '@ngrx/store'

export const togglePortfolioMetrics = createAction(
  '[Analysis] Toggle Portfolio Metrics'
)
export const toggleLayersPanel = createAction('[Analysis] Toggle Layer Panel')
export const togglePropertiesPanel = createAction(
  '[Analysis] Toggle Properties Panel'
)
export const toggleLossSetGroupEditor = createAction(
  '[Analysis] Toggle Loss Set Edit',
  props<{ openPanel: boolean }>()
)

export type AnalysisPanelsSection =
  | 'lossSets'
  | 'scenarios'
  | 'optimizations'
  | 'inurance'
  | 'layerTypes'

export interface SectionClass {
  hidden: boolean
  collapse: boolean
}

export interface AnalysisPanelsCollapseEvent {
  section: AnalysisPanelsSection
  collapse: boolean
}

export const setAnalysisPanelsSectionCollapse = createAction(
  '[Analysis] Set Panels Section Collapse',
  props<AnalysisPanelsCollapseEvent>()
)
