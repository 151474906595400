import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { LAYER_PALETTE } from '../../model/layer-palette.model'
import { LossSetGroup } from '../../model/loss-set-layers.model'
import {
  LossSetGroupIDsChangeEvent,
  MAX_UNIQUE_LAYERS,
  OptimizationInputRange,
  OptimizationInputRangeChangeEvent,
  OptimizationRangesTypes,
  OptimizationInputCurrencyChangeEvent,
} from '../optimization.model'
import { CurrencyCode } from 'src/app/api/analyzere/analyzere.model'
import { FormControl } from '@angular/forms'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-ranges',
  templateUrl: `./optimization-ranges.component.html`,
  styleUrls: [`./optimization-ranges.component.scss`],
})
export class OptimizationRangesComponent implements OnInit {
  maxUniqueLayers = MAX_UNIQUE_LAYERS
  @Input() rangesTypes: OptimizationRangesTypes[]
  @Input() lossSetGroups: LossSetGroup[]
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  currencyControl = new FormControl()

  @Output() rangeChange = new EventEmitter<OptimizationInputRangeChangeEvent>()
  @Output() selectedLossSetGroupIDsChange =
    new EventEmitter<LossSetGroupIDsChangeEvent>()
  @Output() currencyChange =
    new EventEmitter<OptimizationInputCurrencyChangeEvent>()

  ngOnInit(): void {
    this.currencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
  }

  filterCurrencyValues(searchVal: any) {
    if (searchVal.code) {
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      return this.currencyList.filter(
        value => value.code.toLowerCase().indexOf(searchVal.toLowerCase()) === 0
      )
    }
  }
  onCurrencyChange(userInput: string) {
    this.filteredCurrencyList = this.currencyList.filter(currency =>
      currency.code.toLowerCase().startsWith(userInput.toLowerCase())
    )
  }
  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }

  trackByOption(index: number, range: OptimizationInputRange) {
    return range?.id ?? index
  }

  trackByRangesTypes(_index: number, range: OptimizationRangesTypes) {
    return range.id
  }

  get uniqueLayers() {
    let total = 0
    this.rangesTypes.forEach(rt => {
      const ranges = rt.ranges
      total +=
        ranges
          .filter(f => f.numberOfOptions > 0)
          .reduce((acc, curr) => acc * curr.numberOfOptions, 1) *
        (rt.lossSetGroupIDs.length || 1)
    })
    return total
  }

  get isOutOfRange() {
    return this.uniqueLayers > this.maxUniqueLayers
  }

  getLayerDescription(id: string) {
    const name = LAYER_PALETTE.find(l => l.id === id && !l.subtype)
    if (name) {
      return name.name
    } else {
      return ''
    }
  }

  compareFn(o1: string, o2: string) {
    return o1 === o2
  }

  getLabelClass(id: string) {
    return {
      [`app-palette-${id}`]: true,
      ['layer-type']: true,
    }
  }
}
