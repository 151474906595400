import { Numeric, ScaleBand, ScaleLinear, ScaleTime } from 'd3'

export const BASE_DIMENSIONS = ['x', 'y'] as const

export type BaseDimension = typeof BASE_DIMENSIONS[number]

export type Coord = Record<BaseDimension, number>

export type WithIndex<T> = T & { i: number }

export type WithOffsetIndex<T> = WithIndex<T> & {
  asOffset?: boolean
  isY2?: boolean
}

export type ScalarScale =
  | ScaleLinear<number, number>
  | ScaleTime<number, number>

export type GraphingScale = ScalarScale | ScaleBand<string | number | Numeric>

export type GraphingScaleType = 'linear' | 'time' | 'band'

export type RectMap<T> = Record<'top' | 'right' | 'bottom' | 'left', T>

export type Rect = RectMap<number>

export interface CoordExtentsOptions {
  enableRescale?: boolean
  ensureOrigin?: boolean
}

export class CoordExtents {
  private enableRescale: boolean
  private ensureOrigin: boolean
  private xs: number[]
  private ys: number[]

  xmin = Number.MAX_VALUE
  xmax = 0
  ymin = Number.MAX_VALUE
  ymax = 0

  paddingPercent: Rect

  get x(): readonly [number, number] {
    return this.getPaddedMinMax(this.xmin, this.xmax, this.ensureOrigin)
  }

  get y(): readonly [number, number] {
    return this.getPaddedMinMax(this.ymin, this.ymax, this.ensureOrigin)
  }

  constructor(
    paddingPercent: Rect | number = 10,
    opts: CoordExtentsOptions = {}
  ) {
    this.enableRescale = opts?.enableRescale ?? false
    this.ensureOrigin = opts?.ensureOrigin ?? true
    this.xs = []
    this.ys = []

    if (typeof paddingPercent === 'object') {
      this.paddingPercent = paddingPercent
    } else {
      this.paddingPercent = {
        top: paddingPercent,
        right: paddingPercent,
        bottom: paddingPercent,
        left: paddingPercent,
      }
    }
  }

  addCoord(coord: Coord): void {
    this.addX(coord.x)
    this.addY(coord.y)
  }

  addX(x: number): void {
    if (this.enableRescale) {
      this.xs.push(x)
    }

    if (x < this.xmin) {
      this.xmin = x
    }
    if (x > this.xmax) {
      this.xmax = x
    }
  }

  addY(y: number): void {
    if (this.enableRescale) {
      this.ys.push(y)
    }

    if (y < this.ymin) {
      this.ymin = y
    }
    if (y > this.ymax) {
      this.ymax = y
    }
  }

  rescale(xScalePercentile: number, yScalePercentile: number): void {
    const count = this.xs.length

    this.xs.sort((a, b) => a - b)
    const xPercentile = xScalePercentile / 100
    const xmin = this.xs[Math.floor(count * (1 - xPercentile))]
    const xmax = this.xs[Math.floor(count * xPercentile)]

    this.ys.sort((a, b) => a - b)
    const yPercentile = yScalePercentile / 100
    const ymin = this.ys[Math.floor(count * (1 - yPercentile))]
    const ymax = this.ys[Math.floor(count * yPercentile)]

    this.xmin = xmin
    this.xmax = xmax
    this.ymin = ymin
    this.ymax = ymax
  }

  finish(): void {
    this.xs = []
    this.ys = []
  }

  private getPaddedMinMax(
    min: number,
    max: number,
    ensureOrigin = true
  ): readonly [number, number] {
    // If all values are the same, use distance from origin as size
    const size = max - min === 0 ? Math.abs(max) : Math.abs(max - min)

    // Make sure 0 coord is within the extents if `ensureOrigin` true
    const _min = ensureOrigin ? Math.min(min, 0) : min
    const paddedMin =
      ensureOrigin && _min === 0
        ? _min
        : _min - (size * this.paddingPercent.bottom) / 100

    const _max = ensureOrigin ? Math.max(max, 0) : max
    const paddedMax =
      ensureOrigin && _max === 0
        ? _max
        : _max + (size * this.paddingPercent.top) / 100

    return [paddedMin, paddedMax] as const
  }
}

export function applyExtentLimit(
  extent: [number, number],
  extentLimit?: [number, number] | null
): [number, number] {
  if (extentLimit) {
    return [
      Math.max(extent[0], extentLimit[0]),
      Math.min(extent[1], extentLimit[1]),
    ]
  }
  return extent
}
