import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { AnalysisRoutingModule } from './analysis-routing.module'
import { AnimatedScenariosModule } from './animated-scenarios/animated-scenarios.module'
import { ExploreModule } from './explore/explore.module'
import { GroupCompareModule } from './group/group-compare.module'
import { PricingCurveModule } from '../pricingcurve/pricing-curve.module'
import { SharedModule } from '@shared/shared.module'
import { TowerModule } from './tower/tower.module'
import { TechnicalPremiumModule } from './technical-premium/technical-premium.module'

import { AddInuranceDialogContainerComponent } from './layers/add-inurance-dialog/add-inurance-dialog.container'
import { AddInuranceDialogComponent } from './layers/add-inurance-dialog/add-inurance-dialog.component'
import { AnalysisComponent } from './portfolio/portfolio.component/portfolio.component'
import { AutoGroupLossSetContainerComponent } from './layers/auto-group-loss-sets-container.component'
import { AutoGroupLossSetDialogComponent } from './layers/auto-group-loss-sets/auto-group-loss-sets.component'
import { CededPortfolioEffects } from './store/ceded-portfolio/portfolio.effects'
import { CompareMetricSettingsEffects } from './store/compare/compare-metric-settings/compare-metric-settings.effects'
import { CompareViewEffects } from './store/compare/compare-view/compare-view.effects'
import { CompareEffects } from './store/compare/compare.effects'
import { CompareProgramGroupEffects } from './store/compare/program-group-compare/program-group-compare.effects'
import { CurrentPortfolioContainerComponent } from './portfolio/current-portfolio.container/current-portfolio.container'
import { CustomIndexedDialogComponent } from './properties/layer/custom-indexed-dialog/custom-indexed-dialog.component'
import { InuranceLayersContainerComponent } from 'src/app/analysis/layers/inurance-layers-container.component'
import { InuranceLayersComponent } from 'src/app/analysis/layers/inurance-layers.component'
import { LayerPaletteItemContainerComponent } from '@shared/layer-palette-item.container'
import { LayerPanelLossSetGroupsComponent } from './layers/layer-panel-loss-set-groups.component'
import { LayerPanelScenariosOptimizationsComponent } from './layers/layer-panel-scenarios-optimizations.component'
import { LayerPanelComponent } from './layers/layer-panel/layer-panel.component'
import { LossSetLayerSelectorComponent } from './layers/loss-set-layer-selector/loss-set-layer-selector.component'
import { LossSetLayerContainerComponent } from './layers/loss-set-layer.container.component'
import { MultiSectionCardComponent } from './properties/layer/multi-section/multi-section-card.component'
import { MultiSectionDialogComponent } from './properties/layer/multi-section/multi-section-dialog.component'
import { MultiSectionComponent } from './properties/layer/multi-section/multi-section.component'
import { OptimizationModule } from './optimization/optimization.module'
import { PortfolioDetailsDialogContainerComponent } from './portfolio/portfolio-details-dialog.container/portfolio-details-dialog.container'
import { PortfolioExchangeDialogContainerComponent } from './portfolio/portfolio-exchange-dialog-container/portfolio-exchange-dialog.container'
import { PortfolioHeaderComponent } from './portfolio/portfolio-header/portfolio-header.component'
import { PortfolioMetricsComponent } from './portfolio/portfolio-metrics/portfolio-metrics.component'
import { PortfolioPropertiesDialogContainerComponent } from './portfolio/portfolio-properties-dialog.container/portfolio-properties-dialog.container'
import { PortfolioRouteContainerComponent } from './portfolio/portfolio-route.container/portfolio-route.container'
import { PortfolioTabsComponent } from './portfolio/portfolio-tabs/portfolio-tabs.component'
import { ScaleLossSetContainerComponent } from './layers/scale-loss-set-container.component'
import { ScaleLossSetDialogComponent } from './layers/scale-loss-set-dialog.component'
import { SubmitMarketDialogComponent } from './submit-to-market/submit-market-dialog.component'
import { TopDropDialogComponent } from './properties/layer/top-drop-dialog/top-drop-dialog.component'
import { TopDropDialogContainerComponent } from './properties/layer/top-drop-dialog/top-drop-dialog.container'
import { TopAndDropSectionCardComponent } from './properties/layer/top-drop-dialog/top-drop-section-card/top-drop-section-card.component'
import { TremorDialogComponent } from './submit-to-market/tremor-dialog.component'
import { ScaleLossSetDialogService } from './layers/scale-loss-set-dialog.service'
import { AutoGroupLossSetsDialogService } from './layers/auto-group-loss-sets/auto-group-loss-sets-dialog.service'
import { ScaleLossSetInputComponent } from './layers/scale-loss-set-input.component'
import { AnalysisEffects } from './store/analysis.effects'
import * as fromAnalysis from './store/analysis.reducer'
import { LayersEffects } from './store/ceded-layers/layers.effects'
import { GrouperViewEffects } from './store/grouper/grouper-view/grouper-view.effects'
import { GrouperEffects } from './store/grouper/grouper.effects'
import { GrouperInuranceEffects } from './store/grouper/inurance/grouper-inurance.effects'
import { GrouperProgramGroupMemberEffects } from './store/grouper/program-group-member/program-group-member.effects'
import { GrouperProgramGroupScenariosEffects } from './store/grouper/program-group/program-group-scenarios.effects'
import { GrouperProgramGroupEffects } from './store/grouper/program-group/program-group.effects'
import { GrouperProgramEffects } from './store/grouper/program/program.effects'
import { GrouperSharedLimitEffects } from './store/grouper/shared-limit/grouper-shared-limit.effects'
import { LossSetGroupEffects } from './store/loss-set-layers/loss-set-group/loss-set-group.effects'
import { LossSetLayersEffects } from 'src/app/analysis/store/loss-set-layers/loss-set-layers.effects'
import { CapitalMetricsEffects } from 'src/app/analysis/store/metrics/capital-metrics.effects'
import { EfficiencyMetricsEffects } from 'src/app/analysis/store/metrics/efficiency-metrics.effects'
import { LayersViewMetricsEffects } from './store/metrics/layers-metrics.effects'
import { CompareMetricTableEffects } from './store/metrics/metrics-cart/metrics-cart.effects'
import { MiscMetricsEffects } from './store/metrics/misc-metrics.effects'
import { PortfolioViewMetricsDetailEffects } from './store/metrics/portfolio-detail-metrics.effects'
import { PortfolioViewMetricsEffects } from 'src/app/analysis/store/metrics/portfolio-metrics.effects'
import { TailMetricsEffects } from 'src/app/analysis/store/metrics/tail-metrics.effects'
import { TechnicalPremiumEffects } from 'src/app/analysis/store/technical-premium/technical-premium.effects'
import { VolatilityMetricsEffects } from 'src/app/analysis/store/metrics/volatility-metrics.effects'
import { NetPortfolioEffects } from './store/net-portfolio/portfolio.effects'
import { PortfolioViewEffects } from './store/views/portfolio-view.effects'
import { CanDeactivateGuard } from 'src/app/guards/can-deactivate.guard'
import { RoleGuard } from 'src/app/guards/role-guard.service'
import { SageCurrencyPipe } from '@shared/pipes/sage-currency.pipe'
import { ReinsurersEffects } from '../quote/store/reinsurer/reinsurer.effects'
import { QuoteModule } from '../quote/quote.module'
import { RevenueModule } from '../revenue-power-bi/revenue.module'

@NgModule({
  declarations: [
    AddInuranceDialogContainerComponent,
    AddInuranceDialogComponent,
    AnalysisComponent,
    AutoGroupLossSetContainerComponent,
    AutoGroupLossSetDialogComponent,
    CurrentPortfolioContainerComponent,
    CustomIndexedDialogComponent,
    InuranceLayersComponent,
    InuranceLayersContainerComponent,
    LayerPaletteItemContainerComponent,
    LayerPanelComponent,
    LayerPanelLossSetGroupsComponent,
    LayerPanelScenariosOptimizationsComponent,
    LossSetLayerContainerComponent,
    LossSetLayerSelectorComponent,
    MultiSectionCardComponent,
    MultiSectionComponent,
    MultiSectionDialogComponent,
    PortfolioDetailsDialogContainerComponent,
    PortfolioExchangeDialogContainerComponent,
    PortfolioHeaderComponent,
    PortfolioMetricsComponent,
    PortfolioPropertiesDialogContainerComponent,
    PortfolioRouteContainerComponent,
    PortfolioTabsComponent,
    ScaleLossSetContainerComponent,
    ScaleLossSetDialogComponent,
    ScaleLossSetInputComponent,
    SubmitMarketDialogComponent,
    TopDropDialogComponent,
    TopDropDialogContainerComponent,
    TopAndDropSectionCardComponent,
    TremorDialogComponent,
  ],
  imports: [
    AnalysisRoutingModule,
    AnimatedScenariosModule,
    PricingCurveModule,
    RevenueModule,
    QuoteModule,
    EffectsModule.forFeature([
      AnalysisEffects,
      CapitalMetricsEffects,
      CededPortfolioEffects,
      CompareEffects,
      CompareMetricSettingsEffects,
      CompareMetricTableEffects,
      CompareProgramGroupEffects,
      CompareViewEffects,
      EfficiencyMetricsEffects,
      GrouperEffects,
      GrouperInuranceEffects,
      GrouperProgramEffects,
      GrouperProgramGroupEffects,
      GrouperProgramGroupMemberEffects,
      GrouperProgramGroupScenariosEffects,
      GrouperSharedLimitEffects,
      GrouperViewEffects,
      LayersEffects,
      LayersViewMetricsEffects,
      LossSetGroupEffects,
      LossSetLayersEffects,
      MiscMetricsEffects,
      NetPortfolioEffects,
      PortfolioViewEffects,
      PortfolioViewMetricsDetailEffects,
      PortfolioViewMetricsEffects,
      TailMetricsEffects,
      TechnicalPremiumEffects,
      VolatilityMetricsEffects,
      ReinsurersEffects,
    ]),
    ExploreModule,
    FormsModule,
    GroupCompareModule,
    TechnicalPremiumModule,
    HttpClientModule,
    OptimizationModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature(
      fromAnalysis.ANALYSIS_FEATURE_KEY,
      fromAnalysis.reducer
    ),
    TowerModule,
  ],
  providers: [
    CanDeactivateGuard,
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    RoleGuard,
    SageCurrencyPipe,
    ScaleLossSetDialogService,
    AutoGroupLossSetsDialogService,
    TranslateService,
  ],
})
export class AnalysisModule {}
