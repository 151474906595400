import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export const layerLossSetColDefs: SortTableColumnDef[] = [
  {
    id: 'name',
    minWidth: '12rem',
    label: 'Loss Set Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'lossScaleFactor',
    minWidth: '4rem',
    label: 'Loss Scale Factor',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: true,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'originalPremium',
    minWidth: '10rem',
    label: 'Original Premium',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'premiumScaleFactor',
    minWidth: '4rem',
    label: 'Premium Scale Factor',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: true,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'newPremium',
    minWidth: '10rem',
    label: 'New Premium Amount',
    valueType: 'currency',
    whenLoading: 'show',
    editable: true,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'newExpenseRatio',
    minWidth: '4rem',
    label: 'New Expense Ratio',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: true,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'ls_dim1',
    minWidth: '8rem',
    label: 'ls_dim1',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'ls_dim2',
    minWidth: '8rem',
    label: 'ls_dim2',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'description',
    minWidth: '12rem',
    label: 'Description',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'min_loss',
    minWidth: '8rem',
    label: 'Scale Min Loss',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'avg_annual_loss',
    minWidth: '8rem',
    label: 'Scale Avg Annual Loss',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'max_loss',
    minWidth: '8rem',
    label: 'Scale Max Loss',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'num_losses',
    minWidth: '8rem',
    label: 'Num Losses',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'non_zero_losses',
    minWidth: '8rem',
    label: 'Non Zero Losses',
    valueType: 'currency',
    whenLoading: 'show',
    editable: false,
    numberTransform: val => val,
    numberReverseTransform: val => val,
  },
  {
    id: 'map1',
    minWidth: '12rem',
    label: 'Mapping 1',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
    headerInput: true
  },
  {
    id: 'map2',
    minWidth: '12rem',
    label: 'Mapping 2',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
    headerInput: true
  },
  {
    id: 'map3',
    minWidth: '12rem',
    label: 'Mapping 3',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
    headerInput: true
  },
  {
    id: 'map4',
    minWidth: '12rem',
    label: 'Mapping 4',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
    headerInput: true
  },
  {
    id: 'map5',
    minWidth: '12rem',
    label: 'Mapping 5',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
    headerInput: true
  },
  {
    id: 'perspective',
    minWidth: '8rem',
    label: 'Perspective',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'program_name',
    minWidth: '8rem',
    label: 'Program Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'sage_layer_type',
    minWidth: '8rem',
    label: 'Sage Layer Type',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
]
