import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'

export interface NavItem {
  displayName: string
  disabled?: boolean
  iconName?: string
  route?: string
  home?: boolean
  children?: NavItem[]
}

@Component({
  selector: 'app-nav-list-item',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        outline: none;
        width: 255px;
      }

      .mat-mdc-list-item {
        height: 64px !important;
      }

      .mdc-list-item__content {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
      }

      .mat-mdc-list-item.active {
        background-color: var(--accent);
      }

      .mat-mdc-list-item:not(.active):hover > .mat-mdc-list-item:not(.expanded),
      .mat-mdc-list-item:not(.active):focus > .mat-mdc-list-item:not(.expanded) {
        background-color: var(--bg-2-lit) !important;
      }
      .app-theme-dark .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mdc-list-item__primary-text {
        margin: 0 !important;
        padding: 8px 0 8px 16px;
      }

      .mat-mdc-list-item:not(.active):hover button {
        color: white;
        background-color: var(--bg-3-lit);
      }

      .mat-mdc-list-item,
      .mat-mdc-list-item__content {
        display: flex;
        width: auto;
      }

      .mat-icon {
        padding-right: 8px;
      }

      .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: fit-content;
        transform: translateY(2px);
      }

      button {
        color: white;
        background: transparent;
        margin: 0 var(--inset-small);
        position: relative;
        top: 2px;
      }

      button:focus,
      button:hover {
        color: white;
        background-color: var(--bg-3-lit);
      }
    `,
  ],
  template: `
    <a
      mat-list-item
      [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
      (click)="itemSelect.emit(item)"
      [ngClass]="{ active: isActive(item), expanded: expanded }"
      *ngIf="!item.disabled"
    >
      <mat-icon>{{ item.iconName }}</mat-icon>
      <div class="name">
        {{ item.displayName }}
      </div>

      <span
        class="iconExpand"
        fxFlex
        *ngIf="item.children && item.children.length"
      >
        <span fxFlex></span>
        <button appButtonIcon big opaque>
          {{ headerIcon }}
        </button>
      </span>
    </a>
    <div *ngIf="expanded">
      <app-nav-list-item
        *ngFor="let child of item.children"
        [item]="child"
        [depth]="depth + 1"
        [expandedMap]="expandedMap"
        (itemSelect)="itemSelect.emit($event)"
      >
      </app-nav-list-item>
    </div>
  `,
})
export class NavListItemComponent {
  @Input() item: NavItem
  @Input() depth = 0
  @Input() expandedMap: Record<string, boolean> = {}

  @Output() itemSelect = new EventEmitter<NavItem>()

  @HostBinding('attr.aria-expanded') get isExpanded2() {
    return this.expanded
  }

  get key(): string {
    return this.item.route || this.item.displayName
  }

  get expanded(): boolean {
    const _expanded = this.expandedMap[this.key]
    return _expanded != null ? _expanded : this.isDescendentActive(this.item)
  }

  get headerIcon() {
    return this.expanded ? 'expand_less' : 'expand_more'
  }

  constructor(private router: Router) {}

  isActive(item: NavItem): boolean {
    return item.route != null && this.router.isActive(item.route, true)
  }

  isDescendentActive(item: NavItem): boolean {
    return (item.children || []).reduce(
      (_, child) => this.isActive(child) || this.isDescendentActive(child),
      false
    )
  }
}
