<section class="wrapper" *ngIf="form && pricingCurve">
  <form class="calc-form" [formGroup]="form">
    <div class="inputs">
      <div
        *ngFor="let predColumn of pricingCurve.activeCreditPredictionColumns"
      >
        <span>{{ predColumn.label }}</span>
        <div class="data-row">
          <mat-form-field class="constant-input" subscriptSizing="dynamic">
            <input
              matInput
              [formControlName]="predColumn.calcColumnName"
              (focus)="updatePercentSign(predColumn.calcColumnName)"
              (blur)="updatePercentSign(predColumn.calcColumnName)"
            />
          </mat-form-field>
          <div class="mult"><span>x</span></div>
          <mat-form-field class="user-input" subscriptSizing="dynamic">
            <input
              matInput
              [formControlName]="predColumn.calcColumnInputName"
              (focus)="updatePercentSign(predColumn.calcColumnInputName)"
              (blur)="updatePercentSign(predColumn.calcColumnInputName)"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="outputs">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label><span>Pricing Prediction</span></mat-label>
        <input matInput formControlName="pricingPrediction" />
      </mat-form-field>
    </div>
  </form>
</section>
