import { Action, createReducer, on } from '@ngrx/store'
import * as ThemeActions from './theme.actions'
import { initialState, State } from './theme.state.facade'

const themeReducer = createReducer(
  initialState,

  on(ThemeActions.toggleDarkTheme, state => ({
    ...state,
    dark: !state.dark,
  })),

  on(ThemeActions.toggleThemeChrome, state => ({
    ...state,
    chrome: !state.chrome,
  })),

  on(ThemeActions.toggleThemeLayerWireframe, state => ({
    ...state,
    layerWireframe: !state.layerWireframe,
  })),

  on(ThemeActions.toggleThemeUserCard, state => ({
    ...state,
    userCard: !state.userCard,
  })),

  on(ThemeActions.toggleThemeLogoColor, state => ({
    ...state,
    logoColor: !state.logoColor,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return themeReducer(state, action)
}
