import { StructureResults } from './credit-results.model'

export interface CreditMetricConfiguration {
  name: string
  carrierId: number
  weight: number // sum of weights of all metric settings
  metricSettings: CreditMetricSetting[]
}

export interface CreditMetricSetting {
  id?: number
  name: string // metric configuration name
  carrier_id: number // client id
  category: CreditMetricCategory
  metric: CreditMetric
  label: string
  type: CreditMetricValueType
  ordinal: number
  show: boolean // indicates if metric should be shown in compare module
  weight: number // indicates bubble size on chart in compare module
  formula?: string // to calculate custom metric
  scenario: string
  returnPeriod?: string
  mapping_scenario_id: number
}

export type CreditMetricCategorySettings = {
  ceded: CreditMetricCategorySetting
  net: CreditMetricCategorySetting
  custom: CreditMetricCategorySetting
}

export interface CreditMetricCategorySetting {
  configurationName: string
  carrierId: number
  category: CreditMetricCategory
  metricSettings: CreditMetricSetting[]
}

export type CreditMetricCategory = 'Ceded' | 'Net' | 'Custom'

export type CreditMetric = keyof StructureResults | 'custom'

export enum CreditMetricValueType {
  Currency = 'currency',
  Percentage = 'percentage',
}
export const CREDIT_METRIC_VALUE_TYPES = Object.values(CreditMetricValueType)

export type CreditCustomMetricOperation =
  | 'add'
  | 'subtract'
  | 'multiply'
  | 'divide'
  | 'leftParen'
  | 'rightParen'

export interface CreditMetricOption {
  metric: CreditMetric
  label: string
  type: CreditMetricValueType
}

export interface CreditScenarioOption {
  scenario: string
  scenarioId: string
}

export interface CreditMetricCategoryOption {
  category: CreditMetricCategory
  metricOptions: CreditMetricOption[]
}

export const METRIC_OPTIONS: CreditMetricOption[] = [
  {
    metric: 'expected_loss',
    label: 'Expected Loss',
    type: CreditMetricValueType.Currency,
  },
  {
    metric: 'expected_premium',
    label: 'Expected Premium',
    type: CreditMetricValueType.Currency,
  },
  {
    metric: 'pmiers_capital',
    label: 'PMIERS Capital',
    type: CreditMetricValueType.Currency,
  },
  { metric: 'margin', label: 'Margin', type: CreditMetricValueType.Currency },
  {
    metric: 'annual_return_on_pmiers',
    label: 'Annual Return On PMIERS',
    type: CreditMetricValueType.Percentage,
  },
  {
    metric: 'annual_premium',
    label: 'Annual Premium',
    type: CreditMetricValueType.Percentage,
  },
  {
    metric: 'loss_ratio',
    label: 'Loss Ratio',
    type: CreditMetricValueType.Percentage,
  },
]

export const METRIC_CATEGORY_OPTIONS: CreditMetricCategoryOption[] = [
  { category: 'Ceded', metricOptions: METRIC_OPTIONS },
  { category: 'Net', metricOptions: METRIC_OPTIONS },
]

export const DEFAULT_METRIC_CONFIGURATION_NAME = 'Unlabeled Configuration'
export const DEFAULT_METRIC_SETTINGS: CreditMetricSetting[] = [
  {
    category: 'Ceded',
    metric: 'expected_loss',
    label: 'Expected Loss - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 0,
    show: true,
    weight: 8,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'expected_premium',
    label: 'Expected Premium - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 1,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'pmiers_capital',
    label: 'PMIERS Capital - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 2,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'expense',
    label: 'Expense - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 3,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'margin',
    label: 'Margin - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 4,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'annual_return_on_pmiers',
    label: 'Annual Return On PMIERS - Milliman Baseline',
    type: CreditMetricValueType.Percentage,
    ordinal: 5,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Ceded',
    metric: 'loss_ratio',
    label: 'Loss Ratio - Milliman Baseline',
    type: CreditMetricValueType.Percentage,
    ordinal: 6,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'expected_loss',
    label: 'Expected Loss - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 0,
    show: true,
    weight: 8,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'expected_premium',
    label: 'Expected Premium - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 1,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'pmiers_capital',
    label: 'PMIERS Capital - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 2,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,

    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'expense',
    label: 'Expense - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 3,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'margin',
    label: 'Margin - Milliman Baseline',
    type: CreditMetricValueType.Currency,
    ordinal: 4,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'annual_return_on_pmiers',
    label: 'Annual Return On PMIERS - Milliman Baseline',
    type: CreditMetricValueType.Percentage,
    ordinal: 5,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
  {
    category: 'Net',
    metric: 'loss_ratio',
    label: 'Loss Ratio - Milliman Baseline',
    type: CreditMetricValueType.Percentage,
    ordinal: 6,
    show: true,
    weight: 7,
    formula: null,
    scenario: 'Milliman Baseline',
    returnPeriod: null,
    mapping_scenario_id: -1,
    carrier_id: -1,
    name: DEFAULT_METRIC_CONFIGURATION_NAME,
  },
]
