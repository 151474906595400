import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { BrokerRouteContainerComponent } from './broker-route.container/broker-route.container'
import {
  createAnalysisTierRouteMatcher,
  createMetricsTierRouteMatcher,
  createDigitalTierRouteMatcher,
  createTierRouteMatcher,
  createDisplayTierRouteMatcher,
  createReinsurersTierRouteMatcher,
  createSignatureTierRouteMatcher,
  createQuoteTierRouteMatcher,
  createSlipTemplatesTierRouteMatcher,
  createCreditTierRouteMatcher,
} from './broker-tier-route-matcher'

const routes: Routes = [
  { path: '', component: BrokerRouteContainerComponent },
  {
    path: 'select',
    component: BrokerRouteContainerComponent,
    data: { clientSelect: true },
  },
  {
    path: ':clientID/select',
    component: BrokerRouteContainerComponent,
    data: { clientSelect: true },
  },
  {
    matcher: createTierRouteMatcher,
    component: BrokerRouteContainerComponent,
  },
  {
    matcher: createSlipTemplatesTierRouteMatcher,
    loadChildren: () =>
      import('../slip-templates/slip-templates.module').then(
        m => m.SlipTemplatesModule
      ),
    data: { contextPath: ['sliptemplates'] },
  },
  {
    matcher: createAnalysisTierRouteMatcher,
    loadChildren: () =>
      import('../analysis/analysis.module').then(m => m.AnalysisModule),
  },
  {
    matcher: createCreditTierRouteMatcher,
    loadChildren: () =>
      import('../credit/credit.module').then(m => m.CreditModule),
  },
  {
    matcher: createMetricsTierRouteMatcher,
    loadChildren: () =>
      import('../metrics/metrics.module').then(m => m.MetricsModule),
  },
  {
    matcher: createReinsurersTierRouteMatcher,
    loadChildren: () =>
      import('../reinsurers/reinsurer.module').then(m => m.ReinsurerModule),
    data: { contextPath: ['reinsurers'] },
  },
  {
    matcher: createSignatureTierRouteMatcher,
    loadChildren: () =>
      import('../signature/signature.module').then(m => m.SignatureModule),
    data: { contextPath: ['signature'] },
  },
  {
    matcher: createQuoteTierRouteMatcher,
    loadChildren: () =>
      import('../quote/quote.module').then(m => m.QuoteModule),
    data: { contextPath: ['quote'] },
  },
  {
    matcher: createDigitalTierRouteMatcher,
    loadChildren: () =>
      import('../digital/digital.module').then(m => m.DigitalModule),
  },
  {
    matcher: createDisplayTierRouteMatcher,
    loadChildren: () =>
      import('../display/display.module').then(m => m.DisplayModule),
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrokerRoutingModule {}
