import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { filterTowerLayers, isLayerAggFeeder } from '../../model/layers.util'
import { Layer } from '../../model/layers.model'

export const filterLayers = (layerState: LayerState[]) =>
  layerState
    .filter(filterTowerLayers)
    .filter(
      ls =>
        !isLayerAggFeeder(ls.layer) && ls.layer.physicalLayer.limit.value > 0
    )

export const filterAndMapForClone = (layers: Layer[]) =>
  layers
    .filter(
      layer =>
        layer.meta_data.sage_layer_type !== 'shared_limit' &&
        layer.meta_data.sage_layer_type !== 'shared_limits'
    )
    .map(layer => {
      return {
        ...layer,
        // Remove sharedLayerID value on layers that were part of a Shared Limit.
        sharedLayerID: '',
        physicalLayer: {
          ...layer.physicalLayer,
          participation: layer.sharedLayerID
            ? layer.physicalLayer.participation * -1
            : layer.physicalLayer.participation,
        },
        meta_data: {
          ...layer.meta_data,
          inuranceSource: false,
          inuranceSourceFor: '',
          structureID: '',
        },
      }
    })
