import { BlobResponse } from '../../api/model/backend.model'

export const getBlobResponses = (
  blobSAS: BlobResponse[]
): {
  thumbnail: BlobResponse
  carrier: BlobResponse
  reinsurer: BlobResponse
  market: BlobResponse
} => {
  const carrier = blobSAS.find(b => b.container_name === 'carrier-images')
  if (!carrier) {
    throw Error('No Blob SAS found with continer name "carrier-images"')
  }
  const thumbnail = blobSAS.find(b => b.container_name === 'thumbnails')
  if (!thumbnail) {
    throw Error('No Blob SAS found with continer name "carrier-images"')
  }
  const reinsurer = blobSAS.find(b => b.container_name === 'reinsurer-images')
  if (!reinsurer) {
    throw Error('No Blob SAS found with continer name "reinsurer-images"')
  }

  const market = blobSAS.find(b => b.container_name === 'submit-to-market')
  if (!market) {
    throw Error('No Blob SAS found with continer name "submit-to-market"')
  }
  return { carrier, thumbnail, reinsurer, market }
}
