<div>
  <span>
    Filter Opportunities&nbsp;
    <button appButton (click)="clearSearchFilters()">
      <span>Clear</span>
    </button> </span
  >
  <mat-form-field>
    <mat-label>Account Name</mat-label>
    <input
      type="text"
      matInput
      [formControl]="accountNameControl"
      [matAutocomplete]="accountName"
    />
    <mat-autocomplete #accountName="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredAccountNames | async"
        [value]="option"
        (onSelectionChange)="updateSelectionFilter($event, 'accountName')"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opportunity Name</mat-label>
    <input
      type="text"
      matInput
      [formControl]="opportunityNameControl"
      [matAutocomplete]="opportunityName"
      (keyup)="onNameKeyUp(opportunityNameControl.value)"
    />
    <mat-autocomplete #opportunityName="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOpportunityNames | async"
        [value]="option"
        (onSelectionChange)="updateSelectionFilter($event, 'opportunityName')"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opportunity Stage</mat-label>
    <input
      type="text"
      matInput
      [formControl]="opportunityStageControl"
      [matAutocomplete]="opportunityStage"
      (keyup)="onStageKeyUp(opportunityStageControl.value)"
    />
    <mat-autocomplete #opportunityStage="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOpportunityStages | async"
        [value]="option"
        (onSelectionChange)="updateSelectionFilter($event, 'opportunityStage')"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Business Unit</mat-label>
    <input
      type="text"
      matInput
      [formControl]="businessUnitControl"
      [matAutocomplete]="opportunityBusinessUnit"
      (keyup)="onBUKeyUp(businessUnitControl.value)"
    />
    <mat-autocomplete #opportunityBusinessUnit="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredBusinessUnits | async"
        [value]="option"
        (onSelectionChange)="updateSelectionFilter($event, 'opportunityBU')"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opportunity Class</mat-label>
    <input
      type="text"
      matInput
      [formControl]="opportunityClassControl"
      [matAutocomplete]="opportunityClass"
      (keyup)="onClassKeyUp(opportunityClassControl.value)"
    />
    <mat-autocomplete #opportunityClass="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredClasses | async"
        [value]="option"
        (onSelectionChange)="updateSelectionFilter($event, 'opportunityClass')"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opportunity Sub-Class</mat-label>
    <input
      type="text"
      matInput
      [formControl]="opportunitySubClassControl"
      [matAutocomplete]="opportunitySubClass"
      (keyup)="onSubClassKeyUp(opportunitySubClassControl.value)"
    />
    <mat-autocomplete #opportunitySubClass="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredSubClasses | async"
        [value]="option"
        (onSelectionChange)="
          updateSelectionFilter($event, 'opportunitySubClass')
        "
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opportunity Risk Type</mat-label>
    <input
      type="text"
      matInput
      [formControl]="riskTypeControl"
      [matAutocomplete]="opportunityRiskType"
      (keyup)="onRiskTypeKeyUp(riskTypeControl.value)"
    />
    <mat-autocomplete #opportunityRiskType="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOpportunityRiskTypes | async"
        [value]="option"
        (onSelectionChange)="
          updateSelectionFilter($event, 'opportunityRiskType')
        "
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
