<button mat-menu-item disableRipple [matMenuTriggerFor]="clientMenu">
  <app-selected-client-container
    *ngIf="selectedClient"
    [selectedClient]="selectedClient"
    [name]="selectedClient.name"
  ></app-selected-client-container>
  <div class="placeholder" *ngIf="!selectedClient">
    <span>{{ placeholder }}</span>
    <div *ngIf="!selectedClient" class="dropdown-arrow"></div>
  </div>
</button>
<mat-menu
  #clientMenu="matMenu"
  class="app-menu-borderless app-menu-no-max-width"
  backdropClass="app-tier-select-backdrop"
>
  <app-client-grid
    class="client-grid"
    [clients]="clients"
    (clientClick)="clientChange.emit($event)"
  ></app-client-grid>
</mat-menu>
