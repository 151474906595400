import {
  CreditGrossLossScenario,
  CreditPortfolio,
  ScenarioType,
} from './../../credit/model/credit-portfolio.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export const CREDIT_ADMIN_PERMISSION = 'Credit Security'

export enum CreditAdminTab {
  Portfolios = 'Portfolios',
  Structures = 'Structures',
}

export const CREDIT_ADMIN_TABS: CreditAdminTab[] = [
  CreditAdminTab.Portfolios,
  CreditAdminTab.Structures,
]

export enum CreditAdminPortfolioDialogTab {
  PortfolioDetails = 'Portfolio Details',
  GrossLossScenarios = 'Gross Loss Scenarios',
}

export const CREDIT_ADMIN_PORTFOLIO_DIALOG_TABS: CreditAdminPortfolioDialogTab[] =
  [
    CreditAdminPortfolioDialogTab.PortfolioDetails,
    CreditAdminPortfolioDialogTab.GrossLossScenarios,
  ]

export enum CreditFileType {
  PortfolioData = 'portfolio',
  ActualGrossLossScenarioData = 'actuals_gls', // for actual
  GrossLossScenarioData = 'gls', // for origination
  ActualLoanGrossLossScenarioData = 'actuals_loan', // for actual
  LoanGrossLossScenarioData = 'loan', // for origination
  PAttachData = 'pattach', // for default gross loss scenario
  StochasticData = 'stochastic', // for simulated scenarios
}

export interface CreditCededFileProps {
  structureId: string
  transactionId: string
  scenarioMappingId: string
  trancheId?: string
}

export interface CreditPortfolioUploadResults {
  message: CreditPortfolioUploadMessage[]
}

export interface CreditPortfolioUploadMessage {
  file_size: number
  href: string
  return_period: number
  filename: string
  model_scenario_mapping_id?: number
}

const CREDIT_PORTFOLIO_KEYS: Record<string, keyof CreditPortfolio> = {
  Id: '_id',
  Name: 'name',
  AssetClass: 'asset_class',
  DataType: 'data_type',
  ExposureBase: 'exposure_base',
  TotalExposure: 'total_exposure',
  ExpenseRatio: 'expense_ratio',
  StartBusinessCoveredDate: 'start_business_covered_date',
  EndBusinessCoveredDate: 'end_business_covered_date',
} as const

enum CreditPortfolioLabel {
  Id = 'ID',
  Name = 'Name',
  AssetClass = 'Asset Class',
  DataType = 'Data Type',
  ExposureBase = 'Exposure Base',
  TotalExposure = 'Total Exposure',
  ExpenseRatio = 'Expense Ratio',
  StartBusinessCoveredDate = 'Start Business Covered Date',
  EndBusinessCoveredDate = 'End Business Covered Date',
}

export const CREDIT_ADMIN_PORTFOLIO_COLUMNS: SortTableColumnDef[] = [
  {
    id: CREDIT_PORTFOLIO_KEYS.Id,
    label: CreditPortfolioLabel.Id,
    valueType: 'text',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.Name,
    label: CreditPortfolioLabel.Name,
    valueType: 'text',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.AssetClass,
    label: CreditPortfolioLabel.AssetClass,
    valueType: 'text',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.DataType,
    label: CreditPortfolioLabel.DataType,
    valueType: 'text',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.ExposureBase,
    label: CreditPortfolioLabel.ExposureBase,
    valueType: 'text',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.TotalExposure,
    label: CreditPortfolioLabel.TotalExposure,
    valueType: 'short-numeric',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.ExpenseRatio,
    label: CreditPortfolioLabel.ExpenseRatio,
    valueType: 'percentage',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.StartBusinessCoveredDate,
    label: CreditPortfolioLabel.StartBusinessCoveredDate,
    valueType: 'date',
  },
  {
    id: CREDIT_PORTFOLIO_KEYS.EndBusinessCoveredDate,
    label: CreditPortfolioLabel.EndBusinessCoveredDate,
    valueType: 'date',
  },
]

export const TEMP_PORTFOLIO_ID = 'TEMP_PORTFOLIO_ID'

export const DEFAULT_PORTFOLIO: CreditPortfolio = {
  _id: TEMP_PORTFOLIO_ID,
  name: 'Unlabled Portfolio',
  metadata: {},
  asset_class: 'residential mortgage',
  data_type: 'MI',
  exposure_base: 'RIF',
  total_exposure: 13329668418,
  expense_ratio: 0.23,
  portfolio_data: '',
  start_business_covered_date: '2024-01-01T00:00:00',
  end_business_covered_date: '2024-12-31T00:00:00',
  modified_date: '',
  gross_loss_scenarios: [],
  actuals: [],
  portfolio_results: {},
}

export const DEFAULT_GROSS_LOSS_SCENARIO: CreditGrossLossScenario = {
  name: 'Milliman Unlabled',
  metadata: {},
  scenario: 'Milliman Unlabeled',
  model: 'Milliman Simulations',
  gross_loss_scenario_data: '',
  loan_gross_loss_scenario_data: '',
  pattach_data: '',
  scenario_type: ScenarioType.Deterministic,
  stochastic_data: null,
  actuals: [],
  is_default: false,
  modified_date: '',
}
