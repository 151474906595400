import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ApplicationError } from "src/app/error/model/error";
import { CreditIssueFot, CreditQuoteAuditTable, CreditQuoteOverviewRow } from "../../model/credit-quote.model";

export const CreditQuoteAction = createActionGroup({
  source: 'Credit Results',
  events: {
    'Fetch Quote Audit': props<{ trancheId: string }>(),
    'Fetch Quote Audit Success': props<{ results: CreditQuoteAuditTable }>(),
    'Fetch Quote Audit Failure': props<{ error: ApplicationError }>(),
    'Issue FOT': props<{ data: CreditIssueFot }>(),
    'Issue FOT Success': props<{ data: CreditIssueFot }>(),
    'Issue FOT Failure': props<{ error: ApplicationError }>(),
    'Fetch Quote Overview': props<{ trancheId: string }>(),
    'Fetch Quote Overview Success': props<{ results: CreditQuoteOverviewRow[] }>(),
    'Fetch Quote Overview Failure': props<{ error: ApplicationError }>(),
  },
})
