import {
  OnInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core'

import * as powerbi from 'powerbi-client'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { PowerbiService } from '../../shared/power-bi/powerbi.service'
import { ReportCodes } from 'src/app/api/model/pbi.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],
})
export class RevenueComponent implements OnInit, OnDestroy {
  name = 'Revenue Dashboard'
  private tokenSubscription: Subscription

  private report: powerbi.Embed | null = null

  constructor(private router: Router, private powerbiService: PowerbiService) {}

  onBackClick(): void {
    this.router.navigate(['/home'])
  }

  ngOnInit(): void {
    // Embed the report and start listening for token updates
    this.powerbiService.initiate(ReportCodes.RevDashboard)
    this.tokenSubscription = this.powerbiService.accessTokenData.subscribe(() => {
      this.powerbiService.embedReport('embedContainer', 'overlay', this.report)
    })
  }

  ngOnDestroy(): void {
    // Cleanup event handlers when the component is destroyed
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe()
    }
  }
}
