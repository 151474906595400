<div>
  <h1 mat-dialog-title *ngIf="canDelete()">
    Are you sure you want to delete "{{ data.label }}" group?
  </h1>
  <h1 mat-dialog-title *ngIf="!data.allowDelete">
    You have unsaved changes on this page. Please save it first and then
    delete.
  </h1>
  <h1 mat-dialog-title *ngIf="data.isChild">
    Unable to delete Subgroup as it is part of a Parent Group.
  </h1>
  <h1 mat-dialog-title *ngIf="data.hasSLI">
    This Group contains Inurance and Shared Limits. Please delete Inurance
    or Shared Limit before deleting Group.
  </h1>
  <div mat-dialog-content></div>
  <div mat-dialog-actions *ngIf="canDelete()">
    <button appButton accent (click)="newGroupClick()">
      <span>Delete</span>
    </button>
    <button appButton link mat-dialog-close (click)="cancel()">
      <span>Cancel</span>
    </button>
  </div>
  <div mat-dialog-actions *ngIf="!canDelete()">
    <button appButton accent mat-dialog-close (click)="cancel()">
      <span>Cancel</span>
    </button>
  </div>
</div>
