import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tag',
  styles: [
    `
           :host {
             display: inline;
             font-size: var(--font-size-small);
             color: var(--accent);
             background: var(--bg-accent);
             border-radius: var(--border-radius);
             padding: 0px 5px 3px;
             margin-left: var(--inset-small);
           }
         `,
  ],
  template: `
    <ng-content></ng-content>
  `,
})
export class TagComponent {}
