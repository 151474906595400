<app-theme-container>
  <app-reinsurer
    [clientID]="clientID$ | async"
    [client]="client$ | async"
    [yearID]="yearID$ | async"
    [studyID]="studyId$ | async"
    [studies]="studies$ | async"
    [studySelected]="studySelected$ | async"
    [savingReinsurer]="savingReinsurer$ | async"
    [reinsurers]="reinsurers$ | async"
    [metricDetails]="categories$ | async"
    [reinsurersBlob]="reinsurersBlob$ | async"
    [reinsurerFilters]="reinsurerFilters$ | async"
    [reinsurerDomicileList]="reinsurerDomicileList$ | async"
    [reinsurerSPRatingList]="reinsurerSPRatingList$ | async"
    [reinsurerAmBestRatingList]="reinsurerAmBestRatingList$ | async"
    [reinsurerDirty]="reinsurerDirty$ | async"
    [lossFilters]="currentLossFilters$ | async"
    [selectedProgramID]="selectedProgramID$ | async"
    [programs]="programs$ | async"
    [accountOpportunities]="accountOpportunities$ | async"
    [clients]="clients$ | async"
    [reinsurerPopulateFrom]="reinsurerPopulateFrom$ | async"
    [selectedUserPreferences] ="selectedUserPreferences$ | async"
    [userPreferencesColumnsList]="userPreferencesColumnsList$ | async"
    [defaultUSAUserPreferences]="defaultUSAUserPreferences$ | async"
    (collapseToggle)="onCollapseToggle($event)"
    (saveClick)="onSaveClick()"
    (setReinsurer)="onSetReinsurer($event)"
    (showInfo)="onShowInfo($event)"
    (updateOrAddDirty)="onUpdateOrAddDirty($event)"
    (reinsurerFilterToggle)="onReinsurerFilterToggle($event)"
    (removeAllFilters)="onRemoveAllFilters()"
    (removeFilter)="onRemoveFilter($event)"
    (populateSelectorClick)="onPopulateSelectorClick($event)"
    (setOneReinsurer)="onSetOneReinsurer($event)"
    (exportClick)="onExportClick($event)"
    (showAgencyModal)="onShowAgencyModal($event)"
    (showFundManagerModal)="onShowFundManagerModal($event)"
    (deleteRe)="onDeleteRe($event)"
  ></app-reinsurer>
</app-theme-container>
