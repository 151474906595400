import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { CreditTransaction } from '../../model/credit-structure.model'
import { CreditLayerPaletteItem } from '../../model/credit-layer.model'

export const CreditTransactionActions = createActionGroup({
  source: 'Credit Transaction',
  events: {
    Add: props<{
      layerItem: CreditLayerPaletteItem
      transaction: Partial<CreditTransaction>
    }>(),
    'Add Success': props<{
      transaction: CreditTransaction
      transactions: CreditTransaction[]
    }>(),
    Update: props<{ transaction: CreditTransaction }>(),
    'Update Success': props<{
      transaction: CreditTransaction
      transactions: CreditTransaction[]
    }>(),
    Delete: props<{ transactionId: string }>(),
    'Delete Success': props<{
      transactionId: string
      transactions: CreditTransaction[]
    }>(),
    Select: props<{ transactionId: string }>(),
    'Select Success': props<{ transaction: CreditTransaction }>(),
    'Select By Tranche': props<{ trancheId: string }>(),
    'Select By Tranche Success': props<{ transaction: CreditTransaction }>(),
  },
})
