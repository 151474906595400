import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Dictionary } from 'ramda'
import { Program } from '../../../core/model/program.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../store/grouper/program-group.model'
import {
  DeleteInuranceFromDesign,
  InuranceLevel,
  InuranceReference,
  InuranceRelationship,
  InuranceView,
} from '../../model/inurance.model'
import {
  createLayerInuranceView,
  createStructureInuranceView,
} from '../../model/inurance.util'
import { ProgramEntity } from '../../store/grouper/program/program.reducer'
import { Layer } from '../../model/layers.model'
import { SharedLimitMode } from '../../store/grouper/shared-limit/grouper-shared-limit.reducer'
import { FHCFWarningDialogComponent } from '../../group/components/fhcf-warning-dialog/fhcf-warning-dialog.component'
import {
  findMainLayerOfSectionLayer,
  findMultiSelectVisibleLayer,
  findRiskActualLayer,
  isLayerTopOrDrop,
} from '../../model/layers.util'
import { Client } from 'src/app/core/model/client.model'
import { isSwingLayer } from '../swing-layer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-inurance-component',
  styleUrls: ['./add-inurance-dialog.component.scss'],
  templateUrl: './add-inurance-dialog.component.html',
})
export class AddInuranceDialogComponent implements OnInit {
  addTitle = 'Add New'
  editTitle = 'Swap'
  categories: string[] = ['Layers', 'Structures', 'Groups']
  filteredStructures: Program[]
  filteredGroups: ProgramGroup[]
  sourceStructures: Program[]
  sourceGroups: ProgramGroup[]
  targetStructures: Program[]
  targetGroups: ProgramGroup[]
  sourceViewLayers: Layer[]
  targetViewLayers: Layer[]
  swapViewLayers: Layer[]
  waiting: boolean

  sourceSelectedCategory: string
  swapSelectedCategory: string
  targetSelectedCategory: string

  currentSource = 'No source selected'
  currentSwap = 'No swap selected'
  currentTarget = 'No target selected'

  leftShowLayers = false
  leftShowStructures = false
  leftShowGroups = false

  midShowLayers = false
  midShowStructures = false
  midShowGroups = false

  rightShowLayers = false
  rightShowStructures = false
  rightShowGroups = false

  sourceLayer: Layer | null
  sourceLayersFromStructure: Program | null
  sourceStructure: Program | null
  sourceGroup: ProgramGroup | null

  swapLayer: Layer | null
  swapLayersFromStructure: Program | null
  swapStructure: Program | null
  swapGroup: ProgramGroup | null

  targetLayer: Layer | null
  targetLayersFromStructure: Program | null
  targetStructure: Program | null
  targetGroup: ProgramGroup | null

  sourceView: InuranceView | null
  swapView: InuranceView | null
  targetView: InuranceView | null

  sharedLayers: string[] = []

  @Input() isAdd: boolean
  @Input() isEdit: boolean
  @Input() editLayer: Layer
  @Input() editStructure: Program
  @Input() editGroup: ProgramGroup
  @Input() editRelationship: InuranceRelationship
  @Input() fromMultiSection = false

  @Input() groups: ProgramGroup[]
  @Input() members: ProgramGroupMember[]
  @Input() structures: Program[]
  @Input() allStructures: Program[]

  _sourceLayersByStructure: Dictionary<Layer[]>
  @Input() set sourceLayersByStructure(
    sourceLayersByStructure: Dictionary<Layer[]>
  ) {
    this.filterSourceLayerByStructure(sourceLayersByStructure)
  }
  get sourceLayersByStructure(): Dictionary<Layer[]> {
    return this._sourceLayersByStructure
  }

  filterSourceLayerByStructure(sourceLayersByStructure: Dictionary<Layer[]>) {
    const dict: Dictionary<Layer[]> = {}
    for (const structureId of Object.keys(sourceLayersByStructure)) {
      dict[structureId] = sourceLayersByStructure[structureId]
        .filter(layer => {
          const layerSubtype = layer.meta_data.sage_layer_subtype
          const targetLayerSubtype =
            this.targetLayer?.meta_data.sage_layer_subtype
          const targetLayerId = this.targetLayer?.id ?? ''

          if (
            targetLayerSubtype === 'section-layer' &&
            layerSubtype === 'main-layer' &&
            layer.layerRefs.includes(targetLayerId)
          ) {
            return false
          }

          return (
            layerSubtype !== 'section-layer' && layerSubtype !== 'flipper-layer'
          )
        })
        .map(l => ({
          ...l,
          meta_data: {
            ...l.meta_data,
            layerName: this.getLayerName(l),
          },
        }))
    }
    this._sourceLayersByStructure = dict

    if (this.sourceStructure) {
      this.sourceView = this.createInuranceView(
        'source',
        undefined,
        this.sourceStructure
      )
    }

    if (this.sourceGroup) {
      this.sourceView = this.createInuranceView('source', this.sourceGroup)
    }

    if (this.sourceView) {
      this.newInurance.emit({ view: this.sourceView as InuranceView })
    }
  }

  _swapLayersByStructure: Dictionary<Layer[]>
  @Input() set swapLayersByStructure(
    swapLayersByStructure: Dictionary<Layer[]>
  ) {
    const dict: Dictionary<Layer[]> = {}
    for (const structureId of Object.keys(swapLayersByStructure)) {
      dict[structureId] = swapLayersByStructure[structureId]
        .filter(layer => layer.meta_data.sage_layer_subtype !== 'flipper-layer')
        .map(l => ({
          ...l,
          meta_data: {
            ...l.meta_data,
            layerName: this.getLayerName(l),
          },
        }))
    }

    this._swapLayersByStructure = dict

    if (this.swapStructure) {
      this.swapView = this.createInuranceView(
        'swap',
        undefined,
        this.swapStructure
      )
    }

    if (this.swapGroup) {
      this.swapView = this.createInuranceView('swap', this.swapGroup)
    }

    if (this.swapView) {
      this.newInurance.emit({ view: this.swapView as InuranceView })
    }
  }
  get swapLayersByStructure(): Dictionary<Layer[]> {
    return this._swapLayersByStructure
  }

  _targetLayersByStructure: Dictionary<Layer[]>
  @Input() set targetLayersByStructure(
    targetLayersByStructure: Dictionary<Layer[]>
  ) {
    const dict: Dictionary<Layer[]> = {}
    for (const structureId of Object.keys(targetLayersByStructure)) {
      dict[structureId] = targetLayersByStructure[structureId]
        .filter(layer => {
          if (
            this.isAdd &&
            !this.fromMultiSection &&
            layer.meta_data.sage_layer_subtype === 'section-layer'
          ) {
            return false
          }

          return layer.meta_data.sage_layer_subtype !== 'flipper-layer'
        })
        .map(l => ({
          ...l,
          meta_data: {
            ...l.meta_data,
            layerName: this.getLayerName(l),
          },
        }))
    }
    this._targetLayersByStructure = dict
    if (this.targetStructure) {
      this.targetView = this.createInuranceView(
        'target',
        undefined,
        this.targetStructure
      )
    }

    if (this.targetGroup) {
      this.targetView = this.createInuranceView('target', this.targetGroup)
    }

    if (this.targetView) {
      this.newInurance.emit({ view: this.targetView as InuranceView })
    }
  }
  get targetLayersByStructure(): Dictionary<Layer[]> {
    return this._targetLayersByStructure
  }

  @Input() sourceLayersByStructureIsLoading = false
  @Input() swapLayersByStructureIsLoading = false
  @Input() targetLayersByStructureIsLoading = false

  @Input() cededLayers: LayerState[]
  @Input() currentProgram: Program
  @Input() currentLayer: Layer
  @Input() dirty: boolean
  @Input() error: string | null = null
  @Input() programEntities: ProgramEntity[]
  @Input() saving: boolean
  @Input() saved: InuranceView[]
  @Input() sharedLimitLayers: Layer[]
  @Input() sharedLimitMode: SharedLimitMode
  @Input() client: Client | null

  @Output() saveClick = new EventEmitter<void>()
  @Output() hasSaved = new EventEmitter<{
    structure: Program
    layer: Layer
  }>()
  @Output() resetGrouper = new EventEmitter()
  @Output() addGroup = new EventEmitter<ProgramGroup>()
  @Output() newInurance = new EventEmitter<{
    view: InuranceView
    layers?: Layer[]
  }>()
  @Output() swapInurance = new EventEmitter<DeleteInuranceFromDesign>()
  @Output() addProgram = new EventEmitter<{
    structure: Program
    layers: Layer[]
  }>()
  @Output() getLayersByStructure = new EventEmitter<{
    structure: Program | ProgramGroup
    type: string
  }>()
  @Output() openEditSharedLimit = new EventEmitter<{
    layer: Layer
    selectedLayer: string
    selectedProgram: string
  }>()
  @Output() sharedLimitToggle = new EventEmitter<{
    layerID: string
    entityID: string
    analysisID: string
    netPortfolioID: string
    cededPortfolioID: string
    netPortfolioLayersIDs: string[]
    cededLayers: LayerState[]
  }>()

  constructor(
    public dialogRef: MatDialogRef<AddInuranceDialogComponent>,
    public dialog: MatDialog
  ) {}

  get activeAction() {
    if (this.saving) {
      return 'Saving...'
    } else if (
      this.sourceLayersByStructureIsLoading ||
      this.swapLayersByStructureIsLoading ||
      this.targetLayersByStructureIsLoading
    ) {
      return 'Loading...'
    } else {
      return ''
    }
  }

  get saveText() {
    if (this.isEdit) {
      return 'Swap'
    } else {
      if (this.isSaved() && !this.error) {
        return 'Saved'
      } else {
        return 'Save'
      }
    }
  }

  getLayerName(layer: Layer, index?: number): string {
    if (!layer.meta_data.layerName) {
      return `Layer ${index ?? ''} - No name`
    }
    if (layer.meta_data.sage_layer_subtype === 'section-layer') {
      const layerName = layer.meta_data.layerName ?? ''
      const description = layer.physicalLayer.description ?? ''
      return `${layerName} - ${description}`
    }
    return layer.meta_data.layerName
  }

  ngOnInit(): void {
    const clientPrograms: string[] = []
    this.client?.clientYears.forEach(year => {
      if (year.id === this.currentProgram.yearID) {
        year.studies.forEach(study =>
          study.programIDs.forEach(id => clientPrograms.push(id.toString()))
        )
      }
    })

    this.filteredStructures = [
      this.currentProgram,
      ...this.allStructures.filter(
        structure =>
          structure.id !== this.currentProgram.id &&
          structure.analysisID === this.currentProgram.analysisID &&
          clientPrograms.includes(structure.id)
      ),
    ]
    this.populateStructures()

    this.filteredGroups = this.groups.filter(
      group => group.analysisProfileID === this.currentProgram.analysisID
    )
    this.populateGroups()

    if (this.isEdit) {
      this.autoPopulateSourceToSwap(
        this.editLayer,
        this.editStructure,
        this.editGroup,
        this.editRelationship
      )

      this.autoPopulateTargetForEdit(
        this.currentProgram,
        this.editGroup, // TODO: Current group
        this.editRelationship
      )
    } else {
      this.autoPopulateTarget()
    }
  }

  onLayersFromStructureSelectionChange(structure: Program, type: string): void {
    if (type === 'source') {
      if (
        !this.sourceLayersByStructure ||
        (this.sourceLayersByStructure &&
          !this.sourceLayersByStructure[structure.id])
      ) {
        this.getLayersByStructure.emit({ structure, type })
      }
    } else if (type === 'swap') {
      if (
        !this.swapLayersByStructure ||
        (this.swapLayersByStructure &&
          !this.swapLayersByStructure[structure.id])
      ) {
        this.getLayersByStructure.emit({ structure, type })
      }
    } else {
      if (
        !this.targetLayersByStructure ||
        (this.targetLayersByStructure &&
          !this.targetLayersByStructure[structure.id])
      ) {
        this.getLayersByStructure.emit({ structure, type })
      }
    }
  }

  onLayerSelectionChange(layer: Layer, type: string): void {
    // tslint:disable
    const structure: Program =
      type === 'source'
        ? this.sourceLayersFromStructure!
        : type === 'swap'
          ? this.swapLayersFromStructure!
          : this.targetLayersFromStructure!

    const entity: ProgramEntity = this.programEntities.find(
      pe => pe.program.id === structure.id
    )!
    // tslint:enable

    if (type === 'source') {
      this.sourceStructure = null
      this.sourceGroup = null
      this.currentSource = layer.meta_data.layerName
        ? `Layer - ${layer.meta_data.layerName}`
        : 'Layer - No layer name'
      if (layer.id === this.targetLayer?.id) {
        this.targetLayer = null
      }
    } else if (type === 'swap') {
      this.swapStructure = null
      this.swapGroup = null
      this.currentSwap = layer.meta_data.layerName
        ? `Layer - ${layer.meta_data.layerName}`
        : 'Layer - No layer name'
    } else {
      this.targetStructure = null
      this.targetGroup = null
      this.currentTarget = layer.meta_data.layerName
        ? `Layer - ${layer.meta_data.layerName}`
        : 'Layer - No layer name'
      if (layer.id === this.sourceLayer?.id) {
        this.sourceLayer = null
      }
    }
    this.populateGroups()
    this.populateStructures()
    this.onLayerSelection(type, entity, structure, layer)
  }

  onStructureSelectionChange(structure: Program, type: string): void {
    this.waiting = true
    this.getLayersByStructure.emit({ structure, type })

    if (type === 'source') {
      this.sourceLayer = null
      this.sourceGroup = null
      this.currentSource = structure.label
        ? 'Structure - ' + structure.label
        : 'Structure - No structure name'
      if (
        structure.id === this.targetStructure?.id ||
        structure.id === this.targetLayersFromStructure?.id
      ) {
        this.targetStructure = null
        this.targetLayersFromStructure = null
        this.targetLayer = null
      }
    } else if (type === 'swap') {
      this.swapLayer = null
      this.swapGroup = null
      this.currentSwap = structure.label
        ? 'Structure - ' + structure.label
        : 'Structure - No structure name'
    } else {
      this.targetLayer = null
      this.targetGroup = null
      this.currentTarget = structure.label
        ? 'Structure - ' + structure.label
        : 'Structure - No structure name'
      if (structure.id === this.sourceStructure?.id) {
        this.sourceStructure = null
      }
    }
    this.waiting = false
    this.populateGroups()
    this.populateStructures()
  }

  onGroupSelectionChange(group: ProgramGroup, type: string): void {
    this.waiting = true
    this.getAllStructures(group).map(structure => {
      this.getLayersByStructure.emit({ structure, type })
    })
    if (type === 'source') {
      this.sourceStructure = null
      this.sourceLayer = null
      this.currentSource = group.label
        ? 'Group - ' + group.label
        : 'Group - No group name'
      if (group.id === this.targetGroup?.id) {
        this.targetGroup = null
        this.targetLayer = null
      }
    } else if (type === 'swap') {
      this.swapStructure = null
      this.swapLayer = null
      this.currentSwap = group.label
        ? 'Group - ' + group.label
        : 'Group - No group name'
    } else {
      this.targetStructure = null
      this.targetLayer = null
      this.currentTarget = group.label
        ? 'Group - ' + group.label
        : 'Group - No group name'
      if (group.id === this.sourceGroup?.id) {
        this.sourceGroup = null
      }
    }
    this.waiting = false
    this.populateGroups()
    this.populateStructures()
  }

  createInuranceView(
    type: string,
    group?: ProgramGroup,
    structure?: Program
  ): InuranceView {
    let level: InuranceLevel = 'program'
    const references: InuranceReference[] = []
    const fromDesign = true
    // tslint:disable-next-line: no-non-null-assertion
    const toProcess: ProgramGroup | Program = group ? group : structure!

    if (structure) {
      const layers: Layer[] = (
        type === 'source'
          ? this.sourceLayersByStructure
          : type === 'swap'
            ? this.swapLayersByStructure
            : this.targetLayersByStructure
      )[structure.id]
      layers?.map(l => {
        references.push({ layerID: l.id, structureID: structure.id })
      })
      this.addProgram.emit({ structure, layers })
    }

    if (group) {
      level = 'programGroup'
      const structures: Program[] = this.getAllStructures(group)
      structures.map(struct => {
        const layers: Layer[] = (
          type === 'source'
            ? this.sourceLayersByStructure
            : type === 'swap'
              ? this.swapLayersByStructure
              : this.targetLayersByStructure
        )[struct.id]
        if (layers && layers.length > 0) {
          layers.map(l => {
            this.addProgram.emit({ structure: struct, layers })
            references.push({
              layerID: l.id,
              structureID: struct.id,
              structureGroupID: group.id,
            })
          })
        }
      })
      this.addGroup.emit(group)
    }

    const adjustedType = type === 'swap' ? 'source' : type
    const structureForRefresh = this.isEdit ? this.currentProgram : undefined
    const layerForRefresh = this.isEdit ? this.currentLayer : undefined
    return createStructureInuranceView(
      adjustedType,
      toProcess,
      undefined,
      fromDesign,
      level,
      references,
      layerForRefresh,
      structureForRefresh
    )
  }

  getAllStructures(group: ProgramGroup): Program[] {
    const membersAreStructures: ProgramGroupMember[] = []

    const membersAreGroups: ProgramGroupMember[] = this.members.filter(
      member =>
        member.parentGroupID === group.id && member.type === 'programGroup'
    )

    membersAreGroups.map(memberIsGroup => {
      this.members.map(member => {
        if (
          member.parentGroupID === memberIsGroup.programGroupID &&
          member.type === 'program'
        ) {
          membersAreStructures.push(member)
        }
      })
    })

    this.members.map(member => {
      if (member.parentGroupID === group.id && member.type === 'program') {
        membersAreStructures.push(member)
      }
    })

    return this.allStructures.filter(
      structure =>
        membersAreStructures
          .map(member => member.programID)
          .includes(structure.id) &&
        structure.analysisID === this.currentProgram.analysisID
    )
  }

  addExistingView(view: InuranceView, type: string): void {
    if (view) {
      if (
        view.levelFromDesign === 'program' ||
        view.levelFromDesign === 'programGroup'
      ) {
        this.newInurance.emit({ view })
      } else {
        if (view.levelFromDesign === 'layer') {
          this.newInurance.emit({
            view,
            layers:
              type === 'source'
                ? this.sourceViewLayers
                : this.targetViewLayers
                  ? this.targetViewLayers
                  : undefined,
          })
        }
      }
    }
  }

  onSaveClick(): void {
    if (this.isEdit) {
      const toDelete = this.createDeleteInurance()
      this.swapInurance.emit(toDelete)
    } else {
      if (this.sourceView) {
        this.addExistingView(this.sourceView, 'source')
      }

      if (this.targetView) {
        this.addExistingView(this.targetView, 'target')
      }
      this.saveClick.emit()
    }
  }

  isSaveValid(): boolean {
    if (
      (this.sourceGroup || this.sourceStructure || this.sourceLayer) &&
      (this.targetGroup || this.targetStructure || this.targetLayer) &&
      this.sourceView &&
      this.targetView &&
      !this.error &&
      !this.isSaved() &&
      !this.waiting
    ) {
      if (this.isEdit) {
        return !(
          (this.swapGroup || this.swapStructure || this.swapLayer) &&
          this.swapView
        )
      } else {
        return false
      }
    } else {
      return true
    }
  }

  isSaved(): boolean {
    if (this.sourceView && this.targetView) {
      return (
        this.saved.includes(this.sourceView) &&
        this.saved.includes(this.targetView)
      )
    }
    return false
  }

  resetSourceAndTarget(side: string): void {
    if (side === 'left') {
      this.sourceLayersFromStructure = null
      this.sourceStructure = null
      this.sourceLayer = null
      this.sourceGroup = null
    }
    if (side === 'mid') {
      this.swapLayersFromStructure = null
      this.swapStructure = null
      this.swapLayer = null
      this.swapGroup = null
    }
    if (side === 'right') {
      this.targetLayersFromStructure = null
      this.targetStructure = null
      this.targetLayer = null
      this.targetGroup = null
    }
    this.populateStructures()
    this.populateGroups()
  }

  onCategorySelection(category: string, side: string): void {
    switch (category) {
      case 'Layers':
        if (side === 'left') {
          this.leftShowGroups = false
          this.leftShowStructures = false
          this.leftShowLayers = true
          this.resetSourceAndTarget('left')
        } else if (side === 'mid') {
          this.midShowGroups = false
          this.midShowStructures = false
          this.midShowLayers = true
          this.resetSourceAndTarget('mid')
        } else {
          this.rightShowGroups = false
          this.rightShowStructures = false
          this.rightShowLayers = true
          this.resetSourceAndTarget('right')
        }
        break
      case 'Structures':
        if (side === 'left') {
          this.leftShowGroups = false
          this.leftShowStructures = true
          this.leftShowLayers = false
          this.resetSourceAndTarget('left')
        } else if (side === 'mid') {
          this.midShowGroups = false
          this.midShowStructures = true
          this.midShowLayers = false
          this.resetSourceAndTarget('mid')
        } else {
          this.rightShowGroups = false
          this.rightShowStructures = true
          this.rightShowLayers = false
          this.resetSourceAndTarget('right')
        }
        break
      case 'Groups':
        if (side === 'left') {
          this.leftShowGroups = true
          this.leftShowStructures = false
          this.leftShowLayers = false
          this.resetSourceAndTarget('left')
        } else if (side === 'mid') {
          this.midShowGroups = true
          this.midShowStructures = false
          this.midShowLayers = false
          this.resetSourceAndTarget('mid')
        } else {
          this.rightShowGroups = true
          this.rightShowStructures = false
          this.rightShowLayers = false
          this.resetSourceAndTarget('right')
        }
        break
    }
  }

  onDoneClick(): void {
    if (this.isSaved() && !this.error) {
      // If current layer is a section layer refresh with its visible layer.
      let selectedLayer = this.currentLayer
      if (this.currentLayer.meta_data.sage_layer_subtype === 'section-layer') {
        const layers = this.cededLayers.map(l => l.layer)
        const mainLayer = findMainLayerOfSectionLayer(layers, this.currentLayer)
        const visibleLayer = findMultiSelectVisibleLayer(layers, mainLayer)
        selectedLayer = visibleLayer ?? this.currentLayer
      }
      this.hasSaved.emit({
        structure: this.currentProgram,
        layer: selectedLayer,
      })
    }
    this.resetGrouper.emit()
  }

  createLayerInuranceReferences(
    layer: Layer,
    structure: Program
  ): InuranceReference[] {
    return [{ layerID: layer.id, structureID: structure.id }]
  }

  autoPopulateTarget(): void {
    this.targetSelectedCategory = 'Layers'
    this.rightShowLayers = true
    this.targetLayersFromStructure = this.currentProgram
    this.targetStructure = this.targetLayersFromStructure
    this.onLayersFromStructureSelectionChange(
      this.targetLayersFromStructure,
      'target'
    )

    this.targetLayer = this.targetLayersByStructure[
      this.targetLayersFromStructure.id
    ].find(
      layer =>
        layer.id === this.currentLayer.id ||
        (layer.meta_data.visible_layer_id &&
          layer.meta_data.visible_layer_id === this.currentLayer.id)
      // tslint:disable-next-line: no-non-null-assertion
    )!

    this.filterSourceLayerByStructure(this._sourceLayersByStructure)

    this.currentTarget = this.targetLayer.meta_data.layerName
      ? 'Layer - ' + this.targetLayer.meta_data.layerName
      : 'Layer - No layer name'

    this.populateGroups()
    this.populateStructures()
    setTimeout(
      // tslint:disable-next-line: no-non-null-assertion
      () => this.onLayerSelectionChange(this.targetLayer!, 'target'),
      2000
    )
  }

  autoPopulateTargetForEdit(
    structure: Program,
    group: ProgramGroup,
    relationship: InuranceRelationship
  ): void {
    switch (relationship) {
      case 'layerToLayer':
      case 'structureToLayer':
      case 'structureGroupToLayer': {
        this.targetSelectedCategory = 'Layers'
        this.rightShowLayers = true
        this.targetLayersFromStructure = this.currentProgram
        this.onLayersFromStructureSelectionChange(
          this.targetLayersFromStructure,
          'target'
        )

        this.targetLayer = this.targetLayersByStructure[
          this.targetLayersFromStructure.id
        ].find(
          l =>
            l.id === this.currentLayer.id ||
            (l.meta_data.visible_layer_id &&
              l.meta_data.visible_layer_id === this.currentLayer.id)
          // tslint:disable-next-line: no-non-null-assertion
        )!

        this.filterSourceLayerByStructure(this._sourceLayersByStructure)

        this.currentTarget = this.targetLayer.meta_data.layerName
          ? `Layer - ${this.targetLayer.meta_data.layerName}`
          : 'Layer - No layer name'

        setTimeout(
          // tslint:disable-next-line: no-non-null-assertion
          () => this.onLayerSelectionChange(this.targetLayer!, 'target'),
          2000
        )
        break
      }

      case 'layerToStructure':
      case 'structureToStructure':
      case 'structureGroupToStructure': {
        this.targetSelectedCategory = 'Structures'
        this.rightShowStructures = true
        this.targetStructure = structure
        this.currentTarget = this.targetStructure.label
          ? `Structure - ${this.targetStructure.label}`
          : 'Structure - No structure name'
        setTimeout(() => {
          this.onStructureSelectionChange(structure, 'target')
        }, 2000)
        break
      }

      // Probably not needed since we don't have a current group in design?
      case 'layerToStructureGroup':
      case 'structureToStructureGroup':
      case 'structureGroupToStructureGroup': {
        this.targetSelectedCategory = 'Groups'
        this.rightShowGroups = true
        this.targetGroup = group
        this.currentTarget = this.targetGroup.label
          ? `Group - ${this.targetGroup.label}`
          : 'Structure Group - No  structure group name'
        setTimeout(() => {
          this.onGroupSelectionChange(group, 'target')
        }, 2000)
        break
      }
    }
    this.populateGroups()
    this.populateStructures()
  }

  autoPopulateSourceToSwap(
    layer: Layer,
    structure: Program,
    group: ProgramGroup,
    relationship: InuranceRelationship
  ): void {
    switch (relationship) {
      case 'layerToLayer':
      case 'layerToStructure':
      case 'layerToStructureGroup': {
        this.sourceSelectedCategory = 'Layers'
        this.leftShowLayers = true
        this.sourceLayersFromStructure = structure
        this.onLayersFromStructureSelectionChange(
          this.sourceLayersFromStructure,
          'source'
        )
        this.sourceLayer = this.sourceLayersByStructure[structure.id].find(
          l => l.id === layer.id
          // tslint:disable-next-line: no-non-null-assertion
        )!
        this.currentSource = this.sourceLayer.meta_data.layerName
          ? `Layer - ${this.sourceLayer.meta_data.layerName}`
          : 'Layer - No layer name'
        this.onLayerSelectionChange(this.sourceLayer, 'source')
        break
      }
      case 'structureToStructure':
      case 'structureToLayer':
      case 'structureToStructureGroup': {
        this.sourceSelectedCategory = 'Structures'
        this.leftShowStructures = true
        this.sourceLayersFromStructure = structure
        this.sourceStructure = structure
        this.currentSource = structure.label
          ? `Structure - ${structure.label}`
          : 'Structure - No structure name'
        this.sourceView = this.createInuranceView(
          'source',
          undefined,
          structure
        )
        this.newInurance.emit({ view: this.sourceView })
        break
      }
      case 'structureGroupToLayer':
      case 'structureGroupToStructure':
      case 'structureGroupToStructureGroup': {
        this.sourceSelectedCategory = 'Groups'
        this.leftShowGroups = true
        this.currentSource = group.label
          ? 'Group - ' + group.label
          : 'Group - No group name'
        this.sourceGroup = group
        this.sourceView = this.createInuranceView('source', group)
        this.newInurance.emit({ view: this.sourceView })
        break
      }
    }
    this.populateGroups()
    this.populateStructures()
  }

  populateGroups(): void {
    this.sourceGroups = this.filteredGroups.filter(
      fs => !this.targetGroup || this.targetGroup.id !== fs.id
    )
    this.targetGroups = this.filteredGroups.filter(
      fs => !this.sourceGroup || this.sourceGroup.id !== fs.id
    )
  }

  populateStructures(): void {
    this.sourceStructures = this.filteredStructures.filter(
      fs =>
        !(
          this.targetLayersFromStructure &&
          this.targetLayersFromStructure.id === fs.id
        ) &&
        (!this.targetStructure || this.targetStructure.id !== fs.id)
    )
    this.targetStructures = this.filteredStructures.filter(
      fs => !this.sourceStructure || this.sourceStructure.id !== fs.id
    )
  }

  getCurrentRelationship(): InuranceRelationship {
    if (
      this.sourceSelectedCategory === 'Layers' &&
      this.targetSelectedCategory === 'Layers'
    ) {
      return 'layerToLayer'
    }

    if (
      this.sourceSelectedCategory === 'Layers' &&
      this.targetSelectedCategory === 'Structures'
    ) {
      return 'layerToStructure'
    }

    if (
      this.sourceSelectedCategory === 'Layers' &&
      this.targetSelectedCategory === 'Groups'
    ) {
      return 'layerToStructureGroup'
    }

    if (
      this.sourceSelectedCategory === 'Structures' &&
      this.targetSelectedCategory === 'Layers'
    ) {
      return 'structureToLayer'
    }

    if (
      this.sourceSelectedCategory === 'Structures' &&
      this.targetSelectedCategory === 'Structures'
    ) {
      return 'structureToStructure'
    }

    if (
      this.sourceSelectedCategory === 'Structures' &&
      this.targetSelectedCategory === 'Groups'
    ) {
      return 'structureToStructureGroup'
    }

    if (
      this.sourceSelectedCategory === 'Groups' &&
      this.targetSelectedCategory === 'Layers'
    ) {
      return 'structureGroupToLayer'
    }

    if (
      this.sourceSelectedCategory === 'Groups' &&
      this.targetSelectedCategory === 'Structures'
    ) {
      return 'structureGroupToStructure'
    }

    if (
      this.sourceSelectedCategory === 'Groups' &&
      this.targetSelectedCategory === 'Groups'
    ) {
      return 'structureGroupToStructureGroup'
    }

    // Default to please TS.
    return 'layerToLayer'
  }

  createDeleteInurance(): DeleteInuranceFromDesign {
    const targetLayer = this.targetLayer as Layer
    const structureOfTarget =
      this.targetLayersFromStructure ??
      this.targetStructure ??
      this.currentProgram
    const targetView = this.targetView as InuranceView
    const relationship = this.getCurrentRelationship()
    const sourceLayer = this.sourceLayer ?? undefined
    const sourceStructure = this.sourceLayersFromStructure ?? undefined
    const sourceGroup = this.sourceGroup ?? undefined
    const targetGroup = this.targetGroup ?? undefined
    return {
      relationship,
      sourceLayer,
      sourceStructure,
      sourceGroup,
      targetLayer,
      targetGroup,
      structureOfTarget,
      targetView,
    }
  }

  getStudyName(structure: Program): string {
    let label = ''
    this.client?.clientYears
      .find(year => year.id === structure.yearID)
      ?.studies.forEach(study => {
        if (study.programIDs.map(id => id.toString()).includes(structure.id)) {
          label = ' - ' + study.name
        }
      })
    return label
  }

  onLayerSelection(
    type: string,
    entity: ProgramEntity,
    structure: Program,
    layer: Layer
  ): { type: string } | undefined {
    let hiddenTD: LayerState | undefined
    let isLayer: Layer | undefined
    let layerID: string = layer.id
    const visibleID: string = layerID
    const fromDesign = true
    const levelFromDesign: InuranceLevel = 'layer'
    const referencesFromDesign: InuranceReference[] =
      this.createLayerInuranceReferences(layer, structure)

    if (layer?.meta_data.isRiskVisible) {
      const allLayers: Layer[] = entity.cededLayers.map(l => l.layer)
      const actualRiskLayer: Layer | undefined = findRiskActualLayer(
        allLayers,
        layer.id
      )
      isLayer = entity.cededLayers.find(
        l => l.layer.id === actualRiskLayer?.id
      )?.layer
      layerID = isLayer ? isLayer.id : layerID
    }

    if (isLayerTopOrDrop(layer)) {
      hiddenTD = entity.cededLayers.find(l =>
        l.layer.layerRefs.includes(layer.id || '')
      )
      layerID = hiddenTD?.layer.id ?? ''
    }

    if (
      layer.meta_data.sage_layer_type === 'cat_fhcf' &&
      this.sharedLimitMode !== 'none'
    ) {
      this.dialog.open(FHCFWarningDialogComponent, {
        width: '30vw',
      })
      return { type: 'No Action' }
    } else {
      if (
        (hiddenTD && hiddenTD.layer.sharedLayerID === '') ||
        layer.sharedLayerID === ''
      ) {
        if (this.sharedLimitMode === 'new') {
          if (hiddenTD) {
            if (
              hiddenTD.layer.layerRefs.every(ref =>
                this.sharedLayers.includes(ref)
              )
            ) {
              this.sharedLayers = this.sharedLayers.filter(
                e => !hiddenTD?.layer.layerRefs.includes(e)
              )
            } else {
              this.sharedLayers.push(...hiddenTD?.layer.layerRefs)
              this.sharedLayers = this.sharedLayers.slice()
            }
          } else {
            if (this.sharedLayers.includes(visibleID)) {
              this.sharedLayers = this.sharedLayers.filter(e => e !== visibleID)
            } else {
              this.sharedLayers.push(visibleID)
              this.sharedLayers = this.sharedLayers.slice()
            }
          }
          this.sharedLimitToggle.emit({
            layerID,
            entityID: entity.program.id,
            analysisID: entity.program.analysisID,
            cededLayers: entity.cededLayers,
            cededPortfolioID: entity.program.cededPortfolioID,
            netPortfolioLayersIDs: entity.netLayers,
            netPortfolioID: entity.program.netPortfolioID,
          })
        }
      }

      if (layer) {
        const layers: Layer[] = entity.cededLayers.map(le => le.layer)
        if (layer.meta_data.sage_layer_type) {
          if (
            ['noncat_indxl', 'cat_multisection'].includes(
              layer.meta_data.sage_layer_type
            ) &&
            layer.meta_data.sage_layer_subtype === 'visible-layer'
          ) {
            const mainLayer: Layer[] = layers.filter((d: Layer) => {
              if (d.meta_data.sage_layer_subtype === 'main-layer') {
                return d
              }
            })
            layer = mainLayer[0]
          }
          if (isSwingLayer(layer, 'visible-layer')) {
            const mainLayer: Layer[] = layers.filter((d: Layer) => {
              if (d.meta_data.sage_layer_subtype === 'combined-layer') {
                return d
              }
            })
            layer = mainLayer[0]
          }
        }

        const structureForRefresh = this.isEdit
          ? this.currentProgram
          : undefined
        const layerForRefresh = this.isEdit ? this.currentLayer : undefined

        const view: InuranceView = createLayerInuranceView(
          type === 'swap' ? 'source' : type,
          entity.program,
          layer,
          undefined,
          fromDesign,
          levelFromDesign,
          referencesFromDesign,
          layerForRefresh,
          structureForRefresh
        )

        if (type === 'source') {
          this.sourceView = view
          this.sourceViewLayers = layers
        } else if (type === 'swap') {
          this.swapView = view
          this.swapViewLayers = layers
        } else {
          this.targetView = view
          this.targetViewLayers = layers
        }
        this.newInurance.emit({ view, layers })
      }

      if (
        !this.dirty &&
        this.sharedLimitMode !== 'new' &&
        ((hiddenTD && hiddenTD.layer.sharedLayerID) || layer)
      ) {
        const sharedLimitLayer: Layer | undefined = this.sharedLimitLayers.find(
          l =>
            // tslint:disable-next-line: no-non-null-assertion
            l.layerRefs.includes((hiddenTD?.layer || layer)?.id || '')
        )
        if (sharedLimitLayer) {
          this.openEditSharedLimit.emit({
            layer: sharedLimitLayer,
            selectedLayer: (hiddenTD?.layer || layer)?.id || '',
            selectedProgram: entity.program.id,
          })
        }
      }
    }
  }
}
