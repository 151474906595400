<div class="title-container">
  <div>
    <h1 mat-dialog-title>{{ title }}</h1>
  </div>
</div>
<div mat-dialog-content class="content">
  <div class="parent-container column">
    <div class="header">
      <h3>{{ parentTitle }}</h3>
    </div>
    <ng-container *ngFor="let value of parentValues; let i = index">
      <li
        #parentItem
        (click)="onSelect(value, i, 'parent')"
        [ngClass]="getClass(value, 'parent')"
        [matTooltip]="value.viewValue"
      >
        <span class="name"> {{ value.viewValue }} </span>
      </li>
    </ng-container>
  </div>
  <div class="child-container column">
    <div class="header">
      <h3>{{ childTitle }}</h3>
    </div>
    <ng-container *ngFor="let value of childValues; let i = index">
      <li
        #childItem
        (click)="onSelect(value, i, 'child')"
        [ngClass]="getClass(value, 'child')"
        [matTooltip]="value.viewValue"
        *ngIf="value.id !== -1"
      >
        <span class="name"> {{ value.viewValue }} </span>
      </li>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions class="actions">
  <div
    class="button-tooltip-container"
    matTooltip="A subclass must be selected"
    [matTooltipDisabled]="isSubClassSelectionValid"
  >
    <button
      appButton
      border
      center
      accent
      tabindex="1"
      [disabled]="selectedParentId === undefined || !isSubClassSelectionValid"
      (click)="onOKClick()"
    >
      OK
    </button>
  </div>
  <button appButton link tabindex="2" [mat-dialog-close]="false">Cancel</button>
</div>
