<ng-container *ngIf="{ saving: data.saving$ | async } as ctx">
  <section
    [ngClass]="data.groupClass"
    class="group app-dialog-sidebar app-elevation-2"
  >
    <div class="card">
      <h3>Group Scenarios</h3>

      <h3 class="subtle">
        {{ data.group.label }}
      </h3>

      <div class="list-wrapper">
        <div class="list">
          <ng-container *ngFor="let it of existing; let i = index">
            <ng-container
              *ngTemplateOutlet="
                    groupScenarioTemplate;
                    context: { $implicit: it, listType: 'existing', index: i }
                  "
            ></ng-container>
          </ng-container>

          <ng-container *ngFor="let it of adding; let i = index">
            <ng-container
              *ngTemplateOutlet="
                    groupScenarioTemplate;
                    context: { $implicit: it, listType: 'adding', index: i }
                  "
            ></ng-container>
          </ng-container>

          <ng-template
            #groupScenarioTemplate
            let-item
            let-index="index"
            let-type="listType"
          >
            <button
              appButton
              stack
              primary
              [class.selected]="isSelected(type, index)"
              [accent]="isSelected(type, index)"
              [unclickable]="ctx.saving || isSelected(type, index)"
              (click)="!ctx.saving && setSelected(type, index)"
            >
              <div class="wrapper">
                    <span class="title">{{
                      index === 0 && type === 'existing'
                        ? 'Original'
                        : item.group.label
                      }}</span>

                <div
                  *ngIf="item.group.description"
                  class="description"
                  [matTooltip]="asTooltip(item.group.description)"
                  matTooltipShowDelay="500"
                >
                  {{ item.group.description }}
                </div>
              </div>

              <ng-template #rightTemplate>
                <mat-icon
                  *ngIf="index !== 0 || type !== 'existing'"
                  class="delete"
                  [class.disabled]="ctx.saving"
                  (click)="!ctx.saving && onDelete('existing', index)"
                >
                  cancel
                </mat-icon>
              </ng-template>
            </button>
          </ng-template>
        </div>

        <button
          appButton
          link
          [unclickable]="ctx.saving"
          [disabled]="ctx.saving"
          class="add"
          (click)="!ctx.saving && onAdd()"
        >
          <mat-icon class="prefix">add</mat-icon>
          <span>Add Group Scenario</span>
        </button>
      </div>

      <div class="actions">
        <ng-container *ngIf="!ctx.saving; else savingTemplate">
          <button
            appButton
            primary
            [disabled]="disableSave || ctx.saving"
            (click)="handleSave()"
          >
            Save
          </button>

          <button appButton link (click)="onCancel()">Cancel</button>
        </ng-container>

        <ng-template #savingTemplate>
          <app-active-action value="Saving" body></app-active-action>
        </ng-template>
      </div>
    </div>
  </section>

  <section class="structures app-dialog-body">
    <section
      *ngFor="
            let model of selectedStructureModels;
            let structureIndex = index
          "
      class="card structure"
    >
      <h3 class="subtle">{{ model.scenarios[0]?.label }}</h3>

      <div class="list">
        <button
          *ngFor="let it of model.scenarios; let i = index"
          appButton
          stack
          primary
          [class.selected]="model.index === i"
          [class.saving]="ctx.saving"
          [class.unclickable]="
                ctx.saving || !isNewGroupSelected || model.index === i
              "
          [accent]="model.index === i"
          [disabled]="
                ctx.saving || (!isNewGroupSelected && model.index !== i)
              "
          [unclickable]="
                ctx.saving || !isNewGroupSelected || model.index === i
              "
          (click)="
                !ctx.saving && onStructureScenarioClick(structureIndex, i)
              "
        >
          <div class="wrapper">
            <span>{{ i === 0 ? 'Original' : it.label }}</span>

            <div
              *ngIf="it.description"
              class="description"
              [matTooltip]="asTooltip(it.description)"
              matTooltipShowDelay="500"
            >
              {{ it.description }}
            </div>
          </div>
        </button>
      </div>
    </section>
  </section>

  <button
    appButtonIcon
    big
    class="close"
    [class.disabled]="ctx.saving"
    (click)="onCancel(ctx.saving)"
  >
    close
  </button>
</ng-container>
