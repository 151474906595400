import { ChangeDetectionStrategy, Component } from '@angular/core'
import { NavService } from '../nav.service'
import { Router } from '@angular/router'


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-under-construction',
  styles: [
    `
      .header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        box-sizing: border-box;
        padding: var(--stack) var(--inset-big) var(--stack) 0;
        height: var(--header-height);
        border-bottom: 1px solid var(--border);
      }

      .menu-icon {
        background-color: var(--bg);
        border-color: var(--bg);
        margin: var(--inset);
      }

      .menu {
        color: white;
      }

      a {
        flex: 0 0 auto;
      }

      a.logo {
        width: calc(
          var(--layer-panel-width) - var(--inset-big) - var(--inset-tiny)
        );
        margin-right: var(--inset-huge);
      }

      a.sidebar-toggle {
        color: var(--body);
        padding: 0.75rem 1rem;
        margin-right: var(--inset-big);
      }

      .title {
        flex: 0 1 auto;
        overflow: hidden;
      }

      .maintenance-container {
        text-align: center;
        background: linear-gradient(135deg, #00b3aa 0%, #4a8a87 100%);
        color: white;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        animation: fadeIn 1s ease;
      }

      h1 {
        font-size: 2.5em;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        line-height: 1.5;
      }
    `,
  ],
  template: `<app-theme-container>
    <div class="header">
      <button
        mat-icon-button
        class="menu-icon"
        id="menu"
        (click)="navService.open()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <a class="logo" routerLink="/home" appLogo></a>
      <app-header-title
        class="title"
        name=" "
        subtitle=" "
        (backClick)="onBackClick()"
      ></app-header-title>
    </div>
    <div class="maintenance-container">
      <div class="maintenance-content">
        <h1>🚧 We'll Be Back Soon!</h1>
        <p>
          Page you are trying to reach is currently undergoing maintenance.
          Please check back later.
        </p>
        <p>We appreciate your patience and understanding!</p>
      </div>
    </div>
  </app-theme-container>`,
})
export class MaintenanceComponent {
  constructor(
    public navService: NavService,
    private router: Router
  ) {}

  onBackClick(): void {
    this.router.navigate(['/home'])
  }
}
