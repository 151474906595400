<app-optimization-header
  [stepIndex]="stepIndex"
  [actionConfig]="actionStepConfig"
  [disableAction]="disableAction"
  (closeClick)="closeClick.emit()"
  (resetClick)="onResetClick()"
  (backClick)="onBackClick()"
  (actionClick)="onActionClick($event)"
  class="header"
></app-optimization-header>
<main class="main optimization">
  <mat-horizontal-stepper
    [linear]="true"
    [disableRipple]="true"
    (selectionChange)="onSelectionChanged($event)"
    #stepper
  >
    <mat-step>
      <ng-template matStepLabel>Select Ranges</ng-template>
      <div [style.height]="stepHeight" class="step-container">
        <div class="section-ranges">
          <app-optimization-ranges
            [rangesTypes]="rangesTypes"
            [lossSetGroups]="lossSetGroups"
            [currencyList]="currencyList"
            [filteredCurrencyList]="currencyList"
            (rangeChange)="rangeChange.emit($event)"
            (currencyChange)="currencyChange.emit($event)"
            (selectedLossSetGroupIDsChange)="
                  selectedLossSetGroupIDsChange.emit($event)
                "
          ></app-optimization-ranges>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Preview Candidates</ng-template>
      <div [style.height]="stepHeight" class="step-container">
        <app-optimization-candidates-results
          [rangesTypes]="rangesTypes"
          [candidateLayers]="candidateLayers"
          [candidateLayersLoading]="candidateLayersLoading"
          [error]="candidatesLayersError"
          [lossSetGroups]="lossSetGroups"
          (selectedChange)="onSelectedChange($event)"
          (valueChange)="candidateLayerChange.emit($event)"
        ></app-optimization-candidates-results>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Metrics</ng-template>
      <div [style.height]="stepHeight" class="step-container">
        <app-optimization-candidates-results-metrics
          [candidatesResults]="candidateResultsMetrics"
          [rangesTypes]="rangesTypes"
          [candidateLayersMetricsLoading]="candidateLayersMetricsLoading"
          [resultsView]="resultsView"
          [chartState]="chartState"
          [chartView]="chartView"
          [error]="metricsError"
          [lossSetGroups]="lossSetGroups"
          [lossFilters]="lossFilters"
          [portfolioTailMetrics]="portfolioTailMetrics"
          (valueChange)="onCandidateResultValueChange($event)"
          (viewChange)="viewChange.emit($event)"
          (dimensionChange)="dimensionChange.emit($event)"
          (checkboxChanges)="onCheckboxSelectionsChanges($event)"
          (portfolioReturnPeriodToggleChange)="
                portfolioReturnPeriodToggleChange.emit($event)
              "
        ></app-optimization-candidates-results-metrics>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div [style.height]="stepHeight" class="step-container">
        <app-optimization-summary
          [lastCreated]="lastCreated"
          [error]="savingError"
          [saving]="saving"
          [candidateLayers]="candidateLayers"
          [candidatesResults]="candidateResultsMetrics"
        ></app-optimization-summary>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</main>
