import { Dictionary } from '@ngrx/entity'
import { values } from 'ramda'
import { rejectNil } from '../../shared/util/operators'

const SYMBOLS = '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

function removeUsedSymbols(usedSymbols: string[]): string {
  return usedSymbols.reduce((acc, s) => acc.replace(s, ''), SYMBOLS)
}

export function getNextInuranceSymbol(symbolMap: Dictionary<string>): string {
  const usedSymbols = rejectNil(values(symbolMap))
  return removeUsedSymbols(usedSymbols)[0]
}

export function createInuranceSymbols(symbolMap: Dictionary<string>) {
  const usedSymbols = rejectNil(values(symbolMap))
  const availableSymbols = removeUsedSymbols(usedSymbols)
  const nextSymbolMap: Dictionary<string> = {}
  let count = 0
  return {
    next: (key: string) => {
      let symbol = symbolMap[key]
      if (!symbol) {
        symbol = availableSymbols[count % availableSymbols.length]
        count++
      }
      nextSymbolMap[key] = symbol
      return symbol
    },
    getMap: () => nextSymbolMap,
  }
}

export type InuranceSymbols = ReturnType<typeof createInuranceSymbols>
