import {
  Cube,
  Point,
  Tower3DOptions,
  translateCube,
  translatePoint,
  toRadians,
  Annotation,
  createAnnotation,
  Tower3DAngle,
  DEFAULT_HEIGHT,
  MIN_Z,
  MAX_Z,
  MIN_Y,
  MAX_Y,
  MIN_X,
  MAX_X,
  DEFAULT_SCALE,
  EVENT_DISTANCE,
  DEFAULT_START_ANGLE,
  getLimit,
  getAttachment,
} from './tower.3d.model'
import * as d3 from 'd3'
import { _3d } from 'd3-3d'
import { annotation } from 'd3-svg-annotation'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { StructureLayerDataResponse } from '../../../api/model/backend.model'
import { EventLayer } from '../animated-scenarios.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { SharedIDGroup } from '../../store/grouper/program-group.model'

export class Tower3D {
  private origin: number[]
  private cubesData: Cube[]
  private yLine: Point[]
  private xLine: Point[]
  private yAxisRotation = 0
  private svg: any
  private cubesGroup: any
  private cubes3D: any
  private yScale3d: any
  private xScale3d: any
  private grid3d: any
  private grid: Point[]
  private xGrid: any
  private annotations: Annotation[]
  private annotationSVG: any
  private yAxisLinearScale: d3.ScaleLinear<number, number>
  private xAxisLinearScale: d3.ScaleLinear<number, number>
  private currency: string
  private finalLossCubes: Array<any>

  private mx: number
  private my: number
  private mouseX: number
  private mouseY: number
  private alpha = 0
  private beta = 0
  private maxHeight: number

  private get scale(): number {
    return this.options.scale ?? DEFAULT_SCALE
  }

  private get startAngle(): Tower3DAngle {
    return this.options.startAngle || DEFAULT_START_ANGLE
  }

  private get defaultHeight(): number {
    return this.options.defaultHeight ?? DEFAULT_HEIGHT
  }

  private get minZ(): number {
    return this.options.minZ ?? MIN_Z
  }

  private get maxZ(): number {
    return this.options.maxZ ?? MAX_Z
  }

  private get minX(): number {
    return this.options.minX ?? MIN_X
  }

  private get maxX(): number {
    return this.options.maxX ?? MAX_X
  }

  private get minY(): number {
    return this.options.minY ?? MIN_Y
  }

  private get maxY(): number {
    return this.options.maxY ?? MAX_Y
  }

  private get eventDistance(): number {
    return this.options.eventDistance ?? EVENT_DISTANCE
  }

  constructor(private container: HTMLElement, private options: Tower3DOptions) {
    this.xAxisLinearScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([this.minX, this.maxX])
    this.init()
  }

  private dragStart(event: any) {
    this.mx = event.x
    this.my = event.y
  }

  private dragged(event: any) {
    this.mouseX = this.mouseX || 0
    this.mouseY = this.mouseY || 0
    this.beta = ((event.x - this.mx + this.mouseX) * Math.PI) / 230
    // this.alpha = (((d3.event.y - this.my + this.mouseY) * Math.PI) / 230) * -1
    this.alpha = 0
    this.render(0)
  }

  private dragEnd(event: any) {
    this.mouseX = event.x - this.mx + this.mouseX
    this.mouseY = event.y - this.my + this.mouseY
  }

  private init() {
    this.annotations = []
    d3.select(this.container).selectAll('*').remove()
    this.svg = d3
      .select(this.container)
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .call(
        d3
          .drag()
          .on('drag', this.dragged.bind(this))
          .on('start', this.dragStart.bind(this))
          .on('end', this.dragEnd.bind(this))
      )
      .append('g')
    const rect = this.container.getBoundingClientRect()
    this.origin = [
      0 + this.options.marginLeft,
      rect.height - this.options.marginBottom,
    ]
    this.cubes3D = _3d()
      .shape('CUBE')
      .x((d: Point) => {
        return d.x
      })
      .y((d: Point) => {
        return d.y
      })
      .z((d: Point) => {
        return d.z
      })
      .origin(this.origin)
      .scale(this.scale)

    this.yScale3d = _3d()
      .shape('LINE_STRIP')
      .origin(this.origin)
      .scale(this.scale)

    this.xScale3d = _3d()
      .shape('LINE_STRIP')
      .origin(this.origin)
      .scale(this.scale)

    this.grid3d = _3d()
      // .shape('GRID', this.maxX + 1)
      .shape('GRID', 2)
      .origin(this.origin)
      .scale(this.scale)
    this.xGrid = this.svg.append('g')
    this.cubesGroup = this.svg.append('g').attr('class', 'cubes')
    this.annotationSVG = this.svg
      .append('g')
      .attr('class', 'annotations-container')
      .style('font-size', this.getFontSize())
    this.yLine = []
    this.xLine = []
    d3.range(this.minY, this.maxY + 1, 1).forEach(d => {
      this.yLine.push({ x: 0, y: -d, z: this.minZ })
    })
    d3.range(this.minX, this.maxX + 1, 1).forEach(d => {
      this.xLine.push({ x: d, y: 0, z: this.maxZ })
    })
    this.grid = []
    // for (let z = this.minZ; z <= this.maxZ; z++) {
    //   for (let x = this.minX; x <= this.maxX; x++) {
    this.grid.push({ x: this.minX, y: 0, z: this.minZ })
    this.grid.push({ x: this.maxX, y: 0, z: this.minZ })
    this.grid.push({ x: this.minX, y: 0, z: this.maxZ })
    this.grid.push({ x: this.maxX, y: 0, z: this.maxZ })
    // this.grid.push({ x, y: 0, z })
    //   }
    // }
  }

  destroy() {
    d3.select(this.container).selectAll('*').remove()
  }

  updateOptions(options: Partial<Tower3DOptions>) {
    this.options = { ...this.options, ...options }
  }

  update() {
    d3.select(this.container).selectAll('*').remove()
    this.init()
    this.render(0)
  }

  resize(scale: number) {
    this.updateOptions({ scale })
    this.update()
  }

  setStartAngle(angle: Tower3DAngle) {
    this.updateOptions({ startAngle: angle })
  }

  setEventLayers(
    layers: EventLayer[],
    layersData: StructureLayerDataResponse[],
    eventIndex: number
  ) {
    if (layers.length === 0) {
      return
    }
    const layersWithLoss = layers.filter(
      l => l.loss > 0 && getLimit(l.layer).value > 0
    )
    // set the initial loss at limit 0 to transition into the full loss
    const initLossCubes = layersWithLoss.map(l => {
      const layerData = layersData.find(data =>
        data.layer_id.includes(l.layer.physicalLayer.id)
      )
      const startingCession = layerData?.starting_cession || 0
      const attachment = getAttachment(l.layer).value
      const participation = l.layer.physicalLayer.participation
      const cube = this.makeCube(
        l.layer.id + 'loss',
        this.xAxisLinearScale(startingCession),
        this.yAxisLinearScale.invert(attachment),
        this.maxZ - 1 - eventIndex * this.eventDistance,
        this.xAxisLinearScale(Math.abs(participation)),
        this.yAxisLinearScale.invert(0),
        1,
        l,
        []
      )
      return cube
    })
    this.cubesData.push(...initLossCubes)

    // Store final version of the loss cube to transition to
    this.finalLossCubes = layersWithLoss.map(l => {
      const layerData = layersData.find(data =>
        data.layer_id.includes(l.layer.physicalLayer.id)
      )
      const startingCession = layerData?.starting_cession || 0
      const attachment = getAttachment(l.layer).value
      const participation = l.layer.physicalLayer.participation
      // If the loss is over the maxHeight, scale the height of the loss rendering
      let height = l.loss
      if (height >= this.maxHeight) {
        height = this.maxHeight - attachment
      }
      const cube = this.makeCube(
        l.layer.id + 'loss',
        this.xAxisLinearScale(startingCession),
        this.yAxisLinearScale.invert(attachment),
        this.maxZ - 1 - eventIndex * this.eventDistance,
        this.xAxisLinearScale(Math.abs(participation)),
        this.yAxisLinearScale.invert(height - 1),
        1,
        l,
        []
      )
      return cube
    })

    this.cubesData.push(
      ...layers
        .filter(l => getLimit(l.layer).value > 0)
        .map(l => {
          const layerData = layersData.find(data =>
            data.layer_id.includes(l.layer.physicalLayer.id)
          )
          const startingCession = layerData?.starting_cession || 0
          const attachment = getAttachment(l.layer).value
          const participation = l.layer.physicalLayer.participation
          const limit = getLimit(l.layer).value
          const cube = this.makeCube(
            l.layer.id,
            this.xAxisLinearScale(startingCession),
            this.yAxisLinearScale.invert(attachment),
            this.maxZ - 1 - eventIndex * this.eventDistance,
            this.xAxisLinearScale(Math.abs(participation)),
            this.yAxisLinearScale.invert(
              limit >= this.maxHeight ? this.maxHeight - attachment : limit
            ),
            1,
            l,
            []
          )
          return cube
        })
    )
  }

  setInitLayers(
    layers: LayerState[],
    layersData: StructureLayerDataResponse[],
    sharedIDGroup: SharedIDGroup[]
  ) {
    if (layers.length === 0) {
      return
    }
    this.maxHeight = this.getMaxHeight(layers)

    this.yAxisLinearScale = d3
      .scaleLinear()
      .domain([this.minY, this.maxY])
      .range([0, this.maxHeight])

    this.cubesData = layers.map(l => {
      const layerData = layersData.find(data =>
        data.layer_id.includes(l.layer.physicalLayer.id)
      )
      const startingCession = layerData?.starting_cession || 0
      const attachment = getAttachment(l.layer).value
      const participation = l.layer.physicalLayer.participation
      const limit = getLimit(l.layer).value
      const cube = this.makeCube(
        l.layer.id,
        this.xAxisLinearScale(startingCession),
        this.yAxisLinearScale.invert(attachment),
        this.maxZ - 1,
        this.xAxisLinearScale(Math.abs(participation)),
        this.yAxisLinearScale.invert(
          limit >= this.maxHeight ? this.maxHeight - attachment : limit
        ),
        1,
        l,
        sharedIDGroup
      )
      return cube
    })
    this.currency = getLimit(layers[0].layer).currency
  }

  setYRotation(angle: number, duration: number = 0) {
    this.beta = 0
    d3.select(this.svg)
      .transition()
      .duration(duration)
      .tween(`rotation`, () => {
        const interpolate = d3.interpolateNumber(0, angle)
        return (t: number) => {
          this.yAxisRotation = toRadians(interpolate(t))
          this.render(0)
        }
      })
  }

  render(animationDuration?: number) {
    let processedCubeData = this.cubes3D
      .rotateY(this.startAngle.y + this.yAxisRotation + this.beta)
      .rotateX(-this.startAngle.x + this.alpha)(
      this.cubesData.map(translateCube)
    )
    const processedYLine = this.yScale3d
      .rotateY(this.startAngle.y + this.yAxisRotation + this.beta)
      .rotateX(-this.startAngle.x + this.alpha)([
      this.yLine.map(translatePoint),
    ])
    const processXLine = this.xScale3d
      .rotateY(this.startAngle.y + this.yAxisRotation + this.beta)
      .rotateX(-this.startAngle.x + this.alpha)([
      this.xLine.map(translatePoint),
    ])
    const processedGrid = this.grid3d
      .rotateY(this.startAngle.y + this.yAxisRotation + this.beta)
      .rotateX(-this.startAngle.x + this.alpha)(this.grid.map(translatePoint))

    this.renderGrid(processedGrid)
    this.renderAxis(processXLine, processedYLine)
    this.renderCubes(processedCubeData, animationDuration)
    this.cubesGroup.raise()
    this.annotationSVG.raise()
    if (this.finalLossCubes && this.finalLossCubes.length > 0) {
      const ids: string[] = this.finalLossCubes.map(f => f.id)
      this.cubesData = this.cubesData.filter(c => !ids.includes(c.id))
      this.cubesData.push(...this.finalLossCubes)
      processedCubeData = this.cubes3D
        .rotateY(this.startAngle.y + this.yAxisRotation + this.beta)
        .rotateX(-this.startAngle.x + this.alpha)(
        this.cubesData.map(translateCube)
      )
      this.renderCubes(processedCubeData, animationDuration)
      this.finalLossCubes = []
    }
  }

  private renderCubes(cubeData: any, animationDuration?: number) {
    const cubes = this.cubesGroup
      .selectAll('g.cube')
      .data(cubeData, (d: any) => {
        return d.id
      })
    const ce = cubes
      .enter()
      .append('g')
      .attr('class', (d: any) => {
        let mask = ''
        let layerClass = 'app-tower-layer'
        if (d.sharedIDGroup) {
          const sharedIDGroup = d.sharedIDGroup as SharedIDGroup[]
          const group = sharedIDGroup.find(g =>
            g.group.find(s => d.layerState.layer.id.includes(s))
          )
          if (group) {
            mask = `app-tower-shared-layer-group-${group.numberGroup}`
          }
        }
        if (!d.id.endsWith('loss') && d.layerState.loss !== undefined) {
          layerClass = 'app-tower-layer-3d-loss'
        }
        return `cube ${mask} ${layerClass} app-palette-${d.layerState.layer.meta_data.sage_layer_type}`
      })
      .style('stroke', (d: any) => {
        if (d.id.endsWith('loss')) {
          return 'none'
        }
        return 'white'
      })
      .style('fill', (d: any) => {
        if (d.id.endsWith('loss')) {
          return 'url(#loss-gradient)'
        }
      })
      .merge(cubes)
      .sort(this.cubes3D.sort)

    cubes.exit().remove()

    ce.on('mouseover', (_e: any, d: any) => {
      this.annotations = []
      const n = ce.nodes()
      const i = n.indexOf(this)
      d3.select(n[i])
        .style('stroke', () => {
          // tslint:disable-next-line: no-non-null-assertion
          return d3.color('yellow')!.brighter(2) + ''
        })
        .style('stroke-width', 2)
      this.annotations.push(
        createAnnotation(
          d,
          this.origin,
          this.scale,
          this.options.numberFormatter,
          this.container
        )
      )
      const makeAnnotation = annotation()
        .accessors({
          x: (data: Omit<Point, 'z'>) => data.x,
          y: (data: Omit<Point, 'z'>) => data.y,
        })
        .annotations(this.annotations)
      this.annotationSVG.call(makeAnnotation)
    }).on('mouseout', () => {
      const n = ce.nodes()
      const i = n.indexOf(this)
      d3.select(n[i])
        .style('stroke', () => {
          return 'white'
        })
        .style('stroke-width', 1)
      this.annotations = []
      this.annotationSVG.selectAll('*').remove()
    })

    const faces = ce.selectAll('path.face').data(
      (d: any) => {
        return d.faces
      },
      (d: any) => {
        return d.face
      }
    )

    faces
      .enter()
      .append('path')
      .attr('class', 'face')
      .attr('fill-opacity', 0.95)
      .classed('_3d', true)
      .merge(faces)
      .transition()
      .duration(animationDuration ?? this.options.cubeTransitionDuration)
      .attr('d', this.cubes3D.draw)

    faces.exit().remove()

    ce.selectAll('._3d').sort(_3d().sort)
  }

  private renderGrid(grid: any) {
    const gridPaths = this.xGrid.selectAll('path.grid').data(grid, (d: any) => {
      return d.plane
    })
    gridPaths
      .enter()
      .append('path')
      .attr('class', '_3d grid')
      .merge(gridPaths)
      .attr('stroke', 'black')
      .attr('stroke-width', 0.3)
      .attr('fill', (d: any) => {
        return d.ccw ? 'lightgrey' : '#717171'
      })
      .attr('fill-opacity', 0.35)
      .attr('d', this.grid3d.draw)

    gridPaths.exit().remove()
    this.xGrid.selectAll('._3d').sort(_3d().sort)
  }

  private renderAxis(xLine: any, yLine: any) {
    const xScale = this.svg.selectAll('path.xScale').data(xLine)
    xScale
      .enter()
      .append('path')
      .attr('class', '_3d xScale')
      .merge(xScale)
      .attr('stroke', 'white')
      .attr('fill', 'white')
      .attr('stroke-width', 0.5)
      .attr('d', this.xScale3d.draw)
    xScale.exit().remove()

    const xText = this.svg.selectAll('text.xText').data(xLine[0])

    xText
      .enter()
      .append('text')
      .attr('class', '_3d xText')
      .attr('stroke', 'white')
      .attr('stroke-opacity', '50%')
      .attr('fill', 'white')
      .attr('fill-opacity', '50%')
      .attr('dx', '.3em')
      .style('font-size', this.getFontSize())
      .merge(xText)
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z }
      })
      .attr('x', (d: any) => {
        return d.projected.x
      })
      .attr('y', (d: any) => {
        return d.projected.y + 20
      })
      .text((d: any) => {
        return d[0] >= 0 && d[0] % 2 === 0 ? d[0] * 10 + '%' : ''
      })

    xText.exit().remove()
    xScale.selectAll('._3d').sort(_3d().sort)
    xText.selectAll('._3d').sort(_3d().sort)

    /* ----------- y-Scale ----------- */

    const yScale = this.svg.selectAll('path.yScale').data(yLine)

    yScale
      .enter()
      .append('path')
      .attr('class', '_3d yScale')
      .merge(yScale)
      .attr('stroke', 'grey')
      .attr('stroke-width', 2)
      .attr('d', this.yScale3d.draw)

    yScale.exit().remove()

    /* ----------- y-Scale Text ----------- */

    const yText = this.svg.selectAll('text.yText').data(yLine[0])

    yText
      .enter()
      .append('text')
      .attr('class', '_3d yText')
      .attr('dx', '.3em')
      .attr('stroke', 'white')
      .attr('fill', 'white')
      .style('font-size', this.getFontSize())
      .merge(yText)
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z }
      })
      .attr('x', (d: any) => {
        return d.projected.x
      })
      .attr('y', (d: any) => {
        return d.projected.y
      })
      .text((d: any) => {
        return d[1] <= 0 && d[1] % 2 === 0
          ? this.options.numberFormatter.transform(
              this.yAxisLinearScale(d[1] * -1),
              this.currency
            )
          : ''
      })

    yText.exit().remove()
    yScale.selectAll('._3d').sort(_3d().sort)
    yText.selectAll('._3d').sort(_3d().sort)
  }

  private getMaxHeight(layers: LayerState[]) {
    const heights = layers
      .map(l => {
        const limit = getLimit(l.layer)
        const attachment = getAttachment(l.layer)
        return limit.value + attachment.value
      })
      .filter(n => analyzereConstants.unlimitedValue > n)
    if (heights.length === 0) {
      heights.push(this.defaultHeight)
    }
    return Math.max(...heights)
  }

  private makeCube(
    id: string,
    x: number,
    y: number,
    z: number,
    width: number,
    height: number,
    depth: number,
    layerState: LayerState | EventLayer,
    sharedIDGroup: SharedIDGroup[]
  ): Cube {
    return {
      id,
      layerState,
      sharedIDGroup,
      points: [
        { x, y: -height + -y, z }, // FRONT TOP LEFT
        { x, y: -y, z }, // FRONT BOTTOM LEFT
        { x: x + width, y: -y, z }, // FRONT BOTTOM RIGHT
        { x: x + width, y: -height + -y, z }, // FRONT TOP RIGHT
        { x, y: -height + -y, z: z - depth }, // BACK  TOP LEFT
        { x, y: -y, z: z - depth }, // BACK  BOTTOM LEFT
        { x: x + width, y: -y, z: z - depth }, // BACK  BOTTOM RIGHT
        { x: x + width, y: -height + -y, z: z - depth }, // BACK  TOP RIGHT
      ],
    }
  }

  getFontSize() {
    return 'var(--font-size-tiny)'
  }
}
