<app-group-inurance-bar
  [dirty]="dirty$ | async"
  [mode]="mode$ | async"
  [nextSymbol]="nextSymbol$ | async"
  [source]="source$ | async"
  [target]="target$ | async"
  [cursor]="cursor$ | async"
  [error]="error$ | async"
  [activeAction]="activeAction$ | async"
  (openNewClick)="onOpenNewClick()"
  (cancelClick)="onCancelClick()"
  (terminalClick)="onTerminalClick($event)"
  (addClick)="onAddClick()"
  (deleteClick)="onDeleteClick($event)"
></app-group-inurance-bar>
