import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  QuoteCustomCompareView,
  QuoteReinsurer,
  QuoteReinsurerCompareTableEntry,
} from '../models/quote.model'
import { clone, equals } from 'ramda'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { FormControl } from '@angular/forms'
import { debounceTime } from 'rxjs'

@Component({
  selector: 'app-quote-custom-comparison',
  templateUrl: './quote-custom-comparison.component.html',
  styleUrls: ['./quote-custom-comparison.component.scss'],
})
export class QuoteCustomComparisonComponent implements OnInit {
  @Input() sectionID: string | null
  @Input() customCompareViews: QuoteCustomCompareView[] = []
  @Input() set currentCompareView(val: QuoteCustomCompareView | null) {
    if (
      val &&
      typeof val.id !== 'undefined' &&
      typeof this.selectedComparisonView.id === 'undefined'
    ) {
      this.selectedComparisonView.id = val.id
    }
  }
  @Input() set reinsurers(value: QuoteReinsurer[][] | undefined) {
    if (!!value && !equals(this.reinsurers, value)) {
      this._reinsurers = value
      this.updateSelectionRows()
    }
  }
  get reinsurers(): QuoteReinsurer[][] | undefined {
    return this._reinsurers
  }
  _reinsurers: QuoteReinsurer[][] | undefined

  @Output() setCompareView = new EventEmitter<QuoteCustomCompareView>()
  @Output() deleteCompareView = new EventEmitter<number>()
  @Output() saveCompareView = new EventEmitter<QuoteCustomCompareView>()

  quoteEntries: QuoteReinsurerCompareTableEntry[] = []
  selectedComparisonView: QuoteCustomCompareView
  labelControl: FormControl = new FormControl('')

  constructor(private confirmationDialog: ConfirmationDialogService) {}

  ngOnInit(): void {
    this.labelControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val: string) => {
        if (val.length && val !== this.selectedComparisonView.name) {
          this.selectedComparisonView.name = val.trim()
          this.setCompareView.emit(this.updatedComparisonView)
        }
      })
    if (
      this.selectedComparisonView &&
      typeof this.selectedComparisonView !== 'undefined'
    ) {
      const view = this.customCompareViews.find(
        compareView => compareView.id === this.selectedComparisonView.id
      )
      if (view) {
        this.selectComparisonView(view)
      }
    } else if (this.sectionID) {
      this.updateSelectionRows()
    }
  }

  get flattenedTableList(): QuoteReinsurerCompareTableEntry[] {
    return this.quoteEntries.reduce(
      (acc, val) =>
        !val.quoteVersions.length
          ? acc.concat(val)
          : acc.concat(...val.quoteVersions),
      []
    )
  }

  get currentComparisonSelectedRows(): QuoteReinsurerCompareTableEntry[] {
    return this.flattenedTableList.filter(val => val.selected)
  }

  get updatedComparisonView(): QuoteCustomCompareView {
    const updatedMemberEntry: QuoteCustomCompareView = {
      ...this.selectedComparisonView,
      members: this.flattenedTableList
        ? this.currentComparisonSelectedRows.map(row => row.id)
        : this.selectedComparisonView.members,
    }
    const id = this.selectedComparisonView.id
    const originalIfSaved = this.customCompareViews.find(val => val.id === id)
    if (!originalIfSaved) {
      // If there is no saved copy, the compare view is always dirty
      return {
        ...updatedMemberEntry,
        dirty: true,
      }
    } else {
      const updatedMembers = updatedMemberEntry.members
      const originalMembers = originalIfSaved.members
      const dirty =
        !updatedMembers.every(member => originalMembers.includes(member)) ||
        !originalMembers.every(member => updatedMembers.includes(member)) ||
        originalIfSaved.name !== this.selectedComparisonView.name
      return {
        ...updatedMemberEntry,
        members: dirty
          ? updatedMemberEntry.members
          : updatedMemberEntry.members.sort(
              (a, b) => originalMembers.indexOf(a) - originalMembers.indexOf(b)
            ),
        dirty,
      }
    }
  }

  updateSelectionRows(): void {
    if (!this.selectedComparisonView) {
      this.setToBlankView()
    }
    this.quoteEntries = [...this.reinsurers].map(reinsurerGroup => {
      const entry = reinsurerGroup[0]
      const isSingleVersion = reinsurerGroup.length === 1
      const hasMultipleSimilarVersions =
        !isSingleVersion &&
        reinsurerGroup.every(
          member => member.reinsurerPhaseVersion === entry.reinsurerPhaseVersion
        )

      if (isSingleVersion) {
        // Displayed as a single line entry with no nesting
        const id = Number(entry.id)
        const selected = this.selectedComparisonView.members.includes(id)
        return {
          id,
          quoteName: `${entry.quoteReinsurerName} - ${entry.reinsurerPhaseLabel}`,
          quoteVersions: [],
          selected,
          childrenHidden: false,
        }
      } else {
        // Blank main row with all versions nested
        const quoteVersions = reinsurerGroup.map(reinsurer => {
          const id = Number(reinsurer.id)
          const selected = this.selectedComparisonView.members.includes(id)
          return {
            id: Number(reinsurer.id),
            quoteName: reinsurer.reinsurerPhaseLabel,
            quoteVersions: [],
            selected,
            childrenHidden: false,
          }
        })
        return {
          id: null,
          quoteName: !hasMultipleSimilarVersions
            ? entry.quoteReinsurerName
            : `${entry.quoteReinsurerName} - ${entry.reinsurerPhaseLabel}`,
          selected: quoteVersions.every(version => version.selected),
          quoteVersions,
          childrenHidden: hasMultipleSimilarVersions,
        }
      }
    })
  }

  selectComparisonView(view: QuoteCustomCompareView): void {
    this.selectedComparisonView = clone(view)
    this.labelControl.setValue(view.name)
    this.updateSelectionRows()
    this.setCompareView.emit(this.updatedComparisonView)
  }

  setToBlankView(): void {
    this.labelControl.setValue('')
    this.selectedComparisonView = {
      name: '',
      riskSectionId: Number(this.sectionID),
      // Select all versions of all reinsurers for new comparison
      members: this.reinsurers.flatMap(reinsurer =>
        reinsurer.map(r => Number(r.id))
      ),
      dirty: false,
    }
    this.setCompareView.emit(this.updatedComparisonView)
  }

  addNewComparison(): void {
    this.setToBlankView()
    this.updateSelectionRows()
    this.setCompareView.emit(this.updatedComparisonView)
  }

  isIndeterminate(row: QuoteReinsurerCompareTableEntry): boolean {
    const versions = row.quoteVersions
    if (!versions.length) {
      return false
    } else {
      return versions.some(v => v.selected) && versions.some(v => !v.selected)
    }
  }

  checkboxChange(
    event: MatCheckboxChange,
    parentIndex: number,
    childIndex?: number
  ): void {
    if (typeof childIndex === 'undefined') {
      const val = this.quoteEntries[parentIndex]
      const hasChildren = !!val.quoteVersions.length
      const newVal: QuoteReinsurerCompareTableEntry = {
        ...val,
        selected: event.checked,
        quoteVersions: hasChildren
          ? val.quoteVersions.map(child => ({
              ...child,
              selected: event.checked,
            }))
          : [],
      }
      this.quoteEntries.splice(parentIndex, 1, newVal)
    } else {
      const parentVal = this.quoteEntries[parentIndex]
      const newVal: QuoteReinsurerCompareTableEntry = {
        ...parentVal,
        quoteVersions: parentVal.quoteVersions.map((val, index) => ({
          ...val,
          selected: index === childIndex ? event.checked : val.selected,
        })),
      }
      const allChildrenSelected = newVal.quoteVersions.every(
        val => val.selected
      )
      this.quoteEntries.splice(parentIndex, 1, {
        ...newVal,
        selected: allChildrenSelected,
      })
    }
    this.setCompareView.emit(this.updatedComparisonView)
  }

  onDeleteClick(id: number): void {
    const dialogRef = this.confirmationDialog.open({
      submitLabel: 'Confirm',
      message:
        'You are about to permenantly delete this comparison, please confirm this action.',
    })
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.setToBlankView()
        this.updateSelectionRows()
        this.deleteCompareView.emit(id)
      }
    })
  }
}
