import { Component, Inject } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Subjectivity } from '../../quote/models/quote.model'

@Component({
  selector: 'app-edit-row-modal',
  template: `
    <h2 mat-dialog-title>Edit {{ data.row.reinsurerName }} Subjectivity</h2>

    <div
      class="min-width-500"
      mat-dialog-content
      [formGroup]="editSubjectivityForm"
    >
      <mat-form-field appearance="outline">
        <mat-label>Select Type:</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let option of data.subjectivityOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <p></p>

      <mat-form-field class="width-100" appearance="outline">
        <mat-label>Reinsurer Commentary:</mat-label>
        <textarea
          matInput
          formControlName="reinsCommentary"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
      <p></p>

      <mat-form-field class="width-100" appearance="outline">
        <mat-label>Broker Comments:</mat-label>
        <textarea
          matInput
          formControlName="brokerComments"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          matInput
          formControlName="brokerComments"
        ></textarea>
      </mat-form-field>
      <p></p>

      <mat-form-field appearance="outline">
        <mat-label>Client Accepted:</mat-label>
        <mat-select formControlName="clientAccepted">
          <mat-option *ngFor="let option of clientAccepted" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- end of mat-dialog-content -->
    <div mat-dialog-actions>
      <button appButton mat-dialog-close type="submit" (click)="save()">
        Save
      </button>
      <button mat-button [mat-dialog-close]="true">Cancel</button>
    </div>
  `,
  styles: [
    `
           .min-width-500 {
             min-width: 500px;
           }
           .width-100 {
             width: 100%;
           }
         `,
  ],
})
export class EditRowDialogComponent {
  editSubjectivityForm: FormGroup
  clientAccepted: string[] = ['', 'Yes', 'No']
  editedSubjectivityRow: Subjectivity

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditRowDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { row: any; subjectivityOptions: string[] }
  ) {
    this.editSubjectivityForm = this.fb.group({
      type: '',
      reinsCommentary: '',
      brokerComments: '',
      clientAccepted: '',
    })

    const selectTypeChoice = this.data.subjectivityOptions.find(
      c => c === this.data.row.type
    )
    this.editSubjectivityForm.controls.type.setValue(selectTypeChoice)

    this.editSubjectivityForm.controls.reinsCommentary.setValue(
      data.row.reinsCommentary
    )
    this.editSubjectivityForm.controls.brokerComments.setValue(
      data.row.brokerComments
    )

    const clientAcceptedChoice = this.clientAccepted.find(
      c => c === this.data.row.clientAccepted
    )

    this.editSubjectivityForm.controls.clientAccepted.setValue(
      clientAcceptedChoice
    )
  }

  save() {
    this.editedSubjectivityRow = {
      acceptedLineCond: '',
      acceptedContractWord: '',
      brokerComments: this.editSubjectivityForm.controls.brokerComments.value,
      clientAccepted: this.editSubjectivityForm.controls.clientAccepted.value,
      id: this.data.row.id,
      reinsCommentary: this.editSubjectivityForm.controls.reinsCommentary.value,
      reinsResponseStatus: '',
      reinsurer: this.data.row.reinsurerName.slice(0, -5).trim(),
      reinsurerResponse: '',
      type: this.editSubjectivityForm.controls.type.value,
      applyToStructureForSameMarket:
        this.editSubjectivityForm.controls.applyToStructureForSameMarket.value,
      vendorName: this.data.row.vendorName,
    }
    const editedSubjectivity = {
      rows: [this.editedSubjectivityRow],
      id: this.data.row.reinsurerId,
    }
    this.dialogRef.close(editedSubjectivity)
  }
}
