interface Numeric {
  valueOf(): number
}

export const getMedianValue = <T>(valueFn: (item: T) => number, views: T[]) => {
  const values = views.map(valueFn).sort((a, b) => a - b)
  const p50 = Math.floor(values.length / 2)
  return values.length % 2 ? values[p50] : (values[p50] + values[p50 - 1]) / 2.0
}

export const randomInt = (min = 1, max = 100) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const randomFloat = (min = 0, max = 99) =>
  Math.random() * (max - min) + min

export const calcGridDimensions = (
  count: number
): readonly [number, number] => {
  if (count <= 3) {
    return [1, count]
  }
  if (count <= 6) {
    return [2, Math.floor((count + 1) / 2)]
  }
  if (count <= 12) {
    return [3, Math.floor((count + 2) / 3)]
  }
  const y = Math.ceil(Math.sqrt(count))
  return [Math.ceil(count / y), y]
}

export const numericValue = (value: number | Numeric | string): number =>
  typeof value === 'number'
    ? value
    : typeof value === 'string'
    ? Number(value)
    : value.valueOf()

export const addNumeric = (...values: (number | Numeric)[]) =>
  values.reduce((acc: number, v) => acc + numericValue(v), 0)

export const subNumeric = (...values: (number | Numeric)[]) =>
  values
    .slice(1)
    .reduce((acc: number, v) => acc - numericValue(v), values[0] ?? 0)

export const multNumeric = (...values: (number | Numeric)[]) =>
  values.reduce((acc: number, v) => acc * numericValue(v), 0)

export const divNumeric = (...values: (number | Numeric)[]) =>
  values
    .slice(1)
    .reduce((acc: number, v) => acc / numericValue(v), values[0] ?? 0)

export function divideOrZero(numerator: number, denominator: number): number {
  return denominator !== 0 ? numerator / denominator : 0
}

export function getStandardDeviation(values: number[]) {
  const n = values.length
  if (n === 0) {
    return 0
  }
  const mean = values.reduce((acc, val) => acc + val, 0) / n
  const diffSquared = values.map(val => Math.pow(val - mean, 2))
  const diffSquaredSum = diffSquared.reduce((acc, d) => acc + d, 0)
  const variance = diffSquaredSum / n
  return Math.sqrt(variance)
}


/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * clamp([input], 0, 255)
 *
 * @param input The number to check against
 * @param min The lower boundary of the output range
 * @param max The upper boundary of the output range
 * @returns A number in the range [min, max]
 */
export const clamp = (input: number, min: number, max: number): number =>
  Math.min(Math.max(input, min), max)


