import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
} from '@angular/cdk/drag-drop'
import { ViewportRuler } from '@angular/cdk/overlay'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { MatSelectChange } from '@angular/material/select'
import { select, Store } from '@ngrx/store'
import { isNil, splitEvery, uniq } from 'ramda'
import { Observable, forkJoin, throwError, Subject, concat } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { CompareView } from '../../analysis/model/compare-view.model'
import {
  LayerPaletteView,
  LAYER_PALETTE_VIEWS,
  layerIds,
} from '../../analysis/model/layer-palette.model'
import * as fromAnalysisActions from '../../analysis/store/analysis.actions'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import {
  ProgramGroup,
  ProgramGroupMember,
  SharedLimit,
} from '../../analysis/store/grouper/program-group.model'
import {
  AccountOpportunity,
  FeatureFlag,
  RiskSectionWithMarkets,
  StudyResponse,
} from '../../api/model/backend.model'
import { Client, ClientYear } from '../../core/model/client.model'
import { Program } from '../../core/model/program.model'
import { Folder, Study } from '../../core/model/study.model'
import { AppState } from '../../core/store'
import * as fromBrokerActions from '../../core/store/broker/broker.actions'
import { selectDesignProgramIDS } from '../../core/store/broker/broker.selectors'
import { QuoteReinsurer } from '../../quote/models/quote.model'
import { QuoteStartProps } from '../../quote/store/quote.actions'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { checkboxSelectClass } from '@shared/checkbox-select.component'
import { FilterInputComponent, NavKey } from '@shared/filter-input.component'
import {
  SetNameDialogComponent,
  SetNameDialogData,
  SetNameDialogResult,
} from '@shared/set-name-dialog.component'
import { CanSize, CanSizeCtor, mixinSize, Size } from '@shared/size.mixin'
import { rejectNil } from '@shared/util/operators'
import { AutoBuildService } from '../auto-build.service'
import {
  CloneDialogResult,
  CloneDialogComponent,
} from '../clone-dialog.component'
import { DesignFromOTDialogComponent } from '../design-from-ot-dialog/design-from-ot-dialog'
import { StructureCardComponent } from 'src/app/tier/structure-card/structure-card.component'
import { SwapLossSetsDialogComponent } from '../swap-loss-sets-dialog/swap-loss-sets-dialog.component'
import { BackendService } from 'src/app/api/backend/backend.service'
import {
  TierStructuresDialogComponent,
  TierStructuresDialogConfig,
} from '../tier-structures-dialog.component/tier-structures-dialog.component'
import { TierPath, UpdatePositionIndexesRequest } from '../tier.model'
import { AnalyzreService } from '../../api/analyzere/analyzre.service'
import {
  ImportLossSetsClickEvent,
  importBulkLossSets,
  swapBulkLossSets,
  setProgramNameAndDescription,
} from '../../core/store/program/program.actions'
import {
  updateCheckedPrograms,
  removedCheckedPrograms,
} from '../../core/store/broker/broker.actions'
import { generateUUID } from 'three/src/math/MathUtils'
import { LoadingPopupComponent } from '@shared/loading-popup/loading-popup.component'
import { selectSavingAsClone } from 'src/app/analysis/store/analysis.selectors'
import { NewFolderDialogContainerComponent } from '../new-folder-dialog/new-folder-dialog.container'
import { SectionState } from 'src/app/quote/store/section/section.reducer'
import { CreditStructure } from 'src/app/credit/model/credit-structure.model'
import { MessageDialogService } from '@shared/message-dialog.service'
import {
  isAHLLayer,
  isQSLayer,
  isROLLayer,
} from 'src/app/analysis/model/layers.util'
import { MatSnackBar } from '@angular/material/snack-bar'
import { CreditStructureGroup } from './../../credit/model/credit-structure-group.model'
import { CreditRoutes } from '../../credit/model/credit-routes.model'
import { CreditSubmissionStructure } from './../../credit/model/credit-submission.model'
import { setCompareStructureOptionsDimensionProp } from 'src/app/analysis/store/compare/compare.actions'

class TierStructuresBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof TierStructuresBase =
  mixinSize(TierStructuresBase)

const cursorSections = [
  'structures',
  'structureGroups',
  'compareViews',
] as const
type CursorSection = (typeof cursorSections)[number]
type Cursor = Record<CursorSection, { id: string; index: number }>

export interface StructureSaveAsEvent {
  name: string
}
const PANEL_CLASS = 'app-tier-structures-dialog'
const LIBRE_TEMPLATE_IDENTIFIER = 'T'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tier-structures',
  templateUrl: './tier-structures.component.html',
  styleUrls: ['./tier-structures.component.scss'],
})
export class TierStructuresComponent
  extends _SizeMixinBase
  implements CanSize, AfterViewInit, OnInit, OnChanges, OnDestroy
{
  mousePosition: { x: number; y: number } | null = null
  filter = ''
  filterStructures: Program[] = []
  filterStructureGroups: ProgramGroup[] = []
  cursorSection: CursorSection = 'structureGroups'
  cursor = initCursor()
  folderSections: Folder[][] = []
  slices: number
  editModeSelected = false
  selectAllSelected = false
  target: CdkDropList
  targetIndex: number
  source: CdkDropList
  sourceIndex: number
  activeContainer: any
  groupOrStructureFilterSelected: 'Group' | 'Structure' | 'SL' | null
  selectedFolderID: string | null | undefined = null
  selectedFolderName: string | null | undefined
  folderModeStructureIds: string[] = []
  showWeightedAvgRateOnLine: boolean
  showWeightedAvgRatePercentageOfSubject: boolean
  showWeightedAvgCedingCommission: boolean
  showWeightedAvgPMPM: boolean
  secondBar = true
  _groupFilterByAssoc: boolean

  @Input() set groupFilterByAssoc(value: boolean) {
    this._groupFilterByAssoc = value
    this.updateFilter()
  }
  get groupFilterByAssoc() {
    return this._groupFilterByAssoc
  }
  @Input() structureGroupMembers: ProgramGroupMember[]
  @Input() clients?: Client[]
  @Input() years?: ClientYear[]
  @Input() years2?: ClientYear[]
  @Input() programs: Study[]
  @Input() programs2: Study[]
  @Input() title: string
  @Input() executiveSummaryHidden = true
  @Input() clientData: string
  @Input() yearID: string | null
  @Input() creditStructures: CreditStructure[] = []
  @Input() creditSubmissionStructures: CreditSubmissionStructure[] = []
  @Input() selectedClientID?: string | null
  @Input() selectedClientID2?: string | null
  @Input() selectedCededLayerID?: string | null | undefined
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() quoteDirty: boolean
  @Input() showAssignedLines = false
  @Input() selectedStructureGroupID?: string | null
  @Input() selectedSharedLimitID?: string | null
  @Input() showDialogClone: boolean
  @Input() showEditMode: boolean = false
  @Input() isButton = false
  @Input() studies: StudyResponse[]
  @Input() featureFlags: FeatureFlag[]
  @Input() editFolderMode: boolean
  @Input() folders: Folder[] = []
  @Input() selectedCompareView: CompareView
  updatedFolders: Folder[] = []

  @Output() saveAsClick = new EventEmitter<StructureSaveAsEvent>()
  @Output() showDialogFlag = new EventEmitter<string>()
  @Output() importLossSetsClick = new EventEmitter<ImportLossSetsClickEvent>()
  @Output() updatePositionIndexes =
    new EventEmitter<UpdatePositionIndexesRequest>()
  @Output() updateFolders = new EventEmitter<string | undefined | null>()
  @Output() updateStructureFolderID = new EventEmitter<{
    structureId: string
    folderID: string | undefined | null
  }>()

  views: LayerPaletteView[] = LAYER_PALETTE_VIEWS

  checkedPrograms$: Observable<Program[]>
  checkedPrograms: Program[]
  selectedViewId: string | null
  allowAutoBuild = false
  hasLibreTemplate = false
  libreTemplate: Program | undefined
  salesForce: string | undefined
  disabledLossSetsSwap: boolean
  checkboxTooltip = ''

  private destroy$ = new Subject()

  quoteSummary: {
    offeredPercentageTotal: number
    weightedAvgRateOnLine?: number
    weightedAvgRatePercentageOfSubject?: number
    weightedAvgCedingCommission?: number
    weightedAvgPMPM?: number
  } = {
    offeredPercentageTotal: 0,
  }

  onMouseOver(event: MouseEvent) {
    this.mousePosition = { x: event.clientX, y: event.clientY }
  }

  onAddLossSetClick(): void {
    if (this.selectedStructureIDs?.length === 0) {
      return
    }
    let time = 0
    this.dialog
      .open(CloneDialogComponent, { data: { title: 'Add Loss Sets' } })
      .afterClosed()
      .pipe(filter(result => result.event === 'save'))
      .pipe(
        filter<CloneDialogResult>(res => !!res),
        map(res => {
          this.checkedPrograms.forEach((a, i) => {
            if (a.description === 'No Modeling') {
              a = { ...a, description: '' }
            }
            const result = {
              structure: a,
              parentGrossPortfolioID: res.parentGrossPortfolioID || '',
              analysisProfileID: res.analysisProfileID || '',
              isLast:
                (this.checkedPrograms &&
                  this.checkedPrograms.length - 1 === i) ||
                false,
            }
            this.store.dispatch(
              setProgramNameAndDescription({
                structure: a,
                name: a.label,
                description: a.description,
              })
            )
            setTimeout(() => {
              this.store.dispatch(importBulkLossSets(result))
            }, time)
            time += 1200
          })
        })
      )
      .subscribe()
  }
  getLossSetName(inputString: string): string {
    const firstDashIndex = inputString.indexOf('-')
    return inputString.slice(0, firstDashIndex).trim().replace(/\s/g, '')
  }

  onSwapLossSetClick(): void {
    if (this.disabledLossSetsSwap || this.selectedStructureIDs?.length === 0) {
      return
    }
    if (
      this.selectedStructures &&
      this.selectedStructures.every(
        ss =>
          (this.selectedStructures &&
            this.selectedStructures[0].parentGrossPortfolioID) ===
          ss.parentGrossPortfolioID
      )
    ) {
      this.dialog
        .open(CloneDialogComponent, { data: { title: 'Swap Loss Sets' } })
        .afterClosed()
        .pipe(filter(result => result.event === 'save'))
        .pipe(
          switchMap(res => {
            const structure = this.selectedStructures
              ? this.selectedStructures[0]
              : null
            if (!structure) {
              // Handle case when structure is null or undefined
              return throwError('No structure selected')
            }

            const oldLossSets = this.analyzereService.fetchPortfolio(
              structure.parentGrossPortfolioID || ''
            )

            const newLossSets = this.analyzereService.fetchPortfolio(
              res.parentGrossPortfolioID
            )

            return forkJoin([oldLossSets, newLossSets]).pipe(
              map(([oldLossSetsRes, newLossSetsRes]) => {
                return { res, oldLossSetsRes, newLossSetsRes }
              })
            )
          })
        )
        .subscribe(res => {
          const oldLossSets = res.oldLossSetsRes
          const newLossSets = res.newLossSetsRes

          this.dialog
            .open(SwapLossSetsDialogComponent, {
              data: [oldLossSets, newLossSets],
            })
            .afterClosed()
            .subscribe(result => {
              if (!result) {
                return
              }
              if (!this.selectedStructures) {
                return
              }
              this.selectedStructures.forEach((ss, i) => {
                const structure = ss
                this.store.dispatch(
                  swapBulkLossSets({
                    structure,
                    analysisProfileID: res.res.analysisProfileID || '',
                    oldParentGrossPortfolioID:
                      structure.parentGrossPortfolioID || '',
                    newParentGrossPortfolioID:
                      res.res.parentGrossPortfolioID || '',
                    lossSetMapping: result,
                    isLast:
                      (this.selectedStructures &&
                        this.selectedStructures.length - 1 === i) ||
                      false,
                  })
                )
              })
            })
        })
    } else {
      this.messageService.showMessage(
        'The selected structures have different Parent Gross portfolios. Please select structures with the same parent gross portfolio in order to swap loss sets.'
      )
    }
  }

  onCloneClick(): void {
    if (this.selectedStructureIDs?.length === 0) {
      return
    }
    if (this.checkedPrograms) {
      this.checkedPrograms.forEach((a, i) => {
        if (this.structures2) {
          const res: CloneDialogResult = {
            parentGrossPortfolioID: a.parentGrossPortfolioID,
            analysisProfileID: a.analysisID,
            index: i,
            isClone: true,
          }
          this.store.dispatch(fromAnalysisActions.saveAsCloneAnalysis(res))
        }
      })
      this.dialog.open(LoadingPopupComponent, {
        data: {
          title: 'Cloning structure',
          subText: 'Please wait for operation to complete...',
        },
        disableClose: true,
        hasBackdrop: true,
      })

      this.store
        .pipe(select(selectSavingAsClone), takeUntil(this.destroy$))
        .subscribe(savingAsClone => {
          if (!savingAsClone) {
            this.dialog.closeAll()
            this.showDialogClone = false
            setTimeout(() => {
              this.changeDetectorRef.detectChanges()
            }, 100)
          }
        })
    }
  }

  private _currentReinsurerMap: Record<number, QuoteReinsurer> = {}
  @Input() set currentReinsurerMap(
    currentReinsurerMap: Record<number, QuoteReinsurer>
  ) {
    this._currentReinsurerMap = currentReinsurerMap
    this.getQuoteSummary()
  }
  get currentReinsurerMap(): Record<number, QuoteReinsurer> {
    return this._currentReinsurerMap
  }

  private _selectedYearID: string | null
  @Input() set selectedYearID(value: string | null) {
    this._selectedYearID = value
    this.updateFilter()
  }
  get selectedYearID(): null | string {
    return this._selectedYearID
  }
  private _selectedYearID2: string | null
  @Input() set selectedYearID2(value: string | null) {
    this._selectedYearID2 = value
    this.updateFilter()
  }
  get selectedYearID2(): null | string {
    return this._selectedYearID2
  }

  private _selectedProgramID?: string | null
  @Input() set selectedProgramID(value: string | null | undefined) {
    this._selectedProgramID = value
    this.updateFilter()
  }

  get selectedProgramID() {
    return this._selectedProgramID
  }
  private _selectedProgramID2?: string | null
  @Input() set selectedProgramID2(value: string | null | undefined) {
    this._selectedProgramID2 = value
    this.updateFilter()
  }

  get selectedProgramID2() {
    return this._selectedProgramID2
  }

  @Input() selectedStructureID: string | null

  private _structureGroups: ProgramGroup[] | undefined
  @Input() set structureGroups(value: ProgramGroup[] | undefined) {
    this._structureGroups = value
    this.updateFilter()
  }
  get structureGroups(): ProgramGroup[] | undefined {
    return this._structureGroups
  }

  private _structures: Program[] | undefined
  @Input() set structures(value: Program[] | undefined) {
    this._structures = value
    const libreTemplate = this._structures?.find(
      a => a.libRE === LIBRE_TEMPLATE_IDENTIFIER
    ) as Program
    this.libreTemplate = libreTemplate ? libreTemplate : undefined
    this.hasLibreTemplate = !!libreTemplate
    this.updateFilter()
  }
  get structures(): Program[] | undefined {
    return this._structures?.filter(
      a => a.libRE !== LIBRE_TEMPLATE_IDENTIFIER
    ) as Program[]
  }

  private _structures2: Program[] | undefined
  @Input() set structures2(value: Program[] | undefined) {
    this._structures2 = value
    this.updateFilter()
  }
  get structures2(): Program[] | undefined {
    return this._structures2
  }
  private _compareViews: CompareView[] | undefined
  @Input() set compareViews(value: CompareView[] | undefined) {
    this._compareViews = value
  }

  get compareViews(): CompareView[] | undefined {
    return this._compareViews?.filter(
      view => view.client_id === this.selectedClientID
    )
  }

  @Input() selectedStructureGroupIDs?: string[]

  private _selectedStructureIDs?: string[]
  @Input() set selectedStructureIDs(value: string[] | undefined) {
    this._selectedStructureIDs = value
    this.updateFilter()
  }

  get selectedStructureIDs(): string[] | undefined {
    return this._selectedStructureIDs
  }

  private _selectedStructures?: Program[]
  @Input() set selectedStructures(value: Program[] | undefined) {
    this._selectedStructures = value
    this.updateFilter()
  }

  get selectedStructures(): Program[] | undefined {
    return this._selectedStructures
  }

  private _sharedLimits: SharedLimit[] | null
  @Input() set sharedLimits(value: SharedLimit[] | null) {
    this._sharedLimits = value
  }

  get sharedLimits(): SharedLimit[] | null {
    return (
      this._sharedLimits?.filter(
        view => view.carrier_year_id?.toString() === this.selectedYearID
      ) || []
    )
  }

  // Allow Scenario or Optimization Selection - Shows the scenarios/optimization select all checkbox
  @Input() set allowScenarioOrOptimizationSelection(value: any) {
    this._allowScenarioOrOptimizationSelection = coerceBooleanProperty(value)
  }
  get allowScenarioOrOptimizationSelection() {
    return this._allowScenarioOrOptimizationSelection
  }
  _allowScenarioOrOptimizationSelection = false

  @Input() clientSelect = false
  @Input() contextPath?: string[]

  @Input() set structureFilter(value: string) {
    this.filter = value
    this.updateFilter()
  }

  // Multiple selection
  @Input() set showCloseButton(value: any) {
    this._showCloseButton = coerceBooleanProperty(value)
  }
  get showCloseButton() {
    return this._showCloseButton
  }
  @HostBinding('class.show-close') _showCloseButton = false

  // Multiple selection
  @Input() set selectMultiple(value: any) {
    this._selectMultiple = coerceBooleanProperty(value)
  }
  get selectMultiple() {
    return this._selectMultiple
  }
  _selectMultiple = false

  // Elevation
  @Input() set elevation(value: any) {
    this._elevation = coerceNumberProperty(value)
  }
  get elevation() {
    return this._elevation
  }
  _elevation = 0

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  private _routerUrl: string
  @Input() set routerUrl(value: string) {
    this._routerUrl = value
    if (this._routerUrl?.includes(CreditRoutes.Credit)) {
      this.isCredit = true
      if (this.routerUrl?.includes(CreditRoutes.Design)) {
        this.isCreditDesign = true
      }
    }
  }
  get routerUrl() {
    return this._routerUrl
  }
  isCredit = false
  isCreditDesign = false

  @Input() isQuote = false
  @Input() isSlipT = false
  @Input() isSignature = false
  @Input() isDesign = false
  @Input() quoteLayers: LayerState[] = []
  @Input() sectionList: SectionState[] | null

  @Output() tierChange = new EventEmitter<TierPath>()
  @Output() tierChange2 = new EventEmitter<TierPath>()
  @Output() sageViewSelectionChange = new EventEmitter<{
    id: number
    updatedStudy: StudyResponse
  }>()
  @Output() compareViewSelectionChange = new EventEmitter<CompareView>()
  @Output() structureGroupSelectionChange = new EventEmitter<ProgramGroup>()
  @Output() structureSelectionChange = new EventEmitter<Program>()
  @Output() creditStructureSelectionChange = new EventEmitter<CreditStructure>()
  @Output() creditStructureOptimize = new EventEmitter<CreditStructure>()
  @Output() creditSubmissionSelectionChange =
    new EventEmitter<CreditSubmissionStructure>()
  @Output() createNewFolder = new EventEmitter<string>()
  @Output() scenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<Program>
  >()
  @Output() closeClick = new EventEmitter<void>()

  @Output() setRoute = new EventEmitter<{ page: string; program: Program }>()
  @Output() structureFilterChange = new EventEmitter<string>()
  @Output() structureNameDescriptionEdit = new EventEmitter<{
    structure: Program
    name: string
    description: string
  }>()
  @Output() structureIndexEdit = new EventEmitter<{
    structure: Program
    index: number
  }>()
  @Output() groupOrStructureSelectionChange = new EventEmitter<
    'Group' | 'Structure' | 'SL' | null
  >()
  @Output() groupFilterByAssocChange = new EventEmitter<boolean>()
  @Output() layerSelect = new EventEmitter<QuoteStartProps>()
  @Output() groupSelect = new EventEmitter<QuoteStartProps>()
  @Output() slSelect = new EventEmitter<QuoteStartProps>()
  @Output() initSignaturePage = new EventEmitter<'Group' | 'Structure' | 'SL'>()
  @Output() createNewCreditStructureClick = new EventEmitter()

  @ViewChild(FilterInputComponent) filterInput?: FilterInputComponent
  @ViewChildren('structureGroupItem') structureGroupItems: QueryList<ElementRef>
  @ViewChildren(StructureCardComponent, { read: ElementRef })
  structureItems?: QueryList<ElementRef>

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>
  @ViewChild(CdkDropList) placeholder: CdkDropList

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.folderSections = this.getTotalFolders()
    this.slices = this.getSlices()
  }

  @HostListener('document:keydown', ['$event']) onKeyup($event: KeyboardEvent) {
    if (($event.target as any)?.type === 'text') {
      // Ignore keystrokes in inputs
      return
    }
    switch ($event.key) {
      case '/':
        this.filterInput?.focus()
        break
      case ' ':
      case 'Enter':
        this.selectCursor()
        break
      case 'ArrowDown':
        this.moveCursor(1)
        break
      case 'ArrowUp':
        this.moveCursor(-1)
        break
      case 'ArrowRight':
        this.moveCursorSection(1)
        break
      case 'ArrowLeft':
        this.moveCursorSection(-1)
        break
    }
  }

  @HostBinding('class.show-client-info')
  get showClientInfo(): boolean {
    return this.clientSelect && this.selectedClientID == null
  }

  get showOverview(): boolean {
    return this.clientSelect && this.selectedClientID != null
  }

  get showSelectMessage(): boolean {
    if ((this.isQuote && this.selectedStructureID) || this.isClientSelectPage) {
      return false
    }
    const isGroupSelected = this.groupOrStructureFilterSelected === 'Group'
    const isSLSelected = this.groupOrStructureFilterSelected === 'SL'
    const isStructureSelected =
      this.groupOrStructureFilterSelected === 'Structure'
    const noGroupCriteriaMet =
      isGroupSelected &&
      (this.selectedProgramHasNoStructureGroups ||
        this.noStructureGroupSelected)
    const noSLCriteriaMet =
      isSLSelected &&
      (this.selectedProgramHasNoSharedLimits || this.noSharedLimitSelected)
    const noStructureCriteriaMet =
      isStructureSelected &&
      (this.selectedProgramHasNoStructures || this.noStructureSelected)
    return (
      this.noYearSelected ||
      this.noProgramSelected ||
      this.noGroupOrStuctureFilterSelected ||
      noGroupCriteriaMet ||
      noSLCriteriaMet ||
      noStructureCriteriaMet
    )
  }
  get isClientSelectPage(): boolean {
    return this.clientSelect
  }
  get noYearSelected(): boolean {
    return this.selectedYearID === null
  }
  get noProgramSelected(): boolean {
    return this.selectedProgramID === null
  }
  get selectedProgramHasNoStructureGroups(): boolean {
    return !this.noProgramSelected && this.structureGroups?.length === 0
  }
  get selectedProgramHasNoSharedLimits(): boolean {
    return !this.noProgramSelected && this.sharedLimits?.length === 0
  }
  get selectedProgramHasNoStructures(): boolean {
    return !this.noProgramSelected && this.structures?.length === 0
  }
  get noGroupOrStuctureFilterSelected(): boolean {
    return (
      !this.groupOrStructureFilterSelected && (this.isQuote || this.isSignature)
    )
  }
  get noStructureSelected(): boolean {
    return this.selectedStructureID === null
  }
  get noStructureGroupSelected(): boolean {
    return this.selectedStructureGroupID === null
  }
  get noSharedLimitSelected(): boolean {
    return this.selectedSharedLimitID === null
  }

  get showStructureGroups(): boolean {
    return (
      !this.clientSelect &&
      !this.showSelectMessage &&
      this.structureGroups != null
    )
  }

  get showStructures(): boolean {
    return (
      !this.clientSelect && !this.showSelectMessage && this.structures != null
    )
  }

  get showCreditStructures(): boolean {
    if (!this.routerUrl) {
      return false
    }
    return (
      !this.clientSelect &&
      !this.showSelectMessage &&
      this.routerUrl.includes(CreditRoutes.Credit)
    )
  }

  get showCreditStructureGroups(): boolean {
    if (!this.routerUrl) {
      return false
    }
    return (
      !this.clientSelect &&
      !this.showSelectMessage &&
      this.routerUrl.includes(CreditRoutes.Credit) &&
      (this.routerUrl.includes(CreditRoutes.Group) ||
        this.routerUrl.includes(CreditRoutes.Compare))
    )
  }

  get showCompareViews(): boolean {
    return (
      !this.clientSelect && !this.showSelectMessage && this.compareViews != null
    )
  }

  get showBarRight(): boolean {
    return (
      (this.showStructureGroups || this.showStructures) &&
      !this.isQuote &&
      !this.isSignature &&
      !this.isCredit
    )
  }

  get showStructuresHeader(): boolean {
    return this.structures != null && this.structureGroups != null
  }

  get showCreditStructuresHeader(): boolean {
    if (!this.routerUrl) {
      return false
    }
    return (
      this.routerUrl.includes(CreditRoutes.Credit) &&
      (this.routerUrl.includes(CreditRoutes.Group) ||
        this.routerUrl.includes(CreditRoutes.Compare))
    )
  }

  get selectMessage(): string {
    const isEmpty = <T>(arr: T[] | null | undefined) =>
      !isNil(arr) && arr.length === 0

    return this.selectedYearID === null
      ? isEmpty(this.years)
        ? 'This company has no years available.'
        : 'Please select a year for this company.'
      : isNil(this.selectedProgramID)
        ? isEmpty(this.programs)
          ? 'This company has no programs available.'
          : 'Please select a program for this company and year.'
        : isEmpty(this.structures)
          ? 'This program has no structures available.'
          : isNil(this.groupOrStructureFilterSelected)
            ? 'Please select to filter by group or structure for this company, year and program.'
            : this.groupOrStructureFilterSelected === 'Group' &&
                isNil(this.selectedStructureGroupID)
              ? 'Please select a group for this company, year and program.'
              : this.groupOrStructureFilterSelected === 'SL' &&
                  isNil(this.selectedSharedLimitID)
                ? 'Please select a group for this company, year and program.'
                : 'Please select a structure for this company, year and program.'
  }

  get structureGroupsHeader(): string {
    return !isNil(this.structureGroups) && this.structureGroups.length > 0
      ? 'Structure Groups'
      : 'No Structure Groups Available'
  }

  get structuresHeader(): string {
    return (!isNil(this.structures) && this.structures.length > 0) ||
      (!isNil(this.creditStructures) && this.creditStructures.length > 0) ||
      (!isNil(this.creditSubmissionStructures) &&
        this.creditSubmissionStructures.length > 0)
      ? 'Structures'
      : 'No Structures Available'
  }

  get compareViewsHeader(): string {
    return !isNil(this.compareViews) && this.compareViews.length > 0
      ? 'Compare Views'
      : 'No Compare Views Available'
  }

  get selectedView() {
    return LAYER_PALETTE_VIEWS.find(v => v.id === this.selectedViewId)
  }

  get layerViewAllowed(): boolean {
    if (this.featureFlags) {
      const featureFlagFound = this.featureFlags.find(
        element => element.featureName === 'AllowLayerViewSelection'
      )
      if (featureFlagFound) {
        return featureFlagFound.isEnabled
      }
    }
    return false
  }

  constructor(
    private store: Store<AppState>,
    public elementRef: ElementRef,
    private dialog: MatDialog,
    private viewportRuler: ViewportRuler,
    private analyzereService: AnalyzreService,
    private autoBuildService: AutoBuildService,
    private backendService: BackendService,
    private messageService: MessageDialogService,
    private _snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(elementRef)
    this.target = null as any
    this.source = null as any

    this.checkedPrograms$ = this.store.pipe(select(selectDesignProgramIDS))
    this.checkedPrograms$.subscribe(a => {
      this.checkedPrograms = a
    })
  }

  determineViewType(): void {
    if (this.selectedProgramID && this.studies) {
      this.selectedViewId = this.getDefaultView()
    }
  }

  ngOnInit(): void {
    this.determineViewType()
    // allow autobuild feature on feature flag?
    if (this.editFolderMode) {
      this.checkboxTooltip = 'Check to add multiple structures to a folder'
    }
    if (this.featureFlags) {
      const featureFlagFound = this.featureFlags.find(
        element => element.featureName === 'AllowAutoBuild'
      )
      if (featureFlagFound) {
        this.allowAutoBuild = featureFlagFound.isEnabled
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedYearID && this.years) {
      const year = this.years.find(y => y.id === this.selectedYearID)
      if (
        !this.programs ||
        (year && this.programs.length !== year.studies.length)
      ) {
        this.programs = year?.studies as Study[]
      }
    }
    if (changes && changes.selectedProgramID) {
      this.determineViewType()
      this.salesForce = this.getOpportunityRenewedFrom()
    }

    let disabledLossSetsSwap = false
    if (this.selectedStructureIDs && this.selectedStructureIDs.length > 0) {
      this.filterStructures.forEach(program => {
        if (disabledLossSetsSwap) {
          return
        }
        if (program.libRE === 'Y') {
          if (this.selectedStructureIDs?.includes(program.id)) {
            disabledLossSetsSwap = true
          }
        }
      })
    }
    this.disabledLossSetsSwap = disabledLossSetsSwap
    if (
      this.filterStructures &&
      this.checkForInvalidPositionIndexes(this.filterStructures) &&
      this.selectedProgramID
    ) {
      const indexesToUpdate = this.filterStructures.map((structure, i) => {
        return {
          structureId: structure.id,
          positionIndex: i + 1,
        }
      })
      this.updatePositionIndexes.emit({
        indexes: indexesToUpdate,
        programId: this.selectedProgramID,
      })

      this.filterStructures = this.filterStructures.map(structure => {
        const index = indexesToUpdate.find(
          ind => ind.structureId === structure.id
        )
        return {
          ...structure,
          position_index: index?.positionIndex ?? structure.position_index,
        }
      })
    }
    if (this.folders) {
      this.organizeUpdatedFolders()
      this.folderSections = this.getTotalFolders()
    }

    this.updateFilter()
    this.getQuoteSummary()
  }

  populateFolders(): void {
    if (this.folders && this.structures) {
      this.updatedFolders = this.folders.map(f => {
        return {
          ...f,
          programIDs: [],
        }
      })
      for (const folder of this.updatedFolders) {
        for (const structure of this.structures) {
          if (
            structure.folderID === folder.id &&
            !folder.programIDs.includes(structure.id)
          ) {
            folder.programIDs.push(structure.id)
          }
        }
      }
    }
  }

  selectFolderID(folderID: string | null, folderName: string | null): void {
    this.selectedFolderID = folderID
    this.selectedFolderName = folderName
    this.updateFilter()
    if (!folderID) {
      this.editModeSelected = false
      this.populateFolders()
    } else {
      this.updatedFolders = []
    }
    this.folderSections = this.getTotalFolders()
    this.folderModeStructureIds = []
  }

  onStructureCheckChange(event: { structureId: string }): void {
    const { structureId } = event
    const index = this.folderModeStructureIds.indexOf(structureId)
    if (index !== -1) {
      this.folderModeStructureIds.splice(index, 1)
    } else {
      this.folderModeStructureIds.push(structureId)
    }
    this.updateFilter()
  }

  onTierChange($event: TierPath): void {
    this.selectedFolderID = null
    this.selectedFolderName = null
    this.tierChange.emit($event)
  }

  ngAfterViewInit(): void {
    this.filterInput?.focus()
  }

  dragEntered(event: CdkDragEnter<Program>): void {
    if (this.selectMultiple) {
      return
    }
    const { item, container } = event
    this.sourceIndex = this.filterStructures.indexOf(item.data)
    this.targetIndex = this.filterStructures.indexOf(container.data)
    moveItemInArray(this.filterStructures, this.sourceIndex, this.targetIndex)
  }

  checkForInvalidPositionIndexes(structures: Program[]): boolean {
    const indexes: (number | undefined)[] = []
    for (const s of structures) {
      if (
        (!s.position_index && s.position_index !== 0) ||
        s.position_index === null ||
        indexes.includes(s.position_index)
      ) {
        return true
      }
      indexes.push(s.position_index)
    }
    return false
  }

  onViewChange($event: MatSelectChange): void {
    this.selectedViewId = $event.value
    const selectedSageViewName = this.views.find(
      v => v.id === this.selectedViewId
    )?.name
    const selectedStudy = this.studies.find(
      s => s.id.toString() === this.selectedProgramID
    )
    if (selectedStudy) {
      const cloneSelectedStudy = { ...selectedStudy }
      cloneSelectedStudy.sage_view = selectedSageViewName
      this.sageViewSelectionChange.emit({
        id: selectedStudy.id,
        updatedStudy: cloneSelectedStudy,
      })
    }
    if (selectedSageViewName && this.selectedProgramID) {
      const req = { sageViewName: selectedSageViewName }

      this.backendService
        .putStudySageView(Number(this.selectedProgramID), req)
        .subscribe((response: any) => {
          if (response.error !== undefined) {
            this._snackBar.open('Error Editing Audience View.', 'X', {
              duration: 2000,
              panelClass: 'snackbar-white-bg',
            })
          }
        })
    }
  }

  getDefaultView(): string {
    const selectedStudyView = this.studies.find(
      s => s.id.toString() === this.selectedProgramID
    )?.sage_view
    if (selectedStudyView) {
      return this.views.find(v => v.name === selectedStudyView)?.id || '1'
    }
    return '1'
  }

  onGroupOrStructureSelectionChange(
    groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  ): void {
    this.groupOrStructureFilterSelected = groupOrStructureFilterSelection
    this.groupOrStructureSelectionChange.emit(
      this.groupOrStructureFilterSelected
    )
    if (
      this.groupOrStructureFilterSelected === 'Structure' ||
      this.groupOrStructureFilterSelected === 'Group' ||
      this.groupOrStructureFilterSelected === 'SL'
    ) {
      this.initSignaturePage.emit(this.groupOrStructureFilterSelected)
    }
  }

  isStructureGroupSelected(group: ProgramGroup): boolean {
    return (
      this.selectedStructureGroupIDs != null &&
      this.selectedStructureGroupIDs.includes(group.id)
    )
  }

  isFolderStructureSelected(structureId: string): boolean {
    return !this.isDesign || this.showDialogClone
      ? this.isStructureSelected(structureId)
      : this.folderModeStructureIds.includes(structureId)
  }

  isStructureSelected(structureId: string): boolean {
    return (
      this.selectedStructureIDs != null &&
      this.selectedStructureIDs.includes(structureId)
    )
  }

  isCompareViewSelected(compareView: CompareView): boolean {
    return this.selectedCompareView
      ? this.selectedCompareView.id === compareView.id
      : false
  }

  getScenarios(structure: Program): Program[] {
    const ids = structure.scenarioIDs ?? []
    return rejectNil(ids.map(id => this._structures?.find(s => s.id === id)))
  }

  getScenariosSelected(structure: Program): Record<string, boolean> {
    const ids = structure.scenarioIDs ?? []
    return ids.reduce(
      (acc, id) => {
        if (this.selectedStructureIDs?.includes(id)) {
          acc[id] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }

  getOptimizations(structure: Program): Program[] {
    const ids = structure.optimizationIDs ?? []
    return rejectNil(ids.map(id => this._structures?.find(s => s.id === id)))
  }

  getOptimizationsSelected(structure: Program): Record<string, boolean> {
    const ids = structure.optimizationIDs ?? []
    return ids.reduce(
      (acc, id) => {
        if (this.selectedStructureIDs?.includes(id)) {
          acc[id] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }

  indicateFocus(section: CursorSection, id: string): boolean {
    return this.cursorSection === section && this.cursor[section].id === id
  }

  onSetRoute(page: string, program: Program): void {
    this.setRoute.emit({
      page,
      program,
    })
  }

  onCreateNewFolder(): void {
    this.dialog
      .open(NewFolderDialogContainerComponent)
      .afterClosed()
      .subscribe(() => {
        this.onUpdateFolders()
      })
  }

  onUpdateFolders(): void {
    if (this.selectedProgramID) {
      this.updateFolders.emit(this.selectedProgramID)
    }
  }

  toggleStructureSelection(structure: Program, status: boolean): void {
    if (status) {
      if (
        this.selectedStructureIDs &&
        !this.selectedStructureIDs.includes(structure.id)
      ) {
        this.store.dispatch(
          updateCheckedPrograms({ checkedItemIds: [structure] })
        )
        this.structureSelectionChange.emit(structure)
      }
    } else {
      this.store.dispatch(removedCheckedPrograms({ programs: [structure] }))
      this.structureSelectionChange.emit(structure)
    }
  }

  onUpdateSelectAll(event: MatCheckboxChange): void {
    this.selectAllSelected = event.checked
    for (const structure of this.filterStructures) {
      this.toggleStructureSelection(structure, event.checked)
    }
  }

  onStructureSelectionChange(structure: Program): void {
    this.folderModeStructureIds = []
    this.structureSelectionChange.emit(structure)
  }

  onCreditStructureSelectionChange(structure: CreditStructure): void {
    this.creditStructureSelectionChange.emit(structure)
  }

  onCreditStructureOptimizeClick(structure: CreditStructure): void {
    this.creditStructureOptimize.emit(structure)
  }

  onCreditSubmissionSelectionChange(
    submission: CreditSubmissionStructure
  ): void {
    this.creditSubmissionSelectionChange.emit(submission)
  }

  onEditClick(structure: Program): void {
    const data: SetNameDialogData = {
      actionName: 'Edit',
      entityName: 'Name & Description',
      otherNames: this.structures
        ?.map(s => s.label)
        .filter(n => n !== structure.label),
      currentName: structure.label,
      currentDescription: structure.description,
      showDescription: true,
    }
    this.dialog
      .open(SetNameDialogComponent, { width: '30vw', data })
      .afterClosed()
      .pipe(
        filter<SetNameDialogResult>(res => !!res),
        map(({ name, description }) => ({ structure, name, description }))
      )
      .subscribe(props => this.structureNameDescriptionEdit.emit(props))
  }

  onStructureGroupSelectionChange(
    $event: MouseEvent | TouchEvent,
    group: ProgramGroup
  ): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.structureGroupSelectionChange.emit(group)
  }

  onCompareViewSelectionChange(
    $event: MouseEvent | TouchEvent,
    compareView: CompareView
  ): void {
    $event.preventDefault()
    $event.stopPropagation()
    const toRemoveStructures: Program[] = []
    const toRemoveGroups: ProgramGroup[] = []

    this.selectedCompareView =
      this.selectedCompareView?.id === compareView?.id ? null : compareView

    this.structures?.map(structure => {
      if (this.selectedStructureIDs?.includes(structure.id)) {
        toRemoveStructures.push(structure)
      }
    })
    this.structureGroups?.map(group => {
      if (this.selectedStructureIDs?.includes(group.id)) {
        toRemoveGroups.push(group)
      }
    })

    toRemoveStructures.map(structure =>
      this.structureSelectionChange.emit(structure)
    )
    toRemoveGroups.map(group => this.structureGroupSelectionChange.emit(group))

    const groups =
      compareView.groups ||
      this.structureGroups?.filter(g =>
        compareView.group_ids?.includes(g.id)
      ) ||
      []
    groups?.forEach(group => this.structureGroupSelectionChange.emit(group))

    let structures: Program[]
    if (compareView.structures && compareView.structures.length !== 0) {
      structures = compareView.structures
    } else {
      structures =
        this.structures?.filter(s => {
          return compareView.structure_ids
            ?.map(x => x.toString())
            .includes(s.id)
        }) || []
    }
    structures?.forEach(structure =>
      this.structureSelectionChange.emit(structure)
    )
    this.compareViewSelectionChange.emit(compareView)
  }

  trackByID(index: number, item?: { id: string }): string {
    return item?.id ?? (index as unknown as string)
  }

  onFilterUpdate(value: string, $event?: MouseEvent | TouchEvent): void {
    if ($event) {
      $event.preventDefault()
      $event.stopPropagation()
    }
    this.filter = value
    this.structureFilterChange.emit(value)
    this.updateFilter()
  }

  onFilterClear($event: MouseEvent | KeyboardEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.filterInput?.clear()
  }

  onNavKeydown(key: NavKey): void {
    switch (key) {
      case 'up':
        return this.moveCursor(-1)
      case 'down':
        return this.moveCursor(1)
      case 'enter':
        return this.selectCursor()
      case 'tab':
        return this.moveCursorSection()
    }
  }

  onItemMouseover(section: CursorSection, index: number): void {
    this.cursorSection = section
    this.cursor[section].id = this.cursorList[index]?.id ?? ''
    this.cursor[section].index = index
  }

  onGroupAssocChange() {
    this.groupFilterByAssocChange.emit(!this.groupFilterByAssoc)
  }

  onDeleteFolder(event: {
    folderID: string
    programIDs: (string | number)[]
  }): void {
    const { folderID, programIDs } = event
    this.updatedFolders = this.updatedFolders.filter(f => f.id !== folderID)
    this.folders = this.updatedFolders
    for (const programID of programIDs) {
      const structureId = String(programID)
      this.updateStructureFolderID.emit({
        structureId,
        folderID: null,
      })
    }
    this.selectFolderID(null, null)
    this.folderSections = this.getTotalFolders()
    this.updateFilter()
  }

  onRemoveFromFolder(event: { structureId: string }): void {
    const { structureId } = event
    for (const folder of this.updatedFolders) {
      if (folder.programIDs.includes(structureId)) {
        const updatedProgramIDs = folder.programIDs.filter(
          p => p !== structureId
        )
        this.updatedFolders = this.updatedFolders.map(f => {
          let programIDs = f.programIDs
          if (f.id === folder.id) {
            programIDs = updatedProgramIDs
          }
          return {
            ...folder,
            programIDs,
          }
        })
      }
    }
    this.updateStructureFolderID.emit({
      structureId,
      folderID: null,
    })
  }

  dropFolder(event: CdkDragDrop<string[]>): void {
    if (!this.mousePosition) {
      return
    }
    const { x, y } = this.mousePosition
    let folder = document.elementFromPoint(x, y)?.parentElement?.parentElement
    for (let i = 0; i < 3 && folder && !folder.id; i++) {
      folder = folder.parentElement
    }
    const folderId = folder?.id
    if (
      event.previousContainer.id === event.container.id ||
      !folder ||
      !folderId ||
      event.item.data.folderID === folderId
    ) {
      return
    }
    if (this.folderModeStructureIds.length > 0 && folder?.id) {
      this.folderModeStructureIds.forEach(structureId =>
        this.updateStructureFolderData(structureId, folderId)
      )
    } else {
      this.updateStructureFolderData(event.item.data.id, folderId)
    }
    this.updateFilter()
    this.organizeUpdatedFolders()
    this.folderModeStructureIds = []
  }

  updateStructureFolderData(structureId: string, folderID: string): void {
    const data = { structureId, folderID }
    this.updateStructureFolderID.emit(data)
  }

  dropStructure(_drop: CdkDragDrop<Program>): void {
    // Indices and filterStructure positions are updated in dragEntered()
    if (!this.selectMultiple && this.sourceIndex !== this.targetIndex) {
      // tslint:disable-next-line: no-non-null-assertion
      let id: number
      if (this.sourceIndex === this.targetIndex) {
        // tslint:disable-next-line: no-non-null-assertion
        id = this.filterStructures[this.targetIndex].position_index!
      } else {
        if (this.targetIndex === 0) {
          // tslint:disable-next-line: no-non-null-assertion
          id = this.filterStructures[this.targetIndex + 1].position_index! - 1
        } else if (this.targetIndex === this.filterStructures.length - 1) {
          // tslint:disable-next-line: no-non-null-assertion
          id = this.filterStructures[this.targetIndex - 1].position_index! + 1
        } else {
          const previousIndex =
            // tslint:disable-next-line: no-non-null-assertion
            this.filterStructures[this.targetIndex - 1].position_index!
          const nextIndex =
            // tslint:disable-next-line: no-non-null-assertion
            this.filterStructures[this.targetIndex + 1].position_index!
          id = (nextIndex - previousIndex) / 2 + previousIndex
        }
      }
      this.structureIndexEdit.emit({
        structure: this.filterStructures[this.targetIndex],
        index: id === 0 ? 0.1 : id,
      })
    }
  }

  onUpdateEditModeSelection(event: any): void {
    this.editModeSelected = event.checked
    this.updateFilter()
  }

  findIndexOf(collection: HTMLCollection, node: HTMLElement) {
    return Array.prototype.indexOf.call(collection, node)
  }

  isInsideDropListClientRect(
    dropList: CdkDropList,
    x: number,
    y: number
  ): boolean {
    const { top, bottom, left, right } =
      dropList.element.nativeElement.getBoundingClientRect()
    return y >= top && y <= bottom && x >= left && x <= right
  }

  dragMoved(e: CdkDragMove<any>): void {
    const point = this.getPointerPositionOnPage(e.event)

    this.listGroup._items.forEach(dropList => {
      if (this.isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList
        return
      }
    })
  }

  isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch')
  }

  getPointerPositionOnPage(event: MouseEvent | TouchEvent): {
    x: number
    y: number
  } {
    const point = this.isTouchEvent(event)
      ? event.touches[0] || event.changedTouches[0]
      : event
    const scrollPosition = this.viewportRuler.getViewportScrollPosition()
    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    }
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
    if (drop === this.placeholder) {
      return true
    }

    if (drop !== this.activeContainer) {
      return false
    }

    const phElement = this.placeholder.element.nativeElement
    const sourceElement = drag.dropContainer.element.nativeElement
    const dropElement = drop.element.nativeElement

    const dragIndex = this.findIndexOf(
      // tslint:disable-next-line: no-non-null-assertion
      dropElement.parentElement!.children,
      this.source ? phElement : sourceElement
    )
    const dropIndex = this.findIndexOf(
      // tslint:disable-next-line: no-non-null-assertion
      dropElement.parentElement!.children,
      dropElement
    )

    if (!this.source) {
      this.sourceIndex = dragIndex
      this.source = drag.dropContainer

      phElement.style.width = sourceElement.clientWidth + 'px'
      phElement.style.height = sourceElement.clientHeight + 'px'

      // tslint:disable-next-line: no-non-null-assertion
      sourceElement.parentElement!.removeChild(sourceElement)
    }

    this.targetIndex = dropIndex
    this.target = drop

    phElement.style.display = ''
    // tslint:disable-next-line: no-non-null-assertion
    dropElement.parentElement!.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    )

    this.placeholder._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    )
    return false
  }

  private get cursorList(): { id: string }[] {
    return this.cursorSection === 'structureGroups'
      ? this.filterStructureGroups
      : this.cursorSection === 'compareViews'
        ? this.compareViews
          ? this.compareViews
          : []
        : this.filterStructures
  }

  private getAllMembers(
    groupID: string,
    groupMembers: ProgramGroupMember[]
  ): string[] {
    const filteredMembers = groupMembers.filter(
      g => g.parentGroupID === groupID
    )
    const members: string[] = []
    for (const member of filteredMembers) {
      if (member.type === 'programGroup') {
        members.push(
          ...this.getAllMembers(member.programGroupID ?? '', groupMembers)
        )
      } else {
        members.push(member.programID ?? '')
      }
    }
    return uniq(members)
  }

  private filterGroupByProgram(
    groupID: string,
    programID: string,
    groupMembers: ProgramGroupMember[]
  ): boolean {
    const members = this.getAllMembers(groupID, groupMembers)
    const memberEntities = members.map(m =>
      (this.structures || []).find(s => s.id === m)
    )
    return memberEntities.some(m => m?.studyID === programID)
  }

  private updateFilter(): void {
    const _filter = this.makeFilter(this.filter)

    this.filterStructures = _filter(this.structures ?? []).sort((a, b) => {
      // tslint:disable-next-line: no-non-null-assertion
      return a.position_index! - b.position_index!
    })
    if (this.showDialogClone) {
      this.filterStructures = this.filterStructures.filter(
        structure =>
          structure.grossPortfolioID !==
            '38d15d98-f7f3-4ea2-9791-4141717d0f33' &&
          structure.cededPortfolioID !==
            '7fa05c2a-e13a-4504-9cf1-1d45aecc5ea6' &&
          structure.netPortfolioID !== '0e8efe9e-995f-458f-bcea-0ac9f7741e9d'
      )
    }
    // Hide libre structures on the pages that show the structure cards on a popover control
    // if (this.elevation) {
    //   this.filterStructures = this.filterStructures.filter(s => !s.libRE)
    // }

    const filterByGroupAssoc =
      this.structureGroups &&
      this.structureGroups.length > 0 &&
      this.groupFilterByAssoc &&
      this.structures &&
      this.structures.length > 0

    let groups = [...(this.structureGroups ?? [])]
    if (filterByGroupAssoc) {
      groups = groups.filter(g =>
        this.filterGroupByProgram(
          g.id,
          this.selectedProgramID ?? '',
          this.structureGroupMembers
        )
      )
    }
    this.filterStructureGroups = _filter(groups).filter(
      g => this.selectedYearID && this.selectedYearID === g.yearID
    )
    this.updateCursor()
    this.folderSections = this.getTotalFolders()
    this.slices = this.getSlices()
  }

  private get isMenuOpen(): boolean {
    return (
      document.getElementsByClassName('app-menu').length > 0 ||
      document.getElementsByClassName(checkboxSelectClass).length > 0
    )
  }

  private moveCursorSection(n = 1): void {
    if (this.isMenuOpen || this.isQuote || this.isSignature) {
      return
    }

    let sections = [...cursorSections]
    if (!this.showStructureGroups) {
      sections = sections.filter(s => s !== 'structureGroups')
    }
    if (!this.showCompareViews) {
      sections = sections.filter(s => s !== 'compareViews')
    }
    const nextSectionIndex =
      (sections.indexOf(this.cursorSection) + n) % sections.length
    this.cursorSection = sections[nextSectionIndex]
  }

  private moveCursor(n: number): void {
    if (this.isMenuOpen || this.isQuote || this.isSignature) {
      return
    }

    const section = this.cursorSection
    const prevIndex = this.cursor[this.cursorSection].index
    const nextIndex = prevIndex + n
    if (nextIndex < 0) {
      this.cursor[section].index = this.cursorList.length + nextIndex
    } else if (nextIndex > this.cursorList.length - 1) {
      this.cursor[section].index = nextIndex - this.cursorList.length
    } else {
      this.cursor[section].index = nextIndex
    }

    this.cursor[section].id = this.cursorList[this.cursor[section].index].id

    const elements =
      section === 'structureGroups'
        ? this.structureGroupItems
        : this.structureItems
    elements?.forEach((el, i) => {
      if (i === this.cursor[section].index) {
        const _el = el.nativeElement as HTMLDivElement
        if (_el) {
          _el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    })
  }

  private updateCursor(): void {
    // Initializes if not already
    this.moveCursorSection(0)
    const item = this.cursorList.find(
      it => it.id === this.cursor[this.cursorSection]?.id
    )
    if (!item) {
      this.cursor[this.cursorSection].id = this.cursorList[0]?.id ?? ''
      this.cursor[this.cursorSection].index = 0
    }
  }

  private getSlices() {
    if (window.matchMedia('all and (min-width: 1660px)').matches) {
      return 3
    } else if (window.matchMedia('all and (min-width: 1080px)').matches) {
      return 2
    } else {
      return 1
    }
  }

  // organizeFilterStructures() {
  //   if (this.structures) {
  //     let organizedFilterStructures: Program[]
  //     if (!this.selectedFolderID) {
  //       organizedFilterStructures = this.filterStructures
  //         .filter(fs => !fs.folderID)
  //         .sort((a, b) => {
  //           // tslint:disable-next-line: no-non-null-assertion
  //           return a.position_index! - b.position_index!
  //         })
  //     } else {
  //       organizedFilterStructures = this.filterStructures
  //         .filter(fs => fs.folderID === this.selectedFolderID)
  //         .sort((a, b) => {
  //           // tslint:disable-next-line: no-non-null-assertion
  //           return a.position_index! - b.position_index!
  //         })
  //     }
  //     this.filterStructures = organizedFilterStructures
  //   }
  // }

  organizeUpdatedFolders(id?: string) {
    const folderID = id ?? this.selectedFolderID
    if (!folderID) {
      this.populateFolders()
    } else {
      this.updatedFolders = []
    }
  }

  getTotalFolders(): Folder[][] {
    const slices = this.getSlices()
    return splitEvery(slices, this.updatedFolders)
  }

  getQuoteSummary(): void {
    const type = this.quoteLayers.find(
      l => l.layer.id === this.selectedCededLayerID
    )?.layer.meta_data.sage_layer_type
    if (!type) {
      return
    }
    let weightedRol = 0
    let weightedRateOnLineSubject = 0
    let offeredPercentage = 0
    let weightedCedingCommission = 0
    let weightedPmpm = 0
    for (const { quoteFields, decline } of Object.values(
      this.currentReinsurerMap
    )) {
      if (!quoteFields || decline) {
        continue
      }
      const offeredPctg = quoteFields?.quoteOfferedPercentage ?? 0
      const rol = quoteFields.quoteRolPercentage ?? 0
      const rateOnLineSubject = quoteFields.quoteRateOnLineSubject ?? 0
      const cedingCommission = quoteFields.quoteCedingCommission ?? 0
      const pmpm = quoteFields.quotePmpm ?? 0
      weightedRol += rol * offeredPctg
      weightedRateOnLineSubject += rateOnLineSubject * offeredPctg
      weightedCedingCommission += cedingCommission * offeredPctg
      weightedPmpm += pmpm * offeredPctg
      offeredPercentage += offeredPctg
    }
    const denominator = offeredPercentage !== 0 ? offeredPercentage : 1
    const weightedAvgRateOnLine = isROLLayer(type)
      ? (weightedRol / denominator) * 100
      : undefined
    const weightedAvgRatePercentageOfSubject = this.isRateSubjectLayer(type)
      ? (weightedRateOnLineSubject / denominator) * 100
      : undefined
    const weightedAvgCedingCommission = isQSLayer(type)
      ? (weightedCedingCommission / denominator) * 100
      : undefined
    const weightedAvgPMPM = isAHLLayer(type)
      ? (weightedPmpm / denominator) * 100
      : undefined

    this.quoteSummary = {
      offeredPercentageTotal: offeredPercentage * 100,
      weightedAvgRateOnLine,
      weightedAvgRatePercentageOfSubject,
      weightedAvgCedingCommission,
      weightedAvgPMPM,
    }
    this.showWeightedAvgRateOnLine = typeof weightedAvgRateOnLine === 'number'
    this.showWeightedAvgRatePercentageOfSubject =
      typeof weightedAvgRatePercentageOfSubject === 'number'
    this.showWeightedAvgCedingCommission =
      typeof weightedAvgCedingCommission === 'number'
    this.showWeightedAvgPMPM = typeof weightedAvgPMPM === 'number'
  }

  isRateSubjectLayer(id: string): boolean {
    return (
      id === layerIds.noncatXl ||
      id === layerIds.noncatIndxl ||
      id === layerIds.noncatRisk ||
      id === layerIds.noncatSwing ||
      id === layerIds.catXl
    )
  }

  private selectCursor(): void {
    if (this.isMenuOpen || this.isQuote || this.isSignature) {
      return
    }

    const id = this.cursor[this.cursorSection].id
    if (this.cursorSection === 'structureGroups') {
      const item = this.filterStructureGroups.find(it => it.id === id)
      if (item) {
        this.structureGroupSelectionChange.emit(item)
      }
    }
    if (this.cursorSection === 'structures') {
      const item = this.filterStructures.find(it => it.id === id)
      if (item) {
        this.structureSelectionChange.emit(item)
      }
    }
  }

  preventDefault($event: MouseEvent | TouchEvent): boolean {
    $event.preventDefault()
    $event.stopPropagation()
    return false
  }

  buildDialogData(): TierStructuresDialogConfig {
    return {
      analysisProfileID: null,
      yearID: this.yearID,
      years: this.years,
      years2: this.years2,
      structureGroups: this._structureGroups,
      structures: this._structures,
      structures2: this._structures2,
      selectedYearID: this._selectedYearID,
      selectedYearID2: this._selectedYearID2,
      selectedClientID: this.selectedClientID,
      selectedClientID2: this.selectedClientID2,
      selectedStructureGroupIDs: this.selectedStructureGroupIDs,
      selectedStructureIDs: this._selectedStructureIDs,
      selectedStructures: this._selectedStructures,
      allowScenarioOrOptimizationSelection:
        this._allowScenarioOrOptimizationSelection,
      contextPath: this.contextPath,
      structureFilter: this.structureFilter,
      structureGroupMembers: this.structureGroupMembers,
      groupFilterByAssoc: this._groupFilterByAssoc,
      studyID: this.selectedProgramID,
      studyID2: this.selectedProgramID2,
      showDialogClone: true,
      programs: this.programs,
      programs2: this.programs2,
      studies: this.studies,
      featureFlags: this.featureFlags,
      routerUrl: this.routerUrl,
    }
  }

  onCreateStructureClick(): void {
    if (this.libreTemplate) {
      const libreTemplate = {
        ...this.libreTemplate,
        folderID: this.selectedFolderID,
      }
      this.structureSelectionChange.emit(libreTemplate)
    }
  }

  onBulkCloneClick(): void {
    this.showDialogFlag.emit()
    this.showDialogClone = true
    this.store.dispatch(fromBrokerActions.clearCheckedPrograms())
    const data: TierStructuresDialogConfig = {
      triggerRef: this.elementRef,
      leftOffset: -16,
      topOffset: -12,
      panelClass: PANEL_CLASS,
      ...this.buildDialogData(),
    }
    this.dialog.open(TierStructuresDialogComponent, {
      id: PANEL_CLASS,
      panelClass: PANEL_CLASS,
      data,
      height: '70vh',
      maxWidth: 'calc(100vw - 2 * var(--inset-big))',
      width: 'calc(100vw - 2 * var(--inset-big)) !important',
      disableClose: true,
    })
    this.folderModeStructureIds = []
  }

  getOpportunityRenewedFrom(): string | undefined {
    let opportunityId: string | undefined
    if (this.programs) {
      opportunityId = this.programs.find(
        p => p.id === this.selectedProgramID
      )?.opportunity_id
    }

    if (isNil(opportunityId)) {
      return undefined
    }

    const selectedOpportunity = this.accountOpportunities?.find(
      ao => ao.oppId === opportunityId
    )

    return selectedOpportunity?.opportunityRenewedFrom as string
  }

  onExecutiveSummaryClick(): void {
    const files = this.clientData.split('|')
    for (const file of files) {
      this.backendService
        .getExecutiveSummaries(file)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          window.open(window.URL.createObjectURL(data), '_blank')
        })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onAutobuildClick(): void {
    const salesForce = this.getOpportunityRenewedFrom()
    const clientId = this.selectedClientID as string
    const yearId = this.selectedYearID as string
    const programId = this.selectedProgramID as string

    if (
      !this.libreTemplate ||
      isNil(salesForce) ||
      isNil(clientId) ||
      isNil(yearId) ||
      isNil(programId)
    ) {
      return
    }

    this.dialog
      .open(DesignFromOTDialogComponent, {
        width: '80%',
        data: {
          renewedFromOpportunityId: salesForce,
          selectedClientID: this.selectedClientID,
          selectedYearId: this.selectedYearID,
          selectedProgramID: this.selectedProgramID,
        },
      })
      .afterClosed()
      .pipe(filter(result => (result ? result.event === 'import' : false)))
      .subscribe(result => {
        // auto build service needs an array of OT sections - get them out from children of layerRows
        const allSections: RiskSectionWithMarkets[] = []
        for (const layer of result.selectedLayers) {
          for (const section of layer.sections) {
            section.selectedStructure = layer.selectedStructure // assign structure # to sections
            section.selectedLayer = layer.selectedLayer // assign possibly changed layer type to sections
            delete section.largestSectionSignedPct
            delete section.largestSectionSignedPctReinsurer
            delete section.sections
            allSections.push(section)
          }
        }
        // Group the OT sections into structures
        const structuresSection =
          this.autoBuildService.createStructuresFromOTSections(
            allSections,
            result.defaultCessionPct
          )
        // Group the section nums per risk ref
        const riskRefSections =
          this.autoBuildService.groupSectionsPerRiskRef(allSections)

        // Let's create those structures in Sage
        this.autoBuildService.createStructures(
          clientId,
          yearId,
          programId,
          this.libreTemplate as Program,
          structuresSection,
          generateUUID(),
          riskRefSections
        )
      })
  }

  private makeFilter =
    (query?: string) =>
    <
      T extends {
        isScenario?: boolean
        isOptimization?: boolean
        label: string
        description?: string
        folderID?: string | null
      },
    >(
      items: T[]
    ) => {
      const organizedItems = this.isDesign
        ? this.selectedFolderID
          ? items.filter(it => this.selectedFolderID === it.folderID)
          : items.filter(it => !it.folderID)
        : items

      const nonScenarioOrOptimization = organizedItems.filter(
        it => !it.isScenario && !it.isOptimization
      )
      if (query) {
        const q = query?.toLowerCase()
        return nonScenarioOrOptimization.filter(
          it =>
            it.label.toLowerCase().includes(q) ||
            it.description?.toLowerCase().includes(q)
        )
      }
      return nonScenarioOrOptimization
    }
}

const initCursor = (): Cursor =>
  cursorSections.reduce(
    (acc, section) => ({ ...acc, [section]: { id: '', index: 0 } }),
    {} as Cursor
  )
