import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  ClientSearch,
  CreditSubmissionAuthorizationRequest,
  CreditSubmissionAuthorizeQuoteDialogData,
  CreditSubmissionStructure,
  CreditSubmissionUserTranche,
} from '../../model/credit-submission.model'
import { CreditCalculationStructure } from '../../model/credit-structure.model'

export const CreditSubmissionStructureActions = createActionGroup({
  source: 'Credit Submission Structure',
  events: {
    'Reset Selected Submission': emptyProps(),
    'Set Selected Filters': props<{ filters: ClientSearch }>(),
    'Get Submission Structures Failure': props<{ error: ApplicationError }>(),
    'Get Submission Structures Success': props<{
      structures: CreditSubmissionStructure[]
    }>(),
    'Set Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Update Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Reinitialize Tower Submission': props<{ structureId: string }>(),
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditSubmissionStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Put: props<{ structure: CreditCalculationStructure }>(),
    'Put Success': props<{ structure: CreditSubmissionStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
    'Authorize Quote': props<{ authorization:  CreditSubmissionAuthorizeQuoteDialogData }>(),
    'Authorize Quote Success': props<{ authorization:  CreditSubmissionAuthorizationRequest }>(),
    'Authorize Quote Failure': props<{ error: ApplicationError }>(),
  },
})

export const CreditSubmissionUserTrancheActions = createActionGroup({
  source: 'Credit Submission User Tranche',
  events: {
    Fetch: props<{ submissionId: number }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    'Fetch Success': props<{ tranches: CreditSubmissionUserTranche[] }>(),
    Update: props<{ tranche: CreditSubmissionUserTranche }>(),
    Save: emptyProps(),
    'Save Failure': props<{ error: ApplicationError }>(),
    'Save Success': props<{ tranches: CreditSubmissionUserTranche[] }>(),
  },
})
