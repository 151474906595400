import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export interface Reinsurer {
  id: number
  name: string
  bannerImageSrc: string
  is_default: boolean
  reinsurerProgramFactor: ProgramFactor[]
  weight: number
  domicile: string | null
  fein_num: string | null
  aain_num: string | null
  sp_rating: string | null
  am_best_rating: string | null
  policy_holder: number
  currency: string | null
  country_code: string | null
  am_best_outlook: string | null
  sp_outlook: string | null
  naic_num: string | null
  lors_num: string | null
  approval_status: string | null
  market_security_grade: string | null
  market_security_restrictions: string | null
  policy_holder_date: Date | null
  pipelinename: string | null
  audit_insert_dt: Date | null
  audit_update_dt: Date | null
  market_use: string | null
  tpRef: string | null
  doNotUseInd: boolean
  internalNarr: string | null
  totalEquity: number | null
  periodEndDateTotEquity: Date | null
  del_ind: boolean
  del_ind_update_dt: Date | null
  reinsuranceApprovalFromDate: string | null
  reinsuranceApprovalToDate: string | null
  internationalReinsuranceApprovalFromDate: string | null
  internationalReinsuranceApprovalToDate: string | null
  directApprovalFromDate: string | null
  directApprovalToDate: string | null
  reinsuranceApprovalStatus: string | null
  internationalReinsuranceApprovalStatus: string | null
  reinsuranceApprovedFor: string | null
  internationalReinsuranceApprovedFor: string | null
  companyPapers: AgencyDetails[]
  selectedCompanyPapers: AgencyDetails[]
  segregatedAccounts: AgencyDetails[]
  selectedSegregatedAccounts: AgencyDetails[]
  ratingDate: string | null
  outlookDate: string | null
  lastReviewDate: string | null
  amBestRatingDate: string | null
}

export interface ProgramFactor {
  id?: number
  carrier_id: number
  study_id: number
  reinsurer_id: number
  is_default: boolean
  incumbent: boolean
  target_market: boolean
  expected_loss_multiplier: number
  elm_selected: boolean
  ceded_margin_percentage: number
  cmp_selected: boolean
  std_dev_percentage: number
  market_pricing_factor: number
  stp_selected: boolean
  notes: string | null
  key_uw_contact: string | null
  appetite: string | null
  is_new_relation: boolean | null
  relation_seq_number: number | null
  obo_name: string | null
  placed_through: string | null
  co_broker: string | null
  co_broker_ref: string | null
  volatility_metric: string | null
  expected_loss_power: number
  volatility_multiplier: number
  fixed_cost: number
  minimum_rate_on_line: number
  reinsurer_margin_percentage: number
  max_ceding_commission_percentage: number
  segregated_account?: boolean
  fund_mgr_unknown?: boolean
}

export interface AgencyDetails {
  agencyTPRef: string | null
  agencySeqNumber: number
  agencyName: string
  memberTPRef: string | null
  memberName: string | null
  memberSeqNum: number
  effectiveDate: Date | null
  expiryDate: Date | null
  memberPct: number
  burxStamp: string | null
  burxTypeCodeValue: string | null
  lorsCode: string | null
  type: string | null
  doNotUseInd: boolean
  internalNarr: string | null
  approvalStatusCodeValue: string | null
  agencyFromSageInd?: boolean
  ilsName: string | null
  ilsInternalNarr: string | null
  ilsInfo: string | null
  ratingDate: string | null
  outlookDate: string | null
  lastReviewDate: string | null
  amBestRatingDate: string | null
}

export const FUND_MANAGER_DIALOG_COLUMNS: SortTableColumnDef[] = [
  {
    label: '',
    id: 'selected',
    valueType: 'checkbox',
    editable: true,
  },
  {
    label: 'Agency Name',
    id: 'agencyName',
    minWidth: '25rem',
  },
  {
    label: 'Agency Trading Partner Number',
    id: 'agencyTPRef',
    minWidth: '15rem',
  },
  {
    label: 'ILS Internal Narrative',
    id: 'ilsInternalNarr',
    minWidth: '15rem',
  },
  {
    label: 'ILS Name',
    id: 'ilsName',
    minWidth: '25rem',
  },
]
export interface FundManagerDialogDatum {
  id: string
  agencyName: string
  agencyTPRef: string
  ilsInternalNarr: string
  ilsName: string
  selected: boolean
}
export interface FundManager {
  fundManagerTpRef: string
  companyPapers?: CompanyPaperAgencySeqNum[]
  segregatedAccounts?: string[]
  programID: string
}

export interface CompanyPaperAgencySeqNum {
  companyPaper: string | null
  agencySeqNumber: number | null
}

export interface AgencyBridge {
  agencyTPRef: string | null
  agencySeqNumberSAGE: number | null
  agencySeqNumber: number | null
  logicalDeleteInd: boolean
  audit_user_name: string | null
  audit_insert_dt: Date | null
  audit_update_dt: Date | null
}

export interface CreateAgencyBridgeOTRequest {
  agencyTPRef: string | null
  agencySeqNumberSAGE: number | null
}

export interface CreateAgencyResponse {
  tpRef: string
  newAgencySeqNum: number
}

export interface AgencyDetailsSage {
  agencyTPRef: string | null
  agencySeqNumberSAGE: number
  agencyName: string
  memberTPRef: string | null
  memberName: string | null
  memberSeqNum: number
  effectiveDate: Date | null
  expiryDate: Date | null
  memberPct: number
  burxStamp: string | null
  burxTypeCodeValue: string | null
  lorsCode: string | null
  type: string | null
  doNotUseInd: boolean
  internalNarr: string | null
  approvalStatusCodeValue: string | null
}

export interface AgencyCreationRequest {
  agencyDetails: AgencyDetailsSage[]
  programId: string | undefined
}

export interface ReinsurerPriorYearView {
  SFOpportunityID: string | null
  riskRef: string | null
  interestNarrative: string | null
  sectionNum: number
  layerName: string | null
  marketTpRef: string | null
  signedPct: number
  writtenPct: number
  totalSignedPct: number
  reinsurerName: string | null
  memberName: string | null
  memberPct: number | null
  effectiveDateAndTime?: string | null
  expiryDateAndTime?: string | null
  notePad?: string
  annualAggregateDeductible?: number
  premiumAdjRate?: number
  riskLimit?: number
  occurrenceLimit?: number
  occurrenceAttachment?: number
  initialPremiumAmount?: number
  minimumPremiumAmount?: number
  maximumLiability?: number
  unlimitedLiabilityIndicator?: string
  reinstatementsFull: string
  baseCurrencyCode: string
}

export interface ReinsurerLayer extends Reinsurer {
  percentage: number
  value: number
}

export interface ReinsurerLayerString {
  percentage: number
  value: number
  id: number
  name: string
}

export interface Appetite {
  contact: string
  type: string
  appetite: AppetiteColor
}

export interface ReinsurerFilter {
  column: string
  value: string
}

export interface SelectedUserPreferences {
  id?: number
  preferenceValue: string
  preferenceType: string
  programId?: string
  userId?: string
}

export interface CustomDialogData {
  inceptionDate: string
  expireDate: string
  occLimit: string
  riskLimit: string
  aggLimit: string
  excess: string
  aggAttachment: string
  notes: string
  reinstatements: string
  deposit: string
  minimum: string
  adjustRate: string
  currency: string
}
export const EXPORT_DIALOG_DATA: CustomDialogData = {
  inceptionDate: 'Inception Date',
  expireDate: 'Expiration Date',
  currency: 'Currency',
  occLimit: 'OCC Limit',
  riskLimit: 'Risk Limit',
  aggLimit: 'AGG Limit',
  excess: 'Attachment',
  aggAttachment: 'AGG Attachment(AAD)',
  reinstatements: 'Reinstatements',
  deposit: 'Deposit',
  minimum: 'Minimum',
  adjustRate: 'Adjustable Rate',
  notes: 'Notes',
}
export type AppetiteColor = 'Red' | 'Amber' | 'Green'
export const APPETITE_COLOR = ['Red', 'Amber', 'Green']

export const DOMICILE_COL = 'domicile'
export const SP_RATING_COL = 'sp_rating'
export const AM_BEST_RATING_COL = 'am_best_rating'
export const APPETITE_COL = 'appetite'
export const SURPLUS_COL = 'policy_holder'

export const LOW_SURPLUS_LABEL = '< 1B'
export const MED_SURPLUS_LABEL = '1-5B'
export const HIGH_SURPLUS_LABEL = '> 5B'

export const LOW_SURPLUS_VALUE = 1000000000
export const HIGH_SURPLUS_VALUE = 5000000000

export const LLOYDS_SYNDICATE_PREFIX = `Lloyd's Syndicate`

export const FUND_MANAGER_MARKET_USE = 'Fund_Manager'
export const AGENCY_MARKET_USE = 'Underwriting_Agency_MGA'
export const FUND_MANAGER_SEG_ACCOUNT_TEXT = 'in Respect of Segregated Account'
export const FUND_MANAGER_UNKNOWN_TEXT = 'Unknown'

export const LOCKTON_LLP_SHARE_DIVISOR = 3
export const LOCKTON_LLP_NAME = 'Lockton Re LLP'
export const SALESFORCE_US_BU = 'USA'
