<div class="selector" #tierBar>
  <div class="tier-bar-opportunity">
    <app-tier-bar-container
      contextPath="reinsurers"
    ></app-tier-bar-container>
    <app-program-opportunity-info
      [programs]="programs"
      [selectedProgramID]="selectedProgramID"
      [accountOpportunities]="accountOpportunities"
    ></app-program-opportunity-info>
  </div>
</div>
<div [ngStyle]="containerHeight">
  <app-reinsurer-list
    [clientID]="clientID"
    [yearID]="yearID"
    [studies]="studies"
    [studyID]="studyID"
    [reinsurers]="reinsurers"
    [reinsurersBlob]="reinsurersBlob"
    [reinsurerFilters]="reinsurerFilters"
    [reinsurerDomicileList]="reinsurerDomicileList"
    [reinsurerSPRatingList]="reinsurerSPRatingList"
    [reinsurerAmBestRatingList]="reinsurerAmBestRatingList"
    [metricCategories]="metricCategories"
    [metricDetails]="metricDetails"
    [clients]="clients"
    [programs]="programs"
    [accountOpportunities]="accountOpportunities"
    [reinsurerPopulateFrom]="reinsurerPopulateFrom"
    (setReinsurer)="setReinsurer.emit($event)"
    (showInfo)="showInfo.emit($event)"
    (updateOrAddDirty)="updateOrAddDirty.emit($event)"
    (reinsurerFilterToggle)="reinsurerFilterToggle.emit($event)"
    (removeAllFilters)="removeAllFilters.emit($event)"
    (removeFilter)="removeFilter.emit($event)"
    (populateSelectorClick)="populateSelectorClick.emit($event)"
    (setOneReinsurer)="setOneReinsurer.emit($event)"
    (showAgencyModal)="showAgencyModal.emit($event)"
    (showFundManagerModal)="showFundManagerModal.emit($event)"
    (deleteRe)="deleteRe.emit($event)"
  >
  </app-reinsurer-list>
</div>
