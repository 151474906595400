<div>
  <h1 mat-dialog-title>Data Availability</h1>

  <div mat-dialog-content>
    <!-- Table: Filers  -->
    <mat-spinner
      *ngIf="!dataRefreshStatus"
      [diameter]="20"
      color="accent"
    ></mat-spinner>
    <div *ngIf="dataRefreshStatus">
      <p class="table-title">{{ dataRefreshStatus.year }} Filers</p>
      <div class="table-container mat-elevation-z8">
        <table mat-table [dataSource]="filersDataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="approxCompaniesFiledCount">
            <th mat-header-cell *matHeaderCellDef># of Companies Filed</th>
            <td mat-cell *matCellDef="let element">
              {{ element.approxCompaniesFiledCount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approxTotalCompaniesCount">
            <th mat-header-cell *matHeaderCellDef># of Total Companies</th>
            <td mat-cell *matCellDef="let element">
              {{ element.approxTotalCompaniesCount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approxCompaniesFiledPercentage">
            <th mat-header-cell *matHeaderCellDef>% of Companies Filed</th>
            <!-- Cap filed percentage at 100% -->
            <td mat-cell *matCellDef="let element">
              {{
                (element.approxCompaniesFiledPercentage > 1
                  ? 1
                  : element.approxCompaniesFiledPercentage) | percent
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approxNpeCompaniesFiledPercentage">
            <th mat-header-cell *matHeaderCellDef>% of Companies With By-Line Underwriting Data</th>
            <!-- Cap filed percentage at 100% -->
            <td mat-cell *matCellDef="let element">
              {{
                (element.approxNpeCompaniesFiledPercentage > 1
                  ? 1
                  : element.approxNpeCompaniesFiledPercentage) | percent
              }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="filersColumns[0].actual; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: filersColumns[0].actual"
          ></tr>
        </table>
      </div>

      <p class="table-title key-dates-width">
        {{ dataRefreshStatus.year }} Key Dates (Estimated)
      </p>

      <!-- Table: Key Dates, shows year dynamically from API -->
      <div class="table-container mat-elevation-z8 key-dates-width">
        <table mat-table [dataSource]="keyDateDataSource">
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element.key }}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <tr
            mat-row
            *matRowDef="let row; columns: keyDateColumns[0].actual"
          ></tr>
        </table>
      </div>

      <p>
        Please note that individual and group data will be available on a
        rolling basis beginning on the dates listed above; some insurers may
        file after the deadlines, delaying availability.
      </p>
    </div>

    <div mat-dialog-actions class="actions">
      <button appButton accent (click)="close()">
        <span>Close</span>
      </button>
    </div>
  </div>
</div>
