import { createSelector } from '@ngrx/store'
import { selectQuoteState } from '../../store/quote.selectors'
import { IControl } from '../../../management-information/store/management-information.reducer'

export const selectQuoteMIState = createSelector(
  selectQuoteState,
  state => state.quoteManagementInformation
)

export const selectQuoteTabs = createSelector(
  selectQuoteMIState,
  state => state.tabs
)
export const selectActiveTab = createSelector(
  selectQuoteMIState,
  state => state.activeTab
)

export const selectQuoteMIAudienceView = createSelector(
  selectQuoteMIState,
  state => state.audienceView
)

export const selectQuoteMISelectors = createSelector(
  selectQuoteMIState,
  state => state.selectors
)
export const selectQuoteMILayerTypes = createSelector(
  selectQuoteMIState,
  state => state.selectors.layerType.selectedValues
)
export const selectQuoteMIFilters = createSelector(
  selectQuoteMIState,
  state => {
    const selectors: IControl[] = []
    if (!state.selectors) {
      return selectors
    }
    const entries: [string, IControl][] = Object.entries(state.selectors)
    for (const [_, selector] of entries) {
      if (selector.selectedValues?.length > 0) {
        selectors.push(selector)
      }
    }
    return selectors
  }
)

export const selectQuoteMILayerIntervals = createSelector(
  selectQuoteMIState,
  state => state.layerIntervals
)
export const selectQuoteMITimeIntervals = createSelector(
  selectQuoteMIState,
  state => state.timeIntervals
)
export const selectAllIntervals = createSelector(selectQuoteMIState, state => [
  ...state.layerIntervals,
  ...state.timeIntervals,
])

export const selectQuoteMISummaryData = createSelector(
  selectQuoteMIState,
  state => state.summaryData
)
export const selectQuoteMISummaryContent = createSelector(
  selectQuoteMIState,
  state => state.summaryContent
)

export const selectQuoteMIDetailsData = createSelector(
  selectQuoteMIState,
  state => state.detailsData
)
export const selectQuoteMIDetailsColumnsSettings = createSelector(
  selectQuoteMIState,
  state => state.detailsColumnsSettings
)
export const selectQuoteMIRankingData = createSelector(
  selectQuoteMIState,
  state => state.rankingData
)
export const selectQuoteMIRankingColumnsSettings = createSelector(
  selectQuoteMIState,
  state => state.rankingColumnsSettings
)
export const selectQuoteMIDataCounts = createSelector(
  selectQuoteMIState,
  state => state.dataCounts
)

export const selectLastRefreshDate = createSelector(
  selectQuoteMIState,
  state => state.lastRefreshDate
)
