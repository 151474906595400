import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MaybeError } from './../../api/model/api.model'
import { CreditStructure } from '../model/credit-structure.model'
import { BackendService } from '../../api/backend/backend.service'
import { CreditSubmissionStructure } from '../model/credit-submission.model'

@Injectable({
  providedIn: 'root',
})
export class CreditThumbnailService {
  constructor(private backendService: BackendService) {}

  saveCreditStructureThumbnail(
    portfolioId: string,
    structureId: string,
    imageData: Blob
  ): Observable<MaybeError> {
    const fileBits = [imageData]
    const fileName = this.getThumbnailFileName(portfolioId, structureId)
    const creditCalculationStructureImageFile = new File(fileBits, fileName)
    const fd = new FormData(document.forms[0])
    fd.append('credit', creditCalculationStructureImageFile)
    return this.backendService.postCreditThumbnail(fd)
  }

  addCreditStructureThumbnails(
    portfolioId: string,
    structures: CreditStructure[]
  ): CreditStructure[] {
    return structures.map(structure =>
      this.addCreditStructureThumbnail(portfolioId, structure)
    )
  }

  addCreditSubmissionStructureThumbnails(
    portfolioId: string,
    structures: CreditSubmissionStructure[]
  ): CreditSubmissionStructure[] {
    return structures.map(structure =>
      this.addCreditSubmissionStructureThumbnail(portfolioId, structure)
    )
  }

  addCreditStructureThumbnail(
    portfolioId: string,
    structure: CreditStructure
  ): CreditStructure {
    const fileName = this.getThumbnailFileName(
      portfolioId,
      structure.credit_calculation_structure_id
    )
    const imageSrc = `${fileName}.png`
    return { ...structure, imageSrc }
  }

  public addCreditSubmissionStructureThumbnail(
    portfolioId: string,
    structure: CreditSubmissionStructure
  ): CreditSubmissionStructure {
    const fileName = this.getThumbnailFileName(
      portfolioId,
      structure.credit_submission_structure_id
    )
    const imageSrc = `${fileName}.png`
    return { ...structure, imageSrc, portfolio_id: portfolioId }
  }

  private getThumbnailFileName(
    portfolioId: string,
    structureId: string
  ): string {
    return `credit_${portfolioId}_${structureId}`
  }
}
