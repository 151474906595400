import { mergeDeepRight, range } from 'ramda'
import { BenchmarkBuildChartProps } from './benchmark-chart-view'
import {
  BenchmarkControlConfig,
  BenchmarkControlType,
  BenchmarkGridItemState,
  BenchmarkSubmode,
} from './benchmark-submode'

export interface BenchmarkGridOptions {
  /**
   * Use extended grid submodes when true or if submode config has
   * matching `gridControls` type.
   */
  useGrid?: boolean | BenchmarkControlType
}

export function initializeBenchmarkGridItems(submode: BenchmarkSubmode) {
  const grid = [submode, ...(submode.grid ?? [])]
  return grid.reduce((acc, s, index) => {
    const key = buildBenchmarkSubmodeKey(submode.id, index)
    acc[index] = { index, key, show: !s.gridDeselect }
    return acc
  }, {} as Record<string, BenchmarkGridItemState>)
}

export const buildBenchmarkSubmodeKey = (
  submodeID: string,
  gridIndex = 0
): string => {
  let key = submodeID
  if (gridIndex > 0) {
    key += '.' + gridIndex
  }
  return key
}

export const getBenchmarkGridSubmodeKey = (
  controlType: BenchmarkControlType,
  submode: BenchmarkSubmode,
  gridIndex: number
): string => {
  if (useBenchmarkGridControl(submode, controlType, gridIndex)) {
    return buildBenchmarkSubmodeKey(submode.id, gridIndex)
  }
  return submode.id
}

export const getAllBenchmarkGridSubmodeKeys = (
  submode?: BenchmarkSubmode | null
) => {
  if (!submode) {
    return []
  }
  return (submode.grid ?? []).reduce(
    (keys, _, i) => {
      keys.push(buildBenchmarkSubmodeKey(submode.id, i + 1))
      return keys
    },
    [submode.id]
  )
}

export function buildBenchmarkGridSubmode(
  props: BenchmarkBuildChartProps
): BenchmarkSubmode | undefined {
  const { submode, gridIndex = 0, options = {} } = props
  if (submode) {
    if (gridIndex > 0 && options.useGrid) {
      if (useBenchmarkGridControl(submode, options.useGrid, gridIndex)) {
        const submodeItemDef = submode.grid?.[gridIndex - 1]
        if (submodeItemDef) {
          const submodeItem = mergeDeepRight(submode, submodeItemDef) as any
          submodeItem.id = buildBenchmarkSubmodeKey(submodeItem.id, gridIndex)
          return submodeItem
        }
      }
    }

    return submode
  }
}

export function buildBenchmarkGridRange(
  submode?: BenchmarkSubmode | null
): number[] {
  if (!submode) {
    return []
  }
  const gridLength = (submode.grid?.length ?? 0) + 1
  return range(0, gridLength)
}

export function buildAllBenchmarkGridSubmodes(
  submode?: BenchmarkSubmode | null,
  includeByIndex?: boolean[]
): BenchmarkSubmode[] {
  const options = { useGrid: true }
  return buildBenchmarkGridRange(submode).reduce((acc, gridIndex) => {
    if (includeByIndex == null || includeByIndex[gridIndex] === true) {
      const cfg = buildBenchmarkGridSubmode({ submode, gridIndex, options })
      if (cfg) {
        acc.push(cfg)
      }
    }
    return acc
  }, [] as BenchmarkSubmode[])
}

function toGridControlConfig(
  typeOrConfig: BenchmarkControlType | BenchmarkControlConfig
): BenchmarkControlConfig {
  if (typeof typeOrConfig === 'string') {
    return { type: typeOrConfig }
  }
  return typeOrConfig
}

export function useBenchmarkGridControl(
  submode?: BenchmarkSubmode | null,
  control?: BenchmarkControlType | true,
  gridIndex?: number
): boolean {
  if (control === true) {
    return true
  }
  if (submode?.gridControls && control) {
    return submode.gridControls.map(toGridControlConfig).some(cfg => {
      if (
        gridIndex != null &&
        cfg.forGridIndices &&
        !cfg.forGridIndices.includes(gridIndex)
      ) {
        // Don't use grid index-specific control if the grid indices are
        // configured but this grid index is not included
        return false
      }
      return cfg.type === control
    })
  }
  return false
}
