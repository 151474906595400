import { CdkScrollable } from '@angular/cdk/overlay'
import { ExtendedScrollToOptions } from '@angular/cdk/scrolling'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { dissoc } from 'ramda'
import { Size } from '@shared/size.mixin'
import {
  CompareMetricCategory,
  CompareMetricValue,
} from '../../../model/compare-metrics.model'
import { CompareEntity } from '../../../store/compare/compare.reducer'
import { hideMetric } from '../../../../metrics/metrics.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metrics-sidebar',
  styleUrls: ['./compare-metrics-sidebar.component.scss'],
  templateUrl: './compare-metrics-sidebar.component.html',
})

export class CompareMetricsSidebarComponent implements AfterViewInit {
  @Input() entitiesLength: number
  @Input() metricCategories: CompareMetricCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() expandedChangeMetrics: Record<string, boolean>
  @Input() firstEntity: CompareEntity
  @Input() size: Size
  @Input() offsetHeight: number
  @Input() grossSelected: boolean

  @Output() collapseToggle = new EventEmitter<string>()
  @Output() ragToggle = new EventEmitter<CompareMetricValue>()
  @Output() changeToggle = new EventEmitter<CompareMetricValue>()
  @Output() scrollChange = new EventEmitter<ExtendedScrollToOptions>()
  @Output() updateGrossSelection = new EventEmitter<boolean>()

  @ViewChild(CdkScrollable) scrollable: CdkScrollable

  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  get spacerStyle(): { height: string } {
    const height = `${this.offsetHeight}px`
    return { height }
  }

  ngAfterViewInit(): void {
    this.scrollable.elementScrolled().subscribe(() => {
      const top = this.scrollable.measureScrollOffset('top')
      this.scrollChange.emit({ top })
    })
  }

  scrollTo(opts: ExtendedScrollToOptions): void {
    if (opts.top !== this.scrollable.measureScrollOffset('top')) {
      // Do not scroll Sidebar horizontally
      // @ts-ignore
      this.scrollable.scrollTo(dissoc('start', {behavior: opts}))
    }
  }

  isExpanded(category: string): boolean {
    return (
      (this.expandedMetricCategories &&
        this.expandedMetricCategories[category]) ||
      false
    )
  }

  trackByID(index: number, category: CompareMetricCategory | undefined): string | number {
    return (category && category.category) || index
  }

  onUpdateGrossSelection(event: MatCheckboxChange): void {
    this.updateGrossSelection.emit(event.checked)
    if (event.checked) {
      if (!this.expandedMetricCategories['Net Results']) {
        this.collapseToggle.emit('Net Results')
      }
      if (!this.expandedMetricCategories['Tail Metrics']) {
        this.collapseToggle.emit('Tail Metrics')
      }
    }
  }

  getValues(metrics: CompareMetricValue[][]): CompareMetricValue[][] {
    const returnMetrics = JSON.parse(JSON.stringify(metrics))
    const hideIndex = metrics.findIndex((value: any) => {
      return hideMetric(value[0])
    })
    if (hideIndex !== -1) {
      returnMetrics.splice(hideIndex, 1)
    }
    return returnMetrics
  }
}
