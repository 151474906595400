import { values } from 'ramda'
import {
  Program,
  StructureTowerPreferences,
  TowerPreferences,
} from '../../core/model/program.model'
import {
  OmitID,
  ProgramResponse,
  StructureTowerPreferencesResponse,
} from '../model/backend.model'

export const convertAllProgramsFromResponse = (responses: ProgramResponse[]) =>
  values(
    responses.reduce((acc, res) => {
      const program = convertProgramFromResponse(res)

      // Set program dict, keeping any already set props (i.e. scenarioIDs or optimizationIDs)
      acc[program.id] = { ...program, ...(acc[program.id] ?? {}) }

      // If this program has a scenario parent, add its ID to its parent's
      // scenario IDs, even if the parent hasn't yet been set in the dict
      if (program.isScenario && program.parentScenarioID != null) {
        if (!acc[program.parentScenarioID]) {
          acc[program.parentScenarioID] = {}
        }
        const parent = acc[program.parentScenarioID]
        if (!parent.scenarioIDs) {
          parent.scenarioIDs = []
        }
        parent.scenarioIDs.push(program.id)
      } else if (
        program.isOptimization &&
        program.parentOptimizationID != null
      ) {
        if (!acc[program.parentOptimizationID]) {
          acc[program.parentOptimizationID] = {}
        }
        const parent = acc[program.parentOptimizationID]
        if (!parent.optimizationIDs) {
          parent.optimizationIDs = []
        }
        parent.optimizationIDs.push(program.id)
      }

      return acc
    }, {} as Record<string, Partial<Program>>)
  ) as Program[]

export const convertProgramFromResponse = (res: ProgramResponse): Program => ({
  id: String(res.id),
  studyID: String(res.study_id),
  label: res.label,
  programType: res.program_type,
  grossPortfolioID: res.gross_portfolio_id,
  cededPortfolioID: res.ceded_portfolio_id,
  netPortfolioID: res.net_portfolio_id,
  parentGrossPortfolioID: res.parent_gross_portfolio_id,
  analysisID: res.analysis_profile_id,
  layerData: res.layer_data,
  marketLayer: res.structure_market_layers,
  marketContent: res.market_content,
  imageSrc:
    res.analysis_profile_id +
    '-' +
    res.ceded_portfolio_id +
    '-' +
    res.net_portfolio_id +
    '-' +
    res.gross_portfolio_id +
    '-occurrence.png',
  towerPreferences: convertTowerPreferencesResponse(res.tower_preferences),
  description: res.description,
  isScenario: res.is_scenario,
  parentScenarioID: res.parent_scenario_id
    ? String(res.parent_scenario_id)
    : undefined,
  tremor: res.tremor,
  yearID: String(res.carrier_year_id),
  position_index: res.position_index,
  isOptimization: res.is_optimization,
  parentOptimizationID: res.parent_optimization_id
    ? String(res.parent_optimization_id)
    : undefined,
  tailMetricsOptions: res.tail_metrics_options
    ? String(res.tail_metrics_options)
    : undefined,
  lastModified: res.last_modified ? String(res.last_modified) : undefined,
  libRE: res.libRE,
  structureCurrency: res.structure_currency?.trim(),
  folderID: res.folder_id,
  fotCount: res.fot_count,
  quoteCount: res.quote_count,
})

const convertTowerPreferencesResponse = (
  res: StructureTowerPreferencesResponse | undefined
): StructureTowerPreferences | undefined => {
  if (res) {
    return {
      occurrence: {
        incrementsY: res.increment_y_occ,
        incrementsYDirty: false,
        maxY: res.y_max_occ,
        maxYDirty: false,
        most: null,
      },
      aggregate: {
        incrementsY: res.y_max_agg > 0 ? res.increment_y_agg : null,
        incrementsYDirty: false,
        maxY: res.y_max_agg > 0 ? res.y_max_agg : null,
        maxYDirty: false,
        most: null,
      },
      log: false,
      snapping: true,
      logMin: 1,
    }
  } else {
    const initial: TowerPreferences = {
      incrementsY: null,
      incrementsYDirty: false,
      maxY: null,
      maxYDirty: false,
      most: null,
    }
    return {
      occurrence: initial,
      aggregate: initial,
      log: false,
      snapping: true,
      logMin: 1,
    }
  }
}

export const convertProgramToRequest = (
  program: Partial<Program>
): OmitID<Partial<ProgramResponse>> => ({
  label: program.label,
  description: program.description,
  analysis_profile_id: program.analysisID?.trim(),
  ceded_portfolio_id: program.cededPortfolioID?.trim(),
  gross_portfolio_id: program.grossPortfolioID?.trim(),
  net_portfolio_id: program.netPortfolioID?.trim(),
  parent_gross_portfolio_id: program.parentGrossPortfolioID?.trim(),
  program_type: program.programType,
  study_id: program.studyID ? parseInt(program.studyID, 10) : undefined,
  is_scenario: program.isScenario,
  parent_scenario_id: program.parentScenarioID
    ? parseInt(program.parentScenarioID, 10)
    : undefined,
  position_index: program.position_index,
  is_optimization: program.isOptimization,
  parent_optimization_id: program.parentOptimizationID
    ? parseInt(program.parentOptimizationID, 10)
    : undefined,
  tail_metrics_options: program.tailMetricsOptions
    ? program.tailMetricsOptions
    : undefined,
  libRE: program.libRE,
  structure_currency: program.structureCurrency,
  autobuild_id: program.autobuildID,
  folder_id: program.folderID,
  fot_count: program.fotCount,
  quote_count: program.quoteCount,
})
