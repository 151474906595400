import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReducer from './user-preferences.reducer'

export const selectUserPreferencesState =
  createFeatureSelector<fromReducer.UserPreferencesState>(
    fromReducer.FEATURE_KEY
  )

export const selectUserPreferences = createSelector(
  selectUserPreferencesState,
  state => state.userPreferences
)

export const selectUserPreferencesColumns = createSelector(
  selectUserPreferencesState,
  state => state.userPreferencesColumnsList
)

export const selectdefaultUSAUserPreferences = createSelector(
  selectUserPreferencesState,
  state => state.defaultUSAUserPreferences
)
