import { Component } from '@angular/core';
import { SortTableColumnDef, SortTableRow } from '@shared/sort-table/sort-table.model';


const cols: SortTableColumnDef[] = []
const rows: SortTableRow[] = []
for (let i = 1; i < 20; i++){
  cols.push({
    id: String(i),
    label: String(i),
    valueType: 'numeric',
    editable: i > 4 && i < 15
  })
}
for (let i = 1; i < 30; i++){
  const row: SortTableRow = {}
  for (let j = 1; j < 20; j++){
    row[String(j)] = j * i
  }
  rows.push(row)
}

@Component({
  selector: 'app-testing-excel',
  templateUrl: './testing-excel.component.html',
  styleUrls: ['./testing-excel.component.scss']
})
export class TestingExcelComponent {

  columns: SortTableColumnDef[] = cols
  rows: SortTableRow[] = rows

  constructor() {
  }
}
