<button mat-menu-item (click)="itemClick.emit($event)">
  <mat-checkbox
    *ngIf="allowSelection && selected"
    [checked]="true"
  ></mat-checkbox>
  <mat-checkbox
    *ngIf="allowSelection && !selected"
    [checked]="false"
  ></mat-checkbox>
  <label>{{ program.label }}</label>
</button>
