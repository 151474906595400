import {inject, Injectable} from '@angular/core'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppState } from 'src/app/core/store'
import { select, Store } from '@ngrx/store'
import * as fromMiscMetricsActions from './misc-metrics.actions'
import { concatMap, withLatestFrom } from 'rxjs/operators'
import { concatMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import { fetchEfficiencyMetrics } from './efficiency-metrics.actions'
import { selectCompareMetricSettingsEntities } from '../analysis.selectors'

@Injectable()
export class MiscMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMiscMetricsActions.fetchMiscMetrics),
      withLatestFrom(
        this.store.pipe(select(selectCompareMetricSettingsEntities))
      ),
      concatMapWithInput(
        ([{ cededPortfolioViewID, netPortfolioViewID }, settings]) => {
          const probabilityMetricSetting = settings.filter(
            setting => setting.category === 'Misc' && setting.year
          )
          const portfolioID =
            probabilityMetricSetting[0].portfolioType === 'Net'
              ? netPortfolioViewID
              : cededPortfolioViewID
          return this.service.getMiscMetrics(
            cededPortfolioViewID,
            portfolioID,
            // tslint:disable-next-line: no-non-null-assertion
            probabilityMetricSetting[0].aggregationMethodType!,
            // tslint:disable-next-line: no-non-null-assertion
            probabilityMetricSetting[0].perspective!,
            // tslint:disable-next-line: no-non-null-assertion
            probabilityMetricSetting[0].year!,
            // tslint:disable-next-line: no-non-null-assertion
            probabilityMetricSetting[0].lossFilter!
          )
        }
      ),
      rejectErrorWithInput((error, [props]) => {
        this.store.dispatch(
          fromMiscMetricsActions.fetchMiscMetricsFailure({
            error,
            ...props,
          })
        )
        this.store.dispatch(fetchEfficiencyMetrics(props))
        }
      ),
      concatMap(([results, [props]]) => {
        const actions = []
        actions.push(
          fromMiscMetricsActions.fetchMiscMetricsSuccess({
            ...results,
            ...props,
          }),
          fetchEfficiencyMetrics(props)
        )
        return actions
      })
    )
  })
}
