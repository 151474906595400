import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { MultiSectionLayerService } from 'src/app/analysis/layers/multi-section-layer.service'
import { Layer } from 'src/app/analysis/model/layers.model'
import { MultiSectionComponent } from 'src/app/analysis/properties/layer/multi-section/multi-section.component'
import { MultiSectionProperties } from 'src/app/analysis/properties/properties-panel.model'
import {
  selectCededMultiSectionLayers,
  selectCurrentProgram,
  selectInuranceLoadingInMultiSection,
  selectParentGrossLossSetLayers,
} from 'src/app/analysis/store/analysis.selectors'
import {
  updateMultiSectionFromDialog,
  updatePhysicalLayer,
} from 'src/app/analysis/store/ceded-layers/layers.actions'
import { AppState } from 'src/app/core/store'
import { selectCurrencyList } from '../../../../core/store/broker/broker.selectors'

@Component({
  selector: 'app-multi-section-dialog',
  styleUrls: ['./multi-section-dialog.component.scss'],
  templateUrl: './multi-section-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSectionDialogComponent implements OnInit {
  readonly msLayers$ = this.store.select(selectCededMultiSectionLayers)
  readonly currencyList$ = this.store.select(selectCurrencyList)
  readonly pgLossSetLayers$ = this.store.select(selectParentGrossLossSetLayers)
  readonly currentProgram$ = this.store.select(selectCurrentProgram)
  readonly loadingInMultiSection$ = this.store.select(
    selectInuranceLoadingInMultiSection
  )
  isLibRE: boolean

  // This property is true if any section card is marked invalid
  sectionInvalid = false

  @ViewChild('multiSections') multiSections: MultiSectionComponent
  @ViewChild('saveAndClose') saveAndCloseButton: MatButton

  constructor(
    private dialogRef: MatDialogRef<MultiSectionDialogComponent>,
    private store: Store<AppState>,
    readonly mslService: MultiSectionLayerService,
    @Inject(MAT_DIALOG_DATA) private data: MultiSectionProperties
  ) {}

  ngOnInit(): void {
    this.isLibRE = this.data.isLibRE
  }

  get layer() {
    return this.data.layer
  }

  busy(): void {
    this.mslService.setBusy(true)
  }

  onSaveAndClose(): void {
    // Don't allow save until all the section cards are valid
    if (!this.saveAndCloseButton.disabled && !this.sectionInvalid) {
      this.busy()
      this.dialogRef.close(this.multiSections.currentSections)
    }
  }

  onSaveClick(): void {
    // Don't allow save until all the section cards are valid
    if (!this.saveAndCloseButton.disabled && !this.sectionInvalid) {
      this.busy()

      setTimeout(() => {
        this.store.dispatch(
          updateMultiSectionFromDialog({
            layer: this.layer,
            sections: this.multiSections.currentSections,
          })
        )
        // Cause the multi-section box to redraw and update text
        this.store.dispatch(
          updatePhysicalLayer({ id: this.layer.id, change: {} })
        )
      })
    }
  }

  onAddClick(): void {
    if (!this.saveAndCloseButton.disabled) {
      this.multiSections.onAddSection()
    }
  }

  getCurrencyChangeLayer(layer: Layer) {
    this.store.dispatch(
      updatePhysicalLayer({
        id: layer.id,
        change: {
          ...layer.physicalLayer,
          meta_data: {
            ...layer.meta_data,
            isChangedInDesign: true,
          },
        },
      })
    )
  }
}
