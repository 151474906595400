import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  OpportunityDetailsDialogComponent,
  OpportunityDetailsDialogData,
} from './opportunity-details-dialog.component'

@Injectable()
export class OpportunityDetailsDialogService {
  constructor(public dialog: MatDialog) {}

  open(opts?: OpportunityDetailsDialogData) {
    return this.dialog.open(OpportunityDetailsDialogComponent, {
      minWidth: '40vw',
      data: opts,
    })
  }
}
