import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { IControl } from 'src/app/pricingcurve/model/pricing-curve.model'
import { PricingCurveCarrierPermissionsDialogPayload } from '../../dialog/pricing-curve-dialog.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-permissions-dialog',
  templateUrl: './pricing-curve-permissions-dialog.component.html',
  styleUrls: ['./pricing-curve-permissions-dialog.component.scss'],
})
export class PricingCurvePermissionsDialogComponent {
  creditCarriers: string[]
  form: FormGroup
  formControl: IControl

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: PricingCurveCarrierPermissionsDialogPayload,
    public matDialogRef: MatDialogRef<PricingCurvePermissionsDialogComponent>
  ) {
    this.creditCarriers = data.carriers
    this.form = this.formBuilder.group({
      organization: new FormControl([]),
    })
    this.formControl = {
      id: 'organization',
      values: this.creditCarriers,
      allValues: this.creditCarriers,
      selectedValues: [],
      name: 'organization',
      columnName: 'organization',
    }
  }

  destroyDialog(): void {
    this.matDialogRef.close([])
  }

  submitCarriers(): void {
    this.matDialogRef.close(this.selectedCarriers)
  }

  get selectedCarriers(): string[] {
    return this.form.get('organization').value
  }
}
