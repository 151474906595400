import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SummaryChartMenuOption } from '../summary-charts-model'

@Component({
  selector: 'app-explore-summary-chart-option-menu',
  templateUrl: './explore-summary-chart-menu.component.html',
  styleUrls: ['./explore-summary-chart-menu.component.scss'],
})
export class ExploreSummaryChartMenuComponent {
  @Input() menuOptions: SummaryChartMenuOption[]
  @Input() currentOption: SummaryChartMenuOption

  @Output() optionSelectChange = new EventEmitter<SummaryChartMenuOption>()

  onOptionSelect(
    $event: MouseEvent | TouchEvent,
    selectedOption: SummaryChartMenuOption
  ) {
    $event.preventDefault()
    this.optionSelectChange.emit(selectedOption)
  }
}
