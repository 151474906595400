import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import {
  OptimizationCandidateLayer,
  OptimizationCandidateResult,
} from '../optimization.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-summary',
  styleUrls: ['./optimization-summary.component.scss'],
  templateUrl: './optimization-summary.component.html',
})
export class OptimizationSummaryComponent {
  @Input() lastCreated = 0
  @Input() error: string | null
  @Input() candidatesResults: OptimizationCandidateResult[]
  @Input() candidateLayers: OptimizationCandidateLayer[]
  @Input() saving: boolean

  get totalLayers() {
    return this.candidateLayers.length
  }

  get totalSelectedCandidates() {
    return this.candidatesResults.length
  }
}
