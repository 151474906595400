export interface Editor {
  analysisProfileID: string
  parentGrossPortfolioID: string
}

export interface State {
  editor: Editor
}

export const initialState: State = {
  editor: {
    analysisProfileID: '',
    parentGrossPortfolioID: '',
  },
}
