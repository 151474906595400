<div class="row label" *ngIf="showExtraLabels">
    <div></div>
    <div>From</div>
    <div>To</div>
    <div>Increments of</div>
    <div># of options</div>
  </div>
  <div class="row">
    <div>
      <label class="name">{{ name }}</label>
    </div>
    <div>
      <app-layer-property
        [currentCurrency]="range.currency"
        [type]="range.type"
        [value]="range.from"
        [readonly]="false"
        [hideLabel]="true"
        (inputChange)="setValue('from', $event)"
      ></app-layer-property>
    </div>
    <div>
      <app-layer-property
        [currentCurrency]="range.currency"
        [type]="range.type"
        [value]="range.to"
        [readonly]="false"
        [hideLabel]="true"
        (inputChange)="setValue('to', $event)"
      ></app-layer-property>
    </div>
    <div>
      <app-layer-property
        [currentCurrency]="range.currency"
        [type]="range.type"
        [value]="range.incrementsOf"
        [readonly]="disableIncrementsAndOptions"
        [hideLabel]="true"
        (inputChange)="setValue('incrementsOf', $event)"
      ></app-layer-property>
    </div>
    <div>
      <app-layer-property
        [type]="'numeric'"
        [value]="range.numberOfOptions"
        [readonly]="disableIncrementsAndOptions"
        [hideLabel]="true"
        (inputChange)="setValue('numberOfOptions', $event)"
      ></app-layer-property>
    </div>
  </div>
