import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-panel-toggle-button',
  styles: [
    `
           .wrapper {
             box-sizing: border-box;
             width: 12px;
             height: 42px;
             background: var(--bg-1);
             border-radius: 0 4px 4px 0;
             padding-top: 8px;
             border: 1px solid var(--border-1);
             border-right-width: 1px;
             border-left-width: 0;
             opacity: 0.8;
             cursor: pointer;
             transition: background 250ms, color 250ms;
           }
     
           :host.right .wrapper {
             border-radius: 4px 0 0 4px;
             border-right-width: 0;
             border-left-width: 1px;
           }
     
           :host.hover .wrapper,
           .wrapper:hover {
             background: var(--border-1);
             opacity: 1;
           }
     
           mat-icon {
             position: relative;
             color: var(--hint);
             right: 6px;
           }
     
           :host.right mat-icon {
             right: 7px;
           }
     
           :host.hover .wrapper mat-icon,
           .wrapper:hover mat-icon {
             color: var(--primary);
           }
         `,
  ],
  template: `
    <div
      class="wrapper"
      [matTooltip]="label"
      [matTooltipPosition]="tooltipPosition"
      nbButton
    >
      <mat-icon aria-hidden="false" [attr.aria-label]="label">
        {{ iconID }}
      </mat-icon>
    </div>
  `,
})
export class PanelToggleButtonComponent {
  @Input() set right(value: boolean) {
    this._right = coerceBooleanProperty(value)
  }
  get right(): boolean {
    return this._right
  }
  @HostBinding('class.right') _right = false

  @Input() set open(value: boolean) {
    this._open = coerceBooleanProperty(value)
  }
  get open(): boolean {
    return this._open
  }
  @HostBinding('class.open') _open = true

  @Input() set hover(value: boolean) {
    this._hover = coerceBooleanProperty(value)
  }
  get hover(): boolean {
    return this._hover
  }
  @HostBinding('class.hover') _hover = true

  @Output() toggleClick = new EventEmitter()

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    this.toggleClick.emit()
  }

  get iconID() {
    if (this.right) {
      return this.open ? 'arrow_right' : 'arrow_left'
    }
    return this.open ? 'arrow_left' : 'arrow_right'
  }

  get label(): string {
    return this.open ? 'Hide' : 'Show Panel'
  }

  get tooltipPosition(): TooltipPosition {
    return this.right ? 'before' : 'after'
  }
}
