import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { ErrorDialogComponent } from './dialog'
import { ErrorComponent } from './error.component'
import { ErrorEffects } from './store/error.effects'
import { SharedModule } from '@shared/shared.module'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [ErrorComponent, ErrorDialogComponent],
  exports: [ErrorComponent],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([ErrorEffects]),
  ],
})
export class ErrorModule {}
