import { SalesforceClient } from './../../../api/model/signature.model'
import { FotState } from './../fots/fot.reducer'
import { Action, createReducer, on } from '@ngrx/store'
import {
  ReinsuredFot,
  SignatureContract,
  SignatureReinsurer,
  getReferenceTypeOrNumber,
} from './../../signature.model'
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity'
import * as fromSignatureActions from '../signature.actions'
import * as fromContractsActions from './contracts.actions'
import { clone } from 'ramda'
import { RiskRefDetail } from 'src/app/api/model/signature.model'

export interface State extends EntityState<SignatureContract> {
  selectedContract?: SignatureContract
  selectedReinsurer?: SignatureReinsurer
  currentPageIndex: number
  dirty: boolean
  dirtyContracts: string[] /* Track dirty Contracts - by pageSetName */
  saving: boolean
  exporting: boolean
  groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  riskRefs: RiskRefDetail[]
  clients: SalesforceClient[]
}

export const adapter = createEntityAdapter<SignatureContract>({
  selectId: (contractstate): string => contractstate.pageSetName,
})

export const initialState: State = adapter.getInitialState({
  currentPageIndex: 0,
  dirty: false,
  dirtyContracts: [],
  saving: false,
  exporting: false,
  groupOrStructureFilterSelection: null,
  riskRefs: [],
  clients: [],
})

export const UNLIMITED_VAL = 2147483647 /* 2,147,483,647 */

const ContractsReducer = createReducer(
  initialState,

  on(
    fromSignatureActions.initSignatureData,
    (state, { groupOrStructureFilterSelection }) => {
      return { ...state, groupOrStructureFilterSelection }
    }
  ),

  on(fromSignatureActions.fetchAllRiskRefsSuccess, (state, { riskRefs }) => ({
    ...state,
    riskRefs,
  })),

  on(
    fromSignatureActions.fetchAllClientsFromSalesforceSuccess,
    (state, { clients }) => ({
      ...state,
      clients,
    })
  ),

  on(
    fromSignatureActions.fetchAllSavedContractsSuccess,
    (state, { contracts }) => {
      const currentGroupOrStructureFilter =
        state.groupOrStructureFilterSelection
      const sortedContracts: SignatureContract[] = clone(contracts)
        .filter(c => {
          /* Exclude contracts that have no reinsurers, or any reinsurer without an FOT */
          let contractHasReinsurerWithNoFots = false
          if (c.reinsurers.length <= 0) {
            return
          }
          for (const reinsurer of c.reinsurers) {
            if (
              !reinsurer.fotsReinsured ||
              reinsurer.fotsReinsured.length <= 0
            ) {
              contractHasReinsurerWithNoFots = true
              break
            }
          }
          if (contractHasReinsurerWithNoFots) {
            return
          }

          /* When group filter selected, only show contracts with a program group id.
             When structure filter selected, only show contracts with a structure id */
          return currentGroupOrStructureFilter === 'Group'
            ? c.reinsurers[0].fotsReinsured[0].programGroupId !== null
            : c.reinsurers[0].fotsReinsured[0].structureId !== null
        })
        .map(c => {
          return {
            ...c,
            reinsurers: c.reinsurers.map(reinsurer => {
              return {
                ...reinsurer,
                fotsReinsured: reinsurer.fotsReinsured.sort(
                  (a, b) => a.fotPositionInContract - b.fotPositionInContract
                ),
              }
            }),
          }
        })
      return adapter.addMany(sortedContracts, state)
    }
  ),

  on(
    fromContractsActions.createSignatureContractSuccess,
    (state, { contractToCreate, inceptionDate, cedents }) => {
      if (state.entities[contractToCreate.pageSetName]) {
        return state
      }
      const orderOfFots = contractToCreate.fots.map(fot => fot.id)
      const contractToAdd: SignatureContract = {
        pageSetName: contractToCreate.pageSetName,
        contractName: '',
        programId: parseInt(contractToCreate.fots[0].programId ?? '', 10),
        reinsurers: [],
      }
      const fotToSigPageMapping: Partial<SignatureReinsurer>[] = []

      for (const fot of contractToCreate.fots) {
        const fotId =
          state.groupOrStructureFilterSelection === 'Group'
            ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : state.groupOrStructureFilterSelection === 'SL'
            ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
        fot.assignedLines?.forEach(assigned => {
          if (
            assigned.placedThrough !== 'Lockton Re' ||
            assigned.signed === 0
          ) {
            /* only create sig pages for placed through -> Lockton Re, and signed % more than 0% */
            return
          }
          const aiinRefNumber = fot.refTypesFromSelection?.find(
            ref => ref.reinsurer === assigned.reinsurer
          )?.aiinReferenceNumber
          const feinRefNumber = fot.refTypesFromSelection?.find(
            ref => ref.reinsurer === assigned.reinsurer
          )?.feinReferenceNumber
          const naicRefNumber = fot.refTypesFromSelection?.find(
            ref => ref.reinsurer === assigned.reinsurer
          )?.naicReferenceNumber

          const mappingIndex = fotToSigPageMapping.findIndex(
            mapping => mapping.reinsurerName === assigned.reinsurer
          )

          if (mappingIndex < 0) {
            fotToSigPageMapping.push({
              reinsurerName: assigned.reinsurer,
              tpRef: assigned.marketTpRef,
              sequenceNumber: assigned.relationSeqNumber,
              referenceType: getReferenceTypeOrNumber(
                aiinRefNumber,
                feinRefNumber,
                naicRefNumber
              ),
              aiinReferenceNumber: aiinRefNumber,
              feinReferenceNumber: feinRefNumber,
              naicReferenceNumber: naicRefNumber,
              effectiveDate:
                inceptionDate /* pulled from salesforce opportunity based on current selected program */,
              programId: parseInt(fot.programId ?? '', 10),
              fotsReinsured: [
                {
                  programGroupId: fot.programGroupId,
                  programGroupName: fot.programGroupName,
                  structureId: fot.structureId,
                  structureName: fot.structureName,
                  sharedLimitId: fot.sharedLimitId,
                  sharedLimitName: fot.sharedLimitName,
                  layerRef: fot.layerRef,
                  layerName: fot.layerName,
                  layerType: fot.layerType,
                  layerCurrency: fot.layerCurrency,
                  reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
                  reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
                  subjectivities: assigned.subjectivity ?? '',
                  occurrence: fot.occurrence
                    ? fot.occurrence >= UNLIMITED_VAL
                      ? UNLIMITED_VAL
                      : fot.occurrence
                    : 0,
                  percentage: assigned.signed ?? 0,
                  brokerageType: fot.brokerageType,
                  brokeragePercent: assigned.brokerage,
                  reinstatementPercent: assigned.brokerageRe,
                  reinsuranceBrokeragePayable: '',
                  riskReinsurerId: fot.riskReinsurerId,
                  fotPositionInContract: orderOfFots.indexOf(fotId),
                },
              ],
            })
          } else {
            fotToSigPageMapping[mappingIndex].fotsReinsured?.push({              
              programGroupId: fot.programGroupId,
              programGroupName: fot.programGroupName,
              structureId: fot.structureId,
              structureName: fot.structureName,
              sharedLimitId: fot.sharedLimitId,
              sharedLimitName: fot.sharedLimitName,
              layerRef: fot.layerRef,
              layerName: fot.layerName,
              layerType: fot.layerType,
              layerCurrency: fot.layerCurrency,
              reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
              reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
              subjectivities: assigned.subjectivity ?? '',
              occurrence: fot.occurrence
                ? fot.occurrence >= UNLIMITED_VAL
                  ? UNLIMITED_VAL
                  : fot.occurrence
                : 0,
              percentage: assigned.signed ?? 0,
              brokerageType: fot.brokerageType,
              brokeragePercent: assigned.brokerage,
              reinstatementPercent: assigned.brokerageRe,
              reinsuranceBrokeragePayable: '',
              riskReinsurerId: fot.riskReinsurerId,
              fotPositionInContract: orderOfFots.indexOf(fotId),
            })
          }
        })
      }

      for (const sigPage of fotToSigPageMapping) {
        const fotsToAdd: ReinsuredFot[] = contractToCreate.fots
          .filter(
            fot =>
              !sigPage.fotsReinsured?.find(assignedFot => {
                const assignedId =
                  state.groupOrStructureFilterSelection === 'Group'
                    ? `${assignedFot.programGroupId}-${assignedFot.layerRef}-${assignedFot.reinsurerPhaseLabel}`
                    : state.groupOrStructureFilterSelection === 'SL'
                    ? `${assignedFot.sharedLimitId}-${assignedFot.layerRef}-${assignedFot.reinsurerPhaseLabel}`
                    : `${assignedFot.structureId}-${assignedFot.layerRef}-${assignedFot.reinsurerPhaseLabel}`
                return assignedId === fot.id
              })
          )
          .map(fot => {
            const fotId =
              state.groupOrStructureFilterSelection === 'Group'
                ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : state.groupOrStructureFilterSelection === 'SL'
                ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            return {              
              programGroupId: fot.programGroupId,
              programGroupName: fot.programGroupName,
              structureId: fot.structureId,
              structureName: fot.structureName,
              sharedLimitId: fot.sharedLimitId,
              sharedLimitName: fot.sharedLimitName,
              layerRef: fot.layerRef,
              layerName: fot.layerName,
              layerType: fot.layerType,
              layerCurrency: fot.layerCurrency,
              reinsurerPhaseVersion: fot.reinsurerPhaseVersion,
              reinsurerPhaseLabel: fot.reinsurerPhaseLabel,
              subjectivities: '',
              occurrence: fot.occurrence
                ? fot.occurrence >= UNLIMITED_VAL
                  ? UNLIMITED_VAL
                  : fot.occurrence
                : 0,
              percentage: 0 /* FOTs that aren't assigned to a reinsurer get added with a 0 signed % */,
              brokerageType: fot.brokerageType,
              brokeragePercent: 0,
              reinstatementPercent: 0,
              reinsuranceBrokeragePayable: '',
              riskReinsurerId: fot.riskReinsurerId,
              fotPositionInContract: orderOfFots.indexOf(fotId),
            }
          })
        const fotsReinsured = fotsToAdd
          .concat(sigPage.fotsReinsured ?? [])
          .sort((a, b) => a.fotPositionInContract - b.fotPositionInContract)

        contractToAdd.reinsurers.push({
          tpRef: sigPage.tpRef,
          sequenceNumber: sigPage.sequenceNumber,
          contractName: '',
          reinsurerName: sigPage.reinsurerName ?? '',
          referenceType: getReferenceTypeOrNumber(
            sigPage.aiinReferenceNumber,
            sigPage.naicReferenceNumber,
            sigPage.feinReferenceNumber
          ),
          aiinReferenceNumber: sigPage.aiinReferenceNumber,
          feinReferenceNumber: sigPage.feinReferenceNumber,
          naicReferenceNumber: sigPage.naicReferenceNumber,
          effectiveDate: sigPage.effectiveDate,
          riskRef: '',
          isFinalVersion: false,
          programId: sigPage.programId ?? null,
          fotsReinsured,
          cedents,
          companyAlias: sigPage.companyAlias,
        })
      }

      // if multiple Lloyds reinsurers & their subjectivities, brokeragePercent & reinstatementPercent
      // are equal combine into one 'Lloyds members' reinsurer
      const matchedReinsurers: number[] = []
      const unmatchedReinsurers: number[] = []
      let finalListOfReinsurers: SignatureReinsurer[] = []
      let summedSignedPercent = 0
      const lloydsReinsurers: SignatureReinsurer[] = []
      const nonLloydsReinsurers: SignatureReinsurer[] = []

      contractToAdd.reinsurers.forEach(r => {
        const nameParts = r.reinsurerName.split(' ')
        if (nameParts[0] === "Lloyd's") {
          lloydsReinsurers.push(r)
        } else {
          nonLloydsReinsurers.push(r)
        }
      })

      // the 3 properties we need to compare to see if any 3 are the same are in each
      // reinsurer's array of fots, so nested looping and then looping in that
      // - almost recursion - to compare values to each other
      lloydsReinsurers.forEach((r, i) => {
        r.fotsReinsured.forEach(f => {
          lloydsReinsurers.forEach((r2, k) => {
            if (k !== i && !matchedReinsurers.includes(i)) {
              r2.fotsReinsured.forEach(f2 => {
                if (
                  f.subjectivities === f2.subjectivities &&
                  f.brokeragePercent === f2.brokeragePercent &&
                  f.reinstatementPercent === f2.reinstatementPercent
                ) {
                  // if this one hasn't already been compared & added, add this one's % to total
                  if (!matchedReinsurers.includes(i)) {
                    summedSignedPercent = parseFloat(
                      (summedSignedPercent + f.percentage).toFixed(10)
                    )
                  }
                  if (!matchedReinsurers.includes(k)) {
                    summedSignedPercent = parseFloat(
                      (summedSignedPercent + f2.percentage).toFixed(10)
                    )
                  }
                  matchedReinsurers.push(i)
                  matchedReinsurers.push(k)
                }
              })
            }
          })
        })
        if (!matchedReinsurers.includes(i)) {
          unmatchedReinsurers.push(i)
        }
      })

      if (matchedReinsurers.length > 0) {
        const consolidatedLloyds = {
          ...lloydsReinsurers[matchedReinsurers[0]],
          reinsurerName: `Certain Underwriting Members of Lloyd’s London`,
          fotsReinsured: [
            {
              ...lloydsReinsurers[matchedReinsurers[0]].fotsReinsured[0],
              percentage: summedSignedPercent,
            },
          ],
        }
        lloydsReinsurers.unshift(consolidatedLloyds)
      }

      finalListOfReinsurers = [...lloydsReinsurers, ...nonLloydsReinsurers]

      contractToAdd.reinsurers = finalListOfReinsurers

      return adapter.addOne(contractToAdd, {
        ...state,
        dirty: true,
        dirtyContracts: [
          ...new Set([...state.dirtyContracts, contractToCreate.pageSetName]),
        ],
      })
    }
  ),

  on(
    fromContractsActions.setSelectedContract,
    (state, { selectedContract }) => {
      if (!state.entities[selectedContract.pageSetName]) {
        return state
      }
      return {
        ...state,
        selectedContract: state.entities[selectedContract.pageSetName],
        selectedReinsurer:
          state.entities[selectedContract.pageSetName]?.reinsurers[0],
        currentPageIndex: 0,
      }
    }
  ),

  on(
    fromContractsActions.setSelectedSigPage,
    (state, { selectedReinsurer }) => {
      const newPageIndex =
        state.selectedContract?.reinsurers.findIndex(
          s => s.reinsurerName === selectedReinsurer.reinsurerName
        ) ?? 0
      if (newPageIndex < 0) {
        return state
      }
      return { ...state, selectedReinsurer, currentPageIndex: newPageIndex }
    }
  ),

  on(
    fromContractsActions.updateSignatureContractSuccess,
    (state, { reinsurer, applyToEntireFot, updatedReferenceTypeAndNum }) => {
      const reinsurerFoundIndex =
        state.selectedContract?.reinsurers.findIndex(
          s => s.reinsurerName === reinsurer.reinsurerName
        ) ?? -1

      if (reinsurerFoundIndex < 0 || !state.selectedContract) {
        return state
      }

      /* Update selected contract */
      const updatedSigPages = clone(state.selectedContract?.reinsurers)
      const updatedReinsurer: SignatureReinsurer =
        !!updatedReferenceTypeAndNum.referenceType
          ? { ...reinsurer, ...updatedReferenceTypeAndNum }
          : reinsurer
      updatedSigPages?.splice(reinsurerFoundIndex, 1, updatedReinsurer)

      /* Apply updates for contract name, effective date, cedent location, layer type, and layer name to all pages within contract */
      if (applyToEntireFot) {
        for (const i in updatedSigPages) {
          if (+i !== reinsurerFoundIndex) {
            if (applyToEntireFot.contractName) {
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                contractName: reinsurer.contractName,
              })
            }
            if (applyToEntireFot.effectiveDate) {
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                effectiveDate: reinsurer.effectiveDate,
              })
            }
            if (applyToEntireFot.riskRef) {
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                riskRef: reinsurer.riskRef,
              })
            }
            if (applyToEntireFot.cedents) {
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                cedents: reinsurer.cedents,
              })
            }
            if (applyToEntireFot.layerType) {
              const updatedFotsReinsured = updatedSigPages[+i].fotsReinsured
              const fotReinsuredIndexToUpdate = applyToEntireFot.layerType.index
              updatedFotsReinsured.splice(fotReinsuredIndexToUpdate, 1, {
                ...updatedFotsReinsured[fotReinsuredIndexToUpdate],
                layerType:
                  reinsurer.fotsReinsured[fotReinsuredIndexToUpdate].layerType,
              })
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                fotsReinsured: [...updatedFotsReinsured],
              })
            }
            if (applyToEntireFot.layerName) {
              const updatedFotsReinsured = updatedSigPages[+i].fotsReinsured
              const fotReinsuredIndexToUpdate = applyToEntireFot.layerName.index
              updatedFotsReinsured.splice(fotReinsuredIndexToUpdate, 1, {
                ...updatedFotsReinsured[fotReinsuredIndexToUpdate],
                layerName:
                  reinsurer.fotsReinsured[fotReinsuredIndexToUpdate].layerName,
              })
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                fotsReinsured: [...updatedFotsReinsured],
              })
            }
            if (applyToEntireFot.companyAlias) {
              updatedSigPages.splice(+i, 1, {
                ...updatedSigPages[+i],
                companyAlias: reinsurer.companyAlias,
              })
            }
          }
        }
      }

      const updatedContractName = applyToEntireFot?.contractName
        ? reinsurer.contractName
        : null

      return adapter.updateOne(
        {
          id: state.selectedContract.pageSetName,
          changes: {
            contractName:
              updatedContractName ?? state.selectedContract.contractName,
            reinsurers: [...updatedSigPages],
          },
        },
        {
          ...state,
          selectedContract: {
            ...state.selectedContract,
            contractName:
              updatedContractName ?? state.selectedContract.contractName,
            reinsurers: [...updatedSigPages],
          },
          selectedReinsurer: {
            ...updatedReinsurer,
          },
          dirty: true,
          dirtyContracts: [
            ...new Set([
              ...state.dirtyContracts,
              state.selectedContract.pageSetName,
            ]),
          ],
        }
      )
    }
  ),

  on(fromContractsActions.addLayerToSelectedContract, (state, { layer }) => {
    if (!state.selectedContract) {
      return state
    }

    /* Update selected contract */
    const sigPages = clone(state.selectedContract?.reinsurers) ?? []

    /* Update FOTs for assigned reinsurers that already have a page */
    const updatedSigPages: SignatureReinsurer[] = sigPages.map(sigPage => {
      const reinsurerAssigned = layer.assignedLines?.find(
        assigned => assigned.reinsurer === sigPage.reinsurerName
      )
      const lastFotIndex = sigPage.fotsReinsured.length - 1
      const fotPositionInContract =
        sigPage.fotsReinsured[lastFotIndex].fotPositionInContract + 1
      const fotToAdd: ReinsuredFot = reinsurerAssigned
        ? {
            programGroupId: layer.programGroupId,
            programGroupName: layer.programGroupName,
            structureId: layer.structureId,
            structureName: layer.structureName,
            sharedLimitId: layer.sharedLimitId,
            sharedLimitName: layer.sharedLimitName,
            layerRef: layer.layerRef,
            layerName: layer.layerName,
            layerType: layer.layerType,
            layerCurrency: layer.layerCurrency,
            reinsurerPhaseVersion: layer.reinsurerPhaseVersion,
            reinsurerPhaseLabel: layer.reinsurerPhaseLabel,
            subjectivities: reinsurerAssigned.subjectivity ?? '',
            occurrence: layer.occurrence
              ? layer.occurrence >= UNLIMITED_VAL
                ? UNLIMITED_VAL
                : layer.occurrence
              : 0,
            percentage:
              reinsurerAssigned.signed &&
              reinsurerAssigned.placedThrough === 'Lockton Re'
                ? reinsurerAssigned.signed
                : 0,
            brokerageType: layer.brokerageType,
            brokeragePercent: reinsurerAssigned.brokerage,
            reinstatementPercent: reinsurerAssigned.brokerageRe,
            reinsuranceBrokeragePayable: '',
            riskReinsurerId: layer.riskReinsurerId,
            fotPositionInContract,
          }
        : {
            programGroupId: layer.programGroupId,
            programGroupName: layer.programGroupName,
            structureId: layer.structureId,
            structureName: layer.structureName,
            sharedLimitId: layer.sharedLimitId,
            sharedLimitName: layer.sharedLimitName,
            layerRef: layer.layerRef,
            layerName: layer.layerName,
            layerType: layer.layerType,
            layerCurrency: layer.layerCurrency,
            reinsurerPhaseVersion: layer.reinsurerPhaseVersion,
            reinsurerPhaseLabel: layer.reinsurerPhaseLabel,
            subjectivities: '',
            occurrence: layer.occurrence
              ? layer.occurrence >= UNLIMITED_VAL
                ? UNLIMITED_VAL
                : layer.occurrence
              : 0,
            percentage: 0 /* FOTs that aren't assigned to a reinsurer get added with a 0 signed % */,
            brokerageType: layer.brokerageType,
            brokeragePercent: 0,
            reinstatementPercent: 0,
            reinsuranceBrokeragePayable: '',
            riskReinsurerId: layer.riskReinsurerId,
            fotPositionInContract,
          }
      const fotsReinsured = sigPage.fotsReinsured.concat(fotToAdd)

      return {
        ...sigPage,
        fotsReinsured,
      }
    })

    /* Add signature pages for reinsurers only assigned to this added layer */
    const assignedToAddPagesFor =
      layer.assignedLines?.filter(
        assigned =>
          !updatedSigPages.find(
            page => page.reinsurerName === assigned.reinsurer
          ) &&
          assigned.signed !== 0 &&
          assigned.placedThrough === 'Lockton Re'
      ) ?? []
    const pagesToAdd: SignatureReinsurer[] = assignedToAddPagesFor.map(
      assigned => {
        const fotsToAdd: ReinsuredFot[] = updatedSigPages[0].fotsReinsured
          .filter(fot => {
            const fotId =
              state.groupOrStructureFilterSelection === 'Group'
                ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : state.groupOrStructureFilterSelection === 'SL'
                ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            return fotId !== layer.id
          })
          .map(fot => {
            return {
              ...fot,
              id: undefined,
              percentage: 0 /* FOTs that aren't assigned to a reinsurer get added with a 0 signed % */,
              subjectivities: '',
              brokeragePercent: 0,
              reinstatementPercent: 0,
            }
          })
        const lastFotIndex = updatedSigPages[0].fotsReinsured.length - 1
        const fotPositionInContract =
          updatedSigPages[0].fotsReinsured[lastFotIndex].fotPositionInContract
        const fotsReinsured = fotsToAdd.concat({
          programGroupId: layer.programGroupId,
          programGroupName: layer.programGroupName,
          structureId: layer.structureId,
          structureName: layer.structureName,
          sharedLimitId: layer.sharedLimitId,
          sharedLimitName: layer.sharedLimitName,
          layerRef: layer.layerRef,
          layerName: layer.layerName,
          layerType: layer.layerType,
          layerCurrency: layer.layerCurrency,
          reinsurerPhaseVersion: layer.reinsurerPhaseVersion,
          reinsurerPhaseLabel: layer.reinsurerPhaseLabel,
          subjectivities: assigned.subjectivity ?? '',
          occurrence: layer.occurrence
            ? layer.occurrence >= UNLIMITED_VAL
              ? UNLIMITED_VAL
              : layer.occurrence
            : 0,
          percentage: assigned.signed ?? 0,
          brokerageType: layer.brokerageType,
          brokeragePercent: assigned.brokerage,
          reinstatementPercent: assigned.brokerageRe,
          reinsuranceBrokeragePayable: '',
          riskReinsurerId: layer.riskReinsurerId,
          fotPositionInContract, // since first sig page already added layer, use same position
        })

        const aiinRefNumber = layer.refTypesFromSelection?.find(
          ref => ref.reinsurer === assigned.reinsurer
        )?.aiinReferenceNumber
        const naicRefNumber = layer.refTypesFromSelection?.find(
          ref => ref.reinsurer === assigned.reinsurer
        )?.feinReferenceNumber
        const feinRefNumber = layer.refTypesFromSelection?.find(
          ref => ref.reinsurer === assigned.reinsurer
        )?.naicReferenceNumber

        return {
          contractName: updatedSigPages[0].contractName,
          reinsurerName: assigned.reinsurer ?? '',
          referenceType: getReferenceTypeOrNumber(
            aiinRefNumber,
            naicRefNumber,
            feinRefNumber
          ),
          aiinReferenceNumber: aiinRefNumber,
          feinReferenceNumber: feinRefNumber,
          naicReferenceNumber: naicRefNumber,
          effectiveDate: updatedSigPages[0].effectiveDate,
          riskRef: updatedSigPages[0].riskRef,
          isFinalVersion: false,
          programId: parseInt(layer.programId ?? '', 10),
          fotsReinsured,
          cedents: [
            ...updatedSigPages[0].cedents.map(cedent => {
              return {
                ...cedent,
                id: undefined,
              }
            }) /* copy all cedents to this new page */,
          ],
          companyAlias: updatedSigPages[0].companyAlias,
        }
      }
    )

    let reinsurerIndex = updatedSigPages.findIndex(
      sigPage =>
        sigPage.reinsurerName === state.selectedReinsurer?.reinsurerName
    )
    if (reinsurerIndex < 0) {
      reinsurerIndex = 0
    }

    return adapter.updateOne(
      {
        id: state.selectedContract?.pageSetName,
        changes: {
          reinsurers: [...updatedSigPages, ...pagesToAdd],
        },
      },
      {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          reinsurers: [...updatedSigPages, ...pagesToAdd],
        },
        selectedReinsurer: {
          ...updatedSigPages[reinsurerIndex],
        },
        currentPageIndex: reinsurerIndex,
        dirty: true,
        dirtyContracts: [
          ...new Set([
            ...state.dirtyContracts,
            state.selectedContract.pageSetName,
          ]),
        ],
      }
    )
  }),

  on(
    fromContractsActions.removeLayerFromSelectedContract,
    (state, { layer }) => {
      if (!state.selectedContract) {
        return state
      }
      const updatedSigPages = clone(state.selectedContract?.reinsurers)

      const reinsurersToDelete: string[] = []
      for (const i in updatedSigPages) {
        if (!updatedSigPages[i]) {
          continue
        }
        const fotIndexToRemove = updatedSigPages[i].fotsReinsured.findIndex(
          fot => {
            const fotId =
              state.groupOrStructureFilterSelection === 'Group'
                ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : state.groupOrStructureFilterSelection === 'SL'
                ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            return fotId === layer.id
          }
        )
        if (fotIndexToRemove >= 0) {
          updatedSigPages[i].fotsReinsured.splice(fotIndexToRemove, 1)
        }
        const isSignedOnOtherFot = updatedSigPages[i].fotsReinsured.find(
          fot => fot.percentage !== 0
        )
        if (!isSignedOnOtherFot) {
          reinsurersToDelete.push(updatedSigPages[i].reinsurerName) // remove this page since reinsurer is not signed to any of the other FOTs
        }
      }
      reinsurersToDelete.forEach(reinsurer => {
        const indexToDelete = updatedSigPages.findIndex(
          sigPage => sigPage.reinsurerName === reinsurer
        )
        if (indexToDelete >= 0) {
          updatedSigPages.splice(indexToDelete, 1)
        }
      })

      let reinsurerIndex = updatedSigPages.findIndex(
        sigPage =>
          sigPage.reinsurerName === state.selectedReinsurer?.reinsurerName
      )
      if (reinsurerIndex < 0) {
        reinsurerIndex = 0
      }

      return adapter.updateOne(
        {
          id: state.selectedContract.pageSetName,
          changes: {
            reinsurers: [...updatedSigPages],
          },
        },
        {
          ...state,
          selectedContract: {
            ...state.selectedContract,
            reinsurers: [...updatedSigPages],
          },
          selectedReinsurer: {
            ...updatedSigPages[reinsurerIndex],
          },
          currentPageIndex: reinsurerIndex,
          dirty: true,
          dirtyContracts: [
            ...new Set([
              ...state.dirtyContracts,
              state.selectedContract.pageSetName,
            ]),
          ],
          /* need to add sig pages to delete since PUT won't remove the deleted layer */
        }
      )
    }
  ),

  on(fromContractsActions.resetSelectedContractSuccess, (state, { fots }) => {
    if (!state.selectedContract?.reinsurers) {
      return state
    }
    const updatedSigPages: SignatureReinsurer[] = []
    const updatedFots: FotState[] = []
    const orderOfFots = state.selectedContract.reinsurers[0].fotsReinsured.map(
      fot => {
        const fotId =
          state.groupOrStructureFilterSelection === 'Group'
            ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : state.groupOrStructureFilterSelection === 'SL'
            ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
        return fotId
      }
    ) /* Keep previous order of FOTs */

    state.selectedContract.reinsurers.forEach(reinsurer => {
      const reinsurerFotsUpdated: ReinsuredFot[] = []
      reinsurer.fotsReinsured.forEach(fot => {
        const fotId =
          state.groupOrStructureFilterSelection === 'Group'
            ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : state.groupOrStructureFilterSelection === 'SL'
            ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
        const updatedFot = fots.find(f => fotId === f.id)
        if (
          updatedFot &&
          !updatedFots.find(updated => updated.id === updatedFot.id)
        ) {
          updatedFots.push(updatedFot)
        }
        const hasThisReinsurerAssigned = updatedFot?.assignedLines?.find(
          assigned =>
            assigned.reinsurer === reinsurer.reinsurerName &&
            assigned.signed !== 0 &&
            assigned.placedThrough === 'Lockton Re'
        )
        if (updatedFot && hasThisReinsurerAssigned) {
          reinsurerFotsUpdated.push({
            id: undefined,            
            programGroupId: updatedFot.programGroupId,
            programGroupName: updatedFot.programGroupName,
            structureId: updatedFot.structureId,
            structureName: updatedFot.structureName,
            sharedLimitId: updatedFot.sharedLimitId,
            sharedLimitName: updatedFot.sharedLimitName,
            layerRef: updatedFot.layerRef,
            layerName: updatedFot.layerName,
            layerType: updatedFot.layerType,
            layerCurrency: updatedFot.layerCurrency,
            reinsurerPhaseVersion: updatedFot.reinsurerPhaseVersion,
            reinsurerPhaseLabel: updatedFot.reinsurerPhaseLabel,
            subjectivities: hasThisReinsurerAssigned.subjectivity ?? '',
            occurrence: updatedFot.occurrence
              ? updatedFot.occurrence >= UNLIMITED_VAL
                ? UNLIMITED_VAL
                : updatedFot.occurrence
              : 0,
            percentage: hasThisReinsurerAssigned.signed ?? 0,
            brokerageType: updatedFot.brokerageType,
            brokeragePercent: hasThisReinsurerAssigned.brokerage,
            reinstatementPercent: hasThisReinsurerAssigned.brokerageRe,
            reinsuranceBrokeragePayable: '',
            riskReinsurerId: updatedFot.riskReinsurerId,
            fotPositionInContract: orderOfFots.indexOf(fotId),
          })
        }
      })
      if (reinsurerFotsUpdated.length > 0) {
        updatedSigPages.push({
          ...reinsurer,
          id: undefined,
          fotsReinsured: reinsurerFotsUpdated,
        })
      }
    })

    /* Add new assigned lines */
    updatedFots.forEach(updatedFot => {
      updatedFot.assignedLines?.forEach(assigned => {
        const pageExists = updatedSigPages.find(
          r => r.reinsurerName === assigned.reinsurer
        )
        const reinsurerSignedMoreThanZero = updatedFots.find(fot =>
          fot.assignedLines?.find(
            r =>
              r.reinsurer === assigned.reinsurer &&
              r.signed !== 0 &&
              r.placedThrough === 'Lockton Re'
          )
        )
        if (!pageExists && reinsurerSignedMoreThanZero) {
          const refNums = updatedFot.refTypesFromSelection?.find(
            ref => ref.reinsurer === assigned.reinsurer
          )
          updatedSigPages.push({
            ...updatedSigPages[0],
            id: undefined,
            reinsurerName: assigned.reinsurer ?? '',
            aiinReferenceNumber: refNums?.aiinReferenceNumber,
            feinReferenceNumber: refNums?.feinReferenceNumber,
            naicReferenceNumber: refNums?.naicReferenceNumber,
            fotsReinsured: [
              {
                id: undefined,
                programGroupId: updatedFot.programGroupId,
                programGroupName: updatedFot.programGroupName,
                structureId: updatedFot.structureId,
                structureName: updatedFot.structureName,
                sharedLimitId: updatedFot.sharedLimitId,
                sharedLimitName: updatedFot.sharedLimitName,
                layerRef: updatedFot.layerRef,
                layerName: updatedFot.layerName,
                layerType: updatedFot.layerType,
                layerCurrency: updatedFot.layerCurrency,
                reinsurerPhaseVersion: updatedFot.reinsurerPhaseVersion,
                reinsurerPhaseLabel: updatedFot.reinsurerPhaseLabel,
                subjectivities: assigned.subjectivity ?? '',
                occurrence: updatedFot.occurrence
                  ? updatedFot.occurrence >= UNLIMITED_VAL
                    ? UNLIMITED_VAL
                    : updatedFot.occurrence
                  : 0,
                percentage: assigned.signed ?? 0,
                brokerageType: updatedFot.brokerageType,
                brokeragePercent: assigned.brokerage,
                reinstatementPercent: assigned.brokerageRe,
                reinsuranceBrokeragePayable: '',
                riskReinsurerId: updatedFot.riskReinsurerId,
                fotPositionInContract: orderOfFots.indexOf(updatedFot.id),
              },
            ],
          })
        }
      })
    })

    /* Add back unassigned lines */
    for (const i in updatedSigPages) {
      if (!updatedSigPages[i]) {
        continue
      }
      updatedFots.forEach(f => {
        if (
          !updatedSigPages[i].fotsReinsured.find(fot => {
            const fotId =
              state.groupOrStructureFilterSelection === 'Group'
                ? `${fot.programGroupId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : state.groupOrStructureFilterSelection === 'SL'
                ? `${fot.sharedLimitId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
                : `${fot.structureId}-${fot.layerRef}-${fot.reinsurerPhaseLabel}`
            return fotId === f.id
          })
        ) {
          /* means this reinsurer was not assigned to this FOT */
          updatedSigPages[i].fotsReinsured.push({
            id: undefined,
            programGroupId: f.programGroupId,
            programGroupName: f.programGroupName,
            structureId: f.structureId,
            structureName: f.structureName,
            sharedLimitId: f.sharedLimitId,
            sharedLimitName: f.sharedLimitName,
            layerRef: f.layerRef,
            layerName: f.layerName,
            layerType: f.layerType,
            layerCurrency: f.layerCurrency,
            reinsurerPhaseVersion: f.reinsurerPhaseVersion,
            reinsurerPhaseLabel: f.reinsurerPhaseLabel,
            subjectivities: '',
            occurrence: f.occurrence
              ? f.occurrence >= UNLIMITED_VAL
                ? UNLIMITED_VAL
                : f.occurrence
              : 0,
            percentage: 0 /* FOTs that aren't assigned to a reinsurer get added with a 0 signed % */,
            brokerageType: f.brokerageType,
            brokeragePercent: 0,
            reinstatementPercent: 0,
            reinsuranceBrokeragePayable: '',
            riskReinsurerId: f.riskReinsurerId,
            fotPositionInContract: orderOfFots.indexOf(f.id),
          })
        }
      })
      /* Resort updated fots */
      updatedSigPages[i].fotsReinsured.sort(
        (a, b) => a.fotPositionInContract - b.fotPositionInContract
      )
    }

    let reinsurerIndex = updatedSigPages.findIndex(
      sigPage =>
        sigPage.reinsurerName === state.selectedReinsurer?.reinsurerName
    )
    if (reinsurerIndex < 0) {
      reinsurerIndex = 0
    }

    return adapter.updateOne(
      {
        id: state.selectedContract.pageSetName,
        changes: {
          id: undefined,
          reinsurers: updatedSigPages,
        },
      },
      {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          id: undefined,
          reinsurers: [...updatedSigPages],
        },
        selectedReinsurer: {
          ...updatedSigPages[reinsurerIndex],
        },
        currentPageIndex: reinsurerIndex,
        dirty: true,
        dirtyContracts: [
          ...new Set([
            ...state.dirtyContracts,
            state.selectedContract.pageSetName,
          ]),
        ],
        /* need to add sig pages to delete since PUT won't remove the deleted layer */
      }
    )
  }),

  on(
    fromContractsActions.savePostSignatureContracts,
    fromContractsActions.savePutSignatureContracts,
    state => ({
      ...state,
      saving: true,
    })
  ),

  on(
    fromContractsActions.savePostSignatureContractsSuccess,
    fromContractsActions.savePutSignatureContractsSuccess,
    (state, { contracts }) => {
      const updates: Update<SignatureContract>[] = contracts.map(contract => {
        return {
          id: contract.pageSetName,
          changes: {
            ...contract,
          },
        }
      })

      const updatedSelectedContract = contracts.find(
        c => c.pageSetName === state.selectedContract?.pageSetName
      )
      const updatedSelectedReinsurer = updatedSelectedContract?.reinsurers.find(
        r => r.reinsurerName === state.selectedReinsurer?.reinsurerName
      )

      return adapter.updateMany(updates, {
        ...state,
        selectedContract: updatedSelectedContract ?? state.selectedContract,
        selectedReinsurer: updatedSelectedReinsurer ?? state.selectedReinsurer,
        dirty: false,
        dirtyContracts: [],
        saving: false,
      })
    }
  ),

  on(
    fromContractsActions.deleteSignatureContractSuccess,
    (state, { contract }) => {
      if (!state.entities[contract.pageSetName]?.id) {
        /* if contract in state was reset, don't remove it */
        return state
      }
      return adapter.removeOne(contract.pageSetName, {
        ...state,
        selectedContract: undefined,
        selectedReinsurer: undefined,
        currentPageIndex: 0,
      })
    }
  ),
  on(fromContractsActions.deleteSignatureReinsurer, (state, { reinsurer }) => {
    const updatedSigPages = clone(state.selectedContract?.reinsurers)
    if (!updatedSigPages) {
      return state
    }
    const reinsurerIndex =
      updatedSigPages.findIndex(
        r => r.reinsurerName === reinsurer.reinsurerName
      ) ?? -1
    if (reinsurerIndex < 0 || !state.selectedContract?.pageSetName) {
      return state
    }
    updatedSigPages?.splice(reinsurerIndex, 1)
    const updatedIndex =
      reinsurerIndex > updatedSigPages.length - 1 ? 0 : reinsurerIndex

    return adapter.updateOne(
      {
        id: state.selectedContract.pageSetName,
        changes: {
          reinsurers: updatedSigPages,
        },
      },
      {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          reinsurers: [...updatedSigPages],
        },
        selectedReinsurer: {
          ...updatedSigPages[updatedIndex],
        },
        currentPageIndex: updatedIndex,
        dirty: true,
        dirtyContracts: [
          ...new Set([
            ...state.dirtyContracts,
            state.selectedContract.pageSetName,
          ]),
        ],
        /* need to add sig pages to delete since PUT won't remove the deleted reinsurer */
      }
    )
  }),

  on(fromContractsActions.exportSignatureContract, state => ({
    ...state,
    exporting: true,
  })),

  on(fromContractsActions.exportSignatureContractSuccess, state => ({
    ...state,
    exporting: false,
  })),

  on(fromSignatureActions.resetSignatureData, state => {
    return adapter.removeAll(state)
  }),

  on(fromSignatureActions.resetSignatureProperties, state => {
    return {
      ...state,
      selectedContract: undefined,
      selectedReinsurer: undefined,
      currentPageIndex: 0,
      dirty: false,
      dirtyContracts: [],
      saving: false,
      exporting: false,
      groupOrStructureFilterSelection: null,
    }
  })
)

export function reducer(state: State | undefined, action: Action) {
  return ContractsReducer(state, action)
}
