import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromOptimizationReducer from './optimization.reducer'
import * as fromOptimizationCandidatesResultsReducer from './optimization-candidates-results.reducer'
import {
  LayerModelingDatum,
  LayerModelingDimension,
  LayerModelingView,
} from '../../layer-modeling/layer-modeling.model'
import { LayerModelingState } from '../../layer-modeling/store/layer-modeling.reducer'
import {
  OptimizationCandidateResultDenormalized,
  denormalize,
  OptimizationCandidateResult,
  OptimizationRangesTypes,
} from '../optimization.model'
import LayerModelingProps from '../../layer-modeling/layer-modeling-defs'
import { isLayerMetricApplicable } from '../../model/layer-metric-applicability'
import { analyzereConstants } from '@shared/constants/analyzere'
import * as fromOptimizationLayersReducer from './optimization-layers.reducer'
import * as fromOptimizationRangesTypesReducer from './optimization-ranges-types.reducer'
import { values } from 'ramda'
import { Coord } from '@graphing/utils/coord'
import { rejectNil } from '@shared/util/operators'

export const selectOptimizationState =
  createFeatureSelector<fromOptimizationReducer.State>(
    fromOptimizationReducer.OPTIMIZATION_FEATURE_KEY
  )

// Ranges Types Selectors
export const selectOptimizationRangesTypesState = createSelector(
  selectOptimizationState,
  state => state.rangesTypes
)
const { selectAll: selectRangesTypesEntities } =
  fromOptimizationRangesTypesReducer.adapter.getSelectors(
    selectOptimizationRangesTypesState
  )

export const selectRangesTypes = createSelector(
  selectRangesTypesEntities,
  (states): OptimizationRangesTypes[] => {
    return states.map(s => ({
      id: s.id,
      lossSetGroupIDs: s.lossSetGroupIDs,
      ranges: rejectNil(values(s.entities)),
    }))
  }
)

// Layers
export const selectCandidateLayersState = createSelector(
  selectOptimizationState,
  state => state.candidateLayers
)

export const {
  selectAll: selectCandidateLayers,
  selectEntities: selectCandiateLayersByID,
} = fromOptimizationLayersReducer.adapter.getSelectors(
  selectCandidateLayersState
)

export const selectCandidateLayersLoading = createSelector(
  selectCandidateLayersState,
  state => state.loading
)
export const selectCandidateLayersError = createSelector(
  selectCandidateLayersState,
  state => state.error
)

export const selectIncludedCandidateLayers = createSelector(
  selectCandidateLayers,
  state => state.filter(s => s.include)
)

export const selectLayersMetricsLoading = createSelector(
  selectCandidateLayersState,
  state => state.metricsLoading
)
export const selectLayersMetricsError = createSelector(
  selectCandidateLayersState,
  state => state.metricsError
)

// Candidate Results
export const selectCandidatesResultState = createSelector(
  selectOptimizationState,
  state => state.candidateResults
)

export const {
  selectAll: selectCandidatesResultsEntities,
  selectEntities: selectCandidatesResultsEntitiesByID,
} = fromOptimizationCandidatesResultsReducer.adapter.getSelectors(
  selectCandidatesResultState
)

export const selectCandidatesResults = createSelector(
  selectCandidatesResultsEntities,
  selectCandidateLayers,
  (candidates, candidateLayers) => {
    return candidates.map(c => {
      return {
        ...c,
        candidateLayers: candidateLayers.filter(l =>
          c.candidateLayers.includes(l.id)
        ),
      }
    }) as OptimizationCandidateResult[]
  }
)

export const selectCandidatesResultsByID = createSelector(
  selectCandidatesResults,
  state => {
    return state.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {} as Record<string, OptimizationCandidateResult>)
  }
)

export const selectCandidatesResultError = createSelector(
  selectCandidatesResultState,
  state => state.error
)

export const selectCandidatesResultLoading = createSelector(
  selectCandidatesResultState,
  state => state.loading
)

export const selectCandidatesResultsView = createSelector(
  selectCandidatesResultState,
  state => state.view
)

export const selectChartDimensionState = createSelector(
  selectCandidatesResultState,
  state => state.chartState
)

export const selectSaving = createSelector(
  selectCandidatesResultState,
  state => state.saving
)
export const selectSavingError = createSelector(
  selectCandidatesResultState,
  state => state.error
)

export const selectLastCreated = createSelector(
  selectCandidatesResultState,
  state => state.lastCreated
)

export const selectChartView = createSelector(
  selectChartDimensionState,
  selectCandidatesResults,
  (state, views): LayerModelingView => {
    if (views.length === 0) {
      return { loading: true, data: [] }
    }

    const data = views.flatMap(denormalize).map(createDatum(state))
    return {
      loading: false,
      data,
      secondaryLines: getSecondaryLines(data).coord,
      colors: getSecondaryLines(data).color,
    }
  }
)

export const selectCandidatesResultsToSave = createSelector(
  selectCandidatesResults,
  state => state.filter(s => s.savePortfolio)
)

export const selectTailMetricsState = createSelector(
  selectCandidatesResultState,
  state => state.tailMetrics
)

export const selectPortfolioTailMetrics = createSelector(
  selectTailMetricsState,
  state => state.portfolio
)

const makeGetValue =
  (state: LayerModelingState, view: OptimizationCandidateResultDenormalized) =>
    (dimension: LayerModelingDimension): number => {
      const propID = state[dimension]
      const prop = LayerModelingProps.find(p => p.id === propID)

      if (!prop) {
        throw Error(`Property '${propID} not found.`)
      }

      if (!isLayerMetricApplicable(view, prop)) {
        return 0
      }

      const value = view[propID]

      if (value == null || typeof value !== 'number') {
        if (propID === 'portfolioEfficiencyScore') {
          return 0
        } else {
          throw Error(
            `${dimension} '${propID}' value is not a number (${value}).`
          )
        }
      }
      if (value >= analyzereConstants.unlimitedValue) {
        return 0
      }
      return value
    }

const createDatum =
  (state: LayerModelingState) =>
    (view: OptimizationCandidateResultDenormalized): LayerModelingDatum => {
      const getValue = makeGetValue(state, view)
      const x = getValue('x')
      const y = getValue('y')
      const size = getValue('size')
      const description = `Layer ${view.layerNumber}`
      const layerType = `${view.programType}_${view.layerType}`
      const lossSetGroup = `${view.lossSetGroupID}`
      return { description, layerType, lossSetGroup, x, y, size }
    }

const getSecondaryLines = (
  data: LayerModelingDatum[]
): { coord: Coord[][]; color: string[] } => {
  if (data.length > 0) {
    const coord: Coord[][] = []
    const color: string[] = []
    data.forEach((d, i) => {
      if (i + 1 < data.length) {
        if (
          d.layerType === data[i + 1].layerType &&
          d.lossSetGroup === data[i + 1].lossSetGroup
        ) {
          const value = [
            {
              x: d.x,
              y: d.y,
            },
            {
              x: data[i + 1].x,
              y: data[i + 1].y,
            },
          ]
          coord.push(value)
          if (
            d.layerType === 'xl' ||
            d.layerType === 'cat_xl' ||
            d.layerType === 'noncat_xl'
          ) {
            color.push('app-palette-cat_xl')
          } else {
            color.push('app-palette-cat_qs')
          }
        }
      }
    })
    return { coord, color }
  }
  return { coord: [], color: [] }
}
