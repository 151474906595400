import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import {
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { Metrics } from '../../../api/analyzere/analyzere.model'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { MaybeError } from '../../../api/model/api.model'
import { concatMapWithInput, rejectErrorWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import {
  toPortfolioViewMetrics,
  updatePortfolioViewMetricsPayload,
} from '../../model/metrics.converter'
import { PortfolioType } from '../../model/portfolio-metrics.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import {
  selectCurrentStructureID,
  selectEditorPortfolioSetID,
  selectPortfolioViewDetailMetricsState,
  selectPortfolioViewMetrics,
  selectPortfolioViewState,
} from '../analysis.selectors'
import * as fromPortfolioView from '../views/portfolio-view.reducer'
import * as fromPortfolioViewMetricActions from './portfolio-metrics.actions'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import { updateTailMetrics } from '../../../core/store/program/program.actions'
// tslint:disable: no-non-null-assertion
export interface PortfolioViewMetricsAndAction {
  metrics: Record<string, Metrics[]>
  aggregationMethod: AggregationMethodType
  perspective: Perspective
  portfolioType: PortfolioType
  lossFilter: string
  returnPeriod1: number
  returnPeriod2: number
  returnPeriod3: number
  returnPeriod4: number
  returnPeriod5: number
}

@Injectable()
export class PortfolioViewMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  updateAndFetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioViewMetricActions.updateAndFetch),
      map(({ type, ...props }) => props),
      concatMap(props => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectEditorPortfolioSetID)),
            this.store.pipe(
              select(selectPortfolioViewMetrics, { portfolioSetID })
            ),
            this.store.pipe(
              select(selectPortfolioViewState, { portfolioSetID })
            )
          ),
          map(([_, editorSetID, metrics, portfolioViewState]) => ({
            change: props.change,
            portfolioSetID: portfolioSetID || editorSetID,
            metrics,
            portfolioViewState,
          }))
        )
      }),
      filter(({ portfolioSetID }) => portfolioSetID != null),
      map(({ change, portfolioSetID, metrics, portfolioViewState }) => {
        // tslint:disable-next-line: no-non-null-assertion
        const metric = metrics!
        let id: string
        if (change.id) {
          id = change.id
        } else {
          id = change.portfolioType
            ? this.getId(change.portfolioType, portfolioViewState) || ''
            : this.getId(metric.portfolioType, portfolioViewState) || ''
        }
        return fromPortfolioViewMetricActions.fetchPortfolioViewMetrics(
          updatePortfolioViewMetricsPayload(portfolioSetID!, id, metric, change)
        )
      })
    )
  })

  private getId(type: PortfolioType, state: fromPortfolioView.State) {
    if (type === 'Ceded') {
      return state.cededPortfolioViewID
    } else if (type === 'Gross') {
      return state.grossPortfolioViewID
    } else {
      return state.netPortfolioViewID
    }
  }

  fetchAndCalculate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioViewMetricActions.fetchPortfolioViewMetrics),
      map(({ type, ...props }) => props),
      concatMap(props => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectPortfolioViewState, { portfolioSetID })
            )
          )
        )
      }),
      concatMapWithInput(([props, state]) => {
        let httpObservable
        if (props.portfolioType === 'Ceded') {
          httpObservable = this.service.getMultiPortfolioViewMetrics(
            [state.grossPortfolioViewID!, state.netPortfolioViewID!],
            props.aggregationMethod,
            props.perspective,
            props.lossFilter,
            this.transformReturnPeriod(props.returnPeriod1),
            this.transformReturnPeriod(props.returnPeriod2),
            this.transformReturnPeriod(props.returnPeriod3),
            this.transformReturnPeriod(props.returnPeriod4),
            this.transformReturnPeriod(props.returnPeriod5)
          )
        } else {
          httpObservable = this.service.getMultiPortfolioViewMetrics(
            [props.id],
            props.aggregationMethod,
            props.perspective,
            props.lossFilter,
            this.transformReturnPeriod(props.returnPeriod1),
            this.transformReturnPeriod(props.returnPeriod2),
            this.transformReturnPeriod(props.returnPeriod3),
            this.transformReturnPeriod(props.returnPeriod4),
            this.transformReturnPeriod(props.returnPeriod5)
          )
        }
        return httpObservable.pipe(
          map(res => {
            if (res.error) {
              return res as PortfolioViewMetricsAndAction & MaybeError
            } else {
              return {
                data: {
                  metrics: res.data,
                  aggregationMethod: props.aggregationMethod,
                  portfolioType: props.portfolioType,
                  returnPeriod1: props.returnPeriod1,
                  returnPeriod2: props.returnPeriod2,
                  returnPeriod3: props.returnPeriod3,
                  returnPeriod4: props.returnPeriod4,
                  returnPeriod5: props.returnPeriod5,
                  perspective: props.perspective,
                  lossFilter: props.lossFilter,
                },
              } as { data: PortfolioViewMetricsAndAction }
            }
          })
        )
      }),
      rejectErrorWithInput((error, [props]) =>
        this.store.dispatch(
          fromPortfolioViewMetricActions.fetchPortfolioViewMetricsFailure({
            ...extractPortfolioSetID(props)!,
            error,
          })
        )
      ),
      concatMap(([response, [props, state]]) => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of([response, [props, state]] as const).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectPortfolioViewDetailMetricsState, { portfolioSetID })
            )
          )
        )
      }),
      withLatestFrom(this.store.pipe(select(selectCurrentStructureID))),
      mergeMap(([[[response, [action, state]], metrics], structrureID]) => {
        const metricsOptions = toPortfolioViewMetrics(
          response,
          state.grossPortfolioViewID!,
          state.cededPortfolioViewID!,
          state.netPortfolioViewID!
        )
        const actions: Action[] = [
          fromPortfolioViewMetricActions.fetchPortfolioViewMetricsSuccess({
            ...extractPortfolioSetID(action)!,
            metrics: metricsOptions,
            netPortfolioViewID: state.netPortfolioViewID!,
            grossPortfolioViewID: state.grossPortfolioViewID!,
            cededPortfolioViewID: state.cededPortfolioViewID!,
            grossMetricsExpense: metrics.grossCalculatedMetrics!.expense,
            cededMetricsExpense: metrics.cededCalculatedMetrics!.expense,
            netMetricsExpense: metrics.netCalculatedMetrics!.expense,
          }),
        ]
        if (structrureID) {
          actions.push(
            updateTailMetrics({
              id: structrureID as string,
              tailMetrics: metricsOptions,
            })
          )
        }
        return actions
      })
    )
  })

  updateAndFetchExpectedSD$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioViewMetricActions.fetchPortfolioViewMetricsSuccess),
      concatMap(action => {
        const id: string[] = []
        switch (action.metrics.portfolioType) {
          case 'Ceded': {
            id.push(action.grossPortfolioViewID)
            id.push(action.netPortfolioViewID)
            break
          }
          case 'Gross': {
            id.push(action.grossPortfolioViewID)
            break
          }
          case 'Net': {
            id.push(action.netPortfolioViewID)
            break
          }
        }

        return this.service
          .getMultiPortfolioViewMetrics(
            id,
            'AEP',
            action.metrics.perspective,
            action.metrics.lossFilter,
            this.transformReturnPeriod(1),
            this.transformReturnPeriod(action.metrics.returnPeriod2),
            this.transformReturnPeriod(action.metrics.returnPeriod3),
            this.transformReturnPeriod(action.metrics.returnPeriod4),
            this.transformReturnPeriod(action.metrics.returnPeriod5)
          )
          .pipe(
            map(res => {
              const val = {
                data: {
                  metrics: res.data,
                  aggregationMethod: 'AEP',
                  portfolioType: action.metrics.portfolioType,
                  lossFilter: action.metrics.lossFilter,
                  returnPeriod1: 1,
                  returnPeriod2: action.metrics.returnPeriod2,
                  returnPeriod3: action.metrics.returnPeriod3,
                  returnPeriod4: action.metrics.returnPeriod4,
                  returnPeriod5: action.metrics.returnPeriod5,
                  perspective: action.metrics.perspective,
                },
              } as { data: PortfolioViewMetricsAndAction }
              return {
                val,
                netPortfolioViewID: action.netPortfolioViewID,
                grossPortfolioViewID: action.grossPortfolioViewID,
                cededPortfolioViewID: action.cededPortfolioViewID,
                grossMetricsExpense: action.grossMetricsExpense,
                cededMetricsExpense: action.cededMetricsExpense,
                netMetricsExpense: action.netMetricsExpense,
                action,
              }
            })
          )
      }),
      map(res => {
        const objectRes = toPortfolioViewMetrics(
          res.val.data,
          res.grossPortfolioViewID,
          res.cededPortfolioViewID,
          res.netPortfolioViewID
        )
        return fromPortfolioViewMetricActions.setExpectedSD({
          ...extractPortfolioSetID(res.action)!,
          expected: objectRes.returnPeriodData[1].period1,
          stdDev: objectRes.returnPeriodData[2].period1,
        })
      })
    )
  })

  private transformReturnPeriod(value: number) {
    return 1 / value
  }
}
