import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
  UpdateCompareMetric,
} from '../../analysis/model/compare-metrics.model'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import {
  Reinsurer,
  ReinsurerFilter,
  SelectedUserPreferences,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { Client } from '../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer',
  styleUrls: ['./reinsurer.component.scss'],
  templateUrl: './reinsurer.component.html',
})
export class ReinsurerComponent implements OnChanges {
  filteredReinsurers: Reinsurer[] | null = []
  opportunityRenewed: boolean
  @Input() name = 'Reinsurer Selection'
  @Input() client: Client | null
  @Input() clientID: string | null
  @Input() yearID: string | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() studySelected: string | null
  @Input() savingReinsurer: boolean
  @Input() reinsurers: Reinsurer[] | null
  @Input() reinsurersBlob: BlobResponse | null
  @Input() reinsurerFilters: ReinsurerFilter[]
  @Input() reinsurerDomicileList: string[]
  @Input() reinsurerSPRatingList: string[]
  @Input() reinsurerAmBestRatingList: string[]
  @Input() reinsurerDirty: boolean
  @Input() metricDirty: boolean
  @Input() lossFilters: LossFilter[]

  @Input() metricDetails: CompareMetricTableCategory[]

  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs?: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() clients: Client[]
  @Input() reinsurerPopulateFrom: Reinsurer[] | null
  @Input() selectedUserPreferences: SelectedUserPreferences | null
  @Input() userPreferencesColumnsList: string[]
  @Input() defaultUSAUserPreferences: SelectedUserPreferences

  @Output() saveClick = new EventEmitter()
  @Output() updateMetricElement = new EventEmitter<UpdateCompareMetric>()
  @Output() collapseToggle = new EventEmitter<string>()
  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurers: Reinsurer[]
  }>()
  @Output() setOneReinsurer = new EventEmitter<{
    programID: string
    reinsurer: Reinsurer
  }>()
  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() reinsurerFilterToggle = new EventEmitter<ReinsurerFilter>()
  @Output() removeAllFilters = new EventEmitter()
  @Output() removeFilter = new EventEmitter<ReinsurerFilter>()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()
  @Output() enableAllClick = new EventEmitter()
  @Output() populateSelectorClick = new EventEmitter<{
    client: string
    program: string
  }>()
  @Output() exportClick = new EventEmitter()
  @Output() showAgencyModal = new EventEmitter<{
    re: Reinsurer
    type: string
  }>()
  @Output() showFundManagerModal = new EventEmitter<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()
  @Output() deleteRe = new EventEmitter<Reinsurer>()

  get activeAction(): string {
    if (this.savingReinsurer) {
      return 'Saving'
    } else {
      return ''
    }
  }

  ngOnChanges(): void {
    this.filteredReinsurers =
      this.reinsurers?.filter(r => !r.del_ind || r.is_default) || []
    if (this.accountOpportunities && this.selectedProgramID) {
      this.checkForRenewal()
    }
  }
  checkForRenewal(): void {
    const selectedProgram = this.programs?.find(
      p => p.id === this.selectedProgramID
    )
    const opportunity = this.accountOpportunities?.find(
      o => o.id === selectedProgram?.opportunity_id
    )
    this.opportunityRenewed = !!opportunity?.opportunityRenewedFrom
  }
}
