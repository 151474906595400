import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import {
  PivotTableColumn,
  PivotTableRowDef,
} from '../pivot-table.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrls: ['./pivot-table.component.scss'],
})
export class PivotTableComponent<T> {
  @Input() pivotTableColumns: PivotTableColumn<T>[]
  @Input() pivotTableRowDefs: PivotTableRowDef<T>[]
  @Input() width: string
  @Input() height: string

  valueTransform(val: string | number, rowDef: PivotTableRowDef): string {
    const { type, decimals, currencySymbol } = rowDef
    switch (type) {
      case 'numeric':
      case 'numeric-transform': {
        return Number(val)
          .toFixed(decimals)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      case 'percentage-transform': {
        return `${(Number(val) * 100).toFixed(decimals)}%`
      }
      case 'percentage': {
        return `${Number(val).toFixed(decimals)}%`
      }
      case 'currency': {
        return `${currencySymbol}${val}`
      }
      case 'string':
      default: {
        return val.toString()
      }
    }
  }
}
