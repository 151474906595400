import { Action, combineReducers } from '@ngrx/store'
import * as fromFots from './fots/fot.reducer'
import * as fromContracts from './contracts/contracts.reducer'

export const FEATURE_KEY = 'signature'
export interface State {
  fots: fromFots.State
  contracts: fromContracts.State
}

const signatureReducer = combineReducers<State>({
  fots: fromFots.reducer,
  contracts: fromContracts.reducer,
})

export function reducer(state: State | undefined, action: Action) {
  return signatureReducer(state, action)
}
