<app-return-period-table
  [data]="returnPeriodData"
  [rp1]="returnPeriod1"
  [rp2]="returnPeriod2"
  [rp3]="returnPeriod3"
  [rp4]="returnPeriod4"
  [rp5]="returnPeriod5"
  [metrics]="metrics"
  noPadding
  noNames
  showTogglesButton
  (rp1Change)="returnPeriod1Change.emit($event)"
  (rp2Change)="returnPeriod2Change.emit($event)"
  (rp3Change)="returnPeriod3Change.emit($event)"
  (rp4Change)="returnPeriod4Change.emit($event)"
  (rp5Change)="returnPeriod5Change.emit($event)"
  (portfolioTypeChange)="portfolioTypeChange.emit($event)"
  (perspectiveChange)="perspectiveChange.emit($event)"
  (aggregationMethodChange)="aggregationMethodChange.emit($event)"
></app-return-period-table>
