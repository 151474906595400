<div class="form-wrapper">
  <form class="contract">
    <section>
      <div>
        <span class="instructions">
          Select the target OpenTWINS Risk Reference for this contract and select the appropriate checkbox if it's a co-risk in a wholesale deal.
        </span>
      </div>
    </section>
    <br/>
    <section class="riskRefSelection">
      <div>
        <span
          (click)="onRiskRefSelectClick()"
          (click)="$event.stopPropagation()"
        >Select the target Risk Reference for this contract
          <button
            appButtonIcon
            big
            class="material-icons"
            matTooltip="Edit Risk Ref"
            matTooltipPosition="after"
          >
            <mat-icon *ngIf="renewalRiskRefs" inline color="primary">edit</mat-icon>
          </button>
        </span>
      </div>
      <br/>
      <div>
        <mat-chip-row
          class="app-chip-palette app-palette-teal"
          matTooltip="{{ selectedRiskRef }}"
          matTooltipPosition="after"
          removable="true"
          (removed)="removeRiskRef()"
          *ngIf="selectedRiskRef"
        >{{ selectedRiskRef }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
      </div>
    </section>

    <section class="coRiskCheck">
      <div>
        <span>Is this a co-risk (wholesale business with a separate lead risk)?:
          <mat-radio-group (change)="onCoRiskRadioChange($event)">
            <mat-radio-button *ngFor="let option of coRiskOptions" [value]="option"
              class="coRisk-radio-option">
              {{option}}
            </mat-radio-button>
          </mat-radio-group>
        </span>
      </div>
    </section>

    <section *ngIf="isCoRiskContract" class="leadRiskSelection">
      <div>
        <span
          (click)="onLeadRiskRefSelectClick()"
          (click)="$event.stopPropagation()"
        >Select a Lead Risk Reference Number
          <button
            appButtonIcon
            big
            class="material-icons"
            matTooltip="Edit Lead Risk Ref"
            matTooltipPosition="after"
          >
            <mat-icon *ngIf="renewalRiskRefs" inline color="primary">edit</mat-icon>
          </button>
        </span>
      </div>
      <br/>
      <div>
        <mat-chip-row
          class="app-chip-palette app-palette-teal"
          matTooltip="{{ selectedLeadRiskRef }}"
          matTooltipPosition="after"
          removable="true"
          (removed)="removeLeadRiskRef()"
          *ngIf="selectedLeadRiskRef"
        >{{ selectedLeadRiskRef }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
      </div>
    </section>
  </form>
</div>
