import {inject, Injectable} from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { map, switchMap } from 'rxjs/operators'
import { BackendService } from '../../api/backend/backend.service'
import {
  CarrierResponse,
  CarrierYearResponse,
  StudyDataWithYear,
  StudyResponse,
} from '../../api/model/backend.model'
import {
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from '../../api/util'
import { AppState } from '../../core/store'
import * as fromProgramInitiationActions from './program-initation.actions'
import { convertPermissionsToEntitiesResponse } from '../../core/model/client.converter'

// tslint:disable: no-non-null-assertion
@Injectable()
export class ProgramInitiationEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private backendService: BackendService,
    private snackbar: MatSnackBar
  ) {}

  addNewProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProgramInitiationActions.createNewProgramFromOpportunity),
      switchMap(action => {
        return this.backendService.postStudy(action.program)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromProgramInitiationActions.createNewProgramFromOpportunityFailure({
            error,
          })
        )
      ),
      map(programResponse => {
        const newProgram = programResponse as StudyResponse
        this.snackbar.open(
          `Successfully Initiated Program ${newProgram.name}`,
          'X',
          {
            duration: 3000,
          }
        )
        return fromProgramInitiationActions.createNewProgramFromOpportunitySuccess(
          { newProgram }
        )
      })
    )
  })

  addNewYearAndProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProgramInitiationActions.createNewYearProgramFromOpportunity),
      switchMapWithInput(action => {
        const yearRequest = {
          year: parseInt(action.program.inceptionYear!, 10),
          carrier_id: action.program.carrier_id!,
        }
        return this.backendService.postYear(yearRequest)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromProgramInitiationActions.createNewYearProgramFromOpportunityFailure(
            {
              error,
            }
          )
        )
      ),
      switchMapWithInput(([carrierYearResponse, action]) => {
        const programRequest = {
          carrier_year_id: (carrierYearResponse as CarrierYearResponse).id,
          name: action.program.name,
          description: action.program.description,
          opportunity_id: action.program.opportunity_id,
        }
        return this.backendService.postStudy(programRequest)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromProgramInitiationActions.createNewYearProgramFromOpportunityFailure(
            {
              error,
            }
          )
        )
      ),
      map(([programResponse, [carrierYearResponse, action]]) => {
        const carrierYearRes = carrierYearResponse as CarrierYearResponse
        const newProgram = programResponse as StudyResponse
        this.snackbar.open(
          `Successfully Initiated Year ${carrierYearRes.year} and Program ${newProgram.name}`,
          'X',
          {
            duration: 3000,
          }
        )
        return fromProgramInitiationActions.createNewYearProgramFromOpportunitySuccess(
          {
            newProgram,
            year: action.program.inceptionYear!,
            carrierID: carrierYearRes.carrier_id.toString(),
          }
        )
      })
    )
  })

  addNewCarrierYearProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromProgramInitiationActions.createNewCarrierYearProgramFromOpportunity
      ),
      switchMapWithInput(action => {
        this.snackbar.open(
          `Creating new Carrier: ${action.carrier.name}...`,
          'X',
          {
            duration: 20000,
          }
        )
        return this.backendService.postAccountInitiationCarrier(action.carrier)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromProgramInitiationActions.createNewCarrierYearProgramFromOpportunityFailure(
            {
              error,
            }
          )
        )
      ),
      map(([carrierResponse, action]) => {
        const newCarrier = carrierResponse as CarrierResponse

        const program: StudyDataWithYear = {
          name: action.program.name!,
          description: action.program.description,
          opportunity_id: action.program.opportunity_id!,
          inceptionYear: action.program.inceptionYear!,
          carrier_id: newCarrier.id,
        }

        if (
          action.carrierLogoFormData &&
          action.carrierLogoFormData.get('fileKey')
        ) {
          this.backendService
            .postCarrierThumbnail(
              this.formatCarrierLogoData(
                action.carrierLogoFormData,
                newCarrier.id
              )
            )
            .subscribe(() => {
              this.store.dispatch(
                fromProgramInitiationActions.createNewYearProgramFromOpportunity(
                  {
                    program,
                  }
                )
              )
            })
        } else {
          this.store.dispatch(
            fromProgramInitiationActions.createNewYearProgramFromOpportunity({
              program,
            })
          )
        }

        return fromProgramInitiationActions.createNewCarrierYearProgramFromOpportunitySuccess(
          { newCarrier }
        )
      })
    )
  })

  updateSFAccountCarrierMapping$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProgramInitiationActions.updateSFAccountCarrierMapping),
      switchMap(action => {
        return this.backendService.updateCarrierMapping(action.accountMapping)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromProgramInitiationActions.updateSFAccountCarrierMappingFailure({
            error,
          })
        )
      ),
      switchMap(accountMapping => {
        this.snackbar.open(
          `Successfully Updated Carrier Mapping for ${accountMapping.sfAccountName} to ${accountMapping.carrierName}`,
          'X',
          {
            duration: 3000,
          }
        )
        return [
          fromProgramInitiationActions.updateSFAccountCarrierMappingSuccess({
            accountMapping,
          }),
          fromProgramInitiationActions.refreshCarrierYears(),
        ]
      })
    )
  })

  refreshCarrierYears$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProgramInitiationActions.refreshCarrierYears),
      switchMap(() => {
        return this.backendService.permissions()
      }),
      rejectError(error =>
        this.store.dispatch(
          fromProgramInitiationActions.refreshCarrierYearsFailure({
            error,
          })
        )
      ),
      map(permissions =>
        fromProgramInitiationActions.refreshCarrierYearsSuccess(
          convertPermissionsToEntitiesResponse(permissions)
        )
      )
    )
  })

  formatCarrierLogoData(carrierLogoData: any, carrierId: number): FormData {
    const blob = carrierLogoData
      .get('fileKey')
      .slice(0, carrierLogoData.get('fileKey').size, 'image/png')
    const carrierImage = new File([blob], `${carrierId}`, {
      type: 'image/png',
    })
    const carrierLogoFormData = new FormData(document.forms[0])
    carrierLogoFormData.append('file', carrierImage)

    return carrierLogoFormData
  }
}
