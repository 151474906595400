import {
  convertSignatureContractsFromResponse,
  convertSignatureContractsToRequestPost,
  convertSignatureContractFromResponse,
  convertSignatureContractsToRequestPut,
} from './signature-reinsurer.convertor'
import {
  RiskRefDetail,
  SalesforceClient,
  SignatureContractResponse,
} from './../model/signature.model'
import { SignatureContract } from './../../signature/signature.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResponse } from '../model/api.model'
import { environment } from '../../../environments/environment'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SignatureReinsurerService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  /* Salesforce Clients API */
  getAllClientsFromSalesforce(): ApiResponse<SalesforceClient[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signatureSalesforceClients}`
    return this.httpClient
      .get<SalesforceClient[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET All Clients from Salesforce')
      )
  }

  /* Risk Renewals API */
  getAllRenewalRiskRefs(
    programId: string | null
  ): ApiResponse<RiskRefDetail[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}/riskRefs?programId=${programId}`
    return this.httpClient
      .get<RiskRefDetail[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET All Signature Risk Ref(s) By ProgramId')
      )
  }

  /* Signature Contract APIs */
  getSignatureContracts(
    programId: string | null
  ): ApiResponse<SignatureContract[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}?programId=${programId}`
    return this.httpClient
      .get<SignatureContractResponse[]>(url)
      .pipe(
        map(convertSignatureContractsFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Signature Contract(s) By ProgramId')
      )
  }
  postSignatureContracts(
    contracts: Partial<SignatureContract>[]
  ): ApiResponse<SignatureContract[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}`
    const req = convertSignatureContractsToRequestPost(contracts)
    return this.httpClient
      .post<SignatureContractResponse[]>(url, req, this.httpOptions)
      .pipe(
        map(convertSignatureContractsFromResponse),
        mapToMaybeData(),
        catchAndHandleError('POST Signature Contract(s)')
      )
  }
  putSignatureContracts(
    contracts: Partial<SignatureContract>[]
  ): ApiResponse<SignatureContract[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}`
    const req = convertSignatureContractsToRequestPut(contracts)
    return this.httpClient
      .put<SignatureContractResponse[]>(url, req, this.httpOptions)
      .pipe(
        map(convertSignatureContractsFromResponse),
        mapToMaybeData(),
        catchAndHandleError('PUT Signature Contract(s)')
      )
  }
  deleteSignatureContract(id: number): ApiResponse<SignatureContract> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}/${id}`
    return this.httpClient
      .delete<SignatureContractResponse>(url)
      .pipe(
        map(convertSignatureContractFromResponse),
        mapToMaybeData(),
        catchAndHandleError('DELETE Signature Contract')
      )
  }
}
