<div class="main">
  <mat-form-field
    subscriptSizing="dynamic"
    floatLabel="always"
    appearance="fill"
    class="select"
  >
    <mat-label>Reinsurer Selection</mat-label>
    <mat-select
      [disabled]="
        isDisabled || isExpanded || isNameSelectDisable || showCustomCompare
      "
      [(value)]="selectedRe"
      (selectionChange)="onReinsurerSelection($event.value)"
      panelClass="reinsurer-panel"
    >
      <mat-option
        *ngFor="let re1 of reinsuranceList"
        [value]="re1"
        [matTooltip]="re1"
        matTooltipClass="metric-tooltip"
        matTooltipShowDelay="250"
        matTooltipPosition="right"
      >
        <span [ngClass]="{ 'fot-label': re1 === 'FOT MASTER' }">{{ re1 }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="reinsurerPlacedThrough" class="mega-div">
    <label> Placed through {{ reinsurerPlacedThrough }}</label>
  </div>

  <button
    *ngIf="!isFotMaster && !isExpiring"
    appButtonIcon
    gigantic
    class="icon decline"
    disableRipple
    [matMenuTriggerFor]="declineMenu"
    matTooltip="Click to open quote options."
    matTooltipPosition="right"
  >
    more_horiz
  </button>
  <mat-menu #declineMenu="matMenu">
    <button
      mat-menu-item
      [disabled]="isDeclined"
      (click)="declineConfirm = true"
    >
      Decline Quote
    </button>
  </mat-menu>
  <div class="assign-lines">
    <div
      class="export-check"
      matTooltip="Click to show/hide on Export"
      matTooltipPosition="right"
      *ngIf="!isDeclined"
    >
      <mat-slide-toggle
        class="check"
        [disabled]="isDisabled || isDeclined"
        [checked]="exportSelected"
        (change)="onUpdateExportToggleSelection($event)"
        >Show Export</mat-slide-toggle
      >
    </div>
    <div *ngIf="isFotMaster || isExpiring" class="align-tile">
      <button
        [disabled]="isDisabled || isDeclined"
        appButton
        big
        accent
        border
        tabindex="-1"
        (click)="onAssignedLinesClick()"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
  <div class="mega-div" *ngIf="declineConfirm">
    <div>
      <label>Decline Quote Reason: </label>
      <textarea [(ngModel)]="reason" autofocus></textarea>
    </div>
    <div class="decline">
      <button
        appButton
        big
        class="decline-button"
        border
        (click)="declineConfirm = false"
      >
        Cancel
      </button>
      <button appButton big class="decline-button" border (click)="decline()">
        Decline
      </button>
    </div>
  </div>
  <div class="mega-div decline-text" *ngIf="isDeclined">
    This Quote is Declined.
    <div
      class="reason"
      *ngIf="currentReinsurer && currentReinsurer.declineReason"
    >
      Reason: {{ currentReinsurer.declineReason }}
    </div>
  </div>
  <div class="mega-div">
    <div
      class="quote-label"
      *ngIf="
        selectedPhase === reinsurerPhases.Quote ||
        selectedPhase === reinsurerPhases.FOT ||
        selectedPhase === reinsurerPhases.Expiring
      "
    >
      <div class="quote-label-container">
        <mat-form-field
          subscriptSizing="dynamic"
          floatLabel="always"
          class="quote-label-form"
        >
          <mat-label class="label-form-label"
            >Current
            {{ selectedPhase }}
            Label</mat-label
          >
          <input
            matInput
            class="quote-label-input"
            [(ngModel)]="currentLabel"
            (change)="labelChange()"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="quote-phase">
      <div
        [class.active-dropdown]="selectedPhase === reinsurerPhases.Quote"
        [class.inactive-dropdown]="selectedPhase !== reinsurerPhases.Quote"
        *ngIf="!isFotMaster && !isExpiring"
      >
        <div class="quote-phase-label">
          <div class="label-container">
            <label bold> Quote </label>
            <div class="dropdown">
              <mat-select
                [disabled]="isDisabled || isExpanded || showCustomCompare"
                class="quote-select"
                [value]="currentLabel"
                (selectionChange)="onQuoteChange($event.value)"
              >
                <mat-option *ngFor="let q of getQuoteList" [value]="q">
                  <span matTooltip="{{ q }}" matTooltipPosition="right">{{
                    q
                  }}</span>
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="preferred right">
            <label> Preferred: </label>
            <mat-checkbox
              matTooltip="Check to make it Preferred Version for Quick Quote Panel"
              matTooltipPosition="above"
              [checked]="getPreferredChecked()"
              (click)="onPreferredCheckboxChanged()"
            ></mat-checkbox>
          </div>
        </div>
      </div>
      <div
        [class.active-dropdown]="selectedPhase === reinsurerPhases.FOT"
        [class.inactive-dropdown]="selectedPhase !== reinsurerPhases.FOT"
        *ngIf="isFotMaster"
      >
        <div class="quote-phase-label">
          <div class="label-container">
            <label> FOT </label>
            <div class="dropdown">
              <mat-label *ngIf="selectedPhase === reinsurerPhases.FOT">{{
                currentLabel
              }}</mat-label>
              <mat-select
                [disabled]="isDisabled || isExpanded || showCustomCompare"
                *ngIf="allowFOT"
                class="quote-select"
                [(value)]="currentLabel"
                (selectionChange)="onFOTChange($event.value)"
              >
                <mat-option *ngFor="let f of getFOTList" [value]="f">
                  <span matTooltip="{{ f }}" matTooltipPosition="right">{{
                    f
                  }}</span>
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="right">
            <label> Preferred: </label>
            <mat-checkbox
              matTooltip="Check to make it Preferred Version for Quick Quote Panel"
              matTooltipPosition="above"
              [checked]="getPreferredChecked()"
              (click)="onPreferredCheckboxChanged()"
            ></mat-checkbox>
          </div>
        </div>
      </div>
      <div
        [class.active-dropdown]="selectedPhase === reinsurerPhases.Expiring"
        [class.inactive-dropdown]="selectedPhase !== reinsurerPhases.Expiring"
        *ngIf="isExpiring"
      >
        <div class="quote-phase-label">
          <div class="label-container">
            <label> Expiring </label>
            <div class="dropdown">
              <mat-label *ngIf="selectedPhase === reinsurerPhases.Expiring">{{
                currentLabel
              }}</mat-label>
              <mat-select
                [disabled]="isDisabled || isExpanded || showCustomCompare"
                *ngIf="allowExpiring"
                class="quote-select"
                [(value)]="currentLabel"
                (selectionChange)="onExpiringChange($event.value)"
              >
                <mat-option *ngFor="let f of getExpiringList" [value]="f">
                  <span
                    matTooltip="{{ getExpiringSectionLabel(f) }}"
                    matTooltipPosition="right"
                    >{{ f }}</span
                  >
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="right">
            <label> Preferred: </label>
            <mat-checkbox
              matTooltip="Check to make it Preferred Version for Quick Quote Panel"
              matTooltipPosition="above"
              [checked]="getPreferredChecked()"
              (click)="onPreferredCheckboxChanged()"
            ></mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-expand-populate">
      <div class="quote-expand-populate-div">
        <button
          [disabled]="isDisabled || isDeclined"
          appButton
          big
          accent
          border
          tabindex="-1"
          [matTooltip]="getToolTip"
          matTooltipPosition="above"
          [matMenuTriggerFor]="moreMenu"
        >
          Populate From
        </button>
        <mat-menu #moreMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let s of populateFromList"
            (click)="onPopulateFrom(s)"
            matTooltip="{{ s.quoteReinsurerName }}, {{ s.reinsurerPhaseLabel }}"
            matTooltipPosition="right"
          >
            {{ s.quoteReinsurerName }}
            {{
              s.reinsurerPhase === reinsurerPhases.Quote ? s.reinsurerPhase : ''
            }}
            {{ s.reinsurerPhaseVersion }}
          </button>
        </mat-menu>
      </div>
      <div class="quote-expand-populate-div2">
        <button
          [disabled]="
            isDisabled || isExpanded || isDeclined || showCustomCompare
          "
          appButton
          big
          accent
          border
          tabindex="-1"
          (click)="onExpandClick()"
        >
          Expand
        </button>
      </div>
    </div>
    <div class="quote">
      <div class="button-inline">
        <button
          class="save"
          [disabled]="isDisabled || !isSaveDisabled || isDeclined"
          appButton
          big
          accent
          border
          tabindex="-1"
          (click)="save()"
        >
          <span *ngIf="isSaving && !isDisabled"> Saving... </span>
          <span *ngIf="isDisabled || !isSaving"> Save </span>
        </button>
        <i
          matTooltip="Delete"
          matTooltipPosition="above"
          class="material-icons delete-button"
          [ngClass]="isDisabled ? 'disable' : 'not-disable'"
          (click)="delete()"
        >
          delete
        </i>
      </div>
    </div>
  </div>
</div>
