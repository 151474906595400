<div *ngIf="open" class="wrapper">
  <section class="loss-sets" [class]="sectionClass('lossSets')">
    <h3
      appHeaderCollapse
      [useDefaultRightButton]="!showLossSetGroupEditor"
      [collapse]="collapseBySection['lossSets']"
      (collapseChange)="onLossSetHeaderClick($event)"
    >
      <div class="header-wrapper">
        <mat-icon *ngIf="showLossSetGroupEditor" inline>
          navigate_before
        </mat-icon>
        {{ lossSetLabel }}
      </div>
      <ng-template #rightButtonTemplate>
        <button appButton link small>Close</button>
      </ng-template>
    </h3>

    <div class="collapsible" [@collapse]="collapse('lossSets')">
      <app-layer-panel-loss-set-groups
        [lossSetGroups]="lossSetGroups"
        [lossSetGroupsMetrics]="lossSetGroupsMetrics"
        [showLossSetGroupEditor]="showLossSetGroupEditor"
        [groupEditSelectedLossSets]="groupEditSelectedLossSets"
        [lossSetGroupEditMode]="lossSetGroupEditMode"
        [lossSetSelectedGroup]="lossSetSelectedGroup"
        [lossSetSelectionMode]="lossSetSelectionMode"
        [currentCededLayer]="currentCededLayer"
        [programID]="programID"
        [allCededLayers]="allCededLayers"
        [lossSetGroupsActiveAction]="lossSetGroupsActiveAction"
        [parentLossSets]="parentLossSets"
        [grossLossSets]="grossLossSets"
        [designDirty]="designDirty"
        [metrics]="metrics"
        (lossSetGroupEditorClick)="lossSetGroupEditorClick.emit($event)"
        (groupEditLossSetSelection)="groupEditLossSetSelection.emit($event)"
        (lossSetGroupClick)="lossSetGroupClick.emit($event)"
        (deleteLossSetGroupClick)="deleteLossSetGroupClick.emit($event)"
        (lossSetSelection)="lossSetSelection.emit($event)"
        (grossLossSetSelection)="grossLossSetSelection.emit($event)"
        (newLossSetGroupClick)="newLossSetGroupClick.emit()"
        (cancelLossSetGroupClick)="cancelLossSetGroupClick.emit()"
        (saveLossSetGroupClick)="saveLossSetGroupClick.emit($event)"
        (updateLossSetGroupClick)="updateLossSetGroupClick.emit($event)"
        (lossSetGroupSetDirty)="lossSetGroupSetDirty.emit($event)"
        (grossSelectionModeClick)="grossSelectionModeClick.emit()"
        (removeRiskLossSets)="removeRiskLossSets.emit($event)"
        (addRiskLossSets)="addRiskLossSets.emit($event)"
        (returnPeriod1Change)="returnPeriod1Change.emit($event)"
        (returnPeriod2Change)="returnPeriod2Change.emit($event)"
        (returnPeriod3Change)="returnPeriod3Change.emit($event)"
      ></app-layer-panel-loss-set-groups>

      <div *ngIf="!showLossSetGroupEditor" class="toggle">
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          class="app-mat-theme"
          value="{{ getLossSetSelectionMode() }}"
        >
          <mat-button-toggle
            value="Gross"
            disableRipple
            ngDefaultControl
            (click)="onGrossClick()"
          >
            Gross
          </mat-button-toggle>
          <mat-button-toggle
            value="Ceded"
            disableRipple
            ngDefaultControl
            (click)="onCededClick()"
          >
            Ceded
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <app-loss-set-layer-container></app-loss-set-layer-container>
    </div>
  </section>

  <section
    *ngIf="showScenarios && !showLossSetGroupEditor"
    [class]="sectionClass('scenarios')"
  >
    <h3
      appHeaderCollapse
      [collapse]="collapseBySection['scenarios']"
      (collapseChange)="
            collapseChange.emit({ section: 'scenarios', collapse: $event })
          "
    >
      Scenarios
    </h3>
    <div class="collapsible" [@collapse]="collapse('scenarios')">
      <label appInfoText small italic class="info-text">
        Click on an alternate loss set scenario to open
      </label>
      <app-layer-panel-scenarios-optimizations
        [currentProgram]="currentProgram"
        [scenarios]="scenarios"
        (scenarioOrOptimizationClick)="
              scenarioOrOptimizationClick.emit($event)
            "
      ></app-layer-panel-scenarios-optimizations>
    </div>
  </section>

  <section
    *ngIf="showOptimizations && !showLossSetGroupEditor"
    [class]="sectionClass('optimizations')"
  >
    <h3
      appHeaderCollapse
      [collapse]="collapseBySection['optimizations']"
      (collapseChange)="
            collapseChange.emit({ section: 'optimizations', collapse: $event })
          "
    >
      Optimizations
    </h3>
    <div class="collapsible" [@collapse]="collapse('optimizations')">
      <label appInfoText small italic class="info-text">
        Click on a structure optimization to open
      </label>
      <app-layer-panel-scenarios-optimizations
        [currentProgram]="currentProgram"
        [optimizations]="optimizations"
        (scenarioOrOptimizationClick)="
              scenarioOrOptimizationClick.emit($event)
            "
      ></app-layer-panel-scenarios-optimizations>
    </div>
  </section>

  <section
    *ngIf="!showLossSetGroupEditor"
    [class]="sectionClass('inurance')"
  >
    <div class="inurance-header">
      <h3
        appHeaderCollapse
        [collapse]="collapseBySection['inurance']"
        (collapseChange)="
              collapseChange.emit({ section: 'inurance', collapse: $event })
            "
      >
        Inurance
      </h3>
      <button
        class="add-inurance-button"
        appButton
        small
        accent
        (click)="
              $event.preventDefault();
              $event.stopPropagation();
              onOpenAddInuranceClick()
            "
        [disabled]="!currentCededLayer || isLayerNew()"
        [matTooltip]="getInuranceToolTip()"
      >
        <span>+</span>
      </button>
    </div>
    <div class="collapsible" [@collapse]="collapse('inurance')">
      <label
        matTooltip="Naming Convention: Program/Structure/Layer"
        matTooltipPosition="above"
        class="info-text"
        appInfoText
        small
        italic
      >
        Select a Layer from the Tower to display Inurance sources
      </label>

      <app-inurance-layers-container></app-inurance-layers-container>
    </div>
  </section>

  <section
    *ngIf="!showLossSetGroupEditor"
    class="layer-types"
    [class]="sectionClass('layerTypes')"
  >
    <h3
      appHeaderCollapse
      [collapse]="collapseBySection['layerTypes']"
      (collapseChange)="
            collapseChange.emit({ section: 'layerTypes', collapse: $event })
          "
    >
      Layer Types
    </h3>
    <div class="collapsible" [@collapse]="collapse('layerTypes')">
      <label appInfoText small italic class="info-text">
        Drag over graph to add new
      </label>

      <app-layer-palette-item-container
        [layerPasteTrigger]="layerPasteTrigger"
        [structureId]="currentProgram?.id"
        [studies]="studies"
        [selectedProgramID]="selectedProgramID"
      ></app-layer-palette-item-container>
    </div>
  </section>
</div>
