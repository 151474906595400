import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { Metrics } from '../../../api/analyzere/analyzere.model'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import {
  ApiResponse,
  MaybeData,
  MaybeError,
} from '../../../api/model/api.model'
import { mergeMapWithInput, rejectErrorWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import { toPortfolioViewMetrics } from '../../model/metrics.converter'
import { PortfolioViewMetricsAndAction } from '../../store/metrics/portfolio-metrics.effects'
import { toPortfolioMetrics } from '../optimization.model'
import * as fromOptimizationTailMetricsActions from './optimization-tail-metrics.actions'
import {
  selectIncludedCandidateLayers,
  selectOptimizationState,
} from './optimization.selectors'

@Injectable()
export class OptimizationTailMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private analyzereService: AnalyzreService
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetrics
      ),
      filter(action => !!action.id),
      withLatestFrom(this.store.pipe(select(selectOptimizationState))),
      mergeMapWithInput(([action, state]) => {
        const candidateResultID = action.id as string
        let httpObservable: ApiResponse<Record<string, Metrics[]>>
        const grossPortfolioViewID =
          state.candidateResults.entities[candidateResultID]
            ?.grossPortfolioViewID || ''
        const netPortfolioViewID =
          state.candidateResults.entities[candidateResultID]
            ?.netPortfolioViewID || ''
        httpObservable = this.analyzereService.getMultiPortfolioViewMetrics(
          [grossPortfolioViewID, netPortfolioViewID],
          action.aggregationMethod,
          action.perspective,
          action.lossFilter,
          this.transformReturnPeriod(action.returnPeriod1),
          1,
          1,
          1,
          1
        )
        return httpObservable.pipe(
          map(res => {
            if (res.error) {
              return res as unknown as MaybeData<PortfolioViewMetricsAndAction> &
                MaybeError
            } else {
              return {
                data: { ...toPortfolioMetrics(action), metrics: res.data },
              } as MaybeData<PortfolioViewMetricsAndAction> & MaybeError
            }
          })
        )
      }),
      rejectErrorWithInput((error, [action]) =>
        this.store.dispatch(
          fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetricsFailure(
            { error, id: action.id as string }
          )
        )
      ),
      withLatestFrom(this.store.pipe(select(selectIncludedCandidateLayers))),
      map(([[metricsResponse, [action, state]], candidateLayers]) => {
        const metrics = toPortfolioViewMetrics(
          metricsResponse,
          state.candidateResults.entities[action.id as string]
            ?.grossPortfolioViewID || '',
          state.candidateResults.entities[action.id as string]
            ?.cededPortfolioViewID || '',
          state.candidateResults.entities[action.id as string]
            ?.netPortfolioViewID || ''
        )
        let efficiencyTailVal = metrics.returnPeriodData[0].period1
        if (metricsResponse.portfolioType !== 'Ceded') {
          const cededMetricsResponse: PortfolioViewMetricsAndAction = {
            ...metricsResponse,
            portfolioType: 'Ceded',
          }
          const cededMetrics = toPortfolioViewMetrics(
            cededMetricsResponse,
            state.candidateResults.entities[action.id as string]
              ?.grossPortfolioViewID || '',
            state.candidateResults.entities[action.id as string]
              ?.cededPortfolioViewID || '',
            state.candidateResults.entities[action.id as string]
              ?.netPortfolioViewID || ''
          )
          efficiencyTailVal = cededMetrics.returnPeriodData[0].period1
        }
        const expectedCededMargin = candidateLayers.find(
          c => c.id === action.id
        )?.expectedCededMargin
        return fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetricsSuccess(
          {
            id: action.id as string,
            metrics: {
              var: metrics.returnPeriodData[0].period1,
              tvar: metrics.returnPeriodData[1].period1,
              efficiencyScore:
                (efficiencyTailVal / (expectedCededMargin ?? 1)) * -1,
            },
          }
        )
      })
    )
  })

  private transformReturnPeriod(value: number) {
    return 1 / value
  }
}
