import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  ChangeManyEvent,
  GroupScenario,
  GroupScenariosDialogData,
} from '../../store/grouper/program-group.model'
import { GroupScenariosDialogComponent } from '../components/group-scenarios-dialog/group-scenarios-dialog.component'

const panelID = 'app-group-scenarios-dialog'
const panelClass = [panelID, 'app-dialog-with-sidebar']

@Injectable()
export class GroupScenariosDialogService {
  constructor(private dialog: MatDialog) {}

  open(
    data: GroupScenariosDialogData
  ): MatDialogRef<
    GroupScenariosDialogComponent,
    ChangeManyEvent<GroupScenario>
  > {
    return this.dialog.open<
      GroupScenariosDialogComponent,
      GroupScenariosDialogData,
      ChangeManyEvent<GroupScenario>
    >(GroupScenariosDialogComponent, {
      data,
      id: panelID,
      panelClass,
      disableClose: true,
    })
  }

  close(): void {
    const ref = this.ref
    if (ref) {
      ref.close()
    }
  }

  update(data: Partial<GroupScenariosDialogData>) {
    const dialog = this.ref && this.ref.componentInstance
    if (dialog) {
      dialog.data = { ...dialog.data, ...data }
    }
  }

  private get ref():
    | MatDialogRef<GroupScenariosDialogComponent, GroupScenariosDialogData>
    | undefined {
    return this.dialog.getDialogById(panelID)
  }
}
