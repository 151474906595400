import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthCallBackComponent } from './core/auth/auth-callback.component'
import { RoleGuard } from './guards/role-guard.service'
import { FacRedirectComponent } from './landing/fac-redirect/fac-redirect.component'

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'clients',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./broker/broker.module').then(m => m.BrokerModule),
  },
  {
    path: 'digital',
    loadChildren: () =>
      import('./digital/digital.module').then(m => m.DigitalModule),
  },
  {
    path: 'home',
    redirectTo: '/clients',
  },
  {
    path: 'quote',
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule),
  },
  {
    path: 'analysis',
    loadChildren: () =>
      import('./analysis/analysis.module').then(m => m.AnalysisModule),
  },
  {
    path: 'credit',
    loadChildren: () =>
      import('./credit/credit.module').then(m => m.CreditModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'signature',
    loadChildren: () =>
      import('./signature/signature.module').then(m => m.SignatureModule),
  },
  {
    path: 'sliptemplates',
    loadChildren: () =>
      import('./slip-templates/slip-templates.module').then(
        m => m.SlipTemplatesModule
      ),
  },
  {
    path: 'display',
    loadChildren: () =>
      import('./display/display.module').then(m => m.DisplayModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'metrics',
    loadChildren: () =>
      import('./metrics/metrics.module').then(m => m.MetricsModule),
  },
  {
    path: 'reinsurers',
    loadChildren: () =>
      import('./reinsurers/reinsurer.module').then(m => m.ReinsurerModule),
  },
  {
    path: 'tracking',
    loadChildren: () =>
      import('./tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'program-initiation',
    loadChildren: () =>
      import('./program-initiation/program-initiation.module').then(
        m => m.ProgramInitiationModule
      ),
  },
  {
    path: 'benchmark',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./benchmark/benchmark.module').then(m => m.BenchmarkModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'landingmenu',
    loadChildren: () =>
      import('./landing-menu/landing-menu.module').then(
        m => m.LandingMenuModule
      ),
  },
  {
    path: 'management-information',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./management-information/management-information.module').then(
        m => m.ManagementInformationModule
      ),
  },
  {
    path: 'revenue-dashboard',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./revenue-power-bi/revenue.module').then(m => m.RevenueModule),
  },
  {
    path: 'fac-management-information',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./management-information/management-information.module').then(
        m => m.ManagementInformationModule
      ),
  },
  {
    path: 'fac-dashboard',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./facultative-power-bi/facultative-dashboard.module').then(
        m => m.FacDashboardModule
      ),
  },
  {
    path: 'kpi-dashboard',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./kpi-power-bi/kpi-dashboard.module').then(
        m => m.KPIDashboardModule
      ),
  },
  {
    path: 'prove',
    canActivate: [RoleGuard],
    loadChildren: () => import('./prove/prove.module').then(m => m.ProveModule),
  },
  {
    path: 'pricingcurve',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./pricingcurve/pricing-curve.module').then(
        m => m.PricingCurveModule
      ),
  },
  {
    path: 'auth-callback',
    component: AuthCallBackComponent,
  },
  {
    path: 'fac-redirect',
    component: FacRedirectComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
