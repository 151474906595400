import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import {
  ProgramQuotationsResponse,
  LayerSubmitMarketResponse,
  MarketLayerPostBody,
  MarketLayerSubmitResponse,
  DocumentResponse,
} from '../model/market.model'

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  getQuotation(
    programID: any,
    carrierID: string
  ): ApiResponse<ProgramQuotationsResponse> {
    const url = `${environment.marketApi.base}${environment.marketApi.quotations}`
      .replace('{id}', programID)
      .replace('{carrierID}', carrierID)
    return this.httpClient
      .get<ProgramQuotationsResponse>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Quotation'))
  }

  getDocuments(carrierID: string): ApiResponse<DocumentResponse> {
    const url = `${environment.documentApi.base}${environment.documentApi.documents}`.replace(
      '{carrierID}',
      carrierID
    )
    return this.httpClient
      .get<DocumentResponse>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Documents'))
  }

  getDocumentFromBackend(
    carrierID: string,
    facName: string,
    facID: string
  ): ApiResponse<DocumentResponse> {
    const url = `${environment.documentApi.base}${environment.documentApi.facDocuments}`
      .replace('{carrierID}', carrierID)
      .replace('{facName}', facName)
      .replace('{facID}', facID)
    return this.httpClient
      .get<DocumentResponse>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Fac Documents'))
  }

  deleteDocumentFromBackend(
    carrierID: string,
    facName: string,
    facID: string
  ): ApiResponse<any> {
    const url = `${environment.documentApi.base}${environment.documentApi.deleteFacDocuments}`
      .replace('{carrierID}', carrierID)
      .replace('{facName}', facName)
      .replace('{facID}', facID)
    return this.httpClient
      .post<any>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Delete Fac Documents'))
  }

  postMarket(
    postBody: string,
    carrierID: string
  ): ApiResponse<LayerSubmitMarketResponse> {
    const url = `${environment.marketApi.base}${environment.marketApi.program}`.replace(
      '{carrierID}',
      carrierID
    )
    return this.httpClient
      .post<LayerSubmitMarketResponse>(url, postBody, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Layer Market'))
  }

  postMarketLayerID(
    id: string,
    body: MarketLayerPostBody[]
  ): ApiResponse<MarketLayerSubmitResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.marketLayer}/${id}`
    return this.httpClient
      .post<MarketLayerSubmitResponse>(url, body)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Market Layer'))
  }
}
