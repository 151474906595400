<app-credit-tier-bar-multiselect-container
  (tierChange)="tierChange.emit()"
></app-credit-tier-bar-multiselect-container>
<div>
  <!-- groups -->
  <div class="groups">
    <h2>{{ structureGroupsHeader }}</h2>
    <ng-container *ngIf="creditStructureGroups?.length > 0">
      <cdk-virtual-scroll-viewport itemSize="50" class="group-list">
        <div
          #structureGroupItem
          *cdkVirtualFor="
            let group of creditStructureGroups;
            let i = index;
            trackBy: trackByID
          "
        >
          <div
            class="group-item-content"
            [ngClass]="{ checked: group.checked }"
            (click)="onCreditStructureGroupSelectionChange(group)"
          >
            <span>{{ group.label }}</span>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
  <!-- structures -->
  <div class="structures">
    <h2>{{ structuresHeader }}</h2>
    <ng-container *ngIf="creditStructures?.length > 0">
      <div class="structure-list">
        <div
          cdkDropList
          *ngFor="
            let structure of creditStructures;
            let i = index;
            trackBy: trackByID
          "
          class="structure-item-wrapper"
        >
          <app-credit-structure-card-container
            class="structure-item-content"
            cdkDrag
            [cdkDragData]="structure"
            [size]="size"
            [index]="i"
            [structure]="structure"
            [selectMultiple]="true"
            [canCheck]="true"
            [canOptimize]="false"
            (structureClick)="onCreditStructureCheckChange(structure)"
            (structureCheckChange)="onCreditStructureCheckChange(structure)"
          ></app-credit-structure-card-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
