import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { clone } from 'ramda'
import {
  AgencyDetails,
  FUND_MANAGER_DIALOG_COLUMNS,
  FUND_MANAGER_UNKNOWN_TEXT,
  FUND_MANAGER_SEG_ACCOUNT_TEXT,
  FundManagerDialogDatum,
  ProgramFactor,
  Reinsurer,
} from '../../core/model/reinsurer.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fund-manager-detail-dialog-component',
  templateUrl: './fund-manager-detail.component.html',
  styles: [
    `
      .relationship-title {
        margin-bottom: 20px !important;
      }
      :host ::ng-deep .mat-mdc-checkbox .mdc-checkbox {
        padding: 0 !important;
      }
      :host ::ng-deep .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
        height: 16px !important;
        width: 16px !important;
        top: 0 !important;
        left: 0 !important;
      }
      :host ::ng-deep .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
        top: 0 !important;
        left: 0 !important;
      }
      :host ::ng-deep .mat-mdc-checkbox-touch-target {
        top: 0 !important;
        height: 16px !important;
        left: 0 !important;
        width: 16px !important;
        transform: none !important;
      }
    `
  ]
})
export class FundManagerDetailDialogComponent implements OnInit {
  fundManagerColumns = FUND_MANAGER_DIALOG_COLUMNS
  fundManagerRows: FundManagerDialogDatum[]
  segregatedAccount = false
  fundMrgUnknown = false

  @Input() reinsurer: Reinsurer
  @Input() companyPapers: AgencyDetails[]
  @Input() selectedCompanyPapers: AgencyDetails[]
  @Input() incumbentOrTargetMarket: string
  @Input() savingReinsurer: boolean

  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() setReinsurer = new EventEmitter<Reinsurer>()
  @Output() saveClick = new EventEmitter<{
    reinsurer: Reinsurer
    selectedCompanyPapers: AgencyDetails[]
  }>()

  constructor(
    public dialogRef: MatDialogRef<FundManagerDetailDialogComponent>
  ) {}

  ngOnInit(): void {
    this.fundManagerRows = [
      ...this.getCompanyPaperRows(),
      this.getFundManagerUnknownRow(),
      this.getSegregatedAccountRow(),
    ]
    this.segregatedAccount =
      this.reinsurer.reinsurerProgramFactor[0].segregated_account ?? false
    this.fundMrgUnknown =
      this.reinsurer.reinsurerProgramFactor[0].fund_mgr_unknown ?? false
  }

  onSaveClick(): void {
    const toSaveReinsurer = {
      ...this.reinsurer,
      reinsurerProgramFactor: this.getUpdatedReinsurerProgramFactor(),
    }
    this.updateOrAddDirty.emit(toSaveReinsurer)
    this.setReinsurer.emit(toSaveReinsurer)
    this.saveClick.emit({
      reinsurer: toSaveReinsurer,
      selectedCompanyPapers: this.getUpdatedSelectedCompanyPapers(),
    })
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<FundManagerDialogDatum>) {
    const rowIdx = this.fundManagerRows.findIndex(r => r.id === id)
    if (rowIdx !== -1) {
      const updatedRows = [...this.fundManagerRows]
      updatedRows[rowIdx] = { ...updatedRows[rowIdx], [column.id]: value }
      this.fundManagerRows = updatedRows
    }
    if (id === FUND_MANAGER_SEG_ACCOUNT_TEXT) {
      this.segregatedAccount = value
    }
    if (id === FUND_MANAGER_UNKNOWN_TEXT) {
      this.fundMrgUnknown = value
    }
  }

  private getCompanyPaperRows(): FundManagerDialogDatum[] {
    return this.companyPapers.map(paper => {
      return {
        id: `${paper.agencyName}:${paper.agencyTPRef}`,
        agencyName: paper.agencyName,
        agencyTPRef: paper.agencyTPRef ?? '',
        ilsInternalNarr: paper.ilsInternalNarr ?? '',
        ilsName: paper.ilsName ?? '',
        selected: this.isCompanyPaperSelected(paper),
      }
    })
  }

  private isCompanyPaperSelected(paper: AgencyDetails): boolean {
    return this.selectedCompanyPapers.some(
      paperSelected =>
        paper.agencyName === paperSelected.agencyName &&
        paper.agencyTPRef === paperSelected.agencyTPRef
    )
  }

  private getFundManagerUnknownRow(): FundManagerDialogDatum {
    return {
      id: FUND_MANAGER_UNKNOWN_TEXT,
      agencyName: FUND_MANAGER_UNKNOWN_TEXT,
      agencyTPRef: '',
      ilsInternalNarr: '',
      ilsName: '',
      selected: !!this.reinsurer.reinsurerProgramFactor[0].fund_mgr_unknown,
    }
  }

  private getSegregatedAccountRow(): FundManagerDialogDatum {
    return {
      id: FUND_MANAGER_SEG_ACCOUNT_TEXT,
      agencyName: FUND_MANAGER_SEG_ACCOUNT_TEXT,
      agencyTPRef: '',
      ilsInternalNarr: '',
      ilsName: '',
      selected: !!this.reinsurer.reinsurerProgramFactor[0].segregated_account,
    }
  }

  private getUpdatedSelectedCompanyPapers(): AgencyDetails[] {
    return this.companyPapers.filter(paper =>
      this.fundManagerRows.find(
        row =>
          row.selected &&
          row.agencyName === paper.agencyName &&
          row.agencyTPRef === paper.agencyTPRef
      )
    )
  }

  private getUpdatedReinsurerProgramFactor(): ProgramFactor[] {
    const factor = clone(this.reinsurer.reinsurerProgramFactor)
    factor[0].incumbent = this.incumbentOrTargetMarket === 'inc'
    factor[0].target_market = this.incumbentOrTargetMarket === 'tm'
    factor[0].segregated_account = this.segregatedAccount
    factor[0].fund_mgr_unknown = this.fundMrgUnknown
    return factor
  }
}
