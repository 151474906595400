import { head } from 'ramda'
import { getDataAggregateMetricLabel } from '../../api/benchmark/data/aggregate-data'
import { HasName } from '../../core/model/general.model'
import { AggregateMetricMethod } from '../../core/model/metric.model'
import {
  GraphingValueFormat,
  GraphingValueFormatPreset,
} from '@graphing/utils/graphing-value-formatter'
import { rejectNil, toArray } from '@shared/util/operators'
import { BenchmarkBuildChartProps } from './benchmark-chart-view'
import { buildEntityFilterName } from './benchmark-entity-name-formatter'
import { buildBenchmarkGridSubmode } from './benchmark-grid'
import { BenchmarkSubmode } from './benchmark-submode'
import {
  BenchmarkMetric,
  BenchmarkMetricBase,
  BenchmarkPeerSet,
  BenchmarkVisibility,
} from './benchmark.model'
import { getBenchmarkMetricID } from './benchmark.util'

export interface BenchmarkDimensionMetricConfig
  extends Omit<BenchmarkMetricBase, 'name'> {
  id: string | number
  name?: string
  estimateYearLatestIndex?: number
  metricGroupSortDefinition?: (string | number)[]
}

export interface BenchmarkMetricDimension {
  ids: (string | number | BenchmarkDimensionMetricConfig)[]
  defaultID?: string | number | (string | number)[]
  defaultIndex?: number
  aggregateMethod?: AggregateMetricMethod
  format?: GraphingValueFormat | GraphingValueFormatPreset
  useAutocomplete?: boolean
  isOptional?: boolean
  hideControl?: boolean
  lobTargetValueMetricID?: string | number | BenchmarkDimensionMetricConfig
  stateTargetValueMetricID?: string | number | BenchmarkDimensionMetricConfig
}

export const benchmarkMetricDimensionKeys = [
  'primary',
  'secondary',
  'tertiary',
] as const

export type BenchmarkMetricDimensionKey =
  typeof benchmarkMetricDimensionKeys[number]

export type BenchmarkMetricDimensionsMap = Partial<
  Record<BenchmarkMetricDimensionKey, BenchmarkMetricDimension>
>

export type BenchmarkMetricDimensionsBySubmode = Record<
  string,
  BenchmarkMetricDimensionsMap
>

export function toBenchmarkDimensionMetricConfig(
  cfg: string | number | BenchmarkDimensionMetricConfig
): BenchmarkDimensionMetricConfig {
  return typeof cfg === 'number' || typeof cfg === 'string' ? { id: cfg } : cfg
}

function _getBenchmarkDimensions(props: BenchmarkBuildChartProps) {
  return getBenchmarkDimensions(props.submode, props.metricGroupIndex)
}
export function getBenchmarkDimensions(
  submode?: BenchmarkSubmode | null,
  metricGroupIndex?: number
): BenchmarkMetricDimensionsMap | undefined {
  if (metricGroupIndex == null && submode?.metricGroups == null) {
    return submode?.dimensions
  }
  let i = metricGroupIndex ?? 0
  if (submode?.metricGroups && i >= submode.metricGroups.length - 1) {
    // If the metric group index is out of bounds of the metric groups
    // definition array (e.g. when the config changes, but the old index is
    // still saved in local storage), use the configured metric group at the
    // modulo of the old index
    i = i % submode.metricGroups.length
  }
  return submode?.metricGroups?.[i]?.dimensions
}

function buildMetricName(
  cfg: BenchmarkDimensionMetricConfig,
  rawMetric: BenchmarkMetric,
  peerSets: BenchmarkPeerSet[] = [],
  companies: HasName[] = []
): string {
  let name = rawMetric.name
  if (cfg.name) {
    name = cfg.name
  } else if (cfg.aggregateMethod) {
    const label = getDataAggregateMetricLabel(cfg.aggregateMethod)
    if (label) {
      name = `${name} ${label}`
    }
  }
  const entityFilter = rawMetric.entityFilter ?? cfg.entityFilter
  if (entityFilter) {
    const suffix = buildEntityFilterName(entityFilter, companies, peerSets)
    if (suffix) {
      name += ', ' + suffix
    }
  }
  return name
}

function buildMetric(
  props: BenchmarkBuildChartProps,
  cfg: BenchmarkDimensionMetricConfig,
  rawMetric: BenchmarkMetric,
  dimension?: BenchmarkMetricDimension,
  visibility: BenchmarkVisibility = 'show'
): BenchmarkMetric | undefined {
  const { peerSets, companies, lobs = [] } = props
  const metric: BenchmarkMetric = {
    ...rawMetric,
    ...cfg,
    rawName: cfg.name ?? rawMetric.name,
    name: buildMetricName(cfg, rawMetric, peerSets, companies),
    visibility,
  }

  if (metric.unlessLOB != null) {
    const lobIDs = lobs.map(lob => lob.id)
    if (toArray(metric.unlessLOB).some(lob => lobIDs.includes(lob))) {
      return
    }
  }
  if (metric.whenLOB != null) {
    const lobIDs = lobs.map(lob => lob.id)
    if (!toArray(metric.whenLOB).some(lob => lobIDs.includes(lob))) {
      return
    }
  }

  const aggregateMethod = cfg.aggregateMethod ?? dimension?.aggregateMethod
  if (aggregateMethod && metric.aggregateMethod == null) {
    metric.aggregateMethod = aggregateMethod
  }

  const format = cfg.format ?? rawMetric.format ?? dimension?.format
  if (format && metric.format == null) {
    metric.format = format
  }

  const metricWeightID = cfg.metricWeightID ?? rawMetric.metricWeightID
  if (metricWeightID && metric.metricWeightID == null) {
    metric.metricWeightID = metricWeightID
  }

  const metric2ID = cfg.metric2ID ?? rawMetric.metric2ID
  if (metric2ID && metric.metric2ID == null) {
    metric.metric2ID = metric2ID
  }

  const metric2WeightID = cfg.metric2WeightID ?? rawMetric.metric2WeightID
  if (metric2WeightID && metric.metric2WeightID == null) {
    metric.metric2WeightID = metric2WeightID
  }

  return metric
}

export const getBenchmarkDimensionMetrics =
  (dimensionKey: BenchmarkMetricDimensionKey) =>
  (props: BenchmarkBuildChartProps): BenchmarkMetric[] => {
    const { metricsByID = {}, metricVisibility = {} } = props
    const submode = buildBenchmarkGridSubmode(props)
    const dimensions = _getBenchmarkDimensions({ ...props, submode })
    const dimension = dimensions?.[dimensionKey]

    const metricCfgs =
      dimension?.ids.map(toBenchmarkDimensionMetricConfig) ?? []
    const metrics =
      metricCfgs.map(cfg => {
        const id = getBenchmarkMetricID(cfg)
        const rawMetric = metricsByID[id]
        const visibility = metricVisibility[id] ?? cfg.visibility

        if (!rawMetric || visibility === 'hide') {
          return
        }

        return buildMetric(props, cfg, rawMetric, dimension, visibility)
      }) ?? []

    return rejectNil(metrics)
  }

function findDefaultMetric(
  metrics: BenchmarkMetric[] = [],
  defaultIDs?: string | number | (string | number)[]
): BenchmarkMetric | undefined {
  if (!defaultIDs) {
    return
  }
  const ids = toArray(defaultIDs)
  for (const id of ids) {
    const defaultMetric = metrics.find(
      m => String(getBenchmarkMetricID(m)) === String(id)
    )
    if (defaultMetric) {
      return defaultMetric
    }
  }
}

export function getAllBenchmarkMetrics(
  props: BenchmarkBuildChartProps
): BenchmarkMetric[] {
  const { metricsByID = {} } = props
  const dimensions = _getBenchmarkDimensions(props)
  const dimension = dimensions?.primary
  const metricCfgs = dimension?.ids.map(toBenchmarkDimensionMetricConfig) ?? []
  return rejectNil(
    metricCfgs.map(cfg => {
      const id = getBenchmarkMetricID(cfg)
      const rawMetric = metricsByID[id]
      if (rawMetric) {
        return buildMetric(props, cfg, rawMetric, dimension)
      }
    })
  )
}

export const getBenchmarkDefaultMetric =
  (dimensionKey: BenchmarkMetricDimensionKey) =>
  (
    props: BenchmarkBuildChartProps,
    metrics: BenchmarkMetric[]
  ): BenchmarkMetric | null => {
    const submode = buildBenchmarkGridSubmode(props)
    const dimensions = _getBenchmarkDimensions({ ...props, submode })
    const defaultIDs = dimensions?.[dimensionKey]?.defaultID
    const defaultMetric = findDefaultMetric(metrics, defaultIDs)
    if (defaultMetric) {
      return defaultMetric
    }
    return dimensions?.[dimensionKey]?.isOptional ? null : head(metrics) ?? null
  }
