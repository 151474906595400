import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BackendService } from 'src/app/api/backend/backend.service'
import { SignatureContract } from '../signature.model'
import {
  OTRiskConfirmRequest,
  OTRiskConfirmResponse,
  OTRiskRequest,
  ProcessLog,
} from '../../api/model/backend.model'

@Component({
  selector: 'app-opentwins-submission-dialog-box',
  templateUrl: './opentwins-submission-dialog-box.component.html',
  styleUrls: [`./opentwins-submission-dialog.scss`],
})
export class OpenTwinsSubmissionDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<OpenTwinsSubmissionDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contract: SignatureContract
      isCoRiskContract: boolean
      leadRiskRef: string | undefined
    },
    private backendService: BackendService
  ) {}

  policyFilePosition: string
  dmsLocation: string
  officePosition: string
  regionPosition: string
  brokerSupportingPersonRef: string
  placingBrokerPersonRef: string
  producingBrokerPersonRef: string
  submitting: boolean
  polling: boolean
  isComplete: boolean
  errorMessage: string
  processId: string | undefined
  processLog: ProcessLog | undefined
  logInterval: any
  logCount = 0
  riskTypeValue: string
  selectedContract: SignatureContract
  confirmResponse: OTRiskConfirmResponse | undefined
  isConfirming: boolean
  isConfirmed: boolean | undefined
  showSplitsErrorMessage: boolean

  ngOnInit(): void {
    if (this.data.contract && this.data.contract.id) {
      this.selectedContract = this.data.contract
      const req: OTRiskConfirmRequest = {
        contractId: this.selectedContract.id,
      }
      this.isConfirming = true
      this.backendService.confirmOpenTWINSRisk(req).subscribe(res => {
        if (res.error !== undefined) {
          this.errorMessage = res.error.message
        }
        this.confirmResponse = res.data
        this.isConfirmed = this.confirmResponse?.isConfirmed
        this.isConfirming = false
      })
    }
  }

  submit(): void {
    this.errorMessage = ''
    if (this.selectedContract) {
      this.showSplitsErrorMessage = false

      if (
        this.confirmResponse?.acknowledgements &&
        this.confirmResponse.acknowledgements.length > 0
      ) {
        if (
          this.confirmResponse.acknowledgements.some(x => !x.isAcknowledged)
        ) {
          this.errorMessage =
            'You must accept acknowledgements before proceeding'
          return
        }
      }

      if (this.splitsNotEntered()) {
        this.showSplitsErrorMessage = true
      } else {
        const req: OTRiskRequest = {
          policyFileRef: '',
          brokerSupportingPersonRef: this.brokerSupportingPersonRef,
          placingBrokerRef: this.placingBrokerPersonRef,
          producingBrokerRef: this.producingBrokerPersonRef,
          contractId: this.data.contract.id,
          contractSplits: this.confirmResponse
            ? this.confirmResponse.contractSplits
            : [],
          isCoRisk: this.data.isCoRiskContract,
          leadRiskRef: this.data.leadRiskRef,
        }
        this.submitting = true
        this.backendService.postOpenTWINSRisk(req).subscribe(res => {
          if (res.error !== undefined) {
            this.errorMessage = res.error.message
          }
          this.processId = res.data?.processId
          this.logCount = 0
          this.polling = true
          this.logInterval = setInterval(() => {
            this.logCount += 1
            this.checkProcessLog(this.processId)
          }, 3000)
        })
      }
    } else {
      this.errorMessage = 'No selected contract found'
    }
  }

  splitsNotEntered(): boolean {
    if (
      this.confirmResponse?.contractSplits &&
      this.confirmResponse?.contractSplits.length > 0
    ) {
      return this.confirmResponse.contractSplits.some(
        split =>
          split.splitPercent < 0 ||
          split.splitPercent > 100 ||
          split.reinstatementSplitPercent < 0 ||
          split.reinstatementSplitPercent > 100
      )
    }

    return false
  }

  formatDate(isoDateString: string): string {
    const dateObj = new Date(isoDateString)
    return dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString()
  }

  checkProcessLog(pId: string | undefined): void {
    if (this.logCount > 30) {
      this.polling = false
      this.submitting = false
      this.isComplete = true
      clearInterval(this.logInterval)
    }

    if (pId) {
      this.backendService.getProcessLog(pId).subscribe(res => {
        this.processLog = res.data
        if (
          this.processLog?.status === 'COMPLETE' ||
          this.processLog?.status === 'ERROR'
        ) {
          this.polling = false
          this.submitting = false
          this.isComplete = true
          clearInterval(this.logInterval)
        }
      })
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'cancel' })
  }
}
