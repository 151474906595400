import { createAction, props } from '@ngrx/store'
import {
  EntitiesResponse,
  StudyResponse,
} from '../../../api/model/backend.model'
import { ApplicationError } from '../../../error/model/error'
import { Auth } from '../../model/auth.model'

export const identifySuccess = createAction(
  '[Auth] Identify Success',
  props<Auth>()
)

export const updateAuthToken = createAction(
  '[Auth] Update Token',
  props<{ token: string }>()
)

export const identifyPermissionsSuccess = createAction(
  '[Auth] Identify Permissions Success',
  props<EntitiesResponse>()
)

export const identifyPermissionsFailure = createAction(
  '[Auth] Identify Permissions Failure',
  props<{ error: ApplicationError }>()
)

export const setAuthenticatingUser = createAction(
  '[Auth] Set Authenticating User',
  props<{ authenticatingUser: string }>()
)

export const setSageLogicalRole = createAction(
  '[Auth] Set Sage Logical Role',
  props<{ sageLogicalRole: string }>()
)

export const authenticateSuccess = createAction(
  '[Auth] Authenticate User Success'
)
export const authenticateFailure = createAction(
  '[Auth] Authenticate User Failure'
)

export const setAnalyzereToken = createAction(
  '[Auth] Set AnalyzeRe Token',
  props<{ token: string }>()
)
export const setAnalyzereTokenFailure = createAction(
  '[Auth] Set AnalyzeRe Token Failure',
  props<{ error: ApplicationError }>()
)

export const setMarketToken = createAction(
  '[Auth] Set Market Token',
  props<{ marketToken: string }>()
)

export const setMarketTokenSuccess = createAction(
  '[Auth] Set Market Token Success',
  props<{ token: string }>()
)

export const setSelectedApp = createAction(
  '[Auth] Set Selected App',
  props<{ selectedApp: string }>()
)

export const updateStudySageView = createAction(
  '[Auth] Update Sage View',
  props<{ id: number; study: StudyResponse }>()
)
