import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  CreditStructureEntity,
  CreditTransaction,
} from '../../model/credit-structure.model'
import {
  CreditOptimizationTabType,
  CreditOptimizationResultTableRow,
  CreditOptimizationResultTable,
} from '../../model/credit-optimization.model'

export enum CreditOptimizationActions {
  InitOptimizationData = '[Credit Optimization] Init Optimization Data',
  InitOptimizationDataSuccess = '[Credit Optimization] Init Optimization Data - Success',
  InitOptimizationDataFailure = '[Credit Optimization] Init Optimization Data - Failure',
  UpdateConfigurationTransaction = '[Credit Optimization] Update Configuration Transaction',
  UpdateConfigurationValidity = '[Credit Optimization] Update Configuration Validity',
  RunOptimization = '[Credit Optimization] Run Optimization',
  RunOptimizationInitiated = '[Credit Optimization] Run Optimization - Initiated',
  RunOptimizationSuccess = '[Credit Optimization] Run Optimization - Success',
  RunOptimizationFailure = '[Credit Optimization] Run Optimization - Failure',
  OptimizationComplete = '[Credit Optimization] Optimization Complete',
  ResetOptimization = '[Credit Optimization] Reset Optimization',
  SelectOptimization = '[Credit Optimization] Select Optimization',
  CreateStructureFromOptimization = '[Credit Optimization] Create Structure From Optimization',
  SetActiveTabType = '[Credit Optimization] Set Active Tab Type',
  EnableTabType = '[Credit Optimization] Enable Tab Type',
}

export const InitOptimizationData = createAction(
  CreditOptimizationActions.InitOptimizationData,
  props<{ structureId: string }>()
)
export const InitOptimizationDataFailure = createAction(
  CreditOptimizationActions.InitOptimizationDataFailure,
  props<{ error: ApplicationError }>()
)
export const InitOptimizationDataSuccess = createAction(
  CreditOptimizationActions.InitOptimizationDataSuccess,
  props<{ structureEntity: CreditStructureEntity }>()
)

export const UpdateConfigurationTransaction = createAction(
  CreditOptimizationActions.UpdateConfigurationTransaction,
  props<{ transaction: CreditTransaction }>()
)
export const UpdateConfigurationValidity = createAction(
  CreditOptimizationActions.UpdateConfigurationValidity,
  props<{ validity: boolean }>()
)

export const RunOptimization = createAction(
  CreditOptimizationActions.RunOptimization
)
export const RunOptimizationInitiated = createAction(
  CreditOptimizationActions.RunOptimizationInitiated,
  props<{ processId: string }>()
)
export const RunOptimizationFailure = createAction(
  CreditOptimizationActions.RunOptimizationFailure,
  props<{ error: ApplicationError }>()
)
export const RunOptimizationSuccess = createAction(
  CreditOptimizationActions.RunOptimizationSuccess,
  props<{ results: CreditOptimizationResultTable }>()
)

export const OptimizationComplete = createAction(
  CreditOptimizationActions.OptimizationComplete
)

export const ResetOptimization = createAction(
  CreditOptimizationActions.ResetOptimization
)

export const SelectOptimization = createAction(
  CreditOptimizationActions.SelectOptimization,
  props<{ optimizationRow: CreditOptimizationResultTableRow }>()
)

export const CreateStructureFromOptimization = createAction(
  CreditOptimizationActions.CreateStructureFromOptimization
)

export const SetActiveTabType = createAction(
  CreditOptimizationActions.SetActiveTabType,
  props<{ tabType: CreditOptimizationTabType }>()
)
export const EnableTabType = createAction(
  CreditOptimizationActions.EnableTabType,
  props<{ tabType: CreditOptimizationTabType }>()
)
