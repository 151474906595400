import { formatShortFixedNumber } from '@shared/util/short-number'
import { Numeric } from 'd3'
import { format } from 'd3-format'
import { utcFormat } from 'd3-time-format'
import { Periodicity } from '../models/graphing.model'

export type ValueFormatter = (
  n: number | Numeric,
  tickIndex?: number | 'alt'
) => string

export type GraphingValueTransformer = (
  n: number | Numeric,
  opts?: { invert?: boolean }
) => number

export type GraphingValueFormatTransform =
  | 'identity'
  | 'percent'
  | 'hundreds'
  | 'thousands'

interface GraphingValueFormatBase {
  transform?: GraphingValueFormatTransform
  prefix?: string
  suffix?: string
  tick?: GraphingValueFormat | GraphingValueFormatPreset
  alt?: GraphingValueFormat | GraphingValueFormatPreset
}

export type GraphingValueFormatType =
  | 'round'
  | 'fixed'
  | 'short'
  | 'short-fixed'
  | 'custom'

interface GraphingValueFormatRound extends GraphingValueFormatBase {
  type: 'round'
  precision?: number
  untrimmed?: boolean
}

interface GraphingValueFormatFixed extends GraphingValueFormatBase {
  type: 'fixed'
  precision?: number
  untrimmed?: boolean
}

interface GraphingValueFormatShort extends GraphingValueFormatBase {
  type: 'short'
  precision?: number
  untrimmed?: boolean
}

interface GraphingValueFormatShortFixed extends GraphingValueFormatBase {
  type: 'short-fixed'
  precision?: number
}

interface GraphingValueFormatCustom extends GraphingValueFormatBase {
  type: 'custom'
  specifier: string
}

export type GraphingValueFormat =
  | GraphingValueFormatRound
  | GraphingValueFormatFixed
  | GraphingValueFormatShort
  | GraphingValueFormatShortFixed
  | GraphingValueFormatCustom

export type GraphingValueFormatPreset =
  | 'short'
  | 'short-fixed'
  | 'percent'
  | 'percent-transform'

interface FormatQuarterOptions {
  forceQ1?: boolean
  onlyQ1?: boolean
  onlyHalves?: boolean
  exactMonth?: boolean
  asMonth?: boolean
}

export const formatShortName = (name?: string, len = 11) =>
  name && name.length > len ? `${name.substring(0, len + 4)}...` : name ?? ''

// Numeric

const FORMATTERS: Record<string, ValueFormatter> = {
  ',.3': format(',.3'),
  ',.3~': format(',.3~'),
  ',.0~f': format(',.0~f'),
  ',.1~f': format(',.1~f'),
  ',.4~s': format(',.4~s'),
}

function toFormatter(specifier: string): ValueFormatter {
  return FORMATTERS[specifier] ?? format(specifier)
}

const isDigit = (char: string): boolean => char >= '0' && char <= '9'

function siToNumeric(siPrefix: string) {
  switch (siPrefix) {
    case 'k': // Kilo
      return 'K'
    case 'G': // Giga
      return 'B' // Billions
    default:
      return siPrefix
  }
}

const createShortNumberFormatter =
  (
    siFormatter: ValueFormatter,
    baseFormatter: ValueFormatter
  ): ValueFormatter =>
  n => {
    const _n = Number(n)
    const si = siFormatter(_n)
    const lastChar = si.substring(si.length - 1)
    const hasSuffix = !isDigit(lastChar)
    const num = hasSuffix ? si.substring(0, si.length - 1) : si
    const suffix = hasSuffix ? siToNumeric(lastChar) : ''

    // If milli suffix, just divide by 1000 and remove suffix
    if (suffix === 'm') {
      return baseFormatter(Number(num) / 1000)
    }

    return `${num}${suffix}`
  }

const SHORT_PRESET: GraphingValueFormat = {
  type: 'short',
  precision: 4,
}

const SHORT_FIXED_PRESET: GraphingValueFormat = {
  type: 'short-fixed',
  precision: 1,
}

const PERCENT_PRESET: GraphingValueFormat = {
  type: 'short-fixed',
  precision: 1,
  suffix: '%',
  alt: SHORT_FIXED_PRESET,
}

const PRESETS: Record<GraphingValueFormatPreset, GraphingValueFormat> = {
  short: SHORT_PRESET,
  'short-fixed': SHORT_FIXED_PRESET,
  'percent-transform': PERCENT_PRESET,
  percent: {
    ...PERCENT_PRESET,
    transform: 'percent',
  },
}

export function isGraphingValueFormatter(
  f?: unknown
): f is GraphingValueFormat {
  return (
    f != null &&
    typeof f === 'object' &&
    (f as GraphingValueFormat).type != null
  )
}

const DEFAULT_GRAPHING_VALUE_FORMAT_PRESET: GraphingValueFormatPreset =
  'short-fixed'

export function toGraphingValueFormat(
  formatOrPreset?: GraphingValueFormat | GraphingValueFormatPreset
): GraphingValueFormat {
  return isGraphingValueFormatter(formatOrPreset)
    ? formatOrPreset
    : PRESETS[formatOrPreset ?? DEFAULT_GRAPHING_VALUE_FORMAT_PRESET]
}

function createTypeFormatter(
  f: GraphingValueFormat,
  options: { forceTrim?: boolean } = {}
): ValueFormatter {
  switch (f.type) {
    case 'custom': {
      return toFormatter(f.specifier)
    }
    case 'round':
    case 'fixed': {
      const typeChar = f.type === 'fixed' ? 'f' : ''
      const trimChar = f.untrimmed && !options.forceTrim ? '' : '~'
      const precision = f.precision ?? (f.type === 'fixed' ? 3 : 4)
      return toFormatter(`,.${precision}${trimChar}${typeChar}`)
    }
    case 'short': {
      const trimChar = f.untrimmed && !options.forceTrim ? '' : '~'
      const specifier = `,.${f.precision ?? 4}${trimChar}`
      const si = toFormatter(`${specifier}s`)
      const base = toFormatter(specifier)
      return createShortNumberFormatter(si, base)
    }
    default:
    case 'short-fixed': {
      return (n, tickIndex) => {
        return tickIndex == null
          ? formatShortFixedNumber(Number(n), {
              precision: 1,
              noMajorRoundUp: true,
            })
          : formatShortNumber(n, tickIndex)
      }
    }
  }
}

function _createGraphingValueTransformer(
  transform?: GraphingValueFormatTransform
): (n: number, opts?: { invert?: boolean }) => number {
  switch (transform) {
    case 'percent':
      return (n, { invert } = {}) => (!invert ? n * 100 : n / 100)
    case 'hundreds':
      return (n, { invert } = {}) => (!invert ? n / 100 : n * 100)
    case 'thousands':
      return (n, { invert } = {}) => (!invert ? n / 1000 : n * 1000)
    case 'identity':
    default:
      return n => n.valueOf()
  }
}

export function createGraphingValueTransformer(
  transform?: GraphingValueFormatTransform
): GraphingValueTransformer {
  const fn = _createGraphingValueTransformer(transform)
  return (n, opts) => fn(n.valueOf(), opts)
}

function createValueFormatter(
  f: GraphingValueFormat,
  options: { forceTrim?: boolean } = {}
): ValueFormatter {
  const typeFormatter = createTypeFormatter(f, options)
  const transformValue = createGraphingValueTransformer(f.transform)
  return (n, tickIndex) => {
    const val = transformValue(n)
    return `${f.prefix ?? ''}${typeFormatter(val, tickIndex)}${f.suffix ?? ''}`
  }
}

export function createGraphingValueFormatter(
  formatOrPreset?: GraphingValueFormat | GraphingValueFormatPreset
): ValueFormatter {
  const f = toGraphingValueFormat(formatOrPreset)
  const formatBase = createValueFormatter(f)
  const formatTick = f.tick
    ? createValueFormatter(toGraphingValueFormat(f.tick))
    : createValueFormatter(f, { forceTrim: true })
  const formatAlt = createValueFormatter(toGraphingValueFormat(f.alt))
  return (n, tickIndex) => {
    if (tickIndex == null) {
      return formatBase(n)
    }
    if (tickIndex === 'alt') {
      return formatAlt(n)
    }
    return formatTick(n, tickIndex)
  }
}

export const formatShortNumber = createGraphingValueFormatter('short')
export const formatPercent = createGraphingValueFormatter('percent')

// Date

const formatDate = utcFormat('%Y-%m-%d')
export const formatDateMMDDYY = utcFormat('%m/%d/%Y') /* date as MM/dd/yyyy */
export const formatYear = utcFormat('%Y')
export const formatMonth =
  utcFormat('%m') /* month as a decimal number [01, 12] */
export const formatYearMonth = utcFormat('%Y-%m')
export const formatYearQuarter = (date: Date, quarter: string) =>
  `${utcFormat('%Y')(date)}-${quarter}`

function formatPeriodEndQuarter(date: Date): string {
  const mm = date.getUTCMonth()
  const dd = date.getUTCDate()
  const yy = formatYear(date)
  if ((mm === 0 && dd < 4) || (mm === 2 && dd > 27)) {
    return `1Q ${yy}`
  } else if ((mm === 3 && dd < 4) || (mm === 5 && dd > 27)) {
    return `2Q ${yy}`
  } else if ((mm === 6 && dd < 4) || (mm === 8 && dd > 27)) {
    return `3Q ${yy}`
  } else if ((mm === 9 && dd < 4) || (mm === 11 && dd > 27)) {
    return `4Q ${yy}`
  }
  return formatDate(date)
}

function formatTickQuarter(date: Date, opts?: FormatQuarterOptions): string {
  const mm = date.getUTCMonth()
  const dd = date.getUTCDate()
  if (
    opts?.exactMonth
      ? (mm === 0 && dd === 1) || (mm === 11 && dd === 31)
      : mm < 3
  ) {
    return opts?.forceQ1
      ? opts?.onlyHalves
        ? 'H1'
        : opts.asMonth
        ? 'Jan'
        : 'Q1'
      : formatYear(date)
  } else if (
    opts?.exactMonth
      ? (mm === 3 && dd === 1) || (mm === 2 && dd === 31)
      : mm < 6
  ) {
    return opts?.onlyHalves || opts?.onlyQ1 ? '' : opts?.asMonth ? 'Apr' : 'Q2'
  } else if (
    opts?.exactMonth
      ? (mm === 6 && dd === 1) || (mm === 5 && dd === 30)
      : mm < 9
  ) {
    return opts?.onlyHalves
      ? 'H2'
      : opts?.onlyQ1
      ? ''
      : opts?.asMonth
      ? 'Jul'
      : 'Q3'
  } else if (
    opts?.exactMonth ? (mm === 9 && dd === 1) || (mm === 8 && dd === 30) : true
  ) {
    return opts?.onlyHalves || opts?.onlyQ1 ? '' : opts?.asMonth ? 'Oct' : 'Q4'
  }
  return ''
}

const toDate = (n: number | Numeric): Date =>
  (typeof n === 'number' ? new Date(n) : n) as Date

export const createTimeFormatterForPeriod =
  (periodicity?: Periodicity): ValueFormatter =>
  (n, tickIndex) => {
    const date = toDate(n)
    const nonTick = tickIndex == null
    switch (periodicity ?? 'annual') {
      case 'annual':
        return nonTick
          ? formatYear(date)
          : formatTickQuarter(date, { onlyQ1: true, exactMonth: true })
      case 'interim':
        return nonTick
          ? formatPeriodEndQuarter(date)
          : formatTickQuarter(date, { onlyHalves: true, exactMonth: true })
      case 'quarterly':
        return nonTick
          ? formatPeriodEndQuarter(date)
          : formatTickQuarter(date, { exactMonth: true })
      case 'monthly':
        return nonTick
          ? formatDate(date)
          : formatTickQuarter(date, { exactMonth: true, asMonth: true })
    }
  }
