import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../core/store'
import { startAnalysis, reinitialize } from '../../store/analysis.actions'
import { selectClientsByID } from '../../../core/store/clients.selectors'
import {
  selectCurrentAnalysisProfile,
  selectCurrentYearID,
} from '../../../core/store/broker/broker.selectors'
import { selectCurrentStudyPrograms } from '../../../core/store/program/program.selectors'
import { Program } from '../../../core/model/program.model'
import { setCurrentAnalysisProfile } from '../../../core/store/broker/broker.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-route',
  templateUrl: './portfolio-route.container.html',
})
export class PortfolioRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  programs$: Observable<Program[]>

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(
          this.store.pipe(select(selectClientsByID)),
          this.store.pipe(select(selectCurrentYearID)),
          this.store.pipe(select(selectCurrentAnalysisProfile))
        ),
        map(([paramMap, clients, yearID, analysisProfile]) => {
          const portfolios = JSON.parse(paramMap.get('portfolios') || '') as {
            cededPortfolioID: string
            grossPortfolioID: string
            netPortfolioID: string
            parentGrossPortfolioID: string
          }
          if (
            !analysisProfile ||
            analysisProfile.id !== paramMap.get('analysisID')
          ) {
            this.store.dispatch(
              setCurrentAnalysisProfile({
                id: paramMap.get('analysisID') as string,
              })
            )
          }
          if (Object.keys(clients).length === 0 || yearID === null) {
            return reinitialize({
              cededPortfolioID: portfolios.cededPortfolioID,
              grossPortfolioID: portfolios.grossPortfolioID,
              netPortfolioID: portfolios.netPortfolioID,
              parentGrossPortfolioID: portfolios.parentGrossPortfolioID,
              analysisProfileID: paramMap.get('analysisID') || '',
              clientID: paramMap.get('clientID') || '',
              studyID: paramMap.get('programID') || '',
              yearID: paramMap.get('yearID') || '',
              structureID: paramMap.get('structureID') || '',
            })
          } else {
            return startAnalysis({
              cededPortfolioID: portfolios.cededPortfolioID,
              grossPortfolioID: portfolios.grossPortfolioID,
              netPortfolioID: portfolios.netPortfolioID,
              parentGrossPortfolioID: portfolios.parentGrossPortfolioID,
              analysisProfileID: paramMap.get('analysisID') || '',
              clientID: paramMap.get('clientID') || '',
              fromSave: false,
              studyID: paramMap.get('programID') || '',
              yearID: paramMap.get('yearID') || '',
              structureID: paramMap.get('structureID') || '',
            })
          }
        })
      )
      .subscribe(this.store)
    this.programs$ = this.store.pipe(select(selectCurrentStudyPrograms))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
