import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { AppState } from '../../core/store'
import { Store, select } from '@ngrx/store'
import * as fromPricingCurve from '../store/pricing-curve.actions'
import {
  PricingCurveContextTypes,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SavedPricingCurveEntry,
} from '../model/pricing-curve.model'
import * as fromSelectors from '../store/pricing-curve.selectors'
import { map } from 'rxjs/operators'
import { selectAuthState } from '../../core/store/auth/auth.selectors'
import { PricingCurve } from '../model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-container',
  templateUrl: './pricing-curve.container.html',
})
export class PricingCurveContainerComponent implements OnInit, OnDestroy {
  savedCurves$: Observable<SavedPricingCurveEntry[]>
  pricingCurves$: Observable<PricingCurve[]>
  status$: Observable<PricingCurveStatus>
  username$: Observable<string | null>
  context$: Observable<PricingCurveContextTypes>
  graphSettings$: Observable<PricingCurveGraphSettings>
  readonly$: Observable<boolean>

  private destroy$ = new Subject()

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(fromPricingCurve.resetModuleToDefaultState())
    this.store.dispatch(
      fromPricingCurve.initPricingCurveData({ context: 'pricing-curve' })
    )
    this.initObservables()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  initObservables(): void {
    this.savedCurves$ = this.store.select(
      fromSelectors.selectSavedCurvesExcludingAdded
    )
    this.pricingCurves$ = this.store.select(fromSelectors.selectPricingCurves)
    this.status$ = this.store.select(fromSelectors.selectPricingCurveStatus)
    this.username$ = this.store.pipe(
      select(selectAuthState),
      map(state => state.username)
    )
    this.context$ = this.store.select(fromSelectors.selectPricingCurveContext)
    this.graphSettings$ = this.store.select(fromSelectors.selectPricingCurveGraphSettings)
    this.readonly$ = this.store.select(fromSelectors.selectIsPricingCurveReadOnly)
  }
}
