import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { PortfolioSetID } from 'src/app/analysis/model/portfolio-set.model'
import { SavePortfolioPropertiesEvent } from 'src/app/analysis/model/tower-properties.model'
import { StructureTowerPreferencesRequest } from 'src/app/api/model/backend.model'
import { Program } from 'src/app/core/model/program.model'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-tower-properties',
  templateUrl: './portfolio-tower-properties.component.html',
  styleUrls: ['./portfolio-tower-properties.component.scss'],
})
export class PortfolioTowerPropertiesComponent {
  @Input() set incrementsYOcc(value: number | null) {
    if (value) {
      this.editIncrementsYOcc = JSON.parse(JSON.stringify(value))
      if (!this.initialIncrementsYOcc) {
        this.initialIncrementsYOcc = this.editIncrementsYOcc
      }
    }
  }
  @Input() incrementsYDirtyOcc: boolean
  @Input() set maxYOcc(value: number | null) {
    if (value) {
      this.editMaxYOcc = JSON.parse(JSON.stringify(value))
      if (!this.initialMaxYOcc) {
        this.initialMaxYOcc = this.editMaxYOcc
      }
    }
  }
  @Input() maxYDirtyOcc: boolean
  @Input() mostOcc: number

  @Input() set incrementsYAgg(value: number | null) {
    if (value) {
      this.editIncrementsYAgg = JSON.parse(JSON.stringify(value))
      if (!this.initialIncrementsYAgg) {
        this.initialIncrementsYAgg = this.editIncrementsYAgg
      }
    }
  }
  @Input() incrementsYDirtyAgg: boolean
  @Input() set maxYAgg(value: number | null) {
    if (value) {
      this.editMaxYAgg = JSON.parse(JSON.stringify(value))
      if (!this.initialMaxYAgg) {
        this.initialMaxYAgg = this.editMaxYAgg
      }
    }
  }
  @Input() maxYDirtyAgg: boolean
  @Input() mostAgg: number
  @Input() snapping: boolean
  @Input() log: boolean
  @Input() logMin: number
  @Input() portfolioSetID: PortfolioSetID | null
  @Input() layers: LayerState[]
  @Input() aggLayers: LayerState[]

  @Input() programID: Program | undefined
  @Input() currentCurrency: string

  @Output() saveProperties = new EventEmitter<SavePortfolioPropertiesEvent>()
  @Output() propertiesSave = new EventEmitter<
    SavePortfolioPropertiesEvent & {
      id: string
      preferences: StructureTowerPreferencesRequest[]
    }
  >()

  initialIncrementsYOcc: number
  editIncrementsYOcc: number
  initialMaxYOcc: number
  editMaxYOcc: number

  initialIncrementsYAgg: number
  editIncrementsYAgg: number
  initialMaxYAgg: number
  editMaxYAgg: number

  constructor(
    public dialogRef: MatDialogRef<PortfolioTowerPropertiesComponent>
  ) {}

  get getIncrementsYOcc(): number {
    return this.editIncrementsYOcc
  }
  set setIncrementsYOcc(value: number) {
    this.editIncrementsYOcc = value
  }
  get getLogMin(): number {
    return this.logMin
  }
  set setLogMin(value: number) {
    this.logMin = value
  }
  get getMaxYOcc(): number {
    return this.editMaxYOcc
  }
  set setMaxYOcc(value: number) {
    this.editMaxYOcc = value
  }

  get getIncrementsYAgg(): number {
    return this.editIncrementsYAgg
  }
  set setIncrementsYAgg(value: number) {
    this.editIncrementsYAgg = value
  }
  get getMaxYAgg(): number {
    return this.editMaxYAgg
  }
  set setMaxYAgg(value: number) {
    this.editMaxYAgg = value
  }

  get isDisabled(): boolean {
    return this.getIncrementsYOcc === 0 || this.getIncrementsYAgg === 0
  }

  onLogToggle($event: MatSlideToggleChange) {
    this.log = $event.checked
  }
  onSnappingToggle($event: MatSlideToggleChange) {
    this.snapping = $event.checked
  }

  onSaveProperties() {
    if (!this.isDisabled) {
      if (this.programID) {
        const preferences = [
          {
            increment_y_agg: this.getIncrementsYAgg
              ? this.getIncrementsYAgg
              : 0,
            increment_y_occ: this.getIncrementsYOcc,
            y_max_agg: this.getMaxYAgg ? this.getMaxYAgg : 0,
            y_max_occ: this.getMaxYOcc,
          },
        ]
        this.propertiesSave.emit({
          id: this.programID.id,
          preferences,
          portfolioSetID: this.portfolioSetID,
          occ: {
            incrementsY: this.editIncrementsYOcc,
            incrementsYDirty: true,
            maxY: this.editMaxYOcc,
            maxYDirty: true,
            // tslint:disable-next-line:no-non-null-assertion
            most: this.mostOcc!,
          },
          agg: {
            incrementsY: this.editIncrementsYAgg,
            incrementsYDirty: this.aggLayers.length > 0 ? true : false,
            maxY: this.editMaxYAgg,
            maxYDirty: this.aggLayers.length > 0 ? true : false,
            // tslint:disable-next-line:no-non-null-assertion
            most: this.mostAgg!,
          },
          log: this.log,
          snapping: this.snapping,
          logMin: this.logMin,
        })
      }

      this.saveProperties.emit({
        portfolioSetID: this.portfolioSetID,
        occ: {
          incrementsY: this.editIncrementsYOcc,
          incrementsYDirty: true,
          maxY: this.editMaxYOcc,
          maxYDirty: true,
          // tslint:disable-next-line:no-non-null-assertion
          most: this.mostOcc!,
        },
        agg: {
          incrementsY: this.editIncrementsYAgg,
          incrementsYDirty: this.aggLayers.length > 0 ? true : false,
          maxY: this.editMaxYAgg,
          maxYDirty: this.aggLayers.length > 0 ? true : false,
          // tslint:disable-next-line:no-non-null-assertion
          most: this.mostAgg!,
        },
        log: this.log,
        snapping: this.snapping,
        logMin: this.logMin,
      })
      this.dialogRef.close()
    }
  }
}
