import { createAction, props } from '@ngrx/store'
import {
  AgencyDetails,
  AgencyDetailsSage,
  Reinsurer,
  ReinsurerFilter,
} from '../../core/model/reinsurer.model'

import { ApplicationError } from '../../error/model/error'

export const fetchStudyReinsurer = createAction(
  '[Study Reinsurers] Fetch',
  props<{ carrierID: string; studyID: string }>()
)

export const fetchStudyReinsurerSuccess = createAction(
  '[Study Reinsurers] Fetch Success',
  props<{
    programID: string
    reinsurers: Reinsurer[]
  }>()
)

export const fetchStudyReinsurerFailure = createAction(
  '[Study Reinsurers] Fetch Failure',
  props<{ programID: string; error: ApplicationError }>()
)

export const updateReinsurer = createAction(
  '[Study Reinsurers] Update Reinsurer'
)

export const updateReinsurerSuccess = createAction(
  '[Study Reinsurers] Update Reinsurer Success'
)

export const updateReinsurerFailure = createAction(
  '[Study Reinsurers] Update Reinsurer Failure',
  props<{ programID: string; error: ApplicationError }>()
)

export const setReinsurer = createAction(
  '[Study Reinsurers] Set Reinsurer',
  props<{ programID: string; reinsurers: Reinsurer[] }>()
)

export const saveReinsurer = createAction(
  '[Study Reinsurers] Save Reinsurer',
  props<{ reinsurers: Reinsurer[] }>()
)

export const setUpdateOneReinsurer = createAction(
  '[Study Reinsurers] Set Update One Reinsurer',
  props<{ programID: string; reinsurer: Reinsurer }>()
)


export const fetchStudiesReinsurerSuccess = createAction(
  '[Studies Reinsurers] Fetch Success',
  props<{
    reinsurersByStudy: { programID: string; reinsurers: Reinsurer[] }[]
  }>()
)

export const fetchStudiesReinsurerFailure = createAction(
  '[Studies Reinsurers] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const updateOrAddStudiesReinsurerDirty = createAction(
  '[Studies Reinsurers] Update or Add Dirty',
  props<{ reinsurer: Reinsurer }>()
)

export const removeAgencySeqFromReinsurerDirty = createAction(
  '[Studies Reinsurers] Remove Agency Sequence From Reinsurer Dirty',
  props<{ agencyTPRef: string, sequenceNumber: number }>()
)

export const removeAgencyFromReinsurerDirty = createAction(
  '[Studies Reinsurers] Remove Agency From Reinsurer Dirty',
  props<{ agencyTPRef: string }>()
)

export const deleteClientStudiesReinsurerDirty = createAction(
  '[Studies Reinsurers] Delete Studies'
)

export const toggleReinsurerFilter = createAction(
  '[Studies Reinsurers] Toggle Reinsurer Filter',
  props<{ reinsurerFilter: ReinsurerFilter }>()
)

export const removeAllReinsurerFilters = createAction(
  '[Studies Reinsurers] Remove All Reinsurer Filters'
)

export const removeReinsurerFilter = createAction(
  '[Studies Reinsurers] Remove Reinsurer Filter',
  props<{ reinsurerFilter: ReinsurerFilter }>()
)

export const populateFromReinsurer = createAction(
  '[Study Reinsurers] Populate from Reinsurer',
  props<{ client: string; program: string }>()
)

export const fetchPopulateFromReinsurerSuccess = createAction(
  '[Study Reinsurers] Populate From Fetch Success',
  props<{ reinsurers: Reinsurer[] }>()
)

export const fetchPopulateFromReinsurerFailure = createAction(
  '[Study Reinsurers] Populate From Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const exportReinsurers = createAction(
  '[Study Reinsurers] Export XLSX',
  props<
  { 
    customBreakOutSelections: string[],
    customDisplayFieldSelections: string[]
  }>()
)

export const exportReinsurersSuccess = createAction(
  '[Study Reinsurers] Export XLSX Success'
)

export const openAgencyDetailsDialog = createAction(
  '[Study Reinsurers] Open Agency Details Dialog',
  props<{ data: Reinsurer; programId: string | null; select: string }>()
)

export const openReinsurerDetailsDialog = createAction(
  '[Study Reinsurers] Open Reinsurer Details Dialog',
  props<{ data: Reinsurer; programID?: string }>()
)

export const fetchAgencyDetailsFailure = createAction(
  '[Study Reinsurers] Fetch Agency Details Failure',
  props<{ error: ApplicationError }>()
)

export const postAgencyDetailsToOpenTWINSFailure = createAction(
  '[Study Reinsurers] Post Agency Details to OpenTWINS Failure',
  props<{ error: ApplicationError }>()
)

export const saveAgencyDetailsSuccess = createAction(
  '[Study Reinsurers] Save Agency Details Success'
)

export const saveAgencyDetails = createAction(
  '[Study Reinsurers] Save Agency Details',
  props<{ agencyDetails: AgencyDetailsSage[] }>()
)

export const deleteAgencyTemp = createAction(
  '[Studies Reinsurers] Delete Agency Temp',
  props<{ re: Reinsurer }>()
)

export const openFundManagerDetailsDialog = createAction(
  '[Study Reinsurers] Open Fund Manager Details Dialog',
  props<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()
)

export const fetchCompanyPapersSuccess = createAction(
  '[Studies Reinsurers] Fetch Company Papers Success',
  props<{
    programID: string
    reinsurer: Reinsurer
    companyPapers: AgencyDetails[]
    selectedCompanyPapers: AgencyDetails[]
  }>()
)
export const fetchCompanyPapersFailure = createAction(
  '[Studies Reinsurers] Fetch Company Papers Failure',
  props<{ error: ApplicationError }>()
)

export const fetchMultipleCompanyPapers = createAction(
  '[Studies Reinsurers] Fetch Multiple Company Papers',
  props<{ programID: string; fundManagers: Reinsurer[] }>()
)

export const fetchMultipleCompanyPapersSuccess = createAction(
  '[Studies Reinsurers] Fetch Multiple Company Papers Success',
  props<{
    programID: string
    fundManagers: Reinsurer[]
    companyPaperPairs: Record<string, AgencyDetails[]>
  }>()
)

export const saveCompanyPapers = createAction(
  '[Studies Reinsurers] Save Company Papers',
  props<{
    programID: string
    reinsurer: Reinsurer
    selectedCompanyPapers: AgencyDetails[]
  }>()
)

export const saveCompanyPapersSuccess = createAction(
  '[Studies Reinsurers] Save Company Papers Success',
  props<{
    programID: string
    reinsurer: Reinsurer
    selectedCompanyPapers: AgencyDetails[]
  }>()
)

export const saveCompanyPapersFailure = createAction(
  '[Studies Reinsurers] Save Company Papers Failure',
  props<{ error: ApplicationError }>()
)

export const openSegregatedAccountDetailsDialog = createAction(
  '[Studies Reinsurers] Open Segregated Account Details for Assigned Lines',
  props<{
    programID: string
    fundManager: Reinsurer
  }>()
)

export const fetchSegregatedAccountDetailsSuccess = createAction(
  '[Studies Reinsurers] Fetch Segregated Account Details for Assigned Lines Success',
  props<{
    programID: string
    fundManager: Reinsurer
    segregatedAccounts: AgencyDetails[]
    selectedSegregatedAccounts?: AgencyDetails[]
  }>()
)

export const fetchMultipleSegregatedAccountDetailsSuccess = createAction(
  '[Studies Reinsurers] Fetch Multiple Segregated Account Details Success',
  props<{
    programID: string
    fundManagers: Reinsurer[]
    segregatedAccountPairs: Record<string, AgencyDetails[]>
  }>()
)

export const fetchSegregatedAccountDetailsFailure = createAction(
  '[Studies Reinsurers] Fetch Segregated Account Details for Assigned Lines Failure',
  props<{ error: ApplicationError }>()
)

export const saveSegregatedAccountDetails = createAction(
  '[Studies Reinsurers] Save Segregated Account Details',
  props<{
    programID: string
    reinsurer: Reinsurer
    selectedSegregatedAccounts: AgencyDetails[]
  }>()
)

export const saveSegregatedAccountDetailsFailure = createAction(
  '[Studies Reinsurers] Save Segregated Account Details Failure',
  props<{ error: ApplicationError }>()
)

export const saveSegregatedAccountDetailsSuccess = createAction(
  '[Studies Reinsurers] Save Segregated Account Details Success',
  props<{
    programID: string
    reinsurer: Reinsurer
    selectedSegregatedAccounts: AgencyDetails[]
  }>()
)
