import { NgModule } from '@angular/core'
import { MatSortModule } from '@angular/material/sort'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SharedModule } from '../../shared/shared.module'
import { LayerModelingRoutingModule } from './layer-modeling-routing.module'
import { LayerModelingComponent } from './layer-modeling.component/layer-modeling.component'
import { LayerModelingContainerComponent } from './layer-modeling.container/layer-modeling.container'
import { LayerModelingEffects } from './store/layer-modeling.effects'
import * as fromLayerModeling from './store/layer-modeling.reducer'

@NgModule({
  declarations: [LayerModelingComponent, LayerModelingContainerComponent],
  imports: [
    LayerModelingRoutingModule,
    SharedModule,
    MatSortModule,
    StoreModule.forFeature(
      fromLayerModeling.LAYER_MODELING_FEATURE_KEY,
      fromLayerModeling.reducer
    ),
    EffectsModule.forFeature([LayerModelingEffects]),
  ],
  exports: [LayerModelingComponent],
})
export class LayerModelingModule {}
