import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

export interface ImportLossSetsData {
  parentGrossPortfolioID: string
}

export interface ImportLossSetsResult {
  parentGrossPortfolioID: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-set-name-dialog',
  styles: [
    `
           .full-width {
             width: 100%;
           }
     
           .duplicate-error {
             font-size: small;
           }
     
           mat-form-field:not(:last-of-type) {
             margin-bottom: var(--stack);
           }
         `,
  ],
  template: `
    <div class="clone-dialog">
      <h1 mat-dialog-title>Import Loss Sets</h1>
      <mat-dialog-content>
        <mat-form-field class="full-width">
          <input
            matInput
            required
            placeholder="Parent Gross Portfolio ID"
            [(ngModel)]="parentGrossPortfolioID"
          />
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button appButton border (click)="onSave()">Import Loss Sets</button>
        <button appButton border (click)="onClose()">Close</button>
      </mat-dialog-actions>
    </div>
  `,
})
export class ImportLossSetsDialogComponent {
  constructor(public dialogRef: MatDialogRef<ImportLossSetsDialogComponent>) {}
  parentGrossPortfolioID: string
  onSave() {
    // Close the dialog
    if (this.parentGrossPortfolioID) {
      this.dialogRef.close({
        parentGrossPortfolioID: this.parentGrossPortfolioID,
      })
    }
  }
  onClose() {
    this.dialogRef.close({})
  }
}
