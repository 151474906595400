import { partialRight } from 'ramda'
import { PhysicalLayer } from 'src/app/analysis/model/layers.model'
import { Metadata } from 'src/app/api/analyzere/analyzere.model'

export const indexedLayerType = 'noncat_indxl'
type IndexedLayerSubtypes =
  | 'visible-layer'
  | 'main-layer'
  | 'loss-layer'
  | 'settlement-layer'

export const indexationFull = 0
export const indexationSevere = 1
export const indexationFranchise = 2

export type HasId = { id: string }
export type HasMetadata = { meta_data: Partial<Metadata> }

export const isIndexedLayer = <T>(
  { meta_data: m }: T & HasMetadata,
  ...subtypes: (IndexedLayerSubtypes | Omit<string, IndexedLayerSubtypes>)[]
): boolean =>
  m.sage_layer_type === indexedLayerType &&
  (subtypes.length === 0 || subtypes.includes(m.sage_layer_subtype ?? ''))

export const isIndexedVisibleLayer = partialRight(isIndexedLayer, [
  'visible-layer',
])
export const isIndexedMainLayer = partialRight(isIndexedLayer, ['main-layer'])

export function getFixedIndexValue(physical: PhysicalLayer) {
  return physical.meta_data.fixedIndexValue
}

export function getCustomIndexValues(physical: PhysicalLayer) {
  const json = physical.meta_data.customIndexValues
  if (typeof json !== 'string') {
    return undefined
  }

  const customIndexValues = customIndexValuesFromString(json)
  if (
    !Array.isArray(customIndexValues) ||
    !customIndexValues.every(v => typeof v === 'number')
  ) {
    return undefined
  }

  return customIndexValues as Array<number>
}

/**
 * Convert a string of custom index values separated with commas into a number array.
 *
 * @param s a string of custom index values
 * @return the number array
 */
export function customIndexValuesFromString(s: string): number[] {
  return s.split(',').map(Number)
}

/**
 * Convert the custom index values array to a string separated with commas.
 *
 * @param values an array of custom index value numbers
 * @return the string containing the custom index values
 */
export function customIndexValuesToString(values: number[]): string {
  return values.join(',')
}
