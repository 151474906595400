import { HasID } from '../../core/model/general.model'
import graphingColorPalette, {
  GraphingColorPalette,
  graphingColorPaletteExtended,
} from '@graphing/utils/graphing-color-palette'
import { GraphingStrokeStyle } from '@graphing/models/graphing.model'
import { BenchmarkPeerSetEntity } from '../store/benchmark-peer-set.reducer'
import { BenchmarkTargetValue } from './benchmark-chart'
import { BenchmarkOption, benchmarkOptions } from './benchmark-options'
import { BenchmarkSubmode } from './benchmark-submode'
import { BenchmarkCompany, BenchmarkPeerSet } from './benchmark.model'

function createSecondaryPalette(
  palette: readonly GraphingColorPalette[]
): GraphingColorPalette[] {
  return palette.filter(p => p !== 'salmon' && p !== 'teal')
}

const secondaryPalette = createSecondaryPalette(graphingColorPalette)

const swatchCount = graphingColorPalette.length + secondaryPalette.length

export function colorClass(d: number): string
export function colorClass<T extends object>(
  d: T,
  i: number,
  color?: string
): string
export function colorClass<T extends object>(
  d: T | number,
  i?: number,
  color?: string
): string {
  if (color) {
    return `app-palette-${color}`
  }
  i = typeof d === 'number' && (i == null || typeof i !== 'number') ? d : i
  if (i == null) {
    throw TypeError('`colorClass` requires an index.')
  }
  const swatchIndex = i % swatchCount
  const secondary = swatchIndex >= graphingColorPalette.length
  let cls: string
  if (!secondary) {
    cls = `app-palette-${graphingColorPalette[swatchIndex]}`
  } else {
    const secondaryIndex = swatchIndex - graphingColorPalette.length
    const c = secondaryPalette[secondaryIndex]
    cls = `app-palette-${c} app-palette-secondary`
  }
  return cls
}

export const makeGetBenchmarkColorClass = (
  palette: readonly GraphingColorPalette[],
  { asColorOnly }: { asColorOnly?: boolean } = {}
) => {
  const _secondaryPalette = createSecondaryPalette(palette)
  const _swatchCount = palette.length + _secondaryPalette.length

  function getClass(d: number): string
  function getClass<T extends object>(d: T, i: number, color?: string): string
  function getClass<T extends object>(
    d: T | number,
    i?: number,
    color?: string
  ): string {
    if (color) {
      return asColorOnly ? color : `app-palette-${color}`
    }
    i = typeof d === 'number' && (i == null || typeof i !== 'number') ? d : i
    if (i == null) {
      throw TypeError('`colorClass` requires an index.')
    }
    const swatchIndex = i % _swatchCount
    const secondary = swatchIndex >= palette.length
    let cls: string
    if (!secondary) {
      const c = palette[swatchIndex]
      cls = asColorOnly ? c : `app-palette-${c}`
    } else {
      const secondaryIndex = swatchIndex - palette.length
      const c = _secondaryPalette[secondaryIndex]
      cls = asColorOnly ? c : `app-palette-${c} app-palette-secondary`
    }
    return cls
  }

  return getClass
}

function getMetricColorInfo(targetValues: BenchmarkTargetValue[]) {
  return targetValues.reduce(
    (acc, tv) => {
      if (tv.show) {
        const color = tv.def.color
        if (color && !acc.reservedColors.has(color)) {
          acc.colorByIndex[acc.i] = color
          acc.reservedColors.add(color)
        }
        acc.i++
      }
      return acc
    },
    { colorByIndex: {}, reservedColors: new Set(), i: 0 } as {
      colorByIndex: Record<number, GraphingColorPalette>
      reservedColors: Set<GraphingColorPalette>
      i: number
    }
  )
}

export function makeGetBenchmarkTargetValueColor(
  targetValues: BenchmarkTargetValue[]
) {
  const { colorByIndex, reservedColors } = getMetricColorInfo(targetValues)

  const palette = graphingColorPaletteExtended.filter(
    color => !reservedColors.has(color)
  )
  const getColorClass = makeGetBenchmarkColorClass(palette)

  return (targetValueIndex?: number) => {
    const i = Number(targetValueIndex)
    return getColorClass({}, i, colorByIndex[i])
  }
}

export function getColorIndices(
  entities: (BenchmarkCompany | BenchmarkPeerSet)[],
  startIndex = 0
): number[] {
  let i = startIndex
  return entities.map(p => (!(p as any).hide ? i++ : -1))
}

export interface BenchmarkLineStyle {
  color: string
  style: GraphingStrokeStyle
  overflow?: number
}

interface HasKeyWithID {
  key: { id: string | number }
}

type BenchmarkOptionWithColor = Omit<BenchmarkOption, 'color' | 'style'> & {
  color: string
  style: GraphingStrokeStyle
}

const optionsWithColor = benchmarkOptions.filter(
  o => o.color != null && o.style != null
) as BenchmarkOptionWithColor[]

export const createBenchmarkColorStyleByIndex = (
  data: HasKeyWithID[],
  colorsByID: Record<string | number, BenchmarkLineStyle>
) =>
  data.reduce(
    (acc, d, i) => ({ ...acc, [i]: colorsByID[d.key.id] }),
    {} as Record<number, BenchmarkLineStyle>
  )

const colorsByDataOptionID = (submode: BenchmarkSubmode | null) =>
  optionsWithColor.reduce((acc, opt) => {
    const cfg = submode?.options?.find(o => o.id === opt.id)

    let color = `app-palette-${cfg?.color ?? opt.color}`
    if (opt.classMod) {
      color += ` ${cfg?.classMod ?? opt.classMod}`
    }
    acc[opt.id] = { color, style: cfg?.style ?? opt.style }
    return acc
  }, {} as Record<string | number, BenchmarkLineStyle>)

export function getBenchmarkColorStyle(
  i: number,
  stroke?: GraphingStrokeStyle
): BenchmarkLineStyle {
  const color = colorClass(i)
  const style: GraphingStrokeStyle = stroke ?? 'solid'
  const result: BenchmarkLineStyle = { color, style }
  const overflow = Math.floor(i / swatchCount)
  if (overflow > 0) {
    result.style = 'dash'
    result.overflow = overflow
  }
  return result
}

export const getBenchmarkColorStyleByCompanyGroupID = (
  submode: BenchmarkSubmode | null,
  targetCompany: HasID | null,
  peerSetEntities: BenchmarkPeerSetEntity[],
  peers: BenchmarkCompany[]
): Record<string | number, BenchmarkLineStyle> => {
  let i = 0
  const colorsByID: Record<string | number, BenchmarkLineStyle> = {}
  if (targetCompany) {
    colorsByID[targetCompany.id] = getBenchmarkColorStyle(i, 'bold')
    i++
  }
  peerSetEntities.forEach(pse => {
    colorsByID[pse.peerSet.id] = getBenchmarkColorStyle(i)
    i++
  })
  peers.forEach(p => {
    colorsByID[p.id] = getBenchmarkColorStyle(i)
    i++
  })

  const optionsColors = colorsByDataOptionID(submode)

  return { ...colorsByID, ...optionsColors }
}

export function getBenchmarkChipColorClassesForIndex(
  i: number,
  disableColors = false
): string[] {
  let colors: string[]
  if (disableColors) {
    colors = ['app-palette-primary']
  } else {
    const style = getBenchmarkColorStyle(i)
    colors = [style.color]
    if (style.overflow) {
      colors.push('app-chip-dash')
    }
  }
  return i >= 0 ? ['app-chip-palette', ...colors] : ['app-chip-outline']
}
