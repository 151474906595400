import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NavService } from '../../nav.service'
import { Router } from '@angular/router'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-digital-header',
  styleUrls: ['./digital-header.component.scss'],
  templateUrl: './digital-header.component.html',
})
export class DigitalHeaderComponent {
  @Input() name: string

  get subtitle(): string {
    return 'View and upload documents via the digital client.'
  }

  constructor(public navService: NavService, private router: Router) {}

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }
}
