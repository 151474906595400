import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { ApiResponse } from 'src/app/api/model/api.model'
import { CarrierData } from 'src/app/api/model/backend.model'

@Component({
  selector: 'app-program-initiation-dialog',
  templateUrl: './program-initiation-dialog.component.html',
  styleUrls: ['./program-initiation-dialog.component.scss'],
})
export class ProgramInitiationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ProgramInitiationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountName: string
      accountOpportunityName: string
      carriers$: ApiResponse<CarrierData[]>
    },
    private snackbar: MatSnackBar
  ) {}

  carriers?: CarrierData[]
  displayedColumns: string[] = ['name', 'action']
  dataSource: MatTableDataSource<CarrierData>
  enterMode: string
  fileToUpload: File | null

  ngOnInit(): void {
    this.data.carriers$.subscribe(res => {
      this.carriers = res.data?.sort((a, b) => a.name.localeCompare(b.name))
      this.dataSource = new MatTableDataSource(this.carriers)
    })
    this.enterMode = 'update'
  }

  mapToCarrier(element: any) {
    this.dialogRef.close({
      event: 'map_existing',
      mapToCarrierId: element.id,
      newCarrierName: element.name,
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' })
  }

  switchMode(mode: string) {
    this.enterMode = mode
  }

  confirmAction() {
    const formData: FormData = new FormData()
    if (!this.fileToUpload) {
      this.snackbar.open('Please Upload Carrier logo first', 'X', {
        duration: 2000,
      })
    } else if (
      this.fileToUpload.type !== 'image/png' &&
      this.fileToUpload.type !== 'png'
    ) {
      this.snackbar.open('File format is invalid, please upload png', 'X', {
        duration: 2000,
      })
    } else {
      if (this.fileToUpload && this.fileToUpload != null) {
        formData.append('fileKey', this.fileToUpload, this.fileToUpload.name)
      }

      this.dialogRef.close({
        event: 'map_new',
        newCarrierName: this.data.accountName,
        carrierLogoFile: formData,
      })
    }
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      this.fileToUpload = files.item(0)
    }
  }
}
