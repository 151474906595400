<button
  appCard
  details
  [ngClass]="{'edit-mode': editMode}"
  [imageSrc]="'assets/icons/folder.png'"
  [description]="structureCount"
  (click)="handleFolderClick()"
  id="{{ folderID }}"
>
  <div id="{{ folderID }}">{{ folderName }}</div>
</button>

<button
  appButtonIcon
  gigantic
  class="popover-button"
  [matMenuTriggerFor]="options"
  id="{{ folderID }}"
>
  more_horiz
</button>
<mat-menu
  class="app-menu app-menu-translucent"
  backdropClass="app-theme-dark"
  #options
  xPosition="before"
>
  <button mat-menu-item (click)="handleEditFolderNameClick()">
    Edit Name
  </button>
  <button mat-menu-item (click)="handleDeleteClick()">Delete</button>
</mat-menu>
