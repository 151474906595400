import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { ProgramInitiationRoutingModule } from './program-initiation-routing.module'
import { ProgramInitiationHeaderComponent } from './components/program-initiation-header/program-initiation-header.component'
import { ProgramInitiationRouteContainerComponent } from './components/program-initiation-route-container/program-initiation-route.container'
import { ProgramInitiationContainerComponent } from './components/program-initiation-container/program-initiation.container'
import { ProgramInitiationContentComponent } from './components/program-initiation-content/program-initiation-content.component'
import { ProgramInitiationComponent } from './components/program-initiation/program-initiation.component'
import { ProgramInitiationEffects } from './store/program-initiation.effects'
import { EffectsModule } from '@ngrx/effects'
import { ProgramInitiationFilterComponent } from './components/program-initiation-filter/program-initiation-filter.component'
import { ProgramInitiationDialogComponent } from './components/program-initiation-dialog/program-initiation-dialog.component'
import { ProgramInitiationMappingDialogComponent } from './components/program-initiation-mapping-dialog/program-initiation-mapping-dialog.component'
import { ProgramInitiationMappingActionDialogComponent } from './components/program-initiation-mapping-action-dialog/program-initiation-mapping-action-dialog.component'

@NgModule({
  declarations: [
    ProgramInitiationRouteContainerComponent,
    ProgramInitiationContainerComponent,
    ProgramInitiationHeaderComponent,
    ProgramInitiationContentComponent,
    ProgramInitiationComponent,
    ProgramInitiationFilterComponent,
    ProgramInitiationDialogComponent,
    ProgramInitiationMappingDialogComponent,
    ProgramInitiationMappingActionDialogComponent,
  ],
  imports: [
    CommonModule,
    ProgramInitiationRoutingModule,
    SharedModule,
    TierModule,
    EffectsModule.forFeature([ProgramInitiationEffects]),
  ],
})
export class ProgramInitiationModule {}
