import { UNLIMITED_VAL } from '../store/contracts/contracts.reducer'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import {
  Cedent,
  SignatureContract,
  SignatureReinsurer,
  SignatureReinsurerReferenceType,
} from '../signature.model'
import { FotState } from '../store/fots/fot.reducer'
import { clone } from 'ramda'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  RiskRefDetail,
  RiskRefDetailGroup,
  SalesforceClient,
} from '../../api/model/signature.model'
import { SignatureSelectClientsDialogComponent } from './signature-select-clients-dialog.component'
import { SignatureSelectRiskRefDialogComponent } from './signature-select-riskref-dialog.component'
import { getCurrencySymbol } from '@angular/common'
import { unabbreviateState } from '@graphing/models/graphing-geo'
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  NumberFormatListModel,
  PasteCleanupService,
  QuickToolbarService,
  RichTextEditor,
  ToolbarService,
  ToolbarSettingsModel,
  ToolbarType,
} from '@syncfusion/ej2-angular-richtexteditor'
import { MatDialog } from '@angular/material/dialog'

const DIALOG_ID = 'app-signature-select-clients-dialog'
const RISKREF_DIALOG_ID = 'app-signature-select-riskref-dialog-component'
const HEREINAFTER_TEXT = '-(hereinafter referred to as the "Company")'
const COLLECTIVELY_TEXT = '-(collectively, the "Company")'

@Component({
  selector: 'app-signature-form',
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    QuickToolbarService,
    PasteCleanupService,
  ],
  template: `
    <div class="disclaimer">
      <p>
        The SIGNATURE PAGE is intended to legally document and adequately
        identify that the reinsurer assumes a participation in a specific
        reinsurance contract.
      </p>
      <p>
        The Reinsurance Contract Signature Page Generator will generate
        signature pages electronically for brokers to use to obtain client and
        market signatures for contract execution.
      </p>
    </div>
    <div class="form-wrapper">
      <form [formGroup]="signatureForm">
        <!-- Contract Name -->
        <section class="contract-name">
          <mat-form-field
            subscriptSizing="dynamic"
          >
            <input
              matInput
              placeholder="Contract Name"
              matTooltip="Contract Name must follow exact name of Contract used on Contract Title Page"
              matTooltipClass="form-tooltip"
              matTooltipPosition="after"
              formControlName="contractName"
            />
          </mat-form-field>
          <p>(hereinafter referred to as the "Contract")</p>
        </section>
        <!-- Effective Date -->
        <section class="effective-date">
          <div class="inline-block">
            <p class="effective-label">EFFECTIVE:</p>
            <mat-form-field
              subscriptSizing="dynamic"
            >
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="contractEffectiveDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </section>
        <!-- Cedent(s) -->
        <section class="cedents">
          <p>issued to</p>
          <mat-expansion-panel hideToggle expanded="true">
            <mat-chip-grid>
              <mat-chip-row
                class="app-chip-palette app-palette-teal"
                matTooltip="{{ cedent.cedentLocation }}"
                matTooltipClass="form-tooltip"
                matTooltipPosition="after"
                *ngFor="let cedent of cedents"
                >{{ cedent.cedentName }}
              </mat-chip-row>
            </mat-chip-grid>
          </mat-expansion-panel>
          <p class="company-alias-container">
            <mat-form-field
              subscriptSizing="dynamic"
              class="company-alias"
              [ngStyle]="{ 'width.ch': 'auto' }"
            >
              <input
                matInput
                autocomplete="off"
                id="companyAliasInputId"
                [placeholder]="companyAlias"
                formControlName="companyAlias"
              />
            </mat-form-field>
            <span class="pencil">
              <button
                appButtonIcon
                big
                class="material-icons"
                (click)="onCedentsClick()"
                (click)="$event.stopPropagation()"
                matTooltip="Edit Carrier Clients"
                matTooltipClass="form-tooltip"
                matTooltipPosition="after"
              >
                <mat-icon inline>edit</mat-icon>
              </button>
            </span>
          </p>
        </section>
        <!-- Reinsurer -->
        <section formGroupName="reinsurer" class="reinsurer">
          <p class="by" >by</p>
          <mat-form-field
            subscriptSizing="dynamic"
            [ngStyle]="{ 'width.ch': reinsurerTextWidth }"
          >
            <input matInput id="reinsurerInput" placeholder="Reinsurer" formControlName="name" />
          </mat-form-field>
          <div class="inline-block">
            <mat-form-field
              subscriptSizing="dynamic"
              class="select-option"
            >
              <mat-label class="ref-type-label">Reinsurer Reference Type</mat-label>
              <mat-select formControlName="referenceType">
                <mat-option value="NAIC"> NAIC</mat-option>
                <mat-option value="AIIN"> AIIN</mat-option>
                <mat-option value="FEIN"> FEIN</mat-option>
              </mat-select>
            </mat-form-field>
            <p>Reference:</p>
            <mat-form-field
              subscriptSizing="dynamic"
            >
              <input
                matInput
                placeholder="Reference Number"
                formControlName="referenceNumber"
              />
            </mat-form-field>
          </div>
          <p>(the "Reinsurer" or "Subscribing Reinsurer")</p>
        </section>
        <!-- Layers -->
        <section class="layers">
          <p>
            Under the terms of the Contract, the above Reinsurer agrees to
            assume severally and not jointly with other participants:
          </p>
          <ng-container formArrayName="layers">
            <ng-container *ngFor="let layer of layers.controls; let i = index">
              <div class="form-section" [formGroup]="layer">
                <mat-expansion-panel
                  hideToggle
                  [expanded]="isLayerExpanded(i)"
                  (opened)="layerExpanded(i)"
                  (closed)="layerCollapsed(i)"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ layerNames[i] }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="inline-block">
                    <mat-form-field
                      subscriptSizing="dynamic"
                      >
                      <input
                        matInput
                        placeholder="Coverage/Layer Type"
                        formControlName="type"
                      />
                    </mat-form-field>
                    <p>-</p>
                    <mat-form-field
                      subscriptSizing="dynamic"
                    >
                      <input
                        matInput
                        placeholder="Coverage/Layer Name"
                        formControlName="name"
                      />
                    </mat-form-field>
                  </div>
                  <div class="inline-block">
                    <mat-form-field
                      subscriptSizing="dynamic"
                    >
                      <input
                        matInput
                        placeholder="Reinsurer Signed Percentage"
                        formControlName="signedPercentage"
                      />
                    </mat-form-field>
                    <p>% of {{ getCurrencySymbol(currencySymbols[i]) }}</p>
                    <mat-form-field
                      subscriptSizing="dynamic"
                    >
                      <input
                        matInput
                        placeholder="Occurrence Limit (if applicable)"
                        formControlName="occurrenceLimit"
                      />
                    </mat-form-field>
                    <p>
                      , that is
                      <span>
                        {{
                          weightedOccurrenceVals[i] !== 'N/A'
                            ? (weightedOccurrenceVals[i]
                              | currency
                                : currencySymbols[i]
                                : 'symbol-narrow'
                                : '4.2-2')
                            : 'N/A'
                        }}
                      </span>
                    </p>
                  </div>
                </mat-expansion-panel>
              </div>
            </ng-container>
          </ng-container>
        </section>
        <!-- Reinsurance Brokerage -->
        <section class="brokerage">
          <p>
            Reinsurance brokerage payable to the Intermediary shall be
            calculated as follows:
          </p>
          <ng-container formArrayName="brokerages">
            <ng-container
              *ngFor="let brokerage of brokerages.controls; let i = index"
            >
              <div class="form-section" [formGroup]="brokerage">
                <mat-expansion-panel
                  hideToggle
                  [expanded]="isLayerExpanded(i)"
                  (opened)="layerExpanded(i)"
                  (closed)="layerCollapsed(i)"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ layerNames[i] }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="inline-block">
                    <mat-form-field
                      subscriptSizing="dynamic"
                    >
                      <input
                        matInput
                        placeholder="Lockton Re Brokerage Percent"
                        formControlName="percent"
                      />
                    </mat-form-field>
                    <p>% of the</p>
                    <mat-form-field
                      subscriptSizing="dynamic"
                      class="select-option"
                    >
                      <mat-label>Lockton Re Brokerage Type</mat-label>
                      <mat-select formControlName="type">
                        <mat-option value="gross"> gross</mat-option>
                        <mat-option value="net"> net</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p>ceded reinsurance premium</p>
                  </div>
                  <div class="inline-block">
                    <p>and</p>
                    <mat-form-field
                      subscriptSizing="dynamic"
                    >
                      <input
                        matInput
                        placeholder="Brokerage Reinstatement Percent"
                        formControlName="reinstatementPercent"
                      />
                    </mat-form-field>
                    <p>% of any reinstatement premium</p>
                  </div>
                </mat-expansion-panel>
              </div>
            </ng-container>
          </ng-container>
        </section>
        <!-- Add/Minus Layer -->
        <section class="add-minus-layer">
          <div class="inline-block">
            <button appButton big border [matMenuTriggerFor]="menuAdd">
              + Layer
            </button>
            <button
              *ngIf="selectedReinsurer.fotsReinsured.length > 1"
              appButton
              big
              border
              [matMenuTriggerFor]="menuMinus"
            >
              - Layer
            </button>
            <mat-menu #menuAdd="matMenu">
              <button
                mat-menu-item
                matTooltip="{{ fot.structureName }}-{{ fot.layerName }}-{{
                  fot.reinsurerPhaseLabel
                }}"
                matTooltipClass="form-tooltip"
                matTooltipPosition="after"
                (click)="addFotLayer(fot)"
                *ngFor="let fot of fotsToAdd"
              >
                {{ fot.structureName }}-{{ fot.layerName }}-{{
                  fot.reinsurerPhaseLabel
                }}
              </button>
            </mat-menu>
            <mat-menu #menuMinus="matMenu">
              <button
                mat-menu-item
                matTooltip="{{ fot.structureName }}-{{ fot.layerName }}-{{
                  fot.reinsurerPhaseLabel
                }}"
                matTooltipClass="form-tooltip"
                matTooltipPosition="after"
                (click)="minusFotLayer(fot)"
                *ngFor="let fot of fotsToRemove"
              >
                {{ fot.structureName }}-{{ fot.layerName }}-{{
                  fot.reinsurerPhaseLabel
                }}
              </button>
            </mat-menu>
          </div>
        </section>
        <!-- Subjectivites -->
        <section class="subjectivities">
          <h4>Subjectivities</h4>
          <span class="subjectivities-note"
            >Note: List labels are ignored, export will be in correct
            format</span
          >
          <span class="subjectivities-warning" *ngIf="subjectivityWarning"
            >Warning: Max list nesting is 7 levels, export will have a max of 7
            levels</span
          >
          <ejs-richtexteditor
            id="rich-text-editor"
            #richtext
            formControlName="subjectivities"
            width="50vw"
            height="400px"
            [fontSize]="fontSize"
            [cssClass]="cssClass"
            [toolbarSettings]="tools"
            [numberFormatList]="numberFormatList"
            [pasteCleanupSettings]="pasteCleanupSettings"
          >
          </ejs-richtexteditor>
        </section>
        <!-- Risk Ref and Version -->
        <br />
        <section class="version">
          <div>
            <span
              (click)="onRiskRefSelectClick()"
              (click)="$event.stopPropagation()"
              >Select a Risk Reference Number
              <button
                appButtonIcon
                big
                class="material-icons"
                matTooltip="Edit Risk Ref"
                matTooltipClass="form-tooltip"
                matTooltipPosition="after"
              >
                <mat-icon *ngIf="renewalRiskRefs" inline>edit</mat-icon>
              </button>
            </span>
          </div>
          <br />
          <div>
            <mat-chip-row
              class="app-chip-palette app-palette-teal"
              matTooltip="{{ this.selectedRiskRef }}"
              matTooltipClass="form-tooltip"
              matTooltipPosition="after"
              removable="true"
              (removed)="removeRiskRef()"
              *ngIf="selectedRiskRef"
              >{{ this.selectedRiskRef }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
          </div>
          <mat-checkbox formControlName="isFinalVersion"
            >Final Version
          </mat-checkbox>
        </section>
      </form>
    </div>
  `,
  styles: [
    `
      :host {
        text-align: center;
        background-color: black;
        color: white;
      }

      :host > .disclaimer {
        background-color: black;
        color: white;
        padding: 8px 0;
      }

      :host > .form-wrapper {
        background: white !important;
        color: black !important;
      }
      .form-wrapper .mat-expansion-panel {
        background: white;
        color: black;
        margin-bottom: 5px;
        width: 50vw;
      }
      .form-wrapper .mat-expansion-panel-header-title {
        color: black;
        justify-content: center;
      }
      .form-wrapper .mat-divider {
        border-top-color: black;
      }
      .form-wrapper .mat-mdc-list-base .mat-mdc-list-item {
        color: black;
        display: flex;
        justify-content: center;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      :host ::ng-deep .form-wrapper .mat-mdc-checkbox .mat-checkbox-frame {
        border-color: black;
      }
      :host ::ng-deep .form-wrapper .mat-mdc-checkbox {
        font-weight: bold;
      }
      :host ::ng-deep .form-wrapper .mdc-line-ripple::before {
        /* change color of underline */
        background-color: black !important;
      }
      :host ::ng-deep .form-wrapper .mat-mdc-form-field-ripple {
        /* change color of underline when focused */
        background-color: black !important;
      }

      :host ::ng-deep .mdc-evolution-chip-set__chips {
        display: flex;
        flex-flow: wrap;
        justify-content: center !important;
        min-width: 100% !important;
      }

      .form-wrapper > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 35px 0;
      }

      .company-alias-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      form > section,
      .form-section {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .contract-name > mat-form-field {
          width: 650px;
          height: 59px;
          padding-top: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid black !important;
      }

      .contract-name > p {
        margin-top: 0;
      }
      .effective-date .inline-block > p {
        font-weight: bold;
        top: 12px !important;
        position: relative;
      }
      .effective-date > .inline-block > mat-form-field {
        height: 63px;
        padding-top: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid black !important;
      }

      .effective-label {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .cedents > mat-expansion-panel {
        width: 35vw !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
      :host ::ng-deep .cedents .mat-chip-list-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
      .cedents > p:last-child {
        display: flex;
        column-gap: 10px;
      }
      .reinsurer > p:first-child {
        margin-bottom: 0;
      }
      .reinsurer > p:last-child {
        margin-top: 0;
      }
      .layers span {
        font-weight: bold;
      }
      .layers,
      .brokerage,
      .add-minus-layer {
        padding: 20px 0;
      }
      .subjectivities {
        border: 1px solid black;
        border-radius: 5px;
        padding: 0 10px 15px 10px;
        margin-top: 20px;
        position: relative;

        ejs-richtexteditor{
          text-align: left;
        }

        h4 {
          margin-top: inherit;
          margin-bottom: unset;
        }

        .subjectivities-note {
          position: absolute;
          top: 5px;
          right: 5px;
          font-style: italic;
          font-size: 12px;
        }

        .subjectivities-warning {
          color: var(--warn);
          font-weight: bold;
        }
      }
      .subjectivities mat-form-field {
        width: 725px;
      }
      :host ::ng-deep .subjectivities .mdc-line-ripple::before {
        display: none;
      }
      :host ::ng-deep .subjectivities
        .mat-mdc-form-field.mat-focused
        .mdc-line-ripple::before {
        display: none;
      }
      :host ::ng-deep .subjectivities .mat-mdc-form-field-label {
        color: black;
      }
      :host ::ng-deep .subjectivities .mat-focused .mat-mdc-form-field-label {
        color: black !important;
      }
      .version > mat-checkbox {
        margin-top: 30px;
      }

      .inline-block {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
      :host ::ng-deep .inline-block .mat-datepicker-toggle {
        color: black;
      }

      :host ::ng-deep .select-option .mat-mdc-select-value,
      :host ::ng-deep .select-option .mat-mdc-form-field-label {
        color: black !important;
        font-weight: bold;
      }
      :host ::ng-deep .select-option .mat-mdc-select-arrow,
        .select-option
        .mat-mdc-form-field.mat-focused.mat-primary
        .mat-mdc-select-arrow {
        color: white !important;
      }

      mat-form-field {
        width: 250px;
        text-align: center;
        margin-bottom: 21.5px;
      }

      input::placeholder,
      textarea::placeholder {
        opacity: 1 !important;
        color: black !important;
        font-weight: bold;
      }

      input {
        font-weight: bold;
        text-align: center;
        color: black !important;
      }

      .pencil {
        max-height: 20px;
        max-width: 20px;
      }

      .e-toolbar-items {
        background: #fafafa;
      }

      .e-richtexteditor {
        .e-rte-toolbar {
          .e-toolbar-item {
            .e-icons {
              color: #333;
            }
          }
        }
        .e-content {
          text-align: left;
        }
      }
      .e-toolbar-wrapper {
        display: flex;
        justify-content: flex-end;
        .e-toolbar {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          background: #fafafa;
          border: 1px none rgba(0, 0, 0, 0.12);
          box-shadow: none;
          width: fit-content !important;

          .e-toolbar-item {
            &.e-overlay {
              background: #fafafa;
            }
          }

          .e-hor-nav {
            background: #fafafa;
            border: solid rgba(0, 0, 0, 0.12);
            border-width: 0 0 0 1px;
          }

          &.e-extended-toolbar {
            .e-toolbar-pop {
              background: #fafafa;
            }
          }
        }
      }

      .e-toolbar.e-extended-toolbar
        .e-toolbar-extended
        .e-toolbar-item
        .e-tbar-btn {
        background: #fafafa;
      }

      .e-toolbar .e-popup-up-icon.e-icons {
        color: rgba(0, 0, 0, 0.54);
      }

      .e-toolbar .e-popup-down-icon.e-icons,
      .e-toolbar .e-popup-up-icon.e-icons {
        color: rgba(0, 0, 0, 0.54);
        display: flex;
        text-align: center;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 12px;
      }

      #companyAliasInputId {
        padding-bottom: 4px;
      }

      #reinsurerInput {
        padding-bottom: 4px;
        font-weight: bold;
      }

      .ref-type-label {
        color: black;
        font-size: 12px;
        font-weight: bold;
      }

      p.by {
        margin-bottom: 24px !important;
      }

      mat-form-field {
        border-bottom: 1px solid black;
      }

      mat-form-field.company-alias {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      mat-expansion-panel {
        box-shadow:  0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          min-height: 22px;
      }
      :host ::ng-deep .form-tooltip {
        background-color: var(--bg-2) !important;
        color: #FFFFFF80 !important;
        border: 1px solid black;
        border-radius: 4px;
      }
      :host ::ng-deep .mat-datepicker-input {
        transform: translateY(100%)
      }
    `,
  ],
})
export class SignatureFormComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject()
  private linkElement: HTMLLinkElement;

  companyAlias: string
  selectedRiskRef: string | undefined
  filteredRiskRefs: Observable<RiskRefDetailGroup[]>
  tools: ToolbarSettingsModel = {
    items: ['NumberFormatList'],
    enable: true,
    enableFloating: true,
    type: ToolbarType.MultiRow,
  }
  fontSize: object = {
    default: '12pt',
  }
  cssClass = 'rich-text'
  pasteCleanupSettings: object = {
    prompt: false,
    plainText: false,
    keepFormat: false,
    deniedTags: ['a', 'h1', 'h2', 'h3'],
    deniedAttrs: ['class', 'level'],
    allowedStyleProps: ['color'],
  }
  numberFormatList: NumberFormatListModel = {
    types: [
      { text: 'None', value: 'none' },
      { text: 'Number', value: 'decimal' },
      { text: 'Upper Alpha', value: 'upperAlpha' },
      { text: 'Lower Alpha', value: 'lowerAlpha' },
    ],
  }
  subjectivityWarning = false

  @Input() contracts: SignatureContract[]
  @Input() selectedContract: SignatureContract
  @Input() reinsurers: SignatureReinsurer[]
  @Input() selectedReinsurer: SignatureReinsurer // Contains cedents information
  @Input() fotsToAdd: FotState[]
  @Input() fotsToRemove: FotState[]
  @Input() renewalRiskRefs: RiskRefDetail[]
  @Input() clients: SalesforceClient[]

  @Output() updateSignatureForm = new EventEmitter<{
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceType?: SignatureReinsurerReferenceType
  }>()
  @Output() addLayerToSelectedContract = new EventEmitter<FotState>()
  @Output() removeLayerFromSelectedContract = new EventEmitter<FotState>()

  @ViewChild('richtext', { static: false }) richtext: RichTextEditor

  signatureForm: FormGroup

  get cedents(): Cedent[] {
    const cedents = JSON.parse(JSON.stringify(this.signatureForm?.get('cedents')?.value)) as Cedent[]
    if (cedents && cedents.length > 0){
      cedents.sort((a,b) => a.cedentName.localeCompare(b.cedentName))
    }
    return cedents ?? []
  }

  get layers() {
    const layers = 'layers'
    return this.signatureForm?.controls[layers] as FormArray
  }
  get brokerages() {
    const brokerages = 'brokerages'
    return this.signatureForm?.controls[brokerages] as FormArray
  }

  get reinsurerTextWidth(): number {
    return (
      (this.signatureForm?.get('reinsurer.name')?.value as string).length + 1
    )
  }

  get currencySymbols(): string[] {
    const symbols: string[] = []
    for (const i in this.selectedReinsurer.fotsReinsured) {
      if (this.selectedReinsurer.fotsReinsured[i]) {
        symbols.push(this.selectedReinsurer.fotsReinsured[i].layerCurrency)
      }
    }
    return symbols
  }

  get weightedOccurrenceVals(): (number | 'N/A')[] {
    const vals: (number | 'N/A')[] = []
    for (const i in this.selectedReinsurer.fotsReinsured) {
      if (this.selectedReinsurer.fotsReinsured[i]) {
        const weightedVal =
          this.selectedReinsurer.fotsReinsured[i].occurrence >= UNLIMITED_VAL
            ? 'N/A'
            : this.selectedReinsurer.fotsReinsured[i].occurrence *
              this.selectedReinsurer.fotsReinsured[i].percentage
        vals.push(weightedVal)
      }
    }
    return vals
  }

  get layerNames(): string[] {
    const layerNames: string[] = []
    for (const i in this.selectedReinsurer.fotsReinsured) {
      if (this.selectedReinsurer.fotsReinsured[i]) {
        layerNames.push(this.selectedReinsurer.fotsReinsured[i].layerName ?? '')
      }
    }
    return layerNames
  }

  onCedentsClick() {
    const dialogRef = this.dialog.open(SignatureSelectClientsDialogComponent, {
      id: DIALOG_ID,
      width: '55vw',
      minWidth: '55vw',
      data: {
        clients: this.clients,
        selected: this.selectedReinsurer.cedents,
      },
      panelClass: [DIALOG_ID, 'app-dialog-with-sidebar'],
      backdropClass: 'backdropBackground',
    })

    dialogRef.afterClosed().subscribe((selectedClients: SalesforceClient[]) => {
      if (!selectedClients) {
        return
      }
      const mappedCedents: Cedent[] = selectedClients.map(client => {
        let clientLocationString = ''
        if (client.billingCountry === 'United States') {
          // For the United States, location format should be City, State
          const clientStateString = unabbreviateState(
            client.billingState.substring(2, client.billingState.length)
          )
          clientLocationString = `${client.billingCity}, ${clientStateString}`
        } else {
          // For all other countries, format should be City, Country.
          clientLocationString = `${client.billingCity}, ${client.billingCountry}`
        }

        return {
          id: this.selectedReinsurer.cedents.find(c => c.tpRef === client.tpRef)
            ?.id, // find database id for any clients that already existed with this contract, else null
          cedentName: client.acctName,
          cedentLocation: clientLocationString,
          tpRef: client.tpRef,
        }
      })
      this.signatureForm.patchValue({
        cedents: mappedCedents,
      }) /* need valueChanges emitted so state is updated as well */

      this.resetCompanyAlias()
    })
  }

  onRiskRefSelectClick() {
    const dialogRef = this.dialog.open(SignatureSelectRiskRefDialogComponent, {
      id: RISKREF_DIALOG_ID,
      width: '55vw',
      minWidth: '55vw',
      data: {
        renewalRiskRefs: this.renewalRiskRefs,
      },
      backdropClass: 'backdropBackground',
    })

    dialogRef.afterClosed().subscribe((selectedRiskRef: string) => {
      if (selectedRiskRef) {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            riskRef: selectedRiskRef,
          },
          applyToEntireFot: {
            riskRef: true,
          },
        })
      }
    })
  }

  addFotLayer(fot: FotState) {
    this.addLayerToSelectedContract.emit(fot)
  }
  minusFotLayer(fot: FotState) {
    this.removeLayerFromSelectedContract.emit(fot)
  }
  expandedLayerIndexes: number[] = []
  isLayerExpanded(layerIndex: number) {
    return this.expandedLayerIndexes.find(
      expandedIndex => expandedIndex === layerIndex
    )
  }
  layerExpanded(layerIndex: number) {
    const layerAlreadyExpanded = this.expandedLayerIndexes.find(
      expandedIndex => expandedIndex === layerIndex
    )
    if (!layerAlreadyExpanded && typeof layerAlreadyExpanded !== 'number') {
      this.expandedLayerIndexes.push(layerIndex)
    }
  }
  layerCollapsed(layerIndex: number) {
    const indexToRemove = this.expandedLayerIndexes.findIndex(
      expandedIndex => expandedIndex === layerIndex
    )
    if (indexToRemove < 0) {
      return
    }
    this.expandedLayerIndexes.splice(indexToRemove, 1)
  }

  removeRiskRef() {
    this.updateSignatureForm.emit({
      reinsurer: {
        ...this.selectedReinsurer,
        riskRef: undefined,
      },
      applyToEntireFot: {
        riskRef: true,
      },
    })
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.initSyncfusionLightmode()
  }

  initSyncfusionLightmode(){
    this.linkElement = this.renderer.createElement('link');
    this.linkElement.rel = 'stylesheet';
    this.linkElement.href = 'https://cdn.syncfusion.com/ej2/26.2.4/material.css';
    this.renderer.appendChild(document.head, this.linkElement);
  }

  ngOnInit() {
    const fotFormGroups = this.selectedReinsurer.fotsReinsured.map(fot => {
      const occurrenceString =
        fot.occurrence >= UNLIMITED_VAL
          ? 'Unlimited'
          : fot.occurrence?.toLocaleString()
      return {
        layer: new FormGroup({
          name: new FormControl(fot.layerName),
          occurrenceLimit: new FormControl(occurrenceString),
          type: new FormControl(fot.layerType),
          signedPercentage: new FormControl(
            this.floatValue(fot.percentage, 'signedPercentage')
          ),
        }),
        brokerage: new FormGroup({
          type: new FormControl(fot.brokerageType),
          payable: new FormControl(fot.reinsuranceBrokeragePayable),
          percent: new FormControl(this.floatValue(fot.brokeragePercent, '')),
          reinstatementPercent: new FormControl(
            this.floatValue(fot.reinstatementPercent, '')
          ),
        }),
      }
    })

    this.signatureForm = this.formBuilder.group(
      {
        contractName: new FormControl(this.selectedReinsurer.contractName),
        contractEffectiveDate: new FormControl(
          this.selectedReinsurer.effectiveDate
        ),
        cedents: new FormControl(this.selectedReinsurer.cedents),
        reinsurer: new FormGroup({
          name: new FormControl(this.selectedReinsurer.reinsurerName),
          referenceType: new FormControl(this.selectedReinsurer.referenceType),
          referenceNumber: new FormControl(
            this.selectedReinsurer.referenceType === 'AIIN'
              ? this.selectedReinsurer.aiinReferenceNumber
              : this.selectedReinsurer.referenceType === 'FEIN'
              ? this.selectedReinsurer.feinReferenceNumber
              : this.selectedReinsurer.naicReferenceNumber
          ),
        }),
        layers: new FormArray(
          fotFormGroups.map(fotFormGroup => fotFormGroup.layer)
        ),
        brokerages: new FormArray(
          fotFormGroups.map(fotFormGroup => fotFormGroup.brokerage)
        ),
        subjectivities: new FormControl(
          this.transformTextWithLineBreaksToHtml(this.selectedReinsurer.fotsReinsured[0].subjectivities),
          { updateOn: 'change' }
        ),
        riskRef: new FormControl(this.selectedReinsurer.riskRef),
        isFinalVersion: new FormControl(this.selectedReinsurer.isFinalVersion),
        companyAlias: new FormControl(this.selectedReinsurer.companyAlias),
      },
      { updateOn: 'blur' }
    )

    this.selectedRiskRef = this.selectedReinsurer.riskRef

    this.resetCompanyAlias()
    this.getMaxListDepth(this.selectedReinsurer.fotsReinsured[0].subjectivities)

    /* Update state on form changes */
    this.subscribeToValueChangesForm()
    /* Update state for layers and brokerages on value changes */
    this.subscribeToValueChangesLayers()
    this.subscribeToValueChangesBrokerages()
  }

  resetCompanyAlias() {
    if (
      !this.companyAlias ||
      this.companyAlias === COLLECTIVELY_TEXT ||
      this.companyAlias === HEREINAFTER_TEXT
    ) {
      if (this.cedents.length > 1) {
        this.companyAlias = COLLECTIVELY_TEXT
      } else {
        this.companyAlias = HEREINAFTER_TEXT
      }
    }
    const companyAliasInput = document.getElementById('companyAliasInputId')
    companyAliasInput?.setAttribute('value', this.companyAlias)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.selectedReinsurer || changes.selectedReinsurer.firstChange) {
      return
    }

    const updatedSelectedReinsurer: SignatureReinsurer =
      changes.selectedReinsurer.currentValue
    /* Update individual fields of form */
    this.signatureForm.patchValue(
      {
        contractName: updatedSelectedReinsurer.contractName,
        contractEffectiveDate: updatedSelectedReinsurer.effectiveDate,
        cedents: updatedSelectedReinsurer.cedents,
        reinsurer: {
          name: updatedSelectedReinsurer.reinsurerName,
          referenceType: updatedSelectedReinsurer.referenceType,
          referenceNumber:
            updatedSelectedReinsurer.referenceType === 'AIIN'
              ? updatedSelectedReinsurer.aiinReferenceNumber
              : updatedSelectedReinsurer.referenceType === 'FEIN'
              ? updatedSelectedReinsurer.feinReferenceNumber
              : updatedSelectedReinsurer.naicReferenceNumber,
        },
        subjectivities:
          this.transformTextWithLineBreaksToHtml(updatedSelectedReinsurer.fotsReinsured[0].subjectivities),
        riskRef: updatedSelectedReinsurer.riskRef,
        isFinalVersion: updatedSelectedReinsurer.isFinalVersion,
        companyAlias: updatedSelectedReinsurer.companyAlias,
      },
      { emitEvent: false }
    )

    /* update riskref field */
    this.selectedRiskRef = updatedSelectedReinsurer.riskRef

    /* Update layers and brokerages */
    const layerKey = 'layers'
    const layers = this.signatureForm.controls[layerKey] as FormArray
    const brokerageKey = 'brokerages'
    const brokerages = this.signatureForm.controls[brokerageKey] as FormArray
    if (layers.length > updatedSelectedReinsurer.fotsReinsured.length) {
      layers.removeAt(layers.length - 1)
    }
    if (brokerages.length > updatedSelectedReinsurer.fotsReinsured.length) {
      brokerages.removeAt(brokerages.length - 1)
    }
    for (const i in updatedSelectedReinsurer.fotsReinsured) {
      if (!updatedSelectedReinsurer.fotsReinsured[i]) {
        continue
      }
      const changedFot = updatedSelectedReinsurer.fotsReinsured[i]
      if (layers.at(+i)) {
        const occurrenceString =
          changedFot.occurrence >= UNLIMITED_VAL
            ? 'Unlimited'
            : changedFot.occurrence.toLocaleString()
        layers.at(+i).patchValue(
          {
            name: changedFot.layerName,
            occurrenceLimit: occurrenceString,
            type: changedFot.layerType,
            signedPercentage: this.floatValue(
              changedFot.percentage,
              'signedPercentage'
            ),
          },
          { emitEvent: false }
        )
        brokerages.at(+i).patchValue(
          {
            type: changedFot.brokerageType,
            payable: changedFot.reinsuranceBrokeragePayable,
            percent: this.floatValue(changedFot.brokeragePercent, ''),
            reinstatementPercent: this.floatValue(
              changedFot.reinstatementPercent,
              ''
            ),
          },
          { emitEvent: false }
        )
      } else {
        const occurrenceString =
          changedFot.occurrence >= UNLIMITED_VAL
            ? 'Unlimited'
            : changedFot.occurrence.toLocaleString()
        layers.push(
          new FormGroup({
            name: new FormControl(changedFot.layerName),
            occurrenceLimit: new FormControl(occurrenceString),
            type: new FormControl(changedFot.layerType),
            signedPercentage: new FormControl(
              this.floatValue(changedFot.percentage, 'signedPercentage')
            ),
          })
        )
        brokerages.push(
          new FormGroup({
            type: new FormControl(changedFot.brokerageType),
            payable: new FormControl(changedFot.reinsuranceBrokeragePayable),
            percent: new FormControl(
              this.floatValue(changedFot.brokeragePercent, '')
            ),
            reinstatementPercent: new FormControl(
              this.floatValue(changedFot.reinstatementPercent, '')
            ),
          })
        )
        /* If FOT was added, need to subscribe to valueChanges of that layer/brokerage */
        this.subscribeToValueChangesLayer(i)
        this.subscribeToValueChangesBrokerage(i)
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()

    if (this.linkElement) {
      this.renderer.removeChild(document.head, this.linkElement);
    }
  }

  private transformTextWithLineBreaksToHtml(text: string): string {
    if (!text) {
      return ''
    }
    if (text.includes('</p>')){
      return text
    }
    return text
      .split('\n')
      .map(line => `<p>${line}</p>`)
      .join('')
  }

  private subscribeToValueChangesForm() {
    /* Contract Name */
    this.signatureForm
      .get('contractName')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            contractName: newVal,
          },
          applyToEntireFot: {
            contractName: true,
          },
        })
      })
    /* Effective Date */
    this.signatureForm
      .get('contractEffectiveDate')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        // Convert the offset to milliseconds (getTimezoneOffset() returns result in minutes)
        const timezoneOffsetInMilliseconds =
          new Date(newVal).getTimezoneOffset() * 60000
        let effectiveDateWithoutTimeZone = new Date(
          newVal.valueOf() - timezoneOffsetInMilliseconds
        ).toISOString()
        effectiveDateWithoutTimeZone = effectiveDateWithoutTimeZone.replace(
          '.000Z',
          ''
        )
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            effectiveDate: effectiveDateWithoutTimeZone,
          },
          applyToEntireFot: {
            effectiveDate: true,
          },
        })
      })
    /* Cedents */
    this.signatureForm
      .get('cedents')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            cedents: newVal,
          },
          applyToEntireFot: {
            cedents: true,
          },
        })
      })
    /* Reinsurer */
    this.signatureForm
      .get('reinsurer.name')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            reinsurerName: newVal,
          },
        })
      })
    this.signatureForm
      .get('reinsurer.referenceType')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            referenceType: newVal,
          },
          updatedReferenceType: newVal as SignatureReinsurerReferenceType,
        })
      })
    this.signatureForm
      .get('reinsurer.referenceNumber')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        if (
          this.signatureForm.get('reinsurer.referenceType')?.value === 'AIIN'
        ) {
          this.updateSignatureForm.emit({
            reinsurer: {
              ...this.selectedReinsurer,
              aiinReferenceNumber: newVal,
            },
          })
        }
        if (
          this.signatureForm.get('reinsurer.referenceType')?.value === 'FEIN'
        ) {
          this.updateSignatureForm.emit({
            reinsurer: {
              ...this.selectedReinsurer,
              feinReferenceNumber: newVal,
            },
          })
        }
        if (
          this.signatureForm.get('reinsurer.referenceType')?.value === 'NAIC'
        ) {
          this.updateSignatureForm.emit({
            reinsurer: {
              ...this.selectedReinsurer,
              naicReferenceNumber: newVal,
            },
          })
        }
      })
    /* Subjectivities */
    this.signatureForm
      .get('subjectivities')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.getMaxListDepth(newVal)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: this.selectedReinsurer.fotsReinsured.map(fot => {
              return {
                ...fot,
                subjectivities: newVal,
              }
            }),
          },
        })
      })

    /* Version */
    this.signatureForm
      .get('isFinalVersion')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            isFinalVersion: newVal,
          },
        })
      })
    /* Company Alias */
    this.signatureForm
      .get('companyAlias')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            companyAlias: newVal,
          },
          applyToEntireFot: {
            companyAlias: true,
          },
        })
      })
  }

  private getMaxListDepth(subjectivities: string) {
    const parser = new DOMParser()
    const parsedHtml = parser.parseFromString(subjectivities, 'text/html').body
    const lists = Array.from(parsedHtml.childNodes).filter(
      node => node.nodeName === 'OL'
    )
    let maxDepth = 0
    lists.forEach(list => {
      maxDepth = Math.max(maxDepth, this.getNodeChildren(list))
    })
    this.subjectivityWarning = maxDepth > 7
  }

  private getNodeChildren(node: ChildNode, val = 0): number {
    const eligibleChildren = Array.from(node.childNodes).filter(
      childNode => childNode.nodeName === 'OL' || childNode.nodeName === 'LI'
    )
    let maxDepth = val
    while (eligibleChildren.length) {
      const childNode = eligibleChildren.splice(0, 1)[0]
      const nextVal = childNode.nodeName === 'LI' ? val + 1 : val
      const childDepth = this.getNodeChildren(childNode, nextVal)
      maxDepth = Math.max(childDepth, maxDepth)
    }
    return maxDepth
  }

  private subscribeToValueChangesLayers() {
    for (const i in this.selectedReinsurer.fotsReinsured) {
      if (this.selectedReinsurer.fotsReinsured[i]) {
        this.subscribeToValueChangesLayer(i)
      }
    }
  }
  private subscribeToValueChangesLayer(i: string) {
    const layerKey = 'layers'
    const layers = this.signatureForm.controls[layerKey] as FormArray

    /* Layer Name */
    layers
      .at(+i)
      .get('name')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          layerName: newVal,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
          applyToEntireFot: {
            layerName: {
              index: +i,
            },
          },
        })
      })
    /* Occurrence */
    layers
      .at(+i)
      .get('occurrenceLimit')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const transformedToNumber =
          parseFloat(newVal.replace(/,/g, '')) >= UNLIMITED_VAL
            ? UNLIMITED_VAL
            : parseFloat(newVal.replace(/,/g, ''))
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          occurrence: transformedToNumber,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
    /* Layer Type */
    layers
      .at(+i)
      .get('type')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          layerType: newVal,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
          applyToEntireFot: {
            layerType: {
              index: +i,
            },
          },
        })
      })
    /* Signed % */
    layers
      .at(+i)
      .get('signedPercentage')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const transformedToPercent = newVal / 100.0
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          percentage: transformedToPercent,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
  }

  private subscribeToValueChangesBrokerages() {
    for (const i in this.selectedReinsurer.fotsReinsured) {
      if (this.selectedReinsurer.fotsReinsured[i]) {
        this.subscribeToValueChangesBrokerage(i)
      }
    }
  }
  private subscribeToValueChangesBrokerage(i: string) {
    const brokerageKey = 'brokerages'
    const brokerages = this.signatureForm.controls[brokerageKey] as FormArray

    /* Brokerage Type */
    brokerages
      .at(+i)
      .get('type')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          brokerageType: newVal,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
    /* Brokerage Payable */
    brokerages
      .at(+i)
      .get('payable')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          reinsuranceBrokeragePayable: newVal,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
    /* Brokerage Percent */
    brokerages
      .at(+i)
      .get('percent')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const transformedToPercent = newVal / 100.0
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          brokeragePercent: transformedToPercent,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
    /* Brokerage Reinstatement Percent */
    brokerages
      .at(+i)
      .get('reinstatementPercent')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newVal => {
        const transformedToPercent = newVal / 100.0
        const updatedFot = {
          ...this.selectedReinsurer.fotsReinsured[+i],
          reinstatementPercent: transformedToPercent,
        }
        const updatedFotsReinsurerd = clone(
          this.selectedReinsurer.fotsReinsured
        )
        updatedFotsReinsurerd.splice(+i, 1, updatedFot)
        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            fotsReinsured: [...updatedFotsReinsurerd],
          },
        })
      })
  }
  /* Method used to handle the decimal places */
  floatValue(val: any, columnName: string) {
    if (val === 0) {
      return (0).toFixed(2)
    }
    if (val && columnName === 'signedPercentage') {
      const conv: number = val * 100.0
      const convertedValue = parseFloat(conv.toString())
      const result = Number(convertedValue.toPrecision(15)) * 1.0
      const count = this.countDecimals(result)
      if (count === 0) {
        return conv.toFixed(2)
      }
      return count > 0 && count <= 8 ? conv.toFixed(count) : conv.toFixed(8)
    } else {
      const conv: number = Number((val * 100.0).toFixed(4))
      const charArr = conv.toString().split('.')
      if (charArr && charArr.length === 2) {
        const charFirst = charArr[1]
        if (charFirst.length >= 4) {
          if (
            charFirst.charAt(0) === '0' &&
            charFirst.charAt(1) === '0' &&
            charFirst.charAt(2) === '0' &&
            charFirst.charAt(3) === '0'
          ) {
            return conv.toFixed(2)
          } else if (
            charFirst.charAt(0) !== '0' &&
            charFirst.charAt(1) !== '0' &&
            charFirst.charAt(2) === '0' &&
            charFirst.charAt(3) === '0'
          ) {
            return conv.toFixed(2)
          } else {
            return conv.toFixed(4)
          }
        } else if (charFirst.length === 3) {
          if (
            charFirst.charAt(0) === '0' &&
            charFirst.charAt(1) === '0' &&
            charFirst.charAt(2) === '0'
          ) {
            return conv.toFixed(2)
          } else {
            return conv
          }
        } else if (charFirst.length === 2) {
          if (charFirst.charAt(0) === '0' && charFirst.charAt(1) === '0') {
            return conv.toFixed(2)
          } else {
            return conv
          }
        } else {
          return conv.toFixed(2)
        }
      } else {
        return conv.toFixed(2)
      }
    }
  }

  countDecimals(value: number): number {
    if (Math.floor(value) === value) {
      return 0
    }
    return value.toString().split('.')[1].length || 0
  }

  getCurrencySymbol(currency: string) {
    return getCurrencySymbol(currency, 'narrow')
  }
}
