export const slugify = (text: string): string =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

export const slugifyUnderscore = (text: string) =>
  slugify(text).replace(/-/g, '_')

const nextLetter = (letter: string, increment = 1): string =>
  String.fromCharCode(letter.charCodeAt(0) + increment)

/*! https://stackoverflow.com/questions/2256607/how-to-get-the-next-letter-of-the-alphabet-in-javascript */
export const getNextLetter = (letter: string): string => {
  if (letter === 'Z' || letter === 'z') {
    return nextLetter(letter, -25) + nextLetter(letter, -25) // AA or aa
  } else {
    const lastChar = letter.slice(-1)
    const sub = letter.slice(0, -1)
    if (lastChar === 'Z' || lastChar === 'z') {
      // If a string of length > 1 ends in Z/z,
      // increment the string (excluding the last Z/z) recursively,
      // and append A/a (depending on casing) to it
      return getNextLetter(sub) + nextLetter(lastChar, -25)
    } else {
      // (take till last char) append with (increment last char)
      return sub + nextLetter(lastChar)
    }
  }
}

export function isNumber(str?: string | number): boolean {
  if (str != null) {
    return !isNaN(str as any) && !isNaN(parseFloat(str as any))
  }
  return false
}

export interface TruncateStringOptions {
  cutoff: number
  length: number
  cutoffString: string
}

export const DEFAULT_TRUNCATE_STRING_OPTIONS: TruncateStringOptions = {
  cutoff: 16,
  length: 24,
  cutoffString: '...',
}

export function truncateString(
  title: string,
  options: Partial<TruncateStringOptions> = {}
): string {
  const o = { ...DEFAULT_TRUNCATE_STRING_OPTIONS, ...options }
  return title.length > o.cutoff
    ? `${title.substring(0, o.length)}${o.cutoffString}`
    : title
}

export function containsObo(name: string | null): boolean {
  if (!name) {
    return false
  }
  if (name.includes('obo') || name.includes('o.b.o')) {
    return true
  }
  return false
}
