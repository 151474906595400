import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AppState } from '../../../../core/store'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import {
  ScenarioEvent,
  ScenarioEventChange,
  AddScenarioEventPayload,
} from '../../animated-scenarios.model'

import * as fromGroupScenarioEventActions from '../../store/group/group-scenario-event.actions'
import {
  selectGroupExecuting,
  selectAllGroupScenarioEvents,
  selectGroupLossSets,
} from '../../store/animated-scenarios.selectors'
import { map } from 'rxjs/operators'
import { getLossSetNames } from '../../util/tower.3d.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-scenarios-event-dialog',
  styleUrls: ['./group-event-table-dialog.container.scss'],
  templateUrl: './group-event-table-dialog.container.html',
})
export class GroupScenarioEventTableDialogContainerComponent implements OnInit {
  executing$: Observable<boolean>
  scenarioEvents$: Observable<ScenarioEvent[]>
  lossSetNames$: Observable<string[]>

  constructor(
    public dialogRef: MatDialogRef<GroupScenarioEventTableDialogContainerComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.executing$ = this.store.pipe(select(selectGroupExecuting))
    this.scenarioEvents$ = this.store.pipe(select(selectAllGroupScenarioEvents))
    this.lossSetNames$ = this.store.pipe(
      select(selectGroupLossSets),
      map(l => getLossSetNames(l))
    )
  }

  onAddScenarioEvent(lossSetNames: string[]): void {
    const newEvent = lossSetNames.reduce((acc, next) => {
      acc[next] = 0
      return acc
    }, {} as AddScenarioEventPayload)
    this.store.dispatch(
      fromGroupScenarioEventActions.addGroupScenarioEvent({ event: newEvent })
    )
  }

  onScenarioEventDelete(eventID: number): void {
    this.store.dispatch(
      fromGroupScenarioEventActions.removeGroupScenarioEvent({ id: eventID })
    )
  }

  onScenarioEventChange(event: ScenarioEventChange): void {
    this.store.dispatch(
      fromGroupScenarioEventActions.updateGroupScenarioEvent({
        id: event.id,
        changes: event.changes,
      })
    )
  }
}
