import { Action, createReducer, on } from '@ngrx/store'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import { startAnalysis } from '../analysis.actions'
import {
  fetchPortfolioViewMetrics,
  fetchPortfolioViewMetricsFailure,
  fetchPortfolioViewMetricsSuccess,
  updateAndFetch,
  updateRSS,
  setExpectedSD,
} from './portfolio-metrics.actions'

export interface State {
  loading: boolean
  error: string | null
  metrics: PortfolioMetrics | null
  rss: number | null
}

export const initialState: State = {
  loading: false,
  error: null,
  metrics: null,
  rss: null,
}

const portfolioViewMetricsReducer = createReducer(
  initialState,
  on(startAnalysis, state => {
    if (state) {
      return state
    } else {
      return initialState
    }
  }),
  on(fetchPortfolioViewMetrics, updateAndFetch, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchPortfolioViewMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(fetchPortfolioViewMetricsSuccess, (state, { metrics }) => {
    const arrRPD = [...metrics.returnPeriodData].map(row => {
      if (row.term === 'VaR' && state.metrics) {
        return {
          ...row,
          value: state.metrics.returnPeriodData[0].value,
        }
      } else if (row.term === 'tVaR' && state.metrics) {
        return {
          ...row,
          value: state.metrics.returnPeriodData[1].value,
        }
      } else {
        return row
      }
    })
    // Remove loss filter if user changes perspective to UW
    if (metrics.perspective === 'UW') {
      return {
        ...state,
        metrics: { ...metrics, lossFilter: 'all', returnPeriodData: arrRPD },
      }
    }
    return {
      ...state,
      metrics: { ...metrics, returnPeriodData: arrRPD },
    }
  }),

  on(setExpectedSD, (state, { expected, stdDev }) => {
    // tslint:disable-next-line:no-non-null-assertion
    const arrRPD = [...state.metrics!.returnPeriodData].map(row => {
      if (row.term === 'VaR') {
        return {
          ...row,
          value: expected,
        }
      } else if (row.term === 'tVaR') {
        return {
          ...row,
          value: stdDev,
        }
      } else {
        return row
      }
    })
    // tslint:disable-next-line:no-non-null-assertion
    const metrics = { ...state.metrics! }
    return {
      ...state,
      metrics: { ...metrics, returnPeriodData: arrRPD },
      loading: false,
      error: null,
    }
  }),

  on(updateRSS, (state, { rss }) => ({
    ...state,
    rss,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return portfolioViewMetricsReducer(state, action)
}
