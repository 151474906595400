import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  InuranceSourceTargetView,
  InuranceView,
  Terminal,
} from '../../../model/inurance.model'
import {
  selectGrouperInuranceCursor,
  selectGrouperInuranceDeleting,
  selectGrouperInuranceError,
  selectGrouperInuranceMode,
  selectGrouperInuranceSaving,
  selectGrouperInuranceSource,
  selectGrouperInuranceTarget,
  selectGrouperIsDirty,
  selectNextGrouperInuranceSymbol,
} from '../../../store/analysis.selectors'
import {
  cancelGrouperInurance,
  deleteGrouperInurance,
  deleteGrouperInuranceFailure,
  openNewGrouperInurance,
  saveGrouperInurance,
  setGrouperInuranceCursor,
} from '../../../store/grouper/inurance/grouper-inurance.actions'
import { GrouperInuranceMode } from '../../../store/grouper/inurance/grouper-inurance.reducer'
import { AppState } from '../../../../core/store'
import { errorPayload } from '../../../../error/model/error'
import { TierService } from '../../../../tier/tier.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-inurance-bar-container',
  templateUrl: './group-inurance-bar-container.html',
})
export class GroupInuranceBarContainerComponent implements OnInit {
  dirty$: Observable<boolean>
  mode$: Observable<GrouperInuranceMode>
  nextSymbol$: Observable<string>
  source$: Observable<InuranceView | null>
  target$: Observable<InuranceView | null>
  cursor$: Observable<Terminal | null>
  error$: Observable<string | null>
  activeAction$: Observable<string | null>

  constructor(private store: Store<AppState>, private ts: TierService) {}

  ngOnInit(): void {
    this.dirty$ = this.store.select(selectGrouperIsDirty)
    this.mode$ = this.store.select(selectGrouperInuranceMode)
    this.nextSymbol$ = this.store.select(selectNextGrouperInuranceSymbol)
    this.source$ = this.store.select(selectGrouperInuranceSource)
    this.target$ = this.store.select(selectGrouperInuranceTarget)
    this.cursor$ = this.store.select(selectGrouperInuranceCursor)
    this.error$ = this.store.select(selectGrouperInuranceError)

    this.activeAction$ = combineLatest([
      this.store.select(selectGrouperInuranceSaving),
      this.store.select(selectGrouperInuranceDeleting),
    ]).pipe(
      map(([saving, deleting]) =>
        saving ? 'Adding...' : deleting ? 'Deleting...' : null
      )
    )
  }

  onOpenNewClick(): void {
    this.store.dispatch(openNewGrouperInurance())
  }

  onCancelClick(): void {
    this.ts.setDelayDialogPopup(true)
    this.store.dispatch(cancelGrouperInurance())
  }

  onTerminalClick($event: Terminal): void {
    this.store.dispatch(setGrouperInuranceCursor({ cursor: $event }))
  }

  onAddClick(): void {
    this.store.dispatch(saveGrouperInurance())
  }

  onDeleteClick({ source, target }: InuranceSourceTargetView): void {
    if (source && target && source.payload) {
      this.ts.setDelayDialogPopup(true)
      return this.store.dispatch(deleteGrouperInurance(source.payload))
    }

    let err: string | undefined
    if (!source) {
      err = `Unexpected Error: Source Inurance is empty.`
    }
    if (!target) {
      err = `Unexpected Error: Target Inurance is empty.`
    }
    if (source && !source.payload) {
      err = `Unexpected Error: Source Inurance has no payload.`
    }
    if (err) {
      const error = errorPayload(err)
      this.store.dispatch(deleteGrouperInuranceFailure({ error }))
    }
  }
}
