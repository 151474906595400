import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { PowerBIEmbedModule } from 'powerbi-client-angular'
import { RevenueContainerComponent } from './revenue.container'
import { RevenueComponent } from './revenue/revenue.component'
import { revenueRoutingModule } from './revenue-routing.module'
import { RevenueHeaderComponent } from './revenue-header/revenue-header.component'
import { HttpClientModule } from '@angular/common/http'
import { PowerbiService } from '../shared/power-bi/powerbi.service'


@NgModule({
  declarations: [
    RevenueContainerComponent,
    RevenueComponent,
    RevenueHeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TierModule,
    PowerBIEmbedModule,
    revenueRoutingModule,
    HttpClientModule,
  ],
  providers: [PowerbiService],
})
export class RevenueModule {}
