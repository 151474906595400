<ng-container>
    <div mat-dialog-content class="mat-dialog-wrapper">
      <div class="title">
        <h2>Contact Support</h2>
      </div>
      <div class="subtitle">
        <h4>Need Help, report an issue or Suggestion</h4>
      </div>
      <div>
        <section class="section-padding">
          <span class="dialog-label"> Select Report Type: </span>
          <mat-radio-group [(ngModel)]="reportTypeValue" >
            <mat-radio-button
              [value]="'Issue'"
              class="radio-option"
            >
              Issue
            </mat-radio-button>
            <mat-radio-button [value]="'Suggestion'" class="radio-btn">
              Suggestion
            </mat-radio-button>
          </mat-radio-group>
        </section>
        <section class="section-padding">
          <span class="dialog-label">Urgent: </span>
          <mat-radio-group [(ngModel)]="isUrgent" (change)="onUrgentSelectionChange($event)">
            <mat-radio-button
              [value]="'Yes'"
              class="radio-option"
            >
              Yes
            </mat-radio-button>
            <mat-radio-button [value]="'No'" class="radio-btn">
              No
            </mat-radio-button>
          </mat-radio-group>
        </section>
        <section class="section-flex">
          <span class="dialog-flex-label">Addtional Recipients: </span>
          <div class="emailAutoComplete">
            <app-multiselect-autocomplete
              [form]="supportRecipientForm"
              [filter]="this.data.userEmailListSelector"
              [name]="'Enter Additional Recipients'"
              [sectionEnd]="false"
              [hideClearFilter]="true"
            ></app-multiselect-autocomplete>
          </div> 
        </section>

        <section class="section-flex">
          <span class="dialog-flex-label">Email Subject: </span>
          <mat-form-field [ngClass]="{'error-border': emailSubjectInputControl.invalid && emailSubjectInputControl.touched}" subscriptSizing="dynamic">
            <input
              placeholder="Enter email subject"
              matInput
              [formControl]="emailSubjectInputControl"
              required
              type="email"
            />
          </mat-form-field>
        </section>

        <section class="section-flex">
            <span class="dialog-flex-label">Description: </span>
            <mat-form-field class="no-border" [ngClass]="{'error-outline': descriptionTextAreaControl.invalid && descriptionTextAreaControl.touched}" appearance="outline">
              <textarea 
                class ="issueDescription"
                matInput
                placeholder="Enter Issue or Suggestion Description. Also, please enter other relevant information like the company, program, year, and any error messages, if applicable."
                [formControl]="descriptionTextAreaControl"
                required
              >
              </textarea>
            </mat-form-field>
        </section>

        <span class="dialog-flex-label">Add Attachments: </span>
            <input
              required
              type="file"
              id="file"
              (change)="handleFileInput($event.target.files)"
            />
      </div>

      <div mat-dialog-actions align="end">
        <button appButton accent (click)="onSendEmailClick()" cdkFocusInitial>
          Send Email
        </button>
        <button appButton accent mat-dialog-close="2" cdkFocusInitial>
          Close
        </button>
      </div>
    </div>
</ng-container>
