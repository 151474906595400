import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { NavService } from '../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signature-route',
  template: '<app-signature-container></app-signature-container>',
})
export class SignatureRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  name: 'signature' | undefined

  constructor(private route: ActivatedRoute, private nav: NavService) {}

  ngOnInit(): void {
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
