import { MarketService } from 'src/app/api/market/market.service'
import { SharedIDPortfolio } from '../model/portfolio-set.model'
import { StructureMarketLayersResponse } from '../../api/model/backend.model'
import {
  // MarketLayerPost,
  MarketInputLayer,
  MarketDocument,
} from '../../api/model/market.model'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { Component, Inject, ViewChild, OnDestroy } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { Program } from '../../core/model/program.model'
import { State as AuthState } from '../../core/store/auth/auth.state.facade'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { FormControl } from '@angular/forms'
import { filterTowerLayers, isLayerAgg } from '../model/layers.util'
import { analyzereConstants } from '@shared/constants/analyzere'
import { State as ReinsurerState } from '../../reinsurers/store/study-reinsurers.reducer'
import { MatOption } from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  StructureLayerDataResponse,
  BlobResponse,
} from '../../api/model/backend.model'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { interval, Subscription } from 'rxjs'
// import { Store } from '@ngrx/store'
// import { AppState } from '../../core/store'
// import { setProgramMarketLayer } from '../../core/store/program.actions'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { Reinstatement } from '../../api/analyzere/analyzere.model'
import { PercentPipe } from '@angular/common'
import { TremorDialogComponent } from './tremor-dialog.component'
import { TowerTooltipElement } from '../tower/mechanics/tower.model'
import * as d3 from 'd3'
import { AnalyzreService } from '../../api/analyzere/analyzre.service'

interface EditReinstatements {
  counter?: number
  premium?: number
  formattedValue?: string | number | number[]
}

export interface DialogData {
  layers: LayerState[]
  currentProgram: Program
  authState: AuthState
  reinsurers: ReinsurerState
  startingCession: StructureLayerDataResponse
  sharedIDPortfolio: SharedIDPortfolio[]
  marketBlob: BlobResponse
  selectedClientID: string | null
  selectedYearID: string | null
  layerViewIDs: Record<string, string>
  grossPortfolioViewID: string | null
}

export interface LayerData {
  layer: LayerState
  display: string
}

export interface MarketFile {
  fileName: string
  name: string
  selected: boolean
  date: string
  path: string
  guid: string
  class: string
}

export interface TableData {
  id: number | null
  status: string
  layer: LayerState
  reinsurer: string
  underwriter: string
  note: string
  rol: string
  capacity: string
  layer_id: string
  market_reference: string
}

@Component({
  selector: 'app-submit-market-dialog',
  styleUrls: [`./submit-market-dialog.scss`],
  templateUrl: './submit-market-dialog.html',
})
export class SubmitMarketDialogComponent implements OnDestroy {
  layerListControl = new FormControl()
  layerList: LayerData[] = []
  reinsurerListControl = new FormControl()
  reinsurerList: string[] = []
  underwriterControl = new FormControl()
  underwriterList: string[] = []
  submissionCounter = 0
  highlight: string[] = []

  displayedColumns: string[] = [
    'status',
    'layer',
    'reinsurer',
    'underwriter',
    'reinstatements',
    'occurrencelimit',
    'attachmentpoint',
    'aggregatelimit',
    'capacity',
    'rol',
    'lossOnLine',
    'purePremium',
  ]
  dataSource: TableData[] = []
  intervalSubscription: Subscription
  tooltip: TowerTooltipElement | null = null
  analyzereMetrics: Map<
    string,
    {
      lossOnLine: string
      purePremium: string
    } | null
  > = new Map()

  @ViewChild('allSelected', { static: true }) private allSelected: MatOption

  constructor(
    private marketService: MarketService,
    private shortNumberPipe: ShortNumberPipe,
    public dialogRef: MatDialogRef<SubmitMarketDialogComponent>,
    private _snackBar: MatSnackBar,
    // private store: Store<AppState>,
    private tremorDialog: MatDialog,
    private analyzereService: AnalyzreService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.tooltip = d3
      .select<HTMLBodyElement, any>('body')
      .select<HTMLDivElement>('#d3Tooltip')
    this.pollMarket()
    data.layers = data.layers.filter(filterTowerLayers)
    this.getLayerList(data.layers)
    this.getReinsurerList()
    this.getMarketContent()
    this.intervalSubscription = interval(10000).subscribe(_x => {
      this.pollMarket()
    })
  }

  mouseEnter(event: any, val: any) {
    const c = event.currentTarget.cloneNode(true)
    c.style.display = 'inline'
    c.style.width = 'auto'
    c.style.visibility = 'hidden'
    c.style.fontSize = 'var(--font-size-small)'
    document.body.appendChild(c)
    if (c.offsetWidth + 1 > event.currentTarget.clientWidth) {
      if (this.tooltip) {
        this.tooltip.transition().duration(1).style('opacity', 0.9)
        this.tooltip
          .html(val)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY}px`)
      }
    }
    c.remove()
  }

  mouseOut() {
    if (this.tooltip) {
      this.tooltip.transition().duration(0).style('opacity', 0)
    }
  }

  getMetricsPremiumValue(id: string, currency: string) {
    return this.analyzereMetrics.get(id)
      ? this.getDollarValue(
          currency,
          this.analyzereMetrics.get(id)?.purePremium
        )
      : ''
  }

  getMetricsLossOnLineValue(id: string) {
    return this.analyzereMetrics.get(id)
      ? this.analyzereMetrics.get(id)?.lossOnLine
      : ''
  }

  getMetricsLossOnLine(id: string) {
    const analyzereMetrics = this.analyzereMetrics.get(id)
    if (analyzereMetrics && analyzereMetrics.lossOnLine) {
      return parseFloat(analyzereMetrics.lossOnLine) / 100
    } else {
      return 0
    }
  }

  getAnalyzereMetrics() {
    const layerIDs: LayerState[] = []
    this.dataSource.forEach(data => {
      if (
        layerIDs.findIndex(layerID => {
          return layerID.layer.id === data.layer.layer.id
        }) === -1 &&
        this.analyzereMetrics.get(data.layer.layer.id) === undefined
      ) {
        this.analyzereMetrics.set(data.layer.layer.id, null)
        layerIDs.push(data.layer)
      }
    })
    layerIDs.forEach(data => {
      if (!this.analyzereMetrics.get(data.layer.id)) {
        this.fetchAnalyzereMetrics(data)
      }
    })
  }

  pollMarket() {
    if (this.dataSource.length === 0 && this.data.currentProgram.marketLayer) {
      this.data.currentProgram.marketLayer.forEach(
        (marketLayer: StructureMarketLayersResponse) => {
          this.dataSource.push({
            id: marketLayer.id,
            status: 'hourglass_empty',
            layer: this.data.layers.filter((layer: LayerState) => {
              return layer.layer.physicalLayer.id === marketLayer.layer_id
            })[0],
            reinsurer: '',
            underwriter: marketLayer.underwriter,
            note: 'This is a note.',
            rol: '',
            capacity: '',
            layer_id: marketLayer.layer_id,
            market_reference: marketLayer.market_reference,
          })
          this.dataSource = [...this.dataSource]
        }
      )
    }
    this.dataSource.forEach((marketLayer: TableData) => {
      if (marketLayer.market_reference !== '') {
        this.marketService
          .getQuotation(
            marketLayer.market_reference,
            this.data.selectedClientID ? this.data.selectedClientID : ''
          )
          .subscribe(res => {
            if (!res.error) {
              if (res.data) {
                const index = this.dataSource.findIndex(existingData => {
                  return existingData.id === marketLayer.id
                })
                if (
                  res.data.programStateId === 0 ||
                  res.data.programStateId === 1 ||
                  res.data.programStateId === 2 ||
                  res.data.programStateId === 4 ||
                  res.data.programStateId === 5
                ) {
                  if (index !== -1) {
                    if (
                      res.data.interactions.length > 0 &&
                      res.data.interactions[0].interactionStateId === 9
                    ) {
                      this.dataSource[index].status = 'error'
                    } else {
                      this.dataSource[index].status = 'hourglass_empty'
                    }
                  }
                } else {
                  if (index !== -1) {
                    this.dataSource[index].status = 'done'
                    this.dataSource[index].reinsurer =
                      res.data.quotations[0].partyName
                    this.dataSource[index].capacity =
                      res.data.quotations[0].layers
                        .filter(quotedLayer => {
                          return (
                            quotedLayer.layerReference === marketLayer.layer_id
                          )
                        })[0]
                        .capacity.toFixed(3)
                        .toString()
                    this.dataSource[index].rol = res.data.quotations[0].layers
                      .filter(quotedLayer => {
                        return (
                          quotedLayer.layerReference === marketLayer.layer_id
                        )
                      })[0]
                      .rateOnLine.toFixed(3)
                      .toString()
                  }
                }
                this.getAnalyzereMetrics()
                this.dataSource = [...this.dataSource]
              }
            }
          })
      }
    })
  }

  tremor() {
    this.tremorDialog.open(TremorDialogComponent, {
      width: '100vw',
      height: '90vw',
      data: {
        tremor: this.data.currentProgram.tremor,
      },
    })
  }

  fileClick(file: MarketFile) {
    window.open(file.path)
  }

  selectFile(file: MarketFile) {
    const index = this.availableFiles.findIndex((avFile: MarketFile) => {
      return avFile.name === file.name
    })
    this.availableFiles[index].selected = !this.availableFiles[index].selected
  }

  getROL(rol: any) {
    if (rol === undefined) {
      return 'N/A'
    } else {
      return rol
    }
  }

  getColor(selected: boolean) {
    if (selected) {
      return 'accent'
    } else {
      return 'primary'
    }
  }

  getIconFromFile(fileName: string) {
    const dotSplit = fileName.split('.')
    if (
      dotSplit[dotSplit.length - 1] === 'xlsx' ||
      dotSplit[dotSplit.length - 1] === 'xls' ||
      dotSplit[dotSplit.length - 1] === 'csv'
    ) {
      return 'excel'
    } else if (
      dotSplit[dotSplit.length - 1] === 'doc' ||
      dotSplit[dotSplit.length - 1] === 'docx'
    ) {
      return 'word'
    } else {
      return 'default'
    }
  }

  selectDeselectFile(selected: boolean) {
    if (selected) {
      return 'Exclude'
    } else {
      return 'Include'
    }
  }

  getMarketContent() {
    if (this.data.selectedClientID && this.data.selectedYearID) {
      this.marketService
        .getDocuments(
          this.data.selectedClientID ? this.data.selectedClientID : ''
        )
        .subscribe(res => {
          if (!res.error) {
            if (res.data && res.data.documents) {
              res.data.documents.forEach((doc: MarketDocument) => {
                if (
                  doc.documentClass === 'Loss Listing' ||
                  doc.documentClass === 'Exposure Listing'
                ) {
                  this.availableFiles.push({
                    name: doc.documentName,
                    fileName: doc.fileName,
                    selected: false,
                    date: doc.createdAtTimestamp.split('T')[0],
                    path: doc.links[0].url,
                    guid: doc.id,
                    class: doc.documentClass,
                  })
                }
              })
            }
          }
        })
    }
  }

  constructURL(name: string) {
    return (
      this.data.marketBlob.url +
      '/' +
      this.data.marketBlob.container_name +
      '/' +
      name +
      this.data.marketBlob.token
    )
  }

  selectAll(field: MatOption) {
    if (field.selected) {
      const allLayers: LayerState[] = []
      this.layerList.forEach((layerD: LayerData) => {
        allLayers.push(layerD.layer)
      })
      this.layerListControl.setValue(allLayers)
      field.select()
    } else {
      this.layerListControl.setValue([])
    }
    this.highlightLayers()
  }

  getLayerText() {
    let layerText = ''
    if (
      this.layerListControl &&
      this.layerListControl.value &&
      this.layerListControl.value.length > 0
    ) {
      if (this.allSelected.selected) {
        layerText = this.layerDetailsString(this.layerListControl.value[1])
      } else {
        layerText = this.layerDetailsString(this.layerListControl.value[0])
      }
      if (this.layerListControl.value.length > 1) {
        let numOthers = this.layerListControl.value.length - 1
        if (this.allSelected.selected) {
          numOthers--
        }
        if (numOthers > 0) {
          layerText +=
            ' (+' + numOthers + (numOthers > 1 ? ' others)' : ' other)')
        }
      }
    }
    return layerText
  }

  // hard-coded for demo purposes only
  availableFiles: MarketFile[] = [
    {
      name: 'Limits Profile',
      fileName: 'limitsprofile.xlsx',
      selected: false,
      date: '6/1/2023',
      path: 'thepath',
      guid: 'guid',
      class: 'Exposure Listing',
    },
    {
      name: 'Claims Listing',
      fileName: 'claimslisting.xlsx',
      selected: false,
      date: '6/1/2023',
      path: 'thepath',
      guid: 'guid',
      class: 'Loss Listing',
    },
    {
      name: 'Large Losses',
      fileName: 'largelosses.xlsx',
      selected: false,
      date: '6/1/2023',
      path: 'thepath',
      guid: 'guid',
      class: 'Loss Listing',
    },
  ]

  submitFiles: string[] = []

  setSubmittedFile(file: MarketFile) {
    const index = this.submitFiles.indexOf(file.guid)
    if (index > -1) {
      this.submitFiles.splice(index, 1)
    } else {
      this.submitFiles.push(file.guid)
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
  }

  layerClicked(field: MatOption) {
    if (!field.selected) {
      this.allSelected.deselect()
    } else if (field.selected) {
      const selectedLayers = this.layerListControl.value
      if (
        selectedLayers.filter((layer: LayerState) => {
          return layer === field.value
        }).length > 0
      ) {
        if (selectedLayers.length === this.layerList.length) {
          this.allSelected.select()
        } else {
          this.allSelected.deselect()
        }
      } else {
        if (selectedLayers.length + 1 === this.layerList.length) {
          this.allSelected.select()
        } else {
          this.allSelected.deselect()
        }
      }
    }
    this.highlightLayers()
  }

  reinsurerClicked(field: MatOption) {
    const reinsurerEntities =
      this.data.reinsurers.entities[this.data.currentProgram.studyID]
    if (reinsurerEntities) {
      const selectedReinsurer = reinsurerEntities.reinsurers.find(reinsurer => {
        return reinsurer.name === field.value
      })
      if (
        selectedReinsurer &&
        selectedReinsurer.reinsurerProgramFactor[0].key_uw_contact !== null
      ) {
        if (field.selected) {
          this.underwriterList.push(
            selectedReinsurer.reinsurerProgramFactor[0].key_uw_contact
          )
        } else {
          const index = this.underwriterList.indexOf(
            selectedReinsurer.reinsurerProgramFactor[0].key_uw_contact
          )
          if (index > -1) {
            this.underwriterList.splice(index, 1)
          }
        }
      }
    }
  }

  getReinsurerList() {
    const reinsurerEntities =
      this.data.reinsurers.entities[this.data.currentProgram.studyID]
    if (reinsurerEntities) {
      const reinsurers = reinsurerEntities.reinsurers
        .filter(r => !r.is_default)
        .filter(
          r =>
            r.reinsurerProgramFactor[0].incumbent ||
            r.reinsurerProgramFactor[0].target_market
        )
      reinsurers.forEach((reinsurer: Reinsurer) => {
        this.reinsurerList.push(reinsurer.name)
      })
    } else {
      this.reinsurerList = []
    }
  }

  getWidth() {
    return window.innerWidth * 0.8 * 0.33
  }

  getHeight() {
    return window.innerHeight * 0.3
  }

  getStatus(status: string) {
    if (status === 'add') {
      return ' Send'
    } else if (status === 'hourglass_empty') {
      return ' Awaiting Quote'
    } else if (status === 'done') {
      return ' Quoted'
    } else if (status === 'error') {
      return ' Error'
    }
  }

  highlightLayer(item: LayerState) {
    const highlightedLayers: string[] = []
    if (this.layerListControl.value !== null) {
      this.layerListControl.value.forEach((layer: LayerState) => {
        if (layer.layer) {
          highlightedLayers.push(layer.layer.id)
        }
      })
    }
    if (item.layer) {
      highlightedLayers.push(item.layer.id)
    }
    this.highlight = highlightedLayers
  }

  highlightLayers() {
    const highlightedLayers: string[] = []
    if (this.layerListControl.value !== null) {
      this.layerListControl.value.forEach((layer: LayerState) => {
        if (layer.layer) {
          highlightedLayers.push(layer.layer.id)
        }
      })
    }
    this.highlight = highlightedLayers
  }

  getLayerList(layers: LayerState[]) {
    layers.filter(filterTowerLayers).forEach((layer: LayerState) => {
      if (layer.layer) {
        this.layerList.push({ display: this.layerDetailsString(layer), layer })
      }
    })
  }

  getDollarValue(currency: any, value: any) {
    if (value > analyzereConstants.unlimitedValue) {
      return 'Unlimited'
    } else {
      return this.shortNumberPipe.transform(value, currency)
    }
  }

  layerDetailsString(layer: LayerState) {
    if (layer && layer.layer) {
      if (
        (layer.layer.meta_data.sage_layer_type === 'cat_qs' ||
          layer.layer.meta_data.sage_layer_type === 'noncat_qs' ||
          layer.layer.meta_data.sage_layer_type === 'ahl_qs') &&
        layer.layer.physicalLayer.limit.value >=
          analyzereConstants.unlimitedValue
      ) {
        return (
          layer.layer.physicalLayer.description +
          ' | ' +
          (Math.abs(layer.layer.physicalLayer.participation) * 100).toFixed(2) +
          '% of Unlimited' +
          ' xs ' +
          this.shortNumberPipe.transform(
            layer.layer.physicalLayer.attachment.value,
            layer.layer.physicalLayer.franchise.currency
          )
        )
      } else {
        return (
          layer.layer.physicalLayer.description +
          ' | ' +
          (Math.abs(layer.layer.physicalLayer.participation) * 100).toFixed(2) +
          '% of ' +
          this.shortNumberPipe.transform(
            layer.layer.physicalLayer.limit.value,
            layer.layer.physicalLayer.franchise.currency
          ) +
          ' xs ' +
          this.shortNumberPipe.transform(
            layer.layer.physicalLayer.attachment.value,
            layer.layer.physicalLayer.franchise.currency
          )
        )
      }
    } else {
      return ''
    }
  }

  isNew() {
    if (this.dataSource.length > 0) {
      let newElement = false
      this.dataSource.forEach((element: TableData) => {
        if (element.status === 'add') {
          newElement = true
          if (
            this.displayedColumns.filter((displayedColumn: string) => {
              return displayedColumn === 'submit'
            }).length === 0
          ) {
            this.displayedColumns.push('submit')
          }
        }
      })
      return newElement
    } else {
      const index = this.displayedColumns.indexOf('submit')
      if (index > -1) {
        this.displayedColumns.splice(index, 1)
      }
      return false
    }
  }

  submitAll() {
    // simulating a market submission for demo purposes only
    this._snackBar.open('Submitting Layer to Market...')

    const newEntries = this.dataSource.filter(entry => {
      return entry.status === 'add'
    })
    const submittedResinurers: Set<string> = new Set<string>()
    newEntries.forEach(newEntry => {
      if (!submittedResinurers.has(newEntry.reinsurer)) {
        submittedResinurers.add(newEntry.reinsurer)
        const reinsurerEntries = newEntries.filter(et => {
          return et.reinsurer === newEntry.reinsurer
        })
        this.submit(newEntry.reinsurer, reinsurerEntries)
      }
    })
  }

  submit(_reinsurerName: string, elements: TableData[]) {
    const inputLayers: MarketInputLayer[] = []
    elements.forEach(element => {
      this.submissionCounter++

      setTimeout(() => {
        inputLayers.push(this.processRow(element))
      }, (Math.floor(Math.random() * 20) + 5) * 1000)
    })
    // const marketPost: MarketLayerPost = {
    //   programReference: this.data.currentProgram.id,
    //   programName: this.data.currentProgram.label,
    //   programDescription: this.data.currentProgram.description
    //     ? this.data.currentProgram.description
    //     : this.data.currentProgram.label,
    //   brokerEmail: this.data.authState.username
    //     ? this.data.authState.username
    //     : '',
    //   renewalDate: '2021-05-31T00:00:00+00:00',
    //   inputLayers,
    //   documentStoreIDs: this.submitFiles,
    //   reInsurerIds: [1],
    //   cedantName: 'Star Insurance Company',
    //   cedantId: '18023',
    // }
    // this._snackBar.open('Submitting Layer to Market...')
    // this.marketService
    //   .postMarket(
    //     JSON.stringify(marketPost),
    //     this.data.selectedClientID ? this.data.selectedClientID : ''
    //   )
    //   .subscribe(marketRes => {
    //     if (marketRes.error) {
    //       this._snackBar.dismiss()
    //       this._snackBar.open('Error While Submitting Layer.', 'X', {
    //         duration: 2000,
    //       })
    //     } else if (marketRes.data) {
    //       if (!marketRes.data.success) {
    //         this._snackBar.dismiss()
    //         this._snackBar.open('Error While Submitting Layer.', 'X', {
    //           duration: 2000,
    //         })
    //       } else {
    //         elements.forEach(element => {
    //           // add to the state
    //           this.marketService
    //             .postMarketLayerID(this.data.currentProgram.id, [
    //               {
    //                 layer_id: element.layer.layer.physicalLayer.id,
    //                 market_reference: marketRes.data ? marketRes.data.id : '',
    //                 underwriter: element.underwriter,
    //               },
    //             ])
    //             .subscribe(layerIDRes => {
    //               this._snackBar.dismiss()
    //               if (layerIDRes.error) {
    //                 this._snackBar.open('Error While Submitting Layer.', 'X', {
    //                   duration: 2000,
    //                 })
    //               } else if (layerIDRes.data) {
    //                 this.store.dispatch(
    //                   setProgramMarketLayer({
    //                     program: this.data.currentProgram,
    //                     marketLayers: [
    //                       {
    //                         id: layerIDRes.data.Id,
    //                         layer_id: layerIDRes.data.layer_id,
    //                         structure_id: layerIDRes.data.structure_id,
    //                         market_reference: layerIDRes.data.market_reference,
    //                         underwriter: layerIDRes.data.underwriter,
    //                       },
    //                     ],
    //                   })
    //                 )
    //                 const indexElement = this.dataSource.indexOf(element)
    //                 this.dataSource[indexElement].status = 'hourglass_empty'
    //                 this.dataSource[indexElement].id = layerIDRes.data.Id
    //                 this.dataSource[indexElement].market_reference =
    //                   layerIDRes.data.market_reference
    //                 this.dataSource[indexElement].layer_id =
    //                   layerIDRes.data.layer_id
    //                 if (
    //                   this.dataSource.filter((row: TableData) => {
    //                     return row.status === 'add'
    //                   }).length === 0
    //                 ) {
    //                   const index = this.displayedColumns.indexOf('submit')
    //                   if (index > -1) {
    //                     this.displayedColumns.splice(index, 1)
    //                   }
    //                 }
    //                 this._snackBar.open('Layer Successfully Submitted.', 'X', {
    //                   duration: 2000,
    //                 })
    //               }
    //             })
    //         })
    //       }
    //     }
    //   })
  }

  processRow(element: TableData): MarketInputLayer {
    // hard-coded for demo purposes onlyy

    const lossonline = this.getMetricsLossOnLineValue(
      element.layer.layer.id
    )?.replace('%', '')
    if (lossonline && !isNaN(parseFloat(lossonline))) {
      const multLoL = 1.5 * (Number(lossonline) / 100)
      element.rol = `${(multLoL * 100).toFixed(2)}%`
    }

    element.capacity = this.getDollarValue(
      element.layer.layer.physicalLayer.franchise.currency,
      element.layer.layer.physicalLayer.limit.value
    )
    const marketLayer: MarketInputLayer = {
      layerType: element.layer.layer.physicalLayer.meta_data.sage_layer_type
        ? element.layer.layer.physicalLayer.meta_data.sage_layer_type
        : '',
      name: element.layer.layer.physicalLayer.description
        ? element.layer.layer.physicalLayer.description
        : '',
      description: element.layer.layer.physicalLayer.description
        ? element.layer.layer.physicalLayer.description
        : '',
      layerReference: element.layer.layer.physicalLayer.id,
      inceptionDate: '2021-06-01T00:00:00+00:00',
      expiryDate: '2022-05-31T00:00:00+00:00',
      cession: Math.abs(element.layer.layer.physicalLayer.participation),
      laePercentage: 0.1,
      expectedLoss: this.getMetricsLossOnLine(element.layer.layer.id),
      triggers: [
        {
          eventCoverageTypeId: 1,
          limit: {
            limitTypeId: isLayerAgg(element.layer.layer) ? 1 : 2,
            attachment: isLayerAgg(element.layer.layer)
              ? element.layer.layer.physicalLayer.aggregateAttachment.value >=
                analyzereConstants.unlimitedValue
                ? -1
                : element.layer.layer.physicalLayer.aggregateAttachment.value
              : element.layer.layer.physicalLayer.attachment.value >=
                analyzereConstants.unlimitedValue
              ? -1
              : element.layer.layer.physicalLayer.attachment.value,
            limitValue: isLayerAgg(element.layer.layer)
              ? element.layer.layer.physicalLayer.aggregateLimit.value >=
                analyzereConstants.unlimitedValue
                ? -1
                : element.layer.layer.physicalLayer.aggregateLimit.value
              : element.layer.layer.physicalLayer.limit.value >=
                analyzereConstants.unlimitedValue
              ? -1
              : element.layer.layer.physicalLayer.limit.value,
            currency: element.layer.layer.physicalLayer.limit.currency,
          },
          reinstatements: [
            {
              number: 1,
              percentage: 1,
            },
          ],
          /*SubjectLosses: [
            {
              Description: '5969-18 ANP Ex CA',
              UnderlyingLossSourceName: null,
              UnderlyingLossSourceTypeId: 1,
              Regions: [
                294,
                291,
                293,
                292,
                287,
                290,
                289,
                284,
                285,
                286,
                281,
                282,
                283,
                277,
                279,
                280,
                273,
                270,
                274,
                269,
                271,
                272,
                268,
                275,
                276,
                265,
                267,
                266,
                262,
                261,
                264,
                259,
                260,
                263,
                254,
                255,
                258,
                252,
                256,
                253,
                248,
                250,
                251,
                296,
                297,
                249,
                288,
                278,
                257,
                247,
              ],
              Perils: [1, 2, 3, 4, 8, 12, 16, 64, 512, 2064, 2048],
              LinesOfBusiness: [4, 2, 3, 5],
              Inuring: false,
            },
          ],*/
        },
      ],
    }

    this.submissionCounter--
    if (this.submissionCounter <= 0) {
      this._snackBar.dismiss()
    }
    return marketLayer
  }

  cancel(element: any) {
    const index = this.dataSource.indexOf(element)
    if (index > -1) {
      this.dataSource.splice(index, 1)
    }
    if (
      this.dataSource.filter((dataElement: TableData) => {
        return dataElement.status === 'add'
      }).length === 0
    ) {
      const columnIndex = this.displayedColumns.indexOf('submit')
      if (columnIndex > -1) {
        this.displayedColumns.splice(columnIndex, 1)
      }
    }
    this.dataSource = [...this.dataSource]
  }

  fetchAnalyzereMetrics(layer: LayerState) {
    const layersById = this.data.layers.reduce(
      (obj: Record<string, LayerState>, item) => (
        (obj[item.layer.id] = item), obj
      ),
      {}
    )
    this.analyzereService
      .getLayersViewViewMetricsAndCalculate(
        this.data.layerViewIDs[layer.layer.id],
        this.data.grossPortfolioViewID,
        this.data.layerViewIDs,
        layersById,
        this.data.layers,
        false
      )
      .subscribe(res => {
        if (res.error) {
          return { error: res.error }
        } else if (res.data) {
          this.analyzereMetrics.set(layer.layer.id, {
            lossOnLine: (res.data.lossOnLine * 100).toFixed(2) + '%',
            purePremium: Math.abs(res.data.purePremium).toFixed(2),
          })
        }
      })
  }

  add() {
    let addedEntries: TableData[] = []
    this.layerListControl.value.forEach((layer: LayerState) => {
      if (layer.layer) {
        this.fetchAnalyzereMetrics(layer)
        this.reinsurerListControl.value.forEach((reinsurer: string) => {
          addedEntries.push({
            id: null,
            status: 'add',
            underwriter: this.underwriterControl.value,
            layer,
            reinsurer,
            note: '',
            rol: '',
            capacity: '',
            layer_id: '',
            market_reference: '',
          })
        })
      }
    })
    addedEntries = addedEntries.concat(this.dataSource)
    this.dataSource = addedEntries
    this.dataSource = [...this.dataSource]
    this.reinsurerListControl.setValue(null)
    this.underwriterControl.setValue(null)
    this.underwriterList = []
  }

  showAgg() {
    let showAgg = false
    this.layerList.forEach((layerData: LayerData) => {
      if (isLayerAgg(layerData.layer.layer)) {
        showAgg = true
      }
    })
    return showAgg
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe()
  }

  formatValueReinstatement(
    value: string | number | number[] | Reinstatement[]
  ) {
    if (typeof value === 'string' || typeof value === 'number') {
      throw new Error('Layer property number-list value must be reinstatements')
    }
    const newArray: Array<EditReinstatements> = []
    value.forEach((r: Reinstatement | number) => {
      if (typeof r === 'number') {
        throw new Error(
          'Layer property number-list value must be reinstatements'
        )
      }
      if (newArray.length === 0) {
        newArray.push({
          counter: 1,
          premium: r.premium,
          formattedValue: this.setPipePercentage(r.premium),
        })
      } else {
        let addNew = true
        newArray.forEach((er: EditReinstatements) => {
          if (er.premium === r.premium) {
            addNew = false
            // tslint:disable-next-line: no-non-null-assertion
            er.counter = er.counter! + 1
          }
        })
        if (addNew) {
          newArray.push({
            counter: 1,
            premium: r.premium,
            formattedValue: this.setPipePercentage(r.premium),
          })
        }
      }
    })
    return newArray
  }

  setPipePercentage(num: string | number | undefined): string {
    if (num === undefined) {
      return ''
    }
    const pipe = new PercentPipe('en-US')
    let perc
    perc = pipe.transform(num)
    return perc || ''
  }
}
