<app-portfolio-tower-properties
  [incrementsYOcc]="incrementsYOcc$ | async"
  [incrementsYDirtyOcc]="incrementsYDirtyOcc$ | async"
  [maxYOcc]="maxYOcc$ | async"
  [maxYDirtyOcc]="maxYDirtyOcc$ | async"
  [mostOcc]="mostOcc$ | async"
  [incrementsYAgg]="incrementsYAgg$ | async"
  [incrementsYDirtyAgg]="incrementsYDirtyAgg$ | async"
  [maxYAgg]="maxYAgg$ | async"
  [log]="log$ | async"
  [logMin]="logMin$ | async"
  [snapping]="snapping$ | async"
  [maxYDirtyAgg]="maxYDirtyAgg$ | async"
  [mostAgg]="mostAgg$ | async"
  [layers]="layers$ | async"
  [portfolioSetID]="portfolioSetID$ | async"
  [aggLayers]="aggLayers$ | async"
  [programID]="programID$ | async"
  [currentCurrency]="currentCurrency$ | async"
  (propertiesSave)="onPropertiesSave($event)"
>
</app-portfolio-tower-properties>
