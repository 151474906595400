import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatRadioChange } from '@angular/material/radio'
import { User } from 'src/app/api/model/backend.model'
import {
  ContactSupport,
  IControl,
} from 'src/app/contact-support/model/contact-support.model'
import { Client, ClientYear } from 'src/app/core/model/client.model'
import { Study } from 'src/app/core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-contact-support-button-dialog',
  templateUrl: './contact-support-button-dialog.component.html',
  styleUrls: ['./contact-support-button-dialog.component.scss'],
})
export class ContactSupportButtonDialogComponent implements OnInit {
  supportRecipientForm: FormGroup
  errorMessage: string
  fileToUpload: File | null
  emailSubjectInputControl = new FormControl('', [Validators.required])
  descriptionTextAreaControl = new FormControl('', [Validators.required])
  @Output() supportEmailClick = new EventEmitter<{
    contactSupport: ContactSupport
  }>()

  reportTypeValue: string = 'Issue'
  isUrgent: string = 'No'
  constructor(
    public dialogRef: MatDialogRef<ContactSupportButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      client: Client | null
      currentYear: ClientYear | null
      currentProgram: Study | undefined
      usersList: User[]
      userEmailListSelector: IControl
    },
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.supportRecipientForm = this.formbuilder.group({
      userEmailList: new FormControl([]),
    })
  }

  formValid() {
    return (
      this.emailSubjectInputControl.valid &&
      this.descriptionTextAreaControl.valid
    )
  }

  onUrgentSelectionChange(radioChange: MatRadioChange) {
    const emailSubject = this.emailSubjectInputControl.value.trim()
    this.isUrgent = radioChange.value
    if (this.isUrgent === 'Yes') {
      if (emailSubject && !emailSubject.includes('[Urgent]')) {
        this.emailSubjectInputControl.setValue('[Urgent] ' + emailSubject)
      } else {
        this.emailSubjectInputControl.setValue('[Urgent]')
      }
    } else if (this.isUrgent === 'No') {
      if (emailSubject.includes('[Urgent]')) {
        this.emailSubjectInputControl.setValue(
          emailSubject.replace('[Urgent]', '')
        )
      }
    }
  }

  handleFileInput(files: FileList): void {
    if (files.length > 0) {
      this.fileToUpload = files.item(0)
    }
  }

  onSendEmailClick() {
    if (this.formValid()) {
      const formData: FormData = new FormData()
      const contactSupport: ContactSupport = {
        reportType: this.reportTypeValue.toString(),
        isUrgent: this.isUrgent === 'Yes' ? true : false,
        emailSubject: this.emailSubjectInputControl.value.toString(),
        additionalRecipientsEmail:
          this.supportRecipientForm.get('userEmailList')?.value,
        issueDescription: this.descriptionTextAreaControl.value.toString(),
        currentPageUrl: window.location.href,
        currentClient:
          this.data.client && this.data.client.name
            ? this.data.client.name
            : '',
        currentYear:
          this.data.currentYear && this.data.currentYear.year
            ? this.data.currentYear.year
            : '',
        currentProgram:
          this.data.currentProgram && this.data.currentProgram.name
            ? this.data.currentProgram.name
            : '',
        attachmentFile: this.fileToUpload,
      }
      this.supportEmailClick.emit({ contactSupport: contactSupport })
      this.dialogRef.close()
    }
  }
}
