import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { groupBy, keys, partition } from 'ramda'
import { forkJoin, Observable } from 'rxjs'
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { LogicalPortfolioLayer } from '../../../api/analyzere/analyzere.model'
import { AnimatedScenariosService } from '../../../api/animated-scenarios/animated-scenarios.service'
import { ApiResponse, MaybeError } from '../../../api/model/api.model'
import { mergeMapWithInput, rejectErrorWithInput } from '../../../api/util'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import { LayerView } from '../../model/layer-view'
import { convertFromLogicalPortfolioLayers } from '../../model/layers.converter'
import { PortfoliosIDAndName } from '../../model/portfolio-set.model'
import {
  selectCededLayers,
  selectCurrentAnalysisProfileID,
  selectCurrentProgram,
  selectEditorPortfolioSetAndStudyIDs,
  selectGrossPortfolioViewID,
  selectLossSetGroups,
  selectNetPortfolioLayersIDs,
} from '../../store/analysis.selectors'
import {
  isAggLayerOptimization,
  OptimizationCandidateResultEntity,
} from '../optimization.model'
import * as fromOptimizationCandidatesResultsActions from './optimization-candidates-results.actions'
import * as fromOptimizationSelectors from './optimization.selectors'
import * as fromOptimizationCandidateLayersActions from './optimization-layers.actions'
import * as fromOptimizationTailMetricsActions from './optimization-tail-metrics.actions'
import {
  ExtraOptions,
  OptimizationService,
} from '../../../api/optimization/optimization.service'
import { Layer } from '../../model/layers.model'
import { isLayerAggFeeder } from '../../model/layers.util'

@Injectable()
export class OptimizationCandidateResultsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private animatedService: AnimatedScenariosService,
    private optimizationService: OptimizationService
  ) {}

  candidates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptimizationCandidatesResultsActions.generateCandidates),
      withLatestFrom(
        this.store.pipe(
          select(fromOptimizationSelectors.selectIncludedCandidateLayers)
        )
      ),
      mergeMap(([_, candidateLayers]) => {
        const actions: Action[] = []
        const candidates: OptimizationCandidateResultEntity[] = []
        const layerTuplet = partition(c => c.group === '', candidateLayers)
        layerTuplet[0].forEach(c => {
          candidates.push({
            id: c.id,
            createPortfolio: false,
            savePortfolio: false,
            candidateLayers: [c.id],
          })
        })
        const groupedBy = groupBy(c => c.group, layerTuplet[1])
        keys(groupedBy).forEach(key => {
          candidates.push({
            id: key as string,
            createPortfolio: false,
            savePortfolio: false,
            candidateLayers: groupedBy[key].map(c => c.id),
          })
        })
        actions.push(
          fromOptimizationCandidatesResultsActions.generateCandidatesSuccess({
            values: candidates,
          }),
          fromOptimizationCandidateLayersActions.getLayersMetrics({
            ids: candidateLayers.map(c => c.id),
          })
        )
        return actions
      })
    )
  })

  portfolioMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetrics
      ),
      withLatestFrom(
        this.store.pipe(select(selectNetPortfolioLayersIDs)),
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(
          select(fromOptimizationSelectors.selectCandidatesResultsByID)
        ),
        this.store.pipe(select(selectGrossPortfolioViewID))
      ),
      mergeMap(
        ([
          { id },
          netPortfolioIDs,
          cededLayers,
          analysisProfileID,
          rangesResultsByID,
          grossPortfolioViewID,
        ]) => {
          return this.optimizationService
            .getCandidateResultPortfolioMetrics(
              id,
              rangesResultsByID,
              netPortfolioIDs,
              cededLayers,
              analysisProfileID || '',
              grossPortfolioViewID || ''
            )
            .pipe(map(r => ({ ...r, id })))
        }
      ),
      withLatestFrom(
        this.store.pipe(
          select(fromOptimizationSelectors.selectTailMetricsState)
        )
      ),
      mergeMap(([response, tailMetricsState]) => {
        if (response.error) {
          return [
            fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetricsFailure(
              { id: response.id, error: response.error }
            ),
          ]
        }

        return [
          fromOptimizationCandidatesResultsActions.getCandidatesPortfolioMetricsSuccess(
            {
              changes: [
                // tslint:disable-next-line: no-non-null-assertion
                response.data!,
              ],
            }
          ),
          fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetrics(
            { id: response.id, ...tailMetricsState.portfolio }
          ),
        ]
      })
    )
  })

  save$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromOptimizationCandidatesResultsActions.save),
        withLatestFrom(
          this.store.pipe(
            select(fromOptimizationSelectors.selectCandidatesResultsToSave)
          ),
          this.store.pipe(select(selectCurrentProgram)),
          this.store.pipe(select(selectEditorPortfolioSetAndStudyIDs))
        ),
        filter(([_, results]) => {
          if (results.length === 0) {
            this.store.dispatch(
              fromOptimizationCandidatesResultsActions.saveSuccess({
                lastCreated: 0,
                details: [],
              })
            )
            return false
          } else {
            return true
          }
        }),
        map(([_, results, structure, portfolioSetAndStudyIDs]) => {
          return {
            results,
            structure,
            portfolioSetAndStudyIDs,
          }
        }),
        mergeMapWithInput(({ results, structure }) => {
          const actions: Array<
            ApiResponse<{
              program: Program
              cededLayers: LogicalPortfolioLayer[]
              portfolioIDs: PortfoliosIDAndName
              otherPrograms?: Program[]
            }>
          > = []
          results.forEach((_, index) => {
            actions.push(
              this.animatedService.cloneStructureWithSharedLimit(
                structure?.id as string,
                `${
                  structure?.label
                } - Optimization - ${new Date().getTime()}_${index}`,
                structure?.description ?? '',
                {
                  asOptimization: true,
                  persistStructure: true,
                  handleDirty: false,
                }
              )
            )
          })
          return forkJoin(actions).pipe(
            map(responses => {
              const data: Array<{
                program: Program
                cededLayers: LogicalPortfolioLayer[]
                portfolioIDs: PortfoliosIDAndName
                otherPrograms?: Program[]
              }> = []
              for (const response of responses) {
                if (response.error) {
                  return { error: response.error }
                } else {
                  // tslint:disable-next-line: no-non-null-assertion
                  data.push(response.data!)
                }
              }
              return { data }
            })
          )
        }),
        rejectErrorWithInput(error => {
          this.store.dispatch(
            fromOptimizationCandidatesResultsActions.saveFailure({ error })
          )
        }),
        withLatestFrom(this.store.pipe(select(selectLossSetGroups))),
        mergeMapWithInput(([[newStructures, { results }], lossSetGroups]) => {
          const updates: Observable<MaybeError>[] = []
          newStructures.forEach((newStructure, i) => {
            const result = results[i]
            let cededLayers = convertFromLogicalPortfolioLayers(
              newStructure.cededLayers
            )
            let options: ExtraOptions | undefined
            if (isAggLayerOptimization(cededLayers)) {
              const feeder =
                newStructure.cededLayers.find(l => isLayerAggFeeder(l)) ||
                (newStructure.cededLayers[0]
                  .sources[0] as LogicalPortfolioLayer)
              const feederLayer = convertFromLogicalPortfolioLayers([feeder])[0]
              cededLayers = [...cededLayers, feederLayer]
              options = {
                aggFeeder: feederLayer,
                isAgg: true,
              }
            } else {
              options = undefined
            }
            const candidateLayers = result.candidateLayers
            const newLayers: Layer[] = []
            for (const candidateLayer of candidateLayers) {
              let candidateLayerUpdated = JSON.parse(JSON.stringify(candidateLayer))
              if (candidateLayerUpdated && candidateLayerUpdated.technicalPremium === null) {
                candidateLayerUpdated.technicalPremium = 0
              }
              const templateLayer = cededLayers.find(
                l => l.meta_data.fromLayerID === candidateLayerUpdated.id.split('_')[0]
              ) as Layer
              const layerChanges = new LayerView(
                cededLayers.map(c => ({
                  deleted: false,
                  dirty: false,
                  layer: c,
                  hash: '',
                  new: false,
                })),
                templateLayer
              )
              layerChanges.setFromValues({
                ...candidateLayerUpdated,
                id: candidateLayerUpdated.id,
              })
              layerChanges.name = `Layer ${candidateLayerUpdated.layerNumber}`
              layerChanges.layer.id = candidateLayerUpdated.id
              newLayers.push(layerChanges.layer)
            }

            updates.push(
              this.optimizationService.updateWithCandidateLayers(
                newStructure.program.cededPortfolioID,
                newStructure.program.netPortfolioID,
                cededLayers.map(c => c.id),
                newLayers,
                result,
                lossSetGroups,
                options
              )
            )
          })
          return forkJoin(updates).pipe(
            map(responses => {
              for (const r of responses) {
                if (r.error) {
                  return { error: r.error }
                }
              }
              return {}
            })
          )
        }),
        rejectErrorWithInput(error => {
          this.store.dispatch(
            fromOptimizationCandidatesResultsActions.saveFailure({ error })
          )
        })
      )
      .pipe(
        map(([_response, [[newStructures, { portfolioSetAndStudyIDs }]]]) => {
          const details = newStructures.map(s => ({
            // tslint:disable-next-line: no-non-null-assertion
            ...portfolioSetAndStudyIDs!,
            program: s.program,
            otherPrograms: s.otherPrograms,
          }))
          return fromOptimizationCandidatesResultsActions.saveSuccess({
            // tslint:disable-next-line: no-non-null-assertion
            lastCreated: newStructures.length,
            details,
          })
        })
      )
  })
}
