import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {Action, Store} from '@ngrx/store'
import { concatMap, debounceTime, map, tap } from 'rxjs/operators'
import { rejectNil } from '../../../shared/util/operators'
import { LayerViewValues } from '../../model/layer-view'
import { LAYER_MODELING_DIMENSIONS } from '../layer-modeling.model'
import {
  restoreLayerModelingSettings,
  setLayerModelingDimensionProp,
} from './layer-modeling.actions'
import {AppState} from "../../../core/store";

const LOCAL_STORAGE_BASE = 'sage.layerModeling.'

@Injectable()
export class LayerModelingEffects {
  private actions$ = inject(Actions)

  storeOnSetDimensionProp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setLayerModelingDimensionProp),
        debounceTime(100),
        tap(({ dimension, prop }) => {
          localStorage.setItem(`${LOCAL_STORAGE_BASE}${dimension}`, prop)
        })
      ),
    { dispatch: false }
  )

  restoreSettingsFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreLayerModelingSettings),
      debounceTime(100),
      map(() => [] as Action[]),
      concatMap(() =>
        rejectNil(
          LAYER_MODELING_DIMENSIONS.map(dimension => {
            const path = `${LOCAL_STORAGE_BASE}${dimension}`
            const val = localStorage.getItem(path)
            if (val) {
              const prop = val as keyof LayerViewValues
              return setLayerModelingDimensionProp({ dimension, prop })
            }
          })
        )
      )
    )
  )
}
