import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { selectIsSageViewUpdated, selectMetricTableSettingsStudyID } from '../analysis/store/analysis.selectors'
import { fetchMetricTableSettings, setMetricTableSageViewChange } from '../analysis/store/metrics/metrics-cart/metrics-cart.actions'
import { AppState } from '../core/store'
import { NavService } from '../nav.service'
import { createTierPath } from '../tier/tier.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-route',
  template: `
    <app-metric-container></app-metric-container>
  `,
})
export class MetricsRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private nav: NavService
  ) {}

  ngOnInit(): void {
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()

    this.route.paramMap
      .pipe(
        map(params => createTierPath(params)),
        map(path => path.program),
        filter<string>(programID => programID != null && programID !== 'none'),
        withLatestFrom(
          this.store.pipe(select(selectMetricTableSettingsStudyID)),
          this.store.pipe(select(selectIsSageViewUpdated))
        ),
        filter(([next, prev,isSageViewUpdated]) => {
          const hasStudyIdChanged = !prev || next !== prev
          return hasStudyIdChanged || isSageViewUpdated // Added the condition to check if audience view in design has changed
        }),
        map(([next]) => fetchMetricTableSettings({ studyID: next })),
        tap( () => {
          this.store.dispatch(setMetricTableSageViewChange({isSageViewUpdated: false})) // Update the state
        })
      )
      .subscribe(this.store)
  }

  ngOnDestroy(): void {
  this.destroy$.next(true)
    this.destroy$.complete()
  }
}
