import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { reduceReducers } from '@shared/util/reduce-reducers'
import { CreditMetricConfiguration } from './../../model/credit-metric.model'
import { getTotalWeight } from '../../utils/credit.utils'
import * as fromCreditMetricActions from './credit-metrics.actions'

export interface State {
  metricConfigurationNames: string[]
  metricConfiguration?: CreditMetricConfiguration
  isLoading: boolean
  isDirty: boolean
  isSaving: boolean
}

export const initialState: State = {
  metricConfigurationNames: [],
  isLoading: false,
  isDirty: false,
  isSaving: false,
}

const metricConfigurationNamesReducer = createReducer(
  initialState.metricConfigurationNames,
  on(
    fromCreditMetricActions.GetMetricConfigurationNamesByCarrierIdSuccess,
    (_, { metricConfigurationNames }) => metricConfigurationNames
  ),
  on(fromCreditMetricActions.Reset, _ => [])
)

const metricConfigurationReducer = createReducer(
  initialState.metricConfiguration,
  on(
    fromCreditMetricActions.SelectMetricConfigurationSuccess,
    fromCreditMetricActions.CreateMetricConfigurationSuccess,
    (_, { metricConfiguration }) => metricConfiguration
  ),
  on(
    fromCreditMetricActions.UpdateMetricConfigurationName,
    (state, { metricConfigurationName }) => {
      const metricSettings = state.metricSettings.map(m => {
        return { ...m, name: metricConfigurationName }
      })
      return {
        ...state,
        name: metricConfigurationName,
        metricSettings,
      }
    }
  ),
  on(
    fromCreditMetricActions.UpdateCreditMetricSetting,
    (state, { metricSetting }) => {
      const metricSettings = [...state.metricSettings]
      const metricSettingIndex = metricSettings.findIndex(
        s =>
          s.category === metricSetting.category &&
          s.ordinal === metricSetting.ordinal
      )
      if (metricSettingIndex >= 0) {
        metricSettings.splice(metricSettingIndex, 1, metricSetting)
      }
      return {
        ...state,
        metricSettings,
        weight: getTotalWeight(metricSettings),
      }
    }
  ),
  on(
    fromCreditMetricActions.AddCreditMetricSetting,
    (state, { metricSetting }) => {
      const metricSettings = [...state.metricSettings, metricSetting]
      return {
        ...state,
        metricSettings,
        weight: getTotalWeight(metricSettings),
      }
    }
  ),
  on(
    fromCreditMetricActions.DeleteCreditMetricSettingSuccess,
    (state, { metricSetting }) => {
      const metricSettings = state.metricSettings.filter(
        s => s.id !== metricSetting.id
      )
      return {
        ...state,
        metricSettings,
        weight: getTotalWeight(metricSettings),
      }
    }
  ),
  on(fromCreditMetricActions.EnableAllCreditMetricSettings, state => {
    const metricSettings = state.metricSettings.map(s => {
      return { ...s, show: true }
    })
    return {
      ...state,
      metricSettings,
      weight: getTotalWeight(metricSettings),
    }
  }),
  on(
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    (state, { metricSettings }) => {
      return { ...state, metricSettings }
    }
  ),
  on(fromCreditMetricActions.Reset, _ => undefined)
)

const isLoadingReducer = createReducer(
  initialState.isLoading,
  on(fromCreditMetricActions.SelectMetricConfiguration, () => true),
  on(
    fromCreditMetricActions.SelectMetricConfigurationSuccess,
    fromCreditMetricActions.CreateMetricConfigurationSuccess,
    fromCreditMetricActions.Reset,
    () => false
  )
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    fromCreditMetricActions.CreateMetricConfigurationSuccess,
    fromCreditMetricActions.UpdateMetricConfigurationName,
    fromCreditMetricActions.UpdateCreditMetricSetting,
    fromCreditMetricActions.AddCreditMetricSetting,
    fromCreditMetricActions.EnableAllCreditMetricSettings,
    () => true
  ),
  on(
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    fromCreditMetricActions.Reset,
    () => false
  )
)

const isSavingReducer = createReducer(
  initialState.isSaving,
  on(fromCreditMetricActions.SaveCreditMetricSettings, () => true),
  on(
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    fromCreditMetricActions.SaveCreditMetricSettingsFailure,
    fromCreditMetricActions.Reset,
    () => false
  )
)

const creditMetricsReducer = combineReducers<State>({
  metricConfigurationNames: metricConfigurationNamesReducer,
  metricConfiguration: metricConfigurationReducer,
  isLoading: isLoadingReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
})

const reducedReducer = reduceReducers(creditMetricsReducer)

export function reducer(state: State | undefined, action: Action): State {
  return reducedReducer(state, action)
}
