import { createAction, props } from '@ngrx/store'

export interface AddProgramGroupProps {
  id: string
  ordinal?: number
  parentGroupID?: string
  isDuplicate?: boolean
}

export const setGrouperSlideIndex = createAction(
  '[Grouper View] Set Slide Index',
  props<{ index: number; force?: boolean }>()
)

export const setGrouperSlidesPerView = createAction(
  '[Grouper View] Set Slides Per View',
  props<{ value: number }>()
)

export const setGrouperTowerSizePercentage = createAction(
  '[Grouper View] Set Tower Size Percentage',
  props<{ percentage: number }>()
)

export const refreshGrouperTowerSizePercentage = createAction(
  '[Grouper View] Refresh Tower Size Percentage'
)
