import { createAction, props } from '@ngrx/store'
import { LayerDetailsExport } from 'src/app/analysis/layer-details/layer-details.model'
import { ApplicationError } from '../../../../error/model/error'
import { ProgramGroupSet, GroupBar, ProgramGroup } from '../program-group.model'

export interface AddProgramGroupProps {
  id: string
  ordinal?: number
  parentGroupID?: string
  isDuplicate?: boolean
}

export const addProgramGroup = createAction(
  '[Grouper Program Group] Add',
  props<AddProgramGroupProps>()
)

export const addProgramGroupSuccess = createAction(
  '[Grouper Program Group] Add Success',
  props<ProgramGroupSet & AddProgramGroupProps>()
)

export const addProgramGroupFailure = createAction(
  '[Grouper Program Group] Add Failure',
  props<{ error: ApplicationError }>()
)

export const addNewProgramGroup = createAction(
  '[Grouper Program Group] Add New',
  props<{
    label: string
    yearID: string
    parentGroupID?: string
    programIDs: string[]
    isUntitled: boolean
  }>()
)

export const removeProgramGroup = createAction(
  '[Grouper Program Group] Remove',
  props<{ id: string }>()
)

export const deleteProgramGroup = createAction(
  '[Grouper Program Group] Delete',
  props<{ groupBar: GroupBar; allowDelete: boolean }>()
)

export const deleteProgramGroupSuccess = createAction(
  '[Grouper Program Group] Delete Success',
  props<{ id: string }>()
)

export const deleteProgramGroupFailure = createAction(
  '[Grouper Program Group] Delete Failure',
  props<{ error: ApplicationError }>()
)

interface GrouperSetMinimizedProgramGroupProps {
  /* ID of Program Group to set minimized value */
  id: string
  /* Value: true for minimized, false for unminimized, undefined to toggle */
  value?: boolean
}

/**
 * Set Minimized Program Group
 */
export const setGrouperMinimizedProgramGroup = createAction(
  '[Grouper Program Group] Set Minimized Metric',
  props<GrouperSetMinimizedProgramGroupProps>()
)

export const addProgramGroupToGroup = createAction(
  '[Grouper Program Group] Add Group to Group',
  props<AddProgramGroupProps>()
)

export const renameProgramGroup = createAction(
  '[Grouper Program Group] Rename',
  props<{ id: string; newName: string }>()
)

export const fetchProgramGroupLayerDetails = createAction(
  '[Grouper Program Group] Fetch Layer Details',
  props<{ groupID: string; groupName: string }>()
)

export const exportLayerDetailsAsCsv = createAction(
  '[Layer Details] Export Layer Details As Csv',
  props<LayerDetailsExport>()
)

export const updateProgramGroup = createAction(
  '[Grouper Program Group] Update',
  props<{ group: ProgramGroup; fotCount: number; quoteCount: number }>()
)

export const updateProgramGroupSuccess = createAction(
  '[Grouper Program Group] Update Success',
  props<{ group: ProgramGroup }>()
)

export const updateProgramGroupFailure = createAction(
  '[Grouper Program Group] Update Failure',
  props<{ error: ApplicationError }>()
)
