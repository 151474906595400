import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  EMPTY_EXPLORE_MULTI_SELECT_EXPORT_PANEL,
  ExploreFilterMap,
  ExploreMultiSelectExportPanel,
  ExploreSummaryDatum,
  SummaryExportGroup,
  ExploreMultiSelectExportPanelTypeOptions,
  ExploreMultiSelectExportPanelType,
  ExploreMultiSelectExportPanelTitleOptions,
  ExploreMultiSelectExportPanelOption,
  EXPLORE_MULTI_SELECT_EXPORT_DEFAULT_STYLES,
  ExploreMultiSelectExportStyles,
  ExploreExportColumnArrayValue,
  EXPLORE_SUMMARY_HEADERS,
  EXPLORE_SUMMARY_LOSS_TYPE_HEADERS,
  ExploreSummaryHeader,
  ExploreMultiSelectExportPanelChartOptions,
  ExploreMultiSelectExportPanelChartOption,
  DEFAULT_SUMMARY_COLUMN_FILTER_MAP,
  SummaryDataResponse,
  ExploreMultiSelectExportLayoutPanel,
} from '../../../explore.model'
import { AccountOpportunity, ExploreSummaryView, StudyResponse } from 'src/app/api/model/backend.model'
import { AnalysisProfile } from 'src/app/api/analyzere/analyzere.model'
import { Program } from 'src/app/core/model/program.model'
import { trigger, state, style, transition, animate } from '@angular/animations'
import {
  buildSummaryFilterMap,
  filterLossSets,
  getGroupedSummaryRequest,
  getRowsFromLossSets,
  updateSelectedFilterMap,
} from '../../../explore.util'
import {
  SUMMARY_CHART_METRICS_INFO,
  SummaryChartAxis,
  SummaryChartInfo,
} from '../../../explore-gross-summary-charts/summary-charts-model'
import { LossSetLayer } from '../../../../model/loss-set-layers.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import { Client } from 'src/app/core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-multi-select-export-dialog',
  templateUrl: './explore-multi-select-export-dialog.component.html',
  styleUrls: ['./explore-multi-select-export-dialog.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 1 })),
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('200ms ease-in', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ExploreMultiSelectExportDialogComponent implements OnInit {
  @Input() analysisProfile: AnalysisProfile
  @Input() grossPortfolioID: string
  @Input() summaryExportGroups: SummaryExportGroup[]
  @Input() program: Program | undefined
  @Input() currentStructureCurrency: string | undefined
  @Input() summaryLossSetRows: ExploreSummaryDatum[]
  @Input() summaryColumnFilterMap: ExploreFilterMap
  @Input() summaryViews: ExploreSummaryView[]
  @Input() selectedSummaryView: ExploreSummaryView
  @Input() summaryData: SummaryDataResponse[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() summaryFilterMap: ExploreFilterMap
  @Input() summaryRP: number[]
  @Input() mappingLabels: MappingLabels
  @Input() client: Client
  @Input() study: StudyResponse
  @Input() selectedStructure: Program
  @Input() accountOpportunities: AccountOpportunity[]
  chartEntityLimit: number = 16
  defaultPanel: ExploreMultiSelectExportPanel
  darkMode: boolean = false
  summaryHeaders: ExploreSummaryHeader[]
  visibilityMap: ExploreFilterMap = {}
  defaultStyles: ExploreMultiSelectExportStyles
  titles: Record<string, string> = {}
  panels: ExploreMultiSelectExportPanel[]
  layoutPanels: ExploreMultiSelectExportLayoutPanel[] | null = null
  typeOptions = ExploreMultiSelectExportPanelTypeOptions
  titleOptions = ExploreMultiSelectExportPanelTitleOptions
  chartGridDropdownOptions = ExploreMultiSelectExportPanelChartOptions
  chartGridMetricsOptions: SummaryChartAxis[]
  columnOptions: ExploreExportColumnArrayValue[] = []
  previewOpen: boolean = false

  @Output() getSummaryExportGroups = new EventEmitter<SummaryExportGroup[]>()

  ngOnInit(): void {
    if (this.selectedSummaryView) {
      this.selectedSummaryView = this.summaryViews.find(
        view => view.name === this.selectedSummaryView.name
      )
      this.titleOptions = [
        ...ExploreMultiSelectExportPanelTitleOptions,
        {
          value: 'summaryView',
          label: 'Summary View',
        },
      ]
    }
    this.defaultPanel = {
      ...EMPTY_EXPLORE_MULTI_SELECT_EXPORT_PANEL,
      summaryView: this.selectedSummaryView,
    }
    this.panels = [this.defaultPanel]
    this.panels.forEach((_, i) => {
      const value = this.getIndexedTitle(i, 'Panel')
      this.visibilityMap[value] = true
    })
    this.defaultStyles = EXPLORE_MULTI_SELECT_EXPORT_DEFAULT_STYLES
    this.summaryHeaders = EXPLORE_SUMMARY_LOSS_TYPE_HEADERS.concat(
      EXPLORE_SUMMARY_HEADERS
    )
    this.summaryColumnFilterMap = DEFAULT_SUMMARY_COLUMN_FILTER_MAP
    this.chartGridMetricsOptions = Object.values(SUMMARY_CHART_METRICS_INFO)
    this.titles = {
      analysisProfileID: this.analysisProfile.id,
      grossPortfolioID: this.grossPortfolioID,
      structure: this.program.label,
      date: new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      summaryView: this.selectedSummaryView?.name ?? undefined,
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.summaryExportGroups) {
      if (
        this.summaryExportGroups &&
        !this.summaryExportGroups.some(g => !g.data) &&
        this.panels
      ) {
        this.generateLayoutPanels()
      }
    }
  }

  generateLayoutPanels(): void {
    if (this.panels) {
      this.layoutPanels = this.panels.map(panel => {
        if (panel.type === 'titles') {
          return {
            panel
          }
        }
        if (panel.summaryView) {
          const group = this.summaryExportGroups.find(g => g.data && g.name === panel.summaryView.name)
          if (group) {
            const view = this.summaryViews.find(
              v => v.name === panel.summaryView.name
            )
            if (view && panel) {
              return {
                panel,
                view,
                group,
              }
            }
          }
        }
      })
    }
  }

  toggleVisibility(index: number, title: string): void {
    const value = this.getIndexedTitle(index, title)
    this.visibilityMap[value] = !this.visibilityMap[value]
  }

  isSectionVisible(index: number, title: string): boolean {
    const value = this.getIndexedTitle(index, title)
    return this.visibilityMap[value]
  }

  getIndexedTitle(index: number, title: string): string {
    return `${title} ${index + 1} `
  }

  getPanelSubtitle(panelType: ExploreMultiSelectExportPanelType) {
    if (!panelType) {
      return ''
    }
    return this.typeOptions.find(o => o.value === panelType).label
  }

  onTypeChange(value: string, index: number): void {
    const panels = [...this.panels]
    let columns: ExploreExportColumnArrayValue[] | undefined = undefined
    if (value === 'table') {
      columns = this.getViewColumns()
    }
    panels[index] = {
      ...panels[index],
      type: value as ExploreMultiSelectExportPanelType,
      columns,
    }
    this.panels = panels
  }

  getViewColumns(): ExploreExportColumnArrayValue[] {
    let columns = this.summaryColumnFilterMap
    if (this.selectedSummaryView) {
      columns = updateSelectedFilterMap(
        this.summaryColumnFilterMap,
        this.selectedSummaryView.columnFilters
      )
    }
    return this.summaryHeaders.map(header => {
      return {
        value: header.id,
        label: header.label,
        selected: columns[header.id],
      }
    })
  }

  onColumnSelectionChange(event: any, index: number): void {
    const panels = [...this.panels]
    const panel = panels[index]
    panel.columns = panel.columns.map(col => {
      return {
        ...col,
        selected: event.includes(col.label),
      }
    })
    this.panels = panels
  }

  getColumnsValue(columns: ExploreExportColumnArrayValue[]): string[] {
    return columns.map(column => {
      if (column.selected || column.value === 'groupBy') {
        return column.label
      }
    })
  }

  onTopTitleChange(value: string, index: number): void {
    this.panels[index].topTitle = value
  }

  onTopTitleSelect(
    value: ExploreMultiSelectExportPanelOption,
    index: number
  ): void {
    const key = value.value as keyof typeof this.titles
    const title = `${value.label}: ${this.titles[key]}`
    this.onTopTitleChange(title, index)
  }

  onSubtitleChange(value: string, index: number, subtitleIndex: number): void {
    this.panels[index].subtitles[subtitleIndex] = value
  }

  onSubtitleSelect(
    value: ExploreMultiSelectExportPanelOption,
    index: number,
    subtitleIndex: number
  ): void {
    const key = value.value as keyof typeof this.titles
    const title = `${value.label}: ${this.titles[key]}`
    this.onSubtitleChange(title, index, subtitleIndex)
  }

  onAddSubtitle(index: number): void {
    const panels = [...this.panels]
    const subtitles = panels[index].subtitles ?? []
    subtitles.push('')
    panels[index] = {
      ...panels[index],
      subtitles,
    }
    this.panels = panels
  }

  onTableTitleChange(value: string, index: number): void {
    this.panels[index].tableTitle = value
  }

  onPanelSummaryViewChange(value: ExploreSummaryView, index: number): void {
    this.panels[index].summaryView = value
    const summaryExportGroup = this.summaryExportGroups.find(g => g.data && g.name === value.name)
    if (summaryExportGroup) {

    }
  }

  getLayoutStyle(
    parent: keyof ExploreMultiSelectExportStyles,
    item: string
  ): { [key: string]: string } {
    const section = this.defaultStyles[parent]
    if (section && typeof section === 'object' && item in section) {
      if (section && typeof section === 'object' && item in section) {
        const style = section[item as keyof typeof section]
        if (typeof style === 'object' && style !== null) {
          return style as unknown as { [key: string]: string }
        }
      }
    } else {
      return {}
    }
  }

  onChartGridChange(
    chartGrid: ExploreMultiSelectExportPanelChartOption,
    index: number
  ): void {
    const chartGridOptions = Array.from(
      { length: chartGrid.total },
      (_, index) => ({
        index,
        chart: undefined,
        metric: undefined,
        split: null,
        size: null,
        loading: false,
        highlight: false,
        data: undefined,
        vertSplits: [],
      })
    )
    chartGridOptions.forEach((_, i) => {
      const key = this.getIndexedTitle(i, 'Chart')
      this.visibilityMap[key] = false
    })
    this.panels[index] = {
      ...this.panels[index],
      chartGrid,
      chartGridOptions,
    }
  }

  onChartGridMetricChange(
    value: SummaryChartAxis,
    index: number,
    chartIndex: number
  ): void {
    this.panels[index].chartGridOptions[chartIndex].metric = value
  }

  onChartGridChartTypeChange(
    value: SummaryChartInfo,
    index: number,
    chartIndex: number
  ): void {
    this.panels[index].chartGridOptions[chartIndex].chart = value
  }

  getChartHeight(grid: string): { [key: string]: string } {
    const base = grid.split(' x ')[0]
    const divide = grid.split(' x ')[1]
    if (base === '1') {
      return { height: `${50 / Number(divide)}%` }
    } else {
      return { height: '50%' }
    }
  }

  onAddPanel(panel?: ExploreMultiSelectExportPanel): void {
    const panels = [...this.panels]
    const newPanel = panel ?? this.defaultPanel
    panels.push(newPanel)
    this.panels.forEach((_, i) => {
      this.visibilityMap[this.getIndexedTitle(i, 'Panel')] = false
    })
    this.visibilityMap[this.getIndexedTitle(panels.length - 1, 'Panel')] = true
    this.panels = panels
  }

  toggleDarkMode(): void {
    this.darkMode = !this.darkMode
  }

  onSelectedSummaryViewChange(view: ExploreSummaryView): void {
    this.selectedSummaryView = view
    this.defaultPanel = {
      ...EMPTY_EXPLORE_MULTI_SELECT_EXPORT_PANEL,
      summaryView: this.selectedSummaryView,
    }
  }

  onPreviewClick(): void {
    this.previewOpen = true
    if (
      !this.summaryExportGroups ||
      this.summaryExportGroups.length === 0 ||
      this.summaryExportGroups.some(g => !g.data)
    ) {
      this.onGetSummaryExportGroups()
    } else {
      this.generateLayoutPanels()
    }
  }

  onGetSummaryExportGroups(): void {
    const groupNames: string[] = []
    const summaryExportGroups: SummaryExportGroup[] = this.panels
      .filter(panel => !!panel.summaryView)
      .map(panel => {
        const { summaryView } = panel
        const { name } = summaryView
        if (!groupNames.includes(name)) {
          const viewSummaryFilterMap = updateSelectedFilterMap(
            this.summaryFilterMap,
            summaryView.filters,
            false
          )
          const filteredLossSets = filterLossSets(
            this.lossSetLayers,
            viewSummaryFilterMap
          )
          const rows = getRowsFromLossSets(
            filteredLossSets,
            this.summaryData,
            summaryView.modeling.filter(x => x !== ''),
            true
          )
          const groups = getGroupedSummaryRequest(
            rows,
            summaryView.modeling.filter(x => x !== ''),
            filteredLossSets
          )
          groupNames.push(name)
          if (name && groups) {
            return {
              name,
              groups,
              filteredLossSets,
              rows,
              viewSummaryFilterMap
            }
          }
        }
        return undefined
      })
      .filter(Boolean)
    this.getSummaryExportGroups.emit(summaryExportGroups.filter(g => !!g.name))
  }

  onUpdatePreviewMode(): void {
    this.previewOpen = false
    this.layoutPanels = []
  }

  onCopyClick(index: number): void {
    const panel = {...this.panels[index]}
    this.onAddPanel(panel)
  }

  onDeletePanel(index: number): void {
    this.panels = this.panels.filter((_, i) => i !== index)
  }
}
