import { CurrencyPipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  SwiperConfigInterface,
  SwiperModule,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper-v-13'
import { SharedModule } from '@shared/shared.module'
import { TowerComponent } from './tower-component/tower.component'
import { TowerContainerComponent } from './tower.container'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
}

@NgModule({
  declarations: [TowerComponent, TowerContainerComponent],
  exports: [TowerComponent, TowerContainerComponent],
  imports: [SharedModule, HttpClientModule, FormsModule, SwiperModule],
  providers: [
    CurrencyPipe,
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    ShortNumberPipe,
  ],
})
export class TowerModule {}
