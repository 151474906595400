import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { values as _values } from 'ramda'
import { rejectNil } from '../../../shared/util/operators'
import { reduceReducers } from '../../../shared/util/reduce-reducers'
import { isAgg, OptimizationCandidateLayer } from '../optimization.model'
import * as fromOptimizationLayersActions from './optimization-layers.actions'
import * as fromOptimizationActions from './optimization.actions'

interface ExtendedState {
  loading: boolean
  error: string | null
  metricsLoading: boolean
  metricsError: string | null
}

export type State = EntityState<OptimizationCandidateLayer> & ExtendedState

export const adapter = createEntityAdapter<OptimizationCandidateLayer>()

const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
  metricsLoading: false,
  metricsError: null,
})

const layersReducer = createReducer(
  initialState,
  on(fromOptimizationLayersActions.generateLayers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromOptimizationLayersActions.generateLayersSuccess,
    (state, { values }) => {
      return adapter.setAll(values, { ...state, loading: false, error: null })
    }
  ),
  on(
    fromOptimizationLayersActions.generateLayersFailure,
    (state, { error }) => ({ ...state, loading: false, error: error.message })
  ),
  on(fromOptimizationActions.reset, () => ({ ...initialState })),
  on(fromOptimizationLayersActions.updateLayers, (state, { changes }) => {
    return adapter.updateMany(changes, state)
  }),
  on(fromOptimizationLayersActions.getLayersMetrics, state => ({
    ...state,
    metricsLoading: true,
    metricsError: null,
  })),
  on(
    fromOptimizationLayersActions.getLayersMetricsFailure,
    (state, { error }) => ({
      ...state,
      metricsLoading: false,
      metricsError: error.message,
    })
  ),
  on(
    fromOptimizationLayersActions.getLayersMetricsSuccess,
    (state, { changes }) =>
      adapter.updateMany(changes, {
        ...state,
        metricsError: null,
        metricsLoading: false,
      })
  )
)

const layersAggFeederReducer = createReducer(
  initialState,
  on(fromOptimizationLayersActions.updateLayers, (state, { changes }) => {
    if (changes.length === 1) {
      if (
        // agg layer optimization. Update feeder group properties
        changes[0].changes.group &&
        isAgg(_values(state.entities)[0] as OptimizationCandidateLayer)
      ) {
        const groupID = changes[0].changes.group as string
        const groupMembers = rejectNil(_values(state.entities)).filter(
          m => m.group === groupID
        )
        if (groupMembers.length > 0) {
          const updates = groupMembers.map(g => ({
            id: g.id,
            changes: {
              lossSetGroupID: groupMembers[0].lossSetGroupID,
              feederOccurrenceLimit: groupMembers[0].feederOccurrenceLimit,
              feederOccurrenceAttachment:
                groupMembers[0].feederOccurrenceAttachment,
              feederFranchiseDeductible:
                groupMembers[0].feederFranchiseDeductible,
              feederParticipation: groupMembers[0].feederParticipation,
            },
          }))
          return adapter.updateMany(updates, state)
        } else {
          return state
        }
      } else if (
        isAgg(_values(state.entities)[0] as OptimizationCandidateLayer) &&
        changes[0].changes.group === ''
      ) {
        return state
      } else if (
        (isAgg(_values(state.entities)[0] as OptimizationCandidateLayer) &&
          (changes[0].changes.feederOccurrenceLimit != null ||
            changes[0].changes.feederOccurrenceAttachment != null ||
            changes[0].changes.feederFranchiseDeductible != null ||
            changes[0].changes.feederParticipation != null)) ||
        changes[0].changes.lossSetGroupID != null
      ) {
        const candidateLayers = rejectNil(_values(state.entities))
        const changedLayer = candidateLayers.find(l => l.id === changes[0].id)
        if (changedLayer?.group) {
          const groupMembers = candidateLayers.filter(
            m => m.group === changedLayer.group
          )
          if (groupMembers.length > 1) {
            const updates = groupMembers.map(g => ({
              id: g.id,
              changes: {
                lossSetGroupID: groupMembers[0].lossSetGroupID,
                feederOccurrenceLimit: changedLayer.feederOccurrenceLimit,
                feederOccurrenceAttachment:
                  changedLayer.feederOccurrenceAttachment,
                feederFranchiseDeductible:
                  changedLayer.feederFranchiseDeductible,
                feederParticipation: changedLayer.feederParticipation,
              },
            }))
            return adapter.updateMany(updates, state)
          } else {
            return state
          }
        } else {
          return state
        }
      } else {
        return state
      }
    } else {
      return state
    }
  })
)

const layersGroupAddedReducer = createReducer(
  initialState,
  on(fromOptimizationLayersActions.updateLayers, (state, { changes }) => {
    if (changes.length === 1) {
      if (changes[0].changes.group) {
        return adapter.updateOne(
          { ...changes[0], changes: { ...changes[0].changes, include: true } },
          state
        )
      } else {
        return state
      }
    } else {
      return state
    }
  })
)

const _reducer = reduceReducers(
  layersReducer,
  layersAggFeederReducer,
  layersGroupAddedReducer
)

export function reducer(state: State | undefined, action: Action) {
  return _reducer(state, action)
}
