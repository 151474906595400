import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Dictionary } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { AppState } from '../../../core/store'
import {
  setCurrentStructure,
  setCurrentStudy,
} from '../../../core/store/broker/broker.actions'
import { ClientYear } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { rejectNil } from '../../../shared/util/operators'
import {
  InuranceTag,
  InuranceTagsByID,
  Terminal,
} from '../../model/inurance.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import {
  GroupBar,
  GrouperSlide,
  GroupScenariosDialogData,
  GroupScenariosModel,
  ProgramGroup,
  UNTITLED_GROUP_LABEL,
} from '../../store/grouper/program-group.model'
import { AddProgramGroupProps } from '../../store/grouper/program-group/program-group.actions'
import { ProgramGroupEntity } from '../../store/grouper/program-group/program-group.reducer'
import {
  AddProgramProps,
  MoveProgramProps,
} from '../../store/grouper/program/program.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-tower-footer',
  styleUrls: ['./group-tower-footer.component.scss'],
  templateUrl: './group-tower-footer.component.html',
})
export class GroupTowerFooterComponent {
  @Input() dirty: boolean
  @Input() slide: GrouperSlide
  @Input() years: readonly ClientYear[]
  @Input() programsByID: Dictionary<Program>
  @Input() selectedProgramIDs?: readonly string[]
  @Input() name: string
  @Input() analysisProfileID: string | null
  @Input() groupsByID: Dictionary<ProgramGroupEntity>
  @Input() groupBarsByID: Record<string, GroupBar>
  @Input() groups: ProgramGroup[] = []
  @Input() inuranceTagsByProgramID: InuranceTagsByID = {}
  @Input() inuranceTagsByProgramGroupID: InuranceTagsByID = {}
  @Input() minimizeProgram: boolean
  @Input() programGroups: readonly ProgramGroup[]
  @Input() groupScenariosByID: Record<string, GroupScenariosModel>
  @Input() ifLibreGroup: []
  @Input() set selectMode(value: boolean) {
    this._selectMode = coerceBooleanProperty(value)
  }
  get selectMode() {
    return this._selectMode
  }
  @HostBinding('class.select-mode') _selectMode = false

  @Input() set showMinimizedGroupBar(value: boolean) {
    this._showMinimizedGroupBar = coerceBooleanProperty(value)
  }
  get showMinimizedGroupBar() {
    return this._showMinimizedGroupBar
  }
  @HostBinding('class.minimized-group') _showMinimizedGroupBar = true

  @Input() set hideName(value: boolean) {
    this._hideName = coerceBooleanProperty(value)
  }
  get hideName() {
    return this._hideName
  }
  @HostBinding('class.hide-name') _hideName = true

  @Output() programBarClick = new EventEmitter<void>()
  @Output() programGroupBarClick = new EventEmitter<
    ProgramGroupEntity | undefined
  >()
  @Output() programGroupAdd = new EventEmitter<AddProgramGroupProps>()
  @Output() programGroupRemove = new EventEmitter<string>()
  @Output() programGroupMinimizeToggle = new EventEmitter<string>()
  @Output() programAdd = new EventEmitter<AddProgramProps>()
  @Output() programRemove = new EventEmitter<void>()
  @Output() programMove = new EventEmitter<MoveProgramProps>()
  @Output() saveUntitled = new EventEmitter<AddProgramGroupProps>()
  @Output() inuranceTagClick = new EventEmitter<InuranceTag>()
  @Output() animatedScenarios = new EventEmitter<{ groupID: string }>()
  @Output() groupLayerDetails = new EventEmitter<{
    groupID: string
    groupName: string
  }>()
  @Output() groupScenariosClick = new EventEmitter<GroupScenariosDialogData>()
  @Output() programGroupDelete = new EventEmitter<GroupBar>()
  @Output() programGroupRename = new EventEmitter<string>()

  get showTag(): boolean {
    return !(
      this.minimizeProgram || this.groupBarsByID[this.slide.groupID].minimized
    )
  }

  get groupBars() {
    let bars: Array<GroupBar | null> = []
    if (this.slide && this.slide.groupBars) {
      const firstGroupBar = this.slide.groupBars[0]
      if (this.showMinimizedGroupBar) {
        const bar = this.deepestGroupBar
        bars = [
          ...this.slide.groupBars
            .map(gb => (gb != null ? gb : bar))
            .filter(gb => gb !== firstGroupBar),
        ]
      } else {
        bars = [...this.slide.groupBars.filter(gb => gb !== firstGroupBar)]
      }
    }
    return bars.reverse()
  }

  get deepestGroupBar(): GroupBar | null {
    const bars = this.slide.groupBars.filter(gb => gb != null)
    if (bars.length > 0) {
      return bars[bars.length - 1]
    }
    return null
  }

  get isDisabledMoveToGroup(): boolean {
    const group = this.groups.filter(g => g.id !== this.slide.groupID)
    return !(group && group.length > 0) || this.hasGroupScenarios
  }

  get leftEdges() {
    return (this.deepestGroupBar && this.deepestGroupBar.leftEdges) || []
  }

  get rightEdges() {
    return (this.deepestGroupBar && this.deepestGroupBar.rightEdges) || []
  }

  get inuranceTags(): InuranceTag[] {
    return (
      (this.slide &&
        this.slide.programID &&
        this.inuranceTagsByProgramID[this.slide.programID]) ||
      []
    )
  }

  get showMoveLeft(): boolean {
    return (
      this.slide.moveLeftGroupID != null && this.slide.moveLeftOrdinal != null
    )
  }

  get showMoveRight(): boolean {
    return (
      this.slide.moveRightGroupID != null && this.slide.moveRightOrdinal != null
    )
  }

  get showMoveToGroup(): boolean {
    return this.groups.length > 0
  }

  get showMoreButton(): boolean {
    return (
      !this.selectMode &&
      (this.showMoveLeft || this.showMoveRight || this.showMoveToGroup)
    )
  }

  get hasGroupScenarios(): boolean {
    const groupScenarios = rejectNil(
      this.groupBars.map(bar => bar && this.groupScenariosByID[bar.id])
    )
    return groupScenarios.some(gs => gs.groupScenarios.length > 1)
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  getGroupLabel(group: ProgramGroup): string {
    return group.label === UNTITLED_GROUP_LABEL ? 'New Group' : group.label
  }

  getInuranceTags(terminal: Terminal): InuranceTag[] {
    return this.inuranceTags.filter(v => v.terminal === terminal)
  }

  isGroupMinimizedBottom(bar: GroupBar | null, index: number): boolean {
    if (bar != null && bar.minimized) {
      const bars = this.groupBars
      const i = bars.lastIndexOf(bar)
      return i === index
    }
    return false
  }

  allowGroupClick(id?: string): boolean {
    const group = id && this.groupsByID[id]
    return (group && extractPortfolioSetID(group.programGroup)) != null
  }

  onBarClick(id?: string) {
    if (this.allowGroupClick(id)) {
      const group = (id && this.groupsByID[id]) || undefined
      if (group) {
        this.programGroupBarClick.emit(group)
      }
    }
  }

  onRemoveClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    $event.stopPropagation()
    this.programRemove.emit()
  }

  onMoveLeft() {
    if (!this.hasGroupScenarios) {
      const id = this.slide.programID || this.slide.id
      const fromGroupID = this.slide.groupID
      const toGroupID = this.slide.moveLeftGroupID
      const ordinal = this.slide.moveLeftOrdinal
      if (!toGroupID || !ordinal) {
        console.error('Cannot move left without a to group ID or ordinal')
        return
      }
      this.programMove.emit({ id, fromGroupID, toGroupID, ordinal })
    }
  }

  onMoveRight() {
    if (!this.hasGroupScenarios) {
      const id = this.slide.programID || this.slide.id
      const fromGroupID = this.slide.groupID
      const toGroupID = this.slide.moveRightGroupID
      const ordinal = this.slide.moveRightOrdinal
      if (!toGroupID || !ordinal) {
        console.error('Cannot move left without a to group ID or ordinal')
        return
      }
      this.programMove.emit({ id, fromGroupID, toGroupID, ordinal })
    }
  }

  onMoveToGroup(programGroupID: string) {
    const id = this.slide.programID || this.slide.id
    const fromGroupID = this.slide.groupID
    const toGroupID = programGroupID
    const group = this.groupBarsByID[programGroupID]
    if (!group) {
      console.error('Cannot move to group with empty group bar')
      return
    }
    const ordinal =
      group.firstIndex > this.slide.index
        ? group.addLeftOrdinal
        : group.addRightOrdinal
    this.programMove.emit({ id, fromGroupID, toGroupID, ordinal })
  }

  onDesignClick(slide: GrouperSlide) {
    if (slide.programID == null) {
      console.error('Cannot navigate to design, structure ID is empty.')
      return
    }
    const programSelected = this.programsByID[slide.programID]
    let clientID: string
    let programID: string
    let yearID: string
    if (programSelected) {
      this.route.params.subscribe(data => {
        clientID = data.clientID
        yearID = data.yearID
        if (data.programID !== programSelected.studyID) {
          // to update Design page title route
          this.store.dispatch(setCurrentStudy({ id: programSelected.studyID }))
        }
        programID = programSelected.studyID
        const portfolioSetID = extractPortfolioSetID(programSelected)
        if (!portfolioSetID) {
          throw new Error(
            'Unexpected Error: Selected Structure has no Analysis Profile ID or Portfolio IDs.'
          )
        }
        const { analysisProfileID, ...portfolioIDs } = portfolioSetID
        this.router
          .navigate([
            'clients',
            clientID,
            'years',
            yearID,
            'programs',
            programID,
            'structure',
            slide.programID,
            'analysis',
            programSelected.analysisID,
            'portfolios',
            JSON.stringify(portfolioIDs),
          ])
          .then(() => {
            this.store.dispatch(setCurrentStructure({ id: programSelected.id }))
          })
      })
    }
  }

  trackByID(index: number, group: ProgramGroup | undefined) {
    return (group && group.id) || index
  }

  onSaveUntitled(slide: GrouperSlide) {
    if (!this.hasGroupScenarios) {
      const id = slide.programID
      if (id) {
        this.saveUntitled.emit({ id, parentGroupID: slide.groupID })
      }
    }
  }

  trackByTag(index: number, view: InuranceTag) {
    return (view && `${view.symbol}_${view.terminal}`) || index
  }
}
