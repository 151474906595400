<ng-container *ngIf="!reinsurer.is_default">
  <app-reinsurer-banner
    [ngClass]="getCardTypeStyle(reinsurer)"
    [reinsurer]="reinsurer"
    [programs]="programs"
    [metricDetails]="metricDetails"
    [programID]="programID"
    [accountOpportunities]="accountOpportunities"
    [duplicateReinsurers]="duplicateReinsurers"
    (showInfo)="showInfo.emit($event)"
    (showFundManagerModal)="showFundManagerModal.emit($event)"
  >
  </app-reinsurer-banner>
</ng-container>
