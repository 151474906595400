import {
  PricingCurveData,
  PricingCurveDatum,
  PricingCurveGraphSettings,
} from '../../model/pricing-curve.model'

export type TabStatus = {
  layers: boolean
  manual: boolean
  saved: boolean
}

export interface PricingCurveBaseDialogOptions {
  width: string
  minWidth: string
  maxWidth: string
  height: string
  minHeight?: string
  maxHeight?: string
  disableClose: boolean
  autoFocus?: boolean
}

export type PricingCurveAddDataDialogPayload = {
  isEdit: boolean
  isQuickEdit?: boolean
  pricingCurveInitialState?: PricingCurveData
  id?: number
  tabsToHide: TabStatus
  startingTab: 'layers' | 'manual' | 'saved'
}

export type PricingCurveExcludeLayerDialogPayload = {
  datum: PricingCurveDatum // The layer point that was clicked
  curve: PricingCurveData // The pricing curve that is being changed
  currentDataSetNames: { id: number; name: string }[] // An array of all dataSet names that are included on the graph
}

export type PricingCurveDataSettingsDialogPayload = {
  layersVisible: boolean
  lineVisible: boolean
  hasLayers: boolean
  hasLine: boolean
}

export type PricingCurveGraphSettingsDialogPayload = {
  currentGraphSettings: PricingCurveGraphSettings
}

export type PricingCurveCarrierPermissionsDialogPayload = {
  carriers: string[]
}

export const addDataDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  minWidth: '75vw',
  width: '90vw',
  maxWidth: '90vw',
  height: '90vh',
  disableClose: true,
  ...options,
})

export const exportPreviewDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  minWidth: '75vw',
  width: '90vw',
  maxWidth: '90vw',
  height: '90vh',
  disableClose: true,
  autoFocus: false,
  ...options,
})

export const excludeLayerDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  width: '60vw',
  minWidth: '60vw',
  maxWidth: '80vw',
  height: '42vh',
  minHeight: '42vh',
  maxHeight: '42vh',
  disableClose: true,
  ...options,
})

export const settingsDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  width: '300px',
  minWidth: '300px',
  maxWidth: '300px',
  height: '250px',
  disableClose: true,
  ...options,
})

export const graphSettingsDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  width: '45vw',
  minWidth: '45vw',
  maxWidth: '45vw',
  height: '45vh',
  disableClose: true,
  ...options,
})

export const carrierPermissionsDefaults = (
  options?: Partial<PricingCurveBaseDialogOptions>
): PricingCurveBaseDialogOptions => ({
  width: '25vw',
  minWidth: '25vw',
  maxWidth: '25vw',
  height: '25vh',
  disableClose: true,
  ...options,
})
