import {
  BenchmarkEntityPair,
  getSortedBenchmarkEntityPairs,
} from '../../api/benchmark/benchmark-entity-sorter'
import { HasName } from '../../core/model/general.model'
import { BenchmarkAbbreviationsState } from './benchmark-abbreviation'
import { BenchmarkChartView } from './benchmark-chart-view'
import { isBenchmarkOptionTrue } from './benchmark-options'
import { BenchmarkEntityFilterType, BenchmarkPeerSet } from './benchmark.model'

export type BenchmarkEntityNameFormatter = (
  id: string | number,
  options?: { disableAbbreviation?: boolean }
) => string

export function createEntityNameFormatter(
  view: BenchmarkChartView,
  abbreviations?: BenchmarkAbbreviationsState
): BenchmarkEntityNameFormatter {
  const options = view.options ?? []
  // Get list & dictionary of all companies and their corresponding peer set
  const combinePeerSets = isBenchmarkOptionTrue(options, 'combinePeerSets')
  const sortedCompanyPeerSets = getSortedBenchmarkEntityPairs(view, {
    combinePeerSets,
  })
  const pairsByID = sortedCompanyPeerSets.reduce((acc, pair) => {
    const id = pair.company?.id ?? pair.peerSet?.id
    if (id != null) {
      acc[id] = pair
    }
    return acc
  }, {} as Record<string | number, BenchmarkEntityPair>)

  const showLabel = isBenchmarkOptionTrue(options, 'pointLabel')

  return (id, opts = {}): string => {
    if (showLabel || view.companies?.[0]?.id === id) {
      if (!opts.disableAbbreviation) {
        const result = abbreviations?.byID[id]
        if (result) {
          return result.abbreviation
        }
      }
      const { company, peerSet } = pairsByID[id]
      return (
        company?.abbreviation ?? company?.name ?? peerSet?.name ?? String(id)
      )
    }
    return ''
  }
}

export function removeBenchmarkEntityNameSuffix(name = ''): string {
  return name.replace(' (SNL P&C Group)', '')
}

export function getBenchmarkTargetName(target?: HasName): string {
  const name = target?.name ?? 'Unnamed'
  return removeBenchmarkEntityNameSuffix(name)
}

export function getBenchmarkAllPeersName(
  peerSets: BenchmarkPeerSet[] = [],
  companies: HasName[] = []
): string {
  if (peerSets.length + companies.length === 1) {
    if (peerSets.length) {
      return peerSets[0].name
    } else if (companies.length) {
      return removeBenchmarkEntityNameSuffix(companies[0].name)
    }
  }
  return 'All Peers'
}

export function buildEntityFilterName(
  entityFilter?: BenchmarkEntityFilterType,
  companies: HasName[] = [],
  peerSets: BenchmarkPeerSet[] = []
): string | undefined {
  switch (entityFilter) {
    case 'target-industry':
    case 'target': {
      return getBenchmarkTargetName(companies?.[0])
    }
    case 'all-peers': {
      return getBenchmarkAllPeersName(peerSets, companies?.slice(1))
    }
    case 'target-and-peers': {
      const targetName = getBenchmarkTargetName(companies?.[0])
      const peers = companies?.slice(1)
      const peersName = getBenchmarkAllPeersName(peerSets, peers)
      return `${targetName} vs. ${peersName}`
    }
  }
}
