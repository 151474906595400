<app-header-title
  class="title"
  [name]="title"
  [subtitle]="subtitle"
  noLink
></app-header-title>
<section class="actions">
  <button (click)="closeClick.emit()" appButton huge link tabindex="-1">
    Close
  </button>
  <button (click)="resetClick.emit()" appButton huge link tabindex="-1">
    Reset
  </button>
  <button
    (click)="stepIndex > 0 && backClick.emit()"
    [disabled]="stepIndex === 0"
    appButton
    huge
    link
    tabindex="-1"
  >
    Back
  </button>
  <button
    *ngIf="actionName"
    (click)="onActionClick()"
    [disabled]="disableAction"
    appButton
    huge
    accent
    border
    tabindex="-1"
  >
    {{ actionName }}
  </button>
</section>
