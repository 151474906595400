import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'
import { State as ThemeState } from '../core/store/theme/theme.state.facade'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-theme',
  styles: [
    `
           :host {
             display: block;
             color: var(--body);
             background: var(--bg);
             height: 100vh;
             width: 100vw;
             overflow: hidden;
           }
         `,
  ],
  template: ` <ng-content></ng-content> `,
})
export class ThemeComponent {
  @Input() set dark(value: any) {
    this._dark = coerceBooleanProperty(value)
  }
  _dark: boolean | undefined

  @Input() theme: ThemeState

  @HostBinding('class.app-theme-dark') get isDark() {
    return this._dark != null
      ? this._dark
      : this.theme != null
      ? this.theme.dark
      : false
  }

  @HostBinding('class.app-theme-chrome') get isChrome() {
    return this.theme != null ? this.theme.chrome : false
  }

  @HostBinding('class.app-theme-user-card') get isUserCard() {
    return this.theme != null ? this.theme.userCard : false
  }

  @HostBinding('class.app-theme-layer-wireframe') get isLayerWireframe() {
    return this.theme != null ? this.theme.layerWireframe : false
  }

  @HostBinding('class.app-theme-logo-color') get isLogoColor() {
    return this.theme != null ? this.theme.logoColor : false
  }
}
