import { Selection } from 'd3-selection'
import { curry } from 'ramda'
import { resolveValueFn, ValueFn } from '@shared/util/value-fn'
import { D3fcLineSelection } from '../models/graphing.model'

const baseColor = 'var(--body)'
const baseColorInverse = 'var(--body-inverse)'
const baseOpacity = 'var(--alpha)'

export const decorateClass = curry(
  (className: string, sel: D3fcLineSelection): D3fcLineSelection => {
    if (sel.node()) {
      return sel.attr('class', `${sel.attr('class') ?? ''} ${className}`)
    }
    return sel
  }
)

export const decorateAttrWithOpacityAndDefault = curry(
  <T>(
    colorAttr: string,
    defaultColor: ValueFn<string, T>,
    opacity: ValueFn<string | number, T>,
    rgbClass: ValueFn<string, T>,
    sel: Selection<any, T, any, any>
  ) =>
    sel
      .enter()
      .attr('class', d => {
        const currentNonPaletteClasses = (sel.attr('class') ?? '')
          .split(' ')
          .filter(c => !c.startsWith('app-palette-'))
        const nextClass = resolveValueFn(rgbClass, d) ?? ''
        return `${currentNonPaletteClasses.join(' ')} ${nextClass}`
      })
      .attr(colorAttr, d => {
        if (resolveValueFn(rgbClass, d) == null) {
          return resolveValueFn(defaultColor, d) ?? ''
        }
        return `rgba(var(--rgb), ${resolveValueFn(opacity, d) ?? baseOpacity})`
      })
)

export interface DecorateStrokeAndFillOverrides {
  rgbClass?: string
  fill?: string
  fillOpacity?: string | number
  stroke?: string
  strokeOpacity?: string | number
}

export type DecorateStrokeAndFillOptions =
  | [string | undefined]
  | readonly [string | undefined, DecorateStrokeAndFillOverrides]

export const decorateStrokeAndFill = curry(
  <T>(
    options: ValueFn<DecorateStrokeAndFillOptions, T>,
    sel: Selection<any, T, any, any>
  ) => {
    sel
      .enter()
      .attr('class', d => {
        const currentNonPaletteClasses = (sel.attr('class') ?? '')
          .split(' ')
          .filter(c => !c.startsWith('app-palette-'))
        const [rgbClass] = resolveValueFn(options, d) ?? ''
        return `${currentNonPaletteClasses.join(' ')} ${rgbClass ?? ''}`
      })
      .attr('fill', d => {
        const [rgb, opts] = resolveValueFn(options, d)
        if (rgb == null) {
          return resolveValueFn(baseColor, d) ?? ''
        }
        if (opts?.fill) {
          return opts.fill
        }
        return `rgba(var(--rgb), ${opts?.fillOpacity ?? baseOpacity})`
      })
      .attr('stroke', d => {
        const [rgb, opts] = resolveValueFn(options, d)
        if (rgb == null) {
          return resolveValueFn(baseColorInverse, d) ?? ''
        }
        if (opts?.stroke) {
          return opts.stroke
        }
        return `rgba(var(--rgb), ${opts?.strokeOpacity ?? baseOpacity})`
      })
  }
)

export const decorateFillWithOpacityAndDefault = curry(
  <T>(
    defaultColor: ValueFn<string, T>,
    opacity: ValueFn<string | number, T>,
    rgbClass: ValueFn<string, T>,
    sel: Selection<any, T, any, any>
  ) =>
    decorateAttrWithOpacityAndDefault(
      'fill',
      defaultColor,
      opacity,
      rgbClass,
      sel
    )
)

export const decorateFillWithOpacity = curry(
  <T>(
    opacity: ValueFn<number, T>,
    rgbClass: ValueFn<string, T>,
    sel: Selection<any, T, any, any>
  ) => decorateFillWithOpacityAndDefault(baseColor, opacity, rgbClass, sel)
)

export const decorateFillWithDefault = curry(
  <T>(
    defaultColor: ValueFn<string, T>,
    rgbClass: ValueFn<string, T>,
    sel: Selection<any, T, any, any>
  ) =>
    decorateFillWithOpacityAndDefault(defaultColor, baseOpacity, rgbClass, sel)
)

export const decorateFill = curry(
  <T>(rgbClass: ValueFn<string, T>, sel: Selection<Element, T, Element, any>) =>
    decorateFillWithOpacityAndDefault(baseColor, baseOpacity, rgbClass, sel)
)

export const decorateStroke = curry(
  <T>(rgbClass: ValueFn<string, T>, sel: Selection<Element, T, Element, any>) =>
    decorateAttrWithOpacityAndDefault(
      'stroke',
      baseColorInverse,
      baseOpacity,
      rgbClass,
      sel
    )
)

export const decorateAttrs = curry(
  <T>(
    attrMap: Record<string, ValueFn<string | number | boolean | null, T>>,
    sel: Selection<any, T, any, any>
  ) =>
    Object.keys(attrMap).reduce(
      (acc, key) => acc.attr(key, d => resolveValueFn(attrMap[key], d)),
      sel
    )
)
