import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { LossSetLayer } from 'src/app/api/analyzere/analyzere.model'
import {
  CASUALTY_PALETTE_IDS,
  layerIds,
  PROPERTY_PALETTE_IDS,
} from 'src/app/analysis/model/layer-palette.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import {
  AssignedLines,
  MultiSectionMapping,
  MultiTotalExpectedForReinsurerVersion,
  QuoteCustomCompareView,
  QuoteExport,
  QuoteExportAssignedLines,
  QuoteReinsurer,
  ReinsurerPhases,
  Section,
  SubjectivityTracking,
  TotalExpectedForReinsurerVersion,
} from '../models/quote.model'
import getQuotePanelDefs, {
  QuotePanelDefResolved,
  SubjectivityTrackingColumnDef,
} from '../quote-panel/quote-panel-defs'
import { LayerView, LayerViewValues } from 'src/app/analysis/model/layer-view'
import { groupBy, path, prop, sum, uniqBy } from 'ramda'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { Injectable } from '@angular/core'
import {
  fotSummaryRowIds,
  QuoteExportModes,
  QuoteExportRowValue,
  QuoteExportSectionView,
  QuoteExportTermView,
  QuoteVersionListEntry,
} from './quote-excel.model'
import { FullDateToYear } from '@shared/pipes/full-date-to-year'
import layerMetricDefs from 'src/app/analysis/model/layer-metric-defs'
import { QuoteExcelUtils } from './quote-excel.utils'
import { QuoteExportAssignedLinesConverter } from './quote-export-assigned-lines.converter'
import QuoteExportRowsConverter from './quote-export-rows.converter'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import {
  isLayerDrop,
  isLayerTopOrDrop,
} from 'src/app/analysis/model/layers.util'

@Injectable({
  providedIn: 'root',
})
export class QuoteExportConverter {
  private layerState: LayerState[]
  private lossSetLayers: LossSetLayer[]
  private quotePanelDefs: QuotePanelDefResolved[]
  private expiringOpportunity: AccountOpportunity | undefined
  private currentOpportunity: AccountOpportunity | undefined
  private isSLSelected: boolean
  private isGroupSelected: boolean
  private view: LayerView | undefined
  private allViews: LayerView[] | undefined
  private rowsTracking: SubjectivityTracking[]
  private xolExport: boolean
  private sectionViews: QuoteExportSectionView[]
  private termViews: QuoteExportTermView[]
  private compareViewIdsBySectionView: number[] = []

  constructor(
    private utils: QuoteExcelUtils,
    private alConverter: QuoteExportAssignedLinesConverter
  ) {}

  export(
    layerState: LayerState[],
    view: LayerView | undefined,
    lossSetLayers: LossSetLayer[],
    quotePanelDefs: QuotePanelDefResolved[],
    expiringOpportunity: AccountOpportunity | undefined,
    currentOpportunity: AccountOpportunity | undefined,
    isSLSelected: boolean,
    isGroupSelected: boolean,
    rowsTracking: SubjectivityTracking[],
    allViews: LayerView[] | undefined,
    subjectivityTrackingColumnDef: SubjectivityTrackingColumnDef[],
    sectionViews: QuoteExportSectionView[],
    termViews: QuoteExportTermView[]
  ): QuoteExport {
    // Set export data members
    this.layerState = layerState
    this.view = view
    this.lossSetLayers = lossSetLayers
    this.quotePanelDefs = quotePanelDefs
    this.expiringOpportunity = expiringOpportunity
    this.currentOpportunity = currentOpportunity
    this.isSLSelected = isSLSelected
    this.isGroupSelected = isGroupSelected
    this.rowsTracking = rowsTracking
    this.allViews = allViews
    this.sectionViews = sectionViews
    this.termViews = termViews
    this.compareViewIdsBySectionView = []

    // Filter sectionViews to only those that are included and have reinsurers selected
    this.sectionViews = this.sectionViews.filter(
      sectionView =>
        (sectionView.sectionSelected ||
          !!sectionView.selectedCompareViewIds.length) &&
        !!sectionView.reinsurers.length
    )

    this.xolExport =
      !this.isSLSelected &&
      !this.isGroupSelected &&
      this.sectionViews.every(
        ({ section }) =>
          section.layerType === layerIds.catXl ||
          section.layerType === layerIds.noncatXl
      )

    const members: number[] = this.calculateLossSetsMembers()

    const headers: string[] = this.getExportSectionHeaders() // Names of each layer section i.e. ['Layer 1', 'Layer 2', 'Layer 3']
    const multiSectionMappings = this.getMultiSectionMappings() // Gets all multi section layer ids and main layer ids
    const sharedLimitIds = this.getSharedLimitLayerIds() // Gets all shared limit layer ids
    const expiringOppYear = this.expiringOpportunity?.opportunityInceptionDate
    const currentOppYear = this.currentOpportunity?.opportunityInceptionDate

    /* Split reinsurers by their phase - FOT, Expiring, or neither */
    const reinsurerListForExport = this.getReinsurerListSorted(
      [...this.sectionViews.map(({ reinsurers }) => reinsurers)].flat()
    )
    const expiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.Expiring
    )
    const nonFotAndExpiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport
    )
    const fotReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.FOT
    )

    /* Gets the total expected premium & loss values for each reinsurer version of a multi section layer, group and shared limit */
    const multiExpectedVals = this.getMultiSectionTotalExpectedValues(
      nonFotAndExpiringReinsurers,
      multiSectionMappings
    )
    const groupExpectedVals = this.getGroupTotalExpectedValues(
      nonFotAndExpiringReinsurers
    )
    const sharedExpectedVals = this.getSharedLimitExpectedValues(
      nonFotAndExpiringReinsurers,
      sharedLimitIds
    )

    /* Iterate through each filtered section and add export columns/rows for each reinsurer (by phase - FOT, Expiring, etc.) */
    const descriptionColumn: string[] = ['Reinsurer']
    const descriptionRows: string[][][] = []
    const finalExpiringColumns: string[][] = []
    const finalExpiringRows: QuoteExportRowValue[][][][] = []
    const finalNonFotAndExpiringColumns: string[][] = []
    const finalNonFotAndExpiringRows: QuoteExportRowValue[][][][] = []
    const finalFotColumns: string[][] = []
    const finalFotRows: QuoteExportRowValue[][][][] = []
    const quotesSignedPercRow: number[][] = []
    /* Iterate through each filtered section and add assigned lines columns/rows  */
    const assignedLinesData: QuoteExportAssignedLines[][][] = []
    const assignedLinesColumns: string[] = !this.xolExport
      ? [' ', ' ', ' ', ' ']
      : [' '] // Assigned lines columns should start after reinsurer and tpref values in tab
    const assignedLinesRe: string[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesRows: QuoteExportRowValue[][][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    const sectionCurrencies: string[] = []

    const [subjectPremiumRows, xolLayerIndexMappings] =
      this.getXOLSubjectPremiumRowsForStructure(reinsurerListForExport)

    const xolSubjectPremiumRows: QuoteExportRowValue[][][] = [
      ...subjectPremiumRows,
    ]

    // reinsurers need to be displayed in a logical order, handling missing versions/reinsurers between layers
    const nonExpiringOrFotReinsurersBySection: ReinsurerState[][] =
      this.sectionViews.map(sectionView =>
        uniqBy(
          ({ reinsurer }: ReinsurerState) =>
            `${reinsurer.tpRef}-${reinsurer.reinsurerPhaseVersion}`
        )(this.getReinsurersByType(sectionView.reinsurers))
      )

    // The column order for non fot or expiring versions by tpRef and version
    const overallNonExpiringOrFotReinsurerVersionList: QuoteVersionListEntry[] =
      []

    nonExpiringOrFotReinsurersBySection.forEach((reinsurers, i) => {
      // The first section should always be the base order, any other reinsurers will get added after
      if (i === 0) {
        const sectionEntries = reinsurers.map(({ reinsurer }) => ({
          tpRef: reinsurer.tpRef,
          reinsurerPhaseVersion: Number(reinsurer.reinsurerPhaseVersion),
          quoteReinsurerName: reinsurer.quoteReinsurerName,
        }))
        overallNonExpiringOrFotReinsurerVersionList.push(...sectionEntries)
      } else {
        // Find all reinsurers and versions that don't exist in the order already
        const newReinsurersOrVersions = reinsurers.filter(
          re =>
            !overallNonExpiringOrFotReinsurerVersionList.some(
              version =>
                version.tpRef === re.reinsurer.tpRef &&
                version.reinsurerPhaseVersion ===
                  Number(re.reinsurer.reinsurerPhaseVersion)
            )
        )
        newReinsurersOrVersions.forEach(({ reinsurer }) => {
          const newItem = {
            tpRef: reinsurer.tpRef,
            reinsurerPhaseVersion: Number(reinsurer.reinsurerPhaseVersion),
            quoteReinsurerName: reinsurer.quoteReinsurerName,
          }
          if (
            overallNonExpiringOrFotReinsurerVersionList.some(
              val => val.tpRef === reinsurer.tpRef
            )
          ) {
            // Version of an existing reinsurer that hasn't existed, add after last version in existing list
            const lastVersionForTpRef = [
              ...overallNonExpiringOrFotReinsurerVersionList,
            ]
              .reverse()
              .find(val => val.tpRef === reinsurer.tpRef)
            const lastIndex =
              overallNonExpiringOrFotReinsurerVersionList.indexOf(
                lastVersionForTpRef
              )
            overallNonExpiringOrFotReinsurerVersionList.splice(
              lastIndex + 1,
              0,
              newItem
            )
          } else {
            // New reinsurer, append to end of order
            overallNonExpiringOrFotReinsurerVersionList.push(newItem)
          }
        })
      }
    })

    let iteration = 0
    const getReinsurersForView = (
      view: QuoteExportSectionView,
      s: Section
    ): ReinsurerState[] => {
      if (view.sectionSelected) {
        return view.reinsurers.filter(
          ({ reinsurer }) => reinsurer.cededlayerID === s.layerRef
        )
      }
      const comparisonId = this.compareViewIdsBySectionView[iteration]
      const comparison = view.compareViews.find(
        compareView => compareView.id === comparisonId
      )
      return view.reinsurers.filter(({ reinsurer }) =>
        comparison.members.includes(Number(reinsurer.id))
      )
    }

    const formattedSections = this.sectionViews
      .map(view => this.getSectionsForSectionView(view, true))
      .flat()

    this.sectionViews.forEach(view => {
      // Get the sections that need to be iterated through for each section view
      const sectionIterations = this.getSectionsForSectionView(view)
      sectionIterations.forEach((s, j) => {
        const reinsurersForIteration = getReinsurersForView(view, s)
        const termView = this.termViews.find(({ layerTypes }) =>
          layerTypes.includes(s.layerType)
        )
        const exportQuotePanelDefs = this.getQuotePanelDefsForSection(s) // gets all the selected terms for this layer's quote panels
        let sectionRows = this.getSectionRows(
          s,
          exportQuotePanelDefs,
          view,
          termView
        )

        sectionCurrencies.push(s.layerCurrency)

        // Add total expected ceded loss/premium
        if (!this.xolExport) {
          Object.values(exportQuotePanelDefs).forEach(element => {
            if (
              element.id === 'totalQuoteExpectedCededLoss' ||
              element.id === 'totalQuoteExpectedCededPremium'
            ) {
              sectionRows = [...sectionRows, element]
            }
          })
        }
        const descRows = [...this.getDescriptionRowsForSection(sectionRows)]
        descriptionRows.push(descRows) // gets all the export metrics for this section (i.e. Subject Premium, etc.)

        /* Get non fot or expiring rows & columns for this section and append to arrays */
        const exportRowConverter = new QuoteExportRowsConverter(
          this.isGroupSelected,
          this.isSLSelected,
          this.utils,
          this.view,
          this.xolExport
        )
        const [nonFotOrExpiringRows, nonFotOrExpiringColumns] =
          exportRowConverter.getNonFotOrExpiringColumnsAndRowsForSection(
            s,
            sectionRows,
            nonFotAndExpiringReinsurers,
            multiExpectedVals,
            groupExpectedVals,
            sharedExpectedVals,
            overallNonExpiringOrFotReinsurerVersionList,
            this.getReinsurersByType(reinsurersForIteration)
          )
        if (nonFotOrExpiringRows.length > 0) {
          finalNonFotAndExpiringColumns.push(nonFotOrExpiringColumns)
          finalNonFotAndExpiringRows.push(nonFotOrExpiringRows)
        }

        /* Get expiring rows & columns for this section and append to finalExpiring arrays */
        const [expiringRows, expiringColumns] =
          this.getFotOrExpiringColumnsAndRowsForSection(
            s,
            sectionRows,
            this.getReinsurersByType(
              reinsurersForIteration,
              ReinsurerPhases.Expiring
            ),
            multiExpectedVals,
            groupExpectedVals,
            sharedExpectedVals
          )
        if (expiringRows.length > 0) {
          finalExpiringColumns.push(expiringColumns)
          finalExpiringRows.push(expiringRows)
        }
        if (finalNonFotAndExpiringRows.length > finalExpiringRows.length) {
          /* Need to add empty value to match section row/column sizes */
          finalExpiringColumns.push([])
          finalExpiringRows.push([])
        }

        /* Get FOT rows & columns for this section and append to finalFot arrays */
        const [fotRows, fotColumns] =
          this.getFotOrExpiringColumnsAndRowsForSection(
            s,
            sectionRows,
            this.getReinsurersByType(
              reinsurersForIteration,
              ReinsurerPhases.FOT
            )
          )
        if (fotRows.length > 0) {
          finalFotColumns.push(fotColumns)
          finalFotRows.push(fotRows)
        }
        if (finalNonFotAndExpiringRows.length > finalFotRows.length) {
          /* Need to add empty value to match section row/column sizes */
          finalFotColumns.push([])
          finalFotRows.push([])
        }

        // Get latest FOTs for assigned lines for export
        const latestFOTTemp = [...fotReinsurers]
        latestFOTTemp.reverse()
        const latestFOTs: ReinsurerState[] = []
        latestFOTTemp.forEach((lf, index) => {
          if (index === 0 || (lf && !latestFOTs.includes(lf))) {
            latestFOTs.push(lf)
          }
        })

        /* Update assigned lines data of export for this section */
        const [
          sectionAlFOTData,
          sectionAlRe,
          sectionAlTpRef,
          sectionAlSubRows,
        ] = this.getAssignedLinesColumnsAndRowsForSection(
          s,
          this.getReinsurersByType(
            reinsurersForIteration,
            ReinsurerPhases.FOT
          ).reverse(),
          formattedSections
        )

        const [
          sectionAlExpiringData,
          sectionAlReExpiring,
          sectionAlTpRefExpiring,
        ] = this.getAssignedLinesColumnsAndRowsForSection(
          s,
          this.getReinsurersByType(
            reinsurersForIteration,
            ReinsurerPhases.Expiring
          ),
          formattedSections
        )
        const fotExpiringLayerSet = [
          ...sectionAlExpiringData,
          ...sectionAlFOTData,
        ]
        const percRow = this.getReducedExpiringForSection(
          s,
          this.getReinsurersByType(
            reinsurersForIteration,
            ReinsurerPhases.Expiring
          )
        )
        quotesSignedPercRow.push(percRow)
        assignedLinesData.push(fotExpiringLayerSet)

        if (j === 0) {
          assignedLinesRe.push(...sectionAlRe)
          assignedLinesTpRef.push(...sectionAlTpRef)
        }
        const deDupSectionRe = [...sectionAlRe, ...sectionAlReExpiring]
        deDupSectionRe.forEach(sectionRe => {
          const [, reinsurerName] = sectionRe
          if (!assignedLinesRe.some(val2 => val2[1] === reinsurerName)) {
            assignedLinesRe.push(sectionRe)
          }
        })
        const deDuptpRef = [...sectionAlTpRef, ...sectionAlTpRefExpiring]
        deDuptpRef.forEach(tpRef => {
          const [agencyTPRef, agencySeqNumber] = tpRef
          if (
            !assignedLinesTpRef.some(
              val2 => val2[0] === agencyTPRef && val2[1] === agencySeqNumber
            )
          ) {
            assignedLinesTpRef.push(tpRef)
          }
        })

        assignedLinesSubRows.push(...sectionAlSubRows)
        /* Get final assigned columns */
        // ntd combine expiring and current fots
        if (this.utils.findLongestArrayLength(fotExpiringLayerSet) > 0) {
          assignedLinesColumns.push(
            ...this.alConverter.getAssignedLineColumns(
              !this.xolExport,
              fotExpiringLayerSet,
              expiringOppYear,
              currentOppYear,
              s,
              expiringReinsurers,
              this.layerState,
              formattedSections,
              formattedSections.slice(0, iteration),
              this.isGroupSelected || this.isSLSelected
            )
          )
        }
        iteration++
      })
    })
    /* Get final assigned lines rows */
    // ! Sort breaks tpRef order, so removed
    assignedLinesData.forEach(ald => {
      if (ald.length > 0) {
        ald.forEach((al: any) => {
          if (al.length > 1) {
            al.sort((a: any, b: any) =>
              a.reinsurer > b.reinsurer ? 1 : b.reinsurer > a.reinsurer ? -1 : 0
            )
          }
        })
      }
    })

    if (assignedLinesColumns.length) {
      assignedLinesRows.push(
        ...this.alConverter.getAssignedLineRows(
          assignedLinesRe,
          reinsurerListForExport,
          expiringReinsurers,
          fotReinsurers,
          nonFotAndExpiringReinsurers,
          !this.xolExport,
          assignedLinesTpRef,
          assignedLinesData,
          assignedLinesColumns,
          formattedSections
        )
      )
    }

    const [summaryRows, newIndexMappings] = this.getFotSummaryRows(
      expiringReinsurers,
      fotReinsurers,
      currentOppYear,
      [...xolLayerIndexMappings]
    )

    const expiringReinsurerMappings =
      this.getMultipleVersionMappingsForReinsurers(
        expiringReinsurers,
        nonFotAndExpiringReinsurers
      )

    const sectionIterations = this.sectionViews.flatMap(view =>
      this.getSectionsForSectionView(view)
    )

    const layerPaletteMappings = sectionIterations.map(({ layerType }) => {
      if (PROPERTY_PALETTE_IDS.includes(layerType as layerIds)) {
        return 3
      } else if (CASUALTY_PALETTE_IDS.includes(layerType as layerIds)) {
        return 4
      }
    })

    return {
      descriptionColumn,
      descriptionRows,
      headers,
      expiringReinsurerRows: finalExpiringRows,
      expiringReinsurerColumns: finalExpiringColumns,
      nonFotAndExpiringReinsurerRows: finalNonFotAndExpiringRows,
      nonFotAndExpiringReinsurerColumns: finalNonFotAndExpiringColumns,
      fotReinsurerRows: finalFotRows,
      fotReinsurerColumns: finalFotColumns,
      trackingRows: this.rowsTracking,
      trackingColumns: subjectivityTrackingColumnDef,
      assignedLinesRows,
      assignedLinesColumns,
      assignedLinesSubRows,
      assignedLinesTpRef: uniqBy(prop(0), assignedLinesTpRef),
      quotesSignedPercRow,
      members,
      xolSubjectPremiumRows,
      exportMode: this.xolExport
        ? QuoteExportModes.XOL
        : QuoteExportModes.DEFAULT,
      fotSummaryRows: [...summaryRows],
      expiringReinsurerMappings,
      layerPaletteMappings,
      sectionCurrencies,
      fotSummaryXOLLayerIndexMappings: [...newIndexMappings],
    }
  }

  private getSectionsForSectionView = (
    view: QuoteExportSectionView,
    setViewIds?: boolean
  ): Section[] => {
    if (view.sectionSelected) {
      let sections
      if (!this.isGroupSelected) {
        sections =
          !!view.section && !!view.childSections.length
            ? [view.section, ...view.childSections]
            : [view.section]
      } else {
        sections = view.childSections
      }
      if (setViewIds) {
        this.compareViewIdsBySectionView.push(...sections.map(_ => -1))
      }
      return sections
    }
    const mappedViews = view.selectedCompareViewIds.map(() =>
      !!view.section && !!view.childSections.length
        ? [view.section, ...view.childSections]
        : [view.section]
    )

    if (setViewIds) {
      this.compareViewIdsBySectionView.push(
        ...view.selectedCompareViewIds.flatMap((id, i) =>
          this.utils.populateArraysWithValue(mappedViews[i].length, id)
        )
      )
    }

    return mappedViews.flat()
  }

  private getExportSectionHeaders(): string[] {
    return this.sectionViews
      .map(view => {
        // If group/SL, continue
        if (this.isGroupSelected) {
          return view.childSections.map(({ layerName }) => layerName)
        } else if (this.isSLSelected) {
          return [view.section, ...view.childSections].map(
            ({ layerName }) => layerName
          )
        }
        const layer = this.utils.findLayerForSection(
          view.section,
          this.layerState
        )
        // Else, generate names for each view
        if (view.sectionSelected) {
          // Regular export for section selected
          if (isMultiSectionLayer(layer)) {
            return [view.section, ...view.childSections].map(childSection =>
              this.getSectionHeaderIfMultisection(childSection)
            )
          }
          if (
            !!view.section &&
            !!view.childSections.length &&
            !this.isSLSelected &&
            !this.isGroupSelected
          ) {
            return [view.section, ...view.childSections].map(section => {
              const sectionLayer = this.utils.findLayerForSection(
                section,
                this.layerState
              )
              let mainLayer = sectionLayer
              if (isLayerTopOrDrop(sectionLayer)) {
                mainLayer =
                  this.layerState.find(({ layer }) =>
                    layer.layerRefs.includes(sectionLayer.id)
                  )?.layer ?? sectionLayer
              }
              const sectionName =
                sectionLayer.meta_data.layerName ??
                sectionLayer.physicalLayer.description
              const mainName =
                mainLayer.meta_data.layerName ??
                mainLayer.physicalLayer.description
              return `${sectionName}:${mainName}`
            })
          } else {
            return [
              layer.meta_data.layerName ?? layer.physicalLayer.description,
            ]
          }
        }
        return view.selectedCompareViewIds
          .map(id => {
            const compareView = view.compareViews.find(cView => cView.id === id)
            if (isMultiSectionLayer(layer)) {
              return [view.section, ...view.childSections].map(childSection =>
                this.getSectionHeaderIfMultisection(childSection, compareView)
              )
            }
            if (
              !!view.section &&
              !!view.childSections.length &&
              !this.isSLSelected &&
              !this.isGroupSelected
            ) {
              return [view.section, ...view.childSections].map(section => {
                const sectionLayer = this.utils.findLayerForSection(
                  section,
                  this.layerState
                )
                let mainLayer = sectionLayer
                if (isLayerTopOrDrop(sectionLayer)) {
                  mainLayer =
                    this.layerState.find(({ layer }) =>
                      layer.layerRefs.includes(sectionLayer.id)
                    )?.layer ?? sectionLayer
                }
                const sectionName =
                  sectionLayer.meta_data.layerName ??
                  sectionLayer.physicalLayer.description
                const mainName =
                  mainLayer.meta_data.layerName ??
                  mainLayer.physicalLayer.description
                return `${sectionName} - ${compareView.name}:${mainName} - ${compareView.name}`
              })
            } else {
              const name =
                layer.meta_data.layerName ?? layer.physicalLayer.description
              return [`${name} - ${compareView.name}`]
            }
          })
          .flat()
      })
      .flat()
  }

  private calculateLossSetsMembers(): number[] {
    const layersFromSectionViews = this.sectionViews
      .map(view => {
        let layer: LayerState
        if (view.section && !this.isSLSelected) {
          layer = this.layerState.find(
            ({ layer }) => layer.id === view.section.layerRef
          )
        } else {
          return view.childSections.map(childSection =>
            this.layerState.find(
              ({ layer }) => layer.id === childSection.layerRef
            )
          )
        }
        return view.sectionSelected
          ? layer
          : view.selectedCompareViewIds.map(() => layer)
      })
      .flat()
    if (
      !layersFromSectionViews.length ||
      layersFromSectionViews.every(val => typeof val === 'undefined')
    ) {
      return []
    }
    return layersFromSectionViews.map(l =>
      sum(
        this.lossSetLayers
          .filter(ls => l.layer.lossSetLayers?.some(({ id }) => id === ls.id))
          .map(ls => ls.meta_data.members ?? 0)
      )
    )
  }

  private getMultiSectionMappings(): MultiSectionMapping[] {
    if (this.isSLSelected || this.isGroupSelected) {
      return []
    }
    const multisectionSections = this.sectionViews.filter(view =>
      isMultiSectionLayer(
        this.utils.findLayerForSection(view.section, this.layerState)
      )
    )
    return multisectionSections.map(view => {
      const sectionLayer = this.utils.findLayerForSection(
        view.section,
        this.layerState
      )
      const mainLayer = this.layerState.find(
        ({ layer }) => layer.meta_data.visible_layer_id === sectionLayer.id
      ).layer
      return {
        mainLayerId: mainLayer.id,
        mainLayerName:
          mainLayer.meta_data.layerName ?? `Main Layer ${mainLayer.id}`,
        sectionLayerId: sectionLayer.id,
        sectionLayerName: sectionLayer.physicalLayer.description ?? '',
      }
    })
  }

  private getMultiSectionTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiSections: MultiSectionMapping[]
  ): MultiTotalExpectedForReinsurerVersion[] {
    const sectionAVals = multiSections.filter(multi =>
      multi.sectionLayerName.includes('Section A')
    )
    const multiExpectedVals: MultiTotalExpectedForReinsurerVersion[] = []
    sectionAVals.forEach(aVal => {
      const reFound = nonFotAndExpiringReinsurers.filter(
        r => r.reinsurer.cededlayerID === aVal.sectionLayerId
      )
      reFound.forEach(reinsurer => {
        multiExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
          sections: multiSections.filter(
            multi => multi.mainLayerId === aVal.mainLayerId
          ) /* get all sections that have the same mainLayerId */,
        })
      })
    })
    return multiExpectedVals
  }

  getSharedLimitLayerIds(): string[] {
    if (!this.isSLSelected) {
      return []
    }
    return this.sectionViews
      .filter(({ section }) => section.layerType === 'shared_limits')
      .map(({ section }) => section.layerRef)
  }

  private getReinsurerListSorted(
    reinsurerList: ReinsurerState[]
  ): ReinsurerState[] {
    return reinsurerList.sort((a, b) => {
      if (a.reinsurer.quoteReinsurerName && b.reinsurer.quoteReinsurerName) {
        if (a.reinsurer.quoteReinsurerName < b.reinsurer.quoteReinsurerName) {
          return -1
        }
        if (a.reinsurer.quoteReinsurerName > b.reinsurer.quoteReinsurerName) {
          return 1
        }
      }
      return 0
    })
  }

  private getQuotePanelDefsForSection(s: Section): QuotePanelDefResolved[] {
    let exportQuotePanelDefs = this.quotePanelDefs
    if (this.allViews) {
      const view = this.allViews.find(({ layer }) => layer.id === s.layerRef)
      if (!view) {
        return []
      }
      exportQuotePanelDefs = getQuotePanelDefs(
        view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
    }
    return exportQuotePanelDefs
  }

  private getSectionRows(
    s: Section,
    defs: QuotePanelDefResolved[],
    sectionView: QuoteExportSectionView,
    termView?: QuoteExportTermView
  ): QuotePanelDefResolved[] {
    const arr: QuotePanelDefResolved[] = []
    Object.values(defs).forEach(element => {
      for (const [key, value] of Object.entries(s)) {
        if (element.exportID === key && value === true) {
          arr.push(element)
        }
      }
    })
    if (this.isSLSelected || this.isGroupSelected || !termView) {
      return arr
    }
    if (
      !this.isSLSelected &&
      !this.isGroupSelected &&
      !!sectionView.section &&
      !!sectionView.childSections.length &&
      sectionView.section.id !== s.id
    ) {
      let shownFields: (keyof LayerViewValues)[] = []
      if (s.layerType === layerIds.drop || s.layerType === layerIds.catTd) {
        shownFields = [
          'quoteAggregateLimit',
          'quoteAggregateAttachment',
          'quoteOccurrenceLimit',
          'quoteOccurrenceAttachment',
        ]
      } else if (s.layerSubType === 'section-layer') {
        shownFields = [
          'quoteAggregateLimit',
          'quoteAggregateAttachment',
          'quoteCessionPercentage',
          'quoteOccurrenceLimit',
          'quoteOccurrenceAttachment',
        ]
      }
      return defs.filter(def => shownFields.includes(def.id))
    }
    return arr.filter(
      def =>
        termView &&
        termView.terms.some(({ id, active }) => id === def.id && active)
    )
  }

  private getDescriptionRowsForSection(
    sectionRows: QuotePanelDefResolved[]
  ): string[][] {
    const head: string[][] = []

    sectionRows.forEach(row => {
      if (row.label === 'Sliding Scale Commission') {
        head.push([row.label, 'text'])
        head.push(['Min', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Provisional', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Max', 'text'])
      } else {
        head.push([row.label, 'text'])
      }
    })

    return head
  }

  private getReinsurersByType(
    reinsurerList: ReinsurerState[],
    phase?: ReinsurerPhases
  ): ReinsurerState[] {
    switch (phase) {
      case ReinsurerPhases.Expiring:
      case ReinsurerPhases.FOT:
        return reinsurerList.filter(
          ({ reinsurer }) => reinsurer.reinsurerPhase === phase
        )
      default:
        return reinsurerList.filter(
          ({ reinsurer }) =>
            reinsurer.reinsurerPhase !== ReinsurerPhases.FOT &&
            reinsurer.reinsurerPhase !== ReinsurerPhases.Expiring
        )
    }
  }

  private getSubjectPremiumForStructure(
    reinsurerListForExport: ReinsurerState[]
  ): number {
    // Subject premium should come from any quote panel, creating fallbacks still
    // Per Lockton, all panels should have the same subject premium or there was an input mistake
    const nonFotAndExpiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport
    )
    if (nonFotAndExpiringReinsurers.length) {
      return (
        nonFotAndExpiringReinsurers[0].reinsurer?.quoteFields.subjectPremium
          .value ?? 0
      )
    }
    const fotReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.FOT
    )
    if (fotReinsurers.length) {
      return fotReinsurers[0].reinsurer?.quoteFields.subjectPremium.value ?? 0
    }
    const expiringReinsurers = this.getReinsurersByType(
      reinsurerListForExport,
      ReinsurerPhases.Expiring
    )
    if (expiringReinsurers.length) {
      return (
        expiringReinsurers[0].reinsurer?.quoteFields.subjectPremium.value ?? 0
      )
    }
  }

  private getXOLSubjectPremiumRowsForStructure(
    reinsurerListForExport: ReinsurerState[]
  ): [readonly QuoteExportRowValue[][][], readonly number[]] {
    if (!this.xolExport) {
      return [[], []]
    }
    // Define the static header values
    const subjectPremium = this.getSubjectPremiumForStructure(
      reinsurerListForExport
    )
    const subjectPremiumRows: QuoteExportRowValue[][][] = [
      [
        ['Subject Premium', 'text'],
        [
          this.utils.convertCurrencyToSymbol(
            !!subjectPremium ? subjectPremium : 0,
            this.view.currency
          ),
        ],
      ],
      [[''], ['Occurrence Limit', 'text'], ['Occurrence Attachment', 'text']],
    ]
    const sectionIterations = this.sectionViews.flatMap(view =>
      this.getSectionsForSectionView(view)
    )

    const filteredViews = this.allViews.filter(view =>
      this.sectionViews.some(
        sectionView => sectionView.section.layerRef === view.layer.id
      )
    )

    const iterationMap = sectionIterations.map(section =>
      filteredViews.findIndex(view => view.layer.id === section.layerRef)
    )

    filteredViews.forEach(view => {
      const layer = view.layer
      subjectPremiumRows.push([
        [
          layer.meta_data.layerName ?? layer.physicalLayer.description ?? '',
          'text',
        ],
        [
          this.utils.convertCurrencyToSymbol(
            view.occurrenceLimit,
            view.currency
          ),
          'currency',
        ],
        [
          this.utils.convertCurrencyToSymbol(
            view.occurrenceAttachment,
            view.currency
          ),
          'currency',
        ],
      ])
    })
    return [subjectPremiumRows, iterationMap]
  }

  private getFotOrExpiringColumnsAndRowsForSection(
    s: Section,
    sectionRows: QuotePanelDefResolved[],
    fotOrExpiringReinsurers: ReinsurerState[],
    multiExpectedVals?: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals?: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals?: TotalExpectedForReinsurerVersion[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    fotOrExpiringReinsurers.forEach(r => {
      const reRow: QuoteExportRowValue[][] = []
      /* For total expected ceded loss / premium */
      let totalQuoteExpectedCededLoss = 0
      let totalQuoteExpectedCededPremium = 0
      const totalExpectedMultiVals = multiExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedGroupVals = groupExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedSharedVals = sharedExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )

      const idForReinsurerValidation =
        s.layerType !== layerIds.catMultisection ||
        this.isSLSelected ||
        this.isGroupSelected
          ? s.layerRef
          : (this.utils.getVisibleLayerSectionForMultiSectionSection(
              this.layerState,
              s
            )?.layer.id ?? '')

      if (this.utils.validateReinsurer(r.reinsurer, s.layerRef)) {
        reinsurerColumns.push(this.utils.labelReinsurer(r.reinsurer))
        if (sectionRows.length > 0 && r.reinsurer.quoteFields) {
          fotOrExpiringReinsurers.forEach(x => {
            if (!this.utils.validateReinsurer(x.reinsurer, s.layerRef)) {
              return
            }
            if (
              r.reinsurer.quoteReinsurerName ===
                x.reinsurer.quoteReinsurerName &&
              r.reinsurer.reinsurerPhaseLabel ===
                x.reinsurer.reinsurerPhaseLabel
            ) {
              if (
                s.layerType === layerIds.catMultisection ||
                this.isSLSelected
              ) {
                return
              } else {
                if (
                  r.reinsurer.quoteFields &&
                  x.reinsurer.quoteFields?.quoteExpectedCededLoss &&
                  x.reinsurer.quoteFields.quoteExpectedCededPremium
                ) {
                  totalQuoteExpectedCededLoss +=
                    x.reinsurer.quoteFields.quoteExpectedCededLoss.value
                  totalQuoteExpectedCededPremium +=
                    x.reinsurer.quoteFields.quoteExpectedCededPremium.value
                }
              }
            }
          })
          let checkSlidingScale = false
          sectionRows.forEach((sec, ind) => {
            const layerSectionObj: Section = JSON.parse(JSON.stringify(s))
            for (const [key, value] of Object.entries(
              r.reinsurer.quoteFields as any
            )) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                let layerValue = JSON.parse(JSON.stringify(value))

                if (sec.id === 'layerClass') {
                  const subclass = r.reinsurer.quoteFields.layerSubClass
                  if (subclass) {
                    layerValue += `/${subclass}`
                  }
                } else if (
                  sec.id === 'quoteCessionPercentage' &&
                  s.layerType.includes('multisection') &&
                  s.layerSubType === 'section-layer'
                ) {
                  layerValue *= -1
                }

                this.view?.layers?.map(layer => {
                  if (
                    r.reinsurer.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.reinsurer.cededlayerID === layer.layer.id) {
                    layerSectionObj.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.utils.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }
            if (sec.id === 'slidingComm' && r.reinsurer.slidingScale) {
              checkSlidingScale = true
              reRow.push(['', 'text'])
              const min =
                r.reinsurer.slidingScale[0].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[0].lossRatio * 100 +
                '%'
              reRow.push([min, 'text'])
              const slide1 =
                (r.reinsurer.slidingScale[0].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide1, 'text'])
              const prov =
                r.reinsurer.slidingScale[1].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[1].lossRatio * 100 +
                '%'
              reRow.push([prov, 'text'])
              const slide2 =
                (r.reinsurer.slidingScale[2].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide2, 'text'])
              const max =
                r.reinsurer.slidingScale[2].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[2].lossRatio * 100 +
                '%'
              reRow.push([max, 'text'])
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.layerCurrency
                  ))
                : (reRow[ind][0] = this.utils.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.layerCurrency
                  ))
            }
          })
        }
        reinsurerRows.push(reRow)
      }
    })
    return [reinsurerRows, reinsurerColumns] as const
  }

  private getReducedExpiringForSection(
    s: Section,
    fotReinsurers: ReinsurerState[]
  ): number[] {
    let idForReinsurerValidation = s.layerRef
    // if (
    //   s.layerType === layerIds.catMultisection &&
    //   !this.isSLSelected &&
    //   !this.isGroupSelected
    // ) {
    //   idForReinsurerValidation =
    //     this.utils.getVisibleLayerSectionForMultiSectionSection(
    //       this.layerState,
    //       s
    //     ).layer.id
    // }
    const signPerc: number[] = []
    fotReinsurers.forEach(r => {
      if (this.utils.validateReinsurer(r.reinsurer, idForReinsurerValidation)) {
        if (r.reinsurer.riskAssignedLinesLink) {
          const signedPercTotal = r.reinsurer.riskAssignedLinesLink.reduce(
            (tot, curr) => (curr.signed ? tot + curr.signed : tot),
            0
          )
          signPerc.push(signedPercTotal)
        }
      }
    })
    return signPerc
  }

  private getAssignedLinesColumnsAndRowsForSection(
    s: Section,
    reinsurers: ReinsurerState[],
    filteredSections: Section[]
  ): readonly [
    AssignedLines[][],
    string[][],
    QuoteExportRowValue[][],
    QuoteExportRowValue[][],
  ] {
    const assignedLinesData: AssignedLines[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    let reinsurerSections: AssignedLines[] = []

    let idForReinsurerValidation = s.layerRef
    if (
      s.layerType === layerIds.catMultisection &&
      !this.isSLSelected &&
      !this.isGroupSelected
    ) {
      idForReinsurerValidation =
        this.utils.getVisibleLayerSectionForMultiSectionSection(
          this.layerState,
          s
        ).layer.id

      const index = filteredSections.indexOf(s)
      const usedMSVisibleLayers = filteredSections
        .slice(0, index)
        .filter(section => section.layerType === layerIds.catMultisection)
        .map(
          section =>
            this.utils.getVisibleLayerSectionForMultiSectionSection(
              this.layerState,
              section
            ).layer.id
        )
      if (usedMSVisibleLayers.includes(idForReinsurerValidation)) {
        // Completely ignore a section if its visible layer has been used already
        return [[], [], [], []]
      }
    }

    reinsurers.forEach(r => {
      if (
        r &&
        this.utils.validateReinsurer(r.reinsurer, idForReinsurerValidation)
      ) {
        if (r.reinsurer.riskAssignedLinesLink) {
          let limit = 0
          if (
            s.layerType === layerIds.catAg ||
            s.layerType === layerIds.noncatAg ||
            s.layerType === layerIds.ahlAg
          ) {
            limit = r.reinsurer.quoteFields?.quoteAggregateLimit?.value || 0
          } else if (s.layerType === layerIds.noncatRisk) {
            limit = r.reinsurer.quoteFields?.quoteRiskLimit?.value || 0
          } else {
            limit = r.reinsurer.quoteFields?.quoteOccurrenceLimit?.value || 0
          }
          const aLinks = r.reinsurer.riskAssignedLinesLink.map(e => {
            return {
              ...e,
              limit,
              layer: s.layerName,
            }
          })

          reinsurerSections = [...reinsurerSections, ...aLinks]
          r.reinsurer.riskAssignedLinesLink.forEach(a => {
            const subjectivityALRow: any[] = []
            assignedLinesTpRef.push([
              a.marketTpRef || '0',
              a.relationSeqNumber || 0,
            ])

            if (a.subjectivity || a.contract) {
              subjectivityALRow.push(
                a.reinsurer,
                s.layerName,
                a.contract,
                a.subjectivity,
                ''
              )
              assignedLinesSubRows.push(subjectivityALRow)
            }
          })
        }
      }
    })
    const layerSections = reinsurerSections.map(re => ({
      id: re.id,
      brokerage: re.brokerage,
      brokerageRe: re.brokerageRe,
      recommended: re.recommended,
      signedPercentage: re.signed,
      signedCurrency: re.signed && re.limit ? re.signed * re.limit : 0,
      writtenPercentage: re.written,
      writtenCurrency: re.written && re.limit ? re.written * re.limit : 0,
      placedThrough: re.placedThrough,
      coBroker: re.coBroker,
      leadMarket: re.leadMarket,
      reinsurer: re.reinsurer,
      tpRef: re.marketTpRef,
      underwriterRef: re.underwriterRef,
    }))

    const assignedLinesRe = reinsurerSections.map(re => [re.id, re.reinsurer])
    assignedLinesData.push(layerSections)

    return [
      assignedLinesData,
      assignedLinesRe,
      assignedLinesTpRef,
      assignedLinesSubRows,
    ] as const
  }

  private getGroupTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[]
  ): TotalExpectedForReinsurerVersion[] {
    const groupExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const reFoundIndex = groupExpectedVals.findIndex(
        val =>
          val.reinsurerName === reinsurer.reinsurer.quoteReinsurerName &&
          val.reinsurerVersion === reinsurer.reinsurer.reinsurerPhaseVersion
      )
      if (reFoundIndex < 0) {
        groupExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      } else {
        const newExpectedCdedLoss =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededLoss +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0)
        const newExpectedCdedPremium =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededPremium +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0)
        groupExpectedVals[reFoundIndex] = {
          ...groupExpectedVals[reFoundIndex],
          totalQuoteExpectedCededLoss: newExpectedCdedLoss,
          totalQuoteExpectedCededPremium: newExpectedCdedPremium,
        }
      }
    })
    return groupExpectedVals
  }

  private getSharedLimitExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    sharedLimitIds: string[]
  ): TotalExpectedForReinsurerVersion[] {
    const sharedExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const sharedFound = sharedLimitIds.find(
        sharedId => sharedId === reinsurer.reinsurer.cededlayerID
      )
      if (sharedFound) {
        sharedExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      }
    })
    return sharedExpectedVals
  }

  private getSectionHeaderIfMultisection(
    s: Section,
    compareView?: QuoteCustomCompareView
  ): string {
    const sectionLayer = this.utils.findLayerForSection(s, this.layerState)
    const mainLayer = this.layerState.find(
      ({ layer }) =>
        layer.layerRefs.includes(sectionLayer.id) ||
        layer.meta_data.visible_layer_id === sectionLayer.id
    )
    let sectionLayerDesc = sectionLayer.physicalLayer.description
    let mainLayerDesc = mainLayer.layer.meta_data.layerName
    if (compareView) {
      sectionLayerDesc += `-${compareView.name}`
      mainLayerDesc += `-${compareView.name}`
    }
    return `${sectionLayerDesc}:${mainLayerDesc}`
  }

  private getFotSummaryRows(
    expiringReinsurers: ReinsurerState[],
    fotReinsurers: ReinsurerState[],
    currentOppYear: string,
    xolLayerIndexMappings: number[]
  ): [readonly QuoteExportRowValue[][][], readonly number[]] {
    if (this.isSLSelected || this.isGroupSelected) {
      return [[], []]
    }
    const rows: QuoteExportRowValue[][][] = []
    const newXOLIndexMappings = [...xolLayerIndexMappings]
    const getValidity = (reinsurer: QuoteReinsurer) =>
      !reinsurer.decline && !!reinsurer.exportToggle
    const getReFromState = (reinsurers: ReinsurerState[]) =>
      reinsurers.map(({ reinsurer }) => reinsurer).filter(getValidity)

    const groupReinsurersByLayerID = (reinsurerList: QuoteReinsurer[]) =>
      groupBy(prop('cededlayerID'), reinsurerList)

    const longestExpiring = this.utils.findLongestArrayLength(
      Object.values(
        groupReinsurersByLayerID(getReFromState(expiringReinsurers))
      )
    )

    const longestFOT = this.utils.findLongestArrayLength(
      Object.values(groupReinsurersByLayerID(getReFromState(fotReinsurers)))
    )

    const longestLayer = longestExpiring + longestFOT

    this.sectionViews.forEach(sectionView => {
      const sectionIterations = this.getSectionsForSectionView(sectionView)
      sectionIterations.forEach((section, i) => {
        // Iterate through each section iteration (either default view or custom comparisons)
        const layer = this.layerState.find(
          ({ layer }) => layer.id === section.layerRef
        ).layer
        const termView = this.termViews.find(({ layerTypes }) =>
          layerTypes.includes(section.layerType)
        )

        const customComparisonId = sectionView.selectedCompareViewIds[i]
        const customComparison = sectionView.compareViews.find(
          ({ id }) => id === customComparisonId
        )

        const exportQuotePanelDefs = this.getQuotePanelDefsForSection(section) // gets all the selected terms for this layer's quote panels
        const sectionRows = this.getSectionRows(
          section,
          exportQuotePanelDefs,
          sectionView,
          termView
        )
        const metricIds = [...fotSummaryRowIds]
        if (sectionRows.every(({ id }) => id !== 'quoteRolPercentage')) {
          const index = metricIds.indexOf('quoteRolPercentage')
          metricIds.splice(index, 1)
        }
        if (sectionRows.every(({ id }) => id !== 'quoteRateOnLineSubject')) {
          const index = metricIds.indexOf('quoteRateOnLineSubject')
          metricIds.splice(index, 1)
        }

        let expiring = expiringReinsurers.filter(
          ({ reinsurer }) =>
            reinsurer.cededlayerID === layer.id && getValidity(reinsurer)
        )

        let fots = fotReinsurers.filter(
          ({ reinsurer }) =>
            reinsurer.cededlayerID === layer.id && getValidity(reinsurer)
        )

        // Filter by custom comparison members for iteration if necessary
        if (customComparison) {
          expiring = expiring.filter(exp =>
            customComparison.members.includes(Number(exp.reinsurer.id))
          )
          fots = fots.filter(fot =>
            customComparison.members.includes(Number(fot.reinsurer.id))
          )
        }

        if (!!expiring.length || !!fots.length) {
          // total length of these rows will be the maximum length of a layer entry plus columns for title, gap, and YoY, add 2 since the layerName col gets added
          let name = layer.meta_data.layerName
          if (customComparison) {
            name += ` - ${customComparison.name}`
          }
          rows.push([
            [name ?? '', 'text'],
            ...this.utils.populateArraysWithValue(
              longestLayer + fots.length + 1,
              [' ']
            ),
          ])

          const expiringTitles = expiring.map(exp => [
            `Expiring ${exp.reinsurer.reinsurerPhaseLabel}`,
            'text',
          ])

          const fotTitles = fots.map(fot => [
            `${(currentOppYear && new FullDateToYear().transform(currentOppYear)) ?? ''} FOT ${fot.reinsurer.reinsurerPhaseLabel}`,
            'text',
          ])

          const expiringSpaces = this.utils.populateArraysWithValue(
            longestExpiring - expiringTitles.length,
            [' ']
          )
          const fotSpaces = this.utils.populateArraysWithValue(
            longestFOT - fotTitles.length,
            [' ']
          )

          const yoyChangeText = this.utils.populateArraysWithValue(
            Math.max(fots.length, 1),
            ['YoY Rate % Change', 'text']
          )

          // Expiring / FOT Title Row, YoY Rate % Header
          rows.push([
            [' '],
            ...expiringTitles,
            ...expiringSpaces,
            ...fotTitles,
            ...fotSpaces,
            [' '],
            ...yoyChangeText,
          ])
          let yoyAdded = false

          metricIds.forEach(id => {
            const metricDef = layerMetricDefs[id]
            const valueType = metricDef.valueType
            const label =
              typeof metricDef.label === 'string'
                ? metricDef.label
                : 'Rate-on-Line, Occurrence'
            const defTitleEntry = [label as string, 'text']
            let expiringEntries: QuoteExportRowValue[][] | undefined
            let fotEntries: QuoteExportRowValue[][] | undefined

            if (!!expiring.length) {
              expiringEntries = expiring.map(exp =>
                this.getFotSummaryRowValueForReinsurer(
                  exp,
                  id,
                  valueType,
                  layer.currency
                )
              )
            }
            if (!!fots.length) {
              fotEntries = fots.map(fot =>
                this.getFotSummaryRowValueForReinsurer(
                  fot,
                  id,
                  valueType,
                  layer.currency
                )
              )
            }

            // Calculate YoY Rate % Change
            const expiringSpaces = this.utils.populateArraysWithValue(
              longestExpiring - (expiringEntries?.length ?? 0),
              [' ']
            )
            const fotSpaces = this.utils.populateArraysWithValue(
              longestFOT - (fotEntries?.length ?? 0),
              [' ']
            )
            const row = [
              defTitleEntry,
              ...(expiringEntries ?? []),
              ...expiringSpaces,
              ...(fotEntries ?? []),
              ...fotSpaces,
            ]
            // Rate on Line and Rate % of Subject are fields that modify one another proportionally, both will have the same YoY change
            if (['quoteRolPercentage', 'quoteRateOnLineSubject'].includes(id)) {
              yoyChangeText.forEach((_, i) => {
                if (!i) {
                  row.push([' '])
                }

                // Always use the first expiring value to caluclate YoY
                const expiringVal =
                  !!expiringEntries?.length && expiringEntries[0]
                    ? Number(expiringEntries[0][0])
                    : 0
                const fotVal =
                  !!fotEntries?.length && fotEntries[i]
                    ? Number(fotEntries[i][0])
                    : 0

                const ratePercentageChange = !!expiringVal
                  ? (fotVal - expiringVal) / expiringVal
                  : ''
                row.push([
                  this.utils.calculateValue(
                    ratePercentageChange,
                    valueType,
                    layer.currency
                  ),
                  'percentage',
                ])
              })
            }
            rows.push(row)
          })
        } else {
          newXOLIndexMappings.splice(i, 1, -1)
        }
      })
    })

    return [rows, newXOLIndexMappings.filter(val => val >= 0)]
  }

  private getMultipleVersionMappingsForReinsurers(
    expiringReinsurers: ReinsurerState[],
    nonFotAndExpiringReinsurers: ReinsurerState[]
  ): number[][] {
    const mappings: number[][] = []
    // Build relation between expiring and reinsurer
    const expiringByLayer: Record<string, ReinsurerState[]> = groupBy(
      path(['reinsurer', 'cededlayerID']),
      expiringReinsurers
    )
    const reByLayer: Record<string, ReinsurerState[]> = groupBy(
      path(['reinsurer', 'cededlayerID']),
      nonFotAndExpiringReinsurers
    )
    Object.values(reByLayer).forEach(reList => {
      const row: number[] = []
      reList.forEach((reinsurer, j) => {
        // Find expiring reinsurer that has this reinsurer as an assigned line
        const expiringForLayer =
          expiringByLayer[reinsurer.reinsurer.cededlayerID] ?? []

        if (!expiringForLayer.length) {
          return
        }

        let reinsurerMatch = expiringForLayer.find(re =>
          re.reinsurer.riskAssignedLinesLink?.some(
            line => line.marketTpRef === reinsurer.reinsurer.tpRef
          )
        )

        if (!reinsurerMatch) {
          // Find the reinsurer with the largest sum of signed value
          const summedSignedPercents = expiringForLayer.map(re =>
            sum(
              re.reinsurer.riskAssignedLinesLink.map(line => line.signed ?? 0)
            )
          )

          if (!summedSignedPercents.length) {
            // If everything is equal, use the largest offered% value
            const offeredPercentVals = expiringForLayer.map(
              re => re.reinsurer.quoteFields.quoteOfferedPercentage ?? 0
            )
            const index = offeredPercentVals.findIndex(
              val => val === Math.max(...offeredPercentVals)
            )
            reinsurerMatch = expiringForLayer[index]
          } else {
            // Use summed signed value
            const index = summedSignedPercents.findIndex(
              val => val === Math.max(...summedSignedPercents)
            )
            reinsurerMatch = expiringForLayer[index]
          }
        }

        if (!reinsurerMatch && !!expiringForLayer.length) {
          // Default to the first expiring if no other condition is met
          reinsurerMatch = expiringForLayer[0]
        }

        const expiringIndex = expiringForLayer.indexOf(reinsurerMatch)

        row.push(expiringIndex)
      })
      mappings.push(row)
    })

    return mappings
  }

  private getFotSummaryRowValueForReinsurer(
    reinsurer: ReinsurerState,
    id: keyof LayerViewValues,
    valueType: MetricValueType,
    currency: string
  ): QuoteExportRowValue[] {
    const quoteFields = reinsurer.reinsurer.quoteFields
    const entryValue = (quoteFields as any)[id] ?? ''
    const calculatedValue = this.utils.calculateValue(
      entryValue,
      valueType,
      currency
    )
    const finalValue = !!calculatedValue
      ? calculatedValue
      : valueType === 'currency'
        ? `${this.utils.convertCurrencyToSymbol(0, currency)}`
        : 0
    return [finalValue, valueType]
  }
}
