import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../core/store'
import { LossSetLayer } from '../model/loss-set-layers.model'
import * as fromAnalysisSelectors from '../store/analysis.selectors'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import {
  addRiskLossSetsToLayer,
  removeRiskLossSetsToLayer,
  updateLayer,
} from '../store/ceded-layers/layers.actions'
import { Layer } from '../model/layers.model'
import { updateGrossLossSets } from '../store/loss-set-layers/loss-set-layers.actions'
import { RiskLossSetLayerModel } from './loss-set-layer-selector/loss-set-layer-selector.component'
import { LossSetGroupEntity } from '../store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import { selectAnalysisLossSetGroupEditor } from '../store/analysis-panels.selectors'
import {
  setDirtyLossSetGroup,
  updateGroupEditLossSets,
} from '../store/loss-set-layers/loss-set-group/loss-set-group.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-loss-set-layer-container',
  template: `
    <app-loss-set-layer-selector
      (lossSetSelection)="onLossSetSelection($event)"
      (grossLossSetSelection)="onGrossLossSetSelection($event)"
      (removeRiskLossSets)="onRemoveRiskLossSets($event)"
      (addRiskLossSets)="onAddRiskLossSets($event)"
      (groupEditLossSetSelection)="onGroupEditLossSetSelection($event)"
      (lossSetGroupSetDirty)="onLossSetGroupSetDirty($event)"
      [selectedLayer]="selectedLayer$ | async"
      [layerChoices]="layerChoices$ | async"
      [lossSetsList]="layerChoices$ | async"
      [lossSetSelectionMode]="lossSetSelectionMode$ | async"
      [showLossSetGroupEditor]="showLossSetGroupEditor$ | async"
      [grossLossSets]="grossLossSets$ | async"
      [allCededLayers]="allCededLayers$ | async"
      [groupEditSelectedLossSets]="groupEditSelectedLossSets$ | async"
      [lossSetGroupEditMode]="lossSetGroupEditMode$ | async"
      [lossSetSelectedGroup]="lossSetSelectedGroup$ | async"
      [lossSetGroups]="lossSetGroups$ | async"
    ></app-loss-set-layer-selector>
  `,
})
export class LossSetLayerContainerComponent implements OnInit {
  layerChoices$: Observable<LossSetLayer[]>
  grossLossSets$: Observable<LossSetLayer[]>
  loading$: Observable<boolean>
  error$: Observable<string | null>

  selectedLayer$: Observable<LayerState | null>
  allCededLayers$: Observable<LayerState[] | null>

  showLossSetGroupEditor$: Observable<boolean>
  groupEditSelectedLossSets$: Observable<LossSetLayer[]>
  lossSetGroups$: Observable<LossSetGroupEntity[]>
  lossSetGroupEditMode$: Observable<string>
  lossSetSelectedGroup$: Observable<string | null>
  lossSetSelectionMode$: Observable<string>

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.layerChoices$ = this.store.pipe(
      select(fromAnalysisSelectors.selectParentGrossLossSetLayers)
    )
    this.grossLossSets$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayers)
    )
    this.loading$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayersLoading)
    )

    this.error$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetLayersError)
    )

    this.selectedLayer$ = this.store.pipe(
      select(fromAnalysisSelectors.selectCurrentCededLayer)
    )

    this.allCededLayers$ = this.store.pipe(
      select(fromAnalysisSelectors.selectCededLayers)
    )
    this.groupEditSelectedLossSets$ = this.store.pipe(
      select(fromAnalysisSelectors.selectGroupEditSelectedLossSets)
    )

    this.lossSetGroups$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetGroupEntities)
    )

    this.showLossSetGroupEditor$ = this.store.pipe(
      select(selectAnalysisLossSetGroupEditor)
    )

    this.lossSetGroupEditMode$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetGroupEditMode)
    )

    this.lossSetSelectedGroup$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetSelectedGroup)
    )

    this.lossSetSelectionMode$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetSelectionMode)
    )
  }

  onLossSetSelection(partialLayer: Partial<Layer>) {
    this.store.dispatch(
      updateLayer({
        // tslint:disable-next-line: no-non-null-assertion
        id: partialLayer.id!,
        change: {
          lossSetLayers: partialLayer.lossSetLayers,
          meta_data: {
            ...partialLayer.meta_data,
            isLossSetUpdated: true, // Whenever a ceded loss set is updated we update the isLossSetUpdated to true
          },
        },
      })
    )
  }

  onGrossLossSetSelection(lossSetLayers: LossSetLayer[]) {
    this.store.dispatch(updateGrossLossSets({ lossSetLayers }))
  }

  onRemoveRiskLossSets(removeLossSets: RiskLossSetLayerModel) {
    this.store.dispatch(
      removeRiskLossSetsToLayer({
        id: removeLossSets.id,
        lossSets: removeLossSets.lossSets,
      })
    )
  }

  onAddRiskLossSets(addLossSets: RiskLossSetLayerModel) {
    this.store.dispatch(
      addRiskLossSetsToLayer({
        id: addLossSets.id,
        lossSets: addLossSets.lossSets,
      })
    )
  }

  onGroupEditLossSetSelection(lossSetLayers: LossSetLayer[]) {
    this.store.dispatch(updateGroupEditLossSets({ lossSetLayers }))
  }

  onLossSetGroupSetDirty(lossSetGroupID: string) {
    this.store.dispatch(setDirtyLossSetGroup({ lossSetGroupID, dirty: true }))
  }
}
