import { createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { ExtendedState, State } from './program.state.facade'
import { append, lensPath, lensProp, set, view } from 'ramda'
import * as AnalysisActions from '../../../analysis/store/analysis.actions'
import { convertAllProgramsFromResponse } from '../../../api/program/program.converter'
import {
  Program,
  StructureTowerPreferences,
  TowerPreferences,
} from '../../model/program.model'
import { StructureLayerDataResponse } from '../../../api/model/backend.model'
import * as AuthActions from '../auth/auth.actions'
import * as ProgramActions from './program.actions'
import * as OptimizationActions from '../../../analysis/optimization/store/optimization-candidates-results.actions'
import { rejectNil } from '@shared/util/operators'
import { layerIds } from '../../../analysis/model/layer-palette.model'
import {
  setFotAndQuoteCountFailure,
  setFotAndQuoteCountSuccess,
} from 'src/app/quote/store/quote.actions'

export const adapter = createEntityAdapter<Program>({
  selectId: (program): string => `${program.id}`,
})

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
  dirtyProgram: null,
})

const programReducer = createReducer(
  initialState,

  on(ProgramActions.setDirtyProgram, (state, program) => {
    return { ...state, dirtyProgram: program }
  }),

  on(ProgramActions.updateDirtyProgram, state => {
    if (state.dirtyProgram !== null) {
      return {
        ...adapter.updateOne(
          { id: state.dirtyProgram.id, changes: state.dirtyProgram },
          state
        ),
        dirtyProgram: null,
      }
    } else {
      return { ...state }
    }
  }),

  // @ts-ignore
  on(ProgramActions.setColorChange, (state, { layer, color }) => {
    const dProgram: Program = JSON.parse(JSON.stringify(state.dirtyProgram))
    if (dProgram) {
      const layerData = dProgram.layerData
      if (layerData) {
        let id = ''
        if (
          layer.meta_data &&
          layer.meta_data.sage_layer_subtype !== layerIds.feeder &&
          (layer.meta_data.sage_layer_type === layerIds.catAg ||
            layer.meta_data.sage_layer_type === layerIds.noncatAg ||
            layer.meta_data.sage_layer_type === layerIds.ahlAg)
        ) {
          id = `LAgg${layer.id}`
        } else {
          id = `LOcc${layer.id}`
        }
        const index = layerData.findIndex(
          (lData: StructureLayerDataResponse) => {
            return lData.layer_id === id
          }
        )
        layerData[index].color = color
        return { ...state, dirtyProgram: dProgram }
      } else {
        return { ...state }
      }
    } else {
      return { ...state }
    }
  }),

  on(
    ProgramActions.setProgramMarketLayer,
    (state, { program, marketLayers }) => {
      if (state.dirtyProgram !== null) {
        const currentMarketLayers = JSON.parse(
          JSON.stringify(state.entities[state.dirtyProgram.id])
        )
        if (currentMarketLayers && currentMarketLayers.marketLayer) {
          currentMarketLayers.marketLayer =
            currentMarketLayers.marketLayer.concat(marketLayers)
        }
        if (currentMarketLayers) {
          return {
            ...adapter.updateOne(
              {
                id: program.id,
                changes: { marketLayer: currentMarketLayers.marketLayer },
              },
              state
            ),
          }
        } else {
          return state
        }
      }
      return state
    }
  ),

  on(
    ProgramActions.setProgramLogAndSnapping,
    (state, { id, log, logMin, snapping }) => {
      const program = state.entities[Number(id)]
      if (program && program.towerPreferences) {
        let towerPreferences = getChange(['log'], log, program.towerPreferences)
        towerPreferences = getChange(['snapping'], snapping, towerPreferences)
        towerPreferences = getChange(['logMin'], logMin, towerPreferences)
        return adapter.updateOne(
          { id: program.id, changes: { towerPreferences } },
          state
        )
      }
      return state
    }
  ),

  on(ProgramActions.updateProgram, (state, { programID, change }) => {
    return adapter.updateOne({ id: programID, changes: change }, state)
  }),

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(AuthActions.identifyPermissionsSuccess, (state, { programs }) => {
    const ps = convertAllProgramsFromResponse(programs)
    const next = adapter.setAll(ps, state)
    return { ...next, loading: initialState.loading, error: initialState.error }
  }),

  on(
    AnalysisActions.saveAsAnalysisSuccess,
    (state, { program, otherPrograms }) => {
      let _state = state
      if (program.isScenario && program.parentScenarioID) {
        // Scenario is being added, add its ID to its parent's scenario IDs
        const id = program.parentScenarioID
        const parent = state.entities[id]
        if (parent) {
          const prev = view(scenarioIDsLens, parent) as string[] | undefined
          const next = append(program.id, prev ?? [])
          const changes = set(scenarioIDsLens, next, parent)
          _state = adapter.updateOne({ id, changes }, state)
        }
      }
      _state = adapter.addOne(program, _state)
      if (otherPrograms && otherPrograms.length > 0) {
        return adapter.addMany(otherPrograms, _state)
      } else {
        return _state
      }
    }
  ),

  on(AnalysisActions.imageUploadSuccess, (state, { structureID, res }) => {
    if (structureID) {
      // Set current timestamp from success response
      return {
        ...adapter.updateOne(
          {
            id: structureID,
            changes: { lastModified: res },
          },
          state
        ),
      }
    } else {
      return state
    }
  }),

  on(OptimizationActions.saveSuccess, (state, { details }) => {
    if (details.length === 0) {
      return state
    }
    const parentOptimizationID = details[0].program
      .parentOptimizationID as string
    const parent = state.entities[parentOptimizationID]
    const optimizationIDs = details.map(d => d.program.id)
    if (parent) {
      const otherPrograms = rejectNil(details.flatMap(d => d.otherPrograms))
      const prev = view(optimizationIDsLens, parent) as string[] | undefined
      const next = prev ? [...prev, ...optimizationIDs] : optimizationIDs
      const changes = set(optimizationIDsLens, next, parent)
      let _state = adapter.updateOne(
        { id: parentOptimizationID, changes },
        state
      )
      _state = adapter.addMany(
        details.map(d => d.program),
        _state
      )
      if (otherPrograms.length > 0) {
        return adapter.addMany(otherPrograms, _state)
      } else {
        return _state
      }
    } else {
      return state
    }
  }),

  // Occ
  on(ProgramActions.setOccIncrementsY, (state, { programID, incrementsY }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['occurrence', 'incrementsY'],
        incrementsY,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setOccIncrementsYDirty, (state, { programID, dirty }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['occurrence', 'incrementsYDirty'],
        dirty,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setOccMaxY, (state, { programID, maxY }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['occurrence', 'maxY'],
        maxY,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setOccMaxYDirty, (state, { programID, dirty }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['occurrence', 'maxYDirty'],
        dirty,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setOccMost, (state, { programID, most }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['occurrence', 'most'],
        most,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  // Agg
  on(ProgramActions.setAggIncrementsY, (state, { programID, incrementsY }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['aggregate', 'incrementsY'],
        incrementsY,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setAggIncrementsYDirty, (state, { programID, dirty }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['aggregate', 'incrementsYDirty'],
        dirty,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setAggMaxY, (state, { programID, maxY }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['aggregate', 'maxY'],
        maxY,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setAggMaxYDirty, (state, { programID, dirty }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['aggregate', 'maxYDirty'],
        dirty,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  on(ProgramActions.setAggMost, (state, { programID, most }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const towerPreferences = getChange(
        ['aggregate', 'most'],
        most,
        program.towerPreferences
      )
      return adapter.updateOne(
        { id: program.id, changes: { towerPreferences } },
        state
      )
    }
    return state
  }),

  // All
  on(ProgramActions.setAllProperties, (state, { programID, occ, agg }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      return adapter.updateOne(
        {
          id: program.id,
          changes: {
            towerPreferences: {
              ...program.towerPreferences,
              occurrence: occ,
              aggregate: agg,
            },
          },
        },
        state
      )
    }
    return state
  }),

  on(ProgramActions.resetTowerProperties, (state, { programID }) => {
    const program = state.entities[Number(programID)]
    if (program && program.towerPreferences) {
      const initial: TowerPreferences = {
        incrementsY: null,
        incrementsYDirty: false,
        maxY: null,
        maxYDirty: false,
        most: null,
      }
      return adapter.updateOne(
        {
          id: program.id,
          changes: {
            towerPreferences: {
              ...program.towerPreferences,
              occurrence: initial,
              aggregate: initial,
            },
          },
        },
        state
      )
    }
    return state
  }),

  on(
    ProgramActions.setProgramNameAndDescriptionSuccess,
    (state, { id, name, description }) =>
      adapter.updateOne({ id, changes: { label: name, description } }, state)
  ),

  on(setFotAndQuoteCountSuccess, (state, { id, fotCount, quoteCount }) =>
    adapter.updateOne({ id, changes: { fotCount, quoteCount } }, state)
  ),

  on(
    ProgramActions.setProgramNameAndDescriptionFailure,
    setFotAndQuoteCountFailure,
    (state, { error }) => ({ ...state, error })
  ),

  on(ProgramActions.updateProgramIndexSuccess, (state, { id, index }) =>
    adapter.updateOne({ id, changes: { position_index: index } }, state)
  ),

  on(ProgramActions.updateFolderIDSuccess, (state, { structureId, folderID }) =>
    adapter.updateOne({ id: structureId, changes: { folderID } }, state)
  ),

  on(ProgramActions.updateCountsSuccess, (state, { structureId, fotCount, quoteCount }) =>
  adapter.updateOne({ id: structureId, changes: { fotCount, quoteCount } }, state)
  ),
  
  on(ProgramActions.updateProgramIndexFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(
    ProgramActions.saveTowerPreferenesSuccess,
    (state, { id, towerPreferences }) => {
      const program = state.entities[Number(id)]
      console.log(towerPreferences[0])
      if (program) {
        const towerPrefs = JSON.parse(JSON.stringify(program.towerPreferences))
        towerPrefs.occurrence.incrementsY = towerPreferences[0].increment_y_occ
        towerPrefs.occurrence.maxY = towerPreferences[0].y_max_occ
        towerPrefs.aggregate.incrementsY = towerPreferences[0].increment_y_agg
        towerPrefs.aggregate.maxY = towerPreferences[0].y_max_agg
        return adapter.updateOne(
          {
            id,
            changes: {
              towerPreferences: towerPrefs,
            },
          },
          state
        )
      } else {
        return state
      }
    }
  ),
  on(ProgramActions.updateTailMetricsSuccess, (state, { id, tailMetrics }) => {
    // @ts-ignore
    const tailMetricsOptions = { ...tailMetrics, returnPeriodData: undefined }
    return adapter.updateOne(
      {
        id,
        changes: { tailMetricsOptions: JSON.stringify(tailMetricsOptions) },
      },
      state
    )
  })
)

export function reducer(state: State | undefined, action: Action) {
  return programReducer(state, action)
}

export function getChange(
  path: string[],
  value: number | boolean,
  towerPreferences: StructureTowerPreferences
): StructureTowerPreferences {
  const lens = lensPath(path)
  return set(lens, value, towerPreferences)
}

// @ts-ignore
const scenarioIDsLens = lensProp('scenarioIDs')
// @ts-ignore
const optimizationIDsLens = lensProp('optimizationIDs')
