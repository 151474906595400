import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import {
  CreditCalculationStructure,
  CreditStructure,
} from './../../model/credit-structure.model'
import {
  CreditStructureGroup,
  CreditStructureGroupEntity,
  CreditStructureGroupMember,
  CreditStructureGroupMemberEntity,
} from '../../model/credit-structure-group.model'
import { ProgramsYearsFilter } from './../../../tier/credit/credit-tier.model'
import { ApplicationError } from '../../../error/model/error'

export const CreditStructureGroupFilterActions = createActionGroup({
  source: 'Credit Group Filters',
  events: {
    Update: props<{ filters: ProgramsYearsFilter }>(),
    'Fetch Structures': props<{ filters: ProgramsYearsFilter }>(),
    'Fetch Structures Success': props<{
      creditStructures: CreditStructure[]
    }>(),
    'Fetch Structures Failure': props<{ error: ApplicationError }>(),
    'Fetch Groups': props<{ filters: ProgramsYearsFilter }>(),
    'Fetch Groups Success': props<{
      creditStructureGroups: CreditStructureGroup[]
    }>(),
    'Fetch Groups Failure': props<{ error: ApplicationError }>(),
    Reset: emptyProps(),
  },
})

enum CreditStructureGroupActions {
  AddCreditStructureGroup = '[Credit Group] Add Credit Structure Group',
  AddCreditStructureGroupSuccess = '[Credit Group] Add Credit Structure Group - Success',
  AddCreditStructureGroupFailure = '[Credit Group] Add Credit Structure Group - Failure',
  UpdateCreditStructureGroup = '[Credit Group] Update Credit Structure Group',
  UpdateCreditStructureGroupSuccess = '[Credit Group] Update Credit Structure Group - Success',
  UpdateCreditStructureGroupFailure = '[Credit Group] Update Credit Structure Group - Failure',
  RenameCreditStructureGroup = '[Credit Group] Rename Credit Structure Group',
  RemoveCreditStructureGroup = '[Credit Group] Remove Credit Structure Group',
  DeleteCreditStructureGroup = '[Credit Group] Delete Credit Structure Group',
  DeleteCreditStructureGroupSuccess = '[Credit Group] Delete Credit Structure Group - Success',
  DeleteCreditStructureGroupFailure = '[Credit Group] Delete Credit Structure Group - Failure',
  SaveCreditGroups = '[Credit Group] Save Groups',
  SaveCreditStructureGroup = '[Credit Group] Save Credit Structure Groups',
  SaveCreditStructureGroupSuccess = '[Credit Group] Save Credit Structure Group - Success',
  SaveCreditStructureGroupFailure = '[Credit Group] Save Credit Structure Group - Failure',
  FetchCreditStructureGroupsForProgram = '[Credit Group] Fetch Credit Structure Groups for Selected Program',
  FetchCreditStructureGroupsForProgramSuccess = '[Credit Group] Fetch Credit Structure Groups for Selected Program - Success',
  FetchCreditStructureGroupsForProgramFailure = '[Credit Group] Fetch Credit Structure Groups for Selected Program - Failure',
  FetchCreditStructureGroupDetails = '[Credit Group] Fetch Credit Structure Group Details for Selected Group',
  FetchCreditCalculationStructureForGroup = '[Credit Group] Fetch Credit Calculation Structure for Selected Group',
  FetchCreditCalculationStructureForGroupSuccess = '[Credit Group] Fetch Credit Calculation Structure for Selected Group - Success',
  FetchCreditCalculationStructureForGroupFailure = '[Credit Group] Fetch Credit Calculation Structure for Selected Group - Failure',
}

enum CreditStructureGroupMemberActions {
  AddStructureToGroup = '[Credit Group Members] Add Structure to Structure Group',
  RemoveStructureFromGroup = '[Credit Group Members] Remove Structure from Structure Group',
  SaveCreditStructureGroupMembersSuccess = '[Credit Group Members] Save Credit Structure Group Members - Success',
  SaveCreditStructureGroupMembersFailure = '[Credit Group Members] Save Credit Structure Group Members - Failure',
  FetchCreditGroupMembers = '[Credit Group Members] Fetch Credit Group Members for Selected Group',
  FetchCreditGroupMembersSuccess = '[Credit Group Members] Fetch Credit Group Members for Selected Group - Success',
  FetchCreditGroupMembersFailure = '[Credit Group Members] Fetch Credit Group Members for Selected Group - Failure',
  FetchCreditGroupMemberCalculationStructuresSuccess = '[Credit Group Members] Fetch Credit Group Member Calculation Structures - Success',
  FetchCreditGroupMemberCalculationStructuresFailure = '[Credit Group Members] Fetch Credit Group Member Calculation Structures - Success',
}

/* Group Actions */
export const AddCreditStructureGroup = createAction(
  CreditStructureGroupActions.AddCreditStructureGroup,
  props<{ creditStructure: CreditStructure }>()
)
export const AddCreditStructureGroupFailure = createAction(
  CreditStructureGroupActions.AddCreditStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const AddCreditStructureGroupSuccess = createAction(
  CreditStructureGroupActions.AddCreditStructureGroupSuccess,
  props<{
    creditStructureGroupEntity: CreditStructureGroupEntity
    creditStructureGroupMemberEntity: CreditStructureGroupMemberEntity
  }>()
)
export const UpdateCreditStructureGroup = createAction(
  CreditStructureGroupActions.UpdateCreditStructureGroup,
  props<{ creditStructure: CreditStructure }>()
)
export const UpdateCreditStructureGroupFailure = createAction(
  CreditStructureGroupActions.UpdateCreditStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const UpdateCreditStructureGroupSuccess = createAction(
  CreditStructureGroupActions.UpdateCreditStructureGroupSuccess,
  props<{
    creditStructureGroupMemberEntity: CreditStructureGroupMemberEntity
  }>()
)
export const RenameCreditStructureGroup = createAction(
  CreditStructureGroupActions.RenameCreditStructureGroup,
  props<{ label: string }>()
)
export const RemoveCreditStructureGroup = createAction(
  CreditStructureGroupActions.RemoveCreditStructureGroup
)
export const DeleteCreditStructureGroup = createAction(
  CreditStructureGroupActions.DeleteCreditStructureGroup
)
export const DeleteCreditStructureGroupFailure = createAction(
  CreditStructureGroupActions.DeleteCreditStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const DeleteCreditStructureGroupSuccess = createAction(
  CreditStructureGroupActions.DeleteCreditStructureGroupSuccess,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)
export const SaveCreditGroups = createAction(
  CreditStructureGroupActions.SaveCreditGroups
)
export const SaveCreditStructureGroup = createAction(
  CreditStructureGroupActions.SaveCreditStructureGroup,
  props<{ creditStructureGroupEntity: CreditStructureGroupEntity }>()
)
export const SaveCreditStructureGroupFailure = createAction(
  CreditStructureGroupActions.SaveCreditStructureGroupFailure,
  props<{ error: ApplicationError }>()
)
export const SaveCreditStructureGroupSuccess = createAction(
  CreditStructureGroupActions.SaveCreditStructureGroupSuccess,
  props<{
    creditStructureGroupEntity: CreditStructureGroupEntity
  }>()
)
export const FetchCreditStructureGroupsForProgram = createAction(
  CreditStructureGroupActions.FetchCreditStructureGroupsForProgram,
  props<{ programId: string }>()
)
export const FetchCreditStructureGroupsForProgramFailure = createAction(
  CreditStructureGroupActions.FetchCreditStructureGroupsForProgramFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditStructureGroupsForProgramSuccess = createAction(
  CreditStructureGroupActions.FetchCreditStructureGroupsForProgramSuccess,
  props<{ creditStructureGroups: CreditStructureGroup[] }>()
)
export const FetchCreditStructureGroupDetails = createAction(
  CreditStructureGroupActions.FetchCreditStructureGroupDetails,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)
export const FetchCreditCalculationStructureForGroup = createAction(
  CreditStructureGroupActions.FetchCreditCalculationStructureForGroup,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)
export const FetchCreditCalculationStructureForGroupFailure = createAction(
  CreditStructureGroupActions.FetchCreditCalculationStructureForGroupFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditCalculationStructureForGroupSuccess = createAction(
  CreditStructureGroupActions.FetchCreditCalculationStructureForGroupSuccess,
  props<{
    creditStructureGroup: CreditStructureGroup
    creditCalculationStructure: CreditCalculationStructure
  }>()
)

/* Group Member Actions */
export const AddStructureToGroup = createAction(
  CreditStructureGroupMemberActions.AddStructureToGroup,
  props<{ creditStructure: CreditStructure }>()
)
export const RemoveStructureFromGroup = createAction(
  CreditStructureGroupMemberActions.RemoveStructureFromGroup,
  props<{
    creditStructure: CreditStructure
    creditCalculationStructure: CreditCalculationStructure
  }>()
)
export const SaveCreditStructureGroupMembersFailure = createAction(
  CreditStructureGroupMemberActions.SaveCreditStructureGroupMembersFailure,
  props<{ error: ApplicationError }>()
)
export const SaveCreditStructureGroupMembersSuccess = createAction(
  CreditStructureGroupMemberActions.SaveCreditStructureGroupMembersSuccess,
  props<{ creditStructureGroupMembers: CreditStructureGroupMember[] }>()
)
export const FetchCreditGroupMembers = createAction(
  CreditStructureGroupMemberActions.FetchCreditGroupMembers,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)
export const FetchCreditGroupMembersFailure = createAction(
  CreditStructureGroupMemberActions.FetchCreditGroupMembersFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditGroupMembersSuccess = createAction(
  CreditStructureGroupMemberActions.FetchCreditGroupMembersSuccess,
  props<{ creditStructureGroupMembers: CreditStructureGroupMember[] }>()
)
export const FetchCreditGroupMemberCalculationStructuresFailure = createAction(
  CreditStructureGroupMemberActions.FetchCreditGroupMemberCalculationStructuresFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditGroupMemberCalculationStructuresSuccess = createAction(
  CreditStructureGroupMemberActions.FetchCreditGroupMemberCalculationStructuresSuccess,
  props<{
    creditStructureGroupMembers: CreditStructureGroupMember[]
    creditStructures: CreditStructure[]
    creditCalculationStructures: CreditCalculationStructure[]
  }>()
)
