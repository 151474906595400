import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import * as d3 from 'd3'
import { colors, ItemSelect, LossItem } from '../store/explore.model'
import { LossSetTableState } from '../store/explore.reducer'
import { AggregationMethodType } from '../../model/metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gross-loss-graph',
  styleUrls: ['./gross-loss-graph.component.scss'],
  templateUrl: './gross-loss-graph.component.html',
})
export class GrossLossDistributionGraphComponent
  implements AfterViewInit, OnChanges
{
  @Input() loading: boolean
  @Input() error: boolean
  @Input() aggregationMethod: AggregationMethodType
  dataGraph: any

  @ViewChildren('graphContainer', {})
  private graphContainer: QueryList<ElementRef>

  @Input() set dataTable(value: LossSetTableState[]) {
    if (value) {
      this.getInitialValues(value)
    }
  }

  @Output() rpChange = new EventEmitter<{ rp: number; index: number }>()

  constructor(
    private element: ElementRef // private cd: ChangeDetectorRef
  ) {}

  lob: string | null

  width = 0
  height = 0
  chartWidth = 0
  chartHeight = 0
  svgwidth: any = 0
  svgheight: any = 0
  resizeTimeout: number
  items: ItemSelect[] = []
  selected: string[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable && changes.dataTable.currentValue) {
      this.getInitialValues(changes.dataTable.currentValue)
    }
    if (this.graphContainer) {
      this.graphContainer.changes.subscribe(() => {
        if (this.graphContainer.length > 0) {
          this.calculateDimensions()
          if (this.dataGraph) {
            this.drawGraph(this.dataGraph)
          }
        }
      })
    }
  }

  ngAfterViewInit(): void {
    if (this.graphContainer.length > 0) {
      this.calculateDimensions()
      if (this.dataGraph) {
        this.drawGraph(this.dataGraph)
      }
    }
  }

  getInitialValues(value: LossSetTableState[]): void {
    const rpArray = value[0].lossDistributionDataTable.map(l => {
      return l.returnPeriod
    })
    this.dataGraph = {} as any
    this.items = []
    this.selected = []
    this.dataGraph.x_axis = rpArray
    const lossItem: LossItem[] = []
    value.map(d => {
      // tslint:disable-next-line: no-non-null-assertion
      this.items.push({ label: d.lossName, value: d.lossID! })
      this.selected.push(d.lossName)
      const rpValue = d.lossDistributionDataTable.map(l => {
        return String(
          this.aggregationMethod === 'OEP' ? l.oepValue : l.aepValue
        )
      })
      lossItem.push({ label: d.lossName, value: rpValue })
      this.dataGraph[d.lossName] = rpValue
    })
  }

  getColors(lossID: string): string {
    return colors[this.selected.indexOf(lossID)]
  }

  drawGraph(dataG: any): void {
    d3.select('svg').remove()
    // set the dimensions and margins of the graph
    const margin = { top: 35, right: 120, bottom: 30, left: 100 }
    const width = this.svgwidth - margin.left - margin.right
    const height = this.svgheight - margin.top - margin.bottom
    const formatValue = d3.format(',')
    const formatValueSN = d3.format('s')

    const COLORS = d3
      .scaleOrdinal<string, string>()
      .domain(this.selected)
      .range(colors)

    // append the svg object to the body of the page
    const svg = d3
      .select('#my_dataviz')
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.svgheight)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    // group the data: I want to draw one line per group
    // nest function allows to group the calculation per level of a factor
    const getKeyValue =
      <U extends keyof T, T extends object>(key: U) =>
      (obj: T) =>
        obj[key]

    const sumStat = Object.keys(dataG)
      .map((d: any) => {
        const values: any = dataG.x_axis.map((axis: any, index: number) => {
          return {
            x: axis,
            value: Number(getKeyValue<keyof any, any>(d)(dataG)[index]),
          }
        })
        return {
          key: d,
          values,
        }
      })
      .splice(1)
      .filter(s => {
        return this.selected.includes(s.key)
      })

    const allX: number[] = []
    const allY: number[] = []
    sumStat.forEach(s => {
      s.values.forEach((v: any) => {
        allX.push(v.x)
        allY.push(v.value)
      })
    })
    const extent = d3.extent(allX)

    // Add X axis --> it is a date format
    const x = d3
      .scaleLinear()
      // tslint:disable-next-line:no-non-null-assertion
      .domain([extent[0]!, extent[1]!])
      .range([0, width])
      .nice()
    svg
      .append('g')
      .attr('class', 'axisGraph')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(10))

    // Add Y axis
    const y = d3
      .scaleLinear()
      // tslint:disable-next-line:no-non-null-assertion
      .domain([0, d3.max(allY)!])
      .range([height, 0])
      .nice()
    svg.append('g').attr('class', 'axisGraph').call(d3.axisLeft(y))

    // Draw the line
    svg
      .selectAll('.line')
      .data(sumStat)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', d => COLORS(d.key))
      .attr('stroke-width', 1.5)
      .attr('d', dd => {
        return d3
          .line()
          .x((d: any) => {
            return x(d.x)
          })
          .y((d: any) => {
            return y(+d.value)
          })(dd.values)
      })

    svg.append('path').attr('class', 'path-hover')

    const focus = svg
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none')

    focus
      .append('line')
      .attr('class', 'x-hover-line hover-line')
      .attr('y1', 0)
      .attr('y2', height)

    focus
      .append('line')
      .attr('class', 'y-hover-line hover-line')
      .attr('x1', width)
      .attr('x2', width)

    focus.append('circle').attr('r', 5)

    focus
      .append('rect')
      .attr('class', 'tooltip')
      .attr('width', 150)
      .attr('height', 70)
      .attr('x', 10)
      .attr('y', -40)
      .attr('rx', 4)
      .attr('ry', 10)

    focus
      .append('text')
      .attr('class', 'tooltip-header-s')
      .attr('x', 18)
      .attr('y', -17)
      .text('LossSet ID: ')
      .attr('fill', '#ffffff')

    focus
      .append('text')
      .attr('class', 'tooltip-series')
      .attr('x', 85)
      .attr('y', -17)
      .attr('fill', '#ffffff')

    focus
      .append('text')
      .attr('class', 'tooltip-header-p')
      .attr('x', 18)
      .attr('y', 3)
      .text('Point: ')
      .attr('fill', '#ffffff')

    focus
      .append('text')
      .attr('class', 'tooltip-point')
      .attr('x', 60)
      .attr('y', 3)
      .attr('fill', '#ffffff')

    focus
      .append('text')
      .attr('class', 'tooltip-header-v')
      .attr('x', 18)
      .attr('y', 23)
      .text('Value: ')
      .attr('fill', '#ffffff')

    focus
      .append('text')
      .attr('class', 'tooltip-value')
      .attr('x', 60)
      .attr('y', 23)
      .attr('fill', '#ffffff')

    const dots = svg.append('g').selectAll('dot').data(sumStat)

    sumStat.forEach(s => {
      s.values.forEach((v: any) => {
        dots
          .enter()
          .append('circle')
          .attr('class', 'myCircle')
          .attr('cx', x(v.x))
          .attr('cy', y(+v.value))
          .attr('r', 5)
          .attr('stroke', 'none')
          .attr('stroke-width', 1)
          .attr('fill', 'none')
          .attr('pointer-events', 'all')
          .style('fill', COLORS(s.key))
          .attr('transform', 'translate(" + 100 + "," + 100 + ")')
          .on('mouseover', () => {
            focus.style('display', null)
          })
          .on('mouseout', () => {
            focus.style('display', 'none')
            d3.select('.year1').text('')
            d3.select('.year2').text('')
            svg.select('.path-hover').attr('fill', 'transparent')
          })
          .on('mousemove', _ => {
            focus.attr(
              'transform',
              'translate(' + x(v.x) + ',' + y(v.value) + ')'
            )
            focus.select('.tooltip-series').text('"' + s.key + '"')
            focus.select('.tooltip-point').text(formatValue(v.x))
            focus.select('.tooltip-value').text(formatValueSN(v.value))
            focus.select('.x-hover-line').attr('y2', height - y(v.value))
            focus.select('.y-hover-line').attr('x2', width + width)
          })
      })
    })
  }

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout)
    }
    // wait half a second in case they're mid resize
    this.resizeTimeout = window.setTimeout(
      (() => {
        this.update()
      }).bind(this),
      500
    )
  }

  private calculateDimensions(): void {
    let graphHeight = 0
    let graphWidth = 0
    let h1
    let h2
    if (this.element.nativeElement.children.length === 4) {
      h1 = this.element.nativeElement.children[1].offsetHeight
      h2 = this.element.nativeElement.children[2].offsetHeight
    } else if (this.element.nativeElement.children.length === 3) {
      h1 = this.element.nativeElement.children[0].offsetHeight
      h2 = this.element.nativeElement.children[1].offsetHeight
    }

    if (this.chartWidth && this.chartHeight) {
      graphWidth = this.chartWidth
      graphHeight = this.chartHeight
    } else {
      graphWidth =
        this.element.nativeElement.offsetParent.offsetWidth * 0.45 * 2
      graphHeight =
        this.element.nativeElement.offsetParent.offsetHeight * 0.45 * 2 -
        h1 -
        h2 -
        10
    }
    this.svgwidth = graphWidth
    this.svgheight = Math.max(graphHeight, 650)
  }

  private update(): void {
    this.calculateDimensions()
    if (this.dataGraph) {
      this.drawGraph(this.dataGraph)
    }
  }
}
