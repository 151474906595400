import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Program } from '../../../core/model/program.model'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import {
  GroupBar,
  GrouperSlide,
  ProgramGroup,
} from '../../store/grouper/program-group.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tower-controls',
  styleUrls: ['./tower-controls.component.scss'],
  templateUrl: './tower-controls.component.html',
})
export class GroupTowerControlsComponent {
  @Input() slide: GrouperSlide
  @Input() name: string
  @Input() page: string
  @Input() minimizeGroupName?: string
  @Input() programType?: boolean
  @Input() scenarios?: (Program | ProgramGroup)[]
  @Input() scenariosSelected: Record<string, boolean>
  @Input() optimizations?: (Program | ProgramGroup)[]
  @Input() optimizationsSelected: Record<string, boolean>
  @Input() title: string

  @Input() set minimizeProgram(value: boolean) {
    this._minimizeProgram = coerceBooleanProperty(value)
  }
  get minimizeProgram() {
    return this._minimizeProgram
  }
  @HostBinding('class.minimize-program') _minimizeProgram = false

  @HostBinding('class.minimize-group') get hasMinimizeGroupName() {
    return this.minimizeGroupName != null
  }

  @Input() set showAgg(value: boolean) {
    this._showAgg = coerceBooleanProperty(value)
  }
  get showAgg() {
    return this._showAgg
  }
  private _showAgg = true

  @Input() set showOcc(value: boolean) {
    this._showOcc = coerceBooleanProperty(value)
  }
  get showOcc() {
    return this._showOcc
  }
  private _showOcc = true

  @Input() set hideShowTower(value: boolean) {
    this._hideShowTower = coerceBooleanProperty(value)
  }
  get hideShowTower() {
    return this._hideShowTower
  }
  _hideShowTower = false

  @Input() set hideShowAgg(value: boolean) {
    this._hideShowAgg = coerceBooleanProperty(value)
  }
  get hideShowAgg() {
    return this._hideShowAgg
  }
  _hideShowAgg = false

  @Input() set hideShowOcc(value: boolean) {
    this._hideShowOcc = coerceBooleanProperty(value)
  }
  get hideShowOcc() {
    return this._hideShowOcc
  }
  _hideShowOcc = false

  @Input() set selectMode(value: boolean) {
    this._selectMode = coerceBooleanProperty(value)
  }
  get selectMode() {
    return this._selectMode
  }
  _selectMode = false

  @Input() set isScenario(value: boolean) {
    this._isScenario = coerceBooleanProperty(value)
  }
  get isScenario() {
    return this._isScenario
  }
  _isScenario = false

  @Output() towerMinimize = new EventEmitter<void>()
  @Output() towerRestore = new EventEmitter<void>()
  @Output() showAggChange = new EventEmitter<boolean>()
  @Output() programRemove = new EventEmitter<void>()
  @Output() showOccChange = new EventEmitter<boolean>()
  @Output() scenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<Program>
  >()

  get deepestGroupBar(): GroupBar | null {
    const bars = this.slide.groupBars.filter(gb => gb != null)
    if (bars.length > 0) {
      return bars[bars.length - 1]
    }
    return null
  }

  get leftEdges() {
    return (
      (this.deepestGroupBar &&
        this.deepestGroupBar.leftEdges.filter(le => le.paletteIndex !== -1)) ||
      []
    )
  }

  get rightEdges() {
    return (
      (this.deepestGroupBar &&
        this.deepestGroupBar.rightEdges.filter(re => re.paletteIndex !== -1)) ||
      []
    )
  }

  get towerIcon(): string {
    return !this.minimizeProgram ? 'minimize' : 'crop_square'
  }

  get towerTooltip(): string {
    return !this.minimizeProgram ? 'Snap Tower Up' : 'Show Tower'
  }

  onShowTowerClick(): void {
    return !this.minimizeProgram
      ? this.towerMinimize.emit()
      : this.towerRestore.emit()
  }

  onShowAggClick(): void {
    return this.showAggChange.emit(!this.showAgg)
  }

  onShowOccClick(): void {
    return this.showOccChange.emit(!this.showOcc)
  }

  onRemoveClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    this.programRemove.emit()
  }
}
