import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../../core/store'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs'
import {
  AccountOpportunity,
  CarrierAccount,
  StudyDataWithYear,
} from '../../../api/model/backend.model'
import {
  selectAccountOpportunities,
  selectCarrierAccountMap,
  selectIsSaving,
} from '../../../core/store/accountopportunity.selectors'
import { selectClientsByID } from '../../../core/store/clients.selectors'
import { Client } from '../../../core/model/client.model'
import { StudyData } from '../../../admin/model/admin.model'
import * as fromProgramInitiation from '../../store/program-initation.actions'
import { map } from 'rxjs/operators'
import { ApiResponse } from '../../../api/model/api.model'
import { BackendService } from '../../../api/backend/backend.service'
import { selectCurrentClientID } from '../../../core/store/broker/broker.selectors'
import { AccountMappingData } from '../../model/program-initiation.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-initiation-container',
  templateUrl: `./program-initiation.container.html`,
})
export class ProgramInitiationContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  accountOpportunities$: Observable<AccountOpportunity[] | null>
  unfilteredAccountOpportunities$: Observable<AccountOpportunity[] | null>
  carrierAccounts$: Observable<CarrierAccount[] | null>
  currentClientID$: Observable<string | null>
  allCarriers$: ApiResponse<any>
  isSaving$: Observable<boolean | null>
  carriersByID$: Observable<Record<string, Client> | null>

  filterOpportunities$ = new BehaviorSubject<string[]>([])

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog,
    public backendService: BackendService
  ) {}

  ngOnInit() {
    this.unfilteredAccountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.accountOpportunities$ = combineLatest([
      this.store.pipe(select(selectAccountOpportunities)),
      this.filterOpportunities$,
    ]).pipe(
      map(([opportunities, filters]) => {
        if (filters === null || filters.length === 0) {
          return opportunities
        } else {
          return opportunities.filter(o => filters.includes(o.oppId))
        }
      })
    )

    this.allCarriers$ = this.backendService.getCarriers()
    this.carrierAccounts$ = this.store.pipe(select(selectCarrierAccountMap))
    this.isSaving$ = this.store.pipe(select(selectIsSaving))
    this.carriersByID$ = this.store.pipe(select(selectClientsByID))
    this.currentClientID$ = this.store.pipe(select(selectCurrentClientID))
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onNewProgramCreate(program: Partial<StudyData>) {
    this.store.dispatch(
      fromProgramInitiation.createNewProgramFromOpportunity({ program })
    )
  }

  onNewYearProgramCreate(program: Partial<StudyDataWithYear>) {
    this.store.dispatch(
      fromProgramInitiation.createNewYearProgramFromOpportunity({ program })
    )
  }

  onNewCarrierYearProgramCreate(data: any) {
    const carrier = data.carrier
    const program = data.program
    const carrierLogoFormData = data.carrierLogoFile

    this.store.dispatch(
      fromProgramInitiation.createNewCarrierYearProgramFromOpportunity({
        carrier,
        program,
        carrierLogoFormData,
      })
    )
  }

  onBackClick() {
    this.router.navigate(['/home'])
    return false
  }

  filterAccountOpportunities(oppids: string[]) {
    this.filterOpportunities$.next(oppids)
  }

  onUpdateSFMapping(accountMapping: AccountMappingData): void {
    this.store.dispatch(
      fromProgramInitiation.updateSFAccountCarrierMapping({ accountMapping })
    )
  }
}
