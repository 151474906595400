import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from './size.mixin'

class RankBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof RankBase = mixinSize(RankBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-rank',
  styles: [
    `
           :host {
             --action-text-size: var(--font-size);
           }
     
           label {
             display: inline-flex;
             justify-content: center;
             font-size: var(--action-text-size);
             font-weight: var(--font-header-weight);
             /** Min width is 2x the inset padding subtracted from the height */
             min-width: calc(
               var(--action-text-size) + 0.5rem - 2 *
                 (var(--action-text-size) / 5 + 0.21rem)
             );
             border-radius: 5px;
             cursor: auto;
             height: calc(var(--action-text-size) + 0.5rem);
             line-height: calc(var(--action-text-size) + 0.5rem);
             padding-left: calc(var(--action-text-size) / 5 + 0.21rem);
             padding-right: calc(var(--action-text-size) / 5 + 0.21rem);
             outline: none;
     
             color: var(--body-inverse);
             background: rgba(var(--rgb), 0.9);
             border: 1px solid var(--color);
           }
     
           /* Size mixin */
     
           :host.mini {
             --action-text-size: var(--font-size-mini);
           }
     
           :host.mini label {
             padding: 0 3px;
             height: 14px;
             line-height: 14px;
             border-radius: 2px;
           }
     
           :host.tiny {
             --action-text-size: var(--font-size-tiny);
           }
     
           :host.tiny label {
             padding: 0 4px;
             height: 16px;
             line-height: 16px;
             border-radius: 3px;
           }
     
           :host.small {
             --action-text-size: var(--font-size-small);
           }
     
           :host.big {
             --action-text-size: var(--font-size-big);
           }
     
           :host.huge {
             --action-text-size: var(--font-size-huge);
           }
     
           :host.gigantic {
             --action-text-size: var(--font-size-gigantic);
           }
     
           :host.massive {
             --action-text-size: var(--font-size-massive);
           }
         `,
  ],
  template: `
    <label [ngClass]="ragClass">{{ rank }}</label>
  `,
})
export class RankComponent extends _SizeMixinBase implements CanSize {
  @Input() rank: number
  @Input() max: number

  // Size mixin
  @Input() size: Size
  @Input() massive: boolean
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @Input() mini: boolean
  @HostBinding('class.massive')
  get isSizeMassive() {
    return this.size === 'massive'
  }
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  get ragClass() {
    const p = 100 - ((this.rank - 1) / (this.max - 1)) * 100
    const p5 = Math.round(p / 5) * 5
    return {
      [`app-rag-${p5}`]: true,
    }
  }

  constructor(public elementRef: ElementRef) {
    super(elementRef)
  }
}
