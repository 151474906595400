<a (click)="showInfo.emit(financialInfo)">
  <!-- <img [src]="imgSrc" [title]="reinsurer.name" [alt]="reinsurer.name" /> -->
  <div class="names">
    <div
      *ngIf="reinsurer.name || reinsurer.reinsurerProgramFactor[0].obo_name"
      class="card"
      [ngStyle]="
        reinsurer.market_use === fundManagerMarketUse && { color: '#FFFFFF' }
      "
    >
      {{ name }}
    </div>
    <div
      *ngIf="subHeader"
      class="sub-header"
    >
      {{ subHeader }}
    </div>
  </div>
  <div
    *ngIf="reinsurer.country_code"
    class="bottom-right"
    [ngStyle]="
      reinsurer.market_use === fundManagerMarketUse && { color: '#FFFFFF' }
    "
  >
    {{ reinsurer.country_code }}
  </div>
  <div *ngIf="reinsurer.market_use === agencyMarketUse" class="bottom-left">
    Agency
  </div>
  <div
    *ngIf="
      reinsurer.market_use !== agencyMarketUse &&
      reinsurer.market_use !== fundManagerMarketUse
    "
    class="bottom-left"
  >
    Reinsurer
  </div>
  <div
    *ngIf="reinsurer.market_use === fundManagerMarketUse"
    class="bottom-left"
    [ngStyle]="{ color: '#FFFFFF' }"
  >
    Fund Manager
  </div>
  <button
    *ngIf="checkCompanyPapers()"
    appButtonIcon
    huge
    class="top-right"
    disableRipple
    (click)="
      $event.preventDefault();
      $event.stopPropagation();
      onFundManagerModalMenuClick()
    "
    matTooltip="Click to open company paper selection."
    matTooltipPosition="right"
  >
    more_horiz
  </button>
</a>
