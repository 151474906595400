import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CanDeactivateGuard } from '../guards/can-deactivate.guard'
import { RoleGuard } from '../guards/role-guard.service'
import { GroupCompareRouteContainerComponent } from './group/group-compare-route.container'
import { PortfolioRouteContainerComponent } from './portfolio/portfolio-route.container/portfolio-route.container'
import { TowerContainerComponent } from './tower/tower.container'
import { GroupAnimatedScenariosContainerComponent } from './animated-scenarios/group/group-animated-scenarios.container/group-animated-scenarios.container'
import { AnimatedScenariosContainerComponent } from './animated-scenarios/animated-scenarios.container/animated-scenarios.container'
import { ExploreContainerComponent } from './explore/explore.container/explore.container'
import { TechnicalPremiumContentContainerComponent } from './technical-premium/technical-premium-content-container/technical-premium-content.container'

const routes: Routes = [
  {
    path: 'group-animated-scenarios',
    component: GroupAnimatedScenariosContainerComponent,
    canActivate: [RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { contextPath: ['analysis', 'group-animated-scenarios'] },
  },
  {
    path: 'group',
    component: GroupCompareRouteContainerComponent,
    canActivate: [RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { contextPath: ['analysis', 'group'] },
  },
  {
    path: 'group/:parameter',
    component: GroupCompareRouteContainerComponent,
    canActivate: [RoleGuard],
    data: { contextPath: ['analysis', 'group'] },
  },
  {
    path: 'compare',
    component: GroupCompareRouteContainerComponent,
    canActivate: [RoleGuard],
    data: { contextPath: ['analysis', 'compare'] },
  },
  {
    path: ':analysisID/portfolios/:portfolios',
    component: PortfolioRouteContainerComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'tower',
      },
      {
        path: 'tower',
        component: TowerContainerComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'layer-details',
        loadChildren: () =>
          import('./layer-details/layer-details.module').then(
            m => m.LayerDetailsModule
          ),
      },
      {
        path: 'layer-modeling',
        loadChildren: () =>
          import('./layer-modeling/layer-modeling.module').then(
            m => m.LayerModelingModule
          ),
      },
      {
        path: 'simulated-trial',
        loadChildren: () =>
          import('./simulated-trial/simulated-trial.module').then(
            m => m.SimulatedTrailModule
          ),
      },
      {
        path: 'animated-scenarios',
        component: AnimatedScenariosContainerComponent,
      },
      {
        path: 'explore-gross',
        component: ExploreContainerComponent,
      },
      {
        path: 'technical-premium',
        canActivate: [RoleGuard],
        canDeactivate: [CanDeactivateGuard],
        component: TechnicalPremiumContentContainerComponent
      },
    ],
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalysisRoutingModule {}
