import { partialRight } from 'ramda'
import { Layer, PhysicalLayer } from 'src/app/analysis/model/layers.model'
import {
  LogicalPortfolioLayer,
  LossSetLayer,
  Metadata,
} from 'src/app/api/analyzere/analyzere.model'

export type MultiSectionLayerType = 'cat_multisection' | 'noncat_multisection'
export type MultiSectionLayerSubtypes =
  | 'visible-layer'
  | 'main-layer'
  | 'section-layer'
  | 'flipper-layer'

type HasMetadata<T> = T & { meta_data: Partial<Metadata> }

const multiSectionLayerTypes: (
  | MultiSectionLayerType
  | Omit<string, MultiSectionLayerType>
)[] = ['cat_multisection', 'noncat_multisection']

export const isMultiSectionLayer = <T>(
  { meta_data: m }: HasMetadata<T>,
  ...subtypes: (
    | MultiSectionLayerSubtypes
    | Omit<string, MultiSectionLayerSubtypes>
  )[]
): boolean =>
  !!m.sage_layer_type &&
  multiSectionLayerTypes.includes(m.sage_layer_type) &&
  (subtypes.length === 0 || subtypes.includes(m.sage_layer_subtype ?? ''))

export const isMultiSectionMainLayer = partialRight<
  Parameters<typeof isMultiSectionLayer>[0],
  string,
  boolean
>(isMultiSectionLayer, ['main-layer'])

export const isSectionLayer = <T>({ meta_data: m }: HasMetadata<T>): boolean =>
  m.sage_layer_subtype === 'section-layer'

export function splitMultiSectionLayerSources(
  logicalLayer: LogicalPortfolioLayer
) {
  const lossSetSources = logicalLayer.sources as LossSetLayer[]
  const flipperSources = logicalLayer.sources as LogicalPortfolioLayer[]

  const lossSets = lossSetSources.filter(
    source => !isMultiSectionLayer(source, 'flipper-layer')
  )
  const flipper = flipperSources.find(source =>
    isMultiSectionLayer(source, 'flipper-layer')
  )

  return [lossSets, flipper] as const
}

export function asMultiSectionLayer(
  logicalLayer: LogicalPortfolioLayer,
  physicalLayer: PhysicalLayer,
  lossSets: LossSetLayer[],
  flipper?: LogicalPortfolioLayer
): Layer {
  const lossSetLayers = lossSets.map(source => ({
    id: source.id,
    loss_sets: source.loss_sets,
    meta_data: source.meta_data,
  }))

  const layerRefs = flipper?.id ? [flipper?.id] : []

  return {
    id: logicalLayer.id,
    modified_date: logicalLayer.modified,
    lossSetLayers,
    layerRefs,
    physicalLayer,
    meta_data: logicalLayer.meta_data,
    sharedLayerID: '',
    viewMetrics: {
      loading: false,
      error: null,
      metrics: null,
      rss: null,
    },
  }
}

export function letterFromDescription(description: string | null) {
  const re = description?.match(/Section (.+)/)
  if (!re) {
    return null
  }

  return re[1]
}
