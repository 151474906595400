import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'titleCaseWithSpace',
})
export class TitleCaseWithSpacePipe implements PipeTransform {
  transform(value: string): string {
    const replace = value.replace(/([A-Z])/g, ' $1')
    return replace.charAt(0).toUpperCase() + replace.slice(1)
  }
}
