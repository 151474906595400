<app-group-towers
  [dirty]="dirty"
  [entities]="entities$ | async"
  [filteredEntities]="filteredEntities$ | async"
  [slides]="slides$ | async"
  [studies]="studies$ | async"
  [groups]="groups$ | async"
  [groupsByID]="groupsByID$ | async"
  [groupBarsByID]="groupBarsByID$ | async"
  [sharedLayers]="sharedLayers"
  [inuranceSource]="inuranceSource$ | async"
  [inuranceTarget]="inuranceTarget$ | async"
  [minimizedByID]="minimizedByID$ | async"
  [analysisProfileID]="analysisProfileID"
  [slideIndex]="slideIndex$ | async"
  [towerSizePercentage]="towerSizePercentage$ | async"
  [size]="size$ | async"
  [sharedIDGroup]="sharedIDGroup$ | async"
  [years]="years$ | async"
  [programGroups]="programGroups"
  [clientProgramGroupMembers]="clientProgramGroupMembers"
  [programsByID]="programsByID$ | async"
  [selectedProgramIDs]="selectedProgramIDs"
  [selectMode]="selectMode"
  [isSharedLimitPanelOpen]="sharedLimitMode !== 'none'"
  [isInuranceToolbarOpen]="inuranceMode !== 'none'"
  [inuranceTagsByLevel]="inuranceTagsByLevel$ | async"
  [programGroupMembers]="programGroupMembers$ | async"
  [groupCurrency]="groupCurrency$ | async"
  [groupScenariosByID]="groupScenariosByID$ | async"
  [currencyRates]="currencyRates$ | async"
  [analysisProfileCurrency]="analysisProfileCurrency"
  [slidesPerView]="slidesPerView$ | async"
  (showAggChange)="onShowAggChange($event)"
  (showOccChange)="onShowOccChange($event)"
  (layerClick)="onLayerClick($event)"
  (inuranceTagClick)="onInuranceTagClick($event)"
  (programBarClick)="onProgramBarClick($event)"
  (programGroupBarClick)="onProgramGroupBarClick($event)"
  (programGroupAdd)="onProgramGroupAdd($event)"
  (programGroupRemove)="onProgramGroupRemove($event)"
  (programGroupMinimizeToggle)="onProgramGroupMinimizeToggle($event)"
  (programGroupDelete)="onProgramGroupDelete($event)"
  (programAdd)="onProgramAdd($event)"
  (programRemove1)="onProgramRemove($event)"
  (programRemove)="programRemove.emit($event)"
  (programMove)="onProgramMove($event)"
  (programMinimize)="onProgramMinimize($event)"
  (programRestore)="onProgramRestore($event)"
  (slideIndexChange)="onSlideIndexChange($event)"
  (saveUntitled)="onSaveUntitled($event)"
  (animatedScenarios)="onAnimatedScenarios($event)"
  (groupLayerDetails)="onGroupLayerDetails($event)"
  (groupScenariosClick)="onGroupScenariosClick($event)"
  (programGroupRename)="onProgramGroupRename($event)"
></app-group-towers>
