import {
  StructureLayerDataResponse,
  StructureMarketContentResponse,
  StructureMarketLayersResponse,
} from '../../api/model/backend.model'

export interface Program {
  id: string
  studyID: string
  label: string
  description?: string
  programType: string
  parentGrossPortfolioID?: string
  grossPortfolioID: string
  cededPortfolioID: string
  netPortfolioID: string
  analysisID: string
  imageSrc?: string
  imageSrcGroup?: string[]
  checkGroup?: boolean
  checkGroupOcc?: boolean
  newGroupCheck?: boolean
  checkCompare?: boolean
  groupSelected?: string | null
  compareSelected?: string | null
  layerData?: StructureLayerDataResponse[]
  tremor?: string
  marketLayer?: StructureMarketLayersResponse[]
  marketContent?: StructureMarketContentResponse[]
  towerPreferences?: StructureTowerPreferences
  parentGroupID?: string
  isScenario?: boolean
  parentScenarioID?: string
  scenarioIDs?: string[]
  yearID: string
  position_index?: number
  isOptimization?: boolean
  parentOptimizationID?: string
  optimizationIDs?: string[]
  tailMetricsOptions?: string
  lastModified?: string
  libRE?: string
  structureCurrency?: string
  autobuildID?: string
  folderID?: string | null | undefined
  fotCount?: number
  quoteCount?: number
}

export interface StructureTowerPreferences {
  occurrence: TowerPreferences
  aggregate: TowerPreferences
  log: boolean
  snapping: boolean
  logMin: number
}

export interface TowerPreferences {
  incrementsY: number | null
  incrementsYDirty: boolean
  maxY: number | null
  maxYDirty: boolean
  most: number | null
}

export function isProgram(program: any): program is Program {
  const p = program as Program
  return p.programType != null && p.cededPortfolioID != null
}
