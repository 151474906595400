import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import {
  map,
  mergeMap,
  concatMap,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators'
import {
  mapAssoc,
  rejectError,
  mergeApiResponses,
  rejectErrorWithInput,
  mergeMapWithInput,
  switchMapWithInput,
  executeSequentially,
  mapToMaybeData,
} from '../../../../api/util'
import { AppState } from '../../../../core/store'
import { loadProgramGroupEntitiesResponse } from '../load-program-group-entities'
import { createFetchPortfolioViewActionsFromGroups } from './create-portfolio-view-action'
import * as ProgramGroupActions from './program-group.actions'
import { ApiResponse, MaybeData, MaybeError } from 'src/app/api/model/api.model'
import { ProgramGroupMember, ProgramGroupSet } from '../program-group.model'
import { Program } from 'src/app/core/model/program.model'
import {
  LogicalPortfolioLayer,
  Portfolio,
} from 'src/app/api/analyzere/analyzere.model'
import { forkJoin, Observable, of } from 'rxjs'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { ReconcileWarningDialogComponent } from '../../../group/components/reconcile-warning-dialog/reconcile-warning-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { selectAllAncestorGroupsWithID } from 'src/app/core/store/program-group-member.selectors'
import { DeleteGroupDialogService } from '../../../group/services/delete-group-dialog.service'
import { selectProgramGroupsByID } from '../../../../core/store/program-group/program-group.selectors'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import { saveGrouper } from '../grouper.actions'
import { extractPortfolioSetID } from 'src/app/analysis/model/portfolio-set-id.util'
import {
    selectGroupCurrency,
  selectGrouperProgramGroupMembers,
  selectGrouperSlideProgramEntitiesData,
  selectPortfolioSetEntitiesByID,
} from '../../analysis.selectors'
import { LayerState } from '../../ceded-layers/layers.reducer'
import { LayerMetrics } from 'src/app/analysis/model/layers-metrics.model'
import { Layer } from 'src/app/analysis/model/layers.model'
import { KeyValuePair } from 'ramda'
import { createLayerMetricsViews } from '../../metrics/layer-metrics.selectors'
import { LayerView } from 'src/app/analysis/model/layer-view'
import { GroupLayerDetailsDialogService } from '../../../group/services/group-layer-details-dialog.service'
import { setCurrentAnalysisProfile } from '../../../../core/store/broker/broker.actions'
import {
  selectCurrentAnalysisProfile,
  selectCurrentClient,
  selectCurrentClientID,
  selectCurrentStudy,
} from '../../../../core/store/broker/broker.selectors'
import { environment } from 'src/environments/environment'
import { ProgramGroupResponse } from 'src/app/api/model/backend.model'
import { HttpClient } from '@angular/common/http'
import { convertAllProgramGroupsFromResponse } from 'src/app/api/program-group/program-group.converter'
import { selectStudies } from 'src/app/core/store/auth/auth.selectors'
import { ExcelExportService } from '@shared/services/excel-export.service'
import { selectAccountOpportunities } from 'src/app/core/store/accountopportunity.selectors'
import { getEffectiveDate, getLayerListAsString, styleHeader } from '../grouper-util'
import { DatePipe } from '@angular/common'

// tslint:disable: no-non-null-assertion
@Injectable()
export class GrouperProgramGroupEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private inuranceService: InuranceService,
    private deleteGroupDialog: DeleteGroupDialogService,
    private groupLayerDetailsDialog: GroupLayerDetailsDialogService,
    private datePipe: DatePipe,
    private excelExport: ExcelExportService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}

  fetchProgramGroupOnAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.addProgramGroup),
      map(({ type, ...props }) => props),
      mergeMap(props => {
        const parentID = props.parentGroupID ? props.parentGroupID : ''
        return loadProgramGroupEntitiesResponse(this.store, props.id, parentID)
      }),
      rejectError(error =>
        this.store.dispatch(
          ProgramGroupActions.addProgramGroupFailure({ error })
        )
      ),
      withLatestFrom(this.store.pipe(select(selectCurrentAnalysisProfile))),
      map(([payload, analysisProfile]) => {
        // Load Analysis Profile if necessary
        const newAnalysisID = payload.programs[0].analysisID
        if (!analysisProfile || analysisProfile.id !== newAnalysisID) {
          this.store.dispatch(setCurrentAnalysisProfile({ id: newAnalysisID }))
        }
        return payload
      }),
      // Create actions to fetch the portfolio views for each group being loaded
      mapAssoc('actions', payload =>
        createFetchPortfolioViewActionsFromGroups(payload.programGroups)
      ),
      mergeMap(({ actions, ...payload }) => [
        ProgramGroupActions.addProgramGroupSuccess(payload),
        ...actions,
      ])
    )
  )

  checkSharedLimitInuranceOnGroupAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.addProgramGroupToGroup),
      map(({ type, ...props }) => props),
      mergeMapWithInput(props => {
        const parentID = props.parentGroupID ? props.parentGroupID : ''
        const programGroups: ApiResponse<
          ProgramGroupSet & { id: string; parentGroupID: string }
        >[] = []
        programGroups.push(
          loadProgramGroupEntitiesResponse(this.store, props.id, parentID)
        )
        programGroups.push(
          loadProgramGroupEntitiesResponse(this.store, parentID, '')
        )
        return forkJoin(programGroups).pipe(mergeApiResponses())
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          ProgramGroupActions.addProgramGroupFailure({ error })
        )
      ),
      switchMapWithInput(([loadResponses]) => {
        let programArray: Program[] = []
        const portfolioAction: ApiResponse<Portfolio>[] = []
        let result: LogicalPortfolioLayer[] = []
        for (const loadResponse of loadResponses) {
          programArray = programArray.concat(loadResponse.programs)
        }
        for (const id of programArray) {
          portfolioAction.push(this.service.fetchPortfolio(id.cededPortfolioID))
        }
        return forkJoin(portfolioAction).pipe(
          map(portfolioResponses => {
            for (const portfolioResponse of portfolioResponses) {
              if (portfolioResponse.error) {
                return { error: portfolioResponse.error }
              } else {
                result = result.concat(
                  portfolioResponse.data?.layers as LogicalPortfolioLayer[]
                )
              }
            }
            return { data: result }
          })
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          ProgramGroupActions.addProgramGroupFailure({ error })
        )
      ),
      map(([layerArray, [_, props]]) => {
        let isWarning = false
        isWarning = layerArray.some(
          l =>
            l.meta_data.sage_layer_type === 'shared_limit' ||
            l.meta_data.inuranceSource ||
            l.meta_data.inuranceTarget
        )
        if (isWarning) {
          this.dialog.open(ReconcileWarningDialogComponent, {
            width: '30vw',
          })
          return { type: 'No Action' }
        } else {
          return ProgramGroupActions.addProgramGroup(props)
        }
      })
    )
  )

  deleteProgramGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProgramGroupActions.deleteProgramGroup),
        map(({ type, ...props }) => props),
        concatMap(props =>
          of(props).pipe(
            withLatestFrom(
              this.store.pipe(
                select(
                  selectAllAncestorGroupsWithID({
                    programGroupID: props.groupBar.id,
                  })
                )
              )
            )
          )
        ),
        withLatestFrom(this.store.pipe(select(selectProgramGroupsByID))),
        switchMapWithInput(([[props, _], allGroups]) => {
          // Fetch ceded layers of group
          const cededPortfolioIDs: string[] = []
          cededPortfolioIDs.push(
            allGroups[props.groupBar.id]!.cededPortfolioID!
          )
          return this.inuranceService
            .fetchLayersFromPortfolios(cededPortfolioIDs)
            .pipe(
              switchMap(response => {
                if (response.error) {
                  return of({ error: response.error })
                } else {
                  // Loop through layers and see if any are shared limit
                  const ogLayerIDs: string[] = []
                  response.data!.forEach(layer => {
                    if (layer.meta_data.sage_layer_type === 'shared_limit') {
                      ogLayerIDs.push(layer.meta_data.backAllocatedForID!)
                    }
                  })
                  // If no shared limits, return false
                  if (ogLayerIDs.length < 1) {
                    return of({ data: false })
                  } else {
                    // Shared Limit Layers exist - go through OG Layers and look for inurance
                    return this.service
                      .fetchLayers<LogicalPortfolioLayer>(ogLayerIDs)
                      .pipe(
                        switchMap(res => {
                          if (res.error) {
                            return of({ error: res.error })
                          } else {
                            let hasInurance = false
                            res.data!.forEach(ogLayer => {
                              if (
                                ogLayer.meta_data.inuranceSourceFor?.includes(
                                  props.groupBar.id
                                ) ||
                                ogLayer.meta_data.inuranceTargetFor?.includes(
                                  props.groupBar.id
                                )
                              ) {
                                hasInurance = true
                              }
                            })
                            return of({ data: hasInurance })
                          }
                        })
                      )
                  }
                }
              })
            )
        }),
        rejectErrorWithInput(error =>
          this.store.dispatch(
            ProgramGroupActions.deleteProgramGroupFailure({ error })
          )
        ),
        concatMap(([hasSLI, [[props, ancestorGroups], _]]) => {
          const isChild = ancestorGroups.length > 0
          return this.deleteGroupDialog
            .open(props.allowDelete, isChild, hasSLI, props.groupBar.label)
            .afterClosed()
            .pipe(
              map(action => {
                return { action, props }
              })
            )
        }),
        map(data => {
          if (data.action === 'delete') {
            this.store.dispatch(
              ProgramGroupActions.deleteProgramGroupSuccess({
                id: data.props.groupBar.id,
              })
            )
            this.store.dispatch(saveGrouper())
          }
        })
      ),
    { dispatch: false }
  )

  fetchProgramGroupLayerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.fetchProgramGroupLayerDetails),
      withLatestFrom(
        this.store.pipe(select(selectGrouperSlideProgramEntitiesData)),
        this.store.pipe(select(selectPortfolioSetEntitiesByID)),
        this.store.pipe(select(selectGrouperProgramGroupMembers)),
        this.store.pipe(select(selectStudies))
      ),
      concatMap(
        ([
          { groupName, groupID },
          programEntity,
          portfolioEntitySet,
          members,
          studies,
        ]) => {
          let viewMetricIDsByLayerID: Record<string, string> = {}
          const porfolioViewIDs = Object.values(portfolioEntitySet)
          let cededLayersByID: Record<string, LayerState>
          const actions: Array<
            Observable<
              MaybeError & MaybeData<{ metrics: LayerMetrics; layer: Layer }>
            >
          > = []
          programEntity = programEntity.filter(p => {
            const parentGroupID = p.program.parentGroupID
            if (parentGroupID) {
              const ancestors = [
                ...this.getAllAncestors(members, parentGroupID),
                parentGroupID,
              ]
              return ancestors.includes(groupID)
            } else {
              return false
            }
          })

          let studyIDs: string[] = []
          programEntity.forEach(e => {
            studyIDs.push(e.program.studyID)
            // tslint:disable-next-line: no-non-null-assertion
            const portfolioSetID = extractPortfolioSetID(e.program)!
            const selectedPortfolioSet = porfolioViewIDs.find(
              p => p && JSON.stringify(p.id) === JSON.stringify(portfolioSetID)
            )
            viewMetricIDsByLayerID =
              selectedPortfolioSet?.portfolioView.cededLayersViewIDs!
            cededLayersByID = this.convertArrayToObject(e.cededLayers)
            for (const key in viewMetricIDsByLayerID) {
              if (
                e.cededLayers.map(l => l.layer.id).includes(key) &&
                selectedPortfolioSet
              ) {
                const layerSelected = e.cededLayers.find(
                  l1 => l1.layer.id === key
                )?.layer
                actions.push(
                  this.service
                    .getLayersViewViewMetricsAndCalculate(
                      viewMetricIDsByLayerID[key],
                      selectedPortfolioSet?.portfolioView.grossPortfolioViewID,
                      selectedPortfolioSet?.portfolioView.cededLayersViewIDs,
                      cededLayersByID,
                      e.cededLayers,
                      false
                    )
                    .pipe(
                      map(response => {
                        if (response.error) {
                          return { error: response.error }
                        } else {
                          return {
                            data: {
                              metrics: response.data as LayerMetrics,
                              layer: layerSelected!,
                            },
                          }
                        }
                      })
                    )
                )
              }
            }
          })
          return executeSequentially(actions).pipe(
            map(result => {
              const changes: KeyValuePair<Layer, LayerMetrics | null>[] = []
              if (result.error) {
                return { error: result.error }
              }
              const metricsAndLayerIDArray = result.data!
              for (const metricsAndLayerID of metricsAndLayerIDArray) {
                changes.push([
                  metricsAndLayerID.layer,
                  metricsAndLayerID.metrics,
                ])
              }
              return { data: changes, studyIDs, groupName, studies, groupID }
            })
          )
        }
      ),
      map(values => {
        let layerMetricViews: LayerView[] = []
        if (values.data && values.data?.length > 0) {
          layerMetricViews = createLayerMetricsViews(values.data, null, null)
        }
        if (layerMetricViews && values.groupName) {
          this.groupLayerDetailsDialog.open(
            layerMetricViews,
            values.groupName,
            values.studyIDs,
            values.studies || [],
            values.groupID
          )
        }
        return { type: 'No Action' }
      })
    )
  )

  exportLayerDetailsAsXlsx$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProgramGroupActions.exportLayerDetailsAsCsv),
        withLatestFrom(
          this.store.pipe(select(selectCurrentClient)),
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(selectCurrentStudy)),
          this.store.pipe(select(selectGroupCurrency))
        ),
        //@ts-ignore
        mergeMap(([{rows, columns, groupID, groupName}, client, accountOpportunity, study, groupCurrency]) => {
          const effectiveDate = getEffectiveDate(accountOpportunity, study, this.datePipe)
          const today = new Date().toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})
          const layerListDescription = getLayerListAsString(rows)

          const workbook = this.excelExport.createWorkbook()
          const worksheet = this.excelExport.appendWorksheet(workbook, "Layer Details")
          // ***** Title Section ***** \\
          this.excelExport.appendTitleRow(worksheet, client?.name || '')
          this.excelExport.appendSubheaderRow(worksheet, `${groupName}  - ${layerListDescription}`)
          this.excelExport.appendSubheaderRow(worksheet, "Effective: " + effectiveDate)
          this.excelExport.appendBlankRows(worksheet, 1)
          // *****    Title #2   ***** \\
          this.excelExport.appendTitleRow(worksheet, "Options Comparison")
          this.excelExport.appendSubheaderRow(worksheet, "As of " + today)
          this.excelExport.appendBlankRows(worksheet)
          // *****   Table Section  ***** \\
          this.excelExport.appendSortTable(worksheet, columns, rows, {currencyShownInThousands: true, styleFunc: styleHeader, currency: groupCurrency ?? 'USD'})
          this.excelExport.appendBlankRows(worksheet, 2)
          this.excelExport.appendHintRow(worksheet, "Currency values are shown in the thousands")
          this.excelExport.appendLogos(worksheet, workbook, 11, 21)
          // this.excelExport.autoSizeColumns(worksheet)
          this.excelExport.exportAsXlsx(workbook, `${client?.name} - ${groupName} - Group Layer Details - ${today}`)
          return []
        })
      ),
    {
      dispatch: false,
    }
  )

  updateProgramGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgramGroupActions.updateProgramGroup),
      withLatestFrom(this.store.pipe(select(selectCurrentClientID))),
      mergeMap(([{ group, fotCount, quoteCount }, clientID]) => {
        const id = group.id
        const url = `${environment.internalApi.base}/programgroups/${id}`
        const body: ProgramGroupResponse = {
          id: parseInt(id, 10),
          carrier_id: Number(clientID || '0'),
          label: group.label,
          carrier_year_id: Number(group.yearID),
          description: group.description,
          gross_portfolio_id: group.grossPortfolioID,
          ceded_portfolio_id: group.cededPortfolioID,
          net_portfolio_id: group.netPortfolioID,
          analysis_profile_id: group.analysisProfileID,
          is_scenario: group.isScenario,
          parent_scenario_id: Number(group.parentScenarioID),
          fot_count: fotCount,
          quote_count: quoteCount,
        }
        return this.http.put<ProgramGroupResponse>(url, body).pipe(
          map(res => convertAllProgramGroupsFromResponse([res])),
          mapToMaybeData()
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          ProgramGroupActions.updateProgramGroupFailure({ error })
        )
      ),
      map(data =>
        ProgramGroupActions.updateProgramGroupSuccess({
          group: data[0],
        })
      )
    )
  })

  private convertArrayToObject = (array: LayerState[]) =>
    array.reduce(
      (obj: Record<string, LayerState>, item) => (
        (obj[item.layer.id] = item), obj
      ),
      {}
    )

  private getAllAncestors(
    programGroupMembers: ProgramGroupMember[],
    groupID: string
  ) {
    const groupIDs: string[] = []
    const members = programGroupMembers.filter(
      m =>
        m.type === 'programGroup' &&
        m.programGroupID === groupID &&
        m.parentGroupID
    )
    if (members.length > 0) {
      groupIDs.push(...members.map(m => m.parentGroupID))
      for (const member of members) {
        groupIDs.push(
          ...this.getAllAncestors(programGroupMembers, member.parentGroupID)
        )
      }
      return groupIDs
    } else {
      return groupIDs
    }
  }
}
