import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { dissoc } from 'ramda'
import { Size } from '@shared/size.mixin'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import {
  ReturnPeriodRow,
  RETURN_PERIOD_PORTFOLIO_COLS,
  AggregationMethodType,
  Perspective,
} from '../../model/metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-return-period-table',
  styleUrls: ['./return-period-table.component.scss'],
  templateUrl: './return-period-table.component.html',
})
export class ReturnPeriodTableComponent implements OnInit {
  _data: ReturnPeriodRow[]
  newData: ReturnPeriodRow[]
  layerViewId = '1'

  @Input('data')
  set data(value: ReturnPeriodRow[]) {
    this._data = value
    if (this._isPortfolioMetrics) {
      this.newData = JSON.parse(JSON.stringify(value)).filter(
        (row: ReturnPeriodRow) => {
          return row.term !== 'Std Dev'
        }
      )
    } else {
      this.newData = JSON.parse(JSON.stringify(value)).map(
        (row: ReturnPeriodRow) => {
          return { ...row, header: <string>null, value: <number>null }
        }
      )
    }
  }
  _isPortfolioMetrics: boolean
  @Input('isPortfolioMetrics')
  set isPortfolioMetrics(value: boolean) {
    this._isPortfolioMetrics = value
    if (this._data) {
      if (value) {
        this.newData = this._data.filter((row: ReturnPeriodRow) => {
          return row.term !== 'Std Dev'
        })
      } else {
        this.newData = this._data.map((row: ReturnPeriodRow) => {
          return { ...row, header: <string>null, value: <number>null }
        })
      }
    }
  }

  @Input() rp1: number
  @Input() rp2: number
  @Input() rp3: number
  @Input() rp4: number
  @Input() rp5: number
  @Input() metrics?: PortfolioMetrics
  @Input() viewId: string

  @Input() size: Size
  @Input() displayFooter: boolean

  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  @HostBinding('class.no-size')
  get noSize() {
    return this.size == null
  }

  @Input() set noPadding(value: any) {
    this._noPadding = coerceBooleanProperty(value)
  }
  get noPadding() {
    return this._noPadding
  }
  @HostBinding('class.no-padding') _noPadding = false

  @Input() set noNames(value: any) {
    this._noNames = coerceBooleanProperty(value)
  }
  get noNames() {
    return this._noNames
  }
  @HostBinding('class.no-names') _noNames = false

  @Input() set showTogglesButton(value: any) {
    this._showTogglesButton = coerceBooleanProperty(value)
  }
  get showTogglesButton() {
    return this._showTogglesButton
  }
  _showTogglesButton = false

  get tableData() {
    if (this.noNames) {
      return this.newData.map(r => dissoc('term', r))
    }
    return this.newData
  }

  get displayedColumns(): string[] {
    if (this._isPortfolioMetrics) {
      if (this.layerViewId === '2') {
        return RETURN_PERIOD_PORTFOLIO_COLS.filter(
          c => c !== 'term' && !c.includes('period')
        )
      }
      if (this.noNames) {
        return RETURN_PERIOD_PORTFOLIO_COLS.filter(c => c !== 'term')
      }
      return RETURN_PERIOD_PORTFOLIO_COLS
    } else {
      if (this.noNames) {
        return RETURN_PERIOD_PORTFOLIO_COLS.filter(c => c !== 'term')
          .filter(c => c !== 'header')
          .filter(c => c !== 'value')
      }
      return RETURN_PERIOD_PORTFOLIO_COLS.filter(c => c !== 'header').filter(
        c => c !== 'value'
      )
    }
  }

  get title() {
    if (!this._isPortfolioMetrics) {
      return this.size === 'tiny' || this.size === 'mini'
        ? 'RP'
        : 'Return Period'
    }
    return ''
  }

  get titlePortfolio() {
    return this.size === 'tiny' || this.size === 'mini' ? 'RP' : "RP's"
  }

  get rpButtonSize() {
    return this.size === 'tiny' || this.size === 'mini' ? this.size : 'small'
  }

  get footerValue() {
    // LKTN-215 - now using appShortNumber pipe to denote suffix
    return this.size === 'tiny' || this.size === 'small' ? '' : '' // : 'Figures are $ million'
  }

  get dummyHeader() {
    return this.size === 'tiny' || this.size === 'small' ? '' : 'DUM'
  }

  @Output() rp1Change = new EventEmitter<number>()
  @Output() rp2Change = new EventEmitter<number>()
  @Output() rp3Change = new EventEmitter<number>()
  @Output() rp4Change = new EventEmitter<number>()
  @Output() rp5Change = new EventEmitter<number>()
  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()

  ngOnInit() {
    this.layerViewId = this.viewId ? this.viewId : '1'
  }
}
