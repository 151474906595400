import { createSelector } from '@ngrx/store'
import { KeyValuePair, zip } from 'ramda'
import { rejectNil } from '@shared/util/operators'
import { LayerView } from '../../model/layer-view'
import { Layer } from '../../model/layers.model'
import { filterTowerLayerResults } from '../../model/layers.util'
import {
  selectCededLayers,
  selectCededPortfolioViewLayersViewIDs,
  selectLayersViewMetricsEntitiesByID,
  selectPortfolioViewDetailGrossMetrics,
} from '../analysis.selectors'
import { LayerState } from '../ceded-layers/layers.reducer'
import { LayerMetricsState } from './layers-metrics.reducer'
import { LayerMetrics } from '../../model/layers-metrics.model'

const selectCededLayersWithViewMetrics = createSelector(
  selectCededLayers,
  selectCededPortfolioViewLayersViewIDs,
  selectLayersViewMetricsEntitiesByID,
  (layerStates, viewMetricIDsByLayerID, layerViewMetricsByID) => {
    const getLayerViewMetrics = makeGetLayerViewMetrics(
      viewMetricIDsByLayerID,
      layerViewMetricsByID,
      layerStates
    )
    const layers = layerStates
      .filter(filterTowerLayerResults)
      .map(ls => ls.layer)
    const metrics = layers.map(getLayerViewMetrics)

    return zip(layers, metrics)
  }
)

const selectGrossCovariance = createSelector(
  selectPortfolioViewDetailGrossMetrics,
  m => m && m.grossCovariance
)

export const createLayerMetricsViews = (
  layerMetricsPairs: KeyValuePair<Layer, LayerMetrics | null>[],
  grossPortfolioCovariance: number | null,
  cededLayers: LayerState[] | null
) => {
  const views = rejectNil(
    layerMetricsPairs.map(([layer, metrics]) => {
      if (metrics) {
        return new LayerView(cededLayers, layer, {
          metrics,
          grossPortfolioCovariance,
        })
      }
    })
  )
  const allLoaded = layerMetricsPairs.length === views.length

  return !allLoaded ? [] : views
}

export const selectLayerMetricsViews = createSelector(
  selectCededLayersWithViewMetrics,
  selectGrossCovariance,
  selectCededLayers,
  createLayerMetricsViews
)

export const getLayerViewMetricID = (
  viewIDMap: Record<string, string>,
  layerStates: LayerState[],
  layer?: Layer | null
): string | null => {
  if (!layer) {
    return null
  }

  const id: string = layer.id

  if (!layer.sharedLayerID) {
    return viewIDMap[id] || null
  }

  // It's shared limit: find its final nested layer
  const nestedLayerState = layerStates.find(
    ls => ls.layer.meta_data.backAllocatedForID === id
  )
  if (nestedLayerState) {
    const nestedLayerID = nestedLayerState.layer.id
    return viewIDMap[nestedLayerID] || null
  }
  return null
}

export const getSLLayerViewMetricID = (
  viewIDMap: Record<string, string>,
  layerStates: LayerState[],
  layer?: Layer | null
): string | null => {
  if (!layer) {
    return null
  }

  const id: string = layer.id

  if (!layer.sharedLayerID) {
    return viewIDMap[id] || null
  }
  // It's shared limit: find its Shared Layer
  const nestedLayerState = layerStates.find(
    ls =>
      ls.layer.meta_data.sage_layer_type === 'shared_limits' &&
      layer.sharedLayerID === ls.layer.id
  )
  if (nestedLayerState) {
    const nestedLayerID = nestedLayerState.layer.id
    return viewIDMap[nestedLayerID] || null
  }
  return null
}

const makeGetLayerViewMetrics =
  (
    viewIDMap: Record<string, string>,
    layerViewMetricsByID: Record<string, LayerMetricsState>,
    layerStates: LayerState[]
  ) =>
  (layer: Layer): LayerMetrics | null => {
    const viewMetricID = getLayerViewMetricID(viewIDMap, layerStates, layer)
    if (viewMetricID) {
      const metricsState = layerViewMetricsByID[viewMetricID] || null
      if (metricsState) {
        return metricsState.metrics
      }
    }
    return null
  }
