import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import {
  fetchLossSetLayers,
  fetchLossSetLayersFailure,
  fetchLossSetLayersSuccess,
  updateLossSetAAL,
  setLossSetAALSuccess,
  fetchParentLossSetLayersSuccess,
  fetchParentLossSetLayersFailure,
  updateGrossLossSets,
  setDirty,
  setLossSetSelectionMode,
  getLossSetGroupMetricsSuccess,
  getAllLossSetGroupsMetrics,
  fetchLossSetLayerViewsSuccess,
} from './loss-set-layers.actions'
import * as fromLossSetGroup from './loss-set-group/loss-set-group.reducer'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import { DEFAULT_PREMIUM } from 'src/app/api/analyzere/analyzere.model'

export interface State {
  loading: boolean
  error: string | null
  layers: LossSetLayer[]
  parentGrossLayers: LossSetLayer[]
  dirty: boolean
  lossSetSelectionMode: string
  lossSetGroups: fromLossSetGroup.LossSetGroupEntityState
  lossSetGroupsMetrics: Record<string, PortfolioMetrics>[]
  lossSetLayerViewIDs: Record<string, string>
}

export const initialState: State = {
  loading: false,
  error: null,
  layers: [],
  parentGrossLayers: [],
  dirty: false,
  lossSetSelectionMode: 'Gross',
  lossSetGroups: fromLossSetGroup.initialState,
  lossSetGroupsMetrics: [],
  lossSetLayerViewIDs: {},
}

const loadingReducer = createReducer(
  initialState.loading,
  on(fetchLossSetLayers, _ => true),
  on(fetchLossSetLayersSuccess, _ => false),
  on(fetchLossSetLayersFailure, _ => false),
  on(fetchParentLossSetLayersSuccess, _ => false),
  on(fetchParentLossSetLayersFailure, _ => false)
)

const errorReducer = createReducer(
  initialState.error,
  on(fetchLossSetLayers, _ => null),
  on(fetchLossSetLayersSuccess, _ => null),
  on(fetchLossSetLayersFailure, (_, { error }) => error.message),
  on(fetchParentLossSetLayersSuccess, _ => null),
  on(fetchParentLossSetLayersFailure, (_, { error }) => error.message)
)

const layersReducer = createReducer(
  initialState.layers,
  on(fetchLossSetLayersSuccess, (_, { lossSetLayers }) => lossSetLayers),
  on(updateGrossLossSets, (_, { lossSetLayers }) => lossSetLayers)
)

const parentGrossLayersReducer = createReducer(
  initialState.parentGrossLayers,
  on(
    fetchParentLossSetLayersSuccess,
    (_, { parentLossSetLayers }) => parentLossSetLayers.map(layer => {
      let premium = layer.premium
      if (!premium) {
        premium = DEFAULT_PREMIUM
      }
      return {
        ...layer,
        premium
      }
    })
  ),
  on(updateLossSetAAL, (parentGrossLayers, { lossSetLayerID, mean }) => {
    const lossSetLayers = parentGrossLayers.map(l => {
      if (l.id === lossSetLayerID) {
        return { ...l, mean }
      } else {
        return l
      }
    })
    return lossSetLayers
  }),
  on(setLossSetAALSuccess, (parentGrossLayers, { data }) => {
    const lossSetLayers = parentGrossLayers.map(l => {
      if (data && data[l.id]) {
        return { ...l, mean: data[l.id].mean }
      } else {
        return l
      }
    })
    return lossSetLayers
  })
)

const metricsReducer = createReducer(
  initialState.lossSetGroupsMetrics,
  on(getLossSetGroupMetricsSuccess, (metrics, { data }) => {
    return metrics.concat([data])
  }),
  on(getAllLossSetGroupsMetrics, _ => [])
)

const dirtyReducer = createReducer(
  initialState.dirty,
  on(setDirty, (_, { dirty }) => dirty),
  on(updateGrossLossSets, _ => true)
)

const lossSetSelectionModeReducer = createReducer(
  initialState.lossSetSelectionMode,
  on(
    setLossSetSelectionMode,
    (_, { lossSetSelectionMode }) => lossSetSelectionMode
  )
)

const lossSetLayerViewIDsReducer = createReducer(
  initialState.lossSetLayerViewIDs,
  on(fetchLossSetLayerViewsSuccess, (_, { layerViewIDs }) => layerViewIDs)
)

const lossSetLayerReducer = combineReducers<State>({
  loading: loadingReducer,
  error: errorReducer,
  layers: layersReducer,
  parentGrossLayers: parentGrossLayersReducer,
  dirty: dirtyReducer,
  lossSetGroups: fromLossSetGroup.reducer,
  lossSetSelectionMode: lossSetSelectionModeReducer,
  lossSetGroupsMetrics: metricsReducer,
  lossSetLayerViewIDs: lossSetLayerViewIDsReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return lossSetLayerReducer(state, action)
}
