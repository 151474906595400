<div class="new-folder">
  <h1 mat-dialog-title>{{ actionText }}</h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width" subscriptSizing="dynamic">
      <mat-label>Folder Name</mat-label>
      <input
        required
        matInput
        [(ngModel)]="folderName"
        (ngModelChange)="folderName = $event"
        (input)="handleInputChange($event)"
        #folderNameModel="ngModel"
        #input
        maxlength="50"
      />
      <mat-error *ngIf="folderNameModel.invalid"
      >Value is required.</mat-error
      >
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button appButton accent (click)="handleClick()">
      <span>{{ actionText }}</span>
    </button>
    <button appButton link mat-dialog-close>
      <span>Cancel</span>
    </button>
  </div>
</div>
