<div class="top">
  <h1
  mat-dialog-title
  class="reinsurer-title"
  >{{ getReinsurerName(reinsurer) }}</h1>
  <button
    [disabled]="!reinsurer.tpRef || isLoading"
    appButton
    big
    accent
    border
    tabindex="-1"
    class="subjectivity-button"
    (click)="openSubjectivitiesDialog()"
    >
    Track Subjectivities
  </button>
</div>
<div class="title">
  <h5 class="no-mtop">TP Ref ID: {{ reinsurer.tpRef }}</h5>
  <div class="right">
    <div class="dropdown">
      <mat-label> Placed Through</mat-label>
      <mat-select
        class="quote-select"
        [(value)]="reinsurer.reinsurerProgramFactor[0].placed_through"
        (selectionChange)="setPlacedThrough($event.value)"
      >
        <mat-option *ngFor="let pt of ptList" [value]="pt">
          <span>{{ pt }}</span>
        </mat-option>
      </mat-select>
    </div>

    <div class="dropdown">
      <mat-label> Correspondent Broker</mat-label>
      <mat-select
        class="quote-select"
        [(value)]="reinsurer.reinsurerProgramFactor[0].co_broker"
        (selectionChange)="setBroker($event.value)"
      >
        <mat-option *ngFor="let b of brokerList" [value]="b.name">
          <span>{{ b.name }}</span>
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
<br />
<div class="title">
  <h5 class="no-mtop">Approval dates: {{ approvalPeriod }}</h5>
</div>
<h5 class="no-mtop narrative-dialog">{{ reinsurer.internalNarr }}</h5>
<h5 *ngFor="let dialog of authReqDialog" class="no-mtop auth-dialog">{{ dialog }}</h5>
<div class="wrapper">
  <br />
  <h3>Financial Data</h3>
  <ng-container *ngIf="isAgency && showPricingFactor()">
    <h5 *ngIf="agencyDetails.length === 0">
      No relationship selected for this Agency.
    </h5>
    <div *ngFor="let relationship of agencyDetails">
      <h4 class="mbot">
        {{ relationship.memberName }} : {{ relationship.memberPct }}%
        <span class="ref"></span> TP Ref ID: {{ relationship.memberTPRef }}
      </h4>
      <table class="tidy-auto-table">
        <thead>
          <tr>
            <th>Domicile</th>
            <th>NAIC #</th>
            <th>FEIN #</th>
            <th>AIIN #</th>
            <th>S&P Rating</th>
            <th>S&P Rating Date</th>
            <th>Outlook Date</th>
            <th>AM Best Rating</th>
            <th>AM Best Rating Date</th>
            <th class="big-header">
              {{
                getAgencyReinsurer(relationship.memberTPRef).policy_holder === 0
                  ? 'Total Equity'
                  : 'Policyholder Surplus'
              }}
              as of
              {{ getPolicyDate(getAgencyReinsurer(relationship.memberTPRef)) }}
            </th>
            <th>Last Review Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).domicile }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).naic_num }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).fein_num }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).aain_num }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).sp_rating }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).ratingDate }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).outlookDate }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).am_best_rating }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).amBestRatingDate }}
            </td>
            <td class="big-header">
              {{
                (getAgencyReinsurer(relationship.memberTPRef).policy_holder ===
                0
                  ? getAgencyReinsurer(relationship.memberTPRef).totalEquity
                  : getAgencyReinsurer(relationship.memberTPRef)
                      .policy_holder) || 0
                  | appShortNumber
                    : getAgencyReinsurer(relationship.memberTPRef).currency ||
                        '$'
              }}
            </td>
            <td>
              {{ getAgencyReinsurer(relationship.memberTPRef).lastReviewDate }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </ng-container>
  <ng-container *ngIf="isFundManager && showPricingFactor()">
    <h5 *ngIf="noCompanyPapers && !segregatedAccount">
      No company papers selected for this Fund Manager.
    </h5>
    <div *ngFor="let companyPaper of selectedCompanyPapers">
      <h4 class="mbot">
        {{ companyPaper.agencyName }} <span class="ref"></span> TP Ref ID:
        {{ companyPaper.agencyTPRef }}
      </h4>
      <table class="tidy-auto-table">
        <thead>
          <tr>
            <th>Domicile</th>
            <th>NAIC #</th>
            <th>FEIN #</th>
            <th>AIIN #</th>
            <th>S&P Rating</th>
            <th>S&P Rating Date</th>
            <th>Outlook Date</th>
            <th>AM Best Rating</th>
            <th>AM Best Review Date</th>
            <th class="big-header">
              {{
                companyPaper.policy_holder === 0
                  ? 'Total Equity'
                  : 'Policyholder Surplus'
              }}
              as of
              {{ getPolicyDate(companyPaper) }}
            </th>
            <th>Last Review date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ companyPaper.domicile }}</td>
            <td>{{ companyPaper.naic_num }}</td>
            <td>{{ companyPaper.fein_num }}</td>
            <td>{{ companyPaper.aain_num }}</td>
            <td>{{ companyPaper.sp_rating }}</td>
            <td>{{ companyPaper.ratingDate }}</td>
            <td>{{ companyPaper.outlookDate }}</td>
            <td>{{ companyPaper.am_best_rating }}</td>
            <td>{{ companyPaper.amBestRatingDate }}</td>
            <td class="big-header">
              {{
                (companyPaper.policy_holder === 0
                  ? companyPaper.totalEquity
                  : companyPaper.policy_holder) || 0
                  | appShortNumber: companyPaper.currency || '$'
              }}
            </td>
            <td>{{ companyPaper.lastReviewDate }}</td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div *ngIf="segregatedAccount">
      <h4 class="mbot">In respect of a segregated account</h4>
      <table class="tidy-auto-table">
        <thead>
          <tr>
            <th>Domicile</th>
            <th>NAIC #</th>
            <th>FEIN #</th>
            <th>AIIN #</th>
            <th>S&P Rating</th>
            <th>AM Best Rating</th>
            <th class="big-header">Policyholder Surplus as of</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="big-header"></td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </ng-container>
  <table
    *ngIf="(!isAgency && !isFundManager) || !showPricingFactor()"
    class="tidy-auto-table"
  >
    <thead>
      <tr>
        <th>Domicile</th>
        <th>NAIC #</th>
        <th>FEIN #</th>
        <th>AIIN #</th>
        <th>S&P Rating</th>
        <th>S&P Rating Date</th>
        <th>Outlook Date</th>
        <th>AM Best Rating</th>
        <th>AM Best Review Date</th>
        <th class="big-header">
          {{
            reinsurer.policy_holder === 0
              ? 'Total Equity'
              : 'Policyholder Surplus'
          }}
          as of {{ getPolicyDate(reinsurer) }}
        </th>
        <th>Last Review Date</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ reinsurer.domicile }}</td>
        <td>{{ reinsurer.naic_num }}</td>
        <td>{{ reinsurer.fein_num }}</td>
        <td>{{ reinsurer.aain_num }}</td>
        <td>{{ reinsurer.sp_rating }}</td>
        <td>{{ reinsurer.ratingDate }}</td>
        <td>{{ reinsurer.outlookDate }}</td>
        <td>{{ reinsurer.am_best_rating }}</td>
        <td>{{ reinsurer.amBestRatingDate }}</td>
        <td class="big-header">
          {{
            (reinsurer.policy_holder === 0
              ? reinsurer.totalEquity
              : reinsurer.policy_holder) || 0
              | appShortNumber: reinsurer.currency || '$'
          }}
        </td>
        <td>{{ reinsurer.lastReviewDate }}</td>
      </tr>
    </tbody>
  </table>

  <br />

  <h3>Appetite</h3>
  <table class="tidy-auto-table">
    <thead>
      <tr>
        <th class="appetites_header">Key U/W Contact</th>
        <th class="appetites_header">Program</th>
        <th class="appetite_header">Appetite</th>
        <th class="notes_header">Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let study of studyReinsurers; let i = index">
        <td>
          <input
            type="text"
            [ngModel]="getContactModel(study)"
            (ngModelChange)="setContactModel(study, $event)"
            (focusout)="setReinsurerChange(study)"
          />
        </td>
        <td>
          {{ study.studyName }}
        </td>
        <td>
          <button
            class="button-appetite"
            [ngClass]="{
              'app-rag-0': getColor(study) === 'Red',
              'app-rag-50': getColor(study) === 'Amber',
              'app-rag-100': getColor(study) === 'Green'
            }"
            appButton
            [matMenuTriggerFor]="addMenu"
          >
            <div class="select-style">{{ getColor(study) }}</div>
            <mat-icon inline>arrow_drop_down</mat-icon>
          </button>
          <mat-menu
            #addMenu="matMenu"
            panelClass="app-menu-translucent"
            backdropClass="app-theme-dark"
          >
            <button
              mat-menu-item
              *ngFor="let color of menu"
              (click)="setAppetiteColor(study, color)"
            >
              {{ color }}
            </button>
          </mat-menu>
        </td>
        <td>
          <app-reinsurer-notes-component
            [notesModel]="getNotesModel(study)"
            [studyID]="study.studyId"
            (setNotesModel)="setNotesModel(study, $event)"
            (setReinsurer)="setReinsurerChange(study)"
          >
          </app-reinsurer-notes-component>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="!activeAction; else actionRunning">
    <button
      appButton
      accent
      mat-dialog-close="2"
      cdkFocusInitial
      [disabled]="!isDirty"
      (click)="onSaveClick()"
    >
      Save
    </button>
  </ng-container>
  <ng-template #actionRunning>
    <app-active-action [value]="activeAction"></app-active-action>
  </ng-template>
  <button appButton primary mat-dialog-close="2" cdkFocusInitial>Cancel</button>
</div>
