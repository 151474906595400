import { Action, createReducer, on, combineReducers } from '@ngrx/store'
import * as BrokerActions from './broker.actions'
import { initialState, State } from './broker.state.facade'

const checkedProgramsReducer = createReducer(
  initialState.checkedPrograms,
  on(BrokerActions.updateCheckedPrograms, (state, { checkedItemIds }) => {
    return state.concat(checkedItemIds)
  }),
  on(BrokerActions.removedCheckedPrograms, (state, { programs }) => {
    return state.filter(item => item.id !== programs[0].id)
  }),
  on(BrokerActions.clearCheckedPrograms, _ => [])
)

const brokerReducer = createReducer(
  initialState,
  on(BrokerActions.setCurrentClient, (state, { id }) => ({
    ...state,
    currentClientID: id,
    currentYearID: null,
    currentStudyID: null,
  })),
  on(BrokerActions.setCurrentClient2, (state, { id }) => ({
    ...state,
    currentClientID2: id,
    currentYearID2: null,
    currentStudyID2: null,
  })),

  on(BrokerActions.setCurrentYear, (state, { id }) => ({
    ...state,
    currentYearID: id,
    currentStudyID: null,
  })),
  on(BrokerActions.setCurrentYear2, (state, { id }) => ({
    ...state,
    currentYearID2: id,
    currentStudyID: null,
  })),

  on(BrokerActions.setCurrentStudy, (state, { id }) => ({
    ...state,
    currentStudyID: id,
  })),

  on(BrokerActions.setCurrentStudy2, (state, { id }) => ({
    ...state,
    currentStudyID2: id,
  })),

  on(
    BrokerActions.setCurrentAnalysisProfileSuccess,
    (state, { analysisProfile }) => ({
      ...state,
      currentAnalysisProfile: analysisProfile,
    })
  ),

  on(BrokerActions.setCurrentStructure, (state, { id }) => ({
    ...state,
    currentStructureID: id,
  })),

  on(
    BrokerActions.setCurrentTierPath,
    (state, { client, year, program, structure }) => ({
      ...state,
      currentClientID: client,
      currentYearID: year,
      currentStudyID: program,
      currentStructureID: structure,
      folders: []
    })
  ),
  on(
    BrokerActions.setCurrentTierPath2,
    (state, { client, year, program, structure }) => ({
      ...state,
      currentClientID2: client,
      currentYearID2: year,
      currentStudyID2: program,
      currentStructureID: structure,
    })
  ),
  on(BrokerActions.setStructureFilter, (state, { filter }) => ({
    ...state,
    structureFilter: filter,
  })),

  on(BrokerActions.setGroupFilterByAssoc, (state, { groupFilterByAssoc }) => ({
    ...state,
    groupFilterByAssoc,
  })),

  on(BrokerActions.setCurrencyList, (state, { currencies }) => ({
    ...state,
    currencies,
  })),

  on(BrokerActions.setCurrencyRates, (state, { currencyRates }) => ({
    ...state,
    currencyRates,
  })),

  on(BrokerActions.fetchFoldersSuccess, (state, { folders }) => ({
    ...state,
    folders
  })),
)
const reducers = combineReducers<State>({
  broker: brokerReducer,
  checkedProgram: checkedProgramsReducer,
})
export function reducer(state: State | undefined, action: Action) {
  return reducers(state, action)
}
