import { Injectable } from '@angular/core'
import {
  AssignedLinesKeys,
  AssignedLinesTextMappings,
  Fills,
  Fonts,
  QuoteExcelParams,
  QuoteExportModes,
  QuoteExportRowValue,
} from './quote-excel.model'
import { Border, Borders, Cell, Worksheet } from 'exceljs'
import { clone, isNil, length, maxBy, reduce } from 'ramda'
import { ExcelExportService } from '@shared/services/excel-export.service'
import { CurrencyPipe, DatePipe } from '@angular/common'
import { QuoteReinsurer, Section } from '../models/quote.model'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { Reinstatement } from 'src/app/api/analyzere/analyzere.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { Layer } from 'src/app/analysis/model/layers.model'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'

@Injectable({ providedIn: 'root' })
export class QuoteExcelUtils {
  constructor(
    private exportService: ExcelExportService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly datePipe: DatePipe
  ) {}

  public convertTempAssignedLinesValsToText(
    val: string,
    isDefault: boolean
  ): string | undefined {
    if (Object.keys(AssignedLinesTextMappings).includes(val)) {
      if (val === 'name' && isDefault) {
        return
      }
      return AssignedLinesTextMappings[val as AssignedLinesKeys]
    }
    return
  }

  public findFirstNonZeroDecimalPlace(val: number): number {
    if (val % 1 === 0) {
      return 0
    }
    const firstNonZeroIndex = String(val)
      .split('.')[1]
      .split('')
      .findIndex(char => char !== '0')
    return Math.max(firstNonZeroIndex + 1, 0)
  }

  public findDescRowIndex(
    rows: string[][],
    header: string,
    isOccLimit: boolean = false
  ): number {
    if (!isOccLimit) {
      return rows.findIndex(val => val[0] === header)
    } else {
      return rows.findIndex(this.containsOccurrenceLimit)
    }
  }

  public findLayerForSection(section: Section, layers: LayerState[]): Layer {
    return layers.find(({ layer }) => layer.id === section.layerRef).layer
  }

  public getValsAtIndex<T>(
    rowIndex: number,
    valIndex: number,
    quoteParams: QuoteExcelParams
  ): T[] {
    const isDefaultExport = quoteParams.exportMode === QuoteExportModes.DEFAULT
    const longestExpiring = this.findLongestArrayLength(
      quoteParams.expiringReinsurerColumns
    )
    const expiringrows = !!quoteParams.expiringReinsurerRows[rowIndex]?.[valIndex]?.length
      ? quoteParams.expiringReinsurerRows[rowIndex][valIndex]
      : []
    const nonexpiringrows = !!quoteParams.nonFotAndExpiringRows[rowIndex]?.[
      valIndex
    ]?.length
      ? quoteParams.nonFotAndExpiringRows[rowIndex][valIndex]
      : []

    const slicedNonExpiringRows = isDefaultExport
      ? nonexpiringrows.slice(0, -2)
      : nonexpiringrows

    const spaces = longestExpiring - expiringrows.length

    return [
      ...[...expiringrows, ...this.getSpacesForCurrentRow(spaces)].map(
        (e: QuoteExportRowValue[]) => e[0] as T
      ),
      ...slicedNonExpiringRows.map((e: QuoteExportRowValue[]) => e[0] as T),
    ]
  }

  public getAssignedLinesBorderByEntry(
    isDefault: boolean,
    entry: QuoteExportRowValue[]
  ): Partial<Borders> {
    const definedBorder: Partial<Border> = {
      style: 'thin',
      color: { argb: '000000' },
    }
    const bottomBorder = {
      bottom: definedBorder,
    }
    if (isDefault) {
      return bottomBorder
    } else {
      if (entry.length === 1) {
        return {}
      }
      const entryKey = entry[2]
      const leftBorder: Partial<Borders> = {
        ...bottomBorder,
        left: definedBorder,
      }
      switch (entryKey) {
        case AssignedLinesKeys.SIGNED_PERCENT:
        case AssignedLinesKeys.PERCENT_WRITTEN:
          return leftBorder
        case AssignedLinesKeys.LEAD_MARKET:
          return {
            ...leftBorder,
            right: definedBorder,
          }
        case AssignedLinesKeys.DOLLARS_SIGNED:
          return {
            ...bottomBorder,
            right: definedBorder,
          }
        default:
          return bottomBorder
      }
    }
  }

  public isCellInEmptyColumn(
    worksheet: Worksheet,
    cell?: Cell,
    cellCol?: string,
    includeFills?: boolean
  ): boolean {
    if (!cell && !cellCol) {
      return false
    }

    const col = cell.col || cellCol
    const noCellHasValue = worksheet
      .getColumn(col)
      .values.every(val => !String(val).trim().length)

    if (!includeFills) {
      return noCellHasValue
    } else {
      let noCellHasFill = true
      worksheet.getColumn(col).eachCell(cell => {
        noCellHasFill = noCellHasFill && !cell.style.fill
      })
      return noCellHasValue && noCellHasFill
    }
  }

  public getSpacesForCurrentRow(num: number = 1): string[] {
    const spaces: string[] = []
    for (let i = 0; i < num; i++) {
      spaces.push('')
    }
    return spaces
  }

  public findLongestSubArray(input: any[][]): any[] {
    return reduce(maxBy(length), [], input)
  }

  public findLongestArrayLength(input: any[][]): number {
    return this.findLongestSubArray(input).length
  }

  public addXOLSubjectPremiumSummaryToWorksheet(
    worksheet: Worksheet,
    quoteParams: QuoteExcelParams
  ): void {
    const subjectPremiumRows = clone(quoteParams.xolSubjectPremiumRows)
    this.exportService.appendBlankRows(worksheet)

    const subjectPremiumRowVals = subjectPremiumRows[0].map(val => val[0])
    const subjectPremiumRow = worksheet.addRow(subjectPremiumRowVals)
    subjectPremiumRow.getCell(1).fill = Fills.Solid_Gray
    subjectPremiumRow.getCell(1).font = Fonts.Segoe_8_White_Bold

    const subjectPremiumCell = subjectPremiumRow.getCell(2)
    subjectPremiumCell.style = {
      font: Fonts.Segoe_8,
      alignment: { horizontal: 'right', wrapText: true },
    }
    const currencySymbol = String(subjectPremiumRowVals[1]).charAt(0)
    if (
      subjectPremiumCell.value &&
      typeof subjectPremiumCell.value === 'number' &&
      subjectPremiumCell.value % 1 !== 0
    ) {
      subjectPremiumCell.numFmt = this.formatCellAsCurrency(
        currencySymbol,
        true
      )
    } else {
      subjectPremiumCell.numFmt = this.formatCellAsCurrency(
        currencySymbol,
        false
      )
    }
    subjectPremiumCell.value =
      this.removeCurrencySymbolFromCell(subjectPremiumCell)

    const headerRow = worksheet.addRow(subjectPremiumRows[1].map(val => val[0]))
    headerRow.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
    })

    const sectionRows = subjectPremiumRows.slice(2)
    sectionRows.forEach(row => {
      const sectionRow = worksheet.addRow(row.map(val => val[0]))
      sectionRow.eachCell((cell, colNum) => {
        const cellEntry = row[colNum - 1]
        cell.alignment = { wrapText: true }
        cell.style = {
          font: Fonts.Segoe_8,
          alignment: { horizontal: 'right', wrapText: true },
          border: {
            bottom: { style: 'thin', color: { argb: '000000' } },
          },
        }
        if (cellEntry[1] === 'currency' && cell.value !== 'Unlimited') {
          const currencySymbol = String(cellEntry[0]).charAt(0)
          if (
            cell.value &&
            typeof cell.value === 'number' &&
            cell.value % 1 !== 0
          ) {
            cell.numFmt = this.formatCellAsCurrency(currencySymbol, true)
          } else {
            cell.numFmt = this.formatCellAsCurrency(currencySymbol, false)
          }
          cell.value = this.removeCurrencySymbolFromCell(cell)
        }
      })
    })

    this.exportService.appendBlankRows(worksheet)
  }

  public removeCurrencySymbolFromCell(cell: Cell): number {
    const parsedCellVal = cell.value?.toString().replace(/,/g, '')
    if (!parsedCellVal) {
      return 0
    }
    // Get numeric value after the currency symbols (e.g. £, $, 'MVR', 'COP')
    const currencyRegex = /[a-zA-Z£€$]+/
    return parseFloat(parsedCellVal.split(currencyRegex)[1])
  }

  public formatCellAsCurrency(
    currencySymbol: string,
    hasDecimals: boolean
  ): string {
    const decimals = hasDecimals ? '.0000' : ''
    return `${currencySymbol}#,##0${decimals}`
  }

  public populateArraysWithValue = <T>(
    count: number,
    val: T,
    emptyAtEnd = false
  ): T[] => {
    const fillArray: T[] = []
    for (let i = 0; i < count; i++) {
      fillArray.push(val)
    }
    if (emptyAtEnd) {
      fillArray.push(' ' as T)
    }
    return fillArray
  }

  public getCellBorderByIndex(
    targetIndex: number,
    index: number,
    noBottomBorder?: boolean
  ): Partial<Borders> {
    const borderDef: Border = {
      style: 'thin',
      color: { argb: '000000' },
    }
    const baseBorder: Partial<Borders> = {
      bottom: !noBottomBorder ? borderDef : undefined,
    }
    if (index === targetIndex) {
      return {
        ...baseBorder,
        right: {
          ...borderDef,
          style: 'thick',
        },
      }
    }
    return baseBorder
  }

  public convertCurrencyToSymbol(
    value: number | { value: number | null },
    currencyCode: string
  ): string | null {
    const val = typeof value === 'object' ? (value.value ?? 0) : value
    return this.currencyPipe.transform(
      val,
      currencyCode,
      'symbol-narrow',
      '1.0-0'
    )
  }

  public labelReinsurer(
    reinsurer: QuoteReinsurer,
    type?: string,
    num?: number
  ): string {
    const {
      quoteReinsurerName: reinsurerName,
      reinsurerPhaseLabel: phaseLabel,
      reinsurerPhaseVersion: phaseVersion,
    } = reinsurer

    let label = isNil(phaseLabel) ? phaseVersion : phaseLabel

    if (type === 'quote') {
      if (num === 1) {
        label = ''
      }
    }
    return `${reinsurerName} ${label}`
  }

  public calculateValue(value: any, type: MetricValueType, currency: string) {
    const numberTransform = (val: number) =>
      this.isValueUnlimited(val) ? analyzereConstants.unlimitedValue : val
    if (value === null) {
      if (
        type === 'currency' ||
        type === 'numeric' ||
        type === 'percentage' ||
        type === 'ratio' ||
        type === 'formatted-onedecimal-numeric' ||
        type === 'unformatted-numeric'
      ) {
        value = 0
      } else {
        value = ''
      }
      return value
    }
    if (value && (value.value || value.value === 0) && value.currency) {
      if (value.value === null) {
        value.value = 0
        return this.convertCurrencyToSymbol(value.value, value.currency)
      }
      if (
        value.value.toString().indexOf('e+') > -1 ||
        value.value.toString() === 'Unlimited' ||
        (!isNaN(Number(value.value)) &&
          Number(value.value) >=
            numberTransform(analyzereConstants.unlimitedValue))
      ) {
        return 'Unlimited'
      } else {
        return this.convertCurrencyToSymbol(value.value || 0, value.currency)
      }
    } else {
      if (type === 'currency') {
        return this.convertCurrencyToSymbol(value || 0, currency)
      } else if (type === 'percentage') {
        return value || 0
      } else if (type === 'dropdown') {
        return value || ''
      } else if (type === 'number-list') {
        const re: { counter: number; premium: number }[] = []
        if (value.length > 0) {
          const premiumsList: number[] = []
          value.forEach((v: Reinstatement) => {
            if (v.premium !== undefined) {
              premiumsList.push(v.premium)
            }
          })

          let current = -1
          let counter = 0
          const counterList: number[][] = []

          premiumsList.forEach((p, i) => {
            if (i === premiumsList.length - 1 && premiumsList[i - 1] === p) {
              counterList.push([counter + 1, current])
            }

            if (current !== p) {
              if (current !== -1) {
                counterList.push([counter, current])
              }
              current = p
              counter = 1
            } else {
              counter += 1
            }

            if (i === premiumsList.length - 1 && premiumsList[i - 1] !== p) {
              current = p
              counter = 1
              counterList.push([counter, current])
            }
          })

          counterList.forEach(c => {
            re.push({ counter: c[0], premium: c[1] })
          })
        }

        let val = ''
        if (re.length > 0) {
          re.forEach(r => {
            val += ' ' + r.counter + '@' + r.premium * 100 + '%,'
          })
        }
        return val
      } else if (type === 'date') {
        return this.datePipe.transform(value, 'MM/dd/yyyy') ?? ''
      } else if (type === 'multiselect') {
        return value.join(', ')
      } else {
        return value || ''
      }
    }
  }

  // The non-section portion of a multisection reinsurer is on the visible-layer, find the visible layer for each qsage section passed in
  public getVisibleLayerSectionForMultiSectionSection(
    layerStates: LayerState[],
    section: Section
  ): LayerState | undefined {
    const layerForSection = layerStates.find(
      ({ layer }) => layer.id === section.layerRef
    )
    if (!layerForSection || !isMultiSectionLayer(layerForSection.layer)) {
      return
    }
    if (isMultiSectionLayer(layerForSection.layer, 'visible-layer')) {
      return layerForSection
    } else if (isMultiSectionLayer(layerForSection.layer, 'main-layer')) {
      return layerStates.find(
        ({ layer }) =>
          layer.id === layerForSection.layer.meta_data.visible_layer_id
      )
    } else if (isMultiSectionLayer(layerForSection.layer, 'section-layer')) {
      const mainLayer = layerStates.find(
        ({ layer }) =>
          isMultiSectionLayer(layer, 'main-layer') &&
          layer.layerRefs.includes(layerForSection.layer.id)
      )
      return layerStates.find(
        ({ layer }) => layer.id === mainLayer.layer.meta_data.visible_layer_id
      )
    }
  }

  public validateReinsurer(reinsurer: QuoteReinsurer, layerRef: string) {
    return (
      reinsurer.cededlayerID === layerRef &&
      reinsurer.quoteReinsurerName &&
      !reinsurer.decline &&
      reinsurer.exportToggle === true
    )
  }

  private isValueUnlimited(num: number): boolean {
    return num.toString().toLowerCase().includes('e')
  }

  // Added Shared Condition for top and drop layers
  private containsOccurrenceLimit(element: string[]) {
    return (
      element[0] === 'Occurrence Limit' ||
      element[0] === 'Shared Per Occurrence Limit' ||
      element[0] === 'Top Occurrence Limit'
    )
  }
}
