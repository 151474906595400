import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { assoc, dissoc } from 'ramda'
import { ApplicationError } from '../../error/model/error'
import { md5 } from '@shared/util/hash'
import { reduceReducers } from '@shared/util/reduce-reducers'
import { BenchmarkMetricView } from '../model/benchmark-metric-view'
import { BenchmarkMetric } from '../model/benchmark.model'
import { buildBenchmarkMetricsLabel } from '../model/benchmark.util'
import * as fromControlActions from './benchmark-controls.actions'
import * as fromActions from './benchmark-metric-view.actions'

export interface BenchmarkMetricViewEntity {
  view: BenchmarkMetricView
  hash: string
  new: boolean
  dirty: boolean
  updating: boolean
}

interface ExtendedState {
  activeAction: string | null
  error: ApplicationError | null
  selectedBySubmode: Record<string, BenchmarkMetricView>
  showMetrics: boolean
  restoreTime?: number
}

export type BenchmarkMetricViewState = EntityState<BenchmarkMetricViewEntity> &
  ExtendedState

const adapter = createEntityAdapter<BenchmarkMetricViewEntity>({
  selectId: entity => String(entity.view.id),
})

export const benchmarkMetricViewAdapter = adapter

export const initialState: BenchmarkMetricViewState = adapter.getInitialState({
  activeAction: null,
  error: null,
  selectedBySubmode: {},
  showMetrics: false,
})

// State Reducers

const activeAction = createReducer(
  initialState.activeAction,
  on(fromActions.fetchBenchmarkCompanyMetricViews, () => 'Loading'),
  on(fromActions.updateBenchmarkMetricView, () => 'Saving'),
  on(fromActions.addBenchmarkMetricView, () => 'Adding'),
  on(fromActions.removeBenchmarkMetricView, () => 'Removing'),
  on(
    fromActions.fetchBenchmarkCompanyMetricViewsSuccess,
    fromActions.fetchBenchmarkCompanyMetricViewsFailure,
    fromActions.updateBenchmarkMetricViewSuccess,
    fromActions.updateBenchmarkMetricViewFailure,
    fromActions.addBenchmarkMetricViewSuccess,
    fromActions.addBenchmarkMetricViewFailure,
    fromActions.removeBenchmarkMetricViewSuccess,
    fromActions.removeBenchmarkMetricViewFailure,
    // @ts-ignore
    () => null
  )
)

const errorReducer = createReducer(
  initialState.error,
  on(
    fromActions.fetchBenchmarkCompanyMetricViewsFailure,
    (_, { error }) => error
  ),
  on(
    fromActions.fetchBenchmarkCompanyMetricViews,
    fromActions.fetchBenchmarkCompanyMetricViewsSuccess,
    // @ts-ignore
    () => null
  )
)

const selectedBySubmode = createReducer(
  initialState.selectedBySubmode,
  on(fromActions.setBenchmarkSelectedMetricView, (state, { submode, view }) =>
    assoc(submode, view, state)
  ),
  on(fromActions.addBenchmarkMetricViewSuccess, (state, { view }) =>
    assoc(view.submode, view, state)
  ),
  on(fromActions.removeBenchmarkMetricViewSuccess, (state, { view }) =>
    state[view.submode]?.id === view.id ? dissoc(view.submode, state) : state
  ),
  on(
    fromActions.restoreBenchmarkMetricViewSettingsSuccess,
    (_, { updates }) =>
      updates.selectedBySubmode ?? initialState.selectedBySubmode
  ),
  on(fromActions.prepareNewBenchmarkMetricViewDone, (state, { view }) =>
    assoc(view.submode, view, state)
  )
)

const showMetrics = createReducer(
  initialState.showMetrics,
  on(fromActions.prepareNewBenchmarkMetricViewDone, () => true),
  on(fromActions.toggleBenchmarkMetricViewShowMetrics, state => !state),
  on(fromActions.setBenchmarkMetricViewShowMetrics, (_, { value }) => value)
)

const restoreTime = createReducer(
  initialState.restoreTime,
  on(fromActions.restoreBenchmarkMetricViewSettingsSuccess, () => Date.now())
)

const extendedReducer = combineReducers<BenchmarkMetricViewState>({
  ids: state => state || initialState.ids,
  entities: state => state || initialState.entities,
  activeAction,
  error: errorReducer,
  selectedBySubmode,
  showMetrics,
  restoreTime,
})

// Entity Reducer

const defaultEntity: Omit<BenchmarkMetricViewEntity, 'view'> = {
  hash: '',
  dirty: false,
  new: false,
  updating: false,
}

const createEntity = (
  view: BenchmarkMetricView
): BenchmarkMetricViewEntity => ({ ...defaultEntity, view, hash: md5(view) })

const updateDimension =
  (dimension: 'primary' | 'secondary' | 'tertiary') =>
  (
    state: BenchmarkMetricViewState,
    { submode, metric }: { submode: string; metric?: BenchmarkMetric | null }
  ): BenchmarkMetricViewState => {
    const selected = state.selectedBySubmode[submode]
    // Do not update if none selected, or is pre-made view
    if (selected && selected.entityID != null) {
      const selectors = adapter.getSelectors()
      const entity = selectors.selectEntities(state)[selected.id]
      if (entity) {
        // If still a new view, update its default name
        let name = entity.view.name
        if (entity.new && metric) {
          const names = selectors.selectAll(state).map(e => e.view.name)
          if (dimension === 'primary') {
            const secondary = entity.view.secondary
            name = buildBenchmarkMetricsLabel([metric, secondary], names)
          } else if (dimension === 'secondary') {
            const primary = entity.view.primary
            name = buildBenchmarkMetricsLabel([primary, metric], names)
          }
        }
        const view = { ...entity.view, [dimension]: metric ?? undefined, name }
        const dirty = md5(view) !== entity.hash
        const changes = { ...entity, view, dirty }
        const update = { id: String(selected.id), changes }
        return adapter.updateOne(update, state)
      }
    }
    return state
  }

const entityReducer = createReducer(
  initialState,
  on(fromActions.fetchBenchmarkCompanyMetricViewsSuccess, (state, { views }) =>
    adapter.setAll(views.map(createEntity), state)
  ),
  on(
    fromControlActions.SetBenchmarkControlsMetricBySubmode,
    updateDimension('primary')
  ),
  on(
    fromControlActions.SetBenchmarkControlsSecondaryMetricBySubmode,
    updateDimension('secondary')
  ),
  on(
    fromControlActions.SetBenchmarkControlsTertiaryMetricBySubmode,
    updateDimension('tertiary')
  ),
  on(fromControlActions.SetBenchmarkControlsTargetCompany, state =>
    adapter.removeAll(state)
  ),
  on(
    fromActions.updateBenchmarkMetricViewSuccess,
    fromActions.addBenchmarkMetricViewSuccess,
    (state, { view }) => {
      const list = adapter.getSelectors().selectAll(state)
      const newViews = list.filter(it => it.new)
      const newViewIDs = newViews.map(it => String(it.view.id))
      const next = adapter.removeMany(newViewIDs, state)
      return adapter.upsertOne(createEntity(view), next)
    }
  ),
  on(fromActions.removeBenchmarkMetricViewSuccess, (state, { view }) =>
    adapter.removeOne(String(view.id), state)
  ),
  on(fromActions.prepareNewBenchmarkMetricViewDone, (state, { view }) => {
    const entity = createEntity(view)
    entity.new = true
    return adapter.upsertOne(entity, state)
  })
)

// Combined Reducers

const benchmarkMetricViewReducer = reduceReducers<BenchmarkMetricViewState>(
  extendedReducer,
  entityReducer
)

export function reducer(
  state: BenchmarkMetricViewState | undefined,
  action: Action
): BenchmarkMetricViewState {
  return benchmarkMetricViewReducer(state, action)
}
