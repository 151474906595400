import { Pipe, PipeTransform } from '@angular/core'
import { getCurrencySymbol } from '@angular/common'

const ORDERS = [
  { suffix: '∞', magnitude: Math.pow(10, 20) },
  { suffix: 'T', magnitude: Math.pow(10, 12) },
  { suffix: 'B', magnitude: Math.pow(10, 9) },
  { suffix: 'M', magnitude: Math.pow(10, 6) },
  { suffix: 'K', magnitude: Math.pow(10, 3) },
]
const ROUND = 10

@Pipe({
  name: 'appShortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(
    value: number,
    currency?: string,
    isTooltip = false,
    showThousandsDecimal = false,
    precision = 1
  ): any {
    if (value == null || value === undefined) {
      return null
    }
    if (isNaN(value)) {
      return value
    }

    const init = { short: Math.abs(value), suffix: '' }
    const format = isTooltip ? this.noRound : this.round

    let { short, suffix } = ORDERS.reduce((acc, order) => {
      if (!acc.suffix) {
        const div = format(acc.short / order.magnitude)
        return div >= 1 ? { short: div, suffix: order.suffix } : acc
      }
      return acc
    }, init)

    if (!suffix) {
      short = format(short)
      suffix = ''
    } else if (suffix === '∞') {
      return 'Unlimited'
    }
    const symbol = currency ? getCurrencySymbol(currency, 'narrow') : ''

    // If value < 1000 and no fixed decimal needed, remove decimal. Otherwise show 1 decimal place
    const decimals =
      Math.abs(value) < 1000 && !showThousandsDecimal ? 0 : precision
    return `${value < 0 ? '-' : ''}${symbol}${short.toFixed(decimals)}${suffix}`
  }

  private round(x: number, roundBy = ROUND): number {
    return Math.round(x * roundBy) / roundBy
  }

  private noRound(x: number, roundBy = ROUND): number {
    return (x * roundBy) / roundBy
  }
}
