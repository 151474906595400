<ng-container
  *ngTemplateOutlet="
        tower;
        context: {
          $implicit: showLoading$ | async
        }
      "
></ng-container>
<ng-template #tower let-loading>
  <app-loading-item [item]="'1'" [loading]="loading">
    <app-tower
      *ngIf="loading === false"
      [portfolioIDs]="portfolioIDs$ | async"
      [layers]="layers$ | async"
      [allLayers]="allLayers$ | async"
      [tdLayers]="tdLayers$ | async"
      [selected]="selected$ | async"
      [hiddenLayers]="hiddenLayers$ | async"
      [inuranceTagsByLayerID]="(inuranceTagsByLevel$ | async)?.layer || {}"
      [showAgg]="showAgg$ | async"
      [showOcc]="showOcc$ | async"
      [showZoom]="showZoom$ | async"
      [widthPercentage]="1"
      [heightPercentage]="1"
      [offsetTop]="240"
      [activeAction]="activeAction$ | async"
      [incrementsYOcc]="incrementsYOcc$ | async"
      [incrementsYDirtyOcc]="incrementsYDirtyOcc$ | async"
      [maxYOcc]="maxYOcc$ | async"
      [maxYDirtyOcc]="maxYDirtyOcc$ | async"
      [mostOcc]="mostOcc$ | async"
      [incrementsYAgg]="incrementsYAgg$ | async"
      [incrementsYDirtyAgg]="incrementsYDirtyAgg$ | async"
      [currentStructure]="currentProgram$ | async"
      (setLayerData)="onSetLayerData($event)"
      (setDirtyStructure)="onSetDirtyStructure($event)"
      [structureLayerData]="structureLayerData$ | async"
      [maxYAgg]="maxYAgg$ | async"
      [maxYDirtyAgg]="maxYDirtyAgg$ | async"
      [mostAgg]="mostAgg$ | async"
      [lossSetLayers]="lossSetLayers$ | async"
      [sharedIDGroup]="sharedIDPortfolio$ | async"
      [currentCurrency]="currentCurrency"
      [log]="log$ | async"
      [logMin]="logMin$ | async"
      [snapping]="snapping$ | async"
      [currencyRates]="currencyRates$ | async"
      [analysisProfileCurrency]="analysisProfileCurrency"
      (selectedLayerID)="onSelectedLayerID($event)"
      (layerResize)="onLayerResize($event)"
      (setLayer)="onSetLayer($event)"
      (imageUpload)="onImageUpload($event)"
      (deleteLayer)="onDeleteLayerID($event)"
      (firstAggLayerAdd)="onFirstAggLayerAdd($event)"
      (setIncrementsYOcc)="onSetIncrementsYOcc($event)"
      (setSavedCurves)="onLoadCurves()"
      (setInitDefaultConnectors)="onLoadInitDefaultConnectors()"
      (setMaxYOcc)="onSetMaxYOcc($event)"
      (setMaxYOccDirty)="onSetMaxYDirtyOcc($event)"
      (setOccIncrementDirty)="onSetIncrementOccDirty($event)"
      (setMostOcc)="onSetMostOcc($event)"
      (setIncrementsYAgg)="onSetIncrementsYAgg($event)"
      (setMaxYAgg)="onSetMaxYAgg($event)"
      (setMostAgg)="onSetMostAgg($event)"
      (setTowerPropertiesInitialOcc)="onSetTowerPropertiesInitialOcc($event)"
      (setTowerPropertiesInitialAgg)="onSetTowerPropertiesInitialAgg($event)"
      (inuranceTagClick)="onInuranceTagClick($event)"
    ></app-tower>
  </app-loading-item>
</ng-template>
