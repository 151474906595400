import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core'

import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'

const DEFAULT_PRESETS: number[] = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-weight-slider',
  styles: [
    `
           .app-theme-dark {
             display: block;
             color: var(--body);
             padding: var(--inset-small);
             overflow: hidden;
           }
     
           /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
           mat-slider {
             width: 100%;
             height: 40px;
           }
     
           .presets button {
             margin-right: var(--inset);
           }
     
           .presets {
             padding-bottom: var(--inset-small);
           }
         `,
  ],
  template: `
    <div class="app-theme-dark">
      <div class="presets">
        <button
          *ngFor="let preset of presets"
          appButton
          tiny
          extraPadding
          (click)="weightChange.emit(preset)"
        >
          {{ preset }}
        </button>
      </div>
      <!-- TODO: The 'tickInterval' property no longer exists -->
      <mat-slider
        #slider
        [max]="100"
        [min]="1"
        [step]="1"
        [thumbLabel]="true"
        (slideend)="onWeightChange(slider.value)"
        (pointerup)="onWeightChange(slider.value)"
        (keyup)="onWeightChange(slider.value)"
        (click)="onWeightSliderClick($event)"
      ><input matSliderThumb [value]="weight" /></mat-slider>
    </div>
  `,
})
export class WeightSliderComponent implements OnInit, OnDestroy {
  @Input() weight: number
  @Input() presets: number[] = DEFAULT_PRESETS

  @Output() weightChange = new EventEmitter<number | null>()

  private debounce$ = new Subject<number | null>()
  private destroy$ = new Subject()

  ngOnInit(): void {
    this.debounce$
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(value => this.weightChange.emit(value))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onWeightSliderClick($event: MouseEvent | TouchEvent) {
    $event.stopImmediatePropagation()
  }

  onWeightChange(value: number | null) {
    this.debounce$.next(value)
  }
}
