import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { ScenarioEvent } from '../../animated-scenarios.model'
import { createReducer, on, Action } from '@ngrx/store'
import * as fromScenarioEventActions from './group-scenario-event.actions'

export type ScenarioEventState = EntityState<ScenarioEvent>

export const adapter = createEntityAdapter<ScenarioEvent>({
  sortComparer,
})

function sortComparer(a: ScenarioEvent, b: ScenarioEvent) {
  return a.id - b.id
}

export const initialState = adapter.getInitialState()

export const scenarioEventReducer = createReducer(
  initialState,
  on(
    fromScenarioEventActions.addAllGroupScenarioEvents,
    (state, { events }) => {
      return adapter.setAll(
        events.map((e, i) => ({ ...e, id: i })),
        state
      )
    }
  ),

  on(fromScenarioEventActions.addGroupScenarioEvent, (state, { event }) => {
    const id =
      state.ids.length > 0 ? (state.ids[state.ids.length - 1] as number) + 1 : 1
    return adapter.addOne({ ...event, id }, state)
  }),

  on(
    fromScenarioEventActions.updateGroupScenarioEvent,
    (state, { id, changes }) => {
      return adapter.updateOne({ id, changes }, state)
    }
  ),

  on(fromScenarioEventActions.removeGroupScenarioEvent, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),

  on(fromScenarioEventActions.removeAllGroupScenarioEvents, state => {
    return adapter.removeAll(state)
  })
)

export function reducer(state: ScenarioEventState | undefined, action: Action) {
  return scenarioEventReducer(state, action)
}
