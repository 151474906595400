<app-theme-container>
  <app-group-compare
    name="Compare"
    allowScenarioOrOptimizationSelection
    [props]="props$ | async"
    [state]="state$ | async"
    [analysisProfileID]="analysisProfileID$ | async"
    [years]="years$ | async"
    [programGroups]="programGroups$ | async"
    [programGroupMembers]="programGroupMembers$ | async"
    [programs]="programs$ | async"
    [programIDs]="programIDs$ | async"
    [selectedYearID]="selectedYearID$ | async"
    [selectedClientID]="selectedClientID$ | async"
    [selectedProgramGroupIDs]="selectedProgramGroupIDs$ | async"
    [slidesPerView]="slidesPerView$ | async"
    [saving]="saving$ | async"
    [structureFilter]="structureFilter$ | async"
    [groupFilterByAssoc]="groupFilterByAssoc$ | async"
    [studyID]="studyID$ | async"
    [selectedProgramID]="selectedProgramID$ | async"
    [studies]="studies$ | async"
    [accountOpportunities]="accountOpportunities$ | async"
    [currencyList]="currencyList$ | async"
    [filteredCurrencyList]="currencyList$ | async"
    [compareCurrency]="compareCurrency$ | async"
    [tabSelected]="tabSelected$ | async"
    (selectedCurrency)="getSelectedCurrency($event)"
    (selectedConversion)="getSelectedConversion($event)"
    [compareViews]="compareViews$ | async"
    [selectedCompareView]="selectedCompareView$ | async"
    (programAdd)="onProgramAdd($event)"
    (programGroupAdd)="onProgramGroupAdd($event)"
    (programRemove)="onProgramRemove($event)"
    (programGroupRemove)="onProgramGroupRemove($event)"
    (saveClick)="onSaveClick()"
    (slidesPerViewChange)="onSlidesPerViewChange($event)"
    (structureFilterChange)="onStructureFilterChange($event)"
    (groupFilterByAssocChange)="onGroupFilterByAssocChange($event)"
    (export)="onExport($event)"
    (saveCompareViewClick)="onCompareViewSaveClick($event)"
    (saveAsCompareViewClick)="onCompareViewSaveAsClick($event)"
    (deleteCompareViewClick)="onCompareViewDeleteClick($event)"
    (updateSelectedCompareView)="onUpdateSelectedCompareView($event)"
    (tabIndex)="setTabIndex($event)"
  ></app-group-compare>
</app-theme-container>
