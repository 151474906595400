import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
@Component({
  selector: 'app-quote-confirmation-dialog',
  templateUrl: './quote-confirmation-dialog.component.html',
  styleUrls: ['./quote-confirmation-dialog.component.scss'],
})
export class QuoteConfirmationDialogComponent {
  title: string
  constructor(
    public dialogRef: MatDialogRef<QuoteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.title =
      'Do you want to overwrite ' +
      data +
      ' with value from Analyze Re or keep the manually overridden value?'
  }

  useAnalyze(): void {
    this.dialogRef.close('useAnalyze')
  }
  useManual(): void {
    this.dialogRef.close('useManual')
  }
}
