import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { ProgramEvents } from '../model/program-events.model'

export const saveOrUpdateProgramEvents = createAction(
  '[Program Events] Save Or Program Events',
  props<{ programEvents: ProgramEvents }>()
)

export const saveOrUpdateProgramEventsSuccess = createAction(
  '[Program Events] Save Or Program Events Success',
  props<{ programEvents: ProgramEvents }>()
)

export const saveOrUpdateProgramEventsFailure = createAction(
  '[Program Events] Save Or Program Events Failure',
  props<{ error: ApplicationError }>()
)
