import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core'
import { NgModel } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface NewGroupDialogComponentData {
  groupName: string
  pageName?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-group',
  styleUrls: ['./new-group-dialog.component.scss'],
  templateUrl: './new-group-dialog.component.html',
})
export class NewGroupDialogComponent {
  groupName: string
  @ViewChild('groupNameModel')
  groupNameModel: NgModel
  pageName = 'Group'

  constructor(
    public dialogRef: MatDialogRef<NewGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewGroupDialogComponentData
  ) {
    this.groupName = data.groupName ?? ''
    this.pageName = data.pageName ?? ''
  }

  newGroupClick(): void {
    if (!this.groupNameModel.invalid) {
      this.dialogRef.close(this.groupName)
    }
  }
}
