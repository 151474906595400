import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from '../../api/backend/backend.service'
import { Client } from '../../core/model/client.model'
import { AppState } from '../../core/store'
import { selectRouterStateUrl } from '../../core/store/router.selectors'
import { Size } from '@shared/size.mixin'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-selected-client-container',
  styleUrls: ['./selected-client.container.scss'],
  templateUrl: './selected-client.container.html',
})
export class SelectedClientContainerComponent implements OnChanges {
  @Input() selectedClient: Client
  @Input() name?: string
  @Input() size: Size
  @Output() clientClick = new EventEmitter<string>()

  imageURI: string
  routerUrl: string

  private destroy$ = new Subject()

  get isClientSelect() {
    return (
      this.routerUrl === '/clients/select' ||
      this.routerUrl === `/clients/${this.selectedClient.id}/select`
    )
  }

  constructor(
    private store: Store<AppState>,
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.selectedClient &&
      changes.selectedClient.currentValue.id !==
        changes.selectedClient.previousValue?.id
    ) {
      this.fetchCarrierImage()
      this.getRouterUrl()
    }
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }

  fetchCarrierImage(): void {
    this.backendService
      .getCarrierImage(Number.parseInt(this.selectedClient.id, 10))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.createImageFromBlob(data)
      })
  }

  getRouterUrl(): void {
    this.store
      .pipe(select(selectRouterStateUrl))
      .pipe(takeUntil(this.destroy$))
      .subscribe(routerUrl => (this.routerUrl = routerUrl))
  }
}
