import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { OptimizationStepActionConfig, stepActions } from '../optimization.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-header',
  styleUrls: ['./optimization-header.component.scss'],
  templateUrl: './optimization-header.component.html',
})
export class OptimizationHeaderComponent {
  @Input() actionConfig: OptimizationStepActionConfig
  @Input() stepIndex: number
  @Input() disableAction: boolean

  @Output() actionClick = new EventEmitter<typeof stepActions[number]>()
  @Output() closeClick = new EventEmitter()
  @Output() resetClick = new EventEmitter()
  @Output() backClick = new EventEmitter()

  get title() {
    return 'Optimization'
  }

  get subtitle() {
    return 'Discover optimal Structures by performing an analysis of underlying losses using a set of user driven constraints.'
  }

  get actionName() {
    const actionID = stepActions[this.stepIndex]
    return this.actionConfig[actionID]
  }

  onActionClick() {
    if (!this.disableAction) {
      this.actionClick.emit(stepActions[this.stepIndex])
    }
  }
}
