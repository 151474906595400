import { createAction, props } from '@ngrx/store'
import { Change, OptimizationInputRange } from '../optimization.model'

export const setRanges = createAction(
  '[Optimization] Set Ranges',
  props<{ ranges: OptimizationInputRange[] }>()
)

export const updateRanges = createAction(
  '[Optimization] Update Ranges',
  props<Change<OptimizationInputRange>>()
)
