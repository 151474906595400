import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { PricingCurveAddDataDialogPayload } from '../../dialog/pricing-curve-dialog.model'
import * as fromSelectors from '../../../store/pricing-curve.selectors'
import * as fromActions from '../../../store/pricing-curve.actions'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import {
  DialogData,
  PricingCurveAddDataDialogTabs,
  PricingCurveContextTypes,
  PricingCurveData,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SavedCurveSelectors,
  SavedPricingCurveEntry,
  SubmitPricingCurvePayload,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import { PricingCurveAddDataDialogComponent } from '../add-data-dialog/pricing-curve-add-data-dialog.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-data-dialog-container',
  styleUrls: ['./add-data-dialog.container.scss'],
  templateUrl: './add-data-dialog.container.html',
})
export class AddDataDialogContainerComponent implements OnInit {
  workingCurveData$: Observable<PricingCurveData | null>
  dialogData$: Observable<DialogData | undefined>
  savedCurves$: Observable<SavedPricingCurveEntry[] | undefined>
  savedCurveSelectors$: Observable<SavedCurveSelectors | undefined>
  status$: Observable<PricingCurveStatus | undefined>
  currentDataSetNames$: Observable<string[] | undefined>
  savedCurveNameMap$: Observable<{ id: number; name: string }[] | undefined>
  addedCurveIds$: Observable<number[]>
  context$: Observable<PricingCurveContextTypes>
  graphSettings$: Observable<PricingCurveGraphSettings>
  readonly$: Observable<boolean>

  constructor(
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: PricingCurveAddDataDialogPayload,
    public matDialogRef: MatDialogRef<PricingCurveAddDataDialogComponent>
  ) {}

  ngOnInit(): void {
    this.initObservables()
  }

  initObservables(): void {
    this.workingCurveData$ = this.store.select(
      fromSelectors.selectWorkingPricingCurveData
    )
    this.dialogData$ = this.store.select(fromSelectors.selectAddDialogDetails)
    this.savedCurves$ = this.store.select(fromSelectors.selectDialogSavedCurves)
    this.savedCurveSelectors$ = this.store.select(
      fromSelectors.selectSavedCurveSelectors
    )
    this.status$ = this.store.select(fromSelectors.selectPricingCurveStatus)
    this.currentDataSetNames$ = this.store.select(
      fromSelectors.selectCurrentDataSetNames
    )
    this.savedCurveNameMap$ = this.store.select(
      fromSelectors.selectSavedCurvesNameMap
    )
    this.addedCurveIds$ = this.store
      .select(fromSelectors.selectPricingCurves)
      .pipe(map(curves => curves.map(curve => curve.id)))
    this.context$ = this.store.select(fromSelectors.selectPricingCurveContext)
    this.graphSettings$ = this.store.select(
      fromSelectors.selectPricingCurveGraphSettings
    )
    this.readonly$ = this.store.select(fromSelectors.selectIsPricingCurveReadOnly)
  }

  destroyDialog(): void {
    this.matDialogRef.close()
  }

  tabChanged(tab: PricingCurveAddDataDialogTabs): void {
    this.store.dispatch(fromActions.updateAddDataDialogTab({ tab }))
  }

  layerSplitViewChanged(layerSplitView: boolean): void {
    this.store.dispatch(
      fromActions.updateWorkingCurveData({
        curveData: { layerSplitView },
      })
    )
    this.store.dispatch(fromActions.fetchLayers({ layerSplitView }))
    this.store.dispatch(fromActions.fetchSelectors({ layerSplitView }))
  }

  setManual(isManual: boolean): void {
    this.store.dispatch(
      fromActions.updateWorkingCurveData({
        curveData: {
          isManual,
        },
      })
    )
  }

  submitCurve({ data, save }: SubmitPricingCurvePayload): void {
    if (data.context === 'pricing-curve') {
      // Removes layers if the curve is manual
      const curveData: PricingCurveData = {
        ...data,
        layers: data.isManual ? [] : data.layers,
      }
      if (save) {
        this.store.dispatch(fromActions.savePricingCurve({ curve: curveData }))
      } else {
        if (data.initialSetup) {
          this.store.dispatch(fromActions.addCurveToGraph({ curveData }))
        } else {
          this.store.dispatch(
            fromActions.updatePricingCurve({ id: data.id, curveData })
          )
        }
      }
    } else {
      this.store.dispatch(
        fromActions.submitCreditCurve({ curve: data, saveCurve: save })
      )
    }
    this.destroyDialog()
  }
}
