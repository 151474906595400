import {
  PortfolioViewID,
  PortfolioSetAndStudyIDs,
} from '../../model/portfolio-set.model'
import { props, createAction } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { MiscMetricsResult } from '../../model/misc-metrics.model'

export const fetchMiscMetrics = createAction(
  '[Misc Metrics] Fetch',
  props<PortfolioSetAndStudyIDs & PortfolioViewID>()
)

export const fetchMiscMetricsSuccess = createAction(
  '[Misc Metrics] Fetch Success',
  props<PortfolioSetAndStudyIDs & MiscMetricsResult>()
)

export const fetchMiscMetricsFailure = createAction(
  '[Misc Metrics] Fetch Failure',
  props<PortfolioSetAndStudyIDs & { error: ApplicationError }>()
)
