/// <reference types="@types/dom-mediacapture-record" />

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class Recorder {
  private recorder: MediaRecorder
  private stream: MediaStream
  isRecording = new BehaviorSubject<boolean>(false)

  async startRecording(handler: (blob: Blob) => void) {
    try {
      this.stream = await (navigator.mediaDevices as any).getDisplayMedia({
        video: true,
      })
    } catch (err) {
      return
    }
    this.recorder = new MediaRecorder(this.stream, {
      mimeType: 'video/webm; codecs=vp8',
    })

    const chunks: Blob[] = []
    this.recorder.onstart = () => this.isRecording.next(true)
    this.recorder.ondataavailable = e => chunks.push(e.data)
    this.recorder.onstop = _e => {
      const completeBlob = new Blob(chunks, { type: chunks[0].type })
      this.isRecording.next(false)
      handler(completeBlob)
    }

    this.recorder.start()
  }

  stopRecording() {
    this.recorder.stop()
    this.stream.getVideoTracks()[0].stop()
  }
}
