import {
  PortfolioSetAndStudyIDs,
  SharedIDPortfolio,
} from './portfolio-set.model'

export function isPortfolioSetAndStudyIDs(
  portfolioSetIDs: any
): portfolioSetIDs is PortfolioSetAndStudyIDs {
  return (
    portfolioSetIDs != null &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).clientID !== undefined &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).studyID !== undefined &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).analysisProfileID !==
      undefined &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).cededPortfolioID !==
      undefined &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).netPortfolioID !== undefined &&
    (portfolioSetIDs as PortfolioSetAndStudyIDs).grossPortfolioID !== undefined
  )
}

export const isGroupsEquals = (
  group: SharedIDPortfolio[],
  newGroup: string[]
): boolean => {
  let isEquals = false

  if (group.length === 0) {
    return false
  }

  let valueA = ''
  newGroup.forEach(a => {
    valueA = valueA + a
  })

  group.forEach(g => {
    let valueB = ''
    g.group.forEach(b => {
      valueB = valueB + b
    })

    if (valueA === valueB) {
      isEquals = true
    }
  })
  return isEquals
}
