import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReducer from './contact-support.reducer'

export const selectContactSupportState =
  createFeatureSelector<fromReducer.ContactSupportState>(
    fromReducer.FEATURE_KEY
  )

export const selectAllUsers = createSelector(
  selectContactSupportState,
  state => state.allUsersContactsList
)

export const SelectUserEmailListSelector = createSelector(
  selectContactSupportState,
  state => state.userEmailList
)
