import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { of, OperatorFunction, pipe } from 'rxjs'
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../../core/store'
import {
  selectGrouperProgramIDs,
  selectGrouperSlideIndex,
  selectGrouperSlidesPerView,
} from '../../analysis.selectors'
import { addProgramGroupSuccess } from '../program-group/program-group.actions'
import {
  addProgramToGroup,
  moveGrouperProgram,
} from '../program/program.actions'
import { calculateTowerSize } from './calculate-tower-size'
import {
  refreshGrouperTowerSizePercentage,
  setGrouperSlideIndex,
  setGrouperSlidesPerView,
  setGrouperTowerSizePercentage,
} from './grouper-view.actions'

@Injectable()
export class GrouperViewEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  slideProgramIntoViewOnAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProgramToGroup),
      map(action => action.program.id),
      this.slideProgramIntoView()
    )
  )

  slideProgramIntoViewOnMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveGrouperProgram),
      map(action => action.id),
      this.slideProgramIntoView()
    )
  )

  private slideProgramIntoView(): OperatorFunction<string, Action> {
    return pipe(
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramIDs)),
            this.store.pipe(select(selectGrouperSlideIndex)),
            this.store.pipe(select(selectGrouperSlidesPerView))
          )
        )
      ),
      map(([id, allIDs, slideIndex, slidesPerView]) => {
        const toIndex = allIDs.findIndex(_id => _id === id)
        const slideLastIndex = slideIndex + slidesPerView - 1
        if (toIndex > slideLastIndex) {
          return toIndex - slidesPerView + 1
        }
        if (toIndex < slideIndex) {
          return toIndex
        }
        return -1
      }),
      filter(index => index >= 0),
      map(index => setGrouperSlideIndex({ index, force: true }))
    )
  }

  slideProgramGroupIntoViewOnAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProgramGroupSuccess),
      map(action => action.programs),
      map(programs => programs.map(p => p.id)),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramIDs)),
            this.store.pipe(select(selectGrouperSlideIndex)),
            this.store.pipe(select(selectGrouperSlidesPerView))
          )
        )
      ),
      map(([ids, allIDs, slideIndex, slidesPerView]) => {
        const indices = ids
          .map(id => allIDs.findIndex(_id => _id === id))
          .sort()
        if (indices.length === 0) {
          return -1
        }
        const start = indices[0]
        const end = indices[indices.length - 1]
        const slideLastIndex = slideIndex + slidesPerView - 1
        if (end > slideLastIndex) {
          if (start > slideIndex) {
            if (end - start > slideLastIndex - slideIndex) {
              return start
            } else {
              return end - slidesPerView + 1
            }
          }
        } else if (start < slideIndex) {
          return start
        }
        return -1
      }),
      filter(index => index >= 0),
      map(index => setGrouperSlideIndex({ index }))
    )
  )

  calculateTowerSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGrouperSlidesPerView, refreshGrouperTowerSizePercentage),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(this.store.pipe(select(selectGrouperSlidesPerView)))
        )
      ),
      map(([_, slidesPerView]) => calculateTowerSize(slidesPerView)),
      map(percentage => setGrouperTowerSizePercentage({ percentage }))
    )
  )
}
