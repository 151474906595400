import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { WhitespaceSyncWarningsData } from '../models/quote.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

@Component({
  selector: 'app-whitespace-sync-warnings',
  templateUrl: './whitespace-sync-warnings.component.html',
  styleUrls: ['./whitespace-sync-warnings.component.scss'],
})
export class WhitespaceSyncWarningsComponent {
  columnDefs: SortTableColumnDef[] = [
    {
      id: 'underwriterRef',
      label: 'Underwriter Ref',
      valueType: 'text',
      editable: false,
    },
  ]

  overWriteColumnDefs: SortTableColumnDef[] = [
    {
      id: 'reinsurer',
      label: 'Reinsurer',
      valueType: 'text',
      editable: false,
    },
    {
      id: 'layer',
      label: 'On',
      valueType: 'text',
      editable: false,
    },
    {
      id: 'warning',
      label: 'Warning',
      valueType: 'text',
      editable: false,
    },
  ]
  rows: {}[] = []

  constructor(
    // private _dialogRef: MatDialogRef<WhitespaceSyncWarningsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: WhitespaceSyncWarningsData
  ) {
    data.duplicateRiskRefs.forEach((value, key) => {
      const layerStructureMapping: { [key: string]: string } = {}
      value.forEach(setElement => {
        const subStructure = JSON.parse(setElement) as {
          layer: string
          line: string
        }
        if (!this.columnDefs.find(x => x.id === subStructure.layer)) {
          this.columnDefs.push({
            id: subStructure.layer,
            label: subStructure.layer,
            valueType: 'text',
            editable: false,
            bodyStyle: {
              maxWidth: '15vw',
            },
          })
        }
        layerStructureMapping[subStructure.layer] = layerStructureMapping[
          subStructure.layer
        ]
          ? `${layerStructureMapping[subStructure.layer]}, ${subStructure.line}`
          : subStructure.line
      })
      this.rows.push({
        underwriterRef: key,
        ...layerStructureMapping,
      })
    })
  }
}
