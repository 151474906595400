import { uniqBy } from 'ramda'
import { BenchmarkChartView } from '../../benchmark/model/benchmark-chart-view'
import { benchmarkOptions } from '../../benchmark/model/benchmark-options'
import {
  BenchmarkCompany,
  BenchmarkPeer,
  BenchmarkPeerSet,
} from '../../benchmark/model/benchmark.model'
import { isBenchmarkPeerSet } from '../../benchmark/model/benchmark.util'

export interface BenchmarkEntityPair {
  company?: BenchmarkCompany
  peerSet?: BenchmarkPeerSet
}

export interface BenchmarkQuantilePeerSet
  extends Omit<BenchmarkPeerSet, 'type' | 'mode'> {
  quantileP?: number
}

/**
 * Data order should be: (1) company 1 (i.e. target company), (2) peer sets,
 * and (3) remaining companies.
 */
export const getSortedBenchmarkEntityPairs = (
  view: BenchmarkChartView,
  {
    combinePeerSets,
    inclSeparatePeerSets,
  }: { combinePeerSets?: boolean; inclSeparatePeerSets?: boolean } = {}
): BenchmarkEntityPair[] => {
  const pairs: BenchmarkEntityPair[] = []
  if (view?.companies && !view?.excludeTargetCompany) {
    const target = view.companies[0]
    if (isBenchmarkPeerSet(target)) {
      pairs.push({ peerSet: target })
    } else {
      pairs.push({ company: target })
    }
  }
  if (view?.peerSets) {
    view.peerSets.forEach(peerSet => {
      if (!inclSeparatePeerSets && (combinePeerSets || peerSet.isComposite)) {
        pairs.push({ peerSet })
      } else {
        pairs.push(...peerSet.peers.map(company => ({ company, peerSet })))
      }
      if (inclSeparatePeerSets) {
        pairs.push({ peerSet })
      }
    })
  }
  if (view?.companies && view.companies.length > 1) {
    const [, ...companies] = view.companies
    pairs.push(...companies.map(company => ({ company })))
  }
  return pairs
}

export const getSortedBenchmarkEntities = (
  view: BenchmarkChartView,
  options: { includeQuantile?: boolean } = {}
): (BenchmarkCompany | BenchmarkPeerSet | BenchmarkQuantilePeerSet)[] => {
  const entities: (BenchmarkCompany | BenchmarkPeerSet)[] = []
  const quantilePeers: BenchmarkPeer[] = []

  if (view?.companies && !view?.excludeTargetCompany) {
    entities.push(view.companies[0])
  }
  if (view?.peerSets) {
    const peerSets = view.peerSets
    entities.push(...peerSets)

    if (options.includeQuantile) {
      // Exclude quantile peer sets by ID
      // If quantile peer set selection is not shown then show all quantile peer sets.
      // It is shown if `showPeerSetQuantileSelect` is `true` or `undefined` and hidePeers is not `true`.
      const quantilePeerSets = peerSets.filter(
        ps =>
          view.excludeQuantileByID?.[ps.id] !== true ||
          view.submode?.showPeerSetQuantileSelect === false ||
          view.submode.hidePeers
      )
      quantilePeers.push(...quantilePeerSets.flatMap(ps => ps.peers))
    }
  }
  if (view?.companies && view.companies.length > 1) {
    const [, ...companies] = view.companies
    entities.push(...companies)

    if (options.includeQuantile) {
      quantilePeers.push(...companies)
    }
  }

  if (quantilePeers.length > 0) {
    const peers = uniqBy(p => p.id, quantilePeers)
    // Get quantile options that will be used to be add quantile series
    const quantiles = benchmarkOptions
      .filter(q => q.type === 'quantile' && q.quantileP != null)
      .filter(q => view.submode.options?.some(o => o.id === q.id))
      .map(q => ({
        id: q.id,
        name: q.name,
        peers,
        quantileP: q.quantileP,
        isComposite: true,
      }))

    return [...quantiles, ...entities]
  }

  return entities
}
