import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Layer } from 'src/app/analysis/model/layers.model'
import { Reinstatement } from 'src/app/api/analyzere/analyzere.model'
import { Program } from 'src/app/core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-premium-input',
  styleUrls: ['./premium-input.component.scss'],
  templateUrl: './premium-input.component.html',
})
export class PremiumInputComponent {
  @Input() value: string | number
  @Input() currentCurrency: string
  @Input() reinsurers: string
  @Input() pricingCurves: string
  @Input() isNew: boolean
  @Input() isQuotaShare: boolean
  @Input() layerDetails: Layer
  @Input() currentProgramDetails: Program

  @Output() inputChange = new EventEmitter<string | number | Reinstatement[]>()
  @Output() inputKeyup = new EventEmitter<void>()
  @Output() showReinsurerDialog = new EventEmitter<void>()
  @Output() inputSubmit = new EventEmitter<void>()

  techCedingCommissionLabel = 'Technical Ceding Commission'
}
