import {
  CompareMetricSetting,
  CompareMetricTableCategory,
} from 'src/app/analysis/model/compare-metrics.model'
import { rejectNil } from '@shared/util/operators'
import { md5 } from 'src/app/shared/util/hash'
import { MetricSettingsSaveChanges } from '../../../../api/metric-settings/metric-settings-response.model'

export const NO_CATEGORY_NAME = '__NONE__'

interface CategoryInfo {
  labels: string[]
  labelMap: Record<string, CompareMetricSetting[]>
  hasPortfolioTypes: boolean
}

const initializeCategoryInfo = () => ({
  labels: <string[]>[],
  labelMap: {},
  hasPortfolioTypes: false,
})

export function createAllMetricTableCategoryValues(
  settings: CompareMetricSetting[]
): CompareMetricTableCategory[] {
  const init = {
    categories: [] as string[],
    categoryMap: {} as Record<string, CategoryInfo>,
  }
  const { categories, categoryMap } = settings.reduce((acc, val) => {
    const category = val.category || NO_CATEGORY_NAME
    if (acc.categoryMap[category] == null) {
      acc.categories.push(category)
      acc.categoryMap[category] = initializeCategoryInfo()
    }
    if (acc.categoryMap[category].labelMap[val.label + val.path] == null) {
      acc.categoryMap[category].labelMap[val.label + val.path] = []
      acc.categoryMap[category].labels.push(val.label + val.path)
    } else if (
      acc.categoryMap[category].labelMap[val.label + val.path].length === 1
    ) {
      acc.categoryMap[category].hasPortfolioTypes = true
    }
    acc.categoryMap[category].labelMap[val.label + val.path].push(val)
    return acc
  }, init)

  // Move Capital & Efficiency b/w Ceded & Net so that Ceded & Net appear
  // next to each other on the Metrics cart page
  if (categories.length > 5) {
    const category2 = categories[2]
    const category3 = categories[3]
    categories[2] = categories[4]
    categories[3] = categories[5]
    categories[4] = category2
    categories[5] = category3
  }

  const MetricsCat = rejectNil(categories).map(category => {
    const { labels, labelMap, hasPortfolioTypes } = categoryMap[category]
    const metrics = labels.map(label => labelMap[label])
    return { category, metrics, hasPortfolioTypes }
  })
  const ceded = MetricsCat.find(c => c.category === 'Ceded Cost Metrics')
  if (ceded) {
    const cat1 = ceded.metrics[2]
    const cat2 = ceded.metrics[3]
    const cat3 = ceded.metrics[4]
    const cat4 = ceded.metrics[5]
    const cat5 = ceded.metrics[6]
    const cat6 = ceded.metrics[7]

    ceded.metrics[2] = cat5
    ceded.metrics[3] = cat1
    ceded.metrics[4] = cat6
    ceded.metrics[5] = cat2
    ceded.metrics[6] = cat3
    ceded.metrics[7] = cat4
  }
  return MetricsCat
}

export function buildMetricHash(s: CompareMetricSetting) {
  return md5(
    JSON.stringify(s)
  )
}

export function getMetricSettingKey(metric: MetricSettingsSaveChanges): string {
  return `${metric.id ?? 'ID_UNDEFINED'}_${metric.metric_setting_id}`
}
