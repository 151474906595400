import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import {
  fetchLayersViewMetrics,
  fetchLayersViewMetricsFailure,
  fetchLayersViewMetricsSuccess,
} from '../metrics/layers-metrics.actions'
import * as fromMetrics from '../metrics/metrics.reducer'
import {
  fetchPortfolioViewDetailMetrics,
  fetchPortfolioViewDetailMetricsFailure,
  fetchPortfolioViewDetailMetricsSuccess,
} from '../metrics/portfolio-detail-metrics.actions'
import {
  fetchPortfolioViewMetrics,
  fetchPortfolioViewMetricsFailure,
  fetchPortfolioViewMetricsSuccess,
  setExpectedSD,
  updateAndFetchExpectedSD,
  updateRSS,
  updateAndFetch,
} from '../metrics/portfolio-metrics.actions'
import {
  addCededLayerView,
  fetchCededLayersViews,
  fetchCededLayersViewsFailure,
  fetchCededLayersViewsSuccess,
  fetchPortfolioView,
  fetchPortfolioViewFailure,
  fetchPortfolioViewSuccess,
} from '../views/portfolio-view.actions'
import * as fromPortfolioView from '../views/portfolio-view.reducer'
import {
  fetchVolatilityMetrics,
  fetchVolatilityMetricsFailure,
  fetchVolatilityMetricsSuccess,
} from '../metrics/volatility-metrics.actions'
import {
  fetchCapitalMetrics,
  fetchCapitalMetricsSuccess,
  fetchCapitalMetricsFailure,
} from '../metrics/capital-metrics.actions'
import {
  fetchEfficiencyMetricsSuccess,
  fetchEfficiencyMetricsFailure,
  fetchEfficiencyMetrics,
} from '../metrics/efficiency-metrics.actions'
import {
  fetchMiscMetrics,
  fetchMiscMetricsFailure,
  fetchMiscMetricsSuccess,
} from '../metrics/misc-metrics.actions'
import {
  fetchTailMetrics,
  fetchTailMetricsSuccess,
  fetchTailMetricsFailure,
} from '../metrics/tail-metrics.actions'
import { startAnalysis } from '../analysis.actions'

export { buildKey as buildPortfolioSetKey }

export interface PortfolioSetEntity {
  id: PortfolioSetID
  portfolioView: fromPortfolioView.State
  viewMetrics: fromMetrics.State
}

export const initialEntity: PortfolioSetEntity = {
  id: {
    analysisProfileID: '',
    cededPortfolioID: '',
    grossPortfolioID: '',
    netPortfolioID: '',
  },
  portfolioView: fromPortfolioView.initialState,
  viewMetrics: fromMetrics.initialState,
}

const buildKey = (id: PortfolioSetID): string =>
  `${id.analysisProfileID}_` +
  `${id.cededPortfolioID}_${id.grossPortfolioID}_${id.netPortfolioID}`

export const adapter = createEntityAdapter<PortfolioSetEntity>({
  selectId: entity => buildKey(entity.id),
})

export type State = EntityState<PortfolioSetEntity>

export const initialState: State = adapter.getInitialState()

export const {
  selectAll: selectAllPortfolioSetEntities,
} = adapter.getSelectors()

const entityReducer = combineReducers<Omit<PortfolioSetEntity, 'id'>>({
  portfolioView: fromPortfolioView.reducer,
  viewMetrics: fromMetrics.reducer,
})

const updateEntityReducer = (
  state: State,
  action: Partial<PortfolioSetID> & Action
): State => {
  const id = extractPortfolioSetID(action)
  if (id) {
    const prev = state.entities[buildKey(id)]
    const next = { id, ...entityReducer(prev, action) }
    return adapter.upsertOne(next, state)
  }
  return state
}

const portfolioSetReducer = createReducer(
  initialState,
  on(
    fetchPortfolioView,
    fetchPortfolioViewSuccess,
    fetchPortfolioViewFailure,
    addCededLayerView,
    fetchCededLayersViews,
    fetchCededLayersViewsSuccess,
    fetchCededLayersViewsFailure,
    fetchPortfolioViewDetailMetrics,
    fetchPortfolioViewDetailMetricsSuccess,
    fetchPortfolioViewDetailMetricsFailure,
    // `on` is only typed for 10 actions, continue below
    updateEntityReducer
  ),
  on(
    fetchPortfolioViewMetrics,
    fetchPortfolioViewMetricsSuccess,
    fetchPortfolioViewMetricsFailure,
    updateAndFetch,
    updateAndFetchExpectedSD,
    setExpectedSD,
    updateRSS,
    updateEntityReducer
  ),
  on(
    fetchLayersViewMetrics,
    fetchLayersViewMetricsSuccess,
    fetchLayersViewMetricsFailure,
    fetchVolatilityMetrics,
    fetchVolatilityMetricsFailure,
    fetchVolatilityMetricsSuccess,
    fetchCapitalMetrics,
    fetchCapitalMetricsSuccess,
    fetchCapitalMetricsFailure,
    updateEntityReducer
  ),
  on(
    fetchEfficiencyMetrics,
    fetchEfficiencyMetricsSuccess,
    fetchEfficiencyMetricsFailure,
    fetchTailMetrics,
    fetchTailMetricsFailure,
    fetchTailMetricsSuccess,
    fetchMiscMetrics,
    fetchMiscMetricsFailure,
    fetchMiscMetricsSuccess,
    startAnalysis,
    updateEntityReducer
  )
)

export function reducer(state: State | undefined, action: Action): State {
  return portfolioSetReducer(state, action)
}
