import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
  AgencyDetails,
  FUND_MANAGER_DIALOG_COLUMNS,
  FundManagerDialogDatum,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import {
  AssignedLines,
  QuoteReinsurer,
  createAlID,
} from '../models/quote.model'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-seg-account-detail-dialog-component',
  styleUrls: ['./quote-seg-account-detail.component.scss'],
  templateUrl: './quote-seg-account-detail.component.html',
})
export class QuoteSegregatedAccountDetailsDialogComponent implements OnInit {
  fundManagerColumns = FUND_MANAGER_DIALOG_COLUMNS
  fundManagerRows: FundManagerDialogDatum[]
  segregatedAccountsSelectedForSave: AgencyDetails[]

  @Input() programID: string
  @Input() fundManager: Reinsurer
  @Input() segregatedAccounts: AgencyDetails[]
  @Input() selectedSegregatedAccountsData: AgencyDetails[]
  @Input() selectedReinsurerEntity: QuoteReinsurer

  @Output() addNewAssignedLines = new EventEmitter<{
    id: string
    assignedLines: AssignedLines
  }>()
  @Output() updateAssignedLines = new EventEmitter<{
    id: string
    assignedLinesUpdated: AssignedLines[]
    assignedLinesToDelete: AssignedLines[]
  }>()
  @Output() saveSegregatedAccounts = new EventEmitter<{
    programID: string
    fundManager: Reinsurer
    selectedSegregatedAccounts: AgencyDetails[]
  }>()

  constructor(
    public dialogRef: MatDialogRef<QuoteSegregatedAccountDetailsDialogComponent>
  ) {}

  ngOnInit() {
    this.fundManagerRows = this.getSegregatedAccountRows()
    this.segregatedAccountsSelectedForSave =
      this.getUpdatedSelectedSegregatedAccount()
  }

  private getSegregatedAccountRows(): FundManagerDialogDatum[] {
    return this.segregatedAccounts.map(paper => {
      return {
        id: `${paper.agencyName}:${paper.agencyTPRef}`,
        agencyName: paper.agencyName,
        agencyTPRef: paper.agencyTPRef ?? '',
        ilsInternalNarr: paper.ilsInternalNarr ?? '',
        ilsName: paper.ilsName ?? '',
        selected: this.isSegregatedAccountSelected(paper),
      }
    })
  }

  private isSegregatedAccountSelected(paper: AgencyDetails): boolean {
    return this.selectedSegregatedAccountsData.some(
      paperSelected =>
        paper.agencyName === paperSelected.agencyName &&
        paper.agencyTPRef === paperSelected.agencyTPRef
    )
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<FundManagerDialogDatum>) {
    const rowIdx = this.fundManagerRows.findIndex(r => r.id === id)
    if (rowIdx !== -1) {
      const updatedRows = [...this.fundManagerRows]
      updatedRows[rowIdx] = { ...updatedRows[rowIdx], [column.id]: value }
      this.fundManagerRows = updatedRows
      this.segregatedAccountsSelectedForSave =
        this.getUpdatedSelectedSegregatedAccount()
    }
  }

  private getUpdatedSelectedSegregatedAccount(): AgencyDetails[] {
    return this.segregatedAccounts.filter(paper =>
      this.fundManagerRows.find(
        row =>
          row.selected &&
          paper.agencyTPRef === row.agencyTPRef &&
          row.agencyName === paper.agencyName
      )
    )
  }

  onAddClick() {
    if (this.segregatedAccountsSelectedForSave.length <= 0) {
      return
    }
    const previousRows =
      this.selectedReinsurerEntity.riskAssignedLinesLink ?? []
    const placeholderRows = previousRows.filter(
      row => row.reinsurer === this.fundManager?.name
    )
    const assignedLinesUpdated = this.getAssignedLinesUpdated(
      previousRows,
      placeholderRows
    )
    this.updateAssignedLines.emit({
      id: this.selectedReinsurerEntity.id,
      assignedLinesUpdated,
      assignedLinesToDelete: placeholderRows,
    })
    this.saveSegregatedAccounts.emit({
      programID: this.programID,
      fundManager: this.fundManager,
      selectedSegregatedAccounts: this.segregatedAccountsSelectedForSave,
    })
  }

  onAddPlaceholderClick() {
    this.addNewAssignedLines.emit({
      id: this.selectedReinsurerEntity.id,
      assignedLines: {
        id: createAlID(),
        reinsurer: this.fundManager.name,
        bureaus: '',
        written: 0,
        recommended: 0,
        signed: 0,
        signedOfAuthorized: 0,
        placedThrough: '',
        brokerage: 0,
        brokerageRe: 0,
        contract: '',
        subjectivity: '',
        marketTpRef: this.fundManager.tpRef ?? '',
        coBroker: '',
        leadMarket: '',
        coBrokerRef: '',
        underwriterRef: '',
        relationSeqNumber: 0,
        placeholder: true,
        brokerageCoBrokerShare: 0,
        brokerageReCoBrokerShare: 0
      },
    })
  }

  private getAssignedLinesUpdated(
    previousRows: AssignedLines[],
    placeholderRows: AssignedLines[]
  ): AssignedLines[] {
    return [
      ...previousRows.filter(
        previous => !placeholderRows.some(row => row.id === previous.id)
      ),
      ...this.getAssignedLinesNew(placeholderRows),
    ]
  }

  private getAssignedLinesNew(
    placeholderRows: AssignedLines[]
  ): AssignedLines[] {
    const [placeholderDetails] = [...placeholderRows]
    return this.segregatedAccountsSelectedForSave.map(
      (account: AgencyDetails) => {
        return {
          bureaus: '',
          written: 0,
          recommended: 0,
          signed: 0,
          signedOfAuthorized: 0,
          placedThrough: '',
          brokerage: 0,
          brokerageRe: 0,
          contract: '',
          subjectivity: '',
          coBroker: '',
          leadMarket: '',
          coBrokerRef: '',
          underwriterRef: '',
          ...placeholderDetails,
          id: createAlID(),
          reinsurer: account.agencyName,
          marketTpRef: account.agencyTPRef ?? '',
          relationSeqNumber: account.agencySeqNumber,
          placeholder: undefined,
          brokerageCoBrokerShare: 0,
          brokerageReCoBrokerShare: 0
        }
      }
    )
  }
}
