export const periodToMonthName = (period: number): string => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][(period + 3) % 12]
}

export const getDetailedForecastTooltipTitle = (chartTitle: string): string => {
  if (chartTitle === 'Actual') {
    return 'Forecast vs Budget'
  }
  return 'Forecast: ' + chartTitle
}
