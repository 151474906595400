import {
  CreditStructure,
  CreditCalculationStructure,
  CreditTransaction,
  TRANSACTION_TEMP_PREFIX,
} from '../model/credit-structure.model'
import {
  AnimatedScenariosResults,
  AnimatedScenariosResultsData,
} from '../model/credit-animated-scenarios.model'
import { CreditMetricSetting } from '../model/credit-metric.model'
import {
  CreditCompareMetric,
  CreditStructureCompareEntity,
} from '../model/credit-compare.model'
import { CreditStructureGroup } from '../model/credit-structure-group.model'
import { CreditModuleContext } from '../model/credit-routes.model'
import { ActivatedRouteSnapshot } from '@angular/router'

export const getToday = (): string => {
  return new Date().toISOString().split('T')[0]
}

export const removeTempTransactionIds = (
  structure: CreditCalculationStructure
): CreditCalculationStructure => {
  return {
    ...structure,
    transactions: structure.transactions.map(({ _id, ...rest }) => {
      return _id.includes(TRANSACTION_TEMP_PREFIX)
        ? (rest as CreditTransaction)
        : { _id, ...rest }
    }),
  }
}

export const isDefined = <T>(arg: T | undefined): arg is T => arg !== undefined

export const isDefinedMetricSettingId = (id?: number): boolean =>
  isDefined(id) && id > 0

export const convertAnimatedScenariosData = (
  AnimatedScenariosResultsData: AnimatedScenariosResultsData
): AnimatedScenariosResults[] => {
  let results: AnimatedScenariosResults[] = []
  let id = 0
  if (
    AnimatedScenariosResultsData.data &&
    AnimatedScenariosResultsData.data.length > 0
  ) {
    AnimatedScenariosResultsData.data.map((as, i) => {
      id = id + i + 1
      results.push({ ...as, id, isActuals: false })
    })
  }
  if (
    AnimatedScenariosResultsData.actualsData &&
    AnimatedScenariosResultsData.actualsData.length > 0
  ) {
    AnimatedScenariosResultsData.actualsData.map((as, i) => {
      id = id + i + 1
      results.push({ ...as, id, isActuals: true })
    })
  }
  return results
}

/** returns label of format `label-scenario`*/
export const getMetricLabel = (label: string, scenario: string): string => {
  const newLabel = label.split('-')[0].trim()
  return `${newLabel} - ${scenario}`
}

export const getMetricLabelParts = (label: string): string[] => {
  return label.split('-').map(part => part.trim())
}

export const getTotalWeight = (
  metricSettings: CreditMetricSetting[]
): number => {
  return metricSettings.reduce((totalWeight, metricSetting) => {
    return metricSetting.show ? totalWeight + metricSetting.weight : totalWeight
  }, 0)
}

export const getCompareEntityForStructure = (
  creditStructure: CreditStructure,
  metrics: CreditCompareMetric[]
): CreditStructureCompareEntity => {
  return {
    credit_calculation_structure_id:
      creditStructure.credit_calculation_structure_id,
    name: creditStructure.label,
    thumbnails: [creditStructure.imageSrc ?? ''],
    metrics,
  }
}

export const getCompareEntityForStructureGroup = (
  creditStructureGroup: CreditStructureGroup,
  metrics: CreditCompareMetric[],
  thumbnails: string[]
): CreditStructureCompareEntity => {
  return {
    credit_calculation_structure_id:
      creditStructureGroup.credit_calculation_structure_id,
    name: creditStructureGroup.label,
    thumbnails,
    metrics,
  }
}

export const useActuals = (context: CreditModuleContext): boolean => {
  return context === CreditModuleContext.Monitoring
}

export const getParamFromRootUrl = (
  router: ActivatedRouteSnapshot,
  searchParam: string
): string => {
  const foundParam = router.params[searchParam] as string | undefined
  if (foundParam) {
    // base case
    return foundParam
  }

  if (router.children.length > 0) {
    // recursive case
    return getParamFromRootUrl(router.children[0], searchParam)
  }
}
