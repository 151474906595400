import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import {
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
} from 'src/app/analysis/model/layer-palette.model'
import {
  DefaultSavedCurveEntry,
  LayerEntry,
  LayerTypeDefaultEntry,
  PricingCurveLayerEntryDTO,
  SavedLayerTypeEntry,
} from 'src/app/analysis/technical-premium/technical-premium.model'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import {
  catchAndHandleError,
  encodeParams,
  mapResponse,
  mapToMaybeData,
  mergeApiResponses,
} from '../util'
import { initDefaults } from 'src/app/analysis/technical-premium/technical-premium.utils'

const API = environment.internalApi

@Injectable({
  providedIn: 'root',
})
export class TechnicalPremiumService {
  constructor(private httpClient: HttpClient) {}

  saveLayerTypeEntries(
    entries: SavedLayerTypeEntry[]
  ): ApiResponse<SavedLayerTypeEntry[]> {
    const url = `${API.base}${API.technicalPremium}`
    return this.httpClient
      .post<SavedLayerTypeEntry[]>(url, entries)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Layer type defaults'))
  }

  deleteLayerTypeEntriesByProgramId(programId: number): ApiResponse<any> {
    const url = `${API.base}${API.technicalPremium}/program/${programId}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Layer type defaults by program id')
      )
  }

  getManyLayerTypeEntries(
    programIds: string[]
  ): ApiResponse<Record<string, LayerTypeDefaultEntry>> {
    const actions: ApiResponse<{ id: string; data: LayerTypeDefaultEntry }>[] =
      programIds.map(programId => this.getLayerTypeEntriesWithId({ programId }))

    return forkJoin(actions).pipe(
      mergeApiResponses(),
      mapResponse(data => {
        const mappings: Record<string, LayerTypeDefaultEntry> = {}
        data.forEach(datum => {
          mappings[datum.id] = datum.data
        })
        return mappings
      })
    )
  }

  getLayerTypeEntriesWithId(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<{ id: string; data: LayerTypeDefaultEntry }> {
    const url = `${environment.internalApi.base}${environment.internalApi.technicalPremium}`
    return this.httpClient
      .get<SavedLayerTypeEntry[] | undefined>(url, {
        params: encodeParams(params),
      })
      .pipe(
        map(items => this.mapLayerTypeEntryResponse(items ?? [])),
        map(entries => {
          return {
            id: (params?.programId as string) ?? '',
            data: initDefaults(entries),
          }
        }),
        mapToMaybeData(),
        catchError(() => of({}))
      )
  }

  getLayerTypeEntries(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<LayerTypeDefaultEntry> {
    if (!params?.['programId']) {
      return of({})
    }
    const url = `${environment.internalApi.base}${environment.internalApi.technicalPremium}`
    return this.httpClient
      .get<SavedLayerTypeEntry[] | undefined>(url, {
        params: encodeParams(params),
      })
      .pipe(
        map(items => this.mapLayerTypeEntryResponse(items ?? [])),
        map(entries => {
          return initDefaults(entries)
        }),
        mapToMaybeData(),
        catchError(() => of({}))
      )
  }

  postPricingCurveLayerRefMappings(
    entries: PricingCurveLayerEntryDTO[]
  ): ApiResponse<PricingCurveLayerEntryDTO[]> {
    const url = `${API.base}${API.technicalPremiumMappings}`
    const entriesNoDuplicates = entries.filter(
      (val, i, arr) =>
        arr.findIndex(
          v =>
            v.layerRef === val.layerRef &&
            v.pricing_curve_id === val.pricing_curve_id
        ) === i
    )
    return this.httpClient
      .post<PricingCurveLayerEntryDTO[]>(url, entriesNoDuplicates)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Layer Ref mappings'))
  }

  getPricingCurveLayerRefMappingsForId(
    id: number
  ): ApiResponse<PricingCurveLayerEntryDTO[]> {
    const url = `${API.base}${API.technicalPremiumMappings}/${id}`
    return this.httpClient
      .get<PricingCurveLayerEntryDTO[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Layer Ref mappings for id')
      )
  }

  deleteLayerRefMappingsForId(id: string): ApiResponse<string> {
    const url = `${API.base}${API.technicalPremiumMappings}/${id}`
    return this.httpClient
      .delete<string>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Layer Ref mappings'))
  }

  deleteManyLayerRefMappings(ids: string[]): ApiResponse<string[]> {
    const observables = ids.map(id => this.deleteLayerRefMappingsForId(id))
    return forkJoin(observables).pipe(
      mergeApiResponses(),
      mapResponse(val => val)
    )
  }

  private mapLayerTypeEntryResponse(items: SavedLayerTypeEntry[]) {
    const entries: Record<string, LayerEntry> = {}
    items.map((item, index) => {
      const entryItem = entries[item.layer_type]
      if (entryItem) {
        entries[item.layer_type] = {
          ...entryItem,
          pricingCurves: entryItem.pricingCurves.concat(
            this.createDefaultCurveObjectForSavedEntry(item)
          ),
          pricingCurveIds: entryItem.pricingCurveIds?.concat(
            item.pricing_curve_id
          ),
        }
      } else {
        entries[item.layer_type] = this.mapLayerTypeEntryToLayerEntry(
          item,
          index
        )
      }
    })
    return entries
  }

  private mapLayerTypeEntryToLayerEntry(
    savedLayerEntry: SavedLayerTypeEntry,
    index: number
  ): LayerEntry {
    const paletteEntry = LAYER_PALETTE.find(
      item => item.id === savedLayerEntry.layer_type
      // tslint:disable-next-line:no-non-null-assertion
    )!
    return {
      id: index,
      name: `${LAYER_PALETTE_PROGRAMS[paletteEntry.program]} - ${
        paletteEntry.name
      }`,
      layerType: savedLayerEntry.layer_type,
      pricingCurves: [
        this.createDefaultCurveObjectForSavedEntry(savedLayerEntry),
      ],
      hasError: false,
      modified: false,
      pricingCurveIds: [savedLayerEntry.pricing_curve_id],
      saveForOnlyNewLayers: false,
    }
  }

  private createDefaultCurveObjectForSavedEntry(
    savedLayerEntry: SavedLayerTypeEntry
  ): DefaultSavedCurveEntry {
    return {
      id: savedLayerEntry.pricing_curve_id,
      pc_name: '',
      percentage: savedLayerEntry.weight_percent,
      value: 0,
    }
  }
}
