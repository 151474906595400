import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { CompareView } from '../../../app/analysis/model/compare-view.model'

@Injectable({
  providedIn: 'root',
})
export class CompareViewService {
  private httpOptions = {
    headers: new HttpHeaders({
      // 'Accept-Encoding': 'gzip, compress, br',
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  getCompareViews(): ApiResponse<CompareView[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.compareView}`
    return this.httpClient
      .get<CompareView[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Compare View'))
  }

  saveCompareView(compareView: CompareView): ApiResponse<CompareView> {
    const url = `${environment.internalApi.base}${environment.internalApi.compareView}`
    return this.httpClient
      .post<CompareView>(url, compareView, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Compare View'))
  }

  deleteCompareView(compareView: CompareView): ApiResponse<string> {
    const url = `${environment.internalApi.base}${environment.internalApi.compareView}/${compareView.id}`
    return this.httpClient
      .delete<string>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Compare View'))
  }
}
