import { FotState } from '../store/fots/fot.reducer'
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'

export interface SignatureCreateContractDialogConfig {
  groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  fots?: FotState[]
}

@Component({
  selector: 'app-signature-create-contract-dialog',
  template: `
    <h1 mat-dialog-title>Create New Signature Contract</h1>
    <mat-divider></mat-divider>

    <form [formGroup]="contractForm" (ngSubmit)="generateContract()">
      <div mat-dialog-content class="dialog-content">
        <div class="fot-select-content">
          <div class="dialog-list-title">Select FOTs for Contract</div>
          <mat-selection-list #fotList formControlName="selectedFots">
            <mat-list-option
              *ngFor="let fot of fots"
              [value]="fot"
              class="dialog-list-option"
            >
              {{ getFotSelectorName(fot) }}
            </mat-list-option>
          </mat-selection-list>
        </div>
        <ng-container *ngIf="getDragAndDropData().length > 1">
          <div class="fot-order-content">
            <div class="dialog-list-title">
              Drag and Drop FOTs to Desired Order
            </div>
            <div
              cdkDropList
              [cdkDropListData]="getDragAndDropData()"
              (cdkDropListDropped)="drop($event)"
              class="dialog-list"
            >
              <div
                class="dialog-list-item"
                *ngFor="let fot of getDragAndDropData()"
                cdkDrag
              >
                {{ getFotSelectorName(fot) }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div mat-dialog-actions>
        <mat-form-field
          class="page-set-name"
          [ngClass]="{ error: allControlsTouched() && contractForm.invalid }"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            placeholder="Contract Name"
            formControlName="pageSetName"
          />
        </mat-form-field>
        <mat-error
          class="dialog-form-error"
          *ngIf="allControlsTouched() && contractForm.invalid"
        >
          Required Inputs Needed
        </mat-error>
        <div class="create-cancel-buttons">
          <button
            appButton
            accent
            type="submit"
            [disabled]="!contractForm.valid"
          >
            Create
          </button>
          <button appButton primary link mat-dialog-close>Cancel</button>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      :host body {
        color: white !important;
      }
      :host {
        display: flex;
        flex-direction: column;
        padding: 24px;
      }
      h1 {
        margin-bottom: 24px;
      }
      .mat-divider {
        border-color: rgba(255, 255, 255, 0.12) !important;
      }
      .dialog-content {
        max-height: 50vh;
        display: flex;
        column-gap: 25px;
        padding-top: 15px;
        overflow-x: hidden;
      }
      .fot-select-content {
        min-width: 33vw;
        overflow-y: auto;
      }
      .fot-order-content {
        min-width: 30vw;
        overflow-y: auto;
        padding-right: 15px;
      }
      .dialog-list-title {
        font-size: var(--font-size);
        font-weight: bold;
        margin-top: 10px;
      }
      .dialog-list {
        min-height: 40px;
        border-radius: 4px;
        overflow: hidden;
        display: block;
      }
      .dialog-list-option {
        font-size: 14px;
      }
      .dialog-list-item {
        padding: 20px 10px;
        margin: 10px 0 10px 0;
        border-radius: 5px;
        border-bottom: solid 1px var(--accent);
        background: var(--bg-1-lit);
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        font-size: 14px;
      }
      .dialog-list-item mat-icon {
        cursor: pointer;
      }
      .page-set-name {
        padding-top: 0;
        border-bottom: 1px solid white !important;
      }
      .page-set-name.error {
        border-bottom: 1px solid red !important;
      }
      .dialog-form-error {
        padding: 0 15px 0 15px;
        color: red;
      }
      .create-cancel-buttons {
        margin-left: auto;
      }
      input,
      input::placeholder {
        color: white !important;
        padding-bottom: 4px;
      }
    `,
  ],
})
export class SignatureCreateContractDialogComponent {
  groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  fots: FotState[] = []
  contractForm = this.formBuilder.group({
    pageSetName: new FormControl('', [Validators.required]),
    selectedFots: new FormControl([], [Validators.required]),
  })

  constructor(
    public dialogRef: MatDialogRef<SignatureCreateContractDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: SignatureCreateContractDialogConfig,
    private readonly formBuilder: FormBuilder
  ) {
    this.setData(data)
  }

  allControlsTouched() {
    const pageSetName = 'pageSetName'
    const selectedFots = 'selectedFots'
    return (
      this.contractForm.controls[pageSetName].touched &&
      this.contractForm.controls[selectedFots].touched
    )
  }

  setData(data: SignatureCreateContractDialogConfig): void {
    /* dynamically update fot list as they are fetched */
    this.groupOrStructureFilterSelection = data.groupOrStructureFilterSelection
    if (data.fots) {
      this.fots = [...data.fots]
      this.fots.sort((a, b) =>
        this.getFotSelectorName(a).localeCompare(this.getFotSelectorName(b))
      )
      this.fots = this.fots.filter(
        le => !(le.layerName.startsWith('Section ') && le.layerType === 'Multi-Section')
      )
    }
  }

  getFotSelectorName(fot: FotState) {
    switch (this.groupOrStructureFilterSelection) {
      case 'Group':
        return `${fot.programGroupName}-${fot.layerName}-${fot.reinsurerPhaseLabel}`
      case 'SL':
        return `${fot.sharedLimitName}-${fot.layerName}-${fot.reinsurerPhaseLabel}`
      default:
        return `${fot.structureName}-${fot.layerName}-${fot.reinsurerPhaseLabel}`
    }
  }

  getDragAndDropData(): FotState[] {
    return this.contractForm.get('selectedFots')?.value ?? []
  }
  drop(event: CdkDragDrop<FotState[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    )
  }

  generateContract() {
    if (!this.contractForm.valid) {
      this.contractForm.markAllAsTouched()
      return
    }
    const submittedForm: {
      pageSetName?: string
      selectedFots?: FotState[]
    } = this.contractForm.value
    const contractToCreate = {
      pageSetName: submittedForm.pageSetName,
      fots: submittedForm.selectedFots,
    }
    this.contractForm.markAsUntouched()
    this.dialogRef.close(contractToCreate)
  }
}
