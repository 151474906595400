import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { NgModel } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { EditFolderNameData, NewFolderRequest } from '../tier.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-folder-dialog',
  templateUrl: `./new-folder-dialog.component.html`,
  styleUrls: [`./new-folder-dialog.component.scss`],
})
export class NewFolderDialogComponent implements OnInit, OnDestroy {
  @Input() selectedProgramID: string
  @Input() data?: EditFolderNameData
  folderName = ''
  folderID = ''
  actionText = 'Create Folder'
  folderNameModel: NgModel
  private inputChange = new Subject<string>()
  private destroy$ = new Subject<void>()

  @Output() createFolder = new EventEmitter<NewFolderRequest>()
  @Output() editFolder = new EventEmitter<EditFolderNameData>()

  constructor(public dialogRef: MatDialogRef<NewFolderDialogComponent>) {}

  ngOnInit(): void {
    this.inputChange
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(value => {
        this.folderName = value
      })
    if (this.data) {
      this.folderName = this.data.name
      this.folderID = this.data.folderID
      this.actionText = 'Rename Folder'
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  handleInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value
    this.inputChange.next(inputValue)
  }

  handleClick(): void {
    if (this.data) {
      const editFolderData = {
        name: this.folderName,
        folderID: this.folderID,
        programId: this.selectedProgramID,
      }
      this.editFolder.emit(editFolderData)
    } else {
      this.createFolder.emit({
        name: this.folderName,
        programId: this.selectedProgramID,
      })
    }
  }
}
