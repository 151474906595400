import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { CompareMetricSetting } from '../analysis/model/compare-metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-capital-metrics-custom',
  styles: [
    `
           .row {
             display: flex;
             flex-wrap: nowrap;
             flex-direction: row;
             justify-content: space-between;
             align-items: center;
             box-sizing: border-box;
             padding: var(--stack-tiny) var(--inset-small) var(--stack-tiny)
               var(--inset);
           }
           label {
             color: var(--body);
             font-weight: var(--font-weight);
           }
           input {
             width: 6rem;
             text-align: center;
             margin-left: var(--inset-big);
           }
           button {
             margin: 5px;
             padding: 6px 10px;
             float: right;
           }
           /* Remove browser default up/down buttons in number input field */
           input::-webkit-outer-spin-button,
           input::-webkit-inner-spin-button {
             /* display: none; <- Crashes Chrome on hover */
             -webkit-appearance: none;
             margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
           }
     
           input[type='number'] {
             -moz-appearance: textfield; /* Firefox */
           }
         `,
  ],
  template: `
    <div class="row" *ngFor="let i of spArray">
      <label>
        {{ i.title }}
      </label>
      <input
        appInput
        big
        class="weight"
        type="number"
        matTooltip="Enter a value to weight this metric in the heatmap index."
        matTooltipPosition="above"
        [ngModel]="i.value"
        (ngModelChange)="setRowValue($event, this.spArray.indexOf(i))"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        (change)="onValueChange(i)"
      />
    </div>
  `,
})
export class CapitalMetricsCustomComponent implements OnInit {
  spArray: { title: string; value: number }[]

  @Input() metrics: CompareMetricSetting
  @Input() spValues: { title: string; value: number }[]

  @Output() spPremiumValueChange = new EventEmitter<number>()
  @Output() spReserveValueChange = new EventEmitter<number>()
  @Output() spDivesificationValueChange = new EventEmitter<number>()
  @Output() spCatValueChange = new EventEmitter<number>()

  ngOnInit() {
    this.spArray = this.spValues.slice()
  }

  setRowValue(value: number, index: number) {
    if (value >= 0 && value <= 100) {
      this.spArray[index].value = value
    }
  }

  onValueChange(data: { title: string; value: number }) {
    if (
      data.title === 'S&P Premium risk factor' ||
      data.title === 'BCAR Premium risk factor'
    ) {
      this.spPremiumValueChange.emit(data.value)
    } else if (
      data.title === 'S&P Reserve risk factor' ||
      data.title === 'BCAR Reserve risk factor'
    ) {
      this.spReserveValueChange.emit(data.value)
    } else if (
      data.title === 'S&P Diversification factor' ||
      data.title === 'BCAR Diversification factor'
    ) {
      this.spDivesificationValueChange.emit(data.value)
    } else if (data.title === 'S&P Cat dilution factor') {
      this.spCatValueChange.emit(data.value)
    }
  }
}
