import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ExtendedScrollToOptions } from '@angular/cdk/scrolling'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Client } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import { WindowSize } from '../../../core/store/app.actions'
import { getClientStudyWithID } from '../../../core/store/clients.selectors'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { Size } from '@shared/size.mixin'
import { rejectNil } from '@shared/util/operators'
import {
  CompareMetricCategory,
  CompareMetricValue,
  GrossMetricsInput,
} from '../../model/compare-metrics.model'
import { PortfolioViewMetricsPayload } from '../../model/portfolio-metrics.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { selectCompareLayersList } from '../../store/analysis.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import * as CompareActions from '../../store/compare/compare.actions'
import { CompareEntity } from '../../store/compare/compare.reducer'
import { ProgramGroup } from '../../store/grouper/program-group.model'
import { updateAndFetch } from '../../store/metrics/portfolio-metrics.actions'
import { CompareMetricsSidebarComponent } from '../components/compare-metrics-sidebar/compare-metrics-sidebar.component'
import { CompareMetricsContainerComponent } from '../components/compare-metrics.container/compare-metrics.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-towers',
  styleUrls: ['./compare-towers.component.scss'],
  templateUrl: './compare-towers.component.html',
})
export class CompareTowersComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject()

  config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    navigation: true,
    observer: true,
    observeParents: true,
    touchEventsTarget: 'container',
    preventClicks: true,
    simulateTouch: false,
    pagination: false,
    watchOverflow: true,
    touchStartPreventDefault: false,
  }

  isAgg = false
  programsLayers: LayerState[][]
  allProgramsLayers: LayerState[][]

  @Input() precision: number
  @Input() entities: CompareEntity[]
  @Input() structureGroupIDs: string[]
  @Input() structuresByID: Dictionary<Program>
  @Input() structureGroupsByID: Dictionary<ProgramGroup>
  @Input() grossMetricsInput: GrossMetricsInput | null
  @Input() client: Client | null
  @Input() towerSizePercentage: number
  @Input() windowSize: WindowSize
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() grossSelected: boolean
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() expandedChangeMetrics: Record<string, boolean>

  @Input() set slideBG(value: any) {
    this._slideBG = coerceBooleanProperty(value)
  }
  get slideBG() {
    return this._slideBG
  }
  @HostBinding('class.slide-bg') _slideBG = true

  @Output() collapseToggle = new EventEmitter<string>()
  @Output() ragToggle = new EventEmitter<CompareMetricValue>()
  @Output() changeToggle = new EventEmitter<CompareMetricValue>()
  @Output() programRemove = new EventEmitter<Program | Program[]>()
  @Output() updateGrossSelection = new EventEmitter<boolean>()
  @Output() updateEntityIndex = new EventEmitter<string[]>()
  @Output() scenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<Program>
  >()
  @Output() groupScenarioOrOptimizationSelectChange = new EventEmitter<
    CheckboxSelectChangeEvent<ProgramGroup>
  >()

  @ViewChild(CompareMetricsSidebarComponent)
  sidebar: CompareMetricsSidebarComponent

  @ViewChildren(CompareMetricsContainerComponent)
  metricsContainers = new QueryList<CompareMetricsContainerComponent>()

  get slideStyle() {
    const p = this.towerSizePercentage * 100
    const width = `calc(${p}% - 2 * var(--inset-big))`
    return { width }
  }

  get slideStyleGross() {
    return { width: '160px' }
  }

  get width(): number {
    const w = this.windowSize.width - 64 - 200
    return w * this.towerSizePercentage - 12 - 24
  }

  get height(): number {
    return (this.windowSize.height - 204) * 0.2
  }

  get size(): Size {
    if (this.width < 148) {
      return 'mini'
    }
    if (this.width < 172) {
      return 'tiny'
    }
    if (this.width < 196) {
      return 'small'
    }
    if (this.width < 244) {
      return 'body'
    }
    if (this.width < 448) {
      return 'big'
    }
    return 'huge'
  }

  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  get rankMax(): number {
    return this.programsLayers.length
  }

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .pipe(takeUntil(this.destroy$), select(selectCompareLayersList))
      .subscribe(value => {
        const programsLayers = value.map(layerState => {
          return layerState.filter(
            l => !l.deleted && l.layer.meta_data.sage_layer_subtype !== 'actual'
          )
        })
        if (JSON.stringify(this.allProgramsLayers) !== JSON.stringify(value)) {
          this.allProgramsLayers = value
          this.programsLayers = programsLayers
          this.cdRef.markForCheck()
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.entities || changes.grossSelected) && this.sidebar) {
      this.onScrollChange({ top: 0 })
    }
  }

  programTypeEmpty(index: number): boolean {
    if (this.entities[index].program.programType === '') {
      return true
    }
    return false
  }

  getName(index: number): string {
    const entity = this.entities[index]
    if (!entity || !this.client) {
      return ''
    }
    // const program = entity.program
    // const study = getClientStudyWithID(program.studyID, this.client)
    // if (entity.program.programType === '') {
    //   return program.label
    // }
    return entity.program.label
  }

  getProgramName(index: number): string {
    const entity = this.entities[index]
    if (!entity || !this.client) {
      return ''
    }
    const program = entity.program
    const study = getClientStudyWithID(program.studyID, this.client)
    return study ? `${study.name}` : ''
  }

  getImageSrc(index: number): string {
    const entity = this.entities[index]
    return entity.program.imageSrc ?? ''
  }

  getLastModified(index: number): string {
    const entity = this.entities[index]
    return entity.program.lastModified ?? ''
  }

  getPrograms(index: number): string[] {
    const entity = this.entities[index]
    // tslint:disable-next-line:no-non-null-assertion
    return entity.program.imageSrcGroup!
  }

  getAggImageSrc(index: number): string | undefined {
    const entity = this.entities[index]
    let aggImage
    if (entity.program.imageSrc) {
      // @ts-ignore
      // tslint:disable-next-line:no-non-null-assertion
      aggImage = entity.program.imageSrc!.replace('occurrence', 'aggregate')
    }
    return aggImage
  }

  getScenarios(index: number): (Program | ProgramGroup)[] {
    const structure = this.entities[index].program
    const scenarioIDs = structure.scenarioIDs ?? []
    return this.structureGroupIDs?.includes(structure.id)
      ? rejectNil(scenarioIDs.map(id => this.structureGroupsByID[id]))
      : rejectNil(scenarioIDs.map(id => this.structuresByID[id]))
  }

  getOptimizations(index: number): (Program | ProgramGroup)[] {
    const structure = this.entities[index].program
    const optimizationIDs = structure.optimizationIDs ?? []
    return this.structureGroupIDs?.includes(structure.id)
      ? rejectNil(optimizationIDs.map(id => this.structureGroupsByID[id]))
      : rejectNil(optimizationIDs.map(id => this.structuresByID[id]))
  }

  getScenariosSelected(index: number): Record<string, boolean> {
    const scenarioIDs = this.entities[index].program.scenarioIDs ?? []
    const selectedIDs = this.entities.map(e => e.program.id)
    return scenarioIDs.reduce(
      (acc, id) => ({ ...acc, [id]: selectedIDs.includes(id) }),
      {} as Record<string, boolean>
    )
  }

  getOptimizationsSelected(index: number): Record<string, boolean> {
    const optimizationIDs = this.entities[index].program.optimizationIDs ?? []
    const selectedIDs = this.entities.map(e => e.program.id)
    return optimizationIDs.reduce(
      (acc, id) => ({ ...acc, [id]: selectedIDs.includes(id) }),
      {} as Record<string, boolean>
    )
  }

  showAgg(index: number): boolean {
    const res = this.entities[index].program.checkCompare
    if (res !== undefined) {
      return res
    }
    return true
  }

  getSelected(index: number): string {
    const entity = this.entities[index]
    return (entity && entity.program.groupSelected) || ''
  }

  setSelected(id: string, index: number) {
    const program = this.entities[index].program
    if (program) {
      this.store.dispatch(
        CompareActions.setSelectedProgramFromCompare({
          id: program.id,
          selected: id,
          program,
        })
      )
    }
  }

  getMetricCategories(index: number): CompareMetricCategory[] {
    return (
      (this.entities[index] &&
        this.entities[index].metricCategories &&
        this.entities[index].metricCategories.filter(
          m => m.category !== 'Gross Metrics'
        )) ||
      []
    )
  }

  getPortfolioSetIDWithProgram(program: Program): PortfolioSetID {
    const portfolioSetID = extractPortfolioSetID({
      ...program,
      analysisProfileID: program.analysisID,
    })
    if (!portfolioSetID) {
      throw Error(
        `Cannot get portfolio set ID for compare program ${program.id}`
      )
    }
    return portfolioSetID
  }

  getPortfolioSetID(index: number): PortfolioSetID {
    const program = this.entities[index].program
    return this.getPortfolioSetIDWithProgram(program)
  }

  getMetricsWeight(index: number): number {
    const categories = this.entities[index].metricCategories.filter(
      metric => metric.category !== 'Gross Metrics'
    )
    const total = categories.reduce(
      (weight: number, category) =>
        category.metrics.reduce(
          (innerWeight: number, metric) =>
            // tslint:disable-next-line: no-non-null-assertion
            innerWeight + metric[0].rank! * (metric[0].weight / 100),
          weight
        ),
      0
    )
    return Math.round(total * 100) / 100
  }

  setSimulateTouch(value: boolean) {
    this.config.simulateTouch = value
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.entities, event.previousIndex, event.currentIndex)
    const indexArray = this.entities.map(id => id.program.id)
    this.updateEntityIndex.emit(indexArray)
  }

  onReturnPeriodChange(change: Partial<PortfolioViewMetricsPayload>) {
    this.entities
      .map(e => this.getPortfolioSetIDWithProgram(e.program))
      .forEach(id => this.store.dispatch(updateAndFetch({ ...id, change })))
  }

  onScrollChange(opts: ExtendedScrollToOptions) {
    this.sidebar.scrollTo(opts)
    this.metricsContainers.forEach(mc => mc.scrollTo(opts))
  }

  onShowAggChange(checked: boolean, index: number) {
    this.isAgg = !this.isAgg
    const program = this.entities[index].program
    if (program) {
      this.store.dispatch(
        CompareActions.setProgramFromCompare({
          id: program.id,
          check: checked,
          program,
        })
      )
    }
  }

  onScenarioOrOptimizationSelectChange(
    index: number,
    $event: CheckboxSelectChangeEvent<Program | ProgramGroup>
  ) {
    const structure = this.entities[index].program
    return this.structureGroupIDs?.includes(structure.id)
      ? this.groupScenarioOrOptimizationSelectChange.emit(
          $event as CheckboxSelectChangeEvent<ProgramGroup>
        )
      : this.scenarioOrOptimizationSelectChange.emit(
          $event as CheckboxSelectChangeEvent<Program>
        )
  }

  trackByID(index: number, entity: CompareEntity) {
    return (entity && entity.program && entity.program.id) || index
  }
}
