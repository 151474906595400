export const excludeProperties = [
  'name',
  'programType',
  'layerType',
  'cascadeLowerLayerID',
  'reinstatements',
  'topAndDropProperties',
  'multiSections',
  'technicalPremium',
  'premium',
]
