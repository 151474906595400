import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fhcf-warning',
  templateUrl: './fhcf-warning-dialog.component.html',
})
export class FHCFWarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FHCFWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FHCFWarningDialogComponent
  ) {}

  cancel(): void {
    this.dialogRef.close()
  }
}
