import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ReinsurerState } from '../../store/reinsurer/reinsurer.reducer'
import { QuoteCustomCompareView, Section } from '../../models/quote.model'
import { SectionState } from '../../store/section/section.reducer'
import {
  QuoteExportDefaultSelections,
  QuoteExportDialogPayload,
  QuoteExportSectionView,
  QuoteExportTermItem,
  QuoteExportTermView,
} from '../../export/quote-excel.model'
import { includes, path, pickBy, uniq, uniqBy, view } from 'ramda'
import {
  LAYER_PALETTE,
  layerIds,
} from 'src/app/analysis/model/layer-palette.model'
import getQuotePanelDefs, {
  QUOTE_PANEL_DEFS,
} from '../../quote-panel/quote-panel-defs'
import layerMetricDefs from 'src/app/analysis/model/layer-metric-defs'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import {
  filterTowerLayers,
  isLayerAggFeeder,
  isLayerDrop,
  isLayerTopOrDrop,
} from 'src/app/analysis/model/layers.util'
import { LayerView, LayerViewValues } from 'src/app/analysis/model/layer-view'

export function prepareSectionViewsForExport(
  layers: LayerState[],
  sections: SectionState[],
  reinsurers: ReinsurerState[],
  compareViews: QuoteCustomCompareView[]
): QuoteExportSectionView[] {
  const filteredLayers = layers.filter(
    l =>
      filterTowerLayers(l) &&
      !isLayerAggFeeder(l.layer) &&
      !isLayerDrop(l.layer)
  )
  return filteredLayers.map(({ layer }) => {
    const section = sections.find(
      ({ section }) => section.layerRef === layer.id
    ).section
    const mainLayer = layers.find(
      layerState =>
        layerState.layer.meta_data.visible_layer_id === layer.id ||
        layerState.layer.layerRefs.includes(layer.id)
    )
    let childSections =
      mainLayer?.layer.layerRefs
        .map(layerRef =>
          sections.find(
            sectionState => sectionState.section.layerRef === layerRef
          )
        )
        ?.filter(val => !!val)
        .map(sectionState => sectionState.section) ?? []

    if (!childSections.length) {
      if (isLayerTopOrDrop(layer)) {
        const dropLayer = layers.find(
          layerState => layerState.layer.meta_data.topID === layer.id
        )
        childSections = [
          sections.find(
            sectionState => sectionState.section.layerRef === dropLayer.layer.id
          ).section,
        ]
      }
    }

    const mainLayerSection = sections.find(
      ({ section }) => section.layerRef === mainLayer?.layer.id
    )?.section

    const usedSection =
      childSections.includes(section) && mainLayerSection
        ? mainLayerSection
        : section

    return {
      id: usedSection.id,
      section: usedSection,
      childSections: childSections.filter(
        childSection => childSection.id !== usedSection.id
      ),
      reinsurers: reinsurers.filter(
        ({ reinsurer }) =>
          (reinsurer.cededlayerID === usedSection.layerRef ||
            childSections.some(
              childSection => reinsurer.cededlayerID === childSection.layerRef
            )) &&
          reinsurer.quoteReinsurerName &&
          !reinsurer.decline &&
          reinsurer.exportToggle === true
      ),
      compareViews: compareViews.filter(
        view => view.riskSectionId === Number(usedSection.id)
      ),
      sectionSelected: true,
      selectedCompareViewIds: [] as number[],
    }
  })
}

@Component({
  selector: 'app-quote-export-dialog-component',
  templateUrl: './quote-export-dialog.component.html',
  styleUrls: ['./quote-export-dialog.component.scss'],
})
export class QuoteExportDialogComponent implements OnInit {
  @Input() reinsurers: ReinsurerState[]
  @Input() compareViews: QuoteCustomCompareView[]
  @Input() sections: SectionState[]
  @Input() layers: LayerState[]
  @Output() closeDialog = new EventEmitter<QuoteExportDialogPayload>()

  exportSectionViews: QuoteExportSectionView[] = []
  exportTermViews: QuoteExportTermView[] = []

  get isDialogValid(): boolean {
    return (
      this.exportSectionViews.some(this.isSectionViewValid) &&
      this.exportTermViews.every(this.isTermViewValid)
    )
  }

  get disabledTooltip(): string {
    if (!this.exportSectionViews.some(this.isSectionViewValid)) {
      return 'At least one standard or custom view must be selected'
    } else if (!this.exportTermViews.every(this.isTermViewValid)) {
      return 'One or more layer types have invalid term selections'
    }
  }

  isSectionViewValid(view: QuoteExportSectionView): boolean {
    return view.sectionSelected || !!view.selectedCompareViewIds.length
  }

  isTermViewValid(view: QuoteExportTermView): boolean {
    return view.terms.some(term => term.active)
  }

  ngOnInit(): void {
    this.exportSectionViews = prepareSectionViewsForExport(
      this.layers,
      this.sections,
      this.reinsurers,
      this.compareViews
    )

    const layerTypes: layerIds[] = uniq(
      this.exportSectionViews.map(
        ({ section }) => section.layerType as layerIds
      )
    )

    const termsByLayerType: Record<string, QuoteExportTermItem[]> = uniqBy(
      path(['section', 'layerType']),
      this.exportSectionViews
    ).reduce((acc, { section }) => {
      const layer = this.layers.find(
        ({ layer }) => layer.id === section.layerRef
      )
      const layerView = new LayerView(this.layers, layer.layer)
      const panelDefs = getQuotePanelDefs(
        layerView.values,
        false,
        'Both ECO & XPL apply',
        false
      )

      const terms: QuoteExportTermItem[] = panelDefs
        .map(val => ({
          active: true,
          id: val.id,
          quoteExportId: val.exportID,
          label: val.label,
        }))
        .filter(termEntry =>
          Object.entries(section).some(
            ([key, value]) => termEntry.quoteExportId === key && !!value
          )
        )

      acc[section.layerType] = terms
      return acc
    }, {})

    this.exportTermViews = layerTypes.map(layerType => {
      const paletteItem = LAYER_PALETTE.find(({ id }) => id === layerType)
      const defaultTermSelections = QuoteExportDefaultSelections[layerType]
      const terms = termsByLayerType[layerType]

      const updatedTerms: QuoteExportTermItem[] = terms.map(term => ({
        ...term,
        active: defaultTermSelections
          ? defaultTermSelections.includes(term.id as keyof LayerViewValues)
          : true,
      }))

      const viewForLayerType = this.exportSectionViews.find(
        sectionView =>
          sectionView.section.layerType === layerType &&
          !!sectionView.childSections.length
      )

      const newLayerTypes = [layerType]
      if (viewForLayerType) {
        const childLayerTypes = uniq(
          viewForLayerType.childSections.map(
            childSection => childSection.layerType as layerIds
          )
        )
        newLayerTypes.push(...childLayerTypes)
      }

      return {
        layerTypes: newLayerTypes,
        paletteItem,
        terms: updatedTerms,
      }
    })
  }

  isToggleAllSelected(index: number): boolean {
    return this.exportTermViews[index].terms.every(term => term.active)
  }

  isToggleAllIndeterminate(index: number): boolean {
    return (
      this.exportTermViews[index].terms.some(term => term.active) &&
      !this.isToggleAllSelected(index)
    )
  }

  toggleAll(index: number, checked: boolean): void {
    const view = this.exportTermViews[index]
    this.exportTermViews[index] = {
      ...view,
      terms: view.terms.map(term => ({
        ...term,
        active: checked,
      })),
    }
  }

  toggleTerm(termViewIndex: number, termIndex: number, checked: boolean): void {
    const view = { ...this.exportTermViews[termViewIndex] }
    const terms = [...view.terms]
    terms.splice(termIndex, 1, {
      ...terms[termIndex],
      active: checked,
    })
    this.exportTermViews[termViewIndex] = {
      ...view,
      terms,
    }
  }

  toggleViewSettings(
    viewIndex: number,
    checked: boolean,
    listIndex?: number,
    isStandard?: boolean
  ): void {
    const view = this.exportSectionViews[viewIndex]
    if (isStandard) {
      this.exportSectionViews[viewIndex] = {
        ...view,
        sectionSelected: checked,
        selectedCompareViewIds: [],
      }
    } else {
      const newCompareViews = [...view.selectedCompareViewIds]
      if (!checked) {
        const viewId = view.compareViews[listIndex].id
        const selectionIndex = newCompareViews.indexOf(viewId)
        newCompareViews.splice(selectionIndex, 1)
      } else {
        newCompareViews.push(view.compareViews[listIndex].id)
      }
      this.exportSectionViews[viewIndex] = {
        ...view,
        sectionSelected: checked ? false : view.sectionSelected,
        selectedCompareViewIds: newCompareViews,
      }
    }
  }

  submitDialog(): void {
    if (this.isDialogValid) {
      const sectionViews = this.exportSectionViews.map(sectionView => ({
        ...sectionView,
        reinsurers: sectionView.sectionSelected
          ? sectionView.reinsurers
          : sectionView.reinsurers.filter(({ reinsurer }) => {
              const selectedCompareViews = sectionView.compareViews.filter(
                ({ id }) => sectionView.selectedCompareViewIds.includes(id)
              )
              return selectedCompareViews.some(compareView =>
                compareView.members.includes(Number(reinsurer.id))
              )
            }),
      }))
      this.closeDialog.emit({
        sectionViews,
        termViews: this.exportTermViews,
      })
    }
  }
}
