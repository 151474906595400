import createEntityStoreSet, {
  as,
} from '../../core/model/create-entity-store-set'
import {
  BenchmarkLob,
  BenchmarkUiSettings
} from '../model/benchmark.model'

const benchmarkEntityStoreSet = createEntityStoreSet('Benchmark', {
  lobs: as<BenchmarkLob>(),
  uiSettings: as<BenchmarkUiSettings>(),
})

export default benchmarkEntityStoreSet
