import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReinsurers from './study-reinsurers.reducer'
import * as fromBrokerSelectors from '../../core/store/broker/broker.selectors'
import {
  createAmBestRatingFilterList,
  createDomicileFilterList,
  createSPRatingFilterList,
} from './create-study-reinsurers-filter-lists'
import { selectSLCurrentStudyID } from '../../analysis/store/analysis.selectors'

export const selectReinsurersState =
  createFeatureSelector<fromReinsurers.State>(fromReinsurers.FEATURE_KEY)

export const { selectAll: selectAllStudiesReinsurerState } =
  fromReinsurers.adapter.getSelectors(selectReinsurersState)

export const selectStudyReinsurersWithID = createSelector(
  selectReinsurersState,
  (state: fromReinsurers.State, props: { id: string }) => {
    const entity = state.entities[props.id]
    if (entity) {
      return entity.reinsurers
    }
    return null
  }
)

export const selectStudyReinsurerWithID = createSelector(
  selectReinsurersState,
  (
    state: fromReinsurers.State,
    props: { studyID: string; reinsurerID: number }
  ) => {
    const entity = state.entities[props.studyID]
    if (entity) {
      const reinsurer = entity.reinsurers.find(r => r.id === props.reinsurerID)
      return reinsurer
    }
    return null
  }
)

export const selectSelectedStudyReinsurers = createSelector(
  selectReinsurersState,
  fromBrokerSelectors.selectCurrentStudyID,
  (state, selectedStudyID) => {
    if (selectedStudyID) {
      const entity = state.entities[selectedStudyID]
      if (entity) {
        return entity.reinsurers.filter(re => !re.is_default)
      }
    }
    return null
  }
)

export const selectReinsurerDomicileList = createSelector(
  selectSelectedStudyReinsurers,
  createDomicileFilterList
)

export const selectReinsurerSPRatingList = createSelector(
  selectSelectedStudyReinsurers,
  createSPRatingFilterList
)

export const selectReinsurerAmBestRatingList = createSelector(
  selectSelectedStudyReinsurers,
  createAmBestRatingFilterList
)

export const selectStudyReinsurersSaving = createSelector(
  selectReinsurersState,
  state => state.saving
)

export const selectStudyReinsurerDirty = createSelector(
  selectReinsurersState,
  state => state.dirty
)

export const selectStudyReinsurerPopulateFrom = createSelector(
  selectReinsurersState,
  state => state.populateFrom
)

export const selectCurrentStudyReinsurers = createSelector(
  selectReinsurersState,
  fromBrokerSelectors.selectCurrentStudyID,
  (state, id) => {
    const entity = id && state.entities[id]
    return entity ? entity.reinsurers : []
  }
)

export const selectCurrentStudySLReinsurers = createSelector(
  selectReinsurersState,
  selectSLCurrentStudyID,
  (state, id) => {
    const entity = id && state.entities[id]
    return entity ? entity.reinsurers : []
  }
)

export const selectStudyReinsurerFilters = createSelector(
  selectReinsurersState,
  state => state.reinsurerFilters
)
