<div class="header">
  <label>
    <span>{{ displayLabel }}</span>
    <button
      appButtonIcon
      big
      opaque
      (click)="onCollapseToggle($event)"
      matTooltip="Click the arrow to show or hide for display purposes on this page."
      matTooltipPosition="right"
    >
      {{ headerIcon }}
    </button>
  </label>
</div>
<ng-container *ngIf="!collapsed">
  <ng-container *ngFor="let rowValues of values; trackBy: trackByIDs">
    <div
      *ngIf="rowValues[0].show"
      class="row"
      [ngClass]="{
            'single-item-row': !short && rowValues.length === 1,
            'row-show-change':
              isChangeExpanded(rowValues[0]) &&
              !short &&
              rowValues.length === 1,
            short: short,
            'short-show-change': isChangeExpanded(rowValues[0]) && short
          }"
      [ngStyle]="valueStyle"
    >
      <div class="label-and-menu">
        <mat-icon
          class="warning-icon"
          *ngIf="rowValues[0].validFormula === false"
          matTooltip="The formula for this metric is no longer valid. One of the component metrics has changed."
          matTooltipPosition="above"
        >
          error
        </mat-icon>
        <label [matTooltip]="asTooltip(getFirstLabel(rowValues))">
          {{ getFirstLabel(rowValues) }}
        </label>

        <div
          *ngIf="showMoreMenu && entitiesLength !== 1"
          class="label-buttons"
        >
          <button
            appButtonIcon
            big
            class="icon"
            [matMenuTriggerFor]="moreMenu"
            matTooltip="Click to customize the view."
            matTooltipPosition="right"
          >
            more_horiz
          </button>
          <mat-menu #moreMenu="matMenu">
            <button
              mat-menu-item
              (click)="onRAGToggle($event, rowValues[0])"
            >
              {{ showRAGToggleLabel(rowValues[0]) }}
            </button>

            <button
              mat-menu-item
              (click)="onChangeToggle($event, rowValues[0])"
            >
              {{ showChangeToggleLabel(rowValues[0]) }}
            </button>
          </mat-menu>
        </div>
      </div>

      <div *ngIf="isChangeExpanded(rowValues[0])" class="change-from-first">
            <span class="material-icons small-font">
              subdirectory_arrow_right
            </span>
        <label class="small-font">
          Change From {{ firstProgramLabel }}
        </label>
      </div>
    </div>
  </ng-container>
</ng-container>
