import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './analysis-panels.actions'

export interface State {
  portfolioMetricsOpen: boolean
  layersOpen: boolean
  propertiesOpen: boolean
  lossSetGroupEditor: boolean
  collapse: Record<fromActions.AnalysisPanelsSection, boolean>
}

export const initialState: State = {
  portfolioMetricsOpen: true,
  layersOpen: true,
  propertiesOpen: true,
  lossSetGroupEditor: false,
  collapse: {
    lossSets: false,
    scenarios: false,
    optimizations: false,
    inurance: false,
    layerTypes: false,
  },
}

const analysisPanelsReducer = createReducer(
  initialState,

  on(fromActions.togglePortfolioMetrics, state => ({
    ...state,
    portfolioMetricsOpen: !state.portfolioMetricsOpen,
  })),

  on(fromActions.toggleLayersPanel, state => ({
    ...state,
    layersOpen: !state.layersOpen,
  })),

  on(fromActions.togglePropertiesPanel, state => ({
    ...state,
    propertiesOpen: !state.propertiesOpen,
  })),

  on(fromActions.toggleLossSetGroupEditor, (state, { openPanel }) => ({
    ...state,
    lossSetGroupEditor: openPanel,
  })),

  on(
    fromActions.setAnalysisPanelsSectionCollapse,
    (state, { section, collapse }) => ({
      ...state,
      collapse: { ...state.collapse, [section]: collapse },
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return analysisPanelsReducer(state, action)
}
