import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReducer from './pricing-curve.reducer'
import { PricingCurveState } from './pricing-curve.reducer'
import {
  PricingCurveData,
  PricingCurveGraphSettings,
  SELECTORS_INITIAL_STATE,
  SavedPricingCurveEntry,
  SelectedTransaction,
  TECHNICAL_FACTORS_DEFAULT_STATE,
} from '../model/pricing-curve.model'
import {
  filterSelectorsForLayerRequest,
  getColorClass,
  onlyUnique,
} from '../pricing-curve.utils'
import { GLOBAL_DEFAULT_CURVE_ENTRY } from 'src/app/analysis/technical-premium/technical-premium.model'
import { PricingCurve } from '../model/pricing-curve'

const mapCurveDataToPricingCurve = (
  curveData: PricingCurveData,
  graphSettings: PricingCurveGraphSettings,
  selectedTransaction: SelectedTransaction | null
) => new PricingCurve(curveData, graphSettings, selectedTransaction)

export const selectPricingCurveState =
  createFeatureSelector<fromReducer.PricingCurveState>(
    fromReducer.PRICING_CURVE_FEATURE_KEY
  )

export const selectAddDialogDetails = createSelector(
  selectPricingCurveState,
  state => state.addDataDialog
)

export const selectPricingCurvesData = createSelector(
  selectPricingCurveState,
  state => state.pricingCurves
)

export const selectPricingCurveGraphSettings = createSelector(
  selectPricingCurveState,
  state => state.graphSettings
)

export const selectSelectedCreditTransaction = createSelector(
  selectPricingCurveState,
  (state: PricingCurveState) => state.selectedTransaction
)

export const selectPricingCurves = createSelector(
  selectPricingCurvesData,
  selectPricingCurveGraphSettings,
  selectSelectedCreditTransaction,
  (curveDatas, graphSettings, selectedTransaction) =>
    curveDatas.map(curveData =>
      mapCurveDataToPricingCurve(curveData, graphSettings, selectedTransaction)
    )
)

export const selectWorkingPricingCurveData = createSelector(
  selectPricingCurveState,
  state => state.workingCurve
)

export const selectWorkingDateIntervals = createSelector(
  selectWorkingPricingCurveData,
  state => state?.dateIntervals ?? []
)

export const selectWorkingCombinedSelectors = createSelector(
  selectWorkingPricingCurveData,
  state => ({
    dateIntervals: state.dateIntervals ?? [],
    selectors: state.selectors ?? SELECTORS_INITIAL_STATE,
  })
)

export const selectWorkingPricingCurve = createSelector(
  selectWorkingPricingCurveData,
  selectPricingCurveGraphSettings,
  selectSelectedCreditTransaction,
  (workingCurveData, graphSettings, selectedTransaction) =>
    mapCurveDataToPricingCurve(workingCurveData, graphSettings, selectedTransaction)
)

export const selectSavedCurves = createSelector(
  selectPricingCurveState,
  state => state.savedCurves
)

export const selectSavedCurvesIncludingDefault = createSelector(
  selectPricingCurveState,
  state => [...state.savedCurves, GLOBAL_DEFAULT_CURVE_ENTRY]
)

export const selectSavedCurvesNameMap = createSelector(
  selectSavedCurves,
  savedCurves =>
    savedCurves.map(curve => ({ id: curve.id, name: curve.pc_name }))
)

export const selectSavedCurvesExcludingAdded = createSelector(
  selectPricingCurveState,
  state =>
    state.savedCurves.filter(
      curve =>
        !state.pricingCurves.map(c => c.id).includes(curve.id) &&
        !state.pricingCurves.map(c => c.label).includes(curve.pc_name)
    )
)

export const selectPricingCurveContext = createSelector(
  selectPricingCurveState,
  state => state.context
)

export const selectSavedCurveSelectors = createSelector(
  selectPricingCurveState,
  state => state.savedCurveSelectors
)

export const selectPricingCurveStatus = createSelector(
  selectPricingCurveState,
  state => state.status
)

export const selectPreviouslyClickedId = createSelector(
  selectAddDialogDetails,
  addDataDialog => addDataDialog.previouslyClickedId
)

export const selectDialogSavedCurves = createSelector(
  selectAddDialogDetails,
  addDataDialog => addDataDialog.savedCurves
)

export const selectPreviouslyClickedDataSet = createSelector(
  selectPricingCurveState,
  (state: PricingCurveState) => {
    if (state.addDataDialog.previouslyClickedId) {
      const dataSetId = state.addDataDialog.previouslyClickedId.pricingCurveId
      return {
        curve: state.pricingCurves.find(set => set.id === dataSetId),
      }
    }
    return {
      curve: undefined,
    }
  }
)

export const selectNextLocalGraphId = createSelector(
  selectPricingCurves,
  (curves: PricingCurve[]) => {
    const totalDatasets = curves.length
    const savedDatasets = curves.filter(item => item.isSaved).length
    return -1 * (1 + (totalDatasets - savedDatasets))
  }
)

export const selectSavedCurveCreatorNameMap = createSelector(
  selectSavedCurves,
  (savedCurves: SavedPricingCurveEntry[]) => {
    let entries: Record<string, string> = {}
    savedCurves.forEach(curve => {
      entries = {
        ...entries,
        [curve.fullName]: curve.audit_updated_by,
      }
    })
    return entries
  }
)

export const selectCurrentDataSetNames = createSelector(
  selectPricingCurves,
  (curves: PricingCurve[]) => {
    return curves.map(curve => curve.label).filter(onlyUnique)
  }
)

export const selectNextGraphColor = createSelector(
  selectPricingCurves,
  (curves: PricingCurve[]) => {
    const usedColors = curves
      .map(set => set.graphColorClass.split('-')[2])
      .filter(onlyUnique)
    return getColorClass(curves.length, usedColors)
  }
)
export const selectPricingCurvesByIds = (ids: number[]) =>
  createSelector(selectPricingCurves, (curves: PricingCurve[]) =>
    curves.filter(({ id }) => ids.includes(id))
  )

export const selectFilters = createSelector(
  selectPricingCurveState,
  (state: PricingCurveState) => {
    if (!state.workingCurve.selectors) {
      return []
    }

    return filterSelectorsForLayerRequest(state.workingCurve.selectors)
  }
)


