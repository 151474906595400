import { createSelector } from '@ngrx/store'
import { flatten } from 'ramda'
import { AppState } from '../index'
import { selectClientsByID } from '../clients.selectors'
import { TierPath } from '../../../tier/tier.model'
import { Client, ClientYear } from '../../model/client.model'
import { Study } from '../../model/study.model'

export const selectPortfolioState = (state: AppState) => state.broker
export const selectAnalysisState = (state: AppState) => state.analysis

export const selectDesignProgramIDS = createSelector(
  selectPortfolioState,
  state => state.checkedProgram
)

export const selectCurrentClientID = createSelector(
  selectPortfolioState,
  state => state.broker.currentClientID
)

export const selectCurrentClientID2 = createSelector(
  selectPortfolioState,
  state => state.broker.currentClientID2
)

export const selectCurrentYearID = createSelector(
  selectPortfolioState,
  state => state.broker.currentYearID
)

export const selectCurrentYearID2 = createSelector(
  selectPortfolioState,
  state => state.broker.currentYearID2
)

export const selectCurrentStudyID = createSelector(
  selectPortfolioState,
  state => state.broker.currentStudyID
)

export const selectCurrentStudyID2 = createSelector(
  selectPortfolioState,
  state => state.broker.currentStudyID2
)

export const selectCurrentAnalysisProfile = createSelector(
  selectPortfolioState,
  state => state.broker.currentAnalysisProfile
)

export const selectCurrentExchangeRateProfile = createSelector(
  selectCurrentAnalysisProfile,
  state => state?.exchange_rate_profile
)

export const selectCurrentExchangeRateTable = createSelector(
  selectCurrentExchangeRateProfile,
  state => state?.exchange_rate_table
)

export const selectCurrentExchangeRateCurrency = createSelector(
  selectCurrentExchangeRateTable,
  state => state?.base_currency
)

export const selectCurrentStructureID = createSelector(
  selectPortfolioState,
  state => state.broker.currentStructureID
)

export const selectStudyFolders = createSelector(
  selectPortfolioState,
  state => state.broker.folders
)

export const selectTierPath = createSelector(
  selectCurrentClientID,
  selectCurrentYearID,
  selectCurrentStudyID,
  selectCurrentStructureID,
  (clientID, yearID, programID, structureID): TierPath => ({
    client: clientID,
    year: yearID,
    program: programID,
    structure: structureID,
  })
)
export const selectTierPath2 = createSelector(
  selectCurrentClientID2,
  selectCurrentYearID2,
  selectCurrentStudyID2,
  selectCurrentStructureID,
  (clientID2, yearID2, programID2, structureID): TierPath => ({
    client: clientID2,
    year: yearID2,
    program: programID2,
    structure: structureID,
  })
)

export const selectCurrentClient = createSelector(
  selectCurrentClientID,
  selectClientsByID,
  (id, clients) => (id && clients[id]) || null
)

export const selectCurrentClient2 = createSelector(
  selectCurrentClientID2,
  selectClientsByID,
  (id, clients) => (id && clients[id]) || null
)

export const selectCurrentYear = createSelector(
  selectCurrentYearID,
  selectCurrentClient,
  (
    yearID: string | null | undefined,
    client: Client | null | undefined
  ): ClientYear | undefined =>
    (client && yearID && client.clientYears.find(y => y.id === yearID)) ||
    undefined
)

export const selectCurrentYear2 = createSelector(
  selectCurrentYearID2,
  selectCurrentClient2,
  (yearID: string, client: Client | null | undefined): ClientYear | undefined =>
    (client && yearID && client.clientYears.find(y => y.id === yearID)) ||
    undefined
)

export const selectCurrentStudy = createSelector(
  selectCurrentStudyID,
  selectCurrentYear,
  (
    programID: string | null | undefined,
    year: ClientYear | null | undefined
  ): Study | undefined =>
    (year && programID && year.studies.find(s => s.id === programID)) ||
    undefined
)
export const selectCurrentStudy2 = createSelector(
  selectCurrentStudyID2,
  selectCurrentYear2,
  (
    programID: string | null | undefined,
    year: ClientYear | null | undefined
  ): Study | undefined =>
    (year && programID && year.studies.find(s => s.id === programID)) ||
    undefined
)
export const selectCurrentClientYears = createSelector(
  selectCurrentClient,
  client => (client && client.clientYears) || []
)

export const selectCurrentClientYears2 = createSelector(
  selectCurrentClient2,
  client => (client && client.clientYears) || []
)

export const selectCurrentYearStudies = createSelector(
  selectCurrentYear,
  year => (year && year.studies) || []
)

export const selectCurrentYearStudies2 = createSelector(
  selectCurrentYear2,
  year => (year && year.studies) || []
)

export const selectCurrentClientStudies = createSelector(
  selectCurrentClientYears,
  years => flatten(years.map(y => y.studies))
)

export const selectCurrentStudyProgramIDs = createSelector(
  selectCurrentStudy,
  study => (study ? study.programIDs : [])
)
export const selectCurrentStudyProgramIDs2 = createSelector(
  selectCurrentStudy2,
  study => (study ? study.programIDs : [])
)
export const selectCurrentLossFilters = createSelector(
  selectCurrentAnalysisProfile,
  state => state?.loss_filters
)

export const selectStructureFilter = createSelector(
  selectPortfolioState,
  s => s.broker.structureFilter
)

export const selectGroupFilterByAssoc = createSelector(
  selectPortfolioState,
  s => s.broker.groupFilterByAssoc
)

export const selectAnalysisEditor = createSelector(
  selectAnalysisState,
  s => s.editor
)

export const selectCurrencyList = createSelector(
  selectPortfolioState,
  s => s.broker.currencies
)

export const selectCurrencyRates = createSelector(
  selectPortfolioState,
  s => s.broker.currencyRates
)
