import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../../../nav.service'
import { TierNames } from '../../../../tier/tier.model'
import { ProgramGroup } from '../../../store/grouper/program-group.model'
import { Client } from '../../../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-animated-scenarios-header',
  styleUrls: ['./group-animated-header.component.scss'],
  templateUrl: './group-animated-header.component.html',
})
export class GroupAnimatedScenariosHeaderComponent {
  @Input() structureGroup: ProgramGroup | null
  @Input() currentClient: Client | null
  @Input() recording: boolean
  @Input() disableReanimate: boolean
  @Input() disableProcessEvents: boolean
  @Input() executing: boolean

  @Output() stopRecord = new EventEmitter()
  @Output() record = new EventEmitter()
  @Output() reanimate = new EventEmitter()
  @Output() processEvents = new EventEmitter()

  constructor(public navService: NavService, private router: Router) {}

  get title(): string {
    return 'Group Animated Scenarios'
  }

  get tierNames(): TierNames | undefined {
    if (this.structureGroup && this.currentClient) {
      return {
        client: this.currentClient.name,
        year: this.structureGroup.label,
      }
    } else {
      return undefined
    }
  }

  onBackClick(): boolean {
    this.router.navigate(['/analysis/group'])
    return false
  }

  getSubtitle(): string {
    return ''
  }
}
