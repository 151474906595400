import { createAction, props } from '@ngrx/store'
import { LayerDetailsExportXlsx } from '../../layer-details/layer-details.model'

export const exportAsXlsx = createAction(
  '[Layer Details] Export As Xlsx',
  props<LayerDetailsExportXlsx>()
)

export const addNewLayer = createAction(
  '[Layer Details] Add New Layer',
  props<{
    id: string
    lossFilterValue: string
    yearUpdate: number
    methodUpdate: string
  }>()
)

export const updateLayerTitle = createAction(
  '[Layer Details] Update Layer Title',
  props<{ year: number; method: string }>()
)

export const resetLayerTitle = createAction('[Layer Details] Reset Layer Title')
