import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { BenchmarkLineStyle } from '../benchmark/model/benchmark-color'
import { BenchmarkPeerSet } from '../benchmark/model/benchmark.model'

export interface ColorChipItem {
  name: string
  hide?: boolean
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-color-chips',
  styles: [
    `
      mat-chip-row {
        position: relative;
        cursor: pointer;
        line-height: 17px;
        min-height: 36px;
        padding: 6px 12px 7px;
        border-radius: 18px;
      }

      :host mat-chip-row.app-palette-primary {
        color: var(--primary-lit);
      }

      :host-context(.export) mat-chip-row .name {
        display: inline-block;
      }

      mat-chip-row .name {
        overflow: hidden;
        white-space: normal;
      }

      :host mat-chip.app-palette-primary {
        color: var(--primary-lit);
      }

      :host mat-chip-set ::ng-deep button {
        display: inline-flex;
        align-items: center;
        line-height: 18px;
        padding: 0px 6px 3px 3px;
        margin: 4px;
      }

      :host mat-chip-set ::ng-deep button mat-icon {
        position: relative;
        top: 3px;
        width: 20px;
        font-size: var(--font-size);
      }

      mat-chip-row.mat-mdc-standard-chip mat-icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
        margin-right: 6px;
        opacity: 0.4;
      }

      .name {
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }

      :host-context(.export) mat-chip .name {
        display: inline-block;
      }

      .empty {
        padding-top: var(--stack-tiny);
      }

      @media screen and (max-width: 1240px) {
        mat-chip-row {
          min-height: 30px;
          font-size: var(--font-size-tiny);
          line-height: 15px;
          padding-left: 8px;
          padding-right: 6px;
        }

        mat-chip-row.mat-mdc-standard-chip mat-icon {
          font-size: 14px;
          width: 14px;
          height: 14px;
        }

        mat-chip-row mat-icon.hide {
          margin-right: 6px;
        }
      }
    `,
  ],
  template: `
    <div class="empty">
      <label *ngIf="emptyLabel" big appInfoText>{{ emptyLabel }}</label>
    </div>

    <mat-chip-set
      [aria-label]="label + ' selection'"
      [ngClass]="{ 'mat-mdc-chip-set-stacked': stacked }"
    >
      <mat-chip-row
        *ngFor="let it of items; let i = index"
        [ngClass]="getChipClass(it)"
        (removed)="itemRemove.emit(it)"
        (click)="chipClick.emit(i)"
      >
        <mat-icon *ngIf="it.hide || hideIndividualPeers" class="hide">visibility_off</mat-icon>
        <span class="name">{{ getName(it) }}</span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    </mat-chip-set>
  `,
})
export class ColorChipsComponent<T extends ColorChipItem> {
  @Input() label: string
  @Input() items: T[]
  @Input() colorClasses: string[][]
  @Input() emptyMessage?: string
  @Input() maxNameLength = 40
  @Input() colorsByID: Record<string | number, BenchmarkLineStyle> = {}
  @Input() disableColors: boolean
  @Input() stacked = false
  @Input() hideIndividualPeers = false

  @Output() itemRemove = new EventEmitter<T>()
  @Output() chipClick = new EventEmitter<number>()

  get emptyLabel(): string | undefined {
    if (this.items.length === 0) {
      return this.emptyMessage ?? 'No Items'
    }
  }

  getName(item: T) {
    if (item.name.length > this.maxNameLength) {
      return `${item.name.substring(0, this.maxNameLength)}...`
    }
    return item.name
  }

  getChipClass(peerSet: BenchmarkPeerSet) {
    const cx: string[] = []
    cx.push('app-chip-palette')

    if (this.hideIndividualPeers) {
      cx.push('app-chip-outline')
      return cx
    }

    if (this.disableColors || this.colorsByID[peerSet.id] === undefined) {
      cx.push('app-palette-primary')
    } else {
      cx.push(this.colorsByID[peerSet.id].color)
    }
    return cx
  }
}
