import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { CompareEntity } from '../../../store/compare/compare.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-group-thumbnail',
  styleUrls: ['./compare-group-thumbnail.component.scss'],
  templateUrl: './compare-group-thumbnail.component.html',
})
export class CompareGroupThumbnailComponent {
  @Input() index: number
  @Input() height: number
  @Input() entities: CompareEntity[]

  @ViewChild('swiper', { static: false })
  set swiperRef(val: ElementRef) {
    this._swiperRef = val
  }
  get swiperRef() {
    return this._swiperRef
  }
  _swiperRef: ElementRef

  configGroup: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    watchOverflow: true,
    touchStartPreventDefault: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
    effect: 'slide',
  }

  getPrograms(index: number): string[] {
    const entity = this.entities[index]
    // tslint:disable-next-line:no-non-null-assertion
    return entity.program.imageSrcGroup!
  }

  getGroupImageSrc(index: number, subIndex: number): string | undefined {
    const entity = this.entities[index]
    // tslint:disable-next-line:no-non-null-assertion
    return entity.program.imageSrcGroup![subIndex]
  }

  getSlideStyle() {
    const width = `100%`
    return { width }
  }

  onPrev(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slidePrev()
    }
  }

  onNext(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slideNext()
    }
  }
}
