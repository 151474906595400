import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { QuoteCustomCompareView } from '../models/quote.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-delete-reinsurer-dialog',
  templateUrl: 'quote-delete-reinsurer-dialog.component.html',
  styleUrls: ['quote-delete-reinsurer-dialog.component.scss'],
})
export class QuoteDeleteReinsurerDialogComponent {
  comparisons: QuoteCustomCompareView[]
  comparisonStatus: Record<number, { status: 'remove' | 'delete' }> = {}
  constructor(
    private dialogRef: MatDialogRef<QuoteDeleteReinsurerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { comparisons: QuoteCustomCompareView[] }
  ) {
    this.comparisons = data.comparisons
    data.comparisons.forEach(
      comparison =>
        (this.comparisonStatus[comparison.id] = { status: 'remove' })
    )
  }

  destroyDialog(): void {
    this.dialogRef.close()
  }

  confirmForm(): void {
    this.dialogRef.close(this.comparisonStatus)
  }

  updateCheckbox(id: number, checkbox: 'remove' | 'delete') {
    const currentStatus = { ...this.comparisonStatus[id] }
    currentStatus.status = checkbox
    this.comparisonStatus[id] = currentStatus
  }
}
