import { AccountOpportunity } from '../../api/model/backend.model'
import { AGENCY_MARKET_USE, AgencyDetails, FUND_MANAGER_MARKET_USE, FUND_MANAGER_SEG_ACCOUNT_TEXT, FUND_MANAGER_UNKNOWN_TEXT, LLOYDS_SYNDICATE_PREFIX, Reinsurer, ReinsurerPriorYearView } from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { parse } from 'date-fns'
import { CurrencyPipe, DatePipe } from '@angular/common'
import { containsObo } from '@shared/util/string'

export const INTL_BU = ['International P&C', 'Aviation', 'Bermuda', 'Healthcare', 'Korea', 'North America (UK)', 'Speciality',' Retrocession', 'Marine and Energy']

export const isReinsurerOutsideOpportunityDate = (reinsurer: Reinsurer, programs: Study[] | null, accountOpportunities: AccountOpportunity[] | null, selectedProgramID: string | null, isInternational?: boolean): boolean => {
  const selectedProgramOpportunityId = programs?.find(
    f => f.id === selectedProgramID
  )?.opportunity_id
  const selectedProgramOpportunity = accountOpportunities?.find(
    f => f.oppId === selectedProgramOpportunityId
  )
  const approvalToDateKey = isInternational ? 'internationalReinsuranceApprovalToDate' : 'reinsuranceApprovalToDate'
  const approvalFromDateKey = isInternational ? 'internationalReinsuranceApprovalFromDate' : 'reinsuranceApprovalFromDate'

  const reinsuranceApprovalToDate = parseDate(
    reinsurer[approvalToDateKey],
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const reinsuranceApprovalFromDate = parseDate(
    reinsurer[approvalFromDateKey],
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const opportunityInceptionDate = parseDate(
    selectedProgramOpportunity?.opportunityInceptionDate,
    'yyyy-MM-dd'
  )
  const opportunityTerminationDate = parseDate(
    selectedProgramOpportunity?.opportunityTerminationDate,
    'yyyy-MM-dd'
  )

  if (
    opportunityInceptionDate &&
    opportunityTerminationDate &&
    reinsuranceApprovalFromDate &&
    reinsuranceApprovalFromDate.getTime() !== invalidDate?.getTime() &&
    reinsuranceApprovalToDate &&
    reinsuranceApprovalToDate.getTime() !== invalidDate?.getTime() &&
    (reinsuranceApprovalFromDate > opportunityInceptionDate ||
     reinsuranceApprovalToDate < opportunityInceptionDate)
  ) {
    return true
  }

  return false
}

export const parseDate = (
  dateString: string | null | undefined,
  format: string
): Date | null => {
  if (!dateString) {
    return null
  }
  return parse(dateString, format, new Date())
}

export const invalidDate = parseDate(
  '1900-01-01T00:00:00',
  "yyyy-MM-dd'T'HH:mm:ss"
)

export const internationalDateApprovalNeeded = (reinsurer: Reinsurer): boolean => {
  return (reinsurer.reinsurerProgramFactor[0].co_broker === 'Lockton Re LLP' && reinsurer.domicile === 'United States') ||
 (!reinsurer.reinsuranceApprovalFromDate && !reinsurer.reinsuranceApprovalToDate)

}

export const formatDate = (dateAsString: string): string => {
  if (!!dateAsString && dateAsString !== ' ') {
    const d = new Date(dateAsString)
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
  return ' '
}

export const getDataForReinsurerType = (
  type: 'inc' | 'tm',
  updatedReinsurers: Reinsurer[],
  agencyDetails: AgencyDetails[][],
  uniqueRefSections: ReinsurerPriorYearView[],
  priorData: ReinsurerPriorYearView[],
  reinsurers: Reinsurer[] | null,
  allCompanyPapers: Record<string, AgencyDetails[]> | null,
  allSegregatedAccounts: Record<string, AgencyDetails[]> | null,
  spliceInd: 19 | 20,
  datePipe: DatePipe,
  totalsArr: number[],
  includeWrittenPct: boolean,
  currencyPipe: CurrencyPipe,
  oppBU: string,
  oppGI: boolean,
  customBreakoutSelections?: string [],
) => {
  const result: (string | number | null)[][] = []
  updatedReinsurers.forEach(r => {
    let priorDataArray: (string | number)[] = []
    const reinsurerProgramFactorType =
      type === 'inc'
        ? r.reinsurerProgramFactor[0].incumbent
        : r.reinsurerProgramFactor[0].target_market
    if (
      reinsurerProgramFactorType &&
      r.market_use !== AGENCY_MARKET_USE &&
      r.market_use !== FUND_MANAGER_MARKET_USE
    ) {
      let arr = []
      if (
        r.market_security_grade === 'Collateralized' ||
        r.market_security_restrictions === 'Collateralized'
      ) {
        arr.push(
          r.name || '',
          '',
          '',
          '',
          r.domicile || '',
          'Collateralized',
          '',
          '',
          '',
          '',
          '',
          '',
          r.reinsurerProgramFactor[0].placed_through || '',
          r.reinsurerProgramFactor[0].co_broker || '',
          '',
          getNeededApprovalStatus(r, oppBU, oppGI) || '',
          r.market_security_grade || '',
          r.market_security_restrictions || '',
        )
        arr = merge(arr, priorDataArray, spliceInd)
      } else {
        priorDataArray = calculatePriorSigned(
          r,
          uniqueRefSections,
          priorData,
          false,
          false,
          null,
          totalsArr,
          undefined,
          includeWrittenPct,
          customBreakoutSelections
        )
        arr.push(
          r.name || '',
          formatNumber(
            getNumberFormat(r.naic_num, r.aain_num) || ''
          ),
          formatNumber(r.fein_num || ''),
          getLorsNum(r),
          r.domicile || '',
          r.am_best_rating || '',
          formatDate(r.amBestRatingDate || ''),
          r.am_best_outlook || '',
          r.sp_rating || '',
          formatDate(r.ratingDate || ''),
          r.sp_outlook || '',
          formatDate(r.outlookDate || ''),
          formatDate(r.lastReviewDate || ''),
          findEquityPHSValues(r, currencyPipe).value,
          findEquityPHSValues(r, currencyPipe).type,
          datePipe.transform(
            r.policy_holder === 0
              ? r.periodEndDateTotEquity
              : r.policy_holder_date,
            'MMM d, y'
          ) || ' ',
          r.reinsurerProgramFactor[0].placed_through || '',
          r.reinsurerProgramFactor[0].co_broker || '',
          ' ',
          getNeededApprovalStatus(r, oppBU, oppGI) || '',
          r.market_security_grade || '',
          r.market_security_restrictions || '',
        )
        arr = merge(arr, priorDataArray, spliceInd)
      }
      result.push(arr)
    } else if (
      reinsurerProgramFactorType &&
      r.market_use === AGENCY_MARKET_USE
    ) {
      const agencyList = agencyDetails.find(
        a =>
          a &&
          a.length > 0 &&
          a[0].agencyTPRef === r.tpRef &&
          a[0].agencySeqNumber ===
            r.reinsurerProgramFactor[0].relation_seq_number
      )
      if (agencyList && agencyList.length > 0 && reinsurers) {
        agencyList.forEach(a => {
          const reFound = reinsurers.find(r1 => r1.tpRef === a.memberTPRef)
          if (reFound) {
            let arr = []
            if (
              r.market_security_grade === 'Collateralized' ||
              r.market_security_restrictions === 'Collateralized'
            ) {
              const oboDetected =
                containsObo(r.name) || containsObo(reFound.name)
              const name = `${r.name}${
                !oboDetected ? ` obo ${reFound.name}` : ''
              }`
              arr.push(
                name,
                '',
                '',
                '',
                reFound.domicile || '',
                'Collateralized',
                '',
                '',
                '',
                '',
                '',
                '',
                r.reinsurerProgramFactor[0].placed_through || '',
                r.reinsurerProgramFactor[0].co_broker || '',
                '',
                getNeededApprovalStatus(r, oppBU, oppGI) || '',
                r.market_security_grade || '',
                r.market_security_restrictions || '',
              )
              arr = merge(arr, priorDataArray, spliceInd)
            } else {
              priorDataArray = calculatePriorSigned(
                r,
                uniqueRefSections,
                priorData,
                true,
                false,
                a.memberName,
                totalsArr,
                undefined,
                includeWrittenPct,
                customBreakoutSelections
              )
              const oboDetected =
                containsObo(r.name) || containsObo(reFound.name)
              const name = `${r.name}${
                !oboDetected ? ` obo ${reFound.name}` : ''
              }`
              arr.push(
                name,
                formatNumber(
                  getNumberFormat(reFound.naic_num, reFound.aain_num) ||
                    ''
                ),
                formatNumber(reFound.fein_num || ''),
                getLorsNum(reFound),
                reFound.domicile || '',
                reFound.am_best_rating || '',
                formatDate(reFound.amBestRatingDate || ''),
                reFound.am_best_outlook || '',
                reFound.sp_rating || '',
                formatDate(reFound.ratingDate || ''),
                reFound.sp_outlook || '',
                formatDate(reFound.outlookDate || ''),
                formatDate(reFound.lastReviewDate || ''),
                findEquityPHSValues(reFound, currencyPipe).value,
                findEquityPHSValues(reFound, currencyPipe).type,
                datePipe.transform(
                  reFound.policy_holder === 0
                    ? reFound.periodEndDateTotEquity
                    : reFound.policy_holder_date,
                  'MMM d, y'
                ) || ' ',
                r.reinsurerProgramFactor[0].placed_through || '',
                r.reinsurerProgramFactor[0].co_broker || '',
                ' ',
                getNeededApprovalStatus(r, oppBU, oppGI) || '',
                r.market_security_grade || '',
                r.market_security_restrictions || '',
              )
              arr = merge(arr, priorDataArray, spliceInd)
            }
            result.push(arr)
          }
        })
      }
    } else if (
      reinsurerProgramFactorType &&
      r.market_use === FUND_MANAGER_MARKET_USE
    ) {
      const companyPapers =
        allCompanyPapers && r.tpRef ? allCompanyPapers[r.tpRef] : []
      const segregatedAccounts =
        allSegregatedAccounts && r.tpRef ? allSegregatedAccounts[r.tpRef] : []
      if (companyPapers && companyPapers.length > 0) {
        companyPapers.forEach(companyPaper => {
          priorDataArray = calculatePriorSigned(
            r,
            uniqueRefSections,
            priorData,
            false,
            true,
            null,
            totalsArr,
            companyPaper.agencyTPRef ?? '',
            includeWrittenPct,
            customBreakoutSelections
          )
          let arr = []
          arr.push(
            companyPaper.agencyName,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Company Paper ' + r.reinsurerProgramFactor[0].placed_through ||
              '',
            '',
            '',
            '',
            companyPaper.approvalStatusCodeValue || '',
            '',
          )
          arr = merge(arr, priorDataArray, spliceInd)
          result.push(arr)
        })
      }
      if (
        r.reinsurerProgramFactor[0].segregated_account &&
        !(segregatedAccounts && segregatedAccounts.length > 0)
      ) {
        priorDataArray = calculatePriorSigned(
          r,
          uniqueRefSections,
          priorData,
          false,
          true,
          null,
          totalsArr,
          '',
          includeWrittenPct,
          customBreakoutSelections
        )
        let sArr = []
        sArr.push(
          `${r.name} ${FUND_MANAGER_SEG_ACCOUNT_TEXT}`,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          r.reinsurerProgramFactor[0].placed_through || '',
          '',
          '',
          '',
          '',
          '',
        )
        sArr = merge(sArr, priorDataArray, spliceInd)
        result.push(sArr)
      } else if (segregatedAccounts && segregatedAccounts.length > 0) {
        segregatedAccounts.forEach(segregatedAccount => {
          priorDataArray = calculatePriorSigned(
            r,
            uniqueRefSections,
            priorData,
            false,
            true,
            null,
            totalsArr,
            segregatedAccount.agencyTPRef ?? '',
            includeWrittenPct,
            customBreakoutSelections
            )
          let arr = []
          const oboDetected =
            containsObo(r.name) || containsObo(segregatedAccount.ilsInfo)
          const name = `${segregatedAccount.ilsInfo}${
            !oboDetected ? ` obo ${r.name}` : ''
          }`
          arr.push(
            name,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Segregated Account ' +
              r.reinsurerProgramFactor[0].placed_through || '',
            '',
            '',
            '',
            segregatedAccount.approvalStatusCodeValue || '',
            '',
          )
          arr = merge(arr, priorDataArray, spliceInd)
          result.push(arr)
        })
      }
      if (r.reinsurerProgramFactor[0].fund_mgr_unknown) {
        let sArr = []
        sArr.push(
          `${FUND_MANAGER_UNKNOWN_TEXT} - ${r.name}`,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          r.reinsurerProgramFactor[0].placed_through || '',
          '',
          '',
          '',
          '',
          '',
        )
        sArr = merge(sArr, priorDataArray, spliceInd)
        result.push(sArr)
      }
    }
  })
  return result
}

export const calculatePriorSigned = (
  r: Reinsurer,
  refSections: ReinsurerPriorYearView[],
  priorData: ReinsurerPriorYearView[],
  isAgency: boolean,
  isFundManager: boolean,
  memberName: string | null,
  totalsArr: number[],
  agencyTPRef?: string,
  includeWrittenPct?: boolean,
  customBreakoutSelections?: string[]
) => {
  const response: (number | string)[] = []

  if (customBreakoutSelections && customBreakoutSelections.length > 0) {
    const breakoutGroups = groupCustomBreakoutRefSections(priorData, customBreakoutSelections)
      .sort((a, b) => (a[0].sectionNum < b[0].sectionNum ? -1 : 1))

    breakoutGroups.forEach(bg => {
      const reinsurerLines = bg.filter(g => g.reinsurerName === r.name)
      let signedPct = 0
      let writtenPct = 0
      reinsurerLines.forEach(rl => {
        signedPct += rl.signedPct
        writtenPct += rl.writtenPct
      })
      const responseIndex = response.length
      if (includeWrittenPct) {
        response.push(writtenPct === 0 ? '' : writtenPct)
        if (!totalsArr[responseIndex]) {
          totalsArr[responseIndex] = 0
        }
        totalsArr[responseIndex] += writtenPct
      }
      response.push(signedPct === 0 ? '' : signedPct)
      const signedPctIndex = includeWrittenPct ? responseIndex + 1 : responseIndex
      if (!totalsArr[signedPctIndex]) {
        totalsArr[signedPctIndex] = 0
      }
      totalsArr[signedPctIndex] += signedPct
    })
  } else {
    refSections.forEach((rs, i) => {
      if (!totalsArr[i]) {
        totalsArr[i] = 0
      }
      let matchedPriorData: any[] = []
      if (isAgency) {
        matchedPriorData = priorData.filter(
          p =>
            p.riskRef === rs.riskRef &&
            p.marketTpRef === r.tpRef &&
            p.sectionNum === rs.sectionNum &&
            p.memberName === memberName
        )
      } else if (isFundManager) {
        matchedPriorData = priorData.filter(
          p =>
            p.riskRef === rs.riskRef &&
            p.sectionNum === rs.sectionNum &&
            p.marketTpRef === agencyTPRef &&
            p.memberName === r.name
        )
      } else {
        matchedPriorData = priorData.filter(
          p =>
            p.riskRef === rs.riskRef &&
            p.marketTpRef === r.tpRef &&
            p.sectionNum === rs.sectionNum
        )
      }
      if (matchedPriorData && matchedPriorData.length > 0) {
        const p = response.length
        if (!totalsArr[p]) {
          totalsArr[p] = 0
        }
        if (!totalsArr[p + 1]) {
          totalsArr[p + 1] = 0
        }
        const layerData = matchedPriorData.filter(mp => mp.layerName === rs.layerName)[0]
        const sPct = Number(layerData?.signedPct ?? '')
        const wPct = Number(layerData?.writtenPct ?? '')

        if (includeWrittenPct) {
          response.push(wPct === 0 ? '' : wPct)
          totalsArr[p] += wPct
          totalsArr[p + 1] += sPct
        } else {
          totalsArr[p] += sPct
        }
        response.push(sPct === 0 ? '' : sPct)
      } else {
        response.push('')
        if (includeWrittenPct) {
          response.push('')
        }
      }
    })
  }
  return response
}



export const merge = (a: any[], b: any[], i = 0) => {
  return [...a.slice(0, i), ...b, ...a.slice(i)]
}


const formatNumber = (input: any) => {
  if (input === '') {
    return input
  } else if (isNaN(input)) {
    return input
  } else {
    return +input
  }
}

export const getNumberFormat = (num1: string | null, num2: string | null) => {
  let res = ''
  if (num1 && num1 !== '0') {
    res += num1
    if (num2 && num2 !== '0') {
      res += '/ ' + num2
    }
  } else if (num2 && num1 !== '0') {
    res += num2
  }
  return res
}

export const getLorsNum = (reinsurer: Reinsurer) => {
  const cleanedName = reinsurer.name.replace(/ /g, '')
  const cleanedPrefix = LLOYDS_SYNDICATE_PREFIX.replace(/ /g, '')
  const isLloydsSyndicate = cleanedName.startsWith(cleanedPrefix)
  if (isLloydsSyndicate) {
    const dashIndex = cleanedName.indexOf('-')
    if (dashIndex !== -1) {
      return `L${cleanedName.slice(cleanedPrefix.length, dashIndex)}`
    }
  }
  return reinsurer.lors_num ?? ''
}

export const BREAKOUT_MAP = [
  { selection: 'inceptionDate', field: 'effectiveDateAndTime', displayName: 'Inception Date' },
  { selection: 'expireDate', field: 'expiryDateAndTime', displayName: 'Expiration Date' },
  { selection: 'currency', field: 'baseCurrencyCode', displayName: 'Currency' },
  { selection: 'adjustRate', field: 'premiumAdjRate', displayName: 'Adjustable Rate' },
  { selection: 'riskLimit', field: 'riskLimit', displayName: 'Risk Limit' },
  { selection: 'occLimit', field: 'occurrenceLimit', displayName: 'Occ Limit' },
  { selection: 'excess', field: 'occurrenceAttachment', displayName: 'Attachment' },
  { selection: 'deposit', field: 'initialPremiumAmount', displayName: 'Deposit' },
  { selection: 'minimum', field: 'minimumPremiumAmount', displayName: 'Minimum' },
  { selection: 'aggLimit', field: 'maximumLiability', displayName: 'Agg Limit' },
  { selection: 'aggAttachment', field: 'annualAggregateDeductible', displayName: 'Agg Attachment(AAD)' },
  { selection: 'notes', field: 'notePad', displayName: 'Notes' },
  { selection: 'reinstatements', field: 'reinstatementsFull', displayName: 'Reinstatements' }
]

export const findUniqueRefSections= (refSections: ReinsurerPriorYearView[], selections: string[]): ReinsurerPriorYearView[] => {
  const uniqueRefs = new Map<string, ReinsurerPriorYearView>()
  refSections.forEach(ref => {
    let key = selections.map(selection => {
      const mapEntry = BREAKOUT_MAP.find(entry => entry.selection === selection)
      if (mapEntry) {
          const fieldValue = ref[mapEntry.field as keyof ReinsurerPriorYearView]
          return `${mapEntry.selection}:${fieldValue != null ? fieldValue : 'N/A'}`
      }
      return ''
    }).join('-')
    if (!uniqueRefs.has(key)) {
        uniqueRefs.set(key, ref)
    }
  })
  return Array.from(uniqueRefs.values())
}

export const groupCustomBreakoutRefSections = (refSections: ReinsurerPriorYearView[], selections: string[]): ReinsurerPriorYearView[][] => {
  const groupedRefs = new Map<string, ReinsurerPriorYearView[]>()

  refSections.forEach(ref => {
      let key = selections.map(selection => {
        const mapEntry = BREAKOUT_MAP.find(entry => entry.selection === selection)
        if (mapEntry) {
            const fieldValue = ref[mapEntry.field as keyof ReinsurerPriorYearView]
            return `${mapEntry.selection}:${fieldValue != null ? fieldValue : 'N/A'}`
        }
        return ''
      }).join('-')
      if (key) {
          if (!groupedRefs.has(key)) {
              groupedRefs.set(key, [])
          }
          groupedRefs.get(key)?.push(ref)
      }
  })

  return Array.from(groupedRefs.values())
}

export const populateRefArray = (
  arrayField: string,
  uniqueRefSections: ReinsurerPriorYearView[],
  selections: string[],
  datePipe: DatePipe,
  currencyPipe: CurrencyPipe,
  includeWrittenPct: boolean
) => {
  const array: string [] = []
  const breakout = BREAKOUT_MAP.find(b => b.selection === arrayField)
  if (breakout && selections && selections.includes(breakout.selection)) {
    uniqueRefSections.forEach((rf, i) => {
      let value = rf[breakout.field as keyof ReinsurerPriorYearView] || ' '
      switch (breakout.selection) {
          case 'adjustRate':
            value = `${value}%`
            break
          case 'inceptionDate':
          case 'expireDate':
              value = value !== ' ' ? datePipe.transform(value) || ' ' : ' '
              break
          case 'riskLimit':
          case 'occLimit':
          case 'excess':
          case 'deposit':
          case 'minimum':
          case 'aggLimit':
          case 'aggAttachment':
              value = value !== ' ' ? addSymbolToAmount(Number(value), rf.baseCurrencyCode, currencyPipe) || '0' : '0'
              break
        }
      if (i === 0) {
        array.push(breakout.displayName)
      }
      array.push(String(value))
      if (includeWrittenPct) {
        array.push(' ')
      }
    })
  }
  return array
}

export const addSymbolToAmount = (
  amount: number,
  currencyCode: string,
  currencyPipe: CurrencyPipe
  ): string => {
  return currencyPipe.transform(
    amount,
    currencyCode,
    'symbol-narrow',
    '1.0-0'
  ) as string
}

export const createInterestNarrativeMap = (data: ReinsurerPriorYearView[]): Map<string, number> => {
  const narrativeMap = new Map<string, number>()
  data.forEach(item => {
    const narrative = item.interestNarrative
    if (narrativeMap.has(narrative)) {
      const currentValue = narrativeMap.get(narrative)
      narrativeMap.set(narrative, currentValue + 1)
    } else {
      narrativeMap.set(narrative, 1)
    }
  })

  return narrativeMap
}

export const findEquityPHSValues = (reinsurer: Reinsurer, currencyPipe: CurrencyPipe):
  {
    value: string,
    type: string
  } => {
    let value = ''
    let type = ''
    if (reinsurer.policy_holder === 0) {
      if (!!reinsurer.totalEquity && reinsurer.totalEquity !== 0) {
        value = addSymbolToAmount(((reinsurer.totalEquity) || 0) /1000, reinsurer.currency ?? '', currencyPipe) ?? ''
        type = 'Total Equity'
      }
    } else {
      value = value = addSymbolToAmount(((reinsurer.policy_holder) || 0) /1000, reinsurer.currency ?? '', currencyPipe) ?? ''
      type = 'PHS'
    }
    if (value.split('')[1] === '0' && reinsurer.domicile === 'United States') {
      type = 'PHS'
    }
    return {
      value,
      type
    }
}

export const getNeededApprovalStatus = (
  reinsurer: Reinsurer,
  bu: string,
  oppGI: boolean
): string => {
  const filteredStatus = new Set<string>()
  const statuses = reinsurer.approval_status?.split(', ') || []
  const coBroker =  reinsurer.reinsurerProgramFactor[0].co_broker

  const isUS = !!bu && bu.includes('US')
  const isINTL = !!INTL_BU && INTL_BU.includes(bu) && !oppGI
  const isLAC = bu && bu.includes('LAC')
  const isTurkey = bu && bu.includes('Turkey')

  const isLocktonLLP = coBroker === 'Lockton Re LLP'
  const isLocktonLLC = coBroker === 'Lockton Re LLC'

  statuses.forEach(status => {
    const pushUS = isUS && (
      status.includes('NORTHAM') ||
      (isLocktonLLP && status.includes('INTL'))
    )

    const pushINTL = isINTL && (
      status.includes('INTL') ||
      (isLocktonLLC && status.includes('NORTHAM'))
    )

    const pushGermany = oppGI && (
      status.includes('GERMANY') ||
      (isLocktonLLC && status.includes(('NORTHAM')))
    )

    const pushLAC = isLAC && (
      status.includes('LAC') ||
      (isLocktonLLP && status.includes('INTL'))
    )

    const pushTurkey = isTurkey && (
      status.includes('TURKEY') ||
      status.includes('INTL')
    )

    if (pushUS) {
      filteredStatus.add(status)
    } else if (pushINTL) {
      filteredStatus.add(status)
    } else if (pushGermany) {
      filteredStatus.add(status)
    } else if (pushLAC) {
      filteredStatus.add(status)
    } else if (pushTurkey) {
      filteredStatus.add(status)
    }
  })
  let updatedStatus = [...filteredStatus].sort((a, b) => {
    const buA = a.split('from ')[1] || ''
    const buB = b.split('from ')[1] || ''
    const compareBU =
      isUS ? 'NORTHAM' :
      isINTL ? 'INTL' :
      oppGI ? 'GERMANY' :
      bu ?? ''

    const isBUA = a.includes(compareBU)
    const isBUB = b.includes(compareBU)

    if (isBUA && !isBUB) return -1
    if (!isBUA && isBUB) return 1

    if (buA > buB) return -1
    if (buA < buB) return 1
    return 0
  })
  return updatedStatus.join(', ')
}
