import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AccountOpportunity } from '../../api/model/backend.model'
import { environment } from 'src/environments/environment'

export interface OpportunityDetailsDialogData {
  opp: AccountOpportunity
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirmation',
  styles: [
    `
           table.tidy-auto-table {
             border-collapse: collapse;
             margin: 0;
             padding: 0;
             width: 100%;
             max-width: 1250px;
             table-layout: auto;
           }
     
           tr {
             border: 1px solid var(--border);
             border-width: 0 0 1px;
             padding: 0 0.35em;
             height: var(--table-row-height);
           }
     
           td {
             padding: var(--stack-tiny) var(--inset-small);
             text-align: right;
             font-weight: var(--font-weight);
             vertical-align: top;
           }
     
           th {
             padding: var(--stack-tiny) var(--inset-small);
             text-align: left;
             font-size: var(--font-size);
             letter-spacing: 0.3px;
             color: var(--subtle);
             font-family: var(--font-header-family);
             font-weight: var(--font-header-weight);
           }
         `,
  ],
  template: `
    <div>
      <h1 mat-dialog-title>{{ data.opp.opportunityName }} Details</h1>
      <div mat-dialog-content>
        <table class="tidy-auto-table">
          <tbody>
            <tr>
              <th>Sales Account</th>
              <td>
                {{ accountName }}
              </td>
            </tr>
            <tr>
              <th>Transactional Account</th>
              <td>
                {{ transactionalAcctName }}
              </td>
            </tr>      
            <tr>
              <th>Transactional Account OpenTwins Id</th>
              <td>
                {{ transactionalAcctOpenTWINSId }}
              </td>
            </tr>                   
            <tr>
              <th>Opportunity Stage</th>
              <td>
                {{ opportunityStage }}
              </td>
            </tr>
            <tr>
              <th>Next Step</th>
              <td>{{ nextStep }}</td>
            </tr>
            <tr>
              <th>Business Unit</th>
              <td>
                {{ opportunityBU }}
              </td>
            </tr>
            <tr>
              <th>Class</th>
              <td>
                {{ opportunityClass }}
              </td>
            </tr>
            <tr>
              <th>Sub-Class</th>
              <td>
                {{ opportunitySubClass }}
              </td>
            </tr>
            <tr>
              <th>Risk Type</th>
              <td>
                {{ opportunityRiskType }}
              </td>
            </tr>
            <tr>
              <th>Contract Structure</th>
              <td>
                {{ opportunityContractStructure }}
              </td>
            </tr>
            <tr>
              <th>Inception Date</th>
              <td>
                {{ opportunityInceptionDate }}
              </td>
            </tr>
            <tr>
              <th>Expiration Date</th>
              <td>
                {{ opportunityTerminationDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div mat-dialog-actions>
        <button appButton accent (click)="confirm(false)">
          <span>Close</span>
        </button>
        <button mat-button color="accent"       
        *ngIf="data && data.opp"
        (click)="goToSalesforce()" color="accent">
        <span>
          Salesforce
          <mat-icon>open_in_new</mat-icon>
        </span>
      </button>
      </div>
    </div>
  `,
})
export class OpportunityDetailsDialogComponent {
  data: OpportunityDetailsDialogData

  get accountName() {
    return this.data.opp.accountName || '-'
  }

  get transactionalAcctName() {
    return this.data.opp.transactionalAcctName || '-'
  }

  get transactionalAcctOpenTWINSId() {
    return this.data.opp.transactionalAcctOpenTWINSId || '-'
  }

  get opportunityStage() {
    return this.data.opp.opportunityStage || '-'
  }

  get nextStep() {
    return this.data.opp.opportunityNextStep || '-'
  }

  get opportunityBU() {
    return this.data.opp.opportunityBU || '-'
  }

  get opportunityClass() {
    return this.data.opp.opportunityClass || '-'
  }

  get opportunitySubClass() {
    return this.data.opp.opportunitySubClass || '-'
  }

  get opportunityRiskType() {
    return this.data.opp.opportunityRiskType || '-'
  }

  get opportunityContractStructure() {
    return this.data.opp.opportunityContractStructure || '-'
  }

  get opportunityInceptionDate() {
    return this.data.opp.opportunityInceptionDate || '-'
  }

  get opportunityTerminationDate() {
    return this.data.opp.opportunityTerminationDate || '-'
  }

  goToSalesforce(): void{
    window.open(
      `${environment.salesforceURI.base}/lightning/r/Opportunity/${this.data.opp.oppId}/view`,
      '_blank'
    )
  }

  constructor(
    public dialogRef: MatDialogRef<OpportunityDetailsDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA)
    public opportunityData: OpportunityDetailsDialogData
  ) {
    this.data = opportunityData
  }

  confirm(choice: boolean) {
    this.dialogRef.close(choice)
  }
}
