import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'
import { getPaletteClassByIndex } from '../../../../core/model/palette'
import { GroupBarEdge } from '../../../store/grouper/program-group.model'

export enum LeftRight {
  left = 'left',
  right = 'right',
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-tower-bar-edge',
  styleUrls: ['./group-tower-bar-edge.component.scss'],
  templateUrl: './group-tower-bar-edge.component.html',
})
export class GroupTowerBarEdgeComponent {
  @Input() edge: GroupBarEdge
  @Input() side: LeftRight

  @Input() set transparent(value: boolean) {
    this._transparent = coerceBooleanProperty(value)
  }
  get transparent() {
    return this._transparent
  }
  private _transparent = false

  @HostBinding('class.left') get isStart(): boolean {
    return this.side === LeftRight.left
  }

  @HostBinding('class.right') get isEnd(): boolean {
    return this.side === LeftRight.right
  }

  @HostBinding('class.minimized') get isMinimized(): boolean {
    return this.edge.minimized
  }

  get paletteClasses() {
    return {
      [getPaletteClassByIndex(this.edge.paletteIndex)]: true,
      untitled: this.edge.paletteIndex === -1,
      transparent: this.transparent,
    }
  }
}
