import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  AgencyDetails,
  Reinsurer,
  AgencyDetailsSage,
  ReinsurerPriorYearView,
  FundManager,
  CreateAgencyResponse,
  CompanyPaperAgencySeqNum,
  AgencyCreationRequest,
} from 'src/app/core/model/reinsurer.model'
import { environment } from '../../../environments/environment'
import { ProgramFactor } from '../../core/model/reinsurer.model'
import { ApiResponse } from '../model/api.model'
import {
  catchAndHandleError,
  mapResponse,
  mapToMaybeData,
  mergeApiResponses,
} from '../util'
import { Study } from 'src/app/core/model/study.model'

@Injectable({
  providedIn: 'root',
})
export class ReinsurerService {
  private httpOptions = {
    headers: new HttpHeaders({
      // 'Accept-Encoding': 'gzip, compress, br',
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  getReinsurers(carrierID: string, studyID: string): ApiResponse<Reinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}/${carrierID}${environment.internalApi.study}/${studyID}/reinsurers`
    return this.httpClient
      .get<Reinsurer[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Reinsurers'))
  }

  getMultipleReinsurers(
    carrierID: string,
    studyIDs: string[]
  ): ApiResponse<Record<string, Reinsurer[]>> {
    const observables = studyIDs.map(id => this.getReinsurers(carrierID, id))
    return forkJoin(observables).pipe(
      mergeApiResponses(),
      mapResponse(data =>
        data.reduce<Record<string, Reinsurer[]>>(
          (acc, reinsurers, i) => ({
            ...acc,
            [studyIDs[i]]: reinsurers,
          }),
          {}
        )
      )
    )
  }

  updateReinsurers(reinsurers: ProgramFactor[]): ApiResponse<Reinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.reinsurers}`
    return this.httpClient
      .put<Reinsurer[]>(url, reinsurers)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Reinsurers'))
  }

  deleteReinsurer(id: number): ApiResponse<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.reinsurers}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Delete Reinsurers'))
  }

  getAgencyDetailsByTpRef(id: string): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencies}/${id}`
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Agency Details'))
  }

  getAgencyDetailsByTpRefAndProgram(tpRef: string, programId: string | null): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agenciesByProgram}/${programId}/tpRef/${tpRef}`
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Agency Details by Program'))
  }

  postAgencyDetailsToOpenTWINS(
    agencyDetails: AgencyDetailsSage[],
    program: Study | undefined
  ): ApiResponse<CreateAgencyResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencyDetails}`
    const agencyCreationRequest: AgencyCreationRequest = {
      agencyDetails,
      programId: program?.id
    }

    return this.httpClient
      .post<CreateAgencyResponse>(url, agencyCreationRequest, this.httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Post Agency Details to OpenTWINS')
      )
  }

  postAgencyDetailsSage(
    relations: AgencyDetailsSage[]
  ): ApiResponse<Array<AgencyDetailsSage>> {
    const observables = []
    for (const relation of relations) {
      if (
        relation.memberName &&
        relation.memberName !== null &&
        relation.memberTPRef &&
        relation.memberTPRef !== null
      ) {
        observables.push(this.postAgencyDetailSage(relation))
      }
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        // tslint:disable-next-line: no-non-null-assertion
        return { data: results.map(r => r.data!) }
      })
    )
  }

  postAgencyDetailSage(
    agencyDetails: AgencyDetailsSage
  ): ApiResponse<AgencyDetailsSage> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencyDetailsSage}`
    return this.httpClient
      .post<AgencyDetailsSage>(url, agencyDetails, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Agency Details Sage'))
  }

  getReinsurerPriorYearBySfID(
    id: string
  ): ApiResponse<ReinsurerPriorYearView[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.reinsurerPriorYear}/${id}`
    return this.httpClient
      .get<ReinsurerPriorYearView[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET ReinsurerPriorYearBySfID')
      )
  }
  getCompanyPapers(
    tpRef: string,
    inceptionDate: string | undefined
  ): ApiResponse<AgencyDetails[]> {
    let url = ''
    if (!inceptionDate) {
      url = `${environment.internalApi.base}${environment.internalApi.agencies}/companyPapers?tpRef=${tpRef}`
    } else {
      url = `${environment.internalApi.base}${environment.internalApi.agencies}/companyPapers?tpRef=${tpRef}&inceptionDate=${inceptionDate}`
    }
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Get Company Papers'))
  }

  getSelectedCompanyPapers(
    tpRef: string,
    programID: string
  ): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencies}/companyPapers/selected?tpRef=${tpRef}&programID=${programID}`
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Get Selected Company Papers')
      )
  }

  postCompanyPapers(
    tpRef: string,
    companyPapers: CompanyPaperAgencySeqNum[],
    programID: string
  ): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencies}/companyPapers`
    const fundManager: FundManager = {
      fundManagerTpRef: tpRef,
      companyPapers,
      programID,
    }
    return this.httpClient
      .post<AgencyDetails[]>(url, fundManager, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Company Papers'))
  }

  getMultipleSelectedCompanyPapers(
    programID: string,
    tpRefs: string[]
  ): ApiResponse<Record<string, AgencyDetails[]>> {
    const observables = tpRefs.map(tpRef =>
      this.getSelectedCompanyPapers(tpRef, programID)
    )
    return forkJoin(observables).pipe(
      mergeApiResponses(),
      mapResponse(data =>
        data.reduce<Record<string, AgencyDetails[]>>(
          (acc, agencies, i) => ({
            ...acc,
            [tpRefs[i]]: agencies,
          }),
          {}
        )
      )
    )
  }

  getSegregatedAccountDetails(
    tpRef: string,
    inceptionDate: string | undefined
  ): ApiResponse<AgencyDetails[]> {
    let url = ''
    if (!inceptionDate) {
      url = `${environment.internalApi.base}${environment.internalApi.agencies}/segregatedAccounts?tpRef=${tpRef}`
    } else {
      url = `${environment.internalApi.base}${environment.internalApi.agencies}/segregatedAccounts?tpRef=${tpRef}&inceptionDate=${inceptionDate}`
    }
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Segregated Account Agency Details')
      )
  }

  postSegregatedAccounts(
    tpRef: string,
    segregatedAccounts: string[],
    programID: string
  ): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencies}/segregatedAccounts`
    const fundManager: FundManager = {
      fundManagerTpRef: tpRef,
      segregatedAccounts,
      programID,
    }
    return this.httpClient
      .post<AgencyDetails[]>(url, fundManager, this.httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Post Segregated Account Agency Details')
      )
  }

  getSelectedSegregatedAccountDetails(
    tpRef: string,
    programID: string
  ): ApiResponse<AgencyDetails[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.agencies}/segregatedAccounts/selected?tpRef=${tpRef}&programID=${programID}`
    return this.httpClient
      .get<AgencyDetails[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Selected Segregated Account Agency Details')
      )
  }

  getMultipleSegregatedAccountDetails(
    tpRefs: string[],
    programID: string
  ): ApiResponse<Record<string, AgencyDetails[]>> {
    const observables = tpRefs.map(tpRef =>
      this.getSelectedSegregatedAccountDetails(tpRef, programID)
    )
    return forkJoin(observables).pipe(
      mergeApiResponses(),
      mapResponse(data =>
        data.reduce<Record<string, AgencyDetails[]>>(
          (acc, agencies, i) => ({
            ...acc,
            [tpRefs[i]]: agencies,
          }),
          {}
        )
      )
    )
  }
}
