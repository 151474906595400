<section class="filter-bar" *ngIf="form">
  <form class="filters" [formGroup]="form">
    <app-multiselect-filter
      [form]="form"
      [filter]="savedCurveSelectors.fullName"
      [name]="'Author'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, savedCurveSelectors.fullName)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-filter>
    <app-multiselect-filter
      [form]="form"
      [filter]="savedCurveSelectors.pcClass"
      [name]="'Class'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, savedCurveSelectors.pcClass)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-filter>
    <app-multiselect-filter
      [form]="form"
      [filter]="savedCurveSelectors.pcSubClass"
      [name]="'Sub-Class'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, savedCurveSelectors.pcSubClass)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-filter>
    <app-multiselect-filter
      [form]="form"
      [filter]="savedCurveSelectors.layerCategory"
      [name]="'Layer Category'"
      [loading]="status.isLoading || status.dialogLoading"
      (clearFilter)="clearFilter($event, savedCurveSelectors.layerCategory)"
      (setFilter)="dispatchFilters()"
    ></app-multiselect-filter>
    <section class="more-options_ranges">
      <div class="range_inputs">
        <mat-label class="range_label">Updated Date</mat-label>
        <mat-form-field
          class="field interval app-bar-field app-bar-field-button app-field-bold-label"
          subscriptSizing="dynamic"
          floatLabel="auto"
        >
          <input
            matInput
            #updateDateInputStart
            formControlName="updateDateInputStart"
            [matDatepicker]="startDate"
            placeholder="Start"
            (dateChange)="onStartDateChange('updateDate', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            matSuffix
            class="clear"
            (click)="clearDate('updateDate', true)"
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          class="field interval app-bar-field app-bar-field-button app-field-bold-label"
          subscriptSizing="dynamic"
          floatLabel="auto"
        >
          <input
            matInput
            #updateDateInputEnd
            formControlName="updateDateInputEnd"
            placeholder="End"
            [matDatepicker]="endDate"
            (dateChange)="onEndDateChange('updateDate', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            matSuffix
            class="clear"
            (click)="clearDate('updateDate', false)"
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </section>
  </form>
</section>
