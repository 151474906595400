<table mat-table class="app-table hide-header-row" [dataSource]="newData">
  <ng-container *ngIf="!noNames" matColumnDef="header">
    <h1 *ngIf="_isPortfolioMetrics">
      <th mat-header-cell class="col-name title" *matHeaderCellDef>
        <span alt="Return Period" class="dummy"></span>
        <button
          appButtonIcon
          *ngIf="showTogglesButton && metrics"
          class="toggles"
          opaque
          [matMenuTriggerFor]="togglesMenu"
        >
          settings
        </button>
        <mat-menu #togglesMenu="matMenu" yPosition="above">
          <app-return-period-toggles
            *ngIf="showTogglesButton && metrics"
            [metrics]="metrics"
            [isLayerResults]="true"
            (portfolioTypeChange)="portfolioTypeChange.emit($event)"
            (perspectiveChange)="perspectiveChange.emit($event)"
            (aggregationMethodChange)="aggregationMethodChange.emit($event)"
          ></app-return-period-toggles>
        </mat-menu>
      </th>
      <td mat-cell class="col-name" *matCellDef="let element">
        {{ element.header }}
      </td>
    </h1>
  </ng-container>

  <ng-container *ngIf="!noNames" matColumnDef="value">
    <h1 *ngIf="_isPortfolioMetrics">
      <th mat-header-cell class="col-name title" *matHeaderCellDef>
        <span alt="Return Period" class="dummy">{{ dummyHeader }}</span>
        <button
          appButtonIcon
          *ngIf="showTogglesButton && metrics"
          class="toggles"
          opaque
          [matMenuTriggerFor]="togglesMenu"
        >
          settings
        </button>
        <mat-menu #togglesMenu="matMenu" yPosition="above">
          <app-return-period-toggles
            *ngIf="showTogglesButton && metrics"
            [metrics]="metrics"
            [isLayerResults]="true"
            (portfolioTypeChange)="portfolioTypeChange.emit($event)"
            (perspectiveChange)="perspectiveChange.emit($event)"
            (aggregationMethodChange)="aggregationMethodChange.emit($event)"
          ></app-return-period-toggles>
        </mat-menu>
      </th>
      <td mat-cell class="col-name stdExp" *matCellDef="let element">
        {{ element.value }}
      </td>
    </h1>
  </ng-container>

  <ng-container *ngIf="!noNames" matColumnDef="term">
    <th mat-header-cell class="col-name title" *matHeaderCellDef>
      <span alt="Return Period">{{ titlePortfolio }}</span>
      <button
        appButtonIcon
        *ngIf="showTogglesButton && metrics"
        class="toggles"
        opaque
        [matMenuTriggerFor]="togglesMenu"
      >
        settings
      </button>
      <mat-menu #togglesMenu="matMenu" yPosition="above">
        <app-return-period-toggles
          *ngIf="showTogglesButton && metrics"
          [metrics]="metrics"
          [isLayerResults]="true"
          (portfolioTypeChange)="portfolioTypeChange.emit($event)"
          (perspectiveChange)="perspectiveChange.emit($event)"
          (aggregationMethodChange)="aggregationMethodChange.emit($event)"
        ></app-return-period-toggles>
      </mat-menu>
    </th>
    <td mat-cell class="col-name" *matCellDef="let element">
      {{ element.term }}
    </td>
  </ng-container>

  <ng-container matColumnDef="period1">
    <th mat-header-cell class="col-value" *matHeaderCellDef>
      <mat-menu
        #period1Menu="matMenu"
        yPosition="above"
        panelClass="app-theme-dark"
        backdropClass="app-theme-dark"
        class="app-menu-no-max-width"
      >
        <app-return-period-selector
          [returnPeriod]="rp1"
          (returnPeriodChange)="rp1Change.emit($event)"
          (click)="$event.preventDefault(); $event.stopPropagation()"
        ></app-return-period-selector>
      </mat-menu>
      <button
        appButton
        [size]="rpButtonSize"
        [matMenuTriggerFor]="period1Menu"
      >
        {{ rp1 === 1 ? 'EXP' : rp1 }}
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>
    </th>
    <td mat-cell class="col-value" *matCellDef="let element">
      {{ invertVarAndTvar(element, 'period1') | appShortNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="period2">
    <th mat-header-cell class="col-value" *matHeaderCellDef>
      <mat-menu
        #period2Menu="matMenu"
        yPosition="above"
        class="app-menu-no-max-width"
      >
        <app-return-period-selector
          [returnPeriod]="rp2"
          (returnPeriodChange)="rp2Change.emit($event)"
          (click)="$event.preventDefault(); $event.stopPropagation()"
        ></app-return-period-selector>
      </mat-menu>
      <button
        appButton
        [size]="rpButtonSize"
        [matMenuTriggerFor]="period2Menu"
      >
        {{ rp2 }}
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>
    </th>
    <td mat-cell class="col-value" *matCellDef="let element">
      {{ invertVarAndTvar(element, 'period2') | appShortNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="period3">
    <th mat-header-cell class="col-value" *matHeaderCellDef>
      <mat-menu
        #period3Menu="matMenu"
        yPosition="above"
        class="app-menu-no-max-width"
      >
        <app-return-period-selector
          [returnPeriod]="rp3"
          (returnPeriodChange)="rp3Change.emit($event)"
          (click)="$event.preventDefault(); $event.stopPropagation()"
        ></app-return-period-selector>
      </mat-menu>
      <button
        appButton
        [size]="rpButtonSize"
        [matMenuTriggerFor]="period3Menu"
      >
        {{ rp3 }}
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>
    </th>
    <td mat-cell class="col-value" *matCellDef="let element">
      {{ invertVarAndTvar(element, 'period3') | appShortNumber }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div *ngIf="displayFooter" class="footerRP">
  {{ footerValue }}
</div>
