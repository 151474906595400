<div>
  <label
      *ngIf="!cell && name && !hideLabel"
      [ngClass]="{ 'label-white': whiteLabel }">
      {{ name }}
  </label>
  <mat-icon
    class="warning-icon"
    *ngIf="displayOverrideTooltip"
    [matTooltip]="overrideTooltip"
    matTooltipPosition="right"
    (click)="tooltipClicked()"
  >error</mat-icon>
</div>
<input
  *ngIf="!isCustomText"
  #input
  type="text"
  formatPercentage
  onClick="select()"
  [ngClass]="{ 'read-only': readonly }"
  [isCession]="isCession"
  [isReinstatements]="isReinstatements"
  [isROL]="isROL"
  [isNegative]="isNegative"
  [input]="value"
  [decimal]="decimal"
  [readonly]="readonly"
  [isAssignedLines]="isAssignedLines"
  [columnName]="columnName"
  [isQQ]="isQQ"
  [(ngModel)]="formattedValue"
  (ngModelChange)="inputChangeDebounce$.next(percentInputChanged($event))"
  (keyup)="inputKeyup.emit(metadata)"
  (keydown.enter)="onInput()"
  (focus)="onFocus()"
  (focusout)="onFocusOut()"
/>
<!--{{formattedValue}} ({{value}})-->

<input
  *ngIf="isCustomText"
  type="text"
  [(ngModel)]="value"
  [readonly]="readonly"
/>

<mat-checkbox
  *ngIf="displayCheckbox"
  [disabled]="checkboxDisabled"
  [matTooltip]="checkboxTooltip"
  [matTooltipDisabled]="checkboxTooltipDisabled"
  [checked]="checkboxStatus"
  (change)="checkboxChange.emit($event.checked)"
></mat-checkbox>

<mat-spinner
  *ngIf="submitting && !noLoadingSpinnerOnSubmit"
  [diameter]="spinnerSize"
></mat-spinner>
