<div class="wrapper">
  <div class="button-bar">
    <div class="button-bar-left">
      <mat-form-field
        appearance="fill"
        subscriptSizing="dynamic"
        class="search-field"
      >
        <input
          matInput
          class="input-field"
          placeholder="Click & type to search..."
          #filterInput
          [formControl]="filterCtrl"
          (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
        />
        <mat-icon
          *ngIf="filterCtrl.value.length"
          class="suffix-icon"
          [class.hidden]="!filterCtrl.value.length"
          matSuffix
          (click)="clearFilter()"
          >cancel</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="button-bar-right">
      <button
        class="reset-button"
        appButton
        big
        accent
        (click)="resetSelectors()"
        [disabled]="!resetSelectorsActive"
        matTooltip="Reset Filter Selections"
        [matTooltipDisabled]="!resetSelectorsActive"
      >
        <span>Reset Filters</span>
      </button>
      <div
        *ngIf="usageContext === 'pricing-curve' || (usageContext === 'credit' && !readonly)"
      >
        <button
          appButton
          big
          accent
          (click)="confirmAndDeleteCurve()"
          [disabled]="!activeRow"
          [matTooltip]="disabledTooltip"
          [matTooltipDisabled]="activeRow"
        >
          <span>Delete</span>
        </button>
        <button
          appButton
          big
          accent
          (click)="loadSelectedRow()"
          [matTooltip]="disabledTooltip"
          [disabled]="
            !activeRow ||
            (activeRow && addedCurveIds && addedCurveIds.includes(activeRow.id))
          "
        >
          <span>Load</span>
        </button>
      </div>
      <div *ngIf="usageContext === 'technical-premium'">
        <button
          appButton
          big
          accent
          [matTooltip]="disabledTooltip"
          [disabled]="
            !activeRow ||
            (activeRow && addedCurveIds && addedCurveIds.includes(activeRow.id))
          "
          (click)="addPricingCurveToTechnicalPremiumCalculation()"
        >
          <span>Add</span>
        </button>
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="sidebar-container">
      <app-pricing-curve-saved-curve-filter-bar
        [savedCurveSelectors]="savedCurveSelectors"
        [status]="status"
      ></app-pricing-curve-saved-curve-filter-bar>
    </div>
    <div class="list-container">
      <div class="table" *ngIf="!status?.dialogLoading; else loadingTemplate">
        <app-sort-table
          [columnDefs]="savedCurveColumns"
          [rows]="savedCurveRows"
          [paginator]="paginator"
          enableRowClickSelect
          singleRowSelect
          (selectedChange)="selectedRowChanged($event)"
        ></app-sort-table>
      </div>
      <ng-template #loadingTemplate>
        <div class="loading-spinner">
          <mat-spinner
            *ngIf="status?.dialogLoading"
            [diameter]="24"
            color="accent"
          ></mat-spinner>
        </div>
      </ng-template>
      <mat-paginator
        class="paginator"
        showFirstLastButtons
        pageSize="30"
        [pageSizeOptions]="[15, 30, 50]"
      ></mat-paginator>
    </div>
  </div>
</div>
