<div class="configTower">
  <div class="name" *ngIf="showControls && !loading">
    <h2>{{ name }}</h2>
  </div>
  <div class="controls" *ngIf="showControls && !loading">
    <mat-checkbox [(ngModel)]="showAgg" (change)="onShowAggChange($event)">
      Show Agg
    </mat-checkbox>
  </div>
</div>

<div class="towers" tabindex="0" #towerContainer>
  <div [hidden]="!showOcc">
    <svg
      id="occurrence"
      [attr.height]="svgheight"
      transform="translate(0, 0)"
      [ngStyle]="{ width: svgwidth + 'px' }"
    >
      <g class="bar-chart" [attr.transform]="towerTransform"></g>
    </svg>
  </div>
  <div [hidden]="!showAgg">
    <svg
      id="aggregate"
      [attr.height]="svgheight"
      transform="translate(0, 0)"
      [ngStyle]="{ width: svgwidth + 'px' }"
    >
      <g class="bar-chart" [attr.transform]="towerTransform"></g>
    </svg>
  </div>
</div>
