import { Component, Inject, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import {
  RiskRefDetail,
  RiskRefDetailGroup,
} from 'src/app/api/model/signature.model'

@Component({
  selector: 'app-signature-select-riskref-dialog-component',
  templateUrl: './signature-select-riskref-dialog.html',
  styles: [
      `
        :host {
          width: 60vw;
        }
        :host ::ng-deep input {
          color: white !important;
          border-bottom: 1px solid #FFFFFF80 !important;
          margin-bottom: 12px;
          padding-bottom: 6px;
        }
        :host ::ng-deep div {
          color: white !important;
        }
        h1 {
          color: white !important;
          font-size: 20px !important;
          font-weight: bold;
          margin-bottom: 24px;
        }
        :host ::ng-deep .mdc-text-field {
          padding-left: 0px !important;
        }
        button {
          margin-left: 20px;
        }
      `
    ]
})
export class SignatureSelectRiskRefDialogComponent implements OnInit {
  riskRefControl = new FormControl()
  selectedRiskRef: string | undefined
  filteredRiskRefs: Observable<RiskRefDetailGroup[]>

  constructor(
    public dialogRef: MatDialogRef<SignatureSelectRiskRefDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      renewalRiskRefs: RiskRefDetail[]
    }
  ) {}

  ngOnInit(): void {
    this.filteredRiskRefs = this.riskRefControl.valueChanges.pipe(
      startWith(''),
      map(inputVal =>
        inputVal
          ? this.filterRiskRefs(inputVal)
          : this.groupRiskRefs(this.data.renewalRiskRefs.slice())
      )
    )
  }

  onSelectedRiskRefOption(option: string) {
    this.riskRefControl.setValue('')

    this.dialogRef.close(option)
  }

  filterRiskRefs(inputVal: string): RiskRefDetailGroup[] {
    const risks = this.data.renewalRiskRefs.filter(
      riskref =>
        riskref.clientName?.toLowerCase().indexOf(inputVal.toLowerCase()) >=
          0 ||
        riskref.riskRef?.toLowerCase().indexOf(inputVal.toLowerCase()) >= 0 ||
        riskref.riskNarrative?.toLowerCase().indexOf(inputVal.toLowerCase()) >=
          0
    )
    return this.groupRiskRefs(risks)
  }
  groupRiskRefs(riskRefs: RiskRefDetail[]): RiskRefDetailGroup[] {
    const riskRefGrouping: RiskRefDetailGroup[] = []

    riskRefs.forEach(rr => {
      const g = riskRefGrouping.find(x => x.groupName === rr.clientName)
      if (g) {
        g.risks.push(rr)
      } else {
        riskRefGrouping.push({ groupName: rr.clientName, risks: [rr] })
      }
    })

    return riskRefGrouping
  }
}
