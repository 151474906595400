import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../core/store'
import { combineLatest, Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { StructureLayerDataResponse } from '../../api/model/backend.model'
import { Program } from '../../core/model/program.model'
import {
  setDirtyProgram,
  updateDirtyProgram,
} from '../../core/store/program/program.actions'
import { selectDirtyProgram } from '../../core/store/program/program.selectors'
import { analyzereConstants } from '@shared/constants/analyzere'
import { Metadata, MonetaryUnit } from '../../api/analyzere/analyzere.model'
import {
  setAggIncrementsY,
  setAggMaxY,
  setAggMaxYDirty,
  setAggMost,
  setOccIncrementsY,
  setOccIncrementsYDirty,
  setOccMaxY,
  setOccMaxYDirty,
  setOccMost,
} from '../../core/store/program/program.actions'
import { layerIds, LayerPaletteItem } from '../model/layer-palette.model'
import { Layer, PhysicalLayer } from '../model/layers.model'
import { filterTowerLayers, getMultiSectionData } from '../model/layers.util'
import { LossSetLayer } from '../model/loss-set-layers.model'
import { PortfolioSetID, SharedIDPortfolio } from '../model/portfolio-set.model'
import { imageUpload, layerDataUpload } from '../store/analysis.actions'
import * as fromSelectors from '../store/analysis.selectors'
import { selectCurrentProgram } from '../store/analysis.selectors'
import {
  addLayer,
  setSelectedLayer,
  updateLayer,
  updatePhysicalLayer,
} from '../store/ceded-layers/layers.actions'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { AggFeederProperties, CurrencyRate } from './mechanics/tower.model'
import {
  selectCurrencyRates,
  selectCurrentAnalysisProfile,
} from '../../core/store/broker/broker.selectors'
import { fetchSavedCurves } from 'src/app/pricingcurve/store/pricing-curve.actions'
import { InuranceTag, InuranceTagsByLevel } from '../model/inurance.model'
import { initLayerTypeValues } from '../store/technical-premium/technical-premium.actions'
import { LayerService } from '@shared/services/layer.service'
import { isMultiSectionLayer } from '../layers/multi-section-layer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tower-container',
  templateUrl: './tower.container.html',
})
export class TowerContainerComponent implements OnInit, OnDestroy {
  portfolioIDs$: Observable<PortfolioSetID | null>
  layers$: Observable<LayerState[]>
  allLayers$: Observable<LayerState[]>
  tdLayers$: Observable<LayerState[]>
  selected$: Observable<string | null>
  selectedId: string
  layers: LayerState[]
  hiddenLayers$: Observable<LayerState[]>
  lossSetLayers$: Observable<LossSetLayer[]>
  currentProgram$: Observable<Program | undefined>
  structureLayerData$: Observable<StructureLayerDataResponse[] | undefined>
  sharedIDPortfolio$: Observable<SharedIDPortfolio[]>
  showAgg$: Observable<boolean>
  showOcc$: Observable<boolean>
  showZoom$: Observable<number>
  activeAction$: Observable<string>
  incrementsYOcc$: Observable<number | null>
  incrementsYDirtyOcc$: Observable<boolean | null>
  maxYOcc$: Observable<number | null>
  maxYDirtyOcc$: Observable<boolean | null>
  mostOcc$: Observable<number | null>
  logMin$: Observable<number | null>
  log$: Observable<boolean | null>
  snapping$: Observable<boolean | null>
  incrementsYAgg$: Observable<number | null>
  incrementsYDirtyAgg$: Observable<boolean | null>
  maxYAgg$: Observable<number | null>
  maxYDirtyAgg$: Observable<boolean | null>
  mostAgg$: Observable<number | null>
  currentCurrency: string
  currencyRates$: Observable<CurrencyRate[]>
  analysisProfileCurrency: string
  inuranceTagsByLevel$: Observable<InuranceTagsByLevel>
  showLoading$: Observable<boolean>

  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    private layerService: LayerService
  ) {}

  ngOnInit(): void {
    this.showLoading$ = combineLatest([
      this.store.pipe(select(fromSelectors.selectCededPortfolioLoading)),
      this.store.pipe(select(fromSelectors.selectTechnicalPremiumIsLoading)),
    ]).pipe(
      map(
        ([portfolioLoading, technicalPremiumLoading]) =>
          portfolioLoading || technicalPremiumLoading
      )
    )
    this.currentProgram$ = this.store.pipe(select(selectCurrentProgram))
    this.structureLayerData$ = combineLatest([
      this.store.pipe(select(selectCurrentProgram)),
      this.store.pipe(select(selectDirtyProgram)),
    ]).pipe(
      map(([currentProgram, dirtyProgram]) => {
        if (
          dirtyProgram !== null &&
          dirtyProgram.layerData &&
          dirtyProgram.layerData.length > 0
        ) {
          return dirtyProgram.layerData
        } else if (currentProgram) {
          return currentProgram.layerData
        } else {
          return []
        }
      })
    )

    this.portfolioIDs$ = this.store.pipe(
      select(fromSelectors.selectEditorPortfolioSetID)
    )

    this.selected$ = this.store.pipe(
      select(fromSelectors.selectCededSelectedLayer)
    )
    this.selected$.subscribe(a => {
      this.selectedId = String(a)
    })

    this.allLayers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => layers.filter(l => !l.deleted))
    )

    this.logMin$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesLogMin)
    )
    this.log$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesLog)
    )
    this.snapping$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesSnapping)
    )

    this.layers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => {
        const sortLayers = layers.filter(filterTowerLayers)
        const sortLayersWithoutIncompleteMS = sortLayers.filter(layer => {
          // Any copied multisection layer must wait until it has its sections created before being added to the tower
          return (
            !isMultiSectionLayer(layer.layer) ||
            !layer.layer.meta_data.copiedLayerId ||
            !!getMultiSectionData(
              layers.map(l => l.layer),
              layer.layer
            )
          )
        })
        sortLayersWithoutIncompleteMS.sort((a, b) => {
          const aZOrder = a.layer.meta_data.z_order
            ? a.layer.meta_data.z_order
            : 0
          const bZOrder = b.layer.meta_data.z_order
            ? b.layer.meta_data.z_order
            : 0
          return aZOrder > bZOrder ? 1 : -1
        })
        return sortLayersWithoutIncompleteMS
      })
    )

    this.tdLayers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => {
        return layers.filter(
          l => !l.deleted && l.layer.meta_data.sage_layer_subtype === 'actual'
        )
      })
    )

    this.hiddenLayers$ = this.store.pipe(
      select(fromSelectors.selectCededLayers),
      map((layers: LayerState[]) => {
        return layers.filter(
          l => !l.deleted && l.layer.meta_data.sage_layer_subtype === 'actual'
        )
      })
    )

    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(fromSelectors.selectCededLayers),
        map((layers: LayerState[]) => {
          return layers.filter(l => !l.deleted)
        })
      )
      .subscribe((layers: LayerState[]) => {
        this.layers = layers
      })

    this.lossSetLayers$ = this.store.pipe(
      select(fromSelectors.selectParentGrossLossSetLayers)
    )

    this.sharedIDPortfolio$ = this.store.pipe(
      select(fromSelectors.selectSharedIDPortfolios)
    )

    this.showAgg$ = this.store.pipe(select(fromSelectors.selectShowAgg))

    this.showOcc$ = this.store.pipe(select(fromSelectors.selectShowOcc))

    this.showZoom$ = this.store.pipe(select(fromSelectors.selectZoom))

    this.activeAction$ = this.store.pipe(
      select(fromSelectors.selectEditorActiveAction)
    )

    this.incrementsYOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccIncrementsY)
    )
    this.incrementsYDirtyOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccIncrementsYDirty)
    )
    this.maxYOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMaxY)
    )
    this.maxYDirtyOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMaxYDirty)
    )
    this.mostOcc$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesOccMost)
    )

    this.incrementsYAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggIncrementsY)
    )
    this.incrementsYDirtyAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggIncrementsYDirty)
    )
    this.maxYAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMaxY)
    )
    this.maxYDirtyAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMaxYDirty)
    )
    this.mostAgg$ = this.store.pipe(
      select(fromSelectors.selectPortfolioPropertiesAggMost)
    )
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(fromSelectors.selectCurrentCurrency),
        map(currency => {
          return currency ? currency : 'USD'
        })
      )
      .subscribe((currency: string) => {
        this.currentCurrency = currency
      })

    this.currencyRates$ = this.store.pipe(select(selectCurrencyRates))

    this.store
      .pipe(select(selectCurrentAnalysisProfile))
      .subscribe(analysisProfile => {
        if (analysisProfile) {
          this.analysisProfileCurrency =
            analysisProfile.exchange_rate_profile.exchange_rate_table.base_currency
        }
      })

    this.inuranceTagsByLevel$ = this.store.pipe(
      select(fromSelectors.selectDesignTagsByLevel)
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onInuranceTagClick($event: InuranceTag): void {
    const { terminal, ...tag } = $event
    if (terminal === 'source') {
      return
    }
    if (tag.layerID) {
      this.onSelectedLayerID(tag.layerID)
    }
  }

  onLoadCurves(): void {
    this.store.dispatch(fetchSavedCurves({ useSavedCurveSelectors: false }))
  }

  onLoadInitDefaultConnectors(): void {
    this.store.dispatch(initLayerTypeValues())
  }

  onSetIncrementsYOcc(value: { programID: string; incrementsY: number }): void {
    this.store.dispatch(setOccIncrementsY(value))
  }
  onSetMaxYOcc(value: { programID: string; maxY: number }): void {
    this.store.dispatch(setOccMaxY(value))
  }
  onSetMaxYDirtyOcc(value: { programID: string; dirty: boolean }): void {
    this.store.dispatch(setOccMaxYDirty(value))
  }

  onSetIncrementOccDirty(value: { programID: string; dirty: boolean }): void {
    this.store.dispatch(setOccIncrementsYDirty(value))
  }

  onSetMostOcc(value: { programID: string; most: number }): void {
    this.store.dispatch(setOccMost(value))
  }

  onSetIncrementsYAgg(value: { programID: string; incrementsY: number }): void {
    this.store.dispatch(setAggIncrementsY(value))
  }
  onSetMaxYAgg(value: { programID: string; maxY: number }): void {
    this.store.dispatch(setAggMaxY(value))
  }
  onSetMaxYDirtyAgg(value: { programID: string; dirty: boolean }): void {
    this.store.dispatch(setAggMaxYDirty(value))
  }
  onSetMostAgg(value: { programID: string; most: number }): void {
    this.store.dispatch(setAggMost(value))
  }

  onSelectedLayerID(layerID: string): void {
    if (layerID !== this.selectedId) {
      this.store.dispatch(setSelectedLayer({ id: layerID }))
    }
  }

  onDeleteLayerID(layerID: string): void {
    this.layerService.deleteLayerCheck(layerID)
  }

  onLayerResize(partialPhysicalLayer: Partial<PhysicalLayer>): void {
    if (!partialPhysicalLayer.logicalLayerID) {
      return
    }
    this.store.dispatch(
      updatePhysicalLayer({
        id: partialPhysicalLayer.logicalLayerID,
        change: {
          ...partialPhysicalLayer,
        },
      })
    )
  }

  onSetDirtyStructure(program: Program): void {
    this.store.dispatch(setDirtyProgram(program))
  }

  onSetLayerData(program: Program): void {
    if (program.layerData) {
      const layerData = program.layerData.map(
        ({ id, structure_id, ...item }) => item
      )
      this.store.dispatch(updateDirtyProgram())
      this.store.dispatch(
        layerDataUpload({
          layerData,
        })
      )
    }
  }

  onSetLayer(partialLayer: Partial<Layer>): void {
    // tslint:disable:no-non-null-assertion
    this.store.dispatch(
      updateLayer({
        id: partialLayer.id!,
        change: {
          ...partialLayer,
          meta_data: {
            ...partialLayer.meta_data,
            isChangedInDesign: true,
          },
        },
      })
    )
  }

  onImageUpload(imageData: any): void {
    this.store.dispatch(
      imageUpload({
        imageData,
      })
    )
  }

  onSetTowerPropertiesInitialOcc(value: {
    programID: string
    maxY: number
  }): void {
    this.store.dispatch(
      setOccIncrementsY({
        ...value,
        incrementsY: value.maxY * 0.025,
      })
    )
    this.store.dispatch(
      setOccMaxY({
        ...value,
        maxY: value.maxY,
      })
    )
  }

  onSetTowerPropertiesInitialAgg(value: {
    programID: string
    maxY: number
  }): void {
    this.store.dispatch(
      setAggIncrementsY({
        ...value,
        incrementsY: value.maxY * 0.025,
      })
    )
    this.store.dispatch(
      setAggMaxY({
        ...value,
        maxY: value.maxY,
      })
    )
  }

  get logicalIDTemp(): string {
    return new Date().getTime() + '' + Math.random() * 100000
  }

  onFirstAggLayerAdd($event: AggFeederProperties): void {
    const item: LayerPaletteItem = {
      id:
        $event.feeder_layer.layer.meta_data.sage_layer_type !== undefined
          ? $event.feeder_layer.layer.meta_data.sage_layer_type
          : layerIds.catAg,
      name: 'Aggregate',
      type: 'ag',
      program:
        $event.feeder_layer.layer.meta_data.sage_layer_type ===
        layerIds.noncatAg
          ? 'noncat'
          : $event.feeder_layer.layer.meta_data.sage_layer_type ===
              layerIds.ahlAg
            ? 'ahl'
            : 'cat',
    }
    const metaData: Partial<Metadata> = {
      ...$event.feeder_layer.layer.physicalLayer.meta_data,
      rol_type: 'agg',
      rol: 0.0,
      lossfilter_name: '',
      sage_layer_type: item.id,
      sage_layer_subtype: item.subtype,
      structureID: $event.structureID,
      pricingcurve_is_default: true,
    }
    const logicalIDTemp = this.logicalIDTemp
    const currency = this.currentCurrency
    const monetaryUnit: MonetaryUnit = {
      value: 0,
      currency,
    }
    this.store.dispatch(
      addLayer({
        layer: {
          id: logicalIDTemp,
          meta_data: metaData,
          layerRefs: [$event.feeder_layer.layer.id],
          lossSetFilter: '',
          lossSetLayers: [],
          physicalLayer: {
            logicalLayerID: logicalIDTemp,
            id: this.logicalIDTemp.replace('.', ''),
            type: $event.feeder_layer.layer.physicalLayer.type,
            attachment: monetaryUnit,
            limit: { value: analyzereConstants.unlimitedValue, currency },
            participation: -0.25,
            premium: monetaryUnit,
            fees: [],
            aggregateLimit: monetaryUnit,
            aggregateAttachment: monetaryUnit,
            description: 'New Layer',
            meta_data: metaData,
            reinstatements: [],
            franchise: monetaryUnit,
            xcoord: 0,
            ycoord: 0,
          },
          sharedLayerID: '',
          viewMetrics: {
            error: null,
            loading: false,
            metrics: null,
            rss: null,
          },
        },
      })
    )
  }
}
