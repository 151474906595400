import { nth } from 'ramda'
import { HasName } from '../../core/model/general.model'
import { environment } from '../../../environments/environment'

// Modes

export const benchmarkUSModeID = 'us'
export const benchmarkGlobalModeID = 'global'
export const benchmarkLoRRIModeID = 'lorri'
export const benchmarkReinsuranceSummaryModeID = 'reinsurance-summary'
export const benchmarkRatingModeID = 'rating'
export const benchmarkAMBestModeID = 'am-best'
export const benchmarkSAndPModeID = 's-and-p'
export const benchmarkManagePeersModeID = 'manage-peers'
export const benchmarkUSHealthID = 'us-health'

export const benchmarkSystemModeIDs = [
  benchmarkUSModeID,
  benchmarkGlobalModeID,
  benchmarkLoRRIModeID,
  benchmarkReinsuranceSummaryModeID,
  benchmarkAMBestModeID,
  benchmarkSAndPModeID,
  benchmarkUSHealthID,
] as const

export type BenchmarkSystemModeID = typeof benchmarkSystemModeIDs[number]

export const benchmarkModeIDs = [
  ...benchmarkSystemModeIDs,
  benchmarkRatingModeID,
  benchmarkManagePeersModeID,
] as const

export type BenchmarkModeID = typeof benchmarkModeIDs[number]

export type BenchmarkMode = HasName & {
  id: BenchmarkModeID
  submodes?: string[]
  hasOverview?: boolean
  description?: string
  excludeFromNext?: boolean
  isModeGroup?: boolean
  parentMode?: BenchmarkMode
  useControlsPeers?: boolean
  useLob?: boolean
  lobAllLinesID?: number
}

// Enable once AM Best has live data
// const ratingModeBase: BenchmarkMode = { id: 'rating', name: 'Rating Agency' }

const benchmarkModes: BenchmarkMode[] = [
  {
    id: 'us',
    name: 'U.S.',
    hasOverview: false,
    submodes: [
      'financial-overview',
      'summary-stat',
      'underwriting-stat',
      'performance',
      'state',
      'market-share',
      'diversification',
      'growth-stat',
      'leverage',
      'reinsurance',
      'mpl',
      // Enable once tabs have live data
      // 'reserving',
      // 'investments',
      // 'combo',
      // 'iris',
      // 'compare',
    ],
    useLob: true,
    lobAllLinesID: 140,
    useControlsPeers: true,
  },
  {
    id: 'reinsurance-summary',
    name: 'Reinsurance Summary',
    hasOverview: false,
    submodes: ['disclosure', 'spend-and-efficiency', 'peer-limit-and-retention'],
  },
  // Remove?
  {
    id: 'global',
    name: 'Global',
    hasOverview: false,
    useControlsPeers: true,
    submodes: [
      'growth',
      'valuation',
      'roe',
      'earnings',
      'underwriting',
      'reserves',
      // Temporarily disabled
      // 'surprise',
    ],
    description:
      'Global view of company growth, earnings, valuation, volatility, and performance over time and compared to peers.',
  },
  {
    id: 'lorri',
    name: 'Lockton Re Rate Index',
    hasOverview: false,
    useControlsPeers: true,
    submodes: ['lorri'],
  },
  { id: 'manage-peers', name: 'Manage Peers', excludeFromNext: true },
  {
    id: 'us-health',
    name: 'U.S. Health Benchmarking',
    hasOverview: false,
    submodes: ['financial-overview-stat-health', 'underwriting-stat-health'],
    useLob: true,
    lobAllLinesID: 140,
    useControlsPeers: true,
  },
]

const nonProductionModes: Record<string, boolean> = {}

const nonProductionSubmodes: Record<string, boolean> = {
  'summary-stat': true,
  'peer-limit-and-retention': true
}

function getBenchmarkModes(): BenchmarkMode[] {
  return benchmarkModes
    .filter(mode => !environment.production || !nonProductionModes[mode.id])
    .map(mode => {
      if (!mode.submodes) {
        return mode
      }
      const submodes = mode.submodes.filter(
        submode => !environment.production || !nonProductionSubmodes[submode]
      )
      return { ...mode, submodes }
    })
}

const modesByID = getBenchmarkModes().reduce((acc, m) => {
  acc[m.id] = m
  return acc
}, {} as Record<string, BenchmarkMode | null>)

export function getBenchmarkMode(
  modeID?: BenchmarkModeID | null
): BenchmarkMode | null {
  return modeID && modesByID[modeID] ? modesByID[modeID] : null
}

// Functions

interface NextModeLookup {
  indexByIDs: Record<string, number>
  list: (readonly [BenchmarkModeID, string | null])[]
}

const buildModeLookupID = (
  modeID?: BenchmarkModeID | null,
  submodeID?: string | null
): string => `${modeID ?? ''}_${submodeID ?? ''}`

const nextModeLookup = getBenchmarkModes()
  .filter(mode => !mode.excludeFromNext)
  .reduce(
    (outerAcc, mode) => {
      let i: number
      if (mode.hasOverview) {
        i = outerAcc.list.length
        outerAcc.indexByIDs[buildModeLookupID(mode.id)] = i
        outerAcc.list.push([mode.id, null])
      }

      return (mode.submodes ?? []).reduce((acc, submodeID) => {
        i = acc.list.length
        acc.indexByIDs[buildModeLookupID(mode.id, submodeID)] = i
        acc.list.push([mode.id, submodeID])

        return acc
      }, outerAcc)
    },
    { indexByIDs: {}, list: [] } as NextModeLookup
  )

export function getNextBenchmarkMode(
  modeID?: BenchmarkModeID | null,
  submodeID?: string | null,
  step = 1
): readonly [BenchmarkModeID, string | null] {
  const lookupID = buildModeLookupID(modeID, submodeID)
  const prevIndex = nextModeLookup.indexByIDs[lookupID]
  const nextIndex = (prevIndex + step) % nextModeLookup.list.length
  // tslint:disable-next-line: no-non-null-assertion
  return nth(nextIndex, nextModeLookup.list)!
}

export const getBenchmarkSystemMode = (
  modeID?: BenchmarkModeID | null,
  managePeerSetsModeID?: BenchmarkModeID
): BenchmarkSystemModeID =>
  ((modeID === 'manage-peers' ? managePeerSetsModeID : modeID) ??
    'us') as BenchmarkSystemModeID

export default getBenchmarkModes
