import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TierService {
  groupPopupDelay$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  setDelayDialogPopup(val: boolean): void {
    this.groupPopupDelay$.next(val)
  }

  getDelayDialogPopup(): Observable<boolean> {
    return this.groupPopupDelay$.asObservable()
  }
}
