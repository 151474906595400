import { lensProp, prop, set, uniq, view } from 'ramda'
import {
  TechnicalFactors,
  PricingCurveLayer,
  PricingCurveExtents,
  PricingCurveDataPoint,
  BasicControl,
  CombinedSelectors,
  PricingCurveTypes,
  SavedCurveSelectors,
  Selectors,
  IIntervalFilter,
  PricingCurveData,
  CreditCurveLayer,
  PricingCurveContextTypes,
  CreditSelectors,
  LayerProperties,
  TerritoryMappings,
} from './model/pricing-curve.model'
import { graphingColorPaletteExtended } from '@graphing/utils/graphing-color-palette'
import { IControl } from '../management-information/store/management-information.reducer'

const colors = [...graphingColorPaletteExtended.slice(0, 11), 'gray']

export const onlyUnique = (value: string, index: number, array: string[]) => {
  return value && array.indexOf(value) === index
}

export function filterSelectorsForLayerRequest(
  selectors: Selectors
): IControl[] {
  const selectorValues: IControl[] = Object.values(selectors)
  return selectorValues.filter(
    selector =>
      selector.selectedValues.length > 0 && selector.id !== 'layerType'
  )
}

export function updateSelectors(
  selectors: Selectors | null,
  newValues: Record<string, string[]> | undefined
): Selectors | null {
  if (!selectors) {
    return null
  } else if (!newValues) {
    return selectors
  }
  const newSelectors: Selectors = {
    ...selectors,
  }
  for (const [selectorKey, selector] of Object.entries(newSelectors)) {
    newSelectors[selectorKey as keyof Selectors] = {
      ...selector,
      selectedValues: newValues[selectorKey] ?? [],
      values: selector.allValues,
    }
  }
  return newSelectors
}

export function resetDateInterval(
  dateIntervals: BasicControl[] | null,
  columnName: string,
  isMin: boolean
): BasicControl[] | null {
  if (!dateIntervals) {
    return null
  }
  const newVal: IIntervalFilter = {
    filterId: columnName,
    newMinValue: undefined,
    newMaxValue: undefined,
  }
  return updateDateInterval(dateIntervals, newVal, isMin)
}

export function updateDateInterval(
  dateIntervals: BasicControl[] | null,
  update: IIntervalFilter,
  isMin: boolean
): BasicControl[] | null {
  if (!dateIntervals) {
    return null
  }
  const intervalIndex = dateIntervals.findIndex(
    interval => interval.columnName === update.filterId
  )
  if (intervalIndex >= 0) {
    const interval = dateIntervals[intervalIndex]
    const newVal: BasicControl = {
      ...interval,
      minValue: isMin ? update.newMinValue : interval.minValue,
      maxValue: !isMin ? update.newMaxValue : interval.maxValue,
    }
    const newList = [...dateIntervals]
    newList.splice(intervalIndex, 1, newVal)
    return newList
  }
  return null
}

export const resetSelector = (
  selectors: Selectors | null,
  columnName: string
): Selectors | null => {
  if (!selectors) {
    return null
  }
  const savedSelector = (selectors as any)[columnName] as IControl
  const newState: Selectors = { ...selectors }

  newState[columnName as keyof Selectors] = {
    ...savedSelector,
    selectedValues: [],
    values: savedSelector.allValues,
  }

  return newState
}

export const resetSelectors = (state: Selectors): Selectors => {
  const entries: [string, string[]][] = Object.entries(state)
  const newState: any = {}

  for (const [selectorKey] of entries) {
    const selectorFromState = (state as any)[selectorKey] as IControl
    newState[selectorKey as keyof Selectors] = {
      ...selectorFromState,
      selectedValues: [],
      values: selectorFromState.allValues,
    }
  }

  return newState as Selectors
}

export const resetDateIntervals = (
  dateIntervals: BasicControl[]
): BasicControl[] =>
  dateIntervals.map(interval => ({
    ...interval,
    value: '',
    minValue: '',
    maxValue: '',
  }))

export function clearAllFilters(
  selectors: Selectors | null,
  dateIntervals: BasicControl[] | null
): CombinedSelectors | null {
  if (selectors && dateIntervals) {
    return {
      selectors: resetSelectors(selectors),
      dateIntervals: resetDateIntervals(dateIntervals),
    }
  }
  return null
}

export function getSavedCurveSelectorsKvpFromSelectors(
  selectors: SavedCurveSelectors,
  nameMap: Record<string, string>
): Record<string, string | string[]> {
  const { updateDate, fullName, ...restFilters } = selectors
  const emailNameValues = {
    ...fullName,
    columnName: 'updatedBy',
    selectedValues: fullName.selectedValues.map(name => nameMap[name]),
  }
  return getKeyValuePairs(
    [updateDate],
    Object.values(restFilters).concat([emailNameValues])
  )
}

export function getCreditSelectorValueFromCreditLayer(
  layer: CreditCurveLayer,
  key: string
): string {
  const creditKey = key as keyof CreditSelectors
  return view(lensProp(creditKey), layer)?.toString() ?? ''
}

export function setCreditSelector(
  creditSelectors: CreditSelectors,
  newVal: IControl,
  key: string
): CreditSelectors {
  const creditKey = key as keyof CreditSelectors
  return set(lensProp(creditKey), newVal, creditSelectors)
}

export function getSelectorFromCreditSelectors(
  creditSelectors: CreditSelectors,
  key: string
): IControl {
  const creditKey = key as keyof CreditSelectors
  return view(lensProp(creditKey), creditSelectors)
}

export function buildCreditSelectorsFromLayers(
  curve: PricingCurveData
): PricingCurveData {
  // credit pricing curve selectors are built from the layer response data, other pc contexts have their own endpoint
  if (
    !curve.creditSelectors ||
    !curve.includedLayerIds ||
    !curve.includedLayerIds.length
  ) {
    return curve
  }
  let creditSelectors = { ...curve.creditSelectors }
  Object.entries(creditSelectors).map(([key, val]) => {
    const layerValues = uniq(
      curve.creditLayers.map(layer =>
        getCreditSelectorValueFromCreditLayer(layer, key)
      )
    ).filter(val => !!val)
    const newVal: IControl = {
      ...val,
      values: layerValues,
      allValues: layerValues,
    }
    creditSelectors = setCreditSelector(creditSelectors, newVal, key)
  })
  return {
    ...curve,
    creditSelectors,
  }
}

export function buildCreditSelectorsOnCurveInit(
  filteredCreditLayers: CreditCurveLayer[] | undefined,
  allCreditLayers: CreditCurveLayer[] | undefined,
  selectors: CreditSelectors | null
): CreditSelectors | null {
  if (!filteredCreditLayers || !allCreditLayers || !selectors) {
    return null
  }
  let creditSelectors = { ...selectors }
  Object.entries(creditSelectors).map(([key, val]) => {
    const hasSelection = !!val.selectedValues.length
    const layersToUse = hasSelection ? allCreditLayers : filteredCreditLayers
    const layerValues = uniq(
      layersToUse.map(layer =>
        getCreditSelectorValueFromCreditLayer(layer, key)
      )
    ).filter(val => !!val)

    const newVal: IControl = {
      ...val,
      values: layerValues,
      allValues: layerValues,
    }
    creditSelectors = setCreditSelector(creditSelectors, newVal, key)
  })
  return creditSelectors
}

export function filterCreditLayers(
  layers: CreditCurveLayer[] | undefined,
  selectors: CreditSelectors | null | undefined
): CreditCurveLayer[] {
  if (!layers || !selectors) {
    return []
  }
  const selectorValues = Object.values(selectors) as IControl[]
  const noneSelected = !selectorValues.some(val => val.selectedValues.length)
  if (noneSelected) {
    return layers
  }
  return layers.filter(layer => {
    const filtersWithSelections = selectorValues.filter(
      selector => !!selector.selectedValues.length
    )
    return filtersWithSelections.every(filter => {
      const layerValue: string | number = view(
        lensProp(filter.id as keyof CreditSelectors),
        layer
      )
      return filter.selectedValues.includes(layerValue.toString())
    })
  })
}

export function updateLayerStatusForCurve(
  curve: PricingCurveData,
  layers: PricingCurveLayer[] | CreditCurveLayer[],
  context: PricingCurveContextTypes
): PricingCurveData {
  if (!curve.includedLayerIds || !curve.includedLayerIds.length) {
    return curve
  }

  if (context === 'credit') {
    return {
      ...curve,
      creditLayers: (layers as CreditCurveLayer[]).map(layer => ({
        ...layer,
        include:
          curve.includedLayerIds?.includes(layer.trancheId.toString()) ?? false,
      })),
    }
  }

  const includedLayerIds = curve.includedLayerIds ?? []
  const updatedLayers = (layers as PricingCurveLayer[]).map(layer => {
    const props = layer.props
    const layerId = props.hist_data_id
      ? `${props.hist_data_id}`
      : `${props.rr_id}-${props.ral_id}`
    return {
      ...layer,
      include: includedLayerIds.indexOf(layerId) >= 0,
    }
  })

  return {
    ...curve,
    layers: updatedLayers,
  }
}

export function manuallyApplySelectorsToLayers(
  layers: PricingCurveLayer[],
  { selectors, dateIntervals }: CombinedSelectors
): PricingCurveLayer[] {
  const nonHistoricalLayers = layers.filter(
    ({ props }) => props.hist_data_id === null
  )
  const historicalLayers = layers.filter(
    layer => !nonHistoricalLayers.includes(layer)
  )
  let filteredNonHistoricalLayers = [...nonHistoricalLayers]
  let filteredHistoricalLayers = [...historicalLayers]
  const filterLayersByProp = (
    layers: PricingCurveLayer[],
    propKey: keyof LayerProperties,
    selectedValues: string[]
  ): PricingCurveLayer[] =>
    layers.filter(({ props }) =>
      selectedValues.length === 1 && selectedValues[0] === 'All'
        ? view(lensProp(propKey), props) !== null
        : selectedValues.includes(view(lensProp(propKey), props) as string)
    )

  const filterLayerBySemiColonProp = (
    layers: PricingCurveLayer[],
    propKey: keyof LayerProperties,
    selectedValues: string[]
  ): PricingCurveLayer[] =>
    layers.filter(({ props }) => {
      const val = view(lensProp(propKey), props)
      if (!!val && String(val).length) {
        const splitVal = String(val)
          .split(';')
          .map(sVal => sVal.toLowerCase())
        return selectedValues.some(selVal =>
          splitVal.includes(selVal.toLowerCase())
        )
      }
    })
  Object.entries(selectors).forEach(([selectorKey, val]) => {
    const key = selectorKey as keyof Selectors
    let selectedValues = (val as IControl).selectedValues
    if (selectedValues.length) {
      switch (key) {
        case 'layerCategory':
        case 'clientName':
        case 'reinsurerName':
        case 'correspondentBroker':
        case 'status':
        case 'lossImpactedFromPrevYear':
        case 'pcClass':
        case 'pcSubClass':
        case 'placedThrough':
          filteredNonHistoricalLayers = filterLayersByProp(
            filteredNonHistoricalLayers,
            key,
            selectedValues
          )
          filteredHistoricalLayers = filterLayersByProp(
            filteredHistoricalLayers,
            key,
            selectedValues
          )
          break
        case 'perils':
        case 'territory':
          if (key === 'territory') {
            selectedValues = [...selectedValues].reduce((acc, val) => {
              if (TerritoryMappings[val]?.length) {
                acc.push(...TerritoryMappings[val])
              }
              return acc
            }, [])
          } else {
            if (
              selectedValues.includes('ANP') ||
              (selectedValues.includes('Non-Natural') &&
                !selectedValues.includes('ANP & Non-Natural'))
            ) {
              selectedValues.push('ANP & Non-Natural')
            }
            if (
              selectedValues.includes('Windstorm') ||
              (selectedValues.includes('Earthquake') &&
                !selectedValues.includes('WS+EQ'))
            ) {
              selectedValues.push('WS+EQ')
            }
          }
          filteredNonHistoricalLayers = filterLayerBySemiColonProp(
            filteredNonHistoricalLayers,
            key,
            selectedValues
          )
          filteredHistoricalLayers = filterLayerBySemiColonProp(
            filteredHistoricalLayers,
            key,
            selectedValues
          )
          break
        case 'ignoreForPricingCurve':
          const ignoreFilter = (layers: PricingCurveLayer[]) =>
            layers.filter(
              ({ props }) =>
                (props.ignoreForPricingCurve === null &&
                  selectedValues.includes(props.ignoreForPricingCurve)) ||
                (selectedValues.includes('N') &&
                  props.ignoreForPricingCurve === null)
            )
          filteredNonHistoricalLayers = ignoreFilter(
            filteredNonHistoricalLayers
          )
          filteredHistoricalLayers = ignoreFilter(filteredHistoricalLayers)
          break
        case 'reinstatement':
          filteredNonHistoricalLayers = filteredNonHistoricalLayers.filter(
            ({ props }) => {
              const reSSCheck =
                selectedValues.includes('SS') &&
                props.aggregatedRipPct === null &&
                props.occurrenceLimit === props.aggregateLimit
              const regCheck =
                props.aggregatedRipPct === null &&
                selectedValues.some(val =>
                  props.aggregatedRipPct.includes(`${val}@`)
                )
              return reSSCheck || regCheck
            }
          )
          filteredHistoricalLayers ==
            filteredHistoricalLayers.filter(({ props }) => {
              const reSSCheck =
                selectedValues.includes('SS') &&
                props.aggregatedRipPct === null &&
                props.aggregatedRipPct === 'SS'
              const regCheck =
                props.aggregatedRipPct !== null &&
                selectedValues.some(val =>
                  props.aggregatedRipPct.includes(`${val}@`)
                )
              return reSSCheck || regCheck
            })
          break
      }
    }
  })

  const inceptionDateFilter = dateIntervals[0]
  if (inceptionDateFilter.minValue) {
    filteredNonHistoricalLayers = filteredNonHistoricalLayers.filter(
      ({ props }) =>
        new Date(props.inceptionDate) >= new Date(inceptionDateFilter.minValue)
    )
    filteredHistoricalLayers = filteredHistoricalLayers.filter(
      ({ props }) =>
        new Date(props.inceptionDate) >= new Date(inceptionDateFilter.minValue)
    )
  }
  if (inceptionDateFilter.maxValue) {
    filteredNonHistoricalLayers = filteredNonHistoricalLayers.filter(
      ({ props }) =>
        new Date(props.inceptionDate) <= new Date(inceptionDateFilter.maxValue)
    )
    filteredHistoricalLayers = filteredHistoricalLayers.filter(
      ({ props }) =>
        new Date(props.inceptionDate) <= new Date(inceptionDateFilter.maxValue)
    )
  }
  return [...filteredHistoricalLayers, ...filteredNonHistoricalLayers]
}

export function truncToDecimal(
  inputNumber: number,
  decimals: number,
  maxFieldValue: number
): number {
  const newVal = Number.parseFloat(inputNumber.toFixed(decimals))
  if (Math.abs(newVal) > maxFieldValue) {
    return newVal < 0 ? -maxFieldValue : maxFieldValue
  } else {
    return newVal
  }
}

export function fitLineByCurveType(
  intercept: number,
  slope: number,
  minX: number,
  maxX: number,
  curveType: PricingCurveTypes
): PricingCurveDataPoint[] {
  switch (curveType) {
    case PricingCurveTypes.EL_LINEAR: {
      return fitElLinear(intercept, slope, minX, maxX)
    }
    case PricingCurveTypes.EL_POWER: {
      return fitElPower(intercept, slope, minX, maxX)
    }
    default: {
      return fitElPower(intercept, slope, minX, maxX)
    }
  }
}

export function getCurveTypeFromTechFactors(techFactors: TechnicalFactors) {
  return techFactors.expected_loss_power === 1
    ? PricingCurveTypes.EL_LINEAR
    : PricingCurveTypes.EL_POWER
}

export function RGBAToHex(rgba: number[], includeA: boolean) {
  let newR = rgba[0].toString(16)
  let newG = rgba[1].toString(16)
  let newB = rgba[2].toString(16)
  let newA = Math.round(rgba[3] * 255).toString(16)

  newR = newR.length === 1 ? '0' + newR : newR
  newG = newG.length === 1 ? '0' + newG : newG
  newB = newB.length === 1 ? '0' + newB : newB
  newA = newA.length === 1 ? '0' + newA : newA

  return `${newR + newG + newB}${includeA ? newA : ''}`
}

export function getColorClass(totalDatasets: number, usedColors: string[]) {
  const max = 12
  let availableColors = colors.filter(value => usedColors.indexOf(value) < 0)
  if (totalDatasets === max - 1) {
    availableColors = colors
  }
  return `app-palette-${availableColors[0]}`
}

export function updateCurveDataProperties(
  curves: PricingCurveData[],
  searchId: number,
  curveUpdate: Partial<PricingCurveData>,
  applyAll?: boolean
): PricingCurveData[] {
  const newCurves = [...curves]
  if (!applyAll) {
    const editCurveIndex = newCurves.findIndex(curve => curve.id === searchId)
    if (editCurveIndex >= 0) {
      const curve = newCurves[editCurveIndex]
      newCurves[editCurveIndex] = {
        ...curve,
        ...curveUpdate,
      }
    }
  } else {
    newCurves.forEach((curve, index) => {
      newCurves[index] = {
        ...curve,
        ...curveUpdate,
      }
    })
  }
  return newCurves
}

export function updateTechFactorsByCurveType(
  slope: number,
  intercept: number,
  isPower: boolean,
  techFactors: TechnicalFactors,
  newMinRateOnLine: number,
  newMaxRateOnLine: number,
  initialSetup: boolean
): TechnicalFactors {
  if (initialSetup) {
    return {
      expected_loss_multiplier: isPower ? intercept : slope,
      expected_loss_power: isPower ? slope : 1,
      fixed_cost: isPower ? 0 : intercept,
      max_ceding_commission_percentage: 0,
      minimum_rate_on_line: newMinRateOnLine,
      maximum_rate_on_line: newMaxRateOnLine,
      reinsurer_margin_percentage: 0,
      volatility_multiplier: 0,
      volatility_metric: 'Ceded Standard Deviation',
    }
  }
  return {
    ...techFactors,
    minimum_rate_on_line: newMinRateOnLine ?? techFactors.minimum_rate_on_line,
    maximum_rate_on_line: newMaxRateOnLine ?? techFactors.maximum_rate_on_line,
    expected_loss_multiplier: isPower ? intercept : slope,
    expected_loss_power: isPower ? slope : techFactors.expected_loss_power,
    fixed_cost: isPower ? techFactors.fixed_cost : intercept,
  }
}

export const getKeyValuePairs = (
  intervals: BasicControl[],
  filters: IControl[],
  useSplitView?: boolean
): Record<string, string | string[]> => {
  let kvp: Record<string, string | string[]> = {}

  filters?.forEach((control: IControl) => {
    if (control.selectedValues) {
      kvp = {
        ...kvp,
        [control.columnName]:
          control.columnName === 'reinsurerName' &&
          control.selectedValues.length === control.allValues.length &&
          !!control.selectedValues.length && !!control.allValues.length
            ? ['All']
            : control.selectedValues,
      }
    }
  })

  intervals.forEach((interval: BasicControl) => {
    if (!!interval?.minValue) {
      const intervalMinName = `${interval.columnName}Min`
      kvp = {
        ...kvp,
        [intervalMinName]: interval.minValue.split('/').join('-'),
      }
    }

    if (!!interval?.maxValue) {
      const intervalMaxName = `${interval.columnName}Max`
      kvp = {
        ...kvp,
        [intervalMaxName]: interval.maxValue.split('/').join('-'),
      }
    }
  })
  if (typeof useSplitView !== 'undefined') {
    kvp = {
      ...kvp,
      combined: `${!useSplitView}`,
    }
  }

  return kvp
}

export const fitMinAndMaxXsAtGivenYs = (
  minY: number,
  maxY: number,
  intercept: number,
  slope: number,
  isPower: boolean
): [number, number] => {
  return [
    fitXAtGivenY(minY, intercept, slope, isPower),
    fitXAtGivenY(maxY, intercept, slope, isPower),
  ]
}

export const fitXAtGivenY = (
  yValue: number,
  intercept: number,
  slope: number,
  isPower: boolean
): number => {
  if (isPower) {
    return Math.pow(yValue / intercept, 1 / slope)
  } else {
    return (yValue - intercept) / slope
  }
}

export const fitYAtGivenX = (
  xValue: number,
  intercept: number,
  slope: number,
  isPower: boolean
): number => {
  if (isPower) {
    return intercept * Math.pow(xValue, slope)
  } else {
    return intercept + xValue * slope
  }
}

export const fitElPower = (
  intercept: number,
  slope: number,
  minBound: number,
  maxBound: number
): PricingCurveDataPoint[] => {
  const dataPrecision = 100
  const deltaX = (maxBound - minBound) / dataPrecision
  const lineData: PricingCurveDataPoint[] = []

  let currentX = minBound
  for (let i = 0; i < dataPrecision + 1; i++) {
    lineData.push({
      x: currentX,
      y: fitYAtGivenX(currentX, intercept, slope, true),
      isLine: true,
      id: i,
    })
    currentX += deltaX
  }

  return lineData
}

export const fitElLinear = (
  intercept: number,
  slope: number,
  minBound: number,
  maxBound: number
) => {
  const dataPrecision = 100
  const deltaX = (maxBound - minBound) / dataPrecision
  const lineData: PricingCurveDataPoint[] = []

  let currentX = minBound
  for (let i = 0; i < dataPrecision + 1; i++) {
    lineData.push({
      x: currentX,
      y: fitYAtGivenX(currentX, intercept, slope, false),
      isLine: true,
      id: i,
    })
    currentX += deltaX
  }

  return lineData
}

export const getExtentsForDataSet = (
  data: any[],
  xPath: string,
  yPath: string
): PricingCurveExtents => {
  const xAccessor = (layer: any): number => view(lensProp(xPath), layer)
  const yAccessor = (layer: any): number => view(lensProp(yPath), layer)
  return {
    xExtents: {
      min: Math.min(...data.map(item => xAccessor(item))),
      max: Math.max(...data.map(item => xAccessor(item))),
    },
    yExtents: {
      min: Math.min(...data.map(item => yAccessor(item))),
      max: Math.max(...data.map(item => yAccessor(item))),
    },
  }
}

export const leastSquares = (
  layers: any[],
  isPower: boolean = false,
  xPath: string,
  yPath: string
): { slope: number; intercept: number } => {
  const xAccessor = (layer: any): number => view(lensProp(xPath), layer)
  const yAccessor = (layer: any): number => view(lensProp(yPath), layer)
  let data = layers
  if (isPower) {
    data = layers.map(layer => {
      let el = Math.log10(layer.el)
      let trol = Math.log10(layer.trol)

      el = isFinite(el) ? el : 0
      trol = isFinite(trol) ? trol : 0

      return { ...layer, el, trol }
    })
  }

  const xSquareSum = data.reduce((a, b) => a + Math.pow(xAccessor(b), 2), 0)
  const xySum = data.reduce((a, b) => a + xAccessor(b) * yAccessor(b), 0)
  const xSum = data.reduce((a, b) => a + xAccessor(b), 0)
  const ySum = data.reduce((a, b) => a + yAccessor(b), 0)

  const dataSetLength = data.length

  const slope =
    (dataSetLength * xySum - xSum * ySum) /
    (dataSetLength * xSquareSum - Math.pow(xSum, 2))

  const intercept = (ySum - slope * xSum) / dataSetLength

  return { slope, intercept }
}

export const rSquared = (
  layers: any[],
  xPath: string,
  yPath: string
): number | undefined => {
  const xAccessor = (layer: any): number => view(lensProp(xPath), layer)
  const yAccessor = (layer: any): number => view(lensProp(yPath), layer)
  // First calculate sum squared regression
  const sumSquared = layers.reduce((acc, val) => {
    const diff = xAccessor(val) - yAccessor(val)
    return acc + Math.pow(diff, 2)
  }, 0)
  // Calculate the mean of the y set
  const meanOfActuals =
    layers.reduce((acc, val) => {
      return acc + xAccessor(val)
    }, 0) / layers.length
  // Calculate the total sum of squares
  const totalSumOfSquares = layers.reduce((acc, val) => {
    const diff = xAccessor(val) - meanOfActuals
    return acc + Math.pow(diff, 2)
  }, 0)
  return 1 - sumSquared / totalSumOfSquares
}

export const sortArrayByKeys = (values: any[], keys: any[]) => {
  return values.sort((a, b) => keys.indexOf(a[0]) - keys.indexOf(b[0]))
}
