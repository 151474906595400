import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../../core/store'
import { AnalyzreService } from '../../../../api/analyzere/analyzre.service'
import * as fromGroupAnimatedScenariosActions from './group-animated-scenarios.actions'
import * as fromGroupScenarioEventResultsActions from './group-scenario-event-result.actions'
import { withLatestFrom, map, concatMap, mergeMap } from 'rxjs/operators'
import { selectGrouperProgramGroupEntitiesByID } from '../../../store/analysis.selectors'
import { errorPayload } from '../../../../error/model/error'
import { of } from 'rxjs'
import { rejectErrorWithInput, mergeMapWithInput } from '../../../../api/util'
import {
  LogicalPortfolioLayer,
  LossSetLayer,
} from '../../../../api/analyzere/analyzere.model'
import {
  selectGroupID,
  selectAllGroupScenarioEvents,
} from '../animated-scenarios.selectors'
import { AnimatedScenariosService } from '../../../../api/animated-scenarios/animated-scenarios.service'

import { selectProgramGroupsByID } from '../../../../core/store/program-group/program-group.selectors'
import { isLayerActualRisk } from '../../../model/layers.util'

@Injectable()
export class GroupAnimatedScenariosEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private animatedScenarioService: AnimatedScenariosService
  ) {}

  groupExecute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGroupAnimatedScenariosActions.groupExecute),
      withLatestFrom(
        this.store.pipe(select(selectAllGroupScenarioEvents)),
        this.store.pipe(select(selectGroupID)),
        this.store.pipe(select(selectProgramGroupsByID))
      ),
      mergeMapWithInput(([_, events, groupID, _groupsByID]) => {
        return this.animatedScenarioService.processStructureGroupWithSharedLimits(
          groupID || '',
          events
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromGroupAnimatedScenariosActions.groupExecuteFailure({ error })
        )
      ),
      mergeMapWithInput(
        ([
          { cededLayers, portfolioIDs, eventIDs },
          [_, events, groupID, groupsByID],
        ]) => {
          // tslint:disable: no-non-null-assertion
          const structureGroup = groupsByID[groupID!]!
          const riskXOL = cededLayers.filter(isLayerActualRisk)
          if (riskXOL.length > 0) {
            const visibleRiskXOL = riskXOL.map(
              l => l.meta_data.riskVisibleLayerID as string
            )
            return this.service
              .fetchLayers<LogicalPortfolioLayer>(visibleRiskXOL)
              .pipe(
                mergeMap(r => {
                  if (r.error) {
                    return of({ error: r.error })
                  } else {
                    return this.animatedScenarioService.processScenarioEvents(
                      [...cededLayers, ...r.data!],
                      portfolioIDs,
                      structureGroup.analysisProfileID!,
                      eventIDs,
                      events
                    )
                  }
                })
              )
          } else {
            return this.animatedScenarioService.processScenarioEvents(
              cededLayers,
              portfolioIDs,
              structureGroup.analysisProfileID!,
              eventIDs,
              events
            )
          }
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromGroupAnimatedScenariosActions.groupExecuteFailure({ error })
        )
      ),
      concatMap(([eventResults]) => {
        return [
          fromGroupAnimatedScenariosActions.groupExecuteSuccess(),
          fromGroupScenarioEventResultsActions.addAllGroupScenarioEventResults({
            eventResults,
          }),
        ]
      })
    )
  )

  setGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromGroupAnimatedScenariosActions.setGroup),
      withLatestFrom(
        this.store.pipe(select(selectGrouperProgramGroupEntitiesByID))
      ),
      map(([{ groupID }, groupEntityByID]) => {
        return groupEntityByID[groupID]
      }),
      mergeMapWithInput(groupEntity => {
        if (groupEntity) {
          return this.service.fetchPortfolio(
            groupEntity.programGroup.grossPortfolioID || ''
          )
        } else {
          return of({ error: errorPayload('Structure Group not found') })
        }
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromGroupAnimatedScenariosActions.setGroupFailure({ error })
        )
      ),
      map(([portfolioResponse, groupEntity]) => {
        return {
          layersResponse: portfolioResponse.layers as LossSetLayer[],
          groupEntity,
        }
      }),
      map(({ layersResponse, groupEntity }) => {
        return {
          layers: layersResponse.map(l => ({
            id: l.id,
            meta_data: l.meta_data,
            description: l.description || '',
            premium: l.premium,
            mean: 0,
            loss_sets: l.loss_sets,
          })),
          groupEntity,
        }
      }),
      withLatestFrom(this.store.pipe(select(selectGroupID))),
      concatMap(([{ layers, groupEntity }, currentGroupID]) => {
        const actions = []
        if (currentGroupID && currentGroupID !== groupEntity?.programGroup.id) {
          actions.push(
            fromGroupAnimatedScenariosActions.resetGroupEventsAndResults()
          )
        }
        actions.push(
          fromGroupAnimatedScenariosActions.setGroupSuccess({
            lossSetLayers: layers,
            // tslint:disable-next-line: no-non-null-assertion
            groupID: groupEntity!.programGroup.id,
          })
        )
        return actions
      })
    )
  })
}
