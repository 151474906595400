import { values } from 'ramda'
import { PortfolioSetIDAndName } from '../../analysis/model/portfolio-set.model'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import {
  OmitID,
  ProgramGroupMemberResponse,
  ProgramGroupResponse,
} from '../model/backend.model'

export const convertAllProgramGroupsFromResponse = (
  responses: ProgramGroupResponse[]
) =>
  values(
    responses.reduce((acc, res) => {
      const pg = convertProgramGroupFromResponse(res)

      // Set group dict, keeping any already set props (i.e. scenarioIDs)
      acc[pg.id] = { ...pg, ...(acc[pg.id] ?? {}) }

      // If this program has a scenario parent, add its ID to its parent's
      // scenario IDs, even if the parent hasn't yet been set in the dict
      if (pg.isScenario && pg.parentScenarioID != null) {
        if (!acc[pg.parentScenarioID]) {
          acc[pg.parentScenarioID] = {}
        }
        const scenarioParent = acc[pg.parentScenarioID]
        if (!scenarioParent.scenarioIDs) {
          scenarioParent.scenarioIDs = []
        }
        scenarioParent.scenarioIDs.push(pg.id)
      }

      return acc
    }, {} as Record<string, Partial<ProgramGroup>>)
  ) as ProgramGroup[]

const convertProgramGroupFromResponse = (
  res: ProgramGroupResponse
): ProgramGroup => ({
  id: String(res.id),
  label: res.label,
  yearID: String(res.carrier_year_id),
  description: res.description,
  analysisProfileID: res.analysis_profile_id,
  cededPortfolioID: res.ceded_portfolio_id,
  grossPortfolioID: res.gross_portfolio_id,
  netPortfolioID: res.net_portfolio_id,
  isScenario: res.is_scenario,
  parentScenarioID: res.parent_scenario_id
    ? String(res.parent_scenario_id)
    : undefined,
  fotCount: res.fot_count,
  quoteCount: res.quote_count,
})

export const convertProgramGroupToRequest = (
  clientID: string,
  group: ProgramGroup,
  ids: PortfolioSetIDAndName
): OmitID<ProgramGroupResponse> => ({
  label: group.label,
  carrier_id: parseInt(clientID, 10),
  ceded_portfolio_id: ids.cededPortfolioID,
  gross_portfolio_id: ids.grossPortfolioID,
  net_portfolio_id: ids.netPortfolioID,
  analysis_profile_id: ids.analysisProfileID,
  description: group.description,
  is_scenario: group.isScenario,
  parent_scenario_id: group.parentScenarioID
    ? parseInt(group.parentScenarioID, 10)
    : undefined,
  carrier_year_id: Number(group.yearID),
  fot_count: group.fotCount,
  quote_count: group.quoteCount,
})

export const convertProgramGroupMemberFromResponse = (
  res: ProgramGroupMemberResponse
): ProgramGroupMember => ({
  id: String(res.id),
  ordinal: res.ordinal,
  parentGroupID: String(res.parent_group_id),
  programGroupID: res.program_group_id
    ? String(res.program_group_id)
    : undefined,
  programID: res.program_id ? String(res.program_id) : undefined,
  type: res.type as 'program' | 'programGroup',
})

export const convertProgramGroupMemberToRequest = (
  clientID: string,
  member: ProgramGroupMember
): OmitID<ProgramGroupMemberResponse> => ({
  carrier_id: parseInt(clientID, 10),
  ordinal: member.ordinal,
  parent_group_id: parseInt(member.parentGroupID, 10),
  program_group_id: member.programGroupID
    ? parseInt(member.programGroupID, 10)
    : null,
  program_id: member.programID ? parseInt(member.programID, 10) : null,
  type: member.type,
})
