import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { delay, mergeMap, retryWhen } from 'rxjs/operators'

const defaultMaxRetries = 70 // 70, 1.6 gives a total retry duration of approx 1 min
const defaultCurve = 1.6

const HTTP_STATUS_RETRY = 503
const HTTP_STATUS_GATEWAYERROR = 504
const CHROME_STATUS_ERROR = 0

const retryableErrors = [
  HTTP_STATUS_RETRY,
  HTTP_STATUS_GATEWAYERROR,
  CHROME_STATUS_ERROR,
]

const fatal = (error: HttpEvent<any> | HttpErrorResponse) =>
  error.type === HttpEventType.ResponseHeader ||
  error.type === HttpEventType.Response
    ? `Status ${error.status}: ${error.statusText} calling ${error.url}`
    : (error as HttpErrorResponse).error ??
      `An unknown error occurred during the http operation`
const giveUp = (retries: number) =>
  `Unable to compelete http operation after ${retries - 1} attempts.`

/**
 * Handle retryable state codes.
 */
@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retryErrorsWithBackoff())
  }
}

const isRetryable = (error: HttpEvent<any>) =>
  error instanceof HttpErrorResponse && retryableErrors.includes(error.status)

// const errorUrl = (error: HttpEvent<any>) =>
//   error instanceof HttpErrorResponse ? `(${error.status}) ${error.url}` : ''

const easeOutQuart = (retries: number, curve = defaultCurve) =>
  Math.round((curve / 2 + Math.pow(1 - retries / curve / 10, 4)) * 1000)

function retryErrorsWithBackoff(maxRetries = defaultMaxRetries) {
  let retries = 0

  return (event: Observable<HttpEvent<any>>) =>
    event.pipe(
      retryWhen((errors: Observable<HttpEvent<any>>) =>
        errors.pipe(
          mergeMap(error => {
            if (!isRetryable(error)) {
              return throwError(fatal(error))
            }

            if (++retries <= maxRetries) {
              return of(error).pipe(delay(easeOutQuart(retries)))
            }

            return throwError(giveUp(retries))
          })
        )
      )
    )
}

// const retryAfter = (headers: HttpHeaders) => {
//   // Avoid an exact one second default
//   const defaultValue = 750

//   const value = Number(headers.get('retry-after')) * 1000
//   if (isNaN(value)) {
//       return defaultValue
//   }
