import {
  RiskRefDetail,
  SalesforceClient,
} from '../../../api/model/signature.model'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { selectGrouperProgramGroupMembers } from '../../../analysis/store/analysis.selectors'
import { ProgramGroupMember } from '../../../analysis/store/grouper/program-group.model'
import {
  AccountOpportunity,
  BlobResponse,
  FeatureFlag,
} from '../../../api/model/backend.model'
import { ClientYear } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { Study } from '../../../core/model/study.model'
import { AppState } from '../../../core/store'
import { selectAccountOpportunities } from '../../../core/store/accountopportunity.selectors'
import {
  SignatureContract,
  SignatureReinsurer,
  SignatureReinsurerReferenceType,
  SignatureViewType,
} from '../../signature.model'
import * as fromSignature from '../../store/signature.actions'
import * as fromContracts from '../../store/contracts/contracts.actions'
import * as fromSignatureSelectors from '../../store/signature.selectors'
import * as fromBrokerSelectors from 'src/app/core/store/broker/broker.selectors'
import { FotState } from '../../store/fots/fot.reducer'
import { MatDialog } from '@angular/material/dialog'
import { OpenTwinsSubmissionDialogBoxComponent } from '../../opentwins/opentwins-submission-dialog-box.component'
import { map } from 'rxjs/operators'
import { selectCurrentStudyPrograms } from 'src/app/core/store/program/program.selectors'
import {
  selectFeatureFlags,
  selectIsUsaBasedUser,
  selectThumbnailBlob,
} from 'src/app/core/store/auth/auth.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signature-content-container',
  templateUrl: './signature-content.container.html',
  styleUrls: ['./signature-content.container.scss'],
})
export class SignatureContentContainerComponent implements OnInit, OnDestroy {
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null

  fots$: Observable<FotState[] | null>
  contracts$: Observable<SignatureContract[] | null>
  selectedContract$: Observable<SignatureContract | undefined>
  reinsurers$: Observable<SignatureReinsurer[] | undefined>
  selectedReinsurer$: Observable<SignatureReinsurer | undefined>
  currentPageIndex$: Observable<number>
  selectedYearID$: Observable<string | null>
  years$: Observable<readonly ClientYear[]>
  selectedProgramID$: Observable<string | null>
  programs$: Observable<readonly Study[]>
  structures$: Observable<readonly Program[]>
  structureFilter$: Observable<string | null>
  thumbnailBlob$: Observable<BlobResponse | null>
  structureGroupMembers$: Observable<ProgramGroupMember[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  isDirty$: Observable<boolean>
  isSaving$: Observable<boolean>
  isExporting$: Observable<boolean>
  dirtyContracts$: Observable<string[] | null>
  featureFlags$: Observable<FeatureFlag[] | null>
  groupOrStructureFilterSelection$: Observable<
    'Group' | 'Structure' | 'SL' | null
  >
  renewalRiskRefs$: Observable<RiskRefDetail[] | null>
  clients$: Observable<SalesforceClient[] | null>
  isUsaBasedUser$: Observable<boolean | null>

  private destroy$ = new Subject()

  viewType = SignatureViewType
  activeView: SignatureViewType = SignatureViewType.SELECTOR
  reinsurers: SignatureReinsurer[] = []

  isCoRiskContract = false
  leadRiskRef: string | undefined

  constructor(
    private store: Store<AppState>,
    public otSubmissionDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fots$ = this.store.pipe(
      select(fromSignatureSelectors.selectAllSignatureFots)
    )
    this.contracts$ = this.store.pipe(
      select(fromSignatureSelectors.selectAllSignatureContracts)
    )
    this.selectedContract$ = this.store.pipe(
      select(fromSignatureSelectors.selectCurrentContract)
    )
    this.reinsurers$ = this.store.pipe(
      select(fromSignatureSelectors.selectCurrentContractSigPages)
    )
    this.selectedReinsurer$ = this.store.pipe(
      select(fromSignatureSelectors.selectCurrentSignaturePage)
    )
    this.currentPageIndex$ = this.store.pipe(
      select(fromSignatureSelectors.selectCurrentSignaturePageIndex)
    )
    this.structureGroupMembers$ = this.store.pipe(
      select(selectGrouperProgramGroupMembers)
    )
    this.structures$ = this.store.pipe(
      select(selectCurrentStudyPrograms),
      map(programs => {
        return programs.filter(
          p =>
            !(p.libRE === 'T' && p.programType === 'LibRE Template Structure')
        )
      })
    )
    this.selectedYearID$ = this.store.pipe(
      select(fromBrokerSelectors.selectCurrentYearID)
    )
    this.years$ = this.store.pipe(
      select(fromBrokerSelectors.selectCurrentClientYears)
    )
    this.selectedProgramID$ = this.store.pipe(
      select(fromBrokerSelectors.selectCurrentStudyID)
    )
    this.programs$ = this.store.pipe(
      select(fromBrokerSelectors.selectCurrentYearStudies)
    )
    this.structureFilter$ = this.store.pipe(
      select(fromBrokerSelectors.selectStructureFilter)
    )
    this.thumbnailBlob$ = this.store.pipe(select(selectThumbnailBlob))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.isSaving$ = this.store.pipe(
      select(fromSignatureSelectors.selectIsSignatureSaving)
    )
    this.isDirty$ = this.store.pipe(
      select(fromSignatureSelectors.selectIsSignatureDirty)
    )
    this.isExporting$ = this.store.pipe(
      select(fromSignatureSelectors.selectIsSignatureExporting)
    )
    this.dirtyContracts$ = this.store.pipe(
      select(fromSignatureSelectors.selectAllDirtyContracts)
    )
    this.featureFlags$ = this.store.pipe(select(selectFeatureFlags))
    this.groupOrStructureFilterSelection$ = this.store.pipe(
      select(fromSignatureSelectors.selectGroupOrStructureFilterSelection)
    )
    this.renewalRiskRefs$ = this.store.pipe(
      select(fromSignatureSelectors.selectContractsRiskRefState)
    )
    this.clients$ = this.store.pipe(
      select(fromSignatureSelectors.selectSignatureClientsFromSalesforce)
    )
    this.isUsaBasedUser$ = this.store.pipe(select(selectIsUsaBasedUser))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  /* From tier structures component */
  onInitSignaturePage(
    groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL'
  ): void {
    this.activeView = SignatureViewType.CONTENT
    this.initSignaturePageData(groupOrStructureFilterSelection)
  }

  /* From signature content component */
  onBackToSelectorClick(): void {
    this.store.dispatch(fromSignature.resetSignatureData())
    this.store.dispatch(fromSignature.resetSignatureProperties())
    this.activeView = SignatureViewType.SELECTOR
  }
  createContract(contractToCreate: {
    pageSetName: string
    fots: FotState[]
  }): void {
    this.store.dispatch(
      fromContracts.createSignatureContract({ contractToCreate })
    )
  }
  onResetClick(contract?: SignatureContract): void {
    /* Reset assigned lines for FOTs of selected contract */
    if (contract?.id) {
      this.store.dispatch(
        fromContracts.deleteSignatureContract({ id: contract.id })
      )
    }
    this.store.dispatch(fromContracts.resetSelectedContract())
  }
  onSaveClick(): void {
    /* Save all dirty contracts in state */
    this.store.dispatch(fromContracts.saveAllDirtyContracts())
  }
  onDeleteClick(contract?: SignatureContract): void {
    if (contract?.id) {
      this.store.dispatch(
        fromContracts.deleteSignatureContract({ id: contract.id })
      )
    }
  }
  onDeleteReinsurerClick(reinsurer: SignatureReinsurer): void {
    this.store.dispatch(fromContracts.deleteSignatureReinsurer({ reinsurer }))
  }
  onGenerateExportClick(pages: SignatureReinsurer[]): void {
    /* Export all pages for selected contract to zip file */
    this.store.dispatch(fromContracts.exportSignatureContract({ pages }))
  }
  setSelectedContract(selectedContract: SignatureContract): void {
    this.store.dispatch(fromContracts.setSelectedContract({ selectedContract }))
  }
  setSelectedSigPage(selectedReinsurer: SignatureReinsurer): void {
    this.store.dispatch(fromContracts.setSelectedSigPage({ selectedReinsurer }))
  }
  updateSignatureForm(updates: {
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceType?: SignatureReinsurerReferenceType
  }): void {
    this.store.dispatch(
      fromContracts.updateSignatureContract({
        reinsurer: updates.reinsurer,
        applyToEntireFot: updates.applyToEntireFot,
        updatedReferenceType: updates.updatedReferenceType,
      })
    )
  }
  addLayerToSelectedContract(layer: FotState): void {
    this.store.dispatch(fromContracts.addLayerToSelectedContract({ layer }))
  }
  removeLayerFromSelectedContract(layer: FotState): void {
    this.store.dispatch(
      fromContracts.removeLayerFromSelectedContract({ layer })
    )
  }
  /* For Open Twins */
  submitToOT(contract: SignatureContract): void {
    const isCoRisk = this.isCoRiskContract

    this.otSubmissionDialog.open(OpenTwinsSubmissionDialogBoxComponent, {
      width: '60vw',
      minWidth: '60vw',
      height: 'fit-content',
      minHeight: '45vh',
      maxHeight: '90vh',
      data: {
        contract,
        isCoRiskContract: isCoRisk,
        leadRiskRef: this.leadRiskRef,
      },
    })
  }

  isCoRiskContractChanged($event: boolean): void {
    this.isCoRiskContract = $event
  }

  leadRiskRefChanged(leadRiskRef: string | undefined): void {
    this.leadRiskRef = leadRiskRef
  }

  initSignaturePageData(
    groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL'
  ): void {
    this.store.dispatch(
      fromSignature.initSignatureData({ groupOrStructureFilterSelection })
    )
  }
}
