<div class="content">
  <div class="lists">
    <app-program-default-list
      [savedCurves]="savedCurves"
      [layerTypeEntries]="layerTypeEntries"
      [studies]="studies"
      [selectedProgramID]="selectedProgramID"
      (dialogClosed)="onDialogClosed()"
    ></app-program-default-list>
    <app-layer-default-list
      [towerLayers]="towerLayers"
      [savedCurves]="savedCurves"
      [reinsurersList]="reinsurersList"
      [lossSetLayers]="lossSetLayers"
      [layersViewIds]="layersViewIds"
      [layerMetrics]="layerMetrics"
      [layerTypeEntries]="layerTypeEntries"
      [currentProgram]="currentProgram"
      (layerResize)="onLayerResize($event)"
      (dialogClosed)="onDialogClosed()"
      (dispatchLayerEntries)="onLayerEntryInit($event)"
      (dispatchLayerEntryUpdate)="onLayerEntryUpdate($event)"
    ></app-layer-default-list>
  </div>
</div>
