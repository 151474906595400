import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@shared/shared.module'
import { TierModule } from '../tier/tier.module'
import { HttpClientModule } from '@angular/common/http'
import { MaintenanceRoutingModule } from './maintenance-routing.module'
import { MaintenanceComponent } from './maintenance.component'

@NgModule({
  declarations: [MaintenanceComponent],
  imports: [
    CommonModule,
    SharedModule,
    TierModule,
    MaintenanceRoutingModule,
    HttpClientModule,
  ],
})
export class MaintenanceModule {}
