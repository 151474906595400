import { PortfolioSetID } from './portfolio-set.model'

interface MaybePortfolioSetID {
  analysisProfileID?: string | null
  analysisID?: string | null
  cededPortfolioID?: string | null
  grossPortfolioID?: string | null
  netPortfolioID?: string | null
  parentGrossPortfolioID?: string | null
}

export const extractPortfolioSetID = (
  id?: MaybePortfolioSetID | null
): PortfolioSetID | null => {
  if (!id) {
    return null
  }
  const analysisProfileID = id.analysisProfileID || id.analysisID
  if (
    analysisProfileID &&
    id.cededPortfolioID &&
    id.grossPortfolioID &&
    id.netPortfolioID
  ) {
    if (id.parentGrossPortfolioID) {
      return {
        analysisProfileID,
        cededPortfolioID: id.cededPortfolioID,
        grossPortfolioID: id.grossPortfolioID,
        netPortfolioID: id.netPortfolioID,
        parentGrossPortfolioID: id.parentGrossPortfolioID,
      }
    } else {
      return {
        analysisProfileID,
        cededPortfolioID: id.cededPortfolioID,
        grossPortfolioID: id.grossPortfolioID,
        netPortfolioID: id.netPortfolioID,
      }
    }
  } else {
    return null
  }
}

export const portfolioSetIDsEqual = (
  a?: Partial<PortfolioSetID> | null,
  b?: Partial<PortfolioSetID> | null
): boolean => {
  const _a = extractPortfolioSetID(a)
  const _b = extractPortfolioSetID(b)
  if (!_a && !_b) {
    return true
  }
  if (!_a || !_b) {
    return false
  }
  return (
    _a.analysisProfileID === _b.analysisProfileID &&
    _a.cededPortfolioID === _b.cededPortfolioID &&
    _a.grossPortfolioID === _b.grossPortfolioID &&
    _a.netPortfolioID === _b.netPortfolioID
  )
}
