import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../core/store'
import { LossSetGroup, LossSetLayer } from '../model/loss-set-layers.model'
import { PortfolioSetID } from '../model/portfolio-set.model'
import { AnalysisInitProps, reinitialize } from '../store/analysis.actions'
import {
  selectCurrentClientID,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectEditorPortfolioSetID,
  selectParentGrossLossSetLayers,
  selectCurrentStructureID,
  selectCurrentProgram,
} from '../store/analysis.selectors'
import * as fromLossSetGroupActions from '../store/loss-set-layers/loss-set-group/loss-set-group.actions'
import { OmitID, StudyResponse } from 'src/app/api/model/backend.model'
import { Program } from 'src/app/core/model/program.model'
import { selectStudies } from 'src/app/core/store/clients.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auto-group-loss-set-container',
  templateUrl: `./auto-group-loss-sets-container.component.html`,
})
export class AutoGroupLossSetContainerComponent implements OnInit {
  parentLossSets$: Observable<LossSetLayer[]>
  portfolioSetID$: Observable<PortfolioSetID | null>
  selectedClientID$: Observable<string | null>
  selectedYearID$: Observable<string | null>
  selectedStudyID$: Observable<string | null>
  selectedStructureID$: Observable<string | null>
  currentProgram$: Observable<Program | undefined>
  studies$: Observable<StudyResponse[] | null>

  constructor(
    private dialogRef: MatDialogRef<AutoGroupLossSetContainerComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.parentLossSets$ = this.store.pipe(
      select(selectParentGrossLossSetLayers)
    )
    this.portfolioSetID$ = this.store.pipe(select(selectEditorPortfolioSetID))
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.selectedStudyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.selectedStructureID$ = this.store.pipe(
      select(selectCurrentStructureID)
    )
    this.currentProgram$ = this.store.pipe(select(selectCurrentProgram))
    this.studies$ = this.store.select(selectStudies)
  }

  onCloseClick(initProps: AnalysisInitProps) {
    this.dialogRef.close()
    this.store.dispatch(
      reinitialize({
        ...initProps,
      })
    )
  }
  onSaveLossSetGroupClick(lossSetGroups: OmitID<LossSetGroup>[]) {
    for (const lossSetGroup of lossSetGroups) {
      this.store.dispatch(
        fromLossSetGroupActions.saveGroup({
          lossSetGroup,
        })
      )
    }
  }
}
