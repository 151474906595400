import { Action } from '@ngrx/store'
import { extractPortfolioSetID } from '../../../model/portfolio-set-id.util'
import { fetchPortfolioView } from '../../views/portfolio-view.actions'
import { ProgramGroup } from '../program-group.model'

export const createFetchPortfolioViewActionFromGroup = (
  group: ProgramGroup
): Action | null => {
  const portfolioSetID = extractPortfolioSetID(group)
  const { clientID, studyID } = group
  if (!portfolioSetID || !clientID || !studyID) {
    console.error('Cannot fetch portfolio view for program group w/o all IDs')
    return null
  }
  return fetchPortfolioView({
    ...portfolioSetID,
    clientID,
    studyID,
    isGroupCompare: true,
  })
}

export const createFetchPortfolioViewActionsFromGroups = (
  groups: ProgramGroup[]
): Action[] =>
  groups
    .map(createFetchPortfolioViewActionFromGroup)
    .filter(action => action != null) as Action[]
