<div class="dialog-header">
  <h2>Export Options</h2>
  <div class="actions-bar">
    <button
      appButton
      accent
      border
      [disabled]="!isDialogValid"
      [matTooltip]="disabledTooltip"
      [matTooltipDisabled]="isDialogValid"
      (click)="submitDialog()"
    >
      Export
    </button>
  </div>
</div>
<div class="wrapper">
  <div class="section-selection">
    <div class="content">
      <div class="header">
        <h4>Select the Authorization Sheet Export Views</h4>
      </div>
      <div
        class="section-container"
        *ngFor="let view of exportSectionViews; let i = index"
      >
        <div [ngClass]="{ empty: !isSectionViewValid(view) }">
          <span
            class="label"
            [ngClass]="'app-palette-' + view.section.layerType"
            >{{ view.section.layerName }}</span
          >
        </div>
        <div class="section-content">
          <mat-checkbox
            [checked]="view.sectionSelected"
            (change)="toggleViewSettings(i, $event.checked, undefined, true)"
            ><span
              matTooltip="The standard section export view, selecting this option will unselect custom comparisons for this section"
              matTooltipPosition="right"
              >Standard</span
            ></mat-checkbox
          >
          <mat-checkbox
            *ngFor="let compareView of view.compareViews; let j = index"
            [checked]="view.selectedCompareViewIds.includes(compareView.id)"
            (change)="toggleViewSettings(i, $event.checked, j)"
            >{{ compareView.name }}</mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>
  <div class="term-selection">
    <div class="header">
      <h4>Select Term Selection Fields to Output on Authorization Sheet</h4>
    </div>
    <div class="content">
      <div
        class="term-container"
        *ngFor="let termView of exportTermViews; let i = index"
      >
        <div>
          <mat-checkbox
            matTooltip="Select All"
            matTooltipPosition="right"
            [checked]="isToggleAllSelected(i)"
            [indeterminate]="isToggleAllIndeterminate(i)"
            (change)="toggleAll(i, $event.checked)"
          ></mat-checkbox>
          <span class="label" [ngClass]="'app-palette-' + termView.layerTypes[0]">{{
            termView.paletteItem.name
          }}</span>
        </div>
        <div class="terms">
          <div
            class="term-item"
            *ngFor="let term of termView.terms; let j = index"
          >
            <mat-checkbox
              [checked]="term.active"
              (change)="toggleTerm(i, j, $event.checked)"
              >{{ term.label }}</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
