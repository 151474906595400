<div class="action" *ngIf="!executing; else actionRunning">
  <button appButton border (click)="addEvent.emit()">
    Add Event
  </button>
  <button
    appButton
    border
    (click)="processEvents.emit()"
    [disabled]="disableProcessEvents"
  >
    Process Events
  </button>
  <button
    appButton
    border
    (click)="reanimate.emit()"
    [disabled]="disableReanimate"
  >
    Re-Animate
  </button>
  <button *ngIf="!recording" appButton border (click)="record.emit()">
    Start Recording
  </button>
  <button *ngIf="recording" appButton border (click)="stopRecord.emit()">
    Stop Recording
  </button>
</div>
<ng-template #actionRunning>
  <app-active-action
    class="action"
    [value]="'Processing'"
  ></app-active-action>
</ng-template>
