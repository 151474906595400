import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import {
  ColumnsSettings,
  mapBackAllCategories,
  mapCategoriesToOneItem,
} from './columns-settings.model'

@Component({
  selector: 'app-columns-settings-dialog',
  template: `
    <div class="dialog__header">
      <h1 mat-dialog-title>Settings</h1>

      <span class="dialog__controls">
        <button
          appButton
          small
          primary
          border
          class="dialog__button"
          (click)="reset()"
        >
          <span><mat-icon inline>history</mat-icon>Reset</span>
        </button>

        <button
          appButton
          small
          primary
          border
          class="dialog__button"
          (click)="hideAll()"
        >
          <span><mat-icon inline>visibility_off</mat-icon>Hide All</span>
        </button>

        <button
          appButton
          small
          accent
          border
          class="dialog__button"
          (click)="closeDialog()"
        >
          <span><mat-icon inline>save</mat-icon>Save</span>
        </button>

        <button appButtonIcon class="closeX" [mat-dialog-close]="false">
          close
        </button>
      </span>
    </div>
    <div mat-dialog-content>
      <mat-divider></mat-divider>

      <div cdkDropListGroup class="dialog__lists-container">
        <div class="dialog__list-container">
          <h2 class="dialog__list-title">Show</h2>

          <div
            cdkDropList
            [cdkDropListData]="visibleColumns"
            class="dialog__list"
            [class.dialog__list--dotted-border]="visibleColumns.length === 0"
            (cdkDropListDropped)="drop($event)"
          >
            <p *ngIf="visibleColumns.length === 0">Drag here</p>

            <div
              class="dialog__list-item"
              *ngFor="let item of visibleColumns; let i = index"
              cdkDrag
            >
              {{ item.label }}

              <mat-icon (click)="hideItem(i)">visibility_off</mat-icon>
            </div>
          </div>
        </div>

        <div class="dialog__list-container">
          <h2 class="dialog__list-title">Hide</h2>

          <div
            cdkDropList
            [cdkDropListData]="hiddenColumns"
            class="dialog__list"
            [class.dialog__list--dotted-border]="hiddenColumns.length === 0"
            (cdkDropListDropped)="drop($event)"
          >
            <p *ngIf="hiddenColumns.length === 0">Drag here</p>

            <div
              class="dialog__list-item"
              *ngFor="let item of hiddenColumns; let i = index"
              cdkDrag
            >
              {{ item.label }}
              <mat-icon (click)="showItem(i)">visibility</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
           .dialog__header {
             display: flex;
             justify-content: space-between;
           }

           .dialog__controls {
             display: flex;
             align-items: center;
             margin: 0;
             padding: 24px;
           }

           .dialog__button {
             height: 30px;
             align-items: center;
             line-height: 18px;
             padding: 0px 6px 3px 3px;
           }

           .dialog__button mat-icon {
             position: relative;
             top: 3px;
             width: 20px;
             font-size: var(--font-size);
             margin-right: 3px;
           }

           mat-divider {
             margin-bottom: 10px;
           }

           .dialog__lists-container {
             display: flex;
             flex-direction: row;
           }

           .dialog__list-container {
             width: 50%;
             margin: 0 25px 25px 0;
             display: inline-block;
             vertical-align: top;
           }

           .dialog__list {
             min-height: 100%;
             border-radius: 4px;
             overflow: hidden;
             display: block;
           }

           .dialog__list--dotted-border {
             border: 1px dashed var(--bg-1-lit);
           }

           .dialog__list p {
             text-align: center;
           }

           .dialog__list-title {
             font-size: var(--font-size-big);
             padding: 4px;
           }

           .dialog__list mat-icon {
             font-size: var(--font-size);
           }

           .dialog__list-item {
             padding: 20px 10px;
             margin-bottom: 10px;
             border-radius: 5px;
             border-bottom: solid 1px var(--accent);
             background: var(--bg-1-lit);
             color: white;
             display: flex;
             flex-direction: row;
             align-items: center;
             justify-content: space-between;
             box-sizing: border-box;
             cursor: move;
             font-size: 14px;
           }

           .dialog__list-item mat-icon {
             cursor: pointer;
           }

           .cdk-drag-preview {
             box-sizing: border-box;
             border-radius: 4px;
             box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
               0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
           }

           .cdk-drag-placeholder {
             opacity: 0;
           }

           .cdk-drag-animating {
             transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
           }

           .dialog__list.cdk-drop-list-dragging
             .dialog__list-item:not(.cdk-drag-placeholder) {
             transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
           }

           button.close {
             color: var(--primary);
             transition: color 250ms;
           }
           button.close:not(.disabled):hover,
           button.close:not(.disabled):focus {
             color: var(--primary-lit);
           }
         `,
  ],
})
export class ColumnsSettingsDialogComponent implements OnInit {
  visibleColumns: SortTableColumnDef[] = []
  hiddenColumns: SortTableColumnDef[] = []
  defaultColumns: SortTableColumnDef[] = []

  constructor(
    public dialogRef: MatDialogRef<ColumnsSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ColumnsSettings
  ) {}

  ngOnInit() {
    this.defaultColumns = this.data.defaultColumns
    this.visibleColumns = mapCategoriesToOneItem(this.data.visibleColumns)
    this.hiddenColumns = mapCategoriesToOneItem(this.data.hiddenColumns)
  }

  closeDialog() {
    this.dialogRef.close({
      visibleColumns: mapBackAllCategories(
        this.defaultColumns,
        this.visibleColumns
      ),
      hiddenColumns: mapBackAllCategories(
        this.defaultColumns,
        this.hiddenColumns
      ),
      defaultColumns: this.defaultColumns,
    })
  }

  drop($event: CdkDragDrop<string[]>) {
    if ($event.previousContainer === $event.container) {
      moveItemInArray(
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex
      )
    } else {
      transferArrayItem(
        $event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex
      )
    }
  }

  hideItem(itemIndex: number) {
    transferArrayItem(
      this.visibleColumns,
      this.hiddenColumns,
      itemIndex,
      this.hiddenColumns.length - 1
    )
  }

  showItem(itemIndex: number) {
    transferArrayItem(
      this.hiddenColumns,
      this.visibleColumns,
      itemIndex,
      this.hiddenColumns.length - 1
    )
  }

  reset() {
    this.visibleColumns = mapCategoriesToOneItem(this.defaultColumns)
    this.hiddenColumns = []
  }

  hideAll() {
    this.visibleColumns = []
    this.hiddenColumns = mapCategoriesToOneItem(this.defaultColumns)
  }
}
