import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  CompareMetricSetting,
  CompareMetricTableCategory,
} from '../../analysis/model/compare-metrics.model'
import { MetricValueType } from '../../core/model/metric-value-type.model'

export interface MetricBuilderDialogComponentData {
  metricCategories: CompareMetricTableCategory[]
  metricSetting?: CompareMetricSetting
}

export class FormulaErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    _control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!form?.invalid
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-builder',
  styleUrls: ['./metric-builder-dialog.component.scss'],
  templateUrl: './metric-builder-dialog.component.html',
})
export class MetricBuilderDialogComponent {
  formula: string
  metricCategories: CompareMetricTableCategory[]
  label: string
  constant: string
  metricSetting: CompareMetricSetting | undefined
  ragOrder: string | undefined
  valueType: MetricValueType | undefined

  matcher = new FormulaErrorStateMatcher()
  formulaForm: FormGroup

  get ragOrderList(): string[] {
    return ['Higher', 'Lower']
  }

  get valueTypeList(): string[] {
    return ['currency', 'percentage', 'numeric', 'ratio']
  }

  get ragButtonLabel(): string {
    return this.ragOrder ? this.ragOrder : 'Ranking Order'
  }

  get valueTypeLabel(): string {
    return this.valueType ? this.valueType : 'Value Type'
  }

  get isEditMode(): boolean {
    return this.metricSetting !== undefined
  }

  get dialogTitle(): string {
    return this.isEditMode
      ? // tslint:disable-next-line:no-non-null-assertion
        `Edit ${this.metricSetting!.label}`
      : 'Build New Custom Metric'
  }

  get addOrSaveButtonLabel(): string {
    return this.isEditMode ? 'Save' : 'Add'
  }

  get isSaveButtonDisabled(): boolean {
    return !this.formula || !this.label || !this.ragOrder || !this.valueType
  }

  constructor(
    public dialogRef: MatDialogRef<MetricBuilderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MetricBuilderDialogComponentData,
    private fb: FormBuilder
  ) {
    this.metricCategories = data.metricCategories
    this.metricSetting = data.metricSetting
    // tslint:disable-next-line:no-non-null-assertion
    this.formula = this.isEditMode ? this.metricSetting!.formula! : ''
    // tslint:disable-next-line:no-non-null-assertion
    this.label = this.isEditMode ? this.metricSetting!.label : ''
    // tslint:disable-next-line:no-non-null-assertion
    this.ragOrder = this.isEditMode ? this.metricSetting!.ragOrder! : undefined
    // tslint:disable-next-line:no-non-null-assertion
    this.valueType = this.isEditMode ? this.metricSetting!.valueType : undefined
    this.initForm()
  }

  initForm(): void {
    this.formulaForm = this.fb.group(
      {
        formula: '',
      },
      {
        validator: this.formulaValidator,
      }
    )
  }

  formulaValidator(form: FormGroup): { invalidFormula: boolean } | null {
    // tslint:disable-next-line:no-non-null-assertion
    const formulaValue = form.get('formula')!.value

    const validatedFormula = formulaValue.replace(/ *\[[^\]]*]/g, '1')

    let condition = false
    try {
      if (formulaValue.includes('][')) {
        condition = true
      }
      // tslint:disable-next-line: no-eval
      eval(validatedFormula)
    } catch (e) {
      condition = true
    }

    return condition ? { invalidFormula: true } : null
  }

  isHiddenMetric(category: string, name: string): boolean {
    return (
      (category === 'Gross Metrics' || category === 'Net Results') &&
      name === 'Deposit Premium'
    )
  }

  onMetricAdd(category: string, name: string): void {
    this.formula = this.formula + `[${category}:${name}]`
  }

  onPlusAdd(): void {
    this.formula = this.formula + '+'
  }

  onMinusAdd(): void {
    this.formula = this.formula + '-'
  }

  onMultiAdd(): void {
    this.formula = this.formula + '*'
  }

  onDivideAdd(): void {
    this.formula = this.formula + '/'
  }

  onLeftParenAdd(): void {
    this.formula = this.formula + '('
  }

  onRightParenAdd(): void {
    this.formula = this.formula + ')'
  }

  onConstAdd(): void {
    this.formula = this.formula + this.constant
    this.constant = ''
  }

  onClearFormula(): void {
    this.formula = ''
  }

  onSetRagOrder(rag: string): void {
    this.ragOrder = rag
  }

  onSetValueType(valueType: string): void {
    this.valueType = valueType as MetricValueType
  }

  addSaveMetricClick(): void {
    if (this.formula && this.label && this.ragOrder && this.valueType) {
      const validatedFormula = this.formula.replace(/ *\[[^\]]*]/g, '1')

      try {
        if (this.formula.includes('][')) {
          throw new Error('Metrics must have a math operator between them')
        }
        // tslint:disable-next-line: no-eval
        const ret = eval(validatedFormula)
        if ((ret || ret === 0) && ret !== 'NaN') {
          const newMetric = {
            formula: this.formula,
            label: this.label,
            ragOrder: this.ragOrder,
            valueType: this.valueType,
          }
          this.dialogRef.close(newMetric)
        }
      } catch (e) {
        console.log('error', e)
        console.log('Formula is not a valid math expression')
      }
    }
  }
}
