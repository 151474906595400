<div *ngIf="!showMinimizedGroupBar" class="row">
  <app-group-tower-bar-edge
    *ngFor="let edge of leftEdges"
    [edge]="edge"
    side="left"
    transparent
  ></app-group-tower-bar-edge>

  <div
    class="program"
    [ngClass]="{ 'program-clickable': selectMode }"
    (click)="programBarClick.emit()"
  >
    <div *ngIf="showTag">
      <app-ribbon-tag
        *ngFor="let tag of getInuranceTags('target'); trackBy: trackByTag"
        class="tag"
        target
        (click)="
              $event.stopPropagation();
              !selectMode && inuranceTagClick.emit(tag)
            "
      >
        {{ tag.symbol }}
      </app-ribbon-tag>
    </div>

    <div
      class="program-label"
      [matTooltip]="name"
      matTooltipPosition="above"
    >
      <h3 *ngIf="!hideName">{{ name }}</h3>
    </div>

    <div *ngIf="showTag">
      <app-ribbon-tag
        *ngFor="let tag of getInuranceTags('source'); trackBy: trackByTag"
        class="tag"
        source
        (click)="
              $event.stopPropagation();
              !selectMode && inuranceTagClick.emit(tag)
            "
      >
        {{ tag.symbol }}
      </app-ribbon-tag>
    </div>

    <div *ngIf="!hideName" class="label-buttons">
      <button
        appButtonIcon
        *ngIf="showMoreButton"
        big
        class="icon"
        [matMenuTriggerFor]="moreMenu"
        (click)="$event.stopPropagation()"
      >
        more_horiz
      </button>
      <mat-menu #moreMenu="matMenu">
        <button
          mat-menu-item
          [disabled]="hasGroupScenarios"
          (click)="onSaveUntitled(slide)"
        >
          New Group
        </button>

        <button
          mat-menu-item
          *ngIf="showMoveToGroup"
          [disabled]="isDisabledMoveToGroup"
          [matMenuTriggerFor]="moveToGroupMenu"
        >
          Move to Group
        </button>
        <mat-menu #moveToGroupMenu="matMenu">
          <ng-container *ngFor="let g of groups; trackBy: trackByID">
            <button
              mat-menu-item
              *ngIf="g.id !== slide.groupID"
              (click)="onMoveToGroup(g.id)"
            >
              {{ getGroupLabel(g) }}
            </button>
          </ng-container>
        </mat-menu>

        <button
          mat-menu-item
          *ngIf="showMoveLeft"
          [disabled]="hasGroupScenarios"
          (click)="onMoveLeft()"
        >
          Move Left
        </button>

        <button
          mat-menu-item
          *ngIf="showMoveRight"
          [disabled]="hasGroupScenarios"
          (click)="onMoveRight()"
        >
          Move Right
        </button>

        <button mat-menu-item (click)="onDesignClick(slide)">Design</button>
      </mat-menu>
    </div>

    <div *ngIf="!hideName" class="right-buttons">
      <button
        *ngIf="!selectMode && !hasGroupScenarios"
        appButtonIcon
        big
        class="icon"
        (click)="onRemoveClick($event)"
      >
        close
      </button>
    </div>
  </div>

  <app-group-tower-bar-edge
    *ngFor="let edge of rightEdges"
    [edge]="edge"
    side="right"
    transparent
  ></app-group-tower-bar-edge>
</div>

<app-group-tower-bar
  *ngIf="showMinimizedGroupBar"
  [groupBar]="deepestGroupBar"
></app-group-tower-bar>
<app-group-tower-bar
  *ngFor="let bar of groupBars; let i = index"
  [dirty]="dirty"
  [groupBar]="bar"
  [slide]="slide"
  [years]="years"
  [programGroups]="programGroups"
  [programsByID]="programsByID"
  [selectedProgramIDs]="selectedProgramIDs"
  [analysisProfileID]="analysisProfileID"
  [inuranceTagsByProgramGroupID]="inuranceTagsByProgramGroupID"
  [groupScenariosByID]="groupScenariosByID"
  [bottomMinimized]="isGroupMinimizedBottom(bar, i)"
  [selectMode]="selectMode"
  [clickable]="allowGroupClick(bar?.id)"
  [groups]="groups"
  [groupsByID]="groupsByID"
  [ifLibreGroup]="ifLibreGroup"
  (barClick)="onBarClick($event)"
  (saveUntitled)="saveUntitled.emit($event)"
  (programGroupAdd)="programGroupAdd.emit($event)"
  (programGroupRemove)="programGroupRemove.emit($event)"
  (programGroupMinimizeToggle)="programGroupMinimizeToggle.emit($event)"
  (programGroupDelete)="programGroupDelete.emit($event)"
  (programAdd)="programAdd.emit($event)"
  (inuranceTagClick)="inuranceTagClick.emit($event)"
  (animatedScenarios)="animatedScenarios.emit($event)"
  (groupLayerDetails)="groupLayerDetails.emit($event)"
  (groupScenariosClick)="groupScenariosClick.emit($event)"
  (programGroupRename)="programGroupRename.emit($event)"
></app-group-tower-bar>
