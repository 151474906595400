import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../../error/model/error'
import {
  CompareMetricCategory,
  CompareMetricSetting,
} from '../../../model/compare-metrics.model'

export const fetchCompareMetricSettings = createAction(
  '[Compare Metric Settings] Fetch',
  props<{ studyID: string }>()
)

export const fetchCompareMetricSettingsSuccess = createAction(
  '[Compare Metric Settings] Fetch Success',
  props<{ settings: CompareMetricSetting[] }>()
)

export const fetchCompareMetricSettingsFailure = createAction(
  '[Compare Metric Settings] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const fetchCompareMetricValues = createAction(
  '[Compare Metric Settings] Fetch Values',
  props<{ programID: number }>()
)

export const fetchCompareMetricValuesSuccess = createAction(
  '[Compare Metric Settings] Fetch Values Success',
  props<{ programID: number; categories: CompareMetricCategory[] }>()
)

export const setCompareMetricProbabilityValues = createAction(
  '[Compare Metric Settings] Update Probability Values Success',
  props<{
    programID: number
    probabilityAttachment: number
    probabilityExhaust: number
  }>()
)

export const fetchCompareMetricValuesFailure = createAction(
  '[Compare Metric Settings] Fetch Values Failure',
  props<{ programID: number; error: ApplicationError }>()
)

export const updateCompareMetricSettings = createAction(
  '[Update Metric Settings] Update',
  props<{ settings: CompareMetricSetting[] }>()
)

export const upsertCompareMetricSettings = createAction(
  '[Save Metric Settings] Upsert'
)

export const fetchCustomCompareMetricValues = createAction(
  '[Compare Metric Settings] Fetch Custom Values',
  props<{ programID: number; categories: CompareMetricCategory[] }>()
)

export const updateCustomRanks = createAction(
  '[Compare Metric Settings] Update Custom Ranks',
  props<{ programID: number; categories: CompareMetricCategory[] }>()
)
