export type AggregationMethodType = 'OEP' | 'AEP'
export type Perspective = 'Loss' | 'LossRp' | 'UW'
export type VaRTVaR = 'VaR' | 'TVaR'

export interface ReturnPeriodRow {
  header: string | null
  value: number | null
  term: 'VaR' | 'tVaR' | 'Std Dev'
  period1: number
  period2: number
  period3: number
  period4: number
  period5: number
  mean: number
}

export interface ReturnPeriodMetrics {
  aggregationMethod: AggregationMethodType
  perspective: Perspective
  returnPeriod1: number
  returnPeriod2: number
  returnPeriod3: number
  returnPeriod4: number
  returnPeriod5: number
  returnPeriodData: ReturnPeriodRow[]
}

export const RETURN_PERIOD_PORTFOLIO_COLS = [
  'header',
  'value',
  'term',
  'period1',
  'period2',
  'period3',
  'period4',
  'period5',
]

export const RETURN_PERIOD_COLS = [
  'header',
  'value',
  'term',
  'period1',
  'period2',
  'period3',
]
