<h1 mat-dialog-title>
  Update the Carrier for {{ this.data.accountMappingData.sfAccountName }}
</h1>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Carrier Name</mat-label>
    <mat-select (selectionChange)="onCarrierSelectionChange($event)">
      <mat-option *ngFor="let carrier of this.data.carriers" [value]="carrier">
        {{ carrier.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button appButton border center accent (click)="updateCarrier()">
    Update
  </button>
  <button appButton link [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
