import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { Observable } from 'rxjs'
import { selectCurrentStudyID } from '../../core/store/broker/broker.selectors'
import * as fromBrokerActions from '../../core/store/broker/broker.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-folder-dialog-container',
  templateUrl: './new-folder-dialog.container.html',
})
export class NewFolderDialogContainerComponent implements OnInit {
  selectedProgramID$: Observable<string | null>

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID))
  }

  onCreateFolder(event: { programId: string; name: string }): void {
    const { programId, name } = event
    this.store.dispatch(fromBrokerActions.createFolder({ programId, name }))
    this.dialog.closeAll()
  }

  onEditFolder(event: {
    folderID: string
    name: string
    programId: string
  }): void {
    this.store.dispatch(fromBrokerActions.updateFolder(event))
    this.dialog.closeAll()
  }
}
