import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../nav.service'
import { RiskRefDetail } from '../../api/model/signature.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-revenue-header',
  styleUrls: ['./revenue-header.component.scss'],
  templateUrl: './revenue-header.component.html',
})
export class RevenueHeaderComponent {
  @Input() name: string

  get subtitle(): string {
    return ''
  }

  constructor(public navService: NavService, private router: Router) {}

  onBackClick(): void {
    this.router.navigate(['/home'])
  }
}
