import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { forkJoin } from 'rxjs'
import { AppState } from '../../../../core/store'
import { QuoteManagementInformationService } from '../../../../api/quote-management-information/quote-management-information.service'
import { rejectError, mergeApiResponses } from '../../../../api/util'
import { QuoteTabType } from '../../model/quote-management-information.model'
import { getChartKvps } from '../../utils/quote-charts.util'
import { getKeyValuePairsForFiltersAndIntervals } from '../../utils/quote-management-information.util'
import * as fromQuoteMIActions from '../quote-management-information.actions'
import * as fromQuoteMISelectors from '../quote-management-information.selectors'
import * as fromQuoteMIChartActions from './quote-management-information-charts.actions'
import * as fromQuoteMIChartSelectors from './quote-management-information-charts.selectors'
import { QuoteChartResponse } from "../../model/quote-charts.model";

@Injectable()
export class QuoteManagementInformationChartEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private quoteMIService: QuoteManagementInformationService
  ) {}

  fetchAllCharts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIChartActions.fetchAllQuoteChartsData),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMIChartSelectors.selectQuoteMICharts)
      ),
      switchMap(([_, audienceView, filters, intervals, charts]) => {
        const kvps = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals
        )
        const requests = charts.map(chart => {
          const kvpsFinal = {
            ...kvps,
            ...getChartKvps(chart),
          }
          return this.quoteMIService.getQuoteChartData(
            kvpsFinal,
            chart.index,
            chart.groupBy
          )
        })
        return forkJoin(requests).pipe(mergeApiResponses())
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIChartActions.fetchAllQuoteChartsDataFailure({ error })
        )
      ),
      map(charts =>
        fromQuoteMIChartActions.fetchAllQuoteChartsDataSuccess({ charts })
      )
    )
  )

  fetchSingleChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromQuoteMIActions.updateSelectedChartGridGroupBy,
        fromQuoteMIActions.updateSelectedChartGridOption
      ),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMIChartSelectors.selectQuoteMICharts)
      ),
      switchMap(([action, audienceView, filters, intervals, charts]) => {
        if (action.tab !== QuoteTabType.CHARTS) {
          return []
        }
        let kvps = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals
        )
        const chart = charts[action.chartIndex] // Fetch chart data for updated grid index
        kvps = {
          ...kvps,
          ...getChartKvps(chart),
        }
        return this.quoteMIService.getQuoteChartData(
          kvps,
          chart.index,
          chart.groupBy
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIChartActions.fetchSingleQuoteChartDataFailure({ error })
        )
      ),
      map(chart => {
        const chartResponse = chart as QuoteChartResponse
        return fromQuoteMIChartActions.fetchSingleQuoteChartDataSuccess({chart: chartResponse})
      })
    )
  )
}
