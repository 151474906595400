import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SignedWarning, OrderPercentErrors } from '../models/quote.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

interface SignedWarningMessage extends SignedWarning {
  errorCharacter?: '❌' | '⚠️'
}

interface OrderPercentErrorsMessage extends OrderPercentErrors {
  errorCharacter?: '❌' | '⚠️'
  message?: string
}

@Component({
  selector: 'app-quote-push-ws-warnings-dialog',
  templateUrl: './quote-push-ws-warnings-dialog.component.html',
  styleUrls: ['./quote-push-ws-warnings-dialog.component.scss'],
})
export class QuotePushWsWarningsDialogComponent implements OnInit {
  messages: SignedWarningMessage[]
  orderMessages: OrderPercentErrorsMessage[] = []
  columnDefs: SortTableColumnDef<SignedWarningMessage>[] = [
    {
      id: 'errorCharacter',
      label: 'Type',
      valueType: 'text',
      editable: false,
    },
    {
      id: 'title',
      label: 'Reinsurer name',
      valueType: 'text',
      editable: false,
    },
    {
      id: 'layer',
      label: 'Layer',
      valueType: 'text',
      editable: false,
    },
    {
      id: 'message',
      label: 'Message',
      valueType: 'text',
      editable: false,
    },
  ]

  orderErrorColumnDefs: SortTableColumnDef<OrderPercentErrorsMessage>[] = [
    {
      id: 'errorCharacter',
      label: 'Type',
      valueType: 'text',
      editable: false,
      headerStyle: {
        paddingRight: '10px',
      },
    },
    {
      id: 'layer',
      label: 'Layer',
      valueType: 'text',
      editable: false,
      headerStyle: {
        paddingRight: '10px',
      },
    },
    {
      id: 'orderPercent',
      label: 'FOT Order %',
      valueType: 'percentage',
      editable: false,
      headerStyle: {
        paddingRight: '10px',
      },
    },
    {
      id: 'totalSignedPercent',
      label: 'Cumulative Signed %',
      valueType: 'percentage',
      editable: false,
      headerStyle: {
        paddingRight: '10px',
      },
    },
    {
      id: 'message',
      label: 'Message',
      valueType: 'text',
      editable: false,
      headerStyle: {
        paddingRight: '10px',
      },
    },
  ]

  constructor(
    private dialogRef: MatDialogRef<QuotePushWsWarningsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      warnings: SignedWarning[]
      errors: SignedWarning[]
      orderErrors: OrderPercentErrors[]
    }
  ) {}

  ngOnInit(): void {
    this.messages = this.data.errors.concat(this.data.warnings)
    this.messages.forEach(message => {
      if (message.type === 'null') {
        message.message = 'Missing a Signed% or Written% value'
        message.errorCharacter = '❌'
      } else if (message.type === 'signed>written') {
        message.message = 'Signed% must be <= Written%'
        message.errorCharacter = '❌'
      } else if (message.type === 'signed=0') {
        message.message = 'Signed is at 0%'
        message.errorCharacter = '⚠️'
      }
    })
    this.data.orderErrors.forEach(message => {
      this.orderMessages.push({
        ...message,
        errorCharacter: '❌',
        message: 'Signed % must be <= FOT Order %',
      })
    })
  }

  confirm(): void {
    if (this.data.errors.length === 0 && this.data.orderErrors.length === 0) {
      this.dialogRef.close('Confirmed')
    }
  }
}
