import {inject, Injectable} from '@angular/core'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppState } from 'src/app/core/store'
import { Store, select } from '@ngrx/store'
import * as fromCapitalMetricsActions from './capital-metrics.actions'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { concatMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { of } from 'rxjs'
import {
  selectCompareMetricSettingsEntities,
  selectPortfolioViewDetailMetricsState,
} from '../analysis.selectors'
import { fetchMiscMetrics } from './misc-metrics.actions'
import { CompareMetricSetting } from '../../model/compare-metrics.model'

@Injectable()
export class CapitalMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCapitalMetricsActions.fetchCapitalMetrics),
      concatMap(props => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCompareMetricSettingsEntities)),
            this.store.pipe(
              select(selectPortfolioViewDetailMetricsState, { portfolioSetID })
            )
          ),
          map(([_, settings, portfolioViewDetailMetricsState]) => ({
            ...props,
            portfolioViewDetailMetricsState,
            settingsSP: settings.find(
              setting =>
                setting.category === 'Capital Metrics' &&
                setting.label.includes('S&P Capital Benefit')
            ),
            settingsBCAR: settings.find(
              setting =>
                setting.category === 'Capital Metrics' &&
                setting.label === 'BCAR Capital Benefit'
            ),
            isCapitalShow: this.getCapitalShow(settings)
          }))
        )
      }),
      withLatestFrom(this.store.pipe(select(selectCurrentClient))),
      concatMapWithInput(
        ([
          {
            cededPortfolioViewID,
            grossPortfolioViewID,
            netPortfolioViewID,
            portfolioViewDetailMetricsState,
            settingsSP,
            settingsBCAR,
          },
        ]) => {
          // @ts-ignore
          return this.service.getCapitalMetrics(
            cededPortfolioViewID,
            grossPortfolioViewID,
            netPortfolioViewID,
            portfolioViewDetailMetricsState.cededCalculatedMetrics
              ? portfolioViewDetailMetricsState.cededCalculatedMetrics.premium
              : 0,
            portfolioViewDetailMetricsState.cededCalculatedMetrics
              ? portfolioViewDetailMetricsState.cededCalculatedMetrics
                  .expectedLoss
              : 0,
            settingsSP && settingsSP.spPremiumValue
              ? settingsSP.spPremiumValue
              : 0,
            settingsSP && settingsSP.spReserveValue
              ? settingsSP.spReserveValue
              : 0,
            settingsSP && settingsSP.spDivesificationValue
              ? settingsSP.spDivesificationValue
              : 0,
            settingsSP && settingsSP.spCatValue ? settingsSP.spCatValue : 0,
            settingsBCAR && settingsBCAR.spPremiumValue
              ? settingsBCAR.spPremiumValue
              : 0,
            settingsBCAR && settingsBCAR.spReserveValue
              ? settingsBCAR.spReserveValue
              : 0,
            settingsBCAR && settingsBCAR.spDivesificationValue
              ? settingsBCAR.spDivesificationValue
              : 0,
            settingsSP && settingsSP.year ? parseInt(settingsSP.year, 10) : 333
          )
        }
      ),
      rejectErrorWithInput((error, [props]) => {
        // Adding a special logic if Cat filter is not added in profile.
        if (props.isCapitalShow) {
          let updatedError = error
          updatedError.message =
            'Cat filter is missing which is required for Capital metrics.'
          this.store.dispatch(
            fromCapitalMetricsActions.fetchCapitalMetricsFailure({
              error,
              ...props,
            })
          )
        }
        this.store.dispatch(
          fetchMiscMetrics({
            ...props,
          })
        )
        }
      ),
      concatMap(([results, [props]]) => {
        const actions = []
        actions.push(
          fromCapitalMetricsActions.fetchCapitalMetricsSuccess({
            ...results,
            ...props,
          }),
          fetchMiscMetrics({
            ...props,
          })
        )
        return actions
      })
    )
  })

private getCapitalShow(settings: CompareMetricSetting[]): boolean {
  let isShow = settings.filter(
              setting =>
                setting.category === 'Capital Metrics' &&
                setting.show
            )
  return isShow.length > 0 ? true : false
}
}
