import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { Client } from '../core/model/client.model'
import { FormControl } from '@angular/forms'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-client-grid',
  styles: [
    `
      .image-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);
        padding: var(--stack-big) var(--inset) 0;
        column-gap: var(--inset);
        row-gap: var(--stack);
      }

      @media screen and (max-width: 1092px) {
        .image-container {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      @media screen and (max-width: 848px) {
        .image-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @media screen and (max-width: 600px) {
        .image-container {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .client-input {
        margin-right: 10px;
        padding-top: 20px;
      }

      .client-input label {
        color: var(--subtle);
        font-family: var(--font-header-family);
        font-size: var(--font-size-tiny);
        font-weight: var(--font-header-weight);
        margin-bottom: var(--stack-tiny);
        letter-spacing: 0.3px;
        text-align: left;
        margin-left: 20px;
      }

      .client-input .mat-mdc-input-element {
        color: #ffffff;
        font-size: 14px;
      }

      .client-input mat-mdc-form-field {
        margin-left: 20px;
      }
      input {
        outline: 1px solid var(--subtle);
        padding: .6rem;
        border-radius: 4px;
        width: auto;
      }

      input:focus {
        outline: 1px solid white;
      }

      input:hover {
        outline: 1px solid white;
      }

      ::ng-deep .client-input .mat-mdc-form-field-infix {
        border-top: 0;
        padding: 0.6em 0;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
      ::ng-deep .mat-autocomplete-panel .mat-mdc-option {
        height: 32px;
        font-size: 14px;
      }

      ::ng-deep .client-input .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
      }
    `,
  ],
  template: `
    <div class="client-input">
      <label>Search for Client</label>

      <div (click)="$event.stopPropagation()">
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            [formControl]="clientControl"
            [matAutocomplete]="clientCtrl"
          />
          <mat-autocomplete
            #clientCtrl="matAutocomplete"
            (optionSelected)="getSelectedClient()"
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="let client of filteredClientsList"
              [value]="client"
              (click)="$event.stopPropagation()"
            >
              {{ client.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="image-container">
        <app-client-image-container
          *ngFor="let client of filteredClients"
          [client]="client"
          (click)="clientClick.emit(client.id)"
        ></app-client-image-container>
      </div>
    </div>
  `,
})
export class ClientGridComponent implements OnInit {
  @Input() clients: Client[]
  @Output() clientClick = new EventEmitter<string>()
  @Input() clientImagesMap: Map<string, string>
  filteredClientsList: Client[]
  filteredClients: Client[]
  clientControl = new FormControl()

  ngOnInit() {
    this.clientControl.reset()
    this.filteredClients = this.clients
    this.filteredClientsList = this.clients
    this.clientControl.valueChanges.subscribe(searchVal => {
      this.filteredClientsList = this.filterclientValues(searchVal)
    })
    this.clients.sort((a, b) => {
      return a.name.toUpperCase() < b.name.toUpperCase()
        ? -1
        : a.name.toUpperCase() > b.name.toUpperCase()
        ? 1
        : 0
    })
  }

  filterclientValues(searchVal: Client): Client[] {
    if (searchVal?.name) {
      return this.clients.filter(
        value =>
          value.name.toLowerCase().indexOf(searchVal.name.toLowerCase()) === 0
      )
    } else {
      const clientCode = searchVal as unknown as string
      if (clientCode.length == 0) {
        this.filteredClients = this.clients.filter(value =>
          value.name.toLowerCase()
        )
      }
      return this.clients.filter(value =>
        value.name.toLowerCase().includes(clientCode?.toLowerCase())
      )
    }
  }

  getSelectedClient(): void {
    this.filteredClients = this.filteredClientsList
  }

  displayFn(client: Client): string | undefined {
    return client ? client.name : undefined
  }
}
