import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  SummaryChartGridOption,
  SummaryChartInfo,
} from '../summary-charts-model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-chart-grid',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        background: var(--bg);
        padding: var(--stack) var(--inset-big);
        box-sizing: border-box;
      }

      :host > * {
        flex: 1;
      }

      :host-context(.app-theme-dark) {
        background: rgb(0, 0, 0, 0.6);
      }

      :host-context(.app-theme-light) {
        background: var(--bg);
      }

      .chart-container {
        width: 100%;
        height: 100%;
      }

      .grid {
        display: grid;
        row-gap: var(--stack-big);
        column-gap: var(--inset);
      }

      .loading {
        flex: 1;
        overflow: auto;
      }

      .loading label {
        display: block;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
      mat-progress-bar {
        position: absolute;
        bottom: 1px;
        left: 0;
        height: 1px;
      }
    `,
  ],
  template: `
    <ng-container *ngIf="!chartsLoading; else allChartsLoadingTemplate">
      <div class="grid" [ngStyle]="gridStyle">
        <ng-container *ngFor="let chartOption of chartOptions">
          <ng-container
            *ngIf="!chartOption.loading; else singleChartLoadingTemplate"
          >
            <ng-container [ngSwitch]="chartOption.chart.type">
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-bubble-chart
                  *ngSwitchCase="'bubble'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                >
                </app-explore-summary-bubble-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-donut-pie-chart
                  *ngSwitchCase="'donut'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  [roundedToAbrev]="roundedToAbrev"
                  [currentStructureCurrency]="currentStructureCurrency"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-donut-pie-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-donut-pie-chart
                  *ngSwitchCase="'pie'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  [pieChart]="true"
                  [roundedToAbrev]="roundedToAbrev"
                  [currentStructureCurrency]="currentStructureCurrency"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-donut-pie-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-group-bar-chart
                  *ngSwitchCase="'unstackedBar'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  [roundedToAbrev]="roundedToAbrev"
                  [currentStructureCurrency]="currentStructureCurrency"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-group-bar-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-stacked-bar-chart
                  *ngSwitchCase="'stackedBar'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-stacked-bar-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-percent-stacked-bar-chart
                  *ngSwitchCase="'percentStackedBar'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-percent-stacked-bar-chart>
              </ng-container>
              <ng-container
                *ngIf="showGridItem(chartOption.index)"
                class="chart-container"
              >
                <app-explore-summary-percent-stacked-area-chart
                  *ngSwitchCase="'verticalArea'"
                  [chartOption]="chartOption"
                  [maximizedIndex]="maximizedIndex"
                  [chartEntityLimit]="chartEntityLimit"
                  [selectableChartsForTab]="selectableChartsForTab"
                  [selectableChartsForMetric]="selectableChartsForMetric"
                  [hideMetrics]="hideMetrics"
                  [lightChartMode]="lightChartMode"
                  (chartOptionUpdate)="onChartOptionUpdate($event)"
                  (updateHideMetrics)="updateHideMetrics.emit($event)"
                  (maximizeClick)="onMaximizeClick($event)"
                ></app-explore-summary-percent-stacked-area-chart>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #allChartsLoadingTemplate>
      <section class="loading">
        <label big appInfoText>Loading...</label>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </section>
    </ng-template>

    <ng-template #singleChartLoadingTemplate>
      <section class="loading">
        <label big appInfoText>Loading...</label>
      </section>
    </ng-template>
  `,
})
export class ExploreSummaryChartGridComponent {
  @Input() chartOptions: SummaryChartGridOption[]
  @Input() selectableChartsForTab: SummaryChartInfo[]
  @Input() selectableChartsForMetric: SummaryChartInfo[]
  @Input() chartsLoading: boolean
  @Input() chartEntityLimit: number
  @Input() hideMetrics: boolean
  @Input() lightChartMode: boolean
  @Input() maximizedIndex: number
  @Input() roundedToAbrev: string
  @Input() currentStructureCurrency: string | undefined

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  onChartOptionUpdate(option: SummaryChartGridOption): void {
    this.chartOptionUpdate.emit(option)
  }

  onMaximizeClick(chartIndex: number): void {
    this.maximizeClick.emit(chartIndex)
  }

  get gridStyle() {
    if (this.maximizedIndex !== null) {
      return { display: 'block', height: '100%', width: '100%' }
    }
    return {
      gridTemplateColumns: ' repeat(2, 1fr)',
      gridAutoRows: '1fr',
      display: 'grid',
      rowGap: 'var(--stack-big)',
      columnGap: 'var(--inset)',
    }
  }

  showGridItem(index: number): boolean {
    if (this.maximizedIndex !== null) {
      return index === this.maximizedIndex
    }
    return true
  }
}
