import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AgencyDetails, Reinsurer } from '../../core/model/reinsurer.model'
import { AppState } from '../../core/store'
import {
  ASSIGNED_LINES_TEMP_PREFIX,
  AssignedLines,
  QuoteReinsurer,
} from '../models/quote.model'
import {
  addAssignedLines,
  deleteAssignedLines,
  updateAssignedLinesArray,
} from '../store/reinsurer/reinsurer.actions'
import { Observable } from 'rxjs'
import { saveSegregatedAccountDetails } from '../../reinsurers/store/study-reinsurers.actions'
import { selectedReinsurerEntity } from '../store/quote.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-seg-account-detail-dialog-container',
  templateUrl: './quote-seg-account-detail.container.html',
})
export class QuoteSegregatedAccountDetailsDialogContainerComponent
  implements OnInit
{
  selectedReinsurerEntity$: Observable<QuoteReinsurer | undefined>

  constructor(
    public dialogRef: MatDialogRef<QuoteSegregatedAccountDetailsDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      programID: string
      fundManager: Reinsurer
      segregatedAccounts: AgencyDetails[]
      selectedSegregatedAccounts: AgencyDetails[]
    },
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.selectedReinsurerEntity$ = this.store.pipe(
      select(selectedReinsurerEntity)
    )
  }

  onSaveSegregatedAccounts($event: {
    programID: string
    fundManager: Reinsurer
    selectedSegregatedAccounts: AgencyDetails[]
  }): void {
    this.store.dispatch(
      saveSegregatedAccountDetails({
        programID: $event.programID,
        reinsurer: $event.fundManager,
        selectedSegregatedAccounts: $event.selectedSegregatedAccounts,
      })
    )
  }

  onAddNewAssignedLines($event: {
    id: string
    assignedLines: AssignedLines
  }): void {
    this.store.dispatch(
      addAssignedLines({
        id: $event.id,
        al: $event.assignedLines,
      })
    )
  }

  onUpdateAssignedLines($event: {
    id: string
    assignedLinesUpdated: AssignedLines[]
    assignedLinesToDelete: AssignedLines[]
  }): void {
    this.store.dispatch(
      updateAssignedLinesArray({
        id: $event.id,
        assignedLines: $event.assignedLinesUpdated,
      })
    )
    $event.assignedLinesToDelete.forEach(line => {
      if (line.id && !line.id.startsWith(ASSIGNED_LINES_TEMP_PREFIX)) {
        this.store.dispatch(deleteAssignedLines({ id: line.id }))
      }
    })
  }
}
