import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RoleGuard } from '../guards/role-guard.service'
import { TrackingRouteContainerComponent } from './tracking-route.container'

const routes: Routes = [
  {
    path: '',
    component: TrackingRouteContainerComponent,
    data: { contextPath: ['tracking'] },
    canActivate: [RoleGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrackingRoutingModule {}
