import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { InuranceService } from '../../../../api/inurance/inurance.service'
import { ProgramGroupService } from '../../../../api/program-group/program-group.service'
import {
  mergeMapWithInput,
  rejectError,
  rejectErrorWithOnlyInput,
} from '../../../../api/util'
import { AppState } from '../../../../core/store'
import { selectCurrentClientID } from '../../../../core/store/broker/broker.selectors'
import { selectProgramGroupSetState } from '../../../../core/store/program-group-member.selectors'
import { GroupScenariosDialogService } from '../../../group/services/group-scenarios-dialog.service'
import { ProgramGroupMemberEntity } from '../program-group-member/program-group-member.reducer'
import {
  GroupScenario,
  ProgramGroupEntityWithProgramPortfolioIDs,
} from '../program-group.model'
import { selectProgramPortfolioSetIDAndName } from '../program/program.selectors'
import * as ProgramGroupActions from './program-group-scenarios.actions'
import {
  saveProgramGroupScenariosFailure,
  saveProgramGroupScenariosSuccess,
} from './program-group-scenarios.actions'

@Injectable()
export class GrouperProgramGroupScenariosEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private programGroupService: ProgramGroupService,
    private inuranceService: InuranceService,
    private groupScenariosDialog: GroupScenariosDialogService
  ) {}

  saveProgramGroupScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.saveProgramGroupScenarios),
      map(({ type, ...props }) => props),
      // Given a list of the group scenarios to add and a list to remove,
      // convert them to a corresponding:
      // (1) list of the groups w/ their child structure portfolios IDs, and
      // (2) a flat list of all group members being added or removed
      map(({ add, remove }) => {
        const groups = add
          .map(makeProgramGroupWithStructurePortfoliosCreator(false))
          .concat(
            remove.map(makeProgramGroupWithStructurePortfoliosCreator(true))
          )

        const members = add
          .flatMap(makeProgramGroupMembersCreator(false))
          .concat(remove.flatMap(makeProgramGroupMembersCreator(true)))

        return { groups, members }
      }),
      concatMap(props =>
        of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClientID)),
            this.store.pipe(select(selectProgramGroupSetState))
          ),
          map(([input, clientID, state]) => ({ ...input, clientID, state }))
        )
      ),
      mergeMapWithInput(({ members, state }) => {
        return this.inuranceService.validateOnGroupSave(members, state)
      }),
      rejectErrorWithOnlyInput(error =>
        this.store.dispatch(saveProgramGroupScenariosFailure({ error }))
      ),
      mergeMap(({ clientID, groups, members }) =>
        this.programGroupService.save(clientID, groups, members)
      ),
      rejectError(error =>
        this.store.dispatch(saveProgramGroupScenariosFailure({ error }))
      ),
      map(saveProgramGroupScenariosSuccess)
    )
  )

  closeAfterSaveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProgramGroupActions.saveProgramGroupScenariosSuccess),
        map(() => this.groupScenariosDialog.close())
      ),
    { dispatch: false }
  )
}

const makeProgramGroupMembersCreator =
  (remove: boolean) =>
  (entity: GroupScenario): ProgramGroupMemberEntity[] =>
    entity.members.map(programGroupMember => ({
      programGroupMember,
      new: !remove,
      deleted: remove,
      dirty: true,
      root: false,
      hash: '',
    }))

const makeProgramGroupWithStructurePortfoliosCreator =
  (remove: boolean) =>
  (entity: GroupScenario): ProgramGroupEntityWithProgramPortfolioIDs => ({
    programGroup: entity.group,
    programPortfolioIDs: entity.structureScenarios.map(s =>
      selectProgramPortfolioSetIDAndName(s.scenarios[s.index])
    ),
    new: !remove,
    deleted: remove,
    dirty: true,
  })
