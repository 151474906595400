import {
  HttpErrorResponse,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http'
import { isNil, splitEvery } from 'ramda'
import {
  concat,
  forkJoin,
  Observable,
  ObservableInput,
  of,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeLast,
  takeWhile,
  withLatestFrom,
} from 'rxjs/operators'
import { ApplicationError, errorPayload } from '../error/model/error'
import { ApiResponse, HasData, MaybeData, MaybeError } from './model/api.model'

export const mapToMaybeData = <T>(): OperatorFunction<T, MaybeData<T>> =>
  map((res: T) => ({ data: res }))

export const mapToData = <T>(): OperatorFunction<T, HasData<T>> =>
  map((res: T) => ({ data: res }))

export const asApiResponse = <T>(
  callback: () => T
): MaybeData<T> & MaybeError => {
  try {
    const data = callback()
    return { data }
  } catch (e) {
    const err = e && e.message ? (e as Error) : Error('Unknown Error')
    const stack = err.stack || ''
    return { error: errorPayload(err.message, stack.split('\n')) }
  }
}

export function handleError<T extends object>(op = 'API', result?: T) {
  return (err: unknown): Observable<T & MaybeError> => {
    let message = `${op} failed.`
    let details: string[] = []
    const reason = (err as any)?.reason
    if (!isNil(reason)) {
      details = [(err as any).reason]
    } else {
      if ((err as any)?.status === 403) {
        message = 'The user has no access to this Entity'
      }
      if ((err as any)?.status === 404) {
        message = 'No data found'
      }
      details = getDetailsMessage(err)
    }
    const text = getTextMessage(err)
    console.error(message, err)
    const error = errorPayload(message, details, { text })
    return of(Object.assign({}, { error }, result) as MaybeError & T)
  }
}

export const catchAndHandleError = <T, R extends object>(
  op?: string,
  result?: R
): OperatorFunction<T, T | (R & MaybeError)> =>
  catchError(handleError(op, result))

export const mapToErrorUnless = (
  successPredicate: (res: string) => boolean
): OperatorFunction<string, MaybeError> =>
  map((res: string) =>
    res && successPredicate(res)
      ? {}
      : ({ error: errorPayload(res) } as MaybeError)
  )

const getDetailsMessage = (e: unknown): string[] => {
  if (e instanceof HttpErrorResponse) {
    const messages = [e.message]
    const msg = e?.error?.message ?? e?.error?.Message
    if (msg) {
      messages.push(msg)
    }
    return messages
  } else {
    if (e instanceof Error) {
      const messages = [e.message]
      return messages
    }
  }
  return []
}

const getTextMessage = (e: unknown): string => {
  if (e instanceof HttpErrorResponse) {
    return e?.error?.text ?? ''
  } else if (e instanceof Error) {
    return e.message
  }
  return ''
}

export const assocLatestFrom = <TInput, T, K extends string>(
  prop: K,
  project: ObservableInput<T>
): OperatorFunction<MaybeError & TInput, MaybeError & TInput & Record<K, T>> =>
  concatMap(id =>
    of(id).pipe(
      withLatestFrom(project),
      map(([res, latest]) => {
        const data = { [prop]: latest } as Record<K, T>
        return { ...res, ...data }
      })
    )
  )

export const mapAssoc = <TInput, T, K extends string>(
  prop: K,
  project: (value: TInput, index: number) => T
): OperatorFunction<MaybeError & TInput, MaybeError & TInput & Record<K, T>> =>
  map((value, index) => {
    if (value.error) {
      return value as MaybeError & TInput & Record<K, T>
    }
    const projectedValue = project(value, index)
    const associatedValue = { [prop]: projectedValue } as Record<K, T>
    return Object.assign(value, associatedValue)
  })

export const mapResponse = <TInput, T>(
  project: (value: TInput, index: number) => T
): UnaryFunction<ApiResponse<TInput>, ApiResponse<T>> =>
  map((value, index) => {
    if (value.error) {
      return value as MaybeError
    }
    // tslint:disable-next-line: no-non-null-assertion
    return { data: project(value.data!, index) }
  })

type Primitive = string | number | boolean | bigint | symbol | null | undefined
type Expand<T> = T extends Primitive ? T : { [K in keyof T]: T[K] }

export const switchMapAssoc = <TInput, T, K extends string>(
  prop: K,
  project: (value: TInput, index: number) => ApiResponse<T>
): OperatorFunction<
  MaybeError & TInput,
  MaybeError & Expand<TInput & Record<K, T>>
> =>
  switchMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError & Expand<TInput & Record<K, T>>)
    }
    return project(value, index).pipe(
      map(res => {
        const error = res.error
        const data = { [prop]: res.data } as Record<K, T>
        return { error, ...value, ...data } as MaybeError &
          Expand<TInput & Record<K, T>>
      })
    )
  })

export const switchMapResponse = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<ApiResponse<TInput>, ApiResponse<T>> =>
  switchMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError)
    }
    // tslint:disable-next-line: no-non-null-assertion
    return project(value.data!, index)
  })

export const switchMapWithInput = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<
  Observable<TInput>,
  ApiResponse<readonly [T | undefined, TInput]>
> =>
  switchMap((value, index) =>
    project(value, index).pipe(
      map(res => ({ ...res, data: [res.data, value] as const }))
    )
  )

export const mergeMapAssoc = <TInput, T, K extends string>(
  prop: K,
  project: (value: TInput, index: number) => ApiResponse<T>
): OperatorFunction<MaybeError & TInput, MaybeError & TInput & Record<K, T>> =>
  mergeMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError & TInput & Record<K, T>)
    }
    return project(value, index).pipe(
      map(res => {
        const error = res.error
        const data = { [prop]: res.data } as Record<K, T>
        return { error, ...value, ...data }
      })
    )
  })

export const mergeMapResponse = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<ApiResponse<TInput>, ApiResponse<T>> =>
  mergeMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError)
    }
    // tslint:disable-next-line: no-non-null-assertion
    return project(value.data!, index)
  })

export const mergeMapWithInput = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<
  Observable<TInput>,
  ApiResponse<readonly [T | undefined, TInput]>
> =>
  mergeMap((value, index) =>
    project(value, index).pipe(
      map(res => ({ ...res, data: [res.data, value] as const }))
    )
  )

export const concatMapAssoc = <TInput, T, K extends string>(
  prop: K,
  project: (value: TInput, index: number) => ApiResponse<T>
): OperatorFunction<MaybeError & TInput, MaybeError & TInput & Record<K, T>> =>
  concatMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError & TInput & Record<K, T>)
    }
    return project(value, index).pipe(
      map(res => {
        const error = res.error
        const data = { [prop]: res.data } as Record<K, T>
        return { error, ...value, ...data }
      })
    )
  })

export const concatMapResponse = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<ApiResponse<TInput>, ApiResponse<T>> =>
  concatMap((value, index) => {
    if (value.error) {
      return of({ ...value } as MaybeError)
    }
    // tslint:disable-next-line: no-non-null-assertion
    return project(value.data!, index)
  })

export const concatMapWithInput = <TInput, T>(
  project: (value: TInput, index: number) => ApiResponse<T>
): UnaryFunction<
  Observable<TInput>,
  ApiResponse<readonly [T | undefined, TInput]>
> =>
  concatMap((value, index) =>
    project(value, index).pipe(
      map(res => ({ ...res, data: [res.data, value] as const }))
    )
  )

export const rejectError = <T>(
  onErrorFn: (err: ApplicationError) => void
): UnaryFunction<ApiResponse<T>, Observable<NonNullable<T>>> =>
  // @ts-ignore
  pipe(
    // @ts-ignore
    filter((res: MaybeData<T> & MaybeError) => {
      if (res.error) {
        onErrorFn(res.error)
        return false
      }
      return true
    }),
    map(res => {
      // tslint:disable-next-line:no-non-null-assertion
      return res.data!
    })
  )

export const rejectErrorWithInput = <T, TInput>(
  onErrorFn: (err: ApplicationError, input: TInput) => void
): UnaryFunction<
  ApiResponse<readonly [T | undefined, TInput]>,
  Observable<readonly [NonNullable<T>, TInput]>
> =>
  // @ts-ignore
  pipe(
    filter(({ error, data }) => {
      if (error) {
        // tslint:disable-next-line: no-non-null-assertion
        onErrorFn(error, data![1])
        return false
      }
      return true
    }),
    map(({ data }) => {
      // tslint:disable-next-line: no-non-null-assertion
      return [data![0]!, data![1]]
    })
  )

export const rejectErrorWithOnlyInput = <T, TInput>(
  onErrorFn: (err: ApplicationError, input: TInput) => void
): UnaryFunction<
  ApiResponse<readonly [T | undefined, TInput]>,
  Observable<TInput>
> =>
  pipe(
    rejectErrorWithInput(onErrorFn),
    map(([_, input]) => input)
  )

export const mergeApiResponses = <T>(): UnaryFunction<
  Observable<Array<MaybeData<T> & MaybeError>>,
  ApiResponse<T[]>
> =>
  map(responses => {
    const merged: MaybeData<T[]> & MaybeError = {}
    const [r, ...rs] = responses.filter(res => res.error != null)
    if (r && r.error) {
      const details = rs.reduce((acc, res) => {
        // tslint:disable-next-line: no-non-null-assertion
        const e = res.error!
        return [...acc, '', e.message, '', ...e.details]
      }, r.error.details)
      merged.error = { ...r.error, details }
    } else {
      // tslint:disable-next-line: no-non-null-assertion
      merged.data = responses.map(res => res.data!)
    }
    return merged
  })

// This version keeps track of the last emitted value of all actions and returns them as an array.
export const executeSequentially = <T>(
  actions: ApiResponse<T>[]
): ApiResponse<Array<T>> => {
  const results: Array<T> = []
  return concat(...actions).pipe(
    takeWhile(r => !r.error, true),
    map(r => {
      if (r.error) {
        return { error: r.error }
      }
      results.push(r.data as T)
      return { data: results }
    }),
    takeLast(1)
  )
}

export const executeSequentiallyInGroup = <T>(
  actions: ApiResponse<T>[],
  groupSize: number
): ApiResponse<Array<T>> => {
  const groupedActions = splitEvery(groupSize, actions).map(g =>
    forkJoin(g).pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error }
          }
        }
        return { data: responses.map(r => r.data as T) }
      })
    )
  )
  return executeSequentially(groupedActions).pipe(
    map(r => {
      if (r.error) {
        return { error: r.error }
      } else {
        return { data: r.data?.flatMap(d => d) }
      }
    })
  )
}

export function getQueryParamKvps(object: any): Record<string, string> {
  const kvps: Record<string, string> = {}
  for (const prop in object) {
    if (!object[prop]) {
      continue
    }
    kvps[prop] = object[prop]
  }
  return kvps
}

export function encodeParams(
  params: Record<string, string | string[]> | undefined
): HttpParams | undefined {
  if (!params) {
    return undefined
  }
  let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() })
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      for (const param of value as string[]) {
        httpParams = httpParams.append(key, param)
      }
    } else {
      httpParams = httpParams.append(key, value)
    }
  })
  return httpParams
}

class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}
