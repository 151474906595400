import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'

import {
  SavedCurveSelectors,
  DialogData,
  PricingCurveStatus,
  SavedPricingCurveEntry,
  PricingCurveContextTypes,
  PricingCurveData,
  PricingCurveAddDataDialogTabs,
  PricingCurveLayer,
  PricingCurveTypes,
  SubmitPricingCurvePayload,
  TechnicalFactors,
  CreditCurveLayer,
  PricingCurveGraphSettings,
} from '../../../model/pricing-curve.model'
import {
  PricingCurveAddDataDialogPayload,
  TabStatus,
} from '../../dialog/pricing-curve-dialog.model'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'
import { State as AuthState } from 'src/app/core/store/auth/auth.state.facade'


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-add-data-dialog',
  styleUrls: ['pricing-curve-add-data-dialog.component.scss'],
  templateUrl: 'pricing-curve-add-data-dialog.component.html',
})
export class PricingCurveAddDataDialogComponent implements OnInit {
  @Input() set workingCurveData(value: PricingCurveData) {
    this._workingCurveData = value
    if (this.graphSettings) {
      this.workingCurve = new PricingCurve(
        {
          ...value,
          label: this.formData.label ?? value.label,
          curveType: this.formData.curveType ?? value.curveType,
        },
        this.graphSettings,
        null
      )
    }
  }
  get workingCurveData(): PricingCurveData {
    return this._workingCurveData
  }
  _workingCurveData: PricingCurveData
  @Input() set dialogData(value: DialogData) {
    this._dialogData = value
    this.tab = value.tab
  }
  get dialogData(): DialogData {
    return this._dialogData
  }
  _dialogData: DialogData
  @Input() savedCurves: SavedPricingCurveEntry[] | undefined
  @Input() savedCurveSelectors: SavedCurveSelectors | undefined
  @Input() status: PricingCurveStatus | undefined
  @Input() currentDataSetNames: string[] | undefined
  @Input() savedCurveNameMap: { id: number; name: string }[] | undefined
  @Input() addedCurveIds: number[]
  @Input() data: PricingCurveAddDataDialogPayload
  @Input() context: PricingCurveContextTypes
  @Input() readonly: boolean
  @Input() set graphSettings(value: PricingCurveGraphSettings) {
    this._graphSettings = value
    if (value && this.workingCurveData) {
      this.workingCurve = new PricingCurve(
        {
          ...this.workingCurveData,
          label: this.formData.label ?? this.workingCurveData.label,
          curveType: this.formData.curveType ?? this.workingCurveData.curveType,
        },
        value,
        null
      )
    }
  }
  get graphSettings(): PricingCurveGraphSettings {
    return this._graphSettings
  }
  _graphSettings: PricingCurveGraphSettings

  @Output() tabChanged = new EventEmitter<PricingCurveAddDataDialogTabs>()
  @Output() layerSplitViewChanged = new EventEmitter<boolean>()
  @Output() submitCurve = new EventEmitter<SubmitPricingCurvePayload>()
  @Output() setManualCurve = new EventEmitter<boolean>()
  tab: PricingCurveAddDataDialogTabs
  workingCurve: PricingCurve
  tabHidden: TabStatus
  hideTabBar: boolean
  form: FormGroup

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.tabHidden = this.data.tabsToHide ?? {
      layers: false,
      manual: false,
      saved: false,
    }
    this.hideTabBar = this.data.tabsToHide
      ? Object.values(this.data.tabsToHide).filter(tab => tab).length > 1
      : false
    this.tabChanged.emit(this.data.startingTab)

    this.form = this.formBuilder.group({
      label: new FormControl(),
      curveType: new FormControl(),
    })
    this.form.valueChanges.subscribe(
      (val: {
        label?: string
        curveType?: PricingCurveTypes
        minPremium?: number | string
        scaleFactor?: string
      }) => {
        if (val.label) {
          this.workingCurve.label = val.label
        }
        if (val.curveType) {
          this.workingCurve.curveType = val.curveType
        }
        if (val.minPremium) {
          let minPremium = val.minPremium
          if (typeof minPremium === 'string') {
            minPremium = minPremium.replace('%', '')
          }
          this.workingCurve.minimumPremium = Number(minPremium) / 100
        }
        if (val.scaleFactor) {
          this.workingCurve.scaleFactor = Number(val.scaleFactor)
        }
      }
    )
  }

  get formData(): { label?: string; curveType?: PricingCurveTypes } {
    return {
      label: this.form?.get('label')?.value,
      curveType: this.form?.get('curveType')?.value,
    }
  }

  get dataSetId(): number | undefined {
    if (this.data && this.data.id !== undefined) {
      return this.data.id
    }
  }

  get layerTabLabel(): string {
    if (this.readonly) {
      return 'View Tranche Data'
    }
    return this.editMode ? 'Edit Layers' : 'Add New Curve'
  }

  get techFactorsTabLabel(): string {
    return this.editMode ? 'Edit Tech Premium Details' : 'Add Manual Curve'
  }

  selectionChanged(layers: PricingCurveLayer[] | CreditCurveLayer[]): void {
    if (this.context === 'pricing-curve') {
      this.workingCurve.layers = layers as PricingCurveLayer[]
    } else if (this.context === 'credit') {
      this.workingCurve.creditLayers = layers as CreditCurveLayer[]
    }
  }

  submitWorkingCurveAsLayerSet(): void {
    this.workingCurve.curveType = PricingCurveTypes.LAYER_SET
    this.workingCurve.technicalFactors = null
    this.workingCurve.lineVisible = false
    this.submitCurve.emit({ data: this.workingCurve.curveData, save: false })
  }

  techFactorsChanged(techFactors: TechnicalFactors): void {
    this.workingCurve.technicalFactors = techFactors
  }

  predictionColumnsChanged(event: { key: string; isActive: boolean }): void {
    this.workingCurve.updatePredictionColumn(event.key, event.isActive)
  }

  tabClicked(tabName: PricingCurveAddDataDialogTabs): void {
    if (this.status?.dialogLoading || this.tab === tabName) {
      return
    }
    this.tabChanged.emit(tabName)

    // If the user makes changes, then doesn't confirm. Remove the changes
    this.workingCurve = new PricingCurve(
      this.workingCurveData,
      this.graphSettings,
      null
    )
    if (!this.editMode) {
      this.setManualCurve.emit(tabName === 'manual')
    }
  }

  get editMode(): boolean {
    return this.data && this.data.isEdit
  }

  get editManual(): boolean {
    return this.workingCurve && this.workingCurve.isManual
  }

  navigateToCurveFactors(): void {
    // Curve layers have been selected, hide tab bar and change tab to tech factors
    this.hideTabBar = true
    this.tabChanged.emit('manual')
  }
}
