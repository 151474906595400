import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppState } from '../core/store'
import { State as ThemeState } from '../core/store/theme/theme.state.facade'
import { selectThemeState } from '../core/store/theme/theme.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-theme-container',
  template: `
    <app-theme [theme]="theme">
      <ng-content></ng-content>
    </app-theme>
  `,
})
export class ThemeContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  theme: ThemeState

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .pipe(takeUntil(this.destroy$), select(selectThemeState))
      .subscribe(value => {
        this.theme = value
        this.cdRef.markForCheck()
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
