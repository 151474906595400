import {
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
  LayerPaletteItem,
  LayerPaletteProgram,
} from '../../../analysis/model/layer-palette.model'
import { MIValueType } from '../../../management-information/model/management-information.model'
import { IControl } from '../../../management-information/store/management-information.reducer'
import {
  LayerQuoteStats,
  QuoteSelectors,
  QuoteSelectorsDto,
  QuoteStats,
  QuoteSummaryData,
} from '../model/quote-management-information.model'
import {
  LAYER_NAME_AGG,
  LAYER_NAME_XOL,
  QuoteSummaryColumn,
  QuoteSummaryContent,
  QuoteSummaryTile,
  emptyTile,
} from '../model/quote-summary.model'

export const getAudienceLayerNames = (
  audienceView: LayerPaletteProgram
): string[] => {
  if (audienceView === 'standard') {
    return LAYER_PALETTE.map(lp => `${getProgramNameFromLayer(lp)} - ${lp.name}`)
  }
  return LAYER_PALETTE.filter(lp => lp.program === audienceView).map(
    lp => lp.name
  )
}

export const getProgramNameFromLayer = (
  layer: LayerPaletteItem
): string => {
  return LAYER_PALETTE_PROGRAMS[layer.program]
}

export const getDefaultAudienceLayerNames = (
  audience: LayerPaletteProgram
): string[] => {
  const isStandard = audience === 'standard'
  return getAudienceLayerNames(audience).filter(name => {
    const layerName = isStandard ? name.split(' - ')[1] : name
    return layerName === LAYER_NAME_XOL || layerName === LAYER_NAME_AGG
  })
}

export const getLayerNameFromId = (id: string, audience: LayerPaletteProgram): string => {
  const layer =  LAYER_PALETTE.find(lp => lp.id === id)
  if(!layer) {
    return ''
  }
  const isStandard = audience === 'standard'
  const programName = isStandard ? `${getProgramNameFromLayer(layer)} - ` : ''
  return `${programName}${layer.name}`
}

export const getLayerIdFromName = (
  name: string,
  audience: LayerPaletteProgram
): string => {
  return (
    LAYER_PALETTE.find(lp => {
      const isStandard = audience === 'standard'
      const layerName = isStandard ? name.split(' - ')[1] : name
      const program = isStandard ? getProgramFromLayerName(name.split(' - ')[0]): audience
      return lp.name === layerName && lp.program === program
    })?.id ?? ''
  )
}

export const getProgramFromLayerName = (name: string): LayerPaletteProgram | '' => {
  for (const [programKey, programName] of Object.entries(LAYER_PALETTE_PROGRAMS)) {
    if (name === programName) {
      return programKey as LayerPaletteProgram
    }
  }
  return ''
}

export const getQuoteSelectors = (
  state: QuoteSelectors,
  selectorsDto: QuoteSelectorsDto,
  audience: LayerPaletteProgram
): QuoteSelectors => {
  const entries: [string, string[]][] = Object.entries(selectorsDto).filter(
    e => e[0] !== 'layerCategory'
  )
  const newState: any = {}
  for (const [selectorName, applicableValues] of entries) {
    const selectorFromState = (state as any)[selectorName] as IControl
    const selectedValues = selectorFromState.selectedValues
    let newAllValues = selectorFromState.allValues
    let newValues = applicableValues.filter(v => v.length > 0) || []
    if ((selectorName as keyof QuoteSelectors) === 'layerType') {
      newValues = newValues.map(id => getLayerNameFromId(id, audience)) || []
    }
    if (newValues.length === 0) {
      newValues = selectedValues
    }
    if (newAllValues.length === 0) {
      newAllValues = newValues
    }
    newState[selectorName as keyof QuoteSelectors] = {
      columnName: selectorName,
      name: selectorName,
      id: selectorName,
      selectedValues,
      allValues: newAllValues,
      values: newValues,
    }
  }
  return newState as QuoteSelectors
}

export const getQuoteSummaryContent = (
  audienceView: LayerPaletteProgram,
  data?: QuoteSummaryData,
  selectedLayerNames?: string[]
): QuoteSummaryContent => {
  return {
    expiring: {
      isMultiSection: true,
      columnSections: [
        {
          name: 'expiringRates',
          columns: getQuoteSummaryColumns(
            data?.expiringRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'expiringExpCedLossByDepositPremium',
          columns: getQuoteSummaryColumns(
            data?.expiringExpCedLossByDepositPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'expiringExpCedLossByExpCedPremium',
          columns: getQuoteSummaryColumns(
            data?.expiringExpCedLossByExpCedPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
      ],
    },
    quote: {
      isMultiSection: true,
      columnSections: [
        {
          name: 'quoteRates',
          columns: getQuoteSummaryColumns(
            data?.quoteRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'quoteExpCedLossByDepositPremium',
          columns: getQuoteSummaryColumns(
            data?.quoteExpCedLossByDepositPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'quoteExpCedLossByExpCedPremium',
          columns: getQuoteSummaryColumns(
            data?.quoteExpCedLossByExpCedPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
      ],
    },
    fot: {
      isMultiSection: true,
      columnSections: [
        {
          name: 'fotRates',
          columns: getQuoteSummaryColumns(
            data?.fotRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotExpCedLossByDepositPremium',
          columns: getQuoteSummaryColumns(
            data?.fotExpCedLossByDepositPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotExpCedLossByExpCedPremium',
          columns: getQuoteSummaryColumns(
            data?.fotExpCedLossByExpCedPremium ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
      ],
    },
    fotVsQuoteVsExpiring: {
      isMultiSection: true,
      columnSections: [
        {
          name: 'quotedVsExpiringRates',
          columns: getQuoteSummaryColumns(
            data?.quotedVsExpiringRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'quotedVsExpiringCapacity',
          columns: getQuoteSummaryColumns(
            data?.quotedVsExpiringCapacity ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotVsExpiringRates',
          columns: getQuoteSummaryColumns(
            data?.fotVsExpiringRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotVsExpiringCapacity',
          columns: getQuoteSummaryColumns(
            data?.fotVsExpiringCapacity ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotVsQuotedRates',
          columns: getQuoteSummaryColumns(
            data?.fotVsQuotedRates ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'fotVsQuotedCapacity',
          columns: getQuoteSummaryColumns(
            data?.fotVsQuotedCapacity ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'percentage',
            },
            selectedLayerNames
          ),
        },
      ],
    },
    occurrence: {
      isMultiSection: true,
      columnSections: [
        {
          name: 'occurrenceLimit',
          columns: getQuoteSummaryColumns(
            data?.occurrenceLimit ?? [],
            {
              audienceView,
              isMultiSection: true,
              columnValueType: 'currency',
            },
            selectedLayerNames
          ),
        },
        {
          name: 'occurrenceAttachment',
          columns: getQuoteSummaryColumns(
            data?.occurrenceAttachment ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'currency',
            },
            selectedLayerNames
          ),
        },
      ],
    },
    totalBoundLimitVsTotalQuotedLimit: {
      isMultiSection: false,
      columnSections: [
        {
          name: 'totalBoundLimitVsTotalQuotedLimit',
          columns: getQuoteSummaryColumns(
            data?.totalBoundLimitVsTotalQuotedLimit ?? [],
            {
              audienceView,
              isMultiSection: false,
              columnValueType: 'numeric-decimal',
            },
            selectedLayerNames
          ),
        },
      ],
    },
  }
}

export const getQuoteSummaryColumns = (
  data: LayerQuoteStats[],
  options: {
    audienceView: LayerPaletteProgram
    isMultiSection: boolean
    columnValueType: MIValueType
  },
  selectedLayerNames?: string[]
): QuoteSummaryColumn[] => {
  let audienceLayerNames = getAudienceLayerNames(options.audienceView)
  if (selectedLayerNames) {
    audienceLayerNames = audienceLayerNames.filter(name =>
      selectedLayerNames.includes(name)
    )
  }
  return audienceLayerNames.map(layerName => {
    const layerId = getLayerIdFromName(layerName, options.audienceView)
    return {
      layerType: layerId,
      tiles: getLayerColumnTiles(
        layerName,
        options.columnValueType,
        options.isMultiSection,
        data.find(stats => stats.layerType === layerId)
      ),
    }
  })
}

export const getAudienceSummaryHeader = (
  header: QuoteSummaryTile[],
  audienceView: LayerPaletteProgram
): QuoteSummaryTile[] => {
  let headerValue = ''
  const updatedHeader = header
  switch (audienceView) {
    case 'cat':
      headerValue = 'Rate-on-Line, Occurrence'
      break
    case 'noncat':
      headerValue = 'Rate, % of Subject'
      break
    case 'ahl':
      headerValue = 'PMPM'
      break
  }
  updatedHeader[1].value = headerValue
  return updatedHeader
}
export const getAudienceSummaryHeaderValue = (
  audienceView: LayerPaletteProgram
): string => {
  let headerValue = ''
  switch (audienceView) {
    case 'cat':
      headerValue = 'Rate-on-Line, Occurrence'
      break
    case 'noncat':
      headerValue = 'Rate, % of Subject'
      break
    case 'ahl':
      headerValue = 'PMPM'
      break
    case 'standard':
      headerValue = 'All'
      break
  }
  return headerValue
}

const getLayerColumnTiles = (
  layerName: string,
  columnValueType: MIValueType,
  isMultiSection: boolean,
  data?: QuoteStats
): QuoteSummaryTile[] => {
  const emptyTiles = isMultiSection
    ? [emptyTile, emptyTile] // need second empty tile if first section of multisection
    : [emptyTile]
  return [
    ...emptyTiles,
    {
      class: 'color',
      value: layerName,
      type: 'text',
    },
    {
      class: 'bg',
      metric: 'min',
      type: columnValueType,
      value: data?.min,
    },
    {
      class: 'bg',
      metric: 'avg',
      type: columnValueType,
      value: data?.avg,
    },
    {
      class: 'bg',
      metric: 'max',
      type: columnValueType,
      value: data?.max,
    },
  ]
}

export const filterDistinctLayerTypes = (types: string[]): string[] => {
  const foundTypes: string[] = []
  for (const type of types) {
    if(!foundTypes.includes(type)) {
      foundTypes.push(type)
    }
  }
  return foundTypes
}
