export function compareArray<T>(a?: T[], b?: T[]): boolean {
  if (!a && !b) {
    return true
  }
  if (!a || !b) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }
  return a.every((ai, i) => ai === b[i])
}

export function isArray2D<T>(data: T[] | T[][]): data is T[][] {
  return Array.isArray(data[0])
}
