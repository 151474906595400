// tslint:disable-next-line: max-line-length
import { Action, combineReducers } from '@ngrx/store'
import * as fromLayersViewMetrics from './layers-metrics.reducer'
import * as fromPortfolioViewDetailMetrics from './portfolio-detail-metrics.reducer'
import * as fromPortfolioViewMetrics from './portfolio-metrics.reducer'
import * as fromVolatilityMetrics from './volatility-metrics.reducer'
import * as fromCapitalMetrics from './capital-metrics.reducer'
import * as fromEfficiencyMetrics from './efficiency-metrics.reducer'
import * as fromMiscMetrics from './misc-metrics.reducer'
import * as fromTailMetrics from './tail-metrics.reducer'

export interface State {
  portfolioViewDetailMetrics: fromPortfolioViewDetailMetrics.State
  portfolioViewMetrics: fromPortfolioViewMetrics.State
  layersViewMetrics: fromLayersViewMetrics.State
  volatilityMetrics: fromVolatilityMetrics.State
  capitalMetrics: fromCapitalMetrics.State
  efficiencyMetrics: fromEfficiencyMetrics.State
  miscMetrics: fromMiscMetrics.State
  tailMetrics: fromTailMetrics.State
}

export const initialState: State = {
  portfolioViewDetailMetrics: fromPortfolioViewDetailMetrics.initialState,
  portfolioViewMetrics: fromPortfolioViewMetrics.initialState,
  layersViewMetrics: fromLayersViewMetrics.initialState,
  volatilityMetrics: fromVolatilityMetrics.initialState,
  capitalMetrics: fromCapitalMetrics.initialState,
  efficiencyMetrics: fromEfficiencyMetrics.initialState,
  miscMetrics: fromMiscMetrics.initialState,
  tailMetrics: fromTailMetrics.initialState,
}

const metricsReducer = combineReducers<State>({
  portfolioViewDetailMetrics: fromPortfolioViewDetailMetrics.reducer,
  portfolioViewMetrics: fromPortfolioViewMetrics.reducer,
  layersViewMetrics: fromLayersViewMetrics.reducer,
  volatilityMetrics: fromVolatilityMetrics.reducer,
  capitalMetrics: fromCapitalMetrics.reducer,
  efficiencyMetrics: fromEfficiencyMetrics.reducer,
  miscMetrics: fromMiscMetrics.reducer,
  tailMetrics: fromTailMetrics.reducer,
})

export function reducer(state: State | undefined, action: Action) {
  return metricsReducer(state, action)
}
