import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { Store } from "@ngrx/store"
import { AppState } from "src/app/core/store"
import { ExploreFilterMap, ExploreSummaryDatum, SummaryDataResponse, SummaryExportGroup } from "../../../explore.model"
import { AccountOpportunity, ExploreSummaryView, StudyResponse } from "src/app/api/model/backend.model"
import { Observable } from "rxjs"
import { selectCurrentAnalysisProfile } from "src/app/core/store/broker/broker.selectors"
import { AnalysisProfile } from "src/app/api/analyzere/analyzere.model"
import { selectExploreSummaryExportGroups, selectExploreSummaryExportLoading, selectGrossPortfolioViewID } from "src/app/analysis/store/analysis.selectors"
import { Program } from "src/app/core/model/program.model"
import { LossSetLayer } from '../../../../model/loss-set-layers.model'
import { getSummaryExportGroups } from "../../../store/explore.actions"
import { filterExportGroups } from "../../../explore.util"
import { MappingLabels } from "src/app/core/model/study.model"
import { Client } from "src/app/core/model/client.model"

export interface ExploreMultiSelectExportDialogData {
  program: Program | undefined
  currentStructureCurrency: string | undefined
  summaryLossSetRows: ExploreSummaryDatum[]
  summaryColumnFilterMap: ExploreFilterMap
  summaryViews: ExploreSummaryView[]
  selectedSummaryView: ExploreSummaryView
  summaryData: SummaryDataResponse[]
  lossSetLayers: LossSetLayer[]
  summaryFilterMap: ExploreFilterMap
  summaryRP: number[]
  mappingLabels: MappingLabels
  accountOpportunities: AccountOpportunity[]
  client: Client
  study: StudyResponse
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-multi-select-export-dialog-container',
  templateUrl: './explore-multi-select-export-dialog.container.html',
})

export class ExploreMultiSelectExportDialogContainerComponent implements OnInit {
  analysisProfile$: Observable<AnalysisProfile | null>
  summaryExportGroups$: Observable<SummaryExportGroup[] | null>
  grossPortfolioID$: Observable<string | null>
  summaryExportLoading$: Observable<boolean |null>
  program: Program
  currentStructureCurrency: string | undefined
  summaryLossSetRows: ExploreSummaryDatum[]
  summaryColumnFilterMap: ExploreFilterMap
  summaryViews: ExploreSummaryView[]
  selectedSummaryView: ExploreSummaryView
  summaryData: SummaryDataResponse[]
  lossSetLayers: LossSetLayer[]
  summaryFilterMap: ExploreFilterMap
  summaryRP: number[]
  mappingLabels: MappingLabels
  client: Client
  study: StudyResponse
  accountOpportunities: AccountOpportunity[]

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ExploreMultiSelectExportDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExploreMultiSelectExportDialogData
  ) {}

  ngOnInit(): void {
    this.analysisProfile$ = this.store.select(selectCurrentAnalysisProfile)
    this.summaryExportGroups$ = this.store.select(selectExploreSummaryExportGroups)
    this.grossPortfolioID$ = this.store.select(selectGrossPortfolioViewID)
    this.summaryExportLoading$ = this.store.select(selectExploreSummaryExportLoading)
    this.program = this.data.program
    this.currentStructureCurrency = this.data.currentStructureCurrency
    this.summaryLossSetRows = this.data.summaryLossSetRows
    this.summaryColumnFilterMap = this.data.summaryColumnFilterMap
    this.summaryViews = this.data.summaryViews
    this.selectedSummaryView = this.data.selectedSummaryView
    this.summaryData = this.data.summaryData
    this.lossSetLayers = this.data.lossSetLayers
    this.summaryFilterMap = this.data.summaryFilterMap
    this.summaryRP = this.data.summaryRP
    this.mappingLabels = this.data.mappingLabels
    this.client = this.data.client
    this.study = this.data.study
    this.accountOpportunities = this.data.accountOpportunities
  }

  OnGetSummaryExportGroups(exportGroups: SummaryExportGroup[]): void {
    if (exportGroups) {
      this.store.dispatch(getSummaryExportGroups({ exportGroups }))
    }
  }
}
