import { Pipe, PipeTransform } from '@angular/core'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

@Pipe({
  name: 'appMarkdown',
})
export class MarkdownPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || value.length === 0) {
      return value
    }
    return DOMPurify.sanitize(marked(value))
  }
}
