import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { filter, shareReplay, skip, takeUntil } from 'rxjs/operators'
import { CheckboxSelectChangeEvent } from './checkbox-select-button.component'
import {
  TriggerPositionDialogComponent,
  TriggerPositionDialogOptions,
} from './trigger-position-dialog.component'

export interface CheckboxSelectItem {
  id: string
  label: string
  description?: string
}

export interface CheckboxSelectDialogOptions<T extends CheckboxSelectItem>
  extends TriggerPositionDialogOptions {
  panelClass?: string
  items: T[]
  selected: Record<string, boolean>
  parent?: T
  allowSelection?: boolean
  accent?: boolean
  label?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-checkbox-select-dialog',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: column;
           }
     
           :host.align-right {
             align-items: flex-end;
           }
     
           section {
             width: 256px;
             max-height: 224px;
             margin-top: var(--stack-small);
             overflow: auto;
             background: var(--bg-1-translucent);
             border-radius: var(--border-radius-big);
             box-shadow: var(--shadow-big);
           }
     
           :host.align-bottom section {
             margin-top: 0;
             margin-bottom: var(--stack-small);
           }
     
           button {
             width: 100%;
             padding-left: 8px;
           }
     
           :host button.selected {
             color: var(--accent);
           }
     
           :host button.selected:hover,
           :host button.selected:focus {
             color: var(--accent-lit);
           }
     
           :host button.indicate-focus {
             color: var(--primary-lit);
             background: var(--bg-2-lit);
           }
     
           button .wrapper {
             display: flex;
             flex-direction: column;
             align-items: flex-start;
           }
     
           button .title {
             display: flex;
             align-items: center;
           }
     
           button .description {
             color: var(--subtle);
             font-weight: var(--font-weight);
             font-size: var(--font-size-small);
             line-height: calc(var(--font-size-small) + 3px);
             margin-bottom: 2px;
             white-space: nowrap;
             text-overflow: ellipsis;
             overflow: hidden;
             width: 100%;
             text-align: left;
           }
     
           button.has-check .description {
             margin-left: 26px;
           }
     
           mat-icon {
             width: 22px;
             height: 22px;
             font-size: 21px;
             margin-right: var(--inset-tiny);
           }
         `,
  ],
  template: `
    <ng-container *ngIf="!alignBottom">
      <ng-container *ngTemplateOutlet="selectButton"></ng-container>
    </ng-container>

    <section>
      <button
        #item
        *ngFor="let it of data.items"
        appButton
        stack
        primary
        [class.selected]="isSelected(it)"
        [class.has-check]="showCheck"
        [class.indicate-focus]="cursor.id === it.id"
        (click)="onSelect(it)"
      >
        <div class="wrapper">
          <div class="title">
            <mat-icon
              *ngIf="showCheck && isSelected(it)"
              aria-label="check"
              (click)="onCheckClick($event, it)"
            >
              check_box
            </mat-icon>
            <mat-icon
              *ngIf="showCheck && !isSelected(it)"
              aria-label="check"
              (click)="onCheckClick($event, it)"
            >
              check_box_outline_blank
            </mat-icon>
            <span [matTooltip]="getTooltip(it)" matTooltipPosition="above">
              {{ it.label }}
            </span>
          </div>

          <div
            *ngIf="it.description"
            class="description"
            [matTooltip]="it.description?.length > 32 ? it.description : ''"
            matTooltipShowDelay="500"
          >
            {{ it.description }}
          </div>
        </div>
      </button>
    </section>

    <ng-container *ngIf="alignBottom">
      <ng-container *ngTemplateOutlet="selectButton"></ng-container>
    </ng-container>

    <ng-template #selectButton>
      <app-checkbox-select-button
        dropdownOpen
        [label]="data.label"
        [items]="data.items"
        [selected]="data.selected"
        [parent]="data.parent"
        [allowSelection]="data.allowSelection"
        [accent]="data.accent"
        (selectChange)="onSelectChange($event)"
        (buttonClick)="onClose()"
      ></app-checkbox-select-button>
    </ng-template>
  `,
})
export class CheckboxSelectDialogComponent<T extends CheckboxSelectItem>
  extends TriggerPositionDialogComponent
  implements OnDestroy {
  private destroy$ = new Subject<void>()

  cursor: { id: string; index: number } = { id: '', index: -1 }

  private _selectChange$: BehaviorSubject<CheckboxSelectChangeEvent<T> | null>
  selectChange$: Observable<CheckboxSelectChangeEvent<T>>

  @ViewChildren('item', { read: ElementRef })
  itemElements?: QueryList<ElementRef>

  @HostListener('document:keydown', ['$event']) onKeyup($event: KeyboardEvent) {
    if (($event.target as any)?.type === 'text') {
      // Ignore keystrokes in inputs
      return
    }
    switch ($event.key) {
      case ' ':
      case 'Enter':
        this.selectCursor()
        break
      case 'ArrowDown':
        this.moveCursor(1)
        break
      case 'ArrowUp':
        this.moveCursor(-1)
        break
    }
  }

  @HostBinding('class.align-right')
  get isAlignedRight(): boolean {
    return this.alignRight
  }

  @HostBinding('class.align-bottom')
  get isAlignedBottom(): boolean {
    return this.alignBottom
  }

  get showCheck(): boolean {
    return this.data.allowSelection === true
  }

  constructor(
    protected dialogRef: MatDialogRef<CheckboxSelectDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: CheckboxSelectDialogOptions<T>,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(dialogRef, elementRef, renderer)

    this.triggerRef = data.triggerRef
    this.topOffset = data.topOffset
    this.leftOffset = data.leftOffset
    this.expectedWidth = data.expectedWidth
    this.expectedHeight = data.expectedHeight
    this.externalClickIgnoreClasses = []

    this._selectChange$ = new BehaviorSubject(null)
    this.selectChange$ = this._selectChange$.asObservable().pipe(
      takeUntil(this.destroy$),
      skip(1),
      filter<CheckboxSelectChangeEvent<T>>(value => value != null),
      shareReplay(1)
    )

    this.setData(data)
  }

  ngOnDestroy() {
  this.destroy$.next()
    this.destroy$.complete()
  }

  setData(data: CheckboxSelectDialogOptions<T>): void {
    if (this.data === data) {
      this.data = data
    }

    if (
      this.cursor.index === -1 ||
      !data.items.find(it => it.id === this.cursor.id)
    ) {
      this.cursor.index = 0
      this.cursor.id = data.items[0]?.id ?? ''
    }
  }

  getTooltip(item: T): string {
    return item.label.length <= 16 ? '' : item.label
  }

  isSelected(item: T): boolean {
    return this.data.selected[item.id] === true
  }

  onSelect($event: T): void {
    this._selectChange$.next({
      [this.data.selected[$event.id] ? 'remove' : 'add']: [$event],
      parent: this.data.parent,
    })
  }

  onSelectChange($event: CheckboxSelectChangeEvent<T>): void {
    this._selectChange$.next($event)
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onCheckClick($event: MouseEvent | TouchEvent, item: T) {
    $event.preventDefault()
    $event.stopPropagation()
    this.onSelect(item)
  }

  private moveCursor(n: number) {
    const prevIndex = this.cursor.index
    const nextIndex = prevIndex + n
    if (nextIndex < 0) {
      this.cursor.index = this.data.items.length + nextIndex
    } else if (nextIndex > this.data.items.length - 1) {
      this.cursor.index = nextIndex - this.data.items.length
    } else {
      this.cursor.index = nextIndex
    }

    this.cursor.id = this.data.items[this.cursor.index].id

    this.itemElements?.forEach((el, i) => {
      if (i === this.cursor.index) {
        const _el = el.nativeElement as HTMLDivElement
        if (_el) {
          _el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    })
  }

  private selectCursor(): void {
    const item = this.data.items.find(it => it.id === this.cursor.id)
    if (item) {
      this.onSelect(item)
    }
  }
}
