import { createAction, props } from '@ngrx/store'
import { ApplicationError } from './../../../error/model/error'
import {
  CreditMetricConfiguration,
  CreditMetricSetting,
} from './../../model/credit-metric.model'

enum CreditMetricActions {
  Reset = '[Credit Metrics] Reset',
  GetMetricConfigurationNamesByCarrierId = '[Credit Metrics] Get Configuration Names By CarrierId',
  GetMetricConfigurationNamesByCarrierIdSuccess = '[Credit Metrics] Get Configuration Names By CarrierId - Success',
  GetMetricConfigurationNamesByCarrierIdFailure = '[Credit Metrics] Get Configuration Names By CarrierId - Failure',
  SelectMetricConfiguration = '[Credit Metrics] Select Metric Configuration By Name',
  SelectMetricConfigurationSuccess = '[Credit Metrics] Select Metric Configuration By Name - Success',
  SelectMetricConfigurationFailure = '[Credit Metrics] Select Metric Configuration By Name - Failure',
  CreateMetricConfiguration = '[Credit Metrics] Create Metric Configuration',
  CreateMetricConfigurationSuccess = '[Credit Metrics] Create Metric Configuration - Success',
  CreateMetricConfigurationFailure = '[Credit Metrics] Create Metric Configuration - Failure',
  UpdateMetricConfigurationName = '[Credit Metrics] Update Metric Configuration Name',
  GetCreditMetricSettings = '[Credit Metrics] Get Metric Settings',
  GetCreditMetricSettingsSuccess = '[Credit Metrics] Get Metric Settings - Success',
  GetCreditMetricSettingsFailure = '[Credit Metrics] Get Metric Settings - Failure',
  SaveMetricSettings = '[Credit Metrics] Save Metric Settings',
  SaveMetricSettingsSuccess = '[Credit Metrics] Save Metric Settings - Success',
  SaveMetricSettingsFailure = '[Credit Metrics] Save Metric Settings - Failure',
  UpdateMetricSetting = '[Credit Metrics] Update Metric Setting',
  AddMetricSetting = '[Credit Metrics] Add Metric Setting',
  DeleteMetricSetting = '[Credit Metrics] Delete Metric Setting by Id',
  DeleteMetricSettingSuccess = '[Credit Metrics] Delete Metric Setting by Id - Success',
  DeleteMetricSettingFailure = '[Credit Metrics] Delete Metric Setting by Id - Failure',
  EnableAllMetricSettings = '[Credit Metrics] Enable All Metric Settings',
}

export const Reset = createAction(CreditMetricActions.Reset)

export const GetMetricConfigurationNamesByCarrierId = createAction(
  CreditMetricActions.GetMetricConfigurationNamesByCarrierId,
  props<{ carrierId: number }>()
)
export const GetMetricConfigurationNamesByCarrierIdFailure = createAction(
  CreditMetricActions.GetMetricConfigurationNamesByCarrierIdFailure,
  props<{ error: ApplicationError }>()
)
export const GetMetricConfigurationNamesByCarrierIdSuccess = createAction(
  CreditMetricActions.GetMetricConfigurationNamesByCarrierIdSuccess,
  props<{ metricConfigurationNames: string[] }>()
)

export const SelectMetricConfiguration = createAction(
  CreditMetricActions.SelectMetricConfiguration,
  props<{ metricConfigurationName: string }>()
)
export const SelectMetricConfigurationFailure = createAction(
  CreditMetricActions.SelectMetricConfigurationFailure,
  props<{ error: ApplicationError }>()
)
export const SelectMetricConfigurationSuccess = createAction(
  CreditMetricActions.SelectMetricConfigurationSuccess,
  props<{ metricConfiguration: CreditMetricConfiguration }>()
)

export const CreateMetricConfiguration = createAction(
  CreditMetricActions.CreateMetricConfiguration
)
export const CreateMetricConfigurationFailure = createAction(
  CreditMetricActions.CreateMetricConfigurationFailure,
  props<{ error: ApplicationError }>()
)
export const CreateMetricConfigurationSuccess = createAction(
  CreditMetricActions.CreateMetricConfigurationSuccess,
  props<{ metricConfiguration: CreditMetricConfiguration }>()
)

export const UpdateMetricConfigurationName = createAction(
  CreditMetricActions.UpdateMetricConfigurationName,
  props<{ metricConfigurationName: string }>()
)

export const GetCreditMetricSettings = createAction(
  CreditMetricActions.GetCreditMetricSettings
)
export const GetCreditMetricSettingsFailure = createAction(
  CreditMetricActions.GetCreditMetricSettingsFailure,
  props<{ error: ApplicationError }>()
)
export const GetCreditMetricSettingsSuccess = createAction(
  CreditMetricActions.GetCreditMetricSettingsSuccess,
  props<{ metricSettings: CreditMetricSetting[] }>()
)

export const SaveCreditMetricSettings = createAction(
  CreditMetricActions.SaveMetricSettings
)
export const SaveCreditMetricSettingsFailure = createAction(
  CreditMetricActions.SaveMetricSettingsFailure,
  props<{ error: ApplicationError }>()
)
export const SaveCreditMetricSettingsSuccess = createAction(
  CreditMetricActions.SaveMetricSettingsSuccess,
  props<{ metricSettings: CreditMetricSetting[] }>()
)

export const UpdateCreditMetricSetting = createAction(
  CreditMetricActions.UpdateMetricSetting,
  props<{ metricSetting: CreditMetricSetting }>()
)

export const AddCreditMetricSetting = createAction(
  CreditMetricActions.AddMetricSetting,
  props<{ metricSetting: CreditMetricSetting }>()
)

export const DeleteCreditMetricSetting = createAction(
  CreditMetricActions.DeleteMetricSetting,
  props<{ metricSetting: CreditMetricSetting }>()
)
export const DeleteCreditMetricSettingFailure = createAction(
  CreditMetricActions.DeleteMetricSettingFailure,
  props<{ error: ApplicationError }>()
)
export const DeleteCreditMetricSettingSuccess = createAction(
  CreditMetricActions.DeleteMetricSettingSuccess,
  props<{ metricSetting: CreditMetricSetting }>()
)

export const EnableAllCreditMetricSettings = createAction(
  CreditMetricActions.EnableAllMetricSettings
)
