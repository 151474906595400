<div class="add">
  <div class="inline">
  Select Field:
  <button appButton big [matMenuTriggerFor]="addMenu">
    {{ selectedField }}
  </button>
  <mat-menu #addMenu="matMenu" class="add-menu">
    <button
      mat-menu-item
      *ngFor="let p of qqOptions"
      [matTooltip]="p"
      (click)="onChangeField($event)"
    >
      {{ p }}
    </button>
  </mat-menu>
  </div>
  <div class="inline right" matTooltip="Toggle on to auto-fill panels with last saved values" matTooltipPosition="below">
    <mat-slide-toggle class="check" [checked]="autoFill"
      (change)="onUpdateAutoFillSelection($event)">Auto-fill</mat-slide-toggle>
  </div>
  <div>
    <h6>*Selecting different field will lose unsaved data.</h6>
    <h5 *ngIf="selectedField === 'Rate'">
      Rate field is either 'Rate On Line' or 'Rate % of Subject' or 'PMPM'
      or 'CedingCommission' based on the layer type.
    </h5>
  </div>
</div>
<app-sort-table
  [columnDefs]="quickQuoteColumnDefs"
  [rows]="quickQuoteRows"
  [excelEnabled]="true"
  [isQQ]="true"
  (valueChange)="onValueChange($event)"
  (versionClick)="onVersionClick($event)"
>
</app-sort-table>
