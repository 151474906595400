import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  LayerEntry,
  CurveEntryUpdatePayload,
  LayerTypeDefaultEntry,
} from '../technical-premium.model'
import { updateLayerTypeValue } from '../../store/technical-premium/technical-premium.actions'
import {
  layerIds,
  LAYER_PALETTE_VIEWS,
  LayerPaletteView,
} from '../../model/layer-palette.model'
import { LayerService } from '@shared/services/layer.service'
import { onlyUnique } from 'src/app/pricingcurve/pricing-curve.utils'
import { StudyResponse } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-default-list',
  styleUrls: ['./program-default-list.component.scss'],
  templateUrl: './program-default-list.component.html',
})
export class ProgramDefaultListComponent implements OnInit, OnChanges {
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() layerTypeEntries: LayerTypeDefaultEntry
  @Input() studies: StudyResponse[]
  @Input() selectedProgramID: string
  views: LayerPaletteView[] = LAYER_PALETTE_VIEWS

  @Output() dialogClosed = new EventEmitter()

  initialLayerTypeEntries: LayerTypeDefaultEntry
  entryHasError = false
  viewId: string | null
  viewLayerTypes: string

  usedLayerTypeEntries: LayerTypeDefaultEntry = {}

  constructor(
    private store: Store<AppState>,
    private layerService: LayerService
  ) {}

  ngOnInit(): void {
    this.determineViewType()
    this.initEntriesList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layerTypeEntries) {
      this.entryHasError = Object.values(this.layerTypeEntries).some(
        entry => entry.hasError
      )
      this.initEntriesList()
    }
  }

  initEntriesList(): void {
    this.usedLayerTypeEntries = {}
    const paletteView = LAYER_PALETTE_VIEWS.find(
      view => view.id === this.viewId
    )
    if (!paletteView) {
      this.usedLayerTypeEntries = this.layerTypeEntries
      return
    }
    const newUsedLayerTypeEntries = { ...this.usedLayerTypeEntries }
    const viewLayerTypes = this.layerService.getLayerIds(paletteView.id)
    if (viewLayerTypes.includes(layerIds.catIlw)) {
      const index = viewLayerTypes.indexOf(layerIds.catIlw)
      viewLayerTypes.splice(index, 1)
      viewLayerTypes.push(layerIds.ilwBin, layerIds.ilwProRata)
    }
    viewLayerTypes.filter(onlyUnique).map(layerType => {
      if (this.layerTypeEntries[layerType]) {
        newUsedLayerTypeEntries[layerType] = this.layerTypeEntries[layerType]
      }
    })
    this.usedLayerTypeEntries = newUsedLayerTypeEntries
    const entries = Object.values(newUsedLayerTypeEntries)
    if (entries.every(entry => !entry.modified)) {
      this.initialLayerTypeEntries = { ...newUsedLayerTypeEntries }
    }
  }

  determineViewType(): void {
    const selectedStudyView = this.studies?.find(
      s => s.id.toString() === this.selectedProgramID
    )?.sage_view
    const selectedViewId = this.views.find(
      v => v.name === selectedStudyView
    )?.id
    this.viewId = selectedViewId ? selectedViewId : '1'
  }

  setEntryByLayerType(layerType: string, value?: LayerEntry) {
    const defaultEntry = this.initialLayerTypeEntries[layerType]
    const newValue = value ?? defaultEntry
    this.store.dispatch(updateLayerTypeValue({ layerType, newValue }))
  }

  handleCurveOperation(data: CurveEntryUpdatePayload): void {
    this.setEntryByLayerType(data.layerType, data.newEntry)
  }

  resetEntryToInitialValue(layerType: string): void {
    this.setEntryByLayerType(layerType)
  }
}
