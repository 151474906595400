import {
  getCurveTypeFromTechFactors,
  getKeyValuePairs,
  onlyUnique,
} from 'src/app/pricingcurve/pricing-curve.utils'
import {
  IControl,
  PCClass,
  Selectors,
  CombinedSelectors,
  PricingCurveLayer,
  SELECTORS_INITIAL_STATE,
  DATE_INTERVALS_INITIAL_STATE,
  TechnicalFactors,
  TECHNICAL_FACTORS_DEFAULT_STATE,
  SavedPricingCurveEntry,
  LayerResponse,
  PricingCurveData,
  PricingCurveTypes,
  CREDIT_PREDICTION_COLUMNS,
  CreditCurvePredictionColumn,
  CreditCurvePredictionColumns,
  CREDIT_SELECTORS_INITIAL_STATE,
  CreditSelectors,
} from '../../pricingcurve/model/pricing-curve.model'
import { assoc } from 'ramda'
import { Client } from 'src/app/core/model/client.model'

export interface SavedPricingCurveResponse {
  id: number
  pc_name: string
  expected_loss_multiplier: number
  expected_loss_power: number
  volatility_multiplier: number
  volatility_metric: string
  fixed_cost: number
  minimum_rate_on_line: number
  maximum_rate_on_line: number
  included_excluded_layers: string
  max_ceding_commission_percentage: number
  reinsurer_margin_percentage: number
  active_filters: string
  audit_update_dt: string
  audit_insert_dt: string
  audit_created_by: string
  audit_updated_by: string
  fullName: string
  isCombined: boolean
}

export type SavedPricingCurveDto = Omit<
  SavedPricingCurveResponse,
  | 'audit_update_dt'
  | 'audit_insert_dt'
  | 'audit_created_by'
  | 'audit_updated_by'
  | 'fullName'
  | 'id'
>

export type SavedCreditCurveResponse = {
  id?: number
  pc_name: string
  prediction_filters: string
  included_tranche_ids: string
  prob_of_attach: number
  prob_of_detach: number
  mi_attach: number
  mi_detach: number
  expected_line_of_loss: number
  mi_thickness: number
  oltv: number
  fico: number
  dti: number
  intercept: number
  r_squared: number
  active_filters: string
  minimum_percent: number
  scaling_factor: number
  audit_update_dt: string
  audit_insert_dt: string
  audit_created_by: string
  audit_updated_by: string
  fullName: string
  is_public: boolean
  carrier_ids: string
}

export type SavedCreditCurveDto = Omit<
  SavedCreditCurveResponse,
  | 'audit_update_dt'
  | 'audit_insert_dt'
  | 'audit_created_by'
  | 'audit_updated_by'
  | 'fullName'
>

export interface SavedLayersDto {
  historicalLayers: string
  layers: string
}

export type SelectorsResponse = {
  layerCategory: string[]
  inceptionDate: string
  clientName: string[]
  reinstatement: string[]
  status: string[]
  ignoreForPricingCurve: string[]
  territory: string[]
  placedThrough: string[]
  perils: string[]
  correspondentBroker: string[]
  lossImpactedFromPrevYear: string[]
  pcClasses: PCClass[]
}

export type SelectorsDto = Omit<
  SelectorsResponse,
  'pcClasses' | 'inceptionDate'
>

export interface CombinedSelectorsDto
  extends Omit<SelectorsDto, 'inceptionDate'> {
  inceptionDateMin: string
  inceptionDateMax: string
  pcClass: string[]
  pcSubClass: string[]
}

export function convertSelectorResponse(
  response: SelectorsResponse,
  selectorState?: CombinedSelectors
): CombinedSelectors {
  const { pcClasses, inceptionDate, ...selectors } = response
  const pcClass = pcClasses.map(item => item.pcClass)
  const pcSubClass = pcClasses.map(item => item.pcSubClass).filter(onlyUnique)
  return {
    selectors: initSelectors(
      selectors,
      pcClass,
      pcSubClass,
      selectorState?.selectors
    ),
    dateIntervals: selectorState?.dateIntervals ?? [],
  }
}

export function convertLayerResponse(
  layerResponses: LayerResponse[]
): PricingCurveLayer[] {
  return layerResponses.map((entry, index) => {
    const { el, trol, include, ...props } = entry
    return {
      id: index,
      el,
      trol,
      include,
      props,
    }
  })
}

export function convertSavedCreditCurveResponseToSavedCurveEntries(
  responses: SavedCreditCurveResponse[]
): SavedPricingCurveEntry[] {
  return responses.map(response => ({
    id: response.id,
    pc_name: response.pc_name,
    included_excluded_layers: response.included_tranche_ids.split(','),
    audit_update_dt: response.audit_update_dt,
    audit_insert_dt: response.audit_insert_dt,
    audit_created_by: response.audit_created_by,
    audit_updated_by: response.audit_updated_by,
    fullName: response.fullName,
    techFactors: undefined,
  }))
}

export function convertSavedCurveResponsesToSavedCurveEntries(
  responses: SavedPricingCurveResponse[]
): SavedPricingCurveEntry[] {
  return responses.map(response => {
    const parsedResponse = convertSavedTechFactorsToLocalTechFactors(response)
    const parsedIncludedLayers = parseIncludedExcludedLayers(
      parsedResponse.included_excluded_layers
    )
    const {
      active_filters,
      audit_created_by,
      audit_insert_dt,
      audit_update_dt,
      audit_updated_by,
      fullName,
      id,
      pc_name,
      isCombined,
      ...techFactors
    } = parsedResponse
    return {
      audit_created_by,
      audit_insert_dt,
      audit_update_dt,
      audit_updated_by,
      fullName,
      id,
      pc_name,
      isCombined,
      techFactors,
      included_excluded_layers: parsedIncludedLayers,
      active_filters: parsedResponse.active_filters
        ? parseSelectorsFromActiveFilters(parsedResponse.active_filters)
        : undefined,
    }
  })
}

export function convertPricingCurveToCreditCurveDto(
  curve: PricingCurveData,
  clients?: Client[],
  id?: number
): SavedCreditCurveDto {
  // tslint:disable-next-line: no-non-null-assertion
  const activePredictionColumns = Object.values(curve.creditPredictionColumns!)
    .filter(val => val.isActive)
    .map(val => val.key)
    .toString()
  const includedTrancheIds = curve.creditLayers
    .filter(layer => layer.include)
    .map(layer => layer.trancheId)
    .toString()
  const publicOrgs = ['Freddie', 'Fannie']
  const creditCarriers = curve.creditCarriers ?? []
  const organizations = creditCarriers.filter(
    val => !val.startsWith('Peer') && !publicOrgs.includes(val)
  )
  const is_public = organizations.every(val => publicOrgs.includes(val))

  const fullCarrierNames = organizations.map(org =>
    org === 'Demo'
      ? 'Mortgage Credit'
      : curve.creditLayers.find(layer => layer.organization === org).carrier
  )

  const carrier_ids =
    fullCarrierNames
      .map(carrier => clients.find(client => client.name === carrier))
      .filter(carrier => !!carrier)
      .map(val => val.id) ?? []

  // tslint:disable-next-line: no-non-null-assertion
  const prediction = curve.creditPredictions!
  return {
    pc_name: curve.label,
    prediction_filters: activePredictionColumns,
    included_tranche_ids: includedTrancheIds,
    prob_of_attach: prediction.coefficient_pairs.pAttach,
    prob_of_detach: prediction.coefficient_pairs.pDetach,
    mi_detach: prediction.coefficient_pairs.mi_detach,
    mi_attach: prediction.coefficient_pairs.mi_attach,
    expected_line_of_loss: prediction.coefficient_pairs.expectedLossOnLine,
    mi_thickness: prediction.coefficient_pairs.mi_thickness,
    dti: prediction.coefficient_pairs.dti,
    fico: prediction.coefficient_pairs.fico,
    intercept: prediction.intercept,
    oltv: prediction.coefficient_pairs.oltv,
    r_squared: prediction.r_squared,
    active_filters: JSON.stringify(
      getKeyValuePairs([], Object.values(curve.creditSelectors ?? {}))
    ),
    minimum_percent: curve.minimumPremium,
    scaling_factor: curve.scaleFactor,
    id,
    is_public,
    carrier_ids: carrier_ids.join(','),
  }
}

export function convertPricingCurveToSavedCurveDto(
  curve: PricingCurveData,
  id?: number
): SavedPricingCurveDto & { id?: number } {
  let savedLayers: SavedLayersDto | string | undefined
  let filters = ''
  const parsedCurve = convertLocalTechFactorsToSavedTechFactors(curve)
  if (parsedCurve.layers && !parsedCurve.isManual) {
    const historicalLayers = parsedCurve.layers.filter(
      layer => layer.props.hist_data_id && layer.include
    )
    const nonHistoricalLayers = parsedCurve.layers.filter(
      layer => (layer.props.ral_id || layer.props.rr_id) && layer.include
    )
    savedLayers = {
      historicalLayers: JSON.stringify(
        historicalLayers.map(layer => `${layer.props.hist_data_id}`)
      ),
      layers: JSON.stringify(
        nonHistoricalLayers.map(
          layer => `${layer.props.rr_id}-${layer.props.ral_id}`
        )
      ),
    }
  }
  if (parsedCurve.selectors && parsedCurve.dateIntervals) {
    filters = JSON.stringify(
      getKeyValuePairs(
        parsedCurve.dateIntervals,
        Object.values(parsedCurve.selectors)
      )
    )
  }

  return {
    pc_name: parsedCurve.label,
    included_excluded_layers: JSON.stringify(savedLayers),
    active_filters: filters,
    id,
    isCombined: !parsedCurve.layerSplitView,
    // tslint:disable-next-line: no-non-null-assertion
    ...parsedCurve.technicalFactors!,
  }
}

const parseSelectorsFromActiveFilters = (
  activeFilters: string | object | undefined
): CombinedSelectors | undefined => {
  if (!activeFilters) {
    return
  }
  const selectors = { ...SELECTORS_INITIAL_STATE }

  // Remove any defined min/max values from the initial state
  const dateIntervals = [...DATE_INTERVALS_INITIAL_STATE].map(interval => ({
    ...interval,
    minValue: '',
    maxValue: '',
  }))
  const selectorEntries: [string, string | string[]][] =
    typeof activeFilters === 'string'
      ? Object.entries(JSON.parse(activeFilters))
      : Object.entries(activeFilters)

  for (const [selectorKey, selectorValues] of selectorEntries) {
    if (
      Object.keys(selectors).includes(selectorKey) &&
      typeof selectorValues !== 'string'
    ) {
      const item = selectors[selectorKey as keyof Selectors]
      selectors[selectorKey as keyof Selectors] = {
        ...item,
        selectedValues: selectorValues ?? [],
      }
    } else if (
      dateIntervals.find(item => selectorKey.includes(item.columnName))
    ) {
      let row = dateIntervals.find(item =>
        selectorKey.includes(item.columnName)
      )
      if (row && typeof selectorValues === 'string') {
        if (selectorKey.toLocaleLowerCase().includes('min')) {
          row = assoc('minValue', selectorValues, row)
        } else if (selectorKey.toLocaleLowerCase().includes('max')) {
          row = assoc('maxValue', selectorValues, row)
        }
        dateIntervals.splice(0, 1, row)
      }
    }
  }

  return {
    selectors,
    dateIntervals,
  } as CombinedSelectors
}

const parseIncludedExcludedLayers = (
  includedExcluded: string | undefined
): string[] => {
  if (!includedExcluded) {
    return []
  }
  const parsedObject: SavedLayersDto = JSON.parse(includedExcluded)
  if (parsedObject.historicalLayers || parsedObject.layers) {
    const historicalLayers = JSON.parse(parsedObject.historicalLayers)
    const layers = JSON.parse(parsedObject.layers)
    return historicalLayers.concat(layers)
  } else {
    return []
  }
}

const initSelectors = (
  selectorsDto: SelectorsDto,
  pcClass: string[],
  pcSubClass: string[],
  state?: Selectors
): Selectors => {
  const entries: [string, string[]][] = Object.entries(selectorsDto)
  entries.push(['pcClass', pcClass], ['pcSubClass', pcSubClass])
  const newState: any = state ? { ...state } : { ...SELECTORS_INITIAL_STATE }

  for (const [selectorKey, selectorValues] of entries) {
    // Get all non-empty values
    let allValues = selectorValues.filter(val => !!val.trim().length)
    let selectedValues: string[] = []
    let values = allValues
    if (state) {
      const selectorFromState = (state as any)[selectorKey] as
        | IControl
        | undefined
      selectedValues =
        selectorFromState?.selectedValues ??
        selectorFromState?.defaultValues ??
        []

      // If there is a selected value, keep all values from previous state to allow user to broaden search
      if (selectorFromState && selectedValues.length) {
        allValues = selectorFromState.allValues
        values = allValues
      }
    }

    newState[selectorKey as keyof Selectors] = {
      selectedValues,
      values,
      allValues,
      columnName: selectorKey,
    }
  }

  const perilsValues = initPerilsValues(newState.perils.allValues)
  newState.perils = {
    ...newState.perils,
    values: perilsValues,
    allValues: perilsValues,
  }

  return newState as Selectors
}

const initCreditSelectors = (creditSelectorsState: string): CreditSelectors => {
  const selectors = { ...CREDIT_SELECTORS_INITIAL_STATE }
  if (!creditSelectorsState.length) {
    return selectors
  }
  const selectedValues: Record<string, string[]> =
    JSON.parse(creditSelectorsState)
  for (const [key, value] of Object.entries(selectedValues)) {
    const creditKey = key as keyof CreditSelectors
    const currentSelector = selectors[creditKey]
    selectors[creditKey] = {
      ...currentSelector,
      selectedValues: value,
    }
  }
  return selectors
}

const initPerilsValues = (perils: string[]) => {
  const values: string[] = []

  for (const peril of perils) {
    switch (peril) {
      case 'WS+EQ':
        values.push('Windstorm', 'Earthquake')
        break
      case 'ANP & Non-Natural':
        values.push('ANP', 'Non-Natural')
        break
      default:
        if (peril) {
          values.push(peril)
        }
        break
    }
  }
  return values.filter(onlyUnique)
}

export function convertCreditSavedCurveResponseToPricingCurve(
  response: SavedCreditCurveResponse
): PricingCurveData {
  const updatedColumns: Record<string, CreditCurvePredictionColumn> = {}
  const basePredictionColumnEntries = Object.entries(CREDIT_PREDICTION_COLUMNS)
  const includedKeys = response.prediction_filters.split(',')
  basePredictionColumnEntries.map(([key, value]) => {
    updatedColumns[key] = {
      ...value,
      isActive: includedKeys.includes(value.key),
    }
  })
  return {
    cardIndex: -1,
    context: 'credit',
    creditLayers: [],
    curveType: PricingCurveTypes.EL_LINEAR,
    dateIntervals: null,
    graphColorClass: '',
    graphColorRgb: undefined,
    id: response.id,
    initialSetup: false,
    isEdit: false,
    isManual: false,
    label: response.pc_name,
    layers: [],
    layerSplitView: false,
    selectors: null,
    creditSelectors: initCreditSelectors(response.active_filters),
    technicalFactors: null,
    visibilityOptions: {
      layersVisible: true,
      lineVisible: true,
    },
    creditPredictionColumns: updatedColumns as CreditCurvePredictionColumns,
    includedLayerIds: response.included_tranche_ids.split(','),
    minimumPremium: response.minimum_percent,
    scaleFactor: response.scaling_factor,
  }
}

export function convertSavedCurveResponseToPricingCurve(
  response: SavedPricingCurveResponse
): PricingCurveData {
  const curveFactors: Record<string, any> = {
    ...TECHNICAL_FACTORS_DEFAULT_STATE,
  }

  const parsedResponse = convertSavedTechFactorsToLocalTechFactors(response)
  for (const [key, value] of Object.entries(parsedResponse)) {
    if (curveFactors[key]) {
      curveFactors[key] = value
    }
  }
  const technicalFactors = curveFactors as TechnicalFactors
  const curveType = getCurveTypeFromTechFactors(technicalFactors)
  const combinedSelectors = parseSelectorsFromActiveFilters(
    response.active_filters
  )
  const includedLayerIds = getIncludedLayerIdsFromPricingCurveResponse(response)
  return {
    id: response.id,
    curveType,
    label: response.pc_name,
    layers: [],
    creditLayers: [],
    technicalFactors,
    selectors: combinedSelectors?.selectors ?? null,
    dateIntervals: combinedSelectors?.dateIntervals ?? null,
    creditSelectors: null,
    layerSplitView: !response.isCombined,
    graphColorClass: '',
    graphColorRgb: undefined,
    includedLayerIds,
    visibilityOptions: {
      layersVisible: true,
      lineVisible: true,
    },
    cardIndex: -1,
    isEdit: false,
    initialSetup: false,
    isManual: !includedLayerIds?.length,
    context: 'pricing-curve',
    minimumPremium: null,
    scaleFactor: null,
  }
}
// The db saves the percentages as ###.### but on the front end we want everything to be .###
const convertSavedTechFactorsToLocalTechFactors = (
  response: SavedPricingCurveResponse
): SavedPricingCurveResponse => {
  return {
    ...response,
    fixed_cost: response.fixed_cost / 100,
    minimum_rate_on_line: response.minimum_rate_on_line / 100,
    maximum_rate_on_line: response.maximum_rate_on_line / 100,
    reinsurer_margin_percentage: response.reinsurer_margin_percentage / 100,
    max_ceding_commission_percentage:
      response.max_ceding_commission_percentage / 100,
  }
}

const convertLocalTechFactorsToSavedTechFactors = (
  inputCurve: PricingCurveData
): PricingCurveData => {
  const curve = { ...inputCurve }
  if (!curve.technicalFactors) {
    return curve
  }
  const techFactors = curve.technicalFactors
  const newTechFactors = {
    ...techFactors,
    fixed_cost: techFactors.fixed_cost * 100,
    minimum_rate_on_line: techFactors.minimum_rate_on_line * 100,
    maximum_rate_on_line: techFactors.maximum_rate_on_line * 100,
    reinsurer_margin_percentage: techFactors.reinsurer_margin_percentage * 100,
    max_ceding_commission_percentage:
      techFactors.max_ceding_commission_percentage * 100,
  }
  curve.technicalFactors = newTechFactors
  return curve
}

const getIncludedLayerIdsFromPricingCurveResponse = (
  response: SavedPricingCurveResponse
): string[] | undefined => {
  if (!response.included_excluded_layers) {
    return []
  }
  const includedLayers: SavedLayersDto =
    response.included_excluded_layers.length > 0
      ? JSON.parse(response.included_excluded_layers)
      : { historicalLayers: '[]', layers: '[]' }
  if (!includedLayers) {
    return []
  }
  const includedLayerIds: string[] = JSON.parse(
    includedLayers.historicalLayers
  ).concat(JSON.parse(includedLayers.layers))
  return includedLayerIds
}
