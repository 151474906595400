import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { StudyData } from '../../../admin/model/admin.model'
import {
  AccountOpportunity,
  CarrierAccount,
  StudyDataWithYear,
} from '../../../api/model/backend.model'
import { Client } from '../../../core/model/client.model'
import { AccountMappingData } from '../../model/program-initiation.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-initiation',
  styleUrls: [`./program-initiation.component.scss`],
  templateUrl: `./program-initiation.component.html`,
})
export class ProgramInitiationComponent {
  @Input() name = 'Program Initiation'
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() carrierAccounts: CarrierAccount[] | null
  @Input() isSaving: boolean | false
  @Input() carriersByID: Record<string, Client> | null
  @Input() allCarriers: any
  @Input() unfilteredAccountOpportunities: AccountOpportunity[] | null
  @Input() currentClientID: string | null

  @Output() newProgramCreate = new EventEmitter<Partial<StudyData>>()
  @Output() newYearProgramCreate = new EventEmitter<StudyDataWithYear>()
  @Output() newCarrierYearProgramCreate = new EventEmitter<any>()
  @Output() filteredAccountOpportunities = new EventEmitter<string[]>()
  @Output() updateSFMapping = new EventEmitter<AccountMappingData>()
}
