<section>
  <mat-form-field>
    <mat-label>Select Section From Open Twins to Prefill</mat-label>
    <input
      matInput
      [formControl]="sectionControl"
      [matAutocomplete]="autoSection"
      #sectionInput
    />
    <mat-autocomplete #autoSection="matAutocomplete" panelWidth="908px">
      <mat-optgroup
        *ngFor="let group of filteredRiskGroups | async"
        [label]="group.groupName"
      >
        <mat-option
          *ngFor="let risk of group.risks"
          [value]="getSectionValue(risk)"
          (onSelectionChange)="onSelectedRisk(risk)"
        >
          <small>
            <span> {{ getSectionValue(risk) }}</span>
          </small>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  <div mat-dialog-actions>
    <button
      appButton
      border
      center
      accent
      [disabled]="!selectedRiskAndSection"
      (click)="onOKClick()"
    >
      OK
    </button>
    <button appButton link [mat-dialog-close]="false">Cancel</button>
  </div>
</section>
