import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { getDefaultBrokerage } from '../quote.util'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { AppState } from '../../core/store'
import { Layer } from '../../analysis/model/layers.model'
import {
  selectAllGrouperProgramCededLayerStates,
  selectCededLayers,
  selectCurrentCededLayer,
  selectEditorState,
  selectGrouperAnalysisProfileID,
  selectGrouperProgramGroupMembers,
} from '../../analysis/store/analysis.selectors'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import {
  addAssignedLines,
  addOrUpdateQuoteReinsurer,
  addSubjectivity,
  declineQuoteReinsurer,
  deleteAssignedLines,
  deleteQuoteReinsurer,
  deleteReinstatement,
  deleteSubjectivity,
  deleteUnsavedQuoteReinsurer,
  deleteUnsavedSubjectivity,
  expandClick,
  exportToggleReinsurer,
  populateFromTo,
  populateSubjectivitiesArray,
  preferredQuoteReinsurer,
  saveLayerClassPerSection,
  saveQuoteReinsurer,
  setSubAlReNameAndId,
  slidingValueUpdate,
  updateAssignedLineReinsurer,
  updateAssignedLinesArray,
  updateLabel,
  updateQuoteFields,
  updateReinsurerName,
  updateSelectedID,
  updateSubjectivitiesArray,
} from '../store/reinsurer/reinsurer.actions'
import {
  exportToggleSection,
  saveQuoteSection,
} from '../store/section/section.actions'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import {
  ASSIGNED_LINES_TEMP_PREFIX,
  AssignedLines,
  createQuoteID,
  QuickQuoteUpdates,
  QUOTE_TEMP_PREFIX,
  QuoteCompareViewUpdatePayload,
  QuoteCustomCompareView,
  QuoteExport,
  QuoteFields,
  QuoteReinsurer,
  ReinsurerPhase,
  ReinsurerPhases,
  ReinsurerSubjectivity,
  Section,
  SlidingScale,
  Subjectivity,
  SUBJECTIVITY_TEMP_PREFIX,
} from '../models/quote.model'
import {
  assignedLineReId,
  assignedLineReName,
  expandedName,
  isExpanded,
  isSaving,
  quoteRiskSubjectivityLinks,
  selectAddOrUpdateData,
  selectAutoBuildLoading,
  selectAutoFill,
  selectBureaus,
  selectedReinsurer,
  selectExternalVendor,
  selectLoading,
  selectQuoteCededLayerID,
  selectQuoteCustomCompareViewsForCurrentSection,
  selectQuoteDirty,
  selectQuoteIsGroupSelected,
  selectQuoteIsSLSelected,
  selectQuoteSectionID,
  selectQuoteSharedLimitID,
  selectQuoteSharedLimitlayers,
  selectQuoteStructureGroupID,
  selectQuoteStructureID,
  selectQuoteStudyID,
  selectReinsurers,
  selectSections,
  selectSelectedField,
  selectTerritories,
} from '../store/quote.selectors'
import { SectionState } from '../store/section/section.reducer'
import {
  checkIfProgramHasRenewedFrom,
  deleteQuoteComparison,
  exportAsExcel,
  fetchAutoBuildExpiring,
  fetchExternalVendorByStructureID,
  fetchSignedLinesFromWS,
  fetchTerritories,
  QuoteStartProps,
  saveQuoteComparison,
  setAnalysisOrReinitialize,
  setSectionID,
  startGroupQuote,
  startQuote,
  startSLQuote,
  updateQuoteComparisonsFromRemoveDialog,
  updateSelectedCompareView,
} from '../store/quote.actions'
import { StudyReinsurersState } from '../../reinsurers/store/study-reinsurers.reducer'
import {
  selectAllStudiesReinsurerState,
  selectSelectedStudyReinsurers,
} from '../../reinsurers/store/reinsurers.selectors'
import { Client, ClientYear } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import { Program } from '../../core/model/program.model'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import {
  ProgramGroup,
  ProgramGroupMember,
  SharedLimit,
} from '../../analysis/store/grouper/program-group.model'
import {
  selectCurrentStructure,
  selectCurrentStudyPrograms,
} from '../../core/store/program/program.selectors'
import {
  selectCurrentClient,
  selectCurrentClientYears,
  selectCurrentStructureID,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectCurrentYearStudies,
  selectStructureFilter,
} from '../../core/store/broker/broker.selectors'
import {
  selectHasWhitespaceAccess,
  selectIsUsaBasedUser,
  selectSharedLimits,
  selectThumbnailBlob,
} from '../../core/store/auth/auth.selectors'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { CheckboxSelectChangeEvent } from '@shared/checkbox-select-button.component'
import { head } from 'ramda'
import {
  setProgramNameAndDescription,
  StructureIndexEditEvent,
  StructureNameEditEvent,
  updateProgramIndex,
} from '../../core/store/program/program.actions'
import {
  setCurrentStructure,
  setStructureFilter,
} from '../../core/store/broker/broker.actions'
import { ActivatedRoute, Router } from '@angular/router'
import {
  asUSD,
  LossSetLayer,
  MonetaryUnit,
} from '../../api/analyzere/analyzere.model'
import * as ProgramActions from '../../analysis/store/grouper/program/program.actions'
import * as CompareActions from '../../analysis/store/compare/compare.actions'
import { setExploreProgram } from '../../analysis/explore/store/explore.actions'
import { extractPortfolioSetID } from '../../analysis/model/portfolio-set-id.util'
import { setSelectedLayer } from '../../analysis/store/ceded-layers/layers.actions'
import { EditorState } from '../../analysis/store/analysis.reducer'
import { LayerView } from '../../analysis/model/layer-view'
import { Bureaus, ExternalVendorMapping } from '../../api/model/quote.model'
import { Territories } from '../../api/territory/territory.service'
import { selectCurrentClientProgramGroups } from '../../core/store/program-group/program-group.selectors'
import { selectProgramGroupMembers } from '../../core/store/program-group-member.selectors'
import * as GrouperActions from '../../analysis/store/grouper/grouper.actions'
import * as ProgramGroupActions from '../../analysis/store/grouper/program-group/program-group.actions'
import { TierPath } from '../../tier/tier.model'
import { Reinsurer } from '../../core/model/reinsurer.model'
import {
  fetchStudyReinsurer,
  openSegregatedAccountDetailsDialog,
} from '../../reinsurers/store/study-reinsurers.actions'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  addOrUpdateQuickQuote,
  saveQuickQuote,
  updateQuickQuoteAutoFill,
  updateQuickQuoteField,
} from '../store/quick-quote/quick-quote.action'
import {
  isLayerActualTopAndDrop,
  isLayerDrop,
  isLayerTop,
  isLayerTopOrDrop,
  isQSLayer,
} from 'src/app/analysis/model/layers.util'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-content-container',
  styleUrls: ['./quote-content.container.scss'],
  templateUrl: './quote-content.container.html',
})
export class QuoteContentContainerComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject()
  @Input() clientSelect = false
  @Input() selectedClientID?: string | null
  @Input() showSubjectivity = false
  @Input() showTrackingModule = false
  @Input() exportClicked = false
  @Input() exportCustomCompareClicked = false
  @Input() saveSubClicked = false
  @Input() saveAssignedLinesClicked = false
  @Input() showToggle = false
  @Input() showAssignedLines = false
  @Input() showQuickQuote = false
  @Input() saveQuickQuoteClicked = false
  @Input() showCustomCompareBuilder = false
  @Input() showCustomCompare = false
  @Input() lossSetLayers: LossSetLayer[]
  @Input() currentCompareView: QuoteCustomCompareView | null
  @Input() isGroupSelected: boolean
  @Input() isSLSelected: boolean
  reinsurerList: ReinsurerState[] | null
  studyID: string | null | undefined
  externalVendor$: Observable<ExternalVendorMapping | null>
  analysisProfileID$: Observable<string | null>
  reinsurerList$: Observable<ReinsurerState[] | null>
  sectionList$: Observable<SectionState[] | null>
  layer$: Observable<LayerState | null>
  layers$: Observable<LayerState[]>
  layerGroup$: Observable<LayerState | null>
  layersGroup$: Observable<LayerState[]>
  selectedReinsurer$: Observable<string | null>
  sectionID$: Observable<string | null | undefined>
  structureID$: Observable<string | null | undefined>
  isExpanded$: Observable<boolean>
  expandedName$: Observable<string | null>
  isSaving$: Observable<boolean>
  selectedCededLayerID$: Observable<string | null | undefined>
  reinsurersNameList$: Observable<StudyReinsurersState[]>
  studyID$: Observable<string | null | undefined>
  editor: EditorState
  prevSavedSubjectPremium: MonetaryUnit | undefined
  prevSavedSubjectPremiumLayerId: string | null | undefined
  savedSubPremMap = new Map<string, MonetaryUnit>()
  allReinsurers$: Observable<Reinsurer[] | null>
  selectedYearID$: Observable<string | null>
  years$: Observable<readonly ClientYear[]>
  selectedProgramID$: Observable<string | null>
  selectedProgramID: string | null
  programs$: Observable<readonly Study[]>
  groupOrStructureFilterSelected: 'Group' | 'Structure' | 'SL' | null
  structures$: Observable<Program[]>
  programGroups$: Observable<ProgramGroup[]>
  structureFilter$: Observable<string | null>
  thumbnailBlob$: Observable<BlobResponse | null>
  programGroupMembers$: Observable<readonly ProgramGroupMember[]>
  structureGroupMembers$: Observable<ProgramGroupMember[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  currentClient$: Observable<Client | null>
  selectedStructureID$: Observable<string | null>
  quoteDirty$: Observable<boolean>
  bureaus$: Observable<Bureaus[]>
  assignedLineReName$: Observable<string | null>
  assignedLineReId$: Observable<number | undefined>
  selectedStructureGroupID$: Observable<string | null | undefined>
  quoteRiskSubjectivityLinks$: Observable<
    ReinsurerSubjectivity[] | null | undefined
  >

  isLoading$: Observable<boolean>
  isAutoBuildLoading$: Observable<boolean>
  sharedLimits$: Observable<SharedLimit[] | null>
  selectedSharedLimitID$: Observable<string | null | undefined>
  layersSL$: Observable<Layer[] | null>
  territories$: Observable<Territories>
  selectedField$: Observable<string>
  addOrUpdateData$: Observable<QuickQuoteUpdates[]>
  hasWhitespaceAccess$: Observable<boolean | null>
  isUsaBasedUser$: Observable<boolean | null>
  autoFill$: Observable<boolean>
  customCompareViews$: Observable<QuoteCustomCompareView[]>

  showFormComponent = false
  subPremChecked = false
  currentReinsurerMap: Record<number, QuoteReinsurer> = {}
  isUsaBasedUser: boolean
  selectedProgram: Program | undefined

  @Output() exportDone = new EventEmitter()
  @Output() saveSubjectivityDone = new EventEmitter()
  @Output() showSubjectivityClick = new EventEmitter()
  @Output() expandedReinsurer = new EventEmitter<QuoteReinsurer | undefined>()
  @Output() showAssignedLinesClick = new EventEmitter()
  @Output() saveAssignedLinesDone = new EventEmitter()
  @Output() backClick = new EventEmitter()
  @Output() saveQuickQuoteDone = new EventEmitter()
  @Output() resetDisplayOnLayerGroupSLChange = new EventEmitter()

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  ngOnInit(): void {
    this.store.dispatch(fetchTerritories())
    this.analysisProfileID$ = this.store.pipe(
      select(selectGrouperAnalysisProfileID)
    )
    this.layers$ = this.store.pipe(
      select(selectCededLayers),
      map((layers: LayerState[]) => {
        if (layers.length === 0 && !this.showFormComponent) {
          this.showFormComponent = false
        }
        return layers.filter(l => !l.deleted)
      })
    )
    this.layersGroup$ = this.store.pipe(
      select(selectAllGrouperProgramCededLayerStates),
      map((layers: LayerState[]) => {
        if (layers.length === 0) {
          return []
        }
        return layers.filter(l => !l.deleted)
      })
    )
    this.layer$ = this.store.pipe(select(selectCurrentCededLayer))
    this.layerGroup$ = this.store.pipe(
      select(selectAllGrouperProgramCededLayerStates),
      map((layers: LayerState[]) => {
        if (layers.length === 0) {
          return null
        }
        let layerGroup = layers.filter(l => !l.deleted)[0]
        // Update the layergroup to hidden layer if we encounter the top layer
        if (
          layerGroup &&
          layerGroup.layer.meta_data.sage_layer_type === 'cat_td' &&
          layerGroup.layer.meta_data.sage_layer_subtype === 'virtual'
        ) {
          layerGroup = layers.find(
            x =>
              x.layer.meta_data.sage_layer_type === 'cat_td' &&
              x.layer.meta_data.sage_layer_subtype === 'actual'
          )!
        }
        return layerGroup
      })
    )
    this.sectionList$ = this.store.pipe(select(selectSections))
    this.selectedReinsurer$ = this.store.pipe(select(selectedReinsurer))
    this.sectionID$ = this.store.pipe(select(selectQuoteSectionID))
    this.structureID$ = this.store.pipe(select(selectQuoteStructureID))
    this.isExpanded$ = this.store.pipe(select(isExpanded))
    this.expandedName$ = this.store.pipe(select(expandedName))
    this.isSaving$ = this.store.pipe(select(isSaving))
    this.selectedCededLayerID$ = this.store.pipe(
      select(selectQuoteCededLayerID)
    )
    this.studyID$ = this.store.pipe(select(selectQuoteStudyID))
    this.studyID$.subscribe(x => {
      this.studyID = x
    })

    this.programGroupMembers$ = this.store.pipe(
      select(selectProgramGroupMembers)
    )
    this.structureGroupMembers$ = this.store.pipe(
      select(selectGrouperProgramGroupMembers)
    )
    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.years$ = this.store.pipe(select(selectCurrentClientYears))
    this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID))
    this.selectedProgramID$.subscribe(x => (this.selectedProgramID = x))
    this.programs$ = this.store.pipe(select(selectCurrentYearStudies))
    this.structureFilter$ = this.store.pipe(select(selectStructureFilter))
    this.thumbnailBlob$ = this.store.pipe(select(selectThumbnailBlob))
    this.externalVendor$ = this.store.pipe(select(selectExternalVendor))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.currentClient$ = this.store.pipe(select(selectCurrentClient))
    this.selectedStructureGroupID$ = this.store.pipe(
      select(selectQuoteStructureGroupID)
    )
    this.selectedSharedLimitID$ = this.store.pipe(
      select(selectQuoteSharedLimitID)
    )
    this.layersSL$ = this.store.pipe(select(selectQuoteSharedLimitlayers))
    this.reinsurerList$ = this.store.pipe(select(selectReinsurers))
    this.isLoading$ = this.store.pipe(select(selectLoading))
    this.isAutoBuildLoading$ = this.store.pipe(select(selectAutoBuildLoading))

    this.reinsurerList$.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.reinsurerList = x
    })
    this.allReinsurers$ = this.store.pipe(select(selectSelectedStudyReinsurers))
    this.selectedStructureID$ = this.store.pipe(
      select(selectCurrentStructureID),
      map(id => {
        if (id === null && !this.showFormComponent) {
          this.showFormComponent = false
        }
        return id
      })
    )
    this.reinsurersNameList$ = this.store.pipe(
      select(selectAllStudiesReinsurerState)
    )
    this.quoteDirty$ = this.store.pipe(select(selectQuoteDirty))
    this.bureaus$ = this.store.pipe(select(selectBureaus))
    this.assignedLineReName$ = this.store.pipe(select(assignedLineReName))
    this.assignedLineReId$ = this.store.pipe(select(assignedLineReId))
    this.quoteRiskSubjectivityLinks$ = this.store.pipe(
      select(quoteRiskSubjectivityLinks)
    )
    this.sharedLimits$ = this.store.pipe(select(selectSharedLimits))
    this.structures$ = this.store.pipe(
      select(selectCurrentStudyPrograms),
      map(program => {
        return program.filter(
          p =>
            !(p.libRE === 'T' && p.programType === 'LibRE Template Structure')
        )
      })
    )
    this.programGroups$ = this.store.pipe(
      select(selectCurrentClientProgramGroups)
    )
    this.territories$ = this.store.pipe(select(selectTerritories))
    this.selectedField$ = this.store.pipe(select(selectSelectedField))
    this.addOrUpdateData$ = this.store.pipe(select(selectAddOrUpdateData))
    this.hasWhitespaceAccess$ = this.store.pipe(
      select(selectHasWhitespaceAccess)
    )
    this.isUsaBasedUser$ = this.store.pipe(select(selectIsUsaBasedUser))
    this.isUsaBasedUser$.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.isUsaBasedUser = x !== null ? x : false
    })
    this.autoFill$ = this.store.pipe(select(selectAutoFill))
    this.customCompareViews$ = this.store.pipe(
      select(selectQuoteCustomCompareViewsForCurrentSection)
    )
    this.store
      .pipe(select(selectCurrentStructure))
      .subscribe(program => (this.selectedProgram = program))
  }

  onPopulateAssignedLinesFromWhiteSpace($event: { reinsurerID: string }): void {
    this.store.dispatch(
      fetchSignedLinesFromWS({ reinsurerId: $event.reinsurerID })
    )
  }

  isCatTopDropOrHidden(layer: Layer): boolean {
    return isLayerTopOrDrop(layer) || isLayerActualTopAndDrop(layer)
  }

  getCatTDHiddenLayer(
    layer: Layer,
    layerStates: LayerState[]
  ): Layer | undefined {
    if (layer.meta_data.sage_layer_subtype === 'actual') {
      return layer
    }
    return layerStates.find(l => {
      return l.layer.layerRefs.includes(layer.id)
    })?.layer
  }

  onAddOrUpdateVersion($event: {
    reinsurerId?: string
    layer: Layer
    phase: ReinsurerPhase
    version: string
    label?: string
    reinsurerName: string
    sectionID: number
    subjectivity: ReinsurerSubjectivity[]
    assignedLines: AssignedLines[]
    programGroupID?: string
    sharedLimitID?: string
    previousSubjectPremium?: MonetaryUnit
    qqField?: string
    qqValue?: number
    qqLayerType?: string
    isPreferred?: boolean
    accountOpp: AccountOpportunity
    layerstate?: LayerState[]
    isSection?: boolean
    previousRe?: QuoteReinsurer
    section?: Section
    tpRef?: string
    reType: string
    sequenceNumber?: number
    isQQSave?: boolean,
    brokerageRIPCommission?: number
    brokerageCommission?: number
  }): void {
    const layerCurrency = $event.layer.physicalLayer.franchise.currency
    const defaultMonetary: MonetaryUnit = {
      value: 0,
      currency: layerCurrency || 'USD',
    }
    let quoteOccurrenceLimit = $event.layer.physicalLayer.limit
    const quotePremium: MonetaryUnit | undefined = { ...defaultMonetary }
    let quoteRolPercentage = $event.layer.physicalLayer.meta_data.rol ?? 0
    let topOccurrenceLimit: MonetaryUnit | undefined = { ...defaultMonetary }
    let topOccurrenceAttachment: MonetaryUnit | undefined = {
      ...defaultMonetary,
    }
    let topAggregateLimit: MonetaryUnit | undefined = { ...defaultMonetary }
    let dropOccurrenceLimit: MonetaryUnit | undefined = { ...defaultMonetary }
    let dropOccurrenceAttachment: MonetaryUnit | undefined = {
      ...defaultMonetary,
    }
    let dropAggregateLimit: MonetaryUnit | undefined = { ...defaultMonetary }
    let combinedSharedLimit: MonetaryUnit | undefined = { ...defaultMonetary }
    let quoteRateOnLineSubject =
      $event.layer.physicalLayer.meta_data.rate_on_subject || 0
    const {brokerageRIPCommission, brokerageCommission} = $event
    if (this.isCatTopDropOrHidden($event.layer) && $event.layerstate) {
      const sharedLayer = this.getCatTDHiddenLayer(
        $event.layer,
        $event.layerstate
      )
      if (!sharedLayer) {
        topOccurrenceLimit = $event.layer.physicalLayer.limit
        topOccurrenceAttachment = $event.layer.physicalLayer.attachment
        topAggregateLimit = $event.layer.physicalLayer.aggregateLimit
      } else {
        sharedLayer.layerRefs.forEach(ref => {
          if ($event.layerstate) {
            const layer = $event.layerstate.find(l => l.layer.id === ref)
            if (!layer) {
              return
            }
            if (isLayerTop(layer.layer)) {
              topOccurrenceLimit = layer.layer.physicalLayer.limit
              topOccurrenceAttachment = layer.layer.physicalLayer.attachment
              topAggregateLimit = layer.layer.physicalLayer.aggregateLimit
            }
            if (isLayerDrop(layer.layer)) {
              dropOccurrenceLimit = layer.layer.physicalLayer.limit
              dropOccurrenceAttachment = layer.layer.physicalLayer.attachment
              dropAggregateLimit = layer.layer.physicalLayer.aggregateLimit
            }
          }
        })
        combinedSharedLimit = this.asMonetaryUnit(
          sharedLayer.physicalLayer.limit.value,
          layerCurrency
        )
      }

      if ($event.phase !== ReinsurerPhases.Expiring) {
        const limit = sharedLayer
          ? combinedSharedLimit.value
          : $event.layer.physicalLayer.limit.value
        quotePremium.value = quoteRolPercentage * limit
      }
    }

    if (isMultiSectionLayer($event.layer)) {
      quotePremium.value = $event.layer.physicalLayer.premium.value
      quoteOccurrenceLimit = {
        ...quoteOccurrenceLimit,
        value:
          $event.layer.physicalLayer.meta_data.contractOccurrenceLimit ??
          quoteOccurrenceLimit.value,
      }
    }

    quotePremium.value = $event.layer.physicalLayer.premium.value

    if (isQSLayer($event.layer.meta_data.sage_layer_type || '')) {
      quoteRolPercentage = 0
      quoteRateOnLineSubject = 0
    }

    const defaultBrokerages = getDefaultBrokerage(
      this.isUsaBasedUser,
      $event.layer.meta_data.sage_layer_type
    )
    const data: QuoteReinsurer = {
      id: $event.reinsurerId ?? createQuoteID(),
      cededlayerID: $event.layer.id,
      reinsurerPhase: $event.phase,
      reinsurerPhaseVersion: $event.version,
      reinsurerPhaseLabel: $event.label,
      tpRef: $event.tpRef,
      quoteFields: {
        quoteOccurrenceLimit,
        quoteOccurrenceAttachment: $event.layer.physicalLayer.attachment,
        quoteRiskLimit: $event.layer.physicalLayer.riskLimit,
        quoteRiskAttachment: $event.layer.physicalLayer.riskAttachment,
        quoteFranchiseDeductible: $event.layer.physicalLayer.franchise,
        quoteReinstatements: $event.layer.physicalLayer.reinstatements,
        quoteAggregateLimit: $event.layer.physicalLayer.aggregateLimit,
        quoteAggregateAttachment:
          $event.layer.physicalLayer.aggregateAttachment,
        quoteCessionPercentage: $event.layer.meta_data.sage_layer_type === 'shared_limits' ? $event.layer.physicalLayer.participation :  $event.layer.physicalLayer.participation * -1,
        quoteCedingCommission: $event.layer.physicalLayer.fees[0]
          ? $event.layer.physicalLayer.fees[0].rate
          : 0,
        quoteReinsurerExpenseProvision: $event.layer.physicalLayer.fees[1]
          ? $event.layer.physicalLayer.fees[1].rate
          : 0,
        quoteProfitCommission: $event.layer.physicalLayer.fees[3]
          ? $event.layer.physicalLayer.fees[3].rate
          : 0,
        quoteRolPercentage,
        quotePremium,
        isLimitUnlimited: $event.layer.physicalLayer.meta_data.isLimitUnlimited,
        isAggregateUnlimited:
          $event.layer.physicalLayer.meta_data.isAggregateUnlimited,
        maolLimit: defaultMonetary,
        terrorismAggSubLimit: defaultMonetary,
        lossRatioCap: defaultMonetary,
        laeCap: defaultMonetary,
        minimumPremium: defaultMonetary,
        clashPremium: defaultMonetary,
        quoteOfferedLimit: { ...defaultMonetary },
        lossRatioCapPercentage: 0,
        quoteMinRateSubject:
          $event.layer.physicalLayer.meta_data.min_rate_subject || 0,
        quoteMaxRateSubject:
          $event.layer.physicalLayer.meta_data.max_rate_subject || 0,
        quoteMinRatePmpm:
          $event.layer.physicalLayer.meta_data.min_rate_pmpm || 0,
        quoteMaxRatePmpm:
          $event.layer.physicalLayer.meta_data.max_rate_pmpm || 0,
        quoteSwingRate: $event.layer.physicalLayer.meta_data.swing_rate || 0,
        quoteFixedIndexValue:
          $event.layer.physicalLayer.meta_data.fixedIndexValue || 0,
        quoteSicOrFranchise:
          $event.layer.physicalLayer.meta_data.sicOrFranchise || 0,
        quoteRateOnLineSubject,
        quotePmpm: $event.layer.physicalLayer.meta_data.pmpm || 0,
        ecoCoveragePct: 0,
        xplCoveragePct: 0,
        minimumPremiumPercentage: 0,
        depositPremiumPercentage: 0,
        profitShareCommission: 0,
        profitShareMinRate: 0,
        profitShareMaxRate: 0,
        brokerageCommission: brokerageCommission ?? defaultBrokerages.brokerageCommission,
        brokerageRIPCommission: brokerageRIPCommission ?? defaultBrokerages.reinstatementBrokerage,
        orderPercent: 0,
        // quoteSignedPercentage: 0,
        quoteMinPercentage: 0,
        quoteOfferedPercentage: 0,
        quoteDepositPremium: defaultMonetary,
        quoteExpectedCededPremium: defaultMonetary,
        quoteExpectedCededLoss: defaultMonetary,
        totalQuoteExpectedCededLoss: 0,
        totalQuoteExpectedCededPremium: 0,
        underwriter: '',
        laeTreatment: '',
        adjustmentBasis: 'Subject Premium Income',
        brokerageType: 'Gross Ceded Premium',
        otherFeatures: '',
        coverageBasis: '',
        indexationtext: '',
        xplEcoDropdown: '',
        xplEcoConditions: '',
        layerCurrency,
        territorialScope: [],
        vendor: '',
        feeOrBrokerage: '',
        structureFX: '',
        premiumFX: '',
        premiumFXToUSD: '',
        modelVersion: 0.0,
        perils: [],
        lossImpactedFromPreviousYear: '',
        cedingCommissionBasis: '',
        fee: 0,
        rebate: 0,
        layerClass: $event.accountOpp ? $event.accountOpp.opportunityClass : '',
        layerSubClass: $event.accountOpp
          ? $event.accountOpp.opportunitySubClass
          : '',
        quoteProbabilityOfAttach: 0,
        quoteProbabilityOfExhaust: 0,
        quoteLossOnLine: 0,
        quotePayout: $event.layer.physicalLayer.payout,
        quoteTrigger: $event.layer.physicalLayer.trigger,
        quoteNth: $event.layer.physicalLayer.nth,
        quoteEffectiveDate:
          $event.layer.physicalLayer.inception_date || undefined,
        quoteExpirationDate:
          $event.layer.physicalLayer.expiry_date || undefined,
        quoteIndex: '',
        quoteTopOccurrenceLimit: topOccurrenceLimit,
        quoteTopOccurrenceAttachment: topOccurrenceAttachment,
        quoteAggregateLimitTop: topAggregateLimit,
        quoteAggregateLimitDrop: dropAggregateLimit,
        quoteDropOccurrenceLimit: dropOccurrenceLimit,
        quoteDropOccurrenceAttachment: dropOccurrenceAttachment,
      },
      quoteReinsurerName: $event.reinsurerName,
      reinsurerSectionId: $event.sectionID,
      riskSubjectivityLink: [],
      riskAssignedLinesLink: [],
      exportToggle: true,
      programGroupID: Number($event.programGroupID),
      sharedLimitID: Number($event.sharedLimitID),
      isPreferred: $event.isPreferred,
      slidingScale: [
        {
          id: 1,
          type: 'Min',
          commission: 0,
          lossRatio: 0,
          slideRate: 0,
        },
        {
          id: 2,
          type: 'Provisional',
          commission: 0,
          lossRatio: 0,
        },
        {
          id: 3,
          type: 'Max',
          commission: 0,
          lossRatio: 0,
          slideRate: 0,
        },
      ],
      reType: $event.reType,
      sequenceNumber: $event.sequenceNumber,
    }

    if (
      data.quoteFields?.quoteCessionPercentage &&
      isMultiSectionLayer($event.layer)
    ) {
      data.quoteFields.quoteCessionPercentage =
        data.quoteFields.quoteCessionPercentage * -1
    }

    if (
      data.quoteFields &&
      this.selectedProgram && this.selectedProgram.libRE !== 'Y'
    ) {
      if (
        $event.layer.meta_data.sage_layer_type === 'cat_td' &&
        $event.layer.meta_data.sage_layer_subtype === 'actual'
      ) {
        const topLayer = $event.layerstate?.find(
          l =>
            l.layer.meta_data.sage_layer_type === 'cat_td' &&
            l.layer.meta_data.sage_layer_subtype === 'virtual'
        )?.layer!
        data.quoteFields.subjectPremium = asUSD(
          this.calculateLossSetsSum(topLayer)
        )
      } else {
        data.quoteFields.subjectPremium = asUSD(
          this.calculateLossSetsSum($event.layer)
        )
      }
    }

    if (data.quoteFields) {
      if (parseInt(data.reinsurerPhaseVersion, 10) > 1) {
        data.quoteFields.subjectPremium = $event.previousSubjectPremium
      }
    }

    if (
      data.quoteFields &&
      this.savedSubPremMap.get(this.getSelectedCededLayerId() as string)
    ) {
      data.quoteFields.subjectPremium = this.savedSubPremMap.get(
        this.getSelectedCededLayerId() as string
      )
    }
    if (
      $event.qqField &&
      $event.qqValue &&
      data.quoteFields &&
      $event.layer.meta_data.sage_layer_subtype !== 'section-layer'
    ) {
      if ($event.previousRe) {
        data.quoteFields = {
          ...$event.previousRe.quoteFields,
          quoteReinstatements:
            $event.previousRe.quoteFields?.quoteReinstatements?.map(
              ({ id, ...rest }) => rest
            ),
          quoteOfferedPercentage: 0,
        }
      }
      const limitValue =
        $event.qqLayerType === 'ag'
          ? data.quoteFields.quoteAggregateLimit
            ? data.quoteFields.quoteAggregateLimit.value
            : 0
          : data.quoteFields.quoteOccurrenceLimit
            ? data.quoteFields.quoteOccurrenceLimit.value
            : 0
      if ($event.qqField === 'quoteRolPercentage') {
        data.quoteFields.quoteRolPercentage = $event.qqValue
        data.quoteFields.quotePremium = {
          value: Math.round($event.qqValue * limitValue),
          currency: layerCurrency || 'USD',
        }
      } else if ($event.qqField === 'quoteRateOnLineSubject') {
        data.quoteFields.quoteRateOnLineSubject = $event.qqValue
        data.quoteFields.quotePremium = {
          value: Math.round(
            $event.qqValue *
              (data.quoteFields.subjectPremium
                ? data.quoteFields.subjectPremium.value
                : 0)
          ),
          currency: layerCurrency || 'USD',
        }
      } else if ($event.qqField === 'quotePmpm') {
        data.quoteFields.quotePmpm = $event.qqValue
        data.quoteFields.quotePremium = {
          value: Math.round(
            $event.qqValue * this.calculateLossSetsMembersSum($event.layer)
          ),
          currency: layerCurrency || 'USD',
        }
      } else if ($event.qqField === 'quoteCedingCommission') {
        data.quoteFields.quoteCedingCommission = $event.qqValue
      } else if ($event.qqField === 'quoteOfferedPercentage') {
        data.quoteFields.quoteOfferedPercentage = $event.qqValue
        data.quoteFields.quoteOfferedLimit = {
          value: Math.round($event.qqValue * limitValue),
          currency: layerCurrency || 'USD',
        }
      } else if ($event.qqField === 'quoteAggregateAttachment') {
        data.quoteFields.quoteAggregateAttachment = {
          value: $event.qqValue,
          currency: layerCurrency || 'USD',
        }
      } else if ($event.qqField === 'quoteAggregateLimit') {
        data.quoteFields.quoteAggregateLimit = {
          value: $event.qqValue,
          currency: layerCurrency || 'USD',
        }
      }
      data.quoteFields.minimumPremium = {
        value:
          (data.quoteFields.quotePremium?.value || 0) *
          (data.quoteFields.minimumPremiumPercentage || 0),
        currency: layerCurrency || 'USD',
      }
      data.quoteFields.quoteDepositPremium = {
        value:
          (data.quoteFields.quotePremium?.value || 0) *
          (data.quoteFields.depositPremiumPercentage || 0),
        currency: layerCurrency || 'USD',
      }
    }

    this.store.dispatch(saveLayerClassPerSection())
    let time = 10
    if ($event.section && !$event.section.layerClassToggle) {
      time = 400
    }
    setTimeout(() => {
      this.store.dispatch(
        addOrUpdateQuoteReinsurer({
          reinsurer: data,
          isSection:
            $event.layer.meta_data.sage_layer_subtype === 'section-layer' ||
            $event.isSection,
          isQQSave: $event.isQQSave,
        })
      )
    }, time)
  }

  onUpdateOffMarketLines($event: ReinsurerState[]): void {
    this.store.dispatch(updateAssignedLineReinsurer({ reinsurerState: $event }))
  }

  onUpdateLabel($event: { reinsurerId: string; label: string }): void {
    this.store.dispatch(
      updateLabel({ id: $event.reinsurerId, label: $event.label })
    )
  }

  onReinsurerResize($event: {
    field: Partial<QuoteFields>
    id: string
    isSection?: boolean
    isQQSave?: boolean
  }): void {
    this.store.dispatch(
      updateQuoteFields({
        id: $event.id,
        change: $event.field,
        isSection: $event?.isSection ?? false,
      })
    )
    if ($event.isQQSave) {
      this.store.dispatch(saveQuickQuote())
    }
  }

  onNameSelection($event: {
    reinsurerName: string
    id: string
    tpRef: string
    brokerageChanges?: Partial<QuoteFields>
  }): void {
    this.store.dispatch(
      updateReinsurerName({
        id: $event.id,
        name: $event.reinsurerName,
        tpRef: $event.tpRef,
      })
    )
    this.store.dispatch(
      updateQuoteFields({
        id: $event.id,
        change: $event.brokerageChanges as Partial<QuoteFields>,
        isSection: false,
      })
    )
  }

  onSaveSubjectivities($event: {
    reinsurer: QuoteReinsurer
    isGroupSelected?: boolean
    isSLSelected?: boolean
  }): void {
    const saveSuccessful = this.onSaveClick({
      reinsurer: $event.reinsurer,
      isGroupSelected: $event.isGroupSelected,
      isSLSelected: $event.isSLSelected,
    })
    if (saveSuccessful) {
      if (this.selectedClientID && this.studyID) {
        this.store.dispatch(
          fetchStudyReinsurer({
            carrierID: this.selectedClientID,
            studyID: this.studyID,
          })
        )
      }
      this.saveSubjectivityDone.emit()
    }
  }

  onSaveAssignedLines($event: {
    reinsurer: QuoteReinsurer
    isGroupSelected?: boolean
    isSLSelected?: boolean
  }): void {
    const saveSuccessful = this.onSaveClick({
      reinsurer: $event.reinsurer,
      isGroupSelected: $event.isGroupSelected,
      isSLSelected: $event.isSLSelected,
    })
    if (saveSuccessful) {
      this.saveAssignedLinesDone.emit()
    }
  }
  onSaveQuickQuote(): void {
    this.saveQuickQuoteDone.emit()
  }

  onPopulateSubjectivities($event: { rows: Subjectivity[]; id: string }): void {
    this.store.dispatch(
      populateSubjectivitiesArray({
        id: $event.id,
        subjectivity: $event.rows,
      })
    )
  }

  onAddNewSubjectivity($event: { sub: Subjectivity; id: string }): void {
    this.store.dispatch(
      addSubjectivity({
        id: $event.id,
        sub: $event.sub,
      })
    )
  }

  onUpdateSubjectivity($event: {
    reinsurerID: string
    subjectivityRows: Subjectivity[]
    save?: boolean
    delete?: boolean
  }): void {
    const { reinsurerID, subjectivityRows, save } = $event
    const arr: ReinsurerSubjectivity[] = []
    subjectivityRows.forEach(s => {
      if (!s.id) {
        return
      }
      arr.push({
        riskReinsurerId: reinsurerID,
        riskSubjectivityId: s.id,
        riskSubjectivity: s,
      })
    })
    this.store.dispatch(
      updateSubjectivitiesArray({
        id: reinsurerID,
        subjectivity: arr,
        bypassFOT: !!$event.delete,
      })
    )
    const reinsurer = this.reinsurerList?.find(
      r => r.reinsurer.id === reinsurerID
    )?.reinsurer
    if (save && reinsurer) {
      setTimeout(() => {
        this.onSaveClick({
          reinsurer,
        })
      }, 500)
    }
  }

  onDeleteSubjectivity($event: { reinsurerID: string; id: string }): void {
    const { reinsurerID, id } = $event
    if (
      id &&
      typeof id === 'string' &&
      id.startsWith(SUBJECTIVITY_TEMP_PREFIX)
    ) {
      this.store.dispatch(deleteUnsavedSubjectivity({ id }))
    } else {
      this.store.dispatch(
        deleteSubjectivity({
          id,
          reinsurerID,
        })
      )
    }
  }

  onAddNewAssignedLines($event: { al: AssignedLines; id: string }): void {
    this.store.dispatch(
      addAssignedLines({
        id: $event.id,
        al: $event.al,
      })
    )
  }

  onUpdateAssignedLines($event: {
    reinsurerID: string
    assignedLinesRows: AssignedLines[]
  }): void {
    this.store.dispatch(
      updateAssignedLinesArray({
        id: $event.reinsurerID,
        assignedLines: $event.assignedLinesRows,
      })
    )
  }

  onDeleteAssignedLines($event: { id: string | number }): void {
    /* only delete assigned lines that have an id from database (not a temp id) */
    const alId = $event.id.toString()
    if (alId && !alId.startsWith(ASSIGNED_LINES_TEMP_PREFIX)) {
      this.store.dispatch(
        deleteAssignedLines({
          id: alId,
        })
      )
    }
  }

  onSaveClick($event: {
    reinsurer: QuoteReinsurer
    isGroupSelected?: boolean
    isSLSelected?: boolean
  }): boolean {
    let reinsurerStateList = this.reinsurerList
    if ($event && ($event.isGroupSelected || $event.isSLSelected)) {
      reinsurerStateList = this.reinsurerList?.filter(
        x => x.reinsurer.id === $event.reinsurer.id
      )!
    }
    if (!reinsurerStateList) {
      return false
    }
    for (let reinsurerState of reinsurerStateList) {
      if (!reinsurerState.dirty) {
        continue
      }
      const assignedLinesData =
        reinsurerState.reinsurer.riskAssignedLinesLink?.map(
          ({ id, ...rest }) => {
            return (
              id &&
              typeof id === 'string' &&
              id.startsWith(ASSIGNED_LINES_TEMP_PREFIX)
                ? rest
                : { id, ...rest }
            ) as AssignedLines
          }
        )

      const dups = this.getDupAssignedLines(assignedLinesData ?? [])
      if (dups && dups.length > 0) {
        const dupsList = dups.map(x => `- ${x.reinsurer}`).join('\n')

        this._snackBar.open(
          `Duplicate assigned lines found:\n${dupsList}`,
          'X',
          {
            duration: undefined,
            panelClass: ['success-snackbar'],
          }
        )
        return false
      }

      const invalidLines = this.getInvalidCoBrokerShare(assignedLinesData ?? [])
      if (invalidLines && invalidLines.length > 0) {
        const invalidList = invalidLines.map(x => `- ${x.reinsurer}`).join('\n')

        this._snackBar.open(
          `Co-broker share cannot exceed associated Brokerage/Brokerage RIP %:\n${invalidList}`,
          'X',
          {
            duration: undefined,
            panelClass: ['success-snackbar'],
          }
        )
        return false
      }

      const re: QuoteReinsurer = {
        ...reinsurerState.reinsurer,
        riskAssignedLinesLink: assignedLinesData,
      }
      re.reType = $event.reinsurer.reType
      re.sequenceNumber = $event.reinsurer.sequenceNumber
      this.store.dispatch(saveQuoteReinsurer({ reinsurer: re }))

      this.prevSavedSubjectPremium =
        reinsurerState.reinsurer.quoteFields?.subjectPremium
      this.prevSavedSubjectPremiumLayerId = this.getSelectedCededLayerId()
      if (this.prevSavedSubjectPremium && this.prevSavedSubjectPremiumLayerId) {
        this.savedSubPremMap.set(
          this.prevSavedSubjectPremiumLayerId,
          this.prevSavedSubjectPremium
        )
      }
      if (!this.showAssignedLines) {
        this.store.dispatch(setSubAlReNameAndId({ reName: '', ralId: 0 }))
      }
    }
    return true
  }

  getDupAssignedLines(lines: AssignedLines[]): AssignedLines[] {
    const lineList: AssignedLines[] = []
    const dups: AssignedLines[] = []

    lines.forEach(line => {
      if (this.matchFound(line, lineList)) {
        dups.push(line)
      } else {
        lineList.push(line)
      }
    })

    return dups
  }

  getInvalidCoBrokerShare(lines: AssignedLines[]): AssignedLines[] {
    const invalidLines: AssignedLines[] = []

    lines.forEach(line => {
      if (
        (line.brokerageCoBrokerShare &&
          line.brokerage &&
          line.brokerageCoBrokerShare > line.brokerage) ||
        (line.brokerageReCoBrokerShare &&
          line.brokerageRe &&
          line.brokerageReCoBrokerShare > line.brokerageRe)
      ) {
        invalidLines.push(line)
      }
    })

    return invalidLines
  }

  matchFound(line: AssignedLines, linesList: AssignedLines[]): boolean {
    if (line.relationSeqNumber == null) {
      return linesList.some(
        x =>
          (x.marketTpRef === line.marketTpRef &&
            x.underwriterRef === line.underwriterRef &&
            !x.relationSeqNumber) ||
          (x.reinsurer === line.reinsurer &&
            x.underwriterRef === line.underwriterRef &&
            !x.relationSeqNumber)
      ) // there seem to be instances where markettpref isn't provided so check for name
    }

    // relation seq number is something other than null/undefined
    return linesList.some(
      x =>
        (x.marketTpRef === line.marketTpRef &&
          x.relationSeqNumber === line.relationSeqNumber &&
          x.underwriterRef === line.underwriterRef) ||
        (x.reinsurer === line.reinsurer &&
          x.relationSeqNumber === line.relationSeqNumber &&
          x.underwriterRef === line.underwriterRef)
    ) // there seem to be instances where markettpref isn't provided so check for name
  }

  onDeleteClick($event: { reinsurer: QuoteReinsurer }): void {
    if ($event.reinsurer.id?.startsWith(QUOTE_TEMP_PREFIX)) {
      this.store.dispatch(
        deleteUnsavedQuoteReinsurer({ reinsurer: $event.reinsurer })
      )
      return
    }
    if ($event.reinsurer.id) {
      this.store.dispatch(deleteQuoteReinsurer({ id: $event.reinsurer.id }))
    }
  }

  onDeclineClick($event: { reinsurer: QuoteReinsurer }): void {
    this.store.dispatch(declineQuoteReinsurer({ reinsurer: $event.reinsurer }))
    this.store.dispatch(saveQuoteReinsurer({ reinsurer: $event.reinsurer }))
  }

  onPreferredClick($event: { reinsurer: QuoteReinsurer }): void {
    this.store.dispatch(
      preferredQuoteReinsurer({ reinsurer: $event.reinsurer })
    )
    this.store.dispatch(saveQuoteReinsurer({ reinsurer: $event.reinsurer }))
  }

  onExportToggleClick($event: { reinsurer: QuoteReinsurer }): void {
    this.store.dispatch(exportToggleReinsurer({ reinsurer: $event.reinsurer }))
    this.store.dispatch(saveQuoteReinsurer({ reinsurer: $event.reinsurer }))
  }

  onExportToggleSectionClick($event: { sections: Section[] }): void {
    this.store.dispatch(
      exportToggleSection({
        sections: $event.sections,
      })
    )
    $event.sections.forEach(section => {
      this.store.dispatch(saveQuoteSection({ section }))
    })
  }

  onDefaultToggleSectionClick($event: { sections: Section[] }): void {
    this.store.dispatch(
      exportToggleSection({
        sections: $event.sections,
      })
    )
    $event.sections.forEach(section => {
      this.store.dispatch(saveQuoteSection({ section }))
    })
  }

  selectedIDChange(id: string): void {
    this.store.dispatch(updateSelectedID({ id }))
  }

  onExpandClick($event: { isOpen: boolean; name: string }): void {
    this.store.dispatch(
      expandClick({ isOpen: $event.isOpen, name: $event.name })
    )
  }

  onPopulateClick($event: {
    from: string
    to: string
    isSectionLayer: boolean
  }): void {
    this.store.dispatch(
      populateFromTo({
        fromID: $event.from,
        toID: $event.to,
        isSection: $event.isSectionLayer,
      })
    )
  }

  onSlidingValueChange($event: { id: string; slidingTable: SlidingScale[] }) {
    this.store.dispatch(
      slidingValueUpdate({ id: $event.id, slidingTable: $event.slidingTable })
    )
  }

  onDeleteReinstatement($event: { id: number }): void {
    this.store.dispatch(deleteReinstatement({ id: $event.id }))
  }

  onExportAsExcel(quoteExport: QuoteExport): void {
    this.store.dispatch(exportAsExcel(quoteExport))
    this.exportDone.emit()
  }

  onTierChange(tierPath: TierPath): void {
    /* check if program is being renewed from opportunity of previous year */
    const selectedProgramId = tierPath.program
    if (selectedProgramId !== null) {
      this.store.dispatch(
        checkIfProgramHasRenewedFrom({
          programId: selectedProgramId,
        })
      )
    }
  }

  onGroupOrStructureSelectionChange(
    groupOrStructureFilterSelection: 'Group' | 'Structure' | 'SL' | null
  ): void {
    this.groupOrStructureFilterSelected = groupOrStructureFilterSelection
    this.showFormComponent = false
  }

  onStructureSelectionChange($event: Program) {
    this.routeToStructure($event)
  }

  onScenarioOrOptimizationSelectChange(
    $event: CheckboxSelectChangeEvent<Program>
  ): void {
    const scenarioOrOptimization = head([
      ...($event.add ?? []),
      ...($event.remove ?? []),
    ])
    if (scenarioOrOptimization) {
      this.routeToStructure(scenarioOrOptimization)
    }
  }

  onSetRoute(event: { page: string; program: Program }): void {
    this.router.navigate([this.router.url + '/analysis/' + event.page])
    switch (event.page) {
      case 'group':
        this.onOpenInGroups(event.program)
        break
      case 'compare':
        this.onOpenInCompare(event.program)
        break
      case 'explore':
        this.onOpenInExplore(event.program)
        break
    }
  }

  onOpenInGroups(program: Program): void {
    this.router
      .navigate(['analysis', 'group'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(
            ProgramActions.addProgramToGroup({
              program: { ...program, checkGroup: false, checkGroupOcc: true },
            })
          )
        }, 500)
      })
  }

  onOpenInCompare(program: Program): void {
    this.router
      .navigate(['analysis', 'compare'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(
            CompareActions.addProgramToCompare({
              program: { ...program, checkCompare: false },
            })
          )
        }, 500)
      })
  }

  onOpenInExplore(program: Program): void {
    this.router
      .navigate(['analysis', 'explore'], { relativeTo: this.route })
      .then(() => {
        // waiting to analysis object in state to be created
        setTimeout(() => {
          this.store.dispatch(setExploreProgram({ program }))
          this.store.dispatch(setCurrentStructure({ id: program.id }))
        }, 500)
      })
  }

  onStructureNameDescriptionEdit($event: StructureNameEditEvent): void {
    this.store.dispatch(setProgramNameAndDescription($event))
  }

  onStructureIndexEdit($event: StructureIndexEditEvent): void {
    this.store.dispatch(updateProgramIndex($event))
  }

  onStructureFilterChange(filter: string): void {
    this.store.dispatch(setStructureFilter({ filter }))
  }

  private routeToStructure(structure: Program): void {
    const portfolioSetID = extractPortfolioSetID(structure)
    if (!portfolioSetID) {
      throw new Error(
        'Unexpected Error: Selected Structure has no Analysis Profile ID or Portfolio IDs.'
      )
    }
    this.store.dispatch(setCurrentStructure({ id: structure.id }))
    this.store.dispatch(
      setAnalysisOrReinitialize({ portfolioSetID, structureID: structure.id })
    )
  }

  onLayerSelect(startProps: QuoteStartProps): void {
    this.store.dispatch(GrouperActions.resetGrouper())
    if (!startProps.cededLayerID) {
      return
    }
    this.store.dispatch(setSelectedLayer({ id: startProps.cededLayerID }))
    if (startProps.sectionID) {
      this.store.dispatch(setSectionID({ sectionID: startProps.sectionID }))
    }
    this.store.dispatch(startQuote(startProps))
    this.store.dispatch(
      fetchAutoBuildExpiring({ layerRef: startProps.cededLayerID })
    )
    this.store.dispatch(
      fetchExternalVendorByStructureID({
        structureID: startProps.structureID ?? '',
      })
    )
    this.currentReinsurerMap = {}
    this.showFormComponent = true
    this.resetDisplayOnLayerGroupSLChange.emit()
  }

  onGroupSelect(startProps: QuoteStartProps): void {
    if (startProps && startProps.structureGroupID) {
      this.store.dispatch(GrouperActions.resetGrouper())
      this.store.dispatch(
        ProgramGroupActions.addProgramGroup({ id: startProps.structureGroupID })
      )
      setTimeout(() => {
        this.store.dispatch(startGroupQuote(startProps))
        this.showFormComponent = true
        this.resetDisplayOnLayerGroupSLChange.emit()
      }, 8000) // Increasing the timeout as group quote sage page is not loading (Temporary fix)
      this.currentReinsurerMap = {}
    }
  }

  onSlSelect(startProps: QuoteStartProps): void {
    if (startProps && startProps.sharedLimitID) {
      this.store.dispatch(startSLQuote(startProps))
      this.currentReinsurerMap = {}
      this.showFormComponent = true
      this.resetDisplayOnLayerGroupSLChange.emit()
    }
  }

  onSubPremCheckedFromDesign($event: LayerView): void {
    if ($event.subjectPremiumChecked) {
      this.subPremChecked = true
    }
  }

  onSegregatedAccountClick($event: {
    programID: string
    fundManager: Reinsurer
    placeholderRows: AssignedLines[]
  }): void {
    this.store.dispatch(
      openSegregatedAccountDetailsDialog({
        programID: $event.programID,
        fundManager: $event.fundManager,
      })
    )
  }

  calculateLossSetsSum(layer: Layer): number {
    return this.lossSetLayers
      .filter(ls => layer.lossSetLayers.some(_ls => _ls.id === ls.id))
      .reduce((acc, ls) => {
        return ls.premium ? acc + ls.premium.value : acc
      }, 0)
  }

  calculateLossSetsMembersSum(layer: Layer): number {
    return this.lossSetLayers
      .filter(ls => layer.lossSetLayers.some(_ls => _ls.id === ls.id))
      .reduce((acc, ls) => {
        return ls.meta_data && ls.meta_data.members
          ? acc + ls.meta_data.members
          : acc
      }, 0)
  }

  onCurrentReinsurerChange($event: {
    index: number
    currentReinsurer: QuoteReinsurer
  }): void {
    const { index, currentReinsurer } = $event
    this.currentReinsurerMap = {
      ...this.currentReinsurerMap,
      [index]: currentReinsurer,
    }
  }

  onSetSubAlReNameAndId($event: { reName: string; ralId: number }): void {
    this.store.dispatch(
      setSubAlReNameAndId({ reName: $event.reName, ralId: $event.ralId })
    )
  }

  asMonetaryUnit = (value: number, currency: string) => {
    return { value, currency } as MonetaryUnit
  }

  accessEditorState() {
    return this.store.pipe(select(selectEditorState))
  }

  getSelectedCededLayerId(): string | null | undefined {
    let selCedLayerIdStr: string | null | undefined
    const edState = this.accessEditorState()

    edState.subscribe(e => {
      selCedLayerIdStr = e.cededLayers.selected
    })

    return selCedLayerIdStr
  }

  onUpdateQuickQuoteField($event: { field: string }): void {
    this.store.dispatch(updateQuickQuoteField({ field: $event.field }))
  }

  onAddOrUpdateQuickQuote($event: { data: QuickQuoteUpdates }): void {
    this.store.dispatch(addOrUpdateQuickQuote({ data: $event.data }))
  }

  showSubjectivitiesEvent($event: { id: string }): void {
    if ($event && $event.id) {
      this.store.dispatch(updateSelectedID($event))
      this.store.dispatch(setSubAlReNameAndId({ reName: '', ralId: 0 }))
    }
    this.showSubjectivityClick.emit($event)
  }

  onUpdateAutoFillSelection(autoFill: boolean): void {
    this.store.dispatch(updateQuickQuoteAutoFill({ autoFill }))
  }

  deleteCompareView(id: number): void {
    this.store.dispatch(deleteQuoteComparison({ id }))
  }

  setCompareView(event: { view: QuoteCustomCompareView; save: boolean }): void {
    this.store.dispatch(updateSelectedCompareView({ view: event.view }))
    if (event.save) {
      this.store.dispatch(saveQuoteComparison())
    }
  }

  updateCompareViews(updates: QuoteCompareViewUpdatePayload): void {
    this.store.dispatch(updateQuoteComparisonsFromRemoveDialog({ updates }))
  }
}
