import { Action, createReducer, on } from '@ngrx/store'
import { OptimizationPortfolioTailMetrics } from '../optimization.model'
import * as fromOptimizationTailMetricsActions from './optimization-tail-metrics.actions'

export interface State {
  portfolio: OptimizationPortfolioTailMetrics
}

export const initialState: State = {
  portfolio: {
    perspective: 'Loss',
    portfolioType: 'Net',
    returnPeriod1: 100,
    aggregationMethod: 'AEP',
    lossFilter: 'all',
  },
}

const tailMetricsReducer = createReducer(
  initialState,
  on(
    fromOptimizationTailMetricsActions.updateAndFetchPortfolioTailMetrics,
    (state, changes) => ({
      ...state,
      portfolio: {
        perspective: changes.perspective,
        portfolioType: changes.portfolioType,
        returnPeriod1: changes.returnPeriod1,
        aggregationMethod: changes.aggregationMethod,
        lossFilter: changes.lossFilter,
      },
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return tailMetricsReducer(state, action)
}
