import { combineReducers, createReducer, on } from '@ngrx/store'
import { QuoteChartGridOption } from '../../model/quote-charts.model'
import {
  DEFAULT_QUOTE_TRENDS,
  QUOTE_TREND_METRICS_INFO,
  QUOTE_TREND_GROUP_BYS_INFO,
  QUOTE_TRENDS_INFO,
} from '../../model/quote-trends.model'
import { QuoteManagementChartsTrendsBase } from '../quote-management-information.reducer'
import * as fromQuoteMIActions from '../quote-management-information.actions'
import * as fromQuoteMITrendActions from './quote-management-information-trends.actions'
import { getChartSplitAndSize } from '../../utils/quote-charts.util'
import { QuoteTabType } from '../../model/quote-management-information.model'

export interface State extends QuoteManagementChartsTrendsBase {
  trends: QuoteChartGridOption[]
}

export const initialTrends: State = {
  trends: DEFAULT_QUOTE_TRENDS,
  metrics: Object.values(QUOTE_TREND_METRICS_INFO),
  selectedMetric: Object.values(QUOTE_TREND_METRICS_INFO)[0],
  groupBys: Object.values(QUOTE_TREND_GROUP_BYS_INFO),
  selectableChartsForTab: Object.values(QUOTE_TRENDS_INFO),
  selectableChartsForMetric: Object.values(QUOTE_TREND_METRICS_INFO)[0]
    .applicableCharts,
  maximizedIndex: null,
  chartEntityLimit: null,
  hideMetrics: false,
  lightChartMode: false
}

const trendsReducer = createReducer(
  initialTrends.trends,
  on(
    fromQuoteMITrendActions.fetchAllQuoteTrendsDataSuccess,
    (state, { charts }) => {
      const newState: QuoteChartGridOption[] = []
      for (const chart of charts) {
        const chartInState = state.find(s => s.index === chart.index)
        if (chartInState) {
          newState.push({
            ...chartInState,
            loading: false,
            data: chart.data,
          })
        }
      }
      return newState
    }
  ),
  on(
    fromQuoteMITrendActions.fetchSingleQuoteTrendDataSuccess,
    (state, { chart }) => {
      const updatedCharts = [...state]
      updatedCharts.splice(chart.index, 1, {
        ...updatedCharts[chart.index],
        data: chart.data,
        loading: false,
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) => {
      if (tab !== QuoteTabType.TRENDS) {
        return state
      }
      const updatedCharts = [...state]
      const newSelectableCharts = selectedChartsMetric.applicableCharts
      return updatedCharts.map(chart => {
        const updateChartInfo = !newSelectableCharts
          .map(c => c.type)
          .includes(chart.chart.type)
        return {
          ...chart,
          metric: selectedChartsMetric,
          chart: updateChartInfo ? newSelectableCharts[0] : chart.chart,
          ...getChartSplitAndSize(
            updateChartInfo ? newSelectableCharts[0] : chart.chart,
            chart.groupBy
          ),
        }
      })
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGridGroupBy,
    (state, { selectedGroupBy, chartIndex, tab }) => {
      if (tab !== QuoteTabType.TRENDS) {
        return state
      }
      const updatedCharts = [...state]
      updatedCharts.splice(chartIndex, 1, {
        ...updatedCharts[chartIndex],
        groupBy: selectedGroupBy,
        loading: true, // switch to chart loading template on UI
        data: undefined, // reset data on group by switch
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGrid,
    (state, { chartIndex, tab }) => {
      if (tab !== QuoteTabType.TRENDS) {
        return state
      }
      const updatedCharts = [...state]
      const previousSelectedIndex = updatedCharts.findIndex(c => c.highlight)
      if (previousSelectedIndex >= 0) {
        updatedCharts.splice(previousSelectedIndex, 1, {
          ...updatedCharts[previousSelectedIndex],
          highlight: false,
        })
      }
      updatedCharts.splice(chartIndex, 1, {
        ...updatedCharts[chartIndex],
        highlight: true,
      })
      return updatedCharts
    }
  ),
  on(
    fromQuoteMIActions.updateSelectedChartGridOption,
    (state, { chartIndex, chartOption, tab }) => {
      if (tab !== QuoteTabType.TRENDS) {
        return state
      }
      const updatedCharts = [...state]
      if (chartOption.chart.type === updatedCharts[chartIndex].chart.type) {
        updatedCharts.splice(chartIndex, 1, chartOption)
      } else {
        updatedCharts.splice(chartIndex, 1, {
          ...updatedCharts[chartIndex],
          chart: chartOption.chart,
          ...getChartSplitAndSize(chartOption.chart, chartOption.groupBy),
        })
      }
      return updatedCharts
    }
  )
)

const trendsMetricsReducer = createReducer(initialTrends.metrics)

const selectedTrendsMetricReducer = createReducer(
  initialTrends.selectedMetric,
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) =>
      tab === QuoteTabType.TRENDS ? selectedChartsMetric : state
  )
)

const trendsGroupBysReducer = createReducer(initialTrends.groupBys)

const selectableChartsForTabReducer = createReducer(
  initialTrends.selectableChartsForTab
)

const selectableChartsForMetricReducer = createReducer(
  initialTrends.selectableChartsForMetric,
  on(
    fromQuoteMIActions.updateSelectedChartsMetric,
    (state, { selectedChartsMetric, tab }) =>
      tab === QuoteTabType.TRENDS
        ? selectedChartsMetric.applicableCharts
        : state
  )
)

const maximizedIndexReducer = createReducer(
  initialTrends.maximizedIndex,
  on(fromQuoteMIActions.maximizeMIChart, (state, { chartIndex, tab }) => {
    if (tab !== QuoteTabType.TRENDS) {
      return state
    }
    return state !== chartIndex ? chartIndex : null
  })
)

const chartEntityLimitReducer = createReducer(
  initialTrends.chartEntityLimit,
  on(fromQuoteMIActions.setChartEntityLimit, (state, { limit, tab }) =>
    tab === QuoteTabType.TRENDS ? limit : state
  )
)

const hideMetricsReducer = createReducer(
  initialTrends.hideMetrics,
  on(fromQuoteMIActions.updateHideMetrics, (_, { hideMetrics }) => hideMetrics)
)

const lightChartModeReducer = createReducer(
  initialTrends.lightChartMode,
  on(fromQuoteMIActions.updateLightChartMode, (_, { lightChartMode }) => lightChartMode)
)

export const quoteManagementTrendsReducer = combineReducers<State>({
  trends: trendsReducer,
  metrics: trendsMetricsReducer,
  selectedMetric: selectedTrendsMetricReducer,
  groupBys: trendsGroupBysReducer,
  selectableChartsForTab: selectableChartsForTabReducer,
  selectableChartsForMetric: selectableChartsForMetricReducer,
  maximizedIndex: maximizedIndexReducer,
  chartEntityLimit: chartEntityLimitReducer,
  hideMetrics: hideMetricsReducer,
  lightChartMode: lightChartModeReducer
})
