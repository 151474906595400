import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { SelectedUserPreferences } from '../../core/model/reinsurer.model'
import * as fromUserPreferencesActions from './user-preferences.actions'

export const FEATURE_KEY = 'userPreferences'

export interface UserPreferencesState {
  userPreferences: SelectedUserPreferences
  defaultUSAUserPreferences: SelectedUserPreferences
  userPreferencesColumnsList: string[]
}

export type State = UserPreferencesState

export const initialState: State = {
  userPreferences: null,
  defaultUSAUserPreferences: {
    preferenceType: 'Reinsurer Selection',
    programId: '',
    preferenceValue: JSON.stringify({
      selectedColumns: [
        'NAIC / AIIN',
        'FEIN',
        'Domicile',
        'A.M. Best Rating',
        'A.M. Best Outlook',
        'S&P Rating',
        'S&P Outlook',
        'Policyholders Surplus/ Total Equity (000s)',
        'Policyholders Surplus/ Total Equity Type',
        'Policyholders Surplus/ Total Equity As of Date',
        'Client Approval',
        'Lockton Approval Status',
        'Lockton Market Security Grade',
        'Lockton Market Security Restrictions',
      ],
      isWritten: true,
      isCustomBreakoutList: false,
      customriskDetailsColumns: ['includePct'],
    }),
  },
  userPreferencesColumnsList: [
    'NAIC / AIIN',
    'FEIN',
    'LORS',
    'Domicile',
    'A.M. Best Rating',
    'A.M. Best Outlook',
    'S&P Rating',
    'S&P Outlook',
    'Last Review Date',
    'Policyholders Surplus/ Total Equity (000s)',
    'Policyholders Surplus/ Total Equity Type',
    'Policyholders Surplus/ Total Equity As of Date',
    'Placed Through',
    'Correspondent Broker',
    'Client Approval',
    'Lockton Approval Status',
    'Lockton Market Security Grade',
    'Lockton Market Security Restrictions',
  ],
}

const userPreferencesReducer = createReducer(
  initialState,
  on(
    fromUserPreferencesActions.fetchUserPreferencessSuccess,
    (state: UserPreferencesState, { selectedUserPreferences }) => {
      return { ...state, userPreferences: selectedUserPreferences }
    }
  ),

  on(
    fromUserPreferencesActions.saveOrUpdateUserPreferencesSuccess,
    (state: UserPreferencesState, { selectedUserPreferences }) => {
      return { ...state, userPreferences: selectedUserPreferences }
    }
  )
)

export function reducer(
  state: UserPreferencesState | undefined,
  action: Action
) {
  return userPreferencesReducer(state, action)
}
