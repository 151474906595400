import { createSelector } from '@ngrx/store'
import { selectQuoteMIState } from './../quote-management-information.selectors'

export const selectQuoteMITrendsState = createSelector(
  selectQuoteMIState,
  state => state.trends
)

export const selectQuoteMITrends = createSelector(
  selectQuoteMITrendsState,
  state => state.trends
)

export const selectTrendsMaximizedIndex = createSelector(
  selectQuoteMITrendsState,
  state => state.maximizedIndex
)

export const selectQuoteMITrendsMetrics = createSelector(
  selectQuoteMITrendsState,
  state => state.metrics
)

export const selectQuoteMISelectedTrendsMetric = createSelector(
  selectQuoteMITrendsState,
  state => state.selectedMetric
)

export const selectAvailableTrendsForTab = createSelector(
  selectQuoteMITrendsState,
  state => state.selectableChartsForTab
)

export const selectAvailableTrendsForMetric = createSelector(
  selectQuoteMITrendsState,
  state => state.selectableChartsForMetric
)

export const selectQuoteMITrendsGroupBys = createSelector(
  selectQuoteMITrendsState,
  state => state.groupBys
)

const selectTrendsEntityLimit = createSelector(
  selectQuoteMITrendsState,
  state => state.chartEntityLimit
)

export const selectTrendsEntityLimitWithDefault = createSelector(
  selectTrendsEntityLimit,
  chartEntityLimit => chartEntityLimit ?? 16
)
