import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CarrierData } from 'src/app/api/model/backend.model'
import { AccountMappingData } from '../../model/program-initiation.model'
import { MatSelectChange } from '@angular/material/select'

@Component({
  selector: 'app-program-initiation-mapping-action-dialog',
  templateUrl: './program-initiation-mapping-action-dialog.component.html',
  styleUrls: ['./program-initiation-mapping-action-dialog.component.scss'],
})
export class ProgramInitiationMappingActionDialogComponent {
  private selectedCarrier: CarrierData
  constructor(
    public dialogRef: MatDialogRef<ProgramInitiationMappingActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      carriers: CarrierData[]
      accountMappingData: AccountMappingData
    }
  ) {
    this.data.carriers.sort((a, b) => a.name.localeCompare(b.name))
  }

  updateCarrier() {
    const updatedMapping: AccountMappingData = {
      sfAccountId: this.data.accountMappingData.sfAccountId,
      sfAccountName: this.data.accountMappingData.sfAccountName,
      carrierId: this.selectedCarrier.id,
      carrierName: this.selectedCarrier.name,
    }
    this.dialogRef.close(updatedMapping)
  }

  onCarrierSelectionChange(change: MatSelectChange): void {
    this.selectedCarrier = change.value
  }
}
