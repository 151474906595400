import { Dictionary } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { createInuranceTagsAndSymbols } from '../../../model/inurance-tags-creator'
import {
  DeleteInuranceFromDesign,
  InuranceTagsByLevel,
  InuranceView,
  Terminal,
} from '../../../model/inurance.model'
import {
  initializeTagsByLevel,
  inuranceCardsEquals,
} from '../../../model/inurance.util'
import { Layer } from '../../../model/layers.model'
import {
  areLayerIDsInSameTopAndDrop,
  findLayer,
  isLayerAggFeeder,
  isLayerFHCF,
} from '../../../model/layers.util'
import { ProgramGroupEntity } from '../program-group/program-group.reducer'
import { ProgramEntity } from '../program/program.reducer'
import * as fromActions from './grouper-inurance.actions'
import * as fromAnalysisActions from '../../analysis.actions'

export type GrouperInuranceMode = 'none' | 'new' | 'delete'

export interface GrouperInuranceState {
  mode: GrouperInuranceMode
  tagsByLevel: InuranceTagsByLevel
  symbolMap: Dictionary<string>
  source: InuranceView | null
  target: InuranceView | null
  cursor: Terminal | null
  saving: boolean
  loadingInDesign: boolean
  loadingInMultiSection: boolean
  fromDesignSaved: InuranceView[]
  error: string | null
  deleteError: string | null
  deleting: boolean
  toDeleteFromDesign: DeleteInuranceFromDesign | null
}

export const initialState: GrouperInuranceState = {
  mode: 'none',
  tagsByLevel: initializeTagsByLevel(),
  symbolMap: {},
  source: null,
  target: null,
  cursor: 'source',
  saving: false,
  fromDesignSaved: [],
  loadingInDesign: false,
  loadingInMultiSection: false,
  error: null,
  deleteError: null,
  deleting: false,
  toDeleteFromDesign: null,
}

export function resetTagsAndSymbolsReducer(
  state: GrouperInuranceState
): GrouperInuranceState {
  return { ...state, symbolMap: {}, tagsByLevel: initializeTagsByLevel() }
}

export function createTagsAndSymbolsReducer(
  state: GrouperInuranceState,
  programEntities: Map<ProgramEntity, ProgramGroupEntity[]>
): GrouperInuranceState {
  const [tagsByLevel, symbolMap] = createInuranceTagsAndSymbols(
    programEntities,
    state.symbolMap
  )
  return { ...state, symbolMap, tagsByLevel }
}

export function resetEditReducer(
  state: GrouperInuranceState
): GrouperInuranceState {
  const { tagsByLevel, symbolMap, fromDesignSaved } = state
  return { ...initialState, tagsByLevel, symbolMap, fromDesignSaved }
}

function validateSourceAndTarget(
  state: GrouperInuranceState,
  layers?: Layer[]
): string | undefined {
  if (inuranceCardsEquals(state.source, state.target)) {
    return 'Source & Target cannot be the same.'
  }
  if (layers) {
    if (
      state.source &&
      state.target &&
      areLayerIDsInSameTopAndDrop(
        layers,
        state.source.layerID,
        state.target.layerID
      )
    ) {
      return 'Source & Target cannot be in the same Top & Drop.'
    }

    if (state.source && state.source.layerID) {
      const source = findLayer(layers, state.source.layerID)
      if (source && isLayerAggFeeder(source)) {
        return 'Source cannot be an Aggregate Feeder.'
      }
    }

    if (state.target && state.target.layerID) {
      const target = findLayer(layers, state.target.layerID)
      if (target && isLayerAggFeeder(target)) {
        return 'Target cannot be an Aggregate Feeder.'
      }
      if (target && isLayerFHCF(target)) {
        return 'Target cannot be an FHCF Layer.'
      }
    }
  }
}

const grouperInuranceReducer = createReducer(
  initialState,
  on(
    fromActions.deleteInuranceFromDesign,
    fromAnalysisActions.openAddInuranceDialogForEdit,
    fromActions.swapInuranceFromDesign,
    state => {
      return { ...state, loadingInDesign: true }
    }
  ),
  on(
    fromAnalysisActions.openAddInuranceDialog,
    (state, { fromMultiSection }) => {
      if (!fromMultiSection) {
        return state
      }
      return {
        ...state,
        loadingInMultiSection: fromMultiSection,
      }
    }
  ),
  on(fromActions.swapInuranceFromDesign, (state, { toDelete }) => {
    return { ...state, toDeleteFromDesign: toDelete }
  }),
  on(fromActions.updateToDeleteFromDesign, (state, { newTargetLayer }) => {
    if (state.toDeleteFromDesign) {
      const toDeleteFromDesign: DeleteInuranceFromDesign = {
        ...state.toDeleteFromDesign,
        targetLayer: newTargetLayer,
      }
      return {
        ...state,
        toDeleteFromDesign,
      }
    } else {
      return state
    }
  }),
  on(
    fromAnalysisActions.fetchLayersByStructureSuccess,
    fromAnalysisActions.openAddInuranceDialogForEditSuccess,
    state => {
      return { ...state, loadingInDesign: false, loadingInMultiSection: false }
    }
  ),
  on(fromActions.openNewGrouperInurance, state => ({
    ...resetEditReducer(state),
    mode: 'new' as GrouperInuranceMode,
  })),

  on(fromActions.openDeleteGrouperInurance, (state, { source, target }) => ({
    ...resetEditReducer(state),
    mode: 'delete' as GrouperInuranceMode,
    source,
    target,
  })),

  on(fromActions.saveGrouperInuranceFromDesignSuccess, state => {
    if (state.source && state.target) {
      const fromDesignSaved: InuranceView[] = [state?.source, state?.target]
      return { ...state, fromDesignSaved }
    } else {
      return { ...state }
    }
  }),

  on(
    fromActions.cancelGrouperInurance,
    fromActions.saveGrouperInuranceSuccess,
    fromActions.deleteGrouperInuranceSuccess,
    fromActions.saveGrouperInuranceFromDesignSuccess,
    fromActions.deleteInuranceFromDesignSuccess,
    state => ({
      ...resetEditReducer(state),
    })
  ),

  on(fromActions.setGrouperInuranceCard, (state, { view, layers }) => {
    if (!state.cursor) {
      return state
    }

    let cursor: Terminal | null = null
    if (state.cursor === 'source' && !state.target) {
      cursor = 'target'
    }
    if (state.cursor === 'target' && !state.source) {
      cursor = 'source'
    }

    const next = { ...state, [state.cursor]: view, cursor, error: <string>null }

    const error = validateSourceAndTarget(next, layers)
    return error ? { ...state, error } : next
  }),

  on(fromActions.setGrouperInuranceCursor, (state, { cursor }) => ({
    ...state,
    cursor,
  })),

  on(fromActions.saveGrouperInurance, state => ({ ...state, saving: true })),

  on(fromActions.saveGrouperInuranceFailure, (state, { error }) => ({
    ...state,
    saving: false,
    fromDesignSaved: [],
    error: error.message,
  })),

  on(fromActions.deleteGrouperInurance, state => ({
    ...state,
    deleting: true,
  })),

  on(fromActions.deleteGrouperInuranceFailure, (state, { error }) => ({
    ...state,
    deleting: false,
    deleteError: error.message,
  }))
)

export function reducer(
  state: GrouperInuranceState | undefined,
  action: Action
): GrouperInuranceState {
  return grouperInuranceReducer(state, action)
}
