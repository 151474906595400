import { Dictionary } from '@ngrx/entity'
import { chain, last, uniqBy } from 'ramda'
import { rejectNil } from '../../../shared/util/operators'
import { filterTowerLayers, filterValid } from '../../model/layers.util'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { PortfolioSetIDAndName } from '../../model/portfolio-set.model'
import * as fromProgramGroupMember from './program-group-member/program-group-member.reducer'
import {
  GroupBar,
  GrouperSlide,
  GrouperState,
  ProgramGroupEntityWithProgramPortfolioIDs,
} from './program-group.model'
import * as fromProgramGroup from './program-group/program-group.reducer'
import { createProgramEntity, ProgramEntity } from './program/program.reducer'
import { selectProgramPortfolioSetIDAndName } from './program/program.selectors'

export const selectRootProgramGroupMemberEntities = (
  state: GrouperState
): fromProgramGroupMember.ProgramGroupMemberEntity[] =>
  fromProgramGroupMember.selectAllByID(state.rootIDs, state.programGroupMembers)

export const selectRootProgramGroupEntities = (
  state: GrouperState
): fromProgramGroup.ProgramGroupEntity[] =>
  selectRootProgramGroupMemberEntities(state)
    .map(pgm => pgm && pgm.programGroupMember.programGroupID)
    .map(pgID => (pgID ? state.programGroups.entities[pgID] : undefined))
    .filter(
      pg => pg != null && !pg.deleted
    ) as fromProgramGroup.ProgramGroupEntity[]

export const selectUntitledRootProgramGroupEntities = (
  state: GrouperState
): fromProgramGroup.ProgramGroupEntity[] =>
  selectRootProgramGroupEntities(state).filter(pg => pg.untitled)

export const selectAddRightRootOrdinal = (state: GrouperState): number => {
  const rightmostRoot = last(selectRootProgramGroupMemberEntities(state))
  if (!rightmostRoot) {
    return fromProgramGroupMember.DEFAULT_ORDINAL
  }
  return (
    rightmostRoot.programGroupMember.ordinal +
    fromProgramGroupMember.DEFAULT_ORDINAL
  )
}

export const selectMemberBy = (
  idOption: {
    id?: string
    programID?: string
    programGroupID?: string
    parentGroupID?: string
  },
  state: GrouperState
): fromProgramGroupMember.ProgramGroupMemberEntity =>
  // Since we are throwing if member is not found, can force non-null
  // tslint:disable-next-line: no-non-null-assertion
  fromProgramGroupMember.selectBy(
    { ...idOption, throwIfEmpty: true },
    state.programGroupMembers
  )!

export const selectMemberByProgramGroupID = (
  programGroupID: string,
  state: GrouperState
) =>
  fromProgramGroupMember.selectByProgramGroupID(
    programGroupID,
    state.programGroupMembers,
    false
  )

export const selectAllMembersByParentID = (
  parentGroupID: string,
  memberState: fromProgramGroupMember.ProgramGroupMemberEntityState
) => fromProgramGroupMember.selectAllByParentGroupID(parentGroupID, memberState)

const selectAncestorGroupsOfMember = (
  member: fromProgramGroupMember.ProgramGroupMemberEntity,
  state: GrouperState
) => {
  const ancestors: fromProgramGroup.ProgramGroupEntity[] = []
  let currentMember = member
  while (currentMember) {
    const parentID = currentMember.programGroupMember.parentGroupID
    const parentGroup = state.programGroups.entities[parentID]
    // End recursion if no group, or its deleted or the root untitled group
    if (!parentGroup || parentGroup.deleted || parentGroup.untitled) {
      break
    }

    ancestors.push(parentGroup)
    currentMember = selectMemberBy({ programGroupID: parentID }, state)
  }
  return ancestors
}

export const selectAllUndeletedMembersByParentID = (
  parentGroupID: string,
  state: GrouperState
) =>
  fromProgramGroupMember.selectAllUndeletedByParentGroupID(
    parentGroupID,
    state.programGroupMembers
  )

export const selectDirtyNewGroupMember = (state: GrouperState) =>
  fromProgramGroupMember.selectDirtyNewProgramGroupMember(
    state.programGroupMembers
  )

export const selectGroupBarsByID = (
  slides: GrouperSlide[]
): Record<string, GroupBar> =>
  slides.reduce(
    (acc, s) => ({
      ...acc,
      ...s.groupBars.reduce((map, g) => (g ? { ...map, [g.id]: g } : map), acc),
    }),
    {} as Record<string, GroupBar>
  )

export const selectGroupHasGroupAgg = (
  group: fromProgramGroup.ProgramGroupEntity,
  _state: GrouperState
): boolean => {
  // TODO: Implement actual calulcation to check if group needs agg tower
  // Awaiting PLEE implementation of group agg middleware
  if (group.programGroup.id === '410') {
    return true
  }
  return false
}

const createSlideProgramEntity = (
  programByID: Dictionary<ProgramEntity>,
  slide: GrouperSlide
): ProgramEntity | undefined => {
  const programEntity = programByID[slide.id]
  if (programEntity) {
    return {
      ...programEntity,
      cededLayers: programEntity.cededLayers.filter(filterTowerLayers),
    }
  }
}

const createSlideProgramEntityData = (
  programByID: Dictionary<ProgramEntity>,
  slide: GrouperSlide
): ProgramEntity | undefined => {
  const programEntity = programByID[slide.id]
  if (programEntity) {
    return {
      ...programEntity,
      cededLayers: programEntity.cededLayers.filter(c => filterValid(c.layer)),
    }
  }
}

const createGroupAggSlideProgramEntity = (
  programGroupsByID: Dictionary<fromProgramGroup.ProgramGroupEntity>,
  slide: GrouperSlide,
  // tslint:disable-next-line: variable-name
  TODO_REMOVE_programsByID: Dictionary<ProgramEntity>
): ProgramEntity | undefined => {
  const groupEntity = programGroupsByID[slide.groupID]
  if (groupEntity) {
    const group = groupEntity.programGroup
    const portfolioSetID = extractPortfolioSetID(group)
    if (portfolioSetID) {
      const programEntity = createProgramEntity({
        id: slide.id,
        label: 'Group Aggregate',
        programType: 'agg',
        analysisID: portfolioSetID.analysisProfileID,
        cededPortfolioID: portfolioSetID.cededPortfolioID,
        netPortfolioID: portfolioSetID.netPortfolioID,
        grossPortfolioID: portfolioSetID.grossPortfolioID,
        studyID: group.studyID || '',
        checkGroup: true,
        checkGroupOcc: true,
        yearID: group.yearID,
      })
      return {
        ...programEntity,
        // TODO: Get actual group layers from wherever in state they are stored
        // Awaiting PLEE implementation of group agg middleware
        // tslint:disable-next-line: no-non-null-assertion
        cededLayers: TODO_REMOVE_programsByID['985']!.cededLayers,
      }
    }
  }
}

export const selectProgramEntitiesForSlides = (
  programGroupsByID: Dictionary<fromProgramGroup.ProgramGroupEntity>,
  programsByID: Dictionary<ProgramEntity>,
  slides: GrouperSlide[]
): ProgramEntity[] =>
  rejectNil(
    slides.map(slide =>
      slide.isGroupAgg
        ? createGroupAggSlideProgramEntity(
            programGroupsByID,
            slide,
            programsByID
          )
        : createSlideProgramEntity(programsByID, slide)
    )
  )

export const selectProgramEntitiesForSlidesData = (
  programGroupsByID: Dictionary<fromProgramGroup.ProgramGroupEntity>,
  programsByID: Dictionary<ProgramEntity>,
  slides: GrouperSlide[]
): ProgramEntity[] =>
  rejectNil(
    slides.map(slide =>
      slide.isGroupAgg
        ? createGroupAggSlideProgramEntity(
            programGroupsByID,
            slide,
            programsByID
          )
        : createSlideProgramEntityData(programsByID, slide)
    )
  )

export const selectStructureMap = (
  slides: GrouperSlide[],
  programs: Dictionary<ProgramEntity>,
  programGroups: Dictionary<fromProgramGroup.ProgramGroupEntity>
): Map<ProgramEntity, fromProgramGroup.ProgramGroupEntity[]> =>
  slides
    .filter(s => !s.isGroupAgg)
    .reduce((acc, slide) => {
      const program = programs[slide.id]
      if (program) {
        const currentProgramGroups = rejectNil(
          rejectNil(slide.groupBars)
            .filter(groupBar => !groupBar.untitled)
            .map(groupBar => programGroups[groupBar.id])
        )
        if (acc.has(program)) {
          // tslint:disable-next-line: no-non-null-assertion
          acc.get(program)!.push(...currentProgramGroups)
        } else {
          acc.set(program, currentProgramGroups)
        }
      }
      return acc
    }, new Map<ProgramEntity, fromProgramGroup.ProgramGroupEntity[]>())

export const selectDirtyProgramGroupsWithPortfolioIDs = (
  state: GrouperState,
  dirtyGroups: fromProgramGroup.ProgramGroupEntity[],
  dirtyMembers: fromProgramGroupMember.ProgramGroupMemberEntity[]
): ProgramGroupEntityWithProgramPortfolioIDs[] => {
  // Make a dictionary of group ID to the list of the corresponding group's
  // descendent programs' portfolio set IDs (i.e. analysis, ceded ID, etc.)
  const portfolioIDsByGroup = state.slides
    .filter(s => !s.isGroupAgg)
    .reduce((acc, slide) => {
      const groupIDs = rejectNil(slide.groupBars).map(b => b.id)
      groupIDs.forEach(id => {
        const programEntity = state.programs.entities[slide.id]
        if (programEntity) {
          if (!acc[id]) {
            acc[id] = []
          }
          acc[id].push(
            selectProgramPortfolioSetIDAndName(programEntity.program)
          )
        }
      })
      return acc
    }, {} as Record<string, PortfolioSetIDAndName[]>)

  // Any groups with descendent dirty members are considered dirty since their
  // portfolio members will need to be updated on save
  const parentGroupsOfDirtyMembers = chain(
    m => selectAncestorGroupsOfMember(m, state),
    dirtyMembers
  )

  const allDirtyGroups = uniqBy(
    g => g.programGroup.id,
    [...dirtyGroups, ...parentGroupsOfDirtyMembers]
  )
  return allDirtyGroups.map(group => {
    const programPortfolioIDs = portfolioIDsByGroup[group.programGroup.id] || []
    return { ...group, programPortfolioIDs }
  })
}
