import { DecimalPipe, PercentPipe } from '@angular/common'
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { NgModel } from '@angular/forms'

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngModel][formatPercentage]',
  providers: [NgModel, PercentPipe],
})
export class FormatPercentageDirective implements OnChanges {
  @Input() isCession = false
  @Input() isReinstatements = false
  @Input() isNegative = false
  @Input() isROL = false
  @Input() input: any
  @Input() decimal: string
  @Input() isAssignedLines: boolean
  @Input() isQQ: boolean
  @Input() columnName: string | number | symbol
  @Output() formatNumber: EventEmitter<number> = new EventEmitter<number>()

  private pipe = new PercentPipe('en-US')
  private numberPipe = new DecimalPipe('en-US')
  private validKeys: string[] = [
    '.',
    'Backspace',
    'Delete',
    'Tab',
    'ArrowRight',
    'ArrowLeft',
  ]

  constructor(private el: ElementRef) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.el.nativeElement === document.activeElement) {
      if (this.isCession || this.isROL) {
        this.setValue(
          this.numberPipe.transform(
            Number(this.input) * 100,
            `1.${this.decimal}-${this.decimal}`
          )
        )
      } else {
        this.setValue(
          this.numberPipe.transform(Number(this.input) * 100, `1.0-0`)
        )
      }
      this.calculateAndSetValue(this.input)
    } else {
      this.transformInput(this.input)
    }
  }

  @HostListener('blur')
  blur() {
    this.transformInput()
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent): boolean {
    const { ctrlKey, metaKey, key, code } = event
    if (
      (this.el.nativeElement.value.indexOf('.') > -1 || !this.isCession) &&
      key === '.'
    ) {
      event.preventDefault()
      return false
    }
    if (
      (typeof Number(key) === 'number' &&
        !isNaN(Number(key)) &&
        code !== 'Space') ||
      (key === 'a' && (metaKey || ctrlKey)) ||
      (key === 'c' && (metaKey || ctrlKey)) ||
      (key === 'v' && (metaKey || ctrlKey)) ||
      (key === 'x' && (metaKey || ctrlKey)) ||
      (key === 'z' && (metaKey || ctrlKey)) ||
      this.validKeys.includes(key)
    ) {
      return true
    } else {
      return this.isNegative && key === '-'
    }
  }

  transformInput(override?: number): void {
    let input
    if (this.isCession || this.isReinstatements || this.isROL) {
      if (
        !this.input &&
        !(typeof this.input === 'number' && this.input === 0)
      ) {
        return
      }
      input = this.input
    } else {
      input = this.input > 1 ? 1 : this.input
    }
    const elementValue = this.convertedElementValue()

    if (override !== undefined) {
      input = override
    } else if (input !== elementValue) {
      input = elementValue
    }
    // formatting difference
    if (this.isCession || this.isROL) {
      if (this.isQQ && input === 0) {
        this.setValue('-')
      } else {
        this.setValue(
          this.pipe.transform(
            Number(input || 0),
            `1.${this.decimal}-${this.decimal}`
          )
        )
      }
    } else {
      this.setValue(this.pipe.transform(Number(input || 0)))
    }
    this.calculateAndSetValue(this.input)
  }

  calculateAndSetValue(input: number | string): void {
    if (this.isAssignedLines && this.columnName) {
      const columnStr = this.columnName.toString()
      if (
        columnStr === 'written' ||
        columnStr === 'recommended' ||
        columnStr === 'signed'
      ) {
        if ((input && input === 0) || input === '0') {
          this.setValue(this.pipe.transform(Number(input || 0), `1.4-4`))
        }
      }
    }
  }

  private setValue(value: string | null): void {
    this.el.nativeElement.value = value
  }

  private convertedElementValue(): number {
    const value = this.el.nativeElement.value
    if (value) {
      if (this.isQQ && value && value.startsWith('%')) {
        return 0
      }
      const num = value.replace(/[%,]/g, '')
      const converted = num ? Number(num / 100) : 1
      if (this.isCession || this.isReinstatements || this.isROL) {
        return isNaN(converted) ? 0 : converted
      } else {
        return converted > 1 ? 1 : isNaN(converted) ? 0 : converted
      }
    }
    return 0
  }
}
