import { AppState } from '.'
import { createSelector } from '@ngrx/store'

export const selectAccountOpportunityState = (state: AppState) =>
  state.accountOpportunity

export const selectAccountOpportunities = createSelector(
  selectAccountOpportunityState,
  state => state.accountOpportunities
)

export const selectCarrierAccountMap = createSelector(
  selectAccountOpportunityState,
  state => state.carrierAccountMap
)

export const selectIsSaving = createSelector(
  selectAccountOpportunityState,
  state => state.saving
)
