import {
  COMPARE_METRIC_DEFINITION_CELL_TYPE,
  CreditCompareMetric,
  CreditCompareMetricCategorySetting,
  CreditCompareTableColumn,
  DEFAULT_COMPARE_METRIC_DEFINITION_COLUMN,
} from '../model/credit-compare.model'
import {
  CreditMetricCategory,
  CreditMetricValueType,
} from '../model/credit-metric.model'

export const getCompareMetricCategorySettings = (
  metrics: CreditCompareMetric[]
): CreditCompareMetricCategorySetting[] => {
  return Object.values(
    metrics.reduce(
      (
        accumulator: {
          [category in CreditMetricCategory]: CreditCompareMetricCategorySetting
        },
        setting
      ) => {
        const { category } = setting
        if (!(category in accumulator)) {
          accumulator[category] = {
            category,
            metricSettings: [],
          }
        }
        accumulator[category].metricSettings.push({ ...setting, value: 0 })
        return accumulator
      },
      {} as {
        [category in CreditMetricCategory]: CreditCompareMetricCategorySetting
      }
    )
  )
}

export const sortByCategoryAndMetricValue = (
  category: CreditMetricCategory,
  notExpectedLossPmiersOrLossRatio: boolean
): ((a: CreditCompareMetric, b: CreditCompareMetric) => number) => {
  return (a: CreditCompareMetric, b: CreditCompareMetric) => {
    if (category === 'Net') {
      if (notExpectedLossPmiersOrLossRatio) {
        return b.value - a.value
      } else {
        return a.value - b.value
      }
    } else if (category === 'Ceded') {
      if (notExpectedLossPmiersOrLossRatio) {
        return a.value - b.value
      } else {
        return b.value - a.value
      }
    }
    return 0
  }
}

export const buildMetricDefinitionColumn = (
  metricCategorySettings: CreditCompareMetricCategorySetting[]
): CreditCompareTableColumn => {
  return {
    ...DEFAULT_COMPARE_METRIC_DEFINITION_COLUMN,
    categories: metricCategorySettings.map(category => {
      return {
        label: category.category,
        metrics: category.metricSettings.map(m => {
          return {
            value: m.label,
            type: COMPARE_METRIC_DEFINITION_CELL_TYPE,
          }
        }),
      }
    }),
  }
}

export const getRoundedMetric = (
  metric: CreditCompareMetric
): CreditCompareMetric => {
  return { ...metric, value: getRoundedMetricValue(metric) }
}

const getRoundedMetricValue = (metric: CreditCompareMetric): number =>
  metric.type === CreditMetricValueType.Currency
    ? roundNumberToShortNumber(metric.value)
    : roundPercentageToTwoDecimals(metric.value)

const roundNumberToShortNumber = (value: number): number => {
  if (value < 1e3) {
    return Math.round(value / 100) * 100 // Round to nearest 100 if < 1K
  } else if (value < 1e6) {
    return Math.round(value / 1e2) * 1e2 // Round to nearest 100 if < 1M
  } else if (value < 1e9) {
    return Math.round(value / 1e5) * 1e5 // Round to nearest 100K if < 1B
  } else if (value < 1e12) {
    return Math.round(value / 1e8) * 1e8 // Round to nearest 100M if < 1T
  } else {
    return Math.round(value / 1e11) * 1e11 // Round to nearest 100B if >= 1T
  }
}

const roundPercentageToTwoDecimals = (value: number): number =>
  Number((value * 100).toFixed(2)) / 100
