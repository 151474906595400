import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../../nav.service'
import {
  AccountOpportunity,
  CarrierAccount,
  CarrierData,
} from '../../../api/model/backend.model'
import { MatDialog } from '@angular/material/dialog'
import { ProgramInitiationMappingDialogComponent } from '../program-initiation-mapping-dialog/program-initiation-mapping-dialog.component'
import { BackendService } from '../../../api/backend/backend.service'
import { AccountMappingData } from '../../model/program-initiation.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-initiation-header',
  styleUrls: [`./program-initiation-header.component.scss`],
  templateUrl: `./program-initiation-header.component.html`,
})
export class ProgramInitiationHeaderComponent implements OnChanges {
  @Input() name: string
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() carrierAccounts: CarrierAccount[] | null
  @Input() allCarriers: any
  @Output() updateSFMapping = new EventEmitter<AccountMappingData>()

  showSFMappingButton = false

  private carriers: CarrierData[]
  private accountMappingData: AccountMappingData[]

  get subtitle(): string {
    return 'Create a new SAGE Program from a Salesforce Opportunity object.'
  }

  constructor(
    public dialog: MatDialog,
    public navService: NavService,
    private router: Router,
    public backendService: BackendService
  ) {}

  ngOnChanges(): void {
    this.carriers = this.allCarriers?.data ?? []
    this.accountMappingData = this.getAccountMappingData()
    this.showSFMappingButton = this.accountMappingData.length >= 1
  }

  onBackClick(): void {
    this.router.navigate(['/home'])
  }

  onSFMappingClick(): void {
    const data = {
      carriers: this.carriers,
      accountMappingData: this.accountMappingData,
    }
    const dialogRef = this.dialog.open(
      ProgramInitiationMappingDialogComponent,
      {
        data,
      }
    )
    dialogRef.afterClosed().subscribe((updatedMapping: AccountMappingData) => {
      if (!updatedMapping) {
        return
      }
      this.updateSFMapping.emit(updatedMapping)
    })
  }

  private getAccountMappingData(): AccountMappingData[] {
    return this.getDistinctAccountOpportunities()
      .map(opportunity => {
        const carrier = this.carrierAccounts?.find(
          c => c.sf_acctid === opportunity.accountId
        )
        const carrierData = carrier
          ? this.carriers.find(
              (r: { id: number }) => r.id === carrier.carrier_id
            )
          : undefined
        return {
          sfAccountId: opportunity.accountId,
          sfAccountName: opportunity.accountName,
          carrierId: carrierData?.id ?? 0,
          carrierName: carrierData?.name,
        } as AccountMappingData
      })
      .filter(opportunity => !!opportunity.carrierName)
  }

  private getDistinctAccountOpportunities(): AccountOpportunity[] {
    return Object.values(
      this.accountOpportunities.reduce(
        (acc, obj) => ({ ...acc, [obj.accountName]: obj }),
        {}
      )
    )
  }
}
