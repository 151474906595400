import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-loading-item',
  styles: [
    `
           div {
             padding: var(--inset);
             user-select: none;
           }
         `,
  ],
  template: `
    <ng-container *ngIf="isLoaded; else notLoadedTemplate">
      <ng-container *ngIf="isNotEmpty; else emptyTemplate">
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
    <ng-template #notLoadedTemplate>
      <ng-container *ngIf="isError; else loadingOrEmptyTemplate">
        <div appInfoText warn big [ngStyle]="divStyle">
          <span>{{ error }}</span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #loadingOrEmptyTemplate>
      <ng-container *ngIf="loading; else emptyTemplate">
        <div appInfoText big [ngStyle]="divStyle">Loading...</div>
      </ng-container>
    </ng-template>
    <ng-template #emptyTemplate>
      <div appInfoText big [ngStyle]="divStyle">No {{ name }}</div>
    </ng-template>
  `,
})
export class AppLoadingItemComponent {
  @Input() item: any
  @Input() loading = false
  @Input() error: string | null | undefined = null
  @Input() name = 'Items'
  @Input() height?: string

  @Input()
  set allowEmptyArray(value: boolean) {
    this._allowEmptyArray = coerceBooleanProperty(value)
  }
  get allowEmptyArray(): boolean {
    return this._allowEmptyArray
  }
  private _allowEmptyArray: boolean

  get divStyle() {
    return this.height ? { height: this.height } : {}
  }

  get isLoaded(): boolean {
    return !this.loading && this.error == null && this.item != null
  }

  get isError(): boolean {
    return this.error != null
  }

  get isNotEmpty(): boolean {
    if (!this.allowEmptyArray && Array.isArray(this.item)) {
      return this.item.length > 0
    }
    // Not an array, so we don't want to show emptyTemplate
    return true
  }
}
