import { curry } from 'ramda'
import { InuranceTag, Terminal } from '../analysis/model/inurance.model'
import {
  TowerBoxElement,
  TowerTagElement,
} from '../analysis/tower/mechanics/tower.model'

export const getRibbonTagTextTranslateX = (
  terminal: Terminal = 'source'
): number => (terminal === 'source' ? 6.5 : 9.5)

export const getRibbonTagTextTransform = (
  terminal: Terminal = 'source'
): string => {
  const x = getRibbonTagTextTranslateX(terminal)
  return `translate(${x} 13)`
}

export const createRibbonTagElement = curry(
  (box: TowerBoxElement, tag: InuranceTag, index: number): TowerTagElement => {
    const data = box.datum()
    const { boxWidth, x, y } = data
    const tagElement = box
      .append('svg')
      .data([{ ...data, inuranceTag: tag }])
      .attr('width', '24')
      .attr('height', '18')
      .attr('fill', 'var(--body)')
      .attr('color', tag.isSection ? 'coral' : 'var(--body)')
      .style('cursor', 'pointer')

    updateRibbonTagElementX(x, boxWidth, tagElement)
    updateRibbonTagElementY(y, tagElement, index)

    tagElement.append('use').attr('xlink:href', `#ribbon-tag-${tag.terminal}`)

    const translateX = getRibbonTagTextTranslateX(tag.terminal)
    tagElement
      .append('text')
      .attr('transform', `translate(${translateX} 13)`)
      .attr('font-family', 'var(--font-family)')
      .attr('font-weight', 'var(--font-header-weight)')
      .attr('font-size', '12')
      .append('tspan')
      .text(() => `${tag.symbol}`)

    return tagElement
  }
)

export const updateRibbonTagElementX = curry(
  (boxX: number, boxWidth: number, tagElement: TowerTagElement): void => {
    tagElement.attr('x', boxX + boxWidth - 29)
  }
)

export const updateRibbonTagElementY = curry(
  (boxY: number, tagElement: TowerTagElement, index: number): void => {
    tagElement.attr('y', boxY + 6 + index * 24)
  }
)
