import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { Program } from '../../../core/model/program.model'
import { MatDialog } from '@angular/material/dialog'
import {
  LossSetTableState,
  ModifierState,
  SummaryChartState,
} from '../store/explore.reducer'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../../model/metrics.model'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { CurrencyRate } from '../../tower/mechanics/tower.model'
import { LossSetToAdd } from '../store/explore.model'
import { uniq } from 'ramda'
import { MatTabChangeEvent } from '@angular/material/tabs'
import {
  AccountOpportunity,
  ExploreSummaryView,
  StudyResponse,
} from 'src/app/api/model/backend.model'
import {
  DEFAULT_MAPPING_LABELS,
  MappingLabels,
} from 'src/app/core/model/study.model'
import {
  ExploreFilterMap,
  GroupSummaryRequest,
  MappingOption,
  SummaryDataResponse,
  CombinedFilterState,
  ExploreSummaryDatum,
} from '../explore.model'
import {
  filterLossSets,
  getGroupedSummaryRequest,
  getRowsFromLossSets,
} from '../explore.util'
import { Client } from 'src/app/core/model/client.model'
import { environment } from 'src/environments/environment'
import {
  SummaryChartAxis,
  SummaryChartGridOption,
} from '../explore-gross-summary-charts/summary-charts-model'
import {
  ExploreMultiSelectExportDialogContainerComponent,
  ExploreMultiSelectExportDialogData,
} from '../export/multi-select-export/explore-multi-select-export-dialog-container/explore-multi-select-export-dialog.container'
import { shiftClick } from '@shared/util/checkbox'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore',
  styleUrls: ['./explore.component.scss'],
  templateUrl: './explore.component.html',
})
export class ExploreComponent implements OnInit {
  isProd = environment.production
  isUat = environment.uat
  menu: any
  allLossSetsSelected = false
  currentTabIndex = 0
  selectedTab = 'Table'
  tabs = ['Gross Table', 'Gross Graph', 'Summary Table', 'Summary Charts']
  mappingLabels: MappingLabels = DEFAULT_MAPPING_LABELS
  modelingArray: string[]
  studySummaryfilters: string[]
  studySummaryColumnFilters: string[]
  summaryFilterMap: ExploreFilterMap = {}
  summaryColumnFilterMap: ExploreFilterMap = {}
  showIndividualLossSets: boolean = false
  summaryView: boolean = false
  summaryChartsView: boolean = false
  summaryFilterOptions: MappingOption[]
  groups: GroupSummaryRequest[]
  study: StudyResponse
  selectedStudySummaryFilterView: ExploreSummaryView | null = null
  isSavedViewDirty: boolean = false
  summaryLossSetRows: ExploreSummaryDatum[]

  @Input() loading: boolean
  @Input() error: boolean
  @Input() program: Program | undefined
  @Input() selectedStructure: Program
  @Input() lossSetLayers: LossSetLayer[]
  @Input() summaryLossSetLayers: LossSetLayer[]
  @Input() lossSetGroups: LossSetGroup[]
  @Input() currentStructureCurrency: string | undefined
  @Input() analysisProfileCurrency: string
  @Input() studies: StudyResponse[]
  @Input() summaryData: SummaryDataResponse[]
  @Input() groupSummaryData: SummaryDataResponse[]
  @Input() client: Client
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() summaryViews: ExploreSummaryView[] = []
  @Input() summaryLoading: boolean
  @Input() summaryRP: number[]
  @Input() chartState: SummaryChartState
  currentRate = 1
  _currencyRates: CurrencyRate[]
  @Input() set currencyRates(currencyRates: CurrencyRate[]) {
    this._currencyRates = currencyRates
    this.currentRate =
      currencyRates.find(
        ({ Currency }) => Currency === this.currentStructureCurrency
      )?.Rate ?? 1
  }
  get currencyRates(): CurrencyRate[] {
    return this._currencyRates
  }

  get summaryPdfExportDisabled(): boolean {
    return (
      !this.summaryViews ||
      this.summaryViews.length === 0 ||
      !this.summaryData ||
      this.summaryData.length === 0
    )
  }

  @Input() grossPortfolioView: string | null
  @Input() dataTable: LossSetTableState[]

  @Input() savedLossSetIDs: string[] | null
  @Input() selectedModifiers: ModifierState

  @Input() lossFilters: LossFilter[]

  @Output() programAddExplore = new EventEmitter<Program>()

  @Output() addLossSetIDs = new EventEmitter<{
    lossSetGroupsToAdd?: LossSetToAdd[]
    lossSetLayersToAdd?: LossSetToAdd[]
  }>()
  @Output() getSummaryData = new EventEmitter<{
    lossSetLayers?: LossSetToAdd[]
  }>()
  @Output() onGetGroupedSummaryData = new EventEmitter<{
    groups: GroupSummaryRequest[]
  }>()

  @Output() removeLossSetIDs = new EventEmitter<{
    lossSetGroupsToRemove?: string[]
    lossSetLayersToRemove?: string[]
  }>()

  @Output() exportAsXlsx = new EventEmitter<{
    lossSetData?: LossSetTableState[]
    aggregationMethod: string
    vartvar: string
    perspective: string
  }>()

  @Output() modifiersChange = new EventEmitter<{
    rp?: number
    index?: number
    perspective: Perspective
    aggregationMethod: AggregationMethodType
    vartvar: VaRTVaR
    isLossRatioView: boolean
  }>()

  @Output() exploreClear = new EventEmitter()
  @Output() saveAsSummaryView = new EventEmitter<ExploreSummaryView>()
  @Output() deleteSummaryView = new EventEmitter<{ id: number }>()
  @Output() updateSummaryRP = new EventEmitter<number[]>()
  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() chartsGroupByChange = new EventEmitter<{
    groupBy: SummaryChartAxis
    chartIndex: number
  }>()
  @Output() chartsGridIndexSelection = new EventEmitter<number>()
  @Output() chartEntityLimitChange = new EventEmitter<number>()
  @Output() updateLightChartMode = new EventEmitter<boolean>()

  selectedLossSetIDs: string[]

  constructor(public dialog: MatDialog) {}

  get showEmptySelectedLabel(): boolean {
    return this.savedLossSetIDs?.length === 0
  }

  get showTable(): boolean {
    return this.dataTable.length > 0
  }

  get addMenuLabel(): string {
    return 'Select LOSS SET / GROUP'
  }

  get showSummaryCharts(): boolean {
    return (
      this.modelingArray[0] !== '' &&
      ((this.groupSummaryData && this.groupSummaryData.length > 0) ||
        this.summaryLoading)
    )
  }

  ngOnInit() {
    this.modelingArray = ['', '', '', '', '']
    if (this.program) {
      this.programSelect(this.program)
      this.study = this.studies.filter(
        s => String(s.id) === this.program.studyID
      )[0]
      if (this.study) {
        if (this.study.mapping_labels) {
          this.mappingLabels = this.study.mapping_labels
        }
      }
    }
    if (this.savedLossSetIDs) {
      this.selectedLossSetIDs = this.savedLossSetIDs
    }
    this.summaryFilterOptions = [
      { label: 'Loss Type', value: 'loss_type' },
      { label: 'Loss Scale Factor', value: 'lossScaleFactor' },
      { label: 'Premium Scale Factor', value: 'premiumScaleFactor' },
      { label: 'LS Dim 1', value: 'ls_dim1' },
      { label: 'LS Dim 2', value: 'ls_dim2' },
      { label: this.mappingLabels.map1, value: 'map1' },
      { label: this.mappingLabels.map2, value: 'map2' },
      { label: this.mappingLabels.map3, value: 'map3' },
      { label: this.mappingLabels.map4, value: 'map4' },
      { label: this.mappingLabels.map5, value: 'map5' },
    ]
  }

  onTabChange(event: MatTabChangeEvent) {
    this.currentTabIndex = event.index
    this.selectedTab = this.tabs[this.currentTabIndex]
    this.summaryView = this.selectedTab.includes('Summary')
    this.summaryChartsView = this.selectedTab.includes('Charts')
    if (this.summaryView && this.lossSetLayers) {
      this.getSummaryLayers()
    }
  }

  // Index of last shift + clicked option.
  lastClickedIndex: number | null = null

  getSummaryLayers(): void {
    const lossSetLayers: LossSetToAdd[] = []
    this.lossSetLayers.forEach(lossSetLayer => {
      let subjectPremiumAmt = lossSetLayer?.premium?.value ?? 0
      subjectPremiumAmt = subjectPremiumAmt / this.currentRate
      const { ls_dim1 = '', ls_dim2 } = lossSetLayer?.meta_data
      const lossName = ls_dim1 + (ls_dim2 ? ' - ' + ls_dim2 : '')
      lossSetLayers.push({
        lossID: lossSetLayer.id,
        lossName,
        filterValue: 'all',
        isLossRatioView: false,
        subjectPremiumAmt,
      })
    })
    if (lossSetLayers.length > 0) {
      this.getSummaryData.emit({ lossSetLayers })
    }
  }

  onLossSetClick(lossID: string, $event: MouseEvent): void {
    const allIds = [...this.lossSetGroups, ...this.lossSetLayers]
    const index = allIds.findIndex(({ id }) => id === lossID)
    const lossSelected = allIds[index].isSelected
    const obj = { id: lossID }

    const rangeData = shiftClick(obj, $event, allIds, this.lastClickedIndex)
    const inRange = rangeData.inRange.map(r => r.id)

    if (!$event.shiftKey) {
      if (this.selectedLossSetIDs.includes(lossID)) {
        this.selectedLossSetIDs = this.selectedLossSetIDs.filter(
          id => id !== lossID
        )
      } else {
        this.selectedLossSetIDs = uniq([...this.selectedLossSetIDs, lossID])
      }
      this.lossSetLayers = this.lossSetLayers.map(l =>
        lossID === l.id ? { ...l, isSelected: !l.isSelected } : l
      )
      this.lossSetGroups = this.lossSetGroups.map(l =>
        lossID === l.id ? { ...l, isSelected: !l.isSelected } : l
      )
      this.onLossSetChange()
      this.lastClickedIndex = rangeData.lastIndex
      return
    }

    if (lossSelected) {
      this.selectedLossSetIDs = this.selectedLossSetIDs.filter(id => !inRange.includes(id))
    } else {
      this.selectedLossSetIDs = Array.from(new Set([...this.selectedLossSetIDs, ...inRange]))
    }


    this.lossSetLayers = this.lossSetLayers.map(l => {
      const isSelected = this.selectedLossSetIDs.includes(l.id)
      return {
        ...l,
        isSelected,
      }
    })

    this.lossSetGroups = this.lossSetGroups.map(l => {
      const isSelected = this.selectedLossSetIDs.includes(l.id)
      return {
        ...l,
        isSelected,
      }
    })
    this.lastClickedIndex = rangeData.lastIndex
    this.onLossSetChange()
  }

  programSelect(program: Program): void {
    if (
      this.selectedStructure === null ||
      this.selectedStructure.id !== program.id ||
      this.lossSetLayers.length === 0
    ) {
      this.programAddExplore.emit(program)
    }
  }

  onLossFilterClick(event: {
    lossID: string
    lossName: string
    filterValue: string
    lossType: string
  }): void {
    let structureCurrencyRate = this.currencyRates.find(
      (res: CurrencyRate) => res.Currency === this.currentStructureCurrency
    )?.Rate
    structureCurrencyRate = structureCurrencyRate ? structureCurrencyRate : 1
    const isLossRatioView = this.selectedModifiers.isLossRatioView || false
    if (event.lossType === 'Group') {
      let subjectPremiumAmt = 0
      const layers = this.lossSetGroups.filter(l => l.id === event.lossID)[0]
        .lossSetLayers
      if (layers) {
        for (const lossSetLayer in layers) {
          if (lossSetLayer) {
            subjectPremiumAmt += Number(
              layers[lossSetLayer].premium?.value || 0
            )
          }
        }
      }
      subjectPremiumAmt = subjectPremiumAmt / structureCurrencyRate
      this.addLossSetIDs.emit({
        lossSetGroupsToAdd: [
          {
            lossID: event.lossID,
            lossName: event.lossName,
            filterValue: event.filterValue,
            isLossRatioView,
            subjectPremiumAmt,
          },
        ],
      })
    } else if (event.lossType === 'Layer') {
      let subjectPremiumAmt = this.lossSetLayers.filter(
        l => l.id === event.lossID
      )[0]?.premium?.value
      subjectPremiumAmt = subjectPremiumAmt / structureCurrencyRate
      this.addLossSetIDs.emit({
        lossSetLayersToAdd: [
          {
            lossID: event.lossID,
            lossName: event.lossName,
            filterValue: event.filterValue,
            isLossRatioView,
            subjectPremiumAmt,
          },
        ],
      })
    }
  }

  onLossSetChange(): void {
    const savedLossSetIDs = this.savedLossSetIDs || []
    const lossSetLayerIDs = this.lossSetLayers.map(l => l.id)
    const isLossRatioView = this.selectedModifiers.isLossRatioView || false

    if (this.selectedLossSetIDs.length > savedLossSetIDs.length) {
      // Add Loss Set Layer / Group
      const newLossIDs = this.selectedLossSetIDs.filter(
        x => !savedLossSetIDs.includes(x)
      )
      const lossSetLayersToAdd: LossSetToAdd[] = []
      const lossSetGroupsToAdd: LossSetToAdd[] = []

      newLossIDs.forEach(lossID => {
        if (lossSetLayerIDs.includes(lossID)) {
          // Loss Set Layer
          const lossSetLayer = this.lossSetLayers.find(
            ({ id }) => id === lossID
          ) as LossSetLayer
          let subjectPremiumAmt = lossSetLayer?.premium?.value ?? 0
          subjectPremiumAmt = subjectPremiumAmt / this.currentRate

          const { ls_dim1 = '', ls_dim2 } = lossSetLayer?.meta_data
          const lossName = ls_dim1 + (ls_dim2 ? ' - ' + ls_dim2 : '')

          lossSetLayersToAdd.push({
            lossID,
            lossName,
            filterValue: 'all',
            isLossRatioView,
            subjectPremiumAmt,
          })
        } else {
          // Loss Set Group
          const lossSetGroup = this.lossSetGroups.find(
            ({ id }) => id === lossID
          )
          let subjectPremiumAmt = 0
          if (lossSetGroup?.lossSetLayers) {
            for (const lossSetLayer of lossSetGroup?.lossSetLayers) {
              subjectPremiumAmt += Number(lossSetLayer.premium?.value || 0)
            }
          }
          subjectPremiumAmt = subjectPremiumAmt / this.currentRate
          lossSetGroupsToAdd.push({
            lossID,
            lossName: lossSetGroup?.name ?? '',
            filterValue: 'all',
            isLossRatioView,
            subjectPremiumAmt,
          })
        }
      })

      if (lossSetGroupsToAdd.length > 0 || lossSetLayersToAdd.length > 0) {
        this.addLossSetIDs.emit({ lossSetGroupsToAdd, lossSetLayersToAdd })
      }
    } else {
      // Remove Loss Set / Group
      const idsToRemove = savedLossSetIDs.filter(
        x => !this.selectedLossSetIDs.includes(x)
      )
      const lossSetLayersToRemove: string[] = []
      const lossSetGroupsToRemove: string[] = []
      idsToRemove.forEach(lossID => {
        if (lossSetLayerIDs.includes(lossID)) {
          lossSetLayersToRemove.push(lossID)
        } else {
          lossSetGroupsToRemove.push(lossID)
        }
      })

      if (
        lossSetGroupsToRemove.length > 0 ||
        lossSetLayersToRemove.length > 0
      ) {
        this.removeLossSetIDs.emit({
          lossSetLayersToRemove,
          lossSetGroupsToRemove,
        })
      }
    }
  }

  onSelectAllLossSets(): void {
    this.allLossSetsSelected = !this.allLossSetsSelected
    if (this.allLossSetsSelected) {
      this.selectedLossSetIDs = [
        ...this.lossSetLayers.map(l => l.id),
        ...this.lossSetGroups.map(l => l.id),
      ]
    } else {
      this.selectedLossSetIDs = []
    }

    this.onLossSetChange()
  }

  onUpdateCombinedFilterState($event: CombinedFilterState): void {
    this.modelingArray = [...$event.modelingArray]
    this.summaryColumnFilterMap = { ...$event.summaryColumnFilterMap }
    this.summaryFilterMap = { ...$event.summaryFilterMap }
    this.selectedStudySummaryFilterView = $event.selectedStudySummaryFilterView
    this.showIndividualLossSets = $event.showIndividualLossSets
    this.isSavedViewDirty = $event.isSavedViewDirty
    if (this.modelingArray[0] !== '') {
      this.getGroupedSummaryData()
    }
  }

  getGroupedSummaryData(): void {
    const filteredLossSets = filterLossSets(
      this.lossSetLayers,
      this.summaryFilterMap
    )
    this.summaryLossSetRows = getRowsFromLossSets(
      filteredLossSets,
      this.summaryData,
      this.modelingArray.filter(x => x !== '')
    )
    if (this.summaryLossSetRows) {
      this.groups = getGroupedSummaryRequest(
        this.summaryLossSetRows,
        this.modelingArray,
        filteredLossSets
      )
      if (this.groups) {
        this.onGetGroupedSummaryData.emit({ groups: this.groups })
      }
    }
  }

  onDeleteSummaryView($event: { id: number }): void {
    const { id } = $event
    this.deleteSummaryView.emit({ id })
    this.selectedStudySummaryFilterView = null
  }

  onUpdateView($event: ExploreSummaryView): void {
    this.isSavedViewDirty = false
    this.saveAsSummaryView.emit($event)
  }

  onUpdateSummaryRP(rp: number[]): void {
    this.updateSummaryRP.emit(rp)
    setTimeout(() => {
      if (this.modelingArray.filter(x => x !== '').length > 0) {
        this.getGroupedSummaryData()
      } else {
        this.getSummaryLayers()
      }
    }, 1000)
  }

  onChartsGroupByChange(props: {
    groupBy: SummaryChartAxis
    chartIndex: number
  }): void {
    this.chartsGroupByChange.emit({
      groupBy: props.groupBy,
      chartIndex: props.chartIndex,
    })
  }

  onChartsGridIndexSelection(gridIndex: number): void {
    this.chartsGridIndexSelection.emit(gridIndex)
  }

  onChartEntityLimitChange(limit: number): void {
    this.chartEntityLimitChange.emit(limit)
  }

  onExportPDFClick(): void {
    const data: ExploreMultiSelectExportDialogData = {
      program: this.selectedStructure,
      currentStructureCurrency: this.currentStructureCurrency,
      summaryLossSetRows: this.summaryLossSetRows,
      summaryColumnFilterMap: this.summaryColumnFilterMap,
      summaryViews: this.summaryViews,
      selectedSummaryView: this.selectedStudySummaryFilterView,
      summaryData: this.summaryData,
      lossSetLayers: this.lossSetLayers,
      summaryFilterMap: this.summaryFilterMap,
      summaryRP: this.summaryRP,
      mappingLabels: this.mappingLabels,
      accountOpportunities: this.accountOpportunities,
      client: this.client,
      study: this.study,
    }
    this.dialog.open(ExploreMultiSelectExportDialogContainerComponent, {
      width: '90vw',
      height: '90vh',
      minWidth: '90vw',
      minHeight: '90vh',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data,
    })
  }
}
