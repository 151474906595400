import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DeleteGroupDialogComponentData {
  allowDelete: boolean
  isChild: boolean
  hasSLI: boolean
  label: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delete-group',
  templateUrl: './delete-group-dialog.component.html',
})

export class DeleteGroupDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteGroupDialogComponentData
  ) {}

  newGroupClick(): void {
    this.dialogRef.close('delete')
  }

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  canDelete(): boolean {
    return this.data.allowDelete && !this.data.isChild && !this.data.hasSLI
  }
}
