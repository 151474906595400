import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LayerRef } from '../analysis/model/layers.model'
import { LossSetLayer } from '../analysis/model/loss-set-layers.model'
import { LogicalPortfolioLayer } from '../api/analyzere/analyzere.model'

export interface CloneDialogResult {
  parentGrossPortfolioID?: string
  analysisProfileID?: string
  layers?: LossSetLayer[] | LayerRef[] | any
  cededLayers?: LogicalPortfolioLayer[] | undefined
  lossSetNames?: string[]
  index?: number
  isClone: boolean
}
@Component({
  selector: 'app-clone-structure-dialog',
  styles: [
    `
      .full-width {
        width: 100%;
      }
      .loading-cursor {
        cursor: wait;
      }

      .mat-mdc-form-field {
        border-bottom: 1px solid white;
        padding: 25px 0 7px 0;
      }

      :host ::ng-deep .mat-mdc-dialog-actions {
        padding: 0 24px 9px;
      }
    `,
  ],

  template: `
    <div class="clone-dialog">
      <h1 mat-dialog-title>Input Ids</h1>

      <mat-dialog-content>
        <mat-form-field
          class="full-width"
          appearance="fill"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            required
            placeholder="Analysis Profile ID"
            [(ngModel)]="analysisProfileID"
          />
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="fill"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            required
            placeholder="Parent Gross Portfolio ID"
            [(ngModel)]="parentGrossPortfolioID"
          />
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button appButton border (click)="onSave()">{{ title }}</button>
        <button appButton border (click)="onClose()">Close</button>
      </mat-dialog-actions>
    </div>
  `,
})
export class CloneDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CloneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title
  }
  analysisProfileID: string
  parentGrossPortfolioID: string
  addLossSets: boolean
  title: string
  onSaveClick: boolean
  onSave() {
    if (this.analysisProfileID && this.parentGrossPortfolioID) {
      this.dialogRef.close({
        analysisProfileID: this.analysisProfileID,
        parentGrossPortfolioID: this.parentGrossPortfolioID,
        event: 'save',
      })
    }
  }
  onClose() {
    this.dialogRef.close({})
  }
}
