import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import * as fromPanels from './analysis-panels.reducer'
import * as fromActions from './analysis.actions'
import * as fromLayers from './ceded-layers/layers.reducer'
import * as fromCededPortfolio from './ceded-portfolio/portfolio.reducer'
import * as fromCompare from './compare/compare.reducer'
import * as fromGrouper from './grouper/grouper.reducer'
import { GrouperState } from './grouper/program-group.model'
import * as fromLossSetLayer from './loss-set-layers/loss-set-layers.reducer'
import * as fromNetPortfolio from './net-portfolio/portfolio.reducer'
import * as fromPortfolioSet from './portfolio-set/portfolio-set.reducer'
import * as fromMetric from './metrics/metrics-cart/metrics-cart.reducer'
import * as fromExplore from '../explore/store/explore.reducer'
import * as fromLayerDetails from './layer-details/layer-details.reducer'
import * as fromTechnicalPremium from './technical-premium/technical-premium.reducer'
import { LogicalPortfolioLayerWithChecked } from '../../tier/swap-loss-sets-dialog/swap-loss-sets-dialog.component'
import { initializeTagsByLevel } from '../model/inurance.util'
import { InuranceTagsByLevel } from '../model/inurance.model'
import { Dictionary } from '@ngrx/entity'

export const ANALYSIS_FEATURE_KEY = 'analysis'

export interface EditorState {
  cededPortfolioID: string | null
  analysisProfileID: string | null
  grossPortfolioID: string | null
  netPortfolioID: string | null
  parentGrossPortfolioID: string | null | undefined
  clientID: string | null
  cededLayers: fromLayers.State
  cededPortfolio: fromCededPortfolio.State
  netPortfolio: fromNetPortfolio.State
  lossSetLayers: fromLossSetLayer.State
  panels: fromPanels.State
  savingAs: boolean
  savingAsError: string | null
  savingAsClone: boolean
  isNewDropLayerSaving: boolean
  savingAsCloneError: string | null
  studyID: string | null
  structureID: string | null
  uploadingImage: boolean
  showAggregate: boolean
  showOccurrence: boolean
  yearID: string | null
  startingCession: boolean
  zoomValue: number
  layerDetailsTitleUpdate: fromLayerDetails.LayerDetailsState
  mapLossSets: LogicalPortfolioLayerWithChecked[][]
  currentCurrency?: string
  inurance: {
    tagsByLevel: InuranceTagsByLevel
    symbolMap: Dictionary<string>
  }
  technicalPremium: fromTechnicalPremium.TechnicalPremiumState
}
export interface State {
  editor: EditorState
  compare: fromCompare.State
  grouper: GrouperState
  portfolio: fromPortfolioSet.State
  metric: fromMetric.State
  explore: fromExplore.State
}

const initialEditorState: EditorState = {
  cededPortfolioID: null,
  grossPortfolioID: null,
  netPortfolioID: null,
  parentGrossPortfolioID: null,
  analysisProfileID: null,
  clientID: null,
  cededLayers: fromLayers.initialState,
  cededPortfolio: fromCededPortfolio.initialState,
  netPortfolio: fromNetPortfolio.initialState,
  lossSetLayers: fromLossSetLayer.initialState,
  panels: fromPanels.initialState,
  savingAs: false,
  savingAsClone: false,
  isNewDropLayerSaving: false,
  uploadingImage: false,
  savingAsError: null,
  savingAsCloneError: null,
  studyID: null,
  structureID: null,
  showAggregate: false,
  showOccurrence: true,
  yearID: null,
  startingCession: false,
  zoomValue: 1,
  layerDetailsTitleUpdate: fromLayerDetails.initialState,
  mapLossSets: [],
  inurance: {
    tagsByLevel: initializeTagsByLevel(),
    symbolMap: {},
  },
  technicalPremium: fromTechnicalPremium.initialState,
}

const inuranceReducer = createReducer(
  initialEditorState.inurance,
  on(fromActions.addInuranceTagsByLevelSuccess, (_, inurance) => inurance)
)

const startingCessionReducer = createReducer(
  initialEditorState.startingCession,
  on(fromActions.layerDataUpload, _ => true),
  on(fromActions.layerDataUploadFailure, _ => false),
  on(fromActions.layerDataUploadSuccess, _ => false)
)

const imageUploadReducer = createReducer(
  initialEditorState.uploadingImage,
  on(fromActions.imageUpload, _ => true),
  on(fromActions.imageUploadFailure, _ => false),
  on(fromActions.imageUploadSuccess, _ => false)
)

const isNewDropLayerSavingReducer = createReducer(
  initialEditorState.isNewDropLayerSaving,
  on(fromActions.setIsNewDropLayerSaving, (_, { value }) => value)
)

const savingAsReducer = createReducer(
  initialEditorState.savingAs,
  on(fromActions.saveAsAnalysis, _ => true),
  on(fromActions.startAnalysis, _ => false),
  on(fromActions.saveAsAnalysisFailure, _ => false)
)

const savingAsErrorReducer = createReducer(
  initialEditorState.savingAsError,
  on(fromActions.saveAsAnalysis, _ => null),
  on(fromActions.startAnalysis, _ => null),
  on(fromActions.saveAsAnalysisFailure, (_, { error }) => error.message)
)

const savingAsCloneReducer = createReducer(
  initialEditorState.savingAsClone,
  on(fromActions.saveAsCloneAnalysis, _ => true),
  on(fromActions.saveAsCloneAnalysisSuccess, _ => false),
  on(fromActions.startAnalysis, _ => false),
  on(fromActions.saveAsCloneAnalysisFailure, _ => false)
)

const savingAsCloneErrorReducer = createReducer(
  initialEditorState.savingAsError,
  on(fromActions.saveAsCloneAnalysis, _ => null),
  on(fromActions.startAnalysis, _ => null),
  on(fromActions.saveAsCloneAnalysisFailure, (_, { error }) => error.message)
)

const clientIDReducer = createReducer(
  initialEditorState.clientID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { clientID }) => clientID
  )
)

const yearIDReducer = createReducer(
  initialEditorState.yearID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { yearID }) => yearID
  )
)

const studyIDReducer = createReducer(
  initialEditorState.studyID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { studyID }) => studyID
  )
)

const structureIDReducer = createReducer(
  initialEditorState.structureID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { structureID }) => structureID
  )
)

const portfolioIDReducer = createReducer(
  initialEditorState.cededPortfolioID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { cededPortfolioID }) => cededPortfolioID
  )
)

const analysisProfileIDReducer = createReducer(
  initialEditorState.analysisProfileID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { analysisProfileID }) => analysisProfileID
  )
)

const grossPortfolioIDReducer = createReducer(
  initialEditorState.analysisProfileID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { grossPortfolioID }) => grossPortfolioID
  )
)

const netPortfolioIDReducer = createReducer(
  initialEditorState.analysisProfileID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { netPortfolioID }) => netPortfolioID
  )
)

const parentGrossPortfolioIDReducer = createReducer(
  initialEditorState.analysisProfileID,
  on(
    fromActions.startAnalysis,
    fromActions.reinitialize,
    (_, { parentGrossPortfolioID }) => parentGrossPortfolioID
  )
)

const showAggregateReducer = createReducer(
  initialEditorState.showAggregate,
  on(fromActions.toggleAggregate, (_, { showAggregate }) => showAggregate)
)

const showOccurrenceReducer = createReducer(
  initialEditorState.showOccurrence,
  on(fromActions.toggleOccurrence, (_, { showOccurrence }) => showOccurrence)
)

const updateZoomReducer = createReducer(
  initialEditorState.zoomValue,
  on(fromActions.updateZoom, (_, { zoomValue }) => zoomValue)
)

const currentCurrencyReducer = createReducer(
  initialEditorState.currentCurrency,
  on(fromActions.currentCurrency, (_, { currentCurrency }) => currentCurrency)
)

const mapLossSetsReducer = createReducer(
  initialEditorState.mapLossSets,
  on(fromActions.mapLossSets, (_, { mapLossSets }) => mapLossSets)
)

const editorReducer = combineReducers<EditorState>({
  cededPortfolioID: portfolioIDReducer,
  analysisProfileID: analysisProfileIDReducer,
  clientID: clientIDReducer,
  cededLayers: fromLayers.reducer,
  cededPortfolio: fromCededPortfolio.reducer,
  netPortfolio: fromNetPortfolio.reducer,
  lossSetLayers: fromLossSetLayer.reducer,
  grossPortfolioID: grossPortfolioIDReducer,
  netPortfolioID: netPortfolioIDReducer,
  parentGrossPortfolioID: parentGrossPortfolioIDReducer,
  panels: fromPanels.reducer,
  savingAs: savingAsReducer,
  savingAsError: savingAsErrorReducer,
  savingAsClone: savingAsCloneReducer,
  isNewDropLayerSaving: isNewDropLayerSavingReducer,
  savingAsCloneError: savingAsCloneErrorReducer,
  studyID: studyIDReducer,
  structureID: structureIDReducer,
  uploadingImage: imageUploadReducer,
  showAggregate: showAggregateReducer,
  showOccurrence: showOccurrenceReducer,
  yearID: yearIDReducer,
  startingCession: startingCessionReducer,
  zoomValue: updateZoomReducer,
  layerDetailsTitleUpdate: fromLayerDetails.reducer,
  currentCurrency: currentCurrencyReducer,
  mapLossSets: mapLossSetsReducer,
  inurance: inuranceReducer,
  technicalPremium: fromTechnicalPremium.reducer,
})

const analysisReducer = combineReducers<State>({
  editor: editorReducer,
  compare: fromCompare.reducer,
  grouper: fromGrouper.reducer,
  portfolio: fromPortfolioSet.reducer,
  metric: fromMetric.reducer,
  explore: fromExplore.reducer,
})

// If changing clients, return undefined to clear analysis state
export function reducer(state: State | undefined, action: Action) {
  return analysisReducer(
    action.type === '[Broker] Set Current Client' ? undefined : state,
    action
  )
}
