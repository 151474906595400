import {
  Action,
  combineReducers,
  createReducer,
  on
} from "@ngrx/store"
import {
  CreditQuoteAssignedLineRow,
  CreditQuoteAuditTable,
  CreditQuoteOverview,
  CreditQuoteSubmissionOverview
} from "../../model/credit-quote.model"
import {
  CreditQuoteAction
} from "./credit-quote.actions"

export interface QuoteStore {
  audit: CreditQuoteAuditTable |  undefined | null
  overview: CreditQuoteOverview | undefined
  submission: CreditQuoteSubmissionOverview | undefined
  assignedLines: CreditQuoteAssignedLineRow[] | undefined
  reinsurers: Record<number, string> | undefined
}

export const initialState: QuoteStore = {
  audit: undefined,
  overview: undefined,
  submission: undefined,
  assignedLines: undefined,
  reinsurers: undefined
}

const auditReducer = createReducer(
  initialState.audit,
  on(
    CreditQuoteAction.fetchQuoteAuditSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchQuoteAuditFailure,
    _ => undefined
  ),
)

const overviewReducer = createReducer(
  initialState.overview,
  on(
    CreditQuoteAction.fetchQuoteOverviewSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchQuoteOverviewFailure,
    _ => undefined
  ),
)

const reinsurersReducer = createReducer(
  initialState.reinsurers,
  on(
    CreditQuoteAction.getReinsurersSuccess,
    (_, { data }) => data
  ),
  on(
    CreditQuoteAction.fetchQuoteForReinsurerFailure,
    _ => undefined
  ),

)

const submissionReducer = createReducer(
  initialState.submission,
  on(
    CreditQuoteAction.fetchQuoteForReinsurerSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchQuoteForReinsurerFailure,
    _ => undefined
  ),
)

const assignedLinesReducer = createReducer(
  initialState.assignedLines,
  on(
    CreditQuoteAction.fetchAssignedLinesSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchAssignedLinesFailure,
    _ => undefined
  ),
)

const quoteReducer = combineReducers<QuoteStore>({
  audit: auditReducer,
  overview: overviewReducer,
  submission: submissionReducer,
  assignedLines: assignedLinesReducer,
  reinsurers: reinsurersReducer
})

export function reducer(state: QuoteStore | undefined, action: Action) {
  return quoteReducer(state, action)
}
