import { ErrorLoggingService } from './error-logging.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule, ErrorHandler } from '@angular/core'
import { MatSidenavModule } from '@angular/material/sidenav'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HotkeyModule } from 'angular2-hotkeys'
import { ReinsurerModule } from './reinsurers/reinsurer.module'
import { ProgramInitiationModule } from './program-initiation/program-initiation.module'
import { ErrorModule } from './error/error.module'
import { EffectsModule } from '@ngrx/effects'
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { metaReducers, reducers } from './core/store'
import { environment } from '../environments/environment'
import { AppEffects } from './core/store/app.effects'
import { AuthEffects } from './core/store/auth/auth.effects'
import { ProgramEffects } from './core/store/program/program.effects'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthCallBackComponent } from './core/auth/auth-callback.component'
import { DebugContainerComponent } from './core/debug.container'
import { CustomSerializer } from './core/store/custom-serializer'
import { RoleGuard } from './guards/role-guard.service'
import { LoadingPopupComponent } from '@shared/loading-popup/loading-popup.component'
import { NavListContainerComponent } from './nav-list.container'
import { NavService } from './nav.service'
import { RetryInterceptor } from '@shared/interceptors/retry.interceptor'
import { SharedModule } from '@shared/shared.module'
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor'
import { BnNgIdleService } from 'bn-ng-idle'
import { TimeoutDialogComponent } from './timeout-dialog.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { SignatureSelectRiskRefDialogComponent } from './signature/content/signature-select-riskref-dialog.component'
import { LayerDeleteDialogComponent } from './analysis/layer-delete-dialog.component'
import { MatSelectModule } from '@angular/material/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatOptionModule } from '@angular/material/core'
import { MatPseudoCheckboxModule } from '@angular/material/core'
import { ContactSupportModule } from './contact-support/contact-support.module'
import { TrackingModule } from './tracking/tracking.module'
import { MaintenanceModule } from './maintenance/maintenance.module'

const CONDITIONAL_IMPORTS = []
if (!environment.production) {
  CONDITIONAL_IMPORTS.push(
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
      trace: true
    })
  )
} else {
  CONDITIONAL_IMPORTS.push(
    StoreModule.forRoot(reducers, {
      metaReducers,
    })
  )
}

@NgModule({
  declarations: [
    TimeoutDialogComponent,
    AppComponent,
    AuthCallBackComponent,
    DebugContainerComponent,
    NavListContainerComponent,
    SignatureSelectRiskRefDialogComponent,
    LoadingPopupComponent,
    LayerDeleteDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ContactSupportModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    ...CONDITIONAL_IMPORTS,
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    EffectsModule.forRoot([AppEffects, AuthEffects, ProgramEffects]),
    ErrorModule,
    MatSidenavModule,
    ReinsurerModule,
    ProgramInitiationModule,
    HotkeyModule.forRoot(),
    NgxMatSelectSearchModule,
    MatPseudoCheckboxModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    TrackingModule,
    MaintenanceModule
  ],
  providers: [
    RoleGuard,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorLoggingService },
    NavService,
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
