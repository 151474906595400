<div
  *ngIf="!status.isLoading && !status.isSavingLayers; else loadingTemplate"
  class="curve-details"
>
  <div class="swiper-container" cdkScrollable>
    <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      class="swiper-wrapper"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        *ngFor="let curve of pricingCurves; let i = index; trackBy: trackByID"
      >
        <div class="card-container swiper-slide" cdkDragLockAxis="x" cdkDrag>
          <app-pricing-curve-details-card
            [pricingCurve]="curve"
            [context]="context"
            [readonly]="readonly"
          ></app-pricing-curve-details-card>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="loading-spinner">
    <mat-spinner
      *ngIf="status.isLoading || status.isSavingLayers"
      [diameter]="24"
      color="accent"
    ></mat-spinner>
  </div>
</ng-template>
