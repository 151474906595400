import {
  CreditCalculationStructure,
  CreditStructure,
} from './credit-structure.model'

export interface CreditSubmissionStructure {
  id?: number
  credit_structure_id: number // maps to CreditStructure that submission was generated from
  credit_submission_structure_id: string // credit_calculation_structure_id of snapshot structure generated for submission
  program_id: number
  label: string
  description: string
  reinsurer_id: number
  transaction_id: string
  status: CreditStatus
  // below used to manage UI state / not saved to db
  checked?: boolean
  imageSrc?: string
  portfolio_id?: string
  year?: number
  audit_insert_dt?: string
  client_id?: number
  carrier_id?: number
  effective_date?: string
}

export enum CreditStatus {
  Sent = 'SENT',
  Quote = 'QUOTE',
  Fot = 'FOT',
}

export interface CreditSubmissionStructureWithProgramData {
  year: number
  portfolio_id: string
  carrier_id: number
  credit_submission_structure: CreditSubmissionStructure
  effective_date: string
}

export interface CreditSubmissionYears {
  year: string
  ids: string[]
}

export interface ClientSearch {
  clients: string[]
  yearIds: string[]
}

export interface SimplifiedClient {
  id: string
  name: string
  img: string
}

export interface SubmissionResults {
  initialStructures: CreditSubmissionStructure[][]
  imageIds: Object
}

export interface CreateSubmissionProps {
  creditStructure: CreditStructure
  calculationStructure: CreditCalculationStructure
  transactionId: string
  description: string
  reinsurerUserMap: Record<number, number[]> // reinsurer_id : user_id[]
}

export interface CreateSubmissionSuccessProps {
  submissionCount: number
  submissionUserTrancheCount: number
}

export interface SubmissionReinsurer {
  reinsurerId: number
  reinsurerName: string
}

export interface CreditSubmissionUserTranche {
  submission_id: number
  user_id: number
  tranche_id: string
  rol: number // N/A for QS
  reinsurer_participation_percent: number
  collateral_percent: number
  ceding_commission: number // N/A for XOL & ILN
  profit_commission: number // N/A for XOL & ILN
}

export interface CreditSubmissionUserTrancheInputs {
  transactionId: string
  trancheId: string // transactionId for QS
  defaultRol: number
  defaulReinsurerParticipationPercent: number
  defaultCollateralPercent: number
  defaultCedingComission: number
  defaultProfitComission: number
}

export interface CreditSubmissionAuthorizeQuoteDialogData {
  reinsurer_participation_percent: number
  rol: number
  tranche_id: string
  comment: string
}

export interface CreditSubmissionAuthorizationRequest {
  submission_id: number
  tranche_id: string
  rol: number
  reinsurer_participation_percent: number
  comments: string
}
