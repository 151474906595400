<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title> Section {{ section.letter }} </mat-card-title>
    <button
      mat-icon-button
      class="remove-button"
      [disabled]="sections.length <= 2"
      (click)="onRemoveSection()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <app-layer-property
      name="Narrative"
      type="text"
      [value]="section.narrative"
      (inputChange)="propChange('narrative', $event)"
    ></app-layer-property>
    <div class="check">
      <app-layer-property
        name="Occurrence Limit"
        [currentCurrency]="section.currency"
        type="currency"
        [value]="
              section.occUnlimited
                ? analyzereConstants.unlimitedValue
                : section.occLimit
            "
        (inputChange)="propChange('occLimit', $event)"
      ></app-layer-property>
      <mat-checkbox
        matTooltipPosition="above"
        [disabled]="mainLayerUnlimited"
        [checked]="section.occUnlimited"
        [matTooltip]="
              mainLayerUnlimited
                ? 'Cannot set a Occurrence Limit as unlimited if the main layer -> Contract Occurrence Limit is unlimited.'
                : 'Unlimited'
            "
        (change)="propChange('occUnlimited', $event.checked)"
      ></mat-checkbox>
    </div>
    <app-layer-property
      name="Occurrence Attachment"
      [currentCurrency]="section.currency"
      type="currency"
      [value]="section.occAttach"
      (inputChange)="propChange('occAttach', $event)"
    ></app-layer-property>
    <div class="check">
      <app-layer-property
        name="Aggregate Limit"
        [currentCurrency]="section.currency"
        type="currency"
        [value]="
              section.aggUnlimited
                ? analyzereConstants.unlimitedValue
                : section.aggLimit
            "
        (inputChange)="propChange('aggLimit', $event)"
      ></app-layer-property>
      <mat-checkbox
        matTooltip="Unlimited"
        matTooltipPosition="above"
        [checked]="section.aggUnlimited"
        (change)="propChange('aggUnlimited', $event.checked)"
      ></mat-checkbox>
    </div>
    <app-layer-property
      name="Aggregate Attachment"
      [currentCurrency]="section.currency"
      type="currency"
      [value]="section.aggAttach"
      (inputChange)="propChange('aggAttach', $event)"
    ></app-layer-property>
    <app-percentage-input
      name="Cession Percentage"
      decimal="2"
      isCession="true"
      isNegative="true"
      [value]="section.participation"
      (inputChange)="propChange('participation', $event)"
    ></app-percentage-input>
    <div class="currency-selector">
      <label>Currency</label>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          [formControl]="sectionCurrencyControl"
          [matAutocomplete]="sectionCurrencyCtrl"
        />
        <mat-autocomplete
          #sectionCurrencyCtrl="matAutocomplete"
          (optionSelected)="onCurrencyChange($event.option.value)"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let currency of filteredCurrencyList"
            [value]="currency"
          >
            {{ currency.code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div *ngIf="lossSetGroups.length > 0">
      <div class="chip-list-label">
        <mat-label>Loss Set Groups</mat-label>
      </div>
      <mat-chip-set aria-label="Loss Set Groups Selection">
        <mat-chip-row
          *ngFor="let group of lossSetGroups"
          [style]="group.style"
          (click)="selectChip(group.lossSetGroup)"
        >
          {{ group.lossSetGroup.name }}
        </mat-chip-row>
      </mat-chip-set>
    </div>
    <div class="selection-list-label" *ngIf="!isLibRE">
      <mat-label>Loss Sets
        <span *ngIf="isLibRE">isLibRE</span>
        <span *ngIf="!isLibRE">NOT libRE</span>
      </mat-label>
    </div>
    <div class="selection-list-controls" *ngIf="!isLibRE">
      <mat-checkbox
        #lossSetsAllNone
        disableRipple
        (change)="onLossSetsAllNone($event)"
      >All/None</mat-checkbox
      >
      <app-filter-input
        class="filter"
        transparent
        (debouncedChange)="onFilter($event.toLowerCase())"
      ></app-filter-input>
    </div>
    <mat-selection-list #lossSetsList disableRipple *ngIf="!isLibRE">
      <mat-list-option
        *ngFor="let lossSet of filteredLossSets$ | async; trackBy: trackLossSetBy"
        checkboxPosition="before"
        matTooltipPosition="above"
        matTooltipClass="loss-set-tooltip"
        [matTooltip]="lossSet.label"
        [value]="lossSet.id"
        [selected]="section.lossSets.includes(lossSet.id)"
        (click)="onLossSetClick(lossSet, $event)"
      >
        {{ lossSet.label }}
      </mat-list-option>
    </mat-selection-list>

    <div class="selection-list-label">
      <mat-label>Inuring Sections</mat-label>
    </div>
    <mat-selection-list
      #inuringSectionsList
      disableRipple
      (selectionChange)="onInuringSelection($event)"
    >
      <mat-list-option
        *ngFor="let s of inurableSections; trackBy: trackInuringBy"
        checkboxPosition="before"
        [value]="s.letter"
        [selected]="s.selected"
        [disabled]="s.disabled"
      >
        Section {{ s.letter }}
      </mat-list-option>
    </mat-selection-list>

    <div class="section-inurance-button">
      <button appButton (click)="onSectionInurance()">Inurance +</button>
    </div>
  </mat-card-content>
</mat-card>
