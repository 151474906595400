import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Inject } from '@angular/core'
import {
  LossSetLayer,
  LogicalPortfolioLayer,
} from '../../api/analyzere/analyzere.model'
import { colors } from '@shared/constants/presetColors'

export interface SwapLossSetsDialogConfig {
  oldLossSets: LogicalPortfolioLayer[] | LossSetLayer[]
  newLossSets: LogicalPortfolioLayer[] | LossSetLayer[]
}

export interface LogicalPortfolioLayerWithChecked
  extends LogicalPortfolioLayer {
  checked: boolean
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-swap-loss-sets-dialog',
  styleUrls: ['./swap-loss-sets-dialog.component.scss'],
  templateUrl: './swap-loss-sets-dialog.component.html',
})
export class SwapLossSetsDialogComponent implements OnInit {
  title = 'Swap Loss Sets'
  oldLossSets: LogicalPortfolioLayer[]
  newLossSets: LogicalPortfolioLayer[]
  lossSetMap = new Map<LogicalPortfolioLayer, LogicalPortfolioLayer[]>()
  styles: string[] = []
  currentDragItem: LogicalPortfolioLayer | undefined
  currentMappingCount = 0

  constructor(
    protected dialogRef: MatDialogRef<SwapLossSetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.oldLossSets = data[0].data.layers
    this.newLossSets = data[1].data.layers
  }

  ngOnInit(): void {
    this.oldLossSets.forEach((_, idx) => {
      this.styles.push(`border-color: ${colors[idx % colors.length]}`)
    })
  }

  getMappedLossSetsIndex(newLossSet: LogicalPortfolioLayer): number[] {
    const lossSets = this.lossSetMap.get(newLossSet)
    if (!lossSets) {
      return []
    }
    return lossSets.map(loss => {
      return this.oldLossSets.findIndex(old => old.id === loss.id)
    })
  }

  getMappedCount(): number {
    let count = 0
    this.lossSetMap.forEach(value => {
      count += value.length
    })
    return count
  }

  onSwapClick(): void {
    if (this.currentMappingCount === 0) {
      return
    }
    this.dialogRef.close(this.lossSetMap)
  }

  getLetter(value: number): string {
    value = value + 1
    return String.fromCharCode(Math.floor(value / 26) + 65)
  }

  concatCurrenLossSet(ls_dim1: string, ls_dim2: string): string {
    return ls_dim2 ? ls_dim1 + ls_dim2 : ls_dim1
  }

  mappingExists(
    newLossSet: LogicalPortfolioLayer,
    item: LogicalPortfolioLayer
  ): boolean {
    if (!this.lossSetMap.has(newLossSet)) {
      return false
    }
    const set = this.lossSetMap.get(newLossSet)
    if (set?.find(setItem => setItem.id === item.id)) {
      return true
    }
    return false
  }

  onDrop($event: DragEvent, newLossSet: LogicalPortfolioLayer): void {
    $event.preventDefault()
    if (!this.currentDragItem) {
      return
    }
    if (this.mappingExists(newLossSet, this.currentDragItem)) {
      return
    }
    if (this.lossSetMap.has(newLossSet)) {
      this.lossSetMap.set(newLossSet, [
        ...(this.lossSetMap.get(newLossSet) || []),
        this.currentDragItem,
      ])
    } else {
      this.lossSetMap.set(newLossSet, [this.currentDragItem])
    }
    this.currentDragItem = undefined
    this.currentMappingCount = this.getMappedCount()
  }

  onDragOver($event: DragEvent): void {
    $event.preventDefault()
  }

  onDragStart($event: DragEvent, oldLossSet: LogicalPortfolioLayer): void {
    $event.dataTransfer?.setData('text', oldLossSet.id.toString()) // Must fill in setData for drag to work properly
    this.currentDragItem = oldLossSet
  }

  removeMapping(lossSet: LogicalPortfolioLayer, index: number): void {
    const set = this.lossSetMap.get(lossSet)
    if (set) {
      set.splice(index, 1)
    }
    this.currentMappingCount = this.getMappedCount()
  }
}
