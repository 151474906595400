import { layerIds } from '../analysis/model/layer-palette.model'
import { AssignedLines } from './models/quote.model'
import { Bureaus } from 'src/app/api/model/quote.model'
import { ReinsurerState } from './store/reinsurer/reinsurer.reducer'

interface DefaultBrokerages {
  brokerageCommission: number
  reinstatementBrokerage: number
}

export const getDefaultBrokerage = (
  isUsaBasedUser: boolean,
  layerId?: string
): DefaultBrokerages => {
  if (isUsaBasedUser) {
    if (layerId === layerIds.catQs) {
      return {
        brokerageCommission: 0.015,
        reinstatementBrokerage: 0,
      }
    } else if (layerId === layerIds.noncatQs) {
      return {
        brokerageCommission: 0.025,
        reinstatementBrokerage: 0,
      }
    }
    return {
      brokerageCommission: 0.1,
      reinstatementBrokerage: 0.05,
    }
  } else {
    return {
      brokerageCommission: 0,
      reinstatementBrokerage: 0,
    }
  }
}

export function isLineSplitLine(line: AssignedLines, allLines: AssignedLines[]): boolean {
  return allLines.some( l => l !== line && l.whiteSpaceImpressionIdx === line.whiteSpaceImpressionIdx && l.whiteSpaceWSetID === line.whiteSpaceWSetID)
}


export function getAssignedLineBureausStamp(newAssignedLine: AssignedLines, bureausList: Bureaus[]): string {
  
  const matchingBureaus = bureausList.filter(
    b => b.tpRef === newAssignedLine.marketTpRef
  )

  let bureau_type = ''
  let bureaus = ''
  if (matchingBureaus.length === 1) {
    bureau_type = matchingBureaus[0].bureau_type
    bureaus = matchingBureaus[0].bureau_stamp
  }

  // only return the stamp for Lloyd's bureaus
  if (newAssignedLine.bureaus && bureau_type === 'LL') {
    return newAssignedLine.bureaus
  }
  
  return (bureaus && bureau_type === 'LL') ? bureaus : 'NA'
}


export function hasOffMarketLines(reinsurer: ReinsurerState): boolean {
  return !!(reinsurer.reinsurer.riskAssignedLinesLink &&
    reinsurer.reinsurer.riskAssignedLinesLink?.length > 0 &&
    reinsurer.reinsurer.riskAssignedLinesLink?.some(row => !row.marketTpRef))
}
