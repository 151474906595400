<div class="wrapper">
  <ng-container>
    <form
      *ngIf="dialogData"
      [formGroup]="form"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="title">
        <div class="title-left">
          <form class="curve-details-input" [formGroup]="labelForm">
            <div class="curve-label-input">
              <mat-form-field>
                <mat-label>Data Label</mat-label>
                <input
                  type="text"
                  class="labelInput"
                  matInput
                  formControlName="label"
                />
              </mat-form-field>
            </div>
            <mat-form-field *ngIf="curveType && !workingCurve.isManual">
              <mat-label>Select Curve Type</mat-label>
              <mat-select
                (selectionChange)="onDropdownChange()"
                disableOptionCentering
                formControlName="curveType"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let option of dropdownOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div class="title-right">
          <mat-checkbox
            class="save-curve"
            *ngIf="context === 'pricing-curve'"
            labelPosition="before"
            [checked]="saveCurve"
            (change)="updateCheckboxState($event)"
            >Save to DB</mat-checkbox
          >
          <div
            class="button-container"
            [matTooltip]="submitDisabledTooltip"
            [matTooltipDisabled]="isFormValid"
          >
            <button
              appButton
              big
              accent
              [disabled]="!isFormValid"
              (click)="onSubmitCurve()"
            >
              <span>{{ dataAdditionButtonText }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="tech-factors row">
        <div class="heading">
          <h3>Pricing Factors</h3>
          <h5 class="no-mtop formula-label">
            Aggregate Layers Technical Premium = Max([((Expected Ceded Loss/(Agg
            limit))^Expected Loss on Line Power)*Expected Loss on Line
            Multiplier+(Volatility Metric/(Agg limit)*Volatility Multiplier +
            (Fixed Cost %)], Min Rate On Line))*Agg limit
          </h5>
          <h5 class="no-mtop formula-label">
            Layers Except Quota Share Technical Premium = Max([(((Expected Ceded
            Loss/(Occ limit*(1+ RP Relativity)))^Expected Loss on Line
            Power)*Expected Loss on Line Multiplier+(Volatility Metric/(Occ
            limit*(1+ RP Relativity))*Volatility Multiplier + (Fixed Cost %)],
            Min Rate On Line))*Occ limit)
          </h5>
        </div>
        <div class="form-labels">
          <label for="expected_loss_multiplier"
            >Expected Loss on line Multiplier</label
          >
          <label for="expected_loss_power">Expected Loss on line Power</label>
          <label for="volatility_multiplier">Volatility Multiplier</label>
          <label>Volatility Metric</label>
          <label for="fixed_cost">Fixed Cost %</label>
          <label for="minimum_rate_on_line">Minimum Rate on Line</label>
          <label for="maximum_rate_on_line">Maximum Rate on Line</label>
        </div>
        <div class="form-row">
          <div class="field-input">
            <input
              type="text"
              #expected_loss_multiplier
              formControlName="expected_loss_multiplier"
              (blur)="updateWithDefaultIfEmpty('expected_loss_multiplier')"
              required
            />
          </div>
          <div class="field-input">
            <input
              type="text"
              #expected_loss_power
              formControlName="expected_loss_power"
              (blur)="updateWithDefaultIfEmpty('expected_loss_power')"
              required
            />
          </div>
          <div class="field-input">
            <input
              type="text"
              #volatility_multiplier
              formControlName="volatility_multiplier"
              (blur)="updateWithDefaultIfEmpty('volatility_multiplier')"
              required
            />
          </div>
          <div class="field-input dropdown">
            <button
              class="button-appetite"
              appButton
              [matMenuTriggerFor]="volatilityMetricMenu"
              #volatilityMenu="matMenuTrigger"
            >
              <div class="select-metric-style">
                {{ selectedVolatilityMetric }}
              </div>
              <mat-icon inline>arrow_drop_down</mat-icon>
            </button>
            <mat-menu
              #volatilityMetricMenu="matMenu"
              panelClass="app-menu-translucent"
              backdropClass="app-theme-dark"
            >
              <button
                mat-menu-item
                (click)="setVolatilityMetric('Ceded Standard Deviation')"
              >
                Ceded Standard Deviation
              </button>
              <button
                mat-menu-item
                (click)="setVolatilityMetric('Ceded Loss CV')"
              >
                Ceded Loss CV
              </button>
              <button
                mat-menu-item
                (click)="setVolatilityMetric('Probability of Attachment')"
              >
                Probability of Attachment
              </button>
              <button
                mat-menu-item
                (mouseenter)="getData($event)"
                [matMenuTriggerFor]="sliderMenu"
              >
                {{ cededTVar }}
              </button>
              <button
                mat-menu-item
                (mouseenter)="getData($event)"
                [matMenuTriggerFor]="sliderMenu"
              >
                {{ cededVar }}
              </button>
            </mat-menu>
            <mat-menu
              #sliderMenu="matMenu"
              yPosition="above"
              class="app-menu-no-max-width"
            >
              <app-return-period-selector
                matTooltip="Select a return period to pair with this metric."
                matTooltipPosition="above"
                (click)="$event.preventDefault(); $event.stopPropagation()"
                [presets]="[1, 5, 10, 25, 50, 100, 250, 1000]"
                (returnPeriodChange)="
                  onPeriodSelected($event, parentMenuItemValue)
                "
              ></app-return-period-selector>
            </mat-menu>
          </div>
          <div class="field-input">
            <input
              type="text"
              #fixed_cost
              formControlName="fixed_cost"
              (focus)="removePercentFromField('fixed_cost')"
              (blur)="addPercentToField('fixed_cost')"
              required
            />
          </div>
          <div
            class="field-input"
            [ngClass]="{ error: !minValid }"
            [matTooltip]="minTooltip"
            [matTooltipDisabled]="minValid"
          >
            <input
              type="text"
              [ngClass]="{ error: !minValid }"
              #minimum_rate_on_line
              formControlName="minimum_rate_on_line"
              (focus)="
                removePercentFromField('minimum_rate_on_line');
                enforceRateOnLineRestrictions()
              "
              (blur)="
                addPercentToField('minimum_rate_on_line');
                enforceRateOnLineRestrictions()
              "
              required
            />
          </div>
          <div
            class="field-input"
            [ngClass]="{ error: !maxValid }"
            [matTooltip]="maxTooltip"
            [matTooltipDisabled]="maxValid"
          >
            <input
              type="text"
              [ngClass]="{ error: !maxValid }"
              #maximum_rate_on_line
              formControlName="maximum_rate_on_line"
              (focus)="
                removePercentFromField('maximum_rate_on_line');
                enforceRateOnLineRestrictions()
              "
              (blur)="
                addPercentToField('maximum_rate_on_line');
                enforceRateOnLineRestrictions()
              "
              required
            />
          </div>
        </div>
      </div>
      <br />
      <div class="quota-share row">
        <div class="heading">
          <h3>Quota Share Pricing</h3>
          <h5 class="no-mtop formula-label">
            Technical Ceding Commission = 1 - Reinsurer Margin % - Expected
            Ceded Loss Ratio of layer
          </h5>
        </div>
        <div class="form-labels">
          <label for="reinsurer_margin_percentage">Reinsurer Margin %</label>
          <label for="max_ceding_commission_percentage"
            >Max Ceding Commission %</label
          >
        </div>
        <div class="form-row">
          <div class="field-input">
            <input
              type="text"
              #reinsurer_margin_percentage
              formControlName="reinsurer_margin_percentage"
              (focus)="removePercentFromField('reinsurer_margin_percentage')"
              (blur)="addPercentToField('reinsurer_margin_percentage')"
              required
            />
          </div>
          <div class="field-input">
            <input
              type="text"
              #max_ceding_commission_percentage
              formControlName="max_ceding_commission_percentage"
              (focus)="
                removePercentFromField('max_ceding_commission_percentage')
              "
              (blur)="addPercentToField('max_ceding_commission_percentage')"
              required
            />
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>
