<a
  (mouseenter)="checkDialog()"
  (mouseover)="hover = true"
  (mouseout)="hover = false"
>
  <div class="tier-bar-opportunity">
    <app-tier-bar-container
      readonly
      [contextPath]="contextPath"
      [isButton]="isButton"
      [hover]="hover"
    ></app-tier-bar-container>
    <mat-slide-toggle
      *ngIf="title === 'Design Structures'"
      class="autobuild"
      [checked]="autoBuildSelected"
      ><span>Bulk Clone</span></mat-slide-toggle
    >
    <app-program-opportunity-info
      [programs]="programs"
      [selectedProgramID]="selectedProgramID"
      [accountOpportunities]="accountOpportunities"
    ></app-program-opportunity-info>
  </div>
</a>
