import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'dayMonthToMonthDay',
})
export class DayMonthToMonthDay implements PipeTransform {
  /* Transforms from dd/mm/yyyy to mm/dd/yyyy */
  transform(date: string): string {
    const dateParts = date.split('/')
    return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
  }
}
