import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { map, Observable, take } from 'rxjs'
import { NavService } from './../../../nav.service'
import { AppState } from './../../../core/store/index'
import { Client, ClientYear } from './../../../core/model/client.model'
import { Study } from './../../../core/model/study.model'
import { TierPath } from '../../tier.model'
import {
  CREDIT_CONTEXT_PATHS,
  CreditModuleContext,
} from './../../../credit/model/credit-routes.model'
import { ProgramsYearsFilter } from '../credit-tier.model'
import { selectClients } from '../../../core/store/clients.selectors'
import {
  selectCurrentClientID,
  selectCurrentClientStudies,
  selectCurrentClientYears,
} from '../../../core/store/broker/broker.selectors'
import { selectGroupFilters } from './../../../credit/store/group/credit-group.selectors'
import { selectCreditModuleContext } from './../../../credit/store/credit.selectors'
import { clearCheckedPrograms } from './../../../core/store/broker/broker.actions'
import { CreditStructureGroupFilterActions } from './../../../credit/store/group/credit-group.actions'

@Component({
  selector: 'app-credit-tier-bar-multiselect-container',
  templateUrl: './credit-tier-bar-multiselect.container.html',
})
export class CreditTierBarMultiselectContainerComponent implements OnInit {
  clients$: Observable<readonly Client[]>
  years$: Observable<readonly ClientYear[]>
  programs$: Observable<readonly Study[]>
  selectedClientId$: Observable<string | null>
  filters$: Observable<ProgramsYearsFilter>

  @Output() tierChange = new EventEmitter()

  constructor(
    private nav: NavService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.clients$ = this.store.pipe(select(selectClients))
    this.years$ = this.store.pipe(
      select(selectCurrentClientYears),
      map(years => years.slice().reverse())
    )
    this.programs$ = this.store.pipe(select(selectCurrentClientStudies))
    this.selectedClientId$ = this.store.pipe(select(selectCurrentClientID))
    this.filters$ = this.store.pipe(select(selectGroupFilters))
  }

  onTierChange(tierPath: TierPath): void {
    this.store.dispatch(clearCheckedPrograms())
    this.store.dispatch(CreditStructureGroupFilterActions.reset())
    this.store
      .pipe(select(selectCreditModuleContext), take(1))
      .subscribe(context => {
        if (context === CreditModuleContext.Group) {
          this.nav.navigateWithTierPath(tierPath, ...CREDIT_CONTEXT_PATHS.Group)
        }
        if (context === CreditModuleContext.Compare) {
          this.nav.navigateWithTierPath(
            tierPath,
            ...CREDIT_CONTEXT_PATHS.Compare
          )
          this.tierChange.emit() // on client change, close dialog
        }
      })
  }

  onUpdateFilters(filters: ProgramsYearsFilter): void {
    this.store.dispatch(CreditStructureGroupFilterActions.update({ filters }))
  }
}
