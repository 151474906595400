<main>
  <div class="content">
    <div class="toolbar">
      <app-explore-gross-sidebar
      [selectedLossSetIDs]="selectedLossSetIDs"
      [allLossSetsSelected]="allLossSetsSelected"
      [lossSetGroups]="lossSetGroups"
      [lossSetLayers]="lossSetLayers"
      [mappingLabels]="mappingLabels"
      [summaryFilterOptions]="summaryFilterOptions"
      [showIndividualLossSets]="showIndividualLossSets"
      [modelingArray]="modelingArray"
      [summaryView]="summaryView"
      [summaryViews]="summaryViews"
      [selectedStudySummaryFilterView]="selectedStudySummaryFilterView"
      [isSavedViewDirty]="isSavedViewDirty"
      [summaryRP]="summaryRP"
      [chartState]="chartState"
      [summaryChartsView]="summaryChartsView"
      [chartState]="chartState"
      [summaryChartsView]="summaryChartsView"
      (onLossSetChange)="onLossSetChange()"
      (onSelectAllLossSets)="onSelectAllLossSets()"
      (onLossSetClick)="onLossSetClick($event.lossID, $event.$event)"
      (updateCombinedFilterState)="onUpdateCombinedFilterState($event)"
      (updateSummaryRP)="onUpdateSummaryRP($event)"
      (chartsGroupByChange)="onChartsGroupByChange($event)"
      (chartsGridIndexSelection)="
        onChartsGridIndexSelection($event)
      "
      (chartEntityLimitChange)="
        onChartEntityLimitChange($event)
      "
    >
      (chartsGroupByChange)="onChartsGroupByChange($event)"F
      (chartsGridIndexSelection)="
        onChartsGridIndexSelection($event)
      "
      (chartEntityLimitChange)="
        onChartEntityLimitChange($event)
      "
    >
    ></app-explore-gross-sidebar>
    </div>
    <div class="tabs">
      <mat-tab-group
        color="accent"
        class="app-metrics-tabs"
        (selectedTabChange)="onTabChange($event)"
        >
        <mat-tab>
          <ng-template mat-tab-label>
            <label class="tabs-label">Gross Table</label>
          </ng-template>
          <ng-template matTabContent>
            <div class="content-table">
              <label
                *ngIf="showEmptySelectedLabel"
                class="empty"
                appInfoText
                big
              >
                Please select a Loss Set / Group
              </label>
              <app-gross-loss-table
                *ngIf="showTable"
                [dataTable]="dataTable"
                [selectedModifiers]="selectedModifiers"
                [currentStructureCurrency]="currentStructureCurrency"
                [analysisProfileCurrency]="analysisProfileCurrency"
                [currencyRates]="currencyRates"
                (modifiersChange)="modifiersChange.emit($event)"
                (lossFilterChange)="onLossFilterClick($event)"
                (exportAsXlsx)="exportAsXlsx.emit($event)"
                [loading]="loading"
                [error]="error"
                [lossFilters]="lossFilters"
              >
              </app-gross-loss-table>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <label class="tabs-label">Gross Graph</label>
          </ng-template>
          <ng-template matTabContent>
            <div class="content-table">
              <label
                *ngIf="showEmptySelectedLabel"
                class="empty"
                appInfoText
                big
              >
                Please select a Loss Set / Group
              </label>
              <app-gross-loss-graph
                *ngIf="showTable"
                [dataTable]="dataTable"
                [aggregationMethod]="selectedModifiers.aggregationMethod"
                [loading]="loading"
                [error]="error"
              >
              </app-gross-loss-graph>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="!isProd">
          <ng-template mat-tab-label>
            <label class="tabs-label">Summary Table</label>
          </ng-template>
          <ng-template matTabContent>
            <div class="content-table">
              <ng-container *ngIf="lossSetLayers">
                <app-explore-gross-summary-table
                  [lossSetLayers]="lossSetLayers"
                  [mappingLabels]="mappingLabels"
                  [modelingArray]="modelingArray"
                  [mappingOptions]="mappingOptions"
                  [lossSetLayers]="lossSetLayers"
                  [lossSetGroups]="lossSetGroups"
                  [summaryFilterMap]="summaryFilterMap"
                  [summaryColumnFilterMap]="summaryColumnFilterMap"
                  [showIndividualLossSets]="showIndividualLossSets"
                  [summaryData]="summaryData"
                  [groupSummaryData]="groupSummaryData"
                  [currentStructureCurrency]="currentStructureCurrency"
                  [analysisProfileCurrency]="analysisProfileCurrency"
                  [groups]="groups"
                  [client]="client"
                  [study]="study"
                  [selectedStructure]="selectedStructure"
                  [summaryFilterOptions]="summaryFilterOptions"
                  [accountOpportunities]="accountOpportunities"
                  [summaryViews]="summaryViews"
                  [selectedStudySummaryFilterView]="selectedStudySummaryFilterView"
                  [isSavedViewDirty]="isSavedViewDirty"
                  [summaryLoading]="summaryLoading"
                  [summaryRP]="summaryRP"
                  (saveAsSummaryView)="onUpdateView($event)"
                  (deleteSummaryView)="onDeleteSummaryView($event)"
                >
                </app-explore-gross-summary-table>
              </ng-container>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="!isProd">
          <ng-template mat-tab-label>
            <label class="tabs-label">Summary Charts</label>
          </ng-template>
          <ng-template matTabContent>
            <label
              *ngIf="!showSummaryCharts"
              class="empty"
              appInfoText
              big
            >
              Please Select a Group By Option to See Chart Data
            </label>
            <ng-container *ngIf="showSummaryCharts"; else #noGroupSummary>
              <app-explore-gross-summary-charts
                [groupSummaryData]="groupSummaryData"
                [chartState]="chartState"
                [chartsLoading]="summaryLoading"
                [currentStructureCurrency]="currentStructureCurrency"
                [summaryRP]="summaryRP"
                (chartOptionUpdate)="chartOptionUpdate.emit($event)"
              >
              </app-explore-gross-summary-charts>
            </ng-container>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="header-options">
        <mat-slide-toggle
          *ngIf="selectedTab === 'Summary Charts'"
          class="light-chart-toggle"
          matTooltip="Set charts to have light background and dark wording"
          matTooltipPosition="below"
          [checked]="chartState.lightChartMode"
          (change)="updateLightChartMode.emit($event.checked)"
          >
          <span class="light-chart-toggle-label">Light Chart Mode</span>
        </mat-slide-toggle>
      </div>
      <div class="header-options">
        <mat-slide-toggle
          *ngIf="selectedTab === 'Summary Charts'"
          class="light-chart-toggle"
          matTooltip="Set charts to have light background and dark wording"
          matTooltipPosition="below"
          [checked]="chartState.lightChartMode"
          (change)="updateLightChartMode.emit($event.checked)"
          >
          <span class="light-chart-toggle-label">Light Chart Mode</span>
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</main>
