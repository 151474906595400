import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { Program } from '../../../core/model/program.model'
import { Layer } from '../../model/layers.model'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import {
  AnalysisPanelsCollapseEvent,
  AnalysisPanelsSection,
  SectionClass,
} from '../../store/analysis-panels.actions'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { LossSetGroupEntity } from '../../store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import { RiskLossSetLayerModel } from '../loss-set-layer-selector/loss-set-layer-selector.component'
import { StudyResponse } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-panel',
  styleUrls: [`./layer-panel.component.scss`],
  templateUrl: `./layer-panel.component.html`,
  animations: [
    trigger('collapse', [
      state(
        'expand',
        style({
          height: '*',
        })
      ),
      state(
        'collapse',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      transition('expand <=> collapse', animate('150ms ease-out')),
    ]),
  ],
})
export class LayerPanelComponent implements OnInit {
  @Input() open = true
  @Input() currentProgram?: Program
  @Input() scenarios: Program[]
  @Input() optimizations: Program[]
  @Input() libRE = false
  @Input() layerPasteTrigger: boolean
  @Input() showLossSetGroupEditor = false
  @Input() groupEditSelectedLossSets: LossSetLayer[]
  @Input() lossSetGroupEditMode: string
  @Input() programID: string
  @Input() lossSetGroups: LossSetGroupEntity[]
  @Input() lossSetGroupsMetrics: Record<string, PortfolioMetrics>[]
  @Input() lossSetSelectedGroup: string
  @Input() lossSetSelectionMode: string
  @Input() lossSetGroupsActiveAction: string
  @Input() currentCededLayer: LayerState | null
  @Input() allCededLayers: LayerState[]
  @Input() collapseBySection: Record<AnalysisPanelsSection, boolean>
  @Input() parentLossSets: LossSetLayer[]
  @Input() grossLossSets: LossSetLayer[]
  @Input() designDirty: boolean
  @Input() metrics: PortfolioMetrics
  @Input() studies: StudyResponse[]
  @Input() selectedProgramID: string

  @Output() scenarioOrOptimizationClick = new EventEmitter<Program>()

  @Output() grossSelectionModeClick = new EventEmitter<void>()
  @Output() cededSelectionModeClick = new EventEmitter<void>()
  @Output() lossSetSelection = new EventEmitter<Partial<Layer>>()
  @Output() grossLossSetSelection = new EventEmitter<LossSetLayer[]>()

  @Output() newLossSetGroupClick = new EventEmitter<void>()
  @Output() cancelLossSetGroupClick = new EventEmitter<void>()
  @Output() saveLossSetGroupClick = new EventEmitter<any>()
  @Output() updateLossSetGroupClick = new EventEmitter<LossSetGroup>()

  @Output() lossSetGroupEditorClick = new EventEmitter<boolean>()
  @Output() groupEditLossSetSelection = new EventEmitter<LossSetLayer[]>()
  @Output() lossSetGroupClick = new EventEmitter<any>()
  @Output() deleteLossSetGroupClick = new EventEmitter<LossSetGroup>()
  @Output() lossSetGroupSetDirty = new EventEmitter<string>()

  @Output() removeRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() addRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()

  @Output() collapseChange = new EventEmitter<AnalysisPanelsCollapseEvent>()

  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()

  @Output() openAddInuranceDialog = new EventEmitter<{
    structure: Program
    layer: LayerState
  }>()

  showScenarios: boolean
  showOptimizations: boolean
  lossSetLabel: string
  hiddenSections = new Set<AnalysisPanelsSection>(['lossSets', 'inurance'])

  ngOnInit(): void {
    this.showScenarios = this.scenarios.length > 1
    this.lossSetLabel = this.showLossSetGroupEditor
      ? 'Edit Loss Set Groups'
      : 'Loss Sets'
    this.showOptimizations = this.optimizations.length > 1
  }

  collapse(section: AnalysisPanelsSection): string {
    return this.collapseBySection[section] ? 'collapse' : 'expand'
  }

  sectionClass(section: AnalysisPanelsSection): SectionClass {
    const hide = this.hiddenSections.has(section)
    return {
      hidden: this.libRE && hide,
      collapse: this.collapseBySection[section],
    }
  }

  onLossSetHeaderClick($event: boolean): void {
    if (this.showLossSetGroupEditor) {
      this.lossSetGroupEditorClick.emit(false)
      this.grossSelectionModeClick.emit()
    } else {
      this.collapseChange.emit({ section: 'lossSets', collapse: $event })
    }
  }

  onGrossClick(): void {
    this.grossSelectionModeClick.emit()
    this.lossSetGroupClick.emit({ lossSetGroup: null, mode: '' })
  }

  onCededClick(): void {
    this.cededSelectionModeClick.emit()
    this.lossSetGroupClick.emit({ lossSetGroup: null, mode: '' })
  }

  getLossSetSelectionMode(): string {
    return this.lossSetSelectionMode === 'Gross' ||
      this.lossSetSelectionMode === 'Ceded'
      ? this.lossSetSelectionMode
      : 'Gross'
  }

  onOpenAddInuranceClick(): void {
    if (
      this.currentProgram &&
      this.currentCededLayer &&
      !this.currentCededLayer?.new &&
      !this.currentCededLayer?.dirty
    ) {
      this.openAddInuranceDialog.emit({
        structure: this.currentProgram,
        layer: this.currentCededLayer,
      })
    }
  }

  isLayerNew(): boolean {
    return (
      (this.currentCededLayer?.new || this.currentCededLayer?.dirty) ?? false
    )
  }

  getInuranceToolTip(): string {
    if (!this.currentCededLayer) {
      return 'Select a layer to add Inurance'
    } else if (this.isLayerNew()) {
      return 'Save layer before adding Inurance'
    } else {
      return 'Add Inurance'
    }
  }
}
