<!-- Enable tab bar once simulated-trial implemented -->
<nav mat-tab-nav-bar color="accent" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    (click)="towerTabSelected.emit()"
    routerLink="tower"
    routerLinkActive
    #towerRoute="routerLinkActive"
    [active]="towerRoute.isActive"
  >
    <span>Tower</span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE"
    routerLink="layer-details"
    routerLinkActive
    #layerDetailsRoute="routerLinkActive"
    [active]="layerDetailsRoute.isActive"
  >
    <span>Layer Details</span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE"
    routerLink="layer-modeling"
    routerLinkActive
    #layerModelingRoute="routerLinkActive"
    [active]="layerModelingRoute.isActive"
  >
    <span>Layer Modeling</span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE"
    routerLink="simulated-trial"
    routerLinkActive
    #simulatedTrialRoute="routerLinkActive"
    [active]="simulatedTrialRoute.isActive"
  >
    <span matTooltip="Gross vs. Ceded by simulated trial">
      <span>Simulated Trial</span>
    </span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE"
    routerLink="animated-scenarios"
    routerLinkActive
    #animatedScenearios="routerLinkActive"
    [active]="animatedScenearios.isActive"
  >
    <span matTooltip="Animated Scenarios">
      <span>Animated Scenarios</span>
    </span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE"
    routerLink="explore-gross"
    routerLinkActive
    #exploreGrossRoute="routerLinkActive"
    [active]="exploreGrossRoute.isActive"
  >
    <span>Explore Gross</span>
  </a>
  <a
    mat-tab-link
    *ngIf="!libRE && !hideTechPremiumTab"
    routerLink="technical-premium"
    routerLinkActive
    #technicalPremiumRoute="routerLinkActive"
    [active]="technicalPremiumRoute.isActive"
  >
    <span>Technical Premium</span>
  </a>
</nav>
<div *ngIf="showTowerControls" class="agg">
  <button
    matTooltipPosition="before"
    matTooltip="Click to customize your graph properties."
    appButtonIcon
    huge
    opaque
    (click)="showProperties.emit()"
  >
    settings
  </button>
  <button
    matTooltipPosition="before"
    matTooltip="Click to export PDF of displayed structure."
    appButtonIcon
    huge
    opaque
    (click)="downloadTower.emit()"
  >
    cloud_download
  </button>
  <button
    matTooltipPosition="before"
    matTooltip="Show/Hide Occurrence or Aggregate"
    appButtonIcon
    huge
    opaque
    [matMenuTriggerFor]="popoverMenu"
  >
    swap_horizontal_circle
  </button>
  <mat-menu
    #popoverMenu="matMenu"
    yPosition="below"
    class="app-menu-no-max-width"
  >
    <mat-checkbox
      class="mat-menu-item"
      [checked]="showOcc"
      [disabled]="!showAgg"
      (change)="onShowOccClick()"
    >Show Occurrence</mat-checkbox
    >
    <mat-checkbox
      class="mat-menu-item"
      [checked]="showAgg"
      [disabled]="!showOcc"
      (change)="onShowAggClick()"
    >Show Aggregate</mat-checkbox
    >
  </mat-menu>
  <div class="zoom-menu">
    <label class="zoom">Zoom</label>
    <mat-slider
      min="1"
      [max]="10"
      step="1"
      thumbLabel="true"
     #ngSlider><input title="Zoom Slider" matSliderThumb (change)="showZoomChange.emit({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
</div>
