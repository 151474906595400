<div class="spacer" [ngStyle]="spacerStyle">
  <div
    class="gross-check"
    matTooltip="Click to show/hide Gross Results"
    matTooltipPosition="below"
  >
    <mat-slide-toggle
      class="check"
      [checked]="grossSelected"
      (change)="onUpdateGrossSelection($event)"
    >Show Gross</mat-slide-toggle
    >
  </div>
</div>
<div class="categories" cdkScrollable [appWheelScrollSpeed]="45">
  <div class="category header">
    <label class="header">Weighted Average</label>
  </div>
  <app-compare-metric-category-label
    *ngFor="let c of metricCategories; trackBy: trackByID"
    class="category"
    [entitiesLength]="entitiesLength"
    [label]="c.category"
    [values]="getValues(c.metrics)"
    [collapsed]="!isExpanded(c.category)"
    [hasPortfolioTypes]="c.hasPortfolioTypes"
    [size]="size"
    [showMoreMenu]="true"
    [hiddenMetricRanks]="hiddenMetricRanks"
    [expandedChangeMetrics]="expandedChangeMetrics"
    [firstProgramLabel]="firstEntity.program.label"
    (collapseToggle)="collapseToggle.emit($event)"
    (ragToggle)="ragToggle.emit($event)"
    (changeToggle)="changeToggle.emit($event)"
  ></app-compare-metric-category-label>
</div>
