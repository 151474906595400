import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-field-radio',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: column;
             justify-content: center;
           }
     
           :host:not(:last-of-type) {
             margin-bottom: var(--stack);
           }
     
           input {
             background-color: var(--bg-3);
             outline: none;
             color: var(--body);
             caret-color: var(--accent);
             border: 2px solid var(--hint);
             border-radius: 5px;
             padding: var(--stack-small) var(--inset-small);
             transition: border-color 250ms;
             width: 100%;
             box-sizing: border-box;
             font-size: var(--font-size-big);
             font-weight: var(--font-header-weight);
           }
           input:hover {
             border-color: var(--border-1-lit);
           }
           input:focus,
           input:active {
             border-color: var(--primary);
           }
           .divContent {
             background-color: var(--bg-2);
             border-radius: 5px;
             border: 2px solid var(--border-1);
             display: flex;
             padding: var(--stack);
           }
     
           .value1 {
             margin-left: var(--stack);
           }
           .value2 {
             margin-left: var(--stack);
           }
     
           .divCb {
             height: 100%;
             display: table;
             margin-top: 10px;
           }
     
           /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
           mat-radio-button {
             display: table-cell;
             vertical-align: middle;
           }
     
           input::-webkit-outer-spin-button,
           input::-webkit-inner-spin-button {
             /* display: none; <- Crashes Chrome on hover */
             -webkit-appearance: none;
             margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
           }
     
           input[type='number'] {
             -moz-appearance: textfield; /* Firefox */
           }
     
           input[type='number']:disabled {
             color: var(--primary);
             border-style: none;
             background-color: var(--bg-2);
           }
     
           /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
           ::ng-deep .custom-frame .mat-checkbox-background,
           ::ng-deep .custom-frame .mat-checkbox-frame {
             border-radius: 70% !important;
           }
           /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
           ::ng-deep
             .divCb
             .mat-checkbox-disabled.mat-checkbox-checked
             .mat-checkbox-background,
           ::ng-deep
             .divCb
             .mat-checkbox-disabled.mat-checkbox-indeterminate
             .mat-checkbox-background {
             background-color: var(--accent) !important;
           }
         `,
  ],
  template: `
    <div class="divContent">
      <div class="divCb">
        <mat-checkbox
          class="custom-frame"
          [checked]="isChecked"
          [disabled]="checkboxDisabled"
          (change)="onCheckboxClick()"
        ></mat-checkbox>
      </div>
      <input
        class="value1"
        type="number"
        [(ngModel)]="value1"
        [disabled]="inputDisabled"
        (focusout)="input1Change.emit($event)"
      />
      <input
        *ngIf="show"
        class="value2"
        type="number"
        [(ngModel)]="value2"
        [disabled]="inputDisabled"
        (focusout)="input2Change.emit($event)"
      />
    </div>
  `,
})
export class FieldRadioComponent {
  @Input() value1: number
  @Input() value2: number
  @Input() isChecked: boolean
  @Input() inputDisabled: boolean
  @Input() checkboxDisabled: boolean
  @Input() show: boolean

  @Output() input1Change = new EventEmitter<number>()
  @Output() input2Change = new EventEmitter<number>()
  @Output() checkBoxChange = new EventEmitter<void>()

  onCheckboxClick() {
    if (!this.checkboxDisabled) {
      this.checkBoxChange.emit()
    }
  }
}
