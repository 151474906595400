import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core'
import { ScenarioEvent, ScenarioEventChange } from '../animated-scenarios.model'
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-scenario-event-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./event-table.component.scss'],
  templateUrl: './event-table.component.html',
})
export class ScenarioEventTableComponent {
  @Input()
  set events(val: ScenarioEvent[]) {
    this.dataSource.data = val
  }
  @Input() lossSetNames: string[]
  @Input() currentCurrency: string
  @Input() disableActions: boolean

  @Output()  eventChange = new EventEmitter<ScenarioEventChange>()
  @Output() delete = new EventEmitter<number>()

  dataSource = new MatTableDataSource<ScenarioEvent>()

  get displayColumns() {
    return ['id', ...this.lossSetNames, 'action']
  }

  toMillions = (val: number) => val / 1000000
  fromMillions = (val: number) => val * 1000000

  inputChange(id: number, lossSetName: string, value: number): void {
    const changes = { [lossSetName]: value } as Partial<ScenarioEvent>
    this.eventChange.emit({ id, changes })
  }

  onDelete(eventID: number): void {
    if (!this.disableActions) {
      this.delete.emit(eventID)
    }
  }

  trackByEvents(event: ScenarioEvent): number {
    return event.id
  }

  getValue(lossSetName: string, event: ScenarioEvent): number {
    return event[lossSetName]
  }
}
