import { CurrencyPipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { Program } from '../../../core/model/program.model'
import { State as AuthState } from '../../../core/store/auth/auth.state.facade'
import { State as ReinsurerState } from '../../../reinsurers/store/study-reinsurers.reducer'
import {
  StructureLayerDataResponse,
  BlobResponse,
  StudyResponse,
} from '../../../api/model/backend.model'
import { SharedIDPortfolio } from '../../model/portfolio-set.model'
import {
  CurrencyCode,
  LossFilter,
} from '../../../api/analyzere/analyzere.model'
import { FormControl } from '@angular/forms'
import { MatMenuTrigger } from '@angular/material/menu'
import {
  LAYER_PALETTE_VIEWS,
  LayerPaletteView,
} from '../../model/layer-palette.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-metrics',
  styleUrls: ['./portfolio-metrics.component.scss'],
  templateUrl: './portfolio-metrics.component.html',
})
export class PortfolioMetricsComponent implements OnInit, OnChanges {
  views: LayerPaletteView[] = LAYER_PALETTE_VIEWS
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  @Input() metrics: PortfolioMetrics
  @Input() rss: number | null
  @Input() layers: LayerState[]
  @Input() currentProgram: Program | undefined
  @Input() authState: AuthState
  @Input() reinsurers: ReinsurerState
  @Input() layerData: StructureLayerDataResponse[]
  @Input() marketBlob: BlobResponse
  @Input() sharedIDPortfolio: SharedIDPortfolio[]
  @Input() selectedClientID: string | null
  @Input() selectedYearID: string | null
  @Input() lossFilters: LossFilter[]
  @Input() layerViewIDs: Record<string, string>
  @Input() grossPortfolioViewID: string | null
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  @Input() currentCurrency: string
  @Input() studies: StudyResponse[] | null
  @Input() selectedProgramID: string

  @Output() structureCurrency = new EventEmitter<string>()
  @Output() structureDirty = new EventEmitter<boolean>(false)
  @Output() toggleClick = new EventEmitter<void>()
  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()
  @Output() returnPeriod4Change = new EventEmitter<number>()
  @Output() returnPeriod5Change = new EventEmitter<number>()
  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()
  @Output() lossFilterClick = new EventEmitter<string>()
  @Output() showDetails = new EventEmitter<void>()
  @Output() showProperties = new EventEmitter<void>()
  @Output() showExchange = new EventEmitter<void>()
  @Output() submitMarketClick = new EventEmitter<any>()
  @Output() saveClick = new EventEmitter()
  @Output() updateLayerCurrencyAndSaveClick = new EventEmitter<string>()

  @Input() set open(value: any) {
    this._open = coerceBooleanProperty(value)
  }
  get open() {
    return this._open
  }
  @HostBinding('class.open') _open = true

  currencySelect: string[] = [
    'Change structure currency',
    'Change structure and all layers currency',
  ]

  currencyControl = new FormControl()
  layerViewId = '1'
  currencySymbol: string | null
  currencyOption: string = this.currencySelect[0]

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit() {
    const selectedStudyView = this.studies?.find(
      s => s.id.toString() === this.selectedProgramID
    )?.sage_view
    const selectedViewId = this.views.find(
      v => v.name === selectedStudyView
    )?.id
    this.layerViewId = selectedViewId ? selectedViewId : '1'

    // tslint:disable-next-line:no-non-null-assertion
    this.currencySymbol = this.currencyPipe
      .transform(
        0,
        this.layers[0]?.layer.physicalLayer.franchise.currency,
        'symbol',
        '1.0-0'
      )!
      .replace('0', '')
    this.currencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.currentCurrency &&
      changes.currentCurrency.firstChange
    ) {
      this.currencyControl.setValue({
        code: this.currentCurrency,
      })
    }
  }

  get returnPeriodData() {
    return this.metrics.returnPeriodData
  }

  get portfolioType() {
    return this.metrics.portfolioType
  }

  get aggregationMethod() {
    return this.metrics.aggregationMethod
  }

  get returnPeriod1(): number {
    return this.metrics.returnPeriod1
  }

  get returnPeriod2(): number {
    return this.metrics.returnPeriod2
  }

  get returnPeriod3(): number {
    return this.metrics.returnPeriod3
  }

  get returnPeriod4(): number {
    return this.metrics.returnPeriod4
  }

  get returnPeriod5(): number {
    return this.metrics.returnPeriod5
  }

  get perspective(): Perspective {
    return this.metrics.perspective
  }

  get isRssNull() {
    return this.rss === null
  }

  get showRss() {
    return this.open && !this.isRssNull
  }

  get openIcon() {
    return this.open ? 'expand_less' : 'expand_more'
  }

  hasMarketPermission() {
    let hasPermissions = false
    if (this.authState.security) {
      this.authState.security.forEach(securityPermission => {
        if (securityPermission === 'Submit to Market') {
          hasPermissions = true
        }
      })
    }
    return hasPermissions
  }

  submitMarket(): void {
    if (this.hasMarketPermission()) {
      this.submitMarketClick.emit({
        layers: this.layers,
        program: this.currentProgram,
        auth: this.authState,
        reinsurers: this.reinsurers,
        startingCession: this.layerData,
        marketBlob: this.marketBlob,
        sharedIDPortfolio: this.sharedIDPortfolio,
        selectedClientID: this.selectedClientID,
        selectedYearID: this.selectedYearID,
        layerViewIDs: this.layerViewIDs,
        grossPortfolioViewID: this.grossPortfolioViewID,
      })
    }
  }

  filterCurrencyValues(searchVal: CurrencyCode): CurrencyCode[] {
    if (searchVal.code) {
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      const currencyCode = searchVal as unknown as string
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(currencyCode.toLowerCase()) === 0
      )
    }
  }

  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }

  onCurrencyOptionChange(val: string): void {
    this.currencyOption = val
  }

  onCurrencyOptionUpdate(val: string): void {
    this.structureCurrency.emit(val)
    this.currentCurrency = val
  }

  onUpdateCurrencyClick(): void {
    this.structureDirty.emit(true)
    if (this.currencyOption === this.currencySelect[1]) {
      this.updateLayerCurrencyAndSaveClick.emit(this.currentCurrency)
    } else {
      this.saveClick.emit()
    }
    this.trigger.closeMenu()
  }
}
