import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { clone } from 'ramda'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import { Client } from '../../core/model/client.model'
import { BrokerData, brokerOptions } from '../../quote/models/quote.model'
import {
  Reinsurer,
  ReinsurerFilter,
  DOMICILE_COL,
  SP_RATING_COL,
  AM_BEST_RATING_COL,
  APPETITE_COL,
  SURPLUS_COL,
  LOW_SURPLUS_VALUE,
  HIGH_SURPLUS_VALUE,
  APPETITE_COLOR,
  LOW_SURPLUS_LABEL,
  MED_SURPLUS_LABEL,
  HIGH_SURPLUS_LABEL,
  FUND_MANAGER_MARKET_USE,
  AGENCY_MARKET_USE,
  ProgramFactor,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { rejectNil } from '@shared/util/operators'
import { CompareMetricTableCategory } from '../../analysis/model/compare-metrics.model'

interface DropListData {
  index: number
  bucket: 'selection' | 'incumbent' | 'marketTarget'
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-list',
  styleUrls: ['./reinsurer-list.component.scss'],
  templateUrl: './reinsurer-list.component.html',
})
export class ReinsurerListComponent implements OnChanges {
  @Input() reinsurers: Reinsurer[] | null

  @Input() studies: readonly Study[]
  @Input() studyID: string | null

  duplicateReinsurers: string[]
  allReinsurers: Reinsurer[] | null
  brokerList: BrokerData[] = brokerOptions
  reinsurersEdit: Reinsurer[] | null
  reinsurersIncumbent: Reinsurer[] | null
  reinsurersTargetMarket: Reinsurer[] | null
  reinsurerUpdate: Reinsurer

  isFilter = false
  filterValue = ''
  filterReinsurersEdit: Reinsurer[] | null
  filterReinsurersIncumbent: Reinsurer[] | null
  filterReinsurersTargetMarket: Reinsurer[] | null

  @Input() clientID: string | null
  @Input() yearID: string | null
  @Input() reinsurersBlob: BlobResponse | null
  @Input() reinsurerFilters: ReinsurerFilter[]
  @Input() reinsurerDomicileList: string[]
  @Input() reinsurerSPRatingList: string[]
  @Input() reinsurerAmBestRatingList: string[]
  @Input() clients: Client[]
  @Input() programs: Study[]
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() reinsurerPopulateFrom: Reinsurer[] | null
  @Input() metricCategories: CompareMetricTableCategory[]
  @Input() metricDetails: CompareMetricTableCategory[]

  @Output() clientClick = new EventEmitter<string>()
  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurers: Reinsurer[]
  }>()

  @Output() showInfo = new EventEmitter<Reinsurer>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()

  @Output() reinsurerFilterToggle = new EventEmitter<ReinsurerFilter>()
  @Output() removeAllFilters = new EventEmitter()
  @Output() removeFilter = new EventEmitter<ReinsurerFilter>()
  @Output() populateSelectorClick = new EventEmitter<{
    client: string
    program: string
  }>()
  @Output() setOneReinsurer = new EventEmitter<{
    programID: string
    reinsurer: Reinsurer
  }>()
  @Output() showAgencyModal = new EventEmitter<{
    re: Reinsurer
    type: string
  }>()
  @Output() showFundManagerModal = new EventEmitter<{
    reinsurer: Reinsurer
    programID: string
    select: string
  }>()
  @Output() deleteRe = new EventEmitter<Reinsurer>()
  readonly appetiteList = APPETITE_COLOR
  readonly DOMICILE_COL = DOMICILE_COL
  readonly SP_RATING_COL = SP_RATING_COL
  readonly AM_BEST_RATING_COL = AM_BEST_RATING_COL
  readonly APPETITE_COL = APPETITE_COL
  readonly SURPLUS_COL = SURPLUS_COL
  readonly surplusList = [
    { value: 'low', label: LOW_SURPLUS_LABEL },
    { value: 'medium', label: MED_SURPLUS_LABEL },
    { value: 'high', label: HIGH_SURPLUS_LABEL },
  ]

  get hasClient(): boolean {
    return this.clientID != null
  }

  get hasStudies(): boolean {
    return this.studies && this.studies.length > 0
  }

  get hasFilters(): boolean {
    return this.reinsurerFilters.length > 0
  }

  get showTable(): boolean {
    return (
      this.hasClient &&
      this.hasStudies &&
      this.hasPrograms &&
      this.isStudyOnList
    )
  }

  get showEmptySelectedLabel(): boolean {
    return this.hasClient && this.hasStudies && !this.hasPrograms
  }

  get hasPrograms(): boolean {
    return this.studyID !== '' && this.studyID !== null
  }

  get isStudyOnList(): boolean {
    return this.studies.find(s => s.id === this.studyID) != null
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.studyID) {
      this.reinsurersEdit = []
      this.reinsurersIncumbent = []
      this.reinsurersTargetMarket = []
      this.isFilter = false
      this.filterReinsurersEdit = []
      this.filterReinsurersIncumbent = []
      this.filterReinsurersTargetMarket = []
    }
    if (changes.reinsurers) {
      this.allReinsurers = clone(this.reinsurers)
      if (this.allReinsurers) {
        if (this.reinsurersEdit && this.reinsurersEdit.length > 0) {
          this.reinsurersEdit = this.reinsurersEdit.map(r => {
            return this.allReinsurers?.find(
              a =>
                a.id === r.id &&
                !a.reinsurerProgramFactor[0].incumbent &&
                !a.reinsurerProgramFactor[0].target_market &&
                (a.reinsurerProgramFactor[0].obo_name ===
                  r.reinsurerProgramFactor[0].obo_name ||
                  a.reinsurerProgramFactor[0].id ===
                    r.reinsurerProgramFactor[0].id)
            ) as Reinsurer
          })

          this.reinsurersEdit = rejectNil(this.reinsurersEdit)
          const missing = this.allReinsurers.filter(
            r =>
              !r.reinsurerProgramFactor[0].incumbent &&
              !r.reinsurerProgramFactor[0].target_market &&
              !this.reinsurersEdit
                ?.map(a => a.id + (a.reinsurerProgramFactor[0].id || 0))
                .includes(r.id + (r.reinsurerProgramFactor[0].id || 0))
          )
          this.reinsurersEdit.push(...missing)
        } else {
          this.reinsurersEdit = this.allReinsurers.filter(
            r =>
              !r.reinsurerProgramFactor[0].incumbent &&
              !r.reinsurerProgramFactor[0].target_market
          )
        }
        if (this.reinsurersIncumbent && this.reinsurersIncumbent.length > 0) {
          this.reinsurersIncumbent = this.reinsurersIncumbent.map(r => {
            return this.allReinsurers?.find(
              a =>
                ((a.reinsurerProgramFactor[0].incumbent &&
                  !a.reinsurerProgramFactor[0].target_market) ||
                  (a.reinsurerProgramFactor[0].is_default &&
                    a.reinsurerProgramFactor[0].incumbent)) &&
                a.id === r.id &&
                (a.reinsurerProgramFactor[0].obo_name ===
                  r.reinsurerProgramFactor[0].obo_name ||
                  a.reinsurerProgramFactor[0].id ===
                    r.reinsurerProgramFactor[0].id)
            ) as Reinsurer
          })
          this.reinsurersIncumbent = rejectNil(this.reinsurersIncumbent)
          const missing = this.allReinsurers.filter(
            r =>
              ((r.reinsurerProgramFactor[0].incumbent &&
                !r.reinsurerProgramFactor[0].target_market) ||
                (r.reinsurerProgramFactor[0].is_default &&
                  r.reinsurerProgramFactor[0].incumbent)) &&
              !this.reinsurersIncumbent
                ?.map(a => a.id + (a.reinsurerProgramFactor[0].id || 0))
                .includes(r.id + (r.reinsurerProgramFactor[0].id || 0))
          )
          this.reinsurersIncumbent.push(...missing)
        } else {
          this.reinsurersIncumbent = this.allReinsurers.filter(
            r =>
              (r.reinsurerProgramFactor[0].incumbent &&
                !r.reinsurerProgramFactor[0].target_market) ||
              (r.reinsurerProgramFactor[0].is_default &&
                r.reinsurerProgramFactor[0].incumbent)
          )
        }
        if (
          this.reinsurersTargetMarket &&
          this.reinsurersTargetMarket.length > 0
        ) {
          this.reinsurersTargetMarket = this.reinsurersTargetMarket.map(r => {
            return this.allReinsurers?.find(
              a =>
                a.id === r.id &&
                a.reinsurerProgramFactor[0].target_market &&
                !a.reinsurerProgramFactor[0].incumbent &&
                (a.reinsurerProgramFactor[0].obo_name ===
                  r.reinsurerProgramFactor[0].obo_name ||
                  a.reinsurerProgramFactor[0].id ===
                    r.reinsurerProgramFactor[0].id)
            ) as Reinsurer
          })
          this.reinsurersTargetMarket = rejectNil(this.reinsurersTargetMarket)
          const missing = this.allReinsurers.filter(
            r =>
              r.reinsurerProgramFactor[0].target_market &&
              !r.reinsurerProgramFactor[0].incumbent &&
              !this.reinsurersTargetMarket
                ?.map(a => a.id + (a.reinsurerProgramFactor[0].id || 0))
                .includes(r.id + (r.reinsurerProgramFactor[0].id || 0))
          )
          this.reinsurersTargetMarket.push(...missing)
        } else {
          this.reinsurersTargetMarket = this.allReinsurers.filter(
            r =>
              r.reinsurerProgramFactor[0].target_market &&
              !r.reinsurerProgramFactor[0].incumbent
          )
        }
        this.detectDuplicateReinsurers()
      }
      if (this.isFilter && this.filterValue) {
        this.createFilter(this.filterValue)
      } else {
        this.filterReinsurersEdit = this.reinsurersEdit
        this.filterReinsurersIncumbent = this.reinsurersIncumbent
        this.filterReinsurersTargetMarket = this.reinsurersTargetMarket
      }
      this.sortReinsurers()
    }
    if (
      changes.reinsurerPopulateFrom &&
      this.reinsurerPopulateFrom &&
      this.reinsurerPopulateFrom.length > 0
    ) {
      this.updateReinsurersPopulateFrom()
    }
  }

  getCoBrokerDetails(reinsurer: Reinsurer): BrokerData {
    const selectedProgram = this.programs.find(p => p.id === this.studyID)

    let bData: BrokerData = {
      name: '',
      ref: '',
    }
    if (selectedProgram){
      let opportunityData: AccountOpportunity[] | null = []
      opportunityData = this.accountOpportunities.filter(
        x =>
          JSON.parse(JSON.stringify(x.oppId)) === selectedProgram.opportunity_id
      )

      if (typeof opportunityData !== 'undefined' && opportunityData.length > 0) {
        if (
          opportunityData[0].parentBU === 'US' &&
          reinsurer.domicile === 'United Kingdom'
        ) {
          bData = this.brokerList.find(b => b.name === 'Lockton Re LLP') || {
            name: '',
            ref: '',
          }
        }
      }
    }

    return bData
  }

  updateReinsurerforAgencyModal(reinsurer: Reinsurer): Reinsurer {
    const brokerData = this.getCoBrokerDetails(reinsurer)
    let correspondentBroker = ''
    let coBrokerRef = ''
    if (brokerData) {
      correspondentBroker = brokerData.name
      coBrokerRef = brokerData.ref
    }
    let newReinsurer = reinsurer
    const factor = clone(newReinsurer.reinsurerProgramFactor)
    factor[0].co_broker = correspondentBroker
    factor[0].co_broker_ref = coBrokerRef
    newReinsurer = {
      ...newReinsurer,
      reinsurerProgramFactor: factor,
    }
    return newReinsurer
  }

  onChangeCheckbox(reinsurer: Reinsurer, type: 'inc' | 'tm' | 'sel'): void {
    let correspondentBroker = ''
    let coBrokerRef = ''
    if (
      !reinsurer.reinsurerProgramFactor[0].co_broker?.replace(/\s/g, '') &&
      !reinsurer.reinsurerProgramFactor[0].co_broker_ref?.replace(/\s/g, '')
    ) {
      const brokerData = this.getCoBrokerDetails(reinsurer)
      if (brokerData) {
        correspondentBroker = brokerData.name
        coBrokerRef = brokerData.ref
      }
    }
    if (this.allReinsurers) {
      const newReinsurer = clone(reinsurer)
      if (correspondentBroker && coBrokerRef) {
        newReinsurer.reinsurerProgramFactor[0].co_broker = correspondentBroker
        newReinsurer.reinsurerProgramFactor[0].co_broker_ref = coBrokerRef
      }
      if (type === 'inc') {
        newReinsurer.reinsurerProgramFactor[0].incumbent = true
        newReinsurer.reinsurerProgramFactor[0].target_market = false
      } else if (type === 'tm') {
        newReinsurer.reinsurerProgramFactor[0].incumbent = false
        newReinsurer.reinsurerProgramFactor[0].target_market = true
      } else {
        newReinsurer.reinsurerProgramFactor[0].incumbent = false
        newReinsurer.reinsurerProgramFactor[0].target_market = false
        newReinsurer.reinsurerProgramFactor[0].is_new_relation = false
        newReinsurer.reinsurerProgramFactor[0].relation_seq_number = 0
      }
      this.updateOrAddDirty.emit(newReinsurer)
      const updatedAllRe = clone(this.allReinsurers)
      const index = this.allReinsurers.findIndex(
        i =>
          i.id === newReinsurer.id &&
          i.reinsurerProgramFactor[0].id ===
            newReinsurer.reinsurerProgramFactor[0].id
      )
      updatedAllRe[index] = newReinsurer
      this.setReinsurer.emit({
        // tslint:disable-next-line: no-non-null-assertion
        programID: this.studyID!,
        reinsurers: updatedAllRe,
      })
    }
  }

  displayFilter(rf: ReinsurerFilter): string {
    let filterColumn
    let filterValue = rf.value

    switch (rf.column) {
      case DOMICILE_COL: {
        filterColumn = `Domicile`
        break
      }
      case SP_RATING_COL: {
        filterColumn = `S&P Rating`
        break
      }
      case AM_BEST_RATING_COL: {
        filterColumn = `AM Best Rating`
        break
      }
      case APPETITE_COL: {
        filterColumn = `Appetite`
        break
      }
      case SURPLUS_COL: {
        const surplusObject = this.surplusList.find(so => so.value === rf.value)
        if (surplusObject) {
          filterValue = surplusObject.label
        }
        filterColumn = `Surplus`
        break
      }
      default: {
        return `${rf.column}: ${rf.value}`
      }
    }
    return `${filterColumn}: ${filterValue}`
  }

  isFilterValueSelected(col: string, val: string): boolean {
    return this.reinsurerFilters.some(
      rf => rf.column === col && rf.value === val
    )
  }

  onRemoveAllFiltersClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    this.removeAllFilters.emit()
  }

  onRemoveFilter($event: MouseEvent | TouchEvent, rf: ReinsurerFilter): void {
    $event.preventDefault()
    this.removeFilter.emit(rf)
  }

  onFilterValueClick(
    $event: MouseEvent | TouchEvent,
    col: string,
    val: string
  ): void {
    $event.stopPropagation()
    $event.preventDefault()
    this.reinsurerFilterToggle.emit({ column: col, value: val })
  }

  showReinsurer(reinsurer: Reinsurer): boolean {
    if (this.reinsurerFilters.length === 0) {
      return true
    }

    const filterOnDomicile =
      !this.reinsurerFilters.some(rf => rf.column === DOMICILE_COL) ||
      this.reinsurerFilters.some(
        rf => rf.column === DOMICILE_COL && rf.value === reinsurer.domicile
      )

    const filterOnSPRating =
      !this.reinsurerFilters.some(rf => rf.column === SP_RATING_COL) ||
      this.reinsurerFilters.some(
        rf => rf.column === SP_RATING_COL && rf.value === reinsurer.sp_rating
      )

    const filterOnAMRating =
      !this.reinsurerFilters.some(rf => rf.column === AM_BEST_RATING_COL) ||
      this.reinsurerFilters.some(
        rf =>
          rf.column === AM_BEST_RATING_COL &&
          rf.value === reinsurer.am_best_rating
      )

    const filterOnAppetite =
      !this.reinsurerFilters.some(rf => rf.column === APPETITE_COL) ||
      this.reinsurerFilters.some(
        rf =>
          rf.column === APPETITE_COL &&
          rf.value === reinsurer.reinsurerProgramFactor[0].appetite
      )

    const filterOnSurplus =
      !this.reinsurerFilters.some(rf => rf.column === SURPLUS_COL) ||
      this.reinsurerFilters.some(
        rf =>
          rf.column === SURPLUS_COL &&
          ((rf.value === 'low' &&
            ((reinsurer.policy_holder === 0
              ? reinsurer.totalEquity
              : reinsurer.policy_holder) || 0) < LOW_SURPLUS_VALUE) ||
            (rf.value === 'medium' &&
              ((reinsurer.policy_holder === 0
                ? reinsurer.totalEquity
                : reinsurer.policy_holder) || 0) >= LOW_SURPLUS_VALUE &&
              ((reinsurer.policy_holder === 0
                ? reinsurer.totalEquity
                : reinsurer.policy_holder) || 0) <= HIGH_SURPLUS_VALUE) ||
            (rf.value === 'high' &&
              ((reinsurer.policy_holder === 0
                ? reinsurer.totalEquity
                : reinsurer.policy_holder) || 0) > HIGH_SURPLUS_VALUE))
      )

    if (
      filterOnDomicile &&
      filterOnSPRating &&
      filterOnAMRating &&
      filterOnAppetite &&
      filterOnSurplus
    ) {
      return true
    } else {
      return false
    }
  }

  drop(event: CdkDragDrop<DropListData>): void {
    if (
      event.container.data.bucket === 'selection' &&
      event.item.dropContainer.data.bucket === 'selection'
    ) {
      moveItemInArray(
        this.filterReinsurersEdit || [],
        event.previousContainer.data.index,
        event.container.data.index
      )
      this.sortReinsurers()
    } else if (event.container === event.previousContainer) {
      moveItemInArray(
        event.container.data.bucket === 'incumbent'
          ? this.filterReinsurersIncumbent || []
          : this.filterReinsurersTargetMarket || [],
        event.previousIndex,
        event.currentIndex
      )
      this.sortReinsurers()
    }
    // Move tile from Selection to Incumbent
    else if (
      event.container.data.bucket === 'incumbent' &&
      event.item.dropContainer.data.bucket === 'selection'
    ) {
      if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersEdit![event.item.dropContainer.data.index]
          .market_use === AGENCY_MARKET_USE
      ) {
        // Open dialog if Agency
        if (
          // tslint:disable-next-line: no-non-null-assertion
          !this.filterReinsurersEdit![
            event.item.dropContainer.data.index
          ].reinsurerProgramFactor[0].co_broker?.replace(/\s/g, '') &&
          // tslint:disable-next-line: no-non-null-assertion
          !this.filterReinsurersEdit![
            event.item.dropContainer.data.index
          ].reinsurerProgramFactor[0].co_broker_ref?.replace(/\s/g, '')
        ) {
          this.reinsurerUpdate = this.updateReinsurerforAgencyModal(
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index]
          )
        } else {
          this.reinsurerUpdate =
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index]
        }
        this.showAgencyModal.emit({
          // tslint:disable-next-line: no-non-null-assertion
          re: this.reinsurerUpdate,
          type: 'inc',
        })
      } else if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersEdit![event.item.dropContainer.data.index]
          .market_use === FUND_MANAGER_MARKET_USE
      ) {
        // Open dialog if Fund Manager
        this.showFundManagerModal.emit({
          reinsurer:
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index],
          // tslint:disable-next-line: no-non-null-assertion
          programID: this.studyID!,
          select: 'inc',
        })
      } else {
        this.onChangeCheckbox(
          // tslint:disable-next-line: no-non-null-assertion
          this.filterReinsurersEdit![event.item.dropContainer.data.index],
          'inc'
        )
        transferArrayItem(
          this.filterReinsurersEdit || [],
          this.filterReinsurersIncumbent || [],
          event.item.dropContainer.data.index,
          event.currentIndex
        )
      }
      this.sortReinsurers()
    }

    // Move tile from Selection to Target Market
    else if (
      event.container.data.bucket === 'marketTarget' &&
      event.item.dropContainer.data.bucket === 'selection'
    ) {
      if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersEdit![event.item.dropContainer.data.index]
          .market_use === AGENCY_MARKET_USE
      ) {
        // Open dialog if Agency
        if (
          // tslint:disable-next-line: no-non-null-assertion
          !this.filterReinsurersEdit![
            event.item.dropContainer.data.index
          ].reinsurerProgramFactor[0].co_broker?.replace(/\s/g, '') &&
          // tslint:disable-next-line: no-non-null-assertion
          !this.filterReinsurersEdit![
            event.item.dropContainer.data.index
          ].reinsurerProgramFactor[0].co_broker_ref?.replace(/\s/g, '')
        ) {
          this.reinsurerUpdate = this.updateReinsurerforAgencyModal(
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index]
          )
        } else {
          this.reinsurerUpdate =
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index]
        }
        this.showAgencyModal.emit({
          // tslint:disable-next-line: no-non-null-assertion
          re: this.reinsurerUpdate,
          type: 'tm',
        })
      } else if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersEdit![event.item.dropContainer.data.index]
          .market_use === FUND_MANAGER_MARKET_USE
      ) {
        // Open dialog if Fund Manager
        this.showFundManagerModal.emit({
          reinsurer:
            // tslint:disable-next-line: no-non-null-assertion
            this.filterReinsurersEdit![event.item.dropContainer.data.index],
          // tslint:disable-next-line: no-non-null-assertion
          programID: this.studyID!,
          select: 'tm',
        })
      } else {
        this.onChangeCheckbox(
          // tslint:disable-next-line: no-non-null-assertion
          this.filterReinsurersEdit![event.item.dropContainer.data.index],
          'tm'
        )
        transferArrayItem(
          this.filterReinsurersEdit || [],
          this.filterReinsurersTargetMarket || [],
          event.item.dropContainer.data.index,
          event.currentIndex
        )
      }
      this.sortReinsurers()
    } else if (
      event.container.data.bucket === 'marketTarget' &&
      event.item.dropContainer.data.bucket === 'incumbent'
    ) {
      this.onChangeCheckbox(
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersIncumbent![event.previousIndex],
        'tm'
      )
      transferArrayItem(
        this.filterReinsurersIncumbent || [],
        this.filterReinsurersTargetMarket || [],
        event.previousIndex,
        event.currentIndex
      )
      this.sortReinsurers()
    } else if (
      event.container.data.bucket === 'incumbent' &&
      event.item.dropContainer.data.bucket === 'marketTarget'
    ) {
      this.onChangeCheckbox(
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersTargetMarket![event.previousIndex],
        'inc'
      )
      transferArrayItem(
        this.filterReinsurersTargetMarket || [],
        this.filterReinsurersIncumbent || [],
        event.previousIndex,
        event.currentIndex
      )
      this.sortReinsurers()
    } else if (
      event.container.data.bucket === 'selection' &&
      event.item.dropContainer.data.bucket === 'marketTarget'
    ) {
      if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersTargetMarket![event.previousIndex].market_use ===
        AGENCY_MARKET_USE
      ) {
        // delete Relation
        // tslint:disable-next-line: no-non-null-assertion
        this.onDeleteRe(this.filterReinsurersTargetMarket![event.previousIndex])
      } else {
        this.onChangeCheckbox(
          // tslint:disable-next-line: no-non-null-assertion
          this.filterReinsurersTargetMarket![event.previousIndex],
          'sel'
        )
        transferArrayItem(
          this.filterReinsurersTargetMarket || [],
          this.filterReinsurersEdit || [],
          event.previousIndex,
          event.container.data.index
        )
      }
      this.sortReinsurers()
    } else if (
      event.container.data.bucket === 'selection' &&
      event.item.dropContainer.data.bucket === 'incumbent'
    ) {
      if (
        // tslint:disable-next-line: no-non-null-assertion
        this.filterReinsurersIncumbent![event.previousIndex].market_use ===
        AGENCY_MARKET_USE
      ) {
        // delete Relation
        // tslint:disable-next-line: no-non-null-assertion
        this.onDeleteRe(this.filterReinsurersIncumbent![event.previousIndex])
      } else {
        this.onChangeCheckbox(
          // tslint:disable-next-line: no-non-null-assertion
          this.filterReinsurersIncumbent![event.previousIndex],
          'sel'
        )
        transferArrayItem(
          this.filterReinsurersIncumbent || [],
          this.filterReinsurersEdit || [],
          event.previousIndex,
          event.container.data.index
        )
      }
      this.sortReinsurers()
    }
  }

  sortReinsurers(): void {
    if (this.filterReinsurersEdit) {
      this.filterReinsurersEdit = this.sortFunction(this.filterReinsurersEdit)
    }
    if (this.filterReinsurersIncumbent) {
      this.filterReinsurersIncumbent = this.sortFunction(
        this.filterReinsurersIncumbent
      )
    }
    if (this.filterReinsurersTargetMarket) {
      this.filterReinsurersTargetMarket = this.sortFunction(
        this.filterReinsurersTargetMarket
      )
    }
  }

  sortFunction(arr: Reinsurer[]): Reinsurer[] {
    arr = arr.sort((a, b) => {
      const nameOne = a.name.toUpperCase()
      const nameTwo = b.name.toUpperCase()
      if (nameOne < nameTwo) {
        return -1
      }
      if (nameOne > nameTwo) {
        return 1
      }
      return 0
    })
    return arr
  }

  byID(_index: number, reinsurer: Reinsurer): number {
    return reinsurer.id + (reinsurer.reinsurerProgramFactor[0].id || 0)
  }

  createFilter(value: string): void {
    if (value) {
      if (this.reinsurersEdit) {
        this.filterReinsurersEdit = this.reinsurersEdit.filter(e => {
          return e.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
      }
      if (this.reinsurersIncumbent) {
        this.filterReinsurersIncumbent = this.reinsurersIncumbent.filter(e => {
          return e.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
      }
      if (this.reinsurersTargetMarket) {
        this.filterReinsurersTargetMarket = this.reinsurersTargetMarket.filter(
          e => {
            return e.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          }
        )
      }
      this.isFilter = true
      this.filterValue = value
    } else {
      this.filterReinsurersEdit = this.reinsurersEdit
      this.filterReinsurersIncumbent = this.reinsurersIncumbent
      this.filterReinsurersTargetMarket = this.reinsurersTargetMarket
      this.isFilter = false
      this.filterValue = ''
    }
  }

  updateReinsurersPopulateFrom(): void {
    if (this.reinsurerPopulateFrom) {
      const moveToSel: Reinsurer[] = []
      const moveToInc: Reinsurer[] = []
      const moveToTM: Reinsurer[] = []
      this.reinsurerPopulateFrom.forEach(re => {
        const oldRe = this.reinsurers?.find(r1 => r1.id === re.id)
        if (oldRe && !this.isEqual(re, oldRe)) {
          if (
            re.reinsurerProgramFactor[0].incumbent !==
            oldRe.reinsurerProgramFactor[0].incumbent
          ) {
            if (re.reinsurerProgramFactor[0].incumbent) {
              moveToInc.push(re)
            } else {
              if (re.reinsurerProgramFactor[0].target_market) {
                moveToTM.push(re)
              } else {
                moveToSel.push(re)
              }
            }
          } else if (
            re.reinsurerProgramFactor[0].target_market !==
            oldRe.reinsurerProgramFactor[0].target_market
          ) {
            if (re.reinsurerProgramFactor[0].target_market) {
              moveToTM.push(re)
            } else {
              if (re.reinsurerProgramFactor[0].target_market) {
                moveToInc.push(re)
              } else {
                moveToSel.push(re)
              }
            }
          }
        }
      })
      if (moveToSel.length > 0) {
        moveToSel.forEach(sel => {
          const oldReInc = this.filterReinsurersIncumbent?.find(
            r1 => r1.id === sel.id
          )
          const oldReTM = this.filterReinsurersTargetMarket?.find(
            r1 => r1.id === sel.id
          )
          if (oldReInc) {
            const reinsurerProgramFactor: ProgramFactor[] =
              sel.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReInc.reinsurerProgramFactor[0].carrier_id,
                id: oldReInc.reinsurerProgramFactor[0].id,
                study_id: oldReInc.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...sel,
              reinsurerProgramFactor,
            }
            this.filterReinsurersIncumbent?.splice(
              this.filterReinsurersIncumbent.indexOf(oldReInc),
              1
            )
            this.filterReinsurersEdit?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          } else if (oldReTM) {
            const reinsurerProgramFactor: ProgramFactor[] =
              sel.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReTM.reinsurerProgramFactor[0].carrier_id,
                id: oldReTM.reinsurerProgramFactor[0].id,
                study_id: oldReTM.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...sel,
              reinsurerProgramFactor,
            }
            this.filterReinsurersTargetMarket?.splice(
              this.filterReinsurersTargetMarket.indexOf(oldReTM),
              1
            )
            this.filterReinsurersEdit?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          }
        })
      }
      if (moveToInc.length > 0) {
        moveToInc.forEach(inc => {
          const oldReSel = this.filterReinsurersEdit?.find(
            r1 => r1.id === inc.id
          )
          const oldReTM = this.filterReinsurersTargetMarket?.find(
            r1 => r1.id === inc.id
          )
          if (oldReSel) {
            const reinsurerProgramFactor: ProgramFactor[] =
              inc.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReSel.reinsurerProgramFactor[0].carrier_id,
                id: oldReSel.reinsurerProgramFactor[0].id,
                study_id: oldReSel.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...inc,
              reinsurerProgramFactor,
            }
            this.filterReinsurersEdit?.splice(
              this.filterReinsurersEdit.indexOf(oldReSel),
              1
            )
            this.filterReinsurersIncumbent?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          } else if (oldReTM) {
            const reinsurerProgramFactor: ProgramFactor[] =
              inc.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReTM.reinsurerProgramFactor[0].carrier_id,
                id: oldReTM.reinsurerProgramFactor[0].id,
                study_id: oldReTM.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...inc,
              reinsurerProgramFactor,
            }
            this.filterReinsurersTargetMarket?.splice(
              this.filterReinsurersTargetMarket.indexOf(oldReTM),
              1
            )
            this.filterReinsurersIncumbent?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          }
        })
      }
      if (moveToTM.length > 0) {
        moveToTM.forEach(tm => {
          const oldReInc = this.filterReinsurersIncumbent?.find(
            r1 => r1.id === tm.id
          )
          const oldReSel = this.filterReinsurersEdit?.find(
            r1 => r1.id === tm.id
          )
          if (oldReInc) {
            const reinsurerProgramFactor: ProgramFactor[] =
              tm.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReInc.reinsurerProgramFactor[0].carrier_id,
                id: oldReInc.reinsurerProgramFactor[0].id,
                study_id: oldReInc.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...tm,
              reinsurerProgramFactor,
            }
            this.filterReinsurersIncumbent?.splice(
              this.filterReinsurersIncumbent.indexOf(oldReInc),
              1
            )
            this.filterReinsurersTargetMarket?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          } else if (oldReSel) {
            const reinsurerProgramFactor: ProgramFactor[] =
              tm.reinsurerProgramFactor.map(obj => ({
                ...obj,
                carrier_id: oldReSel.reinsurerProgramFactor[0].carrier_id,
                id: oldReSel.reinsurerProgramFactor[0].id,
                study_id: oldReSel.reinsurerProgramFactor[0].study_id,
              }))
            const moveRe = {
              ...tm,
              reinsurerProgramFactor,
            }
            this.filterReinsurersEdit?.splice(
              this.filterReinsurersEdit.indexOf(oldReSel),
              1
            )
            this.filterReinsurersTargetMarket?.push(moveRe)
            this.updateOrAddDirty.emit(clone(moveRe))
            this.setOneReinsurer.emit({
              // tslint:disable-next-line: no-non-null-assertion
              programID: this.studyID!,
              reinsurer: moveRe,
            })
          }
        })
      }
      this.sortReinsurers()
    }
  }

  onDeleteRe(re: Reinsurer): void {
    this.deleteRe.emit(re)
  }

  isEqual(obj1: any, obj2: any): boolean {
    const obj1Length = Object.keys(obj1).length
    const obj2Length = Object.keys(obj2).length

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        key => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      )
    }
    return false
  }

  detectDuplicateReinsurers(): void {
    const nameCountMap: { [key: string]: number } = {}
    const duplicates: string[] = []

    this.allReinsurers?.forEach(r => {
      const name = r.reinsurerProgramFactor[0].obo_name ?? r.name
      if (nameCountMap[name]) {
        nameCountMap[name]++
      } else {
        nameCountMap[name] = 1
      }
    })

    for (const name in nameCountMap) {
      if (nameCountMap[name] > 1) {
        duplicates.push(name)
      }
    }
    this.duplicateReinsurers = duplicates
  }
}
