import { Injectable } from '@angular/core'
import { ValueType, Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import { DatePipe } from '@angular/common'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { currencySymbol } from '../../model/layers.util'
import { ExploreSummaryDatum, ExploreSummaryHeader } from '../explore.model'
import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { catchAndHandleError, mapToMaybeData } from 'src/app/api/util'
import { environment } from 'src/environments/environment'
import { ExcelExportService } from '@shared/services/excel-export.service'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

export interface GrossSummaryExportMultiSheet {
  worksheetsData: {
    name: string
    data: SortTableRow<ExploreSummaryDatum>[]
    columnDefs: SortTableColumnDef[]
    topHeaders: ExploreSummaryHeader[]
    chartMetric?: string
    base64Image?: string
  }[]
  fileName: string
  options: GrossSummaryExportServiceOptions
  titleOptions: GrossSummaryExportServiceTitleOptions
}

export interface GrossSummaryExportServiceOptions {
  isGroupBy: boolean
  currency: string | undefined
  roundedValue?: string
  indLossSets?: boolean
}

export interface GrossSummaryExportServiceTitleOptions {
  clientName: string
  program: string
  structure: string
  effectiveDate: string
  chartMetric?: string
}

@Injectable({
  providedIn: 'root',
})
export class GrossSummaryExportXlsxService {
  black = '000000'
  white = 'FFFFFF'
  blue = '00AEEF'
  grayText = '5B6670'
  darkGray = '737F8B'
  lightGray = 'E0E0E0'

  constructor(
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private exportService: ExcelExportService
  ) {}

  createWorkbook(): Workbook {
    const workbook = new Workbook()
    workbook.creator = 'Sage'
    workbook.created = new Date()
    return workbook
  }

  addWorksheet(
    workbook: Workbook,
    sheetName: string,
    data: SortTableRow<ExploreSummaryDatum>[],
    topHeaders: ExploreSummaryHeader[],
    columnDefs: SortTableColumnDef[],
    options: GrossSummaryExportServiceOptions,
    titleOptions: GrossSummaryExportServiceTitleOptions,
    base64Image?: string
  ): Worksheet {
    const horizontalFreeze = options.isGroupBy ? 2 : 1
    const worksheet = workbook.addWorksheet(sheetName, {
      pageSetup: { fitToPage: true },
      views: [
        {
          state: 'frozen',
          xSplit: horizontalFreeze,
          ySplit: 10,
          showGridLines: false,
        },
      ],
    })
    worksheet.pageSetup.margins = {
      left: 0.2,
      right: 0.2,
      top: 0.5,
      bottom: 0.5,
      header: 0.3,
      footer: 0.3,
    }

    this.addTitles(worksheet, titleOptions, columnDefs, options)

    this.addTopHeaders(worksheet, topHeaders, options)
    this.addHeaders(worksheet, columnDefs, options.isGroupBy)
    this.addDataRows(worksheet, data, columnDefs, options)
    this.exportService.appendLogos(worksheet, workbook, 3, 5)
    if (base64Image) {
      const imageOptions = {
        col: Math.max(9, columnDefs.length + 3),
        row: 10,
        width: 600,
        height: 375

      }
      this.addImageToWorksheet(
        workbook,
        worksheet,
        base64Image,
        imageOptions
      )
    }
    return worksheet
  }

  addTitles(
    worksheet: Worksheet,
    titleOptions: GrossSummaryExportServiceTitleOptions,
    columnDefs: SortTableColumnDef[],
    options: GrossSummaryExportServiceOptions
  ): void {
    const title1Row = worksheet.addRow([titleOptions.clientName])
    title1Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: this.blue },
    }

    const programStructure = `${titleOptions.program}: ${titleOptions.structure}`
    const title2Row = worksheet.addRow([programStructure])
    title2Row.font = {
      name: 'Segoe Pro',
      size: 12,
      color: { argb: this.blue },
    }

    const dateString = `Effective: ${titleOptions.effectiveDate}`
    const dateRow = worksheet.addRow([dateString])
    dateRow.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: this.grayText },
    }

    const grossSummaryTitle = titleOptions.chartMetric
      ? `Gross Summary - ${titleOptions.chartMetric}`
      : 'Gross Summary Results'

    const grossSummaryRow = worksheet.addRow([grossSummaryTitle])
    grossSummaryRow.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: this.blue },
    }

    const date = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    const asOfDateRow = worksheet.addRow([date])
    asOfDateRow.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: this.grayText },
      }
    })

    const simulations = ['', 'Based on 200,000 simulations']
    worksheet.addRow(simulations)
    worksheet.addRow([''])

    if (options.isGroupBy) {
      const groupings = String(columnDefs[1].label).split('/')
      const grossModelingLabel = `Gross Modeling Results - ${groupings.join(' and ')} ($${options.roundedValue})`
      const grossModelingLabelArray = ['', grossModelingLabel]

      columnDefs.forEach((_, i) => {
        if (i > 1) grossModelingLabelArray.push('')
      })

      const grossModelingLabelRow = worksheet.addRow(grossModelingLabelArray)
      grossModelingLabelRow.eachCell((c, i) => {
        if (i > 1) {
          c.style = {
            font: {
              name: 'Segoe Pro',
              size: 14,
              color: { argb: this.black },
            },
            border: {
              bottom: {
                style: 'medium',
                color: { argb: this.black },
              },
            },
          }
        }
      })
    }
  }

  addTopHeaders(
    worksheet: Worksheet,
    topHeaders: ExploreSummaryHeader[],
    options: {
      isGroupBy: boolean
      currency: string | undefined
      roundedValue?: string
      indLossSets?: boolean
    }
  ): void {
    const topHeaderArray: string[] = []
    topHeaders.forEach((header, i) => {
      if (header.id !== 'totalContributionToGroupVolatility') {
        if (!header.hideLabel) {
          topHeaderArray.push(header.label)
        } else {
          topHeaderArray.push('')
        }
        const extraCells = i > 2 ? Number(header.width) / 90 - 1 : 0
        if (extraCells > 0) {
          for (let i = 0; i < extraCells; i++) {
            topHeaderArray.push('')
          }
        }
      }
    })
    const topHeaderRow = worksheet.addRow(topHeaderArray)
    const mergeCells: {
      value: string
      index: number
      extraCells: number
    }[] = []
    topHeaderRow.height = 24
    const horizontalFreeze = options.isGroupBy ? 2 : 1
    topHeaderRow.eachCell((c, i) => {
      if (c.value !== '') {
        const isContribution = String(c.value).includes('Contribution')
        const contributionCell = isContribution ? 0 : 1
        const extraCells =
          i > horizontalFreeze
            ? Number(topHeaders.find(h => h.label === c.value).width) / 90  -
              contributionCell
            : 0
        mergeCells.push({
          value: String(c.value),
          index: i,
          extraCells,
        })
        if (isContribution) {
          c.value = 'Contribution To Group Volatility (Downside)'
        }
        c.style = {
          font: {
            name: 'Segoe Pro',
            size: 12,
            color: { argb: this.white },
            bold: true,
          },
          border: {
            bottom: {
              style: 'medium',
              color: { argb: this.black },
            },
          },
          alignment: { horizontal: 'center', vertical: 'middle' },
        }
      }
      if (i > horizontalFreeze) {
        c.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: this.darkGray },
        }
      }
    })
    mergeCells.forEach(c => {
      worksheet.mergeCells([
        topHeaderRow.number,
        c.index,
        topHeaderRow.number,
        c.index + c.extraCells,
      ])
    })
  }

  addHeaders(
    worksheet: Worksheet,
    columnDefs: SortTableColumnDef[],
    isGroupBy: boolean
  ): void {
    const headerRow = worksheet.addRow(columnDefs.map(cd => cd.label))
    headerRow.height = 24
    headerRow.eachCell((cell, i) => {
      let width = 16
      if (i === 1) {
        width = 30
      } else if (i === 2 && isGroupBy) {
        width = 75
      } else if (
        String(cell.value) === 'Total Contribution to Group Volatility'
      ) {
        width = 20
      }
      worksheet.getColumn(i).width = width
      if (!isNaN(Number(cell.value))) {
        cell.value = Number(cell.value)
      }
      if (i < 3) {
        cell.alignment = { wrapText: true, horizontal: 'left' }
      } else {
        cell.alignment = { wrapText: true, horizontal: 'right' }
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.blue },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: this.white },
      }
    })
  }

  addDataRows(
    worksheet: Worksheet,
    dataRows: SortTableRow<ExploreSummaryDatum>[],
    columnDefs: SortTableColumnDef[],
    options: {
      isGroupBy: boolean
      currency: string | undefined
      roundedValue?: string
      indLossSets?: boolean
    }
  ): void {
    let groupings: string[] = []
    if (options.isGroupBy) {
      groupings = String(columnDefs[1].label).split('/')
    }
    dataRows.forEach((data, i) => {
      const row: string[] = []
      columnDefs.forEach(def => {
        const key = def.id as keyof ExploreSummaryDatum
        row.push(String(data[key]))
      })
      const dataRow = worksheet.addRow(row)
      dataRow.eachCell((cell, j) => {
        const columnDef = columnDefs[j - 1]
        let valueType = columnDef.valueType ?? 'currency'
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: this.black },
        }
        cell.border = {
          ...cell.border,
          bottom: {
            style: 'thin',
            color: { argb: this.black },
          },
        }
        if (valueType === 'currency') {
          cell.model = {
            ...cell.model,
            type: ValueType.Number,
          }
          const transformedValue = this.transformValue(
            Number(cell.value),
            valueType
          )
          cell.value = transformedValue
          cell.numFmt = this.numberFormat(
            Math.abs(Number(transformedValue)),
            options.currency || 'USD',
            options.roundedValue ?? 'M',
            options.isGroupBy && !data.name.includes('_')
          )
        } else if (valueType === 'percentage') {
          cell.model = {
            ...cell.model,
            type: ValueType.Number,
          }
          const transformedValue = this.transformValue(
            Number(cell.value),
            valueType
          )
          cell.value = transformedValue
          cell.numFmt = '###,##0.0"%"'
        } else if (valueType === 'ratio' || valueType === 'numeric') {
          cell.value = Number(cell.value).toFixed(1)
        }
        if (options.isGroupBy) {
          const nameParts = data.name.split('_')
          const lastNamePartIndex = groupings.length - 1
          const lastLevel =
            (options.indLossSets && data.rowLayer === 'Level 5') ||
            (!options.indLossSets &&
              data.groupBy.trim() === nameParts[lastNamePartIndex] &&
              lastNamePartIndex !== 0)
          if (lastLevel) {
            if (j > 1) {
              const fillColor = options.indLossSets
                ? this.lightGray
                : this.white
              const fontColor = this.black
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor },
              }
              cell.font.color = { argb: fontColor }
            }
          } else {
            const fontColor = j === 1 ? { argb: 'F1462F' } : cell.font.color
            cell.font = {
              ...cell.font,
              bold: true,
              color: fontColor,
            }
          }
        }
        if (j < 3) {
          cell.alignment = { wrapText: true, horizontal: 'left' }
        } else {
          cell.alignment = { wrapText: true, horizontal: 'right' }
        }
        if (String(cell.value) === 'NaN') {
          cell.model = {
            ...cell.model,
            type: ValueType.String,
          }
          cell.value = ''
        }
      })
    })
  }

  addImageToWorksheet(
    workbook: Workbook,
    worksheet: Worksheet,
    base64Image: string,
    options: { col: number; row: number; width: number; height: number }
  ): void {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: 'png',
    })
  
    worksheet.addImage(imageId, {
      tl: { col: options.col, row: options.row },
      ext: { width: options.width, height: options.height },
    })
  }

  finalizeWorkbook(
    workbook: Workbook,
    fileName: string,
    save: boolean = true
  ): Promise<{ blob: Blob; base64: string }> {
    return workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      if (save) {
        saveAs(blob, fileName)
      }

      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () =>
          resolve({ blob, base64: reader.result as string })
        reader.readAsDataURL(blob)
      })
    })
  }

  exportMultiSheetSummaryXlsx(
    worksheetsData: {
      name: string
      data: SortTableRow<ExploreSummaryDatum>[]
      columnDefs: SortTableColumnDef[]
      topHeaders: ExploreSummaryHeader[]
      chartMetric?: string,
      base64Image?: string
    }[],
    fileName: string,
    options: GrossSummaryExportServiceOptions,
    titleOptions: GrossSummaryExportServiceTitleOptions
  ): Promise<{ blob: Blob; base64: string }> {
    const workbook = this.createWorkbook()

    worksheetsData.forEach(sheetData => {
      const titles = { ...titleOptions, chartMetric: sheetData.chartMetric }
      this.addWorksheet(
        workbook,
        sheetData.name,
        sheetData.data,
        sheetData.topHeaders,
        sheetData.columnDefs,
        options,
        titles,
        sheetData.base64Image
      )
    })

    return this.finalizeWorkbook(workbook, fileName)
  }

  exportSummaryXlsx(
    clientName: string,
    year: string,
    program: string,
    structure: string,
    effectiveDate: string,
    currency: string | undefined,
    topHeaders: ExploreSummaryHeader[],
    columnDefs: SortTableColumnDef[],
    dataRows: SortTableRow<ExploreSummaryDatum>[],
    isGroupBy: boolean,
    indLossSets?: boolean,
    roundedValue?: string,
    save?: boolean
  ): Promise<{ blob: Blob; base64: string }> {
    const workbook = this.createWorkbook()
    const options: GrossSummaryExportServiceOptions = {
      isGroupBy,
      currency,
      indLossSets,
      roundedValue,
    }
    const titleOptions: GrossSummaryExportServiceTitleOptions = {
      clientName,
      program,
      structure,
      effectiveDate,
    }
    this.addWorksheet(
      workbook,
      'Explore Gross Summary',
      dataRows,
      topHeaders,
      columnDefs,
      options,
      titleOptions
    )
    let fileName = 'export'
    let exportFileName = 'export'
    if (clientName && program) {
      fileName = `${clientName} ${program.replace(` ${year}`, '')} ${year} Explore Gross Summary ${this.datePipe.transform(new Date(), 'MMM d, y')}.xlsx`
      exportFileName = `${clientName} ${program.replace(` ${year}`, '')} ${year} Explore Gross Summary.xlsx`
    }
    return workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const base64FromBlob = (blobInput: Blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blobInput)
        return new Promise<string>(resolve => {
          reader.onloadend = () => {
            resolve(reader.result as string)
          }
        })
      }
      if (save) {
        saveAs(blob, fileName)
        base64FromBlob(blob).then(base64Response => {
          this.captureExportDetails(
            base64Response,
            fileName,
            'ExploreGrossSummary'
          )
            .pipe()
            .subscribe()
        })
      }
      return base64FromBlob(blob).then(base64 => ({ blob, base64 }))
    })
  }
  captureExportDetails(
    encodedData: any,
    filename: string,
    exportSelection: string
  ) {
    const cr = {
      blobData: encodedData,
      fileName: filename,
      selection: exportSelection,
    }
    // Upload attached fac files
    const url = `${environment.internalApi.base}${environment.internalApi.captureExportDetails}`
    return this.httpClient
      .post(url, JSON.stringify(cr), httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Capture Export Details')
      )
  }

  numberFormat(
    value: number,
    currency: string,
    abrev: string,
    groupByOne?: boolean
  ): string {
    const lookup = [
      { value: 1e6, symbol: 'M', commas: ',,' },
      { value: 1e3, symbol: 'K', commas: ',' },
    ]

    const item = lookup.find(i => i.symbol === abrev)
    const currSymbol = groupByOne ? currencySymbol(currency) : ''
    const positive = `"${currSymbol}"#,##0.0${item?.commas}`
    const negative = `-${positive}`

    return `${positive};${negative}`
  }

  transformValue(value: string | number, valueType: MetricValueType) {
    if (value === undefined) {
      return ''
    }

    switch (valueType) {
      case 'ratio':
      case 'percentage':
        return (value as number) * 100
      case 'currency':
        return value as number
      default:
        return value
    }
  }
}
