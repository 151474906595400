<div class="summary-charts">
  <app-explore-summary-chart-grid
    [chartsLoading]="chartsLoading"
    [chartOptions]="chartOptions"
    [selectableChartsForTab]="selectableChartsForTab"
    [selectableChartsForMetric]="selectableChartsForMetric"
    [maximizedIndex]="maximizedIndex"
    [chartEntityLimit]="chartsEntityLimit"
    [hideMetrics]="hideMetrics"
    [lightChartMode]="lightChartMode"
    [roundedToAbrev]="roundedToAbrev"
    [currentStructureCurrency]="currentStructureCurrency"
    (maximizeClick)="onMaximizeClick($event)"
    (chartOptionUpdate)="chartOptionUpdate.emit($event)"
  >
  </app-explore-summary-chart-grid>
</div>
