import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  // ChangeDetectorRef,
  EventEmitter,
  // HostBinding,
  // OnDestroy,
  Output,
  // ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { AppState } from '../../core/store'
import * as fromBrokerActions from '../../core/store/broker/broker.actions'
import { NewFolderDialogContainerComponent } from '../new-folder-dialog/new-folder-dialog.container'
import { Folder } from '../../core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-structure-folder-container',
  styleUrls: ['./structure-folder-container.scss'],
  templateUrl: './structure-folder.container.html',
})
export class StructureFolderContainerComponent implements OnInit {
  @Input() folder: Folder
  @Input() editMode: boolean
  folderID: string

  @Output() updateFolders = new EventEmitter()
  @Output() deleteFolder = new EventEmitter<{
    programIDs: (string | number)[]
    folderID: string
  }>()
  @Output() folderClick = new EventEmitter<{ folderID: string }>()

  constructor(private store: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.folderID = this.folder.id
  }

  onDeleteFolder(event: { folderID: string }): void {
    const { folderID } = event
    this.deleteFolder.emit({
      programIDs: this.folder.programIDs,
      folderID,
    })
    this.store.dispatch(fromBrokerActions.deleteFolder({ folderID }))
  }

  onEditFolderName(event: { folderID: string; name: string }): void {
    const data = event
    const dialogRef: MatDialogRef<NewFolderDialogContainerComponent> =
      this.dialog.open(NewFolderDialogContainerComponent, { data })
    dialogRef.afterClosed().subscribe(() => {
      this.updateFolders.emit()
    })
  }

  onFolderClick(event: { folderID: string }): void {
    const { folderID } = event
    this.folderClick.emit({ folderID })
  }
}
