import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  TableDialogOptions,
  TableDialogComponent,
} from './table-dialog.component'

@Injectable()
export class TableDialogService {
  constructor(public dialog: MatDialog) {}

  open(opts?: TableDialogOptions) {
    return this.dialog.open(TableDialogComponent, {
      width: '80vw',
      data: opts,
    })
  }
}
