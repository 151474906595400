import { routerReducer, RouterReducerState } from '@ngrx/router-store'
import {
  ActionReducer,
  ActionReducerMap,
  createReducer,
  MetaReducer,
  on,
  Action,
} from '@ngrx/store'
import { storeFreeze } from 'ngrx-store-freeze'
import { environment } from '../../../environments/environment'
import { setWidth, WindowSize } from './app.actions'
import * as fromAccountOpportunity from './accountopportunity.reducer'
import * as fromAnalysis from './analysis/analysis.reducer'
import * as fromAuth from './auth/auth.reducer'
import * as fromBroker from './broker/broker.reducer'
import * as fromClients from './clients.reducer'
import * as fromProgram from './program/program.reducer'
import * as fromProgramGroup from './program-group/program-group.reducer'
import * as fromProgramGroupMember from './program-group-member.reducer'
import * as fromLossSetGroup from './loss-set-group.reducer'
import * as fromLossSetGroupMember from './loss-set-group-member.reducer'
import * as fromSlipTemplates from './slip-templates/slip-templates.reducer'
import * as fromTheme from './theme/theme.reducer'
import * as fromReinsurerTrackingSubjectivities from '../store/reinsurerSubjectivities.reducer'
import { State as AnalysisState } from './analysis/analysis.state.facade'
import { State as AuthState } from './auth/auth.state.facade'
import { State as BrokerState } from './broker/broker.state.facade'
import { State as ProgramState } from './program/program.state.facade'
import { State as ProgramGroupState } from './program-group/program-group.state.facade'
import { State as SlipTemplatesState } from './slip-templates/slip-templates.state.facade'
import { State as ThemeState } from './theme/theme.state.facade'
import { localStorageSync } from 'ngrx-store-localstorage'

export interface AppState {
  router: RouterReducerState
  window: WindowSize
  accountOpportunity: fromAccountOpportunity.State
  analysis: AnalysisState
  auth: AuthState
  broker: BrokerState
  clients: fromClients.State
  lossSetGroup: fromLossSetGroup.State
  lossSetGroupMember: fromLossSetGroupMember.State
  program: ProgramState
  programGroup: ProgramGroupState
  programGroupMember: fromProgramGroupMember.State
  sliptemplates: SlipTemplatesState
  theme: ThemeState
  reinsurerTrackingSubjectivities: fromReinsurerTrackingSubjectivities.ReinsurerTrackingSubjectivityState
}

const initialWindowSize: WindowSize = { width: 1000, height: 1000 }
const _widthReducer = createReducer(
  initialWindowSize,
  on(setWidth, (_, { size }) => size)
)

export function widthReducer(state: WindowSize, action: Action) {
  return _widthReducer(state, action)
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
    ],
    rehydrate: true,
  })(reducer)
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  window: widthReducer,
  accountOpportunity: fromAccountOpportunity.reducer,
  analysis: fromAnalysis.reducer,
  auth: fromAuth.reducer,
  broker: fromBroker.reducer,
  clients: fromClients.reducer,
  lossSetGroup: fromLossSetGroup.reducer,
  lossSetGroupMember: fromLossSetGroupMember.reducer,
  program: fromProgram.reducer,
  programGroup: fromProgramGroup.reducer,
  programGroupMember: fromProgramGroupMember.reducer,
  sliptemplates: fromSlipTemplates.reducer,
  theme: fromTheme.reducer,
  reinsurerTrackingSubjectivities: fromReinsurerTrackingSubjectivities.reducer
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [
      (reducer: ActionReducer<AppState>) => reducer,
      localStorageSyncReducer,
      storeFreeze,
    ]
  : [localStorageSyncReducer]

export const selectWindowSize = (state: AppState) => state.window
