<h1 mat-dialog-title>Auto-build Design Structures from OpenTWINS</h1>
<mat-dialog-content>
  <mat-spinner
    *ngIf="!riskSections"
    color="accent"
    [diameter]="20"
  ></mat-spinner>

  <div *ngIf="responseHasData">
    <div class="ot-warning" *ngIf="asteriskText !== ''">
      <h3>* {{asteriskText}}</h3>
      <div
        class="layer-flag-info"
        [ngClass]="{'layer-heading': f}"
        *ngFor="let info of layerFlagInfo; let f = first"
      >
        {{info}}
      </div>
    </div>
    <table
      class="app-table app-table-small mat-table"
      mat-table
      [dataSource]="riskSectionsDataSource"
      multiTemplateDataRows
    >
      <!-- Column definitions -->
      <ng-container matColumnDef="riskRef">
        <th mat-header-cell class="width-260" *matHeaderCellDef>RiskRef</th>
        <td mat-cell *matCellDef="let element">{{element.riskRef}}</td>
      </ng-container>

      <ng-container matColumnDef="interestNarrative">
        <th mat-header-cell class="width-260" *matHeaderCellDef>Risk Description</th>
        <td mat-cell *matCellDef="let element">{{element.interestNarrative}}</td>
      </ng-container>

      <ng-container matColumnDef="occurrenceLimit">
        <th mat-header-cell class="padding-right-half" *matHeaderCellDef>
          Limit
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.occurrenceLimit | currency : 'USD' : 'symbol' : '1.0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="occurrenceAttachment">
        <th mat-header-cell class="padding-right-half" *matHeaderCellDef>
          Attachment
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.occurrenceAttachment | currency : 'USD' : 'symbol' : '1.0'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="topMarket">
        <th mat-header-cell *matHeaderCellDef>Top Market</th>
        <td mat-cell *matCellDef="let element">
          {{element.largestLayerSignedPctReinsurer}}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfMarkets">
        <th mat-header-cell *matHeaderCellDef># of Markets</th>
        <td mat-cell *matCellDef="let row; let index = index">
          <!-- <div [matTooltip]="getMarketTooltip(row)" matTooltipPosition="before" matTooltipClass="tooltip-aa">
            {{ getNumberOfMarkets(row) }}
          </div> -->
          {{ getNumberOfMarkets(row) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="layerTypeSelection">
        <th mat-header-cell *matHeaderCellDef>Layer Type</th>
        <td mat-cell *matCellDef="let row; let index = index">
          <mat-form-field>
            <mat-select
              [(ngModel)]="row.selectedLayer"
              (click)="$event.stopPropagation()"
              (selectionChange)="onLayerChange($event)"
            >
              <mat-option *ngFor="let layer of layers" [value]="layer.id">
                <span>
                  <span
                    *ngIf="layer.id === layerIds.noncatIndxl || layer.id === layerIds.noncatSwing
                  || layer.id === layerIds.noncatMultisection || layer.id === layerIds.catMultisection"
                    >*
                  </span>
                  {{layer.program}} - {{ layer.name }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="towerSelection">
        <th mat-header-cell *matHeaderCellDef>Structure</th>
        <td mat-cell *matCellDef="let row; let index = dataIndex">
          <mat-form-field>
            <mat-select
              [(ngModel)]="row.selectedStructure"
              (click)="$event.stopPropagation()"
              (selectionChange)="onTowerSelectionChange($event, index)"
            >
              <mat-option *ngFor="let tower of towers" [value]="tower.id">
                <span>{{tower.name}}</span>
              </mat-option>
              <mat-option value="newTower">+ New Structure</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
          >
            <mat-icon *ngIf="expandedElement !== element"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="expandedElement === element"
              >keyboard_arrow_up</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplayWithExpand.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
          >
            <div *ngFor="let s of element.sections">
              <div class="element-diagram">
                <span class="display-inline-block width-250">
                  {{s.sectionNarrative}}
                </span>
                <span class="margin-right-2">
                  {{s.largestSectionSignedPctReinsurer }}
                </span>
                <span>{{ s.largestSectionSignedPct | number: '0.1-1' }}%</span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplayWithExpand; sticky: true"
      ></tr>
      <tr
        mat-row
        class="element-row"
        *matRowDef="let element; columns: columnsToDisplayWithExpand;"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>
  </div>
  <div *ngIf="riskSections && !responseHasData">
    <h2>
      There is no OpenTwins data found for the expiring opportunity.
    </h2>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div *ngIf="responseHasData" class="actions-row">
    <button mat-raised-button class="close" (click)="onClose()">Close</button>
    <button mat-raised-button class="submit" (click)="onImport()">Import</button>
    <div class="cession-pct-toggle">
      <mat-slide-toggle
        [checked]="shouldDefaultCessionPct"
        (change)="shouldDefaultCessionPct = !shouldDefaultCessionPct"
        matTooltip="If not checked, layers will use Cession % from expiring risk"
      >Set all layers to 100% cession</mat-slide-toggle>
    </div>
  </div>
  <div *ngIf="!responseHasData">
    <mat-dialog-actions>
      <button appButton border class="close" (click)="onClose()">Close</button>
    </mat-dialog-actions>
  </div>
</mat-dialog-actions>
