import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as fromGrouperActions from '../../analysis/store/grouper/grouper.actions'
import { ProgramGroupMember } from '../../analysis/store/grouper/program-group.model'
import * as fromGroupScenarioActions from '../../analysis/store/grouper/program-group/program-group-scenarios.actions'
import { convertProgramGroupMemberFromResponse } from '../../api/program-group/program-group.converter'
import { ApplicationError } from '../../error/model/error'
import * as AuthActions from './auth/auth.actions'

interface ExtendedState {
  loading: boolean
  error: ApplicationError | null
}

export type State = EntityState<ProgramGroupMember> & ExtendedState

export const adapter = createEntityAdapter<ProgramGroupMember>()

export const initialState: State = adapter.getInitialState<ExtendedState>({
  loading: false,
  error: null,
})

const programGroupsMemberReducer = createReducer(
  initialState,

  on(AuthActions.identifySuccess, state => ({
    ...state,
    loading: initialState.loading,
    error: initialState.error,
  })),

  on(AuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(
    AuthActions.identifyPermissionsSuccess,
    (state, { programGroupMembers }) => {
      const next = adapter.setAll(
        programGroupMembers.map(convertProgramGroupMemberFromResponse),
        state
      )
      return {
        ...next,
        loading: initialState.loading,
        error: initialState.error,
      }
    }
  ),

  on(
    fromGrouperActions.saveGrouperSuccess,
    fromGroupScenarioActions.saveProgramGroupScenariosSuccess,
    (state, { members }) => {
      const { create, update, remove } = members
      return adapter.removeMany(
        remove,
        adapter.updateMany(update, adapter.addMany(create, state))
      )
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return programGroupsMemberReducer(state, action)
}
