import { Action, createReducer, on } from '@ngrx/store'
import {
  fetchPortfolio,
  fetchPortfolioFailure,
  fetchPortfolioSuccess,
} from './portfolio.actions'

export interface State {
  id: string | null
  layersIDs: string[]
  loading: boolean
  error: string | null
}

export const initialState: State = {
  id: null,
  layersIDs: [],
  loading: false,
  error: null,
}

const netPortfolioReducer = createReducer(
  initialState,
  on(fetchPortfolio, _ => ({ ...initialState, error: null, loading: true })),
  on(fetchPortfolioSuccess, (state, { id, layersIDs }) => ({
    ...state,
    loading: false,
    error: null,
    id,
    layersIDs,
  })),
  on(fetchPortfolioFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return netPortfolioReducer(state, action)
}
