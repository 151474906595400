import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { State as AuthState } from '../core/store/auth/auth.state.facade'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-digital',
  styles: [
    `
           app-digital-header {
             flex: 0 0 auto;
             padding: var(--stack) var(--inset-big) var(--stack) 0;
             height: var(--header-height);
           }
           @media screen and (max-width: 1240px) {
             app-digital-header {
               height: var(--header-height-small);
             }
           }
         `,
  ],
  template: `
    <app-digital-header [name]="name"></app-digital-header>
    <app-digital-content
      [clientID]="clientID"
      [studyID]="studyID"
      [year]="year"
      [authState]="authState"
    ></app-digital-content>
  `,
})

export class DigitalComponent {
  @Input() name = 'Digital Client'
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() year: string | null
  @Input() authState: AuthState
}
