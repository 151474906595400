import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnInit,
  HostListener,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-overview-card',
  styles: [
    `
           :host {
             position: relative;
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: flex-start;
     
             color: var(--body);
             background: var(--bg-1-translucent);
             backdrop-filter: blur(7px);
             border: 1px solid var(--border-translucent-subtle);
             border-radius: var(--border-radius-big);
             box-shadow: var(--shadow-big);
           }
     
           :host.clickable {
             cursor: pointer;
             box-shadow: 0 0 1px rgba(0, 0, 0, 0);
             transform: translateZ(0);
             transition: background 250ms, transform 250ms;
           }
     
           :host.clickable:hover {
             background: var(--bg-1-translucent-lit);
           }
     
           :host,
           :host * {
             box-sizing: border-box;
           }
     
           :host > * {
             flex: 0;
             margin: 0 var(--inset) var(--stack-big);
           }
     
           .header {
             width: 100%;
             background: radial-gradient(
               circle at 30% 107%,
               #fdf09756 0%,
               #fff0863b 5%,
               #00b8ff52 45%,
               #24bdd656 60%,
               #282eeb26 90%
             );
             border-bottom: 1px solid rgb(226, 233, 234, 0.83);
             border-top-left-radius: var(--border-radius-big);
             border-top-right-radius: var(--border-radius-big);
             margin-bottom: var(--stack-tiny);
           }
     
           :host-context(.app-theme-dark) .header {
             border-bottom: 1px solid rgba(19, 24, 25, 0.83);
           }
     
           :host-context(.app-theme-light) .header {
             border-bottom: 1px solid rgb(226, 233, 234, 0.83);
           }
     
           h3 {
             width: 100%;
             padding: var(--stack-big) 0;
             text-align: center;
     
             background: linear-gradient(0deg, var(--bg-1), rgba(22, 26, 27, 0.29));
             border-top-left-radius: var(--border-radius-big);
             border-top-right-radius: var(--border-radius-big);
           }
     
           .white-noise {
             position: absolute;
             top: 0;
             left: 0;
             margin: 0;
             width: 100%;
             height: 100%;
             /* TODO: FIX */
             /*background-image: url('assets/bg/white-noise.png');*/
             border-radius: var(--border-radius-big);
             opacity: 0.5;
             overflow: hidden;
             z-index: 1;
           }
     
           img,
           caption,
           p {
             z-index: 2;
           }
     
           img {
             width: 100%;
             height: 100%;
             min-height: 11vw;
             object-fit: contain;
           }
     
           caption {
             font-family: var(--font-header-family);
             font-style: italic;
             font-size: var(--font-size-small);
             min-height: 36px;
           }
     
           p {
             align-self: flex-start;
             line-height: 1.4;
           }
     
           :host.center p.content {
             align-self: center;
             min-height: 11vw;
           }
     
           :host footer {
             font-size: var(--font-size-tiny);
             color: var(--subtle);
             align-self: flex-start;
             margin-bottom: var(--stack);
             min-height: 30px;
           }
     
           @media screen and (min-width: 1680px) {
             img {
               min-height: 200px;
             }
           }
         `,
  ],
  template: `
    <div class="header">
      <h3>
        <span>{{ title }}</span>
      </h3>
    </div>

    <div>
      <img [src]="imagePath" />
    </div>

    <caption *ngIf="caption">
      {{
        caption
      }}
    </caption>

    <p class="content"><ng-content></ng-content></p>

    <footer *ngIf="footer">{{ footer }}</footer>

    <div class="white-noise"></div>
  `,
})
export class OverviewCardComponent implements OnInit {
  @Input() title: string
  @Input() imagePath: string
  @Input() caption?: string
  @Input() body: string
  @Input() footer?: string

  @Output() cardClick = new EventEmitter<void>()

  @HostBinding('class.center') _center = false
  @Input() set center(value: any) {
    this._center = coerceBooleanProperty(value)
  }
  get center() {
    return this._center
  }

  @HostBinding('class.clickable') clickable: boolean

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    this.cardClick.emit()
  }

  ngOnInit() {
    this.clickable = this.cardClick.observers.length > 0
  }
}
