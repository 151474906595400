import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil, map, skip, take } from 'rxjs/operators'
import {
  selectSelectedStudyReinsurers,
  selectStudyReinsurersSaving,
  selectStudyReinsurerFilters,
  selectReinsurerDomicileList,
  selectReinsurerSPRatingList,
  selectReinsurerAmBestRatingList,
  selectStudyReinsurerDirty,
  selectStudyReinsurerPopulateFrom,
} from '../store/reinsurers.selectors'
import {
  fetchMetricTableSettings,
  setExpandedMetricTableCategory,
} from '../../analysis/store/metrics/metrics-cart/metrics-cart.actions'
import {
  setReinsurer,
  updateOrAddStudiesReinsurerDirty,
  updateReinsurer,
  removeAllReinsurerFilters,
  toggleReinsurerFilter,
  removeReinsurerFilter,
  deleteClientStudiesReinsurerDirty,
  fetchStudyReinsurer,
  populateFromReinsurer,
  setUpdateOneReinsurer,
  exportReinsurers,
  openAgencyDetailsDialog,
  openReinsurerDetailsDialog,
  deleteAgencyTemp,
  openFundManagerDetailsDialog,
} from '../store/study-reinsurers.actions'
import { AccountOpportunity, BlobResponse } from '../../api/model/backend.model'
import { Client } from '../../core/model/client.model'
import {
  AGENCY_MARKET_USE,
  FUND_MANAGER_MARKET_USE,
  Reinsurer,
  ReinsurerFilter,
  SelectedUserPreferences,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { AppState } from '../../core/store'
import { selectReinsurerBlob } from '../../core/store/auth/auth.selectors'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import { selectClients } from '../../core/store/clients.selectors'
import { LossFilter } from '../../api/analyzere/analyzere.model'
import { selectCurrentLossFilters } from '../../core/store/broker/broker.selectors'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { ReinsurerDetailDialogContainerComponent } from '../reinsurer-detail-container/reinsurer-detail.container'
import { selectMetricTableSettingsCategories } from '../../analysis/store/analysis.selectors'
import { CompareMetricTableCategory } from 'src/app/analysis/model/compare-metrics.model'
import {
  fetchUserPreferences,
  saveOrUpdateUserPreferences,
} from 'src/app/user-preferences/store/user-preferences.actions'
import {
  selectUserPreferencesColumns,
  selectUserPreferences,
  selectdefaultUSAUserPreferences,
} from 'src/app/user-preferences/store/user-preferences.selectors'
import { saveOrUpdateProgramEvents } from 'src/app/program-events/store/program-events.actions'
import { ProgramEvents } from 'src/app/program-events/model/program-events.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-container',
  templateUrl: './reinsurer.container.html',
})
export class ReinsurerContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  clientID$: Observable<string | null>
  client$: Observable<Client | null>
  yearID$: Observable<string | null>
  studyId$: Observable<string | null>
  studies$: Observable<readonly Study[]>
  studySelected$: Observable<string | null>
  savingReinsurer$: Observable<boolean>
  reinsurersBlob$: Observable<BlobResponse | null>
  categories$: Observable<CompareMetricTableCategory[]>

  reinsurers$: Observable<Reinsurer[] | null>
  reinsurerFilters$: Observable<ReinsurerFilter[]>
  reinsurerDomicileList$: Observable<(string | undefined)[] | undefined>
  reinsurerSPRatingList$: Observable<(string | undefined)[] | undefined>
  reinsurerAmBestRatingList$: Observable<(string | undefined)[] | undefined>

  clients$: Observable<readonly Client[]>

  clientID: string | null
  studiesID: Study[]

  reinsurer$: Observable<any>
  reinsurerDirty$: Observable<boolean>
  reinsurerPopulateFrom$: Observable<Reinsurer[] | null>
  currentLossFilters$: Observable<LossFilter[] | undefined>

  selectedProgramID$: Observable<string | null>
  selectedUserPreferences$: Observable<SelectedUserPreferences | null>
  userPreferencesColumnsList$: Observable<string[]>
  defaultUSAUserPreferences$: Observable<SelectedUserPreferences>
  programs$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.client$ = this.store.pipe(select(fromBroker.selectCurrentClient))
    this.clientID$ = this.store.pipe(select(fromBroker.selectCurrentClientID))
    this.yearID$ = this.store.pipe(select(fromBroker.selectCurrentYearID))
    this.studyId$ = this.store.pipe(select(fromBroker.selectCurrentStudyID))
    this.studies$ = this.store.pipe(
      select(fromBroker.selectCurrentClientStudies)
    )
    this.savingReinsurer$ = this.store.pipe(select(selectStudyReinsurersSaving))
    this.reinsurersBlob$ = this.store.pipe(select(selectReinsurerBlob))

    this.reinsurers$ = this.store.pipe(select(selectSelectedStudyReinsurers))
    this.reinsurerFilters$ = this.store.pipe(
      select(selectStudyReinsurerFilters)
    )

    this.reinsurerDomicileList$ = this.store.pipe(
      select(selectReinsurerDomicileList)
    )
    this.reinsurerSPRatingList$ = this.store.pipe(
      select(selectReinsurerSPRatingList)
    )
    this.reinsurerAmBestRatingList$ = this.store.pipe(
      select(selectReinsurerAmBestRatingList)
    )

    this.reinsurerDirty$ = this.store.pipe(
      select(selectStudyReinsurerDirty),
      map(reinsurers => reinsurers.length > 0)
    )

    this.reinsurerPopulateFrom$ = this.store.pipe(
      select(selectStudyReinsurerPopulateFrom)
    )

    this.selectedUserPreferences$ = this.store.pipe(
      select(selectUserPreferences)
    )
    this.userPreferencesColumnsList$ = this.store.pipe(
      select(selectUserPreferencesColumns)
    )

    this.defaultUSAUserPreferences$ = this.store.pipe(
      select(selectdefaultUSAUserPreferences)
    )

    this.clients$ = this.store.pipe(select(selectClients))

    this.currentLossFilters$ = this.store.pipe(select(selectCurrentLossFilters))

    this.store
      .pipe(select(fromBroker.selectCurrentClientID), takeUntil(this.destroy$))
      .subscribe(value => {
        this.clientID = value
      })

    this.store
      .pipe(
        select(fromBroker.selectCurrentStudyID),
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(studyID => {
        this.store.dispatch(deleteClientStudiesReinsurerDirty())
        if (this.clientID && studyID) {
          this.store.dispatch(
            fetchStudyReinsurer({
              carrierID: this.clientID,
              studyID,
            })
          ),
            this.store.dispatch(fetchMetricTableSettings({ studyID }))
          this.store.dispatch(fetchUserPreferences({ studyID }))
        }
      })

    this.selectedProgramID$ = this.store.pipe(
      select(fromBroker.selectCurrentStudyID)
    )
    this.programs$ = this.store.pipe(
      select(fromBroker.selectCurrentYearStudies)
    )
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )

    this.categories$ = this.store.pipe(
      select(selectMetricTableSettingsCategories)
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onBackClick() {
    this.router.navigate(['/home'])
    return false
  }

  onCollapseToggle(category: string) {
    this.store.dispatch(setExpandedMetricTableCategory({ category }))
  }

  onSaveClick() {
    this.store.dispatch(updateReinsurer())
  }

  onSetReinsurer(value: { programID: string; reinsurers: Reinsurer[] }) {
    this.store.dispatch(setReinsurer(value))
  }

  onSetOneReinsurer(value: { programID: string; reinsurer: Reinsurer }) {
    this.store.dispatch(setUpdateOneReinsurer(value))
  }

  onShowInfo(reinsurer: Reinsurer) {
    if (
      reinsurer.market_use !== AGENCY_MARKET_USE &&
      reinsurer.market_use !== FUND_MANAGER_MARKET_USE
    ) {
      this.dialog.open(ReinsurerDetailDialogContainerComponent, {
        panelClass: 'reinsurerDetail-dialog-box',
        data: {
          reinsurer,
          agencyDetails: [],
          relatedReinsurers: [],
        },
        maxHeight: '80vh',
      })
    } else {
      this.store.dispatch(openReinsurerDetailsDialog({ data: reinsurer }))
    }
  }

  onUpdateOrAddDirty(reinsurer: Reinsurer) {
    this.store.dispatch(updateOrAddStudiesReinsurerDirty({ reinsurer }))
  }

  onReinsurerFilterToggle(reinsurerFilter: ReinsurerFilter) {
    this.store.dispatch(toggleReinsurerFilter({ reinsurerFilter }))
  }

  onRemoveAllFilters() {
    this.store.dispatch(removeAllReinsurerFilters())
  }

  onRemoveFilter(reinsurerFilter: ReinsurerFilter) {
    this.store.dispatch(removeReinsurerFilter({ reinsurerFilter }))
  }

  onPopulateSelectorClick($event: { client: string; program: string }) {
    this.store.dispatch(
      populateFromReinsurer({ client: $event.client, program: $event.program })
    )
  }

  onExportClick($event: {
    selectedOptions?: string[]
    selectedDisplayColumns?: string[]
    selectedUserPreferences: SelectedUserPreferences
    userPreferencesUpdated: boolean
    programEvents: ProgramEvents
  }) {
    this.store.dispatch(
      exportReinsurers({
        customBreakOutSelections: $event.selectedOptions,
        customDisplayFieldSelections: $event.selectedDisplayColumns,
      })
    )
    if ($event.userPreferencesUpdated) {
      this.store.dispatch(
        saveOrUpdateUserPreferences({
          selectedUserPreferences: $event.selectedUserPreferences,
        })
      )
    }
    this.store.dispatch(
      saveOrUpdateProgramEvents({
        programEvents: $event.programEvents,
      })
    )
  }

  onShowAgencyModal($event: { re: Reinsurer; type: string }) {
    this.studyId$.pipe(take(1)).subscribe(programId =>
      this.store.dispatch(
        openAgencyDetailsDialog({
          data: $event.re,
          programId,
          select: $event.type,
        })
      )
    )
  }

  onShowFundManagerModal($event: {
    reinsurer: Reinsurer
    programID: string
    select: string
  }) {
    this.store.dispatch(
      openFundManagerDetailsDialog({
        reinsurer: $event.reinsurer,
        programID: $event.programID,
        select: $event.select,
      })
    )
  }

  onDeleteRe(re: Reinsurer) {
    this.store.dispatch(deleteAgencyTemp({ re }))
  }
}
