interface Action<T = any> {
  type: T
}

interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.
  [extraProps: string]: any
}

type Reducer<T = any, A extends Action = AnyAction> = (
  state: T | undefined,
  action: A,
  ...args: Array<Reducer<T>>
) => T

/**
 * @see https://github.com/redux-utilities/reduce-reducers
 */
export const reduceReducers = <T>(
  ...args: Array<Reducer<T> | T>
): Reducer<T> => {
  // Last arg can optionally be the initialState
  const initState = (typeof args[args.length - 1] !== 'function' &&
    args.pop()) as T
  const reducers = args as Array<Reducer<T>>

  if (typeof initState === 'undefined') {
    throw new TypeError(
      'The initial state may not be undefined. ' +
        'If you do not want to set a value for this reducer, ' +
        'you can use null instead of undefined.'
    )
  }

  return (state: T, action: AnyAction, ..._reducers: Array<Reducer<T>>) => {
    const stateIsUndefined = typeof state === 'undefined'
    const actionIsUndefined = typeof action === 'undefined'

    if (stateIsUndefined && actionIsUndefined && initState) {
      return initState
    }

    const useInitState = stateIsUndefined && !actionIsUndefined && initState

    return reducers.reduce(
      (newState, reducer, index) => {
        if (typeof reducer === 'undefined') {
          throw new TypeError(
            `An undefined reducer was passed in at index ${index}`
          )
        }

        return reducer(newState, action, ..._reducers)
      },
      useInitState ? initState : state
    )
  }
}
