import { createAction, props } from '@ngrx/store'
import {
  ScenarioEvent,
  AddScenarioEventPayload,
} from '../animated-scenarios.model'

export const addAllScenarioEvents = createAction(
  '[Animated Scenarios] Add All Scenario Events',
  props<{ events: AddScenarioEventPayload[] }>()
)

export const addScenarioEvent = createAction(
  '[Animated Scenarios] Add Scenario Event',
  props<{ event: AddScenarioEventPayload }>()
)

export const updateScenarioEvent = createAction(
  '[Animated Scenarios] Update Scenario Event',
  props<{ id: number; changes: Partial<ScenarioEvent> }>()
)

export const removeScenarioEvent = createAction(
  '[Animated Scenarios] Remove Scenario Event',
  props<{ id: number }>()
)

export const removeAllScenarioEvents = createAction(
  '[Animated Scenarios] Remove All Scenario Events'
)
