import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { concatMapWithInput, rejectErrorWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import {
  constructPortfolioViewMetricsPayload,
  defaultPortfolioViewMetricsPayload,
} from '../../model/metrics.converter'
import { PortfolioMetrics } from '../../model/portfolio-metrics.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { PortfolioViewID } from '../../model/portfolio-set.model'
import {
  selectCurrentProgram,
  selectPortfolioViewMetrics,
} from '../analysis.selectors'
import {
  calculatePortfolioViewDetailMetrics,
  calculateRSS,
} from './calculations'
import {
  fetchPortfolioViewDetailMetrics,
  fetchPortfolioViewDetailMetricsFailure,
  fetchPortfolioViewDetailMetricsSuccess,
} from './portfolio-detail-metrics.actions'
import {
  fetchPortfolioViewMetrics,
  updateRSS,
} from './portfolio-metrics.actions'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { SectorAssumptionLookupResponse } from 'src/app/core/model/study.model'
import { PortfolioUserInputsResponse } from '../../../core/model/study.model'
import { fetchTailMetrics } from './tail-metrics.actions'

@Injectable()
export class PortfolioViewMetricsDetailEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: AnalyzreService) {}

  fetchAndCalculate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchPortfolioViewDetailMetrics),
      map(({ type, ...props }) => props),
      concatMapWithInput(props =>
        this.service.getAllPortfolioDetailViewMetrics(
          props.cededPortfolioViewID,
          props.grossPortfolioViewID,
          props.netPortfolioViewID
        )
      ),
      rejectErrorWithInput((error, props) =>
        this.store.dispatch(
          fetchPortfolioViewDetailMetricsFailure({ ...props, error })
        )
      ),
      concatMap(([allMetrics, props]) => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of([allMetrics, props] as const).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectPortfolioViewMetrics, { portfolioSetID })
            ),
            this.store.pipe(select(selectCurrentClient)),
            this.store.pipe(select(selectCurrentProgram))
          )
        )
      }),
      concatMap(([[allMetrics, props], existingMetrics, client, structure]) => {
        const actions = []
        const sectorAssumptions: SectorAssumptionLookupResponse = {
          // tslint:disable-next-line: no-non-null-assertion
          roeSector: client!.roe_sector,
          // tslint:disable-next-line: no-non-null-assertion
          epsVolatilitySector: client!.esp_volatility_sector,
          // tslint:disable-next-line: no-non-null-assertion
          intercept: client!.intercept,
        }
        const portfolioUserInputs: PortfolioUserInputsResponse = {
          // tslint:disable-next-line: no-non-null-assertion
          bookValue: client!.book_value,
          // tslint:disable-next-line: no-non-null-assertion
          prospectiveROE: client!.prospective_roe,
          // tslint:disable-next-line: no-non-null-assertion
          epsVolatility: client!.eps_volatility,
          // tslint:disable-next-line: no-non-null-assertion
          taxRate: client!.tax_rate,
        }
        const rss = calculateRSS(
          sectorAssumptions,
          portfolioUserInputs,
          allMetrics.cededPortfolioViewDetailMetrics
            .lossNetAggregateTermsPremiumReinstatementAEPParticipation.mean,
          Math.sqrt(
            allMetrics.grossPortfolioViewDetailMetrics
              .lossNetAggregateTermAEPParticipation.variance
          ),
          Math.sqrt(
            allMetrics.netPortfolioViewDetailMetrics
              .lossNetAggregateTermAEPParticipation.variance
          )
        )
        let structureTailMetricsOptions: PortfolioMetrics | undefined
        if (structure?.tailMetricsOptions) {
          structureTailMetricsOptions = JSON.parse(structure.tailMetricsOptions)
          // tslint:disable-next-line: no-non-null-assertion
          structureTailMetricsOptions!.returnPeriodData = []
        }
        let fetchPortfolioViewMetricAction = fetchPortfolioViewMetrics(
          defaultPortfolioViewMetricsPayload(props, props.netPortfolioViewID)
        )
        if (structureTailMetricsOptions && !existingMetrics) {
          fetchPortfolioViewMetricAction = fetchPortfolioViewMetrics(
            constructPortfolioViewMetricsPayload(
              props,
              this.getId(props, structureTailMetricsOptions),
              structureTailMetricsOptions
            )
          )
        } else if (existingMetrics) {
          fetchPortfolioViewMetricAction = fetchPortfolioViewMetrics(
            constructPortfolioViewMetricsPayload(
              props,
              this.getId(props, existingMetrics),
              existingMetrics
            )
          )
        }
        actions.push(
          fetchPortfolioViewDetailMetricsSuccess({
            // tslint:disable-next-line: no-non-null-assertion
            ...props,
            cededCalculatedMetrics: calculatePortfolioViewDetailMetrics(
              allMetrics,
              'Ceded'
            ),
            grossCalculatedMetrics: calculatePortfolioViewDetailMetrics(
              allMetrics,
              'Gross'
            ),
            netCalculatedMetrics: calculatePortfolioViewDetailMetrics(
              allMetrics,
              'Net'
            ),
          }),
          fetchPortfolioViewMetricAction,
          updateRSS({ ...props, rss }),
          fetchTailMetrics(props)
        )
        return actions
      })
    )
  })

  private getId(result: PortfolioViewID, metrics: PortfolioMetrics) {
    if (metrics.portfolioType === 'Ceded') {
      return result.cededPortfolioViewID
    } else if (metrics.portfolioType === 'Gross') {
      return result.grossPortfolioViewID
    } else {
      return result.netPortfolioViewID
    }
  }
}
