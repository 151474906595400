<app-theme-container>
  <app-program-initiation
    [accountOpportunities]="accountOpportunities$ | async"
    [carrierAccounts]="carrierAccounts$ | async"
    [isSaving]="isSaving$ | async"
    [carriersByID]="carriersByID$ | async"
    [allCarriers]="allCarriers$ | async"
    [unfilteredAccountOpportunities]="
      unfilteredAccountOpportunities$ | async
    "
    [currentClientID]="currentClientID$ | async"
    (newProgramCreate)="onNewProgramCreate($event)"
    (newYearProgramCreate)="onNewYearProgramCreate($event)"
    (newCarrierYearProgramCreate)="onNewCarrierYearProgramCreate($event)"
    (filteredAccountOpportunities)="filterAccountOpportunities($event)"
    (updateSFMapping)="onUpdateSFMapping($event)"
  ></app-program-initiation>
</app-theme-container>
