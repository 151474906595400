<div class="wrapper">
  <label
    class="no-data-label"
    *ngIf="!pricingCurves.length"
    appInfoText
    big
    >Add data by dragging a curve from the left pane onto the graph,
    or click "+ Add Pricing Curve"</label
  >
  <div
    #chart
    id="chart"
    class="app-chart app-chart-tiny-annotation"
    (mousemove)="onMouseMove($event)"
  ></div>
</div>
