<button
  appCard
  details
  [size]="size"
  [description]="structure.description"
  [checkboxTooltip]="checkboxTooltip"
  [index]="index"
  [imageSrc]="imageURI"
  [libRE]="isLibRE"
  [libRETemplate]="isLibRETemplate"
  [checked]="checked"
  [selectMultiple]="selectMultiple || editFolderMode"
  [indicateFocus]="indicateFocus"
  [canCheck]="canCheck"
  (handleCheck)="onStructureCheckChange()"
  (click)="onStructureClick($event)"
>
  <span>{{ structure.label }}</span>
  <ng-template
    *ngIf="scenarios?.length || optimizations?.length"
    #titleActionTemplate
  >
    <app-checkbox-select
      *ngIf="scenarios?.length"
      class="scenarios"
      accent
      [label]="'Scenarios'"
      [items]="scenarios"
      [selected]="scenariosSelected"
      [parent]="structure"
      [allowSelection]="allowScenarioOrOptimizationSelection"
      [elevation]="elevation"
      (selectChange)="scenarioOrOptimizationSelectChange.emit($event)"
    ></app-checkbox-select>
    <app-checkbox-select
      *ngIf="optimizations?.length"
      class="scenarios"
      accent
      [label]="'Optimizations'"
      [items]="optimizations"
      [selected]="optimizationsSelected"
      [parent]="structure"
      [allowSelection]="allowScenarioOrOptimizationSelection"
      [elevation]="elevation"
      (selectChange)="scenarioOrOptimizationSelectChange.emit($event)"
    ></app-checkbox-select>
  </ng-template>
</button>

<!-- For libRE, display the building icon in the upper right -->
<mat-icon
  *ngIf="isLibRETemplate"
  gigantic
  color="primary"
  class="popover-button"
>
  domain
</mat-icon>

<!-- Hide the ... menu if this is a libRE template -->
<button
  #moreMenuButton
  *ngIf="showMoreMenu && !isLibRETemplate && !isLibRE"
  appButtonIcon
  gigantic
  class="popover-button"
  [matMenuTriggerFor]="moreMenu"
>
  more_horiz
</button>
<!-- Show the menu with fewer options is this is a regular libre -->
<button
  #moreMenuButton
  *ngIf="showMoreMenu && !isLibRETemplate && isLibRE"
  appButtonIcon
  gigantic
  class="popover-button"
  [matMenuTriggerFor]="moreMenuLibRE"
>
  more_horiz
</button>

<!-- The default menu items -->
<mat-menu
  class="app-menu app-menu-translucent"
  backdropClass="app-theme-dark"
  #moreMenu="matMenu"
  xPosition="before"
>
  <button mat-menu-item (click)="editClick.emit()">
    Edit Name & Description
  </button>
  <button mat-menu-item (click)="onStructureClick($event)">Design</button>
  <button mat-menu-item (click)="setRouter('group')">Open in Groups</button>
  <button mat-menu-item (click)="setRouter('compare')">Open in Compare</button>
  <button
    mat-menu-item
    (click)="removeFromFolderClick.emit()"
    *ngIf="!!structure.folderID"
  >
    Remove From Folder
  </button>
</mat-menu>

<!-- The menu items for libre items -->
<mat-menu
  class="app-menu app-menu-translucent"
  backdropClass="app-theme-dark"
  #moreMenuLibRE="matMenu"
  xPosition="before"
>
  <button mat-menu-item (click)="editClick.emit()">
    Edit Name & Description
  </button>
  <button mat-menu-item (click)="onStructureClick($event)">Design</button>
  <button
    mat-menu-item
    (click)="removeFromFolderClick.emit()"
    *ngIf="!!structure.folderID"
  >
    Remove From Folder
  </button>
</mat-menu>
