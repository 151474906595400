import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  SignatureContract,
  SignatureReinsurer,
  SignatureReinsurerReferenceType,
} from '../signature.model'
import { FotState } from '../store/fots/fot.reducer'
import {
  RiskRefDetail,
  SalesforceClient,
} from 'src/app/api/model/signature.model'
import { MatDialog } from '@angular/material/dialog'
import { SignatureSelectRiskRefDialogComponent } from './signature-select-riskref-dialog.component'
import { MatRadioChange } from '@angular/material/radio'

const RISKREF_DIALOG_ID = 'app-simple-contract-riskref-dialog-component'

@Component({
  selector: 'app-simple-contract',
  templateUrl: './simple-contract.component.html',
  styleUrls: ['./simple-contract.component.scss'],
})
export class SimpleContractComponent implements OnInit, OnChanges {
  @Input() contracts: SignatureContract[]
  @Input() selectedContract: SignatureContract
  @Input() reinsurers: SignatureReinsurer[]
  @Input() selectedReinsurer: SignatureReinsurer // Contains cedents information
  @Input() fotsToAdd: FotState[]
  @Input() fotsToRemove: FotState[]
  @Input() renewalRiskRefs: RiskRefDetail[]
  @Input() clients: SalesforceClient[]

  @Output() updateSignatureForm = new EventEmitter<{
    reinsurer: SignatureReinsurer
    applyToEntireFot?: {
      contractName?: boolean
      effectiveDate?: boolean
      cedents?: boolean
      layerName?: {
        index: number
      }
      layerType?: {
        index: number
      }
      riskRef?: boolean
      companyAlias?: boolean
    }
    updatedReferenceType?: SignatureReinsurerReferenceType
  }>()

  @Output() isCoRiskContractChangeEvent = new EventEmitter<boolean>()
  @Output() leadRiskRefChangeEvent = new EventEmitter<string | undefined>()

  constructor(private dialog: MatDialog) {}

  selectedRiskRef: string | undefined
  selectedLeadRiskRef: string | undefined
  isCoRiskContract = false
  coRiskOptions: string[] = ['Yes', 'No']

  ngOnInit(): void {
    this.selectedRiskRef = this.selectedReinsurer.riskRef
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedReinsurer || changes.selectedReinsurer.firstChange) {
      return
    }

    const updatedSelectedReinsurer: SignatureReinsurer =
      changes.selectedReinsurer.currentValue

    /* update riskref field */
    this.selectedRiskRef = updatedSelectedReinsurer.riskRef
  }

  removeRiskRef(): void {
    this.updateSignatureForm.emit({
      reinsurer: {
        ...this.selectedReinsurer,
        riskRef: undefined,
      },
      applyToEntireFot: {
        riskRef: true,
      },
    })
  }

  removeLeadRiskRef(): void {
    this.selectedLeadRiskRef = undefined
  }

  onCoRiskRadioChange(radioChange: MatRadioChange): void {
    this.isCoRiskContract =
      radioChange && radioChange.value === 'Yes' ? true : false
    this.isCoRiskContractChangeEvent.emit(this.isCoRiskContract)
  }

  onRiskRefSelectClick(): void {
    const dialogRef = this.dialog.open(SignatureSelectRiskRefDialogComponent, {
      id: RISKREF_DIALOG_ID,
      width: '45%',
      data: {
        renewalRiskRefs: this.renewalRiskRefs,
      },
      backdropClass: 'backdropBackground',
    })

    dialogRef.afterClosed().subscribe((selectedRiskRef: string) => {
      if (selectedRiskRef) {
        this.selectedRiskRef = selectedRiskRef

        this.updateSignatureForm.emit({
          reinsurer: {
            ...this.selectedReinsurer,
            riskRef: selectedRiskRef,
          },
          applyToEntireFot: {
            riskRef: true,
          },
        })
      }
    })
  }

  onLeadRiskRefSelectClick(): void {
    const dialogRef = this.dialog.open(SignatureSelectRiskRefDialogComponent, {
      id: RISKREF_DIALOG_ID,
      width: '45%',
      data: {
        renewalRiskRefs: this.renewalRiskRefs,
      },
      backdropClass: 'backdropBackground',
    })

    dialogRef.afterClosed().subscribe((selectedRiskRef: string) => {
      if (selectedRiskRef) {
        this.selectedLeadRiskRef = selectedRiskRef
        this.leadRiskRefChangeEvent.emit(this.selectedLeadRiskRef)
      }
    })
  }
}
