import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../core/store'
import { LayerView } from '../../model/layer-view'
import {
  selectCededLayers,
  selectLossSetGroups,
  selectLossSetLayers,
} from '../../store/analysis.selectors'
import {
  Change,
  inputRangeFromLayerView,
  OptimizationCandidateResult,
  ResultsView,
  OptimizationCandidateLayer,
  isAggLayerOptimization,
  OptimizationRangesTypes,
  OptimizationInputRangeChangeEvent,
  LossSetGroupIDsChangeEvent,
  CandidateLayerChangeEvent,
  getOptimizationType,
  OptimizationPortfolioTailMetrics,
  OptimizationPortfolioTailMetricsPayload,
  OptimizationInputCurrencyChangeEvent,
} from '../optimization.model'
import {
  generateCandidates,
  getCandidatesPortfolioMetrics,
  save,
  setChartDimensionProp,
  setView,
  updateCandidates,
} from '../store/optimization-candidates-results.actions'
import {
  selectChartDimensionState,
  selectChartView,
  selectLastCreated,
  selectCandidatesResults,
  selectCandidatesResultsView,
  selectSaving,
  selectSavingError,
  selectLayersMetricsError,
  selectCandidateLayers,
  selectCandidateLayersLoading,
  selectLayersMetricsLoading,
  selectCandidateLayersError,
  selectRangesTypes,
  selectPortfolioTailMetrics,
} from '../store/optimization.selectors'
import { reset } from '../store/optimization.actions'
import { LayerModelingState } from '../../layer-modeling/store/layer-modeling.reducer'
import {
  LayerModelingDimensionChangeEvent,
  LayerModelingView,
} from '../../layer-modeling/layer-modeling.model'
import {
  generateLayers,
  updateLayers,
} from '../store/optimization-layers.actions'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { isLayerAgg } from '../../model/layers.util'
import { LossSetGroup } from '../../model/loss-set-layers.model'
import {
  setLossSetGroupIDs,
  setRangesTypes,
  updateRangesTypes,
} from '../store/optimization-ranges-types.actions'
import { uniqBy } from 'ramda'
import {
  CurrencyCode,
  LossFilter,
} from '../../../api/analyzere/analyzere.model'
import {
  selectCurrencyList,
  selectCurrentLossFilters,
} from '../../../core/store/broker/broker.selectors'
import { updateAndFetchPortfolioTailMetrics } from '../store/optimization-tail-metrics.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-container',
  templateUrl: './optimization.container.html',
  styles: [ `
          :host {
            width: 80vw;
          }
    `
  ]
})
export class OptimizationContainerComponent implements OnInit {
  candidateLayers$: Observable<OptimizationCandidateLayer[]>
  candidateResultsMetrics$: Observable<OptimizationCandidateResult[]>
  rangesTypes$: Observable<OptimizationRangesTypes[]>
  candidateLayersLoading$: Observable<boolean>
  candidateLayersMetricsLoading$: Observable<boolean>
  currencyList$: Observable<CurrencyCode[]>
  resultsView$: Observable<'chart' | 'table'>
  chartState$: Observable<LayerModelingState>
  chartView$: Observable<LayerModelingView>
  metricsError$: Observable<string | null>
  candidatesLayersError$: Observable<string | null>
  saving$: Observable<boolean>
  savingError$: Observable<string | null>
  lastCreated$: Observable<number>
  lossSetGroups$: Observable<LossSetGroup[]>
  lossFilters$: Observable<LossFilter[] | undefined>
  portfolioTailMetrics$: Observable<OptimizationPortfolioTailMetrics>

  view: LayerView[]
  private destroy$ = new Subject()

  constructor(
    private dialogRef: MatDialogRef<OptimizationContainerComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.lastCreated$ = this.store.pipe(select(selectLastCreated))
    this.saving$ = this.store.pipe(select(selectSaving))
    this.savingError$ = this.store.pipe(select(selectSavingError))
    this.candidatesLayersError$ = this.store.pipe(
      select(selectCandidateLayersError)
    )
    this.metricsError$ = this.store.pipe(select(selectLayersMetricsError))
    this.chartView$ = this.store.pipe(select(selectChartView))
    this.chartState$ = this.store.pipe(select(selectChartDimensionState))
    this.resultsView$ = this.store.pipe(select(selectCandidatesResultsView))
    this.candidateLayers$ = this.store.pipe(select(selectCandidateLayers))
    this.currencyList$ = this.store.pipe(select(selectCurrencyList))

    this.candidateResultsMetrics$ = this.store.pipe(
      select(selectCandidatesResults)
    )
    this.candidateLayersLoading$ = this.store.pipe(
      select(selectCandidateLayersLoading)
    )
    this.candidateLayersMetricsLoading$ = this.store.pipe(
      select(selectLayersMetricsLoading)
    )
    this.lossSetGroups$ = this.store.pipe(select(selectLossSetGroups))

    this.rangesTypes$ = this.store.pipe(
      select(selectRangesTypes),
      withLatestFrom(
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectLossSetLayers))
      ),
      takeUntil(this.destroy$),
      filter(([ranges, layers, lossSetLayers]) => {
        if (ranges.length === 0) {
          const uniqueLayers = uniqBy(
            l =>
              getOptimizationType(l.layer.meta_data.sage_layer_type || '') +
              l.layer.meta_data.sage_layer_subtype,
            layers
          )
          const templateLayers: LayerState[] = []
          if (isAggLayerOptimization(uniqueLayers.map(l => l.layer))) {
            templateLayers.push(
              uniqueLayers.find(l => isLayerAgg(l.layer)) as LayerState
            )
          } else {
            templateLayers.push(...uniqueLayers)
          }
          this.view = templateLayers.map(
            t =>
              new LayerView(layers, t.layer, {
                lossSets: lossSetLayers,
              })
          )
          const rangesTypes = this.view.map(v => ({
            id: v.type || '',
            ranges: inputRangeFromLayerView(v),
          }))
          this.store.dispatch(
            setRangesTypes({
              rangesTypes,
            })
          )
          return false
        } else {
          return true
        }
      }),
      map(([ranges]) => {
        return ranges
      })
    )
    this.lossFilters$ = this.store.pipe(select(selectCurrentLossFilters))
    this.portfolioTailMetrics$ = this.store.pipe(
      select(selectPortfolioTailMetrics)
    )
  }

  onCloseClick() {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.store.dispatch(reset())
    this.dialogRef.close()
  }

  onRangeChange({ id, change }: OptimizationInputRangeChangeEvent) {
    this.store.dispatch(updateRangesTypes({ id, rangeChange: change }))
  }

  onCurrencyChange({
    id,
    currency,
    ranges,
  }: OptimizationInputCurrencyChangeEvent) {
    const range = ranges.find(r => r.id === id)
    const changes = []
    if (range) {
      for (const r of range.ranges) {
        let temp = { ...r }
        temp = { ...temp, currency: currency.code }
        changes.push(temp)
      }
    }
    for (const change of changes) {
      this.store.dispatch(
        updateRangesTypes({
          id,
          rangeChange: { id: change.id, changes: change },
        })
      )
    }
  }

  onResetClick() {
    this.store.dispatch(reset())
  }

  onRunCombinations() {
    this.store.dispatch(generateLayers({ templates: this.view }))
  }

  onRangeResultValueChange({ changes, results }: CandidateLayerChangeEvent) {
    this.store.dispatch(updateCandidates({ changes }))
    if (
      changes.some(
        c =>
          c.changes.createPortfolio === true ||
          c.changes.createPortfolio === false
      )
    ) {
      changes.forEach(c => {
        if (
          c.changes.createPortfolio === true &&
          !results.find(r => r.id === c.id)?.netPortfolioViewID
        ) {
          this.store.dispatch(getCandidatesPortfolioMetrics({ id: c.id }))
        } else if (c.changes.createPortfolio === false) {
          this.store.dispatch(
            updateCandidates({
              changes: [
                {
                  id: c.id,
                  changes: {
                    cededPortfolioViewID: undefined,
                    netPortfolioViewID: undefined,
                    grossPortfolioViewID: undefined,
                  },
                },
              ],
            })
          )
        }
      })
    }
  }

  onRunLayersMetrics() {
    this.store.dispatch(generateCandidates())
  }

  onViewChange(view: ResultsView) {
    this.store.dispatch(setView({ view }))
  }

  onDimensionChange(event: LayerModelingDimensionChangeEvent) {
    this.store.dispatch(setChartDimensionProp(event))
  }

  onSave() {
    this.store.dispatch(save())
  }

  onCandidateLayerChange(changes: Change<OptimizationCandidateLayer>[]) {
    this.store.dispatch(updateLayers({ changes }))
  }

  onSelectedLossSetGroupIDsChange({ id, change }: LossSetGroupIDsChangeEvent) {
    this.store.dispatch(setLossSetGroupIDs({ id, values: change }))
  }

  onPortfolioReturnPeriodToggleChange(
    $event: OptimizationPortfolioTailMetricsPayload[]
  ) {
    $event.forEach(e => {
      this.store.dispatch(updateAndFetchPortfolioTailMetrics(e))
    })
  }
}
