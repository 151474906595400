<label *ngIf="loading" class="empty" appInfoText big> Loading... </label>
<label *ngIf="!loading && !dataGraph" class="empty" appInfoText big>
  There was an error on Graph Data
</label>
<ng-container *ngIf="!loading">
  <h1 mat-dialog-title>Distribution of Modeled Losses by Loss Type</h1>
  <ng-container>
    <div class="container">
      <div class="div-center">
        <div class="content-title" *ngFor="let item of items">
          <div class="color-label">
            <div
              class="title"
              [ngStyle]="{ background: this.getColors(item.label) }"
            ></div>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div id="my_dataviz" #graphContainer></div>
</ng-container>
