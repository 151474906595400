import { analyzereConstants } from '@shared/constants/analyzere'
import { partialRight, range } from 'ramda'
import { Layer, PhysicalLayer } from 'src/app/analysis/model/layers.model'
import {
  LogicalPortfolioLayer,
  LossSetLayer,
  Metadata,
} from 'src/app/api/analyzere/analyzere.model'
import { layerIds } from '../model/layer-palette.model'

export type SwingLayerSubtypes =
  | 'visible-layer'
  | 'combined-layer'
  | 'loss-layer'
  | 'premium-layer'
  | 'adjustment-layer'

type HasMetadata = { meta_data: Partial<Metadata> }

export const swingBasisInAddToMin = '1'
export const swingBasisSubToMin = '2'

export const isSwingLayer = <T>(
  { meta_data: m }: T & HasMetadata,
  ...subtypes: (SwingLayerSubtypes | Omit<string, SwingLayerSubtypes>)[]
): boolean =>
  (m.sage_layer_type === layerIds.noncatSwing ||
    m.sage_layer_type === layerIds.ahlSwing) &&
  (subtypes.length === 0 || subtypes.includes(m.sage_layer_subtype ?? ''))

export const isSwingCombinedLayer = partialRight<
  Parameters<typeof isSwingLayer>[0],
  string,
  boolean
>(isSwingLayer, ['combined-layer'])

export function createSwingSubLayer(
  layerDefaults: Layer,
  subtype: SwingLayerSubtypes,
  hidden = true
): Layer {
  return {
    ...layerDefaults,
    meta_data: {
      ...layerDefaults.meta_data,
      sage_layer_subtype: subtype,
      hidden,
    },
    physicalLayer: {
      ...layerDefaults.physicalLayer,
      meta_data: {
        ...layerDefaults.physicalLayer.meta_data,
        sage_layer_subtype: subtype,
        // The visible sage layer also needs the swing properties
        ...(subtype === 'visible-layer'
          ? getSwingSubLayerMetadata(layerDefaults, subtype)
          : {}),
      },
    },
  }
}

function getSwingSubLayerMetadata(
  layer: Layer,
  subType: SwingLayerSubtypes
): Partial<Metadata> {
  const baseValues: Partial<Metadata> = {
    swing_rate: 1,
    swing_basis: '1',
  }
  if (layer.physicalLayer.meta_data.sage_layer_type === layerIds.noncatSwing) {
    return {
      ...baseValues,
      min_rate_subject: 0,
      max_rate_subject: 1,
    }
  } else {
    return {
      ...baseValues,
      min_rate_pmpm: 0,
      max_rate_pmpm: 100,
    }
  }
}

export function createSwingCombinedLayer(
  layerDefaults: Layer,
  lossId: string,
  premiumId: string,
  adjustmentId: string,
  visibleId: string,
  currency: string
): Layer {
  return {
    ...layerDefaults,
    lossSetLayers: [],
    layerRefs: [lossId, premiumId, adjustmentId],
    meta_data: {
      ...layerDefaults.meta_data,
      sage_layer_subtype: 'combined-layer',
      hidden: true,
      loss_layer_id: lossId,
      premium_layer_id: premiumId,
      adjustment_layer_id: adjustmentId,
      visible_layer_id: visibleId,
    },
    physicalLayer: {
      ...layerDefaults.physicalLayer,
      type: 'QuotaShare',
      aggregateAttachment: {
        value: 0,
        currency,
      },
      aggregateLimit: {
        value: analyzereConstants.unlimitedValue,
        currency,
      },
      attachment: {
        value: 0,
        currency,
      },
      limit: {
        value: analyzereConstants.unlimitedValue,
        currency,
      },
      participation: layerDefaults.physicalLayer.participation,
      event_limit: {
        value: analyzereConstants.unlimitedValue,
        currency,
      },
      meta_data: {
        ...layerDefaults.physicalLayer.meta_data,
        sage_layer_subtype: 'combined-layer',
      },
      description: null,
    },
  }
}

export function asSwingLayer(
  logicalLayer: LogicalPortfolioLayer,
  physicalLayer: PhysicalLayer
): Layer {
  const sources = logicalLayer.sources as LossSetLayer[]
  const lossSets = sources.map(source => ({
    id: source.id,
    loss_sets: source.loss_sets,
    meta_data: source.meta_data,
  }))

  return {
    id: logicalLayer.id,
    modified_date: logicalLayer.modified,
    lossSetLayers: lossSets,
    layerRefs: [],
    physicalLayer,
    meta_data: logicalLayer.meta_data,
    sharedLayerID: '',
    viewMetrics: {
      loading: false,
      error: null,
      metrics: null,
      rss: null,
    },
  }
}

export function getSwingIds<T>({
  meta_data,
}: T & HasMetadata): [string, string, string] {
  const lossLayerId = meta_data.loss_layer_id
  if (lossLayerId === undefined) {
    throw new Error(`Property loss_layer_id missing in swing layer metadata.`)
  }

  const premiumLayerId = meta_data.premium_layer_id
  if (premiumLayerId === undefined) {
    throw new Error(
      `Property premium_layer_id missing in swing layer metadata.`
    )
  }

  const adjustmentLayerId = meta_data.adjustment_layer_id
  if (adjustmentLayerId === undefined) {
    throw new Error(
      `Property adjustment_layer_id missing in swing layer metadata.`
    )
  }

  return [lossLayerId, premiumLayerId, adjustmentLayerId]
}

export function updateSwingLayers(
  visibleLayer: Layer,
  subjectPremium: number,
  membersSum: number
): Partial<PhysicalLayer>[] {
  const isNoncat =
    visibleLayer.physicalLayer.meta_data.sage_layer_type ===
    layerIds.noncatSwing
  const physical = visibleLayer.physicalLayer
  const meta = physical.meta_data

  const minRate = (isNoncat ? meta.min_rate_subject : meta.min_rate_pmpm) ?? 0.0
  const maxRate = (isNoncat ? meta.max_rate_subject : meta.max_rate_pmpm) ?? 1.0
  const swingRate = meta.swing_rate ?? 1.0
  const swingBasis = meta.swing_basis ?? ''

  const rateValue = isNoncat ? subjectPremium : membersSum

  // Assume that all the displayed is consistent
  const currency = physical.aggregateAttachment.currency

  const swingAttachment =
    physical.aggregateAttachment.value +
    (swingBasis === swingBasisSubToMin
      ? (minRate * rateValue) / swingRate
      : 0)
  const swingLimit = ((maxRate - minRate) * rateValue) / swingRate

  const attachment = physical.attachment
  const limit = physical.limit
  const actualCession = -physical.participation
  const oppositeCession = physical.participation

  const numReinstatements = Math.ceil(
    ((maxRate - minRate) * rateValue) / swingRate / limit.value
  )

  const reinstatementPremium = tameInfinity(
    (swingRate * limit.value) / (minRate * rateValue)
  )

  const combinedUpdate: Partial<PhysicalLayer> = {
    premium: {
      value: minRate * rateValue,
      currency,
    },
    participation: oppositeCession,
    fees: physical.fees
  }

  const lossUpdate: Partial<PhysicalLayer> = {
    aggregateAttachment: physical.aggregateAttachment,
    aggregateLimit: physical.aggregateLimit,
    attachment,
    limit,
    participation: actualCession,
  }

  const premiumUpdate: Partial<PhysicalLayer> = {
    premium: {
      value: minRate * rateValue,
      currency,
    },
    aggregateAttachment: {
      value: swingAttachment,
      currency,
    },
    aggregateLimit: {
      value: swingLimit,
      currency,
    },
    attachment,
    limit,
    reinstatements: range(0, numReinstatements).map(_ => ({
      premium: reinstatementPremium,
      brokerage: 0.0,
    })),
    participation: actualCession,
  }

  const adjustmentUpdate: Partial<PhysicalLayer> = {
    aggregateAttachment: {
      value: swingAttachment,
      currency,
    },
    aggregateLimit: {
      value: swingLimit,
      currency,
    },
    attachment,
    limit,
    participation: oppositeCession,
  }

  return [combinedUpdate, lossUpdate, premiumUpdate, adjustmentUpdate]
}

function tameInfinity(n: number) {
  return Number.isFinite(n) ? n : 0
}
