import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromQuoteReducer from './quote.reducer'
import * as fromReinsurers from './reinsurer/reinsurer.reducer'
import * as fromSection from './section/section.reducer'
import { EXPIRING_REINSURER_NAME } from '../models/quote.model'

export const selectQuoteState = createFeatureSelector<fromQuoteReducer.State>(
  fromQuoteReducer.QUOTE_FEATURE_KEY
)

export const selectReinsurerState = createSelector(
  selectQuoteState,
  state => state.reinsurers
)

export const {
  selectAll: selectReinsurers,
  selectEntities: selectReinsurerDictionary,
} = fromReinsurers.adapter.getSelectors(selectReinsurerState)

export const selectReinsurerIDs = createSelector(
  selectReinsurerState,
  state => state.ids as string[]
)

export const selectedReinsurer = createSelector(
  selectReinsurerState,
  state => state.selected
)

export const selectedReinsurerEntity = createSelector(
  selectReinsurerState,
  selectedReinsurer,
  (state, selectedId) => {
    if (selectedId && state) {
      return state.entities[selectedId]?.reinsurer
    }
  }
)

export const isExpanded = createSelector(
  selectReinsurerState,
  state => state.isExpand
)

export const expandedName = createSelector(
  selectReinsurerState,
  state => state.expandedReinsurerName
)

export const assignedLineReName = createSelector(
  selectReinsurerState,
  state => state.assignedLineReName
)
export const assignedLineReId = createSelector(
  selectReinsurerState,
  state => state.assignedLineReId
)

export const quoteRiskSubjectivityLinks = createSelector(
  selectReinsurerState,
  state => state.quoteRiskSubjectivityLinks
)

export const selectSectionState = createSelector(
  selectQuoteState,
  state => state.sections
)

export const selectQuoteDirty = createSelector(selectReinsurerState, state => {
  let dirty = false
  Object.values(state.entities).forEach(v => {
    if (v?.dirty) {
      dirty = true
    }
  })
  return dirty
})

export const {
  selectAll: selectSections,
  selectEntities: selectSectionDictionary,
} = fromSection.adapter.getSelectors(selectSectionState)

export const selectSectionIDs = createSelector(
  selectSectionState,
  state => state.ids as string[]
)

export const selectSectionCurrencies = createSelector(selectSections, state =>
  state.map(s => s.section.layerCurrency)
)

export const selectQuoteStructureID = createSelector(
  selectQuoteState,
  state => state.structureID
)

export const selectQuoteCededLayerID = createSelector(
  selectQuoteState,
  state => state.cededLayerID
)

export const selectQuoteCededLayerName = createSelector(
  selectQuoteState,
  state => state.cededLayerName
)

export const selectQuoteCededLayerType = createSelector(
  selectQuoteState,
  state => state.cededLayerType
)

export const selectQuoteYearID = createSelector(
  selectQuoteState,
  state => state.yearID
)

export const selectQuoteSectionID = createSelector(
  selectQuoteState,
  state => state.sectionID
)

export const selectQuoteRiskID = createSelector(
  selectQuoteState,
  state => state.riskID
)

export const isSaving = createSelector(
  selectQuoteState,
  state => state.reinsurers.saving
)

export const selectQuoteStudyID = createSelector(
  selectQuoteState,
  state => state.studyID
)

export const selectQuoteStructureGroupID = createSelector(
  selectQuoteState,
  state => state.structureGroupID
)

export const selectQuoteStructureGroupName = createSelector(
  selectQuoteState,
  state => state.structureGroupName
)

export const selectQuoteIsGroupSelected = createSelector(
  selectQuoteState,
  state => state.isGroupSelected
)

export const selectBureaus = createSelector(
  selectQuoteState,
  state => state.bureaus
)

export const selectLoading = createSelector(
  selectQuoteState,
  state => state.isLoading
)

export const selectAutoBuildLoading = createSelector(
  selectQuoteState,
  state => state.isAutoBuildLoading
)

export const selectQuoteSharedLimitID = createSelector(
  selectQuoteState,
  state => state.sharedLimitID
)

export const selectQuoteSharedLimitName = createSelector(
  selectQuoteState,
  state => state.sharedLimitName
)

export const selectQuoteIsSLSelected = createSelector(
  selectQuoteState,
  state => state.isSLSelected
)

export const selectQuoteSharedLimitlayers = createSelector(
  selectQuoteState,
  state => state.sharedLimitLayers
)

export const selectQuoteSharedLimitPortfolioID = createSelector(
  selectQuoteState,
  state => state.sharedLimitPortfolioID
)

export const selectRenewedFromRisks = createSelector(
  selectQuoteState,
  state => state.renewedFromRisks
)

export const selectAllExpiringForThisLayer = createSelector(
  selectReinsurers,
  selectQuoteCededLayerID,
  (reinsurerStates, selectedLayerId) => {
    const expired = reinsurerStates.filter(
      r =>
        r.reinsurer.cededlayerID === selectedLayerId &&
        r.reinsurer.quoteReinsurerName === EXPIRING_REINSURER_NAME
    )
    return expired
  }
)

export const doesCurrentLayerHaveExpiring = createSelector(
  selectAllExpiringForThisLayer,
  expired => {
    return expired.length > 0
  }
)

export const selectTerritories = createSelector(
  selectQuoteState,
  state => state.territories
)

export const selectExternalVendor = createSelector(
  selectQuoteState,
  state => state.externalVendor
)

export const selecthasAutoBuildLayerDataChanged = createSelector(
  selectQuoteState,
  state => state.hasAutoBuildLayerDataChanged
)

export const selectAutoBuildSectionsForSelectedLayer = createSelector(
  selectQuoteState,
  state => state.autoBuildSectionsForSelectedLayer
)

// Quick Quote
export const selectSelectedField = createSelector(
  selectQuoteState,
  state => state.quickQuote.selectedField
)

export const selectAddOrUpdateData = createSelector(
  selectQuoteState,
  state => state.quickQuote.addOrUpdateData
)

export const selectAutoFill = createSelector(
  selectQuoteState,
  state => state.quickQuote.autoFill
)

export const selectWhitespaceSyncWarnings = createSelector(
  selectQuoteState,
  state => state.reinsurers.whitespaceSyncWarnings
)

// Quote Custom Comparisons
export const selectQuoteCustomCompareViews = createSelector(
  selectQuoteState,
  state => state.customCompareViews
)

export const selectQuoteSelectedCompareView = createSelector(
  selectQuoteState,
  state => state.selectedCompareView
)
