import { getChartSplitAndSize } from '../utils/quote-charts.util'
import {
  QuoteChartAxis,
  QuoteChartGridOption,
  QuoteChartInfo,
} from './quote-charts.model'

export type QuoteTrendsInfo = {
  scatterInfo: QuoteChartInfo
  stackedBarInfo: QuoteChartInfo
  percentStackedBarInfo: QuoteChartInfo
  stackedAreaInfo: QuoteChartInfo
}
export type QuoteTrendMetricsInfo = {
  riskAdjustedRateChange: QuoteChartAxis
  sumSignedOverplacementLines: QuoteChartAxis
  signedLimit: QuoteChartAxis
}
export type QuoteTrendGroupBysInfo = {
  reinsurer: QuoteChartAxis
  inceptionYear: QuoteChartAxis
  program: QuoteChartAxis
  layerName: QuoteChartAxis
  client: QuoteChartAxis
}
export const QUOTE_TRENDS_INFO: QuoteTrendsInfo = {
  scatterInfo: {
    name: 'Scatter',
    type: 'scatter',
    icon: 'scatter_plot',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Inception Year',
        columnName: 'inceptionDate',
        applicableCharts: [],
      },
    ],
  },
  stackedAreaInfo: {
    name: 'Stacked Area',
    type: 'stackedArea',
    icon: 'area_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Security Type',
        columnName: 'securityType',
        applicableCharts: [],
      },
      {
        name: 'Inception Year',
        columnName: 'inceptionDate',
        applicableCharts: [],
      },
    ],
  },
  stackedBarInfo: {
    name: 'Stacked Bar',
    type: 'stackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Inception Year',
        columnName: 'inceptionDate',
        applicableCharts: [],
      },
    ],
  },
  percentStackedBarInfo: {
    name: 'Percent Stacked Bar',
    type: 'percentStackedBar',
    icon: 'stacked_bar_chart',
    applicableSizes: [],
    applicableSplits: [
      {
        name: 'Inception Year',
        columnName: 'inceptionDate',
        applicableCharts: [],
      },
    ],
  },
}
export const QUOTE_TREND_METRICS_INFO: QuoteTrendMetricsInfo = {
  riskAdjustedRateChange: {
    name: 'Risk Adjusted Rate Change',
    columnName: 'riskAdjustedRateChange',
    format: 'percent',
    applicableCharts: [QUOTE_TRENDS_INFO.scatterInfo],
  },
  sumSignedOverplacementLines: {
    name: 'Sum Signed & Overplacement Lines',
    columnName: 'sumSignedOverplacementLines',
    format: 'decimal',
    metricPrimaryName: 'Sum Signed %',
    metricSecondaryName: 'Sum Overplacement %',
    applicableCharts: [
      QUOTE_TRENDS_INFO.stackedBarInfo,
      QUOTE_TRENDS_INFO.percentStackedBarInfo,
    ],
  },
  signedLimit: {
    name: 'Signed Limit',
    columnName: 'signedLimit',
    format: 'decimal',
    metricPrimaryName: 'Signed Limit',
    applicableCharts: [
      QUOTE_TRENDS_INFO.scatterInfo,
      QUOTE_TRENDS_INFO.stackedAreaInfo,
    ],
  },
}
export const QUOTE_TREND_GROUP_BYS_INFO: QuoteTrendGroupBysInfo = {
  reinsurer: {
    name: 'Reinsurer',
    columnName: 'reinsurer',
    applicableCharts: [],
  },
  inceptionYear: {
    name: 'Inception Year',
    columnName: 'inceptionDate',
    applicableCharts: [],
  },
  program: {
    name: 'Program',
    columnName: 'programName',
    applicableCharts: [],
  },
  client: {
    name: 'Client',
    columnName: 'clientName',
    applicableCharts: [],
  },
  layerName: {
    name: 'Layer Name',
    columnName: 'layerName',
    applicableCharts: [],
  },
}
export const DEFAULT_QUOTE_TRENDS: QuoteChartGridOption[] = [
  {
    index: 0,
    loading: false,
    highlight: true,
    chart: QUOTE_TRENDS_INFO.scatterInfo,
    metric: QUOTE_TREND_METRICS_INFO.riskAdjustedRateChange,
    groupBy: QUOTE_TREND_GROUP_BYS_INFO.reinsurer,
    ...getChartSplitAndSize(
      QUOTE_TRENDS_INFO.scatterInfo,
      QUOTE_TREND_GROUP_BYS_INFO.reinsurer
    ),
  },
  {
    index: 1,
    loading: false,
    highlight: false,
    chart: QUOTE_TRENDS_INFO.scatterInfo,
    metric: QUOTE_TREND_METRICS_INFO.riskAdjustedRateChange,
    groupBy: QUOTE_TREND_GROUP_BYS_INFO.program,
    ...getChartSplitAndSize(
      QUOTE_TRENDS_INFO.scatterInfo,
      QUOTE_TREND_GROUP_BYS_INFO.program
    ),
  },
  {
    index: 2,
    loading: false,
    highlight: false,
    chart: QUOTE_TRENDS_INFO.scatterInfo,
    metric: QUOTE_TREND_METRICS_INFO.riskAdjustedRateChange,
    groupBy: QUOTE_TREND_GROUP_BYS_INFO.layerName,
    ...getChartSplitAndSize(
      QUOTE_TRENDS_INFO.scatterInfo,
      QUOTE_TREND_GROUP_BYS_INFO.layerName
    ),
  },
  {
    index: 3,
    loading: false,
    highlight: false,
    chart: QUOTE_TRENDS_INFO.scatterInfo,
    metric: QUOTE_TREND_METRICS_INFO.riskAdjustedRateChange,
    groupBy: QUOTE_TREND_GROUP_BYS_INFO.inceptionYear,
    ...getChartSplitAndSize(
      QUOTE_TRENDS_INFO.scatterInfo,
      QUOTE_TREND_GROUP_BYS_INFO.inceptionYear
    ),
  },
]
