import {
  MonetaryUnit,
  Metadata,
  Ref,
  LossSet,
  Fee,
} from 'src/app/api/analyzere/analyzere.model'
import { LayerRef } from './layers.model'

// TODO: Multiply defined in analyzere.model.ts

export interface LossSetLayer {
  id: string
  loss_sets: Ref[] | LossSet[] | LayerRef[]
  meta_data: Partial<Metadata>
  description: string
  premium: MonetaryUnit
  mean: number
  fees?: Fee[]
  isSelected?: boolean
}

/* type guard for loss_sets property of LossSetLayer */
export const isLossSet = (obj: Ref | LossSet | LayerRef): obj is LossSet => {
  return obj && typeof obj === 'object' && 'data' in obj
}

export interface LossSetGroup {
  id: string
  name: string
  programID: string
  lossSetLayers: LossSetLayer[]
  isSelected?: boolean
}

export interface LossSetGroupMember {
  id: string
  name: string
  lossSetGroupID: string
  lossSetLayerID: string
}

export interface LossSetGroupAndMembers {
  lossSetGroup: LossSetGroup
  lossSetGroupMembers: LossSetGroupMember[]
  deletedMembers?: LossSetGroupMember[]
}

export interface ScaledLossSetProps {
  id: string
  description: string
  lossScaleFactor: number
  originalPremium: number
  premiumScaleFactor: number
  newPremium: number
  newExpenseRatio: number
  dirty?: boolean
  lossSetID: string
  name?: string
  ls_dim1?: string
  ls_dim2?: string
  perspective?: string
  program_name?: string
  sage_layer_type?: string
  min_loss?: number
  avg_annual_loss?: number
  max_loss?: number
  num_losses?: number
  non_zero_losses?: number
  map1?: string
  map2?: string
  map3?: string
  map4?: string
  map5?: string
}

export interface ScaledLossDialogOutput {
  action: string
  props: ScaledLossSetProps[]
}
