<button appButtonIcon big class="closeX clear" (click)="destroyDialog()">
  close
</button>
<app-quote-export-dialog-component
  [reinsurers]="reinsurers$ | async"
  [compareViews]="compareViews$ | async"
  [sections]="sections$ | async"
  [layers]="layers$ | async"
  (closeDialog)="destroyDialog($event)"
></app-quote-export-dialog-component>
