import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from 'src/app/nav.service'
import {
  PricingCurveContextTypes,
  PricingCurveGraphSettings,
  PricingCurveStatus,
} from '../../../model/pricing-curve.model'
import * as fromActions from '../../../store/pricing-curve.actions'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { PricingCurveDialogService } from '../../dialog/pricing-curve-dialog.service'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-header',
  styleUrls: ['./pricing-curve-header.component.scss'],
  templateUrl: 'pricing-curve-header.component.html',
})
export class PricingCurveHeaderComponent {
  @Input() name: string
  @Input() status: PricingCurveStatus
  @Input() pricingCurves: PricingCurve[]
  @Input() context: PricingCurveContextTypes
  @Input() graphSettings: PricingCurveGraphSettings
  @Input() readonly: boolean

  constructor(
    public navService: NavService,
    private router: Router,
    private store: Store<AppState>,
    private dialogService: PricingCurveDialogService
  ) {}

  get dataDialogText(): string {
    return this.readonly ? 'View Curves/Data' : '+ Add Pricing Curve'
  }

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }

  onAddClick(): void {
    if (this.status.isLoading) {
      return
    }
    // Dispatch action to load selectors and layers
    this.store.dispatch(
      fromActions.addDataDialogOpened({ context: this.context })
    )
    const addDialogRef = this.dialogService.openAddDataDialog(
      {},
      {
        startingTab: 'layers',
        isEdit: false,
        tabsToHide: { layers: false, manual: false, saved: false },
      }
    )
    addDialogRef.afterClosed().subscribe(() => {
      // Dispatch data dialog closed to reset state
      this.store.dispatch(fromActions.addDataDialogClosed({}))
    })
  }

  onExportClick(): void {
    if (this.status.isLoading || !this.pricingCurves?.length) {
      return
    }
    this.dialogService.openExportPreviewDialog()
  }

  onGraphSettingsClick(): void {
    if (this.status.isLoading) {
      return
    }
    this.dialogService.openGraphSettingsDialog(
      { width: '24rem' },
      {
        currentGraphSettings: this.graphSettings,
      }
    )
  }
}
