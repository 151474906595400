import { FormControl } from '@angular/forms'
import { SalesforceUser, User } from 'src/app/api/model/backend.model'
import { IControl } from 'src/app/contact-support/model/contact-support.model'

// validation for comma separated emails
export function customEmailValidators() {
  return (control: FormControl) => {
    const controlValue = control.value
    if (!controlValue) {
      return null
    }
    const additionalEmails = controlValue
      .split(',')
      .map((email: string) => email.trim())
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValid = additionalEmails.every((email: string) =>
      emailRegex.test(email)
    )
    return isValid ? null : { invalidEmails: true }
  }
}

export function getUserSelector(
  userSelector: IControl,
  userListData: SalesforceUser[]
): IControl {
  let updatedselector = JSON.parse(JSON.stringify(userSelector))
  updatedselector.values = userListData.map(x => x.email)
  updatedselector.allValues = userListData.map(x => x.email)
  return updatedselector
}
