import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import {
  SummaryChartInfo,
  SummaryChartGridOption,
  SummaryChartGroupedDataBySplit,
} from '../summary-charts-model'
import html2canvas from 'html2canvas'
import { parseDataWithSplit } from 'src/app/quote/management-information/utils/quote-charts.util'
import {
  VerticalStackedAreaChart,
  VerticalStackedAreaDatum,
} from '@graphing/vertical-stacked-area-chart'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-percent-stacked-area-chart',
  template: `
    <app-explore-summary-chart-grid-item-frame
      #grid
      class="chart"
      [chartOption]="chartOption"
      [hasData]="chartOption.data?.data.length > 0"
      [selectableChartsForTab]="selectableChartsForTab"
      [selectableChartsForMetric]="selectableChartsForMetric"
      [maximizedIndex]="maximizedIndex"
      [canHideMetrics]="false"
      [hideMetrics]="hideMetrics"
      (updateHideMetrics)="onUpdateHideMetrics($event)"
      (chartOptionUpdate)="chartOptionUpdate.emit($event)"
      (maximizeClick)="maximizeClick.emit($event)"
      (onExportClick)="downloadPNG()"
    >
      <div
        id="chart"
        #chart
        [ngClass]="{
          'app-chart': true,
          'app-chart-tiny-annotation': chartOption.index !== maximizedIndex,
          'white-background': lightChartMode,
        }"
      ></div>
    </app-explore-summary-chart-grid-item-frame>
  `,
  styles: [
    `
      .app-chart-tiny-annotation {
        display: flex;
        height: 100%;
        width: 100%;
        flex-shrink: 0;
      }
      svg.app-chart-tiny-annotation {
        height: 100%;
        width: 100%;
      }
      .app-chart {
        background-color: black;
        padding: 25px 10px;
      }
      .white-background {
        color: black;
        background-color: white !important;
      }
    `,
  ],
})
export class ExploreSummaryPercentStackedAreaChartComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges
{
  @Input() chartOption: SummaryChartGridOption
  @Input() maximizedIndex: number
  @Input() chartEntityLimit: number
  @Input() selectableChartsForTab: SummaryChartInfo[]
  selectableChartsForMetric: SummaryChartInfo[]
  @Input() drawDebounce = 250
  @Input() hideMetrics: boolean
  @Input() lightChartMode: boolean

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()

  @ViewChild('chart', { static: false }) chartEl: ElementRef<HTMLDivElement>
  @ViewChild('grid', { static: false }) gridEl: ElementRef<HTMLDivElement>

  private chart: VerticalStackedAreaChart
  private draw$ = new Subject<void>()
  private destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.draw$
      .pipe(takeUntil(this.destroy$), debounceTime(this.drawDebounce))
      .subscribe(() => this.draw())
  }

  ngAfterViewInit(): void {
    this.selectableChartsForMetric = this.chartOption.metric.applicableCharts
    this.draw$.next()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideMetrics || changes.lightChartMode) {
      this.draw$.next()
    }
    if (changes.chartEntityLimit || changes.maximizedIndex) {
      setTimeout(() => this.draw(), 500)
    }
  }

  onUpdateHideMetrics(hideMetrics = false): void {
    this.updateHideMetrics.emit(hideMetrics)
  }

  downloadPNG(): void {
    const pngEl: HTMLElement | null = document.querySelector('#chart')
    if (pngEl) {
      html2canvas(pngEl).then(canvas => {
        const image = canvas.toDataURL('image/png')
        const yLabel = this.chartOption.metric.name
        const chartLabel = `${yLabel}`
        const a = document.createElement('a')
        a.setAttribute('download', `${chartLabel}.png`)
        a.setAttribute('href', image)
        a.click()
      })
    }
  }

  private draw(): void {
    if (!this.chartEl) {
      return
    }
    const min = this.chartOption.index !== this.maximizedIndex
    const metricName = this.chartOption.metric.name
    const groupByName = ''
    const chartLabel = this.chartOption.metric.name
    this.chart = new VerticalStackedAreaChart(
      this.chartEl.nativeElement,
      {
        xLabel: !min ? groupByName : undefined,
        yLabel: !min ? metricName : undefined,
        chartLabel: !min ? chartLabel : undefined,
        min,
        lightChartMode: this.lightChartMode,
      }
    )
    this.chart.draw(this.chartOption.data?.data as VerticalStackedAreaDatum[],)
  }
}
