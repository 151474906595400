import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { AnalysisStartProps } from '../analysis.actions'
import { PortfolioSetAndStudyIDs } from '../../model/portfolio-set.model'

export const fetchPortfolio = createAction(
  '[Ceded Portfolio] Fetch',
  props<{ id: string; analysisStartProps: AnalysisStartProps }>()
)

export const fetchPortfolioSuccess = createAction(
  '[Ceded Portfolio] Fetch Success',
  props<{
    id: string
    name: string
    description: string
    client: string
    programName: string
    year: string
    perspective: string
  }>()
)

export const fetchPortfolioFailure = createAction(
  '[Ceded Portfolio] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const updateTierPathForPortfolio = createAction(
  '[Ceded Portfolio] Update Tier Path for Portfolio',
  props<{ portfolioSet: PortfolioSetAndStudyIDs, structureId: string }>()
)
