import {
  selectAllUndeletedMembersByParentID,
  selectGroupHasGroupAgg,
  selectRootProgramGroupMemberEntities,
} from './grouper.selectors'
import {
  DEFAULT_ORDINAL,
  ProgramGroupMemberEntity,
  ROOT_ID,
} from './program-group-member/program-group-member.reducer'
import {
  GroupBar,
  GroupBarEdge,
  GrouperSlide,
  GrouperState,
  LabelPos,
  ProgramGroupMember,
} from './program-group.model'
import { ProgramGroupEntity } from './program-group/program-group.reducer'

interface MemberInfo extends ProgramGroupMember {
  isGroupAgg: boolean
  leftOrdinal?: number
  rightOrdinal?: number
}

interface GroupInfo {
  group: ProgramGroupEntity
  memberInfo: MemberInfo
  start: number
  end: number
  labelPos?: LabelPos
  paletteIndex: number
  leftmostOrdinal: number
  rightmostOrdinal: number
  minimized: boolean
}

interface ProgramInfo {
  id: string
  memberInfo: MemberInfo
  parents: string[]
  minimized: boolean
  groupMinimizedIndex?: number
}

function getSlideID(memberInfo: MemberInfo): string {
  if (memberInfo.isGroupAgg) {
    return memberInfo.id
  }
  if (!memberInfo.programID) {
    throw Error(
      'Unexpected error creating grouper slide; ' +
        'no program ID found while getting slide ID.'
    )
  }
  return (
    memberInfo.programID +
    (memberInfo.parentGroupID &&
    !memberInfo.parentGroupID.startsWith('untitled')
      ? '_' + memberInfo.parentGroupID
      : '')
  )
}

function getGroupEntity(
  memberInfo: MemberInfo,
  state: GrouperState
): ProgramGroupEntity {
  if (!memberInfo.programGroupID) {
    throw Error(
      'Unexpected error creating grouper slide; ' +
        'no program group ID found while getting group.'
    )
  }
  const group = state.programGroups.entities[memberInfo.programGroupID]
  if (!group) {
    throw Error(
      'Unexpected error creating grouper slide; ' +
        'no program group found while getting group.'
    )
  }
  if (group.deleted) {
    throw Error(
      'Unexpected error creating grouper slide; ' +
        'program group found was deleted.'
    )
  }
  return group
}

const createGroupInfo = (
  group: ProgramGroupEntity,
  memberInfo: MemberInfo,
  paletteIndex: number,
  childMembers: MemberInfo[],
  minimized = false
): GroupInfo => ({
  group,
  memberInfo,
  start: -1,
  end: -1,
  leftmostOrdinal: childMembers
    .filter(m => !m.isGroupAgg)
    .reduce((acc, m) => {
      const ordinal = m.ordinal
      return acc < m.ordinal ? acc : ordinal
    }, Number.MAX_SAFE_INTEGER),
  rightmostOrdinal: childMembers
    .filter(m => !m.isGroupAgg)
    .reduce((acc, m) => {
      const ordinal = m.ordinal
      return acc > m.ordinal ? acc : ordinal
    }, 0),
  paletteIndex,
  minimized,
})

const updateGroupInfoIndices = (
  index: number,
  map: Record<string, GroupInfo>
) => (id: string): void => {
  if (map[id].start === -1) {
    map[id].start = index
  }
  map[id].end = index
}

const createMemberInfos = (members: ProgramGroupMemberEntity[]): MemberInfo[] =>
  members.map((m, i) => ({
    ...m.programGroupMember,
    isGroupAgg: false,
    leftOrdinal:
      i === 0 ? undefined : members[i - 1].programGroupMember.ordinal,
    rightOrdinal:
      i === members.length - 1
        ? undefined
        : members[i + 1].programGroupMember.ordinal,
  }))

function getGroupLabelPos(
  index: number,
  groupInfo: GroupInfo,
  programInfos: ProgramInfo[]
): readonly [number, LabelPos] {
  // Available label indices are with the group index bounds and not minimized
  const indices = programInfos.reduce(
    (acc, p, i) =>
      i >= groupInfo.start &&
      i <= groupInfo.end &&
      !p.minimized &&
      p.groupMinimizedIndex == null
        ? [...acc, i]
        : acc,
    [] as number[]
  )
  const span = indices.length
  if (span === 0) {
    return [groupInfo.start, groupInfo.start === index ? 'left' : 'none']
  }
  const spanEven = span % 2 === 0
  const labelIndex = indices[Math.floor(span / 2)]
  const labelPos =
    labelIndex === index ? (spanEven ? 'left' : 'center') : 'none'
  return [labelIndex, labelPos] as const
}

function getDropOridinals(info: ProgramInfo) {
  const ordinal = info.memberInfo.ordinal
  const leftOrdinal = info.memberInfo.leftOrdinal || 0
  const rightOrdinal = info.memberInfo.rightOrdinal
  const dropLeftOrdinal = (leftOrdinal + ordinal) / 2
  const dropRightOrdinal = rightOrdinal
    ? (rightOrdinal + ordinal) / 2
    : ordinal + DEFAULT_ORDINAL
  return { dropLeftOrdinal, dropRightOrdinal }
}

function createSlide(
  programInfo: ProgramInfo,
  index: number,
  partialGroupBars: Array<GroupBar | null>,
  groupBarCount: number
): GrouperSlide {
  // Include undefined for any bar item up to the rank count
  const groupBars = [...Array(groupBarCount)].map((_, j) => partialGroupBars[j])
  const { dropLeftOrdinal, dropRightOrdinal } = getDropOridinals(programInfo)
  const groupMinimizedIndex = groupBars.findIndex(g => g && g.minimized)

  return {
    id: programInfo.id,
    groupID: programInfo.memberInfo.parentGroupID,
    index,
    groupBars,
    ordinal: programInfo.memberInfo.ordinal,
    dropLeftOrdinal,
    dropRightOrdinal,
    isGroupAgg: programInfo.memberInfo.isGroupAgg,
    minimized: programInfo.minimized,
    groupMinimizedIndex:
      groupMinimizedIndex >= 0 ? groupMinimizedIndex : undefined,
    programID: programInfo.memberInfo.programID,
  }
}

const createSlideAndGroupBars = (
  barCount: number,
  groupInfoMap: Record<string, GroupInfo>
) => (
  programInfo: ProgramInfo,
  index: number,
  programInfos: ProgramInfo[]
): GrouperSlide => {
  let hasMinimized = false
  const bars = programInfo.parents.map(parent => {
    if (hasMinimized) {
      return null
    }
    // Create the slide's group bar for each parent
    const groupInfo = groupInfoMap[parent]

    const first = groupInfo.start === index
    const last = groupInfo.end === index

    const [labelIndex, labelPos] = getGroupLabelPos(
      index,
      groupInfo,
      programInfos
    )
    const groupBar: GroupBar = {
      id: groupInfo.group.programGroup.id,
      ordinal: groupInfo.memberInfo.ordinal,
      addLeftOrdinal: groupInfo.leftmostOrdinal / 2,
      addRightOrdinal: groupInfo.rightmostOrdinal + DEFAULT_ORDINAL,
      paletteIndex: groupInfo.paletteIndex,
      label: groupInfo.group.programGroup.label,
      labelPos,
      labelIndex,
      untitled: groupInfo.group.untitled,
      first,
      firstIndex: groupInfo.start,
      last,
      lastIndex: groupInfo.end,
      leftEdges: [],
      rightEdges: [],
      minimized: groupInfo.minimized,
    }
    if (groupInfo.minimized) {
      hasMinimized = true
    }
    return groupBar
  })
  return createSlide(programInfo, index, bars, barCount)
}

const getNonNullGroupBars = (groupBars: Array<GroupBar | null>): GroupBar[] =>
  groupBars.filter(g => g != null) as GroupBar[]

function addGroupEdge(
  group: GroupBar,
  groupIndex: number,
  edges: GroupBarEdge[],
  slides: GrouperSlide[],
  slideIndex: number,
  side: 'left' | 'right'
): GroupBarEdge[] {
  // Create an edge for the group
  const pastEdgeIncrement = side === 'left' ? -1 : 1
  let dropOrdinal: number | undefined =
    side === 'left' ? group.ordinal / 2 : group.ordinal + DEFAULT_ORDINAL
  let dropGroupID: string | undefined = ROOT_ID

  if (group.untitled) {
    // Untitled groups cannot allow dropping directly past their edge
    dropGroupID = undefined
    dropOrdinal = undefined
  } else if (groupIndex > 0) {
    // We have parent groups
    if (edges.length === 0) {
      // No parents have edges, so there must be a slide to the side of it
      const nextSlide = slides[slideIndex + pastEdgeIncrement]
      const nextGroup = nextSlide.groupBars[groupIndex]
      // If there is a sibling group directly to the side, position between
      // its ordinal; else, use the ordinal of the program
      dropOrdinal = ((nextGroup || nextSlide).ordinal + group.ordinal) / 2
    }
    const parentGroup = slides[slideIndex].groupBars[groupIndex - 1]
    if (parentGroup) {
      dropGroupID = parentGroup.id
    }
  } else if (
    (side === 'left' && slideIndex !== 0) ||
    (side === 'right' && slideIndex !== slides.length - 1)
  ) {
    // Not the last slide
    const nextGroup = slides[slideIndex + pastEdgeIncrement].groupBars[0]
    if (nextGroup) {
      if (nextGroup.untitled) {
        // If next group is untitled, we should be added to it
        dropGroupID = nextGroup.id
        dropOrdinal =
          side === 'left' ? nextGroup.addRightOrdinal : nextGroup.addLeftOrdinal
      } else {
        dropOrdinal = (nextGroup.ordinal + group.ordinal) / 2
      }
    }
  }

  const edge: GroupBarEdge = {
    paletteIndex: group.paletteIndex,
    dropOrdinal,
    dropGroupID,
    minimized: group.minimized,
  }
  return side === 'left' ? [...edges, edge] : [edge, ...edges]
}

function getNextSlide(
  index: number,
  side: 'left' | 'right',
  slides: GrouperSlide[],
  skipGroupAggs = false
): GrouperSlide | undefined {
  let next: GrouperSlide | undefined
  if (
    (side === 'left' && index !== 0) ||
    (side === 'right' && index !== slides.length - 1)
  ) {
    const nextIndex = side === 'left' ? index - 1 : index + 1
    next = slides[nextIndex]
    if (skipGroupAggs && next && next.isGroupAgg) {
      next = getNextSlide(nextIndex, side, slides, skipGroupAggs)
    }
  }
  return next
}

function setSlideMoveOrdinalsAndGroupEdges(
  slide: GrouperSlide,
  index: number,
  slides: GrouperSlide[]
): void {
  let leftEdges: GroupBarEdge[] = []
  let rightEdges: GroupBarEdge[] = []

  const groupBars = getNonNullGroupBars(slide.groupBars)

  groupBars.forEach((g, gi) => {
    if (g.first) {
      leftEdges = addGroupEdge(g, gi, leftEdges, slides, index, 'left')
    }
    if (g.last) {
      rightEdges = addGroupEdge(g, gi, rightEdges, slides, index, 'right')
    }
    g.leftEdges = leftEdges
    g.rightEdges = rightEdges
  })

  // Calculate the ordinal and group ID when moving the program left
  const leftSlide = getNextSlide(index, 'left', slides)
  if (leftEdges.length === 0 && leftSlide) {
    if (getNonNullGroupBars(leftSlide.groupBars).length > groupBars.length) {
      // Next slide starts a new, deeper group that we want to be added to
      const groupBar = leftSlide.groupBars[groupBars.length] as GroupBar
      slide.moveLeftOrdinal = groupBar.addRightOrdinal
      slide.moveLeftGroupID = groupBar.id
    } else {
      // Next slide is a program in same group, so move to its left
      slide.moveLeftOrdinal = leftSlide.dropLeftOrdinal
      slide.moveLeftGroupID = leftSlide.groupID
    }
  } else {
    const closestEdge = leftEdges[leftEdges.length - 1]
    if (closestEdge.dropGroupID && closestEdge.dropOrdinal) {
      // If the closest edge has a drop point, use it
      slide.moveLeftOrdinal = closestEdge.dropOrdinal
      slide.moveLeftGroupID = closestEdge.dropGroupID
    } else if (leftSlide) {
      // Edge has no drop point, so put into left slide's first group
      const groupBar = leftSlide.groupBars[0] as GroupBar
      slide.moveLeftOrdinal = groupBar.addRightOrdinal
      slide.moveLeftGroupID = groupBar.id
    }
  }

  // Calculate the ordinal and group ID when moving the program right
  const rightSlide = getNextSlide(index, 'right', slides)
  if (rightEdges.length === 0 && rightSlide) {
    if (getNonNullGroupBars(rightSlide.groupBars).length > groupBars.length) {
      // Next slide starts a new, deeper group that we want to be added to
      const groupBar = rightSlide.groupBars[groupBars.length] as GroupBar
      slide.moveRightOrdinal = groupBar.addLeftOrdinal
      slide.moveRightGroupID = groupBar.id
    } else if (!rightSlide.isGroupAgg) {
      // Next slide is a program in same group, so move to its right
      slide.moveRightOrdinal = rightSlide.dropRightOrdinal
      slide.moveRightGroupID = rightSlide.groupID
    }
  } else {
    const closestEdge = rightEdges[0]
    if (closestEdge.dropGroupID && closestEdge.dropOrdinal) {
      // If the closest edge has a drop point, use it
      slide.moveRightOrdinal = closestEdge.dropOrdinal
      slide.moveRightGroupID = closestEdge.dropGroupID
    } else if (rightSlide) {
      // Edge has no drop point, so put into right slide's first group
      const groupBar = rightSlide.groupBars[0] as GroupBar
      slide.moveRightOrdinal = groupBar.addLeftOrdinal
      slide.moveRightGroupID = groupBar.id
    }
  }
}

function getGroupMinimizedIndex(
  groupIDs: string[],
  state: GrouperState
): number | undefined {
  const minimizedByID = state.programGroups.minimized
  const index = groupIDs.findIndex(id => minimizedByID[id] === true)
  return index >= 0 ? index : undefined
}

function shouldHideMinimizedSlide(slide: GrouperSlide): boolean {
  if (!slide.groupMinimizedIndex) {
    return false
  }
  const bar = slide.groupBars[slide.groupMinimizedIndex]
  if (bar) {
    if (bar.first) {
      return false
    }
    if (bar.last && bar.rightEdges.filter(e => !e.minimized).length > 0) {
      return false
    }
  }
  return true
}

/**
 * For any group agg slides we need to make sure the slide to its left's
 * moveRightOridinal (used to determine the reposition when the slide's
 * Move Right action is dispatched) is set to the group agg slide's edge.
 */
function adjustGroupAggAdjacentMoveOrdinals(
  slide: GrouperSlide,
  index: number,
  slides: GrouperSlide[]
): void {
  if (slide.isGroupAgg) {
    const leftSlide = getNextSlide(index, 'left', slides, true)
    if (leftSlide) {
      const bars = getNonNullGroupBars(slide.groupBars)
      const closestEdge = bars[bars.length - 1].rightEdges[0]
      if (closestEdge.dropGroupID && closestEdge.dropOrdinal) {
        // If the closest edge has a drop point, use it
        leftSlide.moveRightOrdinal = closestEdge.dropOrdinal
        leftSlide.moveRightGroupID = closestEdge.dropGroupID
      } else {
        const rightSlide = getNextSlide(index, 'right', slides, true)
        if (rightSlide) {
          // Edge has no drop point, so put into right slide's first group
          const groupBar = rightSlide.groupBars[0] as GroupBar
          leftSlide.moveRightOrdinal = groupBar.addLeftOrdinal
          leftSlide.moveRightGroupID = groupBar.id
        }
      }
    }
  }
}
export function createGrouperSlides(state: GrouperState) {
  const programInfos: ProgramInfo[] = []
  const groupInfoMap: Record<string, GroupInfo> = {}
  let groupIndex = 1
  let rankCount = 0
  const roots = selectRootProgramGroupMemberEntities(state)

  const processItem = (parentIDs: string[]) => (memberInfo: MemberInfo) => {
    rankCount = rankCount < parentIDs.length ? parentIDs.length : rankCount

    if (memberInfo.type === 'program') {
      const slideID = getSlideID(memberInfo)
      const index = programInfos.length
      const minimized = state.programs.minimized[slideID] === true
      programInfos.push({
        id: slideID,
        memberInfo,
        parents: parentIDs,
        minimized,
        groupMinimizedIndex: getGroupMinimizedIndex(parentIDs, state),
      })
      parentIDs.forEach(updateGroupInfoIndices(index, groupInfoMap))
      return
    }

    const groupEntity = getGroupEntity(memberInfo, state)
    const groupID = groupEntity.programGroup.id
    const paletteIndex = groupEntity.untitled ? -1 : groupIndex++

    const members = selectAllUndeletedMembersByParentID(groupID, state)
    const memberInfos = createMemberInfos(members)

    if (selectGroupHasGroupAgg(groupEntity, state)) {
      const lastMember = memberInfos[memberInfos.length - 1]
      memberInfos.push({
        id: `group_${groupEntity.programGroup.id}`,
        isGroupAgg: true,
        ordinal: lastMember.ordinal + DEFAULT_ORDINAL,
        parentGroupID: groupEntity.programGroup.id,
        type: 'program',
        leftOrdinal: lastMember.ordinal,
      })
    }

    groupInfoMap[groupID] = createGroupInfo(
      groupEntity,
      memberInfo,
      paletteIndex,
      memberInfos,
      state.programGroups.minimized[groupID] === true
    )
    memberInfos.forEach(processItem([...parentIDs, groupID]))
  }

  createMemberInfos(roots).forEach(processItem([]))

  const slides = programInfos.map(
    createSlideAndGroupBars(rankCount, groupInfoMap)
  )

  // For each slide, set its groups' edges
  slides.forEach(setSlideMoveOrdinalsAndGroupEdges)

  slides.forEach(adjustGroupAggAdjacentMoveOrdinals)

  return (
    slides
      // Remove slides that are in a minimized group and are not the first/last
      // of the group (the first will be used to display the minimized group bar)
      .filter(s => !shouldHideMinimizedSlide(s))
  )
}
