import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { SubjectivityTrackingColumnDef } from '../quote-panel/quote-panel-defs'
import { Subjectivity, SubjectivityTracking } from '../models/quote.model'
import { clone } from 'ramda'
import { MatDialog } from '@angular/material/dialog'
import { QuoteReinsurerSubjectivityContainerComponent } from '../reinsurer-subjectivity-dialog/reinsurer-subjectivity-dialog.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-tracking',
  templateUrl: './quote-tracking.component.html',
})
export class QuoteTrackingComponent {
  @Output() saveSubjectivities = new EventEmitter<{
    rows: Subjectivity[]
    id: string
  }>()
  @Output() updateSubjectivity = new EventEmitter<{
    reinsurerID: string
    subjectivityRows: Subjectivity[]
  }>()
  @Output() deleteSubjectivity = new EventEmitter<{
    reinsurerId: string
    id: string
  }>()
  @Input() subjectivityOptions: string[]
  @Input() subjectivityTrackingColumnDef: SubjectivityTrackingColumnDef[]
  @Input() rows: SubjectivityTracking[] = []
  subjectivities: Subjectivity[] = []

  constructor(
    public dialog: MatDialog
  ) {}

  editedSubjectivity($event: { rows: Subjectivity[]; id: string }): void {
    // Update rows to have all subjectivities for selected Reinsurer,
    // when 1 subjectivity is updated via edit-row-dialog
    const arraySub: Subjectivity[] = []
    this.rows.forEach(r => {
      if (r.reinsurerId === $event.id && $event.rows[0].id !== r.id) {
        const sub: Subjectivity = {
          acceptedLineCond: '',
          acceptedContractWord: '',
          brokerComments: r.brokerComments,
          clientAccepted: r.clientAccepted,
          id: r.id,
          reinsCommentary: r.reinsCommentary,
          reinsResponseStatus: '',
          reinsurer: r.reinsurer,
          reinsurerResponse: '',
          type: r.type,
          applyToStructureForSameMarket: r.applyToStructureForSameMarket,
          vendorName: r.vendorName
        }
        arraySub.push(sub)
      }
    })

    const combineSubArray = [...arraySub, ...$event.rows]
    // @ts-ignore
    combineSubArray.sort((a, b) => (a.id > b.id ? 1 : -1))
    this.saveSubjectivities.emit({ rows: combineSubArray, id: $event.id })
  }

  onDeleteSubjectivity($event: { id: string; reinsurerId: string }): void {
    const tempRows = clone(this.rows)
    // transform rows from SubjectivityTracking[] to Subjectivity[]
    tempRows.forEach(v => {
      delete v.layerID
      delete v.reinsurerId
    })
    const tempRows1 = tempRows.map(({ reinsurerName: reinsurer, ...rest }) => ({
      reinsurer,
      ...rest,
    }))
    // tslint:disable-next-line: no-non-null-assertion
    this.subjectivities = tempRows1.filter(r => r.id !== $event.id)!
    this.deleteSubjectivity.emit({
      reinsurerId: $event.reinsurerId,
      id: $event.id,
    })
    this.updateSubjectivity.emit({
      reinsurerID: $event.reinsurerId,
      subjectivityRows: this.subjectivities,
    })
  }

  onReinsurerClick(tpRef: string, reinsurerName: string): void {
    this.dialog.open(QuoteReinsurerSubjectivityContainerComponent,
      {
        data: {
          tpRef,
          reinsurerName: reinsurerName.replace('(FOT)', '')
        }
      }
    )
  }
}
