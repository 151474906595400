<app-tier-structures
  class="content"
  small
  isQuote="true"
  [clientSelect]="clientSelect"
  [selectedClientID]="selectedClientID"
  [structureGroupMembers]="programGroupMembers$ | async"
  [selectedYearID]="selectedYearID$ | async"
  [years]="years$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
  [programs]="programs$ | async"
  [structures]="structures$ | async"
  [structureGroups]="programGroups$ | async"
  [groupFilterByAssoc]="true"
  [structureFilter]="structureFilter$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [contextPath]="['quote', 'main']"
  [quoteLayers]="layers$ | async"
  [selectedStructureID]="selectedStructureID$ | async"
  [quoteDirty]="quoteDirty$ | async"
  [selectedCededLayerID]="selectedCededLayerID$ | async"
  [currentReinsurerMap]="currentReinsurerMap"
  [showAssignedLines]="showAssignedLines"
  [selectedStructureGroupID]="selectedStructureGroupID$ | async"
  [sharedLimits]="sharedLimits$ | async"
  [selectedSharedLimitID]="selectedSharedLimitID$ | async"
  [sectionList]="sectionList$ | async"
  (tierChange)="onTierChange($event)"
  (groupOrStructureSelectionChange)="
        onGroupOrStructureSelectionChange($event)
      "
  (structureSelectionChange)="onStructureSelectionChange($event)"
  (setRoute)="onSetRoute($event)"
  (structureNameDescriptionEdit)="onStructureNameDescriptionEdit($event)"
  (scenarioOrOptimizationSelectChange)="
        onScenarioOrOptimizationSelectChange($event)
      "
  (structureFilterChange)="onStructureFilterChange($event)"
  (structureIndexEdit)="onStructureIndexEdit($event)"
  (layerSelect)="onLayerSelect($event)"
  (groupSelect)="onGroupSelect($event)"
  (slSelect)="onSlSelect($event)"
></app-tier-structures>
<app-quote-content
  *ngIf="showFormComponent"
  name="Quote"
  [selectedClientID]="selectedClientID"
  [selectedYearID]="selectedYearID$ | async"
  [programs]="programs$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
  [selectedStructureID]="selectedStructureID$ | async"
  [reinsurerList]="reinsurerList$ | async"
  [selectedStructureGroupID]="selectedStructureGroupID$ | async"
  [isGroupSelected]="isGroupSelected$ | async"
  [sectionList]="sectionList$ | async"
  [lossSetLayers]="lossSetLayers"
  [layer]="layer$ | async"
  [layerState]="layers$ | async"
  [layerGroup]="layerGroup$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [layerStateGroup]="layersGroup$ | async"
  [selectedReinsurer]="selectedReinsurer$ | async"
  [sectionID]="sectionID$ | async"
  [structureID]="structureID$ | async"
  [isExpanded]="isExpanded$ | async"
  [expandedName]="expandedName$ | async"
  [isSaving]="isSaving$ | async"
  [selectedCededLayerID]="selectedCededLayerID$ | async"
  [reinsurersNameList]="reinsurersNameList$ | async"
  [studyID]="studyID$ | async"
  [bureaus]="bureaus$ | async"
  [showSubjectivity]="showSubjectivity"
  [showAssignedLines]="showAssignedLines"
  [showTrackingModule]="showTrackingModule"
  [exportClicked]="exportClicked"
  [exportCustomCompareClicked]="exportCustomCompareClicked"
  [saveSubClicked]="saveSubClicked"
  [saveAssignedLinesClicked]="saveAssignedLinesClicked"
  [showToggle]="showToggle"
  [showQuickQuote]="showQuickQuote"
  [saveQuickQuoteClicked]="saveQuickQuoteClicked"
  [assignedLineReName]="assignedLineReName$ | async"
  [assignedLineReId]="assignedLineReId$ | async"
  [structureRiskSubjectivityRows]="quoteRiskSubjectivityLinks$ | async"
  [isLoading]="isLoading$ | async"
  [isAutoBuildLoading]="isAutoBuildLoading$ | async"
  [selectedSharedLimitID]="selectedSharedLimitID$ | async"
  [isSLSelected]="isSLSelected$ | async"
  [layersSL]="layersSL$ | async"
  [territories]="territories$ | async"
  [externalVendor]="externalVendor$ | async"
  [quoteDirty]="quoteDirty$ | async"
  [selectedField]="selectedField$ | async"
  [addOrUpdateData]="addOrUpdateData$ | async"
  [hasWhitespaceAccess]="hasWhitespaceAccess$ | async"
  [autoFill]="autoFill$ | async"
  [allReinsurersList]="allReinsurers$ | async"
  [showCustomCompareBuilder]="showCustomCompareBuilder"
  [showCustomCompare]="showCustomCompare"
  [customCompareViews]="customCompareViews$ | async"
  [currentCompareView]="currentCompareView"
  (addOrUpdateVersion)="onAddOrUpdateVersion($event)"
  (saveClick)="onSaveClick($event)"
  (deleteClick)="onDeleteClick($event)"
  (declineClick)="onDeclineClick($event)"
  (preferredClick)="onPreferredClick($event)"
  (exportToggleClick)="onExportToggleClick($event)"
  (reinsurerResize)="onReinsurerResize($event)"
  (nameSelection)="onNameSelection($event)"
  (selectedID)="selectedIDChange($event)"
  (saveSubjectivities)="onSaveSubjectivities($event)"
  (populateSubjectivities)="onPopulateSubjectivities($event)"
  (addNewSubjectivity)="onAddNewSubjectivity($event)"
  (updateSubjectivity)="onUpdateSubjectivity($event)"
  (deleteSubjectivity)="onDeleteSubjectivity($event)"
  (expandClick)="onExpandClick($event)"
  (populateClick)="onPopulateClick($event)"
  (slidingValueChange)="onSlidingValueChange($event)"
  (deleteReinstatement)="onDeleteReinstatement($event)"
  (exportAsExcel)="onExportAsExcel($event)"
  (exportToggleSectionClick)="onExportToggleSectionClick($event)"
  (showSubjectivityClick)="showSubjectivitiesEvent($event)"
  (expandedReinsurer)="expandedReinsurer.emit($event)"
  (quoteView)="onSubPremCheckedFromDesign($event)"
  (currentReinsurerChange)="onCurrentReinsurerChange($event)"
  (showAssignedLinesClick)="showAssignedLinesClick.emit()"
  (saveAssignedLines)="onSaveAssignedLines($event)"
  (addNewAssignedLines)="onAddNewAssignedLines($event)"
  (updateAssignedLines)="onUpdateAssignedLines($event)"
  (saveQuickQuote)="onSaveQuickQuote()"
  (populateAssignedLinesFromWhiteSpace)="
        onPopulateAssignedLinesFromWhiteSpace($event)
      "
  (deleteAssignedLines)="onDeleteAssignedLines($event)"
  (updateLabel)="onUpdateLabel($event)"
  (subAlReNameAndId)="onSetSubAlReNameAndId($event)"
  (backClick)="backClick.emit()"
  (showSegregatedAccountModal)="onSegregatedAccountClick($event)"
  (updateQuickQuoteField)="onUpdateQuickQuoteField($event)"
  (addOrUpdateQuickQuote)="onAddOrUpdateQuickQuote($event)"
  (updateOffMarketLines)="onUpdateOffMarketLines($event)"
  (updateAutoFillSelection)="onUpdateAutoFillSelection($event)"
  (deleteCompareView)="deleteCompareView($event)"
  (setCompareView)="setCompareView($event)"
  (updateCompareViews)="updateCompareViews($event)"
></app-quote-content>
