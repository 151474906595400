import { createAction, props } from '@ngrx/store'
import { Program, TowerPreferences } from '../../model/program.model'
import {
  StructureTowerPreferencesRequest,
  StructureMarketLayersResponse,
} from '../../../api/model/backend.model'
import { ApplicationError } from '../../../error/model/error'
import { PhysicalLayer } from '../../../analysis/model/layers.model'
import { PortfolioMetrics } from '../../../analysis/model/portfolio-metrics.model'
import { SwapLossSets } from '../../../analysis/model/bulk-clone.model'
import {
  PositionIndexToUpdate,
  UpdatePositionIndexesRequest,
} from '../../../tier/tier.model'
import { LossSetMappingLabelsResponse, Study } from '../../model/study.model'

export interface ImportLossSetsClickEvent {
  structure: Program
  parentGrossPortfolioID: string
  analysisProfileID?: string
  isLast?: boolean
}
export const setProgramMarketLayer = createAction(
  '[Program] Set Program Market Layer',
  props<{ program: Program; marketLayers: StructureMarketLayersResponse[] }>()
)

export const updateDirtyProgram = createAction('[Program] Update Dirty Program')

export const setColorChange = createAction(
  '[Program] Set Color Change',
  props<{ layer: Partial<PhysicalLayer>; structure: Program; color: string }>()
)

export const setProgramLogAndSnapping = createAction(
  '[Program] Set Program Log/Snapping',
  props<{ id: string; log: boolean; logMin: number; snapping: boolean }>()
)

export const setDirtyProgram = createAction(
  '[Program] Set Dirty Program',
  props<Program>()
)

export const updateProgram = createAction(
  '[Program] Update Program',
  props<{ programID: string; change: Partial<Program> }>()
)

export const saveTowerPreferences = createAction(
  '[Program] Save Tower Preferenes',
  props<{ id: string; preferences: StructureTowerPreferencesRequest[] }>()
)

export const saveTowerPreferenesSuccess = createAction(
  '[Program] Save Tower Preferenes Success',
  props<{ id: string; towerPreferences: StructureTowerPreferencesRequest[] }>()
)

export const saveTowerPreferenesFailure = createAction(
  '[Program] Save Tower Preferenes Failure',
  props<{ error: ApplicationError }>()
)

// Occ
export const setOccIncrementsY = createAction(
  '[Program Properties] Set Occ Increments Y',
  props<{ programID: string; incrementsY: number }>()
)

export const setOccIncrementsYDirty = createAction(
  '[Program Properties] Set Occ Increments Y Dirty',
  props<{ programID: string; dirty: boolean }>()
)

export const setOccMaxY = createAction(
  '[Program Properties] Set Occ Max Y',
  props<{ programID: string; maxY: number }>()
)

export const setOccMaxYDirty = createAction(
  '[Program Properties] Set Occ Max Y Dirty',
  props<{ programID: string; dirty: boolean }>()
)

export const setOccMost = createAction(
  '[Program Properties] Set Occ Most',
  props<{ programID: string; most: number }>()
)

// Agg
export const setAggIncrementsY = createAction(
  '[Program Properties] Set Agg Increments Y',
  props<{ programID: string; incrementsY: number }>()
)

export const setAggIncrementsYDirty = createAction(
  '[Program Properties] Set Agg Increments Y Dirty',
  props<{ programID: string; dirty: boolean }>()
)

export const setAggMaxY = createAction(
  '[Program Properties] Set Agg Max Y',
  props<{ programID: string; maxY: number }>()
)

export const setAggMaxYDirty = createAction(
  '[Program Properties] Set Agg Max Y Dirty',
  props<{ programID: string; dirty: boolean }>()
)

export const setAggMost = createAction(
  '[Program Properties] Set Agg Most',
  props<{ programID: string; most: number }>()
)

// All
export const setAllProperties = createAction(
  '[Program Properties] Set All Properties',
  props<{ programID: string; occ: TowerPreferences; agg: TowerPreferences }>()
)

export const resetTowerProperties = createAction(
  '[Program Properties] Reset Tower Properties',
  props<{ programID: string }>()
)

export interface StructureNameEditEvent {
  structure: Program
  name: string
  description?: string
}

export const setProgramNameAndDescription = createAction(
  '[Program Properties] Edit Name and Description',
  props<StructureNameEditEvent>()
)

export const setProgramNameAndDescriptionSuccess = createAction(
  '[Program Properties] Edit Name and Description Success',
  props<{ id: string; name: string; description?: string }>()
)

export const setProgramNameAndDescriptionFailure = createAction(
  '[Program Properties] Edit Name and Description Failure',
  props<{ error: ApplicationError }>()
)

export interface StructureIndexEditEvent {
  structure: Program
  index: number
}

export const updateProgramIndex = createAction(
  '[Program Properties] Update Position Rank',
  props<StructureIndexEditEvent>()
)

export const updateProgramIndexSuccess = createAction(
  '[Program Properties] Update Position Rank Success',
  props<{ id: string; index: number }>()
)

export const updateProgramIndexFailure = createAction(
  '[Program Properties] Update Position Rank Failure',
  props<{ error: ApplicationError }>()
)

export const updateProgramIndexes = createAction(
  '[Program Properties] Update Positions Rank',
  props<{ req: UpdatePositionIndexesRequest }>()
)

export const updateProgramIndexesSuccess = createAction(
  '[Program Properties] Update Position Ranks Success',
  props<{ structures: Study }>()
)

export const updateProgramIndexesFailure = createAction(
  '[Program Properties] Update Position Ranks Failure',
  props<{ error: ApplicationError }>()
)

export const updateLocalProgramIndexes = createAction(
  '[Program Properties] Update Position Rank Many',
  props<{ indexes: PositionIndexToUpdate[] }>()
)

export const updateFolderID = createAction(
  '[Program Properties] Update Program Folder Id',
  props<{ structureId: string; folderID: string | null | undefined }>()
)

export const updateFolderIDFailure = createAction(
  '[Program Properties] Update Program Folder Id Failure',
  props<{ error: ApplicationError }>()
)

export const updateFolderIDSuccess = createAction(
  '[Program Properties] Update Program Folder Id Success',
  props<{ structureId: string; folderID: string | null | undefined }>()
)

export const updateCounts = createAction(
  '[Program Properties] Update Program Counts',
  props<{ fotCount: number; quoteCount: number }>()
)

export const updateCountsFailure = createAction(
  '[Program Properties] Update Program Counts Failure',
  props<{ error: ApplicationError }>()
)

export const updateCountsSuccess = createAction(
  '[Program Properties] Update Program Counts Success',
  props<{ structureId: string; fotCount: number; quoteCount: number }>()
)

export const updateTailMetrics = createAction(
  '[Program Properties] Update Tail Metrics',
  props<{ id: string; tailMetrics: PortfolioMetrics }>()
)

export const updateTailMetricsSuccess = createAction(
  '[Program Properties] Update Tail Metrics Success',
  props<{ id: string; tailMetrics: PortfolioMetrics }>()
)

export const updateTailMetricsFailure = createAction(
  '[Program Properties] Update Tail Metrics Failure',
  props<{ error: ApplicationError }>()
)

export const importBulkLossSets = createAction(
  '[Analysis] Import Loss Sets',
  props<ImportLossSetsClickEvent>()
)

export const importBulkLossSetsSuccess = createAction(
  '[Program Properties] Import Bulk Loss Sets Success',
  props<{ id: string; parentGrossPortfolioID?: string }>()
)

export const swapBulkLossSets = createAction(
  '[Analysis] Swap Loss Sets',
  props<SwapLossSets>()
)

export const swapBulkLossSetsSuccess = createAction(
  '[Program Properties] Swap Bulk Loss Sets Success',
  props<{ id: string; parentGrossPortfolioID?: string }>()
)

export const postLossSetMappingLabels = createAction(
  '[Study] Post Loss Set Mapping Labels',
  props<LossSetMappingLabelsResponse>()
)

export const postLossSetMappingLabelsFailure = createAction(
  '[Study] Post Loss Set Mapping Labels Failure',
  props<{ error: ApplicationError }>()
)

export const postLossSetMappingLabelsSuccess = createAction(
  '[Study] Post Loss Set Mapping Labels Success',
  props<LossSetMappingLabelsResponse>()
)

export const updateLossSetMappingLabels = createAction(
  '[Study] Update Loss Set Mapping Labels',
  props<LossSetMappingLabelsResponse>()
)

export const updateLossSetMappingLabelsFailure = createAction(
  '[Study] Update Loss Set Mapping Labels',
  props<{ error: ApplicationError }>()
)

export const updateLossSetMappingLabelsSuccess = createAction(
  '[Study] Update Loss Set Mapping Labels Success',
  props<LossSetMappingLabelsResponse>()
)
