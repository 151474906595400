import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { last, mergeRight, omit, pick, range } from 'ramda'
import { Observable, forkJoin } from 'rxjs'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import {
  HasId,
  HasMetadata,
  customIndexValuesFromString,
  indexationFranchise,
  indexationFull,
  indexationSevere,
  indexedLayerType,
} from 'src/app/analysis/layers/indexed-layer'
import {
  GenericLayer,
  Layer,
  NestedLayer,
  PhysicalLayer,
  PostResponse,
  UNLIMITED_DOLLARS,
  ZERO_DOLLARS,
} from 'src/app/analysis/model/layers.model'
import { LossSetLayer } from 'src/app/analysis/model/loss-set-layers.model'
import * as fromLayersActions from 'src/app/analysis/store/ceded-layers/layers.actions'
import { LayersType } from 'src/app/api/analyzere/analyzere-batch.models'
import { Metadata, Ref, asRef } from 'src/app/api/analyzere/analyzere.model'
import {
  AnalyzreService,
  HttpOptions,
} from 'src/app/api/analyzere/analyzre.service'
import { ApiResponse } from 'src/app/api/model/api.model'
import { AppState } from 'src/app/core/store'
import { selectCurrentAnalysisProfile } from '../../core/store/broker/broker.selectors'
import { errorPayload } from 'src/app/error/model/error'

const numSettlementYears = 25

const indexedDefaults = {
  indexation: indexationFull,
  fixedIndexValue: 0.01,
  isCustomIndex: false,
  sicOrFranchise: 0,
}

@Injectable({
  providedIn: 'root',
})
export class IndexedLayerService {
  constructor(
    private service: AnalyzreService,
    private store: Store<AppState>
  ) {}

  createIndexedLayer(layerDefaults: Layer, lossSets: LossSetLayer[]) {
    // Normally, the lossSets will come from the design editor. If the layer is created via autobuild
    // then the loss sets will come from the layerDefaults.
    const lossSetIds =
      lossSets.length > 0 ? lossSets.map(ls => ls.id) : layerDefaults.layerRefs

    if (lossSetIds.length === 0) {
      throw new Error('No large loss sets are selected.')
    }

    return this.createIndexedLossSetLayer(lossSetIds).pipe(
      withLatestFrom(this.staticSimulationStartDate()),
      switchMap(([lossSetLayer, startDate]) => {
        if (!startDate) {
          return Array.of(
            fromLayersActions.addLayerFailure({
              error: errorPayload(
                'Unable to create the Indexed Layer.',
                'An Indexed layer requires a simulation start date set in the analysis profile.'
              ),
            })
          )
        }

        return this.createSettlementYearLayer(
          layerDefaults,
          startDate,
          lossSetLayer
        ).pipe(
          switchMap(settlements => {
            const mainLayer = this.createIndexedMainLayer(
              layerDefaults,
              startDate,
              settlements,
              lossSetLayer
            )
            return Array.of(fromLayersActions.addLayer({ layer: mainLayer }))
          })
        )
      })
    )
  }

  private staticSimulationStartDate(): Observable<string | undefined> {
    return this.store
      .select(selectCurrentAnalysisProfile)
      .pipe(map(ap => ap?.simulation.start_date))
  }

  /**
   * Create a layer that will hold all the ceded loss sets for the indexed layer.
   * Each yearly layer with have a source reference to this layer.
   *
   * @param lossSetLayers the current active loss sets. These are QuotaShare layers.
   * @return the loss sets layer response observerable
   */
  private createIndexedLossSetLayer(lossSetIds: string[]) {
    const description = 'Indexed Layer Grouped Loss Sets'
    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      sage_layer_type: indexedLayerType,
      sage_layer_subtype: 'loss-layer',
      hidden: true,
    }

    return this.createNestedLayer(
      {
        _type: 'Generic',
        loss_sets: [],
        aggregate_attachment: ZERO_DOLLARS,
        aggregate_limit: UNLIMITED_DOLLARS,
        attachment: ZERO_DOLLARS,
        franchise: ZERO_DOLLARS,
        limit: UNLIMITED_DOLLARS,
        participation: 1.0,
        description,
        meta_data,
      },
      {
        _type: 'NestedLayer',
        sources: lossSetIds.map(id => asRef(id)),
        description,
        meta_data,
      }
    )
  }

  private addFromLayerID<T>(layer: T & HasMetadata, fromLayerID = ''): T {
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        fromLayerID,
      },
    }
  }

  private addToDate(dt: Date | string, years: number, days?: number) {
    const dt2 = new Date(dt)
    dt2.setFullYear(dt2.getFullYear() + years)
    dt2.setDate(dt2.getDate() + (days ?? 0))
    return dt2
  }

  private createSettlementYearLayer(
    layerDefaults: Layer,
    startDate: string,
    lossSetLayer: Ref
  ) {
    const props = getIndexedProperties(layerDefaults)
    const indexValues = calculateIndexValues(getFixedOrCustom(props))

    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      sage_layer_type: indexedLayerType,
      sage_layer_subtype: 'settlement-layer',
      hidden: true,
    }

    const layers = range(0, numSettlementYears).map(year => {
      const description = `Indexed Layer Settlement Year ${year}`
      const inceptionDate = this.addToDate(startDate, year).toISOString()
      const expiryDate = this.addToDate(inceptionDate, 1).toISOString()

      const indexValue = indexValues[year]
      const { attachment, limit } = calculateOccurrence(props, indexValue)

      return this.createNestedLayer(
        {
          _type: 'Generic',
          loss_sets: [],
          inception_date: inceptionDate,
          expiry_date: expiryDate,
          aggregate_attachment: ZERO_DOLLARS,
          aggregate_limit: UNLIMITED_DOLLARS,
          franchise: ZERO_DOLLARS,
          attachment,
          limit,
          participation: 1.0,
          description,
          meta_data,
        },
        {
          _type: 'NestedLayer',
          sources: [lossSetLayer],
          description,
          meta_data,
        }
      )
    })

    return forkJoin(layers)
  }

  private createIndexedMainLayer(
    layerDefaults: Layer,
    startDate: string,
    settlements: Ref[],
    lossSetLayer: Ref
  ) {
    const inceptionDate = new Date(startDate).toISOString()
    const expiryDate = this.addToDate(
      inceptionDate,
      numSettlementYears
    ).toISOString()

    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      ...layerDefaults.meta_data,
      sage_layer_type: indexedLayerType,
      sage_layer_subtype: 'main-layer',
      hidden: true,
      loss_layer_id: lossSetLayer.ref_id,
    }

    return {
      ...layerDefaults,
      lossSetLayers: [] as LossSetLayer[],
      layerRefs: settlements.map(ref => ref.ref_id),
      meta_data,
      physicalLayer: {
        ...layerDefaults.physicalLayer,
        inception_date: inceptionDate,
        expiry_date: expiryDate,
        attachment: ZERO_DOLLARS,
        franchise: ZERO_DOLLARS,
        limit: UNLIMITED_DOLLARS,
        meta_data: {
          ...meta_data,
          indexation: indexationFull,
          fixedIndexValue: 0.01,
          isCustomIndex: false,
          sicOrFranchise: 0,
        },
      } as PhysicalLayer,
    }
  }

  createIndexedVisible(layerDefaults: Layer, lossSetLayerIds: string[]): Layer {
    // tslint:disable-next-line: variable-name
    const meta_data: Partial<Metadata> = {
      ...layerDefaults.meta_data,
      sage_layer_type: indexedLayerType,
      sage_layer_subtype: 'visible-layer',
      main_layer_id: layerDefaults.id,
    }

    return {
      ...layerDefaults,
      lossSetLayers: [],
      layerRefs: lossSetLayerIds,
      meta_data,
      physicalLayer: {
        ...layerDefaults.physicalLayer,
        attachment: ZERO_DOLLARS,
        franchise: ZERO_DOLLARS,
        limit: UNLIMITED_DOLLARS,
        meta_data: {
          ...meta_data,
          indexation: indexationFull,
          fixedIndexValue: 0.01,
          isCustomIndex: false,
          sicOrFranchise: 0,
        },
      },
    }
  }

  cloneLossLayer(oldLossLayerId: string) {
    return this.fetchLayer<NestedLayer>(oldLossLayerId).pipe(
      switchMap(oldLossLayer => {
        const oldGeneric = oldLossLayer.sink as GenericLayer & HasMetadata
        const newGeneric = omit(['id', 'created', 'modified'], oldGeneric)
        const newNested = omit(['id', 'created', 'modified'], oldLossLayer)

        newNested.sources = (oldLossLayer.sources as any[]).map(layer =>
          asRef(layer.id)
        )

        return this.createNestedLayer(
          this.addFromLayerID(newGeneric, oldGeneric.id),
          this.addFromLayerID(newNested, oldLossLayerId)
        )
      })
    )
  }

  cloneSettlementLayer(oldSettlementLayerId: string, newLossSetRef: Ref) {
    return this.fetchLayer<NestedLayer>(oldSettlementLayerId).pipe(
      switchMap(oldNested => {
        const oldGeneric = oldNested.sink as GenericLayer & HasMetadata
        const newGeneric = omit(['id', 'created', 'modified'], oldGeneric)
        const newNested = omit(['id', 'created', 'modified'], oldNested)

        newNested.sources = [newLossSetRef]

        return this.createNestedLayer(
          this.addFromLayerID(newGeneric, oldGeneric.id),
          this.addFromLayerID(newNested, oldSettlementLayerId)
        )
      })
    )
  }

  updateIndexedMain(mainLayerId: string, change: Partial<PhysicalLayer>) {
    const valuePropNames = [
      'aggregateLimit',
      'aggregateAttachment',
      'franchise',
      'participation',
      'premium',
      'fees',
      'reinstatements',
      'description',
    ]

    const valueChanges = pick(valuePropNames, change)
    if (Object.keys(valueChanges).length === 0) {
      return []
    }

    return [
      fromLayersActions.updatePhysicalLayer({
        id: mainLayerId,
        change: valueChanges,
      }),
    ]
  }

  updateIndexedSettlements(mainLayer: Layer, visibleLayer: Layer) {
    const props = getIndexedProperties(visibleLayer)
    const indexValues = calculateIndexValues(getFixedOrCustom(props))

    // Recalculate the settlement layers
    const settlementLayers = mainLayer.layerRefs.map((settlementId, year) => {
      const indexValue = indexValues[year]
      const occurrence = calculateOccurrence(props, indexValue)

      // console.debug(
      //   `* year=${year} iv=${indexValue} ix=${calculateIndexation(
      //     props.indexation,
      //     indexValue,
      //     props.sicOrFranchise
      //   )} occ=${occurrence.attachment.value},${occurrence.limit.value}`
      // )

      return this.fetchLayer<NestedLayer>(settlementId).pipe(
        map(nestedLayer => (nestedLayer.sink as GenericLayer).id),
        switchMap(genericId => this.patchLayer(genericId ?? '', occurrence))
      )
    })

    return settlementLayers
  }

  updateLossSetGroup(visibleLayer: Layer) {
    const loss_layer_id = visibleLayer.meta_data.loss_layer_id
    if (loss_layer_id) {
      const refs = visibleLayer.lossSetLayers.map(lossSet => asRef(lossSet.id))
      return this.patchLayer(loss_layer_id, {
        sources: refs,
      })
    }
  }

  defaultIndexValues(fixedIndexValue = 0) {
    return Array<number>(numSettlementYears).fill(fixedIndexValue)
  }

  private createNestedLayer(
    generic: GenericLayer,
    nested: Omit<NestedLayer, 'sink'>
  ) {
    return this.postLayer(generic).pipe(
      switchMap(genericRef => {
        return this.postLayer<NestedLayer>({ ...nested, sink: genericRef })
      })
    )
  }

  private fetchLayer<T extends LayersType>(
    id: string,
    httpOptions?: HttpOptions
  ) {
    return this.service.fetchLayer<T>(id, httpOptions).pipe(
      map(({ data }): T => {
        if (data === undefined) {
          throw new Error(`data is undefined trying to fetch layer ${id}.`)
        }
        return data
      })
    )
  }

  private postLayer<T extends LayersType>(layer: Partial<T>) {
    return this.service
      .postLayer(layer as PostResponse<T>)
      .pipe(map(({ data }) => asRef(this.getLayerId(data))))
  }

  private getLayerId<T extends LayersType>(data?: T) {
    const id = data?.id
    if (typeof id !== 'string') {
      throw new Error('Id not found.')
    }
    return id
  }

  private patchLayer<T extends LayersType>(
    id: string,
    change: Partial<T>
  ): ApiResponse<T> {
    return this.service.patchLayer<T>({ id, change })
  }

  // @ts-ignore
  private postLayerView(
    layerId: string,
    analysisProfileId: string,
    currency?: string
  ) {
    return this.service
      .postLayerView(layerId, analysisProfileId, currency)
      .pipe(map(({ data }) => asRef(this.getLayerId(data))))
  }

  // This function aids debugging by writing out the pieces of
  // the indexed layer to verify that all is as it should be.
  // write(mainLayerId: string, visibleLayer: Layer) {
  //   const url = 'https://lockton-api.analyzere.net'
  //
  //   const outNested = (id: string, layer: any) => {
  //     console.log('')
  //     console.log(`${layer.meta_data.sage_layer_type}`)
  //     console.log(`  ${layer.meta_data.sage_layer_subtype}`)
  //     console.log(`    nested:  ${url}/layers/${id}`)
  //     console.log(
  //       `    sink:    ${url}/layers/${
  //         layer.sink?.id ?? layer.physicalLayer?.id
  //       }`
  //     )
  //     const sources = layer.sources ?? layer.lossSetLayers
  //     console.log(`    sources: ${url}/layers/${sources[0].id}`)
  //     sources.slice(1).forEach((source: any) => {
  //       console.log(`             ${url}/layers/${source.id}`)
  //     })
  //   }
  //
  //   this.fetchLayer<NestedLayer>(mainLayerId).subscribe(mainLayer => {
  //     outNested(mainLayerId, mainLayer)
  //     outNested(visibleLayer.id, visibleLayer)
  //   })
  // }
}

export const matchingLayer =
  <T>(id: string | undefined) =>
  (layer: T & HasId & HasMetadata) =>
    id ? layer.id === id : false

type IndexedProperties = ReturnType<typeof getIndexedProperties>

type IndexedPropNames = 'attachment' | 'limit'
type IndexedMetaNames =
  | 'indexation'
  | 'isCustomIndex'
  | 'fixedIndexValue'
  | 'customIndexValues'
  | 'sicOrFranchise'

function getIndexedProperties(layer: Layer) {
  const propNames: IndexedPropNames[] = ['attachment', 'limit']
  const metaNames: IndexedMetaNames[] = [
    'indexation',
    'isCustomIndex',
    'fixedIndexValue',
    'customIndexValues',
    'sicOrFranchise',
  ]

  const metaDefaults = mergeRight(indexedDefaults)

  return {
    ...pick(propNames, layer.physicalLayer),
    ...metaDefaults(pick(metaNames, layer.physicalLayer.meta_data)),
  }
}

function getFixedOrCustom(props: IndexedProperties): number | number[] {
  if (props.isCustomIndex === true) {
    const customIndexValues = props.customIndexValues
    if (customIndexValues === undefined) {
      throw new Error('customIndexValues is not set.')
    }

    return customIndexValuesFromString(customIndexValues)
  } else {
    return props.fixedIndexValue
  }
}

// For a fixed index you passed in a single number. For custom indexes you pass
// them in a number array.
function calculateIndexValues(fixedIndexOrCustomIndexes: number | number[]) {
  const indexValues =
    // The result is an array of length equal to the number of settlement years.
    // The elements are either all the same fixed index value or the custom
    // index values entered by the user.
    typeof fixedIndexOrCustomIndexes === 'number'
      ? new Array(numSettlementYears).fill(fixedIndexOrCustomIndexes)
      : fixedIndexOrCustomIndexes

  // The elements are calculated thus:
  // iv[0] = 1 + indexValues[0]
  // iv[n] = iv[n-1] * (1 + indexValues[n])
  return indexValues.reduce<number[]>(
    (iv, v) => [...iv, (1 + v) * (last<number>(iv) ?? 1)],
    []
  )
}

function calculateOccurrence(props: IndexedProperties, indexValue: number) {
  const { attachment, limit, sicOrFranchise, indexation } = props

  return {
    attachment: {
      value:
        attachment.value *
        calculateIndexation(indexation, indexValue, sicOrFranchise),
      currency: attachment.currency,
    },
    limit: {
      value:
        limit.value *
        calculateIndexation(indexation, indexValue, sicOrFranchise),
      currency: limit.currency,
    },
  }
}

function calculateIndexation(
  indexation: number,
  indexValue: number,
  franchiseValue: number
): number {
  if (indexation === indexationFull) {
    return indexValue
  } else {
    if (indexValue > 1 + franchiseValue) {
      if (indexation === indexationSevere) {
        return indexValue - franchiseValue
      } else {
        if (indexation === indexationFranchise) {
          return indexValue
        } else {
          return 1.0
        }
      }
    } else {
      return 1.0
    }
  }
}
