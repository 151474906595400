import { createReducer, on, Action } from '@ngrx/store'
import { CapitalMetricsResult } from '../../model/capital-metric.model'
import {
  fetchCapitalMetrics,
  fetchCapitalMetricsFailure,
  fetchCapitalMetricsSuccess,
} from './capital-metrics.actions'

export interface State extends CapitalMetricsResult {
  loading: boolean
  error: string | null
}

export const initialState: State = {
  loading: false,
  error: null,
  bcarCapitalBenefit: 0,
  spCapitalBenefit: 0,
  ecmCapitalBenefit: 0,
}

const capitalMetricsReducer = createReducer(
  initialState,
  on(fetchCapitalMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchCapitalMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(
    fetchCapitalMetricsSuccess,
    (state, { bcarCapitalBenefit, spCapitalBenefit, ecmCapitalBenefit }) => ({
      ...state,
      loading: false,
      error: null,
      bcarCapitalBenefit,
      spCapitalBenefit,
      ecmCapitalBenefit,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return capitalMetricsReducer(state, action)
}
