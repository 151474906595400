import { DatePipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { clone } from 'ramda'
import { CompareMetricTableCategory } from '../../analysis/model/compare-metrics.model'
import {
  BrokerData,
  brokerOptions,
  placedThroughOptions,
} from '../../quote/models/quote.model'
import {
  AGENCY_MARKET_USE,
  APPETITE_COLOR,
  AgencyDetails,
  AppetiteColor,
  FUND_MANAGER_MARKET_USE,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { StudyReinsurersState } from '../store/study-reinsurers.reducer'
import { AccountOpportunity } from '../../api/model/backend.model'
import { containsObo } from '@shared/util/string'
import { formatMMDDYYYY } from '@shared/util/date'
import { formatDate, internationalDateApprovalNeeded, isReinsurerOutsideOpportunityDate } from '../utils/reinsurer.util'
import { QuoteReinsurerSubjectivityContainerComponent } from 'src/app/quote/reinsurer-subjectivity-dialog/reinsurer-subjectivity-dialog.container'

interface StudyReinsurer {
  studyID: string
  studyName: string
  reinsurer: Reinsurer
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-detail-dialog-component',
  styleUrls: ['./reinsurer-detail.component.scss'],
  templateUrl: './reinsurer-detail.component.html',
})
export class ReinsurerDetailDialogComponent implements OnChanges, OnInit {
  parentMenuItemValue: string
  reinsurersEdit: StudyReinsurersState[]
  studyReinsurers: StudyReinsurer[]
  elmSelected: boolean
  elpSelected: boolean
  customMetricList: string[] = []
  cmpSelected: boolean
  stpSelected: boolean
  segregatedAccount: boolean
  noCompanyPapers: boolean
  returnPeriodToggleChange: number
  authReq: boolean
  authReqDialog: string[]
  isLoading = true
  ptList: string[] = placedThroughOptions
  brokerList: BrokerData[] = brokerOptions
  isMetricChecked = false
  isAgency = false
  isFundManager = false
  approvalPeriod: string
  relationshipEffectivePeriod: string

  @Input() reinsurer: Reinsurer
  @Input() set reinsurers(value: readonly StudyReinsurersState[]) {
    // Make a deep copy of the passed in array
    this.reinsurersEdit = JSON.parse(JSON.stringify(value))
  }
  @Input() studyID: string | null
  @Input() metricDetails: CompareMetricTableCategory[]
  @Input() studies: readonly Study[]
  @Input() reinsurerID: number
  @Input() savingReinsurer: boolean
  @Input() agencyDetails: AgencyDetails[] = []
  @Input() relatedReinsurers: Reinsurer[] = []
  @Input() dirtyReinsurers: Reinsurer[]
  @Input() selectedCompanyPapers: AgencyDetails[]
  @Input() programs: Study[] | null
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() selectedProgramID: string | null

  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurer: Reinsurer
  }>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() saveClick = new EventEmitter()

  constructor(
    public dialogRef: MatDialogRef<ReinsurerDetailDialogComponent>,
    public subjectivityDialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {    
    this.isAgency = this.reinsurer.market_use === AGENCY_MARKET_USE
    this.isFundManager = this.reinsurer.market_use === FUND_MANAGER_MARKET_USE
    this.elmSelected = this.reinsurer.reinsurerProgramFactor[0].elm_selected
    this.cmpSelected = this.reinsurer.reinsurerProgramFactor[0].cmp_selected
    this.stpSelected = this.reinsurer.reinsurerProgramFactor[0].stp_selected

    this.relationshipEffectivePeriod = this.isAgency && this.agencyDetails?.length > 0 
      ? `${formatDate(this.agencyDetails[0].effectiveDate.toString())} to ${formatDate(this.agencyDetails[0].expiryDate.toString())}` 
      : ''

    this.segregatedAccount = this.reinsurer.reinsurerProgramFactor[0]
      .segregated_account
      ? true
      : false
    this.noCompanyPapers =
      !this.selectedCompanyPapers || this.selectedCompanyPapers.length === 0
        ? true
        : false
    if (this.selectedCompanyPapers) {
      this.selectedCompanyPapers = this.selectedCompanyPapers.map(paper => {
        const agencyTPRef =
          this.reinsurer.selectedCompanyPapers.filter(p =>
            p.agencyName === paper.agencyName
          )[0]?.agencyTPRef
        const amBestRatingDate = formatMMDDYYYY(paper.amBestRatingDate || '')
        const outlookDate = formatMMDDYYYY(paper.outlookDate || '')
        const ratingDate = formatMMDDYYYY(paper.ratingDate || '')
        const lastReviewDate = formatMMDDYYYY(paper.lastReviewDate || '')
        return {
          ...paper,
          amBestRatingDate,
          outlookDate,
          ratingDate,
          lastReviewDate,
          agencyTPRef
        }
      })
    }
    if (this.relatedReinsurers) {
      this.relatedReinsurers = this.relatedReinsurers.map(reinsurer => {
        reinsurer = { ...reinsurer }
        reinsurer.amBestRatingDate = formatMMDDYYYY(
          reinsurer.amBestRatingDate || ''
        )
        reinsurer.outlookDate = formatMMDDYYYY(reinsurer.outlookDate || '')
        reinsurer.ratingDate = formatMMDDYYYY(reinsurer.ratingDate || '')
        reinsurer.lastReviewDate = formatMMDDYYYY(
          reinsurer.lastReviewDate || ''
        )
        return reinsurer
      })
    }
    if (this.reinsurer) {
      this.reinsurer = { ...this.reinsurer }
      this.reinsurer.amBestRatingDate = formatMMDDYYYY(
        this.reinsurer.amBestRatingDate || ''
      )
      this.reinsurer.outlookDate = formatMMDDYYYY(
        this.reinsurer.outlookDate || ''
      )
      this.reinsurer.ratingDate = formatMMDDYYYY(
        this.reinsurer.ratingDate || ''
      )
      this.reinsurer.lastReviewDate = formatMMDDYYYY(
        this.reinsurer.lastReviewDate || ''
      )
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isAgency = this.reinsurer.market_use === AGENCY_MARKET_USE
    // We need to do the lookup after both input properties have received a value and the order they are set in undefined
    this.authReqDialog = this.getAuthorizationDialog(this.reinsurer)
    if (
      (changes.studies || changes.reinsurers) &&
      this.studies &&
      this.reinsurersEdit
    ) {
      this.studyReinsurers = []
      const selectedStudy = this.studies.find(s1 => s1.id === this.studyID)
      if (selectedStudy) {
        const reinsurer = this.reinsurersEdit
          .find(pr => pr.programID === selectedStudy.id)
          ?.reinsurers?.find(r => r.id === this.reinsurerID)
        if (reinsurer) {
          this.studyReinsurers.push({
            studyID: selectedStudy.id,
            studyName: selectedStudy.name,
            reinsurer: reinsurer,
          })
        }
      }
      this.isLoading = this.getIsLoading()
    }

    if (this.reinsurer.reinsuranceApprovalFromDate) {
      this.approvalPeriod = `${formatDate(
        this.reinsurer.reinsuranceApprovalFromDate ?? ''
      )} to ${formatDate(
        this.reinsurer.reinsuranceApprovalToDate ?? ''
      )}  (Business Unit: ${this.displayApprovedFor(
        this.reinsurer.reinsuranceApprovedFor
      )})`
    } else if (this.reinsurer.internationalReinsuranceApprovalFromDate) {
      this.approvalPeriod = `${formatDate(
        this.reinsurer.internationalReinsuranceApprovalFromDate ?? ''
      )} to ${formatDate(
        this.reinsurer.internationalReinsuranceApprovalToDate ?? ''
      )}  (Business Unit: ${this.displayApprovedFor(
        this.reinsurer.internationalReinsuranceApprovedFor
      )})`
    } else {
      this.approvalPeriod = 'NA'
    }
  }

  getAuthorizationDialog(reinsurer: Reinsurer): string[] {
    const dialogMessages: string[] = []

    const intlApprovalNeeded = internationalDateApprovalNeeded(reinsurer)

    const authorizationRequired = intlApprovalNeeded ? !reinsurer.internationalReinsuranceApprovalStatus || reinsurer.internationalReinsuranceApprovalStatus !== 'ACT' : !reinsurer.reinsuranceApprovalStatus || reinsurer.reinsuranceApprovalStatus !== 'ACT'

    const invalidDates = isReinsurerOutsideOpportunityDate(reinsurer, this.programs, this.accountOpportunities, this.selectedProgramID, intlApprovalNeeded)

    if (invalidDates) {
      dialogMessages.push('Inactive Approval Dates')
    }
    if (reinsurer.market_security_restrictions) {
      dialogMessages.push(reinsurer.market_security_restrictions)
    }
    if (authorizationRequired) {
      dialogMessages.push('Authorization Required')
    }
    return dialogMessages
  }

  getIsLoading(): boolean {
    for (const study of this.studyReinsurers) {
      if (!study || !study.reinsurer || !Array.isArray(study.reinsurer.reinsurerProgramFactor) || study.reinsurer.reinsurerProgramFactor.length === 0) {
        return true
      }
    }
    return false
  }

  displayApprovedFor(approvedFor: string | null): string {
    return approvedFor ? approvedFor.replace('Approved for MALTA', '') : 'NA'
  }

  get menu(): string[] {
    return APPETITE_COLOR
  }

  get name(): string {
    return (
      this.reinsurer.reinsurerProgramFactor[0].obo_name ?? this.reinsurer.name
    )
  }

  get isDirty(): boolean {
    return this.dirtyReinsurers.length > 0
  }

  getColor(study: StudyReinsurer): string {
    return study.reinsurer.reinsurerProgramFactor[0]?.appetite ?? ''
  }

  getPolicyHeader(reinsurer: Reinsurer): string {
    return reinsurer.policy_holder === 0
      ? 'Total Equity'
      : 'Policyholder Surplus'
  }

  getPolicyDate(reinsurer: Reinsurer): string {
    const date =
      reinsurer.policy_holder === 0
        ? reinsurer.periodEndDateTotEquity
        : reinsurer.policy_holder_date
    return this.datePipe.transform(date, 'longDate') || ' '
  }

  setAppetiteColor(study: StudyReinsurer, value: AppetiteColor): void {
    study.reinsurer.reinsurerProgramFactor[0].appetite = value

    this.setReinsurer.emit({
      programID: study.studyID,
      reinsurer: study.reinsurer,
    })

    this.updateOrAddDirty.emit(study.reinsurer)
  }

  setReinsurerChange(study: StudyReinsurer): void {
    this.setReinsurer.emit({
      programID: study.studyID,
      reinsurer: study.reinsurer,
    })

    this.updateOrAddDirty.emit(study.reinsurer)
  }

  getContactModel(study: StudyReinsurer): string | null {
    return study.reinsurer.reinsurerProgramFactor[0]?.key_uw_contact ?? null
  }

  setContactModel(study: StudyReinsurer, event: string): void {
    study.reinsurer.reinsurerProgramFactor[0].key_uw_contact = event
  }

  getNotesModel(study: StudyReinsurer): string | null {
    return study.reinsurer.reinsurerProgramFactor[0]?.notes ?? null
  }

  setNotesModel(study: StudyReinsurer, event: string): void {
    study.reinsurer.reinsurerProgramFactor[0].notes = event
  }

  get activeAction(): string {
    if (this.savingReinsurer) {
      return 'Saving'
    } else {
      return ''
    }
  }

  setPlacedThrough(value: string): void {
    let newReinsurer = this.reinsurer
    const factor = clone(newReinsurer.reinsurerProgramFactor)
    factor[0].placed_through = value
    newReinsurer = {
      ...newReinsurer,
      reinsurerProgramFactor: factor,
    }
    this.updateOrAddDirty.emit(newReinsurer)

    this.setReinsurer.emit({
      // tslint:disable-next-line: no-non-null-assertion
      programID: this.studyID!,
      reinsurer: newReinsurer,
    })
  }

  setBroker(value: string): void {
    const bData = this.brokerList.find(b => b.name === value) || {
      name: '',
      ref: '',
    }
    let newReinsurer = this.reinsurer
    const factor = clone(newReinsurer.reinsurerProgramFactor)
    factor[0].co_broker = bData.name
    factor[0].co_broker_ref = bData.ref
    newReinsurer = {
      ...newReinsurer,
      reinsurerProgramFactor: factor,
    }
    this.updateOrAddDirty.emit(newReinsurer)

    this.setReinsurer.emit({
      // tslint:disable-next-line: no-non-null-assertion
      programID: this.studyID!,
      reinsurer: newReinsurer,
    })
  }

  showPricingFactor(): boolean {
    return (
      this.reinsurer.reinsurerProgramFactor[0].incumbent ||
      this.reinsurer.reinsurerProgramFactor[0].target_market
    )
  }

  getAgencyReinsurer(tpRef: string): Reinsurer {
    return this.relatedReinsurers.find(r => r.tpRef === tpRef)!
  }

  onSaveClick(): void {
    if (this.isDirty) {
      this.saveClick.emit()
    }
  }

  getData(event: any): void {
    this.parentMenuItemValue = event.srcElement.innerText.toString()
  }

  getReinsurerName(reinsurer: Reinsurer): string {
    if (reinsurer.market_use === FUND_MANAGER_MARKET_USE) {
      if (
        reinsurer.selectedSegregatedAccounts &&
        reinsurer.selectedSegregatedAccounts.length > 0
      ) {
        const oboDetected =
          containsObo(reinsurer.name) ||
          containsObo(reinsurer.selectedSegregatedAccounts[0].agencyName)
        return `${reinsurer.selectedSegregatedAccounts[0].agencyName}${
          !oboDetected ? ` obo ${reinsurer.name}` : ''
        }`
      } else {
        return reinsurer.name
      }
    } else {
      let seqNum = 0
      if (this.agencyDetails && this.agencyDetails.length>0){
        seqNum = this.agencyDetails[0].agencySeqNumber
      }
      return seqNum > 0 ? `${reinsurer.name} (Sequence number: ${seqNum})` : reinsurer.name 
    }
  }

  openSubjectivitiesDialog(): void {
    const data = { tpRef: this.reinsurer.tpRef, reinsurerName: this.reinsurer.name }
    this.subjectivityDialog.open(QuoteReinsurerSubjectivityContainerComponent, {data})
  }
}
