import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../analysis/model/portfolio-metrics.model'
import {
  Perspective,
  AggregationMethodType,
} from '../analysis/model/metrics.model'
import { LossFilter } from '../api/analyzere/analyzere.model'
import { MatMenuTrigger } from '@angular/material/menu'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-return-period-toggles',
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group {
        width: 100%;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group:not(:last-of-type) {
        margin-bottom: var(--stack-tiny);
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle {
        width: 50%;
      }

      .loss-filter {
        height: 1.3rem;
      }

      .loss-filters-button {
        width: 100%;
        line-height: normal;
        font-size: small;
        padding-left: 2rem;
      }

      .loss-filters-button-reset {
        width: 100%;
        line-height: normal;
        font-size: small;
        color: var(--primary) !important;
      }

      .remove-filter-button {
        font-size: large;
        padding-top: 0.1rem;
        cursor: pointer;
      }

      .selected {
        background-color: var(--primary);
        color: var(--body-inverse);
      }

      .reset-selected {
        color: var(--primary);
      }
    `,
  ],
  template: `
    <mat-button-toggle-group
      *ngIf="!isLayerResults"
      class="app-mat-theme app-button-toggle-group-small"
      name="portfolioType"
      (change)="portfolioTypeChange.emit($event.value)"
      aria-label="Portfolio Type"
      [value]="layerViewId === '2' ? 'Ceded' : portfolioType"
    >
      <mat-button-toggle value="Gross" disableRipple> Gross </mat-button-toggle>
      <mat-button-toggle value="Ceded" disableRipple> Ceded </mat-button-toggle>
      <mat-button-toggle value="Net" disableRipple>Net</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      class="app-mat-theme app-button-toggle-group-small"
      name="perspective"
      (change)="perspectiveChange.emit($event.value)"
      aria-label="Perspective"
      [value]="perspective"
    >
      <mat-button-toggle value="Loss" disableRipple>Loss</mat-button-toggle>
      <mat-button-toggle value="LossRp" disableRipple
        >Loss+RP</mat-button-toggle
      >
      <mat-button-toggle value="UW" disableRipple>UW</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      class="app-mat-theme app-button-toggle-group-small"
      name="aggregation"
      aria-label="Aggregation"
      (change)="aggregationMethodChange.emit($event.value)"
      [value]="aggregationMethod"
    >
      <mat-button-toggle value="OEP" disableRipple>OEP</mat-button-toggle>
      <mat-button-toggle value="AEP" disableRipple>AEP</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      *ngIf="!isLayerResults"
      class="app-mat-theme app-button-toggle-group-small loss-filter"
      name="filters"
      aria-label="Loss Filters"
      matTooltip="Set perspective to Loss to select Loss Filter"
      matTooltipShowDelay="500"
      [ngClass]="resetLossFilter ? 'reset-selected' : 'selected'"
    >
      <button
        [ngClass]="
          resetLossFilter ? 'loss-filters-button-reset' : 'loss-filters-button'
        "
        [disabled]="perspective !== 'Loss' && perspective !== 'LossRp'"
        mat-button
        [matMenuTriggerFor]="lossFiltersMenu"
        #lossFilterMenuTrigger="matMenuTrigger"
      >
        {{ lossFilterButtonLabel }}
      </button>
      <i
        *ngIf="!resetLossFilter"
        class="material-icons remove-filter-button"
        (click)="onRemoveFilterClick()"
      >
        cancel
      </i>
      <mat-menu #lossFiltersMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let f of lossFilters"
          (click)="
            $event.stopPropagation();
            $event.preventDefault();
            onLossFilterClick($event.target.innerText)
          "
        >
          {{ f.description }}
        </button>
      </mat-menu>
    </mat-button-toggle-group>
  `,
})
export class ReturnPeriodTogglesComponent implements OnInit {
  layerViewId = '1'

  @Input() metrics: PortfolioMetrics
  @Input() isLayerResults = false
  @Input() lossFilters: LossFilter[]
  @Input() viewId: string

  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() lossFilterClick = new EventEmitter<string>()
  @ViewChild('lossFilterMenuTrigger') lossSetFilterMenuTrigger: MatMenuTrigger

  get portfolioType() {
    return this.metrics.portfolioType
  }

  get perspective(): Perspective {
    return this.metrics.perspective
  }

  get aggregationMethod() {
    return this.metrics.aggregationMethod
  }

  get lossFilter() {
    return this.metrics.lossFilter
  }

  get lossFilterButtonLabel() {
    const description = this.lossFilters.find(f => f.name === this.lossFilter)
      ?.description as string
    return this.metrics.lossFilter === 'all' ? 'Loss Filters' : description
  }

  get resetLossFilter(): boolean {
    return this.metrics.lossFilter === 'all' || this.perspective !== 'Loss'
  }

  ngOnInit() {
    this.layerViewId = this.viewId ? this.viewId : '1'
  }

  onLossFilterClick(description: string) {
    const name = this.lossFilters.find(f => f.description === description)?.name
    this.lossFilterClick.emit(name)
    this.lossSetFilterMenuTrigger.closeMenu()
  }

  onRemoveFilterClick(): void {
    this.lossFilterClick.emit('all')
  }
}
