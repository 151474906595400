export interface PBITokenResponse {
  embedUrl: string
  reportId: string
  workspaceId: string
  tokenData: PBITokenData
}

export interface PBITokenData {
  token: string
  tokenId: string
  expiration: Date
}

export enum ReportCodes {
  FacDashboard = 'FAC_DASHBOARD',
  RevDashboard = 'REV_DASHBOARD',
  KPIDashboard = 'KPI_DASHBOARD'
}