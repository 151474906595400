import { Pipe, PipeTransform } from '@angular/core'
import { getCurrencySymbol } from '@angular/common'
const ORDERS = [
  { suffix: '∞', conversion: 'unlimited', magnitude: Math.pow(10, 20) },
  { suffix: 'T', conversion: 'Trillion', magnitude: Math.pow(10, 12) },
  { suffix: 'B', conversion: 'Billions', magnitude: Math.pow(10, 9) },
  { suffix: 'M', conversion: 'Millions', magnitude: Math.pow(10, 6) },
  { suffix: 'K', conversion: 'Thousands', magnitude: Math.pow(10, 3) },
  { suffix: '', conversion: 'Base', magnitude: 1 },
]
const ROUND = 10

@Pipe({
  name: 'currencyConversion',
})
export class CurrencyConversionPipe implements PipeTransform {
  transform(
    value: number,
    currency?: string,
    isTooltip = false,
    showThousandsDecimal = false,
    precision = 1,
    conversion?: string
  ): any {
    if (value == null || value === undefined) {
      return null
    }
    if (isNaN(value)) {
      return value
    }
    let selectedConversion = ORDERS.find(item => item.conversion === conversion)
    if (!selectedConversion) {
      selectedConversion = ORDERS[3]
    }

    const init = { short: Math.abs(value), suffix: selectedConversion?.suffix }
    const format = isTooltip ? this.noRound : this.round
    let short = format(init.short / selectedConversion?.magnitude)
    let suffix = init.suffix

    if (!suffix) {
      short = format(short)
      suffix = ''
    } else if (suffix === '∞') {
      return 'Unlimited'
    }
    const symbol = currency ? getCurrencySymbol(currency, 'narrow') : ''

    // If value < 1000 and no fixed decimal needed, remove decimal. Otherwise show 1 decimal place
    let decimals = precision
    if (conversion !== 'Base') {
      decimals = Math.abs(value) < 1000 && !showThousandsDecimal ? 0 : precision
    }
    return `${value < 0 ? '-' : ''}${symbol}${short.toFixed(decimals)}${suffix}`
  }

  private round(x: number, roundBy = ROUND): number {
    return Math.round(x * roundBy) / roundBy
  }

  private noRound(x: number, roundBy = ROUND): number {
    return (x * roundBy) / roundBy
  }
}
