import { lensPath, sort, transpose, view, zip, clone } from 'ramda'
import {
  CompareMetricCategory,
  CompareMetricSetting,
  CompareMetricValue,
  CompareMetricGrossCategory,
} from '../../../model/compare-metrics.model'
import { PortfolioSetEntity } from '../../portfolio-set/portfolio-set.reducer'
import { rejectNil } from 'src/app/shared/util/operators'

export const NO_CATEGORY_NAME = '__NONE__'
const cededOrder = [
  'Deposit Premium',
  'Expected Ceded Premium',
  'Expected Ceded Loss',
  'Expected Ceded Expense',
  'Expected Ceded Margin',
  'Std Dev of Loss',
  'Expected Loss Ratio',
  'Expected Combined Ratio',
]

type ValueAndCurrency = readonly [number, string | undefined]

const getMockValue = (setting: CompareMetricSetting): ValueAndCurrency => {
  let value: number
  const r = Math.random()
  switch (setting.path) {
    case '__RANDOM_PERCENTAGE':
      value = r
      break
    case '__RANDOM_NUMERIC_K':
      value = Math.random() < 0.5 ? r * Math.pow(10, 8) : r * Math.pow(10, 9)
      break
    case '__RANDOM_CURRENCY_M':
    default:
      value = Math.random() < 0.5 ? r * Math.pow(10, 5) : r * Math.pow(10, 6)
      break
  }
  return [value, 'USD']
}

const getValueFromPath = (
  setting: CompareMetricSetting,
  entity: PortfolioSetEntity,
  currency: string
): ValueAndCurrency => {
  const currencyCode = currency

  const path = lensPath(setting.path.split('/'))
  const value: number | undefined = view(path, entity.viewMetrics || {})
  const customMetricResult = entity.viewMetrics.tailMetrics.customMetricsResult?.find(x => x.label === setting.label && setting.metricSettingID === 0 && x.type === setting.category)
  if(customMetricResult){
    return [customMetricResult.metricResult || 0, currencyCode]
  }

  const customGrossMetricResult = entity.viewMetrics.tailMetrics.customMetricsResult?.find(x => x.label === setting.label && setting.metricSettingID === 0 && x.type === setting.category)
  if(customGrossMetricResult){
    return [customGrossMetricResult.metricResult || 0, currencyCode]
  }
  return [value || 0, currencyCode]
}

const getValue = (
  setting: CompareMetricSetting,
  entity: PortfolioSetEntity,
  currency: string
): ValueAndCurrency => {
  if (setting.path.startsWith('__')) {
    return getMockValue(setting)
  }
  return getValueFromPath(setting, entity, currency)
}

const createValue =
  (entity: PortfolioSetEntity, currency: string, conversion: string) =>
  (setting: CompareMetricSetting, index: number): CompareMetricValue => {
    const [value, currencyCode] = getValue(setting, entity, currency)
    return {
      label: setting.label,
      portfolioType: setting.portfolioType,
      category: setting.category,
      weight: setting.weight,
      valueType: setting.valueType,
      rank: index + 1,
      ragOrder: setting.ragOrder,
      grossMetricType: setting.grossMetricType,
      year: setting.year,
      perspective: setting.perspective,
      lossFilter: setting.lossFilter,
      aggregationMethodType: setting.aggregationMethodType,
      path: setting.path,
      currencyCode,
      conversion,
      value,
      metricSettingID: setting.metricSettingID,
      grossMetricSettingID: setting.grossMetricSettingID,
      spPremiumValue: setting.spPremiumValue,
      spReserveValue: setting.spReserveValue,
      spDivesificationValue: setting.spDivesificationValue,
      spCatValue: setting.spCatValue,
      show: setting.show,
      category_order: setting.category_order
    }
  }

const createCompareMetricValues =
  (
    settings: CompareMetricSetting[],
    currencies: string[],
    conversion: string
  ) =>
  (entity: PortfolioSetEntity, index: number): CompareMetricValue[] => {
    return settings.map(
      createValue(
        entity,
        Array.isArray(currencies) ? currencies[index] : currencies,
        conversion
      )
    )
  }

const rankValues = (values: CompareMetricValue[]): CompareMetricValue[] => {
  const sorted =
    values[0].ragOrder === 'Higher' ||
    (values[0].portfolioType === 'Net' && values[0].perspective === 'UW')
      ? // tslint:disable-next-line: no-non-null-assertion
        sort((a, b) => b.value! - a.value!, values)
      : // tslint:disable-next-line: no-non-null-assertion
        sort((a, b) => a.value! - b.value!, values)
  const rankMap = sorted.reduceRight(
    // tslint:disable-next-line: no-non-null-assertion
    (acc, val, i) => ({ ...acc, [val.value!]: i }),
    {} as Record<number, number>
  )
  // tslint:disable-next-line: no-non-null-assertion
  return values.map(v => ({ ...v, rank: rankMap[v.value!] + 1 }))
}

export const updateRanks = (
  valuesByIndex: CompareMetricValue[][]
): CompareMetricValue[][] => {
  const byValue = transpose(valuesByIndex).map(rankValues)
  return transpose(byValue)
}

const groupByCategory = (
  values: CompareMetricValue[]
): CompareMetricCategory[] => {
  const init = {
    categories: [] as string[],
    categoryMap: {} as Record<
      string,
      {
        labels: string[]
        labelMap: Record<string, CompareMetricValue[]>
        hasPortfolioTypes: boolean
      }
    >,
  }
  const { categories, categoryMap } = values.reduce((acc, val) => {
    const category = val.category || NO_CATEGORY_NAME
    if (acc.categoryMap[category] == null) {
      acc.categories.push(category)
      acc.categoryMap[category] = {
        labels: [],
        labelMap: {},
        hasPortfolioTypes: false,
      }
    }
    if (acc.categoryMap[category].labelMap[val.label + val.path] == null) {
      acc.categoryMap[category].labelMap[val.label + val.path] = []
      acc.categoryMap[category].labels.push(val.label + val.path)
    } else if (
      acc.categoryMap[category].labelMap[val.label + val.path].length === 1
    ) {
      acc.categoryMap[category].hasPortfolioTypes = true
    }
    acc.categoryMap[category].labelMap[val.label + val.path].push(val)
    return acc
  }, init)
  return categories.map(category => {
    const { labels, labelMap, hasPortfolioTypes } = categoryMap[category]
    const metrics = labels.map(label => labelMap[label])
    metrics.sort((a, b) => a[0].category_order - b[0].category_order) // order the records by category order (New column added to change the order of the metrics if needed)
    return { category, metrics, hasPortfolioTypes }
  })
}

const sortAndFlipCededMetrics = (
  categories: CompareMetricCategory[][]
): CompareMetricCategory[][] => {
  categories.forEach(cat => {
    const ceded = cat.find(c => c.category === 'Ceded Cost Metrics')
    if (ceded) {
      ceded.metrics = rejectNil(
        cededOrder.map(label => ceded.metrics.find(m => m[0].label === label))
      ).map(metric => {
        // Flip sign of certain Ceded Metrics for display purposes
        if (flipMetricValue(metric)) {
          metric[0].value = metric[0].value * -1
        }
        return clone(metric)
      })
    }
  })
  return categories
}

export const createAllCompareMetricCategoryValues = (
  programIDs: string[],
  portfolioSets: PortfolioSetEntity[],
  settings: CompareMetricSetting[],
  currencies: string[] | any,
  conversion: string | any
): Array<readonly [number, CompareMetricCategory[]]> => {
  // We are adding the gross metric to the settings array here as we are not saving it in the db for custom tail metrics.
  let compareMetricSettings = settings.filter(x => x.metricSettingID === 0 && x.category === 'Tail Metrics')
  compareMetricSettings.forEach(item => {
    const newItem: CompareMetricSetting = {
      ...item,
      category: 'Gross Metrics',
      path: `${item.path}Gross`,
      portfolioType: 'Gross',
      grossMetricType: 'Tail Metrics',
      saveID: 0,
      show: true
    }
    const isGrossMetricPresent = settings.find(x => x.label === item.label && x.category === 'Gross Metrics' && x.metricSettingID === 0)
    if(!isGrossMetricPresent){
      settings.push(newItem)
    }
  })
  const values = portfolioSets.map(
    createCompareMetricValues(settings, currencies, conversion)
  )
  const valuesWithRank = updateRanks(values)
  const categories = valuesWithRank.map(groupByCategory)
  const updatedCategories = sortAndFlipCededMetrics(categories)
  const ids = programIDs.map(id => parseInt(id, 10))
  return zip(ids, updatedCategories)
}

const groupForGrossCategory = (
  cat: CompareMetricCategory[]
): CompareMetricCategory[] => {
  const init = {
    categories: [] as string[],
    categoryMap: {} as Record<
      string,
      {
        labels: string[]
        labelMap: Record<string, CompareMetricValue[]>
        hasPortfolioTypes: boolean
      }
    >,
  }
  const { categories, categoryMap } = cat[0].metrics.reduce((acc, val) => {
    const category = val[0].grossMetricType || NO_CATEGORY_NAME
    if (acc.categoryMap[category] == null) {
      acc.categories.push(category)
      acc.categoryMap[category] = {
        labels: [],
        labelMap: {},
        hasPortfolioTypes: false,
      }
    }
    if (
      acc.categoryMap[category].labelMap[val[0].label + val[0].path] == null
    ) {
      acc.categoryMap[category].labelMap[val[0].label + val[0].path] = []
      acc.categoryMap[category].labels.push(val[0].label + val[0].path)
    } else if (
      acc.categoryMap[category].labelMap[val[0].label + val[0].path].length ===
      1
    ) {
      acc.categoryMap[category].hasPortfolioTypes = true
    }
    acc.categoryMap[category].labelMap[val[0].label + val[0].path].push(val[0])
    return acc
  }, init)

  return categories.map(category => {
    const { labels, labelMap, hasPortfolioTypes } = categoryMap[category]
    const metrics = labels.map(label => labelMap[label])
    return { category, metrics, hasPortfolioTypes }
  })
}

// Combine metric array with gross array and set value and rank to Null for other than Gross metrics.
export const createGrossCompareMetricCategory = (
  grossCategory: CompareMetricCategory[],
  metricCategory: CompareMetricCategory[]
): CompareMetricGrossCategory[] => {
  let grossMetricArray: CompareMetricGrossCategory[] = []
  const grossGroup = groupForGrossCategory(grossCategory)
  const set = new Set(grossGroup.map(cat => cat.category))
  grossMetricArray = clone(
    metricCategory.map(metric => {
      if (set.has(metric.category)) {
        return grossGroup.filter(gross => gross.category === metric.category)[0]
      }
      return metric
    })
  )
  return grossMetricArray.map(grossMetric => ({
    ...grossMetric,
    metrics: grossMetric.metrics.map(metric =>
      metric.map(m =>
        m.category !== 'Gross Metrics'
          ? { ...m, value: undefined, rank: undefined }
          : { ...m, rank: undefined }
      )
    ),
  }))
}

const flipMetricValue = (value: CompareMetricValue[]): boolean => {
  if (
    value[0].value !== 0 &&
    !(
      value[0].label === 'Expected Loss Ratio' ||
      value[0].label === 'Expected Combined Ratio' ||
      value[0].label === 'Std Dev of Loss' ||
      value[0].label === 'Expected Ceded Margin'
    )
  ) {
    return true
  }

  return false
}
