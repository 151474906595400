import { ClientYear } from '../../core/model/client.model'
import { FormControl } from '@angular/forms'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { Observable } from 'rxjs'
import { Client } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import { map, startWith } from 'rxjs/operators'

export interface SelectorData {
  clientID: string
  yearID: string
  programID: string
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-selector',
  styleUrls: ['./reinsurer-selector.component.scss'],
  templateUrl: './reinsurer-selector.component.html',
})
export class ReinsurerSelectorComponent implements OnInit {
  selectorIds: SelectorData
  clientCtrl = new FormControl()
  filteredClients: Observable<Client[]>
  yearCtrl = new FormControl()
  filteredYears: Observable<ClientYear[]>
  programCtrl = new FormControl()
  filteredPrograms: Observable<Study[]>

  @Input() clients: Client[]
  @Input() selectedClientID: string | null
  @Input() selectedYearID: string | null
  @Input() selectedProgramID: string | null

  @Output() populateSelectorClick = new EventEmitter<{
    client: string
    program: string
  }>()

  get years(): ClientYear[] {
    const client = this.clients.find(f => f.id === this.selectorIds.clientID)
    return client ? client.clientYears : []
  }

  get programs(): readonly Study[] {
    const year = this.years.find(f => f.id === this.selectorIds.yearID)
    return year ? year.studies : []
  }

  get canPopulateClick(): boolean {
    return (
      this.selectorIds.programID !== '' &&
      this.selectorIds.yearID !== '' &&
      this.selectorIds.yearID !== ''
    )
  }

  ngOnInit(): void {
    this.selectorIds = {
      clientID: this.selectedClientID || '',
      yearID: this.selectedYearID || '',
      programID: this.selectedProgramID || '',
    }
    this.clientCtrl.setValue(
      this.clients.find(f => f.id === this.selectorIds.clientID)?.name
    )
    this.yearCtrl.setValue(
      this.years.find(f => f.id === this.selectorIds.yearID)?.year
    )
    this.programCtrl.setValue(
      this.programs.find(f => f.id === this.selectorIds.programID)?.name
    )
    this.filteredClients = this.listenToControlValueChanges(
      this.clientCtrl,
      'Clients'
    ) as Observable<Client[]>
    this.filteredYears = this.listenToControlValueChanges(
      this.yearCtrl,
      'Years'
    ) as Observable<ClientYear[]>
    this.filteredPrograms = this.listenToControlValueChanges(
      this.programCtrl,
      'Programs'
    ) as Observable<Study[]>
  }

  onClientChange(description: string): void {
    const id = this.clients.find(f => f.name === description)?.id
    this.selectorIds.clientID = id ? id : ''
    this.selectorIds.yearID = ''
    this.selectorIds.programID = ''
    this.yearCtrl.setValue('', { emitEvent: true })
    this.programCtrl.setValue('', { emitEvent: true })
  }

  onYearChange(description: string): void {
    const id = this.years.find(f => f.year === description)?.id
    this.selectorIds.yearID = id ? id : ''
    this.selectorIds.programID = ''
    this.programCtrl.setValue('', { emitEvent: true })
  }

  onProgramChange(description: string): void {
    const id = this.programs.find(f => f.name === description)?.id
    this.selectorIds.programID = id ? id : ''
  }

  onPopulateClick(): void {
    if (this.canPopulateClick) {
      this.populateSelectorClick.emit({
        client: this.selectorIds.clientID,
        program: this.selectorIds.programID,
      })
    }
  }

  private listenToControlValueChanges(
    control: FormControl,
    filterType: 'Clients' | 'Years' | 'Programs'
  ): Observable<Client[] | ClientYear[] | Study[]> {
    return control.valueChanges.pipe(
      startWith(''),
      map((filterBy: string) => this.filterData(filterType, filterBy))
    )
  }

  private filterData(
    filterType: 'Clients' | 'Years' | 'Programs',
    filter: string
  ): Client[] | ClientYear[] | Study[] {
    return filterType === 'Clients'
      ? this.clients.filter(client => {
          return client.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        })
      : filterType === 'Years'
      ? this.years.filter(year => {
          return year.year.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        })
      : (this.programs as Study[]).filter(program => {
          return program.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        })
  }
}
