import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding,
} from '@angular/core'
import { Terminal } from '../analysis/model/inurance.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ribbon-card',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             align-items: flex-start;
             position: relative;
             cursor: pointer;
           }
     
           :host.disabled {
             cursor: auto;
           }
     
           /** Wrapper */
     
           .wrapper {
             flex: 1 1 auto;
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             align-items: center;
             height: 96px;
             background: var(--bg-2);
             border: 2px solid var(--border-2);
             border-radius: 4px;
             line-height: initial;
             overflow: hidden;
             transition: background 200ms;
           }
     
           :host:hover .wrapper {
             background: var(--bg-2-lit);
           }
     
           .wrapper.source {
             border-right-width: 0;
           }
     
           .wrapper.target {
             border-left-width: 0;
             border-top-left-radius: 0;
             border-bottom-left-radius: 0;
           }
     
           :host.active .wrapper {
             border-color: var(--body);
           }
     
           :host.selected .wrapper {
             border-color: var(--accent);
           }
     
           :host.disabled .wrapper {
             background: var(--bg-1);
             border-color: var(--border-1);
           }
     
           /** Content */
     
           .content,
           .button-text {
             transition: color 200ms;
           }
     
           .content {
             display: flex;
             flex-direction: row;
             justify-content: flex-start;
             align-items: center;
             width: 100%;
             padding: 0 var(--inset-small) var(--stack-tiny);
             color: var(--primary);
             box-sizing: border-box;
           }
     
           :host:hover .content {
             color: var(--primary-lit);
           }
     
           :host.active .content {
             color: var(--body);
           }
     
           :host.disabled .content {
             color: var(--hint);
           }
     
           label {
             font-family: var(--font-family);
             font-weight: var(--font-header-weight);
             margin-left: var(--inset-tiny);
             margin-right: var(--inset);
           }
     
           .lines-2,
           .button-text {
             display: -webkit-box;
             -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             overflow: hidden;
             white-space: initial;
             text-overflow: ellipsis;
           }
     
           .button-text {
             text-align: center;
             flex: 1 1 auto;
             color: var(--accent);
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
           }
     
           :host:hover .button-text {
             color: var(--accent-lit);
           }
     
           :host.disabled .button-text {
             color: var(--hint);
           }
     
           .title-wrapper {
             display: flex;
             flex-direction: column;
             justify-content: space-between;
             align-items: flex-start;
             overflow: hidden;
           }
     
           h3 {
             margin: 0 0 var(--stack-tiny);
           }
     
           .subtitle {
             color: var(--primary);
           }
     
           h3,
           .subtitle {
             text-overflow: ellipsis;
             overflow: hidden;
             white-space: nowrap;
             width: 100%;
           }
     
           /** SVG */
     
           svg {
             position: relative;
             flex: 0 0 auto;
             color: var(--body);
           }
     
           svg.source {
             top: 1.9px;
             left: -3.4px;
           }
     
           path:not(.line) {
             fill: var(--bg-2);
             transition: fill 200ms;
           }
     
           :host:hover path:not(.line) {
             fill: var(--bg-2-lit);
           }
     
           :host.disabled path:not(.line) {
             fill: var(--bg-1);
           }
     
           line,
           path.line {
             fill: none;
             stroke: var(--border-2);
           }
     
           :host.active line,
           :host.active path.line {
             stroke: var(--body);
           }
     
           :host.selected line,
           :host.selected path.line {
             stroke: var(--accent);
           }
     
           :host.disabled line,
           :host.disabled path.line {
             stroke: var(--border-1);
           }
     
           .source line,
           .source path.line {
             stroke-width: 2;
           }
     
           svg.target {
             left: 2.5px;
           }
         `,
  ],
  template: `
    <svg
      *ngIf="_terminal === 'target'"
      xmlns="http://www.w3.org/2000/svg"
      class="target"
      [attr.height]="height + 4"
      [attr.viewBox]="targetViewBox"
    >
      <g>
        <path [attr.d]="targetPath1"></path>
        <line
          stroke-linecap="round"
          [attr.stroke-width]="targetLineWidth"
          [attr.x1]="targetLineWidth / 2"
          [attr.y1]="targetLineWidth / 2"
          [attr.x2]="targetMiddleX"
          y2="292.91107"
        ></line>
        <path [attr.d]="targetPath2"></path>
        <line
          stroke-linecap="round"
          [attr.stroke-width]="targetLineWidth"
          [attr.x1]="targetLineWidth / 2"
          [attr.y1]="580 - targetLineWidth / 2"
          [attr.x2]="targetMiddleX"
          y2="292.50437"
        ></line>
        <line
          stroke-linecap="round"
          [attr.stroke-width]="targetLineWidth"
          [attr.x1]="targetViewWidth - targetLineWidth / 2"
          [attr.y1]="targetLineWidth / 2"
          [attr.x2]="targetLineWidth / 2"
          [attr.y2]="targetLineWidth / 2"
        ></line>
        <line
          stroke-linecap="round"
          [attr.stroke-width]="targetLineWidth"
          [attr.x1]="targetViewWidth - targetLineWidth / 2"
          [attr.y1]="580 - targetLineWidth / 2"
          [attr.x2]="targetLineWidth / 2"
          [attr.y2]="580 - targetLineWidth / 2"
        ></line>
      </g>
    </svg>
    <div
      class="wrapper"
      [ngClass]="wrapperClass"
      [style.height]="height + 'px'"
    >
      <div class="content">
        <label *ngIf="symbol" [ngStyle]="getFontSizeStyle(56)">
          {{ symbol }}
        </label>
        <div *ngIf="buttonText" class="button-text">
          <span [ngStyle]="getFontSizeStyle(26)">{{ buttonText }}</span>
        </div>
        <div *ngIf="!buttonText && (title || subtitle)" class="title-wrapper">
          <h3
            *ngIf="title"
            [ngClass]="{ 'lines-2': !subtitle }"
            [matTooltip]="title"
          >
            <span [ngStyle]="getFontSizeStyle(26)">{{ title }}</span>
          </h3>
          <div class="subtitle" *ngIf="subtitle">
            <span [ngStyle]="getFontSizeStyle(20)">{{ subtitle }}</span>
          </div>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <svg
      *ngIf="_terminal === 'source'"
      xmlns="http://www.w3.org/2000/svg"
      class="source"
      [attr.width]="protrusion + 2"
      [attr.height]="height + 0.2"
      [attr.viewBox]="sourceViewBox"
    >
      <g>
        <path [attr.d]="sourcePath" [attr.transform]="pathTransform" />
        <line
          [attr.y1]="scaleY(-1.4)"
          [attr.x2]="protrusion - 0.5"
          [attr.y2]="scaleY(49)"
          transform="translate(1 0)"
        />
        <line
          x1="-0.3"
          [attr.y1]="scaleY(48)"
          [attr.x2]="protrusion - 0.5"
          y2="-1"
          [attr.transform]="line2Transform"
        />
      </g>
    </svg>
  `,
})
export class RibbonCardComponent {
  /** Whether to show as the source: i.e. angled/chevron protrusion out from the
   * right, or target, i.e. protrusion inward from the left.
   */
  @Input() terminal?: Terminal
  /** Optional number or character to display in large type to the left of
   * the content.
   */
  @Input() symbol?: string | number
  /** Optional display title of the component */
  @Input() title?: string
  /** Optional display subtitle of the component */
  @Input() subtitle?: string
  /** Optional display as centered link-style button text */
  @Input() buttonText?: string

  /** Height of the component. */
  @Input() set height(value: any) {
    this._height = coerceNumberProperty(value)
  }
  get height() {
    return this._height
  }
  _height = 60

  /** Width of the angled/chevron protrusion. */
  @Input() set protrusion(value: any) {
    this._protrusion = coerceNumberProperty(value)
  }
  get protrusion() {
    return this._protrusion
  }
  _protrusion = 16

  /** Shortcut for terminal = "source". */
  @Input() set source(value: any) {
    this._source = coerceBooleanProperty(value)
    if (value) {
      this._target = undefined
    }
  }
  _source: boolean | undefined

  /** Shortcut for terminal = "target". */
  @Input() set target(value: any) {
    this._target = coerceBooleanProperty(value)
    if (value) {
      this._source = undefined
    }
  }
  _target: boolean | undefined

  /** Component is active and border and text colored white */
  @Input() set active(value: any) {
    this._active = coerceBooleanProperty(value)
  }
  get active() {
    return this._active
  }
  @HostBinding('class.active') _active = false

  /** Component is selected and border is highlighted w/ accent color */
  @Input() set selected(value: any) {
    this._selected = coerceBooleanProperty(value)
  }
  get selected() {
    return this._selected
  }
  @HostBinding('class.selected') _selected = false

  /** Component is disabled and border & bg are hinted */
  @Input() set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value)
  }
  get disabled() {
    return this._disabled
  }
  @HostBinding('class.disabled') _disabled = false

  get _terminal(): Terminal {
    return this.terminal ? this.terminal : this._source ? 'source' : 'target'
  }

  get wrapperClass() {
    return {
      source: this._terminal === 'source',
      target: this._terminal === 'target',
    }
  }

  // Content helpers

  get symbolSize(): string {
    return `${this.scaleY(66)}px`
  }

  // SVG positioning helpers

  get sourceViewBox() {
    return `0 0 ${this.protrusion + 2} ${this.scaleY(94)}`
  }

  get sourcePath() {
    return `M${this.scaleY(48)},0,${this.scaleY(96)},${this.protrusion}H0Z`
  }

  get pathTransform() {
    return `translate(${this.protrusion} 0) rotate(90)`
  }

  get line2Transform() {
    return `translate(1 ${this.scaleY(48)})`
  }

  get targetViewWidth() {
    return (160 * this.protrusion) / 16
  }

  get targetViewBox() {
    return `0 0 ${this.targetViewWidth} 580`
  }

  get targetLineWidth() {
    return 13 * (92 / this.height)
  }

  get targetMiddleX() {
    return (
      this.targetViewWidth -
      this.targetLineWidth / 2 -
      (8 * this.protrusion) / 16
    )
  }

  get targetPath1() {
    const w = this.targetViewWidth - this.targetLineWidth / 2
    return `m${w},2.97098l0,282.42932l-${this.targetMiddleX},-282.42932l${this.targetMiddleX},0z`
  }

  get targetPath2() {
    const w = this.targetViewWidth - this.targetLineWidth / 2
    return `m${w},576.07782l0,-277.45288l-${this.targetMiddleX},277.42938l${this.targetMiddleX},0.0235z`
  }

  getFontSizeStyle(baseSize: number) {
    const base = 86
    return {
      fontSize: `${this.scaleY(baseSize, base)}px`,
    }
  }

  scaleY(itemY: number, baseHeight = 92): number {
    return (this.height * itemY) / baseHeight
  }
}
