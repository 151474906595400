import { Dictionary } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { head, partition } from 'ramda'
import { BENCHMARK_MPL_LOBS } from '../../api/benchmark/config/benchmark-stat-mpl-config'
import { isStale } from '../../core/model/general.model'
import graphingColorPalette from '@graphing/utils/graphing-color-palette'
import { CategoryMode } from '../model/benchmark-categories'
import { BenchmarkChartData } from '../model/benchmark-chart'
import { makeGetBenchmarkColorClass } from '../model/benchmark-color'
import {
  BenchmarkMode,
  getBenchmarkMode,
  getBenchmarkSystemMode,
} from '../model/benchmark-mode'
import { BenchmarkOption } from '../model/benchmark-options'
import {
  BenchmarkSubmode,
  parseBenchmarkSubmode,
} from '../model/benchmark-submode'
import {
  BenchmarkDisplayWarningSettings,
  BenchmarkLob
} from '../model/benchmark.model'
import BenchmarkEntityStore from './benchmark-entity-store'
import * as fromReducer from './benchmark.reducer'
import { filterNewlobsInSubmode } from 'src/app/api/benchmark/data/filter-lobs'

export const selectBenchmarkState =
  createFeatureSelector<fromReducer.BenchmarkState>(
    fromReducer.BENCHMARK_FEATURE_KEY
  )

// Modes / Submodes

export const selectBenchmarkModeID = createSelector(
  selectBenchmarkState,
  state => state.mode
)

export const selectBenchmarkMode = createSelector(
  selectBenchmarkModeID,
  modeID => getBenchmarkMode(modeID)
)

export const selectBenchmarkManagePeerSetsMode = createSelector(
  selectBenchmarkState,
  state => getBenchmarkMode(state.managePeerSetsMode) as BenchmarkMode
)

export const selectBenchmarkSystemModeID = createSelector(
  selectBenchmarkModeID,
  selectBenchmarkManagePeerSetsMode,
  (mode, managePeerSetsMode) =>
    getBenchmarkSystemMode(mode, managePeerSetsMode.id)
)

export const selectBenchmarkSubmodeID = createSelector(
  selectBenchmarkState,
  state => state.submode
)

export const selectBenchmarkSubheaderID = createSelector(
  selectBenchmarkState,
  selectBenchmarkSubmodeID,
  (state, submode) => {
    if (submode) {
      const subheader = state.subheaderBySubmode[submode]
      if (subheader) {
        return subheader
      }
      return submode
    }
    return undefined
  }
)

export const selectBenchmarkPrevModeID = createSelector(
  selectBenchmarkState,
  state => state.prevMode
)

export const selectBenchmarkPrevSubmodeID = createSelector(
  selectBenchmarkState,
  state => state.prevSubmode
)

export const selectBenchmarkSubmodesByID = createSelector(
  selectBenchmarkState,
  state => state.submodes
)

export const selectBenchmarkSubmodeCount = createSelector(
  selectBenchmarkSubmodesByID,
  submodesByID => Object.keys(submodesByID).length
)

export const selectBenchmarkTabLevelSubmode = createSelector(
  selectBenchmarkSubmodesByID,
  selectBenchmarkSubmodeID,
  (submodesByID, submode) => (submode ? submodesByID[submode] : null)
)

// Entities

export const selectBenchmarkCompaniesByMode = createSelector(
  selectBenchmarkState,
  state => state.companiesByMode
)

export const selectBenchmarkMetricsByMode = createSelector(
  selectBenchmarkState,
  state => state.metricsByMode
)

export const benchmarkEntitySelectors =
  BenchmarkEntityStore.getSelectors(selectBenchmarkState)

// Parsed Submode

export const selectBenchmarkModeMetricState = createSelector(
  selectBenchmarkMetricsByMode,
  selectBenchmarkSystemModeID,
  (byMode, mode) => byMode[mode]
)

const selectMetricDict = createSelector(
  selectBenchmarkModeMetricState,
  state => state.entities ?? {}
)

export const selectBenchmarkSubmode = createSelector(
  selectMetricDict,
  selectBenchmarkSubmodesByID,
  selectBenchmarkSubheaderID,
  (metricDict, submodesByID, subheader) => {
    if (!subheader) {
      return null
    }

    return parseBenchmarkSubmode(submodesByID[subheader], metricDict)
  }
)

// LOBs

function selectSubmodeLobs(
  mode: BenchmarkMode | null,
  submode: BenchmarkSubmode | null,
  lobs: BenchmarkLob[]
): BenchmarkLob[] {
  if (mode && submode && lobs) {
    const submodeId = submode.useSubmodeLOBs ?? submode.id
    if (submodeId === 'mpl') {
      return BENCHMARK_MPL_LOBS
    }

    const lobAllLinesID = submode?.lobAllLinesID ?? mode?.lobAllLinesID
    return filterNewlobsInSubmode(
      mode,
      submode,
      lobs.filter(
        lob =>
          lob.id === lobAllLinesID ||
          lob.submodes === undefined ||
          lob.submodes.includes(submodeId)
      )
    )
  }
  return []
}

function moveAllLinesLobsToHead(
  mode: BenchmarkMode | null,
  submode: BenchmarkSubmode | null,
  lobs: BenchmarkLob[]
): BenchmarkLob[] {
  return partition(
    lob => lob.id === (submode?.lobAllLinesID ?? mode?.lobAllLinesID),
    lobs
  ).flat()
}

export const selectBenchmarkLobs = createSelector(
  selectBenchmarkMode,
  selectBenchmarkSubmode,
  benchmarkEntitySelectors.lobs.selectAll,
  (mode, submode, lobs) =>
    moveAllLinesLobsToHead(
      mode,
      submode,
      selectSubmodeLobs(mode, submode, lobs)
    )
)

export const selectBenchmarkLobsByID = createSelector(
  selectBenchmarkLobs,
  lobs =>
    lobs.reduce((acc, lob) => {
      acc[lob.id] = lob
      return acc
    }, {} as Dictionary<BenchmarkLob>)
)

export const selectBenchmarkIncompleteDataSettings = createSelector(
  benchmarkEntitySelectors.uiSettings.selectAll,
  (uiSettings) => {
    return uiSettings.find(s => s.id == 'display-warning-settings')?.settings as BenchmarkDisplayWarningSettings
  }
)

export const selectBenchmarkDataRefreshStatus = createSelector(
  selectBenchmarkState,
  state => state.dataRefreshStatus
)

export const selectBenchmarkDefaultLob = createSelector(
  selectBenchmarkLobs,
  entities => head(entities)
)

// Others

export const selectBenchmarkControlsState = createSelector(
  selectBenchmarkState,
  state => state.controls
)

export const selectBenchmarkPeerSetState = createSelector(
  selectBenchmarkState,
  state => state.peerSet
)

export const selectBenchmarkMetricViewState = createSelector(
  selectBenchmarkState,
  state => state.metricView
)

export const selectShowBenchmarkBG = createSelector(
  selectBenchmarkMode,
  selectBenchmarkSubmodeID,
  (mode, submode) => mode?.id !== 'manage-peers' && submode === null
)

export const selectBenchmarkData = createSelector(
  selectBenchmarkState,
  state => state.data
)

export const selectBenchmarkCharts = createSelector(
  selectBenchmarkData,
  state => state?.charts ?? []
)

export const selectBenchmarkReinsuranceSummaryData = createSelector(
  selectBenchmarkState,
  state => state.reinsuranceSummary?.charts ?? []
)

export const selectBenchmarkChartsMaxRows = createSelector(
  selectBenchmarkData,
  state => {
    let maxRows = 0
    state?.charts.forEach(c => {
      if (c.type === 'table') {
        maxRows = Math.max(maxRows, c.data.series.length)
      }
    })
    return maxRows
  }
)

export const selectBenchmarkTargetValuesLobTable = createSelector(
  selectBenchmarkData,
  state => state?.targetValuesLobTable
)

export const selectBenchmarkTargetValuesStatesTable = createSelector(
  selectBenchmarkData,
  state => state?.targetValuesStatesTable
)

export const selectBenchmarkTargetValuesLobStatesTable = createSelector(
  selectBenchmarkData,
  state => state?.targetValuesLobStatesTable
)

export const selectBenchmarkLobOverrides = createSelector(
  selectBenchmarkData,
  state => state?.lobOverrides
)

export const selectBenchmarkChartTargetValues = createSelector(
  selectBenchmarkCharts,
  charts =>
    charts
      .map(c => c.data as BenchmarkChartData<any>)
      .find(d => d?.targetValues)?.targetValues
)

export const selectBenchmarkAbbreviations = createSelector(
  selectBenchmarkCharts,
  charts =>
    charts
      .map(c => c.data as BenchmarkChartData<any>)
      .find(d => d?.abbreviations != null)?.abbreviations
)

export const selectBenchmarkCategoryOptions = createSelector(
  selectBenchmarkState,
  state => state.controls.categoriesBySubmode?.lorri?.categories
)

const getColorClass = makeGetBenchmarkColorClass(
  [
    ...graphingColorPalette.slice(1),
    'indigo',
    'taupe',
    'mauve',
    'mint',
    'gold',
    'olive',
    'pewter',
  ],
  { asColorOnly: true }
)

const toSublineOption = (
  d: CategoryMode,
  value: boolean,
  color: string
): BenchmarkOption => ({
  id: d.id || '',
  type: 'subline',
  controlType: 'legend-tag',
  name: d.name || '',
  value,
  color,
  style: 'solid',
})

export const selectBenchmarkCategoryControlsOptions = createSelector(
  selectBenchmarkCharts,
  selectBenchmarkControlsState,
  selectBenchmarkSubmodeID,
  (charts, controls, submode) => {
    const subcategories = charts
      .map(c => c.data as BenchmarkChartData<any>)
      .find(d => d?.categoryOptions != null)?.categoryOptions?.subcategories

    if (!subcategories || !submode) {
      return []
    }

    return subcategories.map((d, i): BenchmarkOption => {
      const options = controls?.optionsBySubmode?.[submode]
      const option = options && options[d.id ?? '']
      const value = option === undefined ? false : option
      const color = getColorClass(i)
      return toSublineOption(d, value, color)
    })
  }
)

export const selectBenchmarkSubCategoryOptions = createSelector(
  selectBenchmarkCategoryOptions,
  category => category
)

export const selectBenchmarkLoading = createSelector(
  selectBenchmarkState,
  selectBenchmarkSubmodeCount,
  (state, metricOptionsBySubmodeCount) =>
    state.loading || metricOptionsBySubmodeCount === 0
)

export const selectBenchmarkExtents = createSelector(
  selectBenchmarkState,
  state => state.extents
)

export const selectBenchmarkDataStale = createSelector(
  selectBenchmarkState,
  isStale
)

export const selectBenchmarkFullExportState = createSelector(
  selectBenchmarkState,
  state => state.fullExport
)

export const selectBenchmarkMaximizeIndex = createSelector(
  selectBenchmarkState,
  state => state.maximizeIndex
)

export const selectBenchmarkDownloadingChart = createSelector(
  selectBenchmarkState,
  state => state.downloadingChart
)

export const selectBenchmarkExportState = createSelector(
  selectBenchmarkState,
  selectBenchmarkAbbreviations,
  (state, abbreviations) => ({ ...state.export, abbreviations })
)

export const selectBenchmarkError = createSelector(
  selectBenchmarkState,
  state => state.error
)
