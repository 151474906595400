import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DuplicateStructureDialogComponentData {
  isGroup: boolean
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-duplicate-structure',
  templateUrl: './duplicate-structure-dialog.component.html',
})
export class DuplicateStructureDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DuplicateStructureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicateStructureDialogComponentData
  ) {}

  cancel() {
    this.dialogRef.close()
  }
}
