import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Size } from '@shared/size.mixin'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../../model/portfolio-metrics.model'
import { AggregationMethodType, Perspective } from '../../../model/metrics.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metrics-return-period',
  styleUrls: ['./compare-metrics-return-period.component.scss'],
  templateUrl: './compare-metrics-return-period.component.html',
})

export class CompareMetricsReturnPeriodComponent {
  @Input() metrics: PortfolioMetrics
  @Input() size: Size

  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()
  @Output() returnPeriod4Change = new EventEmitter<number>()
  @Output() returnPeriod5Change = new EventEmitter<number>()
  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()

  get returnPeriodData() {
    if (this.metrics) {
      return this.metrics.returnPeriodData
    }
  }

  get portfolioType() {
    return this.metrics.portfolioType
  }

  get aggregationMethod() {
    return this.metrics.aggregationMethod
  }

  get returnPeriod1(): number {
    return this.metrics.returnPeriod1
  }

  get returnPeriod2(): number {
    return this.metrics.returnPeriod2
  }

  get returnPeriod3(): number {
    return this.metrics.returnPeriod3
  }

  get returnPeriod4(): number {
    return this.metrics.returnPeriod4
  }

  get returnPeriod5(): number {
    return this.metrics.returnPeriod5
  }

  get perspective(): Perspective {
    return this.metrics.perspective
  }
}
