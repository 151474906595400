import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { CreditStructure } from './../../model/credit-structure.model'
import {
  CreditCalculationStructureActions,
  CreditStructureActions,
} from '../structure/credit-structure.actions'
import { CreditTransactionActions } from '../transaction/credit-transaction.actions'
import { CreditResultsActions } from '../results/credit-results.actions'

export interface State {
  currentCreditStructure?: CreditStructure
  isDirty: boolean
  isSaving: boolean
}

export const initialState: State = {
  isDirty: false,
  isSaving: false,
}

const currentCreditStructureReducer = createReducer(
  initialState.currentCreditStructure,
  on(
    CreditStructureActions.fetchSuccess,
    CreditStructureActions.postSuccess,
    CreditStructureActions.postSuccess,
    CreditStructureActions.createSuccess,
    CreditStructureActions.setSelectedStructure,
    CreditStructureActions.updateSelectedStructure,
    (_, { structure }) => structure
  ),
  on(CreditStructureActions.resetSelectedStructure, _ => undefined)
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    CreditCalculationStructureActions.createSuccess,
    CreditCalculationStructureActions.update,
    CreditStructureActions.updateSelectedStructure,
    CreditTransactionActions.addSuccess,
    CreditTransactionActions.updateSuccess,
    CreditTransactionActions.delete,
    CreditResultsActions.setSelectedScenario,
    _ => true
  ),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.putSuccess,
    CreditStructureActions.resetSelectedStructure,
    _ => false
  )
)

const isSavingReducer = createReducer(
  initialState.isSaving,
  on(CreditCalculationStructureActions.save, _ => true),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.postFailure,
    CreditCalculationStructureActions.putSuccess,
    CreditCalculationStructureActions.putFailure,
    CreditStructureActions.resetSelectedStructure,
    _ => false
  )
)

const designReducer = combineReducers<State>({
  currentCreditStructure: currentCreditStructureReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return designReducer(state, action)
}
