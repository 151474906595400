import { createAction, props } from '@ngrx/store'
import { Program } from '../../../core/model/program.model'
import { ApplicationError } from '../../../error/model/error'
import { LayerModelingDimensionChangeEvent } from '../../layer-modeling/layer-modeling.model'
import {
  Change,
  OptimizationCandidateResultEntity,
  ResultsView,
} from '../optimization.model'

export const generateCandidates = createAction(
  '[Optimization] Generate Candidates'
)

export const generateCandidatesSuccess = createAction(
  '[Optimization] Generate Candidates Success',
  props<{ values: OptimizationCandidateResultEntity[] }>()
)

export const generateCandidatesFailure = createAction(
  '[Optimization] Generate Candidates Failure',
  props<{ error: ApplicationError }>()
)

export const updateCandidates = createAction(
  '[Optimization] Update Candidates',
  props<{ changes: Change<OptimizationCandidateResultEntity>[] }>()
)

export const getCandidatesPortfolioMetrics = createAction(
  '[Optimization] Get Portfolio Metrics',
  props<{ id: string }>()
)

export const getCandidatesPortfolioMetricsSuccess = createAction(
  '[Optimization] Get Portfolio Metric Success',
  props<{ changes: Change<OptimizationCandidateResultEntity>[] }>()
)

export const getCandidatesPortfolioMetricsFailure = createAction(
  '[Optimization] Get Portfolio Metric Failure',
  props<{ id: string; error: ApplicationError }>()
)

export const setView = createAction(
  '[Optimization] Set View',
  props<{ view: ResultsView }>()
)

export const setChartDimensionProp = createAction(
  '[Optimization] Set Dimension Prop',
  props<LayerModelingDimensionChangeEvent>()
)

export const save = createAction('[Optimization] Save')

export const saveSuccess = createAction(
  '[Optimization] Save Success',
  props<{
    lastCreated: number
    details: {
      program: Program
      clientID: string
      studyID: string
      otherPrograms?: Program[]
    }[]
  }>()
)

export const saveFailure = createAction(
  '[Optimization] Save Failure',
  props<{ error: ApplicationError }>()
)
