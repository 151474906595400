import { select, Store } from '@ngrx/store'
import { clone, curry } from 'ramda'
import { of } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { ApiResponse } from '../../../api/model/api.model'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import { selectCurrentClientID } from '../../../core/store/broker/broker.selectors'
import * as fromProgramGroupMember from '../../../core/store/program-group-member.reducer'
import { selectProgramGroupMemberState } from '../../../core/store/program-group-member.selectors'
import { State as ProgramGroupState } from '../../../core/store/program-group/program-group.state.facade'
import { selectProgramGroupState } from '../../../core/store/program-group/program-group.selectors'
import { State as ProgramState } from '../../../core/store/program/program.state.facade'
import { selectProgramState } from '../../../core/store/program/program.selectors'
import { errorPayload } from '../../../error/model/error'
import {
  ProgramGroup,
  ProgramGroupMember,
  ProgramGroupSet,
} from './program-group.model'

interface EntitiesDB {
  programState: ProgramState
  groupState: ProgramGroupState
  memberState: fromProgramGroupMember.State
}

interface State {
  res: ProgramGroupSet
  db: EntitiesDB
}

const { selectAll: selectAllMembers } =
  fromProgramGroupMember.adapter.getSelectors()

const selectProgram = (state: State, programID?: string): Program => {
  if (!programID) {
    throw new Error(`No program group member has program ID '${programID}'`)
  }
  const program = state.db.programState.entities[programID]
  if (!program) {
    throw new Error(`No program w/ id '${programID}'`)
  }
  return clone(program)
}

const selectGroup = (state: State, groupID?: string): ProgramGroup => {
  if (!groupID) {
    throw new Error(`No program group member has group ID '${groupID}'`)
  }
  const group = state.db.groupState.entities[groupID]
  if (!group) {
    throw new Error(`No program group w/ ID '${groupID}'`)
  }
  return clone(group)
}

const getProgramForProgramGroup = (
  state: State,
  id: string
): Program | undefined => {
  let i = 1
  let flag = false
  while (!flag) {
    if (state.db.memberState.entities !== null) {
      const memberEntity = state.db.memberState.entities[id]
      if (memberEntity && memberEntity.programID === undefined) {
        const childMember = selectMembersByParentID(
          state,
          memberEntity.programGroupID
        )
        if (childMember) {
          id = childMember[0].id
        }
      } else if (memberEntity && memberEntity.programID) {
        const program = state.db.programState.entities[memberEntity.programID]
        if (program) {
          return program
        }
      }
      i++
      if (i === 1000) {
        flag = true
      }
    }
  }
  return undefined
}

const selectMembersByParentID = (
  state: State,
  parentID?: string
): ProgramGroupMember[] => {
  if (!parentID) {
    throw new Error(`No program group member has parent ID '${parentID}'`)
  }
  return selectAllMembers(state.db.memberState)
    .filter(m => m.parentGroupID === parentID)
    .map(m => clone(m))
}

const pushMemberReducer =
  (parentGroup: ProgramGroup) =>
  (state: State, member: ProgramGroupMember): State => {
    if (member.type === 'programGroup') {
      const program = getProgramForProgramGroup(state, member.id)
      if (!parentGroup.studyID && program) {
        parentGroup.studyID = program.studyID
      }
    }
    state.res.programGroupMembers.push(member)

    if (member.type === 'program') {
      const program = selectProgram(state, member.programID)
      program.parentGroupID = parentGroup.id
      state.res.programs.push(program)

      if (!parentGroup.studyID) {
        parentGroup.studyID = program.studyID
      }
      return state
    }

    const groupID = member.programGroupID

    const programGroup = selectGroup(state, groupID)
    state.res.programGroups.push(programGroup)

    const childMembers = selectMembersByParentID(state, groupID)
    return childMembers.reduce(pushMemberReducer(programGroup), state)
  }

export const loadProgramGroupEntities = (
  groupID: string,
  clientID: string | null,
  programState: ProgramState,
  groupState: ProgramGroupState,
  memberState: fromProgramGroupMember.State
): ProgramGroupSet => {
  const res: ProgramGroupSet = {
    programs: [],
    programGroups: [],
    programGroupMembers: [],
  }
  if (!clientID) {
    console.error('Cannot load program group entities w/ empty client')
    return res
  }
  const state: State = {
    res,
    db: {
      programState,
      groupState,
      memberState,
    },
  }

  const programGroup = selectGroup(state, groupID)
  state.res.programGroups.push(programGroup)

  // Recurse through group's child members, pushing all descendents to response
  const groupMembers = selectMembersByParentID(state, groupID)
  const next = groupMembers.reduce(pushMemberReducer(programGroup), state)

  // Update all program group client IDs
  next.res.programGroups.forEach(g => (g.clientID = clientID))
  return next.res
}

export const loadProgramGroupEntitiesResponse = curry(
  (
    store: Store<AppState>,
    groupID: string,
    parentID: string
  ): ApiResponse<ProgramGroupSet & { id: string; parentGroupID: string }> =>
    of(groupID).pipe(
      withLatestFrom(
        store.pipe(select(selectCurrentClientID)),
        store.pipe(select(selectProgramState)),
        store.pipe(select(selectProgramGroupState)),
        store.pipe(select(selectProgramGroupMemberState))
      ),
      map(([id, ...params]) => {
        try {
          const res = loadProgramGroupEntities(id, ...params)
          return { data: { ...res, id, parentGroupID: parentID } }
        } catch (e) {
          const msg = e && e.message
          const error = errorPayload('Failed to add Program Group', msg)
          return { error }
        }
      })
    )
)
