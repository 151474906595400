<div
  *ngIf="eventResults.length === 0"
  class="swiper-container"
  [swiper]="tower3DSwiperConfig"
>
  <div class="swiper-wrapper">
    <div
      [ngClass]="towerClass"
      *ngFor="let e of structureEntities; let i = index; trackBy: trackByID"
    >
      <app-tower-3d
        *ngIf="!loadingGroup"
        [baseScale]="scale"
        [name]="structureNameByEntity[e.program.id]"
        [layers]="e.cededLayers"
        [layersData]="e.program.layerData"
        [sharedIDGroup]="sharedIDGroup"
      ></app-tower-3d>
    </div>
  </div>
  <div class="swiper-scrollbar"></div>
</div>
<div
  *ngIf="eventResults.length > 0 && !loadingGroup"
  class="result-container"
>
  <div
    #tableResultsSwiper
    class="swiper-container table"
    [swiper]="configGroup"
  >
    <div class="swiper-wrapper table">
      <div
        *ngFor="let result of eventResults; let i = index"
        class="swiper-slide table"
      >
        <h2 class="tower-title">Loss Event {{ i + 1 }}</h2>
        <div class="table-result">
          <app-scenario-event-result-table
            [eventResult]="result"
            [animating]="animating"
            (cededLossClick)="onCededLossClick($event)"
          >
          </app-scenario-event-result-table>
        </div>
      </div>
    </div>
    <div *ngIf="eventResults.length > 1" class="swiper-button-prev" (click)="onPrev()"></div>
    <div *ngIf="eventResults.length > 1" class="swiper-button-next" (click)="onNext()"></div>
  </div>
  <div
    class="swiper-container tower-results"
    [swiper]="tower3DSwiperConfig"
  >
    <div class="swiper-wrapper">
      <div
        [ngClass]="towerClass"
        *ngFor="
              let e of structureEntities;
              let i = index;
              trackBy: trackByID
            "
      >
        <app-tower-3d
          *ngIf="!loadingGroup"
          [baseScale]="scale"
          [name]="structureNameByEntity[e.program.id]"
          [layers]="e.cededLayers"
          [layersData]="e.program.layerData"
          [sharedIDGroup]="sharedIDGroup"
          [animating]="animating"
          [eventResults]="eventResultByEntity[e.program.id]"
          [eventResultStructure]="e.program"
          (animationEnd)="animationEndSubject$.next(true)"
          (eventAnimationIndex)="animationIndexSubject$.next($event)"
        ></app-tower-3d>
      </div>
    </div>
    <div class="swiper-scrollbar"></div>
  </div>
</div>
