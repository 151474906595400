<h1 mat-dialog-title><mat-icon class="warningIcon">warning</mat-icon>Layer delete warning</h1>
<mat-dialog-content>
  <div>
    <div>There are FOTs, Quotes or Expiring panels related to this layer. This action will permanently delete them.</div>
    <div class="acknowledgement"><span><input type="checkbox" [(ngModel)]="isAcknowledged"> Continue and delete layer and all related FOTs, Quotes, Expiring panels?</span></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
  <button *ngIf="isAcknowledged"
    mat-raised-button
    (click)="confirm()"
    color="accent"
  >
    Confirm
  </button>
</mat-dialog-actions>
