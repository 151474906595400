import { createSelector } from '@ngrx/store'
import { selectEditorState } from './analysis.selectors'

export const selectAnalysisPanels = createSelector(
  selectEditorState,
  state => state.panels
)

export const selectAnalysisPortfolioMetricsOpen = createSelector(
  selectAnalysisPanels,
  state => state.portfolioMetricsOpen
)

export const selectAnalysisLayersPanelOpen = createSelector(
  selectAnalysisPanels,
  state => state.layersOpen
)

export const selectAnalysisPropertiesPanelOpen = createSelector(
  selectAnalysisPanels,
  state => state.propertiesOpen
)

export const selectAnalysisLossSetGroupEditor = createSelector(
  selectAnalysisPanels,
  state => state.lossSetGroupEditor
)

export const selectAnalysisPanelsCollapseBySection = createSelector(
  selectAnalysisPanels,
  state => state.collapse
)
