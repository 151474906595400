import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-quote-expiring-panel-confirmation-dialog',
  templateUrl: './quote-expiring-panel-confirmation-dialog.component.html',
  styleUrls: ['./quote-expiring-panel-confirmation-dialog.component.scss'],
})
export class QuoteExpiringPanelConfirmationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<QuoteExpiringPanelConfirmationDialogComponent>
  ) {}

  confirm(): void {
    this.dialogRef.close('Confirmed')
  }
}
