import { ExploreSummaryView } from "src/app/api/model/backend.model"

export interface Study {
  id: string
  carrierYearID: string
  name: string
  programIDs: Array<string | number>
  folders?: Folder[]
  imageSrc?: string
  description?: string
  opportunity_id?: string
  credit_sage_portfolio_id?: string
  executive_summary: string
  explore_gross_views?: ExploreSummaryView[]
}

export interface SectorAssumptionLookupResponse {
  roeSector: number
  epsVolatilitySector: number
  intercept: number
}

export interface PortfolioUserInputsResponse {
  bookValue: number
  prospectiveROE: number
  epsVolatility: number
  taxRate: number
}

export interface Folder {
  name: string
  id: string
  studyID: string
  programIDs: Array<string | number>
  folderIDs: Folder[]
  parentID?: string
}

export interface MappingLabels {
  map1: string | null
  map2: string | null
  map3: string | null
  map4: string | null
  map5: string | null
}

export const DEFAULT_MAPPING_LABELS: MappingLabels = {
  map1: 'Mapping 1',
  map2: 'Mapping 2',
  map3: 'Mapping 3',
  map4: 'Mapping 4',
  map5: 'Mapping 5',
}

export interface LossSetMappingLabelsResponse {
  id: string;
  mappingLabels: MappingLabels;
}
