import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { SalesforceUser, User } from 'src/app/api/model/backend.model'
import { fetchAllUsers } from 'src/app/contact-support/store/contact-support.actions'
import {
  selectAllUsers,
  SelectUserEmailListSelector,
} from 'src/app/contact-support/store/contavt-support.selectors'
import { Client, ClientYear } from 'src/app/core/model/client.model'
import { Study } from 'src/app/core/model/study.model'
import { AppState } from 'src/app/core/store'
import { selectUserName } from 'src/app/core/store/auth/auth.selectors'
import {
  selectCurrentClient,
  selectCurrentStudy,
  selectCurrentYear,
} from 'src/app/core/store/broker/broker.selectors'
import { IControl } from 'src/app/management-information/store/management-information.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-contact-support-icon-container',
  templateUrl: './contact-support-icon-container.component.html',
  styleUrls: ['./contact-support-icon-container.component.scss'],
})
export class ContactSupportIconContainerComponent implements OnInit, OnDestroy {
  sageUserName$: Observable<string | null>
  currentClient$: Observable<Client | null>
  currentYear$: Observable<ClientYear | null>
  allUsers$: Observable<SalesforceUser[] | null>
  userEmailListSelector$: Observable<IControl>
  currentProgram$: Observable<Study | undefined>
  private destroy$ = new Subject()
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.sageUserName$ = this.store.pipe(select(selectUserName))
    this.currentClient$ = this.store.pipe(select(selectCurrentClient))
    this.currentYear$ = this.store.pipe(select(selectCurrentYear))
    this.currentProgram$ = this.store.pipe(select(selectCurrentStudy))
    this.allUsers$ = this.store.pipe(select(selectAllUsers))
    this.userEmailListSelector$ = this.store.pipe(
      select(SelectUserEmailListSelector)
    )

    this.store.dispatch(fetchAllUsers())
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
