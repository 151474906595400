import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { assoc, dissoc, head, lensPath, set, view } from 'ramda'
import { ApplicationError } from '../../error/model/error'
import { md5 } from '@shared/util/hash'
import { mutableOn } from '@shared/util/mutable-on'
import { toArray } from '@shared/util/operators'
import { reduceReducers } from '@shared/util/reduce-reducers'
import {
  BenchmarkModeID,
  BenchmarkSystemModeID,
  benchmarkSystemModeIDs,
  getBenchmarkSystemMode,
} from '../model/benchmark-mode'
import {
  BenchmarkCompany,
  BenchmarkPeerSet,
  ExcludePeerset,
} from '../model/benchmark.model'
import { isBenchmarkPeerSet } from '../model/benchmark.util'
import * as fromControlActions from './benchmark-controls.actions'
import * as fromActions from './benchmark-peer-set.actions'
import { setBenchmarkMode, changeBenchmarkMode } from './benchmark.actions'

export const NEW_PEER_SET_ID = '__new__'

export interface BenchmarkPeerSetEntity {
  peerSet: BenchmarkPeerSet
  hash: string
  dirty: boolean
  new: boolean
  updating: boolean
}

interface ExtendedState {
  activeActionByMode: Partial<Record<BenchmarkModeID, string>>
  error: ApplicationError | null
  manageSelected: string | number | null
  userHasAccessToTarget: boolean
  selectedByMode: Record<BenchmarkModeID, string | number>
  excludeDataByMode: Partial<
    Record<BenchmarkModeID, Record<string | number, boolean>>
  >
  excludeQuantileByMode: Partial<
    Record<BenchmarkModeID, Record<string | number, boolean>>
  >
  restoreTime?: number
  // Last successful fetch time in UNIX epoch milliseconds
  fetchTimeByMode: Record<BenchmarkSystemModeID, number | null>
}

export type BenchmarkPeerSetState = EntityState<BenchmarkPeerSetEntity> &
  ExtendedState

const adapter = createEntityAdapter<BenchmarkPeerSetEntity>({
  selectId: entity => String(entity.peerSet.id),
})

export const benchmarkPeerSetAdapter = adapter

const buildNewPeerSetID = (mode: BenchmarkModeID = 'global') =>
  NEW_PEER_SET_ID + mode

export const initialState: BenchmarkPeerSetState = adapter.getInitialState({
  activeActionByMode: {},
  error: null,
  manageSelected: null,
  userHasAccessToTarget: false,
  selectedByMode: benchmarkSystemModeIDs.reduce((acc, mode) => {
    acc[mode] = buildNewPeerSetID(mode)
    return acc
  }, {} as Record<BenchmarkModeID, string | number>),
  hide: [],
  excludeDataByMode: {},
  savedExcludeDataByMode: {},
  savedIndividualPeerSetIds: [],
  excludeQuantileByMode: {},
  fetchTimeByMode: benchmarkSystemModeIDs.reduce((acc, mode) => {
    acc[mode] = null
    return acc
  }, {} as Record<BenchmarkSystemModeID, number | null>),
})

// State Reducers

const activeAction = createReducer(
  initialState.activeActionByMode,
  on(fromActions.fetchBenchmarkCompanyPeerSets, (state, { entity }) =>
    assoc(entity.mode, 'Loading', state)
  ),
  on(fromActions.updateBenchmarkPeerSet, (state, { entity }) =>
    assoc(entity.mode, 'Saving', state)
  ),
  on(fromActions.addBenchmarkPeerSet, (state, { entity }) =>
    assoc(entity.mode, 'Adding', state)
  ),
  on(fromActions.removeBenchmarkPeerSet, (state, { entity }) =>
    assoc(entity.mode, 'Removing', state)
  ),
  on(fromActions.setOverrideShowBenchmarkPeerSet, (state, { peerSet }) =>
    assoc(peerSet.mode, 'Updating', state)
  ),
  on(
    fromActions.fetchBenchmarkCompanyPeerSetsSuccess,
    (state, { peerSets }) => {
      const mode = head(peerSets)?.mode
      if (mode) {
        return dissoc(mode, state)
      }
      return state
    }
  ),
  on(
    fromActions.fetchBenchmarkCompanyPeerSetsFailure,
    fromActions.updateBenchmarkPeerSetSuccess,
    fromActions.updateBenchmarkPeerSetFailure,
    fromActions.addBenchmarkPeerSetSuccess,
    fromActions.addBenchmarkPeerSetFailure,
    fromActions.removeBenchmarkPeerSetSuccess,
    fromActions.removeBenchmarkPeerSetFailure,
    // @ts-ignore
    (state, { entity }) => dissoc(entity.mode, state)
  ),
  on(
    fromActions.setOverrideShowBenchmarkPeerSetSuccess,
    fromActions.setOverrideShowBenchmarkPeerSetFailure,
    // @ts-ignore
    (state, { peerSet }) => dissoc(peerSet.mode, state)
  )
)

const errorReducer = createReducer(
  initialState.error,
  on(fromActions.fetchBenchmarkCompanyPeerSetsFailure, (_, { error }) => error),
  on(
    fromActions.fetchBenchmarkCompanyCustomPeerSetAccessFailure,
    (_, { error }) => error
  ),
  on(
    fromActions.fetchBenchmarkCompanyPeerSets,
    fromActions.fetchBenchmarkCompanyPeerSetsSuccess,
    // @ts-ignore
    () => null
  )
)

const manageSelected = createReducer(
  initialState.manageSelected,
  on(fromActions.setBenchmarkManagePeerSetSelected, (_, { id }) => id),
  on(fromActions.addBenchmarkPeerSetSuccess, (_, { entity }) => entity.id),
  on(fromActions.removeBenchmarkPeerSetSuccess, (state, { entity }) =>
    state === entity.id ? initialState.manageSelected : state
  ),
  on(
    fromActions.clearBenchmarkPeerSets,
    fromControlActions.SetBenchmarkControlsTargetCompany,
    fromControlActions.ClearBenchmarkControls,
    setBenchmarkMode,
    // @ts-ignore
    () => null
  )
)

const userHasAccessToTarget = createReducer(
  initialState.userHasAccessToTarget,
  on(
    fromActions.fetchBenchmarkCompanyCustomPeerSetAccessSuccess,
    (_, { hasAccessToTarget }) => hasAccessToTarget
  ),
  on(changeBenchmarkMode, () => false)
)

const selectedByMode = createReducer(
  initialState.selectedByMode,
  on(
    fromActions.fetchBenchmarkCompanyPeerSetsSuccess,
    (state, { peerSets }) => {
      // Peer sets are selected by target company from each mode,
      // find which mode this set of peer sets are
      const mode = head(peerSets)?.mode
      if (mode && !peerSets.find(ps => ps.id === state[mode])) {
        // Previous selected for this mode is not in this group of
        // peer sets, so set it to the default (either the first or
        // the initial state)
        const selected =
          head(peerSets.filter(ps => ps.mode === mode))?.id ??
          initialState.selectedByMode[mode]
        return assoc(mode, selected, state)
      }
      return state
    }
  ),
  on(fromActions.setBenchmarkSelectedPeerSet, (state, { mode, id }) => ({
    ...state,
    [mode]: id,
  })),
  on(fromActions.addBenchmarkPeerSetSuccess, (state, { entity }) => ({
    ...state,
    [entity.mode]: entity.id,
  })),
  on(fromActions.removeBenchmarkPeerSetSuccess, (state, { entity }) => {
    const mode = entity.mode
    return {
      ...state,
      [mode]: initialState.selectedByMode[mode],
    }
  }),
  on(
    fromActions.clearBenchmarkPeerSets,
    fromControlActions.ClearBenchmarkControls,
    (state, { mode }) => ({
      ...state,
      [mode]: initialState.selectedByMode[mode],
    })
  )
)

const toggleModeTruthByID = (
  state: Partial<Record<BenchmarkModeID, Record<string | number, boolean>>>,
  {
    mode,
    id,
    value,
  }: { mode: BenchmarkModeID; id: string | number; value?: boolean }
): Record<string, Record<string | number, boolean>> => {
  let next = state[mode] ?? {}
  const nextValue = value != null ? value : !next[id]
  next = assoc(id as string, nextValue, next)
  return assoc(mode, next, state)
}

const toggleModeTruthByIDMulti = (
  state: Partial<Record<BenchmarkModeID, Record<string | number, boolean>>>,
  {
    mode,
    excludePeerset,
  }: { mode: BenchmarkModeID; excludePeerset: ExcludePeerset[] }
): Record<string, Record<string | number, boolean>> => {
  let next = state[mode] ?? {}
  excludePeerset.map(ps => {
    const nextValue = ps.value != null ? ps.value : !next[ps.id]
    next = assoc(ps.id as string, nextValue, next)
  })

  return assoc(mode, next, state)
}

const excludeDataByMode = createReducer(
  initialState.excludeDataByMode,
  on(fromActions.toggleBenchmarkPeerSetDataExcluded, toggleModeTruthByID),
  on(fromActions.clearBenchmarkPeerSets, () => initialState.excludeDataByMode),
  on(fromControlActions.ClearBenchmarkControls, (state, { mode }) =>
    dissoc(mode, state)
  ),
  on(
    fromActions.restoreBenchmarkPeerSetSettingsSuccess,
    (state, { updates }) => updates.excludeDataByMode ?? state
  )
)

const excludeQuantileByMode = createReducer(
  initialState.excludeQuantileByMode,
  on(fromActions.toggleBenchmarkPeerSetQuantileExcluded, toggleModeTruthByID),
  on(
    fromActions.toggleMultiBenchmarkPeerSetQuantileExcluded,
    toggleModeTruthByIDMulti
  ),
  on(
    fromActions.clearBenchmarkPeerSets,
    () => initialState.excludeQuantileByMode
  ),
  on(fromControlActions.ClearBenchmarkControls, (state, { mode }) =>
    dissoc(mode, state)
  ),
  on(
    fromActions.restoreBenchmarkPeerSetSettingsSuccess,
    (state, { updates }) => updates.excludeQuantileByMode ?? state
  )
)

const restoreTime = createReducer(
  initialState.restoreTime,
  on(fromActions.restoreBenchmarkPeerSetSettingsSuccess, () => Date.now())
)

const fetchTimeByMode = createReducer(
  initialState.fetchTimeByMode,
  mutableOn(
    fromActions.fetchBenchmarkCompanyPeerSetsSuccess,
    (state, action) => {
      const rawMode = action.peerSets[0]?.mode
      const mode = getBenchmarkSystemMode(rawMode)
      if (rawMode) {
        // @ts-ignore
        state[mode] = Date.now()
      }
    }
  )
)

const extendedReducer = combineReducers<BenchmarkPeerSetState>({
  ids: state => state || initialState.ids,
  entities: state => state || initialState.entities,
  activeActionByMode: activeAction,
  error: errorReducer,
  manageSelected,
  userHasAccessToTarget,
  selectedByMode,
  excludeDataByMode,
  excludeQuantileByMode,
  restoreTime,
  // @ts-ignore
  fetchTimeByMode,
})

// Entity Reducer

const defaultEntity: Omit<BenchmarkPeerSetEntity, 'peerSet'> = {
  hash: '',
  dirty: false,
  new: false,
  updating: false,
}

/** Ensure hidden prop does not affect the peer set hash. */
export function createHashablePeerSet(peerSet: BenchmarkPeerSet): string {
  return md5({ ...peerSet, hidden: false })
}

const createEntity = (peerSet: BenchmarkPeerSet): BenchmarkPeerSetEntity => {
  // Ensure that peers' hide prop is set (true/false) to prevent hash
  // comparison issues (e.g. checking if a set is savable)
  const peers = peerSet.peers.map(p => ({ ...p, hide: p.hide === true }))
  const ps = { ...peerSet, peers }
  return {
    ...defaultEntity,
    peerSet: ps,
    hash: createHashablePeerSet(ps),
  }
}

const createNewEntity = (
  mode: BenchmarkModeID = 'global'
): BenchmarkPeerSetEntity => {
  const peerSet: BenchmarkPeerSet = {
    id: buildNewPeerSetID(mode),
    name: 'New Peer Group',
    mode,
    type: 'custom',
    peers: [],
    isComposite: false,
  }
  return {
    peerSet,
    hash: createHashablePeerSet(peerSet),
    new: true,
    dirty: true,
    updating: false,
  }
}

const createAllNewEntities = (state?: BenchmarkPeerSetState) =>
  benchmarkSystemModeIDs.map(
    mode => state?.entities[buildNewPeerSetID(mode)] ?? createNewEntity(mode)
  )

const removeManyByMode = (
  mode: BenchmarkModeID,
  state: BenchmarkPeerSetState
): BenchmarkPeerSetState => {
  const idsWithMode = adapter
    .getSelectors()
    .selectAll(state)
    .filter(e => e.peerSet.mode === mode)
    .map(e => String(e.peerSet.id))
  return adapter.removeMany(idsWithMode, state)
}

const peersLens = lensPath(['peerSet', 'peers'])

const removePeers =
  <T>(
    fn: (action: T) => BenchmarkCompany | BenchmarkCompany[] | undefined | null
  ) =>
    (state: BenchmarkCompany[], action: T): BenchmarkCompany[] => {
      const peers = fn(action)
      return peers != null
        ? state.filter(p => !toArray(peers).some(_p => _p.id === p.id))
        : state
    }

const updatePeers =
  <A extends Action>(
    getModeFn: (action: A) => BenchmarkModeID | undefined,
    peersUpdateFn: (peers: BenchmarkCompany[], action: A) => BenchmarkCompany[]
  ) =>
    (state: BenchmarkPeerSetState, action: A) => {
      const mode = getModeFn(action)
      const id = mode
        ? state.manageSelected ?? state.selectedByMode[mode]
        : undefined
      const prev =
        id && state.entities[id]
          ? // tslint:disable-next-line: no-non-null-assertion
          state.entities[id]!
          : createNewEntity(mode)
      const peersPrev: BenchmarkCompany[] = view(peersLens, prev)
      const peersNext = peersUpdateFn(peersPrev, action)
      const next = set(peersLens, peersNext, prev)
      return adapter.upsertOne(next, state)
    }

const entityReducer = createReducer(
  initialState,
  on(
    fromActions.fetchBenchmarkCompanyPeerSetsSuccess,
    (state, { peerSets }) => {
      const mode = head(peerSets)?.mode
      if (mode) {
        const next = removeManyByMode(mode, state)
        const newEntity = createNewEntity(mode)
        const entities = [newEntity, ...peerSets.map(createEntity)]
        return adapter.upsertMany(entities, next)
      }
      return state
    }
  ),
  on(
    fromActions.clearBenchmarkPeerSets,
    fromControlActions.ClearBenchmarkControls,
    (state, { mode }) => {
      const next = removeManyByMode(mode, state)
      return adapter.upsertOne(createNewEntity(mode), next)
    }
  ),
  on(
    fromActions.setBenchmarkPeerSetPeers,
    updatePeers(
      ({ mode }) => mode,
      (_, { peers }) => peers
    )
  ),
  on(
    fromActions.addBenchmarkPeerSetPeers,
    updatePeers(
      ({ peers }) => head(peers)?.mode,
      (state, { peers }) => {
        const peersToAdd = peers.filter(p => !state.some(cp => cp.id === p.id))
        return [...state, ...peersToAdd]
      }
    )
  ),
  on(
    fromActions.removeBenchmarkPeerSetPeers,
    updatePeers(
      ({ peers }) => peers[0]?.mode,
      removePeers(action => action.peers)
    )
  ),
  on(
    fromActions.toggleBenchmarkPeerSetPeersVisibility,
    updatePeers(
      ({ mode }) => mode,
      (peers, { indices, hide }) =>
        peers.map((p, i) =>
          toArray(indices).includes(i)
            ? { ...p, hide: hide != null ? hide : !p.hide }
            : p
        )
    )
  ),
  on(
    fromControlActions.SetBenchmarkControlsTargetCompany,
    updatePeers(
      ({ targetCompany }) => targetCompany?.mode,
      removePeers(action => {
        if (action.targetCompany && !isBenchmarkPeerSet(action.targetCompany)) {
          return action.targetCompany
        }
      })
    )
  ),
  on(fromActions.updateBenchmarkPeerSetSuccess, (state, { entity }) =>
    adapter.upsertOne(createEntity(entity), state)
  ),
  on(fromActions.addBenchmarkPeerSetSuccess, (state, { entity }) =>
    adapter.upsertMany([createEntity(entity), ...createAllNewEntities()], state)
  ),
  on(fromActions.removeBenchmarkPeerSetSuccess, (state, { entity }) =>
    adapter.removeOne(String(entity.id), state)
  ),
  on(fromActions.setOverrideShowBenchmarkPeerSet, (state, { peerSet }) => {
    const id = String(peerSet.id)
    const changes = { updating: true }
    return adapter.updateOne({ id, changes }, state)
  }),
  on(
    fromActions.setOverrideShowBenchmarkPeerSetSuccess,
    (state, { peerSet, value }) => {
      const id = String(peerSet.id)
      const nextPeerSet: BenchmarkPeerSet = { ...peerSet, hidden: !value }
      if (peerSet.type === 'premade' && value) {
        nextPeerSet.type = 'override'
      }
      const changes = { updating: false, peerSet: nextPeerSet }
      return adapter.updateOne({ id, changes }, state)
    }
  ),
  on(
    fromActions.setOverrideShowBenchmarkPeerSetFailure,
    (state, { peerSet }) => {
      const id = String(peerSet.id)
      const changes = { updating: false }
      return adapter.updateOne({ id, changes }, state)
    }
  )
)

// Combined Reducers

const benchmarkPeerSetReducer = reduceReducers<BenchmarkPeerSetState>(
  extendedReducer,
  entityReducer
)

export function reducer(
  state: BenchmarkPeerSetState | undefined,
  action: Action
): BenchmarkPeerSetState {
  return benchmarkPeerSetReducer(state, action)
}
