<ng-container *ngIf="!checkPremiumAndRateRules">
  <div>
    <h1 mat-dialog-title>
      {{ title }}
    </h1>
    <div mat-dialog-content></div>
    <div mat-dialog-actions class="confirm-button">
      <button appButton mat-dialog-close type="submit" (click)="useAnalyze()">
        Use Analyze Re
      </button>
      <button appButton mat-dialog-close type="submit" (click)="useManual()">
        Use Manually
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="checkPremiumAndRateRules">
  <h1>Before you save...</h1>
  <div>
    <ul>
      <li>
        A Premium must be entered on all Expiring, Quote, and FOT Master panels.
      </li>
      <li>
        A Rate must be entered on all Expiring, Quote, and FOT Master panels:
        <ul>
          <li>
            For standard rates, a rate must be entered based on Layer Type (ROL,
            R%oS, PMPM, Ceding Commission).
          </li>
          <li>
            For non-standard rates:
            <ul>
              <li>
                If cessions based, select the ‘Cessions Based Premium’ from the
                Term Selector.
              </li>
              <li>
                If the rate is a sliding scale, add ‘Provisional’ and ‘Sliding
                Scale Commission’ from Term Selector.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div mat-dialog-actions class="confirm-button">
    <button appButton link mat-dialog-close (click)="close()">
     Close
    </button>
    <button appButton mat-dialog-close type="submit" (click)="save()">
      Save Anyway
    </button>
  </div>
</ng-container>
