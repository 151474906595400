import {
  curry,
  forEachObjIndexed,
  head,
  includes,
  isEmpty,
  isNil,
  keys,
  mergeWith,
  reject,
  uniq,
  uniqWith,
} from 'ramda'
import {
  BenchmarkLOBMetricsResponse,
  BenchmarkLOBMetricsValues,
  BenchmarkMetricsRequest,
  BenchmarkResponse,
  BenchmarkUSMetricsDatumResponse,
  BenchmarkUSMetricsResponse,
  isBenchmarkUSMetricsResponse,
} from 'src/app/api/benchmark/data/benchmark-data.model'
import { BenchmarkChartView } from 'src/app/benchmark/model/benchmark-chart-view'
import { BenchmarkMode } from 'src/app/benchmark/model/benchmark-mode'
import { BenchmarkSubmode } from 'src/app/benchmark/model/benchmark-submode'
import { BenchmarkLob } from 'src/app/benchmark/model/benchmark.model'

type FilterRules = Record<FilterCategoryKey, FilterCategory>

type FilterCategoryKey = 'GDR' | 'U' | 'PMMS'
type FilterCategory = { submodes: string[] } & {
  addLobs: Record<FilterTimespanKey, FilterAction[]>
} & { removeLobs: number[] }

type FilterTimespanKey = '2021ANDPRIOR' | '2022ANDAFTER'
type FilterAction = {
  toLob: number
  toName: string
  fromLobs: number[]
}

/* const addRule = (toLob: number, toName: string, fromLobs: number[]) => ({
  toLob,
  toName,
  fromLobs,
}) */

const USRules: FilterRules = {
  GDR: {
    submodes: [
      'growth-stat',
      'diversification',
      'state-diversification',
      'reinsurance',
    ],
    addLobs: {
      '2021ANDPRIOR': [],
      '2022ANDAFTER': [],
    },
    removeLobs: [],
  },
  U: {
    submodes: ['underwriting-stat', 'underwriting-stat-health'],
    addLobs: {
      '2021ANDPRIOR': [],
      '2022ANDAFTER': [],
    },
    removeLobs: [],
  },
  PMMS: {
    submodes: ['performance', 'state', 'market-share'],
    addLobs: {
      '2021ANDPRIOR': [],
      '2022ANDAFTER': [],
    },
    removeLobs: [],
  },
}

export function swapNewToOldLobs(
  mode: BenchmarkMode,
  submode: BenchmarkSubmode,
  lobs: BenchmarkMetricsRequest['lobs']
): BenchmarkMetricsRequest['lobs'] {
  if (lobs === undefined) {
    return lobs
  }

  // Special case for maket share since it stores the lobs in and array of arrays. Call this
  // function on each of the arrays of lobs.
  if (lobs.some(lob => Array.isArray(lob))) {
    return lobs.map(lob =>
      Array.isArray(lob)
        ? (swapNewToOldLobs(mode, submode, lob) as number[])
        : lob
    )
  }

  let swappedLobs = lobs

  if (mode.id === 'us' || mode.id === 'us-health') {
    const category = categoryFromSubmode(submode.id)
    if (category !== undefined) {
      const removed: number[] = []

      forEachObjIndexed(rules => {
        rules.forEach(rule => {
          // If the synthetic lob if found, remove it and added the dependencies
          const pos = lobs.findIndex(lob => lob === rule.toLob)
          if (pos !== -1) {
            removed.push(pos)
            swappedLobs.push(...rule.fromLobs)
          }
        })
      }, USRules[category].addLobs)

      // Remove the accumulated removed items
      swappedLobs = swappedLobs.filter((_, i) => !removed.includes(i))
    }
  }

  return uniq(swappedLobs)
}

export function filterNewlobsInSubmode(
  mode: BenchmarkMode,
  submode: BenchmarkSubmode,
  lobs: BenchmarkLob[]
) {
  if (mode.id === 'us' || mode.id === 'us-health') {
    const category = categoryFromSubmode(submode.id)
    if (category !== undefined) {
      const categoryRules = USRules[category]

      renameLob(lobs, 'Auto Phys', 'Auto Phys Damage')

      const filtered = reject(
// @ts-ignore
        lob => includes(lob.id, categoryRules.removeLobs),
        lobs
      )
      const added = categoryRules.addLobs['2021ANDPRIOR'].map(
        ({ toLob, toName }): BenchmarkLob => ({ id: toLob, name: toName })
      )

      return uniqWith((a, b) => a.id === b.id, [...filtered, ...added])
    }
  }

  return lobs
}

function renameLob(lobs: BenchmarkLob[], from: string, to: string) {
  const pos = lobs.findIndex(lob => lob.name === from)
  if (pos >= 0) {
    lobs[pos] = { ...lobs[pos], name: to }
  }
}

export function filterLobs(
  views: BenchmarkChartView[],
  response: BenchmarkResponse
): BenchmarkResponse {
  if (isBenchmarkUSMetricsResponse(response)) {
    filterUSLobs(views, response)
  }

  return response
}

function filterUSLobs(
  views: BenchmarkChartView[],
  response: BenchmarkUSMetricsResponse[]
) {
  const category = categoryFrom(views)
  if (category === undefined) {
    return
  }

  response.forEach(company =>
    company.companyMetricList.forEach(process(category))
  )
}

const process = curry(
  (category: FilterCategoryKey, cm: BenchmarkUSMetricsDatumResponse) => {
    const categoryRules = USRules[category]
    const addLobs = categoryRules.addLobs[withYearFrom(cm)]
    const removeLobs = categoryRules.removeLobs

    const added = reject(
      isNil,
      addLobs.map(({ toLob, fromLobs }): BenchmarkLOBMetricsResponse | null => {
        const fromValues = cm.lobMetrics.filter(matchingLobs(fromLobs))
        if (isEmpty(fromValues)) {
          return null
        }

        const values = fromValues
          .map(lm => lm.values)
          .reduce((pv, cv) => {
            return mergeWith(addMetricValue, pv, cv)
          })

        return {
          lob: toLob,
          values,
        }
      })
    )

    const filtered = reject(matchingLobs(removeLobs), cm.lobMetrics)

    cm.lobMetrics = [...filtered, ...added]
  }
)

const matchingLobs = (lobs: number[]) => (lm: BenchmarkLOBMetricsResponse) =>
  includes(lm.lob, lobs)

function categoryFrom(views: BenchmarkChartView[]) {
  const submodeId = head(views)?.submode.id
  return categoryFromSubmode(submodeId)
}

function categoryFromSubmode(submodeId: string | undefined) {
  const matchingSubmode = (cat: FilterCategoryKey) =>
    includes(submodeId, USRules[cat].submodes)

  return keys(USRules).find(matchingSubmode)
}

function withYearFrom(cm: BenchmarkUSMetricsDatumResponse): FilterTimespanKey {
  const year = cm.fiscalYear ?? 0
  return year < 2022 ? '2021ANDPRIOR' : '2022ANDAFTER'
}

type BenchmarkLOBMetricsValueType = BenchmarkLOBMetricsValues[string]

function addMetricValue(
  x: BenchmarkLOBMetricsValueType,
  y: BenchmarkLOBMetricsValueType
): BenchmarkLOBMetricsValueType {
  // The x and y values can be numbers or objects but we require them to be the same type
  if (typeof x === 'number' && typeof y === 'number') {
    return x + y
  } else if (typeof x === 'object' && typeof y === 'object') {
    return {
      metricValue: x.metricValue + y.metricValue,
      magnitude: x.magnitude,
    }
  } else {
    return 0
  }
}
