import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-error-dialog',
  styles: [
    `
           :host {
             width: 100%;
             height: 100%;
             display: flex;
             flex-flow: column;
           }
     
           .content {
             height: 100%;
           }
     
           .content pre {
             white-space: pre-line;
             margin: 0;
             line-height: 0.8rem;
           }
     
           h1 {
             border-bottom: 1px solid var(--accent);
             padding-bottom: 10px;
           }
     
           .detail-container {
             user-select: text;
           }
         `,
  ],
  template: `
    <h1 mat-dialog-title>Details</h1>
    <div mat-dialog-content class="content">
      <div class="detail-container">
        <pre>
          <ng-container *ngFor="let detail of data">
            {{ appendNewLine(detail) }}
          </ng-container>
        </pre>
      </div>
    </div>
    <div mat-dialog-actions>
      <button appButton mat-dialog-close>Close</button>
    </div>
  `,
})
export class ErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {}

  appendNewLine(line: string) {
    const msg = line?.replace(
      'support@analyzere.com',
      'sage-support@lockton.com'
    )
    return msg + '\n'
  }
}
