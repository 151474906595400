<h1 mat-dialog-title>New SAGE Carrier, Year and Program</h1>
<mat-dialog-content>
  <div>
    <span
      >This will create a new SAGE Carrier, Year and Program mapping for the
      opportunity: {{ data.accountOpportunityName }}</span
    >
    <div *ngIf="enterMode == 'create'">
      <div class="create-sub-text">
        <span
          >Update the name for the new Carrier or use the SF account name:
        </span>
        <mat-form-field>
          <input matInput [(ngModel)]="data.accountName" />
        </mat-form-field>
      </div>
      <div class="create-sub-text">
        <label for="file">Choose Carrier Logo: </label>
        <input
          required
          type="file"
          id="file"
          accept="image/png"
          (change)="handleFileInput($event.target.files)"
        />
      </div>
    </div>
    <div *ngIf="enterMode == 'update'">
      <div class="update-sub-text">
        <span
          >Search below for carrier to see if they already exist in SAGE --OR--
        </span>
        <button appButton border center accent (click)="switchMode('create')">
          Create New Carrier
        </button>
      </div>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          #input
          autocomplete="off"
        />
      </mat-form-field>
      <table
        mat-table
        [dataSource]="dataSource"
        class="app-table app-table-small carriers-table"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Carrier Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              appButton
              border
              center
              accent
              (click)="mapToCarrier(element)"
            >
              Map to this
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="enterMode == 'create'"
    appButton
    border
    center
    accent
    (click)="confirmAction()"
  >
    Create Carrier
  </button>
  <button appButton link [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
