<div class="main">
  <h1 mat-dialog-title>Expiring Panel not present for renewal.</h1>
  <div mat-dialog-content>
    <div class="content">
      <i class="hint">
        Click Cancel to go back and add Expiring or Continue to move forward
        without the line data from previous year.
      </i>
    </div>
  </div>
  <div mat-dialog-actions>
    <button appButton border mat-dialog-close>Cancel</button>
    <button appButton border accent (click)="confirm()" class="confirm-button">
      Continue
    </button>
  </div>
</div>
