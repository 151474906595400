<button
  mat-icon-button
  class="menu-icon"
  id="menu"
  (click)="navService.open()"
>
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="name"
  [subtitle]="subtitle"
  (backClick)="onBackClick()"
></app-header-title>
