import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators'
import { isNumber } from './util/string'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-slider-input',
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      :host > mat-slider {
        flex: 1 1 0%;
        padding: 0;
        height: 40px;
      }

      :host ::ng-deep .mat-slider-wrapper {
        top: 18px;
      }

      input {
        width: 3rem;
        height: 2rem;
        margin-right: var(--inset-small);
        padding: 0 var(--inset-small);
        background: var(--bg-1-translucent);
        border: 1px solid var(--border-translucent-subtle);
        border-radius: var(--border-radius-big);
        text-align: center;
        color: white;
        font-size: var(--font-size);
      }

      input:hover {
        background: var(--bg-1-translucent-lit);
        border-color: var(--border-translucent-strong);
      }

      input:focus {
        background: var(--bg-1-translucent-lit);
        border-color: var(--accent);
      }

      :host.invalid input {
        color: var(--warn);
        background: var(--bg-warn);
      }

      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      .slider-wrapper {
        position: relative;
        width: 100%;
      }

      .mat-mdc-slider {
        display: inline-block;
        box-sizing: border-box;
        outline: none;
        vertical-align: middle;
        margin-left: 8px;
        margin-right: 8px;
        width: 100% !important;
        min-width: 112px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    `,
  ],
  template: `
    <input
      *ngIf="showInput"
      matInput
      type="number"
      [formControl]="form"
      [maxlength]="maxLength"
    />

    <div class="slider-wrapper">
      <mat-slider
        thumbLabel
        showTickMarks
        [min]="min"
        [max]="max"
        [step]="step"
        [(ngModel)]="value"
        (input)="onSliderChange($event)"
        (ngModelChange)="onSliderChange($event)"
      >
        <input
          matSliderThumb
          value="{{value}}"
        >
      </mat-slider>
    </div>
  `,
})
export class SliderInputComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()
  private invalid = false

  @Input() min = 1
  @Input() max = 100
  @Input() step = 1
  @Input() showInput = true

  form = new FormControl(1)

  private _value = 1
  @Input() set value(value: number) {
    this._value = value
    this.form.setValue(value, { emitEvent: false })
  }
  get value(): number {
    return this._value
  }

  @Output() valueChange = new EventEmitter<number>()

  @HostBinding('class.invalid')
  get isInvalid(): boolean {
    return this.invalid
  }

  get maxLength(): number {
    return String(this.max).length
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        filter(value => this.isValid(value)),
        map(value => Number(value))
      )
      .subscribe(value => this.valueChange.emit(value))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private isValid(value: number): boolean {
    const isNum = isNumber(value)
    if (isNum) {
      const val = Number(value)
      if (val <= this.max && val >= this.min) {
        this.invalid = false
        return true
      }
    }
    this.invalid = true
    return false
  }

  onSliderChange(event: any): void {
    const value = event.target.value
    this.valueChange.emit(value)
    this.form.setValue(value, { emitEvent: false })
  }
}
