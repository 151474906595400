<app-group-animated-scenarios
  [structureEntities]="structureEntities$ | async"
  [executing]="executing$ | async"
  [scenarioEvents]="scenarioEvents$ | async"
  [lossSets]="lossSetLayers$ | async"
  [loadingGroup]="loadingGroup$ | async"
  [animating]="animating$ | async"
  [scenarioEventsResults]="scenarioEventsResults$ | async"
  [structureGroup]="structureGroup$ | async"
  [currentClient]="currentClient$ | async"
  [studies]="studies$ | async"
  [sharedIDGroup]="sharedIDGroup$ | async"
  [eventResultByEntity]="eventResultByEntity$ | async"
  [structureNameByEntity]="structureNameByEntity$ | async"
  (processEvents)="onProcessEvents()"
  (animationEnd)="onAnimationEnd()"
  (reAnimate)="onReanimate()"
></app-group-animated-scenarios>
