import * as d3 from 'd3'
import { TowerComponent } from '../tower-component/tower.component'
import { CurrencyPipe } from '@angular/common'
import { TowerTextElement } from './tower.model'
import { ScaleLogarithmic, ScaleLinear } from 'd3'

export class AxisHandler {
  private textElement: TowerTextElement
  constructor(
    private tower: TowerComponent,
    private chart: any,
    private margin: any,
    private widgetId: string,
    private currencyPipe: CurrencyPipe,
    private currency: string,
    private xAxisTicks: number
  ) {}

  makeAxises(
    yDimensions: ScaleLogarithmic<number, number> | ScaleLinear<number, number>,
    readonly: boolean,
    towerType: string,
    isGroup: boolean
  ) {
    let newMost = 0
    this.chart.select('#' + this.widgetId).remove()
    const legends = this.chart
      .append('g')
      .attr('id', this.widgetId)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      )

    let symbol = this.currencyPipe.transform(
      9,
      this.currency,
      'symbol-narrow',
      '1.0-0'
    )
    if (symbol !== null) {
      symbol = symbol.replace('9', '')
    } else {
      symbol = '$'
    }

    legends
      .append('g')
      .attr('class', 'app-tower-y-axis')
      .call(
        d3
          .axisLeft(yDimensions)
          .ticks(10)
          .tickFormat((tickVal: any) => {
            if (tickVal > newMost) {
              newMost = tickVal
            }
            return tickVal >= 1000
              ? symbol !== null
                ? symbol + tickVal / 1000000 + 'M'
                : ''
              : symbol + tickVal
          })
      )
      .append('text')
      .attr('class', 'axis-title')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end')
      .attr('fill', '#e0e0e0')

    const xScale = d3
      .scaleLinear()
      .domain([0.0, 1.0])
      .range([0, this.tower.width])

    const xAxis = d3
      .axisBottom(xScale)
      .scale(xScale)
      .ticks(this.xAxisTicks)
      .tickFormat(d3.format('.0%'))

    legends
      .append('g')
      .attr('transform', 'translate(0,' + this.tower.chartHeight + ')')
      .attr('class', 'app-tower-x-axis')
      .call(xAxis)

    this.textElement = legends
      .append('text')
      .attr('fullLine', 'null')
      .attr('x', '15%')
      .attr('y', '35%')
      .attr('class', 'app-tower-watermark')
      .style('fill', 'var(--subtle)')
      .attr('transform', `translate(${[10, 20]})`)

    if (!readonly) {
      this.textElement
        .append('tspan')
        .attr('x', '15%')
        .attr('dy', 0)
        .text(() => {
          if (towerType === 'Occ' && !isGroup) {
            return 'Drag a new Layer to the Occurrence'
          } else if (towerType === 'Agg' && !isGroup) {
            return 'Drag an Aggregate Feeder Layer'
          } else {
            return ''
          }
        })

      this.textElement
        .append('tspan')
        .attr('x', '15%')
        .attr('dy', '1.5em')
        .text(() => {
          if (towerType === 'Occ' && !isGroup) {
            return 'Tower to get started.'
          } else if (towerType === 'Agg' && !isGroup) {
            return 'onto the Occurrence Tower.'
          } else {
            return ''
          }
        })
    }

    return newMost
  }

  clearWaterMark() {
    this.textElement.style('opacity', 0)
  }

  setWaterMark() {
    this.textElement.style('opacity', 1)
  }

  range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: Math.ceil((stop - start) / step + 1) },
      (_, i) => start + i * step
    )
}
