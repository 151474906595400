import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import {
  SummaryChartAxis,
  SummaryChartGridOption,
  SummaryChartInfo,
  SummaryChartMenuOption,
} from '../summary-charts-model'
import { FormControl, Validators } from '@angular/forms'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-chart-controls',
  styleUrls: ['./explore-summary-chart-controls.component.scss'],
  templateUrl: './explore-summary-chart-controls.component.html',
})
export class ExploreSummaryChartControlsComponent implements OnInit {
  _chartOption: SummaryChartGridOption
  _chartType: SummaryChartMenuOption
  _splitOptions: SummaryChartMenuOption[]
  _split: SummaryChartMenuOption | null
  _sizeOptions: SummaryChartMenuOption[]
  _size: SummaryChartMenuOption | null
  @Input() chartTypeOptions: SummaryChartMenuOption[]
  @Input() set chartOption(value: SummaryChartGridOption) {
    this._chartOption = value
    this._chartType = this.mapChartInfoToMenuOption(value.chart)
    this._split = value.split
      ? this.mapChartAxisToMenuOption(value.split)
      : null
    this._splitOptions = value.chart.applicableSplits.map(split =>
      this.mapChartAxisToMenuOption(split)
    )
    this._size = value.size ? this.mapChartAxisToMenuOption(value.size) : null
    this._sizeOptions = value.chart.applicableSizes.map(size =>
      this.mapChartAxisToMenuOption(size)
    )
  }
  @Input() selectableChartsForMetric: SummaryChartInfo[]
  @Input() selectableChartsForTab: SummaryChartInfo[]
  @Input() isMaximized: boolean
  @Input() canHideMetrics: boolean
  @Input() hideMetrics: boolean
  @Input() hasComparisonValue: boolean
  @Input() lightChartMode: boolean
  comparisonValue: number | null
  comparisonValueControl = new FormControl('', [
    Validators.pattern(/^\d+$/)
  ])

  @Output() chartOptionUpdate = new EventEmitter<SummaryChartGridOption>()
  @Output() maximizeClick = new EventEmitter<number>()
  @Output() updateHideMetrics = new EventEmitter<boolean>()
  @Output() updateComparisonValue = new EventEmitter<number | null>()
  @Output() onExportClick = new EventEmitter()

  ngOnInit(): void {
    this.comparisonValueControl.valueChanges.subscribe(val => {
      const numericVal = val ? Number(val) : null
      if (numericVal) {
        this.updateComparisonValue.emit(numericVal)
      } else {
        this.updateComparisonValue.emit(null)
      }
    })
  }

  mapChartInfoToMenuOption(chartInfo: SummaryChartInfo): SummaryChartMenuOption {
    return {
      name: chartInfo.name,
      icon: chartInfo.icon,
    }
  }
  mapChartAxisToMenuOption(chartAxis: SummaryChartAxis): SummaryChartMenuOption {
    return {
      name: chartAxis.name,
    }
  }

  onChartTypeChange(option: SummaryChartMenuOption): void {
    const newChart = Object.values(this.selectableChartsForTab).find(
      chart => chart.name === option.name
    )
    if (!newChart) {
      return
    }
    this.chartOptionUpdate.emit({
      ...this._chartOption,
      chart: newChart,
    })
  }

  onSplitTypeChange(option: SummaryChartMenuOption): void {
    const newSplit = this._chartOption.chart.applicableSplits.find(
      split => split.name === option.name
    )
    if (!newSplit) {
      return
    }
    this.chartOptionUpdate.emit({
      ...this._chartOption,
      split: newSplit,
    })
  }

  onSizeTypeChange(option: SummaryChartMenuOption): void {
    const newSize = this._chartOption.chart.applicableSizes.find(
      size => size.name === option.name
    )
    if (!newSize) {
      return
    }
    this.chartOptionUpdate.emit({
      ...this._chartOption,
      size: newSize,
    })
  }
  onHideMetricsChange(hideMetrics: boolean): void {
    this.updateHideMetrics.emit(hideMetrics)
  }
}
