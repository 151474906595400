import { createAction } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { BenchmarkSystemModeID } from '../model/benchmark-mode'
import { BenchmarkCompany } from '../model/benchmark.model'

export const fetchAllBenchmarkCompaniesForCurrentMode = createAction(
  '[Benchmark Company] Fetch All For Current Mode'
)

export const fetchAllBenchmarkCompaniesByMode = createAction(
  '[Benchmark Company] Fetch All By Mode',
  (mode: BenchmarkSystemModeID) => ({ mode })
)
export const fetchAllBenchmarkCompaniesByModeSuccess = createAction(
  '[Benchmark Company] Fetch All By Mode Success',
  (mode: BenchmarkSystemModeID, companies: BenchmarkCompany[]) => ({
    mode,
    companies,
  })
)
export const fetchAllBenchmarkCompaniesByModeFailure = createAction(
  '[Benchmark Company] Fetch All By Mode Failure',
  (mode: BenchmarkSystemModeID, error: ApplicationError) => ({ mode, error })
)
