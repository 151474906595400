import { Action, combineReducers, createReducer, on } from "@ngrx/store"
import { CreditQuoteAuditRow, CreditQuoteAuditTable, CreditQuoteOverviewRow } from "../../model/credit-quote.model"
import { CreditQuoteAction } from "./credit-quote.actions"

export interface QuoteStore {
  audit: CreditQuoteAuditTable |  undefined | null
  overview: CreditQuoteOverviewRow[] | undefined
}

export const initialState: QuoteStore = {
  audit: undefined,
  overview: undefined
}

const auditReducer = createReducer(
  initialState.audit,
  on(
    CreditQuoteAction.fetchQuoteAuditSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchQuoteAuditFailure,
    _ => undefined
  ),
)

const overviewReducer = createReducer(
  initialState.overview,
  on(
    CreditQuoteAction.fetchQuoteOverviewSuccess,
    (_, { results }) => results
  ),
  on(
    CreditQuoteAction.fetchQuoteOverviewFailure,
    _ => undefined
  ),
)

const quoteReducer = combineReducers<QuoteStore>({
  audit: auditReducer,
  overview: overviewReducer
})

export function reducer(state: QuoteStore | undefined, action: Action) {
  return quoteReducer(state, action)
}
