import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { addColumnDefValues } from '../utils/quote-details.util'

export interface QuoteRankingDatum {
  id: string
  reinsurer: string
  rankExpiring: number
  signedExpiring: number
  percentOfTotalExpiring: number
  rankRenewal: number
  rankChange: string // 'Increased' | 'Decreased' | 'Unchanged' | 'NotRenewed'
  signedRenewal: number
  percentOfTotalRenewal: number
  percentChange: number
}

export interface QuoteRankingData {
  rows: QuoteRankingDatum[]
  total: QuoteRankingDatum
}

export const DEFAULT_QUOTE_RANKING_COLUMNS: SortTableColumnDef[] = [
  {
    label: 'Reinsurer',
    id: 'reinsurer',
    ...addColumnDefValues('text', '20rem', false),
  },
  {
    label: 'Rank (Expiring)',
    id: 'rankExpiring',
    ...addColumnDefValues('short-numeric', '5rem', false),
  },
  {
    label: 'Expiring Signed',
    id: 'signedExpiring',
    ...addColumnDefValues('short-numeric', '5rem', false),
  },
  {
    label: '% of Expiring Total',
    id: 'percentOfTotalExpiring',
    ...addColumnDefValues('percentage', '5rem', false),
  },
  {
    label: 'Rank (Renewal)',
    id: 'rankRenewal',
    ...addColumnDefValues('short-numeric', '5rem', false),
  },
  {
    label: 'Rank Change',
    id: 'rankChange',
    bodyStyle: {
      fontWeight: 'bold',
      textAlign: 'center',
    },
    ...addColumnDefValues('text', '5rem', false),
  },
  {
    label: 'Renewal Signed',
    id: 'signedRenewal',
    ...addColumnDefValues('short-numeric', '5rem', false),
  },
  {
    label: '% of Renewal Total',
    id: 'percentOfTotalRenewal',
    ...addColumnDefValues('percentage', '5rem', false),
  },
  {
    label: '% Change',
    id: 'percentChange',
    ...addColumnDefValues('percentage', '5rem', false),
  },
]
