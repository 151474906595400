import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { MatStepper } from '@angular/material/stepper'
import { Selections } from '@shared/util/selections'
import {
  CandidateLayerChangeEvent,
  Change,
  LossSetGroupIDsChangeEvent,
  OptimizationCandidateLayer,
  OptimizationCandidateResult,
  OptimizationCandidateResultEntity,
  OptimizationInputRangeChangeEvent,
  OptimizationPortfolioTailMetrics,
  OptimizationPortfolioTailMetricsPayload,
  OptimizationRangesTypes,
  ResultsView,
  stepActions,
  OptimizationInputCurrencyChangeEvent,
} from '../optimization.model'
import { LayerModelingState } from '../../layer-modeling/store/layer-modeling.reducer'
import {
  LayerModelingDimensionChangeEvent,
  LayerModelingView,
} from '../../layer-modeling/layer-modeling.model'
import { StepperSelectionEvent } from '@angular/cdk/stepper'
import { OptimizationService } from '../optimization.service'
import { CheckboxSelectionsChange } from '@shared/sort-table/sort-table.model'
import { values } from 'ramda'
import { LossSetGroup } from '../../model/loss-set-layers.model'
import { CurrencyCode, LossFilter } from '../../../api/analyzere/analyzere.model'
import { actionCfg } from '../utils/optimization-action-cfg'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization',
  styleUrls: ['./optimization.component.scss'],
  templateUrl: './optimization.component.html',
})
export class OptimizationComponent implements AfterViewInit {
  actionStepConfig = actionCfg
  @Input() rangesTypes: OptimizationRangesTypes[]
  @Input() candidateResultsMetrics: OptimizationCandidateResult[]
  @Input() candidateLayers: OptimizationCandidateLayer[]
  @Input() candidateLayersLoading: boolean
  @Input() candidateLayersMetricsLoading: boolean
  @Input() resultsView: ResultsView
  @Input() chartState: LayerModelingState
  @Input() chartView: LayerModelingView
  @Input() metricsError: string | null
  @Input() candidatesLayersError: string | null
  @Input() saving: boolean
  @Input() savingError: string | null
  @Input() lastCreated: number
  @Input() lossSetGroups: LossSetGroup[]
  @Input() lossFilters: LossFilter[] | undefined
  @Input() portfolioTailMetrics: OptimizationPortfolioTailMetrics
  @Input() currencyList: CurrencyCode[]

  @Output() candidateLayerChange = new EventEmitter<
    Change<OptimizationCandidateLayer>[]
  >()
  @Output() closeClick = new EventEmitter()
  @Output() rangeChange = new EventEmitter<OptimizationInputRangeChangeEvent>()
  @Output() currencyChange =
    new EventEmitter<OptimizationInputCurrencyChangeEvent>()
  @Output() resetClick = new EventEmitter()
  @Output() runCombinations = new EventEmitter()
  @Output() runLayerMetrics = new EventEmitter()
  @Output() rangeResultValueChange =
    new EventEmitter<CandidateLayerChangeEvent>()
  @Output() viewChange = new EventEmitter<ResultsView>()
  @Output() dimensionChange =
    new EventEmitter<LayerModelingDimensionChangeEvent>()
  @Output() save = new EventEmitter()
  @Output() selectedLossSetGroupIDsChange =
    new EventEmitter<LossSetGroupIDsChangeEvent>()
  @Output() portfolioReturnPeriodToggleChange = new EventEmitter<
    OptimizationPortfolioTailMetricsPayload[]
  >()

  @ViewChild(MatStepper, { static: false }) stepper: MatStepper

  stepHeight: string
  selections = new Selections()
  checkboxes: Record<string, Selections> = {}

  private resizeTimeout: number

  constructor(
    private ref: ElementRef,
    private cd: ChangeDetectorRef,
    private service: OptimizationService
  ) {}

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout)
    }
    this.resizeTimeout = window.setTimeout(
      (() => {
        this.calculateContainerSize()
      }).bind(this),
      250
    )
  }
  ngAfterViewInit(): void {
    this.calculateContainerSize()
  }

  private calculateContainerSize() {
    const el = this.ref.nativeElement.querySelector(
      '.mat-horizontal-content-container'
    )
    this.stepHeight = `calc(${el.clientHeight}px - var(--inset-big))`
    this.cd.markForCheck()
  }

  onSelectedChange(selections: Selections) {
    this.selections = selections
  }

  onBackClick() {
    if (this.stepper) {
      this.stepper.selectedIndex = this.stepper.selectedIndex - 1
    }
  }

  onActionClick(action: typeof stepActions[number]) {
    if (this.stepper) {
      if (stepActions.indexOf(action) < stepActions.length) {
        this.stepper.next()
      }
    }
    if (action === 'getCandidates') {
      this.selections.clear()
      values(this.checkboxes).forEach(c => c.clear())
      this.checkboxes = {}
      this.runCombinations.emit()
    } else if (action === 'getMetrics') {
      values(this.checkboxes).forEach(c => c.clear())
      this.checkboxes = {}
      this.runLayerMetrics.emit()
    } else if (action === 'persist') {
      this.save.emit()
    }
  }

  onResetClick() {
    this.resetClick.emit()
    if (this.stepper) {
      this.stepper.selectedIndex = 0
    }
  }

  onSelectionChanged(event: StepperSelectionEvent) {
    if (event.selectedIndex === stepActions.length) {
      this.service.resize('50vw', '55vh')
    } else {
      if (event.previouslySelectedIndex === stepActions.length) {
        this.service.resizeDefault()
      }
    }
  }

  onCheckboxSelectionsChanges(event: CheckboxSelectionsChange) {
    this.checkboxes[event.id] = event.selections
  }

  onCandidateResultValueChange(
    event: Change<OptimizationCandidateResultEntity>[]
  ) {
    this.rangeResultValueChange.emit({
      changes: event,
      results: this.candidateResultsMetrics,
    })
  }

  get disableAction() {
    if (
      this.candidateLayersMetricsLoading ||
      this.candidateLayersLoading ||
      this.saving
    ) {
      return true
    }
    if (this.stepper) {
      const action = stepActions[this.stepper.selectedIndex]
      if (action === 'getMetrics') {
        if (this.candidateLayers.some(result => result.include)) {
          return false
        } else {
          return true
        }
      } else if (action === 'persist') {
        if (this.candidateResultsMetrics.some(result => result.savePortfolio)) {
          return false
        } else {
          return true
        }
      }
    } else {
      return false
    }
  }

  get stepIndex() {
    if (this.stepper) {
      return this.stepper.selectedIndex
    } else {
      return 0
    }
  }

  get filteredCandidateLayers() {
    return this.candidateLayers.filter(r => r.include)
  }
}
