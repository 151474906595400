<app-quote-seg-account-detail-dialog-component
  [programID]="data.programID"
  [fundManager]="data.fundManager"
  [segregatedAccounts]="data.segregatedAccounts"
  [selectedSegregatedAccountsData]="data.selectedSegregatedAccounts"
  [selectedReinsurerEntity]="selectedReinsurerEntity$ | async"
  (addNewAssignedLines)="onAddNewAssignedLines($event)"
  (updateAssignedLines)="onUpdateAssignedLines($event)"
  (saveSegregatedAccounts)="onSaveSegregatedAccounts($event)"
></app-quote-seg-account-detail-dialog-component>
