import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  PortfolioSetID,
  PortfolioSetAndStudyIDs,
  PortfolioViewID,
} from '../../model/portfolio-set.model'

export const fetchPortfolioView = createAction(
  '[Portfolio View] Fetch',
  props<PortfolioSetAndStudyIDs>()
)

export const fetchPortfolioViewSuccess = createAction(
  '[Portfolio View] Fetch Success',
  props<PortfolioSetAndStudyIDs & PortfolioViewID>()
)

export const fetchPortfolioViewFailure = createAction(
  '[Portfolio View] Fetch Failure',
  props<PortfolioSetAndStudyIDs & { error: ApplicationError }>()
)

export const addCededLayerView = createAction(
  '[Portfolio View] Add Ceded Layer View',
  props<PortfolioSetID & { layerID: string; viewID: string }>()
)

export const fetchCededLayersViews = createAction(
  '[Portfolio View] Fetch Ceded Layers View',
  props<
    PortfolioSetID & {
      layerIDs: string[]
      layerCurrencies?: string[]
      analysisProfileID: string
      isDesign: boolean
      isGroup: boolean
    }
  >()
)

export const fetchCededLayersViewsSuccess = createAction(
  '[Portfolio View] Fetch Ceded Layers View Success',
  props<PortfolioSetID & { views: Record<string, string> }>()
)

export const fetchCededLayersViewsFailure = createAction(
  '[Portfolio View] Fetch Ceded Layers View Failure',
  props<PortfolioSetID & { error: ApplicationError }>()
)
