import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../../error/model/error'
import { ProgramGroupSet } from '../../grouper/program-group.model'

export interface AddProgramGroupProps {
  id: string
  ordinal?: number
  parentGroupID?: string
}

export const addCompareProgramGroup = createAction(
  '[Compare Program Group] Add',
  props<AddProgramGroupProps>()
)

export const addCompareProgramGroupSuccess = createAction(
  '[Compare Program Group] Add Success',
  props<ProgramGroupSet & AddProgramGroupProps>()
)

export const addCompareProgramGroupFailure = createAction(
  '[Compare Program Group] Add Failure',
  props<{ error: ApplicationError }>()
)

export const removeCompareProgramGroup = createAction(
  '[Compare Program Group] Remove',
  props<{ id: string }>()
)
