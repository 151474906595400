import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { assoc } from 'ramda'
import { CanFetch } from '../../core/model/general.model'
import { reduceReducers } from '@shared/util/reduce-reducers'
import { BenchmarkData } from '../model/benchmark-chart'
import { BenchmarkModeID, BenchmarkSystemModeID } from '../model/benchmark-mode'
import { BenchmarkSubmode } from '../model/benchmark-submode'
import {
  BenchmarkCompany,
  BenchmarkDataRefreshStatus,
} from '../model/benchmark.model'
import * as fromCompany from './benchmark-company.reducer'
import * as fromControlActions from './benchmark-controls.actions'
import * as fromControls from './benchmark-controls.reducer'
import BenchmarkEntityStore from './benchmark-entity-store'
import * as fromExport from './benchmark-export.reducer'
import * as fromMetricView from './benchmark-metric-view.reducer'
import * as fromMetric from './benchmark-metric.reducer'
import * as fromPeerSet from './benchmark-peer-set.reducer'
import * as fromActions from './benchmark.actions'

export const BENCHMARK_FEATURE_KEY = 'benchmark'

export type BenchmarkState = CanFetch &
  typeof BenchmarkEntityStore.initialStates & {
    submodes: Record<string, BenchmarkSubmode>
    mode: BenchmarkModeID | null
    submode: string | null
    subheaderBySubmode: Record<string, string>
    managePeerSetsMode: BenchmarkSystemModeID
    prevMode: BenchmarkModeID | null
    prevSubmode: string | null
    companiesByMode: fromCompany.BenchmarkCompanyState
    metricsByMode: fromMetric.BenchmarkMetricState
    controls: fromControls.BenchmarkControlsState
    peerSet: fromPeerSet.BenchmarkPeerSetState
    metricView: fromMetricView.BenchmarkMetricViewState
    data?: BenchmarkData
    reinsuranceSummary?: BenchmarkData
    fullExport: { active: boolean; peers?: BenchmarkCompany[] }
    maximizeIndex: number | null
    downloadingChart: boolean
    extents: Record<number, [number, number]>
    export: fromExport.BenchmarkExportState
    dataRefreshStatus?: BenchmarkDataRefreshStatus
  }

export const initialState: BenchmarkState = {
  ...BenchmarkEntityStore.initialStates,
  submodes: {},
  mode: null,
  submode: null,
  subheaderBySubmode: {},
  managePeerSetsMode: 'us',
  prevMode: null,
  prevSubmode: null,
  companiesByMode: fromCompany.initialState,
  metricsByMode: fromMetric.initialState,
  controls: fromControls.initialState,
  peerSet: fromPeerSet.initialState,
  metricView: fromMetricView.initialState,
  fullExport: { active: false },
  maximizeIndex: null,
  downloadingChart: false,
  extents: {},
  export: fromExport.initialState,
  loading: false,
  error: null,
}

const metricOptionsBySubmodeReducer = createReducer(
  initialState.submodes,
  on(fromActions.fetchBenchmarkSubmodesSuccess, (_, { submodes }) => submodes)
)

const modeReducer = createReducer(
  initialState.mode,
  on(fromActions.setBenchmarkMode, (_, { mode }) => mode)
)

const submodeReducer = createReducer(
  initialState.submode,
  on(fromActions.setBenchmarkSubmode, (_, { submode }) => submode)
)

const subheaderReducer = createReducer(
  initialState.subheaderBySubmode,
  on(fromActions.setBenchmarkSubmode, (state, { submode, subheader }) =>
    submode && subheader ? assoc(submode, subheader, state) : state
  )
)

const managePeerSetsModeReducer = createReducer(
  initialState.managePeerSetsMode,
  on(fromActions.setBenchmarkManagePeerSetsMode, (_, { mode }) => mode)
)

const dataReducer = createReducer(
  initialState.data,
  on(fromActions.fetchBenchmarkChartsSuccess, (_, { data }) => data),
  on(
    fromActions.clearBenchmarkCharts,
    fromActions.setBenchmarkMode,
    () => initialState.data
  )
)

const reinsuranceSummaryDataReducer = createReducer(
  initialState.reinsuranceSummary,
  on(
    fromControlActions.fetchBenchmarkReinsuranceSummarySuccess,
    (_, { data }) => data
  ),
  on(
    fromActions.clearBenchmarkCharts,
    fromActions.setBenchmarkMode,
    () => initialState.reinsuranceSummary
  )
)

const maximizeIndexReducer = createReducer(
  initialState.maximizeIndex,
  on(fromActions.maximizeBenchmarkChart, (state, { index }) =>
    state !== index ? index : null
  )
)

const downloadingChartReducer = createReducer(
  initialState.downloadingChart,
  on(fromActions.setBenchmarkDownloadingChart, (_, { value }) => value)
)

const extentsReducer = createReducer(
  initialState.extents,
  on(fromActions.setBenchmarkExtents, (state, { extents }) => {
    const [gridIndex, ...value] = extents
    return { ...state, [gridIndex]: value }
  })
)

const dataRefreshStatusReducer = createReducer(
  initialState.dataRefreshStatus,
  on(fromActions.fetchBenchmarkDataRefreshStatusSuccess, (_, { data }) => data)
)

const fullExportReducer = createReducer(
  initialState.fullExport,
  on(fromActions.exportFullBenchmarkData, () => ({ active: true })),
  on(
    fromControlActions.AddBenchmarkControlsPeers,
    (state, { isFullExport, peers }) =>
      isFullExport ? { active: true, peers } : state
  ),
  on(fromActions.fetchBenchmarkChartsFailure, () => ({ active: false })),
  on(
    fromControlActions.RemoveBenchmarkControlsPeers,
    (state, { isFullExport }) => (isFullExport ? { active: false } : state)
  ),
  on(
    fromControlActions.RemoveBenchmarkControlsSavedPeers,
    (state, { isFullExport }) => (isFullExport ? { active: false } : state)
  )
)

const loadingReducer = createReducer(
  initialState.loading,
  on(fromActions.fetchBenchmarkCharts, () => true),
  on(
    fromActions.clearBenchmarkCharts,
    fromActions.fetchBenchmarkChartsSuccess,
    fromActions.fetchBenchmarkChartsFailure,
    fromActions.fetchBenchmarkChartsAbort,
    () => false
  )
)

const errorReducer = createReducer(
  initialState.error,
  on(fromActions.fetchBenchmarkChartsFailure, (_, { error }) => error),
  on(
    fromActions.clearBenchmarkCharts,
    fromActions.fetchBenchmarkCharts,
    fromActions.fetchBenchmarkChartsSuccess,
    fromActions.fetchBenchmarkChartsAbort,
    // @ts-ignore
    () => null
  )
)

const fetchTimeReducer = createReducer(
  initialState.fetchTime,
  on(fromActions.fetchBenchmarkChartsSuccess, () => Date.now()),
  on(
    fromActions.clearBenchmarkCharts,
    fromActions.invalidateBenchmarkData,
    // @ts-ignore
    () => undefined
  )
)

const _benchmarkReducer = combineReducers<BenchmarkState>({
  ...BenchmarkEntityStore.reducers,
  submodes: metricOptionsBySubmodeReducer,
  mode: modeReducer,
  submode: submodeReducer,
  subheaderBySubmode: subheaderReducer,
  managePeerSetsMode: managePeerSetsModeReducer,
  prevMode: state => state ?? initialState.prevMode,
  prevSubmode: state => state ?? initialState.prevSubmode,
  // @ts-ignore
  companiesByMode: fromCompany.reducer,
  // @ts-ignore
  metricsByMode: fromMetric.reducer,
  controls: fromControls.reducer,
  peerSet: fromPeerSet.reducer,
  metricView: fromMetricView.reducer,
  data: dataReducer,
  reinsuranceSummary: reinsuranceSummaryDataReducer,
  fullExport: fullExportReducer,
  maximizeIndex: maximizeIndexReducer,
  downloadingChart: downloadingChartReducer,
  extents: extentsReducer,
  export: fromExport.reducer,
  loading: loadingReducer,
  error: errorReducer,
  fetchTime: fetchTimeReducer,
  dataRefreshStatus: dataRefreshStatusReducer,
})

const setPrevReducer = createReducer(
  initialState,
  on(fromActions.setBenchmarkMode, state => ({
    ...state,
    prevMode: state.mode,
  })),
  on(fromActions.setBenchmarkSubmode, state => ({
    ...state,
    prevSubmode: state.submode,
  })),
  on(fromActions.changeBenchmarkMode, fromActions.moveBenchmarkMode, state => ({
    ...state,
    prevMode: state.mode,
    prevSubmode: state.submode,
  }))
)

const benchmarkReducer = reduceReducers(setPrevReducer, _benchmarkReducer)

export function reducer(
  state: BenchmarkState | undefined,
  action: Action
): BenchmarkState {
  return benchmarkReducer(state, action)
}
