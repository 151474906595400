import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { isIndexedLayer } from 'src/app/analysis/layers/indexed-layer'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { extractPortfolioSetID } from 'src/app/analysis/model/portfolio-set-id.util'
import {
  LogicalPortfolioLayer,
  LossSetLayer,
  Portfolio,
} from 'src/app/api/analyzere/analyzere.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { AppState } from 'src/app/core/store'
import { selectCurrentClient } from '../../../../core/store/broker/broker.selectors'
import { mapAssoc, mergeMapAssoc } from '../../../../api/util'
import { fetchStudyReinsurer } from '../../../../reinsurers/store/study-reinsurers.actions'
import { convertFromLogicalPortfolioLayers } from '../../../model/layers.converter'
import { Layer } from '../../../model/layers.model'
import { isLayerAgg } from '../../../model/layers.util'
import {
  fetchCededLayersViews,
  fetchPortfolioView,
} from '../../views/portfolio-view.actions'
import * as ProgramGroupActions from '../program-group/program-group.actions'
import * as ProgramActions from './program.actions'

@Injectable()
export class GrouperProgramEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetchAllProgramLayersOnAddProgramGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.addProgramGroupSuccess),
      concatMap(programs =>
        programs.programs.map(program =>
          ProgramActions.fetchGrouperProgramLayers({ program })
        )
      )
    )
  )

  fetchLayersOnAddProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActions.addProgramToGroup),
      map(({ program, parentGroupID }) => ({ ...program, parentGroupID })),
      map(program => ProgramActions.fetchGrouperProgramLayers({ program }))
    )
  )

  fetchLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActions.fetchGrouperProgramLayers),
      mergeMapAssoc('cededPortfolio', ({ program }) =>
        this.service.fetchPortfolioWithAggFeederAndRiskVisible(
          program.cededPortfolioID
        )
      ),
      mergeMapAssoc('feederLayers', ({ cededPortfolio }) => {
        const layers = convertFromLogicalPortfolioLayers(
          cededPortfolio.layers as LogicalPortfolioLayer[]
        )
        const feederID = layers.find(l => isLayerAgg(l))?.layerRefs[0]
        if (!feederID) {
          return of({ data: [] })
        }
        return this.service.fetchLayers([feederID])
      }),
      mergeMapAssoc('complexLayers', ({ cededPortfolio }) => {
        const actualFHCFLayer = (
          cededPortfolio.layers as LogicalPortfolioLayer[]
        ).find(layer => layer.meta_data.isFHCFFinal)
        const actualRiskLayer = (
          cededPortfolio.layers as LogicalPortfolioLayer[]
        ).find(layer => layer.meta_data.isRiskFinal)
        const indexedLayers = (
          cededPortfolio.layers as LogicalPortfolioLayer[]
        ).filter(layer => isIndexedLayer(layer, 'main-layer'))
        const swingLayers = (
          cededPortfolio.layers as LogicalPortfolioLayer[]
        ).filter(layer => isSwingLayer(layer, 'combined-layer'))
        const multiSectionLayers = (
          cededPortfolio.layers as LogicalPortfolioLayer[]
        ).filter(layer => isMultiSectionLayer(layer, 'main-layer'))
        if (
          !actualFHCFLayer &&
          !actualRiskLayer &&
          indexedLayers.length === 0 &&
          swingLayers.length === 0 &&
          multiSectionLayers.length === 0
        ) {
          return of({ data: [] })
        }

        const fetchIds = []

        if (actualFHCFLayer) {
          fetchIds.push((actualFHCFLayer.sources[0] as LossSetLayer).id)
          fetchIds.push((actualFHCFLayer.sources[1] as LossSetLayer).id)
        }

        if (actualRiskLayer) {
          const riskSources = actualRiskLayer.sources as LossSetLayer[]
          riskSources.forEach(s => {
            if (s.meta_data.sage_layer_type === 'noncat_risk') {
              fetchIds.push(s.id)
            }
          })
          // tslint:disable-next-line: no-non-null-assertion
          fetchIds.push(actualRiskLayer.meta_data.riskVisibleLayerID!)
        }

        indexedLayers.forEach(mainLayer => {
          if (mainLayer.meta_data.visible_layer_id) {
            fetchIds.push(mainLayer.meta_data.visible_layer_id)
          }
        })

        swingLayers.forEach(combinedLayer => {
          const combinedSources = combinedLayer.sources as LossSetLayer[]
          combinedSources.forEach(layer => {
            fetchIds.push(layer.id)
          })

          if (combinedLayer.meta_data.visible_layer_id) {
            fetchIds.push(combinedLayer.meta_data.visible_layer_id)
          }
        })

        multiSectionLayers.forEach(mainLayer => {
          const sections = mainLayer.sources as LossSetLayer[]
          sections.forEach(layer => {
            fetchIds.push(layer.id)
          })

          if (mainLayer.meta_data.visible_layer_id) {
            fetchIds.push(mainLayer.meta_data.visible_layer_id)
          }
        })

        return this.service.fetchLayers(fetchIds)
      }),
      mapAssoc(
        'cededPortfolio',
        ({ cededPortfolio, feederLayers, complexLayers }) => {
          const layers = [
            ...cededPortfolio.layers,
            ...feederLayers,
            ...complexLayers.filter(e => !e.meta_data?.inuranceSource),
          ]
          return { ...cededPortfolio, layers } as Portfolio
        }
      ),
      mergeMapAssoc('netPortfolio', ({ program }) => {
        return this.service.fetchPortfolio(program.netPortfolioID)
      }),
      concatMap(res =>
        of(res).pipe(
          withLatestFrom(this.store.pipe(select(selectCurrentClient)))
        )
      ),
      map(([res, client]) => {
        const portfolioSetID = extractPortfolioSetID(res.program)
        if (!client || !portfolioSetID) {
          throw Error(
            'Cannot add group program without portfolio, yearID and study IDs'
          )
        }
        const clientID = client.id
        const studyID = res.program.studyID
        const portfolioSetAndStudyIDs = {
          ...portfolioSetID,
          clientID,
          studyID,
        }
        return { res, portfolioSetAndStudyIDs }
      }),
      mergeMap(({ res, portfolioSetAndStudyIDs }) => {
        const actions = []
        if (res.error) {
          actions.push(
            ProgramActions.fetchGrouperProgramLayersFailure({
              program: res.program,
              error: res.error,
            })
          )
        } else {
          const cededLayers: Layer[] = convertFromLogicalPortfolioLayers(
            res.cededPortfolio.layers as LogicalPortfolioLayer[]
          )
          const netLayers = res.netPortfolio.layers as LogicalPortfolioLayer[]
          actions.push(
            ProgramActions.fetchGrouperProgramLayersSuccess({
              program: res.program,
              cededLayers,
              netLayers: netLayers.map((l: LogicalPortfolioLayer) => l.id),
            })
          )
          actions.push(fetchPortfolioView(portfolioSetAndStudyIDs))
          const sharedLimitLayerID = cededLayers
            .filter(l => l.meta_data.sage_layer_type === 'shared_limits')
            .map(l => l.physicalLayer.id)

          const sharedLimitLayerCurrency = cededLayers
            .filter(l => l.meta_data.sage_layer_type === 'shared_limits')
            .map(l =>
              l.layerResultsMetricCurrency
                ? l.layerResultsMetricCurrency
                : l.currency
                ? l.currency
                : ''
            )

          const currencyList = [
            ...cededLayers.map(l =>
              l.layerResultsMetricCurrency
                ? l.layerResultsMetricCurrency
                : l.currency
                ? l.currency
                : ''
            ),
            ...sharedLimitLayerCurrency,
          ]
          const layerIDs = [
            ...cededLayers.map(l => l.id),
            ...sharedLimitLayerID,
          ]

          actions.push(
            fetchStudyReinsurer({
              carrierID: portfolioSetAndStudyIDs.clientID,
              studyID: portfolioSetAndStudyIDs.studyID,
            })
          )

          actions.push(
            fetchCededLayersViews({
              ...portfolioSetAndStudyIDs,
              layerIDs,
              analysisProfileID: portfolioSetAndStudyIDs.analysisProfileID,
              isDesign: false,
              isGroup: true,
              layerCurrencies: currencyList,
            })
          )
        }
        return actions
      })
    )
  )
}
