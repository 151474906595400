import { CreditTransactionType } from './../model/credit-layer.model'
import { Injectable } from '@angular/core'
import { CreditService } from './../../api/credit/credit.service'
import { CreditThumbnailService } from './../services/credit-thumbnail.service'
import { BackendService } from '../../api/backend/backend.service'
import {
  CreateSubmissionProps,
  CreateSubmissionSuccessProps,
  CreditStatus,
  CreditSubmissionStructure,
  CreditSubmissionUserTrancheInputs,
} from '../model/credit-submission.model'
import { CreditCalculationStructure } from '../model/credit-structure.model'
import { forkJoin, map, Observable, switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CreditSubmissionService {
  private trancheInputs: CreditSubmissionUserTrancheInputs[] = []
  constructor(
    private creditService: CreditService,
    private creditThumbnailService: CreditThumbnailService,
    private backendService: BackendService
  ) {}

  createSubmissions(
    props: CreateSubmissionProps
  ): Observable<CreateSubmissionSuccessProps> {
    return this.createCalculationStructures(props).pipe(
      switchMap(calculationStructures => {
        calculationStructures.forEach(calculationStructure => {
          this.addTrancheInputsForStructure(calculationStructure)
          this.saveThumbnailForStructure(props, calculationStructure)
        })
        return this.createSubmissionStructures(props, calculationStructures)
      }),
      switchMap(submissionStructures =>
        this.createSubmissionUserTranches(props, submissionStructures)
      )
    )
  }

  private createCalculationStructures(
    props: CreateSubmissionProps
  ): Observable<CreditCalculationStructure[]> {
    const length = Object.keys(props.reinsurerUserMap).length
    const requests = Array.from({ length }, () =>
      this.creditService.createSubmissionStructure(props.calculationStructure)
    )
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data))
    )
  }

  private createSubmissionStructures(
    props: CreateSubmissionProps,
    calculationStructures: CreditCalculationStructure[]
  ): Observable<CreditSubmissionStructure[]> {
    const reinsurerIds = Object.keys(props.reinsurerUserMap).map(key =>
      Number(key)
    )
    const requests = calculationStructures.map((calculationStructure, i) =>
      this.creditService.postCreditSubmissionStructure({
        program_id: props.creditStructure.program_id,
        label: calculationStructure.name,
        description: props.description,
        credit_submission_structure_id: calculationStructure._id,
        credit_structure_id: props.creditStructure.id,
        reinsurer_id: reinsurerIds[i],
        transaction_id: props.transactionId,
        status: CreditStatus.Sent,
      })
    )
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data))
    )
  }

  private createSubmissionUserTranches(
    props: CreateSubmissionProps,
    submissionStructures: CreditSubmissionStructure[]
  ): Observable<CreateSubmissionSuccessProps> {
    const requests = submissionStructures.flatMap(submissionStructure => {
      const userIds = props.reinsurerUserMap[submissionStructure.reinsurer_id]
      const trancheInputs = this.trancheInputs.filter(
        t => t.transactionId === submissionStructure.transaction_id
      )
      if (!userIds || !trancheInputs) {
        return
      }
      // for each user associated with the reinsurer, for each tranche on the transaction
      return userIds.flatMap(userId =>
        trancheInputs.map(trancheInput =>
          this.creditService.saveCreditSubmissionUserTranche({
            submission_id: submissionStructure.id,
            user_id: userId,
            tranche_id: trancheInput.trancheId,
            rol: trancheInput.defaultRol,
            reinsurer_participation_percent:
              trancheInput.defaulReinsurerParticipationPercent,
            collateral_percent: trancheInput.defaultCollateralPercent,
            ceding_commission: trancheInput.defaultCedingComission,
            profit_commission: trancheInput.defaultProfitComission,
          })
        )
      )
    })
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data)),
      map(userTranches => {
        return {
          submissionCount: submissionStructures.length,
          submissionUserTrancheCount: userTranches.length,
        }
      })
    )
  }

  private saveThumbnailForStructure(
    props: CreateSubmissionProps,
    calculationStructure: CreditCalculationStructure
  ): void {
    const timestamp = new Date().toISOString() // used to bust cache
    if (!props.creditStructure.imageSrc) {
      return
    }
    this.backendService
      .getThumbnailImage(props.creditStructure.imageSrc, timestamp)
      .subscribe((data: Blob) => {
        this.creditThumbnailService
          .saveCreditStructureThumbnail(
            props.portfolio._id,
            calculationStructure._id,
            data
          )
          .subscribe()
      })
  }

  private addTrancheInputsForStructure(
    calculationStructure: CreditCalculationStructure
  ): void {
    const trancheInputsToAdd: CreditSubmissionUserTrancheInputs[] =
      calculationStructure.transactions.flatMap(transaction =>
        transaction.tranches.map(tranche => {
          return {
            transactionId: transaction._id,
            trancheId:
              transaction.transaction_type !== CreditTransactionType.pmiQs
                ? tranche._id
                : transaction._id,
            defaultRol:
              transaction.transaction_type !== CreditTransactionType.pmiQs
                ? tranche.rol
                : 0,
            defaulReinsurerParticipationPercent:
              tranche.reinsurer_participation_percent,
            defaultCollateralPercent: calculationStructure.collateral_percent,
            defaultCedingComission:
              transaction.transaction_type === CreditTransactionType.pmiQs
                ? transaction.ceding_commission
                : 0,
            defaultProfitComission:
              transaction.transaction_type === CreditTransactionType.pmiQs
                ? transaction.profit_commission
                : 0,
          }
        })
      )
    this.trancheInputs.push(...trancheInputsToAdd)
  }
}
