<app-optimization
  [rangesTypes]="rangesTypes$ | async"
  [candidateLayers]="candidateLayers$ | async"
  [candidateLayersLoading]="candidateLayersLoading$ | async"
  [candidateLayersMetricsLoading]="candidateLayersMetricsLoading$ | async"
  [candidateResultsMetrics]="candidateResultsMetrics$ | async"
  [currencyList]="currencyList$ | async"
  [resultsView]="resultsView$ | async"
  [chartState]="chartState$ | async"
  [chartView]="chartView$ | async"
  [candidatesLayersError]="candidatesLayersError$ | async"
  [metricsError]="metricsError$ | async"
  [saving]="saving$ | async"
  [savingError]="savingError$ | async"
  [lastCreated]="lastCreated$ | async"
  [lossSetGroups]="lossSetGroups$ | async"
  [lossFilters]="lossFilters$ | async"
  [portfolioTailMetrics]="portfolioTailMetrics$ | async"
  (closeClick)="onCloseClick()"
  (rangeChange)="onRangeChange($event)"
  (currencyChange)="onCurrencyChange($event)"
  (resetClick)="onResetClick()"
  (runCombinations)="onRunCombinations()"
  (rangeResultValueChange)="onRangeResultValueChange($event)"
  (runLayerMetrics)="onRunLayersMetrics()"
  (viewChange)="onViewChange($event)"
  (dimensionChange)="onDimensionChange($event)"
  (save)="onSave()"
  (candidateLayerChange)="onCandidateLayerChange($event)"
  (selectedLossSetGroupIDsChange)="onSelectedLossSetGroupIDsChange($event)"
  (portfolioReturnPeriodToggleChange)="
        onPortfolioReturnPeriodToggleChange($event)
      "
></app-optimization>
