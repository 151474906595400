import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { ContactSupport } from 'src/app/contact-support/model/contact-support.model'

@Injectable({
  providedIn: 'root',
})
export class ContactSupportService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  sendSupportEmail(contactSupport: ContactSupport): ApiResponse<string> {
    const additionalRecipientsEmailString =
      contactSupport.additionalRecipientsEmail.length > 0
        ? contactSupport.additionalRecipientsEmail.join(', ')
        : ''
    const formData = new FormData()
    formData.append('reportType', contactSupport.reportType)
    formData.append('isUrgent', JSON.stringify(contactSupport.isUrgent))
    formData.append(
      'additionalRecipientsEmail',
      additionalRecipientsEmailString
    )
    formData.append('emailSubject', contactSupport.emailSubject)
    formData.append('issueDescription', contactSupport.issueDescription)
    formData.append('currentPageUrl', contactSupport.currentPageUrl)
    formData.append('currentClient', contactSupport.currentClient)
    formData.append('currentYear', contactSupport.currentYear)
    formData.append('currentProgram', contactSupport.currentProgram)
    if (contactSupport.attachmentFile) {
      formData.append(
        'attachments',
        contactSupport.attachmentFile,
        contactSupport.attachmentFile.name
      )
    }

    const url = `${environment.internalApi.base}${environment.internalApi.sendEmail}/contact-support`
    return this.httpClient
      .post<string>(url, formData)
      .pipe(mapToMaybeData(), catchAndHandleError('Send Support Email'))
  }
}
