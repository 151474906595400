import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { forkJoin, Observable, of } from 'rxjs'
import {
  delay,
  filter,
  map,
  concatMap,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators'
import {
  LayerViewResponse,
  LogicalPortfolioLayer,
  LossSetLayer,
  Metrics,
  PhysicalPortfolioLayer,
  Portfolio,
  Update,
} from '../../../../api/analyzere/analyzere.model'
import { AnalyzreService } from '../../../../api/analyzere/analyzre.service'
import {
  ApiResponse,
  MaybeData,
  MaybeError,
} from '../../../../api/model/api.model'
import {
  rejectError,
  rejectErrorWithInput,
  concatMapWithInput,
  mapToMaybeData,
} from '../../../../api/util'
import { AppState } from '../../../../core/store'
import { Layer } from '../../../model/layers.model'
import * as SharedLimitActions from './grouper-shared-limit.actions'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import { selectPrograms } from '../../../../core/store/program/program.selectors'
import { selectProgramGroupsByID } from '../../../../core/store/program-group/program-group.selectors'
import { selectCurrentClient } from '../../../../core/store/broker/broker.selectors'
import {
  selectGrouperSlides,
  selectGrouperProgramGroups,
  selectGrouperPrograms,
  selectGrouperProgramEntitiesByID,
  selectGrouperProgramGroupMembers,
  selectAllGrouperProgramCededLayerStates,
} from '../../analysis.selectors'
import { createFetchPortfolioViewActionsFromGroups } from '../program-group/create-portfolio-view-action'
import {
  fetchViewsForStructuresAndGetAncestors,
  getGroupsWithStudyAndClient,
} from '../grouper-util'
import { SharedLimitService } from 'src/app/api/shared-limit/shared-limit.service'
import { selectProgramGroupMembers } from 'src/app/core/store/program-group-member.selectors'
import { updateProgramEntityRecon } from '../program/program.actions'
import {
  selectSharedLimitMembers,
  selectSharedLimits,
} from '../../../../core/store/auth/auth.selectors'
import { SharedLimit } from '../program-group.model'
import { OmitID } from 'src/app/api/model/backend.model'

// tslint:disable: no-non-null-assertion
@Injectable()
export class GrouperSharedLimitEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private inuranceService: InuranceService,
    private sharedLimitService: SharedLimitService
  ) {}

  /**
   * Create the Shared Limit Layer and get the Mean Loss value
   * from the Shared Limit using the Metrics api.
   */
  addSharedLimit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedLimitActions.addSharedLimit),
      map(({ type, ...props }) => props),
      concatMap(props => {
        return this.sharedLimitService.addSharedLimit(
          props.layer,
          props.selectedLayerEntities
        )
      }),
      rejectError(error =>
        this.store.dispatch(SharedLimitActions.addSharedLimitFailure({ error }))
      ),
      map(res => {
        return SharedLimitActions.addSharedLimitSuccess({
          cededPortfolios: res.cededPortfolios,
          netPortfolios: res.netPortfolios,
        })
      })
    )
  })

  /**
   * Deletes the Shared Limit Layer. Since only the Nested BackAllocated Layer
   * is part the Portfolio (Ceded, Net), when a Shared Limit Delete is dispatched,
   * the Ceded and Net Portfolios need to be updated with those omitted.
   */
  deleteSharedLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedLimitActions.deleteSharedLimit),
      concatMap(action => {
        const nestedLayerCeded: Record<string, string[]> = JSON.parse(
          action.sharedLimitLayer.meta_data.nestedLayersCededPortfolioRecord!
        )
        const nestedLayerNet: Record<string, string[]> = JSON.parse(
          action.sharedLimitLayer.meta_data.nestedLayersNetPortfolioRecord!
        )
        const cededUpdates: Array<ApiResponse<Portfolio>> = []
        const netUpdates: Array<ApiResponse<Portfolio>> = []
        const nestedLayerIDs = Object.keys(nestedLayerCeded).flatMap(
          key => nestedLayerCeded[key]
        )
        // Get all the Nested Backallocated layers from the Ceded and
        // Net Portfolio stored in the
        // nestedLayersCededPortfolioRecord/nestedLayersNetPortfolioRecord metadata
        // property of the Shared Limit Layer and update the Portfolios without them.
        Object.keys(nestedLayerCeded).forEach(portfolioID => {
          cededUpdates.push(
            this.sharedLimitService
              .removeNestedLayersAndRestore(portfolioID, nestedLayerCeded, true)
              .pipe(this.service.appendAggFeederAndRiskVisible())
          )
        })
        Object.keys(nestedLayerNet).forEach(portfolioID => {
          netUpdates.push(
            this.sharedLimitService.removeNestedLayersAndRestore(
              portfolioID,
              nestedLayerNet,
              false
            )
          )
        })
        return forkJoin([forkJoin(cededUpdates), forkJoin(netUpdates)]).pipe(
          withLatestFrom(
            this.store.pipe(select(selectSharedLimits)),
            this.store.pipe(select(selectSharedLimitMembers))
          ),
          concatMap(
            ([
              [cededResults, netResults],
              sharedLimits,
              sharedLimitMambers,
            ]) => {
              for (const result of [...cededResults, ...netResults]) {
                if (result.error) {
                  return of({ error: result.error })
                }
              }
              return this.sharedLimitService
                .deleteSharedLimit(
                  action.sharedLimitLayer,
                  sharedLimits,
                  sharedLimitMambers
                )
                .pipe(
                  map(res => ({
                    res,
                  }))
                )
                .pipe(
                  mergeMap(() => {
                    return this.inuranceService
                      .reconcileWithSharedLimitDelete(nestedLayerIDs)
                      .pipe(
                        concatMap(response => {
                          if (response.error) {
                            return of({ error: response.error })
                          } else {
                            if (response.data) {
                              response.data.map(u => {
                                const props = {
                                  layerId: u.id,
                                  metaData: u.meta_data,
                                }
                                this.store.dispatch(
                                  updateProgramEntityRecon(props)
                                )
                              })
                            }
                            return forkJoin([
                              this.service.fetchPortfoliosWithAggFeederAndRiskVisible(
                                cededResults.map(r => r.data!.id)
                              ),
                              this.service.fetchPortfolios(
                                netResults.map(r => r.data!.id)
                              ),
                            ]).pipe(
                              map(([cededPortfolios, netPortfolios]) => {
                                if (
                                  cededPortfolios.error ||
                                  netPortfolios.error
                                ) {
                                  return {
                                    error:
                                      cededPortfolios.error ||
                                      netPortfolios.error,
                                  }
                                } else {
                                  return {
                                    data: {
                                      cededPortfolios: cededPortfolios.data!,
                                      netPortfolios: netPortfolios.data!,
                                      cededNestedLayerRecord: nestedLayerCeded,
                                      nestedLayerIDs,
                                    },
                                  }
                                }
                              })
                            )
                          }
                        })
                      )
                  })
                )
            }
          )
        ) as ApiResponse<{
          cededPortfolios: Portfolio[]
          netPortfolios: Portfolio[]
          cededNestedLayerRecord: Record<string, string[]>
          nestedLayerIDs: string[]
        }>
      }),
      filter(({ error }) => {
        if (error) {
          this.store.dispatch(
            SharedLimitActions.deleteSharedLimitFailure({ error })
          )
          return false
        }
        return true
      }),
      withLatestFrom(
        this.store.pipe(select(selectPrograms)),
        this.store.pipe(select(selectProgramGroupsByID)),
        this.store.pipe(select(selectProgramGroupMembers))
      ),
      concatMapWithInput(([results]) => {
        return this.service.fetchLayers<LogicalPortfolioLayer>(
          results.data!.nestedLayerIDs
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          SharedLimitActions.deleteSharedLimitFailure({ error })
        )
      ),
      concatMapWithInput(
        ([nestedLayers, [results, structures, groupsByID, groupMembers]]) => {
          return this.sharedLimitService.reconcileSharedLimitWithGroup(
            results.data!.cededNestedLayerRecord,
            nestedLayers,
            structures,
            groupsByID,
            groupMembers,
            false
          )
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          SharedLimitActions.deleteSharedLimitFailure({ error })
        )
      ),
      map(([_, [_nestedLayers, [results]]]) => {
        return SharedLimitActions.deleteSharedLimitSuccess({
          cededPortfolios: results.data!.cededPortfolios,
          netPortfolios: results.data!.netPortfolios,
        })
      })
    )
  })

  /**
   * Updates all Nested Backalloated layers when a layer part of a shared limit is
   * updated or removed from a Portfolio. The action updates the Shared Limit Layer,
   * recalculates the mean loss metric from it, and recalculates the premium in the Quota
   * Share Layer by calculating the mean loss on the BackAllocated layer.
   */
  updateSharedLimit$ = createEffect(() => {
    return (
      this.actions$
        .pipe(
          ofType(SharedLimitActions.updateSharedLimit),
          delay(500),
          filter(({ sharedLimitLayers }) => {
            if (sharedLimitLayers.length === 0) {
              this.store.dispatch(
                SharedLimitActions.updateSharedLimitSucccess({
                  cededPortfolios: [],
                  netPortfolios: [],
                })
              )
              return false
            }
            return true
          }),
          // For all deleted Nested Backallocated layer, update the
          // nestedLayersCededPortfolioRecord/nestedLayersNetPortfolioRecord metadata
          // in the Shared Limit Layer.
          concatMap(
            ({ sharedLimitLayers, deletedNestedBackAllocatedLayers }) => {
              const deletedLayerIDs = deletedNestedBackAllocatedLayers.map(
                d => d.meta_data.backAllocatedForID!
              )
              const deletedBackAllocatedLayerIDs =
                deletedNestedBackAllocatedLayers.map(d => d.id)
              const updates: Update<LogicalPortfolioLayer>[] = []
              const mutableSharedLimitLayers: Layer[] = JSON.parse(
                JSON.stringify(sharedLimitLayers)
              )
              if (deletedNestedBackAllocatedLayers.length > 0) {
                mutableSharedLimitLayers.forEach(sharedLimit => {
                  const nestedLayerCededRecord: Record<string, string[]> =
                    JSON.parse(
                      sharedLimit.meta_data.nestedLayersCededPortfolioRecord!
                    )
                  Object.keys(nestedLayerCededRecord).forEach(key => {
                    nestedLayerCededRecord[key] = nestedLayerCededRecord[
                      key
                    ].filter(id => !deletedBackAllocatedLayerIDs.includes(id))
                  })
                  const nestedLayerNetRecord: Record<string, string[]> =
                    JSON.parse(
                      sharedLimit.meta_data.nestedLayersNetPortfolioRecord!
                    )
                  Object.keys(nestedLayerNetRecord).forEach(key => {
                    nestedLayerNetRecord[key] = nestedLayerNetRecord[
                      key
                    ].filter(id => !deletedBackAllocatedLayerIDs.includes(id))
                  })
                  const layerRefs = sharedLimit.layerRefs.filter(
                    id => !deletedLayerIDs.includes(id)
                  )
                  sharedLimit.meta_data.nestedLayersCededPortfolioRecord =
                    JSON.stringify(nestedLayerCededRecord)
                  sharedLimit.meta_data.nestedLayersNetPortfolioRecord =
                    JSON.stringify(nestedLayerNetRecord)
                  sharedLimit.layerRefs = layerRefs
                  // If the layerRefs of a Shared Layer is zero
                  // there is no need to do anything. A zero layerRefs
                  // means all BackAllocated layers have been deleted
                  // from all Portfolios. The Shared Limit is completely
                  // removed by doing that.
                  if (sharedLimit.layerRefs.length > 0) {
                    updates.push({
                      id: sharedLimit.id,
                      change: {
                        sources: [...layerRefs.map(l => ({ ref_id: l }))],
                        meta_data: {
                          ...sharedLimit.meta_data,
                        },
                      },
                    })
                  }
                })
              }
              if (updates.length === 0) {
                return of({
                  data: {
                    sharedLimitLayers: mutableSharedLimitLayers,
                  },
                }) as ApiResponse<{
                  sharedLimitLayers: Layer[]
                }>
              } else {
                return this.service.patchLogicalPortfolioLayers(updates).pipe(
                  map(res => {
                    if (res.error) {
                      return { error: res.error }
                    } else {
                      return {
                        data: {
                          sharedLimitLayers: mutableSharedLimitLayers,
                        },
                      }
                    }
                  })
                ) as ApiResponse<{
                  sharedLimitLayers: Layer[]
                }>
              }
            }
          ),
          rejectError(error =>
            this.store.dispatch(
              SharedLimitActions.updateSharedLimitFailure({ error })
            )
          ),
          // For each Shared Limit Layer, retrieve the LayerView needed
          // for metrics.
          concatMap(({ sharedLimitLayers }) => {
            const observables: Array<
              ApiResponse<LayerViewResponse & { layerID: string }>
            > = []
            sharedLimitLayers.forEach(sharedLayer => {
              observables.push(
                this.service.postLayerView(
                  sharedLayer.id,
                  sharedLayer.meta_data.analysisProfileID!
                )
              )
            })
            return forkJoin(observables).pipe(
              map(res => {
                for (const r of res) {
                  if (r.error) {
                    return { error: r.error } as MaybeError &
                      MaybeData<{
                        sharedLimitLayers: Layer[]
                        layerViewResponses: Array<
                          LayerViewResponse & { layerID: string }
                        >
                      }>
                  }
                }
                return {
                  data: {
                    sharedLimitLayers,
                    layerViewResponses: res.map(r => r.data!),
                  },
                }
              })
            )
          }),
          rejectError(error =>
            this.store.dispatch(
              SharedLimitActions.updateSharedLimitFailure({ error })
            )
          ),
          // For each LayerView, get the mean loss Metrics from
          // from the Shared Limit Layer.
          concatMap(({ sharedLimitLayers, layerViewResponses }) => {
            const observables: Array<
              Observable<MaybeError & MaybeData<Metrics> & { layerID: string }>
            > = []
            layerViewResponses.forEach(r => {
              observables.push(
                this.service.getMeanLossMetrics(r.id).pipe(
                  map(res => {
                    return { ...res, layerID: r.layerID }
                  })
                )
              )
            })
            return forkJoin(observables).pipe(
              map(res => {
                for (const r of res) {
                  if (r.error) {
                    return { error: r.error } as MaybeData<{
                      sharedLimitLayers: Layer[]
                      metrics: Array<Metrics & { layerID: string }>
                    }> &
                      MaybeError
                  }
                }
                return {
                  data: {
                    sharedLimitLayers,
                    metrics: res.map(r => ({ ...r.data!, layerID: r.layerID })),
                  },
                }
              })
            )
          }),
          rejectError(error =>
            this.store.dispatch(
              SharedLimitActions.updateSharedLimitFailure({ error })
            )
          )
        )
        // Recalculate the premium value of the Quota Share Layer by using the
        // Shared Limit Layer mean loss and the mean loss of each Backallocated
        // Layer referenced in the "source" property of the Nested Backallocated Layer.
        // Metrics for each Backallocated Layers is calculated for this.
        .pipe(
          concatMap(({ sharedLimitLayers, metrics }) => {
            const sharedLimitUpdates: Array<
              ApiResponse<{
                cededPortfolios: Portfolio[]
                netPortfolios: Portfolio[]
              }>
            > = []
            sharedLimitLayers.forEach(sharedLayer => {
              const nestedLayerCededRecord: Record<string, string[]> =
                JSON.parse(
                  sharedLayer.meta_data.nestedLayersCededPortfolioRecord!
                )
              const nestedLayerNetRecord: Record<string, string[]> = JSON.parse(
                sharedLayer.meta_data.nestedLayersNetPortfolioRecord!
              )
              const metric = metrics.find(m => m.layerID === sharedLayer.id)
              const analysisProfileID = sharedLayer.meta_data.analysisProfileID!
              const updates: Array<ApiResponse<LossSetLayer[]>> = []
              Object.keys(nestedLayerCededRecord).forEach(cededPortfolioID => {
                updates.push(
                  this.service.fetchPortfolio(cededPortfolioID).pipe(
                    concatMap(res => {
                      if (res.error) {
                        return of({ error: res.error }) as ApiResponse<
                          LossSetLayer[]
                        >
                      } else {
                        const portfolio = res.data!
                        const nestedLayerIDs =
                          nestedLayerCededRecord[cededPortfolioID]
                        const portfolioLayers =
                          portfolio.layers as LogicalPortfolioLayer[]
                        const nestedLayers = portfolioLayers.filter(l =>
                          nestedLayerIDs.includes(l.id)
                        )
                        const observables: Array<ApiResponse<LossSetLayer>> = []
                        nestedLayers.forEach(nestedLayer => {
                          observables.push(
                            this.sharedLimitService.updateNestedLayer(
                              nestedLayer,
                              analysisProfileID,
                              metric!,
                              sharedLayer
                            )
                          )
                        })
                        if (observables.length === 0) {
                          return of({ data: [] }) as ApiResponse<LossSetLayer[]>
                        } else {
                          return forkJoin(observables).pipe(
                            map(responses => {
                              for (const r of responses) {
                                if (r.error) {
                                  return { error: r.error }
                                }
                              }
                              return { data: responses.map(r => r.data!) }
                            })
                          ) as ApiResponse<LossSetLayer[]>
                        }
                      }
                    })
                  )
                )
              })
              sharedLimitUpdates.push(
                forkJoin(updates).pipe(
                  concatMap(res => {
                    for (const r of res) {
                      if (r.error) {
                        return of({ error: r.error }) as ApiResponse<{
                          cededPortfolios: Portfolio[]
                          netPortfolios: Portfolio[]
                        }>
                      }
                    }
                    const cededPortfolioFetches: Array<ApiResponse<Portfolio>> =
                      []
                    const netPortfolioFetches: Array<ApiResponse<Portfolio>> =
                      []

                    Object.keys(nestedLayerCededRecord).forEach(portfolioID => {
                      cededPortfolioFetches.push(
                        this.service.fetchPortfolioWithAggFeederAndRiskVisible(
                          portfolioID
                        )
                      )
                    })
                    Object.keys(nestedLayerNetRecord).forEach(portfolioID => {
                      netPortfolioFetches.push(
                        this.service.fetchPortfolio(portfolioID)
                      )
                    })
                    // Get all Ceded Portfolios and Nested Portfolios from analyzere
                    // to sync the state.
                    return forkJoin([
                      forkJoin(cededPortfolioFetches),
                      forkJoin(netPortfolioFetches),
                    ]).pipe(
                      map(([cededPortfolioRes, netPortfolioRes]) => {
                        for (const r of [
                          ...cededPortfolioRes,
                          ...netPortfolioRes,
                        ]) {
                          if (r.error) {
                            return of({ error: r.error })
                          }
                        }
                        return {
                          data: {
                            cededPortfolios: cededPortfolioRes.map(
                              c => c.data!
                            ),
                            netPortfolios: netPortfolioRes.map(n => n.data!),
                          },
                        }
                      })
                    ) as ApiResponse<{
                      cededPortfolios: Portfolio[]
                      netPortfolios: Portfolio[]
                    }>
                  })
                )
              )
            })
            return forkJoin(sharedLimitUpdates)
          }),
          map(res => {
            const cededPortfolios = []
            const netPortfolios = []
            for (const r of res) {
              if (r.error) {
                return SharedLimitActions.updateSharedLimitFailure({
                  error: r.error,
                })
              } else {
                cededPortfolios.push(...r.data!.cededPortfolios)
                netPortfolios.push(...r.data!.netPortfolios)
              }
            }
            return SharedLimitActions.updateSharedLimitSucccess({
              cededPortfolios,
              netPortfolios,
            })
          })
        )
    )
  })

  /**
   * Updates the Shared Limit Layer properties. Call updateSharedLimit once physicalLayer
   * properties are updated to call success and failure based on updates of backallocated
   * layers.
   */
  updateSharedLimitProperties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedLimitActions.updateSharedLimitProperties),
      withLatestFrom(
        this.store.pipe(select(selectSharedLimits)),
        this.store.pipe(select(selectSharedLimitMembers))
      ),
      concatMap(([sharedLayer, sharedLimits, sharedLimitMembers]) => {
        let clientID = 0
        let yearID = 0
        let slFinal: SharedLimit[] | null
        const slID = sharedLimits.find(
          sl => sl.sl_layer_id === sharedLayer.sharedLimitLayer.id
        )?.id
        const slmIDs: number[] = []
        sharedLimitMembers.forEach(slm => {
          if (slm.sl_id === slID) {
            slmIDs.push(parseInt(slm.id, 10))
          }
        })
        slFinal = sharedLimits.map(response => {
          if (response.id === slID) {
            clientID = response.carrier_id!
            yearID = response.carrier_year_id!
            return {
              ...response,
              sl_name:
                sharedLayer.sharedLimitLayer.physicalLayer.description || '',
            }
          }
          return response
        })
        const body: OmitID<SharedLimit> = {
          sl_layer_id: sharedLayer.sharedLimitLayer.id,
          sl_name: sharedLayer.sharedLimitLayer.physicalLayer.description || '',
          carrier_id: clientID,
          carrier_year_id: yearID,
          analysis_profile_id:
            sharedLayer.sharedLimitLayer.meta_data.analysisProfileID,
        }
        return this.sharedLimitService.updateSL(body, slID!).pipe(
          map(response => {
            return {
              ...response,
              sharedLayer,
              slFinal,
              sharedLimitMembers,
            }
          })
        )
      }),
      concatMapWithInput(action => {
        const update: Update<PhysicalPortfolioLayer> = {
          id: action.sharedLayer.sharedLimitLayer.physicalLayer.id,
          change: {
            ...action.sharedLayer.sharedLimitLayer.physicalLayer,
            aggregate_limit:
              action.sharedLayer.sharedLimitLayer.physicalLayer.aggregateLimit,
            aggregate_attachment:
              action.sharedLayer.sharedLimitLayer.physicalLayer
                .aggregateAttachment,
          },
        }
        return this.service.patchPhysicalPortfolioLayer(update).pipe(
          map(res => {
            if (res.error) {
              return { error: res.error }
            } else {
              return {
                data: {
                  sharedLimitLayers: res.data,
                },
              }
            }
          })
        ) as ApiResponse<{
          sharedLimitLayers: Layer[]
        }>
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          SharedLimitActions.updateSharedLimitFailure({ error })
        )
      ),
      concatMap(data => {
        const action = []
        const layers = [data[1].sharedLayer.sharedLimitLayer]
        action.push(
          SharedLimitActions.updateSLState({
            sharedLimits: data[1].slFinal,
            sharedLimitMembers: data[1].sharedLimitMembers,
          })
        )
        action.push(
          SharedLimitActions.updateSharedLimit({
            sharedLimitLayers: layers,
            deletedNestedBackAllocatedLayers: [],
          })
        )
        return action
      })
    )
  })

  fetchPortfolioViewOnSharedLimitChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SharedLimitActions.addSharedLimitSuccess,
        SharedLimitActions.deleteSharedLimitSuccess,
        SharedLimitActions.updateSharedLimitSucccess
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClient)),
            this.store.pipe(select(selectGrouperPrograms)),
            this.store.pipe(select(selectAllGrouperProgramCededLayerStates)),
            this.store.pipe(select(selectGrouperSlides))
          )
        )
      ),
      map(([action, client, allPrograms, allLayers, slides]) => {
        const cededPortfolioIDs = action.cededPortfolios.map(c => c.id)
        const slideProgramIDs = slides.map(s => s.programID)
        // Filter only structures that are part of shared limit update
        const programs = allPrograms.filter(
          p =>
            cededPortfolioIDs.includes(p.cededPortfolioID) &&
            slideProgramIDs.includes(p.id)
        )
        return fetchViewsForStructuresAndGetAncestors(
          programs,
          allLayers,
          client,
          this.store
        )
      }),
      concatMap(groups => {
        return groups.pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramGroups)),
            this.store.pipe(select(selectCurrentClient)),
            this.store.pipe(select(selectGrouperProgramEntitiesByID)),
            this.store.pipe(select(selectGrouperProgramGroupMembers))
          )
        )
      }),
      map(props => {
        // Flatten groups and filter for only on screen and remove duplicates
        const groupsWithStudyAndClient = getGroupsWithStudyAndClient(...props)
        return createFetchPortfolioViewActionsFromGroups(
          groupsWithStudyAndClient
        )
      }),
      mergeMap(actions => [...actions])
    )
  )

  updateSL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedLimitActions.updateSL),
      mergeMap(({ sharedLimit }) => {
        return this.sharedLimitService
          .updateSL(sharedLimit, sharedLimit.id)
          .pipe(mapToMaybeData())
      }),
      rejectError(error =>
        this.store.dispatch(SharedLimitActions.updateSLFailure({ error }))
      ),
      withLatestFrom(
        this.store.pipe(select(selectSharedLimits)),
        this.store.pipe(select(selectSharedLimitMembers))
      ),
      map(([data, sharedLimits, sharedLimitMembers]) => {
        const slData = [...sharedLimits]
        const slIndex = slData.findIndex(sl => sl.id === data.id)
        if (slIndex !== -1) {
          slData[slIndex] = data
        }
        return SharedLimitActions.updateSLState({
          sharedLimits: slData,
          sharedLimitMembers,
        })
      })
    )
  })
}
