import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import {
  concatMap,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { ProgramService } from '../../../api/program/program.service'
import { AppState } from '../index'
import {
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from '../../../api/util'
import {
  saveTowerPreferences,
  saveTowerPreferenesFailure,
  saveTowerPreferenesSuccess,
  setProgramNameAndDescription,
  setProgramNameAndDescriptionFailure,
  setProgramNameAndDescriptionSuccess,
  updateProgramIndex,
  updateProgramIndexFailure,
  updateProgramIndexSuccess,
  updateTailMetrics,
  updateTailMetricsFailure,
  updateTailMetricsSuccess,
  importBulkLossSets,
  importBulkLossSetsSuccess,
  swapBulkLossSets,
  swapBulkLossSetsSuccess,
  updateProgramIndexes,
  updateProgramIndexesFailure,
  updateProgramIndexesSuccess,
  updateFolderID,
  updateFolderIDFailure,
  updateFolderIDSuccess,
  updateCounts,
  updateCountsFailure,
  updateCountsSuccess,
} from './program.actions'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { forkJoin, of } from 'rxjs'
import {
  Update,
  LogicalPortfolioLayer,
  Metadata,
  PhysicalPortfolioLayer,
  LossSetLayer,
} from '../../../api/analyzere/analyzere.model'
import { selectCurrentStudyPrograms } from './program.selectors'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import { ReturnPeriodRow } from '../../../analysis/model/metrics.model'
import { selectCurrentStructureID } from '../broker/broker.selectors'
import { analyzereConstants } from '@shared/constants/analyzere'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import { isLayerActualTopAndDrop } from 'src/app/analysis/model/layers.util'


@Injectable()
export class ProgramEffects {
  constructor(
    private actions$: Actions,
    private programService: ProgramService,
    private store: Store<AppState>,
    private service: AnalyzreService
  ) {}

  setTowerPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveTowerPreferences),
      switchMap(action => {
        return this.programService.setTowerPreferences(
          action.id,
          action.preferences
        )
      }),
      switchMap(res => {
        const actions = []
        if (res.error) {
          actions.push(saveTowerPreferenesFailure({ error: res.error }))
        } else if (res.data !== undefined) {
          actions.push(saveTowerPreferenesSuccess(res.data))
        }
        return actions
      })
    )
  })

  setProgramNameAndDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProgramNameAndDescription),
      mergeMap(({ structure, name, description }) =>
        this.programService.update(structure.id, {
          ...structure,
          label: name,
          description,
        })
      ),
      rejectError(error =>
        this.store.dispatch(setProgramNameAndDescriptionFailure({ error }))
      ),
      filter(({ label }) => label != null && label.length > 0),
      map(({ id, label, description }) =>
        setProgramNameAndDescriptionSuccess({ id, name: label, description })
      )
    )
  })

  swapBulkLossSets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(swapBulkLossSets),
      mergeMap(
        ({
          structure,
          oldParentGrossPortfolioID,
          newParentGrossPortfolioID,
          analysisProfileID,
          lossSetMapping,
          isLast,
        }) =>
          this.programService
            .update(structure.id, {
              ...structure,
              libRE: undefined,
              parentGrossPortfolioID: newParentGrossPortfolioID,
              analysisID: analysisProfileID,
            })
            .pipe(
              switchMap(() => {
                return forkJoin([
                  this.service.fetchPortfolio(oldParentGrossPortfolioID),
                  this.service.fetchPortfolio(newParentGrossPortfolioID),
                  this.service.fetchPortfolio(structure.cededPortfolioID),
                ]).pipe(
                  map(
                    ([oldLossSetLayers, newLossSetLayers, cededLayers]: [
                      any,
                      any,
                      any,
                    ]) => ({
                      structure,
                      oldParentGrossPortfolioID,
                      oldLossSetLayers,
                      newLossSetLayers,
                      cededLayers,
                      lossSetMapping,
                      isLast,
                    })
                  )
                )
              })
            )
      ),
      concatMap(
        ({
          structure,
          oldParentGrossPortfolioID,
          cededLayers,
          lossSetMapping,
          isLast,
        }) => {
          const grossLayerSet = new Set<string>()
          lossSetMapping.forEach((_: LossSetLayer[], key: LossSetLayer) => {
            grossLayerSet.add(key.id)
          })

          const grossLayers = Array.from(grossLayerSet)

          const netLayers: string[] = [...grossLayers]
          cededLayers.data?.layers.forEach((l: { id: string }) => {
            netLayers.push(l.id)
          })

          const cededLayersTable = cededLayers.data?.layers
          const condensedMap = new Map<string, Set<string>>()
          const condensedMapRisk = new Map<string, Set<string>>()
          let mappedLayerSetRisk: Set<string> | undefined
          cededLayersTable.forEach((layer: any) => {
            condensedMap.set(layer.id, new Set<string>())
            const mappedLayerSet = condensedMap.get(layer.id)
            layer.sources.forEach((source: any) => {
              let isRiskLayer = false
              if (
                source &&
                source.meta_data &&
                (source.meta_data as Metadata).sage_layer_type ===
                  layerIds.noncatRisk
              ) {
                condensedMapRisk.set(source.id, new Set<string>())
                mappedLayerSetRisk = condensedMapRisk.get(source.id)
                isRiskLayer = true
              }
              lossSetMapping.forEach(
                (lossSet: LossSetLayer[], key: LossSetLayer) => {
                  if (isRiskLayer) {
                    if (
                      source.loss_sets &&
                      source.loss_sets[0] &&
                      lossSet.find(
                        (l: LossSetLayer) =>
                          (l.loss_sets[0] as LayerRef).id ===
                          source.loss_sets[0].id
                      )
                    ) {
                      mappedLayerSetRisk?.add((key.loss_sets[0] as LayerRef).id)
                    }
                  } else {
                    if (lossSet.find(l => l.id === source.id)) {
                      mappedLayerSet?.add(key.id)
                    }
                  }
                }
              )
            })
          })
          const updates: Update<LogicalPortfolioLayer>[] = []
          const riskUpdates: Update<PhysicalPortfolioLayer>[] = []
          condensedMap.forEach((set, id) => {
            if (set && set.size > 0) {
              updates.push({
                id,
                change: {
                  sources: Array.from(set).map((l: any) => ({ ref_id: l })),
                },
              })
            }
          })
          condensedMapRisk.forEach((set, id) => {
            if (set && set.size > 0) {
              riskUpdates.push({
                id,
                change: {
                  loss_sets: Array.from(set).map((l: any) => ({ ref_id: l })),
                },
              })
            }
          })
          return this.service
            .updatePortfolioLayers(structure.grossPortfolioID, grossLayers)
            .pipe(
              concatMap(() => {
                if (updates && updates.length > 0) {
                  return this.service.patchLogicalPortfolioLayers(updates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                if (riskUpdates && riskUpdates.length > 0) {
                  return this.service.patchPhysicalPortfolioLayers(riskUpdates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                return this.service.updatePortfolioLayers(
                  structure.netPortfolioID,
                  netLayers
                )
              })
            )
            .pipe(
              map(() => {
                if (isLast) {
                  return swapBulkLossSetsSuccess({
                    id: structure.id,
                    parentGrossPortfolioID: oldParentGrossPortfolioID,
                  })
                } else {
                  return { type: 'No Action' }
                }
              })
            )
        }
      )
    )
  })

  swapBulkLossSetsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(swapBulkLossSetsSuccess, importBulkLossSetsSuccess),
        map(() => {
          location.reload()
        })
      ),
    { dispatch: false }
  )

  importBulkLossSets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importBulkLossSets),
      concatMap(({ structure, parentGrossPortfolioID, analysisProfileID, isLast }) =>
        this.programService
          .update(structure.id, {
            ...structure,
            libRE: undefined,
            parentGrossPortfolioID,
            analysisID: analysisProfileID,
          })
          .pipe(
            switchMap(() => {
              return forkJoin([
                this.service.fetchPortfolio(parentGrossPortfolioID),
                this.service.fetchPortfolio(structure.cededPortfolioID),
              ]).pipe(
                switchMap(([lossSetLayers, cededLayers]: [any, any]) => {
                  const riskVisibleLayerRequests = cededLayers.data.layers
                    .filter(
                      (layer: any) =>
                        layer.meta_data.sage_layer_type === 'noncat_risk'
                    )
                    .map((layer: any) => {
                      if (
                        layer.meta_data.program_name === 'Layer 3 combination'
                      ) {
                        return this.service.fetchLayer(
                          layer.meta_data.riskVisibleLayerID
                        )
                      }
                      return of(null)
                    })
                  return forkJoin(riskVisibleLayerRequests).pipe(
                    map((riskVisibleLayers: any[]) => ({
                      structure,
                      parentGrossPortfolioID,
                      lossSetLayers,
                      cededLayers,
                      riskVisibleLayers: riskVisibleLayers.filter(
                        layer => layer !== null
                      ),
                      isLast
                    })),
                    defaultIfEmpty({
                      structure,
                      parentGrossPortfolioID,
                      lossSetLayers,
                      cededLayers,
                      riskVisibleLayers: [],
                      isLast
                    })
                  )
                })
              )
            })
          )
      ),
      switchMap(
        ({
          structure,
          parentGrossPortfolioID,
          lossSetLayers,
          cededLayers,
          riskVisibleLayers,
          isLast
        }) => {
          const updates: Update<LogicalPortfolioLayer>[] = []
          const layers = lossSetLayers.data.layers
          const newLayers: Layer[] = []
          for (const layer of cededLayers.data.layers) {
          if (isLayerActualTopAndDrop(layer)) {
              const sources = lossSetLayers.data.layers
              const hiddenIDs: string[] = []
              layer.sources.map((s: any) => {
                if ((s.meta_data.sage_layer_type = 'drop')) {
                  hiddenIDs.push(s.id)
                  if (s.meta_data.topID) {
                    hiddenIDs.push(s.meta_data.topID)
                  }
                }
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach((id: string) => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (
              layer.meta_data.sage_layer_type === 'noncat_swing' ||
              layer.meta_data.sage_layer_type === 'noncat_indxl' ||
              isMultiSectionLayer(layer)
            ) {
              const sources = lossSetLayers.data.layers
              const hiddenIDs = [layer.meta_data.visible_layer_id]
              layer.sources.forEach((s: any) => {
                if (
                  layer.meta_data.sage_layer_subtype === 'loss-layer' ||
                  layer.meta_data.sage_layer_subtype === 'adjustment-layer' ||
                  layer.meta_data.sage_layer_subtype === 'premium-layer' ||
                  layer.meta_data.sage_layer_subtype === 'section-layer' ||
                  layer.meta_data.sage_layer_type === 'noncat_indxl'
                ) {
                  hiddenIDs.push(s.id)
                }
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach(id => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (layer.meta_data.sage_layer_type === 'noncat_indxl') {
              const sources = lossSetLayers.data.layers
              const hiddenIDs = [layer.meta_data.visible_layer_id]
              layer.sources.forEach((s: any) => {
                hiddenIDs.push(s.id)
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach(id => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (
              layer.meta_data.sage_layer_type === 'noncat_risk' &&
              layer.meta_data.program_name === 'Layer 3 combination'
            ) {
              const sources = lossSetLayers.data.layers.filter(
                (l: any) =>
                  l.meta_data.loss_type === 'large' ||
                  l.meta_data.loss_type === 'cat'
              )
              const layerToAdd = riskVisibleLayers
                .map((l: any) => l?.data)
                .find(
                  (rvl: any) =>
                    rvl && rvl.id === layer.meta_data.riskVisibleLayerID
                )
              if (layerToAdd) {
                layer.physicalLayer = layerToAdd.sink as PhysicalPortfolioLayer

                sources.forEach((l: any) => {
                  const lossSetLayer = {
                    id: l.id,
                    meta_data: l.meta_data,
                    loss_sets: l.loss_sets,
                  }
                  const hiddenLayerMetaData = {
                    ...layerToAdd.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name:
                      l.meta_data.loss_type === 'large' ? 'Layer 2' : 'Layer 1',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: l.meta_data.loss_type === 'large',
                    isRiskVisible: false,
                    isRiskCatHidden: l.meta_data.loss_type === 'cat',
                    isRiskFinal: false,
                    riskActualLayerID: layer.id,
                  }
                  const hiddenLayerPhysicalMetaData = {
                    ...layerToAdd.sink.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name:
                      l.meta_data.loss_type === 'large' ? 'Layer 2' : 'Layer 1',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: l.meta_data.loss_type === 'large',
                    isRiskVisible: false,
                    isRiskCatHidden: l.meta_data.loss_type === 'cat',
                    isRiskFinal: false,
                    riskActualLayerID: layer.id,
                  }
                  const hiddenLayerPhysicalLayer = {
                    ...layerToAdd.sink,
                    participation: Math.abs(layerToAdd.sink.participation),
                    aggregateAttachment: {
                      value: 0,
                      currency:
                        layerToAdd.sink.aggregateAttachment?.currency || 'USD',
                    },
                    aggregateLimit: {
                      value: analyzereConstants.unlimitedValue,
                      currency:
                        layerToAdd.sink.aggregateLimit?.currency || 'USD',
                    },
                    loss_sets: [
                      {
                        id: l.id,
                        meta_data: l.meta_data,
                        loss_sets: l.loss_sets,
                      },
                    ],
                    meta_data: hiddenLayerPhysicalMetaData,
                  }
                  const hiddenLayer: Layer = {
                    ...layerToAdd,
                    lossSetLayers: [lossSetLayer],
                    meta_data: hiddenLayerMetaData,
                    physicalLayer: hiddenLayerPhysicalLayer,
                    sources: [...sources.map((s: any) => ({ ref_id: s.id }))],
                    sink: { ref_id: hiddenLayerPhysicalLayer.id },
                  }
                  newLayers.push(hiddenLayer)
                })
              }
            } else  {
              updates.push({
                id: layer.id,
                change: {
                  sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                },
              })
            }
          }

          const updatesFinal =
            newLayers.length > 0
              ? this.service.postLossSetLayers(newLayers).pipe(
                  map(newLayerResponses => {
                    if (newLayerResponses.data) {
                      const layer = cededLayers.data.layers[0]
                      updates.push({
                        id: layer.id,
                        change: {
                          sources: [
                            ...newLayerResponses.data.map((l: any) => ({
                              ref_id: l.id,
                            })),
                          ],
                        },
                      })
                      const rvlToPush = riskVisibleLayers
                        .map((l: any) => l?.data)
                        .find(
                          (rvl: any) =>
                            rvl && rvl.id === layer.meta_data.riskActualLayerID
                        )
                      if (rvlToPush) {
                        updates.push({
                          id: riskVisibleLayers
                            .map((l: any) => l?.data)
                            .find(
                              (rvl: any) =>
                                rvl &&
                                rvl.id === layer.meta_data.riskActualLayerID
                            ).id,
                          change: {
                            sources: [
                              ...newLayerResponses.data.map((l: any) => ({
                                ref_id: l.id,
                              })),
                            ],
                          },
                        })
                      }
                    }
                    return updates
                  })
                )
              : of(updates)
          return updatesFinal.pipe(
            switchMap(finalUpdates => {
              const grossLayers: string[] = layers.map(
                (l: { id: string }) => l.id
              )

              return this.service
                .updatePortfolioLayers(structure.grossPortfolioID, grossLayers)
                .pipe(
                  switchMap(() => {
                    return this.service.patchLogicalPortfolioLayers(finalUpdates)
                  }),
                  switchMap(() => {
                    grossLayers.push(
                      ...cededLayers.data.layers.map(
                        (l: { id: string }) => l.id
                      )
                    )
                    return this.service.updatePortfolioLayers(
                      structure.netPortfolioID,
                      grossLayers
                    )
                  }),
                  map(() => {
                    if (isLast) {
                      return importBulkLossSetsSuccess({
                        id: structure.id,
                        parentGrossPortfolioID,
                      })
                    } else {
                      return { type: 'No Action' }
                    }
                  }
                  )
                )
            })
          )
        }
      )
    )
  })

  updateProgramIndex$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProgramIndex),
      mergeMap(({ structure, index }) =>
        this.programService.update(structure.id, {
          ...structure,
          position_index: index,
        })
      ),
      rejectError(error =>
        this.store.dispatch(updateProgramIndexFailure({ error }))
      ),
      map(({ id, position_index }) =>
        // tslint:disable-next-line: no-non-null-assertion
        updateProgramIndexSuccess({ id, index: position_index! })
      )
    )
  })

  updateProgramIndexes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProgramIndexes),
      mergeMap(({ req }) => {
        return this.programService.updatePositionIndexes(req)
      }),
      rejectError(error =>
        this.store.dispatch(updateProgramIndexesFailure({ error }))
      ),
      map(study => {
        return updateProgramIndexesSuccess({ structures: study })
      })
    )
  })

  updateProgramFolderID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFolderID),
      withLatestFrom(this.store.pipe(select(selectCurrentStudyPrograms))),
      mergeMap(([action, structures]) => {
        const { structureId, folderID } = action
        const structure = structures.filter(s => {
          return s.id === structureId
        })[0]
        return this.programService.update(structureId, {
          ...structure,
          folderID,
        })
      }),
      rejectError(error => {
        return this.store.dispatch(updateFolderIDFailure({ error }))
      }),
      map(({ id, folderID }) => {
        return updateFolderIDSuccess({
          structureId: id,
          folderID,
        })
      })
    )
  })

  updateProgramCounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCounts),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStructureID)),
        this.store.pipe(select(selectCurrentStudyPrograms))
      ),
      mergeMap(([action, structureId, structures]) => {
        const { fotCount, quoteCount } = action
        const structure = structures.filter(s => {
          return s.id === structureId
        })[0]

        if (structureId) {
          return this.programService.update(structureId, {
            ...structure,
            fotCount,
            quoteCount,
          })
        } else {
          return of([])
        }
      }),
      rejectError(error => {
        return this.store.dispatch(updateCountsFailure({ error }))
      }),
      map(({ id, fotCount, quoteCount }) => {
        return updateCountsSuccess({
          structureId: id,
          fotCount: fotCount ?? 0,
          quoteCount: quoteCount ?? 0,
        })
      })
    )
  })

  updateTailMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateTailMetrics),
      mergeMapWithInput(({ id, tailMetrics }) => {
        const tailMetricsOptions = {
          ...tailMetrics,
          returnPeriodData: [] as ReturnPeriodRow[],
        }
        return this.programService.putTailMetricsOptions(id, tailMetricsOptions)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(updateTailMetricsFailure({ error }))
      ),
      map(([_, { id, tailMetrics }]) => {
        return updateTailMetricsSuccess({ id, tailMetrics })
      })
    )
  })
}
