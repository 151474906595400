import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BackendService } from '../../api/backend/backend.service'
import { Client } from '../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-client-image-container',
  styleUrls: ['./client-image.container.scss'],
  templateUrl: './client-image.container.html',
})
export class ClientImageContainerComponent implements OnInit, OnDestroy {
  @Input() client: Client
  @Output() clientClick = new EventEmitter<string>()

  imageURI: string

  private destroy$ = new Subject()

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.backendService
      .getCarrierImage(Number.parseInt(this.client.id, 10))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.createImageFromBlob(data)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createImageFromBlob(image: any): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }
}
