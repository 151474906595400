import {
  Input,
  Component,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core'
import { State as AuthState } from '../../core/store/auth/auth.state.facade'
import { Client } from '../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-content',
  styleUrls: [`./display-content.component.scss`],
  templateUrl: './display-content.component.html',
})
export class DisplayContentComponent implements OnChanges {
  @Input() clientID: string | null
  @Input() studyID: string | null
  @Input() year: string | null
  @Input() authState: AuthState
  @Input() carriers: Client[]

  files: string[] = []
  multipleCarriers = false

  ngOnChanges(): void {
    this.multipleCarriers = this.carriers.length > 1 ? true : false
    if (this.clientID) {
      this.carriers.forEach((carrier: Client) => {
        if (carrier.id === this.clientID) {
          if (carrier.executive_summary) {
            this.files = carrier.executive_summary.split('|')
          } else {
            this.files = []
          }
        }
      })
    }
  }

  getWidth(): number | undefined {
    const matTab = document.getElementsByClassName('mat-tab-label-container')[0]
    const app = document.getElementsByTagName('app-theme')[0]
    if (matTab && app) {
      return app.clientWidth - matTab.clientWidth
    }
  }

  getHeight(): number | undefined {
    const header = document.getElementsByTagName('app-display-header')[0]
    const selector = document.getElementsByClassName('selector')[0]
    const app = document.getElementsByTagName('app-theme')[0]
    if (app && selector && header) {
      return app.clientHeight - (selector.clientHeight + header.clientHeight)
    } else if (app && header) {
      return app.clientHeight - header.clientHeight
    }
  }

  isFilePDF(file: string): boolean {
    this.getWidth()
    return file.split('.')[1].toLowerCase().trim() === 'pdf'
  }

  isFileMP4(file: string): boolean {
    this.getWidth()
    return file.split('.')[1].toLowerCase().trim() === 'mp4'
  }
}
