import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  DeleteGroupDialogComponent,
  DeleteGroupDialogComponentData,
} from '../components/delete-group-dialog/delete-group-dialog.component'

@Injectable()
export class DeleteGroupDialogService {
  constructor(public dialog: MatDialog) {}

  open(allowDelete: boolean, isChild: boolean, hasSLI: boolean, label: string) {
    const data: DeleteGroupDialogComponentData = {
      allowDelete,
      isChild,
      hasSLI,
      label,
    }
    return this.dialog.open(DeleteGroupDialogComponent, {
      width: '30vw',
      data,
    })
  }
}
