import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { QuoteReinsurerService } from '../../../../app/api/quote-reinsurer/quote-reinsurer.service'
import { map, concatMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '../../../core/store'
import {
  saveQuoteSection,
  saveQuoteSectionFailure,
  saveQuoteSectionSuccess,
} from './section.actions'
import { rejectError } from '../../../api/util'

@Injectable()
export class SectionsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: QuoteReinsurerService,
  ) {}

  saveSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveQuoteSection),
      concatMap(action => {
        return this.service.putRiskSection(action.section)
      }),
      rejectError(error =>
        this.store.dispatch(saveQuoteSectionFailure({ error }))
      ),
      map(section => saveQuoteSectionSuccess({ section }))
    )
  })
}
