import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { ProgramGroup, ProgramGroupMember } from './program-group.model'
import { Update } from '@ngrx/entity'
import { SavedUpdate } from 'src/app/api/program-group/program-group.service'

export interface IDChange {
  prev: string
  next: string
}

export interface SavedChanges<T> {
  create: T[]
  update: Update<T>[]
  remove: string[]
  idChanges: IDChange[]
}

export interface GrouperSavedChanges {
  clientID: string
  groups: SavedChanges<ProgramGroup>
  members: SavedChanges<ProgramGroupMember>
}

export const saveGrouper = createAction('[Grouper] Save')

export const saveGrouperFailure = createAction(
  '[Grouper] Save Failure',
  props<{ error: ApplicationError }>()
)
export const saveGrouperSuccess = createAction(
  '[Grouper] Save Success',
  props<GrouperSavedChanges>()
)

export const reconcileAncestorGroups = createAction(
  '[Grouper] Reconcile Ancestor Groups',
  props<{ ancestorGroups: ProgramGroup[] }>()
)

export const reconcileAncestorGroupsSuccess = createAction(
  '[Grouper] Reconcile Ancestor Groups Success',
  props<{ savedGroups: SavedUpdate<ProgramGroup>[] }>()
)

export const reconcileAncestorGroupsFailure = createAction(
  '[Grouper] Reconcile Ancestor Groups Failure',
  props<{ error: ApplicationError }>()
)

export const resetGrouper = createAction('[Grouper] Reset')

export const groupCurrency = createAction(
  '[Group] Group Currency',
  props<{ groupCurrency: string | null }>()
)
