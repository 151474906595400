import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  PortfolioMetrics,
  PortfolioViewMetricsPayload,
} from '../../model/portfolio-metrics.model'
import {
  PortfolioSetID,
  PortfolioViewID,
} from '../../model/portfolio-set.model'

export const fetchPortfolioViewMetrics = createAction(
  '[Portfolio View Metrics] Fetch',
  props<PortfolioSetID & PortfolioViewMetricsPayload>()
)

export const updateAndFetch = createAction(
  `[Portfolio View Metrics] Update and Fetch`,
  props<
    Partial<PortfolioSetID> & {
      change: Partial<PortfolioViewMetricsPayload>
    }
  >()
)

export const updateAndFetchExpectedSD = createAction(
  `[Portfolio View Metrics Expected SD] Update and Fetch`,
  props<
    PortfolioSetID &
      PortfolioViewID & {
        grossMetricsExpense: number
        cededMetricsExpense: number
        netMetricsExpense: number
      }
  >()
)

export const setExpectedSD = createAction(
  `[Portfolio View Metrics Set] Update and Fetch`,
  props<
    PortfolioSetID & {
      expected: number
      stdDev: number
    }
  >()
)

export const fetchPortfolioViewMetricsSuccess = createAction(
  '[Portfolio View Metrics] Fetch Success',
  props<
    PortfolioSetID &
      PortfolioViewID & {
        metrics: PortfolioMetrics
        grossMetricsExpense: number
        cededMetricsExpense: number
        netMetricsExpense: number
      }
  >()
)

export const fetchPortfolioViewMetricsFailure = createAction(
  '[Portfolio View Metrics] Fetch Failure',
  props<PortfolioSetID & { error: ApplicationError }>()
)

export const updateRSS = createAction(
  '[Portfolio View Metrics] Update RSS',
  props<PortfolioSetID & { rss: number | null }>()
)
