import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import {
  Change,
  OptimizationCandidateLayer,
  getLayersTableColumnDef,
  OptimizationRangesTypes,
} from '../optimization.model'
import { Selections, SelectionsChangeEvent } from '@shared/util/selections'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { LossSetGroup } from '../../model/loss-set-layers.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-optimization-candidates-results',
  styleUrls: ['./optimization-candidates-results.component.scss'],
  templateUrl: './optimization-candidates-results.component.html',
})
export class OptimizationCandidatesResultsComponent implements OnChanges {
  selections = new Selections()

  @Input() rangesTypes: OptimizationRangesTypes[]
  @Input() candidateLayers: OptimizationCandidateLayer[]
  @Input() candidateLayersLoading: boolean
  @Input() error: string | null
  @Input() lossSetGroups: LossSetGroup[]

  @Output() selectedChange = new EventEmitter<Selections>()
  @Output() valueChange = new EventEmitter<Change<OptimizationCandidateLayer>[]>()

  columnDefs: SortTableColumnDef<OptimizationCandidateLayer>[]

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rangesTypes && !this.columnDefs && this.lossSetGroups) {
      this.columnDefs = getLayersTableColumnDef(
        this.rangesTypes,
        this.lossSetGroups
      )
    }
  }

  onSelectedChange($event: SelectionsChangeEvent) {
    const { selections } = $event
    this.selections = selections
    this.selectedChange.emit(selections)
    if (selections.someSelected === false) {
      const changes = this.candidateLayers.map(r => ({
        id: r.id,
        changes: { include: false },
      }))
      this.valueChange.emit(changes)
    } else {
      const dictionary = selections.dictionary
      const changes: Change<OptimizationCandidateLayer>[] = []
      Object.keys(dictionary).forEach(key => {
        changes.push({
          id: key,
          changes: { include: dictionary[key] },
        })
      })
      this.valueChange.emit(changes)
    }
  }

  onValueChange(value: SortTableValueChangeEvent<OptimizationCandidateLayer>) {
    const layerID = value.id
    let columnValue
    if (value.column.id === 'group') {
      if (value.value === null) {
        columnValue = ''
      } else {
        columnValue = String(value.value)
      }
    } else {
      columnValue = value.value
    }
    let isTp = false
    if (value.column.id === 'technicalPremium') {
      const updateLayer = this.candidateLayers.find(cl => cl.id === value.id)
      if (updateLayer && updateLayer.layerType === 'xl') {
        this.valueChange.emit([
          {
            id: layerID,
            changes: {
              [value.column.id]: columnValue,
              ['rolPercentage']: columnValue / updateLayer.occurrenceLimit,
              ['premium']: columnValue,
            },
          },
        ])
        isTp = true
      } else if (updateLayer && updateLayer.layerType === 'qs') {
        this.valueChange.emit([
          {
            id: layerID,
            changes: {
              [value.column.id]: columnValue,
              ['cedingCommission']: columnValue / updateLayer.subjectPremiumQS,
              ['premium']: columnValue,
            },
          },
        ])
        isTp = true
      }
    }
    if (!isTp) {
      this.valueChange.emit([
        { id: layerID, changes: { [value.column.id]: columnValue } },
      ])
    }
  }
}
