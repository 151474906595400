import { Injectable } from '@angular/core'
import {
  clone,
  groupBy,
  keys,
  lensProp,
  partition,
  prop,
  sum,
  view,
} from 'ramda'
import { forkJoin, Observable, of } from 'rxjs'
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { LayerView } from '../../analysis/model/layer-view'
import { LayerMetrics } from '../../analysis/model/layers-metrics.model'
import { Layer } from '../../analysis/model/layers.model'
import { isLayerAggFeeder } from '../../analysis/model/layers.util'
import {
  LossSetGroup,
  LossSetLayer,
} from '../../analysis/model/loss-set-layers.model'
import {
  Change,
  isAgg,
  isAggLayerOptimization,
  matchTemplateLayer,
  OptimizationCandidateLayer,
  OptimizationCandidateResult,
  OptimizationCandidateResultEntity,
  OptimizationInitialMetrics,
  OptimizationInitialMetricsResponse,
} from '../../analysis/optimization/optimization.model'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { calculatePortfolioViewDetailMetrics } from '../../analysis/store/metrics/calculations'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import {
  AllPortfolioDetailMetrics,
  LayerViewResponse,
  LogicalPortfolioLayer,
  Portfolio,
  Reinstatement,
} from '../analyzere/analyzere.model'
import { AnalyzreService } from '../analyzere/analyzre.service'
import { ApiResponse, MaybeData, MaybeError } from '../model/api.model'
import { executeSequentiallyInGroup } from '../util'
import {
  PricingCurveLayerString,
  SavedPricingCurveEntry,
  TechnicalFactors,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  LayerEntry,
  LayerTypeDefaultEntry,
} from 'src/app/analysis/technical-premium/technical-premium.model'
import {
  calculateTechnicalPremium,
  doesLayerUseLayerTypeDefault,
  getPricingCurvesForLayer,
} from 'src/app/analysis/technical-premium/technical-premium.utils'

export interface ExtraOptions {
  isAgg?: boolean
  aggFeeder?: Layer
}

const skipPhysicalMetadata = [
  'rolType',
  'rateOnLineSubject',
  'cascadeLowerLayerID',
]

@Injectable({
  providedIn: 'root',
})
export class OptimizationService {
  constructor(private service: AnalyzreService) {}

  getInitialMetrics(
    layerViewID: string,
    layerID: string,
    cededYear: number,
    weight: number
  ): ApiResponse<OptimizationInitialMetrics> {
    const baseUrl = `${environment.api.base}${environment.api.metrics.layersView.base}/${layerViewID}`
    return this.service
      .forkJoinUrlMap<OptimizationInitialMetricsResponse>(
        baseUrl,
        'Fetch Initial Candidate Layer Metrics',
        {
          expectedCededLossBase: `${environment.api.metrics.layersView.expectedCededLossBase}`,
          depositPremium: `${environment.api.metrics.layersView.depositPremium}`,
          expectedCededLossBaseNoParticipation: `${environment.api.metrics.layersView.expectedCededLossBaseNoParticipation}`,
          expectedCededPremiumBase: `${environment.api.metrics.layersView.expectedCededPremiumBase}`,
          entryProbability: `${environment.api.metrics.layersView.probability}`
            .replace('{param}', '0.01')
            .toString(),
          cededYearValue:
            `${environment.api.metrics.layersView.cededYearValue}`.replace(
              '{yearParam}',
              (1 / cededYear).toString()
            ),
        }
      )
      .pipe(
        map(results => {
          if (results.error) {
            return { error: results.error }
          } else {
            if (results.data) {
              let expectedCededLoss = results.data.expectedCededLossBase.mean
              expectedCededLoss = expectedCededLoss
                ? Math.abs(expectedCededLoss)
                : 0
              const depositPremium = -results.data.depositPremium.mean
              const purePremiumForTP = Math.abs(
                results.data.expectedCededLossBaseNoParticipation
                  ? results.data.expectedCededLossBaseNoParticipation.mean
                  : 0
              )
              const expectedCededPremium =
                results.data.expectedCededPremiumBase.mean - depositPremium
              const expectedCededLossRatio =
                expectedCededLoss / expectedCededPremium
              const entryProbability = results.data.entryProbability.probability
              const cededYearVar = results.data.cededYearValue?.min
                ? results.data.cededYearValue.min
                : 0
              const cededYearTVar = results.data.cededYearValue?.mean
                ? results.data.cededYearValue?.mean
                : 0
              const expectedCededLossDispForTP =
                results.data.expectedCededLossBaseNoParticipation.mean
              const cededLossCVForTP =
                Math.sqrt(
                  results.data.expectedCededLossBaseNoParticipation.variance
                ) / expectedCededLossDispForTP
              const standardDeviationExpectedLossForTP = Math.sqrt(
                results.data.expectedCededLossBaseNoParticipation.variance
              )
              return {
                data: {
                  expectedCededLoss,
                  standardDeviationExpectedLossForTP,
                  expectedCededPremium: Math.abs(expectedCededPremium),
                  depositPremium: Math.abs(depositPremium),
                  purePremiumForTP,
                  expectedCededLossRatio,
                  entryProbability,
                  cededYearVar,
                  cededYearTVar,
                  cededLossCVForTP,
                  metricWeight: weight,
                  layerViewID,
                  layerID,
                },
              }
            } else {
              return {}
            }
          }
        })
      )
  }

  getInitialMetricsForCandidates(
    candidateLayers: OptimizationCandidateLayer[],
    cededLayers: LayerState[],
    reinsurers: Reinsurer[],
    lossSetLayers: LossSetLayer[],
    analysisProfileID: string,
    lossSetGroups: LossSetGroup[],
    layerTypeDefaults: LayerTypeDefaultEntry,
    savedPricingCurves: SavedPricingCurveEntry[]
  ): ApiResponse<OptimizationCandidateLayer[]> {
    return this.createLayerViews(
      candidateLayers.map(c => c.id),
      candidateLayers,
      cededLayers,
      reinsurers,
      lossSetLayers,
      analysisProfileID,
      lossSetGroups
    ).pipe(
      concatMap(response => {
        if (response.error) {
          return of({ error: response.error }) as Observable<
            MaybeData<OptimizationInitialMetrics[]> &
              MaybeError & {
                results: OptimizationCandidateLayer[]
                cededLayers: LayerState[]
              }
          >
        } else if (response.data) {
          // Calculate initial metrics for each curve in the layer type default
          const actions = response.data.flatMap(v => {
            const defaultEntry =
              layerTypeDefaults[v.layer.meta_data.sage_layer_type]
            // Check if the default entry exists and that it isn't the default entry
            if (defaultEntry && !defaultEntry.pricingCurveIds.includes(-1)) {
              const curves = savedPricingCurves.filter(curve =>
                defaultEntry.pricingCurveIds.includes(curve.id)
              )
              return curves.map(curve => {
                const volatilityMetric = curve.techFactors.volatility_metric
                const parts = volatilityMetric.split(' ')
                const cededYear = !isNaN(Number(parts[1]))
                  ? Number(parts[1])
                  : 250
                const entry = defaultEntry.pricingCurves.find(
                  c => c.id === curve.id
                )
                return this.getInitialMetrics(
                  v.id,
                  v.layerID,
                  cededYear,
                  entry.percentage
                )
              })
            } else {
              return this.getInitialMetrics(v.id, v.layerID, 250, 1)
            }
          })

          return executeSequentiallyInGroup(actions, 250).pipe(
            map(r => ({
              ...r,
              results: candidateLayers,
              cededLayers,
            }))
          )
        }
      }),
      map(results => {
        if (results.error) {
          return { error: results.error }
        } else {
          const initialMetrics = results.data as OptimizationInitialMetrics[]
          const values: OptimizationCandidateLayer[] = []
          const groupedInitialMetrics = groupBy(prop('layerID'), initialMetrics)
          for (const initialMetricGroup of Object.values(
            groupedInitialMetrics
          )) {
            const sumMetric = (propPath: keyof OptimizationInitialMetrics) => {
              return sum(
                initialMetricGroup.map(metrics => {
                  const val = Number(view(lensProp(propPath), metrics))
                  return !isNaN(val) ? val * metrics.metricWeight : 0
                })
              )
            }

            const expectedCededLoss = sumMetric('expectedCededLoss')
            const standardDeviationExpectedLossForTP = sumMetric(
              'standardDeviationExpectedLossForTP'
            )
            const cededLossCVForTP = sumMetric('cededLossCVForTP')
            const cededYearTVar = sumMetric('cededYearTVar')
            const cededYearVar = sumMetric('cededYearVar')
            const entryProbability = sumMetric('entryProbability')
            const expectedCededPremium = sumMetric('expectedCededPremium')
            const depositPremium = sumMetric('depositPremium')
            const purePremiumForTP = sumMetric('purePremiumForTP')
            const expectedCededLossRatio = sumMetric('expectedCededLossRatio')

            const layerID = initialMetricGroup[0].layerID
            const candidateLayer = results.results.find(r => r.id === layerID)
            const cededLayer = results.cededLayers.find(c =>
              layerID.includes(c.layer.id)
            )
            if (candidateLayer && cededLayer) {
              candidateLayer.purePremium = expectedCededLoss
              candidateLayer.standardDeviationExpectedLossForTP =
                standardDeviationExpectedLossForTP
              candidateLayer.cededLossCVForTP = cededLossCVForTP
              candidateLayer.cededYearTVar = cededYearTVar
              candidateLayer.cededYearVar = cededYearVar
              candidateLayer.entryProbability = entryProbability
              candidateLayer.expectedCededPremium = expectedCededPremium
              candidateLayer.depositPremium = depositPremium
              candidateLayer.purePremiumForTP = purePremiumForTP
              candidateLayer.expectedCededLossRatio = expectedCededLossRatio
              // Calculate TP based on new metrics, Ceding commission and ROL for XL. Set Premium accordingly.
              const entries = doesLayerUseLayerTypeDefault(cededLayer.layer)
                ? layerTypeDefaults[cededLayer.layer.meta_data.sage_layer_type]
                    .pricingCurves
                : getPricingCurvesForLayer(cededLayer.layer)
              const techPremium = calculateTechnicalPremium(
                candidateLayer,
                cededLayer.layer.physicalLayer.reinstatements,
                entries,
                savedPricingCurves,
                cededLayer.layer
              )
              if (candidateLayer.layerType === 'qs') {
                if (candidateLayer.lossSetGroupID) {
                  const lossGroup = lossSetGroups.find(
                    l => l.id === candidateLayer.lossSetGroupID
                  )
                  candidateLayer.subjectPremiumQS = lossGroup
                    ? this.getSubjectPremiumGroup(lossGroup)
                    : candidateLayer.subjectPremiumQS
                }
                candidateLayer.technicalPremium =
                  candidateLayer.cessionPercentage *
                    candidateLayer.subjectPremiumQS || 0
                candidateLayer.premium = candidateLayer.subjectPremiumQS || 0
                candidateLayer.cedingCommission = techPremium || 0
              } else if (candidateLayer.layerType === 'xl') {
                candidateLayer.technicalPremium = techPremium || 0
                candidateLayer.premium = techPremium || 0
                candidateLayer.rolPercentage =
                  techPremium / candidateLayer.occurrenceLimit || 0
              } else {
                candidateLayer.technicalPremium = techPremium
              }
              values.push(candidateLayer)
            }
          }
          return { data: values }
        }
      })
    )
  }

  getCandidateLayersMetrics(
    candidateLayers: OptimizationCandidateLayer[],
    cededLayers: LayerState[],
    reinsurers: Reinsurer[],
    lossSetLayers: LossSetLayer[],
    analysisProfileID: string,
    lossSetGroups: LossSetGroup[],
    grossPortfolioViewID: string
  ): ApiResponse<Change<OptimizationCandidateLayer>[]> {
    return this.createLayerViews(
      candidateLayers.map(c => c.id),
      candidateLayers,
      cededLayers,
      reinsurers,
      lossSetLayers,
      analysisProfileID,
      lossSetGroups
    ).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const actions: Array<
            Observable<
              MaybeError &
                MaybeData<{
                  metrics: LayerMetrics
                  layerID: string
                  currency?: string
                }>
            >
          > = []
          const layers: LayerState[] = response.layers.map(l => ({
            layer: l,
            hash: '',
            dirty: false,
            new: false,
            deleted: false,
          }))
          // tslint:disable-next-line: no-non-null-assertion
          const data = response.data!
          data.forEach(r => {
            const layerState = layers.find(
              l => l.layer.id === r.layerID
            ) as LayerState
            actions.push(
              this.service
                .getLayersViewViewMetricsAndCalculate(
                  r.id,
                  grossPortfolioViewID,
                  { [r.layerID]: r.id },
                  {
                    [layerState.layer.id]: layerState,
                  },
                  [layerState],
                  false
                )
                .pipe(
                  map(res => {
                    if (res.error) {
                      return { error: res.error }
                    } else {
                      const currRes = response.results.find(
                        c => c.id === r.layerID
                      )
                      return {
                        data: {
                          metrics: res.data as LayerMetrics,
                          layerID: r.layerID,
                          currency: currRes?.currency,
                        },
                      }
                    }
                  })
                )
            )
          })
          return executeSequentiallyInGroup(actions, 50).pipe(
            map(results => {
              const changes: Change<OptimizationCandidateLayer>[] = []
              if (results.error) {
                return { error: results.error }
              }
              // tslint:disable-next-line: no-non-null-assertion
              const metricsAndLayerIDArray = results.data!
              let i = 0
              for (const metricsAndLayerID of metricsAndLayerIDArray) {
                const layerID = metricsAndLayerID.layerID
                const view = response.layerViews.find(
                  v => v.values.id === layerID
                ) as LayerView
                view.metrics = metricsAndLayerID.metrics
                const refreshedView = new LayerView(
                  view.layers as LayerState[],
                  view.layer,
                  {
                    metrics: metricsAndLayerID.metrics,
                    reinsurers: view.reinsurers,
                    lossSets: view.lossSets,
                  }
                )
                refreshedView.values.id = layerID
                const newValues = refreshedView.values
                if (results.data) {
                  newValues.currency = results.data[i].currency
                  i += 1
                }
                if (isAgg(newValues)) {
                  newValues.occurrenceAttachment = 0
                  newValues.occurrenceLimit = analyzereConstants.unlimitedValue
                  newValues.franchiseDeductible = 0
                }
                changes.push({
                  id: layerID,
                  changes: {
                    ...newValues,
                    layerViewID: metricsAndLayerID.metrics.id,
                  },
                })
              }
              return { data: changes }
            })
          )
        }
      })
    )
  }

  getCandidateResultPortfolioMetrics(
    id: string,
    candidateResultsByID: Record<string, OptimizationCandidateResult>,
    netPortfolioLayerIDs: string[],
    cededLayers: LayerState[],
    analysisProfileID: string,
    grossPortfolioViewID: string
  ): ApiResponse<Change<OptimizationCandidateResultEntity>> {
    const cededLayerIDs = cededLayers.map(l => l.layer.id)
    const filteredNetPortfolioIDs = netPortfolioLayerIDs.filter(
      n => !cededLayerIDs.includes(n)
    )
    const newLayerViewIDs = candidateResultsByID[id]?.candidateLayers.map(
      c => c.layerViewID as string
    )
    const curr = candidateResultsByID[id].candidateLayers[0].currency || ''
    return this.service
      .postLayersViews(filteredNetPortfolioIDs, analysisProfileID as string, [
        curr,
      ])
      .pipe(
        map(response => ({
          ...response,
          newLayerViewIDs,
          analysisProfileID,
          grossPortfolioViewID,
          id,
        })),
        switchMap(response => {
          if (response.error) {
            return of({ error: response.error }) as Observable<
              MaybeData<string[]> &
                MaybeError & {
                  grossPortfolioViewID: string | null
                  id: string
                }
            >
          } else {
            const layerViewIDs = [
              ...(response.newLayerViewIDs as string[]),
              // tslint:disable-next-line: no-non-null-assertion
              ...response.data!.map(l => l.id),
            ]
            return forkJoin([
              this.service.postPortfolioViewAdhoc(
                response.newLayerViewIDs as string[],
                response.analysisProfileID as string
              ),
              this.service.postPortfolioViewAdhoc(
                layerViewIDs,
                response.analysisProfileID as string
              ),
            ]).pipe(
              map(responses => {
                for (const r of responses) {
                  if (r.error) {
                    return { error: r.error }
                  }
                }
                return { data: responses.map(r => r.data as string) }
              }),
              map(res => ({
                ...res,
                grossPortfolioViewID: response.grossPortfolioViewID,
                id: response.id,
              }))
            )
          }
        }),
        switchMap(response => {
          if (response.error) {
            return of({ error: response.error }) as Observable<
              MaybeData<AllPortfolioDetailMetrics> &
                MaybeError & {
                  id: string
                  cededPortfolioViewID: string
                  netPortfolioViewID: string
                  grossPortfolioViewID: string
                }
            >
          } else {
            // tslint:disable-next-line: no-non-null-assertion
            const cededPortfolioViewID = response.data![0]
            // tslint:disable-next-line: no-non-null-assertion
            const netPortfolioViewID = response.data![1]
            return this.service
              .getAllPortfolioDetailViewMetrics(
                cededPortfolioViewID,
                response.grossPortfolioViewID as string,
                netPortfolioViewID
              )
              .pipe(
                map(r => ({
                  ...r,
                  id: response.id,
                  cededPortfolioViewID,
                  netPortfolioViewID,
                  grossPortfolioViewID: response.grossPortfolioViewID,
                }))
              )
          }
        }),
        map(response => {
          if (response.error) {
            return { error: response.error }
          } else {
            const metrics = response.data as AllPortfolioDetailMetrics
            const netCalculatedMetrics = calculatePortfolioViewDetailMetrics(
              metrics,
              'Net'
            )
            const cededCalculatedMetrics = calculatePortfolioViewDetailMetrics(
              metrics,
              'Ceded'
            )
            return {
              data: {
                id: response.id,
                changes: {
                  grossPortfolioViewID: response.grossPortfolioViewID as string,
                  netPortfolioViewID: response.netPortfolioViewID,
                  cededPortfolioViewID: response.cededPortfolioViewID,
                  expectedNetLoss: netCalculatedMetrics.expectedLoss,
                  portfolioExpectedCededPremium: cededCalculatedMetrics.premium,
                },
              },
            }
          }
        })
      )
  }

  postMultiLayerViewAdhoc(
    layers: Layer[],
    results: OptimizationCandidateLayer[],
    referenceIDs: string[],
    analysisProfileID: string,
    lossSetGroups: LossSetGroup[],
    options?: ExtraOptions
  ): ApiResponse<Array<LayerViewResponse & { layerID: string }>> {
    if (options?.isAgg && options.aggFeeder) {
      const feederTemplate = options.aggFeeder
      const resultsTuplets = partition(r => r.group === '', results)
      const groups = groupBy(r => r.group, resultsTuplets[1])
      const actions: ApiResponse<LogicalPortfolioLayer>[] = []
      resultsTuplets[0].forEach(l => {
        const feeder = this.updateAggFeeder(feederTemplate, l, lossSetGroups)
        actions.push(this.service.createFullLayer(feeder))
      })
      keys(groups).forEach((k: string) => {
        const feeder = this.updateAggFeeder(
          feederTemplate,
          groups[k][0],
          lossSetGroups
        )
        actions.push(this.service.createFullLayer(feeder))
      })
      return executeSequentiallyInGroup(actions, 250).pipe(
        switchMap(rs => {
          if (rs.error) {
            return of({ error: rs.error })
          }
          const feederLayerIDs: string[] = rs.data?.map(r => r.id) as string[]
          const newLayersWithAggFeeder: Layer[] = []
          resultsTuplets[0].forEach((result, i) => {
            const layer = layers.find(l => l.id === result.id) as Layer
            newLayersWithAggFeeder.push({
              ...layer,
              layerRefs: [feederLayerIDs[i]],
            })
          })
          keys(groups).forEach((k: string, i) => {
            const groupCandidateLayers = groups[k]
            groupCandidateLayers.forEach(g => {
              const layer = layers.find(l => l.id === g.id) as Layer
              newLayersWithAggFeeder.push({
                ...layer,
                layerRefs: [feederLayerIDs[i + resultsTuplets[0].length]],
              })
            })
          })
          return this.service.postMultiLayerViewAdhoc(
            newLayersWithAggFeeder,
            referenceIDs,
            analysisProfileID
          )
        })
      )
    } else {
      return this.service.postMultiLayerViewAdhoc(
        layers.map(this.mapWithLossSetGroups(results, lossSetGroups)),
        referenceIDs,
        analysisProfileID
      )
    }
  }

  updateWithCandidateLayers(
    cededPortfolioID: string,
    netPortfolioID: string,
    removeLayerIDs: string[],
    newLayers: Layer[],
    result: OptimizationCandidateResult,
    lossSetGroups: LossSetGroup[],
    options?: ExtraOptions
  ): Observable<MaybeError> {
    let feederResponse: ApiResponse<LogicalPortfolioLayer | null> = of({
      data: null,
    })
    newLayers = clone(newLayers)
    newLayers.forEach(l => {
      l.physicalLayer.meta_data.isLimitUnlimited =
        l.physicalLayer.limit.value >= analyzereConstants.unlimitedValue
      l.physicalLayer.meta_data.isAggregateUnlimited =
        l.physicalLayer.limit.value >= analyzereConstants.unlimitedValue
    })
    if (options?.aggFeeder && options.isAgg) {
      const feederTemplate = options.aggFeeder
      const feeder = this.updateAggFeeder(
        feederTemplate,
        result.candidateLayers[0],
        lossSetGroups
      )
      feederResponse = this.service.createFullLayer(feeder)
    } else {
      newLayers.forEach(n => {
        const candidateLayer = result.candidateLayers.find(cl => cl.id === n.id)
        if (candidateLayer?.lossSetGroupID) {
          const lossSetGroup = lossSetGroups.find(
            l => l.id === candidateLayer.lossSetGroupID
          )
          if (lossSetGroup) {
            n.lossSetLayers = lossSetGroup.lossSetLayers
          }
        }
      })
    }
    return feederResponse.pipe(
      map(response => {
        if (response.error) {
          return { error: response.error }
        } else {
          const feederLayer = response.data
          if (feederLayer) {
            const newLayersWithFeeder = clone(newLayers)
            newLayersWithFeeder.forEach(layer => {
              layer.layerRefs = [feederLayer.id]
            })
            return { data: newLayersWithFeeder }
          } else {
            return { data: newLayers }
          }
        }
      }),
      switchMap(re => {
        if (re.error) {
          return of({ error: re.error })
        } else {
          return this.service.createFullLayers(re.data).pipe(
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error }) as Observable<
                  MaybeData<Portfolio> &
                    MaybeError & { createdLayers: LogicalPortfolioLayer[] }
                >
              } else {
                return this.service.fetchPortfolio(netPortfolioID).pipe(
                  map(r => ({
                    ...r,
                    createdLayers: response.data as LogicalPortfolioLayer[],
                  }))
                )
              }
            }),
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error }) as Observable<
                  MaybeData<Portfolio> &
                    MaybeError & { createdLayers: LogicalPortfolioLayer[] }
                >
              } else {
                const netPortfolio = response.data as Portfolio
                const createdLayers = response.createdLayers
                const layerIDs = (
                  netPortfolio.layers as LogicalPortfolioLayer[]
                ).map(l => l.id)
                const newLayerIDs = [
                  ...layerIDs.filter(l => !removeLayerIDs.includes(l)),
                  ...createdLayers.map(l => l.id),
                ]
                return this.service
                  .updatePortfolioLayers(netPortfolioID, newLayerIDs)
                  .pipe(
                    map(r => ({
                      ...r,
                      createdLayers:
                        response.createdLayers as LogicalPortfolioLayer[],
                    }))
                  )
              }
            }),
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error })
              } else {
                return this.service.updatePortfolioLayers(
                  cededPortfolioID,
                  response.createdLayers.map(l => l.id)
                )
              }
            })
          )
        }
      })
    )
  }

  private updateAggFeeder(
    aggFeeder: Layer,
    candidateLayer: OptimizationCandidateLayer,
    lossSetGroups: LossSetGroup[]
  ) {
    let lossSetLayers = aggFeeder.lossSetLayers
    if (lossSetGroups.length > 0 && candidateLayer.lossSetGroupID) {
      lossSetLayers =
        lossSetGroups.find(l => l.id === candidateLayer.lossSetGroupID)
          ?.lossSetLayers || lossSetLayers
    }
    const feeder = clone(aggFeeder)
    feeder.physicalLayer.limit.value = candidateLayer.feederOccurrenceLimit ?? 0
    feeder.physicalLayer.attachment.value =
      candidateLayer.feederOccurrenceAttachment ?? 0
    feeder.physicalLayer.franchise.value =
      candidateLayer.feederFranchiseDeductible ?? 0
    feeder.physicalLayer.participation = candidateLayer.feederParticipation ?? 0
    feeder.lossSetLayers = lossSetLayers
    return feeder
  }

  private mapWithLossSetGroups(
    results: OptimizationCandidateLayer[],
    lossSetGroups: LossSetGroup[]
  ) {
    return (layer: Layer) => {
      const result = results.find(r => r.id === layer.id)
      if (result && result.lossSetGroupID && result.lossSetGroupID.length > 0) {
        const lossSetGroup = lossSetGroups.find(
          g => g.id === result.lossSetGroupID
        )
        if (lossSetGroup) {
          return { ...layer, lossSetLayers: lossSetGroup.lossSetLayers }
        } else {
          return layer
        }
      } else {
        return layer
      }
    }
  }
  createLayerViews(
    ids: string[],
    results: OptimizationCandidateLayer[],
    cededLayers: LayerState[],
    reinsurers: Reinsurer[],
    lossSetLayers: LossSetLayer[],
    analysisProfileID: string,
    lossSetGroups: LossSetGroup[]
  ): Observable<
    MaybeData<Array<LayerViewResponse & { layerID: string }>> &
      MaybeError & {
        layers: Layer[]
        layerViews: LayerView[]
        results: OptimizationCandidateLayer[]
      }
  > {
    const layers: Layer[] = []
    const layerViews: LayerView[] = []
    const filteredResults = results.filter(r => ids.includes(r.id))
    const filteredCededLayers = cededLayers.filter(
      l => !isLayerAggFeeder(l.layer)
    )
    filteredResults.forEach(r => {
      let rUpdated = JSON.parse(JSON.stringify(r))
      if (rUpdated && rUpdated.technicalPremium === null) {
        rUpdated.technicalPremium = 0
      }
      const filteredCededLayer = matchTemplateLayer(
        filteredCededLayers,
        rUpdated
      ) as LayerState
      let lossSetUpdated = lossSetLayers
      if (rUpdated.lossSetGroupID) {
        const lossSetG = lossSetGroups.find(
          l => l.id === rUpdated.lossSetGroupID
        )
        lossSetUpdated = lossSetG ? lossSetG.lossSetLayers : lossSetLayers
      }
      const view = new LayerView(cededLayers, filteredCededLayer.layer, {
        reinsurers,
        lossSets: lossSetUpdated,
      })
      view.setFromValues({ ...rUpdated, id: rUpdated.id }, skipPhysicalMetadata)
      layerViews.push(view)
      layers.push({ ...view.layer, id: rUpdated.id })
    })
    let options: ExtraOptions | undefined
    if (isAggLayerOptimization(layers)) {
      options = {
        isAgg: true,
        aggFeeder: cededLayers.find(c => isLayerAggFeeder(c.layer))?.layer,
      }
    }
    return this.postMultiLayerViewAdhoc(
      layers,
      filteredResults,
      filteredResults.map(f => f.id),
      analysisProfileID || '',
      lossSetGroups,
      options
    ).pipe(
      map(r => ({
        ...r,
        layers,
        layerViews,
        results,
      }))
    )
  }

  private getSubjectPremiumGroup(lossSetsGroup: LossSetGroup): number {
    return lossSetsGroup.lossSetLayers.reduce((acc, ls) => {
      return ls.premium ? acc + ls.premium.value : acc + 0
    }, 0)
  }
}
