import { MetricValueType } from '../../core/model/metric-value-type.model'
import {
  LayerMetricDefMap,
  resolveLayerMetricDef,
} from '../model/layer-metric-defs'
import { LayerView, LayerViewValues } from '../model/layer-view'
import LayerMetricDefs from '../model/layer-metric-defs'
import getLayerPropertyDefs from '../properties/layer/layer-property-defs'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { excludeProperties } from './utils/optimization-prop-def'
import { isLayerAgg, isLayerAggFeeder } from '../model/layers.util'
import { Layer } from '../model/layers.model'
import {
  ExceedanceProbability,
  Metrics,
  CurrencyCode,
} from '../../api/analyzere/analyzere.model'
import { LossSetGroup } from '../model/loss-set-layers.model'
import { uniqBy } from 'ramda'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../model/portfolio-metrics.model'
import { AggregationMethodType, Perspective } from '../model/metrics.model'

export type ResultsView = 'chart' | 'table'

export interface CandidateLayerChangeEvent {
  changes: Change<OptimizationCandidateResultEntity>[]
  results: OptimizationCandidateResult[]
}
export interface OptimizationInputRangeChangeEvent {
  id: string
  change: Change<OptimizationInputRange>
}

export interface LossSetGroupIDsChangeEvent {
  id: string
  change: string[]
}

export interface OptimizationInputCurrencyChangeEvent {
  id: string
  currency: CurrencyCode
  ranges: OptimizationRangesTypes[]
}
export interface OptimizationCurrency {
  id: string
  currency: string
}
export interface OptimizationRangesTypes {
  id: string
  ranges: OptimizationInputRange[]
  lossSetGroupIDs: string[]
}
export interface OptimizationInitialMetricsResponse {
  expectedCededLossBase: Metrics
  depositPremium: Metrics
  expectedCededLossBaseNoParticipation: Metrics
  expectedCededPremiumBase: Metrics
  entryProbability: ExceedanceProbability
  cededYearValue: Metrics
}

export interface OptimizationInitialMetrics {
  expectedCededLoss: number
  standardDeviationExpectedLossForTP: number
  cededLossCVForTP: number
  cededYearTVar: number
  cededYearVar: number
  entryProbability: number
  expectedCededPremium: number
  depositPremium: number
  purePremiumForTP: number
  expectedCededLossRatio: number
  metricWeight: number
  layerViewID: string
  layerID: string
}

export interface OptimizationInputRange {
  id: keyof LayerMetricDefMap
  label: string
  from: number
  to: number
  incrementsOf: number
  numberOfOptions: number
  type: MetricValueType
  currency: string
}

export interface Change<T> {
  id: string
  changes: Partial<T>
}

export interface OptimizationCandidateResultEntity {
  id: string
  createPortfolio: boolean
  savePortfolio: boolean
  netPortfolioViewID?: string
  cededPortfolioViewID?: string
  grossPortfolioViewID?: string
  loading?: boolean
  expectedNetLoss?: number
  portfolioExpectedCededPremium?: number
  portfolioEfficiencyScore?: number
  portfolioMetricsError?: string | null
  candidateLayers: string[]
  portfolioTvar?: number
  portfolioTailError?: string | null
  portfolioVar?: number
}

export interface OptimizationCandidateResult
  extends Omit<OptimizationCandidateResultEntity, 'candidateLayers'> {
  candidateLayers: OptimizationCandidateLayer[]
}

export interface HasAggFeederProperties {
  feederOccurrenceLimit: number
  feederOccurrenceAttachment: number
  feederFranchiseDeductible: number
  feederParticipation: number
}

export interface OptimizationCandidateLayer
  extends LayerViewValues,
    Partial<HasAggFeederProperties> {
  layerNumber: number
  layerViewID?: string
  id: string
  group: string
  include: boolean
  lossSetGroupID?: string
}

const optimizationTypes = [
  'xl',
  'qs',
  'ag',
  'multi-layer',
  'not-supported',
] as const
export type OptimizationType = typeof optimizationTypes[number]

export type OptimizationCandidateResultDenormalized =
  OptimizationCandidateLayer & OptimizationCandidateResult

export interface OptimizationLayersMessageResult {
  values: OptimizationCandidateLayer[]
  error?: string
}

export interface OptimizationPortfolioTailMetricsPayload {
  id: string | null
  aggregationMethod: AggregationMethodType
  perspective: Perspective
  portfolioType: PortfolioType
  lossFilter: string
  returnPeriod1: number
}

export type OptimizationPortfolioTailMetrics = Omit<
  PortfolioMetrics,
  | 'returnPeriodData'
  | 'returnPeriod2'
  | 'returnPeriod3'
  | 'returnPeriod4'
  | 'returnPeriod5'
>

export function toPortfolioMetrics({
  portfolioType,
  aggregationMethod,
  perspective,
  returnPeriod1,
  lossFilter,
}: OptimizationPortfolioTailMetrics): PortfolioMetrics {
  return {
    lossFilter,
    portfolioType,
    aggregationMethod,
    perspective,
    returnPeriod1,
    returnPeriod2: 1,
    returnPeriod3: 1,
    returnPeriod4: 1,
    returnPeriod5: 1,
    returnPeriodData: [],
  }
}

export function denormalize(
  result: OptimizationCandidateResult
): OptimizationCandidateResultDenormalized[] {
  return result.candidateLayers.map(l => ({
    ...l,
    ...result,
    id: combinedID(result, l),
  }))
}

export function normalize(
  obj: OptimizationCandidateResultDenormalized
): OptimizationCandidateResult {
  return {
    id: extractCandidateID(obj),
    createPortfolio: obj.createPortfolio,
    savePortfolio: obj.savePortfolio,
    netPortfolioViewID: obj.netPortfolioViewID,
    cededPortfolioViewID: obj.cededPortfolioViewID,
    grossPortfolioViewID: obj.grossPortfolioViewID,
    loading: obj.loading,
    expectedNetLoss: obj.expectedNetLoss,
    portfolioExpectedCededPremium: obj.portfolioExpectedCededPremium,
    portfolioEfficiencyScore: obj.portfolioEfficiencyScore,
    portfolioMetricsError: obj.portfolioMetricsError,
    candidateLayers: obj.candidateLayers,
  }
}

export function combinedID(
  candidate: OptimizationCandidateResult,
  candidateLayer: OptimizationCandidateLayer
) {
  return `${candidate.id}|${candidateLayer.id}`
}

export function extractCandidateID(
  obj: string | OptimizationCandidateResultDenormalized
) {
  if (typeof obj === 'string') {
    return obj.split('|')[0]
  } else {
    return obj.id.split('|')[0]
  }
}

export function extractLayerID(
  obj: string | OptimizationCandidateResultDenormalized
) {
  if (typeof obj === 'string') {
    return obj.split('|')[1]
  } else {
    return obj.id.split('|')[1]
  }
}

export function getOptimizationType(type: string): OptimizationType {
  const t = type.split('_')[1]
  if (optimizationTypes.includes(t as any)) {
    return t as OptimizationType
  } else {
    return 'not-supported'
  }
}

export function inputRangeFromLayerView(
  view: LayerView
): OptimizationInputRange[] {
  const excludeProps = [...excludeProperties]
  if (isAgg(view.values)) {
    excludeProps.push(
      'occurrenceLimit',
      'occurrenceAttachment',
      'franchiseDeductible'
    )
  }
  if (isQS(view.values)) {
    excludeProps.push('cedingCommission')
  }
  if (isXL(view.values)) {
    excludeProps.push('rolPercentage')
  }
  const layerPropDefs = getLayerPropertyDefs(view.values).filter(
    l => !excludeProps.includes(l.id)
  )
  return layerPropDefs.map(propDef => {
    return {
      id: propDef.id as keyof LayerMetricDefMap,
      label: propDef.label,
      from: (view as any)[propDef.id] as number,
      to: (view as any)[propDef.id] as number,
      incrementsOf: 0,
      numberOfOptions: 1,
      type: propDef.valueType || 'text',
      currency: view.currency || 'USD',
    }
  })
}

export function getLayersTableColumnDef(
  rangesTypes: OptimizationRangesTypes[],
  lossSetGroups: LossSetGroup[]
) {
  let optimizationType: OptimizationType
  if (rangesTypes.length === 1) {
    optimizationType = getOptimizationType(rangesTypes[0].id)
  } else {
    optimizationType = 'multi-layer'
  }
  const def: SortTableColumnDef<OptimizationCandidateLayer>[] = []
  def.push({
    id: 'include',
    label: 'Include',
    editable: true,
    minWidth: '0.5rem',
  })
  def.push({
    id: 'layerNumber',
    label: 'Layer #',
    editable: false,
    valueType: 'unformatted-numeric',
    minWidth: '1rem',
    numberTransform: (n: number) => n,
    numberReverseTransform: (n: number) => n,
  })
  if (optimizationType === 'multi-layer') {
    def.push({ id: 'type', label: 'Type', editable: false, valueType: 'text' })
  }
  if (!isQS({ layerType: optimizationType })) {
    def.push({
      id: 'group',
      label: 'Group',
      editable: true,
      valueType: 'unformatted-numeric',
      minWidth: '0.5rem',
      numberTransform: (n: number) => n,
      numberReverseTransform: (n: number) => n,
      changeDebounceTime: 1,
    })
  }
  if (lossSetGroups.length > 0) {
    def.push({
      id: 'lossSetGroupID',
      label: 'Loss Set Group',
      editable: true,
      valueType: 'dropdown',
      minWidth: '6rem',
      references: lossSetGroups.map(l => ({ value: l.id, viewValue: l.name })),
    })
  }

  if (isAgg({ layerType: optimizationType })) {
    def.push({
      id: 'feederOccurrenceLimit',
      label: 'Feeder Occurrence Limit',
      editable: true,
      valueType: 'currency',
    })
    def.push({
      id: 'feederOccurrenceAttachment',
      label: 'Feeder Occurrence Attachment',
      editable: true,
      valueType: 'currency',
    })
    def.push({
      id: 'feederFranchiseDeductible',
      label: 'Feeder Franchise Deductible',
      editable: true,
      valueType: 'currency',
    })
    def.push({
      id: 'feederParticipation',
      label: 'Feeder Cession Percentage',
      editable: true,
      valueType: 'percentage',
    })
  }
  def.push(
    ...rangesTypes
      .flatMap(r => r.ranges)
      .map(mergeLayerMetricDef(LayerMetricDefs))
  )
  const currRange = def.find(curr => curr.id === 'currencyChange')
  if (currRange) {
    currRange.valueType = 'text'
  }
  def.push({
    id: 'purePremium',
    label: 'Expected Ceded Loss',
    minWidth: '4.5rem',
    editable: false,
    whenLoading: 'show',
    valueType: 'currency',
  }),
    def.push({
      id: 'rolPercentage',
      label: 'Rate-on-Line, Occurrence',
      minWidth: '4.5rem',
      editable: false,
      whenLoading: 'show',
      valueType: 'percentage',
      decimals: 2,
      blacklist: [
        { level: 'layer', type: 'qs' },
        { level: 'subtype', type: 'feeder' },
        'noncat_xl',
        'ahl_xl',
        'noncat_indxl',
        'noncat_risk',
        'noncat_swing',
      ],
    }),
    def.push({
      id: 'cedingCommission',
      label: 'Ceding Commission',
      minWidth: '4.5rem',
      editable: false,
      whenLoading: 'show',
      valueType: 'percentage',
      decimals: 2,
      whitelist: [
        { level: 'layer', type: 'qs' },
        { level: 'layer', type: 'xl' },
        'noncat_indxl',
        { level: 'layer', type: 'ag' },
        'cat_multisection',
        'noncat_multisection',
      ],
      blacklist: ['ahl_xl'],
    }),
    def.push({
      id: 'technicalPremium',
      label: 'Technical Premium',
      minWidth: '4.5rem',
      editable: true,
      whenLoading: 'show',
      valueType: 'currency',
    })
  return uniqBy(d => d.id, def)
}

export function getResultsMetricsTableColumnDef(
  lossSetGroups: LossSetGroup[],
  rangesTypes: OptimizationRangesTypes[]
) {
  let optimizationType: OptimizationType
  if (rangesTypes.length === 1) {
    optimizationType = getOptimizationType(rangesTypes[0].id)
  } else {
    optimizationType = 'multi-layer'
  }
  const defs: SortTableColumnDef<OptimizationCandidateResultDenormalized>[] = []
  if (!isQS({ layerType: optimizationType })) {
    defs.push({
      id: 'group',
      label: 'Group',
      editable: false,
      valueType: 'unformatted-numeric',
      minWidth: '0.5rem',
      whenLoading: 'show',
      numberTransform: (n: number) => n,
      numberReverseTransform: (n: number) => n,
    })
  }
  defs.push({
    id: 'layerNumber',
    label: 'Layer #',
    editable: false,
    valueType: 'unformatted-numeric',
    minWidth: '1rem',
    whenLoading: 'show',
    numberTransform: (n: number) => n,
  })
  if (optimizationType === 'multi-layer') {
    defs.push({
      id: 'type',
      label: 'Type',
      editable: false,
      valueType: 'text',
      whenLoading: 'show',
    })
  }

  defs.push(
    {
      id: 'savePortfolio',
      label: 'Save Portfolio?',
      editable: true,
      valueType: 'checkbox',
      minWidth: '1rem',
      whenLoading: 'show',
    },
    {
      id: 'createPortfolio',
      label: 'Portfolio Metrics?',
      editable: true,
      valueType: 'checkbox',
      minWidth: '1rem',
      whenLoading: 'show',
    },
    {
      id: 'expectedNetLoss',
      label: 'Net Expected Loss',
      valueType: 'currency',
      editable: false,
      minWidth: '4rem',
      whenLoading: 'message',
      category: 'Portfolio Metrics',
    },
    {
      id: 'portfolioExpectedCededPremium',
      label: 'Ceded Expected Premium',
      valueType: 'currency',
      editable: false,
      minWidth: '4rem',
      whenLoading: 'message',
      category: 'Portfolio Metrics',
    },
    {
      id: 'portfolioTvar',
      label: 'TVaR',
      valueType: 'currency',
      editable: false,
      minWidth: '4rem',
      whenLoading: 'message',
      category: 'Portfolio Tail Metrics',
      categoryTemplateID: 'portfolioTailMetrics',
    },
    {
      id: 'portfolioVar',
      label: 'VaR',
      valueType: 'currency',
      editable: false,
      minWidth: '4rem',
      whenLoading: 'message',
      category: 'Portfolio Tail Metrics',
      categoryTemplateID: 'portfolioTailMetrics',
    },
    {
      id: 'portfolioEfficiencyScore',
      label: 'Efficiency Score',
      valueType: 'numeric',
      decimals: 3,
      editable: false,
      minWidth: '4rem',
      whenLoading: 'message',
      category: 'Portfolio Tail Metrics',
      categoryTemplateID: 'portfolioTailMetrics',
    }
  )
  if (lossSetGroups.length > 0) {
    defs.push({
      id: 'lossSetGroupID',
      label: 'Loss Set Group',
      editable: false,
      valueType: 'dropdown',
      whenLoading: 'show',
      minWidth: '6rem',
      category: 'Layer Properties',
      references: lossSetGroups.map(l => ({ value: l.id, viewValue: l.name })),
    })
  }

  if (isAgg({ layerType: optimizationType })) {
    defs.push({
      id: 'feederOccurrenceLimit',
      label: 'Feeder Occurrence Limit',
      editable: false,
      valueType: 'currency',
      category: 'Layer Properties',
      whenLoading: 'show',
    })
    defs.push({
      id: 'feederOccurrenceAttachment',
      label: 'Feeder Occurrence Attachment',
      editable: false,
      valueType: 'currency',
      category: 'Layer Properties',
      whenLoading: 'show',
    })
    defs.push({
      id: 'feederFranchiseDeductible',
      label: 'Feeder Franchise Deductible',
      editable: false,
      valueType: 'currency',
      category: 'Layer Properties',
      whenLoading: 'show',
    })
    defs.push({
      id: 'feederParticipation',
      label: 'Feeder Cession Percentage',
      editable: false,
      valueType: 'percentage',
      category: 'Layer Properties',
      whenLoading: 'show',
    })
  }
  const columnDefs: Partial<
    SortTableColumnDef<OptimizationCandidateResultDenormalized>
  >[] = []
  columnDefs.push({
    id: 'cessionPercentage',
    whenLoading: 'show',
    editable: false,
    category: 'Layer Properties',
  })
  if (!isAgg({ layerType: optimizationType })) {
    columnDefs.push(
      {
        id: 'occurrenceLimit',
        whenLoading: 'show',
        editable: false,
        category: 'Layer Properties',
      },
      {
        id: 'occurrenceAttachment',
        whenLoading: 'show',
        editable: false,
        category: 'Layer Properties',
      }
    )
  }
  columnDefs.push(
    {
      id: 'aggregateLimit',
      whenLoading: 'show',
      minWidth: '5rem',
      editable: false,
      category: 'Layer Properties',
    },
    {
      id: 'aggregateAttachment',
      whenLoading: 'show',
      editable: false,
      category: 'Layer Properties',
    },
    {
      id: 'depositPremium',
      whenLoading: 'show',
      minWidth: '5rem',
      editable: false,
      category: 'Layer Properties',
    },

    {
      id: 'expectedReinstatementPremium',
      minWidth: '5rem',
      whenLoading: 'show',
      editable: false,
      category: 'Layer Metrics',
    },
    {
      id: 'expectedCededPremium',
      minWidth: '6.3rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },
    {
      id: 'purePremium',
      label: 'Expected Ceded Loss',
      minWidth: '4.5rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },
    {
      id: 'expectedCededMargin',
      minWidth: '5.5rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },
    {
      id: 'expectedCededLossRatio',
      label: 'Ceded Loss Ratio',
      minWidth: '4.5rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },

    {
      id: 'entryProbability',
      minWidth: '5.6rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },
    {
      id: 'exitProbability',
      minWidth: '5.6rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },
    {
      id: 'exitAggProbability',
      minWidth: '6.2rem',
      editable: false,
      whenLoading: 'show',
      category: 'Layer Metrics',
    },

    {
      id: 'aeptVar100',
      category: '100yr AEP',
      editable: false,
      whenLoading: 'show',
    },
    {
      id: 'aepVar100',
      category: '100yr AEP',
      editable: false,
      whenLoading: 'show',
    },
    {
      id: 'efficiencyTVaR',
      category: 'Efficiency',
      editable: false,
      whenLoading: 'show',
    },
    {
      id: 'efficiencyVolatility',
      category: 'Efficiency',
      editable: false,
      whenLoading: 'show',
    }
  )
  defs.push(...columnDefs.map(mergeMetricDef(LayerMetricDefs)))
  return defs
}

const mergeLayerMetricDef =
  (defs: Partial<LayerMetricDefMap>) =>
  (
    col: OptimizationInputRange
  ): SortTableColumnDef<OptimizationCandidateLayer> => {
    const def = defs[col.id]
    if (!def) {
      throw new Error(`Cannot find layer metric definition for '${col.id}`)
    }
    return {
      ...resolveLayerMetricDef(def),
      ...col,
    } as SortTableColumnDef<OptimizationCandidateLayer>
  }

const mergeMetricDef =
  (defs: Partial<LayerMetricDefMap>) =>
  (
    col: Partial<SortTableColumnDef<OptimizationCandidateResultDenormalized>>
  ): SortTableColumnDef<OptimizationCandidateResultDenormalized> => {
    const def = (defs as any)[col.id as string]
    if (!def) {
      throw new Error(`Cannot find layer metric definition for '${col.id}`)
    }
    return { ...resolveLayerMetricDef(def), ...col }
  }

export const stepActions = ['getCandidates', 'getMetrics', 'persist'] as const

export type OptimizationStepActionConfig = Record<
  typeof stepActions[number],
  string
>

export function isAgg(values: { layerType?: string }) {
  return values.layerType === 'ag'
}

export function isQS(values: { layerType?: string }) {
  return values.layerType === 'qs'
}

export function isXL(values: { layerType?: string }) {
  return values.layerType === 'xl'
}

export function isAggLayerOptimization(layers: Layer[]) {
  return layers.every(l => isLayerAgg(l) || isLayerAggFeeder(l))
}

export function matchTemplateLayer(
  layers: LayerState[],
  candidateLayer: OptimizationCandidateLayer
) {
  return layers.find(f => f.layer.id === candidateLayer.id.split('_')[0])
}

export const MAX_UNIQUE_LAYERS = 1000
