import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { TopDropProperties } from 'src/app/analysis/properties/properties-panel.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-top-drop-input',
  styles: [
    `
           :host {
             display: flex;
             flex-direction: column;
             justify-content: center;
             padding-left: var(--inset);
           }
     
           :host:not(:last-of-type) {
             margin-bottom: var(--stack);
           }
           label {
             color: var(--subtle);
             font-family: var(--font-header-family);
             font-size: var(--font-size-tiny);
             font-weight: var(--font-header-weight);
             margin-bottom: var(--stack-tiny);
             letter-spacing: 0.3px;
           }
     
           .div-content {
             background-color: var(--bg-2);
             margin-top: var(--stack);
             margin-bottom: var(--stack);
             border-radius: 5px;
             border: 2px solid var(--border-1);
           }
     
           .layer-title > * {
             color: rgba(var(--rgb), 0.92);
             margin: 0 var(--inset);
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             text-align: center;
           }
     
           .layer-title .name {
             font-size: var(--font-size-big);
             margin-top: var(--stack);
             margin-bottom: var(--stack);
             color: var(--accent);
           }
     
           .name {
             cursor: pointer;
           }
     
           @media screen and (max-width: 1240px) {
             .layer-title .name {
               font-size: var(--font-size);
               margin-top: var(--stack);
               margin-bottom: var(--stack);
             }
           }
         `,
  ],
  template: `
    <div class="div-content">
      <div class="layer-title">
        <div
          class="name"
          [ngClass]="'app-palette-noncat_xl'"
          (click)="onShowTopDropDialog()"
        >
          {{ labelButton }}
        </div>
      </div>
    </div>
  `,
})
export class TopDropInputComponent {
  @Input() topLayer: LayerState
  @Input() dropLayer: LayerState | null
  @Input() hiddenLayer: LayerState | null

  @Output() showTopDropDialog = new EventEmitter<TopDropProperties>()

  get labelButton(): string {
    return 'T&D Properties'
  }


  onShowTopDropDialog() {
    this.showTopDropDialog.emit({
      top: this.topLayer,
      drop: this.dropLayer,
      hidden: this.hiddenLayer,
    })
  }
}
