import { createAction, props } from '@ngrx/store'

export interface WindowSize {
  width: number
  height: number
}

export const setWidth = createAction(
  '[Window] Set Size',
  props<{ size: WindowSize }>()
)
