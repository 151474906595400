import { createAction, props } from '@ngrx/store'
import { HasError } from '../../api/model/api.model'
import { toArray } from '@shared/util/operators'
import { BenchmarkModeID } from '../model/benchmark-mode'
import {
  BenchmarkCompany,
  BenchmarkPeerSet,
  ExcludePeerset,
} from '../model/benchmark.model'
import { BenchmarkPeerSetState } from './benchmark-peer-set.reducer'

interface HasTarget {
  entity: BenchmarkCompany | BenchmarkPeerSet
}

interface HasPeerSet {
  entity: BenchmarkPeerSet
}

export interface HasBenchmarkPeerSets {
  peerSets: BenchmarkPeerSet[]
}

export interface HasBenchmarkCustomPeerSetsAccess {
  hasAccess: boolean
}

export const fetchBenchmarkCompanyPeerSets = createAction(
  '[Benchmark Peer Set] Fetch All By Company',
  props<HasTarget>()
)
export const fetchBenchmarkCompanyCustomPeerSetAccess = createAction(
  '[Benchmark Peer Set] Fetch Access Info to Company Custom Peersets'
)
export const fetchBenchmarkCompanyPeerSetsSuccess = createAction(
  '[Benchmark Peer Set] Fetch All By Company Success',
  props<HasBenchmarkPeerSets>()
)
export const fetchBenchmarkCompanyCustomPeerSetAccessSuccess = createAction(
  '[Benchmark Peer Set] Fetch Access Info to Company Custom Peersets Success',
  props<{ hasAccessToTarget: boolean }>()
)
export const fetchBenchmarkCompanyPeerSetsFailure = createAction(
  '[Benchmark Peer Set] Fetch All By Company Failure',
  props<HasTarget & HasError>()
)
export const fetchBenchmarkCompanyCustomPeerSetAccessFailure = createAction(
  '[Benchmark Peer Set] Fetch Access Info to Company Custom Peersets Failure',
  props<HasError>()
)
export const refreshBenchmarkPeerSetsByMode = createAction(
  '[Benchmark Peer Set] Refresh By Mode',
  (mode: BenchmarkModeID) => ({ mode })
)

export const clearBenchmarkPeerSets = createAction(
  '[Benchmark Peer Set] Clear',
  (mode: BenchmarkModeID) => ({ mode })
)

export const setBenchmarkManagePeerSetSelected = createAction(
  '[Benchmark Peer Set] Manage Peer Set Selected',
  (id: string | number | null) => ({ id })
)

export const setBenchmarkSelectedPeerSet = createAction(
  '[Benchmark Peer Set] Set Selected',
  (mode: BenchmarkModeID, id: string | number) => ({ mode, id })
)

// Individual

export const updateBenchmarkPeerSet = createAction(
  '[Benchmark Peer Set] Update',
  props<HasPeerSet>()
)
export const updateBenchmarkPeerSetSuccess = createAction(
  '[Benchmark Peer Set] Update Success',
  props<HasPeerSet>()
)
export const updateBenchmarkPeerSetFailure = createAction(
  '[Benchmark Peer Set] Update Failure',
  props<HasPeerSet & HasError>()
)

export const addBenchmarkPeerSet = createAction(
  '[Benchmark Peer Set] Add',
  props<HasPeerSet>()
)
export const addBenchmarkPeerSetSuccess = createAction(
  '[Benchmark Peer Set] Add Success',
  props<HasPeerSet>()
)
export const addBenchmarkPeerSetFailure = createAction(
  '[Benchmark Peer Set] Add Failure',
  props<HasPeerSet & HasError>()
)

export const removeBenchmarkPeerSet = createAction(
  '[Benchmark Peer Set] Remove',
  (entity: BenchmarkPeerSet) => ({ entity })
)
export const removeBenchmarkPeerSetSuccess = createAction(
  '[Benchmark Peer Set] Remove Success',
  (entity: BenchmarkPeerSet) => ({ entity })
)
export const removeBenchmarkPeerSetFailure = createAction(
  '[Benchmark Peer Set] Remove Failure',
  props<HasPeerSet & HasError>()
)

export interface BenchmarkPeerSetOverrideShowEvent {
  peerSet: BenchmarkPeerSet
  value: boolean
}

export const setOverrideShowBenchmarkPeerSet = createAction(
  '[Benchmark Peer Set] Set Override Show',
  props<BenchmarkPeerSetOverrideShowEvent>()
)
export const setOverrideShowBenchmarkPeerSetSuccess = createAction(
  '[Benchmark Peer Set] Set Override Show Success',
  props<BenchmarkPeerSetOverrideShowEvent>()
)
export const setOverrideShowBenchmarkPeerSetFailure = createAction(
  '[Benchmark Peer Set] Set Override Show Failure',
  props<BenchmarkPeerSetOverrideShowEvent & HasError>()
)

// Peers

export const addBenchmarkPeerSetPeers = createAction(
  '[Benchmark Peer Set] Add Peers',
  (peers: BenchmarkCompany | BenchmarkCompany[], skipFetch?: boolean) => ({
    peers: toArray(peers),
    skipFetch,
  })
)

export const removeBenchmarkPeerSetPeers = createAction(
  '[Benchmark Peer Set] Remove Peers',
  (peers: BenchmarkCompany | BenchmarkCompany[], skipFetch?: boolean) => ({
    peers: toArray(peers),
    skipFetch,
  })
)

export const setBenchmarkPeerSetPeers = createAction(
  '[Benchmark Peer Set] Set Peers',
  (mode: BenchmarkModeID, peers: BenchmarkCompany | BenchmarkCompany[]) => ({
    mode,
    peers: toArray(peers),
  })
)

export interface BenchmarkToggleVisibiltyEvent {
  id: string | number
  mode: BenchmarkModeID
  indices: number | number[]
  hide?: boolean
}

export const toggleBenchmarkPeerSetPeersVisibility = createAction(
  '[Benchmark Peer Set] Toggle Peers Visibility',
  props<BenchmarkToggleVisibiltyEvent>()
)

export const toggleBenchmarkPeerSetDataExcluded = createAction(
  '[Benchmark Peer Set] Toggle Data Excluded',
  (mode: BenchmarkModeID, id: string | number, value?: boolean) => ({
    mode,
    id,
    value,
  })
)

// TODO: Saved Quantile

export const toggleBenchmarkPeerSetQuantileExcluded = createAction(
  '[Benchmark Peer Set] Toggle Quantile Excluded',
  (mode: BenchmarkModeID, id: string | number, value?: boolean) => ({
    mode,
    id,
    value,
  })
)

export const toggleMultiBenchmarkPeerSetQuantileExcluded = createAction(
  '[Benchmark Peer Set] Toggle Multi Quantile Excluded',
  (mode: BenchmarkModeID, excludePeerset: ExcludePeerset[]) => ({
    mode,
    excludePeerset,
  })
)

export const restoreBenchmarkPeerSetSettings = createAction(
  '[Benchmark Peer Set] Restore Settings'
)

export const restoreBenchmarkPeerSetSettingsSuccess = createAction(
  '[Benchmark Peer Set] Restore Settings Success',
  (updates: Partial<BenchmarkPeerSetState>) => ({ updates })
)
