<div class="new-summary-view" [formGroup]="form">
  <h1 mat-dialog-title>Save Explore Gross Summary Filter View</h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width" subscriptSizing="dynamic">
      <mat-label>Summary View Name</mat-label>
      <input
        matInput
        formControlName="summaryViewName"
        matTooltip="The name for your new Summary View"
        matTooltipClass="form-tooltip"
        matTooltipPosition="after"
        maxlength="50"
      />
      <mat-error *ngIf="form.get('summaryViewName').invalid && form.get('summaryViewName').touched">
        Value is required and must be less than 50 characters.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button appButton accent (click)="handleClick()" [disabled]="form.invalid">
      <span>Save</span>
    </button>
    <button appButton link mat-dialog-close>
      <span>Cancel</span>
    </button>
  </div>
</div>
