import { createAction, props } from '@ngrx/store'
import { QuoteChartResponse } from '../../model/quote-charts.model'
import { ApplicationError } from '../../../../error/model/error'

export const fetchAllQuoteTrendsData = createAction(
  '[Quote Management Information] Fetch All Trends Data'
)
export const fetchAllQuoteTrendsDataSuccess = createAction(
  '[Quote Management Information] Fetch All Trends Data - Success',
  props<{ charts: QuoteChartResponse[] }>()
)
export const fetchAllQuoteTrendsDataFailure = createAction(
  '[Quote Management Information] Fetch All Trends Data - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchSingleQuoteTrendDataSuccess = createAction(
  '[Quote Management Information] Fetch Single Trend Data - Success',
  props<{ chart: QuoteChartResponse }>()
)
export const fetchSingleQuoteTrendDataFailure = createAction(
  '[Quote Management Information] Fetch Single Trend Data - Failure',
  props<{ error: ApplicationError }>()
)
