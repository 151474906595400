import { all, keys, path, values } from 'ramda'
import { environment } from '../../../environments/environment'
import { toArray } from '../util/operators'
import {
  DataFilterFieldTerm,
  DataFilterTerm,
  isDataFilterSimpleTerm,
  parseDataFilterText,
} from './data-filter-parser'

// tslint:disable-next-line: variable-name
const DEBUG_enableLogging = false

export interface DataFilterFieldConfig {
  path?: string | string[]
  aliases?: string | string[]
  useExactMatch?: boolean
  isMap?: boolean
  isFlag?: boolean
  falseFlagAliases?: string | string[]
  valueAliases?: Record<string, string | string[] | undefined | null>
}

export type DataFilterConfig<T> = {
  [P in keyof T]?: DataFilterFieldConfig
}

interface DataFilterMatch<T> {
  field?: DataFilterField<T>
  term: DataFilterTerm
  isMatch: boolean
}

export interface DataFilterResult<T> {
  isMatch: boolean
  matches: Array<DataFilterMatch<T>>
}

export const toDataFilterFieldConfigList = <T>(
  fieldsCfg?: DataFilterConfig<T>
): Array<{
  name: keyof T
  cfg: DataFilterFieldConfig
}> =>
  (fieldsCfg &&
    (keys(fieldsCfg) as Array<keyof T>)
      .map(name => ({
        name,
        cfg: fieldsCfg[name] as DataFilterFieldConfig,
      }))
      .filter(({ cfg }) => cfg != null)) ||
  []

const createMatch = <T>(
  field: DataFilterField<T> | undefined,
  term: DataFilterTerm,
  isMatch: boolean
): DataFilterMatch<T> => ({ field, term, isMatch })

const equalsText = (text: string, container: any): boolean => {
  return text.toLowerCase() === String(container).toLowerCase()
}

const containsText = (text: string, container: any): boolean => {
  return String(container)
    .toLowerCase()
    .includes(text.toLowerCase())
}

const anyEqualsText = (
  textValues: string | string[],
  containerValues: any | any[]
): boolean =>
  toArray(containerValues).some(containerValue =>
    toArray(textValues).some(textValue => equalsText(textValue, containerValue))
  )

const anyContainsText = (
  textValues: string | string[],
  containerValues: any | any[]
): boolean =>
  toArray(containerValues).some(containerValue =>
    toArray(textValues).some(textValue =>
      containsText(textValue, containerValue)
    )
  )

function findMatch<T>(
  filterFields: DataFilterField<T>[],
  term: DataFilterTerm
): DataFilterMatch<T> {
  const allMatches = filterFields.map(field => field.getMatch(term))
  const firstMatch = allMatches.find(match => match.isMatch)
  return firstMatch === undefined
    ? // No fields match, so unless we're looking for a negated term,
      // return a successful match
      createMatch(undefined, term, term.negated)
    : // Found a match, so unless term is negated, set `isMatch` true
      { ...firstMatch, isMatch: !term.negated }
}

export function createDataFilterer<T>(
  filterText: string,
  cfg: DataFilterConfig<T>
) {
  const terms = parseDataFilterText(filterText)

  if (DEBUG_enableLogging) {
    print('data-filter parsed', terms)
  }

  const props = Object.keys(cfg)

  return (data: T) => {
    const filterFields = props.map(prop => {
      const fieldCfg = cfg[prop as keyof T] as DataFilterFieldConfig
      return new DataFilterField(prop, fieldCfg, data)
    })

    const matches = terms.map(term => findMatch(filterFields, term))
    const isMatch = all(match => match?.isMatch ?? false, matches)
    const result = { isMatch, matches }

    if (DEBUG_enableLogging) {
      // Print to log (if not prod)
      const label =
        (data as any).label || (data as any).name || (data as any).id
      const msg = `data-filter ${result.isMatch ? '✓' : ' '} ${label}`
      print(msg, { data, matches, terms, cfg })
    }

    return result
  }
}

class DataFilterField<T> {
  private _value: any
  private _valueMap: Record<string, string> = {}

  get keys(): string[] {
    return [this.name, ...toArray(this.cfg.aliases)]
  }

  get comparator(): (
    textValues: any | any[],
    containerValues: any | any[]
  ) => boolean {
    return this.cfg.useExactMatch || this.cfg.isFlag
      ? anyEqualsText
      : anyContainsText
  }

  constructor(public name: string, public cfg: DataFilterFieldConfig, obj: T) {
    const valuePath = [name, ...toArray(cfg.path)]
    this._value = path(valuePath, obj)

    if (cfg.isMap) {
      this._valueMap = this.simplifyValueMap()
    }
  }

  getMatch(term: DataFilterTerm): DataFilterMatch<T> {
    return createMatch(this as DataFilterField<T>, term, this.hasMatch(term))
  }

  private hasMatch(term: DataFilterTerm): boolean {
    const termValues = this.getTermValues(term)
    const fieldValues = this.getFieldValues(term)
    return this.comparator(termValues, fieldValues)
  }

  private getTermValues(term: DataFilterTerm): string | string[] {
    return isDataFilterSimpleTerm(term) ? term.text : term.values
  }

  private getFieldValues(term: DataFilterTerm): any[] {
    return isDataFilterSimpleTerm(term)
      ? this.getValuesForSimpleTerm()
      : this.getValuesForFieldTerm(term)
  }

  private getValuesForSimpleTerm(): any[] {
    if (this._value == null) {
      return []
    }

    if (this.cfg.isMap) {
      return values(this._value)
    }

    if (this.cfg.isFlag) {
      return this._value
        ? this.keys
        : [
            ...this.keys.map(v => `not ${v}`),
            ...toArray(this.cfg.falseFlagAliases),
          ]
    }

    return this.applyValueAliases([this._value])
  }

  private getValuesForFieldTerm(term: DataFilterFieldTerm): any[] {
    if (!this.keysMatchFieldTerm(term) && !this.cfg.isMap) {
      // Field keys != conditional operator keyword & no map values to check
      return []
    }

    const rawValue = this.cfg.isMap
      ? this._valueMap[this.simplifyKey(term.key)]
      : this._value
    return this.applyValueAliases(rawValue)
  }

  private applyValueAliases(prevValues: any | any[]): any[] {
    const aliases = this.cfg.valueAliases || {}
    return toArray(prevValues).reduce((acc, val) => {
      return [...acc, val, ...toArray(aliases[val])]
    }, [])
  }

  private keysMatchFieldTerm(term: DataFilterFieldTerm): boolean {
    return this.keys
      .map(k => this.simplifyKey(k))
      .includes(this.simplifyKey(term.key))
  }

  private simplifyValueMap() {
    return Object.keys(this._value || {}).reduce(
      (acc, key) => ({ ...acc, [this.simplifyKey(key)]: this._value[key] }),
      {}
    )
  }

  private simplifyKey(key: string): string {
    return key.replace(/[\s-]/g, '').toLowerCase()
  }
}

const print = (msg?: any, ...opts: any[]) => {
  if (!environment.production) {
    console.log(msg, ...opts)
  }
}
