import { Action, createReducer, on } from '@ngrx/store'
import {
  fetchPortfolioView,
  fetchPortfolioViewFailure,
  fetchPortfolioViewSuccess,
  addCededLayerView,
  fetchCededLayersViewsSuccess,
  fetchCededLayersViews,
  fetchCededLayersViewsFailure,
} from './portfolio-view.actions'

export interface State {
  cededPortfolioViewID: string | null
  netPortfolioViewID: string | null
  grossPortfolioViewID: string | null
  cededLayersViewIDs: Record<string, string>
  loadingCededLayersViews: boolean
  errorCededLayersViews: string | null
  loading: boolean
  error: string | null
}

export const initialState: State = {
  cededPortfolioViewID: null,
  grossPortfolioViewID: null,
  netPortfolioViewID: null,
  cededLayersViewIDs: {},
  loadingCededLayersViews: false,
  errorCededLayersViews: null,
  loading: false,
  error: null,
}

const portfolioViewReducer = createReducer(
  initialState,
  on(fetchPortfolioView, _ => ({
    ...initialState,
    error: null,
    loading: true,
  })),

  on(
    fetchPortfolioViewSuccess,
    (
      state,
      { cededPortfolioViewID, netPortfolioViewID, grossPortfolioViewID }
    ) => ({
      ...state,
      cededPortfolioViewID,
      error: null,
      loading: false,
      netPortfolioViewID,
      grossPortfolioViewID,
    })
  ),

  on(fetchPortfolioViewFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(addCededLayerView, (state, { layerID, viewID }) => ({
    ...state,
    cededLayersViewIDs: { ...state.cededLayersViewIDs, [layerID]: viewID },
  })),

  on(fetchCededLayersViewsSuccess, (state, { views }) => ({
    ...state,
    cededLayersViewIDs: views,
    errorCededLayersViews: null,
    loadingCededLayersViews: false,
  })),

  on(fetchCededLayersViews, state => ({
    ...state,
    loadingCededLayersViews: true,
    errorCededLayersViews: null,
  })),

  on(fetchCededLayersViewsFailure, (state, { error }) => ({
    ...state,
    loadingCededLayersViews: false,
    errorCededLayersViews: error.message,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return portfolioViewReducer(state, action)
}
