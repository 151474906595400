import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromAnimatedScenariosActions from './animated-scenarios.actions'
import * as fromAnimatedSceneariosSelectors from './animated-scenarios.selectors'
import * as fromScenarioEventResultActions from './scenario-event-result.actions'
import {
  withLatestFrom,
  map,
  concatMap,
  mergeMap,
  catchError,
} from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../core/store'
import { AnimatedScenariosService } from '../../../api/animated-scenarios/animated-scenarios.service'
import { rejectErrorWithInput, mergeMapWithInput } from '../../../api/util'
import { selectCurrentAnalysisProfile } from '../../../core/store/broker/broker.selectors'
import {
  LogicalPortfolioLayer,
  Portfolio,
} from '../../../api/analyzere/analyzere.model'
import { ApiResponse } from '../../../api/model/api.model'
import { Program } from '../../../core/model/program.model'
import { PortfoliosIDAndName } from '../../model/portfolio-set.model'
import { of } from 'rxjs'
import { isLayerActualRisk } from '../../model/layers.util'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import {
  selectCededLayers,
  selectLossSetLayers,
} from '../../store/analysis.selectors'
import { ScenarioEventResult } from '../animated-scenarios.model'
import { selectPrograms } from 'src/app/core/store/program/program.selectors'

// tslint:disable: no-non-null-assertion
@Injectable()
export class AnimatedScenariosEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private animatedScenarioService: AnimatedScenariosService,
    private analyzereService: AnalyzreService
  ) {}

  execute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnimatedScenariosActions.execute),
      withLatestFrom(
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectAllScenarioEvents)
        ),
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectSelectedStructure)
        ),
        this.store.pipe(select(selectCurrentAnalysisProfile)),
        this.store.pipe(select(selectLossSetLayers)),
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectPrograms))
      ),
      mergeMapWithInput(
        ([
          _,
          events,
          structure,
          __,
          lossSetLayers,
          cededLayers,
          structures,
        ]) => {
          const eventCatalogDataID =
            this.animatedScenarioService.getLossSetDataIdWithMinSize(
              lossSetLayers
            )
          return this.animatedScenarioService
            .getEventIds(events.length, eventCatalogDataID)
            .pipe(
              mergeMap(response => {
                if (response.error) {
                  return of({ error: response.error })
                } else {
                  const structureId = structure.id
                  const cededPortfolioID =
                    structures.find(s => s.id === structureId)
                      .cededPortfolioID ?? ''
                  let inuranceCededLayers: LogicalPortfolioLayer[] = []
                  let visibleLayers: string[] = []
                  const defaultInuranceLayers: LogicalPortfolioLayer[] = []
                  const inuranceTargetIdsSet = new Set<{
                    type: string
                    id: string
                    groupID: string
                  }>()
                  cededLayers.forEach(x => {
                    if (x.layer.meta_data.inuranceTarget) {
                      const data = JSON.parse(
                        x.layer.meta_data.inuranceTargetFor
                      )
                      data.forEach(
                        (item: {
                          id: string
                          type: string
                          groupID: string
                        }) => {
                          if (
                            ![...inuranceTargetIdsSet].some(
                              entry => entry.id === item.id
                            )
                          ) {
                            inuranceTargetIdsSet.add({
                              type: item.type,
                              id: item.id,
                              groupID: item.groupID ? item.groupID : '',
                            })
                          }
                        }
                      )
                    }
                  })
                  const inuranceStructureObservable = () => {
                    if (cededPortfolioID) {
                      return this.analyzereService
                        .fetchPortfolio(cededPortfolioID)
                        .pipe(
                          map(response => {
                            if (response && response.data) {
                              return response?.data!
                            }
                          }),
                          catchError(() => {
                            return of(undefined)
                          })
                        )
                    } else {
                      return of(undefined)
                    }
                  }
                  const eventIDs = response.data!
                  return inuranceStructureObservable().pipe(
                    mergeMap(portfolioResponse => {
                      if (portfolioResponse) {
                        for (const logicalLayer of portfolioResponse.layers as LogicalPortfolioLayer[]) {
                          const logicalLayerSources: LogicalPortfolioLayer[] =
                            logicalLayer.sources as LogicalPortfolioLayer[]
                          inuranceCededLayers.push(
                            ...this.animatedScenarioService.getInuranceDataFromPortfolio(
                              logicalLayerSources,
                              inuranceTargetIdsSet
                            )
                          )
                        }
                        inuranceCededLayers =
                          this.animatedScenarioService.removeDuplicateInuranceSources(
                            inuranceCededLayers
                          )
                      }
                      return this.animatedScenarioService
                        .cloneStructureWithSharedLimit(
                          structure?.id,
                          `${
                            structure?.label
                          } - Loss Scenario - ${new Date().getTime()}`,
                          structure?.description ? structure?.description : '',
                          {
                            scenarioEvents: events,
                            eventIDs,
                          },
                          inuranceCededLayers
                        )
                        .pipe(
                          map(r => {
                            if (!r.error && r.data) {
                              return {
                                ...r,
                                data: {
                                  ...r.data,
                                  eventIDs,
                                  portfolioResponse,
                                },
                              }
                            } else {
                              return { ...r }
                            }
                          })
                        ) as ApiResponse<{
                        program: Program
                        cededLayers: LogicalPortfolioLayer[]
                        portfolioIDs: PortfoliosIDAndName
                        eventIDs: number[]
                        portfolioResponse: Portfolio
                      }>
                    })
                  )
                }
              })
            )
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      mergeMapWithInput(
        ([
          { cededLayers, portfolioIDs, eventIDs, portfolioResponse },
          [_, events, structure],
        ]) => {
          let combinedVisibleLayerIds: string[] = []
          let visibleRiskXolIds: string[] = []
          let visibleMultiSectionLayerIds: string[] = []
          const multiSectionMainLayers = cededLayers.filter(
            layer =>
              layer.meta_data.sage_layer_subtype === 'main-layer' &&
              (layer.meta_data.sage_layer_type === 'cat_multisection' ||
                layer.meta_data.sage_layer_type === 'noncat_multisection')
          )
          const riskXOL = cededLayers.filter(isLayerActualRisk)
          if (riskXOL.length > 0) {
            visibleRiskXolIds = riskXOL.map(
              l => l.meta_data.riskVisibleLayerID as string
            )
          }
          if (multiSectionMainLayers.length > 0) {
            visibleMultiSectionLayerIds = multiSectionMainLayers.map(
              l => l.meta_data.visible_layer_id as string
            )
          }
          combinedVisibleLayerIds = [
            ...visibleRiskXolIds,
            ...visibleMultiSectionLayerIds,
          ]
          if (combinedVisibleLayerIds.length > 0) {
            return this.analyzereService
              .fetchLayers<LogicalPortfolioLayer>(combinedVisibleLayerIds)
              .pipe(
                mergeMap(r => {
                  if (r.error) {
                    return of({ error: r.error })
                  } else {
                    return this.animatedScenarioService.processScenarioEvents(
                      [...cededLayers, ...r.data!],
                      portfolioIDs,
                      structure!.analysisID,
                      eventIDs,
                      events
                    )
                  }
                })
              )
          } else {
            return this.animatedScenarioService.processScenarioEvents(
              cededLayers,
              portfolioIDs,
              structure!.analysisID,
              eventIDs,
              events
            )
          }
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      concatMap(([eventResults, [{ program, portfolioResponse }]]) => {
        let newScenarioEventsResult: ScenarioEventResult[] = []
        if (eventResults && eventResults.length > 0) {
          newScenarioEventsResult = eventResults.map(item => {
            const newItem = { ...item }
            newItem.layers = item.layers.filter(itemLayer =>
              portfolioResponse.layers.some(portfolioLayer => {
                const castedPortfolioLayer =
                  portfolioLayer as LogicalPortfolioLayer
                if (
                  castedPortfolioLayer.meta_data.sage_layer_subtype ===
                    'main-layer' &&
                  (castedPortfolioLayer.meta_data.sage_layer_type ===
                    'cat_multisection' ||
                    castedPortfolioLayer.meta_data.sage_layer_type ===
                      'noncat_multisection')
                ) {
                  return (
                    castedPortfolioLayer.meta_data.visible_layer_id ===
                    itemLayer.layer.meta_data.fromLayerID
                  )
                } else if (
                  castedPortfolioLayer.meta_data.sage_layer_subtype ===
                    'actual' &&
                  castedPortfolioLayer.meta_data.sage_layer_type ===
                    'noncat_risk'
                ) {
                  return (
                    castedPortfolioLayer.meta_data.riskVisibleLayerID ===
                    itemLayer.layer.meta_data.fromLayerID
                  )
                } else {
                  return (
                    castedPortfolioLayer.id ===
                    itemLayer.layer.meta_data.fromLayerID
                  )
                }
              })
            )
            return newItem
          })
        }
        return [
          fromAnimatedScenariosActions.executeSuccess(),
          fromScenarioEventResultActions.addAllScenarioEventResults({
            eventResults: newScenarioEventsResult,
            scenarioStructure: program,
            eventResultsForDisplay: eventResults,
          }),
        ]
      })
    )
  )
}
