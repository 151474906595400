import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface AddNameDialogData {
  actionName?: string
  entityName?: string
  currentName?: string
  otherNames?: string[]
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AnyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    )
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-name-dialog',
  styles: [
    `
      .full-width {
        width: 100%;
      }

      .duplicate-error {
        font-size: small;
      }

      h1 {
        margin-bottom: 20px;
      }

      input {
        padding-top: 5px;
        padding-bottom: 8px;
      }

      .actions {
        padding: 0 24px;
      }

      :host ::ng-deep .mat-mdc-dialog-content {
        padding: 0 24px;
      }

      :host ::ng-deep .mdc-text-field {
        padding: 0 !important;
      }

      :host ::ng-deep .mat-mdc-input-element::placeholder {
        color: var(--subtle);
      }

      :host ::ng-deep .mdc-line-ripple::before {
        display: block;
      }

      :host ::ng-deep .mdc-line-ripple::after {
        display: block;
      }

      /* when not focused use darker red */
      :host:has(mat-error) ::ng-deep.mdc-line-ripple::before {
        color: #b03027 !important;
      }

      /* when focused use lighter red, for ripple animation */
      :host:has(mat-error) ::ng-deep.mdc-line-ripple::after {
        color: #f44336 !important;
      }

      :host ::ng-deep .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    `,
  ],
  template: `
    <h1 mat-dialog-title>{{ title }}</h1>

    <div mat-dialog-content>
      <mat-form-field floatLabel="always" class="full-width">
        <mat-label>Peer Group Name</mat-label>
        <input
          #input
          matInput
          required
          [formControl]="name"
          [errorStateMatcher]="matcher"
          [placeholder]="inputLabel"
          [maxLength]="50"
          (keydown.enter)="onSave()"
        />
        <mat-error *ngIf="name.hasError('required')"
          >Name is required.</mat-error
        >
        <mat-error *ngIf="name.hasError('duplicateName')"
          >Name must be unique.</mat-error
        >
      </mat-form-field>

      <div
        class="duplicate-error"
        *ngIf="
          duplicate &&
          !name.invalid &&
          duplicateLength === name.value.length &&
          duplicateName === name.value
        "
      >
        <mat-error>Duplicate Name.</mat-error>
      </div>
    </div>

    <div mat-dialog-actions class="actions">
      <button
        appButton
        accent
        type="submit"
        [disabled]="name.invalid"
        (click)="onSave()"
      >
        {{ actionName }}
      </button>
      <button appButton primary link mat-dialog-close>Cancel</button>
    </div>
  `,
})
export class AddNameDialogComponent
  implements AfterViewInit, AfterContentChecked, OnInit
{
  @ViewChild('input', { static: false })
  inputElement: ElementRef<HTMLInputElement>

  name: FormControl
  matcher = new AnyErrorStateMatcher()

  actionName: string
  entityName: string
  otherNames: string[]

  duplicate = false
  duplicateLength: number
  duplicateName: string

  get title(): string {
    const suffix = this.entityName ? ` ${this.entityName}` : ''
    return `${this.actionName}${suffix}`
  }

  get inputLabel(): string {
    const prefix = this.entityName ? `${this.entityName} ` : ''
    return `${prefix}Name`
  }

  constructor(
    public dialogRef: MatDialogRef<AddNameDialogComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AddNameDialogData
  ) {
    // this.duplicate = false
    this.actionName = data.actionName || 'Add'
    this.entityName = data.entityName || ''

    this.name = new FormControl(data.currentName || '', [
      Validators.required,
      uniqueNameValidator(data.otherNames),
    ])
  }

  ngAfterViewInit() {
    if (this.data.currentName) {
      this.inputElement?.nativeElement?.select()
    }
  }

  ngOnInit(): void {
    this.cdr.detach()
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges()
  }

  onSave() {
    if (!this.name.invalid) {
      this.dialogRef.close(this.name.value)
    }
  }
}

function uniqueNameValidator(otherNames: string[] = []): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = ((control.value as string) || '').toLowerCase()
    const unique = otherNames.every(name => name.toLowerCase() !== value)
    return unique ? null : { duplicateName: { value } }
  }
}
