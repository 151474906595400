import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './grouper-view.actions'

const SLIDE_INDEX_THROTTLE_TIME = 500

export interface GrouperViewState {
  slideIndex: number
  slideIndexTimestamp: number
  slidesPerView: number
  towerSizePercentage: number
}

export const initialState: GrouperViewState = {
  slideIndex: 0,
  slideIndexTimestamp: 0,
  slidesPerView: 3,
  towerSizePercentage: 1.0,
}

const grouperViewReducer = createReducer(
  initialState,

  on(fromActions.setGrouperSlideIndex, (state, { index, force }) => {
    const slideIndexTimestamp = new Date().getTime()
    if (force) {
      // Force update regardless of timestamp
      return {
        ...state,
        slideIndex: index,
        slideIndexTimestamp,
      }
    }
    if (
      state.slideIndexTimestamp <
      slideIndexTimestamp - SLIDE_INDEX_THROTTLE_TIME
    ) {
      // Only update index if throttle time has passed since last priority set
      return {
        ...state,
        slideIndex: index,
        slideIndexTimestamp,
      }
    }
    // No change since not forced and within 200ms of last set
    return state
  }),

  on(fromActions.setGrouperSlidesPerView, (state, { value }) => ({
    ...state,
    slidesPerView: value,
  })),

  on(fromActions.setGrouperTowerSizePercentage, (state, { percentage }) => ({
    ...state,
    towerSizePercentage: percentage,
  }))
)

export function reducer(state: GrouperViewState | undefined, action: Action) {
  return grouperViewReducer(state, action)
}
