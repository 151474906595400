import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../../../nav.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-compare-header',
  styleUrls: ['./group-compare-header.component.scss'],
  templateUrl: './group-compare-header.component.html',
})
export class GroupCompareHeaderComponent {
  @Input() name: 'Group' | 'Compare'
  @Input() saveLabel = 'Save'
  @Input() disableSave = false
  @Input() hideSave = false
  @Input() activeAction: string

  @Output() saveClick = new EventEmitter()
  @Output() addSharedLayerClick = new EventEmitter()
  @Output() deleteSharedLayerClick = new EventEmitter()

  constructor(public navService: NavService, private router: Router) {}

  get title(): string {
    return this.name
  }

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }

  getSubtitle(): string {
    let response = ''
    if (this.name === 'Group') {
      response = 'SAGE Custom Groups, Shared Limits and Inurance Relationships unlock sophisticated risk modeling capabilities.'
    }
    if (this.name === 'Compare') {
      response = 'Compare risk transfer models and tap into new quantitative insights for optimized decisions.'
    }
    return response
  }

  onSaveClick($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    if (!this.disableSave) {
      this.saveClick.emit()
    }
  }
}
