import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { layerIds } from '../../../model/layer-palette.model'
import { SimpleLayer } from '../../../model/layers.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-list',
  styleUrls: [`./layer-list.component.scss`],
  templateUrl: `./layer-list.component.html`,
})
export class LayerListComponent {
  @Input() layerListId: string
  @Input() layerList: SimpleLayer[] | null
  @Input() groupValue: string
  @Output() selectLayer = new EventEmitter<string>()

  layerIds = layerIds

  clickSelectLayer(id: string): void {
    this.selectLayer.emit(id)
  }

  trackByFn(i: number): number {
    return i
  }
}
