// import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import * as fromReinsurer from './reinsurer/reinsurer.reducer'
import * as fromActions from './quote.actions'
import * as fromSection from './section/section.reducer'
import { startAnalysis } from '../../analysis/store/analysis.actions'
import { Bureaus, ExternalVendorMapping } from '../../api/model/quote.model'
import { Territories } from '../../api/territory/territory.service'
import * as GrouperActions from '../../analysis/store/grouper/grouper.actions'
import { Layer } from '../../analysis/model/layers.model'
import { PortfolioSetID } from '../../analysis/model/portfolio-set.model'
import {
  RenewedFromRiskAndSection,
  QuoteCustomCompareView,
} from '../models/quote.model'
import * as fromQuoteMI from '../management-information/store/quote-management-information.reducer'
import * as fromReinsurersActions from './reinsurer/reinsurer.actions'
import * as fromQuickQuote from './quick-quote/quick-quote.reducer'
import {
  saveQuickQuote,
  saveQuickQuoteSuccess,
} from './quick-quote/quick-quote.action'
import { RiskRefWithSections } from 'src/app/api/model/backend.model'
export const QUOTE_FEATURE_KEY = 'quote'

export interface State {
  reinsurers: fromReinsurer.State
  sections: fromSection.State
  quoteManagementInformation: fromQuoteMI.QuoteManagementState
  sharedLimitLayers: Layer[] | null
  sharedLimitPortfolioID: PortfolioSetID | null
  clientID?: string | null
  studyID?: string | null
  yearID?: string | null
  structureID?: string | null
  cededLayerID?: string | null
  cededLayerName?: string | null
  cededLayerType?: string | null
  sectionID?: string | null
  riskID?: string | null
  bureaus: Bureaus[]
  territories: Territories
  structureGroupID?: string | null
  structureGroupName?: string | null
  isGroupSelected: boolean
  sharedLimitID?: string | null
  sharedLimitName?: string | null
  isSLSelected: boolean
  isLoading: boolean
  isAutoBuildLoading: boolean
  renewedFromRisks: RenewedFromRiskAndSection[]
  externalVendor: ExternalVendorMapping | null
  quickQuote: fromQuickQuote.State
  hasAutoBuildLayerDataChanged: boolean
  autoBuildSectionsForSelectedLayer?: RiskRefWithSections
  customCompareViews: QuoteCustomCompareView[]
  selectedCompareView: QuoteCustomCompareView | null
}

const initialState: State = {
  reinsurers: fromReinsurer.initialState,
  sections: fromSection.initialState,
  quoteManagementInformation: fromQuoteMI.initialState,
  sharedLimitLayers: null,
  sharedLimitPortfolioID: null,
  clientID: null,
  studyID: null,
  yearID: null,
  structureID: null,
  cededLayerID: null,
  cededLayerName: null,
  cededLayerType: null,
  sectionID: null,
  riskID: null,
  bureaus: [],
  territories: {
    countryAbbreviations: [],
    usStates: [],
  },
  structureGroupID: null,
  structureGroupName: null,
  isGroupSelected: false,
  sharedLimitID: null,
  sharedLimitName: null,
  isSLSelected: false,
  isLoading: false,
  isAutoBuildLoading: false,
  renewedFromRisks: [],
  externalVendor: null,
  quickQuote: fromQuickQuote.initialState,
  hasAutoBuildLayerDataChanged: false,
  customCompareViews: [],
  selectedCompareView: null,
}

const clientIDReducer = createReducer(
  initialState.clientID,
  on(fromActions.startQuote, (_, { clientID }) => clientID),
  on(fromActions.startGroupQuote, (_, { clientID }) => clientID),
  on(fromActions.startSLQuote, (_, { clientID }) => clientID),
  on(startAnalysis, _ => initialState.clientID)
)
const studyIDReducer = createReducer(
  initialState.studyID,
  on(fromActions.startQuote, (_, { studyID }) => studyID),
  on(fromActions.startGroupQuote, (_, { studyID }) => studyID),
  on(fromActions.startSLQuote, (_, { studyID }) => studyID),
  on(startAnalysis, _ => initialState.studyID)
)
const yearIDReducer = createReducer(
  initialState.yearID,
  on(fromActions.startQuote, (_, { yearID }) => yearID),
  on(fromActions.startGroupQuote, (_, { yearID }) => yearID),
  on(fromActions.startSLQuote, (_, { yearID }) => yearID),
  on(startAnalysis, _ => initialState.yearID)
)
const structureIDReducer = createReducer(
  initialState.structureID,
  on(fromActions.fetchOrSaveQuoteData, (_, { structureID }) => structureID),
  on(fromActions.startQuote, (_, { structureID }) => structureID),
  on(fromActions.startGroupQuote, (_, { structureID }) => structureID),
  on(fromActions.startSLQuote, (_, { structureID }) => structureID),
  on(startAnalysis, _ => initialState.structureID)
)

const cededLayerIDReducer = createReducer(
  initialState.cededLayerID,
  on(fromActions.startQuote, (_, { cededLayerID }) => cededLayerID),
  on(startAnalysis, _ => initialState.cededLayerID),
  on(fromActions.startGroupQuote, _ => initialState.cededLayerID),
  on(fromActions.startSLQuote, _ => initialState.cededLayerID)
)

const cededLayerNameReducer = createReducer(
  initialState.cededLayerName,
  on(fromActions.startQuote, (_, { cededLayerName }) => cededLayerName),
  on(startAnalysis, _ => initialState.cededLayerName),
  on(fromActions.startGroupQuote, _ => initialState.cededLayerName),
  on(fromActions.startSLQuote, _ => initialState.cededLayerName)
)

const cededLayerTypeReducer = createReducer(
  initialState.cededLayerType,
  on(fromActions.startQuote, (_, { cededLayerType }) => cededLayerType),
  on(startAnalysis, _ => initialState.cededLayerType),
  on(fromActions.startGroupQuote, _ => initialState.cededLayerType),
  on(fromActions.startSLQuote, _ => initialState.cededLayerType)
)

const structureGroupIDReducer = createReducer(
  initialState.structureGroupID,
  on(fromActions.startQuote, (_, { structureGroupID }) => structureGroupID),
  on(
    fromActions.startGroupQuote,
    (_, { structureGroupID }) => structureGroupID
  ),
  on(fromActions.startSLQuote, (_, { structureGroupID }) => structureGroupID)
)

const structureGroupNameReducer = createReducer(
  initialState.structureGroupName,
  on(fromActions.startQuote, (_, { structureGroupName }) => structureGroupName),
  on(
    fromActions.startGroupQuote,
    (_, { structureGroupName }) => structureGroupName
  ),
  on(
    fromActions.startSLQuote,
    (_, { structureGroupName }) => structureGroupName
  )
)

const isGroupSelectedReducer = createReducer(
  initialState.isGroupSelected,
  on(fromActions.startQuote, () => initialState.isGroupSelected),
  on(fromActions.startSLQuote, () => initialState.isGroupSelected),
  on(fromActions.startGroupQuote, (_, { structureGroupID }) => {
    if (structureGroupID && structureGroupID !== null) {
      return true
    }
    return false
  })
)

const sharedLimitIDReducer = createReducer(
  initialState.sharedLimitID,
  on(fromActions.startQuote, (_, { sharedLimitID }) => sharedLimitID),
  on(fromActions.startGroupQuote, (_, { sharedLimitID }) => sharedLimitID),
  on(fromActions.startSLQuote, (_, { sharedLimitID }) => sharedLimitID)
)

const sharedLimitNameReducer = createReducer(
  initialState.structureGroupName,
  on(fromActions.startQuote, (_, { sharedLimitName }) => sharedLimitName),
  on(fromActions.startGroupQuote, (_, { sharedLimitName }) => sharedLimitName),
  on(fromActions.startSLQuote, (_, { sharedLimitName }) => sharedLimitName)
)

const isSLSelectedReducer = createReducer(
  initialState.isSLSelected,
  on(fromActions.startQuote, () => initialState.isSLSelected),
  on(fromActions.startGroupQuote, () => initialState.isSLSelected),
  on(fromActions.startSLQuote, (_, { sharedLimitID }) => {
    if (sharedLimitID && sharedLimitID !== null) {
      return true
    }
    return false
  })
)

const sectionIDReducer = createReducer(
  initialState.sectionID,
  on(
    fromActions.setSectionID,
    // tslint:disable-next-line: no-non-null-assertion
    (_, { sectionID }) => {
      return sectionID
    }
  ),
  on(startAnalysis, _ => initialState.sectionID),
  on(fromActions.startGroupQuote, _ => initialState.sectionID),
  on(fromActions.startSLQuote, _ => initialState.sectionID)
)

const riskIDReducer = createReducer(
  initialState.riskID,
  on(
    fromActions.fetchQuoteRiskBystructureIDSuccess,
    fromActions.saveQuoteRiskBystructureIDSuccess,
    fromActions.fetchQuoteRiskByprogramIDSuccess,
    fromActions.saveQuoteRiskByprogramIDSuccess,
    // tslint:disable-next-line: no-non-null-assertion
    (_, { risk }) => risk.id!.toString()
  ),
  on(startAnalysis, _ => initialState.riskID)
)

const bureausReducer = createReducer(
  initialState.bureaus,
  on(fromActions.fetchBureausSuccess, (_, { bureaus }) => {
    return bureaus
  }),
  on(startAnalysis, _ => initialState.bureaus)
)

const isLoadingReducer = createReducer(
  initialState.isLoading,
  on(
    GrouperActions.resetGrouper,
    fromActions.startQuote,
    fromActions.startGroupQuote,
    fromActions.startSLQuote,
    fromActions.fetchQuoteFieldsForSelectedRiskAndSection, // triggered on 'Link Expiring'
    saveQuickQuote,
    () => true
  ),
  on(fromActions.loadingSuccess, saveQuickQuoteSuccess, _ => false)
)

const isAutoBuildLoadingReducer = createReducer(
  initialState.isAutoBuildLoading,
  on(fromActions.fetchAutoBuildExpiringDetails, () => true),
  on(fromReinsurersActions.addOrUpdateQuoteReinsurerSuccess, _ => false)
)

const sharedLimitLayersReducer = createReducer(
  initialState.sharedLimitLayers,
  on(
    startAnalysis,
    fromActions.startQuote,
    fromActions.startGroupQuote,
    fromActions.startSLQuote,
    _ => initialState.sharedLimitLayers
  ),
  on(
    fromActions.fetchLayersForSharedLimitSuccess,
    (_, { sharedLimitLayers }) => {
      return sharedLimitLayers
    }
  )
)

const sharedLimitPortfolioIDReducer = createReducer(
  initialState.sharedLimitPortfolioID,
  on(
    startAnalysis,
    fromActions.startQuote,
    fromActions.startGroupQuote,
    fromActions.startSLQuote,
    _ => initialState.sharedLimitPortfolioID
  ),
  on(
    fromActions.fetchLayersForSharedLimitSuccess,
    (_, { sharedLimitPortfolioID }) => {
      return sharedLimitPortfolioID
    }
  )
)

const renewedFromRisksReducer = createReducer(
  initialState.renewedFromRisks,
  on(
    fromActions.fetchDetailsForRenewedOpportunitySuccess,
    (_, { renewedFromRisks }) => renewedFromRisks
  )
)

const territoryReducer = createReducer(
  initialState.territories,
  on(fromActions.fetchTerritoriesSuccess, (_, { territories }) => territories)
)

const externalVendorReducer = createReducer(
  initialState.externalVendor,
  on(fromActions.fetchExternalVendorSuccess, (_, { vendor }) => vendor)
)

const hasAutoBuildLayerDataChangedReducer = createReducer(
  initialState.hasAutoBuildLayerDataChanged,
  on(
    fromActions.startQuote,
    (_, { isLayerAutoBuild, isLayerChangedInDesign }) =>
      (isLayerAutoBuild && isLayerChangedInDesign) ?? false
  )
)

const autoBuildSectionsForSelectedLayerReducer = createReducer(
  initialState.autoBuildSectionsForSelectedLayer,
  on(
    fromActions.startQuote,
    (_, { autoBuildSectionsForSelectedLayer }) =>
      autoBuildSectionsForSelectedLayer
  )
)

const customCompareViewReducer = createReducer(
  initialState.customCompareViews,
  on(
    fromActions.fetchQuoteComparisonsBySectionIdSuccess,
    (_, { quoteComparisons }) => quoteComparisons
  )
)

const selectedCompareViewReducer = createReducer(
  initialState.selectedCompareView,
  on(fromActions.updateSelectedCompareView, (_, { view }) => view)
)

const quoteReducer = combineReducers<State>({
  reinsurers: fromReinsurer.reducer,
  sections: fromSection.reducer,
  quoteManagementInformation: fromQuoteMI.reducer,
  sharedLimitLayers: sharedLimitLayersReducer,
  sharedLimitPortfolioID: sharedLimitPortfolioIDReducer,
  clientID: clientIDReducer,
  studyID: studyIDReducer,
  yearID: yearIDReducer,
  structureID: structureIDReducer,
  cededLayerID: cededLayerIDReducer,
  cededLayerName: cededLayerNameReducer,
  cededLayerType: cededLayerTypeReducer,
  sectionID: sectionIDReducer,
  riskID: riskIDReducer,
  bureaus: bureausReducer,
  territories: territoryReducer,
  structureGroupID: structureGroupIDReducer,
  structureGroupName: structureGroupNameReducer,
  isGroupSelected: isGroupSelectedReducer,
  sharedLimitID: sharedLimitIDReducer,
  sharedLimitName: sharedLimitNameReducer,
  isSLSelected: isSLSelectedReducer,
  isLoading: isLoadingReducer,
  isAutoBuildLoading: isAutoBuildLoadingReducer,
  renewedFromRisks: renewedFromRisksReducer,
  externalVendor: externalVendorReducer,
  quickQuote: fromQuickQuote.reducer,
  hasAutoBuildLayerDataChanged: hasAutoBuildLayerDataChangedReducer,
  autoBuildSectionsForSelectedLayer: autoBuildSectionsForSelectedLayerReducer,
  customCompareViews: customCompareViewReducer,
  selectedCompareView: selectedCompareViewReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return quoteReducer(state, action)
}
