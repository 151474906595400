import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AutoGroupLossSetContainerComponent } from '../auto-group-loss-sets-container.component'

@Injectable()
export class AutoGroupLossSetsDialogService {
  constructor(public dialog: MatDialog) {}

  open() {
    return this.dialog.open(AutoGroupLossSetContainerComponent, {
      width: '50vw',
    })
  }
}
