import { GroupSummaryRequest } from './../explore.model';
import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { forkJoin, of } from 'rxjs'
import {
  concatMap,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
  tap,
  catchError,
} from 'rxjs/operators'
import { CombinedLossDistributionRow } from 'src/app/core/model/loss-set-table.model'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { rejectError } from '../../../api/util'
import * as fromBroker from '../../../core/store/broker/broker.selectors'
import { AppState } from '../../../core/store'
import {
  selectCurrentCurrency,
  selectCurrentLossDistributionDataTable,
  selectExploreLossSetGroups,
  selectExploreModifiers,
  selectExplorePortfolioSetID,
  selectExploreProgramAnalysisID,
  selectExploreRP,
  selectExploreSummaryRP,
  selectGrossPortfolioViewID,
  selectLossSetGroups,
  selectParentGrossLossSetLayers,
} from '../../store/analysis.selectors'
import {
  addLossSetIDs,
  deleteSummaryFilterView,
  deleteSummaryFilterViewFailure,
  deleteSummaryFilterViewSuccess,
  exploreGrossXlsx,
  fetchSummaryViews,
  getExploreLossSetLayersAndGroups,
  getGrossPortfolioViewID,
  getGrossPortfolioViewIDFailure,
  getGrossPortfolioViewIDSuccess,
  getGroupSummaryDataCollection,
  getLossDataCollection,
  getLossDataCollectionError,
  getLossDataCollectionSuccess,
  getSummaryData,
  getSummaryDataCollection,
  getSummaryDataFailure,
  getSummaryDataSuccess,
  getSummaryGroupData,
  getSummaryGroupDataSuccess,
  saveSummaryFilterView,
  saveSummaryFilterViewFailure,
  setExploreProgram,
  updateLossDataModifiers,
  updateLossDataModifiersSuccess,
  updateSummaryFilterView,
  updateSummaryFilterViewFailure,
  updateSummaryLoading,
} from './explore.actions'
import { convertToTableData } from './explore.converter'
import { LogicalPortfolioLayer, Metrics, PhysicalPortfolioLayer } from 'src/app/api/analyzere/analyzere.model'
import { ApiResponse } from 'src/app/api/model/api.model'
import { VaRTVaR } from 'src/app/analysis/model/metrics.model'
import { selectCurrentClient } from '../../../core/store/broker/broker.selectors'
import { selectAccountOpportunities } from 'src/app/core/store/accountopportunity.selectors'
import { selectCurrentStructure } from '../../../core/store/program/program.selectors'
import { DatePipe } from '@angular/common'
import { GrossLossTableExportXlsxService } from '../export/gross-loss-table-export-xlsx.service'
import { getExploreSummaryAPIs, getSummaryResponse } from '../explore.util'
import { BackendService } from 'src/app/api/backend/backend.service'
import { ExploreSummaryView } from 'src/app/api/model/backend.model'
import { LARGE_FREQ_SINK_LAYER } from '../../model/layers.model';
import { LossSetLayer } from '../../model/loss-set-layers.model';
@Injectable()
export class ExploreEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private backendService: BackendService,
    private datePipe: DatePipe,
    private grossTableExportXlsxService: GrossLossTableExportXlsxService
  ) {}

  fetchByGrossPortfolio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setExploreProgram),
      withLatestFrom(
        this.store.pipe(select(selectParentGrossLossSetLayers)),
        this.store.pipe(select(selectLossSetGroups))
      ),
      concatMap(data => {
        this.store.dispatch(fetchSummaryViews({ programId: Number(data[0].program.studyID) }))
        return [
          getExploreLossSetLayersAndGroups({
            lossSetLayers: data[1],
            lossSetLayerGroups: data[2],
          }),
          getGrossPortfolioViewID(),
        ]
      }
    )
    )
  })

  getGrossView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getGrossPortfolioViewID),
      map(({ type, ...props }) => props),
      withLatestFrom(this.store.pipe(select(selectExplorePortfolioSetID))),
      switchMap(([_props, portfolioSetID]) => {
        // tslint:disable-next-line:no-non-null-assertion
        const ids = portfolioSetID!
        return this.service.getGrossPortfolioView(
          ids.grossPortfolioID,
          ids.analysisProfileID
        )
      }),
      rejectError(error =>
        this.store.dispatch(getGrossPortfolioViewIDFailure({ error }))
      ),
      map(grossPortfolioView => {
        return getGrossPortfolioViewIDSuccess({ grossPortfolioView })
      })
    )
  })

  // Add multiple loss set layers and groups
  addLossSetIDs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addLossSetIDs),
      withLatestFrom(
        this.store.pipe(select(selectExploreProgramAnalysisID)),
        this.store.pipe(select(selectCurrentCurrency)),
        this.store.pipe(select(selectExploreLossSetGroups))
      ),
      concatMap(([data, analysisID, currency, lossGroups]) => {
        const layersIDs: string[] = []

        // Collect individual layers.
        data?.lossSetLayers?.forEach(ls => {
          layersIDs.push(ls.lossID)
        })

        // Collect layers from each loss set group.
        data?.lossSetGroups?.forEach(lsG => {
          // tslint:disable-next-line: no-non-null-assertion
          const lossSetGroup = lossGroups.find(l => l.id === lsG.lossID)!
          lossSetGroup.lossSetLayers.map(m => layersIDs.push(m.id))
        })

        return (
          this.service
            // tslint:disable-next-line: no-non-null-assertion
            .postLayersViews(layersIDs, analysisID!, [currency!])
            .pipe(
              map(res => ({
                ...res,
                ...data,
              }))
            )
        )
      }),
      map(response => {
        if (response.error) {
          getLossDataCollectionError({ error: response.error })
        } else {
          const lossSetArray: {
            lossID: string
            viewID: string
          }[] = []
          response.data?.forEach((d: { layerID: any; id: any }) => {
            lossSetArray.push({ lossID: d.layerID, viewID: d.id })
          })
          return {
            lossSetArray,
            lossSetGroups: response.lossSetGroups,
            lossSetLayers: response.lossSetLayers,
          }
        }
      }),
      withLatestFrom(this.store.pipe(select(selectExploreProgramAnalysisID))),
      concatMap(([data, analysisID]) => {
        const lossSetArray1 = data?.lossSetArray || []
        const lossSetLayers: string[] = []
        lossSetArray1.map(m => lossSetLayers.push(m.viewID))
        // tslint:disable-next-line: no-non-null-assertion
        return this.service
          .postPortfolioViewAdhoc(lossSetLayers, analysisID || '')
          .pipe(
            map(res => ({
              ...res,
              lossSetArray: data?.lossSetArray,
              lossSetGroups: data?.lossSetGroups,
              lossSetLayers: data?.lossSetLayers,
            }))
          )
      }),
      map(response => {
        if (response.error) {
          return getLossDataCollectionError({ error: response.error })
        } else {
          const lossSetGroups = response.lossSetGroups?.map(l => ({
            ...l,
            lossID: l.lossID || '',
            viewID: response.data || '',
            lossType: 'Group',
            lossName: l.lossName || '',
            filterValue: l.filterValue || 'all',
            isLossRatioView: l.isLossRatioView || false,
            subjectPremiumAmt: l.subjectPremiumAmt || 0,
          }))
          const lossSetLayers = response.lossSetLayers?.map(l => {
            const viewID = response.lossSetArray?.find(
              y => y.lossID === l.lossID
            )?.viewID
            return {
              ...l,
              lossID: l.lossID || '',
              viewID: viewID || '',
              lossName: l.lossName || '',
              lossType: 'Layer',
            }
          })
          return getLossDataCollection({
            viewID: response.data,
            lossSetGroups: lossSetGroups ?? [],
            lossSetLayers: lossSetLayers ?? [],
          })
        }
      })
    )
  )

  getLossDataCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLossDataCollection),
      withLatestFrom(
        this.store.pipe(select(selectExploreRP)),
        this.store.pipe(select(selectExploreModifiers))
      ),
      mergeMap(([{ lossSetGroups, lossSetLayers }, arrRP, modifiers]) => {
        const exploreApis: ApiResponse<
          Metrics[] & {
            lossSetID: string
            lossType: string
            lossName: string
            rpArray: number[]
            vartvar: VaRTVaR
            lossFilter: string
            isLossRatioView: boolean
            subjectPremiumAmt: number
            aggregationMethod: string
          }
        >[] = []

        lossSetLayers?.forEach(l => {
          const oepMetrics = this.service.getExploreDataValues(
            l.viewID,
            arrRP,
            l.lossType,
            modifiers.perspective,
            'OEP',
            modifiers.vartvar,
            l.filterValue ?? 'all',
            l.lossID,
            l.lossName,
            l.isLossRatioView ?? false,
            l.subjectPremiumAmt || 0
          )

          const aepMetrics = this.service.getExploreDataValues(
            l.viewID,
            arrRP,
            l.lossType,
            modifiers.perspective,
            'AEP',
            modifiers.vartvar,
            l.filterValue ?? 'all',
            l.lossID,
            l.lossName,
            l.isLossRatioView ?? false,
            l.subjectPremiumAmt || 0
          )

          exploreApis.push(oepMetrics)
          exploreApis.push(aepMetrics)
        })

        lossSetGroups?.forEach(l => {
          const oepMetrics = this.service.getExploreDataValues(
            l.viewID,
            arrRP,
            l.lossType,
            modifiers.perspective,
            'OEP',
            modifiers.vartvar,
            l.filterValue ?? 'all',
            l.lossID,
            l.lossName,
            l.isLossRatioView ?? false,
            l.subjectPremiumAmt || 0
          )

          const aepMetrics = this.service.getExploreDataValues(
            l.viewID,
            arrRP,
            l.lossType,
            modifiers.perspective,
            'AEP',
            modifiers.vartvar,
            l.filterValue ?? 'all',
            l.lossID,
            l.lossName,
            l.isLossRatioView ?? false,
            l.subjectPremiumAmt || 0
          )

          exploreApis.push(oepMetrics)
          exploreApis.push(aepMetrics)
        })

        return forkJoin(exploreApis).pipe(
          map(res => ({
            res,
            arrRP,
            modifiers,
            lossSetGroups,
            lossSetLayers,
            error: res[0].error,
          }))
        )
      }),
      map(result => {
        if (result.error) {
          return getLossDataCollectionError({ error: result.error })
        } else {
          const payload: {
            dataTable: CombinedLossDistributionRow[]
            lossID: string
            lossType: string
            lossName: string
            filterValue: string
            isLossRatioView?: boolean
            subjectPremiumAmt?: number
          }[] = []
          result.lossSetGroups?.forEach(l => {
            const results = result.res.filter(
              y => y.data?.lossSetID === l.lossID
            )
            const oep = results?.find(y => y.data?.aggregationMethod === 'OEP')
            const aep = results?.find(y => y.data?.aggregationMethod === 'AEP')
            const dataTable: CombinedLossDistributionRow[] = convertToTableData(
              oep?.data ?? [],
              oep?.data?.rpArray ?? [],
              result.modifiers.vartvar,
              l.subjectPremiumAmt || 0,
              aep?.data ?? []
            )
            payload.push({
              dataTable,
              lossID: l.lossID,
              lossType: l.lossType,
              lossName: l.lossName,
              filterValue: l.filterValue,
              isLossRatioView: l.isLossRatioView,
              subjectPremiumAmt: l.subjectPremiumAmt,
            })
          })

          result.lossSetLayers?.forEach(l => {
            const results = result.res.filter(
              y => y.data?.lossSetID === l.lossID
            )
            const oep = results?.find(y => y.data?.aggregationMethod === 'OEP')
            const aep = results?.find(y => y.data?.aggregationMethod === 'AEP')
            const dataTable: CombinedLossDistributionRow[] = convertToTableData(
              oep?.data ?? [],
              oep?.data?.rpArray ?? [],
              result.modifiers.vartvar,
              l.subjectPremiumAmt || 0,
              aep?.data ?? []
            )
            payload.push({
              dataTable,
              lossID: l.lossID,
              lossType: l.lossType,
              lossName: l.lossName,
              filterValue: l.filterValue,
              isLossRatioView: l.isLossRatioView,
              subjectPremiumAmt: l.subjectPremiumAmt,
            })
          })

          return getLossDataCollectionSuccess({
            payload,
          })
        }
      })
    )
  )

  updateLossDataModifiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateLossDataModifiers),
      withLatestFrom(
        this.store.pipe(select(selectCurrentLossDistributionDataTable))
      ),
      concatMap(([_, entries]) => {
        const actions: Action[] = []
        if (entries) {
          entries.forEach(e => {
            if (e && e.lossType === 'Layer' && e.lossID) {
              actions.push(
                addLossSetIDs({
                  lossSetLayers: [
                    {
                      lossID: e.lossID,
                      lossName: e.lossName,
                      filterValue: e.filterValue,
                      isLossRatioView: _.isLossRatioView || false,
                      subjectPremiumAmt: e.subjectPremiumAmt,
                    },
                  ],
                })
              )
            } else if (e && e.lossType === 'Group' && e.lossID) {
              actions.push(
                addLossSetIDs({
                  lossSetGroups: [
                    {
                      lossID: e.lossID,
                      lossName: e.lossName,
                      filterValue: e.filterValue,
                      isLossRatioView: _.isLossRatioView || false,
                      subjectPremiumAmt: e.subjectPremiumAmt,
                    },
                  ],
                })
              )
            }
          })
          actions.push(updateLossDataModifiersSuccess())
        }
        return actions
      })
    )
  })

  exportAsXlsx$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exploreGrossXlsx),
        map(
          ({
            lossSetDataTable,
            aggregationMethodType,
            vartvarType,
            perspectiveType,
            occurrenceRows,
            aggregateRows,
            excelLossRatioRows,
            grossOccurrenceResultsOtherValues,
            grossAggregateResultsOtherValues,
            grossRatiosOtherValues,
            view,
          }) => {
            return {
              lossSetDataTable,
              aggregationMethodType,
              vartvarType,
              perspectiveType,
              occurrenceRows,
              aggregateRows,
              excelLossRatioRows,
              grossOccurrenceResultsOtherValues,
              grossAggregateResultsOtherValues,
              grossRatiosOtherValues,
              view,
            }
          }
        ),
        withLatestFrom(
          this.store.pipe(select(selectCurrentClient)),
          this.store.pipe(select(fromBroker.selectCurrentStudy)),
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(selectCurrentStructure)),
          this.store.pipe(select(selectCurrentCurrency))
        ),
        tap(
          ([
            {
              lossSetDataTable,
              aggregationMethodType,
              vartvarType,
              perspectiveType,
              occurrenceRows,
              aggregateRows,
              excelLossRatioRows,
              grossOccurrenceResultsOtherValues,
              grossAggregateResultsOtherValues,
              grossRatiosOtherValues,
              view,
            },
            currentClient,
            currentStudy,
            accountOpportunities,
            currentStructure,
            currentAnalysisCurrency,
          ]) => {
            // effectiveDate is based an accountOpportunity's optional opportunityInceptionDate property
            let effectiveDate = ''
            let analysisCurrentCurrency = ''
            if (currentAnalysisCurrency) {
              analysisCurrentCurrency = currentAnalysisCurrency
            }
            const accOpp = accountOpportunities?.find(
              opp => opp.id === currentStudy?.opportunity_id
            )
            const accOppOnIncepDate = accOpp?.opportunityInceptionDate
            if (accOppOnIncepDate) {
              const parts = accOppOnIncepDate.split('-')
              const date = new Date(
                parseInt(parts[0], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10)
              ).toString()
              effectiveDate = this.datePipe.transform(date, 'longDate') || ''
            }
            const clientName = currentClient?.name ?? '<<clientName>>'
            const structure = currentStructure?.label ?? '<<structure>>'
            // const currency = currentStructure?.structureCurrency?.toString()
            const program = currentStudy?.name ?? '<<program>>'
            const currentDate = this.datePipe.transform(new Date(), 'longDate')
            const fileName = `${clientName} ${program} Explore Gross Details ${currentDate}.xlsx`
            this.grossTableExportXlsxService.exportXlsx(
              fileName,
              clientName,
              structure,
              program,
              lossSetDataTable,
              aggregationMethodType,
              vartvarType,
              perspectiveType,
              effectiveDate,
              occurrenceRows,
              aggregateRows,
              analysisCurrentCurrency,
              excelLossRatioRows,
              grossOccurrenceResultsOtherValues,
              grossAggregateResultsOtherValues,
              grossRatiosOtherValues,
              view
            )
          }
        )
      ),
    {
      dispatch: false,
    }
  )

  // Get Summary Data
  getSummaryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSummaryData),
      withLatestFrom(
        this.store.pipe(select(selectExploreProgramAnalysisID)),
        this.store.pipe(select(selectCurrentCurrency))
      ),
      concatMap(([data, analysisID, currency]) => {
        this.store.dispatch(updateSummaryLoading({ loading: true }))
        const layersIDs: string[] = []
        data?.lossSetLayers?.forEach(ls => {
          layersIDs.push(ls.lossID)
        })
        return (
          this.service
            // tslint:disable-next-line: no-non-null-assertion
            .postLayersViews(layersIDs, analysisID!, [currency!])
            .pipe(
              map(res => ({
                ...res,
                ...data,
              }))
            )
        )
      }),
      map(response => {
        if (response.error) {
          getSummaryDataFailure({ error: response.error })
        } else {
          const lossSetArray: {
            lossID: string
            viewID: string
          }[] = []
          response.data?.forEach((d: { layerID: any; id: any }) => {
            lossSetArray.push({ lossID: d.layerID, viewID: d.id })
          })
          return {
            lossSetArray,
            lossSetLayers: response.lossSetLayers,
          }
        }
      }),
      withLatestFrom(this.store.pipe(select(selectExploreProgramAnalysisID))),
      concatMap(([data, analysisID]) => {
        const lossSetArray1 = data?.lossSetArray || []
        const lossSetLayers: string[] = []
        lossSetArray1.map(m => lossSetLayers.push(m.viewID))
        if (lossSetLayers.length > 0) {
          return this.service
            .postPortfolioViewAdhoc(lossSetLayers, analysisID || '')
            .pipe(
              map(res => ({
                ...res,
                lossSetArray: data?.lossSetArray,
                lossSetLayers: data?.lossSetLayers,
              }))
            )
        }
      }),
      map(response => {
        if (response.error) {
          return getSummaryDataFailure({ error: response.error })
        } else {
          const lossSetLayers = response.lossSetLayers?.map(l => {
            const viewID = response.lossSetArray?.find(
              y => y.lossID === l.lossID
            )?.viewID
            return {
              ...l,
              lossID: l.lossID || '',
              viewID: viewID || '',
              lossName: l.lossName || '',
              lossType: 'Layer',
            }
          })
          return getSummaryDataCollection({
            viewID: response.data,
            lossSetLayers: lossSetLayers ?? [],
          })
        }
      })
    )
  )

  // Get Explore Gross Group Summary Data
  getGroupedSummaryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSummaryGroupData),
      withLatestFrom(
        this.store.pipe(select(selectExploreProgramAnalysisID)),
        this.store.pipe(select(selectCurrentCurrency)),
      ),
      concatMap(([data, analysisID, currency]) => {
        return this.service.postLayer(LARGE_FREQ_SINK_LAYER).pipe(
          map(sinkLayer => ({ data, analysisID, currency, sinkLayer }))
        )
      }),
      concatMap(({data, analysisID, currency, sinkLayer}) => {
        const largeFreqLayer: Partial<LogicalPortfolioLayer> = {
          _type: 'NestedLayer',
          meta_data: {},
          sink: {
            ref_id: sinkLayer.data.id
          },
        }
        const largeLayers: {
          name: string,
          layers: Partial<LossSetLayer>[]
        }[] = []
        data.groups.forEach(group => {
          const layersToAdd: Partial<LossSetLayer>[] = group.layers?.filter(l => l.meta_data.loss_type === 'large')
          if (layersToAdd.length > 0) {
            largeLayers.push({
              name: group.name,
              layers: layersToAdd
            })
          }
        })

        if (largeLayers.length === 0) {
          const updatedData = data
          return of({ updatedData, analysisID, currency })
        }

        const largeFreqLayers = largeLayers.map(l => {
          const sources = l.layers.map(layer => ({
            ref_id: layer.id
          }))
          return ({
            ...largeFreqLayer,
            description: l.name,
            sources
          })
        })
        return this.service.postLayers(largeFreqLayers).pipe(
          map(res => {
            if (!res.error) {
              const newGroups = data.groups.map(g => {
                const newLayer = res.data.find(l => l.description === g.name)
                let largeFrequencyLayerViewID = g.largeFrequencyLayerViewID
                if (newLayer) {
                  largeFrequencyLayerViewID = newLayer.id
                }
                return {
                  ...g,
                  largeFrequencyLayerViewID
                }
              })
              const updatedData = {
                groups: newGroups
              }
              return { updatedData, analysisID, currency}
            }
          })
        )
      }),
      concatMap(({updatedData, analysisID, currency}) => {
        this.store.dispatch(updateSummaryLoading({ loading: true }))
        const groupReqs = updatedData.groups.map(group => {
          const layerIDs = [...group.ids]
          const mainLength = [...layerIDs].length
          if (group.largeFrequencyLayerViewID !== '') {
            layerIDs.push(group.largeFrequencyLayerViewID)
          }
          return this.service
            .postLayersViews(layerIDs, analysisID!, [currency!])
            .pipe(
              map(res => {
                let largeFrequencyLayerViewID = ''
                if (group.largeFrequencyLayerViewID !== '') {
                  largeFrequencyLayerViewID = res.data[mainLength].id
                }
                const viewIDs = res.data.map(d => d.id).filter(d => d !== largeFrequencyLayerViewID)
                return {
                  ...group,
                  ids: viewIDs,
                  subjectPremiumAmt: group.layers.reduce((sum, item) => sum + (item.premium?.value || 0), 0),
                  groupType: 'View',
                  largeFrequencyLayerViewID
                }
              })
            )
        })
        const typeGroups: GroupSummaryRequest[] = []

        updatedData.groups.forEach(group => {
          const attrLayers = group.layers.filter(l => l.meta_data.loss_type === 'attr')
          const attrGroup = {
            name: group.name + '~attr_variance',
            ids: attrLayers.map(l => l.id),
            layers: attrLayers,
            subjectPremiumAmt: attrLayers.reduce((sum, item) => sum + (item.premium?.value || 0), 0),
            largeFrequencyLayerViewID: ''
          }
          const largeLayers = group.layers.filter(l => l.meta_data.loss_type === 'large')
          const largeGroup = {
            name: group.name + '~large_variance',
            ids: largeLayers.map(l => l.id),
            layers: largeLayers,
            subjectPremiumAmt: largeLayers.reduce((sum, item) => sum + (item.premium?.value || 0), 0),
            largeFrequencyLayerViewID: ''
          }
          const catLayers = group.layers.filter(l => l.meta_data.loss_type === 'cat')
          const catGroup = {
            name: group.name + '~cat_variance',
            ids: catLayers.map(l => l.id),
            layers: catLayers,
            subjectPremiumAmt: catLayers.reduce((sum, item) => sum + (item.premium?.value || 0), 0),
            largeFrequencyLayerViewID: ''
          }
          if (attrGroup.ids.length > 0) {
            typeGroups.push(attrGroup)
          }
          if (largeGroup.ids.length > 0) {
            typeGroups.push(largeGroup)
          }
          if (catGroup.ids.length > 0) {
            typeGroups.push(catGroup)
          }
        })
        const typeReqs = typeGroups.map(group => {
          const layerIDs = group.ids
          return this.service
            .postLayersViews(layerIDs, analysisID!, [currency!])
            .pipe(
              map(res => {
                const viewIDs = res.data.map(d => d.id)
                return {
                  ...group,
                  ids: viewIDs,
                  subjectPremiumAmt: group.layers.reduce((sum, item) => sum + (item.premium?.value || 0), 0),
                  groupType: 'Variance',
                  largeFrequencyLayerViewID: ''
                }
              })
            )
        })
        const reqs = groupReqs.concat(typeReqs)
        return forkJoin(reqs).pipe(
          map(updatedGroups => ({
            ...updatedData,
            groups: updatedGroups
          })),
          map(modifiedData => ({ modifiedData, analysisID }))
        )
      }),
      concatMap(({ modifiedData, analysisID }) => {
        const largeFrequencyViewReq = modifiedData.groups.map(group => {
          if (!!group.largeFrequencyLayerViewID && group.largeFrequencyLayerViewID !== '') {
            return this.service.postPortfolioViewAdhoc(
              [group.largeFrequencyLayerViewID],
              analysisID
            ).pipe(
              map((res) => {
                return ({
                name: group.name,
                largeFrequencyLayerViewID: res.data
              })})
            )
          }
          return of({ name: group.name, largeFrequencyLayerViewID: group.largeFrequencyLayerViewID })
        })
        return forkJoin(largeFrequencyViewReq).pipe(
          map(res => {
            return modifiedData.groups.map(g => {
              let largeFrequencyLayerViewID = ''
              const newView = res.find(r => r.name === g.name)
              if (newView && typeof newView.largeFrequencyLayerViewID === 'string') {
                largeFrequencyLayerViewID = newView.largeFrequencyLayerViewID
              }
              return {
                ...g,
                largeFrequencyLayerViewID
              }
            })
          }),
          map(updatedData => ({ updatedData, analysisID }))
        )
      }),
      concatMap(({ updatedData, analysisID }) => {
        const layerRequests = updatedData.map(g => {
          const layerIDs = [...g.ids]
          if (layerIDs.length > 0) {
            return this.service.postPortfolioViewAdhoc(layerIDs, analysisID)
          }
        })
        return forkJoin(layerRequests).pipe(
          map(responses => {
            return responses.map((res, index) => ({
              lossID: res.data,
              viewID: res.data,
              group: updatedData[index]
            }))
          })
        )
      }),
      map(response => {
        const lossSetArray: { lossID: string; viewID: string }[] = response.map(d => ({
          lossID: d.lossID,
          viewID: d.viewID,
        }))
        const lossSetLayers = response.map(res => ({
          lossID: res.lossID,
          viewID: res.viewID,
          lossName: res.group.name,
          lossType: 'Group',
          subjectPremiumAmt: res.group.subjectPremiumAmt,
          groupType: res.group.groupType,
          largeFrequencyLayerViewID: res.group.largeFrequencyLayerViewID
        }))
        return {
          lossSetArray,
          lossSetLayers,
        }
      }),
      map(response => {
        const lossSetLayers = response.lossSetLayers?.map(l => {
          const viewID = response.lossSetArray?.find(
            y => y.lossID === l.lossID
          )?.viewID
          return {
            ...l,
            lossID: l.lossID || '',
            viewID: viewID || '',
            lossName: l.lossName || '',
            lossType: 'Group',
            filterValue: 'all',
            groupType: l.groupType,
            largeFrequencyLayerViewID: l.largeFrequencyLayerViewID
          }
        })
        return getGroupSummaryDataCollection({
          viewID: lossSetLayers[0]?.viewID,
          lossSetLayers: lossSetLayers ?? [],
        })
      })
    )
  )



  getSummaryDataCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSummaryDataCollection),
      withLatestFrom(
        this.store.pipe(select(selectExploreModifiers)),
        this.store.pipe(select(selectGrossPortfolioViewID)),
        this.store.pipe(select(selectExploreSummaryRP))
      ),
      mergeMap(([{ lossSetLayers }, modifiers, analysisProfileID, arrRP]) => {
        const exploreApis = getExploreSummaryAPIs(lossSetLayers, this.service, analysisProfileID, arrRP)
        return forkJoin(exploreApis).pipe(
          map(res => ({
            res,
            arrRP,
            modifiers,
            lossSetLayers,
            error: res[0].error,
          }))
        )
      }),
      map(result => {
        if (result.error) {
          return getLossDataCollectionError({ error: result.error })
        } else {
          const summaryData = getSummaryResponse(result.lossSetLayers, result.res)
          this.store.dispatch(updateSummaryLoading({ loading: false }))
          return getSummaryDataSuccess({
            summaryData,
          })
        }
      })
    )
  )
  getSummaryGroupDataCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroupSummaryDataCollection),
      withLatestFrom(
        this.store.pipe(select(selectExploreModifiers)),
        this.store.pipe(select(selectGrossPortfolioViewID)),
        this.store.pipe(select(selectExploreSummaryRP))
      ),
      mergeMap(([{ lossSetLayers }, modifiers, portfolioViewID, arrRP]) => {
        const exploreApis = getExploreSummaryAPIs(lossSetLayers, this.service, portfolioViewID, arrRP)
        return forkJoin(exploreApis).pipe(
          map(res => ({
            res,
            arrRP,
            modifiers,
            lossSetLayers,
            error: res[0].error,
          }))
        )
      }),
      map(result => {
        if (result.error) {
          return getLossDataCollectionError({ error: result.error })
        } else {
          const groupSummaryData = getSummaryResponse(result.lossSetLayers, result.res, true)
          this.store.dispatch(updateSummaryLoading({ loading: false }))
          return getSummaryGroupDataSuccess({
            groupSummaryData,
          })
        }
      })
    )
  )

  saveExploreGrossSummaryView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSummaryFilterView),
      switchMap(({ data }) => {
        const req = {
          ...data,
          id: 0,
          columnFilters: data.columnFilters
        } as ExploreSummaryView
        return this.backendService.postExploreGrossView(req).pipe(
          map(() => {
            return fetchSummaryViews({ programId: req.programId})
          }),
          catchError(error => {
            return of(saveSummaryFilterViewFailure({ error }))
          })
        )})
    ))

  updateExploreGrossSummaryView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSummaryFilterView),
      switchMap(({ data }) => {
        const req = {
          ...data
        } as ExploreSummaryView
        return this.backendService.putExploreGrossView(req).pipe(
          map(() => {
            return fetchSummaryViews({ programId: req.programId})
          }),
          catchError(error => {
            return of(updateSummaryFilterViewFailure({ error }))
          })
        )})
    ))

  deleteExploreGrossSummaryView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSummaryFilterView),
      switchMap(({ id }) =>
        this.backendService.deleteExploreGrossView(id).pipe(
          map(() => deleteSummaryFilterViewSuccess({ id })),
          catchError((error) =>
            of(deleteSummaryFilterViewFailure({ error }))
          )
        )
      )
    ),
    { dispatch: true }
  )
}

