import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Client } from '../../core/model/client.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-client-select',
  styleUrls: ['./client-select.component.scss'],
  templateUrl: './client-select.component.html',
})
export class ClientSelectComponent {
  @Input() placeholder = 'Select Company'
  @Input() clients: Client[]
  @Input() selectedClientID: string | null

  @Input() set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value)
  }
  get readonly() {
    return this._readonly
  }

  @Output() clientChange = new EventEmitter<string>()

  @HostBinding('class.readonly') _readonly = false

  get selectedClient(): Client | null {
    if (this.selectedClientID != null) {
      const cc = this.clients.find(c => c.id === this.selectedClientID)
      if (cc) {
        return cc
      }
    }
    return null
  }
}
