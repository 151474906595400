import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { PortfolioType } from '../analysis/model/portfolio-metrics.model'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../analysis/model/metrics.model'
import {CompareMetricSetting, CompareMetricTableCategory } from '../analysis/model/compare-metrics.model'
import { LossFilter } from '../api/analyzere/analyzere.model'
import { MatMenuTrigger } from '@angular/material/menu'
import { MatSnackBar } from '@angular/material/snack-bar'

export interface MetricUpdateData {
  portfolioTypeChange: PortfolioType
  perspectiveChange: Perspective
  aggregationMethodChange: AggregationMethodType
  vartvarChange: VaRTVaR
  lossFilterChange: string
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-toggles',
  styles: [
    `
           /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
           mat-button-toggle-group {
             margin-bottom: var(--stack);
             width: 100%;
           }

           /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
           mat-button-toggle {
             width: 50%;
           }

           .loss-filter {
             height: 1.5rem;
           }

           .loss-filters-button {
             width: 100%;
             height: auto;
             padding: 0;
             text-align: center;
             line-height: normal;
             display: block;
             min-height: auto;
             color: var(--body);

             &:hover {
               background: var(--bg-1) !important;
             }
           }

           .loss-filters-button::after {
             display: none;
           }

           .selected {
             background-color: var(--primary);
             color: var(--body-inverse);
           }

           .reset-selected {
             background-color: var(--body-inverse);
             color: var(--primary);
           }

           .update-button {
             display: flex;
           }

           .metric-toggles-button {
             margin: auto;
             width: fit-content;
           }
           .button-container {
            display: flex;
            justify-content: center;  /* Centers buttons horizontally */
            gap: 10px;
           }
           .returnPeriodInput {
              width: 100%;
            }

            .centeredRPInput {
              text-align: center;
              padding-bottom: 2px;
            }
         `,
  ],
  template: `
    <mat-button-toggle-group
      class="app-mat-theme"
      name="portfolioType"
      aria-label="Portfolio Type"
      [value]="portfolioType"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.portfolioTypeChange = $event.value"
    >
      <mat-button-toggle value="Ceded" disableRipple> Ceded </mat-button-toggle>
      <mat-button-toggle value="Net" disableRipple> Net </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="vartvar"
      aria-label="VaR/TVaR"
      *ngIf="metrics.category !== 'Misc'"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.vartvarChange = $event.value"
      [value]="vartvar"
    >
      <mat-button-toggle value="VaR" disableRipple>VaR</mat-button-toggle>
      <mat-button-toggle value="TVaR" disableRipple>TVaR</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="aggregation"
      aria-label="Aggregation"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.aggregationMethodChange = $event.value"
      [value]="aggregationMethod"
    >
      <mat-button-toggle value="OEP" disableRipple>OEP</mat-button-toggle>
      <mat-button-toggle value="AEP" disableRipple>AEP</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="perspective"
      (click)="$event.stopPropagation()"
      (change)="onPerspectiveChange($event)"
      aria-label="Perspective"
      [value]="perspective"
    >
      <mat-button-toggle value="Loss" disableRipple>Loss</mat-button-toggle>
      <mat-button-toggle value="LossRp" disableRipple
        >Loss+RP</mat-button-toggle
      >
      <mat-button-toggle value="UW" disableRipple>UW</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme loss-filter"
      name="filters"
      aria-label="Loss Filters"
      matTooltip="Set perspective to Loss or Loss+RP to select Loss Filter"
      (click)="$event.stopPropagation()"
    >
      <button
        class="loss-filters-button"
        mat-menu-item
        [disabled]="metricUpdateData.perspectiveChange === 'UW'"
        [matMenuTriggerFor]="lossFiltersMenu"
         #lossFiltersMenuTrigger="matMenuTrigger"
        [ngClass]="resetLossFilter ? 'reset-selected' : 'selected'"
      >
        {{ lossFilterButtonLabel }}
      </button>
      <mat-menu #lossFiltersMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let f of lossFilters"
          (click)="onLossFilterClick($event.target.innerText,lossFiltersMenuTrigger)"
        >
          {{ f.description }}
        </button>
      </mat-menu>
    </mat-button-toggle-group>


    <mat-button-toggle-group
      class="app-mat-theme loss-filter"
      name="filters"
      aria-label="Return Period Year"
      matTooltip="Select the return period year for this metric"
      (click)="$event.stopPropagation()"
      *ngIf="isNewTailMetric"
    >
      <!-- Input box inside mat-button-toggle-group -->
      <mat-form-field class="returnPeriodInput" subscriptSizing="dynamic">
          <input
            matInput
            [(ngModel)]="returnPeriodYearLabel"
            [matTooltip]="returnPeriodYearLabel ? '' : 'Select the return period year for this metric'"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (change)="onYearChangeClick($event.target.value)"
            placeholder="Enter return period year"
            class="centeredRPInput"
          />
        </mat-form-field>
    </mat-button-toggle-group>

    <div class="button-container">
      <div class="update-button" *ngIf="isNewTailMetric === false">
        <button
          class="metric-toggles-button"
          appButton
          big
          accent
          border
          (click)="onUpdateMetricClick()"
        >
          Update
        </button>
      </div>

      <div class="update-button" *ngIf="metrics.metricSettingID === 0 && isNewTailMetric === false">
        <button
          class="metric-toggles-button"
          appButton
          big
          accent
          border
          (click)="onDeleteMetricClick(metrics)"
        >
          Delete
        </button>
      </div>
    </div>

    <div class="update-button" *ngIf="isNewTailMetric === true">
      <button
        class="metric-toggles-button"
        appButton
        big
        accent
        border
        (click)="onAddNewMetric()"
      >
        Add
      </button>
    </div>
  `,
})
export class MetricsTogglesComponent implements OnInit {
  @ViewChild('lossFiltersMenuTrigger', {static: false}) lossFiltersMenuTrigger: MatMenuTrigger

  @ViewChild('returnPeriodMenuTrigger', {static: false}) returnPeriodMenuTrigger: MatMenuTrigger

  metricUpdateData: MetricUpdateData
  returnPeriodYearList: string[] = ['1','5','10','25','50','100','250','1000']
  @Input() metrics: CompareMetricSetting
  @Input() lossFilters: LossFilter[]
  @Input() isNewTailMetric: boolean
  @Input() allMetrics: CompareMetricSetting[][]
  @Input() newTailMetric: CompareMetricSetting
  @Input() metricCategories: CompareMetricTableCategory[]

  @Output() updateMetricClick = new EventEmitter<MetricUpdateData>()
  @Output() deleteMetricClick = new EventEmitter<CompareMetricSetting>()

  @Output() addCustomMetric = new EventEmitter<CompareMetricSetting>()

  get portfolioType() {
    return this.metrics.portfolioType
  }

  get perspective() {
    return this.metrics.perspective
  }

  get vartvar() {
    return this.metrics.vartvar
  }

  get aggregationMethod() {
    return this.metrics.aggregationMethodType
  }

  get lossFilter() {
    return this.metrics.lossFilter
  }

  get lossFilterButtonLabel() {
    const description = this.lossFilters.find(
      f => f.name === this.metricUpdateData.lossFilterChange
    )?.description as string
    return description
  }

  get returnPeriodYearLabel(){
    const returnPeriodYear =  this.metrics.year as string ?? '1'
    return returnPeriodYear
  }

  get resetLossFilter(): boolean {
    return this.metrics.lossFilter === 'all' || this.perspective !== 'Loss'
  }

  get (): boolean {
    return this.metrics.lossFilter === 'all' || this.perspective !== 'Loss'
  }

  constructor(
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.metricUpdateData = {
      portfolioTypeChange: this.metrics.portfolioType
        ? this.metrics.portfolioType
        : 'Net',
      perspectiveChange: this.metrics.perspective
        ? this.metrics.perspective
        : 'Loss',
      aggregationMethodChange: this.metrics.aggregationMethodType
        ? this.metrics.aggregationMethodType
        : 'AEP',
      vartvarChange: this.metrics.vartvar ? this.metrics.vartvar : 'VaR',
      lossFilterChange: this.metrics.lossFilter
        ? this.metrics.lossFilter
        : 'all',
    }
  }

  onLossFilterClick(description: string, menuTrigger: MatMenuTrigger) {
    const name = this.lossFilters.find(f => f.description === description)?.name
    this.metricUpdateData.lossFilterChange = name ? name : 'all'
    menuTrigger.closeMenu()
  }

  onUpdateMetricClick() {
    this.updateMetricClick.emit(this.metricUpdateData)
  }

  onDeleteMetricClick() {
    this.deleteMetricClick.emit(this.metrics)
  }

  onAddNewMetric() {
    const maxOrdinal = this.findMaxOrdinal(this.metricCategories)
    const metricPath = `${this.metrics.path}customTailMetrics/tailMetric${this.allMetrics.length + 1}`
    const metricLabel = `${this.metrics.year}yr ${this.metricUpdateData.portfolioTypeChange} ${this.metricUpdateData.vartvarChange} ${this.metricUpdateData.aggregationMethodChange} ${this.metricUpdateData.perspectiveChange} - ${this.lossFilterButtonLabel}`
    this.newTailMetric = {
      ...this.metrics,
      portfolioType: this.metricUpdateData.portfolioTypeChange,
      vartvar: this.metricUpdateData.vartvarChange,
      perspective: this.metricUpdateData.perspectiveChange,
      aggregationMethodType: this.metricUpdateData.aggregationMethodChange,
      lossFilter: this.metricUpdateData.lossFilterChange,
      label: metricLabel,
      category: this.metrics.category,
      path: metricPath,
      ordinal: maxOrdinal + 1 // Need to add ordinal for tail Metrics as it helps us to maintain the position (bottom) of the Tail Metrics. Changes done for AB#18060
    }

    const isCusMetricExists = this.allMetrics.some(metricArray =>
      metricArray.some(metric => metric.label === this.newTailMetric.label && metric.metricSettingID === 0)
    )
    if(!this.newTailMetric.year){
      this.snackbar.open('Please enter return period year', 'X', {
        duration: 2000,
      })
    } else if(isCusMetricExists){
      this.snackbar.open('A custom metric with the same name already exists.', 'X', {
        duration: 2000,
      })
    } else {
      this.addCustomMetric.emit(this.newTailMetric)
    }
  }

  findMaxOrdinal(categories: CompareMetricTableCategory[]): number {
    const maxOrdinal = Math.max(
      ...categories
        .flatMap(category => category.metrics)
        .flat()
        .map(metric => metric.ordinal)
    )
    return maxOrdinal
  }

  onYearChangeClick(
    selectedYear: string
  ) {
    this.metrics = {
      ...this.metrics,
      year: selectedYear
    }
  }


  onPerspectiveChange($event: any) {
    this.metricUpdateData.perspectiveChange = $event.value
    // Reset loss filter if perspective changed to UW
    if (this.metricUpdateData.perspectiveChange === 'UW') {
      this.metricUpdateData.lossFilterChange = 'all'
    }
  }
}
