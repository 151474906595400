import { CdkScrollable } from '@angular/cdk/overlay'
import { ExtendedScrollToOptions } from '@angular/cdk/scrolling'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../../../core/store'
import { Size } from '@shared/size.mixin'
import {
  CompareMetricCategory,
  CompareMetricValue,
} from '../../../model/compare-metrics.model'
import {
  PortfolioMetrics,
  PortfolioViewMetricsPayload,
} from '../../../model/portfolio-metrics.model'
import { PortfolioSetID } from '../../../model/portfolio-set.model'
import {
  selectPortfolioViewDetailMetricsError,
  selectPortfolioViewDetailMetricsLoading,
  selectPortfolioViewMetrics,
  selectPortfolioViewMetricsError,
  selectPortfolioViewMetricsLoading,
} from '../../../store/analysis.selectors'
import { CompareEntity } from '../../../store/compare/compare.reducer'
import { hideMetric } from '../../../../metrics/metrics.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metrics-container',
  styleUrls: ['./compare-metrics-container.scss'],
  templateUrl: './compare-metrics-container.html',
})
export class CompareMetricsContainerComponent implements OnInit, AfterViewInit {
  viewDetailMetricsLoading$: Observable<boolean>
  viewDetailMetricsError$: Observable<string | null>
  viewMetrics$: Observable<PortfolioMetrics | null>
  viewMetricsLoading$: Observable<boolean>
  viewMetricsError$: Observable<string | null>

  @Input() precision: number
  @Input() entitiesLength: number
  @Input() portfolioSetID: PortfolioSetID
  @Input() name: string
  @Input() metricCategories: CompareMetricCategory[]
  @Input() firstEntity: CompareEntity
  @Input() expandedMetricCategories: Record<string, boolean>
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() expandedChangeMetrics: Record<string, boolean>
  @Input() size: Size
  @Input() rankMax: number
  @Input() metricsWeight: number
  @Input() structureIndex: number

  @Output() returnPeriodChange = new EventEmitter<
    Partial<PortfolioViewMetricsPayload>
  >()
  @Output() scrollChange = new EventEmitter<ExtendedScrollToOptions>()

  @ViewChild(CdkScrollable) scrollable: CdkScrollable

  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  get isGrossMetrics(): boolean {
    return this.name === 'Gross Metrics'
  }

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    const props = { portfolioSetID: this.portfolioSetID }
    this.viewDetailMetricsLoading$ = this.store.pipe(
      select(selectPortfolioViewDetailMetricsLoading, props)
    )
    this.viewDetailMetricsError$ = this.store.pipe(
      select(selectPortfolioViewDetailMetricsError, props)
    )
    this.viewMetrics$ = this.store.pipe(
      select(selectPortfolioViewMetrics, props)
    )
    this.viewMetricsLoading$ = this.store.pipe(
      select(selectPortfolioViewMetricsLoading, props)
    )
    this.viewMetricsError$ = this.store.pipe(
      select(selectPortfolioViewMetricsError, props)
    )
  }

  ngAfterViewInit(): void {
    this.scrollable.elementScrolled().subscribe(() => {
      const top = this.scrollable.measureScrollOffset('top')
      const start = this.scrollable.measureScrollOffset('start')
      this.scrollChange.emit({ top, start })
    })
  }

  scrollTo(opts: ExtendedScrollToOptions): void {
    if (
      opts.top !== this.scrollable.measureScrollOffset('top') ||
      opts.start !== this.scrollable.measureScrollOffset('start')
    ) {
      this.scrollable.scrollTo(opts)
    }
  }

  getMetrics(metrics: CompareMetricValue[][]): CompareMetricValue[][] {
    const returnMetrics = JSON.parse(JSON.stringify(metrics))
    const index = returnMetrics.findIndex((metric: CompareMetricValue[]) =>
      hideMetric(metric[0])
    )
    // Remove any metrics filtered by hideMetric
    if (index !== -1) {
      returnMetrics.splice(index, 1)
    }
    return returnMetrics
  }

  isExpanded(category: string): boolean {
    return (
      (this.expandedMetricCategories &&
        this.expandedMetricCategories[category]) ||
      false
    )
  }

  trackByID(index: number, category: CompareMetricCategory | undefined): string | number {
    return (category && category.category) || index
  }

  isNumber(val: any): boolean {
    return typeof val === 'number'
  }
}
