import { Injectable } from '@angular/core'
import {
  LayerEntry,
  LayerTypeDefaultEntry,
  PricingCurveLayerEntryDTO,
} from './technical-premium.model'
import { Layer } from '../model/layers.model'
import {
  doesLayerUseLayerTypeDefault,
  getPricingCurvesForLayer,
  layerEntryToString,
} from './technical-premium.utils'
import { Program } from 'src/app/core/model/program.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { TechnicalPremiumService } from 'src/app/api/technical-premium/technical-premium.service'
import { map, mergeMap, switchMap } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ApplicationError } from 'src/app/error/model/error'
import {
  LayerViewResponse,
  LogicalPortfolioLayer,
  Portfolio,
} from 'src/app/api/analyzere/analyzere.model'
import {
  isMultiSectionLayer,
  isMultiSectionMainLayer,
} from '../layers/multi-section-layer'
import { isIndexedLayer, isIndexedMainLayer } from '../layers/indexed-layer'
import { layerIds } from '../model/layer-palette.model'
import {
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from 'src/app/api/util'
import { convertFromLogicalPortfolioLayers } from '../model/layers.converter'
import { onlyUnique } from 'src/app/pricingcurve/pricing-curve.utils'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { md5 } from '@shared/util/hash'
import { LayerMetrics } from '../model/layers-metrics.model'
import { createProportionalExpense, isQSLayer } from '../model/layers.util'
import { LayerView } from '../model/layer-view'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import { ApiResponse } from 'src/app/api/model/api.model'

type TechnicalPremiumServiceReturnType = ApiResponse<
  PricingCurveLayerEntryDTO[] | LogicalPortfolioLayer[] | Layer[]
>

@Injectable({
  providedIn: 'root',
})
export class TechnicalPremiumSyncService {
  constructor(
    private analyzeReService: AnalyzreService,
    private tpService: TechnicalPremiumService
  ) {}

  createLayerMappingEntriesForPrograms(
    programs: Program[],
    layerTypeDefaults?: LayerTypeDefaultEntry,
    recalculateTechnicalPremium = false,
    savedPricingCurves?: SavedPricingCurveEntry[]
  ): TechnicalPremiumServiceReturnType {
    return this.analyzeReService
      .fetchPortfolios(programs.map(program => program.cededPortfolioID))
      .pipe(
        mergeMap(portfoliosResponse => {
          if (portfoliosResponse.error) {
            return this.getErrorFromResponse(portfoliosResponse)
          } else {
            const portfolios = portfoliosResponse.data!
            return this.analyzeReService.fetchLayers<LogicalPortfolioLayer>(
              this.createLayerRequestFromLayerTypeEntries(
                portfolios,
                {},
                false,
                false
              )
            )
          }
        }),
        rejectError(error => ({
          error,
        })),
        switchMap(logicalLayers => {
          const layers = convertFromLogicalPortfolioLayers(logicalLayers)
          const observables = [
            this.createOrUpdateLayerMappingEntriesForLayers(
              layers,
              layerTypeDefaults ?? {},
              !layerTypeDefaults ? programs[0].studyID : undefined
            ),
          ]
          if (recalculateTechnicalPremium && savedPricingCurves) {
            observables.push(
              this.getLayerDetailsAndRecalculateTechnicalPremium(
                layers,
                programs,
                savedPricingCurves
              )
            )
          }
          return forkJoin(observables).pipe(map(() => ({ data: layers })))
        })
      )
  }

  updateOtherProgramLayersUsingLayerTypeDefault(
    programs: Program[],
    layerTypeDefaults: LayerTypeDefaultEntry,
    savedPricingCurves: SavedPricingCurveEntry[]
  ): TechnicalPremiumServiceReturnType {
    return this.createLayerMappingEntriesForPrograms(
      programs,
      layerTypeDefaults,
      true,
      savedPricingCurves
    )
  }

  createOrUpdateLayerMappingEntriesForLayers(
    layers: Layer[],
    layerTypeDefaultEntries: LayerTypeDefaultEntry,
    studyId?: string
  ): TechnicalPremiumServiceReturnType {
    if (!layers.length) {
      return of({ data: [] })
    }
    return this.tpService
      .getLayerTypeEntries({ programId: studyId ?? '' })
      .pipe(
        switchMap(response => {
          if (response.error) {
            return this.getErrorFromResponse(response)
          }
          const layerTypeDefaults = studyId
            ? response.data!
            : layerTypeDefaultEntries
          const dtos = this.createPricingCurveLayerEntryDTOForLayers(
            layers,
            layerTypeDefaults
          )
          return this.tpService.postPricingCurveLayerRefMappings(dtos)
        })
      )
  }

  updateLayerMappingEntriesOnSave(
    modifiedLayers: Layer[],
    layerTypeEntries: LayerTypeDefaultEntry
  ): TechnicalPremiumServiceReturnType {
    // Get the modified layers and their curve entries
    const modifiedLayerTypeEntryKeys = Object.entries(layerTypeEntries)
      .filter(([, val]) => val.modified)
      .map(([key]) => key)

    // If anything is using the layer type default, include that as well
    const layersWithUpdatedDefaults: Layer[] = modifiedLayers.filter(
      layer =>
        layer.meta_data.sage_layer_type &&
        modifiedLayerTypeEntryKeys.includes(layer.meta_data.sage_layer_type) &&
        doesLayerUseLayerTypeDefault(layer)
    )

    const updatedLayers = modifiedLayers
      .concat(layersWithUpdatedDefaults)
      .map(layer => {
        const physicalLayer = layer.physicalLayer
        const meta_data = physicalLayer.meta_data
        const pricingCurves =
          !meta_data.pricingcurve_is_default && meta_data.pricingCurves
            ? meta_data.pricingCurves
            : layerEntryToString(
                layerTypeEntries[
                  // tslint:disable-next-line: no-non-null-assertion
                  layer.meta_data.sage_layer_type!
                ]
              )
        return {
          ...layer,
          physicalLayer: {
            ...physicalLayer,
            meta_data: {
              ...meta_data,
              pricingCurves,
            },
          },
        }
      })
    return this.createOrUpdateLayerMappingEntriesForLayers(
      updatedLayers,
      layerTypeEntries
    )
  }

  removeLayerMappingEntriesOnSave(layers: LayerState[]): ApiResponse<string[]> {
    const deletedLayers = layers.filter(layer => layer.deleted)
    if (!deletedLayers.length) {
      return of({ data: [] })
    }
    return this.tpService.deleteManyLayerRefMappings(
      deletedLayers.map(layer => layer.layer.id)
    )
  }

  getLayerDetailsAndRecalculateTechnicalPremium(
    layers: Layer[],
    programs: Program[],
    savedPricingCurves: SavedPricingCurveEntry[]
  ): TechnicalPremiumServiceReturnType {
    if (!layers.length) {
      return of({ data: [] })
    }
    const analysisProfileIDMapping: Record<string, string[]> = {}
    const relevantPrograms = this.filterToRelevantPrograms(programs, layers)
    const uniqueStudyIds = relevantPrograms
      .map(program => program.studyID)
      .filter(onlyUnique)
    relevantPrograms.forEach(program => {
      const analysisID = program.analysisID
      const relevantLayers = layers.filter(
        layer => layer.meta_data.structureID === program.id
      )
      const currentEntry = analysisProfileIDMapping[analysisID] ?? []
      const relevantLayerIds = relevantLayers.map(layer => layer.id)
      analysisProfileIDMapping[analysisID] =
        currentEntry.concat(relevantLayerIds)
    })
    return forkJoin([
      this.tpService.getManyLayerTypeEntries(uniqueStudyIds),
      this.analyzeReService.postLayersViewsForManyIds(analysisProfileIDMapping),
    ]).pipe(
      mergeMapWithInput(([, layersViewsResponse]) => {
        // tslint:disable-next-line: no-non-null-assertion
        if (layersViewsResponse.error) {
          return this.getErrorFromResponse(layersViewsResponse)
        }

        const layersViews = layersViewsResponse.data!
        const layersViewIDs: Record<string, string> = {}
        const layersByID: Record<string, LayerState | undefined> = {}
        const allLayersViews = layersViews.flat()
        const layerStates: LayerState[] = layers.map(layer => {
          return {
            new: false,
            layer,
            dirty: false,
            deleted: false,
            hash: md5(layer),
          }
        })
        allLayersViews.map(view => {
          const layerState = layerStates.find(
            layer => layer.layer.id === view.layerID
          )
          layersViewIDs[view.layerID] = view.id
          layersByID[view.layerID] = layerState
        })

        return this.analyzeReService.getManyLayersViewViewMetricsAndCalculate(
          allLayersViews.map(layersView => layersView.id),
          undefined,
          layersViewIDs,
          layersByID,
          layerStates,
          false
        )
      }),
      rejectErrorWithInput(error => this.getErrorFromResponse({ error })),
      switchMap(
        ([metrics, [layerTypeEntryResponse, layersViewsRawResponse]]) => {
          if (layerTypeEntryResponse.error || layersViewsRawResponse.error) {
            return this.getErrorFromResponse(layerTypeEntryResponse)
          }
          // tslint:disable-next-line: no-non-null-assertion
          const layerTypeMappings = layerTypeEntryResponse.data!

          // tslint:disable-next-line: no-non-null-assertion
          const layersViewsResponse = layersViewsRawResponse.data!

          return this.recalculateTechnicalPremium(
            layers,
            metrics,
            layersViewsResponse,
            layerTypeMappings,
            programs,
            savedPricingCurves
          )
        }
      )
    )
  }

  updateLayersFromPricingCurve(
    id: number,
    programs: Program[],
    savedPricingCurves: SavedPricingCurveEntry[]
  ): TechnicalPremiumServiceReturnType {
    return this.tpService.getPricingCurveLayerRefMappingsForId(id).pipe(
      rejectError(error => this.getErrorFromResponse({ error })),
      switchMap(dtos => {
        const ids = dtos.map(dto => dto.layerRef)
        return this.analyzeReService.fetchLayers<LogicalPortfolioLayer>(ids)
      }),
      rejectError(error => this.getErrorFromResponse({ error })),
      switchMap(layers => {
        const convertedLayers = convertFromLogicalPortfolioLayers(
          layers.filter(layer => !!layer.sink)
        ).filter(layer => this.doesLayerUseSyncTechPremiumValue(layer))
        return this.getLayerDetailsAndRecalculateTechnicalPremium(
          convertedLayers,
          programs,
          savedPricingCurves
        )
      })
    )
  }

  doesPricingCurveNeedToSaveLayers(id: number): ApiResponse<boolean> {
    return this.tpService.getPricingCurveLayerRefMappingsForId(id).pipe(
      rejectError(() => of({ data: false })),
      map(data => {
        return { data: !!data.length }
      })
    )
  }

  private recalculateTechnicalPremium(
    layers: Layer[],
    metrics: LayerMetrics[],
    layersViewsResponse: (LayerViewResponse & {
      layerID: string
    })[][],
    layerTypeMappings: Record<string, Record<string, LayerEntry>>,
    programs: Program[],
    savedPricingCurves: SavedPricingCurveEntry[]
  ): TechnicalPremiumServiceReturnType {
    const views = metrics.map(response => {
      // Find the view response that corresponds to this layerMetrics
      const relevantViewResponse = layersViewsResponse
        .flat()
        // tslint:disable-next-line: no-non-null-assertion
        .find(r => r.id === response.id)!

      // Find the layer that corresponds to this layerMetrics
      const responseLayer = layers.find(
        layer => layer.id === relevantViewResponse.layerID
        // tslint:disable-next-line: no-non-null-assertion
      )!

      // Find the program that corresponds to this layerMetrics
      const layerProgram = programs.find(
        program => program.id === responseLayer.meta_data.structureID
        // tslint:disable-next-line: no-non-null-assertion
      )!

      const layerTypeEntries = layerTypeMappings[layerProgram.studyID]

      return new LayerView(null, responseLayer, {
        metrics: response,
        programDefaultPricingCurves:
          layerTypeEntries[
            // tslint:disable-next-line: no-non-null-assertion
            responseLayer.physicalLayer.meta_data.sage_layer_type!
          ].pricingCurves,
        savedPricingCurves,
      })
    })
    const updatedLayers = views
      .filter(view => {
        // For QS layers, if the flat_cede fee entry value equals technical premium, remove
        // Due to floating point math, if first 10 decimals are the same, count as equal
        return isQSLayer(view.layer.meta_data.sage_layer_type ?? '')
          ? view.layer.physicalLayer.fees[0].rate.toFixed(10) !==
              view.technicalPremium?.toFixed(10)
          : // For All others, if premium equals technical premium, remove
            view.layer.physicalLayer.premium.value.toFixed(10) !==
              view.technicalPremium?.toFixed(10)
      })
      .map(view => {
        if (view.technicalPremium == null) {
          return view.layer
        }
        const layer = view.layer
        const fees = [...layer.physicalLayer.fees]

        fees.splice(
          0,
          1,
          createProportionalExpense('flat_cede', view.technicalPremium)
        )
        const layerUpdate = !isQSLayer(layer.meta_data.sage_layer_type ?? '')
          ? {
              premium: {
                ...layer.physicalLayer.premium,
                value: view.technicalPremium,
              },
            }
          : {
              fees,
            }
        return {
          ...layer,
          physicalLayer: {
            ...layer.physicalLayer,
            ...layerUpdate,
          },
        }
      })
    if (updatedLayers.length) {
      return this.analyzeReService.saveLayers<LogicalPortfolioLayer>(
        updatedLayers
      )
    } else {
      return of({ data: updatedLayers })
    }
  }

  getFreshVersionOfDirtiedLayers(
    beforeLayers: LayerState[],
    afterLayers: LayerState[]
  ): Layer[] {
    // Find all layers that are new and use one of the 'checked' values
    const newLayers = beforeLayers.filter(
      layer => layer.new && this.doesLayerUseSyncTechPremiumValue(layer.layer)
    )

    // Get the version of the layer from after the save
    return afterLayers
      .filter(layer => newLayers.some(l => l.layer.id === layer.layer.id))
      .map(layer => layer.layer)
  }

  getErrorFromResponse(response: {
    error?: ApplicationError
  }): ApiResponse<any> {
    return of({ error: response.error })
  }

  createPricingCurveLayerEntryDTOForLayers(
    layers: Layer[],
    layerTypeDefaults: LayerTypeDefaultEntry
  ): PricingCurveLayerEntryDTO[] {
    return layers.reduce<PricingCurveLayerEntryDTO[]>((acc, layer) => {
      if (layer.physicalLayer.meta_data.pricingCurves) {
        const curves = getPricingCurvesForLayer(
          layer
        )
        const newDtos: PricingCurveLayerEntryDTO[] = curves.map(curve => ({
          pricing_curve_id: curve.id!,
          layerRef: layer.id,
        }))
        return acc.concat(newDtos)
      } else if (
        layer.meta_data.sage_layer_type &&
        layerTypeDefaults[layer.meta_data.sage_layer_type] &&
        !layerTypeDefaults[
          layer.meta_data.sage_layer_type
        ].pricingCurveIds.includes(-1) &&
        doesLayerUseLayerTypeDefault(layer)
      ) {
        const newDtos: PricingCurveLayerEntryDTO[] = layerTypeDefaults[
          layer.meta_data.sage_layer_type
        ].pricingCurves.map(curve => ({
          layerRef: layer.id,
          // tslint:disable-next-line: no-non-null-assertion
          pricing_curve_id: curve.id!,
        }))
        return acc.concat(newDtos)
      }
      return acc
    }, [])
  }

  createLayerRequestFromLayerTypeEntries(
    portfolios: Portfolio[],
    layerTypeDefaults: LayerTypeDefaultEntry,
    saveForNewLayers: boolean,
    filter = true
  ): string[] {
    const layersToRequest: string[] = []
    const layerTypeEntries = Object.values(layerTypeDefaults)

    // Saving for only new layers is needed to update the layers that use that default entry
    // The opposite is needed to update the pricing curve/physical layer id mapping for technical premium
    const layerTypeEntriesToUpdate = layerTypeEntries.filter(value =>
      saveForNewLayers
        ? value.saveForOnlyNewLayers
        : !value.saveForOnlyNewLayers
    )
    const layerTypesToUpdate = layerTypeEntriesToUpdate.map(
      val => val.layerType
    )
    portfolios.map(portfolio =>
      (portfolio.layers as LogicalPortfolioLayer[])
        .filter(layer =>
          filter ? this.filterLayerByLayerType(layer, layerTypesToUpdate) : true
        )
        .forEach(layer => {
          if (layer.meta_data.backAllocatedForID) {
            layersToRequest.push(layer.meta_data.backAllocatedForID)
          }
          if (layer.meta_data.visible_layer_id) {
            layersToRequest.push(layer.meta_data.visible_layer_id)
          } else if (
            layer.meta_data.sage_layer_type !== layerIds.catMultisection &&
            layer.meta_data.sage_layer_subtype !== 'section-layer'
          ) {
            layersToRequest.push(layer.id)
          }
        })
    )
    return layersToRequest
  }

  private filterLayerByLayerType = (
    layer: LogicalPortfolioLayer,
    layerTypesToUpdate: string[]
  ): boolean => {
    const isMainLayer =
      isMultiSectionMainLayer(layer) || isIndexedMainLayer(layer)
    const additionalFilter =
      !isMultiSectionLayer(layer) && !isIndexedLayer(layer) ? true : isMainLayer
    return (
      additionalFilter &&
      (layerTypesToUpdate.includes(layer.meta_data.sage_layer_type ?? '') ||
        (layer.meta_data.sage_layer_subtype === layerIds.noncatIndxl &&
          layerTypesToUpdate.includes('noncat_xl')))
    )
  }

  filterToRelevantPrograms(programs: Program[], layers: Layer[]): Program[] {
    return programs.filter(program =>
      layers.some(layer => layer.meta_data.structureID === program.id)
    )
  }

  doesLayerUseSyncTechPremiumValue(layer: Layer): boolean {
    return (
      (layer.physicalLayer.meta_data.technicalPremiumChecked ||
        layer.physicalLayer.meta_data.cedingCommissionChecked) ??
      false
    )
  }
}
