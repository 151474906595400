import { createReducer, on, Action } from '@ngrx/store'
import { MiscMetricsResult } from '../../model/misc-metrics.model'
import {
  fetchMiscMetrics,
  fetchMiscMetricsFailure,
  fetchMiscMetricsSuccess,
} from './misc-metrics.actions'

export interface State extends MiscMetricsResult {
  loading: boolean
  error: string | null
}

export const initialState: State = {
  loading: false,
  error: null,
  programAttachAgg: 0,
  programAttachOcc: 0,
  probabilityMetric: 0,
}

const miscMetricsReducer = createReducer(
  initialState,
  on(fetchMiscMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchMiscMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(
    fetchMiscMetricsSuccess,
    (state, { programAttachAgg, programAttachOcc, probabilityMetric }) => ({
      ...state,
      loading: false,
      error: null,
      programAttachAgg,
      programAttachOcc,
      probabilityMetric,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return miscMetricsReducer(state, action)
}
