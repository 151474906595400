import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { rejectNilOperator } from './util/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-filter-input',
  styles: [
    `
      mat-form-field {
        width: 100%;
        padding-top: 1px;
        border: 1px solid transparent;
        border-radius: 100px;
        background: var(--bg-1-translucent-subtle);
      }

      mat-form-field:hover {
        border: 1px solid var(--border-translucent-subtle);
      }

      mat-form-field:focus-within {
        border: 1px solid var(--border-translucent);
      }

      :host.transparent mat-form-field,
      :host.transparent mat-form-field:focus,
      :host.transparent mat-form-field:hover {
        background: transparent;
        border: 1px solid transparent;
      }

      .wrapper {
        display: flex;
        padding: 8px 0;
      }

      mat-icon {
        font-size: var(--font-size);
        height: 100%;
        margin: 0 0.3em 0 0.7em;
        color: var(--primary);
      }

      mat-icon.lit {
        color: var(--accent);
      }

      mat-icon.clear {
        margin: 0 0.7em 0 0;
        cursor: pointer;
      }

      mat-icon.clear:hover,
      mat-icon.clear:focus {
        color: var(--primary-lit);
      }

      .mat-mdc-form-field {
        display: inline-block;
        position: relative;
        text-align: left;
      }

      :host ::ng-deep .mat-mdc-text-field-wrapper {
        padding-left: 0 !important;
      }

      .search-text {
        color: var(--primary);
        font-size: small !important;
      }

      @media screen and (max-width: 1240px) {
        mat-icon {
          position: relative;
          top: 1px;
        }
      }
    `,
  ],
  template: `
    <mat-form-field class="app-form-field-no-space" subscriptSizing="dynamic">
      <div class="wrapper">
        <mat-icon matPrefix [class.lit]="filterInput?.value">
          filter_list
        </mat-icon>
        <input
          #filterInput
          matInput
          cdkFocusInitial
          type="text"
          placeholder="Search..."
          disableRipple
          class="search-text"
          [value]="value"
          (keydown.escape)="onEscape($event)"
          (keydown.arrowup)="onNavKey($event, 'up')"
          (keydown.arrowdown)="onNavKey($event, 'down')"
          (keydown.enter)="onNavKey($event, 'enter')"
          (keydown.tab)="onNavKey($event, 'tab')"
          (keyup)="onChange($event)"
        />
        <mat-icon
          *ngIf="filterInput?.value"
          matPrefix
          class="clear"
          (click)="onClear($event)"
        >
          cancel
        </mat-icon>
      </div>
    </mat-form-field>
  `,
})
export class FilterInputComponent implements OnInit {
  private _destroy$ = new Subject<void>()
  private _change$ = new BehaviorSubject<string | null>(null)

  private _filter?: string
  @Input() filter?: string

  @Input() debounceTime = 200

  @Input() set transparent(value: any) {
    this._transparent = coerceBooleanProperty(value)
  }
  get transparent() {
    return this._transparent
  }
  @HostBinding('class.transparent') _transparent = false

  @Input() set handleTab(value: any) {
    this._handleTab = coerceBooleanProperty(value)
  }
  get handleTab() {
    return this._handleTab
  }
  _handleTab = false

  @Output() filterChange = new EventEmitter<string>()
  @Output() debouncedChange = new EventEmitter<string>()
  @Output() navKeydown = new EventEmitter<NavKey>()

  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>

  get value(): string {
    return this._filter ?? this.filter ?? ''
  }

  ngOnInit(): void {
    this._change$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(this.debounceTime),
        rejectNilOperator()
      )
      .subscribe(value => this.debouncedChange.emit(value))
  }

  clear(): void {
    this.handleChange('')
    if (this.filterInput?.nativeElement) {
      this.filterInput.nativeElement.value = ''
    }
  }

  focus(): void {
    if (this.filterInput?.nativeElement) {
      this.filterInput.nativeElement.focus()
      this.filterInput.nativeElement.select()
    }
  }

  onEscape($event: KeyboardEvent): void {
    if ($event.key === 'Escape' && this.value) {
      $event.preventDefault()
      $event.stopPropagation()
      return this.handleChange('')
    }
  }

  onNavKey($event: KeyboardEvent, key: NavKey) {
    if (!this.handleTab && $event.key === 'Tab') {
      return
    }
    this.navKeydown.emit(key)
    $event.preventDefault()
    $event.stopPropagation()
    return false
  }

  onChange($event: KeyboardEvent): void {
    if ($event.key === 'Escape') {
      return
    }
    return this.handleChange(($event.target as HTMLInputElement).value)
  }

  onClear($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.handleChange('')
  }

  private handleChange(value: string): void {
    this._filter = value
    this._change$.next(value)
    this.filterChange.emit(value)
  }
}

export type NavKey = 'up' | 'down' | 'enter' | 'tab'
