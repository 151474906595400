import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { lensPath, set } from 'ramda'
import { LoadedLossSet, LossSet } from 'src/app/api/analyzere/analyzere.model'
import { isLoadedLossSet } from '../model/layers.util'
import {
  LossSetLayer,
  ScaledLossSetProps,
} from '../model/loss-set-layers.model'

// tslint:disable: no-non-null-assertion
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-scale-loss-set-input',
  template: `
    <div class="row label" *ngIf="showExtraLabels">
      <div>Loss Set Name</div>
      <div class="scale-factor">Loss Scale Factor</div>
      <div class="premium">Original Premium</div>
      <div class="scale-factor">Premium Scale Factor</div>
      <div class="premium">New Premium Amount</div>
      <div>New Expense Ratio</div>
    </div>
    <div class="row">
      <div>
        <label class="name">{{ name }}</label>
      </div>
      <div class="scale-factor">
        <app-layer-property
          [type]="'numeric'"
          [value]="scaledProps.lossScaleFactor"
          [readonly]="false"
          [hideLabel]="true"
          (inputChange)="setValue('lossScaleFactor', $event)"
          name="Loss Scale Factor"
        ></app-layer-property>
      </div>
      <div class="premium">
        <app-layer-property
          [currentCurrency]="lossSetLayer.premium.currency"
          [type]="'currency'"
          [value]="scaledProps.originalPremium"
          [readonly]="true"
          [hideLabel]="true"
          name="Original Premium"
        ></app-layer-property>
      </div>
      <div class="scale-factor">
        <app-layer-property
          [type]="'numeric'"
          [value]="scaledProps.premiumScaleFactor"
          [readonly]="originalPremium === 0"
          [hideLabel]="true"
          (inputChange)="setValue('premiumScaleFactor', $event)"
          name="Premium Scale Factor"
        ></app-layer-property>
      </div>
      <div class="premium">
        <app-layer-property
          [currentCurrency]="lossSetLayer.premium.currency"
          [type]="'currency'"
          [value]="scaledProps.newPremium"
          [readonly]="false"
          [hideLabel]="true"
          (inputChange)="setValue('newPremium', $event)"
          name="New Premium Amount"
        ></app-layer-property>
      </div>
      <div>
        <app-layer-property
          [type]="'numeric'"
          [value]="scaledProps.newExpenseRatio"
          [readonly]="false"
          [hideLabel]="true"
          (inputChange)="setValue('newExpenseRatio', $event)"
          name="New Expense Ratio"
        ></app-layer-property>
      </div>
    </div>
  `,
  styles: [
    `
           :host {
             display: flex;
             flex-direction: column;
           }
     
           .row {
             display: flex;
             align-items: center;
           }
     
           .row.label {
             margin-bottom: var(--inset-small);
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
             color: var(--accent);
           }
     
           .row > div {
             box-sizing: border-box;
             margin-right: var(--inset-big);
           }
     
           .row > div:first-child {
             flex: 0 0 15rem;
           }
     
           .row > div:last-child {
             flex: 0 0 5rem;
           }
     
           .premium {
             flex: 1;
           }
     
           .scale-factor {
             flex: 0.33;
           }
     
           app-layer-property {
             padding-left: 0;
           }
     
           .name {
             font-size: var(--font-size);
           }
         `,
  ],
})
export class ScaleLossSetInputComponent implements OnInit {
  @Input() lossSetLayer: LossSetLayer
  @Input() showExtraLabels: boolean
  @Input() description: string
  scaledProps: ScaledLossSetProps

  @Output() scaleChange = new EventEmitter<ScaledLossSetProps>()

  get name(): string {
    const { ls_dim1 = '', ls_dim2 } = this.lossSetLayer.meta_data
    const _name = ls_dim1 + (ls_dim2 ? ' - ' + ls_dim2 : '')
    return `${_name}${this.isDirty ? '*' : ''}`
  }

  get isDirty(): boolean {
    return !(
      this.scaledProps.lossScaleFactor === this.lossScaleFactor &&
      this.scaledProps.premiumScaleFactor === this.premiumScaleFactor &&
      this.scaledProps.newPremium === this.lossSetLayer.premium.value &&
      this.scaledProps.newExpenseRatio === this.expenseRatio
    )
  }

  get isLoadedLossSet(): boolean {
    return isLoadedLossSet(this.lossSetLayer.loss_sets[0])
  }

  get lossScaleFactor(): number {
    return this.isLoadedLossSet
      ? (this.lossSetLayer.loss_sets[0] as any).load
      : 1
  }

  get originalPremium(): number {
    return this.lossSetLayer.meta_data.originalPremium ||
      this.lossSetLayer.meta_data.originalPremium === 0
      ? this.lossSetLayer.meta_data.originalPremium
      : this.lossSetLayer.premium.value
  }

  get premiumScaleFactor(): number {
    return this.originalPremium === 0
      ? 0
      : this.lossSetLayer.meta_data.originalPremium
      ? this.lossSetLayer.premium.value / this.originalPremium
      : 1
  }

  get expenseRatio(): number {
    return this.lossSetLayer.fees!.length > 0
      ? this.lossSetLayer.fees![0].rate
      : 0
  }

  // If existing row is already loaded, get the original Loss Set ID
  get lossSetID(): string {
    const lossSet = this.lossSetLayer.loss_sets[0] as LossSet | LoadedLossSet
    return this.isLoadedLossSet
      ? ((lossSet as LoadedLossSet).source as LossSet).id
      : lossSet.id
  }

  ngOnInit() {
    // Initialize scaled properties
    this.scaledProps = {
      id: this.lossSetLayer.id,
      description: this.description,
      lossScaleFactor: this.lossScaleFactor,
      originalPremium: this.originalPremium,
      premiumScaleFactor: this.premiumScaleFactor,
      newPremium: this.lossSetLayer.premium.value,
      newExpenseRatio: this.expenseRatio,
      dirty: false,
      lossSetID: this.lossSetID,
    }
  }

  setValue(property: string, value: any): void {
    this.scaledProps = set(lensPath([property]), value, this.scaledProps)

    // Premium Scale Factor and Premium are directly relatedPremium Scale
    if (property === 'premiumScaleFactor') {
      this.scaledProps = set(
        lensPath(['newPremium']),
        this.originalPremium * value,
        this.scaledProps
      )
    } else if (property === 'newPremium') {
      if (this.originalPremium !== 0) {
        this.scaledProps = set(
          lensPath(['premiumScaleFactor']),
          value / this.originalPremium,
          this.scaledProps
        )
      }
    }

    if (this.isDirty) {
      this.scaledProps = set(lensPath(['dirty']), true, this.scaledProps)
    } else {
      this.scaledProps = set(lensPath(['dirty']), false, this.scaledProps)
    }

    this.scaledProps = set(
      lensPath(['description']),
      this.description,
      this.scaledProps
    )

    this.scaleChange.emit(this.scaledProps)
  }
}
