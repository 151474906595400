<div class="wrapper">
  <div class="title-container">
    <div class="title-text">
      <h1 mat-dialog-title class="title">{{ headerText }}</h1>
    </div>
    <div class="title-right">
      <button appButtonIcon class="closeX clear" (click)="destroyDialog()">
        close
      </button>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="action-text">
      <span
        >You are about to exclude the following layer from the dataset, would
        you like to continue?</span
      >
    </div>
    <div class="content">
      <div class="layer-details">
        <div
          class="layer-detail-column"
          *ngFor="let detail of datum; let i = index"
        >
          <span class="detail-header">
            {{ columnHeaders[i] }}
          </span>
          <span class="detail-value" *ngIf="checkIfPercentageField(detail)">
            {{ formatColumnByType(detail) | percent: '1.0-3' }}
          </span>
          <span class="detail-value" *ngIf="!checkIfPercentageField(detail)">
            {{ formatColumnByType(detail) }}
          </span>
        </div>
      </div>
      <div class="actions">
        <div class="action-options" *ngIf="hasLine">
          <mat-checkbox
            [checked]="saveCurve"
            (change)="saveCurve = $event.checked"
            [matTooltip]="saveCurveTooltip"
            class="save-to-db"
            >Save to Database?</mat-checkbox
          >
        </div>
        <div class="new-name-container">
          <mat-form-field
            appearance="fill"
            *ngIf="saveCurve && dataNameTaken && !(pricingCurve.id >= 0)"
          >
            <mat-label>Data Label</mat-label>
            <input type="text" matInput [(ngModel)]="newCurveLabel" />
          </mat-form-field>
        </div>
        <div class="action-buttons">
          <div
            class="button-container"
            [matTooltip]="buttonTooltipText"
            [matTooltipDisabled]="
              !hasLine ||
              pricingCurve.id >= 0 ||
              !saveCurve ||
              (saveCurve && !saveCurve && !isDataNameConflicting)
            "
          >
            <button
              appButton
              big
              accent
              (click)="submitDialog()"
              [disabled]="saveCurve && isDataNameConflicting && hasLine"
            >
              Continue
            </button>
          </div>
          <button appButton big accent (click)="destroyDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
