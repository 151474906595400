import { coerceNumberProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CheckboxSelectChangeEvent } from './checkbox-select-button.component'
import {
  CheckboxSelectDialogComponent,
  CheckboxSelectDialogOptions,
  CheckboxSelectItem,
} from './checkbox-select-dialog.component'

export const checkboxSelectClass = 'app-dialog-invisible'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-checkbox-select',
  template: `
    <app-checkbox-select-button
      dropdown
      [label]="data.label"
      [items]="data.items"
      [selected]="data.selected"
      [parent]="data.parent"
      [allowSelection]="data.allowSelection"
      [accent]="data.accent"
      (selectChange)="selectChange.emit($event)"
      (buttonClick)="onButtonClick()"
    ></app-checkbox-select-button>
  `,
})
export class CheckboxSelectComponent<T extends CheckboxSelectItem>
  implements OnDestroy
{
  private destroy$ = new Subject<void>()

  data: CheckboxSelectDialogOptions<T>

  @Input() set label(value: string | undefined) {
    this.updateData({ label: value })
  }
  @Input() set items(value: T[]) {
    this.updateData({ items: value })
  }
  @Input() set selected(value: Record<string, boolean>) {
    this.updateData({ selected: value })
  }
  @Input() set parent(value: T | undefined) {
    this.updateData({ parent: value })
  }
  @Input() set allowSelection(value: boolean | undefined) {
    this.updateData({ allowSelection: value })
  }
  @Input() set accent(value: boolean | undefined) {
    this.updateData({ accent: value })
  }

  // Elevation
  @Input() set elevation(value: any) {
    this._elevation = coerceNumberProperty(value)
  }
  get elevation() {
    return this._elevation
  }
  _elevation = 0

  @Output() selectChange = new EventEmitter<CheckboxSelectChangeEvent<T>>()

  get id() {
    let _id = ''
    if (this.data) {
      if (this.data.parent) {
        _id = this.data.parent.id
      } else if (this.data.items) {
        _id = this.data.items[0]?.id ?? '0'
      }
    }
    return `${checkboxSelectClass}_${_id}`
  }

  constructor(private dialog: MatDialog, private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    const ref = this.getDialogRef()
    if (ref) {
      ref.close()
    }
  this.destroy$.next()
    this.destroy$.complete()
  }

  updateData(update: Partial<CheckboxSelectDialogOptions<T>>) {
    this.data = Object.assign({}, this.data, update)
    const ref = this.getDialogRef()
    if (ref && ref.componentInstance) {
      ref.componentInstance.setData(this.data)
    }
  }

  onButtonClick() {
    let ref = this.getDialogRef()
    if (ref) {
      return
    }

    const data: CheckboxSelectDialogOptions<T> = {
      triggerRef: this.elementRef,
      leftOffset: -32,
      topOffset: -34,
      expectedWidth: 256,
      expectedHeight: 273,
      panelClass: checkboxSelectClass,
      ...this.data,
    }

    ref = this.dialog.open<CheckboxSelectDialogComponent<T>>(
      CheckboxSelectDialogComponent,
      {
        id: this.id,
        panelClass: [
          checkboxSelectClass,
          `app-elevation-${this.elevation + 1}`,
        ],
        backdropClass: 'app-checkbox-select-backdrop',
        data,
      }
    )

    ref.componentInstance.selectChange$
      .pipe(takeUntil(ref.afterClosed()))
      .subscribe(value => {
        this.selectChange.emit(value)
      })

    ref.afterClosed().subscribe(() => {
      ref = null
    })
  }

  private getDialogRef(): CheckboxSelectDialogRef<T> | undefined | null {
    return this.dialog.getDialogById(this.id)
  }
}

type CheckboxSelectDialogRef<T extends CheckboxSelectItem> = MatDialogRef<
  CheckboxSelectDialogComponent<T>,
  CheckboxSelectDialogOptions<T>
>
