<div class="main">
  <h1 mat-dialog-title>Set Unconfigured Reinsurers</h1>
  <div mat-dialog-content>
    <div class="content">
      <div class="error-table">
        <div class="reinsurer-table-groups" *ngFor="let reinsurer of modifiedReinsurers">
          <h3>{{layerNames[reinsurer.reinsurer.cededlayerID]}}
            <hr>
          </h3>
          <app-sort-table
            class="table"
            spacing="tight"
            [columnDefs]="columnDefs"
            [rows]="reinsurer.reinsurer.riskAssignedLinesLink"
            [disableSort]="true"
            (valueChange)="updateLineName($event)"
            ></app-sort-table>
        </div>
      </div>
      <i class="hint">*Setting unconfigured reinsurer names are for your reference in SAGE. When syncing back to Whitespace, the lines will match up to the original Whitespace unconfigured reinsurer regardless of what you select in SAGE.
      <br><br>**Please ensure that you pick the correct line to avoid potential mistakes.</i>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button appButton border mat-dialog-close>Cancel</button>
    <button appButton border accent (click)="save()">Confirm</button>
  </div>
</div>
