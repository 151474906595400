import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import {
  LossSetGroup,
  LossSetGroupMember,
} from '../../../app/analysis/model/loss-set-layers.model'
import { ApiResponse } from '../model/api.model'
import {
  LossSetGroupMemberResponse,
  LossSetGroupResponse,
} from '../model/backend.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import {
  convertLossSetGroupFromResponse,
  convertLossSetGroupMemberFromResponse,
  convertLossSetGroupMemberToRequest,
  convertLossSetGroupToRequest,
} from './loss-set-group.converter'

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

// tslint:disable: no-non-null-assertion
@Injectable({
  providedIn: 'root',
})
export class LossSetGroupService {
  url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.internalApi.base}`
  }

  addGroup(
    lossSetGroup: WithOptional<LossSetGroup, 'id'>
  ): ApiResponse<LossSetGroup> {
    const url = `${this.url}/losssetgroup`
    const req = convertLossSetGroupToRequest(lossSetGroup)
    return this.http.post<LossSetGroupResponse>(url, req).pipe(
      map(res => {
        return convertLossSetGroupFromResponse(res)
      }),
      map((res: LossSetGroup) => ({
        ...res,
        lossSetLayers: lossSetGroup.lossSetLayers,
      })),
      mapToMaybeData(),
      catchAndHandleError('Add Loss Set Group')
    )
  }

  deleteGroup(id: string): ApiResponse<LossSetGroup> {
    const url = `${this.url}/losssetgroup/${id}`
    return this.http
      .delete<LossSetGroupResponse>(url)
      .pipe(
        map(convertLossSetGroupFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Delete Loss Set Group')
      )
  }

  updateGroup(
    id: string,
    lossSetGroup: Partial<LossSetGroup>
  ): ApiResponse<LossSetGroup> {
    const url = `${this.url}/losssetgroup/${id}`
    const req = convertLossSetGroupToRequest(lossSetGroup)
    return this.http
      .put<LossSetGroupResponse>(url, req)
      .pipe(
        map(convertLossSetGroupFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Update Loss Set Group')
      )
  }

  addMember(
    lossSetGroupMember: WithOptional<LossSetGroupMember, 'id'>
  ): ApiResponse<LossSetGroupMember> {
    const url = `${this.url}/losssetgroupmember`
    const req = convertLossSetGroupMemberToRequest(lossSetGroupMember)
    return this.http.post<LossSetGroupMemberResponse>(url, req).pipe(
      map(convertLossSetGroupMemberFromResponse),
      map((res: LossSetGroupMember) => ({
        ...res,
      })),
      mapToMaybeData(),
      catchAndHandleError('Add Loss Set Group Member')
    )
  }

  deleteMember(id: string): ApiResponse<LossSetGroupMember> {
    const url = `${this.url}/losssetgroupmember/${id}`
    return this.http
      .delete<LossSetGroupMemberResponse>(url)
      .pipe(
        map(convertLossSetGroupMemberFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Delete Loss Set Group Member')
      )
  }
}
