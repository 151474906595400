import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { PricingCurveDataSettingsDialogPayload } from '../../dialog/pricing-curve-dialog.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-settings-dialog',
  styleUrls: ['pricing-curve-settings-dialog.component.scss'],
  templateUrl: 'pricing-curve-settings-dialog.component.html',
})
export class PricingCurveSettingsDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<PricingCurveSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PricingCurveDataSettingsDialogPayload
  ) {}

  layersVisible = true
  lineVisible = true
  displayLayerCheckbox = true
  displayLineCheckbox = true

  ngOnInit(): void {
    if (this.data) {
      this.layersVisible = this.data.layersVisible
      this.lineVisible = this.data.lineVisible
      this.displayLayerCheckbox = this.data.hasLayers
      this.displayLineCheckbox = this.data.hasLine
    }
  }

  destroyDialog(): void {
    this.matDialogRef.close()
  }

  updateVisibility(field: string, event: any): void {
    if (field === 'layer') {
      this.layersVisible = event.checked
    } else if (field === 'line') {
      this.lineVisible = event.checked
    }
  }

  updateCurveSettings(applyAll: boolean): void {
    this.matDialogRef.close({
      layersVisible: this.layersVisible,
      lineVisible: this.lineVisible,
      applyAll
    })
  }
}
