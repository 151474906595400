import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { PortfolioMetrics } from '../../analysis/model/portfolio-metrics.model'
import { Program } from '../../core/model/program.model'
import { ApiResponse } from '../model/api.model'
import {
  ProgramResponse,
  StructureTowerPreferencesRequest,
} from '../model/backend.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import {
  convertProgramFromResponse,
  convertProgramToRequest,
} from './program.converter'
import { UpdatePositionIndexesRequest } from 'src/app/tier/tier.model'
import { Study } from 'src/app/core/model/study.model'

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export interface TailMetricsRequest {
  tail_metrics_options: string
}

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.internalApi.base}${environment.internalApi.program.base}`
  }

  getAll(): ApiResponse<Program[]> {
    const url = `${this.url}`
    return this.http.get<ProgramResponse[]>(url).pipe(
      map(res => res.map(convertProgramFromResponse)),
      mapToMaybeData(),
      catchAndHandleError('Get All Programs')
    )
  }

  get(id: string): ApiResponse<Program> {
    const url = `${this.url}/${id}`
    return this.http
      .get<ProgramResponse>(url)
      .pipe(
        map(convertProgramFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Get Program')
      )
  }

  add(program: WithOptional<Program, 'id'>): ApiResponse<Program> {
    const url = `${this.url}`
    const req = convertProgramToRequest(program)
    return this.http
      .post<ProgramResponse>(url, req)
      .pipe(
        map(convertProgramFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Add Program')
      )
  }

  update(id: string, program: Partial<Program>): ApiResponse<Program> {
    const url = `${this.url}/${id}`
    const req = convertProgramToRequest(program)
    return this.http
      .put<ProgramResponse>(url, req)
      .pipe(
        map(convertProgramFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Update Program')
      )
  }

  updatePositionIndexes(req: UpdatePositionIndexesRequest): ApiResponse<Study> {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/updateindexes`
    return this.http.post<Study>(url, req).pipe(
      map(res => res),
      mapToMaybeData(),
      catchAndHandleError('Update Position Indexes')
    )
  }

  delete(id: string): ApiResponse<Program> {
    const url = `${this.url}/${id}`
    return this.http
      .delete<ProgramResponse>(url)
      .pipe(
        map(convertProgramFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Delete Program')
      )
  }

  setTowerPreferences(
    id: string,
    preferences: StructureTowerPreferencesRequest[]
  ): ApiResponse<any> {
    let url = `${environment.internalApi.base}${environment.internalApi.towerPreferences}`
    url = url.replace('{id}', id)
    return this.http
      .post<any>(url, preferences)
      .pipe(mapToMaybeData(), catchAndHandleError('Set Tower Preferenes'))
  }

  putTailMetricsOptions(
    id: string,
    options: Omit<PortfolioMetrics, 'returnPeriodData'>
  ): ApiResponse<Program> {
    const url = `${this.url}/${id}${environment.internalApi.program.tailMetrics}`
    const request: TailMetricsRequest = {
      tail_metrics_options: JSON.stringify(options),
    }
    return this.http
      .put<ProgramResponse>(url, request)
      .pipe(
        map(convertProgramFromResponse),
        mapToMaybeData(),
        catchAndHandleError('Program Put Tail Metrics')
      )
  }
}
