import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  CashFlowsChartType,
  CashFlowsAxisType,
  CashFlowsGraphData,
  CreditGrossActual,
} from '../../model/credit-cash-flows.model'
import { CreditTransaction } from '../../model/credit-structure.model'
import { CreditResults } from '../../model/credit-results.model'

export enum CashFlowsActions {
  SetCashFlowsChartType = '[Credit Cash Flows] Set Chart Type',
  SetCashFlowsAxisType = '[Credit Cash Flows] Set Axis Type',
  SetCashFlowsScenario = '[Credit Cash Flows] Set Selected Scenario',
  SetCashFlowsTransaction = '[Credit Cash Flows] Set Selected Transaction',
  GetCashFlowsData = '[Credit Cash Flows] Get Data',
  GetCashFlowsDataSuccess = '[Credit Cash Flows] Get Data - Success',
  GetCashFlowsDataFailure = '[Credit Cash Flows] Get Data - Failure',
  GetCashFlowsLayerResults = '[Credit Cash Flows] Get Layer Results',
  GetCashFlowsLayerResultsSuccess = '[Credit Cash Flows] Get Layer Results - Success',
  GetCashFlowsLayerResultsFailure = '[Credit Cash Flows] Get Layer Results - Failure',
  SaveCashFlowsDataAsPDF = '[Credit Cash Flows] Save cash flow as PDF',
}

export const SetCashFlowsChartType = createAction(
  CashFlowsActions.SetCashFlowsChartType,
  props<{ chartType: CashFlowsChartType }>()
)

export const SetCashFlowsAxisType = createAction(
  CashFlowsActions.SetCashFlowsAxisType,
  props<{ axisType: CashFlowsAxisType }>()
)

export const SetCashFlowsScenario = createAction(
  CashFlowsActions.SetCashFlowsScenario,
  props<{ scenario: CreditGrossActual }>()
)

export const SetCashFlowsTransaction = createAction(
  CashFlowsActions.SetCashFlowsTransaction,
  props<{ transaction: CreditTransaction }>()
)

export const GetCashFlowsData = createAction(CashFlowsActions.GetCashFlowsData)
export const GetCashFlowsDataFailure = createAction(
  CashFlowsActions.GetCashFlowsDataFailure,
  props<{ error: ApplicationError }>()
)
export const GetCashFlowsDataSuccess = createAction(
  CashFlowsActions.GetCashFlowsDataSuccess,
  props<{ data: CashFlowsGraphData }>()
)

export const GetCashFlowsLayerResults = createAction(
  CashFlowsActions.GetCashFlowsLayerResults
)
export const GetCashFlowsLayerResultsFailure = createAction(
  CashFlowsActions.GetCashFlowsLayerResultsFailure,
  props<{ error: ApplicationError }>()
)
export const GetCashFlowsLayerResultsSuccess = createAction(
  CashFlowsActions.GetCashFlowsLayerResultsSuccess,
  props<{ results: CreditResults }>()
)

export const SaveCashFlowsDataAsPDF = createAction(
  CashFlowsActions.SaveCashFlowsDataAsPDF
)
