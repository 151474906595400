import { MonetaryUnit } from '../../api/analyzere/analyzere.model'
import { Layer } from '../model/layers.model'
import {
  isLayerAgg,
  isLayerAggFeeder,
  isLayerBackAllocated,
  isLayerVisibleRisk,
} from '../model/layers.util'
import { LayerState } from '../store/ceded-layers/layers.reducer'

export interface ScenarioEvent {
  id: number
  [key: string]: number
}

export type AddScenarioEventPayload = Omit<ScenarioEvent, 'id'>

export interface ScenarioEventResult {
  id: number
  layers: EventLayer[]
  gross: number
  ceded: number
  net: number
}

export interface EventLayer extends LayerState {
  loss: number
  remaininAggregateLimit: number
  originalLimit: number
}

export interface ScenarioEventChange {
  id: number
  changes: Partial<ScenarioEvent>
}

export interface LossSetDataRow {
  trialid: number
  eventid: number
  loss: number
  day: number
}

export interface ViewYeltRow {
  TrialId: number
  EventId: number
  Sequence: number
  Loss: number
}

export interface EventCatalogDataRow {
  eventid: number
  lob_name: string
  loss_type: string
  vendor: string
  rate: number
}

export interface PortfoliosViewYELT {
  ceded: ViewYeltRow[]
  gross: ViewYeltRow[]
  net: ViewYeltRow[]
}

export interface LayersViewsYELT {
  [key: string]: ViewYeltRow[]
}

export const processEvent = (
  layerState: LayerState | EventLayer,
  loss: number
): EventLayer => {
  if (
    isLayerAggFeeder(layerState.layer) ||
    isLayerBackAllocated(layerState.layer) ||
    layerState.layer.meta_data.sage_layer_type === 'shared_limits' ||
    (layerState.layer.meta_data.sage_layer_type === 'cat_td' &&
      layerState.layer.meta_data.sage_layer_subtype === 'actual')
  ) {
    return {
      ...layerState,
      remaininAggregateLimit: 0,
      originalLimit: 0,
      loss: 0,
    }
  }
  const limit = getLimit(layerState.layer)
  const aggregateLimit: number =
    (layerState as EventLayer).remaininAggregateLimit !== undefined
      ? (layerState as EventLayer).remaininAggregateLimit
      : layerState.layer.physicalLayer.aggregateLimit.value
  return {
    ...layerState,
    loss,
    remaininAggregateLimit: aggregateLimit - loss,
    originalLimit:
      (layerState as EventLayer).originalLimit !== undefined
        ? (layerState as EventLayer).originalLimit
        : limit,
  }
}

export const buildFromPreviousEvent = (
  eventLayer: EventLayer,
  loss: number
): EventLayer => {
  const limit = getLimit(eventLayer.layer)
  const remaininAggregateLimit = eventLayer.remaininAggregateLimit
  const newLayer = JSON.parse(JSON.stringify(eventLayer)) as EventLayer
  if (remaininAggregateLimit <= limit) {
    setLimit(newLayer, remaininAggregateLimit <= 0 ? 0 : remaininAggregateLimit)
  } else {
    setLimit(newLayer, limit)
  }
  return processEvent(newLayer, loss)
}

export const getLimit = (layer: Layer) => {
  if (isLayerAgg(layer)) {
    return layer.physicalLayer.aggregateLimit.value
  } else if (isLayerVisibleRisk(layer)) {
    return (layer.physicalLayer.riskLimit as MonetaryUnit).value
  } else {
    return layer.physicalLayer.limit.value
  }
}

export const setLimit = (layerState: LayerState, limit: number) => {
  if (isLayerAgg(layerState.layer)) {
    layerState.layer.physicalLayer.aggregateLimit.value = limit
  } else if (isLayerVisibleRisk(layerState.layer)) {
    // tslint:disable-next-line: no-non-null-assertion
    layerState.layer.physicalLayer.riskLimit!.value = limit
  } else {
    layerState.layer.physicalLayer.limit.value = limit
  }
}
