import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  LossSetGroup,
  LossSetLayer,
} from '../../../analysis/model/loss-set-layers.model'
import {
  CombinedLossDistributionRow,
  LossDistributionRow,
} from '../../../core/model/loss-set-table.model'
import { Program } from '../../../core/model/program.model'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../../model/metrics.model'
import * as fromExploreActions from './explore.actions'
import { convertCombinedLossDistributionTable } from '../explore.util'
import { SummaryDataResponse, SummaryExportGroup } from '../explore.model'
import { ExploreSummaryView } from 'src/app/api/model/backend.model'

import {
  DEFAULT_SUMMARY_CHARTS,
  SUMMARY_CHART_METRICS_INFO,
  SUMMARY_CHARTS_INFO,
  SummaryChartGridOption,
  SummaryChartInfo,
  SummaryChartMetricsInfo,
} from '../explore-gross-summary-charts/summary-charts-model'


export interface LossSetTableState {
  lossDistributionDataTable: CombinedLossDistributionRow[]
  lossID: string | null
  lossType: string
  lossName: string
  filterValue: string
  isLossRatioView: boolean
  subjectPremiumAmt: number
  aggMethodLossDistributionTable: LossDistributionRow[]
}

export interface ModifierState {
  perspective: Perspective
  aggregationMethod: AggregationMethodType
  vartvar: VaRTVaR
  isLossRatioView?: boolean
}
export interface SummaryChartState {
  chartOptions: SummaryChartGridOption[]
  selectableChartsForTab: SummaryChartInfo[]
  selectableChartsForMetric: SummaryChartInfo[]
  hideMetrics: boolean
  lightChartMode: boolean
  chartsEntityLimit: number
  availableChartsForSummary: SummaryChartMetricsInfo
}

export const defaultChartState: SummaryChartState = {
  chartOptions: DEFAULT_SUMMARY_CHARTS,
  selectableChartsForMetric:[],
  selectableChartsForTab: Object.values(SUMMARY_CHARTS_INFO),
  lightChartMode: false,
  hideMetrics: false,
  chartsEntityLimit: 16,
  availableChartsForSummary: SUMMARY_CHART_METRICS_INFO,
}

export interface State extends EntityState<LossSetTableState> {
  program: Program | null
  lossSetLayers: LossSetLayer[]
  lossSetLayerGroups: LossSetGroup[]
  savedLossSetIDs: string[]
  grossPortfolioView: string | null
  loading: boolean
  error: boolean
  modifiers: ModifierState
  arrRP: number[]
  summaryData: SummaryDataResponse[]
  groupSummaryData: SummaryDataResponse[]
  explore_gross_views: ExploreSummaryView[]
  summaryLoading: boolean
  summaryRP: number[]
  chartState: SummaryChartState
  summaryExportGroups: SummaryExportGroup[]
  summaryExportLoading: boolean
}

export const adapter = createEntityAdapter<LossSetTableState>({
  // tslint:disable-next-line: no-non-null-assertion
  selectId: (lossSetTableState): string => lossSetTableState.lossID!,
})

export const initialModifierState: ModifierState = {
  perspective: 'Loss',
  aggregationMethod: 'OEP',
  vartvar: 'VaR',
  isLossRatioView: false,
}

export const initialState: State = adapter.getInitialState({
  program: null,
  lossSetLayers: [],
  lossSetLayerGroups: [],
  savedLossSetIDs: [],
  grossPortfolioView: null,
  loading: false,
  error: false,
  modifiers: initialModifierState,
  arrRP: [1000, 500, 250, 200, 100, 50, 25, 10, 5, 2, 1],
  summaryData: [],
  groupSummaryData: [],
  explore_gross_views: [],
  summaryLoading: false,
  summaryRP: [10, 25, 50, 100, 200, 250],
  chartState: defaultChartState,
  summaryExportGroups: [],
  summaryExportLoading: false
})

const exploreReducer = createReducer(
  initialState,

  on(fromExploreActions.setExploreProgram, (state: State, { program }) => {
    return { ...state, program, loading: true, error: false }
  }),

  on(
    fromExploreActions.getExploreLossSetLayersAndGroups,
    (state: State, { lossSetLayers, lossSetLayerGroups }) => {
      return { ...state, lossSetLayers, lossSetLayerGroups }
    }
  ),

  on(
    fromExploreActions.addLossSetIDs,
    (state: State, { lossSetGroups, lossSetLayers }) => {
      const savedIDs = state.savedLossSetIDs.slice()
      lossSetLayers?.forEach(({ lossID }) => {
        if (savedIDs.indexOf(lossID) === -1) {
          savedIDs.push(lossID)
        }
      })
      lossSetGroups?.forEach(({ lossID }) => {
        if (savedIDs.indexOf(lossID) === -1) {
          savedIDs.push(lossID)
        }
      })
      return { ...state, loading: true, savedLossSetIDs: savedIDs }
    }
  ),

  on(
    fromExploreActions.removeLossSetIDs,
    (state: State, { lossSetGroupsToRemove, lossSetLayersToRemove }) => {
      const savedIDs = state.savedLossSetIDs.slice()
      lossSetGroupsToRemove.forEach(lossID => {
        savedIDs.splice(savedIDs.indexOf(lossID), 1)
      })
      lossSetLayersToRemove.forEach(lossID => {
        savedIDs.splice(savedIDs.indexOf(lossID), 1)
      })
      state = { ...state, savedLossSetIDs: savedIDs }
      return adapter.removeMany(
        [...lossSetGroupsToRemove, ...lossSetLayersToRemove],
        state
      )
    }
  ),

  on(fromExploreActions.clearExplore, (_state: State) => {
    return initialState
  }),

  on(
    fromExploreActions.getGrossPortfolioViewIDSuccess,
    (state: State, { grossPortfolioView }) => {
      return { ...state, grossPortfolioView }
    }
  ),

  on(
    fromExploreActions.getLossDataCollectionSuccess,
    (state: State, { payload }) => {
      const lossSetTableStates: LossSetTableState[] = []
      payload.forEach(p => {
        const lossSetTableState: LossSetTableState = {
          lossDistributionDataTable: p.dataTable,
          lossID: p.lossID,
          lossType: p.lossType,
          lossName: p.lossName,
          filterValue: p.filterValue,
          isLossRatioView: p.isLossRatioView ?? false,
          subjectPremiumAmt: p.subjectPremiumAmt || 0,
          aggMethodLossDistributionTable: convertCombinedLossDistributionTable(
            p.dataTable,
            state.modifiers.aggregationMethod
          ),
        }
        lossSetTableStates.push(lossSetTableState)
      })

      return adapter.upsertMany(lossSetTableStates, {
        ...state,
        loading: false,
      })
    }
  ),
  on(
    fromExploreActions.updateLossDataModifiers,
    (
      state: State,
      { rp, index, perspective, aggregationMethod, vartvar, isLossRatioView }
    ) => {
      const newRP = state.arrRP.slice()
      if (rp && index !== undefined) {
        newRP.splice(index, 1, rp)
      }
      return {
        ...state,
        loading: true,
        error: false,
        arrRP: newRP,
        modifiers: {
          perspective,
          aggregationMethod,
          vartvar,
          isLossRatioView,
        },
      }
    }
  ),

  on(fromExploreActions.updateLossDataModifiersSuccess, (state: State) => {
    return { ...state, loading: false }
  }),

  on(
    fromExploreActions.getGrossPortfolioViewIDFailure,
    fromExploreActions.getLossDataCollectionError,
    fromExploreActions.updateLossDataModifiersError,
    (state: State, {}) => {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }
  ),
  on(fromExploreActions.resetExploreContainer, () => ({ ...initialState })),
  on(
    fromExploreActions.getSummaryDataSuccess,
    (state: State, { summaryData }) => {
      return {
        ...state,
        summaryData,
      }
    }
  ),
  on(
    fromExploreActions.getSummaryGroupDataSuccess,
    (state: State, { groupSummaryData }) => {
      return {
        ...state,
        groupSummaryData,
      }
    }
  ),
  on(
    fromExploreActions.fetchSummaryViewsSuccess,
    (state: State, { explore_gross_views }) => {
      return {
        ...state,
        explore_gross_views,
      }
    }
  ),
  on(
    fromExploreActions.getSummaryGroupDataSuccess,
    (state: State, { groupSummaryData }) => {
      return {
        ...state,
        groupSummaryData,
      }
    }
  ),
  on(
    fromExploreActions.fetchSummaryViewsSuccess,
    (state: State, { explore_gross_views }) => {
      return {
        ...state,
        explore_gross_views,
      }
    }
  ),
  on(fromExploreActions.deleteSummaryFilterViewSuccess, (state, { id }) => {
    const explore_gross_views = state.explore_gross_views.filter(
      v => v.id !== id
    )
    return {
      ...state,
      explore_gross_views,
    }
  }),
  on(fromExploreActions.updateSummaryLoading, (state, { loading }) => ({
    ...state,
    summaryLoading: loading,
  })),
  on(fromExploreActions.updateSummaryExportLoading, (state, { loading }) => ({
    ...state,
    exportSummaryLoading: loading,
  })),
  on(fromExploreActions.updateSummaryRP, (state, { rp }) => ({
    ...state,
    summaryRP: [...rp].sort((a, b) => a - b),
  })),
  on(
    fromExploreActions.updateSelectedChartGridOption,
    (state, { chartIndex, chartOption }) => {
      const updatedCharts = [...state.chartState.chartOptions]
      if (chartOption.chart.type === updatedCharts[chartIndex].chart.type) {
        updatedCharts.splice(chartIndex, 1, chartOption)
      } else {
        updatedCharts.splice(chartIndex, 1, {
          ...updatedCharts[chartIndex],
          chart: chartOption.chart,
        })
      }
      return {
        ...state,
        chartState: {
          ...state.chartState,
          chartOptions: updatedCharts,
        },
      }
    }
  ),
  on(fromExploreActions.setChartEntityLimit, (state, { limit }) => {
    return {
      ...state,
      chartState: {
        ...state.chartState,
        chartsEntityLimit: limit,
      },
    }
  }),
  on(fromExploreActions.updateLightChartMode, (state, { lightChartMode }) => {
    return {
      ...state,
      chartState: {
        ...state.chartState,
        lightChartMode,
      },
    }
  }),
  on(
    fromExploreActions.updateSelectedChartGridGroupBy,
    (state, { selectedGroupBy, chartIndex }) => {
      const updatedCharts = [...state.chartState.chartOptions]
      const availableCharts = Object.values(state.chartState.availableChartsForSummary)
      const updatedChart = availableCharts.find(chart => {
        return chart.columnName === selectedGroupBy.columnName
      })
      const metric = Object.values(SUMMARY_CHART_METRICS_INFO).find(met => {
        return met.columnName === selectedGroupBy.columnName
      })
      updatedCharts[chartIndex] = {
        index: chartIndex,
        loading: false,
        highlight: false,
        chart: updatedChart.applicableCharts[0],
        metric: metric
      }
      return {
        ...state,
        chartState: {
          ...state.chartState,
          chartOptions: updatedCharts
        }
      }
    }
  ),
  on(
    fromExploreActions.getSummaryExportGroups,
    (state, { exportGroups }) => {
      const groups = exportGroups ?? state.summaryExportGroups
      const summaryExportGroups = groups.filter(g => !!g.name)
      return {
        ...state,
        summaryExportGroups
      }
    }
  ),
  on(
    fromExploreActions.getSummaryExportGroupsSuccess,
    (state, { exportGroupSummaryData, name }) => {
      const groups = [...state.summaryExportGroups].filter(g => !!g.name)
      const exportGroup = {...groups.find(g => g.name === name)}
      let summaryExportGroups = groups
      if (exportGroup && exportGroup.name) {
        summaryExportGroups = groups.filter(g => g.name !== name)
        summaryExportGroups.push({
          ...exportGroup,
          data: exportGroupSummaryData
        })
      }
      return {
        ...state,
        summaryExportGroups
      }
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return exploreReducer(state, action)
}
