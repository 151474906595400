import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '../../shared/shared.module'
import { ExploreRoutingModule } from './explore-routing.module'
import { ExploreComponent } from './explore/explore.component'
import { ExploreContainerComponent } from './explore.container/explore.container'
import { GrossLossDistributionTableComponent } from './gross-loss-table/gross-loss-table.component'
import { GrossLossDistributionGraphComponent } from './gross-loss-graph/gross-loss-graph.component'
import { TierModule } from '../../tier/tier.module'
import { ReactiveFormsModule } from '@angular/forms'
import { ExploreEffects } from './store/explore.effects'
import { EffectsModule } from '@ngrx/effects';
import { ExploreGrossSidebarComponent } from './explore-gross-sidebar/explore-gross-sidebar.component';
import { ExploreGrossSummaryTableComponent } from './explore-gross-summary-table/explore-gross-summary-table.component';
import { ExploreGrossSummaryFilterViewContainerComponent } from './explore-gross-summary-filter-view-container/explore-gross-summary-filter-view-container.component';
import { ExploreGrossSummaryFilterViewDialogComponent } from './explore-gross-summary-filter-view-container/explore-gross-summary-filter-view-dialog/explore-gross-summary-filter-view-dialog.component';
import { ExploreGrossSummaryChartsComponent } from './explore-gross-summary-charts/component/explore-gross-summary-charts.component'
import { ExploreSummaryChartGridComponent } from './explore-gross-summary-charts/chart-grid/explore-summary-chart-grid.component'
import { ExploreSummaryChartGridItemFrameComponent } from './explore-gross-summary-charts/chart-grid/explore-summary-chart-grid-item-frame.component'
import { ExploreSummaryBubbleChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-bubble-chart.component'
import { ExploreSummaryGroupBarChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-group-bar-chart.component'
import { ExploreSummaryPercentStackedBarChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-percent-stacked-bar-chart.component'
import { ExploreSummaryStackedBarChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-stacked-bar-chart.component'
import { ExploreSummaryChartControlsComponent } from './explore-gross-summary-charts/chart-controls/explore-summary-chart-controls.component'
import { ExploreSummaryChartMenuComponent } from './explore-gross-summary-charts/chart-menu/explore-summary-chart-menu.component'
import { ExploreSummarySideChartControlsComponent } from './explore-gross-summary-charts/side-chart-controls/explore-summary-side-chart-controls.component'
import { ExploreSummaryGridSelectComponent } from './explore-gross-summary-charts/grid-select/explore-summary-grid-select.component'
import { ExploreSummaryDonutPieChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-donut-pie-chart.component'
import { ExploreSummaryPercentStackedAreaChartComponent } from './explore-gross-summary-charts/chart-types/explore-summary-percent-stacked-area-chart.component'
import { ExploreMultiSelectExportDialogComponent } from './export/multi-select-export/explore-multi-select-export-dialog/explore-multi-select-export-dialog.component'
import { ExploreMultiSelectExportDialogContainerComponent } from './export/multi-select-export/explore-multi-select-export-dialog-container/explore-multi-select-export-dialog.container';
import { ExploreMultiSelectExportPreviewPageComponent } from './export/multi-select-export/explore-multi-select-export-preview-page/explore-multi-select-export-preview-page.component'

@NgModule({
  declarations: [
    ExploreContainerComponent,
    ExploreComponent,
    GrossLossDistributionTableComponent,
    GrossLossDistributionGraphComponent,
    ExploreGrossSidebarComponent,
    ExploreGrossSummaryTableComponent,
    ExploreGrossSummaryFilterViewContainerComponent,
    ExploreGrossSummaryFilterViewDialogComponent,
    ExploreGrossSummaryChartsComponent,
    ExploreSummaryChartGridComponent,
    ExploreSummaryChartGridItemFrameComponent,
    ExploreSummaryBubbleChartComponent,
    ExploreSummaryDonutPieChartComponent,
    ExploreSummaryGroupBarChartComponent,
    ExploreSummaryPercentStackedBarChartComponent,
    ExploreSummaryStackedBarChartComponent,
    ExploreSummaryChartControlsComponent,
    ExploreSummaryChartMenuComponent,
    ExploreSummarySideChartControlsComponent,
    ExploreSummaryGridSelectComponent,
    ExploreSummaryPercentStackedAreaChartComponent,
    ExploreMultiSelectExportDialogComponent,
    ExploreMultiSelectExportDialogContainerComponent,
    ExploreMultiSelectExportPreviewPageComponent
  ],
  imports: [
    CommonModule,
    ExploreRoutingModule,
    SharedModule,
    TierModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([ExploreEffects]),
  ],
})
export class ExploreModule {}
