import { Observable } from 'rxjs'
import { Component, OnInit } from '@angular/core'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import {
  selectCurrentClientID,
  selectCurrentStudyID,
} from 'src/app/analysis/store/analysis.selectors'
import { selectAccountOpportunities } from 'src/app/core/store/accountopportunity.selectors'
import { Study } from 'src/app/core/model/study.model'
import { selectCurrentYearStudies } from 'src/app/core/store/broker/broker.selectors'
import { selectIsUsaBasedUser } from 'src/app/core/store/auth/auth.selectors'

@Component({
  selector: 'app-open-twin-new-risk-dialog-container',
  templateUrl: './open-twin-new-risk-dialog.container.html',
})
export class OpenTwinNewRiskDialogContainerComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  accountOpportunities$: Observable<AccountOpportunity[] | null>
  hasCoBroker$: Observable<boolean | undefined>
  selectedClientID$: Observable<string | null>
  selectedStudyID$: Observable<string | null>
  programs$: Observable<readonly Study[]>
  isUsaBasedUser$: Observable<boolean | null>

  ngOnInit(): void {
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.selectedStudyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.programs$ = this.store.pipe(select(selectCurrentYearStudies))
    this.isUsaBasedUser$ = this.store.pipe(select(selectIsUsaBasedUser))
  }
}
