<div class="main">
  <h1 mat-dialog-title>ILW Layer Selection Menu</h1>
  <div mat-dialog-content>
    <div class="content">
      <mat-form-field>
        <mat-label>ILW Selection</mat-label>
        <mat-select [(value)]="ilwSelect">
          <mat-option *ngFor="let option of ilwOptions" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button appButton border mat-dialog-close (click)="cancel()">Cancel</button>
    <button appButton border accent (click)="confirm()" class="confirm-button" [disabled]="ilwSelect === ''">Confirm</button>
  </div>
</div>
