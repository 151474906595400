import { Component, Output, EventEmitter, Input } from '@angular/core'
import { AddProgramGroupProps } from '../../../store/grouper/program-group/program-group.actions'
import {
  GrouperSlide,
  ProgramGroupMember,
} from '../../../store/grouper/program-group.model'

@Component({
  selector: 'app-group-button',
  styleUrls: ['./group-button.component.scss'],
  templateUrl: './group-button.component.html',
})
export class GroupButtonComponent {
  @Input() showAddGroupButton: boolean
  @Input() slides: GrouperSlide[] | null
  @Input() programGroupMembers: ProgramGroupMember[]

  @Output() saveUntitled = new EventEmitter<AddProgramGroupProps>()

  onSaveUntitled($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    if (this.slides != null) {
      if (this.slides[0].groupBars[0] != null) {
        const groupBar1 = this.slides[0].groupBars[0]
        const programIDs = this.programGroupMembers
          .filter(member => member.type === 'program' && member.programID)
          .map(m => {
            return m.programID
          })
        const isDuplicate = programIDs.filter(
          (member, index) => programIDs.indexOf(member) !== index
        )
        return this.saveUntitled.emit({
          id: 'root',
          parentGroupID: groupBar1 ? groupBar1.id : undefined,
          isDuplicate: isDuplicate.length > 0,
        })
      }
    }
  }
}
