export const colors: readonly string[] = [
  '#e67e22',
  '#3498db',
  '#1abc9c',
  '#9b59b6',
  '#f1c40f',
  '#e74c3c',
  '#ecf0f1',
  '#34495e',
  '#2ecc71',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f39c12',
  '#d35400',
  '#c0392b',
] as const
