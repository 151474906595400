import { createAction, props } from '@ngrx/store'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import { ApplicationError } from '../../../../error/model/error'

export const setGroup = createAction(
  '[Animated Scenarios] Set Group',
  props<{ groupID: string }>()
)

export const setGroupSuccess = createAction(
  '[Animated Scenarios] Set Group Success',
  props<{ lossSetLayers: LossSetLayer[]; groupID: string }>()
)

export const setGroupFailure = createAction(
  '[Animated Scenarios] Set Group Failure',
  props<{ error: ApplicationError }>()
)

export const groupExecute = createAction('[Animated Scenarios] Group Execute')
export const groupExecuteSuccess = createAction(
  '[Animated Scenarios] Group  Execute Success'
)
export const groupExecuteFailure = createAction(
  '[Animated Scenarios] Group Execute Failure',
  props<{ error: ApplicationError }>()
)

export const setGroupAnimating = createAction(
  '[Animated Scenarios] Set Group Animating',
  props<{ value: boolean }>()
)

export const resetGroupEventsAndResults = createAction(
  '[Animated Scenarios] Reset Group Events And Results'
)
