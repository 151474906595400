import { AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements AfterViewInit{
  @Input() circle = false
  @Input() delay = String(Math.floor(Math.random() * 1500 * -1)) + 'ms'
  @ViewChild('skeleton') skeleton: ElementRef<HTMLDivElement>

  ngAfterViewInit(){
    this.skeleton.nativeElement.style.setProperty('animation-delay', this.delay)
    if (this.circle) {
      this.skeleton.nativeElement.style.setProperty('border-radius', '100%')
    }
  }

}
