import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { CreditResults } from '../../model/credit-results.model'

export const CreditResultsActions = createActionGroup({
  source: 'Credit Results',
  events: {
    'Set Selected Scenario': props<{ scenarioId: string }>(),
    'Set Selected Default Scenario': props<{ scenarioId: string }>(),
    Get: emptyProps(),
    'Get Success': props<{ results: CreditResults }>(),
    'Get Failure': props<{ error: ApplicationError }>(),
    'Get Default': emptyProps(),
    'Get Default Success': props<{ results: CreditResults }>(),
    'Get Default Failure': props<{ error: ApplicationError }>(),
  },
})
