import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-revenue-container',
  template: '<app-theme-container><app-revenue></app-revenue></app-theme-container>',
})
export class RevenueContainerComponent {}
