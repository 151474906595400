import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-save-add-dialog',
  styleUrls: ['./pricing-curve-save-add-dialog.component.scss'],
  templateUrl: './pricing-curve-save-add-dialog.component.html',
})
export class PricingCurveSaveAddDialogComponent implements OnInit {
  message: string
  confirmMessage: string
  cancelMessage: string
  disableCloseButton: boolean

  constructor(
    public matDialogRef: MatDialogRef<PricingCurveSaveAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message?: string
      confirmMessage?: string
      cancelMessage?: string
      disableCloseButton?: boolean
    }
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.message = this.data.message ?? ''
      this.confirmMessage = this.data.confirmMessage ?? 'Confirm'
      this.cancelMessage = this.data.cancelMessage ?? 'Cancel'
      this.disableCloseButton = this.data.disableCloseButton ?? false
    }
  }

  onConfirm(): void {
    this.matDialogRef.close(true)
  }

  onCancel(): void {
    this.matDialogRef.close(false)
  }
}
