import { Actions, ofType } from '@ngrx/effects'
import { ActionCreator } from '@ngrx/store'
import { forkJoin, MonoTypeOperatorFunction } from 'rxjs'
import { first, map, mergeMap } from 'rxjs/operators'
import { toArray } from './util/operators'

/**
 * Operator function that, give a stream of Actions, will wait until the
 * provided list of action types have passed through the stream before
 * returning.
 *
 * E.g., given actionTypePairs:
 *   `[ [ActionASuccess, ActionAError], [ActionBSuccess, ActionBError] ]`
 * will return once one of the first pair and one of the second pair have
 * been dispatched.
 */
export const waitFor = <T>(
  actions$: Actions,
  actionTypePairs:
    | Array<ActionCreator[] | ActionCreator>
    | ActionCreator[]
    | ActionCreator
): MonoTypeOperatorFunction<T> =>
  mergeMap(input => {
    const _a: any = Array.isArray(actionTypePairs)
      ? actionTypePairs
      : [[actionTypePairs]]
    return forkJoin(
      _a.map((pair: any) => {
        const _pair = toArray(pair)
        return actions$.pipe(ofType(..._pair), first())
      })
    ).pipe(map(() => input))
  })

// export declare function concatLatestFrom
//   <T extends Observable<unknown>[], V>
//   (observablesFactory: (value: V) => [...T]):
//     OperatorFunction<
//       V, [V, ...{
//         [i in keyof T]: ObservedValueOf<T[i]>
//       }]>
// export declare function concatLatestFrom<T extends Observable<unknown>, V>
//   (observableFactory: (value: V) => T):
//     OperatorFunction<V, [V, ObservedValueOf<T>]>

// private concatLatestFrom<T extends Observable<unknown>[], V>(observablesFactory: (value: V) => [...T]): OperatorFunction<V, [V, ...{
//   [i in keyof T]: ObservedValueOf<T[i]>
// }]> {
//   return pipe(
//     concatMap(value => {
//       const observables = observablesFactory(value)
//       const observablesAsArray = Array.isArray(observables)
//         ? observables
//         : [observables]
//       return of(value).pipe(withLatestFrom(...observablesAsArray))
//     })
//   )
// }
