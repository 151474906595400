import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fac-logo, button[appFacLogo], a[appFacLogo]',
  styles: [
    `
           :host {
             background: transparent;
             color: var(--body);
             white-space: nowrap;
             text-decoration: none;
             vertical-align: baseline;
             margin: 0;
             padding: 0;
             outline: none;
             border: none;
             user-select: none;
           }
     
           :host-context(.app-theme-logo-color) {
             position: relative;
           }
     
           h1,
           span {
             font-family: var(--font-header-family);
             font-weight: var(--font-header-weight);
             font-size: 72px;
             -webkit-font-smoothing: subpixel-antialiased;
           }
     
           :host-context(.app-theme-logo-color) h1 {
             display: none;
           }
     
           img {
             display: none;
             height: auto;
             width: calc(100% - 17px);
           }
     
           :host-context(.app-theme-logo-color) img {
             display: block;
           }
     
           :host-context(.app-theme-logo-color).big img {
             width: calc(100% - 1.5vw);
           }
     
           :host.big h1 {
             font-size: 96px;
           }
     
           span {
             font-size: 0.6em;
           }
     
           span.color-tm {
             display: none;
             font-weight: var(--font-weight);
           }
     
           :host-context(.app-theme-logo-color) span.color-tm {
             display: block;
             position: absolute;
             bottom: 0;
             right: 0;
           }
     
           :host-context(.app-theme-logo-color).big span.color-tm {
             font-size: 1.2vw;
             bottom: 0.1vw;
             right: 0;
           }
     
           @media screen and (min-width: 1618px) {
             :host-context(.app-theme-logo-color).big img {
               width: calc(100% - 25px);
             }
     
             :host-context(.app-theme-logo-color).big span.color-tm {
               font-size: 19.5px;
             }
           }
         `,
  ],
  template: `
    <h1>FacSAGE<span>TM</span></h1>
    <img src="assets/logos/fac-sage-selector.png" alt="Sage Logo" />
    <span class="color-tm">TM</span>
  `,
})
export class FacAppLogoComponent {
  // Big
  @Input() set big(value: any) {
    this._big = coerceBooleanProperty(value)
  }
  get big() {
    return this._big
  }
  @HostBinding('class.big') _big = false
}
