import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { Client, ClientYear } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import { AppState } from '../../core/store'
import {
  selectCurrentClientID,
  selectCurrentClientYears,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectCurrentYearStudies,
  selectCurrentStructureID,
  selectCurrentClientID2,
  selectCurrentYearID2,
  selectCurrentStudyID2,
  selectCurrentYearStudies2,
  selectCurrentClientYears2,
} from '../../core/store/broker/broker.selectors'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { selectClients } from '../../core/store/clients.selectors'
import { NavService } from '../../nav.service'
import { Size } from '@shared/size.mixin'
import { TierPath } from '../tier.model'
import { createTierPath } from '../tier.util'
import { Program } from '../../core/model/program.model'
import { LossSetLayer } from '../../api/analyzere/analyzere.model'
import { toArray } from '@shared/util/operators'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { AccountOpportunity } from '../../api/model/backend.model'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { QuoteStartProps } from '../../quote/store/quote.actions'
import {
  ProgramGroup,
  SharedLimit,
} from '../../analysis/store/grouper/program-group.model'
import {
  setCurrentClient2,
  setCurrentTierPath2,
  clearCheckedPrograms,
  setCurrentTierPath,
} from '../../core/store/broker/broker.actions'
import { SectionState } from '../../quote/store/section/section.reducer'
import { selectRouterStateUrl } from '../../core/store/router.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tier-bar-container',
  templateUrl: './tier-bar.container.html',
})
export class TierBarContainerComponent implements OnInit {
  routerUrl$: Observable<string>
  clients$: Observable<readonly Client[]>
  years$: Observable<readonly ClientYear[]>
  programs$: Observable<readonly Study[]>
  selectedClientID$: Observable<string | null>
  selectedYearID$: Observable<string | null>
  selectedProgramID$: Observable<string | null>
  selectedStructureID$: Observable<string | null>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  layersChangedInDesign$: Observable<string[] | null>

  currentClients: Client[]
  executiveSummaryHidden = false

  @Input() isButton = false
  @Input() hover = false
  @Input() clientSelect = false
  @Input() lobSelect = false
  @Input() contextPath?: string | string[]
  @Input() size: Size
  @Input() clients?: Client[]
  @Input() years?: ClientYear[]
  @Input() programs?: Study[]
  @Input() selectedClientID?: string | null
  @Input() selectedYearID?: string | null
  @Input() selectedProgramID?: string | null
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() title: string
  @Input() secondBar = false

  @Input() isExplore: boolean
  @Input() structures: Program[] | undefined
  @Input() programGroups: ProgramGroup[]
  @Input() lobs: LossSetLayer[]
  @Input() exploreLoading: boolean
  @Input() selectedStructureID: string | null
  @Input() selectedLob: number | null
  @Input() isQuote = false
  @Input() isSlipT = false
  @Input() isSignature = false
  @Input() isDesign = false
  @Input() isPricingCurve = false
  @Input() quoteLayers: LayerState[] = []
  @Input() selectedCededLayerID: string | null | undefined
  @Input() quoteDirty: boolean
  @Input() sharedLimits: SharedLimit[]
  @Input() sectionList: SectionState[] | null

  @Input() set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value)
  }
  get readonly() {
    return this._readonly
  }
  _readonly = false

  @Input() set enableHover(value: any) {
    this._enableHover = coerceBooleanProperty(value)
  }
  get enableHover() {
    return this._enableHover
  }
  _enableHover = false

  @Output() tierChange = new EventEmitter<TierPath>()
  @Output() groupOrStructureSelectionChange = new EventEmitter<
    'Group' | 'Structure' | 'SL' | null
  >()
  @Output() structureChange = new EventEmitter<Program>()
  @Output() lobChange = new EventEmitter<number>()
  @Output() exploreClear = new EventEmitter()
  @Output() layerSelect = new EventEmitter<QuoteStartProps>()
  @Output() groupSelect = new EventEmitter<QuoteStartProps>()
  @Output() slSelect = new EventEmitter<QuoteStartProps>()

  get contextPathList(): string[] {
    return !this.contextPath ? [] : toArray(this.contextPath)
  }

  constructor(private nav: NavService, private store: Store<AppState>) {}

  ngOnInit() {
    this.routerUrl$ = this.store.pipe(select(selectRouterStateUrl))
    this.clients$ = this.store.pipe(select(selectClients))
    if (this.secondBar) {
      this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID2))
      this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID2))
      this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID2))
      this.programs$ = this.store.pipe(select(selectCurrentYearStudies2))
      this.years$ = this.store.pipe(select(selectCurrentClientYears2))
    } else {
      this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
      this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
      this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID))
      this.programs$ = this.store.pipe(select(selectCurrentYearStudies))
      this.years$ = this.store.pipe(select(selectCurrentClientYears))
    }
    this.selectedStructureID$ = this.store.pipe(
      select(selectCurrentStructureID)
    )
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
  }
  prevpath: TierPath

  onTierChange($event: TierPath) {
    if (this.isPricingCurve) {
      this.store.dispatch(setCurrentTierPath($event))
      this.tierChange.emit($event)
      return
    }

    if (this.secondBar) {
      this.prevpath = $event
      if (
        this.prevpath.client !== null &&
        this.prevpath.client !== $event.client
      ) {
        this.store.dispatch(setCurrentClient2({ id: $event.client }))
      } else {
        this.store.dispatch(setCurrentTierPath2($event))
      }
    } else {
      this.store.dispatch(clearCheckedPrograms())
      if (this.clients == null) {
        if (this.clientSelect) {
          const path = createTierPath($event.client)
          this.nav.navigateWithTierPath(path, 'select')
        } else {
          this.nav.navigateWithTierPath($event, ...this.contextPathList)
        }
      }
      this.tierChange.emit($event)
    }
  }
}
