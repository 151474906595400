<div class="swap-loss-sets-dialog">
  <h1 mat-dialog-title (ondragover)="onDragOver($event)">{{ title }}</h1>
  <p>Drag and drop colored boxes to map old loss sets to new loss sets.</p>
  <p>Click boxes on new loss set list to remove mapping.</p>
  <div mat-dialog-content class="content">
    <div class="table">
      <div class="header">Current Loss Sets</div>
      <ul>
        <ng-container *ngFor="let lossSet of oldLossSets; let i = index">
          <li>
            <div
              class="draggable-tile"
              draggable="true"
              (dragstart)="onDragStart($event, lossSet)"
              [style]="styles[i]"
            >
              {{ getLetter(i) }}{{ i % 26 }}
            </div>
            <div ng-if="lossSet.meta_data.ls_dim1">
              {{
                concatCurrenLossSet(
                  lossSet.meta_data.ls_dim1,
                  lossSet.meta_data.ls_dim2
                )
              }}
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="table">
      <div class="header">New Loss Sets</div>
      <ul>
        <ng-container *ngFor="let lossSet of newLossSets; let i = index">
          <li (drop)="onDrop($event, lossSet)" (dragover)="onDragOver($event)">
            {{ lossSet.description }}
            <div
              *ngFor="
                let lossMap of getMappedLossSetsIndex(lossSet);
                let j = index
              "
              class="draggable-tile pop-tile"
              [style]="styles[lossMap]"
              (click)="removeMapping(lossSet, j)"
            >
              {{ getLetter(lossMap) }}{{ lossMap % 26 }}
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button
      appButton
      border
      center
      accent
      tabindex="2"
      (click)="onSwapClick()"
      [disabled]="currentMappingCount === 0"
    >
      Map Loss Sets
    </button>

    <button appButton link tabindex="3" [mat-dialog-close]="false">
      Cancel
    </button>
  </div>
</div>
