import { ScenarioEventState } from './scenario-event.reducer'
import { Action, on, createReducer } from '@ngrx/store'
import * as fromScenarioEventReducer from './scenario-event.reducer'
import * as fromScenariosEventResultsReducer from './scenario-event-result.reducer'
import * as fromAnimatedScenariosActions from './animated-scenarios.actions'
import * as fromScenarioEventActions from './scenario-event.actions'
import * as fromScenarioEventResultsActions from './scenario-event-result.actions'
import * as fromGroupAnimatedScenariosActions from './group/group-animated-scenarios.actions'
import * as fromGroupAnimatedScenariosReducer from './group/group-animated-scenarios.reducer'
import * as fromGroupScenarioEventActions from './group/group-scenario-event.actions'
import * as fromGroupScenarioEventResultsActions from './group/group-scenario-event-result.actions'
import { ScenarioEventResultState } from './scenario-event-result.reducer'
import { Program } from 'src/app/core/model/program.model'
import { saveSuccess } from '../../store/ceded-layers/layers.actions'
import { saveGrouper } from '../../store/grouper/grouper.actions'

export const ANIMATED_SCENARIOS_FEATURE_KEY = 'animatedScenarios'

export interface AnimatedScenariosState {
  eventState: ScenarioEventState
  eventResultState: ScenarioEventResultState
  executing: boolean
  error: string | null
  selectedStructure: Program | null
  animating: boolean
  groupScenarios: fromGroupAnimatedScenariosReducer.GroupAnimatedScenariosState
}

const initialState: AnimatedScenariosState = {
  eventState: fromScenarioEventReducer.initialState,
  eventResultState: fromScenariosEventResultsReducer.initialState,
  executing: false,
  error: null,
  selectedStructure: null,
  animating: false,
  groupScenarios: fromGroupAnimatedScenariosReducer.initialState,
}

const animatedScenariosReducer = createReducer(
  initialState,
  on(fromAnimatedScenariosActions.execute, state => ({
    ...state,
    executing: true,
    animating: true,
  })),
  on(fromAnimatedScenariosActions.setAnimating, (state, { value }) => ({
    ...state,
    animating: value,
  })),
  on(fromAnimatedScenariosActions.executeFailure, (state, { error }) => ({
    ...state,
    executing: false,
    error: error.message,
  })),
  on(fromAnimatedScenariosActions.executeSuccess, state => ({
    ...state,
    executing: false,
  })),
  on(
    fromScenarioEventResultsActions.addAllScenarioEventResults,
    saveSuccess,
    (state, action) => ({
      ...state,
      eventResultState: fromScenariosEventResultsReducer.reducer(
        state.eventResultState,
        action
      ),
    })
  ),
  on(
    fromScenarioEventActions.addAllScenarioEvents,
    fromScenarioEventActions.addScenarioEvent,
    fromScenarioEventActions.removeScenarioEvent,
    fromScenarioEventActions.updateScenarioEvent,
    (state, action) => ({
      ...state,
      eventState: fromScenarioEventReducer.reducer(state.eventState, action),
    })
  ),
  on(
    fromAnimatedScenariosActions.addSelectedStructure,
    (state, { structure }) => ({ ...state, selectedStructure: structure })
  ),

  on(fromAnimatedScenariosActions.resetEventsAndResults, state => {
    return { ...initialState, selectedStructure: state.selectedStructure }
  }),

  on(
    fromGroupAnimatedScenariosActions.setGroup,
    fromGroupAnimatedScenariosActions.setGroupSuccess,
    fromGroupAnimatedScenariosActions.setGroupFailure,
    fromGroupAnimatedScenariosActions.groupExecute,
    fromGroupAnimatedScenariosActions.groupExecuteFailure,
    fromGroupAnimatedScenariosActions.groupExecuteSuccess,
    fromGroupScenarioEventActions.addAllGroupScenarioEvents,
    fromGroupScenarioEventActions.addGroupScenarioEvent,
    fromGroupScenarioEventActions.removeGroupScenarioEvent,
    fromGroupScenarioEventActions.updateGroupScenarioEvent,
    (state, action) => ({
      ...state,
      groupScenarios: fromGroupAnimatedScenariosReducer.reducer(
        state.groupScenarios,
        action
      ),
    })
  ),

  on(
    fromGroupScenarioEventResultsActions.addAllGroupScenarioEventResults,
    fromGroupScenarioEventResultsActions.removeAllGroupScenarioEventResults,
    fromGroupAnimatedScenariosActions.setGroupAnimating,
    fromGroupAnimatedScenariosActions.resetGroupEventsAndResults,
    saveGrouper,
    (state, action) => ({
      ...state,
      groupScenarios: fromGroupAnimatedScenariosReducer.reducer(
        state.groupScenarios,
        action
      ),
    })
  )
)

export function reducer(
  state: AnimatedScenariosState | undefined,
  action: Action
) {
  return animatedScenariosReducer(state, action)
}
