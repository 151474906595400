import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'FullDateToYear',
})
export class FullDateToYear implements PipeTransform {
  /* Transforms from yyyy-MM-dd to yyyy */
  transform(date: string): string {
    const dateParts = date.split('-')
    return dateParts[0]
  }
}
