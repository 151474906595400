import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
  forwardRef,
} from '@angular/core'
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { clone } from 'ramda'
import {
  indexationFranchise,
  indexationFull,
  indexationSevere,
} from '../../../layers/indexed-layer'
import {
  isSwingLayer,
  swingBasisInAddToMin,
  swingBasisSubToMin,
} from '../../../layers/swing-layer'
import {
  LayerPaletteItem,
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
  layerIds,
} from '../../../model/layer-palette.model'
import { LayerView, unlimitedSetAttempt } from '../../../model/layer-view'
import { MatSnackBar } from '@angular/material/snack-bar'
import { LayerMetrics } from '../../../model/layers-metrics.model'
import {
  DropDownOptions,
  Layer,
  PhysicalLayer,
} from '../../../model/layers.model'
import {
  findActualTopAndDropLayer,
  findMultiSectionMainLayer,
  findRiskActualLayer,
  findSwingRatedCombinedLayer,
  findTDIDs,
  isAggLayer,
  isLayerAgg,
  isLayerAggFeeder,
  isLayerTopOrDrop,
  isNoncatIndexlLayer,
} from '../../../model/layers.util'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import getLayerPropertyDefs, {
  LayerPropertyDefResolved,
} from '../layer-property-defs'
import {
  CustomIndexProperties,
  MultiSectionProperties,
  TopDropProperties,
} from '../../properties-panel.model'
import {
  CopyLayerState,
  LayerState,
  State,
} from '../../../store/ceded-layers/layers.reducer'
import { SharedLimitProperties } from '../../../store/grouper/program-group.model'
import {
  CurrencyCode,
  MonetaryUnit,
  Reinstatement,
} from '../../../../api/analyzere/analyzere.model'
import { Program } from '../../../../core/model/program.model'
import { Reinsurer } from '../../../../core/model/reinsurer.model'
import { Reference } from '@shared/layer-property/layer-property.component'
import { LayerService } from '@shared/services/layer.service'
import { timer, Subscription } from 'rxjs'
import { SavedPricingCurveEntry } from '../../../../pricingcurve/model/pricing-curve.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import {
  DefaultSavedCurveEntry,
  LayerTypeDefaultEntry,
} from 'src/app/analysis/technical-premium/technical-premium.model'

@Component({
  selector: 'app-layer-properties',
  styleUrls: ['./layer-properties.component.scss'],
  templateUrl: './layer-properties.component.html',
})
export class LayerPropertiesComponent implements OnChanges, OnInit, OnDestroy {
  currencyControl = new FormControl()
  slCurrencyControl = new FormControl()
  selSLCurrency: string
  color: string
  view: LayerView | null
  layerPropertyDefs: LayerPropertyDefResolved[] = []
  layerName: string
  isNew: boolean
  topAndDropLayerEdit: LayerState
  topAndDropLayer: LayerState | null
  layerViewId: string
  copyingLayer = false
  canPasteLayer = false
  pastingLayer = false
  isDrop = false
  tempLayer: LayerState
  isEditPage: boolean
  lastSelectedViewID: string | null
  displayLayerID = ''
  showEditDeleteButton: boolean
  private layerEdit: LayerState
  private newSharedLayerEdit: SharedLimitProperties

  private _currentProgram: Program
  @Input() set currentProgram(value: Program | undefined | null) {
    this._currentProgram = value as Program
    if (this.view) {
      this.view.currentProgram = value as Program
    }
  }
  @Input() layer: LayerState | null | undefined

  private _layerViewsMetrics?: LayerMetrics
  @Input() set layerViewsMetrics(value: LayerMetrics) {
    this._layerViewsMetrics = value
    if (this.view) {
      this.view.metrics = value
    }
  }

  private _lossSetLayers: LossSetLayer[]
  @Input() set lossSetLayers(value: LossSetLayer[]) {
    this._lossSetLayers = value
    if (this.view) {
      this.view.lossSets = value
    }
  }

  private _reinsurersList: Reinsurer[]
  @Input() set reinsurersList(value: Reinsurer[]) {
    this._reinsurersList = value
    if (this.view) {
      this.view.reinsurers = value
    }
  }
  private _reinsurersListSL: Reinsurer[]
  @Input() set reinsurersListSL(value: Reinsurer[]) {
    this._reinsurersListSL = value
    if (this.view && this.selectedSharedLayer) {
      this.view.reinsurers = value
    }
  }

  @Input() layers: LayerState[] | null = []
  @Input() layerLoading: boolean

  @Input() selectedViewID: string | null
  @Input() selectedSharedLayer: Layer | null
  @Input() isAddSharedLayer = false
  @Input() isDeleteSharedLayer = false
  @Input() deletingSharedLimit = false
  @Input() updatingSharedLimit = false
  @Input() studyID: string | null
  @Input() sharedCurrentCurrency: string
  @Input() isLibRE: boolean
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  @Input() layerFilteredCurrencyList: CurrencyCode[]
  @Input() cededLayers: State

  @Input()
  set newSharedLayer(value: SharedLimitProperties) {
    this.newSharedLayerEdit = clone(value)
  }

  private _savedPricingCurves: SavedPricingCurveEntry[]
  @Input() set savedPricingCurves(value: SavedPricingCurveEntry[]) {
    this._savedPricingCurves = value
    if (this.view) {
      this.view.savedPricingCurves = value
    }
  }

  private _layerTypeEntries: LayerTypeDefaultEntry
  @Input() set layerTypeEntries(value: LayerTypeDefaultEntry) {
    this._layerTypeEntries = value
    if (this.view) {
      this.view.programDefaultPricingCurves = this.programDefaultPricingCurves
    }
  }

  get programDefaultPricingCurves(): DefaultSavedCurveEntry[] | undefined {
    if (this._layerTypeEntries && this.layer?.layer.meta_data.sage_layer_type) {
      return this._layerTypeEntries[this.layer.layer.meta_data.sage_layer_type]
        ?.pricingCurves
    }
  }

  set open(value: any) {
    this._open = coerceBooleanProperty(value)
  }
  get open() {
    return this._open
  }
  @HostBinding('class.open') _open = true

  @Output() sharedLimitChange = new EventEmitter<SharedLimitProperties>()
  @Output() layerResize = new EventEmitter<{
    layer: Partial<PhysicalLayer>
    def?: string
  }>()
  @Output() colorChange = new EventEmitter<{
    layer: Partial<PhysicalLayer>
    structure: Program
    color: string
  }>()
  @Output() layerRefResize = new EventEmitter<Partial<Layer>>()
  @Output() createHiddenLayer = new EventEmitter()
  @Output() deleteSharedLayerClick = new EventEmitter<Layer>()
  @Output() closeClick = new EventEmitter<void>()
  @Output() deleteLayerClick = new EventEmitter<string>()
  @Output() showTopDropDialog = new EventEmitter<TopDropProperties>()
  @Output() showMultiSectionDialog = new EventEmitter<MultiSectionProperties>()
  @Output() updateSharedLimitPropertiesClick = new EventEmitter()
  @Output() showCustomIndexDialog = new EventEmitter<CustomIndexProperties>()
  @Output() selectedCurrency = new EventEmitter<string>()
  @Output() handlePastedLayer = new EventEmitter<boolean>()
  @Output() selectedSLCurrency = new EventEmitter<string>()
  @Output() structureCurrency = new EventEmitter<string>()
  @Output() sharedLimitCurrency = new EventEmitter<string>()

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.onControlKeyTyped(event)
  }

  unlimitedSetSub: Subscription

  constructor(
    private layerService: LayerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.layerName = this.newSharedLayerEdit.layerName || ''
    this.isEditPage = !this.isAddSharedLayer && !this.isDeleteSharedLayer
    this.currencyControl.valueChanges.subscribe(searchVal => {
      this.layerFilteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
    this.slCurrencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })
    this.layerService.getLayerCopyObs().subscribe(layer => {
      this.tempLayer = layer
      this.canPasteLayer = Object.keys(layer).length > 0
    })
    if (this.selSLCurrency) {
      this.sharedLimitCurrency.emit(this.selSLCurrency.toString())
    }
    this.unlimitedSetSub = unlimitedSetAttempt.subscribe((value: string) => {
      this.snackBar.open(value, 'X', {
        panelClass: 'app-error',
        duration: 10000,
      })
    })
  }

  ngOnDestroy(): void {
    this.unlimitedSetSub.unsubscribe()
  }

  onControlKeyTyped(e: KeyboardEvent): void {
    const { ctrlKey, metaKey, key } = e
    // @ts-ignore
    const tagName = e.target.tagName
    if (tagName === 'DIV') {
      if ((ctrlKey || metaKey) && key === 'c') {
        this.onControlCPressed()
      }
      if ((ctrlKey || metaKey) && key === 'v') {
        this.onControlVPressed()
      }
    }
  }

  onControlCPressed(): void {
    this.copyingLayer = true
    const { parentGrossPortfolioID, analysisID } = this._currentProgram
    const copiedLayer = {
      ...this.layer,
      parentGrossPortfolioID,
      analysisID,
    } as CopyLayerState
    this.layerService.setLayerCopyObs(copiedLayer)
    this.canPasteLayer = true
    setTimeout(() => {
      this.copyingLayer = false
    }, 1250)
  }

  onControlVPressed(): void {
    this.pastingLayer = true
    const pasteReset$ = timer(2000).subscribe(() => {
      pasteReset$.unsubscribe()
      this.pastingLayer = false
    })
    this.handlePastedLayer.emit(true)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayLayerID = this.getDisplayLayerID()
    if (changes.layer) {
      if (!this.layer) {
        this.view = null
        return
      }
      if (this.layer && this.lastSelectedViewID !== this.selectedViewID) {
        this.currencyControl.setValue({
          code: this.layer.layer.currency,
        })
        this.lastSelectedViewID = this.selectedViewID
      }
      this.isDrop = this.layer.layer.meta_data.sage_layer_type === layerIds.drop
      this.view = new LayerView(this.layers, this.layer.layer, {
        metrics: this._layerViewsMetrics,
        lossSets: this._lossSetLayers,
        reinsurers: this.selectedSharedLayer
          ? this._reinsurersListSL
          : this._reinsurersList,
        prevView: this.view ?? undefined,
        programDefaultPricingCurves: this.programDefaultPricingCurves,
        savedPricingCurves: this._savedPricingCurves ?? undefined,
        currentProgram: this._currentProgram ?? undefined,
      })

      this.layerPropertyDefs = getLayerPropertyDefs(this.view.values)
      this.isNew = this.layer.new
      this.layerEdit = clone(this.layer)
      this.topAndDropLayer = null
      this.layers?.forEach(layerState => {
        const layer = layerState.layer
        if (layer.meta_data.sage_layer_type === layerIds.catTd) {
          // tslint:disable-next-line: no-non-null-assertion
          if (layer.layerRefs.slice(0, 2).includes(this.layer!.layer.id)) {
            this.topAndDropLayerEdit = clone(layerState)
            this.topAndDropLayer = clone(layerState)
          }
        }
      })
      this.selSLCurrency = this.selectedSharedLayer
        ? String(this.selectedSharedLayer.currency)
        : 'USD'
      this.slCurrencyControl.setValue({ code: this.selSLCurrency })
    }
    this.showEditDeleteButton = this.isEditPage && this.view != null
  }

  getDisplayLayerID(): string {
    let actualLayer: Layer | undefined
    const layers = this.layers?.map(l => l.layer)
    const layer = this.layer?.layer
    if (layer && layers) {
      const meta_data = layer.meta_data
      if (!!this.topAndDropLayer) {
        actualLayer = findActualTopAndDropLayer(layers, layer.id)
      }
      if (meta_data.sage_layer_type === layerIds.noncatRisk) {
        actualLayer = findRiskActualLayer(layers, layer.id)
      }
      if (meta_data.sage_layer_type === layerIds.catFhcf) {
        actualLayer = layers.find(l => l.meta_data.isFHCFFinal)
      }
      if (meta_data.sage_layer_type === layerIds.noncatIndxl) {
        actualLayer = layers.find(l =>
          isNoncatIndexlLayer({ meta_data: l.meta_data })
        )
      }
      if (isSwingLayer({ meta_data })) {
        actualLayer = findSwingRatedCombinedLayer(layers, layer)
      }
      if (isMultiSectionLayer({ meta_data })) {
        actualLayer = findMultiSectionMainLayer(layers, layer)
      }
      if (isAggLayer({ meta_data })) {
        actualLayer = layers.find(
          l => isAggLayer(l) && l.meta_data.rol_type === 'agg'
        )
      }
    }
    return actualLayer?.id ?? layer?.id ?? ''
  }

  checkIfOpen(label: string): boolean {
    return !(
      (label === 'Reinsurer Expense Provision' ||
        label === 'Profit Commission') &&
      this.open
    )
  }

  onCollapseClick(): void {
    this.open = !this.open
  }

  get isQuotaShare() {
    return (
      this.layer?.layer.meta_data.sage_layer_type === layerIds.catQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.noncatQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.ahlQs
    )
  }

  get subjectPremiumChecked() {
    if (this.view) {
      return this.view.subjectPremiumChecked
    }
  }

  get technicalPremiumChecked() {
    if (this.view) {
      return this.view.technicalPremiumChecked
    }
  }

  get cedingCommissionChecked() {
    if (this.view) {
      return this.view.cedingCommissionChecked
    }
  }

  get hasProfitCommission() {
    return (
      this.layer?.layer.meta_data.sage_layer_type === layerIds.catQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.noncatQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.catXl ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.noncatXl ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.ahlXl ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.noncatIndxl ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.catAg ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.noncatQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.ahlQs ||
      this.layer?.layer.meta_data.sage_layer_type === layerIds.ahlSwing ||
      this.layer?.layer.meta_data.sage_layer_type ===
        layerIds.catMultisection ||
      this.layer?.layer.meta_data.sage_layer_type ===
        layerIds.noncatMultisection
    )
  }

  get getTopLayer(): LayerState | null {
    let topLayer: LayerState | null = null
    const tnd = this.topAndDropLayer
    if (tnd) {
      this.layers?.forEach(layerState => {
        if (
          tnd.layer.layerRefs.length > 0 &&
          tnd.layer.layerRefs.includes(layerState.layer.id)
        ) {
          topLayer = clone(layerState)
        }
      })
    } else {
      topLayer = this.layerEdit
    }
    return topLayer
  }

  get getDropLayer(): LayerState | null {
    let dropLayer: LayerState | null = null
    const tnd = this.topAndDropLayer
    if (tnd) {
      this.layers?.forEach(layer => {
        if (
          tnd.layer.layerRefs.length > 0 &&
          tnd.layer.layerRefs[1] === layer.layer.id
        ) {
          dropLayer = clone(layer)
        }
      })
    }
    return dropLayer
  }

  get openIcon(): string {
    return this.open ? 'expand_more' : 'expand_less'
  }

  get showHelper(): boolean {
    return !this.view && this.isEditPage
  }

  get showSharedHelper(): boolean {
    return !this.selectedSharedLayer && this.isDeleteSharedLayer
  }

  get showDeleteButton(): boolean {
    return this.isDeleteSharedLayer && this.selectedSharedLayer != null
  }

  get showEditButton(): boolean {
    return this.isDeleteSharedLayer && this.selectedSharedLayer != null
  }

  get showCloseButton(): boolean {
    return this.isDeleteSharedLayer && this.selectedSharedLayer != null
  }

  get layerPaletteItem(): LayerPaletteItem | null {
    const v = this.view
    const subtype = v.subtype !== '' ? v.subtype : undefined
    return (
      (v != null &&
        LAYER_PALETTE.find(p => p.id === v.type && p.subtype === subtype)) ||
      null
    )
  }
  get name(): string | null {
    return (this.layerPaletteItem && this.layerPaletteItem.name) || null
  }
  get nameClass(): Record<string, boolean> {
    return {
      [`app-palette-${this.layerPaletteItem && this.layerPaletteItem.id}`]:
        this.layerPaletteItem != null,
    }
  }
  get programLabel(): string | null {
    return (
      this.layerPaletteItem &&
      LAYER_PALETTE_PROGRAMS[this.layerPaletteItem.program]
    )
  }

  get references(): Reference[] {
    const references: Reference[] = []
    this.layers?.forEach(ls => {
      if (!this.view) {
        return
      }
      if (
        ls.layer.meta_data.sage_layer_type === layerIds.catXl ||
        ls.layer.meta_data.sage_layer_type === layerIds.noncatXl ||
        ls.layer.meta_data.sage_layer_type === layerIds.ahlXl ||
        ls.layer.meta_data.sage_layer_type === layerIds.noncatIndxl
      ) {
        if (this.view.type === layerIds.catCa) {
          const casAtt =
            this.view.layer.meta_data.cascadeAttachment ||
            this.view.occurrenceAttachment
          if (ls.layer.physicalLayer.attachment.value <= casAtt) {
            references.push({
              value: ls.layer.id,
              viewValue: ls.layer.physicalLayer.description,
            })
          }
        } else {
          if (
            ls.layer.physicalLayer.attachment.value <=
            this.view.occurrenceAttachment
          ) {
            references.push({
              value: ls.layer.id,
              viewValue: ls.layer.physicalLayer.description,
            })
          }
        }
      }

      if (
        this.view.type === layerIds.catTd &&
        ls.layer.meta_data.sage_layer_type === layerIds.catAg
      ) {
        references.push({
          value: ls.layer.id,
          viewValue: ls.layer.physicalLayer.description,
        })
      }
    })
    if (references.length > 0) {
      references.unshift({ value: '', viewValue: 'Select' })
    }

    return references
  }

  get reinsurers(): string {
    return (
      (this.view && this.view.layer.physicalLayer.meta_data.reinsurers) || ''
    )
  }

  get pricingCurves(): string {
    return (
      (this.view && this.view.layer.physicalLayer.meta_data.pricingCurves) || ''
    )
  }

  get currentProgramDetails(): Program | undefined {
    return (this.view && this.view.currentProgram) || undefined
  }

  get layerDetails(): Layer | null {
    return (this.view && this.view.layer) || null
  }

  get sharedAggLimit(): number {
    return this.newSharedLayerEdit.aggLimit
  }
  set sharedAggLimit(value: number) {
    this.newSharedLayerEdit.aggLimit = value
  }

  get sharedOccLimit(): number {
    return this.newSharedLayerEdit.occLimit
  }
  set sharedOccLimit(value: number) {
    this.newSharedLayerEdit.occLimit = value
  }

  get sharedAggDed(): number {
    return this.newSharedLayerEdit.aggDed
  }
  set sharedAggDed(value: number) {
    this.newSharedLayerEdit.aggDed = value
  }

  get sharedCededPremium(): number {
    return this.newSharedLayerEdit.ceded
  }
  set sharedCededPremium(value: number) {
    this.newSharedLayerEdit.ceded = value
  }

  get sharedCession(): number {
    return this.newSharedLayerEdit.cession
  }
  set sharedCession(value: number) {
    this.newSharedLayerEdit.cession = value
  }

  get sharedReinstatements(): Reinstatement[] {
    return this.newSharedLayerEdit.reinstatements
  }
  set sharedReinstatements(reinstatements: Reinstatement[]) {
    this.newSharedLayerEdit.reinstatements = reinstatements
  }

  get isDisabled(): boolean {
    if (this.isDeleteSharedLayer && this.selectedSharedLayer) {
      return (
        this.newSharedLayerEdit.layerName ===
          this.selectedSharedLayer.physicalLayer.description &&
        this.newSharedLayerEdit.aggLimit ===
          this.selectedSharedLayer.physicalLayer.aggregateLimit.value &&
        this.newSharedLayerEdit.occLimit ===
          this.selectedSharedLayer.physicalLayer.limit.value &&
        this.newSharedLayerEdit.aggDed ===
          this.selectedSharedLayer.physicalLayer.aggregateAttachment.value &&
        this.newSharedLayerEdit.ceded ===
          this.selectedSharedLayer.physicalLayer.premium.value &&
        this.newSharedLayerEdit.cession ===
          Math.abs(this.selectedSharedLayer.physicalLayer.participation) &&
        JSON.stringify(this.newSharedLayerEdit.reinstatements) ===
          JSON.stringify(
            this.selectedSharedLayer.physicalLayer.reinstatements
          ) &&
        this.newSharedLayerEdit.currency === this.selectedSharedLayer.currency
      )
    } else {
      return true
    }
  }

  getDecimals(def: LayerPropertyDefResolved): number | undefined {
    const view = this.view
    const returnValue = def.decimals || 1
    if (view) {
      if (def.id === 'cessionPercentage') {
        const value = view[def.id] * 100
        const convertedString = parseFloat(value.toString())
        const result = Number(convertedString.toPrecision(15))
        const decimals = this.countDecimals(result)
        if (decimals === 0) {
          return returnValue
        }
        return decimals > 0 && decimals <= 8 ? decimals : 8
      }
      return returnValue
    }
  }

  countDecimals(value: number): number {
    if (Math.floor(value) === value) {
      return 0
    }
    return value.toString().split('.')[1].length || 0
  }

  getTechnicalPremiumSLValue(): number {
    if (this.view && this.view.values.purePremiumForTP) {
      if (this.view.reinsurers) {
        const reinsurerDefault = this.view.reinsurers.find(r => r.is_default)
        if (!reinsurerDefault) {
          return 0
        }
        const factor = reinsurerDefault.reinsurerProgramFactor[0]
        if (factor.elm_selected) {
          return (
            factor.expected_loss_multiplier * this.view.values.purePremiumForTP
          )
        } else if (factor.cmp_selected) {
          return (
            this.view.values.purePremiumForTP /
            (1 - factor.ceded_margin_percentage)
          )
        } else if (factor.stp_selected) {
          return (
            (this.view.values.purePremiumForTP +
              factor.std_dev_percentage *
                this.view.values.standardDeviationExpectedLossForTP) *
            factor.market_pricing_factor
          )
        } else {
          return 0
        }
      }
    }
    return 0
  }

  setDef(def: LayerPropertyDefResolved, value: number): void {
    const view: any = this.view
    if (view && def.id) {
      view[def.id] = value
      this.setLayer()
    }
  }

  getDropdownOptions(
    def: LayerPropertyDefResolved
  ): DropDownOptions[] | Reference[] {
    switch (def.id) {
      case 'cascadeLowerLayerID':
        return this.references
      case 'indexation':
        return [
          { value: indexationFull, viewValue: 'Full' },
          { value: indexationSevere, viewValue: 'Severe' },
          { value: indexationFranchise, viewValue: 'Franchise' },
        ]
      case 'swingBasis':
        return [
          { value: swingBasisInAddToMin, viewValue: 'in addition to Min' },
          { value: swingBasisSubToMin, viewValue: 'subject to Min' },
        ]
    }
    return []
  }

  getUnlimited(def: LayerPropertyDefResolved): boolean | undefined {
    const view: any = this.view
    if (view && def.id) {
      return view[def.id] >= analyzereConstants.unlimitedValue
    }
  }

  getCustomIndex(): boolean | undefined {
    return this.view?.isCustomIndex
  }

  onCustomIndex(): void {
    if (this.view) {
      this.view.isCustomIndex = !this.view.isCustomIndex
      if (this.view.isCustomIndex) {
        this.showCustomIndexDialog.emit({ layer: this.view.layer })
      }
    }
  }

  setValue(def: LayerPropertyDefResolved, value: any): void {
    const view: any = this.view
    if (view) {
      if (def.id === 'premium') {
        if (this.isQuotaShare) {
          view.subjectPremiumChecked = false
        } else {
          view.technicalPremiumChecked = false
        }
      } else if (def.id === 'cedingCommission') {
        view.cedingCommissionChecked = false
      } else if (def.id === 'rolPercentage') {
        view.technicalPremiumChecked = false
      } else if (def.id === 'rateOnLineSubject') {
        view.technicalPremiumChecked = false
      }
      view[def.id] = value
      this.setLayer(def.id)
    }
  }

  setLayer(def?: string): void {
    if (this.view) {
      this.layerResize.emit({ layer: this.view.layer.physicalLayer, def })
      if (
        (def !== 'rolPercentage' && isLayerTopOrDrop(this.view.layer)) ||
        !isLayerTopOrDrop(this.view.layer)
      ) {
        this.layerRefResize.emit(this.view.layer)
      }
    }
  }

  getLabel(label: string): string {
    const shared =
      !!this.topAndDropLayer && label === 'Cession Percentage'
        ? ` (Shared)`
        : ''
    return `${label}${shared}`
  }

  changeColor(color: string): void {
    if (this.view) {
      this.colorChange.emit({
        structure: this._currentProgram,
        layer: this.view.layer.physicalLayer,
        color,
      })
    }
  }

  onUnlimitableCheckboxChanged(def: LayerPropertyDefResolved): void {
    const view: any = this.view
    if (this.view && view && def.unlimitable) {
      view[def.unlimitable] = !this.getUnlimited(def)
      this.layerResize.emit({ layer: this.view.layer.physicalLayer })
    }
  }

  onSubjectPremiumChanged(): void {
    if (this.view) {
      this.view.subjectPremiumChecked = !this.view.subjectPremiumChecked
      this.layerResize.emit({ layer: this.view.layer.physicalLayer })
    }
  }

  onTechnicalPremiumChanged(): void {
    if (this.view) {
      this.view.technicalPremiumChecked = !this.view.technicalPremiumChecked

      // If the checkbox is checked, set the premium to the technical premium, triggering an ROL update
      if (this.view.technicalPremiumChecked) {
        this.view.premium = this.view.technicalPremium
      }
      this.layerResize.emit({
        layer: this.view.layer.physicalLayer,
      })
    }
  }

  onCedingCommissionChanged(): void {
    if (this.view) {
      this.view.cedingCommissionChecked = !this.view.cedingCommissionChecked
      this.layerResize.emit({
        layer: this.view.layer.physicalLayer,
      })
    }
  }

  onDropdownChange(def: LayerPropertyDefResolved, value: string): void {
    if (this.view) {
      switch (def.id) {
        case 'cascadeLowerLayerID':
          this.view.cascadeLowerLayerID = value
          this.layerRefResize.emit(this.view.layer)
          break
        default:
          this.setValue(def, value)
          break
      }
    }
  }

  onDeleteClick(): void {
    const view = this.view
    if (this.isDeleteSharedLayer) {
      if (this.selectedSharedLayer) {
        this.deleteSharedLayerClick.emit(this.selectedSharedLayer)
      }
    } else if (view) {
      this.deleteLayerClick.emit(view.layer.id)
      const aggFeeder = isLayerAggFeeder(view.layer)
      if (aggFeeder) {
        const aggLayers = this.layers?.filter(
          l => l.layer.layerRefs.includes(view.layer.id) && isLayerAgg(l.layer)
        )
        aggLayers?.forEach(l => {
          this.deleteLayerClick.emit(l.layer.id)
        })
      }
      const tdIds = findTDIDs(this.layers as LayerState[], view.layer.id)
      tdIds.forEach(id => {
        this.deleteLayerClick.emit(id)
      })
    }
  }

  onUpdateSharedLimitPropertiesClick(): void {
    if (this.isDeleteSharedLayer) {
      if (this.selectedSharedLayer) {
        const aggLimit: MonetaryUnit = {
          value: this.newSharedLayerEdit.aggLimit,
          currency: this.selSLCurrency,
        }
        const aggDed: MonetaryUnit = {
          value: this.newSharedLayerEdit.aggDed,
          currency: this.selSLCurrency,
        }
        const occLimit: MonetaryUnit = {
          value: this.newSharedLayerEdit.occLimit,
          currency: this.selSLCurrency,
        }
        const premium: MonetaryUnit = {
          value: this.newSharedLayerEdit.ceded,
          currency: this.selSLCurrency,
        }
        const layer: Layer = {
          ...this.selectedSharedLayer,
          currency: this.selSLCurrency,
          physicalLayer: {
            ...this.selectedSharedLayer.physicalLayer,
            description: this.layerName,
            aggregateLimit: aggLimit,
            aggregateAttachment: aggDed,
            limit: occLimit,
            premium,
            participation: this.newSharedLayerEdit.cession,
            reinstatements: this.newSharedLayerEdit.reinstatements,
            franchise: {
              ...this.selectedSharedLayer.physicalLayer.franchise,
              currency: this.selSLCurrency,
            },
            attachment: {
              ...this.selectedSharedLayer.physicalLayer.attachment,
              currency: this.selSLCurrency,
            },
          },
        }
        this.updateSharedLimitPropertiesClick.emit(layer)
      }
    }
  }

  onSharedLimitLayerNameChange(e: string): void {
    this.newSharedLayerEdit.layerName = e
    this.layerName = e
    this.sharedLimitChange.emit(this.newSharedLayerEdit)
  }

  onSharedLimitPropChange(): void {
    this.sharedLimitChange.emit(this.newSharedLayerEdit)
  }

  trackByDef(
    index?: number,
    def?: LayerPropertyDefResolved
  ): number | string | undefined {
    return def ? def.id : index
  }

  filterCurrencyValues(searchVal: CurrencyCode): CurrencyCode[] {
    if (searchVal.code) {
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      const searchValStr = String(searchVal).toLowerCase()
      return this.currencyList.filter(
        value => value.code.toLowerCase().indexOf(searchValStr) === 0
      )
    }
  }

  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }
  onCurrencyChange(val: CurrencyCode): void {
    this.selectedCurrency.emit(val.code)
    const lastLayerId = this.cededLayers.ids[this.cededLayers.ids.length - 1]
    if (this.isLibRE && this.view?.layer.id === lastLayerId) {
      this.structureCurrency.emit(val.code)
    } else if (this.isLibRE && this.view?.layer.id !== lastLayerId) {
      this.structureCurrency.emit(
        this.cededLayers.entities[lastLayerId]?.layer.currency
      )
    }
  }

  onSLCurrencyChange(val: CurrencyCode): void {
    this.selectedSLCurrency.emit(val.code)
    this.selSLCurrency = val.code
    this.newSharedLayerEdit.currency = val.code
    this.onSharedLimitPropChange()
  }
}
