<button
  appButtonIcon
  small
  opaque
  [ngClass]="{ lit: filter?.enabled }"
  [matMenuTriggerFor]="menu"
  (click)="$event.stopPropagation()"
>
  filter_list
</button>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="!filter; else optionsTemplate">
    <label appInfoText hint>Loading...</label>
  </ng-container>
</mat-menu>

<ng-template #optionsTemplate>
  <div class="wrapper" (click)="$event.stopPropagation()">
    <mat-checkbox
      [checked]="filter.selections.allSelected"
      [indeterminate]="filter.selections.onlySomeSelected"
      (change)="onSelectAllChange($event.checked)"
    >
      <span class="select-all">Select All</span>
    </mat-checkbox>

    <mat-checkbox
      *ngIf="filter.hasBlanks"
      [(ngModel)]="filter.selections.dictionary[BLANK]"
      (ngModelChange)="onChange()"
    >
      <span class="blank">(Blanks)</span>
    </mat-checkbox>

    <mat-checkbox
      *ngFor="let value of values; trackBy: trackByValue"
      [(ngModel)]="filter.selections.dictionary[value]"
      (ngModelChange)="onChange()"
    >
      {{ value }}
    </mat-checkbox>
  </div>
</ng-template>
