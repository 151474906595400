import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../../error/model/error'
import { GrouperSavedChanges } from '../grouper.actions'
import { ChangeManyEvent, GroupScenario } from '../program-group.model'

export interface AddProgramGroupProps {
  id: string
  ordinal?: number
  parentGroupID?: string
  isDuplicate?: boolean
}

export const saveProgramGroupScenarios = createAction(
  '[Grouper Program Group Scenarios] Save',
  props<ChangeManyEvent<GroupScenario>>()
)

export const saveProgramGroupScenariosSuccess = createAction(
  '[Grouper Program Group Scenarios] Save Success',
  props<GrouperSavedChanges>()
)

export const saveProgramGroupScenariosFailure = createAction(
  '[Grouper Program Group Scenarios] Save Failure',
  props<{ error: ApplicationError }>()
)
