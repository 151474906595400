import { Injectable, OnDestroy } from '@angular/core'
import { LayerDropEvent } from '@shared/layer-palette-item.component'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { CopyLayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { Layer, LayerRef } from '../../analysis/model/layers.model'
import { AHL_PALETTE_IDS, CASUALTY_PALETTE_IDS, layerIds, PROPERTY_PALETTE_IDS, STANDARD_PALETTE_IDS } from '../../analysis/model/layer-palette.model'
import { LossSet } from '../../api/analyzere/analyzere.model'
import { Store, select } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { take, takeUntil } from 'rxjs/operators'
import { selectCededLayers } from 'src/app/analysis/store/analysis.selectors'
import { MessageDialogService } from '@shared/message-dialog.service'
import { deleteLayer } from 'src/app/analysis/store/ceded-layers/layers.actions'
import { LayerDeleteDialogComponent } from 'src/app/analysis/layer-delete-dialog.component'
import { QuoteReinsurerService } from 'src/app/api/quote-reinsurer/quote-reinsurer.service'
import { MatDialog } from '@angular/material/dialog'
import { updateCounts } from 'src/app/core/store/program/program.actions'
import { saveQuoteReinsurer } from 'src/app/quote/store/reinsurer/reinsurer.actions'

@Injectable({
  providedIn: 'root',
})
export class LayerService implements OnDestroy {
  private destroy$ = new Subject()
  layerCopyObs$: BehaviorSubject<CopyLayerState> = new BehaviorSubject(
    {} as CopyLayerState
  )

  constructor(
    private store: Store<AppState>,
    private messageService: MessageDialogService,
    private quoteReinsurerService: QuoteReinsurerService,
    public dialog: MatDialog
  ) {}

  setLayerCopyObs(layer: CopyLayerState): void {
    this.layerCopyObs$.next(layer)
  }
  getLayerCopyObs(): Observable<CopyLayerState> {
    return this.layerCopyObs$.asObservable()
  }

  buildCopiedLayer(
    layer: Layer,
    lossSetLayers: LossSet[] | LayerRef[],
    currency: string,
    structureId: string | null
  ): Layer {
    const logicalIDTemp = new Date().getTime() + '' + Math.random() * 100000
    const { meta_data, physicalLayer, viewMetrics, sharedLayerID, layerRefs } =
      layer
    const { sage_layer_type, sage_layer_subtype } = meta_data
    const layerName = physicalLayer.description + ' Copy'

    const isFHCFFinal = sage_layer_type === layerIds.catFhcf
    const attachmentValue =
      physicalLayer.participation >= -0.5
        ? physicalLayer.attachment.value
        : physicalLayer.attachment.value + physicalLayer.limit.value
    const copyLayer: Layer = {
      id: logicalIDTemp,
      meta_data: {
        client: meta_data.client,
        isFHCFFinal,
        layerName,
        perspective: meta_data.perspective || '',
        rol_type: meta_data.rol_type,
        sage_layer_type,
        sage_layer_subtype,
        structureID: structureId ?? meta_data.structureID,
        year: meta_data.year || '',
        copiedLayerId: layer.id
      },
      layerRefs,
      lossSetFilter: '',
      lossSetLayers: this.getLossLayers(
        lossSetLayers,
        sage_layer_type,
        sage_layer_subtype
      ),
      physicalLayer: {
        aggregateAttachment: {
          value: physicalLayer.aggregateAttachment.value,
          currency,
        },
        aggregateLimit: {
          value: physicalLayer.aggregateLimit.value,
          currency,
        },
        attachment: {
          value: attachmentValue,
          currency,
        },
        description: layerName,
        fees: [],
        franchise: {
          value: physicalLayer.franchise.value,
          currency,
        },
        id: logicalIDTemp.replace('.', ''),
        logicalLayerID: logicalIDTemp,
        limit: {
          value: physicalLayer.limit.value,
          currency,
        },
        participation: physicalLayer.participation,
        premium: {
          value: physicalLayer.premium.value,
          currency,
        },
        meta_data: {
          client: meta_data.client || '',
          perspective: meta_data.perspective || '',
          rol_type: meta_data.rol_type,
          rol: 0.0,
          sage_layer_type,
          year: meta_data.year || '',
          sage_layer_subtype,
          isFHCFFinal,
          payout: meta_data.payout,
          pricingCurves: physicalLayer.meta_data.pricingCurves,
          pricingcurve_is_default:
            physicalLayer.meta_data.pricingcurve_is_default,
          reinsurers: physicalLayer.meta_data.reinsurers,
        },
        reinstatements: physicalLayer.reinstatements,
        type: physicalLayer.type,
        xcoord: -1,
        ycoord: -1,
        inception_date: physicalLayer.inception_date,
        expiry_date: physicalLayer.expiry_date,
        payout: physicalLayer.payout,
        trigger: physicalLayer.trigger,
        nth: physicalLayer.nth,
      },
      sharedLayerID,
      viewMetrics: {
        error: null,
        loading: false,
        metrics: viewMetrics.metrics,
        rss: null,
      },
    }
    if (physicalLayer.riskAttachment && physicalLayer.riskAttachment.value) {
      copyLayer.physicalLayer.riskAttachment = {
        value: physicalLayer.riskAttachment.value,
        currency,
      }
    }
    if (physicalLayer.riskLimit && physicalLayer.riskLimit.value) {
      copyLayer.physicalLayer.riskLimit = {
        value: physicalLayer.riskLimit.value,
        currency,
      }
    }
    return copyLayer
  }

  getLossLayers(
    lossSetLayers: LayerRef[],
    type: string | undefined,
    subtype: string | undefined
  ): LayerRef[] {
    const lossLayers: LayerRef[] = []
    if (type === layerIds.noncatIndxl) {
      lossSetLayers.forEach(a => {
        if (a.meta_data.loss_type === 'large') {
          lossLayers.push({
            id: a.id,
            meta_data: a.meta_data,
          })
        }
      })
    } else if (type === layerIds.noncatXl) {
      const attrOnlySets = lossSetLayers.filter(
        x => x.meta_data.loss_type === 'attr'
      )
      const layerLossSets =
        attrOnlySets.length > 0 ? attrOnlySets : lossSetLayers
      layerLossSets.forEach(({ id, meta_data }) =>
        lossLayers.push({ id, meta_data })
      )
    } else if (!this.isAggFeeder(type, subtype)) {
      lossSetLayers.forEach(({ id, meta_data }) =>
        lossLayers.push({ id, meta_data })
      )
    }
    return lossLayers
  }

  isAggFeeder(id: string | undefined, subtype: string | undefined): boolean {
    return (
      subtype !== 'feeder' &&
      (id === layerIds.catAg ||
        id === layerIds.noncatAg ||
        id === layerIds.ahlAg ||
        id === layerIds.ilwAg
      )
    )
  }

  isPaletteLayerFHCF = (type: string): boolean => type === layerIds.catFhcf

  isPaletteLayerRisk = (type: string): boolean => type === layerIds.noncatRisk

  isPaletteLayerTD = (type: string): boolean => type === layerIds.catTd

  isPaletteLayerAggFeeder(e: LayerDropEvent): boolean {
    return (
      e.item.subtype === 'feeder' &&
      (e.item.id === layerIds.catAg ||
        e.item.id === layerIds.noncatAg ||
        e.item.id === layerIds.ahlAg ||
        e.item.id === layerIds.ilwAg
      )
    )
  }

  getLayerIds(layerPaletteViewId: string): string[] {
    switch (layerPaletteViewId) {
      case '1':
        return STANDARD_PALETTE_IDS
      case '2':
        return AHL_PALETTE_IDS
      case '3':
        return PROPERTY_PALETTE_IDS
      case '4':
        return CASUALTY_PALETTE_IDS
      default:
        return STANDARD_PALETTE_IDS
    }
  }

  deleteLayerCheck(layerRef: string): void {
    this.quoteReinsurerService
      .getLayerQuoteStatus(layerRef)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result.data?.hasSignatureContract) {
          this.messageService.showMessage(
            'Signature Page contracts exist with this layer so it cannot be deleted'
          )
        } else if (result.data?.hasQuotes) {
          this.dialog
            .open(LayerDeleteDialogComponent)
            .afterClosed()
            .subscribe(result => {
              if (result.event === 'confirm') {
                this.quoteReinsurerService
                  .deleteRiskSectionAndDependencies(layerRef)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(deleteResult => {
                    if (deleteResult) {
                      this.deleteLayer(layerRef)
                      this.store.dispatch(
                        updateCounts({
                          fotCount: deleteResult.data?.fotCount ?? 0,
                          quoteCount: deleteResult.data?.quoteCount ?? 0,
                        })
                      )
                    }
                  })
              }
            })
        } else {
          this.deleteLayer(layerRef)
        }
      })
  }

  getQuoteReinsurers(layer: Layer) {
    this.quoteReinsurerService
      .getRiskReinsurersByLayer(layer.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          result.data.forEach(x => {
            return this.store.dispatch(
              saveQuoteReinsurer({
                reinsurer: x,
              })
            )
          })
        }
      })
  }

  deleteLayer(id: string): void {
    this.store.pipe(select(selectCededLayers), take(1)).subscribe(layers => {
      const layerToDelete = layers.find(l => l.layer.id === id)
      if (layerToDelete?.layer.sharedLayerID) {
        this.messageService.showMessage(
          'Please remove the Shared Limit relationship before deleting the layer.'
        )
      } else {
        this.store.dispatch(deleteLayer({ id }))
      }
    })
  }

  ngOnDestroy(): void {
    this.layerCopyObs$.unsubscribe()
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
