import { Action, createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import {
  GroupProps,
  QuoteWithLayerDetails,
  SharedLimitProps,
  SignatureBrokerageType,
  SignatureCurrencyType,
  SignatureLayerLimitType,
  SignatureLayerType,
  SignatureLayerTypes,
  SignatureReinsurerReferenceNumbers,
  StructureProps,
} from './../../signature.model'
import * as fromSignatureActions from '../signature.actions'
import { AssignedLines } from '../../../quote/models/quote.model'
import { Reinsurer } from 'src/app/core/model/reinsurer.model'

/* Groups
    id: programGroupId-layerRef-reinsurerPhaseLabel
    FOT selector label: programGroupName-layerName-reinsurerPhaseLabel
*/
/* Structures
    id: structureId-layerRef-reinsurerPhaseLabel
    FOT selector label: structureName-layerName-reinsurerPhaseLabel
*/
/* Shared Limits
    id: sharedLimitId-layerRef-reinsurerPhaseLabel
    FOT selector label: sharedLimitName-layerName-reinsurerPhaseLabel
*/
export interface FotState {
  id: string
  programId: string | null
  programGroupId: number | null /* null for structures and shared limits */
  programGroupName: string | null /* null for structures and shared limits */
  structureId: number | null /* null for groups and shared limits */
  structureName: string | null /* null for groups and shared limits */
  sharedLimitId: number | null /* null for structures and groups */
  sharedLimitName: string | null /* null for structures and groups */
  layerRef: string
  layerName: string | null
  layerType: SignatureLayerType
  layerCurrency: SignatureCurrencyType
  occurrence?: number
  brokerageType: SignatureBrokerageType
  reinsurerPhaseVersion: string
  reinsurerPhaseLabel?: string
  refTypesFromSelection?: SignatureReinsurerReferenceNumbers[]
  riskReinsurerId: number
  assignedLines?: AssignedLines[]
}

export interface State extends EntityState<FotState> {}

export const adapter = createEntityAdapter<FotState>({
  selectId: (fotstate): string => fotstate.id,
})

export const initialState: State = adapter.getInitialState({})

const FotReducer = createReducer(
  initialState,

  on(
    fromSignatureActions.fetchReinsurersByProgramGroupIdSuccess, // groups
    (
      state,
      {
        quoteReinsurers,
        programId,
        programGroupId,
        programGroupName,
        selectedReinsurers,
      }
    ) => {
      const groupProps: GroupProps = {
        programGroupId,
        programGroupName,
      }
      const fotsToAdd: FotState[] = quoteReinsurers
        .filter(r => r.reinsurerPhase === 'FOT')
        .map(r => mapQuoteToFot(r, programId, selectedReinsurers, groupProps))

      return adapter.addMany(fotsToAdd, state)
    }
  ),

  on(
    fromSignatureActions.fetchReinsurersBySharedLimitIdSuccess, // groups
    (
      state,
      { quoteReinsurers, sharedLimitProps, programId, selectedReinsurers }
    ) => {
      const fotsToAdd: FotState[] = quoteReinsurers
        .filter(r => r.reinsurerPhase === 'FOT')
        .map(r =>
          mapQuoteToFot(
            r,
            programId,
            selectedReinsurers,
            undefined,
            undefined,
            sharedLimitProps
          )
        )
      return adapter.addMany(fotsToAdd, state)
    }
  ),

  on(
    fromSignatureActions.fetchReinsurersByLayerRefSuccess, // structures
    (
      state,
      {
        quoteReinsurers,
        programId,
        structureId,
        structureName,
        selectedReinsurers,
      }
    ) => {
      const structureProps: StructureProps = {
        structureId,
        structureName,
      }
      const fotsToAdd: FotState[] = quoteReinsurers
        .filter(r => r.reinsurerPhase === 'FOT')
        .map(r =>
          mapQuoteToFot(
            r,
            programId,
            selectedReinsurers,
            undefined,
            structureProps
          )
        )

      return adapter.addMany(fotsToAdd, state)
    }
  ),

  on(fromSignatureActions.resetSignatureData, state => {
    return adapter.removeAll(state)
  })
)

export function reducer(state: State | undefined, action: Action) {
  return FotReducer(state, action)
}

const mapQuoteToFot = (
  reinsurerQuote: QuoteWithLayerDetails,
  programId: string | null,
  selectedReinsurers: Reinsurer[] | null,
  groupProps?: GroupProps,
  structureProps?: StructureProps,
  sharedLimitProps?: SharedLimitProps
): FotState => {
  const id = structureProps
    ? `${structureProps.structureId}-${reinsurerQuote.layerRef}-${reinsurerQuote.reinsurerPhaseLabel}`
    : groupProps
    ? `${groupProps.programGroupId}-${reinsurerQuote.layerRef}-${reinsurerQuote.reinsurerPhaseLabel}`
    : `${sharedLimitProps?.sharedLimitId}-${reinsurerQuote.layerRef}-${reinsurerQuote.reinsurerPhaseLabel}`
  {
    const sigLayerType: SignatureLayerType = SignatureLayerTypes.find(
      l => l.id === reinsurerQuote.layerType
    )?.layerType as SignatureLayerType
    const sigLayerLimitType: SignatureLayerLimitType = SignatureLayerTypes.find(
      l => l.id === reinsurerQuote.layerType
    )?.layerLimitType as SignatureLayerLimitType
    const sigReferenceTypes: SignatureReinsurerReferenceNumbers[] = []
    const sigBrokerageType: SignatureBrokerageType =
      reinsurerQuote.quoteFields?.brokerageType === 'Gross Ceded Premium'
        ? 'gross'
        : 'net'

    const structureId = structureProps?.structureId ?? null
    const structureName = structureProps?.structureName ?? null
    const programGroupId = groupProps?.programGroupId ?? null
    const programGroupName = groupProps?.programGroupName ?? null
    const sharedLimitId = sharedLimitProps?.sharedLimitId ?? null
    const sharedLimitName = sharedLimitProps?.sharedLimitName ?? null

    return {
      id,
      programId,
      programGroupId,
      programGroupName,
      structureId,
      structureName,
      sharedLimitName,
      sharedLimitId,
      layerRef: reinsurerQuote.layerRef,
      layerName: reinsurerQuote.layerName,
      layerType: sigLayerType,
      layerCurrency:
        sigLayerLimitType === 'Risk Limit'
          ? (reinsurerQuote.quoteFields?.quoteRiskLimit
              ?.currency as SignatureCurrencyType)
          : sigLayerLimitType === 'Aggregate Limit'
          ? (reinsurerQuote.quoteFields?.quoteAggregateLimit
              ?.currency as SignatureCurrencyType)
          : (reinsurerQuote.quoteFields?.quoteOccurrenceLimit
              ?.currency as SignatureCurrencyType),
      occurrence:
        (reinsurerQuote.quoteFields?.quoteRiskLimit?.value ?? 0) > 0 ||
        sigLayerLimitType === 'Risk Limit' // always use risk limit if value exists
          ? reinsurerQuote.quoteFields?.quoteRiskLimit?.value
          : sigLayerLimitType === 'Aggregate Limit'
          ? reinsurerQuote.quoteFields?.quoteAggregateLimit?.value
          : reinsurerQuote.quoteFields?.quoteOccurrenceLimit?.value,
      brokerageType: sigBrokerageType,
      reinsurerPhaseVersion: reinsurerQuote.reinsurerPhaseVersion,
      reinsurerPhaseLabel: reinsurerQuote.reinsurerPhaseLabel,
      riskReinsurerId: parseInt(reinsurerQuote.id, 10),
      assignedLines: reinsurerQuote.riskAssignedLinesLink,
    }
  }
}
