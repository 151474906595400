import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Study } from '../../core/model/study.model'
import { AccountOpportunity } from '../../api/model/backend.model'
import { OpportunityDetailsDialogService } from './opportunity-details-dialog.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-opportunity-info',
  styles: [
    `
           :host {
             padding: 0 var(--inset);
           }
     
           .cloud-pad {
             padding-left: 0.5rem;
             padding-top: 0.2rem;
           }
     
           mat-icon {
             color: #17a0db;
             padding-right: 0.5rem;
           }
     
           @media all and (max-width: 1240px) {
             .info-text {
               display: none;
             }
           }
         `,
  ],
  template: `
    <div *ngIf="programs && selectedProgram?.opportunity_id">
      <button
        appButton
        border
        center
        (click)="showDetails()"
        matTooltip="Click to display Salesforce Opportunity details."
        matTooltipPosition="above"
      >
        <mat-icon class="cloud-pad" inline>cloud</mat-icon>
      </button>
    </div>
  `,
})
export class ProgramOpportunityInfoComponent {
  @Input() programs: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() accountOpportunities: AccountOpportunity[] | null

  get selectedProgram(): Study | undefined {
    return this.programs.find(p => p.id === this.selectedProgramID)
  }

  get opportunity() {
    return this.accountOpportunities?.find(
      opp => opp.id === this.selectedProgram?.opportunity_id
    )
  }

  constructor(private detailsDialog: OpportunityDetailsDialogService) {}

  showDetails() {
    this.detailsDialog
      .open({
        // tslint:disable-next-line: no-non-null-assertion
        opp: this.opportunity!,
      })
      .afterClosed()
      .subscribe()
  }
}
