import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NavService } from '../../nav.service'
import { Router } from '@angular/router'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-header',
  styleUrls: ['./display-header.component.scss'],
  templateUrl: './display-header.component.html',
})
export class DisplayHeaderComponent {
  @Input() name: string

  get subtitle(): string {
    return ''
  }

  constructor(public navService: NavService, private router: Router) {}

  onBackClick() {
    this.router.navigate(['/home'])
    return false
  }
}
