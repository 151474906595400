<app-tier-bar
  [title]="title"
  [size]="size"
  [readonly]="readonly"
  [isButton]="isButton"
  [hover]="hover"
  [enableHover]="enableHover"
  [routerUrl]="routerUrl$ | async"
  [clientSelect]="clientSelect"
  [clients]="(clients && clients.length > 0) ? clients : (clients$ | async)"
  [years]="(years && years.length > 0) ? years : (years$ | async)"
  [programs]="programs$ | async"
  [selectedClientID]="selectedClientID || (selectedClientID$ | async)"
  [selectedYearID]="selectedYearID || (selectedYearID$ | async)"
  [selectedProgramID]="selectedProgramID || (selectedProgramID$ | async)"
  [layersChangedInDesign]="layersChangedInDesign$ | async"
  [isExplore]="isExplore"
  [lobs]="lobs"
  [exploreLoading]="exploreLoading"
  [structures]="structures"
  [programGroups]="programGroups"
  [selectedStructureID]="selectedStructureID || (selectedStructureID$ | async)"
  [selectedLob]="selectedLob"
  [lobSelect]="lobSelect"
  [accountOpportunities]="(accountOpportunities && accountOpportunities.length > 0) ? accountOpportunities : (accountOpportunities$ | async)"
  [isQuote]="isQuote"
  [isSlipT]="isSlipT"
  [isSignature]="isSignature"
  [isDesign]="isDesign"
  [quoteLayers]="quoteLayers"
  [selectedCededLayerID]="selectedCededLayerID"
  [quoteDirty]="quoteDirty"
  [sharedLimits]="sharedLimits"
  [sectionList]="sectionList"
  [secondBar]="secondBar"
  (tierChange)="onTierChange($event)"
  (groupOrStructureSelectionChange)="groupOrStructureSelectionChange.emit($event)"
  (structureChange)="structureChange.emit($event)"
  (lobChange)="lobChange.emit($event)"
  (exploreClear)="exploreClear.emit()"
  (layerSelect)="layerSelect.emit($event)"
  (groupSelect)="groupSelect.emit($event)"
  (slSelect)="slSelect.emit($event)"
></app-tier-bar>
