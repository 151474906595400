import * as fromSignatureReducer from './signature.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromFots from './fots/fot.reducer'
import * as fromContracts from './contracts/contracts.reducer'

const selectSignatureState = createFeatureSelector<fromSignatureReducer.State>(
  fromSignatureReducer.FEATURE_KEY
)

/* FOT Selectors */
const selectFotState = createSelector(selectSignatureState, state => state.fots)

export const { selectAll: selectAllSignatureFots } =
  fromFots.adapter.getSelectors(selectFotState)

/* Contracts Selectors */
export const selectContractsState = createSelector(
  selectSignatureState,
  state => state.contracts
)

export const { selectAll: selectAllSignatureContracts } =
  fromContracts.adapter.getSelectors(selectContractsState)

export const selectCurrentContract = createSelector(
  selectContractsState,
  state => state.selectedContract
)
export const selectCurrentSignaturePage = createSelector(
  selectContractsState,
  state => state.selectedReinsurer
)
export const selectCurrentContractSigPages = createSelector(
  selectContractsState,
  state => state.selectedContract?.reinsurers
)
export const selectCurrentSignaturePageIndex = createSelector(
  selectContractsState,
  state => state.currentPageIndex
)
export const selectAllDirtyContracts = createSelector(
  selectContractsState,
  state => state.dirtyContracts
)
export const selectIsSignatureDirty = createSelector(
  selectContractsState,
  state => state.dirty
)
export const selectIsSignatureSaving = createSelector(
  selectContractsState,
  state => state.saving
)
export const selectIsSignatureExporting = createSelector(
  selectContractsState,
  state => state.exporting
)
export const selectGroupOrStructureFilterSelection = createSelector(
  selectContractsState,
  state => state.groupOrStructureFilterSelection
)
export const selectContractsRiskRefState = createSelector(
  selectContractsState,
  state => state.riskRefs
)
export const selectSignatureClientsFromSalesforce = createSelector(
  selectContractsState,
  state => state.clients
)
