import { createAction, props } from '@ngrx/store'
import { CompareView } from '../../../model/compare-view.model'
import { ApplicationError } from '../../../../error/model/error'

export interface AddProgramGroupProps {
  id: string
  ordinal?: number
  parentGroupID?: string
}

export const setCompareSlidesPerView = createAction(
  '[Compare View] Set Slides Per View',
  props<{ value: number }>()
)

export const setCompareTowerSizePercentage = createAction(
  '[Compare View] Set Tower Size Percentage',
  props<{ percentage: number }>()
)

export const refreshCompareTowerSizePercentage = createAction(
  '[Compare View] Refresh Tower Size Percentage'
)

export const fetchCompareViewsSuccess = createAction(
  '[Compare View] Fetch Compare Views Success',
  props<{
    programId: string
    compareViews: CompareView[]
  }>()
)

export const saveCompareView = createAction(
  '[Compare View] Save Compare View',
  props<{ compareView: CompareView }>()
)

export const saveCompareViewFailure = createAction(
  '[Compare View] Save Compare View Failure',
  props<{ error: ApplicationError }>()
)

export const saveCompareViewSuccess = createAction(
  '[Compare View] Save Compare View Success',
  props<{ compareView: CompareView }>()
)

export const deleteCompareView = createAction(
  '[Compare View] Delete Compare Views',
  props<{ compareView: CompareView }>()
)

export const deleteCompareViewFailure = createAction(
  '[Compare View] Delete Compare Views Failure',
  props<{ error: ApplicationError }>()
)

export const deleteCompareViewSuccess = createAction(
  '[Compare View] Delete Compare Views Success',
  props<{ compareViewId: string }>()
)

export const updateSelectedCompareView = createAction(
  '[Compare View] Update Selected Compare View',
  props<{ compareView: CompareView | null }>()
)
