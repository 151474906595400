import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { last, tail, uniq } from 'ramda'
import { reduceReducers } from '../../../shared/util/reduce-reducers'
import { createGrouperSlides } from './create-grouper-slides'
import * as fromView from './grouper-view/grouper-view.reducer'
import * as fromActions from './grouper.actions'
import * as fromGroupScenariosActions from './program-group/program-group-scenarios.actions'
import {
  selectAddRightRootOrdinal,
  selectAllMembersByParentID,
  selectMemberBy,
  selectMemberByProgramGroupID,
  selectStructureMap,
  selectUntitledRootProgramGroupEntities,
} from './grouper.selectors'
import * as fromAnalysisActions from '../../store/analysis.actions'
import * as fromInuranceActions from './inurance/grouper-inurance.actions'
import * as fromInurance from './inurance/grouper-inurance.reducer'
import * as fromProgramGroupMember from './program-group-member/program-group-member.reducer'
import {
  GrouperState,
  LayersByStructure,
  LayersByStructureState,
} from './program-group.model'
import * as fromProgramGroupActions from './program-group/program-group.actions'
import * as fromProgramGroup from './program-group/program-group.reducer'
import * as fromProgramActions from './program/program.actions'
import * as fromProgram from './program/program.reducer'
import * as fromSharedLimitActions from './shared-limit/grouper-shared-limit.actions'
import * as fromSharedLimit from './shared-limit/grouper-shared-limit.reducer'
import { Program } from 'src/app/core/model/program.model'
import { Layer } from '../../model/layers.model'
import { Dictionary } from '@ngrx/entity'
import { md5 } from '@shared/util/hash'
import { LayerState } from '../ceded-layers/layers.reducer'

export const initialState: GrouperState = {
  programs: fromProgram.initialState,
  programGroups: fromProgramGroup.initialState,
  programGroupMembers: fromProgramGroupMember.initialState,
  layersByStructure: {
    source: { layers: {}, loading: false },
    swap: { layers: {}, loading: false },
    target: { layers: {}, loading: false },
  },
  analysisProfileID: null,
  rootIDs: [],
  slides: [],
  view: fromView.initialState,
  inurance: fromInurance.initialState,
  sharedLimit: fromSharedLimit.initialState,
  error: null,
  loading: false,
  saving: false,
  yearID: null,
  groupCurrency: null,
}

const removeRootIDReducer = (
  state: GrouperState,
  id: string
): GrouperState => ({
  ...state,
  rootIDs: state.rootIDs.filter(_id => _id !== id),
})

const resetReducer = createReducer(
  initialState,
  on(fromActions.resetGrouper, () => {
    return initialState
  })
)

const rootIDsReducer = createReducer(
  initialState.rootIDs,
  on(
    fromProgramGroupActions.addProgramGroupSuccess,
    (state, { id, parentGroupID }) => {
      return parentGroupID === 'root'
        ? [...state, fromProgramGroupMember.createMemberID(id)]
        : state
    }
  )
)

const errorReducer = createReducer(
  initialState.error,
  on(
    fromProgramGroupActions.addProgramGroup,
    fromProgramGroupActions.addProgramGroupSuccess,
    // @ts-ignore
    () => null
  ),
  on(
    fromProgramGroupActions.addProgramGroupFailure,
    (_, { error }) => error.message
  )
)

const loadingReducer = createReducer(
  initialState.loading,
  on(fromProgramGroupActions.addProgramGroup, () => true),
  on(
    fromProgramGroupActions.addProgramGroupSuccess,
    fromProgramGroupActions.addProgramGroupFailure,
    () => false
  )
)

const savingReducer = createReducer(
  initialState.loading,
  on(
    fromActions.saveGrouper,
    fromGroupScenariosActions.saveProgramGroupScenarios,
    () => true
  ),
  on(
    fromActions.saveGrouperSuccess,
    fromActions.saveGrouperFailure,
    fromGroupScenariosActions.saveProgramGroupScenariosSuccess,
    fromGroupScenariosActions.saveProgramGroupScenariosFailure,
    () => false
  )
)

export const groupCurrencyReducer = createReducer(
  initialState.groupCurrency,
  on(fromActions.groupCurrency, (_, { groupCurrency }) => groupCurrency)
)

const grouperReducer = combineReducers<GrouperState>({
  programs: fromProgram.reducer,
  programGroups: fromProgramGroup.reducer,
  programGroupMembers: fromProgramGroupMember.reducer,
  layersByStructure: state => state || { ...initialState.layersByStructure },
  analysisProfileID: state => state || initialState.analysisProfileID,
  yearID: state => state || initialState.yearID,
  rootIDs: rootIDsReducer,
  slides: state => state || initialState.slides,
  view: fromView.reducer,
  sharedLimit: fromSharedLimit.reducer,
  inurance: fromInurance.reducer,
  error: errorReducer,
  loading: loadingReducer,
  saving: savingReducer,
  groupCurrency: groupCurrencyReducer,
})

const addRootGroup = (
  state: GrouperState,
  group: fromProgramGroup.ProgramGroupEntity,
  ordinal?: number
): GrouperState => {
  let _ordinal = ordinal
  if (!_ordinal) {
    _ordinal = selectAddRightRootOrdinal(state)
  }
  const groupMember = fromProgramGroupMember.createNewGroupEntity(
    group.programGroup.id,
    _ordinal
  )
  return {
    ...state,
    rootIDs: [...state.rootIDs, groupMember.programGroupMember.id],
    programGroups: fromProgramGroup.adapter.addOne(group, state.programGroups),
    programGroupMembers: fromProgramGroupMember.adapter.addOne(
      groupMember,
      state.programGroupMembers
    ),
  }
}

const handleProgramIntoRootReducer = createReducer(
  initialState,
  on(
    fromProgramActions.addProgramToGroup,
    (state, { program, parentGroupID, ordinal }) => {
      let programNew: Program
      if (parentGroupID) {
        programNew = {
          ...program,
          parentGroupID,
        }
        // Not adding the program to the root, entity reducers will handle add
        return {
          ...state,
          programs: fromProgram.adapter.addOne(
            fromProgram.createProgramEntity(programNew),
            state.programs
          ),
        }
      }
      let next = state
      // Get the right-most 'untitled' system group if it exists
      let group: fromProgramGroup.ProgramGroupEntity | undefined
      group = last(selectUntitledRootProgramGroupEntities(state))
      if (!group) {
        // No 'untitled' system group exists, create it and add to state
        group = fromProgramGroup.createUntitledEntity(program.id)
        next = addRootGroup(state, group, ordinal)
      }
      // Add the program's member to the 'untitled' system group
      const programMember = fromProgramGroupMember.createNewProgramEntity(
        program.id,
        fromProgramGroupMember.DEFAULT_ORDINAL * (state.slides.length + 1),
        group.programGroup.id
      )
      return {
        ...next,
        programs: fromProgram.adapter.addOne(
          fromProgram.createProgramEntity(program),
          next.programs
        ),
        programGroupMembers: fromProgramGroupMember.adapter.addOne(
          programMember,
          next.programGroupMembers
        ),
      }
    }
  ),

  on(
    fromProgramActions.moveGrouperProgram,
    (state, { id, toGroupID, ordinal }) => {
      if (toGroupID !== fromProgramGroupMember.ROOT_ID) {
        return state
      }
      // If the toGroupID is ROOT_ID, create new untitled group and move into
      // tslint:disable-next-line: no-non-null-assertion
      const group = fromProgramGroup.createUntitledEntity(id!)
      const stateWithUntitledGroup = addRootGroup(state, group, ordinal)
      // Add the program's member to the 'untitled' system group
      const parentGroupID = group.programGroup.id
      return {
        ...stateWithUntitledGroup,
        programGroupMembers: fromProgramGroupMember.updateReducer(
          stateWithUntitledGroup.programGroupMembers,
          { programID: id },
          { ordinal: fromProgramGroupMember.DEFAULT_ORDINAL, parentGroupID }
        ),
      }
    }
  )
)

const handleProgramGroupIntoRootReducer = createReducer(
  initialState,
  on(
    fromProgramGroupActions.addProgramGroupSuccess,
    (state, { id, parentGroupID, ordinal }) => {
      if (parentGroupID) {
        return state
      }
      let next = state
      let group: fromProgramGroup.ProgramGroupEntity | undefined
      group = last(selectUntitledRootProgramGroupEntities(state))
      if (!group) {
        group = fromProgramGroup.createUntitledEntity(id)
        next = addRootGroup(state, group, ordinal)
      }
      const programMember = fromProgramGroupMember.createNewGroupEntity(
        id,
        fromProgramGroupMember.DEFAULT_ORDINAL,
        group.programGroup.id
      )
      return {
        ...next,
        programGroupMembers: fromProgramGroupMember.adapter.addOne(
          programMember,
          next.programGroupMembers
        ),
      }
    }
  )
)

const removeEmptyGroupIncludingParentsReducer = (
  state: GrouperState,
  programGroupID: string,
  isPostEntitiesUpdate = false
) => {
  const members = selectAllMembersByParentID(
    programGroupID,
    state.programGroupMembers
  )
  const emptyLimit = isPostEntitiesUpdate ? 0 : 1
  if (members.filter(m => !m.deleted).length > emptyLimit) {
    // Leave state unchanged since the group will still have members
    return state
  }

  // Since the user has now removed all of a group's members, instead
  // of marking each of those members for deletion, we should just remove them
  // from state, so that the group won't be saved as an empty group
  const memberIDs = members.map(m => m.programGroupMember.id)
  let next = {
    ...state,
    programGroupMembers: fromProgramGroupMember.adapter.removeMany(
      memberIDs,
      state.programGroupMembers
    ),
  }

  const parentGroupMember = selectMemberBy({ programGroupID }, next)
  if (!parentGroupMember.root) {
    // Recurse to parent, removing it if it's empty
    const parentGroupID = parentGroupMember.programGroupMember.parentGroupID
    next = removeEmptyGroupIncludingParentsReducer(next, parentGroupID)
  } else {
    // We are at the root, so cannot recurse; remove the root ID
    next = removeRootIDReducer(next, parentGroupMember.programGroupMember.id)
  }
  // Remove the program groups and its associated member
  return {
    ...next,
    programGroups: fromProgramGroup.adapter.removeOne(
      programGroupID,
      next.programGroups
    ),
    programGroupMembers: fromProgramGroupMember.deleteReducer(
      next.programGroupMembers,
      // Ignore if group member is gone, since it may have been removed
      // when we recursed into potential empty parent groups
      { programGroupID, throwIfEmpty: false }
    ),
  }
}

const removeIncludingChildrenReducer = (
  state: GrouperState,
  member: fromProgramGroupMember.ProgramGroupMemberEntity
): GrouperState => {
  const memberID = member.programGroupMember.id
  if (member.programGroupMember.type === 'program') {
    // Member is a program; remove it and its associated member
    const programID = member.programGroupMember.programID
    if (!programID) {
      console.error(`No program ID found in member w/ ID ${memberID}`)
      return state
    }
    const programNew = buildProgramID(programID, member)
    return {
      ...state,
      programs: fromProgram.adapter.removeOne(programNew, state.programs),
      programGroupMembers: fromProgramGroupMember.removeReducer(
        state.programGroupMembers,
        { entity: member }
      ),
    }
  }

  // Member is a group, recursively remove its children
  const programGroupID = member.programGroupMember.programGroupID
  if (!programGroupID) {
    console.error(`No program group ID found in member w/ ID ${memberID}`)
    return state
  }
  const childrenMembers = selectAllMembersByParentID(
    programGroupID,
    state.programGroupMembers
  )
  const stateWithoutChildren = childrenMembers.reduce(
    removeIncludingChildrenReducer,
    state
  )

  // Remove the program group and its member
  return {
    ...stateWithoutChildren,
    programGroups: fromProgramGroup.adapter.removeOne(
      programGroupID,
      stateWithoutChildren.programGroups
    ),
    programGroupMembers: fromProgramGroupMember.removeReducer(
      stateWithoutChildren.programGroupMembers,
      { entity: member }
    ),
  }
}

const deleteIncludingChildrenReducer = (
  state: GrouperState,
  member: fromProgramGroupMember.ProgramGroupMemberEntity
): GrouperState => {
  const memberID = member.programGroupMember.id
  if (member.programGroupMember.type === 'program') {
    // Member is a program; remove it and its associated member
    const programID = member.programGroupMember.programID
    if (!programID) {
      console.error(`No program ID found in member w/ ID ${memberID}`)
      return state
    }
    const programNew = buildProgramID(programID, member)
    return {
      ...state,
      programs: fromProgram.adapter.removeOne(programNew, state.programs),
      programGroupMembers: fromProgramGroupMember.deleteReducer(
        state.programGroupMembers,
        { entity: member }
      ),
    }
  }

  // Member is a group, recursively remove its children
  const programGroupID = member.programGroupMember.programGroupID
  if (!programGroupID) {
    console.error(`No program group ID found in member w/ ID ${memberID}`)
    return state
  }
  // Remove the program group and its member
  return {
    ...state,
    programGroups: fromProgramGroup.deleteReducer(state.programGroups, {
      id: programGroupID,
    }),
    programGroupMembers: fromProgramGroupMember.deleteReducer(
      state.programGroupMembers,
      { entity: member }
    ),
  }
}

const preAddOrRemoveReducer = createReducer(
  initialState,

  on(
    fromProgramGroupActions.addProgramGroupSuccess,
    (state, { programGroups }) => {
      // The first program group is always the root/parent
      const childGroupIDs = tail(programGroups).map(g => g.id)
      // For each descendent group already present in state, remove it & its
      // associated member since we will be adding it again to the state
      return childGroupIDs.reduce((next, childGroupID) => {
        const member = selectMemberByProgramGroupID(childGroupID, next)
        if (!member) {
          return next
        }
        return {
          ...next,
          programGroups: fromProgramGroup.adapter.removeOne(
            childGroupID,
            next.programGroups
          ),
          programGroupMembers: fromProgramGroupMember.removeReducer(
            next.programGroupMembers,
            { entity: member }
          ),
        }
      }, state)
    }
  ),

  on(fromProgramActions.removeProgramFromGroup, (state, { program }) => {
    const programMember = selectMemberBy(
      { programID: program.id, parentGroupID: program.parentGroupID },
      state
    )
    const parentGroupID = programMember.programGroupMember.parentGroupID
    return removeEmptyGroupIncludingParentsReducer(state, parentGroupID)
  }),

  on(fromProgramGroupActions.removeProgramGroup, (state, { id }) => {
    // Remove all children programs, groups, and members
    const childrenMembers = selectAllMembersByParentID(
      id,
      state.programGroupMembers
    )
    const stateWithoutChildren = childrenMembers.reduce(
      removeIncludingChildrenReducer,
      state
    )

    const programGroupMember = selectMemberBy({ programGroupID: id }, state)
    if (!programGroupMember) {
      console.error(
        `No program group member found for program group w/ ID ${id}`
      )
      return state
    }
    // If we are remove a root group, remove its root ID
    if (programGroupMember.root) {
      const memberID = programGroupMember.programGroupMember.id
      return removeRootIDReducer(stateWithoutChildren, memberID)
    }
    // Else, remove any parent groups that will become empty due to removal
    const parentGroupID = programGroupMember.programGroupMember.parentGroupID
    return removeEmptyGroupIncludingParentsReducer(
      stateWithoutChildren,
      parentGroupID
    )
  }),

  on(fromProgramGroupActions.deleteProgramGroupSuccess, (state, { id }) => {
    // Remove all children programs, groups, and members except delete ones
    const childrenMembers = selectAllMembersByParentID(
      id,
      state.programGroupMembers
    )
    const uniqRemoveProgramIds: string[] = []
    const removeOtherChildern = childrenMembers.map(c => {
      if (
        c.programGroupMember.programGroupID &&
        c.programGroupMember.programGroupID !== undefined
      ) {
        uniqRemoveProgramIds.push(c.programGroupMember.programGroupID)
        return selectAllMembersByParentID(
          c.programGroupMember.programGroupID,
          state.programGroupMembers
        )
      }
    })
    let removeState = state
    uniq(uniqRemoveProgramIds).map(u => {
      removeState = {
        ...removeState,
        programGroups: fromProgramGroup.adapter.removeOne(
          u,
          removeState.programGroups
        ),
      }
    })
    const flatArray = removeOtherChildern.flat(1).filter(a => a !== undefined)
    const stateWithoutRemoveChildren = flatArray.reduce(
      removeIncludingChildrenReducer,
      removeState
    )
    const stateWithoutChildren = childrenMembers.reduce(
      deleteIncludingChildrenReducer,
      stateWithoutRemoveChildren
    )

    const programGroupMember = selectMemberBy({ programGroupID: id }, state)
    if (!programGroupMember) {
      console.error(
        `No program group member found for program group w/ ID ${id}`
      )
      return state
    }
    // If we are remove a root group, remove its root ID
    if (programGroupMember.root) {
      const memberID = programGroupMember.programGroupMember.id
      return removeRootIDReducer(stateWithoutChildren, memberID)
    }
    // Else, remove any parent groups that will become empty due to removal
    const parentGroupID = programGroupMember.programGroupMember.parentGroupID
    return removeEmptyGroupIncludingParentsReducer(
      stateWithoutChildren,
      parentGroupID
    )
  })
)

const postMoveReducer = createReducer(
  initialState,

  on(fromProgramActions.moveGrouperProgram, (state, { fromGroupID }) => {
    return removeEmptyGroupIncludingParentsReducer(state, fromGroupID, true)
  })
)

const setAnalysisProfileID = (
  value: string | null | undefined,
  state: GrouperState
): GrouperState => ({ ...state, analysisProfileID: value || null })

const resetAnalysisProfileIDReducer = createReducer(
  initialState,

  on(fromProgramActions.addProgramToGroup, (state, { program }) =>
    !state.analysisProfileID
      ? setAnalysisProfileID(program.analysisID, state)
      : state
  ),

  on(fromProgramGroupActions.addProgramGroupSuccess, (state, { programs }) =>
    !state.analysisProfileID
      ? setAnalysisProfileID(programs[0] && programs[0].analysisID, state)
      : state
  ),

  on(
    fromProgramActions.removeProgramFromGroup,
    fromProgramGroupActions.removeProgramGroup,
    state => {
      const programs = fromProgram.adapter
        .getSelectors()
        .selectAll(state.programs)
      return programs.length === 0 ? setAnalysisProfileID(null, state) : state
    }
  )
)

const setYearID = (
  value: string | null | undefined,
  state: GrouperState
): GrouperState => ({ ...state, yearID: value || null })

const resetYearIDReducer = createReducer(
  initialState,

  on(fromProgramActions.addProgramToGroup, (state, { program }) =>
    !state.yearID ? setYearID(program.yearID, state) : state
  ),

  on(fromProgramGroupActions.addProgramGroupSuccess, (state, { programs }) =>
    !state.yearID ? setYearID(programs[0] && programs[0].yearID, state) : state
  ),

  on(
    fromProgramActions.removeProgramFromGroup,
    fromProgramGroupActions.removeProgramGroup,
    state => {
      const programs = fromProgram.adapter
        .getSelectors()
        .selectAll(state.programs)
      return programs.length === 0 ? setYearID(null, state) : state
    }
  )
)

const resetInuranceSymbolMapReducer = createReducer(
  initialState,

  on(
    fromProgramActions.removeProgramFromGroup,
    fromProgramGroupActions.removeProgramGroup,
    state => {
      const programs = fromProgram.adapter
        .getSelectors()
        .selectAll(state.programs)
      return programs.length === 0
        ? {
            ...state,
            inurance: fromInurance.resetTagsAndSymbolsReducer(state.inurance),
          }
        : state
    }
  )
)

const updateSlidesReducer = createReducer(
  initialState,
  on(
    fromProgramActions.addProgramToGroup,
    fromProgramActions.removeProgramFromGroup,
    fromProgramActions.moveGrouperProgram,
    fromProgramActions.setGrouperMinimizedProgram,
    fromProgramGroupActions.addProgramGroupSuccess,
    fromProgramGroupActions.removeProgramGroup,
    fromProgramGroupActions.addNewProgramGroup,
    fromProgramGroupActions.setGrouperMinimizedProgramGroup,
    fromActions.saveGrouperSuccess,
    fromProgramGroupActions.deleteProgramGroupSuccess,
    fromProgramGroupActions.renameProgramGroup,
    (state: GrouperState) => {
      const slides = createGrouperSlides(state)
      // Set the program IDs in the correct order based on parsing the tree
      // that was performed by creating the slides
      const programIDs = slides.filter(s => !s.isGroupAgg).map(s => s.id)
      const programs = { ...state.programs, ids: programIDs }

      return { ...state, programs, slides }
    }
  )
)

const updateInuranceReducer = createReducer(
  initialState,
  on(
    fromProgramActions.fetchGrouperProgramLayersSuccess,
    fromProgramActions.removeProgramFromGroup,
    fromProgramGroupActions.removeProgramGroup,
    fromProgramGroupActions.deleteProgramGroupSuccess,
    fromInuranceActions.saveGrouperInuranceSuccess,
    fromInuranceActions.deleteGrouperInuranceSuccess,
    fromSharedLimitActions.addSharedLimitSuccess,
    fromSharedLimitActions.deleteSharedLimitSuccess,
    fromSharedLimitActions.updateSharedLimitSucccess,
    fromProgramGroupActions.renameProgramGroup,
    state => ({
      ...state,
      inurance: fromInurance.createTagsAndSymbolsReducer(
        state.inurance,
        selectStructureMap(
          state.slides,
          state.programs.entities,
          state.programGroups.entities
        )
      ),
    })
  )
)

const layersByStructureReducer = createReducer(
  initialState,
  on(fromAnalysisActions.fetchLayersByStructure, (state, { inurance }) => {
    let updatedState: LayersByStructureState = state.layersByStructure
    if (inurance === 'source') {
      updatedState = {
        ...updatedState,
        source: { ...updatedState.source, loading: true },
      }
    } else if (inurance === 'swap') {
      updatedState = {
        ...updatedState,
        swap: { ...updatedState.swap, loading: true },
      }
    } else {
      updatedState = {
        ...updatedState,
        target: { ...updatedState.target, loading: true },
      }
    }

    return {
      ...state,
      layersByStructure: updatedState,
    }
  }),
  on(fromAnalysisActions.fetchLayersByStructureFailure, state => {
    return {
      ...state,
      layersByStructure: {
        ...state.layersByStructure,
        source: { ...state.layersByStructure.source, loading: false },
        swap: { ...state.layersByStructure.swap, loading: false },
        target: { ...state.layersByStructure.target, loading: false },
      },
    }
  }),
  on(
    fromAnalysisActions.fetchLayersByStructureSuccess,
    (state, { structure, layers, inurance }) => {
      let updatedLayers: Dictionary<Layer[]>
      let updatedLayersState: LayersByStructure
      let updatedState: LayersByStructureState

      if (inurance === 'source') {
        updatedLayers = { ...state.layersByStructure.source.layers }
        updatedLayers[structure.id] = layers
        updatedLayersState = {
          layers: updatedLayers,
          loading: false,
        }
        updatedState = {
          ...state.layersByStructure,
          source: updatedLayersState,
        }
      } else if (inurance === 'swap') {
        updatedLayers = { ...state.layersByStructure.swap.layers }
        updatedLayers[structure.id] = layers
        updatedLayersState = {
          layers: updatedLayers,
          loading: false,
        }
        updatedState = {
          ...state.layersByStructure,
          swap: updatedLayersState,
        }
      } else {
        updatedLayers = { ...state.layersByStructure.target.layers }
        updatedLayers[structure.id] = layers
        updatedLayersState = {
          layers: updatedLayers,
          loading: false,
        }
        updatedState = {
          ...state.layersByStructure,
          target: updatedLayersState,
        }
      }

      return { ...state, layersByStructure: updatedState }
    }
  )
)

const addInuranceForDesignReducer = createReducer(
  initialState,
  on(fromAnalysisActions.addGroupForInurance, (state, { group }) => {
    const groupEntity = {
      dirty: false,
      new: false,
      hash: md5(group),
      root: false,
      untitled: false,
      deleted: false,
      programGroup: group,
    }
    return {
      ...state,
      programGroups: fromProgramGroup.adapter.addOne(
        groupEntity,
        state.programGroups
      ),
    }
  }),
  on(
    fromAnalysisActions.addProgramForInurance,
    fromAnalysisActions.fetchLayersByStructureSuccess,
    (state, { structure, layers }) => {
      const cededLayers: LayerState[] = layers?.map(layer => {
        return {
          new: false,
          layer,
          dirty: false,
          deleted: false,
          hash: md5(layer),
        }
      })
      return {
        ...state,
        programs: fromProgram.adapter.addOne(
          {
            ...fromProgram.createProgramEntity(structure),
            cededLayers: cededLayers ?? [],
          },
          state.programs
        ),
      }
    }
  )
)

const reducedReducer = reduceReducers(
  resetReducer,
  handleProgramIntoRootReducer,
  handleProgramGroupIntoRootReducer,
  preAddOrRemoveReducer,
  grouperReducer,
  postMoveReducer,
  resetAnalysisProfileIDReducer,
  resetYearIDReducer,
  resetInuranceSymbolMapReducer,
  updateSlidesReducer,
  updateInuranceReducer,
  layersByStructureReducer,
  addInuranceForDesignReducer
)

export function reducer(
  state: GrouperState | undefined,
  action: Action
): GrouperState {
  return reducedReducer(state, action)
}

const buildProgramID = (
  programID: string,
  member: fromProgramGroupMember.ProgramGroupMemberEntity
): string => {
  return (
    programID +
    (member.programGroupMember.parentGroupID
      ? '_' + member.programGroupMember.parentGroupID
      : '')
  )
}
