import { AsyncSubject } from 'rxjs'
import { HasData } from '../../../api/model/api.model'
import { LayerView } from '../../model/layer-view'
import {
  OptimizationCandidateLayer,
  OptimizationInputRange,
  OptimizationLayersMessageResult,
} from '../optimization.model'

export class OptimizationWorkerMessenger {
  private data$: AsyncSubject<OptimizationLayersMessageResult>

  getLayers(
    template: LayerView,
    ranges: OptimizationInputRange[],
    max: number,
    lossSetGroupIDs?: string[]
  ) {
    this.data$ = new AsyncSubject<OptimizationLayersMessageResult>()
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL('./optimization-candidates.worker', import.meta.url),
        { type: 'module' }
      )

      worker.onmessage = ({
        data,
      }: HasData<OptimizationLayersMessageResult>) => {
        const messageResult: OptimizationLayersMessageResult = JSON.parse(
          data as any
        )

        if (
          !messageResult.error &&
          lossSetGroupIDs &&
          lossSetGroupIDs.length > 0
        ) {
          const optimizationLayers = messageResult.values
          const expandedLayers: OptimizationCandidateLayer[] = []
          let counter = 1
          lossSetGroupIDs.forEach(id => {
            optimizationLayers.forEach(l => {
              const index = counter++
              expandedLayers.push({
                ...l,
                lossSetGroupID: id,
                id: `${l.id.split('_')[0]}_${index}`,
                layerNumber: index,
              })
            })
          })
          messageResult.values = expandedLayers
        }
        this.data$.next(messageResult)
        this.data$.complete()
        worker.terminate()
      }
      worker.postMessage(
        JSON.stringify({ template: template.values, ranges, max })
      )
    } else {
      this.data$.next({
        values: [],
        error: 'Web Worker not supported in this environment.',
      })
      this.data$.complete()
    }
    return this.data$.asObservable()
  }
}
