import { LayerView, LayerViewValues } from './layer-view'
import { LayerMetricDef } from './layer-metric-defs'

type TypeApplicabilityLevel = 'program' | 'layer' | 'subtype' | 'type'

export type TypeApplicability =
  | string
  | {
      level?: TypeApplicabilityLevel
      type: string
    }

function parseLayerProgramTypes(
  type?: string
): { program?: string; layer?: string } {
  const parts = type ? type.split('_') : []
  const layer = parts.pop()
  const program = parts.pop()
  return { program, layer }
}

function parseApplicability(applicability: TypeApplicability) {
  let level: TypeApplicabilityLevel = 'type'
  let applicabilityType: string
  if (typeof applicability !== 'string') {
    level = applicability.level || 'type'
    applicabilityType = applicability.type
  } else {
    applicabilityType = applicability
  }
  return { level, applicabilityType }
}

const makeMatcher = (
  type?: string,
  program?: string,
  layer?: string,
  subtype?: string
) => (applicability: TypeApplicability): boolean => {
  const { level, applicabilityType } = parseApplicability(applicability)

  switch (level) {
    case 'layer':
      return layer === applicabilityType
    case 'program':
      return program === applicabilityType
    case 'subtype':
      return subtype === applicabilityType
    default:
    case 'type':
      return type === applicabilityType
  }
}

const isStr = (o: any): o is string | undefined => {
  return !o || typeof o === 'string'
}

export function isLayerMetricApplicable(
  view: LayerView | LayerViewValues,
  def: LayerMetricDef
): boolean
export function isLayerMetricApplicable(
  type?: string,
  subtype?: string,
  whitelist?: TypeApplicability[],
  blacklist?: TypeApplicability[]
): boolean
export function isLayerMetricApplicable(
  typeOrView: string | undefined | LayerView | LayerViewValues,
  subtypeOrDef: string | undefined | LayerMetricDef,
  whitelist?: TypeApplicability[],
  blacklist?: TypeApplicability[]
): boolean {
  let applicable = true

  let _type: string | undefined
  let _subtype: string | undefined
  let _whitelist: TypeApplicability[] | undefined
  let _blacklist: TypeApplicability[] | undefined

  if (isStr(typeOrView) && isStr(subtypeOrDef)) {
    _type = typeOrView
    _subtype = subtypeOrDef
    _whitelist = whitelist
    _blacklist = blacklist
  } else if (!isStr(typeOrView) && !isStr(subtypeOrDef)) {
    _type = typeOrView.type
    _subtype = typeOrView.subtype
    _whitelist = subtypeOrDef.whitelist
    _blacklist = subtypeOrDef.blacklist
  } else {
    throw TypeError('If LayerView provided, LayerMetricDef required.')
  }

  const { program, layer } = parseLayerProgramTypes(_type)
  const match = makeMatcher(_type, program, layer, _subtype)

  if (_whitelist) {
    applicable = _whitelist.some(match)
  }
  if (applicable && _blacklist) {
    applicable = !_blacklist.some(match)
  }
  return applicable
}
