// First company (the target) will be white (palette #5) w/ a thicker stroke
const graphingColorPalette = [
  'body', //    #cce1e2
  'blue', //    #00aeef
  'orange', //  #f68a33
  'green', //   #01c96d
  'pink', //    #ef5399
  'yellow', //  #fcd658
  'magenta', // #bd76ff
  'salmon', //  #f2b6a4
  'cyan', //    #88f4f6
  'red', //     #f1462f
  'lime', //    #bcf063
  'brown', //   #8C564B
  'purple', //  #5c50ff
  'teal', //    #00b3aa
] as const

export const hexGraphingColorPalette = [
  'body', //    #cce1e2
  '#00aeef',
  '#f68a33',
  '#01c96d',
  '#ef5399',
  '#fcd658',
  '#bd76ff',
  '#f2b6a4',
  '#88f4f6',
  '#f1462f',
  '#bcf063',
  '#8C564B',
  '#5c50ff',
  '#00b3aa',
]
export const hexRevenueDashboardGraphingColorPalette = [
  'body', //    #cce1e2
  '#a9a9a9', // light gray
  '#00aeef', // blue
  '#ef5399', // pink
  '#01c96d', // green
  '#f68a33', // orange
  '#f1462f', // red
  '#fcd658', // yellow
  '#8C564B', // brown
  '#bd76ff', // magenta
  '#f2b6a4', // salmon
  '#88f4f6', // cyan
  '#bcf063', // lime
  '#5c50ff', // purple
  '#00b3aa', // teal
]

export type GraphingColorPalette =
  | typeof graphingColorPalette[number]
  | 'gray' //   #636e77
  | 'indigo' // #4682b4
  | 'taupe' //  #b8a1bd
  | 'mauve' //  #ffa6f6
  | 'mint' //   #94ffb5
  | 'gold' //   #b79966
  | 'olive' //  #74a771
  | 'pewter' // #7caac1

export const graphingColorPaletteExtended: readonly GraphingColorPalette[] = [
  ...graphingColorPalette.slice(1),
  graphingColorPalette[0],
  'gray', //    #636e77
] as const

export default graphingColorPalette
