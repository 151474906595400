import { createAction, props } from '@ngrx/store'
import { SortTableColumnView } from '@shared/sort-table/sort-table.model'
import { ColumnsSettings } from '@shared/columns-settings/columns-settings.model'
import {
  DataCounts,
  QuoteSelectors,
  QuoteSelectorsDto,
  QuoteSummaryData,
  QuoteTab,
  QuoteTabType,
} from './../model/quote-management-information.model'
import { LayerPaletteProgram } from '../../../analysis/model/layer-palette.model'
import { ApplicationError } from '../../../error/model/error'
import { IIntervalFilter } from '../../../management-information/store/management-information.reducer'
import { QuoteDetailsData } from '../model/quote-details.model'
import { QuoteRankingData } from '../model/quote-ranking.model'
import {
  QuoteChartAxis,
  QuoteChartGridOption,
} from '../model/quote-charts.model'

export const loadInitialSelectorsWithoutDefaultLayers = createAction(
  '[Quote Management Information] Load Initial Selectors Without Default Layers'
)
export const loadInitialData = createAction(
  '[Quote Management Information] Load Initial Data'
)

export const loadInitialDataFailure = createAction(
  '[Quote Management Information] Load initial data failure',
  props<{ error: ApplicationError }>()
)

export const fetchLastRefreshDateSuccess = createAction(
  '[Quote Management Information] Fetch Last Refresh date success',
  props<{ lastRefreshDate: string }>()
)

/* State Actions */
export const updateQuoteMIActiveTab = createAction(
  '[Quote Management Information] Update Active Tab',
  props<{ tabClicked: QuoteTab }>()
)
export const updateQuoteMIAudienceView = createAction(
  '[Quote Management Information] Update Audience View',
  props<{ audienceView: LayerPaletteProgram }>()
)
export const updateQuoteSummaryContent = createAction(
  '[Quote Management Information] Update Summary Content'
)
export const updateQuoteSummaryContentSuccess = createAction(
  '[Quote Management Information] Update Summary Content - Success',
  props<{
    audienceView: LayerPaletteProgram
    selectors: QuoteSelectors
    summaryData?: QuoteSummaryData
  }>()
)
export const filtersChanged = createAction(
  '[Quote Management Information] Filters changed',
  props<{ filters?: Record<string, string[]> }>()
)
export const layerIntervalsChanged = createAction(
  '[Quote Management Information] Layer Intervals changed',
  props<{ data: IIntervalFilter; isMin: boolean }>()
)
export const timeIntervalsChanged = createAction(
  '[Quote Management Information] Time Intervals changed',
  props<{ data: IIntervalFilter; isMin: boolean }>()
)
export const pinColumnChangeDetails = createAction(
  '[Quote Management Information] Pin Column Change of Details',
  props<{ column: SortTableColumnView }>()
)
export const setDetailsColumnsSettings = createAction(
  '[Quote Management Information] Set Details Columns Settings',
  props<{ columnsSettings: ColumnsSettings }>()
)
export const setRankingsColumnsSettings = createAction(
  '[Quote Management Information] Set Rankings Columns Settings',
  props<{ columnsSettings: ColumnsSettings }>()
)
export const pinColumnChangeRanking = createAction(
  '[Quote Management Information] Pin Column Change of Ranking',
  props<{ column: SortTableColumnView }>()
)
export const setRankingColumnsSettings = createAction(
  '[Quote Management Information] Set Ranking Columns Settings',
  props<{ columnsSettings: ColumnsSettings }>()
)
export const updateSelectedChartsMetric = createAction(
  '[Quote Management Information] Update Selected Charts Metric',
  props<{ selectedChartsMetric: QuoteChartAxis; tab: QuoteTabType }>()
)
export const updateSelectedChartGridGroupBy = createAction(
  '[Quote Management Information] Update Group By for Selected Chart Grid',
  props<{
    selectedGroupBy: QuoteChartAxis
    chartIndex: number
    tab: QuoteTabType
  }>()
)
export const updateSelectedChartGrid = createAction(
  '[Quote Management Information] Update Selected Chart Grid',
  props<{ chartIndex: number; tab: QuoteTabType }>()
)
export const updateSelectedChartGridOption = createAction(
  '[Quote Management Information] Update Selected Chart Grid Option',
  props<{
    chartOption: QuoteChartGridOption
    chartIndex: number
    tab: QuoteTabType
  }>()
)
export const maximizeMIChart = createAction(
  '[Quote Management Information] Maximize',
  props<{ chartIndex: number; tab: QuoteTabType }>()
)

export const setChartEntityLimit = createAction(
  '[Management Information] Set Chart Entity Limit',
  props<{ limit: number; tab: QuoteTabType }>()
)

/* API Actions */
export const fetchQuoteSelectors = createAction(
  '[Quote Management Information] Fetch Selectors'
)
export const fetchQuoteSelectorsSuccess = createAction(
  '[Quote Management Information] Fetch Selectors - Success',
  props<{
    selectorsDto: QuoteSelectorsDto
    audienceView: LayerPaletteProgram
  }>()
)
export const fetchQuoteSelectorsFailure = createAction(
  '[Quote Management Information] Fetch Selectors - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchQuoteSummaryData = createAction(
  '[Quote Management Information] Fetch Summary Data'
)
export const fetchQuoteSummaryDataSuccess = createAction(
  '[Quote Management Information] Fetch Summary Data - Success',
  props<{
    quoteSummaryData: QuoteSummaryData
  }>()
)
export const fetchQuoteSummaryDataFailure = createAction(
  '[Quote Management Information] Fetch Summary Data - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchQuoteDetailsData = createAction(
  '[Quote Management Information] Fetch Details Data'
)
export const fetchQuoteDetailsDataSuccess = createAction(
  '[Quote Management Information] Fetch Details Data - Success',
  props<{ quoteDetailsData: QuoteDetailsData }>()
)
export const fetchQuoteDetailsDataFailure = createAction(
  '[Quote Management Information] Fetch Details Data - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchQuoteRankingData = createAction(
  '[Quote Management Information] Fetch Ranking Data'
)
export const fetchQuoteRankingDataSuccess = createAction(
  '[Quote Management Information] Fetch Ranking Data - Success',
  props<{ quoteRankingData: QuoteRankingData }>()
)
export const fetchQuoteRankingDataFailure = createAction(
  '[Quote Management Information] Fetch Ranking Data - Failure',
  props<{ error: ApplicationError }>()
)
export const fetchQuoteDataCounts = createAction(
  '[Quote Management Information] Fetch Data Counts'
)
export const fetchQuoteDataCountsSuccess = createAction(
  '[Quote Management Information] Fetch Data Counts - Success',
  props<{ quoteDataCounts: DataCounts }>()
)
export const fetchQuoteDataCountsFailure = createAction(
  '[Quote Management Information] Fetch Data Counts - Failure',
  props<{ error: ApplicationError }>()
)
export const updateHideMetrics = createAction(
  '[Quote Management Information] Update Hide Metrics',
  props<{ hideMetrics: boolean }>()
)
export const updateLightChartMode = createAction(
  '[Quote Management Information] Update Light Chart Mode',
  props<{ lightChartMode: boolean }>()
)
