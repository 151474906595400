import { createAction, props } from '@ngrx/store'
import {
  Layer,
  LayerRef,
  PhysicalLayer
} from '../../model/layers.model'
import { ApplicationError } from '../../../error/model/error'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { LossSetContribution } from '../../model/layers-metrics.model'
import { Section } from 'src/app/analysis/properties/layer/multi-section/multi-section.service'
import { BackAllocatedContribution } from '../../layer-details/layer-details.model'
import { LayerMetrics } from 'src/app/analysis/model/layers-metrics.model'

export const addLayer = createAction(
  '[Ceded Layers] Add',
  props<{ layer: Layer }>()
)

export const addLayerSuccess = createAction(
  '[Ceded Layers] Add Success',
  props<{ layer: Layer }>()
)

export const addLayerFailure = createAction(
  '[Ceded Layers] Add Failure',
  props<{ error: ApplicationError }>()
)

export const addDropLayer = createAction(
  '[Ceded Layers] Add Drop Layer',
  props<{ topLayer: Layer }>()
)

export const deleteDropLayer = createAction(
  '[Ceded Layers] Delete Drop Layer',
  props<{ layerId: string }>()
)

export const setSelectedLayer = createAction(
  '[Ceded Layers] Set Selected',
  props<{ id: string | null }>()
)

export const fetchLayers = createAction(
  '[Ceded Layers] Fetch',
  props<{ ids: string[] }>()
)

export const fetchLayersSuccess = createAction(
  '[Ceded Layers] Fetch Success',
  props<{ layers: Layer[] }>()
)

export const fetchLayersFailure = createAction(
  '[Ceded Layers] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const updateLayer = createAction(
  '[Ceded Layers] Update Layer',
  props<{
    id: string
    change: Partial<Layer>
    debounce?: boolean
    noDirty?: boolean
  }>()
)

export const updatePhysicalLayer = createAction(
  '[Ceded Layers] Update Physical Layer',
  props<{
    id: string
    change: Partial<PhysicalLayer>
    debounce?: boolean
    property?: string
    noDirty?: boolean
  }>()
)

export const syncTopAndDropLayers = createAction(
  '[Ceded Layers] Sync Top And Drop Layers',
  props<{ drivingLayerID: string; drivingProperty: string }>()
)

export const updateMultiplePhysicalLayer = createAction(
  '[Ceded Layers] Update Multiple Physical',
  props<{ change: Partial<PhysicalLayer>[] }>()
)

export const deleteLayer = createAction(
  '[Ceded Layers] Delete',
  props<{ id: string }>()
)

export const setDirty = createAction(
  '[Ceded Layers] Set Dirty',
  props<{ id: string; dirty: boolean }>()
)

export const saveLayers = createAction('[Ceded Layers] Save')

export const saveSuccess = createAction(
  '[Ceded Layers] Save Success',
  props<{ structureID: string; layers: Layer[] }>()
)

export const saveFailure = createAction(
  '[Ceded Layers] Save Failure',
  props<{ error: ApplicationError }>()
)

// FHCF Layer
export const addFHCFLayer = createAction(
  '[Ceded Layers] Add FHCF',
  props<{ layer: Layer }>()
)

// Risk Layer
export const addRiskLayer = createAction(
  '[Ceded Layers] Add Risk',
  props<{ layer: Layer }>()
)

export const addRiskLossSetsToLayer = createAction(
  '[Ceded Layers] Add Risk Loss Sets',
  props<{ id: string; lossSets: LossSetLayer[] }>()
)

export const removeRiskLossSetsToLayer = createAction(
  '[Ceded Layers] Remove Risk Loss Sets',
  props<{ id: string; lossSets: LossSetLayer[] }>()
)

export const updateRiskComplete = createAction(
  '[Ceded Layers] Update Risk Complete',
  props<{ cId: string }>()
)

// AAL
export const getAALValues = createAction(
  '[Ceded Layers] Get AAL Values',
  props<{
    id: string
  }>()
)

export const setAALValuesPerLayer = createAction(
  '[Ceded Layers] Set AAL Values Per Layer',
  props<{
    id: string
    lossSetLayers: LayerRef[]
  }>()
)

// Back Allocation
export const getBackAllocatedContributionForMultiSections = createAction(
  '[Ceded Layers] Get BackAllocated Contribution For Multi Sections',
  props<{
    id: string /* visible-layer id*/
  }>()
)

export const getBackAllocatedContribution = createAction(
  '[Ceded Layers] Get BackAllocated Contribution',
  props<{
    id: string
    lossSets: LossSetContribution[]
    forMultiSections?: boolean
  }>()
)

export const getBackAllocatedContributionSuccess = createAction(
  '[Ceded Layers] Get BackAllocated Contribution Success',
  props<{
    data: BackAllocatedContribution[]
    layerID: string
    forMultiSections?: boolean
  }>()
)

export const getBackAllocatedContributionFailure = createAction(
  '[Ceded Layers] Get BackAllocated Contribution Failure',
  props<{ error: ApplicationError }>()
)

export const addIndexedLayer = createAction(
  '[Ceded Layers] Add Indexed',
  props<{ layer: Layer }>()
)

export const addSwingLayer = createAction(
  '[Ceded Layers] Add Swing-rated',
  props<{ layer: Layer }>()
)

export const addSwingCombinedLayer = createAction(
  '[Ceded Layers] Add Swing-rated Combined',
  props<{ layers: Layer[] }>()
)

export const updateMultiSectionFromDialog = createAction(
  '[Ceded Layers] Update Multi-Section From Dialog',
  props<{ layer: Layer; sections: Section[] }>()
)

export const fetchSubsectionLayerMetrics = createAction(
  '[Ceded Layers] Fetch Subsection Layer Metrics',
  props<{
    layerID: string
    currency?: string
    layer: Layer
  }>()
)

export const fetchSubsectionLayerMetricsSuccess = createAction(
  '[Ceded Layers] Fetch Subsection Layer Metrics Success',
  props<{ layerID: string }>()
)

export const fetchSubsectionLayerMetricsFailure = createAction(
  '[Ceded Layers] Fetch Subsection Layer Metrics Failure',
  props<{ error: ApplicationError }>()
)

export const addSublayerMetrics = createAction(
  '[Ceded Layers] Add Subsection Layer Metrics ',
  props<{ layerMetrics: LayerMetrics }>()
)

export const addMultiSectionInuring = createAction(
  '[Ceded Layers] Add Multi-Section Inuring',
  props<{
    visibleLayer: Layer
    sectionLayer: Layer
    section: Section
    inuringSectionIds: string[]
  }>()
)
