import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-animated-scenarios-toolbar',
  styleUrls: ['./animated-scenarios-toolbar.component.scss'],
  templateUrl: './animated-scenarios-toolbar.component.html',
})
export class AnimatedScenariosToolbarComponent {
  @Input() executing: boolean
  @Input() recording: boolean
  @Input() disableReanimate: boolean
  @Input() disableProcessEvents: boolean

  @Output() record = new EventEmitter()
  @Output() stopRecord = new EventEmitter()
  @Output() addEvent = new EventEmitter()
  @Output() processEvents = new EventEmitter()
  @Output() reanimate = new EventEmitter()
}
