import { CurrencyPipe } from '@angular/common'
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { NgModel } from '@angular/forms'
import { max } from 'ramda'
import { analyzereConstants } from '../constants/analyzere'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngModel][formatCurrency]',
  providers: [NgModel],
})
// tslint:disable-next-line:directive-class-suffix
export class FormatCurrency implements OnChanges /*, DoCheck */ {
  @Input() formatCurrency: string
  @Input() input: any
  @Input() currency: string
  @Input() decimal = '0'
  @Input() currencyTransform = (val: number) =>
    this.isValueUnlimited(val) ? analyzereConstants.unlimitedValue : val
  @Output() formatNumber: EventEmitter<number> = new EventEmitter<number>()

  unlimitedValue = analyzereConstants.unlimitedValue

  constructor(public el: ElementRef, private currencyPipe: CurrencyPipe) {
    // this.el.nativeElement.onkeypress = (evt: any) => {
    //   if ((evt.which < 48 || evt.which > 57) && evt.which !== 46) {
    //     evt.preventDefault()
    //   }
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.unlimitedValue = this.currencyTransform(
      analyzereConstants.unlimitedValue
    )

    if (changes.input) {
      const value = this.currencyTransform(Number(changes.input.currentValue))

      const formattedValue = this.format(value)

      this.replaceValue(formattedValue)
    }
  }

  format(value: number): string {
    if (value >= this.unlimitedValue) {
      return 'Unlimited'
    } else {
      return (
        this.currencyPipe.transform(
          value,
          this.currency,
          'symbol-narrow',
          `1.${this.decimal}-${this.decimal}`
        ) ?? ''
      )
    }
  }

  replaceValue(formattedValue: string) {
    const value: string = this.el.nativeElement.value

    const countPunct = (s: string) => (s.match(/[$,.]/g) ?? []).length

    const n = countPunct(value)
    const n2 = countPunct(formattedValue)

    let pos = this.el.nativeElement.selectionStart
    pos += max(n2 - n, 0)

    this.el.nativeElement.value = formattedValue
    this.el.nativeElement.setSelectionRange(pos, pos, 'none')
  }

  @HostListener('focusout')
  ensureInput(): void {
    if (this.input !== '') {
      this.formatCurrency = this.input ?? ''
      const value = this.formatCurrency
      if (value) {
        this.format_number(this.formatCurrency)
      }
    } else {
      this.format_number(0)
    }
  }

  // tslint:disable-next-line:variable-name
  format_number(number: any) {
    const commas = this.el.nativeElement.value.split(',').length - 1
    let num = 0
    let isZ = false
    if (typeof number === 'number') {
      if (number === 0) {
        isZ = true
      }
      number = number.toString()
    } else {
      if (Number(number) === 0) {
        isZ = true
      }
    }
    this.formatNumber.emit(number)
    num = number
    let curr
    if (
      num.toString().indexOf('e+') > -1 ||
      num.toString() === 'Unlimited' ||
      (!isNaN(Number(num)) &&
        Number(num) >=
          this.currencyTransform(analyzereConstants.unlimitedValue))
    ) {
      curr = 'Unlimited'
    } else {
      try {
        curr = this.currencyPipe.transform(
          num,
          this.currency,
          'symbol-narrow',
          `1.${this.decimal}-${this.decimal}`
        )
        curr = curr ?? ''
      } catch (e) {
        console.error('Error', e)
        curr =
          this.currencyPipe.transform(
            0,
            this.currency,
            'symbol-narrow',
            `1.${this.decimal}-${this.decimal}`
          ) ?? ''
      }
    }
    let pos = this.el.nativeElement.selectionStart
    const commasNew = curr.split(',').length - 1
    if (
      this.el.nativeElement.selectionStart ===
        this.el.nativeElement.value.length ||
      commas < commasNew
    ) {
      pos = pos + 1
    }
    if (isZ) {
      pos = pos + 2
    }
    /* tslint:disable-next-line */
    ;(this.el.nativeElement as HTMLInputElement).value = curr + ''
    this.el.nativeElement.setSelectionRange(pos, pos, 'none')
  }

  isValueUnlimited(num: number) {
    return num.toString().includes('e' || 'E')
  }
}
