import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromNetPortfolioActions from './portfolio.actions'
import * as fromCededPortfolioActions from '../ceded-portfolio/portfolio.actions'
import { switchMap, withLatestFrom } from 'rxjs/operators'
import { rejectErrorWithInput, switchMapWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import { Store, select } from '@ngrx/store'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { LogicalPortfolioLayer } from '../../../api/analyzere/analyzere.model'
import {
  selectCurrentCededPortfolioID,
  selectCurrentStructureID,
} from '../analysis.selectors'
import { selectCurrentStudyPrograms } from 'src/app/core/store/program/program.selectors'
import { updateProgram } from 'src/app/core/store/program/program.actions'
import { RepairPortfoliosService } from '../../portfolio/repair-portfolios.service'
@Injectable()
export class NetPortfolioEffects {

  constructor(
    private service: AnalyzreService,
    private actions$: Actions,
    private store: Store<AppState>,
    private repairService: RepairPortfoliosService
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromNetPortfolioActions.fetchPortfolio),
      switchMapWithInput(action => {
        return this.service.fetchPortfolio(action.id)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromNetPortfolioActions.fetchPortfolioFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStudyPrograms)),
        this.store.pipe(select(selectCurrentStructureID))
      ),
      switchMapWithInput(([[portfolio], structures, structureId]) =>
        this.repairService.removeSectionLayersToRepairPortfolio(
          portfolio,
          structureId ?? '',
          structures,
          'netPortfolioID'
        )
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromNetPortfolioActions.fetchPortfolioFailure({ error })
        )
      ),
      withLatestFrom(this.store.pipe(select(selectCurrentCededPortfolioID))),
      switchMap(
        ([
          [
            { portfolio, structureId },
            [[oldPortfolio, { analysisStartProps }]],
          ],
          cededPortfolioID,
        ]) => {
          const actions = []
          const isNewPortfolio = portfolio.id !== oldPortfolio.id
          if (isNewPortfolio) {
            actions.push(
              updateProgram({
                programID: structureId,
                change: {
                  netPortfolioID: portfolio.id,
                },
              })
            )
          } else {
            actions.push(
              fromNetPortfolioActions.fetchPortfolioSuccess({
                id: portfolio.id,
                layersIDs: (portfolio.layers as LogicalPortfolioLayer[]).map(
                  l => l.id
                ),
              })
            )
          }
          actions.push(
            fromCededPortfolioActions.fetchPortfolio({
              // tslint:disable-next-line: no-non-null-assertion
              id: cededPortfolioID!,
              analysisStartProps: {
                ...analysisStartProps,
                netPortfolioID: portfolio.id,
              },
            })
          )
          return actions
        }
      )
    )
  })
}
