import { Action, createReducer, on } from '@ngrx/store'
import * as SlipTemplatesActions from './slip-templates.actions'
import { initialState, State } from './slip-templates.state.facade'

const SlipTemplatesReducer = createReducer(
  initialState,

  on(
    SlipTemplatesActions.getRenewalDetailsSuccess,
    (state, { renewalDetails }) => ({
      ...state,
      renewalDetails,
    })
  ),
  on(SlipTemplatesActions.exportToWhiteSpace, state => ({ ...state, saving: true })),
  on(SlipTemplatesActions.exportToWhiteSpaceSuccess, state => ({
    ...state,
    saving: false,
  })),
  on(SlipTemplatesActions.updateWhitespaceMapping, state => ({ ...state, saving: true })),
  on(SlipTemplatesActions.updateWhitespaceMappingSuccess, state => ({ ...state, saving: false })),
  on(SlipTemplatesActions.updateWhitespaceMappingFailure, state => ({ ...state, saving: false })),
  on(SlipTemplatesActions.fetchTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
  })),
  on(SlipTemplatesActions.exportToWhiteSpaceFailure, state => ({ ...state, saving: false })),
  on(
    SlipTemplatesActions.fetchRiskRefsByProgramIdSuccess,
    (state, { riskRefs }) => ({
      ...state,
      riskRefs,
    })
  ),
  on(SlipTemplatesActions.setRiskRef, (state, { currentRiskRef }) => ({
    ...state,
    currentRiskRef,
  })),
  on(
    SlipTemplatesActions.setSelectedTemplate,
    (state, { currentTemplate }) => ({
      ...state,
      currentTemplate,
    })
  ),
  on(SlipTemplatesActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(
    SlipTemplatesActions.fetchCoBrokedDetailsSuccess,
    (state, { hasCoBroker }) => ({
      ...state,
      hasCoBroker,
    })
  ),
  on(SlipTemplatesActions.fetchCoBrokedDetails, (state, {}) => ({
    ...state,
    coBrokerLoading: true,
  })),
  on(SlipTemplatesActions.fetchCoBrokedDetailsSuccess, (state, {}) => ({
    ...state,
    coBrokerLoading: false,
  })),
  on(
    SlipTemplatesActions.fetchExternalVendorMappingsByStructureSuccess,
    (state, { externalVendorMapping }) => ({
      ...state,
      externalVendorMapping,
    })
  ),
  on(
    SlipTemplatesActions.setCurrentVendorID, (state, { ID }) => ({
      ...state,
      currentVendorID: ID
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return SlipTemplatesReducer(state, action)
}
