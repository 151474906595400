import { curry } from 'ramda'
import { GraphingStrokeStyle } from '@graphing/models/graphing.model'

export const benchmarkOptionsKeys = [
  'quantileArea',
  'percentile75',
  'median',
  'peer-median',
  'peer-average',
  'percentile25',
  'preTaxCatLoss',
  'regression',
  'regressionPeerSets',
  'pointLabel',
  'secondaryAxis',
  'smoothLines',
  'redGreenBars',
  'sortByValue',
  'sortByValueTimeframe',
  'excludeIncompletePeers',
  'combinePeerSets',
  'inclSeparatePeerSets',
  'trendLines',
  'dataLabelFormat',
  'allStateAggregate',
  'colorizeBy',
  'commercial',
  'personal',
  'reinsurance',
  'specific loss',
] as const

export type BenchmarkOptionKey = typeof benchmarkOptionsKeys[number]

export type BenchmarkOptionType = 'quantile' | 'chart' | 'metric' | 'subline'

export type BenchmarkOptionControlType =
  | 'legend-tag'
  | 'checkbox'
  | 'select'
  | 'hidden'

export type BenchmarkOptionShowFilter =
  | 'hasNonCompositePeerSets'
  | 'usP&CIsNotTheOnlyPeerSetSelected'

export interface BenchmarkOption {
  id: BenchmarkOptionKey | string
  type: BenchmarkOptionType
  controlType: BenchmarkOptionControlType
  name: string
  color?: string
  style?: GraphingStrokeStyle
  classMod?: string
  /** Default value is set to `true` if not specified */
  value: string | boolean
  valueOptions?: string[]
  quantileP?: number
  refetchOnChange?: boolean
  showWhen?: BenchmarkOptionShowFilter
}

export const benchmarkOptions: BenchmarkOption[] = [
  {
    id: 'quantileArea',
    type: 'chart',
    controlType: 'legend-tag',
    name: '25-75th Quantile',
    value: false,
    color: 'gray',
    style: 'solid',
    classMod: 'app-palette-subtle',
  },
  {
    id: 'percentile75',
    type: 'quantile',
    controlType: 'legend-tag',
    name: '75%',
    value: false,
    color: 'teal',
    style: 'dot',
    classMod: 'app-palette-subtle',
    quantileP: 0.75,
  },
  {
    id: 'median',
    type: 'quantile',
    controlType: 'legend-tag',
    name: 'Median',
    value: false,
    color: 'gray',
    style: 'dash',
    classMod: 'app-palette-subtle',
    quantileP: 0.5,
  },
  {
    id: 'peer-median',
    type: 'quantile',
    controlType: 'legend-tag',
    name: 'Peer Median',
    value: false,
    color: 'gray',
    style: 'dash',
    classMod: 'app-palette-subtle',
  },
  {
    id: 'peer-average',
    type: 'quantile',
    controlType: 'legend-tag',
    name: 'Peer Average',
    value: false,
    color: 'orange',
    style: 'dash',
    classMod: 'app-palette-subtle',
  },
  {
    id: 'percentile25',
    type: 'quantile',
    controlType: 'legend-tag',
    name: '25%',
    value: false,
    color: 'red',
    style: 'dot',
    classMod: 'app-palette-subtle',
    quantileP: 0.25,
  },
  {
    id: 'preTaxCatLoss',
    type: 'metric',
    controlType: 'legend-tag',
    name: 'Pre-tax Cat Loss',
    value: true,
    color: 'warn-strong',
    style: 'dash',
  },
  {
    id: 'regression',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'Regression',
    value: true,
    color: 'gray',
    style: 'dash',
  },
  {
    id: 'regressionPeerSets',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'Peer Group Regressions',
    value: true,
    color: 'teal',
    style: 'dash',
  },
  {
    id: 'pointLabel',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Show Tickers',
    value: true,
  },
  {
    id: 'secondaryAxis',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Show Target Company Raw Values',
    value: true,
  },
  {
    id: 'smoothLines',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Smooth Lines',
    value: true,
  },
  {
    id: 'redGreenBars',
    type: 'chart',
    controlType: 'hidden',
    name: 'Use Red/Green Bars',
    value: true,
  },
  {
    id: 'sortByValue',
    type: 'chart',
    controlType: 'select',
    name: 'Sort by Value',
    value: 'Ascending',
    valueOptions: ['None', 'Ascending', 'Descending'],
  },
  {
    id: 'sortByValueTimeframe',
    type: 'chart',
    controlType: 'select',
    name: 'Sort by Value Timeframe',
    value: 'Most Recent Year',
    valueOptions: ['Most Recent Year', '5yr Average', '10yr Average'],
  },
  {
    id: 'excludeIncompletePeers',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Exclude Peers Without Full Timeframe',
    value: false,
    refetchOnChange: true,
  },
  {
    id: 'combinePeerSets',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Combine Peers into Groups',
    value: false,
    refetchOnChange: true,
    showWhen: 'hasNonCompositePeerSets',
  },
  {
    id: 'inclSeparatePeerSets',
    type: 'chart',
    controlType: 'checkbox',
    name: 'Combine Peers into Groups',
    value: false,
    refetchOnChange: true,
  },
  {
    id: 'trendLines',
    type: 'chart',
    controlType: 'select',
    name: 'Trend Line',
    value: 'None',
    valueOptions: ['None', 'Average', 'Median'],
  },
  {
    id: 'dataLabelFormat',
    type: 'chart',
    controlType: 'select',
    name: 'Data Label Format',
    value: 'Whole Number',
    valueOptions: ['None', 'Whole Number', 'One Decimal'],
  },
  {
    id: 'allStateAggregate',
    type: 'chart',
    controlType: 'checkbox',
    // `{colorizeBy}` will be replaced by the value of the `colorizeBy` option
    name: 'Show {colorizeBy} Aggregates',
    value: false,
    refetchOnChange: true,
  },
  {
    id: 'colorizeBy',
    type: 'chart',
    controlType: 'select',
    name: 'Colorize By',
    value: 'LOB',
    valueOptions: ['LOB', 'State'],
    refetchOnChange: true,
  },
  {
    id: 'commercial',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'All Commercial Lines',
    value: false,
    color: 'gray',
    style: 'solid',
  },
  {
    id: 'personal',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'All Personal Lines',
    value: false,
    color: 'lightgray',
    style: 'dash',
  },
  {
    id: 'reinsurance',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'All Reinsurance Lines',
    value: false,
    color: 'gray',
    style: 'dash',
  },
  {
    id: 'specific loss',
    type: 'chart',
    controlType: 'legend-tag',
    name: 'All Specifc Loss Lines',
    value: false,
    color: 'lightgray',
    style: 'solid',
  },
]

export const benchmarkOptionsByID = benchmarkOptions.reduce((acc, o) => {
  acc[o.id] = o
  return acc
}, {} as Record<string, BenchmarkOption>)

export const getBenchmarkOption = curry(
  (
    options: BenchmarkOption[],
    optionKey: BenchmarkOptionKey
  ): BenchmarkOption | undefined => {
    return options?.find(o => o.id === optionKey)
  }
)

export const isBenchmarkOptionTrue = curry(
  (options: BenchmarkOption[], optionKey: BenchmarkOptionKey): boolean => {
    const opt = getBenchmarkOption(options, optionKey)
    return opt != null && opt.value === true
  }
)

export const isBenchmarkOptionFalse = curry(
  (options: BenchmarkOption[], optionKey: BenchmarkOptionKey): boolean => {
    const opt = getBenchmarkOption(options, optionKey)
    return opt != null && opt.value === false
  }
)
