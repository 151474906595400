import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  PricingCurveAddDataDialogPayload,
  PricingCurveBaseDialogOptions,
  PricingCurveCarrierPermissionsDialogPayload,
  PricingCurveDataSettingsDialogPayload,
  PricingCurveExcludeLayerDialogPayload,
  PricingCurveGraphSettingsDialogPayload,
  addDataDefaults,
  carrierPermissionsDefaults,
  excludeLayerDefaults,
  exportPreviewDefaults,
  graphSettingsDefaults,
  settingsDefaults,
} from './pricing-curve-dialog.model'
import { PricingCurveExportPreviewDialogComponent } from '../layout/export-preview-dialog/pricing-curve-export-preview-dialog.component'
import { PricingCurveExcludeLayerDialogComponent } from '../layout/exclude-layer-dialog/pricing-curve-exclude-layer-dialog.component'
import { PricingCurveSettingsDialogComponent } from '../layout/settings-dialog/pricing-curve-settings-dialog.component'
import { AddDataDialogContainerComponent } from '../layout/add-data-dialog-container/add-data-dialog.container'
import { PricingCurveGraphSettingsDialogComponent } from '../layout/graph-settings-dialog/pricing-curve-graph-settings-dialog.component'
import { PricingCurvePermissionsDialogComponent } from '../layout/credit-premissions-dialog/pricing-curve-permissions-dialog.component'

@Injectable({ providedIn: 'root' })
export class PricingCurveDialogService {
  constructor(private dialog: MatDialog) {}

  openAddDataDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>,
    d?: PricingCurveAddDataDialogPayload
  ): MatDialogRef<AddDataDialogContainerComponent> {
    const options = addDataDefaults(opts)
    const data = d ?? {}
    return this.dialog.open(AddDataDialogContainerComponent, {
      ...options,
      data,
    })
  }

  openExcludeLayerDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>,
    d?: PricingCurveExcludeLayerDialogPayload
  ): MatDialogRef<PricingCurveExcludeLayerDialogComponent> {
    const options = excludeLayerDefaults(opts)
    const data = d ?? {}
    return this.dialog.open(PricingCurveExcludeLayerDialogComponent, {
      ...options,
      data,
    })
  }

  openExportPreviewDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>
  ): MatDialogRef<PricingCurveExportPreviewDialogComponent> {
    const options = exportPreviewDefaults(opts)
    return this.dialog.open(PricingCurveExportPreviewDialogComponent, {
      ...options,
      data: {},
    })
  }

  openDataSettingsDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>,
    d?: PricingCurveDataSettingsDialogPayload
  ): MatDialogRef<PricingCurveSettingsDialogComponent> {
    const options = settingsDefaults(opts)
    const data = d ?? {}
    return this.dialog.open(PricingCurveSettingsDialogComponent, {
      ...options,
      data,
    })
  }

  openGraphSettingsDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>,
    d?: PricingCurveGraphSettingsDialogPayload
  ): MatDialogRef<PricingCurveGraphSettingsDialogComponent> {
    const options = graphSettingsDefaults(opts)
    const data = d ?? {}
    return this.dialog.open(PricingCurveGraphSettingsDialogComponent, {
      ...options,
      data,
    })
  }

  openCreditCarrierPermissionsDialog(
    opts?: Partial<PricingCurveBaseDialogOptions>,
    d?: PricingCurveCarrierPermissionsDialogPayload
  ) {
    const options = carrierPermissionsDefaults(opts)
    const data = d ?? {}
    return this.dialog.open(PricingCurvePermissionsDialogComponent, {
      ...options,
      data,
    })
  }
}
