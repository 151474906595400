import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Dictionary } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { partition, uniq } from 'ramda'

import { forkJoin, Observable, of, UnaryFunction } from 'rxjs'
import { concatMap, map, withLatestFrom, mergeMap } from 'rxjs/operators'

import {
  LogicalPortfolioLayer,
  Portfolio,
} from '../../../../api/analyzere/analyzere.model'

import { ApiResponse } from '../../../../api/model/api.model'
import {
  catchAndHandleError,
  concatMapWithInput,
  mergeApiResponses,
  rejectError,
  rejectErrorWithInput,
} from '../../../../api/util'
import { AppState } from '../../../../core/store'
import {
  InuranceLevel,
  InurancePayload,
  InuranceReference,
  InuranceView,
} from '../../../model/inurance.model'
import { convertFromLogicalPortfolioLayers } from '../../../model/layers.converter'
import { Layer } from '../../../model/layers.model'
import {
  selectGrouperInurancePayload,
  selectGrouperProgramEntitiesByID,
  selectGrouperPrograms,
  selectGrouperProgramGroups,
  selectGrouperProgramGroupMembers,
  selectAllGrouperProgramCededLayerStates,
  selectCurrentClientID,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectToDeleteFromDesign,
} from '../../analysis.selectors'
import { ProgramEntity } from '../program/program.reducer'
import * as fromActions from './grouper-inurance.actions'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import {
  canInure,
  filterReferences,
  canInureDelete,
  createLayerInuranceView,
  createInurancePayloadFromDesign,
  toInuranceRefs,
  createStructureInuranceView,
} from 'src/app/analysis/model/inurance.util'
import { selectCurrentClient } from '../../../../core/store/broker/broker.selectors'
import { createFetchPortfolioViewActionsFromGroups } from '../program-group/create-portfolio-view-action'
import {
  fetchViewsForStructuresAndGetAncestors,
  getGroupsWithStudyAndClient,
} from '../grouper-util'
import { extractPortfolioSetID } from '../../../model/portfolio-set-id.util'
import { startAnalysis } from '../../analysis.actions'
import { AnalyzreService } from '../../../../api/analyzere/analyzre.service'
import { setCurrentStructure } from '../../../../core/store/broker/broker.actions'
import { setSelectedLayer } from '../../ceded-layers/layers.actions'
import { errorPayload } from '../../../../error/model/error'
import {
  findMainLayerOfSectionLayer,
  findMultiSelectVisibleLayer,
} from 'src/app/analysis/model/layers.util'
import { Program } from 'src/app/core/model/program.model'

// tslint:disable: no-non-null-assertion
@Injectable()
export class GrouperInuranceEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private inuranceService: InuranceService,
    private analyzReService: AnalyzreService
  ) {}

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteGrouperInurance),
      this.mapToLayersDelete(),
      rejectErrorWithInput(error =>
        this.store.dispatch(fromActions.deleteGrouperInuranceFailure({ error }))
      ),
      map(
        ([
          [sourceLayers, targetLayers],
          {
            source,
            target,
            relationship,
            fromDesign,
            layerForRefresh,
            structureForRefresh,
          },
        ]) => {
          const filteredReferenceTuplet = filterReferences(
            source,
            target,
            sourceLayers,
            targetLayers
          )
          return [
            [sourceLayers, targetLayers],
            {
              source: filteredReferenceTuplet[0],
              target: filteredReferenceTuplet[1],
              relationship,
              fromDesign,
              layerForRefresh,
              structureForRefresh,
            },
          ] as const
        }
      ),
      concatMapWithInput(
        ([[sourceLayers, targetLayers], { source, target, relationship }]) => {
          return this.inuranceService.deleteInurance(
            sourceLayers,
            targetLayers,
            source,
            target,
            relationship
          )
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(fromActions.deleteGrouperInuranceFailure({ error }))
      ),
      concatMap(
        ([
          localPortofolioLayers,
          [
            [_oldSourceLayers, _oldTargetLayers],
            {
              source,
              target,
              fromDesign,
              layerForRefresh,
              structureForRefresh,
            },
          ],
        ]) => {
          const actions = []
          const [newSourceLayers, newTargetLayers] = this.toSourceAndTarget(
            localPortofolioLayers,
            source,
            target
          )
          const updates: Record<string, Partial<Layer>[]> =
            this.groupChangesByStructureID(
              newSourceLayers,
              newTargetLayers,
              source,
              target
            )
          actions.push(
            fromActions.deleteGrouperInuranceSuccess({
              changes: updates,
            })
          )
          if (fromDesign && layerForRefresh && structureForRefresh) {
            actions.push(fromActions.deleteInuranceFromDesignSuccess())
            actions.push(
              fromActions.refreshOnChangedInurance({
                layer: newTargetLayers[0],
                structure: structureForRefresh,
              })
            )
            actions.push(setCurrentStructure({ id: structureForRefresh.id }))
            actions.push(setSelectedLayer({ id: layerForRefresh.id }))
          }
          return actions
        }
      )
    )
  })

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.saveGrouperInurance),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectGrouperInurancePayload))),
          map(([_, data]) => ({ data })),
          catchAndHandleError('Create Inurance Save Payload')
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.saveGrouperInuranceFailure({ error }))
      ),
      this.mapToLayers(),
      rejectErrorWithInput(error =>
        this.store.dispatch(fromActions.saveGrouperInuranceFailure({ error }))
      ),
      map(
        ([
          [sourceLayers, targetLayers],
          {
            source,
            target,
            relationship,
            fromDesign,
            structureForRefresh,
            layerForRefresh,
          },
        ]) => {
          const filteredReferenceTuplet = filterReferences(
            source,
            target,
            sourceLayers,
            targetLayers
          )
          return [
            [sourceLayers, targetLayers],
            {
              source: filteredReferenceTuplet[0],
              target: filteredReferenceTuplet[1],
              relationship,
              fromDesign,
              structureForRefresh,
              layerForRefresh,
            },
          ] as const
        }
      ),
      concatMapWithInput(
        ([[sourceLayers, targetLayers], { relationship, source, target }]) => {
          return this.inuranceService.inure(
            sourceLayers,
            targetLayers,
            source,
            target,
            relationship
          )
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(fromActions.saveGrouperInuranceFailure({ error }))
      ),
      concatMap(
        ([
          localPortofolioLayers,
          [
            [_oldSourceLayers, _oldTargetLayers],
            {
              source,
              target,
              fromDesign,
              structureForRefresh,
              layerForRefresh,
            },
          ],
        ]) => {
          const actions = []
          const [newSourceLayers, newTargetLayers] = this.toSourceAndTarget(
            localPortofolioLayers,
            source,
            target
          )
          const updates: Record<string, Partial<Layer>[]> =
            this.groupChangesByStructureID(
              newSourceLayers,
              newTargetLayers,
              source,
              target
            )
          if (fromDesign) {
            const targetLayer = newTargetLayers[0]
            actions.push(
              fromActions.updateToDeleteFromDesign({
                newTargetLayer: targetLayer,
              })
            )
            if (structureForRefresh && layerForRefresh) {
              actions.push(fromActions.deleteSwapInuranceFromDesign())
            }
            actions.push(
              fromActions.saveGrouperInuranceFromDesignSuccess({
                changes: updates,
              })
            )
          } else {
            actions.push(
              fromActions.saveGrouperInuranceSuccess({ changes: updates })
            )
          }
          return actions
        }
      )
    )
  })

  fetchPortfolioViewOnInuranceChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.saveGrouperInuranceSuccess,
        fromActions.deleteGrouperInuranceSuccess
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClient)),
            this.store.pipe(select(selectGrouperPrograms)),
            this.store.pipe(select(selectAllGrouperProgramCededLayerStates))
          )
        )
      ),
      map(([action, client, allPrograms, allLayers]) => {
        const refreshSlideList = action.changes
        // Filter only structures that are part of inurance update
        const programs = allPrograms.filter(p =>
          Object.keys(refreshSlideList).includes(p.id)
        )
        return fetchViewsForStructuresAndGetAncestors(
          programs,
          allLayers,
          client,
          this.store
        )
      }),
      concatMap(groups => {
        return groups.pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramGroups)),
            this.store.pipe(select(selectCurrentClient)),
            this.store.pipe(select(selectGrouperProgramEntitiesByID)),
            this.store.pipe(select(selectGrouperProgramGroupMembers))
          )
        )
      }),
      map(props => {
        // Flatten groups and filter for only on screen and remove duplicates
        const groupsWithStudyAndClient = getGroupsWithStudyAndClient(...props)
        return createFetchPortfolioViewActionsFromGroups(
          groupsWithStudyAndClient
        )
      }),
      mergeMap(actions => [...actions])
    )
  )

  deleteForSwapFromDesign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteSwapInuranceFromDesign),
      withLatestFrom(this.store.pipe(select(selectToDeleteFromDesign))),
      map(([_, toDelete]) => {
        if (toDelete) {
          return fromActions.deleteInuranceFromDesign(toDelete)
        } else {
          const err = 'Unexpected Error: Delete Failing After Saving Swap'
          return fromActions.deleteInuranceFromDesignFailure({
            error: errorPayload(err),
          })
        }
      })
    )
  })

  deleteInuranceFromDesign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteInuranceFromDesign),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        const {
          relationship,
          sourceStructure,
          sourceGroup,
          structureOfTarget,
          targetGroup,
        } = props
        let sourcePortfolioId: string

        if (sourceGroup && sourceGroup.cededPortfolioID) {
          sourcePortfolioId = sourceGroup.cededPortfolioID
        } else {
          sourcePortfolioId = (sourceStructure as Program).cededPortfolioID
        }

        const actions: Array<ApiResponse<Portfolio>> = [
          this.analyzReService.fetchPortfolio(sourcePortfolioId),
        ]

        let targetPortfolioId: string
        if (targetGroup && targetGroup.cededPortfolioID) {
          targetPortfolioId = targetGroup.cededPortfolioID
        } else {
          targetPortfolioId = structureOfTarget?.cededPortfolioID
        }

        if (targetPortfolioId && !relationship.endsWith('Layer')) {
          actions.push(this.analyzReService.fetchPortfolio(targetPortfolioId))
        }

        return forkJoin(actions).pipe(mergeApiResponses())
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromActions.deleteInuranceFromDesignFailure({ error })
        )
      ),
      concatMapWithInput(([[sourcePortfolio, targetPortfolio]]) => {
        const sourceLayerIds: string[] = []
        sourcePortfolio?.layers.forEach((layer: any) => {
          if (layer.meta_data.backAllocatedForID) {
            sourceLayerIds.push(layer.meta_data.backAllocatedForID)
          }
          if (layer.meta_data.visible_layer_id) {
            sourceLayerIds.push(layer.meta_data.visible_layer_id)
          }
          sourceLayerIds.push(layer.id)
        })

        const targetLayerIds: string[] = []
        targetPortfolio?.layers.forEach((layer: any) => {
          if (layer.meta_data.backAllocatedForID) {
            targetLayerIds.push(layer.meta_data.backAllocatedForID)
          }
          if (layer.meta_data.visible_layer_id) {
            targetLayerIds.push(layer.meta_data.visible_layer_id)
          }
          targetLayerIds.push(layer.id)
        })

        const actions: Array<ApiResponse<LogicalPortfolioLayer[]>> = [
          this.analyzReService.fetchLayers<LogicalPortfolioLayer>(
            sourceLayerIds
          ),
        ]

        if (targetPortfolio && targetLayerIds.length > 0) {
          actions.push(
            this.analyzReService.fetchLayers<LogicalPortfolioLayer>(
              targetLayerIds
            )
          )
        }

        return forkJoin(actions).pipe(mergeApiResponses())
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromActions.deleteInuranceFromDesignFailure({ error })
        )
      ),
      map(
        ([
          [sourcePortfolioLayersResponse, targetPortfolioLayersResponse],
          [_, props],
        ]) => {
          let payload: InurancePayload | undefined
          let err: string | undefined =
            'Unexpected Error: Unable to create Inurance Payload to Delete'

          const sourcePortfolioLayers: Layer[] =
            convertFromLogicalPortfolioLayers(sourcePortfolioLayersResponse)

          const targetPortfolioLayers: Layer[] =
            convertFromLogicalPortfolioLayers(
              targetPortfolioLayersResponse ?? []
            )

          const type = 'source'
          const fromDesign = true
          const {
            relationship,
            sourceLayer,
            sourceStructure,
            sourceGroup,
            targetLayer,
            structureOfTarget,
          } = props
          let targetView = props.targetView

          let layerForRefresh = targetLayer
          if (targetLayer.meta_data.sage_layer_subtype === 'section-layer') {
            const mainLayer = findMainLayerOfSectionLayer(
              sourcePortfolioLayers,
              targetLayer
            )
            const visibleLayer = findMultiSelectVisibleLayer(
              sourcePortfolioLayers,
              mainLayer
            )
            layerForRefresh = visibleLayer ?? targetLayer
          }

          if (
            relationship === 'layerToLayer' &&
            sourceLayer &&
            sourceStructure
          ) {
            const levelFromDesign: InuranceLevel = 'layer'
            const layer = sourcePortfolioLayers.find(
              l =>
                l.id === (sourceLayer.meta_data.main_layer_id ?? sourceLayer.id)
            )
            if (layer) {
              const referencesFromDesign: InuranceReference[] = [
                { layerID: layer.id, structureID: sourceStructure.id },
              ]

              const sourceView: InuranceView = createLayerInuranceView(
                type,
                sourceStructure,
                layer,
                undefined,
                fromDesign,
                levelFromDesign,
                referencesFromDesign
              )

              payload = {
                ...createInurancePayloadFromDesign(
                  sourceView,
                  targetView,
                  [layer],
                  [targetLayer]
                ),
                layerForRefresh,
                structureForRefresh: structureOfTarget,
              }
            } else {
              err =
                'Unexpected Error: Error with Source Layer, unable to create Inurance Payload to Delete'
            }
          } else if (
            relationship === 'structureToLayer' ||
            relationship === 'structureGroupToLayer'
          ) {
            if (sourceStructure || sourceGroup) {
              const references: InuranceReference[] = []
              const levelFromDesign: InuranceLevel = sourceGroup
                ? 'programGroup'
                : 'program'
              const toProcess = sourceGroup ?? (sourceStructure as Program)

              sourcePortfolioLayers.map(layer => {
                toInuranceRefs(layer.meta_data.inuranceSourceFor).map(ref => {
                  if (ref.id === targetLayer.id) {
                    references.push({
                      layerID: layer.id,
                      structureID: sourceGroup
                        ? (layer.meta_data.structureID as string)
                        : (sourceStructure as Program).id,
                      structureGroupID: sourceGroup?.id ?? undefined,
                    })
                  }
                })
              })

              const sourceView: InuranceView = createStructureInuranceView(
                type,
                toProcess,
                undefined,
                fromDesign,
                levelFromDesign,
                references
              )

              payload = {
                ...createInurancePayloadFromDesign(
                  sourceView,
                  targetView,
                  sourcePortfolioLayers,
                  [targetLayer]
                ),
                layerForRefresh,
                structureForRefresh: structureOfTarget,
              }
            } else {
              err =
                'Unexpected Error: Error with Source Structure or Group, unable to create Inurance Payload to Delete'
            }
          } else if (
            relationship === 'structureToStructure' ||
            relationship === 'structureGroupToStructure'
          ) {
            if (sourceStructure || sourceGroup) {
              const levelFromDesign: InuranceLevel = sourceGroup
                ? 'programGroup'
                : 'program'
              const sourceToProcess =
                sourceGroup ?? (sourceStructure as Program)
              const sourceReferences: InuranceReference[] = []

              sourcePortfolioLayers.forEach(layer => {
                toInuranceRefs(layer.meta_data.inuranceSourceFor).map(ref => {
                  if (ref.id === targetView.programID) {
                    sourceReferences.push({
                      layerID: layer.id,
                      structureID: sourceGroup
                        ? (layer.meta_data.structureID as string)
                        : (sourceStructure as Program).id,
                      structureGroupID: sourceGroup?.id ?? undefined,
                    })
                  }
                })
              })

              const targetLayers: Layer[] = []
              targetPortfolioLayers.forEach(layer => {
                toInuranceRefs(layer.meta_data.inuranceTargetFor).map(ref => {
                  if (ref.id === sourceToProcess.id) {
                    targetLayers.push(layer)
                  }
                })
              })

              const sourceView = createStructureInuranceView(
                type,
                sourceToProcess,
                undefined,
                fromDesign,
                levelFromDesign,
                sourceReferences
              )

              payload = {
                ...createInurancePayloadFromDesign(
                  sourceView,
                  targetView,
                  sourcePortfolioLayers,
                  targetLayers
                ),
                layerForRefresh,
                structureForRefresh: structureOfTarget,
              }
            } else {
              err =
                'Unexpected Error: Error with Source Structure or Group, unable to create Inurance Payload to Delete'
            }
          } else if (
            (relationship === 'layerToStructure' ||
              relationship === 'layerToStructureGroup') &&
            sourceStructure &&
            sourceLayer
          ) {
            const levelFromDesign: InuranceLevel = 'layer'
            const layer = sourcePortfolioLayers.find(
              l =>
                l.id === (sourceLayer.meta_data.main_layer_id ?? sourceLayer.id)
            )

            if (layer) {
              const sourceReferences: InuranceReference[] = [
                { layerID: layer.id, structureID: sourceStructure.id },
              ]

              const sourceView = createLayerInuranceView(
                type,
                sourceStructure,
                layer,
                undefined,
                fromDesign,
                levelFromDesign,
                sourceReferences
              )

              const targetLayers: Layer[] = []
              targetPortfolioLayers.forEach(l => {
                toInuranceRefs(l.meta_data.inuranceTargetFor).map(ref => {
                  if (ref.id === layer.id) {
                    targetLayers.push(l)
                  }
                })
              })

              if (relationship.endsWith('StructureGroup')) {
                const targetReferences: InuranceReference[] = []
                targetLayers.forEach(l =>
                  targetReferences.push({
                    layerID: l.id,
                    structureID: l.meta_data.structureID as string,
                    structureGroupID: targetView.programGroupID,
                  })
                )

                targetView = {
                  ...targetView,
                  referencesFromDesign: targetReferences,
                }
              }

              payload = {
                ...createInurancePayloadFromDesign(
                  sourceView,
                  targetView,
                  [layer],
                  targetLayers
                ),
                layerForRefresh,
                structureForRefresh: structureOfTarget,
              }
            } else {
              err =
                'Unexpected Error: Error with Source Layer, unable to create Inurance Payload to Delete'
            }
          } else if (
            relationship === 'structureToStructureGroup' ||
            relationship === 'structureGroupToStructureGroup'
          ) {
            if (sourceStructure || sourceGroup) {
              const sourceToProcess =
                sourceGroup ?? (sourceStructure as Program)
              const levelFromDesign: InuranceLevel = sourceGroup
                ? 'programGroup'
                : 'program'
              const sourceReferences: InuranceReference[] = []

              sourcePortfolioLayers.forEach(layer => {
                toInuranceRefs(layer.meta_data.inuranceSourceFor).map(ref => {
                  if (ref.id === targetView.programGroupID) {
                    sourceReferences.push({
                      layerID: layer.id,
                      structureID: sourceGroup
                        ? (layer.meta_data.structureID as string)
                        : (sourceStructure as Program).id,
                    })
                  }
                })
              })

              const targetLayers: Layer[] = []
              targetPortfolioLayers.forEach(layer => {
                toInuranceRefs(layer.meta_data.inuranceTargetFor).map(ref => {
                  if (ref.id === sourceToProcess.id) {
                    targetLayers.push(layer)
                  }
                })
              })

              if (relationship.endsWith('StructureGroup')) {
                const targetReferences: InuranceReference[] = []
                targetLayers.forEach(l =>
                  targetReferences.push({
                    layerID: l.id,
                    structureID: l.meta_data.structureID as string,
                    structureGroupID: targetView.programGroupID,
                  })
                )

                targetView = {
                  ...targetView,
                  referencesFromDesign: targetReferences,
                }
              }

              const sourceView = createStructureInuranceView(
                type,
                sourceToProcess,
                undefined,
                fromDesign,
                levelFromDesign,
                sourceReferences
              )

              payload = {
                ...createInurancePayloadFromDesign(
                  sourceView,
                  targetView,
                  sourcePortfolioLayers,
                  targetLayers
                ),
                layerForRefresh,
                structureForRefresh: structureOfTarget,
              }
            } else {
              err =
                'Unexpected Error: Error with Source Structure or Group, unable to create Inurance Payload to Delete'
            }
          } else {
            err =
              'Unexpected Error: Error with Source, unable to create Inurance Payload to Delete'
          }

          if (payload) {
            return fromActions.deleteGrouperInurance(payload)
          } else {
            return fromActions.deleteInuranceFromDesignFailure({
              error: errorPayload(err),
            })
          }
        }
      )
    )
  })

  refreshStructureOnChangedInurance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.refreshOnChangedInurance),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClientID)),
            this.store.pipe(select(selectCurrentStudyID)),
            this.store.pipe(select(selectCurrentYearID))
          )
        )
      ),
      map(([action, clientID, studyID, yearID]) => {
        const portfolioSetID = extractPortfolioSetID(action.structure)
        if (portfolioSetID && clientID && studyID && yearID) {
          const {
            analysisProfileID,
            cededPortfolioID,
            grossPortfolioID,
            netPortfolioID,
            parentGrossPortfolioID,
          } = portfolioSetID

          this.store.dispatch(
            startAnalysis({
              cededPortfolioID,
              grossPortfolioID,
              netPortfolioID,
              parentGrossPortfolioID,
              analysisProfileID,
              clientID,
              studyID,
              yearID,
              // tslint:disable-next-line: no-non-null-assertion
              structureID: action.structure!.id,
              fromSave: false,
            })
          )
        }
        return { type: 'No Action' }
      })
    )
  })

  private toSourceAndTarget(
    LogicalPortfolioLayers: LogicalPortfolioLayer[],
    source: InuranceReference[],
    target: InuranceReference[]
  ): readonly [Layer[], Layer[]] {
    const allLayerIDs = [...source, ...target].map(ref => ref.layerID)
    const updatedLayers = convertFromLogicalPortfolioLayers(
      LogicalPortfolioLayers
    )
      .filter(l => allLayerIDs.includes(l.id))
      .filter(canInure)
    return partition(
      l => source.map(s => s.layerID).includes(l.id),
      updatedLayers
    )
  }

  private groupChangesByStructureID(
    sourceLayers: Layer[],
    targetLayers: Layer[],
    source: InuranceReference[],
    target: InuranceReference[]
  ) {
    const groupByStructureID: Record<string, Partial<Layer>[]> = {}
    source.forEach(s => {
      const layer = sourceLayers.find(l => l.id === s.layerID)
      if (layer) {
        if (groupByStructureID[s.structureID]) {
          groupByStructureID[s.structureID].push({
            id: layer.id,
            meta_data: layer.meta_data,
          })
        } else {
          groupByStructureID[s.structureID] = [
            {
              id: layer.id,
              meta_data: layer.meta_data,
            },
          ]
        }
      }
    })
    target.forEach(s => {
      const layer = targetLayers.find(l => l.id === s.layerID)
      if (layer) {
        if (groupByStructureID[s.structureID]) {
          groupByStructureID[s.structureID].push({
            id: layer.id,
            meta_data: layer.meta_data,
            lossSetLayers: layer.lossSetLayers,
          })
        } else {
          groupByStructureID[s.structureID] = [
            {
              id: layer.id,
              meta_data: layer.meta_data,
              lossSetLayers: layer.lossSetLayers,
            },
          ]
        }
      }
    })
    return groupByStructureID
  }

  private layersFromState(
    entities: Dictionary<ProgramEntity>,
    ref: InuranceReference[]
  ) {
    const layers: Layer[] = []
    const targetStructureIDs = uniq(ref.map(t => t.structureID))
    const targetLayerIDs = ref.map(t => t.layerID)
    for (const structureID of targetStructureIDs) {
      for (const key in entities) {
        if (entities[key]!.program.id === structureID) {
          layers.push(
            ...entities[key]!.cededLayers.map(l => l.layer).filter(l =>
              targetLayerIDs.includes(l.id)
            )
          )
        }
      }
    }
    return layers
  }

  private mapToLayers(): UnaryFunction<
    Observable<InurancePayload>,
    ApiResponse<readonly [[Layer[], Layer[]] | undefined, InurancePayload]>
  > {
    return concatMapWithInput(
      ({ source, target }): ApiResponse<[Layer[], Layer[]]> => {
        return of(1).pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramEntitiesByID))
          ),
          map(([_, programEntitiesDictionary]) => {
            const sourceLayers = this.layersFromState(
              programEntitiesDictionary,
              source
            ).filter(canInure)
            const targetLayers = this.layersFromState(
              programEntitiesDictionary,
              target
            ).filter(canInure)
            return { data: [sourceLayers, targetLayers] }
          })
        )
      }
    )
  }

  private mapToLayersDelete(): UnaryFunction<
    Observable<InurancePayload>,
    ApiResponse<readonly [[Layer[], Layer[]] | undefined, InurancePayload]>
  > {
    return concatMapWithInput(
      ({
        source,
        target,
        fromDesign,
        fromDesignSourceLayers,
        fromDesignTargetLayers,
      }): ApiResponse<[Layer[], Layer[]]> => {
        return of(1).pipe(
          withLatestFrom(
            this.store.pipe(select(selectGrouperProgramEntitiesByID))
          ),
          map(([_, programEntitiesDictionary]) => {
            if (
              fromDesign &&
              fromDesignSourceLayers &&
              fromDesignTargetLayers
            ) {
              return { data: [fromDesignSourceLayers, fromDesignTargetLayers] }
            } else {
              const sourceLayers = this.layersFromState(
                programEntitiesDictionary,
                source
              ).filter(canInureDelete)
              const targetLayers = this.layersFromState(
                programEntitiesDictionary,
                target
              ).filter(canInureDelete)
              return { data: [sourceLayers, targetLayers] }
            }
          })
        )
      }
    )
  }
}
