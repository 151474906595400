/* Layer Palette*/
export enum CreditTransactionType {
  pmiAggXl = 'pmi_agg_xl',
  pmiQs = 'pmi_qs',
  pmiIln = 'pmi_iln',
  srtAggXl = 'srt_agg_xl',
}

export type CreditLayerPaletteType =
  | 'agg_xl' // Aggregate Excess of Loss
  | 'qs' // Quote Share
  | 'iln' // Insurance Linked Note

export type CreditLayerPaletteGroupType = 'pmi' | 'srt' // ?? what are the full names ??
export type CreditLayerPaletteGroup = {
  name: string
  type: CreditLayerPaletteGroupType
  items: readonly CreditLayerPaletteItem[]
}
export type CreditLayerPaletteItem = {
  id: CreditTransactionType
  name: string
  type: CreditLayerPaletteType
  group: CreditLayerPaletteGroupType
}

export const CREDIT_XOL_NAME = 'Aggregate Excess of Loss'
export const CREDIT_ILN_NAME = 'Insurance Linked Note'
export const CREDIT_QS_NAME = 'Quota Share'

export const CREDIT_TRANSACTION_TYPE_TO_NAME_MAP: Record<
  CreditTransactionType,
  string
> = {
  pmi_agg_xl: CREDIT_XOL_NAME,
  pmi_iln: CREDIT_ILN_NAME,
  pmi_qs: CREDIT_QS_NAME,
  srt_agg_xl: CREDIT_XOL_NAME,
}

export const CREDIT_LAYER_PALETTE_PMI: CreditLayerPaletteItem[] = [
  {
    id: CreditTransactionType.pmiAggXl,
    name: CREDIT_XOL_NAME,
    group: 'pmi',
    type: 'agg_xl',
  },
  {
    id: CreditTransactionType.pmiQs,
    name: CREDIT_QS_NAME,
    group: 'pmi',
    type: 'qs',
  },
  {
    id: CreditTransactionType.pmiIln,
    name: CREDIT_ILN_NAME,
    group: 'pmi',
    type: 'iln',
  },
]
export const CREDIT_LAYER_PALETTE_SRT: CreditLayerPaletteItem[] = [
  {
    id: CreditTransactionType.srtAggXl,
    name: CREDIT_XOL_NAME,
    group: 'srt',
    type: 'agg_xl',
  },
]
export const CREDIT_LAYER_PALETTE: CreditLayerPaletteItem[] = [
  ...CREDIT_LAYER_PALETTE_PMI,
  ...CREDIT_LAYER_PALETTE_SRT,
]
export const CREDIT_LAYER_PALETTE_GROUPS: CreditLayerPaletteGroup[] = [
  {
    name: 'PMI',
    type: 'pmi',
    items: CREDIT_LAYER_PALETTE_PMI,
  },
  // {
  //   name: 'SRT',
  //   type: 'srt',
  //   items: CREDIT_LAYER_PALETTE_SRT,
  // },
]
export const CREDIT_LAYER_TYPE_TO_NAME_MAPPING: Map<
  CreditTransactionType,
  string
> = CREDIT_LAYER_PALETTE_GROUPS.reduce((mapping, group) => {
  group.items.forEach(item => {
    mapping.set(item.id, item.name)
  })
  return mapping
}, new Map<CreditTransactionType, string>())
