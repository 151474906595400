<div class="main">
  <h1 mat-dialog-title>⚠️Sync Warnings⚠️</h1>
  <div mat-dialog-content>
    <div class='content'>
      <div *ngIf="rows.length > 0">
        <h4>The following Underwriter Refs are duplicated on the following layers and lines.</h4>
        <app-sort-table
          spacing="tight"
          [columnDefs]="columnDefs"
          [rows]="rows"
          [disableSort]="true"
          ></app-sort-table>
      </div>
      <div *ngIf="data.overflowSigned.length > 0" class="overflow-signed">
        <h4>The following layers cumulative signed % > 100%</h4>
        <ul>
          <li *ngFor="let layer of data.overflowSigned">{{layer}}</li>
        </ul>
      </div>
      <div *ngIf="data.whitespaceSyncWarnings && data.whitespaceSyncWarnings.length > 0" class="overflow-signed">
        <h4>The following non Whitespace layers have been removed/overwritten with Whitespace sync</h4>
        <p class="caveat">Note all Whitespace layer fields have been overwritten with newly fetched Whitespace data.</p>
        <app-sort-table
          spacing="tight"
          [columnDefs]="overWriteColumnDefs"
          [rows]="data.whitespaceSyncWarnings"
          [disableSort]="true"
          ></app-sort-table>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button appButton border mat-dialog-close>Close</button>
  </div>
</div>
