import { createSelector } from '@ngrx/store'
import { head } from 'ramda'
import {
  selectCurrentClient,
  selectCurrentStudy,
  selectCurrentYear,
} from '../../core/store/broker/broker.selectors'
import { TierNames } from '../../tier/tier.model'
import {
  selectCurrentProgram,
  selectCurrentProgramOptimizations,
  selectCurrentProgramScenarios,
} from './analysis.selectors'

export const selectPortfolioHeaderTierNames = createSelector(
  selectCurrentClient,
  selectCurrentYear,
  selectCurrentStudy,
  selectCurrentProgram,
  selectCurrentProgramScenarios,
  selectCurrentProgramOptimizations,
  (
    client,
    year,
    program,
    currentStructure,
    scenarios,
    optimizations
  ): TierNames => {
    const scenario = scenarios.find(
      s => s.id === currentStructure?.id && s.isScenario
    )
    const optimization = optimizations.find(
      s => s.id === currentStructure?.id && s.isOptimization
    )
    const structure = scenario ? head(scenarios) : head(optimizations)
    return {
      client: client?.name,
      year: year?.year,
      program: program?.name,
      structure: structure?.label,
      scenarioOrOptimization: scenario?.label || optimization?.label,
    }
  }
)
