import { createEntityAdapter, EntityState, Update } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { assoc, clone, dissoc, keys } from 'ramda'
import {
  LogicalPortfolioLayer,
  Metadata,
  Portfolio,
} from '../../../../api/analyzere/analyzere.model'
import { StructureLayerDataResponse } from '../../../../api/model/backend.model'
import { Program } from '../../../../core/model/program.model'
import * as fromProgramActions from '../../../../core/store/program/program.actions'
import { md5 } from '@shared/util/hash'
import { convertFromLogicalPortfolioLayers } from '../../../model/layers.converter'
import { Layer } from '../../../model/layers.model'
import * as fromLayersActions from '../../ceded-layers/layers.actions'
import * as fromLayers from '../../ceded-layers/layers.reducer'
import { fetchLayersViewMetricsSuccessRP } from '../../metrics/layers-metrics.actions'
import * as fromGrouperActions from '../grouper.actions'
import * as fromInuranceActions from '../inurance/grouper-inurance.actions'
import * as fromProgramGroupActions from '../program-group/program-group.actions'
import * as fromSharedLimitActions from '../shared-limit/grouper-shared-limit.actions'
import * as fromActions from './program.actions'
import { setFotAndQuoteCountSuccess } from 'src/app/quote/store/quote.actions'

interface ExtendedState {
  minimized: Record<string, boolean>
}

export interface ProgramEntity {
  program: Program
  cededLayers: fromLayers.LayerState[]
  netLayers: string[]
  loading: boolean
  error: string | null
}

export type ProgramEntityState = EntityState<ProgramEntity> & ExtendedState

const buildID = (program: Program): string =>
  program.id + (program.parentGroupID ? '_' + program.parentGroupID : '')

export const adapter = createEntityAdapter<ProgramEntity>({
  selectId: entity => buildID(entity.program),
})

export const initialState: ProgramEntityState =
  adapter.getInitialState<ExtendedState>({
    minimized: {},
  })

export const createProgramEntity = (program: Program): ProgramEntity => ({
  program: {
    ...program,
    checkGroupOcc: true,
  },
  cededLayers: [],
  netLayers: [],
  error: null,
  loading: false,
})

const initLayerState = (layers: Layer[]) =>
  layers.map(layer => ({
    ...fromLayers.initialLayerState,
    layer,
  }))

const programReducer = createReducer(
  initialState,

  on(fromProgramGroupActions.addProgramGroupSuccess, (state, { programs }) =>
    adapter.addMany(programs.map(createProgramEntity), state)
  ),

  on(
    fromProgramGroupActions.addNewProgramGroup,
    (state, { parentGroupID, label, programIDs, isUntitled }) => {
      if (isUntitled) {
        // If new root group
        const updateProgramParentIdsList = selectAllByParentGroupID(
          parentGroupID,
          state
        )
        return updateProgramParentIdsList.reduce((acc, programEntity) => {
          return adapter.updateOne(
            {
              id: buildID(programEntity.program),
              changes: {
                program: { ...programEntity.program, parentGroupID: label },
              },
            },
            acc
          )
        }, state)
      } else {
        // New group inside other group
        let updateProgram = selectByProgramIDandParentID(
          parentGroupID,
          programIDs[0],
          state
        )
        // if parentGroup is untitled
        if (!updateProgram) {
          updateProgram = selectByProgramID(programIDs[0], state)
        }
        return adapter.updateOne(
          {
            // tslint:disable-next-line: no-non-null-assertion
            id: buildID(updateProgram!.program),
            changes: {
              // tslint:disable-next-line: no-non-null-assertion
              program: { ...updateProgram!.program, parentGroupID: label },
            },
          },
          state
        )
      }
    }
  ),

  on(fromGrouperActions.saveGrouperSuccess, (state, { groups }) => {
    if (!groups.idChanges || groups.idChanges.length === 0) {
      return state
    }
    const updates: Update<ProgramEntity>[] = []
    groups.idChanges.forEach(id => {
      const updateProgramParentIdsList = selectAllByOnlyParentGroupID(
        id.prev,
        state
      )
      updateProgramParentIdsList.map(programEntity => {
        updates.push({
          id: buildID(programEntity.program),
          changes: {
            program: {
              ...programEntity.program,
              parentGroupID: id.next,
            },
          },
        })
      })
    })
    return adapter.updateMany(updates, state)
  }),

  on(
    fromActions.moveGrouperProgram,
    (state, { id, fromGroupID, toGroupID }) => {
      let updateProgram: ProgramEntity | undefined
      if (isUntitledParent(fromGroupID)) {
        updateProgram = selectByProgramIDandParentID(
          isUntitledParent(fromGroupID),
          id,
          state
        )
      } else {
        updateProgram = selectByProgramID(id, state)
      }
      return adapter.updateOne(
        {
          // tslint:disable-next-line: no-non-null-assertion
          id: buildID(updateProgram!.program),
          changes: {
            program: {
              // tslint:disable-next-line: no-non-null-assertion
              ...updateProgram!.program,
              parentGroupID: isUntitledParent(toGroupID),
            },
          },
        },
        state
      )
    }
  ),

  on(fromActions.fetchGrouperProgramLayers, (state, { program }) =>
    adapter.updateOne(
      { id: buildID(program), changes: { error: null, loading: true } },
      state
    )
  ),

  on(fetchLayersViewMetricsSuccessRP, (state, { layerID, metrics }) => {
    const programEntitiy = selectProgramByLayerID(layerID, state)
    if (programEntitiy) {
      const cededLayersArray = [...programEntitiy.cededLayers]
      const updatedMetrics = cededLayersArray.map(c => {
        if (c.layer.id === layerID) {
          c = {
            ...c,
            layer: {
              ...c.layer,
              viewMetrics: {
                error: null,
                loading: false,
                rss: null,
                metrics,
              },
            },
          }
        }
        return c
      })

      return adapter.updateOne(
        {
          id: buildID(programEntitiy.program),
          changes: {
            cededLayers: updatedMetrics,
          },
        },
        state
      )
    } else {
      return state
    }
  }),

  on(
    fromActions.fetchGrouperProgramLayersFailure,
    (state, { program, error }) =>
      adapter.updateOne(
        {
          id: buildID(program),
          changes: { error: error.message, loading: false },
        },
        state
      )
  ),

  on(
    fromInuranceActions.saveGrouperInuranceSuccess,
    fromInuranceActions.deleteGrouperInuranceSuccess,
    fromInuranceActions.saveGrouperInuranceFromDesignSuccess,
    (state, { changes }) => {
      const updates: Update<ProgramEntity>[] = getInuranceChanges(
        state,
        changes
      )
      return adapter.updateMany(updates, state)
    }
  ),

  on(
    fromActions.fetchGrouperProgramLayersSuccess,
    (state, { program, cededLayers, netLayers }) =>
      adapter.updateOne(
        {
          id: buildID(program),
          changes: {
            error: null,
            loading: false,
            cededLayers: initLayerState(cededLayers),
            netLayers,
          },
        },
        state
      )
  ),

  on(fromActions.removeProgramFromGroup, (state, { program }) =>
    adapter.removeOne(buildID(program), state)
  ),

  on(fromActions.setGrouperMinimizedProgram, (state, { program, value }) => {
    const minimize = value != null ? value : !state.minimized[buildID(program)]
    const minimized = minimize
      ? assoc(buildID(program), true, state.minimized)
      // @ts-ignore
      : dissoc<Record<string, boolean>>(buildID(program), state.minimized)
    return { ...state, minimized }
  }),

  on(fromActions.setGrouperProgramShowAgg, (state, { check, program }) =>
    adapter.updateOne(
      {
        id: buildID(program),
        changes: { program: { ...program, checkGroup: check } },
      },
      state
    )
  ),

  on(fromActions.setGrouperProgramShowOcc, (state, { check, program }) =>
    adapter.updateOne(
      {
        id: buildID(program),
        changes: { program: { ...program, checkGroupOcc: check } },
      },
      state
    )
  ),

  on(fromActions.setGrouperSelectedProgram, (state, { selected, program }) =>
    adapter.updateOne(
      {
        id: buildID(program),
        changes: { program: { ...program, groupSelected: selected } },
      },
      state
    )
  ),

  // Shared Limits

  on(
    fromSharedLimitActions.deleteSharedLimitSuccess,
    (state, { cededPortfolios, netPortfolios }) => {
      // tslint:disable: no-non-null-assertion
      return adapter.updateMany(
        getSharedLimitUpdates(state, cededPortfolios, netPortfolios),
        state
      )
    }
  ),

  on(
    fromSharedLimitActions.addSharedLimitSuccess,
    (state, { cededPortfolios, netPortfolios }) => {
      return adapter.updateMany(
        getSharedLimitUpdates(state, cededPortfolios, netPortfolios),
        state
      )
    }
  ),

  on(
    fromSharedLimitActions.updateSharedLimitSucccess,
    (state, { cededPortfolios, netPortfolios }) => {
      return adapter.updateMany(
        getSharedLimitUpdates(state, cededPortfolios, netPortfolios),
        state
      )
    }
  ),
  on(fromLayersActions.saveSuccess, (state, { structureID, layers }) => {
    const entities = Object.keys(state.entities).map(k => ({
      ...state.entities[k]!,
      id: k,
    }))
    const filteredEntities = entities.filter(e => e.program.id === structureID)
    const updates = filteredEntities.map(e => ({
      id: e.id,
      changes: {
        cededLayers: layers.map(layer => ({
          new: false,
          deleted: false,
          dirty: false,
          layer,
          hash: md5(layer),
        })),
      },
    }))
    return adapter.updateMany(updates, state)
  }),

  on(fromActions.updateProgramEntityRecon, (state, { layerId, metaData }) => {
    return adapter.updateMany(
      getReconcileUpdates(state, layerId, metaData),
      state
    )
  }),

  on(
    fromProgramActions.setProgramNameAndDescriptionSuccess,
    (state, { id, name, description }) => {
      const entity = state.entities[id]
      if (!entity) {
        return state
      }
      const program = { ...entity.program, label: name, description }
      return adapter.updateOne({ id, changes: { program } }, state)
    }
  ),

  on(setFotAndQuoteCountSuccess, (state, { id, fotCount, quoteCount }) => {
    const entity = state.entities[id]
    if (!entity) {
      return state
    }
    const program = { ...entity.program, fotCount, quoteCount }
    return adapter.updateOne({ id, changes: { program } }, state)
  }),

  on(fromProgramActions.updateProgramIndexSuccess, (state, { id, index }) => {
    const entity = state.entities[id]
    if (!entity) {
      return state
    }
    const program = { ...entity.program, position_index: index }
    return adapter.updateOne({ id, changes: { program } }, state)
  }),

  on(
    fromProgramActions.updateFolderIDSuccess,
    (state, { structureId, folderID }) => {
      const entity = state.entities[structureId]
      if (!entity) {
        return state
      }
      const program = { ...entity.program, folderID }
      return adapter.updateOne({ id: structureId, changes: { program } }, state)
    }
  ),

  on(fromProgramActions.updateLocalProgramIndexes, (state, { indexes }) => {
    const entities = state.entities
    const updates = indexes.map(({ structureId, positionIndex }) => ({
      id: structureId,
      changes: {
        program: { position_index: positionIndex },
      },
    }))

    const updatedEntities = JSON.parse(JSON.stringify(entities))

    for (const update of updates) {
      updatedEntities[update.id] = update.changes
    }

    return {
      ...state,
      entities: updatedEntities,
    }
  }),
  on(
    fromProgramActions.setColorChange,
    (state, { layer, structure, color }) => {
      const entitiesWithKeys = keys(state.entities).map(k => ({
        ...state.entities[k]!,
        key: k,
      }))
      const programEntityWithKey = entitiesWithKeys.find(
        e => e?.program.id === structure.id
      )
      if (programEntityWithKey) {
        const program = clone(programEntityWithKey.program)
        const layerData = program.layerData
        if (layerData) {
          let id = ''
          if (
            layer.meta_data &&
            layer.meta_data.sage_layer_subtype !== 'feeder' &&
            (layer.meta_data.sage_layer_type === 'cat_ag' ||
              layer.meta_data.sage_layer_type === 'noncat_ag' ||
              layer.meta_data.sage_layer_type === 'ahl_ag')
          ) {
            id = 'LAgg' + layer.id
          } else {
            id = 'LOcc' + layer.id
          }
          const index = layerData.findIndex(
            (lData: StructureLayerDataResponse) => {
              return lData.layer_id === id
            }
          )
          layerData[index].color = color
          return adapter.updateOne(
            { id: programEntityWithKey.key as string, changes: { program } },
            state
          )
        } else {
          return { ...state }
        }
      } else {
        return { ...state }
      }
    }
  )
)

export function reducer(state: ProgramEntityState | undefined, action: Action) {
  return programReducer(state, action)
}

const getSharedLimitUpdates = (
  state: ProgramEntityState,
  cededPortfolios: Portfolio[],
  netPortfolios: Portfolio[]
): Array<Update<ProgramEntity>> => {
  const cededPortfolioIDs = cededPortfolios.map(c => c.id)
  const entities = Object.keys(state.entities)
    .map(key => state.entities[key]!)
    .filter(e => cededPortfolioIDs.includes(e.program.cededPortfolioID))
  const updates: Update<ProgramEntity>[] = []
  entities.forEach(e => {
    const cededPortfolio = cededPortfolios.find(
      c => c.id === e.program.cededPortfolioID
    )
    const netPortfolio = netPortfolios.find(
      n => n.id === e.program.netPortfolioID
    )
    if (cededPortfolio && netPortfolio) {
      const cededLayers = convertFromLogicalPortfolioLayers(
        cededPortfolio.layers as LogicalPortfolioLayer[]
      )
      const netLayers = netPortfolio.layers as LogicalPortfolioLayer[]
      updates.push({
        id: buildID(e.program),
        changes: {
          cededLayers: cededLayers.map(layer => ({
            new: false,
            deleted: false,
            dirty: false,
            layer,
            hash: md5(layer),
          })),
          netLayers: netLayers.map(n => n.id),
        },
      })
    }
  })
  return updates
}

const getReconcileUpdates = (
  state: ProgramEntityState,
  layerId: string,
  metaData: Partial<Metadata>
): Array<Update<ProgramEntity>> => {
  const entities = Object.keys(state.entities)
    .map(key => state.entities[key]!)
    .filter(e => e.cededLayers.find(l => l.layer.id === layerId))
  const updates: Update<ProgramEntity>[] = []
  entities.forEach(e => {
    const currentCededLayers = e.cededLayers
    const updatedCededLayers = currentCededLayers.map(cededLayer => {
      if (cededLayer.layer.id === layerId) {
        return {
          ...cededLayer,
          layer: {
            ...cededLayer.layer,
            meta_data: metaData,
          },
        }
      } else {
        return cededLayer
      }
    })
    updates.push({
      id: buildID(e.program),
      changes: { cededLayers: updatedCededLayers },
    })
  })
  return updates
}

const getInuranceChanges = (
  state: ProgramEntityState,
  changes: Record<string, Partial<Layer>[]>
) => {
  const updates: Update<ProgramEntity>[] = []
  Object.keys(changes).forEach(structureID => {
    let programEntity
    for (const key in state.entities) {
      if (state.entities[key]!.program.id === structureID) {
        programEntity = state.entities[key]
      }
    }
    const layersRecord = changes[structureID].reduce((acc, current) => {
      acc[current.id as string] = current
      return acc
    }, {} as Record<string, Partial<Layer>>)
    if (programEntity) {
      const currentCededLayers = programEntity.cededLayers
      const updatedCededLayers = currentCededLayers.map(cededLayer => {
        const partialCededLayer = layersRecord[cededLayer.layer.id]
        if (partialCededLayer) {
          return {
            ...cededLayer,
            layer: { ...cededLayer.layer, ...partialCededLayer },
          }
        } else {
          return cededLayer
        }
      })
      updates.push({
        id: buildID(programEntity.program),
        changes: { cededLayers: updatedCededLayers },
      })
    }
  })
  return updates
}

const { selectAll } = adapter.getSelectors()

const selectAllByParentGroupID = (
  groupID: string | undefined,
  state: ProgramEntityState
): ProgramEntity[] => {
  return selectAll(state).filter(
    p => !p.program.parentGroupID || p.program.parentGroupID === groupID
  )
}

const selectAllByOnlyParentGroupID = (
  groupID: string | undefined,
  state: ProgramEntityState
): ProgramEntity[] => {
  return selectAll(state).filter(p => p.program.parentGroupID === groupID)
}

const selectByProgramIDandParentID = (
  groupID: string | undefined,
  id: string,
  state: ProgramEntityState
): ProgramEntity | undefined =>
  selectAll(state)
    .filter(p => p.program.parentGroupID === groupID)
    .find(p => p.program.id === id)

const selectByProgramID = (
  id: string,
  state: ProgramEntityState
): ProgramEntity | undefined => selectAll(state).find(p => p.program.id === id)

const isUntitledParent = (parentID: string): string => {
  return parentID && !parentID.startsWith('untitled') ? parentID : ''
}

const selectProgramByLayerID = (
  id: string,
  state: ProgramEntityState
): ProgramEntity | undefined =>
  selectAll(state).find(p => p.cededLayers.find(c => c.layer.id === id))
