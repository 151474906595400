<div>
  <h3>Error Calculating Loss Set Contributions</h3>
  <div class="contribution-message">
    <p>Loss set contributions have beeen omitted.</p>
    <p>The contributions do not add up to the total expected loss. This is typically caused by an error in uploading the loss set where multiple rows of loss per eventid, trialid and day are included in one loss set instead of the total.</p> 
    <p>This should be fixed before results are relied upon.</p>
  </div>
  <button appButton link mat-dialog-close (click)="onClose()">
    <span>Close</span>
  </button>
</div>
