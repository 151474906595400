import { Dictionary } from '@ngrx/entity'
import { createSelector } from '@ngrx/store'
import { AppState } from '.'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../analysis/store/grouper/program-group.model'
import { rejectNil } from '@shared/util/operators'
import { Program } from '../model/program.model'
import { selectCurrentClient } from './broker/broker.selectors'
import * as fromProgramGroupMember from './program-group-member.reducer'
import { selectProgramGroupsByID } from './program-group/program-group.selectors'
import { selectProgramsByID } from './program/program.selectors'

export const selectProgramGroupMemberState = (
  state: AppState
): fromProgramGroupMember.State => state.programGroupMember

export const {
  selectAll: selectProgramGroupMembers,
  selectEntities: selectProgramGroupMembersByID,
} = fromProgramGroupMember.adapter.getSelectors(selectProgramGroupMemberState)

export const selectCurrentClientProgramGroupsMembers = createSelector(
  selectCurrentClient,
  selectProgramGroupMembersByID,
  (client, byID): ProgramGroupMember[] =>
    client ? rejectNil(client.programGroupMemberIDs.map(id => byID[id])) : []
)

type ProgramOrGroupID =
  | string
  | {
      programID?: string
      programGroupID?: string
      parentGroupID?: string
    }

export const findProgramGroupMemberWithID = (
  props: ProgramOrGroupID | undefined,
  members: ProgramGroupMember[] | undefined
): ProgramGroupMember | undefined => {
  if (!members || !props) {
    return undefined
  }
  if (typeof props === 'string') {
    return members.find(m => m.id === props)
  }
  return members.find(
    m =>
      (props.programID && m.programID === props.programID) ||
      (props.programGroupID && m.programGroupID === props.programGroupID) ||
      (props.parentGroupID && m.parentGroupID === props.parentGroupID)
  )
}

export const findAllProgramGroupMembersWithID = (
  props: ProgramOrGroupID | undefined,
  members: ProgramGroupMember[] | undefined
): ProgramGroupMember[] | undefined => {
  if (!members || !props) {
    return undefined
  }
  if (typeof props === 'string') {
    return members.filter(m => m.id === props)
  }
  return members.filter(
    m =>
      (props.programID && m.programID === props.programID) ||
      (props.programGroupID && m.programGroupID === props.programGroupID) ||
      (props.parentGroupID && m.parentGroupID === props.parentGroupID)
  )
}

export const selectProgramGroupMemberWithID = createSelector(
  selectProgramGroupMembers,
  (members: ProgramGroupMember[], props?: ProgramOrGroupID) =>
    findProgramGroupMemberWithID(props, members)
)

const findParent = (
  member: ProgramGroupMember | undefined,
  members: ProgramGroupMember[]
): ProgramGroupMember | undefined =>
  member && members.find(m => m.programGroupID === member.parentGroupID)

export const findAllAncestorGroupsWithID = (
  props: ProgramOrGroupID | undefined,
  members: ProgramGroupMember[] | undefined,
  groupsByID: Dictionary<ProgramGroup> | undefined
): ProgramGroup[] => {
  let currentMember = findProgramGroupMemberWithID(props, members)
  const ancestors: ProgramGroup[] = []
  while (currentMember) {
    const parentGroup = (groupsByID || {})[currentMember.parentGroupID]
    if (!parentGroup) {
      break
    }
    ancestors.push(parentGroup)
    currentMember = findParent(currentMember, members || [])
  }
  return ancestors
}

export const findAllPossibleAncestorGroupsWithID = (
  props: ProgramOrGroupID | undefined,
  members: ProgramGroupMember[] | undefined,
  groupsByID: Dictionary<ProgramGroup> | undefined
): ProgramGroup[] => {
  const currentMembers = findAllProgramGroupMembersWithID(props, members)
  const ancestors: ProgramGroup[] = []
  if (currentMembers) {
    for (const cm of currentMembers) {
      let currentMember = cm
      while (currentMember) {
        const parentGroup = (groupsByID || {})[currentMember.parentGroupID]
        if (!parentGroup) {
          break
        }
        ancestors.push(parentGroup)
        const possibleCurrentMember = findParent(currentMember, members || [])
        if (possibleCurrentMember) {
          currentMember = possibleCurrentMember
        } else {
          break
        }
      }
    }
  }
  return ancestors
}

export const selectAncestorGroupsWithID = (props?: ProgramOrGroupID) =>
  createSelector(
    selectProgramGroupsByID,
    selectProgramGroupMembers,
    selectProgramGroupMemberWithID,
    (groupsByID, members, member) =>
      findAllAncestorGroupsWithID(
        props || (member && member.id),
        members,
        groupsByID
      )
  )

export const selectAllAncestorGroupsWithID = (props?: ProgramOrGroupID) =>
  createSelector(
    selectProgramGroupsByID,
    selectProgramGroupMembers,
    selectProgramGroupMemberWithID,
    (groupsByID, members, member) =>
      findAllPossibleAncestorGroupsWithID(
        props || (member && member.id),
        members,
        groupsByID
      )
  )

export interface ProgramGroupSetState {
  groupsByID: Dictionary<ProgramGroup>
  structuresByID: Dictionary<Program>
  allMembers: ProgramGroupMember[]
}

export const selectProgramGroupSetState = createSelector(
  selectProgramGroupsByID,
  selectProgramsByID,
  selectProgramGroupMembers,
  (groupsByID, structuresByID, allMembers): ProgramGroupSetState => ({
    groupsByID,
    structuresByID,
    allMembers,
  })
)
