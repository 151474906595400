import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from 'src/app/core/store'
import {
    PricingCurveGraphSettings,
  PricingCurveStatus,
} from '../../../model/pricing-curve.model'
import * as fromSelectors from '../../../store/pricing-curve.selectors'
import { exportPricingCurveState } from '../../../store/pricing-curve.actions'
import { PricingCurveGraphComponent } from '../../charting/pricing-curve-graph/graph-component/pricing-curve-graph.component'
import { PricingCurveExportOptions } from '../../../export/pricing-curve-export.model'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-export-preview-dialog',
  styleUrls: ['./pricing-curve-export-preview-dialog.component.scss'],
  templateUrl: './pricing-curve-export-preview-dialog.component.html',
})
export class PricingCurveExportPreviewDialogComponent implements OnInit {
  pricingCurves$: Observable<PricingCurve[]>
  status$: Observable<PricingCurveStatus>
  graphSettings$: Observable<PricingCurveGraphSettings>
  activeIds: number[] | undefined
  exportName = ''

  @ViewChild('graph', { static: false }) graphEl: PricingCurveGraphComponent

  constructor(
    private store: Store<AppState>,
    public matDialogRef: MatDialogRef<PricingCurveExportPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit(): void {
    this.pricingCurves$ = this.store.select(fromSelectors.selectPricingCurves)
    this.status$ = this.store.select(fromSelectors.selectPricingCurveStatus)
    this.graphSettings$ = this.store.select(fromSelectors.selectPricingCurveGraphSettings)
  }

  get disabledTooltip(): string | undefined {
    if (!this.activeIds || !this.activeIds.length) {
      return 'One row must be selected to export'
    }
    if (!this.exportName) {
      return 'The export must have a name'
    }
  }

  updateExportGraph(activeIds: number[]) {
    this.activeIds = activeIds
  }

  destroyDialog() {
    this.matDialogRef.close()
  }

  initExport() {
    if (!this.exportName) {
      return
    }
    this.graphEl.getCanvasFromGraphSVG().then(canvas => {
      if (this.activeIds) {
        const options: PricingCurveExportOptions = {
          type: 'xlsx',
        }
        this.store.dispatch(
          exportPricingCurveState({
            exportLabel: this.exportName,
            includedCurves: this.activeIds,
            graphBase64: canvas.toDataURL(),
            options,
          })
        )
      }
    })
  }
}
