import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../../error/model/error'
import {
  DeleteInuranceFromDesign,
  InurancePayload,
  InuranceView,
  Terminal,
} from '../../../model/inurance.model'
import { Layer } from '../../../model/layers.model'
import { Program } from '../../../../core/model/program.model'

export const openNewGrouperInurance = createAction(
  '[Grouper Inurance] Open New'
)

export const openDeleteGrouperInurance = createAction(
  '[Grouper Inurance] Open Delete',
  props<{ source: InuranceView; target: InuranceView }>()
)

export const cancelGrouperInurance = createAction('[Grouper Inurance] Cancel')

export const setGrouperInuranceCard = createAction(
  '[Grouper Inurance] Set Card',
  props<{ view: InuranceView; layers?: Layer[] }>()
)

export const setGrouperInuranceCursor = createAction(
  '[Grouper Inurance] Set Cursor',
  props<{ cursor: Terminal }>()
)

export const saveGrouperInurance = createAction('[Grouper Inurance] Save')

export const saveGrouperInuranceFailure = createAction(
  '[Grouper Inurance] Save Failure',
  props<{ error: ApplicationError }>()
)

export const saveGrouperInuranceSuccess = createAction(
  '[Grouper Inurance] Save Success',
  props<{ changes: Record<string, Partial<Layer>[]> }>()
)

export const deleteGrouperInurance = createAction(
  '[Grouper Inurance] Delete',
  props<InurancePayload>()
)

export const deleteGrouperInuranceFailure = createAction(
  '[Grouper Inurance] Delete Failure',
  props<{ error: ApplicationError }>()
)

export const deleteGrouperInuranceSuccess = createAction(
  '[Grouper Inurance] Delete Success',
  props<{ changes: Record<string, Partial<Layer>[]> }>()
)

export const saveGrouperInuranceFromDesignSuccess = createAction(
  '[Grouper Inurance] Save From Design Success',
  props<{ changes: Record<string, Partial<Layer>[]> }>()
)

export const refreshOnChangedInurance = createAction(
  '[Grouper Inurance] Refresh Structure on Changed Inurance',
  props<{ structure: Program; layer: Layer }>()
)

export const deleteInuranceFromDesign = createAction(
  '[Grouper Inurance] Delete Inurance from Design',
  props<DeleteInuranceFromDesign>()
)

export const deleteInuranceFromDesignSuccess = createAction(
  '[Grouper Inurance] Delete Inurance from Design Success'
)

export const deleteInuranceFromDesignFailure = createAction(
  '[Grouper Inurance] Delete Inurance from Design Failure',
  props<{ error: ApplicationError }>()
)

export const swapInuranceFromDesign = createAction(
  '[Analysis] Swap for Inurance',
  props<{ toDelete: DeleteInuranceFromDesign }>()
)

export const deleteSwapInuranceFromDesign = createAction(
  '[Analysis] Delete Swap for Inurance'
)

export const updateToDeleteFromDesign = createAction(
  '[Analysis] Update To Delete From Design',
  props<{ newTargetLayer: Layer }>()
)
