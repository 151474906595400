import { CreditTranche } from './credit-structure.model'

export const CREDIT_ANIMATED_SCENARIOS = 'CREDIT ANIMATED SCENARIOS'
export interface EventLayer extends CreditTranche {
  loss: number
  remaininAggregateLimit: number
  originalLimit: number
  stepDownLimit: number
  cededLoss: number
  cededLimit: number
  cededPremium: number
  pmiers: number
  loss_ratio: number
}

export const getLimit = (tranche: CreditTranche) => {
  return Number(tranche.metadata.limit)
}

export const setLimit = (tranche: CreditTranche, limit: number) => {
  tranche.metadata.limit = limit.toString()
}

export interface AnimatedScenariosResultsData {
  data: AnimatedScenariosResults[]
  actualsData: AnimatedScenariosResults[]
}

export interface AnimatedScenariosResults {
  id?: number
  scenarioId: string
  trancheData: TrancheData[]
  monthlyData: MonthlyData[]
  isActuals?: boolean
}

export interface TrancheData {
  trancheId: string
  bottom_attach: number
  top_detach: number
  total_exposure: number
}

export interface MonthlyData {
  forecast_month: Date
  data: MonthlytrancheData[]
}

export interface MonthlytrancheData {
  trancheId: string
  loss: number
  stepDown: number
  cededLoss: number
  cededLimit: number
  cededPremium: number
  pmiers: number
  loss_ratio: number
}

export interface AnimatedTableData {
  id: string
  name: string
  description?: string
  cededLimit?: number
  cededLoss?: number
  cededPremium?: number
  pmiers?: number
  loss_ratio?: number
  style?: string
  type?: string
}
