import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import {
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { Program } from 'src/app/core/model/program.model'
import { AppState } from '../../../../core/store'
import { selectCurrentClientID } from '../../../../core/store/broker/broker.selectors'
import { selectProgramGroupMemberState } from '../../../../core/store/program-group-member.selectors'
import { selectProgramGroupState } from '../../../../core/store/program-group/program-group.selectors'
import { selectProgramState } from '../../../../core/store/program/program.selectors'
import { errorPayload } from '../../../../error/model/error'
import { extractPortfolioSetID } from '../../../model/portfolio-set-id.util'
import {
  loadProgramGroupEntities,
  loadProgramGroupEntitiesResponse,
} from '../../grouper/load-program-group-entities'
import * as ProgramGroupActions from '../compare.actions'
import * as ProgramCompareGroupActions from './program-group-compare.actions'
import { rejectError } from 'src/app/api/util'

// tslint:disable: no-non-null-assertion
@Injectable()
export class CompareProgramGroupEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  fetchProgramGroupOnAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramCompareGroupActions.addCompareProgramGroup),
      map(({ type, ...props }) => props),
      concatMap(props =>
        of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentClientID)),
            this.store.pipe(select(selectProgramState)),
            this.store.pipe(select(selectProgramGroupState)),
            this.store.pipe(select(selectProgramGroupMemberState))
          )
        )
      ),
      map(([props, ...state]) => {
        try {
          const res = loadProgramGroupEntities(props.id, ...state)
          // For compare, we only need the root group entity
          const group = res.programGroups[0]
          if (!group) {
            throw Error(`Could not find program group w/ ID '${props.id}`)
          }

          // Ensure that the group has all analysis/portfolio IDs for metrics
          const portfolioSetID = extractPortfolioSetID(group)
          const { clientID, studyID } = group
          if (!portfolioSetID || !clientID || !studyID) {
            throw Error(
              'Cannot fetch portfolio view for program group w/o all IDs'
            )
          }

          // Return the group annotated w/ all necessary IDs
          return { ...group, ...portfolioSetID, clientID, studyID }
        } catch (e) {
          const msg = e && e.message
          const error = errorPayload('Failed to add Program Group', msg)
          this.store.dispatch(
            ProgramCompareGroupActions.addCompareProgramGroupFailure({ error })
          )
          return null
        }
      }),
      filter(payload => payload != null),
      map(payload => payload!),
      mergeMap(payload => {
        // Adding the group in the structure of a program but w/ its
        // appropriate group portfolio IDs
        // TODO: Refactor compare entities state to be agnostic towards
        // programs vs program groups
        const program: Program = {
          ...payload,
          analysisID: payload.analysisProfileID,
          programType: '',
        }
        return [
          ProgramGroupActions.addProgramToCompare({ program }),
          ProgramGroupActions.setCompareProgramGroup({ id: program.id }),
          ProgramGroupActions.setCompareProgramGroupMembers({ id: program.id }),
        ]
      })
    )
  )

  fetchProgramGroupOnAddT$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramGroupActions.setCompareProgramGroupMembers),
      map(({ type, ...props }) => props),
      mergeMap(props =>
        loadProgramGroupEntitiesResponse(this.store, props.id, '')
      ),
      rejectError(error =>
        this.store.dispatch(
          ProgramGroupActions.setCompareProgramGroupMembersFailure({ error })
        )
      ),
      mergeMap(({ ...payload }) => {
        const imageSrcGroup = payload.programs.map(p => {
          return p.imageSrc!
        })
        const id = payload.id
        return [
          ProgramGroupActions.setCompareProgramGroupMembersSuccess({
            imageSrcGroup,
            id,
          }),
        ]
      })
    )
  )
}
