import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RoleGuard } from '../guards/role-guard.service'
import { ReinsurerRouteContainerComponent } from './reinsurer-route.container'
import { CanDeactivateGuard } from '../guards/can-deactivate.guard'

const routes: Routes = [
  {
    path: '',
    component: ReinsurerRouteContainerComponent,
    data: { contextPath: ['reinsurers'] },
    canActivate: [RoleGuard],
    canDeactivate: [CanDeactivateGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReinsurerRoutingModule {}
