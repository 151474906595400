import { isLayerMetricApplicable } from 'src/app/analysis/model/layer-metric-applicability'
import LayerMetricDefs, {
  LayerMetricDef,
  LayerMetricDefMap,
  LayerMetricDefResolved,
  resolveLayerMetricDef,
} from 'src/app/analysis/model/layer-metric-defs'
import { LayerViewValues } from 'src/app/analysis/model/layer-view'

export const customLayerPropertyDefIDs = [
  'topAndDropProperties',
  'multiSection',
] as const

interface ILayerPropertyDef {
  id: keyof LayerMetricDefMap | (typeof customLayerPropertyDefIDs)[number]
  showUndefinedUnlessTypes?: string[]
}

export type LayerPropertyDef = Partial<LayerMetricDef> & ILayerPropertyDef

export type LayerPropertyDefResolved = LayerMetricDefResolved &
  ILayerPropertyDef

const layerPropertyDefs: LayerPropertyDef[] = [
  { id: 'name' },
  { id: 'programType' },
  { id: 'layerType' },
  {
    id: 'topAndDropProperties',
    label: 'T&D Properties',
    valueType: 'custom',
    whitelist: ['cat_td', 'top_and_drop', 'drop'],
  },
  {
    id: 'multiSection',
    label: 'Sections',
    valueType: 'custom',
    whitelist: ['cat_multisection', 'noncat_multisection'],
  },
  { id: 'fixedIndexValue' },
  { id: 'isCustomIndex' },
  { id: 'customIndexValues' },
  { id: 'occurrenceLimit' },
  { id: 'contractOccurrenceLimit' },
  { id: 'occurrenceAttachment' },
  { id: 'payout' },
  { id: 'trigger' },
  { id: 'riskLimit' },
  { id: 'riskAttachment' },
  { id: 'franchiseDeductible' },
  { id: 'indexation' },
  { id: 'sicOrFranchise' },
  { id: 'reinstatements' },
  { id: 'aggregateLimit' },
  { id: 'aggregateAttachment' },
  { id: 'cessionPercentage' },
  { id: 'cedingCommission' },
  {
    id: 'rolPercentage',
    showUndefinedUnlessTypes: ['noncat_xl', 'noncat_indxl', 'noncat_ag'],
  },
  { id: 'rateOnLineSubject' },
  { id: 'pmpm' },
  { id: 'nth' },
  { id: 'inception_date' },
  { id: 'expiry_date' },
  { id: 'minRateSubject' },
  { id: 'maxRateSubject' },
  { id: 'minRatePmpm' },
  { id: 'maxRatePmpm' },
  { id: 'swingRate' },
  { id: 'swingBasis' },
  { id: 'premium' },
  { id: 'reinsurerExpenseProvision' },
  { id: 'profitCommission' },
  { id: 'currencyChange' },
  { id: 'technicalPremium' },
]

const mergeMetricDef =
  (defs: LayerMetricDefMap, values: LayerViewValues) =>
  (propDef: LayerPropertyDef): LayerPropertyDefResolved => {
    let mergedDef: LayerMetricDef & ILayerPropertyDef
    if (customLayerPropertyDefIDs.includes(propDef.id as any)) {
      mergedDef = propDef as LayerMetricDef & ILayerPropertyDef
    } else {
      const def = defs[propDef.id as keyof LayerMetricDefMap]
      if (!def) {
        throw new Error(
          `Cannot find layer property definition for '${propDef.id}`
        )
      }
      if (def.label === 'Premium') {
        if (
          (values.type === 'cat_ag' && values.subtype === 'feeder') ||
          values.sharedLayerID !== ''
        ) {
          def.editable = false
        } else {
          def.editable = true
        }
      }
      mergedDef = { ...def, ...propDef }
    }

    const resolved = resolveLayerMetricDef(mergedDef, values)
    return resolved
  }

export default function getLayerPropertyDefs(values: LayerViewValues) {
  const defs = layerPropertyDefs
    .map(mergeMetricDef(LayerMetricDefs, values))
    .filter(def => isLayerMetricApplicable(values, def))
    .filter(def => !def.hide)
  return defs
}
