<div class="flex-container-column">
  <div class="selector">
    <app-tier-bar-container contextPath="digital"></app-tier-bar-container>
  </div>
  <div class="flex-container-row" *ngIf="clientID">
    <div class="col">
      <div
        class="container"
        appDragDrop
        (fileDropped)="onFileDropped($event)"
      >
        <h3>Drag and drop file(s) here</h3>
        <h3>or</h3>
        <button mat-raised-button (click)="fileDropRef.click()">
          Browse for file(s)
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            multiple
            (change)="fileBrowseHandler($event.target.files)"
            class="hidden-input"
          />
        </button>
      </div>
      <div class="files-list">
        <div *ngFor="let file of files; let i = index">
          <svg
            (click)="deleteFile(i)"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="18"
            viewBox="0 0 14 18"
          >
            <path
              fill="#B1B1B1"
              fill-rule="nonzero"
              d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
            />
          </svg>
          {{ file?.name }}
        </div>
      </div>
      <div class="metadata-selector">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput required [(ngModel)]="name" />
        </mat-form-field>
        <br />
        <mat-form-field>
          <mat-label>Document Class</mat-label>
          <mat-select [(ngModel)]="docClass">
            <mat-option
              required
              *ngFor="let documentClass of documentClassList"
              [value]="documentClass.id"
            >{{ documentClass.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
          <mat-label>Comments</mat-label>
          <textarea required matInput [(ngModel)]="comments"></textarea>
        </mat-form-field>
        <br />
        <button mat-raised-button [disabled]="disableBtn()" (click)="add()">
          Submit
        </button>
      </div>
    </div>
    <div class="col file-selector">
      <mat-accordion [multi]="true">
        <mat-expansion-panel
          *ngFor="let documentClass of documentClassList"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ documentClass.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            class="document-name"
            href="{{ doc.links[0].url }}"
            *ngFor="let doc of documentClass.documents"
          >{{ doc.documentName }}<br
          /></a>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
