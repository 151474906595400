import { selectQuoteMIState } from './../quote-management-information.selectors'
import { createSelector } from '@ngrx/store'

export const selectQuoteMIChartsState = createSelector(
  selectQuoteMIState,
  state => state.charts
)

export const selectQuoteMICharts = createSelector(
  selectQuoteMIChartsState,
  state => state.charts
)

export const selectChartsMaximizedIndex = createSelector(
  selectQuoteMIChartsState,
  state => state.maximizedIndex
)

export const selectQuoteMIChartsMetrics = createSelector(
  selectQuoteMIChartsState,
  state => state.metrics
)

export const selectQuoteMISelectedChartsMetric = createSelector(
  selectQuoteMIChartsState,
  state => state.selectedMetric
)

export const selectAvailableChartsForTab = createSelector(
  selectQuoteMIChartsState,
  state => state.selectableChartsForTab
)

export const selectAvailableChartsForMetric = createSelector(
  selectQuoteMIChartsState,
  state => state.selectableChartsForMetric
)

export const selectQuoteMIChartsGroupBys = createSelector(
  selectQuoteMIChartsState,
  state => state.groupBys
)

const selectChartsEntityLimit = createSelector(
  selectQuoteMIChartsState,
  state => state.chartEntityLimit
)

export const selectChartsEntityLimitWithDefault = createSelector(
  selectChartsEntityLimit,
  chartEntityLimit => chartEntityLimit ?? 16
)

export const selectHideMetrics = createSelector(
  selectQuoteMIChartsState,
  state => state.hideMetrics
)

export const selectLightChartMode = createSelector(
  selectQuoteMIChartsState,
  state => state.lightChartMode
)
