import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-user-disclaimer-dialog',
  styles: [
    `
           .full-width {
             width: 100%;
           }
           .center {
             text-align: center;
           }
         `,
  ],
  template: `
    <div>
      <h1 class="center" mat-dialog-title>SOFTWARE LICENSE AGREEMENT</h1>
      <div mat-dialog-content (scroll)="onScroll($event)">
        <div [innerHTML]="data"></div>
        <!-- tabindex setting is used to prevent automatic scrolling to bottom https://stackoverflow.com/questions/53029780/angular-6-mat-dialog-scroll-down-to-bottom-automatically -->
        <button appButton accent (click)="onAgreeClick()" tabindex="-1">
          <span>Agree</span>
        </button>
        <button appButton link (click)="onDisagreeClick()" tabindex="-1">
          <!-- tabindex setting is used to prevent automatic scrolling to bottom -->
          <span>Disagree</span>
        </button>
      </div>
      <div mat-dialog-content></div>
    </div>
  `,
})
export class NewUserDisclaimerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewUserDisclaimerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _snackBar: MatSnackBar
  ) {}

  disableButton = true

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (
      event.target.offsetHeight + Math.round(event.target.scrollTop) >=
      event.target.scrollHeight
    ) {
      this.disableButton = false
    }
  }

  onDisagreeClick() {
    this._snackBar.open(
      'Need to agree Terms and conditons to enter application.',
      'X',
      { duration: 3000 }
    )
  }

  onAgreeClick() {
    this.dialogRef.close('agree')
  }
}
