import { BasicControl } from '../management-information/model/management-information.model'
import { IControl } from '../management-information/store/management-information.reducer'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export interface TrackingData {
  acctName: string
  acctid: string
  clientName: string
  clientId: number
  year: string
  programName: string
  programId: number
  businessUnit: string
  inceptionDate: string | null
  opportunityStage: string
  structureCount: number
  reinsurerCount: number
  marketingList: string | null
  quoteCount: number
  fotCount: number
  signedLines: string | null
  submittedOT: string | null
  complete: string
  details?: TrackingDetailsData[] | null
}

export interface TrackingDetailsData {
  programId: number
  structureId: number
  structureName: string
  layerDetails: TrackingDetailsLayerData[] | null
}

export interface TrackingDetailsLayerData {
  layerName: string
  quoteCount: number
  written: string
  order: string
  fotCount: number
  signed: string
}

export interface TrackingSelectors {
  clientName: IControl
  year: IControl
  businessUnit: IControl
  complete: IControl
}

export interface TrackingResponseData {
  acctName: string
  acctid: string
  carrierName: string
  carrierId: number
  year: number
  programName: string
  programId: number
  oppBusinessUnit: string
  oppStage: string
  oppInception: Date
  structureCount: number
  quoteCount: number
  fotCount: number
  assignedLinesCount: number
  reinsurerCount: number
  minOTSubmissionDate: null
  firstExportDate: Date | null
  lastExportDate: Date | null
  completeDate: Date | null
}

export interface TrackingDetailsResponseData {
  year: number
  programId: number
  structureId: number
  structureLabel: string
  riskSectionId: number
  layerRef: string
  layerName: string
  inceptionDate: Date | null
  contractCreatedDate: Date | null
  otSubmissionDate: Date | null
  fotInsertDate: Date | null
  fotCount: number | null
  quoteInsertDate: Date | null
  quoteCount: number | null
  orderTotal: number
  signedTotal: number | null
  writtenTotal: number | null
}

export interface TrackingPreferences {
  label: string
  clientName: string[]
  year: string[]
  businessUnit: string[]
  complete: string[]
  minDateValue: string
  maxDateValue: string
}

export const DEFAULT_TRACKING_SELECTORS: TrackingSelectors = {
  clientName: {
    columnName: 'clientName',
    name: 'clientName',
    id: 'clientName',
    selectedValues: [],
    values: [],
    allValues: [],
  },
  year: {
    columnName: 'year',
    name: 'year',
    id: 'year',
    selectedValues: [],
    values: [],
    allValues: [],
  },
  businessUnit: {
    columnName: 'businessUnit',
    name: 'businessUnit',
    id: 'businessUnit',
    selectedValues: [],
    values: [],
    allValues: [],
  },
  complete: {
    columnName: 'complete',
    name: 'complete',
    id: 'complete',
    selectedValues: [],
    values: [],
    allValues: [],
  },
}


export const DEFAULT_INCEPTION_DATE: BasicControl = {
  name: 'Inception Date',
  columnName: 'inceptionDate',
  minValue: getDateMonthsFrom(-4).toDateString(),
  maxValue: getDateMonthsFrom(4).toDateString(),
}

function getDateMonthsFrom(months: number) {
  const newDate = new Date()
  newDate.setMonth(newDate.getMonth() + months)
  return newDate
}

export const TRACKING_COLUMNS: SortTableColumnDef[] = [
  {
    label: 'Client Name',
    id: 'clientName',
    valueType: 'text',
  },
  {
    label: 'Program Name',
    id: 'programName',
    valueType: 'text',
  },
  {
    label: 'Program Year',
    id: 'year',
    valueType: 'text',
  },
  {
    label: 'Inception Date',
    id: 'inceptionDate',
    valueType: 'text',
  },
  {
    label: 'Opportunity Stage',
    id: 'opportunityStage',
    valueType: 'text',
  },
  {
    label: 'Structure Count',
    id: 'structureCount',
    valueType: 'numeric',
  },
  {
    label: 'Reinsurer Count',
    id: 'reinsurerCount',
    valueType: 'numeric',
  },
  {
    label: 'Marketing List',
    id: 'marketingList',
    valueType: 'text',
  },
  {
    label: 'Quote Count',
    id: 'quoteCount',
    valueType: 'numeric',
  },
  {
    label: 'FOT Count',
    id: 'fotCount',
    valueType: 'numeric',
  },
  {
    label: 'Signed Lines',
    id: 'signedLines',
    valueType: 'text',
  },
  {
    label: 'Submitted to OT',
    id: 'submittedOT',
    valueType: 'text',
  },
  {
    label: 'Complete',
    id: 'complete',
    valueType: 'text',
  },
]

export const TRACKING_DETAILS_COLUMNS: SortTableColumnDef[] = [
  {
    label: 'Layer Name',
    id: 'layerName',
    valueType: 'text',
    width: '15rem',
  },
  {
    label: 'Quote Count',
    id: 'quoteCount',
    valueType: 'numeric',
    width: '5rem',
  },
  {
    label: 'Written %',
    id: 'written',
    valueType: 'text',
    width: '5rem',
  },
  {
    label: 'Order %',
    id: 'order',
    valueType: 'text',
    width: '5rem',
  },
  {
    label: 'FOT Count',
    id: 'fotCount',
    valueType: 'numeric',
    width: '5rem',
  },
  {
    label: 'Signed %',
    id: 'signed',
    valueType: 'text',
    width: '5rem',
  },
]
