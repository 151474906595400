import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MIValueType } from '../management-information/model/management-information.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mi-summary-value',
  styles: [
    `
           :host {
             width: 100%;
           }
         `,
  ],
  template: `
    <ng-container [ngSwitch]="type">
      <span
        *ngSwitchCase="'currency'"
        [matTooltip]="value | currency: 'USD'"
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ value | appShortNumber: 'USD':false:true | emptyValue }}</span
      >

      <span
        *ngSwitchCase="'percentage'"
        [matTooltip]="value | percent: '1.1-4'"
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ value | percent: '1.1-2' | emptyValue }}</span
      >

      <span
        *ngSwitchCase="'numeric-decimal'"
        [matTooltip]="value | number: '1.0-4'"
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ value | number: '1.1-1' | emptyValue }}</span
      >

      <span
        *ngSwitchCase="'numeric-int'"
        [matTooltip]="value | number: '1.0-4'"
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ value | number: '1.0-0' | emptyValue }}</span
      >

      <span
        *ngSwitchCase="'text'"
        [matTooltip]="textToolTip"
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ value }}</span
      >
    </ng-container>
  `,
})
export class MISummaryValueComponent {
  @Input() value: number
  @Input() type: MIValueType = 'text'
  @Input() tooltipShowDelay = 200
  @Input() textToolTip = ''
}
