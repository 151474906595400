import { FolderService } from '../../../api/folder/folder.service'
import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  switchMap,
  map,
  withLatestFrom,
  catchError,
  mergeMap,
} from 'rxjs/operators'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { AppState } from '../index'
import * as fromBrokerActions from './broker.actions'
import * as fromProgramActions from '../program/program.actions'
import { rejectError } from '../../../api/util'
import {
  selectCurrentStudyPrograms,
  selectPrograms,
} from '../program/program.selectors'
import { selectAuthState } from '../auth/auth.selectors'
import { selectProgramGroups } from '../program-group/program-group.selectors'
import { parseCsvResponse } from '../../../api/animated-scenarios/util'
import { CurrencyRate } from '../../../analysis/tower/mechanics/tower.model'
import { of } from 'rxjs'
import { Folder } from '../../model/study.model'
import { BackendService } from '../../../api/backend/backend.service'
import { selectCurrentStudyID } from './broker.selectors'
import { StudyResponse } from '../../../api/model/backend.model'
import { fetchSummaryViews, fetchSummaryViewsFailure, fetchSummaryViewsSuccess } from 'src/app/analysis/explore/store/explore.actions'

// tslint:disable: no-non-null-assertion
@Injectable()
export class BrokerEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private folderService: FolderService,
    private backendService: BackendService
  ) {}

  setCurrentClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrokerActions.setCurrentClient),
      withLatestFrom(
        this.store.pipe(select(selectAuthState)),
        this.store.pipe(select(selectPrograms)),
        this.store.pipe(select(selectProgramGroups))
      ),
      map(([_, authState, programs, programGroups]) => {
        return fromBrokerActions.setCurrentClientSuccess({
          compareViews: authState.compareViews,
          programs,
          programGroups,
        })
      })
    )
  )

  fetchAnalysisProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrokerActions.setCurrentAnalysisProfile),
      withLatestFrom(this.store.pipe(select(selectCurrentStudyPrograms))),
      switchMap(([action, structures]) => {
        // If no analysisID provided, use first structure in study to get id
        if (action.id) {
          return this.service.fetchAnalysisProfile(action.id)
        } else {
          return this.service.fetchAnalysisProfile(structures[0].analysisID)
        }
      }),
      rejectError(error => {
        return this.store.dispatch(
          fromBrokerActions.setCurrentAnalysisProfileFailure({ error })
        )
      }),
      map(analysisProfile => {
        this.service
          .fetchExchangeRateCurrencies(
            analysisProfile.exchange_rate_profile.exchange_rate_table.id
          )
          .subscribe(res => {
            if (res.data) {
              const currencies = res.data.currencies.sort((a, b) =>
                a.code.localeCompare(b.code)
              )
              this.store.dispatch(
                fromBrokerActions.setCurrencyList({ currencies })
              )
            }
          })
        this.service
          .fetchContent(
            analysisProfile.exchange_rate_profile.exchange_rate_table.data.name
          )
          .pipe(parseCsvResponse<CurrencyRate>())
          .subscribe(response => {
            if (response.data) {
              const currencyRates = response.data
              this.store.dispatch(
                fromBrokerActions.setCurrencyRates({ currencyRates })
              )
            }
          })
        return fromBrokerActions.setCurrentAnalysisProfileSuccess({
          analysisProfile,
        })
      })
    )
  )

  fetchStudy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromBrokerActions.fetchStudy),
      withLatestFrom(this.store.pipe(select(selectCurrentStudyID))),
      switchMap(([action, programId]) => {
        return this.backendService.getStudy(programId ?? action.programId)
      }),
      rejectError(error => {
        return this.store.dispatch(
          fromBrokerActions.fetchStudyFailure({ error })
        )
      }),
      map((study: StudyResponse) => {
        return fromBrokerActions.fetchStudySuccess({ study })
      })
    )
  })

  fetchFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromBrokerActions.fetchFolders),
      switchMap((action) => {
        return this.backendService.getStudy(action.programId)
      }),
      rejectError(error => {
        return this.store.dispatch(
          fromBrokerActions.fetchFoldersFailure({ error })
        )
      }),
      map((study: StudyResponse) => {
        const folders = study.folders
        return fromBrokerActions.fetchFoldersSuccess({ folders })
      })
    )
  })

  createFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrokerActions.createFolder),
      switchMap(({ programId, name, parentId }) =>
        this.folderService.postFolder(name, programId, parentId).pipe(
          map(folder => {
            const newFolder = folder as Folder
            return fromBrokerActions.createFolderSuccess({ folder: newFolder })
          }),
          catchError(error => {
            return of(fromBrokerActions.createFolderFailure({ error }))
          })
        )
      )
    )
  )

  updateFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrokerActions.updateFolder),
      switchMap(({ programId, name, parentId, folderID }) =>
        this.folderService.putFolder(folderID, name, programId, parentId).pipe(
          switchMap((folder) => [
            fromBrokerActions.updateFolderSuccess({ folder: folder as Folder }),
            fromBrokerActions.fetchFolders({ programId })
          ]),
          catchError(error => {
            return of(fromBrokerActions.updateFolderFailure({ error }))
          })
        )
      )
    )
  )

  deleteFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrokerActions.deleteFolder),
      withLatestFrom(this.store.pipe(select(selectCurrentStudyID))),
      switchMap(([action, programId]) =>
        this.folderService.deleteFolder(action.folderID).pipe(
          switchMap(() => [
            fromBrokerActions.deleteFolderSuccess(),
            fromBrokerActions.fetchFolders({ programId })
          ]),
          catchError(error => of(fromBrokerActions.deleteFolderFailure({ error })))
        )
      )
    )
  )

  postLossSetMappingLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProgramActions.postLossSetMappingLabels),
      switchMap(({ id, mappingLabels }) =>
        this.backendService.postLossSetMappingLabels(id, mappingLabels).pipe(
          map(() => {
            return fromProgramActions.postLossSetMappingLabelsSuccess({ id, mappingLabels })
          }),
          catchError(error => {
            return of(fromProgramActions.postLossSetMappingLabelsFailure({ error }))
          })
        )
      )
    )
  )

  updateLossSetMappingLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProgramActions.updateLossSetMappingLabels),
      switchMap(({ id, mappingLabels }) =>
        this.backendService.putLossSetMappingLabels(id, mappingLabels).pipe(
          map(() => {
            return fromProgramActions.updateLossSetMappingLabelsSuccess({ id, mappingLabels })
          }),
          catchError(error => {
            return of(fromProgramActions.updateLossSetMappingLabelsFailure({ error }))
          })
        )
      )
    )
  )

  fetchSummaryViews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchSummaryViews),
      switchMap((action) => {
        return this.backendService.getStudy(String(action.programId))
      }),
      rejectError(error => {
        return this.store.dispatch(
          fetchSummaryViewsFailure({ error })
        )
      }),
      map((study: StudyResponse) => {
        const explore_gross_views = study.explore_gross_views
        return fetchSummaryViewsSuccess({ explore_gross_views })
      })
    )
  })
}
