import {
  AssignedLinesResponse,
  ReinstatementResponse,
  ReinsurerResponse,
  ReinsurerSubjectivityResponse,
  RiskResponse,
  SectionResponse,
  SubjectivityResponse,
} from '../model/quote.model'
import { OmitID } from '../model/backend.model'
import {
  QuoteReinsurer,
  QuoteFields,
  Section,
  Subjectivity,
  SlidingScale,
  Reinstatements,
  Risk,
  ReinsurerSubjectivity,
  AssignedLines,
  QUOTE_TEMP_PREFIX,
  ASSIGNED_LINES_TEMP_PREFIX,
  SUBJECTIVITY_TEMP_PREFIX,
} from '../../quote/models/quote.model'
import { MonetaryUnit } from '../analyzere/analyzere.model'
import { analyzereConstants } from '@shared/constants/analyzere'

const convertMonetaryUnit = (
  value: number | undefined,
  currency: string | null | undefined
): MonetaryUnit => {
  return {
    value: value ? value : 0,
    currency: currency || 'USD',
  }
}
const isValueUnlimited = (num: number | undefined): boolean => {
  if (num) {
    return num.toString().includes('e' || 'E')
  } else {
    return false
  }
}

/* To Request */
export const convertRiskToRequestPost = (
  section: Partial<Risk>
): OmitID<Partial<RiskResponse>> => ({
  structureID: section.structureID,
  programID: section.programID,
  fiscalYear: section.fiscalYear,
})
export const convertSectionToRequestPost = (
  section: Partial<Section>
): OmitID<Partial<SectionResponse>> => ({
  layerRef: section.layerRef,
  structureID: section.structureID,
  occurrenceLimitToggle: section.occurrenceLimitToggle,
  occurrenceAttachmentToggle: section.occurrenceAttachmentToggle,
  riskLimitToggle: section.riskLimitToggle,
  riskAttachmentToggle: section.riskAttachmentToggle,
  franchiseDeductibleToggle: section.franchiseDeductibleToggle,
  reinstatementsToggle: section.reinstatementsToggle,
  aggregateLimitToggle: section.aggregateLimitToggle,
  aggregateAttachmentToggle: section.aggregateAttachmentToggle,
  cedingCommissionToggle: section.cedingCommissionToggle,
  reinsurerExpenseProvisionToggle: section.reinsurerExpenseProvisionToggle,
  profitCommissionToggle: section.profitCommissionToggle,
  rolPercentageToggle: section.rolPercentageToggle,
  rateOnLineSubjectToggle: section.rateOnLineSubjectToggle,
  pmpmToggle: section.pmpmToggle,
  premiumToggle: section.premiumToggle,
  effectiveDateToggle: section.effectiveDateToggle,
  expirationDateToggle: section.expirationDateToggle,
  expiryDateToggle: section.expiryDateToggle,
  underwriterToggle: section.underwriterToggle,
  maolLimitToggle: section.maolLimitToggle,
  payoutToggle: section.payoutToggle,
  triggerToggle: section.triggerToggle,
  nthToggle: section.nthToggle,
  terrorismAggSubLimitToggle: section.terrorismAggSubLimitToggle,
  lossRatioCapToggle: section.lossRatioCapToggle,
  lossRatioCapPercentageToggle: section.lossRatioCapPercentageToggle,
  lossCapApplicationToggle: section.lossCapApplicationToggle,
  limitApplicationToggle: section.limitApplicationToggle,
  laeCapToggle: section.laeCapToggle,
  laeTreatmentToggle: section.laeTreatmentToggle,
  adjustmentBasisToggle: section.adjustmentBasisToggle,
  ecoCoveragePctToggle: section.ecoCoveragePctToggle,
  xplCoveragePctToggle: section.xplCoveragePctToggle,
  subjectPremiumToggle: section.subjectPremiumToggle,
  depositPremiumPercentageToggle: section.depositPremiumPercentageToggle,
  minimumPremiumPercentageToggle: section.minimumPremiumPercentageToggle,
  minimumPremiumToggle: section.minimumPremiumToggle,
  clashPremiumToggle: section.clashPremiumToggle,
  profitShareCommissionToggle: section.profitShareCommissionToggle,
  profitShareMinRateToggle: section.profitShareMinRateToggle,
  profitShareMaxRateToggle: section.profitShareMaxRateToggle,
  brokerageCommissionToggle: section.brokerageCommissionToggle,
  brokerageTypeToggle: section.brokerageTypeToggle,
  brokerageRIPCommissionToggle: section.brokerageRIPCommissionToggle,
  orderPercentToggle: section.orderPercentToggle,
  otherFeaturesToggle: section.otherFeaturesToggle,
  coverageBasisToggle: section.coverageBasisToggle,
  indexationtextToggle: section.indexationtextToggle,
  // quoteSignedPercentageToggle: section.quoteSignedPercentageToggle,
  quoteMinPercentageToggle: section.quoteMinPercentageToggle,
  quoteOfferedPercentageToggle: section.quoteOfferedPercentageToggle,
  quoteOfferedLimitToggle: section.quoteOfferedLimitToggle,
  xplEcoDropdownToggle: section.xplEcoDropdownToggle,
  xplEcoConditionsToggle: section.xplEcoConditionsToggle,
  quoteDepositPremiumToggle: section.quoteDepositPremiumToggle,
  quoteCessionsBasedPremiumToggle: section.quoteCessionsBasedPremiumToggle,
  quoteMinRateSubjectToggle: section.quoteMinRateSubjectToggle,
  quoteMaxRateSubjectToggle: section.quoteMaxRateSubjectToggle,
  quoteMinRatePmpmToggle: section.quoteMinRatePmpmToggle,
  quoteMaxRatePmpmToggle: section.quoteMaxRatePmpmToggle,
  quoteSwingRateToggle: section.quoteSwingRateToggle,
  quoteSwingBasisToggle: section.quoteSwingBasisToggle,
  quoteIndexationToggle: section.quoteIndexationToggle,
  quoteFixedIndexValueToggle: section.quoteFixedIndexValueToggle,
  quoteSicOrFranchiseToggle: section.quoteSicOrFranchiseToggle,
  quoteExpectedCededLossToggle: section.quoteExpectedCededLossToggle,
  quoteExpectedCededPremiumToggle: section.quoteExpectedCededPremiumToggle,
  quoteDepositPremiumCalcToggle: section.quoteDepositPremiumCalcToggle,
  slidingCommToggle: section.slidingCommToggle,
  layerName: section.layerName,
  layerType: section.layerType,
  layerCurrency: section.layerCurrency,
  programID: section.programID,
  programGroupID: section.programGroupID,
  sharedLimitID: section.sharedLimitID,
  territorialScopeToggle: section.territorialScopeToggle,
  vendorToggle: section.vendorToggle,
  feeOrBrokerageToggle: section.feeOrBrokerageToggle,
  structureFXToggle: section.structureFXToggle,
  premiumFXToggle: section.premiumFXToggle,
  premiumFXToUSDToggle: section.premiumFXToUSDToggle,
  layerCategoryToggle: section.layerCategoryToggle,
  layerClassToggle: section.layerClassToggle,
  excludeFromPricingCurveToggle: section.excludeFromPricingCurveToggle,
  perilsToggle: section.perilsToggle,
  modelVersionToggle: section.modelVersionToggle,
  lossImpactedFromPreviousYearToggle:
    section.lossImpactedFromPreviousYearToggle,
  cedingCommissionBasisToggle: section.cedingCommissionBasisToggle,
  feeToggle: section.feeToggle,
  rebateToggle: section.rebateToggle,
  quoteProbabilityOfAttachToggle: section.quoteProbabilityOfAttachToggle,
  quoteProbabilityOfExhaustToggle: section.quoteProbabilityOfExhaustToggle,
  quoteIndexToggle: section.quoteIndexToggle,
  quoteTopOccurrenceAttachmentToggle:
    section.quoteTopOccurrenceAttachmentToggle,
  quoteTopOccurrenceLimitToggle: section.quoteTopOccurrenceLimitToggle,
  quoteDropOccurrenceAttachmentToggle:
    section.quoteDropOccurrenceAttachmentToggle,
  quoteDropOccurrenceLimitToggle: section.quoteDropOccurrenceLimitToggle,
  quoteAggregateLimitTopToggle: section.quoteAggregateLimitTopToggle,
  quoteAggregateLimitDropToggle: section.quoteAggregateLimitDropToggle,
  fotCount: section.fotCount,
  quoteCount: section.quoteCount,
})
export const convertReinsurerToRequest = (
  reinsurer: Partial<QuoteReinsurer>
): Partial<ReinsurerResponse> => ({
  id:
    reinsurer.id && !reinsurer.id?.startsWith(QUOTE_TEMP_PREFIX)
      ? parseInt(reinsurer.id, 10)
      : undefined,
  layerRef: reinsurer.cededlayerID,
  newCededLayerForViewID: reinsurer.newCededLayerForViewID,
  riskSectionPhase: reinsurer.reinsurerPhase,
  riskSectionPhaseVersion: reinsurer.reinsurerPhaseVersion,
  riskSectionPhaseLabel: reinsurer.reinsurerPhaseLabel,
  reinsurerName: reinsurer.quoteReinsurerName,
  riskSectionId: reinsurer.reinsurerSectionId,
  quoteEffectiveDate: reinsurer.quoteFields?.quoteEffectiveDate,
  quoteExpirationDate: reinsurer.quoteFields?.quoteExpirationDate,
  quoteExpiryDate: reinsurer.quoteFields?.quoteExpiryDate,
  adjustmentBasis: reinsurer.quoteFields?.adjustmentBasis,
  payout: reinsurer.quoteFields?.quotePayout?.value,
  trigger: reinsurer.quoteFields?.quoteTrigger?.value,
  nth: reinsurer.quoteFields?.quoteNth,
  uwrSLipRoleEnum: reinsurer.quoteFields?.underwriter,
  maolLimit: reinsurer.quoteFields?.maolLimit?.value,
  terrorismAggSubLimit: reinsurer.quoteFields?.terrorismAggSubLimit?.value,
  lossRatioCap: reinsurer.quoteFields?.lossRatioCap?.value,
  lossRatioCapPercentage: reinsurer.quoteFields?.lossRatioCapPercentage,
  lossCapApplication: reinsurer.quoteFields?.lossCapApplication,
  limitApplication: reinsurer.quoteFields?.limitApplication,
  laeCap: reinsurer.quoteFields?.laeCap?.value,
  laeTreatment: reinsurer.quoteFields?.laeTreatment,
  xplCoveragePct: reinsurer.quoteFields?.xplCoveragePct,
  ecoCoveragePct: reinsurer.quoteFields?.ecoCoveragePct,
  subjectPremium: reinsurer.quoteFields?.subjectPremium?.value,
  depositPremiumPercentage: reinsurer.quoteFields?.depositPremiumPercentage,
  minimumPremiumPercentage: reinsurer.quoteFields?.minimumPremiumPercentage,
  minimumPremium: reinsurer.quoteFields?.minimumPremium?.value,
  clashPremium: reinsurer.quoteFields?.clashPremium?.value,
  profitShareCommission: reinsurer.quoteFields?.profitShareCommission,
  profitShareMinRate: reinsurer.quoteFields?.profitShareMinRate,
  profitShareMaxRate: reinsurer.quoteFields?.profitShareMaxRate,
  brokerageRIPCommission: reinsurer.quoteFields?.brokerageRIPCommission,
  orderPercent: reinsurer.quoteFields?.orderPercent,
  brokerageCommission: reinsurer.quoteFields?.brokerageCommission,
  brokerageType: reinsurer.quoteFields?.brokerageType,
  otherFeatures: reinsurer.quoteFields?.otherFeatures,
  coverageBasis: reinsurer.quoteFields?.coverageBasis,
  indexationtext: reinsurer.quoteFields?.indexationtext,
  premium: isValueUnlimited(reinsurer.quoteFields?.quotePremium?.value)
    ? analyzereConstants.unlimitedValue
    : reinsurer.quoteFields?.quotePremium?.value,
  occurrenceLimit: isValueUnlimited(
    reinsurer.quoteFields?.quoteOccurrenceLimit?.value
  )
    ? analyzereConstants.unlimitedValue
    : reinsurer.quoteFields?.quoteOccurrenceLimit?.value,
  occurrenceAttachment: reinsurer.quoteFields?.quoteOccurrenceAttachment?.value,
  topOccurrenceLimit: isValueUnlimited(
    reinsurer.quoteFields?.quoteTopOccurrenceLimit?.value
  )
    ? analyzereConstants.unlimitedValue
    : reinsurer.quoteFields?.quoteTopOccurrenceLimit?.value,
  topOccurrenceAttachment:
    reinsurer.quoteFields?.quoteTopOccurrenceAttachment?.value,
  dropOccurrenceLimit: isValueUnlimited(
    reinsurer.quoteFields?.quoteDropOccurrenceLimit?.value
  )
    ? analyzereConstants.unlimitedValue
    : reinsurer.quoteFields?.quoteDropOccurrenceLimit?.value,

  dropOccurrenceAttachment:
    reinsurer.quoteFields?.quoteDropOccurrenceAttachment?.value,
  topAggregateLimit: reinsurer.quoteFields?.quoteAggregateLimitTop?.value,
  dropAggregateLimit: reinsurer.quoteFields?.quoteAggregateLimitDrop?.value,
  riskLimit: reinsurer.quoteFields?.quoteRiskLimit?.value,
  riskAttachment: reinsurer.quoteFields?.quoteRiskAttachment?.value,
  franchiseDeductible: reinsurer.quoteFields?.quoteFranchiseDeductible?.value,
  aggregateLimit: isValueUnlimited(
    reinsurer.quoteFields?.quoteAggregateLimit?.value
  )
    ? analyzereConstants.unlimitedValue
    : reinsurer.quoteFields?.quoteAggregateLimit?.value,
  aggregateAttachment: reinsurer.quoteFields?.quoteAggregateAttachment?.value,
  cessionPercentage: reinsurer.quoteFields?.quoteCessionPercentage,
  cedingCommission: reinsurer.quoteFields?.quoteCedingCommission,
  profitCommission: reinsurer.quoteFields?.quoteProfitCommission,
  rolPercentage: reinsurer.quoteFields?.quoteRolPercentage,
  rateOnLineSubject: reinsurer.quoteFields?.quoteRateOnLineSubject,
  pmpm: reinsurer.quoteFields?.quotePmpm,
  reinsurerExpenseProvision:
    reinsurer.quoteFields?.quoteReinsurerExpenseProvision,
  decline: reinsurer.decline,
  declineReason: reinsurer.declineReason,
  exportToggle: reinsurer.exportToggle,
  signedPercentage: reinsurer.quoteFields?.quoteSignedPercentage,
  minPercentage: reinsurer.quoteFields?.quoteMinPercentage,
  offeredPercentage: reinsurer.quoteFields?.quoteOfferedPercentage,
  offeredLimit: reinsurer.quoteFields?.quoteOfferedLimit?.value,
  xplEcoDropdown: reinsurer.quoteFields?.xplEcoDropdown,
  xplEcoConditions: reinsurer.quoteFields?.xplEcoConditions,
  isLimitUnlimited: reinsurer.quoteFields?.isLimitUnlimited,
  isAggregateUnlimited: reinsurer.quoteFields?.isAggregateUnlimited,
  quoteDepositPremium: reinsurer.quoteFields?.quoteDepositPremium?.value,
  quoteCessionsBasedPremium: reinsurer.quoteFields?.quoteCessionsBasedPremium,
  quoteMinRateSubject: reinsurer.quoteFields?.quoteMinRateSubject,
  quoteMaxRateSubject: reinsurer.quoteFields?.quoteMaxRateSubject,
  quoteMinRatePmpm: reinsurer.quoteFields?.quoteMinRatePmpm,
  quoteMaxRatePmpm: reinsurer.quoteFields?.quoteMaxRatePmpm,
  quoteSwingRate: reinsurer.quoteFields?.quoteSwingRate,
  quoteSwingBasis: reinsurer.quoteFields?.quoteSwingBasis,
  quoteIndexation: reinsurer.quoteFields?.quoteIndexation,
  quoteFixedIndexValue: reinsurer.quoteFields?.quoteFixedIndexValue,
  quoteSicOrFranchise: reinsurer.quoteFields?.quoteSicOrFranchise,
  quoteExpectedCededLoss: reinsurer.quoteFields?.quoteExpectedCededLoss?.value,
  quoteExpectedCededPremium:
    reinsurer.quoteFields?.quoteExpectedCededPremium?.value,
  quoteDepositPremiumCalc:
    reinsurer.quoteFields?.quoteDepositPremiumCalc?.value,
  minCommission: reinsurer.slidingScale
    ? reinsurer.slidingScale[0].commission
    : 0,
  provCommission: reinsurer.slidingScale
    ? reinsurer.slidingScale[1].commission
    : 0,
  maxCommission: reinsurer.slidingScale
    ? reinsurer.slidingScale[2].commission
    : 0,
  minLossRatio: reinsurer.slidingScale
    ? reinsurer.slidingScale[0].lossRatio
    : 0,
  provLossRatio: reinsurer.slidingScale
    ? reinsurer.slidingScale[1].lossRatio
    : 0,
  maxLossRatio: reinsurer.slidingScale
    ? reinsurer.slidingScale[2].lossRatio
    : 0,
  minSlideRate: reinsurer.slidingScale
    ? reinsurer.slidingScale[0].slideRate
    : 0,
  provSlideRate: undefined,
  maxSlideRate: reinsurer.slidingScale
    ? reinsurer.slidingScale[2].slideRate
    : 0,
  riskReinstatements: convertToReinstatement(
    reinsurer.quoteFields?.quoteReinstatements
  ),
  riskAssignedLines: convertToAssignedLines(reinsurer.riskAssignedLinesLink),
  marketTpRef: reinsurer.tpRef,
  riskSubjectivityLink: convertToSubjectivities(reinsurer.riskSubjectivityLink),
  layerCurrency: reinsurer.quoteFields?.layerCurrency,
  programGroupID: reinsurer.programGroupID,
  sharedLimitID: reinsurer.sharedLimitID,
  territorialScope: reinsurer.quoteFields?.territorialScope?.join(';'),
  vendor: reinsurer.quoteFields?.vendor,
  feeOrBrokerage: reinsurer.quoteFields?.feeOrBrokerage,
  structureFX: reinsurer.quoteFields?.structureFX,
  premiumFX: reinsurer.quoteFields?.premiumFX,
  premiumFXToUSD: reinsurer.quoteFields?.premiumFXToUSD,
  layerCategory: reinsurer.quoteFields?.layerCategory,
  layerClass: reinsurer.quoteFields?.layerClass,
  layerSubClass: reinsurer.quoteFields?.layerSubClass,
  excludeFromPricingCurve: reinsurer.quoteFields?.excludeFromPricingCurve,
  perils: reinsurer.quoteFields?.perils?.join(';'),
  modelVersion: reinsurer.quoteFields?.modelVersion,
  lossImpactedFromPreviousYear:
    reinsurer.quoteFields?.lossImpactedFromPreviousYear,
  cedingCommissionBasis: reinsurer.quoteFields?.cedingCommissionBasis,
  fee: reinsurer.quoteFields?.fee,
  rebate: reinsurer.quoteFields?.rebate,
  quoteProbabilityOfAttach: reinsurer.quoteFields?.quoteProbabilityOfAttach,
  quoteProbabilityOfExhaust: reinsurer.quoteFields?.quoteProbabilityOfExhaust,
  quoteIndex: reinsurer.quoteFields?.quoteIndex,
  isPreferred: reinsurer.isPreferred,
  reType: reinsurer.reType,
  sequenceNumber: reinsurer.sequenceNumber,
  manualExpectedCededLoss: reinsurer.quoteFields.manualExpectedCededLoss,
  manualExpectedCededPremium: reinsurer.quoteFields.manualExpectedCededPremium,
  originalExpectedCededPremium:
    reinsurer.quoteFields.originalExpectedCededPremium,
  originalExpectedCededLoss: reinsurer.quoteFields.originalExpectedCededLoss,
})
const convertToReinstatement = (
  reinstatements?: Reinstatements[],
  riskReinsurerId?: number
): ReinstatementResponse[] => {
  const reinstatementsArray: Array<ReinstatementResponse> = []
  if (reinstatements) {
    for (const sub of reinstatements) {
      if (sub.premium !== undefined) {
        reinstatementsArray.push({
          id: sub.id,
          ripPct: sub.premium,
          riskReinsurerId,
        })
      }
    }
  }
  return reinstatementsArray
}
const convertToAssignedLines = (
  assignedLines?: AssignedLines[],
  riskReinsurerId?: number
): AssignedLinesResponse[] => {
  const assignedLinesArray: Array<AssignedLinesResponse> = []
  if (assignedLines) {
    for (const sub of assignedLines) {
      const id =
        sub.id && !sub.id.startsWith(ASSIGNED_LINES_TEMP_PREFIX)
          ? parseInt(sub.id, 10)
          : undefined
      assignedLinesArray.push({
        id,
        reinsurer: sub.reinsurer,
        bureaus: sub.bureaus,
        written: sub.written,
        recommended: sub.recommended,
        signed: sub.signed,
        signedOfAuthorized: sub.signedOfAuthorized,
        placedThrough: sub.placedThrough,
        brokerage: sub.brokerage,
        brokerageRe: sub.brokerageRe,
        co_broker: sub.coBroker || '',
        leadMarket: sub.leadMarket || '',
        co_broker_ref: sub.coBrokerRef || '',
        underwriterRef: sub.underwriterRef || '',
        marketTpRef: sub.marketTpRef,
        relationSeqNumber: sub.relationSeqNumber,
        contract: sub.contract,
        subjectivity: sub.subjectivity,
        whiteSpaceWSetID: sub.whiteSpaceWSetID,
        whiteSpaceSyncedAt: sub.whiteSpaceSyncedAt,
        whiteSpaceSyncedBy: sub.whiteSpaceSyncedBy,
        layerRef: sub.layerRef,
        riskRef: sub.riskRef,
        riskReinsurerId,
        placeholder: sub.placeholder,
        whiteSpaceImpressionIdx: sub.whiteSpaceImpressionIdx,
        whiteSpaceBusinessUnit: sub.whiteSpaceBusinessUnit,
        brokerageCoBrokerShare: sub.brokerageCoBrokerShare,
        brokerageReCoBrokerShare: sub.brokerageReCoBrokerShare,
      })
    }
  }
  return assignedLinesArray
}
const convertToSubjectivities = (
  reinsurerSubjectivities?: ReinsurerSubjectivity[]
): ReinsurerSubjectivityResponse[] => {
  const subjectivityArray: Array<ReinsurerSubjectivityResponse> = []
  if (reinsurerSubjectivities) {
    for (const sub of reinsurerSubjectivities) {
      const riskSubjectivityId =
        sub.riskSubjectivityId &&
        !sub.riskSubjectivityId.startsWith(SUBJECTIVITY_TEMP_PREFIX)
          ? parseInt(sub.riskSubjectivityId, 10)
          : undefined
      subjectivityArray.push({
        riskReinsurerId: sub.riskReinsurerId
          ? parseInt(sub.riskReinsurerId, 10)
          : undefined,
        riskSubjectivityId,
        riskSubjectivity: {
          id: riskSubjectivityId,
          subjectivity: sub.riskSubjectivity.type,
          reinsCommentary: sub.riskSubjectivity.reinsCommentary,
          brokerComments: sub.riskSubjectivity.brokerComments,
          reinsResponse: sub.riskSubjectivity.reinsurerResponse || '',
          reinsResponseStatus: sub.riskSubjectivity.reinsResponseStatus,
          acceptedLineCond: sub.riskSubjectivity.acceptedLineCond,
          acceptedContractWord: sub.riskSubjectivity.acceptedContractWord,
          clientAccepted: sub.riskSubjectivity.clientAccepted,
          ralId: sub.riskSubjectivity.ralId
            ? Number(sub.riskSubjectivity.ralId)
            : undefined,
          reinsurer: sub.riskSubjectivity.reinsurer,
          applyToStructureForSameMarket:
            sub.riskSubjectivity.applyToStructureForSameMarket,
          deadline: sub.riskSubjectivity.deadline,
          marketTpRef: sub.riskSubjectivity.marketTpRef,
          riskRef: sub.riskSubjectivity.riskRef,
          riskReinsurerId: sub.riskSubjectivity.riskReinsurerId,
          vendorIdentifier: sub.riskSubjectivity.vendorIdentifier,
          vendorName: sub.riskSubjectivity.vendorName,
        },
      })
    }
  }
  return subjectivityArray
}

export const convertSubjectivityLinkToRequestPost = (
  data: Partial<ReinsurerSubjectivity>
): Partial<ReinsurerSubjectivityResponse> => ({
  riskReinsurerId: data.riskReinsurerId
    ? parseInt(data.riskReinsurerId, 10)
    : undefined,
  riskSubjectivityId: data.riskSubjectivityId
    ? parseInt(data.riskSubjectivityId, 10)
    : undefined,
})
export const convertSubjectivityToRequest = (
  subjectivity: Partial<Subjectivity>
): Partial<SubjectivityResponse> => ({
  id:
    subjectivity.id && !subjectivity.id.startsWith(SUBJECTIVITY_TEMP_PREFIX)
      ? parseInt(subjectivity.id, 10)
      : undefined,
  subjectivity: subjectivity.type,
  reinsCommentary: subjectivity.reinsCommentary,
  brokerComments: subjectivity.brokerComments,
  reinsResponse: subjectivity.reinsurerResponse,
  reinsResponseStatus: subjectivity.reinsResponseStatus,
  acceptedLineCond: subjectivity.acceptedLineCond,
  acceptedContractWord: subjectivity.acceptedContractWord,
  clientAccepted: subjectivity.clientAccepted,
  reinsurer: subjectivity.reinsurer,
  ralId: subjectivity.ralId ? Number(subjectivity.ralId) : undefined,
  riskReinsurerId: subjectivity.riskReinsurerId,
  marketTpRef: subjectivity.marketTpRef,
  riskRef: subjectivity.riskRef,
  vendorIdentifier: subjectivity.vendorIdentifier,
  deadline: subjectivity.deadline,
  applyToStructureForSameMarket: subjectivity.applyToStructureForSameMarket,
})

/* From Response */
export const convertReinsurersFromResponse = (
  res: ReinsurerResponse[]
): QuoteReinsurer[] => {
  return res.map(convertReinsurerFromResponse)
}
export const convertReinsurerFromResponse = (
  res: ReinsurerResponse
): QuoteReinsurer => ({
  id: res.id.toString(),
  quoteReinsurerName: res.reinsurerName,
  cededlayerID: res.layerRef,
  newCededLayerForViewID: res.newCededLayerForViewID,
  reinsurerPhase: res.riskSectionPhase,
  reinsurerPhaseVersion: res.riskSectionPhaseVersion,
  reinsurerPhaseLabel: res.riskSectionPhaseLabel,
  quoteFields: getQuoteFiled(res),
  reinsurerSectionId: res.riskSectionId,
  decline: res.decline,
  declineReason: res.declineReason,
  exportToggle: res.exportToggle,
  riskSubjectivityLink: convertFromSubjectivities(res.riskSubjectivityLink),
  riskAssignedLinesLink: convertFromAssignedLines(res.riskAssignedLines ?? []),
  slidingScale: getSlidingTable(res),
  tpRef: res.marketTpRef,
  programGroupID: res.programGroupID,
  sharedLimitID: res.sharedLimitID,
  isPreferred: res.isPreferred,
  reType: res.reType,
  sequenceNumber: res.sequenceNumber,
})
const getQuoteFiled = (res: ReinsurerResponse): QuoteFields => {
  return {
    quoteOccurrenceLimit: convertMonetaryUnit(
      res.occurrenceLimit,
      res.layerCurrency
    ),
    quoteTopOccurrenceLimit: convertMonetaryUnit(
      res.topOccurrenceLimit,
      res.layerCurrency
    ),
    quoteOccurrenceAttachment: convertMonetaryUnit(
      res.occurrenceAttachment,
      res.layerCurrency
    ),
    quoteTopOccurrenceAttachment: convertMonetaryUnit(
      res.topOccurrenceAttachment,
      res.layerCurrency
    ),
    quoteDropOccurrenceLimit: convertMonetaryUnit(
      res.dropOccurrenceLimit,
      res.layerCurrency
    ),
    quoteDropOccurrenceAttachment: convertMonetaryUnit(
      res.dropOccurrenceAttachment,
      res.layerCurrency
    ),
    quoteAggregateLimitTop: convertMonetaryUnit(
      res.topAggregateLimit,
      res.layerCurrency
    ),
    quoteAggregateLimitDrop: convertMonetaryUnit(
      res.dropAggregateLimit,
      res.layerCurrency
    ),
    quoteRiskLimit: convertMonetaryUnit(res.riskLimit, res.layerCurrency),
    quoteRiskAttachment: convertMonetaryUnit(
      res.riskAttachment,
      res.layerCurrency
    ),
    quoteFranchiseDeductible: convertMonetaryUnit(
      res.franchiseDeductible,
      res.layerCurrency
    ),
    quoteReinstatements: convertFromReinstatement(res.riskReinstatements),
    quoteAggregateLimit: convertMonetaryUnit(
      res.aggregateLimit,
      res.layerCurrency
    ),
    quoteAggregateAttachment: convertMonetaryUnit(
      res.aggregateAttachment,
      res.layerCurrency
    ),
    quoteCessionPercentage: res.cessionPercentage,
    quoteCedingCommission: res.cedingCommission,
    quoteReinsurerExpenseProvision: res.reinsurerExpenseProvision,
    quoteProfitCommission: res.profitCommission,
    quoteRolPercentage: res.rolPercentage,
    quoteRateOnLineSubject: res.rateOnLineSubject,
    quotePmpm: res.pmpm,
    quotePremium: convertMonetaryUnit(res.premium, res.layerCurrency),
    quoteEffectiveDate: res.quoteEffectiveDate,
    quoteExpirationDate: res.quoteExpirationDate,
    quoteExpiryDate: res.quoteExpiryDate,
    quotePayout: convertMonetaryUnit(res.payout, res.layerCurrency),
    quoteTrigger: convertMonetaryUnit(res.trigger, res.layerCurrency),
    quoteNth: res.nth,
    underwriter: res.uwrSLipRoleEnum,
    maolLimit: convertMonetaryUnit(res.maolLimit, res.layerCurrency),
    terrorismAggSubLimit: convertMonetaryUnit(
      res.terrorismAggSubLimit,
      res.layerCurrency
    ),
    lossRatioCap: convertMonetaryUnit(res.lossRatioCap, res.layerCurrency),
    lossRatioCapPercentage: res.lossRatioCapPercentage,
    lossCapApplication: res.lossCapApplication,
    limitApplication: res.limitApplication,
    laeCap: convertMonetaryUnit(res.laeCap, res.layerCurrency),
    laeTreatment: res.laeTreatment,
    adjustmentBasis: res.adjustmentBasis,
    ecoCoveragePct: res.ecoCoveragePct,
    xplCoveragePct: res.xplCoveragePct,
    subjectPremium: convertMonetaryUnit(res.subjectPremium, res.layerCurrency),
    depositPremiumPercentage: res.depositPremiumPercentage,
    minimumPremiumPercentage: res.minimumPremiumPercentage,
    minimumPremium: convertMonetaryUnit(res.minimumPremium, res.layerCurrency),
    clashPremium: convertMonetaryUnit(res.clashPremium, res.layerCurrency),
    profitShareCommission: res.profitShareCommission,
    profitShareMinRate: res.profitShareMinRate,
    profitShareMaxRate: res.profitShareMaxRate,
    brokerageCommission: res.brokerageCommission,
    brokerageType: res.brokerageType,
    brokerageRIPCommission: res.brokerageRIPCommission,
    orderPercent: res.orderPercent,
    otherFeatures: res.otherFeatures,
    coverageBasis: res.coverageBasis,
    indexationtext: res.indexationtext,
    quoteSignedPercentage: res.signedPercentage,
    quoteMinPercentage: res.minPercentage,
    quoteOfferedPercentage: res.offeredPercentage,
    quoteOfferedLimit: convertMonetaryUnit(res.offeredLimit, res.layerCurrency),
    xplEcoDropdown: res.xplEcoDropdown,
    xplEcoConditions: res.xplEcoConditions,
    isLimitUnlimited: res.isLimitUnlimited,
    isAggregateUnlimited: res.isAggregateUnlimited,
    quoteDepositPremium: convertMonetaryUnit(
      res.quoteDepositPremium,
      res.layerCurrency
    ),
    quoteCessionsBasedPremium: res.quoteCessionsBasedPremium,
    quoteMinRateSubject: res.quoteMinRateSubject,
    quoteMaxRateSubject: res.quoteMaxRateSubject,
    quoteMinRatePmpm: res.quoteMinRatePmpm,
    quoteMaxRatePmpm: res.quoteMaxRatePmpm,
    quoteSwingRate: res.quoteSwingRate,
    quoteSwingBasis: res.quoteSwingBasis,
    quoteIndexation: res.quoteIndexation,
    quoteFixedIndexValue: res.quoteFixedIndexValue,
    quoteSicOrFranchise: res.quoteSicOrFranchise,
    quoteExpectedCededLoss: convertMonetaryUnit(
      res.quoteExpectedCededLoss,
      res.layerCurrency
    ),
    quoteExpectedCededPremium: convertMonetaryUnit(
      res.quoteExpectedCededPremium,
      res.layerCurrency
    ),
    totalQuoteExpectedCededLoss: 0,
    totalQuoteExpectedCededPremium: 0,
    quoteDepositPremiumCalc: convertMonetaryUnit(
      res.quoteDepositPremiumCalc,
      res.layerCurrency
    ),
    slidingComm: res.slidingComm,
    layerCurrency: res.layerCurrency || 'USD',
    territorialScope:
      typeof res.territorialScope === 'string'
        ? res.territorialScope.split(';')
        : res.territorialScope,
    vendor: res.vendor,
    feeOrBrokerage: res.feeOrBrokerage,
    structureFX: res.structureFX,
    premiumFX: res.premiumFX,
    premiumFXToUSD: res.premiumFXToUSD,
    layerCategory: res.layerCategory,
    layerClass: res.layerClass,
    layerSubClass: res.layerSubClass,
    excludeFromPricingCurve: res.excludeFromPricingCurve,
    perils: typeof res.perils === 'string' ? res.perils.split(';') : res.perils,
    modelVersion: res.modelVersion,
    lossImpactedFromPreviousYear: res.lossImpactedFromPreviousYear,
    cedingCommissionBasis: res.cedingCommissionBasis,
    fee: res.fee,
    rebate: res.rebate,
    quoteProbabilityOfAttach: res.quoteProbabilityOfAttach,
    quoteProbabilityOfExhaust: res.quoteProbabilityOfExhaust,
    quoteIndex: res.quoteIndex,
    manualExpectedCededLoss: res.manualExpectedCededLoss,
    manualExpectedCededPremium: res.manualExpectedCededPremium,
    originalExpectedCededPremium: res.originalExpectedCededPremium,
    originalExpectedCededLoss: res.originalExpectedCededLoss,
  }
}
const convertFromSubjectivities = (
  reinsurerSubjectivities: ReinsurerSubjectivityResponse[]
): ReinsurerSubjectivity[] => {
  const subjectivityArray: Array<ReinsurerSubjectivity> = []
  if (reinsurerSubjectivities && reinsurerSubjectivities.length > 0) {
    for (const sub of reinsurerSubjectivities) {
      subjectivityArray.push({
        riskReinsurerId: sub.riskReinsurerId?.toString(),
        riskSubjectivityId: sub.riskSubjectivityId?.toString(),
        riskSubjectivity: {
          id: sub.riskSubjectivity.id?.toString(),
          type: sub.riskSubjectivity.subjectivity,
          reinsCommentary: sub.riskSubjectivity.reinsCommentary,
          brokerComments: sub.riskSubjectivity.brokerComments,
          reinsurerResponse: sub.riskSubjectivity.reinsResponse,
          reinsResponseStatus: sub.riskSubjectivity.reinsResponseStatus,
          acceptedLineCond: sub.riskSubjectivity.acceptedLineCond,
          acceptedContractWord: sub.riskSubjectivity.acceptedContractWord,
          clientAccepted: sub.riskSubjectivity.clientAccepted,
          reinsurer: sub.riskSubjectivity.reinsurer,
          deadline: sub.riskSubjectivity.deadline,
          ralId: sub.riskSubjectivity.ralId
            ? Number(sub.riskSubjectivity.ralId)
            : 0,
          riskRef: sub.riskSubjectivity.riskRef,
          riskReinsurerId: sub.riskSubjectivity.riskReinsurerId,
          marketTpRef: sub.riskSubjectivity.marketTpRef,
          vendorIdentifier: sub.riskSubjectivity.vendorIdentifier,
          applyToStructureForSameMarket:
            sub.riskSubjectivity.applyToStructureForSameMarket,
          vendorName: sub.riskSubjectivity.vendorName,
        },
      })
    }
  }
  return subjectivityArray
}
const convertFromAssignedLines = (
  assignedLines: AssignedLinesResponse[]
): AssignedLines[] => {
  const assignedLinesArray: Array<AssignedLines> = []
  for (const sub of assignedLines) {
    assignedLinesArray.push({
      id: sub.id?.toString(),
      reinsurer: sub.reinsurer,
      bureaus: sub.bureaus,
      written: sub.written,
      recommended: sub.recommended,
      signed: sub.signed,
      signedOfAuthorized: sub.signedOfAuthorized || 0,
      placedThrough: sub.placedThrough || 'Lockton Re',
      brokerage: sub.brokerage,
      brokerageRe: sub.brokerageRe || 0,
      marketTpRef: sub.marketTpRef,
      coBroker: sub.co_broker || '',
      leadMarket: sub.leadMarket || '',
      coBrokerRef: sub.co_broker_ref || '',
      underwriterRef: sub.underwriterRef || '',
      relationSeqNumber: sub.relationSeqNumber,
      contract: sub.contract || '',
      subjectivity: sub.subjectivity,
      whiteSpaceWSetID: sub.whiteSpaceWSetID,
      whiteSpaceSyncedAt: sub.whiteSpaceSyncedAt,
      whiteSpaceSyncedBy: sub.whiteSpaceSyncedBy,
      layerRef: sub.layerRef,
      riskRef: sub.riskRef,
      placeholder: sub.placeholder,
      whiteSpaceImpressionIdx: sub.whiteSpaceImpressionIdx,
      whiteSpaceBusinessUnit: sub.whiteSpaceBusinessUnit,
      brokerageCoBrokerShare: sub.brokerageCoBrokerShare,
      brokerageReCoBrokerShare: sub.brokerageReCoBrokerShare,
    })
  }
  return assignedLinesArray
}
const getSlidingTable = (res: ReinsurerResponse): SlidingScale[] => {
  return [
    {
      id: 1,
      type: 'Min',
      commission: res.minCommission ? res.minCommission : 0,
      lossRatio: res.minLossRatio ? res.minLossRatio : 0,
      slideRate: res.minSlideRate ? res.minSlideRate : 0,
    },
    {
      id: 2,
      type: 'Provisional',
      commission: res.provCommission ? res.provCommission : 0,
      lossRatio: res.provLossRatio ? res.provLossRatio : 0,
      slideRate: undefined,
    },
    {
      id: 3,
      type: 'Max',
      commission: res.maxCommission ? res.maxCommission : 0,
      lossRatio: res.maxLossRatio ? res.maxLossRatio : 0,
      slideRate: res.maxSlideRate ? res.maxSlideRate : 0,
    },
  ]
}

export const convertSubjectivitiesFromResponse = (
  res: SubjectivityResponse
): Subjectivity => ({
  id: res.id?.toString(),
  type: res.subjectivity,
  reinsCommentary: res.reinsCommentary,
  brokerComments: res.brokerComments,
  reinsurerResponse: res.reinsResponse,
  reinsResponseStatus: res.reinsResponseStatus,
  acceptedLineCond: res.acceptedLineCond,
  acceptedContractWord: res.acceptedContractWord,
  clientAccepted: res.clientAccepted,
  reinsurer: res.reinsurer,
  ralId: res.ralId ? Number(res.ralId) : 0,
  riskReinsurerId: res.riskReinsurerId,
  marketTpRef: res.marketTpRef,
  riskRef: res.riskRef,
  vendorIdentifier: res.vendorIdentifier,
  deadline: res.deadline,
  applyToStructureForSameMarket: res.applyToStructureForSameMarket,
  vendorName: res.vendorName,
})

const convertFromReinstatement = (
  reinstatements: ReinstatementResponse[]
): Reinstatements[] => {
  const reinstatementsArray: Array<Reinstatements> = []
  for (const sub of reinstatements) {
    reinstatementsArray.push({
      id: sub.id,
      premium: sub.ripPct,
      brokerage: 0,
    })
  }
  return reinstatementsArray
}

export const convertSectionsFromResponse = (
  res: SectionResponse[]
): Section => {
  if (res[0] && res[0] !== null) {
    return {
      id: res[0].id.toString(),
      layerRef: res[0].layerRef,
      structureID: res[0].structureID,
      riskRef: res[0].riskRef,
      occurrenceLimitToggle: res[0].occurrenceLimitToggle,
      occurrenceAttachmentToggle: res[0].occurrenceAttachmentToggle,
      riskLimitToggle: res[0].riskLimitToggle,
      riskAttachmentToggle: res[0].riskAttachmentToggle,
      franchiseDeductibleToggle: res[0].franchiseDeductibleToggle,
      reinstatementsToggle: res[0].reinstatementsToggle,
      aggregateLimitToggle: res[0].aggregateLimitToggle,
      aggregateAttachmentToggle: res[0].aggregateAttachmentToggle,
      cedingCommissionToggle: res[0].cedingCommissionToggle,
      reinsurerExpenseProvisionToggle: res[0].reinsurerExpenseProvisionToggle,
      profitCommissionToggle: res[0].profitCommissionToggle,
      rolPercentageToggle: res[0].rolPercentageToggle,
      rateOnLineSubjectToggle: res[0].rateOnLineSubjectToggle,
      pmpmToggle: res[0].pmpmToggle,
      premiumToggle: res[0].premiumToggle,
      effectiveDateToggle: res[0].effectiveDateToggle,
      expirationDateToggle: res[0].expirationDateToggle,
      expiryDateToggle: res[0].expiryDateToggle,
      payoutToggle: res[0].payoutToggle,
      triggerToggle: res[0].triggerToggle,
      nthToggle: res[0].nthToggle,
      underwriterToggle: res[0].underwriterToggle,
      maolLimitToggle: res[0].maolLimitToggle,
      terrorismAggSubLimitToggle: res[0].terrorismAggSubLimitToggle,
      lossRatioCapToggle: res[0].lossRatioCapToggle,
      lossRatioCapPercentageToggle: res[0].lossRatioCapPercentageToggle,
      lossCapApplicationToggle: res[0].lossCapApplicationToggle,
      limitApplicationToggle: res[0].limitApplicationToggle,
      laeCapToggle: res[0].laeCapToggle,
      laeTreatmentToggle: res[0].laeTreatmentToggle,
      ecoCoveragePctToggle: res[0].ecoCoveragePctToggle,
      xplCoveragePctToggle: res[0].xplCoveragePctToggle,
      subjectPremiumToggle: res[0].subjectPremiumToggle,
      depositPremiumPercentageToggle: res[0].depositPremiumPercentageToggle,
      minimumPremiumPercentageToggle: res[0].minimumPremiumPercentageToggle,
      minimumPremiumToggle: res[0].minimumPremiumToggle,
      clashPremiumToggle: res[0].clashPremiumToggle,
      profitShareCommissionToggle: res[0].profitShareCommissionToggle,
      profitShareMinRateToggle: res[0].profitShareMinRateToggle,
      profitShareMaxRateToggle: res[0].profitShareMaxRateToggle,
      brokerageCommissionToggle: res[0].brokerageCommissionToggle,
      brokerageTypeToggle: res[0].brokerageTypeToggle,
      brokerageRIPCommissionToggle: res[0].brokerageRIPCommissionToggle,
      orderPercentToggle: res[0].orderPercentToggle,
      otherFeaturesToggle: res[0].otherFeaturesToggle,
      coverageBasisToggle: res[0].coverageBasisToggle,
      indexationtextToggle: res[0].indexationtextToggle,
      // quoteSignedPercentageToggle: res[0].quoteSignedPercentageToggle,
      quoteMinPercentageToggle: res[0].quoteMinPercentageToggle,
      quoteOfferedPercentageToggle: res[0].quoteOfferedPercentageToggle,
      quoteOfferedLimitToggle: res[0].quoteOfferedLimitToggle,
      xplEcoDropdownToggle: res[0].xplEcoDropdownToggle,
      xplEcoConditionsToggle: res[0].xplEcoConditionsToggle,
      quoteDepositPremiumToggle: res[0].quoteDepositPremiumToggle,
      quoteCessionsBasedPremiumToggle: res[0].quoteCessionsBasedPremiumToggle,
      quoteMinRateSubjectToggle: res[0].quoteMinRateSubjectToggle,
      quoteMaxRateSubjectToggle: res[0].quoteMaxRateSubjectToggle,
      quoteMinRatePmpmToggle: res[0].quoteMinRatePmpmToggle,
      quoteMaxRatePmpmToggle: res[0].quoteMaxRatePmpmToggle,
      quoteSwingRateToggle: res[0].quoteSwingRateToggle,
      quoteSwingBasisToggle: res[0].quoteSwingBasisToggle,
      quoteIndexationToggle: res[0].quoteIndexationToggle,
      quoteFixedIndexValueToggle: res[0].quoteFixedIndexValueToggle,
      quoteSicOrFranchiseToggle: res[0].quoteSicOrFranchiseToggle,
      quoteExpectedCededLossToggle: res[0].quoteExpectedCededLossToggle,
      quoteExpectedCededPremiumToggle: res[0].quoteExpectedCededPremiumToggle,
      quoteDepositPremiumCalcToggle: res[0].quoteDepositPremiumCalcToggle,
      slidingCommToggle: res[0].slidingCommToggle,
      layerName: res[0].layerName,
      layerType: res[0].layerType,
      layerCurrency: res[0].layerCurrency,
      programID: res[0].programID,
      programGroupID: res[0].programGroupID,
      sharedLimitID: res[0].sharedLimitID,
      territorialScopeToggle: res[0].territorialScopeToggle,
      vendorToggle: res[0].vendorToggle,
      feeOrBrokerageToggle: res[0].feeOrBrokerageToggle,
      structureFXToggle: res[0].structureFXToggle,
      premiumFXToggle: res[0].premiumFXToggle,
      premiumFXToUSDToggle: res[0].premiumFXToUSDToggle,
      layerCategoryToggle: res[0].layerCategoryToggle,
      layerClassToggle: res[0].layerClassToggle,
      excludeFromPricingCurveToggle: res[0].excludeFromPricingCurveToggle,
      perilsToggle: res[0].perilsToggle,
      modelVersionToggle: res[0].modelVersionToggle,
      lossImpactedFromPreviousYearToggle:
        res[0].lossImpactedFromPreviousYearToggle,
      cedingCommissionBasisToggle: res[0].cedingCommissionBasisToggle,
      feeToggle: res[0].feeToggle,
      rebateToggle: res[0].rebateToggle,
      quoteProbabilityOfAttachToggle: res[0].quoteProbabilityOfAttachToggle,
      quoteProbabilityOfExhaustToggle: res[0].quoteProbabilityOfExhaustToggle,
      quoteTopOccurrenceAttachmentToggle:
        res[0].quoteTopOccurrenceAttachmentToggle,
      quoteTopOccurrenceLimitToggle: res[0].quoteTopOccurrenceLimitToggle,
      quoteDropOccurrenceAttachmentToggle:
        res[0].quoteDropOccurrenceAttachmentToggle,
      quoteDropOccurrenceLimitToggle: res[0].quoteDropOccurrenceLimitToggle,
      quoteAggregateLimitTopToggle: res[0].quoteAggregateLimitTopToggle,
      quoteAggregateLimitDropToggle: res[0].quoteAggregateLimitDropToggle,
      quoteIndexToggle: res[0].quoteIndexToggle,
      fotCount: res[0].fotCount,
      quoteCount: res[0].quoteCount,
    }
  } else {
    return {
      id: '',
      layerRef: '',
      structureID: 0,
      layerName: '',
      layerType: '',
      layerCurrency: 'USD',
    }
  }
}
const toSection = (response: SectionResponse): Section => ({
  id: String(response.id),
  layerRef: response.layerRef,
  structureID: response.structureID,
  riskRef: response.riskRef,
  occurrenceLimitToggle: response.occurrenceLimitToggle,
  occurrenceAttachmentToggle: response.occurrenceAttachmentToggle,
  riskLimitToggle: response.riskLimitToggle,
  riskAttachmentToggle: response.riskAttachmentToggle,
  franchiseDeductibleToggle: response.franchiseDeductibleToggle,
  reinstatementsToggle: response.reinstatementsToggle,
  aggregateLimitToggle: response.aggregateLimitToggle,
  aggregateAttachmentToggle: response.aggregateAttachmentToggle,
  cedingCommissionToggle: response.cedingCommissionToggle,
  reinsurerExpenseProvisionToggle: response.reinsurerExpenseProvisionToggle,
  profitCommissionToggle: response.profitCommissionToggle,
  rolPercentageToggle: response.rolPercentageToggle,
  rateOnLineSubjectToggle: response.rateOnLineSubjectToggle,
  pmpmToggle: response.pmpmToggle,
  premiumToggle: response.premiumToggle,
  effectiveDateToggle: response.effectiveDateToggle,
  expirationDateToggle: response.expirationDateToggle,
  expiryDateToggle: response.expiryDateToggle,
  payoutToggle: response.payoutToggle,
  triggerToggle: response.triggerToggle,
  nthToggle: response.nthToggle,
  underwriterToggle: response.underwriterToggle,
  maolLimitToggle: response.maolLimitToggle,
  terrorismAggSubLimitToggle: response.terrorismAggSubLimitToggle,
  lossRatioCapToggle: response.lossRatioCapToggle,
  lossRatioCapPercentageToggle: response.lossRatioCapPercentageToggle,
  lossCapApplicationToggle: response.lossCapApplicationToggle,
  limitApplicationToggle: response.limitApplicationToggle,
  laeCapToggle: response.laeCapToggle,
  laeTreatmentToggle: response.laeTreatmentToggle,
  ecoCoveragePctToggle: response.ecoCoveragePctToggle,
  xplCoveragePctToggle: response.xplCoveragePctToggle,
  subjectPremiumToggle: response.subjectPremiumToggle,
  depositPremiumPercentageToggle: response.depositPremiumPercentageToggle,
  minimumPremiumPercentageToggle: response.minimumPremiumPercentageToggle,
  minimumPremiumToggle: response.minimumPremiumToggle,
  clashPremiumToggle: response.clashPremiumToggle,
  profitShareCommissionToggle: response.profitShareCommissionToggle,
  profitShareMinRateToggle: response.profitShareMinRateToggle,
  profitShareMaxRateToggle: response.profitShareMaxRateToggle,
  brokerageCommissionToggle: response.brokerageCommissionToggle,
  brokerageTypeToggle: response.brokerageTypeToggle,
  brokerageRIPCommissionToggle: response.brokerageRIPCommissionToggle,
  orderPercentToggle: response.orderPercentToggle,
  otherFeaturesToggle: response.otherFeaturesToggle,
  coverageBasisToggle: response.coverageBasisToggle,
  indexationtextToggle: response.indexationtextToggle,
  // quoteSignedPercentageToggle: response.quoteSignedPercentageToggle,
  quoteMinPercentageToggle: response.quoteMinPercentageToggle,
  quoteOfferedPercentageToggle: response.quoteOfferedPercentageToggle,
  quoteOfferedLimitToggle: response.quoteOfferedLimitToggle,
  xplEcoDropdownToggle: response.xplEcoDropdownToggle,
  xplEcoConditionsToggle: response.xplEcoConditionsToggle,
  quoteDepositPremiumToggle: response.quoteDepositPremiumToggle,
  quoteCessionsBasedPremiumToggle: response.quoteCessionsBasedPremiumToggle,
  quoteMinRateSubjectToggle: response.quoteMinRateSubjectToggle,
  quoteMaxRateSubjectToggle: response.quoteMaxRateSubjectToggle,
  quoteMinRatePmpmToggle: response.quoteMinRatePmpmToggle,
  quoteMaxRatePmpmToggle: response.quoteMaxRatePmpmToggle,
  quoteSwingRateToggle: response.quoteSwingRateToggle,
  quoteSwingBasisToggle: response.quoteSwingBasisToggle,
  quoteIndexationToggle: response.quoteIndexationToggle,
  quoteFixedIndexValueToggle: response.quoteFixedIndexValueToggle,
  quoteSicOrFranchiseToggle: response.quoteSicOrFranchiseToggle,
  quoteExpectedCededLossToggle: response.quoteExpectedCededLossToggle,
  quoteExpectedCededPremiumToggle: response.quoteExpectedCededPremiumToggle,
  quoteDepositPremiumCalcToggle: response.quoteDepositPremiumCalcToggle,
  slidingCommToggle: response.slidingCommToggle,
  layerName: response.layerName,
  layerType: response.layerType,
  layerCurrency: response.layerCurrency,
  programID: response.programID,
  programGroupID: response.programGroupID,
  sharedLimitID: response.sharedLimitID,
  territorialScopeToggle: response.territorialScopeToggle,
  vendorToggle: response.vendorToggle,
  feeOrBrokerageToggle: response.feeOrBrokerageToggle,
  structureFXToggle: response.structureFXToggle,
  premiumFXToggle: response.premiumFXToggle,
  premiumFXToUSDToggle: response.premiumFXToUSDToggle,
  layerCategoryToggle: response.layerCategoryToggle,
  layerClassToggle: response.layerClassToggle,
  excludeFromPricingCurveToggle: response.excludeFromPricingCurveToggle,
  perilsToggle: response.perilsToggle,
  modelVersionToggle: response.modelVersionToggle,
  lossImpactedFromPreviousYearToggle:
    response.lossImpactedFromPreviousYearToggle,
  cedingCommissionBasisToggle: response.cedingCommissionBasisToggle,
  feeToggle: response.feeToggle,
  rebateToggle: response.rebateToggle,
  quoteProbabilityOfAttachToggle: response.quoteProbabilityOfAttachToggle,
  quoteProbabilityOfExhaustToggle: response.quoteProbabilityOfExhaustToggle,
  quoteIndexToggle: response.quoteIndexToggle,
  quoteTopOccurrenceAttachmentToggle:
    response.quoteTopOccurrenceAttachmentToggle,
  quoteTopOccurrenceLimitToggle: response.quoteTopOccurrenceLimitToggle,
  quoteDropOccurrenceAttachmentToggle:
    response.quoteDropOccurrenceAttachmentToggle,
  quoteDropOccurrenceLimitToggle: response.quoteDropOccurrenceLimitToggle,
  quoteAggregateLimitTopToggle: response.quoteAggregateLimitTopToggle,
  quoteAggregateLimitDropToggle: response.quoteAggregateLimitDropToggle,
  fotCount: response.fotCount,
  quoteCount: response.quoteCount,
})
export const convertSectionsFromResponseToArray = (res: SectionResponse[]) =>
  res.map(toSection)
export const convertSectionFromResponse = (res: SectionResponse): Section => ({
  id: res.id.toString(),
  layerRef: res.layerRef,
  structureID: res.structureID,
  riskRef: res.riskRef,
  occurrenceLimitToggle: res.occurrenceLimitToggle,
  occurrenceAttachmentToggle: res.occurrenceAttachmentToggle,
  riskLimitToggle: res.riskLimitToggle,
  riskAttachmentToggle: res.riskAttachmentToggle,
  franchiseDeductibleToggle: res.franchiseDeductibleToggle,
  reinstatementsToggle: res.reinstatementsToggle,
  aggregateLimitToggle: res.aggregateLimitToggle,
  aggregateAttachmentToggle: res.aggregateAttachmentToggle,
  cedingCommissionToggle: res.cedingCommissionToggle,
  reinsurerExpenseProvisionToggle: res.reinsurerExpenseProvisionToggle,
  profitCommissionToggle: res.profitCommissionToggle,
  rolPercentageToggle: res.rolPercentageToggle,
  rateOnLineSubjectToggle: res.rateOnLineSubjectToggle,
  pmpmToggle: res.pmpmToggle,
  premiumToggle: res.premiumToggle,
  effectiveDateToggle: res.effectiveDateToggle,
  expirationDateToggle: res.expirationDateToggle,
  expiryDateToggle: res.expiryDateToggle,
  payoutToggle: res.payoutToggle,
  triggerToggle: res.triggerToggle,
  nthToggle: res.nthToggle,
  underwriterToggle: res.underwriterToggle,
  maolLimitToggle: res.maolLimitToggle,
  terrorismAggSubLimitToggle: res.terrorismAggSubLimitToggle,
  lossRatioCapToggle: res.lossRatioCapToggle,
  lossRatioCapPercentageToggle: res.lossRatioCapPercentageToggle,
  lossCapApplicationToggle: res.lossCapApplicationToggle,
  limitApplicationToggle: res.limitApplicationToggle,
  laeCapToggle: res.laeCapToggle,
  laeTreatmentToggle: res.laeTreatmentToggle,
  ecoCoveragePctToggle: res.ecoCoveragePctToggle,
  xplCoveragePctToggle: res.xplCoveragePctToggle,
  subjectPremiumToggle: res.subjectPremiumToggle,
  depositPremiumPercentageToggle: res.depositPremiumPercentageToggle,
  minimumPremiumPercentageToggle: res.minimumPremiumPercentageToggle,
  minimumPremiumToggle: res.minimumPremiumToggle,
  clashPremiumToggle: res.clashPremiumToggle,
  profitShareCommissionToggle: res.profitShareCommissionToggle,
  profitShareMinRateToggle: res.profitShareMinRateToggle,
  profitShareMaxRateToggle: res.profitShareMaxRateToggle,
  brokerageCommissionToggle: res.brokerageCommissionToggle,
  brokerageTypeToggle: res.brokerageTypeToggle,
  brokerageRIPCommissionToggle: res.brokerageRIPCommissionToggle,
  orderPercentToggle: res.orderPercentToggle,
  otherFeaturesToggle: res.otherFeaturesToggle,
  coverageBasisToggle: res.coverageBasisToggle,
  indexationtextToggle: res.indexationtextToggle,
  // quoteSignedPercentageToggle: res.quoteSignedPercentageToggle,
  quoteMinPercentageToggle: res.quoteMinPercentageToggle,
  quoteOfferedPercentageToggle: res.quoteOfferedPercentageToggle,
  quoteOfferedLimitToggle: res.quoteOfferedLimitToggle,
  xplEcoDropdownToggle: res.xplEcoDropdownToggle,
  xplEcoConditionsToggle: res.xplEcoConditionsToggle,
  quoteDepositPremiumToggle: res.quoteDepositPremiumToggle,
  quoteCessionsBasedPremiumToggle: res.quoteCessionsBasedPremiumToggle,
  quoteMinRateSubjectToggle: res.quoteMinRateSubjectToggle,
  quoteMaxRateSubjectToggle: res.quoteMaxRateSubjectToggle,
  quoteMinRatePmpmToggle: res.quoteMinRatePmpmToggle,
  quoteMaxRatePmpmToggle: res.quoteMaxRatePmpmToggle,
  quoteSwingRateToggle: res.quoteSwingRateToggle,
  quoteSwingBasisToggle: res.quoteSwingBasisToggle,
  quoteIndexationToggle: res.quoteIndexationToggle,
  quoteFixedIndexValueToggle: res.quoteFixedIndexValueToggle,
  quoteSicOrFranchiseToggle: res.quoteSicOrFranchiseToggle,
  quoteExpectedCededLossToggle: res.quoteExpectedCededLossToggle,
  quoteExpectedCededPremiumToggle: res.quoteExpectedCededPremiumToggle,
  quoteDepositPremiumCalcToggle: res.quoteDepositPremiumCalcToggle,
  slidingCommToggle: res.slidingCommToggle,
  layerName: res.layerName,
  layerType: res.layerType,
  layerCurrency: res.layerCurrency,
  programID: res.programID,
  programGroupID: res.programGroupID,
  sharedLimitID: res.sharedLimitID,
  territorialScopeToggle: res.territorialScopeToggle,
  vendorToggle: res.vendorToggle,
  feeOrBrokerageToggle: res.feeOrBrokerageToggle,
  structureFXToggle: res.structureFXToggle,
  premiumFXToggle: res.premiumFXToggle,
  premiumFXToUSDToggle: res.premiumFXToUSDToggle,
  layerCategoryToggle: res.layerCategoryToggle,
  layerClassToggle: res.layerClassToggle,
  excludeFromPricingCurveToggle: res.excludeFromPricingCurveToggle,
  perilsToggle: res.perilsToggle,
  modelVersionToggle: res.modelVersionToggle,
  lossImpactedFromPreviousYearToggle: res.lossImpactedFromPreviousYearToggle,
  cedingCommissionBasisToggle: res.cedingCommissionBasisToggle,
  feeToggle: res.feeToggle,
  rebateToggle: res.rebateToggle,
  quoteProbabilityOfAttachToggle: res.quoteProbabilityOfAttachToggle,
  quoteProbabilityOfExhaustToggle: res.quoteProbabilityOfExhaustToggle,
  quoteIndexToggle: res.quoteIndexToggle,
  quoteTopOccurrenceAttachmentToggle: res.quoteTopOccurrenceAttachmentToggle,
  quoteTopOccurrenceLimitToggle: res.quoteTopOccurrenceLimitToggle,
  quoteDropOccurrenceAttachmentToggle: res.quoteDropOccurrenceAttachmentToggle,
  quoteDropOccurrenceLimitToggle: res.quoteDropOccurrenceLimitToggle,
  quoteAggregateLimitTopToggle: res.quoteAggregateLimitTopToggle,
  quoteAggregateLimitDropToggle: res.quoteAggregateLimitDropToggle,
  fotCount: res.fotCount,
  quoteCount: res.quoteCount,
})

export const convertRisksFromResponse = (res: RiskResponse[]): Risk => {
  if (res[0] && res[0] !== null) {
    return {
      id: res[0].id ? res[0].id.toString() : '0',
      structureID: res[0].structureID,
      fiscalYear: res[0].fiscalYear,
      programID: res[0].programID,
    }
  } else {
    return {
      id: '',
      structureID: 0,
      programID: 0,
    }
  }
}
export const convertRiskFromResponse = (res: RiskResponse): Risk => ({
  id: res.id ? res.id.toString() : '0',
  structureID: res.structureID,
  fiscalYear: res.fiscalYear,
  programID: res.programID,
})
