import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ofType } from '@ngrx/effects'
import { routerNavigatedAction } from '@ngrx/router-store'
import { ActionsSubject, Store } from '@ngrx/store'
import {keys, Merge, mergeDeepRight, mergeRight} from 'ramda'
import { ReplaySubject, Subscription } from 'rxjs'
import { delay, filter, take, tap, withLatestFrom } from 'rxjs/operators'
import { isMultiSectionLayer } from '../analysis/layers/multi-section-layer'
import { isSwingLayer } from '../analysis/layers/swing-layer'
import {
  Layer,
  LayerRef,
  PhysicalLayer,
  UNLIMITED_AMOUNT,
  UNLIMITED_DOLLARS,
  ZERO_DOLLARS,
} from '../analysis/model/layers.model'
import { isLayerFHCF, isLayerRisk } from '../analysis/model/layers.util'
import * as fromAnalysisActions from '../analysis/store/analysis.actions'
import { selectCededPortfolioState } from '../analysis/store/analysis.selectors'
import * as fromLayersActions from '../analysis/store/ceded-layers/layers.actions'
import {
  asMonetaryUnit,
  Metadata,
  Reinstatement,
} from '../api/analyzere/analyzere.model'
import {
  RiskRefWithSections,
  RiskReinstatement,
  RiskSectionWithMarkets,
} from '../api/model/backend.model'
import { BrokerStructuresContainerComponent } from '../broker/broker-structures/broker-structures.container'
import { Program } from '../core/model/program.model'
import { AppState } from '../core/store'
import * as fromBrokerActions from '../core/store/broker/broker.actions'
import { AutoBuildProgressDialogComponent } from './auto-build-progress-dialog/auto-build-progress-dialog.component'
import { generateUUID } from 'three/src/math/MathUtils'
import { isIndexedLayer } from '../analysis/layers/indexed-layer'
import { generateTemporaryId } from '@shared/layer-palette-item.container'
import * as ProgramActions from '../analysis/store/grouper/program/program.actions'
import * as ProgramGroupActions from '../analysis/store/grouper/program-group/program-group.actions'
import { saveGrouper } from '../analysis/store/grouper/grouper.actions'

export type StructuresSection = Record<string, StructureSection>
export type StructureSection = Record<string, LayerSection>
export type LayerSection = {
  section: RiskSectionWithMarkets | undefined
  totalPct: number
}
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

// Create a monetaryUnit for the value or the default value. If the default value is not
// specified, it is zero.
export const money = (v: number | undefined, currency?: string, d?: number) =>
  asMonetaryUnit(v ?? d ?? 0, currency ?? 'USD')

export class AutoBuildStructureCreator {
  private broker: BrokerStructuresContainerComponent | undefined
  private brokerSub: Subscription
  private progressDialog: MatDialogRef<AutoBuildProgressDialogComponent>
  private structureNum = 0
  private autobuildID: string
  private riskRefSections: RiskRefWithSections[]
  parentGroupId: string
  yearId: string
  programs: Program[] = []

  constructor(
    private actionsSubject$: ActionsSubject,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private client: string,
    private year: string,
    private program: string,
    private libreTemplate: Program,
    private structures: StructureSection[],
    broker$: ReplaySubject<BrokerStructuresContainerComponent | undefined>,
    autobuildID: string,
    riskRefSections: RiskRefWithSections[]
  ) {
    this.showProgress()
    // Each time we go from selection page to design, a new broker instance
    // will be created so will need to capture each new broker instance
    this.brokerSub = broker$.subscribe(broker => (this.broker = broker))
    this.autobuildID = autobuildID
    this.riskRefSections = riskRefSections
  }

  createNextStructure() {
    // For each time through this method we will get the next structure from
    // the ones the user selected
    const structureSection = this.getNextStructure()
    if (structureSection === undefined) {
      // If there are no more structures then we are done so close the
      // progress dialog and clean up

      if (this.programs.length > 1) {
        // Adding the new structures in their own group
        this.programs.forEach(program => {
          this.store.dispatch(ProgramActions.addProgramToGroup({ program }))
        })
        this.store.dispatch(
          ProgramGroupActions.addNewProgramGroup({
            isUntitled: true,
            label: `EXPIRING ${this.parentGroupId}`,
            parentGroupID: `untitled_${this.parentGroupId}`,
            programIDs: [],
            yearID: this.yearId,
          })
        )
        this.store.dispatch(saveGrouper())
        this.programs = []
      }
      this.closeProgress()
      this.brokerSub.unsubscribe()
      return
    }

    const label = `Expiring ${this.structureNum}`

    this.setCloningStep(label, 'Creating a new structure')
    this.cloneLibreTemplate(label)

    // Wait for the clone action to finish successfully
    this.actionsSubject$
      .pipe(
        ofType(fromAnalysisActions.saveAsAnalysisSuccess),
        take(1),
        withLatestFrom(this.store.select(selectCededPortfolioState)),
        delay(1000)
      )
      .subscribe(([{ program: structure }, portfolio]) => {
        // Get the layer sections
        const layerSections = keys(structureSection).map(
          k => structureSection[k]
        )

        // Build a Sage layer for each layer section
        const layers = layerSections.flatMap(
          layerSection =>
            this.buildLayer(
              portfolio.client ?? '',
              portfolio.year ?? '',
              structure.id,
              layerSection
            ) ?? []
        )

        // Now create each of the built layers
        this.createLayers(layers, structure)
      })
  }

  getNextStructure() {
    this.structureNum++
    return this.structures.shift()
  }

  private cloneLibreTemplate(label: string) {
    // Set the current context to the libre template
    this.store.dispatch(
      fromBrokerActions.setCurrentTierPath({
        client: this.client,
        year: this.year,
        program: this.program,
        structure: this.libreTemplate.id,
      })
    )

    // Clone the libreTemplate to create the new empty structure for the OT import
    this.store.dispatch(
      fromAnalysisActions.saveAsCloneAnalysis({
        analysisProfileID: this.libreTemplate.analysisID,
        parentGrossPortfolioID: this.libreTemplate.parentGrossPortfolioID,
        program: {
          id: this.libreTemplate.id,
          label,
          description: 'built using autoimport',
          studyID: this.program,
        },
        autobuildID: this.autobuildID,
        isClone: false
      })
    )
  }

  private createLayers(layers: Layer[], structure: Program) {
    const layerName = (layer: Layer) => layer?.meta_data.layerName ?? ''

    const correlationIds: string[] = []

    // Open the design page on the newly created structure
    this.broker?.onStructureSelectionChange(structure)

    // Wait until the page loads after navigation
    this.actionsSubject$
      .pipe(ofType(routerNavigatedAction), take(1))
      .subscribe(() => {
        this.setProgressRange(layers.length)
        this.addProgressStep(`Creating layer ${layerName(layers[0])}`)

        layers.forEach(layer => {
          // Generate a unique correlation id for this layer
          const cId = generateUUID()
          correlationIds.push(cId)
          layer.meta_data.correlationId = cId
          layer.physicalLayer.meta_data.correlationId = cId
          if (this.structureNum === 1) {
            this.parentGroupId = structure.id
          }
          this.yearId = structure.yearID
          this.programs.push(structure)

          // Add the layer on the next tick to make sure the design page has a
          // chance to fully load
          setImmediate(() => {
            if (isLayerFHCF(layer)) {
              this.store.dispatch(fromLayersActions.addFHCFLayer({ layer }))
            } else if (isLayerRisk(layer)) {
              this.store.dispatch(fromLayersActions.addRiskLayer({ layer }))
            } else if (isIndexedLayer(layer)) {
              this.store.dispatch(fromLayersActions.addIndexedLayer({ layer }))
            } else if (isSwingLayer(layer)) {
              this.store.dispatch(fromLayersActions.addSwingLayer({ layer }))
            } else {
              this.store.dispatch(fromLayersActions.addLayer({ layer }))
            }
          })
        })
      })

    // Wait for the add layer to succeed
    this.actionsSubject$
      .pipe(
        ofType(
          fromLayersActions.addLayerSuccess,
          fromLayersActions.updateRiskComplete
        ),
        filter(
          action =>
            // Only FHCF final
            !(
              action.type === fromLayersActions.addLayerSuccess.type &&
              isLayerFHCF(action.layer) &&
              !action.layer.meta_data.isFHCFFinal
            ) &&
            // Only Indexed visible-layer
            !(
              action.type === fromLayersActions.addLayerSuccess.type &&
              isIndexedLayer(action.layer) &&
              !isIndexedLayer(action.layer, 'visible-layer')
            ) &&
            // Only Swing combined-layer
            !(
              action.type === fromLayersActions.addLayerSuccess.type &&
              isSwingLayer(action.layer) &&
              !isSwingLayer(action.layer, 'combined-layer')
            ) &&
            // Only Multisection main-layer
            !(
              action.type === fromLayersActions.addLayerSuccess.type &&
              isMultiSectionLayer(action.layer) &&
              !isMultiSectionLayer(action.layer, 'main-layer')
            ) &&
            // Skip the Risk addLayerSuccess and wait for the updateRiskComplete
            !(
              action.type === fromLayersActions.addLayerSuccess.type &&
              isLayerRisk(action.layer)
            )
        ),
        take(layers.length)
      )
      .subscribe(action => {
        // Get the correlation id from the action
        const cId =
          action.type === fromLayersActions.updateRiskComplete.type
            ? action.cId ?? ''
            : action.layer.meta_data.correlationId ?? ''

        // Find the matching correlation id
        const i = correlationIds.indexOf(cId)
        if (i !== -1) {
          // Remove the matching correlation id since adding the layer suceeded
          correlationIds.splice(i, 1)

          if (correlationIds.length > 0) {
            // Still more layers to create. The current layer finished so add
            // the step for the next step.
            const layerNum = layers.length - correlationIds.length
            this.setProgress(layerNum)
            this.addProgressStep(
              `Creating layer ${layerName(layers[layerNum])}`
            )
          } else {
            // All the layers were create so save the structure
            this.setProgressSaving('Saving the new structure')

            // Save the structure. Wait a tick so the UI has a chance to update.
            setImmediate(() => {
              this.store.dispatch(fromAnalysisActions.saveAnalysis())
            })

            // Wait for the save to complete. The startAnalysis action is the last
            // step for a save so when this action comes in, the save is complete.
            this.actionsSubject$
              .pipe(
                ofType(fromAnalysisActions.startAnalysis),
                take(1),
                tap(() => this.setProgressSaveComplete()),
                delay(100)
              )
              .subscribe(() => {
                // Return to the structure selection screen
                this.router.navigate(['/home'])

                // Once that screen has loaded, see if there are more structures
                // to create.
                this.actionsSubject$
                  .pipe(ofType(routerNavigatedAction), take(1))
                  .subscribe(() => {
                    this.createNextStructure()
                  })
              })
          }
        }
      })
  }

  buildLayer(
    client: string,
    year: string,
    structureId: string,
    layerSection: LayerSection
  ): Layer | undefined {
    const riskSection = layerSection.section
    if (riskSection === undefined) {
      return
    }

    // Build a general layer to use as the defaults
    const generalLayer = this.buildLayerGeneral(
      client,
      year,
      structureId,
      layerSection.totalPct,
      riskSection
    )

    // Build the user selected layer type
    const layerType = this.getLayerType(riskSection.selectedLayer)
    const selectedLayer = mergeDeepRight(
      generalLayer,
      this.buildLayerType(layerType, riskSection)
    ) as Layer

    return selectedLayer
  }

  buildLayerGeneral(
    client: string,
    year: string,
    structureID: string,
    totalPct: number,
    rs: RiskSectionWithMarkets
  ): Merge<Partial<Layer>, {
    lossSetLayers: any[];
    physicalLayer: PhysicalLayer;
    sharedLayerID: string;
    layerRefs: any[];
    meta_data: {};
    viewMetrics: { rss: null; metrics: null; loading: boolean; error: null };
    lossSetFilter: string;
    id: string
  }, "deep"> {
    const id = generateTemporaryId()
    const currency = rs.baseCurrencyCode
    const layerRefs = ['fef71910-d626-4e5e-8691-fedc26e44f74'] // dummy loss set
    const layer = this.buildLayerWithDefaults({
      id,
      currency,
      layerRefs,
      physicalLayer: this.buildPhysicalWithDefaults(currency, {
        id: generateTemporaryId(),
        attachment: money(rs.occurrenceAttachment, currency),
        limit: money(rs.occurrenceLimit, currency),
        participation: -totalPct / 100,
        premium: money(0, currency),
        aggregateAttachment: money(rs.annualAggregateDeductible, currency),
        aggregateLimit: rs.unlimitedLiabilityIndicator
          ? UNLIMITED_DOLLARS
          : money(rs.maximumLiability, currency),
        reinstatements: this.getReinstatements(rs.reinstatements),
        description: rs.sectionNarrative,
        logicalLayerID: id,
        meta_data: this.buildMetaWithDefaults(client, year, rs.selectedLayer, {
          isAutoBuild: true,
          autoBuildSections: this.filterAndStringifyRiskRefSections(rs),
        }),
      }),
      meta_data: this.buildMetaWithDefaults(client, year, rs.selectedLayer, {
        layerName: rs.sectionNarrative,
        structureID,
      }),
    })

    return layer
  }

  buildLayerQS(rs: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        attachment: ZERO_DOLLARS,
        limit: UNLIMITED_DOLLARS,
        meta_data: {
          rate_on_subject: (rs.premiumAdjRate ?? 0) / 100,
        },
      },
    }
  }

  buildLayerXL(rs: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          rate_on_subject: (rs.premiumAdjRate ?? 0) / 100,
        },
      },
    }
  }

  buildLayerRISK(rs: RiskSectionWithMarkets): RecursivePartial<Layer> {
    const lossSetLayers: LayerRef[] = [
      {
        id: '714ac6c1-46fe-44d6-9cf0-3f10304fc324',
        meta_data: {
          client: 'Lockton Demo',
          year: '2020',
          ls_dim1: 'SCS',
          ls_dim2: 'SCS',
          loss_type: 'cat',
          perspective: 'gross',
          program_name: '2020 Renewal',
          sage_layer_type: 'gross_layer_loss_set',
        },
      },
      {
        id: 'fef71910-d626-4e5e-8691-fedc26e44f74',
        meta_data: {
          client: 'Lockton Demo',
          year: '2020',
          ls_dim1: 'Marine-Hull',
          ls_dim2: 'large',
          loss_type: 'large',
          perspective: 'gross',
          program_name: '2020 Renewal',
          sage_layer_type: 'gross_layer_loss_set',
        },
      },
    ]

    return {
      layerRefs: [],
      lossSetLayers,
      physicalLayer: {
        meta_data: {
          rate_on_subject: (rs.premiumAdjRate ?? 0) / 100,
        },
      },
    }
  }

  buildLayerCA(rs: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          cascadeAttachment: rs.occurrenceAttachment,
        },
      },
      meta_data: {
        cascadeAttachment: rs.occurrenceAttachment,
      },
    }
  }

  buildLayerFHCF(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          isFHCFFinal: true,
        },
      },
      meta_data: {
        isFHCFFinal: true,
      },
    }
  }

  buildLayerAG(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          rol_type: 'agg',
        },
      },
      meta_data: {
        rol_type: 'agg',
      },
    }
  }

  buildLayerINDX(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          sage_layer_subtype: 'visible-layer',
        },
      },
      meta_data: {
        sage_layer_subtype: 'visible-layer',
      },
    }
  }

  buildLayerSWING(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          sage_layer_subtype: 'visible-layer',
        },
      },
      meta_data: {
        sage_layer_subtype: 'visible-layer',
      },
    }
  }

  buildLayerMS(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    const currency = 'USD'
    const lossSetLayers: LayerRef[] = [
      {
        id: '714ac6c1-46fe-44d6-9cf0-3f10304fc324',
        meta_data: {
          client: 'Lockton Demo',
          year: '2020',
          ls_dim1: 'SCS',
          ls_dim2: 'SCS',
          loss_type: 'cat',
          perspective: 'gross',
          program_name: '2020 Renewal',
          sage_layer_type: 'gross_layer_loss_set',
        },
      },
      {
        id: 'fef71910-d626-4e5e-8691-fedc26e44f74',
        meta_data: {
          client: 'Lockton Demo',
          year: '2020',
          ls_dim1: 'Marine-Hull',
          ls_dim2: 'large',
          loss_type: 'large',
          perspective: 'gross',
          program_name: '2020 Renewal',
          sage_layer_type: 'gross_layer_loss_set',
        },
      },
    ]
    return {
      layerRefs: [],
      lossSetLayers,
      physicalLayer: {
        premium: money(0, currency, 1),
        meta_data: {
          sage_layer_subtype: 'visible-layer',
          contractOccurrenceLimit: 1e21,
        },
      },
      meta_data: {
        sage_layer_subtype: 'visible-layer',
      },
    }
  }

  buildLayerTD(_: RiskSectionWithMarkets): RecursivePartial<Layer> {
    return {
      physicalLayer: {
        meta_data: {
          sage_layer_subtype: 'virtual',
        },
      },
      meta_data: {
        sage_layer_subtype: 'virtual',
      },
    }
  }

  buildLayerWithDefaults(layer: Partial<Layer>): Merge<Partial<Layer>, {
    lossSetLayers: any[];
    physicalLayer: PhysicalLayer;
    sharedLayerID: string;
    layerRefs: any[];
    meta_data: {};
    viewMetrics: { rss: null; metrics: null; loading: boolean; error: null };
    lossSetFilter: string;
    id: string
  }, "deep"> {
    // @ts-ignore
    return mergeDeepRight(
      {
        id: '',
        lossSetFilter: '',
        lossSetLayers: [],
        layerRefs: [],
        physicalLayer: this.buildPhysicalWithDefaults('USD', {}),
        meta_data: {},
        sharedLayerID: '',
        viewMetrics: {
          loading: false,
          error: null,
          metrics: null,
          rss: null,
        },
      },
      layer
    )
  }

  buildPhysicalWithDefaults(
    currency: string,
    physical: Partial<PhysicalLayer>
  ): PhysicalLayer {
    if (!currency) {
      currency = 'USD'
    }

    return mergeRight(
      {
        type: 'Generic',
        id: '',
        attachment: asMonetaryUnit(0, currency),
        limit: asMonetaryUnit(UNLIMITED_AMOUNT, currency),
        participation: -1,
        premium: asMonetaryUnit(0, currency),
        fees: [],
        aggregateAttachment: asMonetaryUnit(0, currency),
        aggregateLimit: asMonetaryUnit(0, currency),
        reinstatements: [],
        franchise: asMonetaryUnit(0, currency),
        loss_sets: [],
        description: `on ${new Date().toLocaleString()}`,
        logicalLayerID: '',
        meta_data: {},
        xcoord: -1,
        ycoord: -1,
      },
      physical
    )
  }

  buildMetaWithDefaults(
    client: string,
    year: string,
    sageLayer: string,
    meta: Partial<Metadata>
  ): Partial<Metadata> {
    return mergeRight(
      {
        client,
        year,
        perspective: 'ceded',
        rol_type: this.getLayerType(sageLayer) === 'ag' ? 'agg' : 'occ',
        rol: 0,
        sage_layer_type: sageLayer,
        isFHCFFinal: sageLayer === 'cat_fhcf',
      },
      meta
    )
  }

  getLayerType(sageLayer: string) {
    return sageLayer.slice(sageLayer.indexOf('_') + 1)
  }

  splitSageLayerType(sageLayer: string) {
    const parts = sageLayer.split('_', 2)
    return {
      category: parts[0],
      type: parts[1] ?? '',
    }
  }

  getReinstatements(riskReinstatements: RiskReinstatement[]): Reinstatement[] {
    return riskReinstatements?.map(rr => ({
      premium: (rr.ripPct ?? 0) / 100,
      brokerage: 0,
    }))
  }

  // ----------------------------------------------------------------

  showProgress() {
    this.progressDialog = this.dialog.open(AutoBuildProgressDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
    })
  }

  closeProgress() {
    if (this.progressDialog) {
      this.progressDialog.close()
    }
  }

  setCloningStep(label: string, step: string) {
    this.progressDialog.componentInstance.setCloningStep(label, step)
  }

  addProgressStep(step: string) {
    this.progressDialog?.componentInstance.addStep(step)
  }

  setProgressRange(range: number) {
    this.progressDialog?.componentInstance.setRange(range)
  }

  setProgress(progress: number) {
    this.progressDialog?.componentInstance.setProgress(progress)
  }

  setProgressSaving(step: string) {
    this.progressDialog?.componentInstance.setSavingStep(step)
  }

  setProgressSaveComplete() {
    this.progressDialog?.componentInstance.setSaveComplete()
  }

  private buildLayerType(
    layerType: string,
    riskSection: RiskSectionWithMarkets
  ): RecursivePartial<Layer> {
    const layersToBuild: {
      [key: string]: (
        riskSection: RiskSectionWithMarkets
      ) => RecursivePartial<Layer>
    } = {
      qs: this.buildLayerQS,
      xl: this.buildLayerXL,
      ca: this.buildLayerCA,
      ag: this.buildLayerAG,
      fhcf: this.buildLayerFHCF,
      risk: this.buildLayerRISK,
      indxl: this.buildLayerINDX,
      swing: this.buildLayerSWING,
      multisection: this.buildLayerMS,
      td: this.buildLayerTD,
    }
    const layerToBuild = layersToBuild[layerType]
    if (!layerToBuild) {
      return {}
    }
    return layerToBuild.call(this, riskSection)
  }

  private filterAndStringifyRiskRefSections(
    filterBy: RiskSectionWithMarkets
  ): string {
    return JSON.stringify(
      this.riskRefSections.find(
        riskRefWithSection =>
          riskRefWithSection.riskRef === filterBy.riskRef &&
          riskRefWithSection.sectionNarrative === filterBy.sectionNarrative
      )
    )
  }
}
