import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { LogicalPortfolioLayer } from '../../../api/analyzere/analyzere.model'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { rejectErrorWithInput, switchMapWithInput } from '../../../api/util'
import { AppState } from '../../../core/store'
import { errorPayload } from '../../../error/model/error'
import { convertFromLogicalPortfolioLayers } from '../../model/layers.converter'
import {
  selectCurrentStructureID,
  selectEditorPortfolioSetAndStudyIDs,
} from '../analysis.selectors'
import * as fromLayersActions from '../ceded-layers/layers.actions'
import * as fromPortfolioViewsActions from '../views/portfolio-view.actions'
import * as fromPortfolioActions from './portfolio.actions'
import { isLayerBackAllocated } from '../../model/layers.util'
import { fetchStudyReinsurer } from '../../../reinsurers/store/study-reinsurers.actions'
import { addInuranceTagsByLevel, reinitialize } from '../analysis.actions'
import { selectRouterStateUrl } from '../../../core/store/router.selectors'
import { fetchOrSaveQuoteData } from '../../../quote/store/quote.actions'
import { selectCurrentStudyPrograms } from 'src/app/core/store/program/program.selectors'
import { of } from 'rxjs'
import { updateProgram } from 'src/app/core/store/program/program.actions'
import { PortfolioSetAndStudyIDs } from '../../model/portfolio-set.model'
import { ActivatedRoute, Router } from '@angular/router'
import { setCurrentStructure } from 'src/app/core/store/broker/broker.actions'
import { RepairPortfoliosService } from '../../portfolio/repair-portfolios.service'

@Injectable()
export class CededPortfolioEffects {

  constructor(
    private service: AnalyzreService,
    private actions$: Actions,
    private store: Store<AppState>,
    private repairService: RepairPortfoliosService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPortfolioActions.fetchPortfolio),
      switchMapWithInput(action => {
        return this.service.fetchPortfolioWithAggFeederAndRiskVisible(action.id)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromPortfolioActions.fetchPortfolioFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStructureID)),
        this.store.pipe(select(selectCurrentStudyPrograms))
      ),
      switchMapWithInput(([[portfolio], structureId, structures]) =>
        this.repairService.removeSectionLayersToRepairPortfolio(
          portfolio,
          structureId ?? '',
          structures,
          'cededPortfolioID'
        )
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromPortfolioActions.fetchPortfolioFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectEditorPortfolioSetAndStudyIDs)),
        this.store.pipe(select(selectCurrentStructureID)),
        this.store.pipe(select(selectRouterStateUrl))
      ),
      switchMap(
        ([
          [
            { portfolio, structureId },
            [[oldPortfolio, { analysisStartProps }]],
          ],
          portfolioSetAndStudyIDs,
          structureID,
          url,
        ]) => {
          if (!portfolioSetAndStudyIDs) {
            return [
              fromPortfolioActions.fetchPortfolioFailure({
                error: errorPayload('Cannot fetch portfolio, no editor ID'),
              }),
            ]
          }
          const actions = []
          if (portfolio.id !== oldPortfolio.id) {
            const updatedCededIdProps = {
              ...analysisStartProps,
              cededPortfolioID: portfolio.id,
            }
            const { compareViews, programs, programGroups, fromSave, ...rest } =
              updatedCededIdProps

            const updatedPortfolioSetAndStudyIds =
              rest as PortfolioSetAndStudyIDs
            actions.push(
              reinitialize(updatedCededIdProps),
              updateProgram({
                programID: structureID ?? structureId,
                change: {
                  cededPortfolioID: portfolio.id,
                },
              }),
              fromPortfolioActions.updateTierPathForPortfolio({
                portfolioSet: updatedPortfolioSetAndStudyIds,
                structureId,
              })
            )
          } else {
            const layers = convertFromLogicalPortfolioLayers(
              portfolio.layers as LogicalPortfolioLayer[]
            )
            const backAllocatedQuotaShareID = layers
              .filter(l => isLayerBackAllocated(l))
              .map(l => l.physicalLayer.id)

            const layerIDs = [
              ...layers.map(l => l.id),
              ...backAllocatedQuotaShareID,
            ]

            const layerCurrencies = [...layers.map(l => l.currency ?? '')]

            actions.push(
              ...[
                fromPortfolioActions.fetchPortfolioSuccess({
                  id: portfolio.id,
                  name: portfolio.name,
                  description: portfolio.description || '',
                  client: portfolio.meta_data.client || '',
                  programName: portfolio.meta_data.program_name || '',
                  year: portfolio.meta_data.year || '',
                  perspective: portfolio.meta_data.perspective || '',
                }),
                fromLayersActions.fetchLayersSuccess({
                  layers,
                }),
                fromPortfolioViewsActions.fetchPortfolioView({
                  ...portfolioSetAndStudyIDs,
                  isDesign: true,
                }),

                fetchStudyReinsurer({
                  carrierID: portfolioSetAndStudyIDs.clientID,
                  studyID: portfolioSetAndStudyIDs.studyID,
                }),

                fromPortfolioViewsActions.fetchCededLayersViews({
                  ...portfolioSetAndStudyIDs,
                  layerIDs,
                  layerCurrencies,
                  analysisProfileID: portfolioSetAndStudyIDs.analysisProfileID,
                  isDesign: true,
                  isGroup: false,
                }),

                addInuranceTagsByLevel(),
              ]
            )
            if (structureID && url.includes('quote/main')) {
              actions.push(fetchOrSaveQuoteData({ structureID }))
            }
          }
          return actions
        }
      )
    )
  })

  updateTierPathForPortfolio$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromPortfolioActions.updateTierPathForPortfolio),
        map(({ portfolioSet, structureId }) => {
          return of(
            this.router
              .navigate(
                [
                  'clients',
                  portfolioSet.clientID,
                  'years',
                  portfolioSet.yearID,
                  'programs',
                  portfolioSet.studyID,
                  'structure',
                  structureId,
                  'analysis',
                  portfolioSet.analysisProfileID,
                  'portfolios',
                  JSON.stringify({
                    cededPortfolioID: portfolioSet.cededPortfolioID,
                    grossPortfolioID: portfolioSet.grossPortfolioID,
                    netPortfolioID: portfolioSet.netPortfolioID,
                    parentGrossPortfolioID: portfolioSet.parentGrossPortfolioID,
                  }),
                ],
                {
                  relativeTo: this.route,
                }
              )
              .then(() => {
                this.store.dispatch(setCurrentStructure({ id: structureId }))
              })
          )
        })
      )
    },
    { dispatch: false }
  )
}
