import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  NewGroupDialogComponentData,
  NewGroupDialogComponent,
} from '@shared/new-group-dialog/new-group-dialog.component'

@Injectable()
export class NewGroupDialogService {
  constructor(public dialog: MatDialog) {}

  open(portfolioName: string) {
    const data: NewGroupDialogComponentData = {
      groupName: portfolioName,
    }
    return this.dialog.open(NewGroupDialogComponent, {
      width: '30vw',
      data,
    })
  }
}
