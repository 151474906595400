import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { Layer } from 'src/app/analysis/model/layers.model'
import { MultiSectionCardComponent } from 'src/app/analysis/properties/layer/multi-section/multi-section-card.component'
import {
  MultiSectionService,
  Section,
} from 'src/app/analysis/properties/layer/multi-section/multi-section.service'
import {
  CurrencyCode,
  LossSetLayer,
} from 'src/app/api/analyzere/analyzere.model'
import { AppState } from 'src/app/core/store'
import * as fromAnalysisActions from '../../../store/analysis.actions'
import { Program } from 'src/app/core/model/program.model'
import {
  findLayer,
  findLayerByVisibleId,
} from 'src/app/analysis/model/layers.util'

@Component({
  selector: 'app-multi-section',
  styleUrls: ['./multi-section.component.scss'],
  templateUrl: './multi-section.component.html',
  providers: [MultiSectionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSectionComponent implements OnInit, AfterViewInit {
  @Input() currentProgram: Program
  @Input() mainLayer: Layer
  @Input() layers: Layer[]
  @Input() currencyList: CurrencyCode[]
  @Input() parentGrossLossSetLayers: LossSetLayer[]
  @Input() isLibRE: boolean

  @Output() sectionInvalid = new EventEmitter<boolean>()
  @Output() currencyChangeLayer = new EventEmitter<Layer>()

  @ViewChildren('sectionCards')
  sectionCards: QueryList<MultiSectionCardComponent>

  get sections$() {
    return this.multiSectionService.sections$
  }

  get currentSections() {
    return this.sectionCards.reduce<Section[]>(
      (sections, card) => sections.concat(card.section),
      []
    )
  }

  constructor(
    @Host() private multiSectionService: MultiSectionService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.multiSectionService.initialize(
      this.mainLayer,
      this.layers,
      this.parentGrossLossSetLayers
    )
  }

  ngAfterViewInit(): void {
    // Disable inurable sections based on the initial selections
    this.onInuringSelection()
  }

  onAddSection() {
    this.multiSectionService.addSection()
  }

  onCardStateChange(cardInvalid: boolean): void {
    // If a card goes invalid that is all we need to know to set our flag. If
    // the card goes valid, then we need to check all the cards to see if all
    // the cards are valid, or one or more are invalid.
    if (cardInvalid) {
      this.sectionInvalid.emit(true)
    } else {
      this.sectionInvalid.emit(this.sectionCards.some(card => card.invalid))
    }
  }

  onSectionInurance(section: Section): void {
    const mainLayer = findLayerByVisibleId(this.layers, this.mainLayer.id)
    if (mainLayer === undefined) {
      throw Error('Unable to find main layer.')
    }

    const sectionLayers = mainLayer.layerRefs.map(id => {
      const layer = findLayer(this.layers, id)
      if (layer === undefined) {
        throw Error('Unable to find section layer.')
      }
      return layer
    })

    const sectionLayer = sectionLayers.find(
      layer => layer.physicalLayer.description === `Section ${section.letter}`
    )

    if (!sectionLayer) {
      throw Error('Unable to find selected section layer.')
    }

    this.store.dispatch(
      fromAnalysisActions.openAddInuranceDialog({
        currentProgram: this.currentProgram,
        currentLayer: sectionLayer,
        isAdd: true,
        isEdit: false,
        fromMultiSection: true,
      })
    )
  }

  // When an inuring section is chosen on any card, we need to determine which
  // unselected inuring sections are now prohibited because they would cause a
  // circular reference.
  onInuringSelection(): void {
    const disable = new Map<string, Set<string>>()
    const disableSections = (letter: string, path: string[]) => {
      const letterSet = disable.get(letter)
      path.forEach(l => letterSet?.add(l))
    }

    let check = this.currentSections.map(section => section.letter)
    check.forEach(letter => {
      disable.set(letter, new Set())
    })

    const preventCyclesFor = (letter: string, path: string[] = []) => {
      path = [...path, letter]
      check = check.filter(l => l !== letter)

      disableSections(letter, path)

      const currentSection = this.currentSections.find(
        section => section.letter === letter
      )
      if (currentSection === undefined) {
        throw new Error(`Section ${letter} not found in currentSections`)
      }

      currentSection.inuringSections.forEach(inuringLetter => {
        if (!path.includes(inuringLetter)) {
          preventCyclesFor(inuringLetter, path)
        }
      })
    }

    for (
      let letter = check.shift();
      letter !== undefined;
      letter = check.shift()
    ) {
      preventCyclesFor(letter)
    }

    this.sectionCards.forEach(card => {
      const disabled = (disable.get(card.section.letter) ?? {}) as string[]
      card.onInuringDisable(Array.from(disabled))
    })
  }
}
