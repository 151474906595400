import {inject, Injectable} from '@angular/core'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppState } from 'src/app/core/store'
import { Store, select } from '@ngrx/store'
import * as fromTailMetricsActions from './tail-metrics.actions'
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { concatMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { of, forkJoin } from 'rxjs'
import {
  selectPortfolioViewState,
  selectCompareMetricSettingsEntities,
  selectPortfolioViewDetailMetricsState,
} from '../analysis.selectors'
import * as fromPortfolioView from '../views/portfolio-view.reducer'
import { ApiResponse } from 'src/app/api/model/api.model'
import { Metrics } from 'src/app/api/analyzere/analyzere.model'
import { calculateCustomTailMetric, calculateTailMetrics } from './calculations'
import { CompareMetricSetting } from '../../model/compare-metrics.model'
import { fetchCapitalMetrics } from './capital-metrics.actions'
import { CustomTailMetricResult } from '../../model/tail-metrics.model'

@Injectable()
export class TailMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTailMetricsActions.fetchTailMetrics),
      map(({ type, ...props }) => props),
      concatMap(props => {
        const portfolioSetID = extractPortfolioSetID(props)
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectPortfolioViewState, { portfolioSetID })
            ),
            this.store.pipe(select(selectCompareMetricSettingsEntities)),
            this.store.pipe(
              select(selectPortfolioViewDetailMetricsState, { portfolioSetID })
            )
          ),
          map(
            ([
              _,
              portfolioViewState,
              settings,
              portfolioViewDetailMetricsState,
            ]) => ({
              ...props,
              portfolioSetID,
              portfolioViewState,
              portfolioViewDetailMetricsState,
              settings: settings
                .filter(
                  setting =>
                    setting.category === 'Tail Metrics' ||
                    setting.grossMetricType === 'Tail Metrics'
                )
                .sort((a, b) => (a.saveID >= b.saveID ? 1 : -1)),
            })
          )
        )
      }),
      concatMapWithInput(props => {
        const id: string[][] = []
        const tailMetricSettings = props.settings.filter(s => s.metricSettingID != 0 && (s.category === 'Tail Metrics' || s.category === 'Gross Metrics' ))
        id.push(
          tailMetricSettings.map(
            setting => this.getId(setting, props.portfolioViewState) || ''
          )
        )
        const actions: Array<ApiResponse<Metrics>[]> = []
        actions.push(
          tailMetricSettings.map(
            (setting, i) =>
              this.service.getTailMetrics(
                id[0][i],
                // tslint:disable-next-line: no-non-null-assertion
                setting.aggregationMethodType!,
                // tslint:disable-next-line: no-non-null-assertion
                setting.perspective!,
                // tslint:disable-next-line: no-non-null-assertion
                parseInt(setting.year!, 10),
                // tslint:disable-next-line: no-non-null-assertion
                setting.lossFilter!
              ) as ApiResponse<Metrics>
          )
        )
        return forkJoin(actions[0]).pipe(
          map(
            ([
              NetVaROEP100yr,
              NetVaROEP250yr,
              NetVaRAEP250yr,
              GrossVaROEP100yr,
              GrossVaROEP250yr,
              GrossVaRAEP250yr,
              NetTVaROEP100yr,
              NetTVaROEP250yr,
              NetTVaRAEP250yr,
              GrossTVaROEP100yr,
              GrossTVaROEP250yr,
              GrossTVaRAEP250yr,
            ]) => {
              return {
                data: calculateTailMetrics(
                  // tslint:disable-next-line: no-non-null-assertion
                  NetVaROEP100yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  NetVaROEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  NetVaRAEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossVaROEP100yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossVaROEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossVaRAEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  NetTVaROEP100yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  NetTVaROEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  NetTVaRAEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossTVaROEP100yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossTVaROEP250yr.data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  GrossTVaRAEP250yr.data!,
                  props.settings
                )
              }
            }
          )
        )
      }),
      rejectErrorWithInput((error, props) => {
        this.store.dispatch(
          fromTailMetricsActions.fetchTailMetricsFailure({
            error,
            ...props,
          })
        )
        this.store.dispatch(fetchCapitalMetrics({ ...props }))
        }
      ),
      concatMap(([tailMetrics, props]) => {
        const customTailMetricsObject = [...props.settings];
        const customMetricResult: CustomTailMetricResult[] = [];
        const observablesWithSettings: Array<{ response: { tailMetricResponse: ApiResponse<Metrics>, grossMetricResponse: ApiResponse<Metrics> }, metricSetting: CompareMetricSetting }> = [];
      
        const customMetrics = customTailMetricsObject.filter(s => s.metricSettingID === 0 && s.category === 'Tail Metrics');
      
        customMetrics!.forEach(setting => {
          const tailMetric = this.service.getTailMetrics(
            this.getId(setting, props.portfolioViewState) || '',
            setting.aggregationMethodType!,
            setting.perspective!,
            parseInt(setting.year!, 10),
            setting.lossFilter!
          );
      
          const grossMetric = this.service.getTailMetrics(
            props.grossPortfolioViewID,
            setting.aggregationMethodType!,
            setting.perspective!,
            parseInt(setting.year!, 10),
            setting.lossFilter!
          );
      
          observablesWithSettings.push({
            response: { tailMetricResponse: tailMetric, grossMetricResponse: grossMetric },
            metricSetting: setting
          })
        })
        if(observablesWithSettings.length > 0){
          return forkJoin(
            observablesWithSettings.map(item =>
              forkJoin([item.response.tailMetricResponse, item.response.grossMetricResponse]).pipe(
                map(([tailMetricResult, grossMetricResult]) => {
                  let tailMetricCalculation = 0
                  let grossMetricCalculation= 0
                  if(tailMetricResult.data){
                    tailMetricCalculation = calculateCustomTailMetric(tailMetricResult.data!, grossMetricResult.data!, item.metricSetting, false);
                  }
                  if(grossMetricResult.data){
                    grossMetricCalculation = calculateCustomTailMetric(grossMetricResult.data!, tailMetrics.grossMetric1, item.metricSetting, true);
                  }
                
                  customMetricResult.push({
                    label: item.metricSetting.label,
                    metricResult: tailMetricCalculation,
                    type: 'Tail Metrics'
                  });
                  customMetricResult.push({
                    label: item.metricSetting.label,
                    metricResult: grossMetricCalculation,
                    type: 'Gross Metrics'
                  })
                  return customMetricResult
                })
              )
            )
          ).pipe(
            mergeMap(([customMetric]) => {
              tailMetrics.customMetricsResult = customMetric
              const actions = []
              actions.push(
                fromTailMetricsActions.fetchTailMetricsSuccess({
                  ...props,
                  tailMetrics,
                }),
                fetchCapitalMetrics({ ...props })
              )
              return actions
            })
          )
        }else{
          const actions = []
          actions.push(
            fromTailMetricsActions.fetchTailMetricsSuccess({
              ...props,
              tailMetrics,
            }),
            fetchCapitalMetrics({ ...props })
          )
          return actions
        }
      }),
    )
  })

  private getId(setting: CompareMetricSetting, state: fromPortfolioView.State) {
    const portfolioType = setting.grossMetricType
      ? 'Gross'
      : setting.portfolioType
    if (portfolioType === 'Gross') {
      return state.grossPortfolioViewID
    } else {
      return state.netPortfolioViewID
    }
  }
}
