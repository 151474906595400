import { CdkDragDrop } from '@angular/cdk/drag-drop'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { LayerPaletteItem } from '../analysis/model/layer-palette.model'
import { LayerState } from '../analysis/store/ceded-layers/layers.reducer'
import { LossSetLayer } from '../analysis/model/loss-set-layers.model'
import { isLayerAggFeeder } from '../analysis/model/layers.util'
import * as fromPortfolio from '../analysis/store/ceded-portfolio/portfolio.reducer'

export interface LayerDropEvent {
  event: CdkDragDrop<string[]>
  portfolioMetadata: fromPortfolio.State
  item: LayerPaletteItem
  layers: LayerState[]
  lossSetLayers: LossSetLayer[]
  currentStructureID: string
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-palette-item',
  styles: [
    `
           section {
             transition: background 250ms, color 250ms;
     
             /* Display anchors like buttons */
             white-space: nowrap;
             text-decoration: none;
             vertical-align: baseline;
             text-align: center;
     
             /* Sizing */
             margin: 0;
             min-width: 24px;
             line-height: 24px;
     
             /* Reset button styles */
             cursor: pointer;
             outline: none;
             border: none;
             user-select: none;
     
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
             font-size: var(--font-size);
             padding: 0 10px 3px;
             border: 2px solid transparent;
     
             color: var(--primary);
             background: rgba(var(--rgb), var(--bg-alpha));
             border-radius: var(--border-radius-big);
             border: 2px solid rgba(var(--rgb), 1);
           }
     
           :host-context(.app-theme-layer-wireframe) section {
             position: relative;
             border-radius: 0;
             z-index: 1;
           }
     
           :host.special section {
             border: 2px dashed rgba(var(--rgb), 0.9);
           }
     
           :host.striped section {
             background: repeating-linear-gradient(
               90deg,
               rgba(var(--rgb), var(--bg-2-alpha)),
               rgba(var(--rgb), var(--bg-2-alpha)) 30px,
               #000 30px,
               #000 60px
             );
           }
     
           :host-context(.app-theme-dark) section {
             color: rgba(var(--rgb), 0.92);
           }
     
           section:hover,
           section:focus {
             color: var(--primary-lit);
             background: rgba(var(--rgb), calc(var(--bg-alpha) + 0.1));
           }
     
           :host-context(.app-theme-dark) section:hover,
           :host-context(.app-theme-dark) section:focus {
             color: rgba(var(--rgb), 1);
           }
     
           :host-context(.app-theme-layer-wireframe) section::before,
           :host-context(.app-theme-layer-wireframe) section::after {
             border: 3px rgba(var(--rgb), 0.5) solid;
             content: '';
             display: block;
             position: absolute;
             z-index: -1;
           }
     
           :host-context(.app-theme-layer-wireframe) section::before {
             top: -8px;
             right: -5px;
             width: 100%;
             height: 4px;
             transform: skewX(-45deg);
             border-bottom-width: 0;
             border-top-width: 2px;
             border-right-width: 1px;
           }
     
           :host-context(.app-theme-layer-wireframe) section::after {
             right: -8px;
             top: -5px;
             width: 4px;
             height: 26px;
             transform: skewY(-45deg);
             border-left-width: 0;
             border-right-width: 2px;
             border-top-width: 2px;
           }
     
           :host-context(.app-theme-layer-wireframe).special section::before,
           :host-context(.app-theme-layer-wireframe).special section::after {
             border-style: dashed;
           }
     
           @media screen and (max-width: 1240px) {
             section {
               font-size: var(--font-size-small);
               padding-bottom: 3px;
               line-height: 20px;
             }
     
             :host-context(.app-theme-layer-wireframe) section::before {
               top: -7px;
               right: -4px;
               height: 3px;
             }
     
             :host-context(.app-theme-layer-wireframe) section::after {
               right: -7px;
               top: -5px;
               width: 3px;
               height: 23px;
             }
           }
         `,
  ],
  template: `
    <div cdkDropList class="layer-list" (cdkDropListDropped)="drop($event)">
      <section cdkDrag class="layer-palette-item" [ngClass]="ngClass">
        {{ name }}
      </section>
    </div>
  `,
})
export class LayerPaletteItemComponent {
  @Input() item: LayerPaletteItem
  @Input() layers: LayerState[]
  @Input() layerChoices: LossSetLayer[]
  @Input() currentStructureID: string
  @Output() newLayer = new EventEmitter<LayerDropEvent>()
  @Input() portfolioMetadata: fromPortfolio.State
  @Input() log: boolean

  get id() {
    return this.item.id
  }

  get name() {
    return this.item.name
  }

  get type() {
    return this.item.type
  }

  get program() {
    return this.item.program
  }

  @HostBinding('class.special')
  get special() {
    return this.item.special
  }

  @HostBinding('class.striped')
  get striped() {
    return this.item.striped
  }

  get token() {
    return `${this.program}_${this.type}`
  }

  get ngClass() {
    return { [`app-palette-${this.token}`]: true }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.log) {
      if (
        this.item.id === 'cat_ag' ||
        this.item.id === 'noncat_ag' ||
        this.item.id === 'ahl_ag'
      ) {
        if (this.item.subtype === 'feeder') {
          if (!this.aggFeederExists()) {
            this.newLayer.emit({
              event,
              portfolioMetadata: this.portfolioMetadata,
              item: this.item,
              layers: this.layers,
              lossSetLayers: this.layerChoices,
              currentStructureID: this.currentStructureID,
            })
          }
        } else {
          if (this.aggFeederExists()) {
            this.newLayer.emit({
              event,
              portfolioMetadata: this.portfolioMetadata,
              item: this.item,
              layers: this.layers,
              lossSetLayers: this.layerChoices,
              currentStructureID: this.currentStructureID,
            })
          }
        }
      } else {
        this.newLayer.emit({
          event,
          portfolioMetadata: this.portfolioMetadata,
          item: this.item,
          layers: this.layers,
          lossSetLayers: this.layerChoices,
          currentStructureID: this.currentStructureID,
        })
      }
    }
  }

  private aggFeederExists(): boolean {
    return !!this.layers.find(l => !l.deleted && isLayerAggFeeder(l.layer))
  }
}
