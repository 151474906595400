import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Broker } from '../core/model/broker.model'
import { AppState } from '../core/store'
import { selectAuthState } from '../core/store/auth/auth.selectors'
import { selectCurrentClientID } from '../core/store/broker/broker.selectors'
import {
  resetSignatureData,
  resetSignatureProperties,
} from './store/signature.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signature-container',
  template: `
    <app-theme-container>
      <app-signature
        [clientSelect]="clientSelect"
        [selectedClientID]="selectedClientID$ | async"
        [broker]="broker$ | async"
      ></app-signature>
    </app-theme-container>
  `,
})
export class SignatureContainerComponent implements OnInit {
  @Input() clientSelect = false

  selectedClientID$: Observable<string | null>
  broker$: Observable<Broker>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.resetSignaturePage()
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    // Note: TODO: Currently no user image in blobstorage
    this.broker$ = this.store.pipe(
      select(selectAuthState),
      map(authState => ({
        username: (authState.username as string).split('@')[0],
        name: authState.name as string,
        avatarID: 'assets/avatars/initials-placeholder-square.png',
      }))
    )
  }

  resetSignaturePage() {
    this.store.dispatch(resetSignatureData())
    this.store.dispatch(resetSignatureProperties())
  }
}
