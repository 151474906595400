import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  CurveEntryUpdatePayload,
  DefaultSavedCurveEntry,
  GLOBAL_DEFAULT_CURVE_ENTRY,
  PRICING_CURVE_LAYER_TYPE_DEFAULT,
} from '../technical-premium.model'
import { AddCurveDialogComponent } from '../add-curve-dialog/add-curve-dialog.component'
import { LayerEntry } from '../technical-premium.model'
import { isCurveWeightTotalValid } from '../technical-premium.utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-technical-premium-list-entry',
  styleUrls: ['./technical-premium-list-entry.component.scss'],
  templateUrl: './technical-premium-list-entry.component.html',
})
export class TechnicalPremiumListEntryComponent implements OnInit, OnChanges {
  @Input() id: number
  @Input() title: string
  @Input() curveEntries: DefaultSavedCurveEntry[]
  @Input() pricingCurveIds: number[]
  @Input() layerType: string
  @Input() isLayer: boolean
  @Input() layerIndex: number | undefined
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() hasError: boolean
  @Input() premium: number | null | undefined
  @Input() isQS: boolean | undefined
  @Input() layerId: string | undefined
  @Input() physicalLayerId: string | undefined
  @Input() currencyString: string | undefined
  @Input() modified: boolean | undefined
  @Input() saveForNewLayersOnly: boolean | undefined

  @Output() updateCurveEntries = new EventEmitter<CurveEntryUpdatePayload>()
  @Output() resetEntry = new EventEmitter<number>()
  @Output() resetLayerTypeEntry = new EventEmitter<string>()
  @Output() setEntryToDefault = new EventEmitter<number>()
  @Output() dialogClosed = new EventEmitter()

  titleClass: string
  hovered = false
  tooltipMouseEvent: MouseEvent | undefined
  tooltipSavedCurve: SavedPricingCurveEntry | undefined
  premiumText = 'Technical Premium'
  displayCurrency = true
  newLayersOnlyButtonText = 'Save for New Layers Only'
  saveForOnlyNewLayersTooltip = 'Saving for New Layers Only'

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.titleClass = `app-palette-${this.layerType}`
    if (this.isQS) {
      this.premiumText = 'Technical Ceding Commission'
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.premium &&
      this.premium !== undefined &&
      this.premium !== null
    ) {
      this.displayCurrency = !isNaN(this.premium)
    }
    if (changes.saveForNewLayersOnly && this.title) {
      if (this.saveForNewLayersOnly) {
        this.newLayersOnlyButtonText = 'Save for All Layers'
        this.title += '*'
      } else {
        this.newLayersOnlyButtonText = 'Save for New Layers Only'
        this.title = this.title.replace('*', '')
      }
    }
  }

  getLayerEntry(data: Partial<LayerEntry>): LayerEntry {
    return {
      id: this.id,
      layerType: this.layerType,
      name: this.title.replace('*', ''),
      pricingCurves: this.curveEntries,
      hasError: data.pricingCurves
        ? !isCurveWeightTotalValid(data.pricingCurves)
        : this.hasError,
      isQS: this.isQS,
      layerId: this.layerId,
      physicalLayerId: this.physicalLayerId,
      currency: this.currencyString,
      modified: this.modified ?? true,
      pricingCurveIds: data.pricingCurves?.every(curve => curve.id != null)
        ? // tslint:disable-next-line: no-non-null-assertion
          data.pricingCurves.map(curve => curve.id!)
        : this.pricingCurveIds,
      saveForOnlyNewLayers: this.saveForNewLayersOnly ?? null,
      ...data,
    }
  }

  handleResetEntry(): void {
    if (!this.modified) {
      return
    }
    if (this.isLayer) {
      this.resetEntry.emit(this.id)
    } else {
      this.resetLayerTypeEntry.emit(this.layerType)
    }
  }

  handleSetEntryToDefault(): void {
    this.setEntryToDefault.emit(this.id)
  }

  resetTooltip(): void {
    this.tooltipMouseEvent = undefined
    this.tooltipSavedCurve = undefined
  }

  get isOnlyLayerTypeDefault(): boolean {
    return (
      this.curveEntries.length === 1 &&
      (this.curveEntries[0].id === null || this.curveEntries[0].id < 0)
    )
  }

  openCurveDialog(): void {
    if (this.isLayer && this.premium == undefined) {
      return
    }
    const dialogRef = this.dialog.open(AddCurveDialogComponent, {
      data: { addedCurveIds: this.curveEntries.map(entry => entry.id) },
      width: '90vw',
      minWidth: '90vw',
      maxWidth: '90vw',
      height: 'calc(100vh - 10%)',
      disableClose: true,
    })
    dialogRef.afterClosed().subscribe((curve: SavedPricingCurveEntry) => {
      if (curve) {
        const newEntries = [...this.curveEntries]
        const newEntry = this.createDefaultCurveObjectForCurve(curve, 1)
        if (this.isOnlyLayerTypeDefault) {
          newEntries.splice(0, 1, newEntry)
        } else {
          newEntries.push(newEntry)
        }
        this.updateCurveEntries.emit({
          entryId: this.id,
          layerType: this.layerType,
          newEntry: this.getLayerEntry({
            pricingCurves: newEntries,
            modified: true,
          }),
        })
      }
      this.dialogClosed.emit()
    })
  }

  onSetSaveMode(): void {
    this.updateCurveEntries.emit({
      entryId: this.id,
      layerType: this.layerType,
      newEntry: this.getLayerEntry({
        saveForOnlyNewLayers: !this.saveForNewLayersOnly,
      }),
    })
  }

  onWeightChange(value: number, index: number): void {
    const newEntries = [...this.curveEntries]
    const entry = newEntries[index]
    newEntries[index] = {
      ...entry,
      percentage: value / 100,
    }
    this.updateCurveEntries.emit({
      entryId: this.id,
      layerType: this.layerType,
      newEntry: this.getLayerEntry({
        pricingCurves: newEntries,
        modified: true,
      }),
    })
  }

  createDefaultCurveObjectForCurve(
    curve: SavedPricingCurveEntry,
    weight: number
  ): DefaultSavedCurveEntry {
    return {
      id: curve.id,
      pc_name: curve.pc_name,
      percentage: weight,
      value: 0,
    }
  }

  updateTooltipCurve(id: number, event: MouseEvent): void {
    this.tooltipMouseEvent = event
    this.tooltipSavedCurve = this.savedCurves.find(curve => curve.id === id)
  }

  removeCurve(curve: DefaultSavedCurveEntry): void {
    const newEntries = [...this.curveEntries]
    const index = newEntries.findIndex(({ id }) => curve.id === id)
    if (index >= 0) {
      newEntries.splice(index, 1)
      if (!newEntries.length) {
        newEntries.push(
          this.isLayer
            ? PRICING_CURVE_LAYER_TYPE_DEFAULT
            : this.createDefaultCurveObjectForCurve(
                GLOBAL_DEFAULT_CURVE_ENTRY,
                1
              )
        )
      }
      this.updateCurveEntries.emit({
        entryId: this.id,
        layerType: this.layerType,
        newEntry: this.getLayerEntry({
          pricingCurves: newEntries,
          modified: true,
        }),
      })
    }
  }
}
