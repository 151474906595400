import { Component, Inject } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelectChange } from '@angular/material/select'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreditGrossLossScenario,
  CreditPortfolio,
  ScenarioType,
} from '../../credit/model/credit-portfolio.model'
import { CreditCededLossScenario } from '../../credit/model/credit-structure.model'
import { SelectedScenario } from '../..//credit/model/credit-results.model'

@Component({
  selector: 'app-scenario-selector',
  templateUrl: './scenario-selector.component.html',
  styleUrls: ['./scenario-selector.component.scss'],
})
export class ScenarioSelectorComponent {
  scenarioControl = new FormControl('')
  deterministicScenarios: { value: string; viewValue: string }[] = []
  stochasticScenarios: { value: string; viewValue: string }[] = []
  years: number = 0
  yearsDisabled: boolean = true

  constructor(
    public dialogRef: MatDialogRef<ScenarioSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      creditPortfolio: CreditPortfolio
      currentScenario: string | undefined
      years: number | undefined
    }
  ) {
    const stochastic_data_result =
      data.creditPortfolio.gross_loss_scenarios.filter(
        s => s.scenario_type === ScenarioType.Stochastic
      )
    this.stochasticScenarios = stochastic_data_result.map(s => ({
      value: s._id,
      viewValue: s.name,
    }))

    this.years = data.years || 0
    const deteministic_data_result =
      data.creditPortfolio.gross_loss_scenarios.filter(
        s => s.scenario_type === ScenarioType.Deterministic
      )
    this.deterministicScenarios = deteministic_data_result.map(s => ({
      value: s._id,
      viewValue: s.name,
    }))
    if (data.currentScenario) {
      this.scenarioControl.setValue(data.currentScenario)
    }
    this.yearsDisabled = !!!this.stochasticScenarios.find(
      s => s.value === data.currentScenario
    )
  }

  formatLabel(value: number): string {
    return `${value} Years`
  }

  onApplyClick(): void {
    if (!this.yearsDisabled && this.years <= 0) {
      return
    }
    let scenario:
      | CreditGrossLossScenario
      | CreditCededLossScenario
      | undefined = this.data.creditPortfolio.gross_loss_scenarios.find(
      s => s._id === this.scenarioControl.value
    )
    let ceded = scenario?.scenario_type === ScenarioType.Stochastic
    if (!scenario) {
      this.dialogRef.close()
      return
    }
    const mappingScenarioId =
      scenario.scenario_type === ScenarioType.Deterministic
        ? scenario.scenario_mapping_id
        : this.getStochasticMappingId(scenario)
    const output: SelectedScenario = {
      id: scenario._id,
      name: scenario.name,
      scenario: scenario.scenario,
      scenario_type: scenario.scenario_type,
      years: ceded ? this.years : undefined,
      mappingScenarioId,
    }
    this.dialogRef.close(output)
  }

  onScenarioChange(scenario: MatSelectChange): void {
    this.yearsDisabled = !!!this.stochasticScenarios.find(
      s => s.value === scenario.value
    )
  }

  private getStochasticMappingId(scenario: CreditGrossLossScenario): number {
    return (
      scenario.stochastic_data.find(s => Number(s.return_period) === this.years)
        .scenario_mapping_id ??
      scenario.stochastic_data.find(s => Number(s.return_period) === 1) // default to average if invalid return period
        .scenario_mapping_id
    )
  }
}
