<app-reinsurer-detail-dialog-component
  [reinsurer]="data.reinsurer"
  [reinsurers]="reinsurers$ | async"
  [studyID]="studyID$ | async"
  [studies]="studies$ | async"
  [programs]="programs$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
  [reinsurerID]="data.reinsurer.id"
  [agencyDetails]="data.agencyDetails"
  [selectedCompanyPapers]="data.selectedCompanyPapers"
  [relatedReinsurers]="data.relatedReinsurers"
  [savingReinsurer]="savingReinsurer$ | async"
  [dirtyReinsurers]="dirtyReinsurers$ | async"
  (setReinsurer)="onSetReinsurer($event)"
  (updateOrAddDirty)="onUpdateOrAddDirty($event)"
  (saveClick)="onSaveClick()"
>
</app-reinsurer-detail-dialog-component>
