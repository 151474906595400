<div class="outer-wrapper">
  <div class="info-container">
    <span [matTooltip]="value.parent.value"
      >Class: {{ value.parent.value }}</span
    >
    <span [matTooltip]="value.child.value"
      >Subclass: {{ value.child.value }}</span
    >
  </div>
  <div class="button-wrapper">
    <!-- <button appButton small primary border (click)="onEditClick($event)">
      Edit
    </button> -->
  </div>
</div>
