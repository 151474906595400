import { createReducer, on, Action } from '@ngrx/store'
import * as fromGroupAnimatedScenarios from './group-animated-scenarios.actions'
import * as fromGroupScenarioEventReducer from './group-scenario-event.reducer'
import * as fromGroupScenarioEventActions from './group-scenario-event.actions'
import * as fromGroupScenarioEventResultsReducer from './group-scenario-event-result.reducer'
import * as fromGroupScenarioEventResultsActions from './group-scenario-event-result.actions'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import { saveGrouper } from '../../../store/grouper/grouper.actions'

export interface GroupAnimatedScenariosState {
  groupID: string | null
  loadingGroup: boolean
  loadingGroupError: string | null
  lossSetLayers: LossSetLayer[]
  eventState: fromGroupScenarioEventReducer.ScenarioEventState
  executing: boolean
  executingError: string | null
  eventResultState: fromGroupScenarioEventResultsReducer.ScenarioEventResultState
  animating: boolean
}

export const initialState: GroupAnimatedScenariosState = {
  groupID: null,
  lossSetLayers: [],
  loadingGroup: false,
  loadingGroupError: null,
  eventState: fromGroupScenarioEventReducer.initialState,
  executing: false,
  executingError: null,
  eventResultState: fromGroupScenarioEventResultsReducer.initialState,
  animating: false,
}

const groupReducer = createReducer(
  initialState,
  on(fromGroupAnimatedScenarios.setGroup, state => ({
    ...state,
    loadingGroup: true,
  })),

  on(
    fromGroupAnimatedScenarios.setGroupSuccess,
    (state, { lossSetLayers, groupID }) => ({
      ...state,
      loadingGroup: false,
      loadingGroupError: null,
      lossSetLayers,
      groupID,
    })
  ),

  on(fromGroupAnimatedScenarios.setGroupFailure, (state, { error }) => ({
    ...state,
    loadingGroup: false,
    loadingGroupError: error.message,
  })),

  on(
    fromGroupScenarioEventActions.addAllGroupScenarioEvents,
    fromGroupScenarioEventActions.addGroupScenarioEvent,
    fromGroupScenarioEventActions.removeGroupScenarioEvent,
    fromGroupScenarioEventActions.updateGroupScenarioEvent,
    (state, action) => ({
      ...state,
      eventState: fromGroupScenarioEventReducer.reducer(
        state.eventState,
        action
      ),
    })
  ),

  on(fromGroupAnimatedScenarios.groupExecute, state => ({
    ...state,
    executing: true,
    animating: true,
  })),

  on(fromGroupAnimatedScenarios.groupExecuteSuccess, state => ({
    ...state,
    executing: false,
    executingError: null,
  })),

  on(fromGroupAnimatedScenarios.groupExecuteFailure, (state, { error }) => ({
    ...state,
    executingError: error.message,
    executing: false,
  })),

  on(
    fromGroupScenarioEventResultsActions.addAllGroupScenarioEventResults,
    fromGroupScenarioEventResultsActions.removeAllGroupScenarioEventResults,
    (state, action) => ({
      ...state,
      eventResultState: fromGroupScenarioEventResultsReducer.reducer(
        state.eventResultState,
        action
      ),
    })
  ),

  on(fromGroupAnimatedScenarios.setGroupAnimating, (state, { value }) => ({
    ...state,
    animating: value,
  })),

  on(
    fromGroupAnimatedScenarios.resetGroupEventsAndResults,
    saveGrouper,
    state => {
      return { ...initialState, groupID: state.groupID }
    }
  )
)

export function reducer(
  state: GroupAnimatedScenariosState | undefined,
  action: Action
) {
  return groupReducer(state, action)
}
