import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { map } from 'rxjs/operators'

export interface Territories {
  usStates: string[]
  countryAbbreviations: string[]
}

@Injectable({
  providedIn: 'root',
})
export class TerritoryService {
  constructor(private httpClient: HttpClient) {}

  getTerritoriesData(): ApiResponse<Territories> {
    const url = `${environment.internalApi.base}${environment.internalApi.territory}`
    return this.httpClient.get<string[]>(url).pipe(
      map(response => ({
        countryAbbreviations: response.filter(
          (item: string) => item.length === 3
        ),
        usStates: response.filter((item: string) => item.length !== 3),
      })),
      mapToMaybeData(),
      catchAndHandleError('GET Territories Data failed')
    )
  }
}
