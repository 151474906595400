<app-structure-card
  [class]="isEditMode ? 'structure-edit-box' : ''"
  [size]="size"
  [index]="index"
  [structure]="structure"
  [scenarios]="scenarios"
  [scenariosSelected]="scenariosSelected"
  [allowScenarioOrOptimizationSelection]="allowScenarioOrOptimizationSelection"
  [canCheck]="canCheck"
  [optimizations]="optimizations"
  [optimizationsSelected]="optimizationsSelected"
  [selectMultiple]="selectMultiple"
  [checked]="checked"
  [indicateFocus]="indicateFocus"
  [elevation]="elevation"
  [imageURI]="imageURI"
  [selectedStructureIDs]="selectedStructureIDs"
  [editFolderMode]="editFolderMode"
  [checkboxTooltip]="checkboxTooltip"
  (structureClick)="structureClick.emit()"
  (editClick)="editClick.emit()"
  (addStructure)="addStructure.emit($event)"
  (scenarioOrOptimizationSelectChange)="scenarioOrOptimizationSelectChange.emit($event)"
  (removeFromFolderClick)="onRemoveFromFolder()"
  (structureCheckChange)="structureCheckChange.emit($event)"
></app-structure-card>
