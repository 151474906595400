<h1 mat-dialog-title>{{ reinsurer.name }}</h1>
<h3 mat-dialog-title class="relationship-title">Select Relationship</h3>
<div mat-dialog-content>
  <app-sort-table
    class="table"
    spacing="tight"
    [columnDefs]="fundManagerColumns"
    [rows]="fundManagerRows"
    (valueChange)="onValueChange($event)"
  ></app-sort-table>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="!savingReinsurer; else actionRunning">
    <button
      appButton
      accent
      mat-dialog-close="2"
      cdkFocusInitial
      (click)="onSaveClick()"
    >
      Save
    </button>
  </ng-container>
  <ng-template #actionRunning>
    <app-active-action
      [value]="savingReinsurer ? 'Saving' : ''"
    ></app-active-action>
  </ng-template>
  <button appButton primary mat-dialog-close="2" cdkFocusInitial>Cancel</button>
</div>
