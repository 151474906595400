import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  SummaryChartAxis,
  SummaryChartGridOption,
} from '../summary-charts-model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-summary-side-chart-controls',
  styleUrls: [
    './explore-summary-side-chart-controls.component.scss',
  ],
  templateUrl: `./explore-summary-side-chart-controls.component.html`,
})
export class ExploreSummarySideChartControlsComponent {
  @Input() groupByOptions: SummaryChartAxis[]
  @Input() charts: SummaryChartGridOption[]
  @Input() chartsEntityLimit: number

  @Output() chartsMetricChange = new EventEmitter<{ metric: SummaryChartAxis }>()
  @Output() chartsGroupByChange = new EventEmitter<{
    groupBy: SummaryChartAxis
    chartIndex: number
  }>()
  @Output() chartsGridIndexSelection = new EventEmitter<number>()
  @Output() chartEntityLimitChange = new EventEmitter<number>()
}
