import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core'
import {
  PricingCurveLayer,
  LayerProperties,
  PricingCurveContextTypes,
} from '../../../../model/pricing-curve.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-card-layer-table',
  styleUrls: ['./pricing-curve-card-layer-table.component.scss'],
  templateUrl: './pricing-curve-card-layer-table.component.html',
})
export class PricingCurveCardLayerTableComponent {
  @Input() set pricingCurve(value: PricingCurve) {
    this._pricingCurve = value
    this.dataSource = new MatTableDataSource<PricingCurveLayer>(
      this.pricingCurve.layers.filter(layer => layer.include)
    )
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (layer, property) => {
      if (property === 'el') {
        return layer.el
      } else if (property === 'trol') {
        return layer.trol
      } else {
        return layer.props[property as keyof LayerProperties] ?? ''
      }
    }
  }
  get pricingCurve(): PricingCurve {
    return this._pricingCurve
  }
  _pricingCurve: PricingCurve

  @Input() set context(val: PricingCurveContextTypes) {
    if (val === 'pricing-curve') {
      this.displayedColumns = [
        'clientName',
        'layerName',
        'trol',
        'el',
        'prob_of_attach',
        'prob_of_exhaust',
      ]
    } else if (val === 'credit') {
      this.displayedColumns = [
        'dealName',
        'trancheName',
        'prediction',
        'premium',
        'prob_of_attach',
        'prob_of_exhaust',
      ]
    }
  }

  displayedColumns: string[] = []
  dataSource: MatTableDataSource<PricingCurveLayer> = new MatTableDataSource<PricingCurveLayer>()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
}
