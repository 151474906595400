import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
// @ts-ignore
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  getCustomIndexValues,
  getFixedIndexValue,
} from 'src/app/analysis/layers/indexed-layer'
import { IndexedLayerService } from 'src/app/analysis/layers/indexed-layer.service'
import { PhysicalLayer } from 'src/app/analysis/model/layers.model'
import { CustomIndexProperties } from 'src/app/analysis/properties/properties-panel.model'

@Component({
  selector: 'app-custom-indexed-dialog',
  styles: [
    `
           app-percentage-input {
             flex-direction: row;
           }
     
           app-percentage-input ::ng-deep label {
             width: 100%;
           }
     
           app-percentage-input ::ng-deep input {
             padding-top: 0;
             padding-bottom: 0;
           }
         `,
  ],
  template: `
    <h2 mat-dialog-title>Custom Index</h2>

    <mat-dialog-content>
      <div
        *ngFor="
          let indexValue of indexValues;
          index as i;
          trackBy: trackIndexValueBy
        "
      >
        <app-percentage-input
          name="Settlement Year {{ i + 1 }}"
          [isCession]="true"
          [decimal]="2"
          [value]="indexValue"
          (inputChange)="indexValues[i] = $event"
        ></app-percentage-input>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button appButton accent (click)="onSaveClick()">Save</button>
      <button appButton link mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  `,
})
export class CustomIndexedDialogComponent implements OnInit, OnChanges {
  indexValues: number[] = []

  private readonly physical: PhysicalLayer

  constructor(
    // @ts-ignore
    private dialogRef: MatDialogRef<CustomIndexedDialogComponent>,
    private indexedLayerService: IndexedLayerService,
    @Inject(MAT_DIALOG_DATA) private data: CustomIndexProperties
  ) {
    this.physical = this.data.layer.physicalLayer
  }

  ngOnInit() {
    this.indexValues = this.getIndexValues()
  }

  // @ts-ignore
  ngOnChanges(changes: SimpleChanges) {}

  trackIndexValueBy(i: number, _: number) {
    return i
  }

  getIndexValues() {
    const customIndexValues = getCustomIndexValues(this.physical)

    return customIndexValues ?? this.defaultIndexValues()
  }

  defaultIndexValues() {
    const fixedIndexValue = getFixedIndexValue(this.physical)
    return this.indexedLayerService.defaultIndexValues(fixedIndexValue)
  }

  onSaveClick() {
    this.dialogRef.close(this.indexValues)
  }
}
