import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import {
  AggregationMethodType,
  Perspective,
} from 'src/app/analysis/model/metrics.model'
import { LossFilter } from 'src/app/api/analyzere/analyzere.model'
import {
  CalculatedPortfolioDetailMetrics,
  PortfolioMetrics,
  PortfolioType,
} from '../../analysis/model/portfolio-metrics.model'
import { Size } from '../size.mixin'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.scss'],
})
export class PortfolioDetailsComponent {
  @Input() gross: CalculatedPortfolioDetailMetrics
  @Input() ceded: CalculatedPortfolioDetailMetrics
  @Input() net: CalculatedPortfolioDetailMetrics
  @Input() loading: boolean
  @Input() error: string
  @Input() currency: string
  @Input() size: Size
  @Input() name: string

  @Input() showResults: boolean
  @Input() metrics: PortfolioMetrics
  @Input() rss: number | null
  @Input() lossFilters: LossFilter[]

  @Input() currentCededPortfolioViewID: string | null
  @Input() currentGrossPortfolioViewID: string | null
  @Input() currentNetPortfolioViewID: string | null

  get showPortfolioViewIDs() {
    return !!(
      this.currentCededPortfolioViewID &&
      this.currentGrossPortfolioViewID &&
      this.currentNetPortfolioViewID
    )
  }

  @Input() set dialog(value: any) {
    this._dialog = coerceBooleanProperty(value)
  }
  get dialog() {
    return this._dialog
  }
  _dialog = false

  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }

  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()
  @Output() returnPeriod4Change = new EventEmitter<number>()
  @Output() returnPeriod5Change = new EventEmitter<number>()
  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()
  @Output() lossFilterClick = new EventEmitter<string>()

  get item(): any {
    return this.gross && this.ceded && this.net ? {} : null
  }

  get returnPeriodData() {
    return this.metrics.returnPeriodData
  }

  get returnPeriod1(): number {
    return this.metrics.returnPeriod1
  }

  get returnPeriod2(): number {
    return this.metrics.returnPeriod2
  }

  get returnPeriod3(): number {
    return this.metrics.returnPeriod3
  }

  get returnPeriod4(): number {
    return this.metrics.returnPeriod4
  }

  get returnPeriod5(): number {
    return this.metrics.returnPeriod5
  }

  isNull(value: number | null) {
    return value === null
  }
}
