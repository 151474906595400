import { createAction } from '@ngrx/store'

export const toggleDarkTheme = createAction('[Theme] Toggle Dark')

export const toggleThemeChrome = createAction('[Theme] Toggle Chrome')

export const toggleThemeLayerWireframe = createAction(
  '[Theme] Toggle Layer Wireframe'
)

export const toggleThemeUserCard = createAction('[Theme] Toggle User Card')

export const toggleThemeLogoColor = createAction('[Theme] Toggle Logo Color')
