import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { SortTableColumnFilter, SortTableFilter } from './sort-table-filter'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sort-table-filter',
  styleUrls: ['./sort-table-filter.component.scss'],
  templateUrl: './sort-table-filter.component.html',
})
export class SortTableFilterComponent {
  BLANK = SortTableFilter.BLANK

  @Input() filter: SortTableColumnFilter

  @Output() filterChange = new EventEmitter<void>()

  get values(): (string | number | boolean)[] {
    return this.filter?.values.filter(v => v !== this.BLANK) ?? []
  }

  trackByValue(_: number, value: string | number | boolean) {
    return value
  }

  onSelectAllChange(selection: boolean) {
    this.filter?.selectAll(selection)
    this.filterChange.emit()
  }

  onChange(): void {
    this.filter?.update()
    this.filterChange.emit()
  }
}
