<h1 mat-dialog-title *ngIf="dialog">Portfolio Details for {{name}}</h1>
<div mat-dialog-content class="wrapper">
  <app-loading-item [loading]="loading" [error]="error" [item]="item">
    <table class="tidy-auto-table" *ngIf="gross && ceded && net">
      <thead>
        <tr>
          <th></th>
          <th>Gross</th>
          <th>Ceded</th>
          <th>Net</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Deposit Premium</th>
          <td>
            {{ gross.depositPremium | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.depositPremium | appShortNumber: currency }}
          </td>
          <td>
            {{ net.depositPremium | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Premium</th>
          <td>
            {{ gross.premium | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.premium | appShortNumber: currency }}
          </td>
          <td>
            {{ net.premium | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Expense</th>
          <td>
            {{ gross.expense | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.expense | appShortNumber: currency }}
          </td>
          <td>
            {{ net.expense | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Expected Loss</th>
          <td>
            {{ gross.expectedLoss | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.expectedLoss | appShortNumber: currency }}
          </td>
          <td>
            {{ net.expectedLoss | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Std Dev of Loss</th>
          <td>
            {{ gross.stdDevOfLoss | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.stdDevOfLoss | appShortNumber: currency }}
          </td>
          <td>
            {{ net.stdDevOfLoss | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Expected Underwriting Result</th>
          <td>
            {{ gross.expectedUnderwritingResult | appShortNumber: currency }}
          </td>
          <td>
            {{ ceded.expectedUnderwritingResult | appShortNumber: currency }}
          </td>
          <td>
            {{ net.expectedUnderwritingResult | appShortNumber: currency }}
          </td>
        </tr>
        <tr>
          <th>Expected Loss Ratio</th>
          <td>
            {{
              isNull(gross.expectedLossRatio)
                ? 'NA'
                : (gross.expectedLossRatio | percent: '1.1-1')
            }}
          </td>
          <td>
            {{
              isNull(ceded.expectedLossRatio)
                ? 'NA'
                : (ceded.expectedLossRatio | percent: '1.1-1')
            }}
          </td>
          <td>
            {{
              isNull(net.expectedLossRatio)
                ? 'NA'
                : (net.expectedLossRatio | percent: '1.1-1')
            }}
          </td>
        </tr>
        <tr>
          <th>Expected Combined Ratio</th>
          <td>
            {{
              isNull(gross.expectedCombinedRatio)
                ? 'NA'
                : (gross.expectedCombinedRatio | percent: '1.1-1')
            }}
          </td>
          <td>
            {{
              isNull(ceded.expectedCombinedRatio)
                ? 'NA'
                : (ceded.expectedCombinedRatio | percent: '1.1-1')
            }}
          </td>
          <td>
            {{
              isNull(net.expectedCombinedRatio)
                ? 'NA'
                : (net.expectedCombinedRatio | percent: '1.1-1')
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="showResults" class="results">
      <h1 mat-dialog-title>Portfolio Results</h1>
      <div class="results-flex">
      <div class="score-toggles">
        <div class="subtitle">
          <h5>Perspective</h5>
        </div>
        <app-return-period-toggles [metrics]="metrics" [lossFilters]="lossFilters"
          (portfolioTypeChange)="portfolioTypeChange.emit($event)" (perspectiveChange)="perspectiveChange.emit($event)"
          (aggregationMethodChange)="aggregationMethodChange.emit($event)" (lossFilterClick)="lossFilterClick.emit($event)"></app-return-period-toggles>
      </div>

      <div class="score-table">
        <div class="subtitle table-title">
          <h5>Exceedance Probabilities</h5>
        </div>
        <app-return-period-table [isPortfolioMetrics]="true" [data]="returnPeriodData" [rp1]="returnPeriod1"
          [rp2]="returnPeriod2" [rp3]="returnPeriod3" [rp4]="returnPeriod4" [rp5]="returnPeriod5" [displayFooter]="true"
          (rp1Change)="returnPeriod1Change.emit($event)" (rp2Change)="returnPeriod2Change.emit($event)"
          (rp3Change)="returnPeriod3Change.emit($event)" (rp4Change)="returnPeriod4Change.emit($event)"
          (rp5Change)="returnPeriod5Change.emit($event)"></app-return-period-table>
      </div>
    </div>
    </div>

    <div *ngIf="showPortfolioViewIDs" class="view-ids">
      <div class="view-id">Ceded Portfolio View ID: {{ currentCededPortfolioViewID }}</div>
      <div class="view-id">Gross Portfolio View ID: {{ currentGrossPortfolioViewID }}</div>
      <div class="view-id">Net Portfolio View ID: {{ currentNetPortfolioViewID }}</div>
    </div>
  </app-loading-item>
</div>
<div mat-dialog-actions>
  <button *ngIf="dialog" appButton accent mat-dialog-close="2" cdkFocusInitial>
    Close
  </button>
</div>
