import { Dictionary } from '@ngrx/entity'
import { curry, head, values } from 'ramda'
import { Program } from '../../core/model/program.model'
import { ProgramGroup } from '../store/grouper/program-group.model'
import { ProgramGroupEntity } from '../store/grouper/program-group/program-group.reducer'
import { ProgramEntity } from '../store/grouper/program/program.reducer'
import { createInuranceSymbols, InuranceSymbols } from './inurance-symbol'
import {
  InuranceLevel,
  InuranceMetadataRef,
  InurancePayload,
  InuranceRelationship,
  InuranceTag,
  InuranceTagsByLevel,
  InuranceView,
  Terminal,
} from './inurance.model'
import {
  createLayerInuranceView,
  createStructureInuranceView,
  getInuranceLevelOfRelationship,
  getStructureCededLayers,
  initializeTagsByLevel,
  isSourceLayer,
  isSourceStructure,
  isSourceStructureGroup,
  isTargetLayer,
  isTargetStructure,
  isTargetStructureGroup,
  toInuranceRefs,
} from './inurance.util'
import { Layer } from './layers.model'
import {
  findBackAllocatedVisibleLayer,
  findFHCFHidden1Layer,
  findRiskLargeVisibleLayer,
  findTopAndDropLayers,
  isLayerActualFHCF,
  isLayerActualRisk,
  isLayerActualTopAndDrop,
  isLayerBackAllocated,
  isLayerInurance,
  findMultiSelectVisibleLayer,
  isNoncatIndexlLayer,
  findSwingRatedVisibleLayer,
  findMainLayerOfSectionLayer,
  findIndexedVisibleLayer,
} from './layers.util'
import { isMultiSectionLayer, isSectionLayer } from '../layers/multi-section-layer'
import { isSwingLayer } from '../layers/swing-layer'

type LayerProgramGroupTuple = readonly [Layer, Program, ProgramGroup[]]
interface RefItem {
  ref: InuranceMetadataRef
  terminal: Terminal
  layer: Layer
  program: Program
  programGroup?: ProgramGroup
}
interface RefItemWithAllGroups extends RefItem {
  programGroups: ProgramGroup[]
}
type GroupIDByTerminal = Record<Terminal, Record<string, boolean>>

interface PayloadWithRefItem extends InurancePayload {
  sourceItem?: RefItem
  targetItem?: RefItem
}

interface IDAndVisibleName {
  id: string
  visibleName?: string
}

const getInuranceLayerProgramTuples = (
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): LayerProgramGroupTuple[] => {
  const tuples: LayerProgramGroupTuple[] = []
  structureMap.forEach((programGroupEntities, programEntity) => {
    const program = programEntity.program
    const programGroups = programGroupEntities.map(e => e.programGroup)
    programEntity.cededLayers
      .map(l => l.layer)
      .filter(isLayerInurance)
      .forEach(layer => tuples.push([layer, program, programGroups] as const))
  })
  return tuples
}

function getGroupIDMapByTerminal(
  items: RefItemWithAllGroups[]
): GroupIDByTerminal {
  return items.reduce<GroupIDByTerminal>(
    (acc, { ref, terminal }) => {
      if (terminal === 'target' && isSourceStructureGroup(ref.type)) {
        acc.source[ref.id] = true
      }
      if (terminal === 'source' && isTargetStructureGroup(ref.type)) {
        acc.target[ref.id] = true
      }
      return acc
    },
    { source: {}, target: {} }
  )
}

const toRefItem =
  (
    terminal: Terminal,
    [layer, program, programGroups]: LayerProgramGroupTuple
  ) =>
  (ref: InuranceMetadataRef): RefItemWithAllGroups => ({
    ref,
    terminal,
    layer,
    program,
    programGroups,
  })

const getInuranceRefItems = (
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): RefItem[] => {
  const layerTuples = getInuranceLayerProgramTuples(structureMap)
  const items = layerTuples.flatMap(tuple => {
    const [layer] = tuple
    const sourceRefs = toInuranceRefs(layer.meta_data.inuranceSourceFor)
    const targetRefs = toInuranceRefs(layer.meta_data.inuranceTargetFor)
    return [
      ...sourceRefs.map(toRefItem('source', tuple)),
      ...targetRefs.map(toRefItem('target', tuple)),
    ]
  })
  // Set the ref info's program group only if some other ref is referencing
  // the program group as a source or target
  const hasGroupMapByTerminal = getGroupIDMapByTerminal(items)
  return items.map(item => {
    const { terminal, programGroups } = item
    const hasGroupMap = hasGroupMapByTerminal[terminal]
    const programGroup = programGroups.find(pg => hasGroupMap[pg.id] === true)
    return { ...item, programGroup }
  })
}

function getLayerName(layer: Layer): string {
  if (!layer.meta_data.layerName) {
    return `Unnamed`
  }
  if (isSectionLayer(layer)) {
    const layerName = layer.meta_data.layerName ?? ''
    const description = layer.physicalLayer.description ?? ''
    return `${layerName} - ${description}`
  }
  return layer.meta_data.layerName
}

/** For Shared Limits and T&D, the inurance is on the hidden Back Allocated
 * layer and hidden actual T&D layer respectively, whereas we want to display
 * the tags on the visible (both Top & Drop visible for T&D) layers.
 *
 * In the case of Shared Limits + T&D, inurance is on the hidden Back Allocated
 * which back allocates for the hidden actual T&D layer, which points to the
 * two visible Top and Drop layers.
 */
function getVisibleLayerIDs(
  item: RefItem,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): IDAndVisibleName[] {
  let layer = item.layer
  let visibleName: string | undefined
  const cededLayers = getStructureCededLayers(item.program.id, structureMap)

  if (isLayerBackAllocated(layer)) {
    const baLayer = findBackAllocatedVisibleLayer(cededLayers, layer)
    if (baLayer) {
      layer = baLayer
      visibleName = getLayerName(layer)
    }
  }

  if (isLayerActualTopAndDrop(layer)) {
    return findTopAndDropLayers(cededLayers, layer).map(l => ({
      id: l.id,
      visibleName: getLayerName(l),
    }))
  }

  if (isLayerActualFHCF(layer)) {
    const fhcfHidden1Layer = findFHCFHidden1Layer(cededLayers, layer)
    if (fhcfHidden1Layer) {
      layer = fhcfHidden1Layer
      visibleName = getLayerName(layer)
    }
  }

  if (isLayerActualRisk(layer)) {
    const riskLargeVisibleLayer = findRiskLargeVisibleLayer(cededLayers, layer)
    if (riskLargeVisibleLayer) {
      layer = riskLargeVisibleLayer
      visibleName = getLayerName(layer)
    }
  }

  if (isMultiSectionLayer(layer, 'main-layer')) {
    const multiSelectVisibleLayer = findMultiSelectVisibleLayer(
      cededLayers,
      layer
    )
    if (multiSelectVisibleLayer) {
      layer = multiSelectVisibleLayer
      visibleName = getLayerName(layer)
    }
  }
  if (isSectionLayer(layer)) {
    visibleName = getLayerName(layer)
    const mainLayer = findMainLayerOfSectionLayer(cededLayers, layer)
    if (mainLayer) {
      const visibleLayer = findMultiSelectVisibleLayer(cededLayers, mainLayer)
      if (visibleLayer) {
        layer = visibleLayer
      }
    }
  }
  if (isSwingLayer(layer, 'combined-layer')) {
    const swingVisibleLayer = findSwingRatedVisibleLayer(cededLayers, layer)
    if (swingVisibleLayer) {
      layer = swingVisibleLayer
      visibleName = getLayerName(layer)
    }
  }
  if (isNoncatIndexlLayer(layer)) {
    const visibleLayer = findIndexedVisibleLayer(cededLayers, layer)
    if (visibleLayer) {
      layer = visibleLayer
      visibleName = getLayerName(layer)
    }
  }

  return [{ id: layer.id, visibleName }]
}

function _getSourceIDs(
  item: RefItem,
  structureMap?: Map<ProgramEntity, ProgramGroupEntity[]>
): string | IDAndVisibleName[] | undefined {
  const { ref, terminal, layer, program, programGroup } = item
  if (terminal === 'target') {
    return ref.id
  }
  if (isSourceLayer(ref.type)) {
    if (structureMap) {
      return getVisibleLayerIDs(item, structureMap)
    }
    return layer.id
  }
  if (isSourceStructure(ref.type)) {
    return program.id
  }
  return programGroup && programGroup.id
}

function _getTargetIDs(
  item: RefItem,
  structureMap?: Map<ProgramEntity, ProgramGroupEntity[]>
): string | IDAndVisibleName[] | undefined {
  const { ref, terminal, layer, program, programGroup } = item
  if (terminal === 'source') {
    return ref.id
  }
  if (isTargetLayer(ref.type)) {
    if (structureMap) {
      return getVisibleLayerIDs(item, structureMap)
    }
    return layer.id
  }
  if (isTargetStructure(ref.type)) {
    return program.id
  }
  return programGroup && programGroup.id
}

function asIDAndVisibleNames(
  ids: string | IDAndVisibleName[] | undefined
): IDAndVisibleName[] {
  if (Array.isArray(ids)) {
    return ids
  }
  return ids ? [{ id: ids }] : []
}

function getVisibleSourceIDs(
  item: RefItem,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): IDAndVisibleName[] {
  return asIDAndVisibleNames(_getSourceIDs(item, structureMap))
}

function getVisibleTargetIDs(
  item: RefItem,
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>
): IDAndVisibleName[] {
  return asIDAndVisibleNames(_getTargetIDs(item, structureMap))
}

function getSourceID(item: RefItem): string | undefined {
  const ids = _getSourceIDs(item)
  if (Array.isArray(ids)) {
    throw Error('Unexcepted array in `inurance-tags-creator.getSourceIDs`')
  }
  return ids
}

function getTargetID(item: RefItem): string | undefined {
  const ids = _getTargetIDs(item)
  if (Array.isArray(ids)) {
    throw Error('Unexcepted array in `inurance-tags-creator.getTargetIDs`')
  }
  return ids
}

function buildKey(item?: RefItem): string | undefined {
  if (item) {
    const sourceID = getSourceID(item)
    const targetID = getTargetID(item)
    return sourceID != null && targetID != null
      ? `${sourceID}_${targetID}`
      : undefined
  }
}

function getInuranceDictionaryByRelationship(
  items: RefItem[]
): Record<InuranceRelationship, Record<string, PayloadWithRefItem>> {
  const dictionary: Record<
    InuranceRelationship,
    Record<string, PayloadWithRefItem>
  > = {
    layerToLayer: {},
    layerToStructure: {},
    layerToStructureGroup: {},
    structureToLayer: {},
    structureToStructure: {},
    structureToStructureGroup: {},
    structureGroupToLayer: {},
    structureGroupToStructure: {},
    structureGroupToStructureGroup: {},
  }
  items.forEach(item => {
    const { ref, terminal, layer, program, programGroup } = item
    // Store each inurance pair in the dictionary for its relationship type
    const relationship = ref.type
    const key = buildKey(item)
    if (!key) {
      return
    }
    // Initialize empty value
    if (!dictionary[relationship][key]) {
      dictionary[relationship][key] = { relationship, source: [], target: [] }
    }
    // Set value's source/target tuple if not set
    if (terminal === 'source' && !dictionary[relationship][key].sourceItem) {
      dictionary[relationship][key].sourceItem = item
    }
    if (terminal === 'target' && !dictionary[relationship][key].targetItem) {
      dictionary[relationship][key].targetItem = item
    }
    // Add the inurance reference to source/target
    dictionary[relationship][key][terminal].push({
      layerID: layer.id,
      structureID: program.id,
      structureGroupID: programGroup && programGroup.id,
    })
  })
  return dictionary
}

function createView(
  item: RefItem,
  symbol: string,
  payload: InurancePayload
): InuranceView {
  const { ref, terminal, layer, program, programGroup } = item
  const level = getInuranceLevelOfRelationship(terminal, ref.type)
  switch (level) {
    case 'layer': {
      return createLayerInuranceView(symbol, program, layer, payload)
    }
    case 'program': {
      return createStructureInuranceView(symbol, program, payload)
    }
    case 'programGroup': {
      if (!programGroup) {
        throw Error(
          `Unexpected Error: No structure group in "inurance.createView"`
        )
      }
      return createStructureInuranceView(symbol, programGroup, payload)
    }
  }
}

function appendTag(
  level: InuranceLevel,
  id: string,
  tag: InuranceTag,
  levels: InuranceTagsByLevel
): void {
  if (!levels[level][id]) {
    levels[level][id] = []
  }
  levels[level][id].push(tag)
}

const appendTagPair = curry(
  (
    relationship: InuranceRelationship,
    symbols: InuranceSymbols,
    levels: InuranceTagsByLevel,
    structureMap: Map<ProgramEntity, ProgramGroupEntity[]>,
    refPayload: PayloadWithRefItem
  ): void => {
    const { sourceItem, targetItem, ...payload } = refPayload
    const key = buildKey(sourceItem ?? targetItem)

    if (!targetItem || !key) {
      return
    }

    const symbol = symbols.next(key)
    const targetView = createView(targetItem, symbol, payload)
    const targetIDs = getVisibleTargetIDs(targetItem, structureMap)

    // If source/target is a hidden layer, override the title /w visible name
    // For instance, if a source tag is clicked where the target is a T&D layer,
    // the inurance bar target title will be the Top layer name rather than
    // the hidden layer's name (which the user cannot see)
    const firstTargetID = head(targetIDs)
    if (firstTargetID && firstTargetID.visibleName) {
      targetView.title = firstTargetID.visibleName
    }

    // In Group source/target structure might be loaded or not depending on the users choice.
    // If that structure and its layers are not loaded we show the id as a title.
    // There is no support for deleting inurance when the source structure is not loaded.
    let sourceView: InuranceView = {
      symbol: '',
      title: targetItem.ref.id,
    }
    if (sourceItem) {
      sourceView = createView(sourceItem, symbol, payload)
      const sourceIDs = getVisibleSourceIDs(sourceItem, structureMap)
      const firstSourceID = head(sourceIDs)
      if (firstSourceID && firstSourceID.visibleName) {
        sourceView.title = firstSourceID.visibleName
      }
      const sourceLevel = getInuranceLevelOfRelationship('source', relationship)
      const sourceTag: InuranceTag = {
        ...sourceView,
        terminal: 'source',
        pair: targetView,
      }
      sourceIDs.forEach(({ id, visibleName }) => {
        const tag = visibleName
          ? { ...sourceTag, title: visibleName }
          : sourceTag
        appendTag(sourceLevel, id, tag, levels)
      })
    }

    const targetLevel = getInuranceLevelOfRelationship('target', relationship)
    const targetTag: InuranceTag = {
      ...targetView,
      terminal: 'target',
      pair: sourceView,
    }
    targetIDs.forEach(({ id, visibleName }) => {
      const tag = visibleName ? { ...targetTag, title: visibleName } : targetTag
      appendTag(targetLevel, id, tag, levels)
    })
  }
)

export function createInuranceTagsAndSymbols(
  structureMap: Map<ProgramEntity, ProgramGroupEntity[]>,
  symbolMap: Dictionary<string>
): readonly [InuranceTagsByLevel, Dictionary<string>] {
  const items = getInuranceRefItems(structureMap)
  const dictionaryByRelationship = getInuranceDictionaryByRelationship(items)

  const symbols = createInuranceSymbols(symbolMap)
  const levels = initializeTagsByLevel()
  Object.keys(dictionaryByRelationship).forEach(
    (relationship: InuranceRelationship) => {
      values(dictionaryByRelationship[relationship]).forEach(
        appendTagPair(relationship, symbols, levels, structureMap)
      )
    }
  )
  return [levels, symbols.getMap()] as const
}
