import { Reference } from '@shared/layer-property/layer-property.component'

/** Contains one to many `CreditCalculationStructure` structures */
export interface CreditPortfolio {
  _id?: string
  name: string
  metadata: Record<string, string>
  asset_class: string
  data_type: string
  exposure_base: string
  total_exposure: number
  expense_ratio: number
  portfolio_data: string
  start_business_covered_date?: string
  end_business_covered_date?: string
  gross_loss_scenarios: CreditGrossLossScenario[]
  actuals: CreditPortfolioActual[]
  modified_date: string
  portfolio_results: Record<string, number> | null
  id?: number // needed for use with app-sort-table
}

export interface CreditPortfolioActual {
  actual_gls_data: string
  actual_loan_data: string
  as_of_date: string
}

export interface CreditGrossLossScenario {
  _id?: string
  name: string
  metadata: Record<string, string>
  scenario: string
  model: string
  gross_loss_scenario_data: string
  scenario_mapping_id?: number
  loan_gross_loss_scenario_data: string
  pattach_data: string
  is_default: boolean
  scenario_type: ScenarioType
  stochastic_data?: StochasticData[]
  actuals: CreditGrossLossScenarioActual[]
  modified_date: string
  isActuals?: boolean
}

export type CreditGrossLossScenarioIncluded = CreditGrossLossScenario & {
  includeOnSubmission: boolean
}

export interface CreditGrossLossScenarioActual {
  actual_gls_data: string
  as_of_date: string
}

export interface StochasticData {
  return_period: string
  gross_loss_scenario_data: string
  scenario_mapping_id?: number
}

export interface CreditModelScenarioMapping {
  id: number
  model_name: string
  scenario_name: string
  return_period?: number // applicable for stochastic
}

export enum ScenarioType {
  Stochastic = 'Stochastic',
  Deterministic = 'Deterministic',
}

export const SCENARIO_TYPE_REFERENCES: Reference[] = [
  {
    value: ScenarioType.Deterministic,
    viewValue: ScenarioType.Deterministic,
  },
  {
    value: ScenarioType.Stochastic,
    viewValue: ScenarioType.Stochastic,
  },
]

export const STOCHASTIC_SCENARIO = 'Stochastic'
export const BASELINE_SCENARIO = 'Baseline'
