<h1 mat-dialog-title>
  Ceded Loss Details
</h1>
<ng-container *ngIf="eventLayers.length > 0">
  <div mat-dialog-content class="wrapper">
    <table class="tidy-auto-table">
      <thead>
      <tr>
        <th>Layer Description</th>
        <th>Loss</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let layer of eventLayers">
        <td class="detail">
          {{ layer.layer.physicalLayer.description }}
        </td>
        <td>
          {{
            getValue(layer)
              | appShortNumber: layer.layer.physicalLayer.limit.currency
          }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-container *ngIf="eventLayers.length === 0">
  <span class="no-items">No Items</span>
</ng-container>
<div mat-dialog-actions>
  <button appButton accent mat-dialog-close="2" cdkFocusInitial>
    Close
  </button>
</div>
