<div
  cdkDropList
  cdkDropListOrientation="horizontal"
  class="multi-section-list"
  *ngIf="sections$ | async as sections"
>
  <app-multi-section-card
    #sectionCards
    *ngFor="let section of sections"
    cdkDrag
    cdkDragDisabled="true"
    [section]="section"
    [sections]="sections"
    [mainLayer]="mainLayer"
    [currencyList]="currencyList"
    [filteredCurrencyList]="currencyList"
    [layers]="layers"
    [isLibRE]="isLibRE"
    (stateChange)="onCardStateChange($event)"
    (inuringSelection)="onInuringSelection()"
    (sectionInurance)="onSectionInurance($event)"
    (currencyChangeLayer)="currencyChangeLayer.emit($event)"
  ></app-multi-section-card>
</div>
