<section class="body">
  <div class="wrapper">
    <div class="side">
      <app-program-initiation-filter
        class="filter"
        [accountOpportunities]="accountOpportunities"
        [unfilteredAccountOpportunities]="unfilteredAccountOpportunities"
        [carrierAccounts]="carrierAccounts"
        [currentClientID]="currentClientID"
        (filteredAccountOpportunities)="
          filteredAccountOpportunities.emit($event)
        "
      >
      </app-program-initiation-filter>
      <br />
      <div class="field non-form actions">
        <div>
          <button
            appButton
            border
            center
            [disabled]="!selectedAccount || isSaving"
            (click)="onInitiateClick()"
          >
            <span *ngIf="isSaving"> Processing... </span>
            <span *ngIf="!isSaving"> Initiate Program </span>
          </button>
        </div>
      </div>
    </div>

    <div class="content">
      <app-sort-table
        class="table"
        clamp="2"
        emptyMessage="No opportunities found."
        enableRowClickSelect
        singleRowSelect
        [defaultSort]="defaultSort"
        [singleRowFilter]="rowFilterList"
        [columnDefs]="columnDefs"
        [rows]="accountOpportunitiesRows"
        [paginator]="paginator"
        (selectedChange)="onSelectedChange($event)"
      ></app-sort-table>

      <mat-paginator
        class="paginator"
        showFirstLastButtons
        pageSize="20"
        [pageSizeOptions]="[10, 20, 50, accountOpportunities.length]"
      ></mat-paginator>
    </div>
  </div>
</section>
