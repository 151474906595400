<h1 mat-dialog-title>
  {{ isAdd ? addTitle : editTitle }} Inurance Relationship
</h1>
<div class="content">
  <div class="selection">
    <div class="left">
      <h3>Source</h3>
      <h4>{{ currentSource }}</h4>
      <mat-form-field>
        <mat-label>Source category</mat-label>
        <mat-select
          [disabled]="isEdit"
          [(value)]="sourceSelectedCategory"
          (selectionChange)="onCategorySelection($event.value, 'left')"
        >
          <mat-option *ngFor="let category of categories" [value]="category"
            ><span>{{ category }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="leftShowLayers">
        <mat-form-field>
          <mat-label>Select layers from</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="sourceLayersFromStructure"
            (selectionChange)="
              onLayersFromStructureSelectionChange($event.value, 'source')
            "
          >
            <ng-container *ngFor="let structure of sourceStructures">
              <mat-option
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label + getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div
          *ngIf="
            sourceLayersByStructureIsLoading && sourceLayersFromStructure
          "
        >
          Loading...
        </div>
        <div
          *ngIf="
            sourceLayersFromStructure &&
            !sourceLayersByStructureIsLoading &&
            sourceLayersByStructure[sourceLayersFromStructure.id]
          "
        >
          <mat-form-field>
            <mat-label>Select source layer</mat-label>
            <mat-select
              [disabled]="isEdit"
              [(value)]="sourceLayer"
              (selectionChange)="
                onLayerSelectionChange($event.value, 'source')
              "
            >
              <ng-container
                *ngFor="
                  let layer of sourceLayersByStructure[
                    sourceLayersFromStructure.id
                  ];
                  let i = index
                "
              >
                <mat-option
                  *ngIf="layer.id !== targetLayer?.id"
                  [value]="layer"
                >
                  {{ layer.meta_data.layerName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="
            sourceLayersFromStructure &&
            !sourceLayersByStructureIsLoading &&
            !sourceLayersByStructure[sourceLayersFromStructure.id]
          "
        >
          No layers found for selected structure.
        </div>
      </div>

      <div *ngIf="leftShowStructures">
        <mat-form-field>
          <mat-label>Select source structure</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="sourceStructure"
            (selectionChange)="
              onStructureSelectionChange($event.value, 'source')
            "
          >
            <ng-container *ngFor="let structure of sourceStructures">
              <mat-option
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label }}{{ getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="leftShowGroups">
        <mat-form-field>
          <mat-label>Select source group</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="sourceGroup"
            (selectionChange)="
              onGroupSelectionChange($event.value, 'source')
            "
          >
            <ng-container *ngFor="let group of sourceGroups">
              <mat-option [value]="group">
                {{ group.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- <mat-icon class="arrow">arrow_right_alt</mat-icon> -->
    <div class="middle">
      <h3>Swap Source To</h3>
      <h4>{{ currentSwap }}</h4>
      <mat-form-field>
        <mat-label>Swap category</mat-label>
        <mat-select
          [(value)]="swapSelectedCategory"
          (selectionChange)="onCategorySelection($event.value, 'mid')"
        >
          <mat-option *ngFor="let category of categories" [value]="category"
            ><span>{{ category }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="midShowLayers">
        <mat-form-field>
          <mat-label>Select layers from</mat-label>
          <mat-select
            [(value)]="swapLayersFromStructure"
            (selectionChange)="
              onLayersFromStructureSelectionChange($event.value, 'swap')
            "
          >
            <ng-container *ngFor="let structure of filteredStructures">
              <mat-option
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label + getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div
          *ngIf="swapLayersByStructureIsLoading && swapLayersFromStructure"
        >
          Loading...
        </div>
        <div
          *ngIf="
            swapLayersFromStructure &&
            !swapLayersByStructureIsLoading &&
            swapLayersByStructure[swapLayersFromStructure.id]
          "
        >
          <mat-form-field>
            <mat-label>Select swap layer</mat-label>
            <mat-select
              [(value)]="swapLayer"
              (selectionChange)="
                onLayerSelectionChange($event.value, 'swap')
              "
            >
              <ng-container
                *ngFor="
                  let layer of swapLayersByStructure[
                    swapLayersFromStructure.id
                  ];
                  let i = index
                "
              >
                <mat-option
                  *ngIf="
                    layer.id !== targetLayer?.id &&
                    layer.id !== sourceLayer?.id
                  "
                  [value]="layer"
                >
                  {{ layer.meta_data.layerName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="
            swapLayersFromStructure &&
            !swapLayersByStructureIsLoading &&
            !swapLayersByStructure[swapLayersFromStructure.id]
          "
        >
          No layers found for selected structure.
        </div>
      </div>

      <div *ngIf="midShowStructures">
        <mat-form-field>
          <mat-label>Select swap structure</mat-label>
          <mat-select
            [(value)]="swapStructure"
            (selectionChange)="
              onStructureSelectionChange($event.value, 'swap')
            "
          >
            <ng-container *ngFor="let structure of filteredStructures">
              <mat-option
                *ngIf="
                  structure !== targetStructure &&
                  structure !== sourceStructure
                "
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label }}{{ getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="midShowGroups">
        <mat-form-field>
          <mat-label>Select swap group</mat-label>
          <mat-select
            [(value)]="swapGroup"
            (selectionChange)="onGroupSelectionChange($event.value, 'swap')"
          >
            <ng-container *ngFor="let group of filteredGroups">
              <mat-option
                *ngIf="group !== targetGroup && group !== sourceGroup"
                [value]="group"
              >
                {{ group.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="right">
      <h3>Target</h3>
      <h4>{{ currentTarget }}</h4>
      <mat-form-field>
        <mat-label>Target category</mat-label>
        <mat-select
          [disabled]="isEdit"
          [(value)]="targetSelectedCategory"
          (selectionChange)="onCategorySelection($event.value, 'right')"
        >
          <mat-option *ngFor="let category of categories" [value]="category"
            ><span>{{ category }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="rightShowLayers">
        <mat-form-field>
          <mat-label>Select layers from</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="targetLayersFromStructure"
            (selectionChange)="
              onLayersFromStructureSelectionChange($event.value, 'target')
            "
          >
            <ng-container *ngFor="let structure of targetStructures">
              <mat-option
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label }}{{ getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div
          *ngIf="
            targetLayersByStructureIsLoading &&
            targetLayersFromStructure
          "
        >
          Loading...
        </div>
        <div
          *ngIf="
            targetLayersFromStructure &&
            !targetLayersByStructureIsLoading &&
            targetLayersByStructure[targetLayersFromStructure.id]
          "
        >
          <mat-form-field>
            <mat-label>Select target layer</mat-label>
            <mat-select
              [disabled]="isEdit"
              [(value)]="targetLayer"
              (selectionChange)="
                onLayerSelectionChange($event.value, 'target')
              "
            >
              <ng-container
                *ngFor="
                  let layer of targetLayersByStructure[
                    targetLayersFromStructure.id
                  ];
                  let i = index
                "
              >
                <mat-option
                  *ngIf="layer.id !== sourceLayer?.id"
                  [value]="layer"
                >
                  {{ layer.meta_data.layerName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="
            targetLayersFromStructure &&
            !targetLayersByStructureIsLoading &&
            !targetLayersByStructure[targetLayersFromStructure.id] &&
            !currentLayer
          "
        >
          No layers found for selected structure.
        </div>
      </div>

      <div *ngIf="rightShowStructures">
        <mat-form-field>
          <mat-label>Select target structure</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="targetStructure"
            (selectionChange)="
              onStructureSelectionChange($event.value, 'target')
            "
          >
            <ng-container *ngFor="let structure of targetStructures">
              <mat-option
                [value]="structure"
                matTooltip="{{ structure.label + getStudyName(structure) }}"
                matTooltipPosition="after"
              >
                {{ structure.label }}{{ getStudyName(structure) }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="rightShowGroups">
        <mat-form-field>
          <mat-label>Select target group</mat-label>
          <mat-select
            [disabled]="isEdit"
            [(value)]="targetGroup"
            (selectionChange)="
              onGroupSelectionChange($event.value, 'target')
            "
          >
            <ng-container *ngFor="let group of targetGroups">
              <mat-option [value]="group">
                {{ group.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngIf="!activeAction; else actionRunning">
      <button
        appButton
        accent
        cdkFocusInitial
        [disabled]="isSaveValid()"
        (click)="onSaveClick()"
      >
        {{ saveText }}
      </button>
    </ng-container>
    <ng-template #actionRunning>
      <div class="action-run">
        <app-active-action
          [value]="activeAction"
          [size]="'body'"
        ></app-active-action>
      </div>
    </ng-template>
    <button
      appButton
      primary
      mat-dialog-close="2"
      cdkFocusInitial
      (click)="onDoneClick()"
    >
      {{ isEdit ? 'Cancel' : 'Done' }}
    </button>
  </div>
</div>
