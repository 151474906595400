import { Component, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, Subject, take, takeUntil } from 'rxjs'
import { AppState } from './../../../core/store/index'
import { CreditStructure } from './../../../credit/model/credit-structure.model'
import { CreditStructureGroup } from './../../../credit/model/credit-structure-group.model'
import { CreditModuleContext } from './../../../credit/model/credit-routes.model'
import {
  selectCreditModuleContext,
  selectCreditStructureGroups,
  selectCreditStructures,
} from './../../../credit/store/credit.selectors'
import { selectCurrentCreditGroupMembers } from './../../../credit/store/group/credit-group.selectors'
import * as fromCreditActions from '../../../credit/store/credit.actions'
import * as fromCreditGroupActions from '../../../credit/store/group/credit-group.actions'
import * as fromCreditCompareActions from '../../../credit/store/compare/credit-compare.actions'

@Component({
  selector: 'app-credit-tier-structures-container',
  templateUrl: './credit-tier-structures.container.html',
})
export class CreditTierStructuresContainerComponent
  implements OnInit, OnDestroy
{
  private destroy$ = new Subject()
  private moduleContext: CreditModuleContext
  creditStructures$: Observable<CreditStructure[]>
  creditStructureGroups$: Observable<CreditStructureGroup[]>

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.creditStructures$ = this.store.select(selectCreditStructures)
    this.creditStructureGroups$ = this.store.select(selectCreditStructureGroups)
    this.store
      .pipe(select(selectCreditModuleContext), takeUntil(this.destroy$))
      .subscribe(moduleContext => (this.moduleContext = moduleContext))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onCreditStructureCheckChange(structure: CreditStructure): void {
    const creditStructure: CreditStructure = {
      ...structure,
      checked: !structure.checked,
    }
    this.store.dispatch(
      fromCreditActions.ToggleCreditStructureChecked({ creditStructure })
    )

    if (this.moduleContext === CreditModuleContext.Group) {
      this.dispatchCreditGroupActionsForStructure(creditStructure)
    }
    if (this.moduleContext === CreditModuleContext.Compare) {
      this.dispatchCreditCompareActionsForStructure(creditStructure)
    }
  }

  onCreditStructureGroupSelectionChange(
    structureGroup: CreditStructureGroup
  ): void {
    const creditStructureGroup: CreditStructureGroup = {
      ...structureGroup,
      checked: !structureGroup.checked,
    }
    if (this.moduleContext === CreditModuleContext.Group) {
      this.dispatchCreditGroupActionsForGroup(creditStructureGroup)
    }
    if (this.moduleContext === CreditModuleContext.Compare) {
      this.dispatchCreditCompareActionsForGroup(creditStructureGroup)
    }
  }

  private dispatchCreditGroupActionsForStructure(
    creditStructure: CreditStructure
  ): void {
    if (!creditStructure.checked) {
      this.store
        .pipe(select(selectCurrentCreditGroupMembers), take(1))
        .subscribe(groupMembers => {
          if (groupMembers?.length === 1) {
            this.store.dispatch(
              fromCreditGroupActions.RemoveCreditStructureGroup()
            ) // if only one structure in the group, just remove group from tower
            return
          }
          const creditCalculationStructure = groupMembers?.find(
            member => member.creditStructure.id === creditStructure.id
          )?.creditCalculationStructure
          if (!creditCalculationStructure) {
            return
          }
          this.store.dispatch(
            fromCreditGroupActions.RemoveStructureFromGroup({
              creditStructure,
              creditCalculationStructure,
            })
          )
        })
      return
    }
    this.store.dispatch(
      fromCreditGroupActions.AddStructureToGroup({ creditStructure })
    )
  }

  private dispatchCreditCompareActionsForStructure(
    creditStructure: CreditStructure
  ): void {
    const structureId = creditStructure.credit_calculation_structure_id
    if (!creditStructure.checked) {
      this.store.dispatch(
        fromCreditCompareActions.RemoveCreditStructureCompareEntity({
          structureId,
        })
      )
      return
    }
    this.store.dispatch(
      fromCreditCompareActions.GetCreditCompareMetricsForStructure({
        creditStructure,
      })
    )
  }

  private dispatchCreditGroupActionsForGroup(
    creditStructureGroup: CreditStructureGroup
  ): void {
    this.store.dispatch(fromCreditGroupActions.RemoveCreditStructureGroup()) // remove previously selected group from state
    this.store.dispatch(
      fromCreditActions.ToggleCreditStructureGroupChecked({
        creditStructureGroup,
      })
    )
    if (!creditStructureGroup.checked) {
      return
    }
    this.store.dispatch(
      fromCreditGroupActions.FetchCreditStructureGroupDetails({
        creditStructureGroup,
      })
    )
  }

  private dispatchCreditCompareActionsForGroup(
    creditStructureGroup: CreditStructureGroup
  ): void {
    const structureId = creditStructureGroup.credit_calculation_structure_id
    this.store.dispatch(
      fromCreditActions.ToggleCreditStructureGroupChecked({
        creditStructureGroup,
      })
    )
    if (!creditStructureGroup.checked) {
      this.store.dispatch(
        fromCreditCompareActions.RemoveCreditStructureCompareEntity({
          structureId,
        })
      )
      return
    }
    // get thumbnails first, then metrics data
    this.store.dispatch(
      fromCreditCompareActions.GetCreditCompareThumbnailsForStructureGroup({
        creditStructureGroup,
      })
    )
  }
}
