import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { HasID, HasName } from '../core/model/general.model'
import {
  HierarchicalEntityResult,
  HierarchicalEntitySelectState,
} from './hierarchical-entity-select.service'

export type HierarchicalEntity = HasName &
  HasID & {
    description?: string
    abbreviation?: string
    groupID: string
    groupName: string
    subGroupID?: string
    subGroupName?: string
    level: 1 | 2 | 3 | 4
    term: string
    groupCount: number
    subGroupCount: number
    csGroupStatEntityId?: string
    crossSectorOverride?: boolean
  }

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-hierarchical-entity-select',
  styles: [
    `
      :host {
        display: block;
        overflow: auto;
        user-select: none;
        outline: none;
        --hier-row-padding-left: 24px;
        --hier-row-indent: 40px;
      }

      :host,
      .viewport {
        height: 100%;
        width: 100%;
      }

      :host
        .viewport.cdk-virtual-scroll-orientation-vertical
        ::ng-deep
        .cdk-virtual-scroll-content-wrapper {
        width: 100%;
      }

      .row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 32px;
        color: var(--body);
        background: transparent;
        overflow: hidden;
        cursor: pointer;
        transition: background 250ms, color 250ms;
      }

      .row.expand-only {
        color: var(--subtle);
      }

      .indent {
        flex: 0;
        height: 100%;
        width: 0;
        border: 1px solid var(--border-translucent);
        border-width: 0;
        margin-right: 2px;
        box-sizing: border-box;
      }

      .indent-2 {
        flex: 0;
        height: 100%;
        width: 0;
        border: 1px solid var(--border-translucent);
        border-width: 0;
        margin-right: 2px;
        box-sizing: border-box;
      }

      .row.level-2 > .indent {
        border-width: 0 0 0 1px;
        margin-left: var(--hier-row-padding-left);
      }

      .row.level-3 > .indent {
        flex: 0 0 var(--hier-row-indent);
        border-width: 0 1px 0 1px;
        margin-left: var(--hier-row-padding-left);
      }

      .row.level-4 > .indent {
        flex: 0 0 var(--hier-row-indent);
        border-width: 0 1px 0 1px;
        margin-left: var(--hier-row-padding-left);
      }

      .row.level-4 > .indent-2 {
        flex: 0 0 var(--hier-row-indent);
        border-width: 0 1px 0 0;
        margin-left: 0;
      }

      .row-inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1 0%;
        height: 100%;
        padding-left: calc(var(--hier-row-padding-left) - 2px);
        margin-top: 1px;
        transition: background 250ms, color 250ms;
      }

      .row.level-2 > .row-inner {
        padding-left: calc(var(--hier-row-indent) - 4px);
      }

      .row.level-3 > .row-inner {
        padding-left: calc(var(--hier-row-indent) - 2px);
      }

      .row.level-4 > .row-inner {
        padding-left: calc(var(--hier-row-indent) - 6px);
      }

      .row.disabled > .row-inner,
      .row.related > .row-inner {
        color: var(--hint);
      }

      .row.disabled > .row-inner {
        cursor: auto;
      }

      .row.selected > .row-inner {
        color: var(--body-inverse);
        background: var(--accent);
      }

      .row:not(.at-index):not(.disabled):hover > .row-inner,
      .row:not(.at-index):not(.disabled):focus > .row-inner,
      .row:not(.at-index):not(.related):hover > .row-inner,
      .row:not(.at-index):not(.related):focus > .row-inner {
        background: var(--bg-1-translucent-subtle);
      }

      .row.selected:hover > .row-inner,
      .row.selected:focus > .row-inner {
        color: var(--accent-lit);
        background: var(--bg-accent-translucent);
      }

      .row.selected:hover:focus > .row-inner {
        background: var(--accent);
      }

      :host.multiple .row.selected > .row-inner {
        color: var(--body);
        background: var(--bg-accent-translucent);
      }

      :host.multiple .row.selected:hover > .row-inner,
      :host.multiple .row.selected:focus > .row-inner {
        color: var(--accent-lit);
      }

      .row.at-index {
        cursor: auto;
      }

      .row-inner > .name {
        flex: 1 1 0%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .row-inner > .name {
        font-family: var(--font-header-family);
      }

      .row-inner > .info {
        position: absolute;
        top: 4px;
        right: 13px;
        font-family: var(--font-header-family);
        font-size: var(--font-size-small);
        color: var(--subtle);
        background: var(--bg-2);
        border-radius: var(--border-radius);
        padding: 3px 24px 4px var(--inset-small);
        transition: background 250ms, color 250ms;
      }

      .row-inner > .info.expanded {
        color: var(--primary-lit);
        background: var(--bg-3);
      }

      .row-inner > .info:hover,
      .row-inner > .info:focus {
        color: var(--primary);
        background: var(--bg-2-lit);
      }

      .row-inner > .info.expanded:hover,
      .row-inner > .info.expanded:focus {
        color: var(--primary-lit);
        background: var(--bg-3);
      }

      .row-inner > .info > mat-icon {
        position: absolute;
        top: 0px;
        right: -1px;
        font-size: var(--font-size-big);
        margin: 0 var(--inset-tiny);
      }

      svg {
        position: absolute;
        right: -9px;
        fill: none;
      }

      svg > path {
        fill: var(--accent);
      }

      .row.selected > .row-inner > svg > path {
        fill: var(--body-inverse);
      }

      .empty {
        padding-top: var(--stack-big);
        padding-left: var(--inset-big);
      }
    `,
  ],
  template: `
    <cdk-virtual-scroll-viewport
      *ngIf="!emptyLabel; else emptyTemplate"
      class="viewport"
      [itemSize]="32"
    >
      <div
        *cdkVirtualFor="
          let e of state.entities;
          let i = index;
          trackBy: trackByEntity
        "
        class="row"
        [class.level-2]="e.item.level === 2"
        [class.level-3]="e.item.level === 3"
        [class.level-4]="e.item.level === 4"
        [class.selected]="isSelected(e)"
        [class.expand-only]="state.filtered && e.matches.length === 0"
        [class.disabled]="disabledIDs?.has(e.item.id)"
        [class.related]="isRelated(e)"
      >
        <div class="indent"></div>
        <div *ngIf="e.item.level === 4" class="indent-2"></div>
        <div class="row-inner" (click)="onSelect(e)">
          <span class="name">{{ e.item.name }}</span>
          <span
            *ngIf="getCount(e)"
            class="info"
            [class.expanded]="isExpanded(e)"
            (click)="onInfoClick($event, e)"
            >{{ getInfo(e) }}<mat-icon inline>{{ getIcon(e) }}</mat-icon></span
          >
        </div>
      </div>
    </cdk-virtual-scroll-viewport>

    <ng-template #emptyTemplate>
      <div class="empty">
        <label *ngIf="emptyLabel" big appInfoText>{{ emptyLabel }}</label>
      </div>
    </ng-template>
  `,
})
export class HierarchicalEntitySelectComponent {
  @Input() state: HierarchicalEntitySelectState
  @Input() disabledIDs?: Set<string | number>
  @Input() relatedIDs?: Set<string>
  @Input() selectedByID: Record<string | number, HierarchicalEntity> = {}
  @Input() emptyMessage?: string

  @HostBinding('class.multiple')
  private _multiple = false
  @Input() set multiple(value: any) {
    this._multiple = coerceBooleanProperty(value)
  }
  get multiple() {
    return this._multiple
  }

  @Output() toggleExpand = new EventEmitter<HierarchicalEntity>()
  @Output() selectEntity = new EventEmitter<HierarchicalEntity>()

  get emptyLabel(): string | undefined {
    if (!this.state.entities?.length) {
      return this.emptyMessage ?? 'No items to display.'
    }
  }

  getCount(entity: HierarchicalEntityResult): number {
    return entity.item.groupCount + entity.item.subGroupCount
  }

  getInfo(entity: HierarchicalEntityResult): string | undefined {
    const count = this.getCount(entity)
    if (count === 0) {
      return
    }
    const prefix = entity.filteredCount ? `${entity.filteredCount} of ` : ''
    return `${prefix}${count} companies`
  }

  isSelected(entity: HierarchicalEntityResult): boolean {
    return this.selectedByID[entity.item.id] != null
  }

  isRelated(entity: HierarchicalEntityResult): boolean {
    return this.relatedIDs?.has(String(entity.item.id)) === true
  }

  isExpanded(entity: HierarchicalEntityResult): boolean {
    return this.state.expandedByID[entity.item.id] === true
  }

  getIcon(entity: HierarchicalEntityResult): string {
    return this.isExpanded(entity) ? 'expand_less' : 'expand_more'
  }

  onSelect(entity: HierarchicalEntityResult) {
    if (!this.disabledIDs?.has(entity.item.id)) {
      this.selectEntity.emit(entity.item)
    }
  }

  onInfoClick(
    $event: MouseEvent | TouchEvent,
    entity: HierarchicalEntityResult
  ): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.toggleExpand.emit(entity.item)
  }

  trackByEntity(i: number, entity: HierarchicalEntityResult) {
    if (entity?.item.id) {
      return entity.item.id
    }
    return i
  }
}
