export default function addLegend(
  chart: any,
  width: number,
  legendValues: string[],
  colors: any,
  revenueDashboard?: boolean,
  graphType?: string,
  isForeCast?: boolean,
  lightChartMode?: boolean,
  fontSize?: string,
  intitialOffSetX?: number,
  threeWide?: boolean
): void {
  const getWidth = (val: string): number => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return val.length * 8
    } else {
      context.font = `${fontSize ?? '14px'} Sarabun`
      return context.measureText(val).width
    }
  }
  const legendSize = 12
  const legendSpacing = 4
  const offsetX =
    graphType === 'Global' ? (isForeCast ? -270 : -290) : (intitialOffSetX ?? -150)
  const offsetY =
    graphType && graphType !== 'Period' && graphType !== 'ActualAndBudget'
      ? -90
      : -50
  const legendDirection = (_: any, i: number) => {
    const prevEntriesWidth = legendValues
      .slice(0, i + 1)
      .reduce((total, val) => {
        const width = getWidth(val)
        if (isNaN(width)) {
          return total + 50 + 50
        }
        return total + width + 50
      }, 0)
    return `translate(-${prevEntriesWidth - offsetX}, 10)`
  }

  const legend = chart
    .append('g')
    .attr('transform', `translate(${width - 150}, -25)`)

  const legendItems = legend
    .selectAll('.legend-item')
    .data(legendValues)
    .enter()
    .append('g')
    .attr('class', 'legend-item')
    .attr(
      'transform',
      revenueDashboard
        ? (_: any, i: number) => {
            return `translate(${offsetX}, ${
              offsetY + (legendSize + legendSpacing) * i
            })`
          }
        : legendDirection
    )

  legendItems
    .append('circle')
    .attr(
      'cx',
      revenueDashboard && graphType !== 'ActualAndBudget' && !isForeCast
        ? 30
        : 0
    )
    .attr('cy', revenueDashboard ? 24 : 6)
    .attr('r', threeWide ? 4 : 6)
    .style('fill', (_: any, i: number) => colors(String(i)))

  legendItems
    .append('text')
    .attr(
      'x',
      revenueDashboard
        ? isForeCast
          ? 15
          : graphType !== 'ActualAndBudget'
            ? 45
            : 15
        : threeWide
          ? 10
          : 15
    )
    .attr('y', revenueDashboard ? 30 : threeWide ? 10 : 12)
    .text((d: string) => d)
    .style('fill', lightChartMode ? 'black' : 'var(--body)')
    .style('font-size', revenueDashboard ? '15px' : fontSize)
}
