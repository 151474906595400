import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store'
import { AppState } from '../../core/store'
import { TierNames } from './../../tier/tier.model'
import {
  selectCurrentClient,
  selectCurrentStudy,
  selectCurrentYear,
} from './../../core/store/broker/broker.selectors'
import * as fromCredit from './credit.reducer'

export const selectCreditState: MemoizedSelector<AppState, fromCredit.State> =
  createFeatureSelector(fromCredit.CREDIT_FEATURE_KEY)

export const selectCreditModuleContext = createSelector(
  selectCreditState,
  state => state.moduleContext
)

export const selectCurrentPortfolio = createSelector(
  selectCreditState,
  state => state?.currentPortfolio
)
export const selectCurrentPortfolioId = createSelector(
  selectCurrentPortfolio,
  state => state?._id
)
export const selectGrossLossScenarios = createSelector(
  selectCurrentPortfolio,
  state => state?.gross_loss_scenarios || []
)
export const selectTotalExposure = createSelector(
  selectCurrentPortfolio,
  state => state?.total_exposure
)
export const selectDisableLoanDependentTabs = createSelector(
  selectCurrentPortfolio,
  state =>
    !state?.gross_loss_scenarios.some(
      scenario => scenario.loan_gross_loss_scenario_data
    )
)

export const selectCreditStructures = createSelector(
  selectCreditState,
  state => state?.creditStructures
)
export const selectCreditStructureNames = createSelector(
  selectCreditStructures,
  structures => structures.map(structure => structure.label)
)
export const selectCheckedCreditStructures = createSelector(
  selectCreditStructures,
  state => state?.filter(s => s.checked)
)

export const selectCreditStructureGroups = createSelector(
  selectCreditState,
  state => state?.creditStructureGroups
)
export const selectCheckedCreditStructureGroups = createSelector(
  selectCreditStructureGroups,
  state => state?.filter(s => s.checked)
)

export const selectCreditSubmissionStructures = createSelector(
  selectCreditState,
  state => state.creditSubmissionStructures
)
export const selectCreditSubmissionStructureNames = createSelector(
  selectCreditSubmissionStructures,
  structures => structures.map(structure => structure.label)
)

export const selectCurrentCalculationStructure = createSelector(
  selectCreditState,
  state => state.currentCalculationStructure
)
export const selectCurrentStructureName = createSelector(
  selectCurrentCalculationStructure,
  state => state?.name || ''
)

export const selectCreditTierBreadcrumbs = createSelector(
  selectCurrentClient,
  selectCurrentYear,
  selectCurrentStudy,
  selectCurrentCalculationStructure,
  (client, year, program, structure): TierNames => {
    return {
      client: client?.name,
      year: year?.year,
      program: program?.name,
      structure: structure?.name,
    }
  }
)

export const selectTransactions = createSelector(
  selectCurrentCalculationStructure,
  state => {
    return state?.transactions
  }
)

export const selectCurrentTransaction = createSelector(
  selectCreditState,
  state => state?.currentTransaction
)

export const selectSelectedDefaultScenario = createSelector(
  selectCurrentCalculationStructure,
  state => state?.selected_default_scenario
)

export const selectSelectedGrossScenarios = createSelector(
  selectCurrentCalculationStructure,
  state => state?.selected_scenario
)

export const selectCreditResults = createSelector(
  selectCreditState,
  state => state?.creditResults
)

export const selectDefaultScenarioResults = createSelector(
  selectCreditState,
  state => state?.defaultScenarioResults
)

export const selectTowerControls = createSelector(
  selectCreditState,
  state => state?.towerControls
)

export const selectActualsAsOfDate = createSelector(
  selectCurrentPortfolio,
  state => state.actuals.slice(0, 1).shift()?.as_of_date ?? ''
)
