import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../core/store'
import { Observable } from 'rxjs'
import { ReinsurerTrackingSubjectivity } from '../models/quote.model'
import { fetchSubjectivitiesByReinsurer } from '../store/reinsurer/reinsurer.actions'
import { selectReinsurerTrackingSubjectivities, selectLoading } from '../../core/store/reinsurerSubjectivities.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-reinsurer-subjectivity-dialog-container',
  templateUrl: './reinsurer-subjectivity-dialog.container.html',
})
export class QuoteReinsurerSubjectivityContainerComponent implements OnInit {
  subjectivities$: Observable<ReinsurerTrackingSubjectivity[] | null>
  isLoading$: Observable<boolean>
  tpRef: string | number
  reinsurerName: string
  constructor(
    public dialogRef: MatDialogRef<QuoteReinsurerSubjectivityContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tpRef: string | number,
      reinsurerName: string
    },
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.tpRef = this.data.tpRef
    this.reinsurerName = this.data.reinsurerName
    this.store.dispatch(fetchSubjectivitiesByReinsurer({tpRef: String(this.tpRef)}))
    this.subjectivities$ = this.store.pipe(select(selectReinsurerTrackingSubjectivities))
    this.isLoading$ = this.store.pipe(select(selectLoading))
  }

  onCloseDialog(): void {
    this.dialogRef.close()
  }
}
