import { createAction, props } from '@ngrx/store'
import {
  ScenarioEvent,
  AddScenarioEventPayload,
} from '../../animated-scenarios.model'

export const addAllGroupScenarioEvents = createAction(
  '[Animated Scenarios] Add All Group Scenario Events',
  props<{ events: AddScenarioEventPayload[] }>()
)

export const addGroupScenarioEvent = createAction(
  '[Animated Scenarios] Add Group Scenario Event',
  props<{ event: AddScenarioEventPayload }>()
)

export const updateGroupScenarioEvent = createAction(
  '[Animated Scenarios] Update Group Scenario Event',
  props<{ id: number; changes: Partial<ScenarioEvent> }>()
)

export const removeGroupScenarioEvent = createAction(
  '[Animated Scenarios] Remove Group Scenario Event',
  props<{ id: number }>()
)

export const removeAllGroupScenarioEvents = createAction(
  '[Animated Scenarios] Remove All Group Scenario Events'
)
