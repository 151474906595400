import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ScaleLossSetContainerComponent } from './scale-loss-set-container.component'

@Injectable()
export class ScaleLossSetDialogService {
  constructor(public dialog: MatDialog) {}

  open() {
    return this.dialog.open(ScaleLossSetContainerComponent, {
      width: '75vw',
      disableClose: true,
      panelClass: 'custom-dialog-box',
      autoFocus: false,
    })
  }
}
