import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
} from '@angular/core'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { ScenarioEventResult, EventLayer } from '../animated-scenarios.model'
import { Program } from 'src/app/core/model/program.model'
import { SharedIDPortfolio } from '../../model/portfolio-set.model'
import { isLayerAgg } from '../../model/layers.util'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { ScenarioEventResultTableComponent } from '../event-result-table/event-result-table.component'
import { MatDialog } from '@angular/material/dialog'
import { CededLossDetailsDialogComponent } from '../ceded-loss-details-dialog/ceded-loss-details-dialog.component'
import { Study } from '../../../core/model/study.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-animated-event-result',
  styleUrls: ['./event-result.component.scss'],
  templateUrl: './event-result.component.html',
})
export class ScenarioEventResultComponent {
  @Input() scenarioEventsResult: ScenarioEventResult[]
  @Input() structure: Program | undefined
  @Input() sharedIDPortfolio: SharedIDPortfolio[]
  @Input() originalLayers: LayerState[]
  @Input() originalStructure: Program
  @Input() animating: boolean
  @Input() studies: Study[]
  @Input() name: string

  @Output() animationEnd = new EventEmitter()

  @ViewChildren(ScenarioEventResultTableComponent)
  eventResultTableComponents: QueryList<ScenarioEventResultTableComponent>

  @ViewChild('swiper', { static: false })
  set swiperRef(val: ElementRef) {
    this._swiperRef = val
  }
  get swiperRef() {
    return this._swiperRef
  }
  _swiperRef: ElementRef

  configGroup: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    watchOverflow: true,
    touchStartPreventDefault: false,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  constructor(private dialog: MatDialog) {}

  showAgg(result: ScenarioEventResult): boolean {
    let showAgg = false
    result.layers.forEach((layer: EventLayer) => {
      if (isLayerAgg(layer.layer)) {
        showAgg = true
      }
    })
    return showAgg
  }

  onEventAnimationIndex(index: number): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slideTo(index - 1)
      this.eventResultTableComponents.toArray()[index - 1].interpolate()
    }
  }

  onCededLossClick(data: ScenarioEventResult): void {
    this.dialog.open(CededLossDetailsDialogComponent, { data })
  }

  onPrev(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slidePrev()
    }
  }

  onNext(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.slideNext()
    }
  }
}
