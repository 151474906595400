import {inject, Injectable} from '@angular/core'
import { Action, Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../core/store'
import { QuoteManagementInformationService } from '../../../api/quote-management-information/quote-management-information.service'
import { rejectError } from 'src/app/api/util'
import { QuoteTabType } from '../model/quote-management-information.model'
import { getKeyValuePairsForFiltersAndIntervals } from '../utils/quote-management-information.util'
import * as fromQuoteMIActions from './quote-management-information.actions'
import * as fromQuoteMISelectors from './quote-management-information.selectors'
import * as fromQuoteMIChartActions from './charts/quote-management-information-charts.actions'
import * as fromQuoteMITrendActions from './trends/quote-management-information-trends.actions'
@Injectable()
export class QuoteManagementInformationEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private quoteMIService: QuoteManagementInformationService
  ) {}

  loadInitialSelectorsWithoutDefaultLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromQuoteMIActions.loadInitialSelectorsWithoutDefaultLayers,
        fromQuoteMIActions.updateQuoteMIAudienceView
      ),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView)
      ),
      switchMap(([_, audienceView]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(audienceView, [], [])
        return this.quoteMIService.getQuoteSelectors(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteSelectorsFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView)
      ),
      switchMap(([selectorsDto, audienceView]) => {
        return [
          fromQuoteMIActions.fetchQuoteSelectorsSuccess({
            selectorsDto,
            audienceView,
          }),
          fromQuoteMIActions.loadInitialData(),
        ]
      })
    )
  )

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromQuoteMIActions.loadInitialData,
        fromQuoteMIActions.updateQuoteMIActiveTab,
        fromQuoteMIActions.filtersChanged,
        fromQuoteMIActions.layerIntervalsChanged,
        fromQuoteMIActions.timeIntervalsChanged,
        fromQuoteMIActions.updateSelectedChartsMetric
      ),
      switchMap(() => this.quoteMIService.getLastRefreshDate()),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.loadInitialDataFailure({ error })
        )
      ),
      withLatestFrom(this.store.select(fromQuoteMISelectors.selectActiveTab)),
      switchMap(([lastRefreshDate, activeTab]) => {
        const actions: Action[] = []
        actions.push(
          fromQuoteMIActions.fetchQuoteDataCounts(),
          fromQuoteMIActions.fetchLastRefreshDateSuccess({ lastRefreshDate }),
          fromQuoteMIActions.fetchQuoteSelectors()
        )
        switch (activeTab.tabType) {
          case QuoteTabType.SUMMARY:
            actions.push(fromQuoteMIActions.fetchQuoteSummaryData())
            break
          case QuoteTabType.DETAILS:
            actions.push(fromQuoteMIActions.fetchQuoteDetailsData())
            break
          case QuoteTabType.RANKING:
            actions.push(fromQuoteMIActions.fetchQuoteRankingData())
            break
          case QuoteTabType.CHARTS:
            actions.push(fromQuoteMIChartActions.fetchAllQuoteChartsData())
            break
          case QuoteTabType.TRENDS:
            actions.push(fromQuoteMITrendActions.fetchAllQuoteTrendsData())
            break
        }
        return actions
      })
    )
  )

  fetchQuoteSelectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.fetchQuoteSelectors),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMISelectors.selectActiveTab)
      ),
      switchMap(([_, audienceView, filters, intervals,activeTab]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals,
          activeTab.tabType
        )
        return this.quoteMIService.getQuoteSelectors(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteSelectorsFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView)
      ),
      switchMap(([selectorsDto, audienceView]) => {
        return [
          fromQuoteMIActions.fetchQuoteSelectorsSuccess({
            selectorsDto,
            audienceView,
          }),
        ]
      })
    )
  )

  fetchSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.fetchQuoteSummaryData),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMISelectors.selectActiveTab)
      ),
      switchMap(([_, audienceView, filters, intervals,activeTab]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals,
          activeTab.tabType
        )
        return this.quoteMIService.getQuoteSummaryData(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteSummaryDataFailure({ error })
        )
      ),
      switchMap(quoteSummaryData => {
        return [
          fromQuoteMIActions.fetchQuoteSummaryDataSuccess({
            quoteSummaryData,
          }),
          fromQuoteMIActions.updateQuoteSummaryContent() /* update summary content with updated data */,
        ]
      })
    )
  )

  updateSummaryContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.updateQuoteSummaryContent),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMISummaryData),
        this.store.select(fromQuoteMISelectors.selectQuoteMISelectors)
      ),
      map(([_, audienceView, summaryData, selectors]) =>
        fromQuoteMIActions.updateQuoteSummaryContentSuccess({
          audienceView,
          selectors,
          summaryData,
        })
      )
    )
  )

  fetchDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.fetchQuoteDetailsData),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMISelectors.selectQuoteMISelectors),
        this.store.select(fromQuoteMISelectors.selectActiveTab)
      ),
      switchMap(([_, audienceView, filters, intervals, __,activeTab]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals,
          activeTab.tabType
        )
        return this.quoteMIService.getQuoteDetailsData(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteDetailsDataFailure({ error })
        )
      ),
      map(quoteDetailsData => {
        return fromQuoteMIActions.fetchQuoteDetailsDataSuccess({
          quoteDetailsData,
        })
      })
    )
  )

  fetchRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.fetchQuoteRankingData),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMISelectors.selectQuoteMISelectors),
        this.store.select(fromQuoteMISelectors.selectActiveTab)
      ),
      switchMap(([_, audienceView, filters, intervals, __,activeTab]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals,
          activeTab.tabType
        )
        return this.quoteMIService.getQuoteRankingData(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteRankingDataFailure({ error })
        )
      ),
      map(quoteRankingData => {
        return fromQuoteMIActions.fetchQuoteRankingDataSuccess({
          quoteRankingData,
        })
      })
    )
  )

  fetchDataQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteMIActions.fetchQuoteDataCounts),
      withLatestFrom(
        this.store.select(fromQuoteMISelectors.selectQuoteMIAudienceView),
        this.store.select(fromQuoteMISelectors.selectQuoteMIFilters),
        this.store.select(fromQuoteMISelectors.selectAllIntervals),
        this.store.select(fromQuoteMISelectors.selectQuoteMISelectors),
        this.store.select(fromQuoteMISelectors.selectActiveTab)
      ),
      switchMap(([_, audienceView, filters, intervals, __,activeTab]) => {
        const kvp = getKeyValuePairsForFiltersAndIntervals(
          audienceView,
          filters,
          intervals,
          activeTab.tabType
        )
        return this.quoteMIService.getQuoteDataCounts(kvp)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteMIActions.fetchQuoteDataCountsFailure({ error })
        )
      ),
      map(quoteDataCounts => {
        return fromQuoteMIActions.fetchQuoteDataCountsSuccess({
          quoteDataCounts,
        })
      })
    )
  )
}
