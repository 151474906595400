import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../core/store'
import {
  LossSetLayer,
  ScaledLossDialogOutput,
} from '../model/loss-set-layers.model'
import { PortfolioSetID } from '../model/portfolio-set.model'
import { AnalysisInitProps, reinitialize } from '../store/analysis.actions'
import {
  selectCurrentClientID,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectEditorPortfolioSetID,
  selectParentGrossLossSetLayers,
  selectCurrentStructureID,
} from '../store/analysis.selectors'
import {
  modifyScaleLossSetLayers,
  newScaledLossSetLayers,
} from '../store/loss-set-layers/loss-set-layers.actions'
import { StudyResponse } from 'src/app/api/model/backend.model'
import { selectStudies } from 'src/app/core/store/auth/auth.selectors'
import { MappingLabels } from 'src/app/core/model/study.model'
import { updateLossSetMappingLabels } from 'src/app/core/store/program/program.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-scale-loss-set-container',
  template: `
    <app-scale-loss-set
      [parentLossSets]="parentLossSets$ | async"
      [portfolioSetID]="portfolioSetID$ | async"
      [selectedClientID]="selectedClientID$ | async"
      [selectedYearID]="selectedYearID$ | async"
      [selectedStudyID]="selectedStudyID$ | async"
      [selectedStructureID]="selectedStructureID$ | async"
      [studies]="studies$ | async"
      (scaleLossSets)="onScaleLossSets($event)"
      (closeScaleDialog)="onCloseClick($event)"
      (postMappingLabels)="onPostMappingLabels($event)"
      (updateMappingLabels)="onUpdateMappingLabels($event)"
    ></app-scale-loss-set>
  `,
})
export class ScaleLossSetContainerComponent implements OnInit {
  parentLossSets$: Observable<LossSetLayer[]>
  portfolioSetID$: Observable<PortfolioSetID | null>
  selectedClientID$: Observable<string | null>
  selectedYearID$: Observable<string | null>
  selectedStudyID$: Observable<string | null>
  selectedStructureID$: Observable<string | null>
  studies$: Observable<StudyResponse[] | null>

  constructor(
    private dialogRef: MatDialogRef<ScaleLossSetContainerComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.parentLossSets$ = this.store.pipe(
      select(selectParentGrossLossSetLayers)
    )
    this.portfolioSetID$ = this.store.pipe(select(selectEditorPortfolioSetID))
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.selectedStudyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.selectedStructureID$ = this.store.pipe(
      select(selectCurrentStructureID)
    )
    this.studies$ = this.store.select(selectStudies)
  }

  onScaleLossSets(scaledDialogOutput: ScaledLossDialogOutput) {
    if (scaledDialogOutput.action === 'new') {
      this.store.dispatch(
        newScaledLossSetLayers({
          scaledProps: scaledDialogOutput.props,
        })
      )
    } else if (scaledDialogOutput.action === 'modify') {
      this.store.dispatch(
        modifyScaleLossSetLayers({
          scaledProps: scaledDialogOutput.props,
        })
      )
    }
  }


  onCloseClick(initProps: AnalysisInitProps) {
    this.dialogRef.close()
    this.store.dispatch(
      reinitialize({
        ...initProps,
      })
    )
  }

  onUpdateMappingLabels($event: {
    id: string,
    mappingLabels: MappingLabels
  }): void {
    const { id, mappingLabels } = $event
    this.store.dispatch(updateLossSetMappingLabels({id, mappingLabels}))
  }

  onPostMappingLabels($event: {
    id: string,
    mappingLabels: MappingLabels
  }): void {
    const { id, mappingLabels } = $event
    this.store.dispatch(updateLossSetMappingLabels({id, mappingLabels}))
  }
}
