import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Reference } from '@shared/layer-property/layer-property.component'
import { map, Observable, startWith } from 'rxjs'
import { uniq } from 'ramda'
import {
  CreditCalculationStructure,
  CreditStructure,
  CreditTransaction,
} from '../../model/credit-structure.model'
import { UserReinsurer } from '../../../api/model/backend.model'
import { CreditSubmissionService } from '../../services/credit-submission.service'
import {
  CreateSubmissionProps,
  SubmissionReinsurer,
} from '../../model/credit-submission.model'
import { CreditPortfolio } from '../../model/credit-portfolio.model'

@Component({
  selector: 'app-credit-create-submission-dialog',
  templateUrl: './credit-create-submission-dialog.component.html',
  styleUrls: ['./credit-create-submission-dialog.component.scss'],
})
export class CreditCreateSubmissionDialogComponent implements OnChanges {
  transactionId: string
  references: Reference[] = []
  label = 'Unlabled Submission'
  description = 'No Description'
  submissionReinsurers: SubmissionReinsurer[] = []
  filteredReinsurers: Observable<SubmissionReinsurer[]>
  filterCtrl = new FormControl('', Validators.required)
  selectedReinsurers: SubmissionReinsurer[] = []
  creatingSubmissions = false
  @Input() creditStructure: CreditStructure
  @Input() calculationStructure: CreditCalculationStructure
  @Input() userReinsurers: UserReinsurer[]
  @Input() currentPortfolio: CreditPortfolio
  @Output() close = new EventEmitter()

  constructor(
    private submissionService: CreditSubmissionService,
    private snackbar: MatSnackBar
  ) {}

  ngOnChanges(): void {
    this.setTransaction()
    this.setReinsurers()
  }

  toggleReinsurer(reinsurer: SubmissionReinsurer): void {
    const indexOf = this.selectedReinsurers.indexOf(reinsurer)
    if (indexOf < 0) {
      this.selectedReinsurers.push(reinsurer)
    } else {
      this.selectedReinsurers.splice(indexOf, 1)
    }
  }

  onCreateClick(): void {
    if (this.selectedReinsurers.length < 1) {
      return
    }
    // Create submission for selected transaction only
    const calculationStructure = this.prepStructureForSubmission()
    const reinsurerUserMap = this.selectedReinsurers.reduce(
      (reinsurerUsers: Record<number, number[]>, selectedReinsurer) => {
        reinsurerUsers[selectedReinsurer.reinsurerId] = this.userReinsurers
          .filter(r => r.reinsurerId === selectedReinsurer.reinsurerId)
          .map(r => r.userId)

        return reinsurerUsers
      },
      {}
    )
    const props: CreateSubmissionProps = {
      portfolio: this.currentPortfolio,
      creditStructure: this.creditStructure,
      calculationStructure,
      transactionId: this.transactionId,
      description: this.description,
      reinsurerUserMap,
    }
    this.creatingSubmissions = true
    this.submissionService.createSubmissions(props).subscribe(success => {
      this.creatingSubmissions = false
      this.close.emit()
      this.snackbar.open(
        `Successfully Created ${success.submissionCount} Submission(s),  ${success.submissionUserTrancheCount} User Tranche(s)`,
        'Dismiss',
        {
          duration: 3000,
        }
      )
    })
  }

  private setTransaction(): void {
    if (!this.calculationStructure) {
      return
    }
    const transactions = this.calculationStructure.transactions
    this.transactionId = transactions[transactions.length - 1]._id
    this.references = transactions.map(transaction => {
      return {
        value: transaction._id,
        viewValue: transaction.name,
      }
    })
  }

  private setReinsurers(): void {
    this.filteredReinsurers = this.filterCtrl.valueChanges.pipe(
      startWith(''),
      map(
        filterBy =>
          typeof filterBy === 'string' && filterBy.length > 0
            ? this.filterReinsurers(filterBy)
            : this.submissionReinsurers // default to all reinsurers if no filter typed
      )
    )
    this.submissionReinsurers = this.userReinsurers
      ? uniq(
          this.userReinsurers.map(user => {
            return {
              reinsurerId: user.reinsurerId,
              reinsurerName: user.reinsurerName,
            } as SubmissionReinsurer
          })
        )
      : []
  }

  private filterReinsurers(filter: string): SubmissionReinsurer[] {
    return this.submissionReinsurers.filter(reinsurer =>
      reinsurer.reinsurerName.toLowerCase().includes(filter.toLowerCase())
    )
  }

  private prepStructureForSubmission(): CreditCalculationStructure {
    const transactions = this.prepTransactionsForSubmission()
    const { _id, ...calculationStructureWithoutId } = this.calculationStructure
    return {
      ...calculationStructureWithoutId,
      transactions,
      collateral_percent: 0.2,
      name: this.label,
    }
  }

  private prepTransactionsForSubmission(): CreditTransaction[] {
    const transaction = this.calculationStructure.transactions.find(
      t => t._id === this.transactionId
    )
    if (!transaction) {
      return []
    }
    return [transaction].map(transaction => {
      const { saved_pricing_curve_id, ...transactionWithoutPricingCurve } =
        transaction
      return {
        ...transactionWithoutPricingCurve,
        tranches: transaction.tranches.map(tranche => {
          return { ...tranche, rol: 1 }
        }),
      }
    })
  }
}
