import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { TrackingPreferences, TrackingSelectors } from '../tracking.model'
import { IControl } from 'src/app/management-information/store/management-information.reducer'
import { BasicControl } from 'src/app/management-information/model/management-information.model'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'
import { MatSelectChange } from '@angular/material/select'

@Component({
  selector: 'app-tracking-sidebar',
  templateUrl: './tracking-sidebar.component.html',
  styleUrls: ['./tracking-sidebar.component.scss'],
})
export class TrackingSideBarComponent implements OnInit, OnChanges {
  @ViewChild('controlsRef') controlsRef: ElementRef

  @Input() selectors: TrackingSelectors
  @Input() isLoading: boolean
  @Input() inceptionInterval: BasicControl
  @Input() userPreferences: SelectedUserPreferences[]

  form: FormGroup
  minInceptionDate: Date
  maxInceptionDate: Date
  selectedPreferenceValue: number | null = null

  @Output() filtersChanged = new EventEmitter<{
    filters: Record<string, string[]>
    isSet: boolean
  }>()
  @Output() inceptionDateChanged = new EventEmitter<{
    minInceptionDate: string
    maxInceptionDate: string
  }>()
  @Output() saveTrackingPreferences = new EventEmitter()
  @Output() setTrackingPreferences = new EventEmitter<{ id: number }>()

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      clientName: new FormControl([]),
      year: new FormControl([]),
      businessUnit: new FormControl([]),
      complete: new FormControl([]),
    })
    this.minInceptionDate = new Date(this.inceptionInterval.minValue)
    this.maxInceptionDate = new Date(this.inceptionInterval.maxValue)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.formBuilder.group({
      clientName: new FormControl([]),
      year: new FormControl([]),
      businessUnit: new FormControl([]),
      complete: new FormControl([]),
    })
    this.minInceptionDate = new Date(this.inceptionInterval.minValue)
    this.maxInceptionDate = new Date(this.inceptionInterval.maxValue)
  }

  dispatchFilters(): void {
    this.selectedPreferenceValue = null
    const filters = this.getFiltersFromControls()
    this.filtersChanged.emit({ filters, isSet: true })
    this.form.markAsPristine()
  }

  clearFilter(event: MouseEvent, filter: IControl): void {
    event.stopPropagation()
    this.selectedPreferenceValue = null
    this.form.controls[filter.columnName].setValue([])
    const filters = this.getFiltersFromControls()
    this.filtersChanged.emit({ filters, isSet: true })
  }

  intervalChanged(prefix: 'min' | 'max', value: string): void {
    const isMin = prefix === 'min'
    if (isMin) {
      this.minInceptionDate = new Date(value)
    } else {
      this.maxInceptionDate = new Date(value)
    }
    this.inceptionDateChanged.emit({
      minInceptionDate: this.minInceptionDate.toDateString(),
      maxInceptionDate: this.maxInceptionDate.toDateString(),
    })
  }

  savePreferences(): void {
    this.saveTrackingPreferences.emit()
  }

  getName(id: number): string {
    return (
      (
        JSON.parse(
          this.userPreferences.find(u => u.id === id).preferenceValue
        ) as TrackingPreferences
      ).label ?? 'Preference 1'
    )
  }

  onPreferenceChange(change: MatSelectChange): void {
    this.selectedPreferenceValue = change.value
    this.setTrackingPreferences.emit({ id: change.value })
    let filterData: Record<string, string[]>
    let selectedPreference = JSON.parse(
      this.userPreferences.find(up => up.id === change.value).preferenceValue
    ) as TrackingPreferences

    if (selectedPreference) {
      filterData = {
        clientName: selectedPreference.clientName,
        year: selectedPreference.year,
        businessUnit: selectedPreference.businessUnit,
        complete: selectedPreference.complete,
      }
      this.filtersChanged.emit({ filters: filterData, isSet: false })
    }
  }

  private getFiltersFromControls(): Record<string, string[]> {
    const filters = Object.entries(this.form.controls).reduce(
      (acc, [key, formControl]) => {
        acc = {
          ...acc,
          [key]: formControl.value,
        }
        return acc
      },
      {}
    )
    return filters
  }
}
