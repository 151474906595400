import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as fromOptimizationRanges from './optimization-ranges.reducer'
import * as fromOptimizationRangesActions from './optimization-ranges.actions'
import * as fromOptimizationRangesTypesActions from './optimization-ranges-types.actions'
import * as fromOptimizationActions from './optimization.actions'

export const adapter = createEntityAdapter<fromOptimizationRanges.State>()
export type State = EntityState<fromOptimizationRanges.State>
export const initialState = adapter.getInitialState()

export const rangesTypeReducer = createReducer(
  initialState,
  on(
    fromOptimizationRangesTypesActions.setRangesTypes,
    (state, { rangesTypes }) => {
      const rangesStates = rangesTypes.map(r => {
        return fromOptimizationRanges.reducer(
          {
            ...fromOptimizationRanges.initialState,
            id: r.id,
          },
          fromOptimizationRangesActions.setRanges({ ranges: r.ranges })
        )
      })
      return adapter.setAll(rangesStates, state)
    }
  ),
  on(
    fromOptimizationRangesTypesActions.updateRangesTypes,
    (state, { id, rangeChange }) => {
      let rangesState = state.entities[id]
      if (rangesState) {
        rangesState = fromOptimizationRanges.reducer(
          rangesState,
          fromOptimizationRangesActions.updateRanges(rangeChange)
        )
        return adapter.updateOne({ id, changes: rangesState }, state)
      } else {
        return state
      }
    }
  ),
  on(
    fromOptimizationRangesTypesActions.setLossSetGroupIDs,
    (state, { id, values }) => {
      return adapter.updateOne(
        { id, changes: { lossSetGroupIDs: values } },
        state
      )
    }
  ),
  on(fromOptimizationActions.reset, () => ({ ...initialState }))
)

export function reducer(state: State | undefined, action: Action) {
  return rangesTypeReducer(state, action)
}
