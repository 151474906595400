import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core'
import { PricingCurveContextTypes } from '../../../../model/pricing-curve.model'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import * as fromActions from '../../../../store/pricing-curve.actions'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { PricingCurveDialogService } from '../../../dialog/pricing-curve-dialog.service'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-details-card',
  styleUrls: ['./pricing-curve-details-card.component.scss'],
  templateUrl: './pricing-curve-details-card.component.html',
})
export class PricingCurveDetailsCardComponent implements AfterViewInit {
  @Input() context: PricingCurveContextTypes
  @Input() set pricingCurve(value: PricingCurve) {
    this._pricingCurve = value
    this.layersVisible = this.pricingCurve.layersVisible
    this.lineVisible = this.pricingCurve.lineVisible
    this.isManual = this.pricingCurve.isManual
    if (this.pricingCurve.technicalFactors) {
      const { slope, intercept, isPowerCurve } = this.pricingCurve
      this.slope = slope
      this.yIntercept = intercept
      this.isPowerCurve = isPowerCurve
      if (!this.lineVisible && this.overrideDisplay) {
        this.overrideDisplay = false
      }
    }
    if (this.pricingCurve.rSquared) {
      this.rSquared = this.pricingCurve.rSquared
    }
  }
  get pricingCurve() {
    return this._pricingCurve
  }
  _pricingCurve: PricingCurve
  layersVisible: boolean
  lineVisible: boolean
  isManual: boolean

  slope: number | undefined
  yIntercept: number | undefined
  isPowerCurve: boolean
  rSquared: number | undefined
  max = 12
  overrideDisplay = false

  @ViewChild('header', { static: false }) headerEl: ElementRef<HTMLDivElement>

  get hasLine(): boolean {
    return this.pricingCurve.hasLine
  }

  get hasLayers(): boolean {
    return !!this.pricingCurve.includedLayers.length
  }

  get displayLayerChart() {
    return !this.overrideDisplay && this.hasLayers && this.layersVisible && this.context !== 'credit'
  }

  get displayLineChart() {
    return this.overrideDisplay || (!this.displayLayerChart && this.hasLine)
  }

  get colorClass() {
    return this.pricingCurve.graphColorClass
  }

  constructor(
    private dialogService: PricingCurveDialogService,
    private store: Store<AppState>,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  ngAfterViewInit(): void {
    if (this.headerEl && this.colorClass) {
      const rgb = getComputedStyle(
        this.headerEl.nativeElement
      ).getPropertyValue('background-color')
      if (rgb) {
        this.store.dispatch(
          fromActions.updateDataSetExportRGBValue({
            id: this.pricingCurve.id,
            rgb,
          })
        )
      }
    }
  }

  openCurveSettingsDialog() {
    const dialogRef = this.dialogService.openDataSettingsDialog(undefined, {
      layersVisible: this.layersVisible,
      lineVisible: this.lineVisible,
      hasLayers: this.hasLayers,
      hasLine: this.hasLine,
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(
          fromActions.updateCurveVisibility({
            id: this.pricingCurve.id,
            layersVisible: result.layersVisible,
            lineVisible: result.lineVisible,
            applyAll: result.applyAll
          })
        )
      }
    })
  }

  swapCardDisplayMode() {
    this.overrideDisplay = !this.overrideDisplay
  }

  confirmRemoveCurve() {
    this.confirmationDialog
      .open({
        message:
          'This will remove this dataset from the graph. If this dataset has not been saved, this action will be permanent.',
        submitLabel: 'Confirm',
      })
      .afterClosed()
      .subscribe((doClose: boolean) => {
        if (doClose) {
          this.store.dispatch(
            fromActions.removePricingCurveFromGraph({
              id: this.pricingCurve.id,
            })
          )
        }
      })
  }

  initCurveEdit() {
    this.store.dispatch(
      fromActions.editDataDialogOpened({
        curveData: this.pricingCurve.curveData,
      })
    )

    this.pricingCurve.isEdit = true
    const dialogRef = this.dialogService.openAddDataDialog(undefined, {
      isEdit: true,
      pricingCurveInitialState: this.pricingCurve.curveData,
      id: this.pricingCurve.id,
      tabsToHide: {
        layers: !this.hasLayers,
        manual: !this.hasLine,
        saved: true,
      },
      startingTab: this.pricingCurve.isManual ? 'manual' : 'layers',
    })
    dialogRef.afterClosed().subscribe(() => {
      // Dispatch data dialog closed to reset state
      this.pricingCurve.isEdit = false
      this.store.dispatch(fromActions.editDataDialogClosed())
    })
  }

  get curveLabel(): string {
    return this.pricingCurve.label ?? 'Custom Curve'
  }

  get interceptLabel() {
    return this.isPowerCurve ? 'ELoL Multiplier (a)' : 'Fixed Cost (a)'
  }

  get slopeLabel() {
    return this.isPowerCurve ? 'ELoL Power (b)' : 'ELoL Multiplier (b)'
  }

  get curveType() {
    return this.isPowerCurve
      ? `EL Power Curve${this.isManual ? ' - Manual' : ''}`
      : `EL Linear${this.isManual ? ' - Manual' : ''}`
  }
}
