import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  Inject,
} from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { AppState } from '../../../core/store'
import {
  CalculatedPortfolioDetailMetrics,
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import {
  selectCededPortfolioViewID,
  selectGrossPortfolioViewID,
  selectNetPortfolioViewID,
  selectPortfolioViewDetailCededMetrics,
  selectPortfolioViewDetailGrossMetrics,
  selectPortfolioViewDetailMetricsError,
  selectPortfolioViewDetailMetricsLoading,
  selectPortfolioViewDetailNetMetrics,
  selectPortfolioViewMetrics,
  selectPortfolioViewMetricsRSS,
} from '../../store/analysis.selectors'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import { updateAndFetch } from '../../store/metrics/portfolio-metrics.actions'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { selectCurrentLossFilters } from '../../../core/store/broker/broker.selectors'

export interface PortfolioDetailsDialogData {
  portfolioSetID?: PortfolioSetID
  name?: string
  showResults?: boolean
  currency?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-details-dialog-container',
  templateUrl: './portfolio-details-dialog.container.html',
})
export class PortfolioDetailsDialogContainerComponent
  implements OnInit, OnDestroy
{
  portfolioSetID: PortfolioSetID | null
  name: string | null
  showResults: boolean | null
  currency: string | null
  loading$: Observable<boolean>
  error$: Observable<string | null>
  grossMetrics$: Observable<CalculatedPortfolioDetailMetrics | null>
  cededMetrics$: Observable<CalculatedPortfolioDetailMetrics | null>
  netMetrics$: Observable<CalculatedPortfolioDetailMetrics | null>

  portfolioMetrics$: Observable<PortfolioMetrics | null>
  rss$: Observable<number | null>
  currentLossFilters$: Observable<LossFilter[] | undefined>

  currentCededPortfolioViewID$: Observable<string | null>
  currentGrossPortfolioViewID$: Observable<string | null>
  currentNetPortfolioViewID$: Observable<string | null>

  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data?: PortfolioDetailsDialogData | null
  ) {
    this.portfolioSetID = (data && data.portfolioSetID) || null
    this.name = (data && data.name) || null
    this.showResults = (data && data.showResults) || null
    this.currency = (data && data.currency) || null
  }

  ngOnInit(): void {
    const props = { portfolioSetID: this.portfolioSetID || undefined }
    this.loading$ = this.store.pipe(
      select(selectPortfolioViewDetailMetricsLoading, props)
    )
    this.error$ = this.store.pipe(
      select(selectPortfolioViewDetailMetricsError, props)
    )
    this.grossMetrics$ = this.store.pipe(
      select(selectPortfolioViewDetailGrossMetrics, props)
    )
    this.cededMetrics$ = this.store.pipe(
      select(selectPortfolioViewDetailCededMetrics, props)
    )
    this.netMetrics$ = this.store.pipe(
      select(selectPortfolioViewDetailNetMetrics, props)
    )

    this.portfolioMetrics$ = this.store.pipe(
      select(selectPortfolioViewMetrics, props)
    )
    this.rss$ = this.store.pipe(select(selectPortfolioViewMetricsRSS, props))
    this.currentLossFilters$ = this.store.pipe(select(selectCurrentLossFilters))

    this.currentCededPortfolioViewID$ = this.store.pipe(
      select(selectCededPortfolioViewID, props)
    )
    this.currentGrossPortfolioViewID$ = this.store.pipe(
      select(selectGrossPortfolioViewID, props)
    )
    this.currentNetPortfolioViewID$ = this.store.pipe(
      select(selectNetPortfolioViewID, props)
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onReturnPeriod1Change($event: number): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { returnPeriod1: $event },
      })
    )
  }

  onReturnPeriod2Change($event: number): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { returnPeriod2: $event },
      })
    )
  }

  onReturnPeriod3Change($event: number): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { returnPeriod3: $event },
      })
    )
  }

  onReturnPeriod4Change($event: number): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { returnPeriod4: $event },
      })
    )
  }

  onReturnPeriod5Change($event: number): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { returnPeriod5: $event },
      })
    )
  }

  onPortfolioTypeChange($event: PortfolioType): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { portfolioType: $event },
      })
    )
  }

  onAggregationMethodChange($event: AggregationMethodType): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { aggregationMethod: $event },
      })
    )
  }

  onPerspectiveChange($event: Perspective): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { perspective: $event },
      })
    )
  }

  onLossFilterClick($event: string): void {
    this.store.dispatch(
      updateAndFetch({
        ...this.portfolioSetID,
        change: { lossFilter: $event },
      })
    )
  }
}
