<app-theme-container
  ><app-pricing-curve
    [savedCurves]="savedCurves$ | async"
    [pricingCurves]="pricingCurves$ | async"
    [status]="status$ | async"
    [username]="username$ | async"
    [context]="context$ | async"
    [graphSettings]="graphSettings$ | async"
    [readonly]="readonly$ | async"
  ></app-pricing-curve
></app-theme-container>
